import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import type { GetWeavePopNotificationByType, NotificationActions } from '@frontend/types';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';
import { DefaultNotificationBody } from './defaults/default-notification';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'payments'>>;

export const PaymentNotificationComponent: FC<Props> = ({ isHistorical, notification, emit, ...rest }) => {
  const { t } = useTranslation();
  const actions: NotificationActions = [
    {
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('New Payment')}
        body={
          <DefaultNotificationBody
            name={notification.payload.patientName ?? ''}
            body={notification.payload.originLabel ?? ''}
          />
        }
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
