import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { EmptyStates } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  BaseFieldProps,
  ChecklistField,
  Chip,
  ListFieldProps,
  ListRow,
  NotificationBadge,
  SearchField,
  Text,
  TextButton,
  useDebouncedValue,
} from '@frontend/design-system';
import { HeaderFilterDataType } from '../../../types';

type CalendarViewV3HeaderFilterSelectorProps = {
  title: string;
  renderList: HeaderFilterDataType[];
  numberOfSelections: number;
  selectedLocations: string[];
  totalNumber: number;
  checkListProps: ListFieldProps;
  hasOnlyOneLocation: boolean;
  emptyStatePlaceholder: string;
  searchFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  handleSelectAll: () => void;
  handleClear: () => void;
};

export const CalendarViewV3HeaderFilterSelector = React.memo(
  ({
    title,
    totalNumber,
    numberOfSelections,
    checkListProps,
    hasOnlyOneLocation,
    selectedLocations,
    renderList,
    emptyStatePlaceholder,
    searchFieldProps,
    handleSelectAll,
    handleClear,
  }: CalendarViewV3HeaderFilterSelectorProps) => {
    const { t } = useTranslation('scheduleCalendarFilters');
    const { getScopeName } = useAppScopeStore();

    const debouncedSearchTerm = useDebouncedValue(searchFieldProps.value);

    const searchedList = useMemo(() => {
      return renderList?.filter((listItem) =>
        listItem.label?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
    }, [debouncedSearchTerm, renderList]);

    return (
      <Accordion size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'provider list'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold'>
              {title}
            </Text>
            <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                {numberOfSelections}
              </NotificationBadge>
              <Text>of {totalNumber}</Text>
            </div>
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <SearchField name='calendar-provider-search' placeholder={t('Search')} {...searchFieldProps} />
            {searchedList.length === 0 ? (
              <EmptyStates
                emptyStateConfig={{
                  type: 'schedule',
                  header: emptyStatePlaceholder,
                  description: '',
                }}
              />
            ) : (
              <>
                {!!checkListProps.error && (
                  <Text css={{ marginTop: theme.spacing(2) }} color='error' size='medium'>
                    {checkListProps.error}
                  </Text>
                )}
                <TextButton
                  css={selectAllTextButtonStyles}
                  onClick={handleSelectAll}
                  disabled={numberOfSelections === totalNumber}
                >
                  {t('Select All')}
                </TextButton>
                <TextButton css={clearAllTextButtonStyles} onClick={handleClear}>
                  {t('Clear')}
                </TextButton>
                <ChecklistField
                  name='calendar-view-v3-provider-filter'
                  labelPlacement='right'
                  label={''}
                  css={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
                  {...checkListProps}
                >
                  {selectedLocations.map((locationId) => {
                    return searchedList
                      .filter((listItem) => listItem.locationId === locationId)
                      .map(({ id, label, locationId }) => {
                        return (
                          <ListRow key={id} css={{ borderBottom: 'none', padding: theme.spacing(0, 2, 1, 1) }}>
                            <ListRow.Content>
                              <ChecklistField.Option
                                key={`${id}-${location}`}
                                name={id ?? ''}
                                css={{ minWidth: 120, width: '100%', alignItems: 'center' }}
                              >
                                {label}
                              </ChecklistField.Option>
                            </ListRow.Content>
                            {!hasOnlyOneLocation && (
                              <ListRow.Trail>
                                <Chip.Location css={{ minWidth: 120, maxWidth: 200 }}>
                                  {getScopeName(locationId || '')}
                                </Chip.Location>
                              </ListRow.Trail>
                            )}
                          </ListRow>
                        );
                      });
                  })}
                </ChecklistField>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
);

CalendarViewV3HeaderFilterSelector.displayName = 'CalendarViewV3HeaderFilterSelector';

const selectAllTextButtonStyles = css({
  float: 'left',
  color: theme.colors.primary50,
  fontWeight: 'bold',
  margin: theme.spacing(2, 1, 2, 0),
});

const clearAllTextButtonStyles = css({
  float: 'right',
  color: theme.colors.primary50,
  fontWeight: 'bold',
  margin: theme.spacing(2, 0, 2, 1),
});
