import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'motion/react';
import { CHAT_AREA_WIDTH, CHAT_LIST_WIDTH_COLLAPSED } from '../../constants';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { Nav } from '../nav/nav';

export const NavPanel = () => {
  const mainPanelView = useTeamChatSelector((ctx) => ctx.mainPanelView);
  const isNavExpanded = useTeamChatSelector((ctx) => ctx.isNavExpanded);

  const width = mainPanelView !== 'none' ? (isNavExpanded ? CHAT_LIST_WIDTH_COLLAPSED : 0) : CHAT_AREA_WIDTH;

  return (
    <AnimatePresence>
      <motion.section
        layout
        css={containerStyle}
        initial={{ opacity: 0, width }} // Initial state of the div
        animate={{ opacity: 1, width }}
        exit={{ opacity: 0, width: 0 }}
        transition={{ duration: 0.3, type: 'keyframes' }}
      >
        <Nav />
      </motion.section>
    </AnimatePresence>
  );
};

NavPanel.displayName = 'NavPanel';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
});
