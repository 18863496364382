import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { AnalyticsCommonTypes, CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { Chart, ScrollControl } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ChipVariants, NakedUl, Text, TextLink, useAlert, variantsTheme } from '@frontend/design-system';
import { URLs } from '../../../constants';
import { queryKeys } from '../../../query-keys';
import { trackingIds } from '../../../tracking-ids';
import { getFilteredEnumValues } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { SummaryCardClickHandler } from '../call-intel-summary-charts';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelDemoFlags, useCallIntelLocations, useCallIntelShallowStore } from '../hooks';

type Props = {
  categorisedComparison?: boolean;
  data?: Record<string, Record<keyof CallIntelTypes.TaskTypeEnum, number>>;
  isLoading?: boolean;
  onClick?: SummaryCardClickHandler;
  title: string;
};

type TaskLocationListCardProps = {
  label: string;
  locationName: string;
  onCardClick: (type: TaskType, locationName: string) => void;
  type: TaskType;
  value: number;
};

type TaskType = Exclude<CallIntelTypes.TaskTypeEnum, CallIntelTypes.TaskTypeEnum.TYPE_UNKNOWN>;

type MaxHeightParams = {
  isRowComparison: boolean;
  isMobile: boolean;
  dataPointsLength: number;
  chartDataLength: number;
};

// Extracted utility function for getting background and color from variant
const extractBackground = (variant: keyof typeof variantsTheme): { background: string; color: string } => {
  const cssString = variantsTheme[variant]?.styles;

  if (!cssString) return { background: 'transparent', color: 'inherit' };

  const backgroundMatch = cssString.match(/background:\s*([^;]+);/);
  const colorMatch = cssString.match(/color:\s*([^;]+);/);

  return {
    background: backgroundMatch ? backgroundMatch[1].trim() : 'transparent',
    color: colorMatch ? colorMatch[1].trim() : 'inherit',
  };
};

const getMaxHeight = ({ isRowComparison, isMobile, dataPointsLength, chartDataLength }: MaxHeightParams): number => {
  if (isRowComparison) {
    return isMobile
      ? HEIGHT_CONSTANTS.ROW_COMPARISON_MOBILE(dataPointsLength)
      : HEIGHT_CONSTANTS.ROW_COMPARISON_DESKTOP;
  } else {
    return isMobile
      ? HEIGHT_CONSTANTS.COLUMN_COMPARISON_MOBILE(chartDataLength)
      : HEIGHT_CONSTANTS.COLUMN_COMPARISON_DESKTOP;
  }
};

const TASK_TYPE_PREFIX = 'TYPE_';

const TaskLocationListCard = ({ label, type, value, locationName, onCardClick }: TaskLocationListCardProps) => {
  const { headerChipVariants } = useCallIntelShallowStore('headerChipVariants');

  const { color, background } = extractBackground(headerChipVariants[type]?.variant as ChipVariants);

  return (
    <div
      css={[
        styles.taskLocationCardWrapper,
        css`
          &:hover {
            border-color: ${color};
          }
        `,
      ]}
      role='button'
      onClick={() => onCardClick(type, locationName)}
      data-trackingId={trackingIds.callIntel.compareLocationsTaskLocationCardClick}
    >
      <div
        css={css`
          background-color: ${background};
          padding: ${theme.spacing(0.5)};
          border-radius: ${theme.borderRadius.small};
        `}
      >
        <Text
          css={css`
            color: ${color};
            font-size: ${theme.font.size.h3};
            line-height: ${theme.spacing(3)};
          `}
          weight='bold'
        >
          {value}
        </Text>
        <Text>{label}</Text>
      </div>
    </div>
  );
};

const dataPoints = getFilteredEnumValues(CallIntelTypes.TaskTypeEnum, CallIntelTypes.TaskTypeEnum.TYPE_UNKNOWN);

const COLUMN_COMPARISON_LABEL_HEIGHT_MOBILE = 22; // Height of the column comparison label for mobile screens
const COLUMN_COMPARISON_LABEL_PADDING_MOBILE = 16; // Additional padding for the column comparison label on mobile screens
const MAX_DATA_LIMIT = 5; // The maximum number of data points (locations) before vertical scrolling is enabled
const ROW_COMPARISON_LABEL_HEIGHT_MOBILE = 21; // Height of the row comparison label for mobile screens
const ROW_COMPARISON_LABEL_PADDING_MOBILE = 32; // Additional height added for row comparison label padding on mobile screens
const TASK_CARD_BASE_HEIGHT = 74; // Base height of a task card in the layout
const VERTICAL_PADDING_DESKTOP = 16; // Vertical padding around the card space for standard screens
const VERTICAL_PADDING_MOBILE = 24; // Vertical padding around the card space for mobile screens in row comparison mode

// Constants for calculating the maximum height of the component under different screen sizes and comparison modes
const HEIGHT_CONSTANTS = {
  // Total height for row comparison on desktop, calculated within the maximum data points limit
  ROW_COMPARISON_DESKTOP: TASK_CARD_BASE_HEIGHT * MAX_DATA_LIMIT + VERTICAL_PADDING_DESKTOP * MAX_DATA_LIMIT - 2,

  // Total height for row comparison on mobile, calculated dynamically based on the number of data points
  ROW_COMPARISON_MOBILE: (dataPointsLength: number) =>
    TASK_CARD_BASE_HEIGHT * dataPointsLength +
    VERTICAL_PADDING_MOBILE * dataPointsLength +
    ROW_COMPARISON_LABEL_PADDING_MOBILE +
    ROW_COMPARISON_LABEL_HEIGHT_MOBILE,

  // Total height for column comparison on desktop, calculated within the maximum data points limit
  COLUMN_COMPARISON_DESKTOP:
    TASK_CARD_BASE_HEIGHT * MAX_DATA_LIMIT + VERTICAL_PADDING_DESKTOP * (MAX_DATA_LIMIT + 1) - 2,

  // Total height for column comparison on mobile, calculated dynamically based on the number of data points
  COLUMN_COMPARISON_MOBILE: (dataPointsLength: number) =>
    TASK_CARD_BASE_HEIGHT * dataPointsLength +
    VERTICAL_PADDING_DESKTOP * (dataPointsLength + 1) +
    (COLUMN_COMPARISON_LABEL_HEIGHT_MOBILE + COLUMN_COMPARISON_LABEL_PADDING_MOBILE) * dataPointsLength,
};

const cardWidthBase = 98;

const pageConfig: AnalyticsCommonTypes.PageConfig = {
  pageNumber: 1,
  pageSize: 3,
};

export const FollowUpReasonChart = memo(({ categorisedComparison, data = {}, isLoading, title }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = useNavigate();
  const { dataLabels, filterHintText, filters, isDemoAccount, setFiltersToRestore } = useCallIntelShallowStore(
    'dataLabels',
    'filterHintText',
    'filters',
    'isDemoAccount',
    'setFiltersToRestore'
  );
  const { locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
  });
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();
  const isRowComparison = !categorisedComparison;

  const ulRef = useRef<HTMLUListElement>(null);
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.min });

  const [showMore, setShowMore] = useState<boolean | null>(null);
  const [isShowingAllRows, setIsShowingAllRows] = useState<boolean>(isMobile);
  const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(false);

  const scrollView = ulRef.current;

  const totalFollowUps = useMemo(() => {
    return Object.values(data).reduce((total, taskTypes) => {
      return total + Object.values(taskTypes).reduce((sum, count) => sum + (count || 0), 0);
    }, 0);
  }, [data]);

  const followupTaskTypeFilters: CallIntelTypes.FollowupTaskTypeFilters = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    locations: filters.locations,
    officeUsers: filters.officeUsers,
    contactTypes: filters.contactTypes,
    taskTypes: dataPoints as CallIntelTypes.TaskTypeEnum[],
    taskStatus: [],
  };

  const queryString = useMemo(
    () => `calls-followups-count-${JSON.stringify(followupTaskTypeFilters)}-isDemoAccount-${isDemoAccount}`,
    [followupTaskTypeFilters, isDemoAccount]
  );

  const { data: actualFollowUpCount } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: () =>
      CallIntelligenceApi.getFollowUps({
        filters: followupTaskTypeFilters,
        pageConfig,
        sortBy: CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL,
        sortType: CallIntelTypes.SortTypeEnum.SORT_DESC,
      }),
    onError: () => {
      alert.error(t('Failed to fetch follow-ups'));
    },
    enabled: !isDemoAccount,
    select: (data) => data as CallIntelTypes.FollowUpResponse,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const chartData = useMemo(() => {
    return Object.entries(data).map(([locationName, dataset]) => ({
      name: locationName,
      values: {
        ...dataset,
      },
    }));
  }, [data]);

  const MAX_HEIGHT = getMaxHeight({
    isRowComparison,
    isMobile,
    dataPointsLength: dataPoints.length,
    chartDataLength: chartData.length,
  });

  useEffect(() => {
    setShowMore(!isMobile && chartData.length > MAX_DATA_LIMIT);
    if (isMobile) {
      setIsShowingAllRows(true);
    }
  }, [chartData.length, isMobile]);

  const handleShowMore = useCallback(() => {
    if (isShowingAllRows) {
      ulRef.current?.style.setProperty('max-height', `${MAX_HEIGHT}px`);
    } else {
      ulRef.current?.style.setProperty('max-height', `${ulRef.current.scrollHeight}px`);
    }

    // Toggle the state
    setIsShowingAllRows(!isShowingAllRows);
  }, [isShowingAllRows]);

  const handleControlledScroll = useCallback(
    (direction: 'left' | 'right' | 'reset') => {
      if (!scrollView) {
        return;
      }

      const columns = isRowComparison ? chartData.length : dataPoints.length;

      const scrollLeft = scrollView.scrollWidth / columns;

      if (direction === 'left') {
        const scrollTo = scrollView.scrollLeft - scrollLeft;
        const hasMoreToScroll = scrollTo > 0;
        scrollView.scrollTo({
          left: scrollTo,
          behavior: 'smooth',
        });
        setShowRightScrollButton(true);
        setShowLeftScrollButton(hasMoreToScroll);
      } else if (direction === 'right') {
        const scrollTo = scrollView.scrollLeft + scrollLeft;
        const hasMoreToScroll = scrollView.scrollWidth - scrollTo > scrollView.clientWidth;
        scrollView.scrollTo({
          left: scrollTo,
          behavior: 'smooth',
        });
        setShowLeftScrollButton(true);
        setShowRightScrollButton(hasMoreToScroll);
      } else {
        const hasMoreToScroll = scrollView.scrollWidth > scrollView.clientWidth;
        scrollView.scrollTo({
          left: 0,
        });
        setShowLeftScrollButton(false);
        setShowRightScrollButton(hasMoreToScroll);
      }
    },
    [chartData.length, dataPoints.length, scrollView?.childElementCount, isRowComparison]
  );

  useEffect(() => {
    // Debounce the scroll event to avoid performance issues
    let timeout: ReturnType<typeof setTimeout>;
    handleControlledScroll('reset');

    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!scrollView) {
          return;
        }

        const scrollWidth = scrollView.scrollWidth;
        const clientWidth = scrollView.clientWidth;
        const scrollLeft = scrollView.scrollLeft;

        setShowLeftScrollButton(scrollLeft > 0);
        setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
      }, 100);
    };

    if (scrollView) {
      scrollView.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollView) {
        scrollView.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollView?.childElementCount, isRowComparison]);

  const handleCardClick = useCallback(
    (type: TaskType, locationName: string) => {
      const locationId = Object.keys(locations).find((key) => locations[key] === locationName);

      setFiltersToRestore(filters);

      navigate({
        to: `${URLs.CALL_INTEL_BASE}/follow-ups/${type.split(TASK_TYPE_PREFIX)[1]?.toLowerCase()}`,
        search: {
          locationId,
        },
      });
    },
    [navigate, filters]
  );

  return (
    <Chart isLoading={isLoading}>
      <Chart.Header
        actions={[
          {
            label: t('View All Follow-ups ({{totalFollowUps}})', {
              totalFollowUps: !isDemoAccount ? actualFollowUpCount?.totalFollowUps ?? 0 : totalFollowUps,
            }),
            onClick: () => {
              setFiltersToRestore(filters);
              navigate({
                to: `${URLs.CALL_INTEL_BASE}/follow-ups/all`,
              });
            },
            trackingId: trackingIds.callIntel.compareLocationsViewAllFollowUps,
          },
        ]}
        css={styles.header}
        leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
        subtitle={filterHintText}
        title={title}
      />

      <div css={styles.wrapper}>
        <NakedUl
          className={isRowComparison ? 'rows-comparison-ul' : 'columns-comparison-ul'}
          css={styles.ul}
          ref={ulRef}
          style={{ maxHeight: MAX_HEIGHT }}
        >
          {chartData.map(({ name: rowName, values: columnValues }) => (
            <li
              css={styles.row}
              className={isRowComparison ? 'rows-comparison-li' : 'columns-comparison-li'}
              key={rowName}
            >
              {!isMobile && (
                <div className='sticky-content'>
                  <Chart.LeaderboardRowLabel size='medium' value={rowName} weight='bold' />
                </div>
              )}

              {isRowComparison && isMobile && (
                <div className='row-comparison-label'>
                  <Chart.LeaderboardRowLabel size='medium' value={rowName} weight='bold' />
                </div>
              )}

              <div css={styles.dataCardsWrapper} className='data-cards-wrapper'>
                {Object.entries(columnValues).map(([key, value]) => (
                  <div className='card-space' key={key}>
                    {isMobile && !isRowComparison && (
                      <div className='col-comparison-label'>
                        <Chart.LeaderboardRowLabel size='medium' value={rowName} weight='bold' />
                      </div>
                    )}
                    <TaskLocationListCard
                      label={dataLabels?.taskTypes?.[key] ?? key}
                      locationName={rowName}
                      onCardClick={handleCardClick}
                      type={key as TaskType}
                      value={value ?? 0}
                    />
                  </div>
                ))}
              </div>
            </li>
          ))}
        </NakedUl>
        {showMore && (
          <TextLink
            className='toggle-more'
            onClick={handleShowMore}
            trackingId={trackingIds.callIntel.showMoreFollowUpListAcrossLocations}
            weight='bold'
          >
            {isShowingAllRows ? t('Show Less') : t('Show More')}
            <Icon name={isShowingAllRows ? 'caret-up-small' : 'caret-down-small'} />
          </TextLink>
        )}

        {isMobile && (
          <>
            <ScrollControl
              css={styles.scrollButton}
              direction='left'
              isVisible={showLeftScrollButton}
              onClick={() => handleControlledScroll('left')}
            />

            <ScrollControl
              css={styles.scrollButton}
              direction='right'
              isVisible={showRightScrollButton}
              onClick={() => handleControlledScroll('right')}
            />
          </>
        )}
      </div>
    </Chart>
  );
});

FollowUpReasonChart.displayName = 'FollowUpReasonChart';

const styles = {
  header: css`
    header {
      align-items: flex-start;
    }

    .title-wrapper {
      flex: 1 1 40%;
    }
    .actions-wrapper {
      flex-direction: row-reverse;
      flex: 1 1 50%;

      button {
        font-weight: ${theme.font.weight.bold};
      }
    }

    @media screen and (max-width: 620px) {
      header {
        gap: ${theme.spacing(1.5)};
      }

      .title-wrapper {
        flex: 1 1 100%;
      }

      .actions-wrapper {
        flex-direction: row;
        width: 100%;
        flex: 1 1 50%;

        > p {
          flex: 1 1 100%;
        }
      }
    }
  `,

  wrapper: css`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: ${theme.spacing(1.5)};
    position: relative;

    .toggle-more {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};
    }
  `,

  ul: css`
    overflow: auto;
    transition: max-height 0.3s ease;
    width: 100%;

    &.rows-comparison-ul {
      display: block;

      @media screen and (max-width: ${breakpoints.small.min}px) {
        display: flex;
        flex-direction: row;

        .rows-comparison-li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .row-comparison-label {
            padding: ${theme.spacing(1, 4, 2, 4)};
          }

          &:nth-of-type(even) {
            background-color: ${theme.colors.neutral5};
          }

          &:first-child {
            .data-cards-wrapper {
              padding-left: ${theme.spacing(1)};
            }

            .row-comparison-label {
              padding: ${theme.spacing(1, 2, 2, 2)};
            }
          }

          &:last-child {
            .data-cards-wrapper {
              padding-right: ${theme.spacing(1)};
            }
          }
        }

        .data-cards-wrapper {
          display: flex;
          flex-direction: column;
          width: calc(100vw - ${cardWidthBase}px);
          padding: ${theme.spacing(0, 3)};
        }

        .card-space {
          width: 100%;
          padding-bottom: 16px;
        }
      }
    }
  `,

  row: css`
    display: flex;
    position: relative;
    width: auto;

    &.rows-comparison-li {
      .card-space {
        padding-top: ${theme.spacing(1)};
      }

      &:nth-of-type(even) {
        .card-space,
        .sticky-content {
          background-color: ${theme.colors.neutral5};
        }

        .card-space:last-of-type {
          padding-right: ${theme.spacing(2)};
        }
      }
    }

    &.columns-comparison-li {
      .card-space {
        @media screen and (max-width: ${breakpoints.small.min}px) {
          height: 128px;
        }

        &:nth-of-type(odd) {
          background-color: ${theme.colors.neutral5};
        }
      }

      &:last-of-type {
        .card-space {
          height: 106px;
          @media screen and (max-width: ${breakpoints.small.min}px) {
            height: 144px;
          }
        }
      }

      @media screen and (max-width: ${breakpoints.medium.max}px) {
        width: max-content;
      }
    }

    .sticky-content {
      display: flex;
      align-items: center;
      padding: ${theme.spacing(2)};
      position: sticky;
      background-color: ${theme.colors.white};
      left: 0px;
      z-index: 1;
      width: 176px;

      > p {
        width: 144px;
      }
    }
  `,

  dataCardsWrapper: css`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .card-space {
      flex: 1;
      min-width: 134px;
      height: 90px;
      padding: ${theme.spacing(2, 1, 0, 1)};
    }

    .col-comparison-label {
      width: 132px;
      margin-bottom: ${theme.spacing(2)};
    }
  `,

  scrollButton: css`
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;
    height: auto;
    width: ${theme.spacing(3)};
    padding: ${theme.spacing(0)};
    box-shadow: ${theme.shadows.floating};

    &.left-scroll {
      left: 0px;
    }

    &.right-scroll {
      right: 0px;
    }
  `,

  taskLocationCardWrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    padding: ${theme.spacing(1)};
    background-color: ${theme.colors.white};
    min-width: 132px;
    width: auto;
    cursor: pointer;
  `,
};
