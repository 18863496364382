import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, ChipVariants, SortingValue, Table, TableColumnConfig, Tabs } from '@frontend/design-system';
import { featureFlags } from '../../../../feature-flags';
import { formatters } from '../../../../utils';
import { LocationChip } from '../../../location-chip';
import { TableActions } from '../../../table-actions';
import { UserCard } from '../../../user-card';
import { RoiPatient, useFetchRoiPatients } from '../../hooks';
import { Sorting } from '../../types';
import { useARRoiShallowStore } from '../hooks';

interface ARRoiTableProps {
  trackingIdBase?: string;
}

type Tab = 'opportunities' | 'all';

interface TablePanelProps extends ARRoiTableProps {
  tableInstanceId: string;
  tabType: Tab;
}

const TablePanel = ({ tableInstanceId, tabType, trackingIdBase = '' }: TablePanelProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useARRoiShallowStore('filters');
  const { accessibleLocationData } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);

  const [sorting, setSorting] = useState<Sorting>({
    order_by: 'desc',
    order_by_field: 'appointment_date',
  });

  const { hasNextPage, hasPreviousPage, isLoading, pageConfig, patients, setPageConfig } = useFetchRoiPatients({
    category: 'appointmentreminder',
    isDemoAccount,
    payload: {
      ...sorting,
      appointment_filters: {
        appointment_filter_flag: tabType === 'opportunities',
        appointment_status: {
          appointment_status: 'Confirmed',
          appointment_status_equality: false,
        },
      },
      end_date: filters?.endDate,
      location_id: filters?.locationIds,
      roi_type: 'MissedCallText',
      start_date: filters?.startDate,
    },
    queryKeyHelpers: [tabType],
    subCategory: 'getfutureappointments',
  });

  const handleSorting = useCallback((sortValues: SortingValue<string>[]) => {
    const { id, value } = sortValues[0] || {};
    if (id && value) {
      setSorting({
        order_by_field: id,
        order_by: value,
      });
    }
  }, []);

  const columnConfig: TableColumnConfig<RoiPatient>[] = useMemo(() => {
    return [
      {
        accessor: (patient) => patient,
        cellRenderer: ({ location_id, patient_id, patient_name = '' }: RoiPatient) => {
          const [firstName, ...rest] = patient_name.split(' ');
          return (
            <UserCard
              firstName={firstName}
              key={patient_id}
              lastName={rest.join(' ')}
              locationId={location_id}
              showOnlyName
              userId={patient_id}
            />
          );
        },
        disableColumnFilter: true,
        disableSortBy: true,
        Header: t('Patient Name'),
        id: 'patient_name',
        sticky: 'left',
        width: 200,
      },
      {
        accessor: ({ location_id }) => location_id,
        cellRenderer: (value: string) => {
          return value ? (
            <LocationChip locationName={accessibleLocationData?.[value]?.name || value} maxWidth={180} />
          ) : (
            '-'
          );
        },
        disableSortBy: true,
        Header: t('Location'),
        id: 'location_id',
        omit: filters?.locationIds?.length === 1,
        width: 220,
      },
      {
        accessor: ({ status }) => status,
        cellRenderer: (status: RoiPatient['status']) => {
          if (!status) {
            return '-';
          }

          let chipVarient: ChipVariants = 'primary';

          switch (status) {
            case 'Completed':
              chipVarient = 'seaweed';
              break;

            case 'Confirmed':
            case 'Scheduled':
              chipVarient = 'success';
              break;

            case 'Unconfirmed':
              chipVarient = 'critical';
              break;
          }

          return <Chip variant={chipVarient}>{status}</Chip>;
        },
        disableSortBy: true,
        Header: t('Appointment Status'),
        id: 'status',
        width: 180,
      },
      {
        accessor: ({ appts_completion_date }) =>
          appts_completion_date ? formatters.date.format(appts_completion_date) : '-',
        Header: t('Appointment Date'),
        id: 'appts_completion_date',
        width: 180,
      },
      {
        accessor: ({ phone_number }) => (phone_number ? formatPhoneNumber(phone_number) : '-'),
        disableSortBy: true,
        Header: t('Patients Number'),
        id: 'phone_number',
        width: 160,
      },
      {
        accessor: ({ appointment_type }) => appointment_type,
        disableSortBy: true,
        Header: t('Contact Type'),
        id: 'appointment_type',
        width: 160,
      },
      {
        accessor: ({ patient_id, patient_name, phone_number }) => ({
          patient_id,
          patient_name,
          phone_number,
        }),
        cellRenderer: ({ patient_id, patient_name, phone_number }) => {
          return (
            <TableActions
              personId={patient_id}
              personName={patient_name}
              phoneNumber={phone_number}
              trackingIdBase={`${trackingIdBase}-${tabType}`}
            />
          );
        },
        cellAlign: 'center',
        disableSortBy: true,
        Header: '',
        id: 'actions',
        sticky: 'right',
        width: 120,
      },
    ];
  }, [accessibleLocationData]);

  return (
    <Table
      colConfig={columnConfig}
      data={patients}
      disableMultiSort
      hasResizeColumns
      hasResponsiveColWidths
      isLoading={isLoading}
      isPaginated
      manualPaginationConfig={{
        handleChange: (action: 'next' | 'prev') => {
          if (action === 'next') {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
          } else {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
          }
        },
        hasNext: hasNextPage,
        hasPrevious: hasPreviousPage,
        page: pageConfig.pageNumber,
        defaultRowsPerPage: pageConfig.pageSize,
        onNumRowsChange: (num) => {
          setPageConfig({ pageNumber: 1, pageSize: num });
        },
        rowsPerPageOptions: [10, 25, 50, 100],
      }}
      manualSortBy
      onSortChange={handleSorting}
      tableInstanceId={tableInstanceId}
    />
  );
};

export const ARRoiTable = (props: ARRoiTableProps) => {
  const { t } = useTranslation('analytics');
  const [activeTab, setActiveTab] = useState<Tab>('opportunities');

  return (
    <div>
      <Tabs initialTab={activeTab} onChange={(id: string) => setActiveTab(id as Tab)}>
        <Tabs.Bar css={{ marginBottom: theme.spacing(2) }}>
          <Tabs.Tab id='opportunities' key='opportunities' trackingId={`${props.trackingIdBase}-opportunities`}>
            {t('Opportunities')}
          </Tabs.Tab>
          <Tabs.Tab id='all' key='all' trackingId={`${props.trackingIdBase}-all`}>
            {t('All')}
          </Tabs.Tab>
        </Tabs.Bar>

        <Tabs.Panel controller='opportunities'>
          <TablePanel {...props} tableInstanceId='ar-roi-opportunities-patients' tabType='opportunities' />
        </Tabs.Panel>
        <Tabs.Panel controller='all'>
          <TablePanel {...props} tableInstanceId='ar-roi-all-patients' tabType='all' />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
