import * as TaskCenter from '@weave/schema-gen-ts/dist/schemas/task-center/v1/task_service.pb';
import {
  CreateTasksRequest,
  DeleteTaskRequest,
  UpdateTaskRequest,
} from '@weave/schema-gen-ts/dist/schemas/task-center/v1/task_service.pb';
import { useMutation } from 'react-query';
import { bindHTTP } from '@frontend/schema';

const TaskCenterService = bindHTTP(TaskCenter.TaskService);

export const useTaskCenterMutations = () => {
  const taskCreateMutation = useMutation((params: CreateTasksRequest) => TaskCenterService.CreateTasks(params));

  const taskUpdateMutation = useMutation((params: UpdateTaskRequest) => TaskCenterService.UpdateTask(params));

  const taskDeleteMutation = useMutation((params: DeleteTaskRequest) => TaskCenterService.DeleteTask(params));

  return {
    taskCreateMutation,
    taskDeleteMutation,
    taskUpdateMutation,
  };
};
