import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetPMSMedicalConditionsIO } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

/**
 * @param companyId - AKA locationId
 * @param sourceTenantId
 */
export const useGetPMSMedicalConditions = <E = unknown, T = GetPMSMedicalConditionsIO['output']>(
  req: Compulsory<GetPMSMedicalConditionsIO['input'], 'sourceId' | 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetPMSMedicalConditionsIO, E, T>
) => {
  const queryKey = requestKeys.medicalConditions(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetPMSMedicalConditions(req),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Async version of `useGetPMSMedicalConditions`.
 */
export const useGetPMSMedicalConditionsAsync = <E = unknown, T = GetPMSMedicalConditionsIO['output']>() => {
  const queryClient = useQueryClient();
  const fetchMedicalConditions = useCallback(
    async (
      req: Compulsory<GetPMSMedicalConditionsIO['input'], 'sourceId' | 'companyId'>,
      options?: LimitedSchemaQueryOptions<GetPMSMedicalConditionsIO, E, T>
    ) => {
      try {
        const response = await queryClient.fetchQuery(
          requestKeys.medicalConditions(req),
          () => SchemaDigitalForms.GetPMSMedicalConditions(req),
          {
            ...defaultOptions,
            retry: false,
            ...options,
          }
        );
        return response;
      } catch (error) {
        console.error('Error fetching medical conditions', error);
        return undefined;
      }
    },
    [queryClient]
  );

  return {
    fetchMedicalConditions,
  };
};
