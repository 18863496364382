import { memo, useCallback, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { EmojiClickData, SkinTones } from 'emoji-picker-react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, SpinningLoader, Text, useTooltip } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';

interface Props {
  isLoading?: boolean;
  onClickReaction: (reaction: EmojiClickData) => void;
  reaction: TeamChatTypes.ReactionGroup;
  wrapperStyle?: Interpolation<Theme>;
}

const parseEmoji = (name: string): string => {
  if (typeof name !== 'string') return '';

  return name
    .split('-')
    .map((hex) => String.fromCodePoint(parseInt(hex, 16)))
    .join('');
};

const formatUserNames = (users: string[]): string => {
  if (users.length === 0) return '';

  // Return the first user if there is only one
  if (users.length === 1) return users[0];

  // Place 'and' between the last two users
  if (users.length === 2) return users.join(' and ');

  // Place a comma between all users except the last two
  return users.slice(0, -1).join(', ') + ', and ' + users.slice(-1);
};

export const ReactionButton = memo(({ onClickReaction, reaction, wrapperStyle, isLoading }: Props) => {
  const { t } = useTranslation('team-chat');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);

  const handleClickReaction = useCallback(() => {
    onClickReaction({
      activeSkinTone: SkinTones.NEUTRAL,
      emoji: reaction.name,
      getImageUrl: () => '',
      names: [],
      unified: reaction.name,
      unifiedWithoutSkinTone: reaction.name,
    });
  }, [onClickReaction, reaction.name]);

  const names = useMemo(() => {
    return reaction.usersIds
      .map((userId) => {
        const user = helpers.getUser(userId);
        if (!user) {
          return undefined;
        }
        return getUserFullName(user?.firstName, user?.lastName);
      })
      .filter((name): name is NonNullable<typeof name> => !!name);
  }, [helpers.getUser, reaction.usersIds.length]);

  const isDisabled = isLoading;

  return (
    <>
      <NakedButton
        {...triggerProps}
        disabled={isDisabled}
        css={[wrapperStyle, styles.button]}
        onClick={handleClickReaction}
        trackingId={
          reaction.hasOwnReaction
            ? 'team-chat-2.0-emoji-remove-existing-reaction-button'
            : 'team-chat-2.0-emoji-add-existing-reaction-button'
        }
      >
        {isLoading ? (
          <SpinningLoader size='xs' />
        ) : (
          <>
            <Text>{parseEmoji(reaction.name)}</Text>
            <Text color='subdued' size='small'>
              {reaction.count}
            </Text>
          </>
        )}
      </NakedButton>

      <Tooltip {...tooltipProps} css={styles.tooltip}>
        <figure className='emoji'>{parseEmoji(reaction.name)}</figure>
        <Trans t={t}>
          <Text color='white' textAlign='center'>
            {formatUserNames(names)}{' '}
            <Text as='span' color='subdued'>
              reacted with
            </Text>
          </Text>
        </Trans>
        <Text as='span' color='subdued'>
          :{reaction.name}:
        </Text>
      </Tooltip>
    </>
  );
});

ReactionButton.displayName = 'ReactionButton';

const styles = {
  button: css`
    color: ${theme.colors.neutral50};
    font-size: ${theme.fontSize(13)};
    line-height: 1;
    padding: ${theme.spacing(0, 1)};
    gap: ${theme.spacing(1)};
    display: flex;
    align-items: center;
  `,

  tooltip: css`
    align-items: center;
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: column;
    min-width: 140px;
    padding: ${theme.spacing(1, 2)};

    .emoji {
      align-items: center;
      background-color: ${theme.colors.white};
      border-radius: ${theme.borderRadius.medium};
      display: flex;
      font-size: ${theme.fontSize(30)};
      height: ${theme.spacing(6)};
      justify-content: center;
      width: ${theme.spacing(6)};
    }
  `,
};
