import { MouseEventHandler, useState } from 'react';
import { Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { useTranslation } from '@frontend/i18n';
import { ThreadSendingAreaComponents } from '@frontend/thread-sending-area';
import { theme } from '@frontend/theme';
import {
  Button,
  Heading,
  Modal,
  MultiActionButton,
  SecondaryButton,
  useAlert,
  useTooltip,
} from '@frontend/design-system';
import { TrackingPrefixes } from '../../../tracking-prefixes';
import { useSendInThreadState } from '../../hooks';
import { OnThreadChangeFn, SendInThreadTemplateData } from '../../types';
import { TemplateSelector } from '../template-selector';
import { SendInThreadView } from './send-in-thread-view';

type BaseProps = {
  groupId: string;
  initThreadId?: string;
  onBack: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonProps?: Parameters<typeof SecondaryButton>[0];
  onThreadChange?: OnThreadChangeFn;
  hideSelectTemplateButton?: boolean;
  onClose?: () => void;
  onOpenSettings?: () => void;
} & Pick<Parameters<typeof useSendInThreadState>[0], 'onSend' | 'onSendError' | 'onSchedule' | 'onScheduleError'>;
export type RecipientData =
  | {
      personId?: string;
      initPersonPhone: string;
    }
  | {
      personId: string;
      initPersonPhone?: string;
    };

export type SendInThreadModalContentProps = BaseProps & RecipientData & SendInThreadTemplateData;

/**
 * @param groupId - Also known as `locationId`. This restricts the modal to one location, so the location must be
 * selected or decided before this modal is used.
 * @param onBack - A callback function for the "Back" button on the modal. This is useful to take the user back to the
 * previous step in the flow, rather than closing the flow entirely.
 * @param initThreadId (optional) - When provided, this thread will be loaded, instead of the default thread. By
 * default, the last updated thread that fits the constraints of provided `personId` and `initPersonPhone` will be used.
 * @param secondaryButtonProps (optional) - If provided, a secondary button will be rendered to the left of the "Send"
 * button.
 * @param onThreadChange (optional) - A callback function that will be called when the thread changes. This is useful
 * for situations where the provided link or other template context needs to be updated based on the thread.
 * @param personId (required if `initPersonPhone` is not provided, otherwise is optional) - The person the user has
 * selected (or context has defined) to send the message to. It's probably a good idea to use the
 * `PersonSelectorComponents.SelectRecipientModal` to have the user select a `person` if there is not enough context
 * to determine the `personId` automatically.
 * @param initPersonPhone (required if `personId` is not provided, otherwise is optional) - The person's phone number
 * to interact with.
 * @param templateType - Should be used by features outside of the inbox to define the type of templates the user can
 * select. This should be a `TemplateType_Slug` enum value from `schema-gen-ts` that correlates to the feature where
 * this modal is being used. Only values from the `TemplateType_Slug` enum that are associated with a manual template
 * can be used. Feel free to update the [`ManualTemplateTypes` type](./types.ts) to add other template types. Be sure
 * to run a typecheck afterwards to ensure that you have updated anything affected by that change.
 * Example values:
 * - Payments: `TemplateType_Slug.MANUAL_PAYMENT_REQUEST`
 * - Forms: `TemplateType_Slug.MANUAL_FORMS_MESSAGE`
 * - Reviews: `TemplateType_Slug.MANUAL_REVIEW_REQUEST`
 * @param linkData (optional) - If there is a link associated with the template/message the user is going to be
 * sending, such as a form link, or review invitation link, it should be provided in this prop.
 * @param linkData.link (required) - The link to be rendered in the template according to the mapping of `templateType`
 * to `DynamicFieldProperty_Enum` in the [TEMPLATE_TYPE_LINK_MAP object](./constants.ts)
 * @param linkData.relatedId (required) - An id associated with the link. For example if providing a review invitation
 * link, the review invitation id should be provided here. This is used to populate the `relatedIds` portion of the
 * payload when sending or scheduling a message, and keeps analytics accurate. The `RelatedType` associated with this
 * id is defined in the [TEMPLATE_TYPE_LINK_MAP object](./constants.ts).
 * @param contextPreviewProps (required if `templateType` is provided) - This defines the content displayed in the
 * header of the `SendInThreadModal`.
 * @param contextPreviewProps.iconName (required) - Name of the icon to be displayed.
 * @param contextPreviewProps.title (required) - A `ReactNode` to be rendered as the title. This should mainly be text.
 * @param contextPreviewProps.subtitle (required) - A `ReactNode` to be rendered as the title. This should mainly be
 * text.
 * @param initialBody (optional) - Cannot be used if using templates. If provided, this will be the initial body of the message.
 * @param initialMediaIds (optional) - If provided, these mediaIds will be used to populate the media section of the message.
 * @param hideSelectTemplateButton (optional) - If true, the "Select a Template" button will not be rendered. This is useful
 * for features that do not use templates.
 * @param onClose (optional) - The `onClick` to be passed into the modal header component.
 * @param onSend, onSendError, onSchedule, onScheduleError - see [useSendInThreadState](../../hooks/use-send-in-thread-state.ts) for more details on these props
 * @param onOpenSettings (optional) - A callback function to be called when opening the settings for the template creation flow.
 * @param propertyBindings (optional) - A map of property bindings to be used in the template. If any additional data
 * is needed to render the template, it should be provided here.
 */
export const SendInThreadModalContents = ({
  groupId,
  initThreadId,
  initPersonPhone,
  personId,
  onBack,
  secondaryButtonProps,
  templateType,
  contextPreviewProps,
  linkData,
  onThreadChange,
  initialBody,
  initialMediaIds,
  hideSelectTemplateButton,
  onClose,
  onSend,
  onSendError,
  onSchedule,
  onScheduleError,
  onOpenSettings,
  propertyBindings,
}: SendInThreadModalContentProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const [showTemplateSelection, setShowTemplateSelection] = useState(false);

  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  const { contentProps, sendMessage, scheduleMessage, setDraftToRenderedTemplate } = useSendInThreadState({
    groupId,
    initThreadId,
    sendToCustomPhoneNumber: true, // To allow sending message to custom number
    initPersonPhone: initPersonPhone ?? '',
    personId: personId ?? '',
    templateType,
    linkData,
    onThreadChange,
    initialBody,
    initialMediaIds,
    onSend: (...args) => {
      alert.success(t('Message sent successfully.'));
      onSend?.(...args);
    },
    onSendError: (...args) => {
      alert.error(t('Error sending message. Please try again.'));
      onSendError?.(...args);
    },
    onSchedule: (...args) => {
      alert.success(t('Message scheduled successfully.'));
      onSchedule?.(...args);
    },
    onScheduleError: (...args) => {
      alert.error(t('Error scheduling message. Please try again.'));
      onScheduleError?.(...args);
    },
    propertyBindings,
  });

  // threadContext can have a custom phone number different from the context of the modal state
  const threadContextPhone = contentProps.selectedPersonPhone;

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Share in Message')}</Modal.Header>
      <Modal.Body css={{ margin: theme.spacing(0) }}>
        {showTemplateSelection ? (
          <div
            css={{
              borderRadius: theme.borderRadius.medium,
              border: `1px solid ${theme.colors.neutral20}`,
              marginTop: theme.spacing(2),
              overflowY: 'hidden',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(2, 1),
                gap: theme.spacing(2),
                borderBottom: `1px solid ${theme.colors.neutral20}`,
              }}
            >
              <Button
                iconName='back'
                variant='secondary'
                onClick={() => {
                  setShowTemplateSelection(false);
                }}
                aria-label={t('Back')}
              />

              <Heading level={3}>{t('Select a Template')}</Heading>
            </div>
            <TemplateSelector
              groupIds={[groupId]}
              templateTypes={templateType ? [templateType] : []}
              css={{
                padding: theme.spacing(2),
                height: `calc(100% - ${theme.spacing(7)})`,
                overflowY: 'hidden',
              }}
              onOpenSettings={onOpenSettings}
              onSelectTemplate={(template: Template) => {
                setDraftToRenderedTemplate(template);
                setShowTemplateSelection(false);
              }}
            />
          </div>
        ) : (
          <SendInThreadView
            {...contentProps}
            hideSuperTextAreaSendButton
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: theme.borderRadius.medium,
              border: `1px solid ${theme.colors.neutral20}`,
            }}
            bodyAndSendAreaContainerProps={{
              css: { flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' },
            }}
            onSelectTemplateClick={() => {
              setShowTemplateSelection(true);
            }}
            contextPreviewProps={contextPreviewProps}
            hideSelectTemplateButton={hideSelectTemplateButton}
          />
        )}
      </Modal.Body>
      {showTemplateSelection ? null : (
        <Modal.Footer>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button variant='tertiary' onClick={onBack}>
              {t('Back')}
            </Button>
            <div
              css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}
              {...(!threadContextPhone && triggerProps)}
            >
              {secondaryButtonProps && <SecondaryButton {...secondaryButtonProps} />}
              <MultiActionButton
                actionType='dialog'
                Dialog={
                  <ThreadSendingAreaComponents.ScheduleMessagePopoverContents
                    onSendNow={sendMessage}
                    disableForm={
                      !contentProps.bodyValue || contentProps.isUnknownContactThread || contentProps.optedOut
                    }
                    onSchedule={({ sendAt, pausable }) => {
                      scheduleMessage(sendAt, pausable);
                    }}
                  />
                }
                dialogProps={{
                  css: {
                    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
                    borderRadius: theme.borderRadius.small,
                  },
                }}
                label={t('Send Now')}
                onClick={sendMessage}
                size='small'
                disabled={
                  (!contentProps.bodyValue && contentProps.threadMedia.length === 0) ||
                  !threadContextPhone ||
                  contentProps.optedOut
                }
                css={{ whiteSpace: 'nowrap' }}
                trackingId={`${TrackingPrefixes.SendInThread}-send-button`}
              >
                {t('Send Now')}
              </MultiActionButton>
              {!threadContextPhone && (
                <Tooltip css={{ textAlign: 'center' }} {...tooltipProps}>
                  {t('Input a valid phone number before sending')}
                </Tooltip>
              )}
            </div>
          </div>
        </Modal.Footer>
      )}
    </>
  );
};
