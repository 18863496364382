import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import { TagsUtils } from '@frontend/api-tag';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { Chip, Text, TextLink } from '@frontend/design-system';
import { recentCallsTrackingId } from '../../../pendo-tracking';
import { CachedAudioScrubber } from '../../all-calls/cached-audio-scrubber';
import { RecentCallRecord } from '../../all-calls/types';
import { RecentCallsTagSelector } from '../recent-calls-tag-selector';
import { getOfficeUser, getStatusChipColor } from '../utils';

export const ContactActions = ({
  contact,
  locationId,
}: {
  contact: { name: string; id: string; number: string };
  locationId: string;
}) => {
  const { t } = useTranslation('calls');
  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        gap: ${theme.spacing(1)};
      `}
    >
      <AdaptoActions enableCloseEvent iconColor='inherit' variant='icon-buttons' css={{ display: 'flex' }}>
        <Actions.Call
          label={t(`Call ${contact.name}`)}
          context={{ personId: contact.id, phoneNumber: contact.number, locationId }}
          trackingIdSuffix={recentCallsTrackingId({ context: 'panel::call-contact' })}
        />
        <Actions.Message
          label={t(`Text ${contact.name}`)}
          context={{ personId: contact.id, phoneNumber: contact.number, locationId }}
          trackingIdSuffix={recentCallsTrackingId({ context: 'panel::message-contact' })}
        />
      </AdaptoActions>
    </section>
  );
};

export const CallRecordingSection = ({
  row,
  callRecordingPath,
}: {
  row: RecentCallRecord;
  callRecordingPath: string;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), padding: theme.spacing(1) }}>
      <CachedAudioScrubber filePath={callRecordingPath} mediaId={row?.channelId || ''} />
    </div>
  );
};

const ListRow = ({ label, children }: { label: string; children?: React.ReactNode }) => {
  return (
    <li
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
      }}
    >
      <Text size='large' css={{ color: theme.colors.neutral70 }}>
        {label}
      </Text>
      {children}
    </li>
  );
};

export const CallDetailsSection = ({ row, locationName }: { row: RecentCallRecord; locationName: string }) => {
  const { t } = useTranslation('calls');
  const { startedAt, direction, status = '', locationId, callerNumber, dialedNumber } = row;
  const statusChipColor = getStatusChipColor(status);
  return (
    <ul style={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(1) }}>
      <ListRow label={t('Time')}>
        <Text size='large'>{dayjs(startedAt).format('MMM DD YYYY, hh:mm A')}</Text>
      </ListRow>

      <ListRow label={t('Result')}>
        {direction === 'outbound' && <span>{'--'}</span>}
        {direction === 'inbound' && (
          <Chip isResponsive variant={statusChipColor}>
            {startCase(status)}
          </Chip>
        )}
      </ListRow>

      <ListRow label={t('Location')}>
        {!!locationId && <Chip.Location>{locationName}</Chip.Location>}
        {!locationId && <span>{'--'}</span>}
      </ListRow>

      <ListRow label={t('Office User')}>
        <Text size='large'>{getOfficeUser(row)}</Text>
      </ListRow>

      <ListRow label={t('Office Number')}>
        <Text size='large'>{formatPhoneNumber(direction === 'outbound' ? callerNumber : dialedNumber)}</Text>
      </ListRow>
    </ul>
  );
};

export const TagsSection = ({
  tags,
  onApplyTag,
  onRemoveTag,
}: {
  tags: Tag[];
  onApplyTag?: (tag: Tag) => void;
  onRemoveTag?: (tag: Tag) => void;
}) => {
  const { t } = useTranslation('calls');
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div style={{ display: 'flex', gap: theme.spacing(2), flexDirection: 'column' }}>
      {tags.length === 0 && isEditing && (
        <div style={{ padding: theme.spacing(1) }}>
          <RecentCallsTagSelector
            onClose={() => {
              setIsEditing(false);
            }}
            onSelect={() => {
              setIsEditing(false);
            }}
            onApplyTag={onApplyTag}
            existingTagIds={tags.map((t) => t.id)}
          />
        </div>
      )}
      {tags.length === 0 && !isEditing && <Text color='subdued'>{t('No tags added')}</Text>}
      {tags.length > 0 && (
        <div style={{ display: 'flex', gap: theme.spacing(1), padding: theme.spacing(1), flexWrap: 'wrap' }}>
          {tags.length > 0 &&
            tags.map((tag) => (
              <Chip.Tag
                isRemovable
                onRemoveClick={() => {
                  onRemoveTag?.(tag);
                }}
                key={tag.id}
                color={TagsUtils.convertStringToTagColor(tag.color)}
              >
                {tag.name}
              </Chip.Tag>
            ))}
          {isEditing && (
            <RecentCallsTagSelector
              onClose={() => {
                setIsEditing(false);
              }}
              onSelect={() => {
                setIsEditing(false);
              }}
              existingTagIds={tags.map((t) => t.id)}
              onApplyTag={onApplyTag}
            />
          )}
        </div>
      )}
      <TextLink
        disabled={isEditing}
        weight='bold'
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
        onClick={() => {
          setIsEditing(true);
        }}
        trackingId={recentCallsTrackingId({ context: 'panel::add-tag' })}
      >
        <Icon name='label' />
        {t('Add Tag')}
      </TextLink>
    </div>
  );
};
