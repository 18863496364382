// NOTE: For some trackingIds, we kept old string to preserve historical data on pendo.
// For new trackingIds, we used the new format which use colon as separator.
export const QUICK_FILL_TRACKING_IDS = {
  sideActionBarButton: 'schedule-quickfill-event-panel-icon-button',
  sidePanel: {
    sendMessageTabButton: 'schedule-quickfill-send-message-tab-button',
    manageListTabButton: 'schedule-quickfill-manage-list-tab-button',
  },
  sendMessagesTab: {
    locationSelectionStep: {
      stepCard: 'schedule-quickfill-send-message-location-selector-stepper-card',
      locationDropdown: 'schedule-quickfill-send-message-location-selector-dropdown',
      nextButton: 'schedule-quickfill-send-message-location-selector-next-button',
    },
    dateTimeSelectionStep: {
      stepCard: 'schedule-quickfill-send-message-date-selector-stepper-card',
      dateField: 'schedule-quickfill-send-message-date-selector-date-field',
      timeRangeField: 'schedule-quickfill-send-message-date-selector-time-range-field',
      nextButton: 'schedule-quickfill-send-message-date-selector-next-button',
    },
    selectRecipientStep: {
      stepCard: 'schedule-quickfill-send-message-select-recipients-stepper-card',
      searchField: 'schedule-quick-fill:send-messages-tab:select-recipient-step:search-field',
      sortByDropdown: 'schedule-quick-fill:send-messages-tab:select-recipient-step:sort-by-dropdown',
      selectAllCheckbox: 'schedule-quick-fill:send-messages-tab:select-recipient-step:select-all-checkbox',
      recipientCheckbox: 'schedule-quick-fill:send-messages-tab:select-recipient-step:recipient-checkbox',
      maxSelectionOverlay: 'schedule-quick-fill:send-messages-tab:select-recipient-step:max-selection-overlay',
      maxSelectionBanner: 'schedule-quick-fill:send-messages-tab:select-recipient-step:max-selection-banner',
      nextButton: 'schedule-quickfill-send-message-select-recipients-next-button',
    },
    composeMessageStep: {
      stepCard: 'schedule-quickfill-send-message-compose-message-stepper-card',
      messageField: 'schedule-quick-fill:send-messages-tab:compose-message-step:message-field',
      sendButton: 'schedule-quickfill-send-message-compose-message-next-button',
      openTemplateSelectionButton:
        'schedule-quick-fill:send-messages-tab:compose-message-step:open-template-selection-button',
    },
  },
  manageListTab: {
    searchField: 'schedule-quick-fill:manage-list-tab:search-field',
    addToListButton: 'schedule-quick-fill:manage-list-tab:add-to-list-button',
    removeRecipientButton: 'schedule-quickfill-manage-list-remove-quickfill-recipient-button',
    recipientAddNote: 'schedule-quick-fill:manage-list-tab:recipient-add-note',
    recipientEditNote: 'schedule-quick-fill:manage-list-tab:recipient-edit-note',
    recipientAvatar: 'schedule-quick-fill:manage-list-tab:recipient-avatar',
  },
  historyPage: {
    startQuickFillButton: 'schedule-quick-fill:history-page:start-quick-fill-button',
    searchField: 'schedule-quickfill-history-table-search-field',
    clearAllLocationSelectionButton: 'schedule-quickfill-history-table-location-selector-clear-all-btn',
    locationDropdownOption: 'schedule-quick-fill:history-page:location-dropdown-option',
    locationDropdown: 'schedule-quickfill-history-table-location-selector',
  },
};
