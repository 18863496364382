import { UseQueryOptions } from 'react-query';
import { HttpError } from '@frontend/fetch';
import { useQuery } from '@frontend/react-query-helpers';
import { fetchSyncAppAuthCheck } from './api';
import { SyncAppAuthCheckData, SyncAppAuthCheckRequest } from './types';

const BASE_QUERY_KEY = 'syncApp';

export const SyncAppQueryKeys = {
  syncAppAuthCheck: (request: SyncAppAuthCheckRequest) => [BASE_QUERY_KEY, 'authCheck', request.locationId],
};

export function useSyncAppAuthCheck<TData = SyncAppAuthCheckData>(
  request: SyncAppAuthCheckRequest,
  options?: UseQueryOptions<SyncAppAuthCheckData, HttpError, TData>
) {
  return useQuery({
    queryKey: SyncAppQueryKeys.syncAppAuthCheck(request),
    queryFn: () => fetchSyncAppAuthCheck(request),
    retry: false,
    ...options,
    enabled: !!request.locationId && (options?.enabled ?? true),
  });
}
