import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../../utils';
import { StatItem, StatItemData } from '../../components';
import { REVENUE_MULTIPLIER } from '../../constants';
import { MessagingOverview, MessagingRoi, RoiBaseFilters } from '../../types';
import { AppointmentScheduledChart, MessagesSentChart, RevenueChart } from './charts';

interface MessagingSubViewProps {
  dateRangeHelperText?: string;
  filters?: RoiBaseFilters;
  isDemoAccount?: boolean;
  isLoading?: boolean;
  overviewData: MessagingOverview;
  subViewUrl: string;
}

export const MessagingSingleSubView = ({
  dateRangeHelperText,
  filters,
  isDemoAccount,
  isLoading,
  overviewData,
  subViewUrl,
}: MessagingSubViewProps) => {
  const { t } = useTranslation('analytics');
  const search = useSearch<{ Search: { view: MessagingRoi } }>();

  const stats: StatItemData[] = useMemo(() => {
    switch (search.view) {
      case 'leadsContacted':
        return [
          {
            label: t('Messages Sent'),
            value: formatters.value.format(overviewData.leadsContacted.messagesSent),
          },
          {
            label: t('Leads Contacted'),
            value: formatters.value.format(overviewData.leadsContacted.totalPatients),
          },
        ];

      case 'patientsScheduled':
        return [
          {
            label: t('Appointments Scheduled'),
            value: formatters.value.format(overviewData.patientsScheduled.appointmentsScheduledCount),
          },
          {
            label: t('Patients'),
            value: formatters.value.format(overviewData.patientsScheduled.totalPatients),
          },
        ];

      case 'revenueGenerated':
        return [
          {
            label: t('Revenue Generated'),
            value: formatters.currency.shortenNumber(
              overviewData.revenueGenerated.totalCompletedAppts * REVENUE_MULTIPLIER
            ),
          },
          {
            label: t('Appointments Completed'),
            value: formatters.value.format(overviewData.revenueGenerated.totalCompletedAppts),
          },
        ];
    }

    return [];
  }, [overviewData, search.view]);

  const commonProps = {
    dateRangeHelperText,
    filteredLocationIds: filters?.locationIds ?? [],
    isDemoAccount,
    isLoading,
    subViewUrl,
  };

  return (
    <div css={styles.chartContainer}>
      <div css={styles.stats}>
        {stats.map((props) => (
          <StatItem {...props} key={props.label} subAlignment='bottom' valueFontSize={theme.fontSize(66)} />
        ))}
      </div>

      <div>
        {search.view === 'leadsContacted' ? (
          <>
            <Text css={styles.analysisTitle} size='large' textAlign='center'>
              {t('Leads Contacted Analysis')}
            </Text>
            <MessagesSentChart {...commonProps} data={overviewData.leadsContacted} />
          </>
        ) : search.view === 'patientsScheduled' ? (
          <>
            <Text css={styles.analysisTitle} size='large' textAlign='center'>
              {t('Patients Appointments Scheduled Analysis')}
            </Text>
            <AppointmentScheduledChart {...commonProps} data={overviewData.patientsScheduled} isLoading={isLoading} />
          </>
        ) : (
          <>
            <Text css={styles.analysisTitle} size='large' textAlign='center'>
              {t('Revenue Generated Analysis')}
            </Text>
            <RevenueChart {...commonProps} data={overviewData.revenueGenerated} isLoading={isLoading} />
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  chartContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(10)};
    justify-content: space-evenly;
  `,

  stats: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(1)};
    max-width: 400px;
    width: 100%;

    @container (min-width:  ${breakpoints.medium.min}px) {
      width: 50%;
    }
  `,

  analysisTitle: css`
    font-style: italic;
    margin-bottom: ${theme.spacing(2)};
  `,
};
