import { BulkMessageHooks } from '@frontend/bulk-message-service';
import { useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../feature-flags';

export const useShowBulkMessageButton = (): boolean => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isFFEnabled = getFeatureFlagValue(featureFlags.paBulkMessaging);
  const hasBulkMessaging = BulkMessageHooks.useHasBulkTexting();

  return isFFEnabled && hasBulkMessaging;
};
