import { LifecycleHookProps, LifecycleHookState } from '../collect-payment-multi';

export const LIFECYCLE_HOOKS: LifecycleHookState = {
  allMerchantsLoaded: {
    noPayments: true,
  },
};

const allValuesToFalse = <Obj extends Record<string, boolean>>(obj: Obj): Obj => {
  return Object.keys(obj).reduce((acc, key) => {
    return { ...acc, [key]: false };
  }, {} as Obj);
};
export const makeLifecycleHooks = (props: LifecycleHookProps = {}) => {
  const keys = Object.keys(LIFECYCLE_HOOKS) as (keyof LifecycleHookState)[];

  return keys.reduce((acc, key) => {
    const defaultValues = LIFECYCLE_HOOKS[key];
    if (props[key] === false) {
      acc[key] = allValuesToFalse(defaultValues);
    } else if (props[key]) {
      acc[key] = { ...defaultValues, ...props[key] };
    } else {
      acc[key] = defaultValues;
    }

    return acc;
  }, {} as LifecycleHookState);
};
