import { useCallback } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { PrintDialog } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, PrintIcon, Table, UpdateIcon, useAlert } from '@frontend/design-system';
import { FailedTransactionsFilter } from '..';
import {
  useGenerateColumns,
  useCanDoAction,
  UseFailedTransactionsFiltersMethods,
  useQueryPaginatedFailedInvoices,
  useQueryAllFailedInvoices,
} from '../../../hooks';
import {
  SearchFailedPaymentsModel,
  SearchFailedPaymentsOrder,
  SearchFailedPaymentsParams,
} from '../../../hooks/payment-failed/types';
import { PaymentsTableInstances } from '../../../utils';
import { PrintHeader } from '../../PrintHeader';
import { useCsvExport } from '../CSVExport';
import { generateColumns } from './generate-columns';

const FIELD_TO_ORDER: { [key: string]: SearchFailedPaymentsOrder } = {
  submittedAt: 'submittedAt',
  customerName: 'customerName',
};

export type FailedListProps = {
  handlePrint: () => void;
  filter: SearchFailedPaymentsParams;
  currentPage: number;
  filterMethods: UseFailedTransactionsFiltersMethods;
  onChangeFilter?: (filter: SearchFailedPaymentsParams) => void;
};

export const FailedList = ({
  filter,
  currentPage: initialpage,
  handlePrint,
  filterMethods: { setOrder, setNumRows, setCurrentPage, setFilter },
  onChangeFilter,
}: FailedListProps) => {
  const { t } = useTranslation('payments');
  const navigate = useNavigate();
  const alert = useAlert();
  const { canExport } = useCanDoAction();
  const { selectedLocationIds } = useAppScopeStore();

  const columns = useGenerateColumns<SearchFailedPaymentsModel>(generateColumns, false, PaymentsTableInstances.Failed, {
    hideLocationNameColumn: selectedLocationIds.length === 1,
  });

  const {
    transactions,
    refetch,
    loading,
    hasMore,
    hasPrevious,
    fetchNextPage,
    fetchPreviousPage,
    currentPage,
    numRows,
  } = useQueryPaginatedFailedInvoices({
    currentPage: initialpage,
    onChangePage: setCurrentPage,
    searchParams: filter,
    onResetPage: () => setCurrentPage(1),
  });

  const { ExportAction, ExportFailedTransactionsModal } = useCsvExport({
    filter,
    currentPage,
  });

  const handlePrintClick = () => {
    if (!transactions.length) {
      alert.error('No data to print. Apply a filter and try again.');
    } else {
      handlePrint();
    }
  };

  const handleApplyFilter = useCallback(
    (newFilter: SearchFailedPaymentsParams) => {
      setFilter({ ...filter, ...newFilter });
      onChangeFilter?.({ ...filter, ...newFilter });
      setCurrentPage(1);
    },
    [filter]
  );

  return (
    <>
      <Table
        tableStyle={css`
          min-height: 500px;
          height: 100%;
        `}
        globalTrackingId='nwx:failed-transactions'
        colConfig={columns}
        data={transactions}
        tableInstanceId={PaymentsTableInstances.Refunds}
        isLoading={loading}
        loadingRowsCount={numRows}
        manualSortBy
        hasResizeColumns
        hasResponsiveColWidths
        manualFilters
        manualFiltersRender={(modalProps, setShowNotification) => (
          <FailedTransactionsFilter
            data-trackingid='nwx:failed-transactions:filter'
            initialFilter={filter}
            onApply={handleApplyFilter}
            modalProps={modalProps}
            setShowNotification={setShowNotification}
          />
        )}
        emptyStateConfig={{
          type: 'payments',
          header: t('No data to display'),
        }}
        tableActions={[
          {
            label: t('Print'),
            Icon: PrintIcon,
            onClick: handlePrintClick,
          },
          ...(canExport ? [ExportAction] : []),
          {
            label: t('Refresh'),
            Icon: UpdateIcon,
            onClick: () => refetch(),
          },
        ]}
        styleConfig={{
          columns: [
            {
              id: '*',
              cellStyler: css`
                padding: ${theme.spacing(1, 1.5)};
              `,
              headerStyler: css`
                padding: ${theme.spacing(2, 1.5)};
              `,
            },
          ],
        }}
        onSortChange={(res) => {
          const { id, value } = res[0] || {};
          if (id) {
            const order = FIELD_TO_ORDER[id];
            setOrder(
              order
                ? (`${value === 'asc' ? '' : '-'}${FIELD_TO_ORDER[id]}` as SearchFailedPaymentsOrder)
                : '-submittedAt'
            );
          } else {
            setOrder('-submittedAt');
          }
        }}
        rowActions={{
          onRowClick: (row) => {
            if (row.invoiceId)
              navigate({
                to: `/payments/invoices/${row.invoiceId}?from=failed`,
              });
          },
        }}
        isPaginated
        manualPaginationConfig={{
          page: currentPage,
          hasNext: hasMore && !loading,
          hasPrevious: hasPrevious && currentPage !== 1 && !loading,
          onNumRowsChange: setNumRows,
          defaultRowsPerPage: numRows,
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          handleChange: (prevOrNext: 'prev' | 'next') => {
            if (prevOrNext === 'prev') fetchPreviousPage();
            if (prevOrNext === 'next') fetchNextPage();
          },
        }}
        hasGlobalSearch={true}
        globalSearchConfig={{
          initialValue: filter.search.personName || '',
          position: 'right',
          searchHandler: (searchText: string) => {
            const toSet = {
              ...filter,
              search: {
                ...filter.search,
                personName: searchText.trim().length > 0 ? searchText : undefined,
              },
            };

            setFilter(toSet);
            onChangeFilter?.(toSet);
          },
          placeholder: t('Search'),
          debounceDelay: 1000,
        }}
      />
      {ExportFailedTransactionsModal}
    </>
  );
};

export type PrintFailedListProps = {
  onClose: () => void;
  headerContent?: React.ReactNode;
  filter: SearchFailedPaymentsParams;
  currentPage: number;
};

export const PrintFailedList = ({ onClose, headerContent, filter, currentPage }: PrintFailedListProps) => {
  const { transactions, loading } = useQueryAllFailedInvoices({
    searchParams: filter,
    currentPage,
    numRows: filter.first,
  });

  const columns = useGenerateColumns<SearchFailedPaymentsModel>(generateColumns, true, PaymentsTableInstances.Failed);

  return loading ? (
    <ContentLoader show={loading} />
  ) : !transactions?.length ? null : (
    <PrintDialog show={true} onClose={onClose}>
      <div
        css={css`
          margin: ${theme.spacing(2)};
        `}
      >
        <PrintHeader />
        {headerContent}
        <Table colConfig={columns} data={transactions} tableInstanceId={PaymentsTableInstances.Failed} />
      </div>
    </PrintDialog>
  );
};
