import dayjs from 'dayjs';
import { UseInfiniteQueryOptions, useQueryClient } from 'react-query';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { VoicemailApi } from '@frontend/api-voicemails';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore, useScopedInfiniteQuery } from '@frontend/scope';
import { VoicemailInfiniteQueryData, VoicemailTableDataRow } from '../../components/all-calls/types';
import { generateMediaPath } from '../../components/voicemails/voicemail-utils';
import { getMailboxToNameMap } from '../../components/voicemails/voicemails-multi.component';
import { VoicemailFiltersType } from '../../hooks/use-phone-config-state-store';
import { useTags } from '../../hooks/use-tags';
import { useVoicemailBoxList } from '../../hooks/use-voicemail-box';
import { queryKeys } from '../../query-keys';
import { formatStartDateWithTimeZone, getFullName } from '../../utils';

type VoicemailFilters = {
  searchQuery: string;
  filters: VoicemailFiltersType;
  pageSize: number;
};

const useGetVoicemails = (
  req: VoicemailFilters,
  options?: UseInfiniteQueryOptions<VoicemailInfiniteQueryData, unknown>
) => {
  const { searchQuery, filters, pageSize } = req;
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const { data: voicemailBoxList, isLoading: isVoicemailBoxListLoading } = useVoicemailBoxList({
    phoneConfig,
    locationIds: selectedLocationIds,
  });

  const mailboxToNameMap = getMailboxToNameMap(voicemailBoxList ?? []);

  const { mappedTags, isLoading: isTagDataLoading } = useTags();

  return useScopedInfiniteQuery<VoicemailInfiniteQueryData, unknown>({
    queryKey: queryKeys.unreadVoicemails(searchQuery),
    queryFn: async ({ pageParam }) => {
      const CANARY = 1;
      const { voicemailMessages: data = [] } = await VoicemailApi.listMultiVoicemailMessages({
        locationIds: filters.locationIds,
        syncDeviceSipProfileId: filters.syncDeviceSipProfileId,
        filter: {
          ...filters.filterOptions,
          startTime: formatStartDateWithTimeZone(filters.filterOptions.startTime),
          endTime: formatStartDateWithTimeZone(filters.filterOptions.endTime),
        },
        // We need to fetch more than the requested page size to determine if there is a next page
        limit: pageSize + CANARY,
        page: {
          lastVoicemailId: pageParam?.lastId,
          lastRecordTimestamp: pageParam?.createdAt,
        },
      });

      const parsedData: VoicemailTableDataRow[] = data.map((voicemail) => {
        const { person, message, tagIds } = voicemail;

        return {
          // get the fullname from the person object if it exists. Get the callerName if it's an internal message
          firstName: person?.firstName ?? '',
          lastName: person?.lastName ?? '',
          contactName: getFullName(person?.firstName, person?.lastName, message?.isInternal ? message?.callerName : ''),
          contactId: person?.personId ?? '',
          locationIds: message?.locationIds ?? [],
          channelId: message?.channelId ?? '',
          mailboxId: message?.mailboxId ?? '',
          forwardedMessageId: message?.forwardedMessageId,
          readAt: message?.readAt,
          dateTime: dayjs(message?.createdAt).format('MMM DD YYYY, hh:mm A'),
          mediaFilePath: generateMediaPath(message, selectedLocationIds?.[0] ?? ''),
          mediaId: message?.forwardedMessageId || message?.mediaId || '',
          contactNumber: formatPhoneNumber(message?.callerNumber),
          voiceMailBoxName: mailboxToNameMap[message?.mailboxId ?? ''] ?? '',
          voicemailId: message?.voicemailId ?? '',
          createdAt: message?.createdAt ?? '',
          tags: tagIds?.map((tagId) => mappedTags[tagId]).filter(Boolean) ?? [],
          tagIds: tagIds ?? [],
        };
      });

      const lastRowIndex = pageSize + CANARY > data.length ? data.length - 1 : data.length - 1 - CANARY;
      const lastRowData = parsedData[lastRowIndex];

      if (!pageParam?.lastId) {
        // whenever 1st page of voicemail data is fetched, we need to update the unread count as well to be consistent
        queryClient.refetchQueries([
          selectedLocationIds,
          ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
        ]);
      }

      return {
        data: parsedData.slice(0, pageSize),
        rows: parsedData.slice(0, pageSize),
        meta: {
          lastId: lastRowData?.voicemailId,
          createdAt: lastRowData?.createdAt,
          hasNext: parsedData.length > pageSize,
        },
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.meta;
    },
    enabled: !isTagDataLoading && !isVoicemailBoxListLoading && !!voicemailBoxList,
    ...options,
  });
};

export default useGetVoicemails;
