import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { Prettify } from '@frontend/types';
import { GetThreadStatusIO } from '../types';

export type CalculatePossibleThreadStatusRequestsArgs = {
  personPhones: string[];
  departmentIdsPerGroupId: Record<string, string[]>;
  groupIds: string[];
};
type CalculatePossibleThreadStatusRequestsReturn = Prettify<
  Compulsory<Omit<GetThreadStatusIO['input'], 'threadId'>, 'personPhone' | 'locationId'>
>[];

export const calculatePossibleThreadStatusRequests = ({
  personPhones,
  departmentIdsPerGroupId,
  groupIds,
}: CalculatePossibleThreadStatusRequestsArgs): CalculatePossibleThreadStatusRequestsReturn => {
  const result: CalculatePossibleThreadStatusRequestsReturn = [];

  personPhones.forEach((personPhone) => {
    groupIds.forEach((groupId) => {
      const departmentIds = departmentIdsPerGroupId[groupId] ?? [];
      if (departmentIds.length) {
        departmentIds.forEach((departmentId) => {
          result.push({
            personPhone: formatPhoneNumberE164(personPhone),
            departmentId,
            locationId: groupId,
          });
        });
      } else {
        result.push({
          personPhone: formatPhoneNumberE164(personPhone),
          locationId: groupId,
        });
      }
    });
  });

  return result;
};
