import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { PortingTypes } from '@frontend/api-porting';
import { useDevToolsStore } from '@frontend/devtools';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BannerNotification, DropdownField, Heading, Stepper, Text, useControlledField } from '@frontend/design-system';
import { PORT_TYPE_OPTIONS, trackingIds } from '../../constants';
import { usePortOrderCreationStore } from '../../providers';
import { smallBannerStyle } from '../styles';
import { PortTypesInfoIcon } from './port-types-info-icon';
import { StepLoader } from './step-loader';

export const PortDetailsStep = () => {
  const { t } = useTranslation('porting');
  const { selectedLocationIds, getScopeName } = useAppScopeStore();
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);
  const { isSaving, storePortOrder, portOrderResponse, updateStorePortOrder } = usePortOrderCreationStore([
    'isSaving',
    'storePortOrder',
    'portOrderResponse',
    'updateStorePortOrder',
  ]);
  const isDisableLocationAndPortType = Boolean(portOrderResponse?.id);
  const initialPortOrder = useMemo(() => storePortOrder, []);

  const locationDropdownField = useControlledField({
    type: 'dropdown',
    value: storePortOrder.locationId,
    onChange: (locationId) => !isDisableLocationAndPortType && updateStorePortOrder({ locationId }),
  });
  const portTypeDropdownField = useControlledField({
    type: 'dropdown',
    value: storePortOrder.portType,
    onChange: (portType) =>
      !isDisableLocationAndPortType &&
      updateStorePortOrder({ portType, isSMSHosting: portType === PortingTypes.PortTypeEnum.SMS_HOSTING }),
  });

  useEffect(() => {
    if (!isDisableLocationAndPortType && selectedLocationIds.length === 1) {
      updateStorePortOrder({ locationId: selectedLocationIds[0] });
    }
  }, []);

  // SP-TODO: need to confirm how to handle direct switch of step which won't trigger next button click
  const handleNextButtonClick = async () => {
    if (!storePortOrder.numbers?.length) return;

    const isPortTypeOrLocationChanged =
      storePortOrder.locationId !== initialPortOrder.locationId ||
      storePortOrder.portType !== initialPortOrder.portType;

    // we need to reset numbers for location & port type change case
    // as number type depend on softwareOnly location & port type directly
    // Note: for draft case, we were not allowing to change location & port type
    updateStorePortOrder({
      numbers: isPortTypeOrLocationChanged ? [] : storePortOrder.numbers,
    });
  };

  const isFormValid = Boolean(storePortOrder.locationId) && Boolean(storePortOrder.portType);

  return (
    <>
      <Stepper.Title>{t('Port Details')}</Stepper.Title>
      <Stepper.Content>
        <StepLoader show={isSaving} />
        <Heading level={3} css={{ fontSize: theme.fontSize(16), marginBottom: theme.spacing(1) }}>
          {t('Select Location for this Port Request')}
        </Heading>
        <div css={{ marginBottom: theme.spacing(3) }}>
          <DropdownField
            {...locationDropdownField}
            label='Location'
            name='location-dropdown'
            placeholder={t('Select Location')}
            disabled={isDisableLocationAndPortType}
          >
            {selectedLocationIds?.map((locationId) => (
              <DropdownField.Option key={locationId} value={locationId}>
                {getScopeName(locationId)}
              </DropdownField.Option>
            ))}
          </DropdownField>
          {isDisableLocationAndPortType && (
            <BannerNotification
              status='info'
              message={t(
                'The Location value can no longer be changed. If you would like to change this, please create a new port request and cancel this one.'
              )}
              css={[smallBannerStyle, { marginTop: theme.spacing(2) }]}
            />
          )}
        </div>

        <Heading level={3} css={headerStyle}>
          {t('Select Port Type')}
          <PortTypesInfoIcon />
        </Heading>
        <div css={{ marginBottom: theme.spacing(3) }}>
          <DropdownField
            {...portTypeDropdownField}
            label={t('Port Type')}
            name='port-type'
            disabled={isDisableLocationAndPortType}
          >
            {PORT_TYPE_OPTIONS.map(({ label, value }) => (
              <DropdownField.Option key={value} value={value}>
                {label}
              </DropdownField.Option>
            ))}
          </DropdownField>
          <Text
            size='small'
            color={isDisableLocationAndPortType ? 'disabled' : 'light'}
            css={{ marginTop: theme.spacing(0.5) }}
          >
            {t('Make sure you are porting your business number.')}
          </Text>
          {isDisableLocationAndPortType && (
            <BannerNotification
              status='info'
              message={t(
                'The Port Type value can no longer be changed. If you would like to change this, please create a new port request and cancel this one.'
              )}
              css={[smallBannerStyle, { marginTop: theme.spacing(1) }]}
            />
          )}
        </div>
      </Stepper.Content>
      <Stepper.ButtonBar>
        <Stepper.NextButton
          isValid={(isFormValid && !isSaving) || isDebugModeOn}
          onClick={handleNextButtonClick}
          trackingId={`${trackingIds.submitPortRequest}_port-date_next_btn`}
        >
          {t('Next')}
        </Stepper.NextButton>
      </Stepper.ButtonBar>
    </>
  );
};

const headerStyle = css({
  fontSize: theme.fontSize(16),
  marginBottom: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
});
