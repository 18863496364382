import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { verticalMapping } from '../hooks/use-options-provider';

// Randomly generate a value between given min and max
export const generateRandomValue = (min?: number, max?: number) =>
  Math.floor(Math.random() * ((max || 100) - (min || 1) + 1)) + (min || 1);

const getCallsCount = (sentiments?: CallIntelTypes.Overview['sentiments']): number => {
  // Each call will have only 1 sentiment, hence total calls = total sentiments
  return Object.values(sentiments || {}).reduce((acc, count) => acc + count, 0);
};

// **Get Appointment Types Based on Vertical**
export const getAppointmentTypesForVertical = (vertical: Vertical): CallIntelTypes.AppointmentTypeEnum[] => {
  // If a match is found in the mapping, return the specific appointment types
  if (vertical in verticalMapping) {
    return [...(verticalMapping[vertical] ?? [])]
      .filter((type) => type !== CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN)
      .sort();
  }

  // If the vertical is NOT Dental, Vet, or Optometry, return Generic Appointment Types
  const allSpecificAppointments = new Set<CallIntelTypes.AppointmentTypeEnum>([
    ...(Object.values(CallIntelTypes.AppointmentTypeDentalEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
    ...(Object.values(CallIntelTypes.AppointmentTypeVetEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
    ...(Object.values(CallIntelTypes.AppointmentTypeOptoEnum) as unknown as CallIntelTypes.AppointmentTypeEnum[]),
  ]);

  return Object.values(CallIntelTypes.AppointmentTypeEnum)
    .filter(
      (type) =>
        !allSpecificAppointments.has(type) && type !== CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN
    )
    .sort();
};

export const getFilteredOverviewData = (
  overview: CallIntelTypes.Overview,
  filters: CallIntelTypes.Filters,
  vertical: Vertical | null
): CallIntelTypes.Overview => {
  let factor = 1;

  if (filters?.contactTypes?.length) {
    factor = filters.contactTypes.length / (Object.keys(CallIntelTypes.ContactTypeEnum).length - 1);
  }

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const { isPartial, ...serviceQualitySummaryRest } = serviceQualitySummary;

  // If vertical is null => treat it as generic
  let validApptTypes: CallIntelTypes.AppointmentTypeEnum[] = [];
  if (vertical) {
    validApptTypes = getAppointmentTypesForVertical(vertical);
  } else {
    // treat as generic if vertical is null
    validApptTypes = getAppointmentTypesForVertical(Vertical.INVALID_VERTICAL);
  }

  const filteredApptTypes: Record<string, number> = {};
  Object.entries(overview.appointmentTypes || {}).forEach(([appt, val]) => {
    if (validApptTypes.includes(appt as CallIntelTypes.AppointmentTypeEnum)) {
      filteredApptTypes[appt] = val;
    }
  });

  const filteredOverview = {
    appointmentTypes: filterData(filteredApptTypes),
    categories: filterData(overview.categories),
    sentiments: filterData(overview.sentiments),
    serviceQualitySummary: {
      isPartial: false,
      ...filterData(serviceQualitySummaryRest),
    } as CallIntelTypes.ServiceQualitySummary,
    summary: {
      isPartial: false,
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
    taskTypes: filterData(overview.taskTypes),
    totalCallsAnalyzed,
  };

  return filteredOverview;
};

export const getFilteredOverviewCategoriesDrillDown = (
  overview: CallIntelTypes.Overview,
  filters: CallIntelTypes.Filters,
  vertical: Vertical | null
): CallIntelTypes.Overview => {
  let factor = 1;

  if (filters?.contactTypes?.length) {
    factor = filters.contactTypes.length / (Object.keys(CallIntelTypes.ContactTypeEnum).length - 1);
  }

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const { isPartial, ...serviceQualitySummaryRest } = serviceQualitySummary;

  // If vertical is null => treat it as generic
  let validApptTypes: CallIntelTypes.AppointmentTypeEnum[] = [];
  if (vertical) {
    validApptTypes = getAppointmentTypesForVertical(vertical);
  } else {
    // treat as generic if vertical is null
    validApptTypes = getAppointmentTypesForVertical(Vertical.INVALID_VERTICAL);
  }

  const filteredApptTypes: Record<string, number> = {};
  Object.entries(overview.appointmentTypes || {}).forEach(([appt, val]) => {
    if (validApptTypes.includes(appt as CallIntelTypes.AppointmentTypeEnum)) {
      filteredApptTypes[appt] = val;
    }
  });

  const filteredOverview = {
    appointmentTypes: filterData(filteredApptTypes),
    categories: overview.categories,
    sentiments: filterData(overview.sentiments),
    serviceQualitySummary: {
      isPartial: false,
      ...filterData(serviceQualitySummaryRest),
    } as CallIntelTypes.ServiceQualitySummary,
    summary: {
      isPartial: false,
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
    taskTypes: filterData(overview.taskTypes),
    totalCallsAnalyzed,
  };

  return filteredOverview;
};

export const getFilteredOverviewAppointmentsDrillDown = (
  overview: CallIntelTypes.Overview,
  filters: CallIntelTypes.Filters
): CallIntelTypes.Overview => {
  if (!filters.contactTypes || !filters.contactTypes.length) {
    return overview;
  }

  const factor = filters.contactTypes.length / (Object.keys(CallIntelTypes.ContactTypeEnum).length - 1);

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const { isPartial, ...serviceQualitySummaryRest } = serviceQualitySummary;

  const filteredOverview = {
    appointmentTypes: overview.appointmentTypes,
    categories: filterData(overview.categories),
    sentiments: filterData(overview.sentiments),
    serviceQualitySummary: {
      isPartial: false,
      ...filterData(serviceQualitySummaryRest),
    } as CallIntelTypes.ServiceQualitySummary,
    summary: {
      isPartial: false,
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
    taskTypes: filterData(overview.taskTypes),
    totalCallsAnalyzed,
  };

  return filteredOverview;
};

export const getFilteredOverviewSentimentsDrillDown = (
  overview: CallIntelTypes.Overview,
  filters: CallIntelTypes.Filters,
  vertical: Vertical | null
): CallIntelTypes.Overview => {
  let factor = 1;

  if (filters?.contactTypes?.length) {
    factor = filters.contactTypes.length / (Object.keys(CallIntelTypes.ContactTypeEnum).length - 1);
  }

  const filterData = (data: Partial<Record<string, number>> | null): Partial<Record<string, number>> | null => {
    if (!data) return data;

    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, Math.floor((value ?? 0) * factor)]));
  };

  const { isPartial, ...serviceQualitySummaryRest } = serviceQualitySummary;

  // If vertical is null => treat it as generic
  let validApptTypes: CallIntelTypes.AppointmentTypeEnum[] = [];
  if (vertical) {
    validApptTypes = getAppointmentTypesForVertical(vertical);
  } else {
    // treat as generic if vertical is null
    validApptTypes = getAppointmentTypesForVertical(Vertical.INVALID_VERTICAL);
  }

  const filteredApptTypes: Record<string, number> = {};
  Object.entries(overview.appointmentTypes || {}).forEach(([appt, val]) => {
    if (validApptTypes.includes(appt as CallIntelTypes.AppointmentTypeEnum)) {
      filteredApptTypes[appt] = val;
    }
  });

  const filteredOverview = {
    appointmentTypes: filterData(filteredApptTypes),
    categories: filterData(overview.categories),
    sentiments: filterData(overview.sentiments),
    serviceQualitySummary: {
      isPartial: false,
      ...filterData(serviceQualitySummaryRest),
    } as CallIntelTypes.ServiceQualitySummary,
    summary: {
      isPartial: false,
      scheduledOpportunities: Math.floor((overview?.summary?.scheduledOpportunities || 1) * factor),
      totalOpportunities: overview?.summary?.totalOpportunities,
    },
    taskTypes: filterData(overview.taskTypes),
    totalCallsAnalyzed,
  };

  return filteredOverview;
};

const overviewSentiments = {
  [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(10, 15),
  [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(15, 20),
  [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(10, 15),
};

const overviewDrillDownSentiments = {
  [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(1, 8),
  [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(1, 15),
  [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(1, 10),
};

const serviceQualitySummary: CallIntelTypes.ServiceQualitySummary = {
  callsToReview: generateRandomValue(10, 20),
  callsResolved: generateRandomValue(10, 20),
  isPartial: false,
};

export const overviewScheduledOpportunities = generateRandomValue(1, getCallsCount(overviewSentiments));

const overviewDrillDownScheduledOpportunities = generateRandomValue(1, getCallsCount(overviewDrillDownSentiments));

export const totalCallsAnalyzed = Object.values(overviewSentiments).reduce((acc, value) => acc + (value || 0), 0);

export const overview = {
  appointmentTypes: {
    //Dental
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(20, 50),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(10, 20),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(2, 40),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(25, 40),
    //Vet
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: generateRandomValue(25, 40),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: generateRandomValue(20, 50),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: generateRandomValue(2, 40),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: generateRandomValue(10, 20),
    //Opto
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: generateRandomValue(20, 50),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: generateRandomValue(2, 40),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: generateRandomValue(10, 20),
    //Generic
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: generateRandomValue(2, 40),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: generateRandomValue(20, 50),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: generateRandomValue(10, 20),
  },
  categories: {
    [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(8, 30),
    [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(10, 12),
    [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(2, 5),
    [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(30, 60),
  },
  sentiments: overviewSentiments,
  serviceQualitySummary,
  summary: {
    isPartial: false,
    scheduledOpportunities: generateRandomValue(1, overviewScheduledOpportunities),
    totalOpportunities: overviewScheduledOpportunities,
  },
  taskTypes: {
    [CallIntelTypes.TaskTypeEnum.TYPE_SCHEDULING]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_OTHER]: 0,
  },
  totalCallsAnalyzed,
};

export const generatePreviousOverview = (): CallIntelTypes.Overview => {
  const overviewSentiments = {
    [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(10, 15),
    [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(15, 20),
    [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(10, 15),
  };

  const overviewScheduledOpportunities = generateRandomValue(1, getCallsCount(overviewSentiments));

  return {
    appointmentTypes: {
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(20, 50),
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(10, 20),
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(2, 40),
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(2, 10),
      [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(25, 40),
    },
    categories: {
      [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(8, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(10, 12),
      [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(2, 5),
      [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(30, 60),
    },
    sentiments: overviewSentiments,
    serviceQualitySummary: {
      callsToReview: generateRandomValue(10, 20),
      callsResolved: generateRandomValue(10, 20),
      isPartial: false,
    },
    summary: {
      isPartial: false,
      scheduledOpportunities: generateRandomValue(1, overviewScheduledOpportunities),
      totalOpportunities: overviewScheduledOpportunities,
    },
    taskTypes: {
      [CallIntelTypes.TaskTypeEnum.TYPE_SCHEDULING]: 0,
      [CallIntelTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(4, 20),
      [CallIntelTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: 0,
      [CallIntelTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(4, 20),
      [CallIntelTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(4, 20),
      [CallIntelTypes.TaskTypeEnum.TYPE_OTHER]: 0,
    },
    totalCallsAnalyzed: Object.values(overviewSentiments).reduce((acc, value) => acc + (value || 0), 0),
  };
};

export const overviewAppointmentsDrillDown: CallIntelTypes.Overview = {
  appointmentTypes: {},
  categories: {
    [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(2, 20),
    [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(1, 3),
    [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(10, 40),
  },
  sentiments: overviewDrillDownSentiments,
  serviceQualitySummary,
  summary: {
    isPartial: false,
    scheduledOpportunities: generateRandomValue(1, overviewDrillDownScheduledOpportunities),
    totalOpportunities: overviewDrillDownScheduledOpportunities,
  },
  taskTypes: {
    [CallIntelTypes.TaskTypeEnum.TYPE_SCHEDULING]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_OTHER]: 0,
  },
  totalCallsAnalyzed,
};

export const overviewCategoriesDrillDown: CallIntelTypes.Overview = {
  appointmentTypes: {
    //Dental
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(1, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(2, 25),
    //Vet
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: generateRandomValue(2, 25),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: generateRandomValue(1, 30),
    //Opto
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: generateRandomValue(1, 30),
    //Generic
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: generateRandomValue(1, 30),
  },
  categories: {},
  sentiments: overviewDrillDownSentiments,
  serviceQualitySummary,
  summary: {
    isPartial: false,
    scheduledOpportunities: generateRandomValue(1, overviewDrillDownScheduledOpportunities),
    totalOpportunities: overviewDrillDownScheduledOpportunities,
  },
  taskTypes: {
    [CallIntelTypes.TaskTypeEnum.TYPE_SCHEDULING]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_OTHER]: 0,
  },
  totalCallsAnalyzed,
};

export const overviewSentimentsDrillDown: CallIntelTypes.Overview = {
  appointmentTypes: {
    //Dental
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(1, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(2, 25),
    //Vet
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DENTAL]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTICS]: generateRandomValue(2, 25),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_END_OF_LIFE_CARE]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE_CARE]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SICK_PET]: generateRandomValue(1, 30),
    //Opto
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EYE_EXAM]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_MEDICAL_EYE_CARE]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SPECIALTY_SERVICES]: generateRandomValue(2, 10),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_VISION_CORRECTION]: generateRandomValue(1, 30),
    //Generic
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_DIAGNOSTIC_TESTS]: generateRandomValue(1, 5),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_CONSULTATION]: generateRandomValue(2, 30),
    [CallIntelTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ROUTINE_CHECKUP]: generateRandomValue(1, 30),
  },
  categories: {
    [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(2, 20),
    [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(2, 6),
    [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(10, 40),
    [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(1, 3),
  },
  sentiments: overviewDrillDownSentiments,
  serviceQualitySummary,
  summary: {
    isPartial: false,
    scheduledOpportunities: generateRandomValue(1, overviewDrillDownScheduledOpportunities),
    totalOpportunities: overviewDrillDownScheduledOpportunities,
  },
  taskTypes: {
    [CallIntelTypes.TaskTypeEnum.TYPE_SCHEDULING]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: 0,
    [CallIntelTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(4, 20),
    [CallIntelTypes.TaskTypeEnum.TYPE_OTHER]: 0,
  },
  totalCallsAnalyzed,
};
