import { useEffect, useRef, FC, useLayoutEffect } from 'react';
import { css } from '@emotion/react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { theme } from '@frontend/theme';
import { SpinningLoader } from '@frontend/design-system';
import { INITIAL_MESSAGE_ITEM_INDEX } from '../../constants';
import { Message, StreamInstance } from '../../types';
import { VirtuosoItem } from './message';

interface ConversationListProps {
  messages: Message[];
  unreadCount: number;
  fetchPreviousMessages: () => Promise<void>;
  isFetching: boolean;
  channelId: string;
  streamClient: StreamInstance | null;
  unreadIndex: number;
  isTeamChatFocused: boolean;
}

export const ConversationList: FC<ConversationListProps> = ({
  messages,
  unreadCount,
  fetchPreviousMessages,
  isFetching,
  channelId,
  streamClient,
  unreadIndex,
  isTeamChatFocused,
}) => {
  const listRef = useRef<VirtuosoHandle>(null);

  useEffect(() => {
    if (streamClient && unreadCount && isTeamChatFocused) {
      // Mark messages read only if there is an unread count
      const channel = streamClient.getChannelById('team', channelId, {});
      channel.markRead();
    }
  }, [unreadCount, streamClient, isTeamChatFocused]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (unreadIndex > -1 && unreadCount > 0 && isTeamChatFocused) {
        listRef.current?.scrollToIndex({ index: unreadIndex, align: 'center', behavior: 'smooth' });
      }
    }, 300);
  }, [unreadCount, unreadIndex, isTeamChatFocused]);

  return (
    <Virtuoso
      data={messages}
      style={{ height: '100%' }}
      startReached={fetchPreviousMessages}
      firstItemIndex={INITIAL_MESSAGE_ITEM_INDEX - messages.length}
      initialTopMostItemIndex={messages.length - 1}
      alignToBottom
      ref={listRef}
      itemContent={(index, message, { firstItemIndex }) => (
        <VirtuosoItem
          index={index}
          message={message}
          unreadCount={unreadCount}
          unreadMessageIndex={unreadIndex}
          isTeamChatFocused={isTeamChatFocused}
          firstItemIndex={firstItemIndex}
          totalMessagesCount={messages.length}
        />
      )}
      components={{
        Header: ({ context }: { context?: { isFetching: boolean; firstItemIndex: number } }) =>
          context?.isFetching ? (
            <div css={styles.spinner}>
              <SpinningLoader size='xs' />
            </div>
          ) : null,
      }}
      context={{
        isFetching,
        firstItemIndex: INITIAL_MESSAGE_ITEM_INDEX,
      }}
      followOutput='auto'
    />
  );
};

ConversationList.displayName = 'ConversationList';

const styles = {
  spinner: css({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    width: '100%',
  }),
};
