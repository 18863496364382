import { removeLeadingAndTrailingSlashes } from '@frontend/uri';

export const getMurderThePortalRedirects = (path: string): string | null => {
  const result = getPortalRedirectRoutes(path);
  if (!result) {
    return null;
  }
  const { nwx, settings } = result;
  return `${nwx ?? ''}${settings ? `#${settings}` : ''}`;
};

export const getPortalRedirectRoutes = (path: string): null | { nwx?: NWXRoutes; settings?: SettingsRoutes } => {
  const shouldCheck = path.startsWith('portal') || path.startsWith('/portal');
  if (!shouldCheck) {
    return null;
  }
  switch (true) {
    case isMatch(path, 'portal/accept-invite/[nonceToken]/location/[locationId]'):
    case isMatch(path, 'portal/login'):
    case isMatch(path, 'portal/schedule-setup/[locationId]'):
    case isMatch(path, 'portal/text-connect-setup/[locationId]'):
    case isMatch(path, 'portal/user-invite/[nonceToken]/location/[locationId]'):
      return null;

    // new onboarding routes are auto redirected from nwx dashboard
    case isMatch(path, 'portal/test'):
    case isMatch(path, 'portal/onboarding'):
    case isMatch(path, 'portal/onboarding/form'):
    case isMatch(path, 'portal/onboarding/form/[locationId]/[section]'):
    case isMatch(path, 'portal/onboarding/form/[locationId]/[section]/[task]/[step]'):
    case isMatch(path, 'portal/onboarding/form/[locationId]/complete'):
    case isMatch(path, 'portal/onboarding/form/[locationId]/dashboard'):
    case isMatch(path, 'portal/onboarding/form/[locationId]/screening'):
      return { nwx: 'home/dashboard' };

    case isMatch(path, 'portal/example'):
    case isMatch(path, 'portal/example/components/features'):
    case isMatch(path, 'portal/example/components/inbox'):
    case isMatch(path, 'portal/example/components/schedule'):
    case isMatch(path, 'portal/example/components/i18n'):
    case isMatch(path, 'portal/example/components/demo-widget'):
    case isMatch(path, 'portal/example/query-keys'):
    case isMatch(path, 'portal/marketing/filter/[filterId]'):
      return { nwx: 'home/dashboard' }; //these just go home

    case isMatch(path, 'portal/customers/quick-fill'):
      return { nwx: 'schedule/quickfill-history' };
    case isMatch(path, 'portal/customers'):
    case isMatch(path, 'portal/customers/csv-upload'):
    case isMatch(path, 'portal/customers/csv-upload/preview'):
    case isMatch(path, 'portal/customers/csv-upload/map'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/csv-upload' };

    case isMatch(path, 'portal/payments/settings'):
    case isMatch(path, 'portal/payments/settings/public-business-information'):
    case isMatch(path, 'portal/payments/settings/general-settings'):
    case isMatch(path, 'portal/payments/settings/payment-methods'):
      return { nwx: 'payments/invoices', settings: 'settings/payments' };
    case isMatch(path, 'portal/payments/settings/online-bill-pay'):
    case isMatch(path, 'portal/payments/online-bill-pay'):
      return { nwx: 'payments/online-bill-pay', settings: 'settings/payments' };
    case isMatch(path, 'portal/payments/invoices'):
    case isMatch(path, 'portal/payments/invoices/[...params]'): //not trying to forward params here
      return { nwx: 'payments/invoices' };
    case isMatch(path, 'portal/payments/refunds'):
      return { nwx: 'payments/invoices?status=refunded' as NWXRoutes };
    case isMatch(path, 'portal/payments/buy-now-pay-later'):
      return { nwx: 'payments/buy-now-pay-later' };
    case isMatch(path, 'portal/payments/payouts'):
    case isMatch(path, 'portal/payments/payouts/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/payments/payouts/[id]');
      return { nwx: `payments/payouts/${id}` };
    }
    case isMatch(path, 'portal/payments/payment-plans'):
    case isMatch(path, 'portal/payments/payment-plans/[planId]'):
    case isMatch(path, 'portal/payments/payment-plans/create'):
      return { nwx: 'payments/payment-plans' };

    case isMatch(path, 'portal/messages/bulk'):
    case isMatch(path, 'portal/messages/bulk/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/messages/bulk/[id]');
      return { nwx: `bulk/all-campaigns/text/${id}` };
    }
    case isMatch(path, 'portal/messages/bulk/new'):
      return { nwx: 'bulk/all-campaigns/text/new' };
    case isMatch(path, 'portal/messages/record-history/[type]/[recordId]'): {
      const { type, recordId } = extractParams<{
        type: 'appointment' | 'contactinfo' | 'order' | 'person' | 'recall';
        recordId: string;
      }>(path, 'portal/messages/record-history/[type]/[recordId]');
      return {
        nwx: `messages/inbox`,
        settings: type && recordId ? `settings/messaging/record-history/${type}/${recordId}` : undefined,
      };
    }
    case isMatch(path, 'portal/messages/debug-tools'):
      return { nwx: 'messages/inbox', settings: 'settings/messaging/record-history' };
    case isMatch(path, 'portal/messages/text-connect'):
      return { nwx: 'messages/inbox', settings: 'settings/messaging/text-connect' };
    case isMatch(path, 'portal/messages/message-queue'):
      return { nwx: 'messages/inbox', settings: 'settings/messaging/message-queue' };
    case isMatch(path, 'portal/messages/auto-messaging'):
      return { nwx: 'messages/inbox', settings: 'settings/messaging/auto-messages' };
    case isMatch(path, 'portal/messages/auto-messaging/[ruleId]'): {
      const { ruleId } = extractParams<{ ruleId: string }>(path, 'portal/messages/auto-messaging/[ruleId]');
      return { nwx: 'messages/inbox', settings: `settings/messaging/auto-messages/${ruleId}/editor` };
    }
    case isMatch(path, 'portal/messages/reminder-settings'):
      return { nwx: 'messages/inbox', settings: 'settings/messaging/auto-messages' };

    case isMatch(path, 'portal/forms/dashboard'):
      return { nwx: 'forms/submissions/inbox/all' };
    case isMatch(path, 'portal/forms/upload-forms'):
      return { nwx: 'forms/digitization' };
    case isMatch(path, 'portal/fax'):
      return { nwx: 'fax/inbox' };

    case isMatch(path, 'portal/writeback-logs'):
      return { nwx: 'schedule' };
    case isMatch(path, 'portal/online-scheduling/settings'):
      return { nwx: 'schedule', settings: 'settings/schedule' };
    case isMatch(path, 'portal/online-scheduling/booking-site-setup'):
      return { nwx: 'schedule', settings: 'settings/schedule/booking-site-setup' };
    case isMatch(path, 'portal/online-scheduling/installation'):
      return { nwx: 'schedule', settings: 'settings/schedule/booking-site-setup' };
    case isMatch(path, 'portal/online-scheduling/custom-fields'):
      return { nwx: 'schedule', settings: 'settings/schedule/custom-fields' };
    case isMatch(path, 'portal/subscriptions'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions' };
    case isMatch(path, 'portal/subscriptions/manage-subscription'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/manage-subscription' };
    case isMatch(path, 'portal/subscriptions/bulk-messaging'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/bulk-messaging' };
    case isMatch(path, 'portal/subscriptions/practice-analytics'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/practice-analytics' }; //I don't know if this is right
    case isMatch(path, 'portal/subscriptions/online-scheduling'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/online-scheduling' }; // //I don't know if this is right
    case isMatch(path, 'portal/subscriptions/verify-sign-up'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/insurance-verification' }; // //I don't know if this is right
    case isMatch(path, 'portal/subscriptions/text-connect'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/text-connect' }; // //I don't know if this is right
    case isMatch(path, 'portal/subscriptions/call-intelligence'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/call-intelligence' };
    case isMatch(path, 'portal/subscriptions/digital-forms'):
      return { nwx: 'home/dashboard', settings: 'settings/subscriptions/feature/digital-forms' }; // //I don't know if this is right
    case isMatch(path, 'portal/partners'):
      return { nwx: 'home/partners' };
    case isMatch(path, 'portal/locations'):
      return { nwx: 'home/dashboard' }; //it doesn't need to go anywhere because they'll see the location picker if they haven't chosen a location

    case isMatch(path, 'portal/phone/overrides'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/overrides' };

    case isMatch(path, 'portal/phone/overrides/[id]'): {
      // return { nwx: 'home/dashboard', settings: `settings/phone/overrides/${id}` }; // doesn't exist?
      return { nwx: 'home/dashboard', settings: `settings/phone/overrides` };
    }
    case isMatch(path, 'portal/phone/voicemail-boxes'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/voicemail-box' };

    case isMatch(path, 'portal/phone/voicemail-boxes/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/voicemail-boxes/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/voicemail-box/${id}` };
    }
    case isMatch(path, 'portal/phone/voicemails/mailboxes/[mailboxId]/messages/[messageId]'): {
      const { mailboxId, messageId } = extractParams<{ mailboxId: string; messageId: string }>(
        path,
        'portal/phone/voicemails/mailboxes/[mailboxId]/messages/[messageId]'
      );
      return { nwx: `calls/voicemail-boxes/${mailboxId}/messages/${messageId}` };
    }
    case isMatch(path, 'portal/phone/call-blocking'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/call-blocking' };

    case isMatch(path, 'portal/phone/line-keys'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/line-keys' };

    case isMatch(path, 'portal/phone/line-keys/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/line-keys/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/line-keys/${id}` };
    }
    case isMatch(path, 'portal/phone/call-queues'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/call-queues' };

    case isMatch(path, 'portal/phone/call-queues/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/call-queues/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/call-queues/${id}` };
    }

    case isMatch(path, 'portal/phone/departments'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/departments' };

    case isMatch(path, 'portal/phone/departments/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/departments/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/departments/${id}` };
    }
    case isMatch(path, 'portal/phone/phone-tree'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/phone-tree' };

    case isMatch(path, 'portal/phone/phone-tree/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/phone-tree/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/phone-tree/${id}` };
    }

    case isMatch(path, 'portal/phone/call-records'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/call-recording' };

    case isMatch(path, 'portal/phone/contact-directories'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/contact-directories' };

    case isMatch(path, 'portal/phone/contact-directories/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/contact-directories/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/contact-directories/${id}` };
    }

    case isMatch(path, 'portal/phone/call-groups'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/call-groups' };

    case isMatch(path, 'portal/phone/call-groups/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/call-groups/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/call-groups/${id}` };
    }

    case isMatch(path, 'portal/phone/media-library'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/audio-library' };

    case isMatch(path, 'portal/phone/mainline'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/mainline' };

    case isMatch(path, 'portal/phone/mainline/[id]'): {
      // return { nwx: 'home/dashboard', settings: `settings/phone/mainline/${id}` }; //doesn't exist?
      return { nwx: 'home/dashboard', settings: `settings/phone/mainline` };
    }

    case isMatch(path, 'portal/phone/hold-music'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/hold-music' };

    case isMatch(path, 'portal/phone/devices'):
      return { nwx: 'home/dashboard', settings: 'settings/phone/devices' };

    case isMatch(path, 'portal/phone/devices/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/phone/devices/[id]');
      return { nwx: 'home/dashboard', settings: `settings/phone/devices/${id}` };
    }

    case isMatch(path, 'portal/marketing'):
      return { nwx: 'bulk/all-campaigns/email' };

    case isMatch(path, 'portal/marketing/filters'):
      return { nwx: 'bulk/all-campaigns/email' };

    case isMatch(path, 'portal/marketing/settings'):
      return { nwx: 'home/dashboard', settings: 'settings/email' };

    case isMatch(path, 'portal/marketing/gallery'):
      return { nwx: 'home/dashboard', settings: 'settings/email/gallery' };

    case isMatch(path, 'portal/marketing/campaigns'):
      return { nwx: 'home/dashboard', settings: 'settings/email/campaigns/sent' };

    case isMatch(path, 'portal/marketing/campaigns/[type]'):
      return { nwx: 'home/dashboard', settings: 'settings/email/campaigns/sent' };

    case isMatch(path, 'portal/marketing/campaign/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/marketing/campaign/[id]');
      return { nwx: `bulk/all-campaigns/email/${id}` };
    }

    case isMatch(path, 'portal/marketing/campaign/[id]/[status]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/marketing/campaign/[id]/[status]');
      return { nwx: `bulk/all-campaigns/email/${id}` };
    }

    case isMatch(path, 'portal/marketing/templates'):
      return { nwx: 'bulk/all-campaigns/email/new/templates' };

    case isMatch(path, 'portal/marketing/templates/[templateId]'): {
      const { templateId } = extractParams<{ templateId: string }>(path, 'portal/marketing/templates/[templateId]');
      return { nwx: `bulk/all-campaigns/email/new/templates/${templateId}` };
    }

    case isMatch(path, 'portal/account/password-policy'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/users' };

    case isMatch(path, 'portal/account/phone-numbers'):
    case isMatch(path, 'portal/account/phone-numbers/port-additional-numbers'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/phone-numbers' };

    case isMatch(path, 'portal/account/business-information'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/business-verification' };

    case isMatch(path, 'portal/account/users'):
    case isMatch(path, 'portal/account/users/invite'):
    case isMatch(path, 'portal/account/users/invite/[email]'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/users' };
    case isMatch(path, 'portal/account/users/edit/[profileId]'): {
      const { profileId } = extractParams<{ profileId: string }>(path, 'portal/account/users/edit/[profileId]');
      return { nwx: 'home/dashboard', settings: `settings/organization/users/${profileId}` };
    }
    case isMatch(path, 'portal/account/users/setup-mobile-number/[nonceToken]'): {
      const { nonceToken } = extractParams<{ nonceToken: string }>(
        path,
        'portal/account/users/setup-mobile-number/[nonceToken]'
      );
      return { nwx: 'home/dashboard', settings: `settings/organization/users/setup-mobile-number/${nonceToken}` };
    }

    case isMatch(path, 'portal/account/billing'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/billing-information' };

    case isMatch(path, 'portal/data-sync'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/data-sync' };
    case isMatch(path, 'portal/data-sync/new'):
      return { nwx: 'home/dashboard', settings: 'settings/organization/data-sync/new' };

    case isMatch(path, 'portal/data-sync/[sourceId]'): {
      return { nwx: 'home/dashboard', settings: `settings/organization/data-sync` };
    }

    case isMatch(path, 'portal/analytics/appointment'):
      return { nwx: 'analytics/appointment' };

    case isMatch(path, 'portal/analytics/dashboard'):
      return { nwx: 'analytics/dashboard' };

    case isMatch(path, 'portal/analytics/call-intelligence'):
      return { nwx: 'analytics/call-intelligence/main' };

    case isMatch(path, 'portal/analytics/call-intelligence/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/analytics/call-intelligence/[id]');
      return { nwx: `analytics/call-intelligence/${id}` };
    }

    case isMatch(path, 'portal/analytics/phone'):
      return { nwx: 'analytics/phone' };

    case isMatch(path, 'portal/analytics/auto-recall'):
      return { nwx: 'analytics/auto-recall' };

    case isMatch(path, 'portal/analytics/practice'):
      return { nwx: 'analytics/practice/main' };

    case isMatch(path, 'portal/analytics/practice/[id]'): {
      const { id } = extractParams<{ id: string }>(path, 'portal/analytics/practice/[id]');
      return { nwx: `analytics/practice/main/${id}` };
    }

    case isMatch(path, 'portal/analytics/messaging'):
      return { nwx: 'analytics/messaging' };

    case isMatch(path, 'portal/reviews/settings'):
      return { nwx: 'home/dashboard', settings: 'settings/reviews' };

    case isMatch(path, 'portal/reviews/analytics'):
      return { nwx: 'home/dashboard', settings: 'settings/reviews' };

    default:
      return { nwx: 'home/dashboard', settings: 'settings/organization' };
  }
};

const isNestedParam = (segment: string) => {
  return segment.startsWith('[...') && segment.endsWith(']');
};

const isParam = (segment: string) => {
  return segment.startsWith('[') && segment.endsWith(']');
};

//TODO: this isn't a very efficient way to do this... come up with a faster way
const isMatch = (currentPath: string, route: PortalRoutes) => {
  const cleanPath = removeLeadingAndTrailingSlashes(currentPath);
  const cleanRoute = removeLeadingAndTrailingSlashes(route);
  if (!currentPath || !cleanRoute) {
    return false;
  }

  //this will just make things much faster for most cases
  const hasDynamicSegments = cleanRoute.includes('[');
  if (!hasDynamicSegments) {
    return cleanPath === cleanRoute;
  }

  const currentPathSegments = cleanPath.split('/');
  const routeSegments = cleanRoute.split('/');

  if (currentPathSegments.length !== routeSegments.length) {
    return false;
  }

  for (let i = 0; i < currentPathSegments.length; i++) {
    const pathSegment = currentPathSegments[i];
    const routeSegment = routeSegments[i];
    if (isNestedParam(routeSegment) && pathSegment) {
      return true; //this is now a match regardless of what comes next
    }
    if ((isParam(routeSegment) && pathSegment) || pathSegment === routeSegment) {
      if (i === currentPathSegments.length - 1) {
        // If we're at the end of the path, we consider this a match
        return true;
      } else {
        // Otherwise, keep checking the next segment
        continue;
      }
    } else {
      return false;
    }
  }
  return false;
};

const extractParams = <P extends Record<string, string>>(
  path: string,
  route: PortalRoutes
): P | Record<string, never> => {
  const cleanPath = removeLeadingAndTrailingSlashes(path);
  const cleanRoute = removeLeadingAndTrailingSlashes(route);
  if (!cleanPath || !cleanRoute) {
    return {};
  }

  const currentPathSegments = cleanPath.split('/');
  const routeSegments = cleanRoute.split('/');
  const params: Record<string, string> = {};

  for (let i = 0; i < currentPathSegments.length; i++) {
    const pathSegment = currentPathSegments[i];
    const routeSegment = routeSegments[i];
    if (isParam(routeSegment) && pathSegment) {
      const paramName = routeSegment.slice(1, -1);
      params[paramName] = pathSegment;
    }
  }
  return params as P;
};

type PortalRoutes =
  | 'portal'
  | 'portal/test'
  | 'portal/customers'
  | 'portal/customers/quick-fill'
  | 'portal/customers/csv-upload'
  | 'portal/customers/csv-upload/preview'
  | 'portal/customers/csv-upload/map'
  | 'portal/payments/settings'
  | 'portal/payments/settings/public-business-information'
  | 'portal/payments/settings/general-settings'
  | 'portal/payments/settings/payment-methods'
  | 'portal/payments/settings/online-bill-pay'
  | 'portal/payments/invoices'
  | 'portal/payments/invoices/[...params]'
  | 'portal/payments/online-bill-pay'
  | 'portal/payments/refunds'
  | 'portal/payments/payment-plans'
  | 'portal/payments/payment-plans/[planId]'
  | 'portal/payments/payment-plans/create'
  | 'portal/payments/buy-now-pay-later'
  | 'portal/payments/payouts'
  | 'portal/payments/payouts/[id]'
  | 'portal/messages/bulk'
  | 'portal/messages/bulk/new'
  | 'portal/messages/bulk/[id]'
  | 'portal/messages/record-history/[type]/[recordId]'
  | 'portal/messages/debug-tools'
  | 'portal/messages/text-connect'
  | 'portal/messages/message-queue'
  | 'portal/messages/auto-messaging'
  | 'portal/messages/auto-messaging/[ruleId]'
  | 'portal/messages/reminder-settings'
  | 'portal/forms/dashboard'
  | 'portal/forms/upload-forms'
  | 'portal/onboarding'
  | 'portal/onboarding/form'
  | 'portal/onboarding/form/[locationId]/[section]'
  | 'portal/onboarding/form/[locationId]/[section]/[task]/[step]'
  | 'portal/onboarding/form/[locationId]/complete'
  | 'portal/onboarding/form/[locationId]/dashboard'
  | 'portal/onboarding/form/[locationId]/screening'
  | 'portal/fax'
  | 'portal/example'
  | 'portal/example/components/features'
  | 'portal/example/components/inbox'
  | 'portal/example/components/schedule'
  | 'portal/example/components/i18n'
  | 'portal/example/components/demo-widget'
  | 'portal/example/query-keys'
  | 'portal/writeback-logs'
  | 'portal/online-scheduling/settings'
  | 'portal/online-scheduling/booking-site-setup'
  | 'portal/online-scheduling/installation'
  | 'portal/online-scheduling/custom-fields'
  | 'portal/subscriptions'
  | 'portal/subscriptions/manage-subscription'
  | 'portal/subscriptions/bulk-messaging'
  | 'portal/subscriptions/practice-analytics'
  | 'portal/subscriptions/online-scheduling'
  | 'portal/subscriptions/verify-sign-up'
  | 'portal/subscriptions/text-connect'
  | 'portal/subscriptions/call-intelligence'
  | 'portal/subscriptions/digital-forms'
  | 'portal/partners'
  | 'portal/locations'
  | 'portal/phone/overrides'
  | 'portal/phone/overrides/[id]'
  | 'portal/phone/voicemail-boxes'
  | 'portal/phone/voicemail-boxes/[id]'
  | 'portal/phone/voicemails/mailboxes/[mailboxId]/messages/[messageId]'
  | 'portal/phone/call-blocking'
  | 'portal/phone/line-keys'
  | 'portal/phone/line-keys/[id]'
  | 'portal/phone/call-queues'
  | 'portal/phone/call-queues/[id]'
  | 'portal/phone/departments'
  | 'portal/phone/departments/[id]'
  | 'portal/phone/phone-tree'
  | 'portal/phone/phone-tree/[id]'
  | 'portal/phone/call-records'
  | 'portal/phone/contact-directories'
  | 'portal/phone/contact-directories/[id]'
  | 'portal/phone/call-groups'
  | 'portal/phone/call-groups/[id]'
  | 'portal/phone/media-library'
  | 'portal/phone/mainline'
  | 'portal/phone/mainline/[id]'
  | 'portal/phone/hold-music'
  | 'portal/phone/devices'
  | 'portal/phone/devices/[id]'
  | 'portal/marketing'
  | 'portal/marketing/filters'
  | 'portal/marketing/settings'
  | 'portal/marketing/gallery'
  | 'portal/marketing/campaigns'
  | 'portal/marketing/campaigns/[type]'
  | 'portal/marketing/campaign/[id]'
  | 'portal/marketing/campaign/[id]/[status]'
  | 'portal/marketing/templates'
  | 'portal/marketing/templates/[templateId]'
  | 'portal/marketing/filter/[filterId]'
  | 'portal/account/password-policy'
  | 'portal/account/phone-numbers'
  | 'portal/account/phone-numbers/port-additional-numbers'
  | 'portal/account/business-information'
  | 'portal/account/users'
  | 'portal/account/users/edit/[profileId]'
  | 'portal/account/users/invite'
  | 'portal/account/users/invite/[email]'
  | 'portal/account/users/setup-mobile-number/[nonceToken]'
  | 'portal/account/billing'
  | 'portal/data-sync'
  | 'portal/data-sync/new'
  | 'portal/data-sync/[sourceId]'
  | 'portal/analytics/appointment'
  | 'portal/analytics/dashboard'
  | 'portal/analytics/call-intelligence'
  | 'portal/analytics/call-intelligence/[id]'
  | 'portal/analytics/phone'
  | 'portal/analytics/auto-recall'
  | 'portal/analytics/practice'
  | 'portal/analytics/practice/[id]'
  | 'portal/analytics/messaging'
  | 'portal/reviews/settings'
  | 'portal/reviews/analytics'

  //Public Portal Routes
  | 'portal/text-connect-setup/[locationId]'
  | 'portal/schedule-setup/[locationId]'
  | 'portal/user-invite/[nonceToken]/location/[locationId]'
  | 'portal/accept-invite/[nonceToken]/location/[locationId]'
  | 'portal/login';

type NWXRoutes =
  | 'home/new-dashboard'
  | 'home/dashboard'
  | 'home/partners'
  | 'calls'
  | `calls/${'recent' | 'voicemails' | 'call-queue-stats'}`
  | `calls/voicemail-boxes/${string}/messages/${string}`
  | 'payments'
  | 'payments/buy-now-pay-later'
  | `payments/refunds/${string}`
  | 'payments/invoices'
  | `payments/invoices/${string}`
  | 'payments/payouts'
  | `payments/payouts/${string}`
  | 'payments/payment-plans'
  | 'payments/online-bill-pay'
  | 'schedule'
  | 'schedule/calendar'
  | 'schedule/quickfill-history'
  | 'schedule/request-history'
  | 'analytics'
  | 'analytics/dashboard'
  | 'analytics/call-intelligence'
  | 'analytics/call-intelligence/main'
  | `analytics/call-intelligence/follow-ups/${string}`
  | `analytics/call-intelligence/${string}`
  | `analytics/${'practice' | 'call-intelligence' | 'auto-recall' | 'appointment' | 'phone' | 'messaging' | 'reviews'}`
  | `analytics/${
      | 'practice'
      | 'call-intelligence'
      | 'auto-recall'
      | 'appointment'
      | 'phone'
      | 'messaging'
      | 'reviews'}/${string}`
  | `analytics/roi/${
      | 'practice'
      | 'call-intelligence'
      | 'auto-recall'
      | 'appointment'
      | 'phone'
      | 'messaging'
      | 'reviews'}`
  | 'analytics/roi/'
  | 'analytics/practice'
  | 'analytics/practice/main'
  | `analytics/practice/${
      | 'practice'
      | 'call-intelligence'
      | 'auto-recall'
      | 'appointment'
      | 'phone'
      | 'messaging'
      | 'reviews'}`
  | `analytics/practice/${
      | 'practice'
      | 'call-intelligence'
      | 'auto-recall'
      | 'appointment'
      | 'phone'
      | 'messaging'
      | 'reviews'}/${string}`
  | 'contacts'
  | `contacts/${'all-contacts' | 'active' | 'inactive' | 'lists'}`
  | 'onboard'
  | 'reviews'
  | `reviews/${string}`
  | `reviews/${string}/${string}`
  | 'public'
  | 'reputation'
  | 'reputation/survey-center'
  | 'reputation/survey-center/survey-insights'
  | `reputation/survey-center/survey-insights/${string}`
  | 'bulk'
  | `bulk/${'all-campaigns'}`
  | `bulk/${'all-campaigns'}/${'text' | 'email'}`
  | `bulk/${'all-campaigns'}/${'text' | 'email'}/${'new' | string}`
  | `bulk/${'all-campaigns'}/${'text' | 'email'}/${'new' | string}/${'compose' | 'editor' | 'new-email' | 'templates'}`
  | 'fax'
  | `fax/drafts/${string}`
  | `fax/drafts/${string}/finalize`
  | `fax/drafts/${string}/cover`
  | `fax/drafts/${string}/choose-template`
  | `fax/drafts/${string}/upload`
  | `fax/drafts/${string}/begin`
  | 'fax/drafts/new'
  | 'fax/drafts/new-from-template'
  | `fax/${'inbox' | 'drafts' | 'archived' | 'blocked'}`
  | `fax/${'inbox' | 'drafts' | 'archived' | 'blocked'}/${string}`
  | 'messages'
  | `messages/inbox`
  | `messages/inbox/new`
  | `messages/inbox/${string}/${string}`
  | `messages/scheduled`
  | `messages/scheduled/new`
  | `messages/scheduled/${string}/${string}`
  | `messages/drafts`
  | `messages/drafts/new`
  | `messages/drafts/${string}/${string}`
  | `messages/archived`
  | `messages/archived/new`
  | `messages/archived/${string}/${string}`
  | `messages/blocked`
  | `messages/blocked/new`
  | `messages/blocked/${string}/${string}`
  | 'notifications'
  | 'forms'
  | 'forms/kiosk'
  | 'forms/kiosk/patient-kiosk'
  | 'forms/digitization'
  | 'forms/library'
  | `forms/library/forms/${string}`
  | `forms/library/packets/${string}`
  | 'forms/templates'
  | `forms/templates/${string}`
  | 'forms/builder'
  | `forms/builder/${string}`
  | 'forms/submissions'
  | 'forms/submissions/archived'
  | `forms/submissions/archived/${string}`
  | 'forms/submissions/sent'
  | `forms/submissions/sent/${string}`
  | `forms/submissions/inbox/completed/${string}`
  | 'forms/submissions/inbox'
  | `forms/submissions/inbox/sync-manually/${string}`
  | 'forms/submissions/inbox/all'
  | `forms/submissions/inbox/all/${string}`
  | `forms/submissions/inbox/needs-review/${string}`
  | `forms/submissions/inbox/reviewed/${string}`
  | `forms/submissions/inbox/retry-sync/${string}`;

type SettingsRoutes =
  | 'settings/organization'
  | 'settings/organization/messaging-compliance-registration'
  | 'settings/organization/business-verification'
  | 'settings/organization/brand'
  | 'settings/organization/billing-information'
  | 'settings/organization/single-sign-on'
  | 'settings/organization/phone-numbers/port-request'
  | `settings/organization/phone-numbers/port-request/${string}`
  | 'settings/organization/phone-numbers'
  | 'settings/organization/csv-upload'
  | 'settings/organization/csv-upload/map'
  | 'settings/organization/csv-upload/preview'
  | 'settings/organization/users'
  | `settings/organization/users/${string}`
  | 'settings/organization/payment-methods'
  | 'settings/organization/data-sync'
  | 'settings/organization/data-sync/new'
  | `settings/organization/data-sync/${string}`
  | 'settings/organization/invoice-history'
  | `settings/organization/invoice-history/${string}`
  | 'settings/personal'
  | 'settings/personal/preferences/accessibility'
  | 'settings/personal/customize-app'
  | `settings/personal/profile/${string}`
  | 'settings/personal/diagnostics'
  | 'settings/personal/phone/device-connection'
  | `settings/personal/notifications/${string}`
  | 'settings/personal/notifications/position-and-dismissal'
  | 'settings/personal/email/call-intelligence'
  | 'settings/payments/justifi-settings'
  | 'settings/payments'
  | 'settings/payments/general-settings'
  | 'settings/payments/terminals'
  | 'settings/payments/online-bill-pay'
  | 'settings/payments/account-info'
  | 'settings/payments/public-business-information'
  | 'settings/payments/payment-methods'
  | 'settings/schedule'
  | 'settings/schedule/manage-providers'
  | `settings/schedule/manage-providers/${string}`
  | `settings/schedule/manage-providers/${string}/edit/${string}`
  | `settings/schedule/manage-providers/edit/${string}`
  | 'settings/schedule/appointment-types'
  | `settings/schedule/appointment-types/${string}`
  | 'settings/schedule/booking-site-setup'
  | 'settings/schedule/coming-soon'
  | 'settings/schedule/treatment-rooms'
  | `settings/schedule/treatment-rooms/${string}`
  | `settings/schedule/treatment-rooms/${string}/edit/${string}`
  | `settings/schedule/treatment-rooms/edit/${string}`
  | 'settings/schedule/message-templates'
  | `settings/schedule/message-templates/request-received/${string}`
  | `settings/schedule/message-templates/appointment-confirmed/${string}`
  | 'settings/schedule/office-hours/OfficeHoursSettingsPage'
  | 'settings/schedule/office-hours'
  | 'settings/schedule/custom-fields'
  | 'settings/fax'
  | 'settings/fax/history'
  | 'settings/contacts'
  | 'settings/contacts/file-mapping'
  | 'settings/contacts/review-upload'
  | 'settings/contacts/manage-csv'
  | `settings/contacts/manage-csv/${string}`
  | 'settings/phone/overrides'
  | 'settings/phone/call-routes'
  | 'settings/phone'
  | 'settings/phone/call-blocking'
  | 'settings/phone/line-keys'
  | `settings/phone/line-keys/${string}`
  | 'settings/phone/call-queues'
  | `settings/phone/call-queues/${string}`
  | 'settings/phone/call-recording'
  | 'settings/phone/departments'
  | `settings/phone/departments/${string}`
  | 'settings/phone/phone-tree'
  | `settings/phone/phone-tree/${string}`
  | 'settings/phone/contact-directories'
  | `settings/phone/contact-directories/${string}`
  | 'settings/phone/call-groups'
  | `settings/phone/call-groups/${string}`
  | 'settings/phone/office-hours'
  | 'settings/phone/phone-hours'
  | 'settings/phone/e911'
  | 'settings/phone/main'
  | 'settings/phone/mainline'
  | 'settings/phone/hold-music'
  | 'settings/phone/devices'
  | `settings/phone/devices/${string}`
  | 'settings/phone/audio-library'
  | 'settings/phone/voicemail-box'
  | `settings/phone/voicemail-box/${string}`
  | 'settings/subscriptions'
  | 'settings/subscriptions/manage-subscription'
  | 'settings/subscriptions/feature/email-marketing'
  | 'settings/subscriptions/feature/bulk-messaging'
  | `settings/subscriptions/feature/${string}`
  | 'settings/tags'
  | `settings/tags/edit/${string}`
  | `settings/tags/duplicate/${string}`
  | 'settings/tags/quick-create'
  | 'settings/tags/create'
  | 'settings/image-gallery'
  | 'settings/messaging'
  | 'settings/messaging/mark-confirmation-as-read'
  | 'settings/messaging/auto-messages'
  | `settings/messaging/auto-messages/${string}/editor`
  | 'settings/messaging/auto-message-inbox-visibility'
  | 'settings/messaging/record-history'
  | `settings/messaging/record-history/appointment/${string}`
  | `settings/messaging/record-history/contactinfo/${string}`
  | `settings/messaging/record-history/order/${string}`
  | `settings/messaging/record-history/person/${string}`
  | `settings/messaging/record-history/recall/${string}`
  | 'settings/messaging/text-connect'
  | `settings/messaging/text-connect/${string}`
  | 'settings/messaging/message-queue'
  | 'settings/messaging/templates'
  | `settings/messaging/templates/inbox-edit/${string}`
  | `settings/messaging/templates/inbox-edit/${string}/review-landing-page-editor`
  | `settings/messaging/templates/inbox-edit/${string}/review-landing-page-editor/${string}`
  | `settings/messaging/templates/edit/${string}`
  | `settings/messaging/templates/edit/${string}/review-landing-page-editor`
  | `settings/messaging/templates/edit/${string}/review-landing-page-editor/${string}`
  | `settings/messaging/templates/duplicate/${string}`
  | `settings/messaging/templates/duplicate/${string}/review-landing-page-editor`
  | `settings/messaging/templates/duplicate/${string}/review-landing-page-editor/${string}`
  | 'settings/messaging/templates/import'
  | 'settings/messaging/templates/create'
  | 'settings/messaging/templates/create/review-landing-page-editor'
  | `settings/messaging/templates/create/review-landing-page-editor/${string}`
  | 'settings/messaging/show-unreplied-message-status'
  | 'settings/insurance'
  | 'settings/insurance/automatic-verification'
  | `settings/insurance/automatic-verification/${string}`
  | 'settings/digital-forms'
  | 'settings/digital-forms/medical-history'
  | 'settings/digital-forms/medical-history/medications'
  | 'settings/digital-forms/medical-history/medical-conditions'
  | 'settings/digital-forms/medical-history/allergies'
  | 'settings/digital-forms/provider-mapping'
  | 'settings/digital-forms/privacy-policy'
  | 'settings/digital-forms/customize-kiosk'
  | 'settings/digital-forms/writebacks'
  | 'settings/digital-forms/writebacks/settings'
  | 'settings/digital-forms/writebacks/sources'
  | 'settings/digital-forms/terms-and-conditions'
  | 'settings/email'
  | 'settings/email/filters'
  | 'settings/email/settings'
  | 'settings/email/domain-verify'
  | 'settings/email/gallery'
  | 'settings/email/campaigns'
  | 'settings/email/campaigns/sent'
  | 'settings/email/campaigns/drafts'
  | 'settings/email/campaigns/scheduled'
  | `settings/email/campaign/${string}`
  | `settings/email/campaign/${string}/${'sent' | 'drafts' | 'scheduled'}`
  | 'settings/email/templates'
  | `settings/email/templates/${string}`
  | 'settings/email/manage'
  | `settings/email/filter/${string}`
  | 'settings/reviews';
