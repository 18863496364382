import { ComponentProps, useMemo } from 'react';
import { css } from '@emotion/react';
import { Row } from 'react-table';
import { useTranslation } from '@frontend/i18n';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { useAppScopeStore, WeaveLocationGroup } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { TableColumnConfig, TableProps, Chip, Table, TrashIcon, TableRowActions } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../store/settings';

export const IndexTable = <T extends object, C extends TableColumnConfig<T>[]>({
  tenantLocation,
  idAccessor,
  labelsAccessor,
  labelsColumnPosition = 1,
  nameAccessor,
  filteredLocations,
  setFilteredLocations,
  DeleteIconButton,
  onRowClick,
  canDeleteRow,
  tableConfig,
  trackingIdBase,
  showDelete = true,
  rowActions = [],
}: {
  tenantLocation: WeaveLocationGroup;
  idAccessor: (data: T) => string;
  labelsAccessor: (data: T) => string[];
  labelsColumnPosition?: number;
  nameAccessor: (data: T) => string;
  filteredLocations: string[];
  setFilteredLocations: (locations: string[]) => void;
  DeleteIconButton?: React.FC<{ name: string; id: string; phoneTenantId: string; disabled?: boolean }>;
  /**
   * This prop is unique in that we single it out of the tableConfig prop so allow consumers to modify just the row click behavior within the `rowActions` field
   */
  onRowClick?: (row: T) => void;
  canDeleteRow?: (row: T) => boolean;
  tableConfig: TableProps<T, C>;
  trackingIdBase?: string;
  showDelete?: boolean;
  rowActions?: TableRowActions<T>['actions'];
}) => {
  const { t } = useTranslation('phone');
  const { getScopeName } = useAppScopeStore();
  const isUnify = tenantLocation && tenantLocation.locationType === 'unify';

  const config: TableProps<T, C> = useMemo(() => {
    // This is the common config we want all tables to share
    const defaultConfig: TableProps<T, C> = {
      fullHeight: true,
      uniqueRowId: idAccessor,
      keepPreviousDataWhileLoading: true,
      rowActions: {
        onRowClick,
        actions: !showDelete
          ? undefined
          : showDelete && DeleteIconButton
          ? [
              {
                label: t('Delete'),
                Icon: TrashIcon,
                disabled: (row) => !canDeleteRow?.(row),
                renderCustom: (_getItemProps, _index, _close, _isSingleAction, rowData) => {
                  if (!tenantLocation.phoneTenantId) {
                    return null;
                  }
                  return (
                    <DeleteIconButton
                      key={`$delete-${idAccessor(rowData)}`}
                      name={nameAccessor(rowData)}
                      id={idAccessor(rowData)}
                      phoneTenantId={tenantLocation.phoneTenantId}
                      disabled={!canDeleteRow?.(rowData)}
                    />
                  );
                },
              },
              ...rowActions,
            ]
          : rowActions,
      },
      hasGlobalSearch: true,
      customToolbarRender:
        tenantLocation?.locationType !== 'single'
          ? () => (
              <PhoneSettingsScopedLocationFilterMenu
                selectedOptions={filteredLocations}
                onChange={setFilteredLocations}
                trackingIdBase={trackingIdBase}
              />
            )
          : undefined,
      isPaginated: true,
      wrapperStyle: css`
        .table-toolbar {
          padding: 0;
          display: grid;
          gap: ${theme.spacing(1)};
          grid-template-columns: auto 1fr auto;
          > div:first-of-type {
            grid-column: 1;
          }
          > div:last-of-type {
            margin: 0;
            width: auto;
            grid-column: 3;
          }
        }
      `,
      ...tableConfig,
    };

    // This is the column config for the labels column
    const labelsColumnConfig = {
      id: 'labels',
      Header: 'Locations',
      accessor: (data: T) => {
        const locationIds = labelsAccessor(data);
        if (!locationIds) {
          return;
        }
        if (locationIds.length === 1) {
          return { label: getScopeName(locationIds[0]), type: 'single' };
        } else if (locationIds.length > 1) {
          return { label: t('{{count}} locations', { count: locationIds.length }), type: 'multi' };
        } else {
          return { label: tenantLocation.name, type: 'multi' };
        }
      },
      cellRenderer: (data: { label: string; type: 'multi' | 'single' }) =>
        data.type === 'multi' ? (
          <Chip.MultiChip>{data.label}</Chip.MultiChip>
        ) : (
          <Chip.SingleChip>{data.label}</Chip.SingleChip>
        ),
      width: 150,
      omit: !isUnify,
      sortType: (a: Row<T>, b: Row<T>) => {
        return a.values.labels.label.localeCompare(b.values.labels.label);
      },
    } as unknown as TableColumnConfig<T>;

    // Insert the labels column config at the specified position
    const config = {
      ...defaultConfig,
      colConfig: [
        ...defaultConfig.colConfig.slice(0, labelsColumnPosition),
        labelsColumnConfig,
        // Not replacing items, so we don't need to +1 here
        ...defaultConfig.colConfig.slice(labelsColumnPosition),
      ],
    };

    return config as TableProps<T, C>;
  }, [
    canDeleteRow,
    idAccessor,
    labelsAccessor,
    nameAccessor,
    onRowClick,
    setFilteredLocations,
    tableConfig,
    tenantLocation,
    t,
    labelsColumnPosition,
  ]);

  return <Table {...config} />;
};

export const PhoneSettingsScopedLocationFilterMenu = ({
  selectedOptions,
  onChange,
  trackingIdBase,
}: Pick<ComponentProps<typeof LocationFilterMenu>, 'selectedOptions' | 'onChange' | 'trackingIdBase'>) => {
  const { accessibleLocationData } = useAppScopeStore();
  const { globalAvailableLocationIds } = usePhoneSettingsShallowStore('globalAvailableLocationIds');
  const locallyAvailableLocations =
    globalAvailableLocationIds?.map((id) => accessibleLocationData[id]).filter(Boolean) ?? [];

  return (
    <LocationFilterMenu
      locations={locallyAvailableLocations}
      selectedOptions={selectedOptions}
      onChange={onChange}
      trackingIdBase={trackingIdBase}
    />
  );
};
