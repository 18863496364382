import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { CategoryBarChartData, CategoryBarChartProps, Chart, CustomColumnRendererParams } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useAnalyticsOrgLocations } from '../../../../../hooks';
import { formatters } from '../../../../../utils';
import { REVENUE_MULTIPLIER } from '../../../constants';
import { useFetchMessagingROIOOverview } from '../../../hooks';
import { MessagingRoi } from '../../../types';

const colors = {
  existingPatients: theme.colors.primary20,
  newPatients: theme.colors.indigo50,
  unscheduledPatients: theme.colors.warning10,
};

const strokeColors = {
  existingPatients: theme.colors.primary40,
  newPatients: theme.colors.indigo60,
  unscheduledPatients: theme.colors.warning20,
};

const labels = {
  existingPatients: i18next.t('Existing Patients', { ns: 'analytics' }),
  groupNameHeader: i18next.t('Location', { ns: 'analytics' }),
  newPatients: i18next.t('New Patients', { ns: 'analytics' }),
  unscheduledPatients: i18next.t('Unscheduled Leads', { ns: 'analytics' }),
};
interface MultiComparisonChartProps extends ReturnType<typeof useFetchMessagingROIOOverview> {
  title: string;
}

export const MultiComparisonChart = ({
  aggregated,
  isLoading,
  locationsData = {},
  title,
}: MultiComparisonChartProps) => {
  const { t } = useTranslation('analytics');
  const search = useSearch<{ Search: { view: MessagingRoi } }>();
  const { locationNames } = useAnalyticsOrgLocations({
    module: 'ROI',
  });

  const processedData = useMemo(() => {
    const chartData: CategoryBarChartData = {};
    const customColumns: CategoryBarChartProps['customColumns'] = [];
    let legendsValues: Record<string, string> = {};

    switch (search.view) {
      case 'leadsContacted':
        Object.values(locationsData).forEach(({ location_id, leads_contacted }) => {
          const newPatients = leads_contacted?.new_patients_counts || 0;
          const existingPatients = leads_contacted?.existing_patients_counts || 0;
          const unscheduledPatients = leads_contacted?.unscheduled_patients_counts || 0;

          chartData[location_id] = {
            newPatients,
            unscheduledPatients,
            existingPatients,
          };

          customColumns[0] = {
            columnHeaderId: 'patientsContacted',
            columnHeaderLabel: t('Patients Contacted'),
            data: {
              ...(customColumns[0]?.data || {}),
              [location_id]: newPatients + existingPatients + unscheduledPatients,
            },
          };
        });

        legendsValues = {
          newPatients: formatters.value.format(aggregated?.leads_contacted?.new_patients_counts || 0),
          existingPatients: formatters.value.format(aggregated?.leads_contacted?.existing_patients_counts || 0),
          unscheduledPatients: formatters.value.format(aggregated?.leads_contacted?.unscheduled_patients_counts || 0),
        };
        break;

      case 'patientsScheduled':
        Object.values(locationsData).forEach(({ location_id, patients_scheduled }) => {
          const newPatients = patients_scheduled?.new_patients_counts || 0;
          const existingPatients = patients_scheduled?.existing_patients_counts || 0;

          chartData[location_id] = {
            newPatients,
            existingPatients,
          };

          customColumns[0] = {
            columnHeaderId: 'patientsScheduled',
            columnHeaderLabel: t('Patients Scheduled'),
            data: {
              ...(customColumns[0]?.data || {}),
              [location_id]: newPatients + existingPatients,
            },
          };
        });

        legendsValues = {
          newPatients: formatters.value.format(aggregated?.patients_scheduled?.new_patients_counts || 0),
          existingPatients: formatters.value.format(aggregated?.patients_scheduled?.existing_patients_counts || 0),
        };
        break;

      case 'revenueGenerated':
        Object.values(locationsData).forEach(({ location_id, appointments_completed }) => {
          const newPatients = (appointments_completed?.new_patients_appts_counts || 0) * REVENUE_MULTIPLIER;
          const existingPatients = (appointments_completed?.existing_patients_appts_counts || 0) * REVENUE_MULTIPLIER;

          chartData[location_id] = {
            newPatients,
            existingPatients,
          };

          customColumns[0] = {
            columnHeaderId: 'revenueGenerated',
            columnHeaderLabel: t('Revenue Generated'),
            data: {
              ...(customColumns[0]?.data || {}),
              [location_id]: newPatients + existingPatients,
            },
          };
        });

        legendsValues = {
          newPatients: formatters.currency.format(
            (aggregated?.appointments_completed?.new_patients_appts_counts || 0) * REVENUE_MULTIPLIER
          ),
          existingPatients: formatters.currency.format(
            (aggregated?.appointments_completed?.existing_patients_appts_counts || 0) * REVENUE_MULTIPLIER
          ),
        };
        break;
    }

    return {
      aggregated,
      chartData,
      customColumns,
      legendsValues,
    };
  }, [locationsData, search.view]);

  const renderCustomColumnValue = useCallback(
    ({ groupName, value }: CustomColumnRendererParams) => {
      const locationData = locationsData?.[groupName];

      switch (search.view) {
        case 'leadsContacted': {
          const leadsContacted = locationData.leads_contacted;
          return (
            <CustomColumnRenderer
              mainText={t('Patients')}
              subText={t('{{count}} Messages sent', { count: leadsContacted?.sms_count || 0 })}
              value={value}
            />
          );
        }

        case 'patientsScheduled': {
          const leadsContacted = locationData?.patients_scheduled;
          return (
            <CustomColumnRenderer
              mainText={t('Patients')}
              subText={t('{{count}} Appointments', { count: leadsContacted?.appointments_count || 0 })}
              value={value}
            />
          );
        }

        case 'revenueGenerated':
          return (
            <Text style={{ fontSize: theme.fontSize(24) }} weight='bold'>
              {value}
            </Text>
          );
      }

      return null;
    },
    [search.view, locationsData]
  );

  return (
    <Chart
      colors={colors}
      isLoading={isLoading}
      labels={{ ...labels, ...locationNames }}
      strokeColors={strokeColors}
      style={{ marginBottom: 0 }}
    >
      <Chart.Header title={title} />
      <Chart.CategoryBarChart
        appearance={{
          alwaysApplyBarBorders: true,
          barBorderWidth: 1,
          barSize: 40,
          collectiveTooltip: true,
          formatters: search.view === 'revenueGenerated' ? formatters.currency.format : formatters.value.format,
          groupsGap: 40,
          mode: 'stacked',
        }}
        customColumns={processedData.customColumns}
        customColumnValueRenderer={renderCustomColumnValue}
        data={processedData.chartData}
        highestValueConfig={{
          color: theme.colors.warning40,
          text: t('Highest Conversion'),
        }}
        sortConfig={{
          allowUserToSort: true,
        }}
        trackingIdBase='messaging-roi-multi-comparison'
      >
        <Chart.Legends values={processedData.legendsValues} />
      </Chart.CategoryBarChart>
    </Chart>
  );
};

interface CustomColumnRendererProps {
  mainText: string;
  subText: string;
  value: number | string;
}

const CustomColumnRenderer = ({ mainText, subText, value }: CustomColumnRendererProps) => {
  return (
    <div>
      <div
        css={css`
          align-items: baseline;
          display: flex;
          gap: ${theme.spacing(0.5)};
        `}
      >
        <Text style={{ fontSize: theme.fontSize(20) }} weight='bold'>
          {value}
        </Text>
        <Text size='small'>{mainText}</Text>
      </div>
      <Text color='subdued' size='small'>
        {subText}
      </Text>
    </div>
  );
};
