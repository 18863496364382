import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../../utils';
import { InfoTipPopover } from '../../../info-tip-popover';
import { ScheduleRateBanner } from '../components';
import { useBTRoiShallowStore, useFetchBTRoiScheduleRate } from '../hooks';

const colors = {
  weaveAverage: theme.colors.secondary.eggplant50,
  yourScheduleRate: theme.colors.secondary.seaweed40,
};

const labels = {
  weaveAverage: i18next.t('Weave Average Appointment Schedule Rate', { ns: 'analytics' }),
  yourScheduleRate: i18next.t('Your Appointment Schedule Rate', { ns: 'analytics' }),
};

const calcNote = i18next.t(
  'Appointment Schedule Rate (%) = Number of Appointments Scheduled / Number of Bulk Messages Sent * 100',
  { ns: 'analytics' }
);

export const BTRoiScheduleRateChart = () => {
  const { t } = useTranslation('analytics');
  const { filters } = useBTRoiShallowStore('filters');

  const { isLoading, scheduleRate } = useFetchBTRoiScheduleRate({
    apiParams: {
      payload: {
        start_date: filters?.startDate,
        end_date: filters?.endDate,
        location_id: filters?.locationIds,
      },
    },
  });

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        title={t('Appointments Schedule Rate')}
        bottomElement={
          <div style={{ marginTop: theme.spacing(1) }}>
            <Text size='large' style={{ color: theme.colors.neutral70 }}>
              {t(
                'This chart shows the percentage of bulk texts sent that successfully resulted in scheduled appointments.'
              )}
            </Text>
            <Text color='subdued' size='small'>
              {calcNote}
            </Text>
          </div>
        }
        infoTip={
          <InfoTipPopover>
            <Text>{calcNote}</Text>
            <Text>
              {t(
                'This metric represents the percentage of bulk messages that successfully led to scheduled appointments.'
              )}
            </Text>
          </InfoTipPopover>
        }
      />
      <Chart.AreaChart
        appearance={{
          collectiveTooltip: true,
          customTooltipData: (dataId) => [
            {
              color: colors.yourScheduleRate,
              formattedValue: formatters.percent.appendPercent(scheduleRate.dates[dataId]),
              id: 'yourScheduleRate',
              label: labels.yourScheduleRate,
            },
            {
              color: colors.weaveAverage,
              formattedValue: formatters.percent.appendPercent(scheduleRate.weaveAverage),
              id: 'weaveAverage',
              label: labels.weaveAverage,
            },
          ],
          customYAxisTickFormat: formatters.percent.appendPercent,
          height: 200,
          margin: { top: 30 },
          showXAxis: true,
          showYAxis: true,
          xAxisTickWidth: 100,
        }}
        data={{
          groups: scheduleRate.chartDataGroups,
          markers: scheduleRate.showWeaveAverageGraph
            ? [
                {
                  id: 'weaveAverage',
                  value: scheduleRate.weaveAverage,
                  color: colors.weaveAverage,
                },
              ]
            : [],
        }}
        formatValue={formatters.percent.appendPercent}
      />
      <Chart.Legends
        customData={{
          yourScheduleRate: {
            color: colors.yourScheduleRate,
            label: labels.yourScheduleRate,
          },
          weaveAverage: {
            color: colors.weaveAverage,
            label: labels.weaveAverage,
          },
        }}
      />
      {scheduleRate.showWeaveAverageGraph && <ScheduleRateBanner {...scheduleRate} />}
    </Chart>
  );
};
