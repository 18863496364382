import { memo, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Slider, Unit } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { FieldProps, InfoRoundIconSmall, Text } from '@frontend/design-system';
import { formConfigBase, SectionHeader, SettingSection } from './primitives';

interface IHoldTimeSetting {
  field: FieldProps<typeof formConfigBase, 'holdTime'>;
  initialAnnouncement: number;
  repeatFrequency: number;
}

const validateMaxHoldTime = (initial = 0, repeat = 0, wait = 0) => {
  return initial + repeat < wait;
};

export const HoldTimeSetting = memo(({ field, initialAnnouncement, repeatFrequency }: IHoldTimeSetting) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-queue' });
  const [isValid, setIsValid] = useState(true);
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const departmentsFeatureFlag = getFeatureFlagValue('departments');

  useEffect(() => {
    setIsValid(validateMaxHoldTime(initialAnnouncement, repeatFrequency, Number(field.value)));
  }, [initialAnnouncement, repeatFrequency, field.value]);
  return (
    <SettingSection>
      <SectionHeader>
        <Text weight='bold'>{t('Maximum Caller Hold Time')}</Text>
        <Text
          size='medium'
          color='light'
          css={css`
            max-width: 700px;
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {departmentsFeatureFlag
            ? t(
                'The maximum time the caller will be in the Call Queue. After the maximum time has been reached, the caller will be sent to the fallback option.'
              )
            : t(
                'The maximum time the caller will be in the Call Queue. After the maximum time has been reached, the caller will be sent to the fallback option, which is set by Weave.'
              )}
        </Text>
      </SectionHeader>
      <div style={{ padding: theme.spacing(2), maxWidth: 420 }}>
        <Slider
          min={1}
          max={60}
          values={[Number(field.value) / 1000 / 60]}
          units={Unit.Minutes}
          step={1}
          mode={3}
          onChange={([length]) => {
            field.onChange({
              name: field.name,
              value: (length * 1000 * 60).toString(),
            });
          }}
        />
      </div>
      {!isValid && (
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          <InfoRoundIconSmall />
          <Text as='span' size='medium' color='light'>
            {t('The position announcement repeat time is more than your maximum caller hold time.')}
          </Text>
        </div>
      )}
    </SettingSection>
  );
});
