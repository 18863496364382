import { useScopedAppFlagStore } from '@frontend/scope';
import { useIsIntegratedOffice } from '../../../../hooks';

export const useHasMCTRoiFeature = () => {
  const { isIntegratedOffice } = useIsIntegratedOffice();
  const { getCustomizationFlagValue } = useScopedAppFlagStore();
  const isCFEnabled = getCustomizationFlagValue('missedcalltext');

  return {
    isMCTRoiEligibleLocation: !!isIntegratedOffice && !!isCFEnabled,
  };
};
