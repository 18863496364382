import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useTooltip } from '@frontend/design-system';

export const OptOutIcon = () => {
  const { t } = useTranslation('schedule-quick-fill');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  return (
    <>
      <div {...triggerProps} css={{ flexShrink: 0 }}>
        <Icon name='opt-out-small' />
      </div>
      <Tooltip {...tooltipProps}>{t('Opted Out')}</Tooltip>
    </>
  );
};
