import { useMemo } from 'react';
import { theme } from '@frontend/theme';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { MentionTag } from './mention-tag';

/**
 *
 * Splits a string by delimiters but retains the delimiters in the result
 *  note: by sorting it in descending order, it ensures that matches that are substrings of another match do not match first
 *   EG: Text: "Hello @John Smith and @Paul Bunyan""
 *   EG: Delimiters: ["@John Smith", "@John", "@Paul Bunyan"]
 *   EG: Result: ["Hello", "@John Smith", "and", "@Paul Bunyan"]
 */
const splitAndRetain = (str: string, delimiters: string[]) => {
  delimiters.sort((a, b) => b.length - a.length);
  const pattern = new RegExp(`(${delimiters.map((d) => d.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'g');
  return str.split(pattern).filter(Boolean);
};

export const MessageBodyText = ({ message }: { message: string }) => {
  const users = useTeamChatSelector((ctx) => ctx.users);
  const currentUser = useTeamChatSelector((ctx) => ctx.user);

  const usernames = useMemo(() => {
    const usersIncludingSelf = [...(users ?? []), currentUser].filter((u) => !!u);
    return usersIncludingSelf.map((user) => `@${getUserFullName(user.firstName, user.lastName)}`.trim());
  }, [users, currentUser]);

  const userIds = useMemo(() => {
    const usersIncludingSelf = [...(users ?? []), currentUser].filter((u) => !!u);
    return usersIncludingSelf.map((user) => `@${user.userID}`);
  }, [users, currentUser]);

  const nodes = useMemo(
    () =>
      splitAndRetain(message, [...usernames, ...userIds]).map((node) => {
        const isMention = node.startsWith('@');

        // In some contexts the mentions might look like @Johnny Cash, and in others they might look like @ORG_ID_1234
        // Either way we want to parse out the underlying user
        const user = node.startsWith('@ORG_ID_')
          ? users.find((user) => `@${user.userID}` === node)
          : node.startsWith('@')
          ? users.find((user) => `@${getUserFullName(user.firstName, user.lastName)}`.trim() === node)
          : undefined;

        const isSelf = user?.userID === currentUser?.userID;
        return isMention && user ? (
          <MentionTag key={node} label={`@${getUserFullName(user.firstName, user.lastName)}`} isSelf={isSelf} />
        ) : (
          node
        );
      }),
    [message, usernames, currentUser, userIds]
  );

  return <span style={{ marginRight: theme.spacing(0.5) }}>{nodes}</span>;
};
