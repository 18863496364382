import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { InvoiceModelV3 } from '@frontend/api-invoices';
import { convertToCents } from '@frontend/currency';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useInvalidateUserInvoicesInRange, useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import {
  CreateInvoiceForm,
  DropZoneOverlay,
  shouldShowModal,
  ThresholdModals,
  useCreateInvoiceForm,
  useCreatePaymentRequest,
} from '@frontend/person-invoice-create';
import { useInvalidateActiveInvoiceQueries } from '@frontend/person-invoice-list';
import { breakpoints } from '@frontend/responsiveness';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { ContentLoader, Tray, Text, IconButton, useModalControl } from '@frontend/design-system';

export type OnInvoiceCreated = (invoice: InvoiceModelV3) => void;

type CreatePaymentRequestModalProps = ReturnType<typeof useModalControl>['modalProps'] & {
  onInvoiceCreated: OnInvoiceCreated;
};

const styles = {
  modal: css`
    padding: 0;
    @media (min-width: ${breakpoints.medium.min}px) {
      max-width: ${theme.spacing(63)};
    }
  `,
  paymentDetailsTitle: css`
    margin: ${theme.spacing(2, 0)};
  `,
  modalHeader: css`
    padding: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(2)};
  `,
  modalBody: css`
    overflow-y: initial;
    padding: ${theme.spacing(4)};
    padding-top: ${theme.spacing(0)};
  `,
  modalActions: css`
    padding: ${theme.spacing(4)};
    padding-top: ${theme.spacing(0)};
  `,
};

export const CreatePaymentRequestModal = ({ onInvoiceCreated, ...props }: CreatePaymentRequestModalProps) => {
  return (
    <Tray {...props} css={styles.modal} mountTarget='#root' data-trackingid='create-request-modal-tray'>
      <ModalBodyStateContainer onClose={props.onClose} onInvoiceCreated={onInvoiceCreated} />
    </Tray>
  );
};

const ModalBodyStateContainer = ({
  onClose,
  onInvoiceCreated,
}: Pick<CreatePaymentRequestModalProps, 'onClose' | 'onInvoiceCreated'>) => {
  const { t } = useTranslation('payments');

  const queryClient = useQueryClient();
  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const {
    createFormProps,
    dropZoneProps,
    resetFields,
    attachment,
    selectedPerson: V2SelectedPerson,
    skipAttachmentAuth,
  } = useCreateInvoiceForm();

  const {
    form: { values, isComplete },
  } = createFormProps;
  const { createPaymentRequest, creatingPaymentRequest } = useCreatePaymentRequest({ locationId: values.locationId });
  const { modalProps: thresholdModalProps, triggerProps: thresholdTriggerProps } = useModalControl();
  const invalidateActiveInvoiceQueries = useInvalidateActiveInvoiceQueries();
  const invalidateUserInvoicesInRange = useInvalidateUserInvoicesInRange();

  const handleCreateInvoice = async () => {
    await createPaymentRequest({
      personId: values.personId || '',
      amount: convertToCents(Number(values.amount)),
      attachment,
      mobileNumber: V2SelectedPerson?.MobilePhone,
      memo: values.memo,
      providerName: values.provider,
      skipAttachmentAuth,
    }).then((invoice) => {
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(values.amount),
      });
      onInvoiceCreated(invoice);
      queryClient.invalidateQueries(invoicesQueryKey);

      /**
       * BE race conditions.
       * Query returns stale data if run immediately
       */
      setTimeout(() => {
        invalidateActiveInvoiceQueries();
        invalidateUserInvoicesInRange(invoice.personId);
      }, 2000);
    });
  };

  return (
    <>
      <ContentLoader show={creatingPaymentRequest} message={t('Creating Payment Request')} />
      <Tray.Header
        css={styles.modalHeader}
        Buttons={
          <IconButton onClick={onClose} label='Close Filters'>
            <Icon name='x' />
          </IconButton>
        }
      >
        {t('New Invoice')}
      </Tray.Header>
      <DropZoneOverlay {...dropZoneProps}>
        <Tray.Body css={styles.modalBody}>
          <Text size='large' weight='bold' css={styles.paymentDetailsTitle}>
            {t('Invoice Details')}
          </Text>
          <CreateInvoiceForm {...createFormProps} />
        </Tray.Body>
        <Tray.Actions
          css={styles.modalActions}
          onSecondaryClick={() => {
            resetFields();
            return true;
          }}
          onPrimaryClick={shouldShowModal(values.amount) ? thresholdTriggerProps.onClick : handleCreateInvoice}
          primaryLabel={!creatingPaymentRequest ? t('Create') : t('Creating Payment Request')}
          disabledPrimary={!isComplete || creatingPaymentRequest}
          secondaryLabel={t('Cancel')}
        />
      </DropZoneOverlay>
      <ThresholdModals {...thresholdModalProps} amount={values.amount} onConfirm={handleCreateInvoice} />
    </>
  );
};
