import { CSSProperties, MouseEvent, memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { handleActiveLegendChange } from '../helpers';
import { FormatValue } from '../types';
import { CustomLegendData, Legend, Size } from './legend';

export type CustomLegendsData = {
  [legendId: string]: CustomLegendData;
};

type LegendsProps = {
  className?: string;
  doNotCollapseLegends?: boolean;
  formatValue?: FormatValue;
  fullSpacing?: boolean;
  highlightOnSegmentHover?: boolean;
  labelsTransform?: CSSProperties['textTransform'];
  layout?: 'horizontal' | 'vertical';
  size?: Size;
  style?: CSSProperties;
  values?: Record<string, number | string>;
} & (
  | {
      customData?: CustomLegendsData;
      noop?: never;
    }
  | {
      customData?: never;
      noop?: boolean;
    }
);

export const Legends = memo(
  ({
    className = '',
    customData = {},
    doNotCollapseLegends,
    formatValue,
    fullSpacing,
    highlightOnSegmentHover,
    labelsTransform,
    layout = 'horizontal',
    noop,
    size = 'medium',
    style,
    values,
  }: LegendsProps) => {
    const { t } = useTranslation('analytics');
    const {
      activeLegends = [],
      areSegmentsExpanded,
      collapsedLegends = [],
      hoveredSegment,
      onActiveLegendsChange,
      primaryLegendsList,
      setAreSegmentsExpanded,
    } = useChartContext();

    const customLegends = Object.keys(customData);
    const hasCustomLegends = !!customLegends.length;

    const handleLegendClick = useCallback(
      (id: string) => {
        handleActiveLegendChange({
          activeLegends,
          allLegends: primaryLegendsList,
          legendId: id,
          onActiveLegendsChange,
        });
      },
      [activeLegends, onActiveLegendsChange, primaryLegendsList]
    );

    const handleShowAllClick = useCallback(
      (e: MouseEvent) => {
        e.stopPropagation();
        setAreSegmentsExpanded(!areSegmentsExpanded);
      },
      [areSegmentsExpanded]
    );

    if (!(hasCustomLegends ? customLegends : primaryLegendsList).length) {
      return null;
    }

    return (
      <div
        className={`legends-wrapper ${className} ${layout}`.trim()}
        css={styles.legendsWrapper}
        style={fullSpacing ? { justifyContent: 'space-evenly', ...style } : style}
      >
        {(hasCustomLegends ? customLegends : primaryLegendsList).map((id) => {
          const isHovered = highlightOnSegmentHover && hoveredSegment ? hoveredSegment === id : null;

          return !doNotCollapseLegends && collapsedLegends.includes(id) && !areSegmentsExpanded ? null : (
            <Legend
              customData={customData[id]}
              formatValue={formatValue}
              isHovered={isHovered}
              key={id}
              labelTransform={labelsTransform}
              legendId={id}
              legendValue={values?.[id]}
              onClick={noop || hasCustomLegends ? null : () => handleLegendClick(id)}
              size={size}
            />
          );
        })}
        {!!collapsedLegends.length && (
          <NakedButton className='no-pdf' css={styles.showMoreButton} onClick={handleShowAllClick} size='small'>
            {areSegmentsExpanded ? t('Show Less') : t('Show All')}
            <Icon name={areSegmentsExpanded ? 'caret-up-small' : 'caret-down-small'} />
          </NakedButton>
        )}
      </div>
    );
  }
);

Legends.displayName = 'Legends';

const styles = {
  legendsWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1, 4)};
    justify-content: flex-start;

    &.vertical {
      flex-direction: column;
    }

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      justify-content: center;
    }
  `,

  showMoreButton: css`
    align-items: center;
    color: ${theme.colors.primary40};
    display: flex;
    font-size: ${theme.font.size.medium};
    gap: ${theme.spacing(0.5)};

    &:hover {
      color: ${theme.colors.primary60};
    }
  `,
};
