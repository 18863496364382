import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { CreateAndTermsConditionModal } from '@frontend/payments-setup';
import { theme } from '@frontend/theme';
import {
  Text,
  TextLink,
  useFormField,
  useModalControl,
  GiftIcon,
  GraduationCapIcon,
  RadioField,
  Button,
} from '@frontend/design-system';
import { PageTitleWithLocationChip } from '../Shared';

const UPSELL_LINK = 'https://www.weavehelp.com/hc/en-us/categories/360006216791-Payments';
const FREE_TERMINAL_LINK = 'https://try.getweave.com/payments/';

const styles = {
  promoCard: css`
    display: flex;
    flex-flow: row;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  `,
  blockIcon: css`
    display: inline;
  `,
  roleRequiredModalHeader: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};

interface PaymentsSetupProps {
  stripeCreatedNotOnboarded: boolean;
  isSettingsPage?: boolean;
  refetchQueries?: boolean;
}

export const PaymentsSetup = ({
  stripeCreatedNotOnboarded = false,
  isSettingsPage = false,
  refetchQueries,
}: PaymentsSetupProps) => {
  const { t } = useTranslation('payments');

  const radioFieldProps = useFormField({ type: 'radio', required: true });
  const { modalProps: creationCheckModalProps, triggerProps: creationCheckTriggerProps } = useModalControl();

  const buttonTitle = isSettingsPage ? t('Create Stripe Account') : t('Setup Account');
  const businessTypeRadioLabel = isSettingsPage ? t('Please select the type of Business') : t('Type of Business');

  return (
    <>
      {!stripeCreatedNotOnboarded && (
        <RadioField
          name='business_type'
          label={businessTypeRadioLabel}
          {...radioFieldProps}
          css={css`
            min-width: min-content;
            max-width: 300px;
          `}
          data-trackingid={'portal-payments_setup-stripe_type-of-business_radio'}
        >
          <RadioField.Radio value='COMPANY'>{t('Business')}</RadioField.Radio>
          <RadioField.Radio value='NON_PROFIT'>{t('Nonprofit Organization')}</RadioField.Radio>
          <RadioField.Radio value='INDIVIDUAL'>{t('Individual')}</RadioField.Radio>
        </RadioField>
      )}
      <Button
        variant='primary'
        css={css`
          margin-bottom: ${theme.spacing(3)};
          margin-top: ${!stripeCreatedNotOnboarded ? theme.spacing(3) : 0};
          width: max-content;
        `}
        disabled={!radioFieldProps.value && !stripeCreatedNotOnboarded}
        size='large'
        {...creationCheckTriggerProps}
        trackingId='portal-payments_setup-stripe_create-account_button'
      >
        {stripeCreatedNotOnboarded ? t('Continue Onboarding') : buttonTitle}
      </Button>
      <CreateAndTermsConditionModal
        businessType={radioFieldProps.value}
        creationCheckModalProps={creationCheckModalProps}
        refetchQueries={refetchQueries}
      />
    </>
  );
};

export const PaymentsPromoCards = () => {
  const { t } = useTranslation('payments');
  return (
    <>
      <div css={styles.promoCard}>
        <GiftIcon />
        <div
          css={css`
            margin-left: ${theme.spacing(1)};
          `}
        >
          <Text
            css={css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {t('New Weave Payments customers can get a free credit card terminal!')}
          </Text>
          <TextLink to={FREE_TERMINAL_LINK} target='_blank'>
            {t('Request Terminal')}
          </TextLink>
          <Text
            size='small'
            color='light'
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            {t('Weave will contact you in 1 - 2 business days.')}
          </Text>
        </div>
      </div>
      <div css={styles.promoCard}>
        <GraduationCapIcon />
        <TextLink
          size='large'
          to={UPSELL_LINK}
          target='_blank'
          rel='noreferrer'
          css={css`
            margin-left: ${theme.spacing(1)};
          `}
        >
          {t('Learn more about Weave Payments')}
        </TextLink>
      </div>
    </>
  );
};

export const NoInvoices = (props: PaymentsSetupProps) => {
  const { t } = useTranslation('payments');

  return (
    <Page maxWidth={600}>
      <Page.Header>
        <Page.Header.Heading>
          <Page.Header.Title
            title={<PageTitleWithLocationChip title={t('Welcome to Weave Payments')} hasMargin={true} />}
          />
          <Page.Header.Subtitle subtitle={t('Create a new Stripe account to get started.')} />
        </Page.Header.Heading>
      </Page.Header>
      <Page.Body>
        <PaymentsSetup {...props} />
        <hr
          color={theme.colors.neutral20}
          css={css`
            max-width: 500px;
            margin-bottom: ${theme.spacing(3)};
          `}
        />{' '}
        <PaymentsPromoCards />
      </Page.Body>
    </Page>
  );
};
