import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { CardOnFileSwitch } from './card-on-file/card-on-file-switch';

export const CardOnFile = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.cardOnFile}>
      <CardOnFileSwitch />
    </StepPerformanceWrapper>
  );
};
