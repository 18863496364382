import { Dispatch, SetStateAction } from 'react';
import { DeviceSize, DeviceSizeField } from '@frontend/device-size-field';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { BaseFieldProps, IconButton, Modal, ModalControlModalProps } from '@frontend/design-system';

type Props = {
  deviceSizeProps: BaseFieldProps<DeviceSize>;
  modalProps: ModalControlModalProps;
  previewHtml?: string;
  setPreviewHtml: Dispatch<SetStateAction<string | undefined>>;
};

export const EmailPreviewModal = ({ deviceSizeProps, modalProps, previewHtml, setPreviewHtml }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  const modalSizeStyle = {
    height: 1000,
    maxHeight: '80vh',
    width: deviceSizeProps.value === 'desktop' ? 'max(648px,100%)' : 'max(480px,100%)',
    minWidth: deviceSizeProps.value === 'desktop' ? 'min(648px,100%)' : 'min(480px,100%)',
    maxWidth: '60vw',
  };

  return (
    <Modal
      {...modalProps}
      css={[
        { borderRadius: 0, border: 0, padding: 0, boxShadow: theme.shadows.floating, overflow: 'visible' },
        modalSizeStyle,
      ]}
    >
      <DeviceSizeField
        {...deviceSizeProps}
        value={deviceSizeProps.value}
        css={{ position: 'absolute', top: theme.spacing(-6), left: 0, button: { border: 0 } }}
      />
      <IconButton
        label='close-email-preview-button'
        css={{ position: 'absolute', top: theme.spacing(-6), backgroundColor: theme.colors.white, right: 0 }}
        onClick={() => {
          modalProps.onClose();
          setPreviewHtml(undefined);
        }}
      >
        <Icon name='x' />
      </IconButton>
      <iframe
        title={t('Email Preview')}
        srcDoc={previewHtml}
        sandbox='allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox'
        css={[{ border: 0 }, modalSizeStyle]}
      />
    </Modal>
  );
};
