import { useCallback } from 'react';
import { PageRequest } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaInfiniteQueryOptions, LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import {
  GetFormListIO,
  GetFormListV2IO,
  GetFormListV3IO,
  GetFormDetailsIO,
  GetESMedicalHistoryFormsIO,
  GetNonEditableFormsIO,
  GetFormPDFAuthedIO,
} from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

/**
 * @param companyId - AKA locationId
 */
export const useFormList = <E = unknown, T = GetFormListIO['output']>(
  req: Compulsory<GetFormListIO['input'], 'companyId'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetFormListIO, E, T>
) => {
  const queryKey = requestKeys.formList(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: string }) =>
      SchemaDigitalForms.GetFormList(
        { ...req, page: pageParam || req.page },
        { headers: { 'Location-Id': req.companyId } }
      ),
    getNextPageParam: (lastPage: GetFormListIO['output']) => lastPage.pageMetadata?.page,
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @param companyIds - AKA locationIds
 * @deprecated Use `useFormListV3` instead
 */
export const useFormListV2 = <E = unknown, T = GetFormListV2IO['output']>(
  req: Compulsory<GetFormListV2IO['input'], 'companyIds' | 'pageRequest'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetFormListV2IO, E, T>
) => {
  const queryKey = requestKeys.formListV2(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      SchemaDigitalForms.GetForms({
        ...req,
        pageRequest: pageParam || req.pageRequest,
      }),
    getNextPageParam: (lastPage): PageRequest | undefined => {
      const page = lastPage.pageMetadata?.page;
      return {
        pageNumber: page ? parseInt(page, 10) : undefined,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

type GetFormsListV3Request = Compulsory<GetFormListV3IO['input'], 'orgRequest' | 'pageRequest'>;
type ModifiedFormsListV3Response = Omit<GetFormListV3IO['output'], 'data'> & {
  rows: GetFormListV3IO['output']['data'];
};

/**
 * Fetches the list of forms in the organization and returns the response
 * in a format that is compatible with the InfinitePaginatedList component.
 */
async function getFormsListV3(req: GetFormsListV3Request): Promise<ModifiedFormsListV3Response> {
  const response = await SchemaDigitalForms.GetFormsV3(req);
  return {
    pageMetadata: response.pageMetadata,
    rows: response.data ?? [], // This is required for the InfinitePaginatedList component to work.
  };
}

/**
 * Fetches the list of forms in the organization.
 */
export const useFormListV3 = <E = unknown, T = ModifiedFormsListV3Response>(
  req: GetFormsListV3Request,
  options?: LimitedSchemaInfiniteQueryOptions<GetFormListV3IO, E, T>
) => {
  const queryKey = requestKeys.formListV3(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      getFormsListV3({ ...req, pageRequest: pageParam || req.pageRequest }),
    getNextPageParam: (lastPage): PageRequest | undefined => {
      const page = parseInt(lastPage.pageMetadata?.page || '0', 10);
      const lastPageNumber = parseInt(lastPage.pageMetadata?.lastPage || '0', 10);

      if (page >= lastPageNumber) {
        return undefined;
      }

      return {
        pageNumber: page + 1,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Fetches the details of a form.
 */
export const useGetFormDetails = <E = unknown, T = GetFormDetailsIO['output']>(
  req: Compulsory<GetFormDetailsIO['input'], 'formId' | 'orgId'>,
  options?: LimitedSchemaQueryOptions<GetFormDetailsIO, E, T>
) => {
  const queryKey = requestKeys.formDetails(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetFormDetails(req),
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetFormDetailsAsync = <E = unknown, T = GetFormDetailsIO['output']>() => {
  const queryClient = useQueryClient();
  const fetchFormDetailsAsync = useCallback(
    async (
      req: Compulsory<GetFormDetailsIO['input'], 'formId' | 'orgId'>,
      options?: LimitedSchemaQueryOptions<GetFormDetailsIO, E, T>
    ) => {
      try {
        const response = await queryClient.fetchQuery(
          requestKeys.formDetails(req),
          () => SchemaDigitalForms.GetFormDetails(req),
          {
            ...defaultOptions,
            retry: false,
            ...options,
          }
        );
        return response;
      } catch (error) {
        console.error('Error fetching form details', error);
        return undefined;
      }
    },
    [queryClient]
  );

  return {
    fetchFormDetailsAsync,
  };
};

export const useGetESMedicalHistoryForms = <E = unknown, T = GetESMedicalHistoryFormsIO['output']>(
  req: Compulsory<GetESMedicalHistoryFormsIO['input'], 'companyId' | 'sourceId'>,
  options?: LimitedSchemaQueryOptions<GetESMedicalHistoryFormsIO, E, T>
) => {
  const queryKey = requestKeys.eaglesoftMedicalHistoryForms(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetESMedicalHistoryForms(req),
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetNonEditableForms = <E = unknown, T = GetNonEditableFormsIO['output']>(
  req: Compulsory<GetNonEditableFormsIO['input'], 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetNonEditableFormsIO, E, T>
) => {
  const queryKey = requestKeys.nonEditableForms(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetFormTemplates(req),
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetFormPDF = <E = unknown, T = GetFormPDFAuthedIO['output']>(
  req: Compulsory<GetFormPDFAuthedIO['input'], 'formId' | 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetFormPDFAuthedIO, E, T>
) => {
  const queryKey = requestKeys.formPDF(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetFormPDFAuthed(req),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
