import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, Heading, Modal, ModalControlModalProps, Text, styles as dsStyles } from '@frontend/design-system';
import { formatOutEventCardDateTime } from '../../utils';
import { EventType } from '../calendar-view/types';
import { OutOfOfficeEventActionMenu } from '../schedule-calendar-components/out-of-office-event-action-menu';

type Props = {
  modalProps: ModalControlModalProps;
  title: string;
  eventType: Omit<EventType, 'appointment'>;
  id: string;
  locationName?: string;
  duration?: string;
  providerId?: string;
  providerName?: string;
  startDate: string;
  endDate?: string;
  calendarDate?: string;
};

export const OutOfOfficeEventCardModal = ({
  modalProps,
  id,
  title,
  eventType,
  providerId,
  providerName,
  startDate,
  endDate,
  calendarDate,
  duration = '',
  locationName,
}: Props) => {
  const { t } = useTranslation('schedule');

  if (!modalProps.show) return null;

  return (
    <Modal {...modalProps} minWidth={400} css={styles.modalContainer} onClose={modalProps.onClose}>
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Text size='small'>{t('Out of Office')}</Text>
          <Heading level={1} style={{ lineHeight: '36px' }}>
            {title}
          </Heading>
        </div>
        {eventType === 'outOfOffice' && (
          <OutOfOfficeEventActionMenu
            eventId={id}
            eventTitle={title}
            providerId={providerId}
            providerName={providerName}
            onActionCallback={modalProps.onClose}
          />
        )}
      </div>
      <div css={styles.details}>
        <DetailItem
          title={duration === 'All day' ? t('All-Day') : t('Time')}
          content={formatOutEventCardDateTime({ startDate, endDate, calendarDate, duration })}
        />
        {providerName ? (
          <DetailItem title={t('Practitioner')} content={providerName} />
        ) : (
          <DetailItem
            title={t('Location')}
            content={locationName ? <Chip.Location maxWidth={'fit-content'}>{locationName}</Chip.Location> : '-'}
          />
        )}
      </div>
    </Modal>
  );
};

const DetailItem = ({ title, content }: { title: string; content: React.ReactNode }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <Text size='small' color='light'>
        {title}
      </Text>
      {typeof content === 'string' ? (
        <Text weight='bold' css={dsStyles.truncate}>
          {content}
        </Text>
      ) : (
        content
      )}
    </div>
  );
};

const styles = {
  modalContainer: css`
    padding: ${theme.spacing(3)};
    border-left: 8px solid ${theme.colors.neutral60};
    background-color: ${theme.colors.neutral20};
    box-shadow: ${theme.shadows.floating};
  `,
  details: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2.5)};
    white-space: nowrap;
  `,
};
