import { useNavigate } from '@tanstack/react-location';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { URLs } from '../../../constants';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { CompareLocationsButton } from '../../compare-locations-button';
import { DemoChip } from '../../demo-chip';
import Badge from '../badge';
import { CallIntelInfoTipId, CallIntelInfoTips } from '../call-intel-info-tips';
import { CustomTooltipTitle } from '../custom-tooltip-title';
import { CallIntelSubViewType, useCallIntelDemoFlags, useCallIntelShallowStore } from '../hooks';
import { useVisibilityTracker } from '../hooks/use-visibility-tracker';

type OpportunitiesByTypeChartProps = {
  focusLabel: string;
  infoTipId?: CallIntelInfoTipId;
  isLoading?: boolean;
  onCompareLocationsClick?: (() => void) | null;
  subViewType: CallIntelSubViewType;
  title: string;
  types?: Record<string, number>;
};

export const OpportunitiesByTypeChart = ({
  focusLabel,
  infoTipId,
  isLoading,
  onCompareLocationsClick,
  subViewType,
  types = {},
  title,
}: OpportunitiesByTypeChartProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('analytics');
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();
  const {
    chartOutlineColors,
    chartLabelColors,
    dataColors,
    dataLabels,
    filters,
    filterHintText,
    isDemoAccount,
    setFiltersToRestore,
    setSubView,
  } = useCallIntelShallowStore(
    'chartOutlineColors',
    'chartLabelColors',
    'dataColors',
    'dataLabels',
    'filters',
    'filterHintText',
    'isDemoAccount',
    'setFiltersToRestore',
    'setSubView'
  );
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const { isVisible, elementRef } = useVisibilityTracker({
    identifier: 'newAppointmentVerticals',
    maxDays: 3,
    isNewFeature: true,
  });

  const dataKey = subViewType === 'appointment-type' ? 'appointmentTypes' : 'categories';

  const colors = dataColors[dataKey] || {};
  const labelColors = chartLabelColors[dataKey] || {};
  const strokeColors = chartOutlineColors[dataKey] || {};

  const labels = dataLabels[dataKey] || {};

  const chartDataGroups = Object.entries(types).map(([name, value]) => ({
    name,
    values: {
      [name]: value,
    },
  }));

  return (
    <Chart colors={colors} isLoading={isLoading} labels={{ ...labels, type: focusLabel }} strokeColors={strokeColors}>
      <Chart.Header
        bottomElement={
          <>
            <Text color='subdued' size='medium'>
              {t('Click each bar to learn more.')}
            </Text>
            {onCompareLocationsClick && (
              <CompareLocationsButton
                onClick={onCompareLocationsClick}
                trackingId={
                  dataKey === 'appointmentTypes'
                    ? trackingIds.callIntel.compareLocationsAppointments
                    : trackingIds.callIntel.compareLocationsCategories
                }
              />
            )}
          </>
        }
        infoTip={infoTipId ? <CallIntelInfoTips tip={infoTipId} /> : null}
        leftElement={
          showDemoChipAndBanner ? (
            <DemoChip />
          ) : dataKey === 'appointmentTypes' &&
            !isDemoAccount &&
            lastUsedVertical !== Vertical.DENTAL &&
            chartDataGroups.length &&
            isVisible ? (
            <div ref={elementRef}>
              <Badge label={t('New')} />
            </div>
          ) : null
        }
        subtitle={filterHintText}
        title={title}
      />
      <Chart.BarChart
        appearance={{
          customTooltipTitle: ({ groupName }) => {
            return <CustomTooltipTitle label={labels?.[groupName] || groupName} />;
          },
          customXAxisTick: ({ labels, groupName, ...rest }) => {
            const key = groupName as keyof typeof colors;
            return (
              <XAxisLabelValueTick
                {...rest}
                color={labelColors[key]}
                label={labels?.[groupName] || groupName}
                value={formatters.value.format(types[key])}
              />
            );
          },
          customTooltipData: () => {
            return Object.entries(types ?? {}).map(([key, value]) => ({
              id: key,
              label: dataLabels[dataKey]?.[key] || key,
              color: labelColors[key] || theme.colors.neutral50,
              formattedValue: formatters.value.format(value),
            }));
          },
          margin: { bottom: 20 },
          showXAxis: true,
        }}
        data={{
          groups: chartDataGroups,
        }}
        formatValue={formatters.value.format}
        onClick={({ group }) => {
          setFiltersToRestore(filters);
          setSubView({
            id: group,
            type: subViewType,
          });
          navigate({
            search: {
              type: subViewType,
            },
            to: `${URLs.CALL_INTEL_BASE}/${group.toLowerCase()}`,
          });
        }}
      />
    </Chart>
  );
};
