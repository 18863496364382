import { useEffect, useMemo } from 'react';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { ReviewAppointmentLinkPatient } from '../../../../../../components/ReviewAppointmentLinkPatient';
import { ReviewAppointmentRequestForm } from '../../../../../../components/ReviewAppointmentRequest';
import { getFullName, getIsIntegratedOffice } from '../../../../../../utils';
import { useScheduleRequestModalContext } from '../Context/ScheduleRequestModalContext';
import { useScheduleRequestModalFormContext } from '../Context/ScheduleRequestModalFormContext';
import { getShouldRenderSourceDropDown } from '../utils';
import { SourceTenantListItem } from './types';

type ScheduleRequestModalFormContainerV3Props = {
  appointmentWritebackSourceTenants: SourceTenantListItem[];
  patientWritebackSourceTenants: SourceTenantListItem[];
  isLoadingSourceTenants: boolean;
};

export const ScheduleRequestModalFormContainerV3 = ({
  appointmentWritebackSourceTenants,
  patientWritebackSourceTenants,
  isLoadingSourceTenants,
}: ScheduleRequestModalFormContainerV3Props) => {
  const { selectedScheduleRequest } = useScheduleRequestModalContext();

  const {
    personSelectorDialogProps,
    manageScheduleRequestFormDetails,
    manageScheduleRequestModalDetails,
    selectedPerson,
    personSelectorProps,
    setTemplate,
  } = useScheduleRequestModalFormContext();

  const {
    scheduleRequestApprovalForm: { values, seedValues, getFieldProps, isComplete },
    customDateField,
    customTimeField,
  } = manageScheduleRequestFormDetails;

  const {
    access,
    isLoading: isLoadingModalDetails,
    isSyncAppDetailsLoading,
    providers: practitioners,
    workstations: operatories,
    appointmentTypes,
    syncAppDataSources,
    tags,
  } = manageScheduleRequestModalDetails;

  const isIntegratedOffice = getIsIntegratedOffice(syncAppDataSources);

  /*
    Filtering of practitioners and operatories will need an update later. For now, showing all the practitioners and operatories for the location
    `practitioner.sourceId` and `operatory.sourceId` are the sourceTenantIds but named as sourceId during transformation to make it compatible with v2 data
  */
  const [filteredPractitionerList, filteredOperatoryList] = useMemo(() => {
    const filteredPractitioners = isIntegratedOffice
      ? practitioners?.filter((practitioner) => practitioner.sourceId)
      : practitioners;

    const filteredOperatories = isIntegratedOffice
      ? operatories?.filter((operatory) => operatory.sourceId)
      : operatories;

    return [filteredPractitioners, filteredOperatories];
  }, [isIntegratedOffice, values.source, practitioners, operatories]);

  // Update source form field value when there is only one source location
  useEffect(() => {
    let source = '';

    if (isIntegratedOffice && appointmentWritebackSourceTenants.length === 1) {
      source = appointmentWritebackSourceTenants[0].sourceTenantId || '';
    } else if (!isIntegratedOffice && syncAppDataSources.length === 1) {
      source = syncAppDataSources?.[0]?.SourceID;
    }

    seedValues({
      ...values,
      source,
    });
  }, [isIntegratedOffice, appointmentWritebackSourceTenants.length, syncAppDataSources.length]);

  // Update workstation form field value when there is only one operatory
  useEffect(() => {
    if (filteredOperatoryList.length === 1) {
      seedValues({
        ...values,
        workstation: filteredOperatoryList[0]?.id,
      });
    }
  }, [filteredOperatoryList, isIntegratedOffice]);

  // Seed form values using selectedScheduleRequest
  useEffect(() => {
    if (selectedScheduleRequest) {
      seedValues({
        ...values,
        provider: selectedScheduleRequest.practitionerId,
        appointmentType: selectedScheduleRequest.appointmentTypeId,
      });
    }
  }, [selectedScheduleRequest]);

  const isLoading = isLoadingModalDetails || isSyncAppDetailsLoading || isLoadingSourceTenants;
  const showSourceLocationDropdown = isIntegratedOffice && appointmentWritebackSourceTenants.length > 1;
  const showDataSourceDropdown = !isIntegratedOffice && getShouldRenderSourceDropDown(syncAppDataSources ?? []);
  const showWorkstationDropdown = filteredOperatoryList.length > 1;
  const disabledForm = !selectedPerson?.PersonID;
  const patientName = selectedPerson ? getFullName(selectedPerson) : getFullName(selectedScheduleRequest.schedulee);

  return (
    <>
      <ReviewAppointmentRequestForm
        appointmentTypes={appointmentTypes}
        isLoading={isLoading}
        dateField={customDateField}
        timeField={customTimeField}
        disabledForm={disabledForm}
        getFieldProps={getFieldProps}
        patientName={patientName}
        patientPhone={formatPhoneNumber(selectedPerson?.MobilePhone ?? selectedScheduleRequest?.schedulee?.phoneNumber)}
        personDialogProps={personSelectorDialogProps}
        providerList={filteredPractitionerList}
        selectedScheduleRequest={selectedScheduleRequest}
        showSourceLocationDropdown={showSourceLocationDropdown}
        showDataSourceDropdown={showDataSourceDropdown}
        showWorkstationDropdown={showWorkstationDropdown}
        appointmentWritebackSourceTenants={appointmentWritebackSourceTenants}
        syncAppDataSources={syncAppDataSources}
        values={values}
        workstationList={filteredOperatoryList}
        templateTags={tags}
        isReadOnlyTemplate={!isComplete || disabledForm}
        onTemplateChange={(template: string) => setTemplate(template)}
      />
      <ReviewAppointmentLinkPatient
        access={access}
        isIntegratedOffice={isIntegratedOffice}
        personSelectorProps={personSelectorProps}
        personSelectorDialogProps={personSelectorDialogProps}
        selectedPerson={selectedPerson}
        selectedScheduleRequest={selectedScheduleRequest}
        syncAppDataSources={syncAppDataSources}
        patientWritebackSourceTenants={patientWritebackSourceTenants}
      />
    </>
  );
};
