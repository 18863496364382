import { ReactNode, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonTypes, PersonHelpers } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions as ContactActions, ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { Photos } from '@frontend/photos';
import { useMediaMatches } from '@frontend/responsiveness';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { IconButton, ListRow, CaretDownIconSmall, Chip } from '@frontend/design-system';

export const Expandable = ({ children }: { children: ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const variants = {
    open: {
      height: 'auto',
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        opacity: { duration: 0.3 },
        height: { duration: 0.1 },
      },
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        ease: 'easeInOut',
        opacity: { duration: 0.15 },
        height: { duration: 0.3 },
      },
    },
  };
  return (
    <div
      style={{
        display: 'grid',
        gridColumn: '2/-1',
        gridAutoFlow: 'column',
        // Give the expand icon some space to breathe
        minHeight: theme.spacing(1),
      }}
    >
      <AnimatePresence>
        {isExpanded && (
          <motion.div style={{ display: 'flex' }} variants={variants} animate='open' initial='closed' exit='closed'>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <IconButton
        css={{
          justifySelf: 'end',
          height: 24,
          position: 'absolute',
          bottom: theme.spacing(0.5),
          ':hover:not(:disabled)': {
            background: theme.colors.neutral10,
          },
        }}
        size='small'
        label='expand'
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded((prev) => !prev);
        }}
      >
        <CaretDownIconSmall
          css={{ transform: isExpanded ? 'rotate(180deg)' : 'none', transition: 'transform 300ms' }}
        />
      </IconButton>
    </div>
  );
};

export const Actions = ({
  person,
  getLocationsByDatasource,
}: {
  person: PersonTypes.Person;
  getLocationsByDatasource: (
    sourceId: string,
    clientLocationId?: string | null | undefined
  ) => DataSourcesHooks.LocationReference[];
}) => {
  const { selectedLocationIds } = useAppScopeStore();
  const locations = getLocationsByDatasource(person.SourceID, person.ClientLocationID);
  const locationId = locations?.[0]?.locationId || selectedLocationIds[0];
  const { Modal } = ActionsUI.actions.useQuickFillAction(person.PersonID, locations);
  const { t } = useTranslation('contacts');
  const tokens = useTranslationTokens();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasPetServiceReport = getFeatureFlagValue('pet-service-report');
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const isVet = lastUsedVertical === 'VET';

  return (
    <>
      <AdaptoActions
        enableCloseEvent
        iconColor='inherit'
        variant='action-buttons'
        css={{ display: 'flex', gap: theme.spacing(2) }}
      >
        <ContactActions.Call label={t('Call')} context={{ person }} trackingIdSuffix='contact-actions' />
        <ContactActions.Message
          label={t('Message')}
          context={{
            personId: person.PersonID,
            threadGroupId: locations?.length === 1 ? locations?.[0]?.locationId : undefined,
            groupIds: locations?.reduce<string[]>((acc, curr) => {
              if (curr.locationId) {
                acc.push(curr.locationId);
              }
              return acc;
            }, []),
          }}
          trackingIdSuffix='contact-actions'
        />
        <ContactActions.SendForm
          label={t('Send Form')}
          context={{ person, locationId: locationId || selectedLocationIds[0] }}
          trackingIdSuffix='contact-actions'
        />
        <ContactActions.QuickFill
          label={t('Add to Quick Fill')}
          personId={person.PersonID}
          locations={locations}
          trackingIdSuffix='contact-actions'
        />
        <ContactActions.Review
          label={t('Review')}
          personId={person.PersonID}
          groupId={locationId || selectedLocationIds[0]}
          trackingIdSuffix='contact-actions'
        />
        <ContactActions.Payments
          label={t('Request Payment')}
          context={{ person, personId: person.PersonID, locationId }}
          trackingIdSuffix='contact-actions'
        />
        {isVet && hasPetServiceReport && (
          <ContactActions.ServiceReport
            label={t('Send Vaccine History')}
            trackingId='contact-action-send-vaccine-history'
            context={{
              personId: person.PersonID,
              locationId,
              mobilePhone: person.MobilePhone,
              petOwnerName: PersonHelpers.getFullName(person),
            }}
          />
        )}
        <ContactActions.DeleteContact
          label={tokens.deleteContact}
          context={{ personId: person.PersonID, locationId }}
          trackingId='contact-action-delete'
          disabled={person.SourceType !== PersonTypes.PersonSourceType.CustomContact}
        />
        {Modal}
      </AdaptoActions>
    </>
  );
};

export const ContactListRow = ({
  listItem,
  children,
  getLocationsByDatasource,
}: {
  listItem: PersonTypes.Person;
  children?: ReactNode;
  getLocationsByDatasource: (
    sourceId: string,
    clientLocationId?: string | null | undefined
  ) => DataSourcesHooks.LocationReference[];
}) => {
  const { selectedLocationIds } = useAppScopeStore();
  const onlyOneLocationSelected = selectedLocationIds.length === 1;
  const { t } = useTranslation('contacts');
  const { matches } = useMediaMatches();

  const { Gender = '', SourceID = '', ClientLocationID } = listItem || {};
  const age = PersonHelpers.getAge(listItem);
  const fullName = PersonHelpers.getFullName(listItem);
  const isMobileScreen = matches.useXSmallMax();
  const locations = getLocationsByDatasource(SourceID, ClientLocationID) || [];

  return (
    <ContactListRowWrapper listItem={listItem} locations={locations}>
      <ListRow.Content
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListRow.Content.Title>{fullName}</ListRow.Content.Title>
          <ListRow.Content.Subtitle>{`${Gender || t('Unknown')} • ${age || t('Unknown')}`}</ListRow.Content.Subtitle>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'wrap-reverse', gap: theme.spacing(1) }}
        >
          <div
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            {children}
          </div>
          <div
            style={{
              display: 'flex',
              gap: theme.spacing(1),
              justifyContent: 'flex-end',
            }}
          >
            {/* To be clear, if there is only one location selected, we will not show a location pill */}
            {!onlyOneLocationSelected && locations.length === 1 && (
              <Chip.SingleChip>{locations[0].name}</Chip.SingleChip>
            )}
            {!onlyOneLocationSelected && locations.length > 1 && (
              <Chip.MultiChip> {t('{{count}} Locations', { count: locations.length })}</Chip.MultiChip>
            )}
          </div>
        </div>
      </ListRow.Content>
      {isMobileScreen ? (
        <Expandable>
          <Actions person={listItem} getLocationsByDatasource={getLocationsByDatasource} />
        </Expandable>
      ) : (
        <ListRow.Trail>
          <Actions person={listItem} getLocationsByDatasource={getLocationsByDatasource} />
        </ListRow.Trail>
      )}
    </ContactListRowWrapper>
  );
};

export const ContactListRowWrapper = ({
  listItem,
  children,
  locations,
}: {
  listItem: PersonTypes.Person;
  children?: React.ReactNode;
  locations: DataSourcesHooks.LocationReference[];
}) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { setPersonId, personId } = useContactPanelShallowStore('setPersonId', 'personId');

  const { PersonID = '' } = listItem || {};

  return (
    <ListRow
      isSelected={personId === PersonID}
      as='div'
      css={{
        gridTemplateColumns: 'auto 1fr auto',
        rowGap: theme.spacing(1),
      }}
      onClick={() => {
        setPersonId(listItem.PersonID, true, locations?.[0]?.locationId ?? selectedLocationIds[0]);
      }}
    >
      <ListRow.Lead>
        <Photos.ContactProfilePhoto
          personId={PersonID}
          firstName={listItem.FirstName}
          lastName={listItem.LastName}
          locationId={locations?.[0]?.locationId ?? selectedLocationIds[0]}
        />
      </ListRow.Lead>
      {children}
    </ListRow>
  );
};
