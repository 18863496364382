import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { createProvider } from '@frontend/store';
import { contextFactory } from '@frontend/design-system';
import type { StickyColor } from './colors';
import { saveStickyNoteToStorage, saveToStorageImmediate, type ArchivedNote } from './storage';

export interface StickyNoteStore {
  noteText: string;
  color: StickyColor;
  draggedText: string;
  isHovering: boolean;
  isDragging: boolean;
  showArchive: boolean;
  isHoveredOverArchive: boolean;
  archivedNotes: ArchivedNote[];
  setNoteText: (text: string) => void;
  setDraggedText: (text: string) => void;
  setIsHovering: (isHovering: boolean) => void;
  setIsDragging: (isDragging: boolean) => void;
  setShowArchive: (show: boolean) => void;
  setIsHoveredOverArchive: (isHovered: boolean) => void;
  handleTyping: (text: string) => void;
  applyDraggedText: () => void;
  archiveCurrentNote: () => void;
  deleteArchivedNote: (id: string) => void;
  archivingNote: string;
  setArchivingNote: (text: string) => void;
}

export const {
  Provider: StickyNoteProvider,
  useStore: useStickyNoteStore,
  useNonReactiveStore: useStickyNonReactiveStore,
} = createProvider<StickyNoteStore>()((set, get) => {
  return {
    noteText: '',
    color: 'yellow',
    draggedText: '',
    isHovering: false,
    isDragging: false,
    showArchive: false,
    isHoveredOverArchive: false,
    archivedNotes: [],
    archivingNote: '',
    setNoteText: (text) => {
      set({ noteText: text });
      saveStickyNoteToStorage(
        {
          currentNote: text,
          archivedNotes: get().archivedNotes,
        },
        get().color
      );
    },
    setDraggedText: (text) => set({ draggedText: text }),
    setIsHovering: (isHovering) => set({ isHovering }),
    setIsDragging: (isDragging) => {
      set({ isDragging });
      if (isDragging) {
        set({ showArchive: true });
      }
    },
    setShowArchive: (show) => set({ showArchive: show }),
    setIsHoveredOverArchive: (isHovered) => set({ isHoveredOverArchive: isHovered }),
    handleTyping: (text) => {
      set({ noteText: text });
      saveStickyNoteToStorage(
        {
          currentNote: text,
          archivedNotes: get().archivedNotes,
        },
        get().color
      );
    },
    setArchivingNote: (text) => set({ archivingNote: text }),
    applyDraggedText: () => {
      const { noteText } = get();
      set({ draggedText: noteText, noteText: '', isDragging: true, showArchive: true, isHovering: false });
    },
    archiveCurrentNote: () => {
      const { noteText, draggedText, archivedNotes } = get();

      if (noteText === '' && draggedText === '') {
        return;
      }

      const prunedArchivedNotes = archivedNotes.filter((note) => {
        return dayjs().diff(dayjs(note.archivedAt), 'day') < 60;
      });

      const newArchivedNotes = [
        {
          id: nanoid(),
          text: noteText === '' ? draggedText : noteText,
          archivedAt: new Date().toISOString(),
        },
        ...prunedArchivedNotes,
      ];
      set({ noteText: '', archivedNotes: newArchivedNotes });
      saveToStorageImmediate(
        {
          currentNote: '',
          archivedNotes: newArchivedNotes,
        },
        get().color
      );
    },
    deleteArchivedNote: (id) => {
      const { archivedNotes } = get();
      const newArchivedNotes = archivedNotes.filter((note) => note.id !== id);
      set({ archivedNotes: newArchivedNotes });
      saveToStorageImmediate(
        {
          currentNote: get().noteText,
          archivedNotes: newArchivedNotes,
        },
        get().color
      );
    },
  };
});

type StickyNoteContextType = {
  color: StickyColor;
};

export const [StickyNoteContext, useStickyNoteContext] = contextFactory<StickyNoteContextType>();
