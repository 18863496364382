import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { OnboardingEvent } from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { useQueryClient } from 'react-query';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { locationApiQueryKeys } from '@frontend/api-location';
import { OnboardingModulesApi } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { GradientPage } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Avatar, Heading, NakedButton, SpinningLoader, Text, styles } from '@frontend/design-system';
import { DebugModeRibbon } from '../components';
import { BusinessInfoFormStep, TemporaryNumberInfoStep } from '../components/new-onboard-form-steps';
import { WICScheduleStep } from '../components/new-onboard-form-steps/wic-schedule-step';
import { INTAKE_FORM_PATHS, INTAKE_FORM_V2_FEATURE_FLAG, NWX_DASHBOARD_PATH, trackingIds } from '../constants';
import { IntakeFormProvider } from '../providers/intake-form.provider';

enum IntakeFormStepsV2 {
  Loader,
  BusinessInfo,
  WeaveInitialCall,
  NewNumberInfo,
  NwxTransition,
}

interface Props {
  onSignOut: () => void;
}

// This is the new intake form which supports only single software-only location.
export const IntakeFormV2 = ({ onSignOut }: Props) => {
  const user = getUser();
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasIntakeFormV2FF = getFeatureFlagValue(INTAKE_FORM_V2_FEATURE_FLAG);
  const [step, setStep] = useState<IntakeFormStepsV2>(IntakeFormStepsV2.Loader);
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const selectedLocationId = selectedLocationIds?.[0] ?? '';
  const selectedLocationSlug = accessibleLocationData?.[selectedLocationId]?.slug ?? '';
  const {
    isShowIntakeForm,
    isSingleSoftwareOnlyLocation,
    intakeFormLocations,
    ownIntakeFormLocations,
    selectedIntakeFormLocationId,
    setSelectedIntakeFormLocationId,
  } = useIntakeFormShallowStore(
    'isShowIntakeForm',
    'isSingleSoftwareOnlyLocation',
    'intakeFormLocations',
    'ownIntakeFormLocations',
    'selectedIntakeFormLocationId',
    'setSelectedIntakeFormLocationId'
  );

  useEffect(() => {
    if (isShowIntakeForm && hasIntakeFormV2FF && isSingleSoftwareOnlyLocation) {
      const hasOwnIncompleteIntakeForm = ownIntakeFormLocations.some((form) => !form.formCompletedAt);
      if (!hasOwnIncompleteIntakeForm) {
        sentry.warn({
          error: 'Intake-form-v2 opened from non-onboarding-contact user',
          topic: 'onboarding',
          addContext: {
            name: 'additional-info',
            context: {
              user: user?.username ?? '',
              intakeFormLocationId: selectedIntakeFormLocationId,
            },
          },
        });
      }
      setSelectedIntakeFormLocationId(intakeFormLocations.find((form) => !form.formCompletedAt)?.locationId);
      setStep(IntakeFormStepsV2.BusinessInfo);
    } else if (!isShowIntakeForm) {
      navigate({
        to: NWX_DASHBOARD_PATH,
        replace: true,
      });
    } else if (!hasIntakeFormV2FF || !isSingleSoftwareOnlyLocation) {
      navigate({ to: INTAKE_FORM_PATHS.root, replace: true });
    }
  }, [isShowIntakeForm, hasIntakeFormV2FF, isSingleSoftwareOnlyLocation]);

  const handleBusinessInfoStepSuccess = () => {
    setStep(IntakeFormStepsV2.WeaveInitialCall);
  };

  const handleWICScheduleStepContinue = () => {
    setStep(IntakeFormStepsV2.NewNumberInfo);
  };

  const handleTemporaryPhoneNumberStepSaveSuccess = async () => {
    await OnboardingModulesApi.publishOnboardingEvent({
      slug: selectedLocationSlug,
      locationId: selectedLocationId,
      onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_INTAKE_FORM_COMPLETED,
    });
    // as we were updating locationName in the previous step, we need to invalidate the cache before redirecting to dashboard
    await queryClient.invalidateQueries({
      queryKey: locationApiQueryKeys.orgLocations(selectedLocationId),
      exact: true,
      refetchInactive: true,
    });
    await queryClient.invalidateQueries({
      queryKey: locationApiQueryKeys.userLocations(user?.userID),
      exact: true,
      refetchInactive: true,
    });
    setStep(IntakeFormStepsV2.NwxTransition);
    setTimeout(() => {
      navigate({ to: NWX_DASHBOARD_PATH });
    }, 2000);
  };

  const handleTemporaryPhoneNumberStepBackClick = () => {
    setStep(IntakeFormStepsV2.BusinessInfo);
  };

  const currentStepComponent = useMemo(() => {
    switch (step) {
      case IntakeFormStepsV2.NewNumberInfo:
        return (
          <TemporaryNumberInfoStep
            onBackClick={handleTemporaryPhoneNumberStepBackClick}
            onSaveSuccess={handleTemporaryPhoneNumberStepSaveSuccess}
          />
        );
      case IntakeFormStepsV2.BusinessInfo:
        return <BusinessInfoFormStep onSaveSuccess={handleBusinessInfoStepSuccess} />;
      case IntakeFormStepsV2.WeaveInitialCall:
        return <WICScheduleStep onContinue={handleWICScheduleStepContinue} />;
      case IntakeFormStepsV2.NwxTransition:
        return <NwxTransitionStep />;
      case IntakeFormStepsV2.Loader:
      default:
        // SP-TODO: replace loader with lottie files
        return (
          <div css={styles.flexCenter}>
            <SpinningLoader />
          </div>
        );
    }
  }, [step]);

  const userFullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();
  return (
    <GradientPage pageStyles={styles.flexCenter} showWeaveIconAtBottom>
      <NakedButton css={logoutButtonStyle} onClick={onSignOut} trackingId={trackingIds.onboardingV2.signOutButtonClick}>
        <Avatar name={userFullName} size='small' />
        <Text as='span' size='medium'>
          {t('Sign Out')}
        </Text>
      </NakedButton>

      <IntakeFormProvider>
        <DebugModeRibbon shouldShowToggle css={ribbonStyle} />
        {currentStepComponent}
      </IntakeFormProvider>
    </GradientPage>
  );
};

const NwxTransitionStep = () => {
  const { t } = useTranslation('onboarding');
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Icon name='weave-logo' size={92} />
      <Heading level={3} textAlign='center'>
        {t("Get ready to elevate your patient's experience")}
      </Heading>
    </div>
  );
};

const ribbonStyle = css({
  position: 'absolute',
  top: 0,
  width: '100%',
  opacity: 0.5,
});

const logoutButtonStyle = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  top: 50,
  right: 50,
  ':focus-visible': {
    background: 'transparent',
    outline: 'none',
    boxShadow: `0 0 0 2px ${theme.colors.neutral50}`,
  },
  [`@media (max-width: ${breakpoints.small.max}px)`]: {
    top: 16,
    right: 16,
  },
});
