import dayjs from 'dayjs';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { i18next } from '@frontend/i18n';

export const API_URLs = {
  missedCallText: {
    appointmentScheduledSummary: `${BASE_URL_ANALYTICS}/roi/getmissedcalltext`,
    messagesSentSummart: `${BASE_URL_ANALYTICS}/roi/getmissedcalltextmessages`,
    practiceValueSummary: `${BASE_URL_ANALYTICS}/roi/getmissedcalltextapptscompleted`,
  },
};

const timeFormat = 'YYYY-MM-DDTHH:mm:ss';

export const customROITimePeriods = {
  thisMonth: {
    label: i18next.t('This Month', { ns: 'analytics' }),
    value: {
      startDate: dayjs().startOf('month').format(timeFormat),
      endDate: dayjs().endOf('month').format(timeFormat),
    },
  },
  lastMonth: {
    label: i18next.t('Last Month', { ns: 'analytics' }),
    value: {
      startDate: dayjs().subtract(1, 'month').startOf('month').format(timeFormat),
      endDate: dayjs().subtract(1, 'month').endOf('month').format(timeFormat),
    },
  },
  thisQuarter: {
    label: i18next.t('This Quarter', { ns: 'analytics' }),
    value: {
      startDate: dayjs().startOf('quarter').format(timeFormat),
      endDate: dayjs().endOf('quarter').format(timeFormat),
    },
  },
  lastQuarter: {
    label: i18next.t('Last Quarter', { ns: 'analytics' }),
    value: {
      startDate: dayjs().subtract(1, 'quarter').startOf('quarter').format(timeFormat),
      endDate: dayjs().subtract(1, 'quarter').endOf('quarter').format(timeFormat),
    },
  },
};

export const REVENUE_MULTIPLIER = 200;
