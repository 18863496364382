import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useScopedAppFlagStore } from '@frontend/scope';

export const useFeatureAccess = (flagName: string, acl: Permission) => {
  const { getFeatureFlagValue, getFeatureFlagLocationMap } = useScopedAppFlagStore();

  // Feature flag check
  const featureFlagLocationMap = getFeatureFlagLocationMap(flagName);
  const locationsWithEnabledFlag = Object.entries(featureFlagLocationMap)
    .filter(([_, value]) => value)
    .map(([locationId]) => locationId);
  const hasEnabledFlag = getFeatureFlagValue(flagName);

  // ACL check
  const locationsWithACLAccess = locationsWithEnabledFlag.filter((locationId) => hasSchemaACL(locationId, acl));
  const hasACLAccess = locationsWithACLAccess.length > 0; // this means that the user has access to manage the feature for at least one location

  const hasAccess = hasACLAccess && hasEnabledFlag;

  // visually they should be able to see the feature if they have access to at least one location
  // but they should only be able to edit the ones they have access to
  return { hasAccess, locationsWithACLAccess, locationsWithEnabledFlag };
};
