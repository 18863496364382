import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../../utils';
import { InfoTipPopover } from '../../../../info-tip-popover';
import { REVENUE_MULTIPLIER } from '../../../constants';
import { roiDefinitions } from '../../../helpers';
import { MessagingOverview } from '../../../types';
import { MessagingChartBaseInterface, MessagingChartsBase } from './charts-base';

interface Props extends MessagingChartBaseInterface {
  data: MessagingOverview['revenueGenerated'];
  dateRangeHelperText?: string;
}

export const RevenueChart = ({ data, dateRangeHelperText, ...rest }: Props) => {
  const { t } = useTranslation('analytics');

  const processedData = useMemo(() => {
    const productions = {
      newPatients: (data?.newPatientsCompletedAppts ?? 0) * REVENUE_MULTIPLIER,
      existingPatients: (data?.existingPatientsCompletedAppts ?? 0) * REVENUE_MULTIPLIER,
      total: (data?.totalCompletedAppts ?? 0) * REVENUE_MULTIPLIER,
    };

    return {
      chartData: {
        centerMetric: `${formatters.currency.shortenNumber(productions.total)}`,
        centerMetricLabel: t('Total'),
        groups: [
          {
            name: 'newPatients',
            value: productions.newPatients,
          },
          {
            name: 'existingPatients',
            value: productions.existingPatients,
          },
        ],
      },
      productions,
    };
  }, [data]);

  return (
    <MessagingChartsBase
      {...rest}
      chartConfig={{
        data: processedData.chartData,
        type: 'pie',
      }}
      infoTip={<InfoTipPopover>{roiDefinitions.productionValueDefinition(dateRangeHelperText)}</InfoTipPopover>}
      legendsFormatValue={formatters.currency.shortenNumber}
      legendsValues={processedData.productions}
      showMoreView='revenueGenerated'
      trackingIdBase='revenue-generated'
      title={t('Production Value')}
    />
  );
};
