import { HistoryType, Entry } from '@weave/schema-gen-ts/dist/schemas/comm/history/v1/history_service.pb';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { PersonHistoryApi } from '@frontend/api-person-history';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedInfiniteQuery } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';
import { HistoryEmptyStateGraphic } from './history-empty-state-graphic';
import { PhoneHistoryEntry, SmsHistoryEntry } from './history-entry';

const LIMIT = 25;

dayjs.extend(calendar);

export const HistoryPanel = ({ personId }: { personId: string }) => {
  const { t } = useTranslation('contacts');
  const { selectedLocationIds } = useAppScopeStore();

  const infiniteQueryProps = useLocalizedInfiniteQuery({
    queryKey: ['personHistory', personId],
    queryFn: async ({ queryKey, pageParam }) => {
      const res = await PersonHistoryApi.getPersonHistory({
        personId,
        locationId: queryKey[0],
        groupIds: selectedLocationIds,
        skip: pageParam,
        limit: LIMIT,
      });
      return { rows: res.entries, nextSkip: res.nextSkip };
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.rows && lastPage?.rows?.length < LIMIT) return undefined;
      return lastPage.nextSkip;
    },
  });
  const hasNoHistory = infiniteQueryProps?.data?.pages?.[0].rows?.length === 0;
  if (infiniteQueryProps.isLoading) {
    return (
      <div css={{ marginTop: theme.spacing(3), textAlign: 'center' }}>
        <SpinningLoader size='medium' />
      </div>
    );
  }

  if (hasNoHistory && !infiniteQueryProps.isLoading) {
    return (
      <div css={{ margin: theme.spacing(4) }}>
        <HistoryEmptyStateGraphic />
        <Text textAlign='center' css={{ marginTop: theme.spacing(3), color: theme.colors.neutral70 }}>
          {t("Looks like there's no history with this patient yet, but your story is just beginning.")}
        </Text>
      </div>
    );
  }
  return (
    <InfinitePaginatedList
      infiniteQueryProps={infiniteQueryProps}
      renderListItem={({ listItem }) => <HistoryEntry {...listItem} />}
    />
  );
};

const HistoryEntry = (historyEntry: Entry) => {
  const isSms = historyEntry.type === HistoryType.HISTORY_TYPE_SMS;
  const isPhone = historyEntry.type === HistoryType.HISTORY_TYPE_PHONE;

  if (isSms) {
    return <SmsHistoryEntry {...historyEntry} />;
  }
  if (isPhone) {
    return <PhoneHistoryEntry {...historyEntry} />;
  }
  return null;
};
