import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, useTooltip, Text } from '@frontend/design-system';

export const ActionButtons = ({
  mode,
  showDebugButtons = false,
  onLayoutChange,
  setMode,
  onZoomClick,
  onFitViewClick,
}: {
  mode: 'edit' | 'view';
  onZoomClick: (direction: 'in' | 'out') => void;
  onFitViewClick: () => void;
  onLayoutChange: (layout: 'TB' | 'LR') => void;
  setMode: Dispatch<SetStateAction<'edit' | 'view'>>;
  showDebugButtons?: boolean;
}) => {
  const { t } = useTranslation('phone');

  const {
    Tooltip: ZoomOutTooltip,
    tooltipProps: zoomOutTooltipProps,
    triggerProps: zoomOutTriggerProps,
  } = useTooltip({ placement: 'bottom-start' });

  const {
    Tooltip: ZoomInTooltip,
    tooltipProps: zoomInTooltipProps,
    triggerProps: zoomInTriggerProps,
  } = useTooltip({ placement: 'bottom-start' });

  const {
    Tooltip: FitViewTooltip,
    tooltipProps: fitViewTooltipProps,
    triggerProps: fitViewTriggerProps,
  } = useTooltip({
    placement: 'bottom-start',
  });

  return (
    <>
      <Button
        variant='secondary'
        iconName='zoom-out'
        css={{ background: theme.colors.white, width: 32 }}
        onClick={() => {
          onZoomClick('out');
        }}
        {...zoomOutTriggerProps}
      />
      <Button
        variant='secondary'
        iconName='zoom-in'
        css={{ background: theme.colors.white, width: 32 }}
        onClick={() => {
          onZoomClick('in');
        }}
        {...zoomInTriggerProps}
      />
      <Button
        variant='secondary'
        iconName='show-canvas'
        css={{ background: theme.colors.white, width: 32 }}
        onClick={onFitViewClick}
        {...fitViewTriggerProps}
      />
      {showDebugButtons && (
        <div style={{ display: 'flex', gap: theme.spacing(1) }}>
          <Button variant='secondary' css={{ background: theme.colors.white }} onClick={() => onLayoutChange('TB')}>
            Vertical
          </Button>
          <Button variant='secondary' css={{ background: theme.colors.white }} onClick={() => onLayoutChange('LR')}>
            Horizontal
          </Button>
          <Button
            variant='secondary'
            css={{ background: theme.colors.white }}
            onClick={() => setMode((mode) => (mode === 'edit' ? 'view' : 'edit'))}
          >
            {`Switch to ${mode === 'edit' ? 'view' : 'edit'}`}
          </Button>
        </div>
      )}

      <ZoomOutTooltip {...zoomOutTooltipProps}>
        <Text color='white'>{t('Zoom out')}</Text>
        <Text color='white' weight='regular'>
          {t('Keyboard shortcut: [-]')}
        </Text>
      </ZoomOutTooltip>

      <ZoomInTooltip {...zoomInTooltipProps}>
        <Text color='white'>{t('Zoom in')}</Text>
        <Text color='white' weight='regular'>
          {t('Keyboard shortcut: [+]')}
        </Text>
      </ZoomInTooltip>

      <FitViewTooltip {...fitViewTooltipProps}>
        <Text color='white'>{t('Show full canvas')}</Text>
        <Text color='white' weight='regular'>
          {t('Keyboard shortcut: [/]')}
        </Text>
      </FitViewTooltip>
    </>
  );
};
