import type { WeavePopNotification, WeaveNotificationActions } from '@frontend/types';
import { NotificationComponent } from '../notification-components';

type NotificationContentProps = {
  notification: WeavePopNotification;
  stacked?: boolean;
  onEmit?: (action: WeaveNotificationActions, notification: WeavePopNotification, extra?: any) => void;
};

export const NotificationContent = ({ notification, stacked = false, onEmit }: NotificationContentProps) => {
  return (
    <NotificationComponent
      stacked={stacked}
      key={notification.id}
      notification={notification}
      isExperimentalMode
      emit={(action, notif) => {
        if (onEmit) {
          onEmit(action, notif);
        }
      }}
    />
  );
};
