import { MouseEvent } from 'react';
import { theme } from '@frontend/theme';

interface Params {
  onMouseLeave?: () => void;
  onMouseMove?: (e: MouseEvent) => void;
  polarAxisFillColor?: string;
  polarAxisStrokeColor?: string;
  polarAxisStrokeDasharray?: string;
  polarAxisStrokeWidth?: number;
}

export const renderBackgroundCircle = ({
  onMouseLeave,
  onMouseMove,
  polarAxisFillColor = theme.colors.neutral10,
  polarAxisStrokeColor,
  polarAxisStrokeDasharray,
  polarAxisStrokeWidth,
}: Params) => {
  return (
    <>
      <svg>
        {/* Outer Circle */}
        <circle
          cx='50%'
          cy='50%'
          fill={polarAxisFillColor}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          r={110}
          stroke={polarAxisStrokeColor}
          strokeDasharray={polarAxisStrokeDasharray}
          strokeWidth={polarAxisStrokeWidth}
        />

        {/* Inner Circle */}
        <circle
          cx='50%'
          cy='50%'
          r={70}
          fill={theme.colors.white}
          stroke={polarAxisStrokeColor}
          strokeDasharray={polarAxisStrokeDasharray}
          strokeWidth={polarAxisStrokeWidth}
        />
      </svg>
    </>
  );
};
