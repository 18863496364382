import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Template, TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { useQuery } from 'react-query';
import { LocationsApi } from '@frontend/api-locations';
import { MMS_MAX_SIZE, MessagesHooks } from '@frontend/api-messaging';
import { ScheduleQueries } from '@frontend/api-schedule';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { SuperTextarea } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  Text,
  useModalControl,
  Modal,
  FileUpload,
  formatDate,
  ContentLoader,
  Heading,
  useAlert,
  TagType,
  Button,
} from '@frontend/design-system';
import { useSchedulingLocationInfo } from '../../../../../hooks';
import { MediaUploadPreview } from '../../../components/MediaUploadPreview';
import { MessageTemplatesModal } from '../../../components/MessageTemplate';
import { ScheduleMassMessageTrackingIds } from './trackingIds';
import { getMassMessageTagValues } from './utils';

type Props = {
  appointmentLocationId: string;
  selectedAppointments: Appointment[];
  date: string;
  onClose: () => void;
  onBack: () => void;
};

export const SendScheduledMessageToPatients = ({
  appointmentLocationId,
  selectedAppointments,
  date,
  onClose,
  onBack,
}: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarActions');
  const [message, setMessage] = useState<string>('');
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();

  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const isVet = lastUsedVertical === Vertical.VET;
  const scheduleTemplateTypeSlug = isVet
    ? TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET
    : TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER;

  const { selectedLocationIds, getScopeName, selectedOrgId, getLocationTimeZone } = useSchedulingLocationInfo();

  const { data: locationDataWithPhoneNumber } = useQuery({
    queryKey: ['schedule-pulse', 'schedule-mass-message', appointmentLocationId],
    queryFn: () => LocationsApi.getLocation(appointmentLocationId),
    enabled: !!appointmentLocationId,
  });

  const phoneNumber = locationDataWithPhoneNumber?.PhoneNumber;

  const imageUploadProps = MessagesHooks.useMediaUploader({
    maxFileSize: MMS_MAX_SIZE,
    onExceedMaxFiles: () => {
      alert.error({ message: t('You can only attach up to 10 images at a time.') });
    },
    locationId: appointmentLocationId,
  });

  const messageTemplatesModalControls = useModalControl();
  const imageUploadModalControl = useModalControl();

  const {
    mutateAsync: sendMassMessage,
    isLoading: isSendMassMessageLoading,
    isError: isSendMassMessageError,
  } = ScheduleQueries.useMutateMassMessageSend();

  const handleSendMassMessage = () => {
    const recipients = selectedAppointments?.map((selectedAppointment) => {
      return {
        personId: selectedAppointment?.person?.personId ?? '',
        bindingsList: [...getBindingsList(selectedAppointment)],
      };
    });

    const location = {
      locationId: appointmentLocationId,
      locale: 'en_US',
      timezone: getLocationTimeZone(appointmentLocationId),
      recipients: recipients,
    };

    const sendMassMessageReq = {
      locations: [location],
      mediaIds: imageUploadProps.files.map((file) => file.mediaId ?? ''),
      templatedMessage: message,
      templateTypeSlug: selectedTemplate?.templateTypeSlug ?? TemplateType_Slug.UNSPECIFIED_TEMPLATE_TYPE,
    };

    sendMassMessage(sendMassMessageReq).then((res) => {
      if (res) {
        alert.success({ message: t('Message sent successfully.') });
        onClose();
      }
    });
  };

  const templateTypeQuery = TemplatorV2Queries.useTemplateTypesList(
    {
      businessGroupIds: selectedLocationIds,
      templateTypeSlugs: [selectedTemplate?.templateTypeSlug ?? scheduleTemplateTypeSlug],
    },
    {
      select: (data) =>
        data.templateTypes
          .find((template) => template.slug === selectedTemplate?.templateTypeSlug)
          ?.dynamicFields.reduce<TagType[]>((acc, dynamicField) => {
            const { label, key, exampleValues } = dynamicField;
            if (label && key) {
              acc.push({
                label,
                key: `{{${key}}}`,
                value: exampleValues?.[0] ?? '',
                optional: !dynamicField.isRequired,
                nonRemovable: !!dynamicField.isRequired,
              });
            }
            return acc;
          }, []) ?? [],
      enabled: !!selectedTemplate?.templateTypeSlug,
    }
  );

  const templateTags = templateTypeQuery.data ?? [];

  const getBindingsList = useCallback(
    (appointmentData: Appointment) => {
      return getMassMessageTagValues({
        appointmentPerson: appointmentData?.person,
        selectedOrgName: getScopeName(selectedOrgId) ?? '',
        groupName: getScopeName(appointmentData.locationId ?? ''),
        locationPhone: phoneNumber ?? '',
        appointment: appointmentData,
      });
    },
    [message]
  );

  useEffect(() => {
    if (isSendMassMessageError) {
      alert.error({ message: t('Failed to send message. Please try again.') });
    }
  }, [isSendMassMessageError]);

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={{
          backgroundColor: theme.colors.white,
          padding: theme.spacing(0, 3, 2, 3),
        }}
      >
        <ContentLoader show={isSendMassMessageLoading} />
        <Heading level={2}>{t('Message Patients ({{count}})', { count: selectedAppointments.length })}</Heading>
        <Trans t={t} count={selectedAppointments.length}>
          <Text color='light'>
            Compose your message below or choose a template. We’ll send this message to{' '}
            <strong>{{ count: selectedAppointments.length }}</strong> recipients scheduled for appointments on{' '}
            <strong>{formatDate(date, 'LL')}</strong>
          </Text>
        </Trans>
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: ${theme.spacing(4, 4, 3, 4)};
          background: ${theme.colors.neutral5};
        `}
      >
        <MediaUploadPreview
          media={imageUploadProps.files}
          onDelete={(id) => {
            imageUploadProps.deleteFile(id);
          }}
        />
        <SuperTextarea
          onChange={(val) => setMessage(val)}
          onImageClick={imageUploadModalControl.triggerProps.onClick}
          onTemplateClick={messageTemplatesModalControls.triggerProps.onClick}
          onSubmit={handleSendMassMessage}
          showTagList={false}
          templateTags={templateTags}
          isNewTag
          value={message}
          isValueReRendered={isNewMessage}
          setIsValueReRendered={setIsNewMessage}
          trackingIds={{
            template: ScheduleMassMessageTrackingIds.textAreaTemplateBtn,
            emoji: ScheduleMassMessageTrackingIds.textAreaEmojiBtn,
            image: ScheduleMassMessageTrackingIds.textAreaImageBtn,
          }}
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: white;
            border: 0;
            & > div {
              align-items: flex-start;
              cursor: text;
              flex: 1;
            }
            & > div:nth-last-of-type(1) {
              align-items: flex-end;
            }
          `}
        />
      </div>
      <ButtonBar
        css={{
          padding: theme.spacing(4),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          iconName='back'
          variant='tertiary'
          trackingId={ScheduleMassMessageTrackingIds.sendMassMessageModalBackBtn}
          css={css`
            color: ${theme.colors.primary50};
          `}
          onClick={onBack}
        >
          {t('Back')}
        </Button>
        <div css={{ display: 'flex', gap: theme.spacing(1) }}>
          <Button
            variant='secondary'
            trackingId={ScheduleMassMessageTrackingIds.sendMassMessageModalCancelBtn}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant='primary'
            iconName='send'
            trackingId={ScheduleMassMessageTrackingIds.sendMassMessageBtn}
            css={css`
              gap: ${theme.spacing(1)};
              svg {
                margin: 0;
              }
            `}
            onClick={handleSendMassMessage}
            disabled={!message || isSendMassMessageLoading}
          >
            {t('Send')}
          </Button>
        </div>
      </ButtonBar>
      <Modal {...imageUploadModalControl.modalProps} maxWidth={600}>
        <Modal.Header textAlign='left'>{t('Upload Image')}</Modal.Header>
        <Modal.Body>
          <FileUpload
            onFileUpload={(files: File[]) => {
              imageUploadProps.upload(files);
              imageUploadModalControl.closeModal();
            }}
            acceptedFileType={['png', 'jpg', 'jpeg']}
            helperText={t('Drop image here (PNG or JPG files only)')}
            multiple
          />
        </Modal.Body>
      </Modal>
      <MessageTemplatesModal
        {...messageTemplatesModalControls}
        setDraft={(val) => {
          setIsNewMessage(true);
          setMessage(val);
        }}
        setSelectedTemplate={setSelectedTemplate}
        groupId={appointmentLocationId}
        templateTypeSlugs={[scheduleTemplateTypeSlug]}
      />
    </div>
  );
};
