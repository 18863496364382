import { ComponentProps } from 'react';
import { PersonHelpers } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { Chip, Text } from '@frontend/design-system';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'sms-tag'>>;

export const SmsTagNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const { t } = useTranslation('notifications');
  const actions: ComponentProps<typeof BaseNotificationInner>['actions'] = [
    {
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={notification.payload.action === 'tagApplied' ? t('Tag Applied to Message') : undefined}
        body={
          <>
            <Chip.Tag color={notification.payload.tagColor}>{notification.payload.tagName}</Chip.Tag>
            <Text weight='bold'>
              {PersonHelpers.getFullName({
                FirstName: notification.payload.personFirstName,
                LastName: notification.payload.personLastName,
                PreferredName: notification.payload.personPreferredName,
              }) || formatPhoneNumber(notification.payload.personPhone)}
            </Text>
            <Text>
              {notification.payload.smsBody ||
                (notification.payload.numMedia
                  ? t('{{count}} attachments', { count: notification.payload.numMedia })
                  : t('Nothing to display'))}
            </Text>
          </>
        }
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
