import { useCallback, useMemo } from 'react';
import { CategoryBarChartData, CategoryBarChartProps, TooltipData } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../utils';
import { InfoTipPopover } from '../../../info-tip-popover';
import { useFetchARROIOOverview } from '../hooks';
import { ARMultiChartsBase } from '.';

const colors = {
  yourPractice: theme.colors.secondary.seaweed30,
  noRemindersPractice: theme.colors.critical30,
};

const strokeColors = {
  youPractice: theme.colors.secondary.seaweed40,
};

const labels = {
  groupNameHeader: i18next.t('Location', { ns: 'analytics' }),
  noRemindersPractice: i18next.t('Practices Not Using Appointment Reminders', { ns: 'analytics' }),
  yourPractice: i18next.t('Your Practice', { ns: 'analytics' }),
};

export const ARMultiComparisonNoShowChart = ({
  aggregated,
  isLoading,
  locationsData,
}: ReturnType<typeof useFetchARROIOOverview>) => {
  const { t } = useTranslation('analytics');

  const processedData = useMemo(() => {
    const chartData: CategoryBarChartData = {};
    const customColumns: CategoryBarChartProps['customColumns'] = [];

    Object.entries(locationsData.breakup).forEach(([locationId, { noShowRate }]) => {
      chartData[locationId] = {
        yourPractice: noShowRate,
      };

      customColumns[0] = {
        columnHeaderId: 'noShowRate',
        columnHeaderLabel: t('No-Show Rate'),
        data: {
          ...(customColumns[0]?.data || {}),
          [locationId]: noShowRate,
        },
      };
    });

    return {
      chartData,
      customColumns,
    };
  }, [aggregated, locationsData]);

  const getCustomTooltipData = useCallback(
    (groupId: string): TooltipData[] => {
      const locationData = locationsData.breakup[groupId];
      const commonId = 'yourPractice';

      return [
        {
          color: colors.yourPractice,
          formattedValue: formatters.percent.appendPercent(locationData.noShowRate),
          id: commonId,
          label: labels.yourPractice,
        },
        {
          color: colors.noRemindersPractice,
          formattedValue: formatters.percent.appendPercent(locationsData.noShowRateOfLocationsNotUsingReminders),
          id: commonId,
          label: labels.noRemindersPractice,
        },
      ];
    },
    [locationsData, locationsData.noShowRateOfLocationsNotUsingReminders]
  );

  return (
    <ARMultiChartsBase
      {...processedData}
      colors={colors}
      customLegends={{
        yourPractice: {
          color: colors.yourPractice,
          label: labels.yourPractice,
        },
        marker: {
          color: colors.noRemindersPractice,
          label: labels.noRemindersPractice,
        },
      }}
      customTooltipData={getCustomTooltipData}
      formatValue={formatters.percent.appendPercent}
      infoTip={
        <InfoTipPopover>
          {t(
            'Appointment Reminder Conversion is the percentage of completed appointments that resulted from reminders compared to the total completed appointments.'
          )}
        </InfoTipPopover>
      }
      isLoading={isLoading}
      labels={labels}
      markers={[
        {
          color: theme.colors.critical30,
          id: 'noRemindersPractice',
          value: locationsData.noShowRateOfLocationsNotUsingReminders,
        },
      ]}
      strokeColors={strokeColors}
      title={t('No-Show Rate')}
      trackingIdBase='ar-multi-no-show-rate'
    />
  );
};
