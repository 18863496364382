import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { TemplatesImg } from '@frontend/create-email-landing';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { useBulkEmailEditorStore, useBulkMessagingNavigator } from '../../hooks';

export const EmptyCustomTemplateState = () => {
  const { t } = useTranslation('bulk-messaging');
  const navigateTo = useBulkMessagingNavigator(CampaignType.EMAIL, true);

  const navigateToNewTemplate = () => {
    useBulkEmailEditorStore.reset();
    navigateTo.composer();
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        rowGap: theme.spacing(4),
      }}
    >
      <img src={TemplatesImg} alt='Bulk Messaging' css={{ width: 600 }} />
      <div css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2), alignItems: 'center' }}>
        <Text color='light' textAlign='center' css={{ width: 250 }}>
          {t("Looks like you haven't created any templates yet.")}
        </Text>
        <NakedButton onClick={navigateToNewTemplate}>
          <Text color='primary'>{t('Create New Template')}</Text>
        </NakedButton>
      </div>
    </div>
  );
};
