import { useEffect } from 'react';
import { Location } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/amp/v1/service.pb';
import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { Chips } from '@frontend/chips';
import { useTranslation } from '@frontend/i18n';
import { MultiLocationSelector } from '@frontend/multi-location-selector';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { FieldProps, Stepper, Text } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore } from '../../../hooks';
import { overrideTextStyles, stepValueContainerStyles } from '../styles';
import { Step, StepId } from '../types';

type Props = {
  displayedLocations: string[];
  locationIdsFieldProps: FieldProps<
    {
      locationIds: {
        type: 'checklist';
      };
    },
    'locationIds'
  >;
  locationsWithACLAccess: string[];
  next: (stepId: StepId) => void;
  previous: (stepId: StepId) => void;
  hide: boolean;
};

export const useLocationStep = ({
  displayedLocations,
  locationIdsFieldProps,
  locationsWithACLAccess,
  next,
  previous,
  hide,
}: Props): Step => {
  const { t } = useTranslation('bulk-messaging');
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { data: organizationData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId });
  const { setLocationIds } = useBulkEmailEditorShallowStore('setLocationIds');

  useEffect(() => {
    setLocationIds(locationIdsFieldProps.value);
  }, [locationIdsFieldProps.value]);

  const id: StepId = 'locations';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Select Locations to Send From')}</Stepper.Title>

        <Stepper.Content>
          <MultiLocationSelector
            accessibleLocations={locationsWithACLAccess}
            displayedLocations={displayedLocations}
            fieldProps={{
              locationIds: { ...locationIdsFieldProps, name: 'locationIds' },
            }}
            trackingIdPrefix={BulkEmailPrefixes.Editor}
          />
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => previous(id)} position='secondary' type='none'>
            {t('Previous')}
          </Stepper.ButtonAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none'>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Select Locations to Send From')}</Text>
        {locationIdsFieldProps.value.length > 0 && (
          <div css={{ display: 'flex', gap: theme.spacing(1), flexWrap: 'wrap' }}>
            {...locationIdsFieldProps.value.map((id, index) => (
              <Chips.LocationChip
                key={index + '-' + id}
                css={{
                  ...overrideTextStyles,
                  textTransform: 'initial',
                  fontSize: theme.fontSize(12),
                  '.chip': { maxWidth: 'unset' },
                }}
                variant='primaryDark'
              >
                {organizationData.find((location: Location) => location.locationId === id)?.name ?? ''}
              </Chips.LocationChip>
            ))}
          </div>
        )}
      </div>
    ),
    hide,
  };
};
