import { useAppFlagStore } from '@frontend/shared';
import { PAYMENTS_APPOINMENT_PROMO_FF } from '../constants';
import { useHVAPayments } from './useHVAPayments';

export const useHVAPaymentsInAppointment = (
  props: Parameters<typeof useHVAPayments>[0]
): ReturnType<typeof useHVAPayments> & {
  showHighlightedPaymentBtn: boolean;
} => {
  const hvaPaymentsData = useHVAPayments(props);

  const { getFeatureFlagValue } = useAppFlagStore();

  const showHighlightedPaymentBtn = getFeatureFlagValue({
    locationIds: [props.locationId],
    flagName: PAYMENTS_APPOINMENT_PROMO_FF,
  });

  return {
    ...hvaPaymentsData,
    showHighlightedPaymentBtn,
  };
};
