import { ProcessorSwitch } from '../atoms/processor-switch';
import { TerminalStepContextJustifi } from './terminal-step-context/terminal-step-context-justifi';
import { TerminalStepContextStripe } from './terminal-step-context/terminal-step-context-stripe';

export const TerminalStepContext = ({ children }: React.PropsWithChildren<object>) => {
  return (
    <ProcessorSwitch stripeContent={TerminalStepContextStripe} justifiContent={TerminalStepContextJustifi}>
      {children}
    </ProcessorSwitch>
  );
};
