import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RRule, rrulestr, Weekday } from 'rrule';
import { formatDate } from '@frontend/date';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';
import { ScheduleHours, ScheduleHoursSessionMap } from './types';
import { getTimeDiff } from './utils/date-helpers';

dayjs.extend(utc);

const dayMap: Record<string, Weekday> = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU,
};

const daysMap: { [key: string]: string } = {
  monday: 'MO',
  tuesday: 'TU',
  wednesday: 'WE',
  thursday: 'TH',
  friday: 'FR',
  saturday: 'SA',
  sunday: 'SU',
};

/**
 * @desc: Function is defined to get the rrule day of the week (Weekday) based on the day abbreviation
 * @param day : string - day abbreviation
 * @returns : Weekday
 */
const getRRuleDay = (day: string): Weekday => dayMap[day] || RRule.MO;

/**
 * @desc: Function is defined to create the rrule object based on the session hours details
 * @param sessionHoursDetails
 * @returns : Object : { rrule: string, duration: number }
 */
const curriedBuildRRule = (timezone: string) => (sessionHoursDetails: ScheduleHoursSessionMap) => {
  const { days, startTime, duration } = sessionHoursDetails;

  const rruleObj = new RRule({
    freq: RRule.WEEKLY,
    byweekday: days.map(getRRuleDay),
    dtstart: dayjs.utc(startTime, 'HH:mm').toDate(),
    tzid: timezone,
  });

  return { rrule: rruleObj.toString(), duration };
};
/**
 * @desc: Function is defined to convert the schedule hours object to rrule object for working hours and breaks
 * @param schedule : ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek
 * @param timezone : string
 * @returns ScheduleHours
 */
export const convertScheduleHoursObjectToRRuleObject = (
  schedule: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek,
  timezone?: string
): ScheduleHours => {
  const processSchedule = (
    scheduleMap: Map<string, ScheduleHoursSessionMap>,
    dayAbbreviation: string,
    startTime: string,
    endTime: string,
    type: 'working_hours' | 'break_hours'
  ) => {
    const duration = getTimeDiff(startTime, endTime, 'HH:mm');
    const key = `${startTime}-${duration}`;
    const session = scheduleMap.get(key) || {
      days: [],
      startTime,
      endTime,
      duration,
      type,
    };
    session.days.push(dayAbbreviation);
    scheduleMap.set(key, session);
  };

  const workingHoursMap = new Map<string, ScheduleHoursSessionMap>();
  const breaksMap = new Map<string, ScheduleHoursSessionMap>();

  for (const [day, schedules] of Object.entries(schedule)) {
    const dayAbbreviation = daysMap[day];
    schedules.forEach((schedule) => {
      processSchedule(workingHoursMap, dayAbbreviation, schedule.startTime, schedule.endTime, 'working_hours');
      schedule.breaks?.forEach((breakItem) => {
        processSchedule(breaksMap, dayAbbreviation, breakItem.startTime, breakItem.endTime, 'break_hours');
      });
    });
  }

  const buildRRule = curriedBuildRRule(timezone ?? '');

  const workingHours = Array.from(workingHoursMap.values()).map(buildRRule);
  const breaks = Array.from(breaksMap.values()).map(buildRRule);

  return { workingHours, breaks };
};
/**
 * @desc: This method is used to convert the schedule hours from rrule format to schedule hours object
 * @param scheduleHoursInRRule: ScheduleHours
 * @returns: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek
 */
export const convertFromRRuleScheduleHoursToScheduleHoursObject = (
  scheduleHoursInRRule: ScheduleHours
): ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek => {
  const output: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek = {};

  const processRRule = (item: { rrule?: string; duration?: number }, type: 'working_hours' | 'break_hours') => {
    const { startTime, endTime, daysOfWeek } = getScheduleInfoFromRRule(item.rrule || '', item.duration || 0, 'HH:mm');

    daysOfWeek.forEach((day) => {
      const dayName = dayjs().day(day).format('dddd').toLowerCase();
      output[dayName] = output[dayName] || [];

      if (type === 'working_hours') {
        output[dayName].push({ startTime, endTime });
      } else {
        output[dayName][0].breaks = output[dayName][0].breaks || [];
        output[dayName][0].breaks.push({ startTime, endTime });
      }
    });
  };

  // Process working hours
  scheduleHoursInRRule?.workingHours?.forEach((item) => processRRule(item, 'working_hours'));

  // Process breaks
  scheduleHoursInRRule?.breaks?.forEach((item) => processRRule(item, 'break_hours'));

  return output;
};

/**
 * @desc: Function to extract the date range from an RRule string
 * @param rRuleString : string - The RRule string
 * @returns : Object : { startDatetime?: Date; endDatetime?: Date }
 */
export const getDateRangeFromRRule = (rRuleString?: string): { startDateTime?: string; endDateTime?: string } => {
  if (!rRuleString) return {};

  const rRuleObj = rrulestr(rRuleString);
  const { dtstart, count } = rRuleObj.options;

  return {
    startDateTime: dayjs.utc(dtstart).toISOString(),
    endDateTime: count
      ? dayjs
          .utc(dtstart)
          .add(count - 1, 'days')
          .toISOString()
      : undefined,
  };
};

interface IScheduleExceptionInfo {
  startDate?: string;
  endDate?: string;
  startTime: string;
}

/**
 * @desc: Function to generate an RRule string from a given date range and time range
 * @param dateRange : [string, string] - The start and end dates in 'MM/DD/YYYY' format
 * @param timeRange : [string, string] - The start and end times in 'HH:mm:ss' format
 * @returns : string - The generated RRule string
 */
export const getRRuleStringFromDateRange = (
  { startDate, endDate, startTime }: IScheduleExceptionInfo,
  timezone: string
): string => {
  // Use startDate if endDate is missing and vice versa
  const effectiveStartDate = startDate || endDate;
  const effectiveEndDate = endDate || startDate;

  if (!effectiveStartDate || !effectiveEndDate) {
    throw new Error('Both startDate and endDate cannot be empty');
  }

  const dtstart = dayjs.utc(`${effectiveStartDate} ${startTime}`, 'MM/DD/YYYY HH:mm:ss').toDate();
  const count = dayjs(effectiveEndDate).diff(dayjs(effectiveStartDate), 'days') + 1;

  return new RRule({ freq: RRule.DAILY, dtstart, count, tzid: timezone }).toString();
};

/**
 * @desc: Interface representing schedule information extracted from an RRule string
 */
interface IScheduleInfo {
  startTime: string;
  endTime: string;
  daysOfWeek: number[];
}

/**
 * Extracts schedule information from an RRule string.
 *
 * @param {string} rrule - The recurrence rule string.
 * @param {number} [duration=0] - The duration in minutes for the event.
 * @param {'HH:mm' | 'hh:mm A'} timeFormat - The format for the start and end times.
 * @returns {IScheduleInfo} An object containing the start time, end time, and days of the week.
 */
export const getScheduleInfoFromRRule = (
  rrule: string,
  duration = 0,
  timeFormat: 'HH:mm' | 'hh:mm A'
): IScheduleInfo => {
  const rule = RRule.fromString(rrule);

  const dtStart = rule.options.dtstart;
  const startTime = formatDate(dtStart, timeFormat, true);
  const endTime = startTime ? dayjs(startTime, timeFormat).add(duration, 'm').format(timeFormat) : '';

  // rrule - MO: 0, TU: 1, WE: 2, TH: 3, FR: 4, SA: 5, SU: 6
  // dayjs - MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6, SU: 0,

  // Convert RRule weekdays to dayjs weekdays
  const daysOfWeek = rule.options.byweekday.map((weekday: number) => (weekday === 6 ? 0 : weekday + 1));

  return {
    startTime,
    endTime,
    daysOfWeek,
  };
};
