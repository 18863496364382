import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { featureFlags } from '../../../../feature-flags';
import { formatters } from '../../../../utils';
import { CompareLocationsButton } from '../../../compare-locations-button';
import { DemoChip } from '../../../demo-chip';
import { InfoTipPopover } from '../../../info-tip-popover';
import { useFetchARROIOOverview } from '../hooks';
import { ARChartsBase } from './ar-charts-base';

const colors = {
  completedByReminders: theme.colors.tangerine30,
};

const labels = {
  completedByReminders: i18next.t('Appointments Completed with Appointment Reminders', { ns: 'analytics' }),
  backfill: i18next.t('Total Appointments completed', { ns: 'analytics' }),
};

const trackingIdBase = 'ar-conversion';

interface Props extends ReturnType<typeof useFetchARROIOOverview> {
  onShowMoreClick?: () => void;
  showCompareMulti?: boolean;
}

export const ARRoiConversionChart = ({ aggregated, isLoading, onShowMoreClick, showCompareMulti }: Props) => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);
  const { appointmentsCompleted } = aggregated || {};

  return (
    <Chart
      colors={colors}
      isLoading={isLoading}
      labels={labels}
      noBorder={!onShowMoreClick}
      onClick={onShowMoreClick}
      trackingId={`${trackingIdBase}-chart`}
    >
      {!!onShowMoreClick && (
        <Chart.Header
          title={t('Appointment Reminder Conversion')}
          infoTip={
            <InfoTipPopover>
              {t(
                'Appointment Reminder Conversion is the percentage of completed appointments that resulted from reminders compared to the total completed appointments.'
              )}
            </InfoTipPopover>
          }
          bottomElement={
            showCompareMulti && (
              <CompareLocationsButton onClick={onShowMoreClick} trackingId={`${trackingIdBase}-compare-locations`} />
            )
          }
          leftElement={isDemoAccount && <DemoChip />}
        />
      )}
      <ARChartsBase onShowMoreClick={onShowMoreClick} trackingIdBase={trackingIdBase}>
        <Chart.RadialBarChart
          appearance={{
            maxPolarAxisValue: appointmentsCompleted?.totalAppointmentsCompleted,
          }}
          data={{
            centerMetric: formatters.percent.format(
              (appointmentsCompleted?.appointmentsCompletedByReminders || 0) /
                (appointmentsCompleted?.totalAppointmentsCompleted || 1)
            ),
            groups: [
              {
                name: 'completedByReminders',
                value: appointmentsCompleted?.appointmentsCompletedByReminders,
              },
            ],
          }}
        />
        <Chart.Legends
          customData={{
            completedByReminders: {
              color: colors.completedByReminders,
              label: labels.completedByReminders,
              value: formatters.value.format(appointmentsCompleted?.appointmentsCompletedByReminders),
            },
            totalAppts: {
              color: theme.colors.neutral10,
              label: labels.backfill,
              value: formatters.value.format(appointmentsCompleted?.totalAppointmentsCompleted),
            },
          }}
          style={{ marginTop: theme.spacing(4) }}
        />
      </ARChartsBase>
    </Chart>
  );
};
