import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { AutoMessagePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Accordion, Heading } from '@frontend/design-system';
import { AutoMessageDetailAppointmentAccordionBody } from './auto-message-detail-appointment-accordion-body';

const AUTO_MESSAGES_ACCORDION_ID = 'schedule-event-notes-section';

// TODO: this is a copy of the type from the scheduling feature, should be moved to a shared location
export type ScheduleEventPersonInfo = {
  id: string;
  name: string;
  status: string;
  age: string;
  gender: string;
  householdId: string;
};

type Props = {
  appointmentId: string;
  appointmentLocationId: string;
  closeAppointmentDetailsModal?: () => void;
  person: ScheduleEventPersonInfo;
};

export const AutoMessageDetailAppointmentEvent = ({
  appointmentId,
  appointmentLocationId,
  closeAppointmentDetailsModal,
  person,
}: Props) => {
  const { t } = useTranslation('messages');
  const [accordionValue, setAccordionValue] = useState<string | null>(null);

  return (
    <Accordion
      controlledValue={accordionValue}
      variant='blank'
      css={{ marginBottom: theme.spacing(4) }}
      onChange={(val) => setAccordionValue(val)}
    >
      <Accordion.Item
        value={AUTO_MESSAGES_ACCORDION_ID}
        trackingId={AutoMessagePrefixes.Suppression + '-appointment-event-accordion-next-7-days'}
      >
        <Accordion.Header
          css={{ borderBottom: `1px solid ${theme.colors.neutral20}`, '.header-container': { paddingLeft: 0 } }}
        >
          <Heading level={2}>{t('Auto-Messages: Next 7 Days')}</Heading>
        </Accordion.Header>
        {accordionValue && (
          <AutoMessageDetailAppointmentAccordionBody
            appointmentId={appointmentId}
            appointmentLocationId={appointmentLocationId}
            closeAppointmentDetailsModal={closeAppointmentDetailsModal}
            person={person}
          />
        )}
      </Accordion.Item>
    </Accordion>
  );
};
