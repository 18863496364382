import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DatePickerField,
  DropdownField,
  FormRow,
  Tray,
  SwitchField,
  Text,
  TimeField,
  ValidatorFieldState,
  useForm,
  useFormField,
  Button,
  ConfirmationModal,
  useModalControl,
} from '@frontend/design-system';
import { useDropdownListSelector } from '../../../../components/DropdownListSelector';
import { useCalendarEventsConfigurationShallowStore } from '../../../../hooks';
import { CalendarSidePanelSpinnerLoaderContainer } from '../../components/CalendarSidePanelSpinnerLoaderContainer';
import { EditableTextContainer } from '../../components/EditableTextContainer';
import { EventButton } from '../../components/EventButton';
import { ProviderDropdownListSelector } from '../../components/ProviderDropdownListSelector';
import { CalendarEventsEnums } from '../../types';
import { convertTo24HourFormat, getIsEndTimeBeforeStartTime, isUUID } from '../../utils';
import { calendarEventsTrackingIds } from './calendar-events-tracking-ids';
import { ExceptionFormValues, OnDeleteExceptionParams } from './types';

interface OutOfOfficeEventsProps {
  eventType: CalendarEventsEnums;
  providerList: Provider[];
  selectedLocationIds: string[];
  selectedDate: string;
  isLoading: boolean;
  formError: string;
  onSave: (formValues: ExceptionFormValues) => void;
  onDelete: (params: OnDeleteExceptionParams) => void;
  closeModal: () => void;
}

export const OutOfOfficeEventsForm = React.memo(
  ({
    eventType,
    formError,
    providerList,
    selectedLocationIds,
    selectedDate,
    isLoading,
    onSave,
    onDelete,
    closeModal,
  }: OutOfOfficeEventsProps) => {
    const { t } = useTranslation('scheduleCalendarEvents');
    const { getScopeName } = useAppScopeStore();
    const { configuredCalendarEvent } = useCalendarEventsConfigurationShallowStore('configuredCalendarEvent');

    const [currentEventType, setCurrentEventType] = useState<CalendarEventsEnums>(eventType);

    const textFieldProps = useFormField({ type: 'text', value: configuredCalendarEvent?.name ?? '' });

    const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } = useModalControl();

    const providerDropdownListSelectorProps = useDropdownListSelector({
      value: isUUID(configuredCalendarEvent?.providerId ?? '') ? configuredCalendarEvent?.providerId : '',
    });

    const locationDropdown = useFormField({
      type: 'dropdown',
      value: selectedLocationIds.length === 1 ? selectedLocationIds[0] : configuredCalendarEvent?.locationId ?? '',
    });

    const isAllDay = useMemo(
      () =>
        !!(
          configuredCalendarEvent?.startDate &&
          configuredCalendarEvent?.endDate &&
          ((configuredCalendarEvent?.startDate === '00:00' && configuredCalendarEvent?.endDate === '23:59') ||
            (configuredCalendarEvent?.startHour === '12:00 AM' && configuredCalendarEvent?.endHour === '11:59 PM'))
        ),
      [
        configuredCalendarEvent?.startDate,
        configuredCalendarEvent?.endDate,
        configuredCalendarEvent?.startHour,
        configuredCalendarEvent?.endHour,
      ]
    );

    const {
      formProps,
      getFieldProps,
      reset,
      seedValues,
      isComplete: isFormComplete,
      values,
    } = useForm({
      fields: {
        isAllDay: {
          type: 'switch',
          value: isAllDay,
        },
        startDate: {
          type: 'datePicker',
          value: '',
          required: true,
        },
        startTime: {
          type: 'time',
          value: '',
          required: true,
        },
        endDate: {
          type: 'datePicker',
          value: '',
          required: true,
          validateOnChange: true,
          validator: (field: ValidatorFieldState<'datePicker'>, allFields) => {
            const isEndDateBeforeStartDate =
              allFields.startDate?.value &&
              dayjs(field.value).isBefore(dayjs(allFields.startDate.value)) &&
              field.value !== allFields.startDate.value;

            if (isEndDateBeforeStartDate) {
              return t('End date cannot be before start date');
            }
            return '';
          },
        },
        endTime: {
          type: 'time',
          value: '',
          required: true,
          validateOnChange: true,
          validator: (field: ValidatorFieldState<'time'>, allFields) => {
            const hasStartAndEndDates = allFields.startDate?.value && allFields.endDate?.value;
            const isEndDateBeforeStartDate =
              dayjs(allFields.endDate?.value).isBefore(dayjs(allFields.startDate?.value)) ||
              allFields.endDate?.value === allFields.startDate?.value;

            const isEndTimeBeforeStartTime = getIsEndTimeBeforeStartTime(
              allFields.startDate?.value,
              allFields.startTime?.value,
              allFields.endDate?.value,
              field.value
            );

            if (hasStartAndEndDates && isEndDateBeforeStartDate && isEndTimeBeforeStartTime) {
              return t('End time cannot be before start time');
            }
            return '';
          },
        },
      },

      onSubmit: async (formValues) => {
        const providerId = providerDropdownListSelectorProps.value;

        const locationId = locationDropdown.value
          ? locationDropdown.value
          : providerList.find((provider) => provider.id === providerId)?.locationId ?? '';

        onSave({
          endDate: formValues.endDate || '',
          endTime: formValues.endTime || '',
          startDate: formValues.startDate || '',
          startTime: formValues.startTime || '',
          isAllDay: formValues.isAllDay || false,
          eventId: configuredCalendarEvent?.eventId,
          name: textFieldProps.value,
          providerId,
          locationId,
          eventType: currentEventType,
        });
      },
    });

    const handleAddNew = (event?: React.FormEvent<Element>) => {
      if (!event) {
        return;
      }
      formProps.onSubmit(event);
    };

    const handleDeleteAction = () => {
      const { locationId, providerId, eventId } = configuredCalendarEvent ?? {};
      onDelete({
        eventId: eventId ?? '',
        providerId: providerId ?? '',
        locationId: locationId ?? '',
        eventType: currentEventType,
      });
    };

    // useEffect to seed form values when user drags the event in the calendar
    useEffect(() => {
      const hasConfiguredCalendarEvent = !!(
        configuredCalendarEvent?.startHour &&
        configuredCalendarEvent?.endHour &&
        configuredCalendarEvent?.calendarDateValue
      );

      if (hasConfiguredCalendarEvent) {
        seedValues({
          ...values,
          startTime: convertTo24HourFormat(configuredCalendarEvent?.startHour ?? ''),
          endTime: convertTo24HourFormat(configuredCalendarEvent?.endHour ?? ''),
          startDate: dayjs(configuredCalendarEvent?.startDate || configuredCalendarEvent?.calendarDateValue).format(
            'MM/DD/YYYY'
          ),
          endDate: dayjs(configuredCalendarEvent?.endDate || configuredCalendarEvent?.calendarDateValue).format(
            'MM/DD/YYYY'
          ),
        });
      }

      if (configuredCalendarEvent?.providerId) {
        const providerId = isUUID(configuredCalendarEvent?.providerId ?? '') ? configuredCalendarEvent?.providerId : '';
        providerDropdownListSelectorProps.onChange(providerId);
      }
    }, [
      configuredCalendarEvent?.startHour,
      configuredCalendarEvent?.endHour,
      configuredCalendarEvent?.startDate,
      configuredCalendarEvent?.endDate,
      configuredCalendarEvent?.calendarDateValue,
      configuredCalendarEvent?.providerId,
    ]);

    // useEffect to seed form values when user clicks selects all day event
    useEffect(() => {
      if (values.isAllDay) {
        const startDateString = values.startDate
          ? values.startDate
          : configuredCalendarEvent?.startDate || selectedDate;
        const formattedStartDate = dayjs(startDateString).format('MM/DD/YYYY');
        const endDateString = values.endDate ? values.endDate : configuredCalendarEvent?.endDate || selectedDate;
        const formattedEndDate = dayjs(endDateString).format('MM/DD/YYYY');
        seedValues({
          startDate: formattedStartDate,
          startTime: '12:00am',
          endDate: formattedEndDate,
          endTime: '11:59pm',
        });
      }
    }, [values.isAllDay]);

    // Update location value to provider's location when the provider is selected
    useEffect(() => {
      if (providerDropdownListSelectorProps.value) {
        locationDropdown.value =
          providerList.find((provider) => provider.id === providerDropdownListSelectorProps.value)?.locationId ?? '';
      }
    }, [providerDropdownListSelectorProps.value]);

    // Reset form on unmount
    useEffect(() => {
      return () => reset();
    }, []);

    const isFormValid =
      isFormComplete &&
      !!textFieldProps.value &&
      (currentEventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT
        ? providerDropdownListSelectorProps.value
        : locationDropdown.value);

    return (
      <>
        {isLoading ? (
          <CalendarSidePanelSpinnerLoaderContainer />
        ) : (
          <>
            <Text size='small' color='light'>
              {t('Out of Office')}
            </Text>
            <EditableTextContainer
              name={t('name-your-event')}
              label={t('Name your event')}
              {...textFieldProps}
              placeHolder={t('Name Your Event')}
            />
            <div css={buttonContainerStyles}>
              <EventButton
                isActive={currentEventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT}
                onClick={() => setCurrentEventType(CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT)}
              >
                {t('Provider Event')}
              </EventButton>
              <EventButton
                isActive={currentEventType === CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT}
                onClick={() => setCurrentEventType(CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT)}
              >
                {t('Office Event')}
              </EventButton>
            </div>
            {currentEventType === CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT &&
              selectedLocationIds.length > 1 && (
                <div css={providerFieldContainerStyles}>
                  <Text>{t('Location')}</Text>
                  <DropdownField
                    name={'location'}
                    css={{ marginLeft: theme.spacing(2) }}
                    label={t('{{count}} Locations', { count: selectedLocationIds.length })}
                    {...locationDropdown}
                  >
                    {selectedLocationIds.map((locationId) => {
                      const locationName = getScopeName(locationId);
                      return (
                        <DropdownField.Option key={locationId} value={locationId} searchValue={locationName ?? ''}>
                          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                            <Icon name='location' size={16} color='light' />
                            <Text>{locationName}</Text>
                          </div>
                        </DropdownField.Option>
                      );
                    })}
                  </DropdownField>
                </div>
              )}
            {currentEventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT && (
              <div css={providerFieldContainerStyles}>
                <Text>{t('Provider')}</Text>
                <ProviderDropdownListSelector
                  providerDropdownListSelectorProps={providerDropdownListSelectorProps}
                  listWidth={280}
                  filterProviderByProviderUUID={true}
                  providersList={providerList}
                  selectedLocationIds={selectedLocationIds}
                  hideLocationTag={selectedLocationIds.length === 1}
                />
              </div>
            )}
            <form {...formProps}>
              <SwitchField
                {...getFieldProps('isAllDay')}
                label={t('All-day')}
                labelPlacement='left'
                css={{ borderBottom: `1px solid ${theme.colors.neutral20}`, paddingBottom: theme.spacing(1) }}
              />
              <FormRow cols={[100]} css={formRowStyles}>
                <Text css={textStyles}>{t('Start')}</Text>
                <div css={datePickerFieldContainerStyles}>
                  <DatePickerField {...getFieldProps('startDate')} label={t('Date')} css={datePickerStyles} />
                  <TimeField
                    {...getFieldProps('startTime')}
                    label={t('Time')}
                    css={timeFieldStyles}
                    disabled={values.isAllDay || !values.startDate}
                  />
                </div>
              </FormRow>
              <FormRow cols={[100]} css={formRowStyles}>
                <Text css={textStyles}>{t('End')}</Text>
                <div css={datePickerFieldContainerStyles}>
                  <DatePickerField
                    {...getFieldProps('endDate')}
                    label={t('Date')}
                    css={datePickerStyles}
                    disabled={!values.startDate || !values.startTime}
                  />
                  <TimeField
                    {...getFieldProps('endTime')}
                    label={t('Time')}
                    css={timeFieldStyles}
                    disabled={values.isAllDay || !values.startDate || !values.endDate}
                  />
                </div>
              </FormRow>
            </form>
            {formError && <Text color='error'>{formError}</Text>}
          </>
        )}
        <div css={trayActions}>
          {configuredCalendarEvent?.eventId && (
            <Button
              iconName='trash'
              variant='tertiary'
              destructive
              disabled={isLoading}
              onClick={deleteTriggerProps.onClick}
              trackingId={calendarEventsTrackingIds.deleteOutOfOfficeEventButton}
            >
              {t('Delete')}
            </Button>
          )}
          <div css={{ marginLeft: 'auto' }}>
            <Tray.Actions
              primaryLabel={t('Save Event')}
              secondaryLabel={t('Cancel')}
              disabledPrimary={isLoading || !isFormValid}
              disabledSecondary={isLoading}
              onPrimaryClick={handleAddNew}
              onSecondaryClick={closeModal}
              primaryTrackingId={calendarEventsTrackingIds.saveOutOfOfficeEventButton}
              secondaryTrackingId={calendarEventsTrackingIds.cancelOutOfOfficeEventButton}
            />
          </div>
        </div>
        <ConfirmationModal
          {...deleteModalProps}
          title={t('Delete Exception')}
          destructive
          message={t(
            "Are you sure you want to delete {{exceptionName}}? Doing so will remove this exception from {{entityName}}'s schedule, and their availability will be updated accordingly.",
            {
              exceptionName: configuredCalendarEvent?.name || t('exception'),
              entityName:
                eventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT
                  ? configuredCalendarEvent?.providerName || t('provider')
                  : getScopeName(configuredCalendarEvent?.locationId || '') || t('location'),
            }
          )}
          confirmLabel={t('Delete')}
          cancelLabel={t('Cancel')}
          onCancel={deleteModalProps.onClose}
          onConfirm={handleDeleteAction}
        />
      </>
    );
  }
);

OutOfOfficeEventsForm.displayName = 'OutOfOfficeEventsForm';

const buttonContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});

const providerFieldContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 30%) 1fr',
  alignItems: 'center',
  margin: theme.spacing(3, 0),
  '> div': {
    width: '100%',
  },
});

const formRowStyles = css({
  marginTop: theme.spacing(2),
});

const datePickerFieldContainerStyles = css({ display: 'flex', margin: 0, marginLeft: 'auto' });
const datePickerStyles = css({ width: '160px', marginRight: theme.spacing(2) });
const timeFieldStyles = css({ width: '110px' });

const trayActions = css({
  marginTop: 'auto',
  display: 'flex',
  alignItems: 'center',
});

const textStyles = css({ margin: 0, marginTop: theme.spacing(1), minWidth: theme.spacing(6) });
