import { Entity } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/entity.pb';
import { UiSource } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/ui_source.pb';
import { createStore, createShallowStore } from '@frontend/store';
import { SessionSearches } from './types';

type SearchHistoryStore = {
  debouncedValue: string;
  resetSessionSearches: (entities: Entity[]) => void;
  searchId: string;
  searchTerm: string;
  sessionSearches: SessionSearches;
  setDebouncedValue: (debouncedValue: string) => void;
  setSearchId: (searchId: string) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSessionSearches: (sessionSearches: SessionSearches) => void;
  setStartTime: (startTime: number) => void;
  setUiSource: (uiSource: UiSource) => void;
  startTime: number;
  uiSource: UiSource;
};

export const useSearchHistoryStore = createStore<SearchHistoryStore>(
  (set) => ({
    debouncedValue: '',
    resetSessionSearches: (entities) => {
      const sessionSearches = entities.reduce((acc, entity) => ({ ...acc, [entity]: {} }), {});
      set({ sessionSearches });
    },
    searchId: '',
    searchTerm: '',
    sessionSearches: {},
    setDebouncedValue: (debouncedValue) => set({ debouncedValue }),
    setSearchId: (searchId) => set({ searchId }),
    setSearchTerm: (searchTerm) => set({ searchTerm }),
    setSessionSearches: (sessionSearches) =>
      set((prev) => ({ sessionSearches: { ...prev.sessionSearches, ...sessionSearches } })),
    setStartTime: (startTime) => set({ startTime }),
    setUiSource: (uiSource) => set({ uiSource }),
    startTime: 0,
    uiSource: UiSource.GLOBAL_SEARCH,
  }),
  {
    name: 'search-history-store',
  }
);

export const useSearchHistoryShallowStore = createShallowStore<SearchHistoryStore>(useSearchHistoryStore);
