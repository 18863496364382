import { theme } from '@frontend/theme';
import { Avatar, Chip, Text } from '@frontend/design-system';

type TagChipProps = {
  name: string;
  onClick?: () => void;
  fullWidth?: boolean;
};
export const TagChip = ({ name, onClick, fullWidth }: TagChipProps) => {
  return (
    <div
      css={{
        '.payment-field-chip': {
          maxWidth: fullWidth ? 'fit-content' : '',
        },
        '.payment-field-chip button>svg': {
          color: 'black',
        },
      }}
    >
      <Chip.Person
        className='payment-field-chip'
        css={{
          backgroundColor: theme.colors.primary5,
          border: 'none',
        }}
        onClick={onClick}
        variant='primary'
        avatar={<Avatar size='xxs' name={name} />}
      >
        <Text size='medium' as='span'>
          {name}
        </Text>
      </Chip.Person>
    </div>
  );
};
