import { useCallback } from 'react';
import {
  Appointment,
  CalendarException,
} from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { OfficeHoursDaySchedule } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { uniqBy } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ProviderEventType } from '../components/calendar-view/types';
import { getFullName } from '../utils';
import { useGetAppointmentsEvents } from './use-get-appointments-events';
import { useGetProvidersEvents } from './use-get-providers-events';

type UseGetProvidersDetailsForCalendarType = {
  providersList: ScheduleTypes.SchemaProvider[];
  appointments: Appointment[];
  providersExceptions: CalendarException[];
  providersOfficeHours: OfficeHoursDaySchedule[];
};

export const useGetProvidersDetailsForCalendar = ({
  appointments,
  providersExceptions,
  providersList,
  providersOfficeHours,
}: UseGetProvidersDetailsForCalendarType) => {
  const { getProvidersBreakEvents, getProvidersExceptionsEvent } = useGetProvidersEvents();

  const { getAppointmentsEventByProviderId } = useGetAppointmentsEvents();

  const formatForSorting = (str: string) => str.replace(/[^a-zA-Z0-9\s]/g, ' ').toLowerCase();

  const getProvidersForCalendarObject = useCallback(
    (locationId: string): ProviderEventType[] => {
      return (
        providersList
          ?.filter(({ locationId: providerLocationId }) => providerLocationId === locationId)
          ?.sort((a, b) => {
            const aName = formatForSorting(a.publicDisplayName || a.lastName || a.firstName || a.resourceName || '');
            const bName = formatForSorting(b.publicDisplayName || b.lastName || b.firstName || b.resourceName || '');
            return aName.localeCompare(bName);
          })
          ?.map((provider) => {
            const fullName = getFullName(provider);
            const publicDisplayName = provider.publicDisplayName?.trim();
            const resourceName = provider.resourceName?.trim();

            const providersExceptionsEvent = getProvidersExceptionsEvent(providersExceptions ?? [], provider.id ?? '');

            const appointmentsEvent = getAppointmentsEventByProviderId({
              appointments: appointments ?? [],
              providerId: provider.id ?? '',
              locationId,
              providerName: fullName,
              providerDisplayName: publicDisplayName,
            });
            const providersBreakEvents = getProvidersBreakEvents(providersOfficeHours ?? [], provider.id ?? '');

            return {
              name: publicDisplayName || fullName || resourceName || '',
              providerId: provider.id ?? '',
              src: provider.publicDisplayImage || '',
              events: uniqBy(
                [...providersExceptionsEvent, ...appointmentsEvent, ...providersBreakEvents],
                (event) => event.eventId
              ),
            };
          }) ?? []
      );
    },
    [providersList, appointments, providersExceptions, providersOfficeHours]
  );

  return { getProvidersForCalendarObject };
};
