import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ModalControlModalProps, Text, Tray, delay } from '@frontend/design-system';
import {
  CallIntelBetweenLocationsPreview,
  CallIntelSummaryPreview,
  CallIntelWithinLocationsPreview,
} from '../../assets/images';
import { trackingIds } from '../../tracking-ids';
import { RadioFilterSelector } from '../filter-selectors';
import { PreviewPopover } from '../preview-popover';
import { MultiLocationsView } from './hooks';

type Props = ModalControlModalProps & {
  onChange: (value: MultiLocationsView) => void;
  value?: MultiLocationsView;
};

export const ExploreLocationsTray = ({ onChange, value, ...rest }: Props) => {
  const { t } = useTranslation('analytics');
  const [componentState, setComponentState] = useState<MultiLocationsView | undefined>(value);

  const renderOpportunityOption = useCallback(
    (title: string, subtitle: string, previewImage: string) => (
      <>
        <Text size='medium' weight='bold'>
          {title}
        </Text>
        <Text color='subdued' size='small'>
          {subtitle} <PreviewPopover imageSrc={previewImage} title={title} />
        </Text>
      </>
    ),
    []
  );

  const handleApply = () => {
    if (componentState) {
      delay(200).then(() => onChange(componentState));
      rest.onClose();
    }
  };

  const handleCancel = () => {
    rest.onClose();
  };

  useEffect(() => {
    if (rest.show) {
      setComponentState(value);
    }
  }, [rest.show]);

  return (
    <Tray {...rest} width='medium'>
      <Tray.Header
        Buttons={
          <Button
            iconName='x'
            label={t('Close')}
            onClick={rest.onClose}
            size='large'
            trackingId={trackingIds.callIntel.closeExploreLocations}
            variant='secondary'
          />
        }
      >
        {t('Explore Location Data')}
      </Tray.Header>
      <Tray.Body>
        <RadioFilterSelector<MultiLocationsView>
          label={t('Explore Location Data')}
          onChange={setComponentState}
          options={{
            summary: renderOpportunityOption(
              t('Summary'),
              t(
                "See multiple locations' data combined. This view enables you to understand how your organization is performing as a whole."
              ),
              CallIntelSummaryPreview
            ),
            betweenLocations: renderOpportunityOption(
              t('Compare Data between Locations'),
              t(
                'See your data organized by each location. This view lets you quickly compare data across your various locations to gauge their performance against each other.'
              ),
              CallIntelBetweenLocationsPreview
            ),
            withinLocations: renderOpportunityOption(
              t('Compare Data within Locations'),
              t(
                'See your data organized by each location. This view lets you easily compare data within each location to assess the performance of every location on its own.'
              ),
              CallIntelWithinLocationsPreview
            ),
          }}
          radioStyle={styles.radioStyles}
          showLabel={false}
          trackingId={trackingIds.callIntel.exploreLocationsRadio}
          value={componentState}
        />
      </Tray.Body>
      <Tray.Actions
        css={styles.actions}
        onPrimaryClick={handleApply}
        onSecondaryClick={handleCancel}
        primaryLabel={t('View Charts')}
        primaryTrackingId={trackingIds.callIntel.applyExploreCharts}
        secondaryLabel={t('Cancel')}
        secondaryTrackingId={trackingIds.callIntel.cancelExploreCharts}
      />
    </Tray>
  );
};

const styles = {
  radioStyles: css`
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }

    div.radio-option {
      align-items: flex-start;
    }
  `,

  actions: css`
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: flex-end;

    .tray-primary-button {
      margin-left: 0;
    }

    > button + button {
      margin-left: 0;
    }
  `,
};
