import { PhoneHoursScheduleRule } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

/** date in ISO format. For example: "2023-08-14T20:54:13Z" */
export const formatDate = (date: string | undefined, formatStr?: string, timeZone?: string) => {
  return dayjs(date).tz(timeZone).format(formatStr);
};

export const dayOfWeekMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export type DayRules = {
  [dayOfWeek: number]: {
    openStartTime: string;
    openEndTime: string;
    breaks?: { startTime: string; endTime: string; name: string }[];
  };
};

/**
 * Helper function to group phone hours (open hours and break hours) by day of week. Ensures
 * that each day of the week has a key in the object (since the absence of a day in the
 * rules of type == "OPEN" means that the business is closed on that day).
 *
 * @param phoneHours
 * @returns
 */
export const groupByDayOfWeek = (phoneHours: PhoneHoursScheduleRule[]) => {
  // Initialize the grouped hours object to make sure there is a key for each day of the week
  const initialDayRules: DayRules = {
    0: { openStartTime: '', openEndTime: '' },
    1: { openStartTime: '', openEndTime: '' },
    2: { openStartTime: '', openEndTime: '' },
    3: { openStartTime: '', openEndTime: '' },
    4: { openStartTime: '', openEndTime: '' },
    5: { openStartTime: '', openEndTime: '' },
    6: { openStartTime: '', openEndTime: '' },
  };

  const groupedHours = phoneHours.reduce<DayRules>((acc, phoneHour) => {
    if (!phoneHour.rules) {
      return acc;
    }

    phoneHour.rules.forEach((rule) => {
      if (phoneHour.type === 'OPEN') {
        // if the rule is of type "OPEN", set the open hours for the specific day
        acc[rule.dayOfWeek] = {
          ...acc[rule.dayOfWeek],
          openStartTime: rule.startTime,
          openEndTime: rule.endTime,
        };
      } else if (phoneHour.type === 'BREAK') {
        // if the rule is of type "BREAK", add to the break hours for the specific day
        acc[rule.dayOfWeek] = {
          ...acc[rule.dayOfWeek],
          breaks: [
            ...(acc[rule.dayOfWeek]?.breaks ?? []),
            { startTime: rule.startTime, endTime: rule.endTime, name: phoneHour.name },
          ],
        };
      }
    });

    return acc;
  }, initialDayRules);

  return groupedHours;
};
