import { css } from '@emotion/react';
import {
  PhoneHoursExpansion,
  CallObject,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Accordion, Heading } from '@frontend/design-system';
import { DayHoursList } from '../../phone-hours/day-hours-list';

export type PhoneHoursCallObject = Extract<CallObject, { phoneHoursExpansion: PhoneHoursExpansion }>;

export function isPhoneHoursCallObject(callObject: CallObject): callObject is PhoneHoursCallObject {
  return 'phoneHoursExpansion' in callObject;
}

type PhoneHoursPanelProps = {
  phoneHourCallObjects: PhoneHoursCallObject[];
};

export const PhoneHoursPanel = ({ phoneHourCallObjects }: PhoneHoursPanelProps) => {
  return (
    <>
      {phoneHourCallObjects.length === 1 && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(2)};
          `}
        >
          <Heading level={3}>{phoneHourCallObjects[0].primitiveName}</Heading>
          <DayHoursList useLargerFonts phoneHours={phoneHourCallObjects[0].phoneHoursExpansion.phoneHours} />
        </div>
      )}

      {phoneHourCallObjects.length > 1 &&
        phoneHourCallObjects.map((callObject, index) => {
          const { phoneHoursExpansion } = callObject;
          return (
            <Accordion
              key={callObject.primitiveName + index}
              css={css`
                > div {
                  box-shadow: none;
                  border: 1px solid ${theme.colors.neutral20};
                }

                > div h3 button section div svg {
                  fill: ${theme.colors.neutral70};
                }

                div[data-active='true'] h3 button {
                  background-color: ${theme.colors.neutral5};
                  border-bottom: 1px solid ${theme.colors.neutral10};
                }
              `}
            >
              <Accordion.Item value={'phoneHours'}>
                <Accordion.Header>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: ${theme.spacing(2)};
                    `}
                  >
                    <Icon name='clock' />
                    <Heading level={3}>{callObject.primitiveName}</Heading>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <DayHoursList useLargerFonts phoneHours={phoneHoursExpansion.phoneHours} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
    </>
  );
};
