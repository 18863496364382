import * as StreamAPI from './stream-api';
import * as StreamMutations from './stream-mutations';
import * as StreamQueries from './stream-queries';
import * as StreamSubscriptions from './stream-subscriptions';

/**
 * Implementing an adapter here. The interface is *lightly* coupled to the StreamChat SDK, but should be fairly easy to swap out.
 * Since team chat is our only SDK for now we're not going to be too strict on the interface constraints
 */

export const Api = {
  initializeClient: StreamAPI.initializeStreamClient,
  disconnectClient: StreamAPI.disconnectStreamClient,
  reconnectClient: StreamAPI.reconnectStreamClient,
  getToken: StreamAPI.getStreamChatToken,
  getUnreadMentionsCount: StreamAPI.getUnreadMentionsCount,
  getMentions: StreamAPI.getMentions,
  getUsers: StreamAPI.getUsers,
  getConversations: StreamAPI.getConversations,
  getConversationMessages: StreamAPI.getConversationMessages,

  updateUserPresence: StreamAPI.updateUserPresence,
  sendMessage: StreamAPI.sendMessage,
  addReactionToMessage: StreamAPI.addReactionToMessage,
  removeReactionFromMessage: StreamAPI.removeReactionFromMessage,
  updateUser: StreamAPI.updateUser,
  sendConversationEvent: StreamAPI.sendConversationEvent,
};

export const Queries = {
  useInvalidateAll: StreamQueries.useInvalidateAll,
  useQueryInitializeClient: StreamQueries.useQueryStreamClient,
  useQueryUnreadMentionsCount: StreamQueries.useQueryUnreadMentionsCount,
  useQueryMentions: StreamQueries.useQueryMentions,
  useQueryUsers: StreamQueries.useQueryUsers,
  useQueryConversations: StreamQueries.useQueryConversations,
  useQueryConversationMessages: StreamQueries.useQueryConversationMessages,
  useQueryReplies: StreamQueries.useQueryReplies,
};

export const Mutations = {
  useMutationSendMessage: StreamMutations.useMutationSendMessage,
  useMutationSendReply: StreamMutations.useMutationSendReply,
  useMutationEditMessage: StreamMutations.useMutationEditMessage,
  useMutationDeleteMessage: StreamMutations.useMutationDeleteMessage,
  useMutationAddReactionToMessage: StreamMutations.useMutationAddReaction,
  useMutationRemoveReactionFromMessage: StreamMutations.useMutationDeleteReaction,
  useMutationAddAttachments: StreamMutations.useMutationAddAttachments,
  useMutationUpdateUser: StreamMutations.useMutationUpdateUser,
  useMutationCreateConversation: StreamMutations.useMutationCreateConversation,
  useMutationUpdateConversation: StreamMutations.useMutationUpdateConversation,
  useMutationDeleteConversation: StreamMutations.useMutationDeleteConversation,
  useMutationSendConversationEvent: StreamMutations.useMutationSendConversationEvent,
  useMutationAddMembersToConversation: StreamMutations.useMutationAddMembersToConversation,
  useMutationRemoveMembersFromConversation: StreamMutations.useMutationRemoveMembersFromConversation,
};

export const Subscriptions = {
  subscribeToClient: StreamSubscriptions.subscribeToClient,
  subscribeToChannel: StreamSubscriptions.subscribeToChannel,
  getWeaveWebsocketHandler: StreamSubscriptions.getTeamChatWeaveWebsocketHandler,
};
