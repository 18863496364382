import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { SelectNavigationSwitch } from './select/select-navigation-switch';

export const Select = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.select}>
      <SelectNavigationSwitch />
    </StepPerformanceWrapper>
  );
};
