import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';
import { useActiveConversationSelector } from '../../providers/active-conversation.provider';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { ConversationBody } from '../conversation/conversation-body';
import { ThreadTitle } from './thread-title';

export const ThreadPanel = () => {
  return (
    <>
      <ThreadTitle />
      <ThreadBody />
    </>
  );
};

const ThreadBody = () => {
  const { t } = useTranslation('team-chat');
  const selectedThreadParentId = useTeamChatSelector((ctx) => ctx.selectedThreadParentId);
  const parentMessage = useActiveConversationSelector((ctx) =>
    ctx.messages.find((message) => message.id === selectedThreadParentId)
  );
  const replies = useActiveConversationSelector((ctx) => ctx.replies);
  const repliesError = useActiveConversationSelector((ctx) => ctx.repliesError);
  const fetchNextReplies = useActiveConversationSelector((ctx) => ctx.fetchNextReplies);
  const isLoadingReplies = useActiveConversationSelector((ctx) => ctx.isLoadingReplies);
  const allMessages = useMemo(() => (parentMessage ? [parentMessage, ...replies] : []), [parentMessage, replies]);

  if (repliesError) {
    return (
      <EmptyPanelWrapper>
        <Text color='error'>{t('Error loading replies')}</Text>
      </EmptyPanelWrapper>
    );
  }

  if (!parentMessage) {
    return (
      <EmptyPanelWrapper>
        <Text color='error'>{t('Thread parent message not found')}</Text>
      </EmptyPanelWrapper>
    );
  }

  // if (parentMessage.type === 'deleted') {
  //   return (
  //     <EmptyPanelWrapper>
  //       <Text color='error'>{t('Parent message has been deleted')}</Text>
  //     </EmptyPanelWrapper>
  //   );
  // }

  if (isLoadingReplies) {
    return (
      <EmptyPanelWrapper>
        <SpinningLoader />
      </EmptyPanelWrapper>
    );
  }

  return parentMessage ? (
    <ConversationBody selectedTarget={undefined} messages={allMessages} fetchNextPage={fetchNextReplies} />
  ) : (
    <SpinningLoader />
  );
};

const EmptyPanelWrapper = ({ children }: { children: React.ReactNode }) => (
  <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: theme.spacing(2) }}>
    {children}
  </div>
);
