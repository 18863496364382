import { useTranslation } from '@frontend/i18n';
import { ModalControlModalProps, Tray, Heading, Button } from '@frontend/design-system';
import { VerifyInsuranceForm } from './verify-insurance-form';

interface Props extends ModalControlModalProps {
  onReportLearnMoreClick: () => void;
}

export const VerifyInsuranceSidePanel = ({ onReportLearnMoreClick, ...modalProps }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  return (
    <Tray {...modalProps} width='medium' disableCloseOnEscape>
      <Tray.Header
        css={{ alignItems: 'flex-start' }}
        Buttons={<Button iconName='x' variant='secondary' label='close' onClick={modalProps.onClose} />}
      >
        <Heading>{t('Verify Insurance')}</Heading>
      </Tray.Header>
      {modalProps.show && (
        <VerifyInsuranceForm onClose={modalProps.onClose} onReportLearnMoreClick={onReportLearnMoreClick} />
      )}
    </Tray>
  );
};
