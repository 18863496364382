import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';
import { Author } from './author';
import { NonActionableMessageBase } from './base';
import { MessageBody } from './message-body';

interface MessageItemProps {
  message: TeamChatTypes.Message;
  onClick?: () => void;
  className?: string;
}

export const MessageMinimal = memo(({ className, message, onClick }: MessageItemProps) => {
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const messageSenderUser = useMemo(() => helpers.getUser(message.userId), [helpers.getUser, message.userId]);
  const messageSenderUsers = useMemo(() => (messageSenderUser ? [messageSenderUser] : []), [messageSenderUser]);
  const name = getUserFullName(messageSenderUsers?.[0]?.firstName, messageSenderUsers?.[0]?.lastName);
  return (
    <NonActionableMessageBase as='div' className={className} isHighlighted={false} onClick={onClick}>
      {messageSenderUsers?.length && (
        <ChatAvatar
          users={messageSenderUsers}
          size='small'
          showStatusAsTooltip
          css={css({ gridColumn: '1/2', gridRow: '1/2' })}
        />
      )}
      <div>
        <Author date={message.createdAt} name={name || 'Unknown Member'} userID={message.userId} />
        <MessageBody message={message} />
      </div>
    </NonActionableMessageBase>
  );
});

MessageMinimal.displayName = 'MessageMinimal';
