import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, styles, TextAlignment } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { CategoryBarChartAppearance } from './category-bar-chart-types';

interface Props {
  canUserSort?: boolean;
  categoryId: string;
  columnHeaderLabel?: string;
  customCategoryNameRenderer?: CategoryBarChartAppearance['customCategoryNameRenderer'];
  onClick?: (categoryId: string, sortedAsc?: boolean) => void;
  sortedAsc?: boolean;
  sortedBy?: boolean;
  textAlign?: TextAlignment;
  trackingIdBase?: string;
}

export const ColumnHeader = forwardRef<HTMLDivElement, Props>(
  (
    {
      canUserSort,
      categoryId,
      columnHeaderLabel,
      customCategoryNameRenderer,
      onClick,
      sortedAsc,
      sortedBy,
      textAlign = 'left',
      trackingIdBase = '',
    },
    ref
  ) => {
    const { t } = useTranslation('analytics');
    const { labels = {} } = useChartContext();

    // For placeholder, we are still rendering the column header to maintain the layout, but in invisible state
    const isPlaceholder = categoryId === 'placeholder';

    return (
      <div ref={ref}>
        {customCategoryNameRenderer ? (
          customCategoryNameRenderer(categoryId)
        ) : (
          <NakedButton
            css={[buttonStyles, styles.truncate]}
            onClick={canUserSort ? () => onClick?.(categoryId, sortedAsc) : undefined}
            style={{
              color: sortedBy ? theme.font.colors.default : theme.font.colors.subdued,
              cursor: canUserSort ? 'pointer' : 'default',
              opacity: isPlaceholder ? 0 : 1,
              pointerEvents: isPlaceholder ? 'none' : 'auto',
              textAlign,
              width: isPlaceholder ? 0 : '100%',
            }}
            trackingId={`${trackingIdBase}-column-header-${categoryId}`}
          >
            {categoryId === 'sn' ? (
              t('No')
            ) : (
              <>
                {columnHeaderLabel ?? labels?.[categoryId] ?? categoryId}
                {!!canUserSort && <Icon name={sortedAsc ? 'alt-caret-up-tiny' : 'alt-caret-down-tiny'} />}
              </>
            )}
          </NakedButton>
        )}
      </div>
    );
  }
);

ColumnHeader.displayName = 'ColumnHeader';

const buttonStyles = css`
  align-items: center;
  color: ${theme.font.colors.subdued};
  display: flex;
  font-size: ${theme.font.size.medium};
  font-weight: ${theme.font.weight.regular};
  gap: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(2)};

  svg {
    min-width: ${theme.spacing(1.5)};
  }
`;
