import { FC } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, Text } from '@frontend/design-system';
import BundleUpgradeIcon from '../../../assets/bundle-upgrade-icon.svg';
import WivPromotionIcon from '../../../assets/wiv-promotion-icon.svg';
import { useFeatureSubscriptionConfig } from '../../../hooks/useFeatureSubscriptionConfig';
import { FeatureSubscriptionContext } from '../../../providers/FeatureSubscriptionProvider';
import { getBundleTrialDays, getUpgradeBundleDisplayName } from '../../../self-upgrade.util';
import { WivPromotionAction } from './WivPromotionAction';

interface WivPromotionCardProps {
  cardStyles?: Interpolation<Theme>;
  locationIds: string[];
  source: 'contacts' | 'appointments';
}
export const WivPromotionCard: FC<WivPromotionCardProps> = ({ cardStyles, locationIds, source }) => {
  const featureSubscriptionConfig = useFeatureSubscriptionConfig({ feature: Feature.WEAVE_VERIFY, locationIds });
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'wiv-promotion' });

  const { recommendedBundle, isBundleUpgradeEligible } = featureSubscriptionConfig;
  return (
    <FeatureSubscriptionContext.Provider value={featureSubscriptionConfig}>
      <div css={[{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }, cardStyles]}>
        <img
          src={isBundleUpgradeEligible ? BundleUpgradeIcon : WivPromotionIcon}
          height={theme.spacing(6)}
          width={theme.spacing(6)}
        />

        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          {isBundleUpgradeEligible && (
            <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
              <Text size='medium' weight='bold' css={{ color: theme.colors.secondary.seaweed50 }}>
                {t('Insurance Verification')}
              </Text>
              <Chip variant='seaweed'>{t('Premium')}</Chip>
            </div>
          )}
          <Text size='large' color='light'>
            {isBundleUpgradeEligible
              ? t('Included in the {{bundleName}} Bundle. Try {{bundleName}} free for {{count}} days.', {
                  bundleName: getUpgradeBundleDisplayName(recommendedBundle),
                  count: getBundleTrialDays(recommendedBundle),
                })
              : t('Save time by using Weave insurance verification.')}
          </Text>
          <WivPromotionAction trackingPrefix={source} actionStyles={{ marginLeft: theme.spacing(-1.5) }} />
        </div>
      </div>
    </FeatureSubscriptionContext.Provider>
  );
};
