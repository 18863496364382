import { InvoiceDetailModel, InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';

export const isSuccessfullyPaidInvoice = (invoice: InvoiceModel) =>
  invoice.payment &&
  (invoice.status === InvoiceStatus.Paid ||
    invoice.status === InvoiceStatus.PartiallyPaid ||
    invoice.payment?.status === 'SUCCEEDED');

export const isPaidInvoice = (invoice: InvoiceModel | InvoiceDetailModel | undefined) =>
  !!invoice && (isSuccessfullyPaidInvoice(invoice) || invoice.payment?.status === 'PENDING');

export const isCanceledInvoice = (invoice: InvoiceModel) => invoice.status === InvoiceStatus.Canceled;

export const isPartialPayment = (invoice: InvoiceModel) =>
  invoice.payment && invoice.billedAmount - invoice.payment.paidAmount > 0;

export const isCompletelyPaid = (invoice: InvoiceModel) => isPaidInvoice(invoice) && !isPartialPayment(invoice);
