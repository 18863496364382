import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { useAppScopeStore, useScopedAppFlagStore, useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Text, emptyStateGraphics, styles } from '@frontend/design-system';
import { useFetchAggregatedCallsSummary } from '../../components/phone/hooks';
import { queryKeys } from '../../query-keys';
import { appendTime, defaultDateRangeMap, formatEndDateWithTimeZone, formatStartDateWithTimeZone } from '../../utils';
import { CallResultsPieChart, CallResultsPieChartDataInfo } from './pie-chart';

const useCallResultsData = (
  shouldUseCallResultsEndpoint: boolean,
  dateRange: { startDate: string; endDate: string },
  filteredLocationIds: string[],
  timezone: string
) => {
  const filters = {
    startTime: appendTime(dayjs(dateRange.startDate)) ?? '',
    endTime: appendTime(dayjs(dateRange.endDate)) ?? '',
  };

  const searchQuery = useMemo(
    () => `${JSON.stringify({ ...filters })}`,
    [filters.startTime, filters.endTime, filteredLocationIds]
  );

  const { data: callResult, isLoading: isCallResultLoading } = useScopedQuery({
    queryKey: queryKeys.callResults(searchQuery),
    queryFn: () =>
      PhoneCallsApi.getCallResults({
        startTime: formatStartDateWithTimeZone(filters.startTime),
        endTime: formatEndDateWithTimeZone(filters.endTime),
        locationIds: filteredLocationIds,
      }),
    enabled: shouldUseCallResultsEndpoint && filteredLocationIds.length > 0,
    retry: false,
  });

  const { data, isLoading } = useFetchAggregatedCallsSummary(
    {
      start_date: filters.startTime,
      end_date: filters.endTime,
      location_id: filteredLocationIds,
      time_zone: timezone,
    },
    !shouldUseCallResultsEndpoint
  );

  return useMemo(() => {
    let answeredCalls = 0;
    let missedCalls = 0;
    let abandonedCalls = 0;
    let totalCalls = 0;
    let isDataLoading = false;

    if (shouldUseCallResultsEndpoint) {
      answeredCalls = Number(callResult?.answeredCalls) || 0;
      missedCalls = Number(callResult?.missedCalls) || 0;
      abandonedCalls = Number(callResult?.abandonedCalls) || 0;
      isDataLoading = isCallResultLoading;
    } else {
      answeredCalls = Number(data?.answerRate?.answered) || 0;
      missedCalls = Number(data?.answerRate?.missed) || 0;
      abandonedCalls = Number(data?.answerRate?.abandoned) || 0;
      isDataLoading = !!isLoading;
    }

    totalCalls = answeredCalls + missedCalls + abandonedCalls;

    return {
      answeredCalls,
      missedCalls,
      abandonedCalls,
      totalCalls,
      isDataLoading,
    };
  }, [callResult, data, isLoading, isCallResultLoading, shouldUseCallResultsEndpoint]);
};

/**
 * @dashboard-widget
 *
 * id: call-results-widget
 * title: Call Results
 * description: Highlights incoming calls, showing whether they were answered, missed, or abandoned.
 * icon: phone-small
 */
export const CallResultsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState('week');
  const { accessibleLocationData, selectedLocationIds, getScopeName } = useAppScopeStore();
  const { widgetTrackingId } = useDashboardWidget();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>(selectedLocationIds);

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const shouldUseCallResultsEndpoint = getFeatureFlagValue('nwx-use-callresults-data') ?? false;
  const timezone = accessibleLocationData[filteredLocationIds[0]]?.timezone ?? '';

  const locationOptions = useMemo(
    () =>
      selectedLocationIds.map((id) => ({
        label: getScopeName(id),
        value: id,
      })),
    [getScopeName, selectedLocationIds]
  );

  const thisWeekDateRange = defaultDateRangeMap['this-week'];
  const callData = useCallResultsData(shouldUseCallResultsEndpoint, thisWeekDateRange, filteredLocationIds, timezone);

  const chartData = useMemo<CallResultsPieChartDataInfo[]>(() => {
    return [
      { key: 'answered', label: t('Answered'), value: callData.answeredCalls },
      { key: 'missed', label: t('Missed'), value: callData.missedCalls },
      { key: 'abandoned', label: t('Abandoned'), value: callData.abandonedCalls },
    ];
  }, [callData]);

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={contentContainerStyle}>
        <ContentLoader show={callData.isDataLoading} />
        <LocationFilterMenu
          options={locationOptions}
          selectedOptions={selectedLocationIds}
          onChange={setFilteredLocationIds}
          trackingIdBase={widgetTrackingId('location-selector')}
          showClearAll={false}
        />
        {!callData.totalCalls && !callData.isDataLoading ? (
          <div css={[styles.flexCenter, { flexDirection: 'column', gap: theme.spacing(2) }]}>
            {!!filteredLocationIds.length && <emptyStateGraphics.no_calls_made height={120} width={120} />}
            <Text color='light'>
              {!filteredLocationIds.length ? t('No locations selected') : t('No data available')}
            </Text>
          </div>
        ) : (
          <div css={halfWidthStyle}>
            <CallResultsPieChart centerMetricLabel={t('Total Calls')} data={chartData} tooltipLabel={t('Calls')} />
          </div>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

CallResultsWidget.config = {
  size: 'medium-narrow',
  feature: 'phone',
};

const contentContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  height: '100%',
  minHeight: 0,
});

const halfWidthStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  minHeight: 0,
});
