import { UseQueryOptions } from 'react-query';
import { useQuery } from '@frontend/react-query-helpers';
import { GetDevice, GetDeviceE911Address, ListDevices, UpdateDevice, ValidateDestroyLine } from './api';
import { queryKeys } from './query-keys';
import type {
  ValidateDestroLineType,
  GetDeviceLineType,
  GetDevicesListType,
  GetDeviceE911AddressType,
  UpdateDeviceType,
} from './types';

export const useValidateDestroyQuery = (req: ValidateDestroLineType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: [opts.locationId, ...queryKeys.validateDestroyLine(req.id ?? '')],
    queryFn: () => ValidateDestroyLine(req),
    refetchOnMount: 'always',
  });
};

export const useGetDeviceQuery = (req: GetDeviceLineType['input']) => {
  return useQuery({
    queryKey: [req.deviceId, ...queryKeys.deviceById()],
    queryFn: () =>
      GetDevice({
        deviceId: req.deviceId,
      }),
  });
};

export const useGetDeviceE911Address = <T = GetDeviceE911AddressType['output']>(
  req: GetDeviceE911AddressType['input'],
  opts?: UseQueryOptions<GetDeviceE911AddressType['output'], unknown, T>
) => {
  return useQuery({
    queryKey: [req.deviceId, ...queryKeys.deviceAddressById()],
    queryFn: () =>
      GetDeviceE911Address({
        deviceId: req.deviceId,
      }),
    ...opts,
  });
};

export const useGetDevicesList = <T = GetDevicesListType['output']>(
  req: GetDevicesListType['input'],
  opts?: UseQueryOptions<GetDevicesListType['output'], unknown, T>
) => {
  return useQuery({
    queryKey: [req.tenantId, ...queryKeys.devicesList()],
    queryFn: () => ListDevices(req),
    ...opts,
  });
};

export const useUpdateDevice = (req: UpdateDeviceType['input'], opts: { locationId: string }) => {
  return useQuery({
    queryKey: '',
    queryFn: () =>
      UpdateDevice(
        {
          device: req.device,
        },
        { headers: { 'Location-Id': opts.locationId } }
      ),
  });
};
