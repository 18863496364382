import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, CrownIconSmall, NakedA, SpinningLoader } from '@frontend/design-system';
import { AnalyticsTrackingIds } from '../..';
import { PracticeAnalyticsPLGVideo } from '../../assets';
import { URLs } from '../../constants';
import { HomepageAnalyticsHeader } from './homepage-analytics-header';

type Props = {
  isFreeTrialEligible?: boolean;
  isLoadingFreeTrialEligibility?: boolean;
  onSubscribeClick: () => void;
};

export const PAUpsellBanner = ({ isFreeTrialEligible, isLoadingFreeTrialEligibility, onSubscribeClick }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { getCustomizationFlag } = useScopedAppFlagStore();

  const isFreeTrialActive = getCustomizationFlag('practiceanalytics').every(
    (flag) => flag?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE && flag?.isFreeTrial
  );

  const getUpgradeButtonTitle = () => {
    if (isFreeTrialActive || !isFreeTrialEligible) {
      return t('Upgrade to Practice Analytics');
    }

    return t('Try 30 Days Free Trial');
  };

  return (
    <section css={styles.wrapper}>
      <HomepageAnalyticsHeader
        action={
          isLoadingFreeTrialEligibility ? (
            <div css={styles.loadingSpinner}>
              <SpinningLoader size='small' />
            </div>
          ) : (
            <Button
              css={styles.upgradeButton}
              onClick={onSubscribeClick}
              size='large'
              trackingId={AnalyticsTrackingIds.practiceAnalyticsPLG.plgUpgradeButton}
            >
              <CrownIconSmall size={28} css={{ fill: 'none', stroke: theme.colors.white }} />
              {getUpgradeButtonTitle()}
            </Button>
          )
        }
        css={styles.header}
        title={t('What is Practice Analytics?')}
      />
      <NakedA
        css={styles.videoWrapper}
        onClick={() => {
          navigate({
            to: URLs.PRACTICE_ANALYTICS_SUBSCRIBE,
          });
        }}
        trackingId={AnalyticsTrackingIds.practiceAnalyticsPLG.plgUpgradeBanner}
      >
        <video autoPlay loop muted playsInline src={PracticeAnalyticsPLGVideo} style={{ width: '100%' }} />
      </NakedA>
    </section>
  );
};

const styles = {
  wrapper: css`
    margin-bottom: ${theme.spacing(3)};
  `,

  header: css`
    padding-top: 0;
    flex-wrap: nowrap;
  `,

  upgradeButton: css`
    height: auto;
    width: auto;

    svg {
      margin-left: 0;
      margin-right: ${theme.spacing(1)};
    }
  `,

  videoWrapper: css`
    border-radius: ${theme.borderRadius.medium};
    display: block;
    overflow: hidden;
  `,

  loadingSpinner: css`
    align-items: center;
    display: flex;
    height: ${theme.spacing(5.75)};
    justify-content: center;
  `,
};
