import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { ServiceProvidersApi } from '@frontend/api-service-providers';
import { EmptyStates } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  ButtonBar,
  Checkbox,
  ChecklistField,
  Text,
  DatePickerField,
  Heading,
  useControlledField,
  useFormField,
  NakedUl,
  ContentLoader,
  Button,
  NotificationBadge,
} from '@frontend/design-system';
import { queryKeys } from '../../../../../../query-keys';
import { customAccordionStyles } from '../../../../../components/location-accordion-list';
import { useScheduleActionsContainerMethod } from '../../../../../context/ScheduleActionsContainerContext';
import { useGetCalendarEventsV3Data, useGetV3CalendarStartAndEndDateTime } from '../../../../../hooks';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../stores/use-schedule-actions-appointment-list-store';
import { convertV3CalendarEventToAppointmentDetails } from '../../../../CalendarV3/helpers';
import { PatientListRow } from '../../../components/PatientListRow';
import { ScheduleMassMessageStepEnum } from '../../../types';
import { FiltersContainer } from './Filters/FilterContainer';
import { SendScheduledMessageToPatients } from './SendScheduledMessageToPatients';
import { ScheduleMassMessageTrackingIds } from './trackingIds';
import { getUniqueListByKey } from './utils';

const { listAppointments } = ServiceProvidersApi;

export const SchedulePulseContactPatientsPanel = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('scheduleCalendarActions');

  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const { setHideTabs, selectedDate, selectedLocationId: locationId, closeModal } = useScheduleActionsContainerMethod();

  const selectedLocationId = locationId ?? '';

  const [currStep, setCurrStep] = useState<ScheduleMassMessageStepEnum>(
    ScheduleMassMessageStepEnum.SELECT_PATIENTS_TO_CONTACT
  );

  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState<string[]>([]);

  const {
    statusFilter,
    appointmentList,
    setAppointmentList,
    setDefaultAppointmentList,
    defaultAppointmentList,
    providerList,
    insuranceStatusFilters,
  } = useScheduleActionsAppointmentListInfoShallowStore(
    'statusFilter',
    'appointmentList',
    'setAppointmentList',
    'setDefaultAppointmentList',
    'defaultAppointmentList',
    'providerList',
    'insuranceStatusFilters'
  );

  const todayDate = dayjs().format('MM/DD/YYYY').toString();

  const calendarProps = useFormField({
    type: 'datePicker',
    value: selectedDate || (todayDate as string),
  });

  const isValidDate = calendarProps?.value && !calendarProps.error;

  const [startDate, endDate] = useMemo(() => {
    if (isValidDate) {
      const startDateInUTC = dayjs(String(calendarProps.value)).utc().toISOString();
      const endDateInUTC = dayjs(String(calendarProps.value)).add(1, 'day').utc().toISOString();
      return [startDateInUTC, endDateInUTC];
    }
    return ['', ''];
  }, [calendarProps?.value, isValidDate]);

  const {
    data: appointments,
    refetch: refetchAppointments,
    isFetching,
  } = useScopedQuery({
    queryKey: queryKeys.appointments({
      equals: {
        insuranceStatuses: insuranceStatusFilters,
        locationIds: selectedLocationId ? [selectedLocationId] : selectedLocationIds,
      },
      between: { start: startDate, end: endDate },
    }),
    queryFn: () =>
      listAppointments({
        between: { start: startDate, end: endDate },
        equals: {
          locationIds: selectedLocationId ? [selectedLocationId] : selectedLocationIds,
          insuranceStatuses: insuranceStatusFilters,
        },
      }),
    enabled:
      (!!selectedLocationIds.length || !!selectedLocationId) && !!startDate && !!endDate && !isScheduleV3FlagEnabled,
  });

  const { startDateTime: calendarEventsStartDate, endDateTime: calendarEventsEndDate } =
    useGetV3CalendarStartAndEndDateTime(dayjs(String(calendarProps.value)).toString());

  const {
    data: calendarEventsData,
    isLoading: isLoadingCalendarEvents,
    refetch: refetchCalendarEvents,
  } = useGetCalendarEventsV3Data({
    request: {
      startDateTime: calendarEventsStartDate,
      endDateTime: calendarEventsEndDate,
      page: 1,
      limit: 50,
    },
    selectedLocationIds,
    selectedLocationId: selectedLocationId ?? selectedLocationIds[0],
    enabled: isScheduleV3FlagEnabled,
  });

  // Convert calendarEvents to appointments format to display in appointments list and store in ScheduleActionsAppointmentListInfo store
  useEffect(() => {
    const isScheduleV3 = !!calendarEventsData?.events?.length && isScheduleV3FlagEnabled;
    const appointmentsList = isScheduleV3
      ? convertV3CalendarEventToAppointmentDetails(calendarEventsData?.events)
      : appointments?.appointments;

    !statusFilter && setAppointmentList(appointmentsList ?? []);
    setDefaultAppointmentList(appointmentsList ?? []);
  }, [appointments?.appointments, calendarEventsData?.events, isScheduleV3FlagEnabled]);

  useEffect(() => {
    if (isValidDate) {
      if (isScheduleV3FlagEnabled) {
        refetchCalendarEvents();
      } else {
        refetchAppointments();
      }
    }
  }, [isValidDate, isScheduleV3FlagEnabled]);

  const filteredAppointments = useMemo(() => {
    const list = !!appointmentList.length || !!providerList.length ? appointmentList : defaultAppointmentList;
    return list?.reduce(
      (acc, obj) => {
        let appointmentStatus = obj.statusOfficeView?.toLowerCase();

        if (appointmentStatus === 'unknown') {
          appointmentStatus = 'unconfirmed';
        }

        const isTextOptedIn = obj.person?.personId && obj.person?.textOptedIn && obj?.person?.mobilePhone;
        if (isTextOptedIn) {
          acc.textOptedIn?.push(obj);
        }
        if (!obj.person?.textOptedIn && obj.person?.personId && obj?.person?.mobilePhone) {
          acc.textOptedOut?.push(obj);
        }
        return acc;
      },
      { textOptedIn: [] as Appointment[], textOptedOut: [] as Appointment[] }
    );
  }, [statusFilter, appointmentList, defaultAppointmentList]);

  const startingValue =
    filteredAppointments?.textOptedIn?.length ?? 0 > 0
      ? 'message-list'
      : filteredAppointments?.textOptedOut?.length ?? 0 > 0
      ? 'phone-call-list'
      : 'first';

  const checklistFieldProps = useFormField(
    {
      type: 'checklist',
      value: selectedAppointmentIds,
    },
    [selectedAppointmentIds]
  );

  const [filteredTextOptInAppointmentList, filteredTextOptOutAppointmentList] = useMemo(() => {
    return [
      getUniqueListByKey<Appointment>(filteredAppointments?.textOptedIn ?? [], 'id'),
      getUniqueListByKey<Appointment>(filteredAppointments?.textOptedOut ?? [], 'id'),
    ];
  }, [filteredAppointments?.textOptedIn, filteredAppointments?.textOptedOut]);

  const selectAllProps = useControlledField({
    type: 'checkbox',
    value: checklistFieldProps.value.length === filteredTextOptInAppointmentList?.length,
    onChange: () => {
      if (checklistFieldProps.value.length === filteredTextOptInAppointmentList?.length) {
        setSelectedAppointmentIds([]);
      } else {
        setSelectedAppointmentIds(filteredTextOptInAppointmentList?.map((appt) => appt.id ?? '') ?? []);
      }
    },
  });

  const selectedAppointments = useMemo(() => {
    return (
      checklistFieldProps.value?.map((apptId) => {
        return filteredTextOptInAppointmentList?.find((appt) => appt.id === apptId) ?? {};
      }) ?? []
    );
  }, [checklistFieldProps.value, filteredTextOptInAppointmentList]);

  const appointmentsLocationId = useMemo(() => {
    if (!!selectedAppointments.length) {
      return selectedAppointments.find((appt) => appt.locationId)?.locationId ?? '';
    }
    return '';
  }, [selectedAppointments]);

  const handleSendMessagePageBackClick = () => {
    setCurrStep(ScheduleMassMessageStepEnum.SELECT_PATIENTS_TO_CONTACT);
  };

  if (currStep === ScheduleMassMessageStepEnum.SEND_MASS_MESSAGE && isValidDate) {
    return (
      <SendScheduledMessageToPatients
        selectedAppointments={selectedAppointments}
        date={calendarProps.value}
        onClose={() => closeModal?.()}
        onBack={handleSendMessagePageBackClick}
        appointmentLocationId={selectedLocationId ?? appointmentsLocationId ?? ''}
      />
    );
  }

  return (
    <section css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <header
        css={{
          marginBottom: theme.spacing(2),
          padding: theme.spacing(0, 3),
        }}
      >
        <Heading level={3} css={{ marginBottom: theme.spacing(1) }}>
          {t('Select Patients to Contact')}
        </Heading>
        <div css={{ display: 'flex', gap: theme.spacing(2), alignItems: 'flex-start' }}>
          <DatePickerField
            data-trackingid={ScheduleMassMessageTrackingIds.datePickerField}
            {...calendarProps}
            css={{ width: 190 }}
            name=''
            label={''}
          />
          <FiltersContainer />
        </div>
      </header>
      <div css={{ position: 'relative', background: theme.colors.neutral5, flex: 1, overflowY: 'auto' }}>
        <ContentLoader show={isFetching || isLoadingCalendarEvents} />
        {!isFetching && (
          <Accordion
            distance={3}
            css={[
              customAccordionStyles.accordionContainer,
              {
                background: theme.colors.neutral5,
                padding: theme.spacing(2, 4, 3, 4),
              },
            ]}
            showBoxShadow
            variant='location'
            chevronSize={16}
            size='small'
            startingValue={startingValue}
          >
            <Accordion.Item
              trackingId={ScheduleMassMessageTrackingIds.messageListAccordion}
              key='message-list'
              value='message-list'
              css={{ minHeight: 40 }}
            >
              <Accordion.Header disabled={!filteredTextOptInAppointmentList?.length} css={customAccordionStyles.header}>
                <Accordion.Header.Icon icon='messaging' title={t('Message List')} />

                {!!filteredTextOptInAppointmentList?.length && (
                  <NotificationBadge truncateCount css={{ backgroundColor: theme.colors.primary50 }}>
                    {filteredTextOptInAppointmentList.length}
                  </NotificationBadge>
                )}
              </Accordion.Header>

              <Accordion.Body css={customAccordionStyles.body}>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                    padding: theme.spacing(1, 2),
                    margin: theme.spacing(0, 1),
                  }}
                >
                  <Checkbox
                    data-trackingid={ScheduleMassMessageTrackingIds.selectAllMessageListCheckbox}
                    name='select-all-rows'
                    {...selectAllProps}
                    error={false}
                  ></Checkbox>
                  <Heading level={3}>
                    {t('{{count}} Patients', { count: filteredTextOptInAppointmentList?.length })}
                  </Heading>
                </div>
                <hr />
                <section css={{ padding: theme.spacing(0, 2), overflow: 'auto' }}>
                  {!!filteredTextOptInAppointmentList?.length ? (
                    <NakedUl>
                      <ChecklistField {...checklistFieldProps} name='message-list-checklist' label=''>
                        {filteredTextOptInAppointmentList?.map((appt) => {
                          return (
                            <ChecklistField.Option
                              key={appt.id ?? ''}
                              name={appt.id ?? ''}
                              css={css`
                                align-items: center;
                                margin: 0 8px;
                                & > label {
                                  width: 100%;
                                }
                              `}
                            >
                              <PatientListRow key={appt.id} appt={appt} locationId={appt?.locationId || ''} />
                            </ChecklistField.Option>
                          );
                        })}
                      </ChecklistField>
                    </NakedUl>
                  ) : (
                    <EmptyStates
                      emptyStateConfig={{ type: 'schedule', header: '', description: t('No Message List') }}
                    />
                  )}
                </section>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              trackingId={ScheduleMassMessageTrackingIds.phoneListAccordion}
              key='phone-call-list'
              value='phone-call-list'
              css={{ minHeight: 40 }}
            >
              <Accordion.Header
                disabled={!filteredTextOptOutAppointmentList?.length}
                css={customAccordionStyles.header}
              >
                <Accordion.Header.Icon icon='phone-outgoing' title={t('Phone Call List')} />
                {!!filteredTextOptOutAppointmentList?.length && (
                  <NotificationBadge truncateCount css={{ backgroundColor: theme.colors.primary50 }}>
                    {filteredTextOptOutAppointmentList.length}
                  </NotificationBadge>
                )}
              </Accordion.Header>

              <Accordion.Body css={customAccordionStyles.body}>
                <Text css={{ padding: theme.spacing(1, 2) }} color='light' size='medium'>
                  {t(
                    'These patients have either opted out of receiving text messages or do not have a textable number saved, and must be called'
                  )}
                </Text>
                <hr />
                {!!filteredTextOptOutAppointmentList ? (
                  <NakedUl css={{ overflow: 'auto' }}>
                    {filteredTextOptOutAppointmentList?.map((appt) => {
                      return (
                        <PatientListRow key={appt.id} optOutText appt={appt} locationId={appt?.locationId || ''} />
                      );
                    })}
                  </NakedUl>
                ) : (
                  <Text css={noListItemStyles}>{t('No Phone Call List')}</Text>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
      <footer>
        <ButtonBar
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(4),
          }}
        >
          <Button
            variant='tertiary'
            onClick={() => setHideTabs(false)}
            trackingId={ScheduleMassMessageTrackingIds.backBtn}
            iconName='back'
          >
            {t('Back')}
          </Button>
          <div css={{ display: 'flex', gap: theme.spacing(1) }}>
            <Button
              variant='secondary'
              trackingId={ScheduleMassMessageTrackingIds.cancelBtn}
              onClick={() => closeModal?.()}
            >
              {t('Cancel')}
            </Button>
            <Button
              disabled={selectedAppointments.length === 0}
              onClick={() => setCurrStep(ScheduleMassMessageStepEnum.SEND_MASS_MESSAGE)}
              trackingId={ScheduleMassMessageTrackingIds.nextBtn}
            >
              {t('Next')}
            </Button>
          </div>
        </ButtonBar>
      </footer>
    </section>
  );
};

const noListItemStyles = css({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.colors.neutral50,
});
