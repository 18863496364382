import { useCallback, useState } from 'react';
import { SearchFailedPaymentsOrder, SearchFailedPaymentsParams, SearchFailedPaymentStatus } from './payment-failed';

const INITIAL_STATE: SearchFailedPaymentsParams = {
  search: {
    paymentStatus: [SearchFailedPaymentStatus.FAILED], // hard code status
    origin: undefined,
    text: undefined,
  },
  submittedAt: {
    greaterThanEqual: undefined,
    lessThanEqual: undefined,
  },
  first: 10,
  order: '-submittedAt',
  after: undefined,
};

export const useFailedTransactionsFilters = () => {
  const [filter, setFilter] = useState<SearchFailedPaymentsParams>(INITIAL_STATE);

  const [currentPage, setCurrentPage] = useState(1);

  const setOrder = useCallback(
    (order: SearchFailedPaymentsOrder) => {
      setCurrentPage(1);
      setFilter((prev) => ({ ...prev, order }));
    },
    [filter]
  );

  const setSubmittedAt = useCallback(
    (start?: string, end?: string) => {
      setCurrentPage(1);
      setFilter((prev) => ({
        ...prev,
        submittedAt: {
          ...prev.submittedAt,
          greaterThanEqual: start,
          lessThanEqual: end,
        },
      }));
    },
    [filter]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);
    setFilter(INITIAL_STATE);
  }, []);

  const setNumRows = useCallback(
    (numRows: number) => {
      setCurrentPage(1);
      setFilter((prev) => ({ ...prev, first: numRows }));
    },
    [filter]
  );

  return {
    filter,
    currentPage,
    filterMethods: {
      setFilter,
      setCurrentPage,
      setOrder,
      resetFilter,
      setSubmittedAt,
      setNumRows,
    },
  };
};

export type UseFailedTransactionsFiltersMethods = ReturnType<typeof useFailedTransactionsFilters>['filterMethods'];
