import { NumberType_Enum } from '@weave/schema-gen-ts/dist/schemas/comm/phone-number/v1/phone_number_service.pb';
import { Channel } from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { AutoRulesQueries } from '@frontend/api-auto-rules';
import { BusinessInfoQueries } from '@frontend/api-business-information';
import { ClientSettingsApi, ClientSettingsQueries } from '@frontend/api-client-settings';
import { EmailComposerQueries } from '@frontend/api-email-composer';
import { BulkMessagesQueries } from '@frontend/api-messaging';
import { FeatureAccessFlags, FeatureAccessPermissions, useFeatureAccess } from '@frontend/feature-access';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { phone as formatPhone, useAlert } from '@frontend/design-system';
import { useGetOnlineSchedulingLink } from '../queries';
import { FooterLinkType } from '../types';

const MOCK_PREFERENCE_TEST_EMAIL = 'weavetestemailpreferences@getweave.com';

export const useLinks = () => {
  const alert = useAlert();
  const { t } = useTranslation('bulk-messaging');
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0] ?? '';
  const { locationsWithEnabledFlag } = useFeatureAccess(
    FeatureAccessFlags.emailMarketing,
    FeatureAccessPermissions.emailMarketing
  );

  const { data: onlineScheduleLink = '' } = useGetOnlineSchedulingLink({ locationId });
  const {
    querySetKeys: { sets, keys },
  } = ClientSettingsApi;
  const { data: email = '' } = ClientSettingsQueries.useGetSetting(sets.email, keys.email, {
    enabled: locationsWithEnabledFlag.length > 1,
    onError: () => {
      alert.error(t('Failed to get reply-to email address.'));
    },
  });
  const { data: businessData } = BusinessInfoQueries.useLocationTcrBrand();
  const { data: locationData } = AutoRulesQueries.useGetLocationData(locationId); // this is just for the preview so it doesn't need multi support
  const { data: phone = '' } = BulkMessagesQueries.useDefaultPhoneRecord({
    locationId,
    numberType: NumberType_Enum.SMS,
  });
  const formattedPhone = formatPhone(phone, { hideCountry: true, format: 'numbers' });

  const address = [
    businessData?.tcrBrand?.street ?? 'street address',
    businessData?.tcrBrand?.city ?? 'city',
    businessData?.tcrBrand?.state ?? 'state',
    businessData?.tcrBrand?.postalCode ?? 'zip code',
  ].join(', ');

  const createString = (str?: string, appended?: boolean) =>
    str ? (appended ? '+' : '' + str?.split(' ').join('+')) : '';

  const getAddressLink = (mode?: string) => {
    if (mode === 'preview') {
      const street = createString(businessData?.tcrBrand?.street);
      const city = createString(businessData?.tcrBrand?.city, true);
      const state = createString(businessData?.tcrBrand?.state, true);
      const zip = createString(businessData?.tcrBrand?.postalCode, true);
      return `https://www.google.com/maps/place/${street}${city}${state}${zip}`;
    }

    return '{{BUSINESS_ADDRESS_LINK}}';
  };

  const { data: preferenceLinks } = EmailComposerQueries.useGenerateManagementUrls({
    locationId,
    channel: Channel.CHANNEL_EMAIL,
    userChannelAddress: MOCK_PREFERENCE_TEST_EMAIL,
    messageType: MessageType_Enum.MARKETING_BULK_MESSAGES,
  });
  const managementUrl = preferenceLinks?.managementUrl ?? '';
  const oneClickUrl = preferenceLinks?.oneClickUrl ?? '';

  const formatLink = (link: string, type?: FooterLinkType, mode?: string) => {
    if (type) {
      if (type === 'phone') {
        const onlyDigitsPhone = link.replace(/\D/g, '');
        const displayedPhone = onlyDigitsPhone ? onlyDigitsPhone : formattedPhone;
        return mode === 'preview' ? `tel:${displayedPhone}` : 'tel:{{BUSINESS_GROUP_PHONE}}';
      } else if (type === 'email') return mode === 'preview' ? `mailto:${link}` : 'mailto:{{BUSINESS_GROUP_EMAIL}}';
    }
    return link.toLowerCase().indexOf('http') !== 0 ? 'https://' + link : link;
  };

  return {
    address,
    businessName: locationData?.name ?? 'business name',
    email,
    formatLink,
    getAddressLink,
    managementUrl,
    oneClickUrl,
    onlineScheduleLink,
    phone: formattedPhone,
  };
};
