import { forwardRef, LegacyRef } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Button, ButtonProps } from '@frontend/design-system';

const styles = css`
  text-transform: none;
  background: ${theme.colors.white};
  min-width: ${theme.spacing(20)};
  width: calc(max(calc(34% - ${theme.spacing(2)}), ${theme.spacing(20)}));

  span {
    flex-direction: column;
  }
`;

export type PaymentMethodButtonProps = Omit<ButtonProps, 'variant'>;
export const PaymentMethodButton = forwardRef(
  ({ onClick, children, ...rest }: PaymentMethodButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
    return (
      <Button ref={ref} variant='secondary' css={styles} onClick={onClick} {...rest}>
        {children}
      </Button>
    );
  }
);

PaymentMethodButton.displayName = 'PaymentMethodButton';
