import { useEffect, useRef, useState } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useInView } from 'motion/react';
import { theme } from '@frontend/theme';

export type UseThreadItemHighlightProps = {
  disabled?: boolean;
  additionalCSS?: {
    default?: Interpolation<Theme>;
    whenHighlighted?: Interpolation<Theme>;
  };
  repeatWheneverVisible?: boolean;
};

export const useThreadItemHighlight = <ElementType extends Element = Element>({
  disabled,
  additionalCSS,
  repeatWheneverVisible = false,
}: UseThreadItemHighlightProps) => {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>();
  const [showHighlight, setShowHighlight] = useState(false);
  const ref = useRef<ElementType>(null);
  const isInView = useInView(ref, {
    once: !repeatWheneverVisible,
  });

  useEffect(() => {
    if (isInView) {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
      setShowHighlight(true);
      const newTimeout = setTimeout(() => {
        setShowHighlight(false);
        setTimeoutRef(undefined);
      }, 1000);
      setTimeoutRef(newTimeout);
    }
  }, [isInView]);

  const styles = disabled
    ? additionalCSS?.default
    : [
        additionalCSS?.default,
        {
          transition: 'background-color 300ms ease-in-out',
        },
        ...(showHighlight
          ? [
              {
                backgroundColor: theme.colors.warning5,
              },
              additionalCSS?.whenHighlighted,
            ]
          : []),
      ];

  return {
    ref,
    styles,
  };
};
