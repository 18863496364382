import { GlobalBannerBase, GlobalBannerBaseProps } from './global-banner-base';
import { BannerContainer } from './shared/banner-container';
import { BannerContentWrapper } from './shared/banner-content-wrapper';

export const GlobalBannerView = ({ type, ...rest }: GlobalBannerBaseProps) => {
  return (
    <BannerContainer bannerType={type}>
      <BannerContentWrapper>
        <GlobalBannerBase type={type} {...rest} />
      </BannerContentWrapper>
    </BannerContainer>
  );
};
