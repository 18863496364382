import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { Crop, Image } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';

export const convertMediaToImage = (media?: Media, crop?: Crop): Image => {
  if (!media) return { name: '', url: '' };

  return {
    name: media.fileName,
    url: media.url,
    crop,
  };
};
