import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Chart } from '@frontend/charts';
import { URLs } from '../../../../constants';
import { ARRoiConversionChart, ARRoiNoShowRateChart } from '../charts';
import { useARRoiShallowStore, useFetchARROIOOverview } from '../hooks';
import { ARRoi } from '../types';

export const ARCharts = (props: ReturnType<typeof useFetchARROIOOverview>) => {
  const navigate = useNavigate();
  const { filters } = useARRoiShallowStore('filters');
  const showCompareMulti = (filters?.locationIds?.length || 0) > 1;

  const onShowMoreClick = useCallback((showMoreView: ARRoi) => {
    navigate({
      to: URLs.ROI_APPOINTMENT_REMINDERS,
      search: { view: showMoreView },
    });
  }, []);

  return (
    <Chart.HorizontalContainer marginBottom={0}>
      <ARRoiConversionChart
        {...props}
        onShowMoreClick={() => onShowMoreClick('arConversion')}
        showCompareMulti={showCompareMulti}
      />
      <ARRoiNoShowRateChart
        {...props}
        onShowMoreClick={() => onShowMoreClick('noShowRate')}
        showCompareMulti={showCompareMulti}
      />
    </Chart.HorizontalContainer>
  );
};
