import { FaxApi } from '@frontend/api-fax';
import { shell } from '@frontend/shell-utils';

const getPDFName = ({ id, filename }: { id: string; filename: string }) => {
  const name = filename.split('/').pop();
  return name?.replace(`${id}-`, '') ?? '';
};

export const generateFaxDeliveryReport = async (faxId: string, locationId: string) => {
  return await FaxApi.getFaxDeliveryReport(faxId, locationId);
};

const generateBlob = async (
  mediaId: string,
  locationId: string,
  isFax: boolean,
  attachDeliveryConfirmation: boolean
): Promise<Blob | null> => {
  try {
    return await FaxApi.getFaxMedia(mediaId, locationId, !!isFax, attachDeliveryConfirmation);
  } catch (error) {
    console.error('Failed to download fax media:', error);
    return null;
  }
};

export const downloadPDF = async (
  mediaItem: { faxId: string; filename: string },
  locationId: string,
  isFax: boolean,
  generateDeliveryReport?: boolean
): Promise<void> => {
  const link = window.document.createElement('a');
  link.style.display = 'none';

  try {
    if (generateDeliveryReport) {
      await generateFaxDeliveryReport(mediaItem.faxId, locationId);
    }

    const blob = await generateBlob(mediaItem.faxId, locationId, isFax, generateDeliveryReport ?? false);

    if (!blob) {
      console.error('Failed to generate blob');
      throw new Error('Failed to generate blob');
    }

    link.href = window.URL.createObjectURL(blob);
    link.download = mediaItem.filename;
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  } finally {
    if (link.href) {
      window.URL.revokeObjectURL(link.href);
    }
    if (link.parentNode) {
      document.body.removeChild(link);
    }
  }
};

export const downloadDeliveryReport = async (faxId: string, locationId: string): Promise<void> => {
  const link = window.document.createElement('a');
  link.style.display = 'none';

  try {
    const { deliveryReportMediaId } = await generateFaxDeliveryReport(faxId, locationId);
    const blob = await generateBlob(deliveryReportMediaId, locationId, false, false);

    if (!blob) {
      throw new Error('Failed to generate delivery report blob');
    }

    link.href = window.URL.createObjectURL(blob);
    link.download = getPDFName({ id: deliveryReportMediaId, filename: 'FaxDeliveryReport.pdf' });
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading delivery report:', error);
    throw error;
  } finally {
    if (link.href) {
      window.URL.revokeObjectURL(link.href);
    }
    if (link.parentNode) {
      document.body.removeChild(link);
    }
  }
};

export const previewPDF = async (
  mediaItem: { faxId: string; filename: string },
  locationId: string,
  isFax: boolean,
  generateDeliveryReport?: boolean
): Promise<void> => {
  try {
    if (generateDeliveryReport) {
      await generateFaxDeliveryReport(mediaItem.faxId, locationId);
    }

    const blob = await generateBlob(mediaItem.faxId, locationId, isFax, generateDeliveryReport ?? false);

    if (!blob) {
      throw new Error('Failed to generate preview blob');
    }

    const url = window.URL.createObjectURL(blob);

    try {
      if (shell.isShell) {
        shell.emit?.('window:open', { url });
      } else {
        window.open(url, '_blank');
      }
    } finally {
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error('Error previewing PDF:', error);
    throw error;
  }
};

export const previewReport = async (faxId: string, locationId: string): Promise<void> => {
  try {
    const { deliveryReportMediaId } = await generateFaxDeliveryReport(faxId, locationId);
    const blob = await generateBlob(deliveryReportMediaId, locationId, false, false);

    if (!blob) {
      throw new Error('Failed to generate report preview blob');
    }

    const url = window.URL.createObjectURL(blob);

    try {
      if (shell.isShell) {
        shell.emit?.('window:open', { url });
      } else {
        window.open(url, '_blank');
      }
    } finally {
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error('Error previewing report:', error);
    throw error;
  }
};
