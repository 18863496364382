import { PhoneCallsService } from '@frontend/schema';
import {
  GetCallQueueMetrics,
  ListCallQueues,
  SubscribeUserToQueues,
  GetCallQueuesPerformanceAnalyticsData,
} from './types';

export const getCallQueuesList = (req: ListCallQueues['input']) => {
  return PhoneCallsService.ListCallQueues(req);
};

export const subscribeUserToQueues = (req: SubscribeUserToQueues['input']) => {
  return PhoneCallsService.SubscribeUserToQueues(req);
};

export const getCallQueueMetrics = (req: GetCallQueueMetrics['input']) => {
  return PhoneCallsService.GetCallQueueMetrics(req);
};

export const getCallQueuesPerformanceAnalyticsData = (req: GetCallQueuesPerformanceAnalyticsData['input']) => {
  return PhoneCallsService.GetCallQueuesPerformanceAnalyticsDataV2(req);
};
