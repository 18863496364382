import React from 'react';
import { css } from '@emotion/react';
import {
  PhoneHours,
  PhoneHoursScheduleRule,
  ScheduleType,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckboxField, TimeRangeField, useForm, FieldConfigs } from '@frontend/design-system';

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

type DayNames = (typeof DAYS_OF_WEEK)[number];

type PhoneHoursScheduleFields = {
  [key in `selected-${DayNames}`]: FieldConfigs['checkbox'];
} & {
  [key in `range-${DayNames}`]: FieldConfigs['timeRange'];
};

const createTimeFields = (days: readonly DayNames[], schedule?: PhoneHoursScheduleRule): PhoneHoursScheduleFields => {
  const fields = {} as PhoneHoursScheduleFields;

  days.forEach((day, i) => {
    const range = schedule?.type === ScheduleType.BREAK ? ['12:00', '13:00'] : ['9:00', '17:00'];
    const rule = schedule?.rules?.find((rule) => rule.dayOfWeek === i);
    if (rule?.startTime) {
      range[0] = rule.startTime;
    }

    if (rule?.endTime) {
      range[1] = rule.endTime;
    }

    fields[`selected-${day}`] = { type: 'checkbox', value: !!rule };
    fields[`range-${day}`] = {
      type: 'timeRange',
      interval: 60,
      value: range,
      required: true,
    };
  });

  return fields;
};

export const useScheduleForm = (params: {
  selectedSchedule: PhoneHoursScheduleRule;
  filteredSchedules: PhoneHours[];
  setBreakNameErr?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('phone');
  const { selectedSchedule, filteredSchedules, setBreakNameErr } = params;

  return useForm({
    fields: {
      phoneHoursNameField: { type: 'text', value: filteredSchedules[0].name ?? '' },
      ...createTimeFields(DAYS_OF_WEEK, selectedSchedule),
      breakName: {
        type: 'text',
        required: true,
        value: selectedSchedule?.name || '',
        validator: (value) => {
          if (!value.value) {
            setBreakNameErr?.(true);
            return t('Please add a break name to continue');
          }
          const conflictExists = filteredSchedules.find((s) => {
            return s.name?.trim().toLowerCase() === value.value.trim().toLowerCase();
          });
          if (conflictExists) {
            setBreakNameErr?.(true);
            return t('Break name {{breakName}} is already in use', { breakName: value.value });
          }
          setBreakNameErr?.(false);
          return '';
        },
      },
    },
  });
};

export const DaysAndHoursFormFields: React.FC<Pick<ReturnType<typeof useScheduleForm>, 'values' | 'getFieldProps'>> = ({
  values,
  getFieldProps,
}) => (
  <div>
    <div style={{ display: 'grid', gap: theme.spacing(2) }}>
      {DAYS_OF_WEEK.map((day) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CheckboxField
            {...getFieldProps(`selected-${day}`)}
            css={css`
              label {
                margin-bottom: 0;
                opacity: ${values[`selected-${day}`] ? '1' : '0.3'};
              }
            `}
            label={day}
            hideErrorText
          />
          <TimeRangeField
            {...getFieldProps(`range-${day}`)}
            label={day}
            interval={15}
            disabled={!values[`selected-${day}`]}
            hideErrorText
          />
        </div>
      ))}
    </div>
  </div>
);
