import { FC, useRef, useState } from 'react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import {
  Text,
  Button,
  useAlert,
  ConfirmationModal,
  useModalControl,
  Modal,
  ContentLoader,
} from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatStore } from '../../../providers';
import { StreamUser } from '../../../types';
import { getFullName } from '../../../utils';
import { AddButton, ChatAvatar } from '../../common';
import { AddMembers } from './add-members';
import { containerStyle, memberContainerStyle, buttonStyle, addMemberModalBodyStyle } from './member-section.styles';

interface MembersProps {
  channel?: Channel;
  members: StreamUser[];
}

export const Members: FC<MembersProps> = ({ channel, members }) => {
  const { t } = useTranslation('team-chat');
  const { currentUser } = useTeamChatStore(['currentUser']);
  const [selectedMembers, setSelectedMembers] = useState<StreamUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { error } = useAlert();
  const confirmationModalProps = useModalControl();
  const addMembersModalProps = useModalControl();
  const userRef = useRef<StreamUser | null>(null);
  const isDm = channel?.id?.startsWith('!members');

  const removeMember = async (user: StreamUser) => {
    try {
      channel &&
        (await channel.removeMembers([user.userID], {
          text: `${currentUser?.firstName} ${currentUser?.lastName} removed ${user.firstName} ${user.lastName}`,
        }));
    } catch (err) {
      error(t('Failed to remove member'));
    }
  };

  const onPrimaryClick = async () => {
    try {
      // FIXME: Adding this condition to avoid the error, will revisit it once we fix the issue channel undefined in all
      // other components
      if (channel && currentUser && !!selectedMembers.length) {
        setIsLoading(true);
        const text = `${getFullName(currentUser)} added ${selectedMembers
          .map((member) => getFullName(member))
          .join(', ')}`;
        await channel.addMembers(
          selectedMembers.map(({ userID }) => userID),
          { text }
        );
        setIsLoading(false);
        addMembersModalProps.closeModal();
        setSelectedMembers([]);
      }
    } catch (err) {
      setIsLoading(false);
      error(selectedMembers.length === 1 ? t('Failed to add member') : t('Failed to add members'));
    }
  };

  const onSecondaryClick = () => {
    addMembersModalProps.closeModal();
    setSelectedMembers([]);
  };

  const onClickDelete = (member: StreamUser) => {
    userRef.current = member;
    confirmationModalProps.openModal();
  };

  const onConfirmDelete = () => {
    userRef.current && removeMember(userRef.current);
  };

  return (
    <section css={containerStyle}>
      {!isDm && (
        <AddButton
          size={32}
          textSize='large'
          title='Add Members'
          onClick={addMembersModalProps.openModal}
          className='members-children'
          style={buttonStyle}
          trackingId='team-chat-2.0-add-new-members-button'
        />
      )}
      {members.map((member) => (
        <div
          css={memberContainerStyle}
          className='members-children'
          key={`channel-settings-member-list-${member.userID}`}
        >
          <ChatAvatar size='small' members={[member]} />
          <Text weight='regular' size='large'>
            {member.firstName} {member.lastName}
          </Text>
          {currentUser?.userID !== member.userID && !isDm && (
            <Button
              aria-label={'Remove Member'}
              onClick={() => onClickDelete(member)}
              css={{ marginLeft: 'auto', svg: { width: '20px !important', height: '20px !important' } }}
              iconName='trash'
              variant='secondary'
              trackingId='team-chat-2.0-remove-user-from-group'
            />
          )}
        </div>
      ))}
      <ConfirmationModal
        title={t('Remove Member')}
        onCancel={confirmationModalProps.closeModal}
        onConfirm={onConfirmDelete}
        destructive
        cancelTrackingId='team-chat-2.0-remove-user-modal-cancel-button'
        confirmTrackingId='team-chat-2.0-remove-user-modal-confirmation-button'
        {...confirmationModalProps.modalProps}
      >
        <Text>{t('Are you sure you want to remove this member?')}</Text>
      </ConfirmationModal>
      <Modal {...addMembersModalProps.modalProps} minWidth={MIN_MODAL_WIDTH}>
        <Modal.Header onClose={addMembersModalProps.closeModal}>{t('Add Members')}</Modal.Header>
        <Modal.Body css={addMemberModalBodyStyle}>
          <AddMembers
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            addedMembers={members}
            show={addMembersModalProps.modalProps.show}
          />
        </Modal.Body>
        <Modal.Actions
          primaryLabel={t('Add')}
          secondaryLabel={t('Cancel')}
          disablePrimary={selectedMembers.length === 0}
          onSecondaryClick={onSecondaryClick}
          onPrimaryClick={onPrimaryClick}
          primaryTrackingId='team-chat-2.0-add-members-modal-done-button'
          secondaryTrackingId='team-chat-2.0-add-members-modal-close-button'
        />
        <ContentLoader show={isLoading} message={t('Adding Members')} />
      </Modal>
    </section>
  );
};
