import { useCallback } from 'react';
import { InvoiceModelV3 } from '@frontend/api-invoices';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { CreateStepNavigationProps } from '../create.types';

export const CreateGenericNavigation = ({ body: Body }: CreateStepNavigationProps) => {
  const {
    person,
    setActiveInvoiceId,
    onInvoiceCreated,
    flow,
    multiStep: { goPrevStep, goNextStep, onCancel },
  } = useCollectPaymentMultiContext();

  const createGoBack = useCallback(() => {
    if (!!person) {
      return goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select });
    }

    console.warn('Multi Collect > Create Step > Go back clicked but no person found in context');
  }, [person]);

  const onCreateRequest = useCallback(
    (invoice: InvoiceModelV3) => {
      setActiveInvoiceId(invoice?.id);
      if (invoice) {
        onInvoiceCreated?.(invoice.id);
      }
      goNextStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
    },
    [onInvoiceCreated]
  );

  const onClickSecondary = useCallback(() => {
    if (flow === 'create') {
      onCancel?.();
    } else {
      createGoBack();
    }
  }, [onCancel, createGoBack]);

  return <Body onCreateRequest={onCreateRequest} onGoBack={createGoBack} onClickSecondary={onClickSecondary} />;
};
