import { css } from '@emotion/react';
import { CreditCardBrand, PaymentType } from '@frontend/api-invoices';
import { Chips } from '@frontend/chips';
import { formatDate } from '@frontend/date';
import { CardBrand } from '@frontend/payments-card-brand';
import { Avatar, Chip, TableColumnConfig } from '@frontend/design-system';
import { SearchFailedPaymentsModel } from '../../../hooks/payment-failed/types';
import {
  getFailedTransactionsOriginLabel,
  mapFailedPaymentsTypeToPaymentType,
} from '../../../hooks/payment-failed/utils';
import { GetLocationName, formatCentsToCurrency, getPaymentMethod } from '../../../utils';
import { makePersonName } from '../../../utils/failed-transactions-helpers';
import { StatusChip } from './status-chip';

export const generateColumns = (
  getLocationName: GetLocationName,
  showLocationName: boolean,
  forPrint = false
): TableColumnConfig<SearchFailedPaymentsModel>[] => [
  {
    id: 'location',
    Header: 'Location Name',
    accessor: (row) => row.locationId,
    cellRenderer: (locationId: string) =>
      forPrint ? (
        getLocationName(locationId ?? '')
      ) : (
        <Chips.LocationChip
          css={css`
            max-width: none;
          `}
        >
          {getLocationName(locationId ?? '')}
        </Chips.LocationChip>
      ),
    disableSortBy: true,
    omit: !showLocationName,
  },
  {
    id: 'customerName',
    Header: 'Customer Name',
    accessor: (row) => makePersonName(row.personFirstName, row.personLastName),
    minWidth: 150,
    maxWidth: 170,
  },
  {
    id: 'paidAmount',
    Header: 'Payment Amount',
    accessor: (row) => (row.amount ? formatCentsToCurrency(+row.amount) : ''),
    minWidth: 120,
    maxWidth: 150,
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'submittedAt',
    Header: 'Date / Time',
    maxWidth: 150,
    accessor: (row) => {
      return row.submittedAt ? formatDate(row.submittedAt, 'MMM D, YYYY h:mm a') : '';
    },
    disableResizing: true,
  },
  {
    id: 'paymentOrigin',
    Header: 'Payment Origin',
    accessor: (row) => {
      return getFailedTransactionsOriginLabel(row.origin);
    },
    minWidth: 64,
    maxWidth: 120,
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'paymentMethod',
    Header: 'Payment Method',
    accessor: (row) => row,
    cellRenderer: (row: SearchFailedPaymentsModel) => {
      const paymentType = row.paymentType;
      const cardBrand = row.cardBrand;
      const last4 = row.cardLastFour;
      let paymentMethod = getPaymentMethod(
        mapFailedPaymentsTypeToPaymentType(paymentType),
        cardBrand?.toUpperCase() as CreditCardBrand
      );
      paymentMethod = paymentMethod?.toUpperCase() as CreditCardBrand | PaymentType;
      return <CardBrand brand={paymentMethod ?? CreditCardBrand.CardBrandUnknown} last4={last4} />;
    },
    maxWidth: 180,
    disableSortBy: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (row) => row,
    cellRenderer: (row: SearchFailedPaymentsModel) => <StatusChip status={row.paymentStatus} />,
    width: 100,
    disableSortBy: true,
  },
  {
    id: 'reasonForFailure',
    Header: 'Reason for Failure',
    accessor: (row) => row.paymentStatusReason,
    disableSortBy: true,
  },
  {
    id: 'requestId',
    Header: 'Request ID',
    accessor: (row) => row.paymentId,
    disableSortBy: true,
  },
  {
    id: 'officeUser',
    Header: 'Office User',
    accessor: (row) => row.userName,
    cellRenderer: (userName: string) =>
      !!userName ? <Chip.Person avatar={<Avatar name={userName} size='xxs' />}>{userName}</Chip.Person> : '',
    disableSortBy: true,
  },
];
