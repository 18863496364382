import { DynamicFieldKey_Enum as DynamicFieldKeys } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { theme } from '@frontend/theme';
import { Button, PopoverDialog, usePopoverDialog } from '@frontend/design-system';
import { DynamicFieldTag, useDynamicFields } from '../../../../../hooks';
import { useEmailSubjectHeader } from '../email-subject-header.context';

const hiddenDynamicFields: DynamicFieldKeys[] = [
  DynamicFieldKeys.BUSINESS_GROUP_ADDRESS,
  DynamicFieldKeys.BUSINESS_GROUP_EMAIL,
  DynamicFieldKeys.ONLINE_BILL_PAY_LINK,
  DynamicFieldKeys.PREFERENCE_MANAGEMENT_LINK,
  DynamicFieldKeys.SCHEDULE_LINK,
  DynamicFieldKeys.UNKNOWN,
  DynamicFieldKeys.UNSUBSCRIBE_LINK,
];

export const DynamicFieldPickerPopover = (props: ReturnType<typeof usePopoverDialog>) => {
  const { setSubject, subject, subjectProps } = useEmailSubjectHeader();

  const { dynamicFields } = useDynamicFields('marketing');
  const visibleDynamicFields = dynamicFields.filter((field) => !hiddenDynamicFields.includes(field.key));

  const onSelect = (field: DynamicFieldTag) => {
    const newSubject = subject + '[' + field.label + ']';
    subjectProps?.onChange({ ...subjectProps, value: newSubject, name: 'subject' });
    setSubject(newSubject);
    props.close();
  };

  return (
    <PopoverDialog
      {...props.getDialogProps()}
      returnFocus={false}
      css={{
        width: 400,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      {visibleDynamicFields.map((field) => (
        <Button
          variant='secondary'
          key={field.symbol}
          size='small'
          onClick={() => onSelect(field)}
          css={{
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            height: 'auto',
            width: 'auto',
            padding: theme.spacing(0.5, 1),
          }}
        >
          {field.label}
        </Button>
      ))}
    </PopoverDialog>
  );
};
