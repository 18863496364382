import { useEffect } from 'react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { getFullName } from '../../../../utils';
import { CustomContact } from './CustomContact';
import { PersonNotesComponent } from './PersonNotesComponent';
import { PersonSearch } from './PersonSearch';
import { useManagePersonListContext } from './PersonSelectorContext';
import { PatientSelectorScreenEnum } from './types';

export const PersonListContainer = () => {
  const { personSelectorScreen, shouldRenderNotes, setPersonSelectorScreen } = useManagePersonListContext();

  useEffect(() => {
    return () => {
      setPersonSelectorScreen(PatientSelectorScreenEnum.PERSON_SEARCH);
    };
  }, []);

  return (
    <>
      {personSelectorScreen === PatientSelectorScreenEnum.PERSON_SEARCH && <PersonSearch />}
      {personSelectorScreen === PatientSelectorScreenEnum.PERSON_NOTES && shouldRenderNotes && <PersonNotes />}
      {personSelectorScreen === PatientSelectorScreenEnum.CUSTOM_CONTACT && <CustomContact />}
    </>
  );
};

const PersonNotes = () => {
  const { isLoading, selectedPerson, locationId, closePopoverDialog, onAddToListHandler, addToListButtonTrackingId } =
    useManagePersonListContext();

  const handleAddToList = (note: string) => {
    if (selectedPerson && onAddToListHandler) {
      onAddToListHandler({
        personId: selectedPerson.PersonID,
        notes: note,
        isNew: !quickFillRecipient?.personId,
      });
    }
  };

  const { data: quickFillRecipient, isLoading: isLoadingQuickFillRecipient } = ScheduleQueries.useGetQuickFillRecipient(
    { locationId, personId: selectedPerson?.PersonID ?? '' }
  );

  return (
    <PersonNotesComponent
      isLoading={isLoading || isLoadingQuickFillRecipient}
      note={quickFillRecipient?.note || ''}
      heading={getFullName(selectedPerson)}
      primaryButtonTrackingId={addToListButtonTrackingId}
      onPrimaryClick={handleAddToList}
      onSecondaryClick={closePopoverDialog}
    />
  );
};
