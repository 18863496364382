import { useCallback, useMemo } from 'react';
import { Channel } from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';
import { ValidNumberStatus } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { DepartmentsQueries } from '@frontend/api-departments';
import { CommPreferenceQueries } from '@frontend/api-messaging';
import { PersonsV3 } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { useTranslation } from '@frontend/i18n';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { MiniChatHooks } from '@frontend/mini-chat';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { SchemaCommPreference, SchemaIO, SchemaPersonV3Service } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';

type GetPersonIO = SchemaIO<(typeof SchemaPersonV3Service)['GetPersonLegacy']>;
type CheckPreferenceIO = SchemaIO<(typeof SchemaCommPreference)['CheckPreference']>;

export type ThreadContextAndOptions = {
  groupIds?: string[];
  threadGroupId?: string;
  threadId?: string;
  personId?: string;
  personPhone?: string;
  locationPhone?: string;
  departmentId?: string;
  targetSmsData?: {
    id: string;
    createdAt: string;
  };
  openInInbox?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
};

type LocationPhoneMaps = {
  locationDepartmentsMap: Record<string, DefaultSms[]>;
  locationPhonesDepartmentsMap: Record<string, { departmentId: string; locationId: string }>;
  departmentIdsPerGroupId: Record<string, string[]>;
};

type OpenThreadArgs = Compulsory<ThreadContextAndOptions, 'threadGroupId' | 'threadId'>;

type UseMessageActionResults = {
  openThread: (args: OpenThreadArgs) => Promise<void>;
  onClick: (args: ThreadContextAndOptions) => Promise<void>;
};

// TODO: completely redo this hook with the new thread status endpoints that are available

export const useMessageAction = () => {
  const { t } = useTranslation('inbox');
  const lookupLastUpdatedThread = SMSDataV3.Hooks.useLookupLastUpdatedThread();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const { getQueryKey: getSMSDataV3QueryKey } = SMSDataV3._QueryUpdaters.useQueryUpdaters();
  const { getQueryKey: getNumberValidityQueryKey } = SMSNumberV1._QueryUpdaters.useQueryUpdaters();

  const { addChat } = MiniChatHooks.useMiniChatShallowStore('addChat');
  const { navigateToThread } = useInboxNavigate();

  const formatPhone = useCallback(
    <T extends string | undefined>(phone: T): T => {
      if (phone === undefined) return undefined as T;

      return phone ? (formatPhoneNumberE164(phone) as T) : ('' as T);
    },
    [formatPhoneNumberE164]
  );

  // Queries
  const departmentsQueries = DepartmentsQueries.useListDefaultSMSQueries(
    selectedLocationIds.map((locationId) => ({
      req: {
        locationId,
      },
      options: {
        select: (data) => ({
          locationId,
          departments: data.smsNumbers ?? [],
        }),
      },
    }))
  );

  const { locationDepartmentsMap, locationPhonesDepartmentsMap, departmentIdsPerGroupId } =
    useMemo<LocationPhoneMaps>(() => {
      return departmentsQueries.reduce<LocationPhoneMaps>(
        (acc, { data }) => {
          if (data) {
            acc.locationDepartmentsMap[data.locationId] = data.departments;
            data.departments.forEach((department) => {
              if (department.smsNumber?.number && department.id) {
                const formattedNumber = formatPhone(department.smsNumber.number);
                acc.locationPhonesDepartmentsMap[formattedNumber] = {
                  departmentId: department.id,
                  locationId: data.locationId,
                };
                acc.departmentIdsPerGroupId[data.locationId] = [
                  ...(acc.departmentIdsPerGroupId[data.locationId] ?? []),
                  department.id,
                ];
              }
            });
          }
          return acc;
        },
        { locationDepartmentsMap: {}, locationPhonesDepartmentsMap: {}, departmentIdsPerGroupId: {} }
      );
    }, [departmentsQueries]);

  // Metadata fetching
  const getThreadPersonPhone = useCallback(
    async (request: SMSDataV3.Types.GetThreadIO['input']) => {
      const response = await queryClient.fetchInfiniteQuery<SMSDataV3.Types.GetThreadIO['output']>({
        queryKey: getSMSDataV3QueryKey({
          endpointName: 'GetThread',
          request,
        }),
        queryFn: () => SMSDataV3.SchemaSMSDataV3Service.GetThread(request),
      });
      const messages = response.pages.flatMap((page) => page.thread.messages);
      return formatPhone(messages.find(({ personPhone }) => !!personPhone)?.personPhone);
    },
    [queryClient.fetchInfiniteQuery, getSMSDataV3QueryKey, formatPhone, SMSDataV3.SchemaSMSDataV3Service.GetThread]
  );

  const lookupThreadId = useCallback(
    async (request: SMSDataV3.Types.LookupThreadIdIO['input']) => {
      const response = await queryClient.fetchQuery<SMSDataV3.Types.LookupThreadIdIO['output']>({
        queryKey: getSMSDataV3QueryKey({
          endpointName: 'LookupThreadId',
          request,
        }),
        queryFn: () => SMSDataV3.SchemaSMSDataV3Service.LookupThreadId(request),
      });
      return response.threadId;
    },
    [queryClient.fetchQuery, getSMSDataV3QueryKey, SMSDataV3.SchemaSMSDataV3Service.LookupThreadId]
  );

  const getPerson = useCallback(
    async (request: GetPersonIO['input']) =>
      queryClient.fetchQuery<GetPersonIO['output']>({
        queryKey: PersonsV3.PersonQueries.queryKeys.getPersonLegacy(request),
        queryFn: () => SchemaPersonV3Service.GetPersonLegacy(request),
      }),
    [queryClient.fetchQuery, PersonsV3.PersonQueries.queryKeys.getPersonLegacy, SchemaPersonV3Service.GetPersonLegacy]
  );

  const getPhoneValidities = useCallback(
    async (personPhones: string[]) => {
      const responses = await Promise.all(
        personPhones.map(async (personPhone) => {
          const request: SMSNumberV1.Types.GetValidityIO['input'] = {
            phoneNumber: formatPhone(personPhone),
          };
          return queryClient.fetchQuery<SMSNumberV1.Types.GetValidityIO['output']>({
            queryKey: getNumberValidityQueryKey({
              endpointName: 'GetValidity',
              request,
            }),
            queryFn: () => SMSNumberV1.SchemaSMSNumberV1Service.GetValidity(request),
          });
        })
      );

      return responses.map((response, index) => ({
        personPhone: formatPhone(personPhones[index]),
        isValid: response.valid !== ValidNumberStatus.VALID_NUMBER_STATUS_INVALID,
      }));
    },
    [queryClient.fetchQuery, getNumberValidityQueryKey, formatPhone, SMSNumberV1.SchemaSMSNumberV1Service.GetValidity]
  );

  const getPhoneOptOutStatuses = useCallback(
    async ({ personPhones, groupIds }: { personPhones: string[]; groupIds?: string[] }) => {
      const resolvedGroupIds = groupIds ?? selectedLocationIds;
      const groupToPersonPhoneCombinations = resolvedGroupIds.reduce<{ personPhone: string; groupId: string }[]>(
        (acc, curr) => {
          personPhones.forEach((personPhone) => {
            acc.push({ groupId: curr, personPhone });
          });
          return acc;
        },
        []
      );

      const responses = await Promise.all(
        groupToPersonPhoneCombinations.map(async ({ personPhone, groupId }) => {
          const request = {
            locationId: groupId,
            channel: Channel.CHANNEL_SMS,
            userChannelAddress: formatPhone(personPhone),
            messageType: MessageType_Enum.MESSAGING_MANUAL,
          };

          const response = await queryClient.fetchQuery<CheckPreferenceIO['output']>({
            queryKey: CommPreferenceQueries.queryKeys.checkPreference(request),
            queryFn: () => SchemaCommPreference.CheckPreference(request),
          });

          return {
            personPhone: request.userChannelAddress,
            groupId,
            isOptedOut: response.consented === false,
          };
        })
      );

      return responses;
    },
    [JSON.stringify(selectedLocationIds)]
  );

  const getPersonLastUpdatedThreadId = useCallback(
    async ({
      personId,
      groupIds,
      departmentId,
    }: {
      personId: string;
      groupIds?: string[];
      departmentId?: string;
    }): Promise<{ threadId: string; groupId: string; departmentId: string; personPhone: string } | undefined> => {
      const person = await getPerson({
        personId,
        locationIds: groupIds ?? selectedLocationIds,
      });
      const personPhones =
        person.contactInfo?.reduce<string[]>((acc, curr) => {
          if (
            curr.type === ContactType_Enum.EMAIL ||
            (curr.type === ContactType_Enum.UNSPECIFIED && curr.destination && /[a-zA-Z]/.test(curr.destination))
          ) {
            return acc;
          } else {
            const formattedPhone = formatPhone(curr.destination);
            if (formattedPhone) acc.push(formattedPhone);
          }
          return acc;
        }, []) ?? [];

      if (!personPhones.length) {
        alert.error(t('This contact has no phone numbers.'));
        console.error('Fetched person has no phone numbers', { person });
        return;
      }

      let phoneValidities: Awaited<ReturnType<typeof getPhoneValidities>> | undefined;

      try {
        phoneValidities = await getPhoneValidities(personPhones);
      } catch (e) {
        console.error('Error looking up phone validity for person', e);
      }
      const validPhones =
        phoneValidities?.reduce<string[]>((acc, curr) => {
          if (curr.isValid) {
            acc.push(curr.personPhone);
          }
          return acc;
        }, []) ?? personPhones;
      if (!validPhones.length) {
        alert.error(t('Selected person has no textable phone numbers.'));
        return;
      }

      try {
        const lastUpdatedThread = await lookupLastUpdatedThread(
          {
            personPhones: validPhones,
            departmentIdsPerGroupId,
            groupIds: groupIds ?? selectedLocationIds,
          },
          { departmentId }
        );

        if (!lastUpdatedThread) {
          const mobilePhone = formatPhone(
            person.contactInfo?.find((contact) => contact.type === ContactType_Enum.PHONE_MOBILE)?.destination
          );
          const mobilePhoneIsValid = mobilePhone && validPhones.includes(mobilePhone);
          const locationId = groupIds?.[0] || selectedLocationIds[0];
          const departmentId =
            locationDepartmentsMap[locationId].find((department) => !!department.mainLine)?.id ||
            locationDepartmentsMap[locationId]?.[0]?.id;
          const newThreadId = await lookupThreadId({
            personPhone: mobilePhoneIsValid ? mobilePhone : formatPhone(personPhones[0]),
            departmentId,
            locationId,
            calculateMissing: true,
          });
          return {
            threadId: newThreadId,
            groupId: locationId,
            departmentId: departmentId ?? '',
            personPhone: mobilePhoneIsValid ? mobilePhone : formatPhone(personPhones[0]),
          };
        }

        return {
          threadId: lastUpdatedThread.threadId,
          groupId: lastUpdatedThread.locationId,
          departmentId: lastUpdatedThread.departmentId,
          personPhone: lastUpdatedThread.personPhone,
        };
      } catch (e) {
        alert.error(t('Error looking up conversations with selected contact.'));
        console.error("Error listing threads for given person's phone numbers", e);
        return;
      }
    },
    [
      JSON.stringify(selectedLocationIds),
      getPerson,
      formatPhone,
      alert.error,
      getPhoneValidities,
      lookupLastUpdatedThread,
      dayjs,
    ]
  );

  const getPersonPhoneLastUpdatedThread = useCallback(
    async ({
      personPhone,
      groupIds,
      departmentId,
    }: {
      personPhone: string;
      groupIds?: string[];
      departmentId?: string;
    }): Promise<{ threadId: string; groupId: string; departmentId: string; personPhone: string } | undefined> => {
      const optedOutStatuses = await getPhoneOptOutStatuses({
        personPhones: [personPhone],
        groupIds: groupIds ?? selectedLocationIds,
      });

      const filteredGroupIds = (groupIds ?? selectedLocationIds).filter(
        (locationId) => !optedOutStatuses.find(({ groupId }) => groupId === locationId)?.isOptedOut
      );

      if (!filteredGroupIds.length) {
        alert.error(t('The selected contact has opted out of text messaging.'));
      }

      const lastUpdatedThread = await lookupLastUpdatedThread(
        {
          personPhones: [personPhone],
          groupIds: filteredGroupIds,
          departmentIdsPerGroupId,
        },
        { departmentId }
      );

      if (lastUpdatedThread) {
        return {
          threadId: lastUpdatedThread.threadId,
          groupId: lastUpdatedThread.locationId,
          departmentId: lastUpdatedThread.departmentId,
          personPhone: lastUpdatedThread.personPhone,
        };
      }

      const locationIdForCalculation = filteredGroupIds[0];
      const departmentIdForCalculation = locationDepartmentsMap[locationIdForCalculation]?.[0]?.id;
      const calculatedThreadId = await lookupThreadId({
        personPhone: formatPhone(personPhone),
        locationId: locationIdForCalculation,
        departmentId: departmentIdForCalculation,
        calculateMissing: true,
      });

      return {
        threadId: calculatedThreadId,
        groupId: locationIdForCalculation,
        departmentId: departmentIdForCalculation ?? '',
        personPhone: formatPhone(personPhone),
      };
    },
    [
      getPhoneOptOutStatuses,
      JSON.stringify(selectedLocationIds),
      JSON.stringify(locationDepartmentsMap),
      formatPhone,
      lookupThreadId,
    ]
  );

  const handleInboxOrChatOpening = useCallback(
    ({
      threadGroupId,
      threadId,
      personId,
      personPhone,
      locationPhone,
      departmentId,
      targetSmsData,
      openInInbox,
    }: Compulsory<ThreadContextAndOptions, 'threadGroupId' | 'threadId' | 'personPhone'>) => {
      if (openInInbox) {
        return navigateToThread({
          groupId: threadGroupId,
          threadId,
          personId,
          personPhone,
          departmentId,
          smsId: targetSmsData?.id,
          smsCreatedAt: targetSmsData?.createdAt,
        });
      }

      return addChat({
        groupId: threadGroupId,
        threadId,
        personId,
        personPhone,
        locationPhone,
        departmentId,
        targetSmsData,
        unreadCount: 0,
      });
    },
    [navigateToThread, addChat]
  );

  const openThread = useCallback<UseMessageActionResults['openThread']>(
    async ({
      threadGroupId = selectedLocationIds.length === 1 ? selectedLocationIds[0] : '', // Default to only selected location
      threadId,
      personId,
      personPhone,
      locationPhone,
      departmentId,
      targetSmsData,
      openInInbox,
      onSuccess,
      onError,
    }) => {
      if (!threadGroupId || !threadId) {
        alert.error(t('Error opening thread.'));
        console.error(
          'Not enough context provided to open thread. Missing data: ' +
            (threadGroupId ? '' : '\n- threadGroupId') +
            (threadId ? '' : '\n- threadId')
        );
        onError?.();
        return;
      }

      const locationDepartments = locationDepartmentsMap[threadGroupId];
      const resolvedDepartmentId =
        departmentId ||
        (locationDepartments.length === 1
          ? locationDepartments[0]?.id
          : locationPhone
          ? locationDepartments.find(
              (department) =>
                department.smsNumber?.number && formatPhone(department.smsNumber?.number) === formatPhone(locationPhone)
            )?.id
          : undefined);

      try {
        // Get required data if not provided
        const resolvedPersonPhone =
          formatPhone(personPhone) ||
          (await getThreadPersonPhone({
            threadId,
            locationId: threadGroupId,
            includeDeleted: true,
          }));

        if (!resolvedPersonPhone) {
          alert.error(t('Error opening thread.'));
          console.error(
            'Not enough context provided to open thread. Missing data: personPhone' +
              (personPhone ? '' : ' (attempted to fetch personPhone from provided thread)')
          );
          onError?.();
          return;
        }

        const resolvedLocationPhone =
          locationPhone ?? locationDepartments.find((dept) => dept.id === resolvedDepartmentId)?.smsNumber?.number;

        handleInboxOrChatOpening({
          threadGroupId,
          personPhone: formatPhone(resolvedPersonPhone),
          departmentId: resolvedDepartmentId,
          personId,
          threadId,
          targetSmsData,
          locationPhone: formatPhone(resolvedLocationPhone),
          openInInbox,
        });
        onSuccess?.();
      } catch (e) {
        alert.error(t('Error looking up thread.'));
        console.error("Error looking up thread personPhone since it wasn't provided.", e);
        onError?.();
        return;
      }
    },
    [
      JSON.stringify(selectedLocationIds),
      handleInboxOrChatOpening,
      formatPhone,
      JSON.stringify(locationDepartmentsMap),
      alert.error,
      t,
      getThreadPersonPhone,
    ]
  );

  const onClick = useCallback<UseMessageActionResults['onClick']>(
    async ({
      groupIds,
      threadId,
      threadGroupId = selectedLocationIds.length === 1 ? selectedLocationIds[0] : undefined,
      personId,
      personPhone,
      locationPhone,
      departmentId,
      targetSmsData,
      openInInbox,
      onSuccess,
      onError,
    }) => {
      // Open specific thread if specified with threadId
      if (threadId && threadGroupId) {
        return openThread({
          threadId,
          threadGroupId,
          personId,
          personPhone,
          locationPhone,
          departmentId,
          targetSmsData,
          openInInbox,
          onSuccess,
          onError,
        });
      }

      // Open specific thread if specified with phone numbers
      const hasBothPhones = !!personPhone && (!!departmentId || !!locationPhone);
      if (hasBothPhones) {
        const mappedLocationPhoneData = locationPhone
          ? locationPhonesDepartmentsMap[formatPhone(locationPhone)]
          : undefined;
        const resolvedDepartmentId = departmentId || mappedLocationPhoneData?.departmentId;
        const resolvedGroupId = threadGroupId || mappedLocationPhoneData?.locationId;

        if (!resolvedGroupId) {
          alert.error(t('Error looking up thread.'));
          console.error(
            'Error looking up thread from phone numbers. Could not find location with provided outbound phone number/department'
          );
          onError?.();
        } else {
          try {
            const resolvedThreadId =
              threadId ||
              (await lookupThreadId({
                personPhone: formatPhone(personPhone),
                departmentId: resolvedDepartmentId,
                locationId: resolvedGroupId ?? '',
                calculateMissing: true,
              }));
            openThread({
              threadId: resolvedThreadId,
              threadGroupId: resolvedGroupId,
              personId,
              personPhone: formatPhone(personPhone),
              locationPhone: formatPhone(locationPhone),
              departmentId: resolvedDepartmentId,
              targetSmsData,
              openInInbox,
              onSuccess,
              onError,
            });
          } catch (e) {
            alert.error(t('Error looking up thread.'));
            console.error('Error looking up threadId from provided data.', e);
            onError?.();
          }
        }
      }

      // Open last updated thread if person or personPhone specified
      if (personId || personPhone) {
        const mappedLocationPhoneData = locationPhone
          ? locationPhonesDepartmentsMap[formatPhone(locationPhone)]
          : undefined;
        const resolvedDepartmentId = departmentId || mappedLocationPhoneData?.departmentId;
        const resolvedGroupId = threadGroupId || mappedLocationPhoneData?.locationId;
        let lastUpdatedThread:
          | { threadId: string; groupId: string; departmentId: string; personId?: string; personPhone: string }
          | undefined;
        if (personId) {
          try {
            const thread = await getPersonLastUpdatedThreadId({
              personId,
              groupIds: resolvedGroupId ? [resolvedGroupId] : groupIds ?? selectedLocationIds,
              departmentId: resolvedDepartmentId,
            });
            if (thread) lastUpdatedThread = { ...thread, personId };
          } catch (e) {
            console.error('Error fetching provided person', e);
          }
        }
        if (!lastUpdatedThread && !!personPhone) {
          lastUpdatedThread = await getPersonPhoneLastUpdatedThread({
            personPhone,
            groupIds: resolvedGroupId ? [resolvedGroupId] : groupIds ?? selectedLocationIds,
            departmentId: resolvedDepartmentId,
          });
        }

        if (!lastUpdatedThread) {
          onError?.();
          return;
        }

        return openThread({
          threadId: lastUpdatedThread.threadId,
          threadGroupId: lastUpdatedThread.groupId,
          personId: lastUpdatedThread.personId,
          personPhone: formatPhone(personPhone) || lastUpdatedThread.personPhone,
          locationPhone: formatPhone(locationPhone),
          departmentId: lastUpdatedThread.departmentId,
          targetSmsData,
          openInInbox,
          onSuccess,
          onError,
        });
      }

      alert.error(t('There was a problem opening the selected conversation.'));
      console.error('Error opening conversation. Not enough context provided.');
      onError?.();
    },
    [
      openThread,
      JSON.stringify(locationPhonesDepartmentsMap),
      formatPhone,
      alert.error,
      lookupThreadId,
      getPersonLastUpdatedThreadId,
    ]
  );

  return {
    openThread,
    onClick,
  };
};
