import { useState } from 'react';
import { Device } from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup.pb';
import {
  CreateResponse,
  ListResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup_service.pb';
import { DeviceType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/sip-profiles/v1/sip_profiles.pb';
import { useQueryClient } from 'react-query';
import { CallGroupApi, CallGroupTypes } from '@frontend/api-call-group';
import { ForwardingNumberTypes } from '@frontend/api-forwarding-number';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { WeaveLocation, WeaveLocationGroup } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { BackIcon, Heading, Modal, Stepper, TextField, useAlert, useFormField } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { trackingId } from '../../../tracking';
import { AssignDeviceContent } from '../../common/assign-device-content';
import { LabelsCard } from '../../common/labels-card';
import { SettingsCard } from '../../common/settings-card';
import { NewRingtoneSelector } from '../ringtone-selector.new';

export const CreateCallGroupModal = ({
  tenantLocation,
  devices,
  callForwardingNumbers,
  show,
  onClose,
  onSuccess,
}: {
  tenantLocation: WeaveLocationGroup;
  devices: CallGroupTypes.SipProfileType[];
  callForwardingNumbers: ForwardingNumberTypes.FwdNumberModel[];
  show: boolean;
  onClose: () => void;
  onSuccess?: (data: CreateResponse) => void;
}) => {
  const { t } = useTranslation('phone');
  const isUnifyLocation = tenantLocation.locationType === 'unify';
  const alert = useAlert();
  const queryClient = useQueryClient();
  const tenantId: string = tenantLocation?.phoneTenantId ?? '';
  const defaultTimeout = 18000;

  const [callGroup, setCallGroup] = useState<CreateResponse>(() => ({
    callGroupId: '',
    tenantId: '',
    locationIds: [],
    name: '',
    ringtone: '',
    devices: [],
  }));
  const { callGroupId, ...payload } = callGroup;
  const textFieldProps = useFormField({ type: 'text', value: callGroup.name }, [callGroup.name]);
  const { navigate } = useSettingsNavigate();

  const stepTitleText: { [key: number]: string } = {
    1: t('Call Group Name'),
    2: t('Assign Devices'),
    3: t('Ringtone'),
  };

  if (isUnifyLocation) {
    stepTitleText[4] = t('Locations');
  }

  const { mutate: createCallGroup } = useMutation(
    (payload: CallGroupTypes.CreateCallGroupRequest) => {
      return CallGroupApi.createCallGroup(payload);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(queryKeys.callGroup(callGroup.callGroupId ?? ''), data);
        queryClient.setQueryData<ListResponse>(queryKeys.listCallGroups(tenantLocation?.phoneTenantId ?? ''), (old) => {
          const updated = {
            callGroups: [
              ...(old?.callGroups ?? []),
              // Add the new call group to the list.
              // NOTE: the data returned from the API in the mutation response is not the same as the data in the list
              // and needs to be transformed.
              {
                devicesCount: data.devices.length,
                id: data.callGroupId,
                locationIds: data.locationIds,
                name: data.name,
                tenantId: data.tenantId,
              },
            ],
          };

          return updated;
        });
        alert.success({
          message: t('Call Group created successfully.'),
          action: {
            label: t('Go to Call Group'),
            onClick: () => navigate({ to: '/phone/call-groups/:id', params: { id: data.callGroupId } }),
          },
        });
        onSuccess?.(data);
      },
      onError: () => {
        alert.error(t('System failed to create Call Group. Please try again.'));
      },
    }
  );

  const locationsNames =
    tenantLocation?.children?.reduce((acc: Record<string, string>, item: Partial<WeaveLocation>) => {
      if (item.locationId && item.name) {
        acc[item.locationId] = item.name;
      }
      return acc;
    }, {}) ?? {};

  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [selectedMobiles, setSelectedMobiles] = useState<string[]>([]);
  const [selectedForwardingNumbers, setSelectedForwardingNumbers] = useState<string[]>([]);

  const forwardingNumbers = selectedForwardingNumbers
    .map((id) => {
      return {
        callLegId: '',
        callGroupId: '',
        delayStart: 0,
        timeout: defaultTimeout,
        forwardingNumber: {
          forwardingNumberId: id,
        },
      };
    })
    .filter(Boolean);

  const deskPhones = selectedDevices
    .map((profileID) => {
      const deviceData = devices?.find((device) => device.sipProfileId === profileID);
      if (!deviceData) {
        return null;
      }

      const { device } = deviceData;
      const phoneType = device?.deviceType;

      if (phoneType === DeviceType_Enum.DESK_PHONE) {
        return {
          callLegId: '',
          callGroupId: '',
          delayStart: 0,
          timeout: defaultTimeout,
          deskPhone: {
            sipProfileId: profileID,
          },
        };
      } else if (phoneType === DeviceType_Enum.SOFTPHONE) {
        return {
          callLegId: '',
          callGroupId: '',
          delayStart: 0,
          timeout: defaultTimeout,
          softPhone: {
            sipProfileId: profileID,
          },
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const mobilePhones = selectedMobiles
    .map((profileID) => {
      const deviceData = devices?.find((device) => device.sipProfileId === profileID);
      if (!deviceData) {
        return null;
      }

      const { device } = deviceData;
      const phoneType = device?.deviceType;

      if (phoneType === DeviceType_Enum.MOBILE_APP) {
        return {
          callLegId: '',
          callGroupId: '',
          delayStart: 0,
          timeout: defaultTimeout,
          mobilePhone: {
            sipProfileId: profileID,
          },
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const combinedDevices = [...deskPhones, ...mobilePhones, ...forwardingNumbers] as Device[];

  const handleClose = () => {
    setSelectedDevices([]);
    setSelectedMobiles([]);
    setSelectedForwardingNumbers([]);
    setCallGroup({
      callGroupId: '',
      tenantId: '',
      locationIds: [],
      name: '',
      ringtone: '',
      devices: [],
    });
    onClose();
  };

  return (
    <Modal show={show} onClose={handleClose} minWidth={900}>
      <Modal.Header onClose={handleClose}></Modal.Header>
      <Modal.Body>
        <Stepper stepTitleText={stepTitleText} isVerticalSingleView maxWidth={900}>
          {/* Call Group Name */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Call Group')}</Stepper.Title>
            <Stepper.Content css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
              <Heading level={3}>{t('Call Group Name')}</Heading>
              <TextField
                {...textFieldProps}
                onChange={(e) => {
                  textFieldProps.onChange(e);
                  setCallGroup({
                    ...callGroup,
                    // @ts-expect-error - event types are not correct
                    name: e.target.value,
                    tenantId: tenantId,
                  });
                }}
                label={t('Call Group Name')}
                name='name'
              ></TextField>
            </Stepper.Content>
            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate
                type='next'
                position='primary'
                disabled={!textFieldProps.value}
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-groups',
                  details: 'continue-createModal',
                })}
              >
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate
                type='none'
                position='secondary'
                onClick={handleClose}
                trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'cancel-createModal' })}
              >
                {t('Cancel')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Assign Devices */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Call Group')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <SettingsCard title={t('Assign Devices')}>
                  <AssignDeviceContent
                    callForwardingNumbers={callForwardingNumbers}
                    callGroup={callGroup}
                    devices={devices}
                    locationsNames={locationsNames}
                    ringtone={callGroup.ringtone}
                    setSelectedDevices={setSelectedDevices}
                    setSelectedMobiles={setSelectedMobiles}
                    setSelectedForwardingNumbers={setSelectedForwardingNumbers}
                    subtitle={t('Select the devices that will be assigned to this call group.')}
                    isMultiLocation={tenantLocation?.locationType !== 'single'}
                    setCallGroup={setCallGroup}
                  />
                </SettingsCard>
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate
              helperText={
                combinedDevices.length > 0
                  ? t('{{count}} devices selected', { count: combinedDevices.length })
                  : undefined
              }
            >
              <Stepper.ButtonAlternate
                type='next'
                position='primary'
                onClick={() => setCallGroup({ ...callGroup, devices: combinedDevices })}
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-groups',
                  details: 'continue-createModal',
                })}
              >
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate
                type='none'
                position='secondary'
                onClick={handleClose}
                trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'cancel-createModal' })}
              >
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate
                type='previous'
                position='tertiary'
                trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'back-createModal' })}
              >
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Ringtone */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Call Group')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <NewRingtoneSelector callGroup={callGroup} setCallGroup={setCallGroup} />
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate>
              {isUnifyLocation ? (
                <>
                  <Stepper.ButtonAlternate
                    type='next'
                    position='primary'
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-groups',
                      details: 'continue-createModal',
                    })}
                  >
                    {t('Continue')}
                  </Stepper.ButtonAlternate>
                  <Stepper.ButtonAlternate
                    type='none'
                    position='secondary'
                    onClick={handleClose}
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-groups',
                      details: 'cancel-createModal',
                    })}
                  >
                    {t('Cancel')}
                  </Stepper.ButtonAlternate>
                  <Stepper.ButtonAlternate
                    type='previous'
                    position='tertiary'
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-groups',
                      details: 'back-createModal',
                    })}
                  >
                    <BackIcon />
                    {t('Back')}
                  </Stepper.ButtonAlternate>
                </>
              ) : (
                <>
                  <Stepper.ButtonAlternate
                    type='none'
                    position='primary'
                    onClick={() => {
                      createCallGroup(payload);
                      handleClose();
                    }}
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-groups',
                      details: 'done-createModal',
                    })}
                  >
                    {t('Save')}
                  </Stepper.ButtonAlternate>
                  <Stepper.ButtonAlternate
                    type='none'
                    position='secondary'
                    onClick={handleClose}
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-groups',
                      details: 'cancel-createModal',
                    })}
                  >
                    {t('Cancel')}
                  </Stepper.ButtonAlternate>
                  <Stepper.ButtonAlternate
                    type='previous'
                    position='tertiary'
                    trackingId={trackingId({
                      context: 'setting',
                      feature: 'call-groups',
                      details: 'back-createModal',
                    })}
                  >
                    <BackIcon />
                    {t('Back')}
                  </Stepper.ButtonAlternate>
                </>
              )}
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Locations Work in progress. Using MultiselectField for now but will need to be changed.*/}
          {isUnifyLocation && (
            <Stepper.Card>
              <Stepper.Title>{t('Create Call Group')}</Stepper.Title>

              <Stepper.Content>
                <div
                  css={{
                    '> div': {
                      boxShadow: 'none',
                      padding: 0,
                    },
                  }}
                >
                  {callGroup.locationIds && <LabelsCard callGroup={callGroup} setCallGroup={setCallGroup} />}
                </div>
              </Stepper.Content>

              <Stepper.ButtonBarAlternate>
                <Stepper.ButtonAlternate
                  type='none'
                  position='primary'
                  onClick={() => {
                    createCallGroup(payload);
                    handleClose();
                  }}
                  trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'done-createModal' })}
                >
                  {t('Save')}
                </Stepper.ButtonAlternate>
                <Stepper.ButtonAlternate
                  type='none'
                  position='secondary'
                  onClick={handleClose}
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-groups',
                    details: 'cancel-createModal',
                  })}
                >
                  {t('Cancel')}
                </Stepper.ButtonAlternate>
                <Stepper.ButtonAlternate
                  type='previous'
                  position='tertiary'
                  trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'back-createModal' })}
                >
                  <BackIcon />
                  {t('Back')}
                </Stepper.ButtonAlternate>
              </Stepper.ButtonBarAlternate>
            </Stepper.Card>
          )}
        </Stepper>
      </Modal.Body>
    </Modal>
  );
};
