import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { Draft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useDeleteDraftMutation, useGetDraftQuery, useUpsertDraftMutation } from '@frontend/api-sms-draft';
import { SMSSendV3 } from '@frontend/api-sms-send';
import { SMSSignatureV1 } from '@frontend/api-sms-signature';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { SchemaSMSNotifierService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { genUUIDV4 } from '@frontend/string';
import { useDebouncedValue } from '@frontend/timer';
import { sentry } from '@frontend/tracking';
import { Prettify } from '@frontend/types';
import { useAlert, useForm, usePopoverMenu, UsePopoverMenuResponse, usePreviousValue } from '@frontend/design-system';
import { ThreadSendingAreaState } from '../components';
import { SendingAreaFormConfig, SendingAreaFormKey } from '../types';
import { getMessageTypeFromRelatedIds } from '../utils';
import { useThreadMedia } from './use-thread-media';

type RelatedIDWithRequirement = RelatedID & { requiredString?: string };
type UseSendMutationOptions = NonNullable<
  NonNullable<Parameters<typeof SMSSendV3.Mutations.useSendMutation>[0]>['options']
>;
type UseScheduleMutationOptions = NonNullable<
  NonNullable<Parameters<typeof ManualSmsScheduledV1.Mutations.useScheduleMutation>[0]>['options']
>;
type MutationSideEffects<
  T extends {
    onSuccess?: any;
    onError?: any;
    onSettled?: any;
    onMutate?: any;
  }
> = Prettify<Partial<Pick<T, 'onSuccess' | 'onError' | 'onSettled' | 'onMutate'>>>;
export type UseThreadSendingAreaArgs = {
  threadId: string;
  groupId: string;
  personPhone: string;
  primaryContactId?: string;
  contextContactId?: string;
  outboundPhone?: {
    departmentId: string;
    locationPhone?: string;
  };
  initialRelatedIds?: RelatedIDWithRequirement[];
  sendSideEffects?: MutationSideEffects<UseSendMutationOptions>;
  scheduleSideEffects?: MutationSideEffects<UseScheduleMutationOptions>;
};

export const useThreadSendingArea = ({
  threadId,
  groupId,
  personPhone,
  primaryContactId,
  contextContactId,
  outboundPhone,
  initialRelatedIds,
  sendSideEffects,
  scheduleSideEffects,
}: UseThreadSendingAreaArgs): ThreadSendingAreaState & {
  focusTextarea: () => void;
  insertText: (text: string) => void;
  templatePopoverState: UsePopoverMenuResponse<HTMLElement>;
  setBody: (text: string) => void;
  addRelatedIds: (relatedIds: RelatedIDWithRequirement[], removePrevious?: boolean) => void;
} => {
  const { t } = useTranslation('thread-sending-area');
  const { selectedOrgId } = useAppScopeStore();
  const user = getUser();
  const alert = useAlert();
  const [relatedIds, setRelatedIds] = useState(initialRelatedIds ?? []);
  const [scheduledRelatedIds, setScheduledRelatedIds] = useState<RelatedIDWithRequirement[]>([]);

  const { data: draft, isLoading: draftIsLoading } = useGetDraftQuery(
    {
      threadId,
      locationId: groupId,
      userId: user?.userID ?? '',
      orgId: selectedOrgId,
    },
    {
      enabled: !!user?.userID,
      select: (data) => data.draft,
    }
  );
  const upsertDraftMutation = useUpsertDraftMutation();
  const deleteDraftMutation = useDeleteDraftMutation();
  const [currentIsTyping, setCurrentIsTyping] = useState(false);
  const indicateTyping = useCallback(
    async (isTyping: boolean, providedThreadId?: string) => {
      if (user?.userID && currentIsTyping !== isTyping) {
        try {
          await SchemaSMSNotifierService.IndicateTyping({
            groupId,
            threadId: providedThreadId || threadId,
            userId: user?.userID,
            isTyping,
            personPhone,
          });
          setCurrentIsTyping(isTyping);
        } catch (err) {
          sentry.warn({
            topic: 'messages',
            error: 'Failed to indicate typing',
            addContext: {
              name: 'IndicateTyping request',
              context: {
                groupId,
                threadId: providedThreadId || threadId,
                userId: user?.userID,
                isTyping,
                personPhone,
              },
            },
          });
        }
      }
    },
    [
      personPhone,
      user?.userID,
      groupId,
      threadId,
      SchemaSMSNotifierService.IndicateTyping,
      currentIsTyping,
      setCurrentIsTyping,
      sentry.warn,
    ]
  );

  const { data: signature, isFetched: signatureIsFetched } = SMSSignatureV1.Queries.useGetSignatureQuery({
    request: {
      groupId,
      userId: user?.userID ?? '',
    },
    options: {
      enabled: !!user?.userID,
      select: (data) => data.signature.signature,
    },
  });
  const upsertSignatureMutation = SMSSignatureV1.Mutations.useUpsertSignatureMutation({
    options: {
      onSuccess: (res) => {
        alert.success({
          message: t('Signature updated successfully'),
        });
        seedValues({
          signature: res.signature.signature,
        });
      },
    },
  });

  const sendMutation = SMSSendV3.Mutations.useSendMutation({
    optimisticUpdate: true,
    options: {
      ...sendSideEffects,
    },
  });
  const scheduledMessagesQuery = ManualSmsScheduledV1.Queries.useGetThreadQuery({
    request: {
      threadId,
      locationId: groupId,
    },
    options: {
      select: (data) => ({
        ...data,
        scheduledSmss: data.scheduledSmss.filter(
          ({ status }) => status === OutboundMessageStatus.SCHEDULED || status === OutboundMessageStatus.PAUSED
        ),
      }),
    },
  });
  const scheduleMutation = ManualSmsScheduledV1.Mutations.useScheduleMutation({
    optimisticUpdate: true,
    options: {
      ...scheduleSideEffects,
    },
  });
  const deleteScheduledMessageMutation = ManualSmsScheduledV1.Mutations.useDeleteMutation();
  const [scheduledMessageBeingEdited, setScheduledMessageBeingEdited] = useState<ScheduledSms>();
  const draftMediaIds =
    draft?.medias.reduce<string[]>((acc, { mediaId }) => {
      if (mediaId) acc.push(mediaId);
      return acc;
    }, []) ?? [];
  const draftMediaState = useThreadMedia({
    key: threadId,
    groupId,
    mediaIds: draftMediaIds,
    setMediaIds: (mediaIds) => {
      if (!draft?.body && !mediaIds.length) {
        deleteDraft();
      } else {
        upsertDraftMutation.mutate({
          draft: {
            body: '',
            relatedIds: [],
            ...draft,
            medias: mediaIds.map((mediaId) => ({ mediaId })),
          },
          locationId: groupId,
          userId: user?.userID ?? '',
          threadId,
          orgId: selectedOrgId,
          personPhone: personPhone ? formatPhoneNumberE164(personPhone) : undefined,
        });
      }
    },
  });
  const addRelatedIds = useCallback(
    (newRelatedIds: RelatedIDWithRequirement[], removePrevious = false) => {
      const setter = scheduledMessageBeingEdited ? setScheduledRelatedIds : setRelatedIds;
      setter((current) => {
        const relatedIdsToKeep = removePrevious
          ? []
          : current.filter(
              (relatedId) =>
                !newRelatedIds.some(
                  (newRelatedId) => newRelatedId.id === relatedId.id && newRelatedId.type === relatedId.type
                )
            );
        return [...relatedIdsToKeep, ...newRelatedIds];
      });
    },
    [scheduledMessageBeingEdited, setRelatedIds, setScheduledRelatedIds]
  );
  const [scheduledMessageMediaIds, setScheduledMessageMediaIds] = useState(scheduledMessageBeingEdited?.mediaIds ?? []);
  const scheduledMessageMediaState = useThreadMedia({
    key: scheduledMessageBeingEdited?.id,
    groupId,
    mediaIds: scheduledMessageMediaIds,
    setMediaIds: (newIds) => setScheduledMessageMediaIds(newIds),
  });
  const editScheduledMessageForm = useForm({
    fields: {
      body: {
        type: 'text',
        value: scheduledMessageBeingEdited?.body ?? '',
      },
    },
    onSubmit: () => {
      if (!scheduledMessageBeingEdited) return;
      onReschedule?.({
        sendAt: scheduledMessageBeingEdited.sendAt,
        pausable: !!scheduledMessageBeingEdited.pausable,
        scheduledSms: scheduledMessageBeingEdited,
      });
    },
  });
  const onSelectScheduledMessage = useCallback(
    (messageId?: string) => {
      if (!messageId) {
        setScheduledMessageBeingEdited(undefined);
        setScheduledMessageMediaIds([]);
        setScheduledRelatedIds([]);
        return;
      }
      const selectedMessage = scheduledMessagesQuery.data?.scheduledSmss.find(({ id }) => id === messageId);
      setScheduledMessageBeingEdited(selectedMessage);
      setScheduledMessageMediaIds(selectedMessage?.mediaIds ?? []);
      // Filter out the pre-generated scheduled message id since it will be regenerated if the message is edited
      setScheduledRelatedIds(
        selectedMessage?.relatedIds.filter(({ type }) => type !== RelatedType.RELATED_TYPE_SCHEDULED_MESSAGE_ID) ?? []
      );

      editScheduledMessageForm.seedValues({
        body: selectedMessage?.body,
      });
    },
    [
      JSON.stringify(scheduledMessagesQuery.data?.scheduledSmss),
      setScheduledMessageBeingEdited,
      editScheduledMessageForm.seedValues,
      setScheduledRelatedIds,
    ]
  );

  const formatMessageBodyWithSignature = useCallback(
    ({ body, signature, mediaCount }: { body: string; signature: string; mediaCount: number }) => {
      if (body) {
        return `${body}\n\n${signature}`.trim();
      }

      if (mediaCount) {
        return signature;
      }

      return '';
    },
    []
  );

  const getRelatedIdsThatMeetRequirements = useCallback(
    ({ relatedIds, body }: { relatedIds: RelatedIDWithRequirement[]; body: string }) => {
      return relatedIds.reduce<RelatedID[]>((acc, { requiredString, ...curr }) => {
        if (!requiredString || body.includes(requiredString)) acc.push(curr);
        return acc;
      }, []);
    },
    []
  );

  const { seedValues, values, reset, ...restOfForm } = useForm<SendingAreaFormConfig, SendingAreaFormKey>({
    fields: {
      body: {
        type: 'text',
        value: draft?.body ?? '',
      },
      signature: {
        type: 'text',
        value: signature ?? '',
      },
    },
    onSubmit: () => {
      onSend();
    },
  });

  const deleteDraft = useCallback(
    () =>
      deleteDraftMutation.mutateAsync({
        threadId,
        locationId: groupId,
        orgId: selectedOrgId,
        userId: user?.userID ?? '',
      }),
    [user?.userID, threadId, groupId, selectedOrgId, deleteDraftMutation.mutateAsync]
  );

  const getSendRequest = useCallback(
    (
      remainingRequest: Pick<Parameters<typeof sendMutation.mutateAsync>[0], 'body' | 'media' | 'relatedIds'>
    ): Parameters<typeof sendMutation.mutateAsync>[0] => {
      return {
        locationId: groupId,
        locationPhone:
          outboundPhone?.locationPhone && !outboundPhone?.departmentId
            ? formatPhoneNumberE164(outboundPhone.locationPhone)
            : undefined,
        personPhone: formatPhoneNumberE164(personPhone),
        programSlugId: SMSDataV3.Types.KnownProgramSlugIds.MANUAL_MESSAGES,
        personId: contextContactId || primaryContactId,
        createdBy: user?.userID,
        shortenUrls: true,
        departmentId: outboundPhone?.departmentId,
        messageType: getMessageTypeFromRelatedIds(remainingRequest.relatedIds ?? []),
        ...remainingRequest,
        _otherOptions: {
          threadId,
        },
      };
    },
    [
      groupId,
      outboundPhone?.locationPhone,
      personPhone,
      contextContactId,
      primaryContactId,
      user?.userID,
      outboundPhone?.departmentId,
      threadId,
      getMessageTypeFromRelatedIds,
    ]
  );

  /**
   * Handles sending a new message.
   * NOTE: this should not be used for sending a scheduled message now. Instead use `onSendScheduled`
   */
  const onSend = () => {
    cancelBodyDebounce();
    const { body, signature } = values;

    if (!body && !draftMediaIds.length) {
      alert.error({
        message: t('Cannot send an empty message.'),
      });
      return;
    }

    const bodyWithSignature = formatMessageBodyWithSignature({
      body: body ?? '',
      signature: signature ?? '',
      mediaCount: draftMediaIds.length ?? 0,
    });

    return sendMutation.mutateAsync(
      getSendRequest({
        body: bodyWithSignature,
        media: draftMediaIds?.map((mediaId) => ({ mediaId })) ?? [],
        relatedIds: getRelatedIdsThatMeetRequirements({
          relatedIds,
          body: bodyWithSignature,
        }),
      }),
      {
        onSuccess: async (...args) => {
          await deleteDraft();
          seedValues({ body: '' });
          draftMediaState.resetLocalMedia();
          setRelatedIds([]);
          sendSideEffects?.onSuccess?.(...args);
        },
        onError: (...args) => {
          alert.error({
            message: t('Error sending message. Please try again'),
          });
          seedValues({
            body: draft?.body,
          });
          sendSideEffects?.onError?.(...args);
        },
      }
    );
  };

  /**
   * Handles sending a scheduled message.
   */
  const onSendScheduled = (scheduledSms: ScheduledSms) => {
    cancelBodyDebounce();
    const { body = '' } = editScheduledMessageForm.values;

    if (!body && !scheduledMessageMediaIds.length) {
      alert.error({
        message: t('Cannot send an empty message'),
      });
      return;
    }

    setScheduledMessageBeingEdited(undefined);
    scheduledMessageMediaState.resetLocalMedia();
    return sendMutation.mutateAsync(
      getSendRequest({
        body: body,
        media: scheduledMessageMediaIds.map((mediaId) => ({ mediaId })),
        relatedIds: getRelatedIdsThatMeetRequirements({
          relatedIds: scheduledRelatedIds,
          body: body,
        }),
      }),
      {
        onSuccess: () => {
          deleteScheduledMessageMutation.mutateAsync(
            {
              messageId: scheduledSms.id,
              locationId: groupId,
              deletedBy: user?.userID ?? '',
            },
            {
              onSuccess: () => {
                scheduledMessagesQuery.refetch();
              },
            }
          );
          setScheduledRelatedIds([]);
        },
        onError: () => {
          alert.error({
            message: t('Error sending message. Please try again.'),
          });
          setScheduledMessageBeingEdited(scheduledSms);
        },
      }
    );
  };

  const getScheduleRequest = useCallback(
    (
      remainingRequest: Pick<
        Parameters<typeof scheduleMutation.mutateAsync>[0],
        'body' | 'relatedIds' | 'sendAt' | 'pausable' | 'mediaIds'
      >
    ): Parameters<typeof scheduleMutation.mutateAsync>[0] => {
      return {
        locationId: groupId,
        threadId,
        departmentId: outboundPhone?.departmentId ?? '',
        locationPhone: outboundPhone?.locationPhone ? formatPhoneNumberE164(outboundPhone.locationPhone) : undefined,
        personPhone: formatPhoneNumberE164(personPhone),
        personId: contextContactId || primaryContactId,
        scheduledBy: user?.userID ?? '',
        messageType: getMessageTypeFromRelatedIds(remainingRequest.relatedIds ?? []),
        ...remainingRequest,
      };
    },
    [
      groupId,
      threadId,
      outboundPhone?.departmentId,
      outboundPhone?.locationPhone,
      personPhone,
      contextContactId,
      primaryContactId,
      user?.userID,
      formatPhoneNumberE164,
      getMessageTypeFromRelatedIds,
    ]
  );

  /**
   * Handles scheduling a new message.
   * NOTE: this should not be used for rescheduling an existing scheduled message, instead use `onReschedule`.
   */
  const onSchedule: ThreadSendingAreaState['onSchedule'] = ({ sendAt, pausable }) => {
    cancelBodyDebounce();
    const { body, signature } = values;

    if (!body && !draftMediaIds.length) {
      alert.error({
        message: t('Cannot schedule an empty message'),
      });
      return;
    }

    const bodyWithSignature = formatMessageBodyWithSignature({
      body: body ?? '',
      signature: signature ?? '',
      mediaCount: draftMediaIds.length,
    });

    return scheduleMutation.mutateAsync(
      getScheduleRequest({
        body: bodyWithSignature,
        mediaIds: draftMediaIds,
        relatedIds: getRelatedIdsThatMeetRequirements({
          relatedIds,
          body: bodyWithSignature,
        }),
        sendAt,
        pausable,
      }),
      {
        onSuccess: async () => {
          await deleteDraft();
          draftMediaState.resetLocalMedia();
          scheduledMessagesQuery.refetch();
          setRelatedIds([]);
        },
        onError: () => {
          alert.error({
            message: t('Error scheduling message. Please try again.'),
          });
          seedValues({
            body: draft?.body,
          });
        },
      }
    );
  };

  /**
   * Handles rescheduling an already scheduled message.
   */
  const onReschedule: ThreadSendingAreaState['onReschedule'] = ({ sendAt, pausable, scheduledSms }) => {
    cancelBodyDebounce();
    const { body } = editScheduledMessageForm.values;
    const mediaIds = scheduledMessageMediaIds;

    if (!body && !mediaIds?.length) {
      alert.error({
        message: t('Cannot schedule an empty message'),
      });
      return;
    }

    setScheduledMessageBeingEdited(undefined);
    scheduledMessageMediaState.resetLocalMedia();
    return scheduleMutation.mutateAsync(
      getScheduleRequest({
        body: body ?? '',
        mediaIds,
        relatedIds: getRelatedIdsThatMeetRequirements({
          relatedIds: scheduledRelatedIds,
          body: body ?? '',
        }),
        sendAt,
        pausable,
      }),
      {
        onSuccess: () => {
          deleteScheduledMessageMutation.mutate(
            {
              messageId: scheduledSms.id,
              locationId: groupId,
              deletedBy: user?.userID ?? '',
            },
            {
              onSettled: () => {
                scheduledMessagesQuery.refetch();
              },
            }
          );
          setScheduledRelatedIds([]);
        },
        onError: () => {
          alert.error({
            message: t('Error updating scheduled message. Please try again.'),
          });
          setScheduledMessageBeingEdited(scheduledSms);
        },
      }
    );
  };

  /**
   * Handles deleting a scheduled message
   */
  const onDeleteScheduledMessage = (scheduledSms: ScheduledSms) => {
    setScheduledMessageBeingEdited(undefined);
    scheduledMessageMediaState.resetLocalMedia();
    return deleteScheduledMessageMutation.mutateAsync(
      {
        messageId: scheduledSms.id,
        locationId: groupId,
        deletedBy: user?.userID ?? '',
      },
      {
        onSuccess: () => {
          scheduledMessagesQuery.refetch();
        },
        onError: () => {
          alert.error({
            message: t('Error deleting scheduled message. Please try again.'),
          });
          setScheduledMessageBeingEdited(scheduledSms);
        },
      }
    );
  };

  const ref = useRef<HTMLTextAreaElement>(null);
  const textareaIdRef = useRef<string>(genUUIDV4());
  const [lastDraftSaved, setLastDraftSaved] = useState<Draft>();
  const updateDraftInForm = useCallback(
    (draft: Draft) => {
      if (
        values.body !== draft.body &&
        lastDraftSaved?.body !== draft.body &&
        values.body === debouncedBody &&
        document.activeElement?.id !== textareaIdRef.current // Don't update draft if the textarea is currently focused to avoid losing user typing data
      ) {
        seedValues({
          body: draft.body,
        });
      }
    },
    [values.body, seedValues, lastDraftSaved?.body, document.activeElement?.id, textareaIdRef.current]
  );

  useEffect(() => {
    if (draft) {
      updateDraftInForm(draft);
      addRelatedIds(draft.relatedIds);
    }
  }, [JSON.stringify(draft)]);

  const updateSignatureInForm = useCallback(
    (signature: string) => {
      if (signature !== values.signature) seedValues({ signature });
    },
    [values.signature, seedValues]
  );

  useEffect(() => {
    if (signature !== undefined) updateSignatureInForm(signature);
  }, [signature]);

  const { debouncedValue: debouncedSignature } = useDebouncedValue(values.signature);

  useEffect(() => {
    if (debouncedSignature === undefined || !signatureIsFetched) return;
    if (debouncedSignature !== signature)
      upsertSignatureMutation.mutate({
        groupId,
        userId: user?.userID ?? '',
        signature: debouncedSignature ?? '',
      });
  }, [debouncedSignature]);

  const { debouncedValue: debouncedBody, cancelDebounce: cancelBodyDebounce } = useDebouncedValue(values.body);

  useEffect(() => {
    if (values.body !== debouncedBody) {
      indicateTyping(true);
    } else {
      indicateTyping(false);
    }
  }, [values.body, debouncedBody]);

  const [previousThreadId] = usePreviousValue(threadId);

  useEffect(() => {
    if (previousThreadId) indicateTyping(false, previousThreadId);
    setLastDraftSaved(undefined);
  }, [threadId]);

  const saveBodyToDraft = useCallback(
    (body: string) => {
      if (!draftIsLoading && draft?.body !== body) {
        if (!body && !draft?.medias.length) {
          deleteDraftMutation.mutate({
            threadId,
            locationId: groupId,
            orgId: selectedOrgId,
            userId: user?.userID ?? '',
          });
          setLastDraftSaved(undefined);
        } else {
          const newDraft: Draft = {
            medias: [],
            relatedIds: [],
            ...draft,
            body,
          };
          upsertDraftMutation.mutate({
            draft: newDraft,
            orgId: selectedOrgId,
            threadId,
            locationId: groupId,
            userId: user?.userID ?? '',
            personPhone: formatPhoneNumberE164(personPhone),
          });
          setLastDraftSaved(newDraft);
        }
      }
    },
    [
      !!draftIsLoading,
      JSON.stringify(draft),
      upsertDraftMutation.mutate,
      deleteDraftMutation.mutate,
      selectedOrgId,
      threadId,
      groupId,
      user?.userID,
      personPhone,
      formatPhoneNumberE164,
      setLastDraftSaved,
    ]
  );

  useEffect(() => {
    saveBodyToDraft(debouncedBody ?? '');
  }, [debouncedBody]);

  const isComplete = !!values.body || !!draftMediaIds.length;
  const editScheduledMessageIsComplete = !!editScheduledMessageForm.values.body || !!scheduledMessageMediaIds.length;

  const selectionRef = useRef<{ selectionStart: number; selectionEnd: number }>({ selectionStart: 0, selectionEnd: 0 });
  const selectionChangeHandler = useRef(
    ({ ref, textareaId }: { ref: RefObject<HTMLTextAreaElement>; textareaId: string }) => {
      const activeElement = window.document.activeElement;
      if (ref.current && activeElement && activeElement.id === textareaId) {
        selectionRef.current = {
          selectionStart: ref.current.selectionStart,
          selectionEnd: ref.current.selectionEnd,
        };
      }
    }
  );

  const focusTextarea = useCallback(() => {
    if (ref.current) ref.current.focus();
  }, [ref.current]);

  const insertText = useCallback(
    (text: string) => {
      const { selectionStart, selectionEnd } = selectionRef.current;
      const currentValue = scheduledMessageBeingEdited ? editScheduledMessageForm.values.body ?? '' : values.body ?? '';
      const chars = currentValue.split('');
      const addLeadingSpace = selectionStart > 0 && /\S/.test(chars[selectionStart - 0]);

      if (selectionStart === selectionEnd) {
        chars.splice(selectionStart, 0, `${addLeadingSpace ? ' ' : ''}${text}`);
      } else {
        chars.splice(selectionStart, selectionEnd - selectionStart, `${addLeadingSpace ? ' ' : ''}${text}`);
      }

      if (scheduledMessageBeingEdited) {
        editScheduledMessageForm.seedValues({
          body: chars.join(''),
        });
      } else {
        seedValues({
          body: chars.join(''),
        });
      }

      setTimeout(() => {
        if (ref.current) {
          const newSelectionStart = selectionStart + text.length + (addLeadingSpace ? 1 : 0);
          ref.current.setSelectionRange(newSelectionStart, newSelectionStart);
          focusTextarea();
        }
      }, 0);
    },
    [
      selectionRef.current.selectionEnd,
      selectionRef.current.selectionStart,
      !!scheduledMessageBeingEdited,
      editScheduledMessageForm.values.body,
      values.body,
      editScheduledMessageForm.seedValues,
      seedValues,
      ref.current?.setSelectionRange,
      focusTextarea,
    ]
  );

  const setBody = useCallback(
    (text: string) => {
      if (scheduledMessageBeingEdited) {
        editScheduledMessageForm.seedValues({
          body: text,
        });
        return;
      }
      seedValues({
        body: text,
      });
    },
    [!!scheduledMessageBeingEdited, editScheduledMessageForm.seedValues, seedValues]
  );

  useEffect(() => {
    const listener = () => selectionChangeHandler.current({ ref, textareaId: textareaIdRef.current });
    window.document.addEventListener('selectionchange', listener);
    return () => {
      window.document.removeEventListener('selectionchange', listener);
    };
  }, []);

  const templatePopoverState = usePopoverMenu({
    placement: 'top',
    middlewareOptions: {
      offset: {
        crossAxis: 4,
        mainAxis: 8,
      },
    },
  });

  return {
    threadId,
    groupId,
    formState: { ...restOfForm, values, seedValues, reset, isComplete },
    editScheduledMessageFormState: { ...editScheduledMessageForm, isComplete: editScheduledMessageIsComplete },
    media: scheduledMessageBeingEdited ? scheduledMessageMediaState.media : draftMediaState.media,
    removeMedia: scheduledMessageBeingEdited ? scheduledMessageMediaState.removeMedia : draftMediaState.removeMedia,
    addMedia: scheduledMessageBeingEdited ? scheduledMessageMediaState.addMedia : draftMediaState.addMedia,
    onSend,
    onSendScheduled,
    onSchedule,
    onReschedule,
    onDeleteScheduledMessage,
    disabled: draftIsLoading,
    scheduledMessages: scheduledMessagesQuery.data?.scheduledSmss ?? [],
    onSelectScheduledMessage,
    selectedScheduledMessage: scheduledMessageBeingEdited,
    ref,
    focusTextarea,
    insertText,
    textareaId: textareaIdRef.current,
    templatePopoverState,
    setBody,
    showSignature: !!debouncedSignature && !scheduledMessageBeingEdited,
    addRelatedIds,
  };
};
