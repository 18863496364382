import { useMemo } from 'react';
import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useFormField, TextField, useDebouncedValue, Button } from '@frontend/design-system';
import { getUserFullName } from '../../../utils';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';
import { EmptySearchState } from '../../common/empty-search-state/empty-search-state';

interface SelectMembersProps {
  selectedMemberIds: string[];
  onChange: (memberIds: string[]) => void;
  allUsers: TeamChatTypes.User[];
}

export const MemberSelectionDropdown = ({ selectedMemberIds, allUsers, onChange }: SelectMembersProps) => {
  const textFieldProps = useFormField({ value: '', type: 'text' });
  const { t } = useTranslation('team-chat');
  const searchText = useDebouncedValue(textFieldProps.value);

  /** TODO: on large orgs this could benefit from using a fuse search */
  const filteredUsers = useMemo(() => {
    const input = searchText.trim().toLowerCase();
    return input
      ? allUsers.filter((user) => {
          const fullName = getUserFullName(user.firstName, user.lastName).toLowerCase();
          return fullName.includes(input);
        })
      : allUsers;
  }, [allUsers, searchText, getUserFullName]);

  const addMember = async (userId: string) => {
    onChange([...selectedMemberIds, userId]);
  };

  const removeMember = async (userId: string) => {
    onChange(selectedMemberIds.filter((id) => id !== userId));
  };

  return (
    <>
      <TextField
        name=''
        label=''
        startAdornment={<Icon name='search' />}
        {...textFieldProps}
        placeholder={t('Search members')}
        clearable
      />
      <div css={userListContainerStyle}>
        {!filteredUsers.length && <EmptySearchState />}
        {filteredUsers.map((user) => {
          const isMemberAdded = selectedMemberIds.includes(user.userID);
          return (
            <div css={userContainerStyle} key={user.userID}>
              <ChatAvatar users={[user]} />
              <Text>{getUserFullName(user.firstName, user.lastName)}</Text>
              {isMemberAdded && (
                <Button
                  variant='tertiary'
                  onClick={() => removeMember(user.userID)}
                  css={marginLeftAuto}
                  destructive
                  trackingId='team-chat-2.0-create-channel-remove-user-from-list'
                >
                  {t('Remove')}
                </Button>
              )}
              {!isMemberAdded && (
                <Button
                  variant='tertiary'
                  css={[marginLeftAuto]}
                  onClick={() => addMember(user.userID)}
                  trackingId='team-chat-2.0-create-channel-add-user-to-list'
                >
                  {t('Add')}
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const userListContainerStyle = css({
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '288px',
  boxShadow: theme.shadows.light,
  borderBottomLeftRadius: theme.borderRadius.medium,
  borderBottomRightRadius: theme.borderRadius.medium,
  marginBottom: theme.spacing(1),
});

const userContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

const marginLeftAuto = css({ marginLeft: 'auto' });
