import { Section } from '@react-email/components';
import { CustomCodeSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import DOMPurify from 'dompurify';
import { useComposerSection } from '../../hooks';

export const CustomCodeOutput = () => {
  const { props } = useComposerSection<CustomCodeSection>();

  if (!props.code) return null;
  const sanitizedCode = DOMPurify.sanitize(props.code);

  return (
    <Section>
      <div dangerouslySetInnerHTML={{ __html: sanitizedCode }} />
    </Section>
  );
};
