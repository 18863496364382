import { useHotkeys } from 'react-hotkeys-hook';
import { useQuery } from 'react-query';
import { Divider } from '@frontend/divider';
import env from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { shell } from '@frontend/shell-utils';
import { Modal, useModalControl, Text } from '@frontend/design-system';

export const VersionInfo = () => {
  const { modalProps, closeModal, openModal } = useModalControl();
  const { t } = useTranslation('base');
  useHotkeys(
    'ctrl+shift+v',
    () => {
      if (!modalProps.show) {
        openModal();
      } else {
        closeModal();
      }
    },
    [modalProps.show]
  );

  const versionEndpoint = window.location.host;
  const { data: publishedAt } = useQuery(
    'published-at',
    () => {
      return fetch(versionEndpoint).then((response) => {
        const headers = response.headers;
        return headers.get('site-metadata-published-at');
      });
    },
    {
      refetchOnMount: true,
      enabled: modalProps.show,
    }
  );

  return (
    <Modal {...modalProps} minWidth={380}>
      <Modal.Header>{t('Version Info')}</Modal.Header>
      <Divider />
      <Modal.Body>
        {shell.isShell && <Text>{t('Application Version: {{version}}', { version: shell.version })}</Text>}
        <Text>{t('Web Version: {{version}}', { version: env.VERSION })}</Text>

        <Text>
          {t('Web Version Published At: {{publishedAt}}', {
            publishedAt: publishedAt ? new Date(publishedAt).toLocaleString() : null,
          })}
        </Text>
      </Modal.Body>
    </Modal>
  );
};
