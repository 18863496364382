import { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { ButtonSection as ButtonSectionType } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import ContentEditable from 'react-contenteditable';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useFormField } from '@frontend/design-system';
import { useComposerSection } from '../../hooks/use-composer-section';
import { ComposerSection, Corners } from '../../types';
import { getCornersStyle, getFrameStyle } from '../../utils';
import { ButtonOutput } from '../outputs';
import { ButtonSettings } from '../settings';
import { ButtonTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const ButtonSection: ComposerSection<ButtonSectionType> = ({ buttonColor, corners, frame, link, textColor }) => {
  const { t } = useTranslation('content-composer');
  const { setProps } = useComposerSection<ButtonSectionType>();
  const { id } = useNode((state) => ({ id: state.id }));
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        settings: state.nodes[currentNodeId]?.related?.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
    };
  });

  const fieldProps = useFormField({
    type: 'text',
    value: link?.text,
  });

  useEffect(() => {
    const newLink = {
      ...(link ? { ...link, text: fieldProps.value } : { linkType: '', text: fieldProps.value, url: '' }),
    };
    setProps((props) => (props.link = newLink));
  }, [fieldProps.value]);

  return (
    <BaseComposerSection
      sectionType='ButtonSection'
      title={t('Button')}
      onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      id={id}
      link={link}
      css={getFrameStyle(frame)}
    >
      <ContentEditable
        id={fieldProps.id}
        html={fieldProps.value}
        onChange={fieldProps.onChange}
        css={[
          getCornersStyle(corners as Corners | undefined),
          {
            background: buttonColor,
            color: textColor,
            padding: theme.spacing(1, 2),
            fontWeight: theme.font.weight.bold,
            outline: 'none',
            height: theme.spacing(5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover, &:focus': {
              boxShadow: theme.shadows.floating,
            },
          },
        ]}
      />
    </BaseComposerSection>
  );
};

ButtonSection.craft = {
  props: {
    buttonColor: theme.colors.primary50.toUpperCase(),
    corners: 'pill',
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    link: {
      linkType: 'custom',
      text: i18next.t('Click to Edit Button', { ns: 'content-composer' }),
      url: '',
    },
    textColor: theme.colors.white.toUpperCase(),
  },
  related: {
    output: ButtonOutput,
    settings: ButtonSettings,
    tool: ButtonTool,
  },
};
