import React, { useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { motion, useAnimate, type AnimationPlaybackControls } from 'motion/react';
import { GetWeavePopNotificationActionsByType, WeavePopNotification, NotificationActions } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  DefaultCloseNotificationButton,
  DefaultNotificationActions,
  DefaultNotificationBodyWrapper,
  DefaultNotificationHeader,
  DefaultNotificationInnerWrapper,
} from './defaults/default-notification';

dayjs.extend(relativeTime);

type BaseNotificationOuterProps<N extends WeavePopNotification> = BaseNotificationComponentProps<N> & {
  children: React.ReactNode;
};

export type BaseNotificationComponentProps<N extends WeavePopNotification> = {
  isHistorical?: boolean;
  stacked?: boolean;
  notification: N;
  className?: string;
  isExperimentalMode?: boolean;
  emit: (event: GetWeavePopNotificationActionsByType<N['type']>, notification: N) => void;
};

export const BaseNotificationOuter = <T extends WeavePopNotification>({
  children,
  isExperimentalMode = false,
  ...props
}: BaseNotificationOuterProps<T>) => {
  if (isExperimentalMode) {
    return <BaseNotificationOuterExperimental {...props}>{children}</BaseNotificationOuterExperimental>;
  }

  return <BaseNotificationOuterDefault {...props}>{children}</BaseNotificationOuterDefault>;
};

export const BaseNotificationOuterExperimental = <T extends WeavePopNotification>({
  stacked,
  className,
  children,
}: BaseNotificationOuterProps<T>) => {
  return (
    <div
      css={css`
        position: relative;
        background: ${theme.colors.white};
        border-bottom-left-radius: ${theme.borderRadius.medium};
        border-bottom-right-radius: ${theme.borderRadius.medium};
        border-top-left-radius: ${stacked
          ? 0
          : theme.borderRadius.medium}; //when stacked this should fit snugly with the element above
        border-top-right-radius: ${stacked
          ? 0
          : theme.borderRadius.medium}; //when stacked this should fit snugly with the element above
        width: 350px;
      `}
      className={className}
    >
      {children}
    </div>
  );
};

export const BaseNotificationOuterDefault = <T extends WeavePopNotification>({
  isHistorical,
  notification,
  emit,
  stacked,
  className,
  children,
}: BaseNotificationOuterProps<T>) => {
  const [scope, animate] = useAnimate();
  const animation = useRef<AnimationPlaybackControls>();
  const hasTimeout = !!notification.state.timeout && !isHistorical;

  useEffect(() => {
    if (!hasTimeout) {
      return;
    }
    animation.current = animate(
      scope.current,
      {
        width: 0,
      },
      {
        duration: (notification.state.timeout ?? 0) / 1000,
      }
    );
    animation.current.then(() => {
      // don't call onTimeout if the animation has been canceled
      if (!animation.current) return;
      onTimeout();
    });
    return () => {
      animation.current?.cancel();
      animation.current = undefined;
    };
  }, [notification.id]);

  useEffect(() => {
    if (!hasTimeout) {
      return;
    }
    if (!notification.state.paused) {
      animation.current?.play();
    }
  }, [notification.state.paused, notification.state.timeout]);

  const onMouseEnter = () => {
    emit(
      {
        action: 'focus',
        payload: undefined,
      },
      notification
    );

    animation.current?.pause();
  };

  const onMouseLeave = () => {
    if (notification.state.paused) {
      return;
    }
    emit(
      {
        action: 'blur',
        payload: undefined,
      },
      notification
    );
    animation.current?.play();
  };

  const onTimeout = () => {
    emit({ action: 'timed-out', payload: undefined }, notification);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={css`
        position: relative;
        margin-bottom: ${theme.spacing(0.5)};
        background: ${theme.colors.white};
        border-bottom-left-radius: ${theme.borderRadius.medium};
        border-bottom-right-radius: ${theme.borderRadius.medium};
        border-top-left-radius: ${stacked
          ? 0
          : theme.borderRadius.medium}; //when stacked this should fit snugly with the element above
        border-top-right-radius: ${stacked
          ? 0
          : theme.borderRadius.medium}; //when stacked this should fit snugly with the element above
        box-shadow: ${stacked ? 'none' : theme.shadows.heavy}; //when stacked the shadow goes on the wrapper
        width: 350px;
      `}
      className={className}
    >
      {children}
      {hasTimeout && (
        <motion.div
          ref={scope}
          /**
           * We set the styles such that there is something to animate, but the user cannot see it.
           * This allows us to rely solely on framer to handle the animation and auto-dismissal of the notification.
           */
          css={css`
            height: 2px;
            background: transparent;
          `}
          initial={{ width: '100%' }}
        />
      )}
    </div>
  );
};

export type NotificationInnerProps = {
  actions: NotificationActions;
  title: React.ReactNode;
  body: React.ReactNode;
  location?: string;
  timestamp: string | number;
  stacked?: boolean;
  isUnread?: boolean;
  id: string;
  onClose?: (e: React.MouseEvent) => void;
  notificationType: string;
};

export const BaseNotificationInner = ({
  actions,
  title,
  body,
  timestamp,
  stacked,
  onClose,
  notificationType,
}: NotificationInnerProps) => {
  return (
    <div css={{ position: 'relative' }}>
      {!!onClose && <DefaultCloseNotificationButton onClick={onClose} />}
      <DefaultNotificationHeader title={title} timestamp={timestamp} stacked={stacked ?? false} />
      <DefaultNotificationInnerWrapper hasActions={!!actions.length}>
        <DefaultNotificationBodyWrapper>{body}</DefaultNotificationBodyWrapper>
        {actions.length ? <DefaultNotificationActions actions={actions} notificationType={notificationType} /> : null}
      </DefaultNotificationInnerWrapper>
    </div>
  );
};
