import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { Device, DeviceType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useQueryClient } from 'react-query';
import { DevicesApi, DevicesTypes } from '@frontend/api-devices';
import SipProfileAPI, { SipProfilesApiTypes } from '@frontend/api-sip-profile';
import { Slider } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { SwitchField, Text, useControlledField, useAlert } from '@frontend/design-system';
import { DEFAULT_PARKED_CALL_RING_BACK_TIME } from '../../../constants';
import { queryKeys } from '../../../query-keys';

type RingSettingsProps = {
  sipProfile: SipProfilesApiTypes.SipProfileType;
  device?: Device;
  tenantId?: string;
};

export const RingSettings = ({ sipProfile, device, tenantId }: RingSettingsProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const alerts = useAlert();
  const { parkRingbackEnabled, parkRingbackLagSeconds } = sipProfile;

  const [parkRingbackLagValue, setParkRingbackLagValue] = useState(
    parkRingbackLagSeconds ?? DEFAULT_PARKED_CALL_RING_BACK_TIME
  );
  const [callWaitingIndicatorEnabled, setCallWaitingIndicatorEnabled] = useState(device?.callWaitingIndicatorBeep);
  const [isParkRingbackEnabled, setIsParkRingbackEnabled] = useState(parkRingbackEnabled);
  const queryClient = useQueryClient();

  const mutateDevice = useMutation({
    mutationFn: ({ device }: DevicesTypes.UpdateDeviceType['input']) =>
      DevicesApi.UpdateDevice({
        device,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([tenantId, ...queryKeys.settings.listDeviceSipProfiles(device?.deviceId ?? '')]);
      alerts.success(t('Saved Ring Settings'));
    },
    onError: (_e, { device }) => {
      setCallWaitingIndicatorEnabled(!device?.callWaitingIndicatorBeep);
      alerts.error(t('Unable to save Ring Settings. Please try again.'));
    },
  });

  const { mutate: mutateExtension } = useMutation({
    mutationFn: ({ sipProfile }: { sipProfile: SipProfilesApiTypes.SipProfileType }) =>
      SipProfileAPI.Update({ sipProfile }),
    onSuccess: () => {
      queryClient.invalidateQueries([tenantId, ...queryKeys.settings.listDeviceSipProfiles(device?.deviceId ?? '')]);
      alerts.success(t('Saved Ring Settings'));
    },
    onError: (_e, { sipProfile }) => {
      setIsParkRingbackEnabled(!sipProfile.parkRingbackEnabled);
      alerts.error(t('Unable to save Ring Settings. Please try again.'));
    },
  });

  const callWaitingFieldProps = useControlledField({
    type: 'switch',
    value: callWaitingIndicatorEnabled,
    onChange: (val: boolean) => {
      setCallWaitingIndicatorEnabled(val);
      console.log('HIT', callWaitingFieldProps.active);
      if (callWaitingFieldProps.active && device?.deviceId) {
        const payload: Device = {
          ...device,
          deviceId: device?.deviceId ?? '',
          callWaitingIndicatorBeep: val,
        };
        mutateDevice.mutate({
          device: payload,
        });
      }
    },
  });

  const mutateExtensionCallback = useCallback(
    (fieldName: 'parkRingbackEnabled' | 'parkRingbackLagSeconds', val: number | boolean) => {
      mutateExtension({
        sipProfile: {
          ...sipProfile,
          [fieldName]: val,
        },
      });
    },
    [sipProfile]
  );

  const parkRingbackFieldProps = useControlledField({
    type: 'switch',
    value: isParkRingbackEnabled,
    onChange: (val: boolean) => {
      setIsParkRingbackEnabled(val);
      if (parkRingbackFieldProps.active) {
        mutateExtensionCallback('parkRingbackEnabled', val);
      }
    },
  });

  const handleSliderChange = (values: number[]) => {
    setParkRingbackLagValue(values[0]);
    mutateExtensionCallback('parkRingbackLagSeconds', values[0]);
  };

  return (
    <section css={{ maxWidth: theme.spacing(75) }}>
      {device?.type !== DeviceType_Enum.SOFTPHONE && (
        <SwitchField
          {...callWaitingFieldProps}
          name='call-waiting-indicator-beep'
          css={{ width: 'fit-content', maxWidth: '600px' }}
          label={
            <div css={{ width: 'fit-content' }}>
              <Text weight='bold'>{t('Apply Call Waiting Indicator Beep')}</Text>
              <Text color='light' size='small'>
                {t('You will hear a beep when there is an incoming call.')}
              </Text>
            </div>
          }
          labelPlacement='left'
        />
      )}
      <SwitchField
        {...parkRingbackFieldProps}
        css={css`
          ${device?.type !== DeviceType_Enum.SOFTPHONE && `margin-top: ${theme.spacing(2)};`};
          width: fit-content;
          max-width: 600px;
        `}
        name='park-ringback'
        label={
          <>
            <Text weight='bold'>{t('Apply Park Ringback')}</Text>
            <Text color='light' size='small'>
              {t(
                'This device will ring back regularly at the set interval after a caller is put on hold by any device in the call group.'
              )}
            </Text>
          </>
        }
        labelPlacement='left'
      />
      <Slider
        disabled={!parkRingbackFieldProps.value}
        max={300}
        min={5}
        onChange={(values) => handleSliderChange([...values])}
        step={5}
        style={{ height: theme.spacing(3), margin: theme.spacing(4, 0, 2, 2), width: '300px' }}
        values={[parkRingbackLagValue]}
      />
    </section>
  );
};
