import dayjs from 'dayjs';
import { customRandom, random } from 'nanoid';

export const tracerHeader = 'X-Weave-Debug-Id';
export const jaegerTracerId = 'jaeger-debug-id';

const ONE_MINUTE = 60000;

export class Tracer {
  public id = '';
  public timeout = 0;
  public isProd: boolean;

  constructor(isProd = true) {
    this.isProd = isProd;
  }

  generateId(length = 6): string {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const nanoid = customRandom(chars, length, random);

    this.id = nanoid();

    return this.id;
  }

  startTrace(callback: () => void, minutes = 2) {
    this.timeout = window.setTimeout(() => {
      this.stopTrace();
      callback();
    }, minutes * ONE_MINUTE);

    return this.generateId();
  }

  stopTrace() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.id = '';
    this.logTracingDisabled();
  }

  generateLink(): string {
    if (this.isProd) {
      return `https://grafana.weavelab.xyz/d/a30053fa-dee8-4418-afc6-a45be096aa1a/trace-by-jaeger-debug-id?from=now-5m&to=now&var-datasource=fe0456a3-c2d7-489f-8bc7-74202957a2fe&var-traceid=${this.id}&var-app=All&orgId=1`;
    }

    return `https://grafana.weavelab.xyz/d/a30053fa-dee8-4418-afc6-a45be096aa1a/trace-by-jaeger-debug-id?from=now-5m&to=now&var-datasource=dbc4a951-04d4-4fbc-9261-45f0fbc505fe&var-traceid=${this.id}&var-app=All&orgId=1`;
  }

  logLinks() {
    window.setTimeout(() => {
      this.logTracingEnabled();
      if (this.id) {
        consoleLogGeneratedLink(this.generateLink());
      }
    }, 5 * 1000); // Delay will allow for user interaction then print after
  }

  logTracingEnabled() {
    consoleLogTracingToggle(!!this.id);
    consoleLogTracingId(this.id);
  }

  logTracingDisabled() {
    consoleLogTracingToggle(!!this.id);
    consoleLogTracingDisabled(tracerHeader);
  }
}

const consoleLogGeneratedLink = (generatedLink: string) => {
  console.log(
    `%c${generatedLink}`,

    `font-size:12px;
     padding:20px;
     border: 1px solid #8e959e;
     border-radius: 0px 5px 5px 5px;
     margin-bottom: 10px;`
  );
};

const consoleLogTracingId = (tracingId: string) => {
  const currentTime = dayjs().format('hh:mm:ss A');
  console.log(
    `%cTrace ID %c${tracingId || 'No tracing Id present'} %c${currentTime}`,

    `margin-top: 10px;
    font-size:13px; background-color: #f4f5f7;
    padding:15px 5px 15px 15px;
    border: 1px solid #8e959e;
    color: #202328;
    border-radius: 15px 0 0 15px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif.;
    font-weight: bold;`,

    `font-size:13px;
    padding:15px;
    border: 1px solid #8e959e;
    border-radius: 0px 15px 15px 0px;
    margin-bottom: 10px;
    border-left: none;
    margin-right: 10px;,
    `,

    `font-size:13px;
    padding:10px 15px;
    border: 1px solid #8e959e;
    border-radius: 15px;
    margin-bottom: 10px;`
  );
};

const consoleLogTracingToggle = (isEnabled: boolean) => {
  console.log(
    `%c ∞ %cweave%cTRACING ${isEnabled ? 'ENABLED' : 'DISABLED'}`,

    `font-size:31px;
     margin-top:5px;
     background-color: #f4f5f7;
     padding:1px 0 8px 0px;
     border: 1px solid #8e959e;
     border-right: none;
     color: #5f6771;
     border-radius: 8px 0 0 8px;
     margin-bottom: 10px;
    `,

    `background-color: ${isEnabled ? '#08873F' : '#FF584C'};
     color: white;
     font-size: 22px;
     font-family: Helvetica Neue, Helvetica, Arial, sans-serif.;
     padding:11px 20px 11px 20px;
     border-radius: 0 8px 8px 0;
     margin-bottom: 10px;
     margin-right:20px;
         `,

    `font-size:15px;
     padding:14px;
     border: 1px solid #8e959e;
     color: ${isEnabled ? '#08873F' : '#FF584C'};
     border-radius: 10px;
     margin-bottom: 10px;`
  );
};

const consoleLogTracingDisabled = (tracingHeader: string) => {
  console.log(
    `%cTrace Id Disabled %c${tracingHeader} will no longer be sent.`,

    `font-size:12px;
     font-weight:bold;
     margin-top:5px;
     background-color: #424952;
     padding:10px;
     border:1px solid #424952;
     color: white;
         `,

    `background-color: #f4f5f7;
     color: #202328;
     font-size:12px;
     font-family: Helvetica Neue, Helvetica, Arial, sans-serif.;
     padding:10px;
     boxing-size:border-box;
     border:1px solid #424952;
     margin-bottom: 10px;
     margin-right:20px;
         `
  );
};
