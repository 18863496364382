import {
  DataType as CapabilityDataType,
  Operation as CapabilityOperation,
} from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/capability.pb';
import { useScopedAppFlagStore } from '@frontend/scope';
import type { CommonHTMLAttributes } from '@frontend/types';
import { useCheckIntegrationCapability } from '../hooks/use-check-integration-capability';

type DirectAvailabilityCheckProps = CommonHTMLAttributes & { locationId: string };

export const DirectAvailabilityCheck = ({ children, locationId, ...rest }: DirectAvailabilityCheckProps) => {
  // TODO 'schedule-alpha-access-to-availability-api' feature flag check AND the capability check will be moved to a new endpoint in sch-4359. The frontend will need to use the new endpoint to determine the usage of direct availability.
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasFeatureFlag = getFeatureFlagValue('schedule-alpha-access-to-availability-api');

  const { hasIntegrationCapability } = useCheckIntegrationCapability(
    {
      type: CapabilityDataType.SCHEDULE_AVAILABILITY,
      operation: CapabilityOperation.SEARCH,
    },
    hasFeatureFlag ? '' : locationId
  );

  // If the feature flag is enabled, show the banner. If the feature flag is disabled, show the banner only if the integration capability is present.
  const showBanner = hasFeatureFlag ? hasFeatureFlag : hasIntegrationCapability;

  if (!showBanner) return null;

  return <div {...rest}>{children}</div>;
};
