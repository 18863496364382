import { useQueryClient, useMutation } from 'react-query';
import CallQueueAPI from '@frontend/api-call-queue';
import { queryKeys } from '../../../query-keys';
import { CallQueueCreatePayload, CallQueueState } from './types';

export const useCallQueueMutations = (phoneTenantId: string) => {
  const queryClient = useQueryClient();

  /**
   * Hold off on optimistic updates for now. These updates (setQueryData) cause an immediate rerender, which can cause the outer mutate options hooks to not fire.
   */
  const create = useMutation({
    mutationFn: (payload: CallQueueCreatePayload) => {
      return CallQueueAPI.Create(payload);
    },
    // onMutate: async (newCallQueue) => {
    //   await queryClient.cancelQueries({
    //     queryKey: [phoneTenantId, ...queryKeys.settings.listCallQueue()],
    //   });
    //   const prev = queryClient.getQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()]);

    //   queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()], (old: any) => {
    //     return [newCallQueue, ...old];
    //   });

    //   return { prev };
    // },
    onSuccess: (data) => {
      queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.readCallQueue(data.callQueueId)], data);
      queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()], (old: any) => {
        return [data, ...old];
      });
    },
    // onError: (_err, _var, context) => {
    //   queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()], context?.prev);
    // },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listCallQueue()]);
    },
  });

  const update = useMutation({
    mutationFn: (payload: CallQueueState) => {
      return CallQueueAPI.Update(payload);
    },
    // onMutate: async (payload) => {
    //   await queryClient.cancelQueries({
    //     queryKey: [phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)],
    //   });
    //   const prev = queryClient.getQueryData([phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)]);
    //   const prevList = queryClient.getQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()]);

    //   // Optimistically update the call queue
    //   queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)], payload);
    //   // Optimistically update the call queue list
    //   queryClient.setQueryData(
    //     [phoneTenantId, ...queryKeys.settings.listCallQueue()],
    //     (old: CallQueueListResponse['callQueues']) => {
    //       return (
    //         old?.map((item) => {
    //           if (item.callQueueId === payload.callQueueId) {
    //             return payload;
    //           }
    //           return item;
    //         }) ?? []
    //       );
    //     }
    //   );

    //   return { prev, prevList };
    // },
    // onError: (_err, payload, context) => {
    //   queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()], context?.prevList);
    //   queryClient.setQueryData(
    //     [phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)],
    //     context?.prev
    //   );
    // },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listCallQueue()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)]);
    },
  });

  const remove = useMutation({
    mutationFn: (payload: { callQueueId: string }) => {
      return CallQueueAPI.Delete(payload);
    },
    // onMutate: async (payload) => {
    //   await queryClient.cancelQueries({
    //     queryKey: [phoneTenantId, ...queryKeys.settings.listCallQueue()],
    //   });

    //   const prevList = queryClient.getQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()]);

    //   queryClient.setQueryData(
    //     [phoneTenantId, ...queryKeys.settings.listCallQueue()],
    //     (old: CallQueueListResponse['callQueues']) => {
    //       return old?.filter((item) => item.callQueueId !== payload.callQueueId) ?? [];
    //     }
    //   );

    //   return { prevList };
    // },
    // onError: (_err, _payload, context) => {
    //   queryClient.setQueryData([phoneTenantId, ...queryKeys.settings.listCallQueue()], context?.prevList);
    // },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listCallQueue()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readCallQueue(payload.callQueueId)]);
    },
  });

  return {
    create,
    update,
    remove,
  };
};
