import { useState } from 'react';
import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { PhoneOverrideType } from 'libs/features/phone/src/components/override/types';
import { DepartmentsApi } from '@frontend/api-departments';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { LocationsApi } from '@frontend/api-locations';
import { GlobalBannerDynamic } from '@frontend/global-info-banner';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import {
  queryKeys,
  usePhoneSettingsShallowStore,
  NewOverrideSettingRemoveModal,
  NewOverrideSettingTray,
} from '@frontend/phone';
import { useHasPhoneSettingsAccess } from '@frontend/phone-config';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { usePhoneOverrideShallowStore, usePhoneOverrideShallowStoreV2 } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Chip,
  EditSimpleIcon,
  Modal,
  SecondaryButton,
  Table,
  Text,
  TextLink,
  TrashIcon,
  useAlert,
  useModalControl,
} from '@frontend/design-system';

dayjs.extend(timezone);

export const OverrideNotificationRibbon = () => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const alerts = useAlert();
  const { overrides } = usePhoneOverrideShallowStore('overrides');
  const { overridesV2 } = usePhoneOverrideShallowStoreV2('overridesV2');
  const phoneOverrides = overridesV2.phoneOverrides;
  const enabledOverrides = overrides?.filter((override) => override.enabled) || [];
  const enabledOverridesV2 = phoneOverrides?.filter((override) => override.enabled) || [];
  const { getLocationName, accessibleLocationData, selectedLocationIds, groupData } = useAppScopeStore();
  const phoneAccess = useHasPhoneSettingsAccess({
    selectedLocations: selectedLocationIds?.map((id) => accessibleLocationData[id]).filter(Boolean) ?? [],
  });

  const filteredEnabledOverridesV1 = enabledOverrides.filter((item) =>
    selectedLocationIds.includes(item?.locationId ?? '')
  );
  const filteredEnabledOverridesV2 = enabledOverridesV2.filter((item) => selectedLocationIds.includes(item.locationId));
  const locationData = accessibleLocationData[selectedLocationIds[0]];
  const selectedLocationData = locationData;

  const { navigate } = useSettingsNavigate();
  const { modalProps, openModal, closeModal } = useModalControl();
  const removeOverride = useModalControl();
  const tray = useModalControl();
  const { setSettingsTenantLocation } = usePhoneSettingsShallowStore('setSettingsTenantLocation');
  const { aggregateValue: hasCallRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: selectedLocationIds ?? [],
  });
  const hasSingleEnabledOverride = hasCallRoutesFlag
    ? filteredEnabledOverridesV2.length === 1
    : enabledOverrides.length === 1;
  const [override, setOverride] = useState<PhoneOverride>({});
  const [overrideV2, setOverrideV2] = useState<Partial<PhoneOverrideType>>({});
  const departmentId = override.departmentId;
  const { data: department } = useScopedQuery({
    queryKey: queryKeys.department(departmentId ?? ''),
    queryFn: async () => {
      return DepartmentsApi.readDepartment({ departmentId: departmentId }).then((res) => res.department);
    },
    enabled: !!departmentId,
  });

  const { data: departments } = useScopedQuery({
    queryKey: queryKeys.listDepartments(),
    queryFn: () => {
      return DepartmentsApi.listDept({ fwdEnabled: true, vmoEnabled: true });
    },
    select: (data) => {
      return data?.departments?.sort((a, b) => (!a.name || !b.name ? 0 : a.name.localeCompare(b.name)));
    },
    onError: () => {
      alerts.error(t('Error in retrieving departments list'));
    },
  });

  const getDepartmentNameById = (id: string) => {
    if (!departments) {
      return null;
    }
    const department = departments.find((department) => department.id === id);
    return department ? department.name : null;
  };

  const getName = () => {
    if (departments && departments.length > 1) {
      if (enabledOverrides?.length === 1 && enabledOverrides[0].departmentId) {
        return t('{{departmentName}}', { departmentName: getDepartmentNameById(enabledOverrides[0].departmentId) });
      } else {
        return t('{{count}} departments', { count: enabledOverrides?.length });
      }
    } else {
      const overridesLocationIds = new Set(enabledOverrides?.map((override) => override.locationId));
      const overridesLocationsCount = overridesLocationIds.size;

      if (overridesLocationsCount === 1) {
        return t('{{locationName}}', { locationName: getLocationName([...overridesLocationIds][0]!) });
      } else {
        return t('{{count}} locations', { count: overridesLocationsCount });
      }
    }
  };

  const getCallRouteName = () => {
    if (filteredEnabledOverridesV2?.length === 1) {
      return t('{{callRouteName}}', { callRouteName: filteredEnabledOverridesV2[0].callRouteName });
    } else if (filteredEnabledOverridesV2?.length > 1) {
      return t('{{count}} Call Routes', { count: filteredEnabledOverridesV2.length });
    }
    return null;
  };

  const OverrideLink = () => {
    return (
      <TextLink onClick={openModal} trackingId='phone-portal-weave2.0-override-notification-btn-openModal'>
        {getName()}
      </TextLink>
    );
  };

  const OverrideLinkV2 = () => {
    return (
      <TextLink
        onClick={() =>
          navigate({
            to: '/phone/call-routes/:id',
            params: { id: enabledOverridesV2[0].callRouteId ?? '' },
          })
        }
      >
        {getCallRouteName()}
      </TextLink>
    );
  };

  const message = (
    <>
      <Trans t={t}>
        Override is turned on for <OverrideLink />.{' '}
      </Trans>
      {t('Calls will not route according to office hour routing until the override is removed.', {
        count: enabledOverrides?.length,
      })}
    </>
  );
  const message2 = hasSingleEnabledOverride ? (
    <Trans t={t}>
      Call Route <OverrideLinkV2 /> has an active override and will not direct calls normally until the override is
      removed.
    </Trans>
  ) : (
    <Trans t={t}>
      {getCallRouteName()} have active overrides and will not direct calls normally until the overrides are removed.
    </Trans>
  );

  const actionButton = (
    <TextLink
      weight='bold'
      onClick={() => {
        if (selectedLocationData && hasSingleEnabledOverride && hasCallRoutesFlag) {
          setOverrideV2(enabledOverridesV2[0]);
          tray.openModal();
        } else if (selectedLocationData) {
          const group =
            selectedLocationData.locationType === 'databeta'
              ? { ...selectedLocationData, children: [] }
              : groupData[selectedLocationData.groupId];
          setSettingsTenantLocation(group);
          navigate({ to: '/phone/overrides' });
          closeModal();
        }
      }}
      trackingId='phone-portal-weave2.0-override-notification-btn-manageInSettings'
    >
      {hasCallRoutesFlag ? t('Go to Overrides') : t('Manage in Settings')}
    </TextLink>
  );

  const onClickHandler = () => {
    if (selectedLocationData && hasCallRoutesFlag) {
      setOverrideV2(enabledOverridesV2[0]);
      tray.openModal();
    } else if (selectedLocationData) {
      const group =
        selectedLocationData.locationType === 'databeta'
          ? { ...selectedLocationData, children: [] }
          : groupData[selectedLocationData.groupId];
      setSettingsTenantLocation(group);
      navigate({ to: '/phone/overrides' });
      closeModal();
    }
  };

  const getOverrideType = (override: PhoneOverride) => {
    const { overrideType } = override;

    switch (overrideType) {
      case 'OVERRIDE_TYPE_VMO':
        return t('Send to Voicemail');
      case 'OVERRIDE_TYPE_FWD':
        return t('Forward Call');
      default:
        return '--';
    }
  };

  const { data: officeTimezone } = useLocalizedQuery({
    queryKey: queryKeys.timezone(selectedLocationIds[0]),
    queryFn: () => LocationsApi.getOfficeTimezone({}),
    select: (data) => data.timezone,
  });

  const getEndTime = (override: PhoneOverride | undefined, officeTimezone: string | undefined) => {
    if (override && override.endAt) {
      return dayjs(override.endAt).tz(officeTimezone).format('MMM D YYYY h:mma z');
    }
    return '--';
  };

  const handleOpenRemoveModal = (override: PhoneOverride) => {
    setOverride(override);
    removeOverride.openModal();
  };

  const handleCreate = (override: PhoneOverride) => {
    setOverride(override);
    tray.openModal();
  };
  const handleOpenRemoveModalV2 = (overrideV2: PhoneOverrideType) => {
    setOverrideV2(overrideV2);
    removeOverride.openModal();
  };

  const handleCreateV2 = (overrideV2: PhoneOverrideType) => {
    setOverrideV2(overrideV2);
    tray.openModal();
  };

  const [showBanner, setShowBanner] = useState(true);
  const handleDismiss = () => setShowBanner(false);

  return (
    <>
      {showBanner &&
        (hasCallRoutesFlag ? !!filteredEnabledOverridesV2?.length : !!filteredEnabledOverridesV1?.length) && (
          <GlobalBannerDynamic
            id='override-notification-banner'
            type='warning'
            title={hasSingleEnabledOverride ? t('Active Override') : t('Active Overrides')}
            message={hasCallRoutesFlag ? message2 : message}
            isDismissible
            onDismiss={handleDismiss}
            action={{
              label: hasSingleEnabledOverride ? t('Edit Override') : t('Manage Overrides'),
              onClick: hasSingleEnabledOverride ? onClickHandler : openModal,
            }}
          />
        )}
      {hasCallRoutesFlag ? (
        <Modal {...modalProps} minWidth={800}>
          <Modal.Header
            onClose={closeModal}
            closeBtnTrackingId='phone-portal-weave2.0-override-notification-btn-closeModal'
          >
            {t('Call Routes with Active Overrides')}
          </Modal.Header>
          <Modal.Body>
            <Text>{t('Call Routes with active Overrides are unable to receive calls.')}</Text>
            <Table
              colConfig={[
                {
                  Header: t('Call Route'),
                  accessor: (override) =>
                    phoneAccess.hasFullPhoneAccess ? (
                      <TextLink
                        onClick={() => {
                          closeModal();
                          navigate({
                            to: '/phone/call-routes/:id',
                            params: { id: override.callRouteId ?? '' },
                          });
                        }}
                      >
                        {override.callRouteName}
                      </TextLink>
                    ) : (
                      <Text size='medium'>{override.callRouteName}</Text>
                    ),
                  id: 'name',
                  width: 100,
                },
                {
                  Header: t('Override Type'),
                  id: 'override type',
                  accessor: (override) => getOverrideType(override),
                  width: 100,
                },
                {
                  Header: t('Override Ends'),
                  id: 'end time',
                  accessor: (override) => getEndTime(override, officeTimezone),
                  width: 100,
                },
                {
                  Header: t('Location'),
                  accessor: (override) => (
                    <Chip.Location variant='primary'>{getLocationName(override.locationId ?? '')}</Chip.Location>
                  ),
                  id: 'location',
                  width: 100,
                  omit: selectedLocationIds.length === 1,
                },
              ]}
              hasResponsiveColWidths
              data={filteredEnabledOverridesV2}
              rowActions={{
                actions: [
                  {
                    label: t('Edit Override'),
                    Icon: EditSimpleIcon,
                    onClick: handleCreateV2,
                    trackingId: 'phone-portal-weave2.0-override-notification-btn-edit',
                  },
                  {
                    label: t('Remove Override'),
                    Icon: TrashIcon,
                    onClick: handleOpenRemoveModalV2,
                    destructive: true,
                    trackingId: 'phone-portal-weave2.0-override-notification-btn-remove',
                  },
                ],
              }}
            ></Table>
            <div
              css={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(3), marginTop: theme.spacing(3) }}
            >
              {actionButton}
              <SecondaryButton
                onClick={closeModal}
                size='tiny'
                trackingId='phone-portal-weave2.0-override-notification-btn-closeModal'
              >
                {t('Close')}
              </SecondaryButton>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal {...modalProps} minWidth={800}>
          <Modal.Header
            onClose={closeModal}
            closeBtnTrackingId='phone-portal-weave2.0-override-notification-btn-closeModal'
          >
            {t('Active Overrides')}
          </Modal.Header>
          <Modal.Body>
            <Text>
              {departments && departments.length > 1
                ? t('Departments with active overrides are unable to receive calls.')
                : selectedLocationIds.length
                ? t('Locations with active overrides are unable to receive calls.')
                : t('Your location will not receive calls with an active override.')}
            </Text>
            <Table
              colConfig={[
                {
                  Header: t('Override'),
                  id: 'override type',
                  accessor: (override) => getOverrideType(override),
                },
                {
                  Header: departments && departments?.length > 1 ? t('Department') : t('Location'),
                  accessor: (override) => {
                    const { departmentId, locationId } = override;
                    const hasDepartments = departments && departments?.length > 1 && departmentId;

                    return hasDepartments ? (
                      <Chip.Department>{getDepartmentNameById(departmentId) || '--'}</Chip.Department>
                    ) : (
                      <Chip.Location>{getLocationName(locationId ?? '')}</Chip.Location>
                    );
                  },
                  id: 'name',
                },
                {
                  Header: t('End Time'),
                  id: 'end time',
                  accessor: (override) => getEndTime(override, officeTimezone),
                },
              ]}
              hasResponsiveColWidths
              data={enabledOverrides}
              rowActions={{
                actions: [
                  {
                    label: t('Edit Override'),
                    Icon: EditSimpleIcon,
                    onClick: handleCreate,
                    trackingId: 'phone-portal-weave2.0-override-notification-btn-edit',
                  },
                  {
                    label: t('Remove Override'),
                    Icon: TrashIcon,
                    onClick: handleOpenRemoveModal,
                    destructive: true,
                    trackingId: 'phone-portal-weave2.0-override-notification-btn-remove',
                  },
                ],
              }}
            ></Table>
            <div
              css={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(2), marginTop: theme.spacing(5) }}
            >
              {actionButton}
              <SecondaryButton
                onClick={closeModal}
                size='tiny'
                trackingId='phone-portal-weave2.0-override-notification-btn-closeModal'
              >
                {t('Close')}
              </SecondaryButton>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <NewOverrideSettingRemoveModal
        override={hasCallRoutesFlag ? overrideV2 : override}
        department={department}
        modalProps={removeOverride.modalProps}
        phoneTenantId={selectedLocationData?.phoneTenantId ?? ''}
      />
      <NewOverrideSettingTray
        override={hasCallRoutesFlag ? overrideV2 : override}
        departmentId={department?.id}
        departmentName={department?.name}
        locationId={selectedLocationIds[0]}
        modalControls={tray}
        overridesData={overridesV2}
      />
    </>
  );
};
