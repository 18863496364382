import { useState } from 'react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useCalendarEventV3Context } from '../../../../context/CalendarEventsV3Context';
import { useCalendarEventsConfigurationShallowStore } from '../../../../hooks';
import { useSchedulingLocationInfo } from '../../../../hooks/use-scheduling-location-info';
import { getRRuleStringFromDateRange } from '../../../../rrule-helper';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../../../stores/use-calendar-view-v3-header-filter-store';
import {
  calculateDuration,
  findOverrideIndexById,
  generateExceptionId,
  transformV3ResponsesForReviewRequest,
} from '../../../../utils';
import { CalendarEventsEnums } from '../../types';
import { OutOfOfficeEventsForm } from './OutofOfficeEventForm';
import {
  CreateUpdateExceptionPayloadParams,
  ExceptionFormValues,
  ExceptionOperations,
  HandleExceptionsParams,
  OnDeleteExceptionParams,
} from './types';

type OutOfOfficeEventFormContainerProps = {
  eventType: CalendarEventsEnums;
  closeModal: () => void;
};

export const OutOfOfficeEventFormContainerV3 = ({ eventType, closeModal }: OutOfOfficeEventFormContainerProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const { getLocationTimeZone } = useSchedulingLocationInfo();
  const { configuredCalendarEvent } = useCalendarEventsConfigurationShallowStore('configuredCalendarEvent');

  const [formError, setFormError] = useState<string>('');
  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);

  const { practitioners, selectedMultiLocationIds } = useCalendarEventV3Context();
  const { selectedCalendarDate } = useCalendarViewV3HeaderFilterShallowStore('selectedCalendarDate');

  const getEntityTypeName = (type?: ScheduleType) => {
    return type === ScheduleType.PROVIDER ? t('Provider') : t('Location');
  };

  const getEntityType = (eventType?: CalendarEventsEnums) => {
    return eventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT
      ? ScheduleType.PROVIDER
      : ScheduleType.LOCATION;
  };

  const createScheduleMutation = SchedulerV3.Mutations.useCreateScheduleMutation();

  const updateScheduleMutation = SchedulerV3.Mutations.useUpdateScheduleMutation();

  const providersListData = transformV3ResponsesForReviewRequest({
    practitionerDataV3: practitioners,
  }).transformedPractitioners as Provider[];

  // handle delete exception for V3
  const handleDeleteExceptionV3 = async ({ eventId, locationId, providerId, eventType }: OnDeleteExceptionParams) => {
    setIsFormSaving(true);
    try {
      const isProviderException = eventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT;
      const entityId = isProviderException ? providerId : locationId;
      const entityType = isProviderException ? ScheduleType.PROVIDER : ScheduleType.LOCATION;

      const entitySchedule = await SchedulerV3.APIs.ListSchedules({
        locationId,
        ids: [entityId],
      });

      const overrideUnAvailabilities = entitySchedule?.schedules[0].recurrenceRules?.override?.unAvailabilities || [];
      const overrideIndex = findOverrideIndexById(overrideUnAvailabilities, eventId);
      if (overrideIndex !== -1) {
        overrideUnAvailabilities.splice(overrideIndex, 1);
      }

      if (entitySchedule?.schedules?.length) {
        const newOverrides = {
          ...entitySchedule?.schedules[0].recurrenceRules?.override,
          unAvailabilities: overrideUnAvailabilities,
        };
        await updateScheduleMutation.mutateAsync({
          id: entitySchedule?.schedules[0].id,
          locationId,
          type: entitySchedule?.schedules[0].type || entityType,
          recurrenceRules: {
            ...entitySchedule?.schedules[0].recurrenceRules,
            override: newOverrides,
          },
        });
      }
      alert.success(
        t('{{entityTypeName}} exception deleted successfully', { entityTypeName: getEntityTypeName(entityType) })
      );
      closeModal();
    } catch (err) {
      console.error(err);
      if (err) {
        setFormError('Something went wrong while deleting the exception');
        alert.error(t('Something went wrong while deleting the exception'));
      }
    } finally {
      setIsFormSaving(false);
    }
  };

  const createPayload = ({
    entityId,
    entityType,
    entitySchedule,
    locationId,
    formValues,
    operation,
  }: CreateUpdateExceptionPayloadParams) => {
    const { recurrenceRules } = entitySchedule?.schedules[0] || {};

    const newOverride = {
      availabilities: recurrenceRules?.override?.availabilities || [],
      un_availabilities: recurrenceRules?.override?.unAvailabilities || [],
    };

    const startTime = formValues.isAllDay ? '00:00:00' : formValues.startTime;
    const endTime = formValues.isAllDay ? '23:59:00' : formValues.endTime;
    const duration = calculateDuration(startTime, endTime);
    const locationTimeZone = getLocationTimeZone(locationId);
    const rrule = getRRuleStringFromDateRange(
      {
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        startTime,
      },
      locationTimeZone
    );

    // Generate IDs only for new exceptions
    const exceptionEntry = {
      id: formValues.eventId || generateExceptionId(),
      name: formValues.name,
      rrule,
      duration,
    };

    if (operation === ExceptionOperations.REMOVE || operation === ExceptionOperations.UPDATE) {
      const unAvailabilityIndex = findOverrideIndexById(newOverride.un_availabilities, formValues.eventId || '');
      if (unAvailabilityIndex !== -1) {
        if (operation === ExceptionOperations.REMOVE) {
          newOverride.un_availabilities.splice(unAvailabilityIndex, 1); // Remove
        } else {
          newOverride.un_availabilities.splice(unAvailabilityIndex, 1, exceptionEntry); // Update
        }
      }
    } else {
      newOverride.un_availabilities.push(exceptionEntry); // Add
    }

    return {
      id: entityId,
      locationId,
      type: entityType,
      recurrenceRules: {
        ...recurrenceRules,
        override: newOverride,
      },
    };
  };

  const handleExceptions = async ({
    entityId,
    entityType,
    locationId,
    formValues,
    operation,
  }: HandleExceptionsParams) => {
    // Fetch latest schedule for entity
    const entitySchedule = await SchedulerV3.APIs.ListSchedules({ locationId, ids: [entityId] });

    const payload = createPayload({
      entityId,
      entityType,
      entitySchedule,
      locationId,
      formValues,
      operation,
    });

    if (operation === ExceptionOperations.REMOVE || operation === ExceptionOperations.UPDATE) {
      await updateScheduleMutation.mutateAsync(payload);
    } else {
      await createScheduleMutation.mutateAsync(payload);
    }
  };

  const handleOnSaveExceptionV3 = async (formValues: ExceptionFormValues) => {
    const entityType = getEntityType(formValues.eventType);
    const prevEntityType = getEntityType(configuredCalendarEvent?.calendarEventType || undefined);
    const prevLocationId = configuredCalendarEvent?.locationId || '';
    const prevProviderId = configuredCalendarEvent?.providerId;
    const locationId = formValues.locationId || '';
    const prevEntityId = (prevEntityType === ScheduleType.PROVIDER ? prevProviderId : prevLocationId) || '';
    const entityId = entityType === ScheduleType.PROVIDER ? formValues.providerId : formValues.locationId;

    setIsFormSaving(true);

    try {
      if (prevEntityId && prevEntityId !== entityId) {
        // Remove the exception from previous entity's schedule overrides
        await handleExceptions({
          entityId: prevEntityId,
          entityType: prevEntityType,
          locationId: prevLocationId,
          formValues,
          operation: ExceptionOperations.REMOVE,
        });

        // Create new exception in the selected entity's schedule overrides with same exception ID which is removed above
        await handleExceptions({
          entityId,
          entityType,
          locationId,
          formValues,
          operation: ExceptionOperations.ADD_NEW_WITH_ID,
        });
      } else {
        // Create or update the exception in the selected entity's schedule overrides
        await handleExceptions({
          entityId,
          entityType,
          locationId,
          formValues,
          operation: formValues.eventId ? ExceptionOperations.UPDATE : ExceptionOperations.ADD_NEW,
        });
      }

      alert.success(
        formValues.eventId
          ? t('{{entityTypeName}} exception updated successfully', { entityTypeName: getEntityTypeName(entityType) })
          : t('{{entityTypeName}} exception created successfully', { entityTypeName: getEntityTypeName(entityType) })
      );
      closeModal();
    } catch (err) {
      console.error(err);
      alert.error(t('Something went wrong'));
    } finally {
      setIsFormSaving(false);
    }
  };

  const handleOnDelete = async ({ eventId, locationId, providerId, eventType }: OnDeleteExceptionParams) => {
    if (eventId) {
      // @TODO: Update `handleExceptions` and use it to delete exception
      await handleDeleteExceptionV3({ eventId, locationId, providerId, eventType });
    }
  };

  const handleOnSave = (formValues: ExceptionFormValues) => {
    handleOnSaveExceptionV3(formValues);
  };

  return (
    <OutOfOfficeEventsForm
      closeModal={closeModal}
      eventType={eventType}
      providerList={providersListData}
      selectedLocationIds={selectedMultiLocationIds}
      selectedDate={selectedCalendarDate as string}
      isLoading={isFormSaving}
      formError={formError}
      onDelete={handleOnDelete}
      onSave={handleOnSave}
    />
  );
};
