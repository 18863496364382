import { FC, useMemo } from 'react';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useDigitalFormsLocationsContext, DigitalFormsLocationsWrapper } from '@frontend/digital-forms-scope';
import {
  useDigitalFormsSendingExperience,
  FormsSendingExperienceTypes,
} from '@frontend/digital-forms-sending-experience';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { FormsPromotionActionButtonWrapper } from '@frontend/self-upgrade';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton, Button } from '@frontend/design-system';

export interface SendFormButtonProps {
  label?: string;
  context: {
    locationId: string;
    person: PersonTypes.Person;
  };
  trackingIdSuffix?: string;
  onClick?: () => void;
  buttonLabel?: string;
}

const EMPTY_LOCATION_IDS: string[] = [];

const SendFormButtonContent: FC<SendFormButtonProps> = ({ label, context, trackingIdSuffix, buttonLabel }) => {
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();
  const { isValidFormsLocation } = useDigitalFormsLocationsContext();
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource, isLoading: isLoadingLocationsByDataSource } =
    DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(context.person.SourceID, context.person.ClientLocationID);

  const minimalPersonData = useMemo<FormPacketSelectorTypes.MinimalPersonData>(() => {
    return {
      id: context.person.PersonID,
      firstName: context.person.FirstName,
      lastName: context.person.LastName,
    };
  }, [context.person]);

  const locationIds = useMemo(() => {
    if (isLoadingLocationsByDataSource) {
      return EMPTY_LOCATION_IDS;
    }

    if (!locations || locations.length === 0) {
      if (!context.locationId) {
        return EMPTY_LOCATION_IDS;
      }
      return [context.locationId];
    }

    return locations.map((location) => location.locationId);
  }, [locations, context.locationId]);

  function shouldDisable() {
    if (!isValidFormsLocation(context.locationId)) {
      return true;
    }

    const person = context.person;
    const hasEmail = !!person.Email;
    const hasHomePhone = !!person.HomePhone;
    const hasMobilePhone = !!person.MobilePhone;
    const hasWorkPhone = !!person.WorkPhone;

    if (!hasEmail && !hasHomePhone && !hasMobilePhone && !hasWorkPhone) {
      return true;
    }

    return false;
  }
  return (
    <>
      {buttonLabel ? (
        <Button
          variant='primary'
          onClick={sendFormsModalControls.openModal}
          disabled={shouldDisable()}
          trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
        >
          {buttonLabel}
        </Button>
      ) : (
        <ActionButton
          {...sendFormsModalControls.triggerProps}
          trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
          label={label}
          disabled={shouldDisable()}
        >
          <Icon name='forms' size={16} />
        </ActionButton>
      )}

      <DigitalFormsSendingExperience
        origin={FormsSendingExperienceTypes.SendFormsOrigin.CONTACTS}
        locationIds={locationIds}
        sendFormsModalControls={sendFormsModalControls}
        person={minimalPersonData}
      />
    </>
  );
};

export const SendFormButton: FC<SendFormButtonProps> = (props) => {
  return (
    <DigitalFormsLocationsWrapper>
      <SendFormButtonContent {...props} />
    </DigitalFormsLocationsWrapper>
  );
};

export interface SendFormActionProps {
  label: string;
  context: {
    locationId: string;
    person: PersonTypes.Person;
  };
  trackingIdSuffix?: string;
  onClick?: () => void;
}

const SendFormActionContent: FC<SendFormActionProps> = ({ label, context, trackingIdSuffix }) => {
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();
  const { isValidFormsLocation, refreshFormsLocationValidity } = useDigitalFormsLocationsContext();
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource, isLoading: isLoadingLocationsByDataSource } =
    DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(context.person.SourceID, context.person.ClientLocationID);

  const minimalPersonData = useMemo<FormPacketSelectorTypes.MinimalPersonData>(() => {
    return {
      id: context.person.PersonID,
      firstName: context.person.FirstName,
      lastName: context.person.LastName,
    };
  }, [context.person]);

  const locationIds = useMemo(() => {
    if (isLoadingLocationsByDataSource) {
      return EMPTY_LOCATION_IDS;
    }

    if (!locations || locations.length === 0) {
      if (!context.locationId) {
        return EMPTY_LOCATION_IDS;
      }
      return [context.locationId];
    }

    return locations.map((location) => location.locationId);
  }, [locations, context.locationId]);

  const isDisabled = useMemo(() => {
    const person = context.person;
    const hasEmail = !!person.Email;
    const hasHomePhone = !!person.HomePhone;
    const hasMobilePhone = !!person.MobilePhone;
    const hasWorkPhone = !!person.WorkPhone;

    if (!hasEmail && !hasHomePhone && !hasMobilePhone && !hasWorkPhone) {
      return true;
    }

    return false;
  }, [context.person]);

  const showFeaturePromotion = useMemo(() => {
    return !isValidFormsLocation(context.locationId) && !isDisabled;
  }, [isDisabled, isValidFormsLocation, context.locationId]);

  return (
    <>
      <FormsPromotionActionButtonWrapper
        onUpgradeSuccess={refreshFormsLocationValidity}
        locationId={context.locationId}
        showFeaturePromotion={showFeaturePromotion}
        source={trackingIdSuffix}
      >
        <AdaptoActions.Action
          {...sendFormsModalControls.triggerProps}
          trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
          label={label}
          disabled={isDisabled}
          icon='forms'
        />
      </FormsPromotionActionButtonWrapper>

      <DigitalFormsSendingExperience
        origin={FormsSendingExperienceTypes.SendFormsOrigin.CONTACTS}
        locationIds={locationIds}
        sendFormsModalControls={sendFormsModalControls}
        person={minimalPersonData}
      />
    </>
  );
};

export const SendFormAction: FC<SendFormActionProps> = (props) => {
  return (
    <DigitalFormsLocationsWrapper>
      <SendFormActionContent {...props} />
    </DigitalFormsLocationsWrapper>
  );
};
