import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const itemContainerStyle = css`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.neutral10};
  padding: ${theme.spacing(2)};
`;

export const iconStyle = css`
  margin-right: ${theme.spacing(1.2)};
`;

export const displayFixStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const centerAlignmentStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
