import { useEffect, useMemo, useRef } from 'react';
import { useMatchRoute, useSearch } from '@tanstack/react-location';
import {
  IntakeFormQueries,
  prepareIntakeFormStoreInfoFromLocationList,
  useIntakeFormShallowStore,
  useIntakeFormStore,
} from '@frontend/api-intake-form';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { useAlert } from '@frontend/design-system';
import { INTAKE_FORM_PATHS, INTAKE_FORM_PATH_V2, INTAKE_FORM_V2_FEATURE_FLAG, NWX_DASHBOARD_PATH } from '../constants';

// Custom Hook used to determine whether a redirect to the onboarding intake form should
// occur for the current location.
export const useOnboardingRedirect = ({ isLocationReady }: { isLocationReady: boolean }) => {
  const { t } = useTranslation('onboarding');
  const alert = useAlert();
  const user = getUser();
  const urlSearchParam = useSearch<{ Search: { v: number } }>();
  const matchRoute = useMatchRoute();
  const useIsLoaderShownRef = useRef<boolean>(false);
  const { selectedLocationIds, selectedGroupId } = useAppScopeStore();
  const { setIntakeFormLocationListInfo, setLocationIdForQuery } = useIntakeFormShallowStore(
    'setIntakeFormLocationListInfo',
    'setLocationIdForQuery'
  );

  const selectedParentId = selectedGroupId;
  const selectedLocationId = selectedParentId ?? selectedLocationIds?.[0] ?? '';
  const isParentLocation = !!selectedParentId;
  const isIntakeFormV1Route = !!matchRoute({ to: INTAKE_FORM_PATHS.root, fuzzy: true });
  const isIntakeFormV2Route = !!matchRoute({ to: INTAKE_FORM_PATH_V2 });

  const intakeFormLocationsQuery = IntakeFormQueries.useIntakeFormLocationsQuery(selectedLocationId);

  useEffect(() => {
    const hasErrorInIntakeFormLocations = intakeFormLocationsQuery.data?.some(({ error }) => !!error);

    // Show alert only in single location or api failure
    if (intakeFormLocationsQuery.isError || (!isParentLocation && hasErrorInIntakeFormLocations)) {
      alert.error(t('Failed to retrieve onboarding form(s) meta data.'));
    }

    // Log error to sentry in all error cases
    if (intakeFormLocationsQuery.isError || hasErrorInIntakeFormLocations) {
      sentry.log({
        message: 'Failed to retrieve onboarding forms meta data',
        data: {
          intakeFormLocationsResponse: intakeFormLocationsQuery.data,
          selectedLocationId,
          isParentLocation,
          error: intakeFormLocationsQuery.error,
        },
        category: 'onboarding',
      });
    }
  }, [intakeFormLocationsQuery.isError, intakeFormLocationsQuery.data, isParentLocation, selectedLocationId]);

  const intakeFormLocationListInfo = useMemo(() => {
    if (intakeFormLocationsQuery.isLoading) return { isShowIntakeForm: false, isSingleSoftwareOnlyLocation: false };
    const listInfo = prepareIntakeFormStoreInfoFromLocationList({
      intakeFormLocations: intakeFormLocationsQuery.data ?? [],
      userEmail: user?.username,
      isParentLocation,
      isWeaveUser: isWeaveUser(),
    });
    useIntakeFormStore.reset();
    setLocationIdForQuery(selectedLocationId);
    setIntakeFormLocationListInfo(listInfo);
    return listInfo;
  }, [intakeFormLocationsQuery.data, intakeFormLocationsQuery.isLoading, selectedLocationId]);

  const isLoadingOnboardingInfo = intakeFormLocationsQuery.isLoading || !isLocationReady;
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isIntakeFormV2Enabled =
    getFeatureFlagValue(INTAKE_FORM_V2_FEATURE_FLAG) && intakeFormLocationListInfo.isSingleSoftwareOnlyLocation;
  let onboardingRedirectUrl = '';
  if (!isLoadingOnboardingInfo) {
    if (
      intakeFormLocationListInfo.isShowIntakeForm &&
      (isIntakeFormV2Enabled ? !isIntakeFormV2Route : !isIntakeFormV1Route)
    ) {
      onboardingRedirectUrl = isIntakeFormV2Enabled ? INTAKE_FORM_PATH_V2 : INTAKE_FORM_PATHS.root;
    } else if (!intakeFormLocationListInfo.isShowIntakeForm && (isIntakeFormV1Route || isIntakeFormV2Route)) {
      onboardingRedirectUrl = NWX_DASHBOARD_PATH;
    }
  }

  const isHomeDashboardV2Page = !!matchRoute({ to: NWX_DASHBOARD_PATH }) && urlSearchParam.v === 2;

  // This is a workaround to prevent the loader from showing up when the user is already on the dashboard page
  useEffect(() => {
    if (isHomeDashboardV2Page && !isLoadingOnboardingInfo) {
      useIsLoaderShownRef.current = true;
    }
  }, [isHomeDashboardV2Page, isLoadingOnboardingInfo]);

  return {
    shouldShowOnboardingLoader: !useIsLoaderShownRef.current && isHomeDashboardV2Page && isLoadingOnboardingInfo,
    onboardingRedirectUrl,
  };
};
