import { i18next } from '@frontend/i18n';
import { StepData } from '@frontend/design-system';
import { CollectPaymentMultiFlow, CollectPaymentMultiSteps } from '../collect-payment-multi.types';

export const PAYMENT_MULTI_STEPS = {
  select: 'select-invoice',
  create: 'create-invoice',
  preview: 'preview-invoice',
  paymentMethods: 'payment-flow-list',
  shareInMessage: 'share-in-message',
  shareInEmail: 'share-in-email',
  cardOnFile: 'card-on-file',
  manualCardEntry: 'manual-card-entry',
  sunbit: 'send-sunbit-link',
  selectTerminal: 'reader-selection',
  collectTerminal: 'collect-reader-payment',
  selectPaymentsTemplate: 'select-payments-template',
  registerTerminal: 'register-terminal',
} as const satisfies { [K: string]: CollectPaymentMultiSteps };

export const PAYMENT_MULTI_STEP_LABELS = {
  [PAYMENT_MULTI_STEPS.select]: i18next.t('Select Payment Request', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.create]: i18next.t('Create Payment Request', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.preview]: i18next.t('Preview Payment Request', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.paymentMethods]: i18next.t('Take Payment', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.shareInMessage]: i18next.t('Share in Message', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.shareInEmail]: i18next.t('Share in Email', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.cardOnFile]: i18next.t('Card on File', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.manualCardEntry]: i18next.t('Manual Card Entry', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.sunbit]: i18next.t('Sunbit', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.selectTerminal]: i18next.t('Pay at Terminal', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.collectTerminal]: i18next.t('Pay at Terminal', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.selectPaymentsTemplate]: i18next.t('Select a Payments Template', { ns: 'payments' }),
  [PAYMENT_MULTI_STEPS.registerTerminal]: i18next.t('Register Terminal', { ns: 'payments' }),
  'card-and-ach-on-file': i18next.t('Card/ACH on File', { ns: 'payments' }),
} as Record<CollectPaymentMultiSteps | 'card-and-ach-on-file', string>;

export const getStepsByFlow = (flow: CollectPaymentMultiFlow): CollectPaymentMultiSteps[] => {
  const allSteps: CollectPaymentMultiSteps[] = [
    PAYMENT_MULTI_STEPS.select,
    PAYMENT_MULTI_STEPS.preview,
    PAYMENT_MULTI_STEPS.create,
    PAYMENT_MULTI_STEPS.paymentMethods,
    PAYMENT_MULTI_STEPS.shareInMessage,
    PAYMENT_MULTI_STEPS.shareInEmail,
    PAYMENT_MULTI_STEPS.cardOnFile,
    PAYMENT_MULTI_STEPS.manualCardEntry,
    PAYMENT_MULTI_STEPS.sunbit,
    PAYMENT_MULTI_STEPS.selectTerminal,
    PAYMENT_MULTI_STEPS.registerTerminal,
    PAYMENT_MULTI_STEPS.collectTerminal,
  ];

  switch (flow) {
    case 'select':
      return allSteps;
    case 'create':
      return allSteps.filter((step) => !['select-invoice', 'preview-invoice'].includes(step));
    case 'pay':
      return allSteps.filter((step) => !['select-invoice', 'preview-invoice', 'create-invoice'].includes(step));
    case 'insert':
      return ['select-invoice', 'create-invoice', 'preview-invoice', 'select-payments-template'];
  }
};

export const getStepDataByFlow = (flow: CollectPaymentMultiFlow, currentSteps: StepData[]): StepData[] => {
  const ids = getStepsByFlow(flow);

  return ids.map((id) => {
    const found = currentSteps.find((stepCard) => stepCard.id === id);

    return (
      found ?? {
        id,
        label: PAYMENT_MULTI_STEP_LABELS[id],
        isValid: false,
        isComplete: false,
        childSteps: [],
      }
    );
  });
};

const paymentMethodSteps: CollectPaymentMultiSteps[] = [
  PAYMENT_MULTI_STEPS.shareInMessage,
  PAYMENT_MULTI_STEPS.cardOnFile,
  PAYMENT_MULTI_STEPS.manualCardEntry,
  PAYMENT_MULTI_STEPS.selectTerminal,
  PAYMENT_MULTI_STEPS.sunbit,
];

const paymentCollectSteps: CollectPaymentMultiSteps[] = [
  PAYMENT_MULTI_STEPS.cardOnFile,
  PAYMENT_MULTI_STEPS.manualCardEntry,
  PAYMENT_MULTI_STEPS.selectTerminal,
  PAYMENT_MULTI_STEPS.collectTerminal,
];

export const isPaymentMethodStep = (step: string) => paymentMethodSteps.includes(step as CollectPaymentMultiSteps);

export const isPaymentCollectStep = (step: string) => paymentCollectSteps.includes(step as CollectPaymentMultiSteps);
