import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { ListDevice, ModelInfo } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { useTranslation } from '@frontend/i18n';
import { Button, Modal } from '@frontend/design-system';
import { Layout } from './layout';

interface Props {
  onClose: () => void;
  device?: ListDevice;
  duplicate?: boolean;
  children?: ReactNode;
  lineKeys: LineKeysTypes.GetLineKeysType['output']['lineKeys'] | undefined;
  model: ModelInfo | undefined;
  onBack: () => void;
}
export const PreviewLayoutModal = ({ model, device, lineKeys, duplicate, onClose, onBack, children }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <>
      <Modal.Header onClose={onClose}>
        <Button
          iconName='back'
          onClick={onBack}
          variant='secondary'
          css={css`
            border: 0;
          `}
        >
          {duplicate ? t('Back to Device Selection') : t('Back to Devices')}
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Layout isPreviewEnabled model={model} device={device} lineKeys={lineKeys} />
      </Modal.Body>
      {children}
    </>
  );
};
