import { EditableText } from '@frontend/editable-text';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { FieldProps, Stepper, Text } from '@frontend/design-system';
import { stepValueContainerStyles } from '../styles';
import { Step, StepId } from '../types';

type Props = {
  fieldProps: FieldProps<
    {
      title: {
        type: 'text';
      };
    },
    'title'
  >;
  next: (stepId: StepId) => void;
  readOnly: boolean;
};

export const useTitleStep = ({ fieldProps, next, readOnly }: Props): Step => {
  const { t } = useTranslation('bulk-messaging');

  const id: StepId = 'title';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Name Your Campaign')}</Stepper.Title>
        <Stepper.Content>
          <EditableText
            fieldProps={fieldProps}
            helperText={t('Required')}
            trackingId={`${BulkEmailPrefixes.Editor}-campaign-name`}
          />
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none' disabled={readOnly}>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{fieldProps.value ? fieldProps.value : t('Name Your Campaign')}</Text>
      </div>
    ),
    readValue: <EditableText fieldProps={fieldProps} helperText={t('Required')} />,
  };
};
