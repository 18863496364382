import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { TableColumnConfig, TableLoadingSkeleton } from '@frontend/design-system';
import { ProceduresColumn } from '..';
import { ColumnHeaderInfoTip, InfoTipPopover, LocationChip, SortType, TableActions, UserCard } from '../..';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatters } from '../../../utils';
import { usePatientsLastContacted, usePracticeAnalyticsShallowStore } from '../hooks';

type SubViewTableColConfig = {
  actionsTrackingIdBase?: string;
  hasProcedures?: boolean;
  middleColumns: TableColumnConfig<PracticeAnalyticsTypes.PatientInfo>[];
  patientsLastContacted: ReturnType<typeof usePatientsLastContacted>;
  predefinedLocationId?: string;
};

export const useSubViewTableColConfig = ({
  actionsTrackingIdBase,
  hasProcedures,
  middleColumns,
  patientsLastContacted,
  predefinedLocationId,
}: SubViewTableColConfig): TableColumnConfig<PracticeAnalyticsTypes.PatientInfo>[] => {
  const { t } = useTranslation('analytics');
  const { filters, isDemoAccount } = usePracticeAnalyticsShallowStore('filters', 'isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });
  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
  const { isLoading: isLoadingLastContacted, lastContactedDates } = patientsLastContacted;

  return [
    {
      Header: t('Patient Name'),
      headerLabel: t('Patient Name'),
      accessor: ({ FirstName, LastName, id, locationId }) =>
        JSON.stringify({
          firstName: FirstName,
          lastName: LastName,
          locationId,
          userId: id,
        }),
      cellRenderer: (value: string) => (
        <UserCard {...JSON.parse(value)} openProfileOnClick={!isDemoAccount} showOnlyName />
      ),
      id: 'name',
      width: 280,
    },
    {
      accessor: ({ locationId = predefinedLocationId }) => locationId,
      cellRenderer: (locationId: string) => {
        return locationId ? (
          <LocationChip locationName={locationNames[locationId] || locationId} maxWidth={180} />
        ) : (
          '-'
        );
      },
      disableSortBy: true,
      Header: t('Location'),
      id: 'locationName',
      omit: !multipleLocationsSelected,
      width: 220,
    },
    {
      Header: t('Phone Number'),
      headerLabel: t('Phone Number'),
      accessor: ({ HomePhone, MobilePhone }) => MobilePhone || HomePhone,
      cellRenderer: (phoneNumber: string) => (phoneNumber ? formatPhoneNumber(phoneNumber) : '-'),
      id: 'phone-number',
      width: 180,
    },
    ...middleColumns,
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Last Contacted')}
          infoTip={
            <InfoTipPopover>{t('Date and Time of last outbound answered call to patient phone number')}</InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Last Contacted'),
      accessor: ({ id }) => formatters.date.format(lastContactedDates[id]),
      cellRenderer: (lastContacted: string) => (isLoadingLastContacted ? <TableLoadingSkeleton /> : lastContacted),
      id: 'lastContacted',
      width: 180,
    },
    {
      Header: t('$ Value'),
      headerLabel: t('$ Value'),
      accessor: ({ productionAmount }) => productionAmount,
      cellRenderer: (productionAmount: number) => formatters.currency.format(productionAmount),
      id: 'productionAmount',
      width: 120,
      startingSortBy: 'desc' as SortType,
    },
    ...(hasProcedures
      ? [
          {
            Header: t('Procedure Codes'),
            headerLabel: t('Procedure Codes'),
            accessor: ({ procedures }: PracticeAnalyticsTypes.PatientInfo) => procedures,
            cellRenderer: (procedures: PracticeAnalyticsTypes.PatientInfo['procedures']) => (
              <ProceduresColumn procedures={procedures} />
            ),
            id: 'procedures',
            width: 180,
          },
        ]
      : []),
    {
      disableSortBy: true,
      Header: t('Actions'),
      headerAlign: 'right',
      headerLabel: t('Actions'),
      accessor: (patient) => patient,
      cellRenderer: (patient) => {
        const phoneNumber = patient.MobilePhone || patient.HomePhone;
        return (
          <TableActions
            isDemoAccount={isDemoAccount}
            personId={patient.id}
            personName={patient.FirstName}
            phoneNumber={phoneNumber}
            trackingIdBase={actionsTrackingIdBase}
          />
        );
      },
      id: 'actions',
      sticky: 'right',
      width: 110,
    },
  ];
};
