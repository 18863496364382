import { DataSourcesApi } from '@frontend/api-data-sources';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';

export const useIsIntegratedOffice = () => {
  const { selectedLocationIds, selectedGroupId } = useAppScopeStore();
  const selectedLocationId = selectedLocationIds[0];
  const parentLocationId = selectedGroupId;

  const { data, isLoading } = useScopedQuery({
    queryKey: `use-is-integrated-office-${parentLocationId}-${selectedLocationId}`,
    queryFn: async () => {
      try {
        const { SyncApps } = await DataSourcesApi.getAccessibleIntegrations(parentLocationId || selectedLocationId);
        return SyncApps || [];
      } catch {
        // If a parent location ID exists and the user lacks access to the parent location,
        // the above call will fail. In such cases, we should attempt the call with the child location ID.
        if (parentLocationId) {
          const { SyncApps } = await DataSourcesApi.getAccessibleIntegrations(selectedLocationId);
          return SyncApps || [];
        }
        return [];
      }
    },
    enabled: !!selectedLocationId,
    cacheTime: 1000 * 60 * 5,
  });

  const isIntegratedOffice = data?.some((syncApp) => syncApp.SourceType === 'Integration');

  return {
    isIntegratedOffice,
    isLoading,
  };
};
