import { useRef } from 'react';
import { css } from '@emotion/react';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { BannerNotification, Chip, ContentLoader, CopyToClipboardButton, Heading, Text } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { LargeInfo } from '../../atoms/large-info';
import { StyledStep } from '../../atoms/styled-step';
import { PaymentMethodsButtons } from './payment-methods-buttons';
import { PaymentMethodsStepBodyProps } from './payment-methods.types';
import { PaymentSummary } from './PaymentSummary';

const styles = {
  stepOverrides: css`
    .step__body {
      padding: 0;
      margin: ${theme.spacing(1)} 0 0;
    }
  `,
  summary: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
  divider: css`
    display: flex;
    align-items: center;
  `,
  paymentMethods: css`
    padding: ${theme.spacing(0)} ${theme.spacing(3)};
    align-content: stretch;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ${theme.spacing(2)};

    & > button {
      height: ${theme.spacing(8)};
    }
  `,
  paymentMethodsNew: css`
    background: ${theme.colors.neutral5};
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    justify-content: center;
  `,
  paymentsHeadingLegacy: css`
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
  `,
  footer: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
    justify-content: space-between;
  `,
  alreadyPaidBanner: {
    container: css`
      background-color: ${theme.colors.white};
    `,
    banner: css`
      margin: ${theme.spacing(2, 2, 0, 2)};
    `,
  },
};

export const PaymentMethodsBody = ({ onClickBack, onClickPrimary, back }: PaymentMethodsStepBodyProps) => {
  const { t } = useTranslation('payments');

  const {
    invoice,
    invoiceQuery: { isLoading },
    invoiceIsPaid,
    newRequestUI,
  } = useCollectPaymentMultiContext();

  const { getLocationName, allLocations } = useMultiQueryUtils();

  const parentRef = useRef<HTMLDivElement>(null);

  const { laterTrackingId, copyTrackingId } = useScopedTrackingIds({
    laterTrackingId: 'collect-later-btn',
    copyTrackingId: 'copy-btn',
  });

  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.paymentMethods}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.paymentMethods]}
      isValid={true}
      css={styles.stepOverrides}
      footer={{
        secondary: () => (
          <CopyToClipboardButton
            variant='tertiary'
            textToCopy={invoice?.links.payment ?? ''}
            tooltipText={t('Copy link')}
            tooltipSuccessText={t('Link copied!')}
            hasSuccessTooltip
            hasIcon={newRequestUI}
            icon={() => <Icon name='link-small' color={invoiceIsPaid ? 'disabled' : 'primary'} size={24} />}
            trackingId={copyTrackingId}
            disabled={invoiceIsPaid}
          >
            {newRequestUI ? (
              <Text weight='bold' color={invoiceIsPaid ? 'disabled' : 'primary'}>
                {t('Copy Link')}
              </Text>
            ) : (
              t('Copy payment link')
            )}
          </CopyToClipboardButton>
        ),
        primary: {
          children: invoiceIsPaid ? t('Close') : t('Collect Later'),
          trackingId: laterTrackingId,
          variant: 'secondary',
        },
        back,
        onClickPrimary: onClickPrimary,
        onClickBack: onClickBack,
      }}
    >
      {(isLoading || !invoice) && <ContentLoader show={true} />}

      {invoice && (
        <div>
          {invoiceIsPaid && (
            <div css={styles.alreadyPaidBanner.container}>
              <BannerNotification
                css={styles.alreadyPaidBanner.banner}
                fullWidth
                status='success'
                message={t('This payment has already been made successfully')}
              />
            </div>
          )}
          {newRequestUI ? (
            <PaymentSummary invoice={invoice} />
          ) : (
            <>
              <div css={styles.summary}>
                <LargeInfo title={t('Customer')} info={invoice?.person.name} />
                <div css={styles.amount} ref={parentRef}>
                  <LargeInfo title={t('Amount Owed')} info={formatCentsToCurrency(invoice?.billedAmount)} />
                  <LargeInfo title={t('Payment Due')} info={formatDate(invoice?.billedAt, 'MMMM D, YYYY')} />
                  {allLocations.length > 1 && (
                    <LargeInfo
                      title={t('Location')}
                      info={
                        <Chip.SingleChip containerRef={parentRef} isResponsive>
                          {getLocationName(invoice?.locationId)}
                        </Chip.SingleChip>
                      }
                    />
                  )}
                </div>
              </div>
              <div css={styles.paymentsHeadingLegacy}>
                <Divider color={theme.colors.neutral20} css={{ margin: `0 0 ${theme.spacing(2)}` }} />
                <Heading level={3}>{t('Select Payment Method')}</Heading>
              </div>
            </>
          )}

          <section css={[styles.paymentMethods, newRequestUI && styles.paymentMethodsNew]}>
            <PaymentMethodsButtons />
          </section>
        </div>
      )}
    </StyledStep>
  );
};
