import { useState, memo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, IconButton, CaretDownIconSmall, PlusIcon } from '@frontend/design-system';
import { AddButton } from '../common/add-button/add-button';
import { ChatListItemShimmer } from '../common/chat-list-item-shimmer/chat-list-item-shimmer';
import { ConversationListItem } from './conversation-list-item';

interface Props {
  title: string;
  conversationIds: string[];
  onAddNewButtonClick?: () => void;
  addNewButtonTrackingId?: string;
  addNewButtonLabel: string;
  expandCollapseButtonTrackingId?: string;
  isLoading: boolean;
}

export const ConversationCategory = memo(
  ({
    title,
    onAddNewButtonClick,
    conversationIds,
    addNewButtonTrackingId,
    addNewButtonLabel,
    expandCollapseButtonTrackingId,
    isLoading,
  }: Props) => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
      <section css={sectionStyle} key={`chat-category-section-${title}`}>
        <div css={containerStyle}>
          <div css={chevronContainerStyle}>
            {!!conversationIds.length && (
              // TODO: we are using a deprecated IconButton component, will be replacing it with the new one in future commits
              <IconButton
                size='xsmall'
                label=''
                onClick={() => setIsExpanded((prev) => !prev)}
                trackingId={expandCollapseButtonTrackingId}
              >
                <CaretDownIconSmall css={[caretStyle, isExpanded && caretTransition]} size={12} />
              </IconButton>
            )}
            <Text css={titleStyle}>{title}</Text>
          </div>
          <IconButton
            label={addNewButtonLabel}
            onClick={onAddNewButtonClick}
            size='small'
            trackingId={addNewButtonTrackingId}
          >
            <PlusIcon size={16} css={css({ path: { fill: theme.colors.neutral90 } })} />
          </IconButton>
        </div>
        {isLoading && <ChatListItemShimmer />}
        {isExpanded &&
          !isLoading &&
          conversationIds.map((conversationId) => (
            <ConversationListItem conversationId={conversationId} key={`chat-list-item-${conversationId}`} />
          ))}
        {onAddNewButtonClick && (
          <AddButton
            onClick={onAddNewButtonClick}
            title={addNewButtonLabel}
            trackingId={`team-chat-2.0-add-new-${title}-button`}
            css={{
              padding: theme.spacing(0, 1.5, 0, 0.75),
              margin: theme.spacing(0.5, 0),
              width: '100%',
              textAlign: 'left',
              justifyContent: 'flex-start',
              span: { gap: theme.spacing(0.5) },
              svg: { marginRight: theme.spacing(0.5) },
            }}
          />
        )}
      </section>
    );
  }
);
ConversationCategory.displayName = 'ConversationCategory';

const sectionStyle = css({ padding: theme.spacing(0, 0.5) });

const containerStyle = css({ display: 'flex', alignItems: 'center', justifyContent: 'space-between' });

const chevronContainerStyle = css({
  gap: theme.spacing(1),
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  padding: theme.spacing(0.25, 0.5),
  margin: theme.spacing(0.5, 0),
});

const titleStyle = css({ lineHeight: 1, color: theme.colors.neutral90, whiteSpace: 'nowrap' });

const caretStyle = css({ transition: 'transform 300ms', transform: 'rotate(180deg)' });

const caretTransition = css({ transform: 'rotate(0deg)' });
