import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props extends CommonHTMLAttributes {
  text: string;
}

export const InfoText = ({ text, ...rest }: Props) => {
  return (
    <div css={containerStyle} {...rest}>
      <Icon name='info-badge' color='primary' css={{ minWidth: 20 }} />
      <Text size='small'>{text}</Text>
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 2),
  background: theme.colors.primary5,
  borderRadius: theme.borderRadius.small,
  border: `1px solid ${theme.colors.primary50}`,
});
