import { useState } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { NakedButton, Text, Truncated, useModalControl } from '@frontend/design-system';
import { OutOfOfficeEventCardModal } from '../modals/out-of-office-event-card-modal';
import { PROVIDER_COLUMN_GAP, PROVIDER_COLUMN_MIN_WIDTH, SCROLLABLE_BUTTON_WIDTH } from './constants';
import { CalendarViewOffices, EventData, ProviderEventType } from './types';
import { getDurationFromHours, getEventType, isAllDayEvent, moveUnassignedProviderHeading } from './utils';

type EventsContainerProps = {
  data: CalendarViewOffices;
  isExpanded: boolean;
};

type ProviderColumnProps = {
  isExpanded: boolean;
  allDayOfficeEventsCount: number;
  providersData: ProviderEventType[];
};

type AllDayOutEventsProps = {
  data: CalendarViewOffices;
  gridTemplateColumns: string;
};
export const AllDayOutEvents = ({ data, gridTemplateColumns }: AllDayOutEventsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <AnimatePresence>
      <motion.section
        css={styles.container}
        initial={{ height: 0 }}
        animate={{ height: isExpanded ? 'auto' : 35 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        exit={{ height: 0 }}
        key={'container'}
      >
        <motion.div
          key={isExpanded ? 'expanded' : 'collapsed'}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.5 }}
          transition={{ duration: 1, ease: 'linear' }}
          css={styles.expandButtonStickyContainer}
        >
          <NakedButton css={styles.expandButton} onClick={() => setIsExpanded(!isExpanded)}>
            <Icon name={isExpanded ? 'collapse-small' : 'expand-small'} />
          </NakedButton>
        </motion.div>
        <div css={styles.eventsLayout(gridTemplateColumns)}>
          <EventsContainer data={data} isExpanded={isExpanded} />
        </div>
        <div css={{ width: SCROLLABLE_BUTTON_WIDTH }} />
      </motion.section>
    </AnimatePresence>
  );
};

const EventsContainer = ({ data, isExpanded }: EventsContainerProps) => {
  return (
    <>
      {data?.map((office, idx) => {
        if (office.providers.length === 0) return null; // Do not show column if no providers

        const sortedProviders = moveUnassignedProviderHeading(office.providers);
        const allDayOfficeEvents = office.officeBreaks?.filter((officeEvent) =>
          isAllDayEvent(officeEvent.startHour, officeEvent.endHour)
        );

        return (
          <div key={`${office.officeName}-${idx}`} css={styles.eventsContainer}>
            <AnimatePresence>
              {isExpanded &&
                allDayOfficeEvents?.map((officeEvent) => {
                  return <EventCard key={officeEvent.eventId} event={officeEvent} />;
                })}
              <ProviderColumns
                isExpanded={isExpanded}
                providersData={sortedProviders}
                allDayOfficeEventsCount={allDayOfficeEvents?.length || 0}
              />
            </AnimatePresence>
          </div>
        );
      })}
    </>
  );
};

const ProviderColumns = ({ isExpanded, providersData, allDayOfficeEventsCount }: ProviderColumnProps) => {
  const { t } = useTranslation('schedule');

  return (
    <section css={providerColumnStyles.root}>
      {providersData.map((provider, idx) => {
        const allDayProviderEvents = provider.events.filter((event) => isAllDayEvent(event.startHour, event.endHour));
        const totalEvents = allDayProviderEvents.length + allDayOfficeEventsCount;

        return (
          <section key={provider.name + idx} css={providerColumnStyles.column}>
            <div css={providerColumnStyles.content}>
              {isExpanded ? (
                allDayProviderEvents.map((event) => {
                  return <EventCard key={event.id} event={event} provider={provider} />;
                })
              ) : (
                <motion.div
                  key='collapsed'
                  css={providerColumnStyles.summary}
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 30 }}
                  transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                  {totalEvents > 0 && (
                    <Text size='small' weight='bold'>
                      {t('{{count}} events', { count: totalEvents })}
                    </Text>
                  )}
                </motion.div>
              )}
            </div>
          </section>
        );
      })}
    </section>
  );
};

const EventCard = ({ event, provider }: { event: EventData; provider?: ProviderEventType }) => {
  const { getScopeName } = useAppScopeStore();
  const { triggerProps, modalProps } = useModalControl();
  const { id = '', locationId = '', name, startDate, endDate } = event;
  const { providerId, name: providerName } = provider || {};

  return (
    <>
      <motion.div
        key='expanded'
        {...triggerProps}
        css={styles.eventCard}
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -5 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        whileHover={{ scale: 1.001, boxShadow: theme.shadows.floating }}
      >
        <Icon name={providerId ? 'remove-small' : 'location-small'} css={{ minWidth: 'fit-content' }} />
        <Truncated as={Text} size='small' weight='bold'>
          {event.name}
        </Truncated>
      </motion.div>
      <OutOfOfficeEventCardModal
        id={id}
        title={name}
        eventType={getEventType(event)}
        duration={getDurationFromHours(event.startHour, event.endHour)}
        locationName={getScopeName(locationId)}
        startDate={startDate}
        endDate={endDate}
        modalProps={modalProps}
        providerId={providerId}
        providerName={providerName}
      />
    </>
  );
};

const styles = {
  container: css`
    border-top: 1px solid ${theme.colors.neutral20};
    background-color: ${theme.colors.neutral5};
    display: flex;
    gap: ${theme.spacing(2)};
    overflow-y: hidden;
  `,
  expandButtonStickyContainer: css`
    position: sticky;
    top: 0;
    left: 0;
    padding: ${theme.spacing(1, 0)};
    background: ${theme.colors.neutral5};
    z-index: ${theme.zIndex.higher};
  `,
  expandButton: css`
    display: flex;
    justify-content: flex-end;
    width: ${SCROLLABLE_BUTTON_WIDTH + 20}px; // Offset of 20px to match scrollable button margin
  `,
  eventsLayout: (gridTemplateColumns: string) => css`
    display: grid;
    grid-template-columns: ${gridTemplateColumns};
    grid-template-rows: auto;
    flex: 1;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(1, 0)};
  `,
  eventsContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `,
  eventCard: css`
    display: flex;
    align-items: center;
    border-left: 4px solid ${theme.colors.neutral60};
    background-color: ${theme.colors.neutral20};
    border-radius: 2px;
    padding: ${theme.spacing(0.25, 1)};
    gap: ${theme.spacing(0.5)};
    width: 100%;
    box-shadow: ${theme.shadows.light};
    cursor: pointer;
  `,
};

const providerColumnStyles = {
  root: css`
    display: flex;
    justify-content: space-around;
    gap: ${PROVIDER_COLUMN_GAP}px;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: ${theme.spacing(1)};
    min-width: ${PROVIDER_COLUMN_MIN_WIDTH}px;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    width: 100%;
  `,
  summary: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 35px;
      top: -8px; // Padding top of section
      left: -9px; // 8px gap between cards + 1px for border
      width: 1px;
      background: ${theme.colors.neutral20};
    }
  `,
};
