import { useTranslation } from '@frontend/i18n';
import { ViewInvoiceDetails, ViewInvoiceHeader } from '@frontend/payment-request-details';
import { ContentLoader } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { PreviewStepBodyProps } from './preview.types';

export const PreviewBody = ({ onGoBack, onClickSecondary }: PreviewStepBodyProps) => {
  const { invoice } = useCollectPaymentMultiContext();

  const { t } = useTranslation('payments');

  const { backTrackingId } = useScopedTrackingIds({
    backTrackingId: 'back-btn',
  });

  if (!invoice) {
    return <ContentLoader show={true}>{t('Loading...')}</ContentLoader>;
  }
  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.preview}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.preview]}
      footer={{
        primary: null,
        onClickBack: onGoBack,
        onClickSecondary: onClickSecondary,
        back: {
          trackingId: backTrackingId,
        },
        secondary: {
          children: t('Close'),
        },
      }}
    >
      <ViewInvoiceHeader invoice={invoice} />
      <ViewInvoiceDetails invoice={invoice} />
    </StyledStep>
  );
};
