import { FC, PropsWithChildren } from 'react';
import { PaymentsProviderWrapper } from '../common/PaymentsProviderWrapper';
import { PaymentsProfilePromotionCard } from './PaymentsProfilePromotionCard';

interface PaymentsProfilePromotionCardProps {
  locationId: string;
}

const PROMOTION_SOURCE = 'profile';

export const PaymentsProfilePromotionWrapper: FC<PropsWithChildren<PaymentsProfilePromotionCardProps>> = ({
  children,
  locationId,
}) => {
  return (
    <PaymentsProviderWrapper locationId={locationId} source={PROMOTION_SOURCE} fallback={children}>
      <PaymentsProfilePromotionCard />
    </PaymentsProviderWrapper>
  );
};
