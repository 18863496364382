import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { ManualCardEntryStepNavigationProps } from '../manual-card-entry.types';

export const ManualCardEntryGenericNavigation = ({ body: Body }: ManualCardEntryStepNavigationProps) => {
  const {
    onPaymentSuccess: onPaymentSuccessContext,
    multiStep: { goPrevStep, onComplete },
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => {
    goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
  }, []);

  const onPaymentSuccess = useCallback(() => {
    onPaymentSuccessContext?.();
  }, [onPaymentSuccessContext]);

  const onCompleteStep = useCallback(() => {
    onComplete();
  }, [onComplete]);

  return <Body onGoBack={onGoBack} onPaymentSuccess={onPaymentSuccess} onCompleteStep={onCompleteStep} />;
};
