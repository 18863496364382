import { APIResponse, getResponseData, http } from '@frontend/fetch';
import {
  DeleteFormResponse,
  FinishUploadResponse,
  UploadedForm,
  UploadedFormsResponse,
  FormsPaymentStatusResponse,
  DeleteFormRequest,
  UploadFormRequest,
  FormsSubmissionResponse,
  SetSubmissionConfirmationRequest,
} from './types';

export const uploadForm = async (req: UploadFormRequest) => {
  const { data } = await http
    .post<APIResponse<any>>('forms/v1/upload', req.form_data, {
      headers: !!req.location_id ? { 'location-id': req.location_id } : {},
    })
    .then(getResponseData);
  return data;
};

/**
 * @deprecated please use useGetUploadedFormsDetails from DigitalFormsQueries
 */
export const getUploadedForms = async (location_id?: string): Promise<UploadedForm[]> => {
  const { data } = await http.get<APIResponse<UploadedFormsResponse>>('forms/v1', {
    headers: !!location_id ? { 'location-id': location_id } : {},
  });
  return data.files;
};

export const deleteUploadedForm = async (req: DeleteFormRequest): Promise<DeleteFormResponse> => {
  const { data } = await http.delete<APIResponse<DeleteFormResponse>>(
    'forms/v1',
    { file_ids: req.file_ids },
    {
      headers: !!req.location_id ? { 'location-id': req.location_id } : {},
    }
  );
  return data;
};

export const finishFormsUpload = async (req: SetSubmissionConfirmationRequest): Promise<FinishUploadResponse> => {
  const res = await http.patch<FinishUploadResponse>(
    'forms/v1/confirmation',
    {
      status: '1',
      ...req,
    },
    { headers: !!req.locationId ? { 'location-id': req.locationId } : {} }
  );
  return res;
};

export const getFormsFinishedStatus = async (location_id?: string): Promise<FormsSubmissionResponse> => {
  const { data } = await http.get<APIResponse<FormsSubmissionResponse>>('forms/v1/confirmation', {
    headers: !!location_id ? { 'location-id': location_id } : {},
  });
  return data;
};

export async function onboardCompany(locationId: string): Promise<number> {
  try {
    await http.post('forms-digital/v1/onboard-company', {
      company_id: locationId,
    });
    return 200;
  } catch (e: any) {
    return e.status as number;
  }
}

export async function getFormsPaymentStatus(location_id?: string) {
  return http.get<FormsPaymentStatusResponse>(`forms/v1/can-upload-forms`, {
    params: {
      location_id,
    },
    headers: !!location_id ? { 'location-id': location_id } : {},
  });
}
