import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../../feature-flags';
import { useFetchRoiData } from '../../hooks';
import { detailedConversionCategorized } from '../../missed-call-text/demo-data';
import { MCTRoiConversionType } from '../../missed-call-text/types';
import { MessagingRoiFetchParams } from '../../types';
import { useHasMCTRoiFeature } from './use-has-mct-roi-feature';

export type CategorizedConversions = {
  conversion_type: MCTRoiConversionType;
  count: number;
}[];

type UseFetchMissedCallTextAttributionProps = {
  categorizedConversions?: Partial<Record<MCTRoiConversionType, number>>;
  isLoading?: boolean;
  sortedConversionTypes?: MCTRoiConversionType[];
};

type Props = {
  apiParams: MessagingRoiFetchParams;
  isEnabled?: boolean;
};

const api = async ({ messagingCategory, payload }: MessagingRoiFetchParams) => {
  const response = await http.post<{ data: CategorizedConversions }>(
    `${BASE_URL_ANALYTICS}/roi/v2/${messagingCategory}/chartview/forward`,
    payload
  );
  return response.data;
};

const allConversionTypes: MCTRoiConversionType[] = ['Same Day', '7 Days', '14 Days', '21 Days', '28 Days'];

export const useFetchMissedCallTextAttribution = ({
  isEnabled,
  ...rest
}: Props): UseFetchMissedCallTextAttributionProps => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);

  const { data: conversionData, isLoading } = useFetchRoiData<MessagingRoiFetchParams, CategorizedConversions>({
    ...rest,
    api,
    errorMessage: t("Couldn't fetch the missed call text attribution. Please try again."),
    isEnabled: isEnabled && isMCTRoiEligibleLocation && !isDemoAccount,
    uniqueId: 'mct-attribution',
  });

  const categoryConversions = useMemo(() => {
    const categorizedConversionsMap: Partial<Record<MCTRoiConversionType, number>> = {};
    const keysInData: MCTRoiConversionType[] = [];
    const data = isDemoAccount ? detailedConversionCategorized : conversionData;

    data?.forEach((item) => {
      categorizedConversionsMap[item.conversion_type] = item.count;
      if (!keysInData.includes(item.conversion_type)) {
        keysInData.push(item.conversion_type);
      }
    });

    return {
      categorizedConversions: categorizedConversionsMap,
      sortedConversionTypes: allConversionTypes.filter((key) => keysInData.includes(key)),
    };
  }, [conversionData, isDemoAccount]);

  return {
    isLoading,
    ...categoryConversions,
  };
};
