import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { ShareInMessageNavigationProps } from '../share-in-message.types';

export const ShareInMessageGenericNavigation = ({ body: Body }: ShareInMessageNavigationProps) => {
  const {
    multiStep: { goPrevStep, onComplete },
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => {
    goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
  }, []);

  const onCompleteStep = useCallback(() => {
    onComplete();
  }, [onComplete]);

  return <Body onGoBack={onGoBack} onCompleteStep={onCompleteStep} />;
};
