import { useCallback } from 'react';
import { css } from '@emotion/react';
import { EmptyTask as EmptyTaskSVG } from '@frontend/empty-states#svg';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, Button } from '@frontend/design-system';
import { useTaskCenterStore } from '../../../provider';

export const EmptyTask = () => {
  const { t } = useTranslation('task-center');
  const { setShowCreateTask } = useTaskCenterStore(['setShowCreateTask']);

  const openCreateTask = useCallback(() => setShowCreateTask(true), []);

  return (
    <div css={containerStyle}>
      <EmptyTaskSVG />
      <Text size='large' color='subdued'>
        {t('You have no assigned tasks available.')}
      </Text>
      <Button onClick={openCreateTask}>{t('Create Task')}</Button>
    </div>
  );
};

const containerStyle = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    margin: ${theme.spacing(1, 0, 0, 0)};
  }

  svg {
    width: 350px;
    height: 350px;
  }
`;
