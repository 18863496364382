import { ReactElement } from 'react';

export type DayToSend = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

export enum SendType {
  SendAll = 'send-all',
  SendSpecific = 'send-specific',
}

export type Step = {
  id: StepId;
  Component: ReactElement;
  collapsedValue: ReactElement;
  readValue?: ReactElement;
  hide?: boolean;
};

export type StepId = 'title' | 'locations' | 'when-to-send' | 'content' | 'audience' | 'attachments';
