import { IImage, IGif } from '@giphy/js-types';
import { PersonHelpers } from '@frontend/api-person';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { StreamGiphyType, GiphyVersionInfo } from '@frontend/gif-picker';

export const getUserFullName = (
  firstName: TeamChatTypes.User['firstName'],
  lastName: TeamChatTypes.User['lastName']
) => {
  return PersonHelpers.getFullName({
    FirstName: firstName,
    LastName: lastName,
  });
};

/**
 *
 * Extracts an array of matching usernames from the text
 */
export const getMentionsFromText = (text: string, usernames: string[]) => {
  const chars = text.replaceAll(/\s/g, '');
  return usernames.filter((username) => chars.includes('@' + username.replaceAll(/\s/g, '')));
};

/**
 *
 * Extracts an array of matching usernames from the text
 */
export const getChannelsFromText = (text: string, channels: string[]) => {
  const chars = text.replaceAll(/\s/g, '');
  return channels.filter((channel) => chars.includes('#' + channel.replaceAll(/\s/g, '')));
};

export type Target =
  | {
      type: 'dm';
      users: TeamChatTypes.User[];
    }
  | {
      type: 'group';
      conversation: TeamChatTypes.Conversation;
    };

// This function converts value of object to string. When gif is attached the value might be number,
// so we convert it to string
const convertValuesToString = (input: IImage): GiphyVersionInfo => {
  return {
    height: String(input.height),
    url: input.url,
    width: String(input.width),
    // Since input is a optional field that is why adding a check is size is in the object
    ...(input.size && { size: String(input.size) }),
  };
};

export const parseGiphyToStreamGifAttachment = (gif: IGif): StreamGiphyType => {
  const giphy: StreamGiphyType['giphy'] = {
    original: convertValuesToString(gif.images.original),
    fixed_height: convertValuesToString(gif.images.fixed_height),
    fixed_height_still: convertValuesToString(gif.images.fixed_height_still),
    fixed_height_downsampled: convertValuesToString(gif.images.fixed_height_downsampled),
    fixed_width: convertValuesToString(gif.images.fixed_width),
    fixed_width_still: convertValuesToString(gif.images.fixed_width_still),
    fixed_width_downsampled: convertValuesToString(gif.images.fixed_width_downsampled),
  };

  return {
    title: gif.title,
    title_link: gif.url,
    thumb_url: gif.images.original.url,
    giphy,
  };
};
