import { memo } from 'react';
import { css } from '@emotion/react';
import { Handle, Position } from '@xyflow/react';
import { theme } from '@frontend/theme';
import { styles, Text } from '@frontend/design-system';
import { SharedNodeProps } from '../data';
import { draggableStyles } from './styles';

export const BasicNode = memo(({ data }: SharedNodeProps) => {
  return (
    <div
      css={[
        css`
          padding: ${theme.spacing(2)};
          border: 1px solid ${theme.colors.neutral50};
          border-radius: ${theme.borderRadius.medium};
          background: ${theme.colors.white};
          width: 'min-content';
        `,
        draggableStyles,
      ]}
    >
      <Handle
        type='target'
        position={Position.Top}
        id={`${data.id}-top`}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          height: 40,
          width: 190,
        }}
      >
        <Text as='span' textAlign='center' size='medium' css={[styles.truncate]}>
          {data.label}
        </Text>
      </div>

      <Handle
        type='source'
        position={Position.Bottom}
        id={`${data.id}-bottom`}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
});

BasicNode.displayName = 'BasicNode';
