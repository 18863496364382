import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { ShareInMessageNavigationSwitch } from './share-in-message/share-in-message-navigation-switch';
import { ShareInMessageProps } from './share-in-message/share-in-message.types';

export const ShareInMessage = (props: ShareInMessageProps) => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.shareInMessage}>
      <ShareInMessageNavigationSwitch {...props} />
    </StepPerformanceWrapper>
  );
};
