export * as Queries from './queries';
export * as Mutations from './mutations';
export * as Types from './types';
export * as Hooks from './hooks';
export * from './service';

import { useSMSNumberV1QueryUpdaters } from './query-updaters';
/**
 * Query updaters should only be used internally by mutations in an api library that affects number-v1 queries
 */
export const _QueryUpdaters = { useQueryUpdaters: useSMSNumberV1QueryUpdaters };
