import { Fragment, useCallback } from 'react';
import { DefaultSms } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/phone_number.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, Text, TextButton, usePopoverMenu } from '@frontend/design-system';
import { OutboundNumberData } from '../types';

type DepartmentOption = {
  id: string;
  name: string;
  number: string;
};

export type DepartmentSelectorState = {
  showButton: boolean;
  selectedDepartment?: DefaultSms;
  popoverState: ReturnType<typeof usePopoverMenu<HTMLElement>>;
  personPhone?: string;
  departments?: DefaultSms[];
  departmentIdsWithActiveConversations?: string[];
  departmentIdsWithArchivedConversations?: string[];
  departmentIdsWithoutConversations?: string[];
};

type DepartmentSelectorProps = DepartmentSelectorState & {
  onSelectDepartment: ({ departmentId, locationPhone }: OutboundNumberData) => void;
  labelType?: 'number' | 'name' | 'both';
  personPhone?: string;
  color?: 'white' | 'default';
} & CommonHTMLAttributes;

export const DepartmentSelector = ({
  showButton,
  selectedDepartment,
  onSelectDepartment,
  popoverState,
  personPhone,
  departments,
  departmentIdsWithoutConversations,
  departmentIdsWithActiveConversations,
  departmentIdsWithArchivedConversations,
  labelType = 'both',
  color = 'default',
  ...rest
}: DepartmentSelectorProps) => {
  const { t } = useTranslation('thread-header');

  const getLabel = useCallback(
    ({ name, number }: { name?: string; number?: string }) => {
      switch (labelType) {
        case 'both':
          return `${name ?? ''} ${formatPhoneNumber(number) || ''}`.trim();
        case 'name':
          if (name) return name;
        // If name doesn't exist, fall back to number
        // eslint-disable-next-line no-fallthrough
        case 'number':
          return formatPhoneNumber(number) || '';
      }
    },
    [labelType]
  );

  if (!showButton) return null;

  const mapDepartmentIdToPopoverOption = (id: string): DepartmentOption => {
    const department = departments?.find(({ id: deptId }) => deptId === id);
    return {
      id,
      number: department?.smsNumber?.number ?? '',
      name: department?.name ?? '',
    };
  };

  const departmentIds =
    departments?.reduce<string[]>((acc, { id }) => {
      if (id) acc.push(id);
      return acc;
    }, []) ?? [];
  const popoverSections: { title: string; departments: DepartmentOption[] }[] = personPhone
    ? [
        {
          title: t('Active Conversations'),
          departments: departmentIdsWithActiveConversations?.map(mapDepartmentIdToPopoverOption) ?? [],
        },
        {
          title: t('New Conversations'),
          departments: departmentIdsWithoutConversations?.map(mapDepartmentIdToPopoverOption) ?? [],
        },
        {
          title: t('Archived Conversations'),
          departments: departmentIdsWithArchivedConversations?.map(mapDepartmentIdToPopoverOption) ?? [],
        },
      ].filter(({ departments }) => departments.length)
    : [
        {
          title: t('Departments'),
          departments: departmentIds.map(mapDepartmentIdToPopoverOption) ?? [],
        },
      ];

  return (
    <>
      <span
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(showButton ? 0 : 0.5),
          justifyContent: 'center',
        }}
        {...rest}
      >
        <Text
          color={color === 'white' ? 'white' : 'light'}
          size='medium'
          weight={color === 'white' ? 'bold' : undefined}
        >
          {t('From:')}
        </Text>
        {showButton ? (
          <TextButton
            css={[
              {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
                color: color === 'white' ? theme.colors.white : theme.colors.primary50,
                fontWeight: 'normal',
              },
              color === 'white' && {
                ':hover, :focus': {
                  backgroundColor: theme.colors.primary60,
                  borderColor: 'transparent',
                },
              },
            ]}
            {...popoverState.getTriggerProps()}
          >
            {getLabel({ name: selectedDepartment?.name, number: selectedDepartment?.smsNumber?.number })}
            <Icon
              name='alt-caret-down-tiny'
              color={color === 'white' ? 'white' : 'light'}
              css={[{ transition: 'rotate 200ms ease-in-out' }, popoverState.isOpen && { rotate: '-180deg' }]}
            />
          </TextButton>
        ) : (
          <Text
            size='medium'
            color={color === 'white' ? 'white' : 'default'}
            css={{ lineHeight: 1, padding: theme.spacing(1, 0.5) }}
          >
            {getLabel({ name: selectedDepartment?.name, number: selectedDepartment?.smsNumber?.number })}
          </Text>
        )}
      </span>
      {showButton && (
        <PopoverMenu {...popoverState.getMenuProps()}>
          {popoverSections.map(({ title, departments }, i) => (
            <Fragment key={`department-picker-popover-section-${title}`}>
              <Text
                css={[
                  { padding: theme.spacing(1, 2) },
                  i !== 0 && { borderTop: `solid 1px ${theme.colors.neutral20}` },
                ]}
                weight='semibold'
              >
                {title}
              </Text>
              {departments.map(({ number, name, id }, index) => {
                const active = id === selectedDepartment?.id;

                if (!id) return null;

                return (
                  <PopoverMenuItem
                    key={id}
                    {...popoverState.getItemProps({
                      index,
                      onClick: () => {
                        onSelectDepartment({ departmentId: id, locationPhone: number });
                      },
                    })}
                    active={active}
                  >
                    {getLabel({ name, number })}
                  </PopoverMenuItem>
                );
              })}
            </Fragment>
          ))}
        </PopoverMenu>
      )}
    </>
  );
};
