import { BaseFieldProps, contextFactory } from '@frontend/design-system';

type EmailSubjectHeaderContextState = {
  setSubject: (subject: string) => void;
  subject: string;
  subjectProps?: BaseFieldProps<string>;
  trackingPrefix: string;
};

export const [EmailSubjectHeaderContext, useEmailSubjectHeader] = contextFactory<EmailSubjectHeaderContextState>(
  'useEmailSubjectHeader must be used within an EmailSubjectHeaderProvider'
);
