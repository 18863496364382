import { ComponentProps, useCallback } from 'react';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Icon } from '@frontend/icons';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton } from '@frontend/design-system';
import { ThreadContextAndOptions, useMessageAction } from './use-message-action';

export type MessageButtonProps<T extends ThreadContextAndOptions | undefined | never> =
  (T extends ThreadContextAndOptions
    ? {
        context: T;
        onClick?: ComponentProps<typeof ActionButton>['onClick'] | undefined | never;
        trackingIdSuffix?: string;
      }
    : {
        context?: T;
        onClick: NonNullable<ComponentProps<typeof ActionButton>['onClick']>;
        trackingIdSuffix?: string;
      }) &
    Omit<ComponentProps<typeof ActionButton>, 'trackingId' | 'onClick'>;

export const MessageButton = <T extends ThreadContextAndOptions | undefined | never>({
  context,
  trackingIdSuffix,
  onClick,
  ...rest
}: MessageButtonProps<T>) => {
  const { onClick: onClickWithContext } = useMessageAction();

  const handleClick = useCallback<NonNullable<ComponentProps<typeof ActionButton>['onClick']>>(
    async (...args) => {
      if (context) {
        await onClickWithContext(context);
      }
      onClick?.(...args);
    },
    [!!context, onClick, onClickWithContext]
  );

  return (
    <ActionButton onClick={handleClick} trackingId={`${ContactActionPrefixes.Message}:${trackingIdSuffix}`} {...rest}>
      <Icon name={'message'} size={16} />
    </ActionButton>
  );
};

export type MessageActionProps<T extends ThreadContextAndOptions | undefined | never> =
  (T extends ThreadContextAndOptions
    ? {
        context: T;
        onClick?: ComponentProps<typeof AdaptoActions.Action>['onClick'] | undefined | never;
        trackingIdSuffix?: string;
      }
    : {
        context?: T;
        onClick: NonNullable<ComponentProps<typeof AdaptoActions.Action>['onClick']>;
        trackingIdSuffix?: string;
      }) &
    Omit<ComponentProps<typeof AdaptoActions.Action>, 'trackingId' | 'onClick' | 'icon'>;

export const MessageAction = <T extends ThreadContextAndOptions | undefined | never>({
  context,
  trackingIdSuffix,
  onClick,
  ...rest
}: MessageActionProps<T>) => {
  const { onClick: onClickWithContext } = useMessageAction();

  const handleClick = useCallback<NonNullable<ComponentProps<typeof AdaptoActions.Action>['onClick']>>(
    async (...args) => {
      if (context) {
        await onClickWithContext?.(context);
      }
      onClick?.(...args);
    },
    [!!context, onClick, onClickWithContext]
  );

  return (
    <AdaptoActions.Action
      trackingId={`${ContactActionPrefixes.Message}:${trackingIdSuffix}`}
      icon='messaging'
      onClick={handleClick}
      {...rest}
    />
  );
};
