import React from 'react';
import { css } from '@emotion/react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';

type Props = {
  children: React.ReactNode;
} & CommonHTMLAttributes;

export const SettingsPageLayout = ({ children, ...rest }: Props) => (
  <div
    css={css`
      height: 100%;
      overflow-y: auto;
      background-color: ${theme.colors.neutral5};
    `}
    {...rest}
  >
    {children}
  </div>
);
