import { Dispatch, SetStateAction, useState } from 'react';
import { css } from '@emotion/react';
import { ViewStatus } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import { VoicemailTypes as VmTypes, VoicemailTypes } from '@frontend/api-voicemails';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Button, useModalControl, useTooltip } from '@frontend/design-system';
import { Action, CustomActionCell } from '../../../components/all-calls/shared-buttons';
import { VoicemailTableDataRow } from '../../../components/all-calls/types';
import { TranscriptionContext } from '../../../components/transcription/types';
import { downloadVoicemail } from '../../../components/voicemails/voicemail-utils';
import { Forwardvoicemail } from '../../../components/voicemails/voicemails-forward';
import {
  defaultForwardVoicemailModalData,
  ForwardVoicemailDynamicData,
  ForwardVoicemailModalData,
} from '../../../components/voicemails/voicemails-multi.component';
import { useVoicemailBoxList } from '../../../hooks/use-voicemail-box';
import { getActionIcon } from '../../../ui-utils';
import { getShortHandName } from '../../utils';

export const SplitViewActions = ({
  data,
  index,
  onDelete,
  setIsTagEditing,
  onForwardSuccess,
  updateReadStatus,
  trackingId,
}: {
  data: VoicemailTableDataRow;
  index: number;
  onDelete: (payload: VmTypes.DeletePayload) => void;
  setIsTagEditing: Dispatch<SetStateAction<boolean>>;
  onForwardSuccess: () => void;
  updateReadStatus: (payload: VoicemailTypes.StatusPayload) => Promise<VoicemailTypes.SetVoicemailViewStatusResponse>;
  trackingId?: string;
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });
  const { selectedLocationIds } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const { data: voicemailBoxList } = useVoicemailBoxList({
    phoneConfig,
    locationIds: selectedLocationIds,
  });

  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore<TranscriptionContext>('setShow');

  const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId: data.contactId,
      phoneNumber: data.contactNumber,
      locationId: selectedLocationIds[0] ?? '',
    },
  });

  const { onClick: messageOnClick } = ActionsUI.actions.useMessageAction();

  const actions: ActionButtonProps[] = [
    {
      iconName: 'message',
      hoverLabel: `${t('Message')} ${getShortHandName(data)}`,
      trackingId: `${trackingId}-message-icn-btn`,
      onClick: () => {
        messageOnClick({
          personId: data.contactId,
          personPhone: data.contactNumber,
          groupIds: data.locationIds,
        });
      },
    },
    {
      iconName: 'phone',
      hoverLabel: `${t('Call')} ${getShortHandName(data)}`,
      trackingId: `${trackingId}-phone-icn-btn`,
      onClick: () => {
        callTriggerProps?.onClick();
      },
    },
  ];

  const [forwardVoicemailModalData, setForwardVoicemailModalData] = useState<ForwardVoicemailModalData>(
    defaultForwardVoicemailModalData as ForwardVoicemailModalData
  );
  const doubleActionControl = useModalControl();

  const openForwardVoicemailModal = (data: ForwardVoicemailDynamicData) => {
    setForwardVoicemailModalData({ ...data, voicemailBoxList: voicemailBoxList || [], onDelete: onDelete });
    doubleActionControl.openModal();
  };

  const moreActions: Action[] = [
    {
      Icon: () => getActionIcon({ iconName: 'user' }),
      label: t('View Profile'),
      trackingId: `${trackingId}-btn-tablerowaction-viewprofile`,
      disabled: !data.contactId,
      onClick: () => {
        if (!!data.contactId) {
          setPersonId(data.contactId, true, selectedLocationIds[0]);
          setShow(true, 'contact');
        }
      },
    },
    {
      Icon: () => getActionIcon({ iconName: 'voicemail-override' }),
      label: t('Forward Voicemail'),
      trackingId: `${trackingId}-btn-tablerowaction-forwardvoicemail`,
      onClick: () => {
        openForwardVoicemailModal({
          vmId: data.voicemailId == '' ? data.channelId : data.voicemailId,
          mediaId: data.mediaId,
          mediaPath: data.mediaFilePath,
          currentVoicemailBoxId: data.mailboxId,
          currentVoicemailBoxName: data.voiceMailBoxName,
        } as ForwardVoicemailDynamicData);
      },
    },
    {
      Icon: () => getActionIcon({ iconName: 'label' }),
      label: t('Add Tag'),
      trackingId: `${trackingId}-btn-tablerowaction-tagvoicemail`,
      onClick: () => {
        setIsTagEditing(true);
      },
    },
    {
      Icon: () => getActionIcon({ iconName: 'download' }),
      label: t('Download Voicemail'),
      trackingId: `${trackingId}-btn-tablerowaction-downloadvoicemail`,
      onClick: () => {
        downloadVoicemail(data.mediaFilePath, data.contactName ?? 'unknown', data.dateTime);
      },
    },
    {
      Icon: () => getActionIcon({ iconName: 'mark-unread' }),
      label: t('Mark Unread'),
      trackingId: `${trackingId}-btn-tablerowaction-togglestatus`,
      hide: !data.readAt,
      onClick: () => {
        if (!!data) {
          updateReadStatus({
            index: index,
            voicemailId: data?.forwardedMessageId || data.channelId,
            status: ViewStatus.VIEW_STATUS_UNREAD,
          });
        }
      },
    },
    {
      Icon: () => getActionIcon({ iconName: 'mark-read' }),
      label: t('Mark Read'),
      trackingId: `${trackingId}-btn-tablerowaction-togglestatus`,
      hide: !!data.readAt,
      onClick: () => {
        if (!!data) {
          updateReadStatus({
            index: index,
            voicemailId: data?.forwardedMessageId || data.channelId,
            status: ViewStatus.VIEW_STATUS_READ,
          });
        }
      },
    },
    {
      Icon: (props) => getActionIcon({ iconName: 'trash', color: props.color }),
      label: t('Delete Voicemail'),
      trackingId: '${trackingId}-btn-tablerowaction-deletevoicemail',
      destructive: true,
      onClick: () => {
        if (!!data) {
          onDelete({ voicemailId: data?.forwardedMessageId || data.channelId, index: index });
        }
      },
    },
  ];

  return (
    <div css={styles.actionWrapper}>
      <Forwardvoicemail
        onForwardSuccess={onForwardSuccess}
        modalProps={doubleActionControl.modalProps}
        forwardData={forwardVoicemailModalData}
      />

      <CustomActionCell actions={moreActions} tableTrackingId={'phone-portal-weave2.0-voicemails'} />

      {actions?.map(({ iconName, hoverLabel, trackingId, onClick, disabled }, id) => {
        return (
          <ActionButton
            key={id}
            disabled={disabled}
            iconName={iconName}
            trackingId={trackingId}
            hoverLabel={hoverLabel}
            onClick={onClick}
          />
        );
      })}
      {PhoneCallModal}
    </div>
  );
};

const styles = {
  actionWrapper: css`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    min-width: 120px;
    gap: ${theme.spacing(1)};
  `,
};

type ActionButtonProps = {
  hide?: boolean;
  disabled?: boolean;
  iconName: IconName;
  hoverLabel?: string;
  trackingId?: string;
  onClick?: () => void;
};

export const ActionButton = ({
  disabled = false,
  hoverLabel,
  iconName,
  onClick,
  trackingId,
}: ActionButtonProps & {
  isSelected?: boolean;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'bottom',
  });

  return (
    <Button
      variant='secondary'
      css={{
        padding: theme.spacing(0.5),
        border: 'none',
      }}
      onClick={() => {
        onClick?.();
      }}
      disabled={disabled}
      trackingId={trackingId}
      {...triggerProps}
    >
      {hoverLabel && <Tooltip {...tooltipProps}>{hoverLabel}</Tooltip>}
      <Icon name={iconName} size={24} color='light' />
    </Button>
  );
};
