import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { QueryKey } from 'react-query';
import { CompulsoryKeys } from 'ts-toolbelt/out/Object/CompulsoryKeys';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { LimitedSchemaQueryOptions, useQuery, ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { SchemaTemplatorV2 } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import {
  GetTemplateIO,
  ListTemplateTypesIO,
  ListTemplatesIO,
  RenderIO,
  RenderTemplateIO,
  ListTemplateRevisionsIO,
  useDefaultLocationIdHeaderOptions,
} from './query-utils';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const baseKeys = {
  service: ['templator-v2'],
  listTemplates: (req: Pick<ListTemplatesIO['input'], CompulsoryKeys<ListTemplatesIO['input']>>) => [
    ...baseKeys.service,
    ...Object.values(req),
    'listTemplates',
  ],
  listTemplateTypes: () => [...baseKeys.service, 'listTemplateTypes'],
  getTemplate: () => [...baseKeys.service, 'getTemplate'],
  render: () => [...baseKeys.service, 'render'],
  renderTemplate: () => [...baseKeys.service, 'renderTemplate'],
  getListTemplateRevisions: () => [...baseKeys.service, 'ListTemplateRevisions'],
} as const;

export const queryKeys = {
  listTemplatesQuery: (req: ListTemplatesIO['input']): QueryKey => [
    ...baseKeys.listTemplates({ orgId: req.orgId }),
    { ...req },
  ],
  listTemplateTypes: (req: ListTemplateTypesIO['input']): QueryKey => [...baseKeys.listTemplateTypes(), { ...req }],
  getTemplateQuery: (req: GetTemplateIO['input']): QueryKey => [...baseKeys.getTemplate(), { ...req }],
  renderQuery: (req: Partial<RenderIO['input']>): QueryKey => [...baseKeys.render(), { ...req }],
  renderTemplateQuery: (req: Partial<RenderTemplateIO['input']>): QueryKey => [...baseKeys.render(), { ...req }],
  getListTemplateRevisionsQuery: (req: ListTemplateRevisionsIO['input']): QueryKey => [
    ...baseKeys.getListTemplateRevisions(),
    { ...req },
  ],
} as const;

export const useTemplatesList = <E = unknown, T = ListTemplatesIO['output']>(
  req: Optional<ListTemplatesIO['input'], 'orgId' | 'businessGroupIds'> = {},
  options?: LimitedSchemaQueryOptions<ListTemplatesIO, E, T>
) => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const resolvedReq: ListTemplatesIO['input'] = {
    orgId: selectedOrgId,
    businessGroupIds: selectedLocationIds,
    ...req,
  };
  const queryKey = queryKeys.listTemplatesQuery(resolvedReq);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.ListTemplates(resolvedReq, getHeaderOptions(resolvedReq.businessGroupIds)),
    meta: {
      request: resolvedReq,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useTemplateTypesList = <E = unknown, T = ListTemplateTypesIO['output']>(
  req: Optional<ListTemplateTypesIO['input'], 'orgId' | 'businessGroupIds'> = {},
  options?: LimitedSchemaQueryOptions<ListTemplateTypesIO, E, T>
) => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useAppFlagStore();
  const resolvedReq: ListTemplateTypesIO['input'] = {
    orgId: selectedOrgId,
    businessGroupIds: selectedLocationIds,
    ...req,
  };
  const queryKey = queryKeys.listTemplateTypes(resolvedReq);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const petNameEnabled = getFeatureFlagValue({
    flagName: 'nwx:manual-templates-pet-name',
    locationIds: resolvedReq.businessGroupIds ?? selectedLocationIds,
    strategy: 'ANY',
  });
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.ListTemplateTypes(resolvedReq, getHeaderOptions(resolvedReq.businessGroupIds)),
    meta: {
      request: resolvedReq,
    },
    select: (data) => {
      const filteredData = {
        ...data,
        templateTypes: data.templateTypes.map((templateType) => {
          if (
            !petNameEnabled &&
            (templateType.slug === TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET ||
              templateType.slug === TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET)
          ) {
            return {
              ...templateType,
              dynamicFields: templateType.dynamicFields.filter(({ key }) => !key?.includes('PET')),
            };
          }
          return templateType;
        }),
      };

      return (options?.select?.(filteredData) ?? filteredData) as T;
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useTemplate = <E = unknown, T = GetTemplateIO['output']>(
  req: Optional<GetTemplateIO['input'], 'orgId'>,
  options?: LimitedSchemaQueryOptions<GetTemplateIO, E, T>
) => {
  const { selectedOrgId } = useAppScopeStore();
  const resolvedReq: GetTemplateIO['input'] = { orgId: selectedOrgId, ...req };
  const queryKey = queryKeys.getTemplateQuery(resolvedReq);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.Template(resolvedReq, getHeaderOptions()),
    meta: {
      request: resolvedReq,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useRender = <E = unknown, T = RenderIO['output']>(
  req: RenderIO['input'],
  options?: LimitedSchemaQueryOptions<RenderIO, E, T>
) => {
  const queryKey = queryKeys.renderQuery(req);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.Render(req, getHeaderOptions()),
    meta: {
      request: req,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

export const useRenderTemplate = <E = unknown, T = RenderTemplateIO['output']>(
  req: RenderTemplateIO['input'],
  options?: LimitedSchemaQueryOptions<RenderTemplateIO, E, T>
) => {
  const queryKey = queryKeys.renderTemplateQuery(req);
  const { getHeaderOptions } = useDefaultLocationIdHeaderOptions();
  const query = useQuery({
    ...defaultOptions,
    ...options,
    queryKey,
    queryFn: () => SchemaTemplatorV2.RenderTemplate(req, getHeaderOptions()),
    meta: {
      request: req,
    },
  });

  return {
    ...query,
    queryKey,
  };
};

/**
 * A hook that returns a query for the `ListTemplateRevisions` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 */
export const useListTemplateRevisions = <E = unknown, D = ListTemplateRevisionsIO['output']>(
  req: ListTemplateRevisionsIO['input'],
  options?: LimitedSchemaQueryOptions<ListTemplateRevisionsIO, E, D, QueryKey>
  // httpOptions?: Options
) =>
  useQuery<ListTemplateRevisionsIO['output'], E, D, QueryKey>({
    queryKey: queryKeys.getListTemplateRevisionsQuery(req),
    queryFn: () => SchemaTemplatorV2.ListTemplateRevisions(req),
    ...options,
  });
