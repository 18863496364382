import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, TextLink } from '@frontend/design-system';

export type SettingsLocationChipProps = {
  isSingleLocation?: boolean;
  chipLabel?: string;
  onActionClick?: React.MouseEventHandler<HTMLElement> & React.MouseEventHandler<HTMLAnchorElement>;
  actionLabel?: string;
  hideLocationChip?: boolean;
};

export function SettingsLocationChip({
  isSingleLocation,
  chipLabel,
  onActionClick,
  actionLabel,
  hideLocationChip,
}: SettingsLocationChipProps) {
  const { t } = useTranslation('base');
  const { selectedLocationIds, getLocationName, isSingleTypeScope } = useAppScopeStore();

  if (isSingleTypeScope || hideLocationChip) return null;
  const singleLocationId = selectedLocationIds[0];
  return (
    <div
      css={css`
        display: flex;
        gap: ${theme.spacing(1)};
        align-items: center;
      `}
    >
      {(isSingleLocation && singleLocationId) || (selectedLocationIds?.length === 1 && selectedLocationIds[0]) ? (
        <Chip.SingleChip>
          {chipLabel || getLocationName(isSingleLocation ? singleLocationId : selectedLocationIds[0])}
        </Chip.SingleChip>
      ) : (
        <Chip.MultiChip>{chipLabel || t(`{{count}} Locations`, { count: selectedLocationIds.length })}</Chip.MultiChip>
      )}
      {onActionClick ? (
        <TextLink
          weight='bold'
          css={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            ':hover, :focus': {
              textDecoration: 'none',
            },
          }}
          onClick={(e) => {
            onActionClick?.(e);
          }}
        >
          {actionLabel ?? t('Edit')}
        </TextLink>
      ) : null}
    </div>
  );
}
