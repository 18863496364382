import { useSchedularV3InvalidateQueries } from '../helpers';
import { UpdateBookingSubmissionIO } from '../types';
import { UseSchedulerV3MutationEndpointArgs, useSchedulerV3Mutation } from './use-scheduler-v3-mutation';

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `UpdateBookingSubmission` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useUpdateBookingSubmissionMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends UpdateBookingSubmissionIO['input'] = UpdateBookingSubmissionIO['input']
>({
  options,
  optimisticUpdate = false,
  ...args
}: UseSchedulerV3MutationEndpointArgs<
  'UpdateBookingSubmission',
  E,
  C | undefined,
  OtherOptions,
  RequestOverride
> = {}) => {
  const { invalidateListBookingSubmissions, invalidateGetBookingSubmission } = useSchedularV3InvalidateQueries();
  return useSchedulerV3Mutation<'UpdateBookingSubmission', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'UpdateBookingSubmission',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        if (optimisticUpdate) {
          // Implement optimistic updates here
        }

        return {
          // Add custom context type here (if exists in `MutationContext`)
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        // Implement query updates or invalidation for affected queries here
        invalidateListBookingSubmissions(response.bookingSubmission?.locationId);
        invalidateGetBookingSubmission(response.bookingSubmission?.id);

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};
