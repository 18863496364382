import { ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { PersonAPI, PersonsV3 } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions as ContactActions } from '@frontend/contact-actions';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Avatar, Heading, Text } from '@frontend/design-system';
import { formatCentsToCurrency } from '../../../utils';
import { calcRemainingBalance } from '../preview-create-request-modal';

type SummaryCardProps = {
  invoice: InvoiceModel;
};

const styles = {
  summarySectionRow: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  summary: css`
    display: flex;
    flex-direction: column;
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  heading: css`
    font-size: ${theme.fontSize(32)};
  `,
  summaryPerson: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `,
  noMargin: css`
    margin: 0;
  `,
  summaryHeader: css`
    font-size: ${theme.fontSize(20)};
  `,
  summaryInfo: css`
    font-size: ${theme.font.size.h2};
    font-weight: ${theme.font.weight.bold};
  `,
};

export const SummaryCard = ({ invoice }: SummaryCardProps) => {
  const { t } = useTranslation('payments');

  return (
    <section css={styles.summarySectionRow}>
      <div css={styles.summary}>
        <SummaryCardPerson invoice={invoice} />
        <div css={styles.amount}>
          <SummaryInfo title={t('Amount')} info={formatCentsToCurrency(invoice.billedAmount)} />
          <SummaryInfo title={t('Owed')} info={formatCentsToCurrency(calcRemainingBalance(invoice))} />
          <SummaryInfo title={t('Payment Due')} info={formatDate(invoice.billedAt, 'MMMM D, YYYY')} />
        </div>
      </div>
    </section>
  );
};

const SummaryCardPerson = ({ invoice }: SummaryCardProps) => {
  const { t } = useTranslation('payments');

  const { data: personData } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: invoice.person.id ?? '',
      locationIds: [invoice.locationId],
    },
    {
      enabled: !!invoice.person.id,
    }
  );

  const person = PersonsV3.PersonHelpers.convertPersonV3ToPerson(personData);

  const [personImg, setPersonImg] = useState('');

  const getPersonPhoto = async () => {
    try {
      const imgUrl = await PersonAPI.getPersonImage(invoice.person.id);
      setPersonImg(imgUrl);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPersonPhoto();
  }, [invoice.person.id]);

  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  return (
    <div css={styles.summaryPerson}>
      <Avatar onClick={() => setPersonId(invoice.person.id)} size='large' name={invoice.person.name} src={personImg} />
      <Heading css={styles.heading}>{invoice.person.name}</Heading>
      <AdaptoActions enableCloseEvent iconColor='inherit' variant='icon-buttons' css={{ display: 'flex' }}>
        <ContactActions.Call label={t('Call')} context={{ person }} />
        <ContactActions.Message
          label={t('Message')}
          context={{ personId: person.PersonID, threadGroupId: invoice.locationId, groupIds: [invoice.locationId] }}
        />
      </AdaptoActions>
    </div>
  );
};

const SummaryInfo = ({ title, info }: { title: string; info: string | undefined | ReactNode }) => {
  return (
    <div>
      <Text color='subdued' css={[styles.noMargin, styles.summaryHeader]}>
        {title}
      </Text>
      <Text css={[styles.noMargin, styles.summaryInfo]}>{info}</Text>
    </div>
  );
};
