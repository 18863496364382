import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { LoadingSkeleton } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { humanizeDateDifference } from '@frontend/shared';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  IconProps as DesignSystemIconProps,
  ModalControlModalProps,
  TableFilters,
  Text,
} from '@frontend/design-system';
import { FiltersTray } from '..';
import { useTimePeriodDropDownSelector, useTimePeriodPopoverSelector } from '../../hooks';
import { generateTrackingId, TrackingOptions } from '../../tracking';
import { trackingIds } from '../../tracking-ids';
import { filtersStyles } from '../../views/common-styles';
import { CustomCheckboxField } from '../custom-check-box-field';
import {
  CheckListPopoverSelector,
  CheckListSelector,
  DatesRangeSelector,
  DropDownMultiSelector,
  LocationsSelector,
  RadioFilterSelector,
} from '../filter-selectors';
import { CALL_INTEL_TIME_PERIODS, DEFAULT_CALL_INTEL_TIME_PERIOD } from './constants';
import { CallIntelMockData } from './demo-data';
import { useCallIntelLocations, useCallIntelShallowStore } from './hooks';
import { useOptionsProvider } from './hooks/use-options-provider';

type FilterIconType = 'calendar' | 'contacts' | 'location' | 'user';

type IconProps = {
  iconType: FilterIconType;
  isFilterActive?: boolean;
} & DesignSystemIconProps;

type TrayInlineNotificationProps = {
  message: React.ReactNode;
};

type Props =
  | {
      activeTab: CallIntelTypes.CallIntelTab;
      hideFilters?: (keyof CallIntelTypes.Filters)[];
      isDemoAccount?: boolean;
      isLoadingData?: boolean;
      nonAnalyzedCallsCount?: number;
      onApplyingFilters?: () => void;
      onSearchReset?: () => void;
      trackingOptions?: TrackingOptions;
    } & (
      | {
          modalProps?: never;
          officeNumbers?: never;
          officeUsers?: AnalyticsCommonTypes.StringRecord;
          onlyChipFilters?: boolean;
          onlyTrayFilters?: never;
          setShowNotificationBadge?: never;
          trayNotificationMessage?: never;
          wrapperStyles?: never;
        }
      | {
          modalProps: ModalControlModalProps;
          officeNumbers?: string[];
          officeUsers?: AnalyticsCommonTypes.StringRecord;
          onlyChipFilters?: never;
          onlyTrayFilters?: boolean;
          setShowNotificationBadge: (show: boolean) => void;
          trayNotificationMessage?: React.ReactNode;
          wrapperStyles?: CSSProperties;
        }
    );

const FilterIcon: React.FC<IconProps> = ({ iconType, isFilterActive }) => {
  let iconName: string;
  switch (iconType) {
    case 'calendar':
      iconName = 'calendar-small';
      break;
    case 'contacts':
      iconName = 'contacts-small';
      break;
    case 'location':
      iconName = 'location-small';
      break;
    case 'user':
      iconName = 'user-small';
      break;
    default:
      iconName = 'location-small'; // fallback
      break;
  }

  return <Icon name={iconName as IconName} color={isFilterActive ? 'white' : 'default'} />;
};

const TrayInlineNotification = ({ message }: TrayInlineNotificationProps) => {
  return (
    <div css={styles.trayInlineNotificationWrapper}>
      <div css={styles.trayInlineNotification}>
        <Icon name='info-badge' color='primary' css={{ minWidth: 24 }} />
        <div>{message}</div>
      </div>
    </div>
  );
};

const demoLocations = CallIntelMockData.dummyLocationNames();

export const CallIntelFilters = ({
  activeTab,
  hideFilters,
  isDemoAccount,
  isLoadingData,
  modalProps,
  nonAnalyzedCallsCount = 0,
  officeNumbers,
  officeUsers,
  onApplyingFilters,
  onlyChipFilters,
  onSearchReset: handleSearchReset,
  onlyTrayFilters,
  setShowNotificationBadge,
  trackingOptions,
  trayNotificationMessage,
  wrapperStyles,
}: Props) => {
  const { t } = useTranslation('analytics');

  const {
    defaultFilters: defaultCallIntelFilters,
    filters,
    initialQueryParamsFilters,
    locationIds: callIntelLocationIds,
    selectedTimePeriodKey,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setInitialQueryParamsFilters,
    setSelectedTimePeriodKey,
    setTrayFilters,
    subView,
    trayFilters,
  } = useCallIntelShallowStore(
    'defaultFilters',
    'filters',
    'initialQueryParamsFilters',
    'locationIds',
    'selectedTimePeriodKey',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setInitialQueryParamsFilters',
    'setSelectedTimePeriodKey',
    'setTrayFilters',
    'subView',
    'trayFilters'
  );

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [showFilteredBadge, setShowFilteredBadge] = useState<boolean>(false);
  const [trayTimePeriodKey, setTrayTimePeriodKey] = useState(selectedTimePeriodKey);

  const lastDatesRef = useRef<{ startDate: string | null; endDate: string | null }>({
    startDate: null,
    endDate: null,
  });
  const skipNextDateRangeRef = useRef(false);

  const { getOptions } = useOptionsProvider();

  const {
    isLoading: isLoadingLocations,
    isMultiLocation,
    locations,
  } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });

  const { defaultEndDate, defaultStartDate, selectedPeriod, TimePeriodPopoverSelector, timePeriods } =
    useTimePeriodPopoverSelector({
      defaultPeriod: DEFAULT_CALL_INTEL_TIME_PERIOD,
      skipFindingExistingPeriod: true,
      timePeriods: CALL_INTEL_TIME_PERIODS,
      values: {
        endDate: filters.endDate,
        selectedPeriod: selectedTimePeriodKey,
        startDate: filters.startDate,
      },
    });

  const { TimePeriodDropDownSelector } = useTimePeriodDropDownSelector({
    defaultPeriod: DEFAULT_CALL_INTEL_TIME_PERIOD,
    skipFindingExistingPeriod: true,
    timePeriods: CALL_INTEL_TIME_PERIODS,
    values: {
      endDate: trayFilters.endDate || filters.endDate,
      selectedPeriod: trayTimePeriodKey,
      startDate: trayFilters.startDate || filters.startDate,
    },
  });

  const defaultFilters: CallIntelTypes.Filters = useMemo(
    () => ({
      appointmentTypes: [],
      callDirection: undefined,
      categories: [],
      contactTypes: [],
      endDate: defaultEndDate,
      hideNonAnalyzedCalls: false,
      locations: locations ? Object.keys(locations) : [],
      officeNumbers: [],
      officeUsers: [],
      schedulingOpportunity: '',
      schedulingOutcomes: [],
      sentiments: [],
      startDate: defaultStartDate,
    }),
    [locations, defaultStartDate, defaultEndDate]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<CallIntelTypes.Filters>) => {
    setTrayFilters({ ...trayFilters, ...newFilters });
  };

  const applyDefaultFilters = (hardReset = false) => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});

    if (!hardReset && Object.keys(initialQueryParamsFilters).length) {
      const newFilters = { ...defaultFilters, ...initialQueryParamsFilters };

      setFilters(newFilters);
      setInitialQueryParamsFilters(initialQueryParamsFilters);
    } else {
      setFilters(defaultFilters);
    }
  };

  const applyActiveFiltersToTrayState = () => {
    setTrayTimePeriodKey(selectedTimePeriodKey);
    handleUpdateTrayFilters(filters);
    if (filters.startDate && filters.endDate) {
      //This is being reset so that TimePeriodDropDownSelector and DatesRangeSelector are in sync and selectedPeriod is shown
      lastDatesRef.current = { startDate: filters.startDate, endDate: filters.endDate };
      skipNextDateRangeRef.current = true;
    }
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    !!onApplyingFilters && onApplyingFilters();
    setSelectedTimePeriodKey(trayTimePeriodKey);
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const handleResetFilters = () => {
    applyDefaultFilters(true);
    !!handleSearchReset && handleSearchReset();
    setSelectedTimePeriodKey(DEFAULT_CALL_INTEL_TIME_PERIOD);
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  // We are not using directly hasCustomFilters to show filters badge as not all tabs has the same set of applied filters
  const areRecordsFiltered = (): boolean => {
    const areDatesEqual =
      dayjs(filters.startDate).isSame(dayjs(defaultFilters.startDate), 'date') &&
      dayjs(filters.endDate).isSame(dayjs(defaultFilters.endDate), 'date');

    const areLocationsEqual = isEqual(filters.locations?.sort(), defaultFilters.locations?.sort());

    if (activeTab === 'overview') {
      return !areDatesEqual || !areLocationsEqual;
    } else if (activeTab === 'records') {
      return (
        !areDatesEqual ||
        !areLocationsEqual ||
        !!filters.appointmentTypes?.length ||
        !!filters.callDirection ||
        !!filters.categories?.length ||
        !!filters.contactTypes?.length ||
        filters.hideNonAnalyzedCalls ||
        !!filters.officeNumbers?.length ||
        !!filters.officeUsers?.length ||
        !!filters.schedulingOpportunity ||
        !!filters.schedulingOutcomes?.length ||
        !!filters.sentiments?.length
      );
    }

    return false;
  };

  const handleContactTypeChange = (value: CallIntelTypes.ContactTypeFilterEnum[], directApply?: boolean) => {
    const updated = { contactTypes: value };
    !!onApplyingFilters && onApplyingFilters();
    directApply ? setFilters(updated) : handleUpdateTrayFilters(updated);
  };

  const handleOfficeUsersChange = (value: string[], directApply?: boolean) => {
    const updated = { officeUsers: value };
    !!onApplyingFilters && onApplyingFilters();
    directApply ? setFilters(updated) : handleUpdateTrayFilters(updated);
  };

  const handleSchedulingOutcomeChange = (value: CallIntelTypes.SchedulingOutcomeEnum[], directApply?: boolean) => {
    const updated: Partial<CallIntelTypes.Filters> = {
      schedulingOutcomes: value,
    };
    // Scheduling outcomes can be filtered only when scheduling opportunity is identified
    // Hence automatically select the identified scheduling opportunity option
    if (value.length) {
      filters.schedulingOpportunity = CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;
    }

    if (directApply && !value.length) {
      // Direct apply is used when user selects a value from the chip filter popover
      filters.schedulingOpportunity = '';
    }

    !!onApplyingFilters && onApplyingFilters();
    directApply ? setFilters(updated) : handleUpdateTrayFilters(updated);
  };

  const handleSectionClearClick = (key: keyof CallIntelTypes.Filters, value: any = undefined) => {
    if (key === 'schedulingOpportunity') {
      handleUpdateTrayFilters({
        schedulingOpportunity: undefined,
        schedulingOutcomes: [],
      });
    } else {
      handleUpdateTrayFilters({ [key]: value });
    }
  };

  /**
   * This function renders the chip filters (the top bar popovers).
   */
  const renderChipFilters = () => (
    <>
      {isMultiLocation && !hideFilters?.includes('locations') && (
        <CheckListPopoverSelector<string>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          defaultValue={defaultCallIntelFilters.locations}
          disabled={isLoadingData}
          label={t('Location')}
          onChange={(locations) => {
            !!onApplyingFilters && onApplyingFilters();
            setFilters({ locations });
            if (!isDemoAccount && isMultiLocation) {
              pendo.track(trackingIds.callIntel.applyLocationFilter, {
                filteredLocations: locations,
                filteredLocationsCount: locations?.length,
                selectedLocationIds: callIntelLocationIds,
                totalLocationsCount: defaultCallIntelFilters?.locations?.length ?? 0,
              });
            }
          }}
          options={locations}
          renderIcon={(isFilterActive) => <FilterIcon iconType='location' isFilterActive={isFilterActive} />}
          value={filters.locations}
          trackingId={trackingIds.callIntel.locationsFilter}
        />
      )}

      {!hideFilters?.includes('startDate') && !hideFilters?.includes('endDate') && (
        <TimePeriodPopoverSelector
          customTimePeriodTracking={{
            filteredLocations: JSON.stringify(filters.locations ?? []),
            filteredLocationsCount: filters.locations?.length ?? 0,
            selectedLocationIds: JSON.stringify(callIntelLocationIds),
            totalLocationsCount: defaultCallIntelFilters?.locations?.length ?? 0,
          }}
          disabled={isLoadingData}
          onChange={({ startDate, endDate, selectedPeriod }) => {
            setSelectedTimePeriodKey(selectedPeriod as CallIntelTypes.SelectedTimePeriodKey);
            !!onApplyingFilters && onApplyingFilters();
            setFilters({ endDate, startDate });
          }}
          persistDefaultLabel
          trackingIdBase={trackingIds.callIntel.timeFilter}
        />
      )}

      {!hideFilters?.includes('contactTypes') && (
        <CheckListPopoverSelector<CallIntelTypes.ContactTypeFilterEnum>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          defaultValue={defaultFilters.contactTypes}
          disabled={isLoadingData}
          label={t('Contact Type')}
          onChange={(value) => handleContactTypeChange(value, true)}
          options={getOptions('contactType')}
          renderIcon={(isFilterActive) => <FilterIcon iconType='contacts' isFilterActive={isFilterActive} />}
          trackingId={trackingIds.callIntel.contactTypesSelector}
          value={filters.contactTypes}
        />
      )}

      {activeTab === 'overview' && !hideFilters?.includes('officeUsers') && (
        <CheckListPopoverSelector<string>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          defaultValue={defaultFilters.officeUsers}
          disabled={isLoadingData}
          label={t('Office User')}
          onChange={(value) => handleOfficeUsersChange(value, true)}
          options={officeUsers ?? {}}
          renderIcon={(isFilterActive) => <FilterIcon iconType='user' isFilterActive={isFilterActive} />}
          trackingId={trackingIds.callIntel.officeUsersSelector}
          value={filters.officeUsers}
        />
      )}

      {activeTab === 'records' && !hideFilters?.includes('schedulingOutcomes') && (
        <>
          <CheckListPopoverSelector<CallIntelTypes.SchedulingOutcomeEnum>
            customStyles={{ chipDropdownFilter: { width: '100%' } }}
            defaultValue={defaultFilters.schedulingOutcomes}
            disabled={
              isLoadingData ||
              filters.schedulingOpportunity ===
                CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED
            }
            label={t('Scheduling Outcome')}
            onChange={(value) => handleSchedulingOutcomeChange(value, true)}
            options={getOptions('schedulingOutcome', 'filterRecord')}
            renderIcon={(isFilterActive) => <FilterIcon iconType='calendar' isFilterActive={isFilterActive} />}
            trackingId={trackingIds.callIntel.schedulingOutcomesFilter}
            value={filters.schedulingOutcomes}
          />
        </>
      )}
    </>
  );

  /**
   *  This function renders the tray filters (table filter tray).
   */
  const renderTrayFilters = () => (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData || isLoadingLocations}
      headerLabel={t('Filter Call Intelligence')}
      hideFiltersIcon={!!modalProps}
      modalProps={modalProps}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={handleResetFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={showFilteredBadge}
      trackingId={trackingIds.callIntel.filtersOpen}
    >
      {isMultiLocation && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingLocations}
              locations={locations}
              onChange={(value) => handleUpdateTrayFilters({ locations: value })}
              onError={handleUpdateErrors}
              trackingId={trackingIds.callIntel.locationsSelector}
              value={trayFilters.locations}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Date Range')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodDropDownSelector
            onChange={({ endDate, startDate, selectedPeriod }) => {
              skipNextDateRangeRef.current = true;
              setTrayTimePeriodKey(selectedPeriod as CallIntelTypes.SelectedTimePeriodKey);
              handleUpdateTrayFilters({ endDate, startDate });
            }}
            trackingIdBase={trackingIds.callIntel.timeSelector}
          />
          <DatesRangeSelector
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => {
              const formattedStartDate = dayjs(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
              const formattedEndDate = dayjs(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

              const { startDate: oldStart, endDate: oldEnd } = lastDatesRef.current;

              if (formattedStartDate == oldStart && formattedEndDate == oldEnd) return;

              lastDatesRef.current = { startDate: formattedStartDate, endDate: formattedEndDate };

              if (skipNextDateRangeRef.current) {
                skipNextDateRangeRef.current = false;
              } else {
                // User has truly changed the date range manually => clear the timePeriodKey
                setTrayTimePeriodKey(null);
              }

              handleUpdateTrayFilters({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
              });
            }}
            startDate={trayFilters.startDate}
          />
        </div>
      </TableFilters.Section>

      {activeTab === 'records' && (
        <>
          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('contactTypes', [])}
            sectionHeaderLabel={t('Contact Type')}
          >
            <div css={filtersStyles.traySection}>
              <CheckListSelector<CallIntelTypes.ContactTypeFilterEnum>
                label={t('Contact Type')}
                onChange={handleContactTypeChange}
                options={getOptions('contactType')}
                showLabel={false}
                trackingIdBase={trackingIds.callIntel.contactTypesFilter}
                value={trayFilters.contactTypes}
              />
            </div>
          </TableFilters.Section>

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('callDirection')}
            sectionHeaderLabel={t('Call Direction')}
          >
            <div css={filtersStyles.traySection}>
              <RadioFilterSelector<CallIntelTypes.CallDirectionEnum>
                label={t('Call Direction')}
                onChange={(value) => handleUpdateTrayFilters({ callDirection: value })}
                options={getOptions('callDirection')}
                showLabel={false}
                trackingId={trackingIds.callIntel.callDirectionFilter}
                value={trayFilters?.callDirection}
                withBorder={false}
              />
            </div>
          </TableFilters.Section>
          {officeUsers && Object.keys(officeUsers).length && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('officeUsers')}
              sectionHeaderLabel={t('Office User')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  label={t('Select Office User')}
                  onChange={(value) => handleUpdateTrayFilters({ officeUsers: value })}
                  options={Object.entries(officeUsers).map(([id, label]) => ({
                    id,
                    label,
                  }))}
                  trackingId={
                    trackingOptions
                      ? generateTrackingId({
                          ...trackingOptions,
                          component: 'office-users-filter',
                        })
                      : trackingIds.callIntel.officeUsersFilter
                  }
                  value={trayFilters.officeUsers}
                />
              </div>
            </TableFilters.Section>
          )}

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('schedulingOpportunity')}
            sectionHeaderLabel={t('Scheduling Opportunity')}
          >
            <div css={filtersStyles.traySection}>
              <RadioFilterSelector<string>
                label={t('Scheduling Opportunity')}
                onChange={(value) => {
                  if (value === CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED) {
                    handleSchedulingOutcomeChange([]);
                  }
                  handleUpdateTrayFilters({ schedulingOpportunity: value });
                }}
                options={getOptions('schedulingOpportunity', 'filterRecord')}
                showLabel={false}
                subContent={{
                  [CallIntelTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: (
                    <>
                      <Text style={{ marginBottom: theme.spacing(2) }} weight='semibold'>
                        {t('Scheduling Outcome')}
                      </Text>
                      <CheckListSelector<CallIntelTypes.SchedulingOutcomeEnum>
                        label={t('Scheduling Outcome')}
                        onChange={handleSchedulingOutcomeChange}
                        options={getOptions('schedulingOutcome', 'filterRecord')}
                        showLabel={false}
                        trackingIdBase={trackingIds.callIntel.schedulingOpportunitiesIdentifiedFilter}
                        value={trayFilters.schedulingOutcomes}
                      />
                    </>
                  ),
                }}
                trackingId={trackingIds.callIntel.schedulingOpportunitiesFilter}
                value={trayFilters.schedulingOpportunity}
              />
            </div>
          </TableFilters.Section>

          {!hideFilters?.includes('sentiments') && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('sentiments')}
              sectionHeaderLabel={t('Customer Sentiment')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  css={styles.sentimentDropDown}
                  label={t('Select Customer Sentiments')}
                  onChange={(value) => handleUpdateTrayFilters({ sentiments: value })}
                  options={getOptions('sentiment', 'array')}
                  trackingId={trackingIds.callIntel.customerSentimentFilter}
                  value={trayFilters.sentiments}
                />
              </div>
            </TableFilters.Section>
          )}
          {!hideFilters?.includes('categories') && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('categories')}
              sectionHeaderLabel={t('Category')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  label={t('Select Categories')}
                  onChange={(value) => handleUpdateTrayFilters({ categories: value })}
                  options={getOptions('categories', 'array')}
                  trackingId={trackingIds.callIntel.categoriesFilter}
                  value={trayFilters.categories}
                />
              </div>
            </TableFilters.Section>
          )}

          {!hideFilters?.includes('appointmentTypes') && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('appointmentTypes')}
              sectionHeaderLabel={t('Appointment Type')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  label={t('Select Appointment Types')}
                  onChange={(value) => handleUpdateTrayFilters({ appointmentTypes: value })}
                  options={getOptions('appointmentTypes', 'array')}
                  trackingId={trackingIds.callIntel.appointmentTypesFilter}
                  value={trayFilters.appointmentTypes}
                />
              </div>
            </TableFilters.Section>
          )}

          {officeNumbers?.length && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('officeNumbers')}
              sectionHeaderLabel={t('Office Number')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  label={t('Select Office Number')}
                  onChange={(value) => handleUpdateTrayFilters({ officeNumbers: value })}
                  options={officeNumbers.map((number) => ({
                    id: number,
                    label: formatPhoneNumber(number),
                  }))}
                  trackingId={trackingIds.callIntel.officeNumbersFilter}
                  value={trayFilters.officeNumbers}
                />
              </div>
            </TableFilters.Section>
          )}

          <TableFilters.Section sectionHeaderLabel={t('Hide Calls')}>
            <div css={filtersStyles.traySection}>
              <CustomCheckboxField
                label={t('Hide calls with no analysis ({{count}})', { count: nonAnalyzedCallsCount })}
                name='toggle-analyzed-calls'
                onChange={(value) => {
                  handleUpdateTrayFilters({ hideNonAnalyzedCalls: value });
                }}
                value={trayFilters.hideNonAnalyzedCalls}
                trackingId={trackingIds.callIntel.hideNonAnalyzedCallsFilter}
              />
            </div>
          </TableFilters.Section>
        </>
      )}

      {!!trayNotificationMessage && <TrayInlineNotification message={trayNotificationMessage} />}
    </FiltersTray>
  );

  /**
   * Sync tray state whenever main filters change, so the tray is always up to date. It also is responsible for showing time-period labels
   */
  useEffect(() => {
    applyActiveFiltersToTrayState();

    const { startDate, endDate } = filters;
    let hintText = '';

    if (selectedPeriod) {
      hintText = timePeriods[selectedPeriod]?.label;
    } else if (startDate && endDate) {
      hintText = humanizeDateDifference(startDate, endDate, true);
    }

    setFilterHintText(hintText);
  }, [filters, timePeriods, selectedPeriod]);

  /**
   * Show or hide notification badge , if parent passes in setShowNotificationBadge.
   */
  useEffect(() => {
    setShowNotificationBadge?.(showFilteredBadge);
  }, [showFilteredBadge]);

  /**
   * Whenever filters or defaults change, reevaluate if we show a "filtered" badge.
   */
  useEffect(() => {
    setShowFilteredBadge(areRecordsFiltered());
  }, [defaultFilters, filters]);

  /**
   * On initial mount, if we have no subView (not a drilldown page), set filters to defaults.
   */
  useEffect(() => {
    if (defaultFilters && !subView.id) {
      setFilters(defaultFilters);
    }
  }, []);

  useEffect(() => {
    // Drill down pages (subview) will retain a few filters from the main page
    if (!subView.id && defaultFilters && callIntelLocationIds.length && Object.keys(locations)?.length) {
      applyDefaultFilters();
    }
  }, [defaultFilters, subView?.id, callIntelLocationIds]);

  /**
   * Conditional render to either show chip filters or tray filters or both.
   */
  if (onlyChipFilters) {
    return renderChipFilters();
  }

  if (onlyTrayFilters) {
    return renderTrayFilters();
  }

  return (
    <div css={filtersStyles.mainWrapper} style={wrapperStyles}>
      {renderChipFilters()}
      {renderTrayFilters()}
      {isLoadingLocations && (
        <LoadingSkeleton css={styles.loadingSkeleton}>
          <Text color='subdued'>{t('Loading Locations...')}</Text>
        </LoadingSkeleton>
      )}
    </div>
  );
};

const styles = {
  sentimentDropDown: css`
    > div:last-of-type {
      max-height: none;
    }
  `,

  chip: css`
    max-width: none;
  `,

  loadingSkeleton: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  `,

  withInfoText: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,

  chipLabel: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,

  trayInlineNotificationWrapper: css`
    position: absolute;
    bottom: 65px;
    padding: ${theme.spacing(2.5, 4)};
    background-color: white;
    z-index: 1;
  `,

  trayInlineNotification: css`
    background-color: ${theme.colors.primary5};
    padding: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.primary50};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: center;
    align-items: center;
  `,
};
