import { css } from '@emotion/react';
import { CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { formatDateInMonthAndDate } from '@frontend/date';
import { FreeTrialActionButton } from '@frontend/free-trials';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Modal, useModalControl } from '@frontend/design-system';
import { MultipleTrials } from '../../components/multiple-trials';
import { FreeTrialsTrackingIds } from '../../tracking-ids';

export const FreeTrialButton = () => {
  const { t } = useTranslation('multipleTrialsModal');
  const { modalProps: multipleTrialsModalProps, triggerProps: multipleTrialsTriggerProps } = useModalControl();
  const { selectedLocationIds } = useAppScopeStore();
  const { customizationFlags } = useAppFlagStore();
  const locationFlags = customizationFlags[selectedLocationIds[0]] ?? {};

  const freeTrialCustomizationFlagDetails = Object.values(locationFlags).find(
    (flag) => flag?.isFreeTrial && flag?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE
  );

  const trialEndDate = freeTrialCustomizationFlagDetails?.endDate
    ? formatDateInMonthAndDate(new Date(freeTrialCustomizationFlagDetails?.endDate))
    : '';

  if (!freeTrialCustomizationFlagDetails || !trialEndDate) return null;

  return (
    <>
      <div css={freeTrialButtonContainer}>
        <FreeTrialActionButton
          trackingId={FreeTrialsTrackingIds.freeTrialButton}
          onActionClick={multipleTrialsTriggerProps.onClick}
          color={theme.colors.white}
        />
      </div>
      <Modal {...multipleTrialsModalProps} maxWidth={600}>
        <Modal.Header onClose={multipleTrialsModalProps.onClose}>{t('Active Free Trials')}</Modal.Header>
        <MultipleTrials onModalClose={multipleTrialsModalProps.onClose} />
      </Modal>
    </>
  );
};

const freeTrialButtonContainer = css({ padding: theme.spacing(2), display: 'flex', justifyContent: 'center' });
