import { TextSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { DynamicFieldKey_Enum as DynamicFieldKeys } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { theme } from '@frontend/theme';
import { DynamicFieldIconSmall, RTEditor } from '@frontend/design-system';
import { DEFAULT_PALETTE } from '../../../constants';
import { useComposerSection, useDynamicFields } from '../../../hooks';
import { useContentComposer } from '../../content-composer-provider';
import { ExpansionButton, FloatingToolbarCenter, PopoverButtonGroup, ToolbarButtonGroup } from '.';

const EXTRA_LARGE_BREAKPOINT = 1550;
const LARGE_BREAKPOINT = 1450;
const MEDIUM_BREAKPOINT = 850;
const SMALL_BREAKPOINT = 600;

interface Props {
  children: React.ReactNode;
  selectedId: string;
  id: string;
  initialHTML?: string;
}

const hiddenFeedbackDynamicFields: DynamicFieldKeys[] = [
  DynamicFieldKeys.UNKNOWN,
  DynamicFieldKeys.REVIEW_REQUEST_LINK,
];

const hiddenBulkEmailDynamicFields: DynamicFieldKeys[] = [
  DynamicFieldKeys.PREFERENCE_MANAGEMENT_LINK,
  DynamicFieldKeys.UNKNOWN,
  DynamicFieldKeys.UNSUBSCRIBE_LINK,
];

export const RTEToolbar = ({ children, id, initialHTML, selectedId }: Props) => {
  const { composerMode, deviceSizeProps } = useContentComposer();
  const isFeedbackMode = composerMode === 'feedback';
  const isDeviceSizeMobile = deviceSizeProps?.value === 'mobile';
  const hiddenDynamicFields = isFeedbackMode ? hiddenFeedbackDynamicFields : hiddenBulkEmailDynamicFields;
  const { dynamicFields } = useDynamicFields(composerMode);
  const visibleDynamicFields = dynamicFields.filter((field) => !hiddenDynamicFields.includes(field.key));
  const { setProps } = useComposerSection<TextSection>();

  return (
    <RTEditor
      initialHTML={initialHTML}
      uniqueId={`content-section-${id}`}
      onHTMLChange={(html) => setProps((props) => (props.text = html))}
      saveToLocalStorage={false}
      dynamicFields={visibleDynamicFields}
      hideFloatingToolbar
      hasDraggableBlocks={false}
    >
      {children}
      {selectedId === id && (
        <FloatingToolbarCenter
          orientation='bottom'
          css={{
            '.toolbar-container': {
              width: 'fit-content',
              margin: 0,
              padding: 0,
              button: {
                minWidth: 28,
                fontWeight: theme.font.weight.bold,
              },
            },
          }}
        >
          <RTEditor.Toolbar>
            <RTEditor.Toolbar.FontPicker />
            <ToolbarButtonGroup breakpoint={SMALL_BREAKPOINT}>
              <RTEditor.Toolbar.FontSize />
            </ToolbarButtonGroup>
            <ToolbarButtonGroup breakpoint={MEDIUM_BREAKPOINT}>
              <RTEditor.Toolbar.FontColor palette={DEFAULT_PALETTE} />
              <RTEditor.Toolbar.Bold />
              <RTEditor.Toolbar.Underline />
              <RTEditor.Toolbar.Italic />
            </ToolbarButtonGroup>
            <ToolbarButtonGroup breakpoint={isDeviceSizeMobile ? EXTRA_LARGE_BREAKPOINT : LARGE_BREAKPOINT}>
              <RTEditor.Toolbar.ElementFormat />
              <RTEditor.Toolbar.EmojiPicker />
              {/* Link doesn't seems to work. Maybe hide in all modes */}
              {!isFeedbackMode && <RTEditor.Toolbar.Link />}
              <RTEditor.Toolbar.DynamicField fields={visibleDynamicFields} noTransform />
            </ToolbarButtonGroup>
            <ExpansionButton breakpoint={isDeviceSizeMobile ? EXTRA_LARGE_BREAKPOINT : LARGE_BREAKPOINT}>
              <PopoverButtonGroup breakpoint={SMALL_BREAKPOINT}>
                <RTEditor.Toolbar.FontSize />
              </PopoverButtonGroup>
              <PopoverButtonGroup breakpoint={MEDIUM_BREAKPOINT}>
                <RTEditor.Toolbar.FontColor palette={DEFAULT_PALETTE} />
                <RTEditor.Toolbar.Bold />
                <RTEditor.Toolbar.Underline />
                <RTEditor.Toolbar.Italic />
              </PopoverButtonGroup>
              <RTEditor.Toolbar.ElementFormat />
              <RTEditor.Toolbar.EmojiPicker />
              {/* Link doesn't seems to work. Maybe hide in all modes */}
              {!isFeedbackMode && <RTEditor.Toolbar.Link />}
              <RTEditor.Toolbar.DynamicField
                fields={visibleDynamicFields}
                buttonLabel={<DynamicFieldIconSmall />}
                noTransform
              />
            </ExpansionButton>
          </RTEditor.Toolbar>
        </FloatingToolbarCenter>
      )}
    </RTEditor>
  );
};
