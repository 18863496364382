import { useEffect, useMemo, useState } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InsuranceDetailsConstants } from '@frontend/insurance-details';
import { useGetWeaveInsuranceVerificationCustomizationFlagDetails } from '@frontend/insurance-verification';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Text,
  Tray,
  Accordion,
  NotificationBadge,
  ChecklistField,
  useDebouncedValue,
  useFormField,
  SwitchField,
} from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../../context/AppointmentsCalendarViewContext';
import { useCalendarViewRefetchMethod } from '../../../../../context/CalendarViewRefetchMethodContext';
import { useGetIntegrationDetails, useGetLocalStorageLocationIdAndUserId } from '../../../../../hooks';
import { useCalendarHeaderFilterShallowStore } from '../../../../../stores';
import { hasSameIds } from '../../../../../utils';
import { getCombinedAppointmentTypes } from '../../../../../utils/get-combined-appointment-types';
import { getFilteredAppointments, getLocalStorageProviderListDetails } from '../../../helpers';
import { HeaderBarFiltersTrackingIds } from '../trackingIds';
import { AppointmentTypesCheckList } from './AppointmentTypesCheckList';
import { InsuranceStatusCheckList } from './InsuranceStatusCheckList';
import { MultiLocationSelector } from './MultiLocationSelector';
import { Clear, ProviderCheckList, ProviderSearchField, SelectAll } from './ProviderCheckList';

const { INSURANCE_STATUS_MAPPING } = InsuranceDetailsConstants;

const INSURANCE_STATUS_COUNT = INSURANCE_STATUS_MAPPING.size;

const INSURANCE_STATUS_VARIANTS = [...INSURANCE_STATUS_MAPPING.values()].map((status) =>
  status.text.toLowerCase().replace(' ', '_')
);

type CalendarFilterProps = {
  closeModal: () => void;
};

export const CalendarFilter = ({ closeModal }: CalendarFilterProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');

  const { selectedLocationIds } = useAppScopeStore();

  const { isIntegratedOffice } = useGetIntegrationDetails({ selectedLocationId: selectedLocationIds[0] });

  const { isFeatureHiddenInAllLocation } = useGetWeaveInsuranceVerificationCustomizationFlagDetails();

  //NOTE - Check if the office is integrated and has Weave Verify enabled, otherwise don't show the ability to filter by insurance status.
  const canUseInsuranceFilters = isIntegratedOffice && !isFeatureHiddenInAllLocation;

  const { localStorageLocationIdKey, userId } = useGetLocalStorageLocationIdAndUserId();

  const [isFilterStateChanged, setIsFilterStateChanged] = useState<boolean>(false);

  const {
    selectedLocationIds: calendarLocationIds,
    providersList: defaultCalendarProvidersList,
    setSelectedMultiLocationIds,
    defaultFilteredLocationIds,
    appointments: calendarViewAppointments,
  } = useAppointmentsCalendarViewProps();

  const { refetchAppointments } = useCalendarViewRefetchMethod();

  const [locationIds, setLocationIds] = useState<string[]>(calendarLocationIds);

  const {
    isUnConfirmationStatusSelected,
    defaultProviders,
    selectedProvidersList,
    defaultAppointmentTypes,
    selectedAppointmentTypesList,
    setSelectedAppointmentTypesList,
    setIsUnConfirmationStatusSelected,
    setFilteredAppointmentList,
    setIsFiltersApplied,
    setSelectedProvidersList: updateSelectedProvidersList,
    resetSelectedProvidersList,
    resetFiltersData,
    setDefaultProviders,
    selectedInsuranceVerificationStatusList,
    setSelectedInsuranceVerificationStatusList,
  } = useCalendarHeaderFilterShallowStore(
    'isUnConfirmationStatusSelected',
    'defaultProviders',
    'selectedProvidersList',
    'defaultAppointmentTypes',
    'selectedAppointmentTypesList',
    'setSelectedAppointmentTypesList',
    'setIsUnConfirmationStatusSelected',
    'setFilteredAppointmentList',
    'setIsFiltersApplied',
    'resetSelectedProvidersList',
    'resetFiltersData',
    'setDefaultProviders',
    'setSelectedProvidersList',
    'selectedInsuranceVerificationStatusList',
    'setSelectedInsuranceVerificationStatusList'
  );

  const { appointments } = calendarViewAppointments;

  const confirmationStatusField = useFormField(
    {
      type: 'switch',
      value: isUnConfirmationStatusSelected,
    },
    [isUnConfirmationStatusSelected]
  );

  const filteredProvidersByLocation = useMemo(() => {
    return (
      defaultProviders?.filter((provider) => {
        return locationIds.includes(provider.locationId ?? '');
      }) ?? []
    );
  }, [defaultProviders, locationIds]);

  const getSelectedProviderIds = (): string[] => {
    const filteredProviderIds = filteredProvidersByLocation.map(({ id }) => id ?? '');

    const { localStorageProviderIdsList } = getLocalStorageProviderListDetails(
      localStorageLocationIdKey,
      userId,
      defaultCalendarProvidersList
    );

    const selectedProviderIds = !!localStorageProviderIdsList?.length
      ? localStorageProviderIdsList
      : !!selectedProvidersList?.length
      ? selectedProvidersList.filter(({ id }) => id && filteredProviderIds.includes(id))?.map(({ id }) => id ?? '')
      : filteredProviderIds;

    return selectedProviderIds;
  };

  const providerCheckListField = useFormField(
    {
      type: 'checklist',
      value: getSelectedProviderIds(),
      minRequired: 1,
    },
    [locationIds, selectedProvidersList, filteredProvidersByLocation]
  );

  const insuranceStatusCheckListField = useFormField(
    {
      type: 'checklist',
      value: selectedInsuranceVerificationStatusList,
    },
    [selectedInsuranceVerificationStatusList]
  );

  const filteredAppointmentTypesByLocationIds = useMemo(() => {
    return defaultAppointmentTypes.filter((appointmentType) => {
      return locationIds.includes(appointmentType.locationId ?? '');
    });
  }, [locationIds, defaultAppointmentTypes]);

  const { combinedAppointmentTypes } = getCombinedAppointmentTypes(
    locationIds,
    filteredAppointmentTypesByLocationIds,
    appointments ?? []
  );

  //TODO - Appointment type id doesn't exist in the appointment data. We'll have to match by type name.
  const getSelectedAppointmentTypesName = () => {
    return (
      combinedAppointmentTypes
        .filter(({ name }) => selectedAppointmentTypesList.includes(name))
        .map(({ name }) => name || '') ?? []
    );
  };

  const appointmentTypesListField = useFormField(
    {
      type: 'checklist',
      value: getSelectedAppointmentTypesName(),
    },
    [selectedAppointmentTypesList, filteredAppointmentTypesByLocationIds]
  );

  const providerSearchField = useFormField({ type: 'text' });
  const debouncedSearchTerm = useDebouncedValue(providerSearchField.value);

  const searchProviders = useMemo(() => {
    return filteredProvidersByLocation.filter(({ publicDisplayName, resourceName, firstName, lastName }) => {
      // Account for the possibility of the provider name being set to publicDisplayName, or resourceName, or firstName + lastName in the use-get-providers-details-for-calendar.ts hook.
      const name = publicDisplayName || resourceName || `${firstName} ${lastName}`.trim();
      const nameMatch = name.toLowerCase().replaceAll(/[.,*()]/g, '');
      const search = debouncedSearchTerm.toLowerCase().replaceAll(/[.,*()]/g, '');
      return nameMatch?.includes(search);
    });
  }, [debouncedSearchTerm, filteredProvidersByLocation]);

  const handleSelectAllProviders = () => {
    providerCheckListField.onChange({
      value: [...searchProviders.map(({ id }) => id), ...providerCheckListField.value],
      name: 'provider filter',
    });
  };

  const handleClearProviders = () => {
    providerCheckListField.onChange({ value: [], name: 'provider filter' });
  };

  const hasSameInsuranceStatuses = hasSameIds(
    insuranceStatusCheckListField.value,
    selectedInsuranceVerificationStatusList
  );

  const applyFilters = () => {
    if (!hasSameInsuranceStatuses) {
      const selectedInsuranceStatusesList =
        insuranceStatusCheckListField.value.map((status) => status.toLowerCase().replace(/[' ']/g, '_')) ?? [];
      setSelectedInsuranceVerificationStatusList(selectedInsuranceStatusesList);
    }
    const providersToUpdate =
      defaultProviders?.filter((provider) => providerCheckListField.value.includes(provider.id ?? '')) || [];

    setSelectedMultiLocationIds(locationIds);
    updateSelectedProvidersList(providersToUpdate, localStorageLocationIdKey, userId || '');
    setSelectedAppointmentTypesList(appointmentTypesListField.value ?? []);
    setIsUnConfirmationStatusSelected(confirmationStatusField.value ?? false);

    const providerList = !!providersToUpdate.length ? providersToUpdate : defaultCalendarProvidersList;

    const appointmentArray: Appointment[] = getFilteredAppointments({
      appointmentsToFilter: appointments || [],
      providerList,
      selectedAppointmentTypes: appointmentTypesListField.value,
      appointmentStatus: confirmationStatusField.value ? 'UNCONFIRMED' : '',
    });

    setFilteredAppointmentList(appointmentArray);
    setIsFiltersApplied(true);
    closeModal();
  };

  const resetFilters = () => {
    setIsFiltersApplied(false);
    setFilteredAppointmentList([]);
    resetSelectedProvidersList(localStorageLocationIdKey, userId || '');
    resetFiltersData();
    setSelectedMultiLocationIds(defaultFilteredLocationIds);
    handleSelectAllProviders();
    setSelectedInsuranceVerificationStatusList([]);
    closeModal();
    refetchAppointments();
  };

  // useEffect to set the default providers as global default providers
  // when the calendarLocationIds and defaultFilteredLocationIds are the same which helps in filtering
  useEffect(() => {
    if (hasSameIds(calendarLocationIds, defaultFilteredLocationIds) && defaultCalendarProvidersList) {
      setDefaultProviders(defaultCalendarProvidersList);
    }
  }, [calendarLocationIds, defaultFilteredLocationIds, defaultCalendarProvidersList]);

  // useEffect to check whether the filter is applied or not
  useEffect(() => {
    const selectedProviderIds = selectedProvidersList?.map(({ id }) => id ?? '').filter((id) => id) ?? [];
    const hasSameProviderIds = hasSameIds(providerCheckListField.value, selectedProviderIds);
    const hasSameLocationIds = hasSameIds(locationIds, calendarLocationIds);
    const hasSameAppointmentTypesIds = hasSameIds(appointmentTypesListField.value ?? [], selectedAppointmentTypesList);
    const hasConfirmationStatusChanged = confirmationStatusField.value !== isUnConfirmationStatusSelected;
    const hasSameInsuranceStatuses = hasSameIds(
      insuranceStatusCheckListField.value,
      selectedInsuranceVerificationStatusList
    );
    const isFilterApplied =
      hasConfirmationStatusChanged ||
      !hasSameAppointmentTypesIds ||
      !hasSameLocationIds ||
      !hasSameProviderIds ||
      !hasSameInsuranceStatuses;

    if (isFilterApplied) {
      setIsFilterStateChanged(true);
    } else {
      setIsFilterStateChanged(false);
    }
  }, [
    confirmationStatusField.value,
    locationIds,
    calendarLocationIds,
    isUnConfirmationStatusSelected,
    selectedAppointmentTypesList,
    selectedProvidersList,
    providerCheckListField.value,
    appointmentTypesListField.value,
    insuranceStatusCheckListField.value,
  ]);

  const handleSelectAllInsuranceStatuses = () => {
    insuranceStatusCheckListField.onChange({
      value: [...INSURANCE_STATUS_VARIANTS, ...insuranceStatusCheckListField.value],
      name: 'calendar-view-insurance-statuses',
    });
  };

  const handleClearInsuranceStatuses = () => {
    insuranceStatusCheckListField.onChange({ value: [], name: 'calendar-view-insurance-statuses' });
  };

  const applyFiltersButtonTrackingId = () => {
    const locationFiltersSelected = !hasSameIds(locationIds, calendarLocationIds) ? '-locations' : '';
    const providerFiltersSelected =
      providerCheckListField.value.length !== filteredProvidersByLocation.length ? '-providers' : '';
    const appointmentTypeFiltersSelected = !!appointmentTypesListField.value?.length ? '-appointmentTypes' : '';
    const confirmationStatusFilterSelected = confirmationStatusField.value ? '-unconfirmedOnly' : '';

    return `${HeaderBarFiltersTrackingIds.applyFiltersButton}${locationFiltersSelected}${providerFiltersSelected}${appointmentTypeFiltersSelected}${confirmationStatusFilterSelected}`;
  };

  const hasOnlyOneLocation = selectedLocationIds.length === 1;

  const providerSelectionCount =
    providerCheckListField.value.length > filteredProvidersByLocation.length
      ? filteredProvidersByLocation.length
      : providerCheckListField.value.length;

  return (
    <>
      {!hasOnlyOneLocation && (
        <MultiLocationSelector
          locationIds={locationIds}
          setLocationIds={setLocationIds}
          defaultFilteredLocationIds={defaultFilteredLocationIds}
        />
      )}

      <Accordion size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'provider list'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold'>
              {t('Provider')}
            </Text>
            <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                {providerSelectionCount}
              </NotificationBadge>
              <Text>of {filteredProvidersByLocation.length}</Text>
            </div>
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <ProviderSearchField searchFieldProps={providerSearchField} />
            {providerCheckListField.error && (
              <Text color='error' size='small'>
                {providerCheckListField.error}
              </Text>
            )}
            <SelectAll
              isDisabled={providerCheckListField.value.length === filteredProvidersByLocation.length}
              trackingId={HeaderBarFiltersTrackingIds.selectAllProvidersButton}
              handleSelectAll={handleSelectAllProviders}
            />
            <Clear
              trackingId={HeaderBarFiltersTrackingIds.clearAllProvidersButton}
              handleClear={handleClearProviders}
            />
            <ProviderCheckList
              searchProviders={searchProviders}
              checkListProps={providerCheckListField}
              locations={locationIds}
              hasOnlyOneLocation={hasOnlyOneLocation}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion shouldRenderBody size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'appointment types'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold' textAlign='left'>
              {t('Appointment Types')}
            </Text>
            <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                {appointmentTypesListField.value?.length}
              </NotificationBadge>
              <Text>{t('of {{count}}', { count: combinedAppointmentTypes.length })}</Text>
            </div>
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <ChecklistField
              {...appointmentTypesListField}
              name='appointmentTypes'
              labelPlacement='right'
              label={''}
              css={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
            >
              <AppointmentTypesCheckList types={combinedAppointmentTypes} />
            </ChecklistField>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion shouldRenderBody size='large' showBoxShadow chevronSize={16}>
        <Accordion.Item value={'appointment status'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Text size='medium' weight='bold' textAlign='left'>
              {t('Confirmation Status')}
            </Text>
            {confirmationStatusField.value && (
              <div
                css={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme.colors.primary50,
                  borderRadius: theme.borderRadius.full,
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                }}
              >
                <Icon name='check-small' size={16} color='white' />
              </div>
            )}
          </Accordion.Header>
          <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <SwitchField
              {...confirmationStatusField}
              name='appointmentStatus'
              labelPlacement='left'
              label={t('Unconfirmed Only')}
              css={{ height: theme.spacing(5) }}
              trackingId={HeaderBarFiltersTrackingIds.unconfirmedOnlyAppointmentsSwitch}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {canUseInsuranceFilters && (
        <Accordion size='large' showBoxShadow chevronSize={16}>
          <Accordion.Item value={'insurance status'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
            <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
              <Text size='medium' weight='bold' textAlign='left'>
                {t('Insurance Verification Status')}
              </Text>
              <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
                  {insuranceStatusCheckListField.value?.length}
                </NotificationBadge>
                <Text>{t('of {{count}}', { count: INSURANCE_STATUS_COUNT })}</Text>
              </div>
            </Accordion.Header>
            <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
              <SelectAll
                isDisabled={insuranceStatusCheckListField.value?.length === INSURANCE_STATUS_COUNT}
                handleSelectAll={handleSelectAllInsuranceStatuses}
                trackingId={HeaderBarFiltersTrackingIds.selectAllInsuranceStatusesFilterButton}
              />
              <Clear
                handleClear={handleClearInsuranceStatuses}
                trackingId={HeaderBarFiltersTrackingIds.clearAllInsuranceStatusesFilterButton}
              />
              <InsuranceStatusCheckList checkListProps={insuranceStatusCheckListField} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      <Tray.Actions
        onPrimaryClick={applyFilters}
        disabledPrimary={!isFilterStateChanged}
        primaryTrackingId={applyFiltersButtonTrackingId()}
        primaryLabel={t('Apply Filters')}
        onSecondaryClick={resetFilters}
        secondaryLabel={t('Reset')}
        secondaryTrackingId={HeaderBarFiltersTrackingIds.resetFiltersButton}
      />
    </>
  );
};
