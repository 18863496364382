import { useState } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { SelectPaymentsTemplateStepContent } from '@frontend/integrated-messaging';
import { CollectPaymentMultiModalInstance, PAYMENT_MULTI_STEPS } from '@frontend/payments-collection-flow';
import { UseInboxTemplateFlowsResults } from '../hooks';

export const MultiCollectSelectInvoiceModal = ({
  show: booleanOrString,
  onClose,
  onInsert,
  onOpenSettings,
  groupId,
  settingsOpenDelay,
  personId,
}: UseInboxTemplateFlowsResults['modalsProps']['selectInvoiceModalProps']) => {
  const booleanShow = booleanOrString === 'hidden' ? false : booleanOrString;

  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel>();

  const handleOnClose = () => {
    onClose?.();
    setSelectedInvoice(undefined);
  };

  const handleOnComplete = () => {
    if (selectedInvoice) {
      onInsert?.({
        invoiceId: selectedInvoice.id,
        textToPayLink: selectedInvoice.links.payment || '',
        template: undefined,
      });
    }
  };

  return (
    <>
      <CollectPaymentMultiModalInstance
        modalProps={{
          show: booleanShow,
          onClose: handleOnClose,
          minWidth: 500,
          maxWidth: 600,
        }}
        multiStepProps={{
          onComplete: handleOnComplete,
        }}
        collectProps={{
          flow: 'insert',
          personId,
          onInvoiceSelected: (invoice) => {
            setSelectedInvoice(invoice);
          },
          renderSelectPaymentsTemplate: ({ invoice, multiStep: { goPrevStep } }) => (
            <SelectPaymentsTemplateStepContent
              selectedInvoice={invoice}
              onClose={onClose}
              groupId={groupId}
              onInsert={onInsert}
              onOpenSettings={onOpenSettings}
              settingsOpenDelay={settingsOpenDelay}
              onClickBack={() => goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select })}
            />
          ),
        }}
      />
    </>
  );
};
