import { useState, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { WeavePopNotification } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';

type SliderProps = {
  currIndex: number;
  total: number;
  onChange: (i: number) => void;
  queue: WeavePopNotification[];
  onClearAll?: () => void;
  disableClearAll?: boolean;
};

const notificationBubbleStyle = ({ show }: { show: boolean; borderColor?: string; backgroundColor?: string }) => css`
  ::before {
    position: absolute;
    background: ${theme.colors.critical50};
    border-radius: ${theme.borderRadius.large};
    border: 1px solid ${theme.colors.white};
    content: '';
    height: 8px;
    width: 8px;
    right: -5px;
    top: -5px;
    opacity: ${show ? 1 : 0};
    transition: opacity ease-in-out 0.3s;
  }
`;

function useVisitedNotifications(notifications: WeavePopNotification[], currentIndex: number): Set<string> {
  const [visitedIds, setVisitedIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    const currentNotification = notifications[currentIndex];
    if (currentNotification) {
      setVisitedIds((prev) => {
        const updated = new Set(prev);
        updated.add(currentNotification.id);
        return updated;
      });
    }
  }, [currentIndex, notifications]);

  return visitedIds;
}

function useRedCardVisible(
  notifications: WeavePopNotification[],
  currentIndex: number,
  visitedIds: Set<string>
): boolean {
  return useMemo(() => {
    return notifications.some((notification) => !visitedIds.has(notification.id));
  }, [currentIndex, notifications, visitedIds]);
}

export const Slider = ({ currIndex, total, onChange, queue, onClearAll, disableClearAll }: SliderProps) => {
  const visitedIds = useVisitedNotifications(queue, currIndex);
  const redCardVisible = useRedCardVisible(queue, currIndex, visitedIds);
  const { t } = useTranslation();

  const handleChange = (newIndex: number) => {
    onChange(newIndex);
  };

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: 40 }}
      exit={{ height: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      css={css`
        overflow: hidden;
        width: 350px;
        height: 40px;
        border-top-right-radius: ${theme.borderRadius.medium};
        border-top-left-radius: ${theme.borderRadius.medium};
      `}
    >
      <motion.div
        css={css`
          display: flex;
          background: ${theme.colors.neutral10};
          padding: ${theme.spacing(0.5)};
          justify-content: flex-end;
          align-items: center;
          height: 40px;
          min-height: 40px;
          border-bottom: 1px solid ${theme.colors.neutral20};
        `}
      >
        {!!onClearAll && (
          <Button
            variant='secondary'
            css={css`
              margin-right: auto;
              border: none;
              font-weight: ${theme.font.weight.regular};
            `}
            trackingId='notifications-slider-btn-clear-all'
            onClick={onClearAll}
            disabled={disableClearAll}
          >
            {t('Clear All')}
          </Button>
        )}
        <Button
          variant='secondary'
          disabled={currIndex <= 0}
          size='small'
          label={t('Previous')}
          iconName='caret-left-small'
          trackingId='notifications-slider-btn-previous'
          onClick={() => handleChange(currIndex - 1)}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 ${theme.spacing(1)};
            min-width: ${theme.spacing(6)};
          `}
        >
          <Text
            size='small'
            css={[
              css`
                padding: ${theme.spacing(0, 0.5)};
                background: ${theme.colors.neutral20};
                border-radius: ${theme.borderRadius.small};
                position: relative;
              `,
              notificationBubbleStyle({
                show: redCardVisible,
                backgroundColor: theme.colors.critical50,
              }),
            ]}
          >
            {currIndex + 1}/{total}
          </Text>
        </div>
        <Button
          variant='secondary'
          disabled={currIndex >= total - 1}
          size='small'
          label={t('Next')}
          iconName='caret-right-small'
          trackingId='notifications-slider-btn-next'
          onClick={() => handleChange(currIndex + 1)}
        />
      </motion.div>
    </motion.div>
  );
};
