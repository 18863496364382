import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { CreateResponse } from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup_service.pb';
import { CallGroupsApi } from '@frontend/api-call-groups';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, useFormField } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { trackingId } from '../../tracking';
import { usePhoneSettingsQuery } from '../../utils/use-phone-settings-query';

interface Props {
  callGroup: CreateResponse;
  setCallGroup?: Dispatch<SetStateAction<CreateResponse>>;
}

export const NewRingtoneSelector = ({ callGroup, setCallGroup }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-groups' });

  const ringtones = usePhoneSettingsQuery({
    queryKey: queryKeys.ringtones(callGroup.callGroupId),
    queryFn: CallGroupsApi.listRingtones,
    select: (data) => {
      const ringtonesMap: { [key: string]: any } = {};

      data.forEach((ringtone) => {
        const id = ringtone.value ?? 'null';
        if (!ringtonesMap[id]) {
          ringtonesMap[id] = ringtone;
        }
      });

      return Object.values(ringtonesMap);
    },
  });

  const ringtoneDropdownProps = useFormField({ type: 'dropdown', value: callGroup.ringtone || 'null' }, [
    callGroup.ringtone,
  ]);

  const handleChange = (ringtone: { value: string }) => {
    !!setCallGroup &&
      setCallGroup((prev) => ({
        ...prev,
        ringtone: ringtone.value,
      }));
  };

  return (
    <section style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      {ringtones.data?.length && (
        <DropdownField
          {...ringtoneDropdownProps}
          css={css`
            max-width: ${theme.spacing(42)};
          `}
          label={t('Select ringtone')}
          name='callGroupRingtone'
          onChange={handleChange}
        >
          {(ringtones.data || []).map(({ label, value }) => (
            <DropdownField.Option
              key={value}
              value={value || 'null'}
              searchValue={label}
              trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'ringtone-select' })}
            >
              {label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      )}
    </section>
  );
};
