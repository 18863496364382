type Params = {
  data: {
    name: string;
    [key: string]: number | string;
  }[];
  index: number;
  isStacked?: boolean;
  length: number;
  name: string;
  nextLegend?: string;
  previousLegend?: string;
  radius: number;
};

// This method is used to apply the radius to the bars in the chart.
// Applicable to the stacked bars only
// Middle segment will skip the radius
export const calcSkipBarRadius = ({
  data,
  index,
  isStacked,
  length,
  name,
  nextLegend,
  previousLegend,
  radius,
}: Params) => {
  const groupData = data.find((d) => d.name === name);

  if (isStacked && length > 1) {
    if (index === 0) {
      // This is the first segment
      const isNextLegendLast = index === length - 2;
      const nextLegendValue = nextLegend ? groupData?.[nextLegend] : 0;
      if (isNextLegendLast && nextLegendValue === 0) {
        return radius;
      }
      return [radius, 0, 0, radius];
    } else if (index === length - 1) {
      // This is the last segment
      const isPreviousLegendFirst = index === 1;
      const previousLegendValue = previousLegend ? groupData?.[previousLegend] : 0;
      if (isPreviousLegendFirst && previousLegendValue === 0) {
        return radius;
      }
      return [0, radius, radius, 0];
    } else {
      return 0;
    }
  }

  return radius;
};
