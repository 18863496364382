import { MerchantPlanTypes } from '@frontend/api-payments';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { PaymentMethodButtons } from './buttons';

export const PaymentMethodsButtonsStripe = () => {
  const {
    stripeData: { enableACHOnFile, stripeLocationId, enableShareInEmail },
    merchant,
  } = useCollectPaymentMultiContext();

  const shouldShowTerminal = () =>
    !!stripeLocationId &&
    !!merchant?.planName &&
    (merchant?.planName === MerchantPlanTypes.FullPayments || merchant?.planName === MerchantPlanTypes.Full);

  return (
    <>
      <PaymentMethodButtons.ShareInMessage />

      {enableShareInEmail && <PaymentMethodButtons.ShareInEmail />}

      {shouldShowTerminal() && <PaymentMethodButtons.SelectTerminal />}

      <PaymentMethodButtons.CardOnFile enableACHOnFile={enableACHOnFile} />

      <PaymentMethodButtons.ManualCardEntry />

      <PaymentMethodButtons.Sunbit />
    </>
  );
};
