import { useCallback } from 'react';
import type { ConfigType } from 'dayjs';
import { useAppScopeStore } from '@frontend/scope';
import { formatDateTime, FormatDateTimeOptions } from '../utils/date-helpers';

export const useSchedulingLocationInfo = () => {
  const state = useAppScopeStore();

  const getLocationTimeZone = useCallback((locationId: string) => {
    const location = state.accessibleLocationData?.[locationId];
    return location?.timezone || '';
  }, []);

  const formatLocationTime = useCallback(
    (inputDateTime: ConfigType, locationId: string, options?: Omit<FormatDateTimeOptions, 'fromTimezone'>) => {
      if (!locationId) {
        console.error('Location ID is required for date-time conversion');
        return '';
      }

      return formatDateTime(inputDateTime, { ...options, fromTimezone: getLocationTimeZone(locationId) });
    },
    []
  );

  return {
    isMultiLocation: !state.isSingleTypeScope,
    parentLocationId: state.selectedGroupId,
    getLocationTimeZone,
    formatLocationTime,
    ...state,
  };
};
