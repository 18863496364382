import { i18next } from '@frontend/i18n';
import { Text } from '@frontend/design-system';

type Props = {
  textColor?: string;
  filteredCount: number;
  totalCount: number;
};

export const RecipientTally = ({ filteredCount, totalCount, textColor }: Props) => {
  if (!totalCount) return null;

  return (
    <Text size='small' css={textColor && { color: textColor }}>
      {getRecipientTallyText(filteredCount, totalCount)}
    </Text>
  );
};

export const getRecipientTallyText = (filteredCount: number, totalCount: number) => {
  if (!totalCount) return '';

  return filteredCount !== undefined
    ? i18next.t('{{filteredCount}} of {{count}} Patients', {
        filteredCount,
        count: totalCount,
        ns: 'schedule-quick-fill',
      })
    : i18next.t('{{count}} Patients', { count: totalCount, ns: 'schedule-quick-fill' });
};
