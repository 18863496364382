import { FC } from 'react';
import { format, isValid } from 'date-fns';
import { FormsSubmission } from '@frontend/api-forms';
import { isoDateToDisplay } from '@frontend/date';
import { Text } from '@frontend/design-system';
import {
  containerStyle,
  eSignFontStyle,
  eSignTimestampStyle,
  labelStyle,
  optionValueStyle,
  valueStyle,
} from './form-field.styles';

interface FormFieldProps {
  field: FormsSubmission.Types.FormFieldData;
}

const FormField: FC<React.PropsWithChildren<FormFieldProps>> = ({ field }) => {
  const fieldType = field.meta.type;

  if (field.value === '<nil>' || ['button', 'text-with-button', 'cardCapture'].includes(fieldType)) {
    return null;
  }

  if (fieldType === 'cardOnFile') {
    return getCardOnFileLayout({
      fieldId: field.id,
      label: field.label || '',
      value: field.value as string,
    });
  }

  if (fieldType === 'achOnFile') {
    return getACHOnFileLayout({
      fieldId: field.id,
      label: field.label || '',
      value: field.value as string,
    });
  }

  if (fieldType === 'eSign') {
    return getElectronicSignatureLayout(field as FormsSubmission.Types.ElectronicSignature);
  }

  if (fieldType === 'richText') {
    return (
      <div key={field.id} css={containerStyle}>
        {field.value === 'null' ? (
          <Text css={valueStyle}>-</Text>
        ) : (
          <div css={valueStyle}>
            <pre
              className='rich-text-container'
              dangerouslySetInnerHTML={{
                __html: field.value as string,
              }}
            ></pre>
          </div>
        )}
      </div>
    );
  }

  if (fieldType === 'checklist') {
    return getCheckboxLayout(field as FormsSubmission.Types.FieldWithOptions);
  }

  if (['radio', 'optionswitch'].includes(fieldType)) {
    return getRadioLayout(field as FormsSubmission.Types.FieldWithOptions);
  }

  if (fieldType === 'date') {
    return getDateFieldLayout(field);
  }

  if (['multiselect-dropdown', 'searchable-listbox'].includes(fieldType)) {
    return getMultiselectDropdownLayout(field as FormsSubmission.Types.MutiselectDropdown);
  }

  if (fieldType === 'select') {
    const dropdownField = field as FormsSubmission.Types.FieldWithOptions;
    const selectedValue = dropdownField.value;
    const options = (
      dropdownField.options ? JSON.parse(dropdownField.options as string) : []
    ) as FormsSubmission.Types.ParsedFormFieldOptions[];
    const selectedOption = options.find((option) => option.value === selectedValue);
    const labelForTheSelectedValue = selectedOption ? selectedOption.label : '';
    return getDefaultFieldLayout(dropdownField.id, dropdownField.label, labelForTheSelectedValue);
  }

  const fieldWithLabel = field as FormsSubmission.Types.FormFieldWithLabel;
  return getDefaultFieldLayout(fieldWithLabel.id, fieldWithLabel.label, fieldWithLabel.value as string | undefined);
};

export default FormField;

function getDefaultFieldLayout(fieldId: string, label: string, value = 'Not answered', highlight = false) {
  return (
    <div key={fieldId} css={containerStyle}>
      <Text color='light' size='medium' css={labelStyle}>
        {label}
      </Text>
      <Text css={valueStyle} className={`fs-mask ${highlight && 'highlight'}`}>
        {value}
      </Text>
    </div>
  );
}

interface GetCardOnFileLayoutPayload {
  fieldId: string;
  label: string;
  value: string;
}

function getCardOnFileLayout({ fieldId, label, value }: GetCardOnFileLayoutPayload) {
  const parsedValue = JSON.parse(value) as FormsSubmission.Types.ParsedCardOnFileValue;
  let content = <Text css={valueStyle}>Not Provided</Text>;

  if (parsedValue && parsedValue.last4) {
    content = (
      <>
        <Text css={valueStyle} className='fs-mask'>
          {parsedValue.nameOnCard}
        </Text>
        <Text css={valueStyle} className='fs-mask'>
          *{parsedValue.last4}
        </Text>
        <Text css={valueStyle} className='fs-mask'>
          {parsedValue.expiry}
        </Text>
        <Text css={valueStyle} className='fs-mask'>
          {parsedValue.country}
        </Text>
      </>
    );
  }

  return (
    <div key={fieldId} css={containerStyle}>
      <Text color='light' size='medium' css={labelStyle}>
        {label}
      </Text>
      {content}
    </div>
  );
}

function getACHOnFileLayout({ fieldId, label, value }: GetCardOnFileLayoutPayload) {
  const parsedValue = JSON.parse(value) as FormsSubmission.Types.ParsedAchOnFileValue;
  let content = <Text css={valueStyle}>Not Provided</Text>;

  if (parsedValue && parsedValue.lastFour) {
    content = (
      <>
        <Text css={valueStyle} className='fs-mask'>
          {parsedValue.bankName}
        </Text>
        <Text css={valueStyle} className='fs-mask'>
          {parsedValue.accountType} account
        </Text>
        <Text css={valueStyle} className='fs-mask'>
          **** {parsedValue.lastFour}
        </Text>
        <Text css={valueStyle} className='fs-mask'>
          {isoDateToDisplay(parsedValue.created, { format: 'MMM D, YYYY, h:mm A' })}
        </Text>
      </>
    );
  }

  return (
    <div key={fieldId} css={containerStyle}>
      <Text color='light' size='medium' css={labelStyle}>
        {label}
      </Text>
      {content}
    </div>
  );
}

function getCheckboxLayout(fieldWithOptions: FormsSubmission.Types.FieldWithOptions) {
  const value = typeof fieldWithOptions.value === 'string' ? fieldWithOptions.value : '';
  const parsedValue = !!value ? (JSON.parse(value) as Array<any>) : '';
  const options = typeof fieldWithOptions.options === 'string' ? fieldWithOptions.options : '';

  if (parsedValue && parsedValue.length > 0) {
    const parsedOptions = JSON.parse(options) as FormsSubmission.Types.ParsedFormFieldOptions[];

    return (
      <div key={fieldWithOptions.id} css={containerStyle}>
        <Text color='light' size='medium' css={labelStyle}>
          {fieldWithOptions.label}
        </Text>

        {Array.isArray(parsedOptions) &&
          parsedOptions.map(
            ({ value: optionValue, label }) =>
              parsedValue.includes(optionValue) && (
                <div key={optionValue} css={optionValueStyle}>
                  <input
                    type='checkbox'
                    key={optionValue}
                    checked={parsedValue.includes(optionValue)}
                    value={optionValue}
                    readOnly
                  />
                  <label className='fs-mask'>{label}</label>
                  <br />
                </div>
              )
          )}
      </div>
    );
  }

  return getDefaultFieldLayout(fieldWithOptions.id, fieldWithOptions.label);
}

function getRadioLayout(fieldWithOptions: FormsSubmission.Types.FieldWithOptions) {
  const value = typeof fieldWithOptions.value === 'string' ? fieldWithOptions.value : '';
  const options = typeof fieldWithOptions.options === 'string' ? fieldWithOptions.options : '';

  if (value) {
    const parsedOptions = JSON.parse(options) as FormsSubmission.Types.ParsedFormFieldOptions[];
    const parsedValue = parsedOptions.find((parsedOption) => parsedOption.value === value)?.label || '-';
    const valuesTobeHighlighted = ['yes', 'sí', 'si'];

    return getDefaultFieldLayout(
      fieldWithOptions.id,
      fieldWithOptions.label,
      parsedValue,
      valuesTobeHighlighted.includes(parsedValue.toLowerCase())
    );
  }

  return getDefaultFieldLayout(fieldWithOptions.id, fieldWithOptions.label);
}

function getDateFieldLayout(field: FormsSubmission.Types.FormFieldData) {
  const fieldWithLabel = field as FormsSubmission.Types.FormFieldWithLabel;
  const value = fieldWithLabel.value as string;
  let parsedValue = value;
  const dateString = value?.split(' ')[0]; // yyyy-mm-dd
  const VALID_DATE_PATTERN = /\d\d\/\d\d\/\d\d\d\d/; // mm/dd/yyyy

  if (isValid(new Date(dateString)) && !VALID_DATE_PATTERN.test(dateString)) {
    const dateStringSplit = dateString.split('-');
    const year = +dateStringSplit[0];
    const month = +dateStringSplit[1] - 1; // JS month starts from 0
    const day = +dateStringSplit[2];
    const date = new Date(year, month, day);
    parsedValue = format(date, 'MM/dd/yyyy');
  }

  return getDefaultFieldLayout(fieldWithLabel.id, fieldWithLabel.label, parsedValue);
}

function getMultiselectDropdownLayout(fieldWithOptions: FormsSubmission.Types.MutiselectDropdown) {
  const selectedValues: string[] = JSON.parse(fieldWithOptions.value as string);
  const options: FormsSubmission.Types.ParsedFormFieldOptions[] = JSON.parse(fieldWithOptions.options as string);

  const optionsMapper: { [key: string]: string } = {};

  options.forEach((item) => {
    optionsMapper[`${item.value}`] = item.label;
  });

  const values = selectedValues.reduce(
    (prev, current, index) => prev + (index === 0 ? '' : ', ') + optionsMapper[current],
    ''
  );

  return (
    <div key={fieldWithOptions.id} css={containerStyle}>
      <Text color='light' size='medium' css={labelStyle}>
        {fieldWithOptions.label}
      </Text>
      <Text css={valueStyle} className='fs-mask'>
        {values || 'Not answered'}
      </Text>
    </div>
  );
}

function getElectronicSignatureLayout(field: FormsSubmission.Types.ElectronicSignature) {
  const fieldValue: FormsSubmission.Types.ElectronicSignatureValue = JSON.parse(field.value as string);
  if (['wet', 'image'].includes(fieldValue.type)) {
    return null;
  }
  if (fieldValue.data === '')
    return (
      <div key={field.id} css={containerStyle}>
        <Text>No Signatures Added</Text>
      </div>
    );

  return (
    <div key={field.id} css={containerStyle}>
      <Text color='light' size='medium' css={labelStyle}>
        {field.label}
      </Text>
      <Text css={eSignFontStyle(fieldValue.font_type)}>{fieldValue.data}</Text>
      <Text css={eSignTimestampStyle}>Date: {fieldValue.timestamp}</Text>
    </div>
  );
}
