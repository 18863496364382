import { css } from '@emotion/react';
import { useTranslation, i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';
import SunbitIcon from '../../../assets/sunbit.svg?react';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { useValidEmailFromInvoice } from '../../../hooks/use-valid-email-from-invoice';
import { useValidPhoneNumberFromInvoice } from '../../../hooks/use-valid-phone-number-from-invoice';
import { PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { PaymentMethodButton, PaymentMethodButtonProps } from '../../atoms/payment-method-button';

const customerPhoneNumberError = i18next.t('No valid phone number', { ns: 'payments' });
const customerEmailError = i18next.t('No valid email id', { ns: 'payments' });

const ShareInEmail: React.FC<PaymentMethodButtonProps> = ({ onClick, ...props }) => {
  const { t } = useTranslation('payments');
  const {
    invoice,
    invoiceIsPaid,
    multiStep: { goNextStep },
  } = useCollectPaymentMultiContext();

  const { firstValidEmail } = useValidEmailFromInvoice(invoice);

  const { trackingId } = useScopedTrackingIds({ trackingId: `payment-methods--${PAYMENT_MULTI_STEPS.shareInEmail}` });

  const {
    Tooltip: EmailTooltip,
    triggerProps: EmailTooltipTriggerProps,
    tooltipProps: EmailTooltipProps,
  } = useTooltip();

  return (
    <PaymentMethodButton
      trackingId={trackingId}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        goNextStep({ orStep: PAYMENT_MULTI_STEPS.shareInEmail });
        onClick?.(e);
      }}
      iconName='email'
      {...(!firstValidEmail ? EmailTooltipTriggerProps : {})}
      {...props}
      disabled={invoiceIsPaid || props.disabled}
    >
      {t('Share in Email')}
      <EmailTooltip {...EmailTooltipProps}>{customerEmailError}</EmailTooltip>
    </PaymentMethodButton>
  );
};
const ShareInMessage: React.FC<PaymentMethodButtonProps> = ({ onClick, ...props }) => {
  const { t } = useTranslation('payments');
  const {
    invoice,
    invoiceIsPaid,
    multiStep: { goNextStep },
  } = useCollectPaymentMultiContext();

  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);

  const { trackingId } = useScopedTrackingIds({ trackingId: `payment-methods--${PAYMENT_MULTI_STEPS.shareInMessage}` });

  const { Tooltip: TtpTooltip, triggerProps: ttpTooltipTriggerProps, tooltipProps: ttpTooltipProps } = useTooltip();

  return (
    <PaymentMethodButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        goNextStep({ orStep: PAYMENT_MULTI_STEPS.shareInMessage });
        onClick?.(e);
      }}
      trackingId={trackingId}
      iconName='messaging'
      {...(!firstValidPhoneNumber ? ttpTooltipTriggerProps : {})}
      {...props}
      disabled={invoiceIsPaid || !firstValidPhoneNumber || props.disabled}
    >
      {t('Text to Pay')}
      <TtpTooltip {...ttpTooltipProps}>{customerPhoneNumberError}</TtpTooltip>
    </PaymentMethodButton>
  );
};

const SelectTerminal: React.FC<PaymentMethodButtonProps> = ({ onClick, ...props }) => {
  const { t } = useTranslation('payments');
  const {
    invoiceIsPaid,
    multiStep: { goNextStep },
  } = useCollectPaymentMultiContext();

  const { trackingId } = useScopedTrackingIds({ trackingId: `payment-methods--${PAYMENT_MULTI_STEPS.selectTerminal}` });
  return (
    <PaymentMethodButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        goNextStep({ orStep: PAYMENT_MULTI_STEPS.selectTerminal });
        onClick?.(e);
      }}
      iconName='credit-card'
      trackingId={trackingId}
      {...props}
      disabled={invoiceIsPaid || props.disabled}
    >
      {t('Pay at Terminal')}
    </PaymentMethodButton>
  );
};

const CardOnFile: React.FC<PaymentMethodButtonProps & { enableACHOnFile: boolean }> = ({
  onClick,
  enableACHOnFile,
  ...props
}) => {
  const { t } = useTranslation('payments');
  const {
    invoiceIsPaid,
    multiStep: { goNextStep },
  } = useCollectPaymentMultiContext();

  const { trackingId } = useScopedTrackingIds({ trackingId: `payment-methods--${PAYMENT_MULTI_STEPS.cardOnFile}` });

  return (
    <PaymentMethodButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        goNextStep({ orStep: PAYMENT_MULTI_STEPS.cardOnFile });
        onClick?.(e);
      }}
      iconName='user'
      trackingId={trackingId}
      {...props}
      disabled={invoiceIsPaid || props.disabled}
    >
      {enableACHOnFile ? t('Card/ACH on File') : t('Card on File')}
    </PaymentMethodButton>
  );
};

const ManualCardEntry: React.FC<PaymentMethodButtonProps> = ({ onClick, ...props }) => {
  const { t } = useTranslation('payments');
  const {
    invoiceIsPaid,
    multiStep: { goNextStep },
  } = useCollectPaymentMultiContext();

  const { trackingId } = useScopedTrackingIds({
    trackingId: `payment-methods--${PAYMENT_MULTI_STEPS.manualCardEntry}`,
  });

  return (
    <PaymentMethodButton
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        goNextStep({ orStep: PAYMENT_MULTI_STEPS.manualCardEntry });
        onClick?.(e);
      }}
      iconName='edit-small'
      trackingId={trackingId}
      {...props}
      disabled={invoiceIsPaid || props.disabled}
    >
      {t('Manual Card Entry')}
    </PaymentMethodButton>
  );
};

const styles = {
  icon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  `,
  sunbitIcon: (isAcceptableSunbitAmount: boolean) =>
    css`
      ${!isAcceptableSunbitAmount && `opacity: 0.25;`};
      margin: 0 auto;
    `,
};

const MINIMUM_SUNBIT_AMOUNT = 6000;

const Sunbit: React.FC<PaymentMethodButtonProps> = ({ onClick, ...props }) => {
  const { t } = useTranslation('payments');
  const {
    invoice,
    invoiceIsPaid,
    multiStep: { goNextStep },
  } = useCollectPaymentMultiContext();

  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);

  const isAcceptableSunbitAmount = !!invoice?.billedAmount && invoice.billedAmount >= MINIMUM_SUNBIT_AMOUNT;

  const {
    Tooltip: SunbitTooltip,
    triggerProps: sunbitTooltipTriggerProps,
    tooltipProps: sunbitTooltipProps,
  } = useTooltip();

  const { trackingId } = useScopedTrackingIds({ trackingId: `payment-methods--${PAYMENT_MULTI_STEPS.sunbit}` });

  return (
    <PaymentMethodButton
      onClick={() => goNextStep({ orStep: PAYMENT_MULTI_STEPS.sunbit })}
      trackingId={trackingId}
      {...(!firstValidPhoneNumber ? sunbitTooltipTriggerProps : {})}
      {...props}
      disabled={invoiceIsPaid || !isAcceptableSunbitAmount || !firstValidPhoneNumber || props.disabled}
    >
      <div>
        <div>
          <SunbitIcon css={[styles.icon, styles.sunbitIcon(isAcceptableSunbitAmount)]} />
          <div>{t('Sunbit')}</div>
        </div>
        {!isAcceptableSunbitAmount && (
          <Text size='small' color='disabled'>
            {t('($60.00 min.)')}
          </Text>
        )}
        {<SunbitTooltip {...sunbitTooltipProps}>{customerPhoneNumberError}</SunbitTooltip>}
      </div>
    </PaymentMethodButton>
  );
};

export const PaymentMethodButtons = {
  ShareInEmail,
  ShareInMessage,
  SelectTerminal,
  CardOnFile,
  ManualCardEntry,
  Sunbit,
};
