import { useCallback } from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { TaskCenterTypes } from '@frontend/api-task-center';
import { useTranslation } from '@frontend/i18n';
import { PanelHeader } from '@frontend/panel-engine';
import { theme } from '@frontend/theme';
import { Button, Tabs } from '@frontend/design-system';
import { useTaskCenterStore } from '../provider';
import { EmptyTask } from './common';
import { TaskItem } from './common/task-item/task-item';

export const TaskList = () => {
  const { t } = useTranslation('task-center');
  const { setShowCreateTask, showCreateTask, tasks } = useTaskCenterStore([
    'setShowCreateTask',
    'showCreateTask',
    'tasks',
  ]);

  const openCreateTask = useCallback(() => setShowCreateTask(true), []);

  return (
    // Adding motion div for future animation collapsing rtl
    <motion.div
      initial={{ display: 'block' }}
      animate={{ display: showCreateTask ? 'none' : 'block' }}
      exit={{ display: 'none' }}
      transition={{ duration: 0 }}
      css={containerStyle}
    >
      <header css={headerStyle}>
        <PanelHeader.Title level={3}>{t('All Tasks')}</PanelHeader.Title>
        <Button iconName='draft' variant='secondary' size='large' onClick={openCreateTask} />
      </header>
      <Tabs initialTab='todo'>
        <Tabs.Bar>
          <Tabs.Tab id='todo' css={tabButtonStyle}>
            {t('To Do')}
          </Tabs.Tab>
          <Tabs.Tab id='in-progress' css={tabButtonStyle}>
            {t('In-Progress')}
          </Tabs.Tab>
          <Tabs.Tab id='done' css={tabButtonStyle}>
            {t('Done')}
          </Tabs.Tab>
          <Tabs.Tab id='created' css={tabButtonStyle}>
            {t('Created')}
          </Tabs.Tab>
        </Tabs.Bar>
        {!!tasks?.length ? (
          <div>
            {tasks.map((task) => (
              // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
              <TaskItem key={task.id} {...task} priority={TaskCenterTypes.Task_Priority.HIGH} />
            ))}
          </div>
        ) : (
          <EmptyTask />
        )}
      </Tabs>
    </motion.div>
  );
};

const containerStyle = css`
  height: 100%;
  width: 100%;
`;

const headerStyle = css`
  padding: ${theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${theme.colors.neutral10};
  border-bottom: 1px solid ${theme.colors.neutral10};
`;

const tabButtonStyle = css`
  flex: 1;
`;
