import { useEffect, useMemo } from 'react';
import { BookingSource } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { formatDate } from '@frontend/date';
import { useBookingSiteStore } from '../../stores';
import { BOOKING_SITE_HTTP_OPTIONS } from '../../utils';

dayjs.extend(utc);

interface Params {
  providerCalendarId: string;
  providerId: string;
}

const MINIMUM_APPT_DURATION_MINUTES = 5;

export const useFetchAndStoreProviderAvailabilities = ({ providerId, providerCalendarId }: Params) => {
  const {
    isSchedularV3Enabled,
    selectedAppointmentType,
    selectedLocationId,
    weekInView,
    setAllProviderAvailabilities,
  } = useBookingSiteStore([
    'isSchedularV3Enabled',
    'selectedAppointmentType',
    'selectedLocationId',
    'weekInView',
    'setAllProviderAvailabilities',
  ]);

  const availabilityQueryV2 = ScheduleQueries.useListAppointmentTypeTimes(
    {
      appointmentTypeId: selectedAppointmentType?.id ?? '',
      locationId: selectedLocationId,
      providerId,
      startDate: weekInView,
      endDate: dayjs(weekInView).endOf('isoWeek').toISOString(),
    },
    { enabled: !isSchedularV3Enabled },
    BOOKING_SITE_HTTP_OPTIONS
  );

  const availabilityQueryV3 = SchedulerV3Queries.useListCalendarAvailabilities(
    {
      calendarId: providerCalendarId,
      locationId: selectedLocationId,
      startDateTime: dayjs(weekInView).utc().format('YYYY-MM-DDTHH:mm:ss'),
      endDateTime: dayjs(weekInView).endOf('isoWeek').utc().format('YYYY-MM-DDTHH:mm:ss'),
      durationMinutes:
        !selectedAppointmentType?.durationMinutes ||
        selectedAppointmentType?.durationMinutes < MINIMUM_APPT_DURATION_MINUTES
          ? MINIMUM_APPT_DURATION_MINUTES
          : selectedAppointmentType?.durationMinutes,
      requestSource: BookingSource.BOOKING_SITE,
    },
    { enabled: isSchedularV3Enabled },
    BOOKING_SITE_HTTP_OPTIONS
  );

  const openSlotList = useMemo<string[]>(() => {
    if (isSchedularV3Enabled) {
      return (availabilityQueryV3.data?.availableSlots ?? [])
        .flatMap(({ date, slots }) => {
          return (slots ?? []).map((slot) => {
            return !!date && !!slot.startTime ? formatDate(`${date} ${slot.startTime}`, 'YYYY-MM-DDTHH:mm:ss') : '';
          });
        })
        .sort((a, b) => dayjs(a).diff(dayjs(b)))
        .filter((slot) => !!slot && dayjs(slot).isAfter(dayjs()));
    }
    return (availabilityQueryV2.data?.data ?? [])
      .map((slot) => slot.startTime ?? '')
      .filter((slot) => !!slot && dayjs(slot).isAfter(dayjs()));
  }, [availabilityQueryV2.data, availabilityQueryV3.data]);

  const isLoading = availabilityQueryV2.isLoading || availabilityQueryV3.isLoading;

  useEffect(() => {
    setAllProviderAvailabilities(providerId, isLoading ? [] : openSlotList);
  }, [openSlotList, isLoading, providerId]);

  return {
    isLoading,
  };
};
