import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ChecklistField } from '@frontend/design-system';

type AppointmentTypesCheckListProps = {
  types: AppointmentTypesTypes.CombinedAppointmentTypes[];
};

export const AppointmentTypesCheckList = ({ types }: AppointmentTypesCheckListProps) => {
  return (
    <>
      {types?.map((type) => (
        // TODO When the appointment has the appointment type id, use it instead of the name
        <ChecklistField.Option key={type.id} name={type.name || type.id || ''}>
          {type.name}
        </ChecklistField.Option>
      ))}
    </>
  );
};
