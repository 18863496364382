// refund origin
import { PaymentOrigin } from '@frontend/api-invoices';

// failed origin
import { SearchFailedPaymentsOrigin } from '../payment-failed/types';

export const mapOriginsGeneric = <Return>(
  map: Record<string | number, string | number>,
  fromValues: (string | number)[],
  defaultValue?: string | number
): Return => {
  const toReturn: (string | number)[] = [];

  fromValues.forEach((item) => {
    if (typeof map[item] !== 'undefined') {
      toReturn.push(map[item]);
    } else if (defaultValue !== undefined && !toReturn.includes(defaultValue)) {
      toReturn.push(defaultValue);
    }
  });

  return toReturn as Return;
};

export const mapPaymentOriginToFailedPaymentsOrigin = (origin: PaymentOrigin[]) => {
  const map = Object.entries(PaymentOrigin).reduce((acc, [_key, value]) => {
    if (typeof value !== 'undefined' && typeof SearchFailedPaymentsOrigin[value] !== 'undefined') {
      return {
        ...acc,
        [value]: SearchFailedPaymentsOrigin[value],
      };
    }

    return acc;
  }, {});

  return mapOriginsGeneric<SearchFailedPaymentsOrigin[]>(map, origin, SearchFailedPaymentsOrigin.UNKNOWN);
};

export const mapFailedPaymentsOriginToPaymentsOrigin = (origin: SearchFailedPaymentsOrigin[]) => {
  const paymentValues = Object.values(PaymentOrigin);
  const map = Object.entries(SearchFailedPaymentsOrigin).reduce((acc, [key, value]) => {
    if (typeof key !== 'undefined' && paymentValues.includes(key as PaymentOrigin)) {
      return {
        ...acc,
        [value]: key,
      };
    }

    return acc;
  }, {});

  return mapOriginsGeneric<PaymentOrigin[]>(map, origin);
};
