import { Icon } from '@frontend/icons';
import { CommonHTMLButtonAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Dot, NakedButton, Text } from '@frontend/design-system';
import { HEADER_HEIGHT } from '../constants';
import { useMiniChatShallowStore } from '../stores';

type Props = CommonHTMLButtonAttributes;

export const DesktopMiniChatRollupButton = ({ onClick, ...rest }: Props) => {
  const { rolledUp, setRolledUp, chats } = useMiniChatShallowStore('rolledUp', 'setRolledUp', 'chats');
  const unreadChatCount = chats.filter(({ unreadCount }) => !!unreadCount).length;

  return (
    <NakedButton
      css={[
        {
          width: '100%',
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTop: `1px solid ${theme.colors.neutral20}`,
          borderLeft: `1px solid ${theme.colors.neutral20}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: HEADER_HEIGHT,
          zIndex: theme.zIndex.high, // so the mini-chats animate under this button on rollup toggle
          ':focus': {
            outline: 'none',
            borderColor: `1px solid ${theme.colors.primary50}`,
          },
          position: 'relative',
        },
        rolledUp
          ? {
              backgroundColor: theme.colors.white,
              ':active, :focus, :hover': {
                backgroundColor: theme.colors.neutral10,
              },
            }
          : {
              backgroundColor: theme.colors.neutral10,
              ':active, :focus, :hover': {
                backgroundColor: theme.colors.neutral20,
              },
            },
      ]}
      onClick={(e) => {
        setRolledUp((prev) => {
          if (!prev) {
            e.currentTarget.blur();
          }

          return !prev;
        });
        onClick?.(e);
      }}
      {...rest}
    >
      <Icon name={rolledUp ? 'message-thread' : 'caret-right-small'} css={{ minWidth: 24 }} />
      {!!rolledUp && <Text size='small'>{chats.length}</Text>}
      {!!unreadChatCount && !!rolledUp && (
        <Dot
          color='critical'
          css={{
            position: 'absolute',
            left: theme.spacing(-0.75),
            top: theme.spacing(-0.75),
            color: theme.font.colors.white,
            fontSize: theme.fontSize(14),
            fontWeight: theme.font.weight.bold,
            width: 18,
            height: 18,
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {unreadChatCount}
        </Dot>
      )}
    </NakedButton>
  );
};

export const MOBILE_ROLLUP_BUTTON_SIZES = {
  height: HEADER_HEIGHT,
  width: 55,
} as const;
