import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface OnlineDotProps {
  isOnline: boolean;
}

export const OnlineDot = ({ isOnline = false }: OnlineDotProps) => {
  return (
    <span css={parentStyle}>
      {isOnline && <div css={onlineDotStyle} className='online-indicator' />}
      {!isOnline && (
        // Add style for hover border as neutral5 when hovered on chat list item.
        <div css={[offlineContainerStyle, centerContainedStyle]} className='online-indicator'>
          <div css={offlineStyle} />
        </div>
      )}
    </span>
  );
};

const centerContainedStyle = css({ display: 'flex', justifyContent: 'center', alignItems: 'center' });

const parentStyle = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
});

const onlineDotStyle = css({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  border: `1px solid ${theme.colors.white}`,
  backgroundColor: theme.colors.success30,
});
const offlineContainerStyle = css({
  width: '11px',
  height: '11px',
  borderRadius: '50%',
  backgroundColor: theme.colors.white,
});
const offlineStyle = css({
  width: '9px',
  height: '9px',
  backgroundColor: theme.colors.white,
  border: `1.5px solid ${theme.colors.neutral30}`,
  borderRadius: '50%',
});
