import { FocusEvent, useMemo } from 'react';
import { css } from '@emotion/react';
import { debounce } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useFormField, TextField } from '@frontend/design-system';

interface ReceiptSectionProps {
  emailFieldProps: ReturnType<typeof useFormField<'email'>>;
}
export const ReceiptSection = ({ emailFieldProps: { onBlur, ...emailFieldProps } }: ReceiptSectionProps) => {
  const { t } = useTranslation('payments');

  const onBlurDebounced = useMemo(() => {
    return debounce((e?: FocusEvent<HTMLInputElement, Element>) => {
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    }, 100);
  }, []);
  return (
    <div
      css={css`
        width: 380px;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
      `}
    >
      <Text weight='bold' size='large'>
        {t('Email Receipt to')}
      </Text>
      <TextField {...emailFieldProps} onBlur={onBlurDebounced} name='email' label={t('Email')} />
    </div>
  );
};
