import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { useAppScopeStore } from '@frontend/scope';

export const useOrgData = () => {
  const { selectedOrgId, accessibleLocationData, selectedLocationIds, selectedGroupId } = useAppScopeStore();
  const locationId = selectedLocationIds[0];
  const locationData = accessibleLocationData?.[locationId] ?? {};
  const { data: organizationsData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId: selectedOrgId });
  const distinctParentIds = [...new Set(organizationsData.map((org) => org.parentId))];
  const locationIdsWithoutParent = organizationsData
    .filter((org) => !distinctParentIds.includes(org.locationId))
    .map((org) => org.locationId)
    .filter(Boolean) as string[];

  return {
    orgId: selectedOrgId,
    organizationsData,
    locationId,
    locationData,
    locationIdsWithoutParent,
    isParentLocation: selectedGroupId === locationId,
  };
};
