/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useRef, useState, type ComponentProps, type ReactNode } from 'react';
import { isEqual, uniq } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { createContext, useContextSelector } from 'use-context-selector';
import { useLocalStorage } from '@frontend/web-storage';
import { _TeamChatActiveConversationProvider } from '../active-conversation.provider/active-conversation.provider';
import { _SubscriptionsManager } from './_subscriptions/_subscriptions.manager';
import { _StreamAPIProvider, _StreamAPIContext } from './team-chat-api-providers/stream-api-provider';
import { useTeamChatApi } from './team-chat-api-providers/use-team-chat-api';

/**
 *
 * Layout of this provider tree
 *      - TeamChatProvider (handles connection, data fetching and exposes the context)
 *          - SubscriptionsManager (handles subscriptions)
 *          - TeamChatActiveConversationProvider (handles active conversation)
 *            - children
 */

export type TeamChatContextValue = ReturnType<typeof useTeamChatProvider>;
const TeamChatContext = createContext<ReturnType<typeof useTeamChatProvider> | undefined>(undefined);

type ProviderProps = {
  orgId: string;
  userId: string;
  api: 'stream' | 'mock';
  children: ReactNode;
};
const _TeamChatProviderInner = ({ orgId, userId, api, children, ...clientProps }: ProviderProps) => {
  const value = useTeamChatProvider({ orgId, userId, api, ...clientProps });
  const { isReady, user } = value;
  return (
    <TeamChatContext.Provider value={value}>
      <_TeamChatActiveConversationProvider
        activeConversationId={value.activeConversationId}
        selectedThreadParentId={value.selectedThreadParentId}
        enabled={isReady}
      >
        {user && (
          <_SubscriptionsManager
            orgId={orgId}
            activeConversationId={value.activeConversationId}
            clientUserId={user.userID}
          />
        )}
        {children}
      </_TeamChatActiveConversationProvider>
    </TeamChatContext.Provider>
  );
};
_TeamChatProviderInner.displayName = 'TeamChatProvider';

export const useTeamChatProvider = ({
  orgId,
  userId: _userId,
  api,
}: {
  orgId: string;
  userId: string;
  api: 'stream' | 'mock';
}) => {
  const queryClient = useQueryClient();
  const [isAsleep, setIsAsleep] = useState(false);
  const [mainPanelView, _setMainPanelView] = useState<
    'mentions' | 'conversation-static' | 'conversation-dynamic' | 'unread' | 'thread' | 'none'
  >('none');
  const [isOpen, setIsOpen] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [activeConversationId, _setActiveConversationId] = useState<string>();
  const [selectedThreadParentId, _setSelectedThreadParentId] = useState<string>();
  const [highlightedMessageId, _setHighlightedMessageId] = useState<string>();
  const [isCurrentUserStatusOpen, _setIsCurrentUserStatusOpen] = useState(false);

  const {
    user,
    setUser,
    isInitialized,
    isInitializing,
    reInitialize,
    initializationError,
    disconnect,
    reconnect,
    destroy,
    useQueryConversations,
    useQueryUnreadMentionsCount,
    useQueryMentions,
    useQueryUsers,
    useMutationUpdateUser,
    useMutationCreateConversation,
    useMutationUpdateConversation,
    useMutationDeleteConversation,
    useMutationAddMembersToConversation,
    useMutationRemoveMembersFromConversation,
  } = useTeamChatApi(api);
  const clientUserId = user?.userID;

  const _sleep = () =>
    disconnect().then(() => {
      setIsAsleep(true);
    });

  const _wake = () =>
    reconnect()
      .catch((err) => {
        console.error('Failed to reconnect client', err);
      })
      .finally(() => {
        setIsAsleep(false);
      });

  const {
    data: otherUsers,
    isLoading: isLoadingUsers,
    isFetched: isUsersFetched,
    remove: _removeUsers,
    error: usersError,
    refetch: refetchUsers,
    set: setUsers,
  } = useQueryUsers({});
  const users = useMemo(() => [...(otherUsers ?? []), ...(user ? [user] : [])], [user, otherUsers]);

  const {
    data: conversations,
    isLoading: isLoadingConversations,
    isFetched: isConversationsFetched,
    remove: _removeConversations,
    error: conversationsError,
    refetch: refetchConversations,
    set: setConversations,
  } = useQueryConversations({});

  const {
    data: unreadMentionsCount,
    isLoading: _isLoadingUnreadMentionsCount,
    isFetched: _isUnreadMentionsCountFetched,
    remove: _removeUnreadMentionsCount,
    error: _unreadMentionsCountError,
    set: setUnreadMentionsCount,
  } = useQueryUnreadMentionsCount({});

  const {
    data: messagesWithMentions,
    isLoading: isLoadingMentions,
    isFetched: isMentionsFetched,
    remove: _removeMentions,
    error: mentionsError,
    set: setMentions,
  } = useQueryMentions(100, 0, {});

  const {
    mutateAsync: updateCurrentUser,
    isLoading: isUpdatingUser,
    error: updateUserError,
  } = useMutationUpdateUser({
    onSuccess: (user) => {
      cache.updateCurrentUser(user);
    },
  });

  const {
    mutateAsync: createConversation,
    isLoading: isCreatingConversation,
    error: createConversationError,
  } = useMutationCreateConversation({
    onSuccess: (conversation) => {
      cache.addConversation(conversation);
      openStaticConversation(conversation.channelId);
    },
  });

  const {
    mutateAsync: updateConversation,
    isLoading: isUpdatingConversation,
    error: updateConversationError,
  } = useMutationUpdateConversation({
    onSuccess: (conversation) => {
      cache.updateConversation(conversation.channelId, conversation);
    },
  });

  const {
    mutateAsync: deleteConversation,
    isLoading: isDeletingConversation,
    error: deleteConversationError,
  } = useMutationDeleteConversation({
    onSuccess: (conversation) => {
      cache.removeConversation(conversation.channelId);
      if (conversation.channelId === activeConversationId) {
        _setActiveConversationId(undefined);
      }
    },
  });

  const { mutateAsync: addMembersToConversation, isLoading: isAddingMembers } = useMutationAddMembersToConversation({
    onSuccess: (userIds, vars) => {
      cache.updateConversation(vars.conversationId, { memberIds: userIds });
    },
  });

  const { mutateAsync: removeMembersFromConversation, isLoading: isRemovingMembers } =
    useMutationRemoveMembersFromConversation({
      onSuccess: (userIds, vars) => {
        cache.updateConversation(vars.conversationId, { memberIds: userIds });
        // userIds returned from the api call contains the users that remain in the conversation
        // if that list doesn't include the current user, we should remove the conversation from the cache
        if (user?.userID && !userIds.includes(user?.userID)) {
          cache.removeConversation(vars.conversationId);
          if (vars.conversationId === activeConversationId) {
            closeConversation();
          }
        }
      },
    });

  const [notificationSettings, setNotificationSettings] = useLocalStorage<{
    showWhenOpen: boolean;
    showOnActiveConversation: boolean;
  }>({
    key: 'team-chat-notification-settings-v2',
    defaultValue: {
      showWhenOpen: false,
      showOnActiveConversation: false,
    },
  });

  /**
   * The cache is for updating the local version of the data without making any api calls
   */

  /** Just appending self user on to the otherUsers so it includes the current user */
  const cache = useMemo(() => {
    const conversationUpdater = (conversationId: string, updater: (conversation: Conversation) => Conversation) => {
      setConversations((prev) => {
        if (!prev) {
          return prev;
        }
        const conversation =
          prev?.dm.find((c) => c.channelId === conversationId) ??
          prev?.groups.find((c) => c.channelId === conversationId);

        if (!conversation) {
          return prev;
        }
        const type = conversation.type === 'DM' ? 'dm' : ('groups' as const);
        const updatedConversation = {
          ...conversation,
          ...updater(conversation),
        } satisfies typeof conversation;
        if (isEqual(conversation, updatedConversation)) {
          return prev;
        }

        const next = prev
          ? {
              dm:
                type === 'dm'
                  ? prev.dm.map((c) => (c.channelId === conversation.channelId ? { ...c, ...updatedConversation } : c))
                  : prev.dm,
              groups:
                type === 'groups'
                  ? prev.groups.map((c) =>
                      c.channelId === conversation.channelId ? { ...c, ...updatedConversation } : c
                    )
                  : prev.groups,
            }
          : prev;

        return next;
      });
    };

    return {
      setUsers,
      setConversations,
      setUnreadMentionsCount,
      updateCurrentUser: (userUpdates: Pick<User, 'firstName' | 'lastName' | 'status'>) => {
        setUser((prev) => (prev ? { ...prev, ...userUpdates } : prev));
      },
      addUser: (user: User) => {
        setUsers((prev) => (prev ? [...prev, user] : [user]));
      },
      removeUser: (userId: string) => {
        setUsers((prev) => (prev ? prev.filter((u) => u.userID !== userId) : prev));
      },
      updateUser: (userId: string, user: Partial<User>) => {
        setUsers((prev) => (prev ? prev.map((u) => (u.userID === userId ? { ...u, ...user } : u)) : prev));
      },

      addConversation: (conversation: Conversation) => {
        const type = conversation.type === 'DM' ? 'dm' : ('groups' as const);
        setConversations((prev) => {
          const isDuplicate = prev?.[type].some((c) => c.channelId === conversation.channelId);
          if (isDuplicate) {
            return prev;
          }
          return prev ? { ...prev, [type]: [...prev[type], conversation] } : prev;
        });
      },

      removeConversation: (conversationId: string) => {
        setConversations((prev) =>
          prev
            ? {
                dm: prev.dm.filter((c) => c.channelId !== conversationId),
                groups: prev.groups.filter((c) => c.channelId !== conversationId),
              }
            : prev
        );
      },
      incrementConversationUnreadCount: (conversationId: string) => {
        conversationUpdater(conversationId, (conversation) => ({
          ...conversation,
          unreadCount: conversation.unreadCount ? conversation.unreadCount + 1 : 1,
        }));
      },
      addUserTypingToConversation: (conversationId: string, userId: string) => {
        conversationUpdater(conversationId, (conversation) => {
          const next = conversation.usersTyping.includes(userId)
            ? conversation
            : {
                ...conversation,
                usersTyping: [...conversation.usersTyping, userId],
              };
          return next;
        });
      },
      removeUserTypingFromConversation: (conversationId: string, userId: string) => {
        conversationUpdater(conversationId, (conversation) => {
          const next = conversation.usersTyping.includes(userId)
            ? {
                ...conversation,
                usersTyping: conversation.usersTyping.filter((u) => u !== userId),
              }
            : conversation;
          return next;
        });
      },
      updateConversation: (
        conversationId: string,
        updates: Partial<Conversation> | Parameters<typeof conversationUpdater>[1]
      ) => {
        return conversationUpdater(conversationId, (conversation) =>
          typeof updates === 'function' ? updates(conversation) : { ...conversation, ...updates }
        );
      },

      removeMentionedMessage: (messageId: string) => {
        setMentions((prev) => (prev ? prev.filter((m) => m.id !== messageId) : []));
      },
      addUnreadMentionedMessage: (message: Message) => {
        setMentions((prev) => (prev ? [{ ...message, isUnread: true }, ...prev] : [message]));
        setUnreadMentionsCount((prev) => (prev ? prev + 1 : 1));
      },
      markMentionedMessageAsRead: (messageId: string) => {
        setMentions((prev) => (prev ? prev.map((m) => (m.id === messageId ? { ...m, isUnread: false } : m)) : prev));
        setUnreadMentionsCount((prev) => Math.max(0, prev ? prev - 1 : 0));
      },
      markConversationMentionedMessagesAsRead: (conversationId: string) => {
        let marked = 0;
        setMentions((prev) => {
          if (!prev) return prev;
          return prev.map((m) => {
            if (m.channelId === conversationId && m.isUnread) {
              marked += 1;
              return { ...m, isUnread: false };
            }
            return m;
          });
        });
        if (marked > 0 && !!unreadMentionsCount) {
          //TODO: for some reason the 'prev' here isn't getting up to date. I'm not sure why, so I'm bypassing it with the local state value for now
          setUnreadMentionsCount((_prev) => Math.max(0, unreadMentionsCount ? unreadMentionsCount - marked : 0));
        }
      },
      removeUnreadMentionedMessage: (messageId: string) => {
        setMentions((prev) => (prev ? prev.filter((m) => m.id !== messageId) : []));
        setUnreadMentionsCount((prev) => Math.max(0, prev ? prev - 1 : 0));
      },

      removeUserFromConversation: (conversationId: string, userId: string) => {
        conversationUpdater(conversationId, (conversation) => ({
          ...conversation,
          memberIds: conversation.memberIds.filter((memberId) => memberId !== userId),
        }));
      },
      addUserToConversation: (conversationId: string, userId: string) => {
        conversationUpdater(conversationId, (conversation) => ({
          ...conversation,
          memberIds: uniq([...conversation.memberIds, userId]),
        }));
      },
      invalidateMessagesCache: (conversationId: string) => {
        const queryKey = ['team-chat', conversationId, 'messages'];
        queryClient.removeQueries(queryKey);
      },
    };
  }, [setUsers, setConversations, setUnreadMentionsCount, setMentions, unreadMentionsCount]);

  const getUser = useCallback((userId: string) => users?.find((u) => u?.userID === userId), [users]);
  const getConversation = useCallback(
    (conversationId: string) =>
      conversations?.dm.find((c) => c.channelId === conversationId) ??
      conversations?.groups.find((c) => c.channelId === conversationId),
    [conversations]
  );

  const getConversationByMemberIds = useCallback(
    (memberIds: string[], type?: 'dm' | 'group', includeSelf = true) => {
      const searchMemberIds = includeSelf ? [...memberIds, clientUserId] : memberIds;
      const findInDms = () =>
        conversations?.dm.find(
          (c) =>
            c.memberIds.length === memberIds.length && //note in the DMs we don't include self
            c.memberIds.every((memberId) => memberIds.includes(memberId))
        );
      const findInGroups = () =>
        conversations?.groups.find(
          (c) =>
            c.memberIds.length === searchMemberIds.length && //in the channels we do include self in the search
            c.memberIds.every((memberId) => searchMemberIds.includes(memberId))
        );
      if (type === 'dm') return findInDms();
      if (type === 'group') return findInGroups();
      return findInDms() ?? findInGroups();
    },
    [conversations, clientUserId]
  );

  const helpers = useMemo(
    () => ({
      getUser,
      getConversation,
      getConversationByMemberIds,
    }),
    [getUser, getConversation, getConversationByMemberIds]
  );

  const unreadMessagesCount = useMemo(
    () =>
      (conversations?.groups.reduce((acc, conversation) => acc + conversation.unreadCount, 0) ?? 0) +
      (conversations?.dm.reduce((acc, conversation) => acc + conversation.unreadCount, 0) ?? 0),
    [conversations?.groups, conversations?.dm]
  );

  const openTeamChat = useCallback(() => {
    setIsOpen(true);
    _wake().then(() => {
      setHasBeenOpened(true);

      //Refetching conversations here because we need to re-watch them, and that happens in the fetch
      refetchConversations();
    });
  }, [_wake, refetchConversations]);

  const closeTeamChat = useCallback(() => {
    _sleep();
    setIsOpen(false);
  }, [_sleep]);
  const focus = useCallback(() => setIsFocused(true), []);
  const unfocus = useCallback(() => setIsFocused(false), []);
  const openUserStatus = useCallback(() => _setIsCurrentUserStatusOpen(true), []);
  const closeUserStatus = useCallback(() => _setIsCurrentUserStatusOpen(false), []);
  const collapseNav = useCallback(() => setIsNavExpanded(false), []);
  const expandNav = useCallback(() => {
    //TODO: shouldn't be able expand on mobile
    setIsNavExpanded(true);
  }, []);

  /**
   * @param type:
   * static means the conversation members are set, and doesn't allow UI to adding/removing members (which changes the conversation )
   * dynamic means the conversation members are allowed to be modified (which changes the conversation id)
   */
  const openThread = useCallback((selectedThreadParentId: string, conversationId: string) => {
    _setSelectedThreadParentId(selectedThreadParentId);
    _setActiveConversationId(conversationId);
    _setMainPanelView('thread');
  }, []);
  const closeThread = useCallback((conversationId: string) => {
    _setSelectedThreadParentId(undefined);
    _setActiveConversationId(conversationId);
    _setMainPanelView('conversation-static');
  }, []);
  const openStaticConversation = useCallback(
    (conversationId: NonNullable<typeof activeConversationId>, highlightedMessageId?: string) => {
      // isAsleep will be true if the user clicks "view" on a notification
      if (isAsleep) {
        _wake();
      }
      _setActiveConversationId(conversationId);
      _setMainPanelView(`conversation-static`);
      _setHighlightedMessageId(highlightedMessageId);
      _setSelectedThreadParentId(undefined);
    },
    [isAsleep, _wake]
  );
  const openDynamicConversation = useCallback((conversationId: typeof activeConversationId) => {
    _setActiveConversationId(conversationId);
    _setMainPanelView(`conversation-dynamic`);
    _setHighlightedMessageId(undefined);
  }, []);
  const openMentions = useCallback(() => {
    _setMainPanelView('mentions');
    _setActiveConversationId(undefined);
    _setHighlightedMessageId(undefined);
  }, []);
  const closeConversation = useCallback(() => {
    collapseNav();
    _setMainPanelView('none');
    _setActiveConversationId(undefined);
    _setSelectedThreadParentId(undefined);
    _setHighlightedMessageId(undefined);
  }, []);

  const restart = useCallback(async () => {
    collapseNav();
    _setActiveConversationId(undefined);
    _setSelectedThreadParentId(undefined);
    _setHighlightedMessageId(undefined);
    _setMainPanelView('none');
    _removeConversations();
    _removeUsers();
    _removeMentions();
    _removeUnreadMentionsCount();
    return disconnect().then(() => {
      setTimeout(() => {
        reInitialize();
      }, 1000);
    });
  }, []);

  type Conversation = NonNullable<typeof conversations>['dm' | 'groups'][number];
  type User = NonNullable<typeof users>[number];
  type Message = NonNullable<typeof messagesWithMentions>[number];

  const isDataLoading = isInitializing || isLoadingUsers || isLoadingConversations;
  const isDataAllFetched = isInitialized && isUsersFetched && isConversationsFetched;
  const dataError = initializationError || usersError || conversationsError;
  const isDataReady = isDataAllFetched && !dataError && !!user;
  const isTeamChatReady = isDataReady && !dataError && !!user && !!users?.length;

  /** When initializing team chat, we need to fetch all of the data, then put it to sleep (disconnect the user) while it's not in use */
  useEffect(() => {
    if (!isDataReady || isOpen) {
      return;
    }
    const timeout = setTimeout(() => {
      _sleep();
    }, 1000);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isDataReady, isOpen]);

  //fully restart team chat after orgId changes
  const prevOrgId = useRef(orgId);
  useEffect(() => {
    if (orgId && orgId !== prevOrgId.current) {
      restart();
    }
    prevOrgId.current = orgId;
  }, [orgId]);

  useEffect(() => {
    return () => {
      destroy();
    };
  }, []);

  const data = {
    isMounted: true,
    api,
    user,
    orgId,
    mainPanelView,
    isOpen,
    hasBeenOpened,
    isCurrentUserStatusOpen,
    isFocused,
    isNavExpanded,
    isAsleep,
    selectedThreadParentId,
    isThread: !!selectedThreadParentId,

    //the storage hook isn't smart enough to know it's non nullable even though there is a default value
    notificationSettings: notificationSettings as NonNullable<typeof notificationSettings>,
    setNotificationSettings,

    openTeamChat,
    closeTeamChat,
    openUserStatus,
    closeUserStatus,
    expandNav,
    collapseNav,
    focus,
    unfocus,

    closeConversation,
    openStaticConversation,
    openDynamicConversation,
    openMentions,
    openThread,
    closeThread,

    isInitializing,
    isInitialized,
    initializationError,

    dataError,
    isDataLoading,
    isReady: isTeamChatReady,
    isDataReady,
    conversations,
    users,
    messagesWithMentions,
    isLoadingMentions,
    isMentionsFetched,
    mentionsError,
    unreadMentionsCount,
    unreadMessagesCount,
    activeConversationId,
    highlightedMessageId,

    createConversation,
    isCreatingConversation,
    createConversationError,
    updateConversation,
    isUpdatingConversation,
    updateConversationError,
    addMembersToConversation,
    isAddingMembers,
    removeMembersFromConversation,
    isRemovingMembers,
    deleteConversation,
    isDeletingConversation,
    deleteConversationError,

    updateCurrentUser,
    isUpdatingUser,
    updateUserError,

    cache,
    helpers,

    refetchUsers,
    refetchConversations,
    restart,
    setUnreadMentionsCount,
    setMentions,
  };
  return data;
};

export const useTeamChatSelector = <T extends any>(selector: (ctx: ReturnType<typeof useTeamChatProvider>) => T) => {
  const data = useContextSelector(TeamChatContext, (ctx) => selector(ctx as ReturnType<typeof useTeamChatProvider>));
  return data as typeof data;
};

export const useTeamChat = <T extends (keyof TeamChatContextValue)[]>(values: T) => {
  const data = useContextSelector(TeamChatContext, (context) =>
    values.reduce((acc, key) => ({ ...acc, [key]: context?.[key] }), {} as Pick<TeamChatContextValue, T[number]>)
  );
  return data;
};

export const TeamChatProvider = ({ children, ...props }: ComponentProps<typeof _TeamChatProviderInner>) => {
  switch (props.api) {
    case 'stream':
      return (
        <_StreamAPIProvider {...props}>
          <_TeamChatProviderInner {...props}>{children}</_TeamChatProviderInner>
        </_StreamAPIProvider>
      );
    case 'mock':
      return <_StreamAPIProvider {...props}>{children}</_StreamAPIProvider>;
    default:
      throw new Error(`Unsupported API type: ${props.api}`);
  }
};
TeamChatProvider.displayName = 'TeamChatProvider';
