import { useCollectPaymentMultiContext } from '../../collect-payment-multi.context';

export type ProcessorSwitchProps = React.PropsWithChildren<{
  stripeContent?: (props: React.PropsWithChildren<object>) => React.ReactNode;
  justifiContent?: (props: React.PropsWithChildren<object>) => React.ReactNode;
}>;
export const ProcessorSwitch = ({
  children,
  stripeContent: StripeContent,
  justifiContent: JustifiContent,
}: ProcessorSwitchProps) => {
  const { activeGateway } = useCollectPaymentMultiContext();

  return (
    <>
      {activeGateway === 'stripe' && typeof StripeContent === 'function' && <StripeContent>{children}</StripeContent>}
      {activeGateway === 'justifi' && typeof JustifiContent === 'function' && (
        <JustifiContent>{children}</JustifiContent>
      )}
    </>
  );
};
