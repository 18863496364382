import React, { forwardRef, useCallback, useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import { motion, MotionProps } from 'motion/react';
import { theme } from '@frontend/theme';
import { WidthKey, WIDTH_DIMS } from './constants';
import { PanelContext } from './context';
import { panelPriority, type PanelConfig } from './PANEL-CONFIG';
import { usePanelStore } from './store';

const createPanelVariants = (width: number) => ({
  initial: { width: 0, opacity: 0 },
  animate: { width: width, opacity: 1 },
});

const panelContainerStyles = (priority: number) => css`
  order: ${priority};
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;

  will-change: width;
  border-left: 1px solid ${theme.colors.neutral20};
  border-top: 1px solid ${theme.colors.neutral20};
`;

const panelSectionStyles = (currentPanelWidth: number) => css`
  min-width: min(${currentPanelWidth}px, 100vw);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

type PanelPortalProps = React.ComponentPropsWithoutRef<'div'> & {
  id: PanelConfig;
  width?: WidthKey;
  children: React.ReactNode;
  onClose: () => void;
  enableAnimations?: boolean;
} & MotionProps;

export const PanelPortal = forwardRef<HTMLDivElement, PanelPortalProps>(
  ({ id, width = 'small', children, onClose, enableAnimations = false, ...rest }, ref) => {
    const currentWidth = usePanelStore((state) => state.currentPanelWidths[id]);
    const { registerPanel, unregisterPanel, trayIds, setLastInteractedWith, setPanelCloseFunction, updatePanelWidth } =
      usePanelStore();
    const priority = panelPriority[id];

    useLayoutEffect(() => {
      registerPanel(id, width, onClose, priority);
      return () => {
        unregisterPanel(id);
      };
    }, []);

    const isInTray = useMemo(() => trayIds.includes(id), [trayIds, id]);
    const trayRoot = useMemo(() => document.getElementById('tray-root'), []);
    const panelRoot = useMemo(() => document.getElementById('panel-root'), []);
    const currentPanelWidth = useMemo(() => WIDTH_DIMS[currentWidth] ?? WIDTH_DIMS[width], [currentWidth, width]);

    const panelVariants = useMemo(() => createPanelVariants(currentPanelWidth), [currentPanelWidth]);

    const handleInteraction = useCallback(() => {
      setLastInteractedWith(id);
    }, [id]);

    useLayoutEffect(() => {
      setPanelCloseFunction(id, onClose);
    }, [onClose]);

    useLayoutEffect(() => {
      const currentWidth = usePanelStore.getState().currentPanelWidths?.[id];
      if (currentWidth !== width) {
        updatePanelWidth(id, width);
      }
    }, [width]);

    if (!trayRoot || !panelRoot) return null;

    const target = isInTray ? trayRoot : panelRoot;

    return (
      <PanelContext.Provider value={{ id }}>
        {createPortal(
          <motion.div
            ref={ref}
            tabIndex={-1}
            variants={enableAnimations ? panelVariants : undefined}
            initial={enableAnimations ? 'initial' : undefined}
            animate={enableAnimations ? 'animate' : undefined}
            exit={enableAnimations ? 'initial' : undefined}
            transition={{ duration: 0.3, ease: 'linear' }}
            style={{ width: currentPanelWidth }}
            css={panelContainerStyles(priority)}
            onFocusCapture={handleInteraction}
            onPointerDown={handleInteraction}
            {...rest}
          >
            <motion.section css={panelSectionStyles(currentPanelWidth)}>{children}</motion.section>
          </motion.div>,
          target
        )}
      </PanelContext.Provider>
    );
  }
);
