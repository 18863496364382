import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { PhoneCallsTypes } from '@frontend/api-phone-calls';
import { ChipVariants } from '@frontend/design-system';
import { CallRecordsFiltersType } from '../../hooks/use-phone-config-state-store';
import { getFormattedCallerName, getFullName } from '../../utils';
import { HydratedCallRecord, RecentCallRecord } from '../all-calls/types';

export const getFallbackCallerName = (callInfo: HydratedCallRecord) => {
  const { direction, callerNumber, dialedNumber } = callInfo;
  return direction === 'inbound'
    ? getFormattedCallerName(callerNumber ?? '')
    : getFormattedCallerName(dialedNumber ?? '');
};

export const getOfficeUser = (callInfo: HydratedCallRecord) => {
  const { user, status, direction, sipName } = callInfo;
  // if it is an inbound call with status abandoned or missed, then show `--`, otherwise use sipName
  const defaultValue =
    direction === 'inbound' && (status === 'abandoned' || status === 'missed') ? '--' : sipName ? sipName : '--';
  const fullName = getFullName(user?.firstName, user?.lastName, defaultValue);
  return fullName;
};

export const getShortHandName = (callInfo: HydratedCallRecord) => {
  const { person } = callInfo;
  return !!person?.firstName ? `${person?.firstName ?? ''}` : getFallbackCallerName(callInfo);
};

export const getRecentCallsSearchQuery = (
  filters: CallRecordsFiltersType | undefined,
  pageSize: number,
  mappedTags: Record<string, Tag>
) => `${JSON.stringify({ ...filters })}-${pageSize}-${Object.keys(mappedTags || {}).length}`;

export const getStatusChipColor = (status: PhoneCallsTypes.CallLog['Status']): ChipVariants => {
  switch (status) {
    case 'abandoned':
      return 'warn';
    case 'missed':
      return 'critical';
    case 'answered':
      return 'primary';
    case 'forwarded':
      return 'eggplant';
    default:
      return 'outline';
  }
};

export const getRecentCallsUniqueKey = (row: RecentCallRecord) => row.id || `${row.channelId}-${row.locationId}`;
