import dayjs, { Dayjs } from 'dayjs';
import { genUUIDV4 } from '@frontend/string';

export type Name = {
  firstName: string;
  lastName: string;
};

const firstNames = [
  'John',
  'Jane',
  'Mary',
  'James',
  'Emma',
  'Robert',
  'Linda',
  'Michael',
  'Patricia',
  'David',
  'Alice',
  'Bob',
  'Charlie',
  'Diana',
  'Eve',
  'Frank',
  'Grace',
  'Helen',
  'Ivan',
  'Julia',
];

const lastNames = [
  'Smith',
  'Johnson',
  'Williams',
  'Brown',
  'Jones',
  'Miller',
  'Davis',
  'Garcia',
  'Rodriguez',
  'Wilson',
  'Anderson',
  'Thomas',
  'Taylor',
  'Moore',
  'Jackson',
  'Martin',
  'Lee',
  'Perez',
  'Thompson',
  'White',
];

// This is an alternative set of names and none of the names are same as the first set
// Usually used when we need to show unique random names in the same scope (eg. practitioners vs patients) to avoid names confusion
const alternativeFirstNames = [
  'Katie',
  'Lily',
  'Mia',
  'Layla',
  'Mila',
  'Aria',
  'Zoe',
  'Ava',
  'Sophia',
  'Amelia',
  'Harper',
  'Ella',
  'Scarlett',
  'Emily',
  'Avery',
  'Abigail',
  'Luna',
  'Chloe',
  'Sofia',
  'Eleanor',
];

const alternativeLastNames = [
  'Adams',
  'Baker',
  'Clark',
  'Dixon',
  'Evans',
  'Foster',
  'Griffin',
  'Harris',
  'Irwin',
  'Jenkins',
  'Keller',
  'Lewis',
  'Mitchell',
  'Nelson',
  'Owens',
  'Parker',
  'Quinn',
  'Reed',
  'Stewart',
  'Turner',
];

const generateRandomInt = (min?: number, max?: number): number => {
  min = Math.ceil(min || 0);
  max = Math.floor(max || 1);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generateRandomFloat = (min?: number, max?: number): number => {
  return Math.random() * (max || 1) + (min || 0);
};

const generateRandomNumberString = (length: number): string => {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += Math.floor(Math.random() * 10);
  }

  return result;
};

const generateRandomDate = (isFutureDate?: boolean, withTime?: boolean): string => {
  const date = dayjs().add(Math.floor(Math.random() * 365), 'day');
  const formatString = withTime ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD';
  return isFutureDate ? date.format(formatString) : date.subtract(365, 'day').format(formatString);
};

export const demoDataUtils = {
  alternativeFirstNames,
  alternativeLastNames,
  dummyLocationId: '00000000-0000-0000-0000-000000000000',
  firstNames,
  generateRandomDate,
  generateRandomInt,
  generateRandomFloat,
  generateRandomNumberString,
  lastNames,

  generateConsecutiveDates: (startDate: Date, count: number): Dayjs[] => {
    const dates = [];

    for (let i = 0; i < count; i++) {
      dates.push(dayjs(startDate).add(i, 'day'));
    }

    return dates;
  },

  getRandomDateAndTime: (isFutureDate?: boolean) => generateRandomDate(isFutureDate, true),

  generateRandomUserNames: (count: number, useAletrnativeList?: boolean): Name[] => {
    const names: Name[] = [];
    const firstNamesList = useAletrnativeList ? alternativeFirstNames : firstNames;
    const lastNamesList = useAletrnativeList ? alternativeLastNames : lastNames;

    for (let i = 0; i < count; i++) {
      const firstName = firstNames[Math.floor(Math.random() * firstNamesList.length)];
      const lastName = lastNames[Math.floor(Math.random() * lastNamesList.length)];
      names.push({ firstName, lastName });
    }

    return names;
  },

  generateRandomUUID: (): string => genUUIDV4(),

  generateRandomBoolean: () => {
    const values = [true, false];
    return values[generateRandomInt()];
  },

  generateRandomGender: () => {
    const genders = ['Female', 'Male'];
    return genders[generateRandomInt()];
  },

  generateRandomPhoneNumber: () => generateRandomNumberString(10),

  generateRandomIntArray: (count?: number) => {
    const array = [];
    const length = count || 10;

    for (let i = 0; i < length; i++) {
      array.push(generateRandomInt(5, 100));
    }

    return array;
  },
};
