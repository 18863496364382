import { payoutsBalanceQuery, BalanceQueryResponse } from '@frontend/api-payouts';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { usePaymentsGraphqlClient } from '../utils';

export const usePayoutsBalanceQuery = () => {
  const { client } = usePaymentsGraphqlClient();
  const { locationIds, getMultiQueryKey } = useMultiQueryUtils();
  const { merchant, paymentsUrl } = useMerchant();

  const { data, isLoading, error } = useLocalizedQuery<BalanceQueryResponse, Error>({
    queryKey: getMultiQueryKey(['balance']),
    enabled: !!paymentsUrl && !!merchant,
    refetchOnWindowFocus: false,
    queryFn: () => client.request(payoutsBalanceQuery, { filter: { locationIDs: locationIds } }),
  });

  return {
    summary: data && data?.balance,
    loading: isLoading,
    error,
  };
};
