import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Modal, ModalControlModalProps, NakedButton, Text } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../constants/self-upgrade';
import { getFeaturePromoActionTrackingId, UpgradeActionButtonTrackingIdMap } from '../../constants/tracking-ids';
import { useGetSelfUpgradeAction } from '../../hooks/useGetSelfUpgradeAction';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { getBundleTrialDays, getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { FeatureUpgradeIcon } from '../feature-upgrade-icon/FeatureUpgradeIcon';

interface FeatureDemoModalProps extends ModalControlModalProps {
  onUpgradeAction?: () => void;
  contentStyles?: Interpolation<Theme>;
}
export const FeatureDemoModal: FC<PropsWithChildren<FeatureDemoModalProps>> = ({
  children,
  contentStyles,
  onUpgradeAction,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'demo-modal' });
  const { feature, isBundleUpgradeEligible, recommendedBundle } = useFeatureSubscription();
  const { actionIconName, actionType, actionTextData, isLoading } = useGetSelfUpgradeAction();

  const getTrackingId = useCallback(
    (action: keyof typeof UpgradeActionButtonTrackingIdMap) => {
      return getFeaturePromoActionTrackingId({
        feature,
        action,
        source: 'demo-modal',
        upgradeType: isBundleUpgradeEligible ? 'bundle' : 'feature',
      });
    },
    [feature, isBundleUpgradeEligible]
  );

  const actionButtonTrackingId = useMemo<string | undefined>(
    () => getTrackingId(actionType),
    [actionType, getTrackingId]
  );

  const handleUpgradeAction = () => {
    onUpgradeAction?.();
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} css={demoModalStyles}>
      <Modal.Header css={modalHeaderStyles}>
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <FeatureUpgradeIcon css={{ aspectRatio: 1, width: 20 }} />
          <Text weight='bold' size='large'>
            {t('This is a demo using sample data.')}
          </Text>
          {!isLoading && actionType !== SelfUpgradeActionType.NONE && (
            <div css={[actionTextStyles, { flex: isBundleUpgradeEligible ? 1 : 0 }]}>
              {isBundleUpgradeEligible && (
                <Text as='span' size='medium'>
                  {t('Included in the {{bundleName}} Bundle. Try {{bundleName}} free for {{count}} days.', {
                    bundleName: getUpgradeBundleDisplayName(recommendedBundle),
                    count: getBundleTrialDays(recommendedBundle),
                  })}
                </Text>
              )}

              <Button
                variant={isBundleUpgradeEligible ? 'primary' : 'tertiary'}
                iconName={isBundleUpgradeEligible ? actionIconName : undefined}
                trackingId={actionButtonTrackingId}
                onClick={handleUpgradeAction}
                size='small'
              >
                {actionTextData?.primaryButtonText ?? ''}
              </Button>
            </div>
          )}

          <NakedButton trackingId={getTrackingId('DISMISS')} onClick={modalProps.onClose} css={closeBtnStyles}>
            <Icon name='x' size={16} />
          </NakedButton>
        </div>
      </Modal.Header>
      <Modal.Body css={[modalBodyStyles, contentStyles]}>{children}</Modal.Body>
    </Modal>
  );
};

const demoModalStyles = css({
  padding: 0,
  minWidth: 'min(75vw, 1200px)',
  overflow: 'hidden',
});

const modalHeaderStyles = css({
  backgroundColor: theme.colors.secondary.seaweed5,
  borderBottom: `1px solid ${theme.colors.secondary.seaweed40}`,
  padding: theme.spacing(1, 2),
});

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  padding: theme.spacing(0),
  margin: theme.spacing(0),
});

const actionTextStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  alignItems: 'center',
});

const closeBtnStyles = css({
  padding: theme.spacing(1),
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.full,
  marginLeft: 'auto',
});
