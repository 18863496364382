import { MouseEvent, ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMediaMatches } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { ButtonBar, PrimaryButton, SecondaryButton } from '@frontend/design-system';
import { useDebugMode } from '../../../hooks';
import { useSelectIntakeFormMutation } from '../../../selectors/intake-form.selectors';

type Props = {
  onNextClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onPrevClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disableNext?: boolean;
  disableBack?: boolean;
  nextButtonLabel?: string;
  nextButtonTestId?: string;
  children?: ReactNode;
  nextButtonTrackingId?: string;
  backButtonTrackingId?: string;
};

export const StepNavigation = ({
  onNextClick,
  onPrevClick,
  disableNext = false,
  disableBack = false,
  nextButtonLabel,
  nextButtonTestId = 'onb-next-button',
  children,
  nextButtonTrackingId,
  backButtonTrackingId,
}: Props) => {
  const { t } = useTranslation('onboarding');
  const { isLoading: loading } = useSelectIntakeFormMutation();
  const { isDebugMode: debug } = useDebugMode();
  nextButtonLabel = nextButtonLabel || t('Next');
  const { matches } = useMediaMatches();
  const isMobile = matches.useXSmallMax();

  return (
    <ButtonBar css={isMobile && children ? mobileBarStyles : buttonBarStyles}>
      {onPrevClick && (
        <SecondaryButton
          data-testid='on-back-button'
          onClick={onPrevClick}
          disabled={disableBack || loading}
          trackingId={backButtonTrackingId}
        >
          {t('Back')}
        </SecondaryButton>
      )}
      {children}
      <PrimaryButton
        data-testid={nextButtonTestId}
        onClick={onNextClick}
        disabled={!debug && (disableNext || loading)}
        trackingId={nextButtonTrackingId}
      >
        {nextButtonLabel}
      </PrimaryButton>
    </ButtonBar>
  );
};

const buttonBarStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${theme.spacing(6)} auto;
  > button {
    margin-bottom: ${theme.spacing(2)};
    max-width: 150px;
  }
`;

const mobileBarStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin: ${theme.spacing(0, 1, 2, 1)};
    width: 100%;
  }
`;
