import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Consumer } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import urlRegex from 'url-regex';
import { BusinessInfoApi, BusinessInfoTypes } from '@frontend/api-business-information';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { stateOptions } from '@frontend/geography';
import { CountryCodes, countryOptions } from '@frontend/geography';
import { TFunction, Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { breakpoints } from '@frontend/responsiveness';
import { titleCase } from '@frontend/string';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  EmailField,
  FormFieldActionTypes,
  FormRow,
  IconButton,
  InfoRoundIconSmall,
  Modal,
  PhoneField,
  PostalCodeField,
  PostalCodeLocale,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  TextLink,
  useForm,
  useTooltip,
  useModalControl,
  Button,
  RadioField,
  BannerNotification,
} from '@frontend/design-system';
import { EinSuggestionModal } from './ein-suggestion-modal';

type BusinessInfoFormProps = {
  isDemoMode?: boolean;
  info: BusinessInfoTypes.TCRBrand;
  isVerified: boolean;
  previousValues?: Partial<BusinessInfoTypes.BusinessInfoFormValues>;
  familyLocationTcrBrands?: BusinessInfoTypes.LocationTCRBrand[];
  handleSubmit: (
    values: BusinessInfoTypes.BusinessInfoFormValues,
    changedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues>
  ) => void;
  handleCancel: () => void;
  handleFamilyLocationEinMatch: (brand: BusinessInfoTypes.TCRBrand) => void;
  hasLocationWritePermission?: boolean;
};

const einFormatter = (ein: string | undefined, removeFormat = false) => {
  if (ein === undefined) return '';

  const numEin = ein.replaceAll('-', '');

  if (removeFormat || numEin.length < 3) return numEin;

  return numEin.slice(0, 2) + '-' + numEin.slice(2);
};

const websiteValidator = ({ value, t }: { value: string; t: TFunction<'business-info', undefined> }) => {
  const lowercaseValue = value.toLowerCase();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (lowercaseValue.includes('google.com')) {
    return t('Website cannot be a link to Google');
  }
  if (lowercaseValue.includes('yahoo.com')) {
    return t('Website cannot be a link to Yahoo');
  }
  if (lowercaseValue.includes('gmail.com')) {
    return t('Website cannot be a link to Gmail');
  }
  if (lowercaseValue.includes('getweave.com')) {
    return t('Website cannot be a link to Weave');
  }
  if (!urlRegex({ exact: true, strict: false }).test(lowercaseValue)) {
    return t('Please enter a valid website URL');
  }
  if (emailRegex.test(lowercaseValue)) {
    return t('Website cannot be an email address');
  }
  if (lowercaseValue.length > 100) {
    return t('Website cannot be longer than 100 characters');
  }
  return '';
};

const findFamilyLocationMatchingEinBrand = (ein: string, familyLocations?: BusinessInfoTypes.LocationTCRBrand[]) => {
  if (familyLocations === undefined || familyLocations.length === 0) return undefined;
  return familyLocations.find(
    (locationTcrBrand) => locationTcrBrand.tcrBrand?.ein && locationTcrBrand.tcrBrand.ein === ein
  )?.tcrBrand;
};

// Returns true if searchResults matches the current form values
const searchResultsHasExactMatch = (
  searchResults: BusinessInfoTypes.EINSearchRecord[],
  matchValues: Required<
    Pick<
      BusinessInfoTypes.BusinessInfoFormValues,
      'ein' | 'companyName' | 'state' | 'city' | 'postalCode' | 'street' | 'street2'
    >
  >
) => {
  const combinedStreetLine = `${matchValues.street} ${matchValues.street2}`.toUpperCase().trim();
  return searchResults
    ? searchResults.some((currentResult) => {
        const truncatedResultPostalCode =
          currentResult.zipCode && currentResult.zipCode.length > 5
            ? currentResult.zipCode.slice(0, 5)
            : currentResult.zipCode;
        const combinedAddressLine = `${currentResult.address ?? ''} ${currentResult.address2 ?? ''}`
          .toUpperCase()
          .trim();
        return (
          currentResult.ein === matchValues.ein &&
          combinedAddressLine === combinedStreetLine &&
          currentResult.companyName?.toUpperCase() === matchValues.companyName?.toUpperCase() &&
          currentResult.city === matchValues.city &&
          currentResult.state === matchValues.state &&
          truncatedResultPostalCode === matchValues.postalCode
        );
      })
    : false;
};

export const BusinessInfoForm = ({
  info,
  isDemoMode,
  isVerified,
  previousValues,
  familyLocationTcrBrands,
  handleSubmit,
  handleCancel,
  handleFamilyLocationEinMatch,
  hasLocationWritePermission,
}: BusinessInfoFormProps) => {
  const [businessType, _setBusinessType] = useState<BusinessInfoTypes.EntityType | undefined>(info.entityType);
  const { t } = useTranslation('business-info');
  const [einSearchIsActive, setEinSearchIsActive] = useState(false);
  const [einSearchIsReverse, setEinSearchIsReverse] = useState(false);
  const [searchResults, setSearchResults] = useState<BusinessInfoTypes.EINSearchRecord[]>([]);
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'right' });
  const { locationData, isMultiLocation } = useLocationDataShallowStore('locationData', 'isMultiLocation');

  const { data: salesforceAccountDetails } = SubscriptionsQueries.useGetSalesforceAccountBySlug({
    enabled: !isMultiLocation && !!locationData?.Slug,
    slug: locationData?.Slug ?? '',
    consumer: Consumer.COMMX,
  });

  const { data: salesforceBusinessData } = SubscriptionsQueries.useGetSalesforceAccountBySlug({
    enabled: !isMultiLocation && !!locationData?.Slug,
    slug: locationData?.Slug ?? '',
    consumer: Consumer.BUSINESS_MESSAGING,
  });

  const isOnboarding =
    salesforceBusinessData?.account?.statusId === 'ACCOUNT_STATUS_ONBOARDING' ||
    salesforceBusinessData?.account?.statusId === 'ACCOUNT_STATUS_ONBOARDING_ACTIVE';

  const suggestedDisplayName = locationData?.Name ?? '';
  const suggestedWebsite = salesforceAccountDetails?.account?.website ?? '';

  const isNewBrand = !info.brandId;
  const initialValues = isNewBrand
    ? previousValues
    : ({
        soleProprietorRadio:
          info.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR ? 'soleProprietor' : 'ein',
        companyName:
          info.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR
            ? info.displayName
            : info.companyName,
        displayName: info.displayName ?? '',
        ein: info.ein,
        street: info.street,
        city: info.city,
        postalCode: info.postalCode,
        country: info.country ?? BusinessInfoTypes.Country.COUNTRY_US,
        state: info.state,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        phone: info.phone?.replace('+1', ''),
        vertical: info.vertical,
        website: info.website,
      } as BusinessInfoTypes.BusinessInfoFormValues);
  const [isUS, setIsUS] = useState(
    initialValues?.country === BusinessInfoTypes.Country.COUNTRY_US || initialValues?.country === undefined
  );
  const isTrulyVerified =
    info.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED ||
    info.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED;
  const industries = Object.values(BusinessInfoTypes.TCRVertical)
    .filter((vertical) => vertical !== BusinessInfoTypes.TCRVertical.TCR_VERTICAL_UNSPECIFIED)
    .map((vertical) => {
      const label =
        vertical !== BusinessInfoTypes.TCRVertical.TCR_VERTICAL_NGO
          ? titleCase(vertical.replace('TCR_VERTICAL_', '').replaceAll('_', ' '))
          : vertical.replace('TCR_VERTICAL_', '').replaceAll('_', ' ');
      return {
        value: vertical,
        label,
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const businessInfoModalControl = useModalControl();
  const einInfoModalControl = useModalControl();
  const bnInfoModalControl = useModalControl();
  const additionalInfoModalControl = useModalControl();
  const websiteInfoModalControl = useModalControl();

  const einValidator = ({ value }: { value: string }) => {
    const numValue = einFormatter(value, true);
    return numValue.length === 9 && /^\d+$/gm.test(numValue) ? '' : t('Must be 9 digits long');
  };
  const bnValidator = ({ value }: { value: string }) => (value.length < 9 ? t('Must be at least 9 characters') : '');
  const phoneValidator = ({ value }: { value: string }) =>
    value.charAt(0) === '1' || value.charAt(0) === '0' ? t('Invalid phone number') : '';

  const { formProps, getFieldProps, changedValues, isComplete, validate, values } = useForm({
    computeChangedValues: true,
    allowInvalidSubmission: isDemoMode,
    fields: {
      soleProprietorRadio: {
        type: 'radio',
        value: initialValues?.soleProprietorRadio === 'soleProprietor' ? 'soleProprietor' : 'ein',
        disabled: !isNewBrand && initialValues?.soleProprietorRadio === 'ein',
        validateOnChange: true,
      },
      companyName: {
        type: 'text',
        required: true,
        value: initialValues?.companyName ?? '',
        validateOnChange: true,
        disabled: isTrulyVerified,
      },
      displayName: {
        type: 'text',
        required: !initialValues?.soleProprietorRadio || initialValues?.soleProprietorRadio === 'ein',
        value: initialValues?.displayName,
        hidden: initialValues?.soleProprietorRadio === 'soleProprietor',
        validateOnChange: true,
      },
      ein: {
        type: 'text',
        required: !initialValues?.soleProprietorRadio || initialValues?.soleProprietorRadio === 'ein',
        value: isUS ? einFormatter(initialValues?.ein) : initialValues?.ein,
        validator: isUS ? einValidator : bnValidator,
        validateOnChange: true,
        disabled: isTrulyVerified,
      },
      street: { type: 'text', required: true, value: initialValues?.street, validateOnChange: true },
      street2: { type: 'text', required: false, value: initialValues?.street2, validateOnChange: true },
      city: { type: 'text', required: true, value: initialValues?.city, validateOnChange: true },
      postalCode: {
        type: 'postalCode',
        required: true,
        value: initialValues?.postalCode,
        locale: isUS ? PostalCodeLocale.US : PostalCodeLocale.CA,
        validateOnChange: true,
      },
      country: {
        type: 'dropdown',
        required: true,
        value: initialValues?.country ?? BusinessInfoTypes.Country.COUNTRY_US,
        validateOnChange: true,
        disabled: isTrulyVerified,
      },
      state: { type: 'dropdown', required: true, value: initialValues?.state, validateOnChange: true },
      firstName: {
        type: 'text',
        required: initialValues?.soleProprietorRadio === 'soleProprietor',
        value: initialValues?.firstName,
        hidden: !initialValues?.soleProprietorRadio || initialValues?.soleProprietorRadio === 'ein',
        validateOnChange: true,
      },
      lastName: {
        type: 'text',
        required: initialValues?.soleProprietorRadio === 'soleProprietor',
        value: initialValues?.lastName,
        hidden: !initialValues?.soleProprietorRadio || initialValues?.soleProprietorRadio === 'ein',
        validateOnChange: true,
      },
      email: { type: 'email', required: true, value: initialValues?.email, validateOnChange: true },
      phone: {
        type: 'phone',
        required: true,
        value: initialValues?.phone,
        validateOnChange: true,
        validator: phoneValidator,
      },
      vertical: { type: 'text', required: true, value: initialValues?.vertical, validateOnChange: true },
      website: {
        type: 'text',
        required: true,
        value: initialValues?.website,
        validator: ({ value }) => websiteValidator({ value, t }),
        validateOnChange: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        if (action.payload.name === 'country') {
          if (state.country.value === BusinessInfoTypes.Country.COUNTRY_US) {
            setIsUS(true);
            return {
              ...state,
              ein: {
                ...state.ein,
                // typescript for some reason thinks that state.ein.value could be a boolean here, which it shouldn't
                value: typeof state.ein.value === 'string' ? einFormatter(state.ein.value) : '',
                validator: einValidator,
              },
              postalCode: {
                ...state.postalCode,
                value: '',
                error: t('This field is required'),
                locale: PostalCodeLocale.US,
              },
              state: {
                ...state.state,
                value: '',
                error: t('This field is required'),
              },
            };
          } else if (state.country.value === BusinessInfoTypes.Country.COUNTRY_CA) {
            setIsUS(false);
            return {
              ...state,
              ein: {
                ...state.ein,
                // typescript for some reason thinks that state.ein.value could be a boolean here, which it shouldn't
                value: typeof state.ein.value === 'string' ? einFormatter(state.ein.value, true) : '',
                validator: bnValidator,
              },
              postalCode: {
                ...state.postalCode,
                value: '',
                error: t('This field is required'),
                locale: PostalCodeLocale.CA,
              },
              state: {
                ...state.state,
                value: '',
                error: t('This field is required'),
              },
            };
          }
        }

        if (action.payload.name === 'soleProprietorRadio') {
          if (action.payload.value) {
            _setBusinessType(BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR);
            return {
              ...state,
              ein: {
                ...state.ein,
                required: action.payload.value === 'ein' ? true : false,
                disabled: action.payload.value === 'ein' ? false : true,
                value: '',
                error: '',
              },
              displayName: {
                ...state.displayName,
                hidden: action.payload.value === 'ein' ? false : true,
                required: action.payload.value === 'ein' ? true : false,
              },
              firstName: {
                ...state.firstName,
                required: action.payload.value === 'ein' ? false : true,
                hidden: action.payload.value === 'ein' ? true : false,
              },
              lastName: {
                ...state.lastName,
                required: action.payload.value === 'ein' ? false : true,
                hidden: action.payload.value === 'ein' ? true : false,
              },
              phone: {
                ...state.phone,
                value: '',
                error: t('This field is required'),
              },
            };
          } else {
            _setBusinessType(BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT);
            return {
              ...state,
              ein: {
                ...state.ein,
                required: true,
                disabled: false,
                value: '',
                error: '',
              },
              displayName: { ...state.displayName, hidden: false, required: true, value: '' },
              firstName: { ...state.firstName, required: false, hidden: true },
              lastName: { ...state.lastName, required: false, hidden: true },
              phone: {
                ...state.phone,
                value: '',
                error: t('This field is required'),
              },
            };
          }
        } else if (action.payload.name === 'ein' && isUS) {
          return {
            ...state,
            ein: {
              ...state.ein,
              value: einFormatter(action.payload.value),
            },
          };
        }
      }

      return null;
    },
    onSubmit: async (values, changedValues) => {
      if (isDemoMode) {
        handleSubmit(
          values as BusinessInfoTypes.BusinessInfoFormValues,
          changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
        );
        return;
      }
      // Remove formatting from ein if present
      if (values.ein) values.ein = values.ein.replaceAll('-', '');
      if (changedValues && changedValues.ein) changedValues.ein = changedValues?.ein.replaceAll('-', '');

      // If a family location has a brand using the same EIN, connect current location to existing brand
      if (familyLocationTcrBrands && familyLocationTcrBrands.length > 0 && values.ein && isNewBrand) {
        const matchingBrand = findFamilyLocationMatchingEinBrand(values.ein, familyLocationTcrBrands);
        if (matchingBrand !== undefined) {
          handleFamilyLocationEinMatch(matchingBrand);
          return;
        }
      }

      // Skip ein lookup if brand is Canadian, Sole Proprietor, or already verified
      if (
        values.country === BusinessInfoTypes.Country.COUNTRY_CA ||
        values.soleProprietorRadio === 'soleProprietor' ||
        isTrulyVerified
      ) {
        handleSubmit(
          values as BusinessInfoTypes.BusinessInfoFormValues,
          changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
        );
        return;
      }

      // Ein lookup/suggestion flow
      setEinSearchIsActive(true);
      if (values.ein && values.companyName && values.city && values.state && values.postalCode) {
        const matchValues = {
          ein: values.ein,
          street: values.street ?? '',
          street2: values.street2 ?? '',
          companyName: values.companyName,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode,
        };

        // Forward ein lookup
        const forwardSearchRes = await BusinessInfoApi.getEinLookup({
          ein: changedValues?.ein || values.ein,
        }).catch(() => Promise.resolve({ searchResults: [] as BusinessInfoTypes.EINSearchRecord[] }));
        if (searchResultsHasExactMatch(forwardSearchRes.searchResults ?? [], matchValues)) {
          handleSubmit(
            values as BusinessInfoTypes.BusinessInfoFormValues,
            changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
          );
          setEinSearchIsActive(false);
          return;
        } else if (forwardSearchRes.searchResults && forwardSearchRes.searchResults.length > 0) {
          setSearchResults(forwardSearchRes.searchResults);
          return;
        }

        // If no results from forward lookup (or error response) use reverse ein lookup
        setEinSearchIsReverse(true);
        const reverseSearchRes = await BusinessInfoApi.getReverseEinLookup({
          company: matchValues.companyName,
          state: matchValues.state,
          city: matchValues.city,
          zipCode: matchValues.postalCode.slice(0, 5),
        }).catch(() => Promise.resolve({ searchResults: [] as BusinessInfoTypes.EINSearchRecord[] }));
        if (
          searchResultsHasExactMatch(reverseSearchRes.searchResults ?? [], matchValues) ||
          (reverseSearchRes.searchResults && reverseSearchRes.searchResults.length === 0) ||
          reverseSearchRes.searchResults === undefined
        ) {
          handleSubmit(
            values as BusinessInfoTypes.BusinessInfoFormValues,
            changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
          );
          setEinSearchIsActive(false);
          return;
        }

        setSearchResults(reverseSearchRes.searchResults);
      } else {
        validate();
      }
    },
  });

  const submitLabel = isNewBrand ? t('Submit') : isVerified ? t('Update') : t('Resubmit');
  const submitDisabled = isNewBrand ? !isComplete : !(changedValues && isComplete);
  const [hasBeenCompleted, setHasBeenCompleted] = useState(!isNewBrand);

  const handleEinSuggestionSelection = (einSuggestion: BusinessInfoTypes.EINSearchRecord) => {
    const resolvedValues = {
      ...values,
      ein: einSuggestion.ein ?? values.ein,
      companyName: einSuggestion.companyName ?? values.companyName,
      street: einSuggestion.address ?? values.street,
      street2: einSuggestion.address2 ?? values.street2,
      city: einSuggestion.city ?? values.city,
      state: einSuggestion.state ?? values.state,
      postalCode: einSuggestion.zipCode ?? values.postalCode,
    };

    const resolvedChangedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues> = {
      ...(changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>),
    };

    // Add ein suggestion values if they don't match initial values
    // and remove changed value if suggestion is equal to initial values
    if (einSuggestion.ein !== previousValues?.ein) {
      resolvedChangedValues.ein = einSuggestion.ein;
    } else if (resolvedChangedValues.ein) {
      delete resolvedChangedValues.ein;
    }
    if (einSuggestion.companyName !== previousValues?.companyName) {
      resolvedChangedValues.companyName = einSuggestion.companyName;
    } else if (resolvedChangedValues.companyName) {
      delete resolvedChangedValues.companyName;
    }
    if (
      `${einSuggestion.address} ${einSuggestion.address2}`.trim() !==
      `${previousValues?.street} ${previousValues?.street2}`.trim()
    ) {
      resolvedChangedValues.street = einSuggestion.address;
      resolvedChangedValues.street2 = einSuggestion.address2;
    } else if (resolvedChangedValues.street || resolvedChangedValues.street2) {
      if (resolvedChangedValues.street) delete resolvedChangedValues.street;
      if (resolvedChangedValues.street2) delete resolvedChangedValues.street2;
    }
    if (einSuggestion.city !== previousValues?.city) {
      resolvedChangedValues.city = einSuggestion.city;
    } else if (resolvedChangedValues.city) {
      delete resolvedChangedValues.city;
    }
    if (einSuggestion.state !== previousValues?.state) {
      resolvedChangedValues.state = einSuggestion.state;
    } else if (resolvedChangedValues.state) {
      delete resolvedChangedValues.state;
    }
    if (einSuggestion.zipCode !== previousValues?.postalCode) {
      resolvedChangedValues.postalCode = einSuggestion.zipCode;
    } else if (resolvedChangedValues.postalCode) {
      delete resolvedChangedValues.postalCode;
    }

    handleSubmit(resolvedValues as BusinessInfoTypes.BusinessInfoFormValues, resolvedChangedValues);
  };

  const resetEinLookupState = () => {
    setEinSearchIsActive(false);
    setEinSearchIsReverse(false);
    setSearchResults([]);
  };

  useEffect(() => {
    if (!submitDisabled && !hasBeenCompleted) setHasBeenCompleted(true);
  }, [submitDisabled]);

  useEffect(() => {
    // Display validation errors after form has been completed, since they don't always appear when values are programmatically changed
    if (hasBeenCompleted) {
      validate();
    }
  }, [values.country, values.soleProprietorRadio]);

  const disableSubmitButton = !isNewBrand && (!changedValues || Object.keys(changedValues).length === 0);

  const isValidBusinessWebsite = () => {
    if (!salesforceAccountDetails?.account) return false;
    const forbiddenDomains = ['google.com', 'yahoo.com', 'hotmail.com', 'gmail.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lowerCaseWebsite = salesforceAccountDetails?.account?.website
      ? salesforceAccountDetails?.account?.website?.toLowerCase()
      : '';

    return (
      !forbiddenDomains.some((domain) => lowerCaseWebsite.includes(domain)) &&
      !emailRegex.test(lowerCaseWebsite) &&
      urlRegex({ exact: true, strict: false }).test(lowerCaseWebsite)
    );
  };

  return (
    <>
      <form {...formProps} css={formStyles}>
        <section>
          <div css={sectionHeaderRowStyles}>
            <div css={sectionTitleStyles}>
              <Text weight='semibold'>{t('Legal Business Information')}</Text>
              <IconButton
                label={t('Open legal business info modal')}
                size='small'
                {...businessInfoModalControl.triggerProps}
              >
                <InfoRoundIconSmall color='light' />
              </IconButton>
            </div>
            {!isNewBrand && isVerified && (
              <SecondaryButton css={css({ width: 'auto' })} onClick={handleCancel}>
                {t('Cancel Update')}
              </SecondaryButton>
            )}
          </div>
          <FormRow>
            <TextField
              {...getFieldProps('companyName')}
              label={`${getFieldProps('companyName').required ? '*' : ''}${t('Legal Business Name')}`}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('street')}
              label={`${getFieldProps('street').required ? '*' : ''}${t('Street Address')}`}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('street2')}
              label={`${getFieldProps('street2').required ? '*' : ''}${t('Address Line 2')}`}
              helperText={t('Optional')}
            />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('city')} label={`${getFieldProps('city').required ? '*' : ''}${t('City')}`} />
            <DropdownField
              {...getFieldProps('state')}
              label={`${getFieldProps('state').required ? '*' : ''}${isUS ? t('State') : t('Province')}`}
            >
              {stateOptions[
                getFieldProps('country').value === BusinessInfoTypes.Country.COUNTRY_US
                  ? CountryCodes.USA
                  : CountryCodes.Canada
              ]?.map(({ label, value }) => (
                <DropdownField.Option key={value} value={value} searchValue={label}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow>
            <PostalCodeField
              {...getFieldProps('postalCode')}
              label={`${getFieldProps('postalCode').required ? '*' : ''}${isUS ? t('Zip Code') : t('Postal Code')}`}
            />
            <DropdownField
              {...getFieldProps('country')}
              label={`${getFieldProps('country').required ? '*' : ''}${t('Country')}`}
            >
              {countryOptions.map(({ label }) => (
                <DropdownField.Option
                  key={label}
                  value={label === 'USA' ? BusinessInfoTypes.Country.COUNTRY_US : BusinessInfoTypes.Country.COUNTRY_CA}
                >
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
        </section>
        <section>
          <div
            css={[
              sectionHeaderRowStyles,
              {
                marginBottom: theme.spacing(0),
              },
            ]}
          >
            <div css={sectionTitleStyles}>
              <Text weight='semibold'>
                {isUS
                  ? t('Do you have a Taxpayer Identification Number (EIN)?')
                  : t('Do you have a Business Number (BN)?')}
              </Text>
              {isUS ? (
                <IconButton label={t('Open EIN info modal')} size='small' {...einInfoModalControl.triggerProps}>
                  <InfoRoundIconSmall color='light' />
                </IconButton>
              ) : (
                <IconButton label={t('Open BN info modal')} size='small' {...bnInfoModalControl.triggerProps}>
                  <InfoRoundIconSmall color='light' />
                </IconButton>
              )}
            </div>
          </div>
          <div
            css={{
              marginBottom: theme.spacing(2),
            }}
          >
            {isUS ? (
              <>
                <div
                  css={{
                    display: 'flex',
                    columnGap: theme.spacing(1),
                    alignItems: 'center',
                  }}
                >
                  <Icon name='info-small' color='primary' />
                  <TextLink
                    href='https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein#:~:text=Ask%20the%20IRS%20to%20search,at%20800%2D829%2D4933.'
                    target='_blank'
                  >
                    {t('Finding your Taxpayer Identification Number (EIN) number')}
                  </TextLink>
                </div>
                <div
                  css={{
                    display: 'flex',
                    columnGap: theme.spacing(1),
                    alignItems: 'center',
                  }}
                >
                  <Icon name='info-small' color='primary' />
                  <TextLink
                    href='https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online'
                    target='_blank'
                  >
                    {t('Request a Taxpayer Identification Number (EIN) from the IRS here')}
                  </TextLink>
                </div>
              </>
            ) : (
              <div
                css={{
                  display: 'flex',
                  columnGap: theme.spacing(1),
                  alignItems: 'center',
                }}
              >
                <Icon name='info-small' color='primary' />
                <TextLink href='https://beta.canadasbusinessregistries.ca/search' target='_blank'>
                  {t('About Business Numbers (BNs)')}
                </TextLink>
              </div>
            )}
          </div>
          <FormRow>
            <RadioField {...getFieldProps('soleProprietorRadio')} label='' labelPlacement='right'>
              <RadioField.Radio value='ein' trackingId={`${IntakePrefixes.BusinessInformation}-ein-radio`}>
                <Text as='span' weight='bold'>
                  {isUS
                    ? t('Yes, I have a Taxpayer Identification Number (EIN)')
                    : t('Yes, I have a Business Number (BN).')}
                </Text>
                <Text size='small' color='subdued'>
                  {isUS
                    ? t('Your business has an EIN to report taxes.')
                    : t('Your business has an BN to report taxes.')}
                </Text>
              </RadioField.Radio>
              {values.soleProprietorRadio === 'ein' ? (
                <FormRow
                  css={{
                    paddingLeft: theme.spacing(3.5),
                  }}
                >
                  <TextField
                    {...getFieldProps('ein')}
                    css={einTextFieldMediaQuery}
                    label={`${getFieldProps('ein').required ? '*' : ''}${
                      isUS ? t('Taxpayer Identification Number (EIN)') : t('Business Number (BN)')
                    }`}
                    disabled={
                      getFieldProps('soleProprietorRadio').value === 'soleProprietor' || getFieldProps('ein').disabled
                    }
                    helperText={
                      isUS
                        ? t('EINs typically use this format: ##-#######')
                        : t('BNs typically use this format: ##### ####')
                    }
                  />
                </FormRow>
              ) : null}
              <RadioField.Radio
                value='soleProprietor'
                disabled={!isNewBrand && initialValues?.soleProprietorRadio === 'ein'}
                trackingId={`${IntakePrefixes.BusinessInformation}-sole-proprietor-radio`}
              >
                <Text as='span' weight='bold'>
                  {isUS
                    ? t('No, I do not have a Taxpayer Identification Number (EIN)')
                    : t('No, I do not have a Business Number (BN).')}
                </Text>
                <Text size='small' color='subdued'>
                  {isUS
                    ? t(
                        'As a sole proprietor, your business uses an SSN instead of an EIN to report taxes, and/or you do not need to send SMS messages.'
                      )
                    : t('As a sole proprietor, your business uses an SIN instead of an BN to report taxes.')}
                </Text>
              </RadioField.Radio>
              {values.soleProprietorRadio === 'soleProprietor' ? (
                <FormRow
                  css={{
                    paddingLeft: theme.spacing(3.5),
                  }}
                >
                  <BannerNotification
                    status='info'
                    message={
                      isUS
                        ? t(
                            'You will be unable to send SMS messages through Weave. Sole proprietorships in the United States should have an EIN they can provide if they have more than one employee. If you do not have an EIN, you can request a Taxpayer Identification Number (EIN) from the IRS above.'
                          )
                        : t(
                            'If your taxable yearly sales exceed $30,000 CAD, or you have any employees, you should have a BN and should provide it in this form. See more information on BNs in the link above.'
                          )
                    }
                  />
                </FormRow>
              ) : null}
            </RadioField>
          </FormRow>
        </section>
        <section>
          <div css={sectionHeaderRowStyles}>
            <div css={sectionTitleStyles}>
              <Text weight='semibold'>{t('Additional Information')}</Text>
              <IconButton
                label={t('Open additional info modal')}
                size='small'
                {...additionalInfoModalControl.triggerProps}
              >
                <InfoRoundIconSmall color='light' />
              </IconButton>
            </div>
          </div>
          <FormRow
            css={{
              marginBottom: theme.spacing(0),
            }}
          >
            <TextField
              {...getFieldProps('displayName')}
              label={`${getFieldProps('displayName').required ? '*' : ''}${t('Doing Business as Name')}`}
              helperText={t('Suggested name: {{suggestedDisplayName}}', { suggestedDisplayName })}
            />
          </FormRow>
          <Text
            size='small'
            color='subdued'
            css={{
              margin: theme.spacing(0),
              display: values.soleProprietorRadio === 'ein' ? 'block' : 'none',
            }}
          >
            {t('Must match branding on website')}
          </Text>
          <FormRow>
            <TextField
              {...getFieldProps('firstName')}
              label={`${getFieldProps('firstName').required ? '*' : ''}${t('First Name')}`}
            />
          </FormRow>
          <FormRow>
            <TextField
              {...getFieldProps('lastName')}
              label={`${getFieldProps('lastName').required ? '*' : ''}${t('Last Name')}`}
            />
          </FormRow>
          <FormRow
            css={{
              marginBottom: theme.spacing(0),
            }}
          >
            <EmailField
              {...getFieldProps('email')}
              label={`${getFieldProps('email').required ? '*' : ''}${t('Primary Contact Email')}`}
            />
          </FormRow>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              margin: theme.spacing(1, 0, 2),
            }}
          >
            <Text
              size='small'
              color='subdued'
              css={{
                margin: theme.spacing(0, 1, 0, 0),
              }}
            >
              {t('When possible, use the email that matches your website (e.g., name@yourofffice.com)')}
            </Text>
          </div>

          <FormRow>
            <PhoneField
              {...getFieldProps('phone')}
              label={`${getFieldProps('phone').required ? '*' : ''}${t(
                `Primary Contact ${
                  businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR ? 'Mobile ' : ''
                }Phone Number`
              )}`}
            />
          </FormRow>
          <FormRow>
            <DropdownField
              {...getFieldProps('vertical')}
              label={`${getFieldProps('vertical').required ? '*' : ''}${t('Industry')}`}
            >
              {industries.map((vertical) => (
                <DropdownField.Option key={vertical.value} value={vertical.value} searchValue={vertical.label}>
                  {vertical.label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow
            cols={[95]}
            css={{
              alignItems: 'center',
            }}
          >
            <TextField
              {...getFieldProps('website')}
              label={`${getFieldProps('website').required ? '*' : ''}${t('Website')}`}
              helperText={
                isValidBusinessWebsite() && isNewBrand && suggestedWebsite
                  ? t('Suggested website: {{suggestedWebsite}}', { suggestedWebsite })
                  : undefined
              }
            />
            <Button
              css={{
                flexBasis: '5% !important',
              }}
              variant='secondary'
              iconName='info'
              label={t('Open website help info')}
              size='large'
              {...websiteInfoModalControl.triggerProps}
            />
          </FormRow>
        </section>
        <section {...triggerProps}>
          <PrimaryButton
            type='submit'
            disabled={disableSubmitButton}
            trackingId={`${IntakePrefixes.BusinessInformation}-EIN-submit-btn`}
          >
            {submitLabel}
          </PrimaryButton>
          {disableSubmitButton && (
            <Tooltip {...tooltipProps}>{t('Please change a value before resubmitting.')}</Tooltip>
          )}
        </section>
      </form>

      <Modal {...businessInfoModalControl.modalProps}>
        <Modal.Header>{t('Legal Business Information')}</Modal.Header>
        <Modal.Body>
          <Text>
            <Trans t={t}>
              This information must be<i> exactly</i> as it is filed with the IRS or CRA.
            </Trans>
          </Text>
          <Text weight='bold' css={modalStyles.section}>
            For US:
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              {t(
                'Check any of your IRS-issued documentation, such as your IRS SS-4 confirmation letter or your Letter 147C (the record of how your entity is filed with the IRS).'
              )}
            </Text>
            <Text as='li'>
              <Trans t={t}>
                {'Ask the IRS for a copy of your Letter 147C by calling the Business & Specialty Tax Line at '}
                <TextLink to='tel:8008294933'>800-829-4933</TextLink>
                {'. You can find more information '}
                <TextLink to='https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein'>
                  here
                </TextLink>
                .
              </Trans>
            </Text>
          </ul>
          <Text weight='bold' css={modalStyles.section}>
            For Canada:
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              <Trans t={t}>
                {'Search '}
                <TextLink to='https://beta.canadasbusinessregistries.ca/search'>Canada's Business Registries</TextLink>.
              </Trans>
            </Text>
          </ul>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={businessInfoModalControl.closeModal} />
      </Modal>

      <Modal {...einInfoModalControl.modalProps}>
        <Modal.Header>{t('Employer Identification Number')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              'If you are a US company or a foreign company with a US IRS Employer Identification Number (EIN), please enter that nine-digit number in the EIN field without dashes.'
            )}
          </Text>
          <Text weight='bold' css={modalStyles.section}>
            Where can I find my EIN?
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              {t(
                'Check any of your IRS-issued documentation, such as your IRS SS-4 confirmation letter or your Letter 147C (the record of how your entity is filed with the IRS).'
              )}
            </Text>
            <Text as='li'>
              <Trans t={t}>
                {'Ask the IRS for a copy of your Letter 147C by calling the Business & Specialty Tax Line at '}
                <TextLink to='tel:8008294933'>800-829-4933</TextLink>
                {'. You can find more information '}
                <TextLink to='https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein'>
                  here
                </TextLink>
                .
              </Trans>
            </Text>
            <Text as='li'>
              {t(
                'If you used your EIN to open a bank account, or apply for any type of state or local license, you should contact the bank or agency to secure your EIN.'
              )}
            </Text>
            <Text as='li'>
              {t('Find a previously filed tax return for your business entity. It will include your EIN.')}
            </Text>
          </ul>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={einInfoModalControl.closeModal} />
      </Modal>

      <Modal {...bnInfoModalControl.modalProps}>
        <Modal.Header>{t('Canada Revenue Agency Business Number')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              'If your primary business registration is in Canada, please enter your Business Number. Please do NOT enter your federal tax ID number or Corporation Number.'
            )}
          </Text>
          <ul css={modalStyles.section}>
            <Text as='li'>{t('Alberta: Corporate Access Numbers')}</Text>
            <Text as='li'>{t('British Columbia: Requires an alpha prefix (BC)')}</Text>
            <Text as='li'>{t('Quebec: Quebec Business Number')}</Text>
            <Text as='li'>{t('Ontario: Federal Corporation Business Number')}</Text>
            <Text as='li'>{t('Manitoba: Manitoba Corporation Number')}</Text>
          </ul>
          <Text weight='bold' css={modalStyles.section}>
            Where can I find my BN?
          </Text>
          <ul css={modalStyles.listSection}>
            <Text as='li'>
              <Trans t={t}>
                {'Search '}
                <TextLink to='https://beta.canadasbusinessregistries.ca/search'>Canada's Business Registries</TextLink>.
              </Trans>
            </Text>
            <Text as='li'>
              {t(
                'If you used your BN to open a bank account, or apply for any type of federal, provincial or local license or program (e.g., GST/HST, payroll deductions, corporate income tax), you should contact the bank or agency to secure your BN.'
              )}
            </Text>
            <Text as='li'>
              {t(
                'Find a previously filed tax return for your existing entity (if you have filed a return) for which you have your lost or misplaced BN. Your previously filed return should be notated with your BN.'
              )}
            </Text>
          </ul>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={bnInfoModalControl.closeModal} />
      </Modal>

      <Modal {...additionalInfoModalControl.modalProps}>
        <Modal.Header>{t('Additional Information')}</Modal.Header>
        <Modal.Body>
          {businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT && (
            <>
              <Text weight='semibold' css={modalStyles.section}>
                {t('Doing Business as Name:')}
              </Text>
              <Text>
                {t(
                  'The outward-facing, public brand name of your business, if it is different from the legal business name. Must match the brand name used on your website.'
                )}
              </Text>
            </>
          )}
          <Text weight='semibold'>
            {businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT
              ? t('Primary Contact Email and Phone Number:')
              : t('Primary Contact Information:')}
          </Text>
          <Text>
            {t(
              'This information may be used by The Campaign Registry to contact you in order to verify the legitimacy of your business.'
            )}
          </Text>
          {businessType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR && (
            <>
              <Text css={modalStyles.section} weight='semibold'>
                {t('Mobile Phone Number:')}
              </Text>
              <Text>
                {t(
                  'If you are registering as a Sole Proprietor, this phone number must be able to receive text messages for verification purposes.'
                )}
              </Text>
            </>
          )}
          <Text css={modalStyles.section} weight='semibold'>
            {t('Industry:')}
          </Text>
          <Text>
            {t(
              'Select the single industry that best describes your business, even if your business operates in multiple industries.'
            )}
          </Text>
          <Text css={modalStyles.section} weight='semibold'>
            {t('Website:')}
          </Text>
          <Text>
            {t(
              'This is required to help verify the legitimacy of your business. See help next to the Website field for more information.'
            )}
          </Text>
        </Modal.Body>
        <Modal.Actions primaryLabel='Okay' onPrimaryClick={additionalInfoModalControl.closeModal} />
      </Modal>

      <Modal {...websiteInfoModalControl.modalProps}>
        <Modal.Header>{t('Website Information')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              "A website is required in order to help verify your business. Ideally you should provide your business's own website, rather than a social media page. The branding on your website for your business (as in, the overall name of your business as shown on your website) should match the Doing Business as Name you provided in this form. Your website must provide a way to contact your business; this can be as simple as an email address or a form. If you provide a social media page, please make sure to include a contact email address for your business."
            )}
          </Text>
          <Text>
            {t(
              "Social media pages can include a company Facebook page or Instagram page. If you don't have a website or a social media page, we recommend creating a simple Facebook page for your business. You can also try submitting a Yelp or WebMD page for your business, but these may cause problems trying to send SMS later, especially if they don't include any contact information for your business."
            )}
          </Text>
        </Modal.Body>
        <Modal.Actions primaryLabel={t('Okay')} onPrimaryClick={websiteInfoModalControl.closeModal} />
      </Modal>

      <EinSuggestionModal
        show={einSearchIsActive && searchResults.length > 0}
        isOnboarding={isOnboarding}
        searchResults={searchResults}
        isReverseSearch={einSearchIsReverse}
        onConfirm={handleEinSuggestionSelection}
        onContinue={() => {
          handleSubmit(
            values as BusinessInfoTypes.BusinessInfoFormValues,
            changedValues as Partial<BusinessInfoTypes.BusinessInfoFormValues>
          );
          resetEinLookupState();
        }}
        onClose={() => {
          resetEinLookupState();
        }}
        hasLocationWritePermission={hasLocationWritePermission}
        isNewBrand={isNewBrand}
      />
    </>
  );
};

const formStyles = css`
  max-width: 500px;
  margin-top: ${`-${theme.spacing(2)}`};

  hr {
    border: 0;
    border-bottom: solid 1px ${theme.colors.neutral20};
  }
`;

const sectionTitleStyles = css`
  display: flex;
  align-items: center;
  overflow: visible;
`;

const sectionHeaderRowStyles = css`
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
`;

const modalStyles = {
  section: css`
    margin-top: ${theme.spacing(2)};
  `,
  listSection: css`
    paddingleft: ${theme.spacing(2)};
  `,
};

const einTextFieldMediaQuery = css`
  height: 40px;
  @media only screen and (max-width: ${breakpoints.xsmall.max}) {
    height: 50px;
  }
`;
