import { useCallback, useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useMiniChatShallowStore } from '../stores';

export const useMiniChatErrorBoundaryHandlers = () => {
  const { t } = useTranslation('mini-chat');
  const alert = useAlert();
  const { reset, showErrorBoundaryAlert, setShowErrorBoundaryAlert, setErrorBoundaryActive } = useMiniChatShallowStore(
    'reset',
    'showErrorBoundaryAlert',
    'setShowErrorBoundaryAlert',
    'setErrorBoundaryActive'
  );

  const resetAndRefresh = useCallback(
    (refresh?: boolean) => {
      reset();
      if (refresh) {
        window.location.reload();
      }
    },
    [reset]
  );

  const showAlert = useCallback(() => {
    alert.error({
      message: t('Something went wrong with the mini-chat. Please refresh or reset and try again.'),
      action: {
        label: t('Reset'),
        onClick: () => {
          resetAndRefresh(true);
        },
      },
    });
    setTimeout(() => {
      setShowErrorBoundaryAlert(false);
    }, 500);
  }, [alert.error, t, resetAndRefresh, setShowErrorBoundaryAlert]);

  useEffect(() => {
    if (showErrorBoundaryAlert) {
      showAlert();
    }
  }, [showErrorBoundaryAlert]);

  const onErrorBoundaryTriggered = useCallback(
    (initiallyShowAlert?: boolean) => {
      setErrorBoundaryActive(true);
      if (initiallyShowAlert) {
        showAlert();
      }
    },
    [setErrorBoundaryActive, showAlert]
  );

  return { resetAndRefresh, onErrorBoundaryTriggered, showAlert };
};
