import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Format to human readable time, but also removes 0 values (except for 0s)
 */
export const formatTimeValue = (value: number) =>
  dayjs
    .duration(value, 's')
    .format('H[h] m[m] s[s]')
    .replace(/\b0+[a-r|t-z]+\s*/gi, '')
    .trim();

export const formatNumber = (value?: number) => new Intl.NumberFormat('en-US').format(value || 0);
