import { useLocalizedQuery } from '@frontend/location-helpers';
import { PageInfo, PageParams, PageParamsInStore } from './types';

interface UseGraphQLQuery<Response, Summary, Edge> {
  queryKey: unknown[];
  queryFn: (pageParams: PageParams) => Promise<Response>;
  numRows: number;
  pageParams: PageParamsInStore;
  setPageParams: (pageParams: PageParamsInStore) => void;
  getPageInfo: (response?: Response) => PageInfo | undefined;
  getEdges: (response?: Response) => Edge[] | [] | undefined;
  getSummary?: (response?: Response) => Summary | undefined;
  enabled: boolean;
}

export const useGraphQLQuery = <Response, Data, Summary, Edge>({
  queryKey,
  queryFn,
  numRows,
  getPageInfo,
  getEdges,
  getSummary,
  pageParams: pageParamsInStore,
  setPageParams,
  enabled,
}: UseGraphQLQuery<Response, Summary, Edge>) => {
  const { currentPage, ...pageParams } = pageParamsInStore;

  const { data, isLoading, refetch, error } = useLocalizedQuery<Response, Error>({
    queryKey: [...queryKey, currentPage],
    enabled: enabled,
    refetchOnWindowFocus: false,
    queryFn: () => queryFn(pageParams),
  });
  const pageInfo = getPageInfo(data);
  const list: Data[] = getEdges(data)?.map((e: any) => e.node) ?? [];

  const fetchNext = () => {
    setPageParams({
      after: pageInfo?.endCursor,
      first: numRows,
      before: undefined,
      last: undefined,
      currentPage: currentPage + 1,
    });
  };

  const fetchPrevious = () => {
    setPageParams({
      before: pageInfo?.startCursor,
      last: numRows,
      after: undefined,
      first: undefined,
      currentPage: Math.max(1, currentPage - 1),
    });
  };

  return {
    loading: isLoading,
    data: list,
    refetch,
    summary: getSummary?.(data),
    hasMore: pageInfo?.hasNextPage,
    hasPrevious: pageInfo?.hasPreviousPage,
    currentPage,
    fetchNextPage: fetchNext,
    fetchPreviousPage: fetchPrevious,
    error,
  };
};
