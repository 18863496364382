import { IconColorType, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { DotProps } from '@frontend/design-system';
import { MessageStatus } from './types';

export type StatusIconMapValue = {
  iconName?: IconName;
  dotColor?: DotProps['color'];
  iconColor?: IconColorType;
  customColor?: string;
};
export const STATUS_ICON_MAP: Record<MessageStatus, StatusIconMapValue> = {
  [MessageStatus.UNREAD]: {
    dotColor: 'primary',
  },
  [MessageStatus.UNREPLIED]: {
    dotColor: 'warning',
  },
  [MessageStatus.READ]: {
    // currently no icon for read status
  },
  [MessageStatus.OUTGOING_SENT]: {
    iconName: 'trend-up',
    iconColor: 'light',
  },
  [MessageStatus.DELIVERY_SUCCESS]: {
    iconName: 'check',
    iconColor: 'success',
  },
  [MessageStatus.ERROR]: {
    iconName: 'alert',
    iconColor: 'error',
  },
  [MessageStatus.PAUSED_SCHEDULED]: {
    iconName: 'calendar-pause',
    iconColor: 'light',
  },
  [MessageStatus.SCHEDULED]: {
    iconName: 'calendar',
    customColor: theme.colors.warning60,
  },
  [MessageStatus.MULTIPLE_SCHEDULED]: {
    iconName: 'info',
    customColor: theme.colors.warning60,
  },
  [MessageStatus.PENDING]: {
    iconName: 'pending',
    iconColor: 'light',
  },
  [MessageStatus.DRAFT]: {
    iconName: 'draft',
    iconColor: 'light',
  },
  [MessageStatus.BLOCKED]: {
    iconName: 'block',
    iconColor: 'light',
  },
  [MessageStatus.ARCHIVED]: {
    iconName: 'archive',
    iconColor: 'light',
  },
};
