import { GuideNameEnum, StepType } from '../types';

type TourGuideInfo = {
  getSteps: () => StepType[];
  version: number;
};

// This mapping will be used to re-set the steps when a guide needs to be re-initialized after a page reload using the 'guideName' from localStorage.
// NOTE: version number need to be incremented when steps get changed
const tourGuideInfoMapping: Partial<Record<GuideNameEnum, TourGuideInfo>> = {};

export const getTourGuideInfo = (guideName: GuideNameEnum): TourGuideInfo | undefined =>
  tourGuideInfoMapping[guideName];
