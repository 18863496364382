import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const cardStyles = (noBorder?: boolean) => css`
  border-radius: ${theme.borderRadius.medium};
  border: ${noBorder ? '0px' : '1px'} solid ${theme.colors.neutral20};
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${theme.spacing(2)};
  overflow: hidden;
  padding: ${noBorder ? 0 : theme.spacing(3)};
  position: relative;

  &.click-enabled {
    cursor: pointer;

    :hover {
      background-color: ${theme.colors.neutral5};
    }
  }
`;

export const chartWrapperStyles = css`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing(3)};
  max-width: 100%;
`;

export const centerPosition = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
