import { css } from '@emotion/react';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { Icon } from '@frontend/icons';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { featureFlags } from '../../../feature-flags';
import { defaultTimePeriods } from '../../../hooks';
import { ARWidgetCard } from './ar-widget-card';
import { BTWidgetCard } from './bt-widget-card';
import { MCTWidgetCard } from './mct-widget-card';

/**
 * @dashboard-widget
 *
 * id: weave-conversion
 * title: Weave Conversion
 * description: Weave Conversion helps you evaluate how the features in Weave are enhancing your practice's performance.
 * icon: dollar-sign-small
 */

export const WeaveConversionWidget: DashboardWidgetFC = () => {
  const dateRange = defaultTimePeriods.lastMonth;
  const { isEditMode } = useDashboardWidget();

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isBulkTextingRoiEnabled = getFeatureFlagValue(featureFlags.bulkTextRoi);

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <Text css={styles.timePeriod} size='medium' weight='bold'>
          <Icon name='calendar-small' />
          {dateRange.label}
        </Text>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={styles.content(isEditMode, isBulkTextingRoiEnabled)}>
        <MCTWidgetCard showTrendLine={!isBulkTextingRoiEnabled} timePeriod={dateRange} />
        {isBulkTextingRoiEnabled && <BTWidgetCard timePeriod={dateRange} />}
        <ARWidgetCard showTrendLine={!isBulkTextingRoiEnabled} timePeriod={dateRange} />
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

WeaveConversionWidget.config = {
  size: 'medium-narrow',
  feature: 'analytics',
};

const styles = {
  timePeriod: css`
    color: ${theme.colors.neutral70};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,

  content: (isEditMode?: boolean, smallCards?: boolean) => css`
    display: flex;
    flex-direction: ${smallCards ? 'row' : 'column'};
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    margin-top: ${theme.spacing(0.75)};
    pointer-events: ${isEditMode ? 'none' : 'auto'};

    button {
      width: ${smallCards ? `calc(50% - ${theme.spacing(0.5)})` : '100%'};
    }
  `,
};
