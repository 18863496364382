import { useCallback, useMemo } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { useMultiStepContext } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { SelectStepNavigationProps } from '../select.types';

export const SelectInsertNavigation = ({ body: Body }: SelectStepNavigationProps) => {
  const { getStepData } = useMultiStepContext();
  const {
    setActiveInvoiceId,
    onInvoiceSelected,
    person,
    multiStep: { goNextStep, onComplete },
  } = useCollectPaymentMultiContext();

  const stepData = getStepData(PAYMENT_MULTI_STEPS.select);

  const onPreviewClick = useCallback((invoice: InvoiceModel) => {
    setActiveInvoiceId(invoice.id);
    goNextStep({ orStep: PAYMENT_MULTI_STEPS.preview });
  }, []);

  const onCreateClick = useCallback(() => {
    goNextStep({ orStep: PAYMENT_MULTI_STEPS.create });
  }, []);

  const onSelectInvoice = useCallback(
    (invoice: InvoiceModel) => {
      setActiveInvoiceId(invoice.id);
      onInvoiceSelected?.(invoice);
    },
    [onInvoiceSelected]
  );

  const onMount = useCallback(
    (invoice: InvoiceModel | undefined) => {
      if (invoice && person && invoice.person.id !== person.personId) {
        /**
         * Avoid bug where user A has a selected invoice.
         * Open user B's select invoice step. Primary button is enabled, no invoice appears
         * to be selected, but if you click "select", will show details from User A's invoice.
         */
        setActiveInvoiceId(null);
      }
    },
    [person]
  );

  const onClickPrimary = useCallback(() => {
    onComplete?.();
  }, [onComplete]);

  const onClickSecondary = useCallback(() => {
    goNextStep({ orStep: PAYMENT_MULTI_STEPS.selectPaymentsTemplate });
  }, []);

  const primary = useMemo(() => {
    return {
      children: 'Insert',
    };
  }, []);

  const secondary = useMemo(() => {
    return {
      disabled: !stepData?.isValid,
      children: 'Insert & Select Message Template',
    };
  }, [stepData?.isValid]);

  return (
    <Body
      onPreviewClick={onPreviewClick}
      onCreateClick={onCreateClick}
      onSelectInvoice={onSelectInvoice}
      onMount={onMount}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      primary={primary}
      secondary={secondary}
    />
  );
};
