import { GetFeatureFlagStatusApiType, ListCalendarAvailabilitiesApiType } from './types';

export const OperatoriesQueryKeys = {
  getOperatoriesList: (locationId: string, page?: string, limit?: string, searchValue?: string) => [
    'operatories',
    locationId,
    page,
    limit,
    searchValue,
  ],
  getOperatoriesListForMultiLocation: (
    locationIds: string[],
    parentLocationId: string,
    page?: string,
    limit?: string,
    searchValue?: string
  ) => ['operatories', ...locationIds, parentLocationId, page, limit, searchValue],
  getOperatoryByOperatoryId: (operatoryId: string) => ['operatories', operatoryId],
};

export const scheduleBookingSiteQueryKeys = {
  base: ['booking-site'],
  featureFlagStatus: (req: GetFeatureFlagStatusApiType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'feature-flag-status',
    req,
  ],
  calendarAvailabilities: (req: ListCalendarAvailabilitiesApiType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'calendar-availabilities',
    req,
  ],
};
