import { Row } from 'react-table';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, TableInstance, useTooltip } from '@frontend/design-system';
import { trackingId } from '../../pendo-tracking';
import { RecentCallRecord } from '../all-calls/types';
import { useShowCallDetailsPanel } from './recent-calls';

export const ViewCallDetailsColumn = ({
  row,
  tableInstance,
  hasAccessToRecordings,
  hoverLabel,
  queryKey,
  registerClick,
}: {
  row: Row<RecentCallRecord>;
  tableInstance: TableInstance<RecentCallRecord>;
  hasAccessToRecordings: boolean;
  hoverLabel?: string;
  queryKey: string[];
  registerClick: () => void;
}) => {
  const { t } = useTranslation('calls');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'bottom',
  });
  const showPanel = useShowCallDetailsPanel({
    row,
    tableInstance,
    hasAccessToRecordings,
    queryKey,
    sectionFocus: 'details',
  });
  return (
    <Button
      variant='secondary'
      css={{
        padding: theme.spacing(0.5),
        border: 'none',
      }}
      aria-label={t('View call details')}
      trackingId={trackingId({ component: 'recentCalls', context: 'table::panel-trigger' })}
      onClick={() => {
        showPanel();
        registerClick();
      }}
      {...triggerProps}
    >
      {hoverLabel && <Tooltip {...tooltipProps}>{hoverLabel}</Tooltip>}
      <Icon name='document-filled' size={24} color={row.isSelected ? 'primary' : 'light'} />
    </Button>
  );
};
