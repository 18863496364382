import { FC, useState, useEffect, Fragment, useRef } from 'react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { PopoverDialog, UsePopoverDialogResponse, RadioField, useControlledField, Text } from '@frontend/design-system';
import { usePmsChangeConfirmationModal } from './pms-change-confirmation-modal';
import PmsSelectorButton from './pms-selector-button/pms-selector-button.component';
import {
  containerStyles,
  radioListContainerStyles,
  hrStyle,
  radioFieldContainerStyle,
  radioSelectStyle,
  radioValueContainerStyle,
  textStyle,
} from './pms-selector.styles';

interface CommonProps {
  popoverDialogControls: UsePopoverDialogResponse;
  availableSources: FormsSourceTenants.Types.ModifiedSourceTenant[];
  onSelectSource: (source: FormsSourceTenants.Types.ModifiedSourceTenant) => void;
  isDisabled?: boolean;
  shouldConfirmChange?: boolean;
}

interface SourceIdSelectorProps extends CommonProps {
  selectedSourceId: string;
}

type PmsSelectorPopoverProps = SourceIdSelectorProps;

export const PmsSelectorPopoverDialog: FC<PmsSelectorPopoverProps> = (props) => {
  const { t } = useTranslation('forms');
  const sourceTenantRef = useRef<{
    prev: FormsSourceTenants.Types.ModifiedSourceTenant | undefined;
    new: FormsSourceTenants.Types.ModifiedSourceTenant | undefined;
  }>({
    prev: undefined,
    new: undefined,
  });
  const [selectedSource, setSelectedSource] = useState<FormsSourceTenants.Types.ModifiedSourceTenant>();
  const { PmsChangeConfirmationModal, pmsChangeConfirmationModalControls } = usePmsChangeConfirmationModal();
  const {
    popoverDialogControls,
    availableSources,
    onSelectSource,
    isDisabled = false,
    shouldConfirmChange = false,
  } = props;
  const selectedId = props.selectedSourceId;

  const radioFieldProps = useControlledField({
    type: 'radio',
    value: selectedId,
    onChange: (newId: string) => {
      const newSource = availableSources.find((source) => source.id === newId);
      if (!newSource) {
        return;
      }

      if (shouldConfirmChange) {
        sourceTenantRef.current.prev = sourceTenantRef.current.new;
        sourceTenantRef.current.new = newSource;
        pmsChangeConfirmationModalControls.openModal();
        popoverDialogControls.close();
        return;
      }

      setSelectedSource(newSource);
      onSelectSource(newSource);
      popoverDialogControls.close();
      sourceTenantRef.current.new = newSource;
    },
  });

  /**
   * Sync selected source details when the selectedId changes.
   */
  useEffect(() => {
    if (selectedId) {
      const filteredSource = availableSources.find((source) => source.id === selectedId);

      if (filteredSource) {
        setSelectedSource(filteredSource);
        sourceTenantRef.current.new = filteredSource;
        return;
      }
    }
    setSelectedSource(undefined);
  }, [selectedId]);

  function confirmChangeHandler() {
    if (sourceTenantRef.current.new) {
      setSelectedSource(sourceTenantRef.current.new);
      onSelectSource(sourceTenantRef.current.new);
    }

    pmsChangeConfirmationModalControls.closeModal();
  }

  return (
    <div>
      {!selectedSource && (
        <Text css={textStyle} size='medium'>
          {t('Select PMS below to setup list of allergy & other medical condition')}
        </Text>
      )}

      <span {...popoverDialogControls.getTriggerProps()}>
        <PmsSelectorButton
          availableSources={availableSources}
          source={selectedSource}
          isOpen={popoverDialogControls.isOpen}
          isDisabled={isDisabled}
        />
      </span>

      {!isDisabled && (
        <PopoverDialog {...popoverDialogControls.getDialogProps()} css={containerStyles}>
          <section css={radioListContainerStyles}>
            <RadioField {...radioFieldProps} name='pms-selector' label='' labelPlacement='left' css={radioSelectStyle}>
              {availableSources.map((source) => (
                <Fragment key={source.id}>
                  <RadioField.Radio value={source.id} css={radioValueContainerStyle}>
                    <div css={radioFieldContainerStyle}>
                      <Text as='span' weight='bold'>
                        {source.sourceName}
                      </Text>
                      <Text weight='light'>{source.pmsName}</Text>
                    </div>
                  </RadioField.Radio>
                  <hr css={hrStyle} />
                </Fragment>
              ))}
            </RadioField>
          </section>
        </PopoverDialog>
      )}

      <PmsChangeConfirmationModal modalControls={pmsChangeConfirmationModalControls} onConfirm={confirmChangeHandler} />
    </div>
  );
};
