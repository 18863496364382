import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { stepValueStyle } from './styles';

export const ComposeMessageStepValue = () => {
  const { t } = useTranslation('schedule-quick-fill');

  return (
    <div css={stepValueStyle}>
      <Text weight='bold'>{t('Compose Message')}</Text>
    </div>
  );
};
