import { ListAppointmentsRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';

export const queryKeys = {
  base: ['schedule'] as const,
  apptReqConfig: (locationId: string) => [...queryKeys.base, 'apptReqConfigData', locationId],
  confirmationTemplate: (locationId?: string) => [
    ...queryKeys.base,
    locationId || '',
    'textTemplates',
    'confirmationTemplate',
  ],
  officeClosedTimes: (locationId: string) => [...queryKeys.base, locationId, 'officeClosedTimes'],
  onlineSchedulingRequestTemplate: (locationId?: string) => [
    ...queryKeys.base,
    locationId || '',
    'textTemplates',
    'onlineSchedulingRequestTemplate',
  ],
  textConnectTemplates: (locationId?: string) => [...queryKeys.base, 'textConnectTemplates', locationId],
  providerUnavailability: (selectedProviderId: string, locationId: string) => [
    ...queryKeys.base,
    'providerUnavailability',
    selectedProviderId,
    locationId,
  ],
  quickFillRecipients: (searchTerm?: string, pageNumber?: number, pageToken?: number, locationId?: string) => [
    ...queryKeys.base,
    'scheduleQuickFillList',
    searchTerm,
    pageNumber,
    pageToken,
    locationId,
  ],
  quickFillTemplate: (selectedTime?: string) => [...queryKeys.base, 'scheduleQuickFillListTemplate', selectedTime],
  appointments: (request: ListAppointmentsRequest) => [...queryKeys.base, 'scheduleAppointments', request],
  appointmentsMulti: (locationIds?: string[]) => [...queryKeys.base, 'scheduleAppointments', ...(locationIds ?? [])],
  providersList: (locationIds?: string[]) => [...queryKeys.base, 'scheduleProvidersList', ...(locationIds ?? [])],
  integrations: (locationId: string, parentLocationId?: string) => [
    ...queryKeys.base,
    'integrations',
    locationId,
    parentLocationId,
  ],
  officeHours: (locationId?: string) => [...queryKeys.base, 'scheduleOfficeHours', locationId],
  officeHoursForMultipleLocationIds: (locationIds: string[]) => [
    ...queryKeys.base,
    'scheduleOfficeHours',
    ...locationIds,
  ],
  appointmentTypes: (locationId: string) => [...queryKeys.base, 'scheduleAppointmentTypes', locationId],
};
