import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

type UseGetAppointmentTypesV3DataParamsType = {
  selectedLocationId: string;
  selectedLocationIds?: string[];
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
  isEnabled?: boolean;
};

export const useGetAppointmentTypesV3Data = ({
  selectedLocationId,
  debouncedSearchValue,
  selectedLocationIds,
  pageConfig,
  isEnabled = true,
}: UseGetAppointmentTypesV3DataParamsType) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;

  const listAppointmentTypesQuery = SchedulerV3.Queries.useListAppointmentTypesQuery({
    request: {
      ...(pageConfig?.limit && { limit: pageConfig?.limit }),
      ...(pageConfig?.page && { page: pageConfig?.page }),
      ...(debouncedSearchValue && { name: debouncedSearchValue }),
      locationId: selectedLocationId,
    },
    options: {
      enabled: !isMultiLocationApiCall && isEnabled,
    },
  });

  const listAppointmentTypesMultiQuery = SchedulerV3.Queries.useListAppointmentTypesMultiQuery({
    request: {
      ...(pageConfig?.limit && { limit: pageConfig?.limit }),
      ...(pageConfig?.page && { page: pageConfig?.page }),
      ...(debouncedSearchValue && { name: debouncedSearchValue }),
      locationsIds: selectedLocationIds || [],
      parentLocationId: parentLocationId || '',
    },
    options: {
      enabled: isMultiLocationApiCall && !!selectedLocationIds?.length && isEnabled,
    },
  });

  return isMultiLocationApiCall ? listAppointmentTypesMultiQuery : listAppointmentTypesQuery;
};

export const useGetAppointmentTypeInfiniteListForV3 = ({
  locationId,
  pageLimit = 50,
  searchText,
}: {
  locationId: string;
  pageLimit?: number;
  searchText?: string;
}) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();

  const singleLocationAppointmentTypeQuery = SchedulerV3.Queries.useListAppointmentTypesInfiniteQuery({
    request: {
      locationId: locationId,
      limit: pageLimit,
      name: searchText,
    },
    options: {
      enabled: !isMultiLocation && !!locationId,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page, index) => ({
            // filter out active appointments
            rows: (page.appointmentTypes ?? []).filter((appointmentType) => !!appointmentType.details?.act),
            totalCount: page.totalCount ?? 0,
            page: index + 1,
            totalPages: Math.ceil((page.totalCount ?? 0) / pageLimit),
          })),
        };
      },
    },
  });

  const multiLocationAppointmentTypeQuery = SchedulerV3.Queries.useListAppointmentTypesMultiInfiniteQuery({
    request: {
      locationsIds: [locationId],
      parentLocationId: parentLocationId,
      limit: pageLimit,
      name: searchText,
    },
    options: {
      enabled: isMultiLocation && !!locationId,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page, index) => ({
            // filter out active appointments
            rows: (page.appointmentTypes ?? []).filter((appointmentType) => !!appointmentType.details?.act),
            totalCount: page.totalCount ?? 0,
            page: index + 1,
            totalPages: Math.ceil((page.totalCount ?? 0) / pageLimit),
          })),
        };
      },
    },
  });

  return isMultiLocation ? multiLocationAppointmentTypeQuery : singleLocationAppointmentTypeQuery;
};

export const useGetPractitionerInfiniteListForV3 = ({
  locationId,
  pageLimit = 50,
  searchText,
}: {
  locationId: string;
  pageLimit?: number;
  searchText?: string;
}) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();

  const singleLocationProvidersQuery = SchedulerV3.Queries.useListProvidersInfiniteQuery({
    request: {
      locationId: locationId,
      limit: pageLimit,
      name: searchText,
    },
    options: {
      enabled: !isMultiLocation && !!locationId,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            totalCount: page.totalCount,
            rows: page.providers,
          })),
        };
      },
    },
  });

  const multiLocationProvidersQuery = SchedulerV3.Queries.useListProvidersMultiInfiniteQuery({
    request: {
      locationIds: [locationId],
      parentLocationId: parentLocationId,
      limit: pageLimit,
      name: searchText,
    },
    options: {
      enabled: !isMultiLocation && !!locationId && !!parentLocationId,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            totalCount: page.totalCount,
            rows: page.providers,
          })),
        };
      },
    },
  });

  return isMultiLocation ? multiLocationProvidersQuery : singleLocationProvidersQuery;
};
