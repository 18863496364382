import { useEffect } from 'react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useQuickFillRecipientShallowStore } from '../store';

export const useSetRecipientCounts = () => {
  const { setTotalCounts } = useQuickFillRecipientShallowStore('setTotalCounts');

  // Fetch Quick Fill recipient count for all locations
  const { data, isLoading } = ScheduleQueries.useGetQuickFillRecipientCounts();

  useEffect(() => {
    if (!isLoading && data?.quickfillCount) {
      setTotalCounts(data.quickfillCount);
    }
  }, [data, isLoading]);
};
