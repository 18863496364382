import { useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

type Props = {
  modalProps: ModalControlModalProps;
  onDelete: () => void;
};

export const DeleteTemplateModal = ({ modalProps, onDelete }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <Modal {...modalProps} minWidth={600}>
      <Modal.Header onClose={modalProps.onClose}>{t('Are You Sure?')}</Modal.Header>
      <Modal.Body>
        <Text>{t('Are you sure you want to delete this template?')}</Text>
      </Modal.Body>
      <Modal.Actions
        secondaryLabel={t('Cancel')}
        primaryLabel={t('Delete Template')}
        onSecondaryClick={modalProps.onClose}
        onPrimaryClick={onDelete}
        destructive
      />
    </Modal>
  );
};
