import { Appointment } from '@weave/schema-gen-ts/dist/schemas/appointments/v3/model.pb';
import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';

export type PetRelatedAppointmentData = {
  latestAppointment: Appointment | undefined;
  futureAppointmentCount: number;
  allAppointments: Appointment[];
};

export const reducePetRelatedAppointmentData = (
  appointments: Appointment[],
  pets: PetDetails[],
  petId?: string
): PetRelatedAppointmentData => {
  const currentTime = new Date().toISOString();
  return appointments.reduce<PetRelatedAppointmentData>(
    (acc, curr) => {
      if (petId) {
        const pet = pets?.find((pet) => pet.petId === petId);
        if (pet && curr.serviceableId !== pet.petServiceableId) return acc; // Return early if we have a specified pet and it doesn't match
      }

      if (curr.appointmentAt > currentTime) {
        acc.futureAppointmentCount += 1;
      }

      if (acc.latestAppointment) {
        const currIsInPastAndLater =
          curr.appointmentAt < currentTime && curr.appointmentAt > acc.latestAppointment.appointmentAt;
        if (currIsInPastAndLater) {
          acc.latestAppointment = curr;
        }
      } else if (curr.appointmentAt < currentTime) {
        acc.latestAppointment = curr;
      }

      return acc;
    },
    { latestAppointment: undefined, futureAppointmentCount: 0, allAppointments: appointments }
  );
};
