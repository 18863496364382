import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { useMediaMatches } from '@frontend/responsiveness';
import { PetList } from './pet-list';
import { PetSummary } from './pet-summary';

// magic number "4" chosen after conversations with product and design.
// the assumption is that most users will not have more than 4 pets.
// if they do, they can quickly hover over the chip to see all pets in the list.
// in the future, we may want to make this component smarter, by measuring the container width, the width of each chip,
// and doing math to determine how many to show, and how many to put in an overflow chip with a "+3" (or whatever)
// as the screen width changes. but for now this is a compromise our team is ok with.
export const ResponsivePetChips = ({ pets, maxPets = 4 }: { pets: PetDetails[]; maxPets?: number }) => {
  const { matches } = useMediaMatches();
  const isWiderThanMobile = matches.useSmallMax();
  const isMobile = !isWiderThanMobile;

  let content = null;

  if (isMobile) {
    // if there is only one pet, show the pet icon with the pet. no need to summarize as "1 pets"
    if (pets.length === 1) {
      content = <PetList pets={pets} />;
    } else {
      content = <PetSummary pets={pets} />;
    }
  } else {
    if (pets.length > maxPets) {
      content = <PetSummary pets={pets} />;
    } else {
      content = <PetList pets={pets} />;
    }
  }
  return content;
};
