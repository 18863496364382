import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  Consent_Action,
  Consent_Medium,
} from '@weave/schema-gen-ts/dist/schemas/comm-preference/consent/v1/consent_service.pb';
import {
  Channel,
  Language,
  WeaveEmailUpdateProgram_Enum_index,
} from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { CommPreferenceQueries } from '@frontend/api-messaging';
import { getUser } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BannerNotification, Button, CheckboxField, Table, Text, useAlert, useForm } from '@frontend/design-system';

interface FaxPreferenceRow {
  id: string;
  updateType: string;
  everyUpdate: boolean;
  dailyUpdate: boolean;
  weeklyUpdate: boolean;
  monthlyUpdate: boolean;
}

type FaxPreferenceFieldName =
  | 'newFaxEveryUpdate'
  | 'newFaxDailyUpdate'
  | 'newFaxWeeklyUpdate'
  | 'newFaxMonthlyUpdate'
  | 'confirmationEveryUpdate'
  | 'confirmationDailyUpdate'
  | 'confirmationWeeklyUpdate'
  | 'confirmationMonthlyUpdate';

export const FaxEmailUpdates = () => {
  const { t } = useTranslation('fax');
  const alert = useAlert();
  const user = getUser();

  /**
   * WARNING
   *
   * Possible misunderstanding of org id vs parent id.
   * A parent location is not the same as an org, and we cannot assume they have the same ids
   *
   */
  const { selectedOrgId, isSingleTypeScope } = useAppScopeStore();

  const isMulti = !isSingleTypeScope;

  const { getFieldProps, seedValues, values } = useForm({
    fields: {
      newFaxEveryUpdate: { type: 'checkbox', required: false },
      newFaxDailyUpdate: { type: 'checkbox', required: false },
      newFaxWeeklyUpdate: { type: 'checkbox', required: false },
      newFaxMonthlyUpdate: { type: 'checkbox', required: false },
      confirmationEveryUpdate: { type: 'checkbox', required: false },
      confirmationDailyUpdate: { type: 'checkbox', required: false },
      confirmationWeeklyUpdate: { type: 'checkbox', required: false },
      confirmationMonthlyUpdate: { type: 'checkbox', required: false },
    },
  });

  const updateTypes = [
    { id: 'new-fax', label: t('New fax messages') },
    { id: 'confirmation-fax', label: t('Fax confirmation pages') },
  ];

  const updateFrequencies = [
    { id: 'everyUpdate', label: t('Every Time'), slug: '_EVERY_TIME' },
    { id: 'dailyUpdate', label: t('Daily'), slug: '_DAILY' },
    { id: 'weeklyUpdate', label: t('Weekly'), slug: '_WEEKLY' },
    { id: 'monthlyUpdate', label: t('Monthly'), slug: '_MONTHLY' },
  ];

  const { data: emailPreferences, refetch } = CommPreferenceQueries.useGetPreference({
    userChannelAddress: user?.username,
    channel: Channel.CHANNEL_WEAVE_EMAIL_UPDATE,
    locationId: selectedOrgId,
  });

  const getCheckboxFieldName = (rowId: string, frequency: string): FaxPreferenceFieldName => {
    const prefix = rowId === 'new-fax' ? 'newFax' : 'confirmation';
    const fieldName = `${prefix}${frequency.charAt(0).toUpperCase()}${frequency.slice(1)}` as FaxPreferenceFieldName;
    return fieldName;
  };

  const faxEmailPreferences = useMemo((): FaxPreferenceRow[] => {
    const preferences = updateTypes.map(
      ({ id, label }): FaxPreferenceRow => ({
        id,
        updateType: label,
        everyUpdate: false,
        dailyUpdate: false,
        weeklyUpdate: false,
        monthlyUpdate: false,
      })
    );

    emailPreferences?.forEach((preference) => {
      const programSlug = parseInt(preference?.programSlug ?? '', 10);
      const consented = !!preference.consented;

      const updatePreference = (rowId: string, frequencyType: keyof Omit<FaxPreferenceRow, 'id' | 'updateType'>) => {
        const row = preferences.find((p) => p.id === rowId);
        if (row) {
          row[frequencyType] = consented;
        }
      };

      switch (programSlug) {
        case WeaveEmailUpdateProgram_Enum_index.FAX_NEW_EVERY_TIME:
          updatePreference('new-fax', 'everyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_NEW_DAILY:
          updatePreference('new-fax', 'dailyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_NEW_WEEKLY:
          updatePreference('new-fax', 'weeklyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_NEW_MONTHLY:
          updatePreference('new-fax', 'monthlyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_EVERY_TIME:
          updatePreference('confirmation-fax', 'everyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_DAILY:
          updatePreference('confirmation-fax', 'dailyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_WEEKLY:
          updatePreference('confirmation-fax', 'weeklyUpdate');
          break;
        case WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_MONTHLY:
          updatePreference('confirmation-fax', 'monthlyUpdate');
          break;
      }
    });

    return preferences;
  }, [emailPreferences]);

  const alertHandlers = {
    onSuccess: () => {
      alert.success(t('Successfully updated email preferences.'));
    },
    onError: () => {
      alert.error(t('Error updating email preferences.'));
    },
    onSettled: () => {
      refetch();
    },
  };

  const { mutate: updatePreference } = CommPreferenceQueries.useRegisterConsent(
    {
      consent: {
        locationId: selectedOrgId,
        userChannelAddress: user?.username,
        channel: Channel.CHANNEL_WEAVE_EMAIL_UPDATE,
      },
    },
    alertHandlers
  );

  const handleSavePreferences = () => {
    const updateObject = {
      channel: Channel.CHANNEL_WEAVE_EMAIL_UPDATE,
      location_id: selectedOrgId,
      location_channel_address: '',
      user_channel_address: user?.username,
      experienceMedium: Consent_Medium.MEDIUM_WEB_FORM,
      experienceAction: Consent_Action.ACTION_FORM_SUBMIT,
      experience_language: Language.LANGUAGE_ENGLISH,
      user_program_consent: [
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_NEW_EVERY_TIME.toString(),
          consented: values.newFaxEveryUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_NEW_DAILY.toString(),
          consented: values.newFaxDailyUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_NEW_WEEKLY.toString(),
          consented: values.newFaxWeeklyUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_NEW_MONTHLY.toString(),
          consented: values.newFaxMonthlyUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_EVERY_TIME.toString(),
          consented: values.confirmationEveryUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_DAILY.toString(),
          consented: values.confirmationDailyUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_WEEKLY.toString(),
          consented: values.confirmationWeeklyUpdate,
        },
        {
          program_slug_id: WeaveEmailUpdateProgram_Enum_index.FAX_CONFIRMATION_MONTHLY.toString(),
          consented: values.confirmationMonthlyUpdate,
        },
      ],
      consented_at: new Date().toISOString(),
      created_at: '',
    };

    updatePreference({ consent: updateObject });
  };

  useEffect(() => {
    if (faxEmailPreferences.length) {
      const newFaxPrefs = faxEmailPreferences.find((pref) => pref.id === 'new-fax');

      const confirmationFaxPrefs = faxEmailPreferences.find((pref) => pref.id === 'confirmation-fax');

      seedValues({
        newFaxEveryUpdate: newFaxPrefs?.everyUpdate ?? false,
        newFaxDailyUpdate: newFaxPrefs?.dailyUpdate ?? false,
        newFaxWeeklyUpdate: newFaxPrefs?.weeklyUpdate ?? false,
        newFaxMonthlyUpdate: newFaxPrefs?.monthlyUpdate ?? false,
        confirmationEveryUpdate: confirmationFaxPrefs?.everyUpdate ?? false,
        confirmationDailyUpdate: confirmationFaxPrefs?.dailyUpdate ?? false,
        confirmationWeeklyUpdate: confirmationFaxPrefs?.weeklyUpdate ?? false,
        confirmationMonthlyUpdate: confirmationFaxPrefs?.monthlyUpdate ?? false,
      });
    }
  }, [faxEmailPreferences]);
  return (
    <section
      css={{
        width: 700,
        borderRadius: theme.borderRadius.medium,
        boxShadow: theme.shadows.light,
        padding: theme.spacing(3),
        backgroundColor: theme.colors.white,
      }}
    >
      <Table<FaxPreferenceRow>
        tableStyle={tableStyle}
        data={faxEmailPreferences}
        colConfig={[
          {
            id: 'updateType',
            width: 175,
            Header: t('Update Type'),
            accessor: (data) => data?.updateType,
            disableSortBy: true,
          },
          ...updateFrequencies.map(({ id, label }) => ({
            id,
            width: 100,
            Header: label,
            disableSortBy: true,
            accessor: (rowData: FaxPreferenceRow) => rowData,
            cellRenderer: (rowData: FaxPreferenceRow) => {
              const fieldName = getCheckboxFieldName(rowData.id, id);
              return (
                <CheckboxField
                  {...getFieldProps(fieldName)}
                  label=''
                  trackingId={`fax-email-settings-${id}-checkbox-${rowData.id}`}
                />
              );
            },
          })),
        ]}
      />
      {user?.username ? (
        <div
          css={{
            marginTop: theme.spacing(2),
          }}
        >
          <Text color='subdued'>{t('Recipient Email')}</Text>
          <Text>{user.username}</Text>
        </div>
      ) : null}

      {isMulti && (
        <div
          css={{
            padding: theme.spacing(2, 0),
          }}
        >
          <BannerNotification
            status='info'
            message={
              <Text size='medium'>
                <Trans>
                  These settings apply to{' '}
                  <Text as='span' weight='bold' size='medium'>
                    all locations
                  </Text>{' '}
                  in your location group. By opting in, you will receive emails with combined data from all locations.
                  Opting out stops updates for all locations' data.
                </Trans>
              </Text>
            }
          />
        </div>
      )}
      <div css={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(3) }}>
        <Button onClick={handleSavePreferences}>{t('Save')}</Button>
      </div>
    </section>
  );
};

// hiding borders must be important and stylized in css``
const tableStyle = css`
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0 !important;
  [role='columnheader']:not(:last-child) {
    border-right: none;
  }
`;
