import { useState, useMemo, FC } from 'react';
import { PersonDetails } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { PersonTypes } from '@frontend/api-person';
import { SendFormButton } from '@frontend/contact-actions-send-form';
import { useTranslation } from '@frontend/i18n';
import { SpinningLoader, Tabs, Text } from '@frontend/design-system';
import { FormItem } from './form-item';
import { mainContainerStyle, panelContainerStyle, textStyle, loaderStyle, sendButtonStyle } from './forms-panel.styles';
import { MissingFormContent } from './missing-form-content';
import { pendoTags } from './pendo-tags';

type FormsPanelProps = {
  person?: PersonTypes.Person;
  locationId?: string;
};

const FormTab = {
  Submission: 'form-submission',
  Send: 'form-send',
};

export const FormsPanel: FC<FormsPanelProps> = ({ person, locationId }) => {
  const { t } = useTranslation('forms');
  const [selectedTab, setSelectedTab] = useState<string>(FormTab.Submission);
  const companyId = locationId || '';
  const personDetails: PersonDetails = useMemo(() => {
    return {
      firstName: person?.FirstName,
      lastName: person?.LastName,
      birthdate: person?.Birthdate,
      id: person?.PersonID,
      phoneNumber: person?.MobilePhone,
      workPhone: person?.WorkPhone,
      homePhone: person?.HomePhone,
    };
  }, [person]);

  const { data, isLoading } = DigitalFormsQueries.useGetSubmissionDetailsForPerson({
    person: personDetails,
    companyId,
  });

  if (isLoading) {
    return (
      <div css={loaderStyle}>
        <SpinningLoader size='small' />
      </div>
    );
  }

  if (!person) {
    return (
      <div css={panelContainerStyle}>
        <Text> {t('No person found')}</Text>
      </div>
    );
  }
  return (
    <section css={mainContainerStyle}>
      <Tabs controlledTab={selectedTab} onChange={setSelectedTab}>
        <Tabs.Bar>
          <Tabs.Tab id={FormTab.Submission} trackingId={pendoTags.contactPanel.submissions}>
            {t('Submissions')}
          </Tabs.Tab>
          <Tabs.Tab id={FormTab.Send} trackingId={pendoTags.contactPanel.sent}>
            {t('Sent')}
          </Tabs.Tab>

          <div css={textStyle}>
            <Text color='subdued' size='small' as='span'>
              {t('Last 30 days')}
            </Text>
          </div>
        </Tabs.Bar>

        <Tabs.Panel controller={FormTab.Submission}>
          {!data?.formsReceived?.length ? (
            <MissingFormContent title={t('Nothing here yet!')} description={t('No forms submitted by this patient')} />
          ) : (
            <div css={panelContainerStyle}>
              {data?.formsReceived?.map((form) => (
                <div key={form.submissionId}>
                  <FormItem key={form.submissionId} form={form} formType='submission' locationId={locationId || ''} />
                </div>
              ))}
            </div>
          )}
        </Tabs.Panel>

        <Tabs.Panel controller={FormTab.Send}>
          {!data?.formsSent?.length ? (
            <div>
              <MissingFormContent title={t('Nothing here yet!')} description={t('No forms sent to this patient')} />
              <Text textAlign='center' color='subdued'>
                {t('Send a form to kick things off and keep the patient journey moving')}
              </Text>
              <div css={sendButtonStyle}>
                <SendFormButton
                  label='Send Form'
                  context={{ person, locationId: locationId || '' }}
                  trackingIdSuffix='contact-panel-send-form-button'
                  buttonLabel='Send Form'
                />
              </div>
            </div>
          ) : (
            <div css={panelContainerStyle}>
              {data?.formsSent?.map((form) => (
                <div key={form.submissionId}>
                  <FormItem key={form.submissionId} form={form} formType='sent' locationId={locationId || ''} />
                </div>
              ))}
            </div>
          )}
        </Tabs.Panel>
      </Tabs>
    </section>
  );
};
