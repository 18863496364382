import { type ComponentProps } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { MessageRegular } from './message-regular';
import { NewMessageDivider } from './new-messages-divider';

interface MessageRegularVirtuosoProps extends ComponentProps<typeof MessageRegular> {
  className?: string;

  /** This is the line that indicates where a user had last caught up to */
  showNewLine: boolean;
}

export const MessageRegularVirtuoso = ({
  showNewLine,
  message,
  isHighlighted,
  onClick,
  onDelete,
  isThread,
  className,
}: MessageRegularVirtuosoProps) => {
  if (message.type === 'system') {
    return <SystemMessage>{message.text}</SystemMessage>;
  }

  return (
    <>
      {showNewLine && <NewMessageDivider />}
      <MessageRegular
        className={className}
        message={message}
        isHighlighted={isHighlighted}
        isThread={isThread}
        onClick={onClick}
        onDelete={onDelete}
      />
    </>
  );
};
MessageRegularVirtuoso.displayName = 'MessageRegularVirtuoso';

const SystemMessage = ({ children }: { children: string }) => {
  return <li css={systemMessageStyles}>{children}</li>;
};

const systemMessageStyles = css({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.colors.neutral50,
  fontSize: theme.fontSize(12),
  fontWeight: theme.font.weight.regular,
});
