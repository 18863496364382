import { contextFactory } from '@frontend/design-system';
import { PaymentActionEnum } from '../types';

interface PaymentsPromotionContextProps {
  source: string;
  hasAccountCreationPermission: boolean;
  hasPaymentsAccount: boolean;
  handleAction: (action: PaymentActionEnum) => void;
}

export const [PaymentsPromotionContext, usePaymentsPromotion] = contextFactory<PaymentsPromotionContextProps>();
