import { i18next } from '@frontend/i18n';
import { findDatesRange } from '../../../utils';

export const CALL_INTEL_TIME_PERIODS = {
  today: {
    label: i18next.t('Today', { ns: 'analytics' }),
    value: findDatesRange({ range: 'day' }),
  },
  yesterday: {
    label: i18next.t('Yesterday', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'day' }),
  },
  thisWeek: {
    label: i18next.t('This Week', { ns: 'analytics' }),
    value: findDatesRange({ range: 'week' }),
  },
  thisMonth: {
    label: i18next.t('This Month', { ns: 'analytics' }),
    value: findDatesRange({ range: 'month' }),
  },
  lastWeek: {
    label: i18next.t('Last Week', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'week' }),
  },
  lastMonth: {
    label: i18next.t('Last Month', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'month' }),
  },
  last7Days: {
    label: i18next.t('Last 7 Days', { ns: 'analytics' }),
    value: findDatesRange({ offset: 7, range: 'day' }),
  },
  last30Days: {
    label: i18next.t('Last 30 Days', { ns: 'analytics' }),
    value: findDatesRange({ offset: 30, range: 'day' }),
  },
};

export const DEFAULT_CALL_INTEL_TIME_PERIOD = 'thisWeek';

export const LOCAL_STORAGE_KEYS = {
  CALL_INTEL_UNSAVED_CHANGES_MODAL_PREF: 'CALL_INTEL_UNSAVED_CHANGES_MODAL_PREF',
};
