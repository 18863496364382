import { useCallback } from 'react';
import { Bool, ThreadStatus } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { Thread } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { Bool_Enum } from '@weave/schema-gen-ts/dist/shared/null/types.pb';
import { SchemaQueryFilters, useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { serviceName } from '../service';
import {
  InfiniteQueryEndpointName,
  QueryEndpointName,
  ServiceQueries,
  SetThreadsArchived,
  SetThreadsNew,
  SetThreadsRead,
  SetThreadsUnarchived,
  SetThreadsActionable,
} from '../types';

const IMMUTABLE_THREAD_KEYS = ['messages', 'locationId', 'departmentId', 'id'] as const satisfies (keyof Thread)[];
type ImmutableThreadKeys = (typeof IMMUTABLE_THREAD_KEYS)[number];
type UpdatableThread = Omit<Thread, ImmutableThreadKeys>;

/**
 * Removes the immutable keys from a thread object to create an updatable thread object.
 */
const convertThreadToUpdatableThread = (thread: Thread): UpdatableThread => {
  return Object.fromEntries(
    Object.entries(thread).filter(([key]) => !IMMUTABLE_THREAD_KEYS.includes(key as ImmutableThreadKeys))
  ) as UpdatableThread;
};

export type ThreadMutationContext = { originalThread?: UpdatableThread; updatedThread?: UpdatableThread };
type ThreadTargetingProperties = Pick<Thread, 'id'> &
  Partial<Pick<Thread, 'locationId' | 'departmentId'>> & {
    groupIds?: string[];
  };
type EndpointQueryFiltersFn<EndpointName extends QueryEndpointName, T extends object = Record<string, unknown>> = (
  args: {
    matchValues: ThreadTargetingProperties;
  } & T
) => {
  queryFilters: SchemaQueryFilters<
    ServiceQueries,
    EndpointName,
    EndpointName extends InfiniteQueryEndpointName ? true : false
  >;
} & Pick<ThreadMutationContext, 'originalThread'>;
type CreateThreadFn = (args: { thread: Thread }) => Pick<ThreadMutationContext, 'updatedThread'>;
type UpdateThreadFn = (args: {
  matchValues: ThreadTargetingProperties;
  newValues: Partial<UpdatableThread>;
}) => ThreadMutationContext;
type UpdateThreadsArchivedStatusFnArgs<ArchivedStatus extends boolean> = {
  archived: ArchivedStatus;
} & (ArchivedStatus extends true
  ? Omit<SetThreadsArchived['input'], 'userId'>
  : Omit<SetThreadsUnarchived['input'], 'userId'>);
type UpdateThreadsActionableStatusFnArgs = {
  actionable: boolean;
} & Omit<SetThreadsActionable['input'], 'userId'>;
type UpdateThreadsReadStatusFnArgs<IsRead extends boolean> = {
  isRead: IsRead;
} & (IsRead extends true ? Omit<SetThreadsRead['input'], 'userId'> : Omit<SetThreadsNew['input'], 'userId'>);
type UpdateThreadsRepliedStatusFnArgs = { groupIds: string[]; threadIds: string[] };
type DeleteThreadFn = (args: {
  matchValues: ThreadTargetingProperties;
}) => Pick<ThreadMutationContext, 'originalThread'>;
type RemoveTagFromThreadFn = (args: { matchValues: ThreadTargetingProperties; tagId: string }) => void;

export const useThreadUpdaters = () => {
  const { updateQuery, getQueryKey, invalidateQueries, getQueriesData } =
    useSchemaQueryUpdaters<ServiceQueries>(serviceName);

  /**
   * A function to get the query filters that will match on the `GetThread` queries that could be affected
   * by changes to the thread that match the given `matchValue`.
   * @param matchValue The values to match the thread on.
   */
  const getGetThreadQueryFilters = useCallback<EndpointQueryFiltersFn<'GetThread'>>(
    ({ matchValues }) => {
      let originalThread: ReturnType<EndpointQueryFiltersFn<'GetThread'>>['originalThread'];
      const queryFilters: ReturnType<EndpointQueryFiltersFn<'GetThread'>>['queryFilters'] = {
        queryKey: getQueryKey<'GetThread'>({
          endpointName: 'GetThread',
          request: matchValues.locationId
            ? {
                threadId: matchValues.id,
                locationId: matchValues.locationId,
              }
            : { threadId: matchValues.id },
        }),
        exact: false,
        predicate: ({ state }) => {
          if (originalThread) return true;
          const thread = state.data?.pages[0]?.thread;
          originalThread = thread ? convertThreadToUpdatableThread(thread) : undefined;
          return true;
        },
      };

      return {
        queryFilters,
        originalThread,
      };
    },
    [getQueryKey]
  );

  /**
   * A function to get the query filters that will match on the `ListThreads` queries that could be affected
   * by changes to the thread that match the given `matchValue`.
   * @param matchValue The values to match the thread on.
   */
  const getListThreadsQueryFilters = useCallback<EndpointQueryFiltersFn<'ListThreads'>>(({ matchValues }) => {
    let originalThread: ReturnType<EndpointQueryFiltersFn<'ListThreads'>>['originalThread'];
    const queryFilters: ReturnType<EndpointQueryFiltersFn<'ListThreads'>>['queryFilters'] = {
      predicate: ({ queryKey, state }) => {
        const request = queryKey[2];

        const locationIdMatches =
          request.locationId && matchValues.locationId ? request.locationId === matchValues.locationId : true;
        const groupIdsMatches =
          request.groupIds?.length && matchValues.locationId ? request.groupIds.includes(matchValues.locationId) : true;
        if (!locationIdMatches && !groupIdsMatches) {
          return false;
        }

        const departmentIdMatches =
          request.departmentIds?.length && matchValues.departmentId
            ? request.departmentIds.includes(matchValues.departmentId)
            : true;
        if (!departmentIdMatches) {
          return false;
        }

        if (!originalThread) {
          originalThread = state.data?.pages.reduce<Thread | undefined>((acc, page) => {
            if (acc) return acc;
            return page.threads.find((thread) => thread.id === matchValues.id);
          }, undefined);
        }
        return true;
      },
    };
    return {
      queryFilters,
      originalThread,
    };
  }, []);

  const createThread = useCallback<CreateThreadFn>(
    ({ thread }) => {
      // GetThread
      invalidateQueries<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: getGetThreadQueryFilters({
          matchValues: { id: thread.id, locationId: thread.locationId, departmentId: thread.departmentId },
        }).queryFilters,
      });

      // ListThreads
      invalidateQueries<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: getListThreadsQueryFilters({
          matchValues: { id: thread.id, locationId: thread.locationId, departmentId: thread.departmentId },
        }).queryFilters,
      });

      if (thread.messages.length > 0) {
        // GetThreadStatus
        updateQuery({
          endpointName: 'GetThreadStatus',
          queryFilters: {
            predicate: (query) => !!query.state.data?.threadId && thread.id === query.state.data.threadId,
            exact: false,
          },
          updater: (data) => {
            if (!data) return data;

            return {
              ...data,
              status: ThreadStatus.ACTIVE,
            };
          },
        });

        // BatchGetThreadStatus
        updateQuery({
          endpointName: 'BatchGetThreadStatus',
          queryFilters: {
            predicate: (query) =>
              !!query.state.data?.responses &&
              query.state.data.responses.some((response) => thread.id === response.threadId),
            exact: false,
          },
          updater: (data) => {
            if (!data) return data;

            return {
              ...data,
              responses: data.responses.map((response) =>
                thread.id === response.threadId ? { ...response, status: ThreadStatus.ACTIVE } : response
              ),
            };
          },
        });
      }

      return { updatedThread: thread };
    },
    [invalidateQueries, getGetThreadQueryFilters, getListThreadsQueryFilters]
  );

  const updateThread = useCallback<UpdateThreadFn>(
    ({ matchValues, newValues }) => {
      const contextResult: ReturnType<UpdateThreadFn> = {};

      // GetThread
      const { queryFilters: getThreadQueryFilters, originalThread: getThreadOriginalThread } = getGetThreadQueryFilters(
        { matchValues }
      );
      if (!contextResult.originalThread) contextResult.originalThread = getThreadOriginalThread;
      updateQuery<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: getThreadQueryFilters,
        updater: (oldData) => {
          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              const newThread = {
                ...page.thread,
                ...newValues,
              };
              if (!contextResult.updatedThread) contextResult.updatedThread = newThread;
              return {
                ...page,
                thread: newThread,
              };
            }),
          };
        },
      });

      // ListThreads
      const { queryFilters: listThreadsQueryFilters, originalThread: listThreadsOriginalThread } =
        getListThreadsQueryFilters({ matchValues });
      if (!contextResult.originalThread) contextResult.originalThread = listThreadsOriginalThread;
      const isOnlyUpdatingReadStatus =
        Object.keys(newValues).length === 1 && (newValues.status === 'read' || newValues.status === 'new');

      if (isOnlyUpdatingReadStatus && matchValues.locationId) {
        updateThreadsReadStatus({
          locationId: matchValues.locationId,
          threadIds: [matchValues.id],
          groupIds: matchValues.groupIds ?? [matchValues.locationId],
          isRead: newValues.status === 'read',
        });
      } else {
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: listThreadsQueryFilters,
        });
      }

      invalidateQueries<'ListThreadsCount'>({
        endpointName: 'ListThreadsCount',
        queryFilters: {
          exact: false,
        },
      });

      return contextResult;
    },
    [updateQuery, invalidateQueries, getGetThreadQueryFilters, getListThreadsQueryFilters]
  );

  const updateThreadsArchivedStatus = useCallback(
    <A extends boolean>({ locationId, threadIds, groupIds, archived }: UpdateThreadsArchivedStatusFnArgs<A>) => {
      /* ------- ListThreads -------- */
      // Collect all queries that would be affected by a change to the thread's archived status
      const queriesToHandle = getQueriesData<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const queryRequest = queryKey[2];
            const matchesGroupIds =
              queryRequest.groupIds?.some((groupId) => groupIds?.includes(groupId) ?? false) ?? true;
            const matchesLocationId = queryRequest.locationId === locationId;

            const isAffectedByArchiveStatusChange =
              (!queryRequest.includeArchived && !queryRequest.isArchived) ||
              !!queryRequest.includeArchived ||
              !!queryRequest.isArchived;

            return (matchesGroupIds || matchesLocationId) && isAffectedByArchiveStatusChange;
          },
        },
      });

      // Separate the queries into those that should be updated (because we have enough information to know the result
      // of the update without more data from the BE) and those that should be invalidated (because we don't know the
      // result of the update)
      const { queryKeysToUpdate, queryKeysToInvalidate } = queriesToHandle.reduce<{
        queryKeysToUpdate: (typeof queriesToHandle)[number][0][];
        queryKeysToInvalidate: (typeof queriesToHandle)[number][0][];
      }>(
        (acc, [queryKey]) => {
          const request = queryKey[2];
          const hasThreadsPreviousStatus = archived
            ? !request.isArchived
            : !!request.isArchived || !!request.includeArchived;

          if (hasThreadsPreviousStatus) acc.queryKeysToUpdate.push(queryKey);
          else acc.queryKeysToInvalidate.push(queryKey);
          return acc;
        },
        { queryKeysToUpdate: [], queryKeysToInvalidate: [] }
      );

      queryKeysToUpdate.forEach((queryKey) => {
        updateQuery<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              threads: page.threads.filter((thread) => !threadIds.includes(thread.id)),
            })),
          }),
        });
      });

      queryKeysToInvalidate.forEach((queryKey) => {
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
        });
      });

      /* ------- GetThreadStatus -------- */
      updateQuery({
        endpointName: 'GetThreadStatus',
        queryFilters: {
          predicate: (query) => !!query.state.data?.threadId && threadIds.includes(query.state.data.threadId),
          exact: false,
        },
        updater: (data) => {
          if (!data) return data;

          return {
            ...data,
            status: archived ? ThreadStatus.ARCHIVED : ThreadStatus.ACTIVE,
          };
        },
      });

      /* ------- BatchGetThreadStatus -------- */
      updateQuery({
        endpointName: 'BatchGetThreadStatus',
        queryFilters: {
          predicate: (query) =>
            !!query.state.data?.responses &&
            query.state.data.responses.some((response) => threadIds.includes(response.threadId)),
          exact: false,
        },
        updater: (data) => {
          if (!data) return data;

          return {
            ...data,
            responses: data.responses.map((response) =>
              threadIds.includes(response.threadId)
                ? { ...response, status: archived ? ThreadStatus.ARCHIVED : ThreadStatus.ACTIVE }
                : response
            ),
          };
        },
      });
    },
    [getQueriesData, updateQuery, invalidateQueries]
  );

  const updateThreadsActionableStatus = useCallback(
    ({ locationId, threadIds, actionable }: UpdateThreadsActionableStatusFnArgs) => {
      // Collect all queries that would be affected by a change to the thread's actionable status
      const queriesToHandle = getQueriesData<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const queryRequest = queryKey[2];
            const matchesGroupIds =
              queryRequest.groupIds?.some((groupId) => queryRequest.groupIds?.includes(groupId) ?? false) ?? true;
            const matchesLocationId = queryRequest.locationId === locationId;

            return matchesGroupIds || matchesLocationId;
          },
        },
      });

      // Separate the queries into those that should be updated (because we have enough information to know the result)
      // and those that should either be filtered or invalidated depending on the new actionable status
      const { queriesToUpdate, actionableOnlyQueries } = queriesToHandle.reduce<{
        queriesToUpdate: (typeof queriesToHandle)[number][0][];
        actionableOnlyQueries: (typeof queriesToHandle)[number][0][];
      }>(
        (acc, [queryKey, data]) => {
          const queryRequest = queryKey[2];
          const isOnlyActionable = queryRequest.actionable === Bool.BOOL_TRUE;
          if (isOnlyActionable) {
            acc.actionableOnlyQueries.push(queryKey);
          } else if (data.pages.some((page) => page.threads.some((thread) => threadIds.includes(thread.id)))) {
            acc.queriesToUpdate.push(queryKey);
          }

          return acc;
        },
        { queriesToUpdate: [], actionableOnlyQueries: [] }
      );

      queriesToUpdate.forEach((queryKey) => {
        updateQuery<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              threads: page.threads.map((thread) =>
                threadIds.includes(thread.id)
                  ? {
                      ...thread,
                      actionable,
                    }
                  : thread
              ),
            })),
          }),
        });
      });

      if (actionable) {
        // If the new status is actionable, we need to invalidate the queries because we don't have the data we need to
        // update them
        actionableOnlyQueries.forEach((queryKey) => {
          invalidateQueries<'ListThreads', true>({
            endpointName: 'ListThreads',
            queryFilters: {
              queryKey,
              exact: true,
            },
          });
        });
      } else {
        // If the new status is not actionable, we can filter the threads out of the queries
        actionableOnlyQueries.forEach((queryKey) => {
          updateQuery<'ListThreads', true>({
            endpointName: 'ListThreads',
            queryFilters: {
              queryKey,
              exact: true,
            },
            updater: (data) => {
              return {
                ...data,
                pages: data.pages.map((page) => ({
                  ...page,
                  threads: page.threads.filter((thread) => {
                    return !threadIds.includes(thread.id);
                  }),
                })),
              };
            },
          });
        });
      }
    },
    [getQueriesData, updateQuery, invalidateQueries]
  );

  const updateThreadsRepliedStatus = useCallback(
    ({ groupIds, threadIds }: UpdateThreadsRepliedStatusFnArgs) => {
      const queriesToHandle = getQueriesData<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const queryRequest = queryKey[2];
            return queryRequest.groupIds?.some((groupId) => groupIds?.includes(groupId) ?? false) ?? true;
          },
        },
      });

      const { queryKeysToUpdate, queryKeysToInvalidate } = queriesToHandle.reduce<{
        queryKeysToUpdate: (typeof queriesToHandle)[number][0][];
        queryKeysToInvalidate: (typeof queriesToHandle)[number][0][];
      }>(
        (acc, [queryKey]) => {
          const request = queryKey[2];

          const shouldUpdate = request.isReplied === Bool_Enum.UNSPECIFIED;
          if (shouldUpdate) {
            acc.queryKeysToUpdate.push(queryKey);
            return acc;
          }

          const shouldInvalidate = request.isReplied && [Bool_Enum.TRUE, Bool_Enum.FALSE].includes(request.isReplied);
          if (shouldInvalidate) {
            acc.queryKeysToInvalidate.push(queryKey);
            return acc;
          }

          return acc;
        },
        { queryKeysToUpdate: [], queryKeysToInvalidate: [] }
      );

      queryKeysToUpdate.forEach((queryKey) => {
        updateQuery<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              threads: page.threads.map((thread) =>
                threadIds.includes(thread.id)
                  ? {
                      ...thread,
                      isReplied: true,
                    }
                  : thread
              ),
            })),
          }),
        });
      });

      queryKeysToInvalidate.forEach((queryKey) => {
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
        });
      });
    },
    [getQueriesData, invalidateQueries]
  );

  const updateThreadsReadStatus = useCallback(
    <R extends boolean>({ locationId, threadIds, groupIds, isRead }: UpdateThreadsReadStatusFnArgs<R>) => {
      // Collect all queries that would be affected by a change to the thread's read/new status
      const queriesToHandle = getQueriesData<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const queryRequest = queryKey[2];
            const matchesGroupIds =
              queryRequest.groupIds?.some((groupId) => groupIds?.includes(groupId) ?? false) ?? true;
            const matchesLocationId = queryRequest.locationId === locationId;

            return matchesGroupIds || matchesLocationId;
          },
        },
      });

      // Separate the queries into the following categories:
      // - queriesToUpdate (because the read/new status change would not add or remove the thread from the query)
      // - queriesToFilter (because the new read/new status would remove the thread from the query)
      // - queriesToInvalidate (because we don't have enough information to know the result of the update)
      const { queryKeysToFilter, queryKeysToUpdate, queryKeysToInvalidate } = queriesToHandle.reduce<{
        queryKeysToFilter: (typeof queriesToHandle)[number][0][];
        queryKeysToUpdate: (typeof queriesToHandle)[number][0][];
        queryKeysToInvalidate: (typeof queriesToHandle)[number][0][];
      }>(
        (acc, [queryKey]) => {
          const request = queryKey[2];

          const hasStatusFilter = !!request.statuses?.length;
          const hasStatusFilterRead = request.statuses?.includes('read');
          const hasStatusFilterNew = request.statuses?.includes('new');
          const hasNoRepliedFilter = !request.isReplied || request.isReplied === Bool_Enum.UNSPECIFIED;
          const hasRepliedFilter = request.isReplied && [Bool_Enum.TRUE, Bool_Enum.FALSE].includes(request.isReplied);

          const shouldFilterQuery =
            hasStatusFilter &&
            !request.statuses?.includes(isRead ? 'read' : 'new') &&
            !hasRepliedFilter &&
            !request.statuses?.includes('error');
          const shouldUpdateQuery =
            (!hasStatusFilter || (hasStatusFilterRead && hasStatusFilterNew)) && hasNoRepliedFilter;
          const shouldInvalidateQuery =
            (isRead ? hasStatusFilterRead : hasStatusFilterNew) ||
            hasRepliedFilter ||
            (!shouldFilterQuery && !shouldUpdateQuery);

          if (shouldFilterQuery) {
            acc.queryKeysToFilter.push(queryKey);
          } else if (shouldUpdateQuery) {
            acc.queryKeysToUpdate.push(queryKey);
          } else if (shouldInvalidateQuery) {
            acc.queryKeysToInvalidate.push(queryKey);
          }

          return acc;
        },
        { queryKeysToFilter: [], queryKeysToUpdate: [], queryKeysToInvalidate: [] }
      );

      queryKeysToFilter.forEach((queryKey) => {
        updateQuery<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              threads: page.threads.filter((thread) => !threadIds.includes(thread.id)),
            })),
          }),
        });
      });

      queryKeysToUpdate.forEach((queryKey) => {
        updateQuery<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              threads: page.threads.map((thread) =>
                threadIds.includes(thread.id)
                  ? {
                      ...thread,
                      status: isRead ? 'read' : 'new',
                    }
                  : thread
              ),
            })),
          }),
        });
      });

      queryKeysToInvalidate.forEach((queryKey) => {
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: {
            queryKey,
            exact: true,
          },
        });
      });
    },
    [getQueriesData, updateQuery, invalidateQueries]
  );

  const deleteThread = useCallback<DeleteThreadFn>(
    ({ matchValues }) => {
      const contextResult: ReturnType<DeleteThreadFn> = {};

      // GetThread
      const { queryFilters: getThreadQueryFilters, originalThread: getThreadOriginalThread } = getGetThreadQueryFilters(
        {
          matchValues,
        }
      );
      if (!contextResult.originalThread) contextResult.originalThread = getThreadOriginalThread;
      invalidateQueries<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: getThreadQueryFilters,
      });

      // ListThreads
      const { queryFilters: listThreadsQueryFilters, originalThread: listThreadsOriginalThread } =
        getListThreadsQueryFilters({
          matchValues,
        });
      if (!contextResult.originalThread) contextResult.originalThread = listThreadsOriginalThread;
      invalidateQueries<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: listThreadsQueryFilters,
      });

      return contextResult;
    },
    [getGetThreadQueryFilters, invalidateQueries, getListThreadsQueryFilters]
  );

  const removeTagFromThread = useCallback<RemoveTagFromThreadFn>(
    ({ matchValues, tagId }) => {
      // GetThread
      updateQuery<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: getGetThreadQueryFilters({
          matchValues,
        }).queryFilters,
        updater: (oldData) => {
          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              thread: {
                ...page.thread,
                uniqueTags: page.thread.uniqueTags?.filter((tag) => tag.tagId !== tagId) ?? [],
                messages: page.thread.messages.map((message) =>
                  message.tags.length || message.tagsDetailed?.length
                    ? {
                        ...message,
                        tags: message.tags.filter((id) => id !== tagId),
                        tagsDetailed: message.tagsDetailed?.filter((tag) => tag.tagId !== tagId),
                      }
                    : message
                ),
              },
            })),
          };
        },
      });

      // ListThreads
      const { queryFilters: listThreadsQueryFilters } = getListThreadsQueryFilters({
        matchValues,
      });
      updateQuery<'ListThreads', true>({
        endpointName: 'ListThreads',
        queryFilters: {
          ...listThreadsQueryFilters,
          predicate: ({ state }) => {
            const hasThreadWithTag = state.data?.pages.some((page) =>
              page.threads.some(
                (thread) => thread.id === matchValues.id && thread.uniqueTags?.some((tag) => tag.tagId === tagId)
              )
            );
            return !!hasThreadWithTag;
          },
        },
        updater: (oldData) => ({
          ...oldData,
          pages: oldData.pages.map((page) => {
            const pageHasThreadWithTag = page.threads.some(
              (thread) => thread.id === matchValues.id && thread.uniqueTags?.some((tag) => tag.tagId === tagId)
            );
            if (!pageHasThreadWithTag) return page;
            return {
              ...page,
              threads: page.threads.map((thread) =>
                thread.uniqueTags?.some((tag) => tag.tagId === tagId)
                  ? {
                      ...thread,
                      uniqueTags: thread.uniqueTags?.filter((tag) => tag.tagId !== tagId) ?? [],
                    }
                  : thread
              ),
            };
          }),
        }),
      });
    },
    [updateQuery, getGetThreadQueryFilters, getListThreadsQueryFilters]
  );

  return {
    /**
     * A function to create a thread on all relevant queries.
     * For now, this just invalidates the queries that could be affected by the new thread.
     * @param thread The thread to create.
     * @returns An object with the updated thread object.
     */
    createThread,
    /**
     * A function to update a thread on all relevant queries.
     * @param matchValues The values to match the thread on.
     * @returns An object with the original and updated thread objects, if found.
     */
    updateThread,
    /**
     * A function to delete a thread on all relevant queries.
     * For now, this just invalidates the queries that could be affected by the deleted thread.
     * @param matchValues The values to match the thread on.
     * @returns An object with the original thread object, if found.
     */
    deleteThread,
    /**
     * A function to remove all instances of a tag from a thread on all relevant queries.
     * @param matchValues The values to match the thread on.
     * @param tagId The ID of the tag to remove.
     */
    removeTagFromThread,
    updateThreadsArchivedStatus,
    updateThreadsActionableStatus,
    /**
     * A function to
     * 1. Sets isReplied=true for specified threads in the ListThreads cache from queries with payload isReplied='unspecified'
     * 2. Invalidates cached ListThreads queries with payload isReplied=true/false to force fresh data fetch
     * @param groupIds LocationIds
     * @param threadIds ID of threads whose isReplied status is to be set as true
     */
    updateThreadsRepliedStatus,
  };
};
