import { createContext, useContext, ReactNode, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from '@frontend/i18n';
import { useIpcShellProvider } from '@frontend/shell-utils';
import { WebsocketState } from '@frontend/websocket';
import { useWebsocketContext } from '../websocket-subscriptions/websocket-subscriptions-provider';

type State = 'healthy' | 'loading' | 'error' | 'unknown' | 'not_applicable';
type Status = {
  state: State;
  reason: string | undefined;
  latency: number | undefined;
  retry: () => void;
};

type ContextValue = {
  ipc: Omit<Status, 'latency'> & { loading: boolean };
  websocket: Status & { isSubscribed: boolean; subscribedLocations: string[]; resubscribe: () => void };
  teamChat: Omit<Status, 'latency'> & {
    key: string;
    updateStatus: (status: State, reason: string | undefined) => void;
  };
};

const AppStatusContext = createContext<ContextValue>({
  ipc: {
    state: 'unknown',
    reason: undefined,
    loading: false,
    retry: () => {},
  },
  websocket: {
    state: 'unknown',
    reason: undefined,
    latency: undefined,
    retry: () => {},
    subscribedLocations: [],
    isSubscribed: false,
    resubscribe: () => {},
  },
  teamChat: {
    state: 'unknown',
    reason: undefined,
    updateStatus: () => {},
    retry: () => {},
    key: '',
  },
});
export const AppStatusProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('settings');
  const ipc = useIpcShellProvider();
  const websockets = useWebsocketContext();
  const teamChat = useTeamChatInfo();

  /* This checks ipc <--> weave & ipc <--> notifications connection status */
  const ipcStatus = ((status: ReturnType<typeof useIpcShellProvider>['status']) => {
    switch (status) {
      case 'connected':
        return 'healthy';
      case 'waiting':
        return 'loading';
      case 'disconnected':
      case 'timeout':
        return 'error';
      case 'not_applicable':
      default:
        return 'not_applicable';
    }
  })(ipc.status);

  const websocketsStatus = ((status: ReturnType<typeof useWebsocketContext>['websocketState']) => {
    switch (status) {
      case WebsocketState.HEALTHY_CONNECTION:
        return 'healthy';
      case WebsocketState.CONNECTING:
      case WebsocketState.RECONNECTING:
      case WebsocketState.WAITING_FOR_PONG:
      case WebsocketState.NEED_TO_RECONNECT:
      case WebsocketState.NEED_TO_REFRESH_TOKEN:
      case WebsocketState.NEED_TO_SEND_PING:
        return 'loading';
      case WebsocketState.WAITING_FOR_URL:
        return 'error';
      default:
        return 'unknown';
    }
  })(websockets.websocketState);

  const value = useMemo(
    () =>
      ({
        ipc: {
          state: ipcStatus,
          loading: ipc.loading,
          retry: ipc.resetConnection,
          reason: ipcStatus === 'error' ? t('There is a problem connecting to notifications!') : undefined,
        },
        websocket: {
          state: websocketsStatus,
          reason: undefined,
          latency: undefined,
          retry: websockets.retry,
          isSubscribed: websockets.isSubscribed,
          subscribedLocations: websockets.subscribedLocations,
          resubscribe: websockets.subscribe,
        },
        teamChat,
      } satisfies ContextValue),
    [
      ipcStatus,
      ipc.loading,
      ipc.resetConnection,
      websocketsStatus,
      websockets.retry,
      websockets?.isSubscribed,
      websockets.subscribe,
    ]
  );
  return <AppStatusContext.Provider value={value}>{children}</AppStatusContext.Provider>;
};

export const useAppStatus = () => {
  return useContext(AppStatusContext);
};

const useTeamChatInfo = () => {
  const [state, setState] = useState<State>('unknown');
  const [reason, setReason] = useState<string | undefined>(undefined);
  const updateStatus = (status: State, reason: string | undefined) => {
    setState(status);
    setReason(reason);
  };

  /**This key will be used in the team chat provider itself, as a mechanism to reset/remount it */
  const [key, setKey] = useState(nanoid());
  const retry = () => setKey(nanoid());
  return {
    key,
    state,
    reason,
    updateStatus,
    retry,
  };
};
