import { FC, useMemo } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Button } from '@frontend/design-system';
import { PaymentsFeatureContentList } from '../../constants';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds, PaymentActionTrackingIds } from '../../tracking-ids';
import { PaymentActionEnum } from '../../types';
import { ContentSlider } from './ContentSlider';

interface PaymentContentSliderProps {
  styles?: Interpolation<Theme>;
}
export const PaymentContentSlider: FC<PaymentContentSliderProps> = ({ styles }) => {
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });

  const { hasPaymentsAccount, hasAccountCreationPermission, handleAction, source } = usePaymentsPromotion();

  const contentList = useMemo(() => {
    return PaymentsFeatureContentList.map((data) => ({
      ...data,
      action: !hasPaymentsAccount ? (
        hasAccountCreationPermission ? (
          <Button
            onClick={() => handleAction(PaymentActionEnum.CREATE_ACCOUNT)}
            size='small'
            css={{ maxWidth: 'max-content', maxHeight: 32 }}
            trackingId={getPaymentHVATrackingIds({
              source,
              trackingId: PaymentActionTrackingIds[PaymentActionEnum.CREATE_ACCOUNT],
            })}
          >
            {t('Set Up Payments Account')}
          </Button>
        ) : (
          <Button
            variant='tertiary'
            css={{ maxWidth: 'max-content' }}
            onClick={() => handleAction(PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE)}
            trackingId={getPaymentHVATrackingIds({
              source,
              trackingId: PaymentActionTrackingIds[PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE],
            })}
          >
            {t('Learn More')}
          </Button>
        )
      ) : null,
    }));
  }, [hasPaymentsAccount]);

  return <ContentSlider contentList={contentList} styles={styles} />;
};
