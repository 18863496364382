import { useState } from 'react';
import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { CollectPaymentModalSteps, PaymentModalProvider } from '@frontend/payments-collection-flow';
import { PaymentModal, PersonInvoiceModalSteps } from '@frontend/person-invoice-payment';
import { useModalControl } from '@frontend/design-system';

type UsePaymentActionArgs = {
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  onClose?: () => void;
  onPaymentSuccess?: () => void;
  onInvoiceCreated?: () => void;
};

/**
 * @deprecated use `CollectPaymentMulti` or one of it's variants instead, which supports multiple processors.
 */
export const usePaymentAction = ({ context, onClose, onPaymentSuccess, onInvoiceCreated }: UsePaymentActionArgs) => {
  const { triggerProps, modalProps } = useModalControl();

  const [initialStep, setInitialStep] = useState<PersonInvoiceModalSteps | CollectPaymentModalSteps>();

  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: [context.locationId], personId: context.personId ?? '' },
    { enabled: !context.person && !!context.personId }
  );

  const person = context.person || PersonsV3.PersonHelpers.convertPersonV3ToPerson(personQuery.data);

  const handleClose = () => {
    modalProps.onClose();
    onClose?.();
  };

  return {
    initialStep,
    setInitialStep,
    triggerProps,
    closeModal: handleClose,
    Modal: (
      <PaymentModalProvider onPaymentSuccess={onPaymentSuccess} onInvoiceCreated={onInvoiceCreated}>
        <PaymentModal {...modalProps} initialStep={initialStep} personId={person.PersonID} person={context.person} />
      </PaymentModalProvider>
    ),
    person,
  };
};
