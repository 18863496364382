import { FC, useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Modal,
  Text,
  TextareaField,
  useAlert,
  ModalControlModalProps,
  useForm,
} from '@frontend/design-system';
import { BundleRequestUpgradeTrackingIds } from '../../constants/tracking-ids';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { BundleUpgradeHeader } from './BundleUpgradeHeader';

interface RequestBundleTrialModal extends ModalControlModalProps {
  bundle: SalesforceAccountProductBundle;
  onActionSuccess: () => void;
}

const NOTE_MAX_LENGTH = 500;

export const RequestBundleTrialModal: FC<RequestBundleTrialModal> = ({ bundle, onActionSuccess, ...modalProps }) => {
  const { feature, selectedLocationIds, isLoading } = useFeatureSubscription();
  const { t } = useTranslation('bundleUpgrade', { keyPrefix: 'request-upgrade' });
  const alerts = useAlert();

  const { getFieldProps, seedValues, values } = useForm({
    fields: {
      notes: {
        type: 'text',
        value: '',
      },
    },
  });

  const { mutate: notifyAdmin, isLoading: isMutating } = SubscriptionsQueries.useNotifyAdminForUpgrade({
    mutationKey: [bundle, 'notify-admin'],
    onSuccess: () => {
      alerts.success(t('Your admin will be notified of your request by email'));
      onActionSuccess();
    },
    onError: (error, request) => {
      alerts.error(t('Failed to notify admin'));
      sentry.log({
        message: 'Failed to notify admin for bundle upgrade request',
        data: {
          error,
          request,
        },
      });
    },
  });

  const handleAction = useCallback(() => {
    const requestData: SubscriptionsQueries.SchemaNotifyAdminForUpgradeRequest = {
      locationId: selectedLocationIds?.[0] || '',
      featureEnum: feature,
      notes: values.notes,
      firstName: getUser()?.firstName,
      // TODO: Milind uncomment when api is ready
      // isBundle: true
    };
    notifyAdmin(requestData);
  }, [values]);

  useEffect(() => {
    if (modalProps.show) {
      seedValues({ notes: '' });
    }
  }, [modalProps.show]);

  return (
    <Modal {...modalProps} minWidth={600}>
      <ContentLoader show={isLoading || isMutating} />
      <Modal.Header onClose={modalProps.onClose} closeBtnTrackingId={BundleRequestUpgradeTrackingIds.dismissAction}>
        <BundleUpgradeHeader bundle={bundle} showTrialBadge />
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        <Text size='medium'>
          {t('Add a note to your admin about why you want access to {{bundleName}} Bundle.', {
            bundleName: getUpgradeBundleDisplayName(bundle),
          })}
        </Text>
        <TextareaField
          label={''}
          {...getFieldProps('notes')}
          placeholder={t('The {{bundleName}} Bundle would help me be more productive because...', {
            bundleName: getUpgradeBundleDisplayName(bundle),
          })}
          helperText={`${values.notes?.length || 0}/${NOTE_MAX_LENGTH}`}
        />
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Send Request'),
          onClick: handleAction,
          disabled: !values.notes,
          trackingId: BundleRequestUpgradeTrackingIds.requestAction,
        }}
      />
    </Modal>
  );
};

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'relative',
});
