import { ComponentProps, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { PickerLocation, useAppScopeStore, WeaveLocation } from '@frontend/scope';
import { useFormField, styles, DropdownFilterChipField, ResolvedFieldProps } from '@frontend/design-system';

const settingsLocationPickerStyle = css`
  width: fit-content;

  @media only screen and (max-width: ${breakpoints.small.min}px) {
    width: 136px;
  }
`;

type LocationOption = {
  label: string;
  value: string;
};

type LocationFilterMenuProps = (
  | {
      locations?: Pick<WeaveLocation, 'name' | 'id'>[];
      options?: never;
    }
  | {
      locations?: never;
      options?: LocationOption[];
    }
) & {
  selectedOptions?: string[];
  onChange: (locations: string[]) => void;
  showClearAll?: boolean;
  trackingIdBase?: string;
  className?: string;
  containerCss?: Interpolation<Theme>;
};

/**
 *
 * @param locations - if specified, will be converted into options for the dropdown filter chip
 * @param options - if specified, will use be used directly as the options for the dropdown filter chip
 *
 * Please use this in conjunction with `filterWhenConstrained` from `@frontend/filters` to keep filtering logic consistent
 *
 * @example
 *
 * const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
 *
 * const filteredData = useMemo(() => {
 *     return filterWhenConstrained({
 *      data: devices ?? [],
 *      constraints: filteredLocations,
 *      filterFn: (device, constraints) => constraints.includes(device.location?.locationId ?? ''),
 *    });
 * }, [devices, filteredLocations]);
 *
 * <LocationFilterMenu
 *    tenantLocation={settingsTenantLocation}
 *    initialFilteredLocations={filteredLocations}
 *    setFilteredLocations={setFilteredLocations}
 * />
 *
 *
 */
export const LocationFilterMenu = ({
  locations,
  options,
  selectedOptions,
  onChange,
  showClearAll,
  trackingIdBase,
  className,
  containerCss,
}: LocationFilterMenuProps) => {
  const { t } = useTranslation('base');

  const checkListField = useFormField(
    {
      type: 'checklist',
      value: selectedOptions,
    },
    [selectedOptions]
  );

  const resolvedOptions: LocationOption[] = useMemo(() => {
    if (options) {
      return options.sort((a, b) => a.label.localeCompare(b.label));
    } else if (locations) {
      return convertToOptions(locations).sort((a, b) => a.label.localeCompare(b.label));
    } else {
      throw new Error('Either locations or options must be provided');
    }
  }, [locations, options]);

  // Only give the user the option to filter by location if there are multiple locations to choose from
  if (resolvedOptions.length < 2) {
    return null;
  }

  return (
    <div css={[settingsLocationPickerStyle, containerCss]}>
      <DropdownFilterChipField
        {...checkListField}
        onChange={(e) => {
          checkListField.onChange(e);
          // @ts-ignore - field types need to be fixed
          onChange(e.value);
        }}
        getCustomLabel={(items) => (items.length > 1 ? t(`{{count}} Locations`, { count: items.length }) : items[0])}
        label={t('Locations')}
        name='location-filter'
        showClearAll={showClearAll}
        trackingIdBase={trackingIdBase}
        onApply={({ close }) => {
          close();
        }}
        className={className}
      >
        {resolvedOptions.map((location) => (
          <DropdownFilterChipField.Option key={location.value} value={location.value} css={{ label: styles.truncate }}>
            {location.label}
          </DropdownFilterChipField.Option>
        ))}
      </DropdownFilterChipField>
    </div>
  );
};

const convertToOptions = (locations: Pick<PickerLocation, 'name' | 'locationId'>[]): LocationOption[] => {
  return locations.map((location) => ({
    label: location.name ?? '',
    value: location.locationId ?? '',
  }));
};

export const ScopedLocationFilterMenu = ({
  selectedOptions,
  onChange,
  trackingIdBase,
  options,
  ...rest
}: ComponentProps<typeof LocationFilterMenu>) => {
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const locallyAvailableLocations = selectedLocationIds?.map((id) => accessibleLocationData[id]).filter(Boolean) ?? [];

  return (
    <LocationFilterMenu
      locations={locallyAvailableLocations}
      selectedOptions={selectedOptions}
      onChange={onChange}
      trackingIdBase={trackingIdBase}
      {...rest}
    />
  );
};

export const ScopedSingleLocationFilterMenu = ({ field }: { field: ResolvedFieldProps<'checklist'> }) => {
  const { t } = useTranslation('base');

  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const locallyAvailableLocations = selectedLocationIds?.map((id) => accessibleLocationData[id]).filter(Boolean) ?? [];
  const options = convertToOptions(locallyAvailableLocations);

  const label = field.value.length > 0 ? accessibleLocationData[field.value[0]]?.name : t('Select Location');

  return (
    <DropdownFilterChipField
      isSingleSelect
      showSearchBar={false}
      showSelectionBar={false}
      {...field}
      name='location-filter'
      label={label}
    >
      {options.map((location) => (
        <DropdownFilterChipField.SingleSelectOption key={location.value} value={location.value}>
          {location.label}
        </DropdownFilterChipField.SingleSelectOption>
      ))}
    </DropdownFilterChipField>
  );
};
