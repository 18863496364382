import { useMemo } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PaymentsFeatureFlags, useMultiQueryUtils } from '@frontend/payments-hooks';
import { PaymentsTableInstances } from '../../utils';
import { GenerateColumnsFn, useGenerateColumns } from '../use-generate-columns';

type GenerateColumnsHOF<T extends NonNullable<unknown>> = (options: {
  showPayoutDescriptor: boolean;
}) => GenerateColumnsFn<T>;

export const useColumnsWithPayoutDescriptor = <T extends NonNullable<unknown>>({
  forPrint,
  hideLocationNameColumn = false,
  generateColumns,
  tableName,
}: {
  forPrint: boolean;
  hideLocationNameColumn?: boolean;
  generateColumns: GenerateColumnsHOF<T>;
  tableName: PaymentsTableInstances;
}) => {
  const { locationIds } = useMultiQueryUtils();

  const { aggregateValue: showPayoutDescriptor } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.PayoutDescriptorColumn,
    locationIds: locationIds,
  });
  const generateTableColumns = useMemo(() => generateColumns({ showPayoutDescriptor }), [showPayoutDescriptor]);

  const columns = useGenerateColumns<T>(generateTableColumns, forPrint, tableName, {
    hideLocationNameColumn,
  });
  return { columns, showPayoutDescriptor };
};
