/**
 * Configuration for Panel priorities and identifiers in the Panel Engine system.
 *
 * @description
 * This configuration defines the priority levels for different panel types in the application.
 * Higher numbers indicate higher priority levels when managing panel visibility and interactions.
 *
 * The priority values are used to determine:
 * - Panel horizontal ordering
 * - Panel priority in the tray
 *
 * Priority values are automatically assigned to PanelPortals based on this configuration,
 * so they don't need to be manually passed to PanelPortal components.
 *
 * @type {Record<string, number>}
 * The type `PanelConfig` is derived from this configuration and serves as the identifier type
 * for panel instances throughout the application.
 */

export const panelPriority = {
  teamchat: 7,
  'slide-panels': 5,
  notifications: 4,
  schedulePulse: 6,
  quickFill: 2,
  'task-center': 1,
} as const satisfies Record<string, number>;

export type PanelConfig = keyof typeof panelPriority;
