import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyDigitalFormsCategorizedListSvg = lazy(() => import('./svg-components/digital-forms-categorized-list-svg'));

const DigitalFormsCategorizedListSvg = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyDigitalFormsCategorizedListSvg {...rest} />
  </Suspense>
);

export default DigitalFormsCategorizedListSvg;
