import { useQuery, useQueries, useQueryClient } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions, LimitedSchemaFetchQueryOptions } from '@frontend/react-query-helpers';
import { SchemaPetsV1Service } from '../service';
import { FetchPetsByPersonIdIO } from '../types';

export const getFetchPetsByPersonIdQueryKey = (req: FetchPetsByPersonIdIO['input']) =>
  ['PetsV1', 'FetchPetsByPersonId', req] as const;
type QueryKey = ReturnType<typeof getFetchPetsByPersonIdQueryKey>;

/**
 * A hook that returns a query for the `FetchPetsByPersonId` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useFetchPetsByPersonIdQuery = <E = unknown, D = FetchPetsByPersonIdIO['output']>(
  req: FetchPetsByPersonIdIO['input'],
  options?: LimitedSchemaQueryOptions<FetchPetsByPersonIdIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<FetchPetsByPersonIdIO['output'], E, D, QueryKey>({
    queryKey: getFetchPetsByPersonIdQueryKey(req),
    queryFn: () => SchemaPetsV1Service.FetchPetsByPersonId(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `FetchPetsByPersonId` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useFetchPetsByPersonIdQueries = <E = unknown, D = FetchPetsByPersonIdIO['output']>(
  argsArr: {
    req: FetchPetsByPersonIdIO['input'];
    options?: LimitedSchemaQueryOptions<FetchPetsByPersonIdIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getFetchPetsByPersonIdQueryKey(req),
      queryFn: () => SchemaPetsV1Service.FetchPetsByPersonId(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useFetchPetsByPersonIdQuery<E, D>>[];

/**
 * A hook that returns a function to fetch the query for the `FetchPetsByPersonId` endpoint.
 * It uses `queryClient.fetchQuery` to ensure proper caching behavior.
 *
 * @returns The function to fetch the query, with the following args:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `fetchQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useFetchFetchPetsByPersonId = () => {
  const queryClient = useQueryClient();

  const fetchFetchPetsByPersonId = async <E = unknown, D = FetchPetsByPersonIdIO['output']>(
    req: FetchPetsByPersonIdIO['input'],
    options?: LimitedSchemaFetchQueryOptions<FetchPetsByPersonIdIO, E, D, QueryKey>,
    httpOptions?: Options
  ) => {
    const response = await queryClient.fetchQuery<FetchPetsByPersonIdIO['output'], E, D, QueryKey>(
      getFetchPetsByPersonIdQueryKey(req),
      () => SchemaPetsV1Service.FetchPetsByPersonId(req, httpOptions),
      options
    );

    return response;
  };

  return fetchFetchPetsByPersonId;
};
