import { useMatch, useSearch } from '@tanstack/react-location';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { NEW_CAMPAIGN_ID, SMS_BETA_CAMPAIGN_TYPE } from '../constants';
import { BulkMessagingSubNav, EditorMode, EditorView } from '../types';

export type BulkMessagingUrlSearchParams = {
  seedId?: string;
  copyAudience?: boolean;
};

// Passing the search params and getting them from useSearch as a fallback is ghetto,
// but it is necessary to prevent a weird screen flashing when navigating from the duplicate screen
export const useBulkMessagingUrlData = (search?: BulkMessagingUrlSearchParams) => {
  const match = useMatch<{
    Params: {
      id: string;
      type: 'text' | 'email' | typeof SMS_BETA_CAMPAIGN_TYPE;
      view: EditorView;
      subNav: BulkMessagingSubNav;
    };
  }>();
  const routeSearch = useSearch<{ Search: BulkMessagingUrlSearchParams }>();
  const { seedId: seedCampaignId, copyAudience } = search ? search : routeSearch;
  const { subNav, type, view, id: campaignId } = match.params;

  const campaignType = type === 'text' ? CampaignType.SMS : CampaignType.EMAIL;
  const isDraft = subNav === 'drafts';
  const mode: EditorMode = campaignId !== NEW_CAMPAIGN_ID ? 'edit' : !!seedCampaignId ? 'duplicate' : 'create';

  // TODO: Remove campaignTypeWithBeta when Bulk Text is migrated
  const campaignTypeWithBeta: CampaignType | typeof SMS_BETA_CAMPAIGN_TYPE =
    type === SMS_BETA_CAMPAIGN_TYPE ? SMS_BETA_CAMPAIGN_TYPE : campaignType;

  return {
    campaignId,
    campaignType,
    copyAudience,
    isDraft,
    mode,
    seedCampaignId,
    view,
    campaignTypeWithBeta,
  };
};
