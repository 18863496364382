import { useQueries, useQueryClient } from 'react-query';
import { InvoiceModel, InvoiceResponse, InvoiceSearchParams, getInvoiceHistory } from '@frontend/api-invoices';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

type LocationIds = (string | undefined)[];
type FetchParams = {
  numRows: number;
  paymentsUrl: string | null;
  locationIds: LocationIds;
  skip: number;
  personid: string;
};

const fetchInvoices = async ({ numRows, skip, paymentsUrl, personid, locationIds }: FetchParams) => {
  const url = `${paymentsUrl}/v1/search/invoices`;
  const order: InvoiceSearchParams['order'] = '-billedAt';
  const params = {
    limit: numRows,
    skip,
    order,
    personid,
    locationIds,
    status: ['UNPAID'],
    active: true,
  };
  return await getInvoiceHistory(url, params);
};

export const useInvalidateActiveInvoiceQueries = (locationIds?: string[]) => {
  const queryClient = useQueryClient();
  const [baseKey] = getPersonActiveInvoiceKey();

  return () => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        const [queryBase, queryLocationIds] = query.queryKey;

        if (queryBase !== baseKey) return false;

        /**
         * If no location ids provided, return true.
         * Base key was already matched.
         */
        if (!locationIds) {
          return true;
        }

        /**
         * No location Ids in query? Must be wrong query.
         */
        if (!queryLocationIds || !Array.isArray(queryLocationIds)) {
          return false;
        }

        /***
         * If any of the locations overlap, invalidate the query.
         */
        return queryLocationIds.some((id) => locationIds.includes(id));
      },
    });
  };
};
export const getPersonActiveInvoiceKey = (...xs: any[]) => {
  return ['active-invoices-person', ...xs];
};

export const usePersonActiveInvoiceList = (primaryPersonId?: string, associatedPersonIds: string[] = []) => {
  const NUM_ROWS = 50;
  const ORDER = '-billedAt';
  const { paymentsUrl } = useMerchant();
  const { locationIds } = useMultiQueryUtils();

  const personIds = [...new Set([primaryPersonId ?? '', ...associatedPersonIds].filter(Boolean))];

  const invoicesQueries = useQueries(
    personIds.map((id) => ({
      enabled: !!paymentsUrl && !!id,
      queryKey: getPersonActiveInvoiceKey(locationIds, id, NUM_ROWS, ORDER),
      queryFn: ({ pageParam: skip = 0 }) =>
        fetchInvoices({
          skip,
          paymentsUrl,
          numRows: NUM_ROWS,
          personid: id || '',
          locationIds,
        }),
      cacheTime: 0,
      select: (data: InvoiceResponse) => data.data,
      getNextPageParam: (lastPage: InvoiceResponse) => lastPage.meta?.links.next,
    }))
  );

  const invoices = invoicesQueries.flatMap((query) => query?.data?.invoices || []);

  // Invoices grouped by personIds in a multi person scenario.
  let multiPersonGroupedInvoices: Record<string, InvoiceModel[]> | undefined;
  if (!!primaryPersonId && !!associatedPersonIds.length && !!invoices.length) {
    const groupedInvoicesInitValue = personIds.reduce<Record<string, InvoiceModel[]>>((acc, id) => {
      acc[id] = [];
      return acc;
    }, {});
    multiPersonGroupedInvoices = invoices.reduce<Record<string, InvoiceModel[]>>((acc, invoice) => {
      const currentPersonId = invoice.person.id;
      if (!acc[currentPersonId]) {
        acc[currentPersonId] = [];
      }
      acc[currentPersonId].push(invoice);
      return acc;
    }, groupedInvoicesInitValue);
  }

  const isFetchInvoicesLoading = invoicesQueries.some((query) => query?.isFetching);
  const refetchInvoices = async () => Promise.all(invoicesQueries.map(({ refetch }) => refetch()));

  return {
    invoices,
    multiPersonGroupedInvoices,
    isFetchInvoicesLoading,
    refetchInvoices,
  };
};
