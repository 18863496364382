import {
  DigitalFormsDisclaimerProducts,
  PaymentMethodInfo,
  PaymentMethodResponse,
  SignQuoteDTO,
  SignQuoteInfo,
} from '../types';

export const prepareSignQuoteDTO = ({
  quoteData,
  ccData,
  eSignature,
  hidePayment,
  shippingAddress,
}: SignQuoteInfo): SignQuoteDTO => {
  const trimmedESignature = eSignature.trim();
  const firstName = trimmedESignature.slice(0, trimmedESignature.indexOf(' '));
  const lastName = trimmedESignature.slice(trimmedESignature.lastIndexOf(' ') + 1);

  return {
    signatureNameProvided: trimmedESignature,
    paymentParam: hidePayment ? 'false' : 'true', //The backend can parse it as:( True: 1, t, T, TRUE, true, True), (False: 0, f, F, FALSE, false, False)
    customerRequest: {
      customerName: trimmedESignature,
      customerDisplayName: trimmedESignature,
      contactName: `B_${quoteData.account.slug}`, // TODO: remove this once quote_id is used
      contactAddress1: quoteData.account.billingStreet,
      contactAddress2: '',
      contactCity: quoteData.account.billingCity,
      contactCompanyName: quoteData.account.name,
      contactFirstName: firstName,
      contactInitial: '',
      contactLastName: lastName,
      contactPhone: quoteData.account.phone,
      contactState: quoteData.account.billingState,
      contactZipCode: quoteData.account.billingPostalCode,
      contactEmail: quoteData.account.email,
      contactCellPhone: quoteData.account.phone,
      storedAccountName: `${firstName} ${lastName} - ${quoteData.account.slug}`,
      storedAccountType: ccData.type,
      token: ccData.token,
    },
    shippingAddress: shippingAddress,
  };
};

export const formatPaymentMethod = (resp: PaymentMethodResponse): PaymentMethodInfo => ({
  name: resp.storedAccountName,
  hash: resp.storedAccountHash,
  type: resp.storedAccountType,
});

export const isDigitizationFeeService = (serviceName?: string): boolean => {
  return [
    DigitalFormsDisclaimerProducts.FORMS_DIGITIZATION_FEE,
    DigitalFormsDisclaimerProducts.DIGITAL_FORMS_IMPLEMENTATION_FEE,
  ].includes(serviceName as DigitalFormsDisclaimerProducts);
};
