import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  Button,
  Chip,
  ClockIconSmall,
  DateRangeField,
  DropdownField,
  NakedButton,
  PopoverMenu,
  useControlledField,
  usePopoverMenu,
} from '@frontend/design-system';
import { calculateDateDifference, findDatesRange } from '../utils';

export const defaultTimePeriods = {
  today: {
    label: i18next.t('Today', { ns: 'analytics' }),
    value: findDatesRange({ range: 'day' }),
  },
  yesterday: {
    label: i18next.t('Yesterday', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'day' }),
  },
  thisWeek: {
    label: i18next.t('This Week', { ns: 'analytics' }),
    value: findDatesRange({ range: 'week' }),
  },
  lastWeek: {
    label: i18next.t('Last Week', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'week' }),
  },
  lastMonth: {
    label: i18next.t('Last Month', { ns: 'analytics' }),
    value: findDatesRange({ offset: 1, range: 'month' }),
  },
  last7Days: {
    label: i18next.t('Last 7 Days', { ns: 'analytics' }),
    value: findDatesRange({ offset: 7, range: 'day' }),
  },
  last30Days: {
    label: i18next.t('Last 30 Days', { ns: 'analytics' }),
    value: findDatesRange({ offset: 30, range: 'day' }),
  },
};

export type TimePeriod = {
  label: string;
  value: {
    startDate: string;
    endDate: string;
  };
};

export type CustomDateRange = Record<string, TimePeriod>;

export type TimePeriodKey<T extends object | undefined = undefined> = T extends object
  ? keyof T
  : keyof typeof defaultTimePeriods;

export type TimePeriods<T extends object | undefined = undefined> = Record<TimePeriodKey<T>, TimePeriod>;

type TimePeriodSelection<T extends object | undefined = undefined> = {
  endDate?: string;
  selectedPeriod?: TimePeriodKey<T> | null;
  startDate?: string;
};

type TimePeriodSelectorProps<T extends object | undefined = undefined> = {
  customTimePeriodTracking?: pendo.Metadata;
  disabled?: boolean;
  onChange?: (selection: TimePeriodSelection<T>) => void;
  persistDefaultLabel?: boolean;
  timePeriods?: Record<string, TimePeriod>;
  trackingIdBase: string;
};

type TimePeriodPopoverSelectorProps<T extends object | undefined = undefined> = TimePeriodSelectorProps<T> & {
  showDateRangePicker?: boolean;
};

type ExtendedProps<T extends object | undefined = undefined> = {
  defaultPeriod: Exclude<TimePeriodKey<T>, ''>;
  onChangeInternal: (selection: TimePeriodSelection<T>) => void;
  values: TimePeriodSelection<T>;
};

type TimePeriodPopoverSelectorPropsExtended<T extends object | undefined = undefined> =
  TimePeriodPopoverSelectorProps<T> & ExtendedProps<T>;

type TimePeriodDropDownSelectorPropsExtended<T extends object | undefined = undefined> = TimePeriodSelectorProps<T> &
  ExtendedProps<T>;

interface UseTimePeriodSelectorProps<T extends object | undefined = undefined> {
  defaultPeriod: Exclude<TimePeriodKey<T>, ''>;
  skipFindingExistingPeriod?: boolean;
  timePeriods?: TimePeriods<T>;
  values?: Partial<TimePeriodSelection<T>>;
}

export const findExistingTimePeriodKey = <T extends object | undefined = undefined>(
  startDate: string,
  endDate: string,
  timePeriods: Record<string, TimePeriod> = defaultTimePeriods
): TimePeriodKey<T> | null => {
  const period = Object.entries(timePeriods || {}).find(([_, { value }]) => {
    return (
      dayjs(startDate).isSame(dayjs(value.startDate), 'date') && dayjs(endDate).isSame(dayjs(value.endDate), 'date')
    );
  });

  return period ? (period[0] as TimePeriodKey<T>) : null;
};

export const getSelectedPeriodUsingDates = <T extends object | undefined = undefined>(
  defaultPeriod: Exclude<TimePeriodKey<T>, ''>,
  skipFindingExistingPeriod = false,
  timePeriods: TimePeriods<T> = defaultTimePeriods,
  values?: Partial<TimePeriodSelection<T>>
) => {
  if (values?.selectedPeriod) {
    return values.selectedPeriod;
  }

  const { startDate, endDate } = values || {};

  if (!startDate || !endDate) {
    return defaultPeriod;
  }

  if (skipFindingExistingPeriod) return null;

  return findExistingTimePeriodKey<T>(startDate, endDate, timePeriods);
};

const TimePeriodPopoverSelector = <T extends object | undefined = undefined>({
  customTimePeriodTracking,
  defaultPeriod,
  disabled,
  onChange,
  onChangeInternal,
  persistDefaultLabel = false,
  showDateRangePicker = true,
  timePeriods = defaultTimePeriods,
  trackingIdBase,
  values,
}: TimePeriodPopoverSelectorPropsExtended<T>) => {
  const { t } = useTranslation('analytics');
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriodKey<T> | null | undefined>(values?.selectedPeriod);
  const [startDate, setStartDate] = useState<string | undefined>(values?.startDate);
  const [endDate, setEndDate] = useState<string | undefined>(values?.endDate);
  const customDateRangeRef = useRef<{ startDate?: string; endDate?: string }>({ startDate, endDate });

  const { close, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom-start',
  });
  const { ref, ...triggerProps } = getTriggerProps();

  const datesRangeProps = useControlledField({
    type: 'dateRange',
    value: startDate && endDate ? [dayjs(startDate).format('MM/DD/YYYY'), dayjs(endDate).format('MM/DD/YYYY')] : [],
    maxDate: dayjs(new Date()).format('YYYY-MM-DD'),
    onChange: (dates) => {
      if (dayjs(dates[0]).isValid() && dayjs(dates[1]).isValid()) {
        customDateRangeRef.current = {
          startDate: dayjs(dates[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dayjs(dates[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        };
        setSelectedPeriod(null);
      }
    },
  });

  const applyChange = useCallback(
    (values: TimePeriodSelection<T>) => {
      setSelectedPeriod(values.selectedPeriod);
      setStartDate(values.startDate);
      setEndDate(values.endDate);
      onChangeInternal(values);
      onChange?.(values);
      close();
    },
    [close]
  );

  const handleChange = useCallback(
    (key: TimePeriodKey<T>) => {
      if (timePeriods?.[key]) {
        const {
          value: { startDate, endDate },
        } = timePeriods[key];

        applyChange({ selectedPeriod: key, startDate, endDate });
      }
    },
    [timePeriods]
  );

  const handleApplyClick = useCallback(() => {
    if (!customDateRangeRef.current.startDate || !customDateRangeRef.current.endDate) {
      return;
    }

    const startDate = customDateRangeRef.current.startDate;
    const endDate = customDateRangeRef.current.endDate;

    applyChange({
      selectedPeriod: null,
      startDate,
      endDate,
    });

    if (customTimePeriodTracking) {
      customTimePeriodTracking &&
        pendo.track(`${trackingIdBase}-custom-time-period`, {
          daysDifference: calculateDateDifference(startDate, endDate, 'day', true),
          endDate,
          startDate,
          ...customTimePeriodTracking,
        });
    }
  }, [customDateRangeRef.current]);

  return (
    <div ref={ref}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={styles.popoverFilterButton}
        filterIsActive={selectedPeriod !== defaultPeriod}
        leftElement={<Icon name='clock-small' color={selectedPeriod !== defaultPeriod ? 'white' : 'default'} />}
        trackingId={trackingIdBase}
      >
        {persistDefaultLabel || !selectedPeriod ? t('Time Period') : timePeriods?.[selectedPeriod]?.label}
      </Chip.DropdownFilter>

      <PopoverMenu {...getMenuProps()}>
        {Object.entries(timePeriods || {}).map(([key, { label }]) => {
          const timePeriodKey = key as TimePeriodKey<T>;

          return (
            <NakedButton
              disabled={disabled}
              className={timePeriodKey === selectedPeriod ? 'active' : ''}
              css={styles.listItem}
              key={timePeriodKey}
              onClick={() => handleChange(timePeriodKey)}
              trackingId={`${trackingIdBase}-${timePeriodKey}`}
            >
              {timePeriodKey === selectedPeriod && (
                <span style={{ color: theme.colors.text.interactive }}>
                  <Icon name='check' />
                </span>
              )}
              {label}
            </NakedButton>
          );
        })}

        {showDateRangePicker && (
          <>
            <hr css={styles.hr} />
            <div css={styles.dateRangeWrapper}>
              <DateRangeField
                {...datesRangeProps}
                css={styles.dateRange}
                disabled={disabled}
                label={t('Pick your dates')}
                name='dates'
              />
              <Button disabled={disabled} onClick={handleApplyClick} trackingId={`${trackingIdBase}-apply-button`}>
                {t('Apply')}
              </Button>
            </div>
          </>
        )}
      </PopoverMenu>
    </div>
  );
};

const TimePeriodDropDownSelector = <T extends object | undefined = undefined>({
  defaultPeriod,
  disabled,
  onChange,
  onChangeInternal,
  timePeriods = defaultTimePeriods,
  trackingIdBase,
  values,
}: TimePeriodDropDownSelectorPropsExtended<T>) => {
  const { t } = useTranslation('analytics');

  const daysFieldProps = useControlledField({
    type: 'dropdown',
    value: values.selectedPeriod || (values.selectedPeriod === null ? undefined : defaultPeriod),
    onChange: (key: TimePeriodKey<T>) => {
      if (timePeriods[key]) {
        const {
          value: { startDate, endDate },
        } = timePeriods[key];

        const params = {
          selectedPeriod: key,
          startDate,
          endDate,
        };

        onChangeInternal(params);
        onChange?.(params);
      }
    },
  });

  return (
    <DropdownField
      {...daysFieldProps}
      css={styles.dropdownSelector}
      data-trackingid={trackingIdBase}
      disabled={disabled}
      icon={ClockIconSmall}
      label={t('Time Period')}
      name='days'
      placeholder={t('Time Period')}
    >
      {Object.entries(timePeriods || {}).map(([key, { label }]) => {
        return (
          <DropdownField.Option key={key} data-trackingid={`${trackingIdBase}-${key}`} value={key}>
            {label}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const useTimePeriodSelectorCommon = <T extends object | undefined = undefined>({
  defaultPeriod,
  timePeriods = defaultTimePeriods,
  values,
  skipFindingExistingPeriod,
}: UseTimePeriodSelectorProps<T>) => {
  // Memoize values to prevent infinite looping when using both the hooks in the same component
  const memoizedValues = useMemo(() => values, [values ? JSON.stringify(values) : null]);

  const [state, setState] = useState<TimePeriodSelection<T>>({
    selectedPeriod: getSelectedPeriodUsingDates<T>(
      defaultPeriod,
      skipFindingExistingPeriod,
      timePeriods,
      memoizedValues
    ),
    startDate: memoizedValues?.startDate ?? timePeriods?.[defaultPeriod]?.value?.startDate,
    endDate: memoizedValues?.endDate ?? timePeriods?.[defaultPeriod]?.value?.endDate,
  });

  useEffect(() => {
    setState({
      selectedPeriod: getSelectedPeriodUsingDates<T>(
        defaultPeriod,
        skipFindingExistingPeriod,
        timePeriods,
        memoizedValues
      ),
      startDate: memoizedValues?.startDate ?? timePeriods?.[defaultPeriod]?.value?.startDate,
      endDate: memoizedValues?.endDate ?? timePeriods?.[defaultPeriod]?.value?.endDate,
    });
  }, [defaultPeriod, memoizedValues, timePeriods]);

  return {
    defaultEndDate: timePeriods?.[defaultPeriod]?.value?.endDate,
    defaultPeriod,
    defaultStartDate: timePeriods?.[defaultPeriod]?.value?.startDate,
    setState,
    state,
    timePeriods,
  };
};

export const useTimePeriodPopoverSelector = <T extends object | undefined = undefined>({
  defaultPeriod,
  skipFindingExistingPeriod,
  timePeriods,
  values,
}: UseTimePeriodSelectorProps<T>) => {
  const { state, setState, ...rest } = useTimePeriodSelectorCommon({
    defaultPeriod,
    skipFindingExistingPeriod,
    timePeriods,
    values,
  });

  return {
    ...state,
    ...rest,
    TimePeriodPopoverSelector: (props: TimePeriodPopoverSelectorProps<T>) => (
      <TimePeriodPopoverSelector
        {...props}
        defaultPeriod={rest.defaultPeriod}
        onChangeInternal={setState}
        timePeriods={timePeriods}
        values={state}
      />
    ),
  };
};

export const useTimePeriodDropDownSelector = <T extends object | undefined = undefined>({
  defaultPeriod,
  skipFindingExistingPeriod,
  timePeriods,
  values,
}: UseTimePeriodSelectorProps<T>) => {
  const { state, setState, ...rest } = useTimePeriodSelectorCommon({
    defaultPeriod,
    skipFindingExistingPeriod,
    timePeriods,
    values,
  });

  return {
    ...state,
    ...rest,
    TimePeriodDropDownSelector: (props: TimePeriodSelectorProps<T>) => (
      <TimePeriodDropDownSelector
        {...props}
        defaultPeriod={rest.defaultPeriod}
        onChangeInternal={setState}
        timePeriods={timePeriods}
        values={state}
      />
    ),
  };
};

const styles = {
  popoverFilterButton: css`
    gap: ${theme.spacing(1)};
    max-width: 200px;
    width: fit-content;
  `,

  listItem: css`
    cursor: pointer;
    height: ${theme.spacing(5)};
    padding: ${theme.spacing(0, 3, 0, 6)};
    text-align: left;
    transition: background-color 0.3s ease;

    > span {
      left: ${theme.spacing(2)};
      position: absolute;
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,

  hr: css`
    border: none;
    border-top: 1px solid ${theme.colors.neutral10};
    margin: ${theme.spacing(1, 0, 0)};
  `,

  dateRangeWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    max-width: 352px;
    padding: ${theme.spacing(2)};
  `,

  dateRange: css`
    > div {
      min-width: auto;
    }
  `,

  dropdownSelector: css`
    font-size: ${theme.font.size.medium};
    min-width: 200px;
  `,
};
