import { FC, useState } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { PaymentPlanQueries, getClientSecret, getClientSecretLink } from '@frontend/api-payment-plans';
import { isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Heading,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useAlert,
  useFormField,
} from '@frontend/design-system';
import { STRIPE_RESPONSE_DELAY } from '../constants';
import { useCardACHSelectionContext } from '../context';

interface AddCardFormProps {
  styles?: Interpolation<Theme>;
}

const AddCardFormComponent: FC<AddCardFormProps> = ({ styles }) => {
  const { t } = useTranslation('payments');
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const { closeAddCard, patientId } = useCardACHSelectionContext();
  const nameProps = useFormField({ type: 'text', required: true });

  const [cardReadyToSubmit, setCardReadyToSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: setupLink, isLoading: isSetupLinkLoading } = PaymentPlanQueries.useGetPaymentMethodSetupLink({
    paymentsUrl,
    locationId,
  });
  const { invalidatePaymentMethodsByPerson } = PaymentPlanQueries.usePaymentPlansInvalidation();

  const handleCardChange = (event: StripePaymentElementChangeEvent) => {
    setCardReadyToSubmit(event.complete);
  };

  const saveCardOnFile = async () => {
    if (!stripe || !elements || !setupLink) {
      console.error('Stripe has not loaded yet');
      // Stripe.js has not yet loaded.
      return;
    }

    if (http.baseUrl.includes('api.weaveconnect.com') && isWeaveUser()) {
      alert.error(t('Weave users do not have access to save card.'));
      return;
    }

    setIsLoading(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      alert.error({ message: t('Error submitting details to Stripe. Please review information and try again.') });
    }
    // get the client secret
    let clientSecret: string | undefined = '';

    try {
      const clientSecretLink = await getClientSecretLink(setupLink, {
        data: {
          personId: patientId,
          locationId: locationId,
        },
      });

      const setupResponse = await getClientSecret(clientSecretLink || '', locationId);
      clientSecret = setupResponse?.clientSecret;
      if (clientSecret) {
        const result = await stripe.confirmSetup({
          elements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location.origin}/payments/payment-plans`,
            payment_method_data: {
              billing_details: {
                name: nameProps.value,
              },
            },
          },
        });
        if (result.error) {
          alert.error({
            message: t('Unable to save Card. Please try again. {{errorCode}}', {
              errorCode: result.error.code,
            }),
          });
          setIsLoading(false);
          closeAddCard();
        } else {
          alert.success(t('Card saved successfully!'));
          setTimeout(() => {
            invalidatePaymentMethodsByPerson(patientId, locationId);
            setIsLoading(false);
            closeAddCard();
          }, STRIPE_RESPONSE_DELAY);
        }
      } else {
        throw new Error('Client secret not found');
      }
    } catch (e) {
      alert.error({
        message: t('Error occured while saving Card.'),
      });
      setIsLoading(false);
    }
  };

  return (
    <div className='add-card-form' css={[containerStyles, styles]}>
      <ContentLoader show={isLoading || isSetupLinkLoading} />
      <Heading className='add-card-form__header' level={2}>
        {t('Save Card on File')}
      </Heading>
      <TextField name='cardholderName' label={t('Cardholder Name')} hasNonFloatingLabel {...nameProps} />
      <PaymentElement onChange={handleCardChange} />
      <div
        className='add-card-form__footer'
        css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: theme.spacing(1) }}
      >
        <SecondaryButton onClick={closeAddCard} css={{ width: 'max-content' }}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          disabled={nameProps['aria-invalid'] || !nameProps.value || !cardReadyToSubmit}
          css={{ width: 'max-content' }}
          onClick={saveCardOnFile}
        >
          {t('Save')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export const AddCardForm: FC<AddCardFormProps> = (props) => {
  return (
    <StripeElementsWrapper type={PaymentsFlowType.AddCardOnFile} paymentConfigName='cardOnly' disableACH>
      <AddCardFormComponent {...props} />
    </StripeElementsWrapper>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 3),
});
