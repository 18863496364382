import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useForm, usePopoverDialog } from '@frontend/design-system';
import { DropdownDisplay } from './dropdown-display';
import { PopoverDrawer } from './popover-drawer';
import { MultiLocationSelectorFieldProps } from './types';

// @param accessibleLocations - list of locations that the user has access to
// @param displayedLocations - list of all location that will display in the dropdown

export const MultiLocationSelector = ({
  accessibleLocations,
  displayedLocations,
  dropdownLabel,
  fieldProps,
  hideLabel = false,
  isRequired = true,
  labelType = 'name',
  popoverYOffset = 0,
  readOnly = false,
  trackingIdPrefix,
  width = '350px',
  ...rest
}: {
  accessibleLocations: string[];
  displayedLocations: string[];
  dropdownLabel?: string;
  fieldProps: MultiLocationSelectorFieldProps;
  hideLabel?: boolean;
  isRequired?: boolean;
  labelType?: 'count' | 'name';
  popoverYOffset?: number;
  readOnly?: boolean;
  trackingIdPrefix: string;
  width?: string;
}) => {
  const { t } = useTranslation('messages');

  const { getFieldProps } = useForm({
    fields: {
      search: { type: 'text', value: '' },
      selectAll: { type: 'checkbox', value: false },
    },
  });
  const searchFieldProps = getFieldProps('search');
  const selectAllFieldProps = getFieldProps('selectAll');
  const locationFieldProps = fieldProps.locationIds;

  const { getDialogProps, getTriggerProps, isOpen } = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -1,
      y: popoverYOffset,
    },
  });

  const isTouched = locationFieldProps.touched;

  return (
    <div {...rest}>
      <Text
        data-trackingid={`${trackingIdPrefix}-locations-selector`}
        {...getTriggerProps()}
        {...(hideLabel ? {} : { 'data-before': dropdownLabel ?? t('Assign Locations') })}
        className='multi-location-selector'
        css={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width,
          border: `1px solid ${
            isTouched && !locationFieldProps.value.length && isRequired
              ? theme.colors.critical50
              : theme.colors.neutral30
          }`,
          padding: theme.spacing(1, 2),
          borderRadius: theme.borderRadius.small,
          cursor: 'pointer',
          height: 38,
          ...{
            ...(hideLabel || (!locationFieldProps.active && !locationFieldProps.value.length)
              ? {}
              : {
                  '&:before': {
                    content: 'attr(data-before)',
                    position: 'absolute',
                    top: theme.spacing(-1.25),
                    left: theme.spacing(1),
                    padding: theme.spacing(0, 0.5),
                    background: theme.colors.white,
                    color:
                      isTouched && !locationFieldProps.value.length && isRequired
                        ? theme.colors.critical50
                        : theme.colors.neutral50,
                    fontSize: theme.fontSize(12),
                  },
                }),
          },
        }}
      >
        <DropdownDisplay
          displayedLocations={displayedLocations}
          labelType={labelType}
          selectedLocationIds={fieldProps.locationIds.value}
        />
        <Icon
          name='caret-down-small'
          css={{
            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
            transition: 'transform 0.25s ease-in-out',
          }}
        />
      </Text>
      {isRequired && (
        <Text
          color={isTouched && !locationFieldProps.value.length ? 'error' : 'subdued'}
          size='small'
          css={{ marginTop: theme.spacing(1) }}
        >
          {t('Required')}
        </Text>
      )}
      <PopoverDrawer
        accessibleLocations={accessibleLocations}
        dialogProps={getDialogProps}
        displayedLocations={displayedLocations}
        fieldProps={{
          locationIds: locationFieldProps,
          search: searchFieldProps,
          selectAll: selectAllFieldProps,
        }}
        readOnly={readOnly}
        width={width}
      />
    </div>
  );
};
