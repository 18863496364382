import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { EmojiAction } from './emoji-action';
import { FlyoutMessageMenuActions } from './flyout-message-menu-actions';
import { ReplyAction } from './reply-action';

interface ActionsBarPopoverProps {
  show: boolean;
}

export const ActionsBarPopover = ({ children, show }: PropsWithChildren<ActionsBarPopoverProps>) => {
  if (!show) {
    return null;
  }

  return <div css={styles.popover}>{children}</div>;
};

ActionsBarPopover.Emoji = EmojiAction;
ActionsBarPopover.Reply = ReplyAction;
ActionsBarPopover.FlyoutMenu = FlyoutMessageMenuActions;

const styles = {
  popover: css`
    border-radius: ${theme.borderRadius.small};
    border: 1px solid ${theme.colors.neutral20};
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(0.5)};
    min-width: ${theme.spacing(5)};
    padding: ${theme.spacing(0.5)};
    box-shadow: none;
    position: absolute;
    top: -20px;
    right: 8px;
    background-color: ${theme.colors.white};
  `,
};
