import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { CollectTerminalSwitch } from './collect-terminal/collect-terminal-switch';

export const CollectTerminal = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.collectTerminal}>
      <CollectTerminalSwitch />
    </StepPerformanceWrapper>
  );
};
