import { FC, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonsV3 } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { Photos } from '@frontend/photos';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import type { CommonHTMLLIAttributes } from '@frontend/types';
import { ResponsivePetChips, useGetActivePetsQuery } from '@frontend/vet-components';
import { theme } from '@frontend/theme';
import { NakedButton, Text, styles } from '@frontend/design-system';
import { getPersonV3SecondaryInfoString } from '../../../../utils';
import { TextWithMatches } from './matching-text';
import { convertStringToMatchedWords, getPhoneMatches } from './utils';

type ContactSearchResultProps = {
  person: Person;
  searchValue: string;
  onSelect: MouseEventHandler<HTMLButtonElement>;
  isGlobalSearch?: boolean;
  trackingId?: string;
};

export const ContactSearchResult: FC<CommonHTMLLIAttributes & ContactSearchResultProps> = ({
  person,
  searchValue,
  isGlobalSearch = false,
  onSelect,
  trackingId,
  ...rest
}) => {
  const personName = PersonsV3.PersonHelpers.getFullName(person);
  const nameParts = convertStringToMatchedWords(personName, [searchValue.trim()]);
  const { setPersonId } = useContactPanelShallowStore('setPersonId');
  const phoneNumbers = getPhoneMatches(searchValue, person.contactInfo);
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const isVet = lastUsedVertical === 'VET';
  const { selectedLocationIds, getScopeName } = useAppScopeStore();
  const { getLocationsByDatasource } = DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getScopeName);
  const locations = getLocationsByDatasource(person.sourceId || '', undefined);

  return (
    <li css={isGlobalSearch && { minWidth: 234, flex: 1 }} {...rest}>
      <NakedButton
        trackingId={trackingId}
        css={{
          display: 'flex',
          gap: theme.spacing(2),
          borderRadius: theme.borderRadius.small,
          padding: theme.spacing(2),
          border: `1px solid ${theme.colors.neutral10}`,
          width: '100%',
          height: '100%',
          alignItems: 'left',
          transition: 'background-color 250ms ease-in-out',
          ':hover': {
            backgroundColor: theme.colors.neutral5,
          },
        }}
        onClick={(e) => {
          setPersonId(person.personId, true, locations?.[0]?.locationId ?? selectedLocationIds[0]);
          onSelect(e);
        }}
      >
        <Photos.ContactProfilePhoto
          locationId={locations?.[0]?.locationId ?? selectedLocationIds[0] ?? ''}
          personId={person.personId}
          firstName={person.firstName}
          lastName={person.lastName}
        />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            overflow: 'hidden',
          }}
        >
          <TextWithMatches text={nameParts} />
          {!!phoneNumbers.length && (
            <div
              css={css`
                margin-bottom: ${theme.spacing(0.25)};
              `}
            >
              {phoneNumbers.map((phone) => (
                <Text
                  key={phone.phoneNumber}
                  size='small'
                  weight='bold'
                  textAlign='left'
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: ${theme.spacing(0.25)};
                  `}
                >
                  <Icon name={phone.iconName} size={16} />
                  <span>{phone.phoneNumber}</span>
                </Text>
              ))}
            </div>
          )}
          {isVet ? (
            <VetContactSearchResultContent
              locationId={locations?.[0]?.locationId ?? selectedLocationIds[0] ?? ''}
              person={person}
            />
          ) : (
            <ContactSearchResultContent person={person} />
          )}
        </div>
      </NakedButton>
    </li>
  );
};

const ContactSearchResultContent = ({ person }: { person: Person }) => (
  <Text
    color='light'
    size='small'
    textAlign='left'
    css={[
      css`
        max-width: 100%;
      `,
      styles.truncate,
    ]}
  >
    {getPersonV3SecondaryInfoString(person)}
  </Text>
);

const VetContactSearchResultContent = ({ person, locationId }: { person: Person; locationId: string }) => {
  const petQuery = useGetActivePetsQuery({ locationId, personId: person.personId || '' });
  return <ResponsivePetChips pets={petQuery.data || []} maxPets={1} />;
};
