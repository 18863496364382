import { useQueryClient, useMutation } from 'react-query';
import PhoneOverrideApi from '@frontend/api-overrides-v2';
import { queryKeys } from '../../query-keys';
import { OverrideCreatePayload, OverrideUpdatePayload } from './types';

export const useOverrideMutations = (phoneTenantId: string) => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (payload: OverrideCreatePayload) => {
      return PhoneOverrideApi.Create(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listOverrides()]);
    },
  });

  const update = useMutation({
    mutationFn: (payload: OverrideUpdatePayload) => {
      return PhoneOverrideApi.Update(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listOverrides()]);
    },
  });

  const remove = useMutation({
    mutationFn: (payload: { phoneOverrideId: string }) => {
      return PhoneOverrideApi.Delete(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listOverrides()]);
    },
  });

  return {
    create,
    update,
    remove,
  };
};
