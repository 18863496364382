import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { BulkMessagingQueries } from '@frontend/api-bulk-messaging';
import { DashboardWidget, DashboardWidgetFC, FirstWordBoldText, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, numeric } from '@frontend/design-system';
import { SingleBarChart } from './single-bar-chart';
import { SingleDoughnutChart } from './single-doughnut-chart';

/**
 * @dashboard-widget
 *
 * id: bulk-texting-widget
 * title: Bulk Texting
 * description: Monitor your text allotment with a real-time progress bar and remaining message count display.
 * icon: cell-phone-small
 */
export const BulkTextingWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('bulk-messaging');
  const navigate = useNavigate();
  const { selectedLocationIds } = useAppScopeStore();
  const { currentSize } = useDashboardWidget();

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const { isLoading, messageUsageInfoMap } = BulkMessagingQueries.useGetMultiBulkMessageUsageInfo(
    selectedLocationIds,
    year,
    month
  );
  const { totalCount, sentAndFailedCount, scheduledCount } = useMemo(() => {
    let totalCount = 0,
      sentAndFailedCount = 0,
      scheduledCount = 0;
    for (const { allotment, sentAndFailed, scheduled } of messageUsageInfoMap.values()) {
      totalCount += allotment;
      sentAndFailedCount += sentAndFailed;
      scheduledCount += scheduled;
    }
    return { totalCount, sentAndFailedCount, scheduledCount };
  }, [messageUsageInfoMap]);
  const remaining = totalCount - sentAndFailedCount - scheduledCount;

  const chartClickHandler = () => {
    navigate({ to: '/bulk/all-campaigns/text' });
  };

  const isTinySlimSize = currentSize === 'tiny-slim';
  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content css={contentContainerStyle}>
        <ContentLoader show={isLoading} />
        {isTinySlimSize ? (
          <SingleDoughnutChart
            remainingCount={remaining}
            scheduledCount={scheduledCount}
            sentCount={sentAndFailedCount}
            onClick={chartClickHandler}
          />
        ) : (
          <SingleBarChart
            remainingCount={remaining}
            scheduledCount={scheduledCount}
            sentCount={sentAndFailedCount}
            onClick={chartClickHandler}
          />
        )}
        <FirstWordBoldText
          css={{ strong: { fontSize: theme.fontSize(24), lineHeight: 1, color: theme.colors.neutral90 } }}
          color='light'
          size='medium'
        >
          {t('{{remainingCount}} Remaining', { remainingCount: numeric(remaining, { decimals: 0 }) })}
        </FirstWordBoldText>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

BulkTextingWidget.config = {
  size: {
    extraSmall: 'tiny-slim',
    small: 'small-narrow',
    medium: 'small-wide',
    large: 'small-wide',
  },
  feature: 'messaging',
};

const contentContainerStyle = css({
  height: '100%',
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
});
