import { PetDetails as PetDetailsType, PetVaccine } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { format } from 'date-fns';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { getTransformedPetServices } from '@frontend/contact-actions-service-report';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { PetEmptyState, useGetActivePetQuery } from '@frontend/vet-components';
import { theme } from '@frontend/theme';
import { Text, NakedUl, SpinningLoader } from '@frontend/design-system';

export const PetDetails = ({ petExtended, sourceId }: { petExtended: PetDetailsType; sourceId: string }) => {
  const { t } = useTranslation('contacts');
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource } = DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(sourceId || '', undefined);
  const petId = petExtended.petId;
  const locationId = locations?.[0]?.locationId || selectedLocationIds[0];
  const petDetailsQuery = useGetActivePetQuery({ petId, locationId });
  const { upcomingServices, overdueServices } = getTransformedPetServices(petDetailsQuery?.data);

  return (
    <section>
      {petDetailsQuery.isLoading ? (
        <SpinningLoader />
      ) : (
        <>
          {overdueServices?.length === 0 && upcomingServices?.length === 0 ? (
            <PetEmptyState imageType={petExtended.validatedImageType} />
          ) : null}
        </>
      )}
      <Services services={overdueServices} title={t('Overdue Services')} isOverdue />
      <Services services={upcomingServices} title={t('Upcoming Services')} />
    </section>
  );
};

export const Services = ({
  services = [],
  isOverdue = false,
  title,
}: {
  services: PetVaccine[];
  title?: React.ReactNode;
  isOverdue?: boolean;
}) => {
  const { t } = useTranslation('contacts');

  return services.length > 0 ? (
    <>
      <Text color='light' size='medium' css={{ marginBottom: theme.spacing(1) }}>
        {title}
      </Text>
      <NakedUl>
        {services.map(({ description, eventTime, eventId }) => (
          <li key={eventId} css={{ marginBottom: theme.spacing(1), display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text size='medium' weight='bold' css={{ paddingRight: theme.spacing(2) }}>
                {description}
              </Text>
              <Text size='small' color={isOverdue ? 'error' : 'light'}>
                {isOverdue ? t('Was due ') : null} {format(new Date(eventTime), 'MMM dd, yyyy')}
              </Text>
            </div>
          </li>
        ))}
      </NakedUl>
    </>
  ) : null;
};
