import { useEffect, useMemo } from 'react';
import { CallIntelQueries } from '@frontend/api-call-intel';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useAlert } from '@frontend/design-system';
import { callIntelligenceUtils, formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { OverviewSummaryOpportunitiesStack } from '../charts';
import { CustomTooltipTitle } from '../custom-tooltip-title';
import { CallIntelMockData } from '../demo-data';
import { CallIntelSubViewId, useCallIntelDemoFlags, useCallIntelShallowStore } from '../hooks';
import { SubViewOpportunitiesInlineStats } from './sub-view-opportunities-inline-stats';

type Props = {
  metricsFor?: string;
  onChangeLoadingState?: (isLoading: boolean) => void;
  sentimentCode: CallIntelSubViewId;
};

const formatChartData = <T extends Record<string, any>>(data?: T | null) => {
  return {
    groups: Object.entries(data || {}).map(([name, value]) => ({
      name,
      values: {
        [name]: value,
      },
    })),
  };
};

export const OpportunitiesBySentimentsSubView = ({ metricsFor, onChangeLoadingState, sentimentCode }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { isDemoAccount, showDemoChipAndBanner } = useCallIntelDemoFlags();
  const { chartLabelColors, dataColors, dataLabels, filterHintText, filters, subView } = useCallIntelShallowStore(
    'chartLabelColors',
    'dataColors',
    'dataLabels',
    'filterHintText',
    'filters',
    'subView'
  );

  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const drillDownFilters = callIntelligenceUtils.getDrillDownFilterKeys(subView);

  const filteredOverviewSentimentsDrillDown = useMemo(() => {
    return CallIntelMockData.getFilteredOverviewSentimentsDrillDown(
      CallIntelMockData.overviewSentimentsDrillDown,
      filters,
      lastUsedVertical
    );
  }, [filters, lastUsedVertical]);

  const { data: aggregatedData, isFetching: isLoadingAggregatedData } = CallIntelQueries.useGetOverviewDetails({
    demoData: filteredOverviewSentimentsDrillDown,
    isDemoAccount,
    payload: { drillDownOptions: drillDownFilters, filters },
    realQueryOptions: {
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        alert.error(t('Failed to fetch overview data'));
        console.error(err);
      },
    },
  });

  const { data: stats, isFetching: isLoadingStats } = CallIntelQueries.useGetOverviewStats({
    demoData: null,
    isDemoAccount,
    payload: {
      drillDownOptions: drillDownFilters,
      filters,
      includes: { opportunities: true },
    },
    realQueryOptions: {
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        alert.error(t('Failed to fetch overview current stats'));
        console.error(err);
      },
    },
    demoQueryOptions: {
      enabled: false,
    },
  });

  const chartData = useMemo(() => {
    return {
      appointmentTypes: formatChartData(aggregatedData?.appointmentTypes),
      category: formatChartData(aggregatedData?.categories),
    };
  }, [aggregatedData]);

  useEffect(() => {
    onChangeLoadingState?.(isLoadingAggregatedData);
  }, [isLoadingAggregatedData]);

  return (
    <div>
      <SubViewOpportunitiesInlineStats data={stats} isLoading={isLoadingStats} metricsFor={metricsFor} />
      <OverviewSummaryOpportunitiesStack data={stats} isLoading={isLoadingAggregatedData} />
      <Chart.HorizontalContainer marginBottom={0}>
        <Chart colors={dataColors.categories || {}} commonTooltipLabel={t('Calls')} labels={dataLabels.categories}>
          <Chart.Header
            leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
            subtitle={filterHintText}
            title={t('{{name}} Call Categories', { name: dataLabels.sentiments?.[sentimentCode] })}
          />
          <Chart.BarChart
            appearance={{
              customTooltipTitle: ({ groupName }) => {
                return <CustomTooltipTitle addPrefix label={dataLabels.categories?.[groupName]} />;
              },
              customXAxisTick: ({ labels, groupName, ...rest }) => {
                return (
                  <XAxisLabelValueTick
                    {...rest}
                    color={chartLabelColors.categories?.[groupName]}
                    label={labels?.[groupName] || groupName}
                    value={formatters.value.format(
                      aggregatedData?.categories?.[groupName as keyof typeof aggregatedData.categories]
                    )}
                  />
                );
              },
              margin: { bottom: 20 },
              showXAxis: true,
            }}
            data={chartData.category}
            formatValue={formatters.value.format}
          />
        </Chart>

        <Chart
          colors={dataColors.appointmentTypes || {}}
          commonTooltipLabel={t('Calls')}
          labels={dataLabels.appointmentTypes}
        >
          <Chart.Header
            leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
            subtitle={filterHintText}
            title={t('{{name}} Call Appointment Types', { name: dataLabels.sentiments?.[sentimentCode] })}
          />
          <Chart.BarChart
            appearance={{
              customTooltipTitle: ({ groupName }) => {
                return <CustomTooltipTitle addPrefix label={dataLabels.appointmentTypes?.[groupName]} />;
              },
              customXAxisTick: ({ labels, groupName, ...rest }) => {
                return (
                  <XAxisLabelValueTick
                    {...rest}
                    color={chartLabelColors.appointmentTypes?.[groupName]}
                    label={labels?.[groupName] || groupName}
                    value={formatters.value.format(
                      aggregatedData?.appointmentTypes?.[groupName as keyof typeof aggregatedData.appointmentTypes]
                    )}
                  />
                );
              },
              margin: { bottom: 20 },
              showXAxis: true,
            }}
            data={chartData.appointmentTypes}
            formatValue={formatters.value.format}
          />
        </Chart>
      </Chart.HorizontalContainer>
    </div>
  );
};
