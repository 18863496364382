import { useQuery, useQueries } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaMessagingSettingsV1Service } from '../service';
import { ListIO } from '../types';

export const getListQueryKey = (req?: ListIO['input']) => {
  const baseRequest = ['MessagingSettingsV1', 'List'] as const;
  if (req) {
    return [...baseRequest, req] as const;
  }
  return baseRequest;
};
type QueryKey = ReturnType<typeof getListQueryKey>;

/**
 * A hook that returns a query for the `List` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useListQuery = <E = unknown, D = ListIO['output']>(
  req: ListIO['input'],
  options?: LimitedSchemaQueryOptions<ListIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<ListIO['output'], E, D, QueryKey>({
    queryKey: getListQueryKey(req),
    queryFn: () => SchemaMessagingSettingsV1Service.List(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `List` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useListQueries = <E = unknown, D = ListIO['output']>(
  argsArr: {
    req: ListIO['input'];
    options?: LimitedSchemaQueryOptions<ListIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getListQueryKey(req),
      queryFn: () => SchemaMessagingSettingsV1Service.List(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useListQuery<E, D>>[];
