import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  DynamicFieldKey_Enum,
  TemplateType_Slug,
  Template,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { QuickFillApi } from '@frontend/api-quick-fill';
import { ScheduleQueries } from '@frontend/api-schedule';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useTranslation } from '@frontend/i18n';
import { TemplateSelector, useGetDefaultTemplate } from '@frontend/integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  Button,
  MessageTemplate,
  Modal,
  Stepper,
  TagType,
  Text,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { QUICK_FILL_TRACKING_IDS } from '../../../tracking-ids';
import { SpinnerLoader } from '../components/spinning-loader';
import { useQuickFillTrayContext } from '../context';
import { useQuickFillRecipientShallowStore } from '../store';
import { defaultQuickFillTemplate, getTimeIn12HrFormat } from './utils';

type Props = {
  locationId: string;
  date: string;
  timeRange: string[];
};

const trackingIds = QUICK_FILL_TRACKING_IDS.sendMessagesTab.composeMessageStep;

export const ComposeMessage = ({ locationId, date, timeRange }: Props) => {
  const alert = useAlert();
  const { selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('schedule-quick-fill');
  const { closeModal } = useQuickFillTrayContext();

  const getDefaultTemplate = useGetDefaultTemplate({ groupIds: selectedLocationIds });

  const defaultTemplate = getDefaultTemplate({ type: TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL });

  const [template, setTemplate] = useState<string>(defaultTemplate?.templateString || defaultQuickFillTemplate);

  const [selectedTemplate, setSelectedTemplate] = useState<Template>();

  const { selectedIds } = useQuickFillRecipientShallowStore('selectedIds');

  const messageTemplatesModalControls = useModalControl();

  const { mutateAsync: sendMessage, isLoading: isSending } = ScheduleQueries.useSendQuickFillMessage();

  const { data: manualTemplateData, refetch: refetchManualTemplates } = useQuery({
    queryKey: ['scheduleQuickFillListTemplate', locationId],
    queryFn: () => QuickFillApi.getDefaultTemplate(locationId),
    enabled: !!locationId,
  });

  const templateTypeQuery = TemplatorV2Queries.useTemplateTypesList(
    {
      businessGroupIds: selectedLocationIds,
      templateTypeSlugs: [TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL],
    },
    {
      select: (data) =>
        data.templateTypes
          .find((template) => template.slug === TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL)
          ?.dynamicFields.reduce<TagType[]>((acc, dynamicField) => {
            const { label, key, exampleValues } = dynamicField;
            if (label && key) {
              acc.push({
                label,
                key: `{{${key}}}`,
                value: exampleValues?.[0] ?? '',
                optional: !dynamicField.isRequired,
                nonRemovable: !!dynamicField.isRequired,
              });
            }
            return acc;
          }, []) ?? [],
      enabled: !!selectedLocationIds.length,
    }
  );

  const templateTags = templateTypeQuery.data ?? [];

  const isLoadingTemplateData = templateTypeQuery.isLoading;

  const formattedDateTimeObj = useMemo(() => {
    const formattedDate = dayjs(date).format('dddd MMMM D YYYY');
    const formattedTimeRange = `${getTimeIn12HrFormat(formattedDate, timeRange[0])} - ${getTimeIn12HrFormat(
      formattedDate,
      timeRange[1]
    )}`;
    const formattedDateAndTime = `${formattedDate}, ${formattedTimeRange}`;

    return {
      date: formattedDate,
      timeRange: formattedTimeRange,
      dateAndTime: formattedDateAndTime,
    };
  }, [date, timeRange]);

  const sendQuickFillMessage = async () => {
    const startTime = dayjs(`${formattedDateTimeObj.date} ${timeRange[0]}`).toISOString();
    const endTime = dayjs(`${formattedDateTimeObj.date} ${timeRange[1]}`).toISOString();

    sendMessage({
      locationId,
      personIds: selectedIds,
      startTime,
      endTime,
    })
      .then(() => {
        alert.success(t('Quick Fill message sent successfully'));
        closeModal();
      })
      .catch(() => {
        alert.error(t('Failed to send Quick Fill message'));
      });
  };

  const handleSubmit = async () => {
    if (!(selectedIds.length && locationId)) return;

    const formattedTemplate = formatTemplateForUpdateOnly(template);

    const isUpdateTemplate = manualTemplateData?.Template !== formattedTemplate;

    const updateTemplatePromise = isUpdateTemplate ? QuickFillApi.updateTemplate(formattedTemplate) : Promise.resolve();

    const sendMessagePromise = updateTemplatePromise.then(() => sendQuickFillMessage());

    try {
      await Promise.all([updateTemplatePromise, sendMessagePromise]);

      if (isUpdateTemplate) {
        refetchManualTemplates();
      }
    } catch (error) {
      sentry.error({ topic: 'scheduling', error });
    }
  };

  const formatTemplateForUpdateOnly = (template: string) => {
    // Handle specific dynamic field key mappings to return the correct key content and format for the quick fill api - single braces, lowercase, and different text.
    return template.replaceAll(/\{([^{}]+)\}/g, (_group, content) => {
      const upperCaseContent = content.toUpperCase();
      if (upperCaseContent === DynamicFieldKey_Enum.BUSINESS_GROUP_NAME) {
        return 'practice_name';
      }
      if (upperCaseContent === DynamicFieldKey_Enum.DATETIME_FORMAL) {
        return 'date_time';
      }
      if (upperCaseContent === DynamicFieldKey_Enum.BUSINESS_GROUP_PHONE) {
        return 'practice_phone';
      }
      return content.toLowerCase();
    });
  };

  const messageTemplateModalTitle = t('Select a Quick Fill Template');

  if (isLoadingTemplateData) return <SpinnerLoader />;

  return (
    <>
      <MessageTemplate
        draggableTags
        tags={templateTags ?? []}
        initialTemplate={
          selectedTemplate?.templateString || defaultTemplate?.templateString || defaultQuickFillTemplate
        }
        onChange={(template) => setTemplate(template)}
        isNewTag
      >
        <div css={containerStyle}>
          <MessageTemplate.Editor />
          <Text size='small' color='subdued'>
            {t('Click a tag below to add it to your message')}
          </Text>
          <MessageTemplate.TagList />
        </div>
      </MessageTemplate>
      <Stepper.ButtonBar
        helperText={template.length === 0 ? t('Compose a message') : ''}
        css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: theme.spacing(2) }}
      >
        <Button
          iconName='template'
          variant='secondary'
          size='large'
          css={{ border: 'none', padding: theme.spacing(1, 0.5) }}
          {...messageTemplatesModalControls.triggerProps}
          trackingId={trackingIds.openTemplateSelectionButton}
        ></Button>
        <Stepper.NextButton
          trackingId={trackingIds.sendButton}
          isValid={template.length > 0 && !isSending}
          onSubmit={handleSubmit}
        >
          {t('Send')}
        </Stepper.NextButton>
      </Stepper.ButtonBar>
      <Modal
        {...messageTemplatesModalControls.modalProps}
        onClose={messageTemplatesModalControls.closeModal}
        maxWidth={600}
      >
        <Modal.Header title={messageTemplateModalTitle} onClose={messageTemplatesModalControls.closeModal}>
          {messageTemplateModalTitle}
        </Modal.Header>
        <Modal.Body>
          <TemplateSelector
            groupIds={selectedLocationIds}
            templateTypes={[TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL]}
            onSelectTemplate={(template) => {
              setTemplate(template.templateString ?? '');
              setSelectedTemplate(template);
              messageTemplatesModalControls.closeModal();
            }}
            onOpenSettings={messageTemplatesModalControls.closeModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;
