import { useMemo } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { PersonsV3 } from '@frontend/api-person';

export const useInvoicePerson = (invoice?: InvoiceModel) => {
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;

  const { data: person } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: personId ?? '',
      locationIds: [locationId || ''],
    },
    {
      enabled: !!personId,
    }
  );

  const personEmail = useMemo(() => (!!person ? PersonsV3.PersonHelpers.getEmailContactType(person) : ''), [person]);

  const personFullName = useMemo(() => (!!person ? PersonsV3.PersonHelpers.getFullName(person) : ''), [person]);

  const personAddress = useMemo(
    () =>
      !!person
        ? {
            line1: person?.address?.street,
            city: person?.address?.city,
            state: person?.address?.state,
            postal_code: person?.address?.postalCode,
            country: 'US',
          }
        : {
            line1: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
          },
    [person]
  );

  return { person, personEmail, personFullName, personAddress };
};
