import { SpinningLoader, styles } from '@frontend/design-system';

type Props = {
  height?: number | string;
};

export const SpinnerLoader = ({ height = 300 }: Props) => {
  return (
    <div css={[{ height }, styles.flexCenter]}>
      <SpinningLoader />
    </div>
  );
};
