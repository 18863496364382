import { createContext, useContext, useMemo, useState } from 'react';
import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { OverrideApi } from '@frontend/api-overrides';
import PhoneOverrideApi from '@frontend/api-overrides-v2';
import { PhoneMediaApi } from '@frontend/api-phone-media';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { scopedFilterAndSort } from '@frontend/filters';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore, useOnScopeChange, WeaveLocation } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Chip,
  useModalControl,
  SpinningLoader,
  SearchField,
  useFormField,
  Text,
  TextLink,
  Truncated,
  ModalControlResponse,
} from '@frontend/design-system';
import { PhoneSettingsScopedLocationFilterMenu } from '../../components/common/index-table';
import { EmptyOverrideSection } from '../../components/override/override-setting';
import { NewOverrideSettingTray } from '../../components/override/override-settings-modal.new';
import { NewOverrideSettingRemoveModal } from '../../components/override/override-settings-remove-modal.new';
import { OverrideSettingContent } from '../../components/override/override-settings.new';
import { PhoneOverrideType } from '../../components/override/types';
import { PhoneSettingsPage } from '../../components/settings/settings-page';
import useVoicemailBoxes from '../../components/voicemail-box/new/use-voicemail-boxes';
import { queryKeys } from '../../query-keys';
import { usePhoneLocationFilterShallowStore, usePhoneSettingsShallowStore } from '../../store/settings';
import { locationSectionStyle } from './overrides-styles';

type OverrideData = {
  override?: PhoneOverride;
  department?: DepartmentsTypes.DepartmentModel;
  locationId?: string;
};
type OverrideHandlerContextType = {
  openSettingTray: () => void;
  openRemoveModal: () => void;
  setOverrideData: (overrideData: OverrideData | null) => void;
};
const OverrideHandlerContext = createContext<OverrideHandlerContextType>({
  openSettingTray: () => {},
  openRemoveModal: () => {},
  setOverrideData: () => {},
});

export const OverrideSettings = () => {
  const { t } = useTranslation('phone');
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const [overrideData, setOverrideData] = useState<OverrideData | null>(null);
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const { aggregateValue: hasCallRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: selectedLocationIds ?? [],
  });
  const phoneTenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const locations = selectedLocationIds?.map((id) => accessibleLocationData[id]).filter(Boolean) ?? [];
  const locationIds = selectedLocationIds ?? [];

  const { data: overrides = [] } = useQuery({
    queryKey: [locationIds, ...queryKeys.overrideList()],
    queryFn: async () => {
      const listOverridesResponse = await OverrideApi.listOverride(
        { locationIds: locationIds },
        { locationId: locationIds[0] }
      );
      return listOverridesResponse.phoneOverrides ?? [];
    },
    enabled: !hasCallRoutesFlag,
  });

  const { data } = useQuery({
    queryKey: [phoneTenantId, ...queryKeys.settings.listOverrides()],
    queryFn: async () => PhoneOverrideApi.List({ tenantId: phoneTenantId }),
    enabled: hasCallRoutesFlag,
  });
  const overridesV2 = data?.phoneOverrides ?? [];

  const removeOverride = useModalControl();
  const sideModal = useModalControl();
  const selectedOverrides = hasCallRoutesFlag ? overridesV2 : overrides;

  return (
    <PhoneSettingsPage
      title={t('Overrides')}
      subtitle={t(
        'Overrides enable you to quickly and easily reroute your call configuration to secure phone coverage during breaks and holidays.'
      )}
      breadcrumbs={[
        { label: t('Phone'), to: '/phone/main' },
        { label: t('Overrides'), to: '/phone/overrides' },
      ]}
      renderContainer={false}
      maxWidth={700}
    >
      <OverrideHandlerContext.Provider
        value={{ openSettingTray: sideModal.openModal, openRemoveModal: removeOverride.openModal, setOverrideData }}
      >
        {settingsTenantLocation?.locationType === 'unify' && settingsTenantLocation?.children?.length ? (
          <MultiLocationView
            hasCallRoutesFlag={hasCallRoutesFlag}
            locations={locations}
            overrides={selectedOverrides}
          />
        ) : (
          <SingleLocationScopedOverrides
            hasCallRoutesFlag={hasCallRoutesFlag}
            locationId={locationIds[0]}
            overrides={overrides}
            overridesV2={overridesV2 ?? []}
          />
        )}
      </OverrideHandlerContext.Provider>

      <NewOverrideSettingTray
        override={overrideData?.override}
        modalControls={sideModal}
        key={overrideData?.override?.id}
        locationId={overrideData?.locationId}
        departmentId={overrideData?.department?.id}
        departmentName={overrideData?.department?.name}
        overridesData={data}
      />

      {overrideData?.override && (
        <NewOverrideSettingRemoveModal
          override={overrideData.override}
          department={overrideData.department}
          modalProps={removeOverride.modalProps}
          phoneTenantId={phoneTenantId}
        />
      )}
    </PhoneSettingsPage>
  );
};

const MultiLocationView = ({
  hasCallRoutesFlag,
  locations,
  overrides,
}: {
  hasCallRoutesFlag?: boolean;
  locations: WeaveLocation[];
  overrides: PhoneOverride[] | PhoneOverrideType[];
}) => {
  const { t } = useTranslation('phone');
  const { settingsTenantLocation, globalAvailableLocationIds } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'globalAvailableLocationIds'
  );
  const { locationFilters, setLocationFilters: setSelectedLocations } = usePhoneLocationFilterShallowStore(
    'locationFilters',
    'setLocationFilters'
  );

  const selectedLocations = locationFilters.overridesIndex;
  const searchField = useFormField({ type: 'text' });

  useOnScopeChange(() => {
    setSelectedLocations({ overridesIndex: [] });
  });

  const { data: voicemailBoxes } = useVoicemailBoxes({
    settingsTenantLocation,
  });

  const customMedia = useQuery({
    queryKey: [settingsTenantLocation?.locationId, ...queryKeys.phoneMedia()],
    queryFn: async () => {
      const locationIds = globalAvailableLocationIds ?? [];
      if (locationIds.length === 0) return [];

      const responses = await Promise.all(locationIds.map((id) => PhoneMediaApi.list({ locationId: id })));

      return responses.flat();
    },
  });

  const normalize = (value: string) => value?.toLowerCase() || ''; // Normalize string for comparison

  const filteredMailboxIds = useMemo(() => {
    const searchValue = normalize(searchField.value);

    if (!searchValue) return [];

    const matchedMailboxes = voicemailBoxes?.filter((box) => normalize(box.mailbox?.name).includes(searchValue)) || [];

    return matchedMailboxes.map((box) => box.mailbox?.id).filter(Boolean);
  }, [searchField.value, voicemailBoxes]);

  const filteredMediaIds = useMemo(() => {
    const searchValue = normalize(searchField.value);

    if (!searchValue) return [];

    return (
      customMedia.data
        ?.filter((media) => normalize(media.FileName).includes(searchValue))
        .map((media) => media.MediaID)
        .filter(Boolean) || []
    );
  }, [searchField.value, customMedia]);

  const filteredOverrides = useMemo(() => {
    const searchValue = normalize(searchField.value);
    return (overrides as PhoneOverrideType[])
      .filter((override) => {
        const matchesCallRouteName = normalize(override.callRouteName).includes(searchValue);
        const matchesMailboxId = filteredMailboxIds.includes(override.voicemailBoxId);
        const matchesMediaItemId = filteredMediaIds.includes(override.mediaItemId);

        return matchesCallRouteName || matchesMailboxId || matchesMediaItemId;
      })
      .sort((a, b) => normalize(a.callRouteName).localeCompare(normalize(b.callRouteName)));
  }, [overrides, searchField.value, filteredMailboxIds, filteredMediaIds]);

  const filteredLocations = useMemo(() => {
    const baseData = hasCallRoutesFlag
      ? locations.filter((location) => filteredOverrides.some((override) => override.locationId === location.id))
      : locations;

    return scopedFilterAndSort({
      data: baseData,
      filteredScopeIds: selectedLocations,
      scopeIds: globalAvailableLocationIds,
      scopeIdAccessor: (location) => [location.id],
    }).filter((location) => normalize(location.name ?? '').includes(normalize(searchField.value)));
  }, [hasCallRoutesFlag, locations, selectedLocations, searchField.value]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      {locations.length > 1 && (
        <div css={locationSectionStyle}>
          <PhoneSettingsScopedLocationFilterMenu
            selectedOptions={selectedLocations}
            onChange={(locations) => {
              setSelectedLocations({
                overridesIndex: locations,
              });
            }}
          />
          <SearchField
            {...searchField}
            {...(hasCallRoutesFlag ? {} : { label: t('Search Locations') })}
            {...(hasCallRoutesFlag ? { name: 'route-search' } : { name: 'location-search' })}
            css={{ background: theme.colors.white }}
          />
        </div>
      )}
      <MultiLocationScopedOverrides
        locations={filteredLocations}
        overrides={filteredOverrides}
        hasCallRoutesFlag={hasCallRoutesFlag}
      />
    </div>
  );
};

const MultiLocationScopedOverrides = ({
  locations,
  overrides,
  hasCallRoutesFlag,
}: {
  locations: WeaveLocation[];
  overrides: PhoneOverride[] | PhoneOverrideType[];
  hasCallRoutesFlag?: boolean;
}) => {
  const locationScopedOverrides = overrides
    ? locations.reduce((acc, location) => {
        const locationOverride = overrides?.find((override) => override.locationId === location.id);
        if (locationOverride) {
          return {
            ...acc,
            [location.id]: locationOverride,
          };
        }

        return acc;
      }, {} as Record<string, PhoneOverride | PhoneOverrideType>)
    : {};

  const startingValue = locations.map((location) => location.id);
  const accordionKey = startingValue.join('-');

  return (
    <Accordion variant='location' isMulti key={accordionKey} startingValue={[startingValue[0]]}>
      {locations.map((location) => {
        return (
          <MultiLocationOverrideContainer
            hasCallRoutesFlag={hasCallRoutesFlag}
            key={location.id}
            location={location}
            override={locationScopedOverrides[location.id]}
            overrides={overrides as PhoneOverrideType[]}
          />
        );
      })}
    </Accordion>
  );
};

const SingleLocationScopedOverrides = ({
  hasCallRoutesFlag,
  locationId,
  overrides,
  overridesV2,
}: {
  hasCallRoutesFlag?: boolean;
  locationId: string;
  overrides: PhoneOverride[];
  overridesV2: PhoneOverrideType[];
}) => {
  const { data: departments, isLoading } = useQuery({
    queryKey: [locationId, ...queryKeys.listDepartments()],
    queryFn: () => DepartmentsApi.listDept({ locationId }),
    select: (data) => data.departments,
  });

  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: [locationId],
  });

  // Even with the departments feature flag off, queries may return mainline departments.
  // Therefore, check the feature flag state to correctly identify non-department locations.
  if ((departments?.length === 0 || !departmentsFlag) && !hasCallRoutesFlag) {
    return <LocationOverrideContainer override={overrides[0]} locationId={locationId} />;
  }

  if (isLoading) {
    return <SpinningLoader />;
  }

  return hasCallRoutesFlag ? (
    <CallRouteOverrideContainer locationId={locationId} overridesV2={overridesV2} isSingleLocation={true} />
  ) : (
    <DepartmentOverrideContainer departments={departments ?? []} locationId={locationId} overrides={overrides} />
  );
};

const LocationOverrideContainer = ({ override, locationId }: { override: PhoneOverride; locationId: string }) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onClickCapture={() => {
        setOverrideData({ override, locationId });
      }}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        background: theme.colors.white,
        boxShadow: theme.shadows.light,
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      {override ? <SettingContent override={override} /> : <EmptyOverrideSetting locationId={locationId} />}
    </section>
  );
};

const DepartmentOverrideContainer = ({
  departments,
  locationId,
  overrides,
}: {
  departments: DepartmentsTypes.DepartmentModel[];
  locationId: string;
  overrides: PhoneOverride[];
}) => {
  const departmentScopedOverrides = overrides
    ? departments?.reduce((acc, dept) => {
        const deptOverride = overrides?.find((override) => override.departmentId === dept.id);
        if (deptOverride) {
          return {
            ...acc,
            [dept.id!]: deptOverride,
          };
        }

        return acc;
      }, {} as Record<string, PhoneOverride>)
    : {};

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      {departments?.map((dept) => (
        <DepartmentOverrideSetting
          key={dept.id}
          locationId={locationId}
          override={departmentScopedOverrides?.[dept.id!]}
          department={dept}
        />
      ))}
    </div>
  );
};

const CallRouteOverrideContainer = ({
  isSingleLocation,
  locationId,
  overridesV2,
}: {
  isSingleLocation?: boolean;
  locationId: string;
  overridesV2: PhoneOverrideType[];
}) => {
  const callRouteScopedOverrides = overridesV2
    ? overridesV2.reduce((acc, override) => {
        const callRouteOverride = overridesV2.find((item) => item.instructionSetId === override.instructionSetId);
        if (callRouteOverride) {
          return {
            ...acc,
            [override.instructionSetId!]: callRouteOverride,
          };
        }

        return acc;
      }, {} as Record<string, PhoneOverrideType>)
    : {};

  const filteredOverrides = isSingleLocation
    ? overridesV2
    : overridesV2?.filter((override) => override.locationId === locationId);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      {filteredOverrides?.map((override) => (
        <CallRouteOverrideSetting
          key={override.callRouteId}
          locationId={locationId}
          override={callRouteScopedOverrides?.[override.instructionSetId!]}
        />
      ))}
    </div>
  );
};

const MultiLocationOverrideContainer = ({
  overrides,
  location,
  override,
  hasCallRoutesFlag,
}: {
  overrides: PhoneOverrideType[];
  location: WeaveLocation;
  override: PhoneOverride | PhoneOverrideType;
  hasCallRoutesFlag?: boolean;
}) => {
  return (
    <Accordion.Item title={location.name} value={location.id}>
      <Accordion.Header>
        <Accordion.Header.Location title={location.name ?? ''} />
      </Accordion.Header>
      <Accordion.Body css={{ padding: 0 }}>
        {override && !hasCallRoutesFlag ? (
          <MultiLocationOverrideSetting key={override.id} override={override} />
        ) : (
          <section
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              padding: theme.spacing(3),
              ':not(:last-child)': {
                borderBottom: `1px solid ${theme.colors.neutral20}`,
              },
            }}
          >
            {hasCallRoutesFlag ? (
              <CallRouteOverrideContainer locationId={location.id ?? ''} overridesV2={overrides} />
            ) : (
              <EmptyOverrideSetting locationId={location.id} />
            )}
          </section>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export const EmptyOverrideSetting = ({
  department,
  locationId,
  override,
  isSideModal,
  editOverride,
}: {
  department?: DepartmentsTypes.DepartmentModel;
  locationId: string;
  override?: PhoneOverrideType;
  isSideModal?: boolean;
  editOverride?: ModalControlResponse;
}) => {
  const { openSettingTray, setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onClickCapture={() =>
        setOverrideData({
          department,
          locationId,
          override,
        })
      }
    >
      <EmptyOverrideSection openSettingTray={openSettingTray} isSideModal={isSideModal} editOverride={editOverride} />
    </section>
  );
};

const DepartmentOverrideSetting = ({
  department,
  locationId,
  override,
}: {
  department: DepartmentsTypes.DepartmentModel;
  locationId: string;
  override?: PhoneOverride;
}) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onClickCapture={() => setOverrideData({ override, department, locationId })}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        background: theme.colors.white,
        boxShadow: theme.shadows.light,
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      <div style={{ display: 'flex', gap: theme.spacing(1) }}>
        <Chip.Department css={{ maxWidth: '115px' }}>{department.name}</Chip.Department>
      </div>
      {override ? (
        <SettingContent override={override} />
      ) : (
        <EmptyOverrideSetting department={department} locationId={locationId} />
      )}
    </section>
  );
};

const CallRouteOverrideSetting = ({ locationId, override }: { locationId: string; override?: PhoneOverrideType }) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);
  const { t } = useTranslation('phone');
  const { navigate } = useSettingsNavigate();
  const hasPhoneFullAccess = hasSchemaACL(locationId, Permission.PHONE_FULL_ACCESS);
  const hasPhoneSystemAccess = useHasPhoneSystemAccess({ locationIds: [locationId] });
  const hasPhoneAdminAccess = hasPhoneFullAccess && hasPhoneSystemAccess;

  return (
    <section
      onClickCapture={() => setOverrideData({ override, locationId })}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        background: theme.colors.white,
        boxShadow: theme.shadows.light,
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          paddingBottom: theme.spacing(2),
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        }}
      >
        {hasPhoneAdminAccess ? (
          <svg width='16' height='16' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.3045 2.06396C12.3045 1.23554 11.6329 0.563965 10.8045 0.563965C9.97604 0.563965 9.30447 1.23554 9.30447 2.06396V5.82339C9.30447 6.63529 8.64629 7.29346 7.83439 7.29346C5.36564 7.29346 3.36432 9.29478 3.36432 11.7635V16.5372L3.04953 16.2234C2.46276 15.6386 1.51301 15.6402 0.928213 16.227C0.343416 16.8138 0.345019 17.7635 0.931793 18.3483L3.37157 20.7799C4.24963 21.655 5.67011 21.655 6.54818 20.7799L8.98795 18.3483C9.57473 17.7635 9.57633 16.8138 8.99153 16.227C8.40674 15.6402 7.45699 15.6386 6.87022 16.2234L6.36432 16.7276V11.7635C6.36432 10.9516 7.02249 10.2935 7.83439 10.2935C10.3031 10.2935 12.3045 8.29214 12.3045 5.82339V2.06396ZM14.0271 7.23486C16.5195 7.23486 18.5401 9.25542 18.5401 11.7479V16.6324L18.9504 16.2234C19.5372 15.6386 20.4869 15.6402 21.0717 16.227C21.6565 16.8138 21.6549 17.7635 21.0682 18.3483L18.6284 20.7799C17.7503 21.655 16.3298 21.655 15.4518 20.7799L13.012 18.3483C12.4252 17.7635 12.4236 16.8138 13.0084 16.227C13.5932 15.6402 14.543 15.6386 15.1297 16.2234L15.5401 16.6324V11.7479C15.5401 10.9123 14.8627 10.2349 14.0271 10.2349C13.1986 10.2349 12.5271 9.56329 12.5271 8.73486C12.5271 7.90644 13.1986 7.23486 14.0271 7.23486Z'
              fill='#424952'
            />
          </svg>
        ) : (
          <Icon name='forward-small' />
        )}
        <div css={{ display: 'flex', gap: theme.spacing(0.5), alignItems: 'center' }}>
          <Text size='medium' css={{ flexShrink: 0 }}>
            {t('Call Route: ')}
          </Text>
          {hasPhoneAdminAccess ? (
            <TextLink
              onClick={() =>
                navigate({
                  to: '/phone/call-routes/:id',
                  params: { id: override?.callRouteId ?? '' },
                })
              }
              css={{ minWidth: 0 }}
            >
              <Truncated>{override?.callRouteName}</Truncated>
            </TextLink>
          ) : (
            <Truncated>{override?.callRouteName}</Truncated>
          )}
        </div>
      </div>
      {override && override.id ? (
        <SettingContent override={override} />
      ) : (
        <EmptyOverrideSetting locationId={locationId} override={override} />
      )}
    </section>
  );
};

const MultiLocationOverrideSetting = ({ override }: { override: PhoneOverride | PhoneOverrideType }) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onClickCapture={() => setOverrideData({ override, locationId: override.locationId })}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        ':not(:last-child)': {
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        },
      }}
    >
      <SettingContent override={override} />
    </section>
  );
};

export const SettingContent = ({
  override,
  isSideModal,
  editOverride,
  removeOverride,
}: {
  override: PhoneOverride | PhoneOverrideType;
  isSideModal?: boolean;
  editOverride?: ModalControlResponse;
  removeOverride?: ModalControlResponse;
}) => {
  const { openSettingTray, openRemoveModal } = useContext(OverrideHandlerContext);

  return (
    <OverrideSettingContent
      override={override}
      openSettingTray={openSettingTray}
      openRemoveModal={openRemoveModal}
      isSideModal={isSideModal}
      editOverride={editOverride}
      removeOverride={removeOverride}
    />
  );
};
