import { useCallback, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { EmojiClickData } from 'emoji-picker-react';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { useTooltip } from '@frontend/design-system';
import { StatusTooltipContent, toolTipContainerStyle } from '../../../external-components';
import { useActiveConversationSelector } from '../../../providers/active-conversation.provider';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';
import { MessageComposer } from '../../composer';
import { ActionsBarPopover } from './actions-bar-popover';
import { Author } from './author';
import { ActionableMessageBase } from './base';
import { MessageBody } from './message-body';
import { RepliesButton } from './replies-button';

interface MessageRegularProps {
  message: TeamChatTypes.Message;
  isHighlighted?: boolean;
  isThread: boolean;
  onClick?: () => void;
  onDelete: (messageId: string) => void;
  className?: string;
}

export const MessageRegular = ({
  className,
  message,
  isHighlighted,
  onClick,
  onDelete,
  isThread,
}: MessageRegularProps) => {
  const addReaction = useActiveConversationSelector((ctx) => ctx.addReaction);
  const removeReaction = useActiveConversationSelector((ctx) => ctx.removeReaction);
  const conversationId = useActiveConversationSelector((ctx) => ctx.conversation?.channelId);
  const conversationType = useActiveConversationSelector((ctx) => ctx.conversation?.type);

  const openThread = useTeamChatSelector((ctx) => ctx.openThread);
  const users = useTeamChatSelector((ctx) => ctx.users);
  const messageSenderUser = useTeamChatSelector((ctx) => ctx.helpers.getUser(message.userId));

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const { Tooltip, tooltipProps, triggerProps, setOpen } = useTooltip({ hoverDelay: 1000 });

  const messageSenderUsers = useMemo(() => (messageSenderUser ? [messageSenderUser] : []), [messageSenderUser]);
  const name = getUserFullName(messageSenderUsers?.[0]?.firstName, messageSenderUsers?.[0]?.lastName);

  const handleAddEmoji = useCallback(
    (emoji: EmojiClickData) => {
      if (message.reactions?.find(({ name }) => emoji.unified === name)) {
        removeReaction({
          channelId: message.channelId,
          messageId: message.id,
          reaction: emoji.unified,
          // we need parentId here but not on addReaction, because addReaction returns a message payload, and we can just get the parentId from that
          // removeReaction does not return a message payload
          parentMessageId: message.parentId,
        });
      } else {
        addReaction({
          channelId: message.channelId,
          messageId: message.id,
          reaction: emoji.unified,
        });
      }
      close();
    },
    [message.reactions]
  );

  const handleEditMessage = async () => {
    setIsEditing(true);
    close();
  };

  const handleReplyMessage = (message: TeamChatTypes.Message) => {
    openThread(message.id, conversationId || '');
  };

  const threadParticipantUsers = useMemo(() => {
    return message.threadParticipantIds
      .map((userId) => {
        const user = users?.find((user) => user.userID === userId);
        if (!user) {
          return undefined;
        }
        return user;
      })
      .filter((user): user is NonNullable<typeof user> => !!user);
  }, [users, message.threadParticipantIds]);

  return (
    <ActionableMessageBase
      className={className}
      isHighlighted={isHighlighted}
      onClick={onClick}
      isEditing={isEditing}
      actionMenuIsOpen={isOpen}
      onOpenMenuAction={open}
      onCloseMenuAction={close}
    >
      {messageSenderUsers && <ChatAvatar users={messageSenderUsers} size='small' showStatusAsTooltip css={avatarCss} />}
      <div css={{ width: '100%' }}>
        {!isEditing && (
          <Author
            date={message.createdAt}
            name={name || 'Unknown Member'}
            userID={message.userId}
            tooltipTriggerProps={triggerProps}
          />
        )}
        <Tooltip {...tooltipProps} css={toolTipContainerStyle}>
          <StatusTooltipContent
            userId={message.userId}
            close={() => setOpen(false)}
            shouldShowSendDirectMessage={conversationType !== 'DM'}
          />
        </Tooltip>
        {isEditing && (
          <MessageComposer onCancel={() => setIsEditing(false)} type='edit' message={message} css={composerStyle} />
        )}
        {!isEditing && (
          <MessageBody message={message}>
            {message.replyCount > 0 && !isThread && (
              <RepliesButton
                count={message.replyCount}
                users={threadParticipantUsers}
                onClick={() => openThread(message.id, conversationId || '')}
              />
            )}
          </MessageBody>
        )}

        <ActionsBarPopover show={isOpen}>
          {message.type !== 'deleted' && <ActionsBarPopover.Emoji onSelect={handleAddEmoji} />}
          {!isThread && <ActionsBarPopover.Reply onClick={() => handleReplyMessage(message)} />}
          {message.isOwnMessage && message.type !== 'deleted' && (
            <ActionsBarPopover.FlyoutMenu message={message} onDelete={onDelete} onEdit={handleEditMessage} />
          )}
        </ActionsBarPopover>
      </div>
    </ActionableMessageBase>
  );
};

MessageRegular.displayName = 'MessageRegular';

const composerStyle = css({ gridColumn: '2/3', gridRow: '1/3', height: 'maxContent' });
const avatarCss = css({ gridColumn: '1/2', gridRow: '1/2' });
