import { useRef, useLayoutEffect, useState, memo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';

type Props = {
  isSelected: boolean;
  onClick: () => void;
  trackingId: string;
  label: string;
  badgeCount: number;
  Icon: React.ReactNode;
};
export const NavItem = memo(({ isSelected, onClick, trackingId, label, badgeCount, Icon }: Props) => {
  const { Tooltip, tooltipProps, triggerProps, setOpen, isOpen } = useTooltip({ hoverDelay: 300 });
  const chatNameRef = useRef<HTMLElement | null>(null);
  const [offsetWidth, setOffsetWidth] = useState<number>(chatNameRef.current?.offsetWidth ?? 0);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!chatNameRef.current) return;
    // need to trigger a rerender to get the width of the element after it has been rendered
    // otherwise the width will be 0 until a rerender is triggered
    setOffsetWidth(chatNameRef?.current?.offsetWidth);
  }, [chatNameRef?.current?.offsetWidth]);

  function chatNameMeasureRef(node: HTMLSpanElement) {
    if (node === null) return;

    chatNameRef.current = node;
    if (!offsetWidth) return;
    if (offsetWidth < node.scrollWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }

  return (
    <div
      css={[containerStyle, isSelected && selectedContainerStyle]}
      onClick={onClick}
      onMouseOut={() => showTooltip && isOpen && setOpen(false)}
      data-trackingid={trackingId}
      {...(showTooltip && triggerProps)}
    >
      {Icon}
      <Text css={css(chatNameStyle)} ref={chatNameMeasureRef} {...(badgeCount && { weight: 'bold' })}>
        {label}
      </Text>
      {/* Want to avoid unnecessary re-rendering hence adding a check to conditionally render the tooltip */}
      {showTooltip && (
        <Tooltip {...tooltipProps}>
          <Text color='white'>{label}</Text>
        </Tooltip>
      )}
      {!!badgeCount && (
        <Text
          weight='semibold'
          size='medium'
          as='span'
          color='white'
          css={[unreadTextStyle, badgeCount > 9 && paddingForUnread]}
        >
          {badgeCount}
        </Text>
      )}
    </div>
  );
});
NavItem.displayName = 'NavItem';

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  width: '100%',
  background: 'none',
  border: 'none',
  gap: theme.spacing(1),
  cursor: 'pointer',
  marginBottom: '2px',
  transition: 'background-color 0.2s',
  '&:hover': {
    transition: 'background-color 0.2s',
    backgroundColor: theme.colors.neutral5,
    borderRadius: theme.borderRadius.small,

    '.online-indicator': {
      borderColor: `${theme.colors.neutral5}`,
    },
  },
});

const selectedContainerStyle = css({
  backgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.small,
  fontWeight: theme.font.weight.bold,
});

const unreadTextStyle = css({
  lineHeight: 1.6,
  backgroundColor: theme.colors.critical50,
  minWidth: '18px',
  width: 'fit-content',
  height: '18px',
  borderRadius: '9px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontSize: theme.fontSize(12),
  // added to right shift the component in the row
  marginLeft: 'auto',
});

const paddingForUnread = css({
  padding: theme.spacing(0, 0.75),
});

const chatNameStyle = css({ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' });
