import { useQueryClient } from 'react-query';
import { DevicesQueryKey } from '@frontend/api-devices';
import SipProfileAPI, { type SipProfilesApiTypes } from '@frontend/api-sip-profile';
import { useTranslation } from '@frontend/i18n';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Chip, EditSimpleIcon, Table, TextLink, XIcon, useModalControl, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { AssignDeviceToCallGroups } from '../../assign-device-modal/assign-device-to-call-groups';

type Props = {
  sipProfile: SipProfilesApiTypes.SipProfileType;
  locationIds?: string[];
  tenantId?: string;
};

export const DeviceCallGroupAssignments = ({ sipProfile, locationIds, tenantId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const { navigate } = useSettingsNavigate();
  const assignDeviceToCallGroupModal = useModalControl();
  const alerts = useAlert();
  const queryClient = useQueryClient();

  const { name, device, sipProfileId } = sipProfile;

  const { data: { callGroups } = { callGroups: [] }, isLoading } = useQuery({
    queryKey: [tenantId, ...queryKeys.settings.listCallGroupsbySipProfile(sipProfileId)],
    queryFn: () =>
      SipProfileAPI.GetCallGroups({
        sipProfileId,
      }),
  });

  const callGroupIds = callGroups?.map((callgroup) => callgroup.id);

  const { mutateAsync } = useMutation({
    mutationFn: ({ sipProfileId, callGroups }: SipProfilesApiTypes.ReplaceCallGroupsType['input']) =>
      SipProfileAPI.ReplaceCallGroups({
        sipProfileId,
        callGroups,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([tenantId, ...DevicesQueryKey.queryKeys.devicesList()]);
      queryClient.invalidateQueries([tenantId, ...queryKeys.settings.listCallGroupsbySipProfile(sipProfileId ?? '')]);
    },
    onError: () => {
      alerts.error(t('Unable to save edits to Call Group Assignments. Please try again.'));
    },
  });

  const addToCallGroups = (cg: SipProfilesApiTypes.SipProfileCallGroupType) => {
    const addedCallGroups = [...callGroups, ...cg];

    return mutateAsync({
      sipProfileId,
      callGroups: addedCallGroups,
    });
  };

  return (
    <>
      <Table
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No Call Groups'),
          description: t('You have not assigned this device to any call groups yet.'),
          action: {
            label: t('Assign Device to Call Groups'),
            onClick: () => assignDeviceToCallGroupModal.openModal(),
          },
        }}
        colConfig={[
          {
            Header: t('Location'),
            accessor: ({ location }) => location?.name,
            cellRenderer: (name) => (name ? <Chip.SingleChip>{name}</Chip.SingleChip> : ''),
            id: 'location-name',
            sortType: 'caseInsensitive',
            omit: locationIds?.length === 1,
          },
          {
            Header: t('Name'),
            accessor: ({ name }) => name,
            id: 'name',
            sortType: 'caseInsensitive',
          },
        ]}
        data={callGroups || []}
        hasResponsiveColWidths
        isLoading={isLoading}
        rowActions={{
          actions: [
            {
              label: t('Edit Call Group'),
              Icon: EditSimpleIcon,
              onClick: ({ id }) => {
                if (!!id) navigate({ to: '/phone/call-groups/:id', params: { id } });
              },
            },
            {
              label: t('Remove Device from Call Group'),
              Icon: XIcon,
              onClick: (row) => {
                const callGroupFiltered = callGroups?.filter(({ id }) => id !== row.id);
                if (device?.deviceId) {
                  mutateAsync({
                    sipProfileId,
                    callGroups: callGroupFiltered,
                  }).then(() => {
                    alerts.success(t('Successfully removed this device from this call group'));
                  });
                }
              },
            },
          ],
        }}
      />
      {!!callGroups?.length && (
        <TextLink
          css={{
            marginTop: theme.spacing(2),
            ':hover, :focus': {
              textDecoration: 'none',
            },
          }}
          weight='bold'
          color='primary'
          onClick={() => assignDeviceToCallGroupModal.openModal()}
        >
          {t('Assign Device to Call Groups')}
        </TextLink>
      )}
      {assignDeviceToCallGroupModal.modalProps.show && (
        <AssignDeviceToCallGroups
          deviceName={name}
          callGroupIds={callGroupIds}
          locationIds={locationIds}
          onSave={addToCallGroups}
          {...assignDeviceToCallGroupModal.modalProps}
        />
      )}
    </>
  );
};
