import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useSMSDataV3QueryUpdaters } from '../query-updaters';
import { SchemaSMSDataV3Service } from '../service';
import { LookupThreadIdIO } from '../types';

type SelectionData = {
  personPhone: string;
  departmentId?: string;
};

type UseThreadSelectionArgs = {
  groupId: string;
  currentThreadId: string;
  selection: SelectionData;
  onSelectionChange: ({
    threadId,
    newSelection,
    isNewThread,
  }: {
    threadId: string;
    newSelection: SelectionData;
    isNewThread?: boolean;
  }) => void;
  onError: ({
    err,
    selectionType,
    context,
  }: {
    err: unknown;
    selectionType: 'personPhone' | 'department';
    context: object;
  }) => void;
};

export const useThreadSelection = ({
  groupId,
  currentThreadId,
  selection,
  onSelectionChange,
  onError,
}: UseThreadSelectionArgs) => {
  const queryClient = useQueryClient();
  const { getQueryKey, getQueriesData, updateQuery } = useSMSDataV3QueryUpdaters();

  const getThreadId = useCallback(
    async (newSelection: SelectionData, calculateMissing?: boolean) => {
      const request: LookupThreadIdIO['input'] = {
        personPhone: formatPhoneNumberE164(newSelection.personPhone),
        locationId: groupId,
        departmentId: newSelection?.departmentId,
        calculateMissing,
      };
      const lookupThreadIdResponse = await queryClient.fetchQuery(
        getQueryKey({ endpointName: 'LookupThreadId', request }),
        () => SchemaSMSDataV3Service.LookupThreadId(request)
      );

      return lookupThreadIdResponse.threadId;
    },
    [formatPhoneNumberE164, getQueryKey, getQueriesData, SchemaSMSDataV3Service.LookupThreadId, updateQuery]
  );

  const handlePersonPhoneSelection = useCallback(
    async (personPhone: string, calculateIfNewThread?: boolean) => {
      try {
        const existingThreadId = await getThreadId({ personPhone, departmentId: selection.departmentId });
        if (existingThreadId && existingThreadId === currentThreadId) return;
        const newThreadId =
          !existingThreadId && calculateIfNewThread
            ? await getThreadId({ personPhone, departmentId: selection.departmentId }, true)
            : '';
        if (newThreadId && newThreadId === currentThreadId) return;
        onSelectionChange({
          threadId: existingThreadId || newThreadId,
          newSelection: { ...selection, personPhone },
          isNewThread: calculateIfNewThread ? !existingThreadId && !!newThreadId : undefined,
        });
      } catch (err) {
        onError?.({
          err,
          selectionType: 'personPhone',
          context: {
            newPersonPhone: personPhone,
            currentSelection: selection,
          },
        });
      }
    },
    [getThreadId, currentThreadId, JSON.stringify(selection), onError]
  );

  const handleDepartmentSelection = useCallback(
    async (departmentId: string, calculateIfNewThread?: boolean) => {
      try {
        const existingThreadId = await getThreadId({ personPhone: selection.personPhone, departmentId });
        if (existingThreadId && existingThreadId === currentThreadId) return;
        const newThreadId =
          !existingThreadId && calculateIfNewThread
            ? await getThreadId({ personPhone: selection.personPhone, departmentId }, true)
            : '';
        if (newThreadId && newThreadId === currentThreadId) return;
        onSelectionChange({
          threadId: existingThreadId || newThreadId,
          newSelection: { ...selection, departmentId },
          isNewThread: calculateIfNewThread ? !existingThreadId && !!newThreadId : undefined,
        });
      } catch (err) {
        onError?.({
          err,
          selectionType: 'department',
          context: {
            newDepartmentId: departmentId,
            currentSelection: selection,
          },
        });
      }
    },
    [getThreadId, currentThreadId, JSON.stringify(selection), onError]
  );

  return {
    handlePersonPhoneSelection,
    handleDepartmentSelection,
  };
};
