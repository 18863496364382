import { useEffect, useMemo, useState, useRef } from 'react';
import { css } from '@emotion/react';
import { useLocation, useSearch } from '@tanstack/react-location';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { sortBy } from 'lodash-es';
import { AppointmentTypesApi } from '@frontend/api-appointment-types';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ExceptionQueries } from '@frontend/api-schedule-exceptions';
import {
  ServiceProvidersApi,
  ServiceProvidersConstants,
  ServiceProvidersQueries,
} from '@frontend/api-service-providers';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { ContentLoader, useModalControl } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { CalendarLocationSelectorModal } from '../../components/CalendarLocationSelectorModal';
import { AppointmentsCalendarViewContextProvider } from '../../context/AppointmentsCalendarViewContext';
import { CalendarViewRefetchMethodProvider } from '../../context/CalendarViewRefetchMethodContext';
import {
  useGetProvidersListForMultipleLocationIds,
  useGetOfficeHoursForMultipleLocationIds,
  useGetCombinedProvidersList,
  useGetProvidersOfficeHoursList,
  useGetIntegrationDetails,
  useGetLocalStorageLocationIdAndUserId,
} from '../../hooks';
import { useAppointmentsInfoShallowStore } from '../../hooks/use-appointments-info-store';
import { useCalendarHeaderFilterShallowStore } from '../../stores/use-calendar-header-filter-store';
import { useIntegrationDetailsShallowStore } from '../../stores/use-integration-details';
import { hasSameIds, MAX_LOCATIONS_ALLOWED_IN_CALENDAR_VIEW } from '../../utils';
import { CalendarTimeLineView } from './CalendarTimeLineView';
import { HeaderBar } from './components/HeaderBar/HeaderBar';
import { CalendarEvents } from './EventsPanel/CalendarEvents';
import { getFilteredAppointments, getLocalStorageProviderListDetails } from './helpers';

const { listAppointments } = ServiceProvidersApi;
const { useGetProvidersExceptionsForMultipleProvidersOfMultiLocations } = ServiceProvidersQueries;
const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

dayjs.extend(utc);

export const Calendar = () => {
  const { current } = useLocation();
  const { isOpen: isSettingModalOpen } = useSettingsNavigate();
  const { t } = useTranslation('schedule');
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { selectedLocationIds } = useAppScopeStore();
  const [defaultFilteredLocationIds, setDefaultFilteredLocationIds] = useState<string[]>(selectedLocationIds ?? []);
  const [selectedMultiLocationIds, setSelectedMultiLocationIds] = useState<string[]>(selectedLocationIds ?? []);
  const performedSearch = useRef(false);

  const { localStorageLocationIdKey, userId } = useGetLocalStorageLocationIdAndUserId();

  const { setAppointments, setSelectedDate, setRefreshAppointments } = useAppointmentsInfoShallowStore(
    'setAppointments',
    'setSelectedDate',
    'setRefreshAppointments'
  );

  const { setIntegrationDetails, setIsIntegratedOffice } = useIntegrationDetailsShallowStore(
    'setIntegrationDetails',
    'setIsIntegratedOffice'
  );

  const {
    defaultAppointmentTypes,
    selectedAppointmentTypesList,
    isUnConfirmationStatusSelected,
    isFiltersApplied,
    selectedProvidersList,
    setIsFiltersApplied,
    setDefaultAppointmentTypes,
    setFilteredAppointmentList,
    resetFiltersData,
    resetSelectedProvidersList,
    selectedInsuranceVerificationStatusList,
  } = useCalendarHeaderFilterShallowStore(
    'setSelectedProvidersList',
    'isFiltersApplied',
    'setIsFiltersApplied',
    'setDefaultAppointmentTypes',
    'selectedProvidersList',
    'defaultAppointmentTypes',
    'selectedAppointmentTypesList',
    'isUnConfirmationStatusSelected',
    'setFilteredAppointmentList',
    'resetFiltersData',
    'resetSelectedProvidersList',
    'selectedInsuranceVerificationStatusList'
  );

  const {
    isIntegratedOffice,
    data: integrationDetails,
    isLoading: isLoadingIntegrationDetails,
  } = useGetIntegrationDetails({
    selectedLocationId: selectedLocationIds[0],
  });

  useEffect(() => {
    if (!isLoadingIntegrationDetails && integrationDetails) {
      setIntegrationDetails(integrationDetails);
      setIsIntegratedOffice(isIntegratedOffice);
    }
  }, [integrationDetails, isIntegratedOffice, isLoadingIntegrationDetails]);

  // NOTE: Reset the selected providers, and filter data from header bar, when the globally selected location ids change.
  useEffect(() => {
    if (!hasSameIds(selectedLocationIds, selectedMultiLocationIds)) {
      resetFiltersData();
      resetSelectedProvidersList(localStorageLocationIdKey, userId);
      setIsFiltersApplied(false);
      setFilteredAppointmentList([]);
    }
  }, [selectedLocationIds]);

  const setDefaultSelections = (locationIds: string[]) => {
    setDefaultFilteredLocationIds(locationIds);
    setSelectedMultiLocationIds(locationIds);
  };

  // Get appointment Types for the selected locations
  const getAndSetAppointmentTypes = async (locationIds: string[]) => {
    const promises = locationIds.map((locationId) => {
      return AppointmentTypesApi.getAppointmentTypes(locationId);
    });
    const res = await Promise.all(promises);
    const appointmentTypeData = res?.reduce((acc, curr) => {
      return acc.concat(curr);
    }, []);
    setDefaultAppointmentTypes(appointmentTypeData);
  };

  // useEffect to fetch appointment types for all locations initially and save it in filter store
  useEffect(() => {
    if (hasSameIds(selectedMultiLocationIds, defaultFilteredLocationIds) && !defaultAppointmentTypes.length) {
      const locationIds = selectedLocationIds.length === 1 ? selectedLocationIds : defaultFilteredLocationIds;
      getAndSetAppointmentTypes(locationIds);
    }
  }, []);

  // useEffect to fetch appointment types when the global selected location/s change
  useEffect(() => {
    getAndSetAppointmentTypes(selectedLocationIds);
  }, [selectedLocationIds]);

  const locationSelectorModalProps = useModalControl();

  const searchParams = useSearch<{ Search: { currentDate: string; appointmentId: string } }>();

  const [calendarDate, setCalendarDate] = useState(
    searchParams.currentDate ? searchParams.currentDate : getTodaysDate('MM/DD/YYYY')
  );

  const [startDate, endDate] = useMemo(() => {
    const startDateInUTC = dayjs(String(calendarDate)).utc().toISOString();
    const endDateInUTC = dayjs(String(calendarDate)).add(23, 'hours').add(59, 'minutes').utc().toISOString();
    return [startDateInUTC, endDateInUTC];
  }, [calendarDate]);

  const {
    data: providers,
    isLoading: isProvidersListLoading,
    refetch: refetchProviders,
  } = useGetProvidersListForMultipleLocationIds({
    locationIds: selectedMultiLocationIds,
  });

  // useEffect to refetch provider if changes are made in the global settings for providers
  useEffect(() => {
    const hasVisitedProvidersSettings = current.hash === 'settings/schedule/manage-providers';
    const hasVisitedOfficeHoursSettings = current.hash === 'settings/schedule/office-hours';
    const hasVisitedAppointmentTypesSettings = current.hash === 'settings/schedule/appointment-types';

    if (!isSettingModalOpen && hasVisitedProvidersSettings) {
      refetchProviders();
      refetchProvidersExceptions();
    }

    if (!isSettingModalOpen && hasVisitedOfficeHoursSettings) {
      refetchOfficeHours();
    }

    if (!isSettingModalOpen && hasVisitedAppointmentTypesSettings) {
      getAndSetAppointmentTypes(selectedLocationIds);
    }
  }, [isSettingModalOpen, current]);

  const {
    data: officeHoursData,
    isLoading: isOfficeHoursLoading,
    refetch: refetchOfficeHours,
  } = useGetOfficeHoursForMultipleLocationIds({
    locationIds: selectedMultiLocationIds,
  });

  const providerIds = useMemo(() => {
    if (providers) {
      return providers.map((provider) => provider.id ?? '').filter((id) => id) ?? [];
    }
    return [];
  }, [providers]);

  const {
    data: providersExceptions,
    isLoading: isProvidersExceptionsLoading,
    refetch: refetchProvidersExceptions,
  } = useGetProvidersExceptionsForMultipleProvidersOfMultiLocations({
    providerIds: providerIds,
    locationIds: selectedMultiLocationIds,
    startDateTime: startDate,
    endDateTime: endDate,
    isAvailable: false,
  });

  const { providersOfficeHoursListData, isProvidersOfficeHoursLoading } = useGetProvidersOfficeHoursList(
    (calendarDate ?? '') as string,
    providerIds ?? [],
    selectedMultiLocationIds
  );

  const {
    data: officeExceptions,
    isLoading: isOfficeExceptionsLoading,
    refetch: refetchOfficeExceptions,
  } = ExceptionQueries.useGetOfficeExceptions({
    locationIds: selectedLocationIds,
    startDateTime: startDate,
    endDateTime: endDate,
    isAvailable: false,
  });

  // validate whether the selected location ids are less than or equal to max number of locations allowed
  const hasValidLocationIds =
    selectedMultiLocationIds.length > 0 && selectedMultiLocationIds.length <= MAX_LOCATIONS_ALLOWED_IN_CALENDAR_VIEW;

  const {
    data: appointments,
    refetch: refetchAppointments,
    isLoading: isAppointmentsDataLoading,
    isRefetching: isAppointmentsDataRefetching,
  } = useLocalizedQuery({
    queryKey: queryKeys.appointments({
      equals: {
        locationIds: selectedMultiLocationIds,
        insuranceStatuses: selectedInsuranceVerificationStatusList,
      },
      between: { start: startDate, end: endDate },
    }),
    queryFn: () =>
      listAppointments({
        between: { start: startDate, end: endDate },
        equals: {
          locationIds: selectedMultiLocationIds,
          insuranceStatuses: selectedInsuranceVerificationStatusList ?? [],
        },
      }),
    retry: 1,
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled: hasValidLocationIds && !!startDate && !!endDate,
    select: (data) => {
      if (demoSourceIds.length === 0) {
        return data;
      } else {
        return {
          ...data,
          appointments: data.appointments?.filter((appointment) => {
            if (!appointment.createdBySourceId) return true;
            return demoSourceIds.includes(appointment.createdBySourceId);
          }),
        };
      }
    },
  });

  const providersList = useGetCombinedProvidersList(appointments ?? {}, (providers ?? []) as ScheduleTypes.Provider[]);

  const alphabeticallySortedProvidersList = useMemo(() => {
    return sortBy(providersList, [(provider) => provider.lastName?.toLowerCase()]);
  }, [providersList]);

  useEffect(() => {
    if (calendarDate && startDate && endDate && hasValidLocationIds) {
      refetchOfficeExceptions();
      refetchAppointments();
    }
  }, [calendarDate, startDate, endDate, hasValidLocationIds]);

  useEffect(() => {
    if (selectedLocationIds && selectedLocationIds.length > 4) {
      locationSelectorModalProps.openModal();
    } else if (selectedLocationIds && selectedLocationIds.length <= 4) {
      setSelectedMultiLocationIds(selectedLocationIds);
      setDefaultFilteredLocationIds(selectedLocationIds);
    }
  }, [selectedLocationIds]);

  const isLoading =
    isProvidersListLoading ||
    isAppointmentsDataLoading ||
    isAppointmentsDataRefetching ||
    isOfficeHoursLoading ||
    isProvidersOfficeHoursLoading ||
    isProvidersExceptionsLoading ||
    isLoadingIntegrationDetails ||
    isOfficeExceptionsLoading;

  useEffect(() => {
    const searchAppointment = () => {
      const element = document.querySelector(`[data-event-id="${searchParams.appointmentId}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        (element as HTMLElement).click();
        performedSearch.current = true;
      }
    };

    if (
      !searchParams.appointmentId ||
      searchParams.currentDate !== calendarDate ||
      isLoading ||
      performedSearch.current
    ) {
      return;
    }
    if (selectedMultiLocationIds.length > MAX_LOCATIONS_ALLOWED_IN_CALENDAR_VIEW) return;

    const timeoutId = setTimeout(searchAppointment, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchParams?.appointmentId, searchParams.currentDate, isLoading, calendarDate, selectedMultiLocationIds.length]);

  useEffect(() => {
    setAppointments(appointments?.appointments || []);
    setRefreshAppointments(refetchAppointments);
    setSelectedDate(calendarDate);
  }, [calendarDate, appointments?.appointments]);

  // get and return provider list from local storage if it exists
  const getProvidersList = () => {
    const { localStorageProviderList } = getLocalStorageProviderListDetails(
      localStorageLocationIdKey,
      userId,
      alphabeticallySortedProvidersList
    );

    const hasFilteredProvidersList =
      !!localStorageProviderList.length &&
      !!alphabeticallySortedProvidersList.length &&
      localStorageProviderList.length !== alphabeticallySortedProvidersList.length;

    return {
      hasFilteredProvidersList,
      localStorageProviderList,
    };
  };

  useEffect(() => {
    const { hasFilteredProvidersList, localStorageProviderList } = getProvidersList();

    const providerList = !!localStorageProviderList.length
      ? localStorageProviderList
      : alphabeticallySortedProvidersList;

    // Update the filtered appointment list if the filters are applied. @TODO: Refactor this logic
    if (isFiltersApplied) {
      const appointmentArray = getFilteredAppointments({
        appointmentsToFilter: appointments?.appointments || [],
        providerList: !!selectedProvidersList.length ? selectedProvidersList : providerList,
        selectedAppointmentTypes: selectedAppointmentTypesList ?? [],
        appointmentStatus: isUnConfirmationStatusSelected ? 'UNCONFIRMED' : '',
      });
      setFilteredAppointmentList(appointmentArray || []);
    }

    // Set the filtered appointment list when the filtered provider list is present in local storage
    if (appointments?.appointments && hasFilteredProvidersList) {
      const appointmentArray: Appointment[] = getFilteredAppointments({
        appointmentsToFilter: appointments?.appointments || [],
        providerList,
        selectedAppointmentTypes: selectedAppointmentTypesList,
        appointmentStatus: isUnConfirmationStatusSelected ? 'UNCONFIRMED' : '',
      });

      setFilteredAppointmentList(appointmentArray);
      setAppointments(appointmentArray);
      setIsFiltersApplied(true);
    }

    // Reset appointments if no filters are applied
    if (!isFiltersApplied) {
      setAppointments(appointments?.appointments ?? []);
    }
  }, [
    appointments?.appointments,
    isFiltersApplied,
    selectedInsuranceVerificationStatusList,
    alphabeticallySortedProvidersList,
  ]);

  return (
    <AppointmentsCalendarViewContextProvider
      appointments={appointments ?? { appointments: [] }}
      providersList={alphabeticallySortedProvidersList}
      selectedDate={calendarDate}
      setSelectedDate={setCalendarDate}
      providersOfficeHours={providersOfficeHoursListData}
      providersExceptions={providersExceptions?.data ?? []}
      selectedLocationIds={selectedMultiLocationIds}
      defaultFilteredLocationIds={defaultFilteredLocationIds}
      officeHours={officeHoursData}
      officeHoursExceptions={officeExceptions?.data ?? []}
      setSelectedMultiLocationIds={setSelectedMultiLocationIds}
    >
      <CalendarViewRefetchMethodProvider
        refetchAppointments={refetchAppointments}
        refetchOfficeExceptions={refetchOfficeExceptions}
        refetchProvidersExceptions={refetchProvidersExceptions}
      >
        <section css={styles.page} id='calendar-view-wrapper'>
          <HeaderBar />
          <div css={{ position: 'relative', overflow: 'auto' }}>
            <ContentLoader
              show={hasValidLocationIds && isLoading}
              message={t('Loading appointments...')}
              css={{ justifyContent: 'flex-start', paddingTop: 'calc(50vh - 150px)' }}
            />
            <CalendarTimeLineView isLoading={hasValidLocationIds && isLoading} />
          </div>
          <CalendarEvents
            customSelectedLocationIds={selectedMultiLocationIds}
            customProvidersList={alphabeticallySortedProvidersList}
          />
        </section>
        <CalendarLocationSelectorModal
          modalProps={locationSelectorModalProps.modalProps}
          locationIds={selectedLocationIds}
          updateSelectedLocationIds={setDefaultSelections}
        />
      </CalendarViewRefetchMethodProvider>
    </AppointmentsCalendarViewContextProvider>
  );
};

const styles = {
  page: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    container-type: inline-size;
  `,
};
