import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, useModalControl } from '@frontend/design-system';
import { pendoTags } from '../../../../shared/constants';

interface PmsChangeConfirmationModalProps {
  modalControls: ReturnType<typeof useModalControl>;
  onConfirm: () => void;
  onCancel?: () => void;
}

const PmsChangeConfirmationModal: FC<PmsChangeConfirmationModalProps> = ({ modalControls, onConfirm, onCancel }) => {
  const { t } = useTranslation('forms');

  return (
    <ConfirmationModal
      {...modalControls.modalProps}
      destructive
      confirmLabel={t('Discard Changes')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      title={t('You have unsaved changes')}
      message={t('Make sure to save your changes before proceeding.')}
      confirmTrackingId={pendoTags.settings.page.medicalHistory.delete.confirm}
      cancelTrackingId={pendoTags.settings.page.medicalHistory.delete.cancel}
    />
  );
};

export default PmsChangeConfirmationModal;
