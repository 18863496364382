import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const DisabledTooltipContent = ({ text }: { text: string }) => {
  return (
    <Text size='medium' color='white'>
      {text}
    </Text>
  );
};

export const disabledTooltipStyle = css`
  background-color: ${theme.colors.neutral90};
  padding: ${theme.spacing(1, 3, 1, 1)};
  max-width: 220px;
`;
