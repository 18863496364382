import { useQueryClient } from 'react-query';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { Modal, TextField, useAlert, useFormField } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { trackingId } from '../../tracking';
import { ExtendedCallRoute } from './types';

export const RenameCallRouteModal = ({ callRoute, onClose }: { callRoute: ExtendedCallRoute; onClose: () => void }) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const phoneTenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const fieldProps = useFormField({
    type: 'text',
    required: true,
    value: callRoute.name,
  });

  const { mutate: updateCallRoute, isLoading: updateCallRouteIsLoading } = CallRouteV1.Mutations.useUpdateMutation({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listOverrides()]);
        alerts.success(t('Name updated successfully.'));
        onClose();
      },
      onError: () => {
        alerts.error(t('Failed to update name.'));
      },
    },
  });

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Rename Call Route')}</Modal.Header>
      <Modal.Body>
        <TextField
          name='call-route-name'
          label='Call Route Name'
          {...fieldProps}
          data-trackingid={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'rename::text-input',
          })}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Save')}
        onPrimaryClick={() => {
          updateCallRoute({
            callRouteId: callRoute.callRouteId,
            locationId: callRoute.locationId,
            name: fieldProps.value,
          });
        }}
        primaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'rename::save-btn',
        })}
        disablePrimary={updateCallRouteIsLoading || !fieldProps.value}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        secondaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'rename::cancel-btn',
        })}
      />
    </>
  );
};
