import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { SelectTerminalSwitch } from './select-terminal/select-terminal-switch';

export const SelectTerminal = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.selectTerminal}>
      <SelectTerminalSwitch />
    </StepPerformanceWrapper>
  );
};
