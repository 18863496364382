export type RegisterPrivateProfitBrandRequest = {
  companyName: string;
  displayName: string;
  ein: string;
  einIssuingCountry: Country;
  phone: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
  email: string;
  website: string;
  vertical: TCRVertical;
  locationId: string;
  mock?: boolean;
  tcrStaging?: boolean;
};

export type RegisterPrivateProfitBrandResponse = {
  brand?: TCRBrand;
};

export type RegisterSoleProprietorBrandRequest = {
  firstName: string;
  lastName: string;
  companyName: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
  email: string;
  website: string;
  vertical: TCRVertical;
  locationId: string;
  mock?: boolean;
  tcrStaging?: boolean;
};

export type RegisterSoleProprietorBrandResponse = {
  brand?: TCRBrand;
};

export type UpdateTCRBrandRequest = {
  brandId: string;
  reverify?: boolean;
  entityType?: EntityType;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  displayName?: string;
  ein?: string;
  einIssuingCountry?: Country;
  phone?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: Country;
  email?: string;
  website?: string;
  brandRelationship?: BrandRelationship;
  vertical?: TCRVertical;
  altBusinessId?: string;
  altBusinessType?: AltBusinessType;
  overrideLock?: boolean;
  tcrStaging?: boolean;
};

export type UpdateTCRBrandResponse = {
  brand?: TCRBrand;
};

export type GetTCRBrandLockStatusRequest = {
  brandId?: string;
  mock?: boolean;
  tcrStaging?: boolean;
};

export type GetTCRBrandLockStatusResponse = {
  locked?: boolean;
};

export type ListTCRBrandsRequest = {
  ein?: string;
  displayName?: string;
  mock?: boolean;
  tcrStaging?: boolean;
};

export type ListTCRBrandsResponse = {
  tcrBrands?: TCRBrand[];
};

export type GetLocationTCRBrandRequest = {
  locationId?: string;
};

export type AssignTCRBrandLocationRequest = {
  locationId?: string;
  brandId?: string;
};

export type AssignTCRBrandLocationResponse = {
  locationTcrBrand?: LocationTCRBrand;
};

export type ConnectLocationToTCRBrandRequest = {
  locationId?: string;
  brandId?: string;
};

export type ConnectLocationToTCRBrandResponse = Record<string, never>;

export type RegisterCampaignRequest = {
  brandId?: string;
  sharedCnp?: TCRCNP;
  tcrStaging?: boolean;
};

export type RegisterCampaignResponse = {
  campaignId?: string;
  mnoMetadata?: { [key: string]: MNOMetaData };
  rawJsonResponse?: string;
};

export type GetPhoneNumbersInfoRequest = {
  locationId?: string;
};

export type GetLocationInfoRequest = {
  locationId?: string;
  locationSlug?: string;
};

export type SearchEINRequest = {
  ein?: string;
  company?: string;
  state?: string;
  city?: string;
  zipCode?: string;
};

export type SearchEINResponse = {
  searchResults?: EINSearchRecord[];
};

export type GetLocationRegistrationStatusRequest = {
  locationId?: string;
};

export type GetLocationRegistrationStatusResponse = {
  registered?: boolean;
  phoneNumbers?: string[];
};

export type GetPhoneNumberRegistrationStatusRequest = {
  phoneNumber?: string;
};

export type GetPhoneNumberRegistrationStatusResponse = {
  registered?: boolean;
};

export enum CampaignStatus {
  CAMPAIGN_STATUS_UNSPECIFIED = 'CAMPAIGN_STATUS_UNSPECIFIED',
  CAMPAIGN_STATUS_ACTIVE = 'CAMPAIGN_STATUS_ACTIVE',
  CAMPAIGN_STATUS_EXPIRED = 'CAMPAIGN_STATUS_EXPIRED',
}

export enum CampaignSharingStatus {
  CAMPAIGN_SHARING_STATUS_UNSPECIFIED = 'CAMPAIGN_SHARING_STATUS_UNSPECIFIED',
  CAMPAIGN_SHARING_STATUS_ACCEPTED = 'CAMPAIGN_SHARING_STATUS_ACCEPTED',
  CAMPAIGN_SHARING_STATUS_DECLINED = 'CAMPAIGN_SHARING_STATUS_DECLINED',
  CAMPAIGN_SHARING_STATUS_PENDING = 'CAMPAIGN_SHARING_STATUS_PENDING',
}

export enum CampaignUsecase {
  CAMPAIGN_USECASE_UNSPECIFIED = 'CAMPAIGN_USECASE_UNSPECIFIED',
  CAMPAIGN_USECASE_LOW_VOLUME_MIXED = 'CAMPAIGN_USECASE_LOW_VOLUME_MIXED',
  CAMPAIGN_USECASE_SOLE_PROPRIETOR = 'CAMPAIGN_USECASE_SOLE_PROPRIETOR',
}

export enum CampaignSubUsecase {
  CAMPAIGN_SUB_USECASE_UNSPECIFIED = 'CAMPAIGN_SUB_USECASE_UNSPECIFIED',
  CAMPAIGN_SUB_USECASE_CUSTOMER_CARE = 'CAMPAIGN_SUB_USECASE_CUSTOMER_CARE',
  CAMPAIGN_SUB_USECASE_MARKETING = 'CAMPAIGN_SUB_USECASE_MARKETING',
  CAMPAIGN_SUB_USECASE_ACCOUNT_NOTIFICATION = 'CAMPAIGN_SUB_USECASE_ACCOUNT_NOTIFICATION',
}

export enum Country {
  COUNTRY_UNSPECIFIED = 'COUNTRY_UNSPECIFIED',
  COUNTRY_US = 'COUNTRY_US',
  COUNTRY_CA = 'COUNTRY_CA',
}

export enum TaxIDType {
  TAX_ID_TYPE_UNSPECIFIED = 'TAX_ID_TYPE_UNSPECIFIED',
  TAX_ID_TYPE_EIN = 'TAX_ID_TYPE_EIN',
  TAX_ID_TYPE_CRA_BN = 'TAX_ID_TYPE_CRA_BN',
}

export enum EntityType {
  ENTITY_TYPE_UNSPECIFIED = 'ENTITY_TYPE_UNSPECIFIED',
  ENTITY_TYPE_PRIVATE_PROFIT = 'ENTITY_TYPE_PRIVATE_PROFIT',
  ENTITY_TYPE_PUBLIC_PROFIT = 'ENTITY_TYPE_PUBLIC_PROFIT',
  ENTITY_TYPE_NON_PROFIT = 'ENTITY_TYPE_NON_PROFIT',
  ENTITY_TYPE_GOVERNMENT = 'ENTITY_TYPE_GOVERNMENT',
  ENTITY_TYPE_SOLE_PROPRIETOR = 'ENTITY_TYPE_SOLE_PROPRIETOR',
}

export enum AltBusinessType {
  ALT_BUSINESS_TYPE_UNSPECIFIED = 'ALT_BUSINESS_TYPE_UNSPECIFIED',
  ALT_BUSINESS_TYPE_DUNS = 'ALT_BUSINESS_TYPE_DUNS',
  ALT_BUSINESS_TYPE_LEI = 'ALT_BUSINESS_TYPE_LEI',
  ALT_BUSINESS_TYPE_GIIN = 'ALT_BUSINESS_TYPE_GIIN',
}

export enum BrandRelationship {
  BRAND_RELATIONSHIP_UNSPECIFIED = 'BRAND_RELATIONSHIP_UNSPECIFIED',
  BRAND_RELATIONSHIP_BASIC_ACCOUNT = 'BRAND_RELATIONSHIP_BASIC_ACCOUNT',
  BRAND_RELATIONSHIP_SMALL_ACCOUNT = 'BRAND_RELATIONSHIP_SMALL_ACCOUNT',
  BRAND_RELATIONSHIP_MEDIUM_ACCOUNT = 'BRAND_RELATIONSHIP_MEDIUM_ACCOUNT',
  BRAND_RELATIONSHIP_LARGE_ACCOUNT = 'BRAND_RELATIONSHIP_LARGE_ACCOUNT',
  BRAND_RELATIONSHIP_KEY_ACCOUNT = 'BRAND_RELATIONSHIP_KEY_ACCOUNT',
}

export enum TCRVertical {
  TCR_VERTICAL_UNSPECIFIED = 'TCR_VERTICAL_UNSPECIFIED',
  TCR_VERTICAL_PROFESSIONAL = 'TCR_VERTICAL_PROFESSIONAL',
  TCR_VERTICAL_REAL_ESTATE = 'TCR_VERTICAL_REAL_ESTATE',
  TCR_VERTICAL_HEALTHCARE = 'TCR_VERTICAL_HEALTHCARE',
  TCR_VERTICAL_HUMAN_RESOURCES = 'TCR_VERTICAL_HUMAN_RESOURCES',
  TCR_VERTICAL_ENERGY = 'TCR_VERTICAL_ENERGY',
  TCR_VERTICAL_ENTERTAINMENT = 'TCR_VERTICAL_ENTERTAINMENT',
  TCR_VERTICAL_RETAIL = 'TCR_VERTICAL_RETAIL',
  TCR_VERTICAL_TRANSPORTATION = 'TCR_VERTICAL_TRANSPORTATION',
  TCR_VERTICAL_AGRICULTURE = 'TCR_VERTICAL_AGRICULTURE',
  TCR_VERTICAL_INSURANCE = 'TCR_VERTICAL_INSURANCE',
  TCR_VERTICAL_POSTAL = 'TCR_VERTICAL_POSTAL',
  TCR_VERTICAL_EDUCATION = 'TCR_VERTICAL_EDUCATION',
  TCR_VERTICAL_HOSPITALITY = 'TCR_VERTICAL_HOSPITALITY',
  TCR_VERTICAL_FINANCIAL = 'TCR_VERTICAL_FINANCIAL',
  TCR_VERTICAL_POLITICAL = 'TCR_VERTICAL_POLITICAL',
  TCR_VERTICAL_GAMBLING = 'TCR_VERTICAL_GAMBLING',
  TCR_VERTICAL_LEGAL = 'TCR_VERTICAL_LEGAL',
  TCR_VERTICAL_CONSTRUCTION = 'TCR_VERTICAL_CONSTRUCTION',
  TCR_VERTICAL_NGO = 'TCR_VERTICAL_NGO',
  TCR_VERTICAL_MANUFACTURING = 'TCR_VERTICAL_MANUFACTURING',
  TCR_VERTICAL_GOVERNMENT = 'TCR_VERTICAL_GOVERNMENT',
  TCR_VERTICAL_TECHNOLOGY = 'TCR_VERTICAL_TECHNOLOGY',
  TCR_VERTICAL_COMMUNICATION = 'TCR_VERTICAL_COMMUNICATION',
}

export enum TCRCNP {
  TCR_CNP_UNSPECIFIED = 'TCR_CNP_UNSPECIFIED',
  TCR_CNP_BANDWIDTH = 'TCR_CNP_BANDWIDTH',
  TCR_CNP_TELNYX = 'TCR_CNP_TELNYX',
  TCR_CNP_TWILIO = 'TCR_CNP_TWILIO',
  TCR_CNP_FLOWROUTE = 'TCR_CNP_FLOWROUTE',
  TCR_CNP_WEAVE_MOCK = 'TCR_CNP_WEAVE_MOCK',
}

export enum TCRBrandIdentityStatus {
  TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED = 'TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED',
  TCR_BRAND_IDENTITY_STATUS_SELF_DECLARED = 'TCR_BRAND_IDENTITY_STATUS_SELF_DECLARED',
  TCR_BRAND_IDENTITY_STATUS_UNVERIFIED = 'TCR_BRAND_IDENTITY_STATUS_UNVERIFIED',
  TCR_BRAND_IDENTITY_STATUS_VERIFIED = 'TCR_BRAND_IDENTITY_STATUS_VERIFIED',
  TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED = 'TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED',
}

export enum WeaveLocationType {
  WEAVE_LOCATION_TYPE_UNSPECIFIED = 'WEAVE_LOCATION_TYPE_UNSPECIFIED',
  WEAVE_LOCATION_TYPE_REGULAR = 'WEAVE_LOCATION_TYPE_REGULAR',
  WEAVE_LOCATION_TYPE_DEMO = 'WEAVE_LOCATION_TYPE_DEMO',
  WEAVE_LOCATION_TYPE_DEV = 'WEAVE_LOCATION_TYPE_DEV',
  WEAVE_LOCATION_TYPE_TEST = 'WEAVE_LOCATION_TYPE_TEST',
}

export enum TCRAuditEventType {
  TCR_AUDIT_EVENT_TYPE_UNSPECIFIED = 'TCR_AUDIT_EVENT_TYPE_UNSPECIFIED',
  TCR_AUDIT_EVENT_TYPE_BRAND_VERIFICATION_SUCCESS = 'TCR_AUDIT_EVENT_TYPE_BRAND_VERIFICATION_SUCCESS',
  TCR_AUDIT_EVENT_TYPE_BRAND_VERIFICATION_FAILURE = 'TCR_AUDIT_EVENT_TYPE_BRAND_VERIFICATION_FAILURE',
}

export type TCRBrand = {
  brandId?: string;
  entityType?: EntityType;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  displayName?: string;
  ein?: string;
  einIssuingCountry?: Country;
  phone?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: Country;
  email?: string;
  website?: string;
  brandRelationship?: BrandRelationship;
  vertical?: TCRVertical;
  altBusinessId?: string;
  altBusinessType?: AltBusinessType;
  referenceId?: string;
  tags?: string[];
  cspId?: string;
  ipAddress?: string;
  universalEin?: string;
  identityStatus?: TCRBrandIdentityStatus;
  insertedAt?: string;
  updatedAt?: string;
  mock?: boolean;
  tcrStaging?: boolean;
};

export type LocationTCRBrand = {
  locationId?: string;
  tcrBrand?: TCRBrand;
};

export type TCRCampaign = {
  campaignId?: string;
  brandId?: string;
  affiliateMarketing?: boolean;
  ageGated?: boolean;
  autoRenewal?: boolean;
  billedDate?: string;
  createDate?: string;
  cspId?: string;
  description?: string;
  directLending?: boolean;
  embeddedLink?: boolean;
  embeddedPhone?: boolean;
  helpMessage?: string;
  messageFlow?: string;
  numberPool?: boolean;
  referenceId?: string;
  sharedCnp?: TCRCNP;
  resellerId?: string;
  sample1?: string;
  sample2?: string;
  sample3?: string;
  sample4?: string;
  sample5?: string;
  campaignStatus?: CampaignStatus;
  subscriberHelp?: boolean;
  subscriberOptin?: boolean;
  subscriberOptout?: boolean;
  subUsecases?: CampaignSubUsecase[];
  usecase?: CampaignUsecase;
  vertical?: TCRVertical;
  mnoMetadata?: { [key: string]: MNOMetaData };
  mnoOperationStatus?: { [key: string]: string };
  sharedByMe?: CampaignSharing;
  tags?: string[];
  mock?: boolean;
  tcrStaging?: boolean;
};

export type MNOMetaData = {
  minMsgSamples?: number;
  reqSubscriberHelp?: boolean;
  reqSubscriberOptout?: boolean;
  reqSubscriberOptin?: boolean;
  mnoReview?: boolean;
  mnoSupport?: boolean;
  noEmbeddedLink?: boolean;
  noEmbeddedPhone?: boolean;
  qualify?: boolean;
  brandTier?: string;
  msgClass?: string;
  tpm?: number;
  tpmScope?: string;
  brandDailyCap?: number;
  mno?: string;
};

export type LocationTCRCampaign = {
  locationId?: string;
  tcrCampaign?: TCRCampaign;
};

export type PhoneNumberTCRCampaign = {
  phoneNumber?: string;
  locationId?: string;
  tcrCampaign?: TCRCampaign;
};

export type CampaignSharing = {
  downstreamCnpId?: string;
  sharedDate?: string;
  sharingStatus?: CampaignSharingStatus;
  statusDate?: string;
  upstreamCnpId?: string;
};

export type PhoneNumbersInfo = {
  locationId?: string;
  tenantId?: string;
  defaultPhoneCnp?: PhoneNumberCNP;
  allPhoneCnps?: PhoneNumberCNP[];
  calculatedDefaultPhoneCnp?: PhoneNumberCNP;
};

export type PhoneNumberCNP = {
  phoneNumber?: string;
  phoneNumberCnp?: string;
};

export type LocationInfo = {
  locationId?: string;
  locationName?: string;
  active?: boolean;
  parentId?: string;
  locationSlug?: string;
  internalAccount?: boolean;
  phoneTenantId?: string;
  tcrVertical?: TCRVertical;
  phoneNumbersInfo?: PhoneNumbersInfo;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
};

export type EINSearchRecord = {
  ein?: string;
  companyName?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  telephone?: string;
  fax?: string;
  website?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  updatedDate?: string;
};

export type TCREvent = {
  eventType?: string;
  cspId?: string;
  cnpId?: string;
  cspName?: string;
  brandId?: string;
  brandName?: string;
  brandReferenceId?: string;
  brandIdentityStatus?: string;
  campaignId?: string;
  campaignReferenceId?: string;
  dcaId?: string;
  dcaName?: string;
  description?: string;
  evpId?: string;
  evpName?: string;
  vettingId?: string;
  mnoId?: string;
  mnoName?: string;
  mock?: boolean;
  tcrStaging?: boolean;
};

export type TenDLCDebugConfig = {
  debugEnabled: boolean;
  rejectEin: boolean;
  useNewBrand: boolean;
  mockResponse: boolean;
};

export type BusinessInfoFormValues = {
  companyName: string;
  displayName?: string;
  street: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
  soleProprietorRadio: 'ein' | 'soleProprietor';
  ein?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  vertical: TCRVertical;
  website: string;
};
