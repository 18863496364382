import { FC, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Chip, useModalControl } from '@frontend/design-system';
import { BundleTrialDashboardTrackingIds } from '../../constants/tracking-ids';
import { getScheduleDemoLink, getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { BundleTrialActiveModal } from '../bundle-upgrade-modal/BundleTrialActiveModal';

export const BundleActions: FC<{
  bundle: SalesforceAccountProductBundle;
  locationIds: string[];
  trialEndDate: Date;
  styles?: Interpolation<Theme>;
}> = ({ bundle, locationIds, styles, trialEndDate }) => {
  const { t } = useTranslation('bundleUpgrade');

  const { modalProps, openModal: openBundleTrainingModal } = useModalControl();

  const remainingDays = useMemo(() => {
    return Math.ceil((trialEndDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24));
  }, [trialEndDate]);

  const handleUpgradeAction = () => {
    window.open(getScheduleDemoLink('bundle', getUser()?.username), '_blank', 'noopener noreferrer');
  };

  return (
    <div css={[actionContainerStyles, styles]}>
      <Button
        variant='tertiary'
        css={buttonStyles}
        trackingId={BundleTrialDashboardTrackingIds.bundleTraining}
        onClick={openBundleTrainingModal}
      >
        {t('{{bundleName}} Training', { bundleName: getUpgradeBundleDisplayName(bundle) })}
      </Button>
      <Button
        variant='tertiary'
        css={buttonStyles}
        trackingId={BundleTrialDashboardTrackingIds.upgradeAction}
        onClick={handleUpgradeAction}
      >
        {t('Call to Upgrade')}
      </Button>
      <Chip css={[trialChipStyles, remainingDays <= 7 && criticalChipStyles]}>
        {t('{{count}} days remaining', { count: remainingDays })}
      </Chip>

      <BundleTrialActiveModal
        title={t('{{bundleName}} Bundle Training', { bundleName: getUpgradeBundleDisplayName(bundle) })}
        locationIds={locationIds}
        {...modalProps}
      />
    </div>
  );
};

const actionContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
});

const buttonStyles = css({
  color: theme.colors.neutral50,
  padding: theme.spacing(0.5, 1),
});

const trialChipStyles = css({
  maxWidth: 'max-content',
  backgroundColor: theme.colors.neutral20,
  color: theme.colors.neutral80,
});

const criticalChipStyles = css({
  backgroundColor: theme.colors.white,
  color: theme.colors.critical50,
});
