export const scheduleAlertsTrackingIds = {
  sendMessage: 'schedule-alerts-send-message',
  callPerson: 'schedule-alerts-call-person',
  viewAppointment: 'schedule-alerts-view-appointment',
  confirmAppointment: 'schedule-alerts-confirm-appointment',
  clearAlert: 'schedule-alerts-clear-alert',
};

export const schedulePulseTrackingIds = {
  schedulePulseEventPanelIconButton: 'schedule-pulse-event-panel-icon-button',
};
