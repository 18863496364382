import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonsV3 } from '@frontend/api-person';
import { PersonSecondaryInfo } from '@frontend/thread-header';
import { Avatar, ListRow } from '@frontend/design-system';

type MenuItemProps = {
  item: Person;
};
export const MenuItem = ({ item: person }: MenuItemProps) => {
  const personName = PersonsV3.PersonHelpers.getFullName(person);

  return (
    <ListRow as='div' className='person-email-menu-item'>
      <ListRow.Lead>
        <Avatar name={personName} />
      </ListRow.Lead>
      <ListRow.Content css={{ textAlign: 'initial' }}>
        <ListRow.Content.Title css={{ display: 'inline-block' }}>{personName}</ListRow.Content.Title>
        <ListRow.Content.Subtitle>
          <PersonSecondaryInfo as='div' personId={person.personId} person={person} />
        </ListRow.Content.Subtitle>
      </ListRow.Content>
    </ListRow>
  );
};
