import { PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { theme, WeaveThemeBorderRadius } from '@frontend/theme';

export const PersonStatusIndicator = ({
  personStatus,
  height = 8,
  borderRadius = 'medium',
  isExpanded = true,
  ...rest
}: {
  personStatus?: string;
  height?: number;
  borderRadius?: WeaveThemeBorderRadius;
  isExpanded?: boolean;
}) => {
  if (!personStatus || personStatus.toUpperCase() !== PersonStatus_Enum.PROSPECTIVE) return null;

  const status = PersonStatus_Enum[personStatus?.toUpperCase() as PersonStatus_Enum];

  const STATUS_COLOR: { [key in PersonStatus_Enum]?: string } = {
    [PersonStatus_Enum.PROSPECTIVE]: theme.colors.rose40,
  };

  const statusColor = STATUS_COLOR[status];

  if (!statusColor) return null;

  const backgroundSize = isExpanded ? '8px 8px' : '6px 6px';

  return (
    <div css={{ height: isExpanded ? 0 : height, width: '100%' }}>
      <div
        css={{
          background: `linear-gradient(
            135deg,
            ${statusColor} 15%,
            transparent 15%,
            transparent 50%,
            ${statusColor} 50%,
            ${statusColor} 65%,
            transparent 65%,
            transparent
        )`,
          backgroundSize,
          height: height,
          borderTopLeftRadius: theme.borderRadius[borderRadius],
          borderTopRightRadius: theme.borderRadius[borderRadius],
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        {...rest}
      />
    </div>
  );
};
