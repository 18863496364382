import { MediaApi } from '@frontend/api-media';
import { sentry } from '@frontend/tracking';

const clearUrl = (url: string): void => {
  window.URL.revokeObjectURL(url);
};

export const getImageName = ({ id, filename }: { id: string; filename: string }) => {
  const name = filename.split('/').pop();
  return name?.replace(`${id}-`, '') ?? '';
};

export const generateBlob = async (mediaId: string, locationId?: string) => {
  try {
    const response = await MediaApi.downloadMmsMediaImage(mediaId, locationId);
    return new Blob([new Uint8Array(response)], { type: 'image/png' });
  } catch (error) {
    sentry.warn({
      error,
      topic: 'messages',
      addContext: {
        name: 'Blob generation context',
        context: {
          mediaId,
          errorMessage: JSON.stringify(error),
        },
      },
    });
  }
  return undefined;
};

export const copyImage = async (imageId: string, locationId?: string) => {
  const blob = await generateBlob(imageId, locationId);
  if (!blob) return;

  document.body.focus(); // ensure document is focused or else the write will fail
  await navigator.clipboard.write([
    new ClipboardItem({
      [blob.type]: blob,
    }),
  ]);
};

export const downloadImage = async (mediaItem: { mediaId: string; filename: string; locationId?: string }) => {
  const link = window.document.createElement('a');
  link.style.display = 'none';
  const blob = await generateBlob(mediaItem.mediaId, mediaItem.locationId);
  if (!blob) return;
  link.href = window.URL.createObjectURL(blob);
  link.download = getImageName({ id: mediaItem?.mediaId, filename: mediaItem?.filename ?? '' });
  window.document.body.appendChild(link);
  link.click();
  // cleanup
  clearUrl(link.href);
  window.document.body.removeChild(link);
};
