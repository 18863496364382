import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const conditionListStyle = css`
  padding-left: 0;
  margin-top: ${theme.spacing(3)};
  overflow-y: auto;
`;

export const conditionListWrapperStyle = css`
  padding: ${theme.spacing(1, 0)};

  .search-row {
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(3)};

    .seach-container {
      flex: auto;
    }

    .sync-btn {
      white-space: nowrap;
    }
  }

  .text-condition-actions {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    button {
      color: ${theme.colors.neutral50};
      padding-left: ${theme.spacing(0)};
      padding-right: ${theme.spacing(0)};
      font-size: ${theme.fontSize(16)};

      &:first-of-type {
        &:after {
          content: '|';
          margin-left: ${theme.spacing(1)};
          margin-right: ${theme.spacing(1)};
          font-size: ${theme.fontSize(18)};
          color: ${theme.colors.neutral20};
        }
      }
    }
  }

  .save-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacing(2)};
    gap: ${theme.spacing(2)};
  }

  .option-item {
    display: flex;
    padding: ${theme.spacing(2)};

    .sync-icon {
      margin-right: ${theme.spacing(2)};
    }

    .switch-field {
      margin-left: auto;
    }

    &:first-of-type {
      border-top: 2px solid ${theme.colors.neutral20};
    }

    border-bottom: 2px solid ${theme.colors.neutral20};
  }
`;
export const seachImageStyle = css`
  height: 350px;
`;
