import { useMemo } from 'react';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { PersonHelpers } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { OTHER_SUBSCRIBER_SELECTION_VALUE } from '../constants';
import { getPayerCombinedId } from '../helpers';
import { usePersonInfoStore } from '../providers';
import { DropdownOption } from '../types';

interface UseGetFormDataReturnType {
  payerList: DropdownOption[];
  providerList: { nationalProviderId: string; taxId: string }[];
  nationalProviderIdList: string[];
  primSubscriberList: DropdownOption[];
  isLoading?: boolean;
}

export const useGetFormData = () => {
  const { t } = useTranslation('insuranceDetails');
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;
  const householdId = personInfo?.WeaveHouseholdID as string;

  const payerListQuery = InsuranceDetailQueries.useGetPayerList(locationId!);
  const providerListQuery = InsuranceDetailQueries.useGetProviderList(locationId!);
  const personHouseholdsQuery = InsuranceDetailQueries.useGetHouseholdData(locationId!, householdId);
  const insuranceDetailsQuery = InsuranceDetailQueries.useGetInsuranceByPersonId(personId, locationId!);

  const isLoading =
    payerListQuery.isLoading ||
    providerListQuery.isLoading ||
    personHouseholdsQuery.isLoading ||
    insuranceDetailsQuery.isLoading;

  const { payerList, providerList, nationalProviderIdList, primSubscriberList } =
    useMemo<UseGetFormDataReturnType>(() => {
      if (isLoading) {
        return { payerList: [], providerList: [], nationalProviderIdList: [], primSubscriberList: [] };
      }

      const payerList =
        payerListQuery.data?.map<DropdownOption>((item) => ({
          label: item.name,
          value: item.id,
          uniqueCombinedId: getPayerCombinedId(item.id, item.name),
        })) ?? [];
      const providerList = providerListQuery.data ?? [];
      const nationalProviderIdList = providerList.reduce<string[]>((acc, provider) => {
        return provider.nationalProviderId ? [...acc, provider.nationalProviderId] : acc;
      }, []);
      const personHouseHoldList =
        personHouseholdsQuery.data?.persons?.sort((a, b) => {
          return new Date(a.birthdate || '').getTime() - new Date(b.birthdate || '').getTime();
        }) ?? [];

      const primSubscriberList = personHouseHoldList
        .filter((p) => p.personId !== personId)
        .map<DropdownOption>((person) => ({
          label: PersonHelpers.getFullName({
            FirstName: person.firstName,
            LastName: person.lastName,
            PreferredName: person.preferredName,
          }),
          value: person.personId,
          isGuardian: person.isGuardian,
        }));

      if (primSubscriberList.length > 0) {
        primSubscriberList.push({
          label: t('Other Subscriber'),
          value: OTHER_SUBSCRIBER_SELECTION_VALUE,
        });
      }

      return { payerList, providerList, nationalProviderIdList, primSubscriberList };
    }, [isLoading, payerListQuery.data, providerListQuery.data, personHouseholdsQuery.data]);

  return {
    isLoading,
    payerList,
    providerList,
    nationalProviderIdList,
    primSubscriberList,
    insuranceDetails: insuranceDetailsQuery.data,
  };
};
