import { useTranslation } from '@frontend/i18n';
import { NodeTypeLabels, NodeTypes } from '@frontend/node-flow';
import { ConfirmationModal, ModalControlModalProps } from '@frontend/design-system';
import { trackingId } from '../../../tracking';
import { NodeActionType } from '../types';

export const WarningModal = ({
  modalProps,
  nodeName,
  stepType,
  changingStepType,
  stepActionType,
  onConfirm,
}: {
  modalProps: ModalControlModalProps;
  stepActionType: NodeActionType;
  stepType?: NodeTypes;
  changingStepType?: NodeTypes;
  nodeName?: string;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation('phone');

  const getStepTypeLabel = (type?: NodeTypes) => {
    if (!type) {
      return '';
    }

    const label = NodeTypeLabels[type as NodeTypes];

    return label;
  };

  const title = {
    add: t('Add Step?'),
    delete: t('Delete Step?'),
    edit: t('Change Step?'),
  };

  const messages = {
    add: t('Adding a {{stepType}} to this section of the Call Route will delete all subsequent steps.', {
      stepType: getStepTypeLabel(stepType),
    }),
    delete: t('Deleting a {{stepType}} step deletes all subsequent steps.', {
      stepType: getStepTypeLabel(stepType),
    }),
    edit: t('Changing this step from a {{stepType}} to a {{changingStepType}} deletes all subsequent steps.', {
      stepType: getStepTypeLabel(stepType),
      changingStepType: getStepTypeLabel(changingStepType),
    }),
  };

  const confirmLabel = {
    add: t('Yes, Add'),
    delete: t('Yes, Delete'),
    edit: t('Yes, Change'),
  };

  return (
    <ConfirmationModal
      {...modalProps}
      title={title[stepActionType]}
      subLabel={nodeName}
      message={messages[stepActionType]}
      cancelLabel={t('No, Cancel')}
      confirmLabel={confirmLabel[stepActionType]}
      destructive
      cancelTrackingId={trackingId({
        context: 'setting',
        feature: 'call-routes',
        details: 'delete-associative-node::cancel-btn',
      })}
      confirmTrackingId={trackingId({
        context: 'setting',
        feature: 'call-routes',
        details: 'delete-associative-node::confirm-btn',
      })}
      onCancel={() => modalProps.onClose()}
      onConfirm={onConfirm}
    />
  );
};
