import { useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { Dot, NakedButton, Text, usePortal } from '@frontend/design-system';
import { CHAT_HEIGHTS, WIDTHS } from '../../constants';
import { useMiniChatShallowStore } from '../../stores';
import { OverflowChatsList } from './overflow-chats-list';

export const OverflowChatsButton = () => {
  const { chats, overflowChatCount } = useMiniChatShallowStore('chats', 'overflowChatCount');
  const overflowChats = chats.slice(0, overflowChatCount);
  const overflowChatUnreadCount = overflowChats.reduce((acc, { unreadCount }) => (unreadCount ? acc + 1 : acc), 0);

  const [overflowListIsOpen, setOverflowListIsOpen] = useState(false);

  const { Portal } = usePortal({
    attributes: {
      id: `overflow-mini-chat-list-portal`,
      tabIndex: -1,
    },
  });

  return (
    <>
      <NakedButton
        key='overflow-chat-button'
        css={{
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTopRightRadius: theme.borderRadius.medium,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: CHAT_HEIGHTS.avatar,
          width: WIDTHS['avatar'],
          zIndex: theme.zIndex.high,
          position: 'relative',
          backgroundColor: theme.colors.white,
          boxShadow: theme.shadows.floating,
          cursor: 'pointer',
          ':focus, :hover': {
            backgroundColor: theme.colors.neutral5,
          },
        }}
        onClick={() => {
          setOverflowListIsOpen(true);
        }}
      >
        <Text weight='bold'>{`+${overflowChatCount}`}</Text>
        {overflowChatUnreadCount > 0 && (
          <Dot
            css={{
              position: 'absolute',
              left: theme.spacing(-0.75),
              top: theme.spacing(-0.75),
              color: theme.font.colors.white,
              fontSize: theme.fontSize(14),
              fontWeight: theme.font.weight.bold,
              width: 18,
              height: 18,
              border: 'none',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            color='critical'
          >
            {overflowChatUnreadCount}
          </Dot>
        )}
      </NakedButton>
      <Portal>
        <AnimatePresence>
          {overflowListIsOpen && (
            <>
              <motion.div
                initial={{
                  backgroundColor: `${theme.colors.primary90}00`,
                }}
                css={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: theme.zIndex.modal,
                  overflowX: 'visible',
                }}
                animate={{
                  backgroundColor: `${theme.colors.primary90}4D`,
                }}
                exit={{
                  backgroundColor: `${theme.colors.primary90}00`,
                }}
                onClick={() => setOverflowListIsOpen(false)}
              >
                <OverflowChatsList onClose={() => setOverflowListIsOpen(false)} />
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </Portal>
    </>
  );
};
