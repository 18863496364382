import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { theme } from '@frontend/theme';
import { Text, styles } from '@frontend/design-system';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';

const isUser = (item: TeamChatTypes.User | TeamChatTypes.Conversation): item is TeamChatTypes.User => {
  return 'userID' in item;
};

export const ConversationDropdownMenuItem = ({ item }: { item: TeamChatTypes.User | TeamChatTypes.Conversation }) => {
  return (
    <div css={containerStyle}>
      {isUser(item) ? (
        <>
          <ChatAvatar users={[item]} />
          <Text as='span'>
            {item.firstName} {item.lastName}
          </Text>
        </>
      ) : (
        <Text as='span'># {item.name}</Text>
      )}
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  position: 'relative',
  width: '100%',

  span: {
    display: 'flex',
    width: '100%',
    ...styles.truncate,
  },
});
