import i18next from 'i18next';
import { PaymentType } from '@frontend/api-invoices';
import {
  FailedPaymentsOrigin,
  FailedPaymentsPaymentType,
  FailedPaymentStatuses,
  SearchFailedPaymentsModel,
  SearchFailedPaymentsOrigin,
  SearchFailedPaymentsParams,
  SearchFailedPaymentsPaymentType,
  SearchFailedPaymentStatus,
} from './types';

export const calculateFailedTransactionsSummary = (rows: SearchFailedPaymentsModel[]) => {
  return rows.reduce(
    (acc) => {
      // TODO

      return acc;
    },
    { total: 0 }
  );
};

export const getFailedTransactionsStatusParamLabels = () => {
  return {
    [SearchFailedPaymentStatus.STATUS_UNKNOWN]: i18next.t('Unknown', { ns: 'payments' }),
    [SearchFailedPaymentStatus.SUCCEEDED]: i18next.t('Succeeded', { ns: 'payments' }),
    [SearchFailedPaymentStatus.FAILED]: i18next.t('Failed', { ns: 'payments' }),
    [SearchFailedPaymentStatus.PENDING]: i18next.t('Pending', { ns: 'payments' }),
  };
};

export const getFailedTransactionsStatusLabels = () => {
  return {
    STATUS_UNKNOWN: i18next.t('Unknown', { ns: 'payments' }),
    SUCCEEDED: i18next.t('Succeeded', { ns: 'payments' }),
    FAILED: i18next.t('Failed', { ns: 'payments' }),
    PENDING: i18next.t('Pending', { ns: 'payments' }),
  };
};

export const getFailedTransacactionsOriginParamLabels = () => {
  return {
    [SearchFailedPaymentsOrigin.UNKNOWN]: i18next.t('Unknown', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.LOCATION]: i18next.t('Manual Card Entry', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.LOCATION_PORTAL]: i18next.t('Online Bill Pay', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.PAYMENT_PORTAL]: i18next.t('Text To Pay', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.TERMINAL]: i18next.t('Terminal', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.MOBILE_TAP_TO_PAY]: i18next.t('Mobile Tap To Pay', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.ONLINE_SCHEDULING]: i18next.t('Online Scheduling', { ns: 'payments' }),
    [SearchFailedPaymentsOrigin.PAYMENT_PLAN]: i18next.t('Payment Plan', { ns: 'payments' }),
  };
};
export const getFailedTransactionsOriginLabels = () => {
  return {
    UNKNOWN: i18next.t('Unknown', { ns: 'payments' }),
    LOCATION: i18next.t('Manual Card Entry', { ns: 'payments' }),
    LOCATION_PORTAL: i18next.t('Online Bill Pay', { ns: 'payments' }),
    PAYMENT_PORTAL: i18next.t('Text To Pay', { ns: 'payments' }),
    TERMINAL: i18next.t('Terminal', { ns: 'payments' }),
    MOBILE_TAP_TO_PAY: i18next.t('Mobile Tap To Pay', { ns: 'payments' }),
    ONLINE_SCHEDULING: i18next.t('Online Scheduling', { ns: 'payments' }),
    PAYMENT_PLAN: i18next.t('Payment Plan', { ns: 'payments' }),
  };
};

export const getFailedTransactionsStatusLabel = (status: FailedPaymentStatuses) => {
  return getFailedTransactionsStatusLabels()[status];
};

export const getFailedTransactionsOriginLabel = (origin: FailedPaymentsOrigin) => {
  return getFailedTransactionsOriginLabels()[origin];
};

export const mapFailedPaymentsTypeToPaymentType = (
  failedPaymentType: SearchFailedPaymentsPaymentType | FailedPaymentsPaymentType
): PaymentType | undefined => {
  switch (failedPaymentType) {
    case SearchFailedPaymentsPaymentType.CARD:
    case 'CARD':
      return PaymentType.Card;
    case SearchFailedPaymentsPaymentType.CARD_PRESENT:
    case 'CARD_PRESENT':
      return PaymentType.CardPresent;
    case SearchFailedPaymentsPaymentType.AFFIRM:
    case 'AFFIRM':
      return PaymentType.Affirm;
    case SearchFailedPaymentsPaymentType.LINK:
    case 'LINK':
      return PaymentType.Link;
    case SearchFailedPaymentsPaymentType.ACH:
    case 'ACH':
      return PaymentType.ACH;
  }

  return undefined;
};

export const getFailedTransactionsQueryKey = (searchParams: SearchFailedPaymentsParams) => {
  const obj = { ...searchParams };
  obj.after = undefined;

  return ['failed-transactions', JSON.stringify(obj)];
};

export const getFailedTransactionsInfiniteQueryKey = (
  selectedLocationIds: string[],
  searchParams: SearchFailedPaymentsParams
) => {
  return [selectedLocationIds, ...getFailedTransactionsQueryKey(searchParams)];
};
