import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Organization } from '@weave/schema-gen-ts/dist/schemas/organization/v1/org.pb';
import { isEqual } from 'lodash-es';
import { getUser, setLoginData } from '@frontend/auth-helpers';
import { shell } from '@frontend/shell-utils';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { WeaveLocation, WeaveLocationGroup } from './types';

export type OrgIdMap = Record<string, OrgIdMapKey>;

export type OrgIdMapKey = { parents: WeaveLocationGroup[]; locations: WeaveLocation[] };
interface ScopeStore {
  locationData: Record<string, WeaveLocation>;
  setLocationData: (scopes: Record<string, WeaveLocation>) => void;
  groupData: Record<string, WeaveLocationGroup>;
  setGroupData: (scopes: Record<string, WeaveLocationGroup>) => void;
  orgData: Record<string, Organization>;
  setOrgData: (orgs: Record<string, Organization>) => void;
  selectedOrgId: string;
  setSelectedOrgId: (orgId: string) => void;
  selectedGroupId: string;
  setSelectedGroupId: (groupId: string) => void;
  forcedGroupId: string;
  setForcedGroupId: (groupId: string) => void;
  selectedLocationIds: string[];
  setSelectedLocationIds: (scopes: string[]) => void;
  appendLocationData: (scopes: Record<string, WeaveLocation>) => void;
  appendGroupData: (scopes: Record<string, WeaveLocationGroup>) => void;
  appendOrgData: (orgs: Record<string, Organization>) => void;
  scopeHierarchy: Record<string, Record<string, string[]>>;
  setScopeHierarchy: (hierarchy: Record<string, Record<string, string[]>>) => void;
  appendScopeHierarchy: (hierarchy: Record<string, Record<string, string[]>>) => void;
}

export const createScopeStore = () =>
  createStoreWithSubscribe<ScopeStore>(
    (set, get) => ({
      locationData: {},
      setLocationData: (locations) => {
        set({ locationData: locations }, false, { type: 'setLocationData' });
      },
      groupData: {},
      setGroupData: (groups) => {
        set({ groupData: groups }, false, { type: 'setGroupData' });
      },
      orgData: {},
      setOrgData: (orgs) => {
        set({ orgData: orgs }, false, { type: 'setOrgData' });
      },
      selectedOrgId: '',
      setSelectedOrgId: (orgId) => {
        set({ selectedOrgId: orgId }, false, { type: 'setSelectedOrgId' });
      },
      selectedGroupId: '',
      setSelectedGroupId: (groupId) => {
        set({ selectedGroupId: groupId }, false, { type: 'setSelectedGroupId' });
      },
      forcedGroupId: '',
      setForcedGroupId: (groupId) => {
        set({ forcedGroupId: groupId }, false, { type: 'setForcedGroupId' });
      },
      selectedLocationIds: [],
      setSelectedLocationIds: (scopes) => {
        set({ selectedLocationIds: scopes }, false, { type: 'setSelectedLocationIds' });
      },
      appendLocationData: (locations) => {
        set({ locationData: { ...get().locationData, ...locations } }, false, { type: 'appendLocationData' });
      },
      appendGroupData: (groups) => {
        set({ groupData: { ...get().groupData, ...groups } }, false, { type: 'appendGroupData' });
      },
      appendOrgData: (orgs) => {
        set({ orgData: { ...get().orgData, ...orgs } }, false, { type: 'appendOrgData' });
      },
      scopeHierarchy: {},
      setScopeHierarchy: (hierarchy) => {
        set({ scopeHierarchy: hierarchy }, false, { type: 'setScopeHierarchy' });
      },
      appendScopeHierarchy: (hierarchy) => {
        set({ scopeHierarchy: { ...get().scopeHierarchy, ...hierarchy } }, false, { type: 'appendScopeHierarchy' });
      },
    }),
    { name: 'ScopeStore', trace: true }
  );

export const useLocationGroupStore = createScopeStore();
const useScopeShallowStore = createShallowStore(useLocationGroupStore);

let lastSentIdsToShell: string[] = [];

const useShellSideEffect = ({ selectedLocationIds }: { selectedLocationIds: string[] }) => {
  /* Send selected location ids to shell */
  if (shell.isShell && !isEqual(lastSentIdsToShell, selectedLocationIds) && selectedLocationIds.length !== 0) {
    lastSentIdsToShell = selectedLocationIds ?? [];
    shell.emit?.('send:locations', { type: 'locations', locations: selectedLocationIds ?? [] });
  }
};

export const useAppScopeStore = () => {
  const {
    setLocationData,
    setGroupData,
    setOrgData,
    setSelectedGroupId,
    setSelectedLocationIds,
    setSelectedOrgId,
    setForcedGroupId,
    appendLocationData,
    appendGroupData,
    appendOrgData,
    setScopeHierarchy,
    appendScopeHierarchy,
  } = useLocationGroupStore.getState();

  /**
   * We want these properties to be reactive.
   * We don't want to use the store directly because it will cause unnecessary re-renders.
   *
   * Components using this hook will rerender when the properties below are updated
   */
  const {
    locationData,
    groupData,
    orgData,
    selectedGroupId,
    selectedLocationIds,
    selectedOrgId,
    forcedGroupId,
    scopeHierarchy,
  } = useScopeShallowStore(
    'locationData',
    'groupData',
    'orgData',
    'selectedGroupId',
    'selectedLocationIds',
    'selectedOrgId',
    'forcedGroupId',
    'scopeHierarchy'
  );

  const user = useRef(getUser());

  const accessibleLocationMap = useMemo(
    () =>
      Object.keys(locationData).reduce((acc, locationId) => {
        const location = locationData[locationId];
        if (location.accessible && location.active) {
          acc[locationId] = {
            ...location,
          };
        }
        return acc;
      }, {} as Record<string, WeaveLocation>),
    [locationData]
  );

  const accessibleGroupMap = useMemo(
    () =>
      Object.keys(groupData).reduce((acc, groupId) => {
        const group = groupData[groupId];
        if (group.accessible && group.active) {
          acc[groupId] = {
            ...group,
            children: group.children.filter((location) => location.accessible && location.active),
          };
        }
        return acc;
      }, {} as Record<string, WeaveLocationGroup>),
    [groupData]
  );

  const accessibleGroupIds = useMemo(() => Object.keys(accessibleGroupMap), [accessibleGroupMap]);
  const accessibleLocationIds = useMemo(() => Object.keys(accessibleLocationMap), [accessibleLocationMap]);
  const accessibleOrgIds = useMemo(() => Object.keys(orgData), [orgData]);

  const _setSelectedOrgId = useCallback(
    (orgId: string) => {
      if (user.current?.userID) {
        setLoginData(user.current.userID, { recentOrganizationId: orgId });
      }
      setSelectedOrgId(orgId);
    },
    [user.current?.userID, setSelectedOrgId]
  );

  const _setSelectedGroupId = useCallback(
    (groupId: string, { skipValidation = false }: { skipValidation?: boolean } = {}) => {
      const isGroupAccessible = !!accessibleGroupMap[groupId];

      if (skipValidation || isGroupAccessible) {
        setSelectedGroupId(groupId);
        setForcedGroupId(groupId);
      } else {
        /**
         * If the group is not accessible, we still want to set the forcedGroupId.
         * There is potential for the group to not be accessible, but the locations within it are,
         * we still might need the groupId for the non-accessible group.
         */
        setSelectedGroupId('');
        setForcedGroupId(groupId);
      }
    },
    [accessibleGroupMap, setSelectedGroupId, setForcedGroupId]
  );

  const _setSelectedLocationIds = useCallback(
    (locationIds: string[], { skipValidation = false }: { skipValidation?: boolean } = {}) => {
      if (skipValidation) {
        setSelectedLocationIds(locationIds);
        if (user.current?.userID) {
          setLoginData(user.current.userID, { lastLocationIds: locationIds });
        }

        // This is a bad path to go down because we select a random location and set the groupId and orgId based on it
        // We're skipping validation now because DCA has multiple groups within the same org
        const referenceLocation = locationIds[0]
          ? // allow for parent location to be selected
            accessibleLocationMap[locationIds[0]] || accessibleGroupMap[locationIds[0]]
          : undefined;
        const groupId = referenceLocation?.groupId;
        const orgId = referenceLocation?.orgId;
        if (groupId) {
          _setSelectedGroupId(groupId, { skipValidation: true });
          setForcedGroupId(groupId);
        }
        if (orgId) {
          _setSelectedOrgId(orgId);
        }

        return;
      }

      // Location ids must be accessible
      if (locationIds.some((id) => !accessibleLocationMap[id])) {
        console.warn('Setting an invalid or inaccessible location. AppScopeStore selections have been reset.');
        setSelectedLocationIds([]);
        _setSelectedGroupId('');
        setForcedGroupId('');
        _setSelectedOrgId('');
        return;
      }

      const locations = locationIds.map((id) => accessibleLocationMap[id]);
      const groupIds = new Set(locations.map((location) => location.groupId).filter(Boolean));

      if (groupIds.size > 1) {
        console.warn('Locations must belong to the same group. AppScopeStore selections have been reset.');
        setSelectedLocationIds([]);
        _setSelectedGroupId('');
        setForcedGroupId('');
        _setSelectedOrgId('');
        return;
      }

      if (user.current?.userID) {
        setLoginData(user.current.userID, { lastLocationIds: locationIds });
      }

      /**
       * If we can determine the orgId and groupId from a selected location,
       * set them as well
       */
      const referenceLocation = locationIds[0] ? accessibleLocationMap[locationIds[0]] : undefined;
      const groupId = referenceLocation?.groupId;
      const orgId = referenceLocation?.orgId;

      if (groupId) {
        _setSelectedGroupId(groupId);
        setForcedGroupId(groupId);
      }

      if (orgId) {
        _setSelectedOrgId(orgId);
      }

      setSelectedLocationIds(locationIds);
    },
    [
      accessibleLocationMap,
      user.current?.userID,
      _setSelectedGroupId,
      _setSelectedOrgId,
      setSelectedLocationIds,
      setForcedGroupId,
    ]
  );

  const getScopeName = useCallback(
    (scopeId: string, type?: 'location' | 'group' | 'org'): string => {
      if (type === 'location') {
        const location = locationData[scopeId] ?? accessibleLocationMap[scopeId];
        return location?.name ?? '';
      }
      if (type === 'group') {
        const group = groupData[scopeId] ?? accessibleGroupMap[scopeId];
        return group?.name ?? '';
      }
      if (type === 'org') {
        const org = orgData[scopeId];
        return org?.name ?? '';
      }

      const scope = accessibleLocationMap[scopeId] ?? accessibleGroupMap[scopeId] ?? orgData[scopeId];
      return scope?.name ?? '';
    },
    [locationData, groupData, orgData]
  );

  const isSingleTypeScope = useMemo(
    () => selectedLocationIds.length === 1 && groupData[forcedGroupId]?.locationType === 'single',
    [selectedLocationIds, groupData, forcedGroupId]
  );

  useShellSideEffect({ selectedLocationIds });

  // To memoize this state, everything within it has to be memoized
  const state = useMemo(() => {
    return {
      accessibleGroupIds,
      accessibleLocationIds,
      accessibleOrgIds,

      accessibleOrgData: orgData,
      /**
       * This is the source of truth for all location data.
       * It should only contain locations that the user has access to, never more than that.
       *
       * This completely depends on the input being passed to the store via `setScopeData`.
       */
      accessibleLocationData: accessibleLocationMap,
      accessibleGroupData: accessibleGroupMap,

      locationData,
      groupData,

      setLocationData: setLocationData,
      setGroupData: setGroupData,
      setOrgData: setOrgData,

      appendAccessibleLocationData: appendLocationData,
      appendAccessibleGroupData: appendGroupData,
      appendAccessibleOrgData: appendOrgData,

      selectedLocationIds,
      selectedGroupId,
      selectedOrgId,
      /**
       * All `selected-*` properties have to be accessible. `forcedGroupId` is different in that it may be inaccessible to the user.
       * This property reflects the groupId of selected locations, even if the group itself is not accessible to the user.
       * No further data about the group is available from the store.
       *
       * Both `selectedGroupId` and `forcedGroupId` and derived from the `selectedLocationIds`.
       * `selectedGroupId` may or may not be set (depending on access), but `forcedGroupId` will always be set.
       */
      forcedGroupId,

      setSelectedGroupId: _setSelectedGroupId,
      // Don't expose this function
      // setForcedGroupId,
      setSelectedOrgId: _setSelectedOrgId,
      setSelectedLocationIds: _setSelectedLocationIds,

      /**
       * @deprecated
       * This is kept for backwards compatibility.
       * Use `getScopeName` instead because it more accurately describes the function.
       */
      getLocationName: getScopeName,
      getScopeName,

      scopeHierarchy,
      setScopeHierarchy,
      appendScopeHierarchy,

      isSingleTypeScope,
    };
  }, [
    accessibleGroupIds,
    accessibleLocationIds,
    accessibleOrgIds,
    orgData,
    accessibleLocationMap,
    accessibleGroupMap,
    locationData,
    groupData,
    setLocationData,
    setGroupData,
    setOrgData,
    appendLocationData,
    appendGroupData,
    appendOrgData,
    selectedLocationIds,
    selectedGroupId,
    selectedOrgId,
    forcedGroupId,
    _setSelectedGroupId,
    _setSelectedOrgId,
    _setSelectedLocationIds,
    getScopeName,
    scopeHierarchy,
    setScopeHierarchy,
    appendScopeHierarchy,
    isSingleTypeScope,
  ]);

  return state;
};

export const useOnScopeChange = (effect: () => void, cleanup?: () => void) => {
  const didMount = useRef(false);
  const { selectedLocationIds } = useAppScopeStore();
  useEffect(() => {
    if (didMount.current) {
      effect();
    } else {
      didMount.current = true;
    }
    return cleanup;
  }, [selectedLocationIds]);
};
