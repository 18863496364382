import { Dispatch, SetStateAction, useRef } from 'react';
import { css } from '@emotion/react';
import { DeviceType_Enum, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesQueries } from '@frontend/api-devices';
import { useTranslation } from '@frontend/i18n';
import { useNavSize } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, Heading, Text, styles } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { CardsDragDrop } from './cards-drag-drop';

type Props = {
  deviceId: string;
  setShowDeviceLayoutPreview: Dispatch<SetStateAction<boolean>>;
};

export const LineKeyCards = ({ deviceId, setShowDeviceLayoutPreview }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { globalAvailableLocationIds: locationIds, settingsTenantLocation } = usePhoneSettingsShallowStore(
    'globalAvailableLocationIds',
    'settingsTenantLocation'
  );
  const containerRef = useRef<HTMLElement | null>(null);
  const navSize = useNavSize();
  const isMobile = navSize.isLte('medium');

  const { data: device } = DevicesQueries.useGetDevicesList<ListDevice | undefined>(
    {
      locationIds,
      deviceType: DeviceType_Enum.DESK_PHONE,
      tenantId: settingsTenantLocation?.phoneTenantId,
    },
    {
      select: ({ devices }) => {
        return devices.find((device) => device.deviceId === deviceId);
      },
    }
  );

  return (
    <section
      css={[
        containerStyles,
        css`
          ${!isMobile &&
          `
            border-right: 1px solid ${theme.colors.neutral20};
            width: ${theme.spacing(50)};
            min-width: ${theme.spacing(50)};
          `}
        `,
      ]}
    >
      <article css={{ padding: theme.spacing(3) }}>
        <Heading>{t('{{name}} Line Keys', { name: device?.name })}</Heading>
        <Text color='light'>
          {t('Extension {{number}}', { number: device?.sipProfiles?.[0].extension?.extensionNumber })}
        </Text>
      </article>
      {isMobile && <MobileLayoutButton setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} />}
      <article ref={containerRef} css={cardContainerStyles}>
        <CardsDragDrop containerRef={containerRef} />
      </article>
    </section>
  );
};

const MobileLayoutButton = ({
  setShowDeviceLayoutPreview,
}: {
  setShowDeviceLayoutPreview: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <Button
      iconName='view'
      css={{ marginLeft: theme.spacing(3), border: 0 }}
      onClick={() => {
        setShowDeviceLayoutPreview(true);
      }}
      size='large'
      variant='secondary'
    >
      {t('Preview Layout')}
    </Button>
  );
};

const cardContainerStyles = css`
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  overflow-y: auto;
  width: 100%;
`;

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${styles.smallScrollbar(theme)};
`;
