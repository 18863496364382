import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const mainContainerStyle = css({
  maxWidth: 700,
  background: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.light,
  padding: theme.spacing(2, 2, 5, 2),
  display: 'flex',
  flexDirection: 'column',
});

export const panelContainerStyle = css({
  height: 237,
  overflowY: 'auto',
});

export const textStyle = css({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: 'auto',
});
export const loaderStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

export const sendButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(1.2),
});
