import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'motion/react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation, i18next } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { PanelContent, PanelHeader, usePanelStore } from '@frontend/panel-engine';
import { usePopupBarManager } from '@frontend/popup-bar';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  IconButton,
  NotificationBadge,
  PlusIcon,
  MoreIcon,
  Tray,
  SearchField,
  useFormField,
  Text,
  Dot,
  usePopoverMenu,
  ModalControlModalProps,
  PopoverMenu,
  PopoverMenuItem,
  Listbox,
  useListboxState,
  styles,
  Heading,
  SwitchField,
  PrimaryButton,
  SecondaryButton,
  TextField,
  DropdownField,
  CaretDownAltIconSmall,
  Avatar,
  useTooltip,
  useForm,
  useAlert,
} from '@frontend/design-system';
import { useStrategy } from '../api/strategy';
import { trackingId } from '../tracking';
import { CategorizedChats, Chat, ChatListItem, StatusData, StatusDuration, TrayUser, UserStatusData } from '../types';
import { getUserFullName } from '../utils';
import { ChatMaintenance } from './chat-maintenance-ui';
import { ChatStatusIcon, PaddedContent } from './primitives';

type CategoryBlockProps = {
  title: string;
  chats: ChatListItem[];
  handleOpen: (chat: Chat) => void;
};

const variants = {
  open: {
    height: 'auto',
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      opacity: { duration: 0.3 },
      height: { duration: 0.1 },
    },
  },
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      ease: 'easeInOut',
      opacity: { duration: 0.15 },
      height: { duration: 0.3 },
    },
  },
};

const BLOCK_HEADER_HEIGHT = 65;

const ChatCategoryBlock = ({ title, chats, handleOpen }: CategoryBlockProps) => {
  const listboxProps = useListboxState('');
  const initialExpanded = localStorage.getItem(`chat-${title}-expanded`);
  const [isExpanded, setIsExpanded] = useState(initialExpanded ? initialExpanded === 'true' : true);
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hideSetStatus = getFeatureFlagValue('hide-team-chat-set-status-stream');
  const isStreamChatEnabled = getFeatureFlagValue('team-chat-use-stream');
  const { useThreadOpened } = useStrategy('chat');
  const markRead = useThreadOpened();

  // it should only hide for the stream users
  const shouldShowSetStatus = isStreamChatEnabled ? !hideSetStatus : true;

  useEffect(() => {
    localStorage.setItem(`chat-${title}-expanded`, JSON.stringify(isExpanded));
  }, [isExpanded]);

  const unreadCount = chats.reduce((acc, chat) => acc + chat.unreadCount, 0);

  return (
    <section
      css={{
        minHeight: BLOCK_HEADER_HEIGHT,
        height: 'fit-content',
        ':not(:last-child)': {
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        },
        padding: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
          <Text as='span' color='light' size='medium' css={{ paddingLeft: theme.spacing(4) }}>
            {title}
          </Text>
          <AnimatePresence>
            {unreadCount > 0 && !isExpanded ? (
              <motion.div exit={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.05 }}>
                <NotificationBadge css={{ background: theme.colors.primary50 }} truncateCount>
                  {unreadCount}
                </NotificationBadge>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
        <IconButton
          size='small'
          label=''
          onClick={() => setIsExpanded((prev) => !prev)}
          trackingId={trackingId({ component: 'tray', context: `expand-collapse-${title.toLowerCase()}` })}
          css={{
            margin: theme.spacing(0, 4),
          }}
        >
          <CaretDownAltIconSmall
            css={{
              transform: isExpanded ? 'rotate(180deg)' : 'none',
              transition: 'transform 300ms',
            }}
          />
        </IconButton>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div variants={variants} animate='open' initial='closed' exit='closed' style={{ overflowY: 'auto' }}>
            <Listbox
              {...listboxProps}
              onSelect={(e) => {
                const chat = chats.find((chat) => chat.id === (e as string));
                if (chat) {
                  handleOpen(chat);
                  if (!isStreamChatEnabled && chat.hasUnread) {
                    chat?.messages && markRead({ messages: chat?.messages });
                  }
                }
              }}
              css={{ overflow: 'auto', height: '100%' }}
            >
              {chats.map(({ id, recipients, name, hasUnread, unreadCount, status }) => (
                <ChatCategoryItem
                  key={id}
                  id={id}
                  title={name || recipients.map((rec) => getUserFullName(rec)).join(', ')}
                  status={status}
                  hasUnread={hasUnread}
                  unreadCount={unreadCount}
                  showSetStatus={shouldShowSetStatus}
                />
              ))}
            </Listbox>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

type CategoryItemProps = {
  id: string;
  title: string;
  showSetStatus: boolean;
  status?: UserStatusData;
  hasUnread?: boolean;
  unreadCount?: number;
};

const ChatCategoryItem = ({
  id,
  title,
  status,
  showSetStatus,
  unreadCount = 0,
  hasUnread = false,
}: CategoryItemProps) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top', trigger: 'hover' });

  return (
    <Listbox.Option
      value={id}
      css={{ padding: theme.spacing(1, 4) }}
      trackingId={trackingId({ component: 'tray', context: `chat-item` })}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', height: theme.spacing(4), width: 250, position: 'relative' }}
      >
        {hasUnread && <Dot css={{ left: `-${theme.spacing(3)}` }} color='primary' />}
        {!hasUnread && status?.isOnline && <Dot css={{ left: `-${theme.spacing(3)}` }} color='success' />}
        <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
          <Text as='span' weight={hasUnread ? 'bold' : 'regular'} css={styles.truncate}>
            {title}
          </Text>
          {showSetStatus && status?.status && (
            <>
              <span {...triggerProps}>
                <ChatStatusIcon />
              </span>
              <Tooltip {...tooltipProps}>{status.status}</Tooltip>
            </>
          )}
          {unreadCount > 0 ? (
            <NotificationBadge css={{ background: theme.colors.primary50 }} truncateCount>
              {unreadCount}
            </NotificationBadge>
          ) : null}
        </div>
      </div>
    </Listbox.Option>
  );
};

const NewMessageButton = ({
  handleCreateNewMessage,
  handleCreateNewGroupMessage,
}: {
  handleCreateNewMessage: () => void;
  handleCreateNewGroupMessage: () => void;
}) => {
  const { t } = useTranslation('chat');
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu();
  return (
    <>
      <IconButton {...getTriggerProps()} label={t('New Message')}>
        <PlusIcon />
      </IconButton>
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem
          {...getItemProps({
            index: 0,
            onClick: handleCreateNewMessage,
          })}
          trackingId={trackingId({ component: 'tray', context: 'new-direct-message' })}
        >
          {t('New Direct Message')}
        </PopoverMenuItem>
        <PopoverMenuItem
          {...getItemProps({ index: 1, onClick: handleCreateNewGroupMessage })}
          trackingId={trackingId({ component: 'tray', context: 'new-group-message' })}
        >
          {t('New Group Message')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};

const MoreActionsButton = ({ setView }: { setView: (view: 'list' | 'settings' | 'status') => void }) => {
  const { t } = useTranslation('chat');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hideSetStatus = getFeatureFlagValue('hide-team-chat-set-status-stream');
  const isStreamChatEnabled = getFeatureFlagValue('team-chat-use-stream');

  // it should only hide for the stream users
  const shouldShowSetStatus = isStreamChatEnabled ? !hideSetStatus : true;

  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu();

  return (
    <>
      <IconButton {...getTriggerProps()} label={t('More actions')}>
        <MoreIcon />
      </IconButton>
      <PopoverMenu {...getMenuProps()}>
        {shouldShowSetStatus && (
          <PopoverMenuItem
            {...getItemProps({ index: 0, onClick: () => setView('status') })}
            trackingId={trackingId({ component: 'tray', context: 'set-status' })}
          >
            {t('Set Status')}
          </PopoverMenuItem>
        )}
        <PopoverMenuItem
          {...getItemProps({ index: 1, onClick: () => setView('settings') })}
          trackingId={trackingId({ component: 'tray', context: 'chat-settings' })}
        >
          {t('Chat Settings')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};

type ChatListViewProps = {
  chats: CategorizedChats;
  name: string;
  handleCreateNewMessage: () => void;
  handleCreateNewGroupMessage: () => void;
  handleOpenMessage: (chat: Chat) => void;
  status: StatusData;
  setView: (view: 'list' | 'settings' | 'status') => void;
};

export const ChatListView = ({
  chats,
  name,
  status,
  handleCreateNewMessage,
  handleCreateNewGroupMessage,
  handleOpenMessage,
  setView,
}: ChatListViewProps) => {
  const { t } = useTranslation('chat');
  const field = useFormField({ type: 'text' });
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hideSetStatus = getFeatureFlagValue('hide-team-chat-set-status-stream');
  const isStreamChatEnabled = getFeatureFlagValue('team-chat-use-stream');

  const shouldShowSetStatus = isStreamChatEnabled ? !hideSetStatus : true;

  const filteredChats = {
    mostRecent: chats.mostRecent.filter((chat) => chat.name?.toLowerCase().includes(field.value.toLowerCase())),
    groupChats: chats.groupChats.filter((chat) => chat.name?.toLowerCase().includes(field.value.toLowerCase())),
    threadChats: chats.threadChats.filter((chat) => chat.name?.toLowerCase().includes(field.value.toLowerCase())),
    directMessages: chats.directMessages.filter((chat) => chat.name?.toLowerCase().includes(field.value.toLowerCase())),
  };

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        gap: theme.spacing(1),
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
          padding: theme.spacing(1, 4),
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <Avatar name={name} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text weight='bold'>{name}</Text>
          {shouldShowSetStatus && (
            <Text color='light' size='small'>
              {status.status ? status.status : t('No Status')}
            </Text>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center', padding: theme.spacing(0, 4) }}>
        <div style={{ flexGrow: 1 }}>
          <SearchField
            {...field}
            name='search'
            label='Search'
            data-trackingid={trackingId({ component: 'tray', context: 'search' })}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <NewMessageButton
            handleCreateNewMessage={handleCreateNewMessage}
            handleCreateNewGroupMessage={handleCreateNewGroupMessage}
          />
          <MoreActionsButton setView={setView} />
        </div>
      </div>
      <div style={{ overflowY: 'auto', zIndex: 0 }}>
        <ChatCategoryBlock title={t('Most Recent')} chats={filteredChats.mostRecent} handleOpen={handleOpenMessage} />
        <ChatCategoryBlock title={t('Group Chats')} chats={filteredChats.groupChats} handleOpen={handleOpenMessage} />
        <ChatCategoryBlock
          title={t('Direct Messages')}
          chats={filteredChats.directMessages}
          handleOpen={handleOpenMessage}
        />
      </div>
    </div>
  );
};

export const ChatSettingsView = ({
  onCancel,
  settings,
  saveSettings,
}: {
  onCancel: () => void;
  settings: { sound: boolean; popup: boolean };
  saveSettings: (settings: { sound?: boolean; popup?: boolean }) => void;
}) => {
  const { t } = useTranslation('chat');

  const { formProps, getFieldProps } = useForm({
    fields: {
      sound: { type: 'switch', value: settings.sound },
      popup: { type: 'switch', value: settings.popup },
    },
    onSubmit: saveSettings,
  });

  return (
    <form
      {...formProps}
      css={{ display: 'grid', gridTemplateRows: '1fr auto', height: '100%', padding: theme.spacing(0, 4, 4) }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Heading level={3} css={{ marginTop: 0 }}>
          {t('Chat Settings')}
        </Heading>
        <div
          style={{
            display: 'grid',
            gridTemplateAreas: '"label switch" "description ."',
            gridTemplateColumns: 'auto 100px',
          }}
        >
          <Text as='div' css={{ gridArea: 'label' }}>
            {t('Notification Sound')}
          </Text>
          <SwitchField label='' {...getFieldProps('sound')} css={{ gridArea: 'switch', justifySelf: 'end' }} />
          <Text color='light' css={{ gridArea: 'description' }}>
            {t('This allows for sound alerts when you receive Team Chat notifications.')}
          </Text>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateAreas: '"label switch" "description ."',
            gridTemplateColumns: 'auto 100px',
          }}
        >
          <Text as='div' css={{ gridArea: 'label' }}>
            {t('Notification Popup')}
          </Text>
          <SwitchField label='' {...getFieldProps('popup')} css={{ gridArea: 'switch', justifySelf: 'end' }} />
          <Text color='light' css={{ gridArea: 'description' }}>
            {t('This allows for popup notifications to appear when you receive new messages in Team Chat.')}
          </Text>
        </div>
      </div>
      <div style={{ display: 'flex', gap: theme.spacing(1), justifyContent: 'end' }}>
        <SecondaryButton css={{ width: 'auto' }} onClick={onCancel}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          css={{ width: 'auto' }}
          type='submit'
          trackingId={trackingId({ component: 'tray', context: 'edit-chat-settings-save' })}
        >
          {t('Save')}
        </PrimaryButton>
      </div>
    </form>
  );
};

const timeOptions = [
  { label: i18next.t('1 hour'), value: 'hour' },
  { label: i18next.t('Today'), value: 'today' },
  { label: i18next.t('This week'), value: 'week' },
  { label: i18next.t("Don't clear"), value: 'never' },
];

export const ChatStatusView = ({
  status,
  saveStatus,
  onCancel,
}: {
  status: StatusData;
  saveStatus: (status: { status?: string; duration?: string }) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('chat');

  const { formProps, getFieldProps } = useForm({
    fields: {
      status: { type: 'text', value: status?.status ?? '' },
      duration: { type: 'dropdown', value: status?.statusDuration || StatusDuration.never },
    },
    onSubmit: saveStatus,
  });

  return (
    <form
      css={{ display: 'grid', gridTemplateRows: '1fr auto', height: '100%', padding: theme.spacing(0, 4, 4) }}
      {...formProps}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Heading css={{ marginTop: 0 }} level={3}>
          {t('Set Status')}
        </Heading>
        <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          <TextField {...getFieldProps('status')} label={t('Status')} />
          <DropdownField {...getFieldProps('duration')} label={t('Clear status after')}>
            {timeOptions.map((option) => (
              <DropdownField.Option value={option.value} key={option.value}>
                {option.label}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </div>
      </div>
      <div style={{ display: 'flex', gap: theme.spacing(1), justifyContent: 'end' }}>
        <SecondaryButton css={{ width: 'auto' }} onClick={onCancel}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          type='submit'
          css={{ width: 'auto' }}
          trackingId={trackingId({ component: 'tray', context: 'edit-status-save' })}
        >
          {t('Save')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export const ChatTrayComponent = ({
  showHeader = true,
  user,
  ...props
}: Omit<ChatListViewProps, 'setView' | 'name' | 'status'> & {
  showHeader?: boolean;
  user: TrayUser;
}) => {
  const { t } = useTranslation('chat');
  const [view, setView] = useState<'list' | 'settings' | 'status'>('list');
  const [statusData, setStatusData] = useState<StatusData>({
    status: '',
    statusDuration: StatusDuration.never,
    statusExp: 0,
  });
  const [settingsData, setSettingsData] = useState({
    sound: false,
    popup: false,
  });

  const { useGetUserStatus, useGetSettings, useSaveSettings, useSaveStatus } = useStrategy('chat');
  const alerts = useAlert();
  const getUserStatus = useGetUserStatus();
  const saveStatus = useSaveStatus();
  const getSettings = useGetSettings();
  const saveSettings = useSaveSettings();

  useEffect(() => {
    const fetchData = async () => {
      const status = await getUserStatus(user.userID);
      const settings = await getSettings();
      setStatusData(status);
      setSettingsData(settings);
    };
    fetchData();
  }, [user]);

  const handleSaveStatus = async (status: { status?: string; duration?: string }) => {
    try {
      await saveStatus({
        status: status.status ?? '',
        duration: (status.duration as StatusDuration) ?? StatusDuration.never,
      });
      alerts.success(t('Status saved'));
      setStatusData(status);
      setView('list');
    } catch {
      alerts.error(t('Error saving status'));
    }
  };

  const handleSaveSettings = async (values: { sound?: boolean; popup?: boolean }) => {
    try {
      await saveSettings({ sound: values.sound ?? false, popup: values.popup ?? false });
      alerts.success(t('Settings saved'));
      setSettingsData({ sound: values.sound ?? false, popup: values.popup ?? false });
      setView('list');
    } catch {
      alerts.error(t('Error saving settings'));
    }
  };

  return (
    <>
      {showHeader && (
        <PanelHeader css={{ padding: theme.spacing(3, 3, 0) }}>
          <Heading css={{ margin: 0 }}>{t('Team Chat')}</Heading>
        </PanelHeader>
      )}
      <PanelContent
        css={css`
          padding: 0;
        `}
      >
        {view === 'list' && statusData && (
          <ChatListView {...props} name={getUserFullName(user)} status={statusData} setView={setView} />
        )}
        {view === 'settings' && (
          <ChatSettingsView
            settings={settingsData}
            saveSettings={handleSaveSettings}
            onCancel={() => setView('list')}
          />
        )}
        {view === 'status' && statusData && (
          <ChatStatusView status={statusData} saveStatus={handleSaveStatus} onCancel={() => setView('list')} />
        )}
      </PanelContent>
    </>
  );
};

export const ChatTrayModalContents = ({
  chats,
  user,
  showHeader = true,
}: {
  chats: ChatListViewProps['chats'];
  user: TrayUser;
  showHeader?: boolean;
}) => {
  const { addPopup } = usePopupBarManager<Chat>();
  const handleCreateNewMessage = () => {
    addPopup({ id: 'new', recipients: [], messages: [], isPrivate: true, type: 'chat' });
    usePanelStore.getState().panelCloseFunctions['teamchat']?.();
  };

  const handleCreateNewGroupMessage = () => {
    addPopup({ id: 'new', recipients: [], messages: [], isPrivate: false, type: 'chat' });
    usePanelStore.getState().panelCloseFunctions['teamchat']?.();
  };

  const handleOpenMessage = (chat: Chat) => {
    usePanelStore.getState().panelCloseFunctions['teamchat']?.();

    // Smoothing out the transition, and ensuring the modal is closed before opening the chat
    setTimeout(() => {
      addPopup(chat);
    }, 100);
  };
  return (
    <ChatTrayComponent
      chats={chats}
      user={user}
      handleCreateNewMessage={handleCreateNewMessage}
      handleCreateNewGroupMessage={handleCreateNewGroupMessage}
      handleOpenMessage={handleOpenMessage}
      showHeader={showHeader}
    />
  );
};

export const ChatTrayModal = ({
  modalProps,
  chats,
  user,
}: {
  modalProps: ModalControlModalProps;
  chats: ChatListViewProps['chats'];
  user: TrayUser;
}) => {
  return (
    <Tray {...modalProps} css={{ padding: 0 }}>
      <ChatTrayModalContents user={user} chats={chats} />
    </Tray>
  );
};

/**
 * This will be the smart component that queries for data
 *
 * If a `chats` prop is passed in, this component will use that data instead of querying for it
 */
export const ChatTray = ({
  modalProps,
  chats: initialChats,
  user,
}: {
  modalProps: ModalControlModalProps;
  chats?: ChatListViewProps['chats'];
  user: TrayUser;
}) => {
  // if no `chats` prop is passed, query for chats
  const { data } = useLocalizedQuery({ queryKey: ['chats', 'list'], queryFn: fetchChats, enabled: !initialChats });

  const chats = initialChats ??
    data ?? {
      mostRecent: [],
      groupChats: [],
      threadChats: [],
      directMessages: [],
    };

  return <ChatTrayModal user={user} modalProps={modalProps} chats={chats} />;
};

const fetchChats = async () => {
  return {
    mostRecent: [],
    groupChats: [],
    threadChats: [],
    directMessages: [],
  };
};

export const ChatTrayContents = ({ chats: initialChats, user }: { chats?: CategorizedChats; user: TrayUser }) => {
  return <ChatTrayContentsDataLayer chats={initialChats} user={user} />;
};

const ChatTrayContentsDataLayer = (props: { user: TrayUser; chats?: CategorizedChats }) => {
  const { t } = useTranslation('chat');
  const { useGetConversations } = useStrategy('chat');
  const getConversations = useGetConversations();
  const chats = getConversations();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isTeamChatInMaintenance = getFeatureFlagValue('team-chat-maintenance-window');

  if (isWeaveUser()) {
    return (
      <PaddedContent css={{ padding: theme.spacing(4) }}>Weave users do not have access to Team Chat</PaddedContent>
    );
  }

  if (isTeamChatInMaintenance) {
    return <ChatMaintenance />;
  }

  if (!chats) {
    return <PaddedContent css={{ padding: theme.spacing(4) }}>{t('Loading chats...')}</PaddedContent>;
  }

  return <ChatTrayModalContents chats={chats} showHeader={false} user={props.user} />;
};
