import { GetOfficeHoursExceptionsForMultipleLocationsType } from './types';

export const queryKeys = {
  base: ['schedule'] as const,
  officeExceptions: (req: GetOfficeHoursExceptionsForMultipleLocationsType['input']) => [
    ...queryKeys.base,
    'officeExceptions',
    req,
  ],
};
