import { css } from '@emotion/react';
import { InvoiceWithPayoutDescriptor, InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { Chips } from '@frontend/chips';
import { formatDate } from '@frontend/date';
import { CardBrand } from '@frontend/payments-card-brand';
import { CheckIcon, MinusIcon, TableColumnConfig } from '@frontend/design-system';
import { InvoiceStatusChip } from '../';
import { paymentTypeMap } from '../../../hooks';
import { GetLocationName, formatCentsToCurrency, getPaymentMethod } from '../../../utils';

type GenerateInvoiceColumnOptions = {
  showPayoutDescriptor: boolean;
};

const isSubmittedPayment = (invoice: InvoiceModel): boolean =>
  !!invoice.payment &&
  (invoice.status !== InvoiceStatus.Unpaid ||
    (invoice.status === InvoiceStatus.Unpaid &&
      (invoice.payment.status === 'PENDING' || invoice.payment.status === 'SUCCEEDED')));

export const generateColumns =
  ({ showPayoutDescriptor }: GenerateInvoiceColumnOptions) =>
  (
    getLocationName: GetLocationName,
    showLocationName: boolean,
    forPrint = false
  ): TableColumnConfig<InvoiceWithPayoutDescriptor>[] =>
    [
      {
        id: 'recorded',
        Header: 'Recorded',
        accessor: (invoice) => invoice,
        cellRenderer: (invoice) => (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            `}
          >
            {invoice.payment && invoice.payment.recordedAt ? (
              <CheckIcon color='success' />
            ) : (
              <MinusIcon color='default' />
            )}
          </div>
        ),
        width: 100,
        cellAlign: 'center',
        disableSortBy: true,
      },
      {
        id: 'location',
        Header: 'Location Name',
        accessor: (invoice: InvoiceModel) => invoice.locationId,
        cellRenderer: (locationId: string) =>
          forPrint ? (
            getLocationName(locationId ?? '')
          ) : (
            <Chips.LocationChip
              css={css`
                max-width: none;
              `}
            >
              {getLocationName(locationId ?? '')}
            </Chips.LocationChip>
          ),
        disableSortBy: true,
        omit: !showLocationName,
      },
      {
        id: 'person',
        Header: 'Customer Name',
        accessor: (invoice) => invoice?.person?.name,
        width: 150,
        disableSortBy: true,
        cellConfig: {
          showHoverLabel: true,
        },
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: (invoice) => invoice,
        cellRenderer: (invoice) => <InvoiceStatusChip invoice={invoice} />,
        width: 100,
        disableSortBy: true,
      },
      {
        id: 'billedAmount',
        Header: 'Amount',
        accessor: (invoice) => (invoice.billedAmount ? formatCentsToCurrency(invoice.billedAmount) : ''),
        width: 120,
      },
      {
        id: 'billedAt',
        Header: 'Creation Date',
        width: 120,
        accessor: (invoice) => (invoice.billedAt ? formatDate(invoice.billedAt, 'MMM D, YYYY') : ''),
      },
      {
        id: 'paidAmount',
        Header: 'Paid Amount',
        accessor: (invoice) =>
          invoice.payment && isSubmittedPayment(invoice) && invoice.payment.paidAmount
            ? formatCentsToCurrency(invoice.payment.paidAmount)
            : '',
        width: 120,
      },
      {
        id: 'paidAt',
        Header: 'Paid Date',
        accessor: (invoice) =>
          invoice.payment && isSubmittedPayment(invoice) && invoice.payment.paidAt
            ? formatDate(invoice.payment.paidAt, 'MMM D, YYYY')
            : '',
        width: 120,
      },
      {
        id: 'fee',
        Header: 'Fees',
        accessor: (invoice) =>
          invoice?.payment && isSubmittedPayment(invoice) && formatCentsToCurrency(invoice.payment.fee),
        width: 100,
        disableSortBy: true,
      },
      {
        id: 'net',
        Header: 'Net',
        accessor: (invoice) =>
          invoice?.payment && isSubmittedPayment(invoice) && formatCentsToCurrency(invoice.payment.net),
        width: 100,
        disableSortBy: true,
      },
      {
        id: 'origin',
        Header: 'Payment Type',
        accessor: (invoice) => invoice?.payment && paymentTypeMap[invoice.payment.origin],
        width: 150,
        disableSortBy: true,
        cellConfig: {
          showHoverLabel: true,
        },
      },
      {
        id: 'paymentMethod',
        Header: 'Payment Method',
        accessor: (invoice) => invoice,
        cellRenderer: (invoice: InvoiceModel) => {
          const paymentType = invoice.payment?.paymentType;
          const cardBrand = invoice.payment?.paymentDetails.brand;
          const last4 = invoice.payment?.paymentDetails.lastFour;
          return <CardBrand brand={getPaymentMethod(paymentType, cardBrand)} last4={last4} />;
        },
        maxWidth: 180,
        disableSortBy: true,
      },
      {
        id: 'providerName',
        Header: 'Provider Name',
        accessor: (invoice: InvoiceModel) => invoice.providerName,
        disableSortBy: true,
      },
      {
        id: 'patientId',
        Header: 'Customer Id',
        accessor: (invoice) => invoice?.person?.id,
        width: 375,
        disableSortBy: true,
      },
      {
        id: 'payoutDescriptor',
        Header: 'Payout Descriptor',
        accessor: (invoice) => invoice.payoutDescriptor ?? '',
        disableSortBy: true,
        omit: !showPayoutDescriptor,
      },
    ];
