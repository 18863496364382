import { useCallback } from 'react';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { serviceName } from '../service';
import { GetValidityIO, ServiceQueries, SetValidityIO } from '../types';

export type SetValidityUpdateContext = {
  previousData?: GetValidityIO['output'];
  updatedData?: GetValidityIO['output'];
};

/**
 * This hook is intended to be used by react-query mutation hooks internally in api libraries.
 * Other use cases should use the corresponding mutations instead.
 */
export const useSMSNumberV1QueryUpdaters = () => {
  const queryUpdaters = useSchemaQueryUpdaters<ServiceQueries>(serviceName);

  // Add custom query updaters for this service here
  const setValidity = useCallback(
    ({ phoneNumber, valid }: Pick<SetValidityIO['input'], 'phoneNumber' | 'valid'>): SetValidityUpdateContext => {
      let previousData: GetValidityIO['output'] | undefined;
      let updatedData: GetValidityIO['output'] | undefined;
      queryUpdaters.updateQuery({
        endpointName: 'GetValidity',
        queryFilters: {
          predicate: ({ queryKey }) => {
            const queryKeyNumber = queryKey[2]?.phoneNumber;
            if (!queryKeyNumber) return false;
            return queryKeyNumber === formatPhoneNumberE164(phoneNumber);
          },
        },
        updater: (oldData) => {
          if (oldData && !previousData) {
            previousData = oldData;
          }
          const newData = {
            ...oldData,
            valid,
          };
          if (!updatedData) {
            updatedData = newData;
          }
          return newData;
        },
      });
      return {
        previousData,
        updatedData,
      };
    },
    [queryUpdaters.updateQuery, formatPhoneNumberE164]
  );

  return {
    ...queryUpdaters,
    setValidity,
  };
};
