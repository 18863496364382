import { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Modal,
  EmojiButton,
  TextField,
  ModalControlModalProps,
  Text,
  Button,
  useAlert,
  useControlledField,
  NakedUl,
} from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider';

interface UserStatusSettingsProps {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
}

const MODAL_WIDTH = 500;

export const UserStatusSettings = ({ modalProps, closeModal }: UserStatusSettingsProps) => {
  const { t } = useTranslation('team-chat');
  const currentUser = useTeamChatSelector((ctx) => ctx.user);
  const updateCurrentUser = useTeamChatSelector((ctx) => ctx.updateCurrentUser);
  const isUpdatingUser = useTeamChatSelector((ctx) => ctx.isUpdatingUser);
  const [statusText, setStatusText] = useState<string>(currentUser?.status?.status?.text ?? '');
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(currentUser?.status?.status?.emoji ?? null);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const statusTextField = useControlledField({
    type: 'text',
    value: statusText,
    onChange: (value) => setStatusText(value),
    touched: true,
  });
  const { error } = useAlert();

  const preDefinedStatus = [
    { emoji: '🗓️', text: t('In a meeting') },
    { emoji: '🚌', text: t('Commuting') },
    { emoji: '🤒', text: t('Out sick') },
    { emoji: '🌴', text: t('Vacationing') },
  ];

  const onSave = async () => {
    try {
      if (currentUser) {
        await updateCurrentUser({
          ...currentUser,
          status: {
            presence: currentUser.status.presence,
            status: {
              duration: undefined,
              expiry: undefined,
              text: statusText,
              emoji: selectedEmoji ?? undefined,
            },
          },
        });
        closeModal();
      }
    } catch {
      error(t('Unable to set status at the moment, please try again later'));
    }
  };

  const clearProgress = () => {
    setStatusText('');
    setSelectedEmoji(null);
  };

  useEffect(() => {
    setStatusText(currentUser?.status?.status?.text ?? '');
    setSelectedEmoji(currentUser?.status?.status?.emoji ?? null);
    if (modalProps.show && textFieldRef.current) {
      /*
        Modal body get focused when the modalProps.show property is set to true. Hence, when we focus on the text field,
        it get overridden by the modal body focus. To avoid this, we are setting a timeout of 600ms to focus on
        the text field.
       */
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 600);
    }
  }, [modalProps.show]);

  return (
    <Modal minWidth={MODAL_WIDTH} {...modalProps} onClose={() => closeModal()}>
      <Modal.Header onClose={closeModal} closeBtnTrackingId='team-chat-2.0-close-user-status-modal'>
        {t('Set your status')}
      </Modal.Header>
      <Modal.Body>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          {modalProps.show && (
            <TextField
              fieldComponentProps={{ ref: textFieldRef }}
              // ref={textFieldRef}
              label=''
              startAdornment={
                selectedEmoji ? (
                  <Text role='img' size='large' css={emojiStartAdornmentStyle}>
                    {selectedEmoji}
                  </Text>
                ) : (
                  <EmojiButton onSelect={({ emoji }) => setSelectedEmoji(emoji)} css={emojiButtonStyle} />
                )
              }
              name=''
              placeholder={t(`What's your status?`)}
              {...statusTextField}
              css={textFieldStyles}
              clearable={!!statusText}
              value={statusText}
              endAdornment={
                !!(statusText || selectedEmoji) && (
                  <Button
                    variant='tertiary'
                    onClick={clearProgress}
                    trackingId='team-chat-2.0-clear-user-status-text-button'
                  >
                    {t('Clear')}
                  </Button>
                )
              }
            />
          )}
          <Text size='large' weight='bold' css={forYouTextStyle}>
            {t('For you')}
          </Text>
          <NakedUl>
            {preDefinedStatus.map(({ emoji, text }) => (
              <li key={`${emoji}-${text}`}>
                <Button
                  variant='secondary'
                  css={preDefinedStatusButtonStyle}
                  onClick={() => {
                    setStatusText(text);
                    setSelectedEmoji(emoji);
                  }}
                >
                  <Text size='large' css={predefinedEmojiStyle}>
                    {emoji}
                  </Text>
                  <Text css={preDefinedEmojiTextStyles}>{text}</Text>
                </Button>
              </li>
            ))}
          </NakedUl>
        </div>
      </Modal.Body>
      <Modal.Actions
        disablePrimary={
          !!statusText || !!selectedEmoji
            ? false
            : currentUser?.status.status.emoji || currentUser?.status.status.text
            ? false
            : true
        }
        onPrimaryClick={onSave}
        primaryLoading={isUpdatingUser}
        onSecondaryClick={closeModal}
        primaryLabel={t('Save')}
        secondaryLabel={t('cancel')}
        primaryTrackingId='team-chat-2.0-save-user-status-button'
        secondaryTrackingId='team-chat-2.0-cancel-user-status-button'
      />
    </Modal>
  );
};

const preDefinedStatusButtonStyle = css({
  justifyContent: 'flex-start',
  padding: theme.spacing(0.25, 1),
  border: 'none',
  width: '100%',
  span: {
    display: 'flex',
  },
});

const textFieldStyles = css({
  width: '100%',
  padding: 0,

  input: {
    padding: 0,
  },
});

const emojiStartAdornmentStyle = css({
  minWidth: '32px',
  marginLeft: theme.spacing(1),
  fontSize: theme.fontSize(22),
  display: 'flex',
  justifyContent: 'center',
});

const preDefinedEmojiTextStyles = css({ color: theme.colors.neutral70, textTransform: 'none' });
const predefinedEmojiStyle = css({ minWidth: '24px', fontSize: theme.fontSize(20) });

const forYouTextStyle = css({ color: theme.colors.neutral50, margin: theme.spacing(2, 0, 1, 0) });

const emojiButtonStyle = css({
  minWidth: '32px',
  width: '32px',
  height: '32px',
  marginLeft: theme.spacing(1),
});
