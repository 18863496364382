import { css } from '@emotion/react';
import { Entry } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { motion } from 'motion/react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Avatar,
  Button,
  Chip,
  formatDate,
  PopoverDialog,
  Text,
  Truncated,
  useAlert,
  usePopoverDialog,
} from '@frontend/design-system';
import { TextWithTooltip } from '../../../components';
import { PersonNotesComponent } from '../../../components/PersonSelector/PersonNotesComponent';
import { getRelativeTime } from '../../../helpers';
import { QUICK_FILL_TRACKING_IDS } from '../../../tracking-ids';
import { OptOutIcon } from '../components/opt-out-icon';

interface Props {
  recipient: Entry;
  locationId: string;
  highlightItem: boolean;
  onHighlightItemComplete?: () => void;
}

const trackingIds = QUICK_FILL_TRACKING_IDS.manageListTab;

export const QuickFillListItem = ({ recipient, locationId, highlightItem, onHighlightItemComplete }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const alert = useAlert();
  const { setPersonId } = useContactPanelShallowStore('setPersonId');
  const addEditNotePopover = usePopoverDialog<HTMLDivElement>({ placement: 'bottom-start' });

  const { note, person, personId, validTo, createdAt } = recipient;
  const firstName = person?.firstName?.trim() || '';
  const lastName = person?.lastName?.trim() || '';
  const isOptOut = person?.receiveSms === false;

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    ScheduleQueries.useMutateDeleteQuickFillRecipient(locationId);

  const { mutateAsync: updateQuickFillRecipient, isLoading: isUpdatingQuickFillRecipient } =
    ScheduleQueries.useMutateUpdateQuickFillRecipient();

  const handleSaveNoteClick = (newNote: string) => {
    updateQuickFillRecipient({
      personId: personId || '',
      note: newNote,
      locationId: locationId,
    })
      .then(() => {
        addEditNotePopover.close();
        alert.success(t('Quick Fill Recipient updated successfully'));
      })
      .catch(() => alert.error(t('Error Updating Quick Fill Recipient')));
  };

  const handleRemove = () => {
    if (!personId) return;

    deleteAsync(personId)
      .then(() => alert.success(t('Quick Fill Recipient Deleted')))
      .catch(() => alert.error(t('Error Deleting Quick Fill Recipient')));
  };

  return (
    <>
      <div css={[styles.root, highlightItem && styles.newItemAnimation]}>
        <motion.div
          css={[styles.container, isDeleting && styles.deletingItem]}
          animate={
            highlightItem && {
              backgroundColor: [theme.colors.white, theme.colors.tangerine10, theme.colors.white],
            }
          }
          transition={{ duration: 3, ease: 'easeInOut', times: [0, 0.5, 1] }}
          onAnimationComplete={() => highlightItem && onHighlightItemComplete?.()}
        >
          <div css={styles.summary}>
            <Avatar
              size='large'
              firstName={firstName}
              lastName={lastName}
              data-trackingid={trackingIds.recipientAvatar}
              onClick={() => setPersonId(personId || '')}
            />
            <div css={{ overflow: 'hidden' }}>
              <div css={styles.details}>
                <Truncated as={Text}>
                  <strong>{firstName}</strong> {lastName}
                </Truncated>
                {isOptOut && <OptOutIcon />}
              </div>
              <div
                {...addEditNotePopover.getTriggerProps()}
                data-trackingid={note ? trackingIds.recipientEditNote : trackingIds.recipientAddNote}
                css={{ display: 'flex', gap: theme.spacing(1), cursor: 'pointer' }}
              >
                <Text size='small' color={note ? 'default' : 'subdued'} css={{ wordBreak: 'break-word' }}>
                  {note ? note : t('Add a note')}
                </Text>
                <Icon
                  css={styles.noteIcon}
                  className='note-add-edit-icon'
                  name={note ? 'edit-small' : 'plus-small'}
                  color='subdued'
                />
              </div>
              <TextWithTooltip
                size='small'
                color='subdued'
                tooltipText={formatDate(createdAt, 'MM/DD/YY h:mm A')}
                css={{ cursor: 'default' }}
              >
                {getRelativeTime(createdAt)}
              </TextWithTooltip>
            </div>
          </div>
          <div css={styles.rightSection}>
            {validTo && (
              <Chip tooltipLabel={t(`Appointment on {{date}}`, { date: formatDate(validTo, 'MM/DD/YY') })}>
                <Icon name='calendar-small' color='primary' />
              </Chip>
            )}
            <Button
              loading={isDeleting}
              variant='secondary'
              iconName='x-small'
              label={t('Remove Quick Fill Recipient')}
              trackingId={trackingIds.removeRecipientButton}
              onClick={handleRemove}
            />
          </div>
        </motion.div>
      </div>
      <PopoverDialog
        {...addEditNotePopover.getDialogProps()}
        css={{ width: 388, padding: 0, borderRadius: theme.borderRadius.small }}
      >
        <PersonNotesComponent
          isLoading={isUpdatingQuickFillRecipient}
          heading={note ? t('Edit Note') : t('Add Note')}
          onPrimaryClick={handleSaveNoteClick}
          onSecondaryClick={addEditNotePopover.close}
          primaryButtonText={t('Save')}
          note={note}
        />
      </PopoverDialog>
    </>
  );
};

const styles = {
  newItemAnimation: css`
    animation: heightAnimation 1s ease-in-out;
    @keyframes heightAnimation {
      0% {
        opacity: 0;
        max-height: 0;
        transform: scale(0.95);
        overflow: hidden;
      }
      100% {
        opacity: 1;
        max-height: 150px;
        transform: scale(1);
      }
    }
  `,
  deletingItem: css`
    opacity: 0.5;
  `,
  root: css`
    width: 100%;
    border-top: 1px solid ${theme.colors.neutral20};
  `,
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(0.5, 1)};
    margin: ${theme.spacing(0.5)};
    transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    :hover {
      background-color: ${theme.colors.neutral5};
      .note-add-edit-icon {
        opacity: 1; // show add/edit icon on hover
      }
    }
  `,
  summary: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    overflow: hidden;
  `,
  details: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
  `,
  rightSection: css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
  `,
  noteIcon: css`
    opacity: 0;
    flex-shrink: 0;
    align-self: center;
    transition: opacity 0.3s ease-in-out;
  `,
};
