import { useMemo } from 'react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { LeaveConversation } from '../../common/channel-actions/leave-conversation';
import { InfoWithEditor } from '../../common/info-with-editor/info-with-editor';
import { ConversationSettingsContainer } from './container';

export const About = ({ conversationId }: { conversationId: TeamChatTypes.Conversation['channelId'] }) => {
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const updateConversation = useTeamChatSelector((ctx) => ctx.updateConversation);
  const isUpdatingConversation = useTeamChatSelector((ctx) => ctx.isUpdatingConversation);
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const { t } = useTranslation('team-chat');
  const conversation = useMemo(() => helpers.getConversation(conversationId), [helpers, conversationId]);

  const conversationNames = useMemo(
    () => conversations?.groups.map(({ name }) => name.toLowerCase()) ?? [],
    [conversations]
  );

  if (!conversation) {
    return <Text>Unable to find selected conversation.</Text>;
  }

  const updateConversationName = async (name: string) => {
    await updateConversation({ conversationId: conversation.channelId, updates: { name } });
  };

  const updateTopic = async (topic: string) => {
    await updateConversation({ conversationId: conversation.channelId, updates: { topic } });
  };

  const updateDescription = async (description: string) => {
    await updateConversation({ conversationId: conversation.channelId, updates: { description } });
  };

  return (
    <ConversationSettingsContainer>
      <InfoWithEditor
        title={t('Name:')}
        value={conversation.name}
        placeholder={t('Channel Name')}
        canEdit={conversation?.type === 'Group'}
        onClickSave={updateConversationName}
        className='channel-settings-item'
        isTextArea={false}
        comment={t(
          'Channels are where conversations happen around a topic. Use a name that is easy to find and understand.'
        )}
        startAdornment={'#'}
        canDisableSave
        conversationNames={conversationNames}
        editButtonTrackingId='team-chat-2.0-edit-channel-name-button'
        saveButtonTrackingId='team-chat-2.0-edit-channel-name-modal-save-button'
        cancelButtonTrackingId='team-chat-2.0-edit-channel-name-modal-cancel-button'
        maxLength={64}
      />
      <InfoWithEditor
        title={t('Topic:')}
        value={conversation.topic}
        placeholder={t('Add topic')}
        canEdit
        onClickSave={updateTopic}
        className='channel-settings-item'
        comment={t('Define the focus of your discussion with {{name}}.', {
          name: conversation?.name ?? 'Untitled Conversation',
        })}
        editButtonTrackingId='team-chat-2.0-edit-topic-button'
        saveButtonTrackingId='team-chat-2.0-edit-edit-topic-modal-save-button'
        cancelButtonTrackingId='team-chat-2.0-edit-edit-topic-name-modal-cancel-button'
        isUpdatingConversation={isUpdatingConversation}
      />
      <InfoWithEditor
        title={t('Description:')}
        value={conversation.description}
        placeholder={t('Add description')}
        canEdit
        onClickSave={updateDescription}
        className='channel-settings-item'
        comment={t(
          'Let people know what {{name}} is focused on right now (ex. a project milestone). Descriptions are always visible in the header.',
          { name: conversation.name ?? 'Untitled Conversation' }
        )}
        editButtonTrackingId='team-chat-2.0-edit-description-button'
        saveButtonTrackingId='team-chat-2.0-edit-description-modal-save-button'
        cancelButtonTrackingId='team-chat-2.0-edit-description-modal-cancel-button'
        maxLength={120}
        isUpdatingConversation={isUpdatingConversation}
      />
      {/* TODO: commenting it now, will update after discussion with Product team */}
      {/* <InfoWithEditor title='Managed By:' value='' canEdit className='channel-settings-item' /> */}
      {conversation.type === 'Group' && (
        <InfoWithEditor
          title={t('Created by:')}
          value={t('{{createdBy}} on {{createdAt}}', {
            createdBy: conversation.createdBy,
            createdAt: conversation.createdAt,
          })}
          className='channel-settings-item'
          editButtonTrackingId='team-chat-2.0-edit-created-by-button'
          saveButtonTrackingId='team-chat-2.0-edit-created-by-modal-save-button'
          cancelButtonTrackingId='team-chat-2.0-edit-created-by-modal-cancel-button'
        />
      )}
      {conversation.type === 'Group' && <LeaveConversation conversationId={conversation.channelId} />}
    </ConversationSettingsContainer>
  );
};
