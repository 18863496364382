import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';

export interface FlyoutMessageMenuProps {
  message: TeamChatTypes.Message;
  onDelete: (messageId: string) => void;
  onEdit: (message: TeamChatTypes.Message) => Promise<void>;
}

export const FlyoutMessageMenuActions = ({ message, onDelete, onEdit }: FlyoutMessageMenuProps) => {
  const { t } = useTranslation('team-chat');
  const {
    close: closePopoverMenu,
    getMenuProps,
    getTriggerProps,
  } = usePopoverMenu({
    placement: 'bottom-end',
  });

  const edit = (message: TeamChatTypes.Message) => {
    onEdit(message).then(() => closePopoverMenu());
  };

  return (
    <>
      <IconButton
        {...getTriggerProps()}
        label={t('More')}
        showLabelOnHover
        size='small'
        trackingId='team-chat-v2-more-message-actions'
        style={{ padding: theme.spacing(0.5) }}
      >
        <Icon name='more' />
      </IconButton>

      <PopoverMenu {...getMenuProps()} css={styles.popover}>
        {/* TODO:: enable following options when we are ready */}
        {/* <PopoverMenuItem onClick={() => handleClick(onReminderSet)}>{t('Remind me about this')}</PopoverMenuItem> */}
        {/* <PopoverMenuItem onClick={() => handleClick(onMarkRead)}>{t('Mark as unread')}</PopoverMenuItem> */}

        <>
          <PopoverMenuItem onClick={() => edit(message)} trackingId='team-chat-2.0-edit-message-button'>
            {t('Edit Message')}
          </PopoverMenuItem>
          <PopoverMenuItem
            destructive
            onClick={() => {
              onDelete(message.id);
              closePopoverMenu();
            }}
            trackingId='team-chat-2.0-delete-message-button'
          >
            {t('Delete Message...')}
          </PopoverMenuItem>
        </>
      </PopoverMenu>
    </>
  );
};

const styles = {
  popover: css`
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${theme.shadows.floating};
    margin: ${theme.spacing(0.5, 2)};
    padding: 0;
  `,
};
