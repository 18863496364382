import { useCollectPaymentMultiContext } from '../../collect-payment-multi.context';
import { CollectPaymentMultiFlow } from '../../collect-payment-multi.types';

export type StepNavigationBaseProps = object;
export type StepNavigationComponent<P extends StepNavigationBaseProps> = React.ComponentType<{
  body: React.ComponentType<P>;
}>;

export type StepRenderComponent<P extends StepNavigationBaseProps> = React.ComponentType<P>;

export type StepNavigationSwitchProps<P extends StepNavigationBaseProps> = {
  generic: StepNavigationComponent<P>;
  body: StepRenderComponent<P>;
} & {
  [K in CollectPaymentMultiFlow]?: StepNavigationComponent<P>;
};

export const StepNavigationSwitch = <P extends StepNavigationBaseProps>({
  generic: Generic,
  select: Select,
  pay: Pay,
  create: Create,
  insert: Insert,
  body,
}: StepNavigationSwitchProps<P>) => {
  const { flow } = useCollectPaymentMultiContext();

  if (typeof Select == 'function' && flow === 'select') {
    return <Select body={body} />;
  }

  if (typeof Pay == 'function' && flow === 'pay') {
    return <Pay body={body} />;
  }
  if (typeof Create == 'function' && flow === 'create') {
    return <Create body={body} />;
  }

  if (typeof Insert == 'function' && flow === 'insert') {
    return <Insert body={body} />;
  }

  return <Generic body={body} />;
};
