import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { InfoHoverIcon } from '../../info-hover-icon';

export type StatItemData = {
  infoHoverText?: string;
  label: string;
  value?: number | string;
};

export type StatItemProps = StatItemData & {
  subAlignment?: 'bottom' | 'right';
  valueFontSize?: number | string;
};

export const StatItem = ({
  infoHoverText,
  label,
  subAlignment = 'right',
  value = 0,
  valueFontSize = theme.font.size.h1,
}: StatItemProps) => {
  return (
    <div className={subAlignment} css={styles.wrapper}>
      <Text as='span' className='value' textAlign='left' weight='bold' style={{ fontSize: valueFontSize }}>
        {value}
      </Text>
      <div css={styles.labelWithTip}>
        <Text as='span' color='subdued' size='medium' textAlign='left'>
          {label}
        </Text>
        {infoHoverText && <InfoHoverIcon text={infoHoverText} />}
      </div>
    </div>
  );
};

const styles = {
  labelWithTip: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
    justify-content: flex-start;
  `,

  wrapper: css`
    &.right {
      align-items: baseline;
      display: flex;
      gap: ${theme.spacing(2)};
    }

    span {
      display: block;
    }

    .value {
      line-height: 1;
    }
  `,
};
