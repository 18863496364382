import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { Chart } from '@frontend/charts';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedButton, Text, styles as dsStyles } from '@frontend/design-system';

type BackgroundConfig = {
  gradientDegree?: number;
  gradientEnd?: string;
  gradientStart?: string;
};

interface Props {
  backgroundConfig?: BackgroundConfig;
  iconName: IconName;
  isLoading?: boolean;
  onClick?: () => void;
  subTitle: string;
  title: string;
  trackingId: string;
  trend?: false | number[];
  value?: number | string;
}

const defaultBackgroundConfig: BackgroundConfig = {
  gradientDegree: 135,
  gradientEnd: '#94BFFF', // Color not available in the theme
  gradientStart: theme.colors.primary40,
};

export const WidgetCard = ({
  backgroundConfig,
  iconName,
  isLoading,
  onClick,
  subTitle,
  title,
  trackingId,
  trend,
  value = 0,
  ...rest
}: Props & HTMLAttributes<HTMLButtonElement>) => {
  const background = { ...defaultBackgroundConfig, ...backgroundConfig };

  return (
    <NakedButton
      css={styles.button}
      onClick={onClick}
      style={{
        background: `linear-gradient(${background?.gradientDegree}deg, ${background?.gradientStart}, ${background?.gradientEnd})`,
      }}
      trackingId={trackingId}
      {...rest}
    >
      <div css={styles.flexGroup}>
        <Icon color='white' name={iconName} />
        <Text css={dsStyles.truncate} color='white' size='small' textAlign='left' weight='bold'>
          {title}
        </Text>
      </div>
      <div css={styles.contentWrapper}>
        <div>
          <Text css={styles.majorValue} textAlign='left' weight='bold'>
            {value}
          </Text>
          <Text color='white' size='small' textAlign='left'>
            {subTitle}
          </Text>
        </div>

        {trend && (
          <div style={{ height: 40, width: 170 }}>
            <Chart.TinyAreaChart data={trend} color={theme.colors.white} />
          </div>
        )}
        <ContentLoader show={isLoading} size='small' />
      </div>
    </NakedButton>
  );
};

const styles = {
  button: css`
    border-radius: ${theme.borderRadius.medium};
    overflow: hidden;
    padding: ${theme.spacing(2)};
    position: relative;
    width: 100%;
  `,

  contentWrapper: css`
    display: flex;
    justify-content: space-between;

    p {
      line-height: 1;
    }
  `,

  flexGroup: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
    margin-bottom: ${theme.spacing(0.5)};
  `,

  majorValue: css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSize(32)};
  `,
};
