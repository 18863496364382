import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import {
  MenuOption,
  MenuOption_MenuNavigation,
} from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-tree/ivr_service.pb';
import { cloneDeep, isEqual } from 'lodash-es';
import { omit } from 'lodash-es';
import { useMutation, useQueryClient } from 'react-query';
import { PhoneTreeApi, PhoneTreeTypes, InstructionsTypes } from '@frontend/api-phone-tree';
import { CoreACLs, hasACL } from '@frontend/auth-helpers';
import { Page } from '@frontend/components';
import { i18next, useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useAppScopeStore, useOnScopeChange, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  FieldProps,
  Heading,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  TextLink,
  useForm,
  useFormField,
  UseFormReturnType,
  XIcon,
  useAlert,
} from '@frontend/design-system';
import { SectionHeader, SettingSection } from '../components/call-queue/primitives';
import { CascadingComponents } from '../components/cascading-components';
import {
  FetchDeptInstructions,
  generateFallbackNodes,
} from '../components/department/phone-routing-section/routing-section/fallback-nodes';
import { EditableHeader, nameConfigBase } from '../components/editable-header';
import { MediaPicker } from '../components/media-picker';
import { ExtensionSettings } from '../components/phone-tree/phone-tree-extension';
import {
  InstructionAction,
  mainReducer,
  optionReducer,
  PartialMenuOption,
} from '../components/phone-tree/phone-tree-reducers';
import {
  DropdownCascadingNode,
  InstructionDropdownCascadingNode,
  InstructionPicker,
  PhoneTreeDepartmentSwitch,
  PhoneTreeInstructionSwitch,
  PhoneTreeSubTreeSwitch,
} from '../components/phone-tree/phone-tree-switch';
import { FormLayout } from '../layouts/form';
import { queryKeys } from '../query-keys';

export const formConfigBase = {
  name: {
    type: 'text',
    required: true,
  },
  greetingMedia: { type: 'dropdown' },
  allowExtensionDialing: { type: 'switch' },
} as const;

export const PhoneTree = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const query = useLocalizedQuery({
    queryKey: queryKeys.phoneTree(id),
    queryFn: ({ queryKey }) => {
      /**
       * The 4th element in our queryKey is the actual phone tree id
       * {locationId}/phone/callQueue/{id}
       */
      return PhoneTreeApi.getDetails({ ivrMenuId: queryKey[3] });
    },
  });

  const { data: phoneTreeList } = useLocalizedQuery({
    queryKey: queryKeys.phoneTrees(),
    queryFn: () => PhoneTreeApi.list({}),
    select: (data: PhoneTreeTypes.ListPhoneTrees['output']) => {
      const phoneData = data?.data;
      if (!phoneData) {
        return [];
      }
      return phoneData.sort((a, b) => (!a.name || !b.name ? 0 : a.name.localeCompare(b.name)));
    },
  });

  const extensionDialingEnabled = phoneTreeList?.find((item) => item.ivrMenuId === id)?.extensionDialingEnabled;
  const navigate = useNavigate();
  useOnScopeChange(() => {
    navigate({ to: '/portal/phone/phone-tree' });
  });
  useEffect(() => {
    if (!!query.data) {
      form.seedValues({
        name: query.data.name,
        greetingMedia: query.data.greetingMediaId,
        allowExtensionDialing: extensionDialingEnabled,
      });
    }
  }, [query.data, extensionDialingEnabled]);

  const mutation = useMutation(
    (payload: PhoneTreeTypes.UpdatePhoneTree['input']) => {
      return PhoneTreeApi.update(payload);
    },
    {
      onMutate: async (newTree) => {
        const previousTree = queryClient.getQueryData<PhoneTreeTypes.PhoneTreeModel>([
          locationId,
          ...queryKeys.phoneTree(id),
        ]);
        await queryClient.cancelQueries([locationId, ...queryKeys.phoneTree(id)]);
        queryClient.setQueryData([locationId, ...queryKeys.phoneTree(id)], newTree);
        return { previousTree };
      },
      onError: (_err, _vars, context) => {
        queryClient.setQueryData([locationId, ...queryKeys.phoneTree(id)], context?.previousTree);
        alert.error(t('Failed to update phone tree'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([locationId, ...queryKeys.phoneTree(id)]);
        queryClient.invalidateQueries([locationId, ...queryKeys.phoneTrees()]);
        alert.success(t('Successfully updated phone tree'));
      },
    }
  );

  const form = useForm({
    computeChangedValues: true,
    fields: formConfigBase,
  });

  const { mutateAsync: updateExtension } = useMutation({
    mutationFn: (req: PhoneTreeTypes.UpdateExtension['input']) => PhoneTreeApi.updateExtension(req),
    onSuccess: (_res, req) => {
      if (!req.extensionDialingEnabled) {
        alert.success(t('Dial by Extension deactivated successfully.'));
      } else {
        alert.success(t('Dial by Extension activated successfully.'));
      }
      return queryClient.invalidateQueries([locationId, ...queryKeys.phoneTrees()]);
    },
    onError: () => {
      alert.error(t('Failed to update dial by extension.'));
    },
  });

  useEffect(() => {
    if (
      !!query.data &&
      form.values.name !== '' &&
      !isEqual(extensionDialingEnabled, form.values.allowExtensionDialing)
    ) {
      updateExtension({
        ivrMenuId: id,
        extensionDialingEnabled: form.values.allowExtensionDialing || false,
      });
      form.seedValues({
        ...form.values,
        allowExtensionDialing: form.values.allowExtensionDialing,
      });
    }
  }, [form.values.allowExtensionDialing, query.data]);

  const onSave = (options: PartialMenuOption[]) => {
    const payload = { ...query.data };
    payload.menuOptions = options as MenuOption[];
    payload.name = form.values.name;
    payload.greetingMediaId = form.values.greetingMedia;

    const req = omit(payload, 'extensionDialingEnabled');
    mutation.mutate(req);
  };

  const onSaveName = (name: string) => {
    const payload = { ...query.data };
    payload.name = name;
    const req = omit(payload, 'extensionDialingEnabled');

    mutation.mutate(req);
  };

  if (query.isSuccess) {
    return (
      <Page
        maxWidth='100%'
        breadcrumbs={[
          { label: t('Phone') },
          { label: t('Phone Trees'), to: '/portal/phone/phone-tree' },
          { label: query.data?.name ?? t('Untitled') },
        ]}
        title={<Header form={form} onSave={onSaveName} />}
      >
        <SettingsProvider options={query.data.menuOptions as PartialMenuOption[]}>
          <PhoneTreeComponent form={form} onSave={onSave} isSaving={mutation.isLoading} />
        </SettingsProvider>
      </Page>
    );
  }

  return <SpinningLoader />;
};

const SwitchUserStyles = (props: { children: ReactNode }) => {
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const hasPhoneTreeWrite = hasACL(locationId, CoreACLs.PHONE_TREE_WRITE);

  return (
    <div
      css={css`
        opacity: ${!hasPhoneTreeWrite ? '0.5' : '1'};
        pointer-events: ${!hasPhoneTreeWrite ? 'none' : 'auto'};
      `}
    >
      {props.children}
    </div>
  );
};

export const PhoneTreeComponent = ({
  form,
  onSave,
  isSaving,
}: {
  form: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>;
  onSave: (options: PartialMenuOption[]) => void;
  isSaving: boolean;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
  const { rows } = useContext(PhoneTreeSettingsContext);

  const [isFormValid, setIsFormValid] = useState(true);

  return (
    <SwitchUserStyles>
      <FormLayout>
        <FormLayout.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            padding: theme.spacing(2, 0),
          }}
        >
          <SettingsBody form={form} setIsFormValid={setIsFormValid} />
        </FormLayout.Body>
        <FormLayout.Controls>
          <PrimaryButton
            trackingId='phn-portal-phonetree-btn-save'
            style={{
              width: 150,
              margin: theme.spacing(1, 0),
              placeSelf: 'end',
            }}
            /**
             * Ideally we want to be able to detect unsaved changes, and enable the button accordingly.
             * This is not yet possible due to the complexity of the phone tree model.
             */
            // disabled={isSaving || !form.isComplete || !form.changedValues}
            disabled={isSaving || !isFormValid}
            onClick={() => {
              onSave(rows);
            }}
          >
            {/* This shows a spinning loader when we save */}
            {isSaving ? (
              <SpinningLoader
                css={{
                  div: {
                    borderColor: 'white transparent transparent transparent !important',
                  },
                }}
                size='small'
              />
            ) : (
              t('Save')
            )}
          </PrimaryButton>
        </FormLayout.Controls>
      </FormLayout>
    </SwitchUserStyles>
  );
};

interface IHeader {
  // This is the source of truth for everything in the component
  form: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>;
  onSave: (name: string) => void;
}

const Header = ({ form: { getFieldProps }, onSave }: IHeader) => {
  const field = getFieldProps('name') as FieldProps<typeof nameConfigBase, 'name'>;
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });

  return (
    <SwitchUserStyles>
      <EditableHeader formField={field} onSave={onSave} label={t('Phone Tree')} />
      <Text color='light'>{t('Set up the routing actions for each dial number below.')}</Text>
    </SwitchUserStyles>
  );
};

type ContextType = { rows: PartialMenuOption[]; setRows: Dispatch<SetStateAction<PartialMenuOption[]>> };
const PhoneTreeSettingsContext = createContext<ContextType>({} as ContextType);

const SettingsProvider = ({ children, options }: { children: ReactNode; options: PartialMenuOption[] }) => {
  const [rows, setRows] = useState<PartialMenuOption[]>(options);

  return <PhoneTreeSettingsContext.Provider value={{ rows, setRows }}>{children}</PhoneTreeSettingsContext.Provider>;
};

interface ISettingsBody {
  form: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>;
  setIsFormValid: (isValid: boolean) => void;
}

/**
 * This component relies on capturing all user input with the useForm hook.
 * Everything should use the form as the source of truth.
 */
const SettingsBody = ({ form: { getFieldProps, values }, setIsFormValid }: ISettingsBody) => {
  const mediaField = useMemo(() => getFieldProps('greetingMedia'), [values.greetingMedia, getFieldProps]);
  const extensionEnabled = useMemo(
    () => getFieldProps('allowExtensionDialing'),
    [values.allowExtensionDialing, getFieldProps]
  );
  return (
    <>
      <GreetingSettings field={mediaField} />
      <PhoneTreeSettings setIsFormValid={setIsFormValid} />
      <ExtensionSettings field={extensionEnabled} />
    </>
  );
};

interface IGreetingSettings {
  field: FieldProps<typeof formConfigBase, 'greetingMedia'>;
}

const GreetingSettings = ({ field }: IGreetingSettings) => {
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  return (
    <SettingSection>
      <SectionHeader>
        <Heading level={2}>{t('Phone Tree Greeting')}</Heading>
        <Text size='large' color='light'>
          {t(
            'Select a greeting with the available options. This message will play when callers reach your phone tree.'
          )}
        </Text>
      </SectionHeader>
      <MediaPicker
        {...field}
        locationId={locationId}
        requestedTypes={{ standard: false, custom: true }}
        allowAddOption
        label={t('Select Greeting')}
      />
    </SettingSection>
  );
};

const defaultDisabledInstructions = [InstructionsTypes.Instruction.VoicemailBox, InstructionsTypes.Instruction.IVRMenu];

export const InstructionSetOptionGroup = memo(
  ({
    updateInstructionOnOption,
    row,
    index,
    isPhoneTree,
    onRemove,
    helperText,
    showNotConfiguredOption = true,
    dropdownNodes,
    disabledNodes, // These options will be shown disabled in case if instruction is set in the PDS/backend but not allowed for any modification in the frontend.
    setIsFormValid,
  }: {
    updateInstructionOnOption: (
      state: PhoneTreeTypes.AnyInstructionWithIds | InstructionsTypes.AnyInstruction,
      index: number
    ) => void;
    row: PhoneTreeTypes.AnyInstructionWithIds | InstructionsTypes.AnyInstruction;
    helperText?: string;
    showNotConfiguredOption?: boolean;
    index: number;
    isPhoneTree?: boolean;
    onRemove: (index: number) => void;
    dropdownNodes: DropdownCascadingNode;
    disabledNodes?: InstructionsTypes.Instruction[];
    setIsFormValid?: (isValid: boolean) => void;
  }) => {
    // const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
    const [state, dispatch] = useReducer(mainReducer, row as PhoneTreeTypes.AnyInstructionWithIds);

    const onInstructionChange = (
      instruction: InstructionsTypes.Instruction | PhoneTreeTypes.MenuTypes.SubTree | PhoneTreeTypes.MenuTypes.Navigate
    ) => {
      dispatch({ type: 'pick-instruction', instruction });
    };

    const onValueChange = (payload: InstructionAction) => {
      dispatch({ type: 'edit-instruction', payload });
    };

    const shouldDisableInstruction = useCallback(
      (stateType: InstructionsTypes.Instruction) => {
        return disabledNodes ? disabledNodes?.includes(stateType) : defaultDisabledInstructions.includes(stateType);
      },
      [disabledNodes]
    );

    // This effect is only to sync changes to the DialOption
    useEffect(() => {
      if (state) {
        updateInstructionOnOption(state, index);
      }
    }, [state]);

    return (
      <div
        style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: theme.spacing(2) }}
        key={`Instruction-${index}`}
      >
        {/* Commenting this out until we have designs for this experience. Currently there will be no way to remove an instruction. */}
        {/* <IconButton label={t('Delete option')} onClick={() => onRemove(index)}>
          <DeleteIcon css={{ transform: 'rotateZ(180deg)' }} />
        </IconButton> */}
        <div style={{ display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap' }}>
          <CascadingComponents node={dropdownNodes}>
            {({ node, setNext }) =>
              !!state &&
              (node.id === 'instruction' ? (
                <InstructionPicker
                  index={index}
                  disabled={shouldDisableInstruction(state.type)}
                  helperText={helperText}
                  showNotConfiguredOption={showNotConfiguredOption}
                  key={node.id}
                  node={node as InstructionDropdownCascadingNode}
                  setNext={setNext}
                  options={node.nodes as InstructionDropdownCascadingNode[]}
                  value={state.type}
                  onChange={onInstructionChange}
                  onRemove={() => onRemove(index)}
                />
              ) : (
                <PhoneTreeInstructionSwitch
                  key={node.id}
                  node={node}
                  setNext={setNext}
                  state={state as PhoneTreeTypes.AnyInstructionWithIds}
                  updateInstruction={onValueChange}
                  setIsFormValid={setIsFormValid}
                  isPhoneTree={isPhoneTree}
                />
              ))
            }
          </CascadingComponents>
        </div>
      </div>
    );
  }
);

const useDropdownOptions = () => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const departmentFlag = getFeatureFlagValue('departments');
  /**
   * These are all possible options, but whether the option is VISIBLE for selection is dictated by the `disabled` prop as defined in the nodeMap
   */
  let nodelist: FetchDeptInstructions[] = [
    InstructionsTypes.Instruction.CallGroup,
    InstructionsTypes.Instruction.CallQueue,
    InstructionsTypes.Instruction.DepartmentId,
    InstructionsTypes.Instruction.DataEndpoint,
    InstructionsTypes.Instruction.ForwardDevice,
    InstructionsTypes.Instruction.ForwardNumber,
    InstructionsTypes.Instruction.Hangup,
    InstructionsTypes.Instruction.InstructionSet,
    InstructionsTypes.Instruction.IVRMenu,
    InstructionsTypes.Instruction.Play,
    InstructionsTypes.Instruction.VoicemailPrompt,
    PhoneTreeTypes.MenuTypes.SubTree,
    PhoneTreeTypes.MenuTypes.Navigate,
  ];

  const hideOptions: FetchDeptInstructions[] = [InstructionsTypes.Instruction.IVRMenu];

  if (!departmentFlag) {
    nodelist = nodelist.filter((inst) => inst !== InstructionsTypes.Instruction.DepartmentId);
  }

  return generateFallbackNodes(
    { nodelist, disabledInstructions: hideOptions },
    i18next.t('phone-tree>>Routing Option', { ns: 'phone' })
  );
};

const SubTreeOptionGroup = memo(
  ({
    onInstructionChange,
    onValueChange: onSwitchChange,
    row,
    index,
    onRemove,
    dropdownNodes,
  }: {
    onInstructionChange: (state: any) => void;
    onValueChange: (state: PhoneTreeTypes.SubTreeMenuOption, index: number) => void;
    row: Partial<PhoneTreeTypes.SubTreeMenuOption>;
    index: number;
    onRemove: (index: number) => void;
    dropdownNodes: DropdownCascadingNode;
  }) => {
    // const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
    const [subTree, setSubTree] = useState<Partial<PhoneTreeTypes.PhoneTreeModel | undefined>>(row.subTree);

    const onValueChange = (val: Partial<PhoneTreeTypes.PhoneTreeModel>) => {
      setSubTree(val);
      const newRow = { ...row, SubTree: val } as PhoneTreeTypes.SubTreeMenuOption;
      onSwitchChange(newRow, index);
    };

    return (
      <div
        style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: theme.spacing(2) }}
        key={`Instruction-${index}`}
      >
        {/* Commenting this out until we have designs for this experience. Currently there will be no way to remove an instruction. */}
        {/* <IconButton label={t('Delete option')} onClick={() => onRemove(index)}>
          <DeleteIcon css={{ transform: 'rotateZ(180deg)' }} />
        </IconButton> */}
        <div style={{ display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap', alignItems: 'baseline' }}>
          <CascadingComponents node={dropdownNodes}>
            {({ node, setNext }) =>
              node.id === 'instruction' ? (
                <InstructionPicker
                  index={index}
                  disabled={true}
                  key={node.id}
                  node={node as InstructionDropdownCascadingNode}
                  setNext={setNext}
                  value={row.type}
                  options={node.nodes as InstructionDropdownCascadingNode[]}
                  onChange={onInstructionChange}
                  onRemove={() => onRemove(index)}
                />
              ) : (
                !!subTree && (
                  <PhoneTreeSubTreeSwitch
                    key={node.id}
                    node={node as InstructionDropdownCascadingNode}
                    setNext={setNext}
                    onChange={onValueChange}
                    state={subTree}
                  />
                )
              )
            }
          </CascadingComponents>
        </div>
      </div>
    );
  }
);

const NavigateOptionGroup = memo(
  ({
    onInstructionChange,
    row,
    index,
    onRemove,
    dropdownNodes,
  }: {
    onInstructionChange: (state: any) => void;
    onValueChange: (state: PhoneTreeTypes.NavigateMenuOption, index: number) => void;
    row: Partial<PhoneTreeTypes.NavigateMenuOption>;
    index: number;
    onRemove: (index: number) => void;
    dropdownNodes: DropdownCascadingNode;
  }) => {
    return (
      <div
        style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: theme.spacing(2) }}
        key={`Instruction-${index}`}
        data-trackingid='phone-portal-phoneTree-repeatGreeting-option'
      >
        {/* Commenting this out until we have designs for this experience. Currently there will be no way to remove an instruction. */}
        {/* <IconButton label={t('Delete option')} onClick={() => onRemove(index)}>
          <DeleteIcon css={{ transform: 'rotateZ(180deg)' }} />
        </IconButton> */}
        <div style={{ display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap', alignItems: 'baseline' }}>
          <CascadingComponents node={dropdownNodes}>
            {({ node, setNext }) =>
              node.id === 'instruction' && (
                <InstructionPicker
                  disabled={false}
                  index={index}
                  key={node.id}
                  node={node as InstructionDropdownCascadingNode}
                  setNext={setNext}
                  value={row.type}
                  options={node.nodes as InstructionDropdownCascadingNode[]}
                  onChange={onInstructionChange}
                  onRemove={() => onRemove(index)}
                />
              )
            }
          </CascadingComponents>
        </div>
      </div>
    );
  }
);

const DepartmentOptionGroup = memo(
  ({
    onInstructionChange,
    onValueChange: onSwitchChange,
    row,
    index,
    onRemove,
    dropdownNodes,
  }: {
    onInstructionChange: (state: any) => void;
    onValueChange: (state: PhoneTreeTypes.Department, index: number) => void;
    row: Partial<PhoneTreeTypes.Department>;
    index: number;
    onRemove: (index: number) => void;
    dropdownNodes: DropdownCascadingNode;
  }) => {
    // const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
    const [departmentOption, setDepartmentOption] = useState<Partial<PhoneTreeTypes.Department> | undefined>(row);

    const onValueChange = (val: Partial<PhoneTreeTypes.Department>) => {
      setDepartmentOption(val);
      const newRow = { ...row, departmentId: val.departmentId } as PhoneTreeTypes.Department;
      onSwitchChange(newRow, index);
    };

    return (
      <div
        style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: theme.spacing(2) }}
        key={`Instruction-${index}`}
      >
        {/* Commenting this out until we have designs for this experience. Currently there will be no way to remove an instruction. */}
        {/* <IconButton label={t('Delete option')} onClick={() => onRemove(index)}>
          <DeleteIcon css={{ transform: 'rotateZ(180deg)' }} />
        </IconButton> */}
        <div style={{ display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap', alignItems: 'baseline' }}>
          <CascadingComponents node={dropdownNodes}>
            {({ node, setNext }) =>
              node.id === 'instruction' ? (
                <InstructionPicker
                  index={index}
                  key={node.id}
                  node={node as InstructionDropdownCascadingNode}
                  setNext={setNext}
                  value={row.type}
                  options={node.nodes as InstructionDropdownCascadingNode[]}
                  onChange={onInstructionChange}
                  onRemove={() => onRemove(index)}
                />
              ) : (
                !!departmentOption && (
                  <PhoneTreeDepartmentSwitch
                    key={node.id}
                    node={node as InstructionDropdownCascadingNode}
                    setNext={setNext}
                    onChange={onValueChange}
                    state={departmentOption}
                  />
                )
              )
            }
          </CascadingComponents>
        </div>
      </div>
    );
  }
);

interface IDialOption {
  onChange: (state: PartialMenuOption, index: number) => void;
  options: { isInUse: boolean; Number: number }[];
  index: number;
  dialOption: PartialMenuOption;
  onRemove: (index: number) => void;
  setIsFormValid: (isValid: boolean) => void;
}

/**
 * This component represents a dial number and its associated routing options
 */
const DialOption = memo(({ onChange, options, index, dialOption, onRemove, setIsFormValid }: IDialOption) => {
  const [state, dispatch] = useReducer(optionReducer, dialOption);

  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });

  const dropdownProps = useFormField({ type: 'dropdown', value: dialOption?.number?.toString() }, [dialOption.number]);
  const dropdownNodes = useDropdownOptions();
  const onInstructionChange = useCallback(
    (instruction: any, index: any) => {
      if (instruction.type === 'DepartmentId') {
        dispatch({ type: 'change-to-department' });
      } else if (instruction.type === 'Navigate') {
        dispatch({ type: 'change-instruction-to-navigation' });
      } else if ('instructionSet' in state && instruction.type !== 'SubTree') {
        const newInstructionSet = cloneDeep(state.instructionSet) as PhoneTreeTypes.InstructionSet;
        newInstructionSet.instructions[index] = instruction;

        dispatch({ type: 'update-instruction', payload: newInstructionSet });
      } else if ('instructionSet' in state && instruction.type === 'SubTree') {
        //instr => sub
        dispatch({ type: 'change-instruction-to-subtree' });
      } else if ('subTree' in state && instruction.type !== 'SubTree') {
        // sub => instr
      }
    },
    [state, dispatch]
  );

  const onDepartmentChange = useCallback(
    (value: any) => {
      dispatch({ type: 'update-department', payload: value });
    },
    [dispatch]
  );

  const onChangeToInstruction = useCallback(
    (instruction: any) => {
      dispatch({ type: 'change-to-instruction', payload: instruction });
    },
    [dispatch]
  );

  const onSubTreeChange = useCallback(
    (value: any) => {
      dispatch({ type: 'update-subtree', payload: value });
    },
    [dispatch]
  );

  const onNavigateChange = useCallback(
    (value: any) => {
      return dispatch({ type: 'update-navigation', payload: value });
    },
    [dispatch]
  );

  const onRemoveInstruction = useCallback(
    (index: any) => {
      dispatch({ type: 'remove-instruction', payload: index });
    },
    [dispatch]
  );

  const onNumberChange = useCallback(
    (e: any) => {
      dropdownProps.onChange(e);
      dispatch({ type: 'update-number', payload: parseInt(e.value) });
    },
    [dropdownProps.onChange, dispatch]
  );

  // TODO: Figure out all rules for allowing fallback options
  const allowFallback =
    state.type === 'InstructionSet' &&
    state?.instructionSet?.instructions &&
    state?.instructionSet?.instructions?.length < 4 &&
    state.instructionSet?.instructions[state.instructionSet?.instructions.length - 1]?.type !==
      InstructionsTypes.Instruction.VoicemailPrompt;

  useEffect(() => {
    onChange(state, index);
  }, [state, index]);

  let content;

  /**
   * There are two types of OptionGroups - SubTree and InstructionSet.
   * They are separated because their model representations are different:
   * - SubTree - a whole PhoneTreeModel
   * - InstructionSet - a model with an array Instructions
   */
  if (state.type === 'SubTree') {
    content = (
      <SubTreeOptionGroup
        /**
         * We're using the entire state object as the key because that's the only reliable way to truly differentiate between states.
         */
        key={`${JSON.stringify(state)}=${index}`}
        index={0}
        row={state}
        onInstructionChange={onChangeToInstruction}
        onValueChange={onSubTreeChange}
        onRemove={() => onRemove(index)}
        dropdownNodes={dropdownNodes}
      />
    );
  } else if (state.type === 'DepartmentId') {
    content = (
      <DepartmentOptionGroup
        /**
         * We're using the entire state object as the key because that's the only reliable way to truly differentiate between states.
         */
        key={`${JSON.stringify(state)}=${index}`}
        index={0}
        row={state}
        onInstructionChange={onChangeToInstruction}
        onValueChange={onDepartmentChange}
        onRemove={() => onRemove(index)}
        dropdownNodes={dropdownNodes}
      />
    );
  } else if (state.type === 'Navigate' && state.navigate === MenuOption_MenuNavigation.REPEAT) {
    content = (
      <NavigateOptionGroup
        /**
         * We're using the entire state object as the key because that's the only reliable way to truly differentiate between states.
         */
        key={`${JSON.stringify(state)}=${index}`}
        index={0}
        row={state}
        onInstructionChange={onChangeToInstruction}
        onValueChange={onNavigateChange}
        onRemove={() => onRemove(index)}
        dropdownNodes={dropdownNodes}
      />
    );
  } else if (state.type === 'InstructionSet') {
    content = state.instructionSet?.instructions.map((row, index) => {
      return (
        <InstructionSetOptionGroup
          /**
           * We're using the entire row object as the key because that's the only reliable way to truly differentiate between rows besides the InstructionID.
           * A row might not have an InstructionID if it is a newly created row.
           */
          key={`${JSON.stringify(row)}=${index}`}
          index={index}
          row={row}
          updateInstructionOnOption={onInstructionChange}
          onRemove={onRemoveInstruction}
          dropdownNodes={dropdownNodes}
          setIsFormValid={setIsFormValid}
          isPhoneTree={true}
        />
      );
    });
  }

  return (
    <article
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        borderRadius: theme.borderRadius.small,
        padding: theme.spacing(3, 2),
        boxShadow: theme.shadows.floating,
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '125px 80% auto',
          gap: theme.spacing(2),
        }}
      >
        <DropdownField {...dropdownProps} onChange={onNumberChange} label={t('Dial Number')} name='dial-number'>
          {options.map((dial) => (
            <DropdownField.Option
              disabled={dial.isInUse && dial.Number !== parseInt(dropdownProps.value)}
              key={`dial-${dial.Number}`}
              value={dial.Number.toString()}
              searchValue={dial.Number.toString()}
            >
              {dial.Number}
            </DropdownField.Option>
          ))}
        </DropdownField>
        <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          {content}

          <TextLink
            style={{ width: 'fit-content' }}
            disabled={!allowFallback}
            onClick={() => {
              dispatch({ type: 'add-instruction' });
            }}
          >
            {t('Add Fallback Option')}
          </TextLink>
        </div>
        <IconButton label={t('Delete')} onClick={() => onRemove(index)}>
          <XIcon />
        </IconButton>
      </div>
    </article>
  );
});

/**
 * These are dial numbers that we allow users to select.
 *
 * Fancy math to order starting from 1 -> ... -> 9 -> 0
 */
const ALL_DIAL_NUMBERS = Array.from({ length: 10 }, (_, i) => ({ Number: (i + 1) % 10, isInUse: true }));

const PhoneTreeSettings: React.FC<{ setIsFormValid: (isValid: boolean) => void }> = ({ setIsFormValid }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
  const { rows, setRows } = useContext(PhoneTreeSettingsContext);

  const onChange = useCallback(
    (state: PartialMenuOption, index: number) => {
      if ('instructionSet' in state && state.instructionSet?.instructions.length === 0) {
        onRemove(index);
      } else {
        onEdit(state, index);
      }
    },
    [rows, setRows]
  );

  const onEdit = useCallback(
    (state: PartialMenuOption, index: number) => {
      setRows([...rows.slice(0, index), state, ...rows.slice(index + 1)]);
    },
    [rows, setRows]
  );

  const onRemove = useCallback(
    (index: any) => {
      setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
    },
    [rows, setRows]
  );

  // Mark the numbers that are already in use so that we can mark them as disabled
  const dialNumbers = useMemo(
    () =>
      ALL_DIAL_NUMBERS.map((dial) => {
        return {
          ...dial,
          isInUse: rows.some((row) => row.number === dial.Number),
        };
      }),
    [rows]
  );

  const allowAddDialOption = dialNumbers.some((dial) => !dial.isInUse);

  const addMenuOption = () => {
    // Add an empty instruction set if there are still available numbers
    const nextNumber = dialNumbers.find((dial) => !dial.isInUse);
    if (nextNumber) {
      setRows([
        ...rows,
        {
          number: nextNumber.Number,
          type: 'InstructionSet',
          instructionSet: { instructions: [{ type: 'CallGroup' }] },
        } as PhoneTreeTypes.InstructionSetMenuOption,
      ]);
    }
  };

  return (
    <SettingSection>
      <SectionHeader>
        <Heading level={2}>{t('Phone Tree')}</Heading>
        <Text size='large' color='light'>
          {t(
            'Add dial number and choose from the drop down where you want calls to go when someone selects that number.'
          )}
        </Text>
      </SectionHeader>
      <>
        {rows.map((row, i) => (
          <DialOption
            key={`Number:${row.number}`}
            options={dialNumbers}
            dialOption={row}
            index={i}
            onChange={onChange}
            onRemove={onRemove}
            setIsFormValid={setIsFormValid}
          />
        ))}
        {allowAddDialOption && rows.length === 0 && (
          <SecondaryButton style={{ width: 200 }} onClick={addMenuOption}>
            {t('Add Dial Number')}
          </SecondaryButton>
        )}
        {allowAddDialOption && rows.length > 0 && (
          <TextLink size='large' weight='bold' style={{ width: 'fit-content' }} onClick={addMenuOption}>
            {t('Add Dial Number')}
          </TextLink>
        )}
      </>
    </SettingSection>
  );
};
