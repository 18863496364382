import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { PaymentsTerminalController } from '@frontend/payments-terminal-controller';

type DiscoverReadersArgs = Parameters<typeof PaymentsTerminalController.discoverReaders>[number][number];

const generateQueryKey = (locationId: string | undefined) => ['PaymentsTerminalController.discoverReaders', locationId];

export const useDiscoverReaderQuery = ({ locationId, stripeLocationId, paymentsUrl }: Partial<DiscoverReadersArgs>) => {
  const readerData = useMemo(() => {
    if (!locationId || !stripeLocationId || !paymentsUrl) return;

    return {
      locationId,
      stripeLocationId,
      paymentsUrl,
    };
  }, [locationId, stripeLocationId, paymentsUrl]);

  return useQuery({
    queryKey: generateQueryKey(locationId),
    queryFn: () => {
      if (!readerData) {
        throw new Error('No reader location provided');
      }
      return PaymentsTerminalController.discoverReaders([readerData]);
    },
    staleTime: 5e3, // 5 seconds
    cacheTime: 60 * 60e3, // 60 minutes
    refetchOnWindowFocus: true,
    enabled: !!readerData,
  });
};

export const useInvalidateDiscoverReaderQuery = (locationId: string) => {
  const queryClient = useQueryClient();
  const queryKey = generateQueryKey(locationId);
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);
  return { invalidateQuery };
};
