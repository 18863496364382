import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { FaxHooks } from '@frontend/api-fax';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketFaxReadEventSubscription = () => {
  const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();

  return useWebsocketEventSubscription(NotificationType.FAX_READ_STATUS_CHANGE, () => {
    invalidateFaxInboxList();
    invalidateFaxCount();
  });
};
