import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { ShareInEmailNavigationSwitch } from './share-in-email/share-in-email-navigation-switch';

export const ShareInEmail = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.shareInEmail}>
      <ShareInEmailNavigationSwitch />
    </StepPerformanceWrapper>
  );
};
