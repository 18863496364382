export const CHAT_TOKEN_URL = '/team-chat/v1/token';
export const TEAM_CHAT_API_INVALIDATION_KEY = 'stream-chat-token';

export const USERS_QUERY_LIMIT = 30;

export const CHANNELS_QUERY_LIMIT = 30;

export const MESSAGES_QUERY_LIMIT = 25;

export const MAX_CHANNEL_NAME_LENGTH = 64;

export const MAX_FILE_SIZE = 10485760;

export const MIN_MODAL_WIDTH = 605;

export const DM_MODAL_HEIGHT = 500;

export const CHANNEL_MODAL_HEIGHT = 600;

export enum Steps {
  One = '1',
  Two = '2',
}

export const INITIAL_MESSAGE_ITEM_INDEX = 10000;

export enum ChannelSettingsController {
  about = 'about',
  notifications = 'notifications',
  channel = 'channel',
  members = 'members',
}

export const CHAT_LIST_WIDTH_EXPANDED = 434;
export const CHAT_LIST_WIDTH_COLLAPSED = 266;
export const CHAT_AREA_WIDTH = 434;

export enum ChatListItemTrackingIds {
  CHANNEL = 'team-chat-2.0-chat-list-item-channel-button',
  GROUP_DM = 'team-chat-2.0-chat-list-item-group-dm-button',
  DM = 'team-chat-2.0-chat-list-item-dm-button',
}

export const DEFAULT_STATUS_EMOJI_UNIFIED = '1f4ac';
