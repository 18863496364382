import { Empty } from '@weave/schema-gen-ts/dist/google/protobuf/empty.pb';
import { SetDemoLocationCustomizationsRequest } from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';
import {
  BlockPrice,
  BlockPriceByProductCodeResponse,
  Consumer,
  SalesforceAccountProductBundle,
} from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import dayjs from 'dayjs';
import { MutationOptions, UseQueryOptions } from 'react-query';
import { HttpError } from '@frontend/fetch';
import { useLocalizedQuery, useLocationDataShallowStore } from '@frontend/location-helpers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaIO, SchemaPackageService, SchemaSalesforce, SchemaSubscriptionManagerService } from '@frontend/schema';
import { useScopedQuery } from '@frontend/scope';
import {
  AccountAddOnQuotaByProductRequest,
  AccountAddOnQuotaByProductResponse,
  AccountBundleQuotaBySlugRequest,
  AccountBundleQuotaBySlugResponse,
  GetSalesforceBundleNameForMultiRequest,
  GetSalesforceBundleNameForMultiResponse,
  SalesforceAccountBySlugRequestQueryParams,
  SFQuotaProductCode,
} from './types';
import { SubscriptionsQueryKeys } from '.';

const consumer = Consumer.COMMX;
const productCode = 'BULK-TEXTING';

export const BULK_MESSAGE_QUOTA_LOCAL_STORAGE_KEY = 'bulkMessageQuota';

const DEFAULT_CACHE_AND_STALE_TIME_IN_HRS = 1000 * 60 * 60 * 24; //24hrs
const CACHE_AND_STALE_TIME_IN_HOUR = 1000 * 60 * 60; // 1HR

// trying to limit the number of calls since we are hitting an external service
const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  cacheTime: DEFAULT_CACHE_AND_STALE_TIME_IN_HRS,
  staleTime: DEFAULT_CACHE_AND_STALE_TIME_IN_HRS,
  retry: false,
};

type SchemaGetAccountBundle = SchemaIO<(typeof SchemaSalesforce)['AccountBundleBySlug']>;
export const useGetAccountBundle = ({ slug, enabled }: { slug: string; enabled: boolean }) => {
  const request: SchemaGetAccountBundle['input'] = {
    consumer,
    slug,
  };
  return useLocalizedQuery({
    ...queryOptions,
    queryKey: SubscriptionsQueryKeys.queryKeys.accountBundle(),
    queryFn: async () => await SchemaSalesforce.AccountBundleBySlug(request),
    enabled,
    select: (data) => data.bundle,
  });
};

type SchemaGetSalesforceAccountBySlug = SchemaIO<(typeof SchemaSalesforce)['SalesforceAccountBySlug']>;
export const useGetSalesforceAccountBySlug = ({
  slug,
  enabled,
  consumer = Consumer.INSYS,
}: SalesforceAccountBySlugRequestQueryParams) => {
  const request: SchemaGetSalesforceAccountBySlug['input'] = {
    consumer,
    slug,
  };

  return useScopedQuery({
    ...queryOptions,
    queryKey: SubscriptionsQueryKeys.queryKeys.accountBundle(),
    queryFn: async () => await SchemaSalesforce.SalesforceAccountBySlug(request),
    enabled,
  });
};

export const useGetSalesforceBundleDetailsByLocation = ({ enabled }: { enabled: boolean }) => {
  return useLocalizedQuery({
    ...queryOptions,
    queryKey: SubscriptionsQueryKeys.queryKeys.salesforceBundleInfo(),
    queryFn: async () => await SchemaPackageService.GetSalesforceBundleName({}),
    enabled,
  });
};

export const useGetSalesforceBundleDetailsMultiLocation = (
  request: GetSalesforceBundleNameForMultiRequest,
  options?: UseQueryOptions<GetSalesforceBundleNameForMultiResponse>
) => {
  return useQuery({
    queryKey: SubscriptionsQueryKeys.queryKeys.multiSalesforceBundleInfo({ locationIds: request.locationIds }),
    queryFn: async () => SchemaPackageService.GetSalesforceBundleNameForMulti(request),
    enabled: !!request?.locationIds?.length,
    ...queryOptions,
    ...options,
  });
};

type UseGetPlanDetailsParams = {
  enabled: boolean;
  sfProductBundleEnum?: SalesforceAccountProductBundle;
};
type SchemaGetPlanDetails = SchemaIO<(typeof SchemaPackageService)['GetPlanDetails']>;
export const useGetPlanDetails = ({ enabled, sfProductBundleEnum }: UseGetPlanDetailsParams) => {
  const request: SchemaGetPlanDetails['input'] = {
    sfProductBundleEnum,
  };
  return useLocalizedQuery({
    ...queryOptions,
    cacheTime: 12 * CACHE_AND_STALE_TIME_IN_HOUR, //12 hrs,
    staleTime: 12 * CACHE_AND_STALE_TIME_IN_HOUR, //12 hrs
    queryKey: SubscriptionsQueryKeys.queryKeys.planDetails(sfProductBundleEnum ?? ''),
    queryFn: async () => await SchemaPackageService.GetPlanDetails(request),
    enabled,
    select: (planeDetails) => planeDetails.features,
  });
};

type UseGetPlanComparisonDetailsParams = {
  sfProductBundleEnumList: SalesforceAccountProductBundle[];
  verticalId: number;
  enabled: boolean;
  showExclusiveFeatures?: boolean;
};
type SchemaGetPlanComparisonDetails = SchemaIO<(typeof SchemaPackageService)['GetPlanComparisonDetails']>;
export const useGetPlanComparisonDetails = ({
  sfProductBundleEnumList,
  verticalId,
  enabled,
}: UseGetPlanComparisonDetailsParams) => {
  const request: SchemaGetPlanComparisonDetails['input'] = {
    products: sfProductBundleEnumList,
    vertical: verticalId,
  };
  return useLocalizedQuery({
    ...queryOptions,
    queryKey: SubscriptionsQueryKeys.queryKeys.planComparisonDetails(sfProductBundleEnumList, verticalId),
    queryFn: async () => await SchemaPackageService.GetPlanComparisonDetails(request),
    enabled,
  });
};

type SchemaGetBlockPrice = SchemaIO<(typeof SchemaSalesforce)['BlockPriceByProductCode']>;

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 * Use useGetAccountAddOnQuotaByProduct instead.
 */
export const useGetBlockPrice = ({ enabled }: { enabled: boolean }) => {
  const request: SchemaGetBlockPrice['input'] = {
    consumer,
    productCode,
  };
  return useLocalizedQuery({
    ...queryOptions,
    queryKey: SubscriptionsQueryKeys.queryKeys.blockPrice(),
    queryFn: async () => await SchemaSalesforce.BlockPriceByProductCode(request),
    select: (data) =>
      data?.blockPrices?.map((block) => ({
        ...block,
        upperBound: Math.round((block.upperBound || 0) / 1000) * 1000,
      })) ?? [],
    enabled,
  });
};

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 * Use useCurrentAddOnQuota hook instead.
 */
export const useLocalStorageQuota = () => {
  const localStoredObj = JSON.parse(localStorage.getItem(BULK_MESSAGE_QUOTA_LOCAL_STORAGE_KEY)!);
  const localStorageExpiration = localStoredObj?.expiration;
  const isExpired = dayjs().format('YYYY-MM-DD HH:mm:ss') > localStorageExpiration;
  const localStorageQuota = !isExpired ? Number(localStoredObj?.quota ?? 0) : undefined;

  if (isExpired) {
    localStorage.removeItem(BULK_MESSAGE_QUOTA_LOCAL_STORAGE_KEY);
  }

  const staleTime = !isExpired ? dayjs().diff(localStorageExpiration, 'ms') + 1 : undefined;

  return { staleTime, localStorageQuota };
};

type SchemaGetBulkMessageQuota = SchemaIO<(typeof SchemaSalesforce)['AccountBulkMessageQuoteBySlug']>;

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 * Use useGetQuotaList instead.
 */
export const useGetBulkMessageQuota = ({ slug, enabled }: { slug: string; enabled: boolean }) => {
  const request: SchemaGetBulkMessageQuota['input'] = {
    consumer,
    slug,
  };

  const { localStorageQuota, staleTime } = useLocalStorageQuota();

  return useLocalizedQuery({
    ...queryOptions,
    queryKey: SubscriptionsQueryKeys.queryKeys.bulkMessageQuota(),
    queryFn: async () => await SchemaSalesforce.AccountBulkMessageQuoteBySlug(request),
    enabled,
    select: (data) => localStorageQuota || data.bulkMessageQuota || 0,
    staleTime,
  });
};

export type SchemaSelfServeRequest = SchemaIO<(typeof SchemaSalesforce)['SelfServe']>;
export const usePostSelfServe = (body: SchemaSelfServeRequest['input']) => SchemaSalesforce.SelfServe(body);

export type SchemaSubscribeRequest = SchemaIO<(typeof SchemaSubscriptionManagerService)['Subscribe']>['input'];
export const useSubscribe = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useMutation({
    mutationFn: (requestPayload: SchemaSubscribeRequest) => SchemaSubscriptionManagerService.Subscribe(requestPayload),
    retry: false,
    mutationKey: [locationId, 'subscribe'],
  });
};

type UseSalesforceBundleBySlugResponseType = {
  isCSMManagedMultiLocation: boolean;
  isSalesforceMultiLocation: boolean;
  isLoading: boolean;
  isError: boolean;
};

type UseSalesforceBundleBySlugRequestType = Pick<SalesforceAccountBySlugRequestQueryParams, 'consumer' | 'enabled'>;

export const useIsCSMManagedMultiLocation = ({
  enabled,
  consumer = Consumer.INSYS,
}: UseSalesforceBundleBySlugRequestType): UseSalesforceBundleBySlugResponseType => {
  const { locationData } = useLocationDataShallowStore('locationData');
  const {
    data: salesforceAccountDetails,
    isLoading,
    isError,
  } = useGetSalesforceAccountBySlug({
    enabled,
    consumer,
    slug: locationData?.Slug ?? '',
  });

  return {
    isCSMManagedMultiLocation: salesforceAccountDetails?.account?.multiCsmManaged ?? false,
    isSalesforceMultiLocation: salesforceAccountDetails?.account?.multiLocation ?? false,
    isLoading,
    isError,
  };
};

export const useUpdateDemoLocationCustomization = (
  options?: MutationOptions<Empty, unknown, SetDemoLocationCustomizationsRequest>
) => {
  return useMutation({
    mutationFn: SchemaPackageService.SetDemoLocationCustomizations,
    ...options,
  });
};

export type SchemaNotifyAdminForUpgradeRequest = SchemaIO<
  (typeof SchemaSubscriptionManagerService)['NotifyAdminForUpgrade']
>['input'];

export const useNotifyAdminForUpgrade = (
  options?: MutationOptions<Empty, unknown, SchemaNotifyAdminForUpgradeRequest>
) => {
  return useMutation({
    mutationFn: (requestPayload: SchemaNotifyAdminForUpgradeRequest) =>
      SchemaSubscriptionManagerService.NotifyAdminForUpgrade(requestPayload),
    ...options,
  });
};

export const useGetQuotaList = (
  productCode: SFQuotaProductCode,
  options?: UseQueryOptions<BlockPriceByProductCodeResponse, unknown, BlockPrice[]>
) => {
  return useQuery({
    queryKey: SubscriptionsQueryKeys.queryKeys.getQuotaList(productCode),
    queryFn: () => SchemaSalesforce.BlockPriceByProductCode({ productCode, consumer }),
    retry: false,
    ...options,
    enabled: productCode && (options?.enabled ?? true),
    select: (data) => {
      return (
        data?.blockPrices?.map((block) => ({
          ...block,
          upperBound: Math.round((block.upperBound || 0) / 1000) * 1000,
        })) ?? []
      );
    },
  });
};

export const useGetLowestPriceQuota = (...props: Parameters<typeof useGetQuotaList>) => {
  const { data: blockPrices, isLoading } = useGetQuotaList(...props);

  const prices = (blockPrices?.map((block) => block?.pricePerMonth) ?? []) as number[];
  const lowestPrice = Math.min(...prices);

  return {
    lowestPrice,
    isLoading,
  };
};

export const useGetAccountAddOnQuotaByProduct = (
  request: AccountAddOnQuotaByProductRequest,
  options?: UseQueryOptions<AccountAddOnQuotaByProductResponse, HttpError, number | undefined>
) => {
  request.consumer = Consumer.INSYS;
  return useQuery({
    queryKey: SubscriptionsQueryKeys.queryKeys.getAccountAddonQuota(request),
    queryFn: () => SchemaSalesforce.AccountAddOnQuotaByProduct(request),
    retry: false,
    ...options,
    select: (data) => data.quota,
    enabled: !!request.product && !!request.slug && !!request.consumer && (options?.enabled ?? true),
  });
};

export const useGetAccountBundleQuota = (
  request: AccountBundleQuotaBySlugRequest,
  options?: UseQueryOptions<AccountBundleQuotaBySlugResponse>
) => {
  request.consumer = Consumer.INSYS;
  return useQuery({
    queryKey: SubscriptionsQueryKeys.queryKeys.getAccountAddonQuota(request),
    queryFn: () => SchemaSalesforce.AccountBundleQuotaBySlug(request),
    retry: false,
    ...options,
    enabled: !!request.slug && !!request.consumer && (options?.enabled ?? true),
  });
};
