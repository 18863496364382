import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { Trans, useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { TextLink } from '@frontend/design-system';
import { ChartInfoBanner } from '..';
import { URLs } from '../../constants';
import { trackingIds } from '../../tracking-ids';
import { usePracticeAnalyticsShallowStore } from './hooks';
import { ActivePatientsMetric, ActivePatientsScheduledMetric, PatientsCount, PracticePercentages } from '.';

type SummaryMetricProps = {
  hideActivePatientsMetric?: boolean;
  hideActivePatientsScheduledMetric?: boolean;
};

const getLastVsCurrentPercentDiff = (last?: number, current?: number) => {
  if (last && current) {
    return ((current - last) / last) * 100;
  }
  return 0;
};

export const SummaryMetric = ({
  clickNoop,
  hideActivePatientsMetric,
  hideActivePatientsScheduledMetric,
  isDrillDownPage,
  onFetchStateChange,
}: PracticeAnalyticsTypes.MetricProps & SummaryMetricProps) => {
  const { t } = useTranslation('analytics');
  const { isDemoAccount } = usePracticeAnalyticsShallowStore('isDemoAccount');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [practicePercentages, setPracticePercentages] = useState<PracticePercentages>({});
  const [patientsCount, setPatientsCount] = useState<PatientsCount>({});

  const handlePraticePercentagesUpdate = (percentages: PracticePercentages) => {
    if (!hideActivePatientsScheduledMetric) {
      setPracticePercentages(percentages);
    }
  };

  const handlePatientsCountUpdate = (patients: PatientsCount) => {
    if (!hideActivePatientsMetric) {
      setPatientsCount(patients);
    }
  };

  const getActivePatientsInfoBanerFocusText = (value: number) => {
    const displayValue = value < 1 && value > -1 ? Math.abs(value).toFixed(2) : Math.round(value);

    if (value < 0) {
      return t("Your active patient count is down by {{value}}%. Let's work together to elevate that.", {
        value: displayValue,
      });
    } else if (value > 0) {
      return t('Your active patient count is up by {{value}}%. Good for you!', { value: displayValue });
    } else {
      return t(
        "Your practice saw roughly the same number of active patients as last period. Let's get those patients through your door."
      );
    }
  };

  const getActivePatientsScheduledInfoBanerFocusText = (value: number) => {
    // If the value is between -1 and 1 then show 2 decimal places, otherwise show rounded value
    const displayValue = value < 1 && value > -1 ? Math.abs(value).toFixed(2) : Math.abs(Math.round(value));

    if (value < 0) {
      return t(
        "Your practice is seeing {{value}}% fewer patients than the average practice. Don't leave revenue on the table!",
        { value: displayValue }
      );
    } else if (value > 0) {
      return t('Your practice is seeing {{value}}% more patients than the average practice.', { value: displayValue });
    } else {
      return t('Your practice is seeing about equal patients as other practices.');
    }
  };

  const renderActivePatientsBottomContent = () => (
    <ChartInfoBanner
      action={
        <Trans t={t}>
          Capture more active patients by{' '}
          <TextLink target='_blank' to={URLs.BULK_MESSAGES} trackingId={trackingIds.practiceAnalytics.scheduleBulkText}>
            scheduling a Bulk Text
          </TextLink>
          .
        </Trans>
      }
      activityText={t('Based on your Active Patients performance')}
      focusText={
        patientsCount?.lastPeriod && patientsCount.total
          ? getActivePatientsInfoBanerFocusText(
              getLastVsCurrentPercentDiff(patientsCount.lastPeriod, patientsCount.total)
            )
          : '--'
      }
    />
  );

  const renderActivePatientsScheduledBottomContent = () => (
    <ChartInfoBanner
      action={
        <Trans t={t}>
          Use{' '}
          <TextLink target='_blank' to={URLs.QUICK_FILL} trackingId={trackingIds.practiceAnalytics.quickFill}>
            Quick-Fill
          </TextLink>{' '}
          to schedule patients into your gaps.
        </Trans>
      }
      activityText={t('Based on your Active Patients Scheduled performance')}
      focusText={
        practicePercentages?.active && practicePercentages?.average
          ? getActivePatientsScheduledInfoBanerFocusText(practicePercentages.active - practicePercentages.average)
          : '--'
      }
    />
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (hideActivePatientsMetric && hideActivePatientsScheduledMetric) {
    return null;
  }

  const commonProps = {
    clickNoop,
    isDemoAccount,
    isDrillDownPage,
    onFetchStateChange,
    windowWidth,
  };

  return (
    <>
      <div css={styles.flexDiv}>
        {!hideActivePatientsMetric && (
          <ActivePatientsMetric
            {...commonProps}
            onUpdatePatientCount={handlePatientsCountUpdate}
            renderBottomContent={renderActivePatientsBottomContent}
          />
        )}

        {!hideActivePatientsScheduledMetric && (
          <ActivePatientsScheduledMetric
            {...commonProps}
            onUpdatePatientPercentage={handlePraticePercentagesUpdate}
            renderBottomContent={renderActivePatientsScheduledBottomContent}
          />
        )}
      </div>

      {windowWidth >= breakpoints.medium.min && (
        <div
          className={`action-banners ${
            hideActivePatientsMetric || hideActivePatientsScheduledMetric ? 'full-width' : ''
          }`}
          css={styles.flexDiv}
        >
          {!hideActivePatientsMetric && renderActivePatientsBottomContent()}
          {!hideActivePatientsScheduledMetric && renderActivePatientsScheduledBottomContent()}
        </div>
      )}
    </>
  );
};

const styles = {
  flexDiv: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};

    > .chart-card {
      flex: 1;
      margin-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      flex-direction: row;

      &.action-banners {
        margin-top: ${theme.spacing(-1)};

        > .chart-info-banner {
          margin-top: 0;
          width: calc(50% - ${theme.spacing(1.5)});
        }

        &.full-width {
          > .chart-info-banner {
            width: 100%;
          }
        }
      }

      > .chart-card {
        width: calc(50% - ${theme.spacing(1.5)});
      }
    }
  `,
};
