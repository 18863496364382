import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ListRow, PopoverDialog, Text, TextField, usePopoverDialog } from '@frontend/design-system';
import { GenericFieldProps } from '../../../types';
import { convertValueToPixels } from '../../../utils';

type Props = GenericFieldProps & {
  options: string[];
};

export const InputDropdownContent = ({ disabled, options, ...fieldProps }: Props) => {
  const { getDialogProps, getTriggerProps, open, isOpen, close } = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -86,
      y: 0,
    },
  });

  const handleChange = (value: string) => {
    fieldProps.onChange({ ...fieldProps, value: convertValueToPixels(value) });
  };

  const handleArrowPress = (key: 'ArrowUp' | 'ArrowDown') => {
    const numericalValue = Number(fieldProps.value.match(/[0-9]+/)?.[0]);
    const unit = fieldProps.value.match(/[a-z]+/)?.[0] ?? '';

    let value = '';
    if (key === 'ArrowUp') {
      value = numericalValue + 1 + unit;
    } else if (key === 'ArrowDown') {
      value = numericalValue - 1 + unit;
    }
    handleChange(value);
  };

  return (
    <>
      <TextField
        {...fieldProps}
        label=''
        endAdornment={
          <Text {...getTriggerProps()} onClick={() => (isOpen ? close() : open())}>
            <Icon
              name='caret-down'
              css={[
                css`
                  transition: transform 0.2s;
                `,
                isOpen &&
                  css`
                    transform: rotate(180deg);
                  `,
              ]}
            />
          </Text>
        }
        css={css`
          width: ${theme.spacing(15)};
        `}
        onChange={(e) => {
          if (disabled) return;
          // TODO: need to do some validation on the user's input here
          // @ts-ignore - DS types are wrong
          handleChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (disabled) return;
          if (e.key === 'ArrowUp') {
            handleArrowPress('ArrowUp');
          }
          if (e.key === 'ArrowDown') {
            handleArrowPress('ArrowDown');
          }
        }}
        disabled={disabled}
      />
      {isOpen && (
        <PopoverDialog
          {...getDialogProps()}
          css={css`
            width: ${theme.spacing(15)};
            padding: 0;
          `}
        >
          {options.map((option) => (
            <ListRow
              key={option}
              css={css`
                border-bottom: unset;
                padding: ${theme.spacing(1, 2)};
                cursor: pointer;
                &:hover,
                &:focus {
                  background: ${theme.colors.neutral5};
                }
              `}
              onClick={() => {
                handleChange(option);
                close();
              }}
            >
              {option}
              {fieldProps.value === option && (
                <Icon
                  name='check'
                  css={css`
                    margin-left: ${theme.spacing(2)};
                    color: ${theme.colors.primary50};
                  `}
                />
              )}
            </ListRow>
          ))}
        </PopoverDialog>
      )}
    </>
  );
};
