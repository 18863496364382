import { IGif, IImage } from '@giphy/js-types';
import dayjs from 'dayjs';
import { MessageResponse } from 'stream-chat';
import { StreamGiphyType, GiphyVersionInfo } from '@frontend/gif-picker';
import { StreamMessageResponse, Message, Reaction } from '../types';
import { formatUser } from '../utils';

// Explicitly adding the reaction_group because it does not exists on MessageResponse
// The correct way would be to update the DefaultGeneric
// Will pick this when fixing the channel undefined issue
interface FormatMessage {
  channelId: string;
  currentUserId: string;
  message: (StreamMessageResponse | MessageResponse) & {
    reaction_groups?: Record<
      string,
      { count: number; first_reaction_at: string; last_reaction_at: string; sum_scores: number }
    >;
  };
}

export const formatMessage = ({ channelId, currentUserId, message }: FormatMessage): Message => {
  const reactions: Reaction[] = [];

  if (message.reaction_groups) {
    const ownReactions: Record<string, boolean> = {};

    message.own_reactions?.forEach((reaction) => {
      ownReactions[reaction.type] = true;
    });

    // I could see the user names available only on the latest_reactions and not on the reaction_groups
    // Hence using the latest_reactions to get the user names for the reactions
    // TODO :: Check if the latest reactions are always available or there is a better way to get the user names
    const users: Record<string, string[]> = {};
    message.latest_reactions?.forEach(({ type, user }) => {
      if (user?.name) {
        if (users[type]) {
          users[type].push(user.name);
        } else {
          users[type] = [user.name];
        }
      }
    });

    for (const reactionName in message.reaction_groups) {
      const reaction = message.reaction_groups[reactionName];
      reactions.push({
        name: reactionName,
        count: reaction.count,
        hasOwnReaction: !!ownReactions[reactionName],
        firstReaction: dayjs(reaction.first_reaction_at).valueOf(),
        users: users[reactionName] ?? [],
      });
    }
  }

  return {
    id: message.id,
    channelId: channelId,
    text: message.text ?? '',
    isEdited: !!message.message_text_updated_at,
    created: dayjs(message.created_at).toDate().toString(),
    lastUpdated: dayjs(message.updated_at).toDate().toString(),
    isUnread: false, // FIXME: add the correct value for isUnread,
    type: message.type,
    senderName: message.user?.name ?? '',
    isOwnMessage: message.user?.id === currentUserId,
    senderUser: formatUser(message.user),
    ...(message.attachments?.length && {
      attachments: message.attachments.reduce<string[]>((acc, { asset_url, image_url, type, thumb_url }) => {
        const attachment = asset_url || image_url || thumb_url; // IMPORTANT: mobile uploads doesn't contain asset_url
        if (type && ['image', 'giphy'].includes(type) && attachment) {
          acc.push(attachment);
        }
        return acc;
      }, []),
    }),
    ...(reactions.length && { reactions: reactions.sort((a, b) => a.firstReaction - b.firstReaction) }),
  };
};

// This function finds out the last unread message index to display the unread line
export const getUnreadIndex = (messages?: Message[], lastReadMessageId?: string, unreadCount?: number): number => {
  // if there are no messages then return 0
  if (!messages?.length) {
    return 0;
  }
  // If there is no last read message id, it means that it's a newly created conversation. Hence return the unread count
  if (!lastReadMessageId) {
    if (!unreadCount) {
      return 0;
    } else if (unreadCount > messages.length) {
      return -1;
    }
    return unreadCount ? messages.length - unreadCount : 0;
  }

  // If none of the above conditions are met, then find the last unread message index from the messages array
  let messageIndex = messages.findIndex((message) => message.id === lastReadMessageId);

  // We also check if the message after the last read one is an own message, then we omit the message sent by us
  for (let i = messageIndex + 1; i < messages.length; i++) {
    if (messages[i].isOwnMessage) {
      messageIndex = i;
    }
  }

  // lastly we return the index + 1 to show the unread line after the last read message
  return messageIndex === -1 ? -1 : messageIndex + 1;
};

const convertValuesToString = (input: IImage): GiphyVersionInfo => {
  const result: GiphyVersionInfo = {
    height: String(input.height),
    url: input.url,
    width: String(input.width),
  };

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      result[key as keyof IImage] = String(input[key as keyof IImage]);
    }
  }

  return result;
};

export const parseGiphyToAttachment = (gif: IGif): StreamGiphyType => {
  const giphy: StreamGiphyType['giphy'] = {
    original: convertValuesToString(gif.images.original),
    fixed_height: convertValuesToString(gif.images.fixed_height),
    fixed_height_still: convertValuesToString(gif.images.fixed_height_still),
    fixed_height_downsampled: convertValuesToString(gif.images.fixed_height_downsampled),
    fixed_width: convertValuesToString(gif.images.fixed_width),
    fixed_width_still: convertValuesToString(gif.images.fixed_width_still),
    fixed_width_downsampled: convertValuesToString(gif.images.fixed_width_downsampled),
  };

  return {
    title: gif.title,
    title_link: gif.url,
    thumb_url: gif.images.original.url,
    giphy,
  };
};
