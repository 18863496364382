import { useRef, useState } from 'react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { EmailView, useEmailStates } from '@frontend/integrated-messaging';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal, Text, formatDate, useAlert } from '@frontend/design-system';

export const ShareInEmail = () => {
  const alert = useAlert();
  const { t } = useTranslation('payments-share-in-email');
  const [isSending, setIsSending] = useState(false);
  const { invoice } = useSelectedInvoice();
  const { closeModal, goBack } = useMultiStepModal();
  const { getLocationName } = useAppScopeStore();
  const goBackOnSubmit = useRef(false);

  const templateType = TemplateType_Slug.MANUAL_PAYMENT_REQUEST;

  const emailStates = useEmailStates({
    locationId: invoice?.locationId ?? '',
    templateType,
    linkData: {
      link: invoice?.links.payment ?? '',
      relatedId: invoice?.id ?? '',
    },
    personId: invoice?.person.id ?? '',
    onSendSuccess: () => {
      if (goBackOnSubmit.current) goBack();
      else closeModal();
      alert.success('Email sent successfully');
      setIsSending(false);
    },
    onSendError: () => {
      setIsSending(false);
    },
    initialValues: {
      subject: t('Balance due for {{name}}', { name: invoice ? getLocationName(invoice.locationId) : '' }),
    },
  });

  if (!invoice) return null;

  return (
    <>
      <Modal.Header onClose={closeModal}>{t('Share in Email')}</Modal.Header>
      <Modal.Body css={{ padding: theme.spacing(0, 3) }}>
        <form {...emailStates.formProps}>
          <EmailView
            locationId={invoice.locationId}
            states={emailStates}
            contextPreviewProps={{
              iconName: 'dollar-sign',
              title: <Text weight='bold'>{formatCentsToCurrency(invoice?.billedAmount)}</Text>,
              subtitle: (
                <Text>
                  {t('Due Date')}:{' '}
                  <Text as='span' color='subdued'>
                    {formatDate(invoice?.billedAt, 'MMM D, YYYY')}
                  </Text>
                </Text>
              ),
            }}
          />
        </form>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Send Now'),
          onClick: (e) => {
            if (!e) return;
            setIsSending(true);
            emailStates.formProps.onSubmit(e);
          },
          disabled: isSending,
        }}
        secondary={{
          label: t('Send & Select Another Delivery Method'),
          onClick: (e) => {
            if (!e) return;
            setIsSending(true);
            goBackOnSubmit.current = true;
            emailStates.formProps.onSubmit(e);
          },
          disabled: isSending,
        }}
        tertiary={{
          label: t('Back'),
          onClick: () => goBack(),
        }}
      />
    </>
  );
};
