import { Source } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/source_tenant.pb';
import {
  DataType as CapabilityDataType,
  Operation as CapabilityOperation,
} from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/capability.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useAppScopeStore } from '@frontend/scope';
import { SourceTenantListItem } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/ScheduleRequestModal/types';
import { useGetDataSourcesForLocation } from '../use-get-data-sources-for-location';

type ManageAppointmentWritebackArgs = {
  isEnabled?: boolean;
  locationId: string;
};

const getSourceTenantList = (sourceTenants: Source[] = []) => {
  return sourceTenants.reduce<SourceTenantListItem[]>((list, source) => {
    const sites = source.sites?.length
      ? source.sites.map((site) => ({
          parentSourceTenantId: source.sourceTenantId,
          sourceTenantId: site.sourceTenantId,
          sourceId: source.id,
          name: site.name,
          pmsName: source.pmsName,
        }))
      : [
          {
            parentSourceTenantId: source.sourceTenantId,
            sourceTenantId: source.sourceTenantId,
            sourceId: source.id,
            name: source.sourceName,
            pmsName: source.pmsName,
          },
        ];

    return list.concat(sites);
  }, []);
};

export const useManageAppointmentWriteback = ({ isEnabled = false, locationId }: ManageAppointmentWritebackArgs) => {
  const { selectedGroupId } = useAppScopeStore();
  const sourceLocationId = selectedGroupId || locationId;

  const {
    mutateAsync: createAppointmentWriteback,
    isLoading: isLoadingCreateWritebackAppointment,
    isError: isCreateAppointmentWritebackError,
  } = SchedulerV3Queries.useAppointmentWriteback();

  // Sources are defined for parent locations
  const { data: dataSources, isLoading: isLoadingDataSources } = useGetDataSourcesForLocation({
    locationId: sourceLocationId,
    isEnabled,
  });

  // Source Tenants are defined for child locations
  const { data: sourceTenantsData, isLoading: isLoadingSourceTenants } = SchedulerV3Queries.useListSourceTenants({
    locationId,
    isEnabled: !!locationId,
  });

  const sourceTenantList = getSourceTenantList(sourceTenantsData?.sources);

  const { data: appointmentStatusData, isLoading: isLoadingAppointmentStatusData } =
    ScheduleQueries.useGetAppointmentStatuses(sourceLocationId);

  const checkWritebackCapability = (sourceTenantId: string) => {
    const sourceId = sourceTenantList.find((source) => source.sourceTenantId === sourceTenantId)?.sourceId;
    const dataSourceObj = dataSources?.dataSources?.find((dataSource) => dataSource.sourceId === sourceId);
    const hasWritebackCapability = dataSourceObj?.integration?.capabilities?.find(
      (capability) =>
        capability.dataType === CapabilityDataType.APPOINTMENT && capability.operation === CapabilityOperation.CREATE
    );
    return !!hasWritebackCapability;
  };

  const getAppointmentExternalStatusForSourceTenant = (sourceTenantId: string) => {
    if (!sourceTenantId || !appointmentStatusData) return;

    const sourceId = sourceTenantList.find((source) => source.sourceTenantId === sourceTenantId)?.sourceId;
    const source = appointmentStatusData?.mappings?.find((mapping) => mapping.sourceId === sourceId);
    const confirmedMappingObject = source?.mappings.find((sourceMap) => sourceMap.key === 'Confirmed');
    return confirmedMappingObject?.value?.[0]?.value;
  };

  // Filter sources based on writeback capability
  const getWritebackCapableSourceIds = (dataType: CapabilityDataType) => {
    return new Set(
      dataSources?.dataSources
        ?.filter((dataSource) =>
          dataSource?.integration?.capabilities?.some(
            (capability) => capability.dataType === dataType && capability.operation === CapabilityOperation.CREATE
          )
        )
        .map((dataSource) => dataSource.sourceId) || []
    );
  };

  // Filter sourceTenantList based on writeback capability
  const getWritebackCapableSourceTenantList = () => {
    const appointmentWritebackCapableSourceIds = getWritebackCapableSourceIds(CapabilityDataType.APPOINTMENT);
    const patientWritebackCapableSourceIds = getWritebackCapableSourceIds(CapabilityDataType.PERSON);

    const appointmentWritebackSourceTenants = sourceTenantList.filter((sourceTenant) =>
      appointmentWritebackCapableSourceIds.has(sourceTenant.sourceId)
    );
    const patientWritebackSourceTenants = sourceTenantList.filter((sourceTenant) =>
      patientWritebackCapableSourceIds.has(sourceTenant.sourceId)
    );

    return { appointmentWritebackSourceTenants, patientWritebackSourceTenants };
  };

  const { appointmentWritebackSourceTenants, patientWritebackSourceTenants } = getWritebackCapableSourceTenantList();

  return {
    isLoadingDataSources,
    isLoadingSourceTenants,
    isLoadingCreateWritebackAppointment,
    isCreateAppointmentWritebackError,
    isLoadingAppointmentStatusData,
    appointmentWritebackSourceTenants,
    patientWritebackSourceTenants,
    createAppointmentWriteback,
    checkWritebackCapability,
    getAppointmentExternalStatusForSourceTenant,
  };
};
