import { memo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { cardStyles } from '../common-styles';
import { LeaderboardDataValue } from './types';

type Props = LeaderboardDataValue & {
  columnId: string;
  rowId: string;
  trackingIdBase?: string;
};

export const Card = memo(({ columnId, onClick, rowId, trackingIdBase, ...value }: Props) => {
  const { formatter, primaryValue, secondaryValue } = value;
  const { labels } = useChartContext();

  const clickProps = !!onClick
    ? {
        className: 'clickable',
        onClick: () =>
          onClick({
            columnId,
            rowId,
            value,
          }),
        role: 'button',
        tabIndex: 0,
        'data-trackingId': `${trackingIdBase}-click-${columnId}`,
      }
    : {};

  return (
    <div {...clickProps} css={[cardStyles(), styles.dataCard]}>
      <Text css={styles.mainText}>
        {formatter?.(primaryValue) ?? primaryValue}
        {secondaryValue ? (
          <Text as='span' color='subdued' weight='regular'>
            /{formatter?.(secondaryValue) ?? secondaryValue}
          </Text>
        ) : null}
      </Text>
      <Text color='subdued' size='medium'>
        {labels[columnId] || columnId}
      </Text>
    </div>
  );
});

Card.displayName = 'Card';

const styles = {
  dataCard: css`
    background-color: ${theme.colors.white};
    gap: 0;
    padding: ${theme.spacing(2)};

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.neutral5};
      }
    }
  `,

  mainText: css`
    font-size: ${theme.font.size.h2};
    font-weight: ${theme.font.weight.bold};
  `,
};
