import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { Heading, styles } from '@frontend/design-system';
import { ContextPreview, ContextPreviewProps } from '../../common';

type HeaderProps = {
  person: Person;
  contextPreviewProps?: ContextPreviewProps;
};
export const Header = ({ person, contextPreviewProps }: HeaderProps) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
      }}
    >
      <PersonBox person={person} />
      {contextPreviewProps && <ContextPreview {...contextPreviewProps} />}
    </div>
  );
};

type PersonBoxProps = {
  person: Person;
};
const PersonBox = ({ person }: PersonBoxProps) => {
  const { t } = useTranslation('integrated-messaging');
  return (
    <div
      css={{
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        minWidth: '35%',
      }}
    >
      <Photos.ContactProfilePhoto
        personId={person?.personId ?? ''}
        disableClick
        name={person ? PersonsV3.PersonHelpers.getFullName(person) : undefined}
      />
      <Heading css={[styles.truncate]} level={3}>
        {person ? PersonsV3.PersonHelpers.getFullName(person) : t('Unknown User')}
      </Heading>
    </div>
  );
};
