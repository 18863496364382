import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { MessageStatus, VoicemailBox } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { Chips } from '@frontend/chips';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ChecklistField,
  ListRow,
  ModalControlModalProps,
  TableFilters,
  Text,
  useFormField,
} from '@frontend/design-system';
import { useActiveLocationsMap } from '../../hooks/use-active-location-map';
import { VoicemailFiltersType } from '../../hooks/use-phone-config-state-store';
import { useTags } from '../../hooks/use-tags';
import { filterEmptyKeys } from '../all-calls/utils';
import { DatesRangeSelector } from '../filter-selectors/dates-range-selector';
import {
  filterSelectorError,
  LocationsSelector,
  LocationStringRecord,
} from '../filter-selectors/locations-checklist-selectors';
import { MultiChipSelector } from '../filter-selectors/multi-chip-selector';
import { RadioFilterSelector } from '../filter-selectors/radio-filter-selector';
import { DEFAULT_NO_TIME_PERIOD, TimePeriodSelector } from '../filter-selectors/time-period-selector';
import { hasVMFilterChanged } from './voicemail-utils';

interface FilterProps {
  defaultFilters: VoicemailFiltersType;
  filters: VoicemailFiltersType;
  mailboxes: VoicemailBox[] | undefined;
  isMulti: boolean;
  onChange: (args: VoicemailFiltersType) => void;
}

type ConditionalProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
};
export const VoicemailsTrayFilters = ({
  defaultFilters,
  filters,
  mailboxes,
  isMulti,
  onChange,
  modalProps,
  setShowNotificationBadge,
}: FilterProps & ConditionalProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails-filter' });
  const [trayFilters, setTrayFilters] = useState<VoicemailFiltersType>(filters);
  const [filterErrors, setFilterErrors] = useState<Record<string, boolean>>({});
  const hasFilterErrors = useMemo(() => Object.values(filterErrors).some((error) => error), [filterErrors]);
  const { mappedTags } = useTags();

  useEffect(() => {
    const isFilterChanged = hasVMFilterChanged(filters, trayFilters);
    if (isFilterChanged) {
      setTrayFilters({
        ...filters,
        locationIds: filters.locationIds,
        filterOptions: {
          ...filters.filterOptions,
          mailboxIds: filters.filterOptions.mailboxIds,
          tagIds: filters.filterOptions.tagIds,
          status: filters.filterOptions.status,
          startTime: filters.filterOptions.startTime,
          endTime: filters.filterOptions.endTime,
        },
      });
    }
    // compare with default filter always to show/hide notification badge
    hasVMFilterChanged(filters, defaultFilters) ? setShowNotificationBadge(true) : setShowNotificationBadge(false);
  }, [filters.locationIds, filters.filterOptions.startTime, filters.filterOptions.endTime]);

  const allLocationsIdToNameMap = useActiveLocationsMap();

  const mailboxFilterProps = useFormField(
    {
      type: 'checklist',
      value: trayFilters.filterOptions.mailboxIds,
    },
    [trayFilters.filterOptions.mailboxIds]
  );

  const personalMailboxData = mailboxes?.find(({ mailboxType }) => mailboxType === 'MAILBOX_TYPE_PERSONAL') ?? null;

  const messageStatusOptions = {
    [MessageStatus.MESSAGE_STATUS_READ]: t('Read'),
    [MessageStatus.MESSAGE_STATUS_UNREAD]: t('Unread'),
  };

  const handleUpdateTrayFilters = (newFilters: Partial<VoicemailFiltersType>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const handleApplyTableFilters = () => {
    const updatedFilters = {
      ...trayFilters,
      filterOptions: { ...trayFilters.filterOptions, mailboxIds: mailboxFilterProps.value },
    };
    hasVMFilterChanged(updatedFilters, defaultFilters)
      ? setShowNotificationBadge(true)
      : setShowNotificationBadge(false);
    onChange(updatedFilters);
    modalProps?.onClose();
  };

  const handleFiltersReset = () => {
    setShowNotificationBadge(false);
    setTrayFilters(defaultFilters);
    onChange(defaultFilters);
    modalProps?.onClose();
  };

  const handleFilterErrors = (error: filterSelectorError) => {
    setFilterErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const handleMessageStatusReset = () => {
    handleUpdateTrayFilters({
      filterOptions: {
        ...trayFilters.filterOptions,
        status: MessageStatus.MESSAGE_STATUS_ALL,
      },
    });
  };

  const handleVoicemailBoxReset = () => {
    handleUpdateTrayFilters({
      filterOptions: {
        ...trayFilters.filterOptions,
        mailboxIds: [],
      },
    });
  };

  return (
    <TableFilters
      width='medium'
      {...modalProps}
      onApplyClick={handleApplyTableFilters}
      onClearAllClick={handleFiltersReset}
      disableApplyButton={hasFilterErrors}
      clearAllLabel={t('Clear All Filters')}
    >
      <TableFilters.Section
        sectionHeaderLabel={t('Read Status')}
        onClearClick={handleMessageStatusReset}
        clearLabel={t('Clear')}
      >
        <RadioFilterSelector<MessageStatus>
          label={t('Read Status')}
          onChange={(status) => {
            handleUpdateTrayFilters({
              filterOptions: {
                ...trayFilters.filterOptions,
                status: status,
              },
            });
          }}
          options={messageStatusOptions}
          showLabel={false}
          withBorder={false}
          value={trayFilters.filterOptions.status}
          trackingId='voicemails-read-status-radio-filter'
        />
      </TableFilters.Section>
      {isMulti && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <LocationsSelector
            allLocationsOption={allLocationsIdToNameMap}
            onChange={(selLocationIds) => {
              handleUpdateTrayFilters({ locationIds: selLocationIds });
            }}
            onError={(err) => {
              handleFilterErrors(err);
            }}
            selectedLocationsValue={trayFilters.locationIds}
            trackingId='voicemails-location-selector-table-filter'
          />
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time Period')}>
        <div css={styles.traySection}>
          <TimePeriodSelector
            defaultPeriod={DEFAULT_NO_TIME_PERIOD}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({
                filterOptions: {
                  ...trayFilters.filterOptions,
                  startTime: startDate,
                  endTime: endDate,
                },
              });
            }}
            startDate={trayFilters.filterOptions.startTime}
            endDate={trayFilters.filterOptions.endTime}
            trackingId='voicemails-time-selector-table-filter'
          />
          <DatesRangeSelector
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({
                filterOptions: {
                  ...trayFilters.filterOptions,
                  startTime: startDate,
                  endTime: endDate,
                },
              });
            }}
            startDate={trayFilters.filterOptions.startTime}
            endDate={trayFilters.filterOptions.endTime}
          />
        </div>
      </TableFilters.Section>

      <TableFilters.Section
        sectionHeaderLabel={t('Voicemail Box')}
        onClearClick={handleVoicemailBoxReset}
        clearLabel={t('Clear')}
      >
        <ChecklistField {...mailboxFilterProps} label='' name='voicemail-box-filter'>
          {!!personalMailboxData && (
            <ListRow key={personalMailboxData.mailbox?.id || ''} css={styles.mailbox}>
              <ListRow.Content>
                <ChecklistField.Option
                  name={personalMailboxData.mailbox?.id || ''}
                  key={personalMailboxData.mailbox?.id || ''}
                >
                  <Text>{personalMailboxData.mailbox?.name || ''}</Text>
                </ChecklistField.Option>
              </ListRow.Content>
            </ListRow>
          )}
          {mailboxes?.map((mailbox) =>
            getCheckListOption({
              mailboxData: mailbox,
              personalMailboxId: personalMailboxData?.mailbox?.id || '',
              allLocationsIdToNameMap,
            })
          )}
        </ChecklistField>
      </TableFilters.Section>

      <TableFilters.Section sectionHeaderLabel={t('Tags')}>
        <MultiChipSelector
          allTagOptions={filterEmptyKeys(mappedTags)}
          selectedItems={trayFilters.filterOptions.tagIds ?? []}
          onChange={(tags) => {
            handleUpdateTrayFilters({
              filterOptions: {
                ...trayFilters.filterOptions,
                tagIds: tags,
              },
            });
          }}
        />
      </TableFilters.Section>
    </TableFilters>
  );
};

const getCheckListOption = ({
  mailboxData,
  personalMailboxId,
  allLocationsIdToNameMap,
}: {
  mailboxData: VoicemailBox;
  personalMailboxId: string;
  allLocationsIdToNameMap: LocationStringRecord;
}) => {
  const { mailbox, locationIds } = mailboxData;
  const getLocationChip = (locationIds: string[]) => {
    if (locationIds.length > 1) {
      return (
        <Chips.LocationChip variant='warningDark'>
          {locationIds.length} {i18next.t('Locations', { ns: 'calls' })}
        </Chips.LocationChip>
      );
    }
    if (locationIds.length === 1 && allLocationsIdToNameMap[locationIds[0]] !== undefined) {
      return <Chips.LocationChip variant='primaryDark'>{allLocationsIdToNameMap[locationIds[0]]}</Chips.LocationChip>;
    }
    return <></>;
  };

  const isShared = mailbox?.sharedAccess;

  if (mailbox?.id === personalMailboxId || !isShared) {
    return null;
  }
  return (
    <ListRow key={mailbox.id} css={styles.mailbox}>
      <ListRow.Content>
        <ChecklistField.Option name={mailbox.id ?? ''} key={mailbox.id ?? ''}>
          {mailbox.name}
        </ChecklistField.Option>
      </ListRow.Content>
      {Object.keys(allLocationsIdToNameMap).length > 1 && (
        <ListRow.Trail>{getLocationChip(locationIds ?? [])}</ListRow.Trail>
      )}
    </ListRow>
  );
};

const styles = {
  traySection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,

  mailbox: css`
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${theme.spacing(0)};
  `,
};
