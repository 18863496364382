import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { mediaQueries } from '../../../constants';
import { StepBody } from './step-body';
import { StepHeader } from './step-header';
import { StepNavigation } from './step-navigation';
import { StepQuestion } from './step-question';

type Props = CommonHTMLAttributes & { children: ReactNode };

export const Step = ({ children, ...rest }: Props) => {
  return (
    <section css={stepContainerStyle} {...rest}>
      {children}
    </section>
  );
};

Step.Body = StepBody;
Step.Header = StepHeader;
Step.Navigation = StepNavigation;
Step.Question = StepQuestion;

const stepContainerStyle = css`
  height: calc(100% - ${theme.spacing(2)});
  width: 100%;
  max-width: 700px;
  margin: ${theme.spacing(2)} auto ${theme.spacing(5)} auto;
  padding: ${theme.spacing(0, 2)};
  ${mediaQueries.xsmall} {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;
