import { useMemo } from 'react';
import { SendResponse } from '@weave/schema-gen-ts/dist/schemas/sms/send/v3/send_service.pb';
import { Icon } from '@frontend/icons';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { InboxTemplatesModals, TemplatesPopover, useInboxTemplateFlows } from '@frontend/inbox-templates';
import { ThreadSendingAreaComponents, ThreadSendingAreaHooks } from '@frontend/thread-sending-area';
import { theme } from '@frontend/theme';
import { Button, EmojiButton, ModalControlResponse } from '@frontend/design-system';

type ThreadViewSendingAreaProps = {
  personPhone: string;
  threadId: string;
  departmentId?: string;
  refetchThread: () => void;
  groupId: string;
  imageUploadModalControl: ModalControlResponse;
  scrollbarWidth: number;
  personId?: string;
  locationPhone: string;
  isEmptyThread: boolean;
};

export const ThreadViewSendingArea = ({
  personPhone,
  threadId,
  departmentId,
  refetchThread,
  groupId,
  personId,
  locationPhone,
  isEmptyThread,
}: ThreadViewSendingAreaProps) => {
  const { navigateToThread } = useInboxNavigate();
  const { availableTemplateFlows, modalsProps } = useInboxTemplateFlows({
    groupId,
    personId,
    personPhone,
    locationPhone,
    onRenderTemplate: ({ bodyValue, linkData }) => {
      setBody(bodyValue);
      focusTextarea();
      const relatedIdsToAdd =
        linkData?.reduce<Parameters<typeof addRelatedIds>[0]>((acc, curr) => {
          if (!curr.relatedType) return acc;
          acc.push({ id: curr.relatedId, type: curr.relatedType, requiredString: curr.link });
          return acc;
        }, []) ?? [];
      if (relatedIdsToAdd.length) addRelatedIds(relatedIdsToAdd, !!threadSendingAreaState.selectedScheduledMessage);
    },
  });

  const sendSideEffects = useMemo<ThreadSendingAreaHooks.UseThreadSendingAreaArgs['sendSideEffects']>(
    () => ({
      onSuccess: (res: SendResponse) => {
        if (res.threadId !== threadId) {
          navigateToThread({
            groupId: res.locationId,
            threadId: res.threadId,
            replace: true,
            personPhone: res.personPhone,
          });
        } else if (isEmptyThread) {
          // Give BE time to create new thread before refetching
          setTimeout(() => {
            refetchThread();
          }, 300);
        }
      },
    }),
    [threadId, navigateToThread, isEmptyThread, refetchThread]
  );

  const { insertText, setBody, focusTextarea, addRelatedIds, templatePopoverState, ...threadSendingAreaState } =
    ThreadSendingAreaHooks.useThreadSendingArea({
      threadId,
      groupId,
      personPhone,
      contextContactId: personId,
      outboundPhone: departmentId
        ? {
            departmentId,
            locationPhone,
          }
        : undefined,
      sendSideEffects,
    });

  return (
    <>
      <ThreadSendingAreaComponents.ThreadSendingArea
        {...threadSendingAreaState}
        primaryButtonTrackingPrefix='inbox'
        sendButtonVariant='primary'
        canSchedule={!isEmptyThread}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ThreadSendingAreaComponents.SignatureButton
            size='large'
            groupId={groupId}
            variant='secondary'
            css={{ border: 'none' }}
            trackingId='inbox-signature-text-area-edit-button'
          />
          <ThreadSendingAreaComponents.ImageButton
            size='large'
            addImages={threadSendingAreaState.addMedia}
            variant='secondary'
            trackingId='inbox-thread-image-button'
          />
          <EmojiButton
            onSelect={({ emoji, unified }) => {
              if (unified !== '') {
                insertText(emoji);
              }
            }}
            trackingId='inbox-thread-emoji-button'
          />
          <Button
            iconName='template'
            variant='secondary'
            size='large'
            css={{ border: 'none', padding: theme.spacing(1, 0.5) }}
            {...templatePopoverState.getTriggerProps()}
            trackingId='inbox-thread-text-area-templates-open'
          >
            <Icon name='caret-down-tiny' />
          </Button>
        </div>
      </ThreadSendingAreaComponents.ThreadSendingArea>
      <InboxTemplatesModals {...modalsProps} />
      <TemplatesPopover
        getMenuProps={templatePopoverState.getMenuProps}
        getItemProps={templatePopoverState.getItemProps}
        templateItems={availableTemplateFlows}
      />
    </>
  );
};
