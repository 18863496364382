import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useMerchant, useMultiQueryUtils, usePaymentsBillingManagerLocations } from '@frontend/payments-hooks';

export const useCanDoAction = () => {
  const { locationId } = useMultiQueryUtils();
  const { merchantsData } = useMerchant();
  const { paymentsBillingManagerLocations, isPaymentsBillingManager } = usePaymentsBillingManagerLocations({
    locationId,
  });

  const canRefund: boolean = hasSchemaACL(locationId, Permission.PAYMENTS_REFUNDS);
  const canExport: boolean = hasSchemaACL(locationId, Permission.PAYMENTS_EXPORTS);
  const canModifyWritebacks = paymentsBillingManagerLocations.includes(locationId);
  const allowedPaymentsLocations = paymentsBillingManagerLocations.filter(
    (locationId) => !!merchantsData[locationId]?.hasPayments
  );

  // TODO: adityaSingh, isPaymentsBillingManager will not be exported from this file
  return { canRefund, canExport, canModifyWritebacks, isPaymentsBillingManager, allowedPaymentsLocations };
};
