import { theme } from '@frontend/theme';
import { DemoChip } from '../../demo-chip';
import { TabCard, TabCardProps } from '../components';
import { summaryTabsStyles } from '../styles';

interface RoiSummaryProps {
  isDemoAccount?: boolean;
  summaryCardsData: TabCardProps[];
}

export const RoiSummary = ({ isDemoAccount, summaryCardsData }: RoiSummaryProps) => {
  return (
    <div css={summaryTabsStyles.mainWrapper}>
      {isDemoAccount && <DemoChip style={{ marginBottom: theme.spacing(1) }} />}
      <div css={summaryTabsStyles.statsWrapper}>
        {summaryCardsData.map((props, index) => (
          <TabCard {...props} key={props.cardId ?? index} style={{ border: 'none', padding: 0 }} />
        ))}
      </div>
    </div>
  );
};
