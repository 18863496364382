import { Label } from 'recharts';
import { theme } from '@frontend/theme';

// Due to limitation within recharts, we cannot define or use it as a component.
// Instead, we have to use it as a function.
export const renderCustomCenterLabel = (majorValue: string, minorValue: string) => {
  return (
    <>
      <Label dy={45} fill={theme.colors.white} fontSize={14} fontWeight='bold' position='top' value={majorValue} />
      <Label dy={62} fill={theme.colors.white} fontSize={12} position='top' value={minorValue} />
    </>
  );
};
