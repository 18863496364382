import { useCallback, useEffect, useState } from 'react';
import { Media } from '@weave/schema-gen-ts/dist/shared/sms/v1/enums.pb';
import { MediaApi, MediaQueries, MediaTypes } from '@frontend/api-media';
import { genUUIDV4 } from '@frontend/string';
import { SendingMediaItem } from '../types';

const convertUploadResponseToMedia = (response: MediaTypes.MediaUploadResponse): Media => {
  return {
    mediaId: response.ID,
    url: response.PublicURL || response.SecureURL,
    filename: response.Name,
    createdAt: response.CreatedAt,
  };
};

type UseUploadMediaArgs = {
  groupId: string;
  onChange?: (media: SendingMediaItem[]) => void;
  maxFileCount?: number;
  onError?: (error: unknown) => void;
  onExceedMaxFileCount?: (excessCount: number) => void;
  mediaIds?: string[];
};

type MediaItemWithoutPreview = Omit<SendingMediaItem, 'previewSrc'>;

type UseUploadMediaResult = {
  isLoadingMedia: boolean;
  files: SendingMediaItem[];
  uploadFiles: (files: File[]) => Promise<void>;
  removeMediaItem: (id: string) => void;
};

/**
 * Hook to manage media
 * @param groupId
 * @param maxFileCount
 * @param onError
 * @param onExceedMaxFileCount
 * @param mediaIds - If provided, this media will be used for the initial state of the media.
 */
export const useUploadMedia = ({
  groupId,
  onChange,
  onError,
  onExceedMaxFileCount,
  maxFileCount,
  mediaIds: providedMediaIds,
}: UseUploadMediaArgs): UseUploadMediaResult => {
  const [mediaWithoutPreviews, setMediaWithoutPreviews] = useState<MediaItemWithoutPreview[]>([]);

  const mediaIds = mediaWithoutPreviews.flatMap(({ mediaObj }) => (mediaObj?.mediaId ? [mediaObj.mediaId] : []));
  const mediaPreviewQueries = MediaQueries.useMmsMedia(
    { mediaIds: providedMediaIds ?? mediaIds, locationId: groupId },
    { enabled: !!providedMediaIds?.length || !!mediaIds.length }
  );
  const providedMediaIsLoading = !!providedMediaIds && mediaPreviewQueries.some((query) => query.isLoading);
  const mediaWithPreviews = providedMediaIds
    ? providedMediaIds.map<SendingMediaItem>((mediaId) => ({
        id: mediaId,
        mediaObj: {
          mediaId,
        },
        hasError: false,
        uploading: false,
        previewSrc: mediaPreviewQueries.find((query) => query.data?.mediaId === mediaId)?.data?.src,
      }))
    : mediaWithoutPreviews.map<SendingMediaItem>((mediaItem) => ({
        ...mediaItem,
        previewSrc: mediaPreviewQueries.find((query) => query.data?.mediaId === mediaItem.mediaObj?.mediaId)?.data?.src,
      }));

  const handleMediaChange = useCallback<typeof setMediaWithoutPreviews>(
    (newVal) => {
      setMediaWithoutPreviews((prev) => {
        if (typeof newVal === 'function') {
          const resolvedNewVal = newVal(prev);
          onChange?.(resolvedNewVal);
          return resolvedNewVal;
        }
        onChange?.(newVal);
        return newVal;
      });
    },
    [onChange, setMediaWithoutPreviews]
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      const filesToAdd =
        maxFileCount === undefined ? files : files.slice(0, maxFileCount - mediaWithoutPreviews.length);
      const exceedsMax = filesToAdd.length !== files.length;
      if (exceedsMax) {
        onExceedMaxFileCount?.(files.length - filesToAdd.length);
      }
      const newMediaItems: MediaItemWithoutPreview[] = filesToAdd.map((file) => ({
        id: genUUIDV4(),
        uploading: true,
        hasError: false,
        file,
      }));
      setMediaWithoutPreviews((media) => media.concat(newMediaItems));

      try {
        const responsesWithIds = await Promise.all(
          newMediaItems.map(async ({ file, id }) => {
            if (!file) return;
            try {
              const response = await MediaApi.uploadMedia(
                {
                  data: file,
                  filename: file.name,
                  type: MediaTypes.MediaUploadTypes.Attachment,
                  publicity: true,
                },
                groupId
              );
              return {
                response,
                id,
              };
            } catch (error) {
              setMediaWithoutPreviews((media) => {
                return media.map((mediaItem) => {
                  if (mediaItem.id === id) {
                    return { ...mediaItem, uploading: false, hasError: true };
                  }
                  return mediaItem;
                });
              });
              throw error;
            }
          })
        );
        const mediaFromResponses = responsesWithIds.reduce<{ media: Media; id: string }[]>((acc, response) => {
          if (response) acc.push({ id: response.id, media: convertUploadResponseToMedia(response.response) });
          return acc;
        }, []);
        handleMediaChange((media) =>
          media.map((mediaItem) => {
            const mediaResponse = mediaFromResponses.find((mediaResponse) => mediaResponse.id === mediaItem.id);
            return mediaResponse ? { ...mediaItem, uploading: false, mediaObj: mediaResponse.media } : mediaItem;
          })
        );
      } catch (error) {
        onError?.(error);
      }
    },
    [handleMediaChange, maxFileCount, onError, onExceedMaxFileCount, groupId]
  );

  const removeMediaItem = useCallback(
    (id: string) => {
      handleMediaChange((media) => {
        const mediaItem = media.find((mediaItem) => mediaItem.id === id);
        if (mediaItem?.mediaObj?.mediaId) {
          try {
            MediaApi.deleteMedia(mediaItem.mediaObj.mediaId, MediaTypes.MediaUploadTypes.MMS);
            return media.filter((mediaItem) => mediaItem.id !== id);
          } catch (error) {
            onError?.(error);
          }
        }
        return media;
      });
    },
    [handleMediaChange]
  );

  useEffect(() => {
    if (providedMediaIds !== undefined) {
      setMediaWithoutPreviews(
        providedMediaIds.map((mediaId) => ({ id: mediaId, uploading: false, hasError: false, mediaObj: { mediaId } }))
      );
    }
  }, [JSON.stringify(providedMediaIds)]);

  return {
    isLoadingMedia: providedMediaIsLoading,
    files: mediaWithPreviews,
    uploadFiles,
    removeMediaItem,
  };
};
