import { useEffect, useState } from 'react';
import { CallIntelQueries, CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { formatters } from '../../../utils';
import { InlineStats } from '../../inline-stats';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from '../hooks';

type Props = {
  type: string;
  metricsFor: string;
};

type Stats = {
  rate: number;
  value: number;
};

type UpdateStatsParams = {
  total: number;
  resolved?: number;
  review?: number;
};

const defaultStats = { value: 0, rate: 0 };

export const ServiceQualitySubView = ({ metricsFor, type }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();
  const { filters, isDemoAccount } = useCallIntelShallowStore('dataLabels', 'filters', 'isDemoAccount');
  const [stats, setStats] = useState<Stats>(defaultStats);

  const { data, isFetching } = CallIntelQueries.useGetOverviewStats({
    demoData: CallIntelMockData.getOverviewStats(),
    isDemoAccount,
    payload: {
      filters,
      includes: { serviceQuality: true },
    },
    realQueryOptions: {
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        alert.error(t('Failed to fetch stats'));
        console.error(err);
      },
    },
  });

  const updateStats = ({ total, resolved, review }: UpdateStatsParams) => {
    const isResolved = type === CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION;
    const value = isResolved ? resolved ?? 0 : review ?? 0;
    const rate = total ? Math.round((value / total) * 100) : 0;
    setStats({ value, rate });
  };

  useEffect(() => {
    if (data) {
      const { totalCallsAnalyzed, serviceQualitySummary } = data;
      updateStats({
        total: totalCallsAnalyzed,
        resolved: serviceQualitySummary.callsResolved,
        review: serviceQualitySummary.callsToReview,
      });
    }
  }, [data]);

  return (
    <InlineStats
      data={[
        {
          label: t('{{name}} Calls', { name: metricsFor }),
          value: formatters.value.format(stats.value),
        },
        {
          label: t('{{name}}', {
            name:
              type !== CallIntelTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION
                ? `${metricsFor} Call Rate`
                : `${metricsFor} Rate`,
          }),
          value: `${stats.rate}%`,
        },
      ]}
      isDemoAccount={showDemoChipAndBanner}
      isLoading={isFetching}
    />
  );
};
