import { Entry, Person } from '@weave/schema-gen-ts/dist/schemas/comm/history/v1/history_service.pb';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/comm/shared/v1/enums.pb';
import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { ActionsUI } from '@frontend/contact-actions';
import { i18next } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { HintedString } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  MessageIcon,
  NakedButton,
  PhoneIncomingIcon,
  PhoneMissedIcon,
  PhoneOutgoingIcon,
  Text,
  styles,
} from '@frontend/design-system';

type CallStatus = HintedString<'missed' | 'abandoned' | 'answered'>;

const getPersonName = (person?: Person) => {
  if (person?.lastName && person?.firstName) {
    return PersonHelpers.getFullName({
      FirstName: person?.firstName,
      LastName: person?.lastName,
      PreferredName: person?.preferredName,
    });
  } else {
    return i18next.t('unknown employee', { ns: 'contacts' });
  }
};

const getSmsIcon = () => {
  return <MessageIcon css={{ paddingRight: theme.spacing(1) }} />;
};

const getPhoneIcon = (direction?: Direction, status?: CallStatus) => {
  const isInbound = direction === Direction.DIRECTION_INBOUND;
  const isOutbound = direction === Direction.DIRECTION_OUTBOUND;

  if (isInbound && status !== 'answered') {
    return <PhoneMissedIcon css={{ paddingRight: theme.spacing(1) }} color='error' />;
  }
  if (isInbound) {
    return <PhoneIncomingIcon css={{ paddingRight: theme.spacing(1) }} />;
  }
  if (isOutbound) {
    return <PhoneOutgoingIcon css={{ paddingRight: theme.spacing(1) }} />;
  }
  return null;
};

const getPhoneText = ({
  direction,
  status,
  locationUser,
}: {
  direction?: Direction;
  status?: CallStatus;
  locationUser?: Entry['locationUser'];
}) => {
  const isInbound = direction === Direction.DIRECTION_INBOUND;
  const isOutbound = direction === Direction.DIRECTION_OUTBOUND;

  if (isInbound && status !== 'answered') {
    return i18next.t('Missed call', { ns: 'contacts' });
  }
  if (isInbound) {
    return i18next.t('Call answered by {{person}}', { person: getPersonName(locationUser), ns: 'contacts' });
  }
  if (isOutbound) {
    return i18next.t('Call placed by {{person}}', {
      person: getPersonName(locationUser),
      ns: 'contacts',
    });
  }
  return i18next.t('Unknown', { ns: 'contacts' });
};

const historyEntryContainerStyles = {
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  display: 'flex',
  height: 105,
  padding: theme.spacing(2),
  width: '100%',
};

const getBody = ({ timestamp, body }: { timestamp?: string; body?: string }) => (
  <div>
    <Text textAlign='left' weight='bold'>
      {dayjs(timestamp).calendar()}
    </Text>
    <Text textAlign='left' color='light' css={styles.multilineTruncate(2)}>
      {body}
    </Text>
  </div>
);

export const PhoneHistoryEntry = ({
  direction,
  recordDetails,
  timestamp,
  locationUser,
}: Pick<Entry, 'direction' | 'recordDetails' | 'timestamp' | 'locationUser'>) => (
  <div css={historyEntryContainerStyles} data-trackingid='contact-2.0-tray-tab-history-call'>
    {getPhoneIcon(direction, recordDetails?.status)}
    {getBody({ timestamp, body: getPhoneText({ direction, locationUser, status: recordDetails?.status }) })}
  </div>
);

export const SmsHistoryEntry = ({
  person,
  personPhone,
  recordDetails,
  timestamp,
}: Pick<Entry, 'person' | 'personPhone' | 'recordDetails' | 'timestamp'>) => {
  const { setShow } = useSlidePanelShallowStore('setShow');
  const mediumMax = useMatchMedia({ maxWidth: breakpoints.medium.max });
  const { onClick } = ActionsUI.actions.useMessageAction();

  return (
    <>
      <NakedButton
        css={historyEntryContainerStyles}
        onClick={() => {
          onClick({
            personId: person?.personId,
            threadGroupId: recordDetails?.locationId,
            groupIds: recordDetails?.locationId ? [recordDetails.locationId] : undefined,
            personPhone,
            departmentId: recordDetails?.departmentId,
            targetSmsData:
              recordDetails?.messageId && timestamp
                ? {
                    id: recordDetails?.messageId,
                    createdAt: timestamp,
                  }
                : undefined,
          });
          if (mediumMax) {
            setShow(false);
          }
        }}
        trackingId='contact-2.0-tray-tab-history-message'
      >
        {getSmsIcon()}
        {getBody({ timestamp, body: recordDetails?.body })}
      </NakedButton>
    </>
  );
};
