import { useCallback } from 'react';
import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { Rule, Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { EventData } from '../../components/calendar-view/types';
import { getDateRangeFromRRule, getScheduleInfoFromRRule } from '../../rrule-helper';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../stores/use-calendar-view-v3-header-filter-store';
import { convertToSystemTimezone } from '../../utils';

type GetCalendarEventByPractitionerIdParamsType = {
  calendarEvents: CalendarEvent[];
  practitionerId: string;
  locationId: string;
};

type GetEntityOutOfOfficeEventsArgs = {
  schedules: Schedule[];
  entityId: string;
  locationId: string;
};

export const useGetCalendarEventsV3 = () => {
  const { t } = useTranslation('scheduleCalendarEvents');

  const { selectedCalendarDate } = useCalendarViewV3HeaderFilterShallowStore('selectedCalendarDate');

  const getMappedCalendarEventData = (calendarEvent: CalendarEvent, start: string, end: string): EventData => {
    const hasName = calendarEvent.attendeeDetails?.firstName || calendarEvent.attendeeDetails?.lastName;
    const personName = `${calendarEvent.attendeeDetails?.firstName} ${calendarEvent?.attendeeDetails?.lastName}`.trim();
    const name = hasName ? personName : 'Unknown';

    return {
      id: calendarEvent.id ?? '',
      isNonIntegratedSource: !calendarEvent.isIntegrated,
      startHour: start,
      endHour: end,
      type: 'second',
      status: calendarEvent.attendeeStatus,
      appointmentType: calendarEvent.eventType,
      eventId: calendarEvent.id ?? '',
      createdBySourceId: calendarEvent.sourceTenantId ?? '',
      workstationIds: calendarEvent.operatoryId ? [calendarEvent.operatoryId] : [],
      name: name,
      patientId: calendarEvent.attendeeId ?? '',
      locationId: calendarEvent.locationId ?? '',
      startDate: formatDate(dayjs(calendarEvent.startDate).toDate(), 'MM/DD/YYYY'),
      endDate: formatDate(dayjs(calendarEvent.endDate).toDate(), 'MM/DD/YYYY'),
      referenceId: calendarEvent.referenceId ?? '',
      personStatus: calendarEvent.attendeeDetails?.status ?? '',
    };
  };

  const getPractitionerCalendarEvents = useCallback(
    ({ calendarEvents, locationId, practitionerId }: GetCalendarEventByPractitionerIdParamsType): EventData[] => {
      return (
        calendarEvents
          ?.filter((event) => {
            return !!event.organizerId && event.locationId === locationId && event.organizerId === practitionerId;
          })
          .map((calendarEvent) => {
            // TODO - This is a temporary fix to handle the timezone issue. Need to refactor this code.
            const start = formatDate(
              convertToSystemTimezone({
                dateString: calendarEvent.startDate || '',
                timeString: calendarEvent.startTime || '',
                sourceTimezone: 'UTC',
              }).toDate(),
              'hh:mm A'
            );

            // TODO - This is a temporary fix to handle the timezone issue. Need to refactor this code.
            const end = formatDate(
              convertToSystemTimezone({
                dateString: calendarEvent.endDate || '',
                timeString: calendarEvent.endTime || '',
                sourceTimezone: 'UTC',
              }).toDate(),
              'hh:mm A'
            );

            return getMappedCalendarEventData(calendarEvent, start, end);
          }) || []
      );
    },
    []
  );

  const getEntityBreakEvents = useCallback(
    ({ schedules, locationId, entityId }: GetEntityOutOfOfficeEventsArgs): EventData[] => {
      const entitySchedule = schedules.find(
        (schedule) => schedule.locationId === locationId && schedule.id === entityId
      );

      const breaks = entitySchedule?.recurrenceRules?.breaks || [];

      return breaks.reduce((acc: EventData[], { id = '', name = '', rrule, duration = 0 }: Rule) => {
        if (!rrule) return acc;

        const { startTime, endTime, daysOfWeek } = getScheduleInfoFromRRule(rrule, duration, 'hh:mm A');

        if (daysOfWeek.includes(dayjs(selectedCalendarDate).day())) {
          acc.push({
            id,
            name: name || t('Break'),
            type: 'unavailable',
            startHour: startTime,
            endHour: endTime,
            eventId: id,
            startDate: selectedCalendarDate,
            endDate: selectedCalendarDate,
          });
        }
        return acc;
      }, []);
    },
    [selectedCalendarDate]
  );

  const getEntityExceptionEvents = useCallback(
    ({ schedules, locationId, entityId }: GetEntityOutOfOfficeEventsArgs): EventData[] => {
      const entitySchedule = schedules.filter(
        (schedule) => schedule.locationId === locationId && schedule.id === entityId
      );

      const exceptions = entitySchedule[0]?.recurrenceRules?.override?.unAvailabilities || [];

      const result = exceptions.map(({ id = '', name = '', rrule, duration = 0 }: Rule): any => {
        if (rrule) {
          const { startDateTime, endDateTime } = getDateRangeFromRRule(rrule);

          const startDateTimeObj = dayjs.utc(startDateTime);
          const endDateTimeObj = dayjs.utc(endDateTime);

          const isSameOrAfterStartDate =
            dayjs(selectedCalendarDate).isSame(startDateTimeObj, 'day') ||
            dayjs(selectedCalendarDate).isAfter(startDateTimeObj, 'day');
          const isSameOrBeforeEndDate =
            dayjs(selectedCalendarDate).isSame(endDateTimeObj, 'day') ||
            dayjs(selectedCalendarDate).isBefore(endDateTimeObj, 'day');

          const shouldShowEvent = isSameOrAfterStartDate && isSameOrBeforeEndDate;

          if (shouldShowEvent) {
            const startHour = startDateTimeObj.format('hh:mm A');
            const endHour = startDateTimeObj.add(duration, 'minute').format('hh:mm A');

            return {
              id,
              name,
              type: 'unavailable',
              startHour,
              endHour,
              eventId: id,
            };
          }

          return null;
        }

        return null;
      });

      return result.filter((item) => item !== null);
    },
    [selectedCalendarDate]
  );

  return {
    getPractitionerCalendarEvents,
    getEntityBreakEvents,
    getEntityExceptionEvents,
  };
};
