import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Alert, Button, useModalControl, Text } from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { ChatTitleBar } from '../chat-title-bar/chat-title-bar';
import { Container } from '../common/container';
import { ConversationCategory } from '../conversation-list/conversation-category';
import { NewConversationModal } from '../new-conversation/new-conversation-creation/new-conversation-modal';
import { NavItem } from './nav-item';

export const Nav = () => {
  const { t } = useTranslation('team-chat');
  const mainPanelView = useTeamChatSelector((ctx) => ctx.mainPanelView);
  const openMentions = useTeamChatSelector((ctx) => ctx.openMentions);
  const unreadMentionsCount = useTeamChatSelector((ctx) => ctx.unreadMentionsCount);
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const openDynamicConversation = useTeamChatSelector((ctx) => ctx.openDynamicConversation);
  const dataError = useTeamChatSelector((ctx) => ctx.dataError);
  const isLoading = useTeamChatSelector((ctx) => ctx.isDataLoading);
  const { modalProps, openModal } = useModalControl();

  const groupConversationIds = useMemo(
    () => conversations?.groups?.map((group) => group.channelId) ?? [],
    [conversations?.groups.length]
  );
  const dmConversationIds = useMemo(
    () => conversations?.dm?.map((dm) => dm.channelId) ?? [],
    [conversations?.dm.length]
  );

  const openNewConversation = useCallback(() => openDynamicConversation(undefined), [openDynamicConversation]);

  return (
    <>
      <ChatTitleBar />
      <Container>
        {dataError ? <NavigationPanelError error={dataError} /> : null}
        <section css={css({ paddingLeft: theme.spacing(1) })}>
          <NavItem
            Icon={
              <i
                css={css`
                  margin-top: ${theme.spacing(-0.5)};
                `}
              >
                @
              </i>
            }
            isSelected={mainPanelView === 'mentions'}
            label='Mentions'
            onClick={openMentions}
            trackingId='team-chat-2.0-chat-mentions-category'
            badgeCount={unreadMentionsCount ?? 0}
          />
        </section>
        <ConversationCategory
          title={t('Channels')}
          conversationIds={groupConversationIds}
          expandCollapseButtonTrackingId='team-chat-2.0-chat-channels-category-expand-collapse-button'
          onAddNewButtonClick={openModal}
          addNewButtonLabel={t('Add channel')}
          addNewButtonTrackingId='team-chat-2.0-add-new-channels-button'
          isLoading={isLoading}
        />
        <ConversationCategory
          title={t('Direct Messages')}
          conversationIds={dmConversationIds}
          expandCollapseButtonTrackingId='team-chat-2.0-chat-direct-messages-category-expand-collapse-button'
          onAddNewButtonClick={openNewConversation}
          addNewButtonLabel={t('Add direct message')}
          addNewButtonTrackingId='team-chat-2.0-add-new-direct-messages-button'
          isLoading={isLoading}
        />
        <NewConversationModal modalProps={modalProps} />
      </Container>
    </>
  );
};

const NavigationPanelError = ({ error }: { error: unknown }) => {
  const { t } = useTranslation('team-chat');
  const message = (
    error
      ? typeof error === 'string'
        ? error
        : typeof error === 'object' && 'message' in error
        ? error.message
        : error?.toString()
      : 'An error occurred'
  ) as string;

  const restart = useTeamChatSelector((ctx) => ctx.restart);

  return (
    <Alert type='error'>
      <div>
        <Text>{message}</Text>
        <Button style={{ marginTop: theme.spacing(1) }} onClick={() => restart()}>
          {t('Reload')}
        </Button>
      </div>
    </Alert>
  );
};
