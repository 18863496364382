import { type ComponentProps } from 'react';
import { css, CSSObject } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { theme } from '@frontend/theme';
import { Avatar, AvatarGroup, Text } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { OnlineDot } from './online-dot';

interface Props {
  users: TeamChatTypes.User[];
  showStatusAsTooltip?: boolean;
  shouldShowOnlineStatus?: boolean;
  showGroupAvatars?: boolean;
  size?: ComponentProps<typeof Avatar>['size'];
  css?: CSSObject;
}

/* 
  Adding a prop called shouldShowOnlineStatus to show the online status of the user, since this component can be reused
  in different places where we may or may not want to show the online status.
  Making it default so that, on some places where we don't want to show online, we can hide it.
 */
export const ChatAvatar = ({
  users,
  size,
  shouldShowOnlineStatus = true,
  showGroupAvatars,
  css = { position: 'relative' },
}: Props) => {
  const firstUser = users[0];

  const currentUser = useTeamChatSelector((ctx) => ctx.user);

  if (!firstUser || users.length === 0) {
    // Ideally this should not happen, but in case if there are no members in a chat then we should display an empty avatar
    return <Avatar size={size || 'small'} isUser />;
  }

  const name = getUserFullName(firstUser.firstName, firstUser.lastName);
  const userIsSelf = currentUser?.userID === firstUser.userID;
  // If an user is deactivated then that user will not have the presence property. Hence will crash the app.
  // That is why adding an option chaining here
  const isOnline = users.length == 1 && shouldShowOnlineStatus && (userIsSelf || firstUser.status?.presence?.online);

  if (users.length > 1 && !showGroupAvatars) {
    return (
      <Text weight='bold' size='small' css={memberCountStyle}>
        {users.length}
      </Text>
    );
  } else if (users.length > 1 && showGroupAvatars) {
    return (
      <AvatarGroup fallbackName={''} size={size || 'small'}>
        {users.map((user) => (
          <Avatar name={getUserFullName(user.firstName, user.lastName)} key={user.userID} isUser />
        ))}
      </AvatarGroup>
    );
  }

  return (
    <Avatar size={size || 'xs'} name={name} isUser css={css}>
      {shouldShowOnlineStatus && <OnlineDot isOnline={isOnline} />}
    </Avatar>
  );
};
ChatAvatar.displayName = 'ChatAvatar';

const memberCountStyle = css({
  color: theme.colors.neutral70,
  borderRadius: theme.borderRadius.medium,
  border: `1.5px solid ${theme.colors.neutral70}`,
  minWidth: '24px',
  minHeight: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});
