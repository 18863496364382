import { Campaign, Status_Enum as CampaignStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import dayjs from 'dayjs';
import { BACKEND_DATE_TIME_FORMAT } from '../utils';

export const isReadOnly = (campaign: Campaign | undefined, isUnscheduled: boolean) => {
  const readOnlyStatuses = [
    CampaignStatus.CANCELED,
    CampaignStatus.COMPLETED,
    CampaignStatus.DELETED,
    CampaignStatus.PROCESSING,
  ];
  const startSendAtPlus15Minutes = dayjs(campaign?.startSendAt).add(15, 'minutes').format(BACKEND_DATE_TIME_FORMAT);
  const now = dayjs().format(BACKEND_DATE_TIME_FORMAT);
  const within15MinutesOrPast = startSendAtPlus15Minutes < now;
  const readOnly =
    !!(
      readOnlyStatuses.find((status) => status === campaign?.currentStatus) ||
      (campaign?.currentStatus === CampaignStatus.SCHEDULED && within15MinutesOrPast)
    ) && !isUnscheduled; // TODO: Calculate this isUnscheduled value from the campaign
  return readOnly;
};
