import { useAppScopeStore } from '@frontend/scope';

export const useQuickFillLocationInfo = () => {
  const state = useAppScopeStore();

  return {
    isMultiLocation: !state.isSingleTypeScope,
    parentLocationId: state.selectedGroupId,
    ...state,
  };
};
