import { useTranslation } from '@frontend/i18n';
import { useFollowupNotification } from '@frontend/notifications';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketFollowupEventSubscription = () => {
  const { create } = useFollowupNotification();
  const { t } = useTranslation('notifications');

  return useWebsocketEventSubscription('FollowupNotification', (payload) => {
    switch (payload.params.method) {
      case 'call':
      case 'email':
      case 'text': {
        const notification = {
          id: payload.id,
          timestamp: Date.now(),
          type: 'message-followup',
          payload: {
            method: payload.params.method,
            patient: payload.params.patient,
            text: payload.params.text,
          },
          state: {
            paused: false,
            status: 'unread',
          },
        } satisfies GetWeavePopNotificationByType<'message-followup'>;
        create(notification, {
          os: {
            title: t('Followup'),
            body: `${payload.params.patient.first_name} ${payload.params.patient.last_name}`,
          },
        });
        return;
      }
      default: {
        const _exhaustive: never = payload.params.method;
        return _exhaustive;
      }
    }
  });
};
