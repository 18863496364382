import { useMemo } from 'react';
import { BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { BulkMessagesQueries } from '@frontend/api-messaging';
import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, SpinningLoader, Text } from '@frontend/design-system';
import { AgeFilter } from '../filters/age-filter';
import { AppointmentDateFilter } from '../filters/appointment-date-filter';
import { DateFilter, formatRange } from '../filters/date-filter';
import { MultiselectFilter } from '../filters/multiselect-filter';
import { FilterAccordion } from './accordion';

type Props = {
  accessibleLocationIds: string[];
  onChange: (updates: BulkMessagingTypes.AudienceFilters) => void;
  selectedFilters: BulkMessagingTypes.AudienceFilters;
};

export const SegmentSelectionForm = ({ accessibleLocationIds, selectedFilters: filters, onChange }: Props) => {
  const { t } = useTranslation('messages');
  const { data: filterOptions = [], isLoading: isFiltersLoading } = BulkMessagesQueries.useRecipientFilterOptions();
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { data: organizationsData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId });
  const locations = accessibleLocationIds.map((locationId) =>
    organizationsData.find((location) => location.locationId === locationId)
  );

  const data = useMemo(() => {
    const options = {
      appointmentPractitioner: filterOptions.find((option) => option.filterType === 'APPOINTMENT_PRACTITIONER'),
      appointmentStatus: filterOptions.find((option) => option.filterType === 'APPOINTMENT_STATUS'),
      appointmentType: filterOptions.find((option) => option.filterType === 'APPOINTMENT_TYPE'),
      lastReceivedBulkMessageOptions: ['30+ days', '60+ days', '90+ days', '120+ days', '180+ days'],
      locations,
      primaryInsurance: filterOptions.find((option) => option.filterType === 'PRIMARY_INSURANCE'),
      recallType: filterOptions.find((option) => option.filterType === 'RECALL_TYPE'),
      recipientStatus: filterOptions.find((option) => option.filterType === 'RECIPIENT_STATUS'),
      // sourceTenant: filterOptions.find((option) => option.filterType === 'SOURCE_TENANT'),
    };

    return {
      ...options,
      appointmentPractitionerOptions: Object.values(options.appointmentPractitioner?.filterOptions ?? {}),
      appointmentStatusOptions:
        Object.values(options.appointmentStatus?.filterOptions ?? {}).filter((item) => item) ?? [],
      appointmentTypeOptions: Object.values(options.appointmentType?.filterOptions ?? {}).filter((item) => item) ?? [],
      locationsOptions: locations
        .map((location) => location?.name)
        .filter((location): location is NonNullable<typeof location> => !!location),
      primaryInsuranceOptions:
        Object.values(options.primaryInsurance?.filterOptions ?? {}).filter((item) => item) ?? [],
      recallTypeOptions: Object.values(options.recallType?.filterOptions ?? {}).filter((item) => item) ?? [],
      recipientStatusOptions: Object.values(options.recipientStatus?.filterOptions ?? {}).filter((item) => item) ?? [],

      numberOfAppointmentStatuses: Object.keys(options.appointmentStatus?.filterOptions ?? {}).length,
      numberOfAppointmentTypes: Object.keys(options.appointmentType?.filterOptions ?? {}).length,
      numberOfLocations: locations.length,
      numberOfPrimaryInsurances: Object.keys(options.primaryInsurance?.filterOptions ?? {}).length,
      numberOfRecallTypes: Object.keys(options.recallType?.filterOptions ?? {}).length,
      numberOfRecipientStatuses: Object.keys(options.recipientStatus?.filterOptions ?? {}).length,
      numberOfAppointmentPractitioners: Object.keys(options.appointmentPractitioner?.filterOptions ?? {}).length,
      // numberOfIntegrations: Object.keys(sourceTenant?.filterOptions ?? {}).length,
    };
  }, [filterOptions, locations]);

  return (
    <>
      {isFiltersLoading ? (
        <div css={{ textAlign: 'center', padding: theme.spacing(4, 4, 0) }}>
          <SpinningLoader />
        </div>
      ) : (
        <div css={{ paddingTop: theme.spacing(4) }}>
          <Accordion
            variant='hybrid'
            color={theme.colors.neutral10}
            //TODO: fix up the accordion component in the design system so you can access the styles easier
            //TODO: maybe some of this visibility stuff could be used in the base component
            css={{
              '> div[data-active="true"]': {
                overflow: 'visible',
                'section[role="region"]': {
                  overflow: 'visible',
                },
              },
              '> div > h3 > button': {
                border: `1px solid ${theme.colors.neutral10}`,
                backgroundColor: theme.colors.white,
                borderRadius: theme.borderRadius.medium,
              },
              '> div[data-active="true"] > h3 > button': {
                backgroundColor: theme.colors.neutral10,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                border: `1px solid ${theme.colors.neutral20}`,
              },
            }}
          >
            {data.numberOfAppointmentStatuses || data.numberOfAppointmentTypes ? (
              <FilterAccordion.Item value='appointment-accordion'>
                <FilterAccordion.Header
                  hasFilters={
                    !!filters.appointmentQualifier ||
                    !!filters.appointmentTypes?.length ||
                    !!filters.appointmentStatuses ||
                    !!filters.appointmentBeginDate ||
                    !!filters.appointmentEndDate
                  }
                  label={'Appointment Details'}
                />
                <FilterAccordion.Body>
                  <AppointmentDateFilter
                    appointmentDateRange={[filters.appointmentBeginDate ?? '', filters.appointmentEndDate ?? '']}
                    onChangeAppointmentDateRange={(range) => {
                      const formattedRange = formatRange(range, 'YYYY-MM-DD');
                      onChange({
                        ...filters,
                        appointmentBeginDate: formattedRange?.[0],
                        appointmentEndDate: formattedRange?.[1],
                      });
                    }}
                    appointmentQualifier={filters.appointmentQualifier}
                    onChangeAppointmentQualifier={(appointmentQualifier) =>
                      onChange({ ...filters, appointmentQualifier })
                    }
                  />
                  {data.numberOfAppointmentTypes ? (
                    <>
                      <MultiselectFilter
                        name='appointment-type'
                        value={filters.appointmentTypes}
                        label={t('Appointment Type')}
                        options={data.appointmentTypeOptions}
                        onChange={(appointmentTypes) => onChange({ ...filters, appointmentTypes })}
                      />
                      <Divider margin={theme.spacing(1)} />
                    </>
                  ) : null}

                  {data.numberOfAppointmentStatuses ? (
                    <MultiselectFilter
                      name='appointment-status'
                      value={filters.appointmentStatuses}
                      label={t('Appointment Status')}
                      options={data.appointmentStatusOptions}
                      onChange={(appointmentStatuses) => onChange({ ...filters, appointmentStatuses })}
                    />
                  ) : null}
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfRecallTypes ? (
              <FilterAccordion.Item value='recall-accordion'>
                <FilterAccordion.Header
                  hasFilters={!!filters.recallTypes?.length || !!filters.recallBeginDate || !!filters.recallEndDate}
                  label={t('Recall Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='recall-type'
                    value={filters.recallTypes}
                    label={t('Recall Type')}
                    options={data.recallTypeOptions}
                    onChange={(recallTypes) => onChange({ ...filters, recallTypes })}
                  />
                  <Text>{t('Recall Date')}</Text>
                  <DateFilter
                    name='recall-date'
                    value={[filters.recallBeginDate ?? '', filters.recallEndDate ?? '']}
                    onChange={(range) => {
                      const formattedRange = formatRange(range, 'YYYY-MM-DD');
                      return onChange({
                        ...filters,
                        recallBeginDate: formattedRange?.[0],
                        recallEndDate: formattedRange?.[1],
                      });
                    }}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfLocations > 1 ? (
              <FilterAccordion.Item value='location-accordion'>
                <FilterAccordion.Header hasFilters={!!filters.locationIds?.length} label={t('Locations')} />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='locations'
                    label={t('Locations')}
                    options={data.locationsOptions}
                    value={
                      filters.locationIds
                        ?.map(
                          (locationId) => data.locations.find((location) => location?.locationId === locationId)?.name
                        )
                        .filter((locationName): locationName is NonNullable<typeof locationName> => !!locationName) ??
                      []
                    }
                    onChange={(locationNames) =>
                      onChange({
                        ...filters,
                        locationIds:
                          locationNames
                            ?.map((name) => data.locations.find((location) => location?.name === name)?.locationId)
                            .filter((locationId): locationId is NonNullable<typeof locationId> => !!locationId) ?? [],
                      })
                    }
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfPrimaryInsurances ? (
              <FilterAccordion.Item value='insurance-accordion'>
                <FilterAccordion.Header
                  hasFilters={!!filters.primaryInsurances?.length}
                  label={t('Insurance Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='insurance-type'
                    label={t('Insurance Type')}
                    options={data.primaryInsuranceOptions}
                    value={filters.primaryInsurances}
                    onChange={(primaryInsurances) => onChange({ ...filters, primaryInsurances })}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfRecipientStatuses ? (
              <FilterAccordion.Item value='recipient-accordion'>
                <FilterAccordion.Header
                  hasFilters={!!filters.recipientStatuses?.length || !!filters.minAge || !!filters.maxAge}
                  label={t('Recipient Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='recipient-status'
                    label={t('Recipient Status')}
                    options={data.recipientStatusOptions}
                    value={filters.recipientStatuses}
                    onChange={(recipientStatuses) => onChange({ ...filters, recipientStatuses })}
                  />
                  <Divider margin={theme.spacing(1)} />
                  <Text size='small'>{t('Recipient Age')}</Text>
                  <AgeFilter
                    minAgeLabel={t('Minimum Age')}
                    maxAgeLabel={t('Maximum Age')}
                    minAge={filters.minAge}
                    maxAge={filters.maxAge}
                    onChangeMaxAge={(maxAge) => onChange({ ...filters, maxAge })}
                    onChangeMinAge={(minAge) => onChange({ ...filters, minAge })}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfAppointmentPractitioners ? (
              <FilterAccordion.Item value='appointment-practitioners-accordian'>
                <FilterAccordion.Header
                  hasFilters={!!filters.appointmentPractitioners?.length}
                  label={t('Provider Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='provider-details'
                    label={t('Provider Name')}
                    options={data.appointmentPractitionerOptions}
                    value={filters.appointmentPractitioners}
                    onChange={(appointmentPractitioners) => onChange({ ...filters, appointmentPractitioners })}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}

            {/* TODO: Add back the last received bulk message filter when both email campaigns and bulk text are both using the same backend service.
            This was commented out because the backend does not distinguish between bulk email and bulk text users and so would return bad results.
            For more details, see: https://getweave.slack.com/archives/C06M3770CEM/p1718304588020749?thread_ts=1718301498.597029&cid=C06M3770CEM */}
            {/* <FilterAccordion.Item value='by-last-received-bulk-message'>
              <FilterAccordion.Header
                hasFilters={!!filters.minDaysSinceLastMarketingMessage}
                label={t('Last Received Bulk Message')}
              />
              <FilterAccordion.Body>
                <DropdownFilter
                  name='last-received-bulk-message'
                  label={t('Last Received Bulk Message')}
                  options={data.lastReceivedBulkMessageOptions}
                  value={filters.minDaysSinceLastMarketingMessage?.toString()}
                  onChange={(selection) =>
                    onChange({
                      ...filters,
                      minDaysSinceLastMarketingMessage: selection ? +selection.replace(/\D/g, '') : undefined,
                    })
                  }
                />
              </FilterAccordion.Body>
            </FilterAccordion.Item> */}
          </Accordion>
        </div>
      )}
    </>
  );
};
