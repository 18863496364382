import { useState } from 'react';
import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { RecentCallsTable } from '../components/recent-calls/recent-calls';

export const RecentCalls = () => {
  const { t } = useTranslation('calls');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('');
  return (
    <Page
      css={styles}
      title={t('Recent Calls')}
      subtitle={!!selectedTimeRange && t(`Showing results for {{timerange}}.`, { timerange: selectedTimeRange })}
    >
      <RecentCallsTable setTimeRange={setSelectedTimeRange} />
    </Page>
  );
};

const styles = css`
  max-width: 100%;
  overflow: auto;
  height: 100%;
`;
