import { ContactInfo } from '@weave/schema-gen-ts/dist/schemas/persons/v3/contact_info.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum, PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { titleCase } from '@frontend/string';
import { TransformedLegacyPerson } from './types';

export const transformToLegacyPerson = (person: Person) => {
  return {
    PreferredName: person.preferredName ?? '',
    FirstName: person.firstName ?? '',
    LastName: person.lastName ?? '',
    PersonID: person.personId,
    ContactInfo: person.contactInfo,
    Notes: person.notes ?? '',
    SourceID: person.sourceId ?? '',
    SourceTenantID: person.sourceTenantId ?? '',
    MobilePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_MOBILE,
    }),
    Email: getPersonContactInfoByType({ contactInfo: person?.contactInfo, contactType: ContactType_Enum.EMAIL }),
    HomePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_HOME,
    }),
    PMID: person.personPmid ?? '',
    PMDisplayId: person.personDisplayPmid ?? '',
    Gender: person.gender ?? '',
    Status: titleCase(PersonStatus_Enum[person.status || PersonStatus_Enum.UNSPECIFIED]),
    Birthdate: person.birthdate ?? '',
  } as unknown as TransformedLegacyPerson;
};

export const containsNoAlphabet = (str: string) => !/[a-zA-Z]/.test(str);

export const sanitizePhoneNumber = (txt: string) => txt.replace(/\D/g, '');

type GetPersonContactInfoByTypeArgs = {
  contactInfo?: ContactInfo[];
  contactType: ContactType_Enum;
};

/**
 * Retrieves the contact information of a person based on the specified contact type.
 *
 * @param {GetPersonContactInfoByTypeArgs} args - The arguments object.
 * @param {Array} args.contactInfo - An array of contact information objects.
 * @param {string} args.contactType - The type of contact information to retrieve (e.g., 'email', 'phone').
 * @returns {string} - The destination (value) of the contact information.
 */
export const getPersonContactInfoByType = ({ contactInfo, contactType }: GetPersonContactInfoByTypeArgs) =>
  contactInfo?.find((contactInfoItem) => contactInfoItem.type === contactType)?.destination || '';

export const getShouldRenderSourceDropDown = (dataSources: DataSourcesTypes.DataSource[]) => {
  const hasMoreThanOneDataSource = dataSources && dataSources.length > 1;
  const shouldRenderDataSourceDropdown = hasMoreThanOneDataSource;
  return shouldRenderDataSourceDropdown;
};

export const getIsIntegratedOffice = (syncApps: DataSourcesTypes.DataSource[]): boolean => {
  return syncApps?.some((syncApp) => syncApp.SourceType === 'Integration');
};
