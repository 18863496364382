import { ReactNode } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonsV3 } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { CommonHTMLButtonAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { NakedButton, SpinningLoader } from '@frontend/design-system';
import { ThreadHeaderSize } from '../types';
import { PersonSecondaryInfo } from './person-secondary-info';
import { ThreadTitle } from './thread-title';

type ContactListItemProps = {
  personId: string;
  person?: Person;
  groupIds?: string[];
  size?: ThreadHeaderSize;
  children?: ReactNode;
} & CommonHTMLButtonAttributes;

export const ContactListItem = ({
  personId,
  person: providedPerson,
  groupIds,
  size = 'normal',
  children,
  ...rest
}: ContactListItemProps) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { data: fetchedPerson, isLoading } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId,
      locationIds: groupIds?.length ? groupIds : selectedLocationIds,
    },
    {
      enabled: !providedPerson && !!personId && (!!groupIds?.length || !!selectedLocationIds.length),
    }
  );
  const person = providedPerson || fetchedPerson;
  const { onClick: _, ...restWithoutAction } = rest;

  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  if (isLoading) {
    return (
      <NakedButton
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: size === 'normal' ? theme.spacing(1, 2) : theme.spacing(0.5, 1),
          width: '100%',
        }}
        {...restWithoutAction}
      >
        <SpinningLoader />
      </NakedButton>
    );
  }

  if (!person) return null;

  return (
    <NakedButton
      css={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: isLoading ? 'center' : 'space-between',
          padding: size === 'normal' ? theme.spacing(1, 2) : theme.spacing(0.5, 1),
          gap: theme.spacing(1),
          width: '100%',
          maxWidth: '100%',
          // Make children icon buttons have correct background color on hover
          button: {
            ':hover:not(&[aria-disabled="true"]), :focus': {
              backgroundColor: theme.colors.white,
            },
          },
        },
        !isLoading && {
          ':hover': {
            backgroundColor: theme.colors.neutral5,
          },
        },
      ]}
      {...rest}
      onClick={(e) => {
        setPersonId(person.personId, true, groupIds?.[0]);
        rest.onClick?.(e);
      }}
    >
      {isLoading ? (
        <SpinningLoader />
      ) : (
        <>
          <div
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              minWidth: 0,
            }}
          >
            <Icon name='nested' size={size === 'normal' ? undefined : 16} color='disabled' />
            <ThreadTitle
              text={PersonsV3.PersonHelpers.getFullName(person)}
              avatarProps={{
                size: size === 'normal' ? 'small' : 'xs',
              }}
              personId={personId}
              textProps={{
                type: 'text',
                size: size === 'normal' ? undefined : 'small',
              }}
              openContactPanel={false}
            >
              <PersonSecondaryInfo
                personId={personId}
                person={person}
                css={{
                  padding: theme.spacing(0, 1),
                  borderLeft: `1px solid ${theme.colors.neutral20}`,
                }}
                size={size}
              />
            </ThreadTitle>
          </div>

          {children}
        </>
      )}
    </NakedButton>
  );
};
