import { useEffect } from 'react';

interface UseRemoveAppLoaderProps {
  removeOnMount?: boolean;
}

const removeAppLoader = () => {
  document.querySelectorAll('.app-loader').forEach((loader) => loader.remove());
};

export const useRemoveAppLoader = ({ removeOnMount = true }: UseRemoveAppLoaderProps = {}) => {
  useEffect(() => {
    if (removeOnMount) {
      removeAppLoader();
    }
  }, [removeOnMount]);

  return {
    removeAppLoader,
  };
};
