import { css } from '@emotion/react';
import { CreateResponse } from '@weave/schema-gen-ts/dist/schemas/phone/callqueue-config/v1/callqueue_config_service.pb';
import CallQueueAPI from '@frontend/api-call-queue';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  Button,
  DropdownField,
  SkeletonLoaders,
  TextField,
  useAlert,
  useForm,
  useModalControl,
} from '@frontend/design-system';
import { queryKeys } from '../../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useAudioQuery } from '../../../../utils/use-audio-query';
import { CreateModal } from '../../../call-queue/new/create-button';
import { AddStepPanelProps, ButtonBar, HeaderBar } from './add-step-panel';

export const CallQueueStep = ({ tenantId, initialState, onClose, onBackClick, onProceedClick }: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const { modalProps, triggerProps } = useModalControl();

  const { getFieldProps, isComplete, values } = useForm({
    fields: {
      callQueueId: {
        required: true,
        type: 'dropdown',
        value: initialState?.callObject?.primitiveId,
      },
      callerLabel: {
        required: false,
        type: 'text',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: initialState?.callObject?.callQueueExpansion?.callerLabel,
      },
    },
  });

  const selectedCallQueueId = values.callQueueId ?? '';

  const { data: audioData, isLoading: audioDataIsLoading } = useAudioQuery({
    tenantId,
    allowedOptions: { custom: true },
  });

  const { data = [], isLoading: listDataIsLoading } = useQuery({
    queryKey: [tenantId, ...queryKeys.settings.listCallQueue()],
    queryFn: () => CallQueueAPI.List({ tenantId: tenantId ?? '' }).then((res) => res.callQueues),
    select: (data) => {
      return (data ?? []).sort((a, b) => a.name.localeCompare(b.name));
    },
    enabled: !!tenantId,
    staleTime: 30 * 1000,
    retry: false,
  });

  const { isLoading: callQueueIsLoading, refetch } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.readCallQueue(selectedCallQueueId)],
    queryFn: () => CallQueueAPI.Read({ callQueueId: selectedCallQueueId }),
    staleTime: 30 * 1000,
    retry: false,
    enabled: false,
  });

  const handleProceedClick = async () => {
    // We need to fetch the callQueue data by the selected callQueueId so that we can get the
    // associated greeting media data (if any) to pass to the call object.
    const callQueueResp = await refetch();
    const callQueue = callQueueResp.data;

    if (!callQueue) {
      alerts.error('Failed to fetch call queue data.');
      return;
    }

    // Get the greeting media data for the selected call queue (if it exists).
    // NOTE: We only need this data to show to the user in the node expansion details, if
    // there are any issues with the audio data, we can still proceed with the call object creation
    // without it and do not need to show an error to the user.
    const greetingMediaId = callQueue.greetingMediaId;
    const greetingMedia = audioData?.customMedia.find((audio) => audio.id === greetingMediaId);

    onProceedClick({
      callObject: {
        primitiveId: callQueue.callQueueId,
        primitiveName: callQueue.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        callQueueExpansion: {
          greetingMediaItemId: callQueue.greetingMediaId,
          downloadUrl: greetingMedia?.path,
          greetingMediaFileName: greetingMedia?.name,
          callerLabel: values.callerLabel,
        },
      },
    });
  };

  const handleOnCreateCallQueueSuccess = (data: CreateResponse) => {
    const newCallQueueId = data.callQueueId;

    // @ts-expect-error This is complaining thinking that name and value do not exist on the event target,
    // but they do.
    getFieldProps('callQueueId').onChange({ target: { name: 'callQueueId', value: newCallQueueId } });
  };

  const shouldShowSkeletonLoaders = listDataIsLoading || audioDataIsLoading;

  return (
    <>
      <HeaderBar title={t('Call Queue')} onClose={onClose} />

      <SkeletonLoaders.Loader isLoading={shouldShowSkeletonLoaders} shape='rectangle' width={320} height={50}>
        <DropdownField
          label={t('Select Call Queue')}
          {...getFieldProps('callQueueId')}
          placeholder={t('Select Call Queue')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        >
          <DropdownField.Option
            css={css`
              padding: 0;
            `}
            key={'create-call-queue'}
            value={''}
            isSelectable={false}
          >
            <Button
              iconName='plus'
              variant='tertiary'
              onClick={() => triggerProps.onClick()}
              css={css`
                width: 100%;
                justify-content: start;
              `}
            >
              {t('Create Call Queue')}
            </Button>
          </DropdownField.Option>
          {data.map((queue) => (
            <DropdownField.Option key={queue.callQueueId} value={queue.callQueueId}>
              {queue.name}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </SkeletonLoaders.Loader>

      <SkeletonLoaders.Loader isLoading={shouldShowSkeletonLoaders} shape='rectangle' width={320} height={50}>
        <TextField
          label={t('Caller Label (Optional)')}
          {...getFieldProps('callerLabel')}
          helperText={t('Label displays on Weave phone screen while call is ringing')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        />
      </SkeletonLoaders.Loader>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!isComplete || callQueueIsLoading}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />

      {settingsTenantLocation && (
        <CreateModal
          tenantLocation={settingsTenantLocation}
          modalProps={modalProps}
          onSuccess={handleOnCreateCallQueueSuccess}
        />
      )}
    </>
  );
};
