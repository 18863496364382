import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const sectionStyle = css({ padding: theme.spacing(0, 0.5) });

export const containerStyle = css({ display: 'flex', alignItems: 'center', justifyContent: 'space-between' });

export const chevronContainerStyle = css({
  gap: theme.spacing(1),
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  padding: theme.spacing(0.25, 0.5),
  margin: theme.spacing(0.5, 0),
});

export const titleStyle = css({ lineHeight: 1, color: theme.colors.neutral90, whiteSpace: 'nowrap' });

export const caretStyle = css({ transition: 'transform 300ms', transform: 'rotate(180deg)' });

export const caretTransition = css({ transform: 'rotate(0deg)' });

export const unreadDotStyle = css({
  backgroundColor: theme.colors.critical50,
  height: '8px',
  width: '8px',
  borderRadius: '50%',
});
