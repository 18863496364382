import { css } from '@emotion/react';
import { FreeTrialButton } from '@frontend/free-trials-modules';
import { SideNavOnboardingModuleProgress } from '@frontend/task-tray';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { HelpIcon, NakedButton } from '@frontend/design-system';

type Props = CommonHTMLAttributes & {
  children: React.ReactNode;
  isIntakeFormNav?: boolean;
};

export const GlobalNavNav = ({ isIntakeFormNav = false, children, ...rest }: Props) => {
  return (
    <>
      <nav
        tabIndex={0}
        css={css`
          padding-top: ${theme.spacing(2)};
          overflow-y: auto;
          overflow-x: hidden;
          height: 100%;
          svg {
            // set all nav icons' sizes in one place
            width: 16px;
            height: 16px;
            // override min-width: 24px property on some older icons
            min-width: 0px;
          }
          svg:not(.statusIcon) {
            fill: ${theme.colors.neutral90};
          }
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.neutral30};
            border-radius: ${theme.borderRadius.small};
          }
        `}
        {...rest}
      >
        {!isIntakeFormNav && (
          <header>
            <FreeTrialButton />
            <SideNavOnboardingModuleProgress />
          </header>
        )}
        {children}
      </nav>
      <footer
        css={css`
          border-top: 1px solid ${theme.colors.neutral20};
          display: flex;
          align-items: center;
        `}
      >
        <PendoTrigger />
      </footer>
    </>
  );
};

const PendoTrigger = () => (
  <NakedButton
    id='pendo-trigger'
    css={css`
      position: relative;
      align-items: center;
      background: ${theme.colors.neutral5};
      display: flex;
      height: 3.5em;
      justify-content: center;
      transition: background 0.3s ease-in-out;
      width: 3.5em;
      :hover {
        color: ${theme.colors.primary50};
      }
    `}
  >
    <HelpIcon
      css={css`
        height: 1em;
        width: 1em;
      `}
    />
  </NakedButton>
);
