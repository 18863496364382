import { ReactElement } from 'react';
import { i18next, useTranslation } from '@frontend/i18n';
import { DropdownField, Text, useControlledField } from '@frontend/design-system';
import { QUICK_FILL_TRACKING_IDS } from '../../../tracking-ids';

export enum SortByEnum {
  DateAsc = 'DateAsc',
  DateDesc = 'DateDesc',
  FirstNameAsc = 'FirstNameAsc',
  FirstNameDesc = 'FirstNameDesc',
  LastNameAsc = 'LastNameAsc',
  LastNameDesc = 'LastNameDesc',
}

const getSortDropdownOptionContent = (option: SortByEnum): string | ReactElement => {
  switch (option) {
    case SortByEnum.DateAsc:
      return i18next.t('Oldest on top', { ns: 'schedule-quick-fill' });
    case SortByEnum.DateDesc:
      return i18next.t('Newest on top', { ns: 'schedule-quick-fill' });
    case SortByEnum.FirstNameAsc:
      return (
        <Text>
          {i18next.t('First name', { ns: 'schedule-quick-fill' })}{' '}
          <Text as='span' color='subdued'>
            {i18next.t('(A - Z)', { ns: 'schedule-quick-fill' })}
          </Text>
        </Text>
      );
    case SortByEnum.FirstNameDesc:
      return (
        <Text>
          {i18next.t('First name', { ns: 'schedule-quick-fill' })}{' '}
          <Text as='span' color='subdued'>
            {i18next.t('(Z - A)', { ns: 'schedule-quick-fill' })}
          </Text>
        </Text>
      );
    case SortByEnum.LastNameAsc:
      return (
        <Text>
          {i18next.t('Last name', { ns: 'schedule-quick-fill' })}{' '}
          <Text as='span' color='subdued'>
            {i18next.t('(A - Z)', { ns: 'schedule-quick-fill' })}
          </Text>
        </Text>
      );
    case SortByEnum.LastNameDesc:
      return (
        <Text>
          {i18next.t('Last name', { ns: 'schedule-quick-fill' })}{' '}
          <Text as='span' color='subdued'>
            {i18next.t('(Z - A)', { ns: 'schedule-quick-fill' })}
          </Text>
        </Text>
      );
  }
};

interface Props {
  value: SortByEnum;
  onChange: (value: SortByEnum) => void;
}

const trackingIds = QUICK_FILL_TRACKING_IDS.sendMessagesTab.selectRecipientStep;

export const SortByDropdown = ({ value, onChange }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const sortByFieldProps = useControlledField({ type: 'dropdown', value, onChange });

  return (
    <DropdownField
      {...sortByFieldProps}
      name='sort-by'
      label={t('Sort By')}
      data-trackingid={trackingIds.sortByDropdown}
    >
      {Object.values(SortByEnum).map((value) => (
        <DropdownField.Option key={value} value={value} trackingId={`${trackingIds.sortByDropdown}:${value}`}>
          {getSortDropdownOptionContent(value)}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
};
