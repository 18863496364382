import { ROOT_NODE, useEditor, useNode } from '@craftjs/core';
import { TextSection as TextSectionType } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { EmailAssistantModal } from '@frontend/email-assistant-modal';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RTEditor, useModalControl } from '@frontend/design-system';
import { ComposerSection } from '../../types';
import { getFrameStyle } from '../../utils';
import { useContentComposer } from '../content-composer-provider';
import { TextOutput } from '../outputs';
import { TextSettings } from '../settings';
import { TextTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const TextSection: ComposerSection<TextSectionType> = ({ frame, text }) => {
  const { t } = useTranslation('content-composer');
  const { promptProps } = useContentComposer();

  const { id } = useNode((state) => ({
    id: state.id,
  }));

  const {
    actions,
    selected,
    nodes,
    query: { parseFreshNode },
  } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        isDeletable: query.node(currentNodeId).isDeletable(),
        node: query.node(currentNodeId).get(),
      };
    }

    return {
      selected,
      nodes: state.nodes[ROOT_NODE]?.data.nodes,
    };
  });

  const { modalProps, triggerProps } = useModalControl();

  // TODO: talk to Donat about if it would be possible for RTE's `initialHTML` property to be watched (i.e. manually update this state)
  //       this function wouldn't be necessary if that were the case (just need to update the text prop)
  // TODO: double check that this works for undo and redo
  const handleClick = (value: string) => {
    if (selected?.node) {
      const index = nodes?.indexOf(id);
      // creates a copy of the node to be replaced by a new one
      const parsed = parseFreshNode(selected.node).toNode();
      parsed.data.props.text = value;
      actions.delete(selected.id);
      setTimeout(() => actions.add(parsed, ROOT_NODE, index), 0);
    }
  };

  return (
    <>
      <BaseComposerSection
        sectionType='TextSection'
        title={t('Text')}
        onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
        id={id}
        css={[
          getFrameStyle(frame),
          {
            // because we have removed the dragging capability, we need to shift the input box to the left
            '.editor-input': {
              padding: '0 !important',
              minHeight: '16px !important', // we don't want it to be that large by default since that's not controlled by the user
            },
            // the placeholder text needs this to left align with the removal of the padding
            '.editor-placeholder': {
              top: '0 !important',
              left: '0 !important',
            },
            // the paragraphs have margins and line-heights by default that shouldn't be there since those aren't controlled by the user
            '.editor-paragraph': {
              margin: 0,
              // lineHeight: '100%', // TODO: waiting on Michelle to confirm this is what we want to do
            },
          },
        ]}
        initialHTML={text}
        openAssistantModal={triggerProps.onClick}
      >
        <RTEditor.Editor placeholder={selected ? 'Enter text here...' : ''} />
      </BaseComposerSection>
      <EmailAssistantModal
        modalProps={modalProps}
        onComplete={(value: string) => handleClick(value)}
        previousText={text}
        promptProps={promptProps}
        type='text'
      />
    </>
  );
};

TextSection.craft = {
  custom: {
    controlOutputRender: true,
  },
  props: {
    frame: {
      alignment: 'center',
      backgroundColor: theme.colors.neutral5.toUpperCase(),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    text: '',
  },
  related: {
    output: TextOutput,
    settings: TextSettings,
    tool: TextTool,
  },
};
