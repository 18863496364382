import { LocationFlag } from '@weave/schema-gen-ts/dist/schemas/feature-flags/feature_flags_types.pb';
import { LocationFeatureResponse } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { State } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import { FeatureFlagStrategy } from '../types';

export type FeatureFlag = {
  Name: string;
  Value: boolean;
  DisplayName: string;
};

export interface FlagStore {
  featureFlags: Record<string, Map<string, LocationFlag>>;
  setFeatureFlags: (props: { locationId: string; flags: LocationFlag[] }) => void;
  setFeatureFlag: (props: { locationId: string; flag: LocationFlag }) => void;
  customizationFlags: Record<string, Map<string, LocationFeatureResponse>>;
  customizationFlagMap: Record<string, string>;
  setCustomizationFlag: (props: { locationId: string; flag: LocationFeatureResponse }) => void;
  setCustomizationFlags: (props: { locationId: string; flags: LocationFeatureResponse[] }) => void;
}

export const useFlagStore = createStoreWithSubscribe<FlagStore>(
  (set) => ({
    featureFlags: {},
    setFeatureFlags: ({ locationId, flags }) =>
      set(
        (state) => {
          if (!state.featureFlags[locationId]) {
            state.featureFlags[locationId] = new Map();
          }

          for (const flag of flags) {
            state.featureFlags[locationId].set(flag.flag?.name ?? '', { ...flag, value: flag.value ?? false });
          }
        },
        false,
        'REGISTER_FEATURE_FLAGS'
      ),
    setFeatureFlag: ({ locationId, flag }) =>
      set(
        (state) => {
          if (!state.featureFlags[locationId]) {
            state.featureFlags[locationId] = new Map();
          }

          state.featureFlags[locationId].set(flag.flag?.name ?? '', { ...flag, value: flag.value ?? false });
        },
        false,
        'REGISTER_FEATURE_FLAG'
      ),
    customizationFlags: {},
    customizationFlagMap: {},
    setCustomizationFlag: ({ locationId, flag }) =>
      set(
        (state) => {
          if (!state.customizationFlags[locationId]) {
            state.customizationFlags[locationId] = new Map();
          }

          if (flag.name && flag.id) {
            const name = flag.name.toLowerCase().replace(/\s+/g, '');
            state.customizationFlags[locationId].set(name, flag);
            state.customizationFlagMap[flag.id] = name;
          } else {
            console.warn('Flag name is missing', flag);
          }
        },
        false,
        'REGISTER_CUSTOMIZATION_FLAG'
      ),
    setCustomizationFlags: ({ locationId, flags }) =>
      set(
        (state) => {
          if (!state.customizationFlags[locationId]) {
            state.customizationFlags[locationId] = new Map();
          }

          for (const flag of flags) {
            if (flag.name && flag.id) {
              const name = flag.name.toLowerCase().replace(/\s+/g, '');
              state.customizationFlags[locationId].set(name, flag);
              state.customizationFlagMap[flag.id] = name;
            } else {
              console.warn('Flag name is missing', flag);
            }
          }
        },
        false,
        'REGISTER_CUSTOMIZATION_FLAGS'
      ),
  }),
  { name: 'FlagStore', trace: true }
);

const useFlagShallowStore = createShallowStore(useFlagStore);

export const useAppFlagStore = () => {
  const { setFeatureFlag, setFeatureFlags, setCustomizationFlag, setCustomizationFlags } = useFlagStore.getState();

  const { featureFlags, customizationFlags, customizationFlagMap } = useFlagShallowStore(
    'featureFlags',
    'customizationFlags',
    'customizationFlagMap'
  );

  function getFeatureFlag({ locationIds, flagName }: { locationIds: string[]; flagName: string }) {
    return locationIds.map((locationId) => featureFlags[locationId]?.get(flagName));
  }

  const getFeatureFlagLocationMap = ({ locationIds, flagName }: { locationIds: string[]; flagName: string }) => {
    return locationIds.reduce<Record<string, ReturnType<(typeof featureFlags)[string]['get']>>>((acc, locationId) => {
      const flagValue = featureFlags[locationId]?.get(flagName);
      acc[locationId] = flagValue;
      return acc;
    }, {});
  };

  function getCustomizationFlag({ locationIds, flagName }: { locationIds: string[]; flagName: string }) {
    return locationIds.map((locationId) => customizationFlags[locationId]?.get(flagName));
  }

  const getCustomizationFlagLocationMap = ({ locationIds, flagName }: { locationIds: string[]; flagName: string }) => {
    return locationIds.reduce<Record<string, ReturnType<(typeof customizationFlags)[string]['get']>>>(
      (acc, locationId) => {
        const flagValue = customizationFlags[locationId]?.get(flagName);
        acc[locationId] = flagValue;
        return acc;
      },
      {}
    );
  };

  function getFeatureFlagValue({
    locationIds,
    flagName,
    strategy = 'ANY',
  }: {
    locationIds: string[];
    flagName: string;
    strategy?: FeatureFlagStrategy;
  }) {
    const list = locationIds.map((locationId) => featureFlags[locationId]?.get(flagName)?.value);

    if (strategy === 'ANY') {
      return list.some(Boolean);
    }

    return list.every(Boolean);
  }

  function getCustomizationFlagValue({
    locationIds,
    flagName,
    strategy = 'ANY',
    state = 'ACTIVE',
  }: {
    locationIds: string[];
    flagName: string;
    strategy?: FeatureFlagStrategy;
    state?: keyof typeof State;
  }) {
    const list = locationIds.map((locationId) => customizationFlags[locationId]?.get(flagName)?.state);

    if (strategy === 'ANY') {
      return list.some((_state) => _state === state);
    }

    return list.every((_state) => _state === state);
  }

  return {
    setFeatureFlag,
    setFeatureFlags,
    featureFlags,
    customizationFlags,
    customizationFlagMap,
    setCustomizationFlag,
    setCustomizationFlags,

    getFeatureFlag,
    /**
     * Returns a map of locationIds to their feature flag values for a given feature flag
     */
    getFeatureFlagLocationMap,
    getCustomizationFlag,
    /**
     * Returns a map of locationIds to their customiztion flag values for a given customization flag
     */
    getCustomizationFlagLocationMap,
    getFeatureFlagValue,
    getCustomizationFlagValue,
  };
};
