import { useState } from 'react';
import {
  CallGroup,
  Device,
  DeviceMake_Enum,
  DeviceType_Enum,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useMutation, useQueryClient } from 'react-query';
import { DevicesApi, DevicesQueryKey, DevicesTypes } from '@frontend/api-devices';
import { queryKeys as phoneSyncQueryKeys } from '@frontend/api-phone-sync';
import SipProfileAPI, { SipProfilesApiTypes } from '@frontend/api-sip-profile';
import { SoftphoneApi } from '@frontend/api-softphone';
import { useTranslation } from '@frontend/i18n';
import { FormValues, useAlert } from '@frontend/design-system';
import { softphoneFormFieldsConfigs } from './use-create-softphone-form';

export const useCreateSoftphoneMutation = ({
  tenantId,
  locationIds,
}: {
  tenantId?: string;
  locationIds?: string[];
}) => {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });
  const queryClient = useQueryClient();

  const createSoftphone = useMutation(
    (payload: { extension: string; firstName: string; userID: string; childLocationSelectedId?: string }) => {
      return SoftphoneApi.create({
        startingExtensionNumber: +payload.extension,
        name: `${payload?.firstName}'s Softphone`,
        uniqueKey: payload.userID,
        deviceType: 'softphone',
        tenantId,
        childLocationId: payload.childLocationSelectedId,
      });
    },
    {
      onSuccess: () => {
        /**
         * We delay the invalidation of the listDevices query to ensure the new device has been processed by the backend
         */
        queryClient.invalidateQueries([tenantId, ...DevicesQueryKey.queryKeys.devicesList()]);
        queryClient.invalidateQueries([locationIds, 'user-softphones']);
        setTimeout(() => {
          queryClient.invalidateQueries({
            predicate: (query) => {
              const key = phoneSyncQueryKeys.listDevices().at(-1);
              return key ? query.queryKey.includes(key) : false;
            },
          });
        }, 3000);
      },
    }
  );

  const mutateDeviceExtension = useMutation({
    mutationFn: ({ sipProfile }: { sipProfile: SipProfilesApiTypes.SipProfileType }) =>
      SipProfileAPI.Update({ sipProfile }),
  });

  const mutateDevice = useMutation({
    mutationFn: ({ device }: DevicesTypes.UpdateDeviceType['input']) =>
      DevicesApi.UpdateDevice({
        device,
      }),
  });

  const updateAddress = useMutation({
    mutationFn: ({ deviceId, e911Address }: DevicesTypes.UpdateDeviceE911AddressType['input']) =>
      DevicesApi.UpdateDeviceE911Address({
        deviceId,
        e911Address,
      }),
  });

  const updateCallGroups = useMutation({
    mutationFn: ({ sipProfileId, callGroups }: SipProfilesApiTypes.ReplaceCallGroupsType['input']) =>
      SipProfileAPI.ReplaceCallGroups({
        sipProfileId,
        callGroups,
      }),
  });

  return {
    isLoading,
    isFormSubmitted: isSuccess,
    onSubmit: (values: FormValues<typeof softphoneFormFieldsConfigs>, callGroupsSelected: CallGroup[]) => {
      const payload = {
        extension: values.extension ?? '',
        firstName: values.firstName ?? '',
        userID: values.userID ?? '',
        childLocationSelectedId: values.childLocationSelectedId,
      };

      setIsLoading(true);

      return createSoftphone
        .mutateAsync(payload)
        .then(async ({ line }) => {
          const device = {
            deviceId: line?.device?.id ?? '',
            type: DeviceType_Enum.SOFTPHONE,
            macAddress: line?.device?.macAddress ?? '',
            name: values.deviceName ?? '',
            make: DeviceMake_Enum.UNSPECIFIED,
            model: '',
            callWaitingIndicatorBeep: false,
            mwiVoicemailBoxId: '',
            pagingEnabled: false,
          } satisfies Device;

          return await Promise.allSettled([
            mutateDevice.mutateAsync({ device }),
            mutateDeviceExtension.mutateAsync({
              sipProfile: {
                name: values.deviceName ?? '',
                internalCallerName: values.internalIDName ?? '',
                parkRingbackEnabled: false,
                parkRingbackLagSeconds: 0,
                personalVoicemailBoxId: line?.voicemailBox?.id ?? '',
                sipProfileId: line?.sipProfile.id ?? '',
                extension: {
                  extensionId: line?.extension?.id ?? '',
                  extensionNumber: line?.extension?.number,
                },
                device: {
                  deviceId: line?.device?.id ?? '',
                  deviceName: values.deviceName ?? '',
                  deviceType: DeviceType_Enum.SOFTPHONE,
                  macAddress: line?.device?.macAddress ?? '',
                  uniqueKey: line.device.uniqueKey,
                },
              },
            }),
            updateAddress.mutateAsync({
              deviceId: line?.device?.id ?? '',
              e911Address: {
                id: values.assignedAddressID ?? '',
                name: '',
              },
            }),
            updateCallGroups.mutateAsync({
              sipProfileId: line.sipProfile.id,
              callGroups: callGroupsSelected,
            }),
          ])
            .then(() => {
              setIsSuccess(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch(() => {
          setIsLoading(false);
          alert.error(t('Device Setup Failed'));
        });
    },
  };
};
