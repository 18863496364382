import { useAppScopeStore } from '@frontend/scope';
import { usePhoneSettingsShallowStore } from '../store/settings';

/**
 * Custom hook to retrieve location IDs associated with the tenant that has been selected
 * in the phone settings. This is useful when needing to fetch data that requires locationsIds
 * rather than a tenant ID.
 *
 * This hook uses the `usePhoneSettingsShallowStore` to get the `settingsTenantLocation`
 * and the `useAppScopeStore` to get the `accessibleLocationData`. It filters the
 * accessible locations to find those that match the `phoneTenantId` of the current
 * `settingsTenantLocation`.
 * NOTE: this will not return parent location Ids.
 *
 * @returns {Array} An array of location IDs that match the current phone tenant ID.
 */
export const useTenantLocationIds = () => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const { accessibleLocationData } = useAppScopeStore();
  const tenantLocations = Object.values(accessibleLocationData).filter(
    (location) => !!location.groupId && location.phoneTenantId === settingsTenantLocation?.phoneTenantId
  );

  const tenantLocationIds = tenantLocations.map((location) => location.id);

  return tenantLocationIds;
};
