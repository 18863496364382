import { DynamicFieldProperty_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { PropertyBinding } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/service.pb';
import {
  AppointmentPerson,
  Appointment,
} from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { PropertyBindingsData } from '@frontend/integrated-messaging';

interface TagMappingProps {
  appointmentPerson: AppointmentPerson | undefined;
  selectedOrgName: string;
  groupName: string;
  locationPhone: string;
  appointment: Appointment;
}

export const getMassMessageTagValues = ({
  appointmentPerson,
  selectedOrgName,
  groupName,
  locationPhone,
  appointment,
}: TagMappingProps) => {
  const contextPropertyBindings: PropertyBindingsData = {
    [DynamicFieldProperty_Enum.ORG_NAME]: selectedOrgName,
    [DynamicFieldProperty_Enum.BUSINESS_GROUP_NAME]: groupName,
    [DynamicFieldProperty_Enum.BUSINESS_GROUP_PHONE]: locationPhone,
  };

  if (appointmentPerson) {
    contextPropertyBindings[DynamicFieldProperty_Enum.FIRST_NAME] = appointmentPerson.firstName;
    contextPropertyBindings[DynamicFieldProperty_Enum.LAST_NAME] = appointmentPerson.lastName;
    contextPropertyBindings[DynamicFieldProperty_Enum.PREFERRED_NAME] =
      appointmentPerson.preferredName || appointmentPerson.firstName;
  }
  if (appointment) {
    contextPropertyBindings[DynamicFieldProperty_Enum.EVENT_AT] = appointment.start;
    contextPropertyBindings[DynamicFieldProperty_Enum.APPOINTMENT_TYPE] = appointment.type;
    contextPropertyBindings[DynamicFieldProperty_Enum.PRACTITIONER_NAME] = appointment.practitionerName;
    contextPropertyBindings[DynamicFieldProperty_Enum.EVENT_DURATION] = appointment.duration?.toString() ?? '';
  }

  return Object.entries({
    ...contextPropertyBindings,
  }).reduce<PropertyBinding[]>((acc, [property, value]) => {
    const typedProperty = Object.keys(DynamicFieldProperty_Enum).includes(property)
      ? (property as DynamicFieldProperty_Enum)
      : undefined;
    if (typedProperty && value)
      acc.push({
        property: typedProperty,
        values: [value],
      });
    return acc;
  }, []);
};

export const getUniqueListByKey = <T>(arr: Array<T>, key: keyof T) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
