import { produce } from 'immer';
import { Layout, Layouts } from 'react-grid-layout';
import { GridHeightUnits, WidgetSizes, gridColumns, widgetSizes } from './constants';
import { DashboardBreakpoints } from './types';

/**
 * Clone a grid.
 * @param {number[][]} grid - The grid to clone.
 * @returns {number[][]} A deep copy of the grid.
 */
const cloneGrid = (grid: number[][]): number[][] => grid.map((row) => row.slice(0));

/**
 * Place an element in the grid.
 * @param {number[][]} grid - The grid to place the element in.
 * @param {number} x - The x-coordinate to start placing the element.
 * @param {number} y - The y-coordinate to start placing the element.
 * @param {number} h - The height of the element.
 * @param {number} w - The width of the element.
 * @param {number} count - The number of columns in the grid.
 * @returns {number[][] | false} The updated grid if the element is placed successfully, otherwise false.
 */
const putElement = (
  grid: number[][],
  x: number,
  y: number,
  h: number,
  w: number,
  count: number
): number[][] | false => {
  if (x + w > count) return false;
  const newGrid = cloneGrid(grid);
  for (let row = y; row < y + h; row++) {
    if (newGrid[row] === undefined) newGrid[row] = Array(count).fill(0);
    for (let col = x; col < x + w; col++) {
      if (newGrid[row][col] === 1) return false;
      newGrid[row][col] = 1;
    }
  }
  return newGrid;
};

/**
 * Add filler to the grid.
 * @param {number[][]} grid - The grid to add filler to.
 * @param {number} columns - The number of columns in the grid.
 */
const addFiller = (grid: number[][], columns: number): void => {
  const clone = cloneGrid(grid).reverse();
  for (let y = 0; y < clone.length; y++) {
    for (let x = 0; x < columns; x++) {
      if (clone[y][x] === 0) {
        //@ts-ignore
        if (y === 0 || clone[y - 1][x] === false) {
          //@ts-ignore
          clone[y][x] = false;
        }
      }
    }
  }
};

interface Element {
  h: number;
  w: number;
  y?: number;
  x?: number;
}

/**
 * TODO: It might need a bit more improvement.
 * This function implements a bin packing algorithm that acts similarly to a css grid-auto-flow: dense;
 * @param {Element[]} list - The list of elements to package.
 * @param {number} columns - The number of columns in the grid.
 * @returns {Element[]} The list of elements with their positions in the grid.
 * @throws An error if an element does not fit in the grid.
 */
export const packageElements = (list: Element[], columns: number): Element[] => {
  let grid = [Array(columns).fill(0)];
  const elements = list.slice(0);
  elements.forEach((item) => {
    let placed = false;
    for (let y = 0; y < grid.length; y++) {
      for (let x = 0; x < columns; x++) {
        if (grid[y][x] === 0) {
          const result = putElement(grid, x, y, item.h, item.w, columns);
          if (result !== false) {
            item.y = y;
            item.x = x;
            grid = result;
            placed = true;
            break;
          }
        }
      }
      if (placed) break;
    }
    if (!placed) {
      const len = grid.length;
      const result = putElement(grid, 0, grid.length, item.h, item.w, columns);
      if (result === false) throw new Error('The element does not fit!');
      item.y = len;
      item.x = 0;
      grid = result;
    }
  });
  addFiller(grid, columns);
  return elements;
};

export type WidgetConfig = {
  id: string;
  enabled: boolean;
  size: Record<DashboardBreakpoints, WidgetSizes> | WidgetSizes;
  positions?: Record<DashboardBreakpoints, Layout>;
};

function findAvailablePosition(
  layout: Layout[],
  width: number,
  height: number,
  cols: number
): { x: number; y: number } {
  const occupiedCells = new Set<string>();

  for (const widget of layout) {
    for (let y = widget.y; y < widget.y + widget.h; y++) {
      for (let x = widget.x; x < widget.x + widget.w; x++) {
        occupiedCells.add(`${x},${y}`);
      }
    }
  }

  for (let y = 0; y < Number.MAX_SAFE_INTEGER; y++) {
    for (let x = 0; x <= cols - width; x++) {
      if (isPositionAvailable(occupiedCells, x, y, width, height)) {
        return { x, y };
      }
    }
  }
  return { x: 0, y: 0 }; // Default fallback
}

function isPositionAvailable(occupiedCells: Set<string>, x: number, y: number, width: number, height: number): boolean {
  for (let i = 0; i < height; i++) {
    for (let j = 0; j < width; j++) {
      if (occupiedCells.has(`${x + j},${y + i}`)) {
        return false;
      }
    }
  }
  return true;
}

export function tidyDashboardLayout(
  widgetConfigs: WidgetConfig[],
  breakpoints: DashboardBreakpoints[] = ['large', 'medium', 'small', 'extraSmall']
): WidgetConfig[] {
  return produce(widgetConfigs, (draft) => {
    const enabledWidgets = draft.filter((widget) => widget.enabled);

    breakpoints.forEach((breakpoint) => {
      const widgetsForPacking = enabledWidgets.map((widget) => ({
        id: widget.id,
        size: (typeof widget.size === 'string' ? widget.size : widget.size[breakpoint]) as WidgetSizes,
      }));

      const packedLayouts = optimizedWidgetPacking(widgetsForPacking, gridColumns[breakpoint]);

      packedLayouts.forEach((layout) => {
        const widget = draft.find((w) => w.id === layout.i);
        if (widget) {
          if (!widget.positions) widget.positions = {} as Record<DashboardBreakpoints, Layout>;
          widget.positions[breakpoint] = layout;
        }
      });
    });
  });
}

export function optimizedWidgetPacking(widgets: Array<{ id: string; size: WidgetSizes }>, columns: number): Layout[] {
  const sortedWidgets = widgets.sort((a, b) => {
    const sizeA = widgetSizes[a.size];
    const sizeB = widgetSizes[b.size];
    return sizeB.h - sizeA.h || sizeB.w - sizeA.w;
  });

  const grid: number[][] = [];
  const layouts: Layout[] = [];

  function canPlace(x: number, y: number, w: number, h: number): boolean {
    if (x + w > columns) return false;
    for (let i = y; i < y + h; i++) {
      if (!grid[i]) grid[i] = new Array(columns).fill(0);
      for (let j = x; j < x + w; j++) {
        if (grid[i][j] === 1) return false;
      }
    }
    return true;
  }

  function placeWidget(widget: { id: string; size: WidgetSizes }, x: number, y: number) {
    const { w, h } = widgetSizes[widget.size];
    for (let i = y; i < y + h; i++) {
      for (let j = x; j < x + w; j++) {
        grid[i][j] = 1;
      }
    }
    layouts.push({ i: widget.id, x, y, w, h });
  }

  sortedWidgets.forEach((widget) => {
    const { w, h } = widgetSizes[widget.size];
    let placed = false;

    for (let y = 0; !placed; y++) {
      for (let x = 0; x < columns; x++) {
        if (canPlace(x, y, w, h)) {
          placeWidget(widget, x, y);
          placed = true;
          break;
        }
      }
    }
  });

  return layouts;
}

export function addWidgetToLayout(
  widgetConfig: WidgetConfig[],
  newWidget: { id: string; size: Record<DashboardBreakpoints, WidgetSizes> | WidgetSizes }
): WidgetConfig[] {
  const newWidgetConfig = [...widgetConfig];

  for (const breakpoint in gridColumns) {
    const size =
      typeof newWidget.size === 'string'
        ? widgetSizes[newWidget.size]
        : widgetSizes[newWidget.size[breakpoint as DashboardBreakpoints]];
    const layout = newWidgetConfig
      .map((widget) => widget.positions?.[breakpoint as DashboardBreakpoints])
      .filter(Boolean) as Layout[];
    const position = findAvailablePosition(layout, size.w, size.h, gridColumns[breakpoint as DashboardBreakpoints]);

    const newWidgetPosition: Layout = {
      i: newWidget.id,
      x: position.x,
      y: position.y,
      w: size.w,
      h: size.h,
    };

    const existingWidget = newWidgetConfig.find((widget) => widget.id === newWidget.id) as WidgetConfig;

    if (existingWidget) {
      if (!existingWidget.positions) {
        existingWidget.positions = {} as Record<DashboardBreakpoints, Layout>;
      }

      existingWidget.positions = {
        ...existingWidget.positions,
        [breakpoint as DashboardBreakpoints]: newWidgetPosition,
      };
    } else {
      newWidgetConfig.push({
        id: newWidget.id,
        enabled: true,
        size: newWidget.size,
        positions: {
          [breakpoint as DashboardBreakpoints]: newWidgetPosition,
        } as Record<DashboardBreakpoints, Layout>,
      });
    }
  }

  return newWidgetConfig;
}

/**
 * Tries to place an element in the grid.
 * @param grid - The grid to place the element in.
 * @param startX - The starting x-coordinate.
 * @param startY - The starting y-coordinate.
 * @param width - The width of the element.
 * @param height - The height of the element.
 * @param columns - The total number of columns in the grid.
 * @returns A new grid with the element placed, or false if the placement is not possible.
 */
function placeElement(
  grid: number[][],
  startX: number,
  startY: number,
  width: number,
  height: number,
  columns: number
): number[][] | false {
  if (startX + width > columns) {
    return false;
  }
  grid = cloneGrid(grid);

  for (let row = startY; row < startY + height; row++) {
    if (grid[row] === undefined) {
      grid[row] = Array(columns).fill(0);
    }
    for (let col = startX; col < startX + width; col++) {
      if (grid[row][col] === 1) {
        return false;
      }
      grid[row][col] = 1;
    }
  }
  return grid;
}

const maxColumnsPossible = 4;

/**
 * Finds the optimal position for a new element in the grid.
 * @param layout - The current layout of the dashboard.
 * @param columns - The total number of columns in the grid.
 * @returns The optimal position and size for the new element.
 */
export function findOptimalPosition(
  layout: Layout[],
  columns: number
): { x: number; y: number; width: number; height: number } {
  if (layout.length === 0) {
    return { x: 0, y: 0, width: 2, height: GridHeightUnits.medium };
  }

  const grid: number[][] = [];
  let maxY = 0;

  layout.forEach((widget) => {
    for (let row = widget.y; row < widget.y + widget.h; row++) {
      if (!grid[row]) {
        grid[row] = Array(columns).fill(0);
      }
      for (let col = widget.x; col < widget.x + widget.w; col++) {
        grid[row][col] = 1;
      }
    }

    maxY = Math.max(maxY, widget.y + widget.h);
  });

  for (let row = 0; row <= maxY; row++) {
    for (let col = 0; col < columns; col++) {
      if (!grid[row] || grid[row][col] === 0) {
        let maxWidth = 0;
        for (let width = col; width < columns && (!grid[row] || grid[row][width] === 0); width++) {
          maxWidth++;
        }

        for (const maxHeight of [GridHeightUnits.tall, GridHeightUnits.medium, GridHeightUnits.small]) {
          let canFit = true;
          for (let height = row; height < row + maxHeight && height <= maxY; height++) {
            if (!grid[height]) {
              grid[height] = Array(columns).fill(0);
            }
            if (grid[height][col] === 1) {
              canFit = false;
              break;
            }
          }

          if (canFit) {
            const result = placeElement(grid, col, row, Math.min(maxWidth, maxColumnsPossible), maxHeight, columns);
            if (result !== false) {
              return { x: col, y: row, width: Math.min(maxWidth, maxColumnsPossible), height: maxHeight };
            }
          }
        }
      }
    }
  }

  return { x: 0, y: maxY + 1, width: 2, height: GridHeightUnits.medium };
}

/***
 * Widgets or PLG Widgets should be enabled if `hasAccess` or `hasFeature` is undefined.
 * This is just a small helper to abstract that logic.
 */
export const isEnabled = (feature: boolean | undefined) => feature !== false;

export const transformToLayouts = (widgets: WidgetConfig[]): Layouts => {
  const layouts: Layouts = { large: [], medium: [], small: [], extraSmall: [] };

  for (const widget of widgets) {
    if (!widget.enabled) continue;

    const size =
      typeof widget.size === 'string'
        ? { large: widget.size, medium: widget.size, small: widget.size, extraSmall: widget.size }
        : widget.size;

    for (const breakpoint of Object.keys(size) as DashboardBreakpoints[]) {
      const widgetSize = widgetSizes[size[breakpoint]];
      const position = widget.positions?.[breakpoint] || { x: 0, y: 0 };

      layouts[breakpoint].push({
        i: widget.id,
        w: widgetSize.w,
        h: widgetSize.h,
        x: position.x,
        y: position.y,
      });
    }
  }

  return layouts;
};
