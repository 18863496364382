import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueries,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useLocalizedQuery, useLocationDataStore } from '@frontend/location-helpers';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { get, put } from './api';
import { Setting } from './types';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

const queryKeyBase = 'client-settings';
const queryKeys = {
  getSetting: (set: string, key: string): QueryKey => [queryKeyBase, set, key],
  getMultiSetting: ({
    set,
    key,
    locationId,
  }: Omit<UseGetMultiSettingArgs, 'locationIds'> & {
    locationId: UseGetMultiSettingArgs['locationIds'][number];
  }): QueryKey => [locationId, queryKeyBase, set, key],
};

export const useGetSetting = (set: string, key: string, options: UseQueryOptions<string | undefined> = {}) =>
  useLocalizedQuery({
    ...defaultOptions,
    queryKey: queryKeys.getSetting(set, key),
    queryFn: () => get(set, key),
    ...options,
  });

type UseGetMultiSettingArgs<T extends string = string> = {
  set: string;
  key: string;
  locationIds: string[];
  defaultValue?: T;
};
type UseGetMultiSettingResult<T extends string = string> = Record<
  string,
  UseQueryResult<{ data: T | undefined; locationId: string }>
>;
export const useGetMultiSetting = <T extends string = string>({
  set,
  key,
  locationIds,
  defaultValue,
}: UseGetMultiSettingArgs<T>): UseGetMultiSettingResult<T> => {
  const queries = useQueries(
    locationIds.map((locationId) => ({
      queryKey: queryKeys.getMultiSetting({ set, key, locationId }),
      queryFn: () =>
        get(set, key, {
          headers: {
            'Location-Id': locationId,
          },
        }).then((result) => {
          if (result === undefined) {
            return defaultValue; // Return default value instead of throwing error so that select will be called.
          }
          return result;
        }) as Promise<T>,
      select: (data: T | undefined): Exclude<UseGetMultiSettingResult<T>[string]['data'], undefined> => {
        return {
          data: data ?? defaultValue,
          locationId,
        };
      },
      placeholderData: defaultValue,
    }))
  );

  return locationIds.reduce<Record<string, UseQueryResult<{ data: T | undefined; locationId: string }>>>(
    (acc, locationId) => {
      const query = queries.find((query) => query.data?.locationId === locationId);
      if (query) acc[locationId] = query;
      return acc;
    },
    {}
  );
};

export const useUpdateSettingMutation = (
  set: string,
  key: string,
  options: UseMutationOptions<
    Setting,
    Error,
    string,
    {
      previousSetting: string | undefined;
    }
  > = {}
) => {
  const queryClient = useQueryClient();
  const { locationId } = useLocationDataStore();
  const settingKey = [locationId, queryKeyBase, set, key];

  return useMutation((value: string) => put({ set, key, value }), {
    ...defaultOptions,
    mutationKey: settingKey,
    onMutate: async (updatedSetting) => {
      await queryClient.cancelQueries(settingKey);
      const previousSetting = queryClient.getQueryData<string>(settingKey);
      queryClient.setQueryData(settingKey, updatedSetting);
      return { previousSetting };
    },
    onError: (error: Error, updatedSetting, context) => {
      if (context) {
        queryClient.setQueriesData(settingKey, context.previousSetting);
      }
      if (options.onError) {
        options.onError(error, updatedSetting, context);
      }
    },
    ...options,
  });
};

type UpdateMultiSettingRequest<T extends string = string> = {
  value: T;
  locationId: string;
};
export const useUpdateMultiSettingMutation = <T extends string = string>(
  set: string,
  key: string,
  options: UseMutationOptions<
    Setting,
    Error,
    UpdateMultiSettingRequest,
    {
      previousSetting: string | undefined;
    }
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [queryKeyBase, set, key],
    mutationFn: ({ value, locationId }: UpdateMultiSettingRequest<T>) =>
      put(
        { set, key, value },
        {
          headers: {
            'Location-Id': locationId,
          },
        }
      ),
    ...options,
    onSuccess: async (res, req, ...rest) => {
      const queryKey = queryKeys.getMultiSetting({ set, key, locationId: req.locationId });
      await queryClient.invalidateQueries(queryKey);
      options.onSuccess?.(res, req, ...rest);
    },
    onError: (error: Error, req, ...rest) => {
      const queryKey = queryKeys.getMultiSetting({ set, key, locationId: req.locationId });
      queryClient.invalidateQueries(queryKey);
      options.onError?.(error, req, ...rest);
    },
  });
};
