import { css } from '@emotion/react';
import { BulkTexting } from '@frontend/empty-states#svg';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../../utils';
import { highlightValueStyles } from '../../styles';
import { useBTRoiShallowStore } from '../hooks';

interface Props {
  value: number;
}

export const HighlightBTValue = ({ value }: Props) => {
  const { t } = useTranslation('analytics');
  const { definitionsHelperDateRange } = useBTRoiShallowStore('definitionsHelperDateRange');

  return (
    <div css={[highlightValueStyles, styles.wrapper]}>
      <Text style={{ maxWidth: '620px', zIndex: 1 }}>
        <Trans t={t}>
          <Text as='strong' weight='bold'>
            Bulk Texting
          </Text>{' '}
          <Text as='span'>generated</Text>{' '}
          <Text as='strong' weight='bold' style={{ color: theme.colors.secondary.eggplant50 }}>
            {formatters.currency.format(value)}
          </Text>{' '}
          <Text as='span'>for your business in</Text> <Text as='span'>{definitionsHelperDateRange}</Text>
        </Trans>
      </Text>
      <BulkTexting className='graphic' />
    </div>
  );
};

const styles = {
  wrapper: css`
    background-color: ${theme.colors.secondary.eggplant5};
    border: 1px solid ${theme.colors.secondary.eggplant40};
  `,
};
