import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Event } from 'stream-chat';
import { useChatNotification } from '@frontend/notifications';
import { useAppScopeStore } from '@frontend/scope';
import { useNotificationLocalStorageStore } from '../hooks';
import { useTeamChatStore } from '../providers';
import { StreamConversation, StreamInstance, StreamUserResponse, StreamUser } from '../types';
import { fetchChannel, formatMessage, formatUser, getChannels, getUserStatus, getSortedChannels } from '../utils';

export const useTeamChatClientEvents = () => {
  const { showNotificationWhenActiveConversation, showNotificationWhenTeamChatActive } =
    useNotificationLocalStorageStore('showNotificationWhenActiveConversation', 'showNotificationWhenTeamChatActive');
  const {
    activeConversation,
    conversations,
    currentUser,
    streamClient,
    setActiveConversation,
    setConversations,
    resetActiveConversation,
    removeConversation,
    setUserStatus,
    setCurrentUser,
    setTotalUnreadCount,
    setUnreadMessageCount,
    setIsFetchingChats,
  } = useTeamChatStore([
    'activeConversation',
    'conversations',
    'setActiveConversation',
    'setConversations',
    'streamClient',
    'resetActiveConversation',
    'removeConversation',
    'currentUser',
    'setUserStatus',
    'setCurrentUser',
    'setTotalUnreadCount',
    'setUnreadMessageCount',
    'setIsFetchingChats',
  ]);
  // Adding a ref here because when the client connection changes might not get the updated activeConversation state
  // from zustand store, that is why we are introducing a ref object to keep track of the activeConversation
  const activeConversationRef = useRef<StreamConversation | undefined>(activeConversation);
  const { selectedOrgId } = useAppScopeStore();
  const sortTimeout = useRef<NodeJS.Timeout | null>(null);

  const totalUnreadCount = useMemo(
    () => [...conversations.dm, ...conversations.groups].reduce((acc, chat) => (acc += chat.unreadCount), 0),
    [conversations]
  );

  const validateConnection = useCallback(async (streamClient: StreamInstance) => {
    try {
      const isConnected = !!streamClient?.userID;

      if (!isConnected) {
        await streamClient.connect();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  }, []);

  const { create: showNotification, remove } = useChatNotification({
    onView: (notification) => {
      const channelId = notification.payload.channelId;
      const conversation = [...conversations.dm, ...conversations.groups].find(
        (conversation) => conversation.channelId === channelId
      );

      if (conversation) {
        setActiveConversation(conversation);
      }
      remove(notification.id);
    },
  });

  const getUpdatedConversations = (conversations: StreamConversation[], conversation: StreamConversation) => {
    return conversations.map((c) => (c.channelId === conversation.channelId ? conversation : c));
  };

  const handleUpdateConversations = useCallback(
    (conversation: StreamConversation) => {
      if (sortTimeout.current) {
        clearTimeout(sortTimeout.current);
      }
      sortTimeout.current = setTimeout(() => {
        const isDM = conversation.type === 'DM';
        // Sort the conversations after every event, because we need to keep the list sorted.
        const sortedConversation = getSortedChannels({
          ...conversations,
          dm: isDM ? getUpdatedConversations(conversations.dm, conversation) : conversations.dm,
          groups: !isDM ? getUpdatedConversations(conversations.groups, conversation) : conversations.groups,
        });
        setConversations(sortedConversation);
      }, 1000);
    },
    [conversations, getSortedChannels]
  );

  const handleDeleteChannel = (channelId: string) => {
    if (channelId === activeConversationRef.current?.channelId) resetActiveConversation();
    removeConversation(channelId);
  };

  const handleFetchChannel = async (streamClient: StreamInstance, channelId: string, event?: Event) => {
    try {
      await validateConnection(streamClient);

      const isActiveConversation =
        activeConversationRef.current && activeConversationRef.current.channelId === channelId;
      // We don't want to make unnecessary API calls to stream, hence when we receive a message related event
      // we try to handled it with the help of event object.
      // FIXME: can this be handled in a better way?
      if (
        activeConversationRef.current &&
        event &&
        [
          'message.new',
          'message.deleted',
          'message.updated',
          'reaction.new',
          'reaction.deleted',
          'reaction.updated',
        ].includes(event.type) &&
        event.message &&
        currentUser
      ) {
        const channel = streamClient.getChannelById('team', channelId, {});
        const newConversation = { ...activeConversationRef.current, unreadCount: channel.countUnread() };
        if (event.type === 'message.new') {
          newConversation.messages = [
            ...newConversation.messages,
            formatMessage({ channelId, message: event.message, currentUserId: currentUser?.userID }),
          ];
        } else if (event.type === 'message.deleted') {
          newConversation.messages = newConversation.messages.filter((message) => message.id !== event.message?.id);
        } else {
          newConversation.messages = activeConversationRef.current.messages.map((message) => {
            if (message.id === event.message?.id) {
              return formatMessage({
                channelId,
                currentUserId: currentUser.userID,
                message: event.message,
              });
            }
            return message;
          });
        }
        setActiveConversation(newConversation);
        handleUpdateConversations(newConversation);
      } else {
        const conversation = await fetchChannel(streamClient, channelId);

        // we won't be making any unnecessary api calls to stream as we can handle by just removing it from the list
        if (conversation.isArchived || conversation.isHidden) {
          handleDeleteChannel(channelId);
        } else {
          if (isActiveConversation) {
            // If Chat is already open, update the conversation
            setActiveConversation(conversation);
          }

          // Update the conversation in the list
          handleUpdateConversations(conversation);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChannelEvents = async (event: Event) => {
    try {
      const { channel_id, message, type, user } = event;
      if (!streamClient) {
        return;
      }

      await validateConnection(streamClient);

      switch (type) {
        case 'message.new': {
          if (!message) {
            return;
          }
          const isFromCurrentUser = message.user?.id === streamClient.user?.id;
          const isRegularMessage = message.type !== 'system';
          const shouldShowNotification =
            activeConversationRef.current?.channelId === channel_id
              ? showNotificationWhenTeamChatActive && showNotificationWhenActiveConversation
              : showNotificationWhenTeamChatActive;

          if (!isFromCurrentUser && isRegularMessage && shouldShowNotification) {
            showNotification({
              id: message.id,
              payload: {
                authorName: user?.name ?? '',
                channelId: channel_id ?? '',
                message: message.text ?? '',
                reaction: null,
                isMention: false,
                attachments: null,
              },
              state: {
                paused: false,
                status: 'unread',
              },
              timestamp: new Date(message.created_at ?? '').toLocaleString(),
              type: 'chat-message-new',
            });
          }
          if (channel_id) handleFetchChannel(streamClient, channel_id, event);
          break;
        }

        case 'message.updated':
        case 'reaction.new':
        case 'reaction.deleted':
        case 'reaction.updated':
        case 'message.deleted':
        case 'message.read':
          if (channel_id) handleFetchChannel(streamClient, channel_id, event);
          break;

        case 'channel.deleted':
          if (channel_id) handleDeleteChannel(channel_id);
          break;
        case 'member.removed':
          if (channel_id && currentUser?.userID === event.user?.id) {
            handleDeleteChannel(channel_id);
          } else if (channel_id) {
            handleFetchChannel(streamClient, channel_id);
          }
          break;
        case 'member.added':
          if (channel_id && currentUser?.userID === event.user?.id) {
            const conversations = await getChannels(streamClient, selectedOrgId);
            setConversations(conversations);
            break;
          } else if (channel_id) handleFetchChannel(streamClient, channel_id);
          break;
        case 'channel.updated':
          if (channel_id) handleFetchChannel(streamClient, channel_id);
          break;
        case 'typing.start': {
          if (
            activeConversationRef.current &&
            activeConversationRef.current.channelId === channel_id &&
            event.user &&
            event.user.id !== currentUser?.userID
          ) {
            const usersTyping: StreamUser[] = [
              ...(activeConversation?.usersTyping ? activeConversation.usersTyping : []).filter(
                (user) => event.user?.id !== user.userID
              ),
              formatUser(event.user),
            ];
            setActiveConversation({ ...activeConversationRef.current, usersTyping });
          }
          break;
        }

        case 'typing.stop': {
          if (
            activeConversationRef.current &&
            activeConversationRef.current.channelId === channel_id &&
            event.user &&
            event.user.id !== currentUser?.userID
          ) {
            const usersTyping: StreamUser[] =
              activeConversationRef.current?.usersTyping?.filter((user) => user.userID !== event?.user?.id) ?? [];
            setActiveConversation({ ...activeConversationRef.current, usersTyping });
          }
          break;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClientEvents = useCallback(
    async (event: Event) => {
      try {
        if (!streamClient) {
          return;
        }
        await validateConnection(streamClient);
        const { type } = event;

        switch (type) {
          case 'connection.changed': {
            try {
              // TODO :: Revisit, test and modify as needed
              const conversations = await getChannels(streamClient, selectedOrgId);
              setUnreadMessageCount(0);
              const sortedConversation = getSortedChannels(conversations);
              setConversations(sortedConversation);

              if (activeConversationRef.current && streamClient) {
                [...conversations.dm, ...conversations.groups].every((conversation) => {
                  streamClient.getChannelById('team', conversation.channelId, {})?.off(handleChannelEvents);
                  if (conversation.channelId === activeConversationRef.current?.channelId) {
                    setActiveConversation(conversation);
                    return false;
                  }
                  return true;
                });
                streamClient
                  .getChannelById('team', activeConversationRef.current.channelId, {})
                  .on(handleChannelEvents);
              }
              setIsFetchingChats(false);
            } catch (error) {
              console.error(error);
            }
            break;
          }

          case 'notification.added_to_channel':
          case 'notification.removed_from_channel': {
            try {
              // TODO :: Revisit, test and modify as needed
              setTimeout(async () => {
                const conversations = await getChannels(streamClient, selectedOrgId);
                if (!!activeConversationRef.current) {
                  const convo = event.channel_id?.startsWith('!members')
                    ? conversations.dm.find(({ channelId }) => channelId === event.channel_id)
                    : conversations.groups.find(({ channelId }) => channelId === event.channel_id);
                  convo && setActiveConversation(convo);
                }
                const sortedConversations = getSortedChannels(conversations);
                setConversations(sortedConversations);
              }, 300);
            } catch (error) {
              console.error(error);
            }
            break;
          }

          case 'user.updated': {
            const user: StreamUserResponse | undefined = event.user;
            if (user) {
              const status = getUserStatus(user);
              setUserStatus(user.id, status);
              if (user.id === currentUser?.userID) {
                setCurrentUser(formatUser(user));
              }
            }
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      activeConversationRef.current,
      selectedOrgId,
      setConversations,
      streamClient,
      showNotificationWhenTeamChatActive,
      showNotificationWhenActiveConversation,
    ]
  );

  useEffect(() => {
    activeConversationRef.current = activeConversation;
  }, [activeConversation]);

  useEffect(() => setTotalUnreadCount(totalUnreadCount), [totalUnreadCount]);

  useEffect(() => {
    const chats = [...conversations.dm, ...conversations.groups];

    if (streamClient) {
      streamClient.on(handleClientEvents);
      chats.forEach(({ channelId }) => {
        streamClient.getChannelById('team', channelId, {})?.on(handleChannelEvents);
      });
    }

    return () => {
      if (streamClient) {
        streamClient.off(handleClientEvents);
        chats.forEach(({ channelId }) => {
          streamClient.getChannelById('team', channelId, {})?.off(handleChannelEvents);
        });
      }
    };
  }, [streamClient, conversations, handleChannelEvents, handleClientEvents]);
};
