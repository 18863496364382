import { gql } from 'graphql-request';

export const payoutListQuery = gql`
  query payouts($first: Int, $last: Int, $before: String, $after: String, $filter: PayoutsFilterInput) {
    payouts(first: $first, last: $last, before: $before, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          payoutId
          locationId
          status
          amount
          createdAt
          estimatedArrivalOn
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const allPayoutsQuery = gql`
  query payouts($before: String, $after: String, $filter: PayoutsFilterInput) {
    payouts(before: $before, after: $after, filter: $filter) {
      edges {
        node {
          payoutId
          locationId
          status
          amount
          createdAt
          estimatedArrivalOn
        }
      }
    }
  }
`;

export const payoutsBalanceQuery = gql`
  query balance($filter: BalanceFilterInput) {
    balance(input: $filter) {
      currency
      total
      available
      inTransit
    }
  }
`;
