import { useMemo } from 'react';
import { MultiStep } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';

export const CardOnFileJustifi = () => {
  const { justifiData } = useCollectPaymentMultiContext();

  const label = useMemo(() => {
    return justifiData.enableACHOnFile
      ? PAYMENT_MULTI_STEP_LABELS['card-and-ach-on-file']
      : PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.cardOnFile];
  }, [justifiData.enableACHOnFile]);

  const onClickPrimary = () => {
    // JustiFi logic here
  };

  return (
    <MultiStep.Step
      id={PAYMENT_MULTI_STEPS.cardOnFile}
      label={label}
      header={{ title: label }}
      footer={{
        primary: 'Complete Payment',
        onClickPrimary,
      }}
    >
      <div>JustiFi content here</div>
    </MultiStep.Step>
  );
};
