import { createContext, useContextSelector } from 'use-context-selector';
import { ActiveSipProfile, PhoneSyncApi } from '@frontend/api-phone-sync';
import {
  DeviceSelectModal,
  PhoneSyncModal,
  usePhonePairSuccessHandler,
  usePhoneSyncControl,
} from '@frontend/phone-sync';
import { useAppScopeStore } from '@frontend/scope';
import { ModalControlModalProps, ModalControlTriggerProps, useModalControl } from '@frontend/design-system';
import { useDialpadWidgetControl } from './dialpad-widget-control-provider';

type DialpadEstablishConnectionContextValue = {
  pairDeviceModalProps: ModalControlModalProps;
  pairDeviceTriggerProps: ModalControlTriggerProps;
  connectDeviceModalProps: ModalControlModalProps;
  connectDeviceTriggerProps: ModalControlTriggerProps;
  phoneSyncButtonProps: ReturnType<typeof usePhoneSyncControl>['buttonProps'];
  phoneSyncModalProps: ReturnType<typeof usePhoneSyncControl>['modalProps'];
  deviceSelectModalProps: ModalControlModalProps;
  deviceSelectTriggerProps: ModalControlTriggerProps;
};

const DialpadEstablishConnectionContext = createContext({} as DialpadEstablishConnectionContextValue);

type DialpadConnectProviderProps = {
  children: React.ReactNode;
};

export const DialpadConnectProvider = ({ children }: DialpadConnectProviderProps) => {
  const { selectedLocationIds } = useAppScopeStore();
  const open = useDialpadWidgetControl((ctx) => ctx.open);
  const { modalProps: pairDeviceModalProps, triggerProps: pairDeviceTriggerProps } = useModalControl();
  const { modalProps: connectDeviceModalProps, triggerProps: connectDeviceTriggerProps } = useModalControl();
  const deviceSelectModal = useModalControl();
  const { buttonProps: manualPairProps, modalProps: phoneSyncModalProps } = usePhoneSyncControl();

  const onSyncSuccess = usePhonePairSuccessHandler()(selectedLocationIds);

  const handleSyncSuccess = async (activeSipProfile: ActiveSipProfile) => {
    await onSyncSuccess(activeSipProfile);
    open?.();
  };

  const handleConnectionSuccessCallback = () => {
    connectDeviceModalProps.onClose();
    open?.();
  };

  const value = {
    pairDeviceModalProps,
    pairDeviceTriggerProps,
    connectDeviceModalProps,
    connectDeviceTriggerProps,
    phoneSyncButtonProps: manualPairProps,
    phoneSyncModalProps,
    deviceSelectModalProps: deviceSelectModal.modalProps,
    deviceSelectTriggerProps: deviceSelectModal.triggerProps,
  };

  return (
    <DialpadEstablishConnectionContext.Provider value={value}>
      {children}
      <DeviceSelectModal
        variant='dialpad'
        deviceSelectModal={deviceSelectModal}
        closeModal={handleConnectionSuccessCallback}
        locationIds={selectedLocationIds}
      />
      <PhoneSyncModal
        {...phoneSyncModalProps}
        locationIds={selectedLocationIds}
        onSyncSuccess={handleSyncSuccess}
        syncFn={PhoneSyncApi.pairDevice}
      />
    </DialpadEstablishConnectionContext.Provider>
  );
};

export const useDialpadEstablishConnectionControl = <T extends any>(
  selector: (value: DialpadEstablishConnectionContextValue) => T
) => {
  return useContextSelector(DialpadEstablishConnectionContext, selector);
};
