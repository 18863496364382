import { css } from '@emotion/react';
import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { useTranslation } from '@frontend/i18n';
import { PetIcon, useGetActivePetQuery } from '@frontend/vet-components';
import { PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';

export const PetListAction = ({
  pet,
  handleClick,
  getItemProps,
  index,
  trackingId,
  locationId,
}: {
  pet: PetDetails;
  handleClick: (pet: PetDetails) => void;
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'];
  index: ReturnType<ReturnType<typeof usePopoverMenu>['getItemProps']>['index'];
  trackingId: string;
  locationId?: string;
}) => {
  const { t } = useTranslation('contacts');
  const petDetailsQuery = useGetActivePetQuery({ petId: pet.petId, locationId: locationId || '' });

  return (
    <PopoverMenuItem
      css={css`
        svg {
          height: 18px;
          width: 18px;
        }
      `}
      trackingId={trackingId}
      Icon={() => <PetIcon imageType={pet?.validatedImageType} />}
      {...getItemProps({
        index,
        onClick: () => {
          if (!pet || !petDetailsQuery.data) return;
          handleClick(petDetailsQuery.data);
        },
      })}
    >
      {pet?.name || t('No name')}
    </PopoverMenuItem>
  );
};
