import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { OrderBy, ReviewedStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { InfiniteQueryResult } from '@frontend/types';
import { convertScheduleRequestV3ToV2 } from './useGetScheduleRequest';

export const useGetScheduleRequestInfiniteListQuery = (
  locationId: string
): InfiniteQueryResult<Record<'rows', ScheduleRequest[]>> => {
  const { isScheduleV3FlagEnabled, isLoading } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const scheduleRequestListQuery = ScheduleQueries.useGetScheduleRequestListInfiniteQuery(
    {
      locationId,
      limit: 25,
      statuses: 'Pending',
      orderBy: '-createdAt',
      offset: 0,
    },
    { enabled: !isScheduleV3FlagEnabled && !isLoading }
  );

  const scheduleRequestListQueryV3 = SchedulerV3.Queries.useListBookingSubmissionsInfiniteQuery({
    request: {
      locationsIds: locationId ? [locationId] : [],
      limit: 25,
      reviewedStatuses: [ReviewedStatus.PENDING],
      orderBy: OrderBy.CREATED_AT, // By default it will be order by descending
    },
    options: {
      enabled: isScheduleV3FlagEnabled && !isLoading && !!locationId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page, index) => ({
            rows: (page.bookingSubmissions ?? []).map<ScheduleRequest>(convertScheduleRequestV3ToV2),
            totalCount: page.totalCount ?? 0,
            page: index + 1,
            totalPages: Math.ceil((page.totalCount ?? 0) / 25),
          })),
        };
      },
    },
  });

  return isScheduleV3FlagEnabled ? scheduleRequestListQueryV3 : scheduleRequestListQuery;
};
