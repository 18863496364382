import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { PaymentsTerminalController, TerminalReader } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import {
  Button,
  ConfirmationModal,
  ContentLoader,
  useModalControl,
  styles as dsStyles,
  Heading,
  Chip,
  Text,
} from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { sortReaders } from '../../../utils/readers';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { useDiscoverReaderQuery } from '../../terminals/stripe/hooks/use-discover-readers-query';
import { useTerminalStepContextStripe } from '../../terminals/terminal-step-context/terminal-step-context-stripe';
import { SelectTerminalNavigationSwitch } from './select-terminal-navigation-switch';
import { AddReaderCard, ReaderCard } from './select-terminal-stripe/reader-card';
import { SelectTerminalStepBodyProps } from './select-terminal.types';

export const SelectTerminalStripe = () => {
  return <SelectTerminalNavigationSwitch body={SelectTerminalStripeBody} />;
};

const styles = {
  body: css``,
  footer: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;
    justify-content: flex-end;
  `,
  headerSection: css`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(2)};
    > *:not(:first-child) {
      margin-left: ${theme.spacing(2)};
    }
  `,
  readerSection: css`
    height: 100%;
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    overflow-y: auto;
    ${dsStyles.smallScrollbar(theme)}
  `,
  terminalPaymentsContainer: css`
    min-height: ${theme.spacing(40)};
    display: grid;
    grid-template-rows: auto 1fr;
    overflow-y: hidden;
    align-items: center;
  `,
  locationChip: css`
    max-width: 200px;
  `,
};

const SelectTerminalStripeBody = ({
  onGoBack,
  onReaderSelected,
  onClickRegisterTerminal,
}: SelectTerminalStepBodyProps) => {
  const {
    invoice,
    stripeData: { stripeLocationId },
    paymentsUrl,
  } = useCollectPaymentMultiContext();

  const locationId = invoice?.locationId;

  const { t } = useTranslation('payments');
  const { selectedReader, setSelectedReader } = useTerminalStepContextStripe();
  const { modalProps, triggerProps } = useModalControl();
  const [tempReader, setTempReader] = useState<TerminalReader | null>(null);

  const { getLocationName, allLocations } = useMultiQueryUtils();

  const handleReaderSelect = async (reader: TerminalReader) => {
    try {
      if (!locationId || !paymentsUrl || !reader) return;
      const selectedReaderTemp = query.data?.find((terminal) => terminal.id === reader.id);
      if (selectedReaderTemp?.action?.status === 'in_progress') {
        setTempReader(reader);
        triggerProps.onClick();
      } else {
        const selected = PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, reader);
        setSelectedReader(selected);
        onReaderSelected(selected);
      }
    } catch (error) {
      console.error('error in connecting to the terminal', error);
    }
  };

  const onConfirm = async () => {
    try {
      if (!locationId || !paymentsUrl || !tempReader) return;
      const selectedReaderTemp = query.data?.find((terminal) => terminal.id === tempReader.id);
      await PaymentsTerminalApi.cancelTerminalAction({
        readerId: selectedReaderTemp?.id || '',
        paymentIntentId: selectedReaderTemp?.action?.ProcessPaymentIntent?.id || '',
        paymentsUrl,
        locationId,
      });

      const selected = PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, tempReader);
      setSelectedReader(selected);
      onReaderSelected(selected);
    } catch (error) {
      console.error('error canceling in progress payment', error);
    }
  };

  const goBack = useCallback(() => {
    onGoBack();
  }, []);

  const query = useDiscoverReaderQuery({ locationId, stripeLocationId, paymentsUrl: paymentsUrl ?? undefined });

  useEffect(() => {
    const storedReader = PaymentsTerminalController.getStoredReader(locationId!);
    /* by checking for the selectedReader, we are automatically redirecting the user to the 
    collection flow only for the first time until the modal is closed and opened again
    */

    if (storedReader && !selectedReader) {
      const storedReaderIsOnline = query.data?.find(
        (reader) => reader.id === storedReader.readerId && reader.status === 'online'
      );
      if (!storedReaderIsOnline) return;

      const storedReaderIsInUse = storedReaderIsOnline?.action?.status === 'in_progress';
      if (storedReaderIsInUse) return;

      setSelectedReader(storedReader);
      onReaderSelected(storedReader);
    }
  }, [query.data, locationId, selectedReader]);

  const loading = query.isLoading || query.isRefetching;

  const sortedReaders = useMemo(() => sortReaders(query.data ?? []), [query.data]);

  const { backTrackingId, registerTerminalTrackingId, refreshTerminalTrackingId, cancelTrackingId } =
    useScopedTrackingIds({
      backTrackingId: `${PAYMENT_MULTI_STEPS.selectTerminal}--back-btn`,
      registerTerminalTrackingId: `${PAYMENT_MULTI_STEPS.selectTerminal}--register-terminal-btn`,
      refreshTerminalTrackingId: `${PAYMENT_MULTI_STEPS.selectTerminal}--refresh-terminal-btn`,
      cancelTrackingId: `${PAYMENT_MULTI_STEPS.selectTerminal}--cancel-btn`,
    });

  return (
    <>
      <StyledStep
        id={PAYMENT_MULTI_STEPS.selectTerminal}
        label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.selectTerminal]}
        css={styles.body}
        components={{
          Footer: () => (
            <>
              {!loading && sortedReaders.length > 0 ? (
                <div className='step__footer' css={styles.footer}>
                  <Button
                    variant='tertiary'
                    style={{ marginRight: 'auto' }}
                    onClick={goBack}
                    trackingId={backTrackingId}
                  >
                    {t('Back')}
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      query.refetch();
                    }}
                    trackingId={refreshTerminalTrackingId}
                    disabled={loading}
                    iconName='update'
                  >
                    {loading ? t('Refreshing Terminals') : t('Refresh Terminals')}
                  </Button>
                </div>
              ) : (
                <div className='step__footer' css={styles.footer}>
                  <Button variant='tertiary' style={{ marginRight: 'auto' }} onClick={goBack}>
                    {t('Back')}
                  </Button>

                  <Button variant='secondary' trackingId={cancelTrackingId} onClick={goBack}>
                    {t('Cancel')}
                  </Button>

                  <Button onClick={onClickRegisterTerminal} trackingId={registerTerminalTrackingId} disabled={loading}>
                    {t('Register a Terminal')}
                  </Button>
                </div>
              )}
            </>
          ),
        }}
      >
        <div css={{ position: 'relative' }}>
          <ContentLoader show={loading} message={t('Discovering terminals...')} />
          <div css={styles.terminalPaymentsContainer}>
            <section css={styles.headerSection}>
              <Heading level={3}>{t('Select a terminal')}</Heading>
              {locationId && allLocations.length > 1 && (
                <Chip.SingleChip css={styles.locationChip}>{getLocationName(locationId)}</Chip.SingleChip>
              )}
            </section>
            {!loading && sortedReaders.length > 0 ? (
              <section css={styles.readerSection}>
                {sortedReaders.map((reader) => (
                  <ReaderCard
                    key={reader.id}
                    reader={reader}
                    isConnected={false}
                    onClick={(reader) => handleReaderSelect(reader)}
                  />
                ))}
                <AddReaderCard onClick={onClickRegisterTerminal} />
              </section>
            ) : (
              <Text weight='bold' textAlign='center' css={{ width: '100%' }}>
                {t('No terminals registered for this account.')}
              </Text>
            )}
          </div>
        </div>
      </StyledStep>

      <ConfirmationModal
        {...modalProps}
        title={t('This terminal is in use')}
        message={t('Override terminal payment, or select another terminal.')}
        onConfirm={onConfirm}
        confirmLabel={t('Override Terminal Payment')}
        onCancel={modalProps.onClose}
      />
    </>
  );
};
