import { useMemo } from 'react';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { SettingType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/settings/v1/settings.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { MessagesTypes, SchemaSMSSharedEnums } from '@frontend/api-messaging';
import { MessagingSettingsV1 } from '@frontend/api-messaging-settings';
import { NumberBlockV1 } from '@frontend/api-sms-number-block';
import { InboxType } from '@frontend/inbox-navigation';
import { useAppScopeStore } from '@frontend/scope';
import { MessageStatus } from '../components';

type UseThreadStatusProps = {
  thread: MessagesTypes.InboxListItem | ScheduledSmsThread | ThreadDraft;
  drafts?: ThreadDraft[];
  inboxType: InboxType;
  scheduledThreadsMap?: Record<string, ScheduledSmsThread[]>;
};
export const useThreadStatus = ({ thread, drafts, inboxType, scheduledThreadsMap }: UseThreadStatusProps) => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const draft = drafts?.find((draft) => draft.threadId === thread.threadId);
  const hasError = 'status' in thread && thread.status === MessagesTypes.KnownThreadStatuses.ERROR;
  const isRead = 'status' in thread && thread.status === MessagesTypes.KnownThreadStatuses.READ;
  const isNew = 'status' in thread && thread.status === MessagesTypes.KnownThreadStatuses.NEW;
  const isOutbound = 'direction' in thread && thread.direction === SchemaSMSSharedEnums.Direction.DIRECTION_OUTBOUND;
  const isReplied = 'isReplied' in thread ? thread.isReplied : true;

  const isBlockedQuery = NumberBlockV1.Queries.useIsBlockedQuery({
    request: {
      locationId: thread.locationId,
      personPhone: thread.personPhone,
    },
    options: {
      enabled: inboxType === InboxType.SCHEDULED || inboxType === InboxType.DRAFTS,
    },
  });
  const isBlocked =
    'isBlocked' in thread ? !!thread.isBlocked : isBlockedQuery.data ? !!isBlockedQuery.data?.isBlocked : false;

  const { data: showUnrepliedStatusFromList, isFetched: showUnrepliedStatusFromListIsFetched } =
    MessagingSettingsV1.Queries.useListQuery(
      {
        settingType: SettingType_Enum.INBOX_LOCATION_SETTING,
        locationIds: selectedLocationIds,
        orgId: selectedOrgId,
      },
      {
        select: (data) => {
          const locationSetting = data.settings.find((setting) => setting.locationId === thread.locationId);
          return !!locationSetting?.setting && 'inboxLocationSetting' in locationSetting.setting
            ? locationSetting?.setting.inboxLocationSetting.showUnrepliedStatus
            : undefined;
        },
      }
    );
  const { data: showUnrepliedStatusFromGet } = MessagingSettingsV1.Queries.useGetQuery(
    {
      settingType: SettingType_Enum.INBOX_LOCATION_SETTING,
      locationId: thread.locationId,
      orgId: selectedOrgId,
    },
    {
      enabled: showUnrepliedStatusFromListIsFetched && showUnrepliedStatusFromList === undefined,
      select: (data) => {
        return 'inboxLocationSetting' in data.setting
          ? data.setting.inboxLocationSetting.showUnrepliedStatus
          : undefined;
      },
    }
  );
  const showUnrepliedStatus = showUnrepliedStatusFromList ?? showUnrepliedStatusFromGet;

  const status = useMemo(() => {
    if (isNew) return MessageStatus.UNREAD;
    if (!!draft) return MessageStatus.DRAFT;
    if (isBlocked) return MessageStatus.BLOCKED;
    if (hasError) return MessageStatus.ERROR;
    if (scheduledThreadsMap?.[thread.threadId]) {
      const scheduledThreads = scheduledThreadsMap[thread.threadId];
      if (scheduledThreads?.length === 1) {
        if (scheduledThreads[0]?.status !== OutboundMessageStatus.PAUSED) {
          return MessageStatus.SCHEDULED;
        }
        return MessageStatus.PAUSED_SCHEDULED;
      }
      return MessageStatus.MULTIPLE_SCHEDULED;
    }
    if ('status' in thread && thread.status === OutboundMessageStatus.PAUSED) return MessageStatus.PAUSED_SCHEDULED;
    if ('status' in thread && thread.status === OutboundMessageStatus.SCHEDULED) return MessageStatus.SCHEDULED;
    if (inboxType === InboxType.ARCHIVED) return MessageStatus.ARCHIVED;
    if (isOutbound) return MessageStatus.OUTGOING_SENT;
    if (showUnrepliedStatus && !isReplied) return MessageStatus.UNREPLIED;
    return MessageStatus.READ;
  }, [
    isNew,
    !!draft,
    isBlocked,
    hasError,
    !!scheduledThreadsMap?.[thread.threadId],
    isOutbound,
    inboxType,
    isReplied,
    showUnrepliedStatus,
  ]);

  return {
    status,
    isDraft: !!draft,
    isRead,
    isNew,
    isBlocked,
    isOutbound,
    hasError,
    isBlockedQuery,
  };
};
