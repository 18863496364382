import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  flex: 1;
  padding: ${theme.spacing(4)} 0;
  overflow: auto;
`;
