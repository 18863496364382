import { useMemo } from 'react';
import { LocationsFeaturesResponse } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { UseQueryOptions } from 'react-query';
import { useQuery } from '@frontend/react-query-helpers';
import { SchemaPlatformLocationFeature } from '@frontend/schema';
import { getCustomizationsFlagsFeaturesList } from './api';
import { CustomizationFlagState, LocationIdWiseCustomizationFlagDetails } from './types';
import { FLAG_ACTIVE_VALID_STATES, isCustomizationFlagActive } from './utils';

export const getCustomizationFlagsByLocationId = (locationId: string) =>
  getCustomizationsFlagsFeaturesList(locationId).then(
    (customizationFlagsData) => customizationFlagsData?.features ?? []
  );

/**
 * @deprecated This function is deprecated. Please use `useScopedAppFlagStore` instead.
 */
export const useMultiCustomizationFlags = (
  locationIds: string[],
  options: UseQueryOptions<LocationsFeaturesResponse, unknown, LocationsFeaturesResponse, string[]> = {}
) => {
  return useQuery({
    queryKey: ['customization-flags', ...locationIds],
    queryFn: () => SchemaPlatformLocationFeature.LocationsFeatures({ locationIds }),
    ...options,
    enabled: !!locationIds?.length && options.enabled !== false,
  });
};

type UseAggregateCustomizationFlagDetailsType = {
  locationIds: string[];
  customizationFlag: Feature;
  enabled?: boolean;
  activeStates?: CustomizationFlagState[];
};

type UseAggregateCustomizationFlagDetailsReturnType = LocationIdWiseCustomizationFlagDetails & {
  isLoading: boolean;
};

/**
 * @deprecated This function is deprecated. Please use `useScopedAppFlagStore` instead.
 */
export const useAggregateCustomizationFlagDetails = ({
  locationIds,
  customizationFlag,
  enabled = true,
  activeStates = FLAG_ACTIVE_VALID_STATES,
}: UseAggregateCustomizationFlagDetailsType): UseAggregateCustomizationFlagDetailsReturnType => {
  const { data: locationWiseCustomizationFlags, isLoading } = useMultiCustomizationFlags(locationIds, {
    enabled,
    cacheTime: 1000 * 60 * 5, // 5 minutes
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const locationIdWiseCustomizationFlagDetails = useMemo<Record<string, boolean>>(() => {
    return Object.entries(locationWiseCustomizationFlags?.locationsFeatures || {}).reduce((acc, [key, value]) => {
      const isFlagEnabled =
        value?.features?.some(
          ({ featureEnum, state }) =>
            featureEnum === customizationFlag && isCustomizationFlagActive({ state, activeStates })
        ) || false;

      acc[key] = !!isFlagEnabled;

      return acc;
    }, {} as Record<string, boolean>);
  }, [locationWiseCustomizationFlags, activeStates, customizationFlag]);

  const { isFeatureHiddenInAllLocations, isFeatureActiveInAllLocations } = useMemo(() => {
    const isFeatureHiddenInAllLocations = Object.values(locationIdWiseCustomizationFlagDetails).every(
      (value) => !value
    );
    const isFeatureActiveInAllLocations = Object.values(locationIdWiseCustomizationFlagDetails).every((value) => value);
    return {
      isFeatureHiddenInAllLocations,
      isFeatureActiveInAllLocations,
    };
  }, [locationIdWiseCustomizationFlagDetails]);

  if (!locationWiseCustomizationFlags?.locationsFeatures) {
    return {
      isFeatureHiddenInAllLocations: false,
      isFeatureActiveInAllLocations: false,
      locationIdWiseCustomizationFlagDetails: {},
      isLoading,
    };
  }

  return {
    isFeatureHiddenInAllLocations,
    isFeatureActiveInAllLocations,
    locationIdWiseCustomizationFlagDetails,
    isLoading,
  };
};
