import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { MultiStep, StepButtonProps, StepCloseButton, StepHeaderProps, StepProps } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../utils/steps';

const styles = {
  default: css`
    row-gap: 0;

    .step__header,
    .step__footer {
      padding: ${theme.spacing(3)};
    }

    .step__body {
      padding: 0 ${theme.spacing(3)};
    }
  `,
  new: css`
    .step__header {
      border-radius: ${theme.borderRadius.medium};
      background: linear-gradient(0.25turn, ${theme.colors.primary70}, ${theme.colors.primary50});
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
      border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0px 0px;
    }

    .step__header-title,
    .step___header-action button svg {
      color: white;
    }
  `,
  legacy: css``,

  defaultFooter: css`
    .step__body {
      margin-bottom: ${theme.spacing(3)};
    }
  `,
};

export const StyledStep = ({
  header,
  components,
  children,
  hasInternalFooter = false,
  ...props
}: StepProps & { hasInternalFooter?: boolean }) => {
  const { Header, ...cs } = components || {};

  const {
    newRequestUI,
    multiStep: { activeStep },
  } = useCollectPaymentMultiContext();

  const CustomHeader = Header || StyledHeader;

  const blueHeaderSteps: string[] = [PAYMENT_MULTI_STEPS.create, PAYMENT_MULTI_STEPS.paymentMethods];
  return (
    <MultiStep.Step
      {...props}
      header={header}
      components={{ ...cs, Header: CustomHeader }}
      css={[
        styles.default,
        newRequestUI && blueHeaderSteps.includes(activeStep) ? styles.new : styles.legacy,
        hasInternalFooter && styles.defaultFooter,
      ]}
    >
      {children}
    </MultiStep.Step>
  );
};

const StyledHeader = ({ components, ...props }: StepHeaderProps) => {
  const { Action = StyledAction, ...cs } = components || {};

  return (
    <MultiStep.Step.Header
      {...props}
      components={{
        ...cs,
        Action,
      }}
    />
  );
};

const StyledAction = (props: StepButtonProps) => {
  return <StepCloseButton {...props} size='small' />;
};
