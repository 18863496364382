import { MouseEventHandler, useEffect, useState } from 'react';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Modal, ModalProps, SecondaryButton } from '@frontend/design-system';
import { SendInThreadModalContents } from '../components';
import { OnThreadChangeFn, SendInThreadTemplateData } from '../types';

type BaseProps = Omit<ModalProps, 'children'> & {
  groupId: string;
  initThreadId?: string;
  onBack: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonProps?: Parameters<typeof SecondaryButton>[0];
  onThreadChange?: OnThreadChangeFn;
  hideSelectTemplateButton?: boolean;
};
type RecipientData =
  | {
      personId?: string;
      initPersonPhone: string;
      petId?: string;
    }
  | {
      personId: string;
      initPersonPhone?: string;
      petId?: string;
    };

export type SendInThreadModalProps = BaseProps & RecipientData & SendInThreadTemplateData;

/**
 * A modal wrapper for the `SendInThreadModalContents` component.
 * See [SendInThreadModalContents component](../components/send-in-thread/send-in-thread-modal-contents) for more details on props.
 */
export const SendInThreadModal = ({
  groupId,
  initThreadId,
  initPersonPhone,
  personId,
  onBack,
  secondaryButtonProps,
  templateType,
  contextPreviewProps,
  linkData,
  onThreadChange,
  initialBody,
  initialMediaIds,
  hideSelectTemplateButton,
  propertyBindings,
  ...modalProps
}: SendInThreadModalProps) => {
  const [temporarilyHideModal, setTemporarilyHideModal] = useState(false);
  const { isOpen } = useSettingsNavigate();

  useEffect(() => {
    if (!isOpen && temporarilyHideModal) {
      setTemporarilyHideModal(false);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        css={{
          minWidth: 'min(800px, 90%)',
        }}
        {...modalProps}
        show={!temporarilyHideModal && modalProps.show}
      >
        <SendInThreadModalContents
          groupId={groupId}
          initThreadId={initThreadId}
          initPersonPhone={initPersonPhone}
          personId={personId ?? ''}
          onBack={onBack}
          secondaryButtonProps={secondaryButtonProps}
          templateType={templateType}
          contextPreviewProps={contextPreviewProps}
          linkData={linkData}
          onThreadChange={onThreadChange}
          initialBody={initialBody}
          initialMediaIds={initialMediaIds}
          hideSelectTemplateButton={hideSelectTemplateButton}
          onClose={modalProps.onClose}
          onSend={() => {
            modalProps.onClose();
          }}
          onSchedule={() => {
            modalProps.onClose();
          }}
          onOpenSettings={() => {
            setTemporarilyHideModal(true);
          }}
          propertyBindings={propertyBindings}
        />
      </Modal>
    </>
  );
};
