import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { phoneAnalyticsDemoData } from '../demo-data';
import { useIsPhoneAnalyticsDemoAccount } from './use-is-phone-analytics-demo-account';

export const usePhoneAnalyticsDemoData = () => {
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();

  const demoData = useMemo(() => {
    if (typeof isDemoAccount === 'undefined' || !isDemoAccount) {
      return null;
    }

    const locationsData = accessibleLocationData;

    return {
      chartsData: phoneAnalyticsDemoData.charts.data(selectedLocationIds),
      tableData: phoneAnalyticsDemoData.table.data(selectedLocationIds, locationsData),
    };
  }, [selectedLocationIds.sort().join('-'), isDemoAccount]);

  return demoData;
};
