import { Direction, Encoding, Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { SmsNewMessageEventPayload } from '@frontend/websocket';
import { convertStringToStatus } from './convert-string-to-status';

export const convertWSPayloadToSMS = (params: SmsNewMessageEventPayload['params']): SMS => {
  const isOutbound = params.action === 'outbound';

  return {
    // params.sms_id is actually the providerMsgId, and the uuid is the Weave sms id
    id: params.uuid,
    providerMsgId: params.sms_id,
    threadId: params.thread_ids && Array.isArray(params.thread_ids) ? params.thread_ids[0] ?? '' : '',
    locationId: params.location_id,
    locationPhone: params.weave_number,
    personPhone: params.patient_number,
    direction: isOutbound ? Direction.DIRECTION_OUTBOUND : Direction.DIRECTION_INBOUND,
    body: params.body,
    status: convertStringToStatus(params.status, isOutbound ? Status.STATUS_SENT : Status.STATUS_NEW),
    statusDetails: '',
    numMedia: params.num_media,
    media: params.media_ids.map((id, index) => ({ mediaId: id, url: params.media_url.at(index) })),
    encoding: Encoding.ENCODING_UNSPECIFIED,
    segments: 1,
    relatedIds: params.related_ids ?? [],
    labels: {},
    programSlugId: '',
    personId: params.person_id || params.patient_id,
    deadline: '',
    autogeneratedBy: params.auto_generated_by ?? '',
    readBy: '',
    deletedBy: '',
    createdBy: isOutbound ? params.weave_user_id : params.person_id || params.patient_id,
    createdAt: params.received_at,
    updatedBy: '',
    updatedAt: '',
    readAt: '',
    deletedAt: '',
    actionable: false,
    departmentId: params.department_id,
    tags: [],
  };
};
