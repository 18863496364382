import {
  DigitiseFormsDetails_StageDates,
  File,
} from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { createProvider } from '@frontend/store';
import { isValidDate } from '../utils';
import { FormDigitizationStore, FormDigitizationStoreState, LoaderData } from './types';

dayjs.extend(isSameOrAfter);

const INITIAL_STATE: FormDigitizationStoreState = {
  locationId: '',
  loader: {
    show: false,
  },
  isLoadingCanUploadForms: true,
  canUploadForms: false,
  isLoadingUploadedForms: true,

  latestProcessStageDates: {},
  latestProcessUploadedForms: [],
  olderUploadedForms: [],
  showOlderForms: false,

  isUpdatingFormSubmitToggleStatus: false,
  isFormInDigitizationProcess: false,
  canWeStillUploadForms: false,

  checkForDigitizationFee: false,
  isCheckingForDigitizationFee: true,
  isUpdatingCheckForDigitizationFee: false,

  // temp state will be removed in upcoming PR
  isUploadedFormsToggleOn: false,
  isUploadedFormsToggleLoading: false,
};

/**
 * This store (context) is used to hold the state for location.
 */
export const { Provider: FormDigitizationProvider, useStore: useFormDigitizationStore } =
  createProvider<FormDigitizationStore>()((set, get) => ({
    ...INITIAL_STATE,
    setLocationId: (value: string) => set({ locationId: value }),
    setLoader: (value: LoaderData) => set({ loader: value }),

    setIsLoadingCanUploadForms: (value: boolean) => set({ isLoadingCanUploadForms: value }),
    setCanUploadForms: (value: boolean) => set({ canUploadForms: value }),

    setIsLoadingUploadedForms: (value: boolean) => set({ isLoadingUploadedForms: value }),
    setLatestProcessUploadedForms: (value: File[]) => set({ latestProcessUploadedForms: value }),
    setOlderUploadedForms: (value: File[]) => set({ olderUploadedForms: value }),
    setShowOlderForms: (value: boolean) => set({ showOlderForms: value }),
    removeUploadedForms: (formIds: string[]) => {
      set((state) => {
        const updatedUploadedForms = state.latestProcessUploadedForms.filter(
          (form) => !(form.id && formIds.includes(form.id))
        );
        return { latestProcessUploadedForms: updatedUploadedForms };
      });
    },

    initLatestProcessStageDates: (value: DigitiseFormsDetails_StageDates) => {
      // Default state - not able to upload forms and not in digitization process
      let canUpload = false;
      let isInProcess = false;

      const hasSubmission = isValidDate(value.submissionDate);
      const isValidAssignmentDate =
        isValidDate(value.assignedDate) && dayjs(value.assignedDate).isSameOrAfter(value.submissionDate);
      if (hasSubmission) {
        // Forms were submitted but not yet assigned - can still upload more
        if (!isValidAssignmentDate) {
          canUpload = true;
        }
      } else {
        // No forms submitted yet - can upload and process is ready
        canUpload = true;
        isInProcess = true;
      }
      set({
        latestProcessStageDates: value,
        canWeStillUploadForms: canUpload,
        isFormInDigitizationProcess: isInProcess,
      });
    },

    resetLatestProcessStageDates: () => {
      set({ latestProcessStageDates: {}, canWeStillUploadForms: true, isFormInDigitizationProcess: true });
    },

    setIsUpdatingFormSubmitToggleStatus: (value: boolean) => set({ isUpdatingFormSubmitToggleStatus: value }),

    setCheckForDigitizationFee: (value: boolean) => set({ checkForDigitizationFee: value }),
    setIsCheckingForDigitizationFee: (value: boolean) => set({ isCheckingForDigitizationFee: value }),
    setIsUpdatingCheckForDigitizationFee: (value: boolean) => set({ isUpdatingCheckForDigitizationFee: value }),

    setCanWeStillUploadForms: (value: boolean) => set({ canWeStillUploadForms: value }),
    setIsFormInDigitizationProcess: (value: boolean) => set({ isFormInDigitizationProcess: value }),

    getDigitizationStoreState: () => {
      const { locationId, latestProcessUploadedForms, canWeStillUploadForms } = get();
      return { locationId, latestProcessUploadedForms, canWeStillUploadForms };
    },

    setIsUploadedFormsToggleOn: (value: boolean) => set({ isUploadedFormsToggleOn: value }),
    setIsUploadedFormsToggleLoading: (value: boolean) => set({ isUploadedFormsToggleLoading: value }),
    resetDigitizationStore: () =>
      set((state) => {
        return {
          ...INITIAL_STATE,
          isCheckingForDigitizationFee: state.isCheckingForDigitizationFee,
        };
      }),
  }));
