import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Chip,
  NakedButton,
  PopoverMenu,
  PopoverMenuItem,
  SpinningLoader,
  usePopoverMenu,
  useTooltip,
} from '@frontend/design-system';
import { AppointmentStatusType } from './types';

type AppointmentStatusChipProps = {
  isLoadingStatus: boolean;
  isLoadingMenuList: boolean;
  isEditable: boolean;
  appointmentStatusList: AppointmentStatusType[];
  statusChip?: AppointmentStatusType;
  clickHandler: (selectedStatusMap: AppointmentStatusType) => void;
  getPopoverTriggerProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getTriggerProps'];
  getPopoverMenuProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getMenuProps'];
};

export const AppointmentStatusChip = ({
  isLoadingStatus,
  isLoadingMenuList,
  isEditable,
  appointmentStatusList,
  statusChip,
  getPopoverTriggerProps,
  getPopoverMenuProps,
  clickHandler,
}: AppointmentStatusChipProps) => {
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const { t } = useTranslation('scheduleCalendarEvents');

  return (
    <>
      {isLoadingStatus ? (
        <SpinningLoader size='xs' />
      ) : (
        <>
          {isEditable ? (
            <>
              <PopoverMenu {...getPopoverMenuProps()}>
                {isLoadingMenuList ? (
                  <SpinningLoader size='xs' />
                ) : (
                  appointmentStatusList?.map((status) => {
                    const { key, text, value, iconName } = status;

                    const label = text && key !== text ? `${key} / ${text}` : `${key}`;

                    return (
                      <PopoverMenuItem
                        key={`${key}-${value}`}
                        value={value}
                        css={{
                          borderLeft:
                            statusChip?.text === text ? `4px solid ${theme.colors.primary50}` : `4px solid transparent`,
                          textTransform: 'capitalize',
                        }}
                        onClick={() => clickHandler(status)}
                      >
                        {iconName && <Icon name={iconName} />}
                        {label.toLowerCase().replaceAll('_', ' ')}
                      </PopoverMenuItem>
                    );
                  })
                )}
              </PopoverMenu>
              <NakedButton {...getPopoverTriggerProps()}>
                <Chip
                  variant={statusChip?.variant}
                  css={{ width: 'auto', maxWidth: '200px', textTransform: 'capitalize' }}
                  leftElement={
                    statusChip?.iconName && <Icon name={statusChip?.iconName} css={{ color: 'currentcolor' }} />
                  }
                >
                  {statusChip && statusChip.key ? statusChip.key.toLowerCase().replaceAll('_', ' ') : statusChip?.text}
                </Chip>
              </NakedButton>
            </>
          ) : (
            <>
              <Chip
                {...triggerProps}
                variant={statusChip?.variant}
                css={{ minWidth: 'fit-content' }}
                leftElement={
                  statusChip?.iconName && <Icon name={statusChip?.iconName} css={{ color: 'currentcolor' }} />
                }
              >
                {statusChip?.text}
              </Chip>
              <Tooltip {...tooltipProps}>{t('Appointment status writeback is not supported')}</Tooltip>
            </>
          )}
        </>
      )}
    </>
  );
};
