import { useEffect, useRef, useState } from 'react';
import { getUser, getWeaveToken, isWeaveTokenActive, onWeaveTokenChange } from '@frontend/auth-helpers';
import { pendo } from '@frontend/tracking';
import { isValidWebsocketUrl } from '@frontend/uri';
import { WebsocketEventPayload } from './events';
import { HeartbeatState, WebsocketState, WebsocketWithSubscriptions } from './types';
import {
  sendPing,
  INITIAL_RECONNECT_WAIT,
  MAX_RECONNECT_WAIT_INTERVAL,
  HEARTBEAT_INTERVAL,
  subscribeLocations,
  subscribeMailboxes,
} from './utils';
import { connect, getWebsocketState } from './websocket-connection';

// These are "global" to this file because they should only be initialized once, there
// *should* only ever be one websocket connection at a time in any given app that uses this service.

function getWebsocketUrl(websocketApi: string) {
  const weaveToken = getWeaveToken();
  if (!isWeaveTokenActive()) {
    console.warn("Weave token isn't active, return empty string");
    return '';
  }
  const params = new URLSearchParams({
    token: weaveToken || '',
  }).toString();
  return weaveToken ? `${websocketApi}?${params}` : '';
}

/**
 * This is the main hook that builds up wrappers around the websocket connection to handle
 *   connection state, reconnection, and heartbeats. It is a Websocket Client Manager of sorts.
 * @param param0
 * @returns
 */
export const useWebSocket = ({
  onMessage,
  selectedLocationIds,
  connectedDeviceSipId,
  refreshAuthToken,
  websocketApi,
}: {
  onMessage: (payload: WebsocketEventPayload) => void;
  selectedLocationIds: string[];
  connectedDeviceSipId?: string;
  refreshAuthToken: () => Promise<string>;
  websocketApi: string;
}) => {
  const locations = useRef<string[]>(selectedLocationIds);
  const heartbeatInterval = useRef<number>();
  const heartbeat = useRef<HeartbeatState>({
    lastHeartbeatAt: null,
    lastPingSentAt: null,
    connectAttemptStartedAt: null,
    reconnectWaitInterval: INITIAL_RECONNECT_WAIT,
    inReconnectLoop: false,
    refreshTokenStartedAt: null,
  });

  const initialUrl = getWebsocketUrl(websocketApi);
  // Use state so that socket updates will be emitted to consuming components
  const socketRef = useRef<WebsocketWithSubscriptions>();
  const [socket, setSocket] = useState<WebsocketWithSubscriptions>();
  // using state here instead of a ref so a rerender will be triggered when the socket is updated
  const [subscribedLocations, setSubscribedLocations] = useState<string[]>([]);
  const isConnecting = useRef(false);
  const [websocketState, setWebsocketState] = useState<WebsocketState>(() =>
    getWebsocketState(initialUrl, heartbeat.current)
  );

  /**
   * This should be used to set a new websocket connection in order be sure that the old websocket
   *   connection is closed. This is necessary to prevent duplicate messages being received and
   *   delivered to subscribers.
   */
  const setCurrentWebsocket = (ws: WebsocketWithSubscriptions) => {
    if (socketRef.current) {
      socketRef.current.onmessage = null;
      socketRef.current.onerror = null;
      socketRef.current.onclose = null;
      socketRef.current.close();
      setSubscribedLocations([]);
    }
    socketRef.current = ws;
    setSocket(ws);
    isConnecting.current = false;
  };

  const sendWsTrackEvent = (eventName: string) => {
    pendo?.track(eventName, {
      networkOnline: navigator.onLine,
      visitorId: getUser()?.userID || '',
      locationIds: selectedLocationIds,
      userAgent: navigator.userAgent,
      location: window.location.href,
    });
  };

  const reset = () => {
    if (socketRef.current) {
      socketRef.current.onmessage = null;
      socketRef.current.onerror = null;
      socketRef.current.onclose = null;
      socketRef.current.close();
      setSubscribedLocations([]);
      socketRef.current = undefined;
    }
    heartbeat.current.refreshTokenStartedAt = null;
    heartbeat.current.lastHeartbeatAt = null;
    heartbeat.current.lastPingSentAt = null;
    heartbeat.current.connectAttemptStartedAt = null;
    heartbeat.current.inReconnectLoop = false;
    reevaluateCurrentState();
  };

  const initiateTokenRefresh = () => {
    // Immediately upon successful refresh of the user's token, reevaluate the current state
    heartbeat.current.refreshTokenStartedAt = Date.now();
    refreshAuthToken().then(reset);
  };

  const reevaluateCurrentState = () => {
    // Check in on the state of the websocket connection and determine if any actions need to be taken
    const state = getWebsocketState(getWebsocketUrl(websocketApi), heartbeat.current);
    setWebsocketState(state);
    // Exponential backoff for reconnecting, only used if NEED_TO_RECONNECT
    switch (state) {
      // The following states are ordered in the general order you might expect a connection to go through
      case WebsocketState.WAITING_FOR_URL:
        break;
      case WebsocketState.CONNECTING:
        break;
      case WebsocketState.HEALTHY_CONNECTION:
        break;
      case WebsocketState.NEED_TO_SEND_PING:
        // This is when we have not received any server sent messages and we want to proactively check the connection
        if (socketRef.current) {
          sendPing(socketRef.current);
          heartbeat.current.lastPingSentAt = Date.now();
          sendWsTrackEvent('websocket-ping-sent');
        }
        break;
      case WebsocketState.WAITING_FOR_PONG:
        break;
      case WebsocketState.NEED_TO_REFRESH_TOKEN:
        initiateTokenRefresh();
        break;
      case WebsocketState.NEED_TO_RECONNECT: {
        isConnecting.current = false;
        getNewWebsocket(getWebsocketUrl(websocketApi))
          .then((ws) => {
            setCurrentWebsocket(ws);
            if (selectedLocationIds && selectedLocationIds.length > 0) {
              subscribeLocations({ locationIds: selectedLocationIds, ws });
              setSubscribedLocations(selectedLocationIds);
              subscribeMailboxes({ locationIds: selectedLocationIds, connectedDeviceSipId, ws });
            }
          })
          .catch(() => (isConnecting.current = false));
        heartbeat.current.inReconnectLoop = true;
        if (heartbeat.current.reconnectWaitInterval === INITIAL_RECONNECT_WAIT) {
          sendWsTrackEvent('websocket-started-reconnect-loop');
        }
        const newReconnectWaitInterval = Math.min(
          heartbeat.current.reconnectWaitInterval * 2,
          MAX_RECONNECT_WAIT_INTERVAL
        );

        if (
          newReconnectWaitInterval !== heartbeat.current.reconnectWaitInterval &&
          newReconnectWaitInterval === MAX_RECONNECT_WAIT_INTERVAL
        ) {
          sendWsTrackEvent('websocket-reconnect-max-wait-reached');
        }
        heartbeat.current.reconnectWaitInterval = newReconnectWaitInterval;
        break;
      }
      case WebsocketState.RECONNECTING:
        break;
      default:
        break;
    }
  };

  const getNewWebsocket = (url: string) => {
    return connect({
      url,
      heartbeatState: heartbeat.current,
      onMessage,
      reevaluateCurrentState,
      userId: getUser()?.userID || '',
    });
  };

  useEffect(() => {
    if (!heartbeatInterval.current) {
      heartbeatInterval.current = window.setInterval(reevaluateCurrentState, HEARTBEAT_INTERVAL);
    }

    return () => {
      if (heartbeatInterval.current) {
        clearInterval(heartbeatInterval.current);
        heartbeatInterval.current = undefined;
      }

      if (socketRef.current) {
        socketRef.current.onmessage = null;
        socketRef.current.onerror = null;
        socketRef.current.onclose = null;
        socketRef.current.close();
        setSubscribedLocations([]);
        socketRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (isValidWebsocketUrl(initialUrl) && !socketRef.current && !isConnecting.current) {
      isConnecting.current = true;
      getNewWebsocket(initialUrl)
        .then((ws) => {
          setCurrentWebsocket(ws);
          if (selectedLocationIds && selectedLocationIds.length > 0) {
            subscribeLocations({ locationIds: selectedLocationIds, ws });
            setSubscribedLocations(selectedLocationIds);
            subscribeMailboxes({ locationIds: selectedLocationIds, connectedDeviceSipId, ws });
          }
        })
        .catch(() => (isConnecting.current = false));

      // This is a side effect that will trigger a new websocket connection when the token changes externally from WS
      // There is a cleanup function that is returned from this effect that will remove the listener when the component is unmounted
      // I'm not sure where any cleanup is being done for this component, so I ignored it
      const off = onWeaveTokenChange(reset);
      return () => {
        off();
      };
    }

    return;
  }, [initialUrl, selectedLocationIds.toString()]);

  useEffect(() => {
    if (socketRef.current && connectedDeviceSipId && socketRef.current.connectedDeviceSipId !== connectedDeviceSipId) {
      subscribeMailboxes({ locationIds: selectedLocationIds, connectedDeviceSipId, ws: socketRef.current });
      socketRef.current.connectedDeviceSipId = connectedDeviceSipId;
    }
  }, [socketRef.current, connectedDeviceSipId]);

  useEffect(() => {
    if (socketRef.current) {
      /**
       * i'm not really sure why `subscribedLocations` and/or `selectedLocationIds` is undefined, but it was breaking in prod
       * we need to look into the root cause of this, but i want to merge this PR first so it doesn't break prod anymore
       */
      if (socketRef.current.subscribedLocations?.toString() !== selectedLocationIds.toString()) {
        // if selected location changes, re-subscribe both locations and mailboxes
        subscribeLocations({ locationIds: selectedLocationIds, ws: socketRef.current });
        setSubscribedLocations(selectedLocationIds);
        subscribeMailboxes({ locationIds: selectedLocationIds, connectedDeviceSipId, ws: socketRef.current });
        socketRef.current.subscribedLocations = locations.current;
        socketRef.current.connectedDeviceSipId = connectedDeviceSipId;
      }
    }
  }, [selectedLocationIds.toString()]);

  return {
    websocket: socket,
    websocketState,
    isSubscribed: subscribedLocations.length > 0,
    subscribedLocations,
    retry: reevaluateCurrentState,
    subscribe: () => {
      if (!socket) return;
      subscribeLocations({ locationIds: selectedLocationIds, ws: socket });
      setSubscribedLocations(selectedLocationIds);
      subscribeMailboxes({ locationIds: selectedLocationIds, connectedDeviceSipId, ws: socket });
    },
  };
};
