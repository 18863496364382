import { memo, useCallback, useMemo } from 'react';
import { Chart, LeaderboardDataValueClickParams, LeaderboardProps } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { SummaryCard, SummaryCardClickHandler } from '../call-intel-summary-charts';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelDemoFlags, useCallIntelLocations, useCallIntelShallowStore } from '../hooks';

type Data = {
  noOpportunities?: number;
  scheduledOpportunities?: number;
  totalAnalysedCalls?: number;
  totalOpportunities?: number;
  unscheduledOpportunities?: number;
};

type Props = {
  categorisedComparison?: boolean;
  data?: Record<string, Data>;
  isLoading?: boolean;
  onClick?: SummaryCardClickHandler;
  title: string;
};

const demoLocations = CallIntelMockData.dummyLocationNames();

export const OpportunityConversionLeaderboard = memo(
  ({ categorisedComparison, data = {}, isLoading, onClick, title }: Props) => {
    const { t } = useTranslation('analytics');
    const { isDemoAccount, showDemoChipAndBanner } = useCallIntelDemoFlags();
    const { filterHintText } = useCallIntelShallowStore('filterHintText');
    const { locations } = useCallIntelLocations({
      demoLocations: isDemoAccount ? demoLocations : undefined,
    });

    const chartData = useMemo(() => {
      const processedData: LeaderboardProps['data'] = Object.entries(data).map(
        ([
          locationId,
          { scheduledOpportunities = 0, totalAnalysedCalls = 0, totalOpportunities = 0, unscheduledOpportunities = 0 },
        ]) => ({
          id: locationId,
          name: locations[locationId] || locationId,
          values: {
            conversionRate: {
              primaryValue: scheduledOpportunities / totalOpportunities,
              formatter: formatters.percent.format,
            },
            unscheduled: {
              primaryValue: unscheduledOpportunities,
              secondaryValue: totalOpportunities,
            },
            callsAnalyzed: {
              primaryValue: totalAnalysedCalls,
            },
          },
        })
      );

      return processedData;
    }, [data]);

    const handleLeaderboardClick = useCallback(({ columnId, rowId }: LeaderboardDataValueClickParams) => {
      onClick?.(columnId as SummaryCard, rowId);
    }, []);

    return (
      <Chart
        isLoading={isLoading}
        labels={{
          callsAnalyzed: t('Total'),
          conversionRate: t('Scheduled Rate'),
          highestMetric: t('Highest Scheduled Rate'),
          unscheduled: t('Opportunities'),
        }}
      >
        <Chart.Header
          leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
          subtitle={filterHintText}
          title={title}
        />
        <Chart.Leaderboard
          appearance={{
            comparisonType: categorisedComparison ? 'columns' : 'rows',
            highestMetric: 'conversionRate',
          }}
          columnHeaderLabels={{
            callsAnalyzed: t('Calls Analyzed'),
            conversionRate: t('Scheduled Rate'),
            name: t('Location'),
            unscheduled: t('Unscheduled'),
          }}
          data={chartData}
          defaultSort={{
            key: 'conversionRate',
            type: 'desc',
          }}
          onClick={handleLeaderboardClick}
          trackingIdBase='ci-locations-leaderboard'
        />
      </Chart>
    );
  }
);

OpportunityConversionLeaderboard.displayName = 'OpportunityConversionLeaderboard';
