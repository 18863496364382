import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { PaymentMethodsStepNavigationProps } from '../payment-methods.types';

export const usePayentMethodsGenericNavigation = () => {
  const {
    flow,
    multiStep: { goPrevStep, onCancel },
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => {
    if (flow === 'select') {
      return goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select });
    }

    console.warn('Multi Collect > Payment Methods Step > Go back clicked but flow was', flow);
  }, [flow]);

  const onClickBack = useCallback(() => {
    if (flow === 'select') {
      return goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select });
    }

    return false;
  }, [flow]);

  const onClickPrimary = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  /**
   * Prevent back button from being rendered.
   */
  const back = useCallback(() => null, []);

  return {
    onGoBack,
    onClickBack,
    onClickPrimary,
    back,
  };
};
export const PaymentMethodsGenericNavigation = ({ body: Body }: PaymentMethodsStepNavigationProps) => {
  const { onGoBack, onClickBack, onClickPrimary, back } = usePayentMethodsGenericNavigation();

  return <Body onGoBack={onGoBack} onClickBack={onClickBack} onClickPrimary={onClickPrimary} back={back} />;
};
