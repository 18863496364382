import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { CollectTerminalStepNavigationProps } from '../collect-terminal.types';

export const CollectTerminalGenericNavigation = ({ body: Body }: CollectTerminalStepNavigationProps) => {
  const {
    onPaymentSuccess: onPaymentSuccessContext,
    multiStep: { goPrevStep, onCancel },
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => {
    goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
  }, []);

  const onPaymentSuccess = useCallback(() => {
    onPaymentSuccessContext?.();
  }, [onPaymentSuccessContext]);

  const onInvoiceSent = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const onCloseWithoutReceipt = useCallback(() => {
    onCancel?.();
  }, [onCancel]);
  return (
    <Body
      onPaymentSuccess={onPaymentSuccess}
      onGoBack={onGoBack}
      onInvoiceSent={onInvoiceSent}
      onCloseWithoutReceipt={onCloseWithoutReceipt}
    />
  );
};
