import {
  DynamicFieldKey_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { RelatedType } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { ManualTemplateTypesWithStaticTemplate } from './types';

export const STATIC_TEMPLATE_ID = 'static-template';

export const STATIC_TEMPLATES = {
  [TemplateType_Slug.MANUAL_FORMS_MESSAGE]: `Hi {{${DynamicFieldKey_Enum.PREFERRED_NAME}}},\nWe are looking forward to your upcoming visit. Please fill out this form at your earliest convenience:\n{{${DynamicFieldKey_Enum.FORMS_LINK}}}\nThank you,\n{{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}`,
  [TemplateType_Slug.MANUAL_PAYMENT_REQUEST]: `Hi, {{${DynamicFieldKey_Enum.PREFERRED_NAME}}} this is {{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}. You have a balance due. Please use this secure link to pay {{${DynamicFieldKey_Enum.TEXT_TO_PAY_LINK}}}`,
  [TemplateType_Slug.MANUAL_REVIEW_REQUEST]: `Hi {{${DynamicFieldKey_Enum.PREFERRED_NAME}}}, thank you for visiting {{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}! Please let us know how we did by clicking the link below.\n{{${DynamicFieldKey_Enum.REVIEW_REQUEST_LINK}}}`,
  [TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET]: `Hi {{${DynamicFieldKey_Enum.PREFERRED_NAME}}}, thank you for visiting {{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}! Please let us know how we did by clicking the link below.\n{{${DynamicFieldKey_Enum.REVIEW_REQUEST_LINK}}}`,
  [TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER]: `Hello {{${DynamicFieldKey_Enum.PREFERRED_NAME}}}, this is {{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}. We wanted to remind you of your upcoming appointment on {{${DynamicFieldKey_Enum.DATE_FORMAL}}} at {{${DynamicFieldKey_Enum.TIME}}}. Text us to confirm. Thank you!`,
  [TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET]: `Hello {{${DynamicFieldKey_Enum.PREFERRED_NAME}}}, this is {{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}. We wanted to remind you of your appointment for {{${DynamicFieldKey_Enum.PET_NAME}}} on {{${DynamicFieldKey_Enum.DATE_FORMAL}}} at {{${DynamicFieldKey_Enum.TIME}}}. Text us to confirm. Thank you!`,
  [TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL]: `Hello, {{${DynamicFieldKey_Enum.FIRST_NAME}}}, this is {{${DynamicFieldKey_Enum.BUSINESS_GROUP_NAME}}}. We have a last minute opening for {{${DynamicFieldKey_Enum.DATETIME_FORMAL}}}. Does this time work for you? Reply yes or no.`,
} satisfies Record<ManualTemplateTypesWithStaticTemplate, string>;

export const TEMPLATE_TYPE_LINK_MAP: Record<
  ManualTemplateTypesWithStaticTemplate,
  { dynamicField?: DynamicFieldKey_Enum; relatedType: RelatedType }
> = {
  [TemplateType_Slug.MANUAL_FORMS_MESSAGE]: {
    dynamicField: DynamicFieldKey_Enum.FORMS_LINK,
    relatedType: RelatedType.RELATED_TYPE_FORM,
  },
  [TemplateType_Slug.MANUAL_PAYMENT_REQUEST]: {
    dynamicField: DynamicFieldKey_Enum.TEXT_TO_PAY_LINK,
    relatedType: RelatedType.RELATED_TYPE_INVOICE,
  },
  [TemplateType_Slug.MANUAL_REVIEW_REQUEST]: {
    dynamicField: DynamicFieldKey_Enum.REVIEW_REQUEST_LINK,
    relatedType: RelatedType.RELATED_TYPE_REVIEW,
  },
  [TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET]: {
    dynamicField: DynamicFieldKey_Enum.REVIEW_REQUEST_LINK,
    relatedType: RelatedType.RELATED_TYPE_REVIEW,
  },
  [TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER]: {
    relatedType: RelatedType.RELATED_TYPE_APPOINTMENT,
  },
  [TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET]: {
    relatedType: RelatedType.RELATED_TYPE_APPOINTMENT,
  },
  [TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL]: {
    relatedType: RelatedType.RELATED_TYPE_APPOINTMENT,
  },
};
