import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { AppointmentTypesApi, AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  MoneyField,
  SwitchField,
  useAlert,
  Button,
  useForm,
  ContentLoader,
  FormFieldActionTypes,
} from '@frontend/design-system';

interface AppointmentTypeSettingsProps {
  apptRequestConfig?: AppointmentTypesTypes.ApptRequestConfig;
  refetchApptReqConfig: () => void;
  locationId: string;
}
export const AppointmentTypePaymentsSettings = ({
  apptRequestConfig,
  refetchApptReqConfig,
  locationId,
}: AppointmentTypeSettingsProps) => {
  const alerts = useAlert();
  const { t } = useTranslation('scheduleAppointmentTypes');

  const hasGlobalBookingDeposit = !!(
    apptRequestConfig?.hasGlobalBookingDeposit &&
    apptRequestConfig?.globalBookingDepositAmount &&
    apptRequestConfig?.globalBookingDepositAmount > 0
  );
  const moneyFieldValue = (apptRequestConfig?.globalBookingDepositAmount || 0).toFixed(2);

  const { mutateAsync: updateBookingDeposit, isLoading: isSaving } = useMutation({
    mutationFn: (configData: AppointmentTypesTypes.ApptRequestConfig) =>
      AppointmentTypesApi.updateApptRequestConfigData(configData, locationId),
    onSuccess: () => {
      alerts.success(t('Payments settings saved successfully'));
      refetchApptReqConfig();
      seedValues({ ...values }); // Seeding value re-initializes form values and reset `changedValues`, which is used to disable save button until current values are changed
    },
    onError: () => {
      alerts.error(t('Failed to save payments settings'));
    },
  });

  const { formProps, values, changedValues, getFieldProps, seedValues, validate, isComplete } = useForm({
    fields: {
      globalBookingDepositSwitch: {
        type: 'switch',
        value: hasGlobalBookingDeposit,
      },
      globalBookingDepositAmount: {
        type: 'money',
        value: moneyFieldValue,
        validator: ({ value }, formValues) => {
          const amount = Number(value);
          const isGlobalBookingDepositSwitchedOn = formValues.globalBookingDepositSwitch?.value === true;
          if (!isGlobalBookingDepositSwitchedOn) return '';
          if (amount === 0) return t('Amount must be greater than 0');
          if (amount % 1 !== 0) return t('Whole dollar amounts only');
          return '';
        },
      },
    },
    fieldStateReducer: (state, action) => {
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'globalBookingDepositSwitch' &&
        action.payload.value === false
      ) {
        return {
          ...state,
          globalBookingDepositAmount: {
            ...state.globalBookingDepositAmount,
            value: moneyFieldValue, // reset value to initial value when switch is turned off
            error: '', // reset error message when switch is turned off
          },
        };
      }
      return state;
    },
    allowInvalidSubmission: false,
    computeChangedValues: true,
    onSubmit: async (values) => {
      /*
        Validate the form whether it's incomplete OR the amount is 0.
        The reason for adding amount check is that `isComplete` returns true if amount field is not touched,
        therefore allowing the submission without amount validation (for initial amount 0.00 on loading).
      */
      if (Number(values.globalBookingDepositAmount) < 1) {
        validate();
        return;
      }

      updateBookingDeposit({
        appointmentTypeIDs: apptRequestConfig?.appointmentTypeIDs ?? [],
        minOpeningsPermitted: apptRequestConfig?.minOpeningsPermitted ?? 1,
        requestBufferDuration: apptRequestConfig?.requestBufferDuration ?? 0,
        hasGlobalBookingDeposit: values.globalBookingDepositSwitch,
        globalBookingDepositAmount: Number(values.globalBookingDepositAmount || 0),
      });
    },
  });

  const shouldDisableSaveBtn = isSaving || !changedValues || !isComplete;

  return (
    <div css={styles}>
      <SwitchField
        {...getFieldProps('globalBookingDepositSwitch')}
        label={t('Request below amount for all appointment types')}
        labelPlacement='right'
      />
      <MoneyField
        {...getFieldProps('globalBookingDepositAmount')}
        css={{ width: 200 }}
        label={t('Amount')}
        helperText={t('Whole dollar amounts only')}
        disabled={!values.globalBookingDepositSwitch}
      />
      <Button css={{ width: 'fit-content' }} disabled={shouldDisableSaveBtn} onClick={formProps.onSubmit}>
        {t('Save Payments Settings')}
      </Button>
      <ContentLoader show={isSaving} size='medium' />
    </div>
  );
};

const styles = css`
  position: relative;
  max-width: 420px;
  margin-top: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
`;
