import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.neutral10}`,
  padding: theme.spacing(1.5, 1, 1.5, 2),
});

export const textContainerStyle = css({ display: 'flex', alignItems: 'center', gap: theme.spacing(1) });

export const textStyle = css({
  fontWeight: theme.font.weight.bold,
  whiteSpace: 'nowrap',
});

export const settingsButtonStyle = css({
  border: 'none',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  width: '100%',
  textAlign: 'start',
  height: '54px',
  justifyContent: 'flex-start',
  color: theme.colors.black,

  ':last-child': {
    borderBottom: 'none',
  },
  span: {
    fontWeight: theme.font.weight.regular,
    fontSize: theme.font.size.large,
  },
});

export const teamChatPopoverStyles = css({
  borderRadius: theme.borderRadius.large,
  border: `1px solid ${theme.colors.neutral20}`,
  boxShadow: theme.shadows.floating,
  width: '300px',
  padding: 0,
});
