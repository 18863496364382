import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { FormRow, MultiselectField, SearchField, useDebouncedValue, useFormField } from '@frontend/design-system';

interface Props {
  locations: Record<string, string>;
  onChange: (searchText: string, locations: string[]) => void;
}

export const Filters: FC<React.PropsWithChildren<Props>> = ({ locations, onChange }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'call-groups' });
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData;
  const locationId = selectedLocationIds?.[0];
  const isMultiLocation = locationData?.[locationId]?.groupId !== undefined;

  const searchFieldProps = useFormField({
    type: 'text',
  });

  const locationsFieldProps = useFormField({ type: 'multiselect' });

  const searchValueChange = useDebouncedValue(searchFieldProps.value, 500);

  useEffect(() => {
    onChange(searchFieldProps.value?.trim(), locationsFieldProps.value);
  }, [locationsFieldProps.value, searchValueChange]);

  return (
    <div css={wrapperStyle}>
      <FormRow>
        <SearchField {...searchFieldProps} label={t('Search')} name='search' />

        {isMultiLocation && (
          <MultiselectField
            {...locationsFieldProps}
            disabled={!Object.entries(locations).length}
            label={Object.entries(locations).length ? t('Filter by location') : t('No location available')}
            name='locations'
          >
            {Object.entries(locations).map(([key, value]) => (
              <MultiselectField.Option key={key} value={key}>
                {value}
              </MultiselectField.Option>
            ))}
          </MultiselectField>
        )}
      </FormRow>
    </div>
  );
};

const wrapperStyle = css`
  margin-top: ${theme.spacing(3)};

  > div {
    margin-bottom: 0;
  }
`;
