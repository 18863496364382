import { Status_Enum as CampaignStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { breakpoints } from '@frontend/responsiveness';

export const LIMITED_EDIT_CAMPAIGN_STATUSES = [
  CampaignStatus.COMPLETED,
  CampaignStatus.PROCESSING,
  CampaignStatus.SCHEDULED,
];

export const NEW_CAMPAIGN_ID = 'new';

export const SIDEBAR_BREAKPOINTS = {
  mobile: 650,
  tablet: breakpoints.medium.min,
  desktop: breakpoints.medium.max,
};

export const SMS_BETA_CAMPAIGN_TYPE = 'text-beta';
