import { FC } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { NakedA, Text, useTooltip } from '@frontend/design-system';
import { BundleFeatureEnum, BundleFeatureInfoMap } from './constants';

interface BundleFeatureChipsProps {
  bundleFeatureEnumList: BundleFeatureEnum[];
}

export const BundleFeatureChips: FC<BundleFeatureChipsProps> = ({ bundleFeatureEnumList }) => {
  return (
    <div css={chipsContainerStyles}>
      {bundleFeatureEnumList.map((bundleFeatureEnum) => (
        <FeatureChip key={bundleFeatureEnum} bundleFeatureEnum={bundleFeatureEnum} />
      ))}
    </div>
  );
};

const FeatureChip: FC<{ bundleFeatureEnum: BundleFeatureEnum }> = ({ bundleFeatureEnum }) => {
  const featureInfo = BundleFeatureInfoMap[bundleFeatureEnum];
  const { triggerProps, tooltipProps, Tooltip } = useTooltip();
  return (
    <NakedA
      key={bundleFeatureEnum}
      css={featureChipStyles}
      href={featureInfo.link}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Icon name={featureInfo.icon} size={24} color='light' />
      <Text size='medium'> {featureInfo.title}</Text>
      {!!featureInfo.additionalInfo && (
        <div {...triggerProps} css={{ marginLeft: 'auto' }}>
          <Icon name='info' size={16} color='light' />
        </div>
      )}
      {!!featureInfo.additionalInfo && <Tooltip {...tooltipProps}>{featureInfo.additionalInfo}</Tooltip>}
    </NakedA>
  );
};

const chipsContainerStyles = css({
  gap: theme.spacing(1),
  gridTemplateColumns: 'repeat(3, 1fr)',
  display: 'grid',
  overflow: 'auto',
  [`@media (max-width: ${breakpoints.medium.min}px)`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const featureChipStyles = css({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.large,
  backgroundColor: theme.colors.neutral5,
  textDecoration: 'none',
  ':hover': {
    backgroundColor: theme.colors.indigo5,
    'p, svg': {
      color: theme.colors.indigo50,
    },
  },
});
