import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { createShallowStore, createStore } from '@frontend/store';

type ScheduleActionsAppointmentsStoreType = {
  hasActiveFilters: boolean;
  setHasActiveFilters: (hasActiveFilters: boolean) => void;
  appointmentList: Appointment[];
  defaultAppointmentList: Appointment[];
  setAppointmentList: (appointmentListData: Appointment[]) => void;
  setDefaultAppointmentList: (appointmentListData: Appointment[]) => void;
  statusFilter?: string;
  setStatusFilter?: (statusFilter: string) => void;
  providerList: string[];
  setProviderList: (providerListData: string[]) => void;
  defaultProviderList: Provider[];
  setDefaultProviderList: (providerListData: Provider[]) => void;
  insuranceStatusFilters: string[];
  setInsuranceStatusFilters: (insuranceStatusFiltersData: string[]) => void;
};

const useScheduleActionsAppointmentListStore = createStore<ScheduleActionsAppointmentsStoreType>(
  (set) => ({
    hasActiveFilters: false,
    setHasActiveFilters: (hasActiveFiltersData: boolean) => set({ hasActiveFilters: hasActiveFiltersData }),
    appointmentList: [],
    defaultAppointmentList: [],
    insuranceStatusFilters: [],
    setInsuranceStatusFilters: (insuranceStatusFiltersData: string[]) =>
      set({ insuranceStatusFilters: insuranceStatusFiltersData }),
    setAppointmentList: (appointmentListData: Appointment[]) =>
      set({ appointmentList: getSortedAppointments(appointmentListData) }),
    setDefaultAppointmentList: (appointmentListData: Appointment[]) =>
      set({ defaultAppointmentList: getSortedAppointments(appointmentListData) }),
    statusFilter: '',
    setStatusFilter: (statusFilterData: string) => set({ statusFilter: statusFilterData }),
    providerList: [],
    setProviderList: (providerListData: string[]) => set({ providerList: providerListData }),
    defaultProviderList: [],
    setDefaultProviderList: (providerListData: Provider[]) => set({ defaultProviderList: providerListData }),
  }),
  { name: 'scheduleActionsAppointmentList' }
);

export const useScheduleActionsAppointmentListInfoShallowStore =
  createShallowStore<ScheduleActionsAppointmentsStoreType>(useScheduleActionsAppointmentListStore);

const getSortedAppointments = (appointments: Appointment[] = []) => {
  // Note: we need to create new array as appointments received as immutable array
  return [...appointments].sort((a, b) => {
    if (!a.start || !b.start) return 0;
    const startTimeComparison = new Date(a.start).getTime() - new Date(b.start).getTime();
    if (startTimeComparison !== 0) return startTimeComparison;

    const lastNameOfA = a.person?.lastName ?? '';
    const lastNameOfB = b.person?.lastName ?? '';
    return lastNameOfA.localeCompare(lastNameOfB);
  });
};
