import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { PreviewNavigationSwitch } from './preview/preview-navigation-switch';

export const Preview = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.preview}>
      <PreviewNavigationSwitch />
    </StepPerformanceWrapper>
  );
};
