import { useMemo, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useFormField, TextField, useDebouncedValue } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { EmptySearchState } from '../../common/empty-search-state/empty-search-state';
import { UserRow } from '../../common/user-row/user-row';

interface Props {
  selectedMemberIds: string[];
  existingMemberIds: string[];
  onChange: (userIds: string[]) => void;
  show: boolean;
}

export const AddMembers = ({ selectedMemberIds, existingMemberIds, onChange, show }: Props) => {
  const textFieldProps = useFormField({
    value: '',
    type: 'text',
  });

  const t = useTranslation('team-chat').t;
  const users = useTeamChatSelector((ctx) => ctx.users);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const searchText = useDebouncedValue(textFieldProps.value);

  const searchUsers = useMemo(() => {
    const input = searchText.trim().toLowerCase();
    const nonMembers = users?.filter((user) => !existingMemberIds.includes(user.userID));

    // The users are filtered using the members that are already added to the channel
    // When a member is added, we do not want to filter the user out
    // Instead, we want to show the user with an added Text
    if (!input) {
      return nonMembers ?? [];
    } else {
      return nonMembers?.filter((user) => {
        const fullName = getUserFullName(user.firstName, user.lastName).toLowerCase();
        return fullName.includes(input);
      });
    }
  }, [users, searchText, existingMemberIds]);

  const addMember = async (userId: string) => onChange([...selectedMemberIds, userId]);
  const removeUser = (userId: string) => onChange(selectedMemberIds.filter((id) => id !== userId));

  useEffect(() => {
    if (show && textFieldRef.current) {
      /*
        Modal body get focused when the modalProps.show property is set to true. Hence, when we focus on the text field,
        it get overridden by the modal body focus. To avoid this, we are setting a timeout of 600ms to focus on
        the text field.
       */
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 600);
    }
  }, [show]);

  return (
    <>
      <TextField
        name=''
        label=''
        startAdornment={<Icon name='search' />}
        placeholder={t('Search members')}
        autoFocus
        clearable
        fieldComponentProps={{ ref: textFieldRef }}
        {...textFieldProps}
      />
      <div css={userListContainerStyle}>
        {!searchUsers?.length && <EmptySearchState />}
        {searchUsers?.map((user) => (
          <UserRow
            key={user.userID}
            user={user}
            selectedMemberIds={selectedMemberIds}
            onRemoveUser={removeUser}
            onAddUser={addMember}
          />
        ))}
      </div>
    </>
  );
};

const userListContainerStyle = css({
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '288px',
  boxShadow: theme.shadows.light,
  borderBottomLeftRadius: theme.borderRadius.medium,
  borderBottomRightRadius: theme.borderRadius.medium,
  marginBottom: theme.spacing(1),
});
