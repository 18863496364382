import { useCallback, useState } from 'react';
import { IGif } from '@giphy/js-types';
import { Attachment, Channel, DefaultGenerics } from 'stream-chat';
import { StreamGiphyType } from '@frontend/gif-picker';
import { useTranslation } from '@frontend/i18n';
import { ThreadSendingMediaItem } from '@frontend/integrated-messaging';
import { genUUIDV4 } from '@frontend/string';
import { useAlert } from '@frontend/design-system';
import { MAX_FILE_SIZE } from '../constants';
import { parseGiphyToAttachment } from '../utils';

type UpdatedThreadSendingMediaItem = ThreadSendingMediaItem & {
  uploadable: boolean;
  type?: string;
  meta?: StreamGiphyType;
};

interface UseImagesUpload {
  hideUploadingImagesEffect: () => void;
  images: UpdatedThreadSendingMediaItem[];
  removeImage: (id: string) => void;
  setImages: (files?: File[]) => void;
  showUploadingImagesEffect: () => void;
  upload: (images: File[], channel: Channel<DefaultGenerics>) => Promise<Attachment[]>;
  addGif: (gif: IGif) => void;
}

type MediaParams = { file: File } | { url: string; meta: StreamGiphyType };

const convertDataToMedia = (input: MediaParams): UpdatedThreadSendingMediaItem => {
  const isFile = 'file' in input;

  return {
    file: isFile ? input.file : new File([], ''),
    hasError: false,
    id: isFile ? genUUIDV4() : input.url,
    mediaObj: {
      filename: isFile ? input.file?.name : '',
      mediaId: isFile ? genUUIDV4() : input.url,
      url: isFile ? URL.createObjectURL(input.file) : input.url,
    },
    previewSrc: isFile ? URL.createObjectURL(input.file) : input.url,
    uploading: false,
    uploadable: !!isFile,
    meta: !isFile ? input.meta : undefined,
  };
};

export const useImagesUpload = (): UseImagesUpload => {
  const alert = useAlert();
  const { t } = useTranslation('team-chat');

  const [selectedImages, setSelectedImages] = useState<UpdatedThreadSendingMediaItem[]>([]);

  const setImages = useCallback(
    (files?: File[]) => {
      if (!files?.length) {
        setSelectedImages([]);
        return;
      }

      if (files.length > 10) {
        alert.error(
          t('You can only attach up to 10 images at a time. {{count}} images were ignored.', {
            count: files.length - 10,
          })
        );
      }

      // Lets pick only first 10 files and check if they are less than 10MB
      const filesToProcess = files.slice(0, 10);
      const uploadableFiles: UpdatedThreadSendingMediaItem[] = [];
      const removedFiles: File[] = [];

      filesToProcess.forEach((file) => {
        if (file.size <= MAX_FILE_SIZE) {
          uploadableFiles.push(convertDataToMedia({ file }));
        } else {
          removedFiles.push(file);
        }
      });

      if (!!removedFiles.length) {
        alert.error(
          removedFiles.length === 1
            ? t('You can only upload images that are less than 10 MB in size. 1 image was not uploaded.')
            : t('You can only upload images that are less than 10 MB in size. {{count}} images were not uploaded.', {
                count: removedFiles.length,
              })
        );
      }

      setSelectedImages([...selectedImages, ...uploadableFiles]);
    },
    [selectedImages]
  );

  const removeImage = useCallback(
    (id: string) => {
      setSelectedImages(selectedImages.filter((image) => image.id !== id));
    },
    [selectedImages]
  );

  const toggleUploadingEffect = useCallback((uploading: boolean) => {
    setSelectedImages((images = []) => images.map((image) => ({ ...image, uploading })));
  }, []);

  const upload = useCallback(async (images: File[], channel: Channel<DefaultGenerics>) => {
    const promises = images?.map((image) => channel.sendImage(image));
    const results = await Promise.allSettled(promises);
    const attachments = results.reduce<Attachment[]>((acc, result) => {
      if (result.status === 'fulfilled' && result.value.file) {
        acc.push({
          asset_url: result.value.file,
          image_url: result.value.file,
          thumb_url: result.value.file,
          type: 'image',
        });
      }
      return acc;
    }, []);

    return attachments;
  }, []);

  const addGif = (gif: IGif) => {
    const meta = parseGiphyToAttachment(gif);
    setSelectedImages([...selectedImages, convertDataToMedia({ url: meta.thumb_url, meta })]);
  };

  return {
    hideUploadingImagesEffect: () => toggleUploadingEffect(false),
    images: selectedImages,
    removeImage,
    setImages,
    showUploadingImagesEffect: () => toggleUploadingEffect(true),
    upload,
    addGif,
  };
};
