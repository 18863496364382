import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, PopoverMenu, PopoverMenuItem, useModalControl, usePopoverMenu } from '@frontend/design-system';
import { Message } from '../../../../types';
import { DeleteMessageConfirmation } from './delete-message-confirmation';

type Action = (message: Message) => void;

export interface FlyoutMessageMenuProps {
  message: Message;
  onDelete: Action;
  onEdit: Action;
}

export const FlyoutMessageMenuActions = ({ message, onDelete, onEdit }: FlyoutMessageMenuProps) => {
  const { t } = useTranslation('team-chat');
  const { close, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom-end',
  });
  const { modalProps, openModal } = useModalControl();

  const handleClick = (action: Action) => {
    action(message);
    close();
  };

  return (
    <>
      <IconButton
        {...getTriggerProps()}
        label={t('More')}
        showLabelOnHover
        size='small'
        trackingId='team-chat-v2-more-message-actions'
        style={{ padding: theme.spacing(0.5) }}
      >
        <Icon name='more' />
      </IconButton>

      <PopoverMenu {...getMenuProps()} css={styles.popover}>
        {/* TODO :: enable following options when we are ready */}
        {/* <PopoverMenuItem onClick={() => handleClick(onReminderSet)}>{t('Remind me about this')}</PopoverMenuItem> */}
        {/* <PopoverMenuItem onClick={() => handleClick(onMarkRead)}>{t('Mark as unread')}</PopoverMenuItem> */}

        <>
          <PopoverMenuItem onClick={() => handleClick(onEdit)} trackingId='team-chat-2.0-edit-message-button'>
            {t('Edit Message')}
          </PopoverMenuItem>
          <PopoverMenuItem destructive onClick={openModal} trackingId='team-chat-2.0-delete-message-button'>
            {t('Delete Message...')}
          </PopoverMenuItem>
        </>
      </PopoverMenu>

      <DeleteMessageConfirmation message={message} modalProps={modalProps} onDelete={() => handleClick(onDelete)} />
    </>
  );
};

const styles = {
  popover: css`
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${theme.shadows.floating};
    margin: ${theme.spacing(0.5, 2)};
    padding: 0;
  `,
};
