import { ReactNode, useCallback } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { BarChartData, Chart, CustomLegendsData, PieChartData, XAxisLabelValueTick } from '@frontend/charts';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CompareLocationsButton } from '../../../../compare-locations-button';
import { DemoChip } from '../../../../demo-chip';
import { MessagingRoi } from '../../../types';
import { ShowMoreButton } from '../../show-more-button';

type ChartConfig =
  | {
      data: PieChartData;
      type: 'pie';
    }
  | {
      data: BarChartData;
      maxValue: number;
      type: 'bar';
    };

export interface MessagingChartBaseInterface {
  filteredLocationIds: string[];
  isDemoAccount?: boolean;
  isLoading?: boolean;
  subViewUrl: string;
}

interface Props extends MessagingChartBaseInterface {
  chartConfig: ChartConfig;
  customLegendsData?: CustomLegendsData;
  infoTip?: ReactNode;
  legendsFormatValue: (value: number) => string;
  legendsValues: Record<string, number>;
  showMoreView: MessagingRoi;
  title: string;
  trackingIdBase?: string;
}

const labels = {
  existingPatients: i18next.t('Existing Patients', { ns: 'analytics' }),
  newPatients: i18next.t('New Patients', { ns: 'analytics' }),
  unsheduledPatients: i18next.t('Unscheduled Leads', { ns: 'analytics' }),
};

const colors = {
  existingPatients: theme.colors.primary20,
  newPatients: theme.colors.warning20,
  unsheduledPatients: theme.colors.indigo50,
};

export const MessagingChartsBase = ({
  chartConfig,
  customLegendsData,
  filteredLocationIds = [],
  infoTip,
  isDemoAccount,
  isLoading,
  legendsFormatValue,
  legendsValues,
  showMoreView,
  subViewUrl,
  title,
  trackingIdBase = '',
}: Props) => {
  const navigate = useNavigate();
  const search = useSearch<{ Search: { view: MessagingRoi } }>();
  const isMainView = !search.view;

  const handleNavigateToSubView = useCallback(() => {
    navigate({
      to: subViewUrl,
      search: { view: showMoreView },
    });
  }, [subViewUrl, showMoreView]);

  return (
    <Chart
      colors={colors}
      isLoading={isLoading}
      labels={labels}
      noBorder={!isMainView}
      onClick={isMainView ? handleNavigateToSubView : undefined}
      style={{ marginBottom: 0 }}
      trackingId={`${trackingIdBase}-chart`}
    >
      {isMainView && (
        <Chart.Header
          bottomElement={
            (filteredLocationIds?.length || 0) > 1 && (
              <CompareLocationsButton
                onClick={handleNavigateToSubView}
                trackingId={`${trackingIdBase}-compare-locations-button`}
              />
            )
          }
          infoTip={infoTip}
          leftElement={isDemoAccount && <DemoChip />}
          style={{ marginBottom: theme.spacing(2) }}
          title={title}
        />
      )}
      <div css={styles.chartWrapper}>
        {chartConfig.type === 'pie' ? (
          <Chart.PieChart
            appearance={{
              hideTooltipTitle: true,
              innerRadius: '60%',
              outerRadius: '100%',
              height: 210,
            }}
            data={chartConfig.data}
            formatValue={legendsFormatValue}
          />
        ) : (
          <div css={styles.barChart}>
            <Chart.BarChart
              appearance={{
                barBackground: true,
                barBackgroundStrokeWidth: 1,
                barSize: 80,
                customXAxisTick: ({ groupName, ...rest }) => {
                  return (
                    <XAxisLabelValueTick
                      {...rest}
                      value={legendsFormatValue?.(legendsValues[groupName])}
                      valueFontSize={24}
                      y={(rest.y || 0) + 8}
                    />
                  );
                },
                groupsGap: 40,
                height: 210,
                hideTooltipTitle: true,
                maxValue: chartConfig.maxValue,
                showXAxis: true,
              }}
              data={chartConfig.data}
              formatValue={legendsFormatValue}
            />
          </div>
        )}
        <Chart.Legends
          customData={customLegendsData}
          formatValue={legendsFormatValue}
          style={{ marginTop: theme.spacing(3) }}
          values={legendsValues}
        />
      </div>
      {isMainView && <ShowMoreButton onClick={handleNavigateToSubView} trackingIdBase={trackingIdBase} />}
    </Chart>
  );
};

const styles = {
  chartWrapper: css`
    display: flex;
    flex-direction: column;
    min-width: 242px;
    position: relative;
    flex-grow: 1;
  `,

  barChart: css`
    display: flex;
    justify-content: center;
  `,
};
