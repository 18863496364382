import {
  CampaignType_Enum as CampaignType,
  List,
  Recipient,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Text, TextField, useFormField } from '@frontend/design-system';

type Props = {
  createContactList: (list: List) => Promise<void>;
  recipientsToUpload: Recipient[];
  setRecipientsToUpload: (recipients: Recipient[]) => void;
};

export const NewSavedListItem = ({ createContactList, recipientsToUpload, setRecipientsToUpload }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const textFieldProps = useFormField({ type: 'text' });

  return (
    <div
      css={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.colors.neutral10}`,
        borderRadius: theme.borderRadius.medium,
      }}
      data-trackingid={`${BulkEmailPrefixes.Audience}-contact-list-item`}
    >
      <Text size='large' weight='bold' css={{ marginBottom: theme.spacing(2) }}>
        {t('New List')}
      </Text>
      <div>
        {/* TODO: Make this save when enter is pressed. Cancelled when escape is pressed. */}
        <TextField
          {...textFieldProps}
          name='list-name'
          label={t('List Name')}
          css={{ marginBottom: theme.spacing(1) }}
          autoFocus
        />
        <Button
          variant='secondary'
          css={{ marginRight: theme.spacing(2) }}
          size='small'
          onClick={() => setRecipientsToUpload([])}
        >
          {t('Cancel')}
        </Button>
        <Button
          size='small'
          disabled={!textFieldProps.value}
          onClick={async () => {
            const { locationId } = recipientsToUpload[0];
            await createContactList({
              locationId,
              type: CampaignType.EMAIL, // TODO: Pass this in as a prop
              name: textFieldProps.value,
              recipients: recipientsToUpload,
            });
            setRecipientsToUpload([]);
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
