import { ReactNode, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { StoredReader, TerminalReader } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import { BaseFieldProps, DropdownField, TextField, useFormField } from '@frontend/design-system';
import { ReceiptSection } from './receipt-section';

const styles = {
  section: css`
    display: grid;
    grid-template-rows: 1fr auto;
    justify-items: center;
    padding: ${theme.spacing(2)};
  `,
  paymentForm: css`
    flex: 1;
    display: flex;
    gap: ${theme.spacing(2)};
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: ${theme.spacing(50)};
  `,
};

type CollectReaderPaymentDisplayProps = {
  selectedReader: StoredReader | undefined;
  ReaderStatusOverrideComponent?: ReactNode;
  availableReaders?: TerminalReader[];
  onSelectedReaderChange?: (reader: TerminalReader) => void;
  emailReceiptFieldProps: BaseFieldProps<string, string, HTMLInputElement>;
  disableTerminalSelection?: boolean;
  isTerminalInUse: boolean;
  paymentSuccessful: boolean;
};

export const CollectReaderPaymentDisplay = ({
  selectedReader,
  paymentSuccessful,
  isTerminalInUse,
  ReaderStatusOverrideComponent,
  availableReaders,
  emailReceiptFieldProps,
  onSelectedReaderChange,
  disableTerminalSelection = false,
}: CollectReaderPaymentDisplayProps) => {
  const { t } = useTranslation('payments');

  const readerNameFieldProps = useFormField(
    {
      type: 'text',
      value: selectedReader?.label,
    },
    [selectedReader?.readerId]
  );

  const availableReaderProps = useFormField(
    {
      type: 'dropdown',
      value: selectedReader?.readerId,
    },
    [selectedReader?.readerId]
  );

  useEffect(() => {
    if (availableReaderProps.value) {
      const reader = availableReaders?.find((reader) => reader.id === availableReaderProps.value);

      if (reader && reader?.id !== selectedReader?.readerId) {
        onSelectedReaderChange?.(reader);
      }
    }
  }, [availableReaderProps.value]);

  return (
    <>
      <section css={styles.section}>
        <div css={styles.paymentForm}>
          {availableReaders?.length ? (
            <DropdownField
              name={'available-terminals'}
              label={t('Terminal')}
              disabled={disableTerminalSelection}
              {...availableReaderProps}
            >
              {availableReaders.map(({ id, label, status }) => (
                <DropdownField.Option key={id} value={id} disabled={status === 'offline'}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          ) : (
            selectedReader && <TextField name='reader-name' label='Terminal' {...readerNameFieldProps} readOnly />
          )}
          {ReaderStatusOverrideComponent}
        </div>
        {!isTerminalInUse && paymentSuccessful && <ReceiptSection emailFieldProps={emailReceiptFieldProps} />}
      </section>
    </>
  );
};
