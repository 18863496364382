import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { BatchGetThreadStatusIO } from '../types';

/**
 * This function standardizes the order of the requests in the input object and formats the phone numbers to E.164
 * format to optimize the react query cache hit rate.
 */
export const standardizeBatchGetThreadStatusRequest = (
  request: BatchGetThreadStatusIO['input']
): BatchGetThreadStatusIO['input'] => {
  return {
    ...request,
    requests: [...request.requests]
      .map((request) => ({
        ...request,
        personPhone: request.personPhone ? formatPhoneNumberE164(request.personPhone) : request.personPhone,
      }))
      .sort((a, b) => {
        const aEntries = Object.entries(a);
        const aString = aEntries.filter(([, val]) => !!val).join(':');
        const bEntries = Object.entries(b);
        const bString = bEntries.filter(([, val]) => !!val).join(':');
        return aString.localeCompare(bString);
      }),
  };
};
