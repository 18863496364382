import { MessagesTypes } from '@frontend/api-messaging';
import { Chips } from '@frontend/chips';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, ListRow } from '@frontend/design-system';
import { InboxListItemTags } from './inbox-list-item-tags';

type InboxListItemContentProps = {
  locationId: string;
  threadNavData: {
    personPhone: string;
    threadId: string;
    departmentId?: string;
    personId?: string;
  };
  shouldRenderChipsRow: boolean;
  showLocationChip: boolean;
  showDepartmentChip: boolean;
  departmentName?: string;
  isBlocked?: boolean;
  messageBodyPreviewText: string;
  uniqueTags?: MessagesTypes.InboxListItem['uniqueTags'];
  isDraft?: boolean;
};

export const InboxListItemContent = ({
  locationId,
  threadNavData,
  shouldRenderChipsRow,
  showLocationChip,
  showDepartmentChip,
  departmentName,
  isBlocked,
  messageBodyPreviewText,
  uniqueTags,
}: InboxListItemContentProps) => {
  const { getScopeName } = useAppScopeStore();

  return (
    <>
      {shouldRenderChipsRow && (
        <div css={{ display: 'flex', gap: theme.spacing(1), margin: theme.spacing(1, 0) }}>
          {showLocationChip && (
            <Chips.LocationChip css={{ fontSize: theme.fontSize(12) }} className='inbox-item-chip'>
              {getScopeName(locationId)}
            </Chips.LocationChip>
          )}
          {showDepartmentChip && departmentName && (
            <Chip.Department css={{ fontSize: theme.fontSize(12) }} className='inbox-item-chip'>
              {departmentName}
            </Chip.Department>
          )}
        </div>
      )}
      {!isBlocked && (
        <ListRow.Content.Subtitle
          css={{
            whiteSpace: 'pre-line',
            fontWeight: isBlocked ? theme.font.weight.semibold : 'inherit',
          }}
        >
          {messageBodyPreviewText}
        </ListRow.Content.Subtitle>
      )}
      <InboxListItemTags
        uniqueTags={uniqueTags}
        threadNavData={{
          ...threadNavData,
          groupId: locationId,
        }}
      />
    </>
  );
};
