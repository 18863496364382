import { ReactNode, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { LayoutType_Enum, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { useTranslation } from '@frontend/i18n';
import { useNavSize } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, Heading, Text } from '@frontend/design-system';
import { DeviceLayout } from '../device-layout';
import { EmptyStateLayout } from './empty-state';
import { LineKeyPagination } from './pagination';

type Props = {
  device: LineKeysTypes.ListDeviceType | undefined;
  model: LineKeysTypes.ModelInfoType | undefined;
  lineKeys: LineKeysTypes.GetLineKeysType['output']['lineKeys'] | undefined;
  isPreviewEnabled?: boolean;
  setShowDeviceLayoutPreview?: (val: boolean) => void;
};

export const Layout = ({ device, model, lineKeys, setShowDeviceLayoutPreview, isPreviewEnabled }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const paginationRef = useRef<HTMLDivElement>(null);
  const touchScreenRef = useRef<HTMLDivElement>(null);
  const [pageNum, setPageNum] = useState(1);

  const keysPerPage = (model?.sideKeys?.keysPerPageLeft ?? 0) + (model?.sideKeys?.keysPerPageRight ?? 0);
  const totalPages = ((lineKeys?.length ?? 0) / keysPerPage < 1 ? 1 : model?.sideKeys?.pages) ?? 1;

  const isPaginationEnabled = !!model?.sideKeys?.pages && model?.sideKeys?.pages > 1 && totalPages > 1;
  const [hasHorizontalScrollbar, setHasHorizontalScrollbar] = useState(false);

  useEffect(() => {
    const divElement = touchScreenRef.current;

    if (divElement) {
      const hasScrollbar = divElement.scrollWidth > divElement.clientWidth;
      setHasHorizontalScrollbar(hasScrollbar);
    }
  }, []);

  useEffect(() => {
    if (!isPaginationEnabled) setPageNum(1);
  }, [isPaginationEnabled]);

  // some models can have line keys assigned to it but no model or model info to spec out the layout
  const isEmptyState = !model || (!model.sideKeys && !model.touchKeys);
  return (
    <>
      {isEmptyState ? (
        <EmptyStateLayout setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} isPreviewEnabled={isPreviewEnabled} />
      ) : (
        <Container isPreviewEnabled={isPreviewEnabled}>
          <ContainerHeader
            setShowDeviceLayoutPreview={setShowDeviceLayoutPreview}
            isPreviewEnabled={isPreviewEnabled}
            device={device}
          />
          <DeviceLayout model={model} isPreviewEnabled={isPreviewEnabled}>
            <DeviceLayout.Header model={model} device={device} />
            {model?.layoutType === LayoutType_Enum.TOUCH_SCREEN ? (
              <DeviceLayout.TouchScreen
                model={model}
                device={device}
                lineKeys={lineKeys}
                isPreviewEnabled={isPreviewEnabled}
                ref={touchScreenRef}
              />
            ) : (
              <DeviceLayout.LayoutWithKeys
                model={model}
                device={device}
                isPreviewEnabled={isPreviewEnabled}
                isPaginationEnabled={isPaginationEnabled}
                pageNum={pageNum}
                lineKeys={lineKeys}
              />
            )}
            <DeviceLayout.Footer model={model} />
          </DeviceLayout>
          <LineKeyPagination
            ref={paginationRef}
            setPageNum={setPageNum}
            totalPages={model?.sideKeys?.pages}
            isPaginationEnabled={isPaginationEnabled}
            pageNum={pageNum}
          />
          <Text css={{ color: theme.colors.neutral50 }} size='medium'>
            {hasHorizontalScrollbar &&
              t(
                '*Horizontal scrolling is enabled due to your screen size. The actual device will show all line keys without scrolling.'
              )}{' '}
          </Text>
        </Container>
      )}
    </>
  );
};

const Container = ({ children, isPreviewEnabled }: { isPreviewEnabled?: boolean; children: ReactNode }) => {
  return <section css={containerStyles({ isPreviewEnabled })}>{children}</section>;
};

type ContainerHeaderProps = {
  setShowDeviceLayoutPreview?: (val: boolean) => void;
  isPreviewEnabled?: boolean;
  device?: ListDevice;
};

const ContainerHeader = ({ setShowDeviceLayoutPreview, isPreviewEnabled, device }: ContainerHeaderProps) => {
  const navSize = useNavSize();
  const isMobile = navSize.isLte('medium');
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <>
      {isMobile && (
        <Button
          iconName='back'
          variant='secondary'
          size='large'
          onClick={() => setShowDeviceLayoutPreview?.(false)}
          css={css`
            border: 0;
          `}
        >
          {t('Back')}
        </Button>
      )}
      <Heading css={{ marginBottom: theme.spacing(3) }} level={2}>
        {isPreviewEnabled
          ? t('{{name}} Layout', { name: device?.name })
          : t('Preview {{name}} Layout', { name: device?.name })}
      </Heading>
    </>
  );
};

const containerStyles = ({ isPreviewEnabled }: { isPreviewEnabled?: boolean }) => css`
  position: relative;
  ${!isPreviewEnabled && `padding: ${theme.spacing(8, 3, 0)}`};
  flex: 1;
  width: 100%;
  overflow-x: auto;
`;
