import { SendIO } from '../types';
import { UseEmailSendV2MutationEndpointArgs, useEmailSendV2Mutation } from './use-email-send-v2-mutation';

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `Send` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useSendMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SendIO['input'] = SendIO['input']
>({
  options,
  // optimisticUpdate = false,
  ...args
}: UseEmailSendV2MutationEndpointArgs<'Send', E, C | undefined, OtherOptions, RequestOverride> = {}) =>
  useEmailSendV2Mutation<'Send', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Send',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        // Implement optimistic updates here

        return {
          // Add custom context type here (if exists in `MutationContext`)
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        // Implement query updates or invalidation for affected queries here

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
