import { PaymentOrigin } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { TFunction } from '@frontend/i18n';
import { CardBrand } from '@frontend/payments-card-brand';
import { paymentTypeMap } from '@frontend/payments-hooks';
import { StatusChip } from '@frontend/payments-status-chip';
import { theme } from '@frontend/theme';
import { CopyableText, TableColumnConfig } from '@frontend/design-system';
import { PaymentRow } from '../../types';
import { getPaymentMethod } from '../../utils';

/**
 * Outcome: Default empty user value should be "N/A" for:
 * - text to pay (online bill pay flow w/ invoice id)
 * - online bill pay (online bill pay flow w/o invoice id)
 */
const neverIncludedOrigins: PaymentOrigin[] = [PaymentOrigin.TextToPay, PaymentOrigin.OnlineBillPay];

export const generateColumns = (t: TFunction): TableColumnConfig<PaymentRow>[] => [
  {
    id: 'date',
    Header: t('Date', { ns: 'payments' }),
    accessor: (payment) => formatDate(payment.date, 'MMM D, YYYY'),
    disableSortBy: true,
    width: 120,
  },
  {
    id: 'time',
    Header: t('Time', { ns: 'payments' }),
    accessor: (payment) => formatDate(payment.date, 'h:mm A'),
    disableSortBy: true,
    width: 100,
  },
  {
    id: 'amount',
    Header: t('Amount', { ns: 'payments' }),
    accessor: (payment) => formatCentsToCurrency(payment.amount),
    disableSortBy: true,
    width: 100,
  },
  {
    id: 'origin',
    Header: t('Origin', { ns: 'payments' }),
    accessor: (payment) => (payment.origin ? paymentTypeMap[payment.origin] : '-'),
    disableSortBy: true,
    width: 120,
  },
  {
    id: 'method',
    Header: t('Method', { ns: 'payments' }),
    accessor: (payment) => payment,
    cellRenderer: (payment: PaymentRow) => {
      const paymentType = payment?.paymentType;
      const cardBrand = payment?.paymentDetails.brand;
      const last4 = payment?.paymentDetails.lastFour;
      return <CardBrand brand={getPaymentMethod(paymentType, cardBrand)} last4={last4} />;
    },
    disableSortBy: true,
    width: 120,
  },
  {
    id: 'confirmation_number',
    Header: t('Confirmation Number', { ns: 'payments' }),
    accessor: (payment) => payment,
    cellRenderer: (payment) =>
      payment.confirmationCode ? (
        <CopyableText
          css={{
            textOverflow: 'ellipsis',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: theme.font.size.medium,
          }}
        >
          {payment.confirmationCode || '-'}
        </CopyableText>
      ) : (
        '-'
      ),
    disableSortBy: true,
  },
  {
    id: 'user',
    Header: t('Office User', { ns: 'payments' }),
    accessor: ({ user, origin }) => {
      const emptyValue = origin && neverIncludedOrigins.includes(origin) ? t('N/A', { ns: 'payments' }) : '-';
      return user || emptyValue;
    },
    disableSortBy: true,
    width: 140,
  },
  {
    id: 'status',
    Header: t('Status', { ns: 'payments' }),
    accessor: (payment) => payment,
    cellRenderer: (payment) => <StatusChip status={payment?.status} maxWidth={'140'} />,
    disableSortBy: true,
    width: 180,
  },
];
