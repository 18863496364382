import { FieldMask } from '@weave/schema-gen-ts/dist/google/protobuf/field_mask.pb';
import { FIELD_MASK_CREATION_FILTER_PRESETS } from './constants';
import { FieldMaskCreationConfig, FieldMaskCreationFilterFn } from './types';

/**
 * A function that takes an object and returns a list of strings that represents the paths of all values in the object
 * according to the provided `filter`.
 *
 * @param obj - The object from which to derive the paths
 * @param config.filter (optional) - A callback function (or preset) that determines which entries in the object might
 * be ignored. It takes in an object for each entry in the object with the following values:
 * - `key`: The key associated with the value in the object, the provided `keyTransformation` does not affect this key
 * - `transformedKey`?: The key associated with the value in the object, after transformation defined by the provided
 *   `keyTransformation`
 * - `value`: The value in the object.
 *
 * Some preset filters are also available.
 * To use them, pass one of the following strings instead:
 * - 'remove-nullish'
 * - 'remove-falsy'
 * - 'preserve-all' (default)
 * @param config.keyTransformation (optional) - A callback function to transform each key in the path. Useful to
 * convert between snake_case and camelCase when working with backend and frontend casing differences.
 */
export const convertObjectToFieldMaskPaths = <T extends object>(
  obj: T,
  { filter = 'preserve-all', keyTransformation }: FieldMaskCreationConfig<T> = {}
): NonNullable<FieldMask['paths']> => {
  const filterFn: FieldMaskCreationFilterFn<T> =
    typeof filter === 'string' ? FIELD_MASK_CREATION_FILTER_PRESETS[filter] : filter;
  const entries = Object.entries(obj);

  const paths = entries.reduce<NonNullable<FieldMask['paths']>>((acc, [key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== undefined && value !== null) {
      acc.push(
        ...convertObjectToFieldMaskPaths(value, { filter, keyTransformation }).map(
          (path) => `${keyTransformation?.(key) ?? key}.${path}`
        )
      );
    } else if (filterFn<T>({ key: key as keyof T, transformedKey: keyTransformation?.(key), value })) {
      acc.push(keyTransformation?.(key) ?? key);
    }
    return acc;
  }, []);

  return paths;
};

/**
 * A function that takes an object and returns a FieldMask that represents the paths of all values in the object
 * according to the provided `filterBehavior`.
 *
 * @param obj - The object from which to derive the paths
 * @param config.filter (optional) - A callback function (or preset) that determines which entries in the object might
 * be ignored. It takes in a triple for each entry in the object as `[key, value, originalValue (optional)]`. Some
 * preset filters are available. To use them, pass one of the following strings instead:
 * - 'remove-nullish'
 * - 'remove-falsy'
 * - 'preserve-all' (default)
 * @param config.keyTransformation (optional) - A callback function to transform each key in the path. Useful to
 * convert between snake_case and camelCase when working with backend and frontend casing differences.
 */
export const convertObjectToFieldMask = <T extends object>(obj: T, config?: FieldMaskCreationConfig<T>) => {
  const paths = convertObjectToFieldMaskPaths(obj, config);

  return new FieldMask({
    paths,
  });
};
