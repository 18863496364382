import { useCallback, useState } from 'react';
import { GlobalBannerDynamic } from '@frontend/global-info-banner';
import { useTranslation } from '@frontend/i18n';

export const NotificationsDisabledGlobalBanner = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  if (!show) return null;

  return (
    <GlobalBannerDynamic
      type='info'
      id='notifications-disabled'
      message={t(
        `You're a busy bee. Unfortunately, after 30+ locations are selected, our hive is full with too much buzzing. Deselect some locations to resume notifications.`
      )}
      onDismiss={handleClose}
      title={t('Notifications Paused')}
    />
  );
};
