import { useMutation, type MutationOptions } from 'react-query';
import type { StreamChat } from 'stream-chat';
import {
  deleteMessage,
  editMessage,
  sendMessage,
  addReactionToMessage,
  removeReactionFromMessage,
  uploadAttachments,
  updateUser,
  createConversation,
  sendConversationEvent,
  addMembersToConversation,
  updateConversation,
  deleteConversation,
  sendReply,
  removeMembersFromConversation,
} from './stream-api';

type AsyncFn = (...args: any) => PromiseLike<any>;
type MutationOpts<TFn extends AsyncFn, TVariables extends any> = MutationOptions<
  TFn extends (...args: any) => Promise<infer U> ? U : unknown,
  unknown,
  TVariables extends any ? TVariables : Parameters<TFn>,
  unknown
>;

const verifyClientWithUser = (client: StreamChat | undefined) => {
  if (!client) {
    throw new Error('Stream client is not initialized');
  }
  if (!client.user?.id) {
    throw new Error('Stream client user is not connected');
  }
  return Promise.resolve(client);
};

export const useMutationSendMessage = (
  streamClient: StreamChat | undefined,
  currentUserId: string,
  options: MutationOpts<
    typeof sendMessage,
    {
      conversationId: string;
      message: Parameters<typeof sendMessage>[0]['message'];
    }
  > = {}
) => {
  return useMutation({
    mutationKey: ['team-chat', currentUserId, 'send-message'],
    mutationFn: ({ conversationId, message }) =>
      verifyClientWithUser(streamClient).then((client) =>
        sendMessage({ client, userId: currentUserId, conversationId, message })
      ),
    ...options,
  });
};

export const useMutationSendReply = (
  streamClient: StreamChat | undefined,
  currentUserId: string,
  conversationId: string,
  parentId: string,
  options: MutationOpts<typeof sendReply, Parameters<typeof sendReply>[3]> = {}
) => {
  return useMutation({
    mutationKey: ['team-chat', currentUserId, conversationId, parentId, 'send-reply'],
    mutationFn: (reply) =>
      verifyClientWithUser(streamClient).then((client) => sendReply(client, currentUserId, conversationId, reply)),
    ...options,
  });
};

export const useMutationEditMessage = (
  streamClient: StreamChat | undefined,
  currentUserId: string,
  options: MutationOpts<typeof editMessage, Parameters<typeof editMessage>[2]> = {}
) => {
  return useMutation({
    mutationKey: ['team-chat', currentUserId, 'edit-message'],
    mutationFn: (message) =>
      verifyClientWithUser(streamClient).then((client) => editMessage(client, currentUserId, message)),
    ...options,
  });
};

export const useMutationDeleteMessage = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<typeof deleteMessage, { messageId: string; hardDelete?: boolean }>
) => {
  return useMutation({
    mutationKey: ['team-chat', 'delete-message'],
    mutationFn: ({ messageId, hardDelete }) =>
      verifyClientWithUser(streamClient).then((client) => deleteMessage(client, messageId, hardDelete)),
    ...options,
  });
};

export const useMutationAddReaction = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<typeof addReactionToMessage, { channelId: string; messageId: string; reaction: string }>
) => {
  return useMutation({
    mutationKey: ['team-chat', 'add-reaction'],
    mutationFn: ({ channelId, messageId, reaction }) =>
      verifyClientWithUser(streamClient).then((client) => addReactionToMessage(client, channelId, messageId, reaction)),
    ...options,
  });
};

export const useMutationDeleteReaction = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<
    typeof removeReactionFromMessage,
    { channelId: string; messageId: string; reaction: string; parentMessageId: string }
  >
) => {
  return useMutation({
    mutationKey: ['team-chat', 'delete-reaction'],
    mutationFn: ({ channelId, messageId, reaction }) =>
      verifyClientWithUser(streamClient).then((client) =>
        removeReactionFromMessage(client, channelId, messageId, reaction)
      ),
    ...options,
  });
};

export const useMutationAddAttachments = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<
    typeof uploadAttachments,
    {
      files: File[];
      channelId: string;
    }
  >
) => {
  return useMutation({
    mutationKey: ['team-chat', 'add-attachment'],
    mutationFn: ({ files, channelId }) =>
      verifyClientWithUser(streamClient).then((client) => uploadAttachments(client, files, channelId)),
    ...options,
  });
};

export const useMutationUpdateUser = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<typeof updateUser, Parameters<typeof updateUser>[1]>
) => {
  return useMutation({
    mutationKey: ['team-chat', 'update-user'],
    mutationFn: (data) => verifyClientWithUser(streamClient).then((client) => updateUser(client, data)),
    ...options,
  });
};

export const useMutationCreateConversation = (
  streamClient: StreamChat | undefined,
  orgId: string,
  options: MutationOpts<typeof createConversation, Parameters<typeof createConversation>[2]>
) => {
  return useMutation({
    mutationKey: ['team-chat', 'create-channel'],
    mutationFn: (conversation) =>
      verifyClientWithUser(streamClient).then((client) => createConversation(client, orgId, conversation)),
    ...options,
  });
};

export const useMutationUpdateConversation = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<
    typeof updateConversation,
    {
      conversationId: string;
      updates: Parameters<typeof updateConversation>[2];
    }
  >
) => {
  return useMutation({
    mutationKey: ['team-chat', 'update-channel'],
    mutationFn: ({ conversationId, updates }) =>
      verifyClientWithUser(streamClient).then((client) => updateConversation(client, conversationId, updates)),
    ...options,
  });
};

export const useMutationDeleteConversation = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<typeof deleteConversation, string>
) => {
  return useMutation({
    mutationKey: ['team-chat', 'delete-channel'],
    mutationFn: (conversationId) =>
      verifyClientWithUser(streamClient).then((client) => deleteConversation(client, conversationId)),
    ...options,
  });
};

export const useMutationSendConversationEvent = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<
    typeof sendConversationEvent,
    {
      conversationId: string;
      event: Parameters<typeof sendConversationEvent>[2];
    }
  >
) => {
  return useMutation({
    mutationKey: ['team-chat', 'mark-read'],
    mutationFn: ({ conversationId, event }) =>
      verifyClientWithUser(streamClient).then((client) => sendConversationEvent(client, conversationId, event)),
    ...options,
  });
};

export const useMutationAddMembersToConversation = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<
    typeof addMembersToConversation,
    {
      conversationId: string;
      userIds: string[];
      message?: string;
    }
  >
) => {
  return useMutation({
    mutationKey: ['team-chat', 'add-members'],
    mutationFn: ({ conversationId, userIds, message }) =>
      verifyClientWithUser(streamClient).then((client) =>
        addMembersToConversation(client, conversationId, userIds, message)
      ),
    ...options,
  });
};

export const useMutationRemoveMembersFromConversation = (
  streamClient: StreamChat | undefined,
  options: MutationOpts<
    typeof removeMembersFromConversation,
    {
      conversationId: string;
      userIds: string[];
      message?: string;
    }
  >
) => {
  return useMutation({
    mutationKey: ['team-chat', 'remove-members'],
    mutationFn: ({ conversationId, userIds, message }) =>
      verifyClientWithUser(streamClient).then((client) =>
        removeMembersFromConversation(client, conversationId, userIds, message)
      ),
    ...options,
  });
};
