import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const centerContainedStyle = css({ display: 'flex', justifyContent: 'center', alignItems: 'center' });

export const channelSettingsContainerStyle = css({
  '.channel-settings-item:nth-child(n)': {
    width: '100%',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.colors.neutral20}`,
  },
  '.channel-settings-item:last-child': {
    borderBottom: 'none',
  },

  margin: theme.spacing(1, 0, 0, 0),
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.medium,
});
