import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { Entity } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/entity.pb';
import { UiSource } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/ui_source.pb';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { startCase } from 'lodash-es';
import { unparse } from 'papaparse';
import { useQueryClient } from 'react-query';
import { Row } from 'react-table';
import { CallRecordingApi } from '@frontend/api-call-recordings';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import { TagsUtils } from '@frontend/api-tag';
import { getWeaveToken, hasSchemaACL } from '@frontend/auth-helpers';
import { Chips } from '@frontend/chips';
import { EmptyStateConfig } from '@frontend/components';
import { ActionsUI } from '@frontend/contact-actions';
import { useCustomContactFormSlidePanel } from '@frontend/create-contact-panel';
import appConfig from '@frontend/env';
import { processExportableData } from '@frontend/file-download-helper';
import { useTranslation, i18next } from '@frontend/i18n';
import { formatPhoneNumber, isPartialPhoneNumber, isPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useMutation } from '@frontend/react-query-helpers';
import { PhoneRecordsSchemaService } from '@frontend/schema';
import { useAppScopeStore, useOnScopeChange, useScopedAppFlagStore, useScopedInfiniteQuery } from '@frontend/scope';
import {
  formatResultsPrimaryKeys,
  handleRegisterClick,
  handleTriggerSearch,
  handleUpdateSearch,
  SearchHistory,
  useSearchHistoryShallowStore,
} from '@frontend/search-history';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  AudioScrubber,
  Table,
  Chip,
  useAlert,
  useModalControl,
  ConfirmationModal,
  DownloadIcon,
  SpinningLoader,
  TableInstance,
  TextLink,
  useDebouncedFn,
} from '@frontend/design-system';
import {
  AllCallsTable,
  CallRecordsFiltersType,
  PaginationSettings,
  usePhonePageSettingShallowStore,
} from '../../hooks/use-phone-config-state-store';
import { useRecentCallsTagMutation, useTags } from '../../hooks/use-tags';
import { queryKeys } from '../../query-keys';
import { getActionIcon } from '../../ui-utils';
import { defaultDateRangeMap, formatEndDateWithTimeZone, formatStartDateWithTimeZone, getFullName } from '../../utils';
import { ProfileCard } from '../all-calls/profile-card';
import { Action, CustomActionCell } from '../all-calls/shared-buttons';
import { ToolbarChipFilter } from '../all-calls/toolbar-filter';
import { HydratedCallRecord, RecentCallRecord, RecentCallRecords } from '../all-calls/types';
import { getTimePeriod } from '../all-calls/utils';
import { DEFAULT_NO_TIME_PERIOD } from '../filter-selectors/time-period-selector';
import { CallDetailsContext, CallDetailsPanelAccordionType } from './call-details-panel/types';
import { RecentCallsTrayFilter } from './calls-filter';
import { deriveExportColumnValue } from './export-data';
import { AddTag, RecentCallsTagSelector } from './recent-calls-tag-selector';
import {
  getOfficeUser,
  getShortHandName,
  getRecentCallsSearchQuery,
  getStatusChipColor,
  getRecentCallsUniqueKey,
} from './utils';
import { ViewCallDetailsColumn } from './view-call-details';

type RecentCallProps = {
  setTimeRange: (subtitle: string) => void;
};

type PageParams = {
  lastId: string;
  endDate: string;
};

type SetPageConfigInput = Partial<PaginationSettings> | ((_: PaginationSettings) => Partial<PaginationSettings>);

const fetchDepartmentsByLocation = async (locationId: string) => {
  const response = await DepartmentsApi.listDept({ locationId }, { locationId: locationId });
  return response.departments;
};

const defaultEmptyState: EmptyStateConfig = {
  type: 'sync_your_phone',
  header: i18next.t('No Items to Display'),
  description: i18next.t('Inbound and outbound call records will display here.'),
};

const defaultRecentCallsFilters: CallRecordsFiltersType = {
  status: [],
  phoneNumber: '',
  name: '',
  locationIds: [],
  startDate: defaultDateRangeMap[DEFAULT_NO_TIME_PERIOD].startDate,
  endDate: defaultDateRangeMap[DEFAULT_NO_TIME_PERIOD].endDate,
};

const entity = Entity.ENTITY_CALL;
export const RecentCallsTable = ({ setTimeRange }: RecentCallProps) => {
  const alert = useAlert();
  const { t } = useTranslation('calls');
  const weaveToken = getWeaveToken();
  const queryClient = useQueryClient();

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const departmentsEnabled = getFeatureFlagValue('departments');
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow, show, context, panelType } = useSlidePanelShallowStore<CallDetailsContext>(
    'setShow',
    'show',
    'context',
    'panelType'
  );
  const { setShow: openPanel } = useCustomContactFormSlidePanel();
  const tableInstanceRef = useRef<TableInstance<RecentCallRecords[]>>();

  const { getLocationName, selectedLocationIds } = useAppScopeStore();
  const {
    config,
    setPageSize,
    setPageNumber,
    setFilters: setCallsPageFilters,
  } = usePhonePageSettingShallowStore('config', 'setPageSize', 'setPageNumber', 'setFilters');

  const [emptyState, setEmptyState] = useState<EmptyStateConfig>(defaultEmptyState);

  const setFilters = (filters: CallRecordsFiltersType) => {
    setCallsPageFilters(AllCallsTable.RecentCalls, filters);
  };

  const hasAccesstoCallRecordingMap = useMemo(() => {
    return selectedLocationIds.reduce((accessMap, locationId) => {
      accessMap[locationId] = hasSchemaACL(locationId, Permission.CALL_RECORDING_READ);
      return accessMap;
    }, {} as Record<string, boolean>);
  }, [selectedLocationIds]);

  const hasAccesstoCallRecording = useMemo(() => {
    return Object.values(hasAccesstoCallRecordingMap).some((hasAccess) => hasAccess);
  }, [hasAccesstoCallRecordingMap]);

  const [departmentsByLocation, setDepartmentsByLocation] = useState<
    Record<string, DepartmentsTypes.ListDepartmentTypes['output']>
  >({});
  useEffect(() => {
    const fetchData = async () => {
      const data: Record<string, DepartmentsTypes.ListDepartmentTypes['output']> = {};
      for (const locId of selectedLocationIds) {
        const departments = await fetchDepartmentsByLocation(locId);
        data[locId] = { departments: departments };
      }
      setDepartmentsByLocation(data);
    };

    fetchData();
  }, [selectedLocationIds]);

  const pageNumber = config[AllCallsTable.RecentCalls].pageNumber;
  const pageSize = config[AllCallsTable.RecentCalls].pageSize;
  const filtersInStore = config[AllCallsTable.RecentCalls].filters;
  const filters = filtersInStore || defaultRecentCallsFilters;

  useOnScopeChange(() => {
    setFilters({ ...filters, locationIds: [] });
  });

  useEffect(() => {
    // initialize the filters if empty
    if (!filtersInStore) {
      // set the filter state in store which is used to fetch the data
      // in call record realtime update
      setFilters(filters);
    }
  }, []);

  useEffect(() => {
    setTimeRange(getTimePeriod(filters.startDate!, filters.endDate!));
  }, [filters.startDate, filters.endDate]);

  const setPageConfig = (input: SetPageConfigInput) => {
    const data = typeof input === 'function' ? input({ pageSize, pageNumber }) : input;
    if (data.pageSize) setPageSize(AllCallsTable.RecentCalls, data.pageSize);
    if (data.pageNumber) setPageNumber(AllCallsTable.RecentCalls, data.pageNumber);
  };

  const { mappedTags, isLoading: isTagDataLoading } = useTags();

  const searchQuery = useMemo(
    () => getRecentCallsSearchQuery(filters, pageSize, mappedTags),
    [filters, pageSize, mappedTags]
  );
  const { searchId, setDebouncedValue } = useSearchHistoryShallowStore('searchId', 'setDebouncedValue');
  const onSearchHistoryChange = useDebouncedFn((value) => setDebouncedValue(value), 3000);

  useEffect(() => {
    handleTriggerSearch({
      entities: [entity],
      searchTerm: filters.phoneNumber || filters.name || '',
      uiSource: UiSource.RECENT_CALLS_SEARCH,
    });
  }, [filters.name, filters.phoneNumber]);

  const {
    data: recentCallRecords,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
  } = useScopedInfiniteQuery<RecentCallRecords, unknown>({
    queryKey: queryKeys.recentCallLogs(searchQuery),
    queryFn: async ({ pageParam }) => {
      const {
        records = [],
        lastId,
        endDate,
      } = await PhoneCallsApi.getMultiCallRecords({
        limit: pageSize,
        locationIds: filters.locationIds.length ? filters.locationIds : selectedLocationIds,
        filters: {
          startTime: formatStartDateWithTimeZone(filters.startDate),
          endTime: formatEndDateWithTimeZone(filters.endDate),
          callStatus: filters.status,
          callDirection: filters.callDirection,
          phoneNumber: filters.phoneNumber,
          name: filters.name,
        },
        page: {
          lastRecordId: pageParam?.lastId,
          lastRecordTime: pageParam?.endDate,
        },
      });
      const parsedData: RecentCallRecord[] = records.map((callItem: HydratedCallRecord) => {
        return { ...callItem, tags: callItem.tagIds?.map((tagId) => mappedTags[tagId]).filter(Boolean) || [] };
      });
      return { data: parsedData, meta: { lastId, endDate } };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.meta as PageParams;
    },
    getPreviousPageParam: (lastPage) => {
      return lastPage.meta as PageParams;
    },
    onError: () => {
      alert.error(t("Couldn't load the data. Please try again."));
    },
    onSettled: (res) => {
      // send the results
      handleUpdateSearch({
        entity,
        results: formatResultsPrimaryKeys<RecentCallRecord>('id', res?.pages[0]?.data),
      });
    },
    enabled: !isTagDataLoading,
  });

  const getHasNext = () => {
    const pageData = recentCallRecords?.pages[pageNumber - 1]?.data || [];
    return hasNextPage && !!pageData.length && pageData.length == pageSize;
  };

  useEffect(() => {
    setPageConfig({ pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!recentCallRecords?.pages[pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageNumber]);

  const getLocationChip = (locationId: string | undefined) => {
    if (!!locationId) {
      return <Chips.LocationChip>{getLocationName(locationId)}</Chips.LocationChip>;
    }
    return <span>{'--'}</span>;
  };

  const downloadMedia = async (record: HydratedCallRecord) => {
    const mediaUrl = `${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${record.channelId}?location_id=${record.locationId}&token=${weaveToken}`;
    try {
      const blob: any = await CallRecordingApi.download(mediaUrl);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${url.split('/')[3]}.mp3`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (e) {
      alert.error(t('unable to download the media'));
    }
  };

  const locationMap = selectedLocationIds.reduce((acc, locationId) => {
    return { ...acc, [locationId]: getLocationName(locationId) };
  }, {});

  const handleExport = () => {
    const startDate = filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '';
    const endDate = filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '';
    const fileName =
      !!startDate && !!endDate
        ? `Call-Report-(${startDate})to(${endDate})-page(${pageNumber})`
        : `Call-Report-page(${pageNumber})`;
    const multipleLocationsSelected = filters.locationIds.length > 1;
    const csv = unparse(
      processExportableData({
        columns: [
          t('Contact Name'),
          t('Call Direction'),
          t('Time'),
          t('Result'),
          t('Contact Number'),
          t('Office Number'),
          t('Office User'),
          ...(multipleLocationsSelected ? [t('Location')] : []),
          ...(departmentsEnabled ? [t('Department')] : []),
        ],
        data: recentCallRecords?.pages[pageNumber - 1]?.data || [],
        deriveExportValue: (params) =>
          deriveExportColumnValue({
            columnName: params.column,
            data: params.data,
            departmentsByLocation,
            locationMap,
          }),
      })
    );
    try {
      saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), `${fileName}.csv`);
      alert.success(t(`{{fileName}} downloaded successfully`, { fileName }));
    } catch (error) {
      alert.error(t('Failed to export file'));
    }
  };

  const deleteModalControls = useModalControl();
  const deletionCandidate = useRef<HydratedCallRecord | null>(null);
  const { mutate: deleteMedia } = useMutation(
    async (media: HydratedCallRecord) => {
      if (media.channelId && media.locationId) {
        return PhoneRecordsSchemaService.DeleteCallRecordings({
          channelIds: [media.channelId],
          locationIds: [media.locationId],
        });
      }

      return Promise.reject(new Error('Invalid media data'));
    },
    {
      onMutate: (media) => {
        //optimistic update
        const queryKey = [selectedLocationIds, ...queryKeys.recentCallLogs(searchQuery)];

        // get snapshot
        const previousData = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (oldData: any) => {
          const newData = oldData?.pages[pageNumber - 1]?.data.map((item: any) => {
            if (item.channelId === media.channelId) {
              return { ...item, recordingPath: null, recordingFilename: null };
            }

            return item;
          });
          return { pages: [{ data: newData }] };
        });

        return { previousData };
      },
      onError: (_err, _newData, context) => {
        alert.error(t('Media deletion failed.'));
        const queryKey = [selectedLocationIds, ...queryKeys.recentCallLogs(searchQuery)];

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        alert.success(t('Media deleted successfully.'));
      },
      onSettled: () => {
        deletionCandidate.current = null;
      },
    }
  );

  const tableData = recentCallRecords?.pages[pageNumber - 1]?.data || [];

  useEffect(() => {
    if (show && panelType === 'callDetails' && tableInstanceRef.current) {
      const existingRow = context?.currentRowId ? tableInstanceRef.current?.rowsById[context.currentRowId] : null;
      if (existingRow) {
        if (!existingRow.isSelected) {
          existingRow.toggleRowSelected(true);
        }
        setShow(true, 'callDetails', {
          rows: tableData.map((row) => ({ ...row })),
          currentRowId: existingRow.id,
          instanceId: tableInstanceRef.current.tableId,
          hasAccessToRecordings: hasAccesstoCallRecording,
          toggleRowSelected: tableInstanceRef.current.toggleRowSelected,
          callDetailsQueryKey: queryKeys.recentCallLogs(searchQuery).filter(Boolean),
          sectionFocus: context?.sectionFocus || 'details',
        });
      }
    }
  }, [tableData.length, tableData, tableInstanceRef.current?.tableId]);

  const registerClick = (data: RecentCallRecord, itemPosition?: number) => {
    if (!data.id || itemPosition === undefined) return;

    // there needs to be an existing search query and searchId to register a click
    if ((filters.name || filters.phoneNumber) && searchId) {
      handleRegisterClick({
        entity,
        entityId: data.id,
        entityPosition: 0,
        itemPosition,
      });
    }
  };

  return (
    <div css={styles.wrapper}>
      <Table
        uniqueRowId={(rowData, _) => {
          return getRecentCallsUniqueKey(rowData);
        }}
        tableInstanceId='recent-calls'
        instanceRef={tableInstanceRef}
        hasResizeColumns
        hasFilterColumns
        emptyStateConfig={emptyState}
        data={tableData}
        customToolbarRender={() => (
          <ToolbarChipFilter
            trackingBaseId='phone-weave2.0-recentCalls'
            filters={{
              locationIds: filters.locationIds,
              startDate: filters.startDate ?? '',
              endDate: filters.endDate ?? '',
            }}
            onChange={(changedfilter) => {
              setFilters({
                ...filters,
                locationIds: changedfilter.locationIds,
                startDate: changedfilter.startDate,
                endDate: changedfilter.endDate,
              });
            }}
          />
        )}
        colConfig={[
          {
            Header: t('Contact Name'),
            accessor: (callItem) => callItem,
            cellRenderer: (callItem: HydratedCallRecord) => {
              return (
                <ProfileCard
                  trackingId={`phone-portal-weave2.0-calls-profilebtn-tablerowaction-viewprofile`}
                  firstName={callItem.person?.firstName ?? ''}
                  lastName={callItem.person?.lastName ?? ''}
                  profileName={getFullName(callItem.person?.firstName, callItem.person?.lastName)}
                  defaultName={t('Unknown')}
                  locationId={callItem?.locationId}
                  personID={callItem.person?.personId}
                  showIcon={callItem.direction === 'outbound'}
                />
              );
            },
            disableSortBy: true,
            id: 'contactName',
            mobileLayoutConfig: {
              order: -1,
              spanFullWidth: false,
              hideHeader: true,
              leftShift: 48,
            },
            minWidth: 220,
          },
          {
            Header: t('Time'),
            accessor: (row) => {
              return dayjs(row.startedAt).format('MMM DD YYYY, hh:mm A');
            },
            disableSortBy: true,
            id: 'dateTime',
            minWidth: 180,
          },
          {
            Header: t('Result'),
            accessor: ({ direction, status }) => ({ direction, status }),
            cellRenderer: ({ direction, status }) => {
              if (direction === 'outbound') return <span>{'--'}</span>;
              const statusChip = getStatusChipColor(status);
              return (
                <Chip isResponsive variant={statusChip}>
                  {startCase(status)}
                </Chip>
              );
            },
            disableSortBy: true,
            id: 'result',
            width: 150,
          },
          {
            Header: t('Contact Number'),
            accessor: ({ callerNumber, direction, dialedNumber }) =>
              formatPhoneNumber(direction === 'inbound' ? callerNumber : dialedNumber),
            disableSortBy: true,
            id: 'contactPhone',
            width: 150,
          },
          {
            Header: t('Office Number'),
            accessor: ({ callerNumber, direction, dialedNumber }) =>
              formatPhoneNumber(direction === 'outbound' ? callerNumber : dialedNumber),
            disableSortBy: true,
            id: 'officePhone',
            mobileLayoutConfig: {
              order: 1,
            },
            width: 150,
          },
          {
            Header: t('Office User'),
            accessor: (callInfo) => getOfficeUser(callInfo),
            disableSortBy: true,
            id: 'officeUser',
            width: 180,
          },
          {
            Header: t('Call Recording'),
            accessor: ({ channelId, locationId, recordingPath, recordingFilename, isRecordingBeingProcessed }) => {
              const hasCallRecording = !!recordingPath && !!recordingFilename;
              const isCallRecordingBeingProcessed = !hasCallRecording && isRecordingBeingProcessed;
              return {
                channelId,
                locationId,
                hasCallRecording: hasCallRecording,
                isRecordingBeingProcessed: isCallRecordingBeingProcessed,
              };
            },
            cellRenderer: ({ channelId, locationId, hasCallRecording, isRecordingBeingProcessed }) => (
              <>
                {channelId && locationId && hasAccesstoCallRecordingMap[locationId] && hasCallRecording ? (
                  <AudioScrubber
                    singlePlayer
                    trackingId='nwx-phones-recentCalls-row-callRecording-audioScrubber'
                    src={`${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${channelId}?location_id=${locationId}&token=${weaveToken}`}
                    css={{ maxWidth: '100%' }}
                  />
                ) : isRecordingBeingProcessed ? (
                  <>
                    <span css={styles.recordingBeingProcessed}>{t('Processing...')}</span>
                    <SpinningLoader
                      css={css`
                        display: block;
                      `}
                      size='small'
                    />
                  </>
                ) : (
                  <span css={styles.noRecordingText}>{'--'}</span>
                )}
              </>
            ),
            disableSortBy: true,
            id: 'callRecording',
            omit: !hasAccesstoCallRecording,
            mobileLayoutConfig: {
              spanFullWidth: true,
              order: 1,
            },
            minWidth: 200,
          },
          {
            Header: t('Location'),
            accessor: ({ locationId }) => getLocationChip(locationId),
            id: 'location',
            omit: !(selectedLocationIds.length > 1),
            mobileLayoutConfig: {
              order: 1,
            },
          },
          {
            Header: t('Tags'),
            accessor: (item) => item,
            id: 'tags',
            disableSortBy: true,
            cellRenderer: (record, _rowData, row, _1, _2, tableInstance) => {
              return (
                <TagsCell
                  {...record}
                  row={row}
                  tableInstance={tableInstance}
                  hasAccesstoCallRecording={hasAccesstoCallRecording}
                  searchQuery={searchQuery}
                />
              );
            },
          },
          {
            Header: t('Department'),
            accessor: ({ departmentId, locationId }) => {
              const dept = departmentsByLocation[locationId!]?.departments?.find((item) => item.id === departmentId);
              return !!departmentId ? dept?.name : 'Main Line';
            },
            disableSortBy: true,
            id: 'department',
            mobileLayoutConfig: {
              order: 1,
            },
            omit: !departmentsEnabled,
          },
          {
            Header: '',
            accessor: (callInfo: any) => callInfo,
            cellRenderer: (_, rowData, row, _1, _2, tableInstance) => {
              if (!rowData) {
                return null;
              }

              const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
                context: {
                  personId: rowData.person?.personId ?? '',
                  phoneNumber: rowData.direction === 'inbound' ? rowData.callerNumber : rowData.dialedNumber,
                  outboundNumber: rowData.direction === 'outbound' ? rowData.callerNumber : rowData.dialedNumber,
                },
              });

              const { onClick: messageOnClick } = ActionsUI.actions.useMessageAction();

              const actions: Action[] = [
                {
                  Icon: () => getActionIcon({ iconName: 'phone' }),
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-call',
                  label: `${t('Call')} ${getShortHandName(rowData)}`,
                  onClick: () => {
                    callTriggerProps?.onClick();
                    registerClick(rowData, row?.index);
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'message' }),
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-message',
                  label: `${t('Message')} ${getShortHandName(rowData)}`,
                  onClick: () => {
                    messageOnClick({
                      personId: rowData.person?.personId ?? '',
                      threadGroupId: rowData.locationId ?? '',
                      personPhone: rowData.direction === 'inbound' ? rowData.callerNumber : rowData.dialedNumber,
                      locationPhone: rowData.direction === 'outbound' ? rowData.callerNumber : rowData.dialedNumber,
                    });
                    registerClick(rowData, row?.index);
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'user' }),
                  hide: !rowData.person?.personId,
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-viewprofile',
                  label: t('View Profile'),
                  onClick: () => {
                    if (!!rowData.person?.personId) {
                      setPersonId(rowData.person?.personId);
                      setShow(true, 'contact');
                      registerClick(rowData, row?.index);
                    }
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'user-management' }),
                  hide: !!rowData.person?.personId,
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-createcontact',
                  label: t('Create Contact'),
                  onClick: () => {
                    const number = rowData.direction === 'inbound' ? rowData.callerNumber : rowData.dialedNumber;

                    const digitsOnly = number ? sanitizePhoneNumber(number) : '';
                    const hasCountryCode = digitsOnly.startsWith('1');

                    openPanel({
                      show: true,
                      context: {
                        mode: 'create',
                        person: {
                          MobilePhone: hasCountryCode ? digitsOnly.slice(1) : digitsOnly,
                        },
                        locationId: rowData.locationId,
                        onSave: () => {
                          alert.success(t('Contact created successfully.'));

                          // This line doesn't quite accomplish anything because the logs do not update with the newly created contact
                          queryClient.invalidateQueries({ queryKey: queryKeys.recentCallLogs(searchQuery) });
                          queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('contacts') });
                        },
                        onError: () => {
                          alert.error(t('There was an error creating your contact. Please try again.'));
                        },
                      },
                    });

                    registerClick(rowData, row?.index);
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'label' }),
                  label: t('Add Tag'),
                  trackingId: 'phone-portal-weave2.0-recentcalls-btn-tablerowaction-tagrecord',
                  onClick: () => {
                    if (row) {
                      row.setState({ isEditing: true });
                      registerClick(rowData, row?.index);
                    }
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'download-small' }),
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-download',
                  label: t('Download Media'),
                  onClick: () => {
                    downloadMedia(rowData);
                    registerClick(rowData, row?.index);
                  },
                  hide:
                    !rowData.channelId ||
                    !rowData.locationId ||
                    !hasAccesstoCallRecordingMap[rowData.locationId] ||
                    !rowData.recordingPath,
                },
                {
                  Icon: (props) => getActionIcon({ iconName: 'trash-small', color: props.color }),
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-delete',
                  label: t('Delete Media'),
                  destructive: true,
                  onClick: () => {
                    deletionCandidate.current = rowData;
                    deleteModalControls.triggerProps.onClick();
                    registerClick(rowData, row?.index);
                  },
                  hide:
                    !rowData.channelId ||
                    !rowData.locationId ||
                    !hasAccesstoCallRecordingMap[rowData.locationId] ||
                    !rowData.recordingPath,
                },
              ];
              return (
                <>
                  <div style={{ width: '100%', display: 'flex', gap: theme.spacing(1), justifyContent: 'center' }}>
                    {row && tableInstance && (
                      <ViewCallDetailsColumn
                        row={row}
                        tableInstance={tableInstance}
                        hoverLabel={t('View call details')}
                        hasAccessToRecordings={hasAccesstoCallRecording}
                        queryKey={queryKeys.recentCallLogs(searchQuery).filter(Boolean)}
                        registerClick={() => registerClick(rowData, row.index)}
                      />
                    )}
                    <CustomActionCell actions={actions} tableTrackingId={'phone-portal-weave2.0-calls'} />
                  </div>
                  {PhoneCallModal}
                </>
              );
            },
            disableSortBy: true,
            id: 'actions',
            sticky: 'right',
            disableColumnFilter: true,
            width: 100,
            mobileLayoutConfig: {
              order: -1,
              spanFullWidth: false,
              hideHeader: true,
            },
          },
        ]}
        hasResponsiveColWidths
        isLoading={isFetching}
        fullHeight
        isPaginated
        hasGlobalSearch
        globalTrackingId='phone-portal-weave2.0-calls'
        globalSearchConfig={{
          initialValue: filters.phoneNumber || filters.name || '',
          position: 'right',
          searchHandler: (searchText) => {
            const sanitizedNumber = sanitizePhoneNumber(searchText);
            const numberSearch =
              isPhoneNumber(sanitizedNumber) || isPartialPhoneNumber(sanitizedNumber) ? sanitizedNumber : '';
            const nameSearch =
              !isPhoneNumber(sanitizedNumber) && !isPartialPhoneNumber(sanitizedNumber) ? searchText : '';

            if (!!isPartialPhoneNumber(searchText)) {
              setEmptyState({
                type: 'sync_your_phone',
                header: t('Phone Number Searches Must be Exactly 10 Digits'),
                description: t('Phone number searches must be exactly 10 digits in order to return a match.'),
              });
            } else {
              setEmptyState(defaultEmptyState);
            }

            setFilters({
              ...filters,
              phoneNumber: numberSearch,
              name: nameSearch,
            });
            onSearchHistoryChange(searchText);
          },
          placeholder: t('Search name or 10-digit number'),
          debounceDelay: 1000,
        }}
        manualFilters
        manualFiltersOptions={{ initialBadgeState: filters.status.length > 0 }}
        manualFiltersRender={(modalProps, setShowNotificationBadge) => (
          <div css={styles.filtersWrapper}>
            <RecentCallsTrayFilter
              defaultFilters={defaultRecentCallsFilters}
              filters={filters}
              modalProps={modalProps}
              onChange={setFilters}
              setShowNotificationBadge={setShowNotificationBadge}
              isMulti={selectedLocationIds.length > 1}
            />
          </div>
        )}
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig((prev) => ({ pageNumber: prev.pageNumber + 1 }));
            } else {
              setPageConfig((prev) => ({ pageNumber: prev.pageNumber - 1 }));
            }
          },
          hasNext: getHasNext(),
          hasPrevious: hasPreviousPage,
          page: pageNumber,
          defaultRowsPerPage: pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
          },
          rowsPerPageOptions: [10, 25, 50, 75],
        }}
        tableActions={[
          {
            Icon: DownloadIcon,
            disabled: !!isFetching || !recentCallRecords?.pages[pageNumber - 1]?.data.length,
            label: t('Download Data'),
            trackingId: 'nwx-phones-recentCalls-export-btn',
            onClick: handleExport,
            type: 'icon',
          },
        ]}
      />
      <ConfirmationModal
        {...deleteModalControls.modalProps}
        title={t('Delete Confirmation')}
        message={t('Are you sure you want to delete this call recording?')}
        destructive
        onConfirm={() => {
          if (deletionCandidate.current) {
            deleteMedia(deletionCandidate.current);
          }
        }}
      />
      <SearchHistory id={UiSource.RECENT_CALLS_SEARCH} />
    </div>
  );
};

const styles = {
  wrapper: css`
    flex: 1;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: ${theme.spacing(1)};
    position: relative;
  `,

  filtersWrapper: css`
    order: -1;
    margin-right: ${theme.spacing(2)};

    @media screen and (max-width: 1500px) {
      flex: 1;
    }
  `,

  audio: css`
    height: ${theme.spacing(4)};
  `,

  noRecordingText: css`
    color: ${theme.colors.neutral90};
    display: inline-block;
    text-align: left;
    width: 100%;
  `,
  recordingBeingProcessed: css`
    animation: fade 1.5s infinite ease-in-out;

    @keyframes fade {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  `,
};

export const TagList = ({
  tags,
  channelId,
  callStartedAt,
  callRecordLocationId,
  queryKey,
}: {
  tags: Tag[];
  channelId: string;
  callStartedAt: string;
  callRecordLocationId: string;
  queryKey: string[];
}) => {
  const { t } = useTranslation('calls');
  const alerts = useAlert();

  const { removeTag } = useRecentCallsTagMutation({
    queryKey,
    callStartedAt,
    channelId,
    callRecordLocationId,
    onSuccess: ({ op }) => {
      if (op === 'remove') alerts.success(t('Successfully removed tag.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Failed to remove tag.'));
    },
  });
  const representativeTag = tags[0];

  return (
    <>
      <Chip.Tag
        isRemovable
        onRemoveClick={() => {
          removeTag(representativeTag);
        }}
        css={{ width: 'auto' }}
        color={TagsUtils.convertStringToTagColor(representativeTag.color)}
      >
        {representativeTag.name}
      </Chip.Tag>
    </>
  );
};

export const TagColumn = ({
  tags,
  channelId,
  callStartedAt,
  callRecordLocationId,
  queryKey,
  showPanel,
  isEditing,
  setIsEditing,
}: {
  tags: Tag[];
  channelId: string;
  callStartedAt: string;
  callRecordLocationId: string;
  queryKey: string[];
  showPanel: () => void;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const alerts = useAlert();
  const { t } = useTranslation('calls');

  const { applyTag } = useRecentCallsTagMutation({
    queryKey: queryKey,
    channelId: channelId,
    callStartedAt: callStartedAt,
    callRecordLocationId: callRecordLocationId,
    onSuccess: ({ op }) => {
      if (op === 'add') alerts.success(t('Tag added successfully.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Tag removed successfully.'));
    },
  });

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(0px, 1fr) auto auto',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      {tags.length > 0 && (
        <TagList
          tags={tags}
          channelId={channelId}
          callStartedAt={callStartedAt}
          callRecordLocationId={callRecordLocationId}
          queryKey={queryKey}
        />
      )}
      {!isEditing && tags.length > 1 && (
        <TextLink
          css={{
            ':hover': {
              textDecoration: 'none',
            },
          }}
          onClick={() => {
            showPanel();
          }}
        >
          +{tags.length - 1}
        </TextLink>
      )}
      {!isEditing && tags.length === 0 && <AddTag existingTagIds={tags.map((tag) => tag.id)} onApplyTag={applyTag} />}
      {isEditing && (
        <RecentCallsTagSelector
          onClose={() => {
            setIsEditing(false);
          }}
          onSelect={() => {
            setIsEditing(false);
          }}
          onApplyTag={applyTag}
          existingTagIds={tags.map((tag) => tag.id)}
        />
      )}
    </div>
  );
};

export const useShowCallDetailsPanel = ({
  row,
  tableInstance,
  hasAccessToRecordings = false,
  queryKey,
  sectionFocus = 'details',
}: {
  row?: Row<RecentCallRecord>;
  tableInstance?: TableInstance<RecentCallRecord>;
  hasAccessToRecordings: boolean;
  queryKey: string[];
  sectionFocus?: CallDetailsPanelAccordionType;
}) => {
  const { setShow } = useSlidePanelShallowStore<CallDetailsContext>('setShow', 'panelType', 'show', 'context');
  if (!row || !tableInstance) {
    return () => {};
  }
  return () =>
    setShow(true, 'callDetails', {
      rows: tableInstance.rows.map((row) => ({ ...row.original })),
      currentRowId: row.id,
      instanceId: tableInstance.tableId,
      hasAccessToRecordings,
      toggleRowSelected: tableInstance.toggleRowSelected,
      callDetailsQueryKey: queryKey,
      sectionFocus: sectionFocus,
    });
};

type TagsCellProps = RecentCallRecord & {
  row: Row<RecentCallRecord>;
  tableInstance: TableInstance<RecentCallRecord>;
  hasAccesstoCallRecording: boolean;
  searchQuery: string;
};

const TagsCell = ({
  tags,
  channelId,
  locationId,
  startedAt,
  row,
  tableInstance,
  hasAccesstoCallRecording,
  searchQuery,
}: TagsCellProps) => {
  const showPanel = useShowCallDetailsPanel({
    row,
    tableInstance,
    hasAccessToRecordings: hasAccesstoCallRecording,
    sectionFocus: 'tags',
    queryKey: queryKeys.recentCallLogs(searchQuery).filter(Boolean),
  });
  let isEditing = false;
  if (row?.state && 'isEditing' in row.state && typeof row.state.isEditing === 'boolean') {
    isEditing = row.state.isEditing;
  }

  if (!channelId || !startedAt || !locationId || !row) return null;

  return (
    <TagColumn
      tags={tags}
      channelId={channelId}
      callStartedAt={startedAt}
      callRecordLocationId={locationId}
      showPanel={showPanel}
      queryKey={queryKeys.recentCallLogs(searchQuery).filter(Boolean)}
      isEditing={isEditing}
      setIsEditing={(val) => row.setState({ isEditing: val })}
    />
  );
};
