import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, DatePickerField, useFormField } from '@frontend/design-system';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../stores/use-calendar-view-v3-header-filter-store';
import { addDaysToDate, getTodaysDate, isSelectedDateIsToday } from '../../utils/date-helpers';

type Props = {
  width?: number;
};

export const CalendarViewHeaderDateSelector = ({ width = 240 }: Props) => {
  const { t } = useTranslation('schedule');

  const { selectedCalendarDate, setSelectedCalendarDate } = useCalendarViewV3HeaderFilterShallowStore(
    'selectedCalendarDate',
    'setSelectedCalendarDate'
  );

  const dateField = useFormField(
    {
      type: 'datePicker',
      required: true,
      minDate: dayjs().subtract(50, 'year').startOf('year').format('YYYY-MM-DD'),
      maxDate: dayjs().add(2, 'year').startOf('year').format('YYYY-MM-DD'),
      value: selectedCalendarDate,
    },
    [selectedCalendarDate]
  );

  useEffect(() => {
    if (!dateField.error && dateField.value !== selectedCalendarDate) {
      setSelectedCalendarDate(dateField.value);
    }
  }, [dateField.value]);

  const handleTodayDayClick = () => setSelectedCalendarDate(getTodaysDate());

  const updateSelectedDateCurried = (noOfDays: number) => () =>
    setSelectedCalendarDate(addDaysToDate(selectedCalendarDate, noOfDays));

  return (
    <div css={{ width }}>
      <div css={{ marginBottom: theme.spacing(1), display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant='secondary'
          onClick={handleTodayDayClick}
          disabled={!dateField.error && isSelectedDateIsToday(selectedCalendarDate)}
        >
          {t('Today')}
        </Button>
        <div css={{ display: 'flex', gap: theme.spacing(1) }}>
          <Button
            variant='secondary'
            iconName='caret-left'
            hoverLabel={t('Previous Day')}
            onClick={updateSelectedDateCurried(-1)}
            disabled={!!dateField.error}
            css={{ border: `1px solid ${theme.colors.neutral30}` }}
          />

          <Button
            variant='secondary'
            iconName='caret-right'
            hoverLabel={t('Next Day')}
            onClick={updateSelectedDateCurried(1)}
            disabled={!!dateField.error}
            css={{ border: `1px solid ${theme.colors.neutral30}` }}
          />
        </div>
      </div>

      <DatePickerField
        {...dateField}
        name='date'
        label=''
        css={{
          height: 60,
          fontWeight: theme.font.weight.bold,
          input: { fontSize: theme.fontSize(25) },
          svg: { height: 40, width: 40 },
        }}
      />
    </div>
  );
};
