import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useGetActivePetQuery, useGetActivePetsQuery } from '@frontend/vet-components';
import { PopoverMenu, usePopoverMenu } from '@frontend/design-system';
import { PetListAction } from './pet-list-action';
import { useSendPetReport } from './use-send-pet-report';

type GenerateServiceReportType = {
  label: string;
  context: { personId: string; locationId: string; mobilePhone: string; petOwnerName: string };
  trackingId: string;
};

export const GenerateServiceReport = ({ label, context, trackingId }: GenerateServiceReportType) => {
  const { openModal, SendInThreadModal } = useSendPetReport(context);
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({
    placement: 'left-start',
    middlewareOptions: { offset: 0 },
  });
  const { data } = useGetActivePetsQuery({
    personId: context.personId,
    locationId: context.locationId,
  });
  const petsData = data || [];

  return (
    <>
      {SendInThreadModal}
      {petsData?.length === 1 ? (
        <SinglePetAction
          trackingId={trackingId}
          label={label}
          locationId={context.locationId || ''}
          openModal={openModal}
          petId={petsData?.[0].petId || ''}
        />
      ) : (
        <>
          <AdaptoActions.Action
            trackingId={trackingId}
            disableCloseOnSelect
            {...getTriggerProps()}
            label={label}
            icon='list-small'
          />
          <PopoverMenu {...getMenuProps()}>
            {petsData?.map((pet, index) => (
              <PetListAction
                key={pet.petId}
                pet={pet}
                index={index}
                getItemProps={getItemProps}
                handleClick={(e) => {
                  AdaptoActions.close();
                  openModal(e);
                }}
                trackingId='contact-action-waterfall-send-vaccine-history'
              />
            ))}
          </PopoverMenu>
        </>
      )}
    </>
  );
};

const SinglePetAction = ({
  trackingId,
  locationId,
  label,
  openModal,
  petId,
}: {
  trackingId: string;
  locationId: string;
  label: string;
  openModal: ReturnType<typeof useSendPetReport>['openModal'];
  petId: PetDetails['petId'];
}) => {
  const petDetailsQuery = useGetActivePetQuery({ petId, locationId });
  return (
    <AdaptoActions.Action
      trackingId={trackingId}
      label={label}
      icon='list-small'
      onClick={() => {
        if (!petDetailsQuery.data) return;
        openModal(petDetailsQuery.data);
      }}
    />
  );
};
