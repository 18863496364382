import { UserDetails } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { PersonTypes, PersonHelpers } from '@frontend/api-person';
import { PersonType } from '../types';

interface GetPatientFullNameCommonPayload {
  type: PersonType;
}

interface GetPatientFullNamePayload extends GetPatientFullNameCommonPayload {
  user: PersonTypes.Person;
  type: 'patient';
}

interface GetAssigneeFullNamePayload extends GetPatientFullNameCommonPayload {
  user: UserDetails;
  type: 'assignee';
}

type GetFullNamePayload = GetPatientFullNamePayload | GetAssigneeFullNamePayload;

export function getFullName({ user, type }: GetFullNamePayload): string {
  if (!user) return '';

  if (type === 'patient') {
    return PersonHelpers.getFullName(user);
  }

  return `${user.firstName} ${user.lastName}`.trim();
}
