import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Template, TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { useTranslation } from '@frontend/i18n';
import { ManualTemplateTypes, TemplateSelector } from '@frontend/integrated-messaging';
import { Modal, ModalControlResponse } from '@frontend/design-system';

type Props = ModalControlResponse & {
  groupId: string;
  setDraft: Dispatch<SetStateAction<string>>;
  setSelectedTemplate: (template: Template) => void;
  templateTypeSlugs: ManualTemplateTypes[];
};

export const MessageTemplatesModal = ({
  groupId,
  setDraft,
  setSelectedTemplate,
  templateTypeSlugs,
  ...modalControlProps
}: Props) => {
  const { t } = useTranslation('inbox');
  const [templateString, setTemplateString] = useState<string>('');

  const modalTitle = t('Select a Message Template');

  const handleModalClose = () => {
    modalControlProps.closeModal();
    setTemplateString('');
  };

  useEffect(() => {
    if (templateString) {
      setDraft(templateString);
      handleModalClose();
    }
  }, [templateString]);

  return (
    <Modal {...modalControlProps.modalProps} maxWidth={600} onClose={handleModalClose}>
      <Modal.Header title={modalTitle} onClose={handleModalClose}>
        {modalTitle}
      </Modal.Header>
      <Modal.Body>
        <TemplateSelector
          groupIds={[groupId]}
          templateTypes={[TemplateType_Slug.MANUAL_MESSAGE, ...templateTypeSlugs]}
          onSelectTemplate={(template) => {
            setTemplateString(template.templateString ?? '');
            setSelectedTemplate(template);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
