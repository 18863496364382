import { Channel } from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { AnimatePresence, motion } from 'motion/react';
import { CommPreferenceQueries } from '@frontend/api-messaging';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { ThreadBodyComponents } from '@frontend/thread-body';
import { theme } from '@frontend/theme';
import { useDebouncedValue } from '@frontend/design-system';
import { CHAT_HEIGHTS, HEADER_HEIGHT } from '../constants';

type MiniChatBodyProps = {
  groupId: string;
  threadId: string;
  targetSmsData?: {
    id: string;
    createdAt: string;
  };
  personPhone: string;
  unreadCount: number;
  chatId: string;
};

export const MiniChatBody = ({ groupId, threadId, targetSmsData, personPhone, unreadCount }: MiniChatBodyProps) => {
  const { data: smsPreference } = CommPreferenceQueries.useCheckSMSPreference({
    userChannelAddress: formatPhoneNumberE164(personPhone),
    channel: Channel.CHANNEL_SMS,
    locationId: groupId,
    messageType: MessageType_Enum.MESSAGING_MANUAL,
  });
  const isOptedOut = smsPreference?.consented === false;
  // Debouncing this value allows virtuoso to finish handling the initial scroll before the banner is shown.
  // This avoids situations where the banner is shown, but the thread is scrolled up from the bottom a little.
  const debouncedOptOut = useDebouncedValue(isOptedOut, 500);

  return (
    <motion.div
      initial={{
        opacity: 1,
      }}
      animate={{
        opacity: 1,
      }}
      css={{
        position: 'relative',
        maxHeight: CHAT_HEIGHTS['full'] - HEADER_HEIGHT,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      exit={{
        opacity: 0,
      }}
    >
      <ThreadBodyComponents.ThreadList
        threadId={threadId}
        groupId={groupId}
        targetSmsData={targetSmsData}
        personPhone={personPhone}
        unreadCount={unreadCount}
      />
      <AnimatePresence>
        {debouncedOptOut && (
          <>
            <motion.div
              key='opt-out-overlay'
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              css={{
                position: 'absolute',
                height: '100%',
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: `${theme.colors.white}7F`,
                zIndex: theme.zIndex.middle,
              }}
            />
            <motion.div
              key='opt-out-banner-wrapper'
              initial={{
                height: 0,
              }}
              animate={{
                height: 'auto',
              }}
              exit={{
                height: 0,
              }}
              css={{
                padding: theme.spacing(1),
                borderTop: `1px solid ${theme.colors.neutral20}`,
                width: '100%',
              }}
            >
              <ThreadBodyComponents.OptOutBanner
                textProps={{
                  size: 'medium',
                }}
                css={{
                  gap: theme.spacing(1),
                }}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
