import { FormsMedicalConditions } from '@frontend/api-forms';
import { SettingsRoutes } from '@frontend/settings-routing';
import { pendoTags } from '../../../shared/constants';

interface Meta {
  id: 'medicalConditions' | 'allergies' | 'medications';
  description: string;
  label: string;
  link: SettingsRoutes;
  trackingId: string;
  syncButtonTrackingId: string;
  saveButtonTrackingId: string;
  cancelButtonTrackingId: string;
}

export function getMedicalConditionsUIMeta(name: FormsMedicalConditions.Types.MedicalConditionName): Meta {
  switch (name) {
    case 'ALLERGY':
      return {
        id: 'allergies',
        description:
          'Sync allergies from your integrated system and enable those you wish to provide as answering options in your forms. Patients will then be able to select from these options when completing their medical history forms.',
        label: 'Allergies',
        link: '/digital-forms/medical-history/allergies',
        trackingId: pendoTags.settings.page.medicalHistory.allergies.allergiesPanel,
        syncButtonTrackingId: pendoTags.settings.page.medicalHistory.allergies.syncButton,
        saveButtonTrackingId: pendoTags.settings.page.medicalHistory.allergies.saveButton,
        cancelButtonTrackingId: pendoTags.settings.page.medicalHistory.allergies.cancelButton,
      };

    case 'DISEASE':
      return {
        id: 'medicalConditions',
        description:
          'Sync Medical Conditions from your integrated system and enable those you wish to provide as answering options in your forms. Patients will then be able to select from these options when completing their medical history forms.',
        label: 'Medical Conditions',
        link: '/digital-forms/medical-history/medical-conditions',
        trackingId: pendoTags.settings.page.medicalHistory.medicalConditions.medicalConditionsPanel,
        syncButtonTrackingId: pendoTags.settings.page.medicalHistory.medicalConditions.syncButton,
        saveButtonTrackingId: pendoTags.settings.page.medicalHistory.medicalConditions.saveButton,
        cancelButtonTrackingId: pendoTags.settings.page.medicalHistory.medicalConditions.cancelButton,
      };

    case 'MEDICATION':
      return {
        id: 'medications',
        description:
          'Sync Medications from your integrated system and enable those you wish to provide as answering options in your forms. Patients will then be able to select from these options when completing their medical history forms.',
        label: 'Medications',
        link: '/digital-forms/medical-history/medications',
        trackingId: pendoTags.settings.page.medicalHistory.medications.medicationsPanel,
        syncButtonTrackingId: pendoTags.settings.page.medicalHistory.medications.syncButton,
        saveButtonTrackingId: pendoTags.settings.page.medicalHistory.medications.saveButton,
        cancelButtonTrackingId: pendoTags.settings.page.medicalHistory.medications.cancelButton,
      };
  }
}
