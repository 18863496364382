import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { BarChartAppearance, BarChartData, BarChartPattern, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { featureFlags } from '../../../../feature-flags';
import { trackingIds } from '../../../../tracking-ids';
import { DemoChip } from '../../../demo-chip';
import { InfoTipPopover } from '../../../info-tip-popover';
import { MessagingRoiFetchParams } from '../../types';
import {
  useFetchMissedCallTextAttribution,
  useFetchMissedCallTextAttributionTimeline,
  useMCTRoiShallowStore,
} from '../hooks';
import { MCTRoiConversionType } from '../types';

type ActiveView = 'chartView' | 'timelineView';

const chartViewColors = {
  appointmentsConverted: theme.colors.secondary.eggplant40,
};

const timelineViewColors: Record<MCTRoiConversionType, string> = {
  '28 Days': theme.colors.tangerine40,
  '21 Days': theme.colors.indigo50,
  '14 Days': theme.colors.secondary.seaweed50,
  '7 Days': theme.colors.rose50,
  'Same Day': '#D39F03', // Color not defined in the theme
};

const chartViewLabels = {
  appointmentsConverted: i18next.t('Appointments Scheduled', { ns: 'analytics' }),
};

const timelineViewLabels: Record<MCTRoiConversionType, string> = {
  '28 Days': i18next.t('28 Days', { ns: 'analytics' }),
  '21 Days': i18next.t('21 Days', { ns: 'analytics' }),
  '14 Days': i18next.t('14 Days', { ns: 'analytics' }),
  '7 Days': i18next.t('7 Days', { ns: 'analytics' }),
  'Same Day': i18next.t('Same Day', { ns: 'analytics' }),
};

const conversionWindowsColors: Partial<Record<MCTRoiConversionType, BarChartPattern>> = {
  '28 Days': {
    name: 'gradient',
    colors: {
      start: timelineViewColors['28 Days'],
      end: theme.colors.tangerine20,
    },
    hoverColors: {
      start: theme.colors.tangerine30,
      end: theme.colors.tangerine5,
    },
  },
  '21 Days': {
    name: 'gradient',
    colors: {
      start: timelineViewColors['21 Days'],
      end: theme.colors.indigo20,
    },
    hoverColors: {
      start: theme.colors.indigo30,
      end: theme.colors.indigo5,
    },
  },
  '14 Days': {
    name: 'gradient',
    colors: {
      start: timelineViewColors['14 Days'],
      end: theme.colors.secondary.seaweed20,
    },
    hoverColors: {
      start: theme.colors.secondary.seaweed30,
      end: theme.colors.secondary.seaweed5,
    },
  },
  '7 Days': {
    name: 'gradient',
    colors: {
      start: timelineViewColors['7 Days'],
      end: theme.colors.rose20,
    },
    hoverColors: {
      start: theme.colors.rose30,
      end: theme.colors.rose5,
    },
  },
  'Same Day': {
    name: 'gradient',
    colors: {
      start: timelineViewColors['Same Day'],
      end: theme.colors.warning10,
    },
    hoverColors: {
      start: theme.colors.warning20,
      end: theme.colors.warning5,
    },
  },
};

const appearance: BarChartAppearance = {
  alwaysApplyBarBorders: true,
  barSize: 100,
  barBorderWidth: 0.5,
  hideTooltipTitle: true,
  labelConfig: {
    customStyles: () => ({
      fontSize: theme.font.size.h1,
      fontWeight: theme.font.weight.bold,
      fill: theme.font.colors.default,
    }),
    distanceFromBar: 16,
    placement: 'end',
    show: true,
  },
  margin: { top: 60 },
  showXAxis: true,
  showYAxis: true,
  patterns: {
    appointmentsConverted: {
      name: 'gradient',
      colors: {
        start: theme.colors.secondary.eggplant50,
        end: theme.colors.secondary.eggplant20,
      },
      hoverColors: {
        start: theme.colors.secondary.eggplant30,
        end: theme.colors.secondary.eggplant10,
      },
    },
    ...conversionWindowsColors,
  },
};

export const MCTDetailedConversionChart = () => {
  const { t } = useTranslation('analytics');
  const { filters } = useMCTRoiShallowStore('filters');
  const [activeView, setActiveView] = useState<ActiveView>('chartView');
  const [activeTimelineView, setActiveTimelineView] = useState<MCTRoiConversionType>('28 Days');
  const isChartViewActive = activeView === 'chartView';
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);

  const commonPayload: MessagingRoiFetchParams = {
    messagingCategory: 'missedcalltext',
    payload: {
      start_date: filters?.startDate,
      end_date: filters?.endDate,
      location_id: filters?.locationIds,
    },
  };

  const {
    categorizedConversions,
    isLoading: isLoadingAttribution,
    sortedConversionTypes,
  } = useFetchMissedCallTextAttribution({
    apiParams: commonPayload,
    isEnabled: isChartViewActive,
  });

  const { isLoading: isLoadingAttributionTimeline, timeline } = useFetchMissedCallTextAttributionTimeline({
    apiParams: {
      ...commonPayload,
      viewType: '7_day',
    },
    isEnabled: !isChartViewActive,
  });

  const chartViewChartData: BarChartData = useMemo(() => {
    return {
      groups:
        sortedConversionTypes?.map((conversionType) => ({
          name: conversionType,
          values: {
            appointmentsConverted: categorizedConversions?.[conversionType] ?? 0,
          },
        })) ?? [],
    };
  }, [categorizedConversions, sortedConversionTypes]);

  const timelineViewChartData: Partial<Record<MCTRoiConversionType, BarChartData | undefined>> = useMemo(() => {
    return Object.entries(timeline ?? {}).reduce<Partial<Record<MCTRoiConversionType, BarChartData | undefined>>>(
      (acc, [conversionType, conversionData]) => {
        // Typecasting needed because reduce converting conversionType to string which is actually a MCTRoiConversionType
        acc[conversionType as MCTRoiConversionType] = {
          groups:
            conversionData?.map(({ name, count = 0 }) => ({
              name,
              values: {
                [conversionType]: count,
              },
            })) || [],
        };
        return acc;
      },
      {}
    );
  }, [timeline]);

  const swicthViews = () => {
    if (isChartViewActive) {
      setActiveTimelineView('28 Days');
      setActiveView('timelineView');
    } else {
      setActiveView('chartView');
    }
  };

  return (
    <Chart
      colors={isChartViewActive ? chartViewColors : timelineViewColors}
      isLoading={isLoadingAttribution || isLoadingAttributionTimeline}
      labels={isChartViewActive ? chartViewLabels : timelineViewLabels}
      style={{ minHeight: '410px' }}
      commonTooltipLabel={isChartViewActive ? undefined : t('Appointments Converted')}
    >
      <Chart.Header
        actions={[
          {
            buttonVariant: 'tertiary',
            iconName: isChartViewActive ? 'analytics-info' : 'analytics',
            label: isChartViewActive ? t('Timeline View') : t('Chart View'),
            onClick: swicthViews,
            showLabel: true,
            trackingId: trackingIds.roi.missedCallTextTimelineViewMessageSend,
          },
        ]}
        bottomElement={
          <Text color='subdued' size='medium'>
            {isChartViewActive ? t('Showing Chart View') : t('Showing Timeline View')}
          </Text>
        }
        infoTip={
          <InfoTipPopover>{t('Breakdown of appointments converted based on the conversion window')}</InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Detailed Appointment Conversion')}
      />
      {isChartViewActive ? (
        <>
          <Chart.BarChart
            appearance={appearance}
            data={chartViewChartData}
            onClick={({ group }) => {
              // Typecasting needed because group returned by onClick is string which is actually a MCTRoiConversionType
              // TODO :: Update BarChart component to accept and return custom type
              setActiveTimelineView(group as MCTRoiConversionType);
              setActiveView('timelineView');
            }}
          />
          <Chart.Legends />
        </>
      ) : (
        <div css={styles.timelineChartWrapper}>
          <Chart.BarChart appearance={appearance} data={timelineViewChartData[activeTimelineView]} />
          <div css={styles.conversionWindow}>
            {Object.entries(timelineViewLabels).map(([key, label]) => {
              // Typecasting needed because map converting key to string which is actually a MCTRoiConversionType
              const typedKey = key as MCTRoiConversionType;

              return (
                <ConversionWindowItem
                  color={timelineViewColors[typedKey]}
                  id={key}
                  isActive={activeTimelineView === typedKey}
                  key={key}
                  label={label}
                  onClick={() => setActiveTimelineView(typedKey)}
                />
              );
            })}
          </div>
        </div>
      )}
    </Chart>
  );
};

type ConversionWindowItemProps = {
  color: string;
  id: string;
  isActive?: boolean;
  label: string;
  onClick: () => void;
};

const ConversionWindowItem = ({ color, id, isActive, label, onClick }: ConversionWindowItemProps) => {
  return (
    <Button
      css={styles.conversionWindowItem(isActive)}
      onClick={onClick}
      trackingId={`roi-conversion-window-item-${id?.replaceAll(' ', '-')}`}
      variant='secondary'
    >
      <Text css={styles.conversionWindowText(color)}>{label}</Text>
    </Button>
  );
};

const styles = {
  timelineChartWrapper: css`
    display: flex;
    flex-direction: column-reverse;
    gap: ${theme.spacing(3)};

    > div {
      flex: 1;
      position: relative;
    }

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      flex-direction: row;
    }
  `,

  conversionWindow: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
    width: 100%;

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      max-width: 260px;
    }
  `,

  conversionWindowItem: (isActive?: boolean) => css`
    font-weight: ${theme.font.weight.regular};
    height: ${theme.spacing(6)};
    justify-content: flex-start;

    background-color: ${isActive ? theme.colors.neutral5 : ''};
  `,

  conversionWindowText: (color: string) => css`
    position: relative;

    &::before {
      background-color: ${color};
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: ${theme.spacing(1.2)};
      margin-right: ${theme.spacing(1)};
      width: ${theme.spacing(1.2)};
    }
  `,
};
