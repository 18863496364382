import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { DashboardWidget, DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { URLs } from '../constants';

/**
 * @dashboard-widget
 *
 * id: call-intel-redirect
 * title: Call Intelligence
 * description: Redirects user to Call Intelligence page.
 * icon: call-intel-small
 */
export const CallIntelRedirectWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');

  const navigate = useNavigate();

  const redirectToCallIntel = () => {
    navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
  };
  return (
    <DashboardWidget onClick={redirectToCallIntel}>
      <DashboardWidget.Content css={contentContainerStyles}>
        <Text weight='bold' size='medium'>
          {t('Call Intelligence')}
        </Text>
        <Icon name='arrow-right-small' />
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

CallIntelRedirectWidget.config = {
  size: 'micro-slim',
  feature: 'analytics',
};

const contentContainerStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  paddingTop: '6px',
  margin: 'auto 0',
});
