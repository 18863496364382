import { FC } from 'react';
import { Missing } from '@frontend/empty-states#svg';
import { Text } from '@frontend/design-system';

interface MissingFormContentProps {
  title: string;
  description: string;
}

export const MissingFormContent: FC<MissingFormContentProps> = ({ title, description }) => {
  return (
    <>
      <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Missing css={{ height: 200 }} />
      </div>
      <Text textAlign='center' color='subdued' weight='bold'>
        {title}
      </Text>
      <Text textAlign='center' color='subdued'>
        {description}
      </Text>
    </>
  );
};
