import { PaymentPlanFilterType, PaymentPlanModel, PaymentPlanSearchParams } from '@frontend/api-payment-plans';
import { PageParamsInStore } from '@frontend/api-payments';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

type PaymentPlanState = {
  filter: PaymentPlanFilterType;
  numRows: number;
  selectedPaymentPlan: PaymentPlanModel | null;
  order: PaymentPlanSearchParams['order'];
  pageParams: PageParamsInStore;
  workflowId: string | null;
};

type PaymentPlanActions = {
  setFilter: (filter: PaymentPlanState['filter']) => void;
  clearFilters: () => void;
  setNumRows: (numRows: PaymentPlanState['numRows']) => void;
  setOrder: (order: PaymentPlanState['order']) => void;
  setSelectedPaymentPlan: (paymentPlan: NonNullable<PaymentPlanState['selectedPaymentPlan']>) => void;
  setPageParams: (pageParams: PaymentPlanState['pageParams']) => void;
  setWorkflowId: (workflowId: PaymentPlanState['workflowId']) => void;
};

type PaymentPlanStore = PaymentPlanState & PaymentPlanActions;

const pageParams = { first: 10, currentPage: 1 };

export const usePaymentPlanStore = createStoreWithSubscribe<PaymentPlanStore>(
  (set) => {
    return {
      selectedPaymentPlan: null,
      filter: {},
      numRows: 10,
      order: '-startedAt',
      pageParams,
      workflowId: null,
      setFilter: (filter) => set((prevState) => ({ ...prevState, filter, pageParams })),
      clearFilters: () => set((prevState) => ({ ...prevState, filter: {} })),
      setSelectedPaymentPlan: (paymentPlan) => set((prevState) => ({ ...prevState, selectedPaymentPlan: paymentPlan })),
      setOrder: (order) => set((prevState) => ({ ...prevState, order, pageParams })),
      setNumRows: (numRows) =>
        set((prevState) => ({ ...prevState, numRows, pageParams: { ...pageParams, first: numRows } })),
      setPageParams: (pageParams) => set((prevState) => ({ ...prevState, pageParams })),
      setWorkflowId: (workflowId) => set((prevState) => ({ ...prevState, workflowId })),
    };
  },
  { name: 'PaymentPlanStore' }
);

export const usePaymentPlanShallowStore = createShallowStore(usePaymentPlanStore);
