import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Alert } from '@frontend/design-system';
import { useGetUpdateOfficeHoursMethod } from '../../hooks';
import { OfficeHoursSettings } from './OfficeHoursSettings';

type OfficeHoursSettingsProps = {
  locationId: string;
};

export const OfficeHoursGlobalSettings = ({ locationId }: OfficeHoursSettingsProps) => {
  const { t } = useTranslation('schedule');

  const { isSaving, onSave, isFetching, officeHoursData, hasOfficeHoursFetchError, refetchOfficeHours } =
    useGetUpdateOfficeHoursMethod(locationId);

  return (
    <div css={officeHoursContainerStyles}>
      {hasOfficeHoursFetchError && (
        <Alert type='error'>{t('Unable to fetch Office Hours. Please try again later.')}</Alert>
      )}
      <OfficeHoursSettings
        locationId={locationId}
        isLoading={isFetching || isSaving}
        officeHoursData={officeHoursData}
        refetchOfficeHours={refetchOfficeHours}
        onSave={onSave}
      />
    </div>
  );
};

const officeHoursContainerStyles = css({
  boxShadow: theme.shadows.light,
  backgroundColor: theme.colors.white,
});
