import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CHAT_AREA_WIDTH } from '../../constants';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { ConversationPanel } from '../conversation/conversation-panel';
import { MentionsPanel } from '../mentions/mentions-panel';
import { ThreadPanel } from '../threads/thread-panel';

export const MainPanel = () => {
  const { t } = useTranslation('team-chat');
  const mainPanelView = useTeamChatSelector((ctx) => ctx.mainPanelView);
  const isNavExpanded = useTeamChatSelector((ctx) => ctx.isNavExpanded);

  const width = mainPanelView !== 'none' ? CHAT_AREA_WIDTH : 0;

  return (
    <AnimatePresence>
      <motion.section
        css={[styles.container, isNavExpanded && styles.containerBorder]}
        initial={{ width }}
        animate={{ width }}
        exit={{ width: 0 }}
        transition={{ duration: 0.3, type: 'keyframes' }}
        layout
      >
        {mainPanelView === 'none' && <div css={{ width: CHAT_AREA_WIDTH }}>{t('Please select a conversation')}</div>}
        {(mainPanelView === 'conversation-static' || mainPanelView === 'conversation-dynamic') && <ConversationPanel />}
        {mainPanelView === 'mentions' && <MentionsPanel />}
        {mainPanelView === 'thread' && <ThreadPanel />}
      </motion.section>
    </AnimatePresence>
  );
};

MainPanel.displayName = 'MainPanel';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: CHAT_AREA_WIDTH,
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
  }),
  containerBorder: {
    borderLeft: `1px solid ${theme.colors.neutral10}`,
  },
};
