import { InsuranceResponse } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/api-service/v1/insurance_verification_api.pb';
import { ThirdPartyErrorMessage } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/third-party/onederful.pb';
import {
  DentalCoverageRequest,
  InsuranceStatus,
  VerificationMetricsResponse,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import {
  ErrorDetails,
  ErrorType,
  UnknownErrorMessage,
  ValidationErrorMessage,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v2/insurance_verification_adapter.pb';
import Fuse from 'fuse.js';
import { formatDate } from '@frontend/date';
import { i18next } from '@frontend/i18n';
import { ERROR_MESSAGES, RelationshipEnum } from './constants';
import { GetPayerDetailsType, InsuranceDetails, VerificationReportData } from './types';

export const getHoursSavedByInsuranceVerification = (totalNumberOfVerification: number): number => {
  const timeSavedPerVerification = 9; // in minutes
  return Number(((totalNumberOfVerification * timeSavedPerVerification) / 60).toFixed(1));
};

export const prepareVerificationReportData = (response?: VerificationMetricsResponse): VerificationReportData => {
  if (!response)
    return {
      totalCount: 0,
      automatedCount: 0,
      manualCount: 0,
      hoursSaved: 0,
    };

  const totalCount = Number(response.overallCount) || 0;
  const automatedCount = Number(response.automatedCount) || 0;
  const manualCount = Number(response.manualCount) || 0;
  const hoursSaved = getHoursSavedByInsuranceVerification(totalCount);

  return { totalCount, automatedCount, manualCount, hoursSaved };
};

export const validateNationalProviderId = (value: string): string =>
  value.length !== 10 ? i18next.t('NPI must be 10 characters long', { ns: 'insuranceDetails' }) : '';

export const validateNationalProviderTaxId = (value: string): string => {
  const allowOnlyNumericRegex = /^[0-9]+$/;
  const isNumeric = allowOnlyNumericRegex.test(value);
  const hasMatchingLength = value.length === 9;
  if (!isNumeric) {
    return i18next.t('Only numbers accepted for Tax ID', { ns: 'insuranceDetails' });
  } else if (!hasMatchingLength) {
    return i18next.t('Tax ID must be 9 characters long', { ns: 'insuranceDetails' });
  }
  return '';
};

export const getInsurancePayerDetails = (
  payerID: string,
  payerName: string,
  payerList: Record<'label' | 'value', string>[] = []
): GetPayerDetailsType => {
  if (!payerList.length) return { payerID: '', payerName: '' };

  const filteredInsurancePayerList = payerList.filter((payer) => payer.value === payerID);

  // If only one payer is found, return the payerID since thats the exact match by payer ID
  if (filteredInsurancePayerList.length === 1) {
    return { payerID, payerName: filteredInsurancePayerList[0].label || '' };
  }

  // if multiple payers are found with the same payer ID, return the fuzzy matched payer ID
  if (!!payerName) {
    const fuse = new Fuse(payerList, { keys: ['label'], threshold: 0.2 });
    const searchResult = fuse.search(payerName);

    if (searchResult.length) {
      const fuzzySearchPayerDetails = searchResult.filter(
        (payer) =>
          payer?.item?.value === payerID ||
          payer?.item?.label?.toLowerCase() === payerName?.toLowerCase() ||
          payer?.item?.label?.includes(payerName?.toLowerCase())
      );

      if (fuzzySearchPayerDetails.length) {
        const [payer] = fuzzySearchPayerDetails;
        return { payerID: payer?.item?.value, payerName: payer?.item?.label || '' };
      }
    }
  }

  return { payerID: '', payerName: '' };
};

export const removeNonAlphaNumericCharacters = (value = ''): string => value.replace(/\W/g, '').trim();
export const sanitizeText = (value = ''): string =>
  (value && value.length === 1 ? value.replace('-', '') : value) ?? '';

export const prepareInsuranceDetails = (
  details: InsuranceResponse,
  payerList: Record<'label' | 'value', string>[] = []
): InsuranceDetails => {
  let payerId = '';
  if (details?.payerCompanyId) {
    const payer = payerList.find((payer) => payer.value === details.payerCompanyId);
    payerId = payer?.label ? details.payerCompanyId : '';
  }

  if (!payerId && details?.onederfulPayerId) {
    const payer = payerList.find((payer) => payer.value === details.onederfulPayerId);
    payerId = payer?.label ? details.onederfulPayerId : '';
  }
  const subscriberInfo = (details.coverage?.thirdPartyCoverage?.details as DentalCoverageRequest)?.subscriber;
  return {
    payerId,
    onderfulPayerId: details.onederfulPayerId ?? '',
    pmsId: details.pmsId ?? '',
    payerName: details?.payerCompanyName ?? '',
    groupName: removeNonAlphaNumericCharacters(details?.groupName) ?? '',
    groupNumber: removeNonAlphaNumericCharacters(details?.groupNumber ?? ''),

    nationalProviderId: removeNonAlphaNumericCharacters(details?.nationalProviderId) ?? '',
    providerTaxId: removeNonAlphaNumericCharacters(details?.providerTaxId) ?? '',

    firstName: sanitizeText(details?.firstName) ?? '',
    lastName: sanitizeText(details?.lastName) ?? '',
    dateOfBirth: formatDate(details.birthDate, 'MM/DD/YYYY', true),
    memberId: removeNonAlphaNumericCharacters(details?.memberId ?? ''),

    relationship: (details?.relationship as RelationshipEnum) ?? RelationshipEnum.Self,
    subscriberId: '',
    subscriberFirstName: subscriberInfo?.firstName ?? '',
    subscriberLastName: subscriberInfo?.lastName ?? '',
    subscriberDateOfBirth: subscriberInfo?.dateOfBirth ?? '',
    subscriberMemberId: subscriberInfo?.memberId ?? '',

    effectiveDate: formatDate(details.coverage?.thirdPartyCoverage?.coverage?.effectiveDate, 'MM/DD/YYYY'),
    // SP-TODO: remove this casting once BE update schema type
    thirdPartyCoverageStatus:
      (details.coverage?.thirdPartyCoverage?.coverage?.status as InsuranceStatus) ?? InsuranceStatus.UNKNOWN,
  };
};

export const getErrorMessageFromDentalCoverageErrorInfo = (
  errorDetails?: ErrorDetails
): { errorMessage: string; errorList: string[] } => {
  let errorMessage = '';
  const errorList: string[] = [];

  if (errorDetails) {
    if (errorDetails.type === ErrorType.ERROR_TYPE_VALIDATION) {
      // SP-TODO: remove this casting once BE update schema type
      const codes = ((errorDetails as any).validationErrorDetails as ValidationErrorMessage)?.codes ?? [];
      codes.forEach((code) => {
        const message = titleCase(code);
        if (message) {
          errorList.push(message);
        }
      });
    }
    if (errorDetails.type === ErrorType.ERROR_TYPE_PARTNER) {
      // SP-TODO: remove this casting once BE update schema type
      const messageInfo = (errorDetails as any).thirdPartyErrorDetails as ThirdPartyErrorMessage;
      messageInfo?.errors?.forEach((error) => {
        const messageKey = ((error.message || error.reason) ?? '').toLowerCase() as keyof typeof ERROR_MESSAGES;
        const message = ERROR_MESSAGES[messageKey] ?? titleCase(messageKey);
        if (message) {
          errorList.push(message);
        }
        const payerSpecificMessage = titleCase(error.payerSpecificMessage ?? '');
        if (payerSpecificMessage) {
          errorList.push(payerSpecificMessage);
        }
      });
      errorMessage = messageInfo?.message ?? '';
    }
    if (errorDetails.type === ErrorType.ERROR_TYPE_UNKNOWN) {
      // SP-TODO: remove this casting once BE update schema type
      errorMessage = ((errorDetails as any).unknownErrorDetails as UnknownErrorMessage)?.message ?? '';
    }
  }
  return { errorMessage, errorList };
};

/**
 * @desc: Function is defined to prettify the text in title case
 *        can handle camelCase, snake_case, lisp-case,
 *        removes repeated whitespace and leading/trailing spaces
 */
export const titleCase = (text: string): string => {
  return text
    ? text
        .replace(/([^A-Z])([A-Z])/g, '$1 $2') // for camelCase
        .replace(/[_\\-]+/g, ' ') // for snake_case and lisp-case
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase()) // title case words
        .replace(/\s+/g, ' ') // collapses repeated whitespace
        .replace(/^\s+|\s+$/, '') // removes leading/trailing whitespace
    : '';
};

const uniquePayerConcatString = '~';
export const getPayerCombinedId = (payerId: string, payerName: string): string => {
  return `${payerId}${uniquePayerConcatString}${payerName}`;
};

export const getPayerIdAndPayerNameFromCombinedId = (combinedId: string) => {
  const [payerId, payerName] = combinedId.split(uniquePayerConcatString);
  return {
    payerId: payerId ?? '',
    payerName: payerName ?? '',
  };
};

export const getRelationShipValue = (relationship: RelationshipEnum | string): string => {
  switch (relationship.toLowerCase()) {
    case 'self':
    case 'se':
    case RelationshipEnum.Self:
      return i18next.t('Self', { ns: 'insuranceDetails' });
    case 'spouse':
    case 'sp':
    case RelationshipEnum.Spouse:
      return i18next.t('Spouse', { ns: 'insuranceDetails' });
    case 'ch':
    case 'child':
    case RelationshipEnum.Child:
      return i18next.t('Child', { ns: 'insuranceDetails' });
    case 'de':
    case 'dependent':
    case RelationshipEnum.Dependent:
      return i18next.t('Dependent', { ns: 'insuranceDetails' });
    default:
      return i18next.t('Self', { ns: 'insuranceDetails' });
  }
};
