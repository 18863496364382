import React, { useEffect, useId } from 'react';
import { useGlobalBannerStore } from '../store';
import type { BannerType, BannerAction } from '../types';

export interface GlobalBannerDynamicProps {
  id?: string;
  type: BannerType;
  priority?: number;
  isDismissible?: boolean;
  onDismiss?: () => void;
  title: string;
  message: React.ReactNode;
  action?: BannerAction;
  trackingId?: string;
}

export const GlobalBannerDynamic: React.FC<GlobalBannerDynamicProps> = ({
  id: providedId,
  type,
  priority = 1,
  isDismissible = true,
  onDismiss,
  title,
  message,
  action,
  trackingId,
}) => {
  const generatedId = useId();
  const id = providedId || generatedId;
  const { registerBanner, unregisterBanner } = useGlobalBannerStore(['registerBanner', 'unregisterBanner']);

  useEffect(() => {
    registerBanner({
      id,
      type,
      priority,
      title,
      message,
      onDismiss,
      action,
      isDismissible,
      trackingId,
    });

    return () => unregisterBanner(id);
  }, [id, type, priority, isDismissible, onDismiss, title, message, action]);

  return null;
};
