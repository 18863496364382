import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { getElectronShellDownloadUrl } from '@frontend/opt-in-to-2-0';
import { osHelpers } from '@frontend/os-helpers';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Modal, Text, TextLink, useModalControl } from '@frontend/design-system';
import { useStepControl } from '../../../hooks';
import { Step } from '../../common';

export const DownloadSoftwareStep = () => {
  const { t } = useTranslation('onboarding');
  const { debug, stepNavigation, intakeForm, updateIntakeForm } = useStepControl();
  const [softwareLatestDownloadAt, setSoftwareLatestDownloadAt] = useState(intakeForm?.softwareLatestDownloadAt);

  const { modalProps, triggerProps } = useModalControl();

  const handlePrevClick = () => {
    stepNavigation.onPrevClick({});
  };
  const handleNextClick = () => {
    stepNavigation.onNextClick({});
  };

  const handleDownloadButtonClicked = () => {
    const now = new Date().toISOString();
    setSoftwareLatestDownloadAt(now);

    if (!debug) {
      window.open(getElectronShellDownloadUrl(), '_blank', 'noopener noreferrer');
      updateIntakeForm({ softwareLatestDownloadAt: now });
    }
  };

  const handleSkipButtonClicked = () => {
    handleNextClick();
  };

  const operatingSystem = osHelpers.getOperatingSystem();

  return (
    <>
      <Step>
        <Step.Header title={t("Let's download the software onto your work computer")} />
        <div css={stepSubTitleStyle}>
          <Text weight='bold'>{t('Get started on {{operatingSystem}}', { operatingSystem })}</Text>
          <Button
            {...triggerProps}
            variant='secondary'
            hoverLabel={t('Download Info')}
            size='small'
            iconName='alert-small'
          />
        </div>
        <Step.Body css={{ maxWidth: 320, rowGap: theme.spacing(2) }}>
          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <Button
              variant='primary'
              size='large'
              onClick={handleDownloadButtonClicked}
              trackingId={`${IntakePrefixes.Software}-download-software-btn`}
            >
              {t('Download Software')}
            </Button>

            <Button
              variant='secondary'
              size='large'
              onClick={handleSkipButtonClicked}
              trackingId={`${IntakePrefixes.Software}-skip-download-software-btn`}
            >
              {t('Skip')}
            </Button>
          </div>

          <Text color='light' size='small' textAlign='center'>
            {t('*The username and password you already created will be your login credentials for the Weave software')}
          </Text>
        </Step.Body>

        <Step.Navigation
          onPrevClick={handlePrevClick}
          onNextClick={handleNextClick}
          disableNext={!softwareLatestDownloadAt}
          nextButtonTrackingId={`${IntakePrefixes.Software}-download-software-next-btn`}
          backButtonTrackingId={`${IntakePrefixes.Software}-download-software-back-btn`}
        />
      </Step>
      <Modal {...modalProps}>
        <Modal.Header>{t('Downloading the Software')}</Modal.Header>
        <Modal.Body>
          <Text textAlign='center' color='light' css={{ marginBottom: theme.spacing(3) }}>
            {t(
              'Click the link below to watch a short tutorial video on how to download the software onto your computer'
            )}
          </Text>
          <TextLink
            to='https://www.weavehelp.com/weavehelp/article/download-the-weave-app'
            target='_blank'
            rel='noopener noreferrer'
            textAlign='center'
            weight='bold'
            css={{ marginBottom: theme.spacing(1), display: 'block' }}
            trackingId={`${IntakePrefixes.Software}-installing-weave-link`}
          >
            {t('Installing Weave on a computer')}
          </TextLink>
        </Modal.Body>
        <Modal.Footer primary={{ label: t('Okay'), onClick: modalProps.onClose }} />
      </Modal>
    </>
  );
};

const stepSubTitleStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
  column-gap: ${theme.spacing(0.5)};
`;
