import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useHasAppointmentAnalyticsFeature } from '../components/appointment/hooks';
import {
  AppointmentAnalyticsOnDashboard,
  AutoRecallOnDashboard,
  MessagingCategoriesOnDashboard,
  MissedCallTextROIOnDashboard,
  PhoneIncomingCategoriesOnDashboard,
  PracticeAnalyticsOnDashboard,
} from '../components/homepage-analytics';
import {
  useIsPhoneAnalyticsDemoAccount,
  usePhoneAnalyticsDemoData,
  usePhoneAnalyticsShallowStore,
} from '../components/phone/hooks';
import { useIsPAEligibleAccount } from '../components/practice/hooks';
import { RoiOverviewCards } from '../components/roi/roi-overview-cards';
import {
  useIsMessagingAnalyticsDemoAccount,
  useMessagingAnalyticsDemoData,
  useMessagingAnalyticsShallowStore,
} from '../components/sms/hooks';
import { featureFlags } from '../feature-flags';
import { useTimePeriodPopoverSelector } from '../hooks';
import { trackingIds } from '../tracking-ids';

export const AnalyticsDashboard = () => {
  const { t } = useTranslation('analytics');
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();

  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const { TimePeriodPopoverSelector, startDate, endDate } = useTimePeriodPopoverSelector({
    defaultPeriod: 'last30Days',
  });
  const hasAppointmentAnalyticsFeature = useHasAppointmentAnalyticsFeature();
  const { isPAEligibleAccount } = useIsPAEligibleAccount();

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isAppointmentRemindersRoiEnabled = getFeatureFlagValue(featureFlags.appointmentRemindersRoi);
  const isBulkTextingRoiEnabled = getFeatureFlagValue(featureFlags.bulkTextRoi);

  const isPhoneAnalyticsDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const phoneAnalyticsDemoData = usePhoneAnalyticsDemoData();
  const { setDemoData: setPhoneAnalyticsDemoData, setIsDemoAccount: setIsPhoneAnalyticsDemoAccount } =
    usePhoneAnalyticsShallowStore('setDemoData', 'setIsDemoAccount');

  const isMessagingAnalyticsDemoAccount = useIsMessagingAnalyticsDemoAccount();
  const messagingAnalyticsDemoData = useMessagingAnalyticsDemoData();
  const { setDemoData: setMessagingAnalyticsDemoData, setIsDemoAccount: setIsMessagingAnalyticsDemoAccount } =
    useMessagingAnalyticsShallowStore('setDemoData', 'setIsDemoAccount');

  const [filters, setFilters] = useState<AnalyticsCommonTypes.AnalyticsSummaryFilters>({
    endDate: dayjs(endDate),
    startDate: dayjs(startDate),
    timeZone: accessibleLocationData[selectedLocationIds[0]]?.timezone,
  });

  const handleUpdateFilters = (filters: Partial<AnalyticsCommonTypes.AnalyticsSummaryFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  };

  useEffect(() => {
    handleUpdateFilters({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
    });
  }, [startDate, endDate]);

  const commonProps = {
    filters,
    onLoading: setIsLoading,
  };

  useEffect(() => {
    setPhoneAnalyticsDemoData(phoneAnalyticsDemoData);
  }, [phoneAnalyticsDemoData]);

  useEffect(() => {
    setIsPhoneAnalyticsDemoAccount(isPhoneAnalyticsDemoAccount);
  }, [isPhoneAnalyticsDemoAccount]);

  useEffect(() => {
    setMessagingAnalyticsDemoData(messagingAnalyticsDemoData);
  }, [messagingAnalyticsDemoData]);

  useEffect(() => {
    setIsMessagingAnalyticsDemoAccount(isMessagingAnalyticsDemoAccount);
  }, [isMessagingAnalyticsDemoAccount]);

  return (
    <Page>
      <Page.Header>
        <Page.Header.Heading>
          <Page.Header.Title title={t('Analytics')} />
          <Page.Header.Action>
            <TimePeriodPopoverSelector
              disabled={isLoading}
              trackingIdBase={trackingIds.analytics.dashboardTimeFilter}
            />
          </Page.Header.Action>
        </Page.Header.Heading>
      </Page.Header>

      <Page.Body>
        {isAppointmentRemindersRoiEnabled || isBulkTextingRoiEnabled ? (
          <RoiOverviewCards
            hideSMSCount={isAppointmentRemindersRoiEnabled && isBulkTextingRoiEnabled}
            isAppointmentRemindersRoiEnabled={isAppointmentRemindersRoiEnabled}
            isBulkTextingRoiEnabled={isBulkTextingRoiEnabled}
            style={{ marginBottom: theme.spacing(3) }}
          />
        ) : (
          <MissedCallTextROIOnDashboard {...commonProps} />
        )}

        <div css={styles.chartsWrapper}>
          <PhoneIncomingCategoriesOnDashboard filters={filters} onLoadingStateChange={setIsLoading} />
          <MessagingCategoriesOnDashboard filters={filters} onLoadingStateChange={setIsLoading} />
          <AutoRecallOnDashboard {...commonProps} />
          {isPAEligibleAccount && <PracticeAnalyticsOnDashboard />}
          {hasAppointmentAnalyticsFeature && <AppointmentAnalyticsOnDashboard {...commonProps} />}
        </div>
      </Page.Body>
    </Page>
  );
};

const styles = {
  chartsWrapper: css`
    column-gap: ${theme.spacing(3)};
    display: flex;
    flex-wrap: wrap;

    > section {
      flex-basis: calc(50% - ${theme.spacing(1.5)});
      flex-grow: 1;

      @media screen and (min-width: ${breakpoints.small.min}px) {
        min-width: 420px;
      }
    }
  `,
};
