import { useMemo } from 'react';
import { CallRecordEventType } from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/enums.pb';
import {
  CallRecord,
  CallRecording,
  CallRecordUpdateEvent,
} from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/message.pb';
import { InfiniteData, QueryClient, useQueryClient } from 'react-query';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import { useAppScopeStore } from '@frontend/scope';
import {
  AllCallsTable,
  CallRecordsFiltersType,
  usePhonePageSettingShallowStore,
} from '../../hooks/use-phone-config-state-store';
import { useTags } from '../../hooks/use-tags';
import { queryKeys } from '../../query-keys';
import { formatEndDateWithTimeZone, formatStartDateWithTimeZone } from '../../utils';
import { RecentCallRecords } from '../all-calls/types';
import { getRecentCallsSearchQuery } from './utils';

// updates the recent call records when a new call record is added or a call recording is finished processing
export const useCallRecordEvent = () => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const { config: phonePageConfig } = usePhonePageSettingShallowStore('config');
  const pageSize = phonePageConfig[AllCallsTable.RecentCalls].pageSize;
  const filters = phonePageConfig[AllCallsTable.RecentCalls].filters;
  const { mappedTags } = useTags();

  const searchQuery = useMemo(
    () => getRecentCallsSearchQuery(filters, pageSize, mappedTags),
    [filters, pageSize, mappedTags]
  );

  const qnKeys = [selectedLocationIds, ...queryKeys.recentCallLogs(searchQuery)];

  const handleCallRecordEvent = (event: CallRecordUpdateEvent) => {
    if (event.type == CallRecordEventType.CALL_RECORD_EVENT_TYPE_NEW && event.callRecord) {
      addCallRecordToRecentCallRecords(event.callRecord, filters, queryClient, qnKeys);
    } else if (
      event.type == CallRecordEventType.CALL_RECORD_EVENT_TYPE_RECORDING_FINISHED_PROCESSING &&
      event.callRecording
    ) {
      updateCallRecordingInRecentCallRecords(event.callRecording, queryClient, qnKeys);
    }
  };

  return { handleCallRecordEvent };
};

export async function addCallRecordToRecentCallRecords(
  callRecord: CallRecord,
  appliedFilter: CallRecordsFiltersType | undefined,
  queryClient: QueryClient,
  recentCallRecordsQueryKey: (string | string[] | undefined)[]
) {
  if (!doesCallRecordPassFilterChecks(callRecord, appliedFilter)) {
    // if the call record does not pass the filter checks, ignore the event
    return;
  }
  const callRecordData = await queryClient.fetchQuery({
    queryKey: [callRecord?.locationId, callRecord?.id],
    queryFn: () =>
      PhoneCallsApi.getCallRecord({
        callRecordId: callRecord.id,
        locationId: callRecord.locationId,
        // we are passing startTime, endTime for efficient lookup of call records in the backend
        startTime: callRecord.startedAt,
        endTime: callRecord.startedAt,
      }),
  });
  if (!callRecordData) {
    return;
  }
  const oldData = queryClient.getQueryData(recentCallRecordsQueryKey) as InfiniteData<RecentCallRecords>;
  if (!oldData) {
    console.error(
      'call records realtime update failed. unable to fetch recent calls data from query cache ',
      recentCallRecordsQueryKey
    );
    return;
  }

  if (oldData.pages.length === 0) {
    // if there are no pages in the call records, then add a new page
    oldData.pages.push({
      data: [],
      meta: {
        lastId: undefined,
        endDate: undefined,
      },
    });
  }
  oldData.pages[0].data.unshift({
    ...callRecordData.record,
    isRecordingBeingProcessed: callRecord.isCallRecorded,
    tags: [],
  });

  queryClient.setQueryData(recentCallRecordsQueryKey, oldData);
}

export function updateCallRecordingInRecentCallRecords(
  recordingInfo: CallRecording,
  queryClient: QueryClient,
  recentCallRecordsQueryKey: (string | string[] | undefined)[]
) {
  const callRecords = queryClient.getQueryData(recentCallRecordsQueryKey) as InfiniteData<RecentCallRecords>;
  if (!callRecords) {
    return;
  }
  // try to find the call record in the first page of call records. If not found, ignore the event
  const callRecord = callRecords.pages[0].data.find((record) => record.id == recordingInfo.callRecordId);
  if (!callRecord) {
    return;
  }
  callRecord.isRecordingBeingProcessed = false;
  callRecord.recordingFilename = recordingInfo.recordingFilename;
  callRecord.recordingPath = recordingInfo.recordingPath;

  queryClient.setQueryData(recentCallRecordsQueryKey, callRecords);
}

function doesCallRecordPassFilterChecks(
  callRecord: CallRecord | undefined,
  appliedFilter: CallRecordsFiltersType | undefined
): boolean {
  if (!appliedFilter) {
    // if no filter is applied, then return true
    return true;
  }
  if (!callRecord) {
    return false;
  }
  if (appliedFilter.locationIds.length != 0 && !appliedFilter.locationIds.includes(callRecord.locationId ?? '')) {
    return false;
  }
  if (appliedFilter.callDirection && appliedFilter.callDirection != callRecord.direction) {
    return false;
  }
  if (!callRecord.status || (appliedFilter.status.length != 0 && !appliedFilter.status.includes(callRecord.status))) {
    return false;
  }
  if (
    appliedFilter.startDate &&
    callRecord.startedAt &&
    new Date(callRecord.startedAt) < new Date(formatStartDateWithTimeZone(appliedFilter.startDate))
  ) {
    return false;
  }
  if (
    appliedFilter.endDate &&
    callRecord.startedAt &&
    new Date(callRecord.startedAt) > new Date(formatEndDateWithTimeZone(appliedFilter.endDate))
  ) {
    return false;
  }
  if (appliedFilter.phoneNumber != '' || appliedFilter.name != '') {
    // if either phone number or name filter is applied, skip the call record
    return false;
  }

  return true;
}
