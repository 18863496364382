import { useCallback } from 'react';
import { useEventListener } from './use-event-listener';

/**
 * Creates a custom event system with typed events.
 *
 * @template EventInterface - An interface that maps event names to their corresponding data types.
 *
 * @returns An object containing:
 * - `triggerCustomEvent`: A function to trigger a custom event.
 * - `useCustomEventListener`: A hook to listen for custom events.
 *
 * @example
 * interface MyEvents {
 *   eventName: { someData: string };
 *   anotherEvent: null; // Event without details
 * }
 *
 * const { triggerCustomEvent, useCustomEventListener } = createCustomEventSystem<MyEvents>();
 *
 * // Triggering an event with details
 * triggerCustomEvent('eventName', { someData: 'example' });
 *
 * // Triggering an event without details
 * triggerCustomEvent('anotherEvent');
 *
 * // Using the event listener hook
 * useCustomEventListener('eventName', (data) => {
 *   console.log(data.someData);
 * });
 *
 * useCustomEventListener('anotherEvent', () => {
 *   console.log('anotherEvent triggered');
 * });
 */
export function createCustomEventSystem<EventInterface extends Record<string, any>>() {
  // Trigger custom event function
  const triggerCustomEvent = <EventName extends keyof EventInterface>(
    eventName: EventName,
    data?: EventInterface[EventName]
  ) => {
    const event = new CustomEvent(eventName as string, { detail: data });
    window.dispatchEvent(event);
  };

  // Custom event listener hook
  const useCustomEventListener = <EventName extends keyof EventInterface>(
    eventName: EventName,
    handler: (data: EventInterface[EventName]) => void | Promise<void>,
    isListenerActive = true
  ) => {
    const eventHandler = useCallback((e: CustomEvent<EventInterface[EventName]>) => handler(e.detail), [handler]);

    useEventListener(eventName as any, eventHandler, isListenerActive);
  };

  return { triggerCustomEvent, useCustomEventListener };
}
