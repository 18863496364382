import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import useResizeObserver from '@react-hook/resize-observer';
import { AnimatePresence, motion } from 'motion/react';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useTranslation } from '@frontend/i18n';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { CHAT_GAP, HEADER_HEIGHT, WIDTHS } from '../constants';
import { MiniChatContext, useMiniChatStore, moveChat as getDestinationOrder, chatIsAvatarVariant } from '../stores';
import { SizeVariant } from '../types';
import { MiniChat } from './mini-chat';
import { MiniChatHeader } from './mini-chat-header';
import { OverflowChatsButton } from './overflow-chats';

const INITIAL_RIGHT = 57; // This is the assumed action-bar width, which is rendered to the right of the component

export const MiniChatManager = () => {
  const { t } = useTranslation('mini-chat');
  const alert = useAlert();
  const { selectedLocationIds } = useAppScopeStore();

  const {
    chats,
    rolledUp,
    openChat,
    minimizeChat,
    setAvatarChatCount,
    avatarChatCount,
    removeChat,
    removeChats,
    activeChatId,
    moveChat,
    addChat: changeThread,
    overflowChatCount,
    setOverflowChatCount,
    errorBoundaryActive,
  } = useMiniChatStore();
  const currentOpenChat = chats.find(({ chatId }) => chatId === activeChatId);
  const { navigateToThread } = useInboxNavigate();
  const ref = useRef<HTMLSpanElement>(null);
  const containerWidthRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(0);
  const { chatIdsOrder, threadIdsOrder: __ } = useMemo(
    () =>
      chats.reduce<{ chatIdsOrder: string[]; threadIdsOrder: string[] }>(
        (acc, { chatId, threadId }) => {
          acc.chatIdsOrder.push(chatId);
          acc.threadIdsOrder.push(threadId);
          return acc;
        },
        { chatIdsOrder: [], threadIdsOrder: [] }
      ),
    [JSON.stringify(chats)]
  );

  const { setNodeRef } = useDroppable({
    id: 'mini-chat-drag-and-drop',
  });
  const [chatIdBeingDragged, setChatIdBeingDragged] = useState<string>();
  const [containerSizing, setContainerSizing] = useState<{ width: number; right: number } | undefined>();

  useResizeObserver(containerWidthRef, (entry) => {
    const right = window.innerWidth - entry.target.getBoundingClientRect().right;
    setContainerSizing({
      width: entry.contentRect.width,
      right,
    });
  });

  useEffect(() => {
    const invalidChats = chats.filter((chat) => !selectedLocationIds.includes(chat.groupId));
    if (invalidChats.length) removeChats(invalidChats.map((chat) => chat.chatId));
  }, [JSON.stringify(selectedLocationIds)]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setWidth(entry.contentRect.width * 0.9);
      });
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, []);

  const calculateSizingChatCounts = () => {
    if (width === 0 || isNaN(width)) return;
    let availableWidth = width;

    // Calculate if current sizing fits
    const calculatedChatSizes = chats.map<{ chatId: string; sizeVariant: SizeVariant }>(({ chatId }) => ({
      chatId,
      sizeVariant: activeChatId === chatId ? 'full' : 'minimized',
    }));
    chats.forEach((chat, i) => {
      const sizeVariant = calculatedChatSizes.find(({ chatId }) => chatId === chat.chatId)?.sizeVariant;
      const chatWidth = WIDTHS[sizeVariant ?? 'minimized'];
      availableWidth -= chatWidth + (i === chats.length - 1 ? 0 : CHAT_GAP);
    });
    if (availableWidth >= 0) {
      setAvatarChatCount(0);
      setOverflowChatCount(0);
      return;
    }

    // Calculate which chats need to be resized
    let neededAvatars = 0;
    let newChatsWillFit = false;
    chats.forEach((chat) => {
      if (newChatsWillFit) return;
      if (chat.chatId === activeChatId) return;
      const currentWidth = activeChatId === chat.chatId ? WIDTHS['full'] : WIDTHS['minimized'];
      const newWidth = WIDTHS['avatar'];
      availableWidth += currentWidth - newWidth;
      neededAvatars++;

      if (availableWidth >= 0) newChatsWillFit = true;
    });
    setAvatarChatCount(neededAvatars);
    if (neededAvatars < chats.length - (activeChatId ? 1 : 0)) {
      setOverflowChatCount(0);
      return;
    }

    // Calculate how many chats will overflow
    let overflowAvailableWidth = width;
    const reversedChats = [...chats].reverse();
    reversedChats.forEach((chat, index) => {
      if (overflowAvailableWidth < 0) return;
      const sizeVariant =
        chat.chatId === activeChatId ? 'full' : reversedChats.length - index <= neededAvatars ? 'avatar' : 'minimized';
      const totalChatWidth = WIDTHS[sizeVariant] + CHAT_GAP;
      overflowAvailableWidth -= totalChatWidth;
      if (overflowAvailableWidth < 0) {
        const remainingChatCount = reversedChats.length - index;
        setOverflowChatCount(remainingChatCount + 1);
      } else if (index === reversedChats.length - 1) {
        setOverflowChatCount(0);
      }
    });
  };

  const chatBeingDraggedIndex = chats.findIndex((chat) => chat.chatId === chatIdBeingDragged);
  const chatBeingDragged = chatBeingDraggedIndex === -1 ? undefined : chats[chatBeingDraggedIndex];
  const [destinationOrder, setDestinationOrder] = useState<string[]>();

  const handleDragOver = useCallback<NonNullable<ComponentProps<typeof DndContext>['onDragOver']>>(
    (e) => {
      const { active, over } = e;
      if (!over?.id || !active?.id) return;
      const overId = String(over.id);
      const activeId = String(active?.id);
      const destinationIndex = chatIdsOrder.findIndex((chatId) => chatId === overId);
      if (destinationIndex === -1) return;
      const destinationOrder = getDestinationOrder(chats, activeId, destinationIndex).map(({ chatId }) => chatId);
      setDestinationOrder(destinationOrder);
    },
    [JSON.stringify(chatIdsOrder), setDestinationOrder]
  );

  const getSizeVariant = useCallback(
    (chatId: string, index?: number): SizeVariant => {
      const resolvedIndex = index ?? chatIdsOrder.findIndex((id) => id === chatId);
      if (chatId === activeChatId) return rolledUp ? 'minimized' : 'full';
      if (
        chatIsAvatarVariant(
          resolvedIndex,
          avatarChatCount,
          chatIdsOrder.findIndex((chatId) => chatId === activeChatId)
        )
      )
        return 'avatar';
      return 'minimized';
    },
    [avatarChatCount, activeChatId, rolledUp, JSON.stringify(chatIdsOrder), chatIdBeingDragged]
  );

  const getTransformationSize = useCallback(
    ({
      currentOrder,
      destinationOrder,
      chatId,
    }: {
      currentOrder: string[];
      destinationOrder?: string[];
      chatId: string;
    }) => {
      if (!destinationOrder) return 0;
      const originalIndex = currentOrder.indexOf(chatId);
      const destinationIndex = destinationOrder.indexOf(chatId);

      if (originalIndex === destinationIndex) return 0;

      const originalDistanceFromRight = currentOrder
        .slice(0, originalIndex)
        .reduce<number>((acc, curr, index) => acc + WIDTHS[getSizeVariant(curr, index)] + CHAT_GAP, 0);
      const destinationDistanceFromRight = destinationOrder
        .slice(0, destinationIndex)
        .reduce<number>((acc, curr) => acc + WIDTHS[getSizeVariant(curr, currentOrder.indexOf(curr))] + CHAT_GAP, 0);

      const difference = originalDistanceFromRight - destinationDistanceFromRight;
      return difference;
    },
    [getSizeVariant, chatIdBeingDragged]
  );

  const transformations: number[] = useMemo(
    () =>
      chatIdsOrder
        .slice(overflowChatCount)
        .map((chatId) => getTransformationSize({ chatId, currentOrder: chatIdsOrder, destinationOrder })),
    [JSON.stringify(chatIdsOrder), JSON.stringify(destinationOrder), getTransformationSize, overflowChatCount]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  useResizeObserver(ref, () => {
    calculateSizingChatCounts();
  });

  useEffect(() => {
    calculateSizingChatCounts();
  }, [JSON.stringify(chatIdsOrder), activeChatId]);

  const handleDragEnd = useCallback<NonNullable<ComponentProps<typeof DndContext>['onDragEnd']>>(
    (e) => {
      setChatIdBeingDragged(undefined);
      setDestinationOrder(undefined);
      const { active, over } = e;

      if (active.id !== over?.id) {
        const destinationIndex = chats.findIndex((chat) => chat.chatId === over?.id);
        if (destinationIndex === -1) return;
        const activeIndex = chats.findIndex((chat) => chat.chatId === active.id);
        if (activeIndex === -1) return;
        moveChat(String(active.id), destinationIndex);
      }
    },
    [setChatIdBeingDragged, JSON.stringify(chats), moveChat]
  );

  const departmentsQueries = DepartmentsQueries.useListDefaultSMSQueries(
    selectedLocationIds.map((locationId) => ({
      req: {
        locationId,
      },
      options: {
        select: (data) => ({
          locationId,
          departments:
            data.smsNumbers?.reduce<{ locationPhone: string; departmentId: string }[]>(
              (acc, curr) =>
                curr?.id && curr.smsNumber?.number
                  ? [...acc, { locationPhone: formatPhoneNumberE164(curr.smsNumber.number), departmentId: curr.id }]
                  : acc,
              []
            ) ?? [],
        }),
      },
    }))
  );
  const departmentIdsPerGroupId = departmentsQueries.reduce<Record<string, string[]>>((acc, curr) => {
    if (!curr.data.locationId) return acc;
    acc[curr.data.locationId] = curr.data.departments.map(({ departmentId }) => departmentId);
    return acc;
  }, {});

  const threadStatusQuery = SMSDataV3.Queries.useBatchGetThreadStatusQuery({
    request: {
      requests: SMSDataV3.Utils.calculatePossibleThreadStatusRequests({
        personPhones: currentOpenChat?.possiblePersonPhones ?? [],
        departmentIdsPerGroupId,
        groupIds: selectedLocationIds,
      }),
    },
    options: { enabled: !!currentOpenChat?.possiblePersonPhones?.length },
  });

  const visibleChats = useMemo(() => chats.slice(overflowChatCount), [overflowChatCount, JSON.stringify(chats)]);

  return (
    <DndContext
      onDragStart={(e) => {
        setChatIdBeingDragged(String(e.active.id));
      }}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      collisionDetection={closestCenter}
      sensors={sensors}
    >
      <SortableContext items={chats.map((chat) => chat.chatId)} strategy={horizontalListSortingStrategy}>
        <span ref={containerWidthRef} css={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 0 }} />
        <span
          ref={ref}
          css={{
            position: 'fixed',
            bottom: 0,
            height: 0,
            right: containerSizing?.right ?? INITIAL_RIGHT,
            width: containerSizing?.width ?? 0,
            overflowY: 'visible',
            overflowX: 'clip',
            zIndex: theme.zIndex.higher,
            '::before': {
              position: 'absolute',
              content: '""',
              width: containerSizing?.width ?? 0,
              height: HEADER_HEIGHT,
              bottom: 0,
              pointerEvents: 'none',
              transition: 'opacity 300ms ease-in-out',
              opacity: rolledUp ? 0 : 1,
              background: chats.length ? `linear-gradient(transparent, ${theme.colors.white})` : 'transparent',
            },
          }}
        >
          <motion.span
            ref={setNodeRef}
            animate={{
              width: rolledUp ? WIDTHS.minimized : '100%',
              right: rolledUp ? `-${WIDTHS.minimized}px` : 0,
              gap: rolledUp ? 0 : theme.spacing(2),
              visibility: rolledUp ? 'hidden' : 'visible',
            }}
            transition={{
              visibility: {
                delay: rolledUp ? 1 : 0,
              },
            }}
            css={{
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'end',
              gap: theme.spacing(2),
              padding: theme.spacing(0, 2),
              height: chatIdBeingDragged ? '100vh' : 0,
              overflow: 'visible',
            }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {!errorBoundaryActive && (
              <AnimatePresence>
                {!!overflowChatCount && <OverflowChatsButton />}
                {visibleChats.map(({ chatId, possiblePersonPhones, ...chatContext }, index) => (
                  <MiniChat
                    key={chatId}
                    chatId={chatId}
                    {...chatContext}
                    sizeVariant={getSizeVariant(chatId, index)}
                    onClick={() => {
                      if (activeChatId !== chatId) {
                        openChat(chatId);
                      }
                    }}
                    onMaximize={() => {
                      navigateToThread({
                        threadId: chatContext.threadId,
                        groupId: chatContext.groupId,
                        personId: chatContext.personId,
                        departmentId: chatContext.departmentId,
                        personPhone: chatContext.personPhone,
                        smsId: chatContext.targetSmsData?.id,
                        smsCreatedAt: chatContext.targetSmsData?.createdAt,
                      });
                      removeChat(chatId);
                    }}
                    onMinimize={() => minimizeChat()}
                    onClose={() => removeChat(chatId)}
                    onSelectPersonPhone={(personPhone) => {
                      if (!currentOpenChat) return;

                      const newThread = threadStatusQuery.data?.responses.find(
                        (response) =>
                          response.personPhone === formatPhoneNumberE164(personPhone) &&
                          response.locationId === currentOpenChat.groupId &&
                          (currentOpenChat.departmentId ? response.departmentId === currentOpenChat.departmentId : true)
                      );
                      if (!newThread) {
                        alert.error(t('Error switching to conversation.'));
                        sentry.error({
                          error: 'Error finding selected thread from thread status query response',
                          topic: 'messages',
                          addContext: {
                            name: 'Thread selection data',
                            context: {
                              threadStatusQuery,
                              currentOpenChat,
                              newPersonPhone: personPhone,
                            },
                          },
                        });
                        return;
                      }

                      changeThread({
                        personPhone,
                        groupId: currentOpenChat.groupId,
                        departmentId: currentOpenChat.departmentId,
                        personId: currentOpenChat.personId,
                        locationPhone: currentOpenChat.locationPhone,
                        unreadCount: 0,
                        threadId: newThread.threadId,
                      });
                    }}
                    onSelectDepartment={({ departmentId, locationPhone }) => {
                      if (!currentOpenChat) return;
                      const newThread = threadStatusQuery.data?.responses.find(
                        (response) =>
                          response.personPhone === formatPhoneNumberE164(currentOpenChat.personPhone) &&
                          response.departmentId === departmentId &&
                          response.locationId === currentOpenChat.groupId
                      );

                      if (!newThread) {
                        alert.error(t('Error switching to conversation.'));
                        sentry.error({
                          error: 'Error finding selected thread from thread status query response',
                          topic: 'messages',
                          addContext: {
                            name: 'Thread selection data',
                            context: {
                              threadStatusQuery,
                              currentOpenChat,
                              newDepartmentId: departmentId,
                              locationPhone,
                            },
                          },
                        });
                        return;
                      }
                      changeThread({
                        personPhone: currentOpenChat.personPhone,
                        groupId: currentOpenChat.groupId,
                        departmentId,
                        personId: currentOpenChat.personId,
                        locationPhone,
                        unreadCount: 0,
                        threadId: newThread.threadId,
                      });
                    }}
                    layout='position'
                    style={{
                      transform: `translateX(${transformations[index]}px)`,
                      transition: chatBeingDragged ? 'transform 0.3s ease-in-out' : undefined,
                    }}
                    css={[
                      {
                        transition: 'opacity 300ms ease-in-out',
                        '*': {
                          transition: 'opacity 300ms ease-in-out',
                        },
                      },
                      !!chatIdBeingDragged && { opacity: 0.8, '*': { opacity: 0.8 } },
                    ]}
                    dragIsActive={!!chatIdBeingDragged}
                    locationPhone={chatContext.locationPhone}
                  />
                ))}
              </AnimatePresence>
            )}
          </motion.span>
        </span>
      </SortableContext>
      <DragOverlay dropAnimation={null}>
        {!!chatBeingDragged && (
          <OverlayChat {...chatBeingDragged} sizeVariant={getSizeVariant(chatBeingDragged.chatId)} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

const OverlayChat = (props: Omit<MiniChatContext, 'sizeVariant'> & { sizeVariant: SizeVariant }) => {
  const { possiblePersonPhones, sizeVariant, targetSmsData, ...propsToUse } = props;

  return (
    <MiniChatHeader
      {...propsToUse}
      key={propsToUse.chatId + '-dragging'}
      onMaximize={() => {}}
      onMinimize={() => {}}
      onClose={() => {}}
      onSelectPersonPhone={() => {}}
      sizeVariant={sizeVariant}
      css={{
        borderRadius: theme.borderRadius.medium,
      }}
      initial={sizeVariant}
      floatingShadow
      hideButtons
      hideHoverLabel
    />
  );
};
