import { useEffect } from 'react';
import dayjs from 'dayjs';
import { DropdownOptionContent } from '@frontend/contact-actions-quickfill';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { DatePickerField, Text, DropdownField, Stepper, TimeRangeField, useForm } from '@frontend/design-system';
import { useQuickFillLocationInfo } from '../../../hooks';
import { QUICK_FILL_TRACKING_IDS } from '../../../tracking-ids';
import { useQuickFillRecipientShallowStore } from '../store';
import { ComposeMessage } from './compose-message';
import { ComposeMessageStepValue } from './compose-message-step-value';
import { DateTimeStepValue } from './date-time-step-value';
import { LocationStepValue } from './location-step-value';
import { RecipientsStepValue } from './recipients-step-value';
import { SelectRecipients } from './select-recipients';
import { stepperCardStyle } from './styles';
import { isEndTimeBeforeStartTime } from './utils';

const trackingIds = QUICK_FILL_TRACKING_IDS.sendMessagesTab;

export const SendMessagesTabContent = () => {
  const { t } = useTranslation('schedule-quick-fill');
  const { isMultiLocation, selectedLocationIds, getLocationName } = useQuickFillLocationInfo();

  const { validLocationIds, selectedIds, clearSelection } = useQuickFillRecipientShallowStore(
    'validLocationIds',
    'selectedIds',
    'clearSelection'
  );

  const hasSingleLocation = validLocationIds.length === 1;

  const form = useForm({
    fields: {
      location: {
        type: 'dropdown',
        value: hasSingleLocation ? validLocationIds[0] : '',
        required: true,
      },
      date: {
        type: 'date',
        value: '',
        required: true,
        minDate: dayjs().format('YYYY-MM-DD'),
      },
      timeRange: {
        type: 'timeRange',
        value: ['', ''],
        required: true,
        interval: 60,
        validator: ({ value }) => (isEndTimeBeforeStartTime(value) ? t('End time must be later than start time') : ''),
      },
    },
  });

  const getDateTimeHelperText = () => {
    const { date, timeRange } = form.values;
    const hasTimeRange = timeRange?.[0] && timeRange?.[1];

    if (!date && !hasTimeRange) {
      return t('Select Date & Time');
    }
    if (!date) {
      return t('Enter Date');
    }
    if (!hasTimeRange) {
      return t('Enter Time');
    }
    return '';
  };

  // Seed location value as validLocationIds is not always available on initial render due to async data fetching
  useEffect(() => {
    if (hasSingleLocation) {
      form.seedValues({ location: validLocationIds[0] });
    }
  }, [validLocationIds]);

  // Clear recipient selection on form location change
  useEffect(() => {
    if (form.values.location) {
      clearSelection();
    }
  }, [form.values.location]);

  // Clear recipient selection on unmount
  useEffect(() => clearSelection, []);

  const hasTimeRange = !!(form.values.timeRange?.[0] && form.values.timeRange?.[1]);
  const timeError = form.getErrors().timeRange;

  return (
    <Stepper>
      {isMultiLocation && (
        <Stepper.Card
          css={stepperCardStyle}
          data-trackingid={trackingIds.locationSelectionStep.stepCard}
          stepValue={<LocationStepValue locationName={getLocationName(form.values.location ?? '')} />}
        >
          <Stepper.Title icon={<Icon name='location' />}>{t('Select Location')}</Stepper.Title>
          <Stepper.Content>
            <DropdownField
              {...form.getFieldProps('location')}
              label={t('Select Location')}
              data-trackingid={trackingIds.locationSelectionStep.locationDropdown}
            >
              {selectedLocationIds.map((locationId) => {
                const hasQuickFillAccess = validLocationIds.includes(locationId);
                return (
                  <DropdownField.Option key={locationId} value={locationId} disabled={!hasQuickFillAccess}>
                    <DropdownOptionContent locationId={locationId} hasQuickFillAccess={hasQuickFillAccess} />
                  </DropdownField.Option>
                );
              })}
            </DropdownField>
          </Stepper.Content>
          <Stepper.ButtonBar>
            <Stepper.NextButton
              trackingId={trackingIds.locationSelectionStep.nextButton}
              isValid={!!form.values.location}
            >
              {t('Next')}
            </Stepper.NextButton>
          </Stepper.ButtonBar>
        </Stepper.Card>
      )}
      <Stepper.Card
        css={stepperCardStyle}
        data-trackingid={trackingIds.dateTimeSelectionStep.stepCard}
        stepValue={<DateTimeStepValue date={form.values.date} timeRange={form.values.timeRange} />}
      >
        <Stepper.Title icon={<Icon name='calendar' />}>{t('Select Date & Time')}</Stepper.Title>
        <Stepper.Content>
          <DatePickerField
            {...form.getFieldProps('date')}
            css={{ width: 290 }}
            label={t('Select Date')}
            data-trackingid={trackingIds.dateTimeSelectionStep.dateField}
          />
          <TimeRangeField
            {...form.getFieldProps('timeRange')}
            css={{ marginTop: theme.spacing(2) }}
            label={t('Select Time')}
            data-trackingid={trackingIds.dateTimeSelectionStep.timeRangeField}
          />
          {timeError && (
            <Text color='error' size='small'>
              {timeError}
            </Text>
          )}
        </Stepper.Content>
        <Stepper.ButtonBar>
          <Text size='small' color='subdued'>
            {getDateTimeHelperText()}
          </Text>
          <Stepper.NextButton
            trackingId={trackingIds.dateTimeSelectionStep.nextButton}
            isValid={hasTimeRange && form.isComplete} // hasTimeRange check is required as TimeRange field is not returning isComplete value correctly
          >
            {t('Next')}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      </Stepper.Card>
      <Stepper.Card
        css={stepperCardStyle}
        data-trackingid={trackingIds.selectRecipientStep.stepCard}
        stepValue={<RecipientsStepValue count={selectedIds?.length} />}
      >
        <Stepper.Title icon={<Icon name='assign-user' />}>{t('Select Recipients')}</Stepper.Title>
        <SelectRecipients locationId={form.values.location || ''} />
      </Stepper.Card>
      <Stepper.Card
        css={stepperCardStyle}
        data-trackingid={trackingIds.composeMessageStep.stepCard}
        stepValue={<ComposeMessageStepValue />}
      >
        <Stepper.Title icon={<Icon name='messaging' />}>{t('Compose Message')}</Stepper.Title>
        <Stepper.Content>
          <ComposeMessage
            locationId={form.values.location || ''}
            date={form.values.date || ''}
            timeRange={form.values.timeRange || ['', '']}
          />
        </Stepper.Content>
      </Stepper.Card>
    </Stepper>
  );
};
