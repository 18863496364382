import { useCallback } from 'react';
import { useReactFlow } from '@xyflow/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  BaseFieldProps,
  Button,
  NakedButton,
  PopoverDialog,
  Text,
  TextareaField,
  useFormField,
  usePopoverDialog,
} from '@frontend/design-system';
import { EdgeType, NodeType, NodeTypes } from '../data';

export type PlayGroundEdgeData = {
  id: string;
  source: string;
  target: string;
  label?: string;
  type?: typeof EdgeType.Basic | typeof EdgeType.ToTarget | typeof EdgeType.FromSource | typeof EdgeType.TreeOption;
  originalType?:
    | typeof EdgeType.Basic
    | typeof EdgeType.ToTarget
    | typeof EdgeType.FromSource
    | typeof EdgeType.TreeOption;
  data?: {
    label?: string;
  };
};

// This function is used for the playground node to process the graph data
function processGraph(graph: { nodes: PlayGroundNodeData[]; edges: PlayGroundEdgeData[] }) {
  function processNodes(nodes: PlayGroundNodeData[]) {
    return nodes.map((node) => {
      const newNode = { ...node, data: node.data };
      return newNode;
    });
  }

  function processEdges(edges: PlayGroundEdgeData[]) {
    return edges.map((edge) => {
      return {
        ...edge,
        data: { label: edge.label },
        originalType: edge.type,
        type: edge.type ?? EdgeType.Basic,
      };
    });
  }

  return {
    nodes: processNodes(graph.nodes),
    edges: processEdges(graph.edges),
  };
}

export function PlaygroundNode() {
  const nodeField = useFormField({ type: 'text', placeholder: 'Enter node data' });
  const edgeField = useFormField({ type: 'text', placeholder: 'Enter edge data' });
  const { setNodes, setEdges } = useReactFlow();
  const { getDialogProps, getTriggerProps } = usePopoverDialog<HTMLButtonElement>({ placement: 'bottom' });

  const onSubmitData = useCallback((graph: { nodes: PlayGroundNodeData[]; edges: PlayGroundEdgeData[] }) => {
    const { nodes, edges } = processGraph(graph);
    setNodes((curNodes) => [
      curNodes[0],
      ...nodes.map((node) => ({ ...node, id: node.id, position: node.position || { x: 0, y: 0 } })),
    ]);
    setEdges(edges);
  }, []);

  return (
    <div
      style={{
        padding: theme.spacing(2),
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
        background: theme.colors.white,
      }}
    >
      <div
        style={{
          display: 'grid',
          gap: theme.spacing(2),
          width: 300,
        }}
      >
        <div
          style={{
            display: 'grid',
            justifyContent: 'space-between',
            gridTemplateAreas: '"title action" "subtitle action" "title2 title2" "action2 action2"',
          }}
        >
          <Text as='span' size='medium' weight='bold' css={{ gridArea: 'title' }}>
            Data
          </Text>
          <div style={{ gridArea: 'subtitle', display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
            <Text as='span' size='small'>
              Visualize your data
            </Text>
            <NakedButton
              css={{
                border: `1px solid ${theme.colors.neutral10}`,
                borderRadius: theme.borderRadius.small,
                height: 20,
                width: 20,
              }}
              className='nodrag nopan nowheel'
              {...getTriggerProps()}
            >
              <Icon size={14} name='info-badge' />
            </NakedButton>
          </div>
          <Button
            className='nodrag nopan nowheel'
            onClick={() => {
              onSubmitData({
                nodes: nodeField.value ? eval(nodeField.value) : [],
                edges: edgeField.value ? eval(edgeField.value) : [],
              });
            }}
            css={{ gridArea: 'action' }}
          >
            Visualize
          </Button>
          <Text as='span' size='small' weight='semibold' css={{ gridArea: 'title2' }}>
            Examples
          </Text>
          <div
            style={{
              gridArea: 'action2',
              display: 'grid',
              gridTemplateColumns: `repeat(4, 1fr)`,
              columnGap: theme.spacing(1),
            }}
          >
            <Button
              variant='secondary'
              onClick={() => {
                populateFieldsWith(nodeField, edgeField, exampleData1);
              }}
            >
              1
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                populateFieldsWith(nodeField, edgeField, exampleData2);
              }}
            >
              2
            </Button>
          </div>
        </div>
        <TextareaField label='Nodes' name='nodes' {...nodeField} className='nodrag nopan nowheel' />
        <TextareaField label='Edges' name='edges' {...edgeField} className='nodrag nopan nowheel' />
        <PopoverDialog css={{ borderRadius: theme.borderRadius.medium }} {...getDialogProps()}>
          <Text size='medium' weight='semibold'>
            Data Format
          </Text>
          <div style={{ display: 'grid', gap: theme.spacing(2), gridTemplateColumns: 'repeat(3, 270px)' }}>
            <div>
              <Text as='p' size='small' css={{ marginTop: 0 }}>
                Each graph consists of nodes and edges. Nodes are the blocks in the graph and edges are the connections
                between nodes.
              </Text>
              <Text as='p' size='small'>
                To visualize your data, provide a list of nodes and edges in the format shown on the right.
              </Text>
              <Text as='p' size='small'>
                Each node must have a{' '}
                <code
                  style={{
                    fontFamily: 'monospace',
                    fontSize: 11,
                    color: theme.colors.tangerine70,
                    backgroundColor: theme.colors.tangerine10,
                    padding: '0 2px',
                  }}
                >
                  type
                </code>{' '}
                property that corresponds to one of the following:
              </Text>
              <div>
                <Text as='span' size='small'>
                  Possible Node Types
                </Text>
                <CodeBlock css={{ backgroundColor: theme.colors.tangerine10 }}>{nodeTypeText}</CodeBlock>
              </div>
            </div>
            <div>
              <Text as='span' size='small'>
                Nodes
              </Text>
              <CodeBlock>{nodeText}</CodeBlock>
            </div>
            <div>
              <Text as='span' size='small'>
                Edges
              </Text>
              <CodeBlock>{edgeText}</CodeBlock>
            </div>
          </div>
        </PopoverDialog>
      </div>
    </div>
  );
}

const populateFieldsWith = (
  nodeField: BaseFieldProps,
  edgeField: BaseFieldProps,
  data: { nodes: PlayGroundNodeData[]; edges: PlayGroundEdgeData[] }
) => {
  const { nodes, edges } = processGraph(data);

  nodeField.onChange({ name: 'nodes', value: JSON.stringify(nodes, null, 2) });
  edgeField.onChange({ name: 'edges', value: JSON.stringify(edges, null, 2) });
};

const CodeBlock = ({ children, className }: { children: string; className?: string }) => {
  return (
    <pre
      css={{
        backgroundColor: theme.colors.primary10,
        borderRadius: theme.borderRadius.medium,
        padding: theme.spacing(1),
        width: 270,
        lineHeight: 1,
        margin: 0,
      }}
      className={className}
    >
      <code
        style={{
          fontFamily: 'monospace',
          fontSize: 11,
        }}
      >
        {children}
      </code>
    </pre>
  );
};

const nodeText = `[
  {
    id: 't6',
    data: {
      id: 'pt-1',
      label: 'Main Phone Tree',
    },
    type: 'phoneTree',
    position: { x: 0, y: 500 },
  },
  {
    id: 't7',
    data: {
      id: 'cg-2',
      label: 'My Call Group',
    },
    type: 'callGroup',
    position: { x: 0, y: 600 },
  },
  {
    id: 't8',
    data: {
      id: 'cg-3',
      label: 'Another Call Group',
    },
    type: 'callGroup',
    position: { x: 0, y: 700 },
  },
  {
    id: 't9',
    data: {
      id: 'vm-2',
      label: 'Another Voicemail',
    },
    type: 'voicemailPrompt',
    position: { x: 0, y: 800 },
  }
]`;

const edgeText = `[
  {
    id: 'e18',
    source: 't6',
    target: 't7',
    label: '1',
  },
  {
    id: 'e19',
    source: 't6',
    target: 't8',
    label: '2',
  },
  {
    id: 'e20',
    source: 't6',
    target: 't9',
    label: '3',
  }
]`;

const nodeTypeText = `{
  Start: 'start',
  Boolean: 'boolean',
  ForwardDevice: 'forwardDevice',
  ForwardNumber: 'forwardNumber',
  VoicemailBox: 'voicemailBox',
  VoicemailPrompt: 'voicemailPrompt',
  Terminate: 'terminate',
  CallGroup: 'callGroup',
  CallQueue: 'callQueue',
  PhoneTree: 'phoneTree',
  PlayMessage: 'playMessage',
  OfficeHours: 'officeHours',
}`;

type PlayGroundNodeData = { id: string; type: NodeTypes; position: { x: number; y: number }; data: any };
const nodes: PlayGroundNodeData[] = [
  {
    id: 'set-1-1',
    type: NodeType.Start,
    position: { x: 0, y: 0 },
    data: {
      id: 'start',
      label: 'Start',
    },
  },
  {
    id: 'set-1-2',
    type: NodeType.Boolean,
    position: { x: 0, y: 100 },
    data: {
      id: 'd161a436',
      label: 'Skip Condition',
    },
  },
  {
    id: 'set-1-3',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 200 },
    data: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-4',
    type: NodeType.ForwardNumber,
    position: { x: 0, y: 300 },
    data: {
      id: 'vm-1',
      label: 'Forwarding Number: 1234567890',
    },
  },
  {
    id: 'set-1-5',
    type: NodeType.PlayMessage,
    position: { x: 0, y: 400 },
    data: {
      id: 'play-1',
      label: 'Play: Breaking the Habit.wav',
    },
  },
  {
    id: 'set-1-6',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 500 },
    data: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-7',
    type: NodeType.CallQueue,
    position: { x: 0, y: 600 },
    data: {
      id: 'cq-1',
      label: 'Call Queue: My Queue',
    },
  },
  {
    id: 'set-1-8',
    type: NodeType.PlayMessage,
    position: { x: 0, y: 700 },
    data: {
      id: 'play-2',
      label: 'Play: fearless.m4a',
    },
  },
  {
    id: 'set-1-9',
    type: NodeType.Terminate,
    position: { x: 0, y: 800 },
    data: {
      id: 'terminate-1',
      label: 'Hangup',
    },
  },
  {
    id: 'set-1-10',
    type: NodeType.CallGroup,
    position: { x: 0, y: 900 },
    data: {
      id: 'cg-2',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: 'set-1-11',
    type: NodeType.Terminate,
    position: { x: 0, y: 1000 },
    data: {
      id: 'terminate-2',
      label: 'Hangup',
    },
  },
  {
    id: 'set-1-12',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1100 },
    data: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-13',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1200 },
    data: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-14',
    type: NodeType.Boolean,
    position: { x: 0, y: 1300 },
    data: {
      id: 'd161a436',
      label: 'Forward Condition',
    },
  },
  {
    id: 'set-1-15',
    type: NodeType.ForwardDevice,
    position: { x: 0, y: 1400 },
    data: {
      id: 'forwarding-1',
      label: 'Forward: 940a049c-d6bf-426b-8543-1223be1675f6',
    },
  },
  {
    id: 'set-1-16',
    type: NodeType.OfficeHours,
    position: { x: 0, y: 1500 },
    data: {
      id: 'schedule-1',
      label: 'Schedule Routing',
    },
  },
  {
    id: 'set-1-17',
    type: NodeType.Terminate,
    position: { x: 0, y: 1600 },
    data: {
      id: 'terminate-3',
      label: 'Hangup',
    },
  },
  {
    id: 't6',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 500 },
    data: {
      id: 'pt-1',
      label: 'Main Phone Tree',
    },
  },
  {
    id: 't7',
    type: NodeType.CallGroup,
    position: { x: 0, y: 600 },
    data: {
      id: 'cg-2',
      label: 'My Call Group',
    },
  },
  {
    id: 't8',
    type: NodeType.CallGroup,
    position: { x: 0, y: 700 },
    data: {
      id: 'cg-3',
      label: 'Another Call Group',
    },
  },
  {
    id: 't9',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 800 },
    data: {
      id: 'vm-2',
      label: 'Another Voicemail',
    },
  },
];

const edges: PlayGroundEdgeData[] = [
  { id: 'e1', source: 'set-1-2', target: 'set-1-8', label: 'Enabled' },
  { id: 'e2', source: 'set-1-8', target: 'set-1-13' },
  { id: 'e3', source: 'set-1-14', target: 'set-1-15', label: 'Disabled' },
  { id: 'e4', source: 'set-1-2', target: 'set-1-14', label: 'Disabled' },
  { id: 'e5', source: 'set-1-16', target: 'set-1-5', label: 'd161a436 - Games with Team' },
  { id: 'e6', source: 'set-1-5', target: 'set-1-3' },
  { id: 'e7', source: 'set-1-3', target: 'set-1-9' },
  { id: 'e8', source: 'set-1-14', target: 'set-1-16', label: 'Enabled' },
  { id: 'e9', source: 'set-1-16', target: 'set-1-10', label: 'd161a436 - Company Lunch' },
  { id: 'e10', source: 'set-1-10', target: 'set-1-6' },
  { id: 'e11', source: 'set-1-6', target: 'set-1-17' },
  { id: 'e12', source: 'set-1-16', target: 't6', label: 'd161a436 - Open' },
  { id: 'e13', source: 'set-1-7', target: 'set-1-4' },
  { id: 'e14', source: 'set-1-4', target: 'set-1-11' },
  { id: 'e15', source: 'set-1-16', target: 'set-1-12', label: 'd161a436 - Closed' },
  { id: 'e16', source: 'set-1-1', target: 'set-1-2' },
  { id: 'e18', source: 't6', target: 't7', label: '1', type: EdgeType.TreeOption },
  { id: 'e19', source: 't6', target: 't8', label: '2', type: EdgeType.TreeOption },
  { id: 'e20', source: 't6', target: 't9', label: '3', type: EdgeType.TreeOption },
];

const exampleData1 = {
  nodes: nodes,
  edges: edges,
};

const nodes2: PlayGroundNodeData[] = [
  {
    id: '-1',
    type: NodeType.Start,
    position: { x: 0, y: 0 },
    data: {
      id: 'start',
      label: 'Incoming Call',
    },
  },
  {
    id: '-2',
    type: NodeType.Boolean,
    position: { x: 0, y: 100 },
    data: {
      id: 'dda8d21a',
      label: 'Skip Condition',
    },
  },
  {
    id: '-3',
    type: NodeType.Boolean,
    position: { x: 0, y: 200 },
    data: {
      id: 'dda8d21a',
      label: 'Forward Condition',
    },
  },
  {
    id: '-4',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 300 },
    data: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-5',
    type: NodeType.Terminate,
    position: { x: 0, y: 400 },
    data: {
      id: 'terminate-1',
      label: 'Hangup',
    },
  },
  {
    id: '-6',
    type: NodeType.CallGroup,
    position: { x: 0, y: 500 },
    data: {
      id: 'cg-1',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: '-7',
    type: NodeType.CallGroup,
    position: { x: 0, y: 600 },
    data: {
      id: 'cg-2',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: '-8',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 700 },
    data: {
      id: 'vm-2',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-9',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 800 },
    data: {
      id: 'ivr-1',
      label: 'IvrMenu: Nested IVR',
    },
  },
  {
    id: '-10',
    type: NodeType.CallGroup,
    position: { x: 0, y: 900 },
    data: {
      id: 'cg-3',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: '-11',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1000 },
    data: {
      id: 'vm-3',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-12',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 1100 },
    data: {
      id: 'ivr-2',
      label: 'IvrMenu: NestedNested IVR',
    },
  },
  {
    id: '-13',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1200 },
    data: {
      id: 'vm-4',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-14',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1300 },
    data: {
      id: 'vm-5',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-15',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1400 },
    data: {
      id: 'vm-6',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-16',
    type: NodeType.OfficeHours,
    position: { x: 0, y: 1500 },
    data: {
      id: 'schedule-1',
      label: 'Schedule Routing',
    },
  },
  {
    id: '-17',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 1600 },
    data: {
      id: 'ivr-3',
      label: 'IvrMenu: TopNestedIVR',
    },
  },
  {
    id: '-18',
    type: NodeType.CallGroup,
    position: { x: 0, y: 1700 },
    data: {
      id: 'cg-4',
      label: 'Call Group: Default Ring',
    },
  },
];

const edges2: PlayGroundEdgeData[] = [
  {
    id: 'e21',
    source: '-2',
    target: '-15',
    label: 'Disabled',
  },
  {
    id: 'e22',
    source: '-3',
    target: '-8',
    label: 'Disabled',
  },
  {
    id: 'e23',
    source: '-2',
    target: '-3',
    label: 'Enabled',
  },
  {
    id: 'e24',
    source: '-16',
    target: '-17',
    label: 'dda8d21a - Open',
  },
  {
    id: 'e25',
    source: '-17',
    target: '-9',
    label: '1',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e26',
    source: '-9',
    target: '-10',
    label: '6',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e27',
    source: '-10',
    target: '-4',
  },
  {
    id: 'e28',
    source: '-9',
    target: '-12',
    label: '7',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e29',
    source: '-12',
    target: '-18',
    label: '5',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e30',
    source: '-18',
    target: '-11',
  },
  {
    id: 'e31',
    source: '-12',
    target: '-12',
    label: '4',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e32',
    source: '-12',
    target: '-9',
    label: '1',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e33',
    source: '-12',
    target: '-17',
    label: '3',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e34',
    source: '-9',
    target: '-9',
    label: '3',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e35',
    source: '-9',
    target: '-17',
    label: '1/2',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e36',
    source: '-17',
    target: '-13',
    label: '2',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e37',
    source: '-17',
    target: '-6',
    label: 'Default',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e38',
    source: '-6',
    target: '-5',
  },
  {
    id: 'e39',
    source: '-3',
    target: '-16',
    label: 'Enabled',
  },
  {
    id: 'e40',
    source: '-16',
    target: '-7',
    label: 'dda8d21a - Closed',
  },
  {
    id: 'e41',
    source: '-7',
    target: '-14',
  },
  {
    id: 'e42',
    source: '-1',
    target: '-2',
  },
];

export const exampleData2 = {
  nodes: nodes2,
  edges: edges2,
};
