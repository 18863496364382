import { PageProps } from '@frontend/components';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';

export const SettingsPage = ({
  children,
  breadcrumbs,
  title,
  subtitle,
  action,
  settingsLocationConfig,
  customBackFn,
  customBackUrl,
  showHeader = true,
  ...props
}: PageProps<true>) => {
  return (
    <Page bgColor={theme.colors.neutral5} {...props}>
      {showHeader && (
        <Page.Header>
          <Page.Header.SettingsBreadcrumbs breadcrumbs={breadcrumbs} />

          <Page.Header.Heading>
            <Page.Header.Title onBackClick={customBackFn} customBackUrl={customBackUrl} title={title}>
              <Page.Header.SettingsLocationChip {...settingsLocationConfig} />
            </Page.Header.Title>
            <Page.Header.Subtitle subtitle={subtitle} />
            {action && <Page.Header.Action>{action}</Page.Header.Action>}
          </Page.Header.Heading>
        </Page.Header>
      )}

      <Page.Body>{children}</Page.Body>
    </Page>
  );
};
