import { Interpolation, Trans, useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ListRow, Text, useTooltip } from '@frontend/design-system';
import { LegendProps, LegendType, UsageData } from '../types';

export const LocationCell = ({ data, legend }: { data: UsageData; legend: LegendProps[] }) => {
  const { t } = useTranslation('bulk-messaging');
  const { accessibleLocationData } = useAppScopeStore();
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'bottom', theme: 'light' });

  const sent = (data.sent || 0) + (data.failed || 0);
  const scheduled = data.pending || 0;
  const unused = (data.quota || 0) - sent - scheduled;

  const getLegendColor = (id: LegendType) => {
    const found = legend.find((item) => item.id === id);
    return found?.tooltipColor || found?.color || theme.colors.neutral90;
  };

  const items = [
    {
      id: 'sent' as LegendType,
      number: sent,
      text: t('Sent'),
    },
    {
      id: 'scheduled' as LegendType,
      number: scheduled,
      text: t('Scheduled'),
    },
    {
      id: 'unused' as LegendType,
      number: unused,
      text: t('Remaining'),
    },
  ];

  return (
    <>
      <Text as='span' {...triggerProps}>
        {accessibleLocationData[data.locationId]?.name}
      </Text>
      <Tooltip {...tooltipProps}>
        {items.map((item) => (
          <ListRow
            key={item.text}
            css={{
              textAlign: 'left',
              display: 'flex',
              columnGap: theme.spacing(1),
              alignItems: 'center',
              color: theme.colors.neutral90,
              fontWeight: theme.font.weight.regular,
            }}
          >
            {/* TODO: revisit this translation */}
            <Trans t={t}>
              <Text css={{ color: getLegendColor(item.id) }}>
                <Interpolation>{{ number: item.number }}</Interpolation>
              </Text>{' '}
              {item.text}
            </Trans>
          </ListRow>
        ))}
      </Tooltip>
    </>
  );
};
