import { useMemo } from 'react';
import { useSearch } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../utils';
import { MultiOverview } from '../../components';
import { REVENUE_MULTIPLIER } from '../../constants';
import { useFetchMessagingROIOOverview } from '../../hooks';
import { MessagingOverview, MessagingRoi, RoiBaseFilters } from '../../types';
import { MultiComparisonChart } from './charts';

interface Props extends ReturnType<typeof useFetchMessagingROIOOverview> {
  filters?: RoiBaseFilters;
  overviewData: MessagingOverview;
}

export const MessagingMultiSubView = ({ filters, overviewData, ...rest }: Props) => {
  const { t } = useTranslation('analytics');
  const search = useSearch<{ Search: { view: MessagingRoi } }>();
  const locationsCount = filters?.locationIds?.length || 0;

  const overview = useMemo(() => {
    let title = '';
    let value = '0';
    let message = <Trans t={t}></Trans>;

    switch (search.view) {
      case 'leadsContacted':
        title = t('Leads Contacted');
        value = formatters.value.format(overviewData.leadsContacted.totalPatients);
        message = (
          <Trans t={t} values={{ messages: overviewData.leadsContacted.messagesSent, locationsCount }}>
            Patients were contacted through{' '}
            <strong style={{ fontSize: theme.fontSize(20) }}>
              {{ messages: overviewData.leadsContacted.messagesSent }}
            </strong>{' '}
            messages sent across {{ locationsCount }} Locations
          </Trans>
        );
        break;

      case 'patientsScheduled':
        title = t('Patients Scheduled');
        value = formatters.value.format(overviewData.patientsScheduled.totalPatients);
        message = (
          <Trans
            t={t}
            values={{ appointments: overviewData.patientsScheduled.appointmentsScheduledCount, locationsCount }}
          >
            Patients scheduled{' '}
            <strong style={{ fontSize: theme.fontSize(20) }}>
              {{ appointments: overviewData.patientsScheduled.appointmentsScheduledCount }}
            </strong>{' '}
            appointments across {{ locationsCount }} Locations
          </Trans>
        );
        break;

      case 'revenueGenerated':
        title = t('Revenue Generated');
        value = formatters.currency.format(overviewData.revenueGenerated.totalCompletedAppts * REVENUE_MULTIPLIER);
        message = (
          <Trans
            t={t}
            values={{ appointmentsCompleted: overviewData.revenueGenerated.totalCompletedAppts, locationsCount }}
          >
            <strong style={{ fontSize: theme.fontSize(20) }}>
              {{ appointmentsCompleted: overviewData.revenueGenerated.totalCompletedAppts }}
            </strong>{' '}
            appointments were completed across {{ locationsCount }} Locations
          </Trans>
        );
    }

    return {
      message,
      title,
      value,
    };
  }, [overviewData, locationsCount, search.view]);

  return (
    <>
      <MultiOverview message={overview.message} value={overview.value} />
      <MultiComparisonChart {...rest} title={overview.title} />
    </>
  );
};
