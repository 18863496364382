import { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { IGif } from '@giphy/js-types';
import { theme } from '@frontend/theme';

export const GifPreview = ({ gif, onSelect }: { gif: IGif; index: number; onSelect: (gif: IGif) => void }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);
  const preview = gif.images.preview_gif;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]; // Only one element observed

        if (entry.isIntersecting && !imageLoaded && imageRef.current) {
          // Check imageLoaded to avoid setting src multiple times
          setImageLoaded(true);
          observer.unobserve(imageRef.current); // Stop observing after loaded
        }
      },
      {
        threshold: 0, // Trigger when even 0% of the image is visible
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [imageLoaded]);

  return (
    <img
      ref={imageRef}
      src={imageLoaded ? preview.url : ''}
      onClick={() => onSelect(gif)}
      css={[styles.gif, css({ display: 'block', ...(!imageLoaded && { height: preview.height }) })]}
      alt={gif.title}
    />
  );
};

const styles = {
  gif: {
    borderRadius: theme.borderRadius.medium,
  },
};
