import { BNPLApi } from '@frontend/api-buy-now-pay-later';
import { useTranslation } from '@frontend/i18n';
import { paymentsQueryKeys, useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';

const errMsg = 'Error creating the BNPL link';

/**
 * @deprecated collect-payment/* will be removed when collect-payment-multi/ is fully live.
 */
export const useBnplLink = ({
  personPhone,
  isBnplRequest = false,
}: {
  personPhone: string;
  isBnplRequest: boolean;
}) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const { invoice } = useSelectedInvoice();
  const { paymentsUrl } = useMerchant();
  const locationId = invoice?.locationId;
  const amount = invoice?.billedAmount;

  const {
    data: bnplTtpLink,
    error,
    isLoading: bnplLinkLoading,
    refetch: refetchBnplTtpLink,
  } = useScopedQuery({
    queryKey: [locationId, paymentsQueryKeys.bnplTtpLink, amount, personPhone],
    queryFn: () => BNPLApi.getBnplTtpLink(paymentsUrl!, locationId!, amount!, personPhone),
    enabled: !!isBnplRequest && !!paymentsUrl && !!invoice?.id && !!personPhone,
    onError: () => {
      alerts.error(t("Couldn't get the Pay Over Time link"));
    },
  });
  return {
    bnplTtpLink,
    bnplLinkError: error ? errMsg : undefined,
    bnplLinkLoading,
    refetchBnplTtpLink,
  };
};
