import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import {
  InvoiceFilterType,
  InvoiceModel,
  InvoiceSearchParams,
  InvoiceStatus,
  InvoiceWithPayoutDescriptor,
  limitDateRange,
} from '@frontend/api-invoices';
import { PaymentQueries } from '@frontend/api-payments';
import { PrintDialog } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import {
  CollectPaymentModal,
  CollectPaymentMultiModalInstance,
  isPaidInvoice,
} from '@frontend/payments-collection-flow';
import { PaymentsFeatureFlags, useMultiQueryUtils } from '@frontend/payments-hooks';
import {
  useInvoiceShallowStore,
  useExportInvoicesQuery,
  InvoiceCancelModal,
  useQueryAllInvoices,
  useQueryPaginatedInvoices,
} from '@frontend/payments-invoice-controller';
import { debounce } from '@frontend/timer';
import { theme } from '@frontend/theme';
import {
  DeleteIcon,
  MarkIcon,
  PrintIcon,
  RefundIcon,
  UnmarkIcon,
  useModalControl,
  Table,
  ExportIcon,
  ContentLoader,
  useAlert,
  PayIcon,
  UpdateIcon,
} from '@frontend/design-system';
import {
  usePrintReceipt,
  useCanDoAction,
  useToggleInvoiceRecorded,
  useNavAlert,
  useColumnsWithPayoutDescriptor,
} from '../../../hooks';
import { PaymentsPageVariant } from '../../../providers';
import { PaymentsNavAlerts, PaymentsTableInstances, calcAvailableToRefund, getLatestRefund } from '../../../utils';
import { PrintHeader } from '../../PrintHeader';
import { RefundModal } from '../../Refunds';
import { FilterExportWarning, InvoiceExportModal, useInvoiceExportModalControl } from '../InvoiceExport';
import { InvoiceFilter } from '../InvoiceFilter';
import { generateColumns } from './generate-columns';

type InvoiceListPageProps = {
  variant?: PaymentsPageVariant;
  showPrint: boolean;
  handlePrint: () => void;
  onChangeFilter?: (filter: InvoiceFilterType) => void;
};

const FIELD_TO_ORDER: { [key: string]: InvoiceSearchParams['order'] } = {
  billedAmount: 'billedAmount',
  billedAt: 'billedAt',
  paidAmount: 'paidAmount',
  paidAt: 'paidAt',
};

export const InvoiceList = ({ variant = 'portal', showPrint, handlePrint, onChangeFilter }: InvoiceListPageProps) => {
  const { t } = useTranslation('payments');
  const navigate = useNavigate();
  const { locationIds } = useMultiQueryUtils();
  const { canExport } = useCanDoAction();
  const isPaymentsAppVariant = variant === 'payments-app';

  useNavAlert(PaymentsNavAlerts.InvalidInvoice, 'Please select a valid invoice from the list.');

  //INVOICE PROVIDER STATE
  const { currentPage, numRows, setNumRows, setOrder, filter, setFilter, resetCurrentPage } = useInvoiceShallowStore(
    'currentPage',
    'numRows',
    'setNumRows',
    'setOrder',
    'filter',
    'setFilter',
    'resetCurrentPage'
  );
  const { showPayoutDescriptor, columns } = useColumnsWithPayoutDescriptor<InvoiceWithPayoutDescriptor>({
    forPrint: false,
    generateColumns,
    hideLocationNameColumn: locationIds.length === 1,
    tableName: PaymentsTableInstances.PaymentRequests,
  });
  const { refetch, hasMore, fetchNextPage, fetchPreviousPage, invoices, loading } = useQueryPaginatedInvoices({
    addPayoutDescriptor: showPayoutDescriptor,
  });

  // REFUNDS
  const alerts = useAlert();
  const { canRefund } = useCanDoAction();
  const { modalProps, triggerProps } = useModalControl({ disableReturnFocus: true });
  const { modalProps: collectPaymentModalProps, triggerProps: collectPaymentTriggerProps } = useModalControl();
  const { onClick: openModal } = triggerProps;
  const [refundInvoice, setRefundInvoice] = useState<InvoiceModel>();
  const [collectPaymentInvoice, setCollectPaymentInvoice] = useState<string>();

  const issueRefund = (invoice: InvoiceModel) => {
    if (!canRefund) {
      alerts.error(t(`You don't have permission to perform a refund`));
      return;
    }
    setRefundInvoice(invoice);
    openModal();
  };
  // CANCEL MODAL
  const { modalProps: confirmCancelModalProps, openModal: openConfirmCancelModal } = useModalControl();
  const [invoiceToCancel, setInvoiceToCancel] = useState<InvoiceModel | undefined>();
  const openCancel = (invoice: InvoiceModel) => {
    setInvoiceToCancel(invoice);
    openConfirmCancelModal();
  };

  // QUICK ACTIONS
  const { toggleRecorded } = useToggleInvoiceRecorded();
  const { loading: printLoading } = useQueryAllInvoices({ enable: showPrint });
  const { invalidateUnrecordedCount } = PaymentQueries.usePaymentsInvalidation();
  const printReceipt = usePrintReceipt();
  const { exportInvoices, isExporting } = useExportInvoicesQuery();

  const { showInvoiceExportWarning, ...invoiceModalProps } = useInvoiceExportModalControl();

  const handleExportInvoicesClick = useCallback(
    debounce(async () => {
      if (canExport) {
        const warnings: FilterExportWarning = {};

        if (filter.person) {
          warnings.searchParams = true;
        }
        // see if data will be truncated
        if (filter.dateRange && limitDateRange([filter.dateRange.start, filter.dateRange.end]).limited) {
          warnings.truncateDateRange = true;
        }
        // when other filters are applied with out a date range, they are truncated to the
        // default MAX range (3 months) because the result will show for all time
        else if (!filter.dateRange && Object.keys(filter).filter((value) => value !== 'dateRange').length > 0) {
          warnings.truncateDateRange = true;
        }

        // show warnings if any
        if (Object.keys(warnings).length > 0) {
          showInvoiceExportWarning(warnings);
        } else {
          exportInvoices();
        }
      } else {
        alerts.error(t('Payment admin role required'));
      }
    }, 1000),
    [filter]
  );

  const handleChangeFilter = useCallback(
    (filter: InvoiceFilterType) => {
      setFilter(filter);
      onChangeFilter?.(filter);
    },
    [onChangeFilter]
  );

  const handleCollectPaymentClick = (invoice: InvoiceModel) => {
    setCollectPaymentInvoice(invoice.id);
    collectPaymentTriggerProps.onClick();
  };

  const isCancelledInvoice = (data: InvoiceModel) => data.status === InvoiceStatus.Canceled;

  const onRefreshClick = () => {
    refetch();
    invalidateUnrecordedCount();
  };

  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds,
  });

  return (
    <>
      <Table
        globalTrackingId='pay-portal-invoicelist'
        tableStyle={css`
          min-height: 500px;
          height: 100%;
        `}
        colConfig={columns}
        // TODO: adding this .slice is a bandaid fix for now
        // the v1/search/invoices endpoint does not indicate if there are NO next or previous links
        data={invoices.slice(0, numRows)}
        styleConfig={{
          columns: [
            {
              id: '*',
              cellStyler: css`
                padding: ${theme.spacing(1, 1.5)};
              `,
              headerStyler: css`
                padding: ${theme.spacing(2, 1.5)};
              `,
            },
          ],
        }}
        emptyStateConfig={{
          type: 'payments',
          header: t('No data to display'),
        }}
        hasFilterColumns
        hasResizeColumns
        hasResponsiveColWidths
        tableInstanceId={
          isPaymentsAppVariant ? PaymentsTableInstances.PaymentRequests : PaymentsTableInstances.Invoices
        }
        manualSortBy
        manualFilters
        manualFiltersRender={(modalProps, setShowNotification) => (
          <InvoiceFilter
            filter={filter}
            onChangeFilter={handleChangeFilter}
            modalProps={modalProps}
            setShowNotification={setShowNotification}
            setOrder={setOrder}
            resetCurrentPage={resetCurrentPage}
          />
        )}
        isLoading={loading}
        loadingRowsCount={numRows}
        onSortChange={(res) => {
          resetCurrentPage();
          if (!res.length) {
            setOrder('-paidAt');
            return;
          }
          const { id, value } = res[0];
          if (id) {
            const order = FIELD_TO_ORDER[id];
            // fallback to -billedAt if field is unsupported
            setOrder(
              order
                ? (`${value === 'asc' ? '' : '-'}${FIELD_TO_ORDER[id]}` as InvoiceSearchParams['order'])
                : '-billedAt'
            );
          } else {
            setOrder('-billedAt');
          }
        }}
        tableActions={[
          {
            label: t('Export'),
            Icon: ExportIcon,
            onClick: handleExportInvoicesClick,
            disabled: !canExport,
          },
          {
            label: t('Print'),
            Icon: PrintIcon,
            onClick: handlePrint,
            disabled: printLoading,
          },
          {
            label: t('Refresh'),
            Icon: UpdateIcon,
            onClick: onRefreshClick,
          },
        ]}
        rowActions={{
          onRowClick: (invoice) => {
            navigate({
              to: isPaymentsAppVariant
                ? `/payments/invoices/${invoice.id}`
                : `/portal/payments/invoices/${invoice.id}/invoices`,
            });
          },
          shouldHover: true,
          actions: [
            // PAYMENT ACTIONS
            {
              label: t('Refund'),
              Icon: RefundIcon,
              onClick: issueRefund,
              hide: (data) => !isPaidInvoice(data) || calcAvailableToRefund(data) === 0,
              trackingId: 'pay-portal-invoicelist-btn-refund',
            },
            {
              label: t('Print Receipt'),
              Icon: PrintIcon,
              hide: (data) =>
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data.payment?.status === 'SUCCEEDED'
                ),
              onClick: (data) =>
                printReceipt(data, {
                  receiptType: data.payment?.hasRefund ? 'refund' : 'payment',
                  refundId: getLatestRefund(data)?.stripeId,
                }),
              trackingId: 'pay-portal-invoicelist-btn-print',
            },
            {
              label: t('Mark Recorded'),
              Icon: MarkIcon,
              hide: (data) =>
                !!data.payment?.recordedAt ||
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data.payment?.status === 'SUCCEEDED'
                ),
              onClick: (data) =>
                toggleRecorded({ data, paymentId: data.payment?.paymentId, recordedAt: data.payment?.recordedAt }),
              trackingId: 'pay-portal-invoicelist-btn-recorded',
            },
            {
              label: t('Mark Unrecorded'),
              Icon: UnmarkIcon,
              hide: (data) => !data.payment?.recordedAt,
              onClick: (data) =>
                toggleRecorded({ data, paymentId: data.payment?.paymentId, recordedAt: data.payment?.recordedAt }),
              trackingId: 'pay-portal-invoicelist-btn-unrecorded',
            },
            // PAYMENT ACTIONS
            {
              label: t('Collect Payment'),
              Icon: PayIcon,
              hide: (data) => !data.links?.payment || isPaidInvoice(data) || isCancelledInvoice(data),
              onClick: (data) => handleCollectPaymentClick(data),
              trackingId: 'pay-portal-invoicelist-collect-payment',
            },
            {
              label: t('Cancel Payment Request'),
              Icon: DeleteIcon,
              hide: (data) => isPaidInvoice(data) || isCancelledInvoice(data),
              onClick: (data) => openCancel(data),
              trackingId: 'pay-portal-invoicelist-btn-cancel',
            },
          ],
        }}
        isPaginated
        manualPaginationConfig={{
          page: currentPage,
          hasNext: hasMore,
          hasPrevious: currentPage !== 1,
          onNumRowsChange: (num) => {
            setNumRows(num);
            resetCurrentPage();
          },
          defaultRowsPerPage: numRows,
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          handleChange: (prevOrNext: 'prev' | 'next') => {
            if (prevOrNext === 'prev') fetchPreviousPage();
            if (prevOrNext === 'next') fetchNextPage();
          },
        }}
        hasGlobalSearch={true}
        globalSearchConfig={{
          initialValue: filter.person || '',
          position: 'right',
          searchHandler: (searchText: string) => {
            const toSet = {
              ...filter,
              person: searchText.trim().length > 0 ? searchText : undefined,
            };

            setFilter(toSet);
            onChangeFilter?.(toSet);
          },
          placeholder: t('Search'),
          debounceDelay: 1000,
        }}
      />
      <InvoiceCancelModal
        modalProps={confirmCancelModalProps}
        invoice={invoiceToCancel}
        onClose={confirmCancelModalProps.onClose}
      />
      {refundInvoice && <RefundModal invoice={refundInvoice} {...modalProps} />}
      <InvoiceExportModal {...invoiceModalProps} onConfirm={exportInvoices} />
      {isExporting && <ContentLoader show={true} message='Preparing Export...' />}
      {collectPaymentInvoice && (
        <>
          {showMultiProcessorCollect ? (
            <CollectPaymentMultiModalInstance
              modalProps={collectPaymentModalProps}
              collectProps={{
                invoiceId: collectPaymentInvoice,
                flow: 'pay',
              }}
            />
          ) : (
            <CollectPaymentModal
              modalProps={collectPaymentModalProps}
              closeModal={collectPaymentModalProps.onClose}
              invoiceId={collectPaymentInvoice}
            />
          )}
        </>
      )}
    </>
  );
};

const printStyles = {
  marginBottom: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

export type PrintInvoiceListProps = {
  onClose: () => void;
  headerContent?: React.ReactNode;
};

export const PrintInvoiceList = ({ onClose, headerContent }: PrintInvoiceListProps) => {
  const { showPayoutDescriptor, columns } = useColumnsWithPayoutDescriptor<InvoiceWithPayoutDescriptor>({
    forPrint: true,
    generateColumns,
    tableName: PaymentsTableInstances.PaymentRequests,
  });
  const { invoices } = useQueryAllInvoices({ addPayoutDescriptor: showPayoutDescriptor });

  if (!invoices.length) {
    return null;
  }

  return (
    <PrintDialog show={true} onClose={onClose} landscape>
      <div
        css={css`
          margin: ${theme.spacing(2)};
        `}
      >
        <PrintHeader css={printStyles.marginBottom} />

        {headerContent}

        <Table
          wrapperStyle={css`
            height: fit-content;
            width: 100%;
          `}
          colConfig={columns}
          data={invoices}
          tableInstanceId={PaymentsTableInstances.PaymentRequests}
        />
      </div>
    </PrintDialog>
  );
};
