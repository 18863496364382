import { memo, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import PoundSign from '../../assets/svg/pound.svg';
import { ChatListItemTrackingIds } from '../../constants';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { ChatAvatar } from '../common/chat-avatar/chat-avatar';
import { TypingAnimation } from '../common/typing-animation';
import { NavItem } from '../nav/nav-item';

interface ChatListItemProps {
  conversationId: string;
}

export const ConversationListItem = memo(({ conversationId }: ChatListItemProps) => {
  const conversation = useTeamChatSelector((ctx) => ctx.helpers.getConversation(conversationId));
  const users = useTeamChatSelector((ctx) => ctx.users);
  const activeConversationId = useTeamChatSelector((ctx) => ctx.activeConversationId);
  const openStaticConversation = useTeamChatSelector((ctx) => ctx.openStaticConversation);
  const memberIds = conversation?.memberIds ?? [];

  const members = useMemo(
    () => memberIds.map((memberId) => users?.find((user) => user.userID === memberId)).filter((user) => !!user),
    [memberIds, users]
  );

  const openConversation = useCallback(() => {
    if (!conversation?.channelId) {
      return;
    }
    openStaticConversation(conversation.channelId);
  }, [openStaticConversation, conversation?.channelId]);

  const Icon = useMemo(() => {
    return conversation?.usersTyping.length && conversation.usersTyping.length > 0 ? (
      <TypingAnimation />
    ) : conversation?.type === 'DM' ? (
      <ChatAvatar users={members} />
    ) : (
      <img src={PoundSign} css={poundSignStyle} alt='#' />
    );
  }, [conversation?.usersTyping.length, conversation?.type, members]);

  if (!conversation) {
    return null;
  }

  const isSelected = activeConversationId === conversation?.channelId;
  const trackingId =
    conversation?.type === 'DM'
      ? conversation.memberIds.length === 2
        ? ChatListItemTrackingIds.DM
        : ChatListItemTrackingIds.GROUP_DM
      : ChatListItemTrackingIds.CHANNEL;

  return (
    <NavItem
      isSelected={isSelected}
      onClick={openConversation}
      trackingId={trackingId}
      label={conversation.name}
      Icon={Icon}
      badgeCount={conversation.unreadCount}
    />
  );
});
ConversationListItem.displayName = 'ConversationListItem';

const poundSignStyle = css({
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'visible',
});
