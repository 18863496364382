import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../utils';
import { StatItem, StatItemData } from '../../components';
import { ARRoiConversionChart, ARRoiNoShowRateChart } from '../charts';
import { useFetchARROIOOverview } from '../hooks';
import { ARRoi } from '../types';

export const ARRoiSingleSubView = (props: ReturnType<typeof useFetchARROIOOverview>) => {
  const { t } = useTranslation('analytics');
  const search = useSearch<{ Search: { view: ARRoi } }>();
  const { appointmentsCompleted } = props?.aggregated || {};
  const ChartComponent = search.view === 'arConversion' ? ARRoiConversionChart : ARRoiNoShowRateChart;

  const stats: StatItemData[] = useMemo(() => {
    switch (search.view) {
      case 'arConversion':
        return [
          {
            label: t('Appointments were Completed with the helps of Appointment Reminders'),
            value: formatters.percent.format(
              (appointmentsCompleted?.appointmentsCompletedByReminders || 0) /
                (appointmentsCompleted?.totalAppointmentsCompleted || 1)
            ),
          },
        ];
    }

    return [];
  }, [appointmentsCompleted, search.view]);

  return (
    <div css={styles.chartContainer}>
      {!!stats.length && (
        <div css={styles.stats}>
          {stats.map((props) => (
            <StatItem {...props} key={props.label} subAlignment='bottom' valueFontSize={theme.fontSize(66)} />
          ))}
        </div>
      )}
      <div style={{ maxWidth: stats.length ? '500px' : '100%', width: '100%' }}>
        <ChartComponent {...props} />
      </div>
    </div>
  );
};

const styles = {
  chartContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(10)};
    justify-content: space-evenly;
  `,

  stats: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(1)};
    max-width: 400px;
    width: 100%;

    @container (min-width:  ${breakpoints.medium.min}px) {
      width: 50%;
    }
  `,
};
