import { useMutation } from 'react-query';
import { InvoiceDetailModel, PaymentOrigin } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { useInvoicePerson } from '../../../../../hooks';
import { UseCreatePaymentIntentOptions } from '../../../../hooks/use-create-payment-intent';
import { useMakePayment } from '../../../manual-card-entry/stripe/use-make-payment';

interface UseCardOnFilePaymentOptions {
  paymentMethod?: PaymentMethod;
  invoice?: InvoiceDetailModel;
  onGoBack: UseCreatePaymentIntentOptions['onGoBack'];
  onRefetchInvoice: UseCreatePaymentIntentOptions['onRefetchInvoice'];
}

export const useCardOnFilePayment = ({
  paymentMethod,
  invoice,
  onGoBack,
  onRefetchInvoice,
}: UseCardOnFilePaymentOptions) => {
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;
  const { personEmail } = useInvoicePerson(invoice);
  const email = personEmail;
  const amount = invoice?.billedAmount ?? 0;

  const { makePayment } = useMakePayment({
    amount,
    email,
    personId,
    locationId,
    origin: PaymentOrigin.ManualCardEntry,
    setupFutureUsage: SetupFutureUsage.onSession,
    paymentMethod,
    invoiceId: invoice?.id,
    onGoBack,
    onRefetchInvoice,
  });

  const { mutateAsync: makeCardOnFilePayment, isLoading: makingCardOnFilePayment } = useMutation({
    mutationFn: () => makePayment(),
  });

  return { makeCardOnFilePayment, makingCardOnFilePayment };
};
