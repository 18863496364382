import { useRef } from 'react';

type MethodToData<M extends () => any> = Awaited<ReturnType<M>>;

export type UseLatencyBustPollProps<TMethod extends () => any> = {
  /**
   * Should be a get-only method with no sideffects.
   */
  queryMethod: TMethod;

  /**
   * Optional. Use when you don't want to await the main getPollPromise method.
   * This is a way to let the script execute, but still get a callback when the poll concludes.
   */
  onPollEnd?: (data: MethodToData<TMethod>) => void;

  /**
   * Takes whatever data is returned from query method. Should return the specific property
   * that is being watched for changes.
   */
  accessor: (data: MethodToData<TMethod>) => any;

  /**
   * By default, the returned value must be truthy. Otherwise, we consider the status unchanged.
   * If dealing with a falsy value, or when wanting to use a custom comparison, use this.
   */
  hasDataChanged?: (data: MethodToData<TMethod>) => boolean;
  timeout?: number;
  maxAttempts?: number;
  initialTimeoutIncrease?: number;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a hack that should only be used in the following cases:
 * 1. We are confident that a change has been made on the backend
 * 2. A 2sec timeout isn't 100% reliable (sometimes it needs to be 3-4sec)
 * 3. No workflow ID is available to poll / the workflow resolves immediately
 */
export const useLatencyBustPoll = <T extends () => any>({
  queryMethod,
  accessor,
  hasDataChanged,
  onPollEnd,
  timeout = 1000,
  initialTimeoutIncrease = 1000,
  maxAttempts = 5,
}: UseLatencyBustPollProps<T>) => {
  const initialStateRef = useRef<MethodToData<T>>();
  const attemptsCount = useRef(0);

  const hasChanged = (data: MethodToData<T>) => {
    if (hasDataChanged) {
      return hasDataChanged(data);
    }

    const latest = accessor(data);

    if (latest) {
      return latest !== initialStateRef.current;
    }

    return false;
  };

  const pollUntilStatusChange = async () => {
    const data = await queryMethod();

    attemptsCount.current += 1;

    if (hasChanged(data)) {
      return data;
    }

    if (attemptsCount.current >= maxAttempts) {
      return data;
    }

    await delay(timeout);

    return pollUntilStatusChange();
  };

  /**
   * Optionally await this. Will return current data when the status changes.
   */
  const getPollPromise = async (currentData: MethodToData<T>) => {
    await delay(initialTimeoutIncrease);

    attemptsCount.current = 0;
    initialStateRef.current = accessor(currentData);

    const latestData = await pollUntilStatusChange();

    onPollEnd?.(latestData);

    return latestData as MethodToData<T>;
  };

  return {
    getPollPromise,
  };
};
