import { useMerchant } from '@frontend/payments-hooks';

export const useCheckPaymentsEnabled = (locationId: string) => {
  const { hasPayments, chargesEnabled } = useMerchant({
    shouldRun: !!locationId,
    locationId: locationId,
  });

  return {
    isPaymentsEnabled: hasPayments && chargesEnabled,
  };
};
