import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { Chip, PopoverMenu, PopoverMenuItem, PrimaryButton, UsePopoverMenuResponse } from '@frontend/design-system';
import { LocationCheckbox } from './location-checkbox';

type DropdownFilterChipProps = Omit<Parameters<typeof Chip.DropdownFilter>[0], 'leftElement' | 'children' | 'onClick'>;

export type LocationFilterButtonProps = {
  selectableLocationIds: string[];
  filteredLocationIds: string[];
  setFilteredLocationIds: Dispatch<SetStateAction<string[]>>;
  dropdownFilterChipProps: DropdownFilterChipProps;
  popoverProps: UsePopoverMenuResponse<HTMLButtonElement>;
  trackingId?: string;
};

/**
 * The easiest way to use this button is to use it in combination with the `useLocationFilterState` hook, which will provide most of the necessary state props
 * @param selectableLocationIds The list of selectable locationIds.
 * @param filteredLocationIds - The list of location ids that are currently selected.
 * @param setFilteredLocationIds - The function to set the list of selected location ids.
 * @param dropdownFilterChipProps - The props to pass to the dropdown filter chip.
 * @param popoverProps - The props to pass to the popover menu.
 *
 * @deprecated Please use LocationFilterMenu instead
 */
export const LocationFilterButton = ({
  selectableLocationIds,
  filteredLocationIds,
  setFilteredLocationIds,
  dropdownFilterChipProps,
  popoverProps,
  trackingId,
}: LocationFilterButtonProps) => {
  const { t } = useTranslation('location-filter');
  const { getLocationName } = useAppScopeStore();
  const [locationsToToggle, setLocationsToToggle] = useState<string[]>([]);

  const applyNewSelection = () => {
    setFilteredLocationIds((prev) => {
      const { toAdd, toRemove } = locationsToToggle.reduce<{ toAdd: string[]; toRemove: string[] }>(
        (acc, locationId) => {
          if (prev.includes(locationId)) acc.toRemove.push(locationId);
          else acc.toAdd.push(locationId);
          return acc;
        },
        { toAdd: [], toRemove: [] }
      );
      return [...prev.filter((id) => !toRemove.includes(id)), ...toAdd];
    });
    setLocationsToToggle([]);
  };

  const toggleLocation = (locationId: string) => {
    setLocationsToToggle((prev) => {
      if (prev.includes(locationId)) return prev.filter((id) => id !== locationId);
      return [...prev, locationId];
    });
  };

  useEffect(() => {
    if (filteredLocationIds.length === 0 && selectableLocationIds.length > 0) {
      setFilteredLocationIds(selectableLocationIds);
    }
  }, [selectableLocationIds]);

  const disableApply = locationsToToggle.length === 0;

  return (
    <>
      <Chip.DropdownFilter
        leftElement={<Icon name='location' />}
        {...dropdownFilterChipProps}
        {...popoverProps.getTriggerProps()}
        css={{
          maxWidth: 135,
          width: 135,
        }}
        trackingId={trackingId}
      >
        {dropdownFilterChipProps.filterIsActive
          ? t('{{count}} Locations', { count: filteredLocationIds.length })
          : t('Locations')}
      </Chip.DropdownFilter>
      <PopoverMenu {...popoverProps.getMenuProps()}>
        {selectableLocationIds.map((locationId, index) => {
          const isSelected = filteredLocationIds.includes(locationId)
            ? !locationsToToggle.includes(locationId)
            : locationsToToggle.includes(locationId);
          const locationName = getLocationName(locationId);

          return (
            <PopoverMenuItem
              key={locationId}
              {...popoverProps.getItemProps({
                index,
                disableCloseOnSelect: true,
              })}
              css={{
                cursor: 'pointer',
              }}
            >
              <LocationCheckbox
                isSelected={isSelected}
                locationName={locationName}
                onToggle={() => {
                  toggleLocation(locationId);
                }}
              />
            </PopoverMenuItem>
          );
        })}
        <PopoverMenuItem
          {...popoverProps.getItemProps({
            index: disableApply ? -1 : selectableLocationIds.length,
            onClick: disableApply ? undefined : applyNewSelection,
          })}
        >
          <PrimaryButton disabled={disableApply}>{t('Apply')}</PrimaryButton>
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};
