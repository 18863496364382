import { css } from '@emotion/react';
import { Entry } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { CheckboxField, Chip, formatDate, Text, Truncated, useFormField, useTooltip } from '@frontend/design-system';
import { TextWithTooltip } from '../../../../components';
import { QUICK_FILL_SEND_MESSAGE_LIMIT } from '../../../../constants';
import { getFullName, getRelativeTime } from '../../../../helpers';
import { QUICK_FILL_TRACKING_IDS } from '../../../../tracking-ids';
import { OptOutIcon } from '../../components/opt-out-icon';
import { useQuickFillRecipientShallowStore } from '../../store';

type Props = {
  recipient: Entry;
  locationId: string;
  isMaxSelected: boolean;
  setShowMaxReachedBanner: (value: boolean) => void;
};

const trackingIds = QUICK_FILL_TRACKING_IDS.sendMessagesTab.selectRecipientStep;

export const ListItem = ({ recipient, locationId, isMaxSelected, setShowMaxReachedBanner }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const selectFieldProps = useFormField({ type: 'checkbox', value: false });
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();
  const { selectedIds, toggleSelection } = useQuickFillRecipientShallowStore('selectedIds', 'toggleSelection');

  const { note, person, personId = '', validTo, createdAt } = recipient;

  const isPersonSelected = selectedIds?.includes(personId);
  const isMessageOptOut = person?.receiveSms === false;
  const isDisabled = (isMaxSelected && !isPersonSelected) || isMessageOptOut;

  const getTooltipText = () => {
    if (isMessageOptOut && isMaxSelected) {
      return t('Max {{count}} selected & SMS opted out', { count: QUICK_FILL_SEND_MESSAGE_LIMIT });
    }
    if (isMaxSelected) {
      return t('Max {{count}} selection reached', { count: QUICK_FILL_SEND_MESSAGE_LIMIT });
    }
    return t('Opted out of text messages');
  };

  return (
    <div css={styles.root}>
      <div css={{ padding: theme.spacing(2), pointerEvents: 'auto' }} {...(isDisabled && triggerProps)}>
        {/* Overlay to capture click to show banner when max selection is reached */}
        {isMaxSelected && !isPersonSelected && (
          <div
            css={styles.checkboxOverlay}
            data-trackingid={trackingIds.maxSelectionOverlay}
            onClick={() => setShowMaxReachedBanner(true)}
          />
        )}
        <CheckboxField
          {...selectFieldProps}
          label=''
          name={personId}
          disabled={isDisabled}
          value={isPersonSelected}
          trackingId={trackingIds.recipientCheckbox}
          onChange={() => toggleSelection(locationId, personId)}
        />
        <Tooltip {...tooltipProps}>{getTooltipText()}</Tooltip>
      </div>
      <div css={styles.content}>
        <div css={styles.summary}>
          <div css={styles.details}>
            <Truncated as={Text} weight='bold' color={isDisabled ? 'disabled' : 'default'}>
              {getFullName(person)}
            </Truncated>
            {isMessageOptOut && <OptOutIcon />}
          </div>
          {note && (
            <Text size='small' color={isDisabled ? 'disabled' : 'default'}>
              {note}
            </Text>
          )}
          <TextWithTooltip
            size='small'
            color={isDisabled ? 'disabled' : 'subdued'}
            tooltipText={formatDate(createdAt, 'MM/DD/YY h:mm A')}
          >
            {getRelativeTime(createdAt)}
          </TextWithTooltip>
        </div>
        {validTo && (
          <div css={styles.rightSection}>
            <Chip tooltipLabel={t(`Appointment on {{date}}`, { date: formatDate(validTo, 'MM/DD/YY') })}>
              <Icon name='calendar-small' color='primary' />
            </Chip>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  root: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid ${theme.colors.neutral20};
  `,
  content: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(1, 1, 1, 2)};
    overflow: hidden;
  `,
  summary: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  details: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
    color: ${theme.colors.text.disabled};
  `,
  rightSection: css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
  `,
  checkboxOverlay: css`
    position: absolute;
    top: ${theme.spacing(2)}; // Adjustment for checkbox container padding
    left: ${theme.spacing(2)};
    width: 20px; // Checkbox size
    height: 20px;
    z-index: ${theme.zIndex.low};
  `,
};
