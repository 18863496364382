import { i18next } from '@frontend/i18n';
import { REVENUE_MULTIPLIER } from '../constants';

export const productionValueDefinition = (dateRangeHelperText?: string) =>
  i18next.t(
    'Production Value is calculated by multiplying the total number of completed appointments {{dateRangeHelperText}} by ${{multiplier}}',
    {
      dateRangeHelperText,
      multiplier: REVENUE_MULTIPLIER,
      ns: 'analytics',
    }
  );
