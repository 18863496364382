import { Dispatch, SetStateAction, useEffect } from 'react';
import { CreateResponse } from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup_service.pb';
import { useTranslation } from '@frontend/i18n';
import { useFormField, MultiselectField } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { trackingId } from '../../tracking';
import { SettingsCard } from './settings-card';

export const LabelsCard = ({
  callGroup,
  setCallGroup,
}: {
  callGroup: CreateResponse;
  setCallGroup: Dispatch<SetStateAction<CreateResponse>>;
}) => {
  const { t } = useTranslation('phone');
  const field = useFormField({ type: 'multiselect', value: callGroup.locationIds });
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const options = settingsTenantLocation?.children?.map((item) => {
    return {
      locationId: item.locationId,
      name: item.name,
    };
  });

  useEffect(() => {
    setCallGroup((prevState) => ({
      ...prevState,
      locationIds: field.value,
    }));
  }, [field.value]);

  return (
    <SettingsCard
      title={t('Locations (Optional)')}
      description={t('Label this Call Group with locations for reference and filtering.')}
    >
      <div data-trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'location-select' })}>
        <MultiselectField // Using this component temporarily. Will need to use a new component that meet the design for the location selector.
          css={{ width: '537px' }}
          {...field}
          label={t('None Selected (All Locations)')}
          name='locations'
          helperText={t('Selecting none will auto-label all locations to this Call Group.')}
        >
          {options?.map((option) => (
            <MultiselectField.Option key={option.locationId} value={option.locationId ?? ''}>
              {option.name}
            </MultiselectField.Option>
          ))}
        </MultiselectField>
      </div>
    </SettingsCard>
  );
};
