import { CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../utils';
import { InlineStats } from '../../inline-stats';
import { useCallIntelDemoFlags } from '../hooks';

type Props = {
  data?: CallIntelTypes.OverviewStatsResponse | null;
  isLoading?: boolean;
  metricsFor?: string;
};

export const SubViewOpportunitiesInlineStats = ({ data, isLoading, metricsFor }: Props) => {
  const { t } = useTranslation('analytics');
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();

  return (
    <InlineStats
      data={[
        {
          label: t('{{name}} Calls', { name: metricsFor }),
          value: formatters.value.format(data?.totalCallsAnalyzed ?? 0),
        },
        {
          label: t('Unscheduled Opportunities'),
          value: formatters.value.format(
            (data?.summary.totalOpportunities || 0) - (data?.summary.scheduledOpportunities || 0)
          ),
        },
        {
          label: t('Conversion Rate'),
          value: formatters.percent.format(
            (data?.summary.scheduledOpportunities || 0) / (data?.summary.totalOpportunities || 0)
          ),
        },
      ]}
      isDemoAccount={showDemoChipAndBanner}
      isLoading={isLoading}
    />
  );
};
