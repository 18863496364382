import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { PhoneOverrideType } from '../../components/override/types';
import { PhoneNumber } from '../../views/settings/types';

export type ExtendedCallRoute = CallRouteV1.Types.ListIO['output']['callRoutes'][number] & {
  phoneNumbers: PhoneNumber[];
  callRoutePhoneOverride?: PhoneOverrideType;
};

export const NodeAction = {
  Add: 'add',
  Edit: 'edit',
  Delete: 'delete',
} as const;

export type NodeActionType = (typeof NodeAction)[keyof typeof NodeAction];
