import { useState } from 'react';
import { css } from '@emotion/react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { PersonsV3 } from '@frontend/api-person';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ChipCombobox, Text, useAlert, useDebouncedFn } from '@frontend/design-system';
import { MenuItem } from './menu-item';
import { TagChip } from './tag-chip';

type ToFieldProps = {
  locationId: string;
  value: Person[];
  person?: Person;
  error: boolean;
  onChange: (val: Person[]) => void;
};
export const ToField = ({ locationId, value, person, error, onChange }: ToFieldProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const handleError = (err: HttpError) => {
    if (
      typeof err.data === 'object' &&
      typeof err.data.message === 'string' &&
      err.data.message.includes('multiple org ids found')
    ) {
      alert.error(
        t('Contacts could not be searched. Please ensure you have only one organization selected, and try again.')
      );
    }
  };
  const { data } = PersonsV3.PersonQueries.usePersonSearchLegacyInfiniteQuery(
    {
      locationIds: [locationId],
      search: debouncedSearchValue || person?.firstName?.substring(0, 2),
      contactInfoTypeFilter: [ContactType_Enum.EMAIL],
      page: {
        size: 20,
      },
    },
    {
      enabled: !!debouncedSearchValue || !!person,
      retry: 1,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            persons:
              page.persons?.filter((person) =>
                person.contactInfo?.some((contact) => contact.type === ContactType_Enum.EMAIL && contact.destination)
              ) ?? [],
          })),
        };
      },
      onError: handleError,
    }
  );
  const personsResults = data?.pages.flatMap((page) => page.persons) ?? [];

  const handleInputChange = useDebouncedFn((val: string) => {
    setDebouncedSearchValue(val);
  }, 500);

  return (
    <ChipCombobox<Person>
      css={{
        border: `1px solid ${error ? theme.colors.critical50 : theme.colors.neutral30}`,
      }}
      menuStyles={css`
        .combobox-autocomplete-menu-item button {
          padding: 0px;
          cursor: pointer;
        }
      `}
      itemWidth={550}
      itemHeight={80}
      onInputChange={handleInputChange}
      className='payment-to-field'
      clearable={false}
      tags={value}
      options={[...personsResults, ...value]}
      startAdornment={
        <Text color='light' css={{ marginRight: theme.spacing(1) }}>
          {t('To:')}
        </Text>
      }
      accessor={(item: Person) => PersonsV3.PersonHelpers.getFullName(item)}
      MenuItem={MenuItem}
      ChipComponent={({ children, onClick }) => <TagChip name={children ?? ''} onClick={onClick} />}
      name='to-address-dropdown'
      label=''
      onTagsChange={onChange}
    />
  );
};
