import { FC, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  addWidgetToLayout,
  DashboardBreakpoints,
  dashboardBreakpoints,
  DashboardWidgetData,
  DashboardWidgetProvider,
  defaultMargins,
  GRID_HEIGHT,
  gridColumns,
  isEnabled,
  WidgetSizes,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, useTooltip } from '@frontend/design-system';
import { useBundleTrialWidgets } from '../../hooks/useBundleTrialWidgets';
import { useSkipBundleDashboardWidgets } from '../../hooks/useSkipBundleDashboardWidgets';

interface BundleTrialWidgetsLayoutProps {
  styles?: Interpolation<Theme>;
  bundle: SalesforceAccountProductBundle;
  dashboardWidgetsMap: Record<string, DashboardWidgetData>;
  addWidgetToDashboard: (widgetData: Parameters<typeof addWidgetToLayout>[1]) => void;
}

const RGL = WidthProvider(Responsive);

export const BundleTrialWidgetsLayout: FC<BundleTrialWidgetsLayoutProps> = ({
  addWidgetToDashboard,
  bundle,
  styles,
  dashboardWidgetsMap,
}) => {
  const { currentBreakpoint, setCurrentBreakpoint, widgetActionMap, widgetConfigList, widgetLayoutList } =
    useBundleTrialWidgets({
      bundle,
    });

  const { skipWidget } = useSkipBundleDashboardWidgets();

  const renderedWidgets = useMemo(
    () =>
      widgetConfigList.map(({ id, size: currentSize, enabled }) => {
        if (!enabled) return null;

        const widgetData = dashboardWidgetsMap[id];
        if (!widgetData) return null;
        if (!isEnabled(widgetData?.hasAccess)) return null;

        const { title, icon, component: Component } = widgetData;
        const size = Component.config.size;
        const sizeOptions = (typeof size === 'string' ? [size] : Object.values(size)) as WidgetSizes[];
        const sizes = [...new Set(sizeOptions)];
        const currentSizeVal = typeof currentSize === 'string' ? currentSize : currentSize[currentBreakpoint];
        const showAddToDashboard = widgetActionMap[id]?.addToDashboard;

        return (
          <div key={id} css={[widgetContainerStyles, showAddToDashboard && cardHoverStyles]}>
            <DashboardWidgetProvider
              currentBreakpoint={currentBreakpoint}
              title={title}
              icon={icon}
              currentSize={currentSizeVal}
              sizes={sizes}
              isEditMode={false}
              id={id}
            >
              <Component onSkip={() => skipWidget(id)} />
              {showAddToDashboard && (
                <AddToDashboardWidgetsButton
                  widgetId={id}
                  onClick={() =>
                    addWidgetToDashboard({
                      id,
                      size: currentSizeVal,
                    })
                  }
                />
              )}
            </DashboardWidgetProvider>
          </div>
        );
      }),
    [currentBreakpoint, widgetConfigList, addWidgetToDashboard, skipWidget]
  );

  return (
    <div css={[containerStyles, styles]}>
      <RGL
        isBounded
        onBreakpointChange={(newBreakpoint) => setCurrentBreakpoint(newBreakpoint as DashboardBreakpoints)}
        rowHeight={GRID_HEIGHT}
        containerPadding={[0, 0]}
        useCSSTransforms
        breakpoints={dashboardBreakpoints}
        cols={gridColumns}
        margin={defaultMargins}
        layouts={widgetLayoutList}
        allowOverlap={false}
        verticalCompact
        isResizable={false}
        isDraggable={false}
        isDroppable={false}
      >
        {renderedWidgets}
      </RGL>
    </div>
  );
};

const AddToDashboardWidgetsButton: FC<{ onClick: () => void; widgetId: string }> = ({ onClick, widgetId }) => {
  const { triggerProps, tooltipProps, Tooltip } = useTooltip();
  const { t } = useTranslation('bundleUpgrade');
  return (
    <NakedButton
      className='action-icon'
      css={circularIconStyles}
      onClick={onClick}
      {...triggerProps}
      trackingId={`${widgetId}-add-widget-to-dashboard-btn`}
    >
      <Icon name='plus-small' size={14} color='light' />
      <Tooltip {...tooltipProps}>{t('Add to dashboard')}</Tooltip>
    </NakedButton>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: theme.borderRadius.large,
  backgroundColor: theme.colors.neutral10,
});

const widgetContainerStyles = css({
  background: 'transparent',
  padding: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const circularIconStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.75),
  backgroundColor: theme.colors.neutral5,
  border: `1px dashed ${theme.colors.neutral50}`,
  borderRadius: theme.borderRadius.full,
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
});

const cardHoverStyles = css({
  '.action-icon': {
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.2s ease-in-out',
  },
  '&:hover .action-icon': {
    opacity: 1,
    pointerEvents: 'unset',
  },
});
