import { AdaptoActions } from '@frontend/adapto-actions';
import { Icon } from '@frontend/icons';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton } from '@frontend/design-system';
import { CallSelectionContext } from './types';
import { usePhoneCallAction } from './use-call-action';

export type CallButtonProps = {
  label?: string;
  context: CallSelectionContext;
  trackingIdSuffix?: string;
};

export const CallButton = ({ label, context, trackingIdSuffix }: CallButtonProps) => {
  const { triggerProps, Modal, disabled, disabledDetails } = usePhoneCallAction({
    context,
  });

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Call}:${trackingIdSuffix}`}
        disabled={disabled}
        label={disabledDetails ? disabledDetails : label}
      >
        <Icon name='phone-call-small' />
      </ActionButton>
      {Modal}
    </div>
  );
};

export type CallActionProps = {
  label: string;
  context: CallSelectionContext;
  trackingIdSuffix?: string;
};

export const CallAction = ({ label, context, trackingIdSuffix }: CallActionProps) => {
  const { triggerProps, Modal, disabled, disabledDetails } = usePhoneCallAction({
    context,
  });

  return (
    <div>
      <AdaptoActions.Action
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Call}:${trackingIdSuffix}`}
        disabled={disabled}
        icon='phone'
        label={disabledDetails ? disabledDetails : label}
      />
      {Modal}
    </div>
  );
};
