import { useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { CreditCardBrand, InvoiceFilterType } from '@frontend/api-invoices';
import { PaymentQueries } from '@frontend/api-payments';
import { RefundFilterType } from '@frontend/api-refunds';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useQueryPaginatedInvoices, useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';
import {
  InvoiceList,
  PaymentsPage,
  InvoiceSummary,
  DateFilters,
  NewInvoiceAction,
  PrintInvoiceList,
} from '../../../components';
import { FailedList, PrintFailedList } from '../../../components/PaymentFailed';
import { QuickFilterOptions } from '../../../components/PaymentRequests/DateFilters/quick-filters';
import { StatusTabs } from '../../../components/PaymentRequests/status-tabs';
import { PrintRefundList, RefundList, RefundSummaryCard } from '../../../components/Refunds';
import {
  mapFailedPaymentsOriginToPaymentsOrigin,
  mapPaymentOriginToFailedPaymentsOrigin,
  useFailedTransactionsFilters,
  usePaymentRequestQuickFilter,
  usePaymentRole,
  usePrintDialogue,
  useRefundSearch,
  useRefundShallowStore,
} from '../../../hooks';
import { SearchFailedPaymentsParams } from '../../../hooks/payment-failed';
import { usePaymentRequestsPanelShallowStore } from '../../../store';

const styles = {
  marginBottom: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

const onChangeStatusEmpty = () => {};

type StatusView = 'invoices' | 'refunds' | 'failed';

const INVOICE_STATUSES = ['allInvoices', 'paid', 'unpaid'];

const isInvoiceStatus = (status: QuickFilterOptions): status is 'allInvoices' | 'paid' | 'unpaid' =>
  INVOICE_STATUSES.includes(status);

export const PaymentRequests = () => {
  const { t } = useTranslation('payments');
  const { locationId } = useMerchant();
  const { hasAnalyticsRole } = usePaymentRole(locationId);

  const search = useSearch();
  const navigate = useNavigate();

  const { showPrint, setShowPrint, handlePrint } = usePrintDialogue();

  const { selectedStatus, setSelectedStatus } = usePaymentRequestsPanelShallowStore(
    'selectedStatus',
    'setSelectedStatus'
  );

  const { invalidateUnrecordedCount } = PaymentQueries.usePaymentsInvalidation();

  const {
    filter: invoiceFilter,
    setFilter: setInvoicesFilter,
    setFilterDisplayDates,
  } = useInvoiceShallowStore('filter', 'setFilter', 'setFilterDisplayDates');

  const { filter: refundFilter, setFilter: setRefundFilter } = useRefundShallowStore('filter', 'setFilter');

  const {
    filter: failedFilter,
    filterMethods: failedFilterMethods,
    currentPage: failedCurrentPage,
  } = useFailedTransactionsFilters();

  const { handleQuickFilter } = usePaymentRequestQuickFilter({
    filter: invoiceFilter,
    onChange: setInvoicesFilter,
  });

  const onChangeStatus = useCallback(
    (selected: QuickFilterOptions) => {
      if (selectedStatus === selected) {
        return;
      }
      setSelectedStatus(selected);

      if (isInvoiceStatus(selected)) {
        handleQuickFilter(selected);
      }
    },
    [invoiceFilter, selectedStatus]
  );

  const onChangeDateRange = (dateRange: InvoiceFilterType['dateRange']) => {
    setFilterDisplayDates(dateRange);
  };

  const statusView: StatusView = useMemo(() => {
    if (isInvoiceStatus(selectedStatus)) {
      return 'invoices';
    }

    if (selectedStatus === 'refunded') {
      return 'refunds';
    }

    if (selectedStatus === 'failed') {
      return 'failed';
    }

    console.error('Unknown selected status: ', selectedStatus);
    return 'invoices';
  }, [selectedStatus]);

  /**
   * When refund store changes are made, map them, as best we can, to the invoice store, to
   * preserve continuity
   */
  const onChangeRefundFilter = useCallback(
    (newRefundFilter: RefundFilterType) => {
      const cardType = newRefundFilter.cardType?.map((c) =>
        c === '' ? CreditCardBrand.CardBrandUnknown : (c.toUpperCase() as CreditCardBrand)
      );

      failedFilterMethods.setFilter({
        ...failedFilter,
        search: {
          ...failedFilter.search,
          origin: mapPaymentOriginToFailedPaymentsOrigin(newRefundFilter.origin || []),
          personName: newRefundFilter.personName,
        },
      });

      setInvoicesFilter({
        ...invoiceFilter,
        person: newRefundFilter.personName,
        paymentType: newRefundFilter.origin,
        paymentMethod: newRefundFilter.paymentType,
        cardType,
      });
    },
    [invoiceFilter, failedFilter]
  );

  const onChangeInvoiceFilter = useCallback(
    (newInvoiceFilter: InvoiceFilterType) => {
      const cardType = newInvoiceFilter.cardType?.map((c) =>
        c === CreditCardBrand.CardBrandUnknown ? '' : (c.toLowerCase() as CreditCardBrand)
      );

      setRefundFilter({
        ...refundFilter,
        personName: newInvoiceFilter.person,
        origin: newInvoiceFilter.paymentType,
        paymentType: newInvoiceFilter.paymentMethod,
        cardType,
      });

      failedFilterMethods.setFilter({
        ...failedFilter,
        search: {
          ...failedFilter.search,
          origin: mapPaymentOriginToFailedPaymentsOrigin(newInvoiceFilter.paymentType || []),
          personName: newInvoiceFilter.person,
        },
      });
    },
    [refundFilter]
  );

  const onChangeFailedFilter = useCallback(
    (toSet: SearchFailedPaymentsParams) => {
      const mappedOrigin = mapFailedPaymentsOriginToPaymentsOrigin(toSet.search.origin || []);
      setInvoicesFilter({
        ...invoiceFilter,
        person: toSet.search.personName,
        paymentType: mappedOrigin,
      });

      setRefundFilter({
        ...refundFilter,
        personName: toSet.search.personName,
        origin: mappedOrigin,
      });
    },
    [invoiceFilter, refundFilter]
  );

  useEffect(() => {
    /**
     * Main date change state in invoice store.
     * Watch it, and sync refund store.
     *
     * `onChangeDateRange()` doesn't have the latest store states,
     * even though it's not memoized. So, get around messing up (clearing) state by using useEffect().
     */

    const { dateRange } = invoiceFilter;

    setRefundFilter({ ...refundFilter, dateRefunded: { lte: dateRange?.end, gte: dateRange?.start } });
    failedFilterMethods.setSubmittedAt(dateRange?.start, dateRange?.end);
  }, [invoiceFilter.dateRange]);

  useEffect(() => {
    const status = String(search.status || '');
    if (['failed', 'allInvoices', 'paid', 'refunded', 'unpaid'].includes(status)) {
      setSelectedStatus(status as QuickFilterOptions);
      // navigate to same page, without status in URL.
      navigate({ search: { status: undefined } });
    }
  }, [search.status]);

  useEffect(() => {
    /**
     * On mount
     */
    // Revalidate unrecorded on mount
    invalidateUnrecordedCount();

    // sync refund date range
    setRefundFilter({
      ...refundFilter,
      dateRefunded: { lte: invoiceFilter?.dateRange?.end, gte: invoiceFilter?.dateRange?.start },
    });
  }, []);

  return (
    <PaymentsPage
      title={
        <>
          <Heading level={1}>{t('invoiceHeaderTitle')}</Heading>
        </>
      }
      maxWidth={1420}
      variant='payments-app'
      action={<NewInvoiceAction trackingId='pay-portal-invoices-button-new' />}
      showPrint={showPrint}
      setShowPrint={setShowPrint}
      printDialogue={({ onClose }) => (
        <>
          {statusView === 'invoices' && (
            <PrintInvoiceList
              headerContent={
                <>
                  <StatusTabs selectedStatus={selectedStatus} onChange={onChangeStatusEmpty} />
                  <DateFilters dateRange={invoiceFilter.dateRange} />
                  {hasAnalyticsRole && <InvoiceSummaryPerformanceWrapper loading={false} />}
                </>
              }
              onClose={onClose}
            />
          )}
          {statusView === 'refunds' && (
            <>
              <PrintRefundList
                onClose={onClose}
                headerContent={
                  <>
                    <StatusTabs selectedStatus={selectedStatus} onChange={onChangeStatusEmpty} />
                    <DateFilters dateRange={invoiceFilter.dateRange} />
                    {hasAnalyticsRole && <RefundSummaryCardPerformanceWrapper />}
                  </>
                }
              />
            </>
          )}
          {statusView === 'failed' && (
            <>
              <PrintFailedList
                onClose={onClose}
                filter={failedFilter}
                currentPage={failedCurrentPage}
                headerContent={
                  <>
                    <StatusTabs selectedStatus={selectedStatus} onChange={onChangeStatusEmpty} />
                    <DateFilters dateRange={invoiceFilter.dateRange} />
                  </>
                }
              />
            </>
          )}
        </>
      )}
    >
      <StatusTabs selectedStatus={selectedStatus} onChange={onChangeStatus} />
      <DateFilters dateRange={invoiceFilter?.dateRange} onChangeDateRange={onChangeDateRange} />

      {statusView === 'invoices' && (
        <>
          {hasAnalyticsRole && <InvoiceSummaryPerformanceWrapper />}
          <InvoiceList
            showPrint={showPrint}
            handlePrint={handlePrint}
            variant='payments-app'
            onChangeFilter={onChangeInvoiceFilter}
          />
        </>
      )}
      {statusView === 'refunds' && (
        <>
          {hasAnalyticsRole && <RefundSummaryCardPerformanceWrapper />}
          <RefundList handlePrint={handlePrint} onChangeFilter={onChangeRefundFilter} />
        </>
      )}
      {statusView === 'failed' && (
        <FailedList
          handlePrint={handlePrint}
          filter={failedFilter}
          filterMethods={failedFilterMethods}
          onChangeFilter={onChangeFailedFilter}
          currentPage={failedCurrentPage}
        />
      )}
    </PaymentsPage>
  );
};

const RefundSummaryCardPerformanceWrapper = () => {
  const { summary } = useRefundSearch();

  return <RefundSummaryCard loading={false} summary={summary} css={{ marginBottom: 24 }} />;
};

const InvoiceSummaryPerformanceWrapper = ({ loading: loadingOverride }: { loading?: boolean }) => {
  const { summary, loading } = useQueryPaginatedInvoices();

  return <InvoiceSummary summary={summary} loading={loadingOverride ?? loading} style={styles.marginBottom} />;
};
