import { useEffect } from 'react';

const useIdleDisconnect = () => {
  useEffect(() => {
    console.warn('team chat idle disconnect not implemented yet');
  }, []);
};

export const _useIdleDisconnect = useIdleDisconnect;

// Idle timeout for disconnecting the user from the stream
// const IDLE_TIMEOUT = 1000 * 60;
// const useIdleDisconnect = ({
//   selectedOrgId,
//   user,
//   client,
//   setClient,
//   tokenData,
// }: {
//   selectedOrgId: string;
//   user?: PortalUser;
//   client?: StreamChat<StreamChatType> | null;
//   setClient: Dispatch<SetStateAction<StreamChat<StreamChatType> | undefined>>;
//   tokenData: { token: string; apiKey: string };
// }) => {
//   const { status } = useChatStatusShallowStore('status');
//   const [isDisconnecting, setIsDisconnecting] = useState(false);
//   const [isConnecting, setIsConnecting] = useState(false);
//   const [isConnected, setIsConnected] = useState<boolean>(!!client?.user);
//   const idle = useIdle(IDLE_TIMEOUT);
//   const { setActivePopup } = usePopupBarManager();

//   useEffect(() => {
//     if (tokenData && status === 'active' && !idle) {
//       if (!user?.userID) return;

//       disconnect.cancel();
//       setIsConnected(true);
//       const client = StreamChat.getInstance(tokenData.apiKey);
//       connect({
//         client,
//         isConnecting,
//         selectedOrgId,
//         setIsConnecting,
//         token: tokenData.token,
//         user,
//       });
//     } else if (client?.user && status === 'idle') {
//       disconnect({
//         client,
//         isDisconnecting,
//         setIsDisconnecting,
//         cb: () => {
//           setActivePopup([]);
//           setIsConnected(false);
//         },
//         setClient,
//       });
//     }
//   }, [idle, status, user?.userID]);

//   useEffect(() => {
//     const handler = () => {
//       if (document.visibilityState !== 'visible' && client?.user) {
//         setIsDisconnecting(true);
//         disconnect({
//           client,
//           isDisconnecting,
//           setIsDisconnecting,
//           cb: () => {
//             setActivePopup([]);
//             setIsConnected(false);
//           },
//           setClient,
//         });
//       } else if (document.visibilityState === 'visible' && status === 'active' && tokenData) {
//         disconnect.cancel();
//         const client = StreamChat.getInstance(tokenData?.apiKey);
//         setIsConnected(true);
//         connect({
//           client,
//           isConnecting,
//           setIsConnecting,
//           token: tokenData?.token,
//           user,
//           selectedOrgId,
//         });
//       }
//     };

//     document.addEventListener('visibilitychange', handler);

//     return () => {
//       document.removeEventListener('visibilitychange', handler);
//     };
//   }, [client, tokenData, status]);

//   return { isConnected };
// };

// const DISCONNECT_COUNTDOWN = 1000 * 5;
// const disconnect = debounce(
//   ({
//     client,
//     isDisconnecting,
//     setIsDisconnecting,
//     cb,
//     setClient,
//   }: {
//     client: StreamChat<StreamChatType>;
//     isDisconnecting: boolean;
//     setIsDisconnecting: (val: boolean) => void;
//     setClient: Dispatch<SetStateAction<StreamChat<StreamChatType> | undefined>>;
//     cb?: () => void;
//   }) => {
//     const _client = client;
//     if (_client.user && !isDisconnecting) {
//       setIsDisconnecting(true);

//       return _client
//         .disconnectUser()
//         .then(() => {
//           cb && cb();
//           // client.disconnectUser set the user to null in the client object, so we need to set the client again.
//           setClient(_client);
//         })
//         .finally(() => {
//           setIsDisconnecting(false);
//         });
//     }

//     return Promise.resolve();
//   },
//   DISCONNECT_COUNTDOWN
// );
