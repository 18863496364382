import { useRef } from 'react';
import { css } from '@emotion/react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Alert, SpinningLoader } from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider/team-chat.provider';
import { MessageMention } from '../conversation/message/message-mention';
import { EmptyMentions } from './empty-mentions';

export const MentionsList = () => {
  const { t } = useTranslation('team-chat');
  const messagesWithMentions = useTeamChatSelector((ctx) => ctx.messagesWithMentions);
  const isLoadingMentions = useTeamChatSelector((ctx) => ctx.isLoadingMentions);
  const isMentionsFetched = useTeamChatSelector((ctx) => ctx.isMentionsFetched);
  const mentionsError = useTeamChatSelector((ctx) => ctx.mentionsError);
  const openStaticConversation = useTeamChatSelector((ctx) => ctx.openStaticConversation);
  const highlightedMessageId = useTeamChatSelector((ctx) => ctx.highlightedMessageId);
  const cache = useTeamChatSelector((ctx) => ctx.cache);
  const openThread = useTeamChatSelector((ctx) => ctx.openThread);
  const listRef = useRef<VirtuosoHandle>(null);
  if (isLoadingMentions) {
    return (
      <div css={styles.spinner}>
        <SpinningLoader size='xs' />
      </div>
    );
  }

  if (isMentionsFetched && messagesWithMentions?.length === 0) {
    return <EmptyMentions />;
  }
  if (isMentionsFetched && mentionsError) {
    // TODO: @gisheri - get design to give us an error state here.
    return <Alert type='error'>{t('We had a problem getting mentions')}</Alert>;
  }

  const goToMessage = (message: NonNullable<typeof messagesWithMentions>[number]) => {
    const isReply = !!message.parentId;
    if (isReply) {
      openThread(message.parentId, message.channelId || '');
    } else {
      openStaticConversation(message.channelId, message.id);
    }
    if (message.isUnread) {
      setTimeout(() => {
        cache.markMentionedMessageAsRead(message.id);
      }, 1000);
    }
  };

  return (
    <Virtuoso
      data={messagesWithMentions}
      style={{ height: '100%' }}
      // endReached={() => console.log('end reached')}
      firstItemIndex={0}
      initialTopMostItemIndex={0}
      ref={listRef}
      itemContent={(_index, message) => (
        <MessageMention
          message={message}
          isHighlighted={message.id === highlightedMessageId}
          onClick={() => goToMessage(message)}
        />
      )}
      components={{
        Header: ({ context }: { context?: { isFetching: boolean; firstItemIndex: number } }) =>
          context?.isFetching ? (
            <div css={styles.spinner}>
              <SpinningLoader size='xs' />
            </div>
          ) : null,
      }}
      context={{
        isFetching: isLoadingMentions,
        firstItemIndex: 0,
      }}
      followOutput='auto'
    />
  );
};

const styles = {
  spinner: css({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    width: '100%',
  }),
};
