import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { CallQueueApi } from '@frontend/api-call-queue-legacy';
import { DevicesTypes } from '@frontend/api-devices';
import { Chips } from '@frontend/chips';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Text, TextLink, SkeletonLoader, emptyStateGraphics, Heading, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { CallQueueDevicesContext, getWorkingState, transformDevices } from '../manage-devices/utils';

interface CallQueueMetricsCardProps {
  queueId: string;
  queueName: string;
  locationIds?: string[];
  callCount?: number;
  allDevices: DevicesTypes.DeviceWithAddressModel[];
}

export const CallQueueMetricsCard = ({
  queueId,
  queueName,
  locationIds = [],
  callCount = 0,
  allDevices,
}: CallQueueMetricsCardProps) => {
  const alert = useAlert();
  const { t } = useTranslation('call-queue-stats');
  const { getScopeName } = useAppScopeStore();

  const locationLabel =
    locationIds?.length > 1 ? `${locationIds.length} Locations` : getScopeName(locationIds?.[0] ?? '');

  const { setShow, context, panelType } = useSlidePanelShallowStore<CallQueueDevicesContext>(
    'setShow',
    'context',
    'panelType'
  );

  const {
    data: callQueue,
    refetch: refetchCallQueueData,
    isError: isErrorInFetchingCallQueue,
  } = useScopedQuery({
    queryKey: queryKeys.callQueue(queueId),
    queryFn: () => {
      return CallQueueApi.get(queueId);
    },
  });

  useEffect(() => {
    if (!!isErrorInFetchingCallQueue) {
      alert.error(t('Failed to fetch call queue data'));
    }
  }, [isErrorInFetchingCallQueue]);

  const devices = callQueue?.Devices?.map((itm) => itm.SipProfileID) ?? [];

  const allDevicesTransformedData = useMemo(() => {
    return transformDevices(allDevices, devices).sort((a, b) => a.name.localeCompare(b.name));
  }, [devices, allDevices]);

  const assignedDevices = useMemo(() => {
    const workingDevices = getWorkingState(allDevicesTransformedData ?? []);
    return workingDevices
      ?.filter((d) => d.isAssigned)
      ?.map((device) => ({ SipProfileID: device?.sipProfile?.ID ?? '' }));
  }, [allDevicesTransformedData]);

  useEffect(() => {
    if (panelType === 'manageDevices' && context?.callQueue?.ID === queueId && context.shouldUpdate === true) {
      refetchCallQueueData();
    }
  }, [context?.shouldUpdate]);

  const devicesCount = assignedDevices?.length ?? 0;

  return (
    <div css={styles.queueCardStyles({ hideLocation: !locationIds.length })} key={queueId}>
      <h3 css={styles.ellipsis} className='header' title={queueName}>
        {queueName}
      </h3>

      <TextLink
        css={{
          textDecoration: 'none',
        }}
        className='link'
        weight='bold'
        color='primary'
        trackingId='call-queue-stats-manage-devices-card-link-btn'
        onClick={() => {
          setShow(true, 'manageDevices', {
            allDevices: allDevices,
            callQueue: callQueue,
            assignedDevices: assignedDevices ?? [],
            shouldUpdate: true,
          });
        }}
      >
        {devicesCount} {t('Devices')}
      </TextLink>

      <Chips.LocationChip className='location' variant={locationIds.length > 1 ? 'warningDark' : 'default'}>
        {locationLabel}
      </Chips.LocationChip>

      <Text className='callCount'>{callCount}</Text>
      <Text as='span' className='callCountDesc'>
        {t('Calls in Queue')}
      </Text>
    </div>
  );
};

export const MetricsCardSkeletonLoader = () => {
  return (
    <div css={styles.queueCardStyles({ hideLocation: false })}>
      <SkeletonLoader className='header' height={20} width='60%' />
      <SkeletonLoader className='link' height={20} width='30%' />
      <SkeletonLoader className='location' height={20} width='50%' />
      <SkeletonLoader className='callCount' height={40} width='50%' />
      <SkeletonLoader className='callCountDesc' height={20} width='50%' />
    </div>
  );
};

export const MetricsEmptyState = () => {
  const { t } = useTranslation('call-queue-stats');
  const EmptyStateSyncPhone = emptyStateGraphics.sync_your_phone;

  return (
    <section css={styles.emptyStateContainer}>
      <EmptyStateSyncPhone
        css={css`
          height: 200px;
        `}
      />
      <Heading level={3} textAlign='center'>
        {t('No Items to Display')}
      </Heading>
      <Text textAlign='center'>{t('Call Queue Data will display here.')}</Text>
    </section>
  );
};

const styles = {
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  emptyStateContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    > h3,
    p {
      color: ${theme.colors.neutral50};
    }
  `,

  queueCardStyles: ({ hideLocation }: { hideLocation: boolean }) => css`
    width: 355px;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.spacing(1)};
    padding: 24px;
    display: grid;

    grid-template-areas:
      'header header header link link'
      'location location location location location'
      'callCount callCount avg avg avg'
      'callCountDesc callCountDesc avgDesc avgDesc avgDesc';

    > .header {
      grid-area: header;
      grid: 1 / span 3;
      justify-self: start;
      width: 180px;
    }

    > .link {
      grid-area: link;
      width: 80px;
      grid-column: 4 / span 2;
      justify-self: end;
    }

    > .location {
      grid-area: location;
      grid-column: 1 / span 2;
      margin: ${theme.spacing(0.5, 0, 2, 0)};
      visibility: ${hideLocation ? 'hidden' : 'visible'};
    }

    > .callCount {
      grid-area: callCount;
      width: 90px;
      font-size: ${theme.fontSize(36)};
      line-height: 40px;
      font-weight: 700;
    }

    > .avg {
      grid-area: avg;
      font-size: ${theme.fontSize(16)};
      align-self: end;
    }

    > .callCountDesc {
      grid-area: callCountDesc;
    }

    > .avgDesc {
      grid-area: avgDesc;
      width: 171px;
    }

    > .callCountDesc,
    > .avgDesc {
      font-size: ${theme.fontSize(14)};
      color: ${theme.colors.neutral50};
      line-height: 20px;
    }
  `,
};
