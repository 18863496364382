import { ComponentProps } from 'react';
import { horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { css } from '@emotion/react';
import { AnimatePresence, HTMLMotionProps, motion } from 'motion/react';
import { Icon } from '@frontend/icons';
import { InboxTemplatesModals, TemplatesPopover, useInboxTemplateFlows } from '@frontend/inbox-templates';
import { ThreadSendingAreaComponents, ThreadSendingAreaHooks } from '@frontend/thread-sending-area';
import { theme } from '@frontend/theme';
import { Button, EmojiButton } from '@frontend/design-system';
import { CHAT_HEIGHTS, HEADER_HEIGHT, WIDTHS } from '../constants';
import { MiniChatBody } from './mini-chat-body';
import { MiniChatHeader } from './mini-chat-header';

type MiniChatProps = Pick<
  ComponentProps<typeof MiniChatHeader>,
  | 'chatId'
  | 'groupId'
  | 'personPhone'
  | 'onSelectPersonPhone'
  | 'departmentId'
  | 'onSelectDepartment'
  | 'personId'
  | 'unreadCount'
  | 'sizeVariant'
  | 'onMaximize'
  | 'onMinimize'
  | 'onClose'
> &
  Pick<ComponentProps<typeof MiniChatBody>, 'threadId' | 'targetSmsData'> &
  Pick<ComponentProps<typeof ThreadSendingAreaComponents.ThreadSendingArea>, 'canSchedule'> & {
    locationPhone?: string;
    dragIsActive?: boolean;
    isMobile?: boolean;
  } & HTMLMotionProps<'div'>;

export const MiniChat = ({
  chatId,
  groupId,
  personId,
  personPhone,
  onSelectPersonPhone,
  departmentId,
  onSelectDepartment,
  unreadCount,
  sizeVariant,
  onMaximize,
  onMinimize,
  onClose,
  threadId,
  targetSmsData,
  canSchedule,
  locationPhone,
  dragIsActive,
  isMobile,
  ...rest
}: MiniChatProps) => {
  const { focusTextarea, insertText, setBody, templatePopoverState, ...threadSendingAreaState } =
    ThreadSendingAreaHooks.useThreadSendingArea({
      threadId,
      groupId,
      personPhone,
      contextContactId: personId,
      outboundPhone: departmentId
        ? {
            departmentId,
          }
        : undefined,
    });
  const { availableTemplateFlows, modalsProps } = useInboxTemplateFlows({
    groupId,
    personId,
    personPhone,
    locationPhone: locationPhone ?? '',
    onRenderTemplate: ({ bodyValue }) => {
      setBody(bodyValue);
      focusTextarea();
    },
  });

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useSortable({
    id: chatId,
    strategy: horizontalListSortingStrategy,
    disabled: dragIsActive,
  });

  return (
    <>
      <motion.div
        ref={setNodeRef}
        variants={{
          full: {
            height: CHAT_HEIGHTS['full'],
            width: WIDTHS['full'],
          },
          minimized: {
            height: CHAT_HEIGHTS['minimized'],
            width: WIDTHS['minimized'],
          },
          avatar: {
            height: CHAT_HEIGHTS['avatar'],
            width: WIDTHS['avatar'],
          },
        }}
        initial='avatar'
        animate={sizeVariant}
        exit={sizeVariant === 'full' ? 'minimized' : sizeVariant}
        css={{
          backgroundColor: theme.colors.white,
          boxShadow: theme.shadows.floating,
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTopRightRadius: theme.borderRadius.medium,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
        {...rest}
      >
        <div
          css={{
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AnimatePresence>
            <MiniChatHeader
              key={'mini-chat-header' + chatId}
              chatId={chatId}
              groupId={groupId}
              personPhone={personPhone}
              onSelectPersonPhone={onSelectPersonPhone}
              departmentId={departmentId}
              onSelectDepartment={onSelectDepartment}
              personId={personId}
              unreadCount={unreadCount}
              sizeVariant={sizeVariant}
              onMaximize={onMaximize}
              onMinimize={onMinimize}
              onClose={onClose}
              hideButtons={dragIsActive}
              ref={setActivatorNodeRef}
              isMobile={isMobile}
              {...listeners}
              {...attributes}
            />
            {sizeVariant === 'full' && !dragIsActive ? (
              <MiniChatBody
                key={'mini-chat-body' + chatId}
                groupId={groupId}
                threadId={threadId}
                targetSmsData={targetSmsData}
                personPhone={personPhone}
                unreadCount={unreadCount}
                chatId={chatId}
              />
            ) : (
              <div
                key='mini-chat-body-placeholder'
                css={{
                  position: 'relative',
                  maxHeight: CHAT_HEIGHTS['full'] - HEADER_HEIGHT,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              ></div>
            )}
            <ThreadSendingAreaComponents.ThreadSendingArea
              {...threadSendingAreaState}
              canSchedule={canSchedule}
              autoGrowTextareaProps={{
                maxRows: 3,
                minRows: 3,
              }}
              hideTypingIndicator={sizeVariant !== 'full'}
              buttonType='button'
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ThreadSendingAreaComponents.SignatureButton size='large' css={iconButtonStyles()} groupId={groupId} />
                <ThreadSendingAreaComponents.ImageButton
                  size='large'
                  css={iconButtonStyles()}
                  addImages={threadSendingAreaState.addMedia}
                />
                <EmojiButton
                  // styles necessary to match this button to the sizing required by mini-chat designs
                  css={{
                    height: theme.spacing(4),
                    width: theme.spacing(4),
                    minWidth: 'unset',
                    padding: theme.spacing(1),
                  }}
                  onSelect={({ emoji, unified }) => {
                    if (unified !== '') {
                      insertText(emoji);
                    }
                  }}
                />
                <Button
                  iconName='template'
                  variant='tertiary'
                  size='large'
                  css={iconButtonStyles()}
                  {...templatePopoverState.getTriggerProps()}
                >
                  <Icon name='caret-down-tiny' size={8} />
                </Button>
              </div>
            </ThreadSendingAreaComponents.ThreadSendingArea>
          </AnimatePresence>
        </div>
      </motion.div>
      <InboxTemplatesModals {...modalsProps} />
      <TemplatesPopover
        getMenuProps={templatePopoverState.getMenuProps}
        getItemProps={templatePopoverState.getItemProps}
        templateItems={availableTemplateFlows}
      />
    </>
  );
};

const iconButtonStyles = (disabled?: boolean) =>
  css([
    {
      padding: theme.spacing(0.5),
      height: 'auto',
      ':hover': {
        backgroundColor: theme.colors.neutral5,
      },
      svg: {
        color: theme.colors.neutral70,
      },
    },
    disabled && {
      ':hover': {
        backgroundColor: 'transparent',
      },
      svg: {
        color: theme.colors.neutral20,
      },
      cursor: 'default',
    },
  ]);
