import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { useInvalidateDiscoverReaderQuery } from '../../../terminals/stripe/hooks/use-discover-readers-query';
import { RegisterTerminalStepNavigationProps } from '../register-terminal.types';

export const RegisterTerminalGenericNavigation = ({ body: Body }: RegisterTerminalStepNavigationProps) => {
  const {
    multiStep: { goPrevStep, goToStep, onCancel: onCancelStepper },
    activeLocationId,
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => goPrevStep({ orStep: PAYMENT_MULTI_STEPS.selectTerminal }), [goPrevStep]);

  const { invalidateQuery } = useInvalidateDiscoverReaderQuery(activeLocationId);

  const onRegisterTerminal = useCallback(() => {
    invalidateQuery();
  }, [activeLocationId]);

  const onCancel = useCallback(() => {
    onCancelStepper?.();
  }, [onCancelStepper]);

  const onComplete = useCallback(() => {
    goToStep({ orStep: PAYMENT_MULTI_STEPS.selectTerminal });
  }, [goToStep]);

  return (
    <Body onGoBack={onGoBack} onRegisterTerminal={onRegisterTerminal} onComplete={onComplete} onCancel={onCancel} />
  );
};
