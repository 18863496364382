import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import { QuickFilterOptions } from '../components/PaymentRequests/DateFilters/quick-filters';

export type PaymentRequestsPanelStore = {
  selectedStatus: QuickFilterOptions;
  setSelectedStatus: (status: QuickFilterOptions) => void;
};

export const usePaymentRequestsPanelStore = createStoreWithSubscribe<PaymentRequestsPanelStore>(
  (set) => ({
    selectedStatus: 'allInvoices',
    setSelectedStatus: (status: QuickFilterOptions) => set((state) => ({ ...state, selectedStatus: status })),
  }),
  {
    name: 'PaymentRequestsPanelStore',
    trace: true,
  }
);

export const usePaymentRequestsPanelShallowStore = createShallowStore(usePaymentRequestsPanelStore);
