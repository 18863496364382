import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { PaymentInfoActionCard } from './PaymentInfoActionCard';
import { SetupPaymentAccountCard } from './SetupPaymentAccountCard';

export const PaymentsProfilePromotionCard: FC = () => {
  const { hasAccountCreationPermission, hasPaymentsAccount } = usePaymentsPromotion();
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });

  return (
    <div css={cardStyles}>
      <Heading level={3}>{t('Payments Balance')}</Heading>
      {!hasPaymentsAccount && hasAccountCreationPermission ? <SetupPaymentAccountCard /> : <PaymentInfoActionCard />}
    </div>
  );
};

const cardStyles = css({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.light,
});
