import { css } from '@emotion/react';

export const draggableStyles = css`
  cursor: -webkit-grab; /* For older versions of Safari */
  cursor: grab;
  user-select: none; /* Prevents text selection while dragging */

  /* Change cursor to 'grabbing' when the element is active */
  &:active {
    cursor: -webkit-grabbing; /* For older versions of Safari */
    cursor: grabbing;
  }
`;
