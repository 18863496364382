import { IconName } from '@frontend/icons';

export enum PaymentActionEnum {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CREATE_ACCOUNT_LEARN_MORE = 'CREATE_ACCOUNT_LEARN_MORE',
  CREATE_INVOICE = 'CREATE_INVOICE',
  DISMISS = 'DISMISS',
  ONLINE_BILL_PAY = 'ONLINE_BILL_PAY',
  SHARE_INVOICE = 'SHARE_INVOICE',
}

export type PaymentActionType = {
  type: PaymentActionEnum;
  title: string;
  iconName: IconName;
  trackingId: string;
};

export type FeatureContentType = { title: string; image: string };
