import { InvoiceSearchParams, InvoiceFilterType, getFilterDisplayDates } from '@frontend/api-invoices';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

type InvoiceState = {
  currentPage: number;
  displayDates: [Date, Date] | [];
  filter: InvoiceFilterType;
  numRows: number;
  selectedInvoiceId?: string;
  order: InvoiceSearchParams['order'];
  workflowIds: string[];
};

type InvoiceActions = {
  setCurrentPage: (page: InvoiceState['currentPage']) => void;
  resetCurrentPage: () => void;
  setFilter: (filter: InvoiceState['filter']) => void;
  setNumRows: (numRows: InvoiceState['numRows']) => void;
  setOrder: (order: InvoiceState['order']) => void;
  setSelectedInvoiceId: (selectedInvoiceId: string | null) => void;
  setWorkflowIds: (workflowIds: string[]) => void;
  setFilterDisplayDates: (dateRange: InvoiceFilterType['dateRange']) => void;
};

type InvoiceStore = InvoiceState & InvoiceActions;

const DEFAULT_ORDER = '-billedAt';
const initialFilter = {
  dateRange: undefined,
};

export const useInvoiceStore = createStoreWithSubscribe<InvoiceStore>(
  (set) => {
    return {
      filter: initialFilter,
      displayDates: getFilterDisplayDates({}),
      currentPage: 1,
      numRows: 10,
      order: DEFAULT_ORDER,
      workflowIds: [],

      setFilterDisplayDates: (dateRange: InvoiceFilterType['dateRange']) => {
        set((prevState) => {
          return {
            ...prevState,
            filter: {
              ...prevState.filter,
              dateRange,
              displayDates: getFilterDisplayDates(dateRange),
            },
          };
        });
      },
      setFilter: (filter: InvoiceFilterType) => {
        set((prevState) => ({
          ...prevState,
          filter,
          currentPage: 1,
          displayDates: getFilterDisplayDates(filter.dateRange),
        }));
      },

      setOrder: (order: InvoiceState['order']) => {
        set((prevState) => {
          return {
            ...prevState,
            order,
          };
        });
      },

      setWorkflowIds: (workflowIds) => set((prevState) => ({ ...prevState, workflowIds })),
      setCurrentPage: (page: number) => set((prevState) => ({ ...prevState, currentPage: page })),
      resetCurrentPage: () => set((prevState) => ({ ...prevState, currentPage: 1, order: DEFAULT_ORDER })),
      setNumRows: (numRows: number) => set((prevState) => ({ ...prevState, numRows, currentPage: 1 })),
      setSelectedInvoiceId: (selectedInvoiceId: string | null) =>
        set((prevState) => ({ ...prevState, selectedInvoiceId })),
    };
  },
  { name: 'InvoiceStore' }
);

export const useInvoiceShallowStore = createShallowStore(useInvoiceStore);
