import { useCallback, useMemo } from 'react';
import { InfiniteData, useQueryClient } from 'react-query';
import { useAppScopeStore } from '@frontend/scope';
import { SearchFailedPaymentsParams, SearchFailedPaymentsResponse } from './types';
import { getFailedTransactionsInfiniteQueryKey } from './utils';

export const usePaginatedFailedInvoicesQueryData = ({
  searchParams,
  currentPage,
}: {
  searchParams: SearchFailedPaymentsParams;
  currentPage?: number;
}) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();

  const listQueryKey = getFailedTransactionsInfiniteQueryKey(selectedLocationIds, searchParams);

  const pageData = useMemo(() => {
    const result = queryClient.getQueryData<InfiniteData<SearchFailedPaymentsResponse>>(listQueryKey);
    return result;
  }, [JSON.stringify(listQueryKey), currentPage]);

  const endCursors = useMemo(() => {
    return pageData?.pages.map((page) => page.lastEdgeCursor) || [];
  }, [pageData]);

  const getPageEndCursor = useCallback(
    (page: number) => {
      return endCursors?.[page - 1] ?? undefined;
    },
    [endCursors]
  );

  const currentPageEndCursor = useMemo(() => {
    if (typeof currentPage !== 'number') {
      return undefined;
    }
    return getPageEndCursor(currentPage);
  }, [getPageEndCursor, currentPage]);

  const previousPageEndCursor = useMemo(() => {
    if (currentPage && currentPage > 1) {
      return getPageEndCursor(currentPage - 1);
    }

    return undefined;
  }, [currentPage, getPageEndCursor]);

  return { listQueryKey, endCursors, getPageEndCursor, currentPageEndCursor, previousPageEndCursor };
};
