import { useNavigate } from '@tanstack/react-location';
import { DeviceType } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { useQueryClient } from 'react-query';
import { useCustomContactFormSlidePanel } from '@frontend/create-contact-panel';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useSoftphoneWidgetControl } from '@frontend/softphone2';
import { useAlert } from '@frontend/design-system';

export type QuickActions = {
  [key: string]: {
    label: string;
    icon: JSX.Element;
    onClick: () => void;
  };
};

export const useQuickActions = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('contacts');
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setShow } = useCustomContactFormSlidePanel();
  const { navigate: navigateSettings } = useSettingsNavigate();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const hasSoftphoneEnabled = !!phoneConfig && phoneConfig.deviceType === DeviceType.DEVICE_TYPE_SOFTPHONE;
  const open = useSoftphoneWidgetControl((ctx) => ctx?.open);
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx.isOpen);

  const actions: QuickActions = {
    'New Call': {
      label: 'New Call',
      icon: <Icon name='phone-small' />,
      onClick: () => {
        if (hasSoftphoneEnabled) {
          if (!isOpen) open();
        } else {
          navigate({ to: '/calls/dial-pad' });
        }
      },
    },
    'New Message': {
      label: 'New Message',
      icon: <Icon name='messaging-small' />,
      onClick: () => navigate({ to: '/messages/inbox/new' }),
    },
    'New Fax': {
      label: 'New Fax',
      icon: <Icon name='fax-small' />,
      onClick: () => alert('Coming Soon'),
    },
    'Payment History': {
      label: 'Payment History',
      icon: <Icon name='history-small' />,
      onClick: () => navigate({ to: '/payments/invoices' }),
    },
    VMO: {
      label: 'VMO',
      icon: <Icon name='voicemail-small' />,
      onClick: () => navigateSettings({ to: '/phone/overrides' }),
    },
    'New Patient': {
      label: 'New Patient',
      icon: <Icon name='assign-user-small' />,
      onClick: () =>
        setShow({
          show: true,
          context: {
            mode: 'create',
            onSave: () => {
              alerts.success(t('Contact created successfully'));
              queryClient.invalidateQueries({ queryKey: [selectedLocationIds[0], 'contacts'] });
            },
            onError: () => {
              alerts.error(t('Contact creation error'));
            },
          },
        }),
    },
    'Phone Settings': {
      label: 'Phone Settings',
      icon: <Icon name='settings-small' />,
      onClick: () => navigateSettings({ to: '/phone/main' }),
    },
    Quickfill: {
      label: 'Quick Fill',
      icon: <Icon name='quickfill-small' />,
      onClick: () => alert('Coming Soon'),
    },
    'Office Hours': {
      label: 'Office Hours',
      icon: <Icon name='hourglass-small' />,
      onClick: () => navigateSettings({ to: '/schedule/office-hours' }),
    },
    'Send Form': {
      label: 'Send Form',
      icon: <Icon name='forms-small' />,
      onClick: () => navigate({ to: '/forms/library' }),
    },
  };

  return actions;
};
