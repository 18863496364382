import { PropsWithChildren, useMemo } from 'react';
import { useSearch } from '@tanstack/react-location';
import { i18next } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { humanizeDateTime } from '@frontend/shared';
import { TableColumnConfig } from '@frontend/design-system';
import { RoiPatient, UseFetchRoiPatientsProps } from '../../hooks';
import { MessagingRoi } from '../../types';
import { MessagingRoiTable, MessagingRoiTableProps } from './messaging-roi-table';

const entryDateConfig: TableColumnConfig<RoiPatient>[] = [
  {
    accessor: ({ entry_date }) => (entry_date ? humanizeDateTime(entry_date) : '-'),
    Header: i18next.t('Entry Date', { ns: 'analytics' }),
    id: 'entry_date',
    width: 180,
  },
];

const completionDateConfig: TableColumnConfig<RoiPatient>[] = [
  {
    accessor: ({ appts_completion_date }) => (appts_completion_date ? humanizeDateTime(appts_completion_date) : '-'),
    Header: i18next.t('Appt Completed Date', { ns: 'analytics' }),
    disableSortBy: true,
    id: 'appts_completion_date',
    width: 190,
  },
];

const leadsContactedMiddleColumnConfig: TableColumnConfig<RoiPatient>[] = [
  {
    accessor: ({ message_details }) => message_details,
    Header: i18next.t('Message Details', { ns: 'analytics' }),
    disableSortBy: true,
    id: 'message_details',
    width: 300,
  },
  ...entryDateConfig,
  {
    accessor: ({ receiver_number }) => receiver_number,
    cellRenderer: (value) => (value ? formatPhoneNumber(value) : '-'),
    Header: i18next.t('Receiver Number', { ns: 'analytics' }),
    id: 'receiver_number',
    width: 180,
  },
];

const patientsConvertedMiddleColumnConfig: TableColumnConfig<RoiPatient>[] = [
  {
    accessor: ({ practitioner_name }) => practitioner_name,
    Header: i18next.t('Practitioner Name', { ns: 'analytics' }),
    disableSortBy: true,
    id: 'practitioner_name',
    width: 200,
  },
  {
    accessor: ({ appointment_type }) => appointment_type,
    Header: i18next.t('Appointment Type', { ns: 'analytics' }),
    disableSortBy: true,
    id: 'appointment_type',
    width: 200,
  },
];

export const MessagingSubView = ({
  children,
  messagingCategory,
  ...rest
}: Pick<PropsWithChildren<MessagingRoiTableProps>, 'children' | 'filters' | 'isDemoAccount' | 'messagingCategory'>) => {
  const search = useSearch<{ Search: { view: MessagingRoi } }>();

  const config = useMemo(() => {
    let messagingSubCategory: UseFetchRoiPatientsProps['subCategory'] = 'getrawsms';
    let middleColumnConfig: TableColumnConfig<RoiPatient>[] = [];

    if (messagingCategory === 'bulkmessaging') {
      switch (search.view) {
        case 'leadsContacted':
        case 'patientsScheduled':
          messagingSubCategory = 'getrawsms';
          middleColumnConfig = leadsContactedMiddleColumnConfig;
          break;

        case 'revenueGenerated':
          messagingSubCategory = 'getconvertedpatients';
          middleColumnConfig = [...patientsConvertedMiddleColumnConfig, ...completionDateConfig];
          break;
      }
    } else {
      switch (search.view) {
        case 'leadsContacted':
          messagingSubCategory = 'getrawmctsms';
          middleColumnConfig = leadsContactedMiddleColumnConfig;
          break;

        case 'patientsScheduled':
          messagingSubCategory = 'getconvertedpatients';
          middleColumnConfig = [...patientsConvertedMiddleColumnConfig, ...entryDateConfig];
          break;

        case 'revenueGenerated':
          messagingSubCategory = 'getconvertedpatients';
          middleColumnConfig = [...patientsConvertedMiddleColumnConfig, ...completionDateConfig];
          break;
      }
    }

    return {
      messagingSubCategory,
      middleColumnConfig,
    };
  }, [messagingCategory, search.view]);

  return (
    <>
      {children}
      <MessagingRoiTable
        {...rest}
        messagingCategory={messagingCategory}
        messagingSubCategory={config.messagingSubCategory}
        messagingTableUniqueId={search.view ?? ''}
        middleColumnConfig={config.middleColumnConfig}
        trackingIdBase={search.view}
      />
    </>
  );
};
