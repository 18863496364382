import { i18next } from '@frontend/i18n';
import { FormValues, UseFormReturnType } from '@frontend/design-system';
import { DEFAULT_STANDARD_MEDIA } from '../../media-picker';
import { CallQueueCreatePayload, CallQueueRingType, CallQueueState } from './types';

export const schema = {
  // id: {
  //   type: 'text',
  //   required: false,
  // },
  name: {
    type: 'text',
    required: true,
    value: '' as string,
  },
  deviceRingType: {
    type: 'radio',
    required: true,
    value: CallQueueRingType.RING_TYPE_RING_ALL as string,
  },
  greetingEnabled: {
    type: 'checkbox',
    required: false,
    value: false as boolean,
  },
  greetingMediaId: {
    type: 'dropdown',
    required: false,
    value: '' as string,
  },
  allowExit: {
    type: 'checkbox',
    required: false,
    value: false as boolean,
  },
  holdMusicId: {
    type: 'dropdown',
    required: false,
    value: '' as string,
  },
  // This field is hidden from users
  holdMusicSystemMedia: {
    type: 'switch',
    required: false,
    value: false as boolean,
  },
  // assignedDevices: {
  //   type: 'multiselect',
  //   required: false,
  //   value: [] as string[],
  // },
  positionAnnouncementEnabled: {
    type: 'checkbox',
    required: false,
    value: false as boolean,
  },
  firstAnnouncementDelayMs: {
    type: 'dropdown',
    required: false,
    value: '0' as string,
  },
  repeatIntervalMs: {
    type: 'dropdown',
    required: false,
    value: '0' as string,
  },
  maxWaitTimeMs: {
    type: 'text',
    required: false,
    value: '0' as string,
  },
  locationIds: {
    type: 'multiselect',
    required: false,
    value: [] as string[],
  },
} as const;

export const populateSchema = (state: CallQueueCreatePayload) => {
  const res = { ...schema };

  let key: keyof typeof schema;
  for (key in schema) {
    if (key === 'maxWaitTimeMs') {
      res[key] = { ...res[key], value: (state[key] / 1000 / 60).toString() || '0' };
    } else if (key === 'repeatIntervalMs' || key == 'firstAnnouncementDelayMs') {
      res[key] = { ...res[key], value: ((state.positionAnnouncement?.[key] ?? 0) / 1000)?.toString() || '0' };
    } else if (key === 'holdMusicId') {
      res[key] = { ...res[key], value: state[key] || DEFAULT_STANDARD_MEDIA };
    } else if (key === 'holdMusicSystemMedia') {
      // Default to true since the default media selection is a system media
      res[key] = { ...res[key], value: state[key] ?? true };
    } else {
      // @ts-ignore - this is fine!
      res[key] = { ...res[key], value: state[key] };
    }
  }

  return res;
};

export const createSubmitPayload = ({
  formValues,
  state,
}: {
  formValues: FormValues<typeof schema>;
  state: CallQueueState;
}) => {
  const {
    firstAnnouncementDelayMs,
    repeatIntervalMs,
    maxWaitTimeMs,
    greetingMediaId,
    holdMusicId,
    deviceRingType,
    ...rest
  } = formValues;
  return {
    ...state,
    ...rest,
    // Convert `default` to empty string - `default` is for the media picker, but the API expects an empty string
    greetingMediaId: greetingMediaId === 'default' ? '' : greetingMediaId ?? '',
    holdMusicId: holdMusicId === 'default' ? '' : holdMusicId ?? '',
    positionAnnouncement: {
      firstAnnouncementDelayMs: parseInt(firstAnnouncementDelayMs || '0') * 1000,
      repeatIntervalMs: parseInt(repeatIntervalMs || '0') * 1000,
    },
    maxWaitTimeMs: parseInt(maxWaitTimeMs || '0') * 1000 * 60,
    deviceRingType: deviceRingType as CallQueueRingType,
  };
};

export type SchemaForm = UseFormReturnType<typeof schema, keyof typeof schema>;
export type SchemaFormValues = FormValues<typeof schema>;

const formatFirstAnnouncement = (time: number) => {
  if (time === 0) {
    return i18next.t('Immediate', { ns: 'phone' });
  }

  return `${time}s`;
};

const FIRST_ANNOUNCEMENT_DELAY_OPTIONS_STEP = 5;
const FIRST_ANNOUNCEMENT_DELAY_OPTIONS_MAX = 60;
export const FIRST_ANNOUNCEMENT_DELAY_OPTIONS = Array.from(
  { length: FIRST_ANNOUNCEMENT_DELAY_OPTIONS_MAX / FIRST_ANNOUNCEMENT_DELAY_OPTIONS_STEP + 1 },
  (_, i) => i * FIRST_ANNOUNCEMENT_DELAY_OPTIONS_STEP
).map((num) => ({
  value: num.toString(),
  label: formatFirstAnnouncement(num),
}));

const formatRepeatInterval = (time: number) => {
  if (time === 0) {
    return i18next.t('Never', { ns: 'phone' });
  }
  const min = Math.floor(time / 60);
  const sec = time % 60;
  const minuteStr = min > 0 ? `${min}m` : '';
  const secondStr = sec > 0 ? `${sec}s` : '';
  return `${minuteStr} ${secondStr}`.trim();
};

const REPEAT_INTERVAL_OPTIONS_STEP = 30;
const REPEAT_INTERVAL_OPTIONS_MAX = 180;
export const REPEAT_INTERVAL_OPTIONS = Array.from(
  { length: REPEAT_INTERVAL_OPTIONS_MAX / REPEAT_INTERVAL_OPTIONS_STEP + 1 },
  (_, i) => i * REPEAT_INTERVAL_OPTIONS_STEP
).map((num) => ({
  value: num.toString(),
  label: formatRepeatInterval(num),
}));
