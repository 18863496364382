import { DaysOfWeek } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { formatDate, getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { FormFieldActionTypes, useForm } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore } from '../../../hooks';
import { DATE_FORMAT, getNextMinTime, MAX_BATCH_RECIPIENTS, TIME_FORMAT } from '../../utils';
import { DayToSend, SendType } from '../types';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const defaultDaysToSend: DayToSend[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

const convertToDaysToSend = (daysOfWeek: DaysOfWeek) => {
  const values: DayToSend[] = [];
  let key: keyof DaysOfWeek;
  for (key in daysOfWeek) {
    if (daysOfWeek[key] === true) {
      values.push(key);
    }
  }
  return values;
};

export const useEmailForm = (readOnly: boolean) => {
  const { t } = useTranslation('bulk-messaging');

  const store = useBulkEmailEditorShallowStore(
    'attachments',
    'consent',
    'locationIds',
    'sendDaysOfWeek',
    'sendPerDay',
    'startSendAt',
    'title'
  );

  const todaysDate = getTodaysDate(DATE_FORMAT);
  const { getFieldProps, seedValues, validate, values } = useForm({
    computeChangedValues: true,
    fields: {
      title: {
        type: 'text',
        value: store.title,
        required: true,
        placeholder: t('Name Your Campaign'),
      },
      locationIds: {
        type: 'checklist',
        value: store.locationIds ?? [],
        required: true,
        placeholder: t('Select Locations'),
      },
      date: {
        type: 'datePicker',
        value: formatDate(store.startSendAt, DATE_FORMAT),
        required: !readOnly,
        minDate: todaysDate,
        maxDate: dayjs().add(1, 'year').format(DATE_FORMAT),
      },
      time: {
        type: 'time',
        value: formatDate(store.startSendAt, TIME_FORMAT) || getNextMinTime(todaysDate, dayjs().hour()),
        required: !readOnly,
        hidden: !store.startSendAt,
      },
      sendOptions: {
        type: 'radio',
        value: store.sendPerDay > 0 ? SendType.SendSpecific : SendType.SendAll,
        required: !readOnly,
        hidden: !store.startSendAt,
      },
      sendInterval: {
        type: 'number',
        value: store.sendPerDay > 0 ? store.sendPerDay.toString() : '',
        min: 1,
        max: MAX_BATCH_RECIPIENTS, // TODO: What is the maximum number of recipients?
      },
      daysToSend: {
        type: 'checklist',
        value: store.sendDaysOfWeek ? convertToDaysToSend(store.sendDaysOfWeek) : defaultDaysToSend,
        required: !readOnly,
        hidden: store.sendPerDay === 0,
      },
      attachments: {
        type: 'multiselect',
        value: store.attachments,
        hidden: store.locationIds.length > 1,
      },
      consent: {
        type: 'checkbox',
        required: !readOnly,
        hidden: readOnly,
        value: store.consent,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type !== FormFieldActionTypes.Update) return null;

      if (action.payload.name === 'date') {
        return {
          ...state,
          // TODO: Fix the time dropdown when switching between dates
          time: {
            ...state.time,
            minTime: state.date.value === todaysDate ? getNextMinTime(state.date.value as string) : '0:00',
            hidden: action.payload.value === '',
          },
          sendOptions: {
            ...state.sendOptions,
            hidden: action.payload.value === '',
          },
          daysToSend: {
            ...state.daysToSend,
            hidden: action.payload.value === '' || state.sendOptions.value !== SendType.SendSpecific,
          },
        };
      }

      if (action.payload.name === 'sendOptions') {
        return {
          ...state,
          daysToSend: {
            ...state.daysToSend,
            hidden: action.payload.value !== SendType.SendSpecific,
          },
        };
      }

      return state;
    },
  });

  const formProps = {
    attachments: getFieldProps('attachments'),
    consent: getFieldProps('consent'),
    date: getFieldProps('date'),
    daysToSend: getFieldProps('daysToSend'),
    locationIds: getFieldProps('locationIds'),
    sendInterval: getFieldProps('sendInterval'),
    sendOptions: getFieldProps('sendOptions'),
    time: getFieldProps('time'),
    title: getFieldProps('title'),
  };

  return {
    formProps,
    seedValues,
    validateForm: validate,
    values,
  };
};
