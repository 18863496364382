import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import EmailPlaceholder from './email-placeholder.svg';

type EmailThumbnailProps = {
  alt?: string;
  height?: number;
  onClick?: () => void;
  src?: string;
  width?: number;
};

export const EmailThumbnail = ({ alt, height, onClick, src, width, ...rest }: EmailThumbnailProps) => {
  const Container = onClick ? NakedButton : 'div';
  return (
    <Container
      css={{
        background: theme.colors.white,
        border: src ? `solid 1px ${theme.colors.neutral20}` : undefined,
        borderRadius: theme.borderRadius.small,
        height: src ? height : undefined,
        overflow: 'hidden',
        width,
      }}
      onClick={onClick}
      {...rest}
    >
      <img src={src ? src : EmailPlaceholder} className='thumbnail-img' alt={alt} />
    </Container>
  );
};
