import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { BaseFieldProps, Button, ContentLoader, Modal, NakedButton, Text, useForm } from '@frontend/design-system';
import { useEmailAssistant } from './use-email-assistant';

export const GeneratedTextModalContent = ({
  onClose,
  onComplete,
  setMode,
  subject = '',
  previousText = '',
  promptProps,
  setPreviousText,
}: {
  onClose: () => void;
  onComplete: (body: string) => void;
  setMode: (mode: 'prompt' | 'generated') => void;
  subject?: string;
  previousText?: string;
  promptProps: BaseFieldProps<string>;
  setPreviousText: (text?: string) => void;
}) => {
  const { t } = useTranslation('email-composer');
  const prompt = promptProps.value;
  const footerRef = useRef<HTMLDivElement>(null);

  const { getFieldProps } = useForm({
    fields: {
      text: { type: 'text', value: previousText },
    },
  });
  const textFieldProp = getFieldProps('text');

  const blurFooterButtons = () => {
    const footer = footerRef.current;
    if (!footer) return;

    const buttons = footer.querySelectorAll('button');
    buttons.forEach((button) => button.blur());
  };

  useEffect(() => {
    const footerElement = footerRef.current;
    if (!footerElement) return;

    footerElement.addEventListener('click', blurFooterButtons);

    return () => {
      footerElement.removeEventListener('click', blurFooterButtons);
    };
  }, [footerRef.current]);

  const {
    generateBody,
    body = '',
    bodyRefinements,
    refineBody,
    undoBodyGeneration,
    redoBodyGeneration,
    canUndoBodyGeneration,
    canRedoBodyGeneration,
    isLoading,
    reset,
  } = useEmailAssistant({
    subject,
    body: previousText ? [{ value: previousText, type: 'text' }] : [],
  });

  const startOver = () => {
    setMode('prompt');
    setPreviousText(undefined);
    reset();
  };

  useEffect(() => {
    if (prompt === '') {
      setMode('prompt');
      return;
    }
    if (!previousText) {
      generateBody(prompt);
    }
  }, [prompt]);

  useEffect(() => {
    if (body) {
      getFieldProps('text').onChange({ ...getFieldProps('text'), value: body });
    }
  }, [body]);

  return (
    <>
      <Modal.Header
        onClose={onClose}
        css={css`
          padding-bottom: ${theme.spacing(2)};
        `}
      >
        <Text
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {t('This text was generated based on your prompt.')}
        </Text>
        <Text weight='regular'>{t('What do you think?')}</Text>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <Text
            as='div'
            css={css`
              height: 100px;
            `}
          >
            <ContentLoader show={isLoading} />
          </Text>
        )}
        {textFieldProp.value && !isLoading && (
          <Text
            as='div'
            css={css`
              padding: ${theme.spacing(2)};
              border-radius: ${theme.borderRadius.small};
              border: solid 1px ${theme.colors.neutral20};
            `}
          >
            <ContentEditable
              id={textFieldProp.id}
              html={textFieldProp.value}
              onChange={(e) => textFieldProp.onChange({ ...textFieldProp, value: e.target.value })}
              css={css`
                outline: none;
                max-height: 400px;
                padding-bottom: ${theme.spacing(2)};
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                  display: none;
                }
                p:not(:last-of-type) {
                  margin-bottom: ${theme.spacing(2)};
                }
              `}
            />
            <footer
              ref={footerRef}
              css={css`
                display: flex;
                justify-content: space-between;
                border-top: solid 1px ${theme.colors.neutral20};
                padding-top: ${theme.spacing(2)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  column-gap: ${theme.spacing(1)};
                `}
              >
                {bodyRefinements?.map((refinement) => (
                  <Button variant='secondary' key={refinement} onClick={() => refineBody(refinement)}>
                    {refinement}
                  </Button>
                ))}
              </div>
              <div
                css={css`
                  display: flex;
                  column-gap: ${theme.spacing(1)};
                `}
              >
                <Button variant='secondary' disabled={!canUndoBodyGeneration} onClick={undoBodyGeneration}>
                  <Icon name='send-back-small' />
                </Button>
                <Button
                  variant='secondary'
                  disabled={!canRedoBodyGeneration}
                  onClick={redoBodyGeneration}
                  css={css`
                    transform: rotate(180deg);
                  `}
                >
                  <Icon name='send-back-small' />
                </Button>
              </div>
            </footer>
          </Text>
        )}
      </Modal.Body>
      <Modal.Actions
        backLabel={
          <NakedButton
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <Icon name='send-back-small' color='primary' />
            <Text as='span' color='primary'>
              {t('Start Over')}
            </Text>
          </NakedButton>
        }
        onBackClick={startOver}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        primaryLabel={t('Insert')}
        disablePrimary={!textFieldProp.value || isLoading}
        onPrimaryClick={() => {
          onComplete(textFieldProp.value);
          onClose();
        }}
      />
    </>
  );
};
