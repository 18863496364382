import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export const PREFIX_TRACKING_ID = 'high-value-adoption';

export const buildTrackingId = ({
  feature,
  source,
  trackingId,
}: {
  feature: Feature;
  source?: string;
  trackingId: string;
}) => {
  return [PREFIX_TRACKING_ID, feature, source, trackingId].filter((str) => !!str).join('-');
};
