import { UseQueryOptions } from 'react-query';
import { VoicemailConfigAPI } from '@frontend/api-voicemail-boxes';
import { useQuery } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { WeaveLocation, WeaveLocationGroup } from '@frontend/scope';
import { queryKeys } from '../../../query-keys';

type VoicemailBoxListResponse = SchemaIO<(typeof VoicemailConfigAPI)['List']>['output'];

const useVoicemailBoxes = ({
  settingsTenantLocation,
  options = {},
}: {
  settingsTenantLocation: WeaveLocation | WeaveLocationGroup | undefined;
  options?: UseQueryOptions<VoicemailBoxListResponse, unknown, VoicemailBoxListResponse['voicemailBoxes']>;
}) => {
  const phoneTenantId = settingsTenantLocation?.phoneTenantId ?? '';
  return useQuery({
    queryKey: [phoneTenantId, ...queryKeys.settings.listVoicemailBox()],
    queryFn: () => VoicemailConfigAPI.List({ tenantId: phoneTenantId }),
    select: ({ voicemailBoxes }) => voicemailBoxes.sort((a, b) => a.mailbox.name.localeCompare(b.mailbox.name)),
    enabled: !!phoneTenantId,
    staleTime: 5 * 60 * 1000,
    ...options,
  });
};

export default useVoicemailBoxes;
