import { Location } from '@weave/schema-gen-ts/dist/schemas/location/v2/data-location/messages.pb';
import { Organization } from '@weave/schema-gen-ts/dist/schemas/organization/v1/org.pb';
import { Tenant } from '@weave/schema-gen-ts/dist/schemas/phone/tenant/tenant_api.pb';
import { Bool_Enum } from '@weave/schema-gen-ts/dist/shared/null/types.pb';
import { WeaveLocation, WeaveLocationGroup } from './types';
import { attachPhoneLocationTypeToGroup } from './utils';

export function rebase({ parentId, ...location }: Location): WeaveLocation {
  // Deliberately remove parent id from location object in favor of groupId

  return {
    ...location,
    id: location.locationId ?? '',
    // Keeping this for backwards compatibility - use `id` instead
    locationId: location.locationId ?? '',
    locationType: undefined,
    groupId: parentId ?? undefined,
    phoneTenantId: location.phoneTenantId ?? undefined,
    orgId: location.orgId ?? undefined,
    accessible: false,
    active: location.active === Bool_Enum.TRUE ? true : false,
    opsType: location.opsType ?? 'OPS_TYPE_INVALID',
  } as unknown as WeaveLocation;
}

export const createLocationDraftObject = (
  group: WeaveLocation,
  childrenIds: string[],
  locationMap: Record<string, WeaveLocation>
) => {
  const { groupId, ...rest } = group;
  return {
    ...rest,
    /**
     * This getter ensures that we only have one copy of each location in the working draft.
     * When querying for children, this getter will reference the locationMap object to retrieve the children.
     *
     * The other approach would be to keep a copy of the children here, but that would require us to keep the children in sync with the locationMap object.
     */
    get children() {
      return childrenIds.map((id) => locationMap[id]);
    },
  };
};

function addTenantId({
  tenants,
  locationMapDraft,
}: {
  tenants: Tenant[];
  locationMapDraft: Record<string, WeaveLocation>;
}) {
  tenants.forEach((tenant) => {
    const tenantId = tenant.id;
    tenant.locations.forEach((location) => {
      if (locationMapDraft[location.id]) {
        locationMapDraft[location.id].phoneTenantId = tenantId;
      }
    });
  });
}

function addLocationType({
  list,
  locationMapDraft,
}: {
  list: WeaveLocationGroup[];
  locationMapDraft: Record<string, WeaveLocation>;
}) {
  list.forEach((group) => {
    attachPhoneLocationTypeToGroup(group, locationMapDraft);
  });
}

/**
 * Mark locations as accessible based on the allowed location ids. If allowedLocationIds is not provided, all locations are marked as accessible.
 * Users should only see and participate in accesisble locations.
 */
function markAccessible({
  locationMapDraft,
  allowedLocationIds,
}: {
  locationMapDraft: Record<string, WeaveLocation>;
  allowedLocationIds?: string[];
}) {
  Object.values(locationMapDraft).forEach((location) => {
    location.accessible = location.active && (allowedLocationIds ? allowedLocationIds.includes(location.id) : true);
  });
}

/**
 * This function is responsible for augmenting the location data that will be made available to the weave app.
 *
 * Locations begin as a flat list with no hierarchy. We can determine relationships between locations by comparing the `locationId` and `parentId` fields.
 *
 * The source of truth is the `locationMapDraft` object. All modifications should be made in place (mutating the object as opposed to recreating it).
 * There are 2 phases to this pipeline: Before and after the hierarchy is introduced to these locations. Any mutations should take place in one of these 2 phases.
 * It might be more convenient to introduce modifications before the hierarchy is introduced, but there may be be occasions where you need the hierarchy to determine what modifications to make.
 *
 * The final return value is the state of the working draft at the end of the pipeline.
 */
export function locationDataAugmentationPipeline({
  tenants,
  locations,
  allowedLocationIds,
}: {
  tenants: Tenant[];
  locations: WeaveLocation[];
  allowedLocationIds?: string[];
}) {
  /**
   * THIS IS OUR SOURCE OF TRUTH
   *
   * All modifications should be made in place (mutating the object as opposed to recreating it), either BEFORE or AFTER
   * the location hierarchy has been established
   */
  const locationMapDraft = locations.reduce((acc, location) => {
    const copy = { ...location };
    acc[copy.id] = copy;
    return acc;
  }, {} as Record<string, WeaveLocation & { keep?: boolean }>);

  /**
   * BEFORE HIERARCHY -- START
   */

  markAccessible({ locationMapDraft, allowedLocationIds });
  addTenantId({ tenants, locationMapDraft });

  /**
   * BEFORE HIERARCHY -- END
   */

  /**
   * Introduce parent-child hierarchy
   * The initial list is a flat list of locations
   * We need to group them into parent-child relationships by comparing the `locationId` and `parentId` fields
   */
  const [groups, childrenLocations] = Object.values(locationMapDraft).reduce<[WeaveLocation[], WeaveLocation[]]>(
    (acc, location) => {
      acc[location.groupId ? 1 : 0].push(location);
      return acc;
    },
    [[], []]
  );

  /**
   * Each item in this list is a group. Each group has its own list of locations.
   * Single locations will be grouped under themselves. Meaning that we create a copy of the single location, then make it a group with itself as a child.
   *
   * Single location example:
   * {
   *    id: 'loc-1',
   *    name: 'Location 1',
   *    children: [
   *      {
   *         id: 'loc-1',
   *         name: 'Location 1',
   *         groupId: 'loc-1'
   *      }
   *    ]
   * }
   */
  const hierarchicalList: WeaveLocationGroup[] = groups.map((group) => {
    const childrenIds = childrenLocations.filter((l) => l.groupId === group.id).map((c) => c.id);

    if (childrenIds.length === 0) {
      // If there are no children, the group is a single location, so we add itself to the childrenIds
      childrenIds.push(group.id);
    }

    // This draft object helps ensure that we only have one copy of each location in the working draft
    return createLocationDraftObject(group, childrenIds, locationMapDraft);
  });

  /**
   * AFTER HIERARCHY -- START
   */

  addLocationType({ list: hierarchicalList, locationMapDraft });

  /**
   * AFTER HIERARCHY -- END
   */

  const hierarchy = hierarchicalList.reduce((acc, location) => {
    acc[location.id] = location.children.length ? location.children.map((child) => child.id) : [location.id];
    return acc;
  }, {} as Record<string, string[]>);

  /**
   * To create the final lists we need to consider single locations as groups of just themselves.
   * When creating the `hierachicalList`, we've added the single location to a location group that is itself.
   * We now need to add a `groupId` field to the location (not the group) to make it a WeaveLocation
   */

  // The empty JSDoc comment below is just to prevent the comment above from attaching the `finalList`
  /**
   *
   */
  const finalList = hierarchicalList.reduce((acc, group) => {
    if (group.children.length === 1 && group.id === group.children[0].id) {
      const { children, ...rest } = group;
      const location = children[0];
      // Remove the getter from `createLocationDraftObject`, and add groupId
      acc.push({ ...group, children: [{ ...location, groupId: group.id }] });
      acc.push({ ...rest, groupId: group.id } satisfies WeaveLocation);
    } else {
      // Remove the getter from `createLocationDraftObject`
      acc.push({ ...group, children: [...group.children] });
      acc.push(...group.children);
    }
    return acc;
  }, [] as (WeaveLocationGroup | WeaveLocation)[]);

  const groupMap = hierarchicalList.reduce((acc, group) => {
    acc[group.id] = group;
    return acc;
  }, {} as Record<string, WeaveLocationGroup>);

  const locationMap = hierarchicalList.reduce((acc, group) => {
    if (group.children.length === 1 && group.children[0].id === group.id) {
      const { children, ...location } = group;
      // Add groupId to the single location as well
      acc[group.id] = { ...location, groupId: group.id };
    } else {
      group.children.forEach((location) => {
        acc[location.id] = location;
      });
    }
    return acc;
  }, {} as Record<string, WeaveLocation>);

  /**
   * Why do we need `groupMap` and `locationMap`? Why not one map for both locations and groups?
   *
   * Because single location groups are groups of just itself, there will be id conflicts where the group id is the same as the location id.
   * You can never know if the id will refer to a group or a location.
   */

  return { locationList: finalList, locationMap, groupMap, hierarchy };
}

export const processOrgData = ({
  orgData,
  locationHierarchy,
  groupMap,
}: {
  orgData: Organization[];
  locationHierarchy: Record<string, string | string[]>;
  groupMap: Record<string, WeaveLocationGroup>;
}) => {
  const orgMap =
    orgData?.reduce((acc, org) => {
      if (org.id) {
        acc[org.id] = org;
      }
      return acc;
    }, {} as Record<string, Organization>) ?? {};

  const groupIds = Object.keys(locationHierarchy);

  const orgHierarchy = groupIds?.reduce((acc, groupId) => {
    const group = groupMap[groupId];

    if (group.orgId && acc[group.orgId]) {
      acc[group.orgId][group.id] = locationHierarchy[groupId];
    } else if (group.orgId) {
      acc[group.orgId] = {
        [group.id]: locationHierarchy[groupId],
      };
    }

    return acc;
  }, {} as Record<string, Record<string, string[] | string>>);

  return {
    orgMap,
    orgHierarchy,
  };
};
