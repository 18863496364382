import { memo } from 'react';
import { css } from '@emotion/react';
import { Handle, Position } from '@xyflow/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';
import {
  hasCallGroupExpansion,
  hasCallQueueExpansion,
  hasDeviceExpansion,
  hasForwardingNumberExpansion,
  hasPhoneHoursExpansion,
  hasPhoneTreeExpansion,
  hasPlayMessageExpansion,
  hasVoicemailExpansion,
  SharedNodeProps,
} from '../data';
import { BaseRoutingNode } from './routing-node';
import { draggableStyles } from './styles';

export const CallGroupNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  const { t } = useTranslation('phone');
  const callObject = hasCallGroupExpansion(data.callObject) ? data.callObject : undefined;

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='call-group'
      title='Call Group'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={(id) => {
        console.log('delete', id);
      }}
      onClickEdit={(id) => {
        console.log('edit', id);
      }}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      <LabelItem title={t('Caller Label')} label={callObject?.callGroupExpansion.callerLabel || '--'} />
    </BaseRoutingNode>
  );
});
CallGroupNode.displayName = 'CallGroupNode';

export const CallQueueNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const { t } = useTranslation('phone');

  const callObject = hasCallQueueExpansion(data.callObject) ? data.callObject : undefined;

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='call-queue'
      title='Call Queue'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      <>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(1)};
          `}
        >
          <LabelItem title={t('Caller Label')} label={callObject?.callQueueExpansion.callerLabel || '--'} />

          <LabelItem title={t('Greeting')} label={callObject?.callQueueExpansion.greetingMediaFileName || '--'} />

          {AudioPlayerComponent &&
            callObject?.callQueueExpansion.greetingMediaItemId &&
            callObject?.callQueueExpansion.downloadUrl && (
              <AudioPlayerComponent mediaItemId={callObject?.callQueueExpansion.greetingMediaItemId} />
            )}
        </div>
      </>
    </BaseRoutingNode>
  );
});
CallQueueNode.displayName = 'CallQueueNode';

export const CallRouteNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='call-route'
      title='Call Route'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    ></BaseRoutingNode>
  );
});
CallRouteNode.displayName = 'CallRouteNode';

export const ForwardingDeviceNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  const { t } = useTranslation('phone');

  const callObject = hasDeviceExpansion(data.callObject) ? data.callObject : undefined;

  const ringDuration = (callObject?.deviceExpansion.timeout ?? 0) / 1000;
  const ringDurationText = ringDuration ? `${t('{{duration}} seconds', { duration: ringDuration })}` : '--';

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='cell-phone'
      title='Device'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <LabelItem title={t('Ring duration')} label={ringDurationText} />

        <LabelItem title={t('Caller Label')} label={callObject?.deviceExpansion.callerLabel || '--'} />
      </div>
    </BaseRoutingNode>
  );
});
ForwardingDeviceNode.displayName = 'ForwardingDeviceNode';

export const ForwardingNumberNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  const { t } = useTranslation('phone');

  const callObject = hasForwardingNumberExpansion(data.callObject) ? data.callObject : undefined;

  const ringDuration = (callObject?.forwardingNumberExpansion?.timeout ?? 0) / 1000;
  const ringDurationText = ringDuration
    ? `${t('{{duration}} seconds', { duration: ringDuration })}`
    : t('Ring until answered');

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='send-forward'
      title='Forwarding Number'
      label={data.label}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      <LabelItem title={t('Ring duration')} label={ringDurationText} />
    </BaseRoutingNode>
  );
});
ForwardingNumberNode.displayName = 'ForwardingNumberNode';

export const OfficeHoursNode = memo(({ data, type, onClickLink, DayHoursListComponent }: SharedNodeProps) => {
  const callObject = hasPhoneHoursExpansion(data.callObject) ? data.callObject : undefined;

  const phoneHours = callObject?.phoneHoursExpansion?.phoneHours ?? [];

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='clock'
      title='Phone Hours'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      {DayHoursListComponent && !!phoneHours.length && (
        <div
          // Need to apply the "nowheel" to allow scrolling inside the custom node
          // From react-flow docs: "If you have scroll containers inside your custom node
          // you can add the class nowheel to disable the default canvas pan behaviour when
          // scrolling inside your custom nodes."
          className='nowheel'
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: 316px;
            overflow-y: scroll;
          `}
        >
          <DayHoursListComponent phoneHours={phoneHours} />
        </div>
      )}
    </BaseRoutingNode>
  );
});
OfficeHoursNode.displayName = 'OfficeHoursNode';

export const PhoneTreeNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const { t } = useTranslation('phone');
  const callObject = hasPhoneTreeExpansion(data.callObject) ? data.callObject : undefined;
  const { timeout, maxFailures, maxTimeouts, promptMediaFileName, promptMediaItemId } =
    callObject?.phoneTreeExpansion || {};

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='phone-tree'
      title='Phone Tree'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <LabelItem title={t('Phone Tree Prompt')} label={promptMediaFileName || '--'} />

        {AudioPlayerComponent && promptMediaItemId ? (
          <AudioPlayerComponent mediaItemId={promptMediaItemId} />
        ) : undefined}

        <LabelItem title={t('Timeout after')} label={((timeout ?? 0) / 1000).toFixed(0) + ' seconds'} />

        <LabelItem title={t('Max timeouts')} label={maxTimeouts || '--'} />

        <LabelItem title={t('Max invalid selections')} label={maxFailures || '--'} />
      </div>
    </BaseRoutingNode>
  );
});
PhoneTreeNode.displayName = 'PhoneTreeNode';

export const TreeOptionNode = memo(({ data }: SharedNodeProps) => {
  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <div
        css={[
          css`
            width: 24px;
            height: 24px;
            background-color: ${theme.colors.neutral90};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          `,
          draggableStyles,
        ]}
      >
        <Text as='span' color='white' weight='bold' size='small'>
          {data.label}
        </Text>
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </>
  );
});
TreeOptionNode.displayName = 'TreeOptionNode';

export const PlayNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const callObject = hasPlayMessageExpansion(data.callObject) ? data.callObject : undefined;
  const mediaItemId = callObject?.playMessageExpansion?.mediaItemId;

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='play'
      title='Play Message'
      label={data.label}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      {AudioPlayerComponent && mediaItemId ? <AudioPlayerComponent mediaItemId={mediaItemId} /> : undefined}
    </BaseRoutingNode>
  );
});
PlayNode.displayName = 'PlayNode';

export const VoicemailNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const { t } = useTranslation('phone');
  const callObject = hasVoicemailExpansion(data.callObject) ? data.callObject : undefined;
  const greetingId = callObject?.voicemailExpansion?.greetingMediaItemId;
  const downloadUrl = callObject?.voicemailExpansion?.downloadUrl;

  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='voicemail'
      title={'Voicemail'}
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <LabelItem title={t('Greeting')} label={callObject?.voicemailExpansion?.greetingMediaFileName || '--'} />
        {AudioPlayerComponent && greetingId && downloadUrl && (
          <AudioPlayerComponent mediaItemId={greetingId} downloadUrl={downloadUrl} />
        )}
      </div>
    </BaseRoutingNode>
  );
});
VoicemailNode.displayName = 'VoicemailNode';

export const RepeatNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      nodeId={data.id}
      icon='automated-message'
      title={'Repeat Phone Tree'}
      label={data.label}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={() => onClickLink?.({ callObjectId: data.callObject?.primitiveId ?? '', nodeId: data.id, type })}
    />
  );
});
RepeatNode.displayName = 'RepeatNode';

export const FallbackNode = memo(({ data }: SharedNodeProps) => {
  const { t } = useTranslation('phone');
  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };

  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  return (
    <div css={draggableStyles} {...triggerProps}>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <div
        style={{
          height: 24,
          padding: theme.spacing(0.25, 1),
          backgroundColor: theme.colors.neutral90,
          borderRadius: theme.borderRadius.small,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text as='span' color='white' size='medium'>
          {data.label}
        </Text>
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <Tooltip
        {...tooltipProps}
        css={css`
          font-weight: 400;
        `}
      >
        {t(
          'The fallback is triggered when either the Phone Tree times out or the caller exceeds the maximum number of invalid selections.'
        )}
      </Tooltip>
    </div>
  );
});
FallbackNode.displayName = 'FallbackNode';

const LabelItem = ({ title, label }: { title: string; label?: string | number }) => {
  return (
    <div>
      <Text size='small' color='subdued'>
        {title}
      </Text>
      <Text size='medium'>{label}</Text>
    </div>
  );
};
