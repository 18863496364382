import { cloneElement, ComponentProps, FC, ReactElement } from 'react';
import { PaymentHighlightedAction } from '@frontend/contact-actions-payment';
import { PopoverDialog, usePopoverDialog } from '@frontend/design-system';
import { getPaymentHVATrackingIds, PaymentsPromoButtonTrackingIds } from '../../tracking-ids';
import { PaymentsProviderWrapper } from '../common/PaymentsProviderWrapper';
import { AppointmentPopoverContent } from './AppointmentPopoverContent';

interface PaymentsAppointmentPromoWrapperProps {
  locationId: string;
  onSettingsOpen?: () => void;
  children: ReactElement<ComponentProps<typeof PaymentHighlightedAction>>;
}

const PROMOTION_SOURCE = 'appointments';

export const PaymentsAppointmentPromoWrapper: FC<PaymentsAppointmentPromoWrapperProps> = ({
  children,
  locationId,
  onSettingsOpen,
}) => {
  const {
    getTriggerProps,
    close: closePopover,
    getDialogProps,
  } = usePopoverDialog({
    placement: 'top-end',
  });

  const trackingId = getPaymentHVATrackingIds({
    source: PROMOTION_SOURCE,
    trackingId: PaymentsPromoButtonTrackingIds.REQUEST_PAYMENT,
  });

  const componentWithPromoTrigger = cloneElement(children, {
    trackingId,
    showPulseAnimation: true,
    triggerProps: getTriggerProps() as any,
  });
  return (
    <PaymentsProviderWrapper locationId={locationId} source={PROMOTION_SOURCE} fallback={children}>
      {componentWithPromoTrigger}
      <PopoverDialog {...getDialogProps()}>
        <AppointmentPopoverContent onSettingsOpen={() => onSettingsOpen?.()} onClose={closePopover} />
      </PopoverDialog>
    </PaymentsProviderWrapper>
  );
};
