import { InvoiceHistoryActionType } from '@weave/schema-gen-ts/dist/schemas/payments/messages/values/values.pb';
import { InvoiceHistory } from '@weave/schema-gen-ts/dist/schemas/payments/views/service/service.pb';
import { useTranslation } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { paymentTypeMap } from '@frontend/payments-hooks';
import { formatPhoneNumber } from '@frontend/phone-numbers';

export const useGetRequestActivityMap = (action: InvoiceHistory): { icon: IconName; title: string } => {
  const { t } = useTranslation('payments');

  const typeMap = {
    [InvoiceHistoryActionType.ACTION_CREATED]: {
      icon: 'plus' as IconName,
      title: t('Payment request created'),
    },
    [InvoiceHistoryActionType.ACTION_PAYMENT_SUCCESSFUL]: {
      icon: 'payments' as IconName,
      title: `${action.origin ? paymentTypeMap[action?.origin] : 'Unknown'} ${t('Payment Taken')}`,
    },
    [InvoiceHistoryActionType.ACTION_PAYMENT_FAILED]: {
      icon: 'pay' as IconName,
      title: `${action.origin ? paymentTypeMap[action?.origin] : 'Unknown'} ${t('Payment Failed')}`,
    },
    [InvoiceHistoryActionType.ACTION_REFUND]: {
      icon: 'refund' as IconName,
      title: t('Payment Refunded'),
    },
    [InvoiceHistoryActionType.ACTION_PARTIAL_REFUND]: {
      icon: 'refund' as IconName,
      title: t('Payment Partially Refunded'),
    },
    [InvoiceHistoryActionType.ACTION_REMINDER]: {
      icon: 'messaging' as IconName,
      title: `${t('Payment Link sent to ')} ${formatPhoneNumber(action.phoneNumber)}`,
    },
    [InvoiceHistoryActionType.ACTION_CANCELLATION]: {
      icon: 'remove' as IconName,
      title: t('Payment Cancelled'),
    },
    [InvoiceHistoryActionType.ACTION_UPDATED]: {
      icon: 'update' as IconName,
      title: t('Payment Updated'),
    },
    [InvoiceHistoryActionType.ACTION_PROCESSING]: {
      icon: 'pending' as IconName,
      title: t('Payment Pending'),
    },
    [InvoiceHistoryActionType.UNKNOWN_ACTION_TYPE]: {
      icon: 'payments-receipt' as IconName,
      title: t('Unknown activity'),
    },
  };

  return action?.actionType
    ? typeMap[action?.actionType]
    : {
        icon: 'payments-receipt' as IconName,
        title: t('Unknown activity'),
      };
};
