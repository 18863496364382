import { forwardRef, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { EntityType } from '@weave/schema-gen-ts/dist/schemas/intent-classifier/events/v1/event_types.pb';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { MediaQueries } from '@frontend/api-media';
import { SchemaSMSSharedEnums, TextWritebacksQueries } from '@frontend/api-messaging';
import { NotificationQueries } from '@frontend/api-notifications';
import { PersonHelpers } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { TagsUtils } from '@frontend/api-tag';
import { getUser } from '@frontend/auth-helpers';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { useSettingsNavigate, allowedNotificationTypes } from '@frontend/settings-routing';
import { sentenceCase } from '@frontend/string';
import { TagSelectionComponents, TagSelectionHooks } from '@frontend/tag-selection';
import { sentry } from '@frontend/tracking';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { UsersTypes } from '@frontend/user-helpers';
import { theme } from '@frontend/theme';
import {
  Avatar,
  CheckboxField,
  ConfirmationModal,
  KeyNames,
  useFormField,
  useModalControl,
  Text,
  useTooltip,
  useAlert,
  Chat,
} from '@frontend/design-system';
import { HIDE_DELETE_MODAL, SMART_TAG_FEEDBACK_THRESHOLDS } from '../../../../../constants';
import { useBubbleActions, useMessagePersonNames } from '../../../../../hooks';
import { SentMessage } from '../../../../../types';
import { messageHasTextWriteback } from '../../../../../utils';
import { TextWritebackStatus } from '../../text-writeback-status';
import { SMSTag } from './sms-tag';

const TAG_ALERT_DELAY = 3500;

type SMSBubbleProps = {
  message: SentMessage;
  personData?: {
    personId: string;
    firstName: string;
    lastName: string;
    preferredName?: string;
  };
  sender?: UsersTypes.UserProfile;
  deleter?: UsersTypes.UserProfile;
  showFullStatus?: boolean;
  isSending?: boolean;
  groupId: string;
  threadId: string;
  locationTags?: Tag[];
  mediaQueries: Record<string, MediaQueries.UseMmsMediaItem>;
};

export const SMSBubble = forwardRef<HTMLUListElement, SMSBubbleProps>(
  ({ message, personData, showFullStatus, isSending, groupId, threadId, locationTags, mediaQueries }, ref) => {
    const { t } = useTranslation('inbox');
    const alert = useAlert();
    const modalControl = useModalControl();
    const { navigate: settingsNavigate } = useSettingsNavigate();
    const { isActive: selectionIsActive } = useBulkListContext();
    const modalCheckboxProps = useFormField({
      type: 'checkbox',
    });
    const [tagSearchValue, setTagSearchValue] = useState<string>('');
    const tagSelectionInputRef = useRef<HTMLInputElement>(null);
    const tagInputWidthCalcRef = useRef<HTMLSpanElement>(null);
    const [tagInputWidth, setTagInputWidth] = useState(0);
    const tagIds = message.tagsDetailed?.map((tag) => tag.tagId) ?? [];
    const user = getUser();
    const { updateSMS } = SMSDataV3._QueryUpdaters.useQueryUpdaters();
    const { data: notificationSettings } = NotificationQueries.useNotificationSettingsQuery();
    const smsTagNotificationId = allowedNotificationTypes.find(({ type }) => type === 'sms-tag')?.id;
    const hasSMSTagNotificationEnabled =
      smsTagNotificationId && !!notificationSettings?.find(({ id }) => id === smsTagNotificationId)?.sendNotification;
    const {
      modalProps: feedbackModalProps,
      onFeedbackClick,
      closeModal: closeFeedbackModal,
      userEntitiesWithFeedback,
    } = TagSelectionHooks.useTagFeedbackFlow({
      feedbackClickThreshold: SMART_TAG_FEEDBACK_THRESHOLDS,
    });
    const hideFeedbackFlow = useMemo(
      () =>
        user?.userID
          ? userEntitiesWithFeedback[user.userID]?.[EntityType.ENTITY_TYPE_SMS]?.includes(message.id) ?? false
          : false,
      [user?.userID, JSON.stringify(userEntitiesWithFeedback[user?.userID ?? '']?.[EntityType.ENTITY_TYPE_SMS])]
    );

    const noteStatusQuery = TextWritebacksQueries.useNoteStatus(
      { smsId: message.id, locationId: groupId },
      {
        enabled: 'relatedIds' in message && messageHasTextWriteback(message.relatedIds ?? []),
      }
    );

    const personName = personData
      ? PersonHelpers.getFullName({
          FirstName: personData?.firstName,
          LastName: personData?.lastName,
          PreferredName: personData?.preferredName,
        })
      : undefined;
    const direction = message.direction === SchemaSMSSharedEnums.Direction.DIRECTION_INBOUND ? 'inbound' : 'outbound';
    const { senderName, deleterName } = useMessagePersonNames({
      direction: message.direction ?? SchemaSMSSharedEnums.Direction.DIRECTION_UNSPECIFIED,
      autogeneratedBy: message.autogeneratedBy ?? '',
      createdBy: message.createdBy ?? '',
      deletedAt: message.deletedAt ?? '',
      deletedBy: message.deletedBy ?? '',
      personName,
    });
    const defaultDisplayErrorText = t('Not delivered');
    const errorText =
      (!isSending && message.status === SchemaSMSSharedEnums.Status.STATUS_NOT_SENT) ||
      message.status === SchemaSMSSharedEnums.Status.STATUS_ERROR
        ? message.statusDetails ?? defaultDisplayErrorText
        : undefined;
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({
      placement: direction === 'inbound' ? 'top-end' : 'top-start',
      trigger: 'hover',
    });
    const getStatusComponent = useCallback(() => {
      if (errorText) {
        return (
          <>
            <span
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
              }}
              {...triggerProps}
            >
              <Text color='error' size='small'>
                {defaultDisplayErrorText}
              </Text>
              <Icon name='alert-invert-small' color='error' />
            </span>
            <Tooltip {...tooltipProps}>{sentenceCase(errorText)}</Tooltip>
          </>
        );
      }

      if (noteStatusQuery.data?.noteStatus) {
        return <TextWritebackStatus noteStatus={noteStatusQuery.data.noteStatus} showLabel={!!showFullStatus} />;
      }
      return undefined;
    }, [errorText, noteStatusQuery.data?.noteStatus, defaultDisplayErrorText, tooltipProps, showFullStatus]);

    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 1);

    const applyTag = SMSDataV3.Mutations.useApplyTagMutation();
    const removeTag = SMSDataV3.Mutations.useDismissTagMutation();
    const {
      popoverProps: tagPopoverProps,
      open: openTagSelection,
      isOpen: tagSelectionIsOpen,
      close: closeTagSelection,
      getTriggerProps: getTagSelectionTriggerProps,
    } = TagSelectionHooks.useTagSelectionPopover({
      groupIds: [groupId],
      onTagSelect: async (tag) => {
        await applyTag.mutateAsync(
          {
            tagId: tag.id,
            smsId: message.id,
            smsCreatedAt: message.createdAt,
            groupId,
            threadId,
            personId: personData?.personId,
            personPhone: message.personPhone,
            personFirstName: personData?.firstName,
            personLastName: personData?.lastName,
            personPreferredName: personData?.preferredName,
          },
          {
            onSuccess: () => {
              if (user?.userID && !tag.assignedUserIds.includes(user.userID) && hasSMSTagNotificationEnabled) {
                alert.success(t('Message tagged.'));
              }
            },
            onError: () => {
              alert.error(t('Error tagging message.'));
            },
          }
        );
        return;
      },
      onTagCreate: (initName) => {
        settingsNavigate({
          to: '/tags/quick-create',
          context: {
            onCreate: (tag: Tag) =>
              applyTag.mutate(
                {
                  tagId: tag.id,
                  smsId: message.id,
                  smsCreatedAt: message.createdAt,
                  groupId,
                  threadId,
                  personId: personData?.personId,
                  personPhone: message.personPhone,
                  personFirstName: personData?.firstName,
                  personLastName: personData?.lastName,
                  personPreferredName: personData?.preferredName,
                },
                {
                  onSuccess: () => {
                    if (user?.userID && !tag.assignedUserIds.includes(user.userID) && hasSMSTagNotificationEnabled) {
                      alert.success(t('Message tagged.'));
                    }
                  },
                  onError: () => {
                    alert.error(t('Error tagging message.'));
                  },
                }
              ),
            name: initName,
          },
        });
      },
      trackingIds: {
        selectTag: `${InboxPrefixes.Tag}-add-existing-tag`,
        createTag: `${InboxPrefixes.Tag}-create-new-tag`,
        createNamedTag: `${InboxPrefixes.Tag}-create-new-tag-with-name`,
        openTagSettings: `${InboxPrefixes.Tag}-open-tag-settings`,
      },
      searchValue: tagSearchValue,
      tagIdsToExclude: tagIds,
    });
    const { globalActions, mediaActions, messageActions, handleSmsDelete, retrySend } = useBubbleActions({
      message,
      modalControl,
      groupId,
      isDeleted: !!message.deletedAt,
    });
    const tagsCount = tagIds.length;
    const sortedTags = useMemo<
      { tagId: string; tag?: NonNullable<typeof locationTags>[number]; appliedBy: string }[]
    >(() => {
      const { foundTags, otherTags } = (message.tagsDetailed ?? []).reduce<{
        foundTags: { tagId: string; tag: NonNullable<typeof locationTags>[number]; appliedBy: string }[];
        // otherTags is a list of tags that aren't in the `ListTags` query results for some reason and will need to be fetched
        otherTags: { tagId: string; appliedBy: string }[];
      }>(
        (acc, { tagId, appliedBy }) => {
          const tag = locationTags?.find((tag) => tag.id === tagId);
          if (tag) acc.foundTags.push({ tagId, tag, appliedBy });
          else acc.otherTags.push({ tagId, appliedBy });
          return acc;
        },
        { foundTags: [], otherTags: [] }
      );

      const foundTagsOrder = TagsUtils.sortTagsAlphabetically(foundTags.map(({ tag }) => tag)).map((tag) => tag.id);
      const sortedFoundTags = foundTagsOrder.map((tagId) => foundTags.find((tag) => tag.tag.id === tagId)!);

      return [...sortedFoundTags, ...otherTags];
    }, [JSON.stringify(message.tagsDetailed), TagsUtils.sortTagsAlphabetically, JSON.stringify(locationTags)]);
    const onlyDefaultTagId = sortedTags.length === 1 && !sortedTags[0]!.appliedBy ? sortedTags[0]!.tagId : undefined;

    const onTagClick = ({
      tagId,
      withoutUndo,
      appliedBy,
    }: {
      tagId: string;
      appliedBy: string;
      withoutUndo?: boolean;
    }) => {
      if (withoutUndo) {
        removeTag.mutate(
          {
            tagId,
            smsId: message.id,
            groupId,
            threadId,
          },
          {
            onSuccess: () => {
              alert.success(t('Tag removed.'));
            },
            onError: () => {
              alert.error(t('Error removing tag.'));
            },
          }
        );
      } else {
        const timeout = setTimeout(() => {
          removeTag.mutate(
            {
              tagId,
              smsId: message.id,
              groupId,
              threadId,
            },
            {
              onError: (error) => {
                alert.error(t('Error removing tag. Please try again.'));
                sentry.log({
                  message: 'Error removing tag after no undo',
                  category: 'messages',
                  type: 'error',
                  data: {
                    error,
                  },
                });
              },
            }
          );
        }, TAG_ALERT_DELAY + 1000);
        updateSMS({
          matchValues: {
            id: message.id,
            threadId,
            locationId: groupId,
          },
          newValues: (sms) => ({
            ...sms,
            tags: sms.tags.filter((id) => id !== tagId),
            tagsDetailed: sms.tagsDetailed?.filter((tag) => tag.tagId !== tagId),
          }),
          skipInvalidation: true,
        });
        alert.info({
          message: t('Tag removed.'),
          action: {
            label: t('Undo'),
            onClick: () => {
              clearTimeout(timeout);

              updateSMS({
                matchValues: {
                  id: message.id,
                  threadId,
                  locationId: groupId,
                },
                newValues: (sms) => {
                  const newTagsDetailed: (typeof sms)['tagsDetailed'] = sms.tagsDetailed?.some(
                    (tag) => tag.tagId === tagId
                  )
                    ? sms.tagsDetailed
                    : [
                        ...(sms.tagsDetailed ?? []),
                        { tagId, smsId: message.id, smsCreatedAt: message.createdAt, appliedBy },
                      ];
                  return { ...sms, tags: Array.from(new Set([...sms.tags, tagId])), tagsDetailed: newTagsDetailed };
                },
              });
            },
          },
          autoDismissAfter: TAG_ALERT_DELAY,
        });
      }
    };

    useEffect(() => {
      if (tagSelectionIsOpen) {
        tagSelectionInputRef.current?.focus();
      } else {
        setTagSearchValue('');
      }
    }, [tagSelectionIsOpen]);

    useEffect(() => {
      setTagInputWidth(tagInputWidthCalcRef.current?.offsetWidth ?? 0);
    }, [tagSearchValue]);

    return (
      <>
        <Chat
          ref={ref}
          direction={direction}
          isSending={isSending}
          timestamp={new Date(message.createdAt).toLocaleTimeString(undefined, { timeStyle: 'short' })}
          senderName={direction === 'outbound' ? senderName || t('Unknown User') : undefined}
          error={errorText ? t('Not delivered') : undefined}
          errorTooltip={errorText}
          retrySend={() => {
            if (message.media?.length) {
              message.media?.map((mediaItem) => {
                if (mediaItem.mediaId) {
                  retrySend(mediaItem);
                }
              });
            }
            if (message.body) {
              retrySend();
            }
          }}
          avatar={
            direction === 'inbound' && personData?.personId ? (
              <Photos.ContactProfilePhoto name={personName} personId={personData.personId} size='small' />
            ) : (
              <Avatar
                size='small'
                title={senderName || t('Unknown User')}
                name={senderName}
                isWeave={!!message.autogeneratedBy}
                isUser={direction === 'outbound'}
              />
            )
          }
          primaryAction={{
            label: t('Tag Message'),
            icon: 'label',
            onClick: () => {
              openTagSelection();
            },
            trackingId: `${InboxPrefixes.Tag}-tag-message`,
          }}
          moreActions={globalActions.map((action) => ({
            ...action,
            trackingId: action.trackingId ? `${action.trackingId}-more-menu` : undefined,
          }))}
          StatusBar={getStatusComponent()}
        >
          {!!message.numMedia &&
            !message.deletedAt &&
            message.media?.map((mediaItem) => {
              if (mediaItem.mediaId) {
                const mediaQuery = mediaQueries[mediaItem.mediaId];
                return (
                  <Chat.Bubble
                    variant='image'
                    key={mediaItem.mediaId}
                    src={mediaQuery?.data?.src}
                    contextActions={mediaActions(mediaItem)}
                    contextMenuReturnFocus={false}
                    imageProps={{
                      suppressPreview: selectionIsActive,
                    }}
                  />
                );
              }
              return null;
            })}
          {(!!message.body || !!message.deletedAt) && (
            <Chat.Bubble
              contextActions={messageActions}
              backgroundColor={!!message.autogeneratedBy ? theme.colors.success5 : undefined}
              text={
                message.deletedAt
                  ? t('This message was deleted on {{date}} by {{name}}', {
                      date: new Date(message.deletedAt).toLocaleDateString(),
                      name: deleterName,
                    })
                  : message.body
              }
              contextMenuReturnFocus={false}
              variant={message.deletedAt ? 'deleted' : 'text'}
            />
          )}
          {sortedTags
            .filter((t) => !!t.tag)
            .map(({ tagId, tag, appliedBy }) => (
              <SMSTag
                key={tagId}
                tagId={tagId}
                tag={tag}
                removeTag={() => onTagClick({ tagId, appliedBy })}
                onSmartTagFeedbackClick={(context) =>
                  onFeedbackClick({ ...context, entityType: EntityType.ENTITY_TYPE_SMS, entityId: message.id })
                }
                showFeedbackPopover={tagsCount > 1 && !hideFeedbackFlow}
                smsId={message.id}
                isAutoApplied={!appliedBy}
                hasTagsFeedbackEndAdornment={!!onlyDefaultTagId && !hideFeedbackFlow}
                groupId={groupId}
              />
            ))}
          {tagSelectionIsOpen && (
            <Chat.Tag
              color='gray'
              label={
                <span ref={getTagSelectionTriggerProps().ref}>
                  <input
                    ref={tagSelectionInputRef}
                    type='text'
                    value={tagSearchValue}
                    autoFocus
                    onChange={(e) => {
                      setTagSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === KeyNames.Escape) {
                        closeTagSelection();
                      }
                    }}
                    css={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      outline: 'none',
                      appearance: 'none',
                      width: `calc(${theme.spacing(1)} + ${tagInputWidth}px)`,
                      ':active': {
                        outline: 'none',
                        border: 'none',
                      },
                    }}
                  />
                </span>
              }
            />
          )}
        </Chat>

        {
          /* This is used to calculate the dynamic width of the tag input */
          tagSelectionIsOpen && (
            <span
              ref={tagInputWidthCalcRef}
              css={{
                position: 'absolute',
                opacity: 0,
                fontSize: theme.fontSize(14),
              }}
              tabIndex={-1}
            >
              {tagSearchValue}
            </span>
          )
        }
        <TagSelectionComponents.TagSelectionPopover {...tagPopoverProps} returnFocus={false} initialFocus={-1} />

        <ConfirmationModal
          {...modalControl.modalProps}
          destructive
          message={t(
            'Are you sure you want to delete this message? This action will delete the message for the office and not affect the customer.'
          )}
          title={t('Delete Confirmation')}
          onConfirm={() => {
            handleSmsDelete();
            if (modalCheckboxProps.value) {
              localStorage.setItem(HIDE_DELETE_MODAL, 'true');
            }
          }}
        >
          <CheckboxField
            {...modalCheckboxProps}
            name='hide-modal-checkbox'
            label={t("Don't show this message again")}
            css={{ marginBottom: theme.spacing(3) }}
          />
        </ConfirmationModal>

        <TagSelectionComponents.TagFeedbackModal
          {...feedbackModalProps}
          onCancel={closeFeedbackModal}
          onSkip={() => feedbackModalProps.onSubmit('')}
        />
      </>
    );
  }
);
SMSBubble.displayName = 'SMSBubble';
