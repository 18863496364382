import { StepNavigationSwitch } from '../../atoms/step-navigation-switch';
import { PaymentMethodsGenericNavigation } from './navigation/payment-methods-generic-navigation';
import { PaymentMethodsSelectNavigation } from './navigation/payment-methods-select-navigation';
import { PaymentMethodsBody } from './payment-methods-body';

export const PaymentMethodNavigationSwitch = () => {
  return (
    <StepNavigationSwitch
      body={PaymentMethodsBody}
      generic={PaymentMethodsGenericNavigation}
      select={PaymentMethodsSelectNavigation}
    />
  );
};
