import { ElementType, ReactNode } from 'react';
import { css } from '@emotion/react';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';

type Props = CommonHTMLAttributes & {
  as?: ElementType;
  children: ReactNode;
};

export const Card = ({ as = 'section', children, ...rest }: Props) => {
  const Component = as;
  return (
    <Component css={cardStyle} {...rest}>
      {children}
    </Component>
  );
};

const cardStyle = css`
  background: ${theme.colors.white};
  box-shadow: ${theme.shadows.heavy};
  border-radius: ${theme.borderRadius.medium};
  width: 100%;
  max-width: 500px;
  padding: ${theme.spacing(2, 3)};
  margin-bottom: ${theme.spacing(3)};
`;
