import { css } from '@emotion/react';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, CheckboxField, useControlledField } from '@frontend/design-system';
import { getUserFullName } from '../../../utils';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';

interface UserRowProps {
  user: TeamChatTypes.User;
  selectedMemberIds: string[];
  onRemoveUser: (id: string) => void;
  onAddUser: (id: string) => void;
}

export const UserRow = ({ user, selectedMemberIds, onAddUser, onRemoveUser }: UserRowProps) => {
  const { t } = useTranslation('team-chat');
  const isMemberAdded = selectedMemberIds.includes(user.userID);
  const checkboxFieldProps = useControlledField({
    value: isMemberAdded,
    onChange: () => (isMemberAdded ? onRemoveUser(user.userID) : onAddUser(user.userID)),
    type: 'checkbox',
  });

  const checkboxName = isMemberAdded ? t('Remove Member') : t('Add Member');

  return (
    <div css={userContainerStyle} key={user.userID}>
      <ChatAvatar users={[user]} />
      <Text>{getUserFullName(user.firstName, user.lastName)}</Text>
      <CheckboxField
        {...checkboxFieldProps}
        name={checkboxName}
        label={undefined}
        trackingId={
          isMemberAdded
            ? 'team-chat-2.0-remove-member-from-existing-group-button'
            : 'team-chat-2.0-add-member-to-existing-group-button'
        }
        css={marginLeftAuto}
      />
    </div>
  );
};

const userContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

const marginLeftAuto = css({ marginLeft: 'auto' });
