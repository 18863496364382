import { ThreadStatus } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { usePopoverMenu } from '@frontend/design-system';
import { DepartmentSelectorState } from '../components';

type UseDepartmentSelectorArgs = {
  groupId: string;
  departmentId?: string;
  alwaysShow?: boolean;
  personPhone?: string;
};

export const useDepartmentSelector = ({
  groupId,
  departmentId,
  alwaysShow = false,
  personPhone,
}: UseDepartmentSelectorArgs): DepartmentSelectorState => {
  const {
    data: { smsNumbers: departments },
  } = DepartmentsQueries.useListDefaultSMSQuery(
    {
      locationId: groupId,
    },
    {
      enabled: !!groupId,
    }
  );
  const departmentIds =
    departments?.reduce<string[]>((acc, { id }) => {
      if (id) acc.push(id);
      return acc;
    }, []) ?? [];
  const selectedDepartment = departments?.find(({ id }) => id === departmentId);

  const popoverState = usePopoverMenu({
    placement: 'bottom',
  });

  const threadStatusQuery = SMSDataV3.Queries.useBatchGetThreadStatusQuery({
    request: {
      requests: SMSDataV3.Utils.calculatePossibleThreadStatusRequests({
        personPhones: personPhone ? [personPhone] : [],
        departmentIdsPerGroupId: { [groupId]: departmentIds },
        groupIds: [groupId],
      }),
    },
    options: {
      enabled: !!personPhone,
    },
  });

  const {
    departmentIdsWithActiveConversations,
    departmentIdsWithArchivedConversations,
    departmentIdsWithoutConversations,
  } = threadStatusQuery.data?.responses.reduce<{
    departmentIdsWithActiveConversations: string[];
    departmentIdsWithArchivedConversations: string[];
    departmentIdsWithoutConversations: string[];
  }>(
    (acc, response) => {
      switch (response.status) {
        case ThreadStatus.ACTIVE:
          acc.departmentIdsWithActiveConversations.push(response.departmentId);
          break;
        case ThreadStatus.ARCHIVED:
          acc.departmentIdsWithArchivedConversations.push(response.departmentId);
          break;
        case ThreadStatus.NEW:
          acc.departmentIdsWithoutConversations.push(response.departmentId);
          break;
      }
      return acc;
    },
    {
      departmentIdsWithActiveConversations: [],
      departmentIdsWithArchivedConversations: [],
      departmentIdsWithoutConversations: [],
    }
  ) ?? {
    departmentIdsWithActiveConversations: [],
    departmentIdsWithArchivedConversations: [],
    departmentIdsWithoutConversations: [],
  };

  const showButton = alwaysShow || ((departments?.length ?? 0) > 1 && !!departmentId);

  return {
    showButton,
    selectedDepartment,
    popoverState,
    personPhone,
    departments,
    departmentIdsWithArchivedConversations,
    departmentIdsWithActiveConversations,
    departmentIdsWithoutConversations,
  };
};
