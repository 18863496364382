import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { emptyStateGraphics, Text } from '@frontend/design-system';
import { EmptyStateConfig } from '../types';

type EmptyDataProps = {
  emptyStateConfig?: EmptyStateConfig;
};

export const EmptyData = ({ emptyStateConfig }: EmptyDataProps) => {
  const EmptyStateGraphic = emptyStateGraphics[emptyStateConfig?.type ?? 'analytics'];

  return (
    <div className='no-data' css={styles.wrapper}>
      <EmptyStateGraphic css={styles.graphic} style={emptyStateConfig?.style} />
      {emptyStateConfig?.label && (
        <Text color='subdued' textAlign='center'>
          {emptyStateConfig?.label}
        </Text>
      )}
      {emptyStateConfig?.description && (
        <Text color='subdued' textAlign='center'>
          {emptyStateConfig?.description}
        </Text>
      )}
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(0.5)};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  `,

  graphic: css`
    height: 200px;
    max-width: 100%;
    width: 200px;
  `,
};
