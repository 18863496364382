import { useEffect } from 'react';
import type { CommonHTMLInputAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';

type SignatureInputProps = {
  onUnmount?: () => void;
} & CommonHTMLInputAttributes;

export const SignatureInput = ({ onUnmount, ...rest }: SignatureInputProps) => {
  useEffect(() => {
    return () => {
      onUnmount?.();
    };
  }, []);

  return (
    <input
      css={{
        border: 'none',
        outline: 'none',
        color: theme.font.colors.light,
        width: '100%',
        textOverflow: 'ellipsis',
      }}
      {...rest}
    />
  );
};
