import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { PaymentRequestModal, usePaymentRequestModal } from '@frontend/payments-invoice-create';

/**
 * @dashboard-widget
 *
 * id: add-invoice
 * title: New Invoice
 * description: Quickly create a new invoice and collect payments.
 * icon: payments
 */
export const AddInvoiceWidget: DashboardWidgetFC = () => {
  const { widgetTrackingId } = useDashboardWidget();
  const { dashboardButtonLabel, triggerProps, modalProps } = usePaymentRequestModal();

  return (
    <>
      <DashboardWidget.ActionButton
        title={dashboardButtonLabel}
        trackingId={widgetTrackingId('action')}
        iconName='add'
        {...triggerProps}
      />
      <PaymentRequestModal {...modalProps} />
    </>
  );
};

AddInvoiceWidget.config = {
  size: 'tiny-slim',
  feature: 'payments',
};
