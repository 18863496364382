import { PaymentsTerminalError } from './payment-terminal-error';
import { SDKStrategy } from './sdk-strategy/sdk-strategy';
import { ServerDrivenStrategy } from './server-driven-strategy/server-driven-strategy';
import type {
  TerminalStrategyProps,
  TerminalStrategyMethods,
  TerminalStrategyContextCreator,
} from './terminal-strategy';

export const createTerminalSession: TerminalStrategyContextCreator = ({
  reader,
  // This should default to true when ready for production
  enableServerDriven = false,
  ...rest
}: TerminalStrategyProps): Promise<TerminalStrategyMethods> => {
  switch (reader.deviceType) {
    case 'verifone_P400':
      return SDKStrategy.createSdkTerminalSession({ reader, ...rest });
    case 'bbpos_wisepos_e':
    case 'justifi':
      return ServerDrivenStrategy.createServerDrivenTerminalSession({ reader, ...rest });
    case 'stripe_s700':
      return enableServerDriven
        ? ServerDrivenStrategy.createServerDrivenTerminalSession({ reader, ...rest })
        : SDKStrategy.createSdkTerminalSession({ reader, ...rest });
    case 'bbpos_chipper2x':
      throw new PaymentsTerminalError('initialize', 'bbpos_chipper2x is not supported');
    case 'bbpos_wisepad3':
      throw new PaymentsTerminalError('initialize', 'bbpos_wisepad3 is not supported');
    case 'stripe_m2':
      throw new PaymentsTerminalError('initialize', 'stripe_m2 is not supported');
    default: {
      const _exhaustiveCheck: never = reader.deviceType;
      throw new PaymentsTerminalError('initialize', `Unknown device type: ${_exhaustiveCheck}`);
    }
  }
};
