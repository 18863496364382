/**
 * If the `constraints` array is empty, the `data` array will not be filtered.
 */
export const filterWhenConstrained = <T extends unknown, C extends unknown>({
  data,
  constraints,
  filterFn,
}: {
  data: T[];
  constraints: C[];
  filterFn: (item: T, constraints: C[]) => boolean;
}) => {
  return constraints.length === 0 ? data : data.filter((item) => filterFn(item, constraints));
};

/**
 * This is a permissive filter function that allows items through if not explicitly filtered out.
 *
 * To be filtered out, the `scopeIdAccessor` must return an array of scopeIds that does not intersect (mutually exclusive) with the `scopeIds` array.
 *
 * If there is nothing to compare against (no scopeIds), no filtering will occur.
 */
export const permissiveExclusiveFilter = <T extends { locationIds?: string[] }>(
  data: T[],
  scopeIds: string[] = [],
  scopeIdAccessor = (group: T) => group.locationIds ?? []
) => {
  const selectedLocationSet = new Set(scopeIds);

  // If there are no scopeIds, return the data as is
  if (scopeIds.length === 0) {
    return data;
  }

  return data.filter((item) =>
    scopeIdAccessor(item).length > 0
      ? scopeIdAccessor(item).some((locationId) => selectedLocationSet.has(locationId))
      : true
  );
};

/**
 * This function filters items down to the applicable scope, as determined by `scopeIds`.
 * It further filters the data by "selected" scopeIds, as determined by `filteredScopeIds`.
 *
 *
 * This function:
 * 1. Filters data down to items that are available in the given scopeIds.
 * 2. After filtering down to the provided scopeIds, the data can then be filtered further with the `filterFn` provided.
 * 3. Optionally sorts the data by name.
 *
 * Notes:
 * 1. If the `scopeIds` array is empty, the `data` array will not undergo initial filtering. Filtering via the `filterFn` will still occur.
 * 2. if `filteredScopeIds` is empty, the `data` array will not undergo filtering via the `filterFn`.
 *
 * The caller should only use this function to determine if the data is in scope. Filtering for other purposes should not be done with this function.
 * Do not use the `filterFn` to filter out items for a different purpose, since filtering might be skipped altogether if empty arrays are passed into `scopeIds` or `filteredScopeIds`.
 */
export const scopedFilterAndSort = <T extends { locationIds?: string[]; name?: string }>({
  data,
  scopeIds = [],
  filteredScopeIds,
  shouldSort = true,
  scopeIdAccessor = (group) => group.locationIds ?? [],
}: {
  data: T[];
  scopeIds?: string[];
  filteredScopeIds: string[];
  shouldSort?: boolean;
  scopeIdAccessor?: (group: T) => string[];
}) => {
  /**
   * Empty arrays are a little magical in this function, and basically bypass filtering for the next two steps.
   * In particular, an empty `scopeIds` will skip step 1, and an empty `filteredScopeIds` will skip step 2.
   */
  const locallyAvailableItems = permissiveExclusiveFilter(data, scopeIds, scopeIdAccessor);

  /**
   * Look for the intersection of the universal set (scopeIds) and the filtered subset (filteredScopeIds).
   *
   * This effectively means that we ignore a filter if the filter is not in the universal set.
   */
  const intersection = filteredScopeIds.filter((id) => scopeIds.includes(id));

  /**
   * If there are no constraints (when the intersection is empty), return the data as is.
   */
  const filteredItems = filterWhenConstrained({
    data: locallyAvailableItems,
    constraints: intersection,
    filterFn: (group, constraints) =>
      scopeIdAccessor(group)?.some((scopeIds) => constraints.includes(scopeIds)) ?? false,
  });

  if (shouldSort) {
    return filteredItems.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
  } else {
    return filteredItems;
  }
};
