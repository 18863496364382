import { useCallRouteV1QueryUpdaters } from '../query-updaters';
import { UpdateIO } from '../types';
import { UseCallRouteV1MutationEndpointArgs, useCallRouteV1Mutation } from './use-call-route-v1-mutation';

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `Update` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useUpdateMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends UpdateIO['input'] = UpdateIO['input']
>({
  options,
  // optimisticUpdate = false,
  ...args
}: UseCallRouteV1MutationEndpointArgs<'Update', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { updateQuery } = useCallRouteV1QueryUpdaters();

  return useCallRouteV1Mutation<'Update', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'Update',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        // Implement optimistic updates here

        return {
          // Add custom context type here (if exists in `MutationContext`)
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        // Implement query updates or invalidation for affected queries here

        // When successfully updating a call route, update the new call route in the list of call routes
        // to reflect the new updated fields
        updateQuery({
          endpointName: 'List',
          queryFilters: {},
          updater: (data) => ({
            callRoutes: data.callRoutes.map((callRoute) =>
              callRoute.callRouteId === response.callRouteId
                ? { ...callRoute, locationId: response.locationId, name: response.name }
                : callRoute
            ),
          }),
        });

        // When successfully updating a call route, update the Read query cached call route
        // to reflect the new updated fields
        updateQuery({
          endpointName: 'Read',
          queryFilters: {},
          updater: (data) => ({
            ...data,
            name: response.name,
            locationId: response.locationId,
            callRoute: { ...data.callRoute, name: response.name },
          }),
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};
