import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from 'react-query';

/**
 *
 * Same as useQuery, but with a setter function to update the cache
 */
export const useQueryWithSetter = <T extends UseQueryOptions<any, any, any, any>>(args: T) => {
  type Data = T extends UseQueryOptions<infer Data> ? Data : unknown;
  type Error = T extends UseQueryOptions<any, infer Error> ? Error : unknown;
  type Key = T extends UseQueryOptions<any, any, any, infer Key> ? Key : unknown;
  type DataUpdater = (prev: Data | undefined) => Data | undefined;
  const queryClient = useQueryClient();
  const result = useQuery(args as UseQueryOptions<Data, Error, Data, Key>);
  const queryKey = args.queryKey ?? ['unknown'];
  const set = (updater: Data | DataUpdater) => {
    queryClient.setQueryData<Data | undefined>(queryKey, updater);
  };
  return { ...result, set };
};

/*
 * Same as useInfiniteQuery but adds a setter function that allows you to modify the cache easier
 */
export const useInfiniteQueryWithSetter = <T extends UseInfiniteQueryOptions<any, any, any, any, any>>(args: T) => {
  type Data = T extends UseInfiniteQueryOptions<infer Data> ? Data : unknown;
  type Error = T extends UseInfiniteQueryOptions<any, infer Error> ? Error : unknown;
  type Key = T extends UseInfiniteQueryOptions<any, any, any, infer Key> ? Key : unknown;
  type DataUpdater = (prev: UseInfiniteQueryResult<Data>['data'] | undefined) => UseInfiniteQueryResult<Data>['data'];
  const queryClient = useQueryClient();
  const result = useInfiniteQuery(args as UseInfiniteQueryOptions<Data, Error, Data, Data, Key>);
  const queryKey = args.queryKey ?? ['unknown'];
  const set = (updater: DataUpdater) => {
    queryClient.setQueryData<UseInfiniteQueryResult<Data>['data']>(queryKey, updater);
  };
  return { ...result, set };
};
