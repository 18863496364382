import { FC, useEffect, useState } from 'react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess';
import { SyncAppQueries } from '@frontend/api-sync-app';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { GlobalBannerDynamic } from '@frontend/global-info-banner';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';

// TODO: change to 60 seconds and 30 retries
const POLLING_INTERVAL = 10 * 1000;
const MAX_POLL_COUNT = 300;

export const SyncAppAuthAlertBanner: FC = () => {
  const { t } = useTranslation('syncAppAuth');
  const { selectedLocationIds } = useAppScopeStore();
  const selectedLocationId = selectedLocationIds[0];
  const [pollStarted, setPollStarted] = useState(false);
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const hasPracticeInstallPermission = hasSchemaACL(selectedLocationId, Permission.SYNC_APP_PRACTICE_ADMIN_INSTALL);
  const hasSyncAppManagePermission = hasSchemaACL(selectedLocationId, Permission.SYNC_APP_MANAGE);

  const showAuthorizeBtn = hasPracticeInstallPermission || hasSyncAppManagePermission;

  const { data } = SyncAppQueries.useSyncAppAuthCheck(
    {
      locationId: selectedLocationId,
    },
    {
      refetchInterval: (data, query) => {
        if (pollStarted && data?.Required && query.state.dataUpdateCount <= MAX_POLL_COUNT) {
          return POLLING_INTERVAL;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    setPollStarted(false);
    setBannerDismissed(false);
  }, [selectedLocationId]);

  const handleAuthorizeClick = () => {
    setPollStarted(true);
    if (data?.DownloadURL) {
      window.open(`${data.DownloadURL}&sourceID=${data?.SourceID ?? ''}`, '_blank');
    }
  };

  if (!(data?.Required && data?.DownloadURL)) {
    return pollStarted && !bannerDismissed ? (
      <GlobalBannerDynamic
        type='success'
        id='sync-app-auth-success-banner'
        message={t('Your PMS account has been successfully reconnected. It will take a while to sync the latest data.')}
        isDismissible={true}
        onDismiss={() => setBannerDismissed(true)}
        title={t('Connection Successful!')}
      />
    ) : null;
  }

  return (
    <GlobalBannerDynamic
      type='error'
      id='sync-app-auth-alert-banner'
      message={
        showAuthorizeBtn
          ? t(
              'To continue syncing your information, please reconnect your PMS account by entering your login credentials.'
            )
          : t(
              'To keep your information synced, your PMS account needs to be reconnected. Please contact your office administrator to complete this process.'
            )
      }
      isDismissible={false}
      title={t('Your Connection Has Expired')}
      trackingId='sync-app-authorize-button'
      action={
        showAuthorizeBtn
          ? {
              label: t('Authorize'),
              onClick: handleAuthorizeClick,
            }
          : undefined
      }
    />
  );
};
