import { createShallowStore, createStore } from '@frontend/store';

type AppointmentTypesHelperStore = {
  isPaymentsEnabled: boolean;
  setPaymentsEnabled: (isPaymentsEnabled: boolean) => void;

  isOnlineSchedulingEnabled: boolean;
  setIsOnlineSchedulingEnabled: (isOnlineSchedulingEnabled: boolean) => void;
};

const useAppointmentTypesHelperStore = createStore<AppointmentTypesHelperStore>(
  (set) => ({
    isPaymentsEnabled: false,
    setPaymentsEnabled: (isPaymentsEnabled) => set({ isPaymentsEnabled }),

    isOnlineSchedulingEnabled: false,
    setIsOnlineSchedulingEnabled: (isOnlineSchedulingEnabled) => set({ isOnlineSchedulingEnabled }),
  }),
  {
    name: 'appointmentTypeHelper',
  }
);

export const useAppointmentTypesHelperShallowStore =
  createShallowStore<AppointmentTypesHelperStore>(useAppointmentTypesHelperStore);
