import { useMemo, useState } from 'react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { Responsive } from 'react-grid-layout';
import {
  addWidgetToLayout,
  DashboardBreakpoints,
  dashboardBreakpoints,
  transformToLayouts,
  WidgetConfig,
  WidgetSizes,
} from '@frontend/grid-dashboard';
import { useSkipBundleDashboardWidgets } from './useSkipBundleDashboardWidgets';

interface UseBundleTrialWidgetsProps {
  bundle: SalesforceAccountProductBundle;
}

interface WidgetIdSize {
  id: string;
  size: WidgetSizes;
  priority?: number;
}

const CommonUltimateWidgets: WidgetIdSize[] = [
  { id: 'cancelled-patients-widget', size: 'small-narrow', priority: 1 },
  { id: 'appointment-count', size: 'tiny-slim', priority: 2 },
  { id: 'forms-submission', size: 'tiny-slim', priority: 3 },
  { id: 'wiv-setup', size: 'micro-narrow', priority: 4 },
  { id: 'schedule-setup', size: 'micro-narrow', priority: 5 },
];

export const getBundleWiseTrialWidgets = (bundle: SalesforceAccountProductBundle): WidgetIdSize[] => {
  switch (bundle) {
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ULTIMATE:
      // TODO: Add widgets for Weave Plus Ultimate later
      return [...CommonUltimateWidgets, { id: 'call-intel-redirect', size: 'micro-slim', priority: 6 }];
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ULTIMATE:
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ULTIMATE:
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ULTIMATE:
      return [...CommonUltimateWidgets];
    default:
      return [];
  }
};

const widgetActionMap: Record<string, { addToDashboard?: boolean }> = {
  'cancelled-patients-widget': { addToDashboard: true },
  'appointment-count': { addToDashboard: true },
  'forms-submission': { addToDashboard: true },
};

const getWidgetConfigList = (widgets: WidgetIdSize[]) => {
  return widgets.reduce<WidgetConfig[]>(addWidgetToLayout, []);
};

export const useBundleTrialWidgets = ({ bundle }: UseBundleTrialWidgetsProps) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<DashboardBreakpoints>(
    // Responsive is written in flow so typecast to any
    (Responsive as any).utils.getBreakpointFromWidth(dashboardBreakpoints, window.innerWidth)
  );

  const { isWidgetSkipped } = useSkipBundleDashboardWidgets();

  const widgetConfigList = useMemo<WidgetConfig[]>(() => {
    const widgets = getBundleWiseTrialWidgets(bundle).filter((widget) => !isWidgetSkipped(widget.id));
    return getWidgetConfigList(widgets.sort((a, b) => (a.priority || 0) - (b.priority || 0)));
  }, [bundle, isWidgetSkipped]);

  const widgetLayoutList = useMemo(() => {
    return transformToLayouts(widgetConfigList);
  }, [widgetConfigList]);

  return {
    currentBreakpoint,
    setCurrentBreakpoint,
    widgetConfigList,
    widgetLayoutList,
    widgetActionMap,
  };
};
