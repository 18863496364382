import { SMSDataV3 } from '@frontend/api-sms-data';
import { AdaptoActions } from '@frontend/adapto-actions';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type MarkUnreadActionProps = {
  onSuccess?: () => void;
} & Omit<ThreadActionProps, 'label' | 'icon' | 'onClick'>;

export const MarkUnreadAction = ({ onSuccess, ...rest }: MarkUnreadActionProps) => {
  const user = getUser();
  const { t } = useTranslation('thread-actions');
  const { groupId, threadId } = useThreadActionsContext();

  const markUnreadMutation = SMSDataV3.Mutations.useSetThreadsStatusNewMutation({
    options: { onSuccess },
  });

  return (
    <AdaptoActions.Action
      label={t('Mark Unread')}
      icon='message-unread'
      onClick={() => {
        if (!user) return;
        markUnreadMutation.mutate({
          threadIds: [threadId],
          locationId: groupId,
          userId: user.userID,
        });
      }}
      {...rest}
    />
  );
};
