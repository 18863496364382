import { DropzoneState, useDropzone } from 'react-dropzone';

type useThreadDropzoneProps = {
  addMedia: (files: File[]) => Promise<{
    newMediaIds: string[];
  }>;
};

export const useThreadDropzone = ({ addMedia }: useThreadDropzoneProps): DropzoneState => {
  const dropzoneState = useDropzone({
    accept: { 'image/jpg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
    onDrop: (files: File[]) => {
      addMedia(files);
    },
    noDragEventsBubbling: true,
    noClick: true,
  });

  return { ...dropzoneState };
};
