import { useMemo } from 'react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { thumbnailSectionStyle, iconStyle } from './grid-view-thumbnail.style';

interface GridViewThumbnailProps {
  index: number;
}

interface ColorPalette {
  primary: string;
  background: string;
}

// create an array of color palettes
const colorPalette: ColorPalette[] = [
  { primary: theme.colors.primary60, background: theme.colors.primary5 },
  { primary: theme.colors.critical60, background: theme.colors.critical5 },
  { primary: theme.colors.warning60, background: theme.colors.warning5 },
  { primary: theme.colors.success60, background: theme.colors.success5 },
  { primary: theme.colors.indigo60, background: theme.colors.indigo5 },
  { primary: theme.colors.rose60, background: theme.colors.rose5 },
  { primary: theme.colors.tangerine60, background: theme.colors.tangerine5 },
];

export const GalleryViewThumbnail = ({ index }: GridViewThumbnailProps) => {
  const paletteIndex = useMemo<number>(() => {
    if (index < colorPalette.length) {
      return index;
    }
    return index % colorPalette.length;
  }, [index]);

  return (
    <section css={[thumbnailSectionStyle, { backgroundColor: colorPalette[paletteIndex].background }]}>
      <Icon name='weave-logo' css={[iconStyle, { color: colorPalette[paletteIndex].primary }]} />
    </section>
  );
};
