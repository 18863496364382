import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useSchedulingLocationInfo } from '../../hooks';

type TimezoneProps = {
  locationId: string;
};

export const Timezone = ({ locationId }: TimezoneProps) => {
  const { t } = useTranslation('schedule');
  const { getLocationTimeZone } = useSchedulingLocationInfo();
  const locationTimeZone = getLocationTimeZone(locationId);

  if (!locationTimeZone) return null;

  return (
    <section css={timezoneStyles}>
      <Text weight='bold'>{t('Timezone')}</Text>
      <Text size='large'>{locationTimeZone}</Text>
    </section>
  );
};

const timezoneStyles = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 50%) 1fr',
  padding: theme.spacing(2, 4),
  borderBottom: `thin solid ${theme.colors.neutral20}`,
});
