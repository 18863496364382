import { ReactNode, createContext, useContext, useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { NotificationDoc } from '@weave/schema-gen-ts/dist/schemas/notification/v1/notification_api.pb';
import {
  onSnapshot,
  collection,
  query,
  where,
  orderBy,
  limit,
  QueryDocumentSnapshot,
  Timestamp,
  writeBatch,
  updateDoc,
  getDoc,
  doc,
} from 'firebase/firestore';
import mitt from 'mitt';
import { getDecodedWeaveToken } from '@frontend/auth-helpers';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { shell } from '@frontend/shell-utils';
import { sentry } from '@frontend/tracking';
import {
  GetWeavePopNotificationByType,
  GetWeavePopNotificationActionsByType,
  WeavePopNotification,
} from '@frontend/types';
import { notificationSounds as soundOptions } from './audio/notification-sounds';
import { useFirestoreDbQuery } from './firestore';
import { TransientQueueProvider } from './transient-queue';
import { useNotificationFiltersStore } from './use-notification-filters';

const emitter = mitt<EmitterEvents>();

type EmitterEvents = {
  [P in WeavePopNotification['type']]: {
    notification: GetWeavePopNotificationByType<P>;
    action: GetWeavePopNotificationActionsByType<P>['action'];
    payload: GetWeavePopNotificationActionsByType<P>['payload'];
  };
};

type ContextValue = {
  emitter: typeof emitter;
  /** this is used for emitting notification actions from non-shell contexts */
  emit: (typeof emitter)['emit'];
  firebaseError: Error | null;
  notifications: NotificationDoc[] | null;
  isLoading: boolean;
  unreadCount: null | number;
  maxLocationsSelected: boolean;
  notificationTrayIsOpen: boolean;
  setNotificationTrayIsOpen: Dispatch<SetStateAction<boolean>>;
  markThreadNotificationsAsRead: (threadId: string, locationId: string) => void;
  toggleNotificationAsRead: (notificationId: string, hasRead: boolean) => void;
};

const NotificationContext = createContext<ContextValue>({
  emitter,
  emit: emitter.emit,
  notifications: [],
  firebaseError: null,
  isLoading: true,
  unreadCount: null,
  maxLocationsSelected: false,
  markThreadNotificationsAsRead: () => {},
  toggleNotificationAsRead: () => {},
  notificationTrayIsOpen: false,
  setNotificationTrayIsOpen: () => {},
});

export const converter = {
  toFirestore: (data: NotificationDoc) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => snapshot.data() as NotificationDoc,
};

/**
 * firestore has a limit of 30 `in` operators on the same field. we decided that we probably
 * wouldn't want to allow users to see notifications from more than 30 locations at a time anyway.
 * there is plenty of ui to help the user know that they are selecting too many locations.
 */
const MAX_SELECTABLE_LOCATIONS = 30;

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  // getting this user id from the token because...
  const userId = getDecodedWeaveToken()?.user_id ?? '';

  // firebase doesn't have a loading state, so use null to track loading
  const firestoreDbQuery = useFirestoreDbQuery();
  const [data, setData] = useState<NotificationDoc[] | null>(null);
  const [unreadCount, setUnreadCount] = useState<null | number>(null);
  const { selectedLocationIds: multiSelectedLocationIds } = useAppScopeStore();
  const [firebaseError, setFirebaseError] = useState<Error | null>(null);
  const { notificationFilters } = useNotificationFiltersStore('notificationFilters');
  const selectedLocationIds = multiSelectedLocationIds;
  const [notificationTrayIsOpen, setNotificationTrayIsOpen] = useState(false);

  useHandleShellNotificationsMode();

  useEffect(() => {
    if (!firestoreDbQuery.db || !userId || !selectedLocationIds.length) return;

    const collection_ = collection(firestoreDbQuery.db, 'user_notification');
    const unreadCountQuery = query(
      collection_,
      where('userId', '==', userId),
      where('locationId', 'in', selectedLocationIds),
      where('isDesktopNotification', '==', true),
      where('hasRead', '==', false)
    );

    const unsubscribe = onSnapshot(
      unreadCountQuery,
      function onNext(querySnapshot) {
        setUnreadCount(querySnapshot.size);
      },
      function onError(e) {
        sentry.error({
          error: e,
          topic: 'notifications',
        });
        console.error('Failed to fetch notification count from firestore', e);
        setUnreadCount(null);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [firestoreDbQuery?.db, userId, selectedLocationIds.join(',')]);

  useEffect(() => {
    if (!firestoreDbQuery.db || !userId || !selectedLocationIds.length) return;

    const conditions = [
      where('userId', '==', userId),
      where('locationId', 'in', selectedLocationIds),
      where('isDesktopNotification', '==', true),
      limit(100),
      orderBy('createdAt', 'desc'),
    ];

    if (notificationFilters.length) conditions.push(where('type', 'in', notificationFilters));
    const notificationListQuery = query(collection(firestoreDbQuery?.db, 'user_notification'), ...conditions);
    const unsubscribe = onSnapshot(
      notificationListQuery,
      function onNext(querySnapshot) {
        setFirebaseError(null);
        const notifications: NotificationDoc[] = [];

        querySnapshot.forEach((doc) => {
          const { createdAt, expireAt, ...rest } = doc.data();
          notifications.push({
            ...rest,
            // firestore dates do not display in the browser, so we need to format them
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore these types are wrong in schema they are Firestore timestamps, not strings
            createdAt: (createdAt as Timestamp).toDate(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore these types are wrong in schema they are Firestore timestamps, not strings
            expireAt: (expireAt as Timestamp).toDate(),
          });
        });

        setData(notifications);
      },
      function onError(e) {
        sentry.error({
          error: e,
          topic: 'notifications',
        });
        console.error('Failed to fetch notification documents from firestore', e);
        setFirebaseError(e);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [firestoreDbQuery.db, userId, notificationFilters.length, selectedLocationIds.join(',')]);

  const markThreadNotificationsAsRead = (threadId: string, locationId: string) => {
    if (!firestoreDbQuery.db || !threadId) return;
    const unreadThreadQuery = query(
      collection(firestoreDbQuery.db, 'user_notification').withConverter(converter),
      where('userId', '==', userId),
      where('locationId', '==', locationId),
      where('hasRead', '==', false),
      where('alert.payload.threadIds', 'array-contains', threadId)
    );
    const unsubscribe = onSnapshot(unreadThreadQuery, (querySnapshot) => {
      // if there aren't any unread notifications for this thread, we don't need to do anything
      if (querySnapshot.size === 0) return;
      const batch = writeBatch(firestoreDbQuery.db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { hasRead: true } as Pick<NotificationDoc, 'hasRead'>);
      });
      batch.commit().finally(unsubscribe);
    });
  };

  const toggleNotificationAsRead = async (notificationId: string, hasRead: boolean) => {
    if (!notificationId || !firestoreDbQuery?.db) return;
    const docToUpdateRef = doc(firestoreDbQuery?.db, 'user_notification', notificationId);
    const docSnap = await getDoc(docToUpdateRef);

    if (!docSnap.exists()) {
      console.error('Unable to find document');
    } else {
      await updateDoc(docToUpdateRef, { hasRead: !hasRead } as Pick<NotificationDoc, 'hasRead'>);
    }
  };

  const value = useMemo(
    () =>
      ({
        emit: emitter.emit,
        emitter,
        notifications: data,
        firebaseError,
        isLoading: data === null,
        markThreadNotificationsAsRead,
        toggleNotificationAsRead,
        unreadCount,
        notificationTrayIsOpen,
        setNotificationTrayIsOpen,
        maxLocationsSelected: selectedLocationIds.length > MAX_SELECTABLE_LOCATIONS,
      } satisfies ContextValue),
    [emitter, data, firebaseError, notificationTrayIsOpen]
  );

  useHandleEmptyQueue();

  return (
    <NotificationContext.Provider value={value}>
      <TransientQueueProvider>{children}</TransientQueueProvider>
      {soundOptions.map((sound) => (
        <audio key={sound.id} className='message-notification-audio' id={sound.id} src={sound.file} preload='none' />
      ))}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);

/**
 * This hook is used to set the notification mode in the shell based on the feature flag
 */
const useHandleShellNotificationsMode = () => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();

  const value = getFeatureFlagValue('pop-notifications-experiments');
  useEffect(() => {
    if (!shell.isShell) return;

    shell.emit?.('notification:mode', !!value ? 'experimental' : 'default');

    return () => {
      shell.emit?.('notification:mode', !!value ? 'experimental' : 'default');
    };
  }, [value]);
};

const useHandleEmptyQueue = () => {
  useEffect(() => {
    if (shell.isShell) {
      const id = 'empty-shell' + Math.random().toString();
      const handler = () => {
        shell.emit?.('notifications:hide', undefined);
      };
      shell.on?.('notifications:empty', handler, id);
      return () => {
        shell.off?.('notifications:empty', handler, id);
      };
    }
    return;
  }, []);
};
