import { useMemo } from 'react';
import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { useMutation } from 'react-query';
import { OfficeHoursApi } from '@frontend/api-office-hours';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import {
  convertFromRRuleScheduleHoursToScheduleHoursObject,
  convertScheduleHoursObjectToRRuleObject,
} from '../rrule-helper';
import { ScheduleHours } from '../types';
import { useSchedulingLocationInfo } from './use-scheduling-location-info';

export const useGetUpdateOfficeHoursMethod = (locationId: string) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const { getLocationTimeZone } = useSchedulingLocationInfo();
  const { isScheduleV3FlagEnabled, isLoading: isLoadingV3FeatureFlags } =
    SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  //#region V2 Code

  const officeHoursV2 = useQuery({
    queryKey: queryKeys.officeClosedTimes(locationId),
    queryFn: () => OfficeHoursApi.getOfficeHours(locationId),
    retry: 1,
    enabled: !isScheduleV3FlagEnabled && !isLoadingV3FeatureFlags && !!locationId,
  });

  const { mutate: updateOfficeHours, isLoading: isUpdatingOfficeHours } = useMutation({
    mutationFn: ({ locationId, schedule }: { locationId: string; schedule: ScheduleAvailabilityTypes.Schedule }) => {
      return OfficeHoursApi.updateOfficeHours(locationId, schedule);
    },
    onSuccess: () => {
      alert.success(t('Successfully updated schedule'));
      officeHoursV2.refetch();
    },
    onError: () => {
      alert.error(t('Failed to update schedule'));
    },
  });

  //#endregion

  //#region V3 Code
  const listSchedulesV3Query = SchedulerV3.Queries.useListSchedulesQuery({
    request: {
      locationId,
      ids: [locationId],
    },
    options: {
      enabled: !!locationId && isScheduleV3FlagEnabled && !isLoadingV3FeatureFlags,
    },
  });

  const officeHoursV3 = useMemo(() => {
    const officeHoursData: ScheduleHours = {
      workingHours: listSchedulesV3Query.data?.schedules[0]?.recurrenceRules?.workingHours ?? [],
      breaks: listSchedulesV3Query.data?.schedules[0]?.recurrenceRules?.breaks ?? [],
    };

    return convertFromRRuleScheduleHoursToScheduleHoursObject(officeHoursData);
  }, [listSchedulesV3Query.data]);

  const updateScheduleMutation = SchedulerV3.Mutations.useUpdateScheduleMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Office hours updated successfully'));
      },
      onError: () => {
        alert.error(t('Failed to update Office hours'));
      },
    },
  });

  const createScheduleMutation = SchedulerV3.Mutations.useCreateScheduleMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Office Hours created successfully'));
      },
      onError: () => {
        alert.error(t('Failed to create Office Hours'));
      },
    },
  });

  //#endregion

  const handleSave = (updatedScheduleAvailability: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek) => {
    if (!updatedScheduleAvailability) return;

    if (isScheduleV3FlagEnabled) {
      // v3 mutation
      const locationTimeZone = getLocationTimeZone(locationId);
      const { workingHours, breaks } = convertScheduleHoursObjectToRRuleObject(
        updatedScheduleAvailability,
        locationTimeZone
      );
      if (listSchedulesV3Query.data?.schedules[0]?.id) {
        updateScheduleMutation.mutate({
          id: listSchedulesV3Query.data?.schedules[0].id ?? '',
          locationId,
          type: listSchedulesV3Query.data?.schedules[0].type || ScheduleType.LOCATION,
          recurrenceRules: {
            ...listSchedulesV3Query.data?.schedules[0].recurrenceRules,
            workingHours,
            breaks,
          },
        });
      } else {
        createScheduleMutation.mutate({
          id: locationId,
          locationId,
          type: ScheduleType.LOCATION,
          recurrenceRules: { workingHours, breaks },
        });
      }
    } else {
      // v2 mutation
      updateOfficeHours({ locationId, schedule: updatedScheduleAvailability });
    }
  };

  return {
    onSave: handleSave,
    isSaving: isScheduleV3FlagEnabled
      ? updateScheduleMutation.isLoading || createScheduleMutation.isLoading
      : isUpdatingOfficeHours,
    isFetching: isScheduleV3FlagEnabled ? listSchedulesV3Query.isFetching : officeHoursV2.isFetching,
    officeHoursData: isScheduleV3FlagEnabled ? officeHoursV3 : officeHoursV2.data,
    hasOfficeHoursFetchError: isScheduleV3FlagEnabled ? listSchedulesV3Query.isError : officeHoursV2.isError,
    refetchOfficeHours: officeHoursV2.refetch, // For V3, we were already handling refetch in onSuccess
  };
};
