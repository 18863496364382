import { http } from '@frontend/fetch';
import { SchemaScheduleService } from '@frontend/schema';
import { Exception, GetOfficeHoursExceptionsForMultipleLocationsType } from './types';

const baseUrl = 'schedule/api/v2/offices';

export const getExceptions = async (locationId: string, query: string) => {
  const dateTimeQuery = query;

  return await http.getData<Exception[]>(`${baseUrl}/${locationId}/exceptions${dateTimeQuery}`);
};

export const getProviderExceptions = async (locationId: string, query: string, providerId: string) => {
  const dateTimeQuery = query;

  return await http.getData<Exception[]>(`${baseUrl}/${locationId}/providers/${providerId}/exceptions${dateTimeQuery}`);
};

export const createException = async (data: Exception, locationId: string) => {
  const url = data?.id ? `${baseUrl}/${locationId}/exceptions/${data?.id}` : `${baseUrl}/${locationId}/exceptions`;
  const method = data?.id ? 'put' : 'post';

  return await http[method]<Exception>(url, data);
};

export const createProviderException = async (data: Exception, locationId: string, providerId: string) => {
  const url = data?.id
    ? `${baseUrl}/${locationId}/providers/${providerId}/exceptions/${data?.id || ''}`
    : `${baseUrl}/${locationId}/providers/${providerId}/exceptions`;
  const method = data?.id ? 'put' : 'post';

  return await http[method]<Exception>(url, data);
};

export const deleteException = async (
  locationId: string,
  exceptionId: string,
  providerId?: string
): Promise<Exception> => {
  if (!exceptionId) {
    return Promise.reject('requires exception id');
  }
  if (providerId) {
    return await http.delete<Exception>(`${baseUrl}/${locationId}/providers/${providerId}/exceptions/${exceptionId}`);
  } else {
    return await http.delete<Exception>(`${baseUrl}/${locationId}/exceptions/${exceptionId}`);
  }
};

export const getOfficeHoursExceptionsForMultipleLocations = (
  req: GetOfficeHoursExceptionsForMultipleLocationsType['input']
): Promise<GetOfficeHoursExceptionsForMultipleLocationsType['output']> => {
  return SchemaScheduleService.ListCalendarExceptionForMultipleLocations(req);
};
