import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { TerminalReader } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import { Chip, NakedButton, Text, styles as dsStyles, useTooltip } from '@frontend/design-system';
import { getTerminalImage } from '../../../terminals/stripe/hooks/get-terminal-image';

const styles = {
  readerCard: css`
    max-width: ${theme.spacing(20)};
    padding: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.spacing(1.5)};
    word-break: break-word;
    text-align: center;
    width: 134px;
    position: relative;
    text-transform: unset;
    overflow: hidden;
    min-height: 157px;
  `,
  terminalIcon: css`
    width: ${theme.spacing(5)};
    margin-bottom: ${theme.spacing(1)};
  `,
  statusChip: css`
    position: absolute;
    right: ${theme.spacing(1)};
    top: ${theme.spacing(1)};
    z-index: ${theme.zIndex.highest};
  `,
  text: css`
    width: 100%;
  `,
  opacity: (isOffline: boolean) => css`
    ${isOffline && `opacity: 0.5;`}
  `,
};

export const ReaderCard = ({
  reader,
  // With server drive connection we will never be connected
  // we should change this to be default or last used reader
  isConnected = false,
  onClick,
}: {
  reader: TerminalReader;
  onClick: (reader: TerminalReader) => void;
  isConnected?: boolean;
}) => {
  const { t } = useTranslation('payments');
  const isOffline = reader.status === 'offline';
  const isInUse = reader?.action?.status === 'in_progress';

  const statusText = isOffline ? t('Offline') : isInUse ? t('In Use') : isConnected && t('Connected');
  const statusChipVariant = isOffline ? 'default' : isInUse ? 'critical' : isConnected && 'success';

  const image = getTerminalImage(reader.deviceType);

  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ initialOpen: false, trigger: 'hover' });
  const { ref: triggerRef, ...restTriggerProps } = triggerProps;
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <NakedButton css={styles.readerCard} onClick={() => onClick(reader)} disabled={isOffline}>
      {statusChipVariant && (
        <Chip css={styles.statusChip} variant={statusChipVariant}>
          {statusText}
        </Chip>
      )}
      <img src={image} css={[styles.terminalIcon, styles.opacity(isOffline)]} />
      <Text
        size='medium'
        css={[styles.opacity(isOffline), dsStyles.truncate, styles.text]}
        ref={(node) => {
          if (!node) return;
          if (node?.offsetWidth < node?.scrollWidth) {
            setShowTooltip(true);
            triggerRef(node);
          }
        }}
        {...restTriggerProps}
      >
        {reader.label}
      </Text>
      {showTooltip && <Tooltip {...tooltipProps}>{reader.label}</Tooltip>}
      <Text size='medium' color='subdued' css={[styles.opacity(isOffline), dsStyles.truncate, styles.text]}>
        {reader.deviceType}
      </Text>
    </NakedButton>
  );
};

export const AddReaderCard = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('payments');

  return (
    <NakedButton css={styles.readerCard} onClick={onClick}>
      <Icon name='plus' />
      <Text size='medium'>{t('Add Reader')}</Text>
    </NakedButton>
  );
};
