import { createContext, useContext, useState, ReactNode, useCallback, forwardRef } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion, MotionProps } from 'motion/react';
import { useScopedAppFlagStore } from '@frontend/scope';
import { WeavePopNotification, CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { NotificationComponent } from './notification-components';
import { useNotificationContext } from './notification-provider';
import { Notification } from './shared/notification';

type Placement = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

export interface TransientQueueProps {
  placement?: Placement;
}

export const TransientQueue = ({ placement = 'top-right' }: TransientQueueProps) => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isExperimentalMode = getFeatureFlagValue('pop-notifications-experiments');
  const { queue, activeIndex, setActiveIndex, removeAll } = useTransientQueueContext();
  const [x, y] = placement.split('-');
  const ctx = useNotificationContext();

  const lastFocusedNotification = queue.find((item) => item.state.paused === true);
  const notificationInView = lastFocusedNotification || queue[activeIndex ?? 0];
  const hasPreference = queue.some((item) => item.type === 'preference');
  const hasSlider = queue.length > 1;

  return (
    <AnimatePresence>
      {notificationInView &&
        (isExperimentalMode ? (
          <motion.div
            style={{
              ...(x === 'top' ? { top: 80 } : { bottom: 0 }),
              ...(y === 'right' ? { right: 50 } : { left: 0 }),
              display: 'flex',
              position: 'fixed',
              zIndex: theme.zIndex.alerts,
              flexDirection: 'column',
              gap: theme.spacing(1),
              padding: theme.spacing(1),
            }}
            animate={{ top: hasSlider ? 40 : 80 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <Notification
              notification={notificationInView}
              onEmit={(action, notification) => {
                ctx.emit(notification.type, {
                  action: typeof action === 'string' ? action : action.action,
                  payload: typeof action === 'string' ? undefined : action?.payload,
                  notification: notification as any,
                });
              }}
              ContainerComponent={TransientItemContainer}
              containerProps={{ id: notificationInView.id, xAlignment: 'center' }}
              containerStyles={css`
                border-radius: ${theme.borderRadius.medium};
                box-shadow: ${theme.shadows.heavy};
                position: relative;
              `}
            >
              <Notification.ProgressBar />
              <AnimatePresence>
                {queue.length > 1 && (
                  <Notification.Slider
                    disableClearAll={hasPreference}
                    onClearAll={removeAll}
                    currIndex={activeIndex}
                    total={queue.length}
                    onChange={setActiveIndex}
                    queue={queue}
                  />
                )}
              </AnimatePresence>
              <Notification.Content stacked={queue.length > 1} />
            </Notification>
          </motion.div>
        ) : (
          <DefaultNotification notificationInView={notificationInView} x={x} y={y} />
        ))}
    </AnimatePresence>
  );
};

const DefaultNotification = ({
  notificationInView,
  x,
  y,
}: {
  notificationInView: WeavePopNotification;
  x: string;
  y: string;
}) => {
  const ctx = useNotificationContext();

  return (
    <motion.div
      style={{
        ...(x === 'top' ? { top: 80 } : { bottom: 0 }),
        ...(y === 'right' ? { right: 0 } : { left: 0 }),
        display: 'flex',
        position: 'fixed',
        flexDirection: 'column',
        zIndex: theme.zIndex.alerts,
        gap: theme.spacing(1),
        padding: theme.spacing(1),
      }}
    >
      <TransientItemContainer key={notificationInView.id} id={notificationInView.id} xAlignment={x as 'left' | 'right'}>
        <NotificationComponent
          key={notificationInView.id}
          notification={notificationInView}
          emit={(e, notification) => {
            ctx.emit(notificationInView.type, {
              action: e.action,
              payload: e.payload,
              notification: notification as any, //TODO: @gisheri - try to avoid this typecast
            });
          }}
        />
      </TransientItemContainer>
    </motion.div>
  );
};

export type AddOptions = {
  duration?: number;
};

type TransientQueueContextType = {
  queue: WeavePopNotification[];
  add: (notification: WeavePopNotification, options?: AddOptions) => void;
  remove: (id: string) => void;
  removeAll: () => void;
  update: (notification: WeavePopNotification) => void;
  setActiveIndex: (index: number) => void;
  activeIndex: number;
};

const TransientQueueContext = createContext<TransientQueueContextType>({
  queue: [],
  add: (_item, _options) => {
    console.warn('Notification Queue Not Initialized');
  },
  remove: (_id) => {},
  removeAll: () => {},
  update: (_item) => {},
  setActiveIndex: (_index) => {},
  activeIndex: 0,
});

const useTransientQueueContext = () => useContext(TransientQueueContext);

export const TransientQueueProvider = ({ children }: { children: ReactNode }) => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isExperimentalMode = getFeatureFlagValue('pop-notifications-experiments');
  const [queue, setQueue] = useState<WeavePopNotification[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const remove = useCallback(
    (id: string) => {
      setQueue((prev) => {
        const newQueue = prev.filter((item) => item.id !== id);

        if (isExperimentalMode) {
          setActiveIndex((prevIndex) => (newQueue.length === 0 ? 0 : Math.min(prevIndex, newQueue.length - 1)));
        }

        return newQueue;
      });
    },
    [isExperimentalMode]
  );

  const add = useCallback(
    (notification: WeavePopNotification) => {
      if (isExperimentalMode) {
        setQueue((prev) => [...prev, notification]);
      } else {
        setQueue((prev) => [notification, ...prev]);
      }
    },
    [isExperimentalMode]
  );

  const removeAll = useCallback(() => {
    setActiveIndex(0);
    setQueue([]);
  }, []);

  const update = useCallback((notification: WeavePopNotification) => {
    setQueue((prev) => prev.map((p) => (p.id === notification.id ? ({ ...p, ...notification } as typeof p) : p)));
  }, []);

  return (
    <TransientQueueContext.Provider value={{ queue, add, remove, removeAll, update, setActiveIndex, activeIndex }}>
      {children}
    </TransientQueueContext.Provider>
  );
};

export { useTransientQueueContext as useTransientQueue };

type TransientItemContainerProps = {
  children: ReactNode;
  id: string;
  onClick?: (id: string) => void;
  xAlignment: 'left' | 'right';
} & CommonHTMLAttributes &
  MotionProps;

export const TransientItemContainer = forwardRef<HTMLDivElement, TransientItemContainerProps>(
  ({ children, id, onClick, xAlignment, ...rest }, ref) => {
    const x = xAlignment === 'left' ? '-100%' : '100%';
    return (
      <motion.div
        ref={ref}
        initial={{ x }}
        animate={{ x: 0 }}
        exit={{ x }}
        transition={{ ease: 'easeOut', duration: 0.1 }}
        onClick={() => onClick?.(id)}
        {...rest}
      >
        {children}
      </motion.div>
    );
  }
);
TransientItemContainer.displayName = 'TransientItemContainer';
