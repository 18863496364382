import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text, ConfirmationModal, useModalControl } from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';

interface Props {
  conversationId: string;
}

export const LeaveConversation = ({ conversationId }: Props) => {
  const { t } = useTranslation('team-chat');
  const { openModal, modalProps } = useModalControl();
  const removeMembersFromConversation = useTeamChatSelector((ctx) => ctx.removeMembersFromConversation);
  const currentUser = useTeamChatSelector((ctx) => ctx.user);
  const leaveChannel = async () => {
    if (!currentUser?.userID) {
      return;
    }
    removeMembersFromConversation({
      conversationId,
      userIds: [currentUser.userID],
      message: `${getUserFullName(currentUser?.firstName, currentUser?.lastName)} left the conversation`,
    });
  };

  return (
    <>
      <Button
        destructive
        variant='tertiary'
        css={css({ margin: theme.spacing(2, 2, 2, 0.5) })}
        onClick={openModal}
        trackingId='team-chat-2.0-leave-channel'
      >
        {t('Leave Channel')}
      </Button>
      <ConfirmationModal
        onConfirm={leaveChannel}
        title={t('Leave Conversation')}
        maxWidth={MIN_MODAL_WIDTH}
        destructive
        confirmTrackingId='team-chat-2.0-leave-channel-confirm'
        cancelTrackingId='team-chat-2.0-leave-channel-cancel'
        {...modalProps}
      >
        <Text>{t('Are you sure you want to leave this conversation?')}</Text>
      </ConfirmationModal>
    </>
  );
};
