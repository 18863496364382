import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedUl, Text } from '@frontend/design-system';

interface NoShowImprovementItemProps {
  description: string;
  iconName: IconName;
  title: string;
}

const NoShowImprovementItem = ({ description, iconName, title }: NoShowImprovementItemProps) => {
  return (
    <li className='no-show-item'>
      <Icon color='primary' name={iconName} size={24} style={{ minWidth: theme.spacing(3) }} />
      <div>
        <Text size='large' weight='bold'>
          {title}
        </Text>
        <Text size='medium'>{description}</Text>
      </div>
    </li>
  );
};

export const NoShowRateBestPractices = () => {
  const { t } = useTranslation('analytics');

  return (
    <NakedUl css={styles.wrapper}>
      <NoShowImprovementItem
        description={t(
          "Include the patient's name, appointment details, and a clear action (e.g., “Reply YES to confirm”)."
        )}
        iconName='message'
        title={t('Personalize Messages')}
      />
      <NoShowImprovementItem
        description={t('Send reminders at optimal times of the day, such as 48 and 24 hours before the appointment.')}
        iconName='clock'
        title={t('Optimize Reminder Timing')}
      />
      <NoShowImprovementItem
        description={t(
          'Consider sending an additional reminder for unconfirmed appointments & implementing an appointment cancellation fee.'
        )}
        iconName='calendar'
        title={t('Send Additional reminders')}
      />
    </NakedUl>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
    overflow: auto;

    .no-show-item {
      align-items: center;
      background-color: ${theme.colors.primary5};
      border-radius: ${theme.borderRadius.medium};
      display: flex;
      gap: ${theme.spacing(2)};
      padding: ${theme.spacing(2)};
    }
  `,
};
