import { WeaveLocationGroup } from '@frontend/scope';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

type LocationFiltersRecord = {
  voicemailBoxesIndex: string[];
  callQueuesIndex: string[];
  callGroupsIndex: string[];
  phoneTreesIndex: string[];
  callRoutesIndex: string[];
  phoneNumbersIndex: string[];
  overridesIndex: string[];
  phoneHoursIndex: string[];
};

type PhoneSettingsStore = {
  settingsTenantLocation: WeaveLocationGroup | undefined;
  setSettingsTenantLocation: (location?: WeaveLocationGroup) => void;
  globalAvailableLocationIds: string[] | undefined;
  setGlobalAvailableLocationIds: (ids: string[]) => void;
  openTenantPicker: () => void;
  closeTenantPicker: () => void;
  setOpenTenantPicker: (fn: () => void) => void;
  setCloseTenantPicker: (fn: () => void) => void;
};

export const usePhoneSettingsStore = createStoreWithSubscribe<PhoneSettingsStore>(
  (set) => ({
    settingsTenantLocation: undefined,
    globalAvailableLocationIds: undefined,
    setSettingsTenantLocation: (location) => {
      set({ settingsTenantLocation: location });
    },
    setGlobalAvailableLocationIds: (locationIds) => {
      set({ globalAvailableLocationIds: locationIds });
    },
    openTenantPicker: () => {
      console.error('openTenantPicker not set');
    },
    closeTenantPicker: () => {
      console.error('closeTenantPicker not set');
    },
    setOpenTenantPicker: (fn) => {
      set({ openTenantPicker: fn });
    },
    setCloseTenantPicker: (fn) => {
      set({ closeTenantPicker: fn });
    },
  }),
  {
    name: 'PhoneSettingsStore',
    trace: true,
  }
);

export const usePhoneSettingsShallowStore = createShallowStore<PhoneSettingsStore>(usePhoneSettingsStore);

export type PhoneLocationFilterStore = {
  locationFilters: LocationFiltersRecord;
  setLocationFilters: (filters: Partial<LocationFiltersRecord>) => void;
};

export const usePhoneLocationFilterStore = createStoreWithSubscribe<PhoneLocationFilterStore>(
  (set) => ({
    locationFilters: {
      voicemailBoxesIndex: [],
      callQueuesIndex: [],
      callGroupsIndex: [],
      phoneTreesIndex: [],
      callRoutesIndex: [],
      phoneNumbersIndex: [],
      overridesIndex: [],
      phoneHoursIndex: [],
    },
    setLocationFilters: (filters) => {
      set((state) => ({ locationFilters: { ...state.locationFilters, ...filters } }));
    },
  }),
  {
    name: 'PhoneLocationFilterStore',
    trace: true,
  }
);

export const usePhoneLocationFilterShallowStore =
  createShallowStore<PhoneLocationFilterStore>(usePhoneLocationFilterStore);
