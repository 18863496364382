import { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, Tabs, Heading, styles } from '@frontend/design-system';
import { ChannelSettingsController, MIN_MODAL_WIDTH, DM_MODAL_HEIGHT, CHANNEL_MODAL_HEIGHT } from '../../../constants';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';
import { About } from './about-section';
import { MembersSelection } from './members-selection';

// Dynamically calculated the width of the conversation name according to the number of members and the width fo avatar
const CONVERSATION_NAME_WIDTH: { [key: number]: number } = { 0: 500, 1: 477, 2: 440, 3: 420, 4: 400 };

interface ConversationSettingsProps {
  modalProps: ModalControlModalProps;
  initialTab: ChannelSettingsController;
  conversationId: string;
}

export const ConversationSettings: FC<ConversationSettingsProps> = ({
  modalProps,
  initialTab,
  conversationId,
}: ConversationSettingsProps) => {
  const { t } = useTranslation('team-chat');
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const users = useTeamChatSelector((ctx) => ctx.users);

  const conversation = useMemo(() => helpers.getConversation(conversationId), [helpers, conversationId]);
  const memberIds = conversation?.memberIds ?? [];
  const members = useMemo(
    () => memberIds.map((memberId) => users?.find((user) => user.userID === memberId)).filter((user) => !!user),
    [memberIds, users]
  );

  // TODO: added this as comment because we might wanna add the tooltip again
  // const { Tooltip, tooltipProps, triggerProps } = useTooltip({ hoverDelay: 500 });

  const dynamicConversationWidth = CONVERSATION_NAME_WIDTH[memberIds.length > 4 ? '4' : memberIds.length];

  const actions = useMemo(() => {
    const baseActions = [
      {
        title: t('About'),
        controller: ChannelSettingsController.about,
        component: <About conversationId={conversationId} />,
        controls: 'conversation-settings-about',
      },
      {
        title: t('Members'),
        controller: ChannelSettingsController.members,
        component: <MembersSelection conversationId={conversationId} />,
        controls: 'conversation-settings-members',
        // +1 to account for the current user
        count: members.length + 1,
      },
      // TODO: removing this settings as of now, will take further actions after Product's confirmation
      // {
      //   title: t('Notification Settings'),
      //   controller: ChannelSettingsController.notifications,
      //   component: <NotificationsSettings conversation={conversation} />,
      //   controls: 'conversation-settings-notifications-settings',
      // },
      // // TODO: removing this settings as of now, will take further actions after Product's confirmation
      // {
      //   title: conversation?.type === 'DM' ? t('Direct Message Settings') : t('Channel Settings'),
      //   controller: ChannelSettingsController.channel,
      //   component: conversation ? <AdvancedChannelSettings conversation={conversation} /> : null,
      //   controls: 'conversation-settings-channel-settings',
      // },
    ];
    return baseActions;
  }, [conversation?.type, conversation?.memberIds]);

  return (
    <Modal
      {...modalProps}
      css={{
        minWidth: MIN_MODAL_WIDTH,
        overflow: 'hidden',
        height: conversation?.type === 'DM' ? DM_MODAL_HEIGHT : CHANNEL_MODAL_HEIGHT,
      }}
    >
      <Tabs initialTab={initialTab}>
        <Modal.Header onClose={modalProps.onClose} css={conversationSettingsStyles.modalHeader}>
          <span css={modalHeadingStyles}>
            {conversation?.type === 'DM' ? (
              <ChatAvatar users={members} showGroupAvatars />
            ) : (
              <Heading level={2}>#</Heading>
            )}
            <Text css={[conversationNameStyle, { maxWidth: dynamicConversationWidth }]} as='p'>
              {conversation?.name}
            </Text>
          </span>
        </Modal.Header>
        <Modal.Body css={modalBodyStyle}>
          <Tabs.Bar>
            {actions.map(({ title, controls, controller, count }) => (
              <Tabs.Tab id={controller} controls={controls} key={`tab-${controller}-${controls}`}>
                <Text id={title} as='span' css={tabContainerStyle}>
                  {title} {count}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.Bar>
          {actions.map(({ component, controller, controls }) => (
            <Tabs.Panel
              controller={controller}
              id={controls}
              key={`tab-component-${controller}-${controls}`}
              css={tabPanelStyle}
            >
              {component}
            </Tabs.Panel>
          ))}
        </Modal.Body>
      </Tabs>
    </Modal>
  );
};

const conversationSettingsStyles = {
  modalHeader: css({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }),
};

const modalHeadingStyles = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  marginBottom: theme.spacing(1),
});

const tabPanelStyle = css({ height: '100%', overflow: 'auto', flexGrow: 1 });

const modalBodyStyle = css({ overflow: 'hidden', display: 'flex', height: '100%', flexDirection: 'column' });

/* 
  The width is restricted to 400px as per the calculation. The width of all elements minus the width of modal 
  width that leads to 400px.
*/
const conversationNameStyle = css([styles.truncate, { fontSize: theme.fontSize(30) }]);
const tabContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
});
