import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { uniq } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, Button, ConfirmationModal, useModalControl, Modal } from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useActiveConversationSelector } from '../../../providers/active-conversation.provider';
import { useTeamChatSelector, useTeamChat } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { AddButton } from '../../common/add-button/add-button';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';
import { AddMembers } from './add-members';

interface Props {
  conversationId: string;
}

function createUpdatedUsersSentence(
  currentUser: NonNullable<ReturnType<typeof useTeamChat>['users']>[0],
  selectedMemberIds: string[],
  helpers: ReturnType<typeof useTeamChat>['helpers'],
  action = 'added',
  maxListed = 4
) {
  // Get the full name of the user who performed the action
  const actorName = getUserFullName(currentUser?.firstName, currentUser?.lastName) || 'Someone';

  // Filter out invalid members and get their full names
  const memberNames = selectedMemberIds
    .map((memberId) => {
      const member = helpers.getUser(memberId);
      return member ? getUserFullName(member?.firstName, member?.lastName) : null;
    })
    .filter((name) => name); // Remove null/undefined entries

  // Handle different cases for the member list
  let membersText = '';
  if (memberNames.length === 0) {
    membersText = 'no one';
  } else if (memberNames.length === 1) {
    membersText = memberNames[0] || 'someone';
  } else if (memberNames.length === 2) {
    membersText = `${memberNames[0]} and ${memberNames[1]}`;
  } else if (memberNames.length <= maxListed) {
    const allButLast = memberNames.slice(0, -1).join(', ');
    const lastMember = memberNames[memberNames.length - 1];
    membersText = `${allButLast}, and ${lastMember}`;
  } else {
    // When exceeding maxListed, list up to maxListed and add "and X more"
    const listedNames = memberNames.slice(0, maxListed);
    const remainingCount = memberNames.length - maxListed;
    const allButLast = listedNames.slice(0, -1).join(', ');
    const lastListed = listedNames[listedNames.length - 1];
    membersText = `${allButLast}, ${lastListed}, and ${remainingCount} more`;
  }

  return `${actorName} ${action} ${membersText}.`;
}

export const MembersSelection = ({ conversationId }: Props) => {
  const { t } = useTranslation('team-chat');
  const currentUser = useTeamChatSelector((ctx) => ctx.user);
  const users = useTeamChatSelector((ctx) => ctx.users);
  const removeMembersFromConversation = useTeamChatSelector((ctx) => ctx.removeMembersFromConversation);
  const addMembersToConversation = useTeamChatSelector((ctx) => ctx.addMembersToConversation);
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const activeConversationId = useTeamChatSelector((ctx) => ctx.activeConversationId);
  const refetchMessages = useActiveConversationSelector((ctx) => ctx.refetchMessages);
  const confirmationModalProps = useModalControl();
  const addMembersModalProps = useModalControl();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [selectedUserIdForDeletion, setSelectedUserIdForDeletion] = useState<string>();

  const conversation = useMemo(() => helpers.getConversation(conversationId), [helpers, conversationId]);
  const isActiveConversation = activeConversationId === conversationId;
  const isDm = conversation?.type === 'DM';

  const removeMember = async (userId: string) => {
    if (!currentUser) {
      return;
    }
    const text = createUpdatedUsersSentence(currentUser, [userId], helpers, 'removed');
    await removeMembersFromConversation({ conversationId, userIds: [userId], message: text });
    if (isActiveConversation) {
      await refetchMessages();
    }
    return;
  };

  const memberIds = conversation?.memberIds ?? [];
  const members = useMemo(
    () => [
      ...(currentUser ? [currentUser] : []),
      ...(uniq(memberIds)
        ?.filter((user) => user !== currentUser?.userID)
        ?.map((memberId) => users?.find((user) => user.userID === memberId))
        ?.filter((user): user is NonNullable<typeof user> => !!user) ?? []),
    ],
    [memberIds, users, currentUser]
  );

  const onAddMembers = async () => {
    if (!currentUser) {
      return;
    }
    const text = createUpdatedUsersSentence(currentUser, selectedMemberIds, helpers);
    await addMembersToConversation({ conversationId, userIds: selectedMemberIds, message: text }).then(() => {
      addMembersModalProps.closeModal();
      setSelectedMemberIds([]);
    });
    if (isActiveConversation) {
      await refetchMessages();
    }
    return;
  };

  const onSecondaryClick = () => {
    addMembersModalProps.closeModal();
    setSelectedMemberIds([]);
  };

  const onClickDelete = (userId: string) => {
    setSelectedUserIdForDeletion(userId);
    confirmationModalProps.openModal();
  };
  const onConfirmDelete = async () => {
    if (selectedUserIdForDeletion) {
      try {
        await removeMember(selectedUserIdForDeletion);
        confirmationModalProps.closeModal();
      } catch (error) {
        throw new Error('Unable to delete');
      }
    }
  };

  return (
    <section>
      {members.length === 0 && <Text>Add some members to get started.</Text>}
      {!isDm && (
        <AddButton
          size={32}
          textSize='large'
          title='Add member'
          onClick={addMembersModalProps.openModal}
          className='members-children'
          style={buttonStyle}
          trackingId='team-chat-2.0-add-new-members-button'
        />
      )}
      {members.map((member) => (
        <div
          css={memberContainerStyle}
          className='members-children'
          key={`channel-settings-member-list-${member.userID}`}
        >
          <ChatAvatar size='small' users={[member]} />
          <Text weight='regular' size='large'>
            {member.firstName} {member.lastName}
          </Text>
          {currentUser?.userID !== member.userID && !isDm && (
            <Button
              aria-label={'Remove Member'}
              onClick={() => onClickDelete(member.userID)}
              css={{ marginLeft: 'auto', svg: { width: '20px !important', height: '20px !important' } }}
              iconName='trash'
              variant='secondary'
              trackingId='team-chat-2.0-remove-user-from-group'
            />
          )}
        </div>
      ))}
      <ConfirmationModal
        title={t('Remove Member')}
        onCancel={confirmationModalProps.closeModal}
        onConfirm={onConfirmDelete}
        destructive
        cancelTrackingId='team-chat-2.0-remove-user-modal-cancel-button'
        confirmTrackingId='team-chat-2.0-remove-user-modal-confirmation-button'
        {...confirmationModalProps.modalProps}
      >
        <Text>{t('Are you sure you want to remove this member?')}</Text>
      </ConfirmationModal>
      <Modal {...addMembersModalProps.modalProps} minWidth={MIN_MODAL_WIDTH}>
        <Modal.Header onClose={addMembersModalProps.closeModal}>{t('Add Members')}</Modal.Header>
        <Modal.Body css={addMemberModalBodyStyle}>
          <AddMembers
            selectedMemberIds={selectedMemberIds}
            onChange={(memberIds) => setSelectedMemberIds(memberIds)}
            existingMemberIds={conversation?.memberIds ?? []}
            show={addMembersModalProps.modalProps.show}
          />
        </Modal.Body>
        <Modal.Actions
          primaryLabel={t('Add')}
          secondaryLabel={t('Cancel')}
          disablePrimary={selectedMemberIds.length === 0}
          onSecondaryClick={onSecondaryClick}
          onPrimaryClick={onAddMembers}
          primaryTrackingId='team-chat-2.0-add-members-modal-done-button'
          secondaryTrackingId='team-chat-2.0-add-members-modal-close-button'
        />
      </Modal>
    </section>
  );
};

const memberContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0.5, 1),
});

const buttonStyle = {
  width: 'fit-content ',
  minWidth: 'fit-content',
  marginBottom: theme.spacing(1),
};

const addMemberModalBodyStyle = css({
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});
