import { useEffect, useState } from 'react';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import {
  VoicemailEventType,
  VoicemailUpdateEvent,
} from '@weave/schema-gen-ts/dist/shared/phone/v1/voicemail/message.pb';
import { InfiniteData } from 'react-query';
import { VoicemailApi } from '@frontend/api-voicemails';
import { useScopedQuery } from '@frontend/scope';
import { queryKeys } from '../../query-keys';
import { VoicemailQueryData } from '../all-calls/types';
import { createVoicemailObject } from './voicemail-utils';

// This function updates the voicemail data in the react query cache based on the voicemail event
// to be re-rendered in the UI
export const updateVoicemailData = (
  event: VoicemailUpdateEvent,
  getVoicemailsData: () => InfiniteData<VoicemailQueryData> | undefined,
  setVoicemailData: (data: InfiniteData<VoicemailQueryData>) => void
) => {
  const voicemailsData = getVoicemailsData();
  switch (event.type) {
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_READ:
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_UNREAD: {
      if (!voicemailsData) {
        return;
      }
      const voicemail = voicemailsData.pages
        .map((page) => page.data)
        .flat()
        .find((vm) => vm.voicemailId === event.voicemail?.id);
      if (!voicemail) {
        // don't do anything if voicemail is not found
        return;
      }
      if (event.type === VoicemailEventType.VOICEMAIL_EVENT_TYPE_READ) {
        voicemail.readAt = event.voicemail?.readEpoch?.toString() ?? new Date().toISOString();
      } else {
        voicemail.readAt = '';
      }
      setVoicemailData(voicemailsData);
      break;
    }
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_DELETE: {
      if (!voicemailsData) {
        return;
      }

      let voicemailFound = false;
      for (const page of voicemailsData.pages) {
        const voicemailIndex = page.data.findIndex((vm) => vm.voicemailId === event.voicemail?.id);
        if (voicemailIndex !== -1) {
          page.data.splice(voicemailIndex, 1);
          voicemailFound = true;
          break; // Stop iterating as soon as the voicemail is found and removed
        }
      }
      if (!voicemailFound) {
        // don't do anything if voicemail is not found
        return;
      }
      setVoicemailData(voicemailsData);
      break;
    }
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_NEW: {
      //onNewVoicemailMessage(event);
      break;
    }
  }
};

// when a new voicemail message is received, it calls an endpoint to get the hydrated voicemail message and adds it to the cache
export const useAddVoicemailMessage = (
  getVoicemailsData: () => InfiniteData<VoicemailQueryData> | undefined,
  setVoicemailData: (data: InfiniteData<VoicemailQueryData>) => void,
  selectedLocationIdsExcludingParent: string[],
  mappedTags: Record<string, Tag>,
  mailboxToNameMap: Record<string, string>
) => {
  const [newVoicemailEvent, setNewVoicemailEvent] = useState<VoicemailUpdateEvent | undefined>(undefined);

  const { refetch } = useScopedQuery({
    queryKey: queryKeys.voicemailMessage(newVoicemailEvent?.mailboxId, newVoicemailEvent?.voicemailId),
    queryFn: () => VoicemailApi.getVoicemail({ messageId: newVoicemailEvent?.voicemailId ?? '' }),
    enabled: false, // This is disabled by default, and will be enabled when a new voicemail message is received
    onSuccess: (data) => {
      if (!data || !data.voicemail) {
        return;
      }
      const newVoicemail = createVoicemailObject(
        data.voicemail,
        selectedLocationIdsExcludingParent,
        mappedTags,
        mailboxToNameMap
      );
      const voicemailsData = getVoicemailsData();
      if (!voicemailsData) {
        console.log('voicemail realtime update failed. voicemailsData is not available in query cache');
        return;
      }
      if (newVoicemail.forwardedMessageId) {
        // if it's a forwarded message, add the voicemail right before the original voicemail
        for (const page of voicemailsData.pages) {
          // the channelID will be the same for the original voicemail and the forwarded voicemail
          // in case the original voicemail was deleted, then use createdAt to find the correct position
          const voicemailIndex = page.data.findIndex(
            (vm) => vm.channelId === newVoicemail.channelId || vm.createdAt < newVoicemail.createdAt
          );
          if (voicemailIndex !== -1) {
            page.data.splice(voicemailIndex, 0, newVoicemail);
            break; // Stop iterating as soon as the voicemail is found
          }
        }
      } else {
        // if it's a new voicemail, add it at the beginning of the list
        voicemailsData.pages[0].data.unshift(newVoicemail);
      }

      setVoicemailData(voicemailsData);
    },
    retry: 3,
    retryDelay: (attempt) => {
      // In real time update, sometimes the voicemail message is not available immediately after the event is received
      // Exponential backoff with a maximum delay of 2 minutes (120000 ms)
      const delay = Math.min(4 ** attempt * 1000, 120000);
      console.log(`Retrying to fetch voicemail message in ${delay} ms. Attempt ${attempt}`);
      return delay;
    },
  });

  useEffect(() => {
    if (newVoicemailEvent) {
      refetch();
    }
  }, [newVoicemailEvent, refetch]);

  const onNewVoicemailMessage = (event: VoicemailUpdateEvent) => {
    setNewVoicemailEvent(event);
  };

  return { onNewVoicemailMessage };
};
