import { IGif } from '@giphy/js-types';

export const getRelativeImageHeight = (image: IGif, targetWidth: number) => {
  const { width, height } = image.images.preview_gif;
  const widthQuotient = targetWidth / width;
  const relativeHeight = widthQuotient * height;

  return relativeHeight;
};

export const generateImageColumns = (gifs: IGif[], columnCount = 3, width = 350) => {
  if (!gifs) return [];
  const columnHeights = Array(columnCount).fill(0);
  const columns: IGif[][] = [...Array(columnCount)].map(() => []);

  gifs.forEach((gif) => {
    const smallestHeight = Math.min(...columnHeights);
    const indexOfSmallestHeight = columnHeights.indexOf(Math.min(...columnHeights));

    const smallestColumn = columns[indexOfSmallestHeight];
    smallestColumn.push(gif);
    const height = getRelativeImageHeight(gif, width / columnCount);
    columnHeights[indexOfSmallestHeight] = smallestHeight + height;
  });

  return columns;
};
