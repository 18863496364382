import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  DeviceMake_Enum,
  DeviceType_Enum,
  ListDevice,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { ContactDirectoriesQuery } from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Table, useTooltip, Text, ContentLoader, Chip } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useGetContactDirectoriesForEachDeviceId } from '../hooks';

type Props = {
  setSelectedDevicesIds: Dispatch<SetStateAction<string[] | undefined>>;
  contactDirectoryId?: string;
};

export const AssignDevicesToDirectoryTable = ({ setSelectedDevicesIds, contactDirectoryId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const [filteredGlobalData, setFilteredGlobalData] = useState<ListDevice[] | null>(null);
  const { globalAvailableLocationIds: locationIds, settingsTenantLocation } = usePhoneSettingsShallowStore(
    'globalAvailableLocationIds',
    'settingsTenantLocation'
  );
  const tenantId = settingsTenantLocation?.phoneTenantId;

  const {
    devices,
    deviceWithContactDirectories,
    isLoading: isLoadingDeviceInfo,
  } = useGetContactDirectoriesForEachDeviceId({
    locationIds: locationIds ?? [],
    tenantId: settingsTenantLocation?.phoneTenantId ?? '',
  });

  const { useGetContactDirectoryDevices } = ContactDirectoriesQuery.useContactDirectoryQueries({
    tenantId,
  });

  const { data: devicesByContactDirectoryId, isLoading: isLoadingPreSelectedDevices } = useGetContactDirectoryDevices(
    { contactListId: contactDirectoryId ?? '' },
    {
      enabled: !!contactDirectoryId && !!devices.length,
    }
  );

  useEffect(() => {
    if (!!devicesByContactDirectoryId?.deviceIds.length) {
      setSelectedDevicesIds(devicesByContactDirectoryId?.deviceIds);
    }
  }, [devicesByContactDirectoryId]);

  const preSelectedDevices = devicesByContactDirectoryId?.deviceIds?.reduce((acc, sum) => {
    return {
      ...acc,
      [sum]: true,
    };
  }, {} as Record<string, boolean>);

  const isLoading = isLoadingDeviceInfo || isLoadingPreSelectedDevices;

  return (
    <>
      {isLoading ? (
        <ContentLoader show />
      ) : (
        <Table
          data={filteredGlobalData ?? devices ?? ([] as typeof devices)}
          isSelectable
          isPaginated
          fullHeight
          fullHeightConfig={{
            offset: contactDirectoryId ? 350 : 475,
          }}
          hasGlobalSearch
          hasResponsiveColWidths
          clientPaginationConfig={{
            defaultRowsPerPage: 25,
          }}
          globalSearchConfig={{
            label: t('Search Device Name or Extension'),
            searchHandler: (term) => {
              const searchedResults = devices?.filter(
                (data) =>
                  data.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                  data.sipProfiles?.[0]?.extension?.extensionNumber === +term
              );
              setFilteredGlobalData(searchedResults ?? null);
            },
          }}
          rowSelectionConfig={{
            isRowDisabled: (device, obj) => {
              const isDeviceInDirectory = deviceWithContactDirectories?.[device.deviceId]?.contactListIds.includes(
                contactDirectoryId ?? ''
              );
              return (
                device.make !== DeviceMake_Enum.YEALINK ||
                (deviceWithContactDirectories?.[device.deviceId]?.contactListIds?.length === 5 &&
                  !obj.isSelected &&
                  !isDeviceInDirectory)
              );
            },
            initialState: preSelectedDevices,
            onSelectionToggle: (_, __, data) => {
              setSelectedDevicesIds(Object.keys(data));
            },
          }}
          uniqueRowId={({ deviceId }) => deviceId}
          colConfig={[
            {
              Header: t('Device Name'),
              id: 'device-name',
              accessor: (cell) => cell,
              cellRenderer: (cell: ListDevice) => {
                const tooltipText =
                  (cell.make === DeviceMake_Enum.POLYCOM &&
                    t('Contact lists cannot yet be assigned to Polycom phones')) ||
                  (cell.type === DeviceType_Enum.SOFTPHONE && t('Contacts lists cannot be assigned to Softphones')) ||
                  (deviceWithContactDirectories?.[cell.deviceId]?.contactListIds?.length === 5 &&
                    t('This device has reached the maximum of 5 assigned lists'));
                return (
                  <div css={deviceNameCellStyles}>
                    <div css={{ width: '95%' }}>{cell.name}</div>
                    {!!tooltipText && <ContactTooltip text={tooltipText} />}
                  </div>
                );
              },
            },
            {
              Header: t('Extension'),
              id: 'extension',
              accessor: (cell) => cell.sipProfiles?.[0]?.extension?.extensionNumber,
              width: 125,
            },
            {
              Header: t('Location'),
              id: 'location',
              accessor: (device) => device,
              cellRenderer: (device) => {
                if (!device.location?.name) return;
                return <Chip.SingleChip>{device.location?.name}</Chip.SingleChip>;
              },
              disableSortBy: true,
              omit: locationIds?.length === 1,
              width: 150,
            },
            {
              Header: t('Number of Assigned Lists'),
              id: 'assigned-lists',
              disableSortBy: true,
              accessor: (cell) => cell,
              cellRenderer: (device) => {
                if (!!deviceWithContactDirectories?.[`${device.deviceId}`]?.contactListIds?.length) {
                  return (
                    <Text>
                      {t('{{count}} lists', {
                        count: deviceWithContactDirectories?.[device.deviceId]?.contactListIds?.length,
                      })}
                    </Text>
                  );
                }
                return '';
              },
            },
          ]}
          wrapperStyle={css`
            margin-top: ${theme.spacing(2)};
            max-height: 550px;
            & > div:first-of-type > div:nth-of-type(2) {
              width: 300px;
            }
          `}
        />
      )}
    </>
  );
};

const ContactTooltip = ({ text }: { text: string }) => {
  const { Tooltip, tooltipProps, triggerProps: triggerTooltipProps } = useTooltip({ placement: 'top' });

  return (
    <>
      <Tooltip {...tooltipProps}>{text}</Tooltip>
      <div {...triggerTooltipProps}>
        <Icon size={24} name='info' color='light' />
      </div>
    </>
  );
};

const deviceNameCellStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(0.5),
};
