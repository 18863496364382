import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, ButtonBar, ContentLoader, Heading, TextareaField, useFormField } from '@frontend/design-system';
import { personSelectorStyles } from './styles';
import { personNotesTrackingIds } from './trackingIds';

interface Props {
  heading: string;
  note?: string;
  isLoading?: boolean;
  primaryButtonTrackingId?: string;
  primaryButtonText?: string;
  onPrimaryClick: (note: string) => void;
  secondaryButtonText?: string;
  onSecondaryClick: () => void;
}

export const PersonNotesComponent = ({
  isLoading = false,
  heading,
  primaryButtonTrackingId,
  primaryButtonText,
  onSecondaryClick,
  secondaryButtonText,
  onPrimaryClick,
  note = '',
}: Props) => {
  const { t } = useTranslation('personSelector');
  const noteFieldProps = useFormField({ type: 'text', value: note }, [note]);

  const handlePrimaryButtonClick = () => {
    onPrimaryClick(noteFieldProps.value);
  };

  const isSaveButtonDisabled = isLoading || (!!noteFieldProps.value && noteFieldProps.value === note);

  return (
    <div css={{ paddingTop: theme.spacing(1), position: 'relative' }}>
      <ContentLoader size='medium' show={isLoading} />
      <div css={personSelectorStyles.header}>
        <Heading level={3}>{heading}</Heading>
      </div>
      <div css={personSelectorStyles.content}>
        <TextareaField
          {...noteFieldProps}
          name='note'
          label={t('Patient notes (optional)')}
          placeholder={t('Patient notes (optional)')}
        />
      </div>
      <ButtonBar css={personSelectorStyles.footer}>
        <Button variant='secondary' trackingId={personNotesTrackingIds.cancelBtn} onClick={onSecondaryClick}>
          {secondaryButtonText || t('Cancel')}
        </Button>
        <Button trackingId={primaryButtonTrackingId} disabled={isSaveButtonDisabled} onClick={handlePrimaryButtonClick}>
          {primaryButtonText ? (
            primaryButtonText
          ) : (
            <>
              <Icon name='plus-small' color='white' />
              {t('Add to List')}
            </>
          )}
        </Button>
      </ButtonBar>
    </div>
  );
};
