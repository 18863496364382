import { useState, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { WeaveLocationGroup } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  BackIcon,
  Button,
  Heading,
  Modal,
  ModalControlModalProps,
  Stepper,
  TextField,
  useAlert,
  useForm,
  useModalControl,
} from '@frontend/design-system';
import { trackingId } from '../../../tracking';
import { SchemaFormValues, populateCreateSchema, createSubmitPayload } from './schema';
import { VoicemailBoxCreateRequest, VoicemailBoxCreateResponse } from './types';
import { useVoicemailBoxMutations } from './use-voicemail-box-mutations';
import { LocationsAndAccessCard, MiscellaneousCard } from './voicemail-box-detail';

const getDefaultState = (tenantId: string): VoicemailBoxCreateRequest => ({
  name: '',
  pin: undefined,
  locationIds: [],
  emailAddresses: [],
  smsNumbers: [],
  sendNotification: false,
  sharedAccess: false,
  playMessageDate: true,
  extensionNumber: undefined,
  tenantId,
});

export const CreateVoicemailBoxButton = ({
  tenantLocation,
  disabled = false,
}: {
  tenantLocation: WeaveLocationGroup;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <Button
        disabled={disabled}
        {...triggerProps}
        size='large'
        css={{ width: 'fit-content' }}
        trackingId={trackingId({ context: 'setting', feature: 'voicemail-boxes', details: 'create' })}
      >
        {t('Create Voicemail Box')}
      </Button>
      <CreateModal tenantLocation={tenantLocation} modalProps={modalProps} />
    </>
  );
};

export const CreateModal = ({
  tenantLocation,
  modalProps,
  onSuccess,
}: {
  tenantLocation: WeaveLocationGroup;
  modalProps: ModalControlModalProps;
  onSuccess?: (data: VoicemailBoxCreateResponse) => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const { navigate } = useSettingsNavigate();
  const [voicemailBox, _setVoicemailBox] = useState<VoicemailBoxCreateRequest>(() =>
    getDefaultState(tenantLocation.phoneTenantId ?? '')
  );
  const { create } = useVoicemailBoxMutations(tenantLocation.phoneTenantId ?? '');

  const populatedSchema = useMemo(() => {
    return populateCreateSchema(voicemailBox);
  }, [voicemailBox]);

  const form = useForm({
    fields: populatedSchema,
  });
  const textFieldProps = form.getFieldProps('name');
  const locationOptions = useMemo(() => {
    return (
      tenantLocation.children?.map((location) => ({
        label: location.name ?? '',
        value: location.locationId ?? '',
      })) ?? []
    );
  }, [tenantLocation]);

  const createVoicemailBox = (formValues: SchemaFormValues, tenantId: string) => {
    const finalState = createSubmitPayload({ tenantId, formValues });
    create.mutate(finalState, {
      onSuccess: (data) => {
        alerts.success({
          message: t('Voicemail Box created successfully.'),
          action: {
            label: t('Go to Voicemail Box'),
            onClick: () => navigate({ to: `/phone/voicemail-box/:id`, params: { id: data.mailbox.id } }),
          },
        });
        form.reset();
        onSuccess?.(data);
      },
      onError: () => {
        alerts.error('System failed to create Voicemail Box. Please try again.');
      },
    });
    modalProps.onClose();
  };

  const cancel = () => {
    form.reset();
    modalProps.onClose();
  };

  const isUnify = tenantLocation.locationType === 'unify';
  const stepTitles: Record<string, string> = {
    1: t('Voicemail Box Name'),
    2: isUnify ? t('Locations and Access') : t('Access Details'),
    3: t('Miscellaneous'),
  };
  if (isUnify) {
    stepTitles[5] = t('Locations');
  }

  return (
    <Modal {...modalProps} minWidth={900}>
      <Modal.Header onClose={cancel}></Modal.Header>
      <Modal.Body>
        <Stepper stepTitleText={stepTitles} isVerticalSingleView maxWidth={900}>
          {/* Voicemail Box Name */}
          <Stepper.Card>
            <Stepper.Title level={3}>{t('Create Voicemail Box')}</Stepper.Title>
            <Stepper.Content css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
              <Heading level={3}>{t('Voicemail Box Name')}</Heading>
              <TextField {...textFieldProps} label={t('Voicemail Box Name')} name='name'></TextField>
            </Stepper.Content>
            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate type='next' position='primary' disabled={!textFieldProps.value}>
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Locations and Access */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Voicemail Box')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <LocationsAndAccessCard
                  form={form}
                  locationOptions={locationOptions}
                  tenantLocation={tenantLocation}
                  canEditExtension
                  canViewExtension
                />
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate>
              {/* If the PIN is not valid, block continue */}
              <Stepper.ButtonAlternate type='next' position='primary' disabled={!form.isComplete}>
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='previous' position='tertiary'>
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Miscellaneous */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Voicemail Box')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <MiscellaneousCard form={form} />
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate
                type='none'
                position='primary'
                onClick={() => createVoicemailBox(form.values, tenantLocation.phoneTenantId ?? '')}
              >
                {t('Save')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='previous' position='tertiary'>
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>
        </Stepper>
      </Modal.Body>
    </Modal>
  );
};
