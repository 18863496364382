import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedButton } from '@frontend/design-system';
import { TruncatedText } from '../../truncated-text';
import { StatItem, StatItemData } from '.';

type ClickProps =
  | {
      cardId: string;
      isActive?: boolean;
      onClick?: (id: string) => void;
      trackingId: string;
    }
  | {
      cardId?: never;
      isActive?: never;
      onClick?: never;
      trackingId?: never;
    };

export type TabCardProps = ClickProps & {
  data: StatItemData[];
  isLoading?: boolean;
  style?: CSSProperties;
  title: string;
};

export const TabCard = ({
  cardId,
  data = [],
  isActive,
  isLoading,
  onClick,
  style,
  title,
  trackingId,
}: TabCardProps) => {
  return (
    <NakedButton
      className={isActive ? 'active' : ''}
      css={styles.card(typeof onClick === 'undefined')}
      onClick={() => onClick?.(cardId)}
      style={style}
      trackingId={trackingId}
    >
      <div css={styles.title}>
        <TruncatedText as='span' className='name' text={title} weight='bold' />
      </div>
      <div css={styles.dataPoints}>
        {data.map(({ label, ...rest }) => (
          <StatItem {...rest} key={label} label={label} subAlignment='bottom' />
        ))}
      </div>

      <ContentLoader show={isLoading} size='small' />
    </NakedButton>
  );
};

const styles = {
  card: (noop?: boolean) => css`
    border-radius: ${theme.borderRadius.medium};
    border: 2px solid ${theme.colors.neutral10};
    cursor: ${noop ? 'default' : 'pointer'};
    flex: 1;
    min-width: 200px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${noop ? '' : theme.colors.neutral5};
    }

    &.active {
      border-color: ${theme.colors.primary50};
    }
  `,

  title: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;

    .name {
      text-align: start;
      font-size: ${theme.font.size.h3};
    }
  `,

  dataPoints: css`
    column-gap: ${theme.spacing(6)};
    display: flex;
    margin-top: ${theme.spacing(1)};
  `,
};
