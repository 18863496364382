import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { AnalyticsCommonTypes, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { LoadingSkeleton } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { ModalControlModalProps, TableFilters, Text } from '@frontend/design-system';
import { useAnalyticsOrgLocations, useTimePeriodDropDownSelector, useTimePeriodPopoverSelector } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { CheckListPopoverSelector, LocationsSelector } from '../filter-selectors';
import { FiltersTray } from '../filters-tray';
import { usePracticeAnalyticsShallowStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
  wrapperStyles?: CSSProperties;
} & (
  | {
      modalProps?: never;
      onlyChipFilters?: boolean;
      onlyTrayFilters?: never;
      setShowNotificationBadge?: never;
    }
  | {
      modalProps: ModalControlModalProps;
      onlyChipFilters?: never;
      onlyTrayFilters?: boolean;
      setShowNotificationBadge: (show: boolean) => void;
    }
);

export const PracticeAnalyticsFilters = ({
  isLoadingData,
  modalProps,
  onlyChipFilters,
  onlyTrayFilters,
  setShowNotificationBadge,
  wrapperStyles,
}: Props) => {
  const { t } = useTranslation('analytics');

  const {
    filters,
    hasCustomFilters,
    isDemoAccount,
    locationIds: practiceAnalyticsLocationIds,
    resetStore,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationIds,
  } = usePracticeAnalyticsShallowStore(
    'filters',
    'hasCustomFilters',
    'isDemoAccount',
    'locationIds',
    'resetStore',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setLocationIds'
  );

  const { isLoadingLocations, isMultiLocation, locationIds, locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PA',
  });

  const [trayFilters, setTrayFilters] = useState<PracticeAnalyticsTypes.Filters>({});

  const { defaultEndDate, defaultStartDate, selectedPeriod, TimePeriodPopoverSelector, timePeriods } =
    useTimePeriodPopoverSelector({
      defaultPeriod: 'last30Days',
      values: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    });

  const { TimePeriodDropDownSelector } = useTimePeriodDropDownSelector({
    defaultPeriod: 'last30Days',
    values: {
      // Fallback is added to show the correct date range when user navigates between pages after applying custom filters
      startDate: trayFilters.startDate || filters.startDate,
      endDate: trayFilters.endDate || filters.endDate,
    },
  });

  const joinedLocationIds = locationIds.sort().join(',');
  const joinedPALocationIds = practiceAnalyticsLocationIds.sort().join(',');

  const [showFilteredBadge, setShowFilteredBadge] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const defaultFilters = useMemo(
    (): PracticeAnalyticsTypes.Filters => ({
      endDate: defaultEndDate,
      locations: locationIds || [],
      startDate: defaultStartDate,
    }),
    [defaultEndDate, defaultStartDate, joinedLocationIds]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<PracticeAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  // We are not using directly hasCustomFilters to show filters badge as not all tabs will have the same set of applied filters
  const areRecordsFiltered = (): boolean => {
    const areDatesEqual =
      dayjs(filters.startDate).isSame(dayjs(defaultFilters.startDate), 'date') &&
      dayjs(filters.endDate).isSame(dayjs(defaultFilters.endDate), 'date');

    const areLocationsEqual = isEqual(filters.locations?.sort(), defaultFilters.locations?.sort());

    return !areDatesEqual || !areLocationsEqual;
  };

  const renderChipFilters = () => (
    <>
      {isMultiLocation && (
        <CheckListPopoverSelector<string>
          defaultValue={defaultFilters.locations}
          disabled={isLoadingData}
          iconName='location-small'
          label={t('Locations')}
          onChange={(locations) => {
            setFilters({ locations });
          }}
          options={locationNames}
          value={filters.locations}
          trackingId={trackingIds.practiceAnalytics.locationsFilter}
        />
      )}

      <TimePeriodPopoverSelector
        disabled={isLoadingData || isLoadingLocations}
        onChange={({ startDate, endDate }) => {
          setFilters({ startDate, endDate });
        }}
        trackingIdBase={trackingIds.practiceAnalytics.timePeriodOnDashboardFilter}
      />
    </>
  );

  const renderTrayFilters = () => (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData || isLoadingLocations}
      headerLabel={t('Filter Practice Analytics')}
      hideFiltersIcon={!!modalProps}
      modalProps={modalProps}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={showFilteredBadge}
      trackingId={trackingIds.practiceAnalytics.filtersOpen}
    >
      {isMultiLocation && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingLocations}
              locations={locationNames}
              onChange={(value) => handleUpdateTrayFilters({ locations: value })}
              onError={handleUpdateErrors}
              trackingId={trackingIds.practiceAnalytics.locationsSelector}
              value={trayFilters.locations}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodDropDownSelector
            disabled={isLoadingData || isLoadingLocations}
            onChange={({ startDate, endDate }) => {
              handleUpdateTrayFilters({ startDate, endDate });
            }}
            trackingIdBase={trackingIds.practiceAnalytics.timePeriodOnTrayFilter}
          />
        </div>
      </TableFilters.Section>
    </FiltersTray>
  );

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      selectedPeriod
        ? timePeriods[selectedPeriod].label
        : `${formatters.date.format(filters.startDate || '')} - ${formatters.date.format(filters.endDate || '')}`
    );
  }, [filters, selectedPeriod, timePeriods]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    setShowNotificationBadge?.(showFilteredBadge);
  }, [showFilteredBadge]);

  useEffect(() => {
    setShowFilteredBadge(areRecordsFiltered());
  }, [defaultFilters, filters]);

  useEffect(() => {
    if (!practiceAnalyticsLocationIds.length) {
      setLocationIds(locationIds);
    } else if (joinedLocationIds !== joinedPALocationIds) {
      //  Reset when location is switched
      resetStore();
      setLocationIds(locationIds);
      applyDefaultFilters();
    }
  }, [joinedLocationIds, joinedPALocationIds]);

  return onlyChipFilters ? (
    renderChipFilters()
  ) : onlyTrayFilters ? (
    renderTrayFilters()
  ) : (
    <div css={filtersStyles.mainWrapper} style={wrapperStyles}>
      {renderChipFilters()}
      {renderTrayFilters()}
      {isLoadingLocations && (
        <LoadingSkeleton css={styles.loadingSkeleton}>
          <Text color='subdued'>{t('Loading Locations...')}</Text>
        </LoadingSkeleton>
      )}
    </div>
  );
};

const styles = {
  loadingSkeleton: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  `,
};
