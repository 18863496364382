import { useCallback, useEffect, useState } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  PaginationProps,
  Table,
  TableColumnConfig,
  Tabs,
  emptyStateGraphics,
  useAlert,
} from '@frontend/design-system';
import { createAllSelectTableStateObject } from '../utils';
import { TableCustomToolbarActions, TableCustomToolbarActionsProps } from './table-custom-toolbar-actions';

export type SortType = 'asc' | 'desc';

type Tab<T extends object> = {
  colConfig: TableColumnConfig<T>[];
  data: T[];
  emptyStateConfig: keyof typeof emptyStateGraphics;
  label: string;
  tableInstanceId: string;
  trackingId?: string;
};

type CommonProps<T> = {
  actions?: TableCustomToolbarActionsProps<T>['actions'];
  autorizedToExport?: boolean | ((name: string) => Promise<boolean>);
  exportFileName?: string;
  isDemoAccount?: boolean;
  isExportReady?: boolean;
  isLoading?: boolean;
  isSelectable?: boolean;
  manualPaginationConfig?: PaginationProps;
  onTabChange?: (tab: string) => void;
  processExportableData?: (data: T[]) => Partial<T>[];
  showExportIcon?: boolean;
  trackingIdBase?: string;
  wrapperStyle?: SerializedStyles;
};

type Props<T extends object> =
  | (CommonProps<T> & {
      colConfig?: never;
      data?: never;
      emptyStateConfig?: never;
      initialTab: string;
      tableInstanceId?: never;
      tabs: Record<string, Tab<T>>;
    })
  | (CommonProps<T> & {
      colConfig: TableColumnConfig<T>[];
      data: T[];
      emptyStateConfig: keyof typeof emptyStateGraphics;
      initialTab?: never;
      tableInstanceId: string;
      tabs?: never;
    });

export function TabbedTable<T extends object>({
  actions,
  autorizedToExport = true,
  colConfig,
  data,
  emptyStateConfig,
  exportFileName = 'data',
  initialTab,
  isDemoAccount,
  isExportReady = true,
  isLoading,
  isSelectable,
  manualPaginationConfig,
  onTabChange,
  processExportableData,
  showExportIcon = true,
  tableInstanceId,
  tabs,
  trackingIdBase,
  wrapperStyle,
}: Props<T>) {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | undefined>(initialTab);
  const [selectedRows, setSelectedRows] = useState<Record<string, T[]>>({ default: [] });

  const handlExport = async (fileName: string, dataToExport: T[]) => {
    setIsExporting(true);
    const isAuthorized =
      showExportIcon &&
      (typeof autorizedToExport === 'function'
        ? await autorizedToExport(fileName.replaceAll('/', '-'))
        : autorizedToExport);

    if (isAuthorized || isDemoAccount) {
      const csv = unparse(processExportableData?.(dataToExport) ?? dataToExport, {});
      saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName + '.csv');
      setIsExporting(false);
    } else {
      alert.warning(t('You are not authorized to export data. This attempt has been logged.'));
      setIsExporting(false);
    }
  };

  const paginationProps = () => {
    if (manualPaginationConfig) {
      return { manualPaginationConfig };
    } else {
      return { clientPaginationConfig: { defaultRowsPerPage: 25 } };
    }
  };

  const handleTabChange = useCallback((tab: string) => {
    setActiveTab(tab);
    onTabChange?.(tab);
  }, []);

  const renderTable = (key?: string) => {
    const tabsConfig = tabs && key && (tabs[key] as Tab<T>);
    const tableData = tabsConfig ? tabsConfig.data : data || [];

    return (
      <>
        <Table
          {...paginationProps()}
          colConfig={tabsConfig ? tabsConfig.colConfig : colConfig || []}
          data={tableData}
          disableMultiSort
          emptyStateConfig={{
            type: tabsConfig ? tabsConfig.emptyStateConfig : emptyStateConfig,
          }}
          hasResizeColumns
          hasResponsiveColWidths
          isLoading={isLoading}
          isPaginated
          isSelectable={isSelectable}
          rowSelectionConfig={{
            initialState: isSelectable ? createAllSelectTableStateObject(tableData.length) : {},
            onSelectionToggle: (selectedRows) => {
              const originals = selectedRows.map(({ original }) => original);
              setSelectedRows((prev) => ({
                ...prev,
                [key || 'default']: originals,
              }));
            },
          }}
          tableInstanceId={tabsConfig ? tabsConfig.tableInstanceId : tableInstanceId}
          wrapperStyle={wrapperStyle}
        />
        <ContentLoader show={isExporting} size='small' />
      </>
    );
  };

  useEffect(() => {
    if (!isSelectable) {
      return;
    }

    if (tabs) {
      setSelectedRows(Object.keys(tabs).reduce((acc, key) => ({ ...acc, [key]: tabs[key].data }), {}));
    } else {
      setSelectedRows({ default: data });
    }
  }, [data, isSelectable, tabs]);

  return tabs ? (
    <Tabs initialTab={initialTab} onChange={handleTabChange}>
      <div css={styles.tabsWrapper}>
        <Tabs.Bar>
          {/* Casting it to avoid complex data type issue by linter */}
          {Object.entries(tabs as Record<string, { label: string; trackingId?: string }>).map(
            ([key, { label, trackingId }]) => (
              <Tabs.Tab id={key} controls={`${key}-panel`} key={key} trackingId={trackingId}>
                {label}
              </Tabs.Tab>
            )
          )}
        </Tabs.Bar>

        <TableCustomToolbarActions<T>
          actions={actions}
          isExportReady={!!activeTab && !!tabs[activeTab].data.length && isExportReady}
          isLoadingData={isLoading}
          onExportClick={() =>
            handlExport(activeTab ? `${exportFileName} - ${activeTab}` : exportFileName, tabs[activeTab || ''].data)
          }
          selection={selectedRows?.[activeTab || 'default']}
          showExportIcon={showExportIcon}
          trackingIdBase={trackingIdBase}
        />
      </div>

      {Object.keys(tabs).map((key) => (
        <Tabs.Panel controller={key} css={styles.panel} id={`${key}-panel`} key={key}>
          {renderTable(key)}
        </Tabs.Panel>
      ))}
    </Tabs>
  ) : (
    <div css={styles.panel}>
      <div css={styles.actions} style={{ justifyContent: 'flex-end' }}>
        <TableCustomToolbarActions<T>
          actions={actions}
          isExportReady={!!data.length && isExportReady}
          isLoadingData={isLoading}
          onExportClick={() => handlExport(activeTab ? `${exportFileName} - ${activeTab}` : exportFileName, data)}
          selection={selectedRows?.['default']}
          showExportIcon={showExportIcon}
          trackingIdBase={trackingIdBase}
        />
      </div>
      {renderTable()}
    </div>
  );
}

const styles = {
  panel: css`
    position: relative;
  `,

  tabsWrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  `,

  actions: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
  `,
};
