import { ReactNode } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useQuery } from 'react-query';
import { CallQueueStatsApi, CallQueueStatsTypes } from '@frontend/api-call-queue-stats';
import { TimeRangeChangeValue } from '@frontend/phone-charts';
import { queryKeys } from '../../query-keys';
import type { AnalyticChartsData } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

const getDifferentialDates = (timeOption: TimeRangeChangeValue) => {
  dayjs.tz.guess();
  const startDate = dayjs(timeOption.startDate);
  const endDate = dayjs(timeOption.endDate);

  switch (timeOption.definition) {
    case 'td':
      return [
        startDate.subtract(1, 'day').startOf('day').toISOString(),
        endDate.subtract(1, 'day').endOf('day').toISOString(),
      ];
    case 'td-1':
      return [
        startDate.subtract(1, 'day').startOf('day').toISOString(),
        endDate.subtract(1, 'day').endOf('day').toISOString(),
      ];
    case 'td-7':
      return [
        startDate.subtract(7, 'day').startOf('day').toISOString(),
        endDate.subtract(7, 'day').endOf('day').toISOString(),
      ];
    case 'td-30':
      return [
        startDate.subtract(30, 'day').startOf('day').toISOString(),
        endDate.subtract(30, 'day').endOf('day').toISOString(),
      ];
    case 'tw-x': {
      const week = startDate.startOf('week').subtract(1, 'week');
      return [week.toISOString(), week.endOf('week').toISOString()];
    }
    case 'tm-x': {
      const month = startDate.startOf('month').subtract(1, 'month');
      return [month.toISOString(), month.endOf('month').toISOString()];
    }
    default:
      return [startDate.toISOString(), endDate.toISOString()];
  }
};

const getData = async (tenantId: string, timeOption: TimeRangeChangeValue, locationIds: string[]) => {
  const [diffStartDate, diffEndDate] = getDifferentialDates(timeOption);

  const data = await CallQueueStatsApi.getCallQueuesPerformanceAnalyticsData({
    startTime: timeOption.startDate,
    endTime: timeOption.endDate,
    differentialStartTime: diffStartDate,
    differentialEndTime: diffEndDate,
    tenantId,
    locationIds,
  });

  return data.analyticsDataPerCallQueue;
};

// const getOverTimeData = (callQueueId: string, timeOption: TimeRangeChangeValue) => {
//   const [diffStartDate, diffEndDate] = getDifferentialDates(timeOption);

//   return getFakeOverTimeData({
//     callQueueId,
//     startTime: timeOption.startDate,
//     endTime: timeOption.endDate,
//     differentialStartTime: diffStartDate,
//     differentialEndTime: diffEndDate,
//   });
// };

// const getFakeOverTimeData = ({
//   callQueueId,
// }: {
//   callQueueId: string;
//   startTime: string;
//   endTime: string;
//   differentialStartTime: string;
//   differentialEndTime: string;
// }) => {
//   const waitOverTimeStats: { timestamp: string; averageWaitBeforeAnswer: number; averageWaitBeforeAbandon: number }[] =
//     [];
//   for (let i = 0; i < Math.floor(Math.random() * 15) + 10; i++) {
//     waitOverTimeStats.push({
//       timestamp: dayjs().startOf('day').add(i, 'hour').toISOString(),
//       averageWaitBeforeAnswer: Math.floor(Math.random() * 100),
//       averageWaitBeforeAbandon: Math.floor(Math.random() * 100),
//     });
//   }

//   return {
//     callQueueId,
//     waitOverTimeStats,
//   };
// };

const CHART_KEYS = {
  AVERAGE_WAIT: 'averageWait',
  ANSWER_RATE: 'answerRate',
  AVERAGE_WAIT_OVER_TIME: 'averageWaitOverTime',
};

const parseData = ({
  data,
}: {
  data?: CallQueueStatsTypes.GetCallQueuesPerformanceAnalyticsData['output']['analyticsDataPerCallQueue'];
}) => {
  if (!data) {
    return undefined;
  }

  /**
   * We shouldn't have to coerce the values to numbers here, but the API is returning strings instead of what's specified in the schema
   */
  return {
    averageWait: {
      base: {
        groups: data.map((d) => ({
          name: `${CHART_KEYS.AVERAGE_WAIT}-${d.callQueueId}`,
          values: {
            answer: d.averageWaitStats?.averageWaitBeforeAnswer ?? 0,
            abandon: d.averageWaitStats?.averageWaitBeforeAbandon ?? 0,
          },
        })),
      },
      candidate: {
        groups: data.map((d) => ({
          name: `${CHART_KEYS.AVERAGE_WAIT}-${d.callQueueId}`,
          values: {
            answer: d.differentialAverageWaitStats?.averageWaitBeforeAnswer ?? 0,
            abandon: d.differentialAverageWaitStats?.averageWaitBeforeAbandon ?? 0,
          },
        })),
      },

      groupLabels: Object.fromEntries(
        data.map((callQueue) => [
          `${CHART_KEYS.AVERAGE_WAIT}-${callQueue.callQueueId ?? ''}`,
          callQueue.callQueueName ?? '',
        ])
      ),
    },
    answerRate: {
      base: {
        groups: data.map((d) => ({
          name: `${CHART_KEYS.ANSWER_RATE}-${d.callQueueId}`,
          values: {
            answer: d.callCountStats?.answeredCalls ?? 0,
            abandon: d.callCountStats?.abandonedCalls ?? 0,
            timeout: d.callCountStats?.timedOutCalls ?? 0,
          },
        })),
      },
      candidate: {
        groups: data.map((d) => ({
          name: `${CHART_KEYS.ANSWER_RATE}-${d.callQueueId}`,
          values: {
            answer: d.differentialCallCountStats?.answeredCalls ?? 0,
            abandon: d.differentialCallCountStats?.abandonedCalls ?? 0,
            timeout: d.differentialCallCountStats?.timedOutCalls ?? 0,
          },
        })),
      },
      groupLabels: Object.fromEntries(
        data.map((callQueue) => [
          `${CHART_KEYS.ANSWER_RATE}-${callQueue.callQueueId ?? ''}`,
          callQueue.callQueueName ?? '',
        ])
      ),
    },
  };
};

// const parseOverTimeData = ({
//   data,
//   callQueueRecord,
// }: {
//   data?: ReturnType<typeof getOverTimeData>;
//   callQueueRecord: Record<string, string>;
// }) => {
//   return data
//     ? {
//         averageWaitOverTime: {
//           base: {
//             groups: data.waitOverTimeStats.map((waitOverTimeStat) => {
//               return {
//                 name: waitOverTimeStat.timestamp,
//                 values: {
//                   answer: waitOverTimeStat.averageWaitBeforeAnswer,
//                   abandon: waitOverTimeStat.averageWaitBeforeAbandon,
//                 },
//               };
//             }),
//           },
//           groupLabels: Object.fromEntries(
//             Object.entries(callQueueRecord).map(([id, name]) => [`${CHART_KEYS.AVERAGE_WAIT_OVER_TIME}-${id}`, name])
//           ),
//         },
//       }
//     : undefined;
// };

export function ChartDataLayer({
  tenantId,
  locationIds,
  selectedTimeOption,
  // selectedOverTimeCallQueueId,
  render,
}: {
  tenantId: string;
  locationIds: string[];
  selectedTimeOption: TimeRangeChangeValue;
  selectedOverTimeCallQueueId?: string;
  render: ({ data, isLoading }: { data?: AnalyticChartsData; isLoading: boolean }) => ReactNode;
}) {
  const { data, isLoading } = useQuery({
    queryKey: [tenantId, ...queryKeys.callQueueAnalytics(), { definition: selectedTimeOption.definition, locationIds }],
    queryFn: () => getData(tenantId, selectedTimeOption, locationIds),
    select: (data) => data?.sort((a, b) => (a.callQueueName ?? '').localeCompare(b.callQueueName ?? '')),
    enabled: !!tenantId,
  });

  // For overtime data
  // const { data: overTimeData, isLoading: isOverTimeLoading } = useQuery({
  //   queryKey: [
  //     tenantId,
  //     ...queryKeys.callQueueAnalyticsOverTime(selectedOverTimeCallQueueId ?? ''),
  //     selectedTimeOption.definition,
  //   ],
  //   queryFn: () => getOverTimeData(callQueueIds[0], selectedTimeOption),
  //   enabled: !!selectedOverTimeCallQueueId,
  // });

  if (data?.length === 0) {
    return render({
      isLoading: false,
      data: {
        averageWait: { base: { groups: [] }, candidate: { groups: [] }, groupLabels: {} },
        answerRate: { base: { groups: [] }, candidate: { groups: [] }, groupLabels: {} },
        averageWaitOverTime: { base: { groups: [] }, groupLabels: {} },
      },
    });
  }

  const parsedData = parseData({ data });
  // For overtime data
  // const parsedOverTimeData = parseOverTimeData({ data: overTimeData, callQueueRecord });
  const parsedOverTimeData = {
    averageWaitOverTime: {
      base: {
        groups: [],
      },
      groupLabels: {},
    },
  };

  const finalData = {
    averageWait: parsedData?.averageWait ?? { base: { groups: [] }, candidate: { groups: [] }, groupLabels: {} },
    answerRate: parsedData?.answerRate ?? { base: { groups: [] }, candidate: { groups: [] }, groupLabels: {} },
    averageWaitOverTime: parsedOverTimeData?.averageWaitOverTime ?? { base: { groups: [] }, groupLabels: {} },
  };

  return render({ data: finalData, isLoading: isLoading });
}
