import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { default as CallIntelIllustration } from '../../assets/call-intel-illustration.webp';
import { default as FormsIllustration } from '../../assets/forms-illustration.webp';
import { default as OnlineSchedulingIllustration } from '../../assets/online-scheduling-illustration.webp';

type FeatureInfo = {
  title: string;
  icon: IconName;
  description?: string;
  additionalInfo?: string;
  link?: string;
  imageSrc?: string;
};

export enum BundleFeatureEnum {
  TEAM_CHAT = 'teamChat',
  QUICK_FILL = 'quickFill',
  REVIEWS = 'reviews',
  EMAIL_MARKETING = 'emailMarketing',
  MISSED_CALL_TEXTING = 'missedCallTexting',
  RECALL_REACTIVATION = 'recallReactivation',
  VACCINE_REMINDERS = 'vaccineReminders',
  TEXT_CONNECT = 'textConnect',
  ONLINE_SCHEDULING = 'onlineScheduling',
  FORMS = 'forms',
  PAID_TRAINING = 'paidTraining',
  CALL_INTEL = 'callIntel',
  PRACTICE_ANALYTICS = 'practiceAnalytics',
}

export const BundleFeatureInfoMap: Record<BundleFeatureEnum, FeatureInfo> = {
  [BundleFeatureEnum.TEAM_CHAT]: {
    title: i18next.t('Team Chat', { ns: 'bundleUpgrade>>feature' }),
    icon: 'chat-small',
    link: 'https://www.weavehelp.com/weavehelp/article/set-team-chat-status-and-notifications',
  },
  [BundleFeatureEnum.QUICK_FILL]: {
    title: i18next.t('ASAP List / Quick Fill', { ns: 'bundleUpgrade>>feature' }),
    icon: 'quickfill-small',
    link: 'https://www.weavehelp.com/weavehelp/article/send-quick-fill-appointment-messages',
  },
  [BundleFeatureEnum.REVIEWS]: {
    title: i18next.t('Manual Reviews', { ns: 'bundleUpgrade>>feature' }),
    icon: 'star-small',
    link: 'https://www.weavehelp.com/weavehelp/article/set-up-reviews',
  },
  [BundleFeatureEnum.EMAIL_MARKETING]: {
    title: i18next.t('Email Marketing', { ns: 'bundleUpgrade>>feature' }),
    icon: 'email-small',
    link: 'https://www.weavehelp.com/weavehelp/article/Email-Marketing-and-Form-Builder-Available-in-the-New-Weave-App-October-2024',
  },
  [BundleFeatureEnum.MISSED_CALL_TEXTING]: {
    title: i18next.t('Missed Call Texting', { ns: 'bundleUpgrade>>feature' }),
    icon: 'phone-missed-small',
    link: 'https://www.weavehelp.com/weavehelp/article/best-practices-for-missed-call-text-auto-messages',
  },
  [BundleFeatureEnum.RECALL_REACTIVATION]: {
    title: i18next.t('Recall & Reactivation', { ns: 'bundleUpgrade>>feature' }),
    icon: 'refresh',
    link: 'https://www.weavehelp.com/weavehelp/article/set-up-auto-messages',
  },
  [BundleFeatureEnum.VACCINE_REMINDERS]: {
    title: i18next.t('Vaccine Reminders', { ns: 'bundleUpgrade>>feature' }),
    icon: 'medical-stroke',
    link: 'https://www.weavehelp.com/weavehelp/article/set-up-auto-messages',
  },
  [BundleFeatureEnum.TEXT_CONNECT]: {
    title: i18next.t('Text Connect', { ns: 'bundleUpgrade>>feature' }),
    icon: 'data-sync-small',
    link: 'https://www.weavehelp.com/weavehelp/article/install-and-use-text-connect',
    additionalInfo: i18next.t('Not included in free trial', { ns: 'bundleUpgrade>>feature' }),
  },
  [BundleFeatureEnum.ONLINE_SCHEDULING]: {
    title: i18next.t('Online Scheduling', { ns: 'bundleUpgrade>>feature' }),
    icon: 'schedule-pulse-small',
    description: i18next.t('Book more appointments by letting your customers schedule themselves online.', {
      ns: 'bundleUpgrade>>feature',
    }),
    link: 'https://www.weavehelp.com/weavehelp/article/set-up-online-scheduling',
    imageSrc: OnlineSchedulingIllustration,
  },
  [BundleFeatureEnum.FORMS]: {
    title: i18next.t('Digital Forms', { ns: 'bundleUpgrade>>feature' }),
    icon: 'forms-small',
    description: i18next.t(
      'Save time and paper by creating, sharing, and storing your forms digitally, right within Weave.',
      { ns: 'bundleUpgrade>>feature' }
    ),
    link: 'https://www.weavehelp.com/weavehelp/article/your-management-system-and-digital-forms',
    imageSrc: FormsIllustration,
  },
  [BundleFeatureEnum.PAID_TRAINING]: {
    title: i18next.t('1:1 Paid Training', { ns: 'bundleUpgrade>>feature' }),
    icon: 'bookmark-small',
    link: 'https://www.weavehelp.com/weavehelp/article/1-1-training-sessions',
    additionalInfo: i18next.t('Not included in free trial', { ns: 'bundleUpgrade>>feature' }),
  },
  [BundleFeatureEnum.CALL_INTEL]: {
    title: i18next.t('Call Intelligence', { ns: 'bundleUpgrade>>feature' }),
    icon: 'call-intel-small',
    additionalInfo: i18next.t('Limited 14 day trial', { ns: 'bundleUpgrade>>feature' }),
    description: i18next.t('Gain valuable insights about your phone calls and scheduling opportunities.', {
      ns: 'bundleUpgrade>>feature',
    }),
    link: 'https://www.weavehelp.com/weavehelp/article/call-intelligence',
    imageSrc: CallIntelIllustration,
  },
  [BundleFeatureEnum.PRACTICE_ANALYTICS]: {
    title: i18next.t('Practice Analytics', { ns: 'bundleUpgrade>>feature' }),
    icon: 'analytics-info-small',
    additionalInfo: i18next.t('Compatible with selected EHRs', { ns: 'bundleUpgrade>>feature' }),
    link: 'https://www.weavehelp.com/weavehelp/article/using-practice-analytics',
  },
};

export const CommonUltimateFeaturesList: BundleFeatureEnum[] = [
  BundleFeatureEnum.TEAM_CHAT,
  BundleFeatureEnum.QUICK_FILL,
  BundleFeatureEnum.REVIEWS,
  BundleFeatureEnum.EMAIL_MARKETING,
  BundleFeatureEnum.MISSED_CALL_TEXTING,
  BundleFeatureEnum.RECALL_REACTIVATION,
  BundleFeatureEnum.VACCINE_REMINDERS,
  BundleFeatureEnum.TEXT_CONNECT,
  BundleFeatureEnum.ONLINE_SCHEDULING,
  BundleFeatureEnum.FORMS,
  BundleFeatureEnum.PAID_TRAINING,
];

interface TrainingWidgetData {
  title: string;
  duration: string;
  actionText: string;
  actionIcon: IconName;
  actionLink: string;
  trackingId: string;
}
export const BundleTrainingWidgets: TrainingWidgetData[] = [
  {
    title: i18next.t('Master the Ultimate Bundle with a Weave training course.', {
      ns: 'bundleUpgrade>>bundle-trial-active-modal',
    }),
    duration: i18next.t('30 min', { ns: 'bundleUpgrade>>bundle-trial-active-modal' }),
    actionText: i18next.t('Ultimate Bundle Training', { ns: 'bundleUpgrade>>bundle-trial-active-modal' }),
    actionIcon: 'award-small',
    actionLink: 'https://trainingcamp.weavehelp.com/path/free-trial-ultimate-bundle',
    trackingId: 'ultimate-bundle-training-link',
  },
  {
    title: i18next.t('Explore the Ultimate Bundle in this pre-recorded webinar.', {
      ns: 'bundleUpgrade>>bundle-trial-active-modal',
    }),
    duration: i18next.t('1 hr', { ns: 'bundleUpgrade>>bundle-trial-active-modal' }),
    actionText: i18next.t('Ultimate Bundle Intro Video', { ns: 'bundleUpgrade>>bundle-trial-active-modal' }),
    actionIcon: 'video-small',
    actionLink: 'https://trainingcamp.weavehelp.com/ultimate-bundle-trial-webinar',
    trackingId: 'ultimate-bundle-intro-video-link',
  },
  // TODO: Milind uncomment in Jan 2025 when the webinar is ready
  // {
  //   title: i18next.t("Sign up for Weave's free, live webinar.", {
  //     ns: 'bundleUpgrade>>bundle-trial-active-modal',
  //   }),
  //   duration: i18next.t('1 hr', { ns: 'bundleUpgrade>>bundle-trial-active-modal' }),
  //   actionText: i18next.t('Join Bi-weekly Webinar', { ns: 'bundleUpgrade>>bundle-trial-active-modal' }),
  //   actionIcon: 'computer-small',
  //   // TODO: Milind update training link
  //   actionLink: 'https://trainingcamp.weavehelp.com/page/your-training-drills',
  //   trackingId: 'join-webinar-link',
  // },
];
