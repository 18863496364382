import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { CardOnFileStepNavigationProps } from '../card-on-file-types';

export const CardOnFileGenericNavigation = ({ body: Body }: CardOnFileStepNavigationProps) => {
  const {
    onPaymentSuccess: onPaymentSuccessContext,
    multiStep: { goPrevStep, onComplete },
  } = useCollectPaymentMultiContext();

  const onPaymentSuccess = useCallback(() => {
    onPaymentSuccessContext?.();
  }, [onPaymentSuccessContext]);

  const onGoBack = useCallback(() => {
    goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
  }, []);

  const onCompleteStep = useCallback(() => {
    onComplete();
  }, [onComplete]);

  return <Body onPaymentSuccess={onPaymentSuccess} onGoBack={onGoBack} onCompleteStep={onCompleteStep} />;
};
