import { http } from '@frontend/fetch';
import { sentry } from '@frontend/tracking';
import { IntakeFormLocation } from './types';

export const logOnboardingSentryError = (errorMessage: string, error?: unknown) => {
  if (error) {
    // ignoring 401 API errors
    if (http.isHttpError(error) && error.status === 401) {
      return;
    }
    console.error(error);
  }

  sentry.error({ error: errorMessage, topic: 'onboarding' });
};

export type PrepareIntakeFormStoreInfoFromLocationList = (args: {
  intakeFormLocations?: IntakeFormLocation[];
  userEmail?: string;
  isParentLocation?: boolean;
  isWeaveUser?: boolean;
}) => {
  intakeFormLocations?: IntakeFormLocation[];
  ownIntakeFormLocations: IntakeFormLocation[];
  isShowIntakeForm: boolean;
  isSingleSoftwareOnlyLocation: boolean;
};

export const prepareIntakeFormStoreInfoFromLocationList: PrepareIntakeFormStoreInfoFromLocationList = ({
  intakeFormLocations = [],
  userEmail = '',
  isParentLocation = false,
  isWeaveUser = false,
}) => {
  const ownIntakeFormLocations: IntakeFormLocation[] = [];
  let hasOwnIncompleteIntakeForms = false;
  let hasIncompleteIntakeForms = false;

  intakeFormLocations
    .filter(({ error }) => !error)
    .forEach((location) => {
      if (!location.onboardingContactEmail || location.onboardingContactEmail === userEmail || isWeaveUser) {
        ownIntakeFormLocations.push(location);
        hasOwnIncompleteIntakeForms = hasOwnIncompleteIntakeForms || !location.formCompletedAt;
      }
      hasIncompleteIntakeForms = hasIncompleteIntakeForms || !location.formCompletedAt;
    });

  const isShowIntakeForm = isParentLocation ? hasOwnIncompleteIntakeForms : hasIncompleteIntakeForms;
  const isSingleSoftwareOnlyLocation =
    intakeFormLocations.length === 1 &&
    !!intakeFormLocations[0].isSoftwareOnlyForm &&
    !intakeFormLocations[0].isMultiForm;

  return { intakeFormLocations, ownIntakeFormLocations, isShowIntakeForm, isSingleSoftwareOnlyLocation };
};
