import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { FaxDraft } from '@weave/schema-gen-ts/dist/schemas/fax/draft/v1/fax_draft_service.pb';
import { FaxTypes } from '@frontend/api-fax';
import { Chips } from '@frontend/chips';
import { ActionableListRow } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ListRow, useTooltip } from '@frontend/design-system';
import { useFaxActions } from '../../hooks';
import { FaxInboxListItemLead } from './fax-inbox-list-item-lead';
import { ListItemStatus } from './list-item-status';

export const DraftInboxListItem = ({ listItem }: { listItem: FaxDraft }) => {
  const { locationId, id } = listItem;
  const { t } = useTranslation('fax');
  const navigate = useNavigate();
  const { getLocationName, selectedLocationIds } = useAppScopeStore();
  const { deleteDraft } = useFaxActions();

  const {
    Tooltip,
    triggerProps: tooltipTriggerProps,
    tooltipProps,
  } = useTooltip({
    trigger: 'hover',
    placement: 'left-start',
    offset: Number(theme.spacing(1).replace('px', '')),
  });

  const hasCoverSheet = !!listItem.coversheet?.html || !!listItem.uploadedCoversheet?.mediaId;
  const hasFiles = !!listItem.mergedFile?.mediaId;

  // preview and download not currently working on BE, will uncomment when fixed
  const actions = [
    // ...(hasMediaId
    //   ? [
    //       {
    //         Icon: () => <Icon name='preview' css={{ color: theme.colors.neutral70 }} />,
    //         label: t('Preview / Print'),
    //         action: () => previewFax(id, `${id}-fax-preview`, locationId),
    //         trackingId: `${FaxPrefixes.Drafts}-preview-button`,
    //       },
    //       {
    //         Icon: () => <Icon name='download' css={{ color: theme.colors.neutral70 }} />,
    //         label: t('Download'),
    //         action: () => downloadFax(id, `${id}-fax-download`, locationId),
    //         trackingId: `${FaxPrefixes.Drafts}-download-button`,
    //       },
    //     ]
    //   : []),
    {
      Icon: () => <Icon name='trash' css={{ color: theme.colors.neutral70 }} />,
      label: t('Delete Draft'),
      action: () => deleteDraft(id, locationId),
      trackingId: `${FaxPrefixes.Drafts}-delete-button`,
    },
  ];

  return (
    <div>
      <ActionableListRow
        css={actionableListRowStyles}
        Lead={
          <FaxInboxListItemLead
            isBulkSelect={false}
            personName={t('Draft')}
            locationId={locationId}
            isCheckboxSelected={false}
            isInbound={false}
          />
        }
        actions={actions}
        Title={
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              minWidth: 0,
              gap: theme.spacing(1),
            }}
            {...tooltipTriggerProps}
          >
            <ListItemStatus status={FaxTypes.FaxStatus.DRAFT} />
            <ListRow.Content.Title
              css={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              isBold={false}
            >
              {t('Draft')}
            </ListRow.Content.Title>
            <Tooltip {...tooltipProps}>{t('Draft')}</Tooltip>
          </div>
        }
        Content={
          <>
            {selectedLocationIds.length > 1 && (
              <Chips.LocationChip css={{ fontSize: theme.fontSize(12) }} className='inbox-item-chip'>
                {getLocationName(locationId)}
              </Chips.LocationChip>
            )}
          </>
        }
        timestamp={listItem.createdAt}
        as='div'
        tabIndex={0}
        key={id}
        id={id}
        onClick={() => {
          if (!hasCoverSheet && !hasFiles) {
            navigate({
              to: `/fax/drafts/${listItem.id}/cover`,
              search: {
                selectedLocationId: selectedLocationIds.length > 1 ? locationId : undefined,
              },
            });
          } else if (hasCoverSheet && !hasFiles) {
            navigate({
              to: `/fax/drafts/${listItem.id}/upload`,
              search: {
                selectedLocationId: selectedLocationIds.length > 1 ? locationId : undefined,
              },
            });
          } else {
            navigate({
              to: `/fax/drafts/${listItem.id}/finalize`,
              search: {
                selectedLocationId: selectedLocationIds.length > 1 ? locationId : undefined,
              },
            });
          }
        }}
        data-trackingid={`${FaxPrefixes.List}-inbox-item`}
      />
    </div>
  );
};

const actionableListRowStyles = css`
  padding: ${theme.spacing(3)};
  padding-left: ${theme.spacing(2.75)};
  overflow-x: hidden;

  &:hover .inbox-item-chip {
    background-color: ${theme.colors.white};
  }
`;
