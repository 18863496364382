import { MutationOptions, UseQueryOptions } from 'react-query';
import { HttpError } from '@frontend/fetch';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPlatformLocationFeature, SchemaSubscriptionManagerService } from '@frontend/schema';
import {
  BundleTrialSignupRequest,
  BundleTrialSignupResponse,
  BundleTrialStatusRequest,
  BundleTrialStatusResponse,
  GetBundleUpgradeEligibilityRequest,
  GetBundleUpgradeEligibilityResponse,
  GetSubscriptionAddOnsStatusesForMultiRequest,
  GetSubscriptionAddOnsStatusesForMultiResponse,
  GetTrialHistoryForMultiRequest,
  GetTrialHistoryForMultiResponse,
  IsMultiEligibleForSelfUpgradeOrTrialRequest,
  IsMultiEligibleForSelfUpgradeOrTrialResponse,
  MultiSubscribeRequest,
  MultiSubscribeResponse,
  MultiTrialSignupRequest,
  MultiTrialSignupResponse,
} from './types';

const HOURS_IN_MS = 1000 * 60 * 60;

export const freeTrialSubscriptionQueryKeys = {
  subscriptionBaseQuery: ['subscription', 'feature'],
  multiSelfUpgradeOrFreeTrialEligibility: ({
    featureEnum,
    locationIds,
  }: IsMultiEligibleForSelfUpgradeOrTrialRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'free-trial',
    'eligibility',
    featureEnum,
    ...locationIds,
  ],
  trialExpiringFeatures: ({ locationIds }: GetTrialHistoryForMultiRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'free-trial',
    'trial-expiring',
    ...locationIds,
  ],
  multiSubscriptionAddOns: ({ locationIds }: GetSubscriptionAddOnsStatusesForMultiRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'add-ons',
    ...locationIds,
  ],
  bundleUpgradeEligibility: ({ locationIds }: GetBundleUpgradeEligibilityRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'bundle-upgrade',
    'eligibility',
    ...locationIds,
  ],
  bundleTrialStatus: ({ locationIds }: BundleTrialStatusRequest) => [
    ...freeTrialSubscriptionQueryKeys.subscriptionBaseQuery,
    'bundle-trial',
    'status',
    ...locationIds,
  ],
};

export const useFreeTrialMultiSignUp = (
  options?: MutationOptions<MultiTrialSignupResponse, unknown, MultiTrialSignupRequest>
) => {
  return useMutation({
    mutationFn: (request: MultiTrialSignupRequest) => SchemaPlatformLocationFeature.MultiLocationsTrialSignup(request),
    ...options,
  });
};

export const useSelfUpgradeOrFreeTrialEligibility = (
  request: IsMultiEligibleForSelfUpgradeOrTrialRequest,
  options?: UseQueryOptions<IsMultiEligibleForSelfUpgradeOrTrialResponse>
) => {
  return useQuery({
    queryFn: async () => await SchemaSubscriptionManagerService.IsMultiEligibleForSelfUpgradeOrTrial(request),
    queryKey: freeTrialSubscriptionQueryKeys.multiSelfUpgradeOrFreeTrialEligibility(request),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
    enabled: !!(request.locationIds.length > 0 && request.featureEnum) && (options?.enabled ?? true),
  });
};

export const useFeatureMultiSubscribe = (
  options?: MutationOptions<MultiSubscribeResponse, HttpError, MultiSubscribeRequest>
) => {
  return useMutation({
    mutationFn: (request: MultiSubscribeRequest) =>
      SchemaSubscriptionManagerService.MultiSubscribe(request, {
        headers: {
          // TODO: Milind remove later after debugging
          'X-Weave-Debug-Id': 'temp-plg-ysg-ms',
        },
      }),
    ...options,
  });
};

export const useTrialExpiringFeatures = (
  request: GetTrialHistoryForMultiRequest,
  options?: UseQueryOptions<GetTrialHistoryForMultiResponse>
) => {
  return useQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.GetTrialHistoryForMulti(request),
    queryKey: freeTrialSubscriptionQueryKeys.trialExpiringFeatures(request),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
    enabled: !!request.locationIds.length && (options?.enabled ?? true),
  });
};

export const useMultiGetAddOnsSubscription = (
  request: GetSubscriptionAddOnsStatusesForMultiRequest,
  options?: UseQueryOptions<GetSubscriptionAddOnsStatusesForMultiResponse>
) => {
  return useQuery({
    queryKey: freeTrialSubscriptionQueryKeys.multiSubscriptionAddOns(request),
    queryFn: async () => await SchemaSubscriptionManagerService.GetSubscriptionAddOnsStatusesForMulti(request),
    ...options,
    enabled: !!request.locationIds.length && (options?.enabled ?? true),
  });
};

export function useGetBundleUpgradeEligibility<TData = GetBundleUpgradeEligibilityResponse>(
  request: GetBundleUpgradeEligibilityRequest,
  options?: UseQueryOptions<GetBundleUpgradeEligibilityResponse, HttpError, TData>
) {
  return useQuery({
    queryFn: async () => await SchemaPlatformLocationFeature.AreLocationsEligibleForBundleTrial(request),
    queryKey: freeTrialSubscriptionQueryKeys.bundleUpgradeEligibility(request),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
    retry: false,
    enabled: request.locationIds?.length > 0 && (options?.enabled ?? true),
  });
}

export function useGetBundleTrialStatus<TData = BundleTrialStatusResponse>(
  request: BundleTrialStatusRequest,
  options?: UseQueryOptions<BundleTrialStatusResponse, HttpError, TData>
) {
  return useQuery({
    queryFn: async () =>
      await SchemaPlatformLocationFeature.BundleTrialStatus(request).then((response) => {
        return {
          ...response,
          maxTrialEndDate: response.bundleTrialStatus
            ?.filter(({ isBundleTrialActive }) => !!isBundleTrialActive)
            ?.map(({ featureDetails = [] }) =>
              featureDetails.map(({ endDate }) => (endDate ? new Date(endDate) : undefined))
            )
            .flat()
            .sort((a, b) => (b?.getTime() ?? 0) - (a?.getTime() ?? 0))[0],
        };
      }),
    queryKey: freeTrialSubscriptionQueryKeys.bundleTrialStatus(request),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    ...options,
    retry: false,
    enabled: request.locationIds?.length > 0 && (options?.enabled ?? true),
  });
}

export function useActivateBundleTrial(
  options?: MutationOptions<BundleTrialSignupResponse, unknown, BundleTrialSignupRequest>
) {
  return useMutation({
    mutationFn: SchemaPlatformLocationFeature.BundleTrialSignup,
    ...options,
  });
}
