import { useContext, useMemo } from 'react';
import { QueryKey } from 'react-query';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useDidUpdate } from '@frontend/design-system';
import { selectedLocationContext } from '../providers';

interface UseMultiQueryParams {
  onLocationChange?: () => void;
}

const useSelectedLocation = () => {
  const locationId = useContext(selectedLocationContext);
  return locationId;
};

export const useMultiQueryUtils = ({ onLocationChange }: UseMultiQueryParams = {}) => {
  const { userID } = getUser() ?? {};
  const selectedLocation = useSelectedLocation();
  const { selectedLocationIds, accessibleLocationIds, accessibleLocationData } = useAppScopeStore();

  const locationFromStore = selectedLocationIds[0];

  const hasMultiLocations = true;

  const locationId = selectedLocation?.[0] ?? (hasMultiLocations ? selectedLocationIds[0] : locationFromStore);

  const locationIds = useMemo(
    () => (selectedLocation ? selectedLocation : hasMultiLocations ? selectedLocationIds : [locationId]),
    [hasMultiLocations, selectedLocation, selectedLocationIds, locationId]
  );

  const selectedLocations = useMemo(
    () => (hasMultiLocations ? selectedLocationIds : [locationId]),
    [hasMultiLocations, selectedLocationIds, locationId]
  );

  const allLocations = useMemo(
    () => (hasMultiLocations ? accessibleLocationIds : [locationId]),
    [hasMultiLocations, accessibleLocationIds, locationId]
  );

  useDidUpdate(() => {
    onLocationChange?.();
  }, [locationIds]);

  const getLocationData = (locationId?: string) =>
    locationId
      ? accessibleLocationData[locationId] ??
        (accessibleLocationData?.[locationId] ? accessibleLocationData?.[locationId] : undefined)
      : undefined;

  const getLocationName = (locationId?: string) => getLocationData(locationId)?.name;

  const getMultiQueryKey = (queryKey: QueryKey) => {
    if (!Array.isArray(queryKey)) queryKey = [queryKey];
    return [...locationIds, userID, ...queryKey];
  };

  return {
    isMultiLocationFeature: hasMultiLocations,
    locationId,
    locationIds,
    allLocations,
    getMultiQueryKey,
    getLocationName,
    getLocationData,
    selectedLocationIds: selectedLocations,
  };
};

interface UseDefaultLocationsOptions {
  objectToCheck: { locationId?: string[] | string };
  getAll: boolean;
}

/**
 * @param optionsObject The object in which to look for the `locationId` property.
 * If `locationId` is present, even as undefined, the default global location selector will not be used.
 *
 * This hook is primarily built for the `useMerchant` and `useMerchantsInfo` hook.
 * This hook only returns the `locationId` property of the `objectToCheck` as an array if the property exists and `getAll` is false.
 * If the property does not exist, the `locationIds` from the `useMultiQueryUtils` hook will be returned.
 */
export const useQueryLocationIds = ({ getAll, objectToCheck }: UseDefaultLocationsOptions) => {
  const { locationIds: locationIdsFromUtils, allLocations } = useMultiQueryUtils();
  const { locationId } = objectToCheck;
  const hasLocationIdProperty = 'locationId' in objectToCheck;

  return useMemo(() => {
    const locationIds = Array.isArray(locationId) ? locationId : [...(locationId ? [locationId] : [])];
    return getAll ? allLocations : hasLocationIdProperty ? locationIds : locationIdsFromUtils;
  }, [locationIdsFromUtils, locationId, getAll, hasLocationIdProperty, allLocations]);
};
