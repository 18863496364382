import { css } from '@emotion/react';
import { useCopy } from '@frontend/shared';
import { jaegerTracerId } from '@frontend/tracer';
import { theme } from '@frontend/theme';
import { Button, Modal, Text, ConfirmBadgeIcon, ErrorBadgeIcon } from '@frontend/design-system';
import { useTracer } from './use-tracer';

export const TracerModal = () => {
  const { closeTracer, tracerEnabled, tracerId, tracerVisible, traceSearchURL } = useTracer();
  const [copied, copyAction, setCopied] = useCopy(`${tracerId}`);
  const traceLink = () => {
    // open trace search in new window/tab
    window.open(traceSearchURL, '_blank');

    // close modal
    closeTracer();
  };

  return (
    <Modal
      css={css`
        position: relative;
        border-radius: 30px;
        &::after {
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 45px;

          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          pointer-events: none;
          border: 2px solid ${tracerEnabled ? theme.colors.success30 : theme.colors.critical40};
        }
        &::before {
          content: '';
          position: absolute;
          right: -20px;
          top: 50%;
          border-radius: 50px;
          height: 45px;
          transform: translateY(-50%);
          width: 12px;
          background-color: ${tracerEnabled ? theme.colors.success30 : theme.colors.critical40};
        }
      `}
      maxWidth={325}
      show={tracerVisible}
      onClose={closeTracer}
    >
      <Modal.Body
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {tracerEnabled ? <ConfirmBadgeIcon size={40} color='success' /> : <ErrorBadgeIcon size={40} color='error' />}
        <section
          css={css`
            margin-left: 25px;
          `}
        >
          <Text as='h3' size='large' color={tracerEnabled ? 'success' : 'error'} weight='bold'>
            Tracer {tracerEnabled ? 'Active' : 'Disabled'}
          </Text>
          {tracerEnabled ? (
            <Text
              onClick={() => {
                copyAction();
                setTimeout(() => setCopied(false), 3000);
              }}
              size='large'
              css={css`
                margin-bottom: 0;
                background-color: ${copied ? theme.colors.neutral20 : theme.colors.neutral10};
                padding: ${theme.spacing(0.4, 1.5)};
                text-align: center;
                border-radius: ${theme.borderRadius.small};
                cursor: ${!copied ? 'pointer' : 'default'};
                transition: 0.2s ease;
                &:hover {
                  background-color: ${theme.colors.neutral20};
                }
              `}
            >
              {copied ? (
                'Copied'
              ) : (
                <>
                  <span
                    css={css`
                      font-weight: bold;
                      margin-right: 4px;
                    `}
                  >
                    {jaegerTracerId}:
                  </span>
                  <span>{tracerId}</span>
                </>
              )}
            </Text>
          ) : (
            <Text
              size='large'
              css={css`
                margin-bottom: 0;
                margin-top: 2px;
              `}
            >
              Tracer header won&apos;t be sent.
            </Text>
          )}
        </section>
      </Modal.Body>
      <Modal.Footer>{tracerEnabled ? <Button onClick={traceLink}>Search for traces</Button> : null}</Modal.Footer>
    </Modal>
  );
};
