import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Direction_Enum, Status_Enum } from '@weave/schema-gen-ts/dist/schemas/fax/shared/v1/enums.pb';
import { Fax } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import { FaxHooks, FaxTypes } from '@frontend/api-fax';
import { FaxMediaQueries } from '@frontend/api-fax-media';
import { GlobalSearchTrackingIds, useGlobalSearch } from '@frontend/global-search';
import { useTranslation } from '@frontend/i18n';
import { SchemaFaxService } from '@frontend/schema';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { Avatar, NakedButton, phone, styles, Text, useAlert } from '@frontend/design-system';
import { useSelectedFaxShallowStore } from '../../stores';
import { ListItemStatus } from '../inbox/list-item-status';

type Props = {
  faxItem: Fax;
};

export const FaxSearchResult = memo(({ faxItem }: Props) => {
  const { t } = useTranslation('fax');
  const alert = useAlert();
  const navigate = useNavigate();
  const { selectedFax, setSelectedFax } = useSelectedFaxShallowStore('selectedFax', 'setSelectedFax');
  const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();
  const contactPhone = faxItem.contactPhone;
  const formattedPhone = phone(contactPhone ?? '', { hideCountry: true });
  const personName = `${faxItem.faxContact?.name} ${faxItem.faxContact?.secondName ?? ''}`;
  const title = personName.trim() || formattedPhone;

  const getStatus = (): FaxTypes.FaxStatus => {
    if (faxItem.status === Status_Enum.FAILED_FATAL || faxItem.status === Status_Enum.FAILED_RETRYABLE)
      return FaxTypes.FaxStatus.ERROR;
    if (!!faxItem.archivedAt) return FaxTypes.FaxStatus.ARCHIVED;
    if (faxItem.direction === Direction_Enum.OUTBOUND && faxItem.status === Status_Enum.DELIVERED)
      return FaxTypes.FaxStatus.DELIVERY_SUCCESS;
    if (faxItem.direction === Direction_Enum.OUTBOUND) return FaxTypes.FaxStatus.OUTGOING_SENT;
    if (faxItem.direction === Direction_Enum.INBOUND && !isRead) return FaxTypes.FaxStatus.UNREAD;
    return FaxTypes.FaxStatus.READ;
  };

  const { mutateAsync: generateMediaAttributes } = FaxMediaQueries.useGenerateMediaAttributes({
    onError: () => {
      alert.error(t('Failed to retrieve fax info. Please try again.'));
    },
  });

  const { addSearch, close, debouncedSearchTerm } = useGlobalSearch(['addSearch', 'close', 'debouncedSearchTerm']);

  const threadInfo = useMemo(
    () => ({
      faxId: faxItem.id ?? '',
      mediaId: faxItem.mediaId ?? '',
      contactName: personName.trim(),
      contactPhone: contactPhone ?? '',
      contactId: faxItem.faxContact?.contactId ?? '',
      profileMediaId: faxItem.faxContact?.profileMediaId ?? '',
      profileMedia: '',
      locationId: faxItem.locationId ?? '',
      direction: faxItem.direction ?? '',
      status: faxItem.status ?? '',
      isArchived: !!faxItem.archivedAt,
      isBlocked: false,
      tags: faxItem.tags ?? [],
      thumbnailIds: [],
      pages: 0,
    }),
    [faxItem]
  );

  const isRead = !!faxItem?.viewedAt;
  const isInbound = faxItem.direction === Direction_Enum.INBOUND;

  const handleClick = useCallback(async () => {
    close();
    addSearch(debouncedSearchTerm, 'faxes');
    navigate({ to: `/fax/inbox/${faxItem.id}` });

    if (!isRead && isInbound) {
      try {
        await SchemaFaxService.UpdateFaxes({
          hasBeenRead: !isRead,
          locationId: faxItem.locationId,
          faxIds: [faxItem.id],
        });
        invalidateFaxInboxList();
        invalidateFaxCount();
      } catch (error) {
        console.error('Error updating fax', error);
      }
    }

    if (
      selectedFax.faxId === faxItem.id &&
      selectedFax.mediaId === faxItem.mediaId &&
      selectedFax.thumbnailIds.length > 0
    ) {
      return;
    }

    setSelectedFax(threadInfo);

    const res = await generateMediaAttributes({
      mediaId: faxItem.mediaId ?? '',
      locationId: faxItem.locationId,
    });

    setSelectedFax({
      ...threadInfo,
      pages: res.attributes.pages,
      thumbnailIds: res.attributes.largeThumbnails ?? [],
    });
  }, [faxItem, selectedFax]);

  return (
    <NakedButton
      trackingId={GlobalSearchTrackingIds.searchResultItem('faxes')}
      onClick={handleClick}
      css={{
        display: 'block',
        border: `1px solid ${theme.colors.neutral10}`,
        borderRadius: theme.borderRadius.small,
        padding: theme.spacing(2),
        minWidth: 234,
        flex: 1,
        transition: 'background-color 0.3s ease',
        backgroundColor: theme.colors.white,
        ':hover': {
          backgroundColor: theme.colors.neutral5,
        },
      }}
    >
      <FaxesSearchItem
        title={title}
        label={faxItem.createdAt}
        phoneNumber={formattedPhone}
        personName={personName}
        status={getStatus()}
      />
    </NakedButton>
  );
});

export const FaxesSearchItem = ({
  title,
  label,
  phoneNumber,
  personName,
  status,
}: {
  title: string;
  label: string;
  phoneNumber: string;
  personName: string;
  status: FaxTypes.FaxStatus;
}) => {
  const formatTimestamp = useTimestampFormatter();

  const timestamp = formatTimestamp(label);
  return (
    <>
      <div
        role='text'
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
        }}
      >
        <Avatar name={personName.trim()} />
        <div
          css={{
            overflow: 'hidden',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              minWidth: 0,
              gap: theme.spacing(1),
            }}
          >
            <ListItemStatus status={status} />
            <Text size='medium' css={styles.truncate} textAlign='left'>
              {title}
            </Text>
          </div>
          <div>
            {!!personName.trim() ? (
              <Text size='small' color='subdued' css={styles.truncate} textAlign='left'>
                {phoneNumber}
              </Text>
            ) : null}
            <Text size='small' textAlign='left' color='subdued'>
              {timestamp}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};
