import { useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { ContentLoader, useModalControl } from '@frontend/design-system';
import { CalendarView } from '../../components/calendar-view/calendar-view';
import { CalendarViewOffices, OfficeCalendarView } from '../../components/calendar-view/types';
import { CalendarLocationSelectorModal } from '../../components/CalendarLocationSelectorModal';
import { CalendarViewV3Header } from '../../components/CalendarViewV3Header';
import { NoCalendarEventsIllustrationContainer } from '../../components/NoCalendarEventsIllustrationContainer';
import { NoProviderIllustrationContainer } from '../../components/NoProviderIllustrationContainer';
import { EventCardComponent } from '../../components/schedule-calendar-components';
import { useCalendarEventV3Context } from '../../context/CalendarEventsV3Context';
import {
  useAppointmentsInfoShallowStore,
  useCalendarEventsConfigurationShallowStore,
  useGetPractitionerDetailsForCalendarV3,
} from '../../hooks';
import { useGetOfficeHoursDetailsForCalendarV3 } from '../../hooks/scheduler-v3/use-get-office-hours-details-for-calendar-v3';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../stores/use-calendar-view-v3-header-filter-store';
import { HeaderFilterDataType, SelectedFiltersType } from '../../types';
import { CalendarEventsV3DateFormat, transformV3ResponsesForReviewRequest } from '../../utils';
import { CalendarEvents } from '../Calendar/EventsPanel/CalendarEvents';
import { CalendarEventType } from '../Calendar/types';
import { isUUID } from '../Calendar/utils';
import { convertV3CalendarEventToAppointmentDetails } from './helpers';

export const CalendarV3Container = () => {
  const { t } = useTranslation('schedule');
  const { getLocationName, selectedLocationIds } = useAppScopeStore();
  const { setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore('setConfiguredCalendarEvent');
  const { setAppointments, setRefreshAppointments, setSelectedDate } = useAppointmentsInfoShallowStore(
    'setAppointments',
    'setRefreshAppointments',
    'setSelectedDate'
  );

  const locationSelectorModalProps = useModalControl();

  const {
    isLoading,
    practitioners,
    calendarEvents,
    appointmentTypes,
    isIntegratedOffice,
    calendarEventsParams,
    calendarEventsTotalCount,
    selectedMultiLocationIds,
    hasSelectedMultiLocationIds,
    scheduleEntries,
    scheduleEntriesOfficeData,
    refetchCalendarEvents,
    setHasSelectedMultiLocationIds,
    setSelectedMultiLocationIds,
    setCalendarEventsParams,
  } = useCalendarEventV3Context();

  const {
    filteredCalendarEvents,
    setFilteredCalendarEvents,
    hasActiveFilters,
    selectedFilters,
    selectedCalendarDate,
    selectedFilteredLocationIds,
    setSelectedFilteredLocationIds,
    setRefetchCalendarEvents,
  } = useCalendarViewV3HeaderFilterShallowStore(
    'filteredCalendarEvents',
    'setFilteredCalendarEvents',
    'selectedFilters',
    'hasActiveFilters',
    'selectedCalendarDate',
    'selectedFilteredLocationIds',
    'setRefetchCalendarEvents',
    'setSelectedFilteredLocationIds'
  );

  // useEffect to set refetchCalendarEvents in the store
  useEffect(() => {
    if (refetchCalendarEvents) {
      setRefetchCalendarEvents?.(refetchCalendarEvents);
    }
  }, []);

  const filteredPractitioners = useMemo(() => {
    return selectedFilters?.practitionerIds?.length
      ? practitioners.filter((practitioner) => selectedFilters.practitionerIds?.includes(practitioner.id))
      : practitioners || [];
  }, [practitioners, selectedFilters?.practitionerIds]);

  const filteredCalendarEventsData = useMemo(() => {
    const hasPractitionerIdsFilter = !!selectedFilters?.practitionerIds?.length;
    const hasAppointmentTypeFilter = !!selectedFilters?.appointmentTypeIds?.length;
    const hasUnconfirmedStatusFilter = selectedFilters?.isUnconfirmedStatusOnly;

    const isFilterApplied =
      hasPractitionerIdsFilter && (hasAppointmentTypeFilter || hasUnconfirmedStatusFilter || hasActiveFilters);

    if (isFilterApplied) {
      return filteredCalendarEvents;
    }

    return calendarEvents;
  }, [filteredCalendarEvents, calendarEvents, selectedFilters, hasActiveFilters]);

  const { getPractitionerEventsForCalendarObject } = useGetPractitionerDetailsForCalendarV3({
    calendarEvents: filteredCalendarEventsData,
    practitioners: filteredPractitioners,
    schedules: scheduleEntries,
  });

  const { getOfficeHoursBreaksAndExceptionEventsForCalendarObject } = useGetOfficeHoursDetailsForCalendarV3({
    schedules: scheduleEntriesOfficeData,
  });

  // TODO: Review this logic to handle the configured calendar event
  const handleConfiguredCalendarEvent = (configuredCalendarEvent: CalendarEventType) => {
    const isProviderEvent = configuredCalendarEvent.providerId && isUUID(configuredCalendarEvent.providerId);
    const isOfficeEvent = configuredCalendarEvent.locationId && !configuredCalendarEvent.providerId;
    if (isOfficeEvent || isProviderEvent) {
      setConfiguredCalendarEvent(configuredCalendarEvent);
    }
  };

  const getOfficeCalendarViewObject = (locationId: string): OfficeCalendarView => {
    // TODO: Add logic to get office hours and exceptions
    return {
      officeName: getLocationName(locationId) ?? '',
      officeBreaks: getOfficeHoursBreaksAndExceptionEventsForCalendarObject(locationId),
      providers: getPractitionerEventsForCalendarObject(locationId),
      locationId: locationId,
      calendarDateValue: dayjs(selectedCalendarDate).format('YYYY-MM-DD') as string,
    };
  };

  const appointmentTypeListForHeader = useMemo<HeaderFilterDataType[]>(() => {
    return (
      appointmentTypes?.map((appointmentType) => ({
        label: appointmentType.externalName || appointmentType.displayName || '',
        id: appointmentType.id,
        locationId: appointmentType.locationId,
      })) || []
    );
  }, [appointmentTypes]);

  const practitionerListForHeader = useMemo<HeaderFilterDataType[]>(() => {
    return (
      practitioners?.map((practitioner) => ({
        label:
          practitioner.externalName ||
          practitioner.displayName ||
          `${practitioner.firstName} ${practitioner.lastName}`.trim() ||
          '',
        id: practitioner.id,
        locationId: practitioner.locationId,
      })) || []
    );
  }, [practitioners]);

  const [defaultAppointmentTypeIds, defaultPractitionerIds] = useMemo<[string[], string[]]>(() => {
    return [
      appointmentTypeListForHeader.map((appointmentType) => appointmentType.id),
      practitionerListForHeader.map((practitioner) => practitioner.id),
    ];
  }, [appointmentTypeListForHeader, practitionerListForHeader]);

  const unconfirmedCalendarEventsCount = useMemo(() => {
    return (
      (hasActiveFilters ? filteredCalendarEventsData : calendarEvents)?.filter(
        (calendarEvent) => calendarEvent?.attendeeStatus?.toLowerCase() === 'unconfirmed'
      ).length || 0
    );
  }, [calendarEvents, filteredCalendarEventsData, hasActiveFilters]);

  const getFilteredCalendarEvents = useCallback(
    (calendarEventsData: CalendarEvent[], selectedFiltersData: SelectedFiltersType) => {
      const filteredAppointments = calendarEventsData?.filter((calendarEvent) => {
        const hasPractitionerId =
          calendarEvent?.organizerId && selectedFiltersData?.practitionerIds?.includes(calendarEvent.organizerId);

        const hasAppointmentTypeId =
          calendarEvent?.referenceTypeId &&
          selectedFiltersData?.appointmentTypeIds?.includes(calendarEvent.referenceTypeId);

        const hasUnconfirmedStatus =
          selectedFiltersData?.isUnconfirmedStatusOnly &&
          calendarEvent?.attendeeStatus?.toLowerCase() === 'unconfirmed';

        if (!hasPractitionerId) return false;

        const hasAppointmentTypeFilter = !!selectedFiltersData?.appointmentTypeIds?.length;
        const isUnconfirmedOnlyFilter = selectedFiltersData?.isUnconfirmedStatusOnly;

        if (hasAppointmentTypeFilter && isUnconfirmedOnlyFilter) {
          return hasAppointmentTypeId && hasUnconfirmedStatus;
        }

        if (hasAppointmentTypeFilter) {
          return hasAppointmentTypeId;
        }

        if (isUnconfirmedOnlyFilter) {
          return hasUnconfirmedStatus;
        }

        return true;
      });

      return filteredAppointments;
    },
    []
  );

  // useEffect to filter calendar events based on selected filters
  useEffect(() => {
    const hasFilters =
      (!!selectedFilters?.practitionerIds?.length ||
        !!selectedFilters?.appointmentTypeIds?.length ||
        selectedFilters?.isUnconfirmedStatusOnly) &&
      hasActiveFilters;

    if (hasFilters && selectedFilters?.practitionerIds?.length) {
      const filteredAppointments = getFilteredCalendarEvents(calendarEvents || [], selectedFilters);
      setFilteredCalendarEvents(filteredAppointments);
    } else {
      setFilteredCalendarEvents([]);
    }
  }, [selectedFilters, calendarEvents, hasActiveFilters]);

  const calendarObject = useMemo<CalendarViewOffices>(() => {
    const locationIds = selectedFilteredLocationIds?.length ? selectedFilteredLocationIds : selectedMultiLocationIds;
    if (!!locationIds?.length) {
      return locationIds.map((locationId) => {
        return getOfficeCalendarViewObject(locationId ?? '');
      });
    }
    return [getOfficeCalendarViewObject(selectedLocationIds[0])];
  }, [
    practitioners,
    calendarEvents,
    filteredCalendarEvents,
    filteredPractitioners,
    selectedMultiLocationIds,
    selectedLocationIds,
    selectedFilters,
    selectedFilteredLocationIds,
    getOfficeCalendarViewObject,
    selectedCalendarDate,
  ]);

  const handleMultiLocationSelection = useCallback((locationIds: string[]) => {
    setSelectedMultiLocationIds(locationIds);
    setHasSelectedMultiLocationIds(true);
  }, []);

  // useEffect to update calendarEventsParams when selectedCalendarDate changes
  useEffect(() => {
    const hasSelectedCalendarDate = !!selectedCalendarDate && !!calendarEventsParams.endDateTime;
    const hasSameDate =
      dayjs(selectedCalendarDate).startOf('day').utc().format(CalendarEventsV3DateFormat) ===
      dayjs(calendarEventsParams.startDateTime).format(CalendarEventsV3DateFormat);

    if (hasSelectedCalendarDate && !hasSameDate) {
      const startDate = dayjs(selectedCalendarDate).startOf('day').utc();
      setCalendarEventsParams({
        ...calendarEventsParams,
        startDateTime: startDate.format(CalendarEventsV3DateFormat),
        endDateTime: startDate.add(1, 'day').format(CalendarEventsV3DateFormat),
      });
      // Set the selected date in the store for Schedule Pulse
      setSelectedDate(selectedCalendarDate);
    }
  }, [selectedCalendarDate]);

  // useEffect to update selectedMultiLocationIds when selectedLocationIds changes
  useEffect(() => {
    if (selectedLocationIds && selectedLocationIds.length > 4 && !hasSelectedMultiLocationIds) {
      locationSelectorModalProps.openModal();
    } else if (selectedLocationIds && selectedLocationIds.length <= 4) {
      setSelectedMultiLocationIds(selectedLocationIds);
    }
  }, [selectedLocationIds, hasSelectedMultiLocationIds]);

  // Convert calendarEvents to appointments format to display in event cards and store in appointments store
  useEffect(() => {
    if (calendarEvents?.length > 0) {
      const appointments = convertV3CalendarEventToAppointmentDetails(calendarEvents);
      setAppointments(appointments);
    }
  }, [calendarEvents]);

  const alphabeticallySortedPractitionerList = useMemo(() => {
    const providersListData = transformV3ResponsesForReviewRequest({
      practitionerDataV3: practitioners,
    }).transformedPractitioners as unknown as ScheduleTypes.Provider[];
    return sortBy(providersListData, [(practitioner) => practitioner.lastName?.toLowerCase()]);
  }, [practitioners]);

  // Update setRefreshAppointment when loading is finished
  useEffect(() => {
    if (!isLoading) {
      setRefreshAppointments(refetchCalendarEvents);
    }
  }, [isLoading]);

  if (isLoading) return <ContentLoader show message={t('Loading Appointments...')} />;

  return (
    <>
      <section css={containerStyles} id='calendar-view-wrapper'>
        <CalendarViewV3Header
          selectedMultiLocationIds={selectedMultiLocationIds}
          appointmentTypeList={appointmentTypeListForHeader}
          practitionersList={practitionerListForHeader}
          unconfirmedCalendarEventsCount={unconfirmedCalendarEventsCount}
          defaultAppointmentTypeIds={defaultAppointmentTypeIds}
          defaultPractitionerIds={defaultPractitionerIds}
          calendarEventsCount={hasActiveFilters ? filteredCalendarEventsData?.length : calendarEventsTotalCount || 0}
          selectedFilteredLocationIds={selectedFilteredLocationIds}
          setSelectedFilteredLocationIds={setSelectedFilteredLocationIds}
        />

        <CalendarView
          data={calendarObject}
          onEventCreate={(startHour, endHour, providerName, providerId, isValid, locationId, calendarDateValue) => {
            handleConfiguredCalendarEvent({
              startHour,
              endHour,
              providerName,
              providerId,
              isValid,
              locationId,
              calendarDateValue,
            });
          }}
          EventCardComponent={EventCardComponent}
          NoProviderComponent={
            isIntegratedOffice ? NoCalendarEventsIllustrationContainer : NoProviderIllustrationContainer
          }
          shouldAllowEventCreation={!isIntegratedOffice}
        />
        <CalendarEvents
          customSelectedLocationIds={selectedMultiLocationIds}
          customProvidersList={alphabeticallySortedPractitionerList}
        />
      </section>

      <CalendarLocationSelectorModal
        modalProps={locationSelectorModalProps.modalProps}
        locationIds={selectedLocationIds}
        updateSelectedLocationIds={handleMultiLocationSelection}
      />
    </>
  );
};

const containerStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  container-type: inline-size;
`;
