import { useQuery } from 'react-query';
import { CallGroupApi } from '@frontend/api-call-group';
import { i18next } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { trackingId } from '../../../tracking';
import { CallGroupPayload, InstructionFieldsProps } from '../types';
import { InstructionFields } from './base';

export const CallGroupInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<CallGroupPayload>['onChange'];
  option: CallGroupPayload;
}) => {
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[selectedLocationIds[0]];
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const { data = [], isLoading } = useQuery({
    queryKey: [queryKeys.callGroups(), location.id],
    queryFn: () => CallGroupApi.listCallGroups({ tenantId: tenantId }),
    select: (data) => {
      return (data.callGroups ?? [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((cg) => ({
          value: cg.id,
          label: cg.name,
        }));
    },
    staleTime: 0,
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<CallGroupPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          callGroupId: data ?? [],
        },
        value: option,
      }}
    />
  );
};

const schema = {
  type: 'callGroup',
  fields: {
    callGroupId: {
      label: i18next.t('Call Group', { ns: 'phone' }),
      name: 'callGroupId',
      type: 'dropdown',
    },
    callerName: {
      label: i18next.t('Caller Label (Optional)', { ns: 'phone' }),
      name: 'callerName',
      type: 'text',
      helperText: i18next.t('Label displays on Weave phone screen while call is ringing', { ns: 'phone' }),
      trackingId: trackingId({ context: 'setting', feature: 'phone-trees', details: 'caller-label' }),
    },
  },
} as const;
