import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { PaymentRequestModal, usePaymentRequestModal } from '@frontend/payments-invoice-create';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { PAYMENT_ACCOUNT_HELP_LINK, SHARE_INVOICE_ROUTE } from '../constants';
import { PaymentActionEnum } from '../types';

type UsePaymentActionProps = {
  locationId: string;
};
export const usePaymentAction = ({ locationId }: UsePaymentActionProps) => {
  const { navigate: navigateToSettings } = useSettingsNavigate();
  const navigate = useNavigate();
  const { modalProps, openModal: openInvoiceModal } = usePaymentRequestModal({ locationIds: [locationId] });

  const handleAction = useCallback(
    (action: PaymentActionEnum) => {
      switch (action) {
        case PaymentActionEnum.CREATE_INVOICE:
          openInvoiceModal();
          break;
        case PaymentActionEnum.CREATE_ACCOUNT:
          navigateToSettings({ to: '/payments/account-info' });
          break;
        case PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE:
          window.open(PAYMENT_ACCOUNT_HELP_LINK, '_blank', 'noopener noreferrer');
          break;
        case PaymentActionEnum.SHARE_INVOICE:
          navigate({ to: SHARE_INVOICE_ROUTE });
          break;
        case PaymentActionEnum.ONLINE_BILL_PAY:
          navigateToSettings({ to: '/payments/online-bill-pay' });
          break;
        default:
          break;
      }
    },
    [openInvoiceModal]
  );

  return {
    handleAction,
    InvoiceModal: () => <PaymentRequestModal {...modalProps} />,
  };
};
