import { useMemo } from 'react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../utils';
import { MultiOverview } from '../../components';
import { ARMultiComparisonConversionChart, ARMultiComparisonNoShowChart } from '../charts';
import { useARRoiShallowStore, useFetchARROIOOverview } from '../hooks';
import { ARRoi } from '../types';

export const ARRoiMultiSubView = (props: ReturnType<typeof useFetchARROIOOverview>) => {
  const { t } = useTranslation('analytics');
  const { filters } = useARRoiShallowStore('filters');
  const search = useSearch<{ Search: { view: ARRoi } }>();
  const ChartComponent =
    search.view === 'arConversion' ? ARMultiComparisonConversionChart : ARMultiComparisonNoShowChart;
  const locationsCount = filters?.locationIds?.length || 0;

  const overview = useMemo(() => {
    let value = '0';
    let message = '';

    switch (search.view) {
      case 'arConversion':
        value = formatters.percent.format(
          (props?.aggregated?.appointmentsCompleted?.appointmentsCompletedByReminders || 0) /
            (props?.aggregated?.appointmentsCompleted?.totalAppointmentsCompleted || 1)
        );
        message = t('of the total appointments completed were attributed to appointment reminders');
        break;

      case 'noShowRate':
        value = formatters.percent.appendPercent(Math.abs(props?.aggregated?.noShowRate?.difference || 0));
        message = t(
          "is the average reduction in no-show rates for practices using Appointment Reminders compared to those that don't."
        );
        break;
    }

    return {
      message,
      value,
    };
  }, [props.aggregated, locationsCount, search.view]);

  return (
    <>
      <MultiOverview message={overview.message} miniGap value={overview.value} />
      <ChartComponent {...props} />
    </>
  );
};
