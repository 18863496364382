import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { FreeTrialSubscriptionQueries } from '@frontend/api-free-trial-subscription';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  Button,
  CheckboxField,
  ContentLoader,
  Modal,
  NakedButton,
  Text,
  TextLink,
  useAlert,
  useForm,
  useModalControl,
  useTooltip,
} from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../constants/self-upgrade';
import { BundleUpgradeTrackingIds } from '../../constants/tracking-ids';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { getScheduleDemoLink, getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { FeatureUpgradeModalComponentProps } from '../../types';
import { BundleFeatureChips } from './BundleFeatureChips';
import { BundleHighlightedFeatures } from './BundleHighlightedFeatures';
import { BundleTrialActiveModal } from './BundleTrialActiveModal';
import { BundleTrialTermsModal } from './BundleTrialTermsModal';
import { BundleUpgradeHeader } from './BundleUpgradeHeader';
import { BundleFeatureEnum, BundleFeatureInfoMap } from './constants';
import { RequestBundleTrialModal } from './RequestBundleTrialModal';
import { getBundleWiseFeatureList, getBundleWiseHighlightedFeatureList } from './utils';

export const BundleUpgradeModal: FC<FeatureUpgradeModalComponentProps> = ({ onActionSuccess, modalControls }) => {
  const [view, setView] = useState<'highlights' | 'all-feature'>('highlights');
  const [highlightedFeatureEnum, setHighlightedFeatureEnum] = useState<BundleFeatureEnum | undefined>();
  const { t } = useTranslation('bundleUpgrade');
  const alerts = useAlert();
  const { accessibleLocationData } = useAppScopeStore();
  const qc = useQueryClient();
  const email = getUser()?.username;

  const { getFieldProps, values, seedValues } = useForm({
    fields: {
      termsAccept: {
        type: 'checkbox',
        value: false,
      },
    },
  });

  const isSmallScreen = useMatchMedia({ maxWidth: breakpoints.medium.min });

  const { openModal: openTrialActiveModal, modalProps: trialActiveModalProps } = useModalControl();
  const { openModal: openTermsModal, modalProps: termsModalProps } = useModalControl();
  const { openModal: openRequestUpgradeModal, modalProps: requestUpgradeModalProps } = useModalControl();
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();
  const invalidationRequired = useRef(false);

  const {
    recommendedBundle,
    hasAdminAccessInAtLeastOne,
    isLoading,
    selectedLocationIds = [],
    invalidateSubscriptionConfig,
  } = useFeatureSubscription();

  const { mutate: activateBundleTrialMutate, isLoading: isMutating } =
    FreeTrialSubscriptionQueries.useActivateBundleTrial({
      onSuccess: () => {
        // Invalidate the trial status query to reflect on activated modal
        // Other invalidation is not done immediately to prevent unmounting of Trial Activated modal from parent
        qc.invalidateQueries(
          FreeTrialSubscriptionQueries.freeTrialSubscriptionQueryKeys.bundleTrialStatus({
            locationIds: selectedLocationIds,
          })
        );
        alerts.success(t('Trial activated successfully.'));
        openTrialActiveModal();
        modalControls.modalProps.onClose();
        // Flag is set for invalidating the subscription config on modal close or unmount
        invalidationRequired.current = true;
      },
      onError: (error, request) => {
        alerts.error(t('Failed to activate trial. Please try again.'));
        sentry.log({
          message: 'Failed to activate bundle trial',
          data: {
            error,
            request,
          },
        });
      },
    });

  const { featureEnumList, highlightedFeatureEnumList } = useMemo(
    () => ({
      highlightedFeatureEnumList: getBundleWiseHighlightedFeatureList(recommendedBundle),
      featureEnumList: getBundleWiseFeatureList(recommendedBundle),
    }),
    [recommendedBundle]
  );

  const featureImageSrc = useMemo(() => {
    return highlightedFeatureEnum ? BundleFeatureInfoMap[highlightedFeatureEnum].imageSrc : undefined;
  }, [highlightedFeatureEnum]);

  const handleTrialAction = () => {
    activateBundleTrialMutate({
      locationIds: selectedLocationIds,
      trialSignupMeta: {
        userEmailId: email,
        vertical: accessibleLocationData[selectedLocationIds[0]]?.vertical,
      },
    });
  };

  const handleCallToUpgrade = () => {
    window.open(getScheduleDemoLink('bundle', email), '_blank', 'noopener noreferrer');
    onActionSuccess({ actionType: SelfUpgradeActionType.SCHEDULE_CALL });
  };

  const handleRequestUpgrade = () => {
    openRequestUpgradeModal();
    modalControls.modalProps.onClose();
  };

  const handleTrialActiveModalClose = () => {
    if (invalidationRequired.current) {
      invalidateSubscriptionConfig();
      invalidationRequired.current = false;
    }
    trialActiveModalProps.onClose();
    onActionSuccess({ actionType: SelfUpgradeActionType.FREE_TRIAL });
  };

  useEffect(() => {
    if (modalControls.modalProps.show) {
      setView('highlights');
      seedValues({ termsAccept: false });
    }
  }, [modalControls.modalProps.show]);

  useEffect(() => {
    return () => {
      // Invalidate the subscription query in case component is unmounted by actions like navigate back
      invalidationRequired.current && invalidateSubscriptionConfig();
    };
  }, []);

  const showFeatureImage = view === 'highlights' && !isSmallScreen && !!highlightedFeatureEnum && !!featureImageSrc;

  return (
    <>
      <Modal {...modalControls.modalProps} css={modalStyles}>
        <ContentLoader show={isMutating || isLoading} />
        <Modal.Body css={modalBodyStyles}>
          {modalControls.modalProps.show && (
            <>
              <NakedButton
                trackingId={BundleUpgradeTrackingIds.dismissAction}
                onClick={modalControls.modalProps.onClose}
                css={dismissBtnStyles}
              >
                <Icon name='x-small' />
              </NakedButton>
              <section css={[contentSectionStyles, { flex: showFeatureImage ? 0.6 : 1 }]}>
                <BundleUpgradeHeader bundle={recommendedBundle} />
                {view === 'highlights' && (
                  <>
                    <BundleHighlightedFeatures
                      bundleFeatureEnumList={highlightedFeatureEnumList}
                      selectedBundleFeatureEnum={highlightedFeatureEnum}
                      onChange={setHighlightedFeatureEnum}
                    />
                    <NakedButton css={viewAllButtonStyles} onClick={() => setView('all-feature')}>
                      <Text size='medium' css={{ color: theme.colors.indigo50 }}>
                        {t('All {{bundleName}} Trial Features', {
                          bundleName: getUpgradeBundleDisplayName(recommendedBundle),
                        })}
                      </Text>
                      <Icon name='caret-right-small' size={14} />
                    </NakedButton>
                  </>
                )}
                {view === 'all-feature' && (
                  <>
                    <NakedButton css={backButtonStyles} onClick={() => setView('highlights')}>
                      <Icon name='caret-left-small' size={14} color='light' />
                      <Text size='medium' color='light' onClick={() => setView('highlights')}>
                        {t('Back')}
                      </Text>
                    </NakedButton>

                    <BundleFeatureChips bundleFeatureEnumList={featureEnumList} />

                    <Text size='medium' color='light'>
                      {t('Some features may have limited or no access during trial. Click on a feature to read more')}
                    </Text>
                  </>
                )}

                <div css={actionBarStyles}>
                  {hasAdminAccessInAtLeastOne ? (
                    <>
                      <CheckboxField
                        css={termsCheckboxStyle}
                        trackingId={BundleUpgradeTrackingIds.termsCheckbox}
                        {...getFieldProps('termsAccept')}
                        label={
                          <>
                            <Text as='span' size='medium'>
                              {t('I agree to the')}{' '}
                            </Text>
                            <TextLink
                              as='span'
                              size='medium'
                              onClick={(e) => {
                                e.preventDefault();
                                openTermsModal();
                              }}
                              trackingId={BundleUpgradeTrackingIds.viewTermsAction}
                            >
                              {t('terms and conditions')}
                            </TextLink>
                          </>
                        }
                      />
                      <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        <Button
                          variant='tertiary'
                          onClick={handleTrialAction}
                          trackingId={BundleUpgradeTrackingIds.trialAction}
                          disabled={!values.termsAccept}
                          {...(values.termsAccept ? null : triggerProps)}
                        >
                          {t('Start Trial')}
                        </Button>
                        <Button trackingId={BundleUpgradeTrackingIds.upgradeAction} onClick={handleCallToUpgrade}>
                          {t('Call to Upgrade')}
                        </Button>
                        <Tooltip {...tooltipProps}>{t('Agree to terms and conditions to activate trial')}</Tooltip>
                      </div>
                    </>
                  ) : (
                    <div css={requestUpgradeActionContainerStyles}>
                      <Text size='medium' color='light'>
                        {t('A request will be sent to your admin')}
                      </Text>
                      <Button trackingId={BundleUpgradeTrackingIds.requestUpgradeAction} onClick={handleRequestUpgrade}>
                        {t('Request Upgrade')}
                      </Button>
                    </div>
                  )}
                </div>
              </section>
              {showFeatureImage && (
                <section css={imgSectionStyles}>
                  {!!featureImageSrc && (
                    <img src={featureImageSrc} loading='lazy' width='100%' height='100%' css={{ objectFit: 'cover' }} />
                  )}
                </section>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      <BundleTrialActiveModal
        showTrialDate
        {...trialActiveModalProps}
        locationIds={selectedLocationIds}
        onClose={handleTrialActiveModalClose}
      />
      <BundleTrialTermsModal bundle={recommendedBundle} {...termsModalProps} />
      <RequestBundleTrialModal
        onActionSuccess={() => {
          requestUpgradeModalProps.onClose();
          onActionSuccess({ actionType: SelfUpgradeActionType.REQUEST_UPGRADE });
        }}
        bundle={recommendedBundle}
        {...requestUpgradeModalProps}
      />
    </>
  );
};

const modalStyles = css({
  padding: 0,
  minWidth: 'min(900px, 90vw)',
  height: 'min(90vh, 650px)',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const modalBodyStyles = css({ display: 'flex', padding: 0, gap: 0, overflow: 'auto' });

const contentSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  overflowY: 'auto',
});

const imgSectionStyles = css({
  overflow: 'hidden',
  flex: 0.4,
});

const viewAllButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1, 1.5),
  backgroundColor: theme.colors.indigo5,
  color: theme.colors.indigo50,
  width: 'max-content',
  borderRadius: theme.borderRadius.large,
});

const backButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: 'max-content',
});

const termsCheckboxStyle = css({
  margin: 0,
  alignItems: 'center',
  flex: 1,
});

const actionBarStyles = css({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: 'auto',
});

const dismissBtnStyles = css({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.full,
});

const requestUpgradeActionContainerStyles = css({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
});
