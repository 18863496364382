import { FC, useEffect, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { processExportableData } from '@frontend/file-download-helper';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { TableColumnConfig, useAlert } from '@frontend/design-system';
import { BulkMessageButton, RecapturedMetric } from '..';
import { InlineStats, TabbedTable } from '../..';
import { useAnalyticsOrgLocations, useShowBulkMessageButton } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { formatters } from '../../../utils';
import { commonStyles } from '../../../views';
import { PatientsHelpers, getPracticeAnalyticsQueryVariables, useSubViewTableColConfig } from '../helpers';
import { usePatientsLastContacted, usePracticeAnalyticsShallowStore } from '../hooks';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      recapturedPatients: scheduledPatients {
        details(start: $start, end: $end, step: $step) {
          patients {
            id
            productionAmount
            nextAppointmentDate
            nextAppointmentScheduledDate
            FirstName: firstName
            LastName: lastName
            PMID: pmid
            MobilePhone: mobilePhone
            HomePhone: homePhone
            WorkPhone: workPhone
            Email: email
            PreferredName: preferredName
            Birthdate: birthdate
            Gender: gender
          }
        }
        totals(start: $start, end: $end, step: $step) {
          leadTime
          patients
          production
        }
      }
    }
  }
`;

export const RecapturedPatientsSubView: FC<React.PropsWithChildren<PracticeAnalyticsTypes.MetricSubViewProps>> = ({
  exportEnabled,
  onPatientsLoad,
}) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount',
    'showDemoChipAndBanner'
  );
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `recapturedPatientsSubView-${isDemoAccount}-${JSON.stringify(filters)}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.RecapturedPatientsTrend>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.recapturedPatients : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    return {
      aggregatedData: PracticeAnalyticsAggregations.recapturedPatientsDetails(data, filters.locations?.length),
      patientIds: Object.values(data?.data || {}).reduce((acc, locationDetails) => {
        return PatientsHelpers.collectPatientIds(locationDetails?.location?.recapturedPatients.details?.patients, acc);
      }, [] as string[]),
    };
  }, [data?.data]);

  const patientsLastContacted = usePatientsLastContacted({
    patientIds: processedData.patientIds,
  });

  const middleColumns: TableColumnConfig<PracticeAnalyticsTypes.PatientInfo>[] = [
    {
      Header: t('Recapture Date'),
      headerLabel: t('Recapture Date'),
      accessor: ({ nextAppointmentScheduledDate }) => formatters.date.format(nextAppointmentScheduledDate),
      id: 'nextAppointmentScheduledDate',
      sortType: 'date',
      sortInverted: true,
      width: 200,
    },
  ];

  useEffect(() => {
    onPatientsLoad?.(processedData.aggregatedData.recapturedPatients.details.patients || []);
  }, [processedData]);

  return (
    <>
      <InlineStats
        data={[
          {
            label: t('Recaptured Patients'),
            value: formatters.value.format(processedData.aggregatedData.recapturedPatients.totals.patients || 0),
          },
          {
            label: t('Recaptured Production'),
            value: formatters.currency.format(processedData.aggregatedData.recapturedPatients.totals.production || 0),
          },
          {
            label: t('Avg. Lead Time (Days)'),
            value: formatters.value.format(
              Math.round(processedData.aggregatedData.recapturedPatients.totals.leadTime || 0)
            ),
          },
        ]}
        isDemoAccount={showDemoChipAndBanner}
        isLoading={isLoading}
      />
      <RecapturedMetric clickNoop isDrillDownPage />
      <TabbedTable<PracticeAnalyticsTypes.PatientInfo>
        actions={BulkMessageButton}
        autorizedToExport={PracticeAnalyticsApi.auditPracticeAnalyticsExport}
        exportFileName='Recaptured Patients' // Translation not needed
        colConfig={useSubViewTableColConfig({
          actionsTrackingIdBase: 'recaptured-patient',
          middleColumns,
          patientsLastContacted,
        })}
        data={processedData.aggregatedData.recapturedPatients.details.patients || []}
        emptyStateConfig='users'
        isExportReady={!isLoading && !patientsLastContacted.isLoading}
        isLoading={isLoading}
        isSelectable={isBulkMessagingEnabled}
        processExportableData={(data) =>
          // Translation is not needed as this is for export purpose
          processExportableData({
            columns: [
              'Patient Name',
              ...(multipleLocationsSelected ? ['Location Name'] : []),
              'Phone Number',
              'Email',
              'Recapture Date',
              'Last Contacted',
              '$ Value',
            ],
            data,
            deriveExportValue: (params) =>
              PatientsHelpers.deriveExportValue({
                ...params,
                lastContactedDates: patientsLastContacted.lastContactedDates,
                locationNames,
              }),
            sortColumn: 'productionAmount',
          })
        }
        showExportIcon={exportEnabled}
        tableInstanceId='recaptured-patients-details'
        trackingIdBase='recaptured-patients'
        wrapperStyle={commonStyles.drillDownTableSize}
      />
    </>
  );
};
