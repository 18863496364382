import { darken, desaturate } from 'color2k';

export const stickyColors = {
  yellow: '#FFF8C6',
  blue: '#C6EAFF',
  green: '#C4F8CC',
  purple: '#E3C6FF',
  pink: '#FFC6E8',
  orange: '#FDD9BC',
} as const satisfies Record<string, string>;

export type StickyColor = keyof typeof stickyColors;

export function generateGradient(baseColor: string) {
  const start = desaturate(darken(baseColor, 0.08), 0.3);
  const middle = desaturate(darken(baseColor, 0.15), 0.5);
  const end = desaturate(darken(baseColor, 0.3), 0.6);

  return `linear-gradient(
      135deg,
      ${start} 0%,
      ${middle} 46%,
      ${end} 100%
    )`;
}

export const stickyDragGradients = (function () {
  return (Object.keys(stickyColors) as StickyColor[]).reduce((acc, key) => {
    acc[key] = generateGradient(stickyColors[key]);
    return acc;
  }, {} as Record<StickyColor, string>);
})();

export function generateGradientTwo(baseColor: string) {
  const start = darken(baseColor, 0.03);
  const middle = desaturate(darken(baseColor, 0.15), 0.5);
  const end = desaturate(darken(baseColor, 0.4), 0.5);

  return `linear-gradient(
      -230deg,
      ${start} 0%,
      ${middle} 46%,
      ${end} 100%
    )`;
}

export const stickyDragGradientsTwo = (function () {
  return (Object.keys(stickyColors) as StickyColor[]).reduce((acc, key) => {
    acc[key] = generateGradientTwo(stickyColors[key]);
    return acc;
  }, {} as Record<StickyColor, string>);
})();
