import { useCallback } from 'react';
import { limitDateRange } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { debounce } from '@frontend/timer';
import { ExportIcon, useAlert } from '@frontend/design-system';
import { useCanDoAction } from '../../../hooks';
import { SearchFailedPaymentsParams } from '../../../hooks/payment-failed';
import { useExportFailedTransactionsQuery } from '../../../hooks/use-failed-transactions-queries';
import { hasFailedTransactionFilters } from '../../../utils/failed-transactions-helpers';
import {
  FilterExportWarning,
  InvoiceExportModal,
  useInvoiceExportModalControl,
} from '../../Invoices/InvoiceExport/invoice-export-confirmation';

type CsvExportProps = {
  filter: SearchFailedPaymentsParams;
  currentPage: number;
  numRows?: number;
};

export const useCsvExport = ({ filter, currentPage }: CsvExportProps) => {
  const alert = useAlert();
  const { canExport } = useCanDoAction();
  const { t } = useTranslation('payments');
  const { exportFailedTransactions, isExporting } = useExportFailedTransactionsQuery({
    searchParams: filter,
    currentPage,
    numRows: filter.first,
  });

  const { showInvoiceExportWarning, ...invoiceModalProps } = useInvoiceExportModalControl();

  const hasFiltersOtherThanDates = hasFailedTransactionFilters({ ...filter, submittedAt: undefined });

  const handleExportClick = useCallback(
    debounce(async () => {
      if (canExport) {
        const warnings: FilterExportWarning = {};

        const dateRangeExists = !!(filter.submittedAt?.greaterThanEqual && filter.submittedAt?.lessThanEqual);
        // see if data will be truncated
        if (
          dateRangeExists &&
          limitDateRange([filter.submittedAt?.greaterThanEqual, filter.submittedAt?.lessThanEqual]).limited
        ) {
          warnings.truncateDateRange = true;
        }
        // when other filters are applied with out a date range, they are truncated to the
        // default MAX range (3 months) because the result will show for all time
        else if (!dateRangeExists && hasFiltersOtherThanDates) {
          warnings.truncateDateRange = true;
        }

        // show warnings if any
        if (Object.keys(warnings).length > 0) {
          showInvoiceExportWarning(warnings);
        } else {
          exportFailedTransactions();
        }
      } else {
        alert.error(t('Payment admin role required'));
      }
    }, 1000),
    [filter.submittedAt, hasFiltersOtherThanDates]
  );

  const ExportAction = {
    label: t('Export'),
    Icon: ExportIcon,
    onClick: handleExportClick,
  };

  return {
    ExportAction,
    ExportFailedTransactionsModal: <InvoiceExportModal {...invoiceModalProps} onConfirm={exportFailedTransactions} />,
    isExporting,
  };
};
