import { ReactNode } from 'react';
import {
  CallGroupExpansion,
  CallObject,
  CallQueueExpansion,
  DeviceExpansion,
  ForwardingNumberExpansion,
  VoicemailExpansion,
  PhoneHoursExpansion,
  PlayMessageExpansion,
  PhoneTreeExpansion,
} from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { PhoneHoursScheduleRule } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { NodeProps, Node, Edge, EdgeProps } from '@xyflow/react';

export const isNodeType = (type: string): type is NodeTypes => {
  return Object.values(NodeType).includes(type as NodeTypes);
};

export const NodeType = {
  Start: 'start',
  Basic: 'basic',
  Boolean: 'boolean',
  ForwardDevice: 'forwardDevice',
  ForwardNumber: 'forwardNumber',
  VoicemailBox: 'voicemailBox',
  VoicemailPrompt: 'voicemailPrompt',
  Terminate: 'terminate',
  CallGroup: 'callGroup',
  CallQueue: 'callQueue',
  CallRoute: 'callRoute',
  PhoneTree: 'phoneTree',
  Repeat: 'repeat',
  TreeOption: 'treeOption',
  PlayMessage: 'playMessage',
  OfficeHours: 'officeHours',
  OpenPhoneHours: 'openPhoneHours',
  ClosedPhoneHours: 'closedPhoneHours',
  BreakPhoneHours: 'breakPhoneHours',
  Fallback: 'fallback',
  PlaceHolder: 'placeHolder',
} as const;

// Type for all of the values of NodeType
export type NodeTypes = (typeof NodeType)[keyof typeof NodeType];

export const associativeNodes = [NodeType.PhoneTree, NodeType.OfficeHours];

export const NodeTypeLabels: Record<NodeTypes, string> = {
  [NodeType.Start]: 'Start',
  [NodeType.Basic]: 'Basic',
  [NodeType.Boolean]: 'Boolean',
  [NodeType.ForwardDevice]: 'Forward Device',
  [NodeType.ForwardNumber]: 'Forward Number',
  [NodeType.VoicemailBox]: 'Voicemail Box',
  [NodeType.VoicemailPrompt]: 'Voicemail Prompt',
  [NodeType.Terminate]: 'Terminate',
  [NodeType.CallGroup]: 'Call Group',
  [NodeType.CallQueue]: 'Call Queue',
  [NodeType.CallRoute]: 'Call Route',
  [NodeType.PhoneTree]: 'Phone Tree',
  [NodeType.Repeat]: 'Repeat',
  [NodeType.TreeOption]: 'Tree Option',
  [NodeType.PlayMessage]: 'Play Message',
  [NodeType.OfficeHours]: 'Phone Hours',
  [NodeType.OpenPhoneHours]: 'Open Phone Hours',
  [NodeType.ClosedPhoneHours]: 'Closed Phone Hours',
  [NodeType.BreakPhoneHours]: 'Break Phone Hours',
  [NodeType.Fallback]: 'Fallback',
  [NodeType.PlaceHolder]: 'Place Holder',
} as const;

export const extendableNodes: NodeTypes[] = [
  NodeType.Start,
  NodeType.TreeOption,
  NodeType.OpenPhoneHours,
  NodeType.ClosedPhoneHours,
  NodeType.BreakPhoneHours,
  NodeType.CallGroup,
  NodeType.CallQueue,
  NodeType.ForwardDevice,
  NodeType.ForwardNumber,
  NodeType.Fallback,
  NodeType.PlayMessage,
] as const;

export const EdgeType = {
  Basic: 'basic',
  ToTarget: 'toTarget',
  FromSource: 'fromSource',
  TreeOption: 'treeOption',
  PlaceHolder: 'placeHolder',
} as const;

export type EdgeTypes = (typeof EdgeType)[keyof typeof EdgeType];

// This is the type for the custom data added to the nodes passed to the graph. Most of
// the data is returned from the API.
export type DataPayload = {
  /** The ID of the node returned from the API */
  id: string;
  label: string;
  isPhoneTreeDescendent?: boolean;
  parentId?: string; // set on placeholder nodes
  callObject?: CallObject;
};

// Type guards for each expansion type
export function hasCallGroupExpansion(
  obj?: CallObject
): obj is CallObject & { callGroupExpansion: CallGroupExpansion } {
  return obj !== undefined && 'callGroupExpansion' in obj;
}

export function hasCallQueueExpansion(
  obj?: CallObject
): obj is CallObject & { callQueueExpansion: CallQueueExpansion } {
  return obj !== undefined && 'callQueueExpansion' in obj;
}

export function hasDeviceExpansion(obj?: CallObject): obj is CallObject & { deviceExpansion: DeviceExpansion } {
  return obj !== undefined && 'deviceExpansion' in obj;
}

export function hasForwardingNumberExpansion(
  obj?: CallObject
): obj is CallObject & { forwardingNumberExpansion: ForwardingNumberExpansion } {
  return obj !== undefined && 'forwardingNumberExpansion' in obj;
}

export function hasVoicemailExpansion(
  obj?: CallObject
): obj is CallObject & { voicemailExpansion: VoicemailExpansion } {
  return obj !== undefined && 'voicemailExpansion' in obj;
}

export function hasPhoneHoursExpansion(
  obj?: CallObject
): obj is CallObject & { phoneHoursExpansion: PhoneHoursExpansion } {
  return obj !== undefined && 'phoneHoursExpansion' in obj;
}

export function hasPlayMessageExpansion(
  obj?: CallObject
): obj is CallObject & { playMessageExpansion: PlayMessageExpansion } {
  return obj !== undefined && 'playMessageExpansion' in obj;
}

export function hasPhoneTreeExpansion(
  obj?: CallObject
): obj is CallObject & { phoneTreeExpansion: PhoneTreeExpansion } {
  return obj !== undefined && 'phoneTreeExpansion' in obj;
}

// This type is used to define the props that are passed to the nodes in the graph. It is meant
// to be a shared type that can be used for all custom nodes.
export type SharedNodeProps = NodeProps<Node<DataPayload, NodeTypes>> & CustomNodeProps;

export type CustomNodeProps = {
  onClickLink?: (data: { callObjectId: string; nodeId: string; type: NodeTypes }) => void;
  AudioPlayerComponent?: (props: { mediaItemId: string; downloadUrl?: string }) => ReactNode;
  DayHoursListComponent?: (props: { phoneHours: PhoneHoursScheduleRule[] }) => ReactNode;
};

export type SharedEdgeProps = EdgeProps<Edge<CustomEdgeData, EdgeTypes>>;

export type CustomEdgeData = {
  label?: string;
  isExtendable?: boolean;
};
