import { createProvider } from '@frontend/store';
import { FieldStoreState, FieldStore } from './field-store.type';

const INITIAL_STATE: FieldStoreState = {
  isCollapsed: false,
};

export const { Provider: FieldStoreProvider, useStore: useFieldStore } = createProvider<FieldStore>()((set) => ({
  ...INITIAL_STATE,
  toggleCollapse: () => set((state) => ({ isCollapsed: !state.isCollapsed })),
}));
