import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { MessagingOverview } from '../../../types';
import { AppointmentScheduledChart } from './appointments-scheduled-chart';
import { MessagingChartBaseInterface } from './charts-base';
import { MessagesSentChart } from './message-sent-chart';
import { RevenueChart } from './revenue-chart';

interface MessagingChartsProps extends MessagingChartBaseInterface {
  data: MessagingOverview;
  dateRangeHelperText?: string;
}

export const MessagingCharts = ({ data, ...rest }: MessagingChartsProps) => {
  return (
    <div css={styles}>
      <MessagesSentChart {...rest} data={data?.leadsContacted} />
      <AppointmentScheduledChart {...rest} data={data?.patientsScheduled} />
      <RevenueChart {...rest} data={data?.revenueGenerated} />
    </div>
  );
};

const styles = css`
  display: flex;
  gap: ${theme.spacing(3)};
  flex-wrap: wrap;

  @media screen and (min-width: 1330px) {
    flex-wrap: nowrap;
  }

  > section {
    flex-grow: 1;
    flex-basis: 33.33%;
  }
`;
