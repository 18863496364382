import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Plan } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import { debounce } from 'lodash-es';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { IntegrationsQueries } from '@frontend/api-integrations';
import { useTranslation } from '@frontend/i18n';
import { isEmpty } from '@frontend/validation';
import { theme } from '@frontend/theme';
import {
  IconButton,
  Listbox,
  NakedUl,
  PopoverDialog,
  SearchField,
  SpinningLoader,
  SyncIcon,
  Text,
  useFormField,
  useListboxState,
  usePopoverDialog,
} from '@frontend/design-system';

interface Props {
  locationId: string;
  sourceId: string;
  onPlanSelect: (plan: Plan) => void;
}

export const PlanSearchInput = ({ sourceId, locationId, onPlanSelect }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const searchableField = useFormField({ type: 'text' });
  const listboxProps = useListboxState('');
  const {
    getDialogProps,
    getTriggerProps,
    open: openPopover,
    close: closePopover,
  } = usePopoverDialog({ placement: 'bottom-start' });
  const { isInsuranceDetailsWritebackSupported } = IntegrationsQueries.useIsWritebackSupported({
    locationId: locationId,
    sourceId: sourceId,
  });
  const {
    data: insurancePlansResponse,
    isFetching,
    refetch: refetchInsurancePlans,
  } = InsuranceDetailQueries.useGetInsurancePlans(sourceId, locationId, {
    disabled: !isInsuranceDetailsWritebackSupported,
  });

  const [searchList, setSearchList] = useState<Plan[]>([]);

  const searchGroupNameAndGroupNumber = (searchValue: string) => {
    if (searchValue && insurancePlansResponse?.length && searchValue.length > 1) {
      const filteredPayerList = insurancePlansResponse.filter(
        (payerDetails) =>
          payerDetails.gNam?.toLowerCase().includes(searchValue) ||
          payerDetails.gNum?.toLowerCase().includes(searchValue)
      );
      setSearchList(() => filteredPayerList);
    } else {
      setSearchList([]);
    }
  };

  const getFilteredList = useCallback(debounce(searchGroupNameAndGroupNumber, 500), [insurancePlansResponse]);

  useEffect(() => {
    getFilteredList(searchableField.value.toLowerCase());
  }, [searchableField.value]);

  useEffect(() => {
    return () => getFilteredList.cancel();
  }, []);

  const handleSelect = (planId: string | string[]) => {
    const selectedPlanId = typeof planId === 'string' ? planId : planId[0];
    const selectedPlan = insurancePlansResponse?.find((plan) => plan.plID === selectedPlanId);
    if (!selectedPlan || isEmpty(selectedPlan)) {
      return;
    }

    closePopover();
    searchableField.value = '';
    onPlanSelect(selectedPlan);
  };

  if (!isInsuranceDetailsWritebackSupported) {
    return null;
  }

  return (
    <>
      <Text weight='bold' size='large'>
        {t('Search for Patient Insurance Information')}
      </Text>
      <Text size='medium'>
        {t('You may search by entering patients: Employer Name or Group ID in the field below')}
      </Text>
      <div css={searchContainerStyle} ref={getTriggerProps().ref as unknown as React.MutableRefObject<HTMLDivElement>}>
        <SearchField name='search' variant='regular' {...searchableField} onFocus={openPopover} />
        {isFetching ? (
          <SpinningLoader size='medium' />
        ) : (
          <IconButton label={t('Sync Payer/Group Plan')} onClick={refetchInsurancePlans}>
            <SyncIcon />
          </IconButton>
        )}
      </div>
      <PopoverDialog {...getDialogProps()} css={{ borderRadius: theme.borderRadius.medium, maxWidth: 378 }}>
        <NakedUl>
          {searchList.length ? (
            <Listbox {...listboxProps} onSelect={handleSelect}>
              {searchList.map((planDetails, index) => (
                <Listbox.Option key={planDetails.plID ?? '' + index} value={planDetails.plID ?? ''}>
                  {`${planDetails.cNam ? planDetails.cNam + ' |' : '-'} ${
                    planDetails.gNam ? planDetails.gNam + ' |' : '-'
                  } ${planDetails.gNum ?? '-'}`}
                </Listbox.Option>
              ))}
            </Listbox>
          ) : (
            <Text as='li' size='medium' color='light'>
              {t('No group name found. Please create a plan by adding the group name and group number in your PMS')}
            </Text>
          )}
        </NakedUl>
      </PopoverDialog>
    </>
  );
};

const searchContainerStyle = css({
  margin: theme.spacing(1, 0, 2, 0),
  display: 'flex',
  gap: theme.spacing(1),
  '> div:first-of-type': { flexGrow: 1 },
});
