import { useEffect } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';

export const NoPaymentsHookJustifi = () => {
  const {
    anyLocationHasPayments,
    multiStep: { onCancel },
    stripeData: { stripeCreatedNotOnboarded },
    paymentSetupModalControl: { openModal },
    onLocationHasNoPayments,
  } = useCollectPaymentMultiContext();

  useEffect(() => {
    if (!anyLocationHasPayments) {
      if (typeof onLocationHasNoPayments === 'function') {
        onLocationHasNoPayments();
      } else {
        openModal();
      }

      onCancel?.();
    }
  }, [anyLocationHasPayments, stripeCreatedNotOnboarded]);

  return <></>;
};
