import { css } from '@emotion/react';
import { CustomCodeSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextareaField } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../constants';
import { useSettingsForm } from '../../hooks';
import { SettingsPanel } from './atoms';

export const CustomCodeSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<CustomCodeSection>({
    code: { type: 'text' },
  });

  return (
    <SettingsPanel
      title={t('Custom Code')}
      css={css`
        height: 100%;
        @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
          margin-bottom: 0;
        }
        // TODO: need to add a class or something to target in the DS's TextareaField wrapper component
        & > div {
          height: 100%;
        }
      `}
    >
      <TextareaField {...getFieldProps<string>('code')} css={{ height: '100%', padding: theme.spacing(1) }} />
    </SettingsPanel>
  );
};
