import { useMemo } from 'react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { TagsV2, TagsUtils } from '@frontend/api-tag';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { SchemaIO, SchemaTagService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { ComponentProps } from '@frontend/types';
import { UsePopoverDialogProps, UsePopoverDialogResponse, usePopoverDialog } from '@frontend/design-system';
import { TagSelectionPopover, TagSelectionPopoverProps } from '../components';

type Tag = SchemaIO<(typeof SchemaTagService)['ListTags']>['output']['tags'][number];

type UseTagSelectionPopoverArgs = UsePopoverDialogProps &
  Pick<TagSelectionPopoverProps, 'onTagSelect' | 'onTagCreate' | 'searchValue' | 'trackingIds'> & {
    groupIds?: string[];
    tagIdsToExclude?: string[];
  };

type UseTagSelectionPopoverResult = {
  popoverProps: ComponentProps<typeof TagSelectionPopover>;
} & UsePopoverDialogResponse;

/**
 * A hook to manage the tag selection popover.
 * @param onTagSelect - A callback function that is called when a tag is selected.
 * @param onTagCreate (optional) - A callback function that is called when the tag creation option is selected. This should usually be used to navigate to the tag creation flow.
 * The popover will be closed after this function is invoked.
 * @param trackingIds (optional) - trackingIds to be passed to various elements in the tag selection popover.
 * @param groupIds (optional) - A list of business group IDs (AKA location IDs) to filter tags by. If not provided, the selected location IDs will be used.
 * @param searchValue (optional) - The search value to filter tags by. This will also populate the initial name for tag creation flows.
 * @param tagIdsToExclude (optional) - A list of tag ids that are already applied to the current entity. This will be used to filter out tags that are already applied.
 * @param ...rest (optional) - Additional props to pass into the `usePopoverDialog` hook.
 */
export const useTagSelectionPopover = ({
  onTagSelect,
  onTagCreate,
  trackingIds,
  groupIds,
  searchValue,
  tagIdsToExclude,
  ...props
}: UseTagSelectionPopoverArgs): UseTagSelectionPopoverResult => {
  const popover = usePopoverDialog(props);
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const resolvedGroupIds = groupIds ?? selectedLocationIds;
  const { data: tagsData } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: resolvedGroupIds },
    options: {
      select: (data) => {
        return {
          ...data,
          tags: TagsUtils.sortTagsAlphabetically(TagsUtils.filterDisabledTags(data.tags)),
        };
      },
    },
  });
  const { aggregateValue: hasTagACL } = checkAggregateACL(resolvedGroupIds, Permission.TAG_MANAGE, 'any');
  const tagsList = useMemo<Tag[]>(() => {
    if (!tagsData?.tags) return [];
    const unappliedTags = tagsData?.tags.filter((tag) => !tagIdsToExclude?.includes(tag.id));
    if (!searchValue) return unappliedTags;
    const lowercaseSearchValue = searchValue.toLowerCase();
    return unappliedTags.filter((tag) => tag.name.toLowerCase().includes(lowercaseSearchValue)) ?? [];
  }, [searchValue?.toLowerCase(), tagsData?.tags, tagIdsToExclude]);

  return {
    ...popover,
    popoverProps: {
      ...popover.getDialogProps(),
      tagsList,
      onTagSelect,
      onTagCreate,
      closePopover: popover.close,
      trackingIds,
      showTagCreationOption: hasTagACL,
      searchValue,
    },
  };
};
