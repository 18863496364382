import { css } from '@emotion/react';
import {
  DeviceInfo,
  ReplaceContactsRequest,
  ReplaceContactsResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contact-lists/contact_lists.pb';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { UnifyLocationsListView } from '../component';
import type { SelectedContactsType } from '../types';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  contactDirectoryName: string;
  contactDirectoryId: string;
  onSave: (req: ReplaceContactsRequest) => Promise<ReplaceContactsResponse>;
  selectedContacts: SelectedContactsType;
  devices?: DeviceInfo[];
  contactIds: string[];
};
export const RemoveContactFromDirectory = ({
  contactDirectoryName,
  contactDirectoryId,
  devices,
  selectedContacts,
  contactIds,
  onSave,
  ...modalProps
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const label = t('Remove Contacts', { count: selectedContacts.contacts.length });
  const alerts = useAlert();
  const { globalAvailableLocationIds: locationIds } = usePhoneSettingsShallowStore('globalAvailableLocationIds');
  const { contacts } = selectedContacts;
  const selectedContactIds = contacts.map((contact) => contact.id);

  const handleSave = () => {
    const filteredContactIds = contactIds.filter((contactId) => !selectedContactIds?.includes(contactId));

    onSave({
      contactListId: contactDirectoryId,
      contactIds: filteredContactIds,
    }).then(() => {
      alerts.success(t('Successfully removed {{count}} contacts from list', { count: contacts.length }));
    });
  };

  return (
    <CommonModalWrapper {...modalProps} disableCloseOnEscape disableCloseOnOverlayClick>
      <Modal.Header onClose={modalProps.onClose}>{label}</Modal.Header>
      <Modal.Body
        css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), margin: theme.spacing(3, 0) }}
      >
        <Text>
          {t('This contact will be removed from the following devices that that are assigned {{name}}', {
            name: contactDirectoryName,
          })}
        </Text>
        {(locationIds?.length ?? 1) > 1 ? (
          <UnifyLocationsListView devices={devices ?? []} />
        ) : (
          <ul>
            {devices?.map((device) => {
              return (
                <Text
                  as={'li'}
                  key={device.id}
                  css={css`
                    margin: ${theme.spacing(1, 0)};
                  `}
                >
                  {device.name}
                </Text>
              );
            })}
          </ul>
        )}
        {contacts.length === 1 ? (
          <Text>
            <Trans t={t} name={contacts[0].name}>
              Are you sure you want to remove{' '}
              <Text as='span' weight='bold'>
                {{ name: contacts[0].name }}
              </Text>
              ?
            </Trans>
          </Text>
        ) : (
          <Text>
            <Trans t={t} count={contacts?.length}>
              Are you sure you want to remove{' '}
              <Text as='span' weight='bold'>
                {{ count: contacts.length }}
              </Text>{' '}
              contacts?
            </Trans>
          </Text>
        )}
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={handleSave}
        primaryLabel={label}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={modalProps.onClose}
      />
    </CommonModalWrapper>
  );
};
