import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import { FaxQueries, FaxTypes } from '@frontend/api-fax';
import { Chips } from '@frontend/chips';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { IconButton, ListRow, Text, phone } from '@frontend/design-system';
import { FaxInboxListItemLead } from '../inbox/fax-inbox-list-item-lead';

export const ContactListItem = ({ listItem }: { listItem: Contact }) => {
  const { id, locationId } = listItem;
  const navigate = useNavigate();
  const { t } = useTranslation('fax');
  const [media, setMedia] = useState('');
  const contactName = typeof listItem === 'string' ? '' : listItem.name + ' ' + listItem.secondName;
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { getLocationName, selectedLocationIds } = useAppScopeStore();

  const profileMediaId = typeof listItem !== 'string' && listItem?.profileMediaId ? listItem.profileMediaId : '';

  const { data: blob } = FaxQueries.useGetFaxContactMedia(profileMediaId, locationId, {
    enabled: !!listItem && !!profileMediaId,
  });

  useEffect(() => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      setMedia(url);
    } else {
      setMedia('');
    }

    return () => {
      if (media) {
        URL.revokeObjectURL(media);
      }
    };
  }, [blob]);

  return (
    <div>
      <ListRow
        css={actionableListRowStyles}
        as='div'
        tabIndex={0}
        key={id}
        id={id}
        onClick={async () => {
          setShow(true, 'faxContactForm', { contact: listItem, locationId, action: FaxTypes.ContactAction.EDIT });
        }}
        data-trackingid={`${FaxPrefixes.Contacts}-item`}
        isSelected={false}
      >
        <ListRow.Lead css={{ padding: 0 }}>
          <FaxInboxListItemLead
            isBulkSelect={false}
            personName={contactName}
            contactId={listItem.id}
            locationId={locationId}
            isCheckboxSelected={false}
            isInbound={false}
            profileMedia={media}
          />
        </ListRow.Lead>
        <ListRow.Content css={{ display: 'grid', gridTemplateRows: 'repeat(auto-fit, minmax(24px, auto))' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: 0 }}>
            <div
              css={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                minWidth: 0,
              }}
            >
              <ListRow.Content.Title
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {contactName}
              </ListRow.Content.Title>
              <Text color='light'>{phone(listItem.faxNumber, { format: 'hyphenated' })}</Text>
              {selectedLocationIds.length > 1 && (
                <Chips.LocationChip
                  css={{
                    fontSize: theme.fontSize(12),
                  }}
                  className='inbox-item-chip'
                >
                  {getLocationName(locationId)}
                </Chips.LocationChip>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  position: 'absolute',
                  right: theme.spacing(3),
                  width: '60px',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  label={t('More actions')}
                  css={{ ':hover:not(:disabled)': { background: 'none', color: theme.colors.primary50 } }}
                  onClick={(e) => {
                    e?.stopPropagation();
                    navigate({
                      to: '/fax/drafts/new',
                      search: {
                        contactId: id,
                      },
                    });
                  }}
                >
                  <Icon name='fax' css={{ color: theme.colors.neutral70 }} />
                </IconButton>
              </div>
            </div>
          </div>
        </ListRow.Content>
      </ListRow>
    </div>
  );
};

const actionableListRowStyles = css`
  padding: ${theme.spacing(3)};
  padding-left: ${theme.spacing(2.75)};
  grid-template-columns: auto 1fr;
  overflow-x: hidden;

  &:hover .inbox-item-chip {
    background-color: ${theme.colors.white};
  }
`;
