import { AppendInvoiceHistoryRequest } from '@weave/schema-gen-ts/dist/schemas/payments/views/service/service.pb';
import { useQueryClient } from 'react-query';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { getInvoiceActivity, updateInvoiceActivity } from './api';
import { getInvoiceQueryKey } from './query-keys';

const HOURS_IN_MS = 1000 * 60 * 60;

const requestActivityQueryKey = (locationId: string, invoiceId: string) => [
  ...getInvoiceQueryKey(locationId, invoiceId),
  'activity',
];

export const useRequestActivityQuery = ({
  paymentsUrl,
  invoiceId,
  locationId,
}: {
  paymentsUrl: string | null;
  invoiceId: string;
  locationId: string;
}) => {
  const { data: invoiceHistory, isLoading } = useQuery({
    queryKey: requestActivityQueryKey(locationId, invoiceId),
    enabled: !!paymentsUrl && !!invoiceId,
    queryFn: () => getInvoiceActivity(paymentsUrl, invoiceId, locationId),
    cacheTime: 12 * HOURS_IN_MS,
    staleTime: 12 * HOURS_IN_MS,
    select: (data) => data.invoiceHistory,
    refetchOnWindowFocus: 'always',
  });
  return { invoiceHistory, isLoading };
};

export const useRequestActivityInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateInvoiceRequestActivity = (locationId: string, invoiceId: string) => {
    queryClient.invalidateQueries(requestActivityQueryKey(locationId, invoiceId));
  };

  return {
    invalidateInvoiceRequestActivity,
  };
};

export const useAppendRequestActivity = ({
  paymentsUrl,
  invoiceId,
  locationId,
}: {
  paymentsUrl: string | null;
  invoiceId: string;
  locationId: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: requestActivityQueryKey(locationId, invoiceId),
    mutationFn: (data: AppendInvoiceHistoryRequest) => updateInvoiceActivity(paymentsUrl, invoiceId, locationId, data),
    onSettled: () => queryClient.invalidateQueries(requestActivityQueryKey(locationId, invoiceId)),
  });
};
