import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ValueType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/mapping.pb';
import { CreatePractitionerRequest, Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  FormRow,
  TextareaField,
  useForm,
  Text,
  SpinningLoader,
  ButtonBar,
  useAlert,
  Stepper,
  Button,
  styles,
  TextField,
} from '@frontend/design-system';
import { useAppointmentTypeCheckListDropdown } from '../../../components/AppointmentTypeCheckListDropdown';
import { ManagePractitionerProfilePhotoContext } from '../../../context/ManagePractitionerProfilePhotoContext';
import { useGetIntegrationDetails, useSchedulingLocationInfo } from '../../../hooks';
import { getFullName } from '../../../utils';
import { PractitionerPhotoContainer } from './PractitionerPhoto';

type PractitionerDetailsV3Props = {
  locationId: string;
  practitionerId?: string;
  practitionerDetails: Practitioner;
  primaryButtonLabel?: string;
  isLoading?: boolean;
  isStepperComponent?: boolean;
  onSave?: () => void;
  onErrors?: (err: string) => void;
  setPractitionerDetailsOnCreate?: (practitionerDetails: Practitioner) => void;
};

export const PractitionerDetailsV3 = ({
  locationId,
  practitionerId,
  practitionerDetails,
  onSave,
  onErrors,
  primaryButtonLabel,
  isLoading,
  isStepperComponent = false,
  setPractitionerDetailsOnCreate,
}: PractitionerDetailsV3Props) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const { selectedLocationIds } = useSchedulingLocationInfo();
  const selectedLocationId = selectedLocationIds.length === 1 ? selectedLocationIds[0] : locationId || '';

  const [profilePhotoSrc, setProfilePhotoSrc] = useState<string>('');

  const { isIntegratedOffice, isLoading: isLoadingIntegrationDetails } = useGetIntegrationDetails({
    selectedLocationId: locationId,
  });

  const updateProviderMutation = SchedulerV3.Mutations.useUpdateProviderMutation();
  const createProviderMutation = SchedulerV3.Mutations.useCreateProviderMutation();
  const { createAndDeleteMappingsAsync, isLoading: isUpdatingMappings } =
    SchedulerV3.Mutations.useCreateAndDeleteMappings();

  const { formProps, getFieldProps, values, seedValues, isComplete } = useForm({
    fields: {
      firstName: { type: 'text', required: true, value: practitionerDetails?.firstName },
      lastName: { type: 'text', required: true, value: practitionerDetails?.lastName },
      displayName: { type: 'text', required: true, value: practitionerDetails?.displayName },
      bio: { type: 'text', required: false, value: practitionerDetails?.profile?.bio },
    },
  });

  const {
    AppointmentTypeCheckListDropdownElement,
    getAppointmentTypeSelectedUnselectedValues,
    hasAppointmentTypeCheckListDropdownError,
  } = useAppointmentTypeCheckListDropdown({
    locationId: selectedLocationId,
    providerId: practitionerId ?? '',
  });

  // Method to get payload for update practitioner
  const getUpdatePractitionerPayload = (profilePhotoUrl?: string): SchedulerV3.Types.UpdateProviderIO['input'] => {
    return {
      id: practitionerId || '',
      firstName: values.firstName || '',
      lastName: values.lastName || '',
      displayName: values.displayName || '',
      hidden: practitionerDetails?.hidden,
      profile: {
        ...practitionerDetails?.profile,
        bio: values?.bio,
        picUrl: profilePhotoUrl ?? profilePhotoSrc,
      },
    };
  };

  // Method to get payload for create practitioner
  const getCreatePractitionerPayload = (): CreatePractitionerRequest => {
    return {
      locationId: selectedLocationId,
      sourceTenantId: selectedLocationId || '',
      firstName: values.firstName || '',
      lastName: values.lastName || '',
      displayName: values.displayName || '',
      hidden: false,
      profile: {
        act: true,
        bio: values?.bio,
        picUrl: profilePhotoSrc,
      },
    };
  };

  // Method to handle update practitioner
  const handleUpdatePractitioner = (profilePhotoUrl?: string) => {
    const changedAppointmentTypes = getAppointmentTypeSelectedUnselectedValues();

    const payload = getUpdatePractitionerPayload(profilePhotoUrl);

    const updatePractitionerMethod = () => updateProviderMutation.mutateAsync(payload);

    const updatePractitionerAppointmentTypesMapping = () =>
      createAndDeleteMappingsAsync({
        locationId: selectedLocationId,
        providerId: practitionerId ?? '',
        values: changedAppointmentTypes.newSelectedAppointmentTypeIds,
        valueType: ValueType.VT_APPOINTMENT_TYPE,
        mappingIdsForDelete: changedAppointmentTypes.mappingIdsForDelete,
      });

    return Promise.all([updatePractitionerMethod(), updatePractitionerAppointmentTypesMapping()])
      .then(() => {
        alert.success(t('Practitioner details updated successfully'));
        onSave?.();
      })
      .catch((err) => {
        alert.error(t('Failed to update Practitioner'));
        onErrors?.(err);
      });
  };

  const handlePostSavePractitioner = (practitioner: Practitioner) => {
    alert.success(t('Practitioner created successfully'));
    setPractitionerDetailsOnCreate?.(practitioner);
    onSave?.();
  };

  // Method to handle create practitioner
  const handleCreatePractitioner = async () => {
    const changedAppointmentTypes = getAppointmentTypeSelectedUnselectedValues();
    const payload = getCreatePractitionerPayload();

    createProviderMutation
      .mutateAsync(payload)
      .then((res) => {
        createAndDeleteMappingsAsync({
          locationId: selectedLocationId,
          providerId: res.provider.id,
          values: changedAppointmentTypes.newSelectedAppointmentTypeIds,
          valueType: ValueType.VT_APPOINTMENT_TYPE,
        })
          .then(() => {
            handlePostSavePractitioner(res.provider);
          })
          .catch((err) => {
            alert.error(t('Failed to create practitioner'));
            onErrors?.(err);
          });
      })
      .catch((err) => {
        alert.error(t('Failed to create practitioner'));
        onErrors?.(err);
      });
  };

  const handleSave = () => {
    if (practitionerId) {
      handleUpdatePractitioner();
    } else {
      handleCreatePractitioner();
    }
  };

  // Seed values on practitioner details change
  useEffect(() => {
    seedValues({
      firstName: practitionerDetails?.firstName || '',
      lastName: practitionerDetails?.lastName || '',
      displayName: practitionerDetails?.displayName || '',
      bio: practitionerDetails?.profile?.bio ?? '',
    });
  }, [practitionerDetails]);

  // Seed profile photo on practitioner details change
  useEffect(() => {
    if (practitionerDetails?.profile?.picUrl) {
      setProfilePhotoSrc(practitionerDetails?.profile?.picUrl);
    }
  }, [practitionerDetails?.profile?.picUrl]);

  const managePractitionerProfilePhotoProviderValue = useMemo(() => {
    return {
      practitionerDetails,
      profilePhotoSrc,
      setProfilePhotoSrc,
      updatePractitionerDetails: handleUpdatePractitioner,
      name: getFullName(values) || getFullName(practitionerDetails),
    };
  }, [practitionerDetails, profilePhotoSrc, practitionerId, values]);

  const isSaving = createProviderMutation.isLoading || updateProviderMutation.isLoading || isUpdatingMappings;

  if (isLoading || isLoadingIntegrationDetails) {
    return (
      <div css={styles.flexCenter}>
        <SpinningLoader size='small' />
      </div>
    );
  }

  return (
    <>
      <form {...formProps}>
        <ManagePractitionerProfilePhotoContext.Provider value={managePractitionerProfilePhotoProviderValue}>
          <PractitionerPhotoContainer />
        </ManagePractitionerProfilePhotoContext.Provider>
        <FormRow cols={[100, 100]} css={practitionerTextFieldContainerStyles(isSmall)}>
          <TextField
            {...getFieldProps('firstName')}
            label={t('First Name')}
            disabled={isIntegratedOffice}
            helperText={
              isIntegratedOffice ? t("The provider's first name as it appears in your Practice Management System.") : ''
            }
          />
          <TextField
            {...getFieldProps('lastName')}
            label={t('Last Name')}
            disabled={isIntegratedOffice}
            helperText={
              isIntegratedOffice ? t("The provider's last name as it appears in your Practice Management System.") : ''
            }
          />
        </FormRow>
        <FormRow cols={[100]} css={practitionerTextFieldContainerStyles(isSmall)}>
          <TextField
            {...getFieldProps('displayName')}
            label={t('Display Name')}
            helperText={t('This name will be visible to customers when booking online, and on your Weave calendar')}
          />
        </FormRow>
        {AppointmentTypeCheckListDropdownElement}
        <FormRow cols={[100]} css={textAreaFieldContainerStyles}>
          <TextareaField label={t('Provider Biography')} {...getFieldProps('bio')} />
        </FormRow>
        <Text size='small' color='subdued'>
          {t(
            "Enter a brief description of the provider's background & expertise. Patients will see this when booking appointments online."
          )}
        </Text>
      </form>
      {!isStepperComponent ? (
        <ButtonBar>
          <Button
            disabled={!isComplete || hasAppointmentTypeCheckListDropdownError}
            loading={isSaving}
            onClick={handleSave}
            size='large'
            type='button'
          >
            {primaryButtonLabel}
          </Button>
        </ButtonBar>
      ) : (
        <Stepper.ButtonBar>
          <Stepper.NextButton isValid={isComplete && !hasAppointmentTypeCheckListDropdownError} onClick={handleSave}>
            {primaryButtonLabel}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      )}
    </>
  );
};

const textAreaFieldContainerStyles = css({
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  flexDirection: 'column',
});

const practitionerTextFieldContainerStyles = (isSmall: boolean) =>
  css({
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
  });
