import { FC } from 'react';
import { css } from '@emotion/react';
import { DashboardWidget, DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedButton, Text } from '@frontend/design-system';

/**
 * @dashboard-widget
 *
 * id: schedule-setup
 * title: Schedule Setup
 * description: Redirect user to Schedule setup page if not done already.
 * icon: schedule-pulse-small
 */
export const ScheduleSetupWidget: DashboardWidgetFC<{ onSkip?: () => void }> = ({ onSkip }) => {
  const { t } = useTranslation('schedule');

  const { navigate } = useSettingsNavigate();

  const redirectToSetup = () => {
    navigate({ to: '/schedule' });
  };

  const handleSkip = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSkip?.();
  };

  // TODO: Milind add condition for setup complete when api is ready
  const isSetupComplete = false;

  return (
    <DashboardWidget
      onClick={redirectToSetup}
      data-trackingid='wiv-setup-widget-skip'
      css={[isSetupComplete && setupCompleteContainerStyles]}
    >
      <DashboardWidget.Content css={[contentContainerStyles]}>
        <ContentLoader show={false} />
        {isSetupComplete ? (
          <SetupCompleteIcon />
        ) : (
          <Icon name='in-progress-small' size={16} css={{ color: theme.colors.neutral20 }} />
        )}
        <Text size='large' css={{ flex: 1 }} color={isSetupComplete ? 'subdued' : 'default'}>
          {t('Set Up Online Scheduling')}
        </Text>
        {!!onSkip && !isSetupComplete && (
          <NakedButton trackingId='os-setup-widget-skip' onClick={handleSkip}>
            <Text size='large' color='subdued'>
              {t('Skip')}
            </Text>
          </NakedButton>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

const SetupCompleteIcon: FC = () => {
  return (
    <div css={roundIconContainerStyles}>
      <Icon name='check-small' css={{ color: theme.colors.success50 }} />
    </div>
  );
};

ScheduleSetupWidget.config = {
  size: 'micro-narrow',
  feature: 'schedule',
};

const contentContainerStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  paddingTop: '6px',
  margin: 'auto 0',
});

const setupCompleteContainerStyles = css({
  backgroundColor: theme.colors.neutral5,
});

const roundIconContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.colors.white,
  borderRadius: theme.borderRadius.full,
  padding: theme.spacing(0.5),
  width: 24,
  height: 24,
});
