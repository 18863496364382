import { StripeError } from '@stripe/stripe-js';
import { i18next } from '@frontend/i18n';

const t = (message: string) => i18next.t(message, { ns: 'payments' });

/**
 * @deprecated collect-flow/* will be removed once collect-flow-multi/ is fully live.
 */
export const showConfirmPaymentErrors = (error: StripeError, showAlert: (message: string) => void) => {
  if (error.decline_code) {
    switch (error.decline_code) {
      case 'generic_decline':
        showAlert(t('The card declined. Please use a different card.'));
        break;
      case 'insufficient_funds':
        showAlert(t('The card has insufficient funds. Please use a different card.'));
        break;
      case 'lost_card':
        showAlert(t('The card returned as lost. Please use a different card.'));
        break;
      case 'stolen_card':
        showAlert(t('The card returned a stolen. Please use a different card.'));
        break;
      case 'fraudulent':
        showAlert(t('The card returned as possibly fraudulent. Please use a different card.'));
        break;
      default:
        showAlert(t(error.message || 'The card declined for an unknown reason. Please use a different card.'));
        break;
    }
  } else if (error.code) {
    switch (error.code) {
      case 'expired_card':
        showAlert(t('The card is expired. Please use a different card.'));
        break;
      case 'incorrect_cvc':
        showAlert(t('The CVC is incorrect. Please update and try again.'));
        break;
      case 'processing_error':
        showAlert(t('The card failed to process. Please review the information and try again.'));
        break;
      case 'incorrect_number':
        showAlert(t('The card number is incorrect. Please update and try again.'));
        break;
      default:
        showAlert(t(error.message || 'The card declined for an unknown reason. Please use a different card.'));
        break;
    }
  }
};

/**
 * @deprecated collect-flow/* will be removed once collect-flow-multi/ is fully live.
 */
export const showConfirmCardPaymentErrors = (error: StripeError, showAlert: (message: string) => void) => {
  if (!error) return;
  if (error?.type === 'card_error')
    showAlert(
      error.message || t('Error processing the selected card. Please check the payment details and try again.')
    );
  else showAlert(t('Error submitting payment. Please try again.'));
};
