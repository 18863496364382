import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, emptyStateGraphics } from '@frontend/design-system';

type Props = {
  imageSize?: number | 'auto';
};

export const EmptyListIllustration = ({ imageSize = 'auto' }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const EmptyStateGraphic = emptyStateGraphics.quick_fill;

  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, overflowY: 'auto' }}>
      <EmptyStateGraphic height={imageSize} width={imageSize} />
      <Text size='medium' color='subdued' css={{ textAlign: 'center', width: 260, paddingBottom: theme.spacing(2) }}>
        {t('Start building your Quick Fill list for unexpected schedule gaps.')}
      </Text>
    </div>
  );
};
