import { Edge, Node } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

interface CallRouteGraphsStore {
  // The nodes for the graphs pertaining to call routes. These should be the latest nodes data retrieved from the API.
  // Stored in an object with call route id as the key.
  allNodes: Record<string, Node[]>;
  // The edges for the graphs pertaining to call routes. These should be the latest edges data retrieved from the API.
  // Stored in an object with call route id as the key.
  allEdges: Record<string, Edge[]>;
  // The nodes in a call graph that are currently being edited. These are not saved to the API until the user saves the changes.
  // Stored in an object with call route id as the key.
  allTempEditingNodes: Record<string, Node[] | undefined>;
  // The edges in a call graph that are currently being edited. These are not saved to the API until the user saves the changes.
  // Stored in an object with call route id as the key.
  allTempEditingEdges: Record<string, Edge[] | undefined>;
  setNodesByCallRouteId: (callRouteId: string, nodes: Node[]) => void;
  setEdgesByCallRouteId: (callRouteId: string, edges: Edge[]) => void;
  setTempEditingNodesByCallRouteId: (callRouteId: string, nodes: Node[] | undefined) => void;
  setTempEditingEdgesByCallRouteId: (callRouteId: string, edges: Edge[] | undefined) => void;
  getNodesByCallRouteId: (callRouteId: string) => Node[] | undefined;
  getEdgesByCallRouteId: (callRouteId: string) => Edge[] | undefined;
  getTempEditingNodesByCallRouteId: (callRouteId: string) => Node[] | undefined;
  getTempEditingEdgesByCallRouteId: (callRouteId: string) => Edge[] | undefined;
}

export const useCallRouteGraphsStore = createStoreWithSubscribe<CallRouteGraphsStore>(
  (set, get) => ({
    allNodes: {},
    allEdges: {},
    allTempEditingNodes: {},
    allTempEditingEdges: {},
    setNodesByCallRouteId: (callRouteId: string, nodes: Node[]) => {
      set(
        (state) => {
          state.allNodes[callRouteId] = nodes;
        },
        false,
        'SET_CALL_ROUTE_CANVAS_NODES'
      );
    },
    setEdgesByCallRouteId: (callRouteId: string, edges: Edge[]) => {
      set(
        (state) => {
          state.allEdges[callRouteId] = edges;
        },
        false,
        'SET_CALL_ROUTE_CANVAS_EDGES'
      );
    },
    setTempEditingNodesByCallRouteId: (callRouteId: string, nodes: Node[] | undefined) => {
      set(
        (state) => {
          state.allTempEditingNodes[callRouteId] = nodes;
        },
        false,
        'SET_CALL_ROUTE_CANVAS_TEMP_EDITING_NODES'
      );
    },
    setTempEditingEdgesByCallRouteId: (callRouteId: string, edges: Edge[] | undefined) => {
      set(
        (state) => {
          state.allTempEditingEdges[callRouteId] = edges;
        },
        false,
        'SET_CALL_ROUTE_CANVAS_TEMP_EDITING_EDGES'
      );
    },
    getNodesByCallRouteId: (callRouteId: string) => get().allNodes[callRouteId],
    getEdgesByCallRouteId: (callRouteId: string) => get().allEdges[callRouteId],
    getTempEditingNodesByCallRouteId: (callRouteId: string) => get().allTempEditingNodes[callRouteId],
    getTempEditingEdgesByCallRouteId: (callRouteId: string) => get().allTempEditingEdges[callRouteId],
  }),
  { name: 'CallRouteCanvasStorage', trace: true }
);

export const useCallRouteGraphsShallowStore = createShallowStore(useCallRouteGraphsStore);
