import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useRouter, Link } from '@tanstack/react-location';
import { NewBadge } from '@frontend/components';
import { useMediaMatches } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { CaretDownIcon, ExternalLinkIcon } from '@frontend/design-system';
import { useNavTrigger } from './global-nav';

export const linkStyle = (isSelected: boolean) => [
  css`
    align-items: center;
    color: ${theme.colors.neutral90};
    display: flex;
    background: ${isSelected ? theme.colors.neutral20 : 'none'};
    height: 40px;
    font-weight: ${isSelected ? theme.font.weight.semibold : 'normal'};
    justify-content: space-between;
    padding: ${theme.spacing(2)};
    text-decoration: none;
    text-transform: capitalize;
    transition: background 250ms ease-in-out;
    width: 100%;

    .link-content-wrapper {
      align-items: center;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .link-content-wrapper > svg {
      fill: ${theme.colors.neutral90};
      margin-right: ${theme.spacing(2)};
    }

    :hover {
      background: ${theme.colors.neutral10};
    }
  `,
];

export const NavLink = ({
  children,
  to,
  caretIcon = false,
  isExternalLink = false,
  isNew = false,
  statusIcon,
  count,
  onClick,
  ...rest
}: {
  children: ReactNode;
  to: string;
  caretIcon?: boolean;
  isExternalLink?: boolean;
  isNew?: boolean;
  statusIcon?: ReactNode;
  count?: number;
  onClick?: () => void;
}) => {
  const { state } = useRouter();
  const currentPathName = state.location.pathname;
  const isSelected = currentPathName.includes(to);
  const { matches } = useMediaMatches();
  const navIsCollapsible = !matches.useSmallMax();
  const { setOpen } = useNavTrigger();

  return isExternalLink ? (
    <a css={linkStyle(isSelected)} href={to} rel='noreferrer' target='_blank'>
      <span className='link-content-wrapper'>{children}</span>
      <ExternalLinkIcon />
    </a>
  ) : (
    <li className='sub-item'>
      <Link
        to={to}
        onClick={(event) => {
          // if the nav is collapsible, proactively collapse it once user navigates to a new page
          if (navIsCollapsible) setOpen(false);
          if (onClick) {
            event.preventDefault();
            onClick();
          }
        }}
        css={[linkStyle(isSelected)]}
        {...rest}
      >
        <span className='link-content-wrapper'>{children}</span>
        {!!count && (
          <span
            css={{
              background: theme.colors.primary50,
              color: theme.colors.white,
              padding: 4,
              borderRadius: theme.borderRadius.full,
              fontSize: theme.fontSize(12),
            }}
          >
            {count > 99 ? '99+' : count}
          </span>
        )}
        {statusIcon}
        {isNew && <NewBadge background='light' />}
        {caretIcon && (
          <CaretDownIcon
            css={css`
              margin: 0px;
              padding: 0px;
              transform: ${isSelected ? 'rotate(180deg)' : 'none'};
              transition: transform 250ms ease-in-out;
            `}
          />
        )}
      </Link>
    </li>
  );
};
