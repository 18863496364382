import { ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { AnimateNumber } from '@frontend/anim-components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { BannerNotification, ContentLoader, NakedButton, Text, TextLink } from '@frontend/design-system';
import { MONTH_INFO } from '../constants';
import { prepareVerificationReportData } from '../helpers';
import { textLinkStyle } from '../styles';

export const VerificationReportCardWrapper = ({ children }: { children: ReactNode }) => {
  return <div css={cardListContainerStyle}>{children}</div>;
};

interface VerificationReportCardItemProps extends CommonHTMLAttributes {
  label: string;
  value: number;
  valueColor?: string;
}

export const VerificationReportCardItem = ({ label, value, valueColor, ...props }: VerificationReportCardItemProps) => {
  return (
    <div css={cardItemContainerStyle} {...props}>
      <Text weight='bold' css={{ fontSize: theme.fontSize(36), color: valueColor }} textAlign='center'>
        <AnimateNumber to={value} />
      </Text>
      <Text color='light' textAlign='center'>
        {label}
      </Text>
    </div>
  );
};

interface Props {
  locationId: string;
  onLearnMoreClick: () => void;
}

export const VerificationReportCardContainer = ({ locationId, onLearnMoreClick }: Props) => {
  const { t } = useTranslation('insuranceDetails');
  const {
    data: insuranceVerificationMetricsData,
    isError,
    isLoading,
    refetch,
  } = InsuranceDetailQueries.useGetInsuranceVerificationMetrics(MONTH_INFO.startDate, MONTH_INFO.endDate, locationId);
  const { totalCount, hoursSaved } = useMemo(
    () => prepareVerificationReportData(insuranceVerificationMetricsData),
    [insuranceVerificationMetricsData]
  );

  const hasVerificationReportError = (isError || !insuranceVerificationMetricsData) && !isLoading;
  if (hasVerificationReportError) {
    return (
      <BannerNotification
        css={{ gridArea: '1 / 1 / 2 / 5' }}
        status='error'
        message={
          <>
            <Text size='medium'>{t('Failed to load verification report data.')}</Text>
            <TextLink size='medium' css={textLinkStyle} onClick={() => refetch()}>
              {t('Try Again')}
            </TextLink>
          </>
        }
      />
    );
  }

  return (
    <VerificationReportCardWrapper>
      <ContentLoader show={isLoading} size='medium' />
      <VerificationReportCardItem
        label={t('{{month}} Verifications', { month: MONTH_INFO.monthName })}
        value={totalCount}
        css={{ gridArea: '1 / 1 / 2 / 3' }}
      />
      <VerificationReportCardItem
        label={t('Estimated Hours Saved')}
        value={hoursSaved}
        valueColor={theme.colors.secondary.seaweed40}
        css={{ gridArea: '1 / 3 / 2 / 5' }}
      />
      <NakedButton css={[textLinkStyle, learnMoreButtonStyle]} onClick={onLearnMoreClick}>
        <Icon name='analytics-small' css={{ color: 'currentColor' }} />
        {t('Learn More')}
      </NakedButton>
    </VerificationReportCardWrapper>
  );
};

const learnMoreButtonStyle = css({
  justifyContent: 'center',
  gridArea: '2 / 2 / 4 / 4',
  margin: theme.spacing(2, 0, 1, 0),
});

const cardItemContainerStyle = css({
  background: theme.colors.white,
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.light,
});

const cardListContainerStyle = css({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(3, 3, 2, 3),
  background: theme.colors.neutral10,
  borderRadius: theme.borderRadius.medium,
  '> div': {
    height: '100%',
  },
});
