import { useCallback } from 'react';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import type { PersonTypes } from '@frontend/api-person';
import { ContactDetails } from '../../contact-details/contact-details';
import { CustomerDetailsProps } from '../customer-details';

dayjs.extend(utc);
dayjs.extend(minMax);

interface PreparedAppointments {
  due: PreparedAppointment;
  last: PreparedAppointment;
  next: PreparedAppointment;
}

interface PreparedAppointment {
  data: PersonTypes.Appointment | string | null;
  label: string | Date;
}

export const AppointmentInformation = ({ data }: CustomerDetailsProps) => {
  const person = data;

  const findAppointment = (searchParam: string): PersonTypes.Appointment | null => {
    const today = dayjs();
    const appointments = person?.Appointments;
    const searchLast = searchParam === 'last';
    const filteredAppointments = appointments?.filter((appointment) => {
      const appointmentDate = dayjs(appointment.AppointmentDate);
      return searchLast ? appointmentDate < today : appointmentDate > today;
    });

    if (filteredAppointments?.length) {
      const appointmentIndex = searchLast ? 0 : filteredAppointments.length - 1;
      return filteredAppointments[appointmentIndex];
    }

    return null;
  };

  const prepareAppointments = useCallback((): PreparedAppointments => {
    const appointmentExists = !!person?.Appointments;
    const lastAppointment = appointmentExists ? findAppointment('last') : null;
    const nextAppointment = appointmentExists ? findAppointment('next') : null;
    const recalls = person?.Recall ?? [];
    const dueDate = recalls.map((recall) => {
      return dayjs.utc(recall.EventTime);
    });
    const dueAppointment = recalls.length ? dayjs.min(dueDate).format('MMMM DD, YYYY') : null;

    return {
      due: {
        data: dueAppointment,
        label: dueAppointment ? dueAppointment : 'None',
      },
      last: {
        data: lastAppointment,
        label: lastAppointment ? lastAppointment.AppointmentDate : 'None',
      },
      next: {
        data: nextAppointment,
        label: nextAppointment ? nextAppointment.AppointmentDate : 'None',
      },
    };
  }, [person]);

  const { last, next, due } = prepareAppointments();

  if (!person) return null;

  return (
    <ContactDetails.Section
      title='Appointment Status'
      contactDetails={[
        {
          title: 'Last appointment',
          value: last.data ? dayjs(last.label).format('MMMM D, YYYY') : '-',
        },
        {
          title: 'Due',
          value: due.data ? dayjs(due.label).format('MMMM D, YYYY') : '-',
        },
        {
          title: 'Next appointment',
          value: next.data ? dayjs(next.label).format('MMMM D, YYYY') : '-',
        },
      ]}
    />
  );
};
