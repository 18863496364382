import { useCallback, useState } from 'react';
import { useActiveConversationSelector } from '../../providers/active-conversation.provider';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import type { Target } from '../../utils';
import { ConversationBody } from './conversation-body';
import { ConversationMemberSelection } from './conversation-member-selection/conversation-member-selection';
import { ConversationTitle } from './conversation-title';

export const ConversationPanel = () => {
  const mainPanelView = useTeamChatSelector((ctx) => ctx.mainPanelView);
  const messages = useActiveConversationSelector((ctx) => ctx.messages);
  const fetchNextPage = useActiveConversationSelector((ctx) => ctx.fetchNextPage);
  const [selectedTarget, setSelectedTarget] = useState<Target>();
  const onTargetChange = useCallback((target: Target | undefined) => {
    setSelectedTarget(target);
  }, []);

  return (
    <>
      <ConversationTitle />
      {mainPanelView === 'conversation-dynamic' && (
        <ConversationMemberSelection selectedTarget={selectedTarget} onChange={onTargetChange} />
      )}
      <ConversationBody selectedTarget={selectedTarget} messages={messages} fetchNextPage={fetchNextPage} />
    </>
  );
};
