import { ProvidersExceptionsForMultipleProvidersOfMultiLocations } from './types';

export const queryKeys = {
  base: ['schedule'] as const,
  providerExceptions: (req: ProvidersExceptionsForMultipleProvidersOfMultiLocations['input']) => [
    ...queryKeys.base,
    'providerExceptions',
    req,
  ],
};
