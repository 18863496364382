import { css } from '@emotion/react';

export const containerStyle = css`
  max-width: auto;
  flex: 1;

  > * {
    max-width: 1000px;
  }
`;
