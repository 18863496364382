import { useQueryClient } from 'react-query';
import { PersonAPI } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  Heading,
  IconButton,
  PrimaryButton,
  XIcon,
  Text,
  useFormField,
  BannerNotification,
} from '@frontend/design-system';
import { modalHeaderStyle, modalStyles } from '../style';
import { ContactFormContext } from './types';

type LanguageAPIOption = 'Spanish' | 'English';

const sanitizeLanguage = (language?: string): LanguageAPIOption | null => {
  if (!language || language === 'No Preference') return null;
  if (language === ('English' satisfies LanguageAPIOption)) return 'English';
  if (language === ('Spanish' satisfies LanguageAPIOption)) return 'Spanish';
  return null;
};

export const ContactForm = () => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const defaultLocationId = selectedLocationIds[0];

  const { t } = useTranslation('contacts');
  const languageOptionsMap = {
    English: t('English'),
    Spanish: t('Spanish'),
    'No Preference': t('No Preference'),
  } as const;

  const { setShow, context } = useSlidePanelShallowStore<ContactFormContext>('setShow', 'context');
  const { person, locationId: contextLocationId } = context ?? {};
  const resolvedLocationId = contextLocationId || defaultLocationId;

  const languageField = useFormField({
    type: 'dropdown',
    value: person?.Preferences?.Language ?? languageOptionsMap['No Preference'],
  });

  const mutatePersonPreferences = useMutation({
    mutationFn: ({ personId, language }: { personId: string; language: LanguageAPIOption | null }) =>
      PersonAPI.updatePersonPreferences(
        personId,
        {
          Language: language,
        },
        contextLocationId
      ),
    onSuccess: (_res, req) => {
      queryClient.invalidateQueries({ queryKey: [resolvedLocationId, req.personId] });

      setShow(false);
    },
  });

  return (
    <div css={modalStyles}>
      <div css={modalHeaderStyle}>
        <Heading level={2}>{t('Update Contact')}</Heading>
        <IconButton label='Close' className='close-btn' onClick={() => setShow(false)}>
          <XIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          padding: theme.spacing(2),
        }}
      >
        <BannerNotification status='info'>
          {t(
            `This contact was pulled in from your practice management system. You can only update your contact's language preference here.`
          )}
        </BannerNotification>
        <section style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <Text size='medium'>{t('General Information')}</Text>
          <DropdownField name='language' {...languageField} label={t('Select Language Preference')}>
            {Object.entries(languageOptionsMap).map(([value, translation]) => (
              <DropdownField.Option key={value} value={value} trackingId='contact-2.0-tray-tab-history-langprefs'>
                {translation}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </section>

        <PrimaryButton
          css={{ width: 'max-content' }}
          onClick={() => {
            if (person?.PersonID) {
              mutatePersonPreferences.mutate({
                personId: person?.PersonID,
                language: sanitizeLanguage(languageField.value),
              });
            }
          }}
        >
          {t('Save')}
        </PrimaryButton>
      </div>
    </div>
  );
};
