import { isEmpty } from 'lodash-es';
import { useMutation } from 'react-query';
import { CreditCardBrand } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { downloadCSV } from '@frontend/media-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useScopedInfiniteQuery } from '@frontend/scope';
import { formatDate, useAlert } from '@frontend/design-system';
import { formatCentsToCurrency, GetLocationName, getPaymentMethod } from '../utils';
import { makePersonName } from '../utils/failed-transactions-helpers';
import {
  getFailedPayments,
  SearchFailedPaymentsModel,
  SearchFailedPaymentsParams,
  SearchFailedPaymentsResponse,
  usePaginatedFailedInvoicesQueryData,
} from './payment-failed';
import {
  calculateFailedTransactionsSummary,
  getFailedTransactionsOriginLabel,
  getFailedTransactionsQueryKey,
  mapFailedPaymentsTypeToPaymentType,
} from './payment-failed/utils';

type UseQueryPaginatedFailedInvoicesProps = {
  currentPage: number;
  onChangePage: (currentPage: number) => void;
  onResetPage: () => void;
  searchParams: SearchFailedPaymentsParams;
};
export const useQueryPaginatedFailedInvoices = ({
  currentPage,
  searchParams,
  onChangePage,
  onResetPage,
}: UseQueryPaginatedFailedInvoicesProps) => {
  const { paymentsUrl } = useMerchant();

  const { locationIds } = useMultiQueryUtils({ onLocationChange: () => onResetPage() });

  const queryKey = getFailedTransactionsQueryKey(searchParams);

  const { data, refetch, fetchNextPage, isLoading, isFetching } = useScopedInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: string | null }) => {
      return getFailedPayments({
        paymentsUrl,
        locationIds,
        params: { ...searchParams, after: pageParam ?? undefined },
      });
    },
    getNextPageParam: (lastPage) => lastPage.lastEdgeCursor,

    staleTime: 60 * 1000,
    cacheTime: 2 * 60 * 1000,
    retry: 3,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: !!paymentsUrl,
  });

  const loading = isLoading || isFetching;
  return {
    loading,
    transactions: !loading && data?.pages ? data.pages[currentPage - 1]?.payments ?? [] : [], // The backend returns a `null` if there are no items, instead of `[]`
    hasMore: !loading && data?.pages ? data.pages[currentPage - 1]?.meta?.hasNextPage : false,
    hasPrevious: !loading && data?.pages ? data.pages[currentPage - 1]?.meta?.hasPreviousPage : false,
    invoicesQueryKey: queryKey,
    numRows: searchParams.first || (!loading && data?.pages ? data.pages[currentPage - 1]?.totalCount || 0 : 0),
    currentPage,
    refetch: () => refetch(), // Just to keep API consistent with saga hook
    fetchNextPage: () => {
      if (data?.pages && data?.pages.length === currentPage) {
        fetchNextPage();
      }
      onChangePage(currentPage + 1);
    },
    fetchPreviousPage: () => {
      onChangePage(Math.max(1, currentPage - 1));
    },
  };
};

export type UseQueryAllFailedInvoicesProps = {
  searchParams: SearchFailedPaymentsParams;
  currentPage?: number;
  numRows?: number;
  enable?: boolean;
};
export const useQueryAllFailedInvoices = ({
  searchParams,
  numRows = 10,
  currentPage = 1,
  enable = true,
}: UseQueryAllFailedInvoicesProps) => {
  const { paymentsUrl } = useMerchant();

  const { getMultiQueryKey, locationIds } = useMultiQueryUtils();
  const queryKey = getMultiQueryKey(['all-failed-invoices', locationIds, searchParams]);

  const hasDateRangeFilters =
    !!searchParams?.submittedAt?.greaterThanEqual || !!searchParams?.submittedAt?.lessThanEqual;
  const NUM_ROWS = hasDateRangeFilters ? 500 : numRows;

  const { previousPageEndCursor } = usePaginatedFailedInvoicesQueryData({
    searchParams,
    currentPage,
  });

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading, isFetching } = useScopedInfiniteQuery({
    enabled: !!paymentsUrl && enable,
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: string | null }) =>
      getFailedPayments({
        paymentsUrl,
        locationIds,
        params: {
          ...searchParams,
          first: NUM_ROWS,
          after: pageParam ?? (!hasDateRangeFilters ? previousPageEndCursor : undefined),
        },
      }),
    getNextPageParam: (currentPage) => {
      return currentPage.lastEdgeCursor;
    },
  });
  if (hasNextPage && !isFetchingNextPage && hasDateRangeFilters) {
    fetchNextPage();
  }

  const loading = isLoading || isFetching || isFetchingNextPage;

  const transactions =
    (hasDateRangeFilters
      ? data?.pages?.flatMap((page) => page.payments ?? []).filter((x): x is SearchFailedPaymentsModel => !!x)
      : data?.pages?.[0]?.payments) ?? [];

  const summary = isEmpty(searchParams)
    ? transactions?.length
      ? calculateFailedTransactionsSummary(transactions)
      : undefined
    : calculateFailedTransactionsSummary(data?.pages?.[currentPage - 1]?.payments || []);

  return {
    loading,
    transactions,
    summary,
  };
};

export type UseExportFailedTransactionsQueryProps = {
  searchParams: SearchFailedPaymentsParams;
  numRows?: number;
  currentPage?: number;
};
export const useExportFailedTransactionsQuery = ({
  searchParams,
  numRows = 10,
  currentPage,
}: UseExportFailedTransactionsQueryProps) => {
  const { paymentsUrl } = useMerchant();

  const { getMultiQueryKey, locationIds, getLocationName } = useMultiQueryUtils();
  const alerts = useAlert();
  const { t } = useTranslation('payments');
  const { isMultiLocationFeature } = useMultiQueryUtils();

  const NUM_ROWS =
    !!searchParams?.submittedAt?.lessThanEqual || !!searchParams?.submittedAt?.greaterThanEqual ? 500 : numRows;

  const { previousPageEndCursor } = usePaginatedFailedInvoicesQueryData({
    searchParams,
    currentPage,
  });

  const {
    data: transactions,
    mutate: exportFailedTransactions,
    isLoading: isExporting,
  } = useMutation<SearchFailedPaymentsResponse>({
    mutationKey: getMultiQueryKey(['failed-transactions', locationIds, searchParams]),
    mutationFn: () => {
      return getFailedPayments({
        paymentsUrl,
        locationIds,
        params: { ...searchParams, first: NUM_ROWS, after: previousPageEndCursor },
      });
    },
    onSuccess: ({ payments }) => {
      const transformedData = transformDataForCsvExport(payments, getLocationName, isMultiLocationFeature);
      alerts.success(t('We’re working on exporting your data. It will download shortly.'));
      downloadCSV(transformedData, 'failed_transactions_list');
    },
    onError: (err) => {
      alerts.error(t('Failed to export failed transaction history'));
      console.error(err);
    },
  });

  return { transactions, isExporting, exportFailedTransactions };
};

export const transformDataForCsvExport = (
  data: SearchFailedPaymentsModel[] = [],
  getLocationName: GetLocationName,
  showLocationName: boolean
) => {
  return data.map((row) => {
    const amount = formatCentsToCurrency(row.amount)?.replace(',', '') || 'Not Available';
    const dateTime = formatDate(row.submittedAt, 'YYYY-MM-D');
    const locationName = getLocationName(row.locationId ?? '') ?? '';
    const personName = makePersonName(row.personFirstName, row.personLastName);
    const origin = getFailedTransactionsOriginLabel(row.origin);

    const paymentType = row.paymentType;
    const cardBrand = row.cardBrand;
    const last4 = row.cardLastFour;
    const paymentMethod = getPaymentMethod(
      mapFailedPaymentsTypeToPaymentType(paymentType),
      cardBrand?.toUpperCase() as CreditCardBrand
    );

    const fullPaymentMethod = makePersonName(paymentMethod, last4);

    return {
      'Transaction ID:': row.invoiceId,
      ...(showLocationName ? { 'Location Name': locationName } : {}),
      'Patient Id': row.personId,
      'Customer Name': personName,
      Amount: amount,
      'Date / Time': dateTime,
      'Payment Origin': origin,
      'Card Type': fullPaymentMethod,
      Status: row.paymentStatus,
      'Reason For Failure': row.paymentStatusReason,
      'Office User': row.userName,
    };
  });
};
