import { TemplateType_Slug, Template } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { Modal, useAlert } from '@frontend/design-system';
import { TemplateSelector, ModalControlWithKeepState } from '../../../messaging';

type InsertArgs = { invoiceId: string; textToPayLink: string; template?: Template };

type SelectPaymentsTemplateStepContentProps = Omit<ModalControlWithKeepState, 'show'> & {
  selectedInvoice?: InvoiceModel;
  groupId: string;
  onInsert: (args: InsertArgs) => void;
  onOpenSettings?: () => void;
  settingsOpenDelay?: number;
  onClickBack: () => void;
};

export const SelectPaymentsTemplateStepContent = ({
  groupId,
  onClose,
  onInsert,
  onClickBack,
  onOpenSettings,
  settingsOpenDelay,
  selectedInvoice,
}: SelectPaymentsTemplateStepContentProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();

  const handleInsert = (template?: Template) => {
    if (!selectedInvoice) {
      alert.error(t('Please select an invoice'));
      return;
    }
    if (!selectedInvoice.links.payment) {
      alert.error(t('No text to pay link available for the selected invoice. Please select another invoice.'));
      return;
    }
    onInsert({ invoiceId: selectedInvoice.id, textToPayLink: selectedInvoice.links.payment, template });
  };

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Select a Payments Template')}</Modal.Header>
      <Modal.Body>
        <TemplateSelector
          groupIds={[groupId]}
          templateTypes={[TemplateType_Slug.MANUAL_PAYMENT_REQUEST]}
          onSelectTemplate={(template) => handleInsert(template)}
          onOpenSettings={onOpenSettings}
          settingsOpenDelay={settingsOpenDelay}
        />
      </Modal.Body>
      <Modal.Footer
        tertiary={{
          label: t('Back'),
          onClick: () => onClickBack(),
        }}
        secondary={{
          label: t('Cancel'),
          onClick: () => onClose(),
        }}
      />
    </>
  );
};
