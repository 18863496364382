import { ComponentProps, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ColorPicker,
  NakedButton,
  PopoverDialog,
  Text,
  TextField,
  useDebouncedValue,
  usePopoverDialog,
} from '@frontend/design-system';
import { DEFAULT_PALETTE } from '../../../constants';
import { dropdownSectionTypeStyling, labelStyling } from '.';

type Props = Omit<ComponentProps<typeof TextField>, 'label'> & {
  isLast?: boolean;
  label?: string;
};

export const ColorSetting = ({ isLast, label, ...fieldProps }: Props) => {
  const { t } = useTranslation('content-composer-settings');
  const debouncedValue = useDebouncedValue(fieldProps.value, 500);

  useEffect(() => {
    if (fieldProps.value) {
      const validatedColor = CSS.supports('color', fieldProps.value)
        ? fieldProps.value
        : CSS.supports('color', `#${fieldProps.value}`)
        ? `#${fieldProps.value}`
        : '';
      // @ts-ignore it's the wrong type in the design system
      fieldProps.onChange({ value: validatedColor.toUpperCase() });
    }
  }, [fieldProps.value]);

  const handleColorChange = (color: string) => fieldProps.onChange({ ...fieldProps, value: color.toUpperCase() });

  const { close, getDialogProps, getTriggerProps } = usePopoverDialog({
    placement: 'bottom-end',
    initialOffset: {
      x: -110,
      y: 32,
    },
  });

  return (
    <div
      css={[
        dropdownSectionTypeStyling,
        !isLast &&
          css`
            margin-bottom: ${theme.spacing(2)};
          `,
      ]}
    >
      <Text css={labelStyling}>{label ?? t('Color')}</Text>
      <div
        css={css`
          position: relative;
        `}
      >
        <PopoverDialog
          {...getDialogProps()}
          css={css`
            border-radius: ${theme.borderRadius.small};
          `}
        >
          <ColorPicker
            initialColor={debouncedValue}
            onChange={handleColorChange}
            onClick={close}
            palette={DEFAULT_PALETTE}
          />
        </PopoverDialog>
        <TextField
          {...fieldProps}
          label=''
          css={css`
            border: 1px solid ${theme.colors.neutral30};
            padding-left: ${theme.spacing(1)};
            padding-right: 0;
            height: ${theme.spacing(4)};
            width: ${theme.spacing(16)};
            position: relative;
          `}
          startAdornment={
            <NakedButton {...getTriggerProps()}>
              <div
                css={css`
                  height: ${theme.spacing(2)};
                  width: ${theme.spacing(2)};
                  border-radius: ${theme.borderRadius.small};
                  background: ${fieldProps.value};
                  border: 1px solid ${theme.colors.neutral50};
                `}
              />
            </NakedButton>
          }
          onChange={(e) => {
            // @ts-ignore it's the wrong type in the design system
            handleColorChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
