import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { OnboardingModulesQueries, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { WeaveInitialCallScheduleIframe } from '@frontend/product-onboarding-tasks';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { trackingIds } from '../constants';

const modalTrackingIds = trackingIds.nwxDashboardTasksSection.scheduleCallDialog;

interface Props extends ModalControlModalProps {
  locationId: string;
  onSuccess: () => void;
}

export const ScheduleCallWithOnboarderModal = ({ locationId, onSuccess, ...modalProps }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  const [isCloseButtonVisible, setIsCloseButtonVisible] = useState<boolean>();
  const alert = useAlert();

  const { mutate: markLocationTaskCompleteMutate } = OnboardingModulesQueries.useMarkLocationTaskCompletedMutation({
    onError: () => {
      alert.error(t('An error occurred while marking the task as complete.'));
    },
    onSuccess,
  });

  const handleScheduleClose = () => {
    pendo.track(modalTrackingIds.closeButton);
    modalProps.onClose();
  };

  const handleAvailabilityLoaded = () => {
    // hide close button once chilli-piper iframe is loaded
    setIsCloseButtonVisible(false);
  };

  const handleBookingConfirmed = () => {
    markLocationTaskCompleteMutate(OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder);
  };

  useEffect(() => {
    if (modalProps.show) {
      setIsCloseButtonVisible(true);
    }
  }, [modalProps.show]);

  return (
    <Modal {...modalProps} maxWidth={600} css={{ padding: theme.spacing(2, 0), height: '90vh', maxHeight: 800 }}>
      <Modal.Header
        closeBtnTrackingId={modalTrackingIds.closeButton}
        onClose={isCloseButtonVisible ? modalProps.onClose : undefined}
        css={{ button: { height: 32 } }}
      >
        <div css={headerContainerStyle}>
          <Icon name='phone-call' />
          <Text size='large' weight='bold'>
            {t('Schedule With a Weave Onboarder')}
          </Text>
        </div>
      </Modal.Header>
      <Modal.Body>
        <WeaveInitialCallScheduleIframe
          show={modalProps.show}
          locationId={locationId}
          onAvailabilityLoaded={handleAvailabilityLoaded}
          onBookingConfirmed={handleBookingConfirmed}
          onClose={handleScheduleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

const headerContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
});
