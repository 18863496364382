import { useEffect, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, Button, useForm, TextField } from '@frontend/design-system';
import { MAX_CHANNEL_NAME_LENGTH } from '../../../constants';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';

type Props = {
  name: string;
  onSubmit: (name: string) => void;
};

export const NameSelection = ({ name, onSubmit }: Props) => {
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const textFieldRef = useRef<HTMLDivElement>(null);

  const conversationNames = useMemo(
    () => new Set(conversations?.groups.map((conversation) => conversation.name.toLowerCase()) ?? []),
    [conversations?.groups]
  );
  const { t } = useTranslation('team-chat');

  const form = useForm({
    fields: {
      name: {
        type: 'text',
        value: name,
        validateOnChange: true,
        touched: true,
        validator: (field) =>
          field.value.trim().length < 1
            ? t('Please provide a valid channel name')
            : conversationNames.has(field.value.trim().toLowerCase())
            ? t('Channel name already exists')
            : '',
      },
    },
    allowInvalidSubmission: false,
    onSubmit: (values) => {
      if (values.name) {
        onSubmit(values.name);
      }
    },
  });

  useEffect(() => {
    if (textFieldRef.current) {
      /*
        Modal body get focused when the modalProps.show property is set to true. Hence, when we focus on the text field,
        it get overridden by the modal body focus. To avoid this, we are setting a timeout of 600ms to focus on
        the text field.
       */
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 600);
    }
  }, []);

  return (
    <form {...form.formProps}>
      <div css={contentContainerStyle}>
        <TextField
          label=''
          placeholder={t('Name')}
          css={textAreaStyle}
          startAdornment={<Text>{t('#')}</Text>}
          maxLength={MAX_CHANNEL_NAME_LENGTH}
          fieldComponentProps={{ ref: textFieldRef }}
          {...form.getFieldProps('name')}
        />
        <Text textAlign='right' size='medium' css={charIndicatorTextStyle}>
          {t('{{total}}/{{totalCount}} characters', {
            total: form.values.name?.length,
            totalCount: MAX_CHANNEL_NAME_LENGTH,
          })}
        </Text>
        <Text css={modalTextStyle}>
          {t(
            'Channels are where conversations happen around a topic, choose a name that is easy to understand and find.'
          )}
        </Text>
      </div>
      <div css={bottomContainerStyle}>
        <Text css={stepTextStyle}>{t('Step 1 of 2')}</Text>
        <Button
          type='submit'
          disabled={!form.isComplete}
          css={[marginRight, buttonWidth]}
          trackingId='team-chat-2.0-create-channel-next-step'
        >
          {t('Next')}
        </Button>
      </div>
    </form>
  );
};

const contentContainerStyle = css({ padding: theme.spacing(0, 3) });
const bottomContainerStyle = css({
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  borderTop: `1px solid ${theme.colors.neutral10}`,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});
const stepTextStyle = css({ paddingLeft: theme.spacing(3) });
const buttonWidth = css({ width: 'fit-content' });
const marginRight = css({ marginRight: theme.spacing(3) });

const textAreaStyle = css({
  margin: theme.spacing(1, 0, 0, 0),
  input: {
    marginLeft: `${theme.spacing(0.5)} !important`,
  },
});

const modalTextStyle = css({
  color: theme.colors.black,
  marginTop: theme.spacing(1),
});

const charIndicatorTextStyle = css({ marginTop: theme.spacing(0.5) });
