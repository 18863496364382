import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../../utils';
import { InfoTipPopover } from '../../../../info-tip-popover';
import { MessagingOverview } from '../../../types';
import { MessagingChartBaseInterface, MessagingChartsBase } from './charts-base';

interface Props extends MessagingChartBaseInterface {
  data: MessagingOverview['patientsScheduled'];
  dateRangeHelperText?: string;
}

export const AppointmentScheduledChart = ({ data, dateRangeHelperText, ...rest }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <MessagingChartsBase
      {...rest}
      chartConfig={{
        data: {
          groups: [
            {
              name: 'newPatients',
              values: {
                newPatients: data?.newPatients,
              },
            },
            {
              name: 'existingPatients',
              values: {
                existingPatients: data?.existingPatients,
              },
            },
          ],
        },
        maxValue: data?.totalPatients,
        type: 'bar',
      }}
      customLegendsData={{
        newPatients: {
          label: t('New Patients'),
        },
        existingPatients: {
          label: t('Existing Patients'),
        },
        total: {
          color: theme.colors.neutral20,
          label: t('Total'),
          value: data?.totalPatients,
        },
      }}
      infoTip={
        <InfoTipPopover>{t('Patients Scheduled {{dateRangeHelperText}}', { dateRangeHelperText })}</InfoTipPopover>
      }
      legendsFormatValue={formatters.value.format}
      legendsValues={data}
      showMoreView='patientsScheduled'
      trackingIdBase='patients-scheduled'
      title={t('Patients Scheduled')}
    />
  );
};
