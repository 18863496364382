import { useCallback, useMemo } from 'react';
import { CategoryBarChartData, CategoryBarChartProps, TooltipData } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../utils';
import { InfoTipPopover } from '../../../info-tip-popover';
import { useFetchARROIOOverview } from '../hooks';
import { ARMultiChartsBase } from '.';

const colors = {
  completedByReminders: theme.colors.tangerine30,
  totalAppointmentsCompleted: theme.colors.primary20,
};

const strokeColors = {
  completedByReminders: theme.colors.tangerine40,
  totalAppointmentsCompleted: theme.colors.primary40,
};

const labels = {
  completedByReminders: i18next.t('Appointments Completed with Appointment Reminders', { ns: 'analytics' }),
  groupNameHeader: i18next.t('Location', { ns: 'analytics' }),
  totalAppointmentsCompleted: i18next.t('Total Appointments completed', { ns: 'analytics' }),
};

export const ARMultiComparisonConversionChart = ({
  aggregated,
  isLoading,
  locationsData,
}: ReturnType<typeof useFetchARROIOOverview>) => {
  const { t } = useTranslation('analytics');

  const processedData = useMemo(() => {
    const chartData: CategoryBarChartData = {};
    const customColumns: CategoryBarChartProps['customColumns'] = [];

    Object.entries(locationsData.breakup).forEach(
      ([locationId, { appointmentsCompletedByReminders, totalAppointmentsCompleted }]) => {
        chartData[locationId] = {
          completedByReminders: appointmentsCompletedByReminders,
          totalAppointmentsCompleted: totalAppointmentsCompleted - appointmentsCompletedByReminders,
        };

        customColumns[0] = {
          columnHeaderId: 'appointmentsCompleted',
          columnHeaderLabel: t('Appointments Completed'),
          data: {
            ...(customColumns[0]?.data || {}),
            [locationId]: ((appointmentsCompletedByReminders || 0) / (totalAppointmentsCompleted || 1)) * 100,
          },
        };
      }
    );

    return {
      chartData,
      customColumns,
    };
  }, [aggregated, locationsData]);

  const getCustomTooltipData = useCallback(
    (groupId: string): TooltipData[] => {
      const locationData = locationsData.breakup[groupId];

      return [
        {
          color: strokeColors.completedByReminders,
          formattedValue: formatters.value.format(locationData.appointmentsCompletedByReminders),
          id: 'completedByReminders',
          label: labels.completedByReminders,
        },
        {
          color: strokeColors.totalAppointmentsCompleted,
          formattedValue: formatters.value.format(locationData.totalAppointmentsCompleted),
          id: 'totalAppointmentsCompleted',
          label: labels.totalAppointmentsCompleted,
        },
      ];
    },
    [locationsData]
  );

  return (
    <ARMultiChartsBase
      {...processedData}
      colors={colors}
      customTooltipData={getCustomTooltipData}
      formatValue={formatters.value.format}
      infoTip={
        <InfoTipPopover>
          {t(
            'The No-Show Rate is the percentage of scheduled appointments that patients fail to attend without prior notice or cancellation.'
          )}
        </InfoTipPopover>
      }
      isLoading={isLoading}
      labels={labels}
      strokeColors={strokeColors}
      title={t('Appointment Reminder Conversion')}
      trackingIdBase='ar-multi-conversion'
    />
  );
};
