import { ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';
import { PersonOrderBy_Enum, PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { useInfiniteQuery } from 'react-query';
import { PersonAPI } from '@frontend/api-person';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaPersonV3Service } from '@frontend/schema';
import { theme } from '@frontend/theme';
import {
  Button,
  ButtonBar,
  SearchField,
  Text,
  useAlert,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { transformToLegacyPerson } from '../../helpers';
import { containsNoAlphabet, sanitizePhoneNumber } from '../../utils';
import { useManagePersonListContext } from './PersonSelectorContext';
import { personSelectorStyles } from './styles';
import { PatientSelectorScreenEnum, PersonLastNameLetterMap, TransformedLegacyPerson } from './types';

const API_RESPONSE_LIMIT = 50;

export const PersonSearch = () => {
  const { t } = useTranslation('personSelector');
  const alert = useAlert();
  const {
    locationId,
    defaultSearchValue,
    shouldRenderNotes,
    setPersonSelectorScreen,
    closePopoverDialog,
    setSelectedPerson,
    dataSources,
    showDataSources,
    addNewButtonTrackingId,
    cancelButtonTrackingId,
  } = useManagePersonListContext();
  const searchFieldProps = useFormField({ type: 'text', value: defaultSearchValue });
  const debouncedSearchTerm = useDebouncedValue(searchFieldProps.value, 500);
  const isPhoneSearch = !!debouncedSearchTerm && containsNoAlphabet(debouncedSearchTerm);

  const infiniteQueryByName = useInfiniteQuery({
    queryKey: ['person-search-by-name', debouncedSearchTerm, locationId],
    queryFn: async (queryFunctionContext) => {
      const personListData = await PersonAPI.getMultiPersonsV3({
        responseLimit: API_RESPONSE_LIMIT,
        pageToken: queryFunctionContext.pageParam,
        search: debouncedSearchTerm,
        locationIds: [locationId],
        statusFilter: PersonStatus_Enum.ACTIVE,
        orderBy: PersonOrderBy_Enum.LAST_NAME,
      });

      return {
        rows: personListData.rows.map((person) => transformToLegacyPerson(person)),
        nextOffset: personListData.nextOffset,
      };
    },
    getNextPageParam: (prevGroup) => {
      if (prevGroup.rows.length < API_RESPONSE_LIMIT) return undefined;
      return prevGroup.nextOffset;
    },
    enabled: !isPhoneSearch,
  });

  const infiniteQueryByPhoneNumber = useInfiniteQuery({
    queryKey: ['person-search-by-phone', debouncedSearchTerm, locationId],
    queryFn: async (queryFunctionContext) => {
      const personListData = await SchemaPersonV3Service.ListPersonsByPhoneLegacy({
        locationIds: [locationId],
        phoneNumber: sanitizePhoneNumber(debouncedSearchTerm),
        partialSearch: sanitizePhoneNumber(debouncedSearchTerm).length < 10,
        page: {
          size: API_RESPONSE_LIMIT,
          token: queryFunctionContext.pageParam,
          order: [{ orderBy: PersonOrderBy_Enum.LAST_NAME, descending: false }],
        },
      });

      return {
        rows: personListData.persons
          ?.map((person) => transformToLegacyPerson(person))
          .sort((a, b) => a?.LastName?.localeCompare(b?.LastName)), // Sort is not implemented on backend yet
        nextOffset: personListData.nextPageToken,
      };
    },
    getNextPageParam: (prevGroup) => {
      if (prevGroup.rows.length < API_RESPONSE_LIMIT) return undefined;
      return prevGroup.nextOffset;
    },
    enabled: isPhoneSearch,
  });

  const addNewPatient = () => {
    setPersonSelectorScreen(PatientSelectorScreenEnum.CUSTOM_CONTACT);
  };

  const handlePersonSelection = async (person: TransformedLegacyPerson) => {
    if (shouldRenderNotes) {
      setSelectedPerson(person);
      setPersonSelectorScreen(PatientSelectorScreenEnum.PERSON_NOTES);
      return;
    }

    const personDetails = { ...person };

    try {
      // Fix for v2 appointment writeback as v3 person API response doesn't contain `ClientLocationID`.
      // So in order to send in appointment writeback payload, we need to fetch the person details using v2 endpoint
      const personResponse = await PersonAPI.getPersonExtended(person.PersonID);
      personDetails.ClientLocationID = personResponse.ClientLocationID;
    } catch (error) {
      alert.error(t('Failed to fetch person/patient details'));
    }

    setSelectedPerson(personDetails);
    closePopoverDialog();
  };

  const getDataSourceName = (sourceId?: string) => {
    const dataSource = dataSources?.find((source) => source.SourceID === sourceId);
    return dataSource?.PracticeManagementSystem || '';
  };

  const lastNameLettersMap = useMemo(() => {
    let result = infiniteQueryByName.data?.pages;

    if (isPhoneSearch) {
      result = infiniteQueryByPhoneNumber.data?.pages;
    }

    return (result || [])
      .flatMap((page) => page.rows)
      .reduce((acc, person) => {
        const firstLetter = person.LastName ? person.LastName[0].toUpperCase() : '';
        if (!acc[firstLetter]) acc[firstLetter] = [person.PersonID];
        else acc[firstLetter].push(person.PersonID);

        return acc;
      }, {} as PersonLastNameLetterMap);
  }, [infiniteQueryByName.data?.pages, infiniteQueryByPhoneNumber.data?.pages]);

  return (
    <>
      <div css={personSelectorStyles.header}>
        <SearchField {...searchFieldProps} name='person-search' autoComplete='off' />
      </div>
      <InfinitePaginatedList
        height={330}
        infiniteQueryProps={isPhoneSearch ? infiniteQueryByPhoneNumber : infiniteQueryByName}
        renderListItem={({ listItem }): ReactNode => {
          const lastNameFirstLetter = listItem.LastName?.[0]?.toUpperCase();
          const isFirstPerson = lastNameLettersMap[lastNameFirstLetter]?.[0] === listItem.PersonID;
          const isLastPerson = lastNameLettersMap[lastNameFirstLetter]?.slice(-1)[0] === listItem.PersonID;
          const integratedSourceName = getDataSourceName(listItem.SourceID);

          return (
            <>
              {isFirstPerson && (
                <Text weight='bold' css={{ padding: theme.spacing(1, 2) }}>
                  {lastNameFirstLetter}
                </Text>
              )}
              <div css={listItemStyle} onClick={() => handlePersonSelection(listItem)}>
                <Text css={{ marginRight: theme.spacing(0.5) }}>{listItem.FirstName}</Text>
                <Text weight='bold'>{listItem.LastName}</Text>
                {showDataSources && (
                  <Text css={{ marginLeft: theme.spacing(1) }} size='small' color='light'>
                    {integratedSourceName}
                  </Text>
                )}
              </div>
              {isLastPerson && <hr css={separatorStyle} />}
            </>
          );
        }}
      />
      <ButtonBar css={personSelectorStyles.footer}>
        <Button variant='secondary' trackingId={cancelButtonTrackingId} onClick={closePopoverDialog}>
          {t('Cancel')}
        </Button>
        <Button variant='primary' trackingId={addNewButtonTrackingId} onClick={addNewPatient}>
          <Icon name='plus-small' color='white' css={{ marginRight: theme.spacing(1) }} />
          {t('Add New')}
        </Button>
      </ButtonBar>
    </>
  );
};

const separatorStyle = css`
  padding-top: theme.spacing(1);
  border: none;
  border-bottom: 1px solid ${theme.colors.neutral20};
`;

const listItemStyle = css`
  padding: ${theme.spacing(1, 2)};
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${theme.colors.neutral10};
  }
`;
