import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { PaymentMethodNavigationSwitch } from './payment-methods/payment-methods-navigation-switch';

export const PaymentMethods = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.paymentMethods}>
      <PaymentMethodNavigationSwitch />
    </StepPerformanceWrapper>
  );
};
