import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { WeaveLocation } from '@frontend/scope';
import { demoDataUtils } from '../../../utils/demo-data-utils';

const callDirections: PhoneAnalyticsTypes.Phone['CallDirection'][] = ['inbound', 'outbound'];
const callStatus: PhoneAnalyticsTypes.Phone['CallStatus'][] = ['abandoned', 'answered', 'forwarded', 'missed'];
const actionTypes = ['voicemail', 'answered', 'abandoned'];
const appointmentCategory = ['Existing Patient', 'Retained Patient', 'Repeated Caller'];
const sourceTypes = ['current_patient', 'new_patient'];

export const data = (
  locationIds: string[],
  locationData?: Record<string, WeaveLocation | undefined>
): PhoneAnalyticsTypes.RawPhoneReportResponse => {
  // Generate 25 dates for today with differnt and random time
  const dates = Array.from({ length: 25 }, () => {
    const date = new Date();
    date.setHours(Math.floor(Math.random() * 23));
    date.setMinutes(Math.floor(Math.random() * 59));
    date.setSeconds(Math.floor(Math.random() * 59));

    return date.toISOString();
  });

  const locationId = locationIds[Math.floor(Math.random() * locationIds.length)];

  return {
    data: dates.map((date) => {
      const randomPersons = demoDataUtils.generateRandomUserNames(2);
      const officeUser = randomPersons[0];
      const patient = randomPersons[1];
      const duration = Math.floor(Math.random() * 1000);

      return {
        ActionType: actionTypes[Math.floor(Math.random() * actionTypes.length)],
        AppointmentCategory: appointmentCategory[Math.floor(Math.random() * appointmentCategory.length)],
        CallDirection: callDirections[Math.floor(Math.random() * callDirections.length)],
        CallerNumber: demoDataUtils.generateRandomPhoneNumber(),
        CallStatus: callStatus[Math.floor(Math.random() * callStatus.length)],
        CreatedAt: date,
        Department: 'Main Line',
        DialedNumber: demoDataUtils.generateRandomPhoneNumber(),
        DurationInSec: duration,
        HoldTime: 0,
        ID: demoDataUtils.generateRandomUUID(),
        LocationID: locationId,
        LocationName: locationData?.[locationId]?.name || '',
        OfficeUser: `${officeUser.firstName} ${officeUser.lastName}`,
        PatientID: demoDataUtils.generateRandomNumberString(4),
        PatientName: `${patient.firstName} ${patient.lastName}`,
        ScheduleMatchOpen: Math.random() > 0.5,
        SourceType: sourceTypes[Math.floor(Math.random() * sourceTypes.length)],
        StartTime: date,
        TimeZone: 'US/Central',
      };
    }),
    meta: {
      links: {},
    },
  };
};
