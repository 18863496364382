import { css } from '@emotion/react';
import { OnboardingModulesQueries } from '@frontend/api-onboarding-modules';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { IconButton, Text } from '@frontend/design-system';
import { LinearProgressBar } from '../components';
import { PORTAL_ONBOARDING_TASKS_FEATURE_FLAG, TRACKING_IDS } from '../constants';
import { useTaskTrayShallowStore } from '../hooks';

export const SideNavOnboardingModuleProgress = () => {
  const { t } = useTranslation('onboarding-modules');
  const { toggleTaskTrayOpen } = useTaskTrayShallowStore('toggleTaskTrayOpen');

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasOnboardingTaskFeatureFlag = getFeatureFlagValue(PORTAL_ONBOARDING_TASKS_FEATURE_FLAG);
  const { getCustomizationFlagValue } = useScopedAppFlagStore();
  const isReviewsEnabled = getCustomizationFlagValue('reviewshomeicon');
  const { moduleListInfo } = OnboardingModulesQueries.useModulesAndTasksQuery(isReviewsEnabled);

  const isHideComponent = !hasOnboardingTaskFeatureFlag || !moduleListInfo || moduleListInfo.progressValue === 100;

  if (isHideComponent) return null;

  const handleClick = () => {
    toggleTaskTrayOpen(true);
  };

  return (
    <>
      <section css={{ padding: theme.spacing(0, 2, 2, 2) }}>
        <div css={headerTextContainer}>
          <Text weight='bold'>{t('Complete Your Weave Set Up')}</Text>
          <IconButton
            trackingId={TRACKING_IDS.navbarCompleteYourWeaveSetupIconBtn}
            label={t('Open')}
            onClick={handleClick}
          >
            <Icon name='caret-right' />
          </IconButton>
        </div>
        <LinearProgressBar
          progress={moduleListInfo.progressValue}
          bgColor={theme.colors.primary20}
          css={{ marginBottom: theme.spacing(2), height: theme.spacing(0.5) }}
        />
        <Text size='small' color='light'>
          {moduleListInfo.progressText}
        </Text>
      </section>
      <hr css={hrStyle} />
    </>
  );
};

const headerTextContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
});

const hrStyle = css({
  margin: theme.spacing(0, 2, 2, 2),
  border: 'none',
  borderTop: `1px solid ${theme.colors.neutral20}`,
});
