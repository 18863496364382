import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CaretDownIconSmall, Chip, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { DateRangeFieldProps, QuickFillButtonFilter, useDateFilters } from '../../hooks';
import { QuickFillConfigOption } from '../../types';
import { kebabCase } from '../../utils';
import { CustomDatePicker } from './custom-date-picker';

type QuickFillButtonProps = {
  onSelect: (range: string[]) => void;
  filter: QuickFillButtonFilter;
  option: QuickFillConfigOption;
  dateRangeProps: DateRangeFieldProps;
  activeOption?: QuickFillConfigOption;
};

const styles = {
  chip: css`
    cursor: pointer;
    color: ${theme.colors.neutral90};
  `,
  label: css`
    display: flex;
    align-items: center;
  `,
  chipSelected: css`
    border: solid 1px ${theme.colors.primary50};
  `,
  datePicker: css`
    max-width: calc(400px - 32px);
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
  `,
  menuItem: css`
    height: auto;
    background: transparent;

    &:hover {
      background: transparent;
    }
  `,
  dropdownIcon: css`
    cursor: pointer;
    transform: scale(0.6) translateY(-2px);
    margin-left: ${theme.spacing(1)};
  `,
};

export const QuickFillButton = ({ onSelect, filter, option, dateRangeProps, activeOption }: QuickFillButtonProps) => {
  const { getMenuProps, getTriggerProps, close } = usePopoverMenu({
    placement: 'bottom-end',
  });

  const { isSelected: getIsSelected, handleClick: onFilterClick } = useDateFilters({
    filter,
    onSelect,
    dateRangeProps,
  });

  const handleClick = useCallback(() => {
    onFilterClick(option);
    if (isCustom) {
      onSelect([filter?.dateRange?.start || '', filter?.dateRange?.end || ''].filter(Boolean));
    }
  }, [option, filter.dateRange]);

  const isSelected = useMemo(() => {
    if (activeOption) {
      return activeOption.label === option.label;
    }
    return getIsSelected(option);
  }, [option, filter]);

  const isCustom = option.period === 'custom';

  return (
    <div>
      <Chip
        trackingId={`pay-portal-invoicelist-quick-range-btn-${kebabCase(option.label)}`}
        size='large'
        variant={isSelected ? 'primary' : 'outline'}
        css={[styles.chip, isSelected && styles.chipSelected]}
        {...(isCustom
          ? getTriggerProps({
              onClick: handleClick,
            })
          : {
              onClick: handleClick,
            })}
      >
        <span css={styles.label}>
          {option.label}
          {isCustom && <CaretDownIconSmall css={styles.dropdownIcon} />}
        </span>
      </Chip>
      {isCustom && (
        <PopoverMenu {...getMenuProps()}>
          <PopoverMenuItem as='span' css={styles.menuItem}>
            <CustomDatePicker
              css={styles.datePicker}
              dateRangeProps={dateRangeProps}
              closePicker={() => close()}
              onSelect={onSelect}
            />
          </PopoverMenuItem>
        </PopoverMenu>
      )}
    </div>
  );
};
