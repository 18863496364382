import { createProvider } from '@frontend/store';

type MedicalConditionsSettingsStore = {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
};

export const { Provider: MedicalConditionsSettingsStoreProvider, useStore: useMedicalConditionsSettingsStore } =
  createProvider<MedicalConditionsSettingsStore>()((set) => ({
    hasUnsavedChanges: false,
    setHasUnsavedChanges: (value) => set(() => ({ hasUnsavedChanges: value })),
  }));
