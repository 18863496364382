import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';

type Props = {
  label: string;
  isSelf: boolean;
};
export const MentionTag = ({ label, isSelf }: Props) => {
  const users = useTeamChatSelector((ctx) => ctx.users);
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const currentUser = useTeamChatSelector((ctx) => ctx.user);
  const openStaticConversation = useTeamChatSelector((ctx) => ctx.openStaticConversation);
  const createConversation = useTeamChatSelector((ctx) => ctx.createConversation);

  const onClick = () => {
    if (!currentUser) {
      return null;
    }

    const implodedLabel = label.replaceAll(' ', '');
    const mentionedUser = users?.find(
      (u) => `@${getUserFullName(u.firstName, u.lastName)}`.replaceAll(' ', '') === implodedLabel
    );
    if (!mentionedUser || mentionedUser?.userID === currentUser?.userID) {
      return null;
    }

    const conversation = conversations?.dm.find((c) => {
      return c.memberIds.length === 1 && c.memberIds[0] === mentionedUser.userID;
    });

    if (conversation) {
      return openStaticConversation(conversation.channelId);
    } else {
      return createConversation({
        memberIds: [mentionedUser.userID],
        type: 'dm',
        name: undefined,
      });
    }
  };

  return (
    <span css={style} className={isSelf ? 'mention-self' : 'mention'} onClick={onClick}>
      {label}
    </span>
  );
};

const style = css`
  background: ${theme.colors.primary5};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.primary60};
  cursor: pointer;
  padding: 0px 2px;
  &:hover {
    background: ${theme.colors.primary10};
    color: ${theme.colors.primary70};
  }
  &.mention-self {
    background: #fff6d6;
  }
`;
