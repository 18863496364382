import dayjs from 'dayjs';
import { useQuery, useQueryClient } from 'react-query';
import {
  SchemaInsuranceVerificationAdapterServiceV1,
  SchemaInsuranceVerificationAPIService,
  SchemaPersonV3Service,
} from '@frontend/schema';
import { GetQueryOptions } from './types';

const queryKeys = {
  base: ['insurance-details'] as const,
  insuranceDetails: (locationId: string, personId: string) => [
    locationId,
    ...queryKeys.base,
    'insurance-details',
    personId,
  ],
  insuranceVerificationDetails: (locationId: string, personId: string) => [
    locationId,
    personId,
    ...queryKeys.base,
    'insurance-verification-details',
  ],
  insuranceSubscriberDetails: (locationId: string, personId: string) => [
    locationId,
    ...queryKeys.base,
    'insurance-subscriber-details',
    personId,
  ],
  insuranceVerificationMetrics: (locationId: string, startDate: string, endDate: string) => [
    locationId,
    ...queryKeys.base,
    'insurance-verification-metrics',
    startDate,
    endDate,
  ],
  payerList: (locationId: string) => [locationId, ...queryKeys.base, 'payer-list'],
  providerList: (locationId: string) => [locationId, ...queryKeys.base, 'provider-list'],
  insurancePlans: (locationId: string, sourceId: string) => [
    locationId,
    ...queryKeys.base,
    'insurance-plans',
    sourceId,
  ],
};

const LOCATION_ID_HEADER = 'Location-Id';
const getLocationIdHeader = (locationId?: string) => ({
  headers: locationId ? { [LOCATION_ID_HEADER]: locationId } : undefined,
});
const logErrorInConsole = (actionName: string) => (err: unknown) =>
  console.error(`An error occurred while ${actionName}`, err);

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return {
    invalidateGetProviderListQuery: (locationId: string) => {
      queryClient.invalidateQueries(queryKeys.providerList(locationId));
    },
    invalidateGetInsuranceByPersonIdQuery: (personId: string, locationId: string) => {
      queryClient.invalidateQueries(queryKeys.insuranceDetails(locationId, personId));
    },
    invalidateGetInsuranceVerificationMetrics: (
      startDate: string | dayjs.Dayjs,
      endDate: string | dayjs.Dayjs,
      locationId: string
    ) => {
      const start = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
      const end = endDate ? dayjs(endDate).add(1, 'day').format('YYYY-MM-DD') : '';
      queryClient.invalidateQueries(queryKeys.insuranceVerificationMetrics(locationId, start, end));
    },
    invalidateGetInsuranceVerificationDetailsPersonIdQuery: (personId: string, locationId: string) => {
      queryClient.invalidateQueries(queryKeys.insuranceVerificationDetails(locationId, personId));
    },
  };
};

export const useGetInsuranceByPersonId = (personId: string, locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.insuranceDetails(locationId, personId),
    queryFn: () =>
      SchemaInsuranceVerificationAPIService.GetInsuranceByPersonID({ personId }, getLocationIdHeader(locationId)),
    enabled: Boolean(personId && locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance details'),
    retry: false,
  });
};

export const useGetInsuranceVerificationDetailsPersonId = (
  personId: string,
  locationId: string,
  options?: GetQueryOptions
) => {
  return useQuery({
    queryKey: queryKeys.insuranceVerificationDetails(locationId, personId),
    queryFn: () =>
      SchemaInsuranceVerificationAdapterServiceV1.GetInsuranceVerificationDetails(
        { personId, locationId },
        getLocationIdHeader(locationId)
      ),
    enabled: Boolean(personId && locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance verification details'),
  });
};

export const useGetSubscriberByDependentsPersonID = (
  personId: string,
  locationId: string,
  options?: GetQueryOptions
) => {
  return useQuery({
    queryKey: queryKeys.insuranceSubscriberDetails(locationId, personId),
    queryFn: () =>
      SchemaInsuranceVerificationAPIService.GetSubscriberByDependentsPersonID(
        { personId },
        getLocationIdHeader(locationId)
      ),
    enabled: Boolean(personId && locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance subscriber details'),
    retry: false,
  });
};

export const useGetInsuranceVerificationMetrics = (
  startDate: string | dayjs.Dayjs,
  endDate: string | dayjs.Dayjs,
  locationId: string,
  options?: GetQueryOptions
) => {
  const start = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
  const end = endDate ? dayjs(endDate).add(1, 'day').format('YYYY-MM-DD') : '';

  return useQuery({
    queryKey: queryKeys.insuranceVerificationMetrics(locationId, start, end),
    queryFn: () =>
      SchemaInsuranceVerificationAdapterServiceV1.GetVerificationMetrics(
        { start, end },
        getLocationIdHeader(locationId)
      ),
    retry: false,
    enabled: Boolean(startDate && endDate && locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance verification metrics'),
  });
};

export const useGetPayerList = (locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.payerList(locationId),
    queryFn: async () => {
      const res = await SchemaInsuranceVerificationAdapterServiceV1.GetPayersList({}, getLocationIdHeader(locationId));
      return res.payersResponse?.map((item) => ({ id: item.id ?? '', name: item.name ?? '' })) ?? [];
    },
    retry: false,
    cacheTime: Infinity,
    staleTime: 60 * 60 * 1000, // 1 hr
    enabled: Boolean(locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance payers'),
  });
};

export const useGetProviderList = (locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.providerList(locationId),
    queryFn: async () => {
      const res = await SchemaInsuranceVerificationAdapterServiceV1.GetProvidersList(
        {},
        getLocationIdHeader(locationId)
      );

      return (
        res.providersResponse?.map((item) => ({
          nationalProviderId: item.nationalProviderId ?? '',
          taxId: item.taxId ?? '',
        })) ?? []
      );
    },
    retry: false,
    cacheTime: Infinity,
    staleTime: 60 * 60 * 1000, // 1 hr
    enabled: Boolean(locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance providers'),
  });
};

export const useGetInsurancePlans = (sourceId: string, locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.insurancePlans(locationId, sourceId),
    queryFn: async () => {
      const res = await SchemaInsuranceVerificationAdapterServiceV1.GetInsurancePlans(
        { sourceId, locationId },
        getLocationIdHeader(locationId)
      );

      return res.plans ?? [];
    },
    retry: false,
    cacheTime: Infinity,
    staleTime: 60 * 60 * 1000, // 1 hr
    enabled: Boolean(sourceId && locationId && !options?.disabled),
    onError: logErrorInConsole('getting insurance plans'),
  });
};

export const useGetHouseholdData = (locationId: string, householdId: string) => {
  return useQuery({
    queryKey: ['insurance', 'household-patient-details', locationId, householdId],
    queryFn: () =>
      SchemaPersonV3Service.SearchPersonsLegacy({
        locationIds: [locationId],
        householdId: householdId,
        page: { size: 50 },
      }),
    enabled: !!locationId && !!householdId,
  });
};
