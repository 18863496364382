import { Interpolation } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { EVENT_CARD_GAP, EVENT_CARD_MIN_WIDTH, OVERLAPPING_EVENTS_BUTTON_TOTAL_SPACE } from './constants';
import type {
  GetPixelFromTimestampParamsType,
  GetGridColumnsFromTimestampParamsType,
  EventData,
  TimeRange,
  ProviderEventType,
  ProcessedEventData,
  EventType,
} from './types';

const PX_PER_MINUTE = 2;
const ONE_MINUTE_IN_MILLISECONDS = 60000;
const ONE_HOUR_IN_MINUTES = 60;
const EVENT_CARD_OFFSET_IN_MINUTES = 15;
const MIN_EVENT_GROUP_RANGE_IN_MINUTES = 15;

export const ADJUSTED_ONE_HOUR_IN_MINUTES = ONE_HOUR_IN_MINUTES * PX_PER_MINUTE;
export const ADJUSTED_ONE_MINUTE_IN_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * PX_PER_MINUTE;

/**
 * Validates input hours are between 0 and 24, and endHour is not before startHour
 * @param {number} startHour - The hour to start from
 * @param {number} endHour - The hour to end at
 * @throws Will throw an error if startHour or endHour is not between 0 and 24, or if endHour is before startHour
 */

dayjs.extend(customParseFormat);
function validateHourInput(startHour: number, endHour: number): void {
  if (startHour < 0 || startHour > 24 || endHour < 0 || endHour > 24) {
    throw new Error('Hour should be between 0 and 24');
  }

  if (endHour < startHour) {
    throw new Error('End hour cannot be before start hour');
  }
}

/**
 * Parses a given timestamp into a valid `dayjs` object.
 *
 * This function tries multiple formats to parse the timestamp and throws an error if it's unable to parse it.
 *
 * @param {string | number} timestamp - The timestamp to parse, which can be a string in various formats or a number.
 *
 * @returns {dayjs.Dayjs} A valid `dayjs` object representing the parsed timestamp.
 *
 * @throws {Error} If the timestamp cannot be parsed into a valid format.
 *
 * @example
 * parseTimestamp('14:30'); // returns a dayjs object representing 14:30
 */
const parseTimestamp = (timestamp: string | number) => {
  let time = dayjs(timestamp);

  if (!time.isValid()) {
    time = dayjs(timestamp, 'HH:mm A');
  }

  if (!time.isValid()) {
    time = dayjs(timestamp, 'HH:mm');
  }

  if (!time.isValid()) {
    time = dayjs(timestamp, 'H A');
  }

  if (!time.isValid()) {
    throw new Error('Invalid timestamp format');
  }

  return time;
};

/**
 * Converts a timestamp to a 12-hour format time string.
 *
 * @param {string | number} timestamp - The input timestamp, which can be a string or a number.
 * @param {boolean} [showMeridiem=false] - Determines whether the output should include the AM/PM indicator.
 * @returns {string} The time in 12-hour format.
 *
 * @example
 * timestampTo12HourFormat('14:30'); // "02:30"
 * timestampTo12HourFormat(1586223820000, true); // E.g., "04:30 PM"
 */
const timestampTo12HourFormat = (timestamp: string | number, showMeridiem = false): string => {
  const time = parseTimestamp(timestamp);

  return time.format(showMeridiem ? 'h:mm A' : 'h:mm');
};

/**
 * Formats an hour in 24-hour format to a 12-hour format with AM/PM label.
 *
 * @param {number} hour - The hour in 24-hour format (0 to 24).
 * @returns {string} The formatted hour in 12-hour format with AM/PM.
 *
 * @example
 * formatHourToLabel(0); // "12 AM"
 * formatHourToLabel(13); // "1 PM"
 * formatHourToLabel(23); // "11 PM"
 */
function formatHourToLabel(hour: number): string {
  const time = dayjs().hour(hour).minute(0);

  return time.format('h A');
}

/**
 * Generate an array of hours in a day within a specified range.
 * @param {number} startHour - The hour to start from (default is 0)
 * @param {number} endHour - The hour to end at (default is 24)
 * @returns {string[]} - An array of hours as strings in the format '[H] AM/PM'
 * @throws Will throw an error if startHour or endHour is not between 0 and 24, or if endHour is before startHour
 */
export function generateHourLabels(startHour = 0, endHour = 24): string[] {
  validateHourInput(startHour, endHour);

  const hours: string[] = [];

  for (let i = startHour; i <= endHour; i++) {
    const hourLabel = formatHourToLabel(i);
    hours.push(hourLabel);
  }

  return hours;
}

export function calculatePercentage(num: number, total: number): number {
  if (total === 0) {
    throw new Error('Division by zero is undefined');
  }
  return (num / total) * 100;
}

/**
 * Converts a timestamp string into an object containing its hours and minutes.
 *
 * @param {string} timestamp - The input timestamp as a string.
 * @returns {Object} An object with properties for hours and minutes.
 * @property {number} hours - The hours extracted from the timestamp.
 * @property {number} minutes - The minutes extracted from the timestamp.
 *
 * @example
 * timestampToHourAndMinutes('14:30'); // { hours: 14, minutes: 30 }
 */

function timestampToHourAndMinutes(timestamp: string) {
  const time = parseTimestamp(timestamp);
  return { hours: time.hour(), minutes: time.minute() };
}

/**
 * Creates a function that calculates the number of minutes from a given timestamp to a starting hour.
 * @param {number} startHour - The hour to start from (default is 0)
 * @returns {Function} - A function that takes a timestamp string and returns the number of minutes from startHour to the timestamp
 */

export const maxTotalGridColumns = (startHour = 0, endHour = 0) => {
  const offset = 2;
  return (endHour - startHour + 1) * 60 - offset;
};

/**
 * Gets the number of pixels from a given timestamp,
 *
 * @param {Object} {startHour, endHour, totalMinutes} - The starting hour, ending hour, and total minutes from the starting hour.
 * @returns {number} The number of pixels from the timestamp.
 */
const getPixelsFromTimestamp = ({ startHour, endHour, totalMinutes }: GetPixelFromTimestampParamsType) => {
  const maxTotalMinutes = maxTotalGridColumns(startHour, endHour);

  if (totalMinutes < 0) {
    return 1; //offset
  }
  if (totalMinutes > maxTotalMinutes) {
    return maxTotalMinutes * PX_PER_MINUTE;
  }

  return totalMinutes * PX_PER_MINUTE;
};

/**
 * Checks if the end timestamp is of the next day compared to the start timestamp.
 *
 * @param {string} startHourTimestamp
 * @param {string} endHourTimeStamp
 * @returns {boolean}
 *
 * @example
 * endHourTimeStamp('10:30 AM', '11:30 AM'); // true
 * endHourTimeStamp('10:30 PM', '01:30 AM'); // false
 * endHourTimeStamp('20:30', '23:30'); // true
 * endHourTimeStamp('12:30', '01:30'); // false
 */
export const isEndHourValid = (startHourTimestamp: string, endHourTimeStamp: string): boolean => {
  const format = ['hh:mm A', 'HH:mm'];

  const startHour = dayjs(startHourTimestamp, format);
  const endHour = dayjs(endHourTimeStamp, format);
  const midnight = dayjs('12:00 AM', format);

  if (!startHour.isValid() || !endHour.isValid() || !midnight.isValid()) {
    return false;
  }

  const isEndHourAfterMidnight = endHour.isAfter(midnight);
  const isEndHourSameAsMidnight = endHour.isSame(midnight);
  const isEndHourBeforeStartHour = endHour.isBefore(startHour);

  if ((isEndHourAfterMidnight || isEndHourSameAsMidnight) && isEndHourBeforeStartHour) {
    return false;
  }

  return true;
};

/**
 * Creates a function to compute the grid columns from a given start and end timestamp,
 *
 * @param {number} [startHour=0] - The starting hour for the calculation. Defaults to 0.
 * @param {number} [endHour=24] - The ending hour for the calculation. Defaults to 24.
 *
 * @returns {Function} A function that computes the grid columns from a start and end timestamp.
 * @property {string} startHourTimestamp - A string timestamp.
 * @property {string} endHourTimeStamp - A string timestamp.
 *
 * @example
 * const getGridColumnsFromTimestamp = createGridColumnsFromStartAndEndTimestamp(8, 16);
 * getGridColumnsFromTimestamp(startHourTimestamp, endHourTimeStamp); {startGridColumn: 150, endGridColumn: 300}
 */
export const createGridColumnsFromStartAndEndTimestamp =
  (startHour = 0, endHour = 24) =>
  ({ startHourTimestamp, endHourTimeStamp }: GetGridColumnsFromTimestampParamsType) => {
    let tempEndHourTimeStamp = endHourTimeStamp;

    const duration = endHourTimeStamp
      ? parseTimestamp(endHourTimeStamp).diff(parseTimestamp(startHourTimestamp), 'minute')
      : 0;

    if (duration >= 0 && duration < EVENT_CARD_OFFSET_IN_MINUTES) {
      tempEndHourTimeStamp = parseTimestamp(startHourTimestamp)
        .add(EVENT_CARD_OFFSET_IN_MINUTES, 'm')
        .format('hh:mm A');
    }

    const isSameDay = isEndHourValid(startHourTimestamp, tempEndHourTimeStamp);
    const { hours: startHours, minutes: startMinutes } = timestampToHourAndMinutes(startHourTimestamp);
    const { hours: endHours, minutes: endMinutes } = timestampToHourAndMinutes(tempEndHourTimeStamp);

    const nextDayOffset = isSameDay ? 0 : 24;

    const startTotalMinutes = (startHours - startHour) * 60 + startMinutes + 1;
    const endTotalMinutes = (endHours + nextDayOffset - startHour) * 60 + endMinutes;

    const endGridColumnTotalMinutes = endTotalMinutes;

    return {
      startGridColumn: getPixelsFromTimestamp({
        startHour,
        endHour,
        totalMinutes: startTotalMinutes,
      }),
      endGridColumn: getPixelsFromTimestamp({
        startHour,
        endHour,
        totalMinutes: endGridColumnTotalMinutes,
      }),
    };
  };

export function calculateTimeProgress(totalDurationInHours: number, hoursOffset = 0) {
  const currentDate = new Date();

  const currentHours = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();

  const totalDurationInMinutes = totalDurationInHours * 60;
  const offsetInMinutes = hoursOffset * 60;

  const minutesPassedSinceOffset = currentHours * 60 + currentMinutes - offsetInMinutes;
  const progressPercentage = calculatePercentage(minutesPassedSinceOffset, totalDurationInMinutes);

  return progressPercentage;
}

/**
 * Validates the start and end hours.
 *
 * @param {number} startHour - The starting hour. Must be between 0 and 23.
 * @param {number} endHour - The ending hour. Must be between 0 and 24 and must be greater than startHour.
 * @returns {void}
 * @throws Will throw an error if startHour or endHour are not valid.
 */
function validateHours(startHour: number, endHour: number) {
  if (startHour < 0 || startHour > 23 || endHour < 0 || endHour > 24 || startHour >= endHour) {
    throw new Error('Invalid start or end hour.');
  }
}

function calculateYPosition(event: React.MouseEvent<Element> | MouseEvent, element: Element) {
  const rect = element.getBoundingClientRect();
  const yRelativeToElement = event.clientY - rect.top;
  return element.scrollTop + yRelativeToElement;
}

function calculateHoursAndMinutes(
  positionWithinScrollWidth: number,
  startHour: number,
  sectionWidth: number,
  numberOfSections: number
) {
  const sectionIndex = Math.floor(positionWithinScrollWidth / sectionWidth);
  const positionWithinSection = positionWithinScrollWidth % sectionWidth;

  const hours = (sectionIndex % numberOfSections) + startHour;
  const minutes = Math.floor((positionWithinSection / sectionWidth) * 60);

  return { hours, minutes, sectionIndex };
}

function format12HourTime(hours: number, minutes: number) {
  const time = dayjs()
    .hour(hours)
    .minute(minutes / PX_PER_MINUTE);
  return time.format('hh:mm A');
}

type Increment = 5 | 10 | 15;

/**
 * Rounds the provided number of minutes to the nearest specified increment.
 *
 * @param {number} mins - The number of minutes to round.
 * @param {Increment} [increment=5] - The increment to which the minutes should be rounded to. Valid values are 5, 10, or 15.
 * @returns {number} The rounded number of minutes.
 *
 * @example
 * toNearestIncrement(8); // 10
 * toNearestIncrement(8, 15); // 15
 */
function toNearestIncrement(mins: number, increment: Increment = 5): number {
  const remainder = mins % increment;
  const halfIncrement = increment / 2;

  if (remainder === 0) {
    return mins;
  }

  return remainder <= halfIncrement ? mins - remainder : mins + (increment - remainder);
}

interface GetTimestamp {
  /**
   * The event from which to derive the timestamp.
   */
  event: React.MouseEvent<Element> | MouseEvent;
  /**
   * The reference element for calculating position.
   */
  element?: Element | null;
  /**
   * The starting hour of the period (e.g., 0 for midnight).
   * @default 0
   */
  startHour?: number;
  /**
   * The ending hour of the period (e.g., 24 for midnight of the next day).
   * @default 24
   */
  endHour?: number;
  /**
   * Round to the nearest increment of this value in minutes (e.g., 15 for quarter-hour increments).
   */
  nearestQuarter?: boolean;
  /**
   * A flag indicating whether or not to round to the nearest quarter.
   */
  nearestIncrement?: Increment;
}

/**
 * Calculates a timestamp based on the position of an event relative to a given element.
 *
 * @param {GetTimestamp} params - The parameters used for calculating the timestamp.
 * @returns {Object} An object containing a `formattedTime` string in 12-hour format and `totalMinutes` which represents the number of minutes from midnight.
 */
export function getTimestamp({
  event,
  element,
  startHour = 0,
  endHour = 24,
  nearestIncrement,
  nearestQuarter = false,
}: GetTimestamp) {
  validateHours(startHour, endHour);
  if (!element) return;
  const positionWithinScrollWidth = calculateYPosition(event, element);
  const numberOfSections = endHour - startHour + 1;
  const sectionWidth = element.scrollHeight / numberOfSections;
  const { hours, minutes } = calculateHoursAndMinutes(
    positionWithinScrollWidth,
    startHour,
    sectionWidth,
    numberOfSections
  );

  const correctedMinutes = nearestQuarter
    ? toNearestIncrement(minutes * PX_PER_MINUTE, nearestIncrement)
    : minutes * PX_PER_MINUTE;
  const formattedTime = format12HourTime(hours, correctedMinutes);

  const totalMinutes = (hours - startHour) * (60 * PX_PER_MINUTE) + correctedMinutes;

  return { formattedTime, totalMinutes };
}

interface CheckIntersectionOptions {
  /**
   * The target element to check intersection for.
   */
  targetElement?: Element;
  /**
   * The container element within which other intersecting elements are looked up.
   */
  containerElement?: Element;
  /**
   * The selector used to query desired elements within the container for intersection check.
   * @default dataAttributeSelector='[data-event="true"]'
   */
  dataAttributeSelector?: string;
}

/**
 * Checks if the target element intersects with any of the desired elements
 * (identified by the data attribute) within a given container.
 *
 * @param {CheckIntersectionOptions} options - Options including target element, container, and optional data attribute selector.
 * @returns {boolean} Returns true if the target element intersects with any desired element, false otherwise.
 */
export const checkIntersection = ({
  targetElement,
  containerElement,
  dataAttributeSelector = '[data-event="true"]',
}: CheckIntersectionOptions): boolean => {
  if (!targetElement || !containerElement) return false;

  const elementBox = targetElement.getBoundingClientRect();
  const desiredElements = containerElement.querySelectorAll(dataAttributeSelector);

  return Array.from(desiredElements).some((element: Element) => {
    const desiredBox = element.getBoundingClientRect();

    return (
      elementBox.left < desiredBox.right &&
      elementBox.right > desiredBox.left &&
      elementBox.top < desiredBox.bottom &&
      elementBox.bottom > desiredBox.top
    );
  });
};

export const EVENT_CARD_DEFAULT_MEASUREMENTS = {
  expandedWidth: 400,
  expandedWidthForAppointment: 600,
  condensedHeight: 40,
};

/**
 * Calculates the distance of an element from the top and bottom edges of its parent element.
 *
 * @param {HTMLElement} element - The target element for which the distances should be calculated.
 * @param {HTMLElement} parentElement - The reference parent element to which the distances are relative.
 * @returns {Object} An object containing distances `top` and `bottom` representing the distance of the element from the top and bottom edges of the parent element, respectively.
 */
export const getDistanceFromParentEdges = (element: HTMLElement, parentElement: HTMLElement) => {
  const parentRect = parentElement.getBoundingClientRect();
  const rect = element.getBoundingClientRect();

  const topDistance = rect.top - parentRect.top;
  const bottomDistance = parentRect.bottom - rect.top;

  return {
    top: topDistance,
    bottom: bottomDistance,
  };
};

export const zIndex = {
  progressLine: 0,
  overlappingEventButton: 1,
  eventTimelineWrapper: 2,
  eventCardDefault: 2,
  hoursOfTheDay: 3,
  backgroundOverlay: 4,
  eventCardExpanded: 4,
  eventCreatorCard: 5,
  providerSection: 6,
} as const;

export const getDurationFromHours = (startHour: string, endHour: string) => {
  let durationString = '';
  const startTimeMatch = startHour === '00:00' || startHour === '12:00 AM';
  const endTimeMatch = endHour === '23:59' || endHour === '11:59 PM';

  if (startTimeMatch && endTimeMatch) {
    durationString = 'All day';
  } else {
    durationString = `${timestampTo12HourFormat(startHour, true)} ${
      endHour ? ` -  ${timestampTo12HourFormat(endHour, true)}` : ''
    }`;
  }
  return durationString;
};

export const isAllDayEvent = (startHour: string, endHour: string) => {
  return getDurationFromHours(startHour, endHour) === 'All day';
};

export const moveUnassignedProviderHeading = <T extends { name: string }>(providers?: T[]): T[] => {
  if (!providers?.length) return [];

  const sortedProviders = [...providers];
  const unassignedIndex = sortedProviders.findIndex((e) => e.name === 'unassigned');
  if (unassignedIndex !== -1) {
    const unassigned = sortedProviders.splice(unassignedIndex, 1)[0];
    sortedProviders.push(unassigned);
  }
  return sortedProviders;
};

export const moveUnassignedProviderEvents = (providers?: ProviderEventType[]) => {
  if (!providers?.length) return [];

  const sortedProviders = [...providers];
  const unassignedIndex = sortedProviders.findIndex((e) => e.name === 'unassigned');
  if (unassignedIndex !== -1) {
    const unassigned = sortedProviders.splice(unassignedIndex, 1)[0];
    sortedProviders.push(unassigned);
  }
  return sortedProviders;
};

export const getEventType = (event: EventData): EventType => {
  const isOutOfOfficeEvent = event.type === 'unavailable' && event.id;
  return isOutOfOfficeEvent ? 'outOfOffice' : event.id ? 'appointment' : 'break';
};

//#region Events grouping new algorithm related functions

/**
 * Formats and sorts the events based on their start time.
 * @param events - The array of events to be formatted and sorted.
 * @returns {EventData[]} - Returns the formatted and sorted array of events.
 */
function formatAndSortEvents(events: EventData[]): ProcessedEventData[] {
  return events
    .map((event) => {
      const startTimeStamp = dayjs(event.startHour, 'hh:mm A').valueOf();
      const endTimeStamp = dayjs(event.endHour, 'hh:mm A').valueOf();
      const minEndTimeStamp = dayjs(startTimeStamp).add(EVENT_CARD_OFFSET_IN_MINUTES, 'minutes').valueOf();

      return {
        ...event,
        eventType: getEventType(event),
        startTimestamp: startTimeStamp,
        endTimestamp: endTimeStamp ? Math.max(endTimeStamp, minEndTimeStamp) : minEndTimeStamp,
        laneIndex: 0,
        laneCount: 1,
      };
    })
    .sort((a, b) => a.startTimestamp - b.startTimestamp);
}

/**
 * Checks if two events are overlapping.
 *
 * @param event1
 * @param event2
 * @returns {boolean} - Returns true if the events are overlapping, false otherwise.
 */
function isOverlappingEvents(event1: TimeRange, event2: TimeRange): boolean {
  // Either event2 contains event1's start OR event1 contains event2's start (inclusive on the lower end/start but not inclusive on end)
  return (
    (event1.startTimestamp >= event2.startTimestamp && event1.startTimestamp < event2.endTimestamp) ||
    (event2.startTimestamp >= event1.startTimestamp && event2.startTimestamp < event1.endTimestamp)
  );
}

/**
 * Prepares overlapping event groups based on the provided events.
 * @param events - The array of events to be grouped.
 * @returns {EventGroup[]} - Returns an array of event groups.
 */
function prepareOverlappingEventGroups(events: EventData[]): {
  range: TimeRange;
  events: ProcessedEventData[];
}[] {
  const filteredEvents = events.filter((event) => !isAllDayEvent(event.startHour, event.endHour));
  const formattedEvents = formatAndSortEvents(filteredEvents);

  const groups = [];
  while (formattedEvents.length > 0) {
    const currEvent = formattedEvents.shift();
    // safety check to ensure we have an event
    if (!currEvent) continue;

    const minGroupEndTimestamp =
      currEvent.startTimestamp + MIN_EVENT_GROUP_RANGE_IN_MINUTES * ONE_MINUTE_IN_MILLISECONDS;
    const newGroup = {
      range: {
        startTimestamp: currEvent.startTimestamp,
        endTimestamp: Math.max(minGroupEndTimestamp, currEvent.endTimestamp), // make sure group has some min range
      },
      events: [currEvent],
    };
    // Since we're sorted by start time, as soon as we hit an appointment that doesn't overlap,
    // the group can be considered "complete" -> move onto the next group
    while (formattedEvents.length > 0 && isOverlappingEvents(newGroup.range, formattedEvents[0])) {
      const eventToAdd = formattedEvents.shift();
      // safety check to ensure we have an event
      if (!eventToAdd) continue;

      newGroup.events.push(eventToAdd);
      // Expand the range to the new limits based on the new appointment (can skip expanding start since we're sorted)
      newGroup.range.endTimestamp = Math.max(newGroup.range.endTimestamp, eventToAdd.endTimestamp);
    }
    groups.push(newGroup);
  }

  return groups;
}

interface LaneInfo {
  priority: number; // priority lane should be shown at the first place
  events: ProcessedEventData[];
}
/**
 * Puts the events from a group into lanes based on their overlap.
 * @param groupEvents - The group events to be put into lanes.
 * @returns {EventData[][]} - Returns an array of lanes, where each lane is an array of events that don't overlap with each other.
 */
function putGroupEventsIntoLane(groupEvents: ProcessedEventData[]): LaneInfo[] {
  const lanes: LaneInfo[] = [];
  for (const currEvent of groupEvents) {
    let eventInserted = false;
    const eventPriority = currEvent.eventType === 'appointment' ? 3 : currEvent.providerId ? 2 : 1;

    // Check each lane to see which one this event can fit into
    for (const lane of lanes) {
      const hasAnyOverlappingEvent = lane.events.some((event) => isOverlappingEvents(event, currEvent));
      if (!hasAnyOverlappingEvent && lane.priority === eventPriority) {
        lane.events.push(currEvent);
        eventInserted = true;
        break;
      }
    }

    if (!eventInserted) {
      lanes.push({ priority: eventPriority, events: [currEvent] });
    }
  }
  // sort lanes by event count to show priority lane or max events in the visible lanes
  return lanes.sort((a, b) => a.priority - b.priority || b.events.length - a.events.length);
}

/**
 * Group overlapping events based based on its time range.
 *
 * e.g.:
 *  - Event 1: 10:00 AM - 11:00 AM
 * -  Event 2: 10:00 AM - 10:30 AM
 *  - Event 3: 10:30 AM - 11:30 AM
 *  - Event 4: 12:00 PM - 1:00 PM
 *  - Event 5: 12:30 PM - 1:30 PM
 *
 * The above events will be grouped first as follows:
 *  - Group 1: Event 1, Event 2, Event 3
 *  - Group 2: Event 4, Event 5
 *
 * Later each group will be put into lanes based on their overlap:
 * Group 1:
 * - Lane 1: Event 1
 * - Lane 2: Event 2, Event 3
 *
 * Group 2:
 * - Lane 3: Event 4
 * - Lane 4: Event 5
 *
 * @param events - The array of events.
 * @returns {EventData[]} Returns groups containing events in which each event has a lane index and lane count.
 */
export function groupOverlappingEvents(events: EventData[]): ProcessedEventData[][] {
  const overlappingGroups = prepareOverlappingEventGroups(events);

  return overlappingGroups.map((group) => {
    const formattedEvents: ProcessedEventData[] = [];
    const lanes = putGroupEventsIntoLane(group.events);
    lanes.forEach((lane, laneIndex) => {
      lane.events.forEach((event) => {
        formattedEvents.push({ ...event, laneIndex, laneCount: lanes.length });
      });
    });
    return formattedEvents;
  });
}

//#endregion

//#region Provider Lane & Event Card positioning related functions

// Calculate the maximum number of lanes that can fit in the provider column
// (Note: By default considering the hidden lanes for overlapping events button to avoid complexity in calculations)
export const getProviderColumnLaneCount = (providerColumnWidth: number, hasHiddenLanes = true): number => {
  const laneTotalWidth = EVENT_CARD_MIN_WIDTH + EVENT_CARD_GAP;
  const overlappingEventsButtonSpace = hasHiddenLanes ? OVERLAPPING_EVENTS_BUTTON_TOTAL_SPACE : 0;

  return Math.floor((providerColumnWidth - overlappingEventsButtonSpace + EVENT_CARD_GAP) / laneTotalWidth);
};

/**
 * Get the CSS for the event card in the vertical view to place it in the correct lane of the provider column.
 * @param providerColumnVisibleLaneCount - The number of lanes visible in the provider column.
 * @param eventGroupLaneCount - The number of lanes in the event group.
 * @param laneIndex - Event card lane index.
 * @returns {React.CSSProperties} - Returns the CSS properties for the event card.
 */
export const getEventCardCssForVerticalView = (
  providerColumnVisibleLaneCount: number,
  eventGroupLaneCount: number,
  laneIndex: number
): Interpolation => {
  const hasHiddenLanes = eventGroupLaneCount > providerColumnVisibleLaneCount;
  const maxLaneCount = Math.min(providerColumnVisibleLaneCount, eventGroupLaneCount);
  const overlappingEventsButtonSpace = hasHiddenLanes ? OVERLAPPING_EVENTS_BUTTON_TOTAL_SPACE : 0;

  // this calculation is similar to the one in the getProviderColumnLaneCount function
  // but here we just remove EVENT_CARD_GAP from the width calculation
  const itemWidth = `calc((100% - ${overlappingEventsButtonSpace}px + ${EVENT_CARD_GAP}px) / ${maxLaneCount} - ${EVENT_CARD_GAP}px)`;

  // here we were moving the event card to the left based on the lane index
  const itemLeft = `calc(((100% - ${overlappingEventsButtonSpace}px + ${EVENT_CARD_GAP}px) / ${maxLaneCount}) * ${laneIndex})`;
  return {
    width: itemWidth,
    left: itemLeft,
  };
};

//#endregion
