import { FC, PropsWithChildren } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';
import { getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { BundleActions } from './BundleTrialActions';

interface BundleTrialHeaderProps {
  styles?: Interpolation<Theme>;
}

interface BundleTrialHeaderNamespace extends FC<PropsWithChildren<BundleTrialHeaderProps>> {
  Title: typeof BundleTitle;
  Actions: typeof BundleActions;
}

export const BundleTrialHeader: BundleTrialHeaderNamespace = ({ children, styles }) => {
  return <div css={[containerStyles, styles]}>{children}</div>;
};

export const BundleTitle = ({ bundle }: { bundle: SalesforceAccountProductBundle }) => {
  const { t } = useTranslation('bundleUpgrade');
  return (
    <div css={titleStyles}>
      <Icon name='award-small' size={24} />
      <Heading level={3}>
        {t('{{bundleName}} Bundle Free Trial', { bundleName: getUpgradeBundleDisplayName(bundle) })}
      </Heading>
    </div>
  );
};

BundleTrialHeader.Title = BundleTitle;
BundleTrialHeader.Actions = BundleActions;

const containerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
});

const titleStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
});
