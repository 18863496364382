import {
  File,
  DigitiseFormsDetails_StageDates,
} from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';

export interface LoaderData {
  show: boolean;
  message?: string;
}

export enum DigitizationStep {
  UPLOAD_FORMS,
  IN_PROGRESS,
  COMPLETED,
}

export interface FormDigitizationStoreState {
  locationId: string;
  loader: LoaderData;

  canUploadForms: boolean;
  isLoadingCanUploadForms: boolean;

  isLoadingUploadedForms: boolean;
  latestProcessStageDates: DigitiseFormsDetails_StageDates;
  latestProcessUploadedForms: File[];
  olderUploadedForms: File[];

  showOlderForms: boolean;

  isFormInDigitizationProcess: boolean;
  canWeStillUploadForms: boolean;

  checkForDigitizationFee: boolean;
  isCheckingForDigitizationFee: boolean;
  isUpdatingCheckForDigitizationFee: boolean;

  isUpdatingFormSubmitToggleStatus: boolean;

  // temp state will be removed in upcoming PR
  isUploadedFormsToggleOn: boolean;
  isUploadedFormsToggleLoading: boolean;
}

export interface FormDigitizationStore extends FormDigitizationStoreState {
  setLocationId: (value: string) => void;
  setLoader: (value: LoaderData) => void;

  setCanUploadForms: (value: boolean) => void;
  setIsLoadingCanUploadForms: (value: boolean) => void;

  setIsLoadingUploadedForms: (value: boolean) => void;
  setLatestProcessUploadedForms: (value: File[]) => void;
  setOlderUploadedForms: (value: File[]) => void;

  setShowOlderForms: (value: boolean) => void;

  removeUploadedForms: (formIds: string[]) => void;

  initLatestProcessStageDates: (value: DigitiseFormsDetails_StageDates) => void;
  resetLatestProcessStageDates: () => void;

  setIsUpdatingFormSubmitToggleStatus: (value: boolean) => void;

  setIsFormInDigitizationProcess: (value: boolean) => void;
  setCanWeStillUploadForms: (value: boolean) => void;
  setCheckForDigitizationFee: (value: boolean) => void;
  setIsCheckingForDigitizationFee: (value: boolean) => void;
  setIsUpdatingCheckForDigitizationFee: (value: boolean) => void;

  setIsUploadedFormsToggleOn: (value: boolean) => void;
  setIsUploadedFormsToggleLoading: (value: boolean) => void;
  getDigitizationStoreState: () => Pick<
    FormDigitizationStoreState,
    'locationId' | 'latestProcessUploadedForms' | 'canWeStillUploadForms'
  >;

  resetDigitizationStore: () => void;
}
