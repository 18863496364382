import { FC, Fragment, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { FloatingNode, FloatingPortal, useFloatingNodeId } from '@floating-ui/react';
import { ErrorBoundaryProps } from '@sentry/react';
import { Navigate } from '@tanstack/react-location';
import {
  CreateTaskEventRequest,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { OnboardingModulesApi, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { SkipLinks } from '@frontend/accessibility';
import { getUser, hasSchemaACL, isWeaveUser } from '@frontend/auth-helpers';
import { BillingAlertGlobalBanner } from '@frontend/billing-alerts';
import { PageFrame } from '@frontend/components';
import { useDevToolsStore } from '@frontend/devtools';
import { DialpadManagerProvider } from '@frontend/dialpad';
import { ErrorBoundary, MainBoundary, NavBarBoundary, TopBarBoundary } from '@frontend/error-boundary';
import { LargeDownloads, largeDownloadsRef } from '@frontend/file-download-helper';
import { GlobalBannerDynamic, GlobalBannerManager, GlobalBannerProvider } from '@frontend/global-info-banner';
import { MessagingComplianceBanners } from '@frontend/messaging-compliance';
import { MiniChatComponents, MiniChatHooks } from '@frontend/mini-chat';
import { NotificationPreferencesProvider, NotificationProvider, NotificationQueue } from '@frontend/notifications';
import { PanelLayout } from '@frontend/panel-engine';
import { useCallPopDemo } from '@frontend/phone';
import { CallPopNotification } from '@frontend/pop';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { IpcShellProvider } from '@frontend/shell-utils';
import { SyncAppAuthAlertBanner } from '@frontend/sync-app-auth';
import { TaskTraySidePanel } from '@frontend/task-tray';
import { TeamChatProvider, TeamChatWrapper, ChatPanel, useTeamChatStore } from '@frontend/team-chat';
import { TeamChatProvider as TeamChatV2RemasteredProvider, TeamChatComponents } from '@frontend/team-chat-new';
import { TourGuideComponent } from '@frontend/tour-guide';
import { sentry } from '@frontend/tracking';
import { WeaveLoader } from '@frontend/weave-loader';
import { SoftphoneManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, Text } from '@frontend/design-system';
import { GlobalNav, IntakeFormNav, NavContextProvider } from '../layout';
import { AppStatusProvider, useAppStatus } from '../providers/app-status.provider';
import { useApp } from '../providers/app.provider';
import { sharedQueryCache } from '../utils/query-cache';
import { WebsocketSubscriptionsProvider } from '../websocket-subscriptions/websocket-subscriptions-provider';
import { CommandPalette } from './command-palette';
import { DevTools } from './devtools';
import { InfoModal } from './info-modal';
import { LocationPickerPage } from './location-pickers/location-picker-page';
import { ResponsiveSlidePanel } from './responsive-slide-panel';
import { SalesDemoFeatureModal } from './sales-demo-feature-modal';
import { SettingsModal } from './settings/settings-modal';
import { StackErrorPage } from './stack-error-page';
import { SimpleTopBar, TopBar } from './top-bar';
import { RibbonManager } from './top-bar-ribbons/ribbon-manager';
import { AppStateType, useInitializeApp } from './use-initialize-app';

const ConditionalPageFrame: FC<PropsWithChildren<{ hasPageFrame: boolean }>> = ({ children, hasPageFrame }) =>
  hasPageFrame ? <PageFrame>{children}</PageFrame> : <>{children}</>;

const Nav = () => {
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');

  return isShowIntakeForm ? <IntakeFormNav /> : <GlobalNav />;
};

export const AuthenticatedAppWrapper = ({ children }: { children: ReactNode }) => {
  const appState = useInitializeApp();

  return (
    <ErrorBoundary fallback={MainBoundary}>
      <AuthenticatedApp appState={appState}>{children}</AuthenticatedApp>
    </ErrorBoundary>
  );
};

const queryClient = new QueryClient({
  queryCache: sharedQueryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});

const AuthenticatedApp = ({ children, appState }: { children: ReactNode; appState: AppStateType }) => {
  const { getFeatureFlagValue, getCustomizationFlagValue } = useScopedAppFlagStore();
  const user = getUser();
  const weaveUser = isWeaveUser();
  const hasChatCustomizationFlag = getCustomizationFlagValue('chat');
  const isTeamChatV2Remastered = getFeatureFlagValue('team-chat-2-remastered');
  const hasActionBar = useMatchMedia({ minWidth: breakpoints.small.min });
  const [showReset, setShowReset] = useState(false);
  const shouldHaveChat = hasChatCustomizationFlag && user && !weaveUser;

  useEffect(() => {
    if (appState.state === 'LOADING' || appState.state === 'REDIRECTING') {
      setTimeout(() => {
        setShowReset(true);
      }, 10000);
    } else {
      setShowReset(false);
    }
  }, [appState]);

  if (appState.state === 'LOADING' || appState.state === 'REDIRECTING') {
    return (
      <WeaveLoader containerCss={{ flexDirection: 'column', gap: theme.spacing(2) }}>
        {showReset && (
          <>
            <Text>Not loading? Try resetting the app.</Text>
            <Button
              size='large'
              variant='secondary'
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.replace('/sign-in');
              }}
            >
              Reset
            </Button>
          </>
        )}
      </WeaveLoader>
    );
  } else if (appState.state === 'ONBOARDING') {
    return (
      <>
        <ContentLoader show />
        <Navigate to={appState.meta.onboardingRedirectUrl} replace />
      </>
    );
  } else if (appState.state === 'LOCATION_SELECTION') {
    /**
     * Before this point, the actual app (Providers and content) has not been rendered yet.
     * Once we have initialized location data, we can proceed with rendering the app.
     *
     */
    const Provider = isTeamChatV2Remastered ? Fragment : TeamChatProvider;
    return (
      <Provider>
        <div
          css={(theme) => ({
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            height: `calc(100% - ${theme.heightOffset}px)`,
            overflow: 'auto',
          })}
        >
          <SimpleTopBar />
          <LocationPickerPage />
        </div>
      </Provider>
    );
  }

  /**
   * We wrap the Main content and Settings modal in a different query client provider so that we can use default settings for those components,
   * versus the one used in the top level of the app.
   *
   * The difference is that we turn on the error boundary for the top level components, but team-implemented pages will not trigger the error boundary from a React Query perspective.
   * This will change in the future, but we want to ensure that teams handle their errors before turning on the error boundary for all components.
   */

  return (
    <>
      <GlobalBannerProvider>
        <AppProviders>
          <TopBarContainer />
          <RibbonManager />
          <GlobalBannerSystem />
          <div
            id='main-container'
            style={{
              display: 'flex',
              overflow: 'hidden',
              height: '100%',
              position: 'relative',
            }}
          >
            <div
              id='app-container'
              style={{
                width: hasActionBar ? 'calc(100% - 57px)' : '100%',
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                position: 'relative',
              }}
            >
              <ErrorBoundary fallback={NavBarBoundary}>
                <Nav />
              </ErrorBoundary>
              <ConditionalPageFrame hasPageFrame={true}>
                <QueryClientProvider client={queryClient}>
                  <Main>
                    {appState.state === 'INITIALIZED' ? (
                      <ErrorBoundary
                        beforeCapture={(scope) => {
                          scope.setTag('topic', 'errorBoundary');
                          scope.setLevel('fatal');
                        }}
                        fallback={(props) => <StackErrorPage {...props} />}
                      >
                        {children}
                      </ErrorBoundary>
                    ) : null}
                  </Main>
                </QueryClientProvider>
              </ConditionalPageFrame>
              <ErrorBoundary>
                <ResponsiveSlidePanel />
                <PanelLayout />
              </ErrorBoundary>
              <ErrorBoundary>
                <TaskTraySidePanel />
                {shouldHaveChat ? isTeamChatV2Remastered ? <TeamChatComponents.ChatPanel /> : <ChatPanel /> : null}
              </ErrorBoundary>
            </div>
          </div>
          <QueryClientProvider client={queryClient}>
            <Settings appState={appState} />
          </QueryClientProvider>
        </AppProviders>
        <Miscellaneous />
      </GlobalBannerProvider>
    </>
  );
};

const TopBarContainer = () => {
  return (
    <ErrorBoundary fallback={TopBarBoundary}>
      <TopBar />
    </ErrorBoundary>
  );
};

const AppProviders = ({ children }: { children: ReactNode }) => {
  const { setShouldPreventUpdate } = useApp();
  const showTools = hasSchemaACL('weave', Permission.DEV_TOOLS) || import.meta.env.MODE === 'development';
  const user = getUser();

  const onActiveCall = useCallback(() => {
    setShouldPreventUpdate(true);
  }, []);
  const onAllCallsEnded = useCallback(() => {
    setShouldPreventUpdate(false);
  }, []);

  return (
    <NotificationPreferencesProvider
      persistOptions={{
        name: `notification-preferences-${user?.userID}`,
        //@ts-ignore
        //TODO: Remove `ts-ignore` when we update to the latest version of `zustand`
        partialize: (state) => ({
          pausedNotificationTypes: state.pausedNotificationTypes,
          dismissedPreferences: state.dismissedPreferences,
        }),
      }}
    >
      <IpcShellProvider context='main'>
        <NotificationProvider>
          <DialpadManagerProvider>
            <SoftphoneManager onAllCallsEnded={onAllCallsEnded} onCallIsActive={onActiveCall}>
              <WebsocketSubscriptionsProvider>
                <AppStatusProvider>
                  <SkipLinks />
                  <NavContextProvider>
                    {/* Adding a provider here so that new chat panel could have a provider. But the old chat
                  provider continue using the ChatStrategyProvider. When we migrate to the new chat we will
                  remove the old chat strategy provider. Hence conditionally using the providers. */}
                    <TeamChatV2ProviderWithErrorBoundary>{children}</TeamChatV2ProviderWithErrorBoundary>
                  </NavContextProvider>
                </AppStatusProvider>
              </WebsocketSubscriptionsProvider>
            </SoftphoneManager>
          </DialpadManagerProvider>
          <NotificationGutter>
            <CallPopNotification />
            <NotificationQueue />
          </NotificationGutter>
          {showTools && <DevTools />}
        </NotificationProvider>
      </IpcShellProvider>
    </NotificationPreferencesProvider>
  );
};

const Miscellaneous = () => {
  const showTools = hasSchemaACL('weave', Permission.DEV_TOOLS) || import.meta.env.MODE === 'development';

  const tourGuideActionHandler = useCallback(
    (eventInfo: CreateTaskEventRequest, taskType?: OnboardingModulesTypes.TaskType) => {
      const isCompleteTaskEvent = eventInfo.eventType === EventType.EVENT_COMPLETE_TASK;
      const isLocationBasedTask = taskType === OnboardingModulesTypes.TaskType.Location;

      if (isCompleteTaskEvent && isLocationBasedTask) {
        OnboardingModulesApi.markLocationTaskCompleted(
          eventInfo.taskId,
          OnboardingModulesTypes.TaskCompletionType.Modules
        );
      }

      OnboardingModulesApi.sendTaskEvent(eventInfo);
    },
    []
  );

  return (
    <>
      {showTools && <CommandPalette />}
      <LargeDownloads ref={largeDownloadsRef} />
      <InfoModal />
      <TourGuideComponent.TourGuide onAction={tourGuideActionHandler} />
      {<SalesDemoFeatureModal />}
    </>
  );
};

const Settings = ({ appState }: { appState: AppStateType }) => {
  return appState.state === 'INITIALIZED' ? <SettingsModal /> : null;
};

const Main = ({ children }: { children: ReactNode }) => {
  useCallPopDemo();
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const { onErrorBoundaryTriggered } = MiniChatHooks.useMiniChatErrorBoundaryHandlers();

  const handleMiniChatErrorScope = useCallback<NonNullable<ErrorBoundaryProps['beforeCapture']>>(
    (scope) => {
      onErrorBoundaryTriggered();
      scope.setTag('topic', 'messages');
      scope.setLevel('fatal');
      scope.setTag('feature', 'mini-chat');
    },
    [onErrorBoundaryTriggered]
  );

  return (
    <>
      <main
        id='main-content'
        tabIndex={0}
        css={css`
          width: 100%;
          overflow-y: auto;
          position: relative;

          // min-width: 0 prevents inner flex components from causing this to overflow its bounds
          // https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
          min-width: 0;
        `}
      >
        {children}
        {/* Don't use a fallback here since it doesn't make sense in the UI */}
        <ErrorBoundary beforeCapture={handleMiniChatErrorScope}>
          {!isMobile && <MiniChatComponents.MiniChatManager />}
        </ErrorBoundary>
      </main>
      {/* Don't use a fallback here since it doesn't make sense in the UI */}
      <ErrorBoundary beforeCapture={handleMiniChatErrorScope}>
        {isMobile && <MiniChatComponents.MobileMiniChatManager />}
      </ErrorBoundary>
    </>
  );
};

const NotificationGutter = ({ children }: { children: ReactNode }) => {
  const nodeId = useFloatingNodeId();
  return (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: theme.zIndex.alerts,
          }}
        >
          {children}
        </div>
      </FloatingPortal>
    </FloatingNode>
  );
};

const TeamChatV2ProviderWithErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const { getFeatureFlagValue, getCustomizationFlagValue } = useScopedAppFlagStore();
  const hasChatCustomizationFlag = getCustomizationFlagValue('chat');
  const isTeamChatv2Remastered = getFeatureFlagValue('team-chat-2-remastered');
  const { selectedOrgId } = useAppScopeStore();
  const { teamChat: teamChatStatus } = useAppStatus();

  const user = getUser();
  const weaveUser = isWeaveUser();

  if (!hasChatCustomizationFlag || !user || weaveUser) {
    return <>{children}</>;
  }

  if (!isTeamChatv2Remastered) {
    return (
      <TeamChatProvider>
        <TeamChatV2LegacyWrapper>{children}</TeamChatV2LegacyWrapper>
      </TeamChatProvider>
    );
  }

  return (
    <ErrorBoundary
      key={teamChatStatus.key}
      onError={(error, componentStack, eventId) => {
        const errorMessage =
          error && typeof error === 'object' && 'toString' in error ? error.toString() : 'An error occurred';
        teamChatStatus.updateStatus('error', errorMessage);
        console.error('Error in TeamChatV2ProviderWithErrorBoundary', error, componentStack, eventId);
        sentry.error({
          error,
          topic: 'team-chat',
        });
      }}
      fallback={<>{children}</>}
    >
      <TeamChatV2RemasteredProvider
        api='stream'
        orgId={selectedOrgId}
        userId={user.userID}
        key={'team-chat-' + selectedOrgId}
      >
        {children}
      </TeamChatV2RemasteredProvider>
    </ErrorBoundary>
  );
};

const TeamChatV2LegacyWrapper = ({ children }: { children: ReactNode }) => {
  const { setErrorObject } = useTeamChatStore(['setErrorObject']);
  return (
    <ErrorBoundary
      onError={(error, componentStack, eventId) => {
        //TODO: @gisheri - communicate error to app-status provider
        console.error('Error in TeamChatProvider', error, componentStack, eventId);
        sentry.error({
          error,
          topic: 'team-chat',
        });
        setErrorObject(error as Error, componentStack, eventId);
      }}
      fallback={<>{children}</>}
    >
      <TeamChatWrapper>{children}</TeamChatWrapper>
    </ErrorBoundary>
  );
};

const GlobalBannerSystem = () => {
  const {
    options: { isDebugModeOn },
  } = useDevToolsStore();

  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');

  return (
    <>
      <GlobalBannerManager />
      <BillingAlertGlobalBanner />
      <SyncAppAuthAlertBanner />
      {!isShowIntakeForm && <MessagingComplianceBanners.BusinessInformationBanner />}
      {isDebugModeOn && <ExampleGlobalBanners />}
    </>
  );
};

//This is temporary, as an example
const ExampleGlobalBanners = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      {show && (
        <GlobalBannerDynamic
          id='example-id'
          type='error'
          title='Example'
          message="Whoops! Our cats knocked over the server while chasing a laser pointer. We're cleaning up the mess now."
          action={{
            label: 'Do Something',
            onClick: () => console.log('Whoops'),
          }}
          onDismiss={() => setShow(false)}
        />
      )}
      {/**
       * GlobalBannerDynamic components can be placed anywhere in the app! The moment they get rendered, they'll subscribe to the global banner system
       */}
      <GlobalBannerDynamic
        id='another-example-id'
        type='warning'
        title='Another Example'
        message="Whoops! Our cats knocked over the server while chasing a laser pointer. We're cleaning up the mess now."
        action={{
          label: 'Do Something',
          onClick: () => console.log('Whoops'),
        }}
        isDismissible={false}
      />
    </>
  );
};
