import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Button } from '@frontend/design-system';

interface Props {
  label?: string;
  onClick?: () => void;
  trackingIdBase?: string;
}

export const ShowMoreButton = ({ label, onClick, trackingIdBase = '' }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.showMoreWrapper}>
      <Button iconName='caret-right' onClick={onClick} trackingId={`${trackingIdBase}-show-more`} variant='tertiary'>
        {label ?? t('Show More')}
      </Button>
    </div>
  );
};

const styles = {
  showMoreWrapper: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > button > span {
      flex-direction: row-reverse;
    }
  `,
};
