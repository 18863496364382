import { memo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import HoldMusicAPI from '@frontend/api-hold-music';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore, WeaveLocation, WeaveLocationGroup } from '@frontend/scope';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentLoader,
  Heading,
  IconButton,
  ModalControlModalProps,
  NakedUl,
  RadioField,
  Text,
  TextLink,
  Tray,
  styles,
  useAlert,
  useFormField,
  useModalControl,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { buildAudioLibraryPath } from '../../utils/media-path';
import { AudioPicker } from '../audio-picker/audio-picker';
import { CachedAudioScrubber } from '../common/cached-audio-scrubber';
import { SettingsCard } from '../common/settings-card';
import { LocationHoldMusic, LocationHoldMusicUpdateRequest } from './types';

export const HoldMusicLocationList = ({
  tenantLocation,
  locationHoldMusic,
}: {
  tenantLocation: WeaveLocation | WeaveLocationGroup;
  locationHoldMusic: LocationHoldMusic[];
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps } = useModalControl();
  const { getScopeName } = useAppScopeStore();
  const [trayData, setTrayData] = useState<{
    label: string;
    type: 'single' | 'multi';
    mediaId: string;
    locationIds: string[];
  }>({
    label: '',
    type: 'single',
    mediaId: '',
    locationIds: [],
  });

  if (!locationHoldMusic.length) {
    return (
      <Text size='medium' color='subdued'>
        {t('No hold music set for this phone tenant.')}
      </Text>
    );
  }

  return (
    <>
      <NakedUl css={{ maxWidth: 700, display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        {locationHoldMusic.map((item) => (
          <li
            key={item.locationId}
            onClickCapture={() => {
              setTrayData({
                label: getScopeName(item.locationId),
                type: 'single',
                mediaId: item.mediaItemId,
                locationIds: [item.locationId],
              });
            }}
          >
            <SettingsCard>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: locationHoldMusic.length > 1 ? '120px 140px 240px 1fr' : '140px 240px 1fr',
                  alignItems: 'center',
                  gap: theme.spacing(2),
                }}
              >
                {locationHoldMusic.length > 1 && (
                  <Chip.SingleChip css={{ justifyContent: 'start' }}>{getScopeName(item.locationId)}</Chip.SingleChip>
                )}
                <div css={styles.truncate}>
                  <Text size='medium' color='subdued'>
                    {t('Track Title')}
                  </Text>
                  <Text css={styles.truncate} size='large'>
                    {item.name}
                  </Text>
                </div>
                <div>
                  <CachedAudioScrubber
                    singlePlayer
                    filePath={buildAudioLibraryPath({
                      media: {
                        id: item.mediaItemId,
                        isGlobal: false,
                        path: item.downloadUrl,
                      },
                    })}
                    mediaId={item.mediaItemId}
                  />
                </div>
                <TextLink
                  css={{ justifySelf: 'end' }}
                  weight='bold'
                  onClick={() => {
                    triggerProps.onClick();
                  }}
                >
                  {t('Manage')}
                </TextLink>
              </div>
            </SettingsCard>
          </li>
        ))}
      </NakedUl>
      <ManageHoldMusicTray
        key={trayData.label}
        tenantLocation={tenantLocation}
        modalProps={modalProps}
        data={trayData}
      />
    </>
  );
};

export const ManageHoldMusicTray = memo(
  ({
    tenantLocation,
    modalProps,
    data,
  }: {
    tenantLocation: WeaveLocation | WeaveLocationGroup;
    modalProps: ModalControlModalProps;
    data: { label: string; type: 'single' | 'multi'; mediaId?: string; locationIds: string[] };
  }) => {
    const { t } = useTranslation('phone');
    const alerts = useAlert();
    const queryClient = useQueryClient();

    const { data: standardMedia = [], isLoading } = useQuery({
      // No need for tenantId here
      queryKey: queryKeys.settings.listSystemMedia(),
      queryFn: () => {
        return HoldMusicAPI.ListSystem({});
      },
      select: (data) =>
        data?.systemMedia?.map((media) => ({
          id: media.mediaItemId,
          name: media.name,
          path: media.downloadUrl,
        })),
    });

    const update = useMutation({
      mutationFn: (payload: LocationHoldMusicUpdateRequest) => {
        return HoldMusicAPI.Update(payload);
      },
      onSuccess: (_data, payload) => {
        alerts.success(t('Hold Music successfully updated.'));
        pendo.track('phone-settings-hold-music-updated', {
          mediaType: payload.system ? 'system' : 'custom',
          mediaId: payload.mediaItemId,
        });
      },
      onError: () => {
        alerts.error(t('System failed to update Hold Music. Please try again.'));
      },
      onSettled: () => {
        queryClient.invalidateQueries([tenantLocation.phoneTenantId, ...queryKeys.settings.listLocationHoldMusic()]);
        modalProps.onClose();
      },
    });

    const targetStandardMediaItem = data?.mediaId ? standardMedia.find((item) => item.id === data?.mediaId) : undefined;
    let fieldValues: { radio: string | undefined; picker: string | undefined } = {
      radio: undefined,
      picker: undefined,
    };
    if (data.mediaId !== 'none') {
      fieldValues = targetStandardMediaItem
        ? {
            radio: targetStandardMediaItem.id,
            picker: undefined,
          }
        : { radio: 'custom', picker: data?.mediaId || '' };
    }

    const field = useFormField({ type: 'radio', value: fieldValues.radio }, [fieldValues.radio]);
    const audioPickerField = useFormField({ type: 'dropdown', value: fieldValues.picker }, [fieldValues.picker]);

    let chip;
    if (data?.type === 'multi') {
      chip = <Chip.MultiChip>{data.label}</Chip.MultiChip>;
    } else if (data?.type === 'single') {
      chip = <Chip.SingleChip>{data.label}</Chip.SingleChip>;
    }

    return (
      <Tray mountTarget='[data-settings-modal-content]' {...modalProps} width='xlarge'>
        <Tray.Header
          css={{ alignItems: 'start' }}
          Buttons={
            <>
              <IconButton label='close' onClick={modalProps.onClose}>
                <Icon name='x' />
              </IconButton>
            </>
          }
        >
          <div>
            <Heading level={2}>{t('Manage Hold Music')}</Heading>
            {chip}
            <Text color='subdued'>{t('Select the music that will play when your callers are on hold.')}</Text>
          </div>
        </Tray.Header>
        <Tray.Body>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '60% 40%',
              borderBottom: `1px solid ${theme.colors.neutral20}`,
              padding: theme.spacing(2),
              alignItems: 'center',
            }}
          >
            <Text size='medium' color='subdued'>
              {t('Track Title')}
            </Text>
            <Text size='medium' color='subdued'>
              {t('Audio')}
            </Text>
          </div>
          {isLoading ? (
            <ContentLoader show message={t('Loading Audio Files')} />
          ) : (
            <RadioField {...field} name='hold-music' label=''>
              {/* Standard Media */}
              <>
                {standardMedia.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '60% 240px',
                      borderBottom: `1px solid ${theme.colors.neutral20}`,
                      padding: theme.spacing(1, 2),
                      alignItems: 'center',
                    }}
                  >
                    <RadioField.Radio css={{ margin: 0 }} value={item.id}>
                      {item.name}
                    </RadioField.Radio>

                    <div>
                      <CachedAudioScrubber
                        filePath={buildAudioLibraryPath({
                          media: { id: item.id, path: item.path, isGlobal: true },
                        })}
                        mediaId={item.id}
                      />
                    </div>
                  </div>
                ))}
              </>
              {/* Custom Media - Audio Picker */}
              <div
                key={'custom'}
                style={{
                  display: 'grid',
                  gridTemplateRows: 'repeat(auto-fit, 40px)',
                  gridTemplateColumns: '60% 240px',
                  gridTemplateAreas: field.value === 'custom' ? '"radio ." "picker picker"' : '"radio ."',
                  rowGap: theme.spacing(1),
                  borderBottom: `1px solid ${theme.colors.neutral20}`,
                  padding: theme.spacing(1, 2),
                  alignItems: 'center',
                }}
              >
                <RadioField.Radio css={{ margin: 0, gridArea: 'radio' }} value='custom'>
                  {t('Custom Audio File')}
                </RadioField.Radio>

                <div
                  style={{
                    gridArea: 'picker',
                    // width of radio + margin
                    paddingLeft: 28,
                    display: field.value === 'custom' ? 'block' : 'none',
                  }}
                >
                  <AudioPicker
                    widths={{ field: '200px', scrubber: '240px' }}
                    allowedOptions={{
                      standard: false,
                      custom: true,
                      mailbox: false,
                      add: true,
                    }}
                    name='audio-picker'
                    field={audioPickerField}
                    tenantId={tenantLocation.phoneTenantId ?? ''}
                    css={{ justifyContent: 'space-between' }}
                  />
                </div>
              </div>
            </RadioField>
          )}
        </Tray.Body>
        <Tray.Actions
          onPrimaryClick={() => {
            if (!field.value) {
              return;
            }

            const payload: LocationHoldMusicUpdateRequest = {
              locationIds: data.locationIds,
              tenantId: tenantLocation.phoneTenantId ?? '',
              mediaItemId: field.value === 'custom' ? audioPickerField.value : field.value,
              system: field.value !== 'custom',
            };
            update.mutate(payload);
          }}
          disabledPrimary={!field.value}
          primaryLabel={t('Save')}
          onSecondaryClick={modalProps.onClose}
          secondaryLabel={t('Cancel')}
        />
      </Tray>
    );
  }
);
