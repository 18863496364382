import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Link } from '@tanstack/react-location';
import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { UsersQueries } from '@frontend/api-users';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Accordion, TextLink, useModalControl, useAlert, SkeletonLoader } from '@frontend/design-system';
import { useCheckPaymentsEnabled, useSchedulingLocationInfo } from '../../../../../../hooks';
import { getFullName } from '../../../../../../utils';
import { ScheduleRequestContextProvider } from '../Context/ScheduleRequestContext';
import { ScheduleRequestModalContextType } from '../Context/ScheduleRequestModalContext';
import { useGetScheduleRequestInfiniteListQuery } from '../hooks/useGetScheduleRequestInfiniteListQuery';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { ScheduleRequestDetails } from './ScheduleRequestDetails';
import { ScheduleRequestFormManager } from './ScheduleRequestFormManager';
import { ScheduleRequestListItemHeader } from './ScheduleRequestListItemHeader';

interface ScheduleRequestListProps {
  locationId: string;
  height?: number | string;
}

const FOOTER_HEIGHT = 53;

export const ScheduleRequestList = ({ locationId, height }: ScheduleRequestListProps) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarActions');

  const [selectedScheduleRequest, setSelectedScheduleRequest] = useState<ScheduleRequest | null>(null);
  const scheduleRequestModalProps = useModalControl();

  const { isMultiLocation } = useSchedulingLocationInfo();
  const { data: locationUsers } = UsersQueries.useGetUsers({
    params: isMultiLocation ? { showChildren: true } : {},
    opts: {
      cacheTime: 10 * 60 * 1000, // 10 mins
      staleTime: 10 * 60 * 1000, // 10 mins
    },
  });

  const scheduleRequestListQuery = useGetScheduleRequestInfiniteListQuery(locationId);

  const { isPaymentsEnabled } = useCheckPaymentsEnabled(locationId);

  const providerValue = useMemo(
    () => ({ locationUsers, refetchScheduleRequestList: () => scheduleRequestListQuery.refetch() }),
    [locationUsers, scheduleRequestListQuery.refetch]
  );

  const handleCloseScheduleRequestModal = () => {
    scheduleRequestModalProps.closeModal();
    setSelectedScheduleRequest(null);
  };

  const scheduleRequestModalContextValue = useMemo<ScheduleRequestModalContextType>(
    () => ({
      scheduleRequestModalProps,
      selectedScheduleRequest: selectedScheduleRequest as ScheduleRequest,
      handleCloseScheduleRequestModal,
      refetchScheduleRequestList: () => scheduleRequestListQuery.refetch(),
      isPaymentsEnabled,
    }),
    [selectedScheduleRequest, scheduleRequestListQuery.refetch, isPaymentsEnabled]
  );

  useEffect(() => {
    if (scheduleRequestListQuery.isError) {
      alert.error(t('Failed to load schedule request list'));
    }
  }, [scheduleRequestListQuery.isError]);

  const isEmptyScheduleRequestList = !scheduleRequestListQuery?.data?.pages?.[0]?.rows?.length;

  return (
    <>
      <ScheduleRequestContextProvider value={providerValue}>
        <Accordion
          css={{ ...(isEmptyScheduleRequestList && { background: theme.colors.white }), flexGrow: 1 }}
          variant='blank'
          chevronSize={16}
          startingValue=''
          size='large'
        >
          <InfinitePaginatedList
            infiniteQueryProps={scheduleRequestListQuery}
            height={typeof height === 'number' ? height - FOOTER_HEIGHT : height}
            skeletonLoader={
              <SkeletonLoader
                height={50}
                width={`calc(100% - ${theme.spacing(4)})`}
                css={{ margin: theme.spacing(2) }}
              />
            }
            emptyStateConfig={{
              type: 'schedule_requests',
              description: t('No New Requests'),
              header: '',
            }}
            renderListItem={({ listItem }) => {
              return (
                <Accordion.Item
                  trackingId={ScheduleRequestsTrackingIds.requestListItemAccordion}
                  style={{ borderBottom: `1px solid ${theme.colors.neutral10}` }}
                  value={listItem?.id ?? ''}
                  key={listItem?.id ?? ''}
                >
                  <Accordion.Header>
                    <ScheduleRequestListItemHeader
                      name={getFullName(listItem.schedulee)}
                      personId={listItem.schedulee?.weavePersonId}
                      appointmentTime={listItem?.dateTime ?? ''}
                      personPhone={listItem?.schedulee?.phoneNumber ?? ''}
                      paidAmount={isPaymentsEnabled && listItem?.bookingAmount ? listItem?.bookingAmount : 0}
                      locationId={listItem.locationId ?? ''}
                      updateSelectedScheduleRequest={() => {
                        setSelectedScheduleRequest(listItem);
                        scheduleRequestModalProps.openModal();
                      }}
                      scheduleRequestId={listItem.id ?? ''}
                      css={{ borderBottom: `1px solid ${theme.colors.neutral40}` }}
                    />
                  </Accordion.Header>

                  <Accordion.Body>
                    <ScheduleRequestDetails
                      scheduleRequestId={listItem.id ?? ''}
                      locationId={listItem.locationId ?? ''}
                      isPaymentsEnabled={isPaymentsEnabled}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              );
            }}
          />
        </Accordion>
      </ScheduleRequestContextProvider>
      {selectedScheduleRequest && (
        <ScheduleRequestFormManager
          scheduleRequestModalContextValue={scheduleRequestModalContextValue}
          selectedScheduleRequest={selectedScheduleRequest}
        />
      )}
      <div css={viewHistoryContainerStyles}>
        <TextLink
          as={Link}
          css={{ display: 'flex', alignItems: 'center' }}
          size='medium'
          weight='bold'
          to={`/schedule/request-history?locationId=${locationId}`}
          trackingId={ScheduleRequestsTrackingIds.viewHistoryBtn}
        >
          <Icon name='external-link-small' css={{ marginRight: theme.spacing(0.5) }} />
          {t('View History')}
        </TextLink>
      </div>
    </>
  );
};

const viewHistoryContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background: theme.colors.white,
  borderTop: `1px solid ${theme.colors.neutral10}`,
});
