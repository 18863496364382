import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { stepValueStyle } from './styles';

type Props = {
  count: number;
};

export const RecipientsStepValue = ({ count }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');

  return (
    <div css={stepValueStyle}>
      {!count ? (
        <Text weight='bold'>{t('Select Recipients')}</Text>
      ) : (
        <Text weight='bold'>{t('{{count}} Recipients', { count })}</Text>
      )}
    </div>
  );
};
