import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Button, Heading, Text } from '@frontend/design-system';

interface ProviderAppointmentTypeMappingInstructionsProps extends CommonHTMLAttributes {
  locationId?: string;
  isModalContent?: boolean;
}

export const ProviderAppointmentTypeMappingInstructions = ({
  locationId,
  isModalContent,
  ...props
}: ProviderAppointmentTypeMappingInstructionsProps) => {
  const { t } = useTranslation('schedule');

  const { navigate: settingsNavigate } = useSettingsNavigate();

  const handleStartMappingClick = () => {
    if (locationId) {
      settingsNavigate({
        to: '/schedule/appointment-types/:locationId',
        params: {
          locationId,
        },
      });
    } else {
      settingsNavigate({
        to: '/schedule/appointment-types',
      });
    }
  };

  return (
    <div {...props}>
      {!isModalContent && (
        <section css={{ marginBottom: theme.spacing(3) }}>
          <Heading level={3}>{t('Connect Providers & Appointments')}</Heading>
          <Text size='medium' css={{ color: theme.colors.neutral70 }}>
            ⏱️ {t('Est. time: 5–10 minutes')}
          </Text>
        </section>
      )}
      <section css={{ marginBottom: theme.spacing(3), paddingRight: theme.spacing(1) }}>
        <Text size='large'>
          {isModalContent
            ? t(
                "You're almost there! Before you can add the booking link to your website for this location, you'll first need to connect your providers to appointment types."
              )
            : t(
                "You're almost there! Before you can add the booking link to your website, you'll first need to connect your providers to appointment types."
              )}
        </Text>
      </section>
      <section css={{ marginBottom: theme.spacing(3) }}>
        <Text weight='bold' size='large'>
          {t('Why it matters:')}
        </Text>
        <ul css={{ paddingLeft: theme.spacing(3) }}>
          <Text as='li' size='large'>
            ✅ {t('Patients book with the right providers')}
          </Text>
          <Text as='li' size='large'>
            🗓️ {t('Accurate availability shown online')}
          </Text>
          <Text as='li' size='large'>
            😊 {t('Smoother experience for everyone')}
          </Text>
        </ul>
      </section>
      <section>
        <Text css={{ marginTop: theme.spacing(3) }} weight='bold' size='large'>
          {t('Quick Steps:')}
        </Text>
        <ol css={{ paddingLeft: theme.spacing(3) }}>
          <li>
            <Text size='large'>{t('Click “Start Mapping” below')}</Text>
          </li>
          <li>
            <Text size='large'>{t(`Find the Appointment type's row and select "Edit"`)}</Text>
          </li>
          <li>
            <Text size='large'>{t('Select available providers for that appointment type')}</Text>
          </li>
          <li>
            <Text size='large'>{t('Save your changes')}</Text>
          </li>
        </ol>
      </section>
      {!isModalContent && (
        <Button onClick={handleStartMappingClick} css={{ marginTop: theme.spacing(5) }}>
          {t('Start Mapping')}
        </Button>
      )}
    </div>
  );
};
