import { useMemo } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  formatDate,
  PopoverMenuDivider,
  PopoverMenuItem,
  PopoverMenuItemDropdown,
  useNestedPopoverMenu,
} from '@frontend/design-system';

type InvoicePopoverMenuItemsProps = {
  invoices: InvoiceModel[];
  trackingIdSuffix?: string;
  onClickSendReminder: (id: string) => void;
  onClickCollectPaymentFromInvoice: (id: string) => void;
  onClickCreateNewInvoice: () => void;
  getMenuItemProps: ReturnType<typeof useNestedPopoverMenu>['getItemProps'];
};

export const InvoicePopoverMenuItems = ({
  invoices,
  trackingIdSuffix,
  onClickSendReminder,
  onClickCollectPaymentFromInvoice,
  onClickCreateNewInvoice,
  getMenuItemProps,
}: InvoicePopoverMenuItemsProps) => {
  const { t } = useTranslation('contacts');

  const getFormattedDate = (date: string) => {
    const currentYear = new Date().getFullYear();
    const d = new Date(date);

    if (d.getFullYear() === currentYear) {
      return formatDate(date, 'MMMM D', true);
    }
    return formatDate(date, 'MMM D, YYYY', true);
  };

  const menuItems = useMemo(
    () =>
      invoices?.map((invoice, index) => (
        <PopoverMenuItemDropdown
          index={index}
          key={invoice.id}
          trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix ?? ''}`}
          prefixIcon='document'
          css={{ width: '100%' }}
          showOnHover={true}
          subMenuItems={({ menu: { getItemProps } }) => (
            <>
              <PopoverMenuItem
                {...getItemProps({
                  index: 0,
                  onClick: () => onClickSendReminder(invoice.id),
                })}
                trackingId={`${ContactActionPrefixes.Payments}:${
                  trackingIdSuffix ?? ''
                }:unpaid--invoice--send-reminder`}
                css={{ cursor: 'pointer' }}
                Icon={() => <Icon name='send' size={16} color='subdued' />}
              >
                {t('Send Reminder')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...getItemProps({
                  index: 1,
                  onClick: () => onClickCollectPaymentFromInvoice(invoice.id),
                })}
                trackingId={`${ContactActionPrefixes.Payments}:${
                  trackingIdSuffix ?? ''
                }:unpaid--invoice--send-reminder`}
                css={{ cursor: 'pointer' }}
                Icon={() => <Icon name='pay' size={16} color='subdued' />}
              >
                {t('Collect Payment')}
              </PopoverMenuItem>
            </>
          )}
        >
          <div>{getFormattedDate(invoice.billedAt)}</div>
          <div
            className='invoice__amount'
            css={css`
              font-size: ${theme.font.size.small};
              padding-right: ${theme.spacing(1)};
              margin-left: auto;
            `}
          >
            {formatCentsToCurrency(invoice.billedAmount)}
          </div>
        </PopoverMenuItemDropdown>
      )),
    [invoices, trackingIdSuffix]
  );

  return (
    <>
      <div css={{ overflowY: 'auto', maxHeight: '240px', width: '100%' }}>{menuItems}</div>
      {!!invoices.length && <PopoverMenuDivider />}
      <PopoverMenuItem
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}:unpaid--create`}
        Icon={() => <Icon name='plus' color='subdued' size={16} />}
        css={{ cursor: 'pointer' }}
        {...getMenuItemProps({
          index: invoices?.length || 0,
          onClick: onClickCreateNewInvoice,
        })}
      >
        {t('New Payment Request')}
      </PopoverMenuItem>
    </>
  );
};
