import { useMutation } from 'react-query';
import { InvoiceModel, PaymentOrigin } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useInvoicePerson } from '../../../hooks';
import { useMakePayment } from '../../hooks';

export interface UseManualCardEntryPaymentOptions {
  cardName?: string;
  receiptEmail: string;
  paymentMethod?: PaymentMethod;
  invoice?: InvoiceModel;
}

export const useManualCardEntryPayment = ({
  cardName,
  receiptEmail,
  paymentMethod,
  invoice,
}: UseManualCardEntryPaymentOptions) => {
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;
  const { personEmail: email } = useInvoicePerson(invoice);
  const amount = invoice?.billedAmount ?? 0;

  const { makePayment } = useMakePayment({
    amount,
    email,
    personId,
    locationId,
    origin: PaymentOrigin.ManualCardEntry,
    setupFutureUsage: null,
    paymentMethod,
    includeElementsInfo: true,
    invoiceId: invoice?.id,
    receiptEmail,
    cardholderName: cardName,
  });

  const { mutateAsync: makeManualCardEntryPayment, isLoading: makingManualCardEntryPayment } = useMutation({
    mutationFn: async () => makePayment(),
  });
  return { makeManualCardEntryPayment, makingManualCardEntryPayment };
};
