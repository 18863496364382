import { ReactNode, useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  CollectPaymentModalSteps,
  CollectPaymentMulti,
  CollectPaymentMultiFlow,
  CollectPaymentMultiModalInstance,
  useCollectPaymentMulti,
  PAYMENT_MULTI_STEPS,
  useSyncCollectPaymentMultiToModal,
  CollectPaymentMultiModal,
  CollectPaymentMultiPortal,
} from '@frontend/payments-collection-flow';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { usePersonActiveInvoiceList } from '@frontend/person-invoice-list';
import { PersonInvoiceModalSteps } from '@frontend/person-invoice-payment';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ActionButton,
  Button,
  PopoverMenu,
  PopoverMenuItemDropdown,
  useNestedPopoverMenu,
  useModalControl,
  ModalControlModalProps,
  ModalControlTriggerProps,
} from '@frontend/design-system';
import { ContactMenuItem } from './contact-menu-item';
import { InvoicePopoverMenuItems } from './invoice-popover-menu-items';
import { usePaymentAction } from './use-payment-action';

export type PaymentButtonProps = {
  label?: string;
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};

export const PaymentButton = ({ label, context, trackingIdSuffix }: PaymentButtonProps) => {
  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds: [context.locationId],
  });

  return showMultiProcessorCollect ? (
    <PaymentButtonMulti label={label} context={context} trackingIdSuffix={trackingIdSuffix} />
  ) : (
    <PaymentButtonLegacy label={label} context={context} trackingIdSuffix={trackingIdSuffix} />
  );
};

export const PaymentButtonMulti = ({ label, context, trackingIdSuffix }: PaymentButtonProps) => {
  const { triggerProps, modalProps } = useModalControl();
  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        label={label}
      >
        <Icon name='dollar-sign' size={16} />
      </ActionButton>
      <MultiCollectModal modalProps={modalProps} personId={context.personId} locationId={context.locationId} />
    </div>
  );
};
/**
 * @deprecated Does not use CollectPaymentMultiModalInstance or one of it's variants
 */
export const PaymentButtonLegacy = ({ label, context, trackingIdSuffix }: PaymentButtonProps) => {
  const { triggerProps, Modal } = usePaymentAction({ context });

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        label={label}
      >
        <Icon name='dollar-sign' size={16} />
      </ActionButton>
      {Modal}
    </div>
  );
};

const MultiCollectModal = ({
  modalProps,
  invoiceId,
  personId,
  locationId,
  flow,
}: {
  modalProps: ModalControlModalProps;
  invoiceId?: string;
  personId?: string;
  locationId?: string;
  flow?: CollectPaymentMultiFlow;
}) => {
  return (
    <CollectPaymentMultiModalInstance
      modalProps={modalProps}
      collectProps={{
        personId,
        invoiceId,
        locationId,
        flow: flow || (personId && !invoiceId ? 'select' : invoiceId ? 'pay' : 'create'),
      }}
    />
  );
};

export type PaymentActionProps = {
  label: string;
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};

export const PaymentAction = ({ label, context, trackingIdSuffix }: PaymentActionProps) => {
  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds: [context.locationId],
  });

  return showMultiProcessorCollect ? (
    <PaymentActionMulti label={label} context={context} trackingIdSuffix={trackingIdSuffix} />
  ) : (
    <PaymentActionLegacy label={label} context={context} trackingIdSuffix={trackingIdSuffix} />
  );
};

const PaymentActionMulti = ({ label, context, trackingIdSuffix }: PaymentActionProps) => {
  const { triggerProps, modalProps } = useModalControl();

  return (
    <>
      <AdaptoActions.Action
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        icon='dollar-sign'
        label={label}
      />
      <MultiCollectModal modalProps={modalProps} personId={context.personId} locationId={context.locationId} />
    </>
  );
};

/**
 * @deprecated Does not use CollectPaymentMultiModalInstance or one of it's variants
 */
const PaymentActionLegacy = ({ label, context, trackingIdSuffix }: PaymentActionProps) => {
  const { triggerProps: paymentTriggerProps, Modal: PaymentModal } = usePaymentAction({ context });

  return (
    <>
      <AdaptoActions.Action
        {...paymentTriggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        icon='dollar-sign'
        label={label}
      />
      {PaymentModal}
    </>
  );
};

export interface PaymentHighlightedActionProps extends PaymentActionProps {
  showPulseAnimation?: boolean;
  trackingId?: string;
  triggerProps?: ModalControlTriggerProps;
}

export const PaymentHighlightedAction = (props: PaymentHighlightedActionProps) => {
  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds: [props.context.locationId],
  });

  return showMultiProcessorCollect ? (
    <PaymentHighlightedActionMulti {...props} />
  ) : (
    <PaymentHighlightedActionLegacy {...props} />
  );
};

const PaymentHighlightedActionMulti = ({
  trackingId,
  trackingIdSuffix,
  context,
  showPulseAnimation,
  label,
}: PaymentHighlightedActionProps) => {
  const { triggerProps, modalProps } = useModalControl();

  return (
    <>
      <AdaptoActions.Action
        {...triggerProps}
        trackingId={trackingId || `${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        icon='pay'
        label={label}
        {...triggerProps}
        css={[highlightedActionStyles, showPulseAnimation && pulseAnimationStyles]}
      />
      <MultiCollectModal modalProps={modalProps} personId={context.personId} locationId={context.locationId} />
    </>
  );
};

/**
 * @deprecated Does not use CollectPaymentMultiModalInstance or one of it's variants
 * @returns
 */
const PaymentHighlightedActionLegacy = ({
  label,
  context,
  trackingIdSuffix,
  showPulseAnimation = false,
  trackingId,
  triggerProps,
}: PaymentHighlightedActionProps) => {
  const { triggerProps: paymentTriggerProps, Modal: PaymentModal } = usePaymentAction({ context });

  return (
    <>
      <AdaptoActions.Action
        {...paymentTriggerProps}
        trackingId={trackingId || `${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        icon='pay'
        label={label}
        {...triggerProps}
        css={[highlightedActionStyles, showPulseAnimation && pulseAnimationStyles]}
      />
      {PaymentModal}
    </>
  );
};

const PAYMENT_SUCCESS_TIMEOUT = 2000;

export type ContactPaymentActionProps = {
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
    associatedPersonIds?: string[];
  };
  trackingIdSuffix?: string;
};
export const ContactPaymentAction = (props: ContactPaymentActionProps) => {
  /**
   * For manual card entry, 2s isn't quiiiiittteee enough.
   * So, either change to a 3s or 4s timeout for everything, or have an optional check to query a 2nd time, if needed.
   */
  const smartRefetchOnTimeout = (attempts = 0) => {
    if (attempts > 1) {
      return;
    }

    setTimeout(async () => {
      const currentInvoiceIdsStr = invoices?.map((invoice) => invoice.id).join(',');

      const refetchedResults = await refetchInvoices();
      const updatedInvoiceIdsStr = refetchedResults
        .flatMap((query) => query?.data?.invoices || [])
        .map((invoice) => invoice.id)
        .join(',');

      if (currentInvoiceIdsStr === updatedInvoiceIdsStr) {
        smartRefetchOnTimeout(attempts + 1);
      }
    }, PAYMENT_SUCCESS_TIMEOUT);
  };

  const { invoices, refetchInvoices, multiPersonGroupedInvoices, isFetchInvoicesLoading } = usePersonActiveInvoiceList(
    props.context.personId,
    props.context.associatedPersonIds
  );

  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds: [props.context.locationId],
  });

  return showMultiProcessorCollect ? (
    <ContactPaymentActionHandlerMulti
      {...props}
      smartRefetchOnTimeout={smartRefetchOnTimeout}
      multiPersonGroupedInvoices={multiPersonGroupedInvoices}
      isFetchInvoicesLoading={isFetchInvoicesLoading}
      invoices={invoices}
    />
  ) : (
    <ContactPaymentActionHandlerLegacy
      {...props}
      smartRefetchOnTimeout={smartRefetchOnTimeout}
      multiPersonGroupedInvoices={multiPersonGroupedInvoices}
      isFetchInvoicesLoading={isFetchInvoicesLoading}
      invoices={invoices}
    />
  );
};

type ContactPaymentActionHandlerProps = ContactPaymentActionProps & {
  smartRefetchOnTimeout: (attempts?: number) => void;
  multiPersonGroupedInvoices?: Record<string, InvoiceModel[]>;
  invoices: InvoiceModel[];
  isFetchInvoicesLoading: boolean;
};

const ContactPaymentActionHandlerMulti = ({
  context: { personId, locationId },
  invoices,
  trackingIdSuffix,
  isFetchInvoicesLoading,
  multiPersonGroupedInvoices,
  smartRefetchOnTimeout,
}: ContactPaymentActionHandlerProps) => {
  const [selectedPersonId, setSelectedPersonId] = useState(personId);

  const {
    triggerProps: paymentTriggerProps,
    modalProps: paymentModalProps,
    closeModal: closePaymentModal,
  } = useModalControl();

  const {
    getTriggerProps: getMenuTriggerProps,
    getMenuProps,
    getItemProps: getMenuItemProps,
    close: closeMenu,
  } = useNestedPopoverMenu({
    placement: 'bottom-start',
  });

  const multiCollectProps = useCollectPaymentMulti(
    {
      trackingId: trackingIdSuffix,
      personId: selectedPersonId,
      onPaymentSuccess: () => {
        smartRefetchOnTimeout();
      },
      onInvoiceCreated: () => {
        smartRefetchOnTimeout();
      },
    },
    {
      trackingId: trackingIdSuffix,
      onCancel: () => {
        closePaymentModal();
        multiCollectProps.resetState();
      },
      onComplete: () => {
        closePaymentModal();
        multiCollectProps.resetState();
      },
    }
  );

  const { person: legacyPerson, setFlowAndStep, setActiveInvoiceId } = multiCollectProps;

  const { disableCloseOnEscape, disableCloseOnOverlayClick } = useSyncCollectPaymentMultiToModal({
    activeStep: multiCollectProps.multiStep.activeStep,
    setShowCancelAction: multiCollectProps.multiStep.setShowCancelAction,
  });

  const person = PersonsV3.PersonHelpers.convertPersonV3ToPerson(legacyPerson);

  const onClickCreateNewInvoice = useCallback(() => {
    setActiveInvoiceId(null);
    setFlowAndStep('create', PAYMENT_MULTI_STEPS.create);
    closeMenu();
    paymentTriggerProps.onClick();
  }, [paymentTriggerProps.onClick]);

  const onClickCollectPaymentFromInvoice = useCallback(
    (id: string) => {
      setActiveInvoiceId(id);
      setFlowAndStep('pay', PAYMENT_MULTI_STEPS.paymentMethods);
      closeMenu();
      paymentTriggerProps.onClick();
    },
    [paymentTriggerProps.onClick, closeMenu]
  );

  const onClickSendReminder = useCallback(
    (id: string) => {
      setActiveInvoiceId(id);
      setFlowAndStep('pay', PAYMENT_MULTI_STEPS.shareInMessage);
      closeMenu();
      paymentTriggerProps.onClick();
    },
    [paymentTriggerProps.onClick, closeMenu]
  );

  return (
    <ContactPaymentActionUI
      PaymentModal={
        <>
          <CollectPaymentMultiModal
            {...paymentModalProps}
            disableCloseOnEscape={disableCloseOnEscape}
            disableCloseOnOverlayClick={disableCloseOnOverlayClick}
          >
            <CollectPaymentMulti {...multiCollectProps} />
          </CollectPaymentMultiModal>
          <CollectPaymentMultiPortal multiControl={multiCollectProps} />
        </>
      }
      invoices={invoices}
      isFetchInvoicesLoading={isFetchInvoicesLoading}
      person={person}
      locationId={locationId}
      trackingIdSuffix={trackingIdSuffix}
      multiPersonGroupedInvoices={multiPersonGroupedInvoices}
      paymentTriggerProps={paymentTriggerProps}
      onClickSendReminder={onClickSendReminder}
      onClickCollectPaymentFromInvoice={onClickCollectPaymentFromInvoice}
      onClickCreateNewInvoice={onClickCreateNewInvoice}
      getMenuProps={getMenuProps}
      getMenuTriggerProps={getMenuTriggerProps}
      getMenuItemProps={getMenuItemProps}
      onSelectPerson={setSelectedPersonId}
    />
  );
};

/**
 * @deprecated Part of the old collection modal's implementation. Once the multi processor collection is fully integrated,
 * remove this method and move unify ContactPaymentAction > ContactPaymentActionHandlerMulti > ContactPaymentActionUI
 */
const ContactPaymentActionHandlerLegacy = ({
  context: { personId, locationId },
  invoices,
  trackingIdSuffix,
  isFetchInvoicesLoading,
  multiPersonGroupedInvoices,
  smartRefetchOnTimeout,
}: ContactPaymentActionHandlerProps) => {
  const [selectedPersonId, setSelectedPersonId] = useState(personId);

  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const {
    triggerProps: paymentTriggerProps,
    Modal: PaymentModal,
    person,
    setInitialStep,
  } = usePaymentAction({
    context: {
      personId: selectedPersonId,
      locationId,
    },
    onPaymentSuccess: () => {
      smartRefetchOnTimeout();
    },
    onInvoiceCreated: () => {
      smartRefetchOnTimeout();
    },
  });

  const {
    getTriggerProps: getMenuTriggerProps,
    getMenuProps,
    getItemProps: getMenuItemProps,
    close: closeMenu,
  } = useNestedPopoverMenu({
    placement: 'bottom-start',
  });

  const onClickCreateNewInvoice = useCallback(() => {
    setInitialStep(PersonInvoiceModalSteps.CreateInvoice);
    paymentTriggerProps.onClick();
  }, [paymentTriggerProps.onClick]);

  const onClickCollectPaymentFromInvoice = useCallback(
    (id: string) => {
      setSelectedInvoiceId(id);
      setInitialStep(CollectPaymentModalSteps.PaymentFlowList);
      closeMenu();
      paymentTriggerProps.onClick();
    },
    [paymentTriggerProps.onClick, closeMenu]
  );

  const onClickSendReminder = useCallback(
    (id: string) => {
      setSelectedInvoiceId(id);
      setInitialStep(CollectPaymentModalSteps.ShareInMessage);
      closeMenu();
      paymentTriggerProps.onClick();
    },
    [paymentTriggerProps.onClick, closeMenu]
  );

  return (
    <ContactPaymentActionUI
      PaymentModal={PaymentModal}
      invoices={invoices}
      isFetchInvoicesLoading={isFetchInvoicesLoading}
      person={person}
      locationId={locationId}
      trackingIdSuffix={trackingIdSuffix}
      multiPersonGroupedInvoices={multiPersonGroupedInvoices}
      paymentTriggerProps={paymentTriggerProps}
      onClickSendReminder={onClickSendReminder}
      onClickCollectPaymentFromInvoice={onClickCollectPaymentFromInvoice}
      onClickCreateNewInvoice={onClickCreateNewInvoice}
      getMenuProps={getMenuProps}
      getMenuTriggerProps={getMenuTriggerProps}
      getMenuItemProps={getMenuItemProps}
      onSelectPerson={setSelectedPersonId}
    />
  );
};

type ContactPaymentActionUIProps = {
  PaymentModal: ReactNode;
  isFetchInvoicesLoading: boolean;
  invoices: InvoiceModel[];
  person: PersonTypes.Person;
  locationId: string;
  trackingIdSuffix?: string;
  multiPersonGroupedInvoices?: Record<string, InvoiceModel[]>;
  paymentTriggerProps: ModalControlTriggerProps;
  getMenuProps: ReturnType<typeof useNestedPopoverMenu>['getMenuProps'];
  getMenuItemProps: ReturnType<typeof useNestedPopoverMenu>['getItemProps'];
  getMenuTriggerProps: ReturnType<typeof useNestedPopoverMenu>['getTriggerProps'];
  onClickSendReminder: (id: string) => void;
  onClickCollectPaymentFromInvoice: (id: string) => void;
  onClickCreateNewInvoice: () => void;
  onSelectPerson: (personId: string) => void;
};
const ContactPaymentActionUI = ({
  PaymentModal,
  invoices,
  isFetchInvoicesLoading,
  person,
  locationId,
  trackingIdSuffix,
  multiPersonGroupedInvoices,
  paymentTriggerProps,
  onClickSendReminder,
  onClickCollectPaymentFromInvoice,
  onClickCreateNewInvoice,
  getMenuProps,
  getMenuTriggerProps,
  getMenuItemProps,
  onSelectPerson,
}: ContactPaymentActionUIProps) => {
  const { t } = useTranslation('contacts');

  const personIdsWithInvoices = Object.keys(multiPersonGroupedInvoices ?? {});

  const totalOutstandingAmount = useMemo(() => {
    if (!invoices || !invoices.length) {
      return 0;
    }

    return invoices.reduce((acc, invoice) => {
      if (invoice.status !== InvoiceStatus.Unpaid) {
        return acc;
      }

      return acc + invoice.billedAmount;
    }, 0);
  }, [invoices, person]);

  const formattedAmount = useMemo(() => {
    return formatCentsToCurrency(totalOutstandingAmount, false);
  }, [totalOutstandingAmount]);

  const getLabel = () => {
    return totalOutstandingAmount > 0 ? t('Unpaid Requests') : t('Create Payment Request');
  };

  const computedLabel = getLabel();

  return (
    <>
      {isFetchInvoicesLoading ? (
        <Button
          variant='secondary'
          iconName='pay'
          className='payment-action-button'
          css={unPaidRequestButtonStyle}
          loading={true}
        />
      ) : totalOutstandingAmount ? (
        <div style={{ position: 'relative' }}>
          <Button
            trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}:unpaid--trigger`}
            variant='secondary'
            hoverLabel={computedLabel}
            iconName='pay'
            className='payment-action-button'
            {...getMenuTriggerProps()}
            css={unPaidRequestButtonStyle}
            loading={isFetchInvoicesLoading}
          >
            {formattedAmount}
          </Button>
          <PopoverMenu {...getMenuProps()}>
            {!multiPersonGroupedInvoices ? (
              <InvoicePopoverMenuItems
                invoices={invoices}
                onClickSendReminder={onClickSendReminder}
                onClickCollectPaymentFromInvoice={onClickCollectPaymentFromInvoice}
                onClickCreateNewInvoice={onClickCreateNewInvoice}
                getMenuItemProps={getMenuItemProps}
                trackingIdSuffix={trackingIdSuffix}
              />
            ) : (
              <div css={{ overflowY: 'auto', maxHeight: '290px', width: '100%' }}>
                {personIdsWithInvoices.map((personId, index) => {
                  const currentInvoices = multiPersonGroupedInvoices[personId];
                  return (
                    <PopoverMenuItemDropdown
                      index={index}
                      key={personId}
                      trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
                      css={{
                        width: '100%',
                        height: theme.spacing(6),
                        cursor: 'pointer',
                        '& svg': {
                          width: theme.spacing(2),
                        },
                      }}
                      subMenuItems={() => (
                        <InvoicePopoverMenuItems
                          invoices={currentInvoices}
                          onClickSendReminder={onClickSendReminder}
                          onClickCollectPaymentFromInvoice={onClickCollectPaymentFromInvoice}
                          onClickCreateNewInvoice={onClickCreateNewInvoice}
                          getMenuItemProps={getMenuItemProps}
                          trackingIdSuffix={trackingIdSuffix}
                        />
                      )}
                      triggerProps={{
                        onClick: () => onSelectPerson(personId),
                      }}
                    >
                      <ContactMenuItem
                        name={currentInvoices[0]?.person.name}
                        locationId={locationId}
                        personId={personId}
                        index={index}
                        invoiceCount={currentInvoices?.length}
                      />
                    </PopoverMenuItemDropdown>
                  );
                })}
              </div>
            )}
          </PopoverMenu>
        </div>
      ) : (
        <AdaptoActions.Action
          {...paymentTriggerProps}
          onClick={onClickCreateNewInvoice}
          trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
          className='payment-action-button'
          icon='dollar-sign'
          label={computedLabel}
        />
      )}
      {PaymentModal}
    </>
  );
};

const pulseAnimationStyles = css({
  ':after': {
    animation: 'pulse 2s ease-in-out infinite',
  },
  '@keyframes pulse': {
    '0%, 100%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
  },
});

const highlightedActionStyles = css({
  color: theme.colors.tangerine40,
  span: {
    color: theme.colors.tangerine40,
  },
  svg: {
    color: theme.colors.tangerine40,
  },
  ':after': {
    content: '""',
    position: 'absolute',
    inset: 4,
    borderRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.tangerine10,
    zIndex: -1,
  },
});

const unPaidRequestButtonStyle = css`
  line-height: 1;
  background-color: ${theme.colors.tangerine5};
  border-color: ${theme.colors.warning30};
  color: ${theme.colors.neutral90};

  svg {
    fill: ${theme.colors.warning40};
    color: ${theme.colors.warning40};
  }

  &:hover,
  &:focus {
    background-color: ${theme.colors.tangerine10};
  }
`;
