import { Button, Section } from '@react-email/components';
import { ButtonSection, Link } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useComposerSection, useLinks } from '../../hooks';
import { ComposerSectionOutputProps, Corners } from '../../types';
import { getCornersStyle, getFrameStyle } from '../../utils';

export const ButtonOutput = ({ renderMode }: ComposerSectionOutputProps) => {
  const { props } = useComposerSection<ButtonSection>();
  const { frame, link } = props;
  const href = useCreateButtonLink(link, renderMode);

  return (
    <Section style={{ ...getFrameStyle(frame), display: 'flex' }}>
      <Button style={createButtonStyle(props)} href={href}>
        {props.link?.text ?? ''}
      </Button>
    </Section>
  );
};

const createButtonStyle = (props: ButtonSection) => ({
  ...getCornersStyle(props.corners as Corners | undefined),
  background: props.buttonColor,
  color: props.textColor,
  border: '0',
  fontSize: '16px',
  lineHeight: '18px',
  cursor: 'pointer',
  padding: '10px 20px',
});

const useCreateButtonLink = (link?: Link, renderMode?: string) => {
  const { formatLink, getAddressLink, onlineScheduleLink } = useLinks();

  switch (link?.linkType) {
    case 'phone':
    case 'email':
      return formatLink(link?.url ?? '', link?.linkType, renderMode);
    case 'custom':
      return formatLink(link.url ?? '');
    case 'form':
      // TODO: Placeholder until we get a real form link
      return renderMode === 'preview' ? 'https://wv3.io/wf0001' : '';
    case 'address':
      return renderMode === 'preview' ? getAddressLink(renderMode) : '{{BUSINESS_GROUP_ADDRESS}}';
    case 'schedule':
      return renderMode === 'preview' ? onlineScheduleLink : '{{SCHEDULE_LINK}}';
    default: {
      return '';
    }
  }
};
