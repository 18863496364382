import { useCollectPaymentMultiContext } from '../collect-payment-multi.context';
import { CollectPaymentMultiSteps } from './steps';
import { TerminalStepContext } from './terminals/terminal-step-context';

export const CollectPaymentMultiBody = () => {
  return (
    <>
      <CollectPaymentMultiSteps.Select />

      <CollectPaymentMultiSteps.Preview />

      <CollectPaymentMultiSteps.Create />

      <SelectPaymentsTemplateWithContext />

      <CollectPaymentMultiSteps.PaymentMethods />

      <CollectPaymentMultiSteps.ShareInMessage />

      <CollectPaymentMultiSteps.ShareInEmail />

      <CollectPaymentMultiSteps.CardOnFile />

      <CollectPaymentMultiSteps.ManualCardEntry />

      <CollectPaymentMultiSteps.Sunbit />

      <CollectPaymentMultiSteps.RegisterTerminal />

      <TerminalStepContext>
        <CollectPaymentMultiSteps.SelectTerminal />

        <CollectPaymentMultiSteps.CollectTerminal />
      </TerminalStepContext>
    </>
  );
};

const SelectPaymentsTemplateWithContext = () => {
  const defaultRenderMethod = () => {
    return <div>Select Payments Template requires a render method</div>;
  };
  const { renderSelectPaymentsTemplate } = useCollectPaymentMultiContext<'insert'>();

  return (
    <CollectPaymentMultiSteps.SelectPaymentsTemplate
      renderMethod={renderSelectPaymentsTemplate || defaultRenderMethod}
    />
  );
};
