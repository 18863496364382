import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';

interface Props extends CommonHTMLAttributes {
  customStyles?: CSSProperties;
}

export const Divider = ({ customStyles, ...props }: Props) => {
  return <hr css={hrStyles} style={customStyles} {...props} />;
};

const hrStyles = css`
  border-color: ${theme.colors.neutral50};
  margin: ${theme.spacing(1, 0)};
`;
