import { useState, useEffect, MouseEvent, useMemo, useRef } from 'react';
import { OverrideType, PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { CreateRequest, UpdateRequest } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override_service.pb';
import { ListResponse } from '@weave/schema-gen-ts/dist/schemas/phone/override/v2/beta/override_service.pb';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { LocationsApi } from '@frontend/api-locations';
import { OverrideApi } from '@frontend/api-overrides';
import { scopedFilterAndSort } from '@frontend/filters';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useInvalidateQueries } from '@frontend/location-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  useModalControl,
  UseFormReturnType,
  useForm,
  Tray,
  IconButton,
  XIcon,
  Heading,
  RadioField,
  CheckboxField,
  DatePickerField,
  TimeField,
  SecondaryButton,
  PrimaryButton,
  Text,
  Chip,
  LocationIcon,
  CubeIconSmall,
  useAlert,
  TextLink,
  DropdownField,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { defaultMediaID } from '../../utils/phone-utils';
import {
  newOverrideButtonSectionStyle,
  overrideButtonSectionStyle,
  overrideRadioFieldStyle,
  overrideSettingsStyle,
} from '../../views/settings/overrides-styles';
import { AudioPicker } from '../audio-picker/audio-picker';
import { getMediaValues } from '../department/phone-routing-settings';
import { ForwardingNumberPicker } from '../forwarding-number-picker';
import { MediaPicker } from '../media-picker';
import useVoicemailBoxes from '../voicemail-box/new/use-voicemail-boxes';
import { NewOverrideSettingRemoveModal } from './override-settings-remove-modal.new';
import { OverrideCreatePayload, OverrideUpdatePayload, PhoneOverrideType, SchemaFormValues } from './types';
import { useOverrideMutations } from './use-overrides-mutations';

const formConfigBase = {
  voicemailBoxId: { type: 'dropdown' },
  voicemailMediaId: { type: 'dropdown' },
  forwardingNumberId: { type: 'dropdown' },
  selectOverride: { type: 'radio' },
  addSchedule: { type: 'checkbox' },
  startDate: { type: 'datePicker' },
  startTime: { type: 'time' },
  endDate: { type: 'datePicker' },
  endTime: { type: 'time' },
} as const;

interface IOverrideSettingTray {
  mountTarget?: string;
  override?: PhoneOverrideType | PhoneOverride;
  locationId?: string;
  isCallRouteFlow?: boolean;
  departmentId?: string;
  departmentName?: string;
  modalControls: ReturnType<typeof useModalControl>;
  overridesData?: ListResponse;
}

// We need to calculate the time difference between userTime and officeTime. This difference will be applied to the payload to ensure that the time in the timeField aligns with the office timezone, ensuring consistency between the selected time and the expected response.
const getTimezoneDifference = (officeTimezone: string | undefined) => {
  const userTime = new Date(dayjs().format('MM/DD/YY HH:mm'));
  const officeTime = new Date(dayjs().tz(officeTimezone).format('MM/DD/YY HH:mm'));
  return (officeTime.getTime() - userTime.getTime()) / (1000 * 60 * 60);
};

const timeToSeconds = (timeStr: string) => {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const NewOverrideSettingTray = ({
  mountTarget,
  override,
  locationId: iLID,
  departmentId: iDID,
  departmentName,
  modalControls,
  isCallRouteFlow,
  overridesData,
}: IOverrideSettingTray) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const invalidateQueries = useInvalidateQueries();
  const alerts = useAlert();
  const { getScopeName, selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData[selectedLocationIds[0]];
  const phoneTenantId = locationData?.phoneTenantId ?? '';
  const instructionSetId = (override as PhoneOverrideType)?.instructionSetId ?? '';

  const lastUsedVMO = overridesData?.lastUsed?.[instructionSetId ?? '']?.lastUsed?.[OverrideType.OVERRIDE_TYPE_VMO];
  const lastUsedFWD = overridesData?.lastUsed?.[instructionSetId ?? '']?.lastUsed?.[OverrideType.OVERRIDE_TYPE_FWD];
  const lastUsedMediaItemId = (override as PhoneOverrideType)?.mediaItemId ? '' : lastUsedVMO?.mediaItemId;
  const lastUsedForwardingNumberId = (override as PhoneOverrideType)?.forwardingNumberId
    ? ''
    : lastUsedFWD?.forwardingNumberId;
  const lastUsedVoicemailBoxId = (override as PhoneOverrideType)?.voicemailBoxId ? '' : lastUsedVMO?.voicemailBoxId;

  const getVoicemailBoxNameById = (id: string) => {
    const voicemailBox = voicemailBoxes?.find((item) => item.mailbox.id === id);
    return voicemailBox ? voicemailBox.mailbox.name : null;
  };

  const { aggregateValue: hasCallRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: selectedLocationIds ?? [],
  });
  const removeOverride = useModalControl();
  const locationId = iLID ?? override?.locationId ?? '';
  const departmentId = (override as PhoneOverride)?.departmentId || (iDID ?? '');
  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: [locationId],
  });

  const { globalAvailableLocationIds } = usePhoneSettingsShallowStore('globalAvailableLocationIds');

  const { data: voicemailBoxes = [] } = useVoicemailBoxes({
    settingsTenantLocation: locationData,
  });

  const filteredVoicemailBoxes = useMemo(
    () =>
      scopedFilterAndSort({
        data: voicemailBoxes,
        scopeIds: globalAvailableLocationIds,
        filteredScopeIds: [],
        shouldSort: false,
      }),
    [voicemailBoxes, globalAvailableLocationIds]
  );

  const { data: officeTimezone } = useQuery({
    queryKey: [locationId, ...queryKeys.timezone(locationId ?? '')],
    queryFn: () =>
      LocationsApi.getOfficeTimezone(
        {},
        {
          locationId,
        }
      ),
    select: (data) => data.timezone,
  });

  const currentDate = dayjs();
  const currentDay = dayjs().startOf('day');
  const currentDateFormatted = dayjs().format('MM/DD/YYYY');
  const roundedTime = currentDate.minute(Math.ceil(currentDate.minute() / 15) * 15);

  /// we know that if there is a matching override, then the override is being edited
  const isEdit = !!override?.id;

  const timezoneDiff = getTimezoneDifference(officeTimezone);
  const currentTimeOffice = roundedTime.add(timezoneDiff, 'hour').format('HH:mm');

  const { mutate: createOverride } = useMutation(
    (payload: CreateRequest) => {
      return OverrideApi.createOverride(payload, { locationId: payload.phoneOverride?.locationId });
    },
    {
      onSuccess: (resp) => {
        invalidateQueries([...queryKeys.overrideList()]);
        invalidateQueries([...queryKeys.listDepartmentsWithSchedules()]);
        invalidateQueries(['List']);
        alerts.success(
          resp.phoneOverride?.startAt
            ? t('Override will start on {{startAt}}', {
                startAt: dayjs(resp.phoneOverride.startAt).tz(officeTimezone).format('MMM D YYYY h:mma z'),
              })
            : t('Override turned on')
        );
      },
      onError: () => {
        alerts.error(t('Override create failed'));
      },
    }
  );

  const { mutate: updateOverride } = useMutation(
    (payload: UpdateRequest) => {
      return OverrideApi.UpdateOverride(payload, { locationId: payload.phoneOverride?.locationId });
    },
    {
      onSuccess: () => {
        invalidateQueries([...queryKeys.overrideList()]);
        invalidateQueries([...queryKeys.listDepartmentsWithSchedules()]);
        alerts.success(t('Override updated successfully'));
      },
      onError: () => {
        alerts.error(t('Override update failed'));
      },
    }
  );

  const createPostPayload = (
    // use the `values` prop of the value returned by useForm
    values: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>['values'] & { id?: string }
  ): CreateRequest | UpdateRequest => {
    const formattedStartDateTime = dayjs(`${values.startDate} ${values.startTime}`)
      .subtract(timezoneDiff, 'hour')
      .format();
    const formattedEndDateTime = dayjs(`${values.endDate} ${values.endTime}`).subtract(timezoneDiff, 'hour').format();

    return {
      phoneOverride: {
        locationId: locationId,
        departmentId: departmentId,
        overrideType: values.selectOverride as OverrideType,
        ...(values.voicemailMediaId !== ''
          ? { mediaItemId: values.voicemailMediaId && getMediaValues(values.voicemailMediaId) }
          : {}),
        ...(values.forwardingNumberId !== '' ? { forwardingNumberId: values.forwardingNumberId } : {}),
        ...(values.addSchedule && values.startDate !== '' && values.startTime !== ''
          ? { startAt: formattedStartDateTime }
          : {}),
        ...(values.addSchedule && values.endDate !== '' && values.endTime !== ''
          ? { endAt: formattedEndDateTime }
          : {}),
        ...(override?.id !== '' ? { id: override?.id } : {}),
      },
    };
  };

  const createPostPayloadV2 = (
    values: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>['values'] & { id?: string },
    instructionSetId: string
  ): OverrideCreatePayload => {
    const formattedStartDateTime = dayjs(`${values.startDate} ${values.startTime}`)
      .subtract(timezoneDiff, 'hour')
      .format();
    const formattedEndDateTime = dayjs(`${values.endDate} ${values.endTime}`).subtract(timezoneDiff, 'hour').format();

    const baseObj: OverrideCreatePayload = {
      instructionSetId,
      overrideType: values.selectOverride as OverrideType,
    };

    if (baseObj.overrideType === OverrideType.OVERRIDE_TYPE_VMO && values.voicemailBoxId) {
      baseObj.voicemailBoxId = values.voicemailBoxId;
    } else baseObj.voicemailBoxId = '';

    if (baseObj.overrideType === OverrideType.OVERRIDE_TYPE_VMO && values.voicemailMediaId !== '') {
      baseObj.mediaItemId = values.voicemailMediaId && getMediaValues(values.voicemailMediaId);
    } else baseObj.mediaItemId = '';

    if (baseObj.overrideType === OverrideType.OVERRIDE_TYPE_FWD && values.forwardingNumberId !== '') {
      baseObj.forwardingNumberId = values.forwardingNumberId;
    } else baseObj.forwardingNumberId = '';

    if (values.addSchedule && values.startDate !== '' && values.startTime !== '') {
      baseObj.startAt = formattedStartDateTime;
    }

    if (values.addSchedule && values.endDate !== '' && values.endTime !== '') {
      baseObj.endAt = formattedEndDateTime;
    }

    return baseObj;
  };
  const updatePostPayloadV2 = (
    values: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>['values'] & { id?: string },
    instructionSetId: string
  ): OverrideUpdatePayload => {
    const formattedStartDateTime = dayjs(`${values.startDate} ${values.startTime}`)
      .subtract(timezoneDiff, 'hour')
      .format();
    const formattedEndDateTime = dayjs(`${values.endDate} ${values.endTime}`).subtract(timezoneDiff, 'hour').format();

    const baseObj: OverrideUpdatePayload = {
      instructionSetId,
      id: values.id || '',
      overrideType: values.selectOverride as OverrideType,
    };

    if (baseObj.overrideType === OverrideType.OVERRIDE_TYPE_VMO && values.voicemailBoxId) {
      baseObj.voicemailBoxId = values.voicemailBoxId;
    } else baseObj.voicemailBoxId = '';

    if (baseObj.overrideType === OverrideType.OVERRIDE_TYPE_VMO && values.voicemailMediaId) {
      baseObj.mediaItemId = getMediaValues(values.voicemailMediaId);
    } else baseObj.mediaItemId = '';

    if (baseObj.overrideType === OverrideType.OVERRIDE_TYPE_FWD && values.forwardingNumberId) {
      baseObj.forwardingNumberId = values.forwardingNumberId;
    } else baseObj.forwardingNumberId = '';

    if (optionalFieldProps.value && values.addSchedule && values.startDate && values.startTime) {
      baseObj.startAt = formattedStartDateTime;
    }

    if (optionalFieldProps.value && values.addSchedule && values.endDate && values.endTime) {
      baseObj.endAt = formattedEndDateTime;
    }

    if (override?.id) {
      baseObj.id = override.id;
    }

    return baseObj;
  };

  const { formProps, getFieldProps, seedValues, validate } = useForm({
    computeChangedValues: true,
    fields: {
      voicemailBoxId: {
        type: 'dropdown',
        value: (override as PhoneOverrideType)?.voicemailBoxId,
      },
      voicemailMediaId: { type: 'dropdown', value: override?.mediaItemId },
      forwardingNumberId: { type: 'dropdown' },
      selectOverride: { type: 'radio' },
      addSchedule: { type: 'checkbox' },
      startDate: {
        type: 'datePicker',
        validator: ({ value }, formValues) => {
          const startDate = dayjs(value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');
          // Skip start date validation for an active override (indicated by override?.enabled) if the start date and time have passed. This specifically applies when editing an override.
          if (formValues.endDate.value === '' && !override?.enabled) {
            return startDate.isBefore(currentDate, 'day') ? t('Start date must be in the future') : '';
          }

          return startDate.isAfter(endDate) ? t('Start date must be before the end date') : '';
        },
      },
      startTime: {
        type: 'time',
        validator: ({ value }, formValues) => {
          const startTime = timeToSeconds(value);
          const endTime = timeToSeconds(formValues.endTime.value);
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');
          const currentTime = timeToSeconds(dayjs().add(timezoneDiff, 'hour').format('HH:mm:ss'));
          // Skip start time validation for an active override (indicated by override?.enabled) if the start date and time have passed. This specifically applies when editing an override.
          if (startDate.isSame(currentDate, 'day') && !startDate.isSame(endDate) && !override?.enabled) {
            return startTime < currentTime ? t('Start time must be in the future') : '';
          }

          return startDate.isSame(endDate) && startTime >= endTime ? t('Start time must be before the end time') : '';
        },
      },
      endDate: {
        type: 'datePicker',
        validator: ({ value }, formValues) => {
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(value, 'MM/DD/YYYY').startOf('day');

          if (!formValues.startDate.value) {
            return endDate.isBefore(currentDay) ? t('End date must be in the future') : '';
          }

          return endDate.isBefore(startDate) ? t('End date must be after the start date') : '';
        },
      },
      endTime: {
        type: 'time',
        validator: ({ value }, formValues) => {
          const startTime = timeToSeconds(formValues.startTime.value);
          const endTime = timeToSeconds(value);
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');
          const currentTime = timeToSeconds(dayjs().add(timezoneDiff, 'hour').format('HH:mm:ss'));

          if (endDate.isSame(currentDate, 'day') && !startDate.isSame(endDate)) {
            return endTime < currentTime ? 'End time must be in the future' : '';
          }
          return startDate.isSame(endDate) && startTime >= endTime ? t('End time must be after the start time') : '';
        },
      },
    },
    onSubmit: (formValues) => {
      let instructionSetId = '';
      if ((override as PhoneOverrideType)?.instructionSetId) {
        instructionSetId = (override as PhoneOverrideType).instructionSetId;
      } else if (override && 'instructionSetId' in override) {
        instructionSetId = override.instructionSetId;
      }
      let result;

      if (override?.id) {
        if (hasCallRoutesFlag && !departmentId) {
          result = updateOverrideV2(formValues, instructionSetId);
        } else {
          result = updateOverride(createPostPayload(formValues));
        }
      } else {
        if (hasCallRoutesFlag && !departmentId) {
          result = createOverrideV2(formValues, instructionSetId);
        } else {
          result = createOverride(createPostPayload(formValues));
        }
      }

      return result;
    },
  });

  const voicemailBoxProps = getFieldProps('voicemailBoxId');
  const vmOverrideMediaProps = getFieldProps('voicemailMediaId');
  const callForwardingNumberProps = getFieldProps('forwardingNumberId');
  const overrideSelectProps = getFieldProps('selectOverride');
  const optionalFieldProps = getFieldProps('addSchedule');
  const startDateFieldProps = getFieldProps('startDate');
  const startTimeFieldProps = getFieldProps('startTime');
  const endDateFieldProps = getFieldProps('endDate');
  const endTimeFieldProps = getFieldProps('endTime');
  const startDate = startDateFieldProps?.value;
  const endDate = endDateFieldProps?.value;
  const isStartToday = dayjs(startDate).isSame(currentDate, 'day');
  const isEndToday = dayjs(endDate).isSame(currentDate, 'day');

  useEffect(() => {
    validate();
  }, [startDateFieldProps.value, endDateFieldProps.value, startTimeFieldProps.value, endTimeFieldProps.value]);

  useEffect(() => {
    if (!!override && timezoneDiff !== undefined) {
      seedValues({
        selectOverride:
          override.overrideType !== OverrideType.OVERRIDE_TYPE_UNSPECIFIED
            ? override.overrideType
            : OverrideType.OVERRIDE_TYPE_VMO,
        voicemailBoxId: (override as PhoneOverrideType).voicemailBoxId || '',
        voicemailMediaId: override.mediaItemId
          ? override.mediaItemId
          : hasCallRoutesFlag
          ? (override as PhoneOverrideType).id && override.overrideType === OverrideType.OVERRIDE_TYPE_VMO
            ? 'default'
            : ''
          : getMediaValues(defaultMediaID),
        forwardingNumberId: override.forwardingNumberId,
        addSchedule: !!override.startAt || !!override.endAt,
        startDate: !!override.startAt ? dayjs(override.startAt).format('MM/DD/YYYY') : '',
        startTime: !!override.startAt ? dayjs(override.startAt).add(timezoneDiff, 'hour').format('h:mma') : '',
        endDate: !!override.endAt ? dayjs(override.endAt).format('MM/DD/YYYY') : '',
        endTime: !!override.endAt ? dayjs(override.endAt).add(timezoneDiff, 'hour').format('h:mma') : '',
      });
    } else
      seedValues({
        selectOverride: OverrideType.OVERRIDE_TYPE_VMO,
        voicemailBoxId: '',
        voicemailMediaId: '',
        forwardingNumberId: '',
        addSchedule: false,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      });
  }, [override, timezoneDiff, filteredVoicemailBoxes]);

  const hasValidationError =
    !!startDateFieldProps.error.length ||
    !!startTimeFieldProps.error.length ||
    !!endDateFieldProps.error.length ||
    !!endTimeFieldProps.error.length;

  const canUpdateOverride =
    (overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_VMO &&
      !hasValidationError &&
      (!hasCallRoutesFlag || ((!departmentId ? !!voicemailBoxProps.value : true) && !!vmOverrideMediaProps.value))) ||
    (overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_FWD &&
      !hasValidationError &&
      !!callForwardingNumberProps.value);

  const needTimeField =
    !!optionalFieldProps.value &&
    [
      { start: startDateFieldProps, end: startTimeFieldProps },
      { start: endDateFieldProps, end: endTimeFieldProps },
    ].some(({ start, end }) => !!start.value !== !!end.value);

  /// Radio Field handles this for us, but we override the default behavior to let the entire div be clickable. W/o this, only the radio button & the label are clickable.
  const handleSelectOverrideType = (val: OverrideType) => (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    overrideSelectProps.onChange({ name: overrideSelectProps.name, value: val });
  };

  const handleCancel = () => {
    isCancelling.current = true;
    const isOverrideActive = override && !!override.id;
    seedValues({
      selectOverride: isOverrideActive ? override.overrideType : OverrideType.OVERRIDE_TYPE_VMO,
      voicemailBoxId: isOverrideActive ? (override as PhoneOverrideType)?.voicemailBoxId : '',
      voicemailMediaId:
        isOverrideActive && override?.mediaItemId
          ? override.mediaItemId
          : isOverrideActive && override?.overrideType === OverrideType.OVERRIDE_TYPE_VMO
          ? 'default'
          : getMediaValues(defaultMediaID),
      forwardingNumberId: isOverrideActive ? override.forwardingNumberId : '',
      addSchedule: isOverrideActive ? !!override.startAt || !!override.endAt : false,
      startDate: isOverrideActive && override.startAt ? dayjs(override.startAt).format('MM/DD/YYYY') : '',
      startTime: isOverrideActive && override.startAt ? dayjs(override.startAt).format('h:mma') : '',
      endDate: isOverrideActive && override.endAt ? dayjs(override.endAt).format('MM/DD/YYYY') : '',
      endTime: isOverrideActive && override.endAt ? dayjs(override.endAt).format('h:mma') : '',
    });

    // Reset flag after a short delay to allow the update to complete
    setTimeout(() => {
      isCancelling.current = false;
    }, 500);
  };

  const isCancelling = useRef(false);
  const previousVoicemailBoxId = useRef(voicemailBoxProps.value);

  // This is to reset voicmail greeting when voicemail box changes
  useEffect(() => {
    if (isCancelling.current) return;

    if (previousVoicemailBoxId.current && previousVoicemailBoxId.current !== voicemailBoxProps.value) {
      seedValues({
        voicemailMediaId: '',
      });
    }
    previousVoicemailBoxId.current = voicemailBoxProps.value;
  }, [voicemailBoxProps.value]);

  const renderButtons = () => (
    <>
      <SecondaryButton
        size='large'
        css={{ width: 'auto' }}
        onClick={() => {
          modalControls.closeModal();
          setTimeout(() => {
            // setTimeout for form to reset after closing the modal
            handleCancel();
          }, 300);
        }}
        trackingId='phone-portal-button-cancelOverride'
      >
        {t('Cancel')}
      </SecondaryButton>
      <PrimaryButton
        size='large'
        css={{ width: 'auto' }}
        disabled={!canUpdateOverride || needTimeField || !modalControls.modalProps.show}
        onClick={() => modalControls.closeModal()}
        type='submit'
        trackingId='phone-portal-button-createEditOverride'
      >
        {t('Save')}
      </PrimaryButton>
    </>
  );

  const { create, update } = useOverrideMutations(phoneTenantId);

  const createOverrideV2 = (formValues: SchemaFormValues, instructionSetId: string) => {
    const finalState = createPostPayloadV2(formValues, instructionSetId);
    create.mutate(finalState, {
      onSuccess: () => {
        alerts.success(t('Override created successfully.'));
      },
      onError: () => {
        alerts.error(t('Failed to create Override.'));
      },
    });
  };

  const updateOverrideV2 = (formValues: SchemaFormValues, instructionSetId: string) => {
    const finalState = updatePostPayloadV2(formValues, instructionSetId);
    update.mutate(finalState, {
      onSuccess: () => {
        alerts.success(t('Override updated successfully.'));
      },
      onError: () => {
        alerts.error(t('Failed to update Override.'));
      },
    });
  };

  const [overrideV2, setOverrideV2] = useState<PhoneOverrideType>();

  const handleOpenRemoveModalV2 = (override: PhoneOverrideType) => {
    setOverrideV2(override);
    removeOverride.openModal();
  };

  return (
    <>
      <Tray
        mountTarget={mountTarget}
        width='xlarge'
        css={{ padding: theme.spacing(4), borderTop: `1px solid ${theme.colors.neutral20}`, overflowY: 'auto' }}
        {...modalControls.modalProps}
      >
        <form {...formProps}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: `calc(100vh - ${theme.spacing(8)})`,
            }}
          >
            <div>
              <Tray.Header
                Buttons={
                  <IconButton
                    label='close'
                    onClick={() => {
                      modalControls.closeModal();
                      // setTimeout for form to reset after closing the modal
                      setTimeout(() => {
                        handleCancel();
                      }, 300);
                    }}
                  >
                    <XIcon />
                  </IconButton>
                }
              >
                <Heading level={2} css={{ marginBottom: 0 }}>
                  {isEdit ? t('Edit Override') : t('Create Override')}
                </Heading>
              </Tray.Header>
              {override && !hasCallRoutesFlag && (
                <Chip
                  variant='default'
                  leftElement={
                    departmentId ? (
                      <CubeIconSmall css={{ height: theme.spacing(2) }} />
                    ) : (
                      <LocationIcon css={{ height: theme.spacing(2) }} />
                    )
                  }
                  css={{ maxWidth: '135px' }}
                >
                  {departmentId ? departmentName : getScopeName(override?.locationId ?? '')}
                </Chip>
              )}
              {hasCallRoutesFlag && (override as PhoneOverrideType)?.callRouteName && !isCallRouteFlow && (
                <Text size='medium' css={{ marginBottom: theme.spacing(1) }} color='light'>
                  {(override as PhoneOverrideType)?.callRouteName}
                </Text>
              )}
              <Text size='medium' css={{ marginBottom: theme.spacing(2) }}>
                {t(
                  'Choose the override type and, if you wish to plan ahead, select the start and end dates for the override.'
                )}
              </Text>
              <Heading level={3} css={{ marginBottom: theme.spacing(2) }}>
                {t('Select Override Type')}
              </Heading>
              <div css={overrideSettingsStyle}>
                <RadioField {...overrideSelectProps} css={overrideRadioFieldStyle}>
                  <div
                    css={{
                      paddingLeft: theme.spacing(2),
                      borderRadius: theme.spacing(1),
                      border: `1px solid ${theme.colors.neutral20}`,
                      flex: 1,
                      cursor: 'pointer',
                      label: { cursor: 'pointer' },
                      input: { cursor: 'pointer' },
                    }}
                    onClick={handleSelectOverrideType(OverrideType.OVERRIDE_TYPE_VMO)}
                  >
                    <RadioField.Radio value={OverrideType.OVERRIDE_TYPE_VMO} trackingId='phone-portal-radio-vmo'>
                      <div css={{ marginLeft: theme.spacing(1) }}>
                        <Text weight='bold' css={{ margin: theme.spacing(2, 0, 0.5) }}>
                          {t('Send to Voicemail')}
                        </Text>
                        <Text size='medium' color='light'>
                          {t('All calls are automatically sent to voicemail')}
                        </Text>
                      </div>
                    </RadioField.Radio>
                  </div>
                  <div
                    css={{
                      paddingLeft: theme.spacing(2),
                      borderRadius: theme.spacing(1),
                      border: `1px solid ${theme.colors.neutral20}`,
                      flex: 1,
                      cursor: 'pointer',
                      label: { cursor: 'pointer' },
                      input: { cursor: 'pointer' },
                    }}
                    onClick={handleSelectOverrideType(OverrideType.OVERRIDE_TYPE_FWD)}
                  >
                    <RadioField.Radio value={OverrideType.OVERRIDE_TYPE_FWD} trackingId='phone-portal-radio-fwd'>
                      <div css={{ marginLeft: theme.spacing(1) }}>
                        <Text weight='bold' css={{ margin: theme.spacing(2, 0, 0.5) }}>
                          {t('Forward Call')}
                        </Text>
                        <Text size='medium' color='light'>
                          {t('All calls are forwarded to a predetermined phone number')}
                        </Text>
                      </div>
                    </RadioField.Radio>
                  </div>
                </RadioField>
                {overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_VMO && (
                  <div>
                    {hasCallRoutesFlag && !departmentId && (
                      <DropdownField
                        {...voicemailBoxProps}
                        css={{ maxWidth: theme.spacing(37.5), marginBottom: theme.spacing(3) }}
                        label={t('Select Voicemail Box')}
                        name='voicemailBoxId'
                      >
                        {!!lastUsedVoicemailBoxId && (
                          <DropdownField.OptionGroup label={t('Most Recently Used')}>
                            <DropdownField.Option
                              key={lastUsedVoicemailBoxId}
                              value={lastUsedVoicemailBoxId}
                              css={{ '&:hover': { backgroundColor: theme.colors.neutral5 } }}
                            >
                              {getVoicemailBoxNameById(lastUsedVoicemailBoxId)}
                            </DropdownField.Option>
                          </DropdownField.OptionGroup>
                        )}
                        {
                          <DropdownField.OptionGroup label={t('Voicemail Boxes')}>
                            {filteredVoicemailBoxes?.map((option) => (
                              <DropdownField.Option key={option.mailbox.id} value={option.mailbox.id}>
                                {option.mailbox.name}
                              </DropdownField.Option>
                            ))}
                          </DropdownField.OptionGroup>
                        }
                      </DropdownField>
                    )}
                    {hasCallRoutesFlag ? (
                      <AudioPicker
                        allowedOptions={{
                          add: true,
                          custom: true,
                          default: true,
                          recent: true,
                          none: true,
                          mailbox: true,
                        }}
                        widths={{ field: 300, scrubber: 300 }}
                        field={vmOverrideMediaProps}
                        name='voicemailMediaId'
                        labels={{ field: t('Select Voicemail Greeting') }}
                        tenantId={phoneTenantId}
                        lastUsedMediaItemId={lastUsedMediaItemId}
                        mailboxId={voicemailBoxProps.value}
                      />
                    ) : (
                      <MediaPicker
                        {...vmOverrideMediaProps}
                        locationId={locationId}
                        allowAddOption
                        allowDefaultOption={departmentsFlag}
                        requestedTypes={{ custom: true, standard: false }}
                        label={t('Select Voicemail Greeting')}
                        name='voicemailMediaId'
                      />
                    )}
                  </div>
                )}

                {overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_FWD && (
                  <ForwardingNumberPicker
                    {...callForwardingNumberProps}
                    name='forwardingNumberId'
                    label={t('Select Forwarding Number')}
                    locationId={locationId}
                    departmentId={departmentId}
                    lastUsedForwardingNumberId={lastUsedForwardingNumberId}
                  />
                )}

                <CheckboxField
                  {...optionalFieldProps}
                  label={
                    <>
                      <Text>{t('Add Start/End Time (Optional)')}</Text>
                      <Text size='medium' color='light'>
                        {t('Schedule when your override starts, ends, or both.')}
                      </Text>
                    </>
                  }
                  data-trackingid='phone-portal-checkBox-addStartEndTime'
                />
                {optionalFieldProps.value && (
                  <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
                    <div>
                      <div css={{ display: 'flex', gap: theme.spacing(1) }}>
                        <DatePickerField
                          label={t('Start Date')}
                          {...startDateFieldProps}
                          css={{ width: '155px' }}
                          minDate={currentDateFormatted}
                          data-trackingid='phone-portal-dateField-startDate'
                        />
                        <TimeField
                          {...startTimeFieldProps}
                          showAdornment
                          label={t('Start Time')}
                          interval={15}
                          minTime={isStartToday ? currentTimeOffice : '0:00'}
                          maxTime='24:00'
                          css={{ width: '155px' }}
                          value={startTimeFieldProps.value ? startTimeFieldProps.value : ''}
                          data-trackingid='phone-portal-timeField-startTime'
                        />
                      </div>
                      <Text color='light' size='small'>
                        {t('Override will automatically start on this date and time')}
                      </Text>
                    </div>
                    <div>
                      <div css={{ display: 'flex', gap: theme.spacing(1) }}>
                        <DatePickerField
                          label={t('End Date')}
                          {...endDateFieldProps}
                          css={{ width: '155px' }}
                          minDate={startDate ? startDate : currentDateFormatted}
                          data-trackingid='phone-portal-dateField-endDate'
                        />
                        <TimeField
                          {...endTimeFieldProps}
                          showAdornment
                          label={t('End Time')}
                          interval={15}
                          minTime={isEndToday ? currentTimeOffice : '0:00'}
                          maxTime='24:00'
                          css={{ width: '155px' }}
                          value={endTimeFieldProps.value ? endTimeFieldProps.value : ''}
                          data-trackingid='phone-portal-timeField-endTime'
                        />
                      </div>
                      <Text color='light' size='small'>
                        {t('Override will automatically stop on this date and time')}
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <section css={hasCallRoutesFlag ? newOverrideButtonSectionStyle : overrideButtonSectionStyle}>
              {hasCallRoutesFlag ? (
                <>
                  <div>
                    {isEdit && (
                      <TextLink
                        weight='bold'
                        color='error'
                        css={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: theme.spacing(0.5),
                        }}
                        onClick={() => {
                          handleOpenRemoveModalV2(override as PhoneOverrideType);
                        }}
                        size='large'
                      >
                        <Icon name='remove-small' />
                        {t('Remove Override')}
                      </TextLink>
                    )}
                  </div>
                  <div css={{ display: 'flex', gap: theme.spacing(2) }}>{renderButtons()}</div>
                </>
              ) : (
                renderButtons()
              )}
              <NewOverrideSettingRemoveModal
                override={overrideV2 ?? {}}
                modalProps={removeOverride.modalProps}
                phoneTenantId={phoneTenantId}
                modalControls={modalControls}
              />
            </section>
          </div>
        </form>
      </Tray>
    </>
  );
};
