import { CallIntelTypes } from '@frontend/api-call-intel';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export interface CallTakeawayPanelStore {
  allCalls: CallIntelTypes.Call[];
  defaultAccordion: CallIntelTypes.CallTakeawayAccordion;
  editedCallIds: string[];
  nextCall?: CallIntelTypes.Call | null;
  previousCall?: CallIntelTypes.Call | null;
  isDemoAccount?: boolean;
  selectedCall: CallIntelTypes.Call | null;
  selectedCallMetaData: CallIntelTypes.CallMetadata | null;
  selectedDemoTasks: CallIntelTypes.CallTaskListResponse | null;
  unsavedEditChangesConfig: CallIntelTypes.UnsavedEditChangesConfig;

  setAllCalls: (calls: CallIntelTypes.Call[]) => void;
  setDefaultAccordion: (accordion: CallIntelTypes.CallTakeawayAccordion) => void;
  setEditedCallIds: (callIds: string[]) => void;
  setIsDemoAccount: (isDemoAccount?: boolean) => void;
  setSelectedCall: (call: CallIntelTypes.Call | null) => void;
  setSelectedCallMetaData: (callMetaData: CallIntelTypes.CallMetadata | null) => void;
  setSelectedDemoTasks: (tasks: CallIntelTypes.Task[] | null) => void;
  setUnsavedEditChangesConfig: (config: CallIntelTypes.UnsavedEditChangesConfig) => void;

  handleNextClick: () => void;
  handlePreviousClick: () => void;
  reset: () => void;
}

export const useCallTakeawayPanelStore = createStoreWithSubscribe<CallTakeawayPanelStore>(
  (set, get) => ({
    allCalls: [],
    defaultAccordion: 'call-summary',
    editedCallIds: [],
    nextCall: null,
    previousCall: null,
    isDemoAccount: undefined,
    selectedCall: null,
    selectedCallMetaData: null,
    selectedDemoTasks: null,
    unsavedEditChangesConfig: {
      actionType: 'NO_ACTION',
      dontAskAgain: false,
      entity: '',
      hasUnsavedChanges: false,
    },

    setAllCalls: (calls) => {
      set(
        (state) => {
          state.allCalls = calls;
        },
        false,
        'SET_ALL_CALL_TAKEAWAY_CALLS'
      );
    },

    setDefaultAccordion: (accordion) => {
      set(
        (state) => {
          state.defaultAccordion = accordion;
        },
        false,
        'SET_DEFAULT_ACCORDION_TAKEAWAY_CALLS'
      );
    },

    setEditedCallIds: (callIds) => {
      set(
        (state) => {
          state.editedCallIds = callIds;
        },
        false,
        'SET_ALL_CALL_TAKEAWAY_EDITED_CALL_IDS'
      );
    },

    setIsDemoAccount: (isDemoAccount) => {
      set(
        (state) => {
          state.isDemoAccount = isDemoAccount;
        },
        false,
        'SET_IS_CALL_TAKEAWAY_DEMO_ACCOUNT'
      );
    },

    setSelectedCall: (call) => {
      const { allCalls } = get();
      const selectedCallIndex = allCalls.findIndex((c) => c.id === call?.id);
      const nextCall = allCalls[selectedCallIndex + 1];
      const previousCall = allCalls[selectedCallIndex - 1];

      set(
        (state) => {
          state.nextCall = nextCall;
          state.previousCall = previousCall;
          state.selectedCall = call;
        },
        false,
        'SET_SELECTED_CALL_TAKEAWAY_CALL'
      );

      if (!get().isDemoAccount) {
        useSlidePanelStore.setState((state) => ({
          ...state,
          show: !!call,
          panelType: 'callTakeaway',
        }));
      }
    },

    setSelectedCallMetaData: (callMetaData) => {
      set(
        (state) => {
          state.selectedCallMetaData = callMetaData;
        },
        false,
        'SET_SELECTED_CALL_META_DATA_TAKEAWAY_CALL'
      );
    },

    setSelectedDemoTasks: (tasks) => {
      set(
        (state) => {
          state.selectedDemoTasks = tasks ? { tasks } : null;
        },
        false,
        'SET_SELECTED_DEMO_TASKS'
      );
    },

    setUnsavedEditChangesConfig: (config) => {
      set(
        (state) => {
          state.unsavedEditChangesConfig = { ...state.unsavedEditChangesConfig, ...config };
        },
        false,
        'SET_UNSAVED_EDIT_CHANGES_CONFIG'
      );
    },

    handleNextClick: () => {
      const { nextCall } = get();

      if (nextCall) {
        get().setSelectedCall(nextCall);
      }
    },

    handlePreviousClick: () => {
      const { previousCall } = get();

      if (previousCall) {
        get().setSelectedCall(previousCall);
      }
    },

    reset: () => {
      set(
        (state) => {
          state.selectedCallMetaData = null;
          state.selectedCall = null;
          state.defaultAccordion = 'call-summary';
        },
        false,
        'RESET_CALL_TAKEAWAY_PANEL_STORE'
      );

      useSlidePanelStore.setState((state) => ({
        ...state,
        show: false,
        panelType: 'callTakeaway',
      }));
    },
  }),
  {
    name: 'CallTakeawayPanelStore',
    trace: true,
  }
);

export const useCallTakeawayPanelShallowStore = createShallowStore(useCallTakeawayPanelStore);
