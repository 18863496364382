import { useCallback } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { EventData } from '../components/calendar-view/types';
import { getEndTimeString, getTimeString } from '../utils/date-helpers';

type GetAppointmentsEventByProviderIdParamsType = {
  appointments: Appointment[];
  providerId: string;
  locationId: string;
  providerName?: string;
  providerDisplayName?: string;
};

export const useGetAppointmentsEvents = () => {
  const getMappedAppointmentData = (appointment: Appointment, start: string, end: string): EventData => {
    return {
      id: appointment.id ?? '',
      isNonIntegratedSource: appointment.isNonIntegratedSource ?? true,
      startHour: start,
      endHour: end,
      type: 'second',
      appointmentType: appointment.type,
      eventId: appointment.id ?? '',
      createdBySourceId: appointment.createdBySourceId ?? '',
      workstationIds: appointment.workstationIds ?? [],
      name: appointment.person ? `${appointment.person?.firstName} ${appointment.person?.lastName}` : 'Unknown',
      patientId: appointment.person?.personId ?? '',
      locationId: appointment.locationId ?? '',
      startDate: formatDate(dayjs(appointment.start).toDate(), 'MM/DD/YYYY'),
      endDate: formatDate(
        dayjs(appointment.start)
          .add(appointment.duration ?? 0, 'm')
          .toDate(),
        'MM/DD/YYYY'
      ),
      personStatus: appointment.person?.status ?? '',
    };
  };

  const getAppointmentsEventByProviderId = useCallback(
    ({
      appointments,
      locationId,
      providerId,
      providerDisplayName,
      providerName,
    }: GetAppointmentsEventByProviderIdParamsType): EventData[] => {
      return (
        appointments
          ?.filter((event) => {
            const hasUnassignedProvider =
              !event.practitionerName &&
              !!event.providerIds?.length &&
              event?.providerIds[0] === '' &&
              providerId === `unassigned-${event.locationId}` &&
              providerDisplayName === 'unassigned';
            if (hasUnassignedProvider) {
              return providerId === `unassigned-${locationId}` && providerDisplayName === 'unassigned';
            } else {
              return (
                (!!event.providerIds?.length || providerName) &&
                event.locationId === locationId &&
                event.providerIds?.some((id) => {
                  const eventPractitionerName = event.practitionerName?.trim();
                  if (id && providerId) {
                    return id === providerId;
                  } else if (providerName && eventPractitionerName === providerName.trim()) {
                    return true;
                  } else if (providerDisplayName && eventPractitionerName === providerDisplayName.trim()) {
                    return true;
                  }
                  return false;
                })
              );
            }
          })
          .map((appointment) => {
            const start = getTimeString(appointment.start);
            const end = getEndTimeString(appointment.start, appointment.duration);
            return getMappedAppointmentData(appointment, start, end);
          }) || []
      );
    },
    []
  );
  return { getAppointmentsEventByProviderId };
};
