import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { LegacyButtonProps, NakedButton, Text } from '@frontend/design-system';
import { DashboardWidget } from './dashboard-widget-item';
import { useDashboardWidget } from './provider';

export interface DashboardWidgetActionButtonProps extends Omit<LegacyButtonProps, 'children'> {
  iconName: IconName;
  title: string;
}

export const DashboardWidgetActionButton = forwardRef<HTMLButtonElement, DashboardWidgetActionButtonProps>(
  ({ iconName, title, ...props }, ref) => {
    const { isEditMode } = useDashboardWidget();

    return (
      <DashboardWidget>
        <NakedButton
          ref={ref}
          css={[
            buttonStyle,
            props.disabled ? buttonDisabledStyle : buttonEnabledStyle,
            isEditMode && {
              background: theme.colors.neutral10,
              opacity: 0.5,
            },
          ]}
          {...props}
          onClick={isEditMode ? undefined : props.onClick}
        >
          <Icon name={iconName} color={props.disabled ? 'subdued' : 'primary'} />
          <Text
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
            weight='bold'
            color={props.disabled ? 'subdued' : 'primary'}
          >
            {title}
          </Text>
        </NakedButton>
      </DashboardWidget>
    );
  }
);

DashboardWidgetActionButton.displayName = 'DashboardWidgetActionButton';

const buttonStyle = css({
  borderRadius: theme.borderRadius.medium,
  flexGrow: 1,
  marginTop: theme.spacing(0.75),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const buttonEnabledStyle = css({
  background: theme.colors.primary5,
  '&:hover': {
    background: theme.colors.primary10,
  },
  '&:active': {
    background: theme.colors.primary5,
    boxShadow: 'none',
  },
});

const buttonDisabledStyle = css({
  background: theme.colors.neutral10,
  opacity: 0.5,
  cursor: 'default',
});
