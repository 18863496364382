import { Terminal } from '@stripe/terminal-js';
import { TerminalReader } from '@frontend/payments-terminal-controller';

/**
 * @deprecated The status returned from this function will not be accurate,
 * you need to call clearReaderDisplay to make sure the auth session is also active.
 */
export const isReaderConnected = (stripeTerminal: Terminal | undefined) =>
  !!stripeTerminal && stripeTerminal?.getConnectionStatus() !== 'not_connected';

/**
 * @deprecated collect-payment/* will be removed when collect-payment-multi/ is fully live.
 */
export const hasActivePayment = (stripeTerminal: Terminal | undefined) =>
  ['waiting_for_input', 'ready', 'processing'].includes(stripeTerminal?.getPaymentStatus() ?? '');

/**
 * @deprecated collect-payment/* will be removed when collect-payment-multi/ is fully live.
 */
export const devLogger = {
  log: (...params: unknown[]) => {
    if (import.meta.env.MODE !== 'production') console.log(...params);
  },
};

/**
 * @deprecated collect-payment/* will be removed when collect-payment-multi/ is fully live.
 *
 * Sorts readers by connection status and then alphabetically by labe\
 * @returns Sorted readers by status and label
 */
export const sortReaders = (readers: TerminalReader[]) => {
  return [...readers].sort((a, b) => {
    if (a.status === 'online' && b.status === 'offline') {
      return -1;
    }
    if (a.status === 'offline' && b.status === 'online') {
      return 1;
    }
    return a.label.localeCompare(b.label);
  });
};
