import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { PetRelatedAppointmentData } from './reduce-pet-related-appointment-data';

export const getPetBindingFromAppointmentData = (
  appointmentsData: PetRelatedAppointmentData,
  pets: PetDetails[],
  appointmentId?: string
): string | undefined => {
  const appointment =
    appointmentsData?.allAppointments.find((appointment) => appointment.appointmentId === appointmentId) ??
    appointmentsData?.latestAppointment;
  const petFromAppointment = pets.find(
    (pet) => appointment?.serviceableId && pet.petServiceableId === appointment?.serviceableId
  );
  return petFromAppointment?.name ?? pets[0]?.name;
};
