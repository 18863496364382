import { FC, ReactNode, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, Heading, NakedButton, Text } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../constants/self-upgrade';
import {
  getFeaturePromoActionTrackingId,
  getFeatureWiseTrackingId,
  SelfUpgradeLandingPageTrackingIds,
} from '../../constants/tracking-ids';
import { useGetSelfUpgradeAction } from '../../hooks/useGetSelfUpgradeAction';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { getBundleTrialDays, getScheduleDemoLink, getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { FeaturePromotionMode, FeaturePromotionPageBaseProps } from '../../types';

interface Props extends FeaturePromotionPageBaseProps {
  showPremiumBadge?: boolean;
  featureName?: string;
  title: string | ReactNode;
  description: string | ReactNode;
  media?: { src: string; alt: string };
}

export const FeaturePromotionBasePage: FC<Props> = ({
  featureName,
  showPremiumBadge,
  title,
  description,
  isDemoAvailable,
  media,
  mode = 'fullPage',
  handleOpenUpgradeModal,
  handleViewDemo,
}) => {
  const { feature, isBundleUpgradeEligible, recommendedBundle } = useFeatureSubscription();
  const { actionType, actionIconName, actionTextData, isLoading } = useGetSelfUpgradeAction();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'landing-page-promo' });
  const email = getUser()?.username;

  const handlePromoActionClick = useCallback(() => {
    switch (actionType) {
      case SelfUpgradeActionType.SCHEDULE_CALL:
        window.open(getScheduleDemoLink(feature, email), '_blank');
        break;
      case SelfUpgradeActionType.REQUEST_UPGRADE:
      case SelfUpgradeActionType.UPGRADE_NOW:
      case SelfUpgradeActionType.FREE_TRIAL:
        handleOpenUpgradeModal();
        break;
      default:
        return;
    }
  }, [actionType, email]);

  const actionButtonTrackingId = useMemo<string | undefined>(() => {
    return getFeaturePromoActionTrackingId({
      feature,
      source: 'landing-page',
      action: actionType,
      upgradeType: isBundleUpgradeEligible ? 'bundle' : 'feature',
    });
  }, [actionType, feature, isBundleUpgradeEligible]);

  return (
    <div css={layoutStyles(mode)}>
      <div css={titleBadgeStyles}>
        {(showPremiumBadge || isBundleUpgradeEligible) && (
          <Chip variant='seaweed' css={{ maxWidth: 'max-content' }}>
            {t('Premium Feature')}
          </Chip>
        )}
        {typeof title === 'string' ? <Heading level={2}>{title}</Heading> : title}
      </div>

      {typeof description === 'string' ? (
        <Text size={'large'} textAlign='center' css={{ maxWidth: 512, padding: theme.spacing(0, 3) }}>
          {isBundleUpgradeEligible
            ? t(
                '{{featureName}} is included in the {{bundleName}} Bundle along with other powerful features to improve office efficiency. Try {{bundleName}} free for {{count}} days.',
                {
                  featureName,
                  bundleName: getUpgradeBundleDisplayName(recommendedBundle),
                  count: getBundleTrialDays(recommendedBundle),
                }
              )
            : description}
        </Text>
      ) : (
        description
      )}
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(1) }}>
        {!isLoading && actionType !== SelfUpgradeActionType.NONE && (
          <>
            <NakedButton trackingId={actionButtonTrackingId} css={actionButtonStyles} onClick={handlePromoActionClick}>
              <Icon name={actionIconName} size={16} color='white' />
              <Text as='span' color='white' weight='bold'>
                {actionTextData?.primaryButtonText}
              </Text>
            </NakedButton>
            {actionTextData?.actionInfoText && (
              <Text color='light' size='medium'>
                {actionTextData?.actionInfoText}
              </Text>
            )}
          </>
        )}
        {isDemoAvailable && (
          <NakedButton
            trackingId={getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.viewDemoAction)}
            css={{ marginTop: theme.spacing(1) }}
            onClick={handleViewDemo}
          >
            <Text color='primary' weight='bold' size='medium'>
              {t('View Demo')}
            </Text>
          </NakedButton>
        )}
      </div>
      {!!media?.src && <img src={media.src} css={mediaStyles} alt={media?.alt} />}
    </div>
  );
};

const layoutStyles = (mode: FeaturePromotionMode) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: mode === 'fullPage' ? theme.spacing(8, 0) : 0,
  });

const titleBadgeStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
});

const mediaStyles = css({
  height: 'auto',
  width: 700,
  maxWidth: '100%',
  padding: theme.spacing(0, 3),
  borderRadius: theme.borderRadius.medium,
});
const actionButtonStyles = css({
  background: theme.colors.primary50,
  height: theme.spacing(4),
  padding: theme.spacing(0, 1.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  borderRadius: theme.borderRadius.small,
});
