import { ReactNode } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { DEFAULT_CONTEXT_VALUE, useGetContextValues } from '../hooks';
import { TaskCenterContext } from '../types';

export const TaskCenterProviderContext = createContext<TaskCenterContext>({
  ...DEFAULT_CONTEXT_VALUE,
});

export const useTaskCenterStore = <T extends (keyof TaskCenterContext)[]>(values: T) => {
  return useContextSelector(TaskCenterProviderContext, (context) =>
    values.reduce((acc, key) => ({ ...acc, [key]: context?.[key] }), {} as Pick<TaskCenterContext, T[number]>)
  );
};

export const TaskCenterProvider = ({ children }: { children: ReactNode }) => {
  const values = useGetContextValues();

  return <TaskCenterProviderContext.Provider value={values}>{children}</TaskCenterProviderContext.Provider>;
};
