import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { VoicemailWithCallRecord } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { VoiceMailboxApi } from '@frontend/api-voicemail-boxes';
import { VoicemailApi } from '@frontend/api-voicemails';
import { getWeaveToken } from '@frontend/auth-helpers';
import { Page } from '@frontend/components';
import { getInitialParams } from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { CachedAudioScrubber } from '../components/common/cached-audio-scrubber';
import { queryKeys } from '../query-keys';
import { formatDate } from '../utils/dates';
import { getFullName, isStringNumeric } from '../utils/phone-utils';

type Props = {
  mailboxId: string;
  messageId: string;
};

const generateMediaPath = (message: VoicemailWithCallRecord | undefined, locationId: string) => {
  const weaveToken = getWeaveToken();
  const currentEnv = getInitialParams().backendApi;
  const mediaId = message?.forwardedMessageId || message?.mediaId;

  if (!mediaId) {
    return '';
  }
  const mediaPath = `${currentEnv}/phone-exp/voicemail/download/${mediaId}?token=${weaveToken}&location_id=${locationId}`;
  return mediaPath;
};

export const useVoicemailMessage = (mailboxId: string, messageId: string) => {
  const { selectedLocationIds } = useAppScopeStore();
  const singleLocationId = selectedLocationIds[0];
  const { getCustomizationFlagValue } = useScopedAppFlagStore();
  const isTranscriptionEnabled = getCustomizationFlagValue('voicemailtranscription');

  const { data, isLoading, isError, error } = useLocalizedQuery({
    queryKey: queryKeys.voicemailMessage(mailboxId, messageId),
    queryFn: () => VoicemailApi.getVoicemail({ messageId: messageId }),
    enabled: !!(singleLocationId && mailboxId && messageId),
    retry: 1,
  });

  const person = data?.voicemail?.person;
  const message = data?.voicemail?.message;
  const voicemailMessage = {
    ...message,
    callerName: getFullName(person?.firstName, person?.lastName, message?.callerName),
  };
  const audioSrc = generateMediaPath(data?.voicemail?.message, singleLocationId);

  const { data: transcriptData } = useLocalizedQuery({
    queryKey: queryKeys.voicemailTranscription(messageId),
    queryFn: () => VoiceMailboxApi.getTranscription(messageId),
    enabled: !!isTranscriptionEnabled,
    retry: 1,
  });

  return {
    voicemailMessage,
    audioSrc,
    transcriptData,
    isTranscriptionEnabled,
    isLoading,
    isError,
    error,
  };
};

export const VoicemailMessagePage = ({ mailboxId, messageId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'voicemail-message' });

  const { voicemailMessage, audioSrc, transcriptData, isTranscriptionEnabled, isLoading, isError, error } =
    useVoicemailMessage(mailboxId, messageId);

  if (isError) {
    return <Text color='error'>Failed to load voicemail: {JSON.stringify(error)}</Text>;
  }

  return (
    <Page title={t('Voicemail Message')} loading={isLoading || !voicemailMessage}>
      {voicemailMessage && (
        <VoicemailMessagePageInner
          voicemailMessage={voicemailMessage}
          audioSrc={audioSrc}
          transcriptData={transcriptData}
          isTranscriptionEnabled={isTranscriptionEnabled}
        />
      )}
    </Page>
  );
};

type PageInnerProps = {
  voicemailMessage: VoicemailWithCallRecord;
  audioSrc: string;
  transcriptData: ReturnType<typeof useVoicemailMessage>['transcriptData'];
  isTranscriptionEnabled: boolean;
};
export const VoicemailMessagePageInner = ({
  voicemailMessage,
  audioSrc,
  transcriptData,
  isTranscriptionEnabled,
}: PageInnerProps) => {
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const singleLocationId = selectedLocationIds[0];
  const { t } = useTranslation('phone', { keyPrefix: 'voicemail-message' });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const customWidth = screenWidth > 600 ? 400 : screenWidth - 200;
  const locationData = accessibleLocationData[singleLocationId];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <section>
        <Text css={styles.heading}>{t('audio')}</Text>
        <CachedAudioScrubber
          singlePlayer
          customWidth={customWidth}
          filePath={audioSrc}
          mediaId={voicemailMessage?.mediaId ?? ''}
        />
      </section>

      <Attribute
        title={t('Caller Name')}
        description={
          !!voicemailMessage?.callerName && !isStringNumeric(voicemailMessage?.callerName)
            ? voicemailMessage?.callerName
            : t('Unknown')
        }
      />
      <Attribute title={t('Phone Number')} description={formatPhoneNumber(voicemailMessage?.callerNumber)} />
      <Attribute
        title={t('Time Received')}
        description={formatDate(voicemailMessage?.createdAt, 'h:mma z, MMM D YYYY', locationData?.timezone)}
      />

      {isTranscriptionEnabled && !!transcriptData?.exists && (
        <section css={styles.attribute}>
          <Text css={styles.heading}>{t('Voicemail Transcript')}</Text>
          <Text size='medium' css={[styles.quotedText, styles.descStyles]}>
            {transcriptData.text?.trim()}
          </Text>
        </section>
      )}

      {isTranscriptionEnabled && !transcriptData?.exists && (
        <section css={styles.attribute}>
          <Text css={styles.heading}>{t('Voicemail Transcript')}</Text>
          <Text size='medium' css={[styles.italicizedText, styles.descStyles]}>
            {t(`Transcript unavailable.`)}
          </Text>
        </section>
      )}
    </>
  );
};

type AttributeProps = {
  title: string;
  description: string;
};

export const Attribute = ({ title, description }: AttributeProps) => {
  return (
    <section css={styles.attribute}>
      <Text css={styles.heading}>{title}</Text>
      <Text size='medium' css={styles.descStyles}>
        {description}
      </Text>
    </section>
  );
};

const styles = {
  heading: css`
    text-transform: capitalize;
    color: ${theme.colors.neutral50};
    font-size: ${theme.fontSize(14)};
    font-weight: 600;
    margin-bottom: ${theme.spacing(0.5)};
    line-height: ${theme.spacing(2.5)};
  `,

  attribute: css`
    margin-top: ${theme.spacing(3)};
    max-width: 400px;

    @media (max-width: 400px) {
      max-width: auto;
    }
  `,

  descStyles: css`
    line-height: ${theme.spacing(2.5)};
  `,

  quotedText: css`
    quotes: '\\0022''\\0022''\\0027''\\0027';
    max-width: ${theme.spacing(50)};

    :before {
      content: open-quote;
    }

    :after {
      content: close-quote;
    }
  `,

  italicizedText: css`
    font-style: italic;
  `,
};
