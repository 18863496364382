import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';

export const getCombinedAppointmentTypes = (
  locationIds: string[],
  appointmentTypes: AppointmentTypesTypes.AppointmentType[],
  appointments: Appointment[]
) => {
  const types: AppointmentTypesTypes.CombinedAppointmentTypes[] = [];

  appointmentTypes.forEach((type) => {
    locationIds.forEach((locationId) => {
      if (type.locationId === locationId) {
        if (types.some((t) => t.name === type.name && t.id === type.id)) return;
        types.push({ name: type.name, id: type.id });
      }
    });
  });

  appointments?.forEach((appointment) => {
    locationIds.forEach((locationId) => {
      if (!appointment.type) return;
      if (appointment.locationId === locationId) {
        if (types.some((t) => t.name === appointment.type)) return;
        types.push({ name: appointment.type, id: appointment.type });
      }
    });
  });

  return {
    combinedAppointmentTypes: types,
  };
};
