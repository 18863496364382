import { SkeletonLoader } from '@frontend/design-system';
import { styles } from './payment-methods';

export const PaymentMethodsLoader = () => {
  return (
    <section css={styles.paymentMethods(true)}>
      <SkeletonLoader height={'64px'} css={styles.iconButton} />
      <SkeletonLoader height={'64px'} css={styles.iconButton} />
      <SkeletonLoader height={'64px'} css={styles.iconButton} />
      <SkeletonLoader height={'64px'} css={styles.iconButton} />
      <SkeletonLoader height={'64px'} css={styles.iconButton} />
      <SkeletonLoader height={'64px'} css={styles.iconButton} />
    </section>
  );
};
