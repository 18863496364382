import { SetSubmissionConfirmationResponse } from '@weave/schema-gen-ts/dist/schemas/forms/forms_api.pb';
import { useMutation, useQueryClient } from 'react-query';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { FormsDigitization } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useFormDigitizationStore } from '../../../../../provider';

interface UseSubmitForDigitizationResults {
  submitForDigitization: (
    digitizationNote?: string,
    digitizationAssignLocations?: string[]
  ) => Promise<SetSubmissionConfirmationResponse>;
}

export const useSubmitForDigitization = (): UseSubmitForDigitizationResults => {
  const { t } = useTranslation('forms');
  const { locationId } = useFormDigitizationStore(['locationId']);

  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(FormsDigitization.API.finishFormsUpload, {
    onSuccess: () => {
      queryClient.invalidateQueries(DigitalFormsQueries.endpointKeys.getUploadedFormsDetails);
      queryClient.invalidateQueries(DigitalFormsQueries.endpointKeys.formSubmitFlag);
    },
    onError: () => {
      alert.error(t('Something went wrong. Please try again'));
    },
  });

  return {
    submitForDigitization: (digitizationNote, digitizationAssignLocations) =>
      mutateAsync({ locationId, digitizationNote, digitizationAssignLocations }),
  };
};
