import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { VirtuosoGrid } from 'react-virtuoso';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';

type Props<T> = {
  DisplayedCard: (index: number, template: T) => ReactElement;
  EmptyCardState?: ReactElement;
  getNextPage: () => void;
  hasNextPage?: boolean;
  height: string | number;
  isFetched: boolean;
  isFetchingNextPage: boolean;
  templates: T[];
};

/*
  EmailTemplates is a component that displays a list of paginated items in a grid format.
  @params DisplayedCard: A function that returns a ReactElement to be displayed for each item in the list.
  @params EmptyCardState: A ReactElement to be displayed when the list is empty.
  @params getNextPage: A function to fetch the next page of data.
  @params hasNextPage: A boolean to determine if there are more pages to fetch.
  @params height: The height of the container which needs to be set because of the virtuoso library.
  @params isFetched: A boolean to determine if the data has been fetched.
  @params isFetchingNextPage: A boolean to determine if the next page is being fetched.
  @params templates: An array of T which will need to be passed in as an argument to the consumption of this EmailTemplates component.
*/

export const EmailTemplates = <T,>({
  DisplayedCard,
  EmptyCardState,
  getNextPage,
  hasNextPage,
  height,
  isFetched,
  isFetchingNextPage,
  templates,
  ...rest
}: Props<T>) => {
  const { t } = useTranslation('bulk-messaging');

  {
    /* Virtuoso doesn't honor a 100% height for the parent container - needs an explicit height */
  }
  return (
    <div css={{ height, width: '100%', paddingBottom: theme.spacing(5) }} {...rest}>
      {templates?.length ? (
        <VirtuosoGrid
          css={css`
            width: 100%;
            .virtuoso-grid-list {
              display: flex;
              flex-wrap: wrap;
              gap: ${theme.spacing(2)};
              .virtuoso-grid-item {
                width: calc(33.33% - ${theme.spacing(1.35)});
                @media (max-width: ${breakpoints.medium.max}px) {
                  width: calc(50% - ${theme.spacing(1.35)});
                }
                @media (max-width: ${breakpoints.small.min}px) {
                  width: 100%;
                }
              }
            }
          `}
          data={templates}
          endReached={getNextPage}
          totalCount={templates.length}
          itemContent={DisplayedCard}
          components={{
            Footer: () => {
              if (isFetchingNextPage) {
                return <SpinningLoader />;
              } else if (isFetched && hasNextPage && !isFetchingNextPage) {
                return (
                  <Text css={{ margin: 0, padding: theme.spacing(6, 0), textAlign: 'center' }}>
                    🎉 {t('You have reached the end')}
                  </Text>
                );
              }
              return null;
            },
          }}
        />
      ) : (
        EmptyCardState
      )}
    </div>
  );
};
