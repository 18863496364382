import { theme } from '@frontend/theme';
import { BannerNotification, BannerProps, Heading, Text } from '@frontend/design-system';

type ScheduleBannerProps = Omit<BannerProps, 'children' | 'status'> & {
  message: string;
  title?: string;
  fullWidth?: boolean;
  status?: BannerProps['status'];
};

export const ScheduleBanner = ({
  title,
  message,
  fullWidth = false,
  status = 'info',
  ...rest
}: ScheduleBannerProps) => {
  return (
    <BannerNotification
      status={status}
      fullWidth={fullWidth}
      title={title ?? ''}
      css={{ marginBottom: theme.spacing(2), padding: theme.spacing(2) }}
      {...rest}
    >
      {title && <Heading level={3}>{title}</Heading>}
      <Text>{message}</Text>
    </BannerNotification>
  );
};
