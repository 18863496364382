import React, { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMediaMatches } from '@frontend/responsiveness';
import { validateUUIDV4 } from '@frontend/string';
import { theme } from '@frontend/theme';
import { SearchField, useControlledField, useDebouncedValue } from '@frontend/design-system';

type CallIntelSearchComponentProps = {
  debounceDelay?: number;
  minSearchLength?: number;
  onChange: (value: string, debounced?: boolean) => void;
  trackingId?: string;
  value: string;
};

const cleanSearchQuery = (query: string): string => {
  if (validateUUIDV4(query)) {
    return query;
  } else {
    let cleanedQuery = query
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 ]/gi, '');

    if (/^\d[\d\s]*$/.test(cleanedQuery)) {
      cleanedQuery = cleanedQuery.replace(/\s+/g, '');
    } else {
      cleanedQuery = cleanedQuery.replace(/\s+/g, ' ');
    }

    return cleanedQuery;
  }
};

export const CallIntelSearch: React.FC<CallIntelSearchComponentProps> = ({
  debounceDelay = 1000,
  minSearchLength = 3,
  onChange: handleSearchOnChange,
  trackingId,
  value,
  ...rest
}) => {
  const { t } = useTranslation('analytics');

  const { matches } = useMediaMatches();

  const searchField = useControlledField({
    type: 'text',
    onChange: (value: string) => {
      handleSearchOnChange(value);
    },
    value,
    validator: ({ value }) => {
      const newValue = value;
      const cleanedQuery = cleanSearchQuery(newValue);
      return cleanedQuery.length < minSearchLength ? t(`Search for at least ${minSearchLength} characters`) : '';
    },
    validateOnChange: true,
  });

  const isMobileScreen = matches.useXSmallMax();

  const placeHolder = useMemo(
    () => (!isMobileScreen ? t('Search Name or Phone Number') : t('Search Name or Phone')),
    [isMobileScreen, t]
  );

  const debouncedSearchValue = useDebouncedValue(searchField.value, debounceDelay);

  useEffect(() => {
    if (debouncedSearchValue) {
      handleSearchOnChange(cleanSearchQuery(debouncedSearchValue), true);
    }
  }, [debouncedSearchValue, handleSearchOnChange, minSearchLength]);

  return (
    <SearchField
      css={styles.searchWrapper}
      data-trackingid={trackingId}
      name='search'
      placeholder={placeHolder}
      {...searchField}
      {...rest}
    />
  );
};

const styles = {
  searchWrapper: css`
    width: 318px;
    @media screen and (max-width: 475px) {
      width: 280px;
      > input {
        font-size: ${theme.font.size.medium};
      }
    }

    @media screen and (max-width: 425px) {
      width: auto;
    }
  `,
};
