import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds, PaymentActionTrackingIds } from '../../tracking-ids';
import { PaymentActionEnum } from '../../types';

export const PaymentInfoActionCard: FC = () => {
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });
  const { hasAccountCreationPermission, handleAction, source } = usePaymentsPromotion();
  return (
    <div css={cardStyles}>
      <div css={bodyStyles}>
        <Icon name='bulb-small' css={iconStyles} size={12} />
        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <Text size='medium' weight='bold'>
            {t('Your plan includes Payments')}
          </Text>
          <Text size='medium'>{t('Customers can easily pay online with Text-to-Pay and Online Bill Pay')}</Text>
        </div>
      </div>
      <div css={actionFooterStyles}>
        {!hasAccountCreationPermission ? (
          <Button
            variant='tertiary'
            onClick={() => handleAction(PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE)}
            trackingId={getPaymentHVATrackingIds({
              source,
              trackingId: PaymentActionTrackingIds[PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE],
            })}
          >
            {t('Learn More')}
          </Button>
        ) : (
          <Button
            variant='tertiary'
            onClick={() => handleAction(PaymentActionEnum.CREATE_INVOICE)}
            trackingId={getPaymentHVATrackingIds({
              source,
              trackingId: PaymentActionTrackingIds[PaymentActionEnum.CREATE_INVOICE],
            })}
          >
            {t('Request Payment')}
          </Button>
        )}
      </div>
    </div>
  );
};

const cardStyles = css({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.borderRadius.large,
  border: `1px solid ${theme.colors.neutral20}`,
  gap: theme.spacing(1),
  padding: theme.spacing(2),
});

const iconStyles = css({
  borderRadius: theme.borderRadius.full,
  backgroundColor: theme.colors.tangerine10,
  color: theme.colors.tangerine50,
  padding: theme.spacing(0.5),
  boxSizing: 'content-box',
  flexShrink: 0,
});

const bodyStyles = css({
  display: 'flex',
  gap: theme.spacing(0.5),
});

const actionFooterStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: theme.spacing(1),
});
