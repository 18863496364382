import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps, Text } from '@frontend/design-system';

type Props = {
  deleteContactList: (listId: string) => Promise<void>;
  listIdToDelete?: string;
  modalProps: ModalControlModalProps;
  setListIdToDelete: (listId?: string) => void;
};

export const DeleteListModal = ({ deleteContactList, listIdToDelete, modalProps, setListIdToDelete }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <ConfirmationModal
      {...modalProps}
      title={t('Are You Sure?')}
      onConfirm={() => {
        if (!listIdToDelete) return;
        deleteContactList(listIdToDelete);
        modalProps.onClose();
        setListIdToDelete(undefined);
        // TODO: Update the campaign segments which may now have an orphaned contact list stuck on them
      }}
      destructive
      modalCss={{ minWidth: 600 }}
    >
      <Text>{t('Are you sure you want to delete this list?')}</Text>
    </ConfirmationModal>
  );
};
