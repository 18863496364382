import { css } from '@emotion/react';
import { MissedCallTexts } from '@frontend/empty-states#svg';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../../utils';
import { highlightValueStyles } from '../../styles';
import { useMCTRoiShallowStore } from '../hooks';

interface Props {
  value: number;
}

export const HighlightMCTValue = ({ value }: Props) => {
  const { t } = useTranslation('analytics');
  const { definitionsHelperDateRange } = useMCTRoiShallowStore('definitionsHelperDateRange');

  return (
    <div css={[highlightValueStyles, styles.wrapper]}>
      <Text style={{ maxWidth: '620px', zIndex: 1 }}>
        <Trans t={t}>
          <Text as='span' weight='bold'>
            Missed Call Text
          </Text>{' '}
          <Text as='span'>generated</Text>{' '}
          <Text as='strong' color='primary' weight='bold'>
            {formatters.currency.format(value)}
          </Text>{' '}
          <Text as='span'>for your business</Text> <Text as='span'>{definitionsHelperDateRange} 🎉.</Text>
        </Trans>
      </Text>
      <MissedCallTexts className='graphic' />
    </div>
  );
};

const styles = {
  wrapper: css`
    background-color: ${theme.colors.primary5};
    border: 1px solid ${theme.colors.primary20};
  `,
};
