import { TrialStatus } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { FreeTrialsTypes, FreeTrialsAPI } from '@frontend/api-free-trials';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Modal, Text } from '@frontend/design-system';
import { FeatureTrialStateDetails } from './FeatureTrialStateDetails';

type ModalProps = {
  advanceModal: () => void;
  featureEnumState: (data: FreeTrialsTypes.Feature) => void;
  setEndDate: (endDate: string) => void;
  onModalClose: () => void;
  multipleTrialsKey: number;
};

export const MultipleTrialsModal = ({
  advanceModal,
  featureEnumState,
  setEndDate,
  onModalClose,
  multipleTrialsKey,
}: ModalProps) => {
  const { t } = useTranslation('multipleTrials');
  const { selectedLocationIds } = useAppScopeStore();
  const { data: trialStateData } = FreeTrialsAPI.useTrialHistory(selectedLocationIds[0]);

  const handleStateUpdateClick = (feature: FreeTrialsTypes.Feature, endDate: string) => {
    advanceModal();
    setEndDate(endDate);
    featureEnumState(feature);
  };

  return (
    <>
      <Modal.Body key={multipleTrialsKey}>
        <div css={{ margin: theme.spacing(2, 0) }}>
          <Text>
            {t(
              'See your active free trials below. View more details for training material or to manage your subscription.'
            )}
          </Text>
        </div>
        {trialStateData?.trialState?.FREE_TRIAL_ABOUT_TO_EXPIRE?.features?.map((freeTrial, index) => (
          <FeatureTrialStateDetails
            key={index}
            state={TrialStatus.FREE_TRIAL_ABOUT_TO_EXPIRE}
            feature={freeTrial.featureEnum}
            endDate={freeTrial.endDate}
            onViewDetailsClick={handleStateUpdateClick}
          />
        ))}
        {trialStateData?.trialState?.FREE_TRIAL_ACTIVE?.features?.map((freeTrial, index) => (
          <FeatureTrialStateDetails
            key={index}
            state={TrialStatus.FREE_TRIAL_ACTIVE}
            feature={freeTrial.featureEnum}
            endDate={freeTrial.endDate}
            onViewDetailsClick={handleStateUpdateClick}
          />
        ))}
        {trialStateData?.trialState?.FREE_TRIAL_EXPIRED?.features?.map((freeTrial, index) => (
          <FeatureTrialStateDetails
            key={index}
            state={TrialStatus.FREE_TRIAL_EXPIRED}
            feature={freeTrial.featureEnum}
            endDate={freeTrial.endDate}
            onViewDetailsClick={handleStateUpdateClick}
          />
        ))}
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Close'), onClick: onModalClose }} />
    </>
  );
};
