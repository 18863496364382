import { Dispatch, SetStateAction, useMemo } from 'react';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { MessagesTypes, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { getUser } from '@frontend/auth-helpers';
import { StatusAlert } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { InboxType, useInboxNavigate } from '@frontend/inbox-navigation';
import { useNotificationContext } from '@frontend/notifications';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { MessageStatus } from '../components/message-status-indicator';
import { ExtendedDepartment } from '../types';
import { useChildMatch } from './use-child-match';

type SelectedThreads = Record<string, string[]>;
type SetSelectedThreads = Dispatch<SetStateAction<Record<string, string[]>>>;
type UseUnifiedListItemProps = {
  thread: ScheduledSmsThread | ThreadDraft | MessagesTypes.InboxListItem;
  departments: ExtendedDepartment[];
  personObj?: SchemaSMSSharedModels.Person;
  isSelectAll: boolean;
  selectedThreadsState: [SelectedThreads, SetSelectedThreads];
  isBlocked: boolean;
  body: string;
  scheduledThreadsMap?: Record<string, ScheduledSmsThread[]>;
  status: MessageStatus;
  isRead: boolean;
  hasError: boolean;
  inboxType: InboxType;
};
export const useUnifiedListItem = ({
  thread,
  departments,
  personObj,
  isSelectAll,
  selectedThreadsState,
  isBlocked,
  body,
  scheduledThreadsMap,
  status,
  isRead,
  hasError,
  inboxType,
}: UseUnifiedListItemProps): ReturnUseUnifiedListItem => {
  const user = getUser();
  const alert = useAlert();
  const { t } = useTranslation('inbox');
  const { selectedLocationIds } = useAppScopeStore();
  const { navigateToThread, closeThread } = useInboxNavigate();
  const formatTimestamp = useTimestampFormatter();

  const notificationTrayContext = useNotificationContext();

  const childMatch = useChildMatch('/:groupId/:threadId');
  const currentThreadId = childMatch?.params['threadId'];
  const { threadId, departmentId, locationId, personPhone } = thread;
  const [selectedThreads, setSelectedThreads] = selectedThreadsState;

  const { data: personApiData } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: 'personId' in thread ? thread.personId : '',
      locationIds: selectedLocationIds,
    },
    { enabled: !personObj && !!('personId' in thread && thread.personId) }
  );

  const person = personObj ?? personApiData;

  const personName = person
    ? PersonHelpers.getFullName({
        FirstName: person?.firstName,
        PreferredName: person?.preferredName,
        LastName: person?.lastName,
      })
    : '';
  const title = personName || formatPhoneNumber(personPhone);

  const isSelected = threadId === currentThreadId;
  const department = departments.find((dept) => dept.id === departmentId);

  const showDepartmentChip = departments.length > 1 && !!department;
  const showLocationChip = selectedLocationIds.length > 1;
  const shouldRenderChipsRow = showLocationChip || showDepartmentChip;

  const messageStatusAlert: StatusAlert | null = useMemo(() => {
    switch (status) {
      case MessageStatus.DRAFT:
        return { text: t('Draft'), color: 'light', weight: 'regular' };
      case MessageStatus.ERROR:
        return { text: t('Not Delivered'), color: 'error', weight: 'bold' };
      case MessageStatus.SCHEDULED: {
        if (scheduledThreadsMap?.[thread.threadId]?.length) {
          return {
            text: formatTimestamp(scheduledThreadsMap[thread.threadId]?.[0]?.sendAt),
            customColor: theme.colors.warning60,
            weight: 'bold',
          };
        } else {
          return {
            text: 'sendAt' in thread ? formatTimestamp(thread.sendAt) : t('Scheduled'),
            customColor: theme.colors.warning60,
            weight: 'bold',
          };
        }
      }
      case MessageStatus.PAUSED_SCHEDULED:
        return { text: t('Paused'), customColor: theme.colors.neutral50 };
      default:
        return null;
    }
  }, [status]);

  const setThreadStatusNewMutation = SMSDataV3.Mutations.useSetThreadsStatusNewMutation();
  const setThreadStatusReadMutation = SMSDataV3.Mutations.useSetThreadsStatusReadMutation();

  const markNoReplyNeededMutation = SMSDataV3.Mutations.useSetThreadsNoReplyNeededMutation({
    options: {
      onSuccess: ({ succeeded }) => {
        if (succeeded.length) alert.success(t('Conversation marked as needing no reply'));
        else alert.error(t('Conversation could not be marked as needing no reply right now'));
      },
      onError: () => {
        alert.error(t('Conversation could not be marked as needing no reply right now'));
      },
    },
  });

  const markNoReplyNeeded = () => {
    markNoReplyNeededMutation.mutateAsync({
      groupIds: selectedLocationIds,
      threadIds: [thread.threadId],
      userId: user?.userID ?? '',
    });
  };

  const onToggleRead = async () => {
    if (isRead) {
      setThreadStatusNewMutation.mutateAsync(
        {
          locationId,
          threadIds: [thread.threadId],
          userId: user?.userID ?? '',
        },
        {
          onSuccess: () => {
            alert.success(t('Marked as unread'));
          },
          onError: () => {
            alert.error(t('Error marking as unread'));
          },
        }
      );
    } else {
      setThreadStatusReadMutation.mutateAsync(
        {
          locationId,
          threadIds: [thread.threadId],
          userId: user?.userID ?? '',
        },
        {
          onSuccess: () => {
            alert.success(t('Marked as read'));
          },
          onError: () => {
            alert.error(t('Error marking as read'));
          },
        }
      );
    }
  };

  const archiveThreadMutation = SMSDataV3.Mutations.useSetThreadsArchivedMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Thread archived'));
      },
      onError: () => {
        alert.error(t('Error archiving thread'));
      },
    },
  });
  const unarchiveThreadMutation = SMSDataV3.Mutations.useSetThreadsUnarchivedMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Thread unarchived'));
      },
      onError: () => {
        alert.error(t('Error unarchiving thread'));
      },
    },
  });

  const onToggleArchived = async () => {
    if (inboxType === InboxType.ARCHIVED) {
      unarchiveThreadMutation.mutateAsync({
        locationId,
        userId: user?.userID ?? '',
        threadIds: [thread.threadId],
      });
    } else {
      archiveThreadMutation.mutateAsync({
        locationId,
        userId: user?.userID ?? '',
        threadIds: [thread.threadId],
      });
      closeThread();
    }
  };

  const goToThread = async () => {
    navigateToThread({
      threadId: thread.threadId,
      groupId: thread.locationId,
      personId: person?.personId,
      personPhone: thread.personPhone,
      replace: true,
    });
    if (!hasError && user?.userID && status === MessageStatus.UNREAD)
      setThreadStatusReadMutation.mutateAsync({
        locationId,
        threadIds: [thread.threadId],
        userId: user?.userID ?? '',
      });
    notificationTrayContext.markThreadNotificationsAsRead(thread.threadId, thread.locationId);
  };

  const messageBodyPreviewText = isBlocked
    ? t('Blocked')
    : body ||
      (thread.numMedia ? `${thread.numMedia} ${t('Attachment', { count: thread.numMedia })}` : t('Nothing to show'));

  const isCheckboxSelected = isSelectAll
    ? !selectedThreads[locationId]?.includes(threadId)
    : !!selectedThreads[locationId]?.includes(threadId);

  const setCheckboxSelected = (selected: boolean) => {
    let threads: string[] = [];
    const filteredThreads = selectedThreads[locationId]?.filter((threadId) => threadId !== thread.threadId) ?? [];
    const updatedThreads = [...(selectedThreads[locationId] ?? []), thread.threadId];

    if (isSelectAll) {
      threads = selected ? filteredThreads : updatedThreads;
    } else {
      threads = selected ? updatedThreads : filteredThreads;
    }

    setSelectedThreads((prev) => ({ ...prev, [locationId]: threads }));
  };

  const setThreadsActionableMutation = SMSDataV3.Mutations.useSetThreadsActionableMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Thread moved to All'));
      },
      onError: () => {
        alert.error(t('There was an error! Please try again later.'));
      },
    },
  });

  const onSetThreadsActionable = async () => {
    setThreadsActionableMutation.mutateAsync({
      locationId,
      userId: user?.userID ?? '',
      threadIds: [thread.threadId],
      actionable: false,
    });
    closeThread();
  };

  return {
    isSelected,
    department,
    chips: { showDepartmentChip, showLocationChip, shouldRenderChipsRow },
    person,
    title,
    checkboxState: [isCheckboxSelected, setCheckboxSelected],
    messageBodyPreviewText,
    messageStatusAlert,
    toggleActions: {
      onToggleRead,
      onToggleArchived,
    },
    goToThread,
    markNoReplyNeeded,
    onSetThreadsActionable,
  };
};

type ReturnUseUnifiedListItem = {
  isSelected: boolean;
  department?: ExtendedDepartment;
  chips: {
    showDepartmentChip: boolean;
    showLocationChip: boolean;
    shouldRenderChipsRow: boolean;
  };
  person?: SchemaSMSSharedModels.Person | Person;
  title: string;
  checkboxState: [boolean, (selected: boolean) => void];
  messageBodyPreviewText: string;
  messageStatusAlert: StatusAlert | null;
  toggleActions: {
    onToggleRead: () => Promise<void>;
    onToggleArchived: () => void;
  };
  markNoReplyNeeded: () => void;
  goToThread: () => void;
  onSetThreadsActionable: () => void;
};
