import { useEffect, useMemo, useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  VoicemailBox,
  VoicemailMessage,
} from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { ViewStatus } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { VoicemailEventType } from '@weave/schema-gen-ts/dist/shared/phone/v1/voicemail/message.pb';
import dayjs from 'dayjs';
import { omit } from 'lodash-es';
import { InfiniteData, useQueryClient } from 'react-query';
import { Row } from 'react-table';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { TagsUtils } from '@frontend/api-tag';
import { VoicemailApi, VoicemailTypes as VmTypes } from '@frontend/api-voicemails';
import { Chips } from '@frontend/chips';
import { EmptyStateConfig } from '@frontend/components';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation, i18next } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { isPartialPhoneNumber, isPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore, useOnScopeChange, useScopedInfiniteQuery, useScopedQuery } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  Button,
  Chip,
  Table,
  TableInstance,
  TextLink,
  useAlert,
  useModalControl,
  useTooltip,
} from '@frontend/design-system';
import { useVoicemailUpdateStore } from '../../hooks/use-new-voicemail-store';
import {
  AllCallsTable,
  PaginationSettings,
  VoicemailFiltersType,
  usePhonePageSettingShallowStore,
} from '../../hooks/use-phone-config-state-store';
import { useTags } from '../../hooks/use-tags';
import { useVoicemailTagMutation } from '../../hooks/use-voicemail-tag-mutations';
import { transcriptionTrackingId } from '../../pendo-tracking';
import { queryKeys } from '../../query-keys';
import { getActionIcon } from '../../ui-utils';
import {
  defaultDateRangeMap,
  formatEndDateWithTimeZone,
  formatStartDateWithTimeZone,
  getFormattedCallerName,
} from '../../utils';
import { CachedAudioScrubber } from '../all-calls/cached-audio-scrubber';
import { ProfileCard } from '../all-calls/profile-card';
import { Action, CustomActionCell } from '../all-calls/shared-buttons';
import { ToolbarChipFilter } from '../all-calls/toolbar-filter';
import { MessageStatus, VoicemailQueryData, VoicemailTableDataRow } from '../all-calls/types';
import { getTimePeriod } from '../all-calls/utils';
import { DEFAULT_NO_TIME_PERIOD } from '../filter-selectors/time-period-selector';
import { TranscriptionContext } from '../transcription/types';
import { getTranscriptionUniqueKey } from '../transcription/utils';
import { VoicemailsTrayFilters } from './vm-filters';
import { AddTag, VoicemailTagSelector } from './voicemail-tag-selector';
import { updateVoicemailData, useAddVoicemailMessage } from './voicemail-update';
import { createVoicemailObject, downloadVoicemail } from './voicemail-utils';
import { Forwardvoicemail } from './voicemails-forward';

type MultiVoicemailProps = {
  setSelectedTimerange: (subtitle: string) => void;
};

export type ForwardVoicemailDynamicData = {
  rowIndex: number;
  vmId: string;
  currentVoicemailBoxName: string;
  currentVoicemailBoxId: string;
  mediaPath: string;
  mediaId: string;
};

type ForwardVoicemailStaticData = {
  voicemailBoxList: VoicemailBox[];
  onDelete: (req: VmTypes.DeletePayload) => void;
};

export type ForwardVoicemailModalData = ForwardVoicemailDynamicData & ForwardVoicemailStaticData;

export const defaultForwardVoicemailModalData = {};

export type SetPageConfigInput = Partial<PaginationSettings> | ((_: PaginationSettings) => Partial<PaginationSettings>);

const defaultEmptyState = {
  type: 'voicemail',
  header: i18next.t('No Items to Display'),
  description: i18next.t('Voicemails will display here.'),
};

const getFallbackCallerName = (contactNumber: string): string => {
  const sanitizedNumber = sanitizePhoneNumber(contactNumber ?? '');
  return !!sanitizedNumber ? getFormattedCallerName(sanitizedNumber) : i18next.t('Unknown', { ns: 'calls' });
};

export const getMailboxToNameMap = (voicemailBoxes: VoicemailBox[]) => {
  return voicemailBoxes.reduce((acc, { mailbox }) => {
    acc[mailbox?.id ?? '-'] = mailbox?.name ?? '-';
    return acc;
  }, {} as Record<string, string>);
};

export const MultiVoicemailsTable = ({ setSelectedTimerange }: MultiVoicemailProps) => {
  const alert = useAlert();
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails' });
  const queryClient = useQueryClient();

  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { show, panelType, context, setShow } = useSlidePanelShallowStore<TranscriptionContext>(
    'setShow',
    'context',
    'panelType',
    'show'
  );

  const { selectedLocationIds, getScopeName } = useAppScopeStore();

  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const {
    config,
    setPageSize,
    setPageNumber,
    setFilters: setVoicemailPageFilters,
  } = usePhonePageSettingShallowStore('config', 'setPageSize', 'setPageNumber', 'setFilters');

  const [emptyState, setEmptyState] = useState<EmptyStateConfig>(defaultEmptyState as EmptyStateConfig);

  const { isLoading: isLoadingVMTranscription, isFeatureHiddenInAllLocations: isVmTranscriptionHiddenInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: !!selectedLocationIds.length,
      customizationFlag: Feature.VOICEMAIL_TRANSCRIPTION,
    });

  const isVMTranscriptionEnabled = !isLoadingVMTranscription && !isVmTranscriptionHiddenInAllLocations;

  const setFilters = (filters: VoicemailFiltersType) => {
    setVoicemailPageFilters(AllCallsTable.Voicemails, filters);
  };

  const defaultFilters: VoicemailFiltersType = useMemo(
    () => ({
      locationIds: [],
      syncDeviceSipProfileId: phoneConfig?.sipProfileId,
      filterOptions: {
        callerNumber: '',
        callerName: '',
        endTime: defaultDateRangeMap[DEFAULT_NO_TIME_PERIOD].endDate,
        startTime: defaultDateRangeMap[DEFAULT_NO_TIME_PERIOD].startDate,
        status: MessageStatus.MESSAGE_STATUS_ALL,
        mailboxIds: [],
        tagIds: [],
      },
    }),
    []
  );

  const pageNumber = config[AllCallsTable.Voicemails].pageNumber;
  const pageSize = config[AllCallsTable.Voicemails].pageSize;
  const filters = config[AllCallsTable.Voicemails].filters ?? defaultFilters;

  useOnScopeChange(() => {
    setFilters({ ...filters, locationIds: [] });
  });

  useEffect(() => {
    if (!filters) {
      setFilters(defaultFilters);
    }
  }, []);

  useEffect(() => {
    setSelectedTimerange(getTimePeriod(filters.filterOptions.startTime!, filters.filterOptions.endTime!));
  }, [filters.filterOptions.startTime, filters.filterOptions.endTime]);

  const setPageConfig = (input: SetPageConfigInput) => {
    const data = typeof input === 'function' ? input({ pageSize, pageNumber }) : input;
    if (data.pageSize) setPageSize(AllCallsTable.Voicemails, data.pageSize);
    if (data.pageNumber) setPageNumber(AllCallsTable.Voicemails, data.pageNumber);
  };

  const searchQuery = useMemo(() => `${JSON.stringify({ ...filters })}-${pageSize}`, [filters, pageSize]);
  const tableInstanceRef = useRef<TableInstance<VoicemailTableDataRow[]>>();

  const { data: voicemailBoxList, isLoading: isVoicemailBoxListLoading } = useScopedQuery({
    queryKey: queryKeys.listVoicemailBox(phoneConfig?.sipProfileId ?? ''),
    queryFn: async () => {
      const { voicemailBoxes: data } = await VoicemailApi.listVoicemailBox({
        locationIds: selectedLocationIds,
        sipProfileId: phoneConfig?.sipProfileId,
        includeNonSharedMailboxes: true,
      });
      return data ?? [];
    },
    select: (data: VoicemailBox[]) => {
      return (
        data?.sort((a, b) => {
          return a?.mailbox?.name.localeCompare(b?.mailbox?.name ?? '') || 0;
        }) ?? []
      );
    },
  });

  const mailboxToNameMap = getMailboxToNameMap(voicemailBoxList ?? []);

  const { mappedTags, isLoading: isTagDataLoading } = useTags();

  const {
    data: voicemailsData,
    isFetching,
    fetchNextPage,
    refetch,
  } = useScopedInfiniteQuery<VoicemailQueryData, unknown>({
    queryKey: queryKeys.multiVoicemails(searchQuery),
    queryFn: async ({ pageParam }) => {
      const CANARY = 1;
      const { voicemailMessages: data = [] } = await VoicemailApi.listMultiVoicemailMessages({
        // If no locationIds are selected, we should use all available locations
        locationIds: filters.locationIds.length ? filters.locationIds : selectedLocationIds,
        syncDeviceSipProfileId: phoneConfig?.sipProfileId,
        filter: {
          ...filters.filterOptions,
          startTime: formatStartDateWithTimeZone(filters.filterOptions.startTime),
          endTime: formatEndDateWithTimeZone(filters.filterOptions.endTime),
        },
        // We need to fetch more than the requested page size to determine if there is a next page
        limit: pageSize + CANARY,
        page: {
          lastVoicemailId: pageParam?.lastId,
          lastRecordTimestamp: pageParam?.createdAt,
        },
      });

      const parsedData: VoicemailTableDataRow[] = data.map((voicemail: VoicemailMessage) =>
        createVoicemailObject(voicemail, selectedLocationIds, mappedTags, mailboxToNameMap)
      );

      const lastRowIndex = pageSize + CANARY > data.length ? data.length - 1 : data.length - 1 - CANARY;
      const lastRowData = parsedData[lastRowIndex];

      if (!pageParam?.lastId) {
        // whenever 1st page of voicemail data is fetched, we need to update the unread count as well to be consistent
        queryClient.refetchQueries([
          selectedLocationIds,
          ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
        ]);
      }

      return {
        data: parsedData.slice(0, pageSize),
        meta: {
          lastId: lastRowData?.voicemailId,
          createdAt: lastRowData?.createdAt,
          hasNext: parsedData.length > pageSize,
        },
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.meta;
    },
    retry: false,
    refetchOnWindowFocus: false,
    // Parsing the data depends on the mailboxMap
    enabled: !!voicemailBoxList && !isTagDataLoading && !isVoicemailBoxListLoading,
  });

  // helper function to get, set the voicemail data cache in react query
  const { getVoicemailData, setVoicemailData } = useMemo(() => {
    const queryKey = [selectedLocationIds, ...queryKeys.multiVoicemails(searchQuery)];

    const getVoicemailData = () => {
      return queryClient.getQueryData<InfiniteData<VoicemailQueryData>>(queryKey);
    };

    const setVoicemailData = (voicemailQueryData: InfiniteData<VoicemailQueryData>) => {
      queryClient.setQueryData(queryKey, voicemailQueryData);
    };
    return { getVoicemailData, setVoicemailData };
  }, [selectedLocationIds, searchQuery]);

  useEffect(() => {
    setPageConfig({ pageNumber: 1 });
  }, [filters.locationIds, filters.filterOptions]);

  const getHasNext = () => {
    const pageMeta = voicemailsData?.pages[pageNumber - 1]?.meta;
    return pageMeta?.hasNext;
  };

  const getLocationChip = (locationIds: string[]) => {
    if (locationIds.length > 1) {
      return (
        <Chips.LocationChip>
          {locationIds.length} {t('Locations')}
        </Chips.LocationChip>
      );
    } else if (locationIds.length === 1) {
      return <Chips.LocationChip>{getScopeName(locationIds[0])}</Chips.LocationChip>;
    }
    return <span>{'--'}</span>;
  };

  const { mutate: onDelete } = useMutation((req: VmTypes.DeletePayload) => VoicemailApi.deleteVoicemailMedia(req), {
    onMutate: async ({ index }) => {
      queryClient.cancelQueries([selectedLocationIds, ...queryKeys.multiVoicemails(searchQuery)]);

      const data = getVoicemailData();

      if (data) {
        /**
         * When a row is deleted from a page, we want to shift everything past the deleted row up, and fill in the remaining space with the next page's data.
         *
         * If we're on the last page of known available data (data that we've already fetched), we might need to prefetch the next available page to
         * get the next row to fill in the gap. If there is no next page, then we don't need to fetch anything.
         */
        const pageIndexWithDeletedData = pageNumber - 1;
        const targetPageSlice = data.pages[pageIndexWithDeletedData];
        const needToPrefetch = data.pages.length === pageIndexWithDeletedData + 1 && targetPageSlice.meta.hasNext;

        /**
         * This is the data we will be referencing from henceforth.
         *
         * If we need to fetch a new page, we will use the new data as reference.
         */
        const refData = needToPrefetch
          ? await fetchNextPage({
              pageParam: {
                lastId: targetPageSlice.meta.lastId,
                createdAt: targetPageSlice.meta.createdAt,
              },
            }).then((res) => res.data ?? { pages: [], pageParams: [] })
          : data;

        const overallDeleteIndex = pageSize * (pageNumber - 1) + index;
        const allData = refData.pages.map((page) => page.data).flat();

        allData.splice(overallDeleteIndex, 1);

        /**
         * We now have a flat list of all rows (with the deleted item removed). These rows are not separated into pages.
         *
         * We will rebuild the pages from this list.
         */
        const newAllData = [];

        /**
         * Split the data into chunks of pageSize. This will be the data for our new pages
         */
        for (let i = 0; i < allData.length; i += pageSize) {
          const chunk = allData.slice(i, i + pageSize);
          newAllData.push(chunk);
        }

        /**
         * Build the page objects from the new page data chunks
         */
        const newPages = newAllData.map((slice, i, list) => {
          /**
           * We can determine that the last page in this new list has no next page if refData has 1 more page than the new list.
           * Marking this correctly will disable the pagination button to advance to the next page.
           */
          const thereUsedToBeMoreRows = i === list.length - 1 && data?.pages.length === list.length + 1;

          const hasNext = thereUsedToBeMoreRows ? false : refData.pages[i]?.meta?.hasNext;

          return {
            data: slice,
            meta: {
              lastId: slice[slice.length - 1]?.voicemailId,
              createdAt: slice[slice.length - 1]?.createdAt,
              hasNext,
            },
          };
        });

        const newPageData = {
          pages: newPages,
          pageParams: newPages.map((page, i) => (i === 0 ? undefined : page.meta)),
        };

        /**
         * Note: We are not invalidating any queries, just making optimistic updates.
         * The queries on this page will be refetched when the user navigates to this page.
         *
         * If there is an error before `setQueryData` is called, we would just show the error alert, and the cached data will be untouched.
         */
        setVoicemailData(newPageData);

        if (!newPages[pageIndexWithDeletedData]) {
          /**
           * If the page we are on is now empty, we need to navigate to the previous page.
           *
           * pageNumber is 1-indexed, pageIndexWithDeletedData is 0-indexed
           */
          setPageConfig({ pageNumber: pageIndexWithDeletedData });
        }

        alert.success(t('voicemailDeletedSuccess'));
      }
    },
    onError: () => {
      alert.error(t('voicemailDeletedError'));
    },
    onSuccess: (_, { voicemailId }) => {
      if (panelType === 'voicemailTranscription' && context?.currentRowId === voicemailId) {
        setShow(false);

        /**
         * The code below is for progressing the transcription panel to the next row after a row is deleted.
         * This is no longer the direction we're going with, but keeping the code here for now in case we want to revisit this.
         */
        // const data = queryClient.getQueryData<InfiniteData<VoicemailQueryData>>([
        //   selectedLocationIds,
        //   ...queryKeys.multiVoicemails(searchQuery),
        // ]);

        // if (data) {
        //   const rows = data.pages[pageNumber - 1] ? data.pages[pageNumber - 1].data : [];

        //   if (rows.length > index && context) {
        //     setShow(true, panelType, {
        //       ...context,
        //       // Keep the same index (which means we will progress down the list)
        //       currentRowId: rows[index].channelId ?? '',
        //       rows: data.pages[pageNumber - 1].data.map((row) => ({ ...row })),
        //     });
        //   } else {
        //     resetTranscriptPanel(setShow, context);
        //   }
        // }
      }
    },
  });

  // toggle voicemail status
  const { mutate: updateReadStatus, mutateAsync: updateReadStatusAsync } = useMutation(
    (payload: VmTypes.StatusPayload) => {
      const req = omit(payload, 'index');
      return VoicemailApi.setVoicemailViewStatus(req);
    },
    {
      onSuccess: (response, { index, status }) => {
        const data = getVoicemailData();

        if (data && data.pages[pageNumber - 1]?.data) {
          const message = data.pages[pageNumber - 1].data?.[index];
          if (message) {
            message.readAt =
              status === ViewStatus.VIEW_STATUS_READ && response.voicemail?.readEpoch
                ? // @ts-ignore - This type is wrong, the schema says it should be a number but it isn't
                  new Date(parseInt(response.voicemail.readEpoch)).toISOString()
                : undefined;

            if (panelType === 'voicemailTranscription' && context) {
              setShow(show, panelType, {
                ...context,
                rows: data.pages[pageNumber - 1].data.map((row) => ({ ...row })),
              });
            }
            setVoicemailData(data);
          }
        }
      },
    }
  );

  const tableData = voicemailsData?.pages[pageNumber - 1]?.data || [];

  useEffect(() => {
    /**
     * This effect is to update the slide panel context with the new rows from the new table instance when the table instance changes.
     * The table instance will change if the user navigates away from the voicemail table, while having the transcription panel open.
     *
     * If we don't do this, each row's `toggleRowSelected` will not work as expected, since they are tied to the stale table instance.
     */
    if (show && panelType === 'voicemailTranscription' && tableInstanceRef.current) {
      const existingRow = context?.currentRowId ? tableInstanceRef.current?.rowsById[context.currentRowId] : null;

      if (existingRow) {
        if (!existingRow.isSelected) {
          existingRow.toggleRowSelected(true);
        }
        setShow(true, 'voicemailTranscription', {
          updateReadStatus: updateReadStatusAsync,
          deleteVoicemail: onDelete,
          currentRowId: existingRow.id,
          instanceId: tableInstanceRef.current?.tableId ?? '',
          rows: tableData.map((row) => ({ ...row })),
          toggleRowSelected: tableInstanceRef.current.toggleRowSelected,
          voicemailsQueryKey: queryKeys.multiVoicemails(searchQuery).filter(Boolean),
          openForwardVoicemailModal: openForwardVoicemailModal,
          sectionFocus: context?.sectionFocus || 'transcript',
        });
      } else {
        resetTranscriptPanel(setShow, context);
      }
    }
  }, [tableInstanceRef.current?.tableId, tableData, tableData.length]);

  const [forwarVoicemailModalData, setForwardVoicemailModalData] = useState<ForwardVoicemailModalData>(
    defaultForwardVoicemailModalData as ForwardVoicemailModalData
  );
  const doubleActionControl = useModalControl();

  const openForwardVoicemailModal = (data: ForwardVoicemailDynamicData) => {
    setForwardVoicemailModalData({ ...data, voicemailBoxList: voicemailBoxList || [], onDelete: onDelete });
    doubleActionControl.openModal();
  };

  const getShortHandName = (callInfo: VoicemailTableDataRow): string => {
    const { contactName, contactNumber } = callInfo;
    return contactName !== '' ? contactName : getFallbackCallerName(contactNumber);
  };

  const { voicemailEvent } = useVoicemailUpdateStore();
  const { onNewVoicemailMessage } = useAddVoicemailMessage(
    getVoicemailData,
    setVoicemailData,
    selectedLocationIds,
    mappedTags,
    mailboxToNameMap
  );

  useEffect(() => {
    if (voicemailEvent) {
      if (voicemailEvent.type === VoicemailEventType.VOICEMAIL_EVENT_TYPE_NEW) {
        // Show the new voicemail update if it matches a selected mailbox or no filter is applied.
        // Also, ensure the status filter is set to unread or all.
        // Also, ensure that the time filter includes today's date to prevent updates for past periods.
        const isTodayIncluded =
          !filters.filterOptions.endTime || dayjs(filters.filterOptions.endTime).isSame(dayjs(), 'day');
        const hasUpdateForSelectedMailbox =
          !filters.filterOptions.mailboxIds?.length ||
          filters.filterOptions.mailboxIds.includes(voicemailEvent.mailboxId);

        if (
          isTodayIncluded &&
          hasUpdateForSelectedMailbox &&
          (filters.filterOptions.status === MessageStatus.MESSAGE_STATUS_UNREAD ||
            filters.filterOptions.status === MessageStatus.MESSAGE_STATUS_ALL)
        ) {
          // add new voicemail only if the filter is not applied
          onNewVoicemailMessage(voicemailEvent);
        }
      } else {
        updateVoicemailData(voicemailEvent, getVoicemailData, setVoicemailData);
      }
    }
  }, [voicemailEvent]);

  return (
    <>
      <Forwardvoicemail modalProps={doubleActionControl.modalProps} forwardData={forwarVoicemailModalData} />
      <div css={styles.wrapper}>
        <Table
          uniqueRowId={(row, index) => {
            return getTranscriptionUniqueKey(row) ?? index;
          }}
          tableInstanceId='multi-voicemails'
          instanceRef={tableInstanceRef}
          globalTrackingId='phone-portal-weave2.0-voicemails'
          data={tableData}
          keepPreviousDataWhileLoading={tableData.length > 0}
          isLoading={isFetching || isVoicemailBoxListLoading}
          fullHeight
          isPaginated
          hasResponsiveColWidths
          hasResizeColumns
          hasFilterColumns
          hasGlobalSearch
          manualFilters
          manualFiltersOptions={{
            initialBadgeState: filters.filterOptions.mailboxIds && filters.filterOptions.mailboxIds.length > 0,
          }}
          manualFiltersRender={(modalProps, setShowNotificationBadge) => (
            <VoicemailsTrayFilters
              defaultFilters={defaultFilters}
              filters={filters}
              mailboxes={voicemailBoxList}
              onChange={setFilters}
              isMulti={selectedLocationIds.length > 1}
              modalProps={modalProps}
              setShowNotificationBadge={setShowNotificationBadge}
            />
          )}
          globalSearchConfig={{
            initialValue: filters.filterOptions.callerNumber || filters.filterOptions.callerName || '',
            searchHandler: (searchText) => {
              const sanitizedNumber = sanitizePhoneNumber(searchText);
              const numberSearch =
                isPhoneNumber(sanitizedNumber) || isPartialPhoneNumber(sanitizedNumber) ? sanitizedNumber : '';
              const nameSearch =
                !isPhoneNumber(sanitizedNumber) && !isPartialPhoneNumber(sanitizedNumber) ? searchText : '';

              if (!!isPartialPhoneNumber(searchText)) {
                setEmptyState({
                  type: 'voicemail',
                  header: t('Phone Number Searches Must be Exactly 10 Digits'),
                  description: t('Phone number searches must be exactly 10 digits in order to return a match.'),
                });
              } else {
                setEmptyState(defaultEmptyState as EmptyStateConfig);
              }

              setFilters({
                ...filters,
                filterOptions: {
                  ...filters.filterOptions,
                  callerNumber: numberSearch,
                  callerName: nameSearch,
                },
              });
            },
            placeholder: t('Search name or 10-digit number'),
            debounceDelay: 1000,
          }}
          emptyStateConfig={emptyState}
          customToolbarRender={() => (
            <ToolbarChipFilter
              defaultTimePeriod={DEFAULT_NO_TIME_PERIOD}
              trackingBaseId='phone-weave2.0-voicemails'
              filters={{
                locationIds: filters.locationIds,
                startDate: filters.filterOptions.startTime ?? '',
                endDate: filters.filterOptions.endTime ?? '',
              }}
              onChange={(changedfilter) => {
                setFilters({
                  ...filters,
                  locationIds: changedfilter.locationIds,
                  filterOptions: {
                    ...filters.filterOptions,
                    startTime: changedfilter.startDate,
                    endTime: changedfilter.endDate,
                  },
                });
              }}
            />
          )}
          colConfig={[
            {
              Header: t('Contact Name'),
              accessor: (row) => row,
              cellRenderer: (_, row) =>
                row ? (
                  <ProfileCard
                    trackingId={`phone-portal-weave2.0-voicemails-profilebtn-tablerowaction-viewprofile`}
                    firstName={row.firstName}
                    lastName={row.lastName}
                    profileName={row.contactName}
                    defaultName={t('Unknown')}
                    locationId={row.locationIds[0]}
                    personID={row.contactId}
                    markedUnread={!row.readAt}
                  />
                ) : null,
              disableSortBy: true,
              id: 'contactName',
              mobileLayoutConfig: {
                order: -1,
                spanFullWidth: false,
                hideHeader: true,
                leftShift: 48,
              },
              width: 190,
            },
            {
              Header: t('Time'),
              accessor: 'dateTime',
              disableSortBy: true,
              id: 'dateTime',
              mobileLayoutConfig: {
                spanFullWidth: true,
              },
              width: 200,
            },
            {
              Header: t('Voicemail'),
              cellRenderer: (_, rowData, row) =>
                rowData ? (
                  <>
                    {rowData.mediaFilePath ? (
                      <CachedAudioScrubber
                        trackingId='nwx-phones-voicemails-tableRow-audioScrubber'
                        onPlay={() => {
                          // There should always be a row
                          if (rowData && row?.index !== undefined) {
                            updateReadStatus({
                              index: row.index,
                              voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                              status: ViewStatus.VIEW_STATUS_READ,
                            });
                          }
                        }}
                        filePath={rowData.mediaFilePath}
                        mediaId={rowData.mediaId}
                      />
                    ) : (
                      <span css={styles.noRecordingText}>{'--'}</span>
                    )}
                  </>
                ) : null,
              disableSortBy: true,
              id: 'voicemailMessage',
              mobileLayoutConfig: {
                spanFullWidth: true,
              },
              minWidth: 300,
            },
            {
              Header: t('Contact Number'),
              accessor: 'contactNumber',
              disableSortBy: true,
              id: 'contactPhone',
              width: 150,
            },
            {
              Header: t('Location'),
              accessor: 'locationIds',
              cellRenderer: (locationIds) => getLocationChip(locationIds ?? []),
              id: 'location',
              omit: !(selectedLocationIds.length > 1),
              disableSortBy: true,
            },
            {
              Header: t('Voicemail Box'),
              accessor: 'voiceMailBoxName',
              disableSortBy: true,
              id: 'voicemailBoxType',
            },
            {
              Header: t('Tags'),
              accessor: (item) => item,
              id: 'tags',
              disableSortBy: true,
              cellRenderer: (record, _rowData, row, _1, _2, tableInstance) => {
                return (
                  <TagsCell
                    {...record}
                    row={row}
                    tableInstance={tableInstance}
                    updateReadStatus={updateReadStatusAsync}
                    deleteVoicemail={onDelete}
                    voicemailsQueryKey={queryKeys.multiVoicemails(searchQuery).filter(Boolean)}
                    openForwardVoicemailModal={openForwardVoicemailModal}
                  />
                );
              },
            },
            /**
             * This column relies on values from the other columns.
             * Please make sure to consider this when modify the columns that are referenced below
             */
            {
              Header: '',
              cellRenderer: (_, rowData, row, _1, _2, tableInstance) => {
                if (!rowData) {
                  return null;
                }
                const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
                  context: {
                    personId: rowData.contactId,
                    phoneNumber: rowData.contactNumber,
                    locationId: selectedLocationIds[0],
                  },
                });

                const { onClick: messageOnClick } = ActionsUI.actions.useMessageAction();

                const actions: Action[] = [
                  {
                    Icon: () => getActionIcon({ iconName: 'phone' }),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-call',
                    label: `${t('Call')} ${getShortHandName(rowData)}`,
                    onClick: () => {
                      callTriggerProps?.onClick();
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'message' }),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-message',
                    label: `${t('Message')} ${getShortHandName(rowData)}`,
                    onClick: () => {
                      messageOnClick({
                        personId: rowData.contactId,
                        personPhone: rowData.contactNumber,
                        groupIds: rowData.locationIds,
                      });
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'user' }),
                    label: t('View Profile'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-viewprofile',
                    disabled: !rowData.contactId,
                    onClick: () => {
                      if (!!rowData.contactId) {
                        setPersonId(rowData.contactId, true, selectedLocationIds[0]);
                        setShow(true, 'contact');
                      }
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'voicemail-override' }),
                    label: t('Forward Voicemail'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-forwardvoicemail',
                    onClick: () => {
                      openForwardVoicemailModal({
                        rowIndex: row?.index,
                        vmId: rowData.voicemailId == '' ? rowData.channelId : rowData.voicemailId,
                        mediaId: rowData.mediaId,
                        mediaPath: rowData.mediaFilePath,
                        currentVoicemailBoxId: rowData.mailboxId,
                        currentVoicemailBoxName: rowData.voiceMailBoxName,
                      } as ForwardVoicemailDynamicData);
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'label' }),
                    label: t('Add Tag'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-tagvoicemail',
                    onClick: () => {
                      if (row) {
                        row.setState({ isEditing: true });
                      }
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'download' }),
                    label: t('Download Voicemail'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-downloadvoicemail',
                    onClick: () => {
                      downloadVoicemail(rowData.mediaFilePath, rowData.contactName ?? 'unknown', rowData.dateTime);
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'mark-unread' }),
                    label: t('Mark Unread'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-togglestatus',
                    hide: !rowData.readAt,
                    onClick: () => {
                      if (!!row) {
                        updateReadStatus({
                          index: row.index,
                          voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                          status: ViewStatus.VIEW_STATUS_UNREAD,
                        });
                      }
                    },
                  },
                  {
                    Icon: () => getActionIcon({ iconName: 'mark-read' }),
                    label: t('Mark Read'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-togglestatus',
                    hide: !!rowData.readAt,
                    onClick: () => {
                      if (!!row) {
                        updateReadStatus({
                          index: row.index,
                          voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                          status: ViewStatus.VIEW_STATUS_READ,
                        });
                      }
                    },
                  },
                  {
                    Icon: (props) => getActionIcon({ iconName: 'trash', color: props.color }),
                    label: t('Delete Voicemail'),
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-deletevoicemail',
                    destructive: true,
                    onClick: () => {
                      if (!!row) {
                        onDelete({ voicemailId: rowData?.forwardedMessageId || rowData.channelId, index: row.index });
                      }
                    },
                  },
                ];
                return (
                  <>
                    <div style={{ width: '100%', display: 'flex', gap: theme.spacing(1), justifyContent: 'center' }}>
                      {isVMTranscriptionEnabled && row && tableInstance && (
                        <TranscriptColumn
                          row={row}
                          tableInstance={tableInstance}
                          updateReadStatus={updateReadStatusAsync}
                          deleteVoicemail={onDelete}
                          voicemailsQueryKey={queryKeys.multiVoicemails(searchQuery).filter(Boolean)}
                          openForwardVoicemailModal={openForwardVoicemailModal}
                        />
                      )}
                      <CustomActionCell actions={actions} tableTrackingId={'phone-portal-weave2.0-voicemails'} />
                    </div>
                    {PhoneCallModal}
                  </>
                );
              },
              disableSortBy: true,
              disableColumnFilter: true,
              id: 'actions',
              sticky: 'right',
              width: isVMTranscriptionEnabled ? 100 : 80,
              mobileLayoutConfig: {
                order: -1,
                hideHeader: true,
                spanFullWidth: false,
              },
            },
          ]}
          /**
           * (!)
           * Hide the transcription panel after pagination settings have changed to prevent the panel from using stale data.
           * More info in TranscriptionPanel.
           */
          manualPaginationConfig={{
            handleChange: (action: 'next' | 'prev') => {
              if (action === 'next') {
                /**
                 * We potentially refetch data if the user navigates to the a page where data is already cached.
                 *
                 * The cached page could have less data than expected with optimistic updates on deletion,
                 * so we refresh only if it has a next page and doesn't have a full page of data.
                 */
                if (voicemailsData?.pages[pageNumber]) {
                  refetch<VoicemailQueryData>({
                    refetchPage: (lastPage) => {
                      return lastPage.meta.hasNext && lastPage.data.length < pageSize;
                    },
                  });
                } else {
                  fetchNextPage();
                }
                setPageConfig((prev) => ({ pageNumber: prev.pageNumber + 1 }));
                /** (!) */
                resetTranscriptPanel(setShow, context);
              } else {
                setPageConfig((prev) => ({ pageNumber: prev.pageNumber - 1 }));
                /** (!) */
                resetTranscriptPanel(setShow, context);
              }
            },
            hasNext: getHasNext(),
            hasPrevious: true,
            page: pageNumber,
            defaultRowsPerPage: pageSize,
            onNumRowsChange: (num) => {
              setPageConfig({ pageNumber: 1, pageSize: num });
              /** (!) */
              resetTranscriptPanel(setShow, context);
            },
            rowsPerPageOptions: [10, 25, 50, 75],
          }}
        />
      </div>
    </>
  );
};

export const TagList = ({ tags, voicemailId, queryKey }: { tags: Tag[]; voicemailId: string; queryKey: string[] }) => {
  const { t } = useTranslation('calls');
  const alerts = useAlert();
  const { removeTag } = useVoicemailTagMutation({
    queryKey,
    voicemailId,
    onSuccess: ({ op }) => {
      if (op === 'remove') alerts.success(t('Successfully removed tag.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Failed to remove tag.'));
    },
  });
  const representativeTag = tags[0];

  return (
    <>
      <Chip.Tag
        isRemovable
        onRemoveClick={() => {
          removeTag(representativeTag);
        }}
        css={{ width: 'auto' }}
        color={TagsUtils.convertStringToTagColor(representativeTag.color)}
      >
        {representativeTag.name}
      </Chip.Tag>
    </>
  );
};

const styles = {
  wrapper: css`
    flex: 1;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: ${theme.spacing(1)};
    position: relative;
  `,

  noRecordingText: css`
    color: ${theme.colors.neutral90};
    display: inline-block;
    text-align: left;
    width: 100%;
  `,
};

export const resetTranscriptPanel = (
  setShow: ReturnType<typeof useSlidePanelShallowStore<TranscriptionContext>>['setShow'],
  context: ReturnType<typeof useSlidePanelShallowStore<TranscriptionContext>>['context']
) => {
  context?.toggleRowSelected(context.currentRowId, false);
  setShow(false, 'voicemailTranscription', undefined);
};

export const useShowTranscriptionPanel = ({
  row,
  tableInstance,
  updateReadStatus,
  deleteVoicemail,
  voicemailsQueryKey,
  sectionFocus,
  openForwardVoicemailModal,
}: {
  row?: Row<VoicemailTableDataRow>;
  tableInstance?: TableInstance<VoicemailTableDataRow>;
  updateReadStatus: (payload: VmTypes.StatusPayload) => Promise<VmTypes.SetVoicemailViewStatusResponse>;
  deleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  voicemailsQueryKey: string[];
  sectionFocus?: TranscriptionContext['sectionFocus'];
  openForwardVoicemailModal: (data: ForwardVoicemailDynamicData) => void;
}) => {
  const { setShow } = useSlidePanelShallowStore<TranscriptionContext>('setShow', 'panelType', 'show', 'context');
  if (!row || !tableInstance) {
    return () => {};
  }

  return () =>
    setShow(true, 'voicemailTranscription', {
      updateReadStatus,
      deleteVoicemail,
      instanceId: tableInstance.tableId,
      currentRowId: row.id,
      rows: tableInstance.rows.map((row) => ({ ...row.original })),
      toggleRowSelected: tableInstance.toggleRowSelected,
      voicemailsQueryKey,
      sectionFocus,
      openForwardVoicemailModal,
    });
};

export const TranscriptColumn = ({
  row,
  tableInstance,
  updateReadStatus,
  deleteVoicemail,
  voicemailsQueryKey,
  openForwardVoicemailModal,
  hoverLabel,
}: {
  row: Row<VoicemailTableDataRow>;
  tableInstance: TableInstance<VoicemailTableDataRow>;
  updateReadStatus: (payload: VmTypes.StatusPayload) => Promise<VmTypes.SetVoicemailViewStatusResponse>;
  deleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  voicemailsQueryKey: string[];
  openForwardVoicemailModal: (data: ForwardVoicemailDynamicData) => void;
  hoverLabel?: string;
  customStyle?: SerializedStyles;
}) => {
  const { t } = useTranslation('calls');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'bottom',
  });
  const showPanel = useShowTranscriptionPanel({
    row,
    tableInstance,
    updateReadStatus,
    deleteVoicemail,
    voicemailsQueryKey,
    openForwardVoicemailModal,
    sectionFocus: 'transcript',
  });

  return (
    <Button
      variant='secondary'
      css={{
        padding: theme.spacing(0.5),
        border: 'none',
      }}
      aria-label={t('Transcription')}
      trackingId={transcriptionTrackingId({ subComponent: 'table', context: 'panel-trigger' })}
      onClick={() => showPanel()}
      {...triggerProps}
    >
      {hoverLabel && <Tooltip {...tooltipProps}>{hoverLabel}</Tooltip>}
      <Icon name='document-filled' size={24} color={row.isSelected ? 'primary' : 'light'} />
    </Button>
  );
};

export const TagColumn = ({
  tags,
  voicemailId,
  queryKey,
  showPanel,
  isEditing,
  setIsEditing,
}: {
  tags: Tag[];
  voicemailId: string;
  queryKey: string[];
  showPanel: () => void;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(0px, 1fr) auto auto',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      {tags.length > 0 && <TagList tags={tags} voicemailId={voicemailId} queryKey={queryKey} />}
      {!isEditing && tags.length > 1 && (
        <TextLink
          css={{
            ':hover': {
              textDecoration: 'none',
            },
          }}
          onClick={() => {
            showPanel();
          }}
        >
          +{tags.length - 1}
        </TextLink>
      )}
      {!isEditing && tags.length === 0 && <AddTag voicemailId={voicemailId} queryKey={queryKey} />}

      {isEditing && (
        <VoicemailTagSelector
          voicemailId={voicemailId}
          queryKey={queryKey}
          existingTagIds={tags.map((tag) => tag.id)}
          onClose={() => {
            setIsEditing(false);
          }}
          onSelect={() => {
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
};

type TagsCellProps = VoicemailTableDataRow &
  Pick<
    Parameters<typeof useShowTranscriptionPanel>[0],
    'updateReadStatus' | 'deleteVoicemail' | 'voicemailsQueryKey' | 'openForwardVoicemailModal'
  > & {
    row: Row<VoicemailTableDataRow>;
    tableInstance: TableInstance<VoicemailTableDataRow>;
  };

const TagsCell = ({
  tags,
  voicemailId,
  row,
  tableInstance,
  updateReadStatus,
  deleteVoicemail,
  voicemailsQueryKey,
  openForwardVoicemailModal,
}: TagsCellProps) => {
  const showPanel = useShowTranscriptionPanel({
    row,
    tableInstance,
    updateReadStatus,
    deleteVoicemail,
    voicemailsQueryKey,
    sectionFocus: 'tags',
    openForwardVoicemailModal,
  });

  let isEditing = false;
  if (row?.state && 'isEditing' in row.state && typeof row.state.isEditing === 'boolean') {
    isEditing = row.state.isEditing;
  }

  if (!row) return null;

  return (
    <TagColumn
      tags={tags}
      voicemailId={voicemailId}
      showPanel={showPanel}
      queryKey={voicemailsQueryKey}
      isEditing={isEditing}
      setIsEditing={(val) => row.setState({ isEditing: val })}
    />
  );
};
