import { css } from '@emotion/react';
import { containerStyle as commonContainerStyle } from '../common.styles';

export const containerStyle = [
  commonContainerStyle,
  css`
    flex-direction: column;
    align-items: initial;
  `,
];

export const innerContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;
