import dayjs, { QUnitType } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import { i18next } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { DEFAULT_NO_TIME_PERIOD } from './components/filter-selectors/time-period-selector';

dayjs.extend(duration);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
export const getFullName = (firstName?: string, lastName?: string, defaultValue = '') => {
  if (firstName || lastName) {
    return `${firstName || ''} ${lastName || ''}`.trim();
  }
  return defaultValue || '';
};

export const isStringNumeric = (callerName: string) => {
  return /^[+-]?\d+(\.\d+)?$/.test(callerName);
};

// Sometimes caller name is a number, in that case, we show 'Unknown' caller name
// Sometimes we don't have person information so we might need to show number instead person name.
export const getFormattedCallerName = (callerName: string) => {
  return !!callerName && isStringNumeric(callerName)
    ? formatPhoneNumber(callerName)
    : i18next.t('Unknown', { ns: 'calls' });
};

// get current epoch time in seconds
/**
 * Find the date range based on the given offset and range type
 * @param offset the offset from the current date, default is 0, value should always be positive
 * @param oneDayLimit if set true, only one day dates will be returned based on the given offset, applicable only for 'day' range
 * @param range the range type, possible values are 'day', 'week', 'month'
 * @param type the type of the range, possible values are 'past', 'future'
 */
type Range = 'day' | 'week' | 'month' | 'quarter';

type FindDateRange = {
  offset?: number;
  oneDayLimit?: boolean;
  range?: Range;
  type?: 'past' | 'future';
};

export type DateRange = {
  startDate: string;
  endDate: string;
};

const getDatesBlock = (offset: number, range: Range) => {
  let startDate, endDate;
  const today = dayjs();

  if (!offset) {
    startDate = dayjs().startOf(range).format('YYYY-MM-DDTHH:mm:ss');
    const lastDay = dayjs().endOf(range);

    // If lastDay is larger then today, then set endDate to today
    endDate = lastDay.isAfter(today) ? today.format('YYYY-MM-DDTHH:mm:ss') : lastDay.format('YYYY-MM-DDTHH:mm:ss');
  } else {
    const date = dayjs().subtract(offset, range as QUnitType);
    startDate = date.startOf(range).format('YYYY-MM-DDTHH:mm:ss');

    const lastDay = date.endOf(range);

    // If lastDay is larger then today, then set endDate to today
    endDate = lastDay.isAfter(today) ? today.format('YYYY-MM-DDTHH:mm:ss') : lastDay.format('YYYY-MM-DDTHH:mm:ss');
  }

  return { startDate, endDate };
};

export const findDatesRange = ({ offset = 0, oneDayLimit, range = 'day' }: FindDateRange): DateRange => {
  switch (range) {
    case 'day': {
      let startDate, endDate;
      if (!offset) {
        startDate = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        endDate = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      } else {
        startDate = dayjs().subtract(offset, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        endDate = dayjs()
          .subtract(oneDayLimit ? offset : 0, 'day')
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss');
      }
      return { startDate, endDate };
    }

    case 'week':
      return getDatesBlock(offset, 'week');

    case 'month':
      return getDatesBlock(offset, 'month');

    case 'quarter':
      return getDatesBlock(offset, 'quarter');

    default:
      throw new Error('Unsupported range type');
  }
};

export const defaultDateRangeMap = {
  [DEFAULT_NO_TIME_PERIOD as string]: { startDate: '', endDate: '' } as DateRange,
  today: findDatesRange({ range: 'day' }),

  // PAST DAYS
  yesterday: findDatesRange({ offset: 1, oneDayLimit: true, range: 'day' }),
  'last-7-days': findDatesRange({ offset: 7, range: 'day' }),
  'last-14-days': findDatesRange({ offset: 14, range: 'day' }),
  'last-30-days': findDatesRange({ offset: 30, range: 'day' }),
  'last-60-days': findDatesRange({ offset: 60, range: 'day' }),
  'last-90-days': findDatesRange({ offset: 90, range: 'day' }),
  'last-1-year': findDatesRange({ offset: 365, range: 'day' }),
};

export const getTimePeriodForDateRange = (startDate: string, endDate: string) => {
  const period = Object.entries(defaultDateRangeMap).find(([_period, { startDate: date1, endDate: date2 }]) => {
    const isStartDateEqual = dayjs(date1).isSame(startDate, 'day');
    const isEndDateEqual = dayjs(date2).isSame(endDate, 'day');
    return isStartDateEqual && isEndDateEqual;
  });

  return period ? period[0] : undefined;
};

export const getTimePeriodFilterLabels = () => {
  const labels: Record<string, string> = {
    today: i18next.t('Today', { ns: 'calls' }),
    yesterday: i18next.t('Yesterday', { ns: 'calls' }),
    'last-14-days': i18next.t('Last 14 Days', { ns: 'calls' }),
    'last-30-days': i18next.t('Last 30 Days', { ns: 'calls' }),
    'last-60-days': i18next.t('Last 60 Days', { ns: 'calls' }),
    'last-7-days': i18next.t('Last 7 Days', { ns: 'calls' }),
    'last-90-days': i18next.t('Last 90 Days', { ns: 'calls' }),
    'last-1-year': i18next.t('Last 1 Year', { ns: 'calls' }),
  };

  return labels;
};
type GetMatchingDateRangeKey = {
  datesMap?: Record<string, DateRange>;
  endDate?: string;
  startDate?: string;
};

export const getMatchingDateRangeKey = ({
  datesMap = defaultDateRangeMap,
  endDate,
  startDate,
}: GetMatchingDateRangeKey) => {
  if (!!startDate && !!endDate) {
    const period = Object.entries(datesMap).find(([_period, { startDate: date1, endDate: date2 }]) => {
      const isStartDateEqual = dayjs(date1).isSame(startDate, 'day');
      const isEndDateEqual = dayjs(date2).isSame(endDate, 'day');
      return isStartDateEqual && isEndDateEqual;
    });

    return period ? period[0] : undefined;
  } else if (startDate === '' && endDate === '') {
    return DEFAULT_NO_TIME_PERIOD;
  }
  return undefined;
};

// This will append local time stamp to date-time before they are intercepted
// Required for the valid time conversion based on the time of the current location
// Returns the formatted date according to current time zone
export const formatStartDateWithTimeZone = (date: string | undefined) => {
  return date ? appendCurrentTimeZone(dayjs(date).format('YYYY-MM-DD[T00:00:00]')) : '';
};

export const formatEndDateWithTimeZone = (date: string | undefined) => {
  return date ? appendCurrentTimeZone(dayjs(date).format('YYYY-MM-DD[T23:59:59]')) : '';
};

/**
 * @param date time zone will be appended to this date
 * @returns a string date with a time zone appended to it at the end
 */
export const appendCurrentTimeZone = (date: string) => {
  const utcOffset = dayjs().utcOffset();

  // Calculate the hours and minutes separately
  const hours = Math.floor(Math.abs(utcOffset) / 60);
  const minutes = Math.abs(utcOffset) % 60;

  // Determine the sign for the timezone offset
  const sign = utcOffset >= 0 ? '+' : '-';

  // Format the hours and minutes with leading zeros
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${date}${sign}${formattedHours}:${formattedMinutes}`;
};

export const formatDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD');
};
