import React, { FC, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Accordion, NotificationBadge } from '@frontend/design-system';
import { useQuickFillLocationInfo } from '../hooks';

export interface LocationAccordionInfo {
  locationId: string;
  name: string;
  count?: number | React.ReactNode;
}

interface Props {
  minBodyHeight?: number;
  locationInfoList: LocationAccordionInfo[];
  ContentComponent: FC<LocationAccordionInfo & { height: number | string; hasAccordionWrapper?: boolean }>;
}

const ACCORDION_HEADER_HEIGHT = 48;
const TOTAL_ACCORDION_HEIGHT = ACCORDION_HEADER_HEIGHT + 16; // added 16px for margin

// TODO: Move this component into the UI library so that it can be used by both the schedule feature library and the quick-fill feature library.
// NOTE: need to remove it from both the schedule and quick-fill feature libraries after moving it to the UI library.
export const LocationAccordionList = ({ locationInfoList, ContentComponent, minBodyHeight = 400 }: Props) => {
  const { isMultiLocation } = useQuickFillLocationInfo();
  const [selectedAccordion, setSelectedAccordion] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const totalHeight = ref.current?.clientHeight ?? 0;
  // deducting the height of all accordion headers to calculate the available space for the body
  const availableSpace = totalHeight - locationInfoList.length * TOTAL_ACCORDION_HEIGHT;

  if (!locationInfoList.length) {
    return null;
  }

  if (!isMultiLocation) {
    return (
      <div css={styles.singleContainer}>
        <ContentComponent {...locationInfoList[0]} height='100%' />
      </div>
    );
  }

  return (
    <Accordion
      ref={ref}
      variant='location'
      showBoxShadow
      chevronSize={16}
      startingValue={locationInfoList[0]?.locationId}
      size='large'
      css={styles.accordionContainer}
      controlledValue={selectedAccordion}
      onChange={(value) => setSelectedAccordion(value)}
    >
      {locationInfoList.map((info) => (
        <Accordion.Item
          key={info.locationId}
          value={info.locationId}
          style={{
            minHeight:
              selectedAccordion === info.locationId ? minBodyHeight + ACCORDION_HEADER_HEIGHT : ACCORDION_HEADER_HEIGHT,
          }}
          css={{ '> section': { height: '100% !important' } }} // set accordion body container 100% height
        >
          <Accordion.Header css={styles.header}>
            <Accordion.Header.Location title={info.name} />
            {!!info.count &&
              (typeof info.count === 'number' ? (
                <NotificationBadge truncateCount css={{ backgroundColor: theme.colors.primary50 }}>
                  {info.count}
                </NotificationBadge>
              ) : (
                info.count
              ))}
          </Accordion.Header>
          <Accordion.Body css={styles.body}>
            <ContentComponent
              {...info}
              height={availableSpace < minBodyHeight ? minBodyHeight : availableSpace}
              hasAccordionWrapper
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const styles = {
  singleContainer: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.neutral10}`,
    background: theme.colors.white,
  }),
  accordionContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  }),
  header: css({
    '.header-container': { justifyContent: 'space-between', alignItems: 'center' },
  }),
  body: css({
    padding: 0,
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }),
};

export { styles as customAccordionStyles };
