import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Bool_Enum } from '@weave/schema-gen-ts/dist/shared/null/types.pb';
import { LocationsApi } from '@frontend/api-locations';
import { getDecodedWeaveToken, isWeaveUser } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useQuery } from '@frontend/react-query-helpers';
import { PickerLocation, useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Heading,
  SearchField,
  styles,
  useDebouncedValue,
  useFormField,
  Text,
  useControlledField,
} from '@frontend/design-system';
import { PrivilegedMultiLocationSelector, UnprivilegedMultiLocationSelector } from './location-pickers';
import { useLocationPickerShallowStore } from './use-location-picker';

type DataLocationSearchResults = PickerLocation;

const UnprivilegedLocationPicker = ({ closeModal, simple = false }: { closeModal?: () => void; simple?: boolean }) => {
  const params = new URLSearchParams();
  const decodedWeaveToken = getDecodedWeaveToken();
  const [searchTerm, setSearchTerm] = useState('');
  const { isLocationsView } = useLocationPickerShallowStore('isLocationsView');
  const searchFieldProps = useControlledField({ type: 'text', value: searchTerm, onChange: setSearchTerm });
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  const clearSearch = () => {
    setSearchTerm('');
  };

  const searchValue = useDebouncedValue(searchTerm);

  for (const id in decodedWeaveToken?.ACLS) {
    params.append('location_id', id);
  }

  useEffect(() => {
    if (searchFieldRef.current) {
      setTimeout(() => {
        searchFieldRef.current?.focus();
      }, 100);
    }
  }, [searchFieldRef]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <SearchField
        ref={searchFieldRef}
        {...searchFieldProps}
        placeholder={isLocationsView ? 'Search Locations' : 'Search Organizations'}
        css={{ margin: 1 }}
        name='location-search'
      />

      <UnprivilegedMultiLocationSelector
        searchTerm={searchTerm === '' ? searchTerm : searchValue.toLowerCase()}
        closeModal={closeModal}
        clearSearch={clearSearch}
        simple={simple}
      />
    </div>
  );
};

const PrivilegedLocationPicker = ({ closeModal }: { closeModal?: () => void }) => {
  const searchFieldProps = useFormField({ type: 'text' });
  const searchValue = useDebouncedValue(searchFieldProps.value);
  const { isLocationsView } = useLocationPickerShallowStore('isLocationsView');
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  const { data = [], isLoading } = useQuery(
    ['locations', searchValue],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const res = await LocationsApi.getPrivilegedLocations(query);

      const dataTransform = res?.locations?.map((location) => ({
        ...location,
        locationID: location.locationId,
        parentID: location.parentId,
      })) as DataLocationSearchResults[];
      return dataTransform;
    },
    {
      enabled: !!searchValue,
      select: (data) => {
        return data.filter((location) => location.active === Bool_Enum.TRUE);
      },
    }
  );

  const showSearchField = !isLocationsView;
  const showSearchOrganizationLabel = !isLocationsView;

  useEffect(() => {
    if (searchFieldRef.current) {
      setTimeout(() => {
        searchFieldRef.current?.querySelector('input')?.focus();
      }, 100);
    }
  }, [searchFieldRef]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: theme.spacing(1),
        marginLeft: sideExtendSpacing,
        marginRight: sideExtendSpacing,
        marginBottom: sideExtendSpacing,
        flex: 1,
      }}
    >
      {showSearchField && (
        <div
          css={css`
            padding: ${theme.spacing(0, 3)};
          `}
        >
          <SearchField
            {...searchFieldProps}
            placeholder={showSearchOrganizationLabel ? 'Search Organizations' : undefined}
            css={{ margin: 1 }}
            name='location-search'
            ref={searchFieldRef}
          />
        </div>
      )}

      <PrivilegedMultiLocationSelector
        isLoading={isLoading}
        locationResults={data}
        closeModal={closeModal}
        isPrivileged
        hasSearchTerm={!!searchValue}
      />
    </div>
  );
};

export const LocationPicker = ({ closeModal, simple = false }: { closeModal?: () => void; simple?: boolean }) => {
  return (
    <>
      {isWeaveUser() ? (
        <PrivilegedLocationPicker closeModal={closeModal} />
      ) : (
        <UnprivilegedLocationPicker simple={simple} closeModal={closeModal} />
      )}
    </>
  );
};

export const DecoratedLocationPicker = ({
  closeModal,
  simple = false,
}: {
  closeModal?: () => void;
  simple?: boolean;
}) => {
  const { t } = useTranslation('base');
  const { getScopeName, selectedGroupId } = useAppScopeStore();

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        maxHeight: '100%',
        height: '100%',
      }}
    >
      {selectedGroupId && (
        <>
          <Heading
            level={2}
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Icon name='location' />
            <span css={styles.truncate} title={getScopeName(selectedGroupId)}>
              {getScopeName(selectedGroupId)}
            </span>
          </Heading>
          <hr css={{ height: 1, border: 'none', backgroundColor: theme.colors.neutral20 }} />
        </>
      )}

      <Text
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <span
          css={css`
            display: flex;
            gap: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          <Trans t={t}>
            <Text weight='bold'>Multi-location filtering</Text>
          </Trans>
        </span>
        <Trans t={t}>
          <Text size='medium' color='light'>
            On this page you can select more than one location to filter. Your filters may change on pages that don’t
            support this feature.
          </Text>
        </Trans>
      </Text>

      <LocationPicker closeModal={closeModal} simple={simple} />
    </div>
  );
};

const sideExtendSpacing = theme.spacing(-3);
