import { useEffect, useState } from 'react';
import { InvoiceHistory } from '@weave/schema-gen-ts/dist/schemas/payments/views/service/service.pb';
import dayjs from 'dayjs';
import { UsersQueries } from '@frontend/api-users';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Avatar, Chip, ContentLoader, Heading, Text } from '@frontend/design-system';
import { useGetRequestActivityMap } from './request-activity-map';
import { styles, activityStyles } from './styles';

export const PaymentRequestActivity = ({
  invoiceHistory,
  isLoading,
}: {
  invoiceHistory?: InvoiceHistory[];
  isLoading: boolean;
}) => {
  const { t } = useTranslation('payments');

  return (
    <div css={styles.card}>
      <Heading level={3} css={styles.marginBottom}>
        {t('Payment Request Activity')}
      </Heading>
      <ContentLoader show={isLoading} />
      {!!invoiceHistory?.length ? (
        invoiceHistory
          ?.sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
          .map((action) => <ActivityRow action={action} />)
      ) : (
        <Text>{t('No history to display')}</Text>
      )}
    </div>
  );
};

export const ActivityRow = ({ action }: { action: InvoiceHistory }) => {
  const actionMap = useGetRequestActivityMap(action);

  return (
    <div css={[activityStyles.activityRow, styles.marginBottom]} key={action.id}>
      <Icon name={actionMap.icon} />
      <div>
        <Text>{actionMap?.title}</Text>
        <Text size='small' color='subdued'>
          {formatDate(action.createdAt, 'MMMM D, YYYY')}
        </Text>
      </div>
      <UserChip userId={action.userId} />
    </div>
  );
};

const UserChip = ({ userName, userId }: { userName?: string; userId?: string }) => {
  const [actionUserName, setActionUserName] = useState<string | undefined>(userName);
  const { data: user } = UsersQueries.useGetUserDetails(userId || '');
  const getUserName = () => {
    if (userId && !userName) {
      setActionUserName(user?.username);
    } else if (userName === 'SYSTEM_SCHEDULED_INVOICE') {
      setActionUserName('System Scheduled Invoice');
    }
  };

  useEffect(() => {
    getUserName();
  }, [userId]);

  return (
    actionUserName && (
      <Chip
        variant='default'
        maxWidth='max-content'
        leftElement={
          <Avatar
            bypassColor={{ fill: theme.colors.neutral30, text: theme.colors.white }}
            name={actionUserName}
            size='xxs'
          />
        }
      >
        {actionUserName}
      </Chip>
    )
  );
};
