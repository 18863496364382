import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../../utils';
import { InfoTipPopover } from '../../../../info-tip-popover';
import { MessagingOverview } from '../../../types';
import { MessagingChartBaseInterface, MessagingChartsBase } from './charts-base';

interface Props extends MessagingChartBaseInterface {
  data: MessagingOverview['leadsContacted'];
  dateRangeHelperText?: string;
}

export const MessagesSentChart = ({ data, dateRangeHelperText, ...rest }: Props) => {
  const { t } = useTranslation('analytics');

  const chartData = useMemo(() => {
    return {
      centerMetric: formatters.value.format(data?.totalPatients),
      centerMetricLabel: t('Total'),
      groups: [
        {
          name: 'newPatients',
          value: data?.newPatients,
        },
        {
          name: 'existingPatients',
          value: data?.existingPatients,
        },
        {
          name: 'unsheduledPatients',
          value: data?.unsheduledPatients,
        },
      ],
    };
  }, [data]);

  return (
    <MessagingChartsBase
      {...rest}
      chartConfig={{
        data: chartData,
        type: 'pie',
      }}
      infoTip={
        <InfoTipPopover>
          {t(
            'Leads contacted {{dateRangeHelperText}}, including new patients, existing patients, and unscheduled leads',
            { dateRangeHelperText }
          )}
        </InfoTipPopover>
      }
      legendsFormatValue={formatters.value.format}
      legendsValues={data}
      showMoreView='leadsContacted'
      trackingIdBase='leads-contacted'
      title={t('Leads Contacted')}
    />
  );
};
