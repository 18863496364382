import { ElementType, useState } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { MessagesHooks } from '@frontend/api-messaging';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ThreadBodyComponents, ThreadBodyTypes } from '@frontend/thread-body';
import { ThreadHeader } from '@frontend/thread-header';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, useFileUpload } from '@frontend/design-system';
import { ContextPreview, ContextPreviewProps } from '../../../common';
import { useThreadMedia } from '../../hooks';
import { ThreadBody, ThreadSendingArea } from '../thread-view';

export type SendInThreadStateProps = {
  imageUploadProps: ReturnType<typeof useFileUpload>;
  groupId: string;
  threadId: string;
  person?: Person;
  optedOut?: boolean;
  personInThreadContext?: Pick<Person, 'firstName' | 'lastName' | 'preferredName' | 'personId'>;
  selectedPersonPhone: string;
  onSelectPersonPhone: (personPhone: string) => void;
  selectedDepartmentId?: string;
  onSelectDepartmentId: (departmentId?: string) => void;
  bodyValue: string;
  dynamicModalContextSubtitle?: string;
  setBodyValue: (value: string) => void;
  onSend: () => void;
  onSchedule: (scheduledTime: Date | string, pausable: boolean) => void;
  hideSelectTemplateButton?: boolean;
  isUnknownContactThread?: boolean;
} & Pick<
  ReturnType<typeof MessagesHooks.useThread>,
  'messages' | 'scheduledMessages' | 'isLoadingFirstPage' | 'hasOlderMessages' | 'mediaQueries' | 'fetchOlderMessages'
> &
  Pick<ReturnType<typeof useThreadMedia>, 'threadMedia' | 'removeMediaItem'>;

export type SendInThreadCustomizationProps = {
  bodyAndSendAreaContainerProps?: CommonHTMLAttributes & { css?: Interpolation<Theme> };
  contextPreviewProps?: ContextPreviewProps;
  hideSuperTextAreaSendButton?: boolean;
  onSelectTemplateClick?: () => void;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<
  C,
  SendInThreadStateProps & SendInThreadCustomizationProps
>;

export const SendInThreadView = <C extends ElementType = 'div'>({
  as,
  imageUploadProps,
  groupId,
  threadId,
  person,
  optedOut,
  selectedPersonPhone,
  personInThreadContext,
  onSelectPersonPhone,
  selectedDepartmentId,
  onSelectDepartmentId,
  bodyValue,
  setBodyValue,
  onSend,
  onSchedule,
  messages,
  scheduledMessages,
  isLoadingFirstPage,
  hasOlderMessages,
  mediaQueries,
  fetchOlderMessages,
  isUnknownContactThread,
  threadMedia,
  removeMediaItem,
  bodyAndSendAreaContainerProps,
  contextPreviewProps,
  dynamicModalContextSubtitle,
  hideSuperTextAreaSendButton,
  onSelectTemplateClick,
  hideSelectTemplateButton,
  ...rest
}: PolymorphicProps<C>) => {
  const { t } = useTranslation('integrated-messaging');
  const Component = as || 'div';
  const [hideSendingArea, setHideSendingArea] = useState(false);

  const manageSendingArea = (type: ThreadBodyTypes.onScheduleMessageActionType): void => {
    if (type === 'view_list') {
      setHideSendingArea(true);
    } else {
      setHideSendingArea(false);
    }
  };

  const disableSendingMessage = optedOut && !isLoadingFirstPage;

  return (
    <Component {...imageUploadProps.dropContainerProps} {...rest}>
      <ThreadHeader
        css={{ minHeight: 'auto', height: 'auto' }}
        groupId={groupId}
        primaryContact={person}
        primaryContactId={person?.personId}
        hideAssociatedContacts
        personPhone={selectedPersonPhone}
        outboundPhone={{
          departmentId: selectedDepartmentId ?? '',
        }}
        onPersonPhoneChange={onSelectPersonPhone}
        allowCustomPhoneNumber={true} // Toggle off to disable sending messages to custom numbers
        onOutboundPhoneChange={({ departmentId }) => onSelectDepartmentId(departmentId)}
        variant='horizontal'
      >
        <ThreadHeader.Header>
          <ThreadHeader.Title openContactPanel={false} />
          {contextPreviewProps && (
            <ContextPreview
              css={{
                maxWidth: '45%',
              }}
              {...contextPreviewProps}
              subtitle={dynamicModalContextSubtitle || contextPreviewProps.subtitle}
            />
          )}
        </ThreadHeader.Header>
        <ThreadHeader.SubHeader>
          <ThreadHeader.PersonPhoneSelector />
          <ThreadHeader.DepartmentSelector />
        </ThreadHeader.SubHeader>
      </ThreadHeader>
      <div {...bodyAndSendAreaContainerProps}>
        <ThreadBody
          messages={messages}
          scheduledMessages={[]}
          context={{
            person,
            personInThreadContext, // for when thread body loads messages from different contact (custom number) while the header and sending context doesn't change.
            threadIsLoading: isLoadingFirstPage,
            isNewThread: !isLoadingFirstPage && messages.length === 0,
            hasOlderMessages,
            optedOut,
            threadId: threadId ?? '',
            mediaQueries,
            isUnknownContactThread,
          }}
          fetchOlderMessages={fetchOlderMessages}
          css={{
            maxHeight: 280,
            minHeight: 280,
          }}
        />
        {disableSendingMessage && (
          <ThreadBodyComponents.OptOutBanner
            textProps={{
              size: 'medium',
            }}
            css={{
              margin: theme.spacing(0, 2, 2),
            }}
          />
        )}
        <ThreadBodyComponents.ScheduledMessageIndicator
          scheduledMessages={scheduledMessages}
          viewOnly
          threadId={threadId ?? ''}
          onAction={manageSendingArea}
        />
        {!hideSendingArea && (
          <ThreadSendingArea
            bodyValue={bodyValue}
            css={{ minHeight: 'auto', height: 'auto' }}
            setBodyValue={setBodyValue}
            disabled={disableSendingMessage}
            fileUploadProps={imageUploadProps}
            threadMedia={threadMedia}
            removeMediaItem={removeMediaItem}
            onSubmit={onSend}
            hideSubmitButton={hideSuperTextAreaSendButton}
            extraActions={
              !!onSelectTemplateClick && !hideSelectTemplateButton
                ? [
                    {
                      icon: (
                        <Icon
                          name='template'
                          css={{
                            color:
                              selectedPersonPhone && !disableSendingMessage
                                ? theme.colors.primary50
                                : theme.colors.neutral20,
                          }}
                        />
                      ),
                      label: t('Select a Template'),
                      showLabelAlways: true,
                      showLabelOnHover: false,
                      css: {
                        fontSize: theme.font.size.medium,
                        fontWeight: theme.font.weight.semibold,
                        color: theme.colors.primary50,
                      },
                      onClick: onSelectTemplateClick,
                      disabled: !selectedPersonPhone || disableSendingMessage,
                    },
                  ]
                : []
            }
          />
        )}
      </div>
    </Component>
  );
};
