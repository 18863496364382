import { _useClientSubscriptions } from './_client.subscriptions';
import { _useConversationSubscriptions } from './_conversation.subscriptions';
import { _useIdleDisconnect } from './_use-idle-disconnect';
import { _useSelfPresenceSync, _useTeamPresenceSync } from './_use-presence-sync';
import { _useWeaveWebsocketNotifications } from './_use-weave-websocket-notifications';

type Props = {
  orgId: string;
  clientUserId: string;
  activeConversationId: string | undefined;
};

/** Set up as a cache sync for incoming events */
export const _SubscriptionsManager = ({ orgId, activeConversationId, clientUserId }: Props) => {
  _useConversationSubscriptions(activeConversationId);
  _useClientSubscriptions();
  _useIdleDisconnect();
  _useSelfPresenceSync();
  _useTeamPresenceSync();
  _useWeaveWebsocketNotifications({ orgId, clientUserId });
  return null;
};
_SubscriptionsManager.displayName = '_SubscriptionsManager';
