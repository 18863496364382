import { useState } from 'react';
import { css } from '@emotion/react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { formatCentsToCurrency } from '@frontend/currency';
import { SendInThreadModalContents } from '@frontend/integrated-messaging';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { ContentLoader, formatDate, Text } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useBnplLink } from '../../../hooks/use-bnpl-link';
import { useValidPhoneNumberFromInvoice } from '../../../hooks/use-valid-phone-number-from-invoice';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { ShareInMessageBodyProps } from './share-in-message.types';

const styles = {
  loader: css`
    height: 200px;
  `,
  body: css`
    .wv-modal-header {
      display: none;
    }

    .wv-modal-footer {
      padding-top: ${theme.spacing(2)};
    }

    .step__body {
      /** Add flex to body, so that the message grow:1 works */
      display: flex;
      flex-direction: column;

      /** Compensate for ShareInMessageBody having it's own padding 8px 24px */
      padding-left: 0;
      padding-right: 0;
      margin-top: ${theme.spacing(2)};
    }

    .wv-modal-flex-content {
      @media screen and (min-width: ${breakpoints.mobile.max}px) {
        min-height: 580px;
      }
    }
  `,
};

export const ShareInMessageBody = ({
  isBnplRequest = false,
  step = PAYMENT_MULTI_STEPS.shareInMessage,
  onGoBack,
  onCompleteStep,
}: ShareInMessageBodyProps) => {
  const { invoice } = useCollectPaymentMultiContext();

  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);

  const [selectedPhone, setSelectedPhone] = useState<string>(firstValidPhoneNumber ?? '');
  const { bnplTtpLink, bnplLinkLoading } = useBnplLink({ personPhone: selectedPhone, isBnplRequest });

  if (!invoice) return null;

  if (bnplLinkLoading && isBnplRequest)
    return (
      <section css={styles.loader}>
        <ContentLoader show={true} />
      </section>
    );

  return (
    <StyledStep
      id={step}
      label={PAYMENT_MULTI_STEP_LABELS[step]}
      hasInternalFooter
      components={{
        Footer: () => null,
      }}
      css={styles.body}
    >
      <SendInThreadModalContents
        groupId={invoice.locationId}
        onBack={onGoBack}
        personId={invoice.person.id || ''}
        initPersonPhone={firstValidPhoneNumber}
        templateType={TemplateType_Slug.MANUAL_PAYMENT_REQUEST}
        linkData={{
          link: isBnplRequest && !!bnplTtpLink ? bnplTtpLink : invoice?.links?.payment ?? '',
          relatedId: invoice.id,
        }}
        onThreadChange={({ personPhone }) => setSelectedPhone(personPhone)}
        onSchedule={onCompleteStep}
        contextPreviewProps={{
          iconName: 'dollar-sign',
          title: <Text weight='bold'>{formatCentsToCurrency(invoice?.billedAmount)}</Text>,
          subtitle: <Text>Due Date: {formatDate(invoice?.billedAt, 'MMM D, YYYY')}</Text>,
        }}
      />
    </StyledStep>
  );
};
