import { ButtonSection, Frame } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { useSettingsForm } from '../../hooks';
import { AlignmentSetting, ButtonSetting, FrameSetting, PaddingSetting, SettingsPanel } from './atoms';

export const ButtonSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<ButtonSection>({
    buttonColor: { type: 'text' },
    corners: { type: 'radio' },
    frame: {
      alignment: { type: 'radio' },
      backgroundColor: { type: 'text' },
      paddingTop: { type: 'text' },
      paddingBottom: { type: 'text' },
      paddingLeft: { type: 'text' },
      paddingRight: { type: 'text' },
    },
    textColor: { type: 'text' },
  });

  return (
    <SettingsPanel title={t('Button Styling')}>
      <AlignmentSetting {...getFieldProps<Frame>('frame', 'alignment')} />
      <ButtonSetting
        backgroundColorFieldProps={getFieldProps<string>('buttonColor')}
        cornersFieldProps={getFieldProps<string>('corners')}
        textColorFieldProps={getFieldProps<string>('textColor')}
      />
      <FrameSetting {...getFieldProps<Frame>('frame', 'backgroundColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps<Frame>('frame', 'paddingTop')}
        paddingBottomFieldProps={getFieldProps<Frame>('frame', 'paddingBottom')}
        paddingLeftFieldProps={getFieldProps<Frame>('frame', 'paddingLeft')}
        paddingRightFieldProps={getFieldProps<Frame>('frame', 'paddingRight')}
        isLast
      />
    </SettingsPanel>
  );
};
