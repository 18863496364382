import { CreditCardBrand } from '@frontend/api-invoices';

export enum SearchFailedPaymentStatus {
  STATUS_UNKNOWN = 0,
  SUCCEEDED = 1,
  FAILED = 2,
  PENDING = 3,
}

export type FailedPaymentStatuses = 'STATUS_UNKNOWN' | 'SUCCEEDED' | 'FAILED' | 'PENDING';

export enum SearchFailedPaymentsOrigin {
  UNKNOWN = 0,
  LOCATION = 1,
  PAYMENT_PORTAL = 2,
  TERMINAL = 3,
  LOCATION_PORTAL = 4,
  MOBILE_TAP_TO_PAY = 5,
  PAYMENT_PLAN = 6,
  ONLINE_SCHEDULING = 7,
}

export type FailedPaymentsOrigin =
  | 'UNKNOWN'
  | 'LOCATION'
  | 'PAYMENT_PORTAL'
  | 'TERMINAL'
  | 'LOCATION_PORTAL'
  | 'MOBILE_TAP_TO_PAY'
  | 'PAYMENT_PLAN'
  | 'ONLINE_SCHEDULING';

export enum SearchFailedPaymentsPaymentType {
  PAYMENT_TYPE_UNKNOWN = 0,
  CARD = 1,
  CARD_PRESENT = 2,
  AFFIRM = 3,
  LINK = 4,
  ACH = 5,
}

export type FailedPaymentsPaymentType = 'PAYMENT_TYPE_UNKNOWN' | 'CARD' | 'CARD_PRESENT' | 'AFFIRM' | 'LINK' | 'ACH';

export type SearchFailedPaymentsOrder = 'submittedAt' | '-submittedAt' | 'customerName' | '-customerName';

export type SearchFailedPaymentsSearch = {
  personName?: string;
  origin?: SearchFailedPaymentsOrigin[];
  text?: string;
  paymentStatus?: SearchFailedPaymentStatus[];
  locationId?: string[];
};

export type SearchFailedPaymentsParams = {
  // before?: string;  // This exists, just not using it, for simplicity
  // last?: string;  // This exists, just not using it, for simplicity
  after?: string;
  first?: number;
  search: SearchFailedPaymentsSearch;
  submittedAt?: {
    greaterThanEqual?: string;
    lessThanEqual?: string;
    // greaterThan?: string; // This exists, just not using it, for simplicity
    // lessThan?: string; // This exists, just not using it, for simplicity
  };
  order?: SearchFailedPaymentsOrder;
};

export type SearchFailedPaymentsModel = {
  invoiceId: string;
  personId: string;
  locationId: string;
  amount: number;
  origin: FailedPaymentsOrigin;
  submittedAt: string;
  paymentType: FailedPaymentsPaymentType;
  paymentStatus: FailedPaymentStatuses;
  paymentStatusReason: string;
  paymentId: string;
  personFirstName: string;
  personLastName: string;
  cardBrand: CreditCardBrand;
  cardLastFour: string;
  bankAccountLastFour: string;
  bankName: string;
  userName: string;
  userId: string;
};

export type SearchFailedPaymentsResponseRaw = {
  payments: {
    payments: {
      edges: {
        cursor: string;
        node: SearchFailedPaymentsModel;
      }[];
    };
    totalCount: number; // total number of records
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type SearchFailedPaymentsResponse = {
  payments: SearchFailedPaymentsModel[];
  totalCount: number;
  meta: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  lastEdgeCursor: string | null;
};
