import { useEffect, useMemo, useState } from 'react';
import { CountUnreadVoicemailsResponse } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/phone_records_api.pb';
import { ViewStatus } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import { isEqual, omit } from 'lodash-es';
import { InfiniteData, UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { VoicemailApi, VoicemailTypes as VmTypes } from '@frontend/api-voicemails';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useAppScopeStore, WeaveLocation } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentLoader,
  emptyStateGraphics,
  styles,
  SwitchField,
  Text,
  useAlert,
  useFormField,
} from '@frontend/design-system';
import { MessageStatus, VoicemailInfiniteQueryData } from '../components/all-calls/types';
import { TranscriptionContext } from '../components/transcription/types';
import {
  AllCallsTable,
  usePhonePageSettingShallowStore,
  VoicemailFiltersType,
} from '../hooks/use-phone-config-state-store';
import { queryKeys } from '../query-keys';
import useGetVoicemails from './hooks/use-get-voicemails';
import { ListView, SplitView } from './unread-voicemails';

/**
 * @dashboard-widget
 *
 * id: unread-voicemails-widget
 * title: Unread Voicemails
 * description: Listen and reply to unread voicemails from your dashboard.
 * icon: phone-small
 */

export const UnreadVoicemailsInfo: DashboardWidgetFC = () => {
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const queryClient = useQueryClient();
  const { currentSize } = useDashboardWidget();
  const alert = useAlert();
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const { title: defaultTitle } = useDashboardWidget();

  const locationOptions = useMemo(() => {
    return selectedLocationIds.map((id) => accessibleLocationData[id]);
  }, [selectedLocationIds, accessibleLocationData]);

  const [filteredLocationIds, setFilteredLocationIds] = useState(selectedLocationIds);

  const { show, panelType, context, setShow } = useSlidePanelShallowStore<TranscriptionContext>(
    'setShow',
    'context',
    'panelType',
    'show'
  );

  const voicemailsUnreadCount = PhoneCallsQueries.useVoicemailsUnreadCount();

  const defaultFilters: VoicemailFiltersType = useMemo(
    () => ({
      locationIds: filteredLocationIds,
      syncDeviceSipProfileId: phoneConfig?.sipProfileId,
      filterOptions: {
        callerNumber: '',
        callerName: '',
        endTime: '',
        startTime: '',
        status: MessageStatus.MESSAGE_STATUS_UNREAD,
        mailboxIds: [],
        tagIds: [],
      },
    }),
    [phoneConfig?.sipProfileId]
  );

  const {
    config,
    setPageNumber,
    setFilters: setVoicemailPageFilters,
  } = usePhonePageSettingShallowStore('config', 'setPageSize', 'setPageNumber', 'setFilters');

  const pageNumber = config[AllCallsTable.Unread_Voicemails].pageNumber;
  const pageSize = config[AllCallsTable.Unread_Voicemails].pageSize;
  const filters = config[AllCallsTable.Unread_Voicemails].filters ?? defaultFilters;
  const searchQuery = useMemo(
    () =>
      `${JSON.stringify({
        locationIds: filters.locationIds,
        sipProfileId: filters.syncDeviceSipProfileId,
      })}`,
    [filters.locationIds, filters.syncDeviceSipProfileId, voicemailsUnreadCount]
  );

  useEffect(() => {
    if (isEqual(filters.locationIds, filteredLocationIds)) {
      return;
    }
    setVoicemailPageFilters(AllCallsTable.Unread_Voicemails, { ...filters, locationIds: filteredLocationIds });
  }, [filteredLocationIds]);

  const voicemailsQuery = useGetVoicemails(
    { searchQuery, filters, pageSize },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            ...page,
            rows: page.data.map((row) => row),
          })),
        };
      },
      refetchOnWindowFocus: false,
      enabled: filteredLocationIds.length > 0,
    }
  );

  const { fetchNextPage } = voicemailsQuery;

  // toggle voicemail status
  const { mutateAsync: updateReadStatusAsync } = useMutation(
    (payload: VmTypes.StatusPayload) => {
      const req = omit(payload, 'index');
      return VoicemailApi.setVoicemailViewStatus(req);
    },
    {
      onSuccess: (response, { index, status }) => {
        const data = queryClient.getQueryData<InfiniteData<VoicemailInfiniteQueryData>>([
          selectedLocationIds,
          ...queryKeys.unreadVoicemails(searchQuery),
        ]);
        let pageNum = pageNumber;
        if (index >= pageSize) {
          pageNum = Math.floor(index / pageSize) + 1;
        }

        if (data && data.pages[pageNum - 1]?.data) {
          const localIndex = index % pageSize;

          const message = data.pages[pageNum - 1].data?.[localIndex];
          const totalUnreads = queryClient.getQueryData([
            selectedLocationIds,
            ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
          ]) as CountUnreadVoicemailsResponse;
          if (message) {
            message.readAt =
              status === ViewStatus.VIEW_STATUS_READ && response.voicemail?.readEpoch
                ? // @ts-ignore - This type is wrong, the schema says it should be a number but it isn't
                  new Date(parseInt(response.voicemail.readEpoch)).toISOString()
                : undefined;
            // change rows for split view as well
            const rowMessage = data.pages[pageNum - 1].rows?.[localIndex];
            if (rowMessage) {
              rowMessage.readAt = message.readAt;
            }
            if (panelType === 'voicemailTranscription' && context) {
              setShow(show, panelType, {
                ...context,
                rows: data.pages[pageNum - 1].data.map((row) => ({ ...row })),
              });
            }
            queryClient.setQueryData([selectedLocationIds, ...queryKeys.unreadVoicemails(searchQuery)], data);
          }
          const mailboxId = response.voicemail?.mailboxId ?? '';
          if (!totalUnreads?.countPerMailbox) {
            totalUnreads.countPerMailbox = {};
          }
          if (status === ViewStatus.VIEW_STATUS_READ) {
            const currentUnreads = totalUnreads?.countPerMailbox?.[mailboxId ?? ''] ?? 0;
            if (currentUnreads > 0) {
              totalUnreads.countPerMailbox[mailboxId] = currentUnreads - 1;
            }
          } else {
            const currentUnreads = totalUnreads?.countPerMailbox?.[mailboxId];
            totalUnreads.countPerMailbox[mailboxId] = (currentUnreads || 0) + 1;
          }
          queryClient.setQueryData(
            [selectedLocationIds, ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? '')],
            totalUnreads
          );
        }
      },
    }
  );

  //delete voicemail
  const { mutate: onDelete } = useMutation((req: VmTypes.DeletePayload) => VoicemailApi.deleteVoicemailMedia(req), {
    onMutate: async ({ index }) => {
      queryClient.cancelQueries([selectedLocationIds, ...queryKeys.unreadVoicemails(searchQuery)]);

      const data = queryClient.getQueryData<InfiniteData<VoicemailInfiniteQueryData>>([
        selectedLocationIds,
        ...queryKeys.unreadVoicemails(searchQuery),
      ]);

      if (data) {
        /**
         * When a row is deleted from a page, we want to shift everything past the deleted row up, and fill in the remaining space with the next page's data.
         *
         * If we're on the last page of known available data (data that we've already fetched), we might need to prefetch the next available page to
         * get the next row to fill in the gap. If there is no next page, then we don't need to fetch anything.
         */
        const pageIndexWithDeletedData = pageNumber - 1;
        const targetPageSlice = data.pages[pageIndexWithDeletedData];
        const needToPrefetch = data.pages.length === pageIndexWithDeletedData + 1 && targetPageSlice.meta.hasNext;

        /**
         * This is the data we will be referencing from henceforth.
         *
         * If we need to fetch a new page, we will use the new data as reference.
         */
        const refData = needToPrefetch
          ? await fetchNextPage({
              pageParam: {
                lastId: targetPageSlice.meta.lastId,
                createdAt: targetPageSlice.meta.createdAt,
              },
            }).then((res) => res.data ?? { pages: [], pageParams: [] })
          : data;

        const overallDeleteIndex = pageSize * (pageNumber - 1) + index;
        const allData = refData.pages.map((page) => page.data).flat();

        allData.splice(overallDeleteIndex, 1);

        /**
         * We now have a flat list of all rows (with the deleted item removed). These rows are not separated into pages.
         *
         * We will rebuild the pages from this list.
         */
        const newAllData = [];

        /**
         * Split the data into chunks of pageSize. This will be the data for our new pages
         */
        for (let i = 0; i < allData.length; i += pageSize) {
          const chunk = allData.slice(i, i + pageSize);
          newAllData.push(chunk);
        }

        /**
         * Build the page objects from the new page data chunks
         */
        const newPages = newAllData?.map((slice, i, list) => {
          /**
           * We can determine that the last page in this new list has no next page if refData has 1 more page than the new list.
           * Marking this correctly will disable the pagination button to advance to the next page.
           */
          const thereUsedToBeMoreRows = i === list.length - 1 && data?.pages.length === list.length + 1;

          const hasNext = thereUsedToBeMoreRows ? false : refData.pages[i]?.meta?.hasNext;

          return {
            data: slice,
            meta: {
              lastId: slice[slice.length - 1]?.voicemailId,
              createdAt: slice[slice.length - 1]?.createdAt,
              hasNext,
            },
          };
        });

        const newPageData = {
          pages: newPages,
          pageParams: newPages?.map((page, i) => (i === 0 ? undefined : page.meta)),
        };

        /**
         * Note: We are not invalidating any queries, just making optimistic updates.
         * The queries on this page will be refetched when the user navigates to this page.
         *
         * If there is an error before `setQueryData` is called, we would just show the error alert, and the cached data will be untouched.
         */
        queryClient.setQueryData([selectedLocationIds, ...queryKeys.unreadVoicemails(searchQuery)], newPageData);

        if (!newPages[pageIndexWithDeletedData]) {
          /**
           * If the page we are on is now empty, we need to navigate to the previous page.
           *
           * pageNumber is 1-indexed, pageIndexWithDeletedData is 0-indexed
           */
          setPageNumber(AllCallsTable.Unread_Voicemails, pageIndexWithDeletedData);
        }

        alert.success(t('voicemailDeletedSuccess'));
      }
    },
    onError: () => {
      alert.error(t('voicemailDeletedError'));
    },
    onSuccess: (res, { voicemailId }) => {
      if (panelType === 'voicemailTranscription' && context?.currentRowId === voicemailId) {
        setShow(false);
      }
      // reduce the unread count
      const totalUnreads = queryClient.getQueryData([
        selectedLocationIds,
        ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
      ]) as CountUnreadVoicemailsResponse;
      const mailboxId = res.voicemail?.mailboxId ?? '';
      if (!totalUnreads?.countPerMailbox) {
        totalUnreads.countPerMailbox = {};
      }
      const currentUnreads = totalUnreads?.countPerMailbox?.[mailboxId ?? ''] ?? 0;
      if (currentUnreads > 0) {
        totalUnreads.countPerMailbox[mailboxId] = currentUnreads - 1;
      }
      queryClient.setQueryData(
        [selectedLocationIds, ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? '')],
        totalUnreads
      );
    },
  });

  const isNarrow = currentSize === 'large-narrow';
  return (
    <DashboardWidget>
      <DashboardWidget.Header title={t('Phones')} />
      <DashboardWidget.Content
        css={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: theme.spacing(0, 1),
        }}
      >
        <UnreadVoicemailsContent
          title={defaultTitle}
          voicemailsQuery={voicemailsQuery}
          voicemailsUnreadCount={voicemailsUnreadCount}
          selectedLocationIds={selectedLocationIds}
          isNarrow={isNarrow}
          locationOptions={locationOptions}
          filteredLocationIds={filteredLocationIds}
          setFilteredLocationIds={setFilteredLocationIds}
          searchQuery={searchQuery}
          pageSize={pageSize}
          filters={filters}
          onDeleteVoicemail={onDelete}
          updateReadStatusAsync={updateReadStatusAsync}
        />
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

export const UnreadVoicemailsContent = ({
  title,
  voicemailsQuery,
  voicemailsUnreadCount,
  selectedLocationIds,
  isNarrow,
  locationOptions,
  filteredLocationIds,
  setFilteredLocationIds,
  searchQuery,
  pageSize,
  filters,
  onDeleteVoicemail,
  updateReadStatusAsync,
}: {
  title: string;
  voicemailsQuery: UseInfiniteQueryResult<VoicemailInfiniteQueryData, unknown>;
  voicemailsUnreadCount: number;
  selectedLocationIds: string[];
  isNarrow: boolean;
  locationOptions: WeaveLocation[];
  filteredLocationIds: string[];
  setFilteredLocationIds: (ids: string[]) => void;
  searchQuery: string;
  pageSize: number;
  filters: VoicemailFiltersType;
  onDeleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  updateReadStatusAsync: (payload: VmTypes.StatusPayload) => Promise<VmTypes.SetVoicemailViewStatusResponse>;
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });

  const switchProps = useFormField({
    type: 'switch',
    value: false,
  });
  const isDataAvailable = !voicemailsQuery.isLoading && (voicemailsQuery.data?.pages?.[0]?.data?.length || 0) > 0;

  return (
    <>
      <section
        css={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          alignItems: 'center',
          gap: theme.spacing(2),
          padding: theme.spacing(1, 0),
        }}
      >
        <div
          css={{
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
            minWidth: 0,
          }}
        >
          <Text as='h3' weight='bold'>
            {title}
          </Text>
          {isDataAvailable && !!voicemailsUnreadCount && <Chip variant='primary'>{voicemailsUnreadCount}</Chip>}
        </div>

        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing(2),
            justifySelf: 'end',
            minWidth: 0,
          }}
        >
          {isDataAvailable && !isNarrow && (
            <SwitchField
              {...switchProps}
              css={{ alignItems: 'center' }}
              name='toggleView'
              label={t('Table View')}
              data-trackingid='unread-voicemails-widget-toggle-btn'
            />
          )}

          {selectedLocationIds.length > 1 && (
            <LocationFilterMenu
              locations={locationOptions}
              selectedOptions={selectedLocationIds}
              onChange={setFilteredLocationIds}
              showClearAll={false}
              css={{ maxWidth: 200 }}
            />
          )}
        </div>
      </section>
      <section>
        <ContentLoader show={voicemailsQuery.isLoading} />

        {!isDataAvailable && (
          <div
            css={[
              styles.flexCenter,
              { flexDirection: 'column', flexGrow: 1, gap: theme.spacing(2), paddingTop: theme.spacing(2) },
            ]}
          >
            {!!filteredLocationIds.length && (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <emptyStateGraphics.voicemail height={150} width={150} />

                <Text
                  css={{
                    marginTop: theme.spacing(3),
                    fontSize: theme.fontSize(20),
                    maxWidth: 400,
                  }}
                  textAlign='center'
                  weight='bold'
                  color='subdued'
                >
                  {t('No Items to Display')}
                </Text>
                <Text
                  css={{
                    marginTop: theme.spacing(1),
                    fontSize: theme.fontSize(16),
                    maxWidth: 400,
                  }}
                  color='subdued'
                >
                  {t('Voicemails will display here.')}
                </Text>
              </div>
            )}

            {!filteredLocationIds.length && <Text color='light'>{t('No locations selected')}</Text>}
          </div>
        )}
        {isDataAvailable && !isNarrow && (
          <div
            css={{
              height: '100%',
              overflow: 'auto',
            }}
          >
            {!switchProps.value ? (
              <SplitView
                voicemailDataQuery={voicemailsQuery}
                queryKey={queryKeys.unreadVoicemails(searchQuery).filter(Boolean)}
                onDeleteVoicemail={onDeleteVoicemail}
                updateReadStatus={updateReadStatusAsync}
              />
            ) : (
              <ListView
                filters={filters}
                pageSize={pageSize}
                voicemailDataQuery={voicemailsQuery}
                onDeleteVoicemail={onDeleteVoicemail}
                updateReadStatus={updateReadStatusAsync}
                trackingId='unreadvoicemails-widget-listView'
              />
            )}
          </div>
        )}
        {isDataAvailable && isNarrow && (
          <ListView
            filters={filters}
            pageSize={pageSize}
            voicemailDataQuery={voicemailsQuery}
            onDeleteVoicemail={onDeleteVoicemail}
            updateReadStatus={updateReadStatusAsync}
            trackingId='unreadvoicemails-widget-listView'
          />
        )}
      </section>
    </>
  );
};

UnreadVoicemailsInfo.config = {
  size: {
    large: 'large-wide',
    medium: 'large-wide',
    small: 'large-narrow',
    extraSmall: 'large-narrow',
  },
  feature: 'phone',
};
