import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const DidYouKnow = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.wrapper}>
      <Icon color='success' name='bulb-small' size={24} />
      <div>
        <Text weight='bold'>{t('Did You Know?')}</Text>
        {children}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    background-color: ${theme.colors.success5};
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.success50};
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
  `,
};
