import { useTranslation } from '@frontend/i18n';
import formsPromotionMedia from '../../../assets/forms-promotion.png';
import { FeaturePromotionPageType } from '../../../types';
import { FeaturePromotionBasePage } from '../FeaturePromotionBasePage';

export const DigitalFormsPromotion: FeaturePromotionPageType = ({ mode, handleOpenUpgradeModal }) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'digital-forms-promotion' });
  return (
    <FeaturePromotionBasePage
      title={t('Digitally Manage Your Forms')}
      featureName={t('Digital Forms')}
      description={t(
        'Use Weave Forms to send, sign, and store all of your customer forms. Digitize your existing forms or create new forms using our templates.'
      )}
      mode={mode}
      media={{ src: formsPromotionMedia, alt: t('Digital Forms Example') }}
      handleOpenUpgradeModal={handleOpenUpgradeModal}
    />
  );
};
