import { FC, useMemo } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Button } from '@frontend/design-system';
import { getFeaturePromoActionTrackingId } from '../../../constants/tracking-ids';
import { useFeatureUpgradeDetailsModal } from '../../../hooks/useFeatureUpgradeDetailsModal';
import { useGetSelfUpgradeAction } from '../../../hooks/useGetSelfUpgradeAction';
import { useFeatureSubscription } from '../../../providers/FeatureSubscriptionProvider';

export const WivPromotionAction: FC<{ trackingPrefix: string; actionStyles?: Interpolation<Theme> }> = ({
  trackingPrefix,
  actionStyles,
}) => {
  const { actionType, actionTextData, isLoading } = useGetSelfUpgradeAction();
  const { isBundleUpgradeEligible } = useFeatureSubscription();

  const { featureUpgradeDetailsModalControls, FeatureUpgradeDetailsModal } = useFeatureUpgradeDetailsModal({
    feature: Feature.WEAVE_VERIFY,
    isBundleUpgradeEligible,
  });

  const actionButtonTrackingId = useMemo<string | undefined>(() => {
    return getFeaturePromoActionTrackingId({
      feature: Feature.WEAVE_VERIFY,
      action: actionType,
      source: 'promotion-card',
      upgradeType: isBundleUpgradeEligible ? 'bundle' : 'feature',
    });
  }, [actionType, isBundleUpgradeEligible]);

  if (isLoading) return null;

  return (
    <>
      <Button
        variant='tertiary'
        onClick={featureUpgradeDetailsModalControls.openModal}
        trackingId={`${trackingPrefix}-${actionButtonTrackingId}`}
        css={[{ maxWidth: 'max-content' }, actionStyles]}
      >
        {actionTextData.primaryButtonText}
      </Button>
      {!!FeatureUpgradeDetailsModal && (
        <FeatureUpgradeDetailsModal modalControls={featureUpgradeDetailsModalControls} onActionSuccess={() => null} />
      )}
    </>
  );
};
