import { useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import {
  CreditCardBrandKeys,
  CreditCardBrand,
  PaymentOrigin,
  StatusKeys,
  FilterPaymentType,
  FilterPaymentTypeKeys,
  DateTypeFilter,
  InvoiceFilterStatus,
  InvoiceFilterType,
  RecordedValue,
  InvoiceSearchParams,
} from '@frontend/api-invoices';
import { useArrayState } from '@frontend/array';
import { formatDate, getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { DropdownField, RadioField, TableFilters, TextField, useFormField } from '@frontend/design-system';
import { StatusItem, PaymentTypeItem } from '../..';
import { usePaymentTypeMap, useProviderNameProps } from '../../../hooks';
import { InvoiceFilterMenuProps, InvoiceRefundStatus } from '../../../types';
import { filterCardTypeMap, filterPaymentMethodMap, filterStatusMap, hasFilter } from '../../../utils';
import { CardTypeFilterTitle } from '../../Shared';

const styles = {
  providerOption: css`
    span {
      display: flex;
      gap: ${theme.spacing(1)};
    }
  `,
  checkBoxStyle: css`
    margin: ${theme.spacing(1, 3)};
    > label {
      margin-left: ${theme.spacing(2)};
    }
  `,
  locationDropdown: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

const getDefaultRange = () => [
  formatDate(dayjs().startOf('day'), 'MM/DD/YYYY'),
  formatDate(dayjs().endOf('day'), 'MM/DD/YYYY'),
];

const DATA_TRACKING_PREFIX = `pay-portal-invoices-filter`;

export interface InvoiceFilterProps extends InvoiceFilterMenuProps {
  filter: InvoiceFilterType;
  onChangeFilter: (filter: InvoiceFilterType) => void;
  resetCurrentPage: () => void;
  setOrder: (order: InvoiceSearchParams['order']) => void;
}

export const InvoiceFilter = ({
  modalProps,
  filter,
  setOrder,
  setShowNotification,
  onChangeFilter,
  resetCurrentPage,
}: InvoiceFilterProps) => {
  const { t } = useTranslation('payments');
  const { getLocationName, locationIds } = useMultiQueryUtils();
  const { paymentTypeMap } = usePaymentTypeMap();

  useEffect(() => {
    setShowNotification(hasFilter(filter, ['dateRange']));
  }, [filter]);

  const handleOnApply = (newFilter: InvoiceFilterType) => {
    setShowNotification(hasFilter(newFilter, ['dateRange']));
    onChangeFilter(newFilter);
  };

  // PERSON
  const personFieldProps = useFormField({ type: 'text', value: filter?.person }, [filter?.person]);

  // DATE OPTIONS
  const initialDates = filter?.dateRange;
  const initialDateType = filter?.dateType;

  const dateRangeProps = useFormField({
    type: 'dateRange',
    value: initialDates?.start && initialDates?.end ? [initialDates.start, initialDates.end] : getDefaultRange(),
    maxDate: getTodaysDate('YYYY-M-D'),
  });

  const { providerLocationProps, providerNameProps, providerLocation, providers, getProviderName } =
    useProviderNameProps({ filter });

  // DATE TYPE
  const dateTypeProps = useFormField({
    type: 'dropdown',
    value: initialDateType ?? '',
  });

  // PAYMENT TYPE
  const [paymentType, setPaymentType, addPaymentType, removePaymentType] = useArrayState<PaymentOrigin>(
    filter?.paymentType ?? [],
    [filter?.paymentType]
  );

  const [paymentMethod, setPaymentMethod, addPaymentMethod, removePaymentMethod] = useArrayState<FilterPaymentType>(
    filter?.paymentMethod ?? [],
    [filter?.paymentMethod]
  );

  // CARD TYPE
  const [cardType, setCardType, addCardType, removeCardType] = useArrayState<CreditCardBrand>(filter?.cardType ?? [], [
    filter?.cardType,
  ]);

  // STATUS OPTIONS
  const [status, setStatus, addStatus, removeStatus] = useArrayState<InvoiceFilterStatus | InvoiceRefundStatus>(
    filter?.status ?? [],
    [filter?.status]
  );

  // STATUS FILTER LOGIC
  // - shows paid/cancel when date paid filter is used

  // state variable so we know the date filter is opened or closed

  const disableStatusFilter = (status: InvoiceFilterStatus) =>
    dateTypeProps.touched &&
    dateTypeProps.value === DateTypeFilter.Paid &&
    (status === InvoiceFilterStatus.unpaid || status === InvoiceFilterStatus.canceled);

  useEffect(() => {
    if (dateTypeProps.touched && dateTypeProps.value === DateTypeFilter.Paid) {
      setStatus(status.filter((item) => item !== InvoiceFilterStatus.canceled && item !== InvoiceFilterStatus.unpaid));
      if (!status.length) {
        setStatus((prevState) => [...prevState, InvoiceFilterStatus.paid, InvoiceFilterStatus.partiallyPaid]);
      }
    }
  }, [dateRangeProps.value, dateTypeProps.value, dateTypeProps.touched]);

  // RECORDED FILTER
  const recordedStatusProps = useFormField({
    type: 'radio',
    value: filter?.recorded ?? '',
  });

  // Build filter

  const handleApply = () => {
    const newFilter: InvoiceFilterType = { dateRange: filter?.dateRange };
    if (!status.length && dateTypeProps.touched && dateTypeProps.value === DateTypeFilter.Paid) {
      newFilter.status = [...status, InvoiceFilterStatus.paid, InvoiceFilterStatus.partiallyPaid];
    }

    if (status.length > 0) {
      newFilter.status = status;
    }

    if (dateTypeProps.touched) {
      newFilter.dateType = dateTypeProps.value as DateTypeFilter;
      setOrder(dateTypeProps.value === DateTypeFilter.Paid ? '-paidAt' : '-billedAt');
      resetCurrentPage();
    }

    if (personFieldProps.value) {
      newFilter.person = personFieldProps.value;
    }

    if (recordedStatusProps.value) {
      newFilter.recorded = recordedStatusProps.value as RecordedValue;
    }

    if (paymentType.length > 0) {
      newFilter.paymentType = paymentType;
    }
    if (paymentMethod.length > 0) {
      newFilter.paymentMethod = paymentMethod;
    }

    if (cardType.length > 0) {
      if (!newFilter.paymentMethod?.includes(FilterPaymentType.Card))
        newFilter.paymentMethod = [...(newFilter.paymentMethod ?? []), FilterPaymentType.Card];
      newFilter.cardType = cardType;
    }

    if (providerLocation && providerNameProps.value) {
      const selectedProvider = providers.find((provider) => provider.id === providerNameProps.value);
      if (selectedProvider) {
        newFilter.providerLocation = providerLocation;
        newFilter.providerName = getProviderName(selectedProvider);
      }
    }

    handleOnApply(newFilter);
  };

  return (
    <TableFilters {...modalProps} trackingId={DATA_TRACKING_PREFIX} onApplyClick={handleApply}>
      <TableFilters.Section
        sectionHeaderLabel={t('Search')}
        disableClear={!personFieldProps.value}
        onClearClick={() => personFieldProps.onChange({ value: '', name: '' })}
        css={styles.checkBoxStyle}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-search`}
      >
        <TextField
          name='person-filter'
          label={t('Search Person or Amount')}
          placeholder={t('e.g. Person (First or Last Name), Amount')}
          {...personFieldProps}
          data-trackingid={`${DATA_TRACKING_PREFIX}-txt-search`}
        />
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Provider Name')}
        disableClear={(locationIds.length === 1 || !providerLocation) && !providerNameProps.value}
        onClearClick={() => {
          providerNameProps.onChange({ value: '', name: '' });
          if (locationIds.length > 1) providerLocationProps.onChange({ value: '', name: '' });
        }}
        css={styles.checkBoxStyle}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-provider-name`}
      >
        {locationIds.length > 1 && (
          <DropdownField
            name='location-name-filter'
            label={t('Location')}
            {...providerLocationProps}
            css={styles.locationDropdown}
            data-trackingid={`${DATA_TRACKING_PREFIX}-drp-location-name`}
          >
            {locationIds.map((location) => {
              return (
                <DropdownField.Option
                  value={location}
                  data-trackingid={`${DATA_TRACKING_PREFIX}-drp-${location}`}
                  key={location}
                >
                  {getLocationName(location)}
                </DropdownField.Option>
              );
            })}
          </DropdownField>
        )}
        <DropdownField
          name='provider-name-filter'
          label={t('Provider')}
          {...providerNameProps}
          disabled={!providerLocationProps.value}
          data-trackingid={`${DATA_TRACKING_PREFIX}-drp-provider-name`}
        >
          {providers.map((provider) => {
            const providerName = getProviderName(provider);
            return (
              <DropdownField.Option
                value={provider.id ?? ''}
                data-trackingid={`${DATA_TRACKING_PREFIX}-drp-${providerName}`}
                key={provider.id}
              >
                {providerName}
              </DropdownField.Option>
            );
          })}
        </DropdownField>
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Date Type Filter By')}
        disableClear={dateTypeProps.value === ''}
        onClearClick={() => {
          dateTypeProps.onChange({ value: '', name: '' });
          setStatus([]);
        }}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-datetype-by`}
      >
        <DropdownField
          name='date-type'
          label={t('Filter by')}
          {...dateTypeProps}
          data-trackingid={`${DATA_TRACKING_PREFIX}-drp-datetype`}
        >
          <DropdownField.Option value={DateTypeFilter.Paid} data-trackingid={`${DATA_TRACKING_PREFIX}-drp-item-paid`}>
            {t('Date Invoice Paid')}
          </DropdownField.Option>
          <DropdownField.Option
            value={DateTypeFilter.Created}
            data-trackingid={`${DATA_TRACKING_PREFIX}-drp-item-created`}
          >
            {t('Date Invoice Created')}
          </DropdownField.Option>
        </DropdownField>
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Payment Request Status')}
        disableClear={!status.length}
        onClearClick={() => setStatus([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-status`}
      >
        {Object.entries(InvoiceFilterStatus).map(([statusKey, statusValue]) => {
          return statusValue === 'CANCELED' ? null : (
            <StatusItem<InvoiceFilterStatus | InvoiceRefundStatus>
              key={statusValue}
              value={statusValue}
              label={filterStatusMap(statusKey as StatusKeys)}
              status={status}
              onAdd={addStatus}
              onRemove={removeStatus}
              css={styles.checkBoxStyle}
              disabled={disableStatusFilter(statusValue)}
              data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-${statusKey.toLowerCase()}`}
            />
          );
        })}
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Payment Type')}
        disableClear={!paymentType.length}
        onClearClick={() => setPaymentType([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-payment-type`}
      >
        {Object.entries(paymentTypeMap).map(([typeKey, typeValue]) => {
          return (
            <PaymentTypeItem
              key={typeKey}
              value={typeKey as PaymentOrigin}
              label={typeValue || ''}
              paymentType={paymentType}
              onAdd={addPaymentType}
              onRemove={removePaymentType}
              css={styles.checkBoxStyle}
              disabled={false}
              data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-payment-type-${typeKey.toLowerCase()}`}
            />
          );
        })}
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Recorded Status')}
        disableClear={!recordedStatusProps.value}
        onClearClick={() => recordedStatusProps.onChange({ value: '', name: '' })}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-recorded`}
      >
        <RadioField
          name='recorded'
          {...recordedStatusProps}
          css={css`
            margin-left: ${theme.spacing(3)};
            label {
              margin-left: ${theme.spacing(2)};
            }
          `}
          data-trackingid={`${DATA_TRACKING_PREFIX}-drp-recorded`}
        >
          <RadioField.Radio value={RecordedValue.recorded} data-trackingid={`${DATA_TRACKING_PREFIX}-rdo-recorded`}>
            {t('Recorded')}
          </RadioField.Radio>
          <RadioField.Radio
            value={RecordedValue.notRecorded}
            data-trackingid={`${DATA_TRACKING_PREFIX}-rdo-notrecorded`}
          >
            {t('Not Recorded')}
          </RadioField.Radio>
        </RadioField>
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Payment Method')}
        disableClear={!paymentMethod.length}
        onClearClick={() => setPaymentMethod([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-payment-method`}
      >
        {Object.entries(FilterPaymentType).map(([paymentMethodKey, paymentMethodValue]) => (
          <StatusItem<FilterPaymentType>
            key={paymentMethodValue}
            value={paymentMethodValue}
            label={filterPaymentMethodMap(paymentMethodKey as FilterPaymentTypeKeys)}
            status={paymentMethod}
            onAdd={addPaymentMethod}
            onRemove={removePaymentMethod}
            css={styles.checkBoxStyle}
            disabled={false}
            data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-${paymentMethodKey.toLowerCase()}`}
          />
        ))}
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={<CardTypeFilterTitle />}
        disableClear={!cardType.length}
        onClearClick={() => setCardType([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-card-type`}
      >
        {Object.entries(CreditCardBrand).map(([cardTypeKey, cardTypeValue]) => (
          <StatusItem<CreditCardBrand>
            key={cardTypeValue}
            value={cardTypeValue}
            label={filterCardTypeMap(cardTypeKey as CreditCardBrandKeys)}
            status={cardType}
            onAdd={addCardType}
            onRemove={removeCardType}
            css={styles.checkBoxStyle}
            disabled={false}
            data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-${cardTypeKey.toLowerCase()}`}
          />
        ))}
      </TableFilters.Section>
    </TableFilters>
  );
};
