import { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { SetupFutureUsageElementsProp } from '@frontend/api-weave-pay';
import { PaymentsFeatureFlags, useMerchant } from '@frontend/payments-hooks';
import { ContentLoader } from '@frontend/design-system';
import { PaymentConfigName, useWeaveAppStripe, useWeaveAppStripeConfig } from './hooks';
import { stripeAppearance as appearance } from './utils';

export enum PaymentsFlowType {
  CardOnFilePayment = 'Card on File Payment',
  ManualCardEntry = 'Manual Card Entry',
  AddCardOnFile = 'Add Card On File',
  Terminal = 'Terminal',
}

interface StripeElementsWrapperProps {
  type: PaymentsFlowType;
  children: ReactNode;
  mode?: 'payment' | 'setup';
  amount?: number;
  paymentConfigName?: PaymentConfigName;
  disableACH?: boolean;
}

export const StripeElementsWrapper = ({
  mode = 'payment',
  amount = 50,
  type,
  children,
  paymentConfigName,
  disableACH,
}: StripeElementsWrapperProps) => {
  const { stripePromise, stripeKey } = useWeaveAppStripe();
  const { activeProcessor, merchantFetched, locationId } = useMerchant();
  const { aggregateValue: enableACHOnFile } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    locationIds: [locationId],
    flagName: PaymentsFeatureFlags.ACHOnFile,
  });

  const isAddCardOnFileFlow = type === PaymentsFlowType.AddCardOnFile;

  const setupFutureUsage = isAddCardOnFileFlow ? SetupFutureUsageElementsProp.OffSession : undefined;
  mode = isAddCardOnFileFlow ? 'setup' : mode;
  paymentConfigName = !disableACH && isAddCardOnFileFlow && enableACHOnFile ? 'cardAndACH' : paymentConfigName;

  const paymentMethodConfiguration = useWeaveAppStripeConfig({
    paymentConfigName: paymentConfigName ?? 'cardOnly',
  });

  const cardOnFilePaymentMethodOptions = { us_bank_account: { verification_method: 'instant' as const } };

  //we want undefined value of paymentMethodConfiguration to be allowed to set the default config
  if (!merchantFetched || !stripeKey || paymentMethodConfiguration === null) return <ContentLoader show={true} />;
  return (
    <Elements
      key={stripeKey}
      stripe={stripePromise}
      options={{
        mode,
        appearance,
        setupFutureUsage,
        currency: 'usd',
        paymentMethodConfiguration,
        onBehalfOf: activeProcessor?.stripeId,
        ...(mode !== 'setup' ? { amount } : {}),
        ...(isAddCardOnFileFlow ? { paymentMethodOptions: cardOnFilePaymentMethodOptions } : {}),
      }}
    >
      {children}
    </Elements>
  );
};
