import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Checkbox, Chip, Table, TableColumnConfig, useControlledField } from '@frontend/design-system';
import { checkCanStartTrial } from '../../../self-upgrade.util';
import { FreeTrialEligibilityData } from '../../../types';
import { InfoTextWithTooltip } from '../common/InfoTextWithTooltip';

interface MultiFreeTrialTableProps {
  data: FreeTrialEligibilityData[];
  initialSelectedData: FreeTrialEligibilityData[];
  onChangeSelection: (locationIds: string[]) => void;
}

export const MultiFreeTrialTable: FC<MultiFreeTrialTableProps> = ({
  data = [],
  initialSelectedData,
  onChangeSelection,
}) => {
  const { accessibleLocationData } = useAppScopeStore();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'free-trial-location-selection-table' });

  const [selectedLocationMap, setSelectedLocationMap] = useState<Record<string, boolean>>({});
  const selectionInitialisedRef = useRef(false);

  const columns = useMemo<TableColumnConfig<FreeTrialEligibilityData>[]>(() => {
    return [
      {
        id: 'select',
        Header: '',
        accessor: (rowData): { isSelectable: boolean; locationID: string } => ({
          isSelectable: checkCanStartTrial(rowData),
          locationID: rowData.locationId,
        }),
        cellRenderer: ({ isSelectable, locationID }: { isSelectable: boolean; locationID: string }) => {
          if (isSelectable) {
            return (
              <SelectCell
                locationId={locationID}
                selectedLocationMap={selectedLocationMap}
                setSelectedLocationMap={setSelectedLocationMap}
              />
            );
          }
          return <Icon name='lock' color='disabled' />;
        },
        disableSortBy: true,
        maxWidth: 60,
      },
      {
        id: 'location',
        Header: t('Location'),
        accessor: (rowData): { locationName?: string; isRowDisabled: boolean } => ({
          locationName: accessibleLocationData[rowData.locationId]?.name,
          isRowDisabled: !checkCanStartTrial(rowData),
        }),
        cellRenderer: ({ locationName, isRowDisabled }: { locationName: string; isRowDisabled: boolean }) => {
          return (
            <Chip.SingleChip css={{ maxWidth: theme.spacing(30), opacity: isRowDisabled ? 0.5 : 1 }}>
              {locationName}
            </Chip.SingleChip>
          );
        },
        sortType: (rowA, rowB) => {
          return (accessibleLocationData[rowA.original.locationId]?.name || '')?.localeCompare(
            accessibleLocationData[rowB.original.locationId]?.name || ''
          );
        },
        maxWidth: 350,
      },
      {
        id: 'status',
        Header: '',
        accessor: (rowData) => rowData,
        cellRenderer: (rowData: FreeTrialEligibilityData) => {
          if (rowData.trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_STATUS_UNSPECIFIED) {
            return (
              <InfoTextWithTooltip
                text={t('This location is not eligible')}
                tooltipText={t('This feature is incompatible with this location.')}
              />
            );
          } else if (rowData.trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ACTIVE) {
            return <InfoTextWithTooltip text={t('Trial Active')} />;
          } else if (!rowData.canManageAddons) {
            return <InfoTextWithTooltip text={t('You are not an admin at this location')} />;
          }
          return null;
        },
        disableSortBy: true,
      },
    ];
  }, [accessibleLocationData, selectedLocationMap]);

  const modifiedData = useMemo(() => {
    return data.sort((a, b) => {
      const priorityA = checkCanStartTrial(a) ? 0 : 1;
      const priorityB = checkCanStartTrial(b) ? 0 : 1;
      return priorityA - priorityB;
    });
  }, [data]);

  useEffect(() => {
    onChangeSelection(
      Object.entries(selectedLocationMap)
        .filter(([, isSelected]) => isSelected)
        .map(([locationId]) => locationId)
    );
  }, [selectedLocationMap]);

  useEffect(() => {
    if (!selectionInitialisedRef.current) {
      setSelectedLocationMap(
        initialSelectedData.filter(checkCanStartTrial).reduce<Record<string, boolean>>((acc, { locationId }) => {
          acc[locationId] = true;
          return acc;
        }, {})
      );
      selectionInitialisedRef.current = true;
    }
  }, [initialSelectedData]);

  return <Table<FreeTrialEligibilityData> colConfig={columns} data={modifiedData} />;
};

type SelectCellProps = {
  locationId: string;
  selectedLocationMap: Record<string, boolean>;
  setSelectedLocationMap: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const SelectCell = ({ locationId, selectedLocationMap, setSelectedLocationMap }: SelectCellProps) => {
  const checkboxProps = useControlledField({
    type: 'checkbox',
    value: !!selectedLocationMap[locationId],
    onChange: () =>
      setSelectedLocationMap((state) => ({
        ...state,
        [locationId]: !state[locationId],
      })),
  });
  return <Checkbox {...checkboxProps} name={`${locationId}-select`} error={false} />;
};
