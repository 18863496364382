import { ReactNode, useState } from 'react';
import { css } from '@emotion/react';
import { SubmitFeedbackRequest } from '@weave/schema-gen-ts/dist/schemas/call-intelligence/v2';
import { FeedbackType, Rating } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { CallIntelMutations, CallIntelTypes } from '@frontend/api-call-intel';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  AIIcon,
  useModalControl,
  useAlert,
  useTooltip,
  Button,
  Text,
  TextLink,
  FeedbackIcon,
  FeedbackBadIcon,
  EditIcon,
  IconButton,
} from '@frontend/design-system';
import { featureFlags } from '../../feature-flags';
import { trackingIds } from '../../tracking-ids';
import { Feedback, FeedbackModal } from '../feedback-modal';

type AIRating = Rating.RATING_NEGATIVE | Rating.RATING_POSITIVE;

type Props = {
  call?: CallIntelTypes.Call | null;
  callMetadata: CallIntelTypes.CallMetadata;
  refetchCallMetadata: () => void;
  isDemoAccount?: boolean;
};

type EditRatingProps = {
  isLoading?: boolean;
  onEdit: () => void;
};

type UpdateRatingProps = {
  isLoading?: boolean;
  onNegative: () => void;
  onPositive: () => void;
};

type ChangeRatingProps = {
  isLoading?: boolean;
  children: ReactNode;
  onCancel: () => void;
};

const EditRatingButton = ({ isLoading, onEdit }: EditRatingProps) => {
  const { t } = useTranslation('analytics');
  const { tooltipProps, triggerProps: toolTipTriggerProps, Tooltip } = useTooltip();

  return (
    <div {...toolTipTriggerProps}>
      <IconButton
        className='icon-button'
        disabled={isLoading || isWeaveUser()}
        label={t('Change your rating')}
        onClick={onEdit}
        showLabelOnHover
        size='xsmall'
        trackingId={trackingIds.callIntel.changeCallAnalysisRating}
      >
        <EditIcon size={16} />
      </IconButton>
      <Tooltip {...tooltipProps}>
        {isWeaveUser()
          ? t(
              'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
            )
          : t('Change your rating')}
      </Tooltip>
    </div>
  );
};

type AddRatingBarProps = { existingRating?: keyof typeof Rating } & UpdateRatingProps;

const AddRatingBar = ({ isLoading, onNegative, onPositive, existingRating }: AddRatingBarProps) => {
  const { t } = useTranslation('analytics');
  const { tooltipProps, triggerProps: toolTipTriggerProps, Tooltip } = useTooltip();
  return (
    <div css={styles}>
      <AIIcon style={{ color: theme.colors.secondary.eggplant50 }} />
      <div className='feedback-buttons-wrapper' {...toolTipTriggerProps}>
        <Button
          css={[iconButtonStyle, existingRating === Rating.RATING_POSITIVE && thumbsUpStyle]}
          disabled={isLoading || isWeaveUser()}
          hoverLabel={t('Share positive feedback')}
          iconName='feedback'
          label={t('Yes, accurate')}
          onClick={onPositive}
          size='small'
          trackingId={trackingIds.callIntel.accurateCallAnalysisFeedback}
          variant='secondary'
        />
        <Button
          css={[iconButtonStyle, existingRating === Rating.RATING_NEGATIVE && thumbsDownStyle]}
          destructive={existingRating === Rating.RATING_NEGATIVE}
          disabled={isLoading || isWeaveUser()}
          hoverLabel={t('Report an issue')}
          iconName='feedback-bad'
          label={t('No, not accurate')}
          onClick={onNegative}
          size='small'
          trackingId={trackingIds.callIntel.inaccurateCallAnalysisFeedback}
          variant='secondary'
        />
        {isWeaveUser() && (
          <Tooltip {...tooltipProps}>
            {t(
              'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
            )}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

type OldRatingBarProps = {
  isLoading?: boolean;
  isEditingRating: boolean;
  setIsEditingRating: (isEditingRating: boolean) => void;
  existingRating?: keyof typeof Rating;
  onNegative: () => void;
  onPositive: () => void;
};

const OldRatingBar = ({
  isLoading,
  isEditingRating,
  setIsEditingRating,
  onNegative,
  onPositive,
  existingRating,
}: OldRatingBarProps) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');

  const handleEditRating = () => {
    if (isWeaveUser()) {
      alert.warning(
        t(
          'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
        )
      );
      return;
    }
    setIsEditingRating(true);
  };

  return (
    <div css={oldRatingBarStyle}>
      {existingRating && existingRating !== Rating.RATING_UNKNOWN ? (
        <>
          {isEditingRating ? (
            <>
              <ChangeRating isLoading={isLoading} onCancel={() => setIsEditingRating(false)}>
                <UpdateRatingButtons isLoading={isLoading} onNegative={onNegative} onPositive={onPositive} />
              </ChangeRating>
            </>
          ) : (
            <>
              {existingRating === Rating.RATING_POSITIVE ? <PositiveRating /> : <NegativeRating />}
              <EditRatingButton isLoading={isLoading} onEdit={handleEditRating} />
            </>
          )}
        </>
      ) : (
        <>
          <NotRated />
          <UpdateRatingButtons isLoading={isLoading} onNegative={onNegative} onPositive={onPositive} />
        </>
      )}{' '}
    </div>
  );
};

const UpdateRatingButtons = ({ isLoading, onNegative, onPositive }: UpdateRatingProps) => {
  const { t } = useTranslation('analytics');
  const { tooltipProps, triggerProps: toolTipTriggerProps, Tooltip } = useTooltip();

  return (
    <div className='feedback-buttons-wrapper' {...toolTipTriggerProps}>
      <IconButton
        className='icon-button'
        disabled={isLoading || isWeaveUser()}
        label={t('Yes, accurate')}
        onClick={onPositive}
        size='xsmall'
        trackingId={trackingIds.callIntel.accurateCallAnalysisFeedback}
      >
        <FeedbackIcon size={16} />
      </IconButton>
      <IconButton
        className='icon-button'
        disabled={isLoading || isWeaveUser()}
        label={t('No, not accurate')}
        onClick={onNegative}
        size='xsmall'
        trackingId={trackingIds.callIntel.inaccurateCallAnalysisFeedback}
      >
        <FeedbackBadIcon size={16} />
      </IconButton>
      {isWeaveUser() && (
        <Tooltip {...tooltipProps}>
          {t(
            'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
          )}
        </Tooltip>
      )}
    </div>
  );
};

const PositiveRating = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <FeedbackIcon color='success' />
      <Text>
        <Trans t={t}>
          These takeaways have been rated as <strong style={{ color: theme.colors.status.success }}>accurate</strong>
        </Trans>
      </Text>
    </>
  );
};

const NegativeRating = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <FeedbackBadIcon color='error' />
      <Text>
        <Trans t={t}>
          These takeaways have been rated as <strong style={{ color: theme.colors.status.critical }}>inaccurate</strong>
        </Trans>
      </Text>
    </>
  );
};

const ChangeRating = ({ children, isLoading, onCancel }: ChangeRatingProps) => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <span style={{ color: theme.colors.secondary.eggplant50 }}>
        <AIIcon />
      </span>
      <Text>{t('Change your rating?')}</Text>
      {children}
      <TextLink disabled={isLoading} onClick={onCancel} trackingId={trackingIds.callIntel.cancelEditingAIRating}>
        {t('Cancel')}
      </TextLink>
    </>
  );
};

const NotRated = () => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <span style={{ color: theme.colors.secondary.eggplant50 }}>
        <AIIcon />
      </span>
      <Text>{t('Are the takeaways of this call accurate?')}</Text>
    </>
  );
};

export const CallTakeawayFeedbackBanner = ({ call, callMetadata, refetchCallMetadata, isDemoAccount }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { modalProps: feedbackModalProps, triggerProps: feedbackModalTriggerProps } = useModalControl();
  const [isEditingRating, setIsEditingRating] = useState<boolean>(false);
  const [existingRating, setExistingRating] = useState<keyof typeof Rating | undefined>(callMetadata?.rating);
  const { isLoading, mutateAsync: submitFeedback } = CallIntelMutations.useSubmitFeedback({
    onSuccess: () => {
      alert.success(t('Rating submitted. Thank you!'));
    },
    onError: (error) => {
      alert.error(t('Failed to submit rating. Please try again.'));
      console.error(error);
    },
  });
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isEditAiEnabled = getFeatureFlagValue(featureFlags.enableCallIntelEditAi);

  if (!call) {
    return null;
  }

  const submitRating = (rating: AIRating, additionalInfo?: Partial<SubmitFeedbackRequest>) => {
    if (isWeaveUser()) {
      alert.warning(
        t(
          'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
        )
      );
      return;
    }

    const userID = getUser()?.userID;

    if (!call || !call.id || !userID || !call.locationId) {
      return;
    }

    if (isDemoAccount) {
      handleRatingSubmitSuccess(rating, additionalInfo);
      return;
    }

    submitFeedback({
      callId: call.id,
      rating,
      userId: userID,
      locationId: call.locationId,
      feedbackType: FeedbackType.FEEDBACK_AI,
      ...additionalInfo,
    }).then(() => handleRatingSubmitSuccess(rating, additionalInfo));
  };

  const handleRatingSubmitSuccess = (rating: AIRating, additionalInfo?: Partial<SubmitFeedbackRequest>) => {
    setExistingRating(rating);
    setIsEditingRating(false);

    isDemoAccount === false && refetchCallMetadata();

    if (additionalInfo) {
      feedbackModalProps.onClose();
    } else {
      feedbackModalTriggerProps.onClick();
    }
  };

  const handleFeedbackSubmit = (feedback: Feedback) => {
    if (!existingRating || (existingRating != Rating.RATING_POSITIVE && existingRating != Rating.RATING_NEGATIVE)) {
      return;
    }

    submitRating(Rating[existingRating], {
      comment: feedback.message,
      allowWeaveToContact: feedback.allowWeaveToContact,
      analysisType: feedback.issues,
    });
  };

  const feedbackModelSubtitle = () => {
    if (isEditAiEnabled) {
      return existingRating === Rating.RATING_POSITIVE
        ? t('Please share details about what you found valuable in the Call Takeaways.')
        : t('Please share details about the issues in the Call Takeaways.');
    }

    return existingRating === Rating.RATING_POSITIVE
      ? t('Please share details about what you found positive or beneficial in the takeaways of this call.')
      : t(
          'Please specify what was inaccurate in the takeaways of this call and provide suggestions for the correct values.'
        );
  };

  return (
    <>
      {isEditAiEnabled ? (
        <AddRatingBar
          isLoading={isLoading}
          existingRating={existingRating}
          onNegative={() => submitRating(Rating.RATING_NEGATIVE)}
          onPositive={() => submitRating(Rating.RATING_POSITIVE)}
        />
      ) : (
        <OldRatingBar
          isLoading={isLoading}
          isEditingRating={isEditingRating}
          setIsEditingRating={setIsEditingRating}
          onNegative={() => submitRating(Rating.RATING_NEGATIVE)}
          onPositive={() => submitRating(Rating.RATING_POSITIVE)}
          existingRating={existingRating}
        />
      )}
      <FeedbackModal
        disableCloseOnOverlayClick
        isLoading={isLoading}
        modalProps={feedbackModalProps}
        onSubmit={handleFeedbackSubmit}
        productName='ci-call-analysis-feedback'
        isAIFeedback
        isAIPositiveFeedback={existingRating === Rating.RATING_POSITIVE}
        subtitle={feedbackModelSubtitle()}
        title={t('Provide Additional Feedback')}
        trackingIdBase={trackingIds.callIntel.aiAdditionalFeedback}
      />
    </>
  );
};

const styles = css`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.full};
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 2)};
  justify-content: center;

  > p {
    line-height: 1rem;
  }

  .feedback-buttons-wrapper {
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    margin: ${theme.spacing(0.5, 0)};
  }
`;

const oldRatingBarStyle = css`
  align-items: center;
  background-color: ${theme.colors.neutral5};
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  gap: ${theme.spacing(1)};
  justify-content: center;
  padding: ${theme.spacing(1)};
  width: 100%;

  .icon-button {
    &:hover {
      background-color: ${theme.colors.neutral10} !important;
    }
  }

  > p {
    line-height: 1rem;
  }

  .feedback-buttons-wrapper {
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  }
`;

const iconButtonStyle = css`
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(0.5)};
`;

const thumbsUpStyle = css`
  background-color: ${theme.colors.success5};
`;

const thumbsDownStyle = css`
  background-color: ${theme.colors.critical5};
`;
