import { memo, useCallback, useEffect, useReducer } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useRouter } from '@tanstack/react-location';
import { UseQueryOptions, useQueryClient } from 'react-query';
import { DeptPhoneNumberApi, DeptPhoneNumberTypes } from '@frontend/api-department-phone-numbers';
import { DepartmentsApi } from '@frontend/api-departments';
import { ForwardingNumberApi } from '@frontend/api-forwarding-number';
import { InstructionsTypes, PhoneTreeTypes } from '@frontend/api-phone-tree';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { removeNonDigits } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  FormFieldActionTypes,
  Modal,
  SpinningLoader,
  TextField,
  TextLink,
  useForm,
  useFormField,
  useModalControl,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { noMediaID } from '../../utils/phone-utils';
import { AddForwardingNumberModal } from '../add-forwarding-number-modal';
import { ExtensibleNode, RenderProps } from '../cascading-components';
import { AddForwardingNumberOption } from '../forwarding-number-picker';
import { MediaPicker, MediaPickerFileState, MediaPickerOptionValues } from '../media-picker';
import { InstructionAction } from './phone-tree-reducers';

type DropdownExtension = {
  createNodes?: () => ExtensibleNode<DropdownExtension>[];
  queryParams?: UseQueryOptions<any, any, any, any>;
  id:
    | InstructionsTypes.Instruction
    | PhoneTreeTypes.MenuTypes.SubTree
    | PhoneTreeTypes.MenuTypes.Navigate
    | 'instruction';
  displayLabel?: string;
  addLink?: string;
};
export type DropdownCascadingNode = ExtensibleNode<DropdownExtension>;

type InstructionDropdownExtension = {
  id: 'instruction';
  value: InstructionsTypes.Instruction | PhoneTreeTypes.MenuTypes.Navigate;
};

export type InstructionDropdownCascadingNode = ExtensibleNode<InstructionDropdownExtension>;

const useIsCalledFromDepartmentsPage = (paramsId: string) => {
  const { data: departments } = useLocalizedQuery({
    queryKey: queryKeys.listDepartments(),
    queryFn: () => DepartmentsApi.listDept({}),
  });
  return departments?.departments?.some((item) => item.id === paramsId);
};

const ForwardingNumberInstruction = memo(
  ({
    forwardingNumId,
    node,
    updateInstruction,
  }: {
    forwardingNumId?: string;
    node: DropdownCascadingNode;
    updateInstruction: ({
      type,
      payload,
    }: Extract<InstructionAction, { type: InstructionsTypes.Instruction.ForwardNumber }>) => void;
  }) => {
    const { selectedLocationIds } = useAppScopeStore();
    const locationId = selectedLocationIds[0];
    const { state } = useRouter();
    const departmentId = state.matches[0].params.id;
    const { triggerProps, modalProps } = useModalControl();

    const { data: forwardingNumbers } = useLocalizedQuery({
      queryKey: queryKeys.forwardingNumbers(),
      queryFn: () => ForwardingNumberApi.list({ locationId }),
      select: (data) =>
        data.sort((a, b) => {
          const aName = a.name || '';
          const bName = b.name || '';
          return aName.localeCompare(bName);
        }),
    });

    const { data: numbers } = useLocalizedQuery({
      enabled: useIsCalledFromDepartmentsPage(departmentId),
      queryKey: queryKeys.listDepartmentPhoneNumbers(departmentId),
      queryFn: () => DeptPhoneNumberApi.getDepartmentPhonenumbers({ departmentId }),
    });
    const deptPhoneNumbers = numbers?.departmentPhoneNumbers?.[0].departmentPhoneNumbers;
    const allowedForwardNumbers =
      forwardingNumbers?.filter((fwdItem) => {
        return !deptPhoneNumbers
          ?.filter((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => !!item.isAssigned)
          .some(
            (deptPhone: DeptPhoneNumberTypes.DepartmentPhoneNumberType) =>
              removeNonDigits(deptPhone.number) === fwdItem.number
          );
      }) ?? [];

    const dropdownProps = useFormField({ type: 'dropdown', value: forwardingNumId || allowedForwardNumbers?.[0]?.id }, [
      forwardingNumId,
    ]);

    const onChange = useCallback(
      (e: any) => {
        if (e.value) {
          updateInstruction({
            type: InstructionsTypes.Instruction.ForwardNumber,
            payload: {
              targetId: e.value,
            },
          });
        }
        dropdownProps.onChange(e);
      },
      [node]
    );

    const changeForwardingNumber = (ForwardingNumberID: string) => {
      if (ForwardingNumberID) {
        updateInstruction({
          type: InstructionsTypes.Instruction.ForwardNumber,
          payload: {
            targetId: ForwardingNumberID,
          },
        });
      }

      dropdownProps.onChange({
        name: 'forwardingNumberId',
        value: ForwardingNumberID || allowedForwardNumbers?.[0]?.id,
      });
    };

    const disallowedNumbers = deptPhoneNumbers
      ?.filter((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => !!item.isAssigned)
      .map((item: DeptPhoneNumberTypes.DepartmentPhoneNumberType) => removeNonDigits(item.number));

    useEffect(() => {
      updateInstruction({
        type: InstructionsTypes.Instruction.ForwardNumber,
        payload: {
          targetId: forwardingNumId || allowedForwardNumbers?.[0]?.id,
        },
      });
    }, [forwardingNumId]);

    return (
      <>
        <DropdownField
          {...dropdownProps}
          name='forwardingNumberId'
          label='Select Number'
          css={{
            width: '300px',
            marginRight: theme.spacing(0.25),
          }}
          onChange={onChange}
        >
          <AddForwardingNumberOption triggerProps={triggerProps} />
          {allowedForwardNumbers?.map((fwdNumber) => (
            <DropdownField.Option key={fwdNumber.id} value={fwdNumber.id}>
              {`${fwdNumber.name} (${fwdNumber.number})`}
            </DropdownField.Option>
          ))}
        </DropdownField>
        <Modal asChild {...modalProps}>
          <AddForwardingNumberModal
            fwdNumbers={allowedForwardNumbers}
            disallowedNumbers={disallowedNumbers}
            locationID={locationId}
            changeForwardingNumber={changeForwardingNumber}
            closeModal={modalProps.onClose}
          />
        </Modal>
      </>
    );
  }
);

const PlayInstruction = memo(
  ({
    mediaValue,
    node,
    updateInstruction,
  }: {
    mediaValue?: string;
    node: DropdownCascadingNode;
    updateInstruction: ({
      type,
      payload,
    }: Extract<InstructionAction, { type: InstructionsTypes.Instruction.Play }>) => void;
  }) => {
    const { selectedLocationIds } = useAppScopeStore();
    const locationId = selectedLocationIds[0];

    const dropdownProps = useFormField({ type: 'dropdown', value: mediaValue }, [mediaValue]);
    const onChange = useCallback(
      (e: any) => {
        updateInstruction({
          type: InstructionsTypes.Instruction.Play,
          payload: {
            targetId: e.value,
          },
        });
        dropdownProps.onChange(e);
      },
      [node]
    );
    return (
      <MediaPicker
        locationId={locationId}
        {...dropdownProps}
        requestedTypes={{ custom: true, standard: false }}
        onChange={onChange}
        name={node.id}
        label={node.label}
      />
    );
  }
);

type PromptActions =
  | {
      type: 'media';
      payload: { id: string; type: 'greeting' | 'media' | 'skip' };
    }
  | { type: 'mailbox'; payload: { id: string } };

type PromptState =
  | PhoneTreeTypes.VoicemailPromptGreeting
  | PhoneTreeTypes.VoicemailPromptMedia
  | PhoneTreeTypes.VoicemailPromptSkipGreeting;

/**
 * Create the appropriate VoicemailPrompt object based on the type of media
 *
 * These values are received from the MediaPicker
 */
const getPrompt = (
  type: 'media' | 'greeting' | 'skip',
  voicemailBoxId: string,
  mediaId?: string
): PhoneTreeTypes.AnyVoicemailPrompt => {
  let obj;
  if (type === 'media') {
    obj = {
      voicemailBoxId: voicemailBoxId,
      ...(mediaId && { systemMediaId: mediaId }),
    } as PhoneTreeTypes.VoicemailPromptMedia;
  } else if (type === 'greeting') {
    obj = {
      voicemailBoxId: voicemailBoxId,
      ...(mediaId && { voicemailGreetingId: mediaId }),
    } as PhoneTreeTypes.VoicemailPromptGreeting;
  } else if (type === 'skip') {
    obj = {
      voicemailBoxId: voicemailBoxId,
      skipGreeting: mediaId === MediaPickerOptionValues.NO_GREETING,
    } as PhoneTreeTypes.VoicemailPromptSkipGreeting;
  } else {
    throw new Error('no type');
  }

  return obj;
};

const promptReducer = (state: PromptState, action: PromptActions): PromptState => {
  const { type, payload } = action;
  switch (type) {
    case 'mailbox': {
      return { ...state, voicemailBoxId: payload.id };
    }
    case 'media': {
      return getPrompt(payload.type, state.voicemailBoxId, payload.id);
    }
  }
};

const getInitialMediaId = (state: PromptState) => {
  let mediaId;
  if (state) {
    if (isVoicemailPromptGreeting(state)) {
      mediaId = state.voicemailGreetingId;
    } else if (isVoicemailPromptMedia(state)) {
      mediaId = state.systemMediaId;
    } else {
      mediaId = state.skipGreeting ? MediaPickerOptionValues.NO_GREETING : MediaPickerOptionValues.DEFAULT_GREETING;
    }
  }

  // Convert the noMediaID to 'none' so that the picker understands it
  if (mediaId === noMediaID) {
    mediaId = 'none';
  }
  return mediaId;
};

const VoicemailPromptInstruction = memo(
  ({
    options,
    node,
    updateInstruction,
    initialValue,
  }: {
    options: DropdownCascadingNode[];
    node: DropdownCascadingNode;
    initialValue?: PhoneTreeTypes.AnyVoicemailPrompt;
    updateInstruction: ({
      type,
      payload,
    }: Extract<InstructionAction, { type: InstructionsTypes.Instruction.VoicemailPrompt }>) => void;
  }) => {
    const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
    const queryClient = useQueryClient();
    const { selectedLocationIds } = useAppScopeStore();
    const locationId = selectedLocationIds[0];

    // This is the source of truth for this component
    const [promptState, promptDispatch] = useReducer(
      promptReducer,
      initialValue ?? ({ voicemailBoxId: options[0].value } as PromptState)
    );
    const mediaId = getInitialMediaId(promptState);

    // These fields will ultimately update promptState with their respective onChange handlers
    const pickerProps = useFormField({ type: 'dropdown', value: mediaId }, [mediaId]);
    const dropdownProps = useFormField({ type: 'dropdown', value: promptState.voicemailBoxId }, [
      promptState.voicemailBoxId,
    ]);

    const onChange = useCallback(
      (e: any) => {
        queryClient.invalidateQueries([locationId, ...queryKeys.phoneMedia()]);
        promptDispatch({
          type: 'mailbox',
          payload: {
            id: e.value,
          },
        });
        dropdownProps.onChange(e);
      },
      [dropdownProps.onChange, promptDispatch]
    );

    const pickerOnChange = useCallback(
      (e: any, selectedMedia?: MediaPickerFileState) => {
        queryClient.invalidateQueries([locationId, ...queryKeys.phoneMedia()]);
        let type: 'greeting' | 'media' | 'skip' = 'skip';

        // type guard to make sure this is a media file
        if (typeof selectedMedia === 'object') {
          type = selectedMedia.isVMGreeting ? 'greeting' : 'media';
        }
        promptDispatch({
          type: 'media',
          payload: {
            id: e.value,
            type,
          },
        });
        pickerProps.onChange(e);
      },
      [pickerProps.onChange, promptDispatch]
    );

    // Sync changes with parent component
    useEffect(() => {
      updateInstruction({
        type: InstructionsTypes.Instruction.VoicemailPrompt,
        payload: promptState,
      });
    }, [promptState]);

    return (
      <>
        <DropdownField
          {...dropdownProps}
          onChange={onChange}
          css={{ minWidth: theme.spacing(38) }}
          label={t('Voicemail Box')}
          name={node.id}
        >
          {options?.map((option) => (
            <DropdownField.Option key={option.value} value={option.value}>
              {option.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
        <MediaPicker
          {...pickerProps}
          value={pickerProps.value}
          locationId={locationId}
          allowNoneOption
          allowDefaultOption
          requestedTypes={{ standard: false, custom: true, mailboxGreeting: true }}
          noneOptionLabel={t('No Greeting')}
          onChange={pickerOnChange}
          /**
           * For initializing the promptState.
           * Allows this component to be updated when the media picker is initialized
           */
          name={node.id}
          label={t('Greeting')}
          mailboxID={promptState.voicemailBoxId}
        />
      </>
    );
  }
);

const SimpleInstruction = ({
  disabled,
  options,
  node,
  value,
  callerName,
  isPhoneTree,
  updateInstruction,
  setIsFormValid,
}: {
  disabled?: boolean;
  node: DropdownCascadingNode;
  options: DropdownCascadingNode[];
  value?: string;
  callerName?: string | undefined;
  isPhoneTree?: boolean;
  updateInstruction: (action: InstructionAction) => void;
  setIsFormValid?: (isValid: boolean) => void;
}) => {
  const { t } = useTranslation('phone');
  const hasCallerContext = node.id === 'CallGroup' || node.id === 'CallQueue' || node.id === 'ForwardDevice';

  const initialValue = value || options?.[0]?.value;

  const { formProps, getFieldProps, validate } = useForm({
    computeChangedValues: true,
    fields: {
      targetId: {
        type: 'dropdown',
        value: initialValue,
      },
      callerName: {
        type: 'text',
        value: callerName ?? '',
        validator: ({ value }) => {
          const validPattern = /^[a-zA-Z0-9-.* ()]+$/;
          if (!validPattern.test(value)) {
            return t(
              'Invalid characters used. Only letters, numbers, hyphens, periods, asterisks, and parentheses are allowed.'
            );
          }
          if (value.length > 25) {
            return t('Input must be 25 characters or fewer.');
          }
          return '';
        },
      },
    },
    fieldStateReducer: (state: any, action: any) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === node.id) {
        updateInstruction({
          type: action.payload.name,
          payload: { targetId: action.payload.value, callerName: state.callerName.value ?? '' },
        });
      } else if (
        action.type === FormFieldActionTypes.Blur &&
        action.payload.name === 'callerName' &&
        (node.id === 'CallGroup' || node.id === 'CallQueue' || node.id === 'ForwardDevice')
      ) {
        updateInstruction({
          type: node.id,
          payload: { targetId: state.targetId.value, callerName: state.callerName.value },
        });
      } else {
        console.error('Invalid instruction type');
      }
      return state;
    },
  });

  const dropdownProps = getFieldProps('targetId');
  const textProps = getFieldProps('callerName');

  useEffect(() => {
    validate();
    if (typeof setIsFormValid === 'function') {
      const isValid = !textProps.error.length;
      setIsFormValid(isValid);
    }
  }, [textProps.value, setIsFormValid]);

  const navigate = useNavigate();
  /**
   * This effect is only to sync the initial default state - when no initial value is passed in.
   * This allows the parent component to have the initial state instead of an empty state
   */
  useEffect(() => {
    if (!value) {
      if (
        node.id !== InstructionsTypes.Instruction.VoicemailPrompt &&
        // node.id !== InstructionsTypes.Instruction.VoicemailBox &&
        node.id !== InstructionsTypes.Instruction.InstructionSet &&
        // This type has not been accounted for
        node.id !== InstructionsTypes.Instruction.Location &&
        // This type seems to be the same as SubTree, also not accounted for
        node.id !== InstructionsTypes.Instruction.PhoneTree &&
        node.id !== InstructionsTypes.Instruction.NotConfigured &&
        node.id !== 'instruction'
      ) {
        updateInstruction({ type: node.id, payload: { targetId: initialValue } });
      }
    }
  }, []);

  // Leaving this in for until we're ready to use it
  // @ts-ignore @typescript-eslint/ban-ts-comment - the allowed unused var setting isn't quite working.
  const _staticAddOption = node.addLink ? (
    <DropdownField.OptionGroup label=''>
      {
        <div
          css={{
            padding: theme.spacing(1, 2),
            fontSize: theme.fontSize(16),
            color: theme.colors.primary50,
            '& :hover': {
              backgroundColor: theme.colors.neutral20,
            },
          }}
          onClick={() => {
            navigate({ to: node.addLink });
          }}
        >
          <TextLink weight='bold'>{t('Add {{item}}', { item: node.displayLabel ?? node.label })}</TextLink>
        </div>
      }
    </DropdownField.OptionGroup>
  ) : null;

  return (
    <form
      {...formProps}
      css={{
        display: 'flex',
        gap: theme.spacing(2),
        ...(hasCallerContext && { height: '80px' }),
      }}
      data-trackingid='phn-portal-instruction-form'
    >
      <DropdownField
        {...dropdownProps}
        disabled={disabled}
        css={{
          width: theme.spacing(30),
        }}
        label={t('Select {{item}}', { item: node.displayLabel ?? node.label })}
        name={node.id}
        data-trackingid='phn-portal-dropdown-select'
      >
        {/* This allows each dropdown to have the option to add an entity. Disabling for now */}
        {/* {staticAddOption} */}
        {options &&
          options?.map((option) => (
            <DropdownField.Option key={option.value} value={option.value} searchValue={option.label}>
              {option.label}
            </DropdownField.Option>
          ))}
      </DropdownField>
      {hasCallerContext && isPhoneTree && (
        <div
          css={{
            width: theme.spacing(40),
          }}
          data-trackingid='phn-portal-callerLabel-div'
        >
          <TextField
            {...textProps}
            helperText={t(
              'Label displays on Weave phones. If added to a fallback option, prior labels will also display.'
            )}
            label={t('Caller Label (Optional)')}
            actionTrackingId='phn-portal-callerLabel-txt-action'
            data-trackingid='phn-portal-callerLabel-txt'
          />
        </div>
      )}
    </form>
  );
};

const getValue = (
  state?: Partial<PhoneTreeTypes.AnyInstructionWithIds>
): { id: string | undefined; callerName?: string } => {
  switch (state?.type) {
    case InstructionsTypes.Instruction.CallGroup: {
      return {
        id: state.callGroup?.callGroupId,
        callerName: state.callGroup?.callerName,
      };
    }
    case InstructionsTypes.Instruction.CallQueue: {
      return {
        id: state.callQueue?.callQueueId,
        callerName: state.callQueue?.callerName,
      };
    }
    case InstructionsTypes.Instruction.DataEndpoint: {
      return { id: state.dataEndpoint?.dataEndpointId };
    }
    case InstructionsTypes.Instruction.DepartmentId: {
      return { id: state.departmentId };
    }
    case InstructionsTypes.Instruction.ForwardDevice: {
      return {
        id: state.forwardDevice?.deviceId,
        callerName: state.forwardDevice?.callerName,
      };
    }
    case InstructionsTypes.Instruction.ForwardNumber: {
      return { id: state.forwardNumber?.forwardingNumberId };
    }
    // case InstructionsTypes.Instruction.Hangup: {
    //   return
    // }
    // case InstructionsTypes.Instruction.InstructionSet: {
    //   return {
    //     ...state,
    //     Type: InstructionsTypes.Instruction.InstructionSet,
    //     [InstructionsTypes.Instruction.InstructionSet]: {
    //       InstructionSetID: payload.instructionSetId,
    //       Instructions: payload.instructions,
    //     },
    //     ConditionId: payload.conditionId,
    //   };
    // }
    case InstructionsTypes.Instruction.IVRMenu: {
      return { id: state.ivrMenu?.phoneTreeId };
    }
    case InstructionsTypes.Instruction.Play: {
      return { id: state.play?.mediaFileId };
      // return {
      //   ...state,
      //   Type: InstructionsTypes.Instruction.Play,
      //   [InstructionsTypes.Instruction.Play]: {
      //     MediaFileID: payload.targetId,
      //   },
      // };
    }
    case InstructionsTypes.Instruction.VoicemailBox: {
      return { id: state.voicemailBox?.voicemailBoxId };
      // return {
      //   ...state,
      //   Type: InstructionsTypes.Instruction.VoicemailBox,
      //   [InstructionsTypes.Instruction.VoicemailBox]: {
      //     VoicemailBoxID: payload.targetId,
      //   },
      // };
    }
    case InstructionsTypes.Instruction.VoicemailPrompt: {
      return { id: state.voicemailPrompt?.voicemailBoxId };
    }
    default: {
      return { id: undefined };
    }
  }
};

enum InstructionPickerStaticOption {
  NOT_CONFIGURED = InstructionsTypes.Instruction.NotConfigured,
}

export const InstructionPicker = ({
  index,
  disabled,
  options = [],
  node,
  setNext,
  value,
  onChange,
  onRemove,
  helperText,
  showNotConfiguredOption = true,
}: RenderProps<InstructionDropdownCascadingNode> & {
  index: number;
  disabled?: boolean;
  showNotConfiguredOption?: boolean;
  helperText?: string;
  onChange: (
    value: InstructionsTypes.Instruction | PhoneTreeTypes.MenuTypes.SubTree | PhoneTreeTypes.MenuTypes.Navigate
  ) => void;
  options?: InstructionDropdownCascadingNode[];
  value?: InstructionsTypes.Instruction | PhoneTreeTypes.MenuTypes.SubTree | PhoneTreeTypes.MenuTypes.Navigate;
  onRemove: () => void;
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'phone-tree' });
  const initialValue = value ?? options?.[0]?.value;
  const dropdownProps = useFormField({ type: 'dropdown', value: initialValue }, [initialValue]);
  /**
   * This effect is to sync the initial state and set up the next node
   */
  useEffect(() => {
    // Set the next node to either the default (first) option or the one pass down
    if (!value) {
      setNext(options[0]);
    } else {
      const nextChild = options.find((opt) => opt.value === value);
      if (nextChild) {
        setNext(nextChild);
      }
    }

    /**
     * Sync value back to parent if there wasn't already an initial value.
     * This node should always be an 'instruction' node
     */
    if (!value) {
      onChange(initialValue);
    }
  }, []);

  const onOptionChange = useCallback(
    (e: any) => {
      if (e.value === InstructionPickerStaticOption.NOT_CONFIGURED) {
        onRemove();
      }

      onChange(e.value);
      if (!e) {
        return;
      }
      dropdownProps.onChange(e);
      const next = options?.find((node) => node.value === e.value);
      if (next) {
        setNext(next);
      }
    },
    [node, options]
  );

  const filteredOptions = options
    ? options
        .sort((a, b) => (!a.label || !b.label ? 0 : a.label.localeCompare(b.label)))
        .filter((option) => {
          // We only want the Department or Navigate option showing if it is the first option
          if (
            index > 0 &&
            (option.value === InstructionsTypes.Instruction.DepartmentId ||
              option.value === PhoneTreeTypes.MenuTypes.Navigate)
          ) {
            return false;
          }

          /**
           * We do not want to show options marked as disabled, unless they are already the current selected option
           */
          return !option.disabled || option.id === dropdownProps.value;
        })
    : [];

  return (
    <DropdownField
      {...dropdownProps}
      disabled={disabled}
      containerCss={css`
        width: ${theme.spacing(38)};
      `}
      onChange={onOptionChange}
      css={{ minWidth: 200 }}
      label={node.label}
      helperText={helperText}
      name={node.id}
    >
      <>
        {showNotConfiguredOption && (
          <DropdownField.Option
            key={InstructionPickerStaticOption.NOT_CONFIGURED}
            value={InstructionPickerStaticOption.NOT_CONFIGURED.toString()}
          >
            {t('Not Configured')}
          </DropdownField.Option>
        )}
      </>
      {filteredOptions.map((option) => (
        <DropdownField.Option key={option.value} value={option.value}>
          {option.label}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
};

function isVoicemailPromptGreeting(
  voicemailPrompt: PhoneTreeTypes.VoicemailPrompt['voicemailPrompt']
): voicemailPrompt is PhoneTreeTypes.VoicemailPromptGreeting {
  return 'voicemailGreetingId' in voicemailPrompt;
}

function isVoicemailPromptMedia(
  voicemailPrompt: PhoneTreeTypes.VoicemailPrompt['voicemailPrompt']
): voicemailPrompt is PhoneTreeTypes.VoicemailPromptMedia {
  return 'systemMediaId' in voicemailPrompt;
}

export const PhoneTreeSubTreeSwitch = memo(
  ({
    node,
    state,
    onChange,
  }: RenderProps<DropdownCascadingNode> & {
    state: Partial<PhoneTreeTypes.PhoneTreeModel>;
    onChange: (value: Partial<PhoneTreeTypes.PhoneTreeModel>) => void;
  }) => {
    const query = useLocalizedQuery({
      ...node.queryParams,
      enabled: !!node.queryParams,
    });
    const options = query.data as DropdownCascadingNode[];

    const onValueChange = (action: Extract<InstructionAction, { type: PhoneTreeTypes.MenuTypes.SubTree }>) => {
      const {
        payload: { targetId },
      } = action;
      onChange({ ...state, ivrMenuId: targetId, name: options.find((option) => option.value === targetId)?.label });
    };

    if (query.isLoading) {
      return <SpinningLoader size='small' />;
    }

    return (
      <SimpleInstruction
        disabled={true}
        node={node}
        options={options}
        value={state?.ivrMenuId}
        // @ts-ignore @typescript-eslint/ban-ts-comment - these types should be compatible, but I'm not sure why they're not
        updateInstruction={onValueChange}
        key={node.id}
      />
    );
  }
);

export const PhoneTreeDepartmentSwitch = memo(
  ({
    node,
    state,
    onChange,
  }: RenderProps<DropdownCascadingNode> & {
    state: Partial<PhoneTreeTypes.Department>;
    onChange: (value: Partial<PhoneTreeTypes.Department>) => void;
  }) => {
    const query = useLocalizedQuery({
      ...node.queryParams,
      enabled: !!node.queryParams,
    });
    const options = query.data as DropdownCascadingNode[];

    const onValueChange = (
      action: Extract<InstructionAction, { type: InstructionsTypes.Instruction.DepartmentId }>
    ) => {
      const {
        payload: { targetId },
      } = action;
      onChange({ ...state, departmentId: targetId });
    };

    if (query.isLoading) {
      return <SpinningLoader size='small' />;
    }

    return (
      <SimpleInstruction
        node={node}
        options={options}
        value={state?.departmentId}
        // @ts-ignore @typescript-eslint/ban-ts-comment - these types should be compatible, but I'm not sure why they're not
        updateInstruction={onValueChange}
        key={node.id}
      />
    );
  }
);

export const PhoneTreeInstructionSwitch = memo(
  ({
    node,
    state,
    isPhoneTree,
    updateInstruction,
    setIsFormValid,
  }: RenderProps<DropdownCascadingNode> & {
    state: Partial<PhoneTreeTypes.AnyInstructionWithIds>;
    isPhoneTree?: boolean;
    updateInstruction: (payload: InstructionAction) => void;
    setIsFormValid?: (isValid: boolean) => void;
  }) => {
    // If a node has queryParams, use them here and enable the query
    const query = useLocalizedQuery({
      ...node.queryParams,
      enabled: !!node.queryParams?.queryFn,
    });
    let options: DropdownCascadingNode[] | undefined;

    if (query.data) {
      options = query.data as DropdownCascadingNode[];
    } else if (node.createNodes) {
      options = node.createNodes();
    } else {
      options = node.nodes;
    }

    if (query.isLoading) {
      return <SpinningLoader size='small' />;
    }

    if (node.id === InstructionsTypes.Instruction.Play && state.type === InstructionsTypes.Instruction.Play) {
      const mediaValue = state.play?.mediaFileId;
      return (
        <PlayInstruction key={node.id} node={node} updateInstruction={updateInstruction} mediaValue={mediaValue} />
      );
    }

    if (
      node.id === InstructionsTypes.Instruction.ForwardNumber &&
      state.type === InstructionsTypes.Instruction.ForwardNumber
    ) {
      const forwardNumberId = state.forwardNumber?.forwardingNumberId;
      return (
        <ForwardingNumberInstruction
          key={node.id}
          node={node}
          updateInstruction={updateInstruction}
          forwardingNumId={forwardNumberId}
        />
      );
    }
    /**
     * If there are no options to display, do not render anything.
     * Only the `Play` instruction above does not rely on `options`.
     */
    if (!options) {
      return null;
    }

    if (
      node.id === InstructionsTypes.Instruction.VoicemailPrompt &&
      state.type === InstructionsTypes.Instruction.VoicemailPrompt
    ) {
      return (
        <VoicemailPromptInstruction
          key={node.id}
          updateInstruction={updateInstruction}
          node={node}
          options={options}
          initialValue={state.voicemailPrompt}
        />
      );
    }

    const value = getValue(state).id;
    const callerName = getValue(state).callerName;

    return (
      <SimpleInstruction
        disabled={node.id === InstructionsTypes.Instruction.VoicemailBox}
        node={node}
        options={options}
        value={value}
        callerName={callerName}
        key={node.id}
        updateInstruction={updateInstruction}
        setIsFormValid={setIsFormValid}
        isPhoneTree={isPhoneTree}
      />
    );
  }
);
