import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  Plan,
  OnederfulPayerIDResponse,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import {
  ErrorDetails,
  DentalCoverageResponse,
  DentalCoverageRequest,
} from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v2/insurance_verification_adapter.pb';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import {
  SchemaInsuranceVerificationAdapterServiceV1,
  SchemaInsuranceVerificationAdapterServiceV2,
} from '@frontend/schema';
import { theme } from '@frontend/theme';
import {
  Combobox,
  ContentLoader,
  DatePickerField,
  DropdownField,
  DropdownSearchField,
  FormRow,
  Heading,
  TextField,
  Tray,
  useAlert,
} from '@frontend/design-system';
import {
  MONTH_INFO,
  OTHER_SUBSCRIBER_SELECTION_VALUE,
  RELATIONSHIP_LIST,
  RelationshipEnum,
  VerificationError,
} from '../../constants';
import {
  getPayerIdAndPayerNameFromCombinedId,
  getRelationShipValue,
  removeNonAlphaNumericCharacters,
} from '../../helpers';
import { useInsuranceForm } from '../../hooks/use-insurance-form';
import { usePersonInfoStore } from '../../providers';
import { InsuranceDetails } from '../../types';
import { ErrorBanner } from '../error-banner';
import { PlanSearchInput } from './plan-search-input';
import { VerifyInsuranceSuccessSection } from './verify-insurance-success-section';

interface VerifyInsuranceSidePanelBodyProps {
  onClose: () => void;
  onReportLearnMoreClick: () => void;
}

export const VerifyInsuranceForm = ({ onClose, onReportLearnMoreClick }: VerifyInsuranceSidePanelBodyProps) => {
  const { t } = useTranslation('insuranceDetails');
  const alert = useAlert();
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID ?? '';

  const [errorDetails, setErrorDetails] = useState<ErrorDetails>();
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [successPageInsuranceDetails, setSuccessPageInsuranceDetails] = useState<Partial<InsuranceDetails>>();
  const { isLoading, form, payerList, nationalProviderIdList, primSubscriberList } = useInsuranceForm();
  const { invalidateGetProviderListQuery, invalidateGetInsuranceVerificationMetrics } =
    InsuranceDetailQueries.useInvalidateQueries();
  const { invalidateGetInsuranceByPersonIdQuery, invalidateGetInsuranceVerificationDetailsPersonIdQuery } =
    InsuranceDetailQueries.useInvalidateQueries();

  const alphabeticallySortedPayerList = payerList.sort((a, b) => a.label.localeCompare(b.label));

  const { mutate: updateInsuranceDetails, isLoading: isUpdating } = useMutation({
    mutationFn: (data: DentalCoverageRequest) =>
      SchemaInsuranceVerificationAdapterServiceV2.GetDentalCoverageInfo(data),
    onSuccess: (res: DentalCoverageResponse) => {
      const { payerId, payerName } = getPayerIdAndPayerNameFromCombinedId(form.values.payerId || '');
      if (res.coverage?.status) {
        const primarySubscriberValue = form.values.primarySubscriber;
        const subscriberId =
          !!primarySubscriberValue && primarySubscriberValue !== OTHER_SUBSCRIBER_SELECTION_VALUE
            ? primarySubscriberValue
            : '';

        setSuccessPageInsuranceDetails({
          payerId: payerId,
          payerName: payerName,
          groupName: form.values.groupName,
          groupNumber: form.values.groupNumber,
          firstName: form.values.patientFirstName,
          lastName: form.values.patientLastName,
          dateOfBirth: form.values.patientBirthDate,
          memberId: form.values.patientMemberId,
          nationalProviderId: form.values.nationalProviderId,
          providerTaxId: form.values.taxId,
          relationship: form.values.relationship as RelationshipEnum,
          subscriberId,
          subscriberFirstName: form.values.subscriberFirstName,
          subscriberLastName: form.values.subscriberLastName,
          subscriberDateOfBirth: form.values.subscriberBirthDate,
          subscriberMemberId: form.values.subscriberMemberId,
        });
        setShowSuccessPage(true);
        alert.success(t('Insurance Verified!'));

        const isNpiInProviderList =
          form.values.nationalProviderId && nationalProviderIdList.includes(form.values.nationalProviderId);

        if (isNpiInProviderList) {
          invalidateGetProviderListQuery(locationId!);
        }
        invalidateGetInsuranceVerificationMetrics(MONTH_INFO.startDate, MONTH_INFO.endDate, locationId!);
        invalidateGetInsuranceByPersonIdQuery(personId, locationId!);
        invalidateGetInsuranceVerificationDetailsPersonIdQuery(personId, locationId!);
        return;
      }

      // handle error case
      setErrorDetails(res.errorDetails);
    },
    onError: () => {
      alert.error(t('Something went wrong while verifying insurance. Please try again.'));
    },
  });

  const handleSelectPlan = (plan: Plan) => {
    if (plan.cNam || plan.paID) {
      SchemaInsuranceVerificationAdapterServiceV1.GetOnederfulPayerID({
        electronicPayerId: plan.paID,
        payerName: plan.cNam,
      }).then((res: OnederfulPayerIDResponse) => {
        const isOnederfulPayerIdPresent =
          !!res.onederfulPayerId && res.onederfulPayerId !== VerificationError.WEAVE_PAYER_NOT_SUPPORTED;
        if (isOnederfulPayerIdPresent) {
          form.seedValues({
            payerId: res.onederfulPayerId ?? '',
            groupNumber: removeNonAlphaNumericCharacters(plan.gNum) ?? '',
            groupName: plan.gNam ?? '',
          });
        } else {
          form.getFieldProps('payerId').error = t('Payer Not supported');
          form.seedValues({
            groupNumber: removeNonAlphaNumericCharacters(plan.gNum) ?? '',
            groupName: plan.gNam ?? '',
          });
          alert.error(t('Payer Not Supported for selected plan!'));
        }
      });
    } else {
      form.seedValues({
        groupNumber: removeNonAlphaNumericCharacters(plan.gNum) ?? '',
        groupName: plan.gNam ?? '',
      });
    }
  };

  const handleSaveClick = () => {
    form.validate();

    if (form.isComplete) {
      const isDependent = form.values.relationship !== RelationshipEnum.Self;

      const { payerId, payerName } = getPayerIdAndPayerNameFromCombinedId(form.values.payerId || '');

      updateInsuranceDetails({
        personId,
        payer: {
          id: payerId,
          name: payerName,
        },
        provider: {
          nationalProviderId: form.values.nationalProviderId,
          taxId: form.values.taxId,
        },
        relationship: getRelationShipValue(form.values.relationship as RelationshipEnum),
        patient: {
          isDependent,
          firstName: form.values.patientFirstName,
          lastName: form.values.patientLastName,
          dateOfBirth: form.values.patientBirthDate,
          memberId: form.values.patientMemberId,
          groupNumber: form.values.groupNumber,
        },
        ...(isDependent && {
          subscriber: {
            firstName: form.values.subscriberFirstName,
            lastName: form.values.subscriberLastName,
            dateOfBirth: form.values.subscriberBirthDate,
            memberId: form.values.subscriberMemberId,
          },
        }),
      });
    }
  };

  const shouldRenderSubscriberInfo = useMemo(() => {
    const isRelationshipSelf = !!form.values.relationship && form.values.relationship === RelationshipEnum.Self;
    const hasMultipleSubscribers = primSubscriberList.length > 1;
    const hasNoSubscribers = primSubscriberList.length === 0;

    // if the relationship is not self and we have multiple subscribers, we should render subscriber info only if the primary subscriber is selected
    if (hasMultipleSubscribers && !isRelationshipSelf) {
      return !!form.values.primarySubscriber;
    }

    // if the relationship is not self and we have no subscribers, we should render subscriber info to allow the user to add subscriber info manually without selecting the primary subscriber
    if (form?.values?.relationship && !isRelationshipSelf && hasNoSubscribers) {
      return true;
    }

    return false;
  }, [form.values.relationship, primSubscriberList.length, form.values.primarySubscriber]);

  if (showSuccessPage && successPageInsuranceDetails) {
    return (
      <VerifyInsuranceSuccessSection
        insuranceDetails={successPageInsuranceDetails}
        onClose={onClose}
        onReportLearnMoreClick={onReportLearnMoreClick}
      />
    );
  }

  return (
    <>
      <Tray.Body>
        <ContentLoader show={isLoading || isUpdating} />
        <ErrorBanner errorDetails={errorDetails} />
        <PlanSearchInput
          locationId={locationId ?? ''}
          sourceId={personInfo?.SourceID ?? ''}
          onPlanSelect={handleSelectPlan}
        />
        <form {...form.formProps} autoComplete='off'>
          <Heading level={2}>{t('Patient Information')}</Heading>
          <hr css={hrStyle} />
          <FormRow>
            <DropdownSearchField
              {...form.getFieldProps('payerId')}
              placeholder={t('Select Insurance Payer')}
              label={t('*Company / Payer Name')}
              searchFieldPlaceholder={t('Search Payer')}
            >
              {alphabeticallySortedPayerList.map((payer) => (
                <DropdownSearchField.Option
                  searchValue={payer.label}
                  key={payer.value + payer.label}
                  value={payer.uniqueCombinedId ?? payer.value}
                >
                  {payer.label}
                </DropdownSearchField.Option>
              ))}
            </DropdownSearchField>
          </FormRow>

          <FormRow cols={[50, 50]}>
            <TextField {...form.getFieldProps('groupName')} label={t('Group Name')} />
            <TextField {...form.getFieldProps('groupNumber')} label={t('Group Number')} />
          </FormRow>

          <FormRow>
            <TextField {...form.getFieldProps('patientMemberId')} label={t('*Member ID')} />
          </FormRow>
          <FormRow>
            <TextField {...form.getFieldProps('patientFirstName')} label={t('*Patient First Name')} />
          </FormRow>
          <FormRow>
            <TextField {...form.getFieldProps('patientLastName')} label={t('*Patient Last Name')} />
          </FormRow>
          <FormRow>
            <DatePickerField
              {...form.getFieldProps('patientBirthDate')}
              label={t('*Patient Date of Birth')}
              css={datePickerStyle}
            />
          </FormRow>
          <Heading level={2}>{t('Provider Information')}</Heading>
          <hr css={hrStyle} />
          <FormRow cols={[50, 50]}>
            <Combobox
              options={nationalProviderIdList}
              name='nationalProviderId'
              label={t('*NPI Selector')}
              fieldProps={form.getFieldProps('nationalProviderId')}
            />
            <TextField {...form.getFieldProps('taxId')} label={t('*Tax ID')} />
          </FormRow>
          <Heading level={2}>{t('Relationship to Subscriber')}</Heading>
          <hr css={hrStyle} />
          <FormRow cols={[50, 50]}>
            <DropdownField
              {...form.getFieldProps('relationship')}
              label={t('*Relationship')}
              placeholder={t('Select Relationship')}
            >
              {RELATIONSHIP_LIST.map(({ label, value }) => (
                <DropdownField.Option value={value} key={value}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>

            {!!primSubscriberList.length &&
              primSubscriberList.length > 1 &&
              form.values.relationship &&
              form.values.relationship !== RelationshipEnum.Self && (
                <DropdownField {...form.getFieldProps('primarySubscriber')} label={t('Subscriber')}>
                  {primSubscriberList.map(({ label, value }) => (
                    <DropdownField.Option key={value} value={value}>
                      {label}
                    </DropdownField.Option>
                  ))}
                </DropdownField>
              )}
          </FormRow>
          {shouldRenderSubscriberInfo && (
            <>
              <Heading level={2}>{t('Subscriber Information')}</Heading>
              <hr css={hrStyle} />
              <FormRow>
                <TextField {...form.getFieldProps('subscriberFirstName')} label={t('*Subscriber First Name')} />
              </FormRow>
              <FormRow>
                <TextField {...form.getFieldProps('subscriberLastName')} label={t('*Subscriber Last Name')} />
              </FormRow>
              <FormRow cols={[50, 50]}>
                <DatePickerField
                  {...form.getFieldProps('subscriberBirthDate')}
                  label={t('*Date of Birth')}
                  css={datePickerStyle}
                />
                <TextField {...form.getFieldProps('subscriberMemberId')} label={t('*Member ID')} />
              </FormRow>
            </>
          )}
        </form>
      </Tray.Body>
      <Tray.Actions
        disabledPrimary={isLoading || isUpdating || !form.isComplete}
        onSecondaryClick={onClose}
        primaryLabel={t('Verify')}
        onPrimaryClick={handleSaveClick}
      />
    </>
  );
};

const hrStyle = css({
  border: 'none',
  borderTop: `1px solid ${theme.colors.neutral20}`,
  marginBottom: theme.spacing(3),
});

const datePickerStyle = css({
  maxWidth: '100%',
});
