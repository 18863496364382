import { HTMLMotionProps, motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { UseThreadItemHighlightProps, useThreadItemHighlight } from '../../hooks';

type BackgroundHighlightProps = Partial<UseThreadItemHighlightProps> & HTMLMotionProps<'div'>;

/**
 * Renders a component that will be highlighted in yellow whenever it appears on screen.
 * Useful for highlighting a particular thread item that was targeted when opening a thread.
 */
export const BackgroundHighlight = ({
  disabled,
  additionalCSS,
  repeatWheneverVisible,
  initial,
  animate,
  exit,
  ...rest
}: BackgroundHighlightProps) => {
  const { ref, styles } = useThreadItemHighlight<HTMLDivElement>({
    disabled,
    additionalCSS,
    repeatWheneverVisible,
  });

  return (
    <motion.div
      ref={ref}
      initial={
        typeof initial === 'object'
          ? {
              backgroundColor: 'transparent',
              ...initial,
            }
          : initial
      }
      animate={
        typeof animate === 'object'
          ? {
              backgroundColor: theme.colors.warning5,
              ...animate,
            }
          : animate
      }
      exit={
        typeof exit === 'object'
          ? {
              backgroundColor: 'transparent',
            }
          : exit
      }
      css={[
        {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        styles,
      ]}
      {...rest}
    />
  );
};
