import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { QuickFillHooks } from '@frontend/api-quick-fill';
import {
  CallIntelRedirectWidget,
  CancelledPatientsWidget,
  HygieneFollowUpsWidget,
  PhonesSnapshotWidget,
  TopAppointmnetTypesWidget,
  CallResultsWidget,
  OpportunityListWidget,
  WeaveConversionWidget,
  MissedCallTextROIWidget,
  useHasMCTRoiFeature,
  AnalyticsFeatureFlags,
} from '@frontend/analytics';
import { BulkEmailWidget, BulkTextingWidget } from '@frontend/bulk-messaging';
import { UnreadVoicemailsInfo } from '@frontend/calls-v2';
import { AddFaxWidget } from '@frontend/fax';
import { FeatureAccessFlags, FeatureAccessPermissions, useFeatureAccess } from '@frontend/feature-access';
import {
  FormsSentComprehensiveWidget,
  FormsSentWidget,
  FormsSubmissionComprehensive,
  FormsSubmissionWidget,
} from '@frontend/forms';
import type { DashboardWidgetData } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { WIVSetupWidget } from '@frontend/insurance-details';
import { AddMessageWidget } from '@frontend/messages';
import { TrainingDashboardWidget } from '@frontend/onboarding-modules';
import { AddInvoiceWidget, PaymentsDashboardWidget, PaymentsSummaryWidget } from '@frontend/payments';
import { useMerchantsInfo } from '@frontend/payments-hooks';
import { ConnectionStatusWidget } from '@frontend/phone';
import { PortRequestsWidgets } from '@frontend/porting';
import { QuickActionsWidget } from '@frontend/quick-actions';
import {
  AddQuickFillWidget,
  AppointmentCountWidget,
  BookedHoursWidget,
  ScheduleRequestsWidget,
  ScheduleSetupWidget,
  UnbookedHoursWidget,
  AppointmentsWidget,
} from '@frontend/schedule';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import {
  StickyNoteWidgetYellow,
  StickyNoteWidgetOrange,
  StickyNoteWidgetGreen,
  StickyNoteWidgetBlue,
  StickyNoteWidgetPink,
  StickyNoteWidgetPurple,
} from '@frontend/sticky-note-widget';
import { AllAppsWidget } from './all-users-widget';

export function useWidgets() {
  const { t } = useTranslation('dashboard');
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();

  const { hasAccess: hasBulkEmailAccess } = useFeatureAccess(
    FeatureAccessFlags.emailMarketing,
    FeatureAccessPermissions.emailMarketing
  );
  const { hasAccess: hasBulkTextingAccess } = useFeatureAccess(
    FeatureAccessFlags.bulkTexting,
    FeatureAccessPermissions.bulkTexting
  );

  const { isLoading: isLoadingPhoneFeature, isFeatureHiddenInAllLocations: isPhoneFeatureHiddenInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: !!selectedLocationIds.length,
      customizationFlag: Feature.PHONE_HOME_ICON,
    });

  const {
    isLoading: isLoadingBulkMessagingCF,
    isFeatureHiddenInAllLocations: isBulkMessagingFeatureHiddenInAllLocations,
  } = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: selectedLocationIds,
    enabled: !!selectedLocationIds.length,
    customizationFlag: Feature.BULK_MESSAGING,
  });

  const hasBulkMessagingFeature = isLoadingBulkMessagingCF ? false : !isBulkMessagingFeatureHiddenInAllLocations;
  const hasPhoneFeature = !isLoadingPhoneFeature && !isPhoneFeatureHiddenInAllLocations;

  const { anyLocationHasPayments } = useMerchantsInfo();
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();
  const isAppointmentsRemindersRoiEnabled = getFeatureFlagValue(AnalyticsFeatureFlags.appointmentRemindersRoi);

  const { hasQuickFillFeature } = QuickFillHooks.useQuickFillFeatureDetails();

  const widgetList = useMemo(
    () =>
      [
        {
          id: 'add-fax' /* AUTO-GENERATED */,
          title: t('New Fax Message') /* AUTO-GENERATED */,
          description: t('Initiate a new fax with a designated Fax Contact.') /* AUTO-GENERATED */,
          icon: 'fax-small' /* AUTO-GENERATED */,
          component: AddFaxWidget /* AUTO-GENERATED */,
        },
        {
          id: 'classic-view' /* AUTO-GENERATED */,
          title: t('Simple View') /* AUTO-GENERATED */,
          description: t('This is the simple view') /* AUTO-GENERATED */,
          icon: 'computer-small' /* AUTO-GENERATED */,
          component: AllAppsWidget /* AUTO-GENERATED */,
          hasAccess: false,
        },
        {
          id: 'add-message' /* AUTO-GENERATED */,
          title: t('New Text') /* AUTO-GENERATED */,
          description: t('Create and send a new message with just one click.') /* AUTO-GENERATED */,
          icon: 'message-small' /* AUTO-GENERATED */,
          component: AddMessageWidget /* AUTO-GENERATED */,
        },
        {
          // TODO: remove this once API integration is complete
          hasAccess: false,
          id: 'booked-hours-widget' /* AUTO-GENERATED */,
          title: t('Booked Hours') /* AUTO-GENERATED */,
          description: t(
            'Displays the total booked hours of the day, providing insights into daily scheduling and resource utilization.'
          ) /* AUTO-GENERATED */,
          icon: 'calendar-small' /* AUTO-GENERATED */,
          component: BookedHoursWidget /* AUTO-GENERATED */,
        },
        {
          // TODO: remove this once API integration is complete
          hasAccess: false,
          id: 'unbooked-hours-widget' /* AUTO-GENERATED */,
          title: t('Unbooked Hours') /* AUTO-GENERATED */,
          description: t(
            'Highlights the number of hours of the day not booked, offering insights into available scheduling opportunities.'
          ) /* AUTO-GENERATED */,
          icon: 'calendar-small' /* AUTO-GENERATED */,
          component: UnbookedHoursWidget /* AUTO-GENERATED */,
        },
        {
          id: 'appointment-count' /* AUTO-GENERATED */,
          title: t('Appointments') /* AUTO-GENERATED */,
          description: t('Show number of appointments scheduled for the day.') /* AUTO-GENERATED */,
          icon: 'calendar-small' /* AUTO-GENERATED */,
          component: AppointmentCountWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: hasPhoneFeature,
          id: 'call-results-widget' /* AUTO-GENERATED */,
          title: t('Call Results') /* AUTO-GENERATED */,
          description: t(
            'Highlights incoming calls, showing whether they were answered, missed, or abandoned.'
          ) /* AUTO-GENERATED */,
          icon: 'phone-small' /* AUTO-GENERATED */,
          component: CallResultsWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: hasPhoneFeature,
          id: 'phones-snapshot-widget' /* AUTO-GENERATED */,
          title: t('Phones Snapshot') /* AUTO-GENERATED */,
          description: t(
            'View daily average response time, total calls placed, and total calls received.'
          ) /* AUTO-GENERATED */,
          icon: 'phone-small' /* AUTO-GENERATED */,
          component: PhonesSnapshotWidget /* AUTO-GENERATED */,
        },
        {
          id: 'opportunity-list-widget' /* AUTO-GENERATED */,
          title: t('Opportunity List') /* AUTO-GENERATED */,
          description: t(
            'Enhance patient care with a widget displaying hygiene and restorative treatment types, listing patients in each category with a total count.'
          ) /* AUTO-GENERATED */,
          icon: 'contacts-small' /* AUTO-GENERATED */,
          component: OpportunityListWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: anyLocationHasPayments,
          id: 'payments-summary' /* AUTO-GENERATED */,
          title: t('Payments Summary') /* AUTO-GENERATED */,
          description: t(
            'Provides net total, fees, refunds, and paid/outstanding invoices, offering a comprehensive transaction snapshot.'
          ) /* AUTO-GENERATED */,
          icon: 'payments-small' /* AUTO-GENERATED */,
          component: PaymentsSummaryWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: anyLocationHasPayments,
          id: 'payments-24-hours' /* AUTO-GENERATED */,
          title: t('Payments Made in the Last 24 Hours') /* AUTO-GENERATED */,
          description: t(
            'See all payments made in the last 24 hours to mark them as recorded in your PMS.'
          ) /* AUTO-GENERATED */,
          icon: 'payments-small' /* AUTO-GENERATED */,
          component: PaymentsDashboardWidget /* AUTO-GENERATED */,
        },
        {
          id: 'quick-actions' /* AUTO-GENERATED */,
          title: t('Quick Actions') /* AUTO-GENERATED */,
          description: t('Program all your most-used actions into buttons for easy access.') /* AUTO-GENERATED */,
          icon: 'action-bar' /* AUTO-GENERATED */,
          component: QuickActionsWidget /* AUTO-GENERATED */,
        },
        {
          id: 'training-articles' /* AUTO-GENERATED */,
          title: t('Training') /* AUTO-GENERATED */,
          description: t("See helpful resources as you learn all about Weave's app.") /* AUTO-GENERATED */,
          icon: 'graduation-cap-small' /* AUTO-GENERATED */,
          component: TrainingDashboardWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: hasQuickFillFeature,
          id: 'add-quick-fill' /* AUTO-GENERATED */,
          title: t('Add to Quick Fill') /* AUTO-GENERATED */,
          description: t(
            'Add patients to your Quick Fill list to schedule last-minute appointments.'
          ) /* AUTO-GENERATED */,
          icon: 'quickfill-small' /* AUTO-GENERATED */,
          component: AddQuickFillWidget /* AUTO-GENERATED */,
        },
        {
          id: 'appointments-widget' /* AUTO-GENERATED */,
          title: t('Confirmation Status') /* AUTO-GENERATED */,
          description: t(
            'Easily manage your schedule with a list view that counts appointments and toggles confirmed statuses.'
          ) /* AUTO-GENERATED */,
          icon: 'calendar-confirmed-small' /* AUTO-GENERATED */,
          component: AppointmentsWidget /* AUTO-GENERATED */,
        },
        {
          id: 'cancelled-patients-widget' /* AUTO-GENERATED */,
          title: t('Cancelled Patients') /* AUTO-GENERATED */,
          description: t(
            'Displays the number of cancelled patients, providing insights into appointment cancellations and trends.'
          ) /* AUTO-GENERATED */,
          icon: 'contacts-small' /* AUTO-GENERATED */,
          component: CancelledPatientsWidget /* AUTO-GENERATED */,
        },
        {
          id: 'hygiene-follow-ups-widget' /* AUTO-GENERATED */,
          title: t('Hygiene Follow-ups') /* AUTO-GENERATED */,
          description: t(
            'Shows the number of hygiene follow-ups, offering insights into patient compliance and care.'
          ) /* AUTO-GENERATED */,
          icon: 'contacts-small' /* AUTO-GENERATED */,
          component: HygieneFollowUpsWidget /* AUTO-GENERATED */,
        },
        {
          // TODO: remove this once API integration is complete
          hasAccess: false,
          id: 'top-appointment-types-widget' /* AUTO-GENERATED */,
          title: t('Top Appointment Types') /* AUTO-GENERATED */,
          description: t(
            'Track the top 3 appointment types your customers are booking with a summary widget.'
          ) /* AUTO-GENERATED */,
          icon: 'calendar-small' /* AUTO-GENERATED */,
          component: TopAppointmnetTypesWidget /* AUTO-GENERATED */,
        },
        {
          // TODO: remove this once API integration is complete
          hasAccess: false,
          id: 'schedule-requests-widget' /* AUTO-GENERATED */,
          title: t('Schedule Requests') /* AUTO-GENERATED */,
          description: t(
            'Effortlessly manage patient requests with a list view of all requested appointments.'
          ) /* AUTO-GENERATED */,
          icon: 'calendar-small' /* AUTO-GENERATED */,
          component: ScheduleRequestsWidget /* AUTO-GENERATED */,
        },
        {
          // TODO: remove this once API integration is complete
          hasAccess: false,
          id: 'connection-status-widget' /* AUTO-GENERATED */,
          title: t('Connection Status') /* AUTO-GENERATED */,
          description: t('Ensure your devices and connections are active and operational.') /* AUTO-GENERATED */,
          icon: 'wifi-small' /* AUTO-GENERATED */,
          component: ConnectionStatusWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: hasBulkEmailAccess,
          plgConfig: {
            id: 'bulk-messaging',
            hasFeature: hasBulkMessagingFeature,
          },
          id: 'bulk-email-widget' /* AUTO-GENERATED */,
          title: t('Bulk Email') /* AUTO-GENERATED */,
          description: t(
            'Track your email marketing allotment with a real-time progress bar and remaining message count display.'
          ) /* AUTO-GENERATED */,
          icon: 'email-small' /* AUTO-GENERATED */,
          component: BulkEmailWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: hasBulkTextingAccess,
          plgConfig: {
            id: 'bulk-messaging',
            hasFeature: hasBulkMessagingFeature,
          },
          id: 'bulk-texting-widget' /* AUTO-GENERATED */,
          title: t('Bulk Texting') /* AUTO-GENERATED */,
          description: t(
            'Monitor your text allotment with a real-time progress bar and remaining message count display.'
          ) /* AUTO-GENERATED */,
          icon: 'cell-phone-small' /* AUTO-GENERATED */,
          component: BulkTextingWidget /* AUTO-GENERATED */,
        },
        {
          // TODO: this widget should be part of fixed widget. and that is yet to be finalized
          hasAccess: false,
          id: 'port-requests-widget' /* AUTO-GENERATED */,
          title: t('Port Requests') /* AUTO-GENERATED */,
          description: t('Displays the number of accepted and pending port requests.') /* AUTO-GENERATED */,
          icon: 'porting-status-small' /* AUTO-GENERATED */,
          component: PortRequestsWidgets /* AUTO-GENERATED */,
        },
        {
          id: 'add-invoice' /* AUTO-GENERATED */,
          title: t('New Invoice') /* AUTO-GENERATED */,
          description: t('Quickly create a new invoice and collect payments.') /* AUTO-GENERATED */,
          icon: 'payments' /* AUTO-GENERATED */,
          component: AddInvoiceWidget /* AUTO-GENERATED */,
        },
        {
          id: 'forms-submission' /* AUTO-GENERATED */,
          title: t('Forms') /* AUTO-GENERATED */,
          description: t(
            'Displays forms submitted throughout the day and a graph indicating their submission time.'
          ) /* AUTO-GENERATED */,
          icon: 'forms-small' /* AUTO-GENERATED */,
          component: FormsSubmissionWidget /* AUTO-GENERATED */,
        },
        {
          id: 'forms-sent' /* AUTO-GENERATED */,
          title: t('Forms') /* AUTO-GENERATED */,
          description: t(
            'Shows forms sent throughout the day and a graph indicating their delivery times.'
          ) /* AUTO-GENERATED */,
          icon: 'forms-small' /* AUTO-GENERATED */,
          component: FormsSentWidget /* AUTO-GENERATED */,
        },
        {
          id: 'forms-submission-comprehensive' /* AUTO-GENERATED */,
          title: t('Forms Submissions') /* AUTO-GENERATED */,
          description: t(
            'Shows the overview of form submissions with categorization by new patients and existing patients'
          ) /* AUTO-GENERATED */,
          icon: 'forms-small' /* AUTO-GENERATED */,
          component: FormsSubmissionComprehensive /* AUTO-GENERATED */,
        },
        {
          id: 'form-sent-comprehensive' /* AUTO-GENERATED */,
          title: t('Forms Sent') /* AUTO-GENERATED */,
          description: t('Shows the delivery method of Forms sent.') /* AUTO-GENERATED */,
          icon: 'forms-small' /* AUTO-GENERATED */,
          component: FormsSentComprehensiveWidget /* AUTO-GENERATED */,
        },
        {
          hasAccess: isMCTRoiEligibleLocation && !isAppointmentsRemindersRoiEnabled,
          id: 'missed-call-text-roi' /* AUTO-GENERATED */,
          title: t('Missed Call Text Conversion') /* AUTO-GENERATED */,
          description: t('Highlights the conversion of the missed call text.') /* AUTO-GENERATED */,
          icon: 'message-small' /* AUTO-GENERATED */,
          component: MissedCallTextROIWidget /* AUTO-GENERATED */,
        },
        {
          id: 'unread-voicemails-widget' /* AUTO-GENERATED */,
          title: t('Unread Voicemails') /* AUTO-GENERATED */,
          description: t('Listen and reply to unread voicemails from your dashboard.') /* AUTO-GENERATED */,
          icon: 'phone-small' /* AUTO-GENERATED */,
          component: UnreadVoicemailsInfo /* AUTO-GENERATED */,
        },
        {
          id: 'call-intel-redirect' /* AUTO-GENERATED */,
          title: t('Call Intelligence') /* AUTO-GENERATED */,
          description: t('Redirects user to Call Intelligence page.') /* AUTO-GENERATED */,
          icon: 'call-intel-small' /* AUTO-GENERATED */,
          component: CallIntelRedirectWidget /* AUTO-GENERATED */,
        },
        {
          id: 'wiv-setup' /* AUTO-GENERATED */,
          title: t('WIV Setup') /* AUTO-GENERATED */,
          description: t('Redirect user to WIV setup page if not done already.') /* AUTO-GENERATED */,
          icon: 'insurance-incomplete-small' /* AUTO-GENERATED */,
          component: WIVSetupWidget /* AUTO-GENERATED */,
        },
        {
          id: 'schedule-setup' /* AUTO-GENERATED */,
          title: t('Schedule Setup') /* AUTO-GENERATED */,
          description: t('Redirect user to Schedule setup page if not done already.') /* AUTO-GENERATED */,
          icon: 'schedule-pulse-small' /* AUTO-GENERATED */,
          component: ScheduleSetupWidget /* AUTO-GENERATED */,
        },
        {
          id: 'sticky-note-yellow' /* AUTO-GENERATED */,
          title: t('Sticky Note') /* AUTO-GENERATED */,
          description: t(
            'Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.'
          ) /* AUTO-GENERATED */,
          icon: 'task-small' /* AUTO-GENERATED */,
          component: StickyNoteWidgetYellow /* AUTO-GENERATED */,
        },
        {
          id: 'sticky-note-orange' /* AUTO-GENERATED */,
          title: t('Sticky Note') /* AUTO-GENERATED */,
          description: t(
            'Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.'
          ) /* AUTO-GENERATED */,
          icon: 'task-small' /* AUTO-GENERATED */,
          component: StickyNoteWidgetOrange /* AUTO-GENERATED */,
        },
        {
          id: 'sticky-note-green' /* AUTO-GENERATED */,
          title: t('Sticky Note') /* AUTO-GENERATED */,
          description: t(
            'Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.'
          ) /* AUTO-GENERATED */,
          icon: 'task-small' /* AUTO-GENERATED */,
          component: StickyNoteWidgetGreen /* AUTO-GENERATED */,
        },
        {
          id: 'sticky-note-blue' /* AUTO-GENERATED */,
          title: t('Sticky Note') /* AUTO-GENERATED */,
          description: t(
            'Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.'
          ) /* AUTO-GENERATED */,
          icon: 'task-small' /* AUTO-GENERATED */,
          component: StickyNoteWidgetBlue /* AUTO-GENERATED */,
        },
        {
          id: 'sticky-note-pink' /* AUTO-GENERATED */,
          title: t('Sticky Note') /* AUTO-GENERATED */,
          description: t(
            'Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.'
          ) /* AUTO-GENERATED */,
          icon: 'task-small' /* AUTO-GENERATED */,
          component: StickyNoteWidgetPink /* AUTO-GENERATED */,
        },
        {
          id: 'sticky-note-purple' /* AUTO-GENERATED */,
          title: t('Sticky Note') /* AUTO-GENERATED */,
          description: t(
            'Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.'
          ) /* AUTO-GENERATED */,
          icon: 'task-small' /* AUTO-GENERATED */,
          component: StickyNoteWidgetPurple /* AUTO-GENERATED */,
        },
        {
          id: 'weave-conversion' /* AUTO-GENERATED */,
          title: t('Weave Conversion') /* AUTO-GENERATED */,
          description: t(
            "Weave Conversion helps you evaluate how the features in Weave are enhancing your practice's performance."
          ) /* AUTO-GENERATED */,
          icon: 'dollar-sign-small' /* AUTO-GENERATED */,
          component: WeaveConversionWidget /* AUTO-GENERATED */,
          hasAccess: isAppointmentsRemindersRoiEnabled,
        },
      ] as const satisfies DashboardWidgetData[],
    [
      t,
      hasBulkMessagingFeature,
      anyLocationHasPayments,
      hasQuickFillFeature,
      hasBulkEmailAccess,
      hasBulkTextingAccess,
      isMCTRoiEligibleLocation,
    ]
  );

  const widgets = useMemo(() => {
    return widgetList.reduce((acc, widget) => {
      acc[widget.id] = widget;
      return acc;
    }, {} as { [id: string]: DashboardWidgetData });
  }, [widgetList]);

  return { widgets, widgetList };
}

export type WidgetIds = ReturnType<typeof useWidgets>['widgetList'][number]['id'];

export const defaultWidgets: WidgetIds[] = [
  'unread-voicemails-widget',
  'payments-summary',
  'add-invoice',
  'add-message',
  'add-quick-fill',
  'add-fax',
  'phones-snapshot-widget',
  'appointments-widget',
  'call-results-widget',
  'quick-actions',
  'training-articles',
];
