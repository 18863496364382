import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, IconButton } from '@frontend/design-system';
import { CHAT_AREA_WIDTH } from '../../constants';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { MentionsList } from './mentions-list';

export const MentionsPanel = () => {
  const isNavExpanded = useTeamChatSelector((ctx) => ctx.isNavExpanded);
  const collapseNav = useTeamChatSelector((ctx) => ctx.collapseNav);
  const closeConversation = useTeamChatSelector((ctx) => ctx.closeConversation);
  const onClickBack = () => {
    closeConversation();

    if (isNavExpanded) {
      collapseNav();
    }
  };
  return (
    <div css={{ width: CHAT_AREA_WIDTH, height: '100%' }}>
      <header css={[styles.header]}>
        {!isNavExpanded && (
          <IconButton
            label='Back'
            onClick={onClickBack}
            css={styles.back}
            trackingId='team-chat-2.0-close-mentions-button'
          >
            <Icon name='caret-left-small' size={18} />
          </IconButton>
        )}
        <Heading level={2}>
          <i css={styles.icon}>@</i>&nbsp;Mentions
        </Heading>
      </header>
      <MentionsList />
    </div>
  );
};

const styles = {
  icon: css`
    display: inline-block;
    margin-top: -2px;
  `,
  header: css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral10};
    display: flex;
    padding: ${theme.spacing(1.75, 2, 1.75, 1)};
    gap: ${theme.spacing(1)};
  `,
  back: css`
    min-width: 36px;
    padding: 0;
    width: 36px;
    height: 36px;
  `,
};
