import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { ShareInEmailStepNavigationProps } from '../share-in-email.types';

export const ShareInEmailGenericNavigation = ({ body: Body }: ShareInEmailStepNavigationProps) => {
  const {
    multiStep: { goPrevStep, onComplete },
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => {
    goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
  }, []);

  const onCompleteStep = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const onClickPrimary = useCallback(() => {
    // just overrides default functionality with nothing;
  }, []);

  return <Body onGoBack={onGoBack} onCompleteStep={onCompleteStep} onClickPrimary={onClickPrimary} />;
};
