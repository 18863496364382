import { BulkTextTemplateEditor } from '@frontend/bulk-text-template-editor';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Stepper, Text } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore } from '../../../hooks';
import { overrideTextStyles, stepValueContainerStyles } from '../styles';
import { Step, StepId } from '../types';

type UseEmailContentStepParams = {
  contentError: string;
  next: (stepId: StepId) => void;
  previous: (stepId: StepId) => void;
};

export const useTextContentStep = ({ contentError, next, previous }: UseEmailContentStepParams): Step => {
  const { t } = useTranslation('bulk-messaging');
  const store = useBulkEmailEditorShallowStore(
    'setSubject',
    'setTemplateHtml',
    'setTemplateJson',
    'templateHtml',
    'templateJson',
    'thumbnailImage',
    'thumbnailUrl'
  );

  const id: StepId = 'content';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Text Message Content')}</Stepper.Title>

        <Stepper.Content>
          <Text css={{ marginBottom: theme.spacing(2) }}>
            {t('Customize your message below. Use dynamic fields to personalize your message for each recipient.')}
          </Text>
          <BulkTextTemplateEditor
            error={contentError}
            initialTemplate={store.templateHtml}
            onBlur={() => {}}
            onChange={store.setTemplateHtml}
            readOnly={false}
          />
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => previous(id)} position='secondary' type='none'>
            {t('Previous')}
          </Stepper.ButtonAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none'>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Text Message Content')}</Text>
        <Text css={{ ...overrideTextStyles, textTransform: 'initial' }}>{store.templateHtml}</Text>
      </div>
    ),
  };
};
