import { Frame } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';

const getFrameAlignmentStyle = (frame?: Frame) => {
  if (frame?.alignment === 'left') {
    return { justifyContent: 'flex-start' };
  } else if (frame?.alignment === 'right') {
    return { justifyContent: 'flex-end' };
  }
  return { justifyContent: 'center' };
};

const getFrameColorStyle = (frame?: Frame) =>
  frame?.backgroundColor ? { backgroundColor: frame?.backgroundColor } : {};

const DEFAULT_PADDING = '0px';

const getPaddingValue = (value: string) => {
  const isOnlyNumbers = /^\d+$/.test(value);
  return isOnlyNumbers ? `${value}px` : value;
};

const getPaddingStyle = (frame?: Frame) => {
  if (!frame) return {};
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = frame;
  const top = paddingTop ? getPaddingValue(paddingTop) : DEFAULT_PADDING;
  const right = paddingRight ? getPaddingValue(paddingRight) : DEFAULT_PADDING;
  const bottom = paddingBottom ? getPaddingValue(paddingBottom) : DEFAULT_PADDING;
  const left = paddingLeft ? getPaddingValue(paddingLeft) : DEFAULT_PADDING;
  return { padding: `${top} ${right} ${bottom} ${left}` };
};

export const getFrameStyle = (frame?: Frame) => ({
  ...getFrameAlignmentStyle(frame),
  ...getFrameColorStyle(frame),
  ...getPaddingStyle(frame),
});
