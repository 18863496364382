import { createPersistProvider } from '@frontend/store';
import { WeavePopNotification, PreferenceType } from '@frontend/types';

/**
 * Notification Preferences System
 *
 * This module manages user notification preferences, allowing for:
 * - Pausing specific notification types
 * - Dismissing preference prompts
 * - Creating rules to suppress notifications based on conditions
 * - Defining preference rules for notifications
 *
 * The system leverages persistent storage to remember user preferences across sessions.
 */

type NotificationType = WeavePopNotification['type'];

export type NotificationCondition = (notification: WeavePopNotification, context: { pathname: string }) => boolean;

/**
 * Defines a rule for showing notification preference pops to the user
 *
 * @property id - Unique identifier for the preference rule ()
 * @property for - The notification type this rule applies to (e.g., sms-message-new)
 * @property condition - Optional function that determines when this rule should apply
 * @property type - The type of preference (e.g., pause-notifications, opt-out)
 * @property title - Title text shown to the user, can be static or dynamic
 * @property body - Description text shown to the user, can be static or dynamic
 */
export type PreferenceRule = {
  id: string;
  for: NotificationType;
  condition?: NotificationCondition;
  type: PreferenceType;
  title: string | ((notification: WeavePopNotification) => string);
  body: string | ((notification: WeavePopNotification) => string);
};

/**
 * Defines a rule for suppressing notifications based on conditions
 *
 * @property id - Unique identifier for the suppress rule
 * @property for - The notification type this rule applies to
 * @property condition - Function that determines when notifications should be suppressed
 */
export type SuppressRule = {
  id: string;
  for: NotificationType;
  condition: NotificationCondition;
};

export type Unsubscribe = () => void;

interface NotificationPreferencesState {
  /** Record storing which notification types have been paused by the user */
  pausedNotificationTypes: Record<string, boolean>;

  /** Record storing which preference prompts have been dismissed by the user */
  dismissedPreferences: Record<string, boolean>;

  /** Collection of rules for suppressing notifications */
  suppressRules: Record<string, SuppressRule>;

  /** Collection of preference rules for showing preference options */
  preferenceRules: Record<string, PreferenceRule>;

  /**
   * Sets the paused state for a specific notification type
   *
   * @param type - The notification type (e.g., sms-message-new)
   * @param paused - Whether to pause (true) or unpause (false)
   */
  pauseNotificationType: (type: string, paused: boolean) => void;

  /**
   * Marks a specific preference prompt as dismissed
   *
   * @param preferenceId - ID of the preference to dismiss
   */
  dismissPreference: (preferenceId: string) => void;

  /**
   * Resets a previously dismissed preference prompt
   *
   * @param preferenceId - ID of the preference to reset
   */
  resetDismissedPreference: (preferenceId: string) => void;

  /**
   * Resets all dismissed preferences to their original state
   */
  resetAllDismissedPreferences: () => void;

  /**
   * Unpauses all notifications that were previously paused
   */
  resetAllPausedNotifications: () => void;

  /**
   * Checks if a specific notification type is currently paused
   *
   * @param type - The notification type to check
   * @returns Boolean indicating whether the notification type is paused
   */
  isNotificationPaused: (type: string) => boolean;

  /**
   * Checks if a specific preference prompt has been dismissed
   *
   * @param preferenceId - ID of the preference to check
   * @returns Boolean indicating whether the preference has been dismissed
   */
  hasPreferenceDismissed: (preferenceId: string) => boolean;

  /**
   * Registers a new suppress rule
   *
   * @param rule - The suppress rule to add
   * @returns Unsubscribe function to remove the rule when no longer needed
   */
  addSuppressRule: (rule: SuppressRule) => Unsubscribe;

  /**
   * Registers a new preference rule
   *
   * @param rule - The preference rule to add
   * @returns Unsubscribe function to remove the rule when no longer needed
   */
  addPreferenceRule: (rule: PreferenceRule) => Unsubscribe;

  /**
   * Determines if a specific notification should be suppressed based on all active rules
   *
   * @param notification - The notification to evaluate
   * @param context - The current application context
   * @returns Boolean indicating whether the notification should be suppressed
   */
  shouldSuppressNotification: (notification: WeavePopNotification, context: { pathname: string }) => boolean;

  /**
   * Finds a preference rule that should be shown for the given notification
   *
   * @param notification - The notification to evaluate
   * @param context - The current application context
   * @returns The applicable preference rule or null if none found
   */
  getPreferenceToShow: (notification: WeavePopNotification, context: { pathname: string }) => PreferenceRule | null;
}

const defaultCondition = () => true;

export const {
  Provider: NotificationPreferencesProvider,
  useStore: useNotificationPreferences,
  useNonReactiveStore: useNotificationPreferencesStore,
} = createPersistProvider<NotificationPreferencesState>()((set, get) => ({
  pausedNotificationTypes: {},

  dismissedPreferences: {},

  suppressRules: {},

  preferenceRules: {},

  pauseNotificationType: (type, paused) => {
    set((state) => ({
      ...state,
      pausedNotificationTypes: {
        ...state.pausedNotificationTypes,
        [type]: paused,
      },
    }));
  },

  dismissPreference: (preferenceId) =>
    set((state) => ({
      dismissedPreferences: {
        ...state.dismissedPreferences,
        [preferenceId]: true,
      },
    })),

  resetDismissedPreference: (preferenceId) =>
    set((state) => ({
      dismissedPreferences: {
        ...state.dismissedPreferences,
        [preferenceId]: false,
      },
    })),

  resetAllDismissedPreferences: () =>
    set(() => ({
      dismissedPreferences: {},
    })),

  resetAllPausedNotifications: () =>
    set(() => ({
      pausedNotificationTypes: {},
    })),

  isNotificationPaused: (type) => get().pausedNotificationTypes[type] === true,

  hasPreferenceDismissed: (preferenceId) => get().dismissedPreferences[preferenceId] === true,

  addSuppressRule: (rule) => {
    set((state) => ({
      suppressRules: {
        ...state.suppressRules,
        [rule.id]: rule,
      },
    }));

    return () => {
      set((state) => {
        const newSuppressRules = { ...state.suppressRules };
        delete newSuppressRules[rule.id];
        return { suppressRules: newSuppressRules };
      });
    };
  },

  addPreferenceRule: (rule) => {
    set((state) => ({
      preferenceRules: {
        ...state.preferenceRules,
        [rule.id]: rule,
      },
    }));

    return () => {
      set((state) => {
        const newPreferenceRules = { ...state.preferenceRules };
        delete newPreferenceRules[rule.id];
        return { preferenceRules: newPreferenceRules };
      });
    };
  },

  shouldSuppressNotification: (notification, context) => {
    const { suppressRules } = get();

    return Object.values(suppressRules).some(
      (rule) => rule.for === notification.type && rule.condition(notification, context)
    );
  },

  getPreferenceToShow: (notification, context) => {
    const { preferenceRules, hasPreferenceDismissed } = get();

    for (const rule of Object.values(preferenceRules)) {
      if (rule.for !== notification.type) continue;

      if (hasPreferenceDismissed(rule.id)) continue;

      const condition = rule.condition || defaultCondition;
      if (condition(notification, context)) {
        return rule;
      }
    }

    return null;
  },
}));
