import { useEffect, useMemo, useState } from 'react';
import { AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { TableFilters } from '@frontend/design-system';
import { useTimePeriodDropDownSelector } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { DatesRangeSelector } from '../filter-selectors';
import { FiltersTray } from '../filters-tray';
import { useAutoRecallShallowStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
};

export const AutoRecallFilters = ({ isLoadingData }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const {
    filters,
    hasCustomFilters,
    locationId: autoRecallLocationId,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationId,
    resetStore,
  } = useAutoRecallShallowStore(
    'filters',
    'hasCustomFilters',
    'locationId',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setLocationId',
    'resetStore'
  );

  const [trayFilters, setTrayFilters] = useState<AutoRecallAnalyticsTypes.Filters>({});

  const { TimePeriodDropDownSelector, defaultEndDate, defaultStartDate, selectedPeriod, timePeriods } =
    useTimePeriodDropDownSelector({
      defaultPeriod: 'last30Days',
      values: {
        // Fallback is added to show the correct date range when user navigates between pages after applying custom filters
        startDate: trayFilters.startDate || filters.startDate,
        endDate: trayFilters.endDate || filters.endDate,
      },
    });

  const defaultFilters = useMemo(
    (): AutoRecallAnalyticsTypes.Filters => ({
      endDate: defaultEndDate,
      startDate: defaultStartDate,
    }),
    [defaultEndDate, defaultStartDate]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<AutoRecallAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      selectedPeriod
        ? timePeriods[selectedPeriod].label
        : `${formatters.date.format(filters.startDate || '')} - ${formatters.date.format(filters.endDate || '')}`
    );

    // Do not add dependency for selectedPeriod here, because selectedPeriod needs to be considered only when user hit the apply button
  }, [filters, timePeriods]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (!autoRecallLocationId) {
      setLocationId(locationId);
    } else if (locationId !== autoRecallLocationId) {
      //  Reset when location is switched
      resetStore();
      setLocationId(locationId);
      applyDefaultFilters();
    }
  }, [autoRecallLocationId, locationId]);

  return (
    <FiltersTray
      disableTray={isLoadingData}
      headerLabel={t('Filter Auto Recall')}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={hasCustomFilters}
      trackingId={trackingIds.autoRecallAnalytics.filtersOpen}
    >
      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodDropDownSelector
            disabled={isLoadingData}
            onChange={({ startDate, endDate }) => {
              handleUpdateTrayFilters({ startDate, endDate });
            }}
            trackingIdBase={trackingIds.autoRecallAnalytics.timePeriodOnTrayFilter}
          />
          <DatesRangeSelector
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => handleUpdateTrayFilters({ endDate, startDate })}
            startDate={trayFilters.startDate}
          />
        </div>
      </TableFilters.Section>
    </FiltersTray>
  );
};
