import { css, keyframes } from '@emotion/react';
import { theme } from '@frontend/theme';

export const TypingAnimation = () => {
  return (
    <div css={style} aria-label='typing animation' tabIndex={-1} aria-hidden='true'>
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

const animation = keyframes`
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
`;

const style = css`
  ul {
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
    height: 17px;
    li {
      background-color: ${theme.colors.neutral70};
      -webkit-animation: ${animation} 1.5s infinite ease-in-out;
      border-radius: 2px;
      display: inline-block;
      height: 4px;
      margin-right: 2px;
      width: 4px;
      &:nth-child(1) {
        -webkit-animation-delay: 200ms;
      }
      &:nth-child(2) {
        -webkit-animation-delay: 300ms;
      }
      &:nth-child(3) {
        -webkit-animation-delay: 400ms;
      }
    }
  }
`;
