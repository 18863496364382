import { useCallback, useEffect, useMemo, useState } from 'react';
import { AnalyticsCommonTypes, BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedInfiniteQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useIsMulti } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { patientsDemoData } from '../common-demo-data';
import { RoiApiPayload } from '../types';

type AppointmentStatus = 'Completed' | 'Confirmed' | 'Scheduled' | 'Unconfirmed';

export interface RoiPatient {
  appointment_type?: string;
  appts_completion_date?: string;
  contact_type?: string;
  entry_date?: string;
  location_id?: string;
  message_details?: string;
  patient_id?: string;
  patient_name?: string;
  phone_number?: string;
  practitioner_name?: string;
  receiver_number?: string;
  sender_number?: string;
  status?: AppointmentStatus;
}

interface Meta {
  links: {
    next: string;
    previous: string;
  };
}

interface PatientsResponse {
  data: {
    data: RoiPatient[];
  };
  meta: Meta;
}

export type LeadsContactedAdditionalPayload = {
  appointment_filter_flag?: boolean;
  appointment_scheduled_flag?: boolean;
};

export type LeadsConvertedAdditionalPayload = {
  appointment_filter_flag?: boolean;
  appointment_status: {
    appointment_status_equality?: boolean;
    appointment_status: AppointmentStatus;
  };
};

type Payload = RoiApiPayload & {
  appointment_filters: LeadsContactedAdditionalPayload | LeadsConvertedAdditionalPayload;
  order_by_field?: string;
  order_by?: 'asc' | 'desc';
  roi_type: 'MissedCallText'; // More to be added in future
};

type FetchParams = {
  payload: Payload & {
    is_multi: boolean;
    org_id: string;
  };
};

type Api = FetchParams & {
  category: 'missedcalltext' | 'appointmentreminder' | 'bulkmessaging';
  limit?: number;
  subCategory?: 'getrawmctsms' | 'getconvertedpatients' | 'getfutureappointments' | 'getrawsms';
  url?: string;
};

export interface UseFetchRoiPatientsProps {
  category?: Api['category'];
  isDemoAccount?: boolean;
  isEnabled?: boolean;
  payload: Payload;
  queryKeyHelpers?: string[];
  subCategory?: Api['subCategory'];
}

interface UseFetchRoiPatientsResponse {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isLoading?: boolean;
  pageConfig: AnalyticsCommonTypes.PageConfig;
  patients: RoiPatient[];
  setPageConfig: (pageConfig: AnalyticsCommonTypes.PageConfig) => void;
}

const DEFAULT_PAGE_SIZE = 10;

const api = async ({ category, limit = DEFAULT_PAGE_SIZE, payload, subCategory, url }: Api) => {
  return await http.post<PatientsResponse>(
    url ?? `${BASE_URL_ANALYTICS}/roi/v4/${category}/${subCategory}?limit=${limit}&skip=0`,
    payload
  );
};

export const useFetchRoiPatients = ({
  category = 'missedcalltext',
  isDemoAccount,
  isEnabled = true,
  payload,
  queryKeyHelpers = [],
  subCategory = 'getrawmctsms',
}: UseFetchRoiPatientsProps): UseFetchRoiPatientsResponse => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const stringifedPayload = JSON.stringify(payload);
  const { isMulti, parentId } = useIsMulti();

  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const queryString = useMemo(
    () =>
      `${JSON.stringify(stringifedPayload)}-${pageConfig.pageSize}-${queryKeyHelpers.join(
        '-'
      )}-${category}-${subCategory}`,
    [category, subCategory, stringifedPayload, pageConfig.pageSize, queryKeyHelpers]
  );

  const enableFetch =
    !isDemoAccount && isEnabled && !!payload?.start_date && !!payload?.end_date && !!payload?.location_id?.length;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    isFetching: isLoading,
  } = useScopedInfiniteQuery({
    queryKey: queryKeys.roiPatients(queryString),
    queryFn: ({ pageParam }) =>
      api({
        limit: pageConfig.pageSize,
        category,
        subCategory,
        payload: {
          ...payload,
          is_multi: isMulti,
          org_id: parentId,
        },
        url: pageParam,
      }),
    getNextPageParam: (lastPage: PatientsResponse | undefined) => {
      return lastPage?.meta?.links?.next || '';
    },
    getPreviousPageParam: (lastPage: PatientsResponse | undefined) => {
      return lastPage?.meta?.links?.previous || '';
    },
    onError: () => {
      alert.error(t("Couldn't fetch the patients details. Please try again."));
    },
    enabled: enableFetch,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const getHasNext = useCallback(() => {
    const pageData = data?.pages[pageConfig.pageNumber - 1]?.data.data || [];
    return hasNextPage && !!pageData.length && pageData.length == pageConfig.pageSize;
  }, [data?.pages, pageConfig.pageNumber, hasNextPage]);

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  }, [queryString]);

  useEffect(() => {
    if (enableFetch && !data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [enableFetch, pageConfig.pageNumber]);

  return {
    fetchNextPage,
    hasNextPage: getHasNext(),
    hasPreviousPage,
    isLoading,
    pageConfig,
    patients: isDemoAccount
      ? patientsDemoData(payload?.location_id ?? [])
      : data?.pages[pageConfig.pageNumber - 1]?.data.data || [],
    setPageConfig,
  };
};
