import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ConfirmationModal, Text, useModalControl } from '@frontend/design-system';
import { NumberedRow } from '../..';

export type FilterExportWarning = {
  searchParams?: boolean;
  truncateDateRange?: boolean;
};

export const useInvoiceExportModalControl = () => {
  const { modalProps, openModal } = useModalControl();
  const [message, setMessage] = useState<FilterExportWarning>({});

  return {
    modalProps,
    exportWarning: message,
    showInvoiceExportWarning: (message: FilterExportWarning) => {
      setMessage(message);
      openModal();
    },
  };
};

type InvoiceExportModalProps = Omit<ReturnType<typeof useInvoiceExportModalControl>, 'showInvoiceExportWarning'> & {
  onConfirm: () => void;
};

export const InvoiceExportModal = ({ modalProps, exportWarning, onConfirm }: InvoiceExportModalProps) => {
  const { t } = useTranslation('payments');

  const warningsCount = useMemo(() => Object.values(exportWarning).filter(Boolean).length, [exportWarning]);

  const textMap: Record<keyof FilterExportWarning, string> = useMemo(() => {
    return {
      searchParams: t('The Search filter option is not currently supported on this export.'),
      truncateDateRange: t(
        'To enhance export speed, the data will be limited to the last 3 months of data you’ve requested.'
      ),
    };
  }, []);

  return (
    <ConfirmationModal
      {...modalProps}
      title={t('Please Note')}
      confirmLabel={t('Continue')}
      onConfirm={() => {
        modalProps.onClose();
        onConfirm();
      }}
    >
      <div
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {Object.entries(exportWarning).map(([key, value], idx) => {
          const text = textMap[key as keyof FilterExportWarning];
          if (!value || !text) {
            return null;
          }
          return (
            <div key={idx}>
              {warningsCount > 1 && (
                <NumberedRow number={idx + 1}>
                  <Text>{text}</Text>
                </NumberedRow>
              )}
              {warningsCount <= 1 && <Text>{text}</Text>}
            </div>
          );
        })}
      </div>
    </ConfirmationModal>
  );
};
