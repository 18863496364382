import { SchemaIO } from '@frontend/schema';
import { useSMSDataV3QueryUpdaters } from '../query-updaters';
import { ServiceMutations } from '../types';
import { useSMSDataV3Mutation, UseSMSDataV3MutationEndpointArgs } from './use-sms-data-v3-mutation';

export const getSetThreadsNoReplyNeededMutationKey = () => ['V3', 'SetThreadsNoReplyNeeded'] as const;

/**
 * A hook that returns a mutation for the `SetThreadsNoReplyNeeded` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useSetThreadsNoReplyNeededMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['SetThreadsNoReplyNeeded']>['input'] = SchemaIO<
    ServiceMutations['SetThreadsNoReplyNeeded']
  >['input']
>({
  options,
  ...args
}: UseSMSDataV3MutationEndpointArgs<
  'SetThreadsNoReplyNeeded',
  E,
  C | undefined,
  OtherOptions,
  RequestOverride
> = {}) => {
  const { updateThreadsRepliedStatus } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'SetThreadsNoReplyNeeded', E, C, OtherOptions, RequestOverride>({
    endpointName: 'SetThreadsNoReplyNeeded',
    ...args,
    options: {
      ...options,
      onSuccess: (response, request, context) => {
        updateThreadsRepliedStatus({ groupIds: request.groupIds, threadIds: response.succeeded });
        return options?.onSuccess?.(response, request, context);
      },
    },
  });
};
