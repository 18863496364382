import { createProvider } from '@frontend/store';
import { SectionStore, SectionStoreState } from './section-store.types';

const INITIAL_STATE: SectionStoreState = {
  isCollapsed: false,
};

export const { Provider: SectionStoreProvider, useStore: useSectionStore } = createProvider<SectionStore>()((set) => ({
  ...INITIAL_STATE,
  toggleCollapse: () => set((state) => ({ isCollapsed: !state.isCollapsed })),
}));
