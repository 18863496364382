import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { FlatIndexLocationWithAlign, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { PersonsV3 } from '@frontend/api-person';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { useVisibilityChange } from '@frontend/document';
import { useTranslation } from '@frontend/i18n';
import { useAppFlagStore } from '@frontend/shared';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { ThreadListLoader } from './thread-list-loader';
import { ThreadListProps, ThreadListVirtuosoContext } from './types';
import { VirtuosoItemWrapper } from './virtuoso-items/virtuoso-item-wrapper';

const START_INDEX = 100000;

type CombinedThreadListProps = ThreadListProps & {
  hasNewerMessages?: boolean;
  targetSmsId?: string;
  targetSmsClickCount?: number;
  fetchNewerMessages?: () => void;
  personPhone: string;
};

/**
 *  ThreadListComponent
 *
 *  - Can be used both as a standard thread list and a bidirectional one based on the presence targetSmsId
 *  - Pass targetSmsId to use this component as bidirectional thread list
 *
 * */
export const ThreadList = ({
  combinedMessages,
  hasOlderMessages,
  optedOut,
  personId,
  threadId,
  groupId,
  locationTags,
  selectionType,
  threadIsLoading,
  threadIsFetching,
  newWritebackMessages,
  hasNewerMessages = false,
  targetSmsId = '',
  targetSmsClickCount = 0,
  mediaQueries,
  fetchNewerMessages,
  fetchOlderMessages,
  setScrollbarWidth,
  personPhone,
}: CombinedThreadListProps) => {
  const getFirstItemId = useCallback(() => {
    const firstMessage = combinedMessages.at(-1);
    return typeof firstMessage === 'string'
      ? (firstMessage as string)
      : (firstMessage as ScheduledSms | SMS | undefined)?.id ?? '';
  }, [combinedMessages]);

  const [messagesMeta, setMessagesMeta] = useState<{
    firstItemId: string;
    messagesLength: number;
    firstItemIndex: number;
  }>({
    firstItemId: getFirstItemId(),
    messagesLength: combinedMessages.length,
    firstItemIndex: START_INDEX,
  });
  const [hasInitiallyScrolled, setHasInitiallyScrolled] = useState<boolean>(false);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [isAtTop, setIsAtTop] = useState<boolean>(false);
  const { featureFlags } = useAppFlagStore();

  const threadNamingIsEnabled = !!featureFlags[groupId]?.get('comm-platform-thread-naming')?.value;
  const { primaryContactId, associatedContacts } = PersonsV3.PersonHooks.useAssociatedContacts({
    phoneNumber: personPhone,
    locationId: groupId,
    enabled: !!personPhone && threadNamingIsEnabled,
  });
  const { data: fetchedPerson } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: personId ?? '',
      locationIds: [groupId],
    },
    {
      enabled: !!personId && !!groupId && !threadNamingIsEnabled,
    }
  );
  const person = threadNamingIsEnabled
    ? associatedContacts.find((contact) => contact.personId === (personId || primaryContactId))
    : fetchedPerson;

  const { isActive: bulkSelectionIsActive } = useBulkListContext();

  const ref = useRef<VirtuosoHandle>(null);

  const { t } = useTranslation('inbox');

  const isThreadVisible = useVisibilityChange(); // to track tab change which affects scrollTo behavior

  const targetSmsIndex = useMemo(() => {
    const smsIndex = combinedMessages.findIndex((message) => typeof message !== 'string' && message.id === targetSmsId);
    return targetSmsId && smsIndex !== -1 ? smsIndex : START_INDEX;
  }, [targetSmsId, combinedMessages]);

  const someMediaIsLoading = useMemo(
    () => Object.values(mediaQueries).some((mediaQuery) => mediaQuery.isLoading),
    [mediaQueries]
  );

  const scrollToItem = useMemo(
    (): FlatIndexLocationWithAlign =>
      targetSmsId
        ? { index: targetSmsIndex, align: 'center', behavior: 'smooth' }
        : { index: 'LAST', align: 'end', behavior: 'smooth' },
    [targetSmsId]
  );

  // TODO: replace with better logic handling dynamic tag height with transitions
  useEffect(() => {
    if (!isAtBottom && isThreadVisible && !hasInitiallyScrolled) {
      const timer = setTimeout(() => {
        ref.current?.scrollToIndex(scrollToItem);
      }, 500);
      return () => clearInterval(timer);
    }
    return;
  }, [isThreadVisible]);

  // To set firstItemIndex from which the list begins rendering for bidirectional thread scrolling
  useEffect(() => {
    if (targetSmsId) {
      const newFirstItemId = getFirstItemId();
      const hasFirstItemChanged = newFirstItemId !== messagesMeta.firstItemId;
      if (hasFirstItemChanged) {
        setMessagesMeta((prev) => ({
          ...prev,
          firstItemId: newFirstItemId,
          messagesLength: combinedMessages.length,
        }));
      } else {
        const newMessagesCount = combinedMessages.length - messagesMeta.messagesLength;
        setMessagesMeta((prev) => ({
          ...prev,
          messagesLength: combinedMessages.length,
          firstItemIndex: prev.firstItemIndex - newMessagesCount,
        }));
      }
    }
  }, [JSON.stringify(combinedMessages)]);

  useEffect(() => {
    if (targetSmsClickCount > 1) {
      ref.current?.scrollToIndex({
        index: targetSmsIndex,
        align: 'center',
        behavior: targetSmsClickCount > 1 ? 'smooth' : 'auto',
      });
    }
  }, [targetSmsClickCount]);

  useEffect(() => {
    if (!hasInitiallyScrolled && !someMediaIsLoading) {
      ref.current?.scrollToIndex(scrollToItem);
    }
  }, [someMediaIsLoading]);

  return (
    <Virtuoso
      ref={ref}
      data-trackingid={`${InboxPrefixes.Thread}-thread-list`}
      alignToBottom
      initialItemCount={combinedMessages.length}
      onScroll={() => setHasInitiallyScrolled(true)}
      firstItemIndex={targetSmsId ? messagesMeta.firstItemIndex : START_INDEX - combinedMessages.length}
      atBottomStateChange={setIsAtBottom}
      atTopStateChange={setIsAtTop}
      defaultItemHeight={!targetSmsId ? 100 : undefined} // default height breaks bidirectional thread logic
      followOutput={(isAtBottom) => {
        if (isAtBottom && !hasNewerMessages && !targetSmsId) {
          return 'smooth';
        }
        return false;
      }}
      data={combinedMessages}
      startReached={() => {
        if (hasOlderMessages && !threadIsFetching && !threadIsLoading) fetchOlderMessages();
      }}
      endReached={() => {
        if (hasNewerMessages && !threadIsFetching && !threadIsLoading) fetchNewerMessages?.();
      }}
      scrollerRef={(ref) => {
        if (ref && 'offsetWidth' in ref) {
          setScrollbarWidth?.(ref.offsetWidth - ref.clientWidth);
        }
      }}
      increaseViewportBy={!!selectionType ? { bottom: 150, top: 0 } : 0}
      atBottomThreshold={1}
      context={
        {
          showTopLoader: threadIsFetching && !!combinedMessages.length && isAtTop && hasOlderMessages,
          showBottomLoader: threadIsFetching && !!combinedMessages.length && isAtBottom && hasNewerMessages,
          optedOut,
          targetSmsId,
          personData: person
            ? {
                personId: person.personId,
                firstName: person.firstName ?? '',
                lastName: person.lastName ?? '',
                preferredName: person.preferredName,
              }
            : undefined,
          newWritebackMessages: newWritebackMessages ?? [],
          groupId,
          threadId,
          locationTags: locationTags ?? [],
          selectionType,
          threadIsFetching,
          threadIsLoading,
          isNewThread: targetSmsId ? false : !threadIsLoading && combinedMessages.length === 0,
          mediaQueries,
          bulkSelectionIsActive,
        } satisfies ThreadListVirtuosoContext
      }
      components={{
        Header: ({ context }) => {
          if ((context?.isNewThread && !targetSmsId) || !isAtTop)
            return <div css={{ margin: 0, padding: 0, height: theme.spacing(9) }} />;
          if (context?.showTopLoader) return <ThreadListLoader size='medium' />;
          return (
            <Text css={{ margin: 0, padding: theme.spacing(3, 0), textAlign: 'center' }}>
              🎉 {t('You have reached the end')}
            </Text>
          );
        },
        Footer: ({ context }) => {
          if (context?.showBottomLoader) return <ThreadListLoader size='medium' />;
          if (context?.isNewThread && !targetSmsId)
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                {t('No messages yet.')}
              </Text>
            );
          return null;
        },
      }}
      itemContent={VirtuosoItemWrapper}
      initialTopMostItemIndex={{ ...scrollToItem, behavior: 'auto' }}
    />
  );
};
