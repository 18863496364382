import { css } from '@emotion/react';
import { DeviceMake_Enum, LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useLineKeyState } from '../../store/line-key-provider';

type Props = {
  keys: (string | number | undefined)[];
  isPreviewEnabled?: boolean;
  lineKeys: LineKey[] | undefined;
  make: DeviceMake_Enum;
  side: 'left' | 'right';
};

export const LayoutKeyRender = ({ isPreviewEnabled, ...rest }: Props) => {
  if (isPreviewEnabled) {
    return <LayoutKeyRenderWithPreview {...rest} />;
  }

  return <LayoutKeyRenderWithoutPreview {...rest} />;
};

const LayoutKeyRenderWithPreview = ({ keys, lineKeys, side, make }: Omit<Props, 'isPreviewEnabled'>) => {
  const lineKeysLength = lineKeys?.length;

  const scrollToCard = () => {};

  return (
    <>
      {keys.map((key) => {
        const showKey = typeof key === 'number' && !!lineKeysLength && lineKeysLength >= key;
        return (
          <li
            key={key}
            css={keyStyles({ key: showKey, make, side, isPreviewEnabled: true })}
            onClick={() => {
              if (key) {
                scrollToCard();
              }
            }}
          >
            <Text color='white' as='span'>
              {showKey && key}
            </Text>
          </li>
        );
      })}
    </>
  );
};

const LayoutKeyRenderWithoutPreview = ({ keys, lineKeys, side, make }: Omit<Props, 'isPreviewEnabled'>) => {
  const lineKeysLength = !!lineKeys?.length ? lineKeys?.length + 1 : lineKeys?.length;

  const { scrollToCard } = useLineKeyState(['scrollToCard']);

  return (
    <>
      {keys.map((key) => {
        const showKey = typeof key === 'number' && !!lineKeysLength && lineKeysLength >= key;
        return (
          <li
            key={key}
            css={keyStyles({ key: showKey, make, side })}
            onClick={() => {
              if (key) {
                scrollToCard(+key);
              }
            }}
          >
            <Text color='white' as='span'>
              {showKey && key}
            </Text>
          </li>
        );
      })}
    </>
  );
};

const keyStyles = ({
  key,
  make,
  side,
  isPreviewEnabled,
}: {
  key?: boolean | 0;
  side: 'left' | 'right';
  make?: DeviceMake_Enum;
  isPreviewEnabled?: boolean;
}) => css`
  width: ${theme.spacing(8)};
  background: ${theme.colors.neutral60};
  border-radius: ${theme.borderRadius.medium};
  border: 2px solid ${theme.colors.neutral90};
  position: relative;
  margin-left: auto;
  margin-right: auto;

  span {
    font-size: ${theme.fontSize(20)};
  }

  ${make === DeviceMake_Enum.POLYCOM &&
  `
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 100%;
          height: 2px;
          background-color: ${theme.colors.white};
          transform: translateY(-50%);
        }
  
        &:nth-child(odd) {
          &::before{
            width: ${theme.spacing(4.25)};
          }
        }
  
        &:nth-child(even) {
          &::before{
            width: ${theme.spacing(2.25)};
          }
        }
  
        ${
          side === 'right' &&
          `&::before {
              right: 100%;
              left: unset;
            }`
        }
      `}

  ${!!key &&
  !isPreviewEnabled &&
  `
      &:active {
        background: ${theme.colors.neutral40};
      }
      &:hover {
        background: ${theme.colors.neutral70};
      }
      cursor: pointer;
    `}
`;
