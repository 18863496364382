import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import intervalToDuration from 'date-fns/intervalToDuration';
import { i18next } from '@frontend/i18n';

export const getPetAge = (birthdate: string) => {
  if (!birthdate) return '';
  const { years, months } = intervalToDuration({ start: new Date(), end: new Date(birthdate) });

  if (years && months) {
    return i18next.t(`{{years}} yrs, {{months}} mos`, { ns: 'base', years, months });
  }
  if (years && !months) {
    return i18next.t(`{{years}} yrs`, { ns: 'base', years });
  }
  if (!years && months) {
    return i18next.t(`{{months}} mos`, { ns: 'base', months });
  }
  return '';
};

export const petIsActive = (status: PetDetails['activeStatus']) => {
  const petStatus = status?.toLowerCase();
  return !(petStatus === 'deceased' || petStatus === 'inactive');
};
