import { ReactNode, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { sendInvoiceReceipt } from '@frontend/api-invoices';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { ITerminalStrategyError, StoredReader, TerminalReader } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import {
  Button,
  DropdownField,
  Modal,
  TextField,
  TextLink,
  useAlert,
  useFormField,
  useTooltip,
  ContentLoader,
} from '@frontend/design-system';
import { useInvoicePerson } from '../../hooks';
import { ReceiptSection } from '../receipt';
import { sortReaders } from './utils';

const styles = {
  section: css`
    display: grid;
    grid-template-rows: 1fr auto;
    justify-items: center;
    padding: ${theme.spacing(2)};
  `,
  paymentForm: css`
    flex: 1;
    display: flex;
    gap: ${theme.spacing(2)};
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: ${theme.spacing(50)};
  `,
  buttonBar: css`
    display: flex;
    justify-content: end;
    gap: ${theme.spacing(2)};
  `,
  receiptButtonBar: css`
    display: flex;
    justify-content: space-between;
  `,
};

type CollectReaderPaymentDisplayProps = {
  selectedReader: StoredReader | undefined;
  onChangePaymentMethodClick: () => void;
  onTryAgainClick: () => void;
  onCancelClick: () => void;
  onCloseClick: () => void;
  ReaderStatusOverrideComponent: ReactNode;
  availableReaders?: TerminalReader[];
  onSelectedReaderChange?: (reader: TerminalReader) => void;
  disableTerminalSelection?: boolean;
  readerError: ITerminalStrategyError | undefined;
  paymentSuccessful: boolean | undefined;
  isTerminalInUse: boolean;
};

export const CollectReaderPaymentDisplay = ({
  selectedReader,
  readerError,
  paymentSuccessful,
  isTerminalInUse,
  onCancelClick,
  onChangePaymentMethodClick,
  onCloseClick,
  onTryAgainClick,
  ReaderStatusOverrideComponent,
  availableReaders,
  onSelectedReaderChange,
  disableTerminalSelection = false,
}: CollectReaderPaymentDisplayProps) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const { invoice } = useSelectedInvoice();
  const { personEmail } = useInvoicePerson(invoice);
  const { paymentsUrl } = useMerchant();
  const [sendingReceipt, setSendingReceipt] = useState(false);

  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  const readerNameFieldProps = useFormField(
    {
      type: 'text',
      value: selectedReader?.label,
    },
    [selectedReader?.readerId]
  );

  const availableReaderProps = useFormField(
    {
      type: 'dropdown',
      value: selectedReader?.readerId,
    },
    [selectedReader?.readerId]
  );

  const emailReceiptFieldProps = useFormField({
    type: 'email',
    value: personEmail,
  });

  const handleSendReceipt = async () => {
    setSendingReceipt(true);
    try {
      if (!paymentsUrl || !invoice?.id) {
        throw new Error('Missing required fields');
      }
      await sendInvoiceReceipt(paymentsUrl, invoice.id, [emailReceiptFieldProps.value]);
      alerts.success(t('Receipt sent successfully'));
      onCloseClick();
    } catch (err) {
      if (err instanceof Error || err instanceof HttpError) console.error('Failed to send receipt', err.message);
      alerts.error(t('Failed to send receipt'));
    }
    setSendingReceipt(false);
  };

  useEffect(() => {
    if (availableReaderProps.value) {
      const reader = availableReaders?.find((reader) => reader.id === availableReaderProps.value);

      if (reader && reader?.id !== selectedReader?.readerId) {
        onSelectedReaderChange?.(reader);
      }
    }
  }, [availableReaderProps.value]);

  const sortedAvailableReaders = useMemo(() => sortReaders(availableReaders ?? []), [availableReaders]);

  return (
    <>
      <Modal.Body>
        <ContentLoader show={sendingReceipt} message={t('Sending receipt...')} />
        <section css={styles.section}>
          <div css={styles.paymentForm}>
            {sortedAvailableReaders?.length ? (
              <DropdownField
                name={'available-terminals'}
                label={t('Terminal')}
                disabled={disableTerminalSelection}
                {...availableReaderProps}
              >
                {sortedAvailableReaders.map(({ id, label, status }) => (
                  <DropdownField.Option key={id} value={id} disabled={status === 'offline'}>
                    {label}
                  </DropdownField.Option>
                ))}
              </DropdownField>
            ) : (
              selectedReader && <TextField name='reader-name' label='Terminal' {...readerNameFieldProps} readOnly />
            )}
            {ReaderStatusOverrideComponent}
          </div>
          {!isTerminalInUse && paymentSuccessful && <ReceiptSection emailFieldProps={emailReceiptFieldProps} />}
        </section>
      </Modal.Body>
      <Modal.Footer>
        {!isTerminalInUse &&
          (!paymentSuccessful ? (
            <div css={styles.buttonBar}>
              {readerError ? (
                <>
                  <Button variant='secondary' size='large' onClick={onChangePaymentMethodClick}>
                    {t('Change Payment Method')}
                  </Button>
                  <Button size='large' onClick={onTryAgainClick}>
                    {t('Try Again')}
                  </Button>
                </>
              ) : (
                <Button variant='secondary' size='large' onClick={onCancelClick}>
                  {t('Cancel')}
                </Button>
              )}
            </div>
          ) : (
            <div css={styles.receiptButtonBar}>
              <TextLink onClick={onCloseClick}>{t('Close without receipt')}</TextLink>
              <Button
                size='large'
                onClick={handleSendReceipt}
                disabled={!emailReceiptFieldProps.value || sendingReceipt}
                {...triggerProps}
              >
                {t('Send Receipt')}
              </Button>
              {!emailReceiptFieldProps.value && <Tooltip {...tooltipProps}>{t('Please enter an email')}</Tooltip>}
            </div>
          ))}
      </Modal.Footer>
    </>
  );
};
