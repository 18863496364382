import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { FaxQueries } from '@frontend/api-fax';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ErrorBadgeIcon, PlusIcon, Text } from '@frontend/design-system';

interface ContactImageProps {
  setUserProfile: (value: File) => void;
  currentMediaId: string;
  locationId: string;
}

export const ContactImage = ({ setUserProfile, currentMediaId, locationId }: ContactImageProps) => {
  const { t } = useTranslation('fax');
  const [profileImageSrc, setProfileImageSrc] = useState<string>();
  const { selectedLocationIds } = useAppScopeStore();
  const mainLocationId = selectedLocationIds[0];

  const { data: currentMediaSrc } = FaxQueries.useGetFaxContactMedia(currentMediaId, locationId ?? mainLocationId, {
    enabled: !!currentMediaId,
  });

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setProfileImageSrc(objectUrl);
    setUserProfile(event.target.files[0]);
  };

  const onProfileClick = () => {
    const inputFile = document.getElementById('hiddenFileInput');
    if (inputFile) {
      inputFile.click();
    }
  };

  const removeProfile = () => {
    setProfileImageSrc(undefined);
  };

  useEffect(() => {
    if (currentMediaSrc) {
      const url = URL.createObjectURL(currentMediaSrc);
      setProfileImageSrc(url);
    }
  }, [currentMediaSrc]);

  useEffect(() => {
    if (!currentMediaId) setProfileImageSrc(undefined);
  }, [currentMediaId]);

  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        margin: ${theme.spacing(1, 0, 3)};
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        {profileImageSrc ? (
          <div
            css={css`
              position: relative;
              height: ${theme.spacing(10)};
              width: ${theme.spacing(10)};
              cursor: pointer;
            `}
          >
            <img
              css={css`
                height: ${theme.spacing(10)};
                width: ${theme.spacing(10)};
                border-radius: ${theme.borderRadius.full};
              `}
              src={profileImageSrc}
              alt=''
            />
            <ErrorBadgeIcon
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
              `}
              size={20}
              color='default'
              onClick={() => removeProfile()}
            />
          </div>
        ) : (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: ${theme.spacing(10)};
              width: ${theme.spacing(10)};
              border-radius: ${theme.borderRadius.full};
              background-color: ${theme.colors.neutral30};
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
              cursor: pointer;
            `}
            onClick={onProfileClick}
          >
            <PlusIcon color='white' />
            <Text
              color='white'
              css={css`
                margin-top: ${theme.spacing(0.8)};
              `}
            >
              {t('Photo')}
            </Text>
          </div>
        )}
        <input
          css={css`
            display: none !important;
          `}
          type='file'
          accept='image/*'
          onChange={onFileChange}
          id='hiddenFileInput'
        />
      </div>
    </section>
  );
};
