import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const emailAssistantGradientStyling = css`
  background: linear-gradient(
    -90deg,
    ${theme.colors.secondary.eggplant20},
    ${theme.colors.primary20},
    ${theme.colors.secondary.eggplant20},
    ${theme.colors.primary20}
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: linear-gradient(
      -90deg,
      ${theme.colors.secondary.eggplant20},
      ${theme.colors.primary20},
      ${theme.colors.secondary.eggplant20},
      ${theme.colors.primary20}
    );
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
