import { useCallback } from 'react';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { PreviewStepNavigationProps } from '../preview.types';

export const PreviewGenericNavigation = ({ body: Body }: PreviewStepNavigationProps) => {
  const {
    multiStep: { goPrevStep, onCancel },
  } = useCollectPaymentMultiContext();

  const onGoBack = useCallback(() => {
    goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select });
  }, []);

  const onClickSecondary = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return <Body onGoBack={onGoBack} onClickSecondary={onClickSecondary} />;
};
