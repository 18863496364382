import { useMemo } from 'react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { Trans, useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps, Text, TextLink } from '@frontend/design-system';
import { getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { BundleFeatureEnum } from './constants';
import { getBundleWiseFeatureList } from './utils';

const MODAL_MAX_WIDTH = 550;

interface BundleTrialTermsModalProps extends ModalControlModalProps {
  bundle: SalesforceAccountProductBundle;
}

export const BundleTrialTermsModal = ({ bundle, ...modalProps }: BundleTrialTermsModalProps) => {
  const { t } = useTranslation('bundleUpgrade', { keyPrefix: 'terms-modal' });

  const { bundleName, hasCallIntel } = useMemo(() => {
    return {
      hasCallIntel: !!getBundleWiseFeatureList(bundle)?.includes(BundleFeatureEnum.CALL_INTEL),
      bundleName: t('{{bundleName}} Bundle', { bundleName: getUpgradeBundleDisplayName(bundle) }),
    };
  }, [bundle]);

  return (
    <Modal {...modalProps} maxWidth={MODAL_MAX_WIDTH}>
      <Modal.Header onClose={modalProps.onClose}>{t('Terms and Conditions')}</Modal.Header>

      <Modal.Body>
        <Text>{t('You are at least 18 years of age.')}</Text>
        <Text>{t('Your Weave account is in good standing.')}</Text>

        <Text>
          <Trans t={t}>
            {{ bundleName }} is provided subject to{' '}
            <TextLink rel='noopener noreferrer' target='_blank' href='https://www.getweave.com/legal/terms/'>
              Weave's Terms of Service
            </TextLink>{' '}
            which are incorporated herein by reference and govern your use of the Weave {{ bundleName }}.
          </Trans>
        </Text>

        <Text>
          {t(
            "By accessing or otherwise using {{bundleName}} you expressly acknowledge agree to Weave's Terms of Service.",
            { bundleName }
          )}
        </Text>

        {hasCallIntel && (
          <Text>
            {t(
              'You acknowledge that all calls may be recorded automatically by activating the Feature and you understand that it is your responsibility to comply with all laws and regulations related to call recording.'
            )}
          </Text>
        )}

        <Text>
          <Trans t={t}>
            You represent and warrant that in conjunction with any and all of Weave's products and services, you have
            reviewed the applicable terms found{' '}
            <TextLink rel='noopener noreferrer' target='_blank' href='https://www.getweave.com/legal/terms/'>
              here
            </TextLink>{' '}
            and you agree to comply with the applicable laws and regulations. Weave reserves the right to suspend your
            account if abuse is reported or if you use the service in a way that otherwise violates Weave's Terms of
            Service.
          </Trans>
        </Text>
      </Modal.Body>
      <Modal.Footer
        secondary={{
          onClick: modalProps.onClose,
          label: t('Back'),
        }}
      />
    </Modal>
  );
};
