import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { TextLink, useTooltip } from '@frontend/design-system';
import { usePaymentsPromotion } from '../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds, PaymentActionTrackingIds } from '../tracking-ids';
import { PaymentActionEnum } from '../types';

export const useCreateAccountTooltip = () => {
  const { Tooltip, tooltipProps, ...props } = useTooltip();
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });
  const { handleAction, hasAccountCreationPermission, source } = usePaymentsPromotion();

  const accountCreateAction = hasAccountCreationPermission
    ? PaymentActionEnum.CREATE_ACCOUNT
    : PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE;

  const tooltipRender = useMemo(() => {
    return (
      <Tooltip {...tooltipProps}>
        {t('You must set up a payments account first.')}{' '}
        <TextLink
          as='span'
          weight='regular'
          onClick={() => handleAction(accountCreateAction)}
          trackingId={getPaymentHVATrackingIds({
            source,
            trackingId: PaymentActionTrackingIds[accountCreateAction],
          })}
        >
          {hasAccountCreationPermission ? t('Create Account') : t('Learn More')}
        </TextLink>
      </Tooltip>
    );
  }, [hasAccountCreationPermission, tooltipProps, handleAction]);

  return {
    ...props,
    tooltipRender,
  };
};
