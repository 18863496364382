import { useMemo } from 'react';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { TableColumnConfig } from '@frontend/design-system';
import { GetLocationName, PaymentsTableInstances } from '../utils';

export type GenerateColumnsFn<T extends NonNullable<unknown>> = (
  getLocationName: GetLocationName,
  showLocationName: boolean,
  forPrint?: boolean
) => TableColumnConfig<T>[];

const getFilteredColumns = (tableInstanceId: PaymentsTableInstances): string[] | undefined => {
  try {
    return JSON.parse(localStorage.getItem(`weave-table-${tableInstanceId}`) ?? '');
  } catch {
    return;
  }
};

export const useGenerateColumns = <T extends NonNullable<unknown>>(
  generateColumns: GenerateColumnsFn<T>,
  forPrint = false,
  tableInstanceType?: PaymentsTableInstances,
  options?: { hideLocationNameColumn?: boolean }
) => {
  const { getLocationName, isMultiLocationFeature } = useMultiQueryUtils();

  const showLocationName = options?.hideLocationNameColumn ? !options?.hideLocationNameColumn : isMultiLocationFeature;
  const columns = useMemo(() => {
    let columns = generateColumns(getLocationName, showLocationName, forPrint);
    if (tableInstanceType && forPrint) {
      const columnsFilteredOut = getFilteredColumns(tableInstanceType);
      //@ts-ignore
      columns = columns.filter((column) => !columnsFilteredOut?.includes(column.id));
    }
    return columns;
  }, [isMultiLocationFeature, generateColumns]);

  return columns;
};
