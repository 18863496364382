import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const groupMaxWidth = css`
  max-width: ${theme.spacing(90)};
  min-width: ${theme.spacing(20)};
  width: 100%;
`;

export const publicInformationLoader = css`
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const form = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const formWithSections = css`
  gap: ${theme.spacing(2)};
`;

export const logoUploader = (isDragging: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: ${theme.borderRadius.small};
  border: dashed ${!isDragging ? theme.colors.neutral30 : theme.colors.primary50} 2px;
  background-color: ${theme.colors.neutral5};
  cursor: pointer;
  display: block;

  img {
    width: 100%;
  }
`;

export const uploadedImageContainer = css`
  display: block;
  margin: auto;
  text-align: center;
`;

export const noImageContainer = css`
  padding: ${theme.spacing(3)};
  p {
    margin: 0;
  }
  p:last-child {
    margin-top: ${theme.spacing(1)};
  }
`;

export const formGroupLabel = css`
  margin: ${theme.spacing(0, 0, 2)};
`;

export const formRow = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: ${theme.spacing(3)};
`;

export const marginLeft = css`
  margin-left: ${theme.spacing(1)};
  svg {
    margin-bottom: 6px;
  }
`;

export const submitButton = css`
  margin: 0 auto;
`;

export const ffToggleContainer = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing(1, 3)};
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const formMaxWidth = css`
  max-width: ${theme.spacing(50)};
  min-width: ${theme.spacing(20)};
  width: 100%;
`;

export const ffToggle = css`
  display: flex;
  align-items: center;
  ${formMaxWidth}
`;

export const ffToggleLine = css`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ffToggleInfo = css`
  padding: ${theme.spacing(1)};
  color: ${theme.colors.neutral70};
`;

export const ffSwitch = css`
  font-size: ${theme.font.size.h2};
  width: 100%;
`;

export const marginBottom = css`
  margin-bottom: ${theme.spacing(2)};
`;

export const confirmModalSmallText = css`
  color: ${theme.colors.neutral50};
`;

export const sectionHeading = css`
  margin-bottom: ${theme.spacing(1)};
`;
