import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { PaymentsSetup } from '../../NoInvoices';

const styles = {
  subtext: css`
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `,
};

export const CreateMerchant = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { stripeCreatedNotOnboarded } = useMerchant();
  return (
    <div>
      <Heading level={3}>{t('No current Stripe account, would you like to create one?')}</Heading>
      <Text css={styles.subtext}>
        {t(
          'No credit checks required. We just need some basic information to verify your business. You will be redirected to Stripe to create your account.'
        )}
      </Text>
      <PaymentsSetup isSettingsPage refetchQueries stripeCreatedNotOnboarded={stripeCreatedNotOnboarded} />
    </div>
  );
};
