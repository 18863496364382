import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

type UseGetPractitionersV3DataParams = {
  selectedLocationId: string;
  selectedLocationIds?: string[];
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
  showHiddenOnCalendar?: boolean;
  isEnabled?: boolean;
};

export const useGetPractitionersV3Data = ({
  selectedLocationId,
  selectedLocationIds,
  debouncedSearchValue = '',
  pageConfig,
  showHiddenOnCalendar = true,
  isEnabled = true,
}: UseGetPractitionersV3DataParams) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;
  const request: SchedulerV3.Types.ListProvidersIO['input'] = {
    locationId: selectedLocationId,
    ...(pageConfig?.limit && { limit: pageConfig?.limit }),
    ...(pageConfig?.page && { page: pageConfig?.page }),
    ...(debouncedSearchValue && { name: debouncedSearchValue }),
    ...(showHiddenOnCalendar && { showHidden: showHiddenOnCalendar }),
  };

  const singleLocationGetPractitionerQuery = SchedulerV3.Queries.useListProvidersQuery({
    request: request,
    options: {
      enabled: !isMultiLocationApiCall && isEnabled && !!request.locationId,
    },
  });

  const requestForMulti: SchedulerV3.Types.ListProvidersMultiIO['input'] = {
    ...(pageConfig?.limit && { limit: pageConfig?.limit }),
    ...(pageConfig?.page && { page: pageConfig?.page }),
    ...(debouncedSearchValue && { name: debouncedSearchValue }),
    ...(showHiddenOnCalendar && { showHidden: showHiddenOnCalendar }),
    locationIds: selectedLocationIds || (selectedLocationId ? [selectedLocationId] : []),
    parentLocationId,
  };

  const multiLocationGetPractitionerQuery = SchedulerV3.Queries.useListProvidersMultiQuery({
    request: requestForMulti,
    options: {
      enabled:
        isMultiLocationApiCall &&
        isEnabled &&
        !!requestForMulti.parentLocationId &&
        !!requestForMulti.locationIds.length,
    },
  });

  return isMultiLocationApiCall ? multiLocationGetPractitionerQuery : singleLocationGetPractitionerQuery;
};
