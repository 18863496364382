import { useCallback, useEffect } from 'react';
import { useLocation } from '@tanstack/react-location';
import { getUser } from '@frontend/auth-helpers';
import { useScopedAppFlagStore } from '@frontend/scope';
import { shell, useIpcShellProvider } from '@frontend/shell-utils';
import { sentry, pendo } from '@frontend/tracking';
import type { WeavePopNotification, NotificationActions, PreferenceType } from '@frontend/types';
import { useNotificationPreferencesStore } from './notification-preferences/store';
import { useNotificationPreferences } from './notification-preferences/use-notification-preferences';
import { usePreferenceActions } from './notification-preferences/use-preference-actions';
import { useNotificationContext } from './notification-provider';
import { useNotificationSettingsShallowStore } from './notification-settings-store';
import { TransientQueue, useTransientQueue } from './transient-queue';

export type PreferenceNotification = WeavePopNotification & {
  id: string;
  payload: {
    title: string;
    body: string;
    notificationType: WeavePopNotification['type'];
  };
  actions: NotificationActions;
  preferenceType: PreferenceType;
};

const processPreferenceRule = (
  notification: WeavePopNotification,
  context: { pathname: string },
  queue: WeavePopNotification[],
  notificationPreferencesStore: ReturnType<typeof useNotificationPreferencesStore>,
  processNotification: (notification: WeavePopNotification) => void
) => {
  const preferenceRule = notificationPreferencesStore.getState().getPreferenceToShow(notification, context);
  const hasSamePreference = queue.find((q) => q?.id === preferenceRule?.id);

  if (preferenceRule && !hasSamePreference) {
    const preferenceNotification: WeavePopNotification = {
      id: preferenceRule.id,
      timestamp: Date.now(),
      type: 'preference',
      preferenceType: preferenceRule.type,
      payload: {
        ...notification.payload,
        notificationType: notification.type,
        title: typeof preferenceRule.title === 'string' ? preferenceRule.title : preferenceRule.title(notification),
        body: typeof preferenceRule.body === 'string' ? preferenceRule.body : preferenceRule.body(notification),
      },
      state: {
        paused: false,
        timeout: 0,
        status: 'unread',
      },
    };

    processNotification(preferenceNotification);
  }
};

export const useNotificationQueue = () => {
  const { status } = useIpcShellProvider();
  const location = useLocation();
  const notificationPreferencesStore = useNotificationPreferencesStore();
  const { notificationTrayIsOpen } = useNotificationContext();
  const { notificationSettings, notificationSounds } = useNotificationSettingsShallowStore(
    'notificationSettings',
    'notificationSounds'
  );
  const { queue, add, remove, update } = useTransientQueue();
  const isWebBrowser = !shell.isShell;

  const removeItem = useCallback(
    (id: string) => {
      if (shell.isShell && status === 'connected') {
        shell.emit?.('notification:hide', id);
      } else {
        remove(id);
      }
    },
    [remove, shell, status]
  );

  const updateItem = useCallback(
    (item: WeavePopNotification) => {
      if (shell.isShell && status === 'connected') {
        shell.emit?.('notification:update', { notification: item });
      } else {
        update(item);
      }
    },
    [shell, update, status]
  );

  const processNotification = useCallback(
    (notification: WeavePopNotification, allowDuplicates = false, providedSoundId?: string) => {
      if (!allowDuplicates && queue.find((q) => q.id === notification.id)) {
        return;
      }

      if (notificationTrayIsOpen && isWebBrowser && status !== 'connected' && !(notification as any).isPreference) {
        return;
      }

      const soundId = providedSoundId ?? notificationSounds[notification.type];
      if (soundId) {
        const sound = document.getElementById(soundId) as HTMLAudioElement;
        if (sound) {
          sound.volume = 0.5;
          sound?.play();
        }
      }

      pendo.track('add-notification-item', {
        networkOnline: navigator.onLine,
        visitorId: getUser()?.userID || '',
        locationId: notification.location || '',
        notificationType: notification.type,
        isPreference: !!(notification as any).isPreference,
        sound: soundId,
        userAgent: navigator.userAgent,
        location: window.location.href,
      });

      if (shell.isShell && status === 'connected') {
        return shell.emit?.('notification:show', { notification });
      }

      add({ ...notification });
    },
    [add, isWebBrowser, notificationSounds, notificationTrayIsOpen, queue, shell, status]
  );

  const addItem = useCallback(
    (notification: WeavePopNotification, allowDuplicates = false, soundId?: string) => {
      const context = { pathname: location.current.pathname };
      if (notificationPreferencesStore.getState().shouldSuppressNotification(notification, context)) {
        return;
      }

      processNotification(notification, allowDuplicates, soundId);

      processPreferenceRule(notification, context, queue, notificationPreferencesStore, processNotification);
    },
    [location, processNotification, removeItem, notificationPreferencesStore, queue]
  );

  const getItem = useCallback(
    (id: string) => {
      return queue.find((q) => q.id === id);
    },
    [queue]
  );

  return {
    queue,
    add: addItem,
    remove: removeItem,
    get: getItem,
    update: updateItem,
    placement: notificationSettings.placement as any,
  };
};

export const NotificationQueue = () => {
  const { status } = useIpcShellProvider();
  const { remove: removeDirectly } = useTransientQueue();
  const { queue, add, placement } = useNotificationQueue();
  usePreferenceActions();

  useEffect(() => {
    if (!('Notification' in window) || !Notification.requestPermission) {
      console.log('This browser does not support desktop notification');
      return;
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  }, []);

  /**
   * Checks IPC state, and if it goes from disconnected to connected, removes current notifications and sends all the notifications through ipc
   */
  useEffect(() => {
    if (status === 'connected') {
      queue.forEach((notification) => {
        removeDirectly(notification.id);
      });
      queue.forEach((notification) => {
        add(notification, true);
      });
      if (queue.length > 0) {
        sentry.log({ message: `IPC Connection status changed ${status} while we had notifications in the queue!` });
      }
    }
  }, [status, shell, add]);

  return (
    <>
      <TransientQueue placement={placement} />
      <PreferenceNotifications />
    </>
  );
};

const PreferenceNotifications = () => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasPreferenceNotifications = getFeatureFlagValue('pop-notification-preferences');

  if (hasPreferenceNotifications) {
    return <PreferenceNotificationsSubscriptions />;
  }

  return null;
};

const PreferenceNotificationsSubscriptions = () => {
  useNotificationPreferences();

  return null;
};
