import { demoDataUtils } from '../../../../utils';
import { CategorizedConversions, TimelineResponse } from '../hooks';
import { MCTRoiConversionType } from '../types';

// Translations not required for demo data
const conversionTypes: MCTRoiConversionType[] = ['Same Day', '7 Days', '14 Days', '21 Days', '28 Days'];
const weeks = ['Jan 1-Jan 7', 'Jan 8-Jan 14', 'Jan 15-Jan 21', 'Jan 22-Jan 28', 'Jan 29-Jan 31'];

export const detailedConversionCategorized: CategorizedConversions = conversionTypes.map((conversion_type, index) => {
  const start = 5;

  return {
    conversion_type,
    count: start + index * 3,
  };
});

export const detailedConversionTimeline: TimelineResponse = {
  conversion_details: conversionTypes.map((conversion_type) => ({
    conversion_type,
    data: weeks.map((name) => ({
      name,
      count: demoDataUtils.generateRandomInt(0, 10),
    })),
  })),
};
