import { useMemo } from 'react';
import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { Provider, Workstation } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { AppointmentTypesApi, AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleApi } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { ServiceProvidersApi, ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import {
  useGetAppointmentTypesV3Data,
  useGetOperatoryV3Data,
  useGetPractitionersV3Data,
  useSchedulingLocationInfo,
} from '../../../../../../hooks';
import { transformV3ResponsesForReviewRequest } from '../../helpers';
import { getWorkstationId } from '../utils';

const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

/**
 * Reduced Types are only required fields for the review request form
 */
export type ReducedAppointmentType = {
  id: string;
  durationMinutes: number;
  name: string;
  sourceId: string;
  externalId?: string;
  amount?: number;
};

export type ReducedPractitioner = {
  id: string;
  firstName?: string;
  lastName?: string;
  sourceId: string;
  publicDisplayName?: string;
  calendarId?: string;
  externalId?: string;
};

export type ReducedOperatory = {
  id: string;
  workstationName: string;
  sourceId: string;
  externalId?: string;
};

export type ReviewRequestReturnType = {
  lists: {
    appointmentTypes: AppointmentTypesTypes.AppointmentType[] | ReducedAppointmentType[];
    providers: Provider[] | ReducedPractitioner[];
    workstations: Workstation[] | ReducedOperatory[];
  };
  selected: {
    appointmentId: string;
    providerId: string;
    workstationId: string;
  };
  isLoading: boolean;
};

export const useReviewRequest = (reviewDetails: ScheduleRequest): ReviewRequestReturnType => {
  const { isScheduleV3FlagEnabled: isV3Enabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const workstationAssets = reviewDetails.requestedOpenings?.[0].assets?.operatories ?? [];
  const providerAssets = reviewDetails.requestedOpenings?.[0].assets?.providers ?? [];
  const locationId = reviewDetails.locationId ?? '';

  const { data: appointmentTypesData, isLoading: isAppointmentTypesLoading } = useQuery({
    queryKey: ['appointmentTypes', locationId],
    queryFn: () => {
      return AppointmentTypesApi.getAppointmentTypes(locationId);
    },
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS,
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS,
    enabled: !!locationId && !isV3Enabled,
  });

  const { data: providersData, isLoading: isProviderLoading } = useLocalizedQuery({
    queryKey: ['scheduleProvidersList', locationId],
    queryFn: () => ScheduleApi.getProvidersListForMultipleLocationIds({ locationIds: [locationId] }),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS,
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS,
    enabled: !!locationId && !isV3Enabled,
  });

  const { data: workstationsData, isLoading: isWorkstationsLoading } = useQuery({
    queryKey: ['workstations', locationId],
    queryFn: async () => await ServiceProvidersApi.getWorkstations({ locationId: locationId }),
    enabled: !!locationId && !isV3Enabled,
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
  });

  // V3 data
  const { selectedLocationIds } = useSchedulingLocationInfo();
  const selectedLocationId = locationId || selectedLocationIds[0];

  const { data: appointmentTypesDataV3, isLoading: isAppointmentTypesLoadingV3 } = useGetAppointmentTypesV3Data({
    selectedLocationId,
    selectedLocationIds,
    isEnabled: isV3Enabled,
  });

  const { data: practitionerData, isLoading: isPractitionerLoading } = useGetPractitionersV3Data({
    selectedLocationId,
    selectedLocationIds,
    isEnabled: isV3Enabled,
    showHiddenOnCalendar: true,
  });

  const { data: operatories, isLoading: isOperatoriesLoading } = useGetOperatoryV3Data({
    selectedLocationId,
    selectedLocationIds,
    isEnabled: isV3Enabled,
  });

  const { transformedAppointmentTypes, transformedPractitioners, transformedOperatories } =
    transformV3ResponsesForReviewRequest({
      appointmentTypesDataV3: appointmentTypesDataV3?.appointmentTypes,
      practitionerDataV3: practitionerData?.providers,
      operatoriesDataV3: operatories?.operatories,
    });

  const appointmentTypes = isV3Enabled ? transformedAppointmentTypes : appointmentTypesData;
  const providers = isV3Enabled ? transformedPractitioners : providersData?.providers;
  const workstations = isV3Enabled ? transformedOperatories : workstationsData?.workstations;

  const appointmentTypeId = useMemo(() => {
    if (!!appointmentTypes?.length) {
      return appointmentTypes.find((apptType) => apptType.name === reviewDetails?.appointmentType)?.id ?? '';
    } else {
      return '';
    }
  }, [appointmentTypes, reviewDetails?.appointmentType]);

  const providerId = useMemo(() => {
    const providerName = providerAssets?.toString();
    if (!!providerName && !!providers?.length) {
      return (
        providers.find(
          (provider) =>
            providerName === `${provider.publicDisplayName}` ||
            providerName === `${provider.firstName} ${provider.lastName}`
        )?.id ?? ''
      );
    } else {
      return '';
    }
  }, [providerAssets?.toString(), providers]);

  const workstationId = useMemo(
    () => getWorkstationId(workstationAssets, workstations ?? []),
    [workstationAssets?.toString(), workstations]
  );

  return {
    lists: {
      appointmentTypes: appointmentTypes ?? [],
      providers: providers ?? [],
      workstations: workstations ?? [],
    },
    selected: {
      appointmentId: isV3Enabled ? reviewDetails.appointmentTypeId ?? '' : appointmentTypeId,
      providerId: isV3Enabled ? reviewDetails.practitionerId ?? '' : providerId,
      workstationId,
    },
    isLoading:
      isAppointmentTypesLoading ||
      isAppointmentTypesLoadingV3 ||
      isProviderLoading ||
      isPractitionerLoading ||
      isWorkstationsLoading ||
      isOperatoriesLoading,
  };
};
