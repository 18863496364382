import { InfiniteData, QueryClient } from 'react-query';
import { InvoiceModel, InvoiceResponse } from './';

export const invoiceCacheOptions = {
  staleTime: 60 * 1000,
  cacheTime: 2 * 60 * 1000,
  refetchOnMount: true,
  refetchOnWindowFocus: 'always' as const,
  refetchOnReconnect: true,
};

export const invoiceDetailsCacheOptions = {
  staleTime: 1000,
  cacheTime: 2 * 60 * 1000,
  refetchOnMount: true,
  refetchOnWindowFocus: 'always' as const,
  refetchOnReconnect: 'always' as const,
};

export const updateInvoiceQueryCache = (queryClient: QueryClient, newInvoice: InvoiceModel, queryKey: string[]) => {
  queryClient
    .getQueryCache()
    .findAll({
      predicate: (query) => query.queryKey.includes('invoices'),
    })
    .forEach((query) =>
      query.setData((oldData: InfiniteData<InvoiceResponse>) => {
        // All we want to modify here is the invoices
        return {
          ...oldData,
          pages: oldData?.pages?.map((page) => {
            return {
              ...page,
              data: {
                ...page.data,
                invoices:
                  page?.data?.invoices?.map((cachedInvoice) =>
                    /* since we are adding payout descriptor to the invoice list items, we are merging 
                    the newInvoice details with the one already in the cache to preserve the descriptors. */
                    cachedInvoice.id === newInvoice.id ? { ...cachedInvoice, ...newInvoice } : cachedInvoice
                  ) ?? [],
              },
            };
          }),
        };
      })
    );

  queryClient.setQueryData(queryKey, newInvoice);
};

export const cancelInvoiceQueryCache = (queryClient: QueryClient, newInvoice: InvoiceModel, queryKey: string[]) => {
  queryClient
    .getQueryCache()
    .findAll({
      predicate: (query) => query.queryKey.includes('invoices'),
    })
    .forEach((query) =>
      query.setData((oldData: InfiniteData<InvoiceResponse>) => {
        // All we want to modify here is the invoices
        return {
          ...oldData,
          pages: oldData?.pages?.map((page) => {
            return {
              ...page,
              data: {
                ...page.data,
                invoices: page?.data?.invoices?.filter((cachedInvoice) => cachedInvoice.id !== newInvoice.id) ?? [],
              },
            };
          }),
        };
      })
    );

  queryClient.setQueryData(queryKey, newInvoice);
};
