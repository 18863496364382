import { useCollectPaymentMultiContext } from '../../collect-payment-multi.context';
import { CollectPaymentMultiSteps } from '../../collect-payment-multi.types';

export type StepPerformanceWrapperProps = React.PropsWithChildren<{
  step: CollectPaymentMultiSteps;
}>;
export const StepPerformanceWrapper = ({ children, step }: StepPerformanceWrapperProps) => {
  const {
    multiStep: { activeStep },
  } = useCollectPaymentMultiContext();

  return activeStep === step ? <>{children}</> : null;
};
