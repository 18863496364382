import { useQueryClient } from 'react-query';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { convertToCents } from '@frontend/currency';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import {
  CollectPaymentModalBody,
  CollectPaymentModalSteps,
  useCollectPaymentModal,
} from '@frontend/payments-collection-flow';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';
import { useInvoiceShallowStore, useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import { MultiStepModal, PaymentsMultiStepModal } from '@frontend/payments-multistep-modal';
import {
  CreateInvoiceForm,
  DropZoneOverlay,
  shouldShowModal,
  ThresholdModals,
  useCreateInvoiceForm,
  useCreatePaymentRequest,
} from '@frontend/person-invoice-create';
import { PersonInvoiceModalSteps } from '@frontend/person-invoice-payment';
import { useAppScopeStore } from '@frontend/scope';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, useModalControl } from '@frontend/design-system';

type PaymentModalProps = ModalControlModalProps;

/**
 * @deprecated Use CollectPaymentMultiModalInstance or one of it's variants, which support multiple processors.
 */
export const CreateAndCollectModal = ({ ...rest }: PaymentModalProps) => {
  const { t } = useTranslation('payments');
  const { selectedLocationIds } = useAppScopeStore();
  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: selectedLocationIds,
  });

  return (
    <PaymentsMultiStepModal
      modalProps={rest}
      closeModal={rest.onClose}
      initialStep={PersonInvoiceModalSteps.CreateInvoice}
      css={{ maxHeight: 750 }}
      maxWidth={parseInt(theme.spacing(82))}
      data-trackingid='create-and-collect-modal'
    >
      <MultiStepModal.Step
        id={PersonInvoiceModalSteps.CreateInvoice}
        title={newRequestUI ? t('Creating Payment Request') : t('Create Invoice')}
        blueHeader={newRequestUI}
      >
        <CreateInvoice />
      </MultiStepModal.Step>
      <CollectPaymentModalBody />
    </PaymentsMultiStepModal>
  );
};

const CreateInvoice = () => {
  const { t } = useTranslation('payments');
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const queryClient = useQueryClient();
  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const {
    createFormProps,
    dropZoneProps,
    resetFields,
    attachment,
    selectedPerson: V2SelectedPerson,
    skipAttachmentAuth,
  } = useCreateInvoiceForm();
  const {
    form: { values, isComplete },
  } = createFormProps;
  const { selectedLocationIds } = useAppScopeStore();
  const { createPaymentRequest, creatingPaymentRequest } = useCreatePaymentRequest({ locationId: values.locationId });
  const { goToStep: goToCollectionStep } = useCollectPaymentModal();
  const { modalProps: thresholdModalProps, triggerProps: thresholdTriggerProps } = useModalControl();
  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: selectedLocationIds,
  });

  const handleCreateInvoice = async () => {
    await createPaymentRequest({
      personId: values.personId!,
      amount: convertToCents(Number(values.amount)),
      attachment,
      mobileNumber: V2SelectedPerson?.MobilePhone,
      memo: values.memo,
      providerName: values.provider,
      skipAttachmentAuth,
    }).then((invoice) => {
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(values.amount),
      });
      setSelectedInvoiceId(invoice.id);
      goToCollectionStep(CollectPaymentModalSteps.PaymentFlowList);
      queryClient.invalidateQueries(invoicesQueryKey);
    });
  };

  return (
    <>
      <Modal.Body>
        <DropZoneOverlay {...dropZoneProps}>
          <CreateInvoiceForm {...createFormProps} />
        </DropZoneOverlay>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: newRequestUI ? t('Collect Payment') : t('Create Invoice'),
          iconName: newRequestUI ? 'pay-small' : undefined,
          onClick: shouldShowModal(createFormProps.form.values.amount)
            ? thresholdTriggerProps.onClick
            : handleCreateInvoice,
          disabled: !isComplete || creatingPaymentRequest,
        }}
        secondary={{
          label: t('Cancel'),
          onClick: () => {
            resetFields();
            return true;
          },
        }}
      />
      <ThresholdModals
        {...thresholdModalProps}
        amount={createFormProps.form.values.amount}
        onConfirm={handleCreateInvoice}
      />
    </>
  );
};
