import { useMemo } from 'react';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import { TagsV2 } from '@frontend/api-tag';
import { useAppScopeStore } from '@frontend/scope';
import { RecentCallRecords } from '../components/all-calls/types';

export const useTags = () => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const { data: tagData, isLoading } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: selectedLocationIds },
  });
  const mappedTags = useMemo(() => {
    return (
      tagData?.tags.reduce((acc, tag) => {
        acc[tag.id] = tag;
        return acc;
      }, {} as Record<string, (typeof tagData)['tags'][number]>) ?? {}
    );
  }, [tagData]);

  return { mappedTags, isLoading };
};

const optimisticApplyTag = ({
  oldData,
  tag,
  channelId,
}: {
  oldData: InfiniteData<RecentCallRecords> | undefined;
  tag: Tag;
  channelId: string;
}) => {
  if (oldData) {
    return {
      pageParams: oldData.pageParams,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((row) => {
            if (row.channelId === channelId) {
              return {
                ...row,
                tags: [...row.tags, tag],
              };
            }
            return row;
          }),
        };
      }),
    };
  }
  return undefined;
};

const optimisticRemoveTag = ({
  oldData,
  tag,
  channelId,
}: {
  oldData?: InfiniteData<RecentCallRecords>;
  tag: Tag;
  channelId: string;
}) => {
  if (oldData) {
    return {
      pageParams: oldData.pageParams,
      pages: oldData?.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((row) => {
            if (row.channelId === channelId) {
              return {
                ...row,
                tags: row.tags.filter((tg) => tg.id !== tag.id),
              };
            }
            return row;
          }),
        };
      }),
    };
  }
  return undefined;
};

export const useRecentCallsTagMutation = ({
  queryKey,
  channelId,
  callStartedAt,
  callRecordLocationId,
  onSuccess,
  onFailure,
}: {
  queryKey: string[];
  channelId: string;
  callStartedAt: string;
  callRecordLocationId: string;
  onSuccess?: (args: { tag: Tag; op: 'remove' | 'add' }) => void;
  onFailure?: (args: { tag: Tag; op: 'remove' | 'add' }) => void;
}) => {
  const { selectedLocationIds } = useAppScopeStore();

  const qc = useQueryClient();

  const { mutate: applyTag } = useMutation({
    mutationFn: async (tag: Tag) => {
      return PhoneCallsApi.applyCallRecordTags({
        channelId,
        tagIds: [tag.id],
        callRecordLocationId,
        startTime: callStartedAt,
      });
    },
    onMutate: async (tag: Tag) => {
      const oldData = qc.getQueryData<InfiniteData<RecentCallRecords> | undefined>([selectedLocationIds, ...queryKey]);
      qc.setQueryData<InfiniteData<RecentCallRecords> | undefined>([selectedLocationIds, ...queryKey], (oldData) => {
        return optimisticApplyTag({ oldData, tag, channelId });
      });
      return { oldData };
    },
    onError: (_err, tag, context) => {
      if (context?.oldData) {
        // if the context fails, revert the change with the oldData without new tag
        qc.setQueryData<InfiniteData<RecentCallRecords> | undefined>(
          [selectedLocationIds, ...queryKey],
          context.oldData
        );
        onFailure?.({ tag, op: 'add' });
      }
    },
    onSuccess: (_, tag) => {
      onSuccess?.({ tag, op: 'add' });
    },
  });

  const { mutate: removeTag } = useMutation({
    mutationFn: async (tag: Tag) => {
      return PhoneCallsApi.removeCallRecordTags({
        channelId,
        tagIds: [tag.id],
        callRecordLocationId,
        startTime: callStartedAt,
      });
    },
    onMutate: async (tag: Tag) => {
      const oldData = qc.getQueryData<InfiniteData<RecentCallRecords> | undefined>([selectedLocationIds, ...queryKey]);
      qc.setQueryData<InfiniteData<RecentCallRecords> | undefined>([selectedLocationIds, ...queryKey], (oldData) => {
        return optimisticRemoveTag({ oldData, tag, channelId });
      });
      return { oldData };
    },
    onError: (_err, tag, context) => {
      if (context?.oldData) {
        qc.setQueryData<InfiniteData<RecentCallRecords> | undefined>(
          [selectedLocationIds, ...queryKey],
          context.oldData
        );
        onFailure?.({ tag, op: 'remove' });
      }
    },
    onSuccess: (_, tag) => {
      onSuccess?.({ tag, op: 'remove' });
    },
  });

  return { applyTag, removeTag };
};
