import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ChipCombobox, Text, TextField, useAlert, useFileUpload } from '@frontend/design-system';
import { useUploadMedia } from '../hooks/useUploadMedia';
import { SendingMediaItem, ToFieldType } from '../types';
import { BodyField } from './body-field';
import { ToField } from './to-field';

type EmailAreaProps = {
  locationId: string;
  getFieldProps: (field: any) => any;
  person?: Person;
  setAttachments: (attachments: SendingMediaItem[]) => void;
  setToField: (toField: ToFieldType) => void;
  toField: ToFieldType;
};
export const EmailArea = ({
  locationId,
  getFieldProps,
  person,
  setAttachments,
  setToField,
  toField,
}: EmailAreaProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const bodyFieldProps = getFieldProps('body');

  const { files, uploadFiles, removeMediaItem } = useUploadMedia({
    onChange: setAttachments,
    groupId: locationId,
    maxFileCount: 10,
    onError: () => {
      alert.error(t('Error uploading. Please try again.'));
    },
    onExceedMaxFileCount: (excessCount) => {
      alert.error(
        t('You can only send up to 10 files in a single message. {{count}} files were not uploaded.', {
          count: excessCount,
        })
      );
    },
  });

  const fileUploadProps = useFileUpload({
    onFileUpload: uploadFiles,
    keepPreviouslySelected: false,
  });

  return (
    <>
      <div
        className='email-area-selection'
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          padding: theme.spacing(0, 2, 1, 2),
        }}
      >
        <ToField
          locationId={locationId}
          person={person}
          value={toField.value}
          onChange={(person: Person[]) => {
            if (!person.length) {
              setToField({ value: person, error: true });
            } else {
              setToField({ value: person, error: false });
            }
          }}
          error={toField.error}
        />
        <ChipCombobox
          css={{
            pointerEvents: 'none',
            border: `1px solid ${theme.colors.neutral30}`,
          }}
          clearable={false}
          tags={[getFieldProps('from').value]}
          options={[]}
          startAdornment={
            <Text color='light' css={{ marginRight: theme.spacing(1) }}>
              {t('From:')}
            </Text>
          }
          ChipComponent={({ children }) => <Text>{children}</Text>}
          name='from'
          label=''
        />
      </div>
      <div
        css={{
          padding: theme.spacing(2),
          borderTop: `1px solid ${theme.colors.neutral20}`,
        }}
      >
        <TextField
          {...getFieldProps('subject')}
          label=''
          data-trackingid={`subject-field`}
          css={{
            position: 'relative',
          }}
          hideErrorText
        ></TextField>
      </div>
      <BodyField
        fileUploadProps={fileUploadProps}
        value={bodyFieldProps.value}
        onChange={(val) => {
          bodyFieldProps.onChange({ name: bodyFieldProps.name, value: val });
        }}
        attachments={files}
        removeAttachment={removeMediaItem}
      />
    </>
  );
};
