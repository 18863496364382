import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useFeatureSubscriptionConfig } from './useFeatureSubscriptionConfig';

interface UseWivTeaserHandlerProps {
  locationIds: string[];
}
export const useWivTeaserHandler = ({ locationIds }: UseWivTeaserHandlerProps) => {
  const {
    isUpgradeEligible,
    isFreeTrialEligible,
    isFeatureActiveInAllLocations: isWivActive,
    isLoading,
  } = useFeatureSubscriptionConfig({
    locationIds,
    feature: Feature.WEAVE_VERIFY,
    checkBundleUpgrade: false,
  });

  return {
    isLoading,
    showWeaveVerify: isWivActive,
    showWeaveVerifyPromotion: !isWivActive && (isUpgradeEligible || isFreeTrialEligible),
  };
};
