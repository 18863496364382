import { FormEventHandler } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  onClick: FormEventHandler;
}

export const SubmitButton = ({ disabled, onClick, ...rest }: Props) => {
  // Technically, it should be an IconButton, but with that it needs a lot of style override.
  return (
    <Button
      variant='primary'
      css={css`
        gap: ${theme.spacing(1)};
        svg {
          margin-left: 0;
        }
      `}
      disabled={disabled}
      onClick={onClick}
      size='small'
      trackingId='team-chat-2.0-send-message'
      {...rest}
    >
      <Icon color='white' name='send' size={14} />
    </Button>
  );
};
