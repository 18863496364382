import { useQueryClient } from 'react-query';
import { useSMSDataV3QueryUpdaters } from '../query-updaters';
import { SchemaSMSDataV3Service } from '../service';
import { BatchGetThreadStatusIO } from '../types';
import {
  calculatePossibleThreadStatusRequests,
  CalculatePossibleThreadStatusRequestsArgs,
  standardizeBatchGetThreadStatusRequest,
} from '../utils';

type LookupLastUpdatedThreadArgs = CalculatePossibleThreadStatusRequestsArgs;

export const useLookupLastUpdatedThread = () => {
  const queryClient = useQueryClient();
  const { getQueryKey } = useSMSDataV3QueryUpdaters();

  const lookupLastUpdatedThread = async (
    args: LookupLastUpdatedThreadArgs,
    filters?: { departmentId?: string; groupId?: string }
  ) => {
    const requestList = calculatePossibleThreadStatusRequests(args);
    const finalRequest = standardizeBatchGetThreadStatusRequest({ requests: requestList });

    const threadStatuses = await queryClient.fetchQuery<BatchGetThreadStatusIO['output']>({
      queryKey: getQueryKey({ endpointName: 'BatchGetThreadStatus', request: finalRequest }),
      queryFn: () => SchemaSMSDataV3Service.BatchGetThreadStatus(finalRequest),
    });

    const lastUpdatedThread = threadStatuses.responses.reduce<(typeof threadStatuses)['responses'][number] | undefined>(
      (result, curr) => {
        if (!result) return curr;
        if (filters?.departmentId && filters.departmentId !== curr.departmentId) return result;
        if (filters?.groupId && filters.groupId !== curr.locationId) return result;
        if (curr.lastModified > result.lastModified) return curr;
        return result;
      },
      undefined
    );

    return lastUpdatedThread;
  };

  return lookupLastUpdatedThread;
};
