import { FC, useCallback } from 'react';
import { css } from '@emotion/react';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import BundleUpgradeIcon from '../../../assets/bundle-upgrade-icon.svg';
import { OnlineSchedulingPromoToastCard } from './OnlineSchedulingPromoToastCard';

interface OnlineSchedulingMessagingPromoCardProps {
  patientName?: string;
  locationId: string;
}

export const OnlineSchedulingMessagingPromoCard: FC<OnlineSchedulingMessagingPromoCardProps> = ({
  locationId,
  patientName,
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'online-scheduling-promo' });

  const getPromoContent = useCallback(
    ({
      isBundleUpgradeEligible,
      bundleName,
      bundleTrialDays,
    }: {
      isBundleUpgradeEligible: boolean;
      bundleName?: string;
      bundleTrialDays?: number;
    }) => {
      if (isBundleUpgradeEligible) {
        return {
          title: patientName
            ? t('{{patientName}} can request their own appointment with Online Scheduling', { patientName })
            : t('Patients can request an appointment with Online Scheduling'),
          subtitle: t('Included in the {{bundleName}} Bundle. Try Ultimate free for {{count}} days.', {
            bundleName,
            count: bundleTrialDays,
          }),
          imagePath: BundleUpgradeIcon,
        };
      }
      return {
        title: patientName
          ? t('Need to schedule an appointment for {{patientName}}?', { patientName })
          : t('Need to schedule an appointment?'),
        subtitle: t('Patients can request an appointment with Online Scheduling.'),
      };
    },
    [patientName]
  );

  return (
    <div css={{ padding: theme.spacing(2) }}>
      <OnlineSchedulingPromoToastCard
        locationIds={locationId ? [locationId] : []}
        source={FeatureNotificationTypes.FeatureNotificationSource.SOURCE_MESSAGING_TEASER_BANNER}
        styles={cardStyles}
        getPromoContent={getPromoContent}
      />
    </div>
  );
};

const cardStyles = css({
  width: '100%',
  maxWidth: 'unset',
});
