import { useEffect, useMemo } from 'react';
import { isFunction } from 'lodash-es';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { CollectPaymentMultiModalInstance } from '@frontend/payments-collection-flow';
import { PaymentsFeatureFlags, useMerchantsInfo, useMultiQueryUtils } from '@frontend/payments-hooks';
import { ButtonProps, useModalControl, useTooltip } from '@frontend/design-system';
import { CreateAndCollectModal } from '../create-and-collect-modal';
import { CreatePaymentRequestModal, OnInvoiceCreated } from '../create-payment-request-modal';

type PaymentRequestListPageOptions =
  | {
      isPaymentRequestListPage: true;
      onInvoiceCreated: OnInvoiceCreated;
    }
  | { isPaymentRequestListPage?: false; onInvoiceCreated?: never };

type UsePaymentRequestModalOptions = { locationIds?: string[] } & PaymentRequestListPageOptions;

export const usePaymentRequestModal = ({
  locationIds: passedInLocationIds,
  isPaymentRequestListPage = false,
  onInvoiceCreated,
}: UsePaymentRequestModalOptions = {}) => {
  const { t } = useTranslation('payments');
  const { locationIds: locationIdsFromUtils } = useMultiQueryUtils();

  const locationIds = useMemo(
    () => passedInLocationIds ?? locationIdsFromUtils,
    [passedInLocationIds?.join(','), locationIdsFromUtils.join(',')]
  );

  const { chargesEnabledLocations, anyLocationHasPayments } = useMerchantsInfo({ locationId: locationIds });
  const chargesEnabled = chargesEnabledLocations.length > 0;

  const { aggregateValue: newLabel } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.CollectPaymentOnABTest,
    locationIds,
  });

  const { modalProps, triggerProps: modalTriggerProps, openModal } = useModalControl();
  const { Tooltip, tooltipProps, triggerProps: tooltipTriggerProps } = useTooltip();

  const dashboardButtonLabel = newLabel ? t('Collect Payment') : t('Invoice');
  const disabledMessage = !anyLocationHasPayments
    ? locationIds.length > 1
      ? t('No selected location has payments enabled')
      : t('Payments is not enabled for this location')
    : !chargesEnabled
    ? locationIds.length > 1
      ? t('No selected location has charges enabled')
      : t('This location has charges disabled')
    : '';

  const disabled = !!disabledMessage;

  const handleClick = () => {
    modalTriggerProps.onClick();
  };

  return {
    dashboardButtonLabel,
    triggerProps: {
      disabled,
      ...modalTriggerProps,
      ...tooltipTriggerProps,
      onClick: handleClick,
    } satisfies ButtonProps,
    tooltipTriggerProps,
    openModal,
    hidePaymentRequestModal: disabled,
    modalProps: {
      modalProps,
      locationIds,
      Tooltip,
      tooltipProps,
      disabledMessage,
      isPaymentRequestListPage,
      onInvoiceCreated,
    },
  };
};

type PaymentRequestModalProps = ReturnType<typeof usePaymentRequestModal>['modalProps'];

export const PaymentRequestModal = ({
  locationIds,
  Tooltip,
  tooltipProps,
  disabledMessage,
  modalProps,
  isPaymentRequestListPage,
  onInvoiceCreated,
}: PaymentRequestModalProps) => {
  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds,
  });

  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds,
  });

  const showCreateAndCollectModal = !isPaymentRequestListPage || newRequestUI;

  useEffect(() => {
    if (!showMultiProcessorCollect && !showCreateAndCollectModal && !isFunction(onInvoiceCreated)) {
      console.error('onInvoiceCreated callback is not provided to PaymentRequestModal');
    }
  }, []);

  return (
    <>
      {/*disabled is not added as a condition to not break existing code like usePaymentAction*/}
      {showMultiProcessorCollect ? (
        <CollectPaymentMultiModalInstance
          modalProps={modalProps}
          collectProps={{
            flow: 'create',
          }}
        />
      ) : showCreateAndCollectModal ? (
        <CreateAndCollectModal {...modalProps} />
      ) : (
        // typescript will enforce `onSuccess` if `isPaymentRequestListPage` is true
        onInvoiceCreated && <CreatePaymentRequestModal {...modalProps} onInvoiceCreated={onInvoiceCreated} />
      )}
      {disabledMessage && <Tooltip {...tooltipProps}>{disabledMessage}</Tooltip>}
    </>
  );
};
