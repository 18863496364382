import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BannerNotification, Text } from '@frontend/design-system';
import { listContainerStyle } from './no-common-source-message.styles';

interface NoCommonSourceMessageProps {
  sharedLocationIds: string[];
}

const NoCommonSourceMessage: FC<NoCommonSourceMessageProps> = ({ sharedLocationIds }) => {
  const { t } = useTranslation('forms');
  const { getScopeName } = useAppScopeStore();

  if (sharedLocationIds.length === 0) {
    return null;
  }

  const messageContent = (
    <>
      <Text size='medium'>{t('There are no PMS/EHR sources found shared between the following location(s):')}</Text>
      <ol css={listContainerStyle}>
        {sharedLocationIds.map((locationId) => (
          <li key={locationId}>
            <Text size='medium'>{getScopeName(locationId)}</Text>
          </li>
        ))}
      </ol>
    </>
  );

  return <BannerNotification status='info' message={messageContent} />;
};

export default NoCommonSourceMessage;
