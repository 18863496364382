import { ValidatedImageType } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { Icon, IconColorType } from '@frontend/icons';
import { CatIcon, DogIcon, HorseIcon, MouseIcon, ReptileIcon, BirdIcon } from './pet-icons';

type Props = {
  imageType?: ValidatedImageType;
  size?: number; // Definition for image size
  color?: IconColorType; // Definition for image color
};

export const PetIcon = ({ imageType = ValidatedImageType.DOG, size = 24, ...rest }: Props) => {
  /**
   * @desc: Function is defined to get the respective Icon JSX
   * @param imageType : ValidatedImageType
   * @param size : number
   * @returns : JSX
   */
  const getIcon = (imageType: ValidatedImageType, size?: number) => {
    switch (imageType) {
      case ValidatedImageType.DOG:
        return <DogIcon size={size} {...rest} />;
      case ValidatedImageType.CAT:
        return <CatIcon size={size} {...rest} />;
      case ValidatedImageType.BIRD:
        return <BirdIcon size={size} {...rest} />;
      case ValidatedImageType.LARGEMAMMAL:
      case ValidatedImageType.HORSE:
        return <HorseIcon size={size} {...rest} />;
      case ValidatedImageType.SMALLMAMMAL:
      case ValidatedImageType.MOUSE:
        return <MouseIcon size={size} {...rest} />;
      case ValidatedImageType.REPTILE:
      case ValidatedImageType.CHAMELEON:
        return <ReptileIcon size={size} {...rest} />;
      default:
        return <Icon name='vet-dog' size={size} {...rest} />;
    }
  };
  return getIcon(imageType, size);
};
