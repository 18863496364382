import { i18next } from '@frontend/i18n';
import { SearchFailedPaymentsParams } from '../hooks/payment-failed';

export const makePersonName = (firstName?: string, lastName?: string) => {
  const fullName =
    [firstName, lastName].filter((name) => !!name && name !== 'unknown').join(' ') || i18next.t('Unknown');

  return fullName;
};

export const hasFailedTransactionFilters = ({ after, submittedAt, search }: SearchFailedPaymentsParams) => {
  if (!!after) {
    return true;
  }

  if (!!submittedAt?.greaterThanEqual || !!submittedAt?.lessThanEqual) {
    return true;
  }

  const textKeys: (keyof SearchFailedPaymentsParams['search'])[] = ['personName', 'text'];

  if (
    textKeys.some((key) => {
      const val = search?.[key];
      if (val === undefined || val === null) {
        return false;
      }

      return String(val).trim().length > 0;
    })
  ) {
    return true;
  }

  const arrayKeys: (keyof SearchFailedPaymentsParams['search'])[] = ['origin', 'paymentStatus', 'locationId'];

  if (
    arrayKeys.some((key) => {
      const val = search?.[key];
      if (val === undefined || val === null) {
        return false;
      }

      return Array.isArray(val) && val.length > 0;
    })
  ) {
    return true;
  }

  return false;
};
