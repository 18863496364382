import { useTranslation } from '@frontend/i18n';
import { useFormNotification } from '@frontend/notifications';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketFormEventSubscription = () => {
  const { create } = useFormNotification();
  const { t } = useTranslation('notifications');
  return useWebsocketEventSubscription('FormsNotification', (payload) => {
    const notification = {
      id: payload.id,
      timestamp: Date.now(),
      type: 'form-submission-new',
      payload: {
        locationId: payload.locationID,
        documentName: payload.params.documentName,
        firstName: payload.params.firstName,
        lastName: payload.params.lastName,
        submissionId: payload.params.submissionId,
        submittedAt: payload.params.submittedAt,
      },
      state: {
        paused: false,
        status: 'unread',
      },
    } satisfies GetWeavePopNotificationByType<'form-submission-new'>;
    create(notification, {
      os: {
        title: t('New Form Submission'),
        body: `${payload.params.firstName} ${payload.params.lastName}`,
      },
    });
  });
};
