import { FC } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { PaymentActionsList } from '../../constants';
import { useCreateAccountTooltip } from '../../hooks/useCreateAccountTooltip';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds } from '../../tracking-ids';
import { PaymentActionType } from '../../types';

export const ActionCards: FC<{ styles?: Interpolation<Theme> }> = ({ styles }) => {
  return (
    <div css={[containerStyles, styles]}>
      {PaymentActionsList.map((action) => (
        <ActionButton key={action.type} action={action} />
      ))}
    </div>
  );
};

const ActionButton: FC<{ action: PaymentActionType }> = ({ action }) => {
  const { hasPaymentsAccount, handleAction, source } = usePaymentsPromotion();
  const { tooltipRender, triggerProps } = useCreateAccountTooltip();

  const isDisabled = !hasPaymentsAccount;
  return (
    <>
      <NakedButton
        trackingId={getPaymentHVATrackingIds({ trackingId: action.trackingId, source })}
        onClick={() => handleAction(action.type)}
        disabled={isDisabled}
        css={[actionCardStyles, isDisabled && { opacity: 0.5 }]}
        {...(hasPaymentsAccount ? null : triggerProps)}
      >
        <Icon name={action.iconName} css={{ color: theme.colors.neutral30 }} />
        <Text size='large' textAlign='left'>
          {action.title}
        </Text>
      </NakedButton>
      {tooltipRender}
    </>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
});

const actionCardStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  backgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.floating,
  padding: theme.spacing(2),
  width: '100%',
});
