import { useEffect, useMemo, useState } from 'react';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  Chip,
  PopoverMenu,
  PopoverMenuItem,
  StatusSwitcherVariants,
  statusSwitcherVariantsColor,
  usePopoverMenu,
} from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';

// Type definitions
type TaskStatusOption = {
  Icon: React.ComponentType;
  label: string;
  trackingId: string;
  value: CallIntelTypes.TaskStatusEnum;
  variant: StatusSwitcherVariants;
};

type Props = {
  defaultOptionValue?: CallIntelTypes.TaskStatusEnum;
  disabled?: boolean;
  onStatusChange?: (newStatus: CallIntelTypes.TaskStatusEnum) => void;
  trackingId?: string;
  value?: CallIntelTypes.TaskStatusEnum;
};

export const TaskStatusSwitcher = ({
  defaultOptionValue,
  disabled = false,
  onStatusChange,
  trackingId,
  value,
}: Props) => {
  const { t } = useTranslation('analytics');

  // Options for task status
  const options: TaskStatusOption[] = useMemo(
    () => [
      {
        Icon: (props) => <Icon name='pending-small' {...props} />,
        label: t('To-Do'),
        trackingId: trackingIds.callIntel.taskStatusTodo,
        value: CallIntelTypes.TaskStatusEnum.STATUS_TODO,
        variant: 'critical',
      },
      {
        Icon: (props) => <Icon name='in-progress-small' {...props} />,
        label: t('In Progress'),
        trackingId: trackingIds.callIntel.taskStatusInProgress,
        value: CallIntelTypes.TaskStatusEnum.STATUS_IN_PROGRESS,
        variant: 'warning',
      },
      {
        Icon: (props) => <Icon name='check-small' {...props} />,
        label: t('Completed'),
        trackingId: trackingIds.callIntel.taskStatusCompleted,
        value: CallIntelTypes.TaskStatusEnum.STATUS_COMPLETED,
        variant: 'success',
      },
    ],
    [t]
  );

  // Set default option based on props or fallback to the first option
  const defaultOption = options.find((option) => option.value === defaultOptionValue) || options[0];

  const [selectedOption, setSelectedOption] = useState<TaskStatusOption>(defaultOption);

  const { close, getMenuProps, getTriggerProps, isOpen } = usePopoverMenu({
    placement: 'bottom',
  });

  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  // Update selected option when `value` prop changes
  useEffect(() => {
    if (value) {
      const newOption = options.find((option) => option.value === value);
      !!newOption && setSelectedOption(newOption);
    }
  }, [value]);

  const handleOptionClick = (option: TaskStatusOption) => {
    setSelectedOption(option);
    !!onStatusChange && onStatusChange(option.value);
    close();
  };

  return (
    <div ref={filterTriggerRef}>
      <Chip.StatusSwitcher
        disabled={disabled}
        isOpen={isOpen}
        isResponsive
        leftElement={<selectedOption.Icon />}
        trackingId={trackingId}
        variant={selectedOption ? selectedOption.variant : 'default'}
        {...triggerProps}
      >
        {selectedOption ? selectedOption.label : t('Status')}
      </Chip.StatusSwitcher>
      <PopoverMenu {...getMenuProps()}>
        {options.map((option) => (
          <PopoverMenuItem key={option.value} onClick={() => handleOptionClick(option)} trackingId={option.trackingId}>
            <option.Icon css={{ color: statusSwitcherVariantsColor[option.variant].color }} />
            {option.label}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </div>
  );
};
