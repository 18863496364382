import { forwardRef } from 'react';
import { format } from 'date-fns';
import { CommonHTMLTimeAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';

type TimeDividerRowProps = CommonHTMLTimeAttributes & {
  timestamp: Date;
};

export const TimeDividerRow = forwardRef<HTMLTimeElement, TimeDividerRowProps>(({ timestamp, ...rest }, ref) => {
  return (
    <time
      css={{
        width: '100%',
        display: 'block',
        textAlign: 'center',
        color: theme.colors.neutral50,
        transition: `margin-bottom 300ms ease-in-out`,
        padding: theme.spacing(1.5, 0, 1),
      }}
      {...rest}
      ref={ref}
    >
      {format(timestamp, 'PP')}
    </time>
  );
});
TimeDividerRow.displayName = 'TimeDividerRow';
