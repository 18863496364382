import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { PaymentConfigName, StripeConfigLocationType, usePaymentMethodConfig } from './use-payment-method-config';

interface UseWeaveAppStripeConfigOptions {
  paymentConfigName?: PaymentConfigName;
}

export const useWeaveAppStripeConfig = ({ paymentConfigName }: UseWeaveAppStripeConfigOptions) => {
  const { locationId, getLocationData } = useMultiQueryUtils();
  const { paymentsUrl } = useMerchant();
  const locationType =
    // locations other than regular and forced regular on prod || forced regular location in a prod
    paymentsUrl?.includes('api.weavedev.net') || getLocationData(locationId)?.opsType !== 'REGULAR'
      ? StripeConfigLocationType.Dev
      : StripeConfigLocationType.Prod;
  const paymentMethodConfig = usePaymentMethodConfig({ paymentConfigName, locationType });

  return paymentsUrl && paymentMethodConfig;
};
