import { css } from '@emotion/react';
import { EmailAssistantModal } from '@frontend/email-assistant-modal';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, useModalControl } from '@frontend/design-system';
import { useContentComposer } from '../../../../content-composer-provider';
import { useEmailSubjectHeader } from '../email-subject-header.context';

export const EmailAssistantButton = () => {
  const { modalProps, triggerProps } = useModalControl();
  const { promptProps } = useContentComposer();
  const { subjectProps } = useEmailSubjectHeader();

  return (
    <>
      <Button
        variant='secondary'
        onClick={triggerProps.onClick}
        css={[
          emailAssistantGradientStyling,
          {
            padding: theme.spacing(0.625, 1),
            width: theme.spacing(5),
            border: '1px solid transparent',
          },
        ]}
      >
        <Icon name='aiassistant-small' />
      </Button>
      <EmailAssistantModal
        modalProps={modalProps}
        onComplete={(value: string) => subjectProps?.onChange({ ...subjectProps, value, name: 'subject' })}
        type='subject'
        previousText={subjectProps?.value}
        promptProps={promptProps}
      />
    </>
  );
};

const emailAssistantGradientStyling = css`
  background: linear-gradient(
    -90deg,
    ${theme.colors.secondary.eggplant20},
    ${theme.colors.primary20},
    ${theme.colors.secondary.eggplant20},
    ${theme.colors.primary20}
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: linear-gradient(
      -90deg,
      ${theme.colors.secondary.eggplant20},
      ${theme.colors.primary20},
      ${theme.colors.secondary.eggplant20},
      ${theme.colors.primary20}
    );
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
