import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { messagingOverviewDemoData } from '../common-demo-data';
import { MessagingRoiFetchParams } from '../types';
import { useFetchRoiData } from './use-fetch-roi-data';

interface Props {
  apiParams: MessagingRoiFetchParams;
  isDemoAccount?: boolean;
  isEnabled?: boolean;
}

type CommonOverview = {
  existing_patients_counts?: number;
  new_patients_counts?: number;
};

type AppointmentOverview = {
  appointments_count?: number;
  existing_patients_appts_counts?: number;
  new_patients_appts_counts?: number;
};

export type Overview = {
  appointments_completed?: AppointmentOverview;
  leads_contacted?: CommonOverview & {
    sms_count?: number;
    unscheduled_patients_counts?: number;
  };
  location_id: string;
  patients_scheduled?: CommonOverview & {
    appointments_count?: number;
  };
};

type Aggregated = {
  [K in Exclude<keyof Overview, 'location_id'>]?: Overview[K];
};

export type OverviewResponse = Overview[];

interface UseFetchMessagingROIOOverview {
  aggregated: Aggregated;
  isLoading?: boolean;
  locationsData: {
    [locationId: string]: Overview & { location_name?: string };
  };
}

const api = async ({ messagingCategory, payload }: MessagingRoiFetchParams) => {
  const response = await http.post<{ data: OverviewResponse }>(
    `${BASE_URL_ANALYTICS}/roi/v4/${messagingCategory}/${
      messagingCategory === 'missedcalltext' ? 'getmctaggregates' : 'aggregates'
    }`,
    payload
  );
  return response.data;
};

export const useFetchMessagingROIOOverview = ({
  isDemoAccount,
  isEnabled = true,
  ...rest
}: Props): UseFetchMessagingROIOOverview => {
  const { t } = useTranslation('analytics');
  const { accessibleLocationData } = useAppScopeStore();

  const featureName = rest.apiParams.messagingCategory === 'missedcalltext' ? 'missed call text' : 'bulk texting';

  const { data: overviewData, isLoading } = useFetchRoiData<MessagingRoiFetchParams, OverviewResponse>({
    ...rest,
    api,
    errorMessage: t("Couldn't fetch the {{featureName}} overview details. Please try again.", { featureName }),
    isEnabled: isEnabled && !isDemoAccount,
    uniqueId: `${rest.apiParams.messagingCategory}-roi-overview`,
  });

  const processedData = useMemo(() => {
    const data = isDemoAccount ? messagingOverviewDemoData(rest?.apiParams?.payload?.location_id || []) : overviewData;

    if (!data) {
      return {
        aggregated: {},
        locationsData: {},
      };
    }

    const locationsData = accessibleLocationData;
    const aggregated: UseFetchMessagingROIOOverview['aggregated'] = {};

    const locations = data.reduce<UseFetchMessagingROIOOverview['locationsData']>((acc = {}, item) => {
      aggregated.appointments_completed = {
        appointments_count:
          (aggregated.appointments_completed?.appointments_count || 0) +
          (item.appointments_completed?.appointments_count || 0),
        existing_patients_appts_counts:
          (aggregated.appointments_completed?.existing_patients_appts_counts || 0) +
          (item.appointments_completed?.existing_patients_appts_counts || 0),
        new_patients_appts_counts:
          (aggregated.appointments_completed?.new_patients_appts_counts || 0) +
          (item.appointments_completed?.new_patients_appts_counts || 0),
      };

      aggregated.leads_contacted = {
        existing_patients_counts:
          (aggregated.leads_contacted?.existing_patients_counts || 0) +
          (item.leads_contacted?.existing_patients_counts || 0),
        new_patients_counts:
          (aggregated.leads_contacted?.new_patients_counts || 0) + (item.leads_contacted?.new_patients_counts || 0),
        sms_count: (aggregated.leads_contacted?.sms_count || 0) + (item.leads_contacted?.sms_count || 0),
        unscheduled_patients_counts:
          (aggregated.leads_contacted?.unscheduled_patients_counts || 0) +
          (item.leads_contacted?.unscheduled_patients_counts || 0),
      };

      aggregated.patients_scheduled = {
        appointments_count:
          (aggregated.patients_scheduled?.appointments_count || 0) + (item.patients_scheduled?.appointments_count || 0),
        existing_patients_counts:
          (aggregated.patients_scheduled?.existing_patients_counts || 0) +
          (item.patients_scheduled?.existing_patients_counts || 0),
        new_patients_counts:
          (aggregated.patients_scheduled?.new_patients_counts || 0) +
          (item.patients_scheduled?.new_patients_counts || 0),
      };

      if (item.location_id) {
        acc[item.location_id] = {
          ...item,
          location_name: locationsData[item.location_id]?.name,
        };
      }

      return acc;
    }, {});

    return {
      aggregated,
      locationsData: locations,
    };
  }, [isDemoAccount, overviewData, rest.apiParams.payload.location_id]);

  return {
    ...processedData,
    isLoading,
  };
};
