import { memo, useContext } from 'react';
import { css } from '@emotion/react';
import { Handle, Position } from '@xyflow/react';
import { useTranslation } from '@frontend/i18n';
import { Button, useTooltip } from '@frontend/design-system';
import { SharedNodeProps } from '../data';
import { InteractionContext } from '../provider';

export const PlaceholderNode = memo(({ data }: SharedNodeProps) => {
  const { t } = useTranslation('phone');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip({ placement: 'right' });

  const { onPlusIconClick } = useContext(InteractionContext);

  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        id={`${data.id}-top`}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <Button
        variant='secondary'
        {...triggerProps}
        onClick={() => onPlusIconClick?.({ nodeId: data.parentId ?? '' })}
        css={css`
          position: relative;
          bottom: 4px;
        `}
        iconName='plus'
      ></Button>

      <Handle
        type='source'
        position={Position.Bottom}
        id={`${data.id}-bottom`}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />

      <Tooltip
        {...tooltipProps}
        css={css`
          font-weight: 400;
        `}
      >
        {t('Add step')}
      </Tooltip>
    </>
  );
});
PlaceholderNode.displayName = 'PlaceholderNode';
