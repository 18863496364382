import { useMemo } from 'react';
import { Button, Section } from '@react-email/components';
import { ButtonSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { theme } from '@frontend/theme';
import { useComposerSection } from '../../hooks';
import { ComposerSectionOutputProps, Corners } from '../../types';
import { getCornersStyle, getFrameStyle } from '../../utils';
import { FacebookNoBorderSvg } from '../assets/facebook-no-border-svg';
import { GoogleNoBorderSvg } from '../assets/google-no-border-svg';
import { WeaveLogoSvg } from '../assets/weave-logo-svg';

export const ReviewButtonOutput = ({ renderMode }: ComposerSectionOutputProps) => {
  const { props } = useComposerSection<ButtonSection>();
  const url = props.link?.url;
  const linkType = props.link?.linkType;

  const buttonLink = useMemo(() => {
    const normalizedLink = url?.startsWith('http') ? url : url ? `https://${url}` : '';
    if (renderMode === 'preview') {
      return normalizedLink;
    }
    switch (linkType) {
      case 'google':
        return '{{GOOGLE_REVIEW_LINK}}';
      case 'facebook':
        return '{{FACEBOOK_REVIEW_LINK}}';
      case 'private':
        return '{{PRIVATE_REVIEW_LINK}}';
      default:
        return normalizedLink;
    }
  }, [linkType, renderMode, url]);

  return (
    <Section style={{ ...getFrameStyle(props.frame), display: 'flex' }}>
      <Button style={createButtonStyle(props)} href={buttonLink}>
        <div style={buttonContentStyle}>
          {linkType === 'google' ? (
            <GoogleNoBorderSvg fillColor={props.textColor} />
          ) : linkType === 'facebook' ? (
            <FacebookNoBorderSvg fillColor={props.frame?.backgroundColor} innerColor={props.textColor} />
          ) : linkType === 'private' ? (
            <WeaveLogoSvg fillColor={props.textColor} />
          ) : null}
          {props.link?.text ?? ''}
        </div>
      </Button>
    </Section>
  );
};

const createButtonStyle = (props: ButtonSection) => ({
  ...getCornersStyle(props.corners as Corners | undefined),
  background: props.buttonColor,
  color: props.textColor,
  border: '0',
  cursor: 'pointer',
});

const buttonContentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(0.75),
  height: theme.spacing(4.5),
  padding: theme.spacing(0.25, 2),
  fontSize: theme.spacing(1.75),
  fontFamily: "'DM Sans', sans-serif",
  fontWeight: theme.font.weight.bold,
  lineHeight: '1.5',
  color: 'inherit',
  '&:hover, &:focus': {
    boxShadow: theme.shadows.floating,
  },
};
