import { Direction_Enum } from '@weave/schema-gen-ts/dist/schemas/fax/shared/v1/enums.pb';
import { Contact, Draft, Fax, FaxRecipient } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import { Bool_Enum } from '@weave/schema-gen-ts/dist/shared/null/types.pb';
import { QueryFunctionContext, UseMutationOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation, ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { HTTPBoundSchemaMethod, SchemaFaxService, SchemaIO } from '@frontend/schema';
import { getContactMedia, getDraftPreview, sendFax } from './api';
import { FaxTypes } from '.';

const REQUEST_LIMIT = 25;

type SchemaMutationOptions<T extends HTTPBoundSchemaMethod<any>> = UseMutationOptions<
  SchemaIO<T>['output'],
  unknown,
  SchemaIO<T>['input'],
  unknown
>;

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export const queryKeys = {
  base: ['fax'] as const,
  listFaxes: ({
    type,
    locationId,
    locationIds,
    filters,
  }: {
    type: string;
    locationId: string;
    locationIds?: string[];
    filters?: { isViewed?: Bool_Enum; isDelivered?: Bool_Enum; direction?: Direction_Enum; tags?: string[] };
  }) => [...queryKeys.base, 'list', 'faxes', { type, locationId, locationIds, filters }],
  getDraftPreview: (id: string) => [...queryKeys.base, 'drafts', id, 'preview'],
  listContacts: ({ type, locationId, locationIds }: { type: string; locationId: string; locationIds: string[] }) => [
    ...queryKeys.base,
    'list',
    'contacts',
    { type, locationId, locationIds },
  ],
  searchContacts: ({ query, locationId }: { query: string; locationId: string }) => [
    ...queryKeys.base,
    'search',
    'contacts',
    { query, locationId },
  ],
  searchFaxes: ({ query, locationIds }: { query: string; locationIds: string[] }) => [
    ...queryKeys.base,
    'search',
    'faxes',
    { query, locationIds },
  ],
  getDraft: (id: string) => [...queryKeys.base, 'drafts', id],
  listDrafts: () => [...queryKeys.base, 'drafts', 'list'],
  getFaxMedia: (mediaId: string, pageNumber: number | undefined, locationId: string) => [
    ...queryKeys.base,
    'media',
    mediaId,
    pageNumber,
    locationId,
  ],
  listDefaultFaxNumber: (locationId: string) => [...queryKeys.base, 'default-fax-number', locationId],
  listNumbers: (locationId: string) => [...queryKeys.base, 'numbers', locationId],
  getDefaultFaxNumber: () => [...queryKeys.base, 'default-fax-number'],
  getFaxContact: (id: string) => [...queryKeys.base, 'contact', id],
  countFaxes: (locationIds: string[]) => [...queryKeys.base, 'count', { locationIds }],
  getFaxContactMedia: (mediaId: string, locationId: string) => [
    ...queryKeys.base,
    'contact-media',
    mediaId,
    locationId,
  ],
  getFaxContacts: (locationId: string, locationIds: string[]) => [
    ...queryKeys.base,
    'list',
    'contacts',
    { locationId, locationIds },
  ],
  forwardFax: () => [...queryKeys.base, 'forward'],
  getFax: (id: string) => [...queryKeys.base, 'fax', id],
};

type SchemaListFaxes = SchemaIO<(typeof SchemaFaxService)['ListFaxes']>;
export const listFaxes = (req: SchemaListFaxes['input']) => SchemaFaxService.ListFaxes(req);

type SchemaListContacts = SchemaIO<(typeof SchemaFaxService)['ListContacts']>;
export const listContacts = (req: SchemaListContacts['input']) => SchemaFaxService.ListContacts(req);

type SchemaListBlockedFaxes = SchemaIO<(typeof SchemaFaxService)['ListBlockedFaxes']>;
export const listBlockedFaxes = (req: SchemaListBlockedFaxes['input']) => SchemaFaxService.ListBlockedFaxes(req);

type SchemaListArchivedFaxes = SchemaIO<(typeof SchemaFaxService)['ListArchivedFaxes']>;
export const listArchivedFaxes = (req: SchemaListArchivedFaxes['input']) => SchemaFaxService.ListArchivedFaxes(req);

type SchemaListDrafts = SchemaIO<(typeof SchemaFaxService)['ListDrafts']>;
export const listDrafts = (req: SchemaListDrafts['input']) => SchemaFaxService.ListDrafts(req);

type SchemaSearchContacts = SchemaIO<(typeof SchemaFaxService)['SearchContacts']>;
export const searchContacts = (req: SchemaSearchContacts['input']) => SchemaFaxService.SearchContacts(req);

type SchemaSearchFaxes = SchemaIO<(typeof SchemaFaxService)['SearchFaxes']>;
export const searchFaxes = (req: SchemaSearchFaxes['input']) => SchemaFaxService.SearchFaxes(req);

type ListFaxesPageParams =
  | {
      lastId: string;
      lastCreatedAt: string;
    }
  | undefined;

type useListFaxesProps = {
  locationId: string;
  locationIds?: string[];
  type: FaxTypes.InboxType;
  enabled: boolean;
  isViewed?: Bool_Enum;
  isDelivered?: Bool_Enum;
  direction?: Direction_Enum;
  tags?: string[];
};

type ListContactsPageParams =
  | {
      previousId: string;
      previousName?: string;
    }
  | undefined;

type useListContactsProps = {
  locationId: string;
  locationIds: string[];
  type: FaxTypes.InboxType;
  enabled: boolean;
};

export const useListFaxesInfiniteQuery = ({
  locationId,
  locationIds,
  type,
  isViewed,
  isDelivered,
  direction,
  tags,
  enabled,
}: useListFaxesProps) =>
  useInfiniteQuery({
    queryKey: queryKeys.listFaxes({
      type,
      locationId,
      locationIds,
      filters: { isViewed, isDelivered, direction, tags },
    }),
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], ListFaxesPageParams>) => {
      const res = await listFaxes({
        locationId,
        locationIds,
        lastId: pageParam?.lastId ?? '',
        lastCreatedAt: pageParam?.lastCreatedAt ?? '',
        isViewed,
        isDelivered,
        direction,
        tags,
      });

      return {
        rows: res.faxes ?? [],
      };
    },
    getNextPageParam: (lastGroup: { rows: Fax[] }): ListFaxesPageParams => {
      if (lastGroup.rows.length < REQUEST_LIMIT) return undefined;
      const lastFax = lastGroup?.rows[lastGroup?.rows.length - 1];
      if (!lastFax) return undefined;
      return {
        lastCreatedAt: lastFax.createdAt,
        lastId: lastFax.id,
      };
    },
    ...defaultOptions,
    enabled,
  });

export const useListBlockedFaxesInfiniteQuery = ({ locationId, locationIds, type, enabled }: useListFaxesProps) =>
  useInfiniteQuery({
    queryKey: queryKeys.listFaxes({ type, locationId, locationIds }),
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], ListFaxesPageParams>) => {
      const res = await listBlockedFaxes({
        locationId,
        locationIds,
        lastId: pageParam?.lastId ?? '',
        lastCreatedAt: pageParam?.lastCreatedAt ?? '',
      });

      return {
        rows: res.faxes ?? [],
      };
    },
    getNextPageParam: (lastGroup: { rows: Fax[] }): ListFaxesPageParams => {
      if (lastGroup.rows.length < REQUEST_LIMIT) return undefined;
      const lastFax = lastGroup?.rows[lastGroup?.rows.length - 1];
      if (!lastFax) return undefined;
      return {
        lastCreatedAt: lastFax.createdAt,
        lastId: lastFax.id,
      };
    },
    ...defaultOptions,
    enabled,
  });

export const useListArchivedFaxesInfiniteQuery = ({ locationId, locationIds, type, enabled }: useListFaxesProps) =>
  useInfiniteQuery({
    queryKey: queryKeys.listFaxes({ type, locationId, locationIds }),
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], ListFaxesPageParams>) => {
      const res = await listArchivedFaxes({
        locationId,
        locationIds,
        lastId: pageParam?.lastId ?? '',
        lastCreatedAt: pageParam?.lastCreatedAt ?? '',
      });

      return {
        rows: res.faxes ?? [],
      };
    },
    getNextPageParam: (lastGroup: { rows: Fax[] }): ListFaxesPageParams => {
      if (lastGroup.rows.length < REQUEST_LIMIT) return undefined;
      const lastFax = lastGroup?.rows[lastGroup?.rows.length - 1];
      if (!lastFax) return undefined;
      return {
        lastCreatedAt: lastFax.createdAt,
        lastId: lastFax.id,
      };
    },
    ...defaultOptions,
    enabled,
  });

export const useListDraftsInfiniteQuery = ({ locationId, locationIds, type, enabled }: useListFaxesProps) =>
  useInfiniteQuery({
    queryKey: queryKeys.listFaxes({ type, locationId, locationIds }),
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], ListFaxesPageParams>) => {
      const res = await listDrafts({
        locationId,
        locationIds,
        lastId: pageParam?.lastId ?? '',
        lastCreatedAt: pageParam?.lastCreatedAt ?? '',
      });
      return {
        rows: res.drafts ?? [],
      };
    },
    getNextPageParam: (lastGroup: { rows: Draft[] }): ListFaxesPageParams => {
      if (lastGroup.rows.length < REQUEST_LIMIT) return undefined;
      const lastFax = lastGroup?.rows[lastGroup?.rows.length - 1];
      if (!lastFax) return undefined;
      return {
        lastCreatedAt: lastFax.createdAt,
        lastId: lastFax.id,
      };
    },
    ...defaultOptions,
    enabled,
  });

type CreateFaxDraftRes = SchemaIO<(typeof SchemaFaxService)['UpsertDraft']>['output'];
type CreateFaxDraftReq = SchemaIO<(typeof SchemaFaxService)['UpsertDraft']>['input'];
export const useCreateFaxDraft = (opts: UseMutationOptions<CreateFaxDraftRes, unknown, CreateFaxDraftReq> = {}) => {
  return useMutation({
    ...opts,
    mutationFn: (draft) => {
      //eslint-disable-next-line
      //@ts-ignore - if these fields are present, it fails the request, so remove them
      const { createdAt, sentAt, updatedAt, userId, ...rest } = draft;
      return SchemaFaxService.UpsertDraft({ ...rest });
    },
  });
};

export const useUpdateFaxDraft = useCreateFaxDraft;

type GetFaxDraftRes = NonNullable<SchemaIO<(typeof SchemaFaxService)['GetDraft']>['output']>;
export const useQueryFaxDraft = (id: string, opts: UseQueryOptions<GetFaxDraftRes> = {}) => {
  return useLocalizedQuery({
    queryKey: queryKeys.getDraft(id),
    queryFn: () =>
      SchemaFaxService.GetDraft({
        draftId: id,
      }),
    ...opts,
  });
};

export const useQueryFaxContacts = (
  locationId: string,
  locationIds: string[],
  limit: number,
  opts: UseQueryOptions<SchemaIO<(typeof SchemaFaxService)['ListContacts']>['output']['contacts']> = {}
) => {
  return useQuery({
    queryKey: queryKeys.getFaxContacts(locationId, locationIds),
    queryFn: () =>
      SchemaFaxService.ListContacts({
        locationId,
        locationIds,
        limit,
        includeAnonymousContacts: true,
      }).then((res) => res.contacts),
    ...opts,
  });
};

export const useQueryFaxNumbers = (
  locationId: string,
  opts: UseQueryOptions<SchemaIO<(typeof SchemaFaxService)['ListNumbers']>['output']['locationPhones']> = {}
) => {
  return useLocalizedQuery({
    queryKey: queryKeys.listNumbers(locationId),
    queryFn: () => SchemaFaxService.ListNumbers({ locationId }).then((res) => res.locationPhones),
    ...opts,
  });
};

export const useQueryDefaultFaxNumber = (
  locationId: string,
  opts: UseQueryOptions<SchemaIO<(typeof SchemaFaxService)['ListNumbers']>['output']['defaultLocationPhone']> = {}
) => {
  return useLocalizedQuery({
    queryKey: queryKeys.listDefaultFaxNumber(locationId),
    queryFn: () => SchemaFaxService.ListNumbers({ locationId }).then((res) => res.defaultLocationPhone),
    ...opts,
  });
};

export const useQueryFaxPreview = (
  draftId: string,
  locationId: string,
  opts: UseQueryOptions<Awaited<ReturnType<typeof getDraftPreview>>> = {}
) => {
  return useLocalizedQuery({
    queryKey: queryKeys.getDraftPreview(draftId),
    queryFn: () => getDraftPreview(draftId, locationId),
    ...opts,
  });
};

type SendDraftReq = { draftId: string; locationId: string; locationPhone: string; faxRecipients: FaxRecipient[] };
export const useSendFax = (opts: UseMutationOptions<unknown, unknown, SendDraftReq> = {}) => {
  return useMutation({
    ...opts,
    mutationFn: ({ draftId, locationId, locationPhone, faxRecipients }) =>
      sendFax(draftId, locationId, locationPhone, faxRecipients),
  });
};

export const useForwardFax = (opts: SchemaMutationOptions<(typeof SchemaFaxService)['ForwardFax']> = {}) =>
  useMutation({
    mutationKey: queryKeys.forwardFax(),
    mutationFn: SchemaFaxService.ForwardFax,
    ...opts,
  });

export const useListContactsInfiniteQuery = ({ locationId, locationIds, type, enabled }: useListContactsProps) => {
  return useInfiniteQuery({
    queryKey: queryKeys.listContacts({ type, locationId, locationIds }),
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], ListContactsPageParams>) => {
      const res = await listContacts({
        locationId,
        locationIds,
        previousId: pageParam?.previousId ?? '',
        previousName: pageParam?.previousName ?? '',
      });

      return {
        rows: res.contacts ?? [],
      };
    },
    getNextPageParam: (lastGroup: { rows: (string | Contact | undefined)[] }): ListContactsPageParams => {
      if (lastGroup.rows.length < REQUEST_LIMIT) return undefined;
      const lastContact = lastGroup?.rows[lastGroup?.rows.length - 1];
      if (!lastContact) return undefined;
      return {
        previousId: typeof lastContact !== 'string' ? lastContact.id : '',
        previousName: typeof lastContact !== 'string' ? lastContact.name : '',
      };
    },
    ...defaultOptions,
    enabled,
  });
};

type useSearchContactsProps = {
  locationId: string;
  locationIds: string[];
  query: string;
  enabled: boolean;
};

type SearchContactsPageParams =
  | {
      offset: number;
    }
  | undefined;

export const useSearchContactsInfiniteQuery = ({ locationId, locationIds, query, enabled }: useSearchContactsProps) => {
  return useInfiniteQuery({
    queryKey: queryKeys.searchContacts({ query, locationId }),
    queryFn: async ({ pageParam }: QueryFunctionContext<string | readonly unknown[], SearchContactsPageParams>) => {
      const res = await searchContacts({
        locationId,
        locationIds,
        query,
        offset: pageParam?.offset ?? 0,
        limit: REQUEST_LIMIT,
      });

      return {
        rows: res.contacts ?? [],
      };
    },
    getNextPageParam: (lastGroup: { rows: (string | Contact | undefined)[] }): SearchContactsPageParams => {
      if (lastGroup.rows.length < REQUEST_LIMIT) return undefined;
      const lastContact = lastGroup?.rows[lastGroup?.rows.length - 1];
      if (!lastContact) return undefined;
      return {
        // BE will be updating this prop, right now it will always be 0
        offset: 0,
      };
    },
    ...defaultOptions,
    enabled,
  });
};
type useSearchFaxesProps = {
  locationIds: string[];
  query: string;
  limit?: number;
};

export const useSearchFaxesQuery = ({ locationIds, query, limit }: useSearchFaxesProps) => {
  return useQuery({
    queryKey: queryKeys.searchFaxes({ query, locationIds }),
    queryFn: () =>
      searchFaxes({
        locationIds,
        query,
        limit,
      }),
    select: (data) => data.faxes,
    ...defaultOptions,
  });
};

type UpsertContactRes = SchemaIO<(typeof SchemaFaxService)['UpsertContact']>['output'];
type UpsertContactReq = SchemaIO<(typeof SchemaFaxService)['UpsertContact']>['input'];
export const useUpsertFaxContact = (opts: UseMutationOptions<UpsertContactRes, unknown, UpsertContactReq> = {}) => {
  return useMutation({
    mutationFn: (contact: UpsertContactReq) => {
      return SchemaFaxService.UpsertContact(contact);
    },
    ...opts,
  });
};

type GetContactRes = SchemaIO<(typeof SchemaFaxService)['GetContact']>['output'];
export const useGetFaxContact = (contactId: string, locationId: string, opts: UseQueryOptions<GetContactRes> = {}) => {
  return useQuery({
    queryKey: queryKeys.getFaxContact(contactId),
    queryFn: () => SchemaFaxService.GetContact({ contactId, locationId }),
    ...opts,
  });
};

type CountFaxesRes = SchemaIO<(typeof SchemaFaxService)['CountFaxes']>['output'];
export const useCountFaxes = (locationIds: string[], opts: UseQueryOptions<CountFaxesRes> = {}) => {
  return useQuery({
    queryKey: queryKeys.countFaxes(locationIds),
    queryFn: () => SchemaFaxService.CountFaxes({ locationIds, isRead: Bool_Enum.FALSE }),
    ...opts,
  });
};

export const useGetFaxContactMedia = (mediaId: string, locationId: string, opts: UseQueryOptions<Blob> = {}) => {
  return useQuery({
    queryKey: queryKeys.getFaxContactMedia(mediaId, locationId),
    queryFn: () => getContactMedia(mediaId, locationId),
    ...opts,
  });
};

type GetFaxRes = SchemaIO<(typeof SchemaFaxService)['GetFax']>['output'];
export const useGetFax = (faxId: string, locationId: string, opts: UseQueryOptions<GetFaxRes> = {}) => {
  return useQuery({
    queryKey: queryKeys.getFax(faxId),
    queryFn: () => SchemaFaxService.GetFax({ faxId, locationId }),
    ...opts,
  });
};
