import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallQueueStatsApi } from '@frontend/api-call-queue-stats';
import { DevicesApi } from '@frontend/api-devices';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ScopedLocationFilterMenu } from '@frontend/location-filter-menu';
import { useAppScopeStore, useOnScopeChange, useScopedQuery } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { SearchField, useFormField, useAlert } from '@frontend/design-system';
import { AllCallsTable, usePhonePageSettingShallowStore } from '../../hooks/use-phone-config-state-store';
import { queryKeys } from '../../query-keys';
import { ChipDropdownFilter } from './chip-dropdown-filter';
import { useLocationHook, sortLocationFunction } from './location-hook';
import { MetricsCardSkeletonLoader, MetricsEmptyState, CallQueueMetricsCard } from './metrics-card';
import { CallQueueMetricsMap, useCallQueueMetricsStore } from './use-metrics-store';

enum SortingLabels {
  AlphabeticalAZ = 'AlphabeticalAZ',
  AlphabeticalZA = 'AlphabeticalZA',
  default = 'none',
}

const SortingOptions: Record<SortingLabels, string> = {
  [SortingLabels.AlphabeticalAZ]: i18next.t('Alphabetical: A-Z', { ns: 'call-queue-stats' }),
  [SortingLabels.AlphabeticalZA]: i18next.t('Alphabetical: Z-A', { ns: 'call-queue-stats' }),
  [SortingLabels.default]: i18next.t('Sort', { ns: 'call-queue-stats' }),
};

/**
 * Sorting function based on the selection of sorting element
 */
const sortFunction = (selection: SortingLabels, metrics: CallQueueMetricsMap) => {
  switch (selection) {
    case SortingLabels.AlphabeticalAZ:
      return (a: string, b: string) => (metrics?.[a]?.name ?? '').localeCompare(metrics?.[b]?.name ?? '');
    case SortingLabels.AlphabeticalZA:
      return (a: string, b: string) => (metrics?.[b]?.name ?? '').localeCompare(metrics?.[a]?.name ?? '');
    default:
      return () => 0;
  }
};

const applySortingAndSearching = ({
  sortElement,
  searchValue,
  metrics,
  callqueueIds,
}: {
  sortElement: SortingLabels;
  searchValue: string;
  metrics: CallQueueMetricsMap;
  callqueueIds: string[];
}) => {
  const selectedSortFunction = sortFunction(sortElement, metrics);
  const filteredCallQueues = callqueueIds.filter((queueId) => {
    return (metrics?.[queueId]?.name ?? '').toLowerCase().includes(searchValue.toLowerCase() ?? '');
  });
  const sortedCallqueueIds = filteredCallQueues.slice().sort(selectedSortFunction);
  return sortedCallqueueIds;
};

export function CardView() {
  const alert = useAlert();
  const { t } = useTranslation('call-queue-stats');
  const { selectedLocationIds: globalLocationIds, accessibleLocationData } = useAppScopeStore();
  const { locationLabels, isDataBeta, isUnifyLocation } = useLocationHook();
  const { metrics, setMetrics } = useCallQueueMetricsStore();
  const [sortedCallqueueIds, setSortedCallqueueIds] = useState<string[]>([]);
  const { setShow, show, panelType } = useSlidePanelShallowStore('setShow', 'panelType', 'show');

  useEffect(() => {
    // When the user navigates away from this page, we want to close the manage devices panel
    return () => {
      if (panelType === 'manageDevices' && show) {
        setShow(false);
      }
    };
  }, [show, panelType, setShow]);

  const { config, setFilters } = usePhonePageSettingShallowStore(
    'config',
    'setPageSize',
    'setPageNumber',
    'setFilters'
  );

  const filters = config[AllCallsTable.Analytics].filters;
  const resolvedFilteredLocations = filters?.locationIds?.length ? filters?.locationIds : globalLocationIds;
  const firstLocationId = resolvedFilteredLocations[0];

  const [selectedSortElement, setSelectedSortElement] = useState<SortingLabels>(SortingLabels.default);
  const { [SortingLabels.default]: removedDefault, ...filteredSortOptions } = SortingOptions;

  useOnScopeChange(() => {
    setFilters(AllCallsTable.Analytics, { locationIds: [] });
  });

  /**
   * fetch all devices that can be assigned to a location
   */
  const { data: allDevicesList, isError: isErrorInDeviceFetching } = useScopedQuery({
    queryKey: queryKeys.devicesWithAddresses(firstLocationId),
    queryFn: () => {
      return DevicesApi.listDevicesWithHeaders(firstLocationId);
    },
  });

  useEffect(() => {
    if (!!isErrorInDeviceFetching) {
      alert.error(t('Failed to fetch the devices list.'));
    }
  }, [isErrorInDeviceFetching]);

  /**
   * Get the call queues list for all the locations by default
   */
  const tenantId = accessibleLocationData[firstLocationId]?.phoneTenantId ?? '';
  const key = `${tenantId}_${[...resolvedFilteredLocations].sort().join(',')}`;
  const { data: callqueues, isLoading: isQueueListLoading } = useScopedQuery({
    queryKey: queryKeys.listCallQueues(key),
    queryFn: () => {
      return CallQueueStatsApi.getCallQueuesList({
        tenantIdLocationIds: [
          {
            tenantId,
            locationIds: resolvedFilteredLocations,
          },
        ],
      });
    },
  });

  const callqueueIds: string[] = useMemo(() => {
    const queueIds = (callqueues?.queues ?? [])
      .filter((queue) => queue.id !== undefined)
      .map((queue) => queue.id) as string[];
    setSortedCallqueueIds(queueIds);
    return queueIds;
  }, [callqueues]);

  // subcribe to the call queues metrics
  const { data: subcribedToQueues, isError: isErrorInSubscribing } = useScopedQuery({
    queryKey: queryKeys.subscribeUserToQueues(callqueueIds),
    queryFn: () => {
      return CallQueueStatsApi.subscribeUserToQueues({
        queueIds: callqueueIds,
      });
    },
  });

  useEffect(() => {
    if (!!isErrorInSubscribing) {
      alert.warning(t('failed to subcribe to the real-time call queue data.'));
    }
  }, [isErrorInSubscribing]);

  // get the call queue metrics here
  const { data: callQueueMetrics, isLoading: isMetricsLoading } = useScopedQuery({
    queryKey: queryKeys.getCallQueueMetrics(callqueueIds),
    queryFn: () => {
      return CallQueueStatsApi.getCallQueueMetrics({
        ids: callqueueIds,
      });
    },
    enabled: !!subcribedToQueues?.success,
  });

  /**
   * We need this map to get the call queue metrics such as call count and average wait time easily by queue id
   */
  const callQueueMetricsMap = useMemo(() => {
    const metricsMap =
      callQueueMetrics?.callQueueMetrics?.reduce((acc, metric) => {
        if (metric?.queueId !== undefined) {
          acc[metric?.queueId] = {
            callCount: metric.callCount,
            averageWaitTimeInSeconds: metric.averageWaitTimeInSeconds,
            waitTimesInSeconds: metric.waitTimesInSeconds,
          };
        }
        return acc;
      }, {} as CallQueueMetricsMap) ?? {};

    (callqueues?.queues ?? []).forEach((queue) => {
      if (queue.id !== undefined) {
        metricsMap[queue.id] = {
          ...metricsMap[queue.id],
          name: queue.name ?? '',
          locationIds: queue.labels?.reduce((acc, label) => {
            if (label.Name === 'location-id') {
              acc.push(label.Value ?? '');
            }
            return acc;
          }, [] as string[]),
        };
      }
    });
    return metricsMap;
  }, [callQueueMetrics?.callQueueMetrics, callqueues?.queues]);

  /**
   * User is subscribed to the call queue events whenever this page is visited, then we need to intialize the metrics store
   */
  useEffect(() => {
    setMetrics(callQueueMetricsMap);
  }, [callQueueMetricsMap]);

  const searchFieldProps = useFormField({ type: 'text', value: '' });

  const handleSortingAndSearching = (newSortElement: SortingLabels, searchVal: string) => {
    const sortedQueueIds = applySortingAndSearching({
      sortElement: newSortElement,
      searchValue: searchVal,
      metrics,
      callqueueIds,
    });
    setSortedCallqueueIds(sortedQueueIds);
  };

  const handleSearchFieldChange = (value: string) => {
    handleSortingAndSearching(selectedSortElement, value);
  };

  const handleSortChange = (newSortElement: SortingLabels) => {
    setSelectedSortElement(newSortElement);
    handleSortingAndSearching(newSortElement, searchFieldProps.value);
  };

  return (
    <>
      <section css={styles.headerContent}>
        <div className='headerLeft'>
          {globalLocationIds.length > 1 && isDataBeta && (
            <ChipDropdownFilter
              key={firstLocationId}
              trackingId='call-queue-stats-location-dropdown-btn'
              label=''
              isFilterActive={false}
              labeledItems={locationLabels}
              sortFunction={sortLocationFunction}
              selectedLabeledItems={firstLocationId}
              onChange={(val) => {
                setFilters(AllCallsTable.Analytics, { locationIds: [val] });
              }}
              leftElement={<Icon name='locations-small' color='default' size={16} />}
            />
          )}
          {isUnifyLocation && globalLocationIds.length > 1 && (
            <ScopedLocationFilterMenu
              // These options should reflect what is actually selected by the user, not the resolved options
              selectedOptions={filters?.locationIds ?? []}
              trackingIdBase='call-queue-stats-location-dropdown'
              onChange={(selected: string[]) => {
                setFilters(AllCallsTable.Analytics, { locationIds: selected });
              }}
            />
          )}
        </div>
        <div className='headerRight'>
          <SearchField
            {...searchFieldProps}
            css={styles.searchField}
            placeholder={t(`Search Call Queues`)}
            name='call-queue-stats-search'
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                handleSearchFieldChange(e.currentTarget.value);
              }
            }}
            onBlur={(e) => handleSearchFieldChange(e?.currentTarget.value ?? '')}
            onClear={() => {
              handleSearchFieldChange('');
            }}
          />
          <ChipDropdownFilter
            trackingId='call-queue-stats-sort-dropdown-btn'
            label={SortingOptions[SortingLabels.default]}
            isFilterActive={false}
            labeledItems={filteredSortOptions}
            selectedLabeledItems={selectedSortElement}
            onChange={(val) => handleSortChange(val as SortingLabels)}
            leftElement={<Icon name='sort-descending' color='default' size={18} />}
          />
        </div>
      </section>
      <section css={styles.metricsGrid}>
        {(isMetricsLoading || isQueueListLoading) &&
          Array.from({ length: 9 }).map((_, index) => <MetricsCardSkeletonLoader key={index} />)}
        {!isMetricsLoading && sortedCallqueueIds.length === 0 && <MetricsEmptyState />}
        {!isMetricsLoading &&
          sortedCallqueueIds.length > 0 &&
          sortedCallqueueIds?.map((queueId) => (
            <CallQueueMetricsCard
              key={queueId}
              queueId={queueId}
              queueName={metrics?.[queueId]?.name ?? ''}
              locationIds={metrics?.[queueId]?.locationIds}
              callCount={metrics?.[queueId]?.callCount}
              allDevices={allDevicesList ?? []}
            />
          ))}
      </section>
    </>
  );
}

const styles = {
  headerContent: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(4)};
    gap: 60px;

    > div.headerRight {
      display: flex;
      gap: 10px;
    }
  `,

  searchField: css`
    width: 400px;
  `,

  popoverStyles: css`
    display: flex;
    > div.tickIcon {
      width: 30px;
    }
  `,

  metricsGrid: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  `,

  ellipsis: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  popoverItemStyles: css`
    max-width: 240px;
    text-align: left;
  `,
};
