import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { ErrorBoundary } from '@frontend/error-boundary';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, SpinningLoader, useAlert, useDebouncedFn, useModalControl } from '@frontend/design-system';
import { INITIAL_MESSAGE_ITEM_INDEX } from '../../constants';
import { useActiveConversationSelector } from '../../providers/active-conversation.provider';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import type { Target } from '../../utils';
import { MessageComposer } from '../composer';
import { EmptyConversation } from './empty-conversation';
import { DeleteMessageConfirmation } from './message/actions-bar-popover/delete-message-confirmation';
import { MessageRegularVirtuoso } from './message/message-regular-virtuoso';

type Props = {
  selectedTarget: Target | undefined;
  messages: TeamChatTypes.Message[];
  fetchNextPage: () => void;
};

export const ConversationBody = ({ selectedTarget, messages, fetchNextPage }: Props) => {
  // TODO: there is probably a better way to do this. it's a little jumpy, but it is a start
  const [height, setHeight] = useState(72);
  const { t } = useTranslation('team-chat');
  const alert = useAlert();
  const isFocused = useTeamChatSelector((ctx) => ctx.isFocused);
  const isThread = useTeamChatSelector((ctx) => ctx.isThread);
  const highlightedMessageId = useTeamChatSelector((ctx) => ctx.highlightedMessageId);
  const conversation = useActiveConversationSelector((ctx) => ctx.conversation);
  const members = useActiveConversationSelector((ctx) => ctx.members);
  const getFirstUnreadMessageIndex = useActiveConversationSelector((ctx) => ctx.getFirstUnreadMessageIndex);
  const isFetching = useActiveConversationSelector((ctx) => ctx.isFetching);
  const sendConversationEvent = useActiveConversationSelector((ctx) => ctx.sendConversationEvent);
  const deleteMessage = useActiveConversationSelector((ctx) => ctx.deleteMessage);
  const editMessage = useActiveConversationSelector((ctx) => ctx.editMessage);
  const { modalProps, openModal } = useModalControl();
  const [currentDeleteMessageId, setCurrentDeleteMessageId] = useState<string>();

  const currentDeleteMessage = useMemo(() => {
    return messages.find((message) => message.id === currentDeleteMessageId);
  }, [currentDeleteMessageId, messages]);

  // This function is already memoized based on anything that might change its output
  const listRef = useRef<VirtuosoHandle>(null);

  const messageUnreadIndex = useMemo(() => {
    return getFirstUnreadMessageIndex();
  }, [getFirstUnreadMessageIndex]);

  const [unreadIndex, setUnreadIndex] = useState(-1);
  const shouldShowMessages = useMemo(
    () => !(messageUnreadIndex === -1 && !!conversation?.unreadCount),
    [messageUnreadIndex, conversation?.unreadCount]
  );

  const channelIdRef = useRef<string | undefined>(conversation?.channelId);

  /** Fetches the next page of messages when the user scrolls up */
  useEffect(() => {
    if (!conversation || !messages || !messages.length) {
      return;
    }
    if (messageUnreadIndex === -1 && !!conversation.unreadCount) {
      fetchNextPage();
    } else {
      const hasLastReadMessageIndex = !!conversation.lastReadMessageId;
      const indexMismatch = messageUnreadIndex !== -1 && messageUnreadIndex !== messages.length - 1;
      const shouldSetIndex = unreadIndex === -1 || channelIdRef.current !== conversation.channelId || !isFocused;
      if (shouldSetIndex && (indexMismatch || !hasLastReadMessageIndex)) {
        setUnreadIndex(messageUnreadIndex ?? -1);
      }
      if (channelIdRef.current !== conversation.channelId) {
        channelIdRef.current = conversation.channelId;
      }
    }
  }, [messageUnreadIndex, messages.length, conversation?.unreadCount, conversation?.channelId, !isFocused]);

  const markConversationAsRead = useDebouncedFn(sendConversationEvent, 1000);

  useEffect(() => {
    if (
      messages[messages.length - 1]?.id &&
      conversation &&
      shouldShowMessages &&
      isFocused &&
      conversation?.lastReadMessageId !== messages[messages.length - 1]?.id
    ) {
      // Mark messages read only if there is an unread count
      markConversationAsRead({ conversationId: conversation.channelId, event: 'read' });
    }
  }, [
    messages[messages.length - 1]?.id,
    markConversationAsRead,
    shouldShowMessages,
    conversation?.channelId,
    conversation?.unreadCount,
    conversation?.lastReadMessageId,
    isFocused,
  ]);

  useLayoutEffect(() => {
    if (!shouldShowMessages && conversation?.unreadCount) {
      return;
    }
    setTimeout(() => {
      if (unreadIndex > -1 && (conversation?.unreadCount ?? 0) > 0 && isFocused) {
        listRef.current?.scrollToIndex({ index: unreadIndex, align: 'center', behavior: 'smooth' });
      }
    }, 300);
  }, [conversation?.unreadCount, shouldShowMessages, unreadIndex, isFocused]);

  /** The new line should only show up for up to 5 seconds */
  const timer = useRef<NodeJS.Timeout | undefined>();
  const [isNewLineExpired, setIsNewLineExpired] = useState(false);
  useEffect(() => {
    if (isFocused && !isNewLineExpired) {
      timer.current = setTimeout(() => {
        setIsNewLineExpired(true);
      }, 5000);
      return () => clearTimeout(timer.current);
    } else {
      clearTimeout(timer.current);
      return;
    }
  }, [isFocused, isNewLineExpired]);

  const messagesToRender = useMemo(
    () =>
      messages.filter((message) => {
        const hasReplies = message.replyCount > 0;
        const isDeleted = message.type === 'deleted';
        const shouldShowDeleted = isDeleted && hasReplies;
        const shouldShow = !isDeleted || shouldShowDeleted;
        // we need to filter here rather than at any level lower in the react tree,
        // because otherwise virtuoso will render zero-sized elements,
        // which issues a warning in the console
        return shouldShow;
      }),
    [messages]
  );

  const showDeletionModal = useCallback((messageId: string) => {
    setCurrentDeleteMessageId(messageId);
    openModal();
  }, []);

  const onDelete = useCallback(() => {
    if (!currentDeleteMessageId) {
      return;
    }
    return deleteMessage({ messageId: currentDeleteMessageId, hardDelete: false })
      .then((message) => {
        if (isThread) {
          const parentMessage = messages.find((m) => m.id === message.parentId);
          const replySender = message.userId;
          if (parentMessage) {
            return editMessage({
              ...parentMessage,
              threadParticipantIds: parentMessage.threadParticipantIds.filter(
                (participantId) => participantId !== replySender
              ),
            });
          }
          return undefined;
        } else {
          return Promise.resolve(message);
        }
      })
      .then(() => {
        alert.success(t('Message deleted successfully'));
      })
      .catch((e) => {
        alert.error(t('Failed to delete message'));
        throw new Error('Unable to delete message.', e);
      });
  }, [messages, deleteMessage, close, currentDeleteMessageId]);

  return (
    <ErrorBoundary>
      {conversation && shouldShowMessages && messages.length ? (
        <Virtuoso
          data={messagesToRender}
          style={{ height: isThread ? height : '100%' }}
          startReached={() => fetchNextPage()}
          firstItemIndex={INITIAL_MESSAGE_ITEM_INDEX - messages.length}
          initialTopMostItemIndex={messages.length - 1}
          alignToBottom={!isThread}
          totalCount={messages.length}
          ref={listRef}
          totalListHeightChanged={(height: number) => {
            setHeight(height);
          }}
          itemContent={(index, message, { firstItemIndex }) => {
            const currentIndex = messagesToRender.length + index - firstItemIndex;
            return (
              <MessageRegularVirtuoso
                message={message}
                isHighlighted={highlightedMessageId === message.id}
                showNewLine={!isNewLineExpired && (conversation?.unreadCount ?? 0) > 0 && unreadIndex === currentIndex}
                onDelete={showDeletionModal}
                isThread={isThread}
              />
            );
          }}
          components={{
            Header: ({ context }: { context?: { isFetching: boolean; firstItemIndex: number } }) =>
              context?.isFetching ? (
                <div css={styles.spinner}>
                  <SpinningLoader size='xs' />
                </div>
              ) : null,
          }}
          context={{
            isFetching,
            firstItemIndex: INITIAL_MESSAGE_ITEM_INDEX,
          }}
          followOutput='auto'
        />
      ) : (
        <EmptyConversation memberCount={members.length || 0} type={conversation?.type ?? 'DM'} />
      )}
      <ErrorBoundary>{<MessageComposer type='new' selectedTarget={selectedTarget} />}</ErrorBoundary>
      <ContentLoader show={isFetching} message={t('Getting latest messages')} />
      {currentDeleteMessage && (
        <DeleteMessageConfirmation
          message={currentDeleteMessage}
          modalProps={modalProps}
          onConfirm={() => onDelete()}
        />
      )}
    </ErrorBoundary>
  );
};

ConversationBody.displayName = 'ConversationBody';

const styles = {
  spinner: css({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    width: '100%',
  }),
};
