import { useCallback, useMemo, useState } from 'react';
import { ButtonSection, Link } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, TextField, useFormField } from '@frontend/design-system';
import { useComposerSection } from '../../../hooks';
import { FloatingButton, FloatingToolbarCenter } from '.';

const REVIEW_LINK_TYPES = ['google', 'facebook', 'private'];

type Props = {
  link?: Link;
};

export const CustomReviewButtonsToolbar = ({ link }: Props) => {
  const { t } = useTranslation('content-composer');
  const [showCustom, setShowCustom] = useState(false);
  const { setProps } = useComposerSection<ButtonSection>();

  const fieldProps = useFormField({ type: 'text', value: link?.url }, [link?.url]);

  const setLink = useCallback(
    (value: string) => {
      setProps((props) => (props.link = { linkType: 'custom', url: value }));
    },
    [setProps]
  );

  const handleCustomLinkInput = useCallback(() => {
    if (fieldProps.value) {
      setLink(fieldProps.value);
      setShowCustom(false);
    }
  }, [fieldProps.value, setLink]);

  const initialState = useMemo(
    () => (
      <FloatingButton onClick={() => setShowCustom(true)} css={toolbarStyling}>
        <Icon name='link' css={iconStyling} />
        <Text as='span' css={{ fontWeight: theme.font.weight.bold }}>
          {t('Link Button')}
        </Text>
      </FloatingButton>
    ),
    []
  );

  const hasLinkState = useMemo(
    () => (
      <FloatingButton css={toolbarStyling}>
        <Icon name='link' css={iconStyling} />
        <Text
          as='span'
          color='primary'
          css={{
            textDecoration: 'underline',
            textUnderlineOffset: theme.spacing(0.5),
          }}
          onClick={() => setShowCustom(true)}
        >
          {link?.url}
        </Text>
        {link?.linkType === 'custom' ? (
          <Icon
            name='x-small'
            onClick={() => {
              setLink('');
              setShowCustom(false);
            }}
          />
        ) : null}
      </FloatingButton>
    ),
    [link, setLink]
  );

  const customLinkTypeState = useMemo(
    () => (
      <FloatingButton css={toolbarStyling}>
        <Icon name='back' css={iconStyling} onClick={() => setShowCustom(false)} />
        <TextField
          {...fieldProps}
          name=''
          label=''
          autoFocus
          placeholder={t('Link URL')}
          css={{ width: 450 }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleCustomLinkInput();
          }}
        />
        <Icon name='check' color={fieldProps.value ? 'primary' : 'disabled'} onClick={handleCustomLinkInput} />
      </FloatingButton>
    ),
    [fieldProps, handleCustomLinkInput]
  );

  const renderToolbarContent = () => {
    if (link?.linkType && REVIEW_LINK_TYPES.includes(link.linkType)) {
      return link.url ? hasLinkState : null;
    }
    return showCustom ? customLinkTypeState : link?.url ? hasLinkState : initialState;
  };

  return <FloatingToolbarCenter orientation='bottom'>{renderToolbarContent()}</FloatingToolbarCenter>;
};

const toolbarStyling = {
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  padding: theme.spacing(1),
  background: theme.colors.white,
  boxShadow: theme.shadows.light,
  border: `solid 1px transparent`,
  borderRadius: theme.borderRadius.small,
  transition: 'all 250ms ease',

  '&:hover, &:focus': {
    outline: 'none',
    boxShadow: theme.shadows.floating,
    background: theme.colors.neutral5,
  },
};

const iconStyling = {
  height: theme.spacing(2),
  width: theme.spacing(2),
  minHeight: theme.spacing(2),
  minWidth: theme.spacing(2),
};
