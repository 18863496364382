import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocationDataStore } from '@frontend/location-helpers';
import { CommonHTMLAttributes } from '@frontend/types';
import {
  IconButton,
  Text,
  PopoverMenu,
  PopoverMenuItem,
  useModalControl,
  usePopoverMenu,
  SpinningLoader,
} from '@frontend/design-system';
import { useBookingSiteContext } from '../../../context/BookingSiteContext';
import { isValidBookingLink } from '../../../utils';
import { bookingSiteSetupTrackingIds } from '../trackingIds';
import { DeleteBookingLinkModal } from './DeleteBookingLinkModal';

interface GoogleBusinessProfileOptionsProps extends CommonHTMLAttributes {
  isLoading: boolean;
  bookingURL: string;
}

export const GoogleBusinessProfileOptions = ({ bookingURL, isLoading, ...rest }: GoogleBusinessProfileOptionsProps) => {
  const { t } = useTranslation('schedule');
  const { locationId } = useLocationDataStore();
  const { modalProps, triggerProps } = useModalControl();
  const { handleDeleteBookingSiteLinkToGoogleBusinessProfile, weaveBookingLinkDetails } = useBookingSiteContext();

  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'bottom' });

  const googleBookingLinkDetails = weaveBookingLinkDetails?.find(
    (link) => link.uri && isValidBookingLink(link.uri, locationId)
  );

  const goToBookingLink = () => {
    const bookingLink = bookingURL || googleBookingLinkDetails?.uri || '';
    if (!!bookingLink) {
      window.open(bookingLink, '_blank');
    }
  };

  const handleOnDelete = (resourceName?: string) => {
    if (resourceName) {
      handleDeleteBookingSiteLinkToGoogleBusinessProfile(resourceName || '');
      modalProps.onClose();
    }
  };

  if (isLoading) return <SpinningLoader size='medium' />;

  return (
    <div {...rest}>
      <IconButton {...getTriggerProps()} label={t('Delete Booking Link')}>
        <Icon name='more' />
      </IconButton>
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem
          {...getItemProps({
            index: 0,
            onClick: goToBookingLink,
          })}
          disabled={!bookingURL && !googleBookingLinkDetails?.uri}
          css={{ cursor: 'pointer' }}
          Icon={() => <Icon name='external-link-small' />}
          trackingId={bookingSiteSetupTrackingIds.previewSiteOption}
        >
          <Text as='span'>{t('Preview Site')}</Text>
        </PopoverMenuItem>
        <PopoverMenuItem
          {...getItemProps({
            index: 1,
            onClick: () => triggerProps.onClick(),
          })}
          css={{ cursor: 'pointer' }}
          Icon={() => <Icon name='trash-small' color='error' />}
          trackingId={bookingSiteSetupTrackingIds.removeLinkOption}
        >
          <Text color='error' as='span'>
            {t('Remove Link')}
          </Text>
        </PopoverMenuItem>
      </PopoverMenu>
      <DeleteBookingLinkModal modalProps={modalProps} onDelete={handleOnDelete} />
    </div>
  );
};
