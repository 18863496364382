import { useQuery } from 'react-query';
import { DevicesApi, DevicesTypes } from '@frontend/api-devices';
import { i18next } from '@frontend/i18n';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { trackingId } from '../../../tracking';
import { InstructionFieldsProps, ForwardDevicePayload } from '../types';
import { InstructionFields } from './base';

export const ForwardDeviceInstructionFields = ({
  onChange,
  option,
  locationId,
}: {
  onChange: InstructionFieldsProps<ForwardDevicePayload>['onChange'];
  option: ForwardDevicePayload;
  locationId: string;
}) => {
  const getDeviceName = (device: DevicesTypes.DeviceModel) => {
    if (device.type === 'desk_phone' || device.type === 'softphone') {
      return device.sipProfile?.name || device.name;
    }
    return device.name;
  };

  const { data = [], isLoading } = useQuery({
    queryKey: [...queryKeys.devicesWithAddresses(), locationId],
    queryFn: () => DevicesApi.listDevicesWithHeaders(locationId),
    select: (data) => {
      return data
        .sort((a, b) => a.sipDevice.name.localeCompare(b.sipDevice.name))
        .map((device) => ({
          value: device.sipDevice.ID,
          label: getDeviceName(device.sipDevice),
        }));
    },
    staleTime: 0,
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<ForwardDevicePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          deviceId: data,
        },
        value: option,
      }}
    />
  );
};

const schema = {
  type: 'forwardDevice',
  fields: {
    deviceId: {
      label: i18next.t('Device', { ns: 'phone' }),
      name: 'deviceId',
      type: 'dropdown',
    },
    callerName: {
      label: i18next.t('Caller Label (Optional)', { ns: 'phone' }),
      name: 'callerName',
      type: 'text',
      helperText: i18next.t('Label displays on Weave phone screen while call is ringing', { ns: 'phone' }),
      trackingId: trackingId({ context: 'setting', feature: 'phone-trees', details: 'caller-label' }),
    },
  },
} as const;
