import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { RegisterTerminalSwitch } from './register-terminal/register-terminal-switch';

export const RegisterTerminal = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.registerTerminal}>
      <RegisterTerminalSwitch />
    </StepPerformanceWrapper>
  );
};
