import { useEffect, useMemo } from 'react';
import { CallIntelQueries } from '@frontend/api-call-intel';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { CustomTooltipTitle } from '..';
import { callIntelligenceUtils, formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { OverviewSummaryOpportunitiesStack, SentimentsChart } from '../charts';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from '../hooks';
import { SubViewOpportunitiesInlineStats } from './sub-view-opportunities-inline-stats';

type Props = {
  metricsFor?: string;
  onChangeLoadingState?: (isLoading: boolean) => void;
};

export const OpportunitiesByTypeSubView = ({ metricsFor, onChangeLoadingState }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { isDemoAccount, showDemoChipAndBanner } = useCallIntelDemoFlags();
  const { chartLabelColors, dataColors, dataLabels, filterHintText, filters, subView } = useCallIntelShallowStore(
    'chartLabelColors',
    'dataColors',
    'dataLabels',
    'filterHintText',
    'filters',
    'subView'
  );

  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const filteredOverviewAppointmentsDrillDown = useMemo(() => {
    return CallIntelMockData.getFilteredOverviewAppointmentsDrillDown(
      CallIntelMockData.overviewAppointmentsDrillDown,
      filters
    );
  }, [filters]);

  const filteredOverviewCategoriesDrillDown = useMemo(() => {
    return CallIntelMockData.getFilteredOverviewCategoriesDrillDown(
      CallIntelMockData.overviewCategoriesDrillDown,
      filters,
      lastUsedVertical
    );
  }, [filters, lastUsedVertical]);

  const drillDownFilters = callIntelligenceUtils.getDrillDownFilterKeys(subView);

  const { data: aggregatedData, isFetching: isLoadingAggregatedData } = CallIntelQueries.useGetOverviewDetails({
    demoData:
      subView.type === 'appointment-type' ? filteredOverviewAppointmentsDrillDown : filteredOverviewCategoriesDrillDown,
    isDemoAccount,
    payload: { drillDownOptions: drillDownFilters, filters },
    realQueryOptions: {
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        alert.error(t('Failed to fetch overview data'));
        console.error(err);
      },
    },
  });

  const { data: stats, isFetching: isLoadingStats } = CallIntelQueries.useGetOverviewStats({
    demoData:
      subView.type === 'appointment-type' ? filteredOverviewAppointmentsDrillDown : filteredOverviewCategoriesDrillDown,
    isDemoAccount,
    payload: {
      drillDownOptions: drillDownFilters,
      filters,
      includes: { opportunities: true },
    },
    realQueryOptions: {
      staleTime: 5 * 60 * 1000,
      onError: (err) => {
        alert.error(t('Failed to fetch overview current stats'));
        console.error(err);
      },
    },
  });

  const targetedData = useMemo(() => {
    const isAppointmentType = subView.type === 'appointment-type';

    // Show(target) opposite data
    return {
      colors: isAppointmentType ? dataColors.categories : dataColors.appointmentTypes,
      data: isAppointmentType ? aggregatedData?.categories : aggregatedData?.appointmentTypes,
      labelColors: isAppointmentType ? chartLabelColors.categories : chartLabelColors.appointmentTypes,
      labels: isAppointmentType ? dataLabels.categories : dataLabels.appointmentTypes,
      title: isAppointmentType
        ? t('{{name}} Call Categories', { name: dataLabels.appointmentTypes?.[subView.id || ''] }).trim()
        : t('{{name}} Call Appointment Types', { name: dataLabels.categories?.[subView.id || ''] }).trim(),
    };
  }, [aggregatedData]);

  const chartData = useMemo(() => {
    return {
      groups: Object.entries(targetedData.data || {}).map(([name, value]) => ({
        name,
        values: {
          [name]: value,
        },
      })),
    };
  }, [targetedData]);

  useEffect(() => {
    onChangeLoadingState?.(isLoadingAggregatedData);
  }, [isLoadingAggregatedData]);

  return (
    <div>
      <SubViewOpportunitiesInlineStats data={stats} isLoading={isLoadingStats} metricsFor={metricsFor} />
      <OverviewSummaryOpportunitiesStack data={stats} isLoading={isLoadingAggregatedData} />
      <Chart.HorizontalContainer marginBottom={0}>
        <Chart
          colors={targetedData.colors || {}}
          commonTooltipLabel={t('Calls')}
          isLoading={isLoadingAggregatedData}
          labels={targetedData.labels}
        >
          <Chart.Header
            leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
            subtitle={filterHintText}
            title={targetedData.title}
          />
          <Chart.BarChart
            appearance={{
              customTooltipTitle: ({ groupName }) => (
                <CustomTooltipTitle addPrefix label={targetedData.labels?.[groupName]} />
              ),
              customXAxisTick: ({ labels, groupName, ...rest }) => {
                return (
                  <XAxisLabelValueTick
                    {...rest}
                    color={targetedData.labelColors?.[groupName]}
                    label={labels?.[groupName] || groupName}
                    value={formatters.value.format(
                      targetedData?.data?.[groupName as keyof (typeof targetedData)['data']]
                    )}
                  />
                );
              },
              customTooltipData: () => {
                if (!targetedData?.data) return [];
                return Object.entries(targetedData.data).map(([key, value]) => ({
                  id: key,
                  label: targetedData.labels?.[key] || key,
                  color: targetedData.labelColors?.[key] || theme.colors.neutral50,
                  formattedValue: formatters.value.format(value),
                }));
              },
              margin: { bottom: 20 },
              showXAxis: true,
            }}
            data={chartData}
            formatValue={formatters.value.format}
          />
        </Chart>
        <SentimentsChart internalPage isLoading={isLoadingAggregatedData} sentiments={aggregatedData?.sentiments} />
      </Chart.HorizontalContainer>
    </div>
  );
};
