import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ARRoiOverviewCard } from './ar-roi-overview-card';
import { BTRoiOverviewCard } from './bt-roi-overview-card';
import { MCTRoiOverviewCard } from './mct-roi-overview-card';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  hideSMSCount?: boolean;
  isAppointmentRemindersRoiEnabled?: boolean;
  isBulkTextingRoiEnabled?: boolean;
}

export const RoiOverviewCards = ({
  hideSMSCount,
  isAppointmentRemindersRoiEnabled,
  isBulkTextingRoiEnabled,
  ...rest
}: Props) => {
  return (
    <div css={[styles.wrapper, hideSMSCount ? styles.threeCards : styles.twoCards]} {...rest}>
      <MCTRoiOverviewCard hideSMSCount={hideSMSCount} />
      {isBulkTextingRoiEnabled && <BTRoiOverviewCard hideSMSCount={hideSMSCount} />}
      {isAppointmentRemindersRoiEnabled && <ARRoiOverviewCard hideSMSCount={hideSMSCount} />}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    gap: ${theme.spacing(3)};
    flex-wrap: wrap;
  `,

  // TODO :: Remove after GA of Bulk Texting ROI
  twoCards: css`
    .roi-overview-card-button {
      width: 100%;

      @container (min-width: 760px) {
        width: calc(100% / 2 - ${theme.spacing(3)});
      }
    }

    .roi-overview-card-data-items {
      justify-content: space-around;

      @container (min-width: 420px) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }
  `,

  threeCards: css`
    .roi-overview-card-button {
      width: 100%;

      @container (min-width: 630px) {
        width: calc(100% / 2 - ${theme.spacing(3)});
      }

      @container (min-width: 960px) {
        width: calc(100% / 3 - ${theme.spacing(3)});
      }
    }

    .roi-overview-card-data-items {
      justify-content: space-around;
      flex-wrap: wrap;

      p {
        text-align: center;

        @container (min-width: 305px) {
          text-align: left;
        }
      }

      @container (min-width: 305px) {
        justify-content: space-between;
      }
    }
  `,
};
