import { useQueryClient, useMutation } from 'react-query';
import { VoicemailConfigAPI } from '@frontend/api-voicemail-boxes';
import { getInitialParams } from '@frontend/env';
import { http } from '@frontend/fetch';
import { queryKeys } from '../../../query-keys';
import {
  VoicemailBoxCreateRequest,
  VoicemailBoxListResponse,
  VoicemailBoxUpdateRequest,
  VoicemailGreetingBulkUpdateRequest,
  VoicemailGreetingCreateRequest,
  VoicemailGreetingUpdateRequest,
} from './types';

export const useVoicemailBoxMutations = (phoneTenantId: string) => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (payload: VoicemailBoxCreateRequest) => {
      return VoicemailConfigAPI.Create(payload);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<VoicemailBoxListResponse>(
        [phoneTenantId, ...queryKeys.settings.listVoicemailBox()],
        (old) => {
          return {
            voicemailBoxes: [
              ...(old?.voicemailBoxes ?? []),
              {
                // Add the new voicemail box to the list.
                // NOTE: the data returned from the API in the mutation response is not the same as the data in the list
                // and needs to be transformed.
                mailbox: data.mailbox,
                extension: data.extension,
                locationIds: data.locationIds,
                isDeletable: false,
              },
            ],
          };
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBox()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailExtensions()]);
    },
  });

  const update = useMutation({
    mutationFn: (payload: VoicemailBoxUpdateRequest) => {
      return VoicemailConfigAPI.Update(payload);
    },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBox()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readVoicemailBox(payload.voicemailBoxId)]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailExtensions()]);
    },
  });

  const remove = useMutation({
    mutationFn: (payload: { voicemailBoxId: string }) => {
      return VoicemailConfigAPI.Delete(payload);
    },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBox()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readVoicemailBox(payload.voicemailBoxId)]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailExtensions()]);
    },
  });

  return {
    create,
    update,
    remove,
  };
};

type UploadAndCreateGreetingResponse = {
  greetingID: string;
  mailboxID: string;
  mediaID: string;
  greetingName: string;
  greetingNumber: number;
  filePath: string;
};

export const useVoicemailBoxGreetingMutations = (phoneTenantId: string, mailboxId: string) => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (payload: VoicemailGreetingCreateRequest) => {
      return VoicemailConfigAPI.CreateGreetingFromMedia(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBoxGreetings(mailboxId)]);
    },
  });

  /**
   * This mutation does not use a schema endpoint
   */
  const uploadAndCreateGreeting = useMutation({
    mutationFn: ({
      file,
      greetingName,
      greetingNumber,
      mailboxId,
    }: {
      file: File;
      greetingName: string;
      greetingNumber: number;
      mailboxId: string;
    }) => {
      const data = new FormData();
      data.append('media-file', file);

      data.append('mailbox_id', mailboxId);
      data.append('greeting_name', greetingName);
      data.append('greeting_number', greetingNumber.toString());

      return http.post<UploadAndCreateGreetingResponse>(
        `${getInitialParams().backendApi}/phone/voicemail-config/v2/${mailboxId}/upload-greeting`,
        data
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBoxGreetings(mailboxId)]);
    },
  });

  const update = useMutation({
    mutationFn: (payload: VoicemailGreetingUpdateRequest) => {
      return VoicemailConfigAPI.UpdateGreeting(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBoxGreetings(mailboxId)]);
    },
  });

  const bulkUpdate = useMutation({
    mutationFn: (payload: VoicemailGreetingBulkUpdateRequest) => {
      return VoicemailConfigAPI.UpdateMailboxGreetings(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBoxGreetings(mailboxId)]);
    },
  });

  const remove = useMutation({
    mutationFn: (payload: { mailboxId: string; greetingId: string }) => {
      return VoicemailConfigAPI.DeleteGreeting(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listVoicemailBoxGreetings(mailboxId)]);
    },
  });

  return {
    create,
    uploadAndCreateGreeting,
    update,
    bulkUpdate,
    remove,
  };
};
