import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { TableFilters } from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';
import { DateRange, findDatesRange, getMatchingDateRangeKey } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { CustomPeriodGroups, TimePeriodSelector } from '../filter-selectors';
import { FiltersTray } from '../filters-tray';
import { AdditionalAppointmentsFilters } from './additional-appointments-filter';
import { useAppointmentAnalyticsShallowStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
};

const DEFAULT_DAY = 'last-14-days';

const upcomingDaysRange: Record<AppointmentAnalyticsTypes.AppointmentsUpcoming, DateRange> = {
  'today-upcoming-appointments': findDatesRange({ range: 'day' }),
  'tomorrow-appointments': findDatesRange({ offset: 1, range: 'day', type: 'future' }),
  'next-7-days': findDatesRange({ offset: 7, range: 'day', type: 'future' }),
  'next-14-days': findDatesRange({ offset: 14, range: 'day', type: 'future' }),
  'next-30-days': findDatesRange({ offset: 30, range: 'day', type: 'future' }),
};

const pastDaysRange: Record<AppointmentAnalyticsTypes.AppointmentsPast, DateRange> = {
  'today-past-appointments': findDatesRange({ range: 'day' }),
  'yesterday-appointments': findDatesRange({ offset: 1, range: 'day' }),
  'last-7-days': findDatesRange({ offset: 7, range: 'day' }),
  'last-14-days': findDatesRange({ offset: 14, range: 'day' }),
  'last-30-days': findDatesRange({ offset: 30, range: 'day' }),
};

const datesRangeMap = {
  ...upcomingDaysRange,
  ...pastDaysRange,
};

const defaultDates = datesRangeMap[DEFAULT_DAY];

export const AppointmentAnalyticsFilters = ({ isLoadingData }: Props) => {
  const { t } = useTranslation('analytics');
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];
  const locationData = accessibleLocationData[locationId];

  const {
    filters,
    hasCustomFilters,
    locationId: appointmentAnalyticsLocationId,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationId,
  } = useAppointmentAnalyticsShallowStore(
    'filters',
    'hasCustomFilters',
    'locationId',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setLocationId'
  );

  const locationSwitched = appointmentAnalyticsLocationId !== locationId;
  const [trayFilters, setTrayFilters] = useState<AppointmentAnalyticsTypes.Filters>({});
  const [controlledSelectedPeriod, setControlledSelectedPeriod] = useState<
    AppointmentAnalyticsTypes.AppointmentsTimeFrame | undefined
  >();

  const timeFilterLabels: Record<string, string> = {
    'today-upcoming-appointments': t('Today'),
    'tomorrow-appointments': t('Tomorrow'),
    'next-7-days': t('Next 7 days'),
    'next-14-days': t('Next 14 days'),
    'next-30-days': t('Next 30 days'),

    'today-past-appointments': t('Today'),
    'yesterday-appointments': t('Yesterday'),
    'last-7-days': t('Last 7 days'),
    'last-14-days': t('Last 14 days'),
    'last-30-days': t('Last 30 days'),
  };

  const defaultFilters: AppointmentAnalyticsTypes.Filters = useMemo(
    () => ({
      EndDate: defaultDates.endDate,
      IsPastData: true,
      LocationID: locationId ? [locationId] : [],
      OrderByAsc: false,
      OrderByField: 'AppointmentDate',
      StartDate: defaultDates.startDate,
      Status: '',
      TimeFrame: DEFAULT_DAY,
      TimeZone: locationData?.timezone,
    }),
    [locationId]
  );

  const timePeriodGroups: CustomPeriodGroups = {
    datesMap: datesRangeMap,
    groups: [
      {
        label: t('Upcoming Appointments'),
        period: Object.keys(upcomingDaysRange),
      },
      {
        label: t('Past Appointments'),
        period: Object.keys(pastDaysRange),
      },
    ],
    labels: timeFilterLabels,
  };

  const handleUpdateTrayFilters = (newFilters: Partial<AppointmentAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    const timeFilter = getMatchingDateRangeKey({
      datesMap: datesRangeMap,
      endDate: filters.EndDate,
      startDate: filters.StartDate,
    });

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      timeFilterLabels[timeFilter || ''] ||
        `${dayjs(filters.StartDate).format('DD MMM, YYYY')} - ${dayjs(filters.EndDate).format('DD MMM, YYYY')}`
    );
  }, [filters]);

  useEffect(() => {
    // Reset when location is switched or default filters are updated
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters || locationSwitched) {
      applyDefaultFilters();
    }
  }, [defaultFilters, locationId]);

  useEffect(() => {
    // appointmentAnalyticsLocationId is used to identify if the location has been switched as the store would retain the previous filters
    if (!appointmentAnalyticsLocationId || locationSwitched) {
      setLocationId(locationId);
    }
  }, [appointmentAnalyticsLocationId, locationId]);

  return (
    <FiltersTray
      disableTray={isLoadingData}
      headerLabel={t('Filter Appointment Analytics')}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={hasCustomFilters}
      trackingId={trackingIds.appointmentAnalytics.filtersOpen}
    >
      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodSelector<AppointmentAnalyticsTypes.AppointmentsTimeFrame>
            controlledSelectedPeriod={controlledSelectedPeriod}
            customPeriodGroups={timePeriodGroups}
            defaultPeriod={DEFAULT_DAY}
            endDate={trayFilters.EndDate}
            onChange={({ endDate, selectedPeriod, startDate }) => {
              // Since there are two instances of "today," one for the past and one for the upcoming,
              // and both have the same date range, there's a risk of overwriting the isPastData value.
              // To address this issue, we must manually manage this scenario using a controlled state.
              setControlledSelectedPeriod(selectedPeriod);

              const isPastData =
                !!selectedPeriod &&
                (selectedPeriod.includes('past') ||
                  selectedPeriod.includes('last') ||
                  selectedPeriod.includes('yesterday'));

              handleUpdateTrayFilters({
                EndDate: endDate,
                IsPastData: isPastData,
                StartDate: startDate,
                // Reset status when past data value has changed
                Status: trayFilters.IsPastData === isPastData ? trayFilters.Status : '',
                TimeFrame: selectedPeriod,
              });
            }}
            startDate={trayFilters.StartDate}
          />
        </div>
      </TableFilters.Section>

      <AdditionalAppointmentsFilters
        disabled={isLoadingData}
        filters={trayFilters}
        onChange={handleUpdateTrayFilters}
      />
    </FiltersTray>
  );
};
