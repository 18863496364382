import { Chart } from '@frontend/charts';
import { i18next, Trans, useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { featureFlags } from '../../../../feature-flags';
import { formatters } from '../../../../utils';
import { CompareLocationsButton } from '../../../compare-locations-button';
import { DemoChip } from '../../../demo-chip';
import { InfoTipPopover } from '../../../info-tip-popover';
import { NoShowRateBestPractices } from '../components';
import { useFetchARROIOOverview } from '../hooks';
import { ARChartsBase } from './ar-charts-base';

const colors = {
  noRemindersPractice: theme.colors.critical30,
  yourPractice: theme.colors.secondary.seaweed40,
};

const labels = {
  noRemindersPractice: i18next.t('Practices Not Using Appointment Reminders', { ns: 'analytics' }),
  yourPractice: i18next.t('Your Practice', { ns: 'analytics' }),
};

const trackingIdBase = 'no-show-rate';

interface Props extends ReturnType<typeof useFetchARROIOOverview> {
  onShowMoreClick?: () => void;
  showCompareMulti?: boolean;
}

const ShowRateMessage = ({ average }: { average: number }) => {
  const { t } = useTranslation('analytics');

  let content = <></>;

  if (average < 0) {
    content = (
      <Trans t={t} values={{ average: formatters.percent.appendPercent(average) }}>
        Your Practice has an average of <strong>{{ average }}</strong> higher no-show rate compared to Practices not
        using Appointment Reminders.
      </Trans>
    );
  } else {
    content = (
      <Trans t={t} values={{ average: formatters.percent.appendPercent(Math.abs(average)) }}>
        Your Practice has an average of <strong>{{ average }}</strong> lower no-show rate compared to Practices not
        using Appointment Reminders 🚀
      </Trans>
    );
  }

  return <Text>{content}</Text>;
};

export const ARRoiNoShowRateChart = ({ aggregated, isLoading, onShowMoreClick, showCompareMulti }: Props) => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);
  const { navigate } = useSettingsNavigate();

  if (aggregated?.noShowRate?.enableNoShowRateBestPractices) {
    return (
      <Chart isLoading={isLoading}>
        <Chart.Header title={t('Best Practices to lower No-Show Rates')} leftElement={isDemoAccount && <DemoChip />} />
        <ARChartsBase
          onShowMoreClick={() => {
            navigate({ to: '/messaging/auto-messages' });
          }}
          showMoreLabel={t('Optimize Appointment Reminder Usage')}
          trackingIdBase={trackingIdBase}
        >
          <NoShowRateBestPractices />
        </ARChartsBase>
      </Chart>
    );
  }

  return (
    <Chart
      colors={colors}
      isLoading={isLoading}
      labels={labels}
      noBorder={!onShowMoreClick}
      onClick={onShowMoreClick}
      trackingId={`${trackingIdBase}-chart`}
    >
      {onShowMoreClick && (
        <Chart.Header
          title={t('No-Show Rate')}
          infoTip={
            <InfoTipPopover>
              {t(
                'The No-Show Rate is the percentage of scheduled appointments that patients fail to attend without prior notice or cancellation.'
              )}
            </InfoTipPopover>
          }
          bottomElement={
            showCompareMulti && (
              <CompareLocationsButton onClick={onShowMoreClick} trackingId={`${trackingIdBase}-compare-locations`} />
            )
          }
          leftElement={isDemoAccount && <DemoChip />}
          removeHeaderBottomMargin={!showCompareMulti}
        />
      )}
      <ShowRateMessage average={aggregated.noShowRate.difference} />
      <ARChartsBase onShowMoreClick={onShowMoreClick} trackingIdBase={trackingIdBase}>
        <Chart.AreaChart
          appearance={{
            customYAxisTickFormat: formatters.percent.appendPercent,
            height: 200,
            margin: { top: 30 },
            showXAxis: true,
            showYAxis: true,
            xAxisTickWidth: 100,
          }}
          data={{ groups: aggregated.noShowRate.groups }}
          formatValue={formatters.percent.appendPercent}
        />
        <Chart.Legends style={{ marginTop: theme.spacing(2) }} />
      </ARChartsBase>
    </Chart>
  );
};
