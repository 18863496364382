import { CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../feature-flags';
import { useIsPAEligibleAccount } from './use-is-pa-eligible-account';
import { usePracticeAnalyticsShallowStore } from './use-practice-analytics-store';

// This hook is used in the Analytics app to determine if the location should show demo data
export const useIsPADemoAccount = (): boolean | undefined => {
  const { getCustomizationFlagValue } = useScopedAppFlagStore();
  const { isPAEligibleAccount } = useIsPAEligibleAccount();
  const { isDemoModal } = usePracticeAnalyticsShallowStore('isDemoModal');
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoFFEnabled = getFeatureFlagValue(featureFlags.practiceAnalyticsDemo);

  const isCustomerLocation = accessibleLocationData[selectedLocationIds[0]]?.opsType === 'REGULAR';
  const isCFActive = getCustomizationFlagValue('practiceanalytics');
  const isCFPromote = getCustomizationFlagValue(
    'practiceanalytics',
    CustomizationFlagTypes.CustomizationFlagState.PROMOTE
  );
  const isCFHide = !isCFActive && !isCFPromote;

  // If Demo modal is open then always show demo data
  if (isDemoModal) {
    return true;
  }

  if (isPAEligibleAccount && isCustomerLocation) {
    // If it's a real customer eligible location
    // If CF is HIDE, then weave users should see demo data
    // If CF is HIDE or PROMOTE, then customer should see demo data
    return (isCFHide && isWeaveUser()) || ((isCFHide || isCFPromote) && !isWeaveUser());
  }

  // If it's a test location used by weave
  if (!isCustomerLocation) {
    return isDemoFFEnabled;
  }

  return;
};
