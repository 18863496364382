import { PaymentOrigin } from '@frontend/api-invoices';
import { i18next } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';

/**
 * @deprecated import the same from '@frontend/payments-hooks'
 */
export const paymentTypeMap: Record<PaymentOrigin, string> = {
  LOCATION: i18next.t('Manual Card Entry', { ns: 'payments' }),
  LOCATION_PORTAL: i18next.t('Online Bill Pay', { ns: 'payments' }),
  PAYMENT_PORTAL: i18next.t('Text To Pay', { ns: 'payments' }),
  TERMINAL: i18next.t('Terminal', { ns: 'payments' }),
  MOBILE_TAP_TO_PAY: i18next.t('Mobile Tap To Pay', { ns: 'payments' }),
  ONLINE_SCHEDULING: i18next.t('Online Scheduling', { ns: 'payments' }),
  PAYMENT_PLAN: i18next.t('Payment Plan', { ns: 'payments' }),
  UNKNOWN: i18next.t('Unknown', { ns: 'payments' }),
};

/**
 * @deprecated import the same hook from '@frontend/payments-hooks'
 */
export const usePaymentTypeMap = () => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const schedulingIntegrationFF = getFeatureFlagValue('schedule-payments-integration-release');

  const newPaymentTypeMap: Partial<typeof paymentTypeMap> = { ...paymentTypeMap };
  if (!schedulingIntegrationFF) {
    delete newPaymentTypeMap.ONLINE_SCHEDULING;
  }
  return { paymentTypeMap: newPaymentTypeMap };
};
