import { useNode } from '@craftjs/core';
import { ComposerSectionMeta, ComposerSectionProps, SetComposerSectionMeta, SetComposerSectionProps } from '../types';

export const useComposerSection = <T>() => {
  const {
    actions: { setProp: setProps, setCustom: setMeta },
    props,
    meta,
    sectionHTML,
  } = useNode((node) => ({
    props: node.data.props as T,
    meta: node.data.custom as ComposerSectionMeta,
    sectionHTML: node.dom,
  }));

  return {
    props: props as T & ComposerSectionProps,
    setProps: setProps as SetComposerSectionProps<T>,
    meta,
    setMeta: setMeta as SetComposerSectionMeta,
    sectionHTML,
  };
};
