import { contextFactory } from '@frontend/design-system';
import { CollectPaymentMultiFlow, CollectPaymentMultiState } from './collect-payment-multi.types';

const [Context, useCollectPaymentMultiContextMethod] = contextFactory<CollectPaymentMultiState>(
  '',
  {} as CollectPaymentMultiState
);

export const useCollectPaymentMultiContext = <F extends CollectPaymentMultiFlow = 'create'>() =>
  useCollectPaymentMultiContextMethod() as CollectPaymentMultiState<F>;

export type CollectPaymentMultiProviderProps = React.PropsWithChildren<CollectPaymentMultiState>;

export const CollectPaymentMultiProvider = ({ children, ...props }: CollectPaymentMultiProviderProps) => {
  return <Context.Provider value={props}>{children}</Context.Provider>;
};
