import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { MappingType_Enum } from '@weave/schema-gen-ts/dist/schemas/syncapp/mappings/v1/service.pb';
import { useTranslation } from '@frontend/i18n';
import { SchemaMappingsService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { PopoverDialog, usePopoverDialog, useFormField, Button } from '@frontend/design-system';
import { useScheduleActionsContainerMethod } from '../../../../../../context/ScheduleActionsContainerContext';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../../stores/use-schedule-actions-appointment-list-store';
import { getFilteredAppointments } from '../../../../helpers';
import { FilterButton } from './FilterButton';
import { FilterModal } from './FilterModal';

export const FiltersContainer = () => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { selectedLocationIds } = useAppScopeStore();
  const [appointmentStatuses, setAppointmentStatuses] = useState<string[]>([]);
  const { selectedDate: scheduleActionsDate } = useScheduleActionsContainerMethod();
  const {
    defaultAppointmentList,
    setAppointmentList,
    setStatusFilter,
    statusFilter,
    setProviderList,
    hasActiveFilters,
    setHasActiveFilters,
    providerList,
    defaultProviderList,
    insuranceStatusFilters,
    setInsuranceStatusFilters,
  } = useScheduleActionsAppointmentListInfoShallowStore(
    'defaultAppointmentList',
    'setAppointmentList',
    'setStatusFilter',
    'statusFilter',
    'setProviderList',
    'setHasActiveFilters',
    'hasActiveFilters',
    'providerList',
    'defaultProviderList',
    'insuranceStatusFilters',
    'setInsuranceStatusFilters'
  );

  const {
    getDialogProps,
    getTriggerProps,
    close: closeFilterModal,
  } = usePopoverDialog<HTMLAnchorElement | HTMLButtonElement>({
    placement: 'bottom-end',
  });

  const resetFilters = () => {
    setAppointmentList(defaultAppointmentList);
    setHasActiveFilters(false);
    setStatusFilter && setStatusFilter('');
    setProviderList([]);
    setInsuranceStatusFilters([]);
  };

  useEffect(() => {
    if (statusFilter || !!insuranceStatusFilters.length || !!providerList.length) {
      setHasActiveFilters(true);
      const appointmentArray = getFilteredAppointments({
        appointmentsToFilter: defaultAppointmentList,
        providerList: !!providerList.length
          ? defaultProviderList.filter((provider) => providerList.includes(provider.id ?? ''))
          : defaultProviderList,
        appointmentStatus: statusFilter && statusFilter !== 'all' ? statusFilter : '',
      });
      setAppointmentList(appointmentArray);
    } else {
      setHasActiveFilters(false);
    }
  }, [statusFilter, providerList, scheduleActionsDate]);

  useEffect(() => {
    if (hasActiveFilters) {
      const appointmentArray = getFilteredAppointments({
        appointmentsToFilter: defaultAppointmentList,
        providerList: !!providerList.length
          ? defaultProviderList.filter((provider) => providerList.includes(provider.id ?? ''))
          : defaultProviderList,
        appointmentStatus: statusFilter && statusFilter !== 'all' ? statusFilter : '',
      });
      setAppointmentList(appointmentArray);
    }
  }, [hasActiveFilters, providerList, scheduleActionsDate, defaultProviderList]);

  const getSelectedProviderIds = (): string[] => {
    const filteredProviderIds = defaultProviderList.map(({ id }) => id ?? '');

    const selectedProviderIds = !!providerList?.length
      ? providerList.filter((id) => id && filteredProviderIds.includes(id))?.map((id) => id ?? '')
      : filteredProviderIds;

    return selectedProviderIds;
  };

  const providerCheckListField = useFormField(
    {
      type: 'checklist',
      value: getSelectedProviderIds(),
      minRequired: 1,
    },
    [defaultProviderList, providerList]
  );

  const appointmentStatusRadioField = useFormField(
    {
      type: 'radio',
      value: statusFilter || 'all',
    },
    [statusFilter]
  );

  const insuranceStatusCheckListField = useFormField(
    {
      type: 'checklist',
      value: insuranceStatusFilters,
    },
    [insuranceStatusFilters]
  );

  const getAndSetAppointmentStatuses = async (locationIds: string[]) => {
    try {
      const res = await Promise.all(
        locationIds.map((locationId) =>
          SchemaMappingsService.ListMappings({ locationId, type: MappingType_Enum.APPOINTMENT })
        )
      );

      const statuses = res.flatMap(({ mappings }) =>
        mappings.flatMap(({ mappings }) =>
          mappings.map(({ key }) => key.toLowerCase()).filter((key) => key !== 'canceled')
        )
      );

      setAppointmentStatuses(['all', ...new Set(statuses)]);
    } catch (error) {
      console.error('Failed to fetch appointment statuses', error);
    }
  };

  useEffect(() => {
    getAndSetAppointmentStatuses(selectedLocationIds);
  }, [selectedLocationIds]);

  return (
    <>
      <FilterButton triggerProps={getTriggerProps} />
      <Button
        variant='tertiary'
        onClick={resetFilters}
        disabled={!hasActiveFilters}
        css={{
          color: theme.colors.primary50,
          fontWeight: 'bold',
          height: '100%',
        }}
      >
        {t('Reset')}
      </Button>
      <PopoverDialog
        css={css`
          width: 260px;
          padding: ${theme.spacing(1, 2)};
          border-radius: ${theme.borderRadius.small};
        `}
        {...getDialogProps()}
      >
        <FilterModal
          closeFilterModal={closeFilterModal}
          appointmentStatuses={appointmentStatuses}
          providerCheckListField={providerCheckListField}
          appointmentStatusRadioField={appointmentStatusRadioField}
          insuranceStatusCheckListField={insuranceStatusCheckListField}
        />
      </PopoverDialog>
    </>
  );
};
