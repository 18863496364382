import { useQuery, UseQueryOptions } from 'react-query';
import * as api from './api';

type QueryOptionsType<T extends (...args: any) => Promise<any>> = Pick<
  UseQueryOptions<Awaited<ReturnType<T>>>,
  'onError' | 'onSuccess' | 'enabled'
>;

export const useFirestoreTokenQuery = (opts?: QueryOptionsType<typeof api.getFirebaseToken>) => {
  return useQuery({
    queryKey: ['firestore-token'],
    retry: false,
    queryFn: api.getFirebaseToken,
    ...opts,
  });
};
