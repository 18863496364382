import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation } from 'react-query';
import { PaymentMethodType } from '@frontend/api-payment-plans';
import { PaymentIntent } from '@frontend/api-weave-pay';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { UseCreatePaymentIntentOptions, useCreatePaymentIntent } from '../../../hooks/use-create-payment-intent';
import { useValidateACHBalance } from '../../../hooks/use-validate-ach-balance';
import { showConfirmCardPaymentErrors, showConfirmPaymentErrors } from './stripe-errors';

export interface UseMakePaymentOptions extends UseCreatePaymentIntentOptions {
  includeElementsInfo?: boolean;
}

export const useMakePayment = ({
  includeElementsInfo = false,
  ...createPaymentIntentOptions
}: UseMakePaymentOptions) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  const errorAlert = (message: string) =>
    alerts.error({
      autoDismissAfter: 12000,
      message,
    });

  const { createPaymentIntent, paymentMethodId, confirmPaymentType } =
    useCreatePaymentIntent(createPaymentIntentOptions);

  const { isACHBalanceValidated } = useValidateACHBalance({
    amount: createPaymentIntentOptions.amount,
    locationId: createPaymentIntentOptions.locationId,
    paymentMethod: createPaymentIntentOptions.paymentMethod,
  });

  const stripeConfirmPayment = async (paymentIntent: PaymentIntent, type: PaymentMethodType) => {
    if (!stripe) return false;
    const stripeConfirmPaymentMethod =
      type === 'us_bank_account' ? stripe.confirmUsBankAccountPayment : stripe.confirmCardPayment;
    const { error } = await stripeConfirmPaymentMethod(paymentIntent.clientSecret, {
      payment_method: paymentMethodId,
    });
    if (error) showConfirmCardPaymentErrors(error, errorAlert);

    return !error;
  };

  const stripeConfirmPaymentWithElements = async (paymentIntent: PaymentIntent) => {
    if (!stripe || !elements) return false;
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: paymentIntent.clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/payments/invoices`,
      },
    });

    if (error) showConfirmPaymentErrors(error, errorAlert);
    return !error;
  };

  const submitElements = async () => {
    if (!elements) return false;
    let isSuccessful = false;
    try {
      elements.update({ amount: createPaymentIntentOptions.amount });
      const { error } = await elements.submit();
      if (error) errorAlert(t('There was an issue with the information provided. Please review and try again.'));
      isSuccessful = !error;
    } catch {
      // stripe failed to communicate
      errorAlert(t('Error processing payment. Please try again.'));
    }
    return isSuccessful;
  };

  const { mutateAsync: makePaymentMutation, isLoading: makingPayment } = useMutation({
    mutationFn: async () => {
      try {
        const balanceValidated = await isACHBalanceValidated();
        if (!balanceValidated) {
          errorAlert(t('Bank account does not have sufficient balance'));
          return;
        }
      } catch (err) {
        console.error(err);
        alerts.error(t('Failed to check bank account balance'));
        return;
      }

      let workflowId: string | undefined;
      const { paymentIntent, paymentId } = await createPaymentIntent();
      if (paymentIntent) {
        if (!stripe || !elements) {
          errorAlert(t('Stripe not loaded yet. Try again!'));
        } else {
          if (!includeElementsInfo || (await submitElements())) {
            let isSuccessful = false;
            try {
              if (includeElementsInfo) isSuccessful = await stripeConfirmPaymentWithElements(paymentIntent);
              else isSuccessful = await stripeConfirmPayment(paymentIntent, confirmPaymentType);
              if (isSuccessful) alerts.success(t('Payment submitted successfully!'));
            } catch {
              errorAlert(t('Error processing payment with Stripe. Try again!'));
            }
            if (isSuccessful) workflowId = paymentId;
          }
        }
      }
      return workflowId;
    },
  });

  return {
    createPaymentIntent,
    makePayment: makePaymentMutation,
    makingPayment,
  };
};
