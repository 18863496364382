import { css } from '@emotion/react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { OnboardingModulesApi, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, PrimaryButton, Text } from '@frontend/design-system';
import { TRACKING_IDS } from '../../constants';
import { useTaskTrayShallowStore } from '../../hooks';
import { InfoText } from './info-text';
import { TaskHeader } from './task-header';

interface Props {
  tasks: OnboardingModulesTypes.TaskTrayTaskInfo[];
}

export const TaskList = ({ tasks }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  const { selectedTaskId, selectedModuleId, setSelectionInfo } = useTaskTrayShallowStore(
    'selectedTaskId',
    'selectedModuleId',
    'setSelectionInfo'
  );
  const user = getUser();
  const { selectedLocationIds } = useAppScopeStore();

  const handleStartTaskClick = (task: OnboardingModulesTypes.TaskTrayTaskInfo) => {
    setSelectionInfo({ moduleId: task.moduleId, taskId: task.id, stepIndex: 0 });

    OnboardingModulesApi.sendTaskEvent({
      locationId: selectedLocationIds[0],
      userId: user?.userID ?? '',
      guideName: task.title,
      guideVersion: 1,
      eventDescription: 'Start Task Clicked in Task Tray',
      stepNumber: 0,
      moduleName: `${task.moduleId}`,
      urlRoute: window.location.href,
      eventType: EventType.EVENT_START_TASK,
      taskId: task.id,
    });
  };

  const handleTaskSelectionChange = (taskId: string | null) => {
    setSelectionInfo({ moduleId: selectedModuleId, taskId: taskId === null ? null : +taskId, stepIndex: null });
  };

  return (
    <Accordion
      variant='filled'
      chevronIcon={false}
      controlledValue={String(selectedTaskId)}
      onChange={handleTaskSelectionChange}
    >
      {tasks.map((task) => (
        <Accordion.Item trackingId={TRACKING_IDS.taskAccordion(task.id)} key={task.id} value={String(task.id)}>
          <Accordion.Header css={accordionHeader(task.isCompleted)}>
            <TaskHeader title={task.title} isCompleted={task.isCompleted} />
          </Accordion.Header>
          <Accordion.Body css={{ paddingTop: theme.spacing(1) }}>
            <div css={bodyContainerStyle}>
              <Text size='medium'>{task.description}</Text>
              {!!task.imageUrl && <img src={task.imageUrl} alt='' height='125' />}
              {!!task.information && <InfoText text={task.information} />}
              <PrimaryButton
                trackingId={TRACKING_IDS.startTaskButton(task.id)}
                disabled={task.isCompleted}
                size='large'
                css={{ maxWidth: 'fit-content' }}
                onClick={() => handleStartTaskClick(task)}
              >
                {t('Start Task')}
              </PrimaryButton>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const bodyContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
});

const accordionHeader = (isCompleted: boolean) =>
  css({
    background: isCompleted ? theme.colors.neutral5 : 'inherit',
    border: 'none',
    p: {
      color: isCompleted ? theme.colors.neutral50 : 'inherit',
    },
  });
