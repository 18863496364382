import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { Image } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';

export const convertImageToMedia = (image?: Image): Media | undefined => {
  if (!image) return undefined;

  return {
    attributes: { width: 0, height: 0 },
    businessGroupIds: [],
    createdAt: new Date().toISOString(),
    createdBy: '',
    contentType: 'image',
    fileName: image.name,
    mediaId: image.url,
    url: image.url,
  };
};
