import { AppointmentPerson } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { ListSchedulesResponse, ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { CalendarEventsEnums } from '../../types';

export type ExceptionFormValues = {
  name: string;
  isAllDay: boolean;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  providerId: string;
  locationId: string;
  eventType: CalendarEventsEnums;
  eventId?: string;
  timeRange?: string[];
};

export type OnDeleteExceptionParams = {
  eventId: string;
  providerId: string;
  locationId: string;
  eventType: CalendarEventsEnums;
};

export enum ExceptionOperations {
  ADD_NEW = 'ADD_NEW',
  ADD_NEW_WITH_ID = 'ADD_NEW_WITH_ID',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

export type CreateUpdateExceptionPayloadParams = {
  entityId: string;
  formValues: ExceptionFormValues;
  locationId: string;
  entityType: ScheduleType;
  entitySchedule: ListSchedulesResponse;
  operation: ExceptionOperations;
};

export type HandleExceptionsParams = {
  entityId: string;
  entityType: ScheduleType;
  locationId: string;
  formValues: ExceptionFormValues;
  operation: ExceptionOperations;
};

export type OnDeleteCalendarEvent = { id: string; createdByLocationId: string };

export type OnCreateCalendarEvent = {
  personId: string;
  startDate: string;
  practitionerId: string;
  appointmentTypeId?: string;
  duration: number;
  type: string;
  locationId: string;
  workstationId?: string;
  practitionerName?: string;
  sourceId?: string;
  person?: AppointmentPerson;
  practitionerCalendarId?: string;
  referenceId?: string;
  appointmentTypeName?: string;
};

export interface OnUpdateCalendarEvent extends OnCreateCalendarEvent {
  id: string;
}

export type AppointmentAvailabilityFormValues = {
  appointmentType: string;
  appointmentDate: string;
  selectedLocationId: string;
  selectedPractitionerId: string;
  selectedPractitionerCalendarId?: string;
  startDate?: string;
  endDate?: string;
  duration?: number;
};
