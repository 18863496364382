import { demoDataUtils } from '../../../utils';
import { RoiPatient } from '../hooks';

const pageLimit = 10;
const contactTypes = ['existing_patient', 'new_patient', 'unscheduled'];
const appointmentsTypes = ['OrthVisit', 'Post Op', 'PeriodicX, ProphyAd', 'ProphyAd'];

export const patientsDemoData = (locationIds: string[]): RoiPatient[] => {
  // This limit is used to generate the number of patients for each location
  const locationIdLimit = Math.floor(pageLimit / locationIds.length);
  const dummyUserNames = demoDataUtils.generateRandomUserNames(25);

  return locationIds.reduce<RoiPatient[]>((acc, locationId) => {
    for (let i = 0; i < locationIdLimit; i++) {
      acc.push({
        appointment_type: appointmentsTypes[i % appointmentsTypes.length],
        appts_completion_date: demoDataUtils.getRandomDateAndTime(),
        contact_type: contactTypes[i % contactTypes.length],
        entry_date: demoDataUtils.getRandomDateAndTime(),
        location_id: locationId,
        message_details: 'This is a dummy message text', // Translation not needed
        patient_id: demoDataUtils.generateRandomUUID(),
        patient_name: `${dummyUserNames[demoDataUtils.generateRandomInt(0, 24)].firstName} ${
          dummyUserNames[demoDataUtils.generateRandomInt(0, 24)].lastName
        }`,
        phone_number: demoDataUtils.generateRandomNumberString(10),
        practitioner_name: `${dummyUserNames[demoDataUtils.generateRandomInt(0, 24)].firstName} ${
          dummyUserNames[demoDataUtils.generateRandomInt(0, 24)].lastName
        }`,
        receiver_number: demoDataUtils.generateRandomNumberString(10),
        sender_number: demoDataUtils.generateRandomNumberString(10),
      });
    }

    return acc;
  }, []);
};
