import { useState } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { BundleHooks } from '@frontend/api-bundle';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { PersonAPI, PersonQueries, PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { CallPopInterface, CallPopWidget, useCallPopStateSync } from '@frontend/pop';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore, useContactPanelShallowStore, useContactPanelStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  IconButton,
  Tabs,
  SkeletonLoaders,
  TextButton,
  BackIcon,
  Text,
  Heading,
  NotificationBadge,
  useAlert,
} from '@frontend/design-system';
import { CustomerDetails } from './contact-details-sections';
import { ContactPanelHeader } from './contact-panel-header';
import { FormsPanel } from './forms-panel';
import { HistoryEmptyStateGraphic } from './history-empty-state-graphic';
import { HistoryPanel } from './history-panel';
import { HouseholdNotes } from './household-notes';
import { HouseholdTasks } from './household-tasks';
import { PaymentsPanel } from './payments-panel';

type ContactPanelProps = {
  person?: PersonTypes.Person;
  backlink?: () => void;
  onClose?: () => void;
  isLoading: boolean;
  personLocationId?: string;
};

export const ContactPanel = ({
  Component,
  onClose,
}: {
  Component: (props: ContactPanelProps) => JSX.Element | null;
  onClose?: () => void;
}) => {
  const { context, setShow } = useSlidePanelShallowStore('context', 'setShow');
  const { personId, personLocationId } = useContactPanelShallowStore('personId', 'resetPersonId', 'personLocationId');
  const { t } = useTranslation('contacts');
  const contextData = context as { backlink?: () => void } | undefined;
  const { setOutlet } = useCallPopStateSync();

  const { data, isLoading, isError } = useLocalizedQuery({
    queryKey: [personId, { locationId: personLocationId }],
    queryFn: () => PersonAPI.getPersonExtended(personId, personLocationId),
    retry: 1,
    onError: () => {
      sentry.error({
        error: 'Error getting person extended',
        topic: 'contacts',
        severityLevel: 'error',
        addContext: {
          name: 'PersonID',
          context: {
            personId,
            personLocationId,
          },
        },
      });
    },
  });

  if (isError) {
    return (
      <div
        css={{ margin: theme.spacing(2), height: '80%', button: { marginBottom: theme.spacing(2), float: 'right' } }}
      >
        <header
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div>
            <Heading level={2}>{t('Oops...')}</Heading>
            <Text size='small' color='subdued'>
              {t(`This user doesn't exist`)}
            </Text>
          </div>

          <IconButton
            onClick={() => {
              setShow(false);
              setOutlet('queue');
            }}
            label='close'
          >
            <Icon name='x' />
          </IconButton>
        </header>
        <div
          css={css`
            height: 90%;
            display: flex;
            justify-content: center;
          `}
        >
          <HistoryEmptyStateGraphic />
        </div>
      </div>
    );
  }
  return (
    <Component
      isLoading={isLoading}
      person={data}
      backlink={contextData?.backlink}
      personLocationId={personLocationId}
      onClose={onClose}
    />
  );
};

ContactPanel.cleanup = () => {
  useContactPanelStore.getState().resetPersonId();
  CallPopInterface.dismissTray();
};

export const ContactPanelTrayComponent = ({ person, isLoading, personLocationId, onClose }: ContactPanelProps) => {
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { setOutlet } = useCallPopStateSync();
  if (!person) {
    return null;
  }

  return (
    <>
      <CallPopSection />
      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <SkeletonLoaders isLoading={isLoading}>
          <ContactPanelHeader person={person} />
          <IconButton
            css={css`
              position: absolute;
              top: ${theme.spacing(2)};
              right: ${theme.spacing(2)};
            `}
            size='normal'
            label='Close panel'
            onClick={() => {
              setOutlet('queue');
              setShow(false);
              onClose?.();
            }}
          >
            <Icon name='x' />
          </IconButton>
        </SkeletonLoaders>
        <ContactPanelTabs person={person} isLoading={isLoading} personLocationId={personLocationId} />
      </div>
    </>
  );
};

export const ContactPanelComponent = ({
  person,
  isLoading,
  backlink,
  personLocationId,
  onClose,
}: ContactPanelProps) => {
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { setOutlet } = useCallPopStateSync();
  const { t } = useTranslation('contacts');

  if (!person) {
    return null;
  }

  return (
    <>
      <CallPopSection />
      <div
        style={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'grid',
            justifyContent: 'space-between',
            alignItems: 'center',
            gridTemplateAreas: '"backlink closeButton"',
            gridTemplateColumns: 'auto auto',
          }}
        >
          {backlink && (
            <TextButton css={{ gridArea: 'backlink' }} icon={BackIcon} onClick={backlink}>
              {t('Back')}
            </TextButton>
          )}
        </div>
        <SkeletonLoaders isLoading={isLoading}>
          <div
            css={css`
              position: relative;
            `}
          >
            <ContactPanelHeader person={person} />
            <IconButton
              css={css`
                position: absolute;
                top: ${theme.spacing(2)};
                right: ${theme.spacing(2)};
              `}
              size='normal'
              label='Close panel'
              onClick={() => {
                setOutlet('queue');
                setShow(false);
                onClose?.();
              }}
            >
              <Icon name='x' />
            </IconButton>
          </div>
        </SkeletonLoaders>
        <ContactPanelTabs person={person} isLoading={isLoading} personLocationId={personLocationId} />
      </div>
    </>
  );
};
type ContactPanelTabsType = 'overview-tab' | 'notes-tab' | 'tasks-tab' | 'history-tab' | 'payments-tab' | 'forms-tab';
const ContactPanelTabs = ({ person, isLoading, personLocationId }: ContactPanelProps) => {
  const { t } = useTranslation('contacts');
  const [lastSelectedContactPanelTab, setLastSelectedContactPanelTab] = useState<ContactPanelTabsType>('overview-tab');
  const alert = useAlert();
  const { data: householdNotes } = PersonQueries.useGetHouseholdNotes(person?.WeaveHouseholdID || '', {
    onError: () => alert.error(t('Failed to load notes.')),
  });
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const { customizationFlags } = useAppFlagStore();

  const locationData = accessibleLocationData?.[selectedLocationIds?.[0]];

  const data = BundleHooks.useIsGbbSalesforceBundle({
    enabled: !!locationData?.slug,
    customizationFlags: Object.fromEntries([...(customizationFlags[selectedLocationIds[0]] ?? [])]),
  });

  const { isLoading: isLoadingFormFeature, isFeatureHiddenInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: personLocationId ? [personLocationId] : selectedLocationIds,
      enabled: true,
      customizationFlag: Feature.FORMS,
    });

  const hasFormFeature = !isLoadingFormFeature && !isFeatureHiddenInAllLocations;
  const isCoreOnly = /core/i.test(data.bundleDisplayName);

  return (
    <Tabs
      controlledTab={lastSelectedContactPanelTab}
      onChange={(id) => setLastSelectedContactPanelTab(id as ContactPanelTabsType)}
    >
      <Tabs.Bar fullWidth>
        <Tabs.Tab id='overview-tab' controls='overview-panel' trackingId='contact-2.0-tray-tab-overview'>
          {t('Overview')}
        </Tabs.Tab>
        <Tabs.Tab id='payments-tab' controls='payments-panel' trackingId='contact-2.0-tray-tab-payments'>
          {t('Payments')}
        </Tabs.Tab>
        {hasFormFeature && (
          <Tabs.Tab id='forms-tab' trackingId='contact-2.0-tray-tab-forms'>
            {t('Forms')}
          </Tabs.Tab>
        )}
        <Tabs.Tab id='notes-tab' controls='notes-panel' trackingId='contact-2.0-tray-tab-notes'>
          <Text
            as={'span'}
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(1)};
              font-size: ${theme.fontSize(14)};
            `}
          >
            {t('Notes')}
            {!!householdNotes?.length && (
              <NotificationBadge css={{ background: theme.colors.primary50 }}>
                {householdNotes?.length}
              </NotificationBadge>
            )}
          </Text>
        </Tabs.Tab>
        {isCoreOnly ? null : (
          <Tabs.Tab id='tasks-tab' controls='tasks-panel' trackingId='contact-2.0-tray-tab-tasks'>
            {t('Tasks')}
          </Tabs.Tab>
        )}
        <Tabs.Tab id='history-tab' controls='history-panel' trackingId='contact-2.0-tray-tab-history'>
          {t('History')}
        </Tabs.Tab>
      </Tabs.Bar>
      <Tabs.Panel
        id='overview-panel'
        controller='overview-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
          background-color: ${theme.colors.neutral5};
          padding: ${theme.spacing(3)};
        `}
      >
        {lastSelectedContactPanelTab === 'overview-tab' && (
          <SkeletonLoaders isLoading={isLoading}>
            <CustomerDetails data={person} locationId={personLocationId} />
          </SkeletonLoaders>
        )}
      </Tabs.Panel>
      <Tabs.Panel
        controller='forms-tab'
        css={css`
          height: 100%;
          background-color: ${theme.colors.neutral5};
          padding: ${theme.spacing(3)};
        `}
      >
        {lastSelectedContactPanelTab === 'forms-tab' && (
          <SkeletonLoaders isLoading={isLoading}>
            <FormsPanel person={person} locationId={personLocationId} />
          </SkeletonLoaders>
        )}
      </Tabs.Panel>
      <Tabs.Panel
        id='payments-panel'
        controller='payments-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
        `}
      >
        <PaymentsPanel
          isActive={lastSelectedContactPanelTab === 'payments-tab'}
          trackingId='contact-tray-payments'
          person={person}
          css={css`
            min-height: 100%;
          `}
        />
      </Tabs.Panel>
      <Tabs.Panel
        id='notes-panel'
        controller='notes-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
        `}
      >
        {lastSelectedContactPanelTab === 'notes-tab' && <HouseholdNotes householdId={person?.WeaveHouseholdID || ''} />}
      </Tabs.Panel>
      <Tabs.Panel
        id='tasks-panel'
        controller='tasks-tab'
        css={css`
          overflow-y: auto;
          height: 100%;
        `}
      >
        {lastSelectedContactPanelTab === 'tasks-tab' && (
          <HouseholdTasks
            householdId={person?.WeaveHouseholdID || ''}
            sourceId={person?.SourceID || ''}
            clientLocationId={person?.ClientLocationID || ''}
          />
        )}
      </Tabs.Panel>
      <Tabs.Panel id='history-panel' controller='history-tab' css={{ overflowY: 'auto' }}>
        {lastSelectedContactPanelTab === 'history-tab' && <HistoryPanel personId={person?.PersonID || ''} />}
      </Tabs.Panel>
    </Tabs>
  );
};

const CallPopSection = () => {
  const { outlet, notifications } = useCallPopStateSync();

  if (outlet !== 'profile' || notifications.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        background: theme.colors.neutral5,
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CallPopWidget notifications={notifications} />
    </div>
  );
};
