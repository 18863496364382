import { useNotificationActionSubscription } from '../notification-hooks/actions/use-notification-action-subscription';
import { useNotification } from '../notification-hooks/use-notification';
import { useNotificationPreferencesStore } from './store';

export const usePreferenceActions = () => {
  const { remove } = useNotification<'preference'>();
  const notificationPreferencesStore = useNotificationPreferencesStore();

  useNotificationActionSubscription(
    'preference',
    'preference',
    ({ notification, payload }) => {
      const { pauseNotificationType, dismissPreference } = notificationPreferencesStore.getState();

      const actionType = payload.actionType;

      if (actionType === 'pause') {
        pauseNotificationType(notification.payload.notificationType, true);
        dismissPreference(notification.id);
        remove(notification.id);
      }
      if (actionType === 'dismiss') {
        dismissPreference(notification.id);
        remove(notification.id);
      }
    },
    [remove, notificationPreferencesStore]
  );
};
