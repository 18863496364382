import { ReactNode } from 'react';
import {
  BarChartData,
  CategoryBarChartAppearance,
  CategoryBarChartProps,
  Chart,
  CustomLegendsData,
} from '@frontend/charts';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { featureFlags } from '../../../../feature-flags';
import { useAnalyticsOrgLocations } from '../../../../hooks';
import { formatters } from '../../../../utils';
import { DemoChip } from '../../../demo-chip';
import { LocationChip } from '../../../location-chip';
import { useARRoiShallowStore } from '../hooks';

interface Props {
  chartData: CategoryBarChartProps['data'];
  colors: Record<string, string>;
  customColumns?: CategoryBarChartProps['customColumns'];
  customLegends?: CustomLegendsData;
  customTooltipData?: CategoryBarChartAppearance['customTooltipData'];
  formatValue?: (value: number) => string;
  infoTip?: ReactNode;
  isLoading?: boolean;
  labels: Record<string, string>;
  legendsValues?: Record<string, number | string>;
  markers?: BarChartData['markers'];
  strokeColors?: Record<string, string>;
  title: string;
  trackingIdBase?: string;
}

export const ARMultiChartsBase = ({
  chartData,
  colors,
  customColumns,
  customLegends,
  customTooltipData,
  formatValue,
  infoTip,
  isLoading,
  labels,
  legendsValues,
  markers,
  strokeColors,
  title,
  trackingIdBase,
}: Props) => {
  const { locationNames } = useAnalyticsOrgLocations({
    module: 'ROI',
  });
  const { accessibleLocationData } = useAppScopeStore();
  const { setFilters } = useARRoiShallowStore('setFilters');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentRemindersRoiDemoData);

  return (
    <Chart
      colors={colors}
      isLoading={isLoading}
      labels={{ ...labels, ...locationNames }}
      strokeColors={strokeColors}
      style={{ marginBottom: 0 }}
    >
      <Chart.Header title={title} infoTip={infoTip} leftElement={isDemoAccount && <DemoChip />} />
      <Chart.CategoryBarChart
        appearance={{
          barSize: 40,
          collectiveTooltip: true,
          customTooltipData,
          customTooltipTitle: ({ groupName }) => (
            <LocationChip locationName={accessibleLocationData[groupName]?.name} noTruncating />
          ),
          formatters: formatValue,
          groupsGap: 40,
          mode: 'stacked',
          skipBarRadiusOnStackJoins: true,
        }}
        customColumns={customColumns}
        customColumnValueRenderer={({ value }) => {
          const numericValue = parseFloat(value as string);
          return (
            <Text style={{ fontSize: theme.font.size.h2 }} weight='bold'>
              {formatters.percent.appendPercent(numericValue)}
            </Text>
          );
        }}
        data={chartData}
        markers={markers}
        onClick={({ group }) => {
          setFilters({
            locationIds: [group],
          });
        }}
        sortConfig={{
          allowUserToSort: true,
        }}
        trackingIdBase={trackingIdBase}
      >
        <Chart.Legends customData={customLegends} values={legendsValues} />
      </Chart.CategoryBarChart>
    </Chart>
  );
};
