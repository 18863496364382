import { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { VoiceMailboxApi } from '@frontend/api-voicemail-boxes';
import { VoicemailTypes } from '@frontend/api-voicemails';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Accordion, Button, Heading, Text } from '@frontend/design-system';
import { transcriptionTrackingId } from '../../pendo-tracking';
import { queryKeys } from '../../query-keys';
import { AddTag } from '../voicemails/voicemail-tag-selector';
import {
  CallDetailsSectionBody,
  ContactActions,
  ContactSection,
  TagsSectionBody,
  TranscriptSectionBody,
  VoicemailMedia,
} from './transcription-panel-sections';
import { TranscriptionContext, VoicemailDetailsAccordionType } from './types';
import { getTranscriptionUniqueKey } from './utils';

export const TranscriptionPanel = () => {
  const { t } = useTranslation('calls');
  const { show, setShow, context } = useSlidePanelShallowStore<TranscriptionContext>('setShow', 'context', 'show');
  const currentRowIndex =
    context?.rows.findIndex((row) => getTranscriptionUniqueKey(row) === context.currentRowId) ?? 0;
  const row = context?.rows[currentRowIndex];
  const transcriptionUniqueKey = getTranscriptionUniqueKey(row);

  const [openedAccordionId, setOpenedAccordionId] = useState<VoicemailDetailsAccordionType>(
    context?.sectionFocus || 'transcript'
  );

  useLayoutEffect(() => {
    if (show && !!context?.sectionFocus && openedAccordionId !== context.sectionFocus) {
      setOpenedAccordionId(context.sectionFocus);
    }
  }, [openedAccordionId, context?.sectionFocus, show]);

  const { data: transcription } = useScopedQuery({
    queryKey: queryKeys.voicemailTranscript(row?.channelId ?? ''),
    queryFn: async () => {
      const response = await VoiceMailboxApi.getTranscription(row?.channelId ?? '');
      return response;
    },
    enabled: !!row,
  });

  /**
   * We rely on the row index here for many things, which works fine until the context is disconnected from the current voicemail table instance.
   *
   * This panel is triggered by the table, and receives context from certain actions performed in the table. However, actions related to pagination do not
   * give us access to the table instance, so we cannot update the panel context with the new rows. To prevent issues, we will close the panel when one of
   * these "unsyncable" actions are performed.
   *
   * As long as the rows in the context is synced correctly, relying on row index will work as expected.
   */

  useLayoutEffect(() => {
    /** The scroll effect performs better with useLayoutEffect */
    if (row) {
      context.toggleRowSelected(transcriptionUniqueKey, true);
      const rowElement = document.querySelectorAll(`#${context?.instanceId} [role=row]`)[currentRowIndex];
      rowElement?.scrollIntoView({ behavior: 'smooth' });
    }

    return () => {
      if (context && row) {
        context.toggleRowSelected(transcriptionUniqueKey, false);
      }
    };
  }, [row]);

  if (!context) {
    return null;
  }

  const handleUpdateReadStatus = async ({ markIsRead, channelId }: { markIsRead: boolean; channelId: string }) => {
    const res = await context.updateReadStatus({
      index: currentRowIndex,
      status: markIsRead ? VoicemailTypes.ViewStatus.VIEW_STATUS_READ : VoicemailTypes.ViewStatus.VIEW_STATUS_UNREAD,
      voicemailId: channelId,
    });

    return res;
  };

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%', padding: theme.spacing(3) }}>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: theme.spacing(1),
        }}
      >
        <Heading level={2} css={{ margin: 0 }}>
          {t('Voicemail Details')}
        </Heading>
        <Button
          variant='secondary'
          label={t('Close panel')}
          iconName='x'
          size='large'
          onClick={() => {
            setShow(false);
          }}
        />
      </header>
      {row && (
        <div style={{ overflow: 'auto' }}>
          <ContactSection
            contact={{
              id: row.contactId,
              firstName: row.firstName,
              lastName: row.lastName,
              profileName: row.contactName,
              defaultName: t('Unknown'),
              number: row.contactNumber,
            }}
            locationId={row.locationIds[0] ?? ''}
            isMarked={!row.readAt}
          >
            <ContactActions
              contact={{ name: row.contactName, id: row.contactId, number: row.contactNumber }}
              locationId={row.locationIds[0] ?? ''}
              mediaFilePath={row.mediaFilePath}
              date={row.dateTime}
              message={row}
              updateReadStatus={(markIsRead) => handleUpdateReadStatus({ markIsRead, channelId: row.channelId })}
              deleteVoicemail={() => context.deleteVoicemail({ index: currentRowIndex, voicemailId: row.channelId })}
              openForwardVoicemailModal={() => {
                context.openForwardVoicemailModal({
                  currentVoicemailBoxId: row.mailboxId,
                  mediaId: row.mediaId,
                  mediaPath: row.mediaFilePath,
                  currentVoicemailBoxName: row.voiceMailBoxName,
                  vmId: row.voicemailId,
                  rowIndex: currentRowIndex,
                });
              }}
            />
          </ContactSection>

          <VoicemailMedia
            message={row}
            mediaFilePath={row.mediaFilePath}
            updateReadStatus={() => handleUpdateReadStatus({ markIsRead: true, channelId: row.channelId })}
          />

          <Accordion
            variant='blank'
            controlledValue={openedAccordionId}
            onChange={(value) => {
              setOpenedAccordionId(value as VoicemailDetailsAccordionType);
              setShow(true, 'voicemailTranscription', {
                ...context,
                sectionFocus: value as VoicemailDetailsAccordionType,
              });
            }}
            padding={0}
            css={css`
              padding: ${theme.spacing(1)};
              > div:not(:last-child) {
                margin-bottom: ${theme.spacing(2)};
              }
            `}
          >
            <Accordion.Item title={t('Transcript')} value='transcript' css={styles.accordionItemStyle}>
              <Accordion.Header
                title={t('Transcript')}
                css={styles.accordionHeaderStyle(openedAccordionId, 'transcript')}
              />
              <Accordion.Body>
                <TranscriptSectionBody
                  message={row}
                  transcription={transcription}
                  updateReadStatus={() => handleUpdateReadStatus({ markIsRead: true, channelId: row.channelId })}
                  isOpen={openedAccordionId === 'transcript'}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item title={t('About this voicemail')} value='details' css={styles.accordionItemStyle}>
              <Accordion.Header
                title={t('About this voicemail')}
                css={styles.accordionHeaderStyle(openedAccordionId, 'details')}
              />
              <Accordion.Body>
                <CallDetailsSectionBody
                  mailboxName={row.voiceMailBoxName}
                  date={row.dateTime}
                  locationId={row.locationIds[0]}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item title={t('Tags')} value='tags' css={styles.accordionItemStyle}>
              <Accordion.Header css={styles.accordionHeaderStyle(openedAccordionId, 'tags')}>
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: ${theme.spacing(0.5)};
                    `}
                  >
                    <Text
                      css={css`
                        font-size: ${theme.fontSize(18)};
                      `}
                      weight='bold'
                    >
                      {t('Tags')}
                    </Text>
                    <Text size='small' color='subdued'>
                      {row.tags.length > 0 ? row.tags.length : ''}
                    </Text>
                  </div>
                  <AddTag
                    voicemailId={row.voicemailId ?? ''}
                    queryKey={context.voicemailsQueryKey}
                    existingTagIds={row.tags.map((tag) => tag.id)}
                  />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <TagsSectionBody
                  tags={row.tags}
                  voicemailId={row.voicemailId}
                  voicemailsQueryKey={context.voicemailsQueryKey}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          gap: theme.spacing(1),
          padding: theme.spacing(2, 1, 1),
          justifyContent: 'end',
        }}
      >
        <Button
          trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'delete' })}
          size='large'
          destructive
          variant='tertiary'
          iconName='trash'
          onClick={() => {
            context.deleteVoicemail({ index: currentRowIndex, voicemailId: row?.channelId });
            if (currentRowIndex + 1 >= context?.rows.length) {
              setShow(false);
              return;
            }
            setShow(true, 'voicemailTranscription', {
              updateReadStatus: context.updateReadStatus,
              deleteVoicemail: context.deleteVoicemail,
              instanceId: context?.instanceId,
              rows: context?.rows,
              toggleRowSelected: context.toggleRowSelected,
              currentRowId: getTranscriptionUniqueKey(context.rows[currentRowIndex + 1]),
              voicemailsQueryKey: context.voicemailsQueryKey,
              openForwardVoicemailModal: context.openForwardVoicemailModal,
              sectionFocus: context?.sectionFocus,
            });
          }}
        >
          {t('Delete Voicemail')}
        </Button>

        <Button
          variant='secondary'
          trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'previous' })}
          size='large'
          css={{ width: 'auto', marginLeft: 'auto' }}
          disabled={currentRowIndex - 1 < 0}
          onClick={() => {
            setShow(true, 'voicemailTranscription', {
              updateReadStatus: context.updateReadStatus,
              deleteVoicemail: context.deleteVoicemail,
              instanceId: context?.instanceId,
              rows: context?.rows,
              toggleRowSelected: context.toggleRowSelected,
              currentRowId: getTranscriptionUniqueKey(context.rows[currentRowIndex - 1]),
              voicemailsQueryKey: context.voicemailsQueryKey,
              openForwardVoicemailModal: context.openForwardVoicemailModal,
              sectionFocus: context?.sectionFocus,
            });
          }}
        >
          {t('Previous')}
        </Button>

        <Button
          variant='secondary'
          trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'next' })}
          size='large'
          css={{ width: 'auto' }}
          disabled={currentRowIndex + 1 >= context?.rows.length}
          onClick={() => {
            setShow(true, 'voicemailTranscription', {
              updateReadStatus: context.updateReadStatus,
              deleteVoicemail: context.deleteVoicemail,
              instanceId: context?.instanceId,
              rows: context?.rows,
              toggleRowSelected: context.toggleRowSelected,
              currentRowId: getTranscriptionUniqueKey(context.rows[currentRowIndex + 1]),
              voicemailsQueryKey: context.voicemailsQueryKey,
              openForwardVoicemailModal: context.openForwardVoicemailModal,
            });
          }}
        >
          {t('Next')}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  accordionHeaderStyle: (
    openedAccordionId: VoicemailDetailsAccordionType,
    currentAccordionType: VoicemailDetailsAccordionType
  ) => css`
    background-color: ${openedAccordionId === currentAccordionType ? theme.colors.neutral5 : 'transparent'};
    border-bottom: none;
    padding: ${theme.spacing(2, 0, 2, 2)};
  `,

  accordionItemStyle: css`
    border-radius: ${theme.spacing(1)};
    border: 1px solid ${theme.colors.neutral20};
    :last-child {
      border-bottom: 1px solid ${theme.colors.neutral20};
    }
  `,
};
