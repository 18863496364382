import { type PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { Attachments } from './attachments';
import { MessageBodyText } from './message-body-text';
import { Reactions } from './reactions';

export const MessageBody = ({ message, children }: PropsWithChildren<{ message: TeamChatTypes.Message }>) => {
  const { t } = useTranslation('team-chat');
  return (
    <div css={outerStyle}>
      {!!message.text && (
        <div css={innerStyle}>
          {message.type === 'deleted' ? (
            <Text color='subdued'>{t('This message was deleted')}</Text>
          ) : (
            <MessageBodyText message={message.text} />
          )}
          {!!message.isEdited && (
            <Text as='span' color='subdued' size='small'>
              ({t('edited')})
            </Text>
          )}
        </div>
      )}
      {!!message.attachments?.length && (
        <Attachments attachments={message.attachments.map((attachment) => attachment.url)} onOpenAttachment={close} />
      )}
      {!!message.reactions?.length && <Reactions message={message} />}
      {children}
    </div>
  );
};

const outerStyle = css`
  grid-column: 2/3;
  grid-row: 2/3;
`;
const innerStyle = css`
  white-space: pre-wrap;
  word-break: break-word;
`;
