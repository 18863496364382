import { createContext, ReactNode } from 'react';

export type InteractionContextType = {
  mode: 'edit' | 'view';
  onPlusIconClick?: (data: { nodeId: string }) => void;
  onEditIconClick?: (data: { nodeId: string }) => void;
  onDeleteIconClick?: (data: { nodeId: string }) => void;
};

export const InteractionContext = createContext<InteractionContextType>({ mode: 'view', onPlusIconClick: () => {} });

export const InteractionProvider = ({
  mode,
  onPlusIconClick,
  onEditIconClick,
  onDeleteIconClick,
  children,
}: InteractionContextType & { children: ReactNode }) => {
  return (
    <InteractionContext.Provider value={{ mode, onPlusIconClick, onEditIconClick, onDeleteIconClick }}>
      {children}
    </InteractionContext.Provider>
  );
};
