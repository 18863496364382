import { forwardRef, MouseEventHandler } from 'react';
import { QuickFillHooks } from '@frontend/api-quick-fill';
import { ActionBar } from '@frontend/action-bar';
import { useTranslation } from '@frontend/i18n';
import { QUICK_FILL_TRACKING_IDS } from '../tracking-ids';

interface Props extends Omit<React.ComponentProps<typeof ActionBar.Action>, 'label' | 'id' | 'icon'> {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
}

export const QuickFillActionBarToggleButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { t } = useTranslation('schedule-quick-fill');
  const { hasQuickFillFeature } = QuickFillHooks.useQuickFillFeatureDetails();

  if (!hasQuickFillFeature) return null;

  return (
    <ActionBar.Action
      {...props}
      label={t('Quick Fill')}
      id='quickfill'
      icon='quickfill'
      trackingId={QUICK_FILL_TRACKING_IDS.sideActionBarButton}
      ref={ref}
    />
  );
});

QuickFillActionBarToggleButton.displayName = 'QuickFillActionBarToggleButton';
