import { useRequestActivityQuery } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { ContentLoader } from '@frontend/design-system';
import { useNavAlert, useNavigateWithAlert } from '../../hooks';
import { PaymentsNavAlerts } from '../../utils';
import { Details } from './Details';
import { PaymentInformationTable } from './payment-information-table';

export { PaymentRequestActions } from './payment-request-actions';
import { PaymentRequestActivity } from './payment-request-activity';
import { AttachmentSection } from './payment-request-attachment';
import { InternalNotes } from './payment-request-notes';
import { SummaryCard } from './SummaryCard';

export const PaymentRequestDetailsPage = ({ selectedInvoiceId }: { selectedInvoiceId: string }) => {
  const { t } = useTranslation('payments');
  const { paymentsUrl } = useMerchant();
  const { invoice, isLoading, isFetched } = useSelectedInvoice(selectedInvoiceId);
  const { invoiceHistory, isLoading: isLoadingActivity } = useRequestActivityQuery({
    paymentsUrl,
    invoiceId: invoice?.id || '',
    locationId: invoice?.locationId || '',
  });

  useNavigateWithAlert({
    enable: isFetched && !isLoading && !invoice,
    key: PaymentsNavAlerts.InvalidInvoice,
    to: '/payments/invoices',
    type: 'error',
  });

  useNavAlert(PaymentsNavAlerts.CreatePaymentRequest, t('Payment Request Created Successfully.'));

  return (
    <>
      <ContentLoader show={isLoading} />
      {!isLoading && !!invoice && (
        <>
          <SummaryCard invoice={invoice} />
          <Details invoice={invoice} />
          <PaymentInformationTable invoice={invoice} />
          <AttachmentSection />
          <PaymentRequestActivity invoiceHistory={invoiceHistory} isLoading={isLoadingActivity} />
          <InternalNotes invoice={invoice} />
        </>
      )}
    </>
  );
};
