import { useTranslation } from '@frontend/i18n';
import type { GetWeavePopNotificationByType, NotificationActions } from '@frontend/types';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';
import { DefaultNotificationBody } from './defaults/default-notification';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'form-submission-new'>>;

export const FormSubmissionNotificationComponent = ({ isHistorical, emit, notification, ...rest }: Props) => {
  const { t } = useTranslation();
  const actions: NotificationActions = [
    {
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('New Form Submission')}
        body={
          <DefaultNotificationBody
            name={`${notification.payload.firstName} ${notification.payload.lastName}`}
            body={notification.payload.documentName}
          />
        }
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
