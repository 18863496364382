import { LineKey, ModelInfo, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { createProvider } from '@frontend/store';
import { contextFactory } from '@frontend/design-system';

type LineKeyStore = {
  isMenuBarOpen: boolean;
  setIsMenuBarOpen: (value: boolean) => void;
  addNewKey: (data: Record<string, LineKey>) => void;
  finalLineKeys: Record<string, LineKey> | undefined;
  setFinalLineKeys: (data: Record<string, LineKey> | undefined) => void;
  isKeysMaxedOut: (maxKeys: number) => boolean;
  scrollToCard: (step: number) => void;
};

const focusToCard = (node: HTMLElement) => {
  /* the setTimeout is added because the menu does not open and scroll without it */
  setTimeout(() => {
    node?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, 100);
  node.focus({ preventScroll: true });
};

export const { Provider: LineKeyProvider, useStore: useLineKeyState } = createProvider<LineKeyStore>()((set, get) => ({
  isMenuBarOpen: false,
  setIsMenuBarOpen: (isMenuBarOpen) => {
    set(() => ({ isMenuBarOpen }));
  },
  addNewKey: (newKey) => {
    set((state) => ({
      finalLineKeys: {
        ...state.finalLineKeys,
        ...newKey,
      },
    }));
  },
  setFinalLineKeys: (finalLineKeys) => {
    set(() => ({ finalLineKeys }));
  },
  finalLineKeys: undefined,
  isKeysMaxedOut: (maxKeys: number) => {
    const lineKeys = get().finalLineKeys;
    return Boolean(maxKeys <= Object.keys(lineKeys ?? {}).length);
  },
  scrollToCard: (step: number) => {
    const numOfLineKeys = Object.keys(get().finalLineKeys ?? {}).length;
    if (step > numOfLineKeys + 1) return;
    let node: HTMLElement | null;
    if (step === numOfLineKeys + 1) {
      get().setIsMenuBarOpen?.(true);
      node = document?.querySelector(`#line-key-add-new-card-menu`);
    } else {
      node = document?.querySelector(`#line-key-card-${step}`);
    }
    if (!node) return;
    focusToCard(node);
  },
}));

type LineKeyProps = {
  lineKeys: Record<string, LineKey> | undefined;
  lineKeyOptions: Record<string, LineKeysTypes.ExtensionsUnionType> | undefined;
  model: ModelInfo | undefined;
  maxKeys: number;
  device: ListDevice | undefined;
};

export const [LineKeyContext, useLineKeyContext] = contextFactory<LineKeyProps>();
