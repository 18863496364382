import { i18next } from '@frontend/i18n';
import { default as OnlineBillPaySvg } from '../assets/online-bill-pay.svg';
import { default as PaymentsSvg } from '../assets/payments.svg';
import { default as TextToPaySvg } from '../assets/text-to-pay.svg';
import { FeatureContentType, PaymentActionEnum, PaymentActionType } from './types';

export const PaymentsFeatureContentList: FeatureContentType[] = [
  {
    title: i18next.t('Account Balances are 50% more likely to be paid on time.', { ns: 'highValueAdoption>>payments' }),
    image: OnlineBillPaySvg,
  },
  {
    title: i18next.t('Save over 2 hours per day using Weave Text-to-Pay.', { ns: 'highValueAdoption>>payments' }),
    image: PaymentsSvg,
  },
  {
    title: i18next.t('83% of offices that use Weave Payments collect faster.', { ns: 'highValueAdoption>>payments' }),
    image: TextToPaySvg,
  },
];

export const PaymentActionsList: PaymentActionType[] = [
  {
    type: PaymentActionEnum.CREATE_INVOICE,
    title: i18next.t('Create an invoice', { ns: 'highValueAdoption>>payments' }),
    iconName: 'in-progress',
    trackingId: 'payment-action-create-invoice',
  },
  {
    type: PaymentActionEnum.ONLINE_BILL_PAY,
    title: i18next.t('Get free Online Bill Pay url', { ns: 'highValueAdoption>>payments' }),
    iconName: 'in-progress',
    trackingId: 'payment-action-online-bill-pay',
  },
  {
    type: PaymentActionEnum.SHARE_INVOICE,
    title: i18next.t('Share invoice with a customer', { ns: 'highValueAdoption>>payments' }),
    iconName: 'in-progress',
    trackingId: 'payment-action-share-invoice',
  },
];

export const PAYMENTS_HVA_FF = 'high-value-adoption:payments';
export const PAYMENTS_APPOINMENT_PROMO_FF = 'high-value-adoption:payments:appointments';

export const PAYMENT_ACCOUNT_HELP_LINK = 'https://www.weavehelp.com/weavehelp/categories/Payments';
export const ONLINE_BILL_PAY_ROUTE = '/payments/online-bill-pay';
export const SHARE_INVOICE_ROUTE = '/payments/invoices';
