import { FC, PropsWithChildren } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { ModalControlResponse } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../constants/self-upgrade';
import { useFeatureSubscriptionConfig } from '../../hooks/useFeatureSubscriptionConfig';
import { useFeatureUpgradeDetailsModal } from '../../hooks/useFeatureUpgradeDetailsModal';
import { FeatureSubscriptionContext } from '../../providers/FeatureSubscriptionProvider';

interface FeatureUpgradeModalWrapperProps {
  modalControls: ModalControlResponse;
  feature: Feature;
  onUpgradeSuccess?: () => void;
}

export const FeatureUpgradeModalWrapper: FC<PropsWithChildren<FeatureUpgradeModalWrapperProps>> = ({
  children,
  feature,
  modalControls,
  onUpgradeSuccess,
}) => {
  const featureSubscriptionConfig = useFeatureSubscriptionConfig({ feature });
  const { FeatureUpgradeDetailsModal } = useFeatureUpgradeDetailsModal({
    feature,
    isBundleUpgradeEligible: featureSubscriptionConfig.isBundleUpgradeEligible,
  });

  const handleActionSuccess = (result?: { actionType?: SelfUpgradeActionType }) => {
    if (
      !result?.actionType ||
      [SelfUpgradeActionType.UPGRADE_NOW, SelfUpgradeActionType.FREE_TRIAL].includes(result.actionType)
    ) {
      onUpgradeSuccess?.();
    }
  };

  return (
    <FeatureSubscriptionContext.Provider value={featureSubscriptionConfig}>
      {children}
      {!!FeatureUpgradeDetailsModal && (
        <FeatureUpgradeDetailsModal modalControls={modalControls} onActionSuccess={handleActionSuccess} />
      )}
    </FeatureSubscriptionContext.Provider>
  );
};
