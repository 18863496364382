import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { useAppScopeStore } from '@frontend/scope';

export const useQuickFillFeatureDetails = () => {
  const { selectedLocationIds } = useAppScopeStore();

  const { isLoading, isFeatureHiddenInAllLocations, locationIdWiseCustomizationFlagDetails } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: !!selectedLocationIds.length,
      customizationFlag: Feature.QUICKFILL,
    });

  const hasQuickFillFeature = !isLoading && !isFeatureHiddenInAllLocations;

  return { hasQuickFillFeature, isLoading, locationIdWiseQuickFillFeature: locationIdWiseCustomizationFlagDetails };
};
