import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { OnboardingEvent } from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { OnboardingModulesQueries, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { i18next, useTranslation } from '@frontend/i18n';
import { WeaveInitialCallScheduleIframe } from '@frontend/product-onboarding-tasks';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, Heading, NakedButton, Text, styles, useAlert } from '@frontend/design-system';
import { trackingIds } from '../../constants';
import { buttonStyle, containerStyle } from './styles';

enum WICScheduleState {
  SCHEDULE = 'schedule',
  BOOKING_CONFIRMED = 'booking-confirmed',
}

const WICStateContentMap: Record<WICScheduleState, { title: string; description?: string }> = {
  [WICScheduleState.SCHEDULE]: {
    title: i18next.t('Schedule your onboarding call today to unlock the full potential of Weave!', {
      ns: 'onboarding',
    }),
    description: i18next.t(
      "This call takes about an hour, and your onboarder will help you every step of the way! They'll guide you through setting up integrations, tweaking your messaging, and more — making sure everything feels just right for your practice.",
      { ns: 'onboarding' }
    ),
  },
  [WICScheduleState.BOOKING_CONFIRMED]: {
    title: i18next.t("We're looking forward to our call!", { ns: 'onboarding' }),
  },
};

interface WICScheduleStepProps {
  onContinue: () => void;
}

export const WICScheduleStep: FC<WICScheduleStepProps> = ({ onContinue }) => {
  const { t } = useTranslation('onboarding');
  const [scheduleState, setScheduleState] = useState<WICScheduleState>(WICScheduleState.SCHEDULE);
  const { accessibleLocationData } = useAppScopeStore();
  const { selectedIntakeFormLocationId = '' } = useIntakeFormShallowStore('selectedIntakeFormLocationId');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const alert = useAlert();

  // useGetModulesAndTasksByLocationId is called to initialize module tasks
  const { refetch: refetchOnboardingTasks } =
    OnboardingModulesQueries.useGetModulesAndTasksByLocationId(selectedIntakeFormLocationId);

  const { mutate: publishOnboardingEvent, isLoading: isPublishingOnboardingEvent } =
    OnboardingModulesQueries.usePublishOnboardingEvent();

  const { mutate: markLocationTaskComplete, isLoading: isMutating } =
    OnboardingModulesQueries.useMarkLocationTaskCompletedMutation({
      onError: () => {
        alert.error(t('An error occurred while scheduling the call.'));
      },
      onSuccess: () => {
        publishOnboardingEvent({
          locationId: selectedIntakeFormLocationId,
          slug: accessibleLocationData[selectedIntakeFormLocationId]?.slug ?? '',
          onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_ONBOARDER_CALL_SCHEDULED,
        });
        refetchOnboardingTasks();
      },
    });

  const handleBookingConfirmed = () => {
    setScheduleState(WICScheduleState.BOOKING_CONFIRMED);
    markLocationTaskComplete(OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder);
  };

  const handleClose = () => {
    pendo.track(trackingIds.onboardingV2.wicSchedule.closeButtonClick);
    onContinue();
  };

  return (
    <div css={[styles.flexCenter, { flexDirection: 'column', gap: theme.spacing(3), padding: theme.spacing(2) }]}>
      <Heading level={2} textAlign='center' css={{ maxWidth: 490 }}>
        {WICStateContentMap[scheduleState].title}
      </Heading>
      <div css={[containerStyle, cardStyles]}>
        <ContentLoader show={isMutating || isPublishingOnboardingEvent} />
        {!isMobile && !!WICStateContentMap[scheduleState].description && (
          <Text size='large'>{WICStateContentMap[scheduleState].description}</Text>
        )}
        <WeaveInitialCallScheduleIframe
          show={!!selectedIntakeFormLocationId}
          locationId={selectedIntakeFormLocationId}
          onClose={handleClose}
          onBookingConfirmed={handleBookingConfirmed}
          styles={iframeStyles}
        />
        {scheduleState === WICScheduleState.SCHEDULE && (
          <NakedButton onClick={onContinue} trackingId={trackingIds.onboardingV2.wicSchedule.skipButtonClick}>
            <Text as='span' weight='bold' size='medium'>
              {t("I'll do it Later (I promise)")}
            </Text>
          </NakedButton>
        )}
        {scheduleState === WICScheduleState.BOOKING_CONFIRMED && (
          <Button
            variant='primary'
            css={buttonStyle}
            onClick={onContinue}
            disabled={isMutating || isPublishingOnboardingEvent}
            trackingId={trackingIds.onboardingV2.wicSchedule.skipButtonClick}
          >
            {t('Next')}
          </Button>
        )}
      </div>
    </div>
  );
};

const iframeStyles = css({
  minWidth: 0,
  width: 'min(100%, 480px)',
  height: 'min(512px, 60vh)',
  borderRadius: theme.borderRadius.medium,
});

const cardStyles = css({
  alignItems: 'center',
  gap: theme.spacing(2),
  width: 'min(648px, calc(100vw - 16px))',
  minHeight: 320,
  borderRadius: 24,
  padding: theme.spacing(2),
});
