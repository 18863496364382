import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { VoicemailConfigAPI } from '@frontend/api-voicemail-boxes';
import { i18next, useTranslation } from '@frontend/i18n';
import { useScopedQuery, WeaveLocationGroup } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  Table,
  Text,
  TextField,
  TrashIcon,
  useFormField,
  useModalControl,
  useAlert,
  Modal,
  ModalControlModalProps,
  Tabs,
  NakedUl,
  SearchField,
  PrimaryButton,
  SpinningLoader,
  Heading,
  styles,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { buildAudioLibraryPath } from '../../../utils/media-path';
import { useAudioQuery } from '../../../utils/use-audio-query';
import { AudioUploadModalContent } from '../../audio-picker/audio-upload-modal';
import { CachedAudioScrubber } from '../../common/cached-audio-scrubber';
import { SettingsCard } from '../../common/settings-card';
import { VoicemailGreeting } from './types';
import { useVoicemailBoxGreetingMutations } from './use-voicemail-box-mutations';

const GreetingsCardContent = ({
  tenantLocation,
  mailboxId,
  greetings = [],
  onGreetingChange,
  isDeviceBox,
  voicemailBoxLocationIds,
}: {
  tenantLocation: WeaveLocationGroup;
  mailboxId: string;
  greetings?: VoicemailGreeting[];
  onGreetingChange: (incoming: { id: string; number: number; name: string }) => void;
  isDeviceBox: boolean;
  voicemailBoxLocationIds: string[]; // NOTE: this can be empty
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps, openModal } = useModalControl();
  const alerts = useAlert();

  const { aggregateValue: departmentsFeatureFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: voicemailBoxLocationIds.length ? voicemailBoxLocationIds : [tenantLocation.id],
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ALL,
  });

  const { remove } = useVoicemailBoxGreetingMutations(tenantLocation.phoneTenantId ?? '', mailboxId);

  const { data: greetingMap } = useScopedQuery({
    queryKey: queryKeys.greetingSchedules(mailboxId),
    queryFn: () => VoicemailConfigAPI.GreetingSchedules({ mailboxId }),
    select: (data) => {
      // Transform the data into a map of greeting number to schedule name
      const greetingsSchedules = data.greetingSchedules;
      const greetingsMap = greetingsSchedules?.reduce((acc, schedule) => {
        if (schedule.greetingNumber !== undefined && schedule.scheduleName) {
          acc[schedule.greetingNumber] = schedule.scheduleName;
        }
        return acc;
      }, {} as Record<number, string>);
      return greetingsMap;
    },
    enabled: !!mailboxId,
  });

  const numberOptions = useMemo(() => {
    return Array.from({ length: isDeviceBox ? 2 : 10 }, (_, i) => {
      return {
        value: i.toString(),
        label: i.toString(),
        disabled: (i !== 0 && !!greetings?.find((d) => d.greetingNumber === i)) ?? false,
      };
    });
  }, [greetings]);

  return (
    <SettingsCard
      title={t('Greetings')}
      description={
        isDeviceBox
          ? t('Assign a greeting that callers will hear when leaving a voicemail after calling a device extension.')
          : t(
              'Optionally, assign greetings a number so that greetings can be managed by voicemail box outside of Call Routing.'
            )
      }
      action={{
        label: t('Add Greeting'),
        onClick: () => openModal(),
        ref: triggerProps.ref,
        trackingId: 'add-greeting',
      }}
    >
      <Table
        fullHeight
        fullHeightConfig={{
          maxHeight: 300,
          minHeight: 200,
        }}
        data={greetings}
        colConfig={[
          {
            id: 'name',
            accessor: (row) => row,
            Header: t('Name'),
            cellRenderer: (row: VoicemailGreeting) => (
              <div css={{ width: '100%' }}>
                <GreetingNameField greeting={row} onChange={onGreetingChange} />
              </div>
            ),
            sortType: (rowA, rowB) => {
              if (rowA.original.greetingName && rowB.original.greetingName) {
                return rowA.original.greetingName.localeCompare(rowB.original.greetingName);
              }

              return 0;
            },
          },
          {
            id: 'greetingNumber',
            accessor: (row) => row,
            Header: t('Greeting Number'),
            cellRenderer: (row: VoicemailGreeting) => {
              const options = numberOptions.reduce<{ value: string; label: string; disabled: boolean }[]>(
                (acc, option) => {
                  const greetingName = greetingMap?.[+option.label];

                  let label;
                  if (isDeviceBox) {
                    label = decorateLabelForDeviceBox(option.label);
                  } else if (departmentsFeatureFlag) {
                    label = option.label;
                  } else if (greetingName) {
                    label = `${option.label}: ${greetingName}`;
                  } else {
                    label = undefined;
                  }

                  if (label) {
                    return [...acc, { ...option, label }];
                  }

                  return acc;
                },
                []
              );

              return (
                <div css={{ width: '100%' }}>
                  <GreetingNumberField options={options} greeting={row} onChange={onGreetingChange} />
                </div>
              );
            },
            sortType: (rowA, rowB) => {
              return rowA.original.greetingNumber - rowB.original.greetingNumber;
            },
          },
          {
            id: 'lastUpdated',
            accessor: 'updatedAt',
            Header: t('Last Updated'),
            cellRenderer: (value) => {
              return value ? dayjs(value).format('LLL') : null;
            },
          },
          {
            id: 'greetingId',
            accessor: (row) => row,
            Header: t('Audio'),
            cellRenderer: (row) => <CachedAudioScrubber filePath={row.mediaPath} mediaId={row.greetingId} />,
            disableSortBy: true,
          },
        ]}
        rowActions={{
          actions: [
            {
              label: t('Delete'),
              Icon: TrashIcon,
              onClick: (row) => {
                remove.mutate(
                  { greetingId: row.greetingId, mailboxId: row.mailboxId },
                  {
                    onSuccess: () => {
                      alerts.success(t('Greeting deleted successfully.'));
                    },
                    onError: () => {
                      alerts.error(t('Failed to delete greeting.'));
                    },
                  }
                );
              },
            },
          ],
        }}
      />
      <SelectGreetingModal modalProps={modalProps} mailboxId={mailboxId} tenantLocation={tenantLocation} />
    </SettingsCard>
  );
};

/**
 * If the incoming change conflicts with an existing greeting, we update the greeting number of the existing greeting to 0.
 *
 * Any number of greetings can be set to the greeting number 0.
 */
const getUpdatedGreetings = (
  greetings: VoicemailGreeting[],
  incoming: { id: string; number: number; name: string }
) => {
  return greetings.map((greeting) => {
    if (greeting.greetingId === incoming.id) {
      return { ...greeting, greetingNumber: incoming.number, greetingName: incoming.name };
    } else if (greeting.greetingNumber === incoming.number) {
      return { ...greeting, greetingNumber: 0 };
    }

    return greeting;
  });
};

export const GreetingsCard = ({
  tenantLocation,
  mailboxId,
  voicemailBoxLocationIds,
  onChange,
  greetings,
  isDeviceBox,
}: {
  tenantLocation: WeaveLocationGroup;
  mailboxId: string;
  voicemailBoxLocationIds: string[];
  onChange: (incoming: VoicemailGreeting[]) => void;
  greetings: VoicemailGreeting[];
  isDeviceBox: boolean;
}) => {
  const onGreetingChange = (incoming: { id: string; number: number; name: string }) => {
    const updatedGreetings = getUpdatedGreetings(greetings, incoming);

    onChange(updatedGreetings);
  };

  return (
    <GreetingsCardContent
      tenantLocation={tenantLocation}
      mailboxId={mailboxId}
      greetings={greetings}
      onGreetingChange={onGreetingChange}
      isDeviceBox={isDeviceBox}
      voicemailBoxLocationIds={voicemailBoxLocationIds}
    />
  );
};

const SelectGreetingModal = ({
  modalProps,
  mailboxId,
  tenantLocation,
}: {
  modalProps: ModalControlModalProps;
  mailboxId: string;
  tenantLocation: WeaveLocationGroup;
}) => {
  const { t } = useTranslation('phone');
  const { uploadAndCreateGreeting } = useVoicemailBoxGreetingMutations(tenantLocation.phoneTenantId ?? '', mailboxId);

  return (
    <Modal {...modalProps} minWidth={600}>
      <Modal.Header
        onClose={() => {
          // reset();
          modalProps.onClose();
        }}
      >
        {t('Add a Greeting')}
      </Modal.Header>
      <Modal.Body>
        <Tabs initialTab='library'>
          <Tabs.Bar css={{ marginBottom: theme.spacing(2) }}>
            <Tabs.Tab id='library' controls='library-panel'>
              {t('Audio Library')}
            </Tabs.Tab>
            <Tabs.Tab id='upload' controls='upload-panel'>
              {t('Upload New')}
            </Tabs.Tab>
          </Tabs.Bar>
          <Tabs.Panel id='library-panel' controller='library'>
            <AudioListTab tenantLocation={tenantLocation} mailboxId={mailboxId} modalProps={modalProps} />
          </Tabs.Panel>
          <Tabs.Panel id='upload-panel' controller='upload'>
            <AudioUploadModalContent
              customUploadFn={(payload) => {
                if (!tenantLocation.phoneTenantId) {
                  return;
                }

                return uploadAndCreateGreeting.mutate(
                  {
                    mailboxId,
                    greetingName: payload.fileName,
                    greetingNumber: 0,
                    file: payload.file,
                  },
                  {
                    onSettled: () => {
                      modalProps.onClose();
                    },
                  }
                );
              }}
              tenantId={tenantLocation.phoneTenantId ?? ''}
              isUploading={uploadAndCreateGreeting.isLoading}
              modalProps={modalProps}
            />
          </Tabs.Panel>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

const AudioListTab = ({
  tenantLocation,
  mailboxId,
  modalProps,
}: {
  tenantLocation: WeaveLocationGroup;
  mailboxId: string;
  modalProps: ModalControlModalProps;
}) => {
  const { t } = useTranslation('phone');
  const searchField = useFormField({ type: 'text' });

  const { data: phoneMedia, isLoading } = useAudioQuery({
    tenantId: tenantLocation.phoneTenantId ?? '',
    allowedOptions: { custom: true },
    mailboxId,
  });
  // const { data: phoneMedia = [], isLoading } = useQuery({
  //   /**
  //    * TODO: Change this to use the tenant ID instead of the location ID
  //    * We need to use the locationID for now because the endpoint is location based, switching locations in the
  //    */
  //   queryKey: [tenantLocation.locationID, ...queryKeys.settings.listVoicemailGreetings()],
  //   queryFn: () => {
  //     return PhoneMediaApi.list({ locationId: tenantLocation.locationID });
  //   },
  // });
  const { create } = useVoicemailBoxGreetingMutations(tenantLocation.phoneTenantId ?? '', mailboxId);
  const alerts = useAlert();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const filteredList = useMemo(
    () =>
      phoneMedia?.customMedia
        ? phoneMedia.customMedia.filter((media) => media.name.toLowerCase().includes(searchField.value.toLowerCase()))
        : [],
    [phoneMedia, searchField.value]
  );

  useEffect(() => {
    const mediaId = phoneMedia?.customMedia[activeIndex]?.id;
    if (mediaId) {
      (document.querySelector(`[data-list-id="${mediaId}"]`) as HTMLElement | undefined)?.focus();
    }
  }, [activeIndex, phoneMedia]);

  return (
    <div style={{ display: 'grid', gap: theme.spacing(2) }}>
      <SearchField {...searchField} name='audio-search' />
      {isLoading ? (
        <div style={{ display: 'grid', justifyItems: 'center' }}>
          <SpinningLoader />
          <Text>{t('Loading Audio Files')}</Text>
        </div>
      ) : (
        // This outer div gives the tab a fixed height
        <div
          style={{
            height: 400,
          }}
        >
          {filteredList.length > 0 ? (
            <NakedUl
              css={{
                display: 'grid',
                gap: theme.spacing(1),
                outline: 'none',
                overflow: 'auto',
                maxHeight: 400,
              }}
              onKeyDown={(e) => {
                if (e.code === 'ArrowDown') {
                  setActiveIndex((index) => (index !== null ? Math.min(filteredList.length - 1, index + 1) : 0));
                } else if (e.code === 'ArrowUp') {
                  setActiveIndex((index) => (index !== null ? Math.max(0, index - 1) : 0));
                }
              }}
            >
              {filteredList?.map((item, index) => (
                <li
                  key={item.id}
                  css={[
                    {
                      display: 'grid',
                      gridTemplateColumns: '1fr 40%',
                      gap: theme.spacing(2),
                      alignItems: 'center',
                      border: `1px solid ${theme.colors.neutral30}`,
                      borderRadius: theme.borderRadius.medium,
                      padding: theme.spacing(1),
                      cursor: 'pointer',
                      ':focus-within': {
                        outline: `2px solid ${theme.colors.primary50}`,
                        outlineOffset: -2,
                        background: theme.colors.primary5,
                      },
                    },
                    index === activeIndex &&
                      selectedIndex === activeIndex && {
                        '&,:focus-within': {
                          outline: `2px solid ${theme.colors.primary50}`,
                          outlineOffset: -2,
                          background: theme.colors.white,
                        },
                      },
                    index === selectedIndex && {
                      '&,:focus-within': {
                        outline: `2px solid ${theme.colors.primary50}`,
                        outlineOffset: -2,
                      },
                    },
                  ]}
                  onMouseDown={() => {
                    setActiveIndex(index);
                    setSelectedIndex(index);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter' || e.code === 'Space') {
                      setSelectedIndex(index);
                    }
                  }}
                  data-list-id={item.id}
                  tabIndex={index === activeIndex ? 0 : -1}
                >
                  <Text css={styles.truncate}>{item.name}</Text>
                  <CachedAudioScrubber
                    tabIndex={index === activeIndex ? 0 : -1}
                    filePath={buildAudioLibraryPath({
                      media: { id: item.id, path: item.path, isGlobal: false },
                    })}
                    mediaId={item.id}
                  />
                </li>
              ))}
            </NakedUl>
          ) : (
            <div>
              <Text>{t('No audio files found.')}</Text>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          // Just to give the button focus state some space
          marginBottom: theme.spacing(1),
        }}
      >
        {selectedIndex !== null ? (
          <PrimaryButton
            disabled={selectedIndex === null}
            onClick={() => {
              if (selectedIndex !== null && tenantLocation.phoneTenantId) {
                const selectedAudio = filteredList[selectedIndex];
                if (selectedAudio) {
                  create.mutate(
                    {
                      mailboxId,
                      greetingName: selectedAudio.name ?? '',
                      greetingNumber: 0,
                      mediaId: selectedAudio.id,
                      tenantId: tenantLocation.phoneTenantId,
                    },
                    {
                      onSuccess: () => {
                        alerts.success(t('Successfully added greeting.'));
                      },
                      onError: () => {
                        alerts.error(t('Failed to add greeting.'));
                      },
                      onSettled: () => {
                        modalProps.onClose();
                      },
                    }
                  );
                }
              }
            }}
          >
            {t('Use Selected Audio')}
          </PrimaryButton>
        ) : (
          <div>
            <Heading level={2} textAlign='center'>
              {t("Don't see your media file?")}
            </Heading>
            <Text>
              {t(
                'Dial *86 on your phone to record a new media file, then refresh this page to have it appear here for selection.'
              )}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

const decorateLabelForDeviceBox = (label: string) => {
  if (label === '0') {
    return i18next.t('0: Unassigned', { ns: 'phone' });
  }

  if (label === '1') {
    return i18next.t('1: Assigned', { ns: 'phone' });
  }

  return label;
};

const GreetingNumberField = ({
  options,
  greeting,
  onChange,
}: {
  options: { value: string; label: string; disabled: boolean }[];
  greeting: VoicemailGreeting;
  onChange: (incoming: { id: string; number: number; name: string }) => void;
}) => {
  const field = useFormField(
    {
      type: 'dropdown',
      value: greeting.greetingNumber.toString(),
    },
    [greeting.greetingNumber]
  );

  return (
    <DropdownField
      {...field}
      onChange={(e) => {
        field.onChange(e);
        onChange({ id: greeting.greetingId, number: +e.value, name: greeting.greetingName ?? '' });
      }}
      name='greeting-number'
      label=''
    >
      {options.map((option) => {
        return (
          <DropdownField.Option key={option.value} value={option.value}>
            {option.label}
          </DropdownField.Option>
        );
      })}
    </DropdownField>
  );
};

const GreetingNameField = ({
  greeting,
  onChange,
}: {
  greeting: VoicemailGreeting;
  onChange: (incoming: { id: string; number: number; name: string }) => void;
}) => {
  const field = useFormField(
    {
      type: 'text',
      value: greeting.greetingName ?? '',
    },
    [greeting.greetingName]
  );

  return (
    <TextField
      {...field}
      onChange={(e) => {
        field.onChange(e);
        // @ts-ignore
        onChange({ id: greeting.greetingId, number: greeting.greetingNumber, name: e.target.value ?? '' });
      }}
      name='greeting-name'
      label=''
    />
  );
};
