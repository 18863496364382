import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { QuickFillTrayContext } from './context';
import { TrayContent } from './tray-content';

type Props = {
  closeModal: () => void;
};

export const QuickFillTrayContent = ({ closeModal }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');

  return (
    <QuickFillTrayContext.Provider value={{ closeModal }}>
      <Text color='light' css={{ padding: theme.spacing(0, 6, 2, 3) }}>
        {t(
          'Easily fill last-minute cancellations or openings in your schedule by messaging your customers who need to get in for an appointment as soon as possible.'
        )}
      </Text>
      <TrayContent />
    </QuickFillTrayContext.Provider>
  );
};
