import { useContext } from 'react';
import { css } from '@emotion/react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from '@xyflow/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text, useTooltip } from '@frontend/design-system';
import { SharedEdgeProps } from '../data';
import { InteractionContext } from '../provider';

const BASIC_EDGE_STYLES = {
  stroke: theme.colors.neutral70,
  strokeWidth: 2,
};

export function AnimatedSVGEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={BASIC_EDGE_STYLES} />
    </>
  );
}

export function AnimatedSVGEdgeReverse({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={BASIC_EDGE_STYLES} />
    </>
  );
}

export function PhoneTreeEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: SharedEdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerY: targetY - 50,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ ...BASIC_EDGE_STYLES, ...style }} />
      {data?.label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${targetX}px,${targetY - 20}px)`,
              pointerEvents: 'all',
              width: 24,
              height: 24,
              backgroundColor: theme.colors.black,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text as='span' color='white' weight='bold' size='small'>
              {data.label}
            </Text>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

export function BasicEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  source,
  style = {},
  markerEnd,
  data,
}: SharedEdgeProps) {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { t } = useTranslation('phone');
  const { mode, onPlusIconClick } = useContext(InteractionContext);
  const { tooltipProps, triggerProps, Tooltip } = useTooltip({ placement: 'right' });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ ...BASIC_EDGE_STYLES, ...style }} />
      {data?.label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -300%) translate(${targetX}px,${targetY}px)`,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
              padding: theme.spacing(0, 1),
              backgroundColor: theme.colors.black,
              borderRadius: theme.borderRadius.small,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text as='span' color='white' weight='bold' size='small'>
              {data.label}
            </Text>
          </div>
        </EdgeLabelRenderer>
      )}

      {mode === 'edit' && data?.isExtendable && (
        <EdgeLabelRenderer>
          <Button
            variant='secondary'
            onClick={() => {
              onPlusIconClick?.({ nodeId: source });
            }}
            {...triggerProps}
            css={css`
              position: absolute;
              transform: ${data?.label
                ? `translate(-50%, -150%) translate(${targetX}px,${targetY}px)`
                : `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`};
              pointer-events: all;
              background-color: ${theme.colors.neutral5};
              display: flex;
              justify-content: center;
              align-items: center;
              padding: ${theme.spacing(1)};
            `}
            iconName='plus'
          ></Button>

          <Tooltip
            {...tooltipProps}
            css={css`
              font-weight: 400;
            `}
          >
            {t('Add step')}
          </Tooltip>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
