import { FC, useCallback } from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { PersonHelpers } from '@frontend/api-person';
import { useTaskCenterMutations, TaskCenterTypes } from '@frontend/api-task-center';
import { useTranslation } from '@frontend/i18n';
import { PanelHeader } from '@frontend/panel-engine';
import { theme } from '@frontend/theme';
import { Button, useForm, TextareaField, TextField, useAlert } from '@frontend/design-system';
import { useTaskCenterStore } from '../provider';
import { CreateTaskFormType, CreateTaskFormKeys } from '../types';
import { PersonPicker, DatePickerDialog, TaskPrioritySwitcher, TaskStatusSwitcher, LocationPicker } from './common';

export const CreateTask: FC = () => {
  const { t } = useTranslation('task-center');
  const alert = useAlert();
  const {
    showCreateTask,
    setShowCreateTask,
    locationId,
    assignees,
    patients,
    locationsData,
    shouldShowLocationDropdown,
  } = useTaskCenterStore([
    'showCreateTask',
    'setShowCreateTask',
    'locationId',
    'assignees',
    'patients',
    'locationsData',
    'shouldShowLocationDropdown',
  ]);

  const { taskCreateMutation } = useTaskCenterMutations();

  const { formProps, getFieldProps, values, seedValues, isComplete, reset } = useForm<
    CreateTaskFormType,
    CreateTaskFormKeys
  >({
    fields: {
      task: { type: 'text', required: true, placeholder: t('Task title...'), value: '' },
      description: { type: 'text', placeholder: t('Add description...'), value: '' },
      location: {
        type: 'dropdown',
        placeholder: t('Select Location'),
        value: shouldShowLocationDropdown ? '' : locationId,
        required: true,
      },
      patient: { type: 'dropdown', placeholder: t('Select Patient'), value: '' },
      dueDate: { type: 'datePicker', placeholder: t('Pick Due Date'), value: '' },
      assignee: { type: 'dropdown', placeholder: t('Select Assignee'), value: '' },
      status: { type: 'dropdown', placeholder: t('Select Status'), value: '' },
      priority: { type: 'dropdown', placeholder: t('Select priority'), value: '' },
    },
  });

  const createTask = useCallback(() => {
    const patient = patients.find((item) => values.patient === item.PersonID);
    // FIXME: Even after defining the form types we have to infer the types here.
    taskCreateMutation.mutate(
      {
        tasks: [
          {
            locationId: values.location as string,
            title: values.task as string,
            description: values.description,
            dueDate: values.dueDate,
            assignee: values.assignee,
            type: TaskCenterTypes.TaskType.TYPE_OTHER,
            source: TaskCenterTypes.TaskSource.SOURCE_TASK_CENTER,
            status: !!values.status
              ? (values.status as TaskCenterTypes.TaskStatus)
              : TaskCenterTypes.TaskStatus.STATUS_UNKNOWN,
            // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema
            // releases
            priority: values.priority as TaskCenterTypes.Task_Priority,
            patient: {
              name: PersonHelpers.getFullName(patient),
              id: patient?.PersonID,
            },
          },
        ],
      },
      {
        onSuccess: () => {
          alert.success(t('Task created successfully'));
          setShowCreateTask(false);
          reset();
        },
        onError: () => {
          alert.error(t('Unable to create task at this moment'));
        },
      }
    );
  }, [patients, values]);

  return (
    <motion.div
      initial={{ display: 'none' }}
      animate={{ display: showCreateTask ? 'block' : 'none' }}
      exit={{ display: 'none' }}
      transition={{ duration: 0 }}
      css={css`
        width: 100%;
      `}
    >
      <header css={headerStyle}>
        <Button
          iconName='back'
          variant='secondary'
          size='large'
          onClick={() => {
            setShowCreateTask(false);
            reset();
          }}
          disabled={taskCreateMutation.isLoading}
        />
        <PanelHeader.Title level={3}>{t('New Task')}</PanelHeader.Title>
      </header>
      <form
        {...formProps}
        css={css`
          padding: ${theme.spacing(2, 1, 2, 3)};
        `}
      >
        <TextField
          label=''
          css={textFieldStyle}
          {...getFieldProps('task')}
          className='create-task-title'
          containerCss={InputContainerStyle}
          error=''
        />
        <TextareaField
          {...getFieldProps('description')}
          label=''
          css={textareaFieldStyle}
          className='create-task-title'
          containerCss={InputContainerStyle}
        />
        <LocationPicker
          data={locationsData}
          label={t('Location')}
          placeholder={getFieldProps('location').placeholder ?? ''}
          value={getFieldProps('location').value}
          shouldRenderChildren={shouldShowLocationDropdown}
          onSelect={(value: string) => seedValues({ ...values, location: value })}
        />
        <PersonPicker
          data={patients}
          type='patient'
          label={t('Patient')}
          icon='user'
          placeholder={getFieldProps('patient').placeholder ?? ''}
          buttonIconName='user'
          onChange={(value) => seedValues({ ...values, patient: value })}
          value={getFieldProps('patient').value}
        />
        <DatePickerDialog
          label={t('Due Date')}
          placeholder={getFieldProps('dueDate').placeholder ?? ''}
          onChange={(value) => seedValues({ ...values, dueDate: value })}
          value={getFieldProps('dueDate').value}
        />

        <PersonPicker
          data={assignees}
          type='assignee'
          label={t('Assignee')}
          icon='patient'
          placeholder={getFieldProps('assignee').placeholder ?? ''}
          buttonIconName='patient'
          onChange={(value) => seedValues({ ...values, assignee: value })}
          value={getFieldProps('assignee').value}
        />

        <TaskStatusSwitcher
          icon='pending'
          label={t('Status')}
          placeholder={getFieldProps('status').placeholder ?? ''}
          buttonIconName='pending'
          onChange={(value: TaskCenterTypes.TaskStatus | '') => seedValues({ ...values, status: value })}
          value={getFieldProps('status').value as TaskCenterTypes.TaskStatus}
        />
        <TaskPrioritySwitcher
          icon='bar-chart'
          label={t('Priority')}
          placeholder={getFieldProps('priority').placeholder ?? ''}
          buttonIconName='no-priority'
          // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
          onChange={(value: TaskCenterTypes.Task_Priority | '') => seedValues({ ...values, priority: value })}
          // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
          value={getFieldProps('priority').value as TaskCenterTypes.Task_Priority}
        />

        <div css={actionBarStyle}>
          <Button
            variant='secondary'
            onClick={() => {
              setShowCreateTask(false);
              reset();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button loading={taskCreateMutation.isLoading} onClick={createTask} disabled={!isComplete}>
            {t('Save')}
          </Button>
        </div>
      </form>
    </motion.div>
  );
};

const headerStyle = css`
  padding: ${theme.spacing(2)};
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.colors.neutral10};
  border-bottom: 1px solid ${theme.colors.neutral10};
`;

const actionBarStyle = css`
  display: flex;
  gap: ${theme.spacing(2)};
  justify-content: flex-end;
`;

const InputContainerStyle = css`
  .create-task-title {
    box-shadow: none !important;
  }
`;

const textFieldStyle = css`
  border: none;
`;

const textareaFieldStyle = css`
  margin: ${theme.spacing(3, 0)};
  border: none;
`;
