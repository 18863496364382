import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

const VISIBILITY_TRACKER_PREFIX = 'callIntelVisibilityTracker';

const getStorageKey = (identifier: string): string => `${VISIBILITY_TRACKER_PREFIX}_${identifier}`;

const getVisibilityTracker = (storageKey: string) => {
  try {
    return JSON.parse(localStorage.getItem(storageKey) || '{}');
  } catch {
    return {};
  }
};

const updateVisibilityTracker = (storageKey: string, updates: Partial<Record<string, any>>) => {
  const tracker = { ...getVisibilityTracker(storageKey), ...updates };
  localStorage.setItem(storageKey, JSON.stringify(tracker));
};

const getTodayDate = (): string => dayjs().format('YYYY-MM-DD');

type UseVisibilityTrackerProps = {
  identifier: string;
  isNewFeature: boolean;
  maxDays?: number;
};

export const useVisibilityTracker = ({ identifier, isNewFeature, maxDays = 3 }: UseVisibilityTrackerProps) => {
  const storageKey = getStorageKey(identifier);
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isNewFeature) {
      // If the feature is inactive, stop showing it.
      setIsVisible(false);
      return;
    }

    const today = getTodayDate();
    const tracker = getVisibilityTracker(storageKey);

    let daysSeen = tracker.daysSeen || 0;

    // If it's a new day, increment the `daysSeen` count
    if (tracker.lastSeenDate !== today) {
      daysSeen += 1;
      updateVisibilityTracker(storageKey, { lastSeenDate: today, daysSeen });
    }

    // Show element only if within `maxDays` limit
    setIsVisible(daysSeen <= maxDays);
  }, [maxDays, isNewFeature, storageKey]);

  return { isVisible, elementRef };
};
