import { useQuery, useQueries, useQueryClient } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions, LimitedSchemaFetchQueryOptions } from '@frontend/react-query-helpers';
import { SchemaPetsV1Service } from '../service';
import { FetchPetIO } from '../types';

export const getFetchPetQueryKey = (req: FetchPetIO['input']) => ['PetsV1', 'FetchPet', req] as const;
type QueryKey = ReturnType<typeof getFetchPetQueryKey>;

/**
 * A hook that returns a query for the `FetchPet` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useFetchPetQuery = <E = unknown, D = FetchPetIO['output']>(
  req: FetchPetIO['input'],
  options?: LimitedSchemaQueryOptions<FetchPetIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<FetchPetIO['output'], E, D, QueryKey>({
    queryKey: getFetchPetQueryKey(req),
    queryFn: () => SchemaPetsV1Service.FetchPet(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `FetchPet` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useFetchPetQueries = <E = unknown, D = FetchPetIO['output']>(
  argsArr: {
    req: FetchPetIO['input'];
    options?: LimitedSchemaQueryOptions<FetchPetIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getFetchPetQueryKey(req),
      queryFn: () => SchemaPetsV1Service.FetchPet(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useFetchPetQuery<E, D>>[];

/**
 * A hook that returns a function to fetch the query for the `FetchPet` endpoint.
 * It uses `queryClient.fetchQuery` to ensure proper caching behavior.
 *
 * @returns The function to fetch the query, with the following args:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `fetchQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useFetchFetchPet = () => {
  const queryClient = useQueryClient();

  const fetchFetchPet = async <E = unknown, D = FetchPetIO['output']>(
    req: FetchPetIO['input'],
    options?: LimitedSchemaFetchQueryOptions<FetchPetIO, E, D, QueryKey>,
    httpOptions?: Options
  ) => {
    const response = await queryClient.fetchQuery<FetchPetIO['output'], E, D, QueryKey>(
      getFetchPetQueryKey(req),
      () => SchemaPetsV1Service.FetchPet(req, httpOptions),
      options
    );

    return response;
  };

  return fetchFetchPet;
};
