import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import EmptyMentionsSVG from '../../assets/svg/empty-mentions.svg';

export const EmptyMentions = () => {
  return (
    <section css={styles.emptyWrap}>
      <div css={styles.empty}>
        {<img src={EmptyMentionsSVG} />}
        <br />
        <Text size='large' textAlign='center' color='subdued'>
          If someone mentions you, you'll see their messages here!
        </Text>
        <br />
        <Text size='large' textAlign='center' color='subdued'>
          Use '@' to tag someone and they'll get notified right away.
        </Text>
      </div>
    </section>
  );
};

const styles = {
  emptyWrap: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,
  empty: css`
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    padding: ${theme.spacing(0, 4)};
    max-width: 312px;
    margin: auto;
    img {
      width: 100%;
    }
  `,
};
