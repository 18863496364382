// TODO: use Crop instead of the PercentCrop in the final version
// import { Crop } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { PercentCrop } from 'react-image-crop';

const createCrop = ({ width, height, x, y }: { width: number; height: number; x: number; y: number }): PercentCrop => ({
  unit: '%',
  x,
  y,
  width,
  height,
});

export const defaultCrop = createCrop({ width: 100, height: 100, x: 0, y: 0 });
