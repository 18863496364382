import { DigitiseFormsDetails_StageDates } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { DigitizationStep } from '../provider';

dayjs.extend(isSameOrAfter);
const DEFAULT_DATE_PREFIX = '0001-01-01';

export const isValidDate = (date?: string): boolean => {
  return !!date && !date.startsWith(DEFAULT_DATE_PREFIX);
};

export const getCurrentStep = (stageDates: DigitiseFormsDetails_StageDates): DigitizationStep => {
  const { processedDate, assignedDate, submissionDate } = stageDates;

  const isAssignedAndSubmissionDateValid = isValidDate(assignedDate) && isValidDate(submissionDate);
  if (
    isValidDate(processedDate) &&
    isAssignedAndSubmissionDateValid &&
    dayjs(assignedDate).isSameOrAfter(submissionDate) &&
    dayjs(processedDate).isSameOrAfter(submissionDate)
  ) {
    return DigitizationStep.COMPLETED;
  } else if (isValidDate(submissionDate)) {
    return DigitizationStep.IN_PROGRESS;
  }
  return DigitizationStep.UPLOAD_FORMS;
};
