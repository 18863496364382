import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Checkbox, Chip, Table, TableColumnConfig, useControlledField } from '@frontend/design-system';
import { checkCanSelfSubscribe } from '../../../self-upgrade.util';
import { SelfSubscribeEligibilityData } from '../../../types';
import { InfoTextWithTooltip } from '../common/InfoTextWithTooltip';

interface SelfUpgradeLocationSelectionTableProps {
  data: SelfSubscribeEligibilityData[];
  price: number;
  subscriptionTerm: 'monthly' | 'annually';
  initialSelectedData: SelfSubscribeEligibilityData[];
  onChangeSelection: (locationIds: string[]) => void;
}

const checkIsRowDisabled = (rowData: SelfSubscribeEligibilityData) => {
  return (
    rowData.isStateActive ||
    rowData.subscriptionStatus !== FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIBE ||
    !rowData.canManageAddons
  );
};
export const SelfUpgradeLocationSelectionTable: FC<SelfUpgradeLocationSelectionTableProps> = ({
  data = [],
  price,
  subscriptionTerm,
  initialSelectedData,
  onChangeSelection,
}) => {
  const { accessibleLocationData } = useAppScopeStore();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-location-selection-table' });

  const [selectedLocationMap, setSelectedLocationMap] = useState<Record<string, boolean>>({});
  const selectionInitialisedRef = useRef(false);

  const columns = useMemo<TableColumnConfig<SelfSubscribeEligibilityData>[]>(() => {
    return [
      {
        id: 'select',
        Header: '',
        accessor: (rowData): { isSelectable: boolean; isRowDisabled: boolean; locationID: string } => ({
          isRowDisabled: checkIsRowDisabled(rowData),
          isSelectable: checkCanSelfSubscribe(rowData),
          locationID: rowData.locationId,
        }),
        cellRenderer: ({
          isRowDisabled,
          isSelectable,
          locationID,
        }: {
          isSelectable: boolean;
          isRowDisabled: boolean;
          locationID: string;
        }) => {
          if (isSelectable) {
            return (
              <SelectCell
                locationId={locationID}
                selectedLocationMap={selectedLocationMap}
                setSelectedLocationMap={setSelectedLocationMap}
              />
            );
          }
          return <Icon name='lock' color={isRowDisabled ? 'disabled' : 'light'} />;
        },
        disableSortBy: true,
        maxWidth: 60,
      },
      {
        id: 'location',
        Header: t('Location'),
        accessor: (rowData): { locationName?: string; isRowDisabled: boolean } => ({
          locationName: accessibleLocationData[rowData.locationId]?.name,
          isRowDisabled: checkIsRowDisabled(rowData),
        }),
        cellRenderer: ({ locationName, isRowDisabled }: { locationName: string; isRowDisabled: boolean }) => {
          return (
            <Chip.SingleChip css={{ maxWidth: theme.spacing(30), opacity: isRowDisabled ? 0.5 : 1 }}>
              {locationName}
            </Chip.SingleChip>
          );
        },
        sortType: (rowA, rowB) => {
          return (accessibleLocationData[rowA.original.locationId]?.name || '')?.localeCompare(
            accessibleLocationData[rowB.original.locationId]?.name || ''
          );
        },
        maxWidth: 350,
      },
      {
        id: 'price',
        Header: t('Cost'),
        accessor: (rowData) => rowData,
        cellRenderer: (rowData: SelfSubscribeEligibilityData) => {
          if (rowData.isStateActive) {
            return <InfoTextWithTooltip text={t('Already activated')} />;
          } else if (
            rowData.subscriptionStatus !== FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIBE
          ) {
            return (
              <InfoTextWithTooltip
                text={t('This location is not eligible')}
                tooltipText={t('This feature is incompatible with this location')}
              />
            );
          } else if (!rowData.canManageAddons) {
            return (
              <InfoTextWithTooltip
                text={t('You are not an admin at this location')}
                tooltipText={t('An admin may complete upgrade for this location.')}
              />
            );
          }
          return (
            <InfoTextWithTooltip
              text={`$${price}/${subscriptionTerm === 'monthly' ? t('mo') : t('year')}`}
              tooltipText={rowData.hasOverdueBalance && t('Cannot upgrade with an overdue balance')}
              textColor='default'
              iconColor='error'
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, [accessibleLocationData, selectedLocationMap, price, subscriptionTerm]);

  const modifiedData = useMemo(() => {
    return data.sort((a, b) => {
      const priorityA = checkCanSelfSubscribe(a) ? 0 : a.hasOverdueBalance ? 1 : 2;
      const priorityB = checkCanSelfSubscribe(b) ? 0 : b.hasOverdueBalance ? 1 : 2;
      return priorityA - priorityB;
    });
  }, [data]);

  useEffect(() => {
    onChangeSelection(
      Object.entries(selectedLocationMap)
        .filter(([, isSelected]) => isSelected)
        .map(([locationId]) => locationId)
    );
  }, [selectedLocationMap]);

  useEffect(() => {
    if (!selectionInitialisedRef.current) {
      setSelectedLocationMap(
        initialSelectedData.filter(checkCanSelfSubscribe).reduce((acc, { locationId }) => {
          acc[locationId] = true;
          return acc;
        }, {} as Record<string, boolean>)
      );
      selectionInitialisedRef.current = true;
    }
  }, [initialSelectedData]);

  return <Table<SelfSubscribeEligibilityData> colConfig={columns} data={modifiedData} />;
};

type SelectCellProps = {
  locationId: string;
  selectedLocationMap: Record<string, boolean>;
  setSelectedLocationMap: Dispatch<SetStateAction<Record<string, boolean>>>;
};

const SelectCell = ({ locationId, selectedLocationMap, setSelectedLocationMap }: SelectCellProps) => {
  const checkboxProps = useControlledField({
    type: 'checkbox',
    value: !!selectedLocationMap[locationId],
    onChange: () =>
      setSelectedLocationMap((state) => ({
        ...state,
        [locationId]: !state[locationId],
      })),
  });
  return <Checkbox {...checkboxProps} name={`${locationId}-select`} error={false} />;
};
