import { useMutation, useQuery, UseQueryResult } from 'react-query';
import { PersonAPI } from '@frontend/api-person';
import { getInsuranceDetails, initiateEligibilityReportWriteback } from './api';
import {
  DentalInsuranceCoverageResponse,
  DentalInsuranceCoverageResponseForDentalXChange,
  EligibilityReportWritebackParams,
} from './types';

export const useInsuranceDetailsByPerson = (
  personId: string,
  enabled?: boolean
): UseQueryResult<DentalInsuranceCoverageResponse, any> => {
  return useQuery([personId, 'dental-coverage-info'], () => getInsuranceDetails(personId), {
    staleTime: 5 * 60 * 1000, // Adding stale time as 5 mins will be invalidating query during writebacks
    enabled: !!enabled && !!personId,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 15 * 60 * 1000, // Caching PMS data for 15 mins will be invalidating query during writebacks
  });
};

export const usePersonImage = (personId: string, enabled?: boolean): UseQueryResult<string, any> => {
  return useQuery([personId, 'photo'], () => PersonAPI.getPersonImage(personId), {
    staleTime: 5 * 60 * 1000, // Adding stale time as 5 mins will be invalidating query during writebacks
    enabled: !!enabled && !!personId,
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 15 * 60 * 1000, // Caching PMS data for 15 mins will be invalidating query during writebacks,
    onError: () => console.error('Error fetching person image'),
  });
};

export const useInitiateEligibilityReportWriteback = (locationId: string) => {
  return useMutation({
    mutationFn: ({ eligibilityReportWritebackRequest, personPMSId, sourceId }: EligibilityReportWritebackParams) =>
      initiateEligibilityReportWriteback(eligibilityReportWritebackRequest, sourceId, personPMSId),
    retry: false,
    mutationKey: [locationId, 'eligibility-report-writeback'],
  });
};

export const useDentalInsuranceDetailsByPerson = (
  personId: string,
  enabled?: boolean
): UseQueryResult<DentalInsuranceCoverageResponseForDentalXChange, any> => {
  return useQuery(
    [personId, 'dental-coverage-info', 'dental', 'dental-x-change'],
    () => getInsuranceDetails(personId),
    {
      staleTime: 5 * 60 * 1000, // Adding stale time as 5 mins will be invalidating query during writebacks
      enabled: !!enabled && !!personId,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000, // Caching PMS data for 15 mins will be invalidating query during writebacks
    }
  );
};
