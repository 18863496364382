import { useEffect, useMemo, useState } from 'react';
import { Column, Img, Row, Section } from '@react-email/components';
import { Image, ImageSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { PercentCrop } from 'react-image-crop';
import { useComposerSection } from '../../hooks';
import { ComposerSectionOutputProps, Corners } from '../../types';
import { getCornersStyle, getFrameStyle, getImageCropStyle } from '../../utils';
import { useContentComposer } from '../content-composer-provider';

const getImageWidthDifference = (count: number, spacing: number) => ((count - 1) * spacing) / count;

type ImageItemProps = {
  handleImageReady: () => void;
  height: number | undefined;
  image?: Image;
  index: number;
  props: ImageSection;
  width: number | undefined;
};

const ImageItem = ({ handleImageReady, height, image, index, props, width }: ImageItemProps) => {
  const { deviceSizeProps } = useContentComposer();
  const deviceSize = deviceSizeProps?.value;
  const { imageCount = 1, imageSpacing = 0 } = props;
  const imageWidth = (width ?? 0) - getImageWidthDifference(imageCount, imageSpacing);

  useEffect(() => {
    // if (image?.SecureURL) {
    //   imgRef.current?.setAttribute('src', image?.SecureURL);
    // }
    handleImageReady();
  }, [image]);

  return (
    <Img
      style={{
        width: deviceSize === 'mobile' ? '100%' : imageWidth,
        height,
        maxWidth: '100%',
        ...getMarginStyle(index, props, deviceSize),
        ...getImageCropStyle(imageWidth, height, image?.crop as PercentCrop),
        ...getCornersStyle(props.corners as Corners | undefined),
      }}
      src={image?.url}
    />
  );
};

export const ImageOutput = ({ onRenderReady }: ComposerSectionOutputProps) => {
  const [localImagesReady, setLocalImagesReady] = useState<boolean[]>([]);

  const { props, sectionHTML } = useComposerSection<ImageSection>();

  // to get the unique image elements inside the Image section.
  const imageElements = useMemo(() => sectionHTML?.querySelectorAll('img[data-type="src-image"]'), [sectionHTML]);

  const handleImageReady = (index: number) => {
    setLocalImagesReady((prev) => {
      const copy = [...prev];
      copy[index] = true;
      return copy;
    });
  };

  const images = props.images;

  useEffect(() => {
    if (localImagesReady.length === (images?.length ?? 0) && localImagesReady.every((ready) => ready)) {
      onRenderReady?.();
    }
  }, [localImagesReady, images]);

  return images?.length ? (
    <Section style={getFrameStyle(props.frame)}>
      {images.length === 1 ? (
        <ImageItem
          handleImageReady={() => handleImageReady(0)}
          height={imageElements?.[0]?.clientHeight}
          image={images[0]}
          index={0}
          props={props}
          width={imageElements?.[0]?.clientWidth}
        />
      ) : (
        <Row className='images-container'>
          {images.map((image, index) =>
            image ? (
              <Column
                key={index}
                className={`image-container image-container-${images.length}`}
                style={{
                  ...(props.height ? { height: props.height } : {}),
                  position: 'relative',
                }}
              >
                <ImageItem
                  handleImageReady={() => handleImageReady(index)}
                  height={imageElements?.[index]?.clientHeight}
                  image={image}
                  index={index}
                  props={props}
                  width={imageElements?.[index]?.clientWidth}
                />
              </Column>
            ) : null
          )}
        </Row>
      )}
    </Section>
  ) : null;
};

const getMarginStyle = (index: number, props: ImageSection, deviceSize = 'desktop') => {
  const imageCount = props.imageCount || 1;
  const imageSpacing = props.imageSpacing || 0;
  const halvedSpacing = imageSpacing / 2;
  const mobileSpacing = { marginBottom: imageSpacing };
  switch (index) {
    case 0:
      if (imageCount === 1) return { margin: '0 auto' };
      return deviceSize === 'mobile' ? mobileSpacing : { marginRight: halvedSpacing };
    case imageCount - 1:
      return deviceSize === 'mobile' ? {} : { marginLeft: halvedSpacing };
    default:
      return deviceSize === 'mobile' ? mobileSpacing : { margin: `0 ${halvedSpacing}px` };
  }
};

export const ImageOutputStyles = () => (
  <style>
    {`.images-container > tbody > tr > .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
    {`@media (max-width: 480px) {
        .images-container > tbody > tr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          align-items: center;
          .image-container {
            margin-bottom: 8px; // TODO: need to make this dynamic but this resets the other margin values
            img {
              margin: 0 !important;
            }
          }
        }
      }`}
    {`@media (min-width: 481px) {
      .images-container > tbody > tr {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .image-container-1 {
          width: 100%;
        }
        .image-container-2 {
          width: 50%;
        }
        .image-container-3 {
          width: 33.33%;
        }
      }
    }`}
  </style>
);
