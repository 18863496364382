import { css } from '@emotion/react';
import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { useSchedulingLocationInfo } from '../../hooks';
import { getBookingSiteUrl } from '../../utils';
import { GoogleAccountContent } from './GoogleBusinessProfileContent/GoogleAccountContent';
import { bookingSiteSetupTrackingIds } from './trackingIds';
import { WebsiteTabContent } from './WebsiteTabContent';

enum TabEnum {
  GoogleAccount = 'google-account',
  Website = 'website',
}

export const BookingSiteSetup = () => {
  const { t } = useTranslation('scheduleGlobalSettings');
  const { selectedLocationIds, isMultiLocation } = useSchedulingLocationInfo();
  const locationId = selectedLocationIds[0];

  const { getCustomizationFlagValue } = useScopedAppFlagStore();

  // NOTE: Google Account tab is rendered only for single location.
  // For multi-location, as of now, we don't have a decision made on it's implementation. This needs to be updated later.
  const bookingSiteUrlForGoogleAccount = getBookingSiteUrl(locationId, ScheduleRequestSource.GOOGLE_BUSINESS_PROFILE);
  const shouldRenderGoogleAccount = getCustomizationFlagValue('onlinescheduling') && !isMultiLocation;

  return (
    <section css={containerStyle}>
      <Tabs initialTab={shouldRenderGoogleAccount ? TabEnum.GoogleAccount : TabEnum.Website}>
        <Tabs.Bar>
          {shouldRenderGoogleAccount && (
            <Tabs.Tab id={TabEnum.GoogleAccount} trackingId={bookingSiteSetupTrackingIds.googleAccountTab}>
              {t('Google Account')}
            </Tabs.Tab>
          )}
          <Tabs.Tab id={TabEnum.Website}>{t('Website')}</Tabs.Tab>
        </Tabs.Bar>
        {shouldRenderGoogleAccount && (
          <Tabs.Panel controller={TabEnum.GoogleAccount}>
            <GoogleAccountContent locationId={locationId} bookingSiteURL={bookingSiteUrlForGoogleAccount} />
          </Tabs.Panel>
        )}
        <Tabs.Panel controller={TabEnum.Website}>
          <WebsiteTabContent />
        </Tabs.Panel>
      </Tabs>
    </section>
  );
};

const containerStyle = css({
  height: '100%',
  maxWidth: 700,
  background: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing(2, 3, 5, 3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
