import { useEffect } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { PersonUnpaidInvoiceList } from '@frontend/person-invoice-list';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { SelectStepBodyProps } from './select.types';

export const SelectBody = ({
  onPreviewClick,
  onCreateClick,
  onSelectInvoice,
  onMount,
  onClickPrimary,
  onClickSecondary,
  primary,
  secondary,
}: SelectStepBodyProps) => {
  const {
    person,
    personQuery: { isLoading: isLoadingPerson },
    invoice,
    activeInvoiceId,
  } = useCollectPaymentMultiContext();

  const isValid = !!activeInvoiceId;

  const { t } = useTranslation('payments');

  const handlePreviewClick = (invoice: InvoiceModel) => {
    onPreviewClick(invoice);
  };

  const handleCreateClick = () => {
    onCreateClick();
  };

  const handleSelectInvoice = (invoice: InvoiceModel) => {
    onSelectInvoice(invoice);
  };

  const { primaryTrackingId } = useScopedTrackingIds({
    primaryTrackingId: 'select--primary-btn',
  });

  useEffect(() => {
    onMount(invoice);
  }, []);

  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.select}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.select]}
      isValid={isValid}
      footer={{
        onClickPrimary: onClickPrimary,
        onClickSecondary: onClickSecondary,
        primary: primary || {
          children: t('Select'),
          trackingId: primaryTrackingId,
        },
        secondary: secondary,
      }}
    >
      {person && (
        <>
          <PersonUnpaidInvoiceList
            onInvoiceClick={handleSelectInvoice}
            onPreviewClick={handlePreviewClick}
            createInvoiceOnClick={handleCreateClick}
            personId={person.personId}
            initialInvoice={invoice}
          />
        </>
      )}
      {!person && isLoadingPerson && <div>Loading person...</div>}
      {!person && !isLoadingPerson && <div>Person not found</div>}
    </StyledStep>
  );
};
