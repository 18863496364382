import React, { useCallback, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useNavigate } from '@tanstack/react-location';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import {
  InvoiceFilterStatus,
  InvoiceModel,
  InvoiceSearchParams,
  InvoiceStatus,
  useCancelInvoiceMutation,
} from '@frontend/api-invoices';
import {
  PaymentMethod,
  PaymentMethodStatus,
  PaymentPlanQueries,
  setDefaultPaymentMethod,
} from '@frontend/api-payment-plans';
import { PersonTypes } from '@frontend/api-person';
import { usePaymentAction } from '@frontend/contact-actions-payment';
import { PaymentsProfilePromotionWrapper } from '@frontend/high-value-adoption';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import {
  CollectPaymentModalSteps,
  CollectPaymentMulti,
  CollectPaymentMultiModal,
  CollectPaymentMultiPortal,
  PAYMENT_MULTI_STEPS,
  useCollectPaymentMulti,
  useSyncCollectPaymentMultiToModal,
} from '@frontend/payments-collection-flow';
import { PaymentsFeatureFlags, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useInvoiceShallowStore, useUserInvoicesInRange } from '@frontend/payments-invoice-controller';
import { usePersonActiveInvoiceList } from '@frontend/person-invoice-list';
import { PersonInvoiceModalSteps } from '@frontend/person-invoice-payment';
import { theme } from '@frontend/theme';
import {
  Button,
  ConfirmationModal,
  Heading,
  Modal,
  ModalControlTriggerProps,
  SkeletonLoader,
  Text,
  useModalControl,
} from '@frontend/design-system';
import { ActionItem } from './payments-panel/action-item';
import { BillingMethodModal } from './payments-panel/billing-methods-modal';
import { InvoiceCardActions } from './payments-panel/invoice-card';
import { EmptyInvoiceChart, InvoiceSummaryChart } from './payments-panel/invoice-summary-chart';
import { OutstandingInvoiceCards, SortOptions } from './payments-panel/outstanding-invoice-cards';

export type PaymentsChartData = {
  key: string;
  label: string;
  value: number;
}[];

export type PaymentsPanelProps = {
  trackingId: string;
  className?: string;
  person?: PersonTypes.Person;
  isActive?: boolean;
};

const styles = {
  parentWrapper: css`
    padding: ${theme.spacing(3)};
    background: ${theme.colors.neutral5};
  `,
  parent: css`
    display: grid;
    row-gap: ${theme.spacing(2)};
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  `,
};

const PAYMENT_SUCCESS_TIMEOUT = 2000;

type CollectModalDataLayerProps = {
  isActive?: boolean;
  trackingId: string;
  className?: string;
  person?: PersonTypes.Person;
  invoiceId?: string;
  locationId: string;
  invoicesInRange: InvoiceModel[];
  refetchInvoicesCallback: () => void;
};
type CollectModalUiProps = {
  paymentTriggerProps: ModalControlTriggerProps;
  PaymentModal: EmotionJSX.Element;
  invoicesInRange: InvoiceModel[];
  refetchInvoicesCallback: () => void;
  goToCreateStep: () => void;
  goToPaymentMethodsStep: () => void;
  goToShareInMessageStep: () => void;
};

export function PaymentsPanel(props: PaymentsPanelProps) {
  const { locationId } = useMultiQueryUtils();

  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds: [locationId],
  });

  const { selectedInvoiceId } = useInvoiceShallowStore('setFilter', 'setSelectedInvoiceId', 'selectedInvoiceId');

  // invoices for last 90 days
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 90);
  startDate.setHours(0, 0, 0, 0);

  const dateRange = useMemo(() => {
    return {
      start: startDate.toISOString(),
      end: new Date().toISOString(),
    };
  }, [startDate.toISOString()]);

  const { invoices: invoicesInRange, refetch: refetchInvoicesInRange } = useUserInvoicesInRange({
    personId: props.person?.PersonID || '',
    dateRange,
  });

  const { refetchInvoices } = usePersonActiveInvoiceList(props.person?.PersonID);

  const refetchInvoicesCallback = useCallback(() => {
    setTimeout(() => {
      refetchInvoices();
      refetchInvoicesInRange();
    }, PAYMENT_SUCCESS_TIMEOUT);
  }, [refetchInvoices, refetchInvoicesInRange]);

  return showMultiProcessorCollect ? (
    <PaymentsPanelMultiDataLayer
      locationId={locationId}
      invoiceId={selectedInvoiceId}
      invoicesInRange={invoicesInRange}
      refetchInvoicesCallback={refetchInvoicesCallback}
      {...props}
    />
  ) : (
    <PaymentsPanelLegacyDataLayer
      locationId={locationId}
      invoiceId={selectedInvoiceId}
      invoicesInRange={invoicesInRange}
      refetchInvoicesCallback={refetchInvoicesCallback}
      {...props}
    />
  );
}

function PaymentsPanelMultiDataLayer({
  person,
  locationId,
  invoiceId,
  trackingId,
  invoicesInRange,
  refetchInvoicesCallback,
  ...rest
}: CollectModalDataLayerProps) {
  const { triggerProps: paymentTriggerProps, modalProps, closeModal: closePaymentModal } = useModalControl();

  const collectProps = useCollectPaymentMulti(
    {
      personId: person?.PersonID,
      locationId,
      invoiceId,
      trackingId: trackingId,
      onPaymentSuccess: refetchInvoicesCallback,
      onInvoiceCreated: refetchInvoicesCallback,
    },
    {
      trackingId: trackingId,
      onCancel: () => {
        closePaymentModal();
      },
      onComplete: () => {
        closePaymentModal();
      },
    }
  );

  const { disableCloseOnEscape, disableCloseOnOverlayClick } = useSyncCollectPaymentMultiToModal({
    activeStep: collectProps.multiStep.activeStep,
    setShowCancelAction: collectProps.multiStep.setShowCancelAction,
  });

  const data = {
    paymentTriggerProps,
    PaymentModal: (
      <>
        <CollectPaymentMultiModal
          {...modalProps}
          disableCloseOnEscape={disableCloseOnEscape}
          disableCloseOnOverlayClick={disableCloseOnOverlayClick}
        >
          <CollectPaymentMulti {...collectProps} />
        </CollectPaymentMultiModal>
        <CollectPaymentMultiPortal multiControl={collectProps} />
      </>
    ),
    goToCreateStep: () => {
      collectProps.setFlowAndStep('create', PAYMENT_MULTI_STEPS.create);
    },
    goToPaymentMethodsStep: () => {
      collectProps.setFlowAndStep('pay', PAYMENT_MULTI_STEPS.paymentMethods);
    },
    goToShareInMessageStep: () => {
      collectProps.setFlowAndStep('pay', PAYMENT_MULTI_STEPS.shareInMessage);
    },
  };

  return (
    <PaymentsPanelUI
      trackingId={trackingId}
      invoicesInRange={invoicesInRange}
      refetchInvoicesCallback={refetchInvoicesCallback}
      person={person}
      {...rest}
      {...data}
    />
  );
}

function PaymentsPanelLegacyDataLayer({
  person,
  locationId,
  trackingId,
  invoicesInRange,
  refetchInvoicesCallback,
  ...rest
}: CollectModalDataLayerProps) {
  const {
    triggerProps: paymentTriggerProps,
    Modal: PaymentModal,
    setInitialStep,
  } = usePaymentAction({
    context: {
      person,
      personId: person?.PersonID || '',
      locationId,
    },
    onPaymentSuccess: refetchInvoicesCallback,
    onInvoiceCreated: refetchInvoicesCallback,
  });

  const data = {
    paymentTriggerProps,
    PaymentModal,
    goToCreateStep: () => setInitialStep(PersonInvoiceModalSteps.CreateInvoice),
    goToPaymentMethodsStep: () => setInitialStep(CollectPaymentModalSteps.PaymentFlowList),
    goToShareInMessageStep: () => setInitialStep(CollectPaymentModalSteps.ShareInMessage),
  };

  return (
    <PaymentsPanelUI
      trackingId={trackingId}
      invoicesInRange={invoicesInRange}
      refetchInvoicesCallback={refetchInvoicesCallback}
      person={person}
      {...data}
      {...rest}
    />
  );
}

export function PaymentsPanelUI({
  person,
  isActive = true,
  trackingId,
  paymentTriggerProps,
  PaymentModal,
  invoicesInRange,
  refetchInvoicesCallback,
  goToCreateStep,
  goToPaymentMethodsStep,
  goToShareInMessageStep,
  ...rest
}: PaymentsPanelProps & CollectModalUiProps) {
  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const {
    data: paymentMethods,
    isLoading: isLoadingPaymentMethods,
    refetch: refetchPaymentMethods,
  } = PaymentPlanQueries.useGetPaymentMethodsByPerson({
    paymentsUrl,
    locationId,
    patientId: person?.PersonID || '',
  });

  const { modalProps: editBillingModalProps, triggerProps: editBillingTriggerProps } = useModalControl();
  const { modalProps: deleteRequestModalProps, triggerProps: deleteRequestTriggerProps } = useModalControl({
    onClose: () => {
      selectedInvoiceToDelete.current = null;
    },
  });

  const { setFilter, setSelectedInvoiceId, setFilterDisplayDates } = useInvoiceShallowStore(
    'setFilter',
    'setSelectedInvoiceId',
    'selectedInvoiceId',
    'setFilterDisplayDates'
  );
  const navigate = useNavigate();

  const { t } = useTranslation('payments');

  const [selectedOrder, setSelectedOrder] = React.useState<SortOptions>('newest');

  const orderParam = useMemo(() => {
    const map: Record<SortOptions, InvoiceSearchParams['order']> = {
      newest: '-billedAt',
      oldest: 'billedAt',
      highest: '-billedAmount',
      lowest: 'billedAmount',
    };

    return map[selectedOrder];
  }, [selectedOrder]);

  const { invoices: activeInvoices } = usePersonActiveInvoiceList(person?.PersonID);

  const { cancelInvoice } = useCancelInvoiceMutation({
    paymentsUrl,
    locationId,
    onSuccess: () => {
      refetchInvoicesCallback();
    },
  });
  const selectedInvoiceToDelete = useRef<InvoiceModel | null>(null);

  const sortedActiveInvoices = useMemo(() => {
    if (!activeInvoices) {
      return [];
    }
    /**
     * hard coded sort order. No need to re-sort.
     */
    if (orderParam === '-billedAt') {
      return activeInvoices;
    }

    return activeInvoices?.sort((a, b) => {
      if (orderParam === 'billedAt') {
        return new Date(a.billedAt).getTime() - new Date(b.billedAt).getTime();
      }

      if (orderParam === '-billedAmount') {
        return b.billedAmount - a.billedAmount;
      }

      return a.billedAmount - b.billedAmount;
    });
  }, [activeInvoices, orderParam]);

  const balances = useMemo(() => {
    return invoicesInRange.reduce(
      (acc, invoice) => {
        if (invoice.status === InvoiceStatus.Paid || invoice.status === InvoiceStatus.PartiallyPaid) {
          return { ...acc, paid: acc.paid + invoice.billedAmount };
        }
        return { ...acc, outstanding: acc.outstanding + invoice.billedAmount };
      },
      { paid: 0, outstanding: 0 }
    );
  }, [invoicesInRange]);

  const chartData = useMemo(() => {
    return [
      { key: 'paid', label: t('Paid'), value: balances.paid },
      { key: 'outstanding', label: t('Still Owed'), value: balances.outstanding },
    ] satisfies PaymentsChartData;
  }, [t, balances]);

  const defaultPaymentMethod = useMemo(() => {
    const filteredPaymentMethods = paymentMethods?.filter(
      (pm) =>
        !(
          pm.status &&
          [
            PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFICATION_PENDING,
            PaymentMethodStatus.PAYMENT_METHOD_STATUS_EXPIRED,
          ].includes(pm.status)
        )
    );

    const foundValidDefault = filteredPaymentMethods?.find(
      (pm) => (pm.is_default && pm.type === 'card') || (pm.is_default && pm.type === 'us_bank_account')
    );
    return foundValidDefault || filteredPaymentMethods?.find((pm) => pm.type === 'card');
  }, [paymentMethods]);

  const isLoadingAll = useMemo(() => {
    return isLoadingPaymentMethods || !person;
  }, [person, isLoadingPaymentMethods]);

  const firstName = useMemo(() => {
    return person?.FirstName || '';
  }, [person]);

  const handleSetBillingMethod = async (paymentMethod: PaymentMethod) => {
    await setDefaultPaymentMethod(paymentsUrl, paymentMethod.id, locationId);
    editBillingModalProps.onClose();
    refetchPaymentMethods();
  };

  const getPaymentMethodText = (pm: PaymentMethod) => {
    if (pm.type === 'card') {
      return `${pm.card.brand} ${pm.card.last4}`;
    } else if (pm.type === 'us_bank_account') {
      return `${pm.bankAccount.accountType} ${pm.bankAccount.last4}`;
    }

    return '';
  };

  const onClickCreateInvoice = useCallback(() => {
    goToCreateStep();
    paymentTriggerProps.onClick();
  }, [paymentTriggerProps]);

  const onClickCollectPaymentFromInvoice = useCallback(
    (invoice: InvoiceModel) => {
      setSelectedInvoiceId(invoice.id);
      goToPaymentMethodsStep();
      paymentTriggerProps.onClick();
    },
    [paymentTriggerProps.onClick]
  );

  const onClickRemindFromInvoice = useCallback(
    (invoice: InvoiceModel) => {
      setSelectedInvoiceId(invoice.id);
      goToShareInMessageStep();
      paymentTriggerProps.onClick();
    },
    [paymentTriggerProps.onClick]
  );

  const onClickViewInvoice = useCallback(
    (invoice: InvoiceModel) => {
      navigate({ to: `/payments/invoices/${invoice.id}` });
    },
    [navigate]
  );

  const onClickDeleteInvoice = useCallback(
    (invoice: InvoiceModel) => {
      deleteRequestTriggerProps.onClick();
      selectedInvoiceToDelete.current = invoice;
    },
    [deleteRequestTriggerProps.onClick]
  );

  const handleConfirmDelete = useCallback(() => {
    if (selectedInvoiceToDelete.current) {
      cancelInvoice(selectedInvoiceToDelete.current);
      selectedInvoiceToDelete.current = null;
    }
  }, []);

  const onClickViewHistory = () => {
    setFilter({
      status: [],
      person: `id:${person?.PersonID}`,
    });
    setFilterDisplayDates({ start: undefined, end: undefined });
    navigate({ to: '/payments/invoices?status=allInvoices' });
  };

  const onClickViewPaidHistory = () => {
    setFilter({
      status: [InvoiceFilterStatus.paid, InvoiceFilterStatus.partiallyPaid],
      person: `id:${person?.PersonID}`,
    });
    setFilterDisplayDates({ start: undefined, end: undefined });
    navigate({ to: '/payments/invoices?status=paid' });
  };

  return (
    <>
      <SkeletonLoader isLoading={isLoadingAll}>
        <div {...rest} css={styles.parentWrapper}>
          <PaymentsProfilePromotionWrapper locationId={locationId}>
            <div css={styles.parent}>
              {isActive && (
                <>
                  {/* Only show chart when tab is active, to avoid responsiveness warnings */}
                  <div>
                    {invoicesInRange.length > 0 ? (
                      <>
                        <Heading level={3}>
                          <Text as='span' size='inherit' weight='regular'>
                            {t('Activity:')}
                          </Text>
                          <Text as='span' size='inherit' weight='bold' css={{ paddingLeft: theme.spacing(1) }}>
                            {t('Last {{days}} days', { days: 90 })}
                          </Text>
                        </Heading>
                        <InvoiceSummaryChart data={chartData} />
                      </>
                    ) : (
                      <EmptyInvoiceChart trackingId={trackingId} onClickOverlayBtn={onClickCreateInvoice} />
                    )}
                  </div>
                </>
              )}

              {sortedActiveInvoices.length > 0 && (
                <OutstandingInvoiceCards
                  trackingId={trackingId}
                  altAction={
                    <Button
                      variant='tertiary'
                      size='large'
                      iconName='plus'
                      trackingId={`${trackingId}--request-payment-btn`}
                      onClick={onClickCreateInvoice}
                    >
                      {t('Payment Request')}
                    </Button>
                  }
                  invoices={sortedActiveInvoices}
                  onSelectOrder={setSelectedOrder}
                  selectedOrder={selectedOrder}
                  cardActions={({ invoice }) => (
                    <InvoiceCardActions
                      onClickRemind={onClickRemindFromInvoice}
                      onClickCollect={onClickCollectPaymentFromInvoice}
                      onClickView={onClickViewInvoice}
                      onClickDelete={onClickDeleteInvoice}
                      trackingId={trackingId}
                      invoice={invoice}
                    />
                  )}
                />
              )}
              {defaultPaymentMethod ? (
                <ActionItem
                  prefix={<Icon name='credit-card' size={16} />}
                  suffixText={getPaymentMethodText(defaultPaymentMethod)}
                  trackingId={`${trackingId}--edit-billing`}
                  {...editBillingTriggerProps}
                >
                  {t('Card On File')}
                </ActionItem>
              ) : (
                <ActionItem
                  trackingId={`${trackingId}--add-billing`}
                  prefix={<Icon name='credit-card' size={16} />}
                  suffixText='-'
                  {...editBillingTriggerProps}
                >
                  {t('Add Card On File')}
                </ActionItem>
              )}
              <ActionItem
                onClick={onClickViewHistory}
                prefix={<Icon name='history' size={16} />}
                suffixIcon={<Icon name='caret-right' size={16} />}
                trackingId={`${trackingId}--view-payment-history`}
              >
                {firstName ? t(`{{firstName}}'s Payment Request History`, { firstName }) : t('Payment Request History')}
              </ActionItem>

              <ActionItem
                onClick={onClickViewPaidHistory}
                prefix={<Icon name='history' size={16} />}
                suffixIcon={<Icon name='caret-right' size={16} />}
                trackingId={`${trackingId}--view-paid-history`}
              >
                {firstName ? t(`{{firstName}}'s Paid History`, { firstName }) : t('Paid History')}
              </ActionItem>
            </div>
          </PaymentsProfilePromotionWrapper>
        </div>
      </SkeletonLoader>
      <Modal {...editBillingModalProps} minWidth={600} maxWidth={600}>
        <BillingMethodModal
          onClose={editBillingModalProps.onClose}
          patientId={person?.PersonID}
          patientEmail={person?.Email || ''}
          handleSetBillingMethod={handleSetBillingMethod}
        />
      </Modal>
      <ConfirmationModal
        {...deleteRequestModalProps}
        destructive
        title={t('Delete Payment Request')}
        message={t('This cannot be undone. Are you sure you want to delete this request?')}
        onConfirm={handleConfirmDelete}
      />
      {PaymentModal}
    </>
  );
}
