import { ScheduleQueryInvalidators } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { useScheduleRequestNotification } from '@frontend/notifications';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useWebsocketScheduleRequestEventSubscription = () => {
  const { create } = useScheduleRequestNotification();
  const { t } = useTranslation('notifications');
  const { invalidateScheduleRequestListAndCount } =
    ScheduleQueryInvalidators.useScheduleRequestListAndCountInvalidation();

  return useWebsocketEventSubscription('NotificationAPI', (payload) => {
    const notification = {
      id: payload.id,
      timestamp: Date.now(),
      type: 'schedule-request-new',
      payload: {
        notificationType: payload.params.notificationType,
        appointmentType: payload.params.appointmentType,
        dateTime: payload.params.dateTime,
        id: payload.params.id,
        durationMinutes: payload.params.durationMinutes,
        note: payload.params.note,
        schedulee: payload.params.schedulee,
      },
      state: {
        paused: false,
        status: 'unread',
      },
    } satisfies GetWeavePopNotificationByType<'schedule-request-new'>;

    create(notification, {
      os: {
        title: t('New Schedule Request'),
        body: `${payload.params.from}`,
      },
    });

    invalidateScheduleRequestListAndCount();
  });
};
