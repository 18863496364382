import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';

const styles = {
  wrapper: (isHovered: boolean, disable: boolean, active: boolean) => css`
    display: flex;
    align-items: center;
    cursor: ${disable ? 'not-allowed' : 'pointer'};
    width: 100%;
    ${isHovered &&
    `
      background-color: ${!active && !disable ? theme.colors.neutral10 : 'transparent'};
      border-radius: ${theme.borderRadius.small};
      border-bottom: ${!active ? `1px solid ${theme.colors.neutral20}` : 'none'};
    `}
  `,
  contentWrapper: (isHovered: boolean, active: boolean) => css`
    border-bottom: ${!active && !isHovered ? `1px solid ${theme.colors.neutral20}` : 'none'};
    padding: ${!active ? theme.spacing(2, 0) : theme.spacing(1)};
    flex: 1;
  `,
  content: css`
    padding-left: ${theme.spacing(1)};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};

export const ClearBtnAriaLabelSuffix = 'clear-btn';
type RowProps = {
  children: React.ReactNode;
  label: string;
  optional: boolean;
  onClick: () => void;
  active: boolean;
  onClear?: () => void;
  onBlur?: () => void;
  disable?: boolean;
};
export const Row = ({ children, label, optional, onClick, active, onClear, disable }: RowProps) => {
  const { t } = useTranslation('payments');
  const [isHovered, setIsHovered] = useState(false);
  const textColor = disable ? 'disabled' : isHovered ? 'light' : 'subdued';

  return (
    <div
      css={styles.wrapper(isHovered, !!disable, active)}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={active || disable ? undefined : onClick}
    >
      <div css={styles.contentWrapper(isHovered, active)}>
        {active && !disable ? (
          children
        ) : (
          <div css={styles.content}>
            <Icon name='plus' color={textColor} size={16} />
            <Text color={textColor} weight='bold'>
              {label}
            </Text>
            {optional && (
              <Text color={textColor} size='small' css={{ fontStyle: 'italic' }}>
                {t('(Optional)')}
              </Text>
            )}
          </div>
        )}
      </div>
      <Button
        iconName='x-small'
        variant='secondary'
        aria-label={`${label}-${ClearBtnAriaLabelSuffix}`}
        css={{
          visibility: active && !disable && onClear ? 'visible' : 'hidden',
        }}
        onClick={onClear}
      />
    </div>
  );
};
