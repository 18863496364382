import { useEffect } from 'react';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';

export const NoPaymentsHookStripe = () => {
  const {
    anyLocationHasPayments,
    multiStep: { onCancel },
    stripeData: { stripeCreatedNotOnboarded },
    paymentSetupModalControl: { openModal },
    onLocationHasNoPayments,
  } = useCollectPaymentMultiContext();

  const { navigate } = useSettingsNavigate();

  useEffect(() => {
    if (!anyLocationHasPayments) {
      if (typeof onLocationHasNoPayments === 'function') {
        onLocationHasNoPayments();
      } else {
        if (!stripeCreatedNotOnboarded) {
          navigate({ to: '/payments/account-info' });
        } else {
          openModal();
        }
      }

      onCancel?.();
    }
  }, [anyLocationHasPayments, stripeCreatedNotOnboarded]);

  return <></>;
};
