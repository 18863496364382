import { useEffect, useRef } from 'react';
import { TeamChatSubscriptions } from '@frontend/api-team-chat';
import { useTeamChatApi } from '../team-chat-api-providers/use-team-chat-api';
import { useTeamChatSelector } from '../team-chat.provider';

type ClientSubscriptionHandler = Parameters<typeof TeamChatSubscriptions.subscribeToClient>[1];

const useClientSubscriptions = () => {
  const api = useTeamChatSelector((ctx) => ctx.api);
  const user = useTeamChatSelector((ctx) => ctx.user);
  const isAsleep = useTeamChatSelector((ctx) => ctx.isAsleep);
  const cache = useTeamChatSelector((ctx) => ctx.cache);
  const refetchConversations = useTeamChatSelector((ctx) => ctx.refetchConversations);
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const { subscribeToClient } = useTeamChatApi(api);

  //using stable refs to avoid unsub/resubscribing on every change
  const refs = {
    cache: useStableRef(cache),
    helpers: useStableRef(helpers),
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    if (isAsleep) {
      return;
    }

    const handleClientEvents: ClientSubscriptionHandler = (event, payload) => {
      if (!user) {
        return;
      }

      switch (event) {
        case 'connection.changed': // when the state of the connection changed - local event - client event
        case 'notification.added_to_channel': // when the client receives a new message for a channel that it doesn't know about yet
        case 'notification.removed_from_channel': // when a user is removed from the list of channel members - clients from the user removed that are not watching the channel
        case 'notification.channel_deleted': // when a channel is deleted - clients from members that are not watching the channel
          refetchConversations();
          break;
        case 'notification.message_new': // when the client receives a new message for a channel that it doesn't know about yet
          if (payload.conversation?.channelId) {
            const conversation = refs.helpers.current.getConversation(payload.conversation?.channelId);
            if (!conversation) {
              refetchConversations();
            }
          }
          break;
        case 'user.updated': {
          // when a user is updated - clients subscribed to the user status - user presence event
          if (payload.user && payload.user.userID === user?.userID) {
            refs.cache.current.updateCurrentUser({
              ...user,
              firstName: payload.user.firstName,
              lastName: payload.user.lastName,
              status: payload.user.status,
            });
          }
          //note: this is not an else-if because we want to update the cache in both places if it's the current user
          if (payload.user) {
            refs.cache.current.updateUser(payload.user.userID, payload.user);
          }
          break;
        }
        case 'connection.recovered': // when the connection to chat servers is back online - local event - client event
        case 'health.check': // every 30 second to confirm that the client connection is still alive - all clients - client event
        case 'user.banned': // when the user is banned - clients for the banned user - client event
        case 'user.unbanned': // when the user ban is lifted - clients for the banned user - client event
        case 'user.deleted': // when a user is deleted - clients subscribed to the user status - user presence event
        case 'user.presence.changed': // when a user status changes (eg. online, offline, away, etc.) - clients subscribed to the user status - user presence event
          // case 'notification.channel_mutes_updated': // when a channel is muted - clients from the user that muted the channel
          // case 'notification.channel_truncated': // when a channels’ history is truncated - clients from members that are not watching the channel
          // case 'notification.invite_accepted': // when the user accepts an invite - clients from the user invited that are not watching the channel
          // case 'notification.invite_rejected': // when the user rejects an invite - clients from the user invited that are not watching the channel
          // case 'notification.invited': // when the user is invited to join a channel - clients from the user invited that are not watching the channel
          // case 'notification.mark_read': // when the total count of unread messages (across all channels the user is a member) changes - clients from the user with the new unread count
          // case 'notification.mutes_updated': // when the user mutes are updated - clients from the user that updated the list of mutes
          break;
        default:
          break;
      }
    };

    const unsubscribe = subscribeToClient(handleClientEvents);
    return () => {
      unsubscribe();
    };
  }, [isAsleep, user]);
};

const useStableRef = <T extends any>(param: T) => {
  const ref = useRef<T>(param);
  useEffect(() => {
    ref.current = param;
  }, [param]);
  return ref;
};

export const _useClientSubscriptions = useClientSubscriptions;
