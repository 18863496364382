import { Session } from 'sip.js';
import { SessionManager } from 'sip.js/lib/platform/web';

export const getSessionReceiversMediaStream = (session: Session) => {
  const stream = new MediaStream();
  getReceiverTracks(session).forEach((track) => {
    stream.addTrack(track);
  });
  return stream;
};

export const getCombinedSessionReceiversMediaStream = (sessions: Session[]) => {
  const stream = new MediaStream();
  sessions.flatMap(getReceiverTracks).forEach((track) => stream.addTrack(track));
  return stream;
};

export const replaceSenderTracks = (sessions: Session[], stream: MediaStream) => {
  const tracks = stream.getTracks();
  const mainTrack = tracks[0]; //the first track is the relevant track here
  sessions.forEach((session) => {
    const senders = getSenders(session);
    senders.forEach((sender) => {
      sender.replaceTrack(mainTrack);
    });
  });
};

export const getMergedStream = (sessions: Session[]) => {
  const context = new AudioContext();
  const stream = new MediaStream();
  const allReceiverTracks = sessions.flatMap(getReceiverTracks);
  sessions.forEach(function (session) {
    const mixedOutput = context.createMediaStreamDestination();
    getReceivers(session).forEach(function (receiver) {
      allReceiverTracks.forEach(function (track) {
        stream.addTrack(receiver.track);
        if (receiver.track.id !== track.id) {
          context.createMediaStreamSource(new MediaStream([track])).connect(mixedOutput);
        }
      });
    });

    //mixing your voice with all the received audio
    getSenders(session).forEach(function (sender) {
      if (sender.track) {
        context.createMediaStreamSource(new MediaStream([sender.track])).connect(mixedOutput);
      }
    });

    //replace your sender track with the mixed track
    getSenders(session)[0].replaceTrack(mixedOutput.stream.getTracks()[0]);
  });

  return stream;
};

export const getReceivers = (session: Session) => {
  /* eslint-disable */
  //@ts-ignore -- .peerConnection is a thing!
  const connection = session.sessionDescriptionHandler?.peerConnection as RTCPeerConnection;
  return connection?.getReceivers() ?? [];
};

export const getSenders = (session: Session) => {
  /* eslint-disable */
  //@ts-ignore -- .peerConnection really is a thing!
  const connection = session.sessionDescriptionHandler?.peerConnection as RTCPeerConnection;
  return connection?.getSenders() ?? [];
};

export const getReceiverTracks = (session: Session) =>
  getReceivers(session)
    .map((receiver) => receiver.track)
    .filter((track): track is MediaStreamTrack => !!track);

export const getSenderTracks = (session: Session) =>
  getSenders(session)
    .map((receiver) => receiver.track)
    .filter((track): track is MediaStreamTrack => !!track);

export const toggleReceiverTracks = (session: Session, enabled: boolean) => {
  getReceiverTracks(session).forEach((track) => (track.enabled = enabled));
};

export const toggleSenderTracks = (session: Session, enabled: boolean) => {
  getSenderTracks(session).forEach((track) => (track.enabled = enabled));
};

export const toggleLocalSenderTracks = (client: SessionManager, session: Session, enabled: boolean) => {
  client
    .getLocalMediaStream(session)
    ?.getTracks()
    .forEach((track) => (track.enabled = enabled));
};
