import { memo, useEffect, useMemo, useState } from 'react';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/appointment_type.pb';
import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import dayjs from 'dayjs';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { contextFactory, useAlert } from '@frontend/design-system';
import {
  useGetAppointmentTypesV3Data,
  useGetCalendarEventsV3Data,
  useGetIntegrationDetails,
  useSchedulingLocationInfo,
  useGetPractitionersV3Data,
  useGetV3CalendarStartAndEndDateTime,
  GetCalendarEventsRequest,
} from '../hooks';
import { useCalendarViewV3HeaderFilterStore } from '../stores/use-calendar-view-v3-header-filter-store';
import { useIntegrationDetailsShallowStore } from '../stores/use-integration-details';
import { calendarViewV3ApiQueryLimit } from '../utils';

type CalendarEventsV3ContextType = {
  selectedMultiLocationIds: string[];
  calendarEvents: CalendarEvent[];
  practitioners: Practitioner[];
  scheduleEntries: Schedule[];
  scheduleEntriesOfficeData: Schedule[];
  appointmentTypes: AppointmentType[];
  calendarEventsParams: GetCalendarEventsRequest;
  calendarEventsTotalCount?: number;
  hasSelectedMultiLocationIds: boolean;
  isIntegratedOffice: boolean;
  isLoading: boolean;
  setCalendarEventsParams: (params: GetCalendarEventsRequest) => void;
  setSelectedMultiLocationIds: (locationIds: string[]) => void;
  refetchCalendarEvents: () => void;
  refetchPractitionerData: () => void;
  refetchAppointmentTypesData: () => void;
  setHasSelectedMultiLocationIds: (hasSelectedMultiLocationIds: boolean) => void;
};

export const [CalendarEventV3Context, useCalendarEventV3Context] = contextFactory<CalendarEventsV3ContextType>(
  'useCalendarEventV3Context must be used within a CalendarEventV3Context'
);

type CalendarEventsV3ProviderProps = {
  children: React.ReactNode;
};
export const CalendarEventsV3Provider = memo(({ children }: CalendarEventsV3ProviderProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const { selectedLocationIds } = useSchedulingLocationInfo();

  const [selectedMultiLocationIds, setSelectedMultiLocationIds] = useState<string[]>(selectedLocationIds ?? []);
  const [hasSelectedMultiLocationIds, setHasSelectedMultiLocationIds] = useState<boolean>(false);

  const { startDateTime, endDateTime } = useGetV3CalendarStartAndEndDateTime(dayjs().toString());

  const [calendarEventsParams, setCalendarEventsParams] = useState<GetCalendarEventsRequest>({
    startDateTime,
    endDateTime,
    page: 1,
    limit: calendarViewV3ApiQueryLimit,
  });

  const { setIntegrationDetails, setIsIntegratedOffice } = useIntegrationDetailsShallowStore(
    'setIntegrationDetails',
    'setIsIntegratedOffice'
  );

  const {
    isIntegratedOffice,
    isLoading: isLoadingIntegrationDetails,
    data: integrationDetails,
  } = useGetIntegrationDetails({
    selectedLocationId: selectedLocationIds[0],
  });

  const {
    data: calendarEventsData,
    isLoading: isCalendarEventsLoading,
    refetch: refetchCalendarEvents,
  } = useGetCalendarEventsV3Data({
    request: calendarEventsParams,
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedMultiLocationIds,
  });

  const {
    data: practitionerData,
    isLoading: isLoadingPractitionerData,
    refetch: refetchPractitionerData,
  } = useGetPractitionersV3Data({
    showHiddenOnCalendar: false,
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedMultiLocationIds,
    pageConfig: {
      page: 1,
      limit: calendarViewV3ApiQueryLimit,
    },
  });

  const practitionerIds = useMemo(
    () => (practitionerData?.providers ?? []).map((provider) => provider.id),
    [practitionerData?.providers]
  );

  const listProviderScheduleQueries = SchedulerV3.Queries.useListSchedulesQueries(
    selectedLocationIds.map((locationId) => ({
      request: {
        locationId,
        ids: practitionerIds,
      },
    }))
  );

  const allProviderSchedules = useMemo(
    () => ({
      data: listProviderScheduleQueries.map((providerSchedule) => providerSchedule.data?.schedules || []).flat(),
      isLoading: listProviderScheduleQueries.some((providerSchedule) => providerSchedule.isLoading),
      isError: listProviderScheduleQueries.some((providerSchedule) => providerSchedule.isError),
    }),
    [listProviderScheduleQueries]
  );

  const listOfficeScheduleQueries = SchedulerV3.Queries.useListSchedulesQueries(
    selectedLocationIds.map((locationId) => ({
      request: {
        locationId,
        ids: selectedLocationIds,
      },
    }))
  );

  const allOfficeSchedules = useMemo(
    () => ({
      data: listOfficeScheduleQueries.map((officeSchedule) => officeSchedule.data?.schedules || []).flat(),
      isLoading: listOfficeScheduleQueries.some((officeSchedule) => officeSchedule.isLoading),
      isError: listOfficeScheduleQueries.some((officeSchedule) => officeSchedule.isError),
    }),
    [listOfficeScheduleQueries]
  );

  const {
    data: appointmentTypesData,
    isLoading: isLoadingAppointmentTypesData,
    refetch: refetchAppointmentTypesData,
  } = useGetAppointmentTypesV3Data({
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedMultiLocationIds,
    pageConfig: {
      page: 1,
      limit: calendarViewV3ApiQueryLimit,
    },
  });

  // useEffect to reset filters when location is changed
  useEffect(() => {
    if (selectedLocationIds.length) {
      useCalendarViewV3HeaderFilterStore.reset();
    }
  }, [selectedLocationIds]);

  useEffect(() => {
    if (!isLoadingIntegrationDetails && integrationDetails) {
      setIntegrationDetails(integrationDetails);
      setIsIntegratedOffice(isIntegratedOffice);
    }
  }, [integrationDetails, isIntegratedOffice, isLoadingIntegrationDetails]);

  // Handle error while fetching provider schedules
  useEffect(() => {
    if (allProviderSchedules.isError) {
      alert.error(t('Error fetching provider schedules'));
    }
  }, [allProviderSchedules.isError]);

  // Handle error while fetching office schedules
  useEffect(() => {
    if (allOfficeSchedules.isError) {
      alert.error(t('Error fetching office schedules'));
    }
  }, [allOfficeSchedules.isError]);

  const isLoading =
    isCalendarEventsLoading ||
    isLoadingPractitionerData ||
    allProviderSchedules.isLoading ||
    allOfficeSchedules.isLoading ||
    isLoadingAppointmentTypesData ||
    isLoadingIntegrationDetails;

  const calendarEventV3ContextValue = useMemo<CalendarEventsV3ContextType>(() => {
    return {
      calendarEvents: calendarEventsData?.events || [],
      practitioners: practitionerData?.providers || [],
      scheduleEntries: allProviderSchedules.data,
      scheduleEntriesOfficeData: allOfficeSchedules.data || [],
      appointmentTypes: appointmentTypesData?.appointmentTypes || [],
      calendarEventsTotalCount: calendarEventsData?.totalCount || 0,
      calendarEventsParams,
      selectedMultiLocationIds,
      hasSelectedMultiLocationIds,
      isIntegratedOffice,
      isLoading,
      setHasSelectedMultiLocationIds,
      setSelectedMultiLocationIds,
      refetchCalendarEvents,
      refetchPractitionerData,
      refetchAppointmentTypesData,
      setCalendarEventsParams,
    };
  }, [
    calendarEventsData?.events,
    calendarEventsData?.totalCount,
    practitionerData?.providers,
    appointmentTypesData?.appointmentTypes,
    allProviderSchedules.data,
    allOfficeSchedules.data,
    calendarEventsParams,
    selectedMultiLocationIds,
    isIntegratedOffice,
    hasSelectedMultiLocationIds,
    isLoading,
  ]);

  return (
    <CalendarEventV3Context.Provider value={calendarEventV3ContextValue}>{children}</CalendarEventV3Context.Provider>
  );
});

CalendarEventsV3Provider.displayName = 'CalendarEventsV3Provider';
