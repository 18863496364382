import { REGISTER_CLICK_EVENT_NAME, TRIGGER_SEARCH_EVENT_NAME, UPDATE_SEARCH_EVENT_NAME } from './constants';
import { RegisterClickPayload, TrigggerSearchPayload, UpdateSearchPayload } from './types';

export const handleRegisterClick = (payload: RegisterClickPayload) => {
  const registerClickEvent = new CustomEvent<RegisterClickPayload>(REGISTER_CLICK_EVENT_NAME, {
    detail: payload,
  });
  window.dispatchEvent(registerClickEvent);
};

export const handleTriggerSearch = (payload: TrigggerSearchPayload) => {
  const triggerSearchEvent = new CustomEvent<TrigggerSearchPayload>(TRIGGER_SEARCH_EVENT_NAME, { detail: payload });
  window.dispatchEvent(triggerSearchEvent);
};

export const handleUpdateSearch = (payload: UpdateSearchPayload) => {
  const updateSearchEvent = new CustomEvent<UpdateSearchPayload>(UPDATE_SEARCH_EVENT_NAME, {
    detail: payload,
  });
  window.dispatchEvent(updateSearchEvent);
};
