import { css } from '@emotion/react';
import { Direction } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { BulkMessagesQueries, MessagesHooks } from '@frontend/api-messaging';
import { AllLocationsAllotment } from '@frontend/all-locations-allotment';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScopedAppFlagStore } from '@frontend/scope';
import { SegmentedBar, SegmentedBarTypes } from '@frontend/segmented-bar';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, Text, useModalControl } from '@frontend/design-system';
import { flags } from '../../constants';
import { getMonthFromNumber } from './utils';

type ContainerSize = 'large' | 'small';

type Props = {
  month?: number;
  quota?: number;
  sent?: number;
  pending?: number;
  additional?: number;
  size?: ContainerSize;
  year?: number;
};

export const BulkMessageGraph = ({ month, quota = 0, sent, pending, additional, size = 'large', year }: Props) => {
  const { t } = useTranslation('messages');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const bulkMessageFlagEnabled = getFeatureFlagValue(flags.emailMarketingV2);
  const isLarge = size === 'large';

  const used = (sent ?? 0) + (pending ?? 0) + (additional ?? 0);

  const data: SegmentedBarTypes.Data[] = [];

  if (sent !== undefined) {
    data.push({
      label: t('Sent Messages'),
      data: sent,
      color: theme.colors.primary70,
      hoverLabel: t('{{messageCount}} Sent', { messageCount: sent }),
    });
  }

  if (pending !== undefined) {
    data.push({
      label: t('Scheduled Messages'),
      data: pending,
      color: theme.colors.secondary.seaweed20,
      hoverLabel: t('{{messageCount}} Scheduled', { messageCount: pending }),
    });
  }

  if (additional !== undefined) {
    const availableAdditional = used > quota ? quota - (sent ?? 0) - (pending ?? 0) : additional;
    data.push({
      label: t('Messages Used by This Send'),
      data: availableAdditional,
      color: theme.colors.secondary.eggplant40,
      hoverLabel: t('{{messageCount}} Used by This Send', { messageCount: availableAdditional }),
    });
  }

  data.push({
    label: t('Remaining Messages'),
    data: quota - used,
    color: theme.colors.neutral10,
    hoverLabel: t('{{messageCount}} Available', { messageCount: quota - used }),
    isRemaining: true,
  });

  const { modalProps, triggerProps } = useModalControl();
  const bulkQueryKeys = MessagesHooks.useBulkQueryKeys();
  const textCampaignData = BulkMessagesQueries.useGetBatchesForSelectedLocations(year, month, Direction.DESCENDING, {
    queryKey: bulkQueryKeys.batches(Direction.DESCENDING, year, month),
    enabled: modalProps.show,
  });

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <Heading level={2} data-testid={`usage-month-${getMonthFromNumber(month)}`}>
          {getMonthFromNumber(month)}
        </Heading>
        <div
          css={css`
            display: flex;
            align-items: center;
            column-gap: ${theme.spacing(1)};
          `}
        >
          <Text weight='semibold' data-testid={`usage-text-${getMonthFromNumber(month)}`}>
            {t('{{usedMessageCount}} / {{maxMessageCount}} Messages Used', {
              usedMessageCount: used > quota ? quota : used,
              maxMessageCount: quota,
            })}
          </Text>
          {bulkMessageFlagEnabled && (
            <NakedButton {...triggerProps}>
              <Icon name='locations-small' />
            </NakedButton>
          )}
        </div>
      </div>
      <SegmentedBar
        items={data}
        css={
          isLarge &&
          css`
            margin-bottom: ${theme.spacing(5)};
          `
        }
      >
        <SegmentedBar.Bar barStyles={barStyles(isLarge)} max={quota} />
        {isLarge && <SegmentedBar.Legend />}
      </SegmentedBar>
      {modalProps.show && <AllLocationsAllotment data={textCampaignData} modalProps={modalProps} month={month} />}
    </>
  );
};

const barStyles = (isLarge: boolean) =>
  isLarge
    ? css`
        height: ${theme.spacing(6)};
        background: ${theme.colors.neutral10};
        border-radius: ${theme.borderRadius.small};
        margin-bottom: ${theme.spacing(1.5)};
      `
    : css`
        margin: ${theme.spacing(0.5, 0, 1)};
      `;
