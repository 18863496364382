import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { isEmail } from 'validator';
import { UpdateMerchant } from '@frontend/api-payments';
import { i18next, useTranslation } from '@frontend/i18n';
import { getMixedMerchantValues, MIXED, MixedType, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  ContentLoader,
  EmailField,
  FormFieldActionTypes,
  FormRow,
  PhoneField,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  TextField,
  ValidatorFieldState,
  ValidatorFn,
  digitsOnly,
  useForm,
  useFormField,
} from '@frontend/design-system';
import { useCanDoAction, useFeatureFlag, usePrevious, useUpdateMerchant } from '../../../hooks';
import * as settingsStyle from '../styles';
import { ImageFile, MerchantLogo } from './merchant-logo';

const fieldNames = {
  name: 'businessName',
  email: 'email',
  phone: 'phone',
  countryCode: 'countryCode',
  receiptEmail: 'receiptEmail',
  statementDescriptor: 'statementDescriptor',
} as const;

const MIXED_PLACEHOLDER = 'Mixed';

const styles = {
  ...settingsStyle,
  italic: (value: string) =>
    css`
      ${value === MIXED_PLACEHOLDER && 'input { font-style: italic; }'}
    `,
  buttonBar: css`
    padding: 0px;
  `,
  phoneNumberField: css`
    flex: 1;
  `,
};

const getInitialValue = <T,>(value: MixedType<T>) => (value === MIXED ? MIXED_PLACEHOLDER : value ?? '');
const emailValidator = ({ value }: ValidatorFieldState<'email'>) =>
  value === MIXED_PLACEHOLDER || isEmail(value) ? '' : i18next.t('Invalid email address');
const phoneValidator = ({ value }: ValidatorFieldState<'phone'>) =>
  value === MIXED_PLACEHOLDER || /^\d{10,11}$/.test(digitsOnly(value)) ? '' : i18next.t('Invalid phone number');

const getPhoneNumber = (phoneNumber: string | undefined) => {
  const formattedPhoneNumber = !phoneNumber || phoneNumber === MIXED ? phoneNumber : phoneNumber.replace(/\D*/gi, '');
  const countryCode =
    formattedPhoneNumber && formattedPhoneNumber?.length > 10
      ? '+' + formattedPhoneNumber.slice(0, formattedPhoneNumber.length - 10)
      : '';
  const phoneNumberWithoutCountryCode = formattedPhoneNumber && formattedPhoneNumber.slice(-10);

  return {
    phoneNumber: phoneNumberWithoutCountryCode,
    countryCode,
    hasCountryCode: !!countryCode,
  };
};

const validateDescriptor: ValidatorFn<'text'> = ({ value: descriptor = '' }: ValidatorFieldState<'text'>) => {
  const atLeastOneLetter = /[a-zA-Z]+/.test(descriptor || '');
  const disallowedChars = /[<>"'*\\]/.test(descriptor || '');

  if (descriptor === MIXED_PLACEHOLDER) {
    return '';
  } else if (descriptor.length < 5 || descriptor.length > 22) {
    return i18next.t('Must be between 5-22 characters', { ns: 'payments>>settings' });
  } else if (!atLeastOneLetter) {
    return i18next.t('Must include at least one letter', { ns: 'payments>>settings' });
  } else if (disallowedChars) {
    return i18next.t(`No <, >, \\, ', ", or * characters are allowed`, { ns: 'payments>>settings' });
  } else {
    return '';
  }
};

export const PublicBusinessInformation = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { enabled: isLogoEnabledLocation } = useFeatureFlag('payments_text_to_pay_old_portal');
  const { locationIds } = useMultiQueryUtils();
  const { merchantsData, isLoading } = useMerchant();
  const { isPaymentsBillingManager } = useCanDoAction();

  const merchantValues = getMixedMerchantValues(locationIds, merchantsData);
  const merchantsDataString = JSON.stringify(merchantValues);

  const showLogo = locationIds.length === 1 && isLogoEnabledLocation;
  const [logo, setLogo] = useState<ImageFile | undefined>(undefined);
  const prevLogo = usePrevious(logo);

  const { handleMerchantUpdate, isUpdating } = useUpdateMerchant();
  const { phoneNumber, countryCode, hasCountryCode } = useMemo(
    () => getPhoneNumber(merchantValues?.phoneNumber),
    [merchantsDataString]
  );
  const countryCodeFieldProps = useFormField({ type: 'text', required: false, value: countryCode || '+1' });

  const { formProps, getFieldProps, isComplete, seedValues, validate, changedValues } = useForm({
    computeChangedValues: true,
    fields: {
      [fieldNames.name]: { type: 'text', required: true },
      [fieldNames.email]: { type: 'text', required: true, validator: emailValidator },
      [fieldNames.receiptEmail]: { type: 'text', required: true, validator: emailValidator },
      [fieldNames.phone]: { type: 'text', required: true, validator: phoneValidator },
      [fieldNames.statementDescriptor]: {
        type: 'text',
        required: true,
        validator: validateDescriptor,
      },
    },
    onSubmit: (values) => {
      const finalPhoneNumber = hasCountryCode
        ? `${countryCodeFieldProps.value}${values[fieldNames.phone]}`
        : values[fieldNames.phone];
      const data: UpdateMerchant = {
        ...(values[fieldNames.name] !== MIXED_PLACEHOLDER ? { name: values[fieldNames.name] } : {}),
        ...(values[fieldNames.phone] !== MIXED_PLACEHOLDER ? { phoneNumber: finalPhoneNumber } : {}),
        ...(values[fieldNames.email] !== MIXED_PLACEHOLDER ? { email: values[fieldNames.email] } : {}),
        ...(values[fieldNames.receiptEmail] !== MIXED_PLACEHOLDER
          ? { receiptEmail: values[fieldNames.receiptEmail] }
          : {}),
        ...(values[fieldNames.statementDescriptor] !== MIXED_PLACEHOLDER
          ? { statementDescriptor: values[fieldNames.statementDescriptor] }
          : {}),
      };
      handleMerchantUpdate(data);
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === fieldNames.statementDescriptor) {
        return {
          ...state,
          [fieldNames.statementDescriptor]: {
            ...state[fieldNames.statementDescriptor],
            value:
              locationIds.length > 1 && action.payload.value.toLowerCase() === 'mixed'
                ? MIXED_PLACEHOLDER
                : action.payload.value.toUpperCase(),
          },
        };
      }
      return null;
    },
  });

  const businessNameLabelId = `${getFieldProps(fieldNames.name).id}-label`;
  const statementDescriptorLabelId = `${getFieldProps(fieldNames.statementDescriptor).id}-label`;

  const setInitialValues = useCallback(
    () =>
      seedValues({
        [fieldNames.name]: getInitialValue(merchantValues?.name),
        [fieldNames.email]: getInitialValue(merchantValues?.email),
        [fieldNames.receiptEmail]:
          getInitialValue(merchantValues?.receiptEmail) || getInitialValue(merchantValues?.email),
        [fieldNames.phone]: getInitialValue(phoneNumber),
        [fieldNames.statementDescriptor]: getInitialValue(merchantValues?.statementDescriptor),
      }),
    [phoneNumber, merchantsDataString]
  );

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);

  const resetForm = () => setInitialValues();

  // validate the statement descriptor on changes and when data first loads
  // will help display errors that came from onboarding
  useEffect(() => {
    if (merchantValues?.statementDescriptor) {
      validate();
    }
  }, [merchantValues?.statementDescriptor]);

  const disableFields = isLoading || isUpdating || !isPaymentsBillingManager;

  return (
    <>
      <ContentLoader show={!merchantValues || isLoading} message={t('Loading Merchant Information')} />
      <form {...formProps} css={[styles.form, styles.formMaxWidth]}>
        <Text size='large' weight='bold' as='label' id={businessNameLabelId} css={styles.formGroupLabel}>
          {t('Business Information')}
        </Text>
        <FormRow>
          <TextField
            {...getFieldProps(fieldNames.name)}
            label={t('Business Name')}
            placeholder='e.g. Pied Piper Dental LLC'
            aria-labelledby={businessNameLabelId}
            data-trackingid='pay-portal-settings-txt-bizname'
            disabled={disableFields}
            css={styles.italic(getFieldProps(fieldNames.name).value)}
          />
        </FormRow>
        <FormRow css={styles.formRow}>
          <TextField
            {...getFieldProps(fieldNames.statementDescriptor)}
            label={t('Statement Descriptor')}
            placeholder='e.g. Homeland Dental'
            aria-labelledby={statementDescriptorLabelId}
            data-trackingid='pay-portal-settings-txt-statementdesc'
            disabled={disableFields}
            css={styles.italic(getFieldProps(fieldNames.statementDescriptor).value)}
          />
          <Text
            size='small'
            color='light'
            css={css`
              margin: ${theme.spacing(1, 0, 0, 1)};
            `}
          >
            {t(`This is the business name that will show up on your customers' bank or credit card statements.
          Choose something they will recognize to help prevent disputes. A common website URL is only
          acceptable if it provides a clear and accurate description of a transaction.`)}
          </Text>
        </FormRow>
        <Text size='large' weight='bold' css={styles.formGroupLabel}>
          {t('Notify of Payment Activity')}
        </Text>
        <FormRow css={styles.formRow}>
          <EmailField
            {...getFieldProps(fieldNames.receiptEmail)}
            label={t('Email Address')}
            data-trackingid='pay-portal-settings-notify-email'
            disabled={disableFields}
            css={styles.italic(getFieldProps(fieldNames.receiptEmail).value)}
          />
          <Text
            size='small'
            color='light'
            css={css`
              margin: ${theme.spacing(1, 0, 0, 1)};
            `}
          >
            {t('New transactions and other alerts will be sent here.')}
          </Text>
        </FormRow>
        <div css={styles.formRow}>
          <Text size='large' weight='bold'>
            {t('Contact Information')}
          </Text>
          <Text size='small' color='light'>
            {t('This contact information will be shown to your customers on Weave payments pages.')}
          </Text>
        </div>
        <FormRow>
          <EmailField
            {...getFieldProps(fieldNames.email)}
            label={t('Email Address')}
            data-trackingid='pay-portal-settings-txt-email'
            disabled={disableFields}
            css={styles.italic(getFieldProps(fieldNames.email).value)}
          />
        </FormRow>
        {getFieldProps(fieldNames.phone).value === MIXED_PLACEHOLDER ? (
          <FormRow>
            <TextField
              {...getFieldProps(fieldNames.phone)}
              label={t('Phone Number')}
              data-trackingid='pay-portal-settings-txt-phone'
              disabled={disableFields}
              css={styles.italic(getFieldProps(fieldNames.phone).value)}
            />
          </FormRow>
        ) : (
          <FormRow cols={hasCountryCode ? [40, 60] : [100]}>
            {hasCountryCode && (
              <div>
                <TextField
                  {...countryCodeFieldProps}
                  name='country-code'
                  readOnly
                  label={t('Country Code')}
                  disabled={true}
                  data-trackingid='pay-portal-settings-txt-country-code'
                />
              </div>
            )}
            <div>
              <PhoneField
                {...getFieldProps(fieldNames.phone)}
                label={t('Phone Number')}
                data-trackingid='pay-portal-settings-txt-phone'
                disabled={disableFields}
                css={[styles.italic(getFieldProps(fieldNames.phone).value), styles.phoneNumberField]}
              />
            </div>
          </FormRow>
        )}
        {showLogo && <MerchantLogo logo={logo} setLogo={setLogo} isUpdating={isUpdating} />}
        <ButtonBar css={styles.buttonBar}>
          <SecondaryButton disabled={!(changedValues || prevLogo !== logo) || disableFields} onClick={resetForm}>
            {t('Revert Changes')}
          </SecondaryButton>
          <PrimaryButton
            type='submit'
            disabled={!isComplete || !(changedValues || prevLogo !== logo) || disableFields}
            css={[styles.groupMaxWidth, styles.submitButton]}
            data-trackingid='pay-portal-settings-btn-save'
          >
            {isUpdating ? (
              <>
                {t('Saving Changes')}
                <SpinningLoader
                  size='xs'
                  css={css`
                    margin-left: ${theme.spacing(1)};
                  `}
                />
              </>
            ) : (
              t('Save Changes')
            )}
          </PrimaryButton>
        </ButtonBar>
      </form>
    </>
  );
};
