import { ComponentProps, forwardRef, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { CHAT_HEIGHTS } from '../../constants';
import { useMiniChatShallowStore } from '../../stores';
import { MobileChatSelectionItem } from '../mobile-selection-menu/mobile-chat-selection-item';

type OverflowChatsListProps = { onClose: () => void } & Omit<ComponentProps<typeof motion.div>, 'ref'>;

export const OverflowChatsList = forwardRef<HTMLDivElement, OverflowChatsListProps>(({ onClose, ...rest }, ref) => {
  const { chats, overflowChatCount, openChat, removeChat } = useMiniChatShallowStore(
    'chats',
    'overflowChatCount',
    'openChat',
    'removeChat'
  );
  const [overflowChats, setOverflowChats] = useState(chats.slice(0, overflowChatCount).reverse());

  const handleRemoveChat = (chatId: string) => {
    setOverflowChats((prev) => prev.filter((chat) => chat.chatId !== chatId));
    const shouldClose = chats.length === 1 || overflowChatCount < 3;
    if (shouldClose) onClose();

    // Avoid showing another chat in the list because the count hasn't updated yet
    setTimeout(() => {
      removeChat(chatId);
      setOverflowChats(
        chats
          .slice(0, overflowChatCount)
          .filter((chat) => chat.chatId !== chatId)
          .reverse()
      );
    }, 100);
  };

  return (
    <motion.div
      ref={ref}
      css={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'end',
        justifyContent: 'end',
      }}
      animate={{
        height: `100%`,
        transform: `translateY(0)`,
      }}
      initial={{ height: CHAT_HEIGHTS.minimized, transform: `translateY(10vh)` }}
      exit={{ height: CHAT_HEIGHTS.minimized, transform: `translateY(10vh)` }}
      {...rest}
    >
      <motion.div
        css={{
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(2),
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
          gap: theme.spacing(1),
        }}
        layout='position'
      >
        <AnimatePresence>
          {overflowChats.map(({ unreadCount, personPhone, groupId, chatId }) => (
            <MobileChatSelectionItem
              key={'overflow-chat-selection-item-' + chatId}
              onClick={() => openChat(chatId)}
              onRemove={() => handleRemoveChat(chatId)}
              unreadCount={unreadCount}
              personPhone={personPhone}
              groupId={groupId}
              css={{
                zIndex: theme.zIndex.highest,
                width: 240,
              }}
              exit={{
                opacity: 0,
                transform: 'translateX(125%)',
              }}
              layout='position'
            />
          ))}
        </AnimatePresence>
      </motion.div>
      <NakedButton
        css={{
          padding: theme.spacing(1),
          backgroundColor: theme.colors.white,
          cursor: 'pointer',
          boxShadow: theme.shadows.floating,
          borderRadius: theme.borderRadius.small,
          ':hover, :focus': {
            backgroundColor: theme.colors.neutral5,
          },
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
        onClick={onClose}
      >
        <Icon name='x-small' />
      </NakedButton>
    </motion.div>
  );
});
OverflowChatsList.displayName = 'OverflowChatsList';
