import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { useTranslation } from '@frontend/i18n';
import { ManualTemplateTypes } from '../types';

export const useTemplateTypeMap = (): Record<ManualTemplateTypes, string> => {
  const { t } = useTranslation('integrated-messaging');

  return {
    [TemplateType_Slug.MANUAL_MESSAGE]: t('Manual'),
    [TemplateType_Slug.MANUAL_PAYMENT_REQUEST]: t('Payment'),
    [TemplateType_Slug.MANUAL_REVIEW_REQUEST_PET]: t('Review Request'),
    [TemplateType_Slug.MANUAL_REVIEW_REQUEST]: t('Review Request'),
    [TemplateType_Slug.MANUAL_FORMS_MESSAGE]: t('Form Request'),
    [TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER]: t('Schedule'),
    [TemplateType_Slug.MANUAL_APPOINTMENT_REMINDER_PET]: t('Schedule'),
    [TemplateType_Slug.MANUAL_SCHEDULE_QUICK_FILL]: t('Quick Fill'),
  };
};
