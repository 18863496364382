import { useState, useMemo, useRef, useCallback } from 'react';
import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, Avatar, Text, ContentLoader, useOnClickOutside } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName, type Target } from '../../../utils';
import { CustomCombobox } from '../../common/custom-combobox/custom-combobox';
import { ConversationDropdownMenuItem } from './conversation-dropdown-menu-item';

/*
  This new conversation component manages the new conversation creation.
  Since we display users and groups in the same list, we need to differentiate between them. StreamUser and StreamConversation
  When one is selected we need to hide the other one, this is managed by filterType<'DM'|'Group'|''>.
  This filterType is updated when user types '@' or '#' user selected either a user or a conversation from the 
  dropdown list.
 */

type TargetOption = TeamChatTypes.User | TeamChatTypes.Conversation;
export const isUser = (target: TargetOption): target is TeamChatTypes.User => 'userID' in target;
export const isConversation = (target: TargetOption): target is TeamChatTypes.Conversation => 'channelId' in target;

type Props = {
  selectedTarget: Target | undefined;
  onChange: (target: Target | undefined) => void;
};
export const ConversationMemberSelection = ({ selectedTarget, onChange: onTargetChange }: Props) => {
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const openDynamicConversation = useTeamChatSelector((ctx) => ctx.openDynamicConversation);
  const users = useTeamChatSelector((ctx) => ctx.users);
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const isCreatingConversation = useTeamChatSelector((ctx) => ctx.isCreatingConversation);
  const currentUser = useTeamChatSelector((ctx) => ctx.user);
  const [shouldShowNumberAdornment, setShouldShowNumberAdornment] = useState<boolean>(false);
  const [currentSearchInput, setCurrentSearchInput] = useState('');
  const { t } = useTranslation('team-chat');
  const comboboxRef = useRef(null);
  const isMenuOpenRef = useRef(false);

  useOnClickOutside({
    ref: comboboxRef,
    handler: () => {
      if (isMenuOpenRef.current) {
        return;
      }
      setShouldShowNumberAdornment(true);
    },
  });

  const handleAddTarget = useCallback(
    async (item: TargetOption) => {
      if (!currentUser) {
        return;
      }

      if (isConversation(item)) {
        const conversation = item;
        onTargetChange({ type: 'group', conversation: conversation });
        openDynamicConversation(conversation.channelId);
      } else {
        const user = item;
        const selectedUsers = [...(selectedTarget?.type === 'dm' ? selectedTarget.users : []), user];
        onTargetChange({ type: 'dm', users: selectedUsers });
        const conversation = helpers.getConversationByMemberIds(
          [...selectedUsers.map((member) => member.userID)],
          'dm'
        );
        openDynamicConversation(conversation?.channelId ?? undefined);
      }
    },
    [helpers, onTargetChange, openDynamicConversation, selectedTarget]
  );

  const handleRemoveTarget = useCallback(
    async (item: TargetOption) => {
      if (!currentUser) {
        return;
      }
      if (isConversation(item)) {
        onTargetChange(undefined);
        openDynamicConversation(undefined);
      } else if (isUser(item)) {
        const remainingUsers = (selectedTarget?.type === 'dm' ? selectedTarget.users : []).filter(
          (user) => user.userID !== item.userID
        );
        onTargetChange({ type: 'dm', users: remainingUsers });
        if (remainingUsers.length === 0) {
          openDynamicConversation(undefined);
          onTargetChange(undefined);
        } else {
          const conversation = helpers.getConversationByMemberIds(
            [...remainingUsers.map((member) => member.userID)],
            'dm'
          );
          openDynamicConversation(conversation?.channelId ?? undefined);
        }
      }
    },
    [helpers, onTargetChange, openDynamicConversation, selectedTarget]
  );

  const availableTargets = useMemo(() => {
    if (selectedTarget?.type === 'dm') {
      return users;
    }
    if (selectedTarget?.type === 'group') {
      return conversations?.groups ?? [];
    }

    if (currentSearchInput.startsWith('@')) {
      const input = currentSearchInput.trim().slice(1).toLowerCase().replace('@', '');
      return users?.filter((user) => getUserFullName(user.firstName, user.lastName).toLowerCase().includes(input));
    }
    if (currentSearchInput.startsWith('#')) {
      const input = currentSearchInput.trim().slice(1).toLowerCase().replace('#', '');
      return conversations?.groups?.filter((group) => group.name.toLowerCase().includes(input));
    } else {
      return [...(conversations?.groups ?? []), ...(users ?? [])];
    }
  }, [selectedTarget, users, conversations, currentSearchInput]);

  const RecipientChip = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({ children, onClick }: { children?: string; onClick?: () => void }) => {
        if (selectedTarget?.type === 'group') {
          return (
            <Chip.Removable onClick={() => onClick?.()}>
              <Text> # {children}</Text>
            </Chip.Removable>
          );
        } else {
          //I guess we just show the person chip if it's anything other than a group
          return (
            <Chip.Person
              avatar={
                <Avatar
                  size='xxs'
                  name={children}
                  bypassColor={{ fill: theme.colors.neutral30, text: theme.colors.white }}
                  isUser
                />
              }
              onClick={onClick}
              css={entityChipStyles}
            >
              {children}
            </Chip.Person>
          );
        }
      },
    [selectedTarget?.type]
  );

  const placeholder =
    selectedTarget?.type === 'dm'
      ? t('@someone')
      : selectedTarget?.type === 'group'
      ? t('#a-channel')
      : t('#a-channel or @someone');

  const startAdornment = useMemo(
    () => (
      <span css={startAdornmentStyle}>
        <Text size='large'>{t('To:')}</Text>
        {selectedTarget?.type === 'dm' && selectedTarget.users.length > 2 && shouldShowNumberAdornment && (
          <Text
            size='small'
            weight='bold'
            css={adornmentCountStyle}
            onClick={() => setShouldShowNumberAdornment(false)}
          >
            {selectedTarget.users.length - 2}
          </Text>
        )}
      </span>
    ),
    [selectedTarget?.type, selectedTarget?.type === 'dm' && users?.length, shouldShowNumberAdornment]
  );

  const selectedTargetItems = useMemo(() => {
    if (!selectedTarget) {
      return [];
    }
    if (selectedTarget.type === 'dm') {
      if (selectedTarget.users.length > 2 && shouldShowNumberAdornment) {
        return selectedTarget.users.slice(selectedTarget.users.length - 2, selectedTarget.users.length);
      }
      return selectedTarget.users;
    }
    if (selectedTarget.type === 'group') {
      return [selectedTarget.conversation];
    }
    return [];
  }, [shouldShowNumberAdornment, selectedTarget]);

  const customFilter = useCallback((option: TargetOption, input: string) => {
    if (input.startsWith('#')) {
      return isConversation(option) && option.name.toLowerCase().includes(input.slice(1).toLowerCase());
    } else if (input.startsWith('@')) {
      return (
        isUser(option) &&
        getUserFullName(option.firstName, option.lastName).toLowerCase().includes(input.slice(1).toLowerCase())
      );
    } else {
      return isUser(option)
        ? getUserFullName(option.firstName, option.lastName).toLowerCase().includes(input.toLowerCase())
        : option.name.toLowerCase().includes(input.toLowerCase());
    }
    return false;
  }, []);

  return (
    <div ref={comboboxRef} css={comboboxParentStyle}>
      <CustomCombobox<TargetOption>
        clearable={false}
        onInputChange={setCurrentSearchInput}
        customOptionsFilter={customFilter}
        options={availableTargets ?? []}
        placeholder={placeholder}
        name={t('channel-search')}
        accessor={(item: TargetOption) => (isUser(item) ? `${item.firstName} ${item.lastName}` : item?.name)}
        className='chip-combobox'
        onOptionSelect={handleAddTarget}
        onOptionRemove={handleRemoveTarget}
        tags={selectedTargetItems ?? []}
        ChipComponent={RecipientChip}
        MenuItem={ConversationDropdownMenuItem}
        startAdornment={startAdornment}
        menuStyles={css({ height: 256, marginTop: theme.spacing(-2) })}
        isMenuOpenRef={isMenuOpenRef}
      />
      <ContentLoader show={isCreatingConversation} message={t('Creating new conversation')} />
    </div>
  );
};

const entityChipStyles = css({
  border: 'none',
  backgroundColor: theme.colors.primary5,
  svg: {
    color: theme.colors.neutral90,
  },
});

const adornmentCountStyle = css({
  width: '24px',
  height: '24px',
  borderRadius: theme.borderRadius.medium,
  border: `1.5px solid ${theme.colors.neutral70}`,
  display: 'flex',
  justifyContent: 'center',
  alignTargets: 'center',
});

const comboboxParentStyle = css({
  '.chip-combobox': {
    border: `1px solid ${theme.colors.neutral10}`,
    borderLeft: 0,
    borderRight: 0,
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    outlineColor: 'none',
    transition: 'none',
    borderRadius: 0,
  },
});

const startAdornmentStyle = css({
  display: 'flex',
  alignSelf: 'baseline',
  gap: theme.spacing(1),
});
