import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { PaymentRequestModal, usePaymentRequestModal } from '@frontend/payments-invoice-create';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';
import { useNavigateWithAlert } from '../../../hooks';
import { PaymentsNavAlerts } from '../../../utils';

const styles = {
  createButton: css`
    width: auto;
    padding-left: ${theme.spacing(1)};
  `,
  plusIcon: css`
    margin-right: ${theme.spacing(1)};
  `,
};

export type NewInvoiceActionProps = {
  trackingId?: string;
};

export const NewInvoiceAction = ({ trackingId }: NewInvoiceActionProps) => {
  const { t } = useTranslation('payments');
  const { navigate } = useNavigateWithAlert({ key: PaymentsNavAlerts.CreatePaymentRequest, type: 'success' });

  const { modalProps, triggerProps } = usePaymentRequestModal({
    isPaymentRequestListPage: true,
    onInvoiceCreated: (invoice) => navigate(`/payments/invoices/${invoice.id}`),
  });

  return (
    <>
      <Button
        trackingId={trackingId}
        iconName='plus'
        variant='primary'
        size='large'
        css={styles.createButton}
        {...triggerProps}
      >
        {t('Request Payment')}
      </Button>

      <PaymentRequestModal {...modalProps} />
    </>
  );
};
