import { FieldMask } from '@weave/schema-gen-ts/dist/google/protobuf/field_mask.pb';
import { OptionalDeep } from 'ts-toolbelt/out/Object/Optional';

type UpdateObjectFromFieldMaskArgs<T extends object> = {
  originalObj: T;
  newValues: OptionalDeep<T>;
  fieldMask: FieldMask;
  transformPathKeys?: (key: string) => string;
};

/**
 * A function to retreive the value in an object by traversing the given path.
 * @param obj - The object to retreive the value from.
 * @param path - The path to traverse in the object. Should be formatted like this: 'a.b.c'. If invalid, `undefined`
 * will be returned.
 * @param transformPathKeys (optional) - A callback function for transforming the keys found in the path string when
 * comparing to object entries. Useful when handing casing differences between the backend and frontend, such as using
 * snake_case and camelCase.
 */
export const getObjectValueFromPath = <T extends object>(
  obj: T,
  path: string,
  transformPathKeys?: (key: string) => string
): unknown => {
  const keys = path.split('.');
  const topLevelKey = keys.shift();

  if (!topLevelKey) return undefined;

  const transformedTopLevelKey = transformPathKeys?.(topLevelKey) ?? topLevelKey;
  const value = transformedTopLevelKey in obj ? obj[transformedTopLevelKey as keyof T] : undefined;

  if (value === undefined) return undefined;

  if (keys.length || (typeof value === 'object' && !Array.isArray(value) && value !== null)) {
    return getObjectValueFromPath(value as object, keys.join('.'), transformPathKeys);
  } else {
    return value;
  }
};

/**
 * @param originalObj - The original object to update according to the values in `newValues` and according to the
 * paths in the provided `fieldMask`
 * @param newValues - The object from which the new values will be pulled to update the original object according to
 * the paths in the provided `fieldMask`. This can be a deep partial of the type of `originalObj`
 * @param fieldMask - The field mask containing the paths to update in the `originalObj`. If you just have a list of
 * paths as a `string[]`, the field mask can be created by doing `new FieldMask({ paths })`.
 * @param transformPathKeys (optional) - If the keys used in the paths strings in `fieldMask` need to be transformed
 * in order to match the keys in the provided objects, provide this function. An example would be to transform from
 * snake_case (commonly used by the backend) to camelCase (commonly used by the frontend).
 *
 * @example
 * ```
 * const originalObj = {
 *  a: {
 *    aOne: false, // will be updated
 *    aTwo: false, // will not be updated
 *  },
 *  b: {
 *    bOne: {
 *      bOneAlpha: false, // will be updated
 *    },
 *    bTwo: false, // will not be updated
 *  }
 * };
 * const newValues = {
 *  a: {
 *    aOne: true, // will update
 *    aTwo: true, // provided, but will not update (since it is not in `paths`)
 *  },
 *  b: {
 *    bOne: {
 *      bOneAlpha: true, // will update with a transformed key from `paths`
 *    }
 *  }
 * };
 *
 * const paths = ['a.aOne', 'b.b_one.b_one_alpha'];
 * const transformPathKeys = (key: string) => toCamelCase(key);
 *
 * const result = updateObjectFromFieldMask({
 *  originalObj,
 *  newValues,
 *  fieldMask: new FieldMask({ paths }),
 *  transformPathKeys,
 * });
 * ```
 * result will look like this:
 * {
 *   a: {
 *     aOne: true,
 *     aTwo: false,
 *   },
 *   b: {
 *     bOne: {
 *       bOneAlpha: true,
 *     },
 *     bTwo: false,
 *   },
 * }
 */
export const updateObjectFromFieldMask = <T extends object>({
  originalObj,
  newValues,
  fieldMask,
  transformPathKeys,
}: UpdateObjectFromFieldMaskArgs<T>): T => {
  // Create a deep clone of the original object
  const result = JSON.parse(JSON.stringify(originalObj)) as T;

  fieldMask.paths?.forEach((path) => {
    const value = getObjectValueFromPath(newValues, path, transformPathKeys);
    const keys = path.split('.');

    // Skip if value is undefined
    if (value === undefined) return;

    // Update nested property
    let current: any = result;
    for (let i = 0; i < keys.length; i++) {
      const key = transformPathKeys?.(keys[i]) ?? keys[i];

      if (i === keys.length - 1) {
        // Set the value at the final key
        current[key] = value;
      } else {
        // Create nested object if it doesn't exist
        if (!(key in current)) {
          current[key] = {};
        }
        current = current[key];
      }
    }
  });

  return result;
};
