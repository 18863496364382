import { useMemo } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { PersonsV3 } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useGetActivePetsQuery } from '@frontend/vet-components';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';
import { useGetAppointmentInfo } from '../../hooks';
import { EventCardComponentProps } from '../calendar-view/providers';
import { AppointmentEventCardPreview } from './appointment-event-card';
import { prepareAppointmentInfo } from './helpers';
import { MotionText } from './motion-text';

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const EventCardComponent = ({ duration = '', id, providerId, title, eventType }: EventCardComponentProps) => {
  const isAppointment = eventType === 'appointment';
  const { appointmentDetails } = useGetAppointmentInfo(id);

  if (isAppointment && !!appointmentDetails) {
    return <AppointmentCardSwitcher duration={duration} appointmentDetails={appointmentDetails} eventId={id} />;
  }

  // Break and Out of office Event Card collapsed view
  return (
    <div css={{ display: 'flex', paddingTop: theme.spacing(1) }}>
      <Icon name={providerId ? 'remove-small' : 'location-small'} color='light' css={{ minWidth: 'fit-content' }} />
      <div css={{ display: 'flex', flexDirection: 'column', marginLeft: theme.spacing(0.5), overflow: 'hidden' }}>
        <Text size='medium' weight='bold' css={[dsStyles.truncate, { lineHeight: '16px' }]}>
          {title}
        </Text>
        <Text size='small' color='light' css={dsStyles.truncate}>
          {duration}
        </Text>
      </div>
    </div>
  );
};

const AppointmentCardSwitcher = ({
  duration,
  appointmentDetails,
  eventId,
}: {
  duration: string;
  appointmentDetails: Appointment;
  eventId: string;
}) => {
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  return lastUsedVertical === 'VET' ? (
    <VetEventCard duration={duration ?? '-'} appointmentDetails={appointmentDetails ?? {}} eventId={eventId} />
  ) : (
    <EventCard eventId={eventId} appointmentDetails={appointmentDetails ?? {}} duration={duration ?? '-'} />
  );
};

const VetEventCard = ({
  duration,
  appointmentDetails,
  eventId,
}: {
  duration: string;
  appointmentDetails: Appointment;
  eventId: string;
}) => {
  const appointmentInfo = useMemo(() => {
    const appointmentInfoToPrepare = appointmentDetails ? [appointmentDetails] : [];
    return prepareAppointmentInfo(appointmentInfoToPrepare ?? [], eventId);
  }, [appointmentDetails, eventId]);

  const { data: personData } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: appointmentInfo?.personInfo?.id ?? '',
      locationIds: [appointmentInfo?.locationId ?? ''],
    },
    {
      enabled: !!appointmentInfo?.personInfo?.id,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      select: (data) => PersonsV3.PersonHelpers.convertPersonV3ToPerson(data),
    }
  );

  const personId = appointmentInfo?.personInfo?.id || '';
  const serviceableIds = appointmentInfo?.petInfo?.serviceableIds || [];

  const petQuery = useGetActivePetsQuery({
    personId: personId || '',
    locationId: appointmentInfo?.locationId || '',
  });
  const pet = petQuery.data?.filter((pet) => pet.petServiceableId === serviceableIds?.[0])[0];

  if (!appointmentInfo) {
    return null;
  }

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Text as='span' size='medium' weight='bold' css={{ marginRight: theme.spacing(0.5) }}>
          {pet?.name}
        </Text>
        <Text as='span' size='medium'>
          {personData?.LastName}
        </Text>
      </div>
      <MotionText size='medium'>{duration}</MotionText>
    </div>
  );
};

const EventCard = ({
  eventId,
  duration,
  appointmentDetails,
}: {
  duration: string;
  eventId: string;
  appointmentDetails: Appointment;
}) => {
  const appointmentInfo = useMemo(() => {
    const appointmentInfoToPrepare = appointmentDetails ? [appointmentDetails] : [];
    return prepareAppointmentInfo(appointmentInfoToPrepare ?? [], eventId);
  }, [appointmentDetails, eventId]);

  return <AppointmentEventCardPreview name={appointmentInfo?.personInfo.name || ''} duration={duration} />;
};
