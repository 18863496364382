import { useMemo, useCallback } from 'react';
import { css } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import { produce } from 'immer';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { WidgetBoundary } from '@frontend/error-boundary';
import {
  DashboardWidgetProvider,
  dashboardBreakpoints,
  defaultMargins,
  gridColumns,
  GRID_HEIGHT,
  DashboardBreakpoints,
  WidgetSizes,
  findOptimalPosition,
  isEnabled,
  transformToLayouts,
  DashboardWidget,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { AddWidget, AddWidgetPlaceholder } from './add-widget';
import { useGridDashboard } from './store';
import { useAccessibleWidgetConfig } from './use-accesible-widgets-config';
import { useWidgets } from './use-dashboard-widgets';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const RGL = WidthProvider(Responsive);

export const GridDashboard = () => {
  const { t } = useTranslation('dashboard');
  const { isEditMode, currentBreakpoint, setCurrentBreakpoint } = useGridDashboard([
    'isEditMode',
    'currentBreakpoint',
    'setCurrentBreakpoint',
  ]);
  const { widgets } = useWidgets();
  const { accessibleWidgets: widgetConfigs, setWidgetConfigs } = useAccessibleWidgetConfig();

  const layouts = useMemo(() => transformToLayouts(widgetConfigs), [widgetConfigs]);

  const handleLayoutChange = useCallback(
    (currentLayout: Layout[]) => {
      if (isEditMode) {
        setWidgetConfigs(
          produce(widgetConfigs, (draft) => {
            draft.forEach((widget) => {
              const layout = currentLayout.find((layout) => layout.i === widget.id);
              if (layout) {
                if (!widget.positions) {
                  widget.positions = {} as Record<DashboardBreakpoints, Layout>;
                }
                widget.positions[currentBreakpoint] = layout;
              }
            });
          })
        );
      }
    },
    [isEditMode, currentBreakpoint, setWidgetConfigs, widgetConfigs]
  );

  const handleBreakpointChange = useCallback(
    (newBreakpoint: DashboardBreakpoints) => {
      setCurrentBreakpoint(newBreakpoint);
    },
    [setCurrentBreakpoint]
  );

  const renderedWidgets = useMemo(
    () =>
      widgetConfigs.map(({ id, size: currentSize, enabled }) => {
        if (!enabled) return null;

        const widgetData = widgets[id];
        if (!widgetData) return null;
        if (!isEnabled(widgetData?.hasAccess)) return null;

        const { title, icon, component: Component } = widgetData;
        const size = Component.config.size;
        const sizeOptions = (typeof size === 'string' ? [size] : Object.values(size)) as WidgetSizes[];
        const sizes = [...new Set(sizeOptions)];

        return (
          <div
            key={id}
            css={[
              css`
                background: transparent;
                padding: ${theme.spacing(1)};
                display: flex;
                justify-content: center;
                align-items: center;
              `,
              isEditMode &&
                css`
                  cursor: grab;
                `,
            ]}
          >
            <ErrorBoundary
              fallback={(props) => (
                // Render the provider with the exact same props as the original widget
                <DashboardWidgetProvider
                  currentBreakpoint={currentBreakpoint}
                  title={title}
                  icon={icon}
                  currentSize={typeof currentSize === 'string' ? currentSize : currentSize[currentBreakpoint]}
                  onSizeChange={(newSize) => {
                    setWidgetConfigs(
                      produce(widgetConfigs, (draft) => {
                        const widget = draft.find((w) => w.id === id);
                        if (widget) {
                          if (typeof widget.size === 'string') {
                            widget.size = newSize;
                          } else {
                            if (!widget.size) {
                              widget.size = {} as Record<DashboardBreakpoints, WidgetSizes>;
                            }
                            widget.size[currentBreakpoint] = newSize;
                          }
                        }
                      })
                    );
                  }}
                  sizes={sizes}
                  isEditMode={isEditMode}
                  id={id}
                  onWidgetRemove={() => {
                    setWidgetConfigs(produce(widgetConfigs, (draft) => draft.filter((widget) => widget.id !== id)));
                  }}
                >
                  <DashboardWidget>
                    <DashboardWidget.Header title={title} />
                    <DashboardWidget.Content
                      css={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <div
                        style={{
                          overflow: 'auto',
                          padding: theme.spacing(1),
                        }}
                      >
                        <WidgetBoundary {...props} />
                      </div>
                    </DashboardWidget.Content>
                  </DashboardWidget>
                </DashboardWidgetProvider>
              )}
            >
              <DashboardWidgetProvider
                currentBreakpoint={currentBreakpoint}
                title={title}
                icon={icon}
                currentSize={typeof currentSize === 'string' ? currentSize : currentSize[currentBreakpoint]}
                onSizeChange={(newSize) => {
                  setWidgetConfigs(
                    produce(widgetConfigs, (draft) => {
                      const widget = draft.find((w) => w.id === id);
                      if (widget) {
                        if (typeof widget.size === 'string') {
                          widget.size = newSize;
                        } else {
                          if (!widget.size) {
                            widget.size = {} as Record<DashboardBreakpoints, WidgetSizes>;
                          }
                          widget.size[currentBreakpoint] = newSize;
                        }
                      }
                    })
                  );
                }}
                sizes={sizes}
                isEditMode={isEditMode}
                id={id}
                onWidgetRemove={() => {
                  setWidgetConfigs(produce(widgetConfigs, (draft) => draft.filter((widget) => widget.id !== id)));
                }}
              >
                <Component />
              </DashboardWidgetProvider>
            </ErrorBoundary>
          </div>
        );
      }),
    [widgetConfigs, widgets, currentBreakpoint, isEditMode, setWidgetConfigs]
  );

  const addWidgetLayout = useMemo(() => {
    const { x, y, width, height } = findOptimalPosition(layouts[currentBreakpoint], gridColumns[currentBreakpoint]);
    return {
      i: 'add-widget',
      x,
      y,
      w: width,
      h: height,
    };
  }, [layouts, currentBreakpoint]);

  const updatedLayouts = useMemo(
    () =>
      produce(layouts, (draft) => {
        if (!isEditMode) {
          draft[currentBreakpoint].push(addWidgetLayout);
        }
      }),
    [layouts, addWidgetLayout, isEditMode, currentBreakpoint]
  );

  return (
    <div css={dashboardWrapperStyle}>
      {widgetConfigs.length ? (
        <RGL
          isBounded
          isResizable={false}
          isDraggable={isEditMode}
          onBreakpointChange={handleBreakpointChange}
          onLayoutChange={handleLayoutChange}
          rowHeight={GRID_HEIGHT}
          containerPadding={[0, 0]}
          useCSSTransforms
          margin={defaultMargins}
          breakpoints={dashboardBreakpoints}
          cols={gridColumns}
          layouts={updatedLayouts}
          allowOverlap={false}
          verticalCompact
        >
          {renderedWidgets}
          {!isEditMode && (
            <div
              key='add-widget'
              css={[
                css`
                  background: transparent;
                  padding: ${theme.spacing(1)};
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `,
                isEditMode &&
                  css`
                    cursor: grab;
                  `,
              ]}
            >
              <DashboardWidgetProvider
                id='add-widget'
                currentBreakpoint={currentBreakpoint}
                title={t('Add Widget')}
                icon={'plus-small'}
                currentSize='medium-wide'
              >
                <AddWidget />
              </DashboardWidgetProvider>
            </div>
          )}
        </RGL>
      ) : (
        <AddWidgetPlaceholder />
      )}
    </div>
  );
};

const dashboardWrapperStyle = css`
  .react-grid-placeholder {
    position: relative;
    background: transparent !important;
  }

  .react-draggable-dragging .virtual-dashboard-widget-container {
    box-shadow: ${theme.shadows.heavy};
    cursor: grabbing;
  }

  .react-grid-placeholder::after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    top: 8px;
    left: 8px;
    background: ${theme.colors.neutral30};
    border-radius: ${theme.borderRadius.medium};
    border: 1px dotted ${theme.colors.neutral50};
  }
`;
