import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';

type UserIconContainerProps = {
  width?: number;
  height?: number;
};

export const UserIconContainer = ({ width = 28, height = 28 }: UserIconContainerProps) => {
  return (
    <div css={userIconContainerStyles(width, height)}>
      <Icon name='user' color='light' size={16} />
    </div>
  );
};

const userIconContainerStyles = (width: number, height: number) =>
  css({
    width: width,
    height: height,
    borderRadius: theme.borderRadius.full,
    border: `1px dashed ${theme.colors.neutral30}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
