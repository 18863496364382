import { IntakeFormApi, IntakeFormTypes } from '@frontend/api-intake-form';
import { CountryCodes } from '@frontend/geography';
import { useAddressForm } from '../hooks/use-address-form';

// TODO: need to move these types & enum of smartyStreetsAddress to its related file
// See https://www.smartystreets.com/docs/cloud/international-street-api#addressprecision
enum SmartyStreetsAddressPrecision {
  none = 'None',
  administrativeArea = 'AdministrativeArea',
  locality = 'Locality',
  thoroughfare = 'Thoroughfare',
  premise = 'Premise',
  deliveryPoint = 'DeliveryPoint',
}

// TODO: confirm about this custom function instead of using query-string package
// Also need to confirm about way of handling string, null and undefined here
const stringifyObject = (data: Record<string, any>) => {
  return new URLSearchParams(data).toString();
};
export const convertToIntakeFormAddress = (
  address: ReturnType<typeof useAddressForm>['values'],
  verified: boolean
): IntakeFormTypes.IntakeFormAddress => ({
  address1: address.address1 ?? '',
  address2: address.address2 ?? '',
  city: address.city ?? '',
  state: address.state ?? '',
  postal: address.postal ?? '',
  country: (address.country as CountryCodes) ?? CountryCodes.USA,
  verified,
});

export const verifyAddress = async (values: ReturnType<typeof useAddressForm>['addressFormProps']['values']) => {
  const addressFields = stringifyObject({
    address1: values.address1,
    address2: values.address2,
    locality: values.city,
    administrative_area: values.state,
    postal_code: values.postal,
    country: values.country,
  });
  const data = (await IntakeFormApi.getVerifiedAddress<IntakeFormTypes.AddressInformationType>(addressFields)) ?? [];

  const result: IntakeFormTypes.IntakeFormAddress[] = [];
  data.forEach(({ analysis, components }) => {
    const precision = analysis?.address_precision;
    if (
      precision === SmartyStreetsAddressPrecision.deliveryPoint ||
      precision === SmartyStreetsAddressPrecision.premise ||
      precision === SmartyStreetsAddressPrecision.thoroughfare
    ) {
      result.push({
        address1: `${components.premise ?? ''} ${components.thoroughfare ?? ''}`,
        address2: `${components.sub_building_type ?? ''} ${components.sub_building_number ?? ''}`.trim(),
        city: components.locality ?? '',
        state: components.administrative_area ?? '',
        country: (values.country as CountryCodes) ?? CountryCodes.USA,
        postal: `${components.postal_code_short ?? ''} ${components.postal_code_extra ?? ''}`.trim(),
        verified: true,
      });
    }
  });
  return result;
};

export const defaultAddress: IntakeFormTypes.IntakeFormAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: CountryCodes.USA,
  postal: '',
  verified: false,
};
