/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { createContext, useContextSelector } from 'use-context-selector';
import { type TeamChatTypes } from '@frontend/api-team-chat';
import type { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { useTeamChatSelector } from '../team-chat.provider';
import { useTeamChatApi } from '../team-chat.provider/team-chat-api-providers/use-team-chat-api';

const defaultOptions: ContextlessQueryObserverOptions = {
  useErrorBoundary: false,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 60 * 1000 * 5, // 5 minutes
};

type ContextValue = ReturnType<typeof _useContextValue>;
const TeamChatConversationContext = createContext<ContextValue | undefined>(undefined);

type Props = {
  activeConversationId: string | undefined;
  selectedThreadParentId: string | undefined;
  enabled: boolean;
  children: React.ReactNode;
};

export const _TeamChatActiveConversationProvider = ({
  activeConversationId,
  selectedThreadParentId,
  enabled,
  children,
}: Props) => {
  const value = _useContextValue({ activeConversationId, selectedThreadParentId, enabled });
  return <TeamChatConversationContext.Provider value={value}>{children}</TeamChatConversationContext.Provider>;
};
_TeamChatActiveConversationProvider.displayName = '_TeamChatActiveConversationProvider';

const _useContextValue = ({ activeConversationId, selectedThreadParentId, enabled }: Omit<Props, 'children'>) => {
  const queryClient = useQueryClient();
  const conversation = useTeamChatSelector((ctx) =>
    [...(ctx.conversations?.dm ?? []), ...(ctx.conversations?.groups ?? [])].find(
      (c) => c.channelId === activeConversationId
    )
  );
  const users = useTeamChatSelector((ctx) => ctx.users);
  const teamChatCache = useTeamChatSelector((ctx) => ctx.cache);
  const api = useTeamChatSelector((ctx) => ctx.api);

  const {
    user,
    useQueryConversationMessages,
    useQueryReplies,
    useMutationSendMessage,
    useMutationSendReply,
    useMutationEditMessage,
    useMutationDeleteMessage,
    useMutationAddReactionToMessage,
    useMutationRemoveReactionFromMessage,
    useMutationAddAttachments,
    useMutationSendConversationEvent,
  } = useTeamChatApi(api);

  const {
    data: messages,
    isLoading,
    error,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchMessages,
    status,
    set: setMessages,
  } = useQueryConversationMessages(activeConversationId ?? '', {
    enabled: enabled && !!activeConversationId && !selectedThreadParentId,
    keepPreviousData: true,
    ...defaultOptions,
  });

  const {
    data: replies,
    isLoading: isLoadingReplies,
    isFetched: isRepliesFetched,
    error: repliesError,
    isFetching: isFetchingReplies,
    fetchNextPage: fetchNextReplies,
    set: setReplies,
  } = useQueryReplies(activeConversationId ?? '', selectedThreadParentId || '', {
    enabled: enabled,
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const allMessages = useMemo(() => {
    return messages?.pages.reverse().flatMap((page) => page) ?? [];
  }, [messages]);
  const allReplies = useMemo(() => {
    return replies?.pages.reverse().flatMap((page) => page) ?? [];
  }, [replies]);

  const cache = useMemo(() => {
    const messageUpdater = (messageId: string, updater: (message: TeamChatTypes.Message) => TeamChatTypes.Message) => {
      setMessages((prev) => {
        if (!prev) {
          return prev;
        }
        //Note: this isn't the easiest/prettiest way to do this, but it is efficient, and that's critical here
        const { pageIndex, messageIndex } = prev.pages.reduce(
          (acc, page, pageIndex) => {
            const messageIndex = page.findIndex((m) => m.id === messageId);
            if (messageIndex !== -1) {
              return { pageIndex, messageIndex };
            }
            return acc;
          },
          { pageIndex: -1, messageIndex: -1 }
        );
        if (pageIndex === -1 || messageIndex === -1) {
          return prev;
        }
        const prevMessage = prev.pages[pageIndex]?.[messageIndex];
        if (!prevMessage) {
          return prev;
        }
        const nextMessage = updater(prevMessage);
        if (isEqual(prevMessage, nextMessage)) {
          return prev;
        }
        const newPages = prev.pages.map((page, idx) => {
          if (idx === pageIndex) {
            return page.map((msg, idx) => (idx === messageIndex ? nextMessage : msg));
          }
          return page;
        });

        return {
          ...prev,
          pages: newPages,
        };
      });
    };
    const replyUpdater = (replyId: string, updater: (message: TeamChatTypes.Message) => TeamChatTypes.Message) => {
      setReplies((prev) => {
        if (!prev) {
          return prev;
        }
        //Note: this isn't the easiest/prettiest way to do this, but it is efficient, and that's critical here
        const { pageIndex, messageIndex } = prev.pages.reduce(
          (acc, page, pageIndex) => {
            const messageIndex = page.findIndex((m) => m.id === replyId);
            if (messageIndex !== -1) {
              return { pageIndex, messageIndex };
            }
            return acc;
          },
          { pageIndex: -1, messageIndex: -1 }
        );
        if (pageIndex === -1 || messageIndex === -1) {
          return prev;
        }
        const prevMessage = prev.pages[pageIndex]?.[messageIndex];
        if (!prevMessage) {
          return prev;
        }
        const nextMessage = updater(prevMessage);
        if (isEqual(prevMessage, nextMessage)) {
          return prev;
        }
        const newPages = prev.pages.map((page, idx) => {
          if (idx === pageIndex) {
            return page.map((msg, idx) => (idx === messageIndex ? nextMessage : msg));
          }
          return page;
        });

        return {
          ...prev,
          pages: newPages,
        };
      });
    };
    return {
      addMessage: (message: TeamChatTypes.Message) =>
        setMessages((prev) => {
          if (!prev) {
            return {
              pages: [[message]],
              pageParams: [],
              pageSequences: [],
            };
          }
          const messageExists = prev.pages.some((page) => page.some((m) => m.id === message.id));
          if (messageExists) {
            return prev;
          }
          const lastPage = prev.pages[prev.pages.length - 1];
          const next = {
            ...prev,
            pages: prev.pages.map((page) => (page === lastPage ? [...page, message] : page)),
          };
          return next;
        }),

      editMessage: (
        messageId: string,
        updates: Partial<TeamChatTypes.Message> | Parameters<typeof messageUpdater>[1]
      ) => {
        messageUpdater(messageId, (prev) => (typeof updates === 'function' ? updates(prev) : { ...prev, ...updates }));
      },

      addReply: (reply: TeamChatTypes.Message) => {
        setReplies((prev) => {
          if (!prev) {
            return {
              pages: [[reply]],
              pageParams: [],
              pageSequences: [],
            };
          }
          const replyExists = prev.pages.some((page) => page?.some((m) => m.id === reply.id));
          const lastPage = prev.pages[prev.pages.length - 1];
          messageUpdater(reply.parentId, (message) => {
            const userAlreadyExists = message.threadParticipantIds?.find(
              (participantId) => participantId === reply.userId
            );
            if (!userAlreadyExists) {
              message.threadParticipantIds.push(reply.userId);
            }
            return { ...message };
          });
          if (replyExists) {
            return prev;
          }
          return {
            ...prev,
            pages: prev.pages.map((page) => (page && page === lastPage ? [...page, reply] : page)),
          };
        });
      },

      editReply: (reply: TeamChatTypes.Message) => {
        replyUpdater(reply.id, (prev) => ({ ...prev, ...reply }));
      },

      deleteReply: (replyId: string) => {
        replyUpdater(replyId, (prev) => ({ ...prev, type: 'deleted' }));
      },

      deleteMessage: (messageId: string) => {
        messageUpdater(messageId, (prev) => ({ ...prev, type: 'deleted' }));
      },

      removeReaction: (messageId: string, isReply: boolean | null, userId: string, reactionName: string) => {
        const updater = isReply ? replyUpdater : messageUpdater;
        updater(messageId, (prev) => {
          const reactions = prev.reactions ?? [];

          return {
            ...prev,
            reactions: reactions
              .map((reaction) =>
                reaction.name === reactionName
                  ? {
                      ...reaction,
                      count: reaction.count - 1,
                      hasOwnReaction: false,
                      usersIds: reaction.usersIds.filter((id) => id !== userId),
                    }
                  : reaction
              )
              .filter((reaction) => reaction.count > 0),
          };
        });
      },
      // works when there is only one reaction or none
      addReaction: (messageId: string, isReply: boolean | null, userId: string, reactionName: string) => {
        const updater = isReply ? replyUpdater : messageUpdater;
        return updater(messageId, (prev) => {
          const reactions = prev.reactions ?? [];
          const hasReaction = reactions.some((reaction) => reaction.name === reactionName);

          if (hasReaction) {
            return {
              ...prev,
              reactions: reactions.map((reaction) =>
                reaction.name === reactionName
                  ? {
                      ...reaction,
                      count: reaction.count + 1,
                      hasOwnReaction: true,
                      usersIds: [...reaction.usersIds, userId],
                    }
                  : reaction
              ),
            };
          } else {
            // Reaction does not exist, add a new one to reactions array
            return {
              ...prev,
              reactions: [
                ...reactions,
                {
                  name: reactionName,
                  count: 1,
                  hasOwnReaction: true,
                  usersIds: [userId],
                  firstReaction: 1,
                },
              ],
            };
          }
        });
      },

      addAttachmentsToMessage: (messageId: string, attachments: TeamChatTypes.Attachment[]) => {
        messageUpdater(messageId, (prev) => {
          return {
            ...prev,
            attachments: [...(prev.attachments ?? []), ...attachments],
          };
        });
      },
    };
  }, [setMessages]);

  const {
    mutateAsync: sendMessage,
    isLoading: isSendingMessage,
    error: sendMessageError,
  } = useMutationSendMessage({
    onSuccess: (messageRes) => {
      if (messageRes.type === 'reply') {
        cache.addReply(messageRes);
      } else {
        cache.addMessage(messageRes);
      }
    },
  });

  const {
    mutateAsync: sendReply,
    isLoading: isSendingReply,
    error: sendReplyError,
  } = useMutationSendReply(activeConversationId ?? '', selectedThreadParentId || '', {
    onSuccess: (message) => {
      cache.addReply(message);
    },
  });
  const {
    mutateAsync: editMessage,
    isLoading: isEditingMessage,
    error: editMessageError,
  } = useMutationEditMessage({
    onSuccess: (message) => {
      if (message.type === 'reply') {
        cache.editReply(message);
      } else {
        cache.editMessage(message.id, message);
      }
    },
  });

  const {
    mutateAsync: deleteMessage,
    isLoading: isDeletingMessage,
    error: deleteMessageError,
  } = useMutationDeleteMessage({
    onSuccess: (message) => {
      // a deleted message has a type of 'deleted'. we can still check if it was a reply by checking the parentId field.
      const isReply = !!message.parentId;
      const isParent = message.replyCount > 0;

      if (isParent) {
        cache.editMessage(message.id, message);
        return;
      }
      if (isReply) {
        cache.deleteReply(message.id);
      } else {
        cache.deleteMessage(message.id);
      }
    },
  });

  const {
    mutateAsync: addReaction,
    isLoading: isAddingReaction,
    error: addReactionError,
  } = useMutationAddReactionToMessage({
    onSuccess: (_message, vars) => {
      const isReply = !!_message.parentId;
      cache.addReaction(vars.messageId, isReply, user?.userID || '', vars.reaction);
    },
  });

  const {
    mutateAsync: removeReaction,
    isLoading: isRemovingReaction,
    error: removeReactionError,
  } = useMutationRemoveReactionFromMessage({
    onSuccess: (_message, vars) => {
      const isReply = !!vars.parentMessageId;
      cache.removeReaction(vars.messageId, isReply, user?.userID || '', vars.reaction);
    },
  });

  const {
    mutateAsync: uploadAttachment,
    isLoading: isUploadingAttachment,
    error: uploadAttachmentError,
  } = useMutationAddAttachments({});

  const { mutateAsync: sendConversationEvent } = useMutationSendConversationEvent({
    onSuccess: (_res, vars) => {
      queryClient.removeQueries(['team-chat', user?.userID || '', 'unread-mentions-count']);
      const event = vars.event;
      if (event === 'read' && activeConversationId) {
        //update conversation's unread count to zero,
        //and update all messages in current conversation to unread = false
        teamChatCache.updateConversation(activeConversationId, {
          unreadCount: 0,
          lastReadMessageId: allMessages[allMessages.length - 1]?.id,
        });
        teamChatCache.markConversationMentionedMessagesAsRead(activeConversationId);
        setMessages((prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            pages: prev.pages.map((page) =>
              page.map((m) => (m.isUnread ? ({ ...m, isUnread: false } satisfies TeamChatTypes.Message) : m))
            ),
          };
        });
      }
    },
  });

  const members = useMemo(() => {
    if (!conversation?.memberIds) {
      return [];
    }

    return conversation.memberIds
      .map((memberId) => users?.find((user) => user.userID === memberId))
      .filter((user) => !!user);
  }, [conversation?.memberIds, users]);

  const getFirstUnreadMessageIndex = useCallback(() => {
    if (!conversation) {
      return undefined;
    }
    if (!activeConversationId) {
      return 0;
    }
    const lastReadMessageId = conversation.lastReadMessageId;
    const unreadCount = conversation.unreadCount;
    const messages = allMessages;
    // if there are no messages then return 0
    if (!messages?.length) {
      return 0;
    }

    // If there is no last read message id, it means that it's a newly created conversation. Hence return the unread count
    if (!lastReadMessageId) {
      if (!unreadCount) {
        return 0;
      } else if (unreadCount > messages.length) {
        return -1;
      }
      return unreadCount ? messages.length - unreadCount : 0;
    }

    // If none of the above conditions are met, then find the last unread message index from the messages array
    let messageIndex = messages.findIndex((message) => message.id === lastReadMessageId);
    for (let i = messageIndex + 1; i < messages.length; i++) {
      if (messages[i].isOwnMessage) {
        messageIndex = i;
      }
    }

    // lastly we return the index + 1 to show the unread line after the last read message
    return messageIndex === -1 ? -1 : messageIndex + 1;
  }, [activeConversationId, conversation?.lastReadMessageId, allMessages.length]);

  const activeConversationValue = {
    conversation,
    messages: allMessages,
    replies: allReplies,
    members,
    isLoading,
    isLoadingReplies: isLoadingReplies || isFetchingReplies,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    fetchNextReplies,
    status,
    error,
    uploadAttachmentError,
    isRepliesFetched,
    isSendingReply,

    sendReply,
    sendMessage,
    editMessage,
    deleteMessage,
    addReaction,
    removeReaction,
    uploadAttachment,
    sendConversationEvent,

    isSendingMessage,
    isEditingMessage,
    isDeletingMessage,
    isAddingReaction,
    isRemovingReaction,
    isUploadingAttachment,

    addReactionError,
    removeReactionError,
    sendMessageError,
    editMessageError,
    deleteMessageError,
    repliesError,
    sendReplyError,

    refetchMessages,

    cache,
    getFirstUnreadMessageIndex,
  };
  return activeConversationValue;
};

export const useActiveConversationSelector = <T extends any>(
  selector: (ctx: ReturnType<typeof _useContextValue>) => T
) => {
  return useContextSelector(TeamChatConversationContext, (ctx) => selector(ctx as ReturnType<typeof _useContextValue>));
};
