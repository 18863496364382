import { LayoutType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { Heading } from '@frontend/design-system';

type Props = {
  device: LineKeysTypes.ListDeviceType | undefined;
  model: LineKeysTypes.ModelInfoType | undefined;
};

export const DeviceLayoutHeader = ({ model, device }: Props) => {
  return (
    <Heading
      as='div'
      className={`device-model-layout-header ${model?.layoutType === LayoutType_Enum.TOUCH_SCREEN && 'touch-screen'}`}
      level={2}
      textAlign='center'
      color='white'
    >
      {model?.name ?? device?.model}
    </Heading>
  );
};
