import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { useAddToQuickFillListDialog } from '../../../hooks';
import { QUICK_FILL_TRACKING_IDS } from '../../../tracking-ids';
import { PlusIcon } from './plus-icon';

type Props = {
  position?: 'left' | 'center';
  showSeparator?: boolean;
  locationId: string;
};

const trackingIds = QUICK_FILL_TRACKING_IDS.manageListTab;

export const AddToListButton = ({ locationId, position = 'center', showSeparator = false }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');

  const { customContactDialogProps, AddToListQuickFillListDialog } = useAddToQuickFillListDialog({
    customContactFormFields: {
      firstName: { value: '', required: true },
      lastName: { value: '', required: true },
      phoneNumber: { value: '', required: true },
      notes: { value: '' },
    },
    selectedLocationId: locationId,
  });

  return (
    <div
      css={{
        padding: theme.spacing(1.5, 2),
        width: '100%',
        display: 'flex',
        justifyContent: position === 'center' ? 'center' : 'flex-start',
        borderTop: showSeparator ? `1px solid ${theme.colors.neutral20}` : 'none',
      }}
    >
      <Button
        variant='tertiary'
        size='large'
        css={{ height: 40, padding: theme.spacing(0.5, 1) }}
        {...customContactDialogProps.getTriggerProps()}
        trackingId={trackingIds.addToListButton}
      >
        <PlusIcon />
        <Text weight='bold'>{t('Add to list')}</Text>
      </Button>
      {AddToListQuickFillListDialog}
    </div>
  );
};
