import React from 'react';
import { css } from '@emotion/react';
import { ReviewedStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import dayjs from 'dayjs';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { getUser } from '@frontend/auth-helpers';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Avatar,
  ContentLoader,
  PopoverMenu,
  PopoverMenuItem,
  Text,
  useModalControl,
  usePopoverMenu,
  useAlert,
  ConfirmationModal,
  Button,
  currency,
} from '@frontend/design-system';
import { useScheduleActionsContext } from '../../ScheduleActionsContext';
import { useScheduleRequestContext } from '../Context/ScheduleRequestContext';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import { ScheduleRequestDeleteConfirmationModal } from './components/ScheduleRequestDeleteConfirmationModal';

interface Props {
  name: string;
  personId?: string;
  appointmentTime: string;
  personPhone: string;
  paidAmount: number;
  locationId: string;
  updateSelectedScheduleRequest: () => void;
  scheduleRequestId: string;
}

export const ScheduleRequestListItemHeader = React.memo(
  ({
    name,
    paidAmount,
    personId,
    appointmentTime,
    personPhone,
    locationId,
    scheduleRequestId,
    updateSelectedScheduleRequest,
  }: Props) => {
    const alert = useAlert();
    const user = getUser();
    const { t } = useTranslation('scheduleCalendarActions');
    const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

    const { refetchScheduleRequestList } = useScheduleRequestContext();
    const { closeModal, refetchAppointmentRequestCount } = useScheduleActionsContext();

    const deleteScheduleRequestModalProps = useModalControl();
    const rejectScheduleRequestModalControl = useModalControl();

    const formattedDate = appointmentTime ? dayjs(appointmentTime).format('DD MMM YYYY, h:mm A') : '';

    const { mutateAsync: deleteScheduleRequest, isLoading: isLoadingDeleteScheduleRequest } =
      ScheduleQueries.useDeleteScheduleRequest();
    const { mutateAsync: deleteScheduleRequestV3, isLoading: isLoadingDeleteScheduleRequestV3 } =
      SchedulerV3.Mutations.useDeleteBookingSubmissionMutation();

    const { mutate: rejectScheduleRequestV3, isLoading: isRejectingScheduleRequestV3 } =
      SchedulerV3.Mutations.useUpdateBookingSubmissionMutation({
        options: {
          onSuccess: () => {
            rejectScheduleRequestModalControl.closeModal();
            alert.success(t('Schedule request rejected successfully'));
          },
          onError: () => {
            alert.error(t('Failed to reject schedule request'));
          },
        },
      });

    const { onClick: messageOnClick } = ActionsUI.actions.useMessageAction();

    const {
      getMenuProps,
      getTriggerProps,
      close: closePopOverMenu,
    } = usePopoverMenu({
      placement: 'bottom-start',
    });

    const rejectScheduleRequest = () => {
      rejectScheduleRequestV3({
        submissionId: scheduleRequestId,
        reviewedBy: user?.userID ?? '',
        reviewedStatus: ReviewedStatus.REJECTED,
      });
    };

    const deleteScheduleRequestHandler = async () => {
      try {
        if (isScheduleV3FlagEnabled) {
          await deleteScheduleRequestV3({ submissionIds: [scheduleRequestId] });
        } else {
          await deleteScheduleRequest({ id: scheduleRequestId, locationId });
          refetchAppointmentRequestCount();
          refetchScheduleRequestList();
        }
        deleteScheduleRequestModalProps.closeModal();
        alert.success(t('Schedule request deleted successfully'));
      } catch (error) {
        alert.error(t('Failed to delete schedule request'));
      }
    };

    const handleReviewRequestMenuItemClick = () => {
      updateSelectedScheduleRequest();
      closePopOverMenu();
    };

    const handleRejectRequestMenuItemClick = () => {
      rejectScheduleRequestModalControl.openModal();
      closePopOverMenu();
    };
    const handleDeleteMenuItemClick = () => {
      deleteScheduleRequestModalProps.openModal();
      closePopOverMenu();
    };
    const handleMessageButtonClick = async () => {
      await messageOnClick({
        personId,
        personPhone,
        threadGroupId: locationId,
        groupIds: [locationId],
      });
      closePopOverMenu();
      closeModal();
    };

    return (
      <>
        <ContentLoader
          show={isLoadingDeleteScheduleRequest || isLoadingDeleteScheduleRequestV3 || isRejectingScheduleRequestV3}
        />
        <div css={containerStyles}>
          <Avatar name={name} />
          <div css={{ marginLeft: theme.spacing(2) }}>
            <Text size='medium' textAlign='left' weight='bold'>
              {name}
            </Text>
            {!!paidAmount && (
              <>
                <Text textAlign='left' size='small' css={{ display: 'inline', color: theme.colors.tangerine50 }}>
                  {currency(paidAmount, { decimals: 2 })}
                </Text>
                {` • `}
              </>
            )}
            <Text textAlign='left' size='small' css={{ display: 'inline' }}>
              {formattedDate ? formattedDate : '-'}
            </Text>
          </div>

          <Button
            variant='secondary'
            iconName='more-small'
            trackingId={ScheduleRequestsTrackingIds.requestListItemMoreBtn}
            css={{ marginLeft: 'auto' }}
            {...getTriggerProps()}
          />
          <PopoverMenu {...getMenuProps()}>
            <PopoverMenuItem
              trackingId={ScheduleRequestsTrackingIds.requestListItemReviewRequestBtn}
              Icon={() => <Icon name='lists-small' />}
              onClick={handleReviewRequestMenuItemClick}
            >
              {t('Review Request')}
            </PopoverMenuItem>
            <div>
              {personPhone && (
                <PopoverMenuItem
                  trackingId={ScheduleRequestsTrackingIds.requestListItemMessageBtn}
                  Icon={() => <Icon name='message-small' />}
                  css={{ width: '100%' }}
                  onClick={handleMessageButtonClick}
                >
                  {t('Message')}
                </PopoverMenuItem>
              )}
            </div>
            {isScheduleV3FlagEnabled && (
              <PopoverMenuItem
                trackingId={ScheduleRequestsTrackingIds.requestListItemRejectBtn}
                Icon={() => <Icon name='calendar-discarded-small' />}
                onClick={handleRejectRequestMenuItemClick}
              >
                {t('Reject Request')}
              </PopoverMenuItem>
            )}
            <PopoverMenuItem
              trackingId={ScheduleRequestsTrackingIds.requestListItemDeleteBtn}
              Icon={() => <Icon name='trash-small' />}
              onClick={handleDeleteMenuItemClick}
            >
              {t('Delete')}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
        <ScheduleRequestDeleteConfirmationModal
          onConfirm={deleteScheduleRequestHandler}
          modalProps={deleteScheduleRequestModalProps.modalProps}
        />
        <ConfirmationModal
          destructive
          {...rejectScheduleRequestModalControl.modalProps}
          title={t('Confirm Reject')}
          message={t('Are you sure you want to reject this request?')}
          onConfirm={rejectScheduleRequest}
          cancelTrackingId={ScheduleRequestsTrackingIds.rejectConfirmationModalCancelBtn}
          confirmTrackingId={ScheduleRequestsTrackingIds.rejectConfirmationModalConfirmBtn}
        />
      </>
    );
  }
);

ScheduleRequestListItemHeader.displayName = 'ScheduleRequestListItemHeader';

const containerStyles = css({ display: 'flex', alignItems: 'center', width: '100%' });
