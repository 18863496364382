import { ReactNode, createContext } from 'react';
import {
  TeamChatApi,
  TeamChatMutations,
  TeamChatQueries,
  TeamChatSubscriptions,
  TeamChatTypes,
} from '@frontend/api-team-chat';
import type { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import type { TeamChatApiProviderInterface } from './interface';

type ChannelSubscriptionHandler = Parameters<typeof TeamChatSubscriptions.subscribeToChannel>[2];
type ClientSubscriptionHandler = Parameters<typeof TeamChatSubscriptions.subscribeToClient>[1];

export const _StreamAPIContext = createContext<TeamChatApiProviderInterface | undefined>(undefined);

const defaultOptions: ContextlessQueryObserverOptions = {
  useErrorBoundary: false,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 60 * 1000 * 5, // 5 minutes
};

const StreamAPIProvider = ({ children, orgId, userId }: { orgId: string; userId: string; children: ReactNode }) => {
  const initialization = TeamChatQueries.useQueryInitializeClient(
    {
      orgId,
      weaveUserId: userId,
    },
    { ...defaultOptions, staleTime: Infinity, keepPreviousData: true }
  );

  const setUser = (updater: (prev: TeamChatTypes.User) => TeamChatTypes.User) => {
    initialization.set((prev) => {
      if (!prev) {
        return;
      }
      const next = updater(prev.user);
      return { ...prev, user: next };
    });
  };

  const { data: connection } = initialization;

  const clientUserId = connection?.user?.userID;

  const invalidate = TeamChatQueries.useInvalidateAll();

  const useQueryUsers = (opts: Parameters<typeof TeamChatQueries.useQueryUsers>[2]) =>
    TeamChatQueries.useQueryUsers(connection?.client, orgId, {
      enabled: !!connection?.client && !!clientUserId,
      ...defaultOptions,
      ...opts,
    });

  const useQueryConversations = (opts: Parameters<typeof TeamChatQueries.useQueryConversations>[3]) =>
    TeamChatQueries.useQueryConversations(connection?.client, clientUserId, orgId, {
      enabled: !!connection?.client && !!clientUserId,
      ...defaultOptions,
      ...opts,
    });

  const useQueryUnreadMentionsCount = (opts: Parameters<typeof TeamChatQueries.useQueryUnreadMentionsCount>[2]) =>
    TeamChatQueries.useQueryUnreadMentionsCount(connection?.client, clientUserId ?? '', {
      enabled: !!connection?.client && !!clientUserId,
      ...defaultOptions,
      ...opts,
    });

  const useQueryMentions = (
    limit: number,
    offset: number,
    opts: Parameters<typeof TeamChatQueries.useQueryMentions>[4]
  ) =>
    TeamChatQueries.useQueryMentions(connection?.client, clientUserId ?? '', limit, offset, {
      enabled: !!connection?.client && !!clientUserId,
      ...defaultOptions,
      ...opts,
    });

  const useQueryConversationMessages = (
    conversationId: string,
    opts: Parameters<typeof TeamChatQueries.useQueryConversationMessages>[3]
  ) =>
    TeamChatQueries.useQueryConversationMessages(connection?.client, connection?.user?.userID || '', conversationId, {
      enabled: opts?.enabled !== false && !!connection?.client && !!connection?.user.userID && !!conversationId,
      ...defaultOptions,
    });

  const useQueryReplies = (
    conversationId: string,
    messageId: string,
    opts: Parameters<typeof TeamChatQueries.useQueryReplies>[4]
  ) =>
    TeamChatQueries.useQueryReplies(connection?.client, conversationId, connection?.user?.userID || '', messageId, {
      enabled:
        opts?.enabled !== false && !!messageId && !!conversationId && !!connection?.client && !!connection?.user.userID,
      ...defaultOptions,
    });

  const useMutationUpdateUser = (opts: Parameters<typeof TeamChatMutations.useMutationUpdateUser>[1]) =>
    TeamChatMutations.useMutationUpdateUser(connection?.client, opts);

  const useMutationCreateConversation = (opts: Parameters<typeof TeamChatMutations.useMutationCreateConversation>[2]) =>
    TeamChatMutations.useMutationCreateConversation(connection?.client, orgId, opts);

  const useMutationUpdateConversation = (opts: Parameters<typeof TeamChatMutations.useMutationUpdateConversation>[1]) =>
    TeamChatMutations.useMutationUpdateConversation(connection?.client, opts);

  const useMutationDeleteConversation = (opts: Parameters<typeof TeamChatMutations.useMutationDeleteConversation>[1]) =>
    TeamChatMutations.useMutationDeleteConversation(connection?.client, opts);

  const useMutationAddMembersToConversation = (
    opts: Parameters<typeof TeamChatMutations.useMutationAddMembersToConversation>[1]
  ) => TeamChatMutations.useMutationAddMembersToConversation(connection?.client, opts);

  const useMutationRemoveMembersFromConversation = (
    opts: Parameters<typeof TeamChatMutations.useMutationRemoveMembersFromConversation>[1]
  ) => TeamChatMutations.useMutationRemoveMembersFromConversation(connection?.client, opts);

  const useMutationSendMessage = (opts: Parameters<typeof TeamChatMutations.useMutationSendMessage>[2]) =>
    TeamChatMutations.useMutationSendMessage(connection?.client, connection?.user?.userID || '', opts);

  const useMutationSendReply = (
    conversationId: string,
    parentMessageId: string,
    opts: Parameters<typeof TeamChatMutations.useMutationSendReply>[4]
  ) =>
    TeamChatMutations.useMutationSendReply(
      connection?.client,
      connection?.user?.userID || '',
      conversationId,
      parentMessageId,
      opts
    );

  const useMutationEditMessage = (opts: Parameters<typeof TeamChatMutations.useMutationEditMessage>[2]) =>
    TeamChatMutations.useMutationEditMessage(connection?.client, userId, opts);

  const useMutationDeleteMessage = (opts: Parameters<typeof TeamChatMutations.useMutationDeleteMessage>[1]) =>
    TeamChatMutations.useMutationDeleteMessage(connection?.client, opts);

  const useMutationAddReactionToMessage = (
    opts: Parameters<typeof TeamChatMutations.useMutationAddReactionToMessage>[1]
  ) => TeamChatMutations.useMutationAddReactionToMessage(connection?.client, opts);
  const useMutationRemoveReactionFromMessage = (
    opts: Parameters<typeof TeamChatMutations.useMutationRemoveReactionFromMessage>[1]
  ) => TeamChatMutations.useMutationRemoveReactionFromMessage(connection?.client, opts);

  const useMutationAddAttachments = (opts: Parameters<typeof TeamChatMutations.useMutationAddAttachments>[1]) =>
    TeamChatMutations.useMutationAddAttachments(connection?.client, opts);

  const useMutationSendConversationEvent = (
    opts: Parameters<typeof TeamChatMutations.useMutationSendConversationEvent>[1]
  ) => TeamChatMutations.useMutationSendConversationEvent(connection?.client, opts);

  const subscribeToConversation = (conversationId: string, handler: ChannelSubscriptionHandler) => {
    if (!connection?.client) {
      throw new Error('Cannot subscribe to conversation. Missing client');
    }
    return TeamChatSubscriptions.subscribeToChannel(connection.client, conversationId, handler);
  };

  const subscribeToClient = (handler: ClientSubscriptionHandler) => {
    if (!connection?.client) {
      throw new Error('Cannot subscribe to client. Missing client');
    }
    return TeamChatSubscriptions.subscribeToClient(connection?.client, handler);
  };

  const disconnect = () => {
    return TeamChatApi.disconnectClient(connection?.client);
  };

  const reconnect = async () => {
    try {
      TeamChatApi.disconnectClient(connection?.client);
    } catch {
      // Ignore error
    }
    return TeamChatApi.reconnectClient(connection?.client);
  };

  const destroy = () => {
    disconnect();
    initialization.data?.client.closeConnection();
    initialization.remove();
    invalidate();
  };

  const value = {
    user: connection?.user,
    setUser,
    isInitialized: initialization.isSuccess,
    isInitializing: initialization.isFetching,
    initializationError: initialization.error,
    reInitialize: initialization.refetch,
    disconnect,
    reconnect,
    destroy: destroy,

    useQueryUsers,
    useQueryConversations,
    useQueryUnreadMentionsCount,
    useQueryMentions,
    useQueryConversationMessages,
    useQueryReplies,
    useMutationUpdateUser,
    useMutationCreateConversation,
    useMutationUpdateConversation,
    useMutationAddMembersToConversation,
    useMutationRemoveMembersFromConversation,
    useMutationDeleteConversation,
    useMutationSendMessage,
    useMutationSendReply,
    useMutationEditMessage,
    useMutationDeleteMessage,
    useMutationAddReactionToMessage,
    useMutationRemoveReactionFromMessage,
    useMutationAddAttachments,
    useMutationSendConversationEvent,

    subscribeToConversation,
    subscribeToClient,
  };

  return <_StreamAPIContext.Provider value={value}>{children}</_StreamAPIContext.Provider>;
};

export const _StreamAPIProvider = StreamAPIProvider;
