import { css } from '@emotion/react';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Info, Text } from '@frontend/design-system';

type Props = CommonHTMLAttributes & {
  text: string;
  infoText?: string;
  subtext?: string;
};

export const StepQuestion = ({ infoText, text, subtext, ...rest }: Props) => {
  return (
    <div css={stepQuestionContainerStyles} {...rest}>
      <div css={questionAndInfoContainer}>
        <Text size='large' weight='bold' textAlign='center'>
          {text}
        </Text>
        {infoText && <Info css={{ marginLeft: theme.spacing(1) }}>{infoText}</Info>}
      </div>
      {subtext && (
        <Text size='medium' color='light' textAlign='center' css={subtextStyles}>
          {subtext}
        </Text>
      )}
    </div>
  );
};

const stepQuestionContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};
`;

const questionAndInfoContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing(1)};
`;

const subtextStyles = css`
  width: 75%;
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
`;
