import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { generateRandomValue, getAppointmentTypesForVertical } from './overview';

type LocationsComparisonStatsProps = {
  appointmentType?: CallIntelTypes.AppointmentTypeEnum;
  category?: CallIntelTypes.CategoryEnum;
  locations?: string[];
  sentiment?: CallIntelTypes.SentimentEnum;
  taskType?: CallIntelTypes.TaskTypeEnum;
};

const locationCount = 8;

export const dummyLocationNames = () => {
  const data: { [key: string]: string } = {};

  for (let i = 1; i <= locationCount; i++) {
    data[`demo-00${i}`] = `Location ${i}`;
  }

  return data;
};

const generateLocationAppointmentTypes = (
  vertical: Vertical | null,
  appointmentType?: CallIntelTypes.AppointmentTypeEnum
) => {
  if (appointmentType) {
    return {
      inferences: {
        [appointmentType]: generateRandomValue(5, 30),
      },
    };
  }

  const appointmentTypes = vertical
    ? getAppointmentTypesForVertical(vertical)
    : getAppointmentTypesForVertical(Vertical.INVALID_VERTICAL);

  return {
    inferences: Object.fromEntries(appointmentTypes.map((type) => [type, generateRandomValue(5, 30)])),
  };
};

const generateLocationCategories = (category?: CallIntelTypes.CategoryEnum) => {
  if (category) {
    return {
      inferences: {
        [category]: generateRandomValue(5, 30),
      },
    };
  }

  return {
    inferences: {
      [CallIntelTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(5, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(5, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(5, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_MEDICATION]: generateRandomValue(5, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_OTHER]: generateRandomValue(5, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(5, 30),
      [CallIntelTypes.CategoryEnum.CATEGORY_VENDOR]: generateRandomValue(5, 30),
    },
  };
};

const generateLocationSentiments = (sentiment?: CallIntelTypes.SentimentEnum) => {
  if (sentiment) {
    return {
      inferences: {
        [sentiment]: generateRandomValue(5, 30),
      },
    };
  }

  return {
    inferences: {
      [CallIntelTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(5, 30),
      [CallIntelTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(5, 30),
      [CallIntelTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(5, 30),
    },
  };
};

const generateLocationTaskTypes = (taskType?: CallIntelTypes.TaskTypeEnum) => {
  if (taskType) {
    return {
      inferences: {
        [taskType]: generateRandomValue(0, 5),
      },
    };
  }

  return {
    inferences: {
      [CallIntelTypes.TaskTypeEnum.TYPE_SCHEDULING]: generateRandomValue(0, 5),
      [CallIntelTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(0, 5),
      [CallIntelTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: generateRandomValue(0, 5),
      [CallIntelTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(0, 5),
      [CallIntelTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(0, 5),
      [CallIntelTypes.TaskTypeEnum.TYPE_OTHER]: generateRandomValue(0, 5),
    },
  };
};

const mapLocations = <T>(callback: () => T, locations?: string[]): CallIntelTypes.LocationStat<T> => {
  const data: CallIntelTypes.LocationStat<T> = {};

  for (let i = 1; i <= locationCount; i++) {
    if (locations && !locations.includes(`demo-00${i}`)) {
      continue;
    }

    data[`00${i}`] = {
      ...callback(),
    };
  }

  return data;
};

const generateOpportunities = (locationFilter?: string[]) => {
  const data: CallIntelTypes.LocationsStats['opportunitiesByLocation'] = {};

  for (let i = 1; i <= locationCount; i++) {
    if (locationFilter && !locationFilter.includes(`demo-00${i}`)) {
      continue;
    }

    data[`00${i}`] = {
      unscheduledOpportunities: generateRandomValue(5, 30),
      totalOpportunities: generateRandomValue(40, 60),
    };
  }

  return data;
};

export const locationsComparisonStats = ({
  locations,
  appointmentType,
  category,
  sentiment,
  taskType,
  vertical,
}: LocationsComparisonStatsProps & { vertical: Vertical | null }): CallIntelTypes.LocationsStats => ({
  appointmentTypesByLocation: mapLocations(
    () => generateLocationAppointmentTypes(vertical, appointmentType),
    locations
  ),
  categoriesByLocation: mapLocations(() => generateLocationCategories(category), locations),
  opportunitiesByLocation: generateOpportunities(locations),
  sentimentsByLocation: mapLocations(() => generateLocationSentiments(sentiment), locations),
  taskTypesByLocation: mapLocations(() => generateLocationTaskTypes(taskType), locations),
});
