import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { MultiStepDynamicRenderMethod } from '../../collect-payment-multi.types';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { DynamicStep } from '../atoms/dynamic-step';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';

export type SelectPaymentsTemplateProps = {
  renderMethod: MultiStepDynamicRenderMethod;
};
export const SelectPaymentsTemplate = ({ renderMethod }: SelectPaymentsTemplateProps) => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.selectPaymentsTemplate}>
      <SelectPaymentsTemplateBody renderMethod={renderMethod} />
    </StepPerformanceWrapper>
  );
};

const styles = {
  body: css`
    .step__body {
      padding: 0;
      margin: ${theme.spacing(3)} 0;
    }

    .wv-modal-flex-content {
      padding: ${theme.spacing(3)};
    }
  `,
};

const SelectPaymentsTemplateBody = ({ renderMethod }: SelectPaymentsTemplateProps) => {
  return (
    <DynamicStep
      id={PAYMENT_MULTI_STEPS.selectPaymentsTemplate}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.selectPaymentsTemplate]}
      components={{ Header: () => null }}
      renderMethod={renderMethod}
      css={styles.body}
    />
  );
};
