import { useCallback, useEffect, useMemo, useState } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PersonsV3 } from '@frontend/api-person';
import { PaymentsFeatureFlags, useMerchant, useMerchantsInfo, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useModalControl, useMultiStepControl } from '@frontend/design-system';
import { isPaidInvoice, makeLifecycleHooks } from '../utils';
import {
  CollectPaymentMultiActiveGateway,
  UseCollectPaymentMultiStepProps,
  UseCollectPaymentMultiProps,
  CollectPaymentMultiFlow,
  CollectPaymentMultiState,
} from './collect-payment-multi.types';
import { useInvoiceQuery } from './hooks/use-invoice-query';
import { getStepDataByFlow, isPaymentMethodStep, PAYMENT_MULTI_STEPS } from './utils/steps';

export const DEFAULT_MIN_WIDTH = 654;

export const useCollectPaymentMulti = <F extends CollectPaymentMultiFlow = 'create'>(
  collectProps: UseCollectPaymentMultiProps<F>,
  multiStepProps: UseCollectPaymentMultiStepProps = {}
): CollectPaymentMultiState<F> => {
  const [minWidth, setMinWidth] = useState(DEFAULT_MIN_WIDTH);

  const resetMinWidth = useCallback(() => {
    setMinWidth(DEFAULT_MIN_WIDTH);
  }, []);

  const {
    personId,
    invoiceId: initialInvoiceId,
    locationId: overrideLocationId,
    onPaymentSuccess: onPaymentSuccessCallback,
    lifecycle,
    onInvoiceCreated,
    onInvoiceSelected,
    onPaymentCompletedAsync,
    onLocationHasNoPayments,
    trackingId = 'payments:nwx:collect-payment-multi',
    ...rest
  } = collectProps || {};

  const initialFlow = rest.flow || 'create';

  const multiStep = useMultiStepControl({
    ...multiStepProps,
    stepper: {
      show: false,
      ...multiStepProps?.stepper,
    },
    onChangeStep: (step, move) => {
      if (typeof multiStepProps.onChangeStep === 'function') {
        const proceed = multiStepProps.onChangeStep(step, move);

        if (proceed === false) {
          return;
        }
      }

      if (step === PAYMENT_MULTI_STEPS.selectTerminal && move.movement === -1) {
        // allow redirect for register terminal > select terminal.
        // Do nothing
      } else if (isPaymentMethodStep(step) && move.movement < 0) {
        // going back from payment step, go to payment methods
        multiStep.goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
        return false;
      }

      return;
    },
    onCancel: () => {
      if (typeof multiStepProps.onCancel === 'function') {
        multiStepProps.onCancel();
      }

      /**
       * custom logic here
       */
    },

    onComplete: () => {
      if (typeof multiStepProps.onComplete === 'function') {
        multiStepProps.onComplete();
      }

      /**
       * custom logic here
       */
    },
  });

  const [activeInvoiceId, setActiveInvoiceId] = useState<string | null>(initialInvoiceId ?? null);
  const [flow, setFlow] = useState<CollectPaymentMultiFlow>(initialFlow);
  const [hasCompletedPayment, setHasCompletedPayment] = useState(false);

  const { locationId: selectedLocationId, allLocations } = useMultiQueryUtils();

  const activeLocationIds = useMemo(() => {
    if (overrideLocationId) {
      return [overrideLocationId];
    }

    return allLocations || [];
  }, [allLocations, overrideLocationId]);

  const { invoice, ...invoiceQuery } = useInvoiceQuery(activeInvoiceId, activeLocationIds);

  const activeLocationId = useMemo(() => {
    return invoice?.locationId || overrideLocationId || selectedLocationId;
  }, [overrideLocationId, selectedLocationId, invoice?.locationId, allLocations]);

  const { stripeLocationId, merchant, paymentsUrl, stripeCreatedNotOnboarded } = useMerchant({
    locationId: activeLocationId,
  });

  const paymentSetupModalControl = useModalControl();

  const { allMerchantsLoaded, anyLocationHasPayments } = useMerchantsInfo();

  const activePersonId = invoice?.person?.id || personId;

  const { aggregateValue: hasJustifyOnboardingEnabled } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.EnableJustifyOnboarding,
    locationIds: [activeLocationId],
  });

  const { data: person, ...personQuery } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: [activeLocationId], personId: activePersonId! },
    { enabled: !!activePersonId && !!activeLocationId }
  );

  const { aggregateValue: enableACHOnFileStripe } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [activeLocationId],
  });

  const { aggregateValue: newRequestUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: [activeLocationId],
  });

  const { aggregateValue: enableShareInEmail } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ShareInEmail,
    locationIds: [invoice?.locationId ?? ''],
  });

  const enableACHOnFileJustifi = false;

  const activeGateway: CollectPaymentMultiActiveGateway = useMemo(() => {
    if (hasJustifyOnboardingEnabled) {
      return 'justifi';
    }
    if (stripeLocationId) {
      return 'stripe';
    }

    // show "Stripe account not setup" errors.
    return 'stripe';
  }, [stripeLocationId, hasJustifyOnboardingEnabled]);

  const changeFlow = useCallback(
    (newFlow: CollectPaymentMultiFlow, goToStepName?: string) => {
      setFlow(newFlow);
      const steps = getStepDataByFlow(newFlow, multiStep.steps);

      multiStep.setSteps(steps);
      multiStep.setActiveStep(goToStepName ?? steps[0].id);
    },
    [initialInvoiceId, multiStep.steps]
  );

  const resetState = useCallback(() => {
    setActiveInvoiceId(initialInvoiceId ?? null);
    changeFlow(initialFlow);
  }, [initialInvoiceId, multiStep.steps]);

  useEffect(() => {
    changeFlow(initialFlow);
  }, [initialFlow]);

  useEffect(() => {
    setActiveInvoiceId(initialInvoiceId ?? null);
  }, [initialInvoiceId]);

  const renderSelectPaymentsTemplate =
    collectProps.flow === 'insert' ? collectProps.renderSelectPaymentsTemplate : undefined;

  const onPaymentSuccess = useCallback(() => {
    setHasCompletedPayment(true);

    if (typeof onPaymentSuccessCallback === 'function') {
      onPaymentSuccessCallback();
    }
  }, [onPaymentSuccessCallback]);

  const invoiceIsPaid = isPaidInvoice(invoice);

  const { aggregateValue: showMultiProcessorCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.UseMultiProcessorCollect,
    locationIds: [activeLocationId],
  });

  const lifecycleHooks = useMemo(() => {
    return makeLifecycleHooks(lifecycle);
  }, [lifecycle]);

  useEffect(() => {
    if (!showMultiProcessorCollect) {
      /**
       * Prevent errors if hook is being invoked without being active
       */
      return;
    }
    if (invoiceIsPaid && !hasCompletedPayment) {
      if (typeof onPaymentCompletedAsync == 'function') {
        setHasCompletedPayment(true);
        onPaymentCompletedAsync(invoice);
      } else {
        if (multiStep.steps.length > 0) {
          if (flow === 'insert') {
            multiStep.goToStep({ orIndex: 0 });
          } else if (multiStep.activeStep !== PAYMENT_MULTI_STEPS.paymentMethods) {
            multiStep.goToStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods });
          }
        }
        onPaymentSuccess();
      }
    }
  }, [invoiceIsPaid]);

  return {
    // direct callback passthrough
    ...rest,
    onInvoiceCreated,
    onInvoiceSelected,
    renderSelectPaymentsTemplate,
    onLocationHasNoPayments,

    // wrapped callbacks
    onPaymentSuccess,

    // // behind the scenes methods,
    resetState,

    // // direct prop passthrough
    trackingId,
    multiStep,

    // // controlled state
    activeInvoiceId,
    setActiveInvoiceId,
    flow,
    setFlowAndStep: changeFlow,
    minWidth,
    setMinWidth,
    resetMinWidth,

    // derived state
    invoice,
    invoiceQuery,
    invoiceIsPaid,
    hasCompletedPayment,

    activeGateway,
    activePersonId,
    person,
    personQuery,
    activeLocationIds,
    activeLocationId,
    merchant,
    newRequestUI,
    paymentsUrl,
    allMerchantsLoaded,
    anyLocationHasPayments,
    paymentSetupModalControl,
    stripeData: {
      stripeLocationId,
      enableACHOnFile: enableACHOnFileStripe,
      enableShareInEmail,
      stripeCreatedNotOnboarded,
    },
    justifiData: {
      enableACHOnFile: enableACHOnFileJustifi,
      hasOnboardingEnabled: hasJustifyOnboardingEnabled,
      enableShareInEmail,
    },
    lifecycleHooks,
  };
};
