import { useCallback, useEffect, useMemo, useState } from 'react';
import { MappingType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/mapping.pb';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { FormRow, useDebouncedValue, useFormField } from '@frontend/design-system';
import { useGetAppointmentTypeInfiniteListForV3 } from '../../hooks';
import {
  CustomMultiSelectInfiniteDropdown,
  CustomMultiSelectInfiniteDropdownOptionType,
} from '../CustomMultiSelectInfiniteDropdown';

interface Props {
  locationId: string;
  providerId: string;
}

export const useAppointmentTypeCheckListDropdown = ({ locationId, providerId }: Props) => {
  const { t } = useTranslation('schedule');
  const [selectedItems, setSelectedItems] = useState<CustomMultiSelectInfiniteDropdownOptionType[]>([]);
  const searchFieldProps = useFormField({ type: 'text' });
  const searchText = useDebouncedValue(searchFieldProps.value);
  const appointmentTypeInfiniteListQuery = useGetAppointmentTypeInfiniteListForV3({
    locationId,
    searchText: searchText.length > 1 ? searchText : '',
  });
  const [hasError, setHasError] = useState(false);

  const listMappingsQuery = SchedulerV3.Queries.useListMappingsQuery({
    request: {
      locationIds: [locationId],
      providerId,
      mappingType: MappingType.PROVIDER_APPOINTMENT_TYPE,
    },
    options: {
      enabled: !!locationId && !!providerId,
    },
  });

  const initialSelectedItems = useMemo(
    () =>
      (listMappingsQuery.data?.mappings ?? []).reduce<CustomMultiSelectInfiniteDropdownOptionType[]>((acc, item) => {
        if (item.appointmentTypeMetaData) {
          acc.push({
            label: item.appointmentTypeMetaData.name,
            value: item.appointmentTypeMetaData.id,
          });
        }
        return acc;
      }, []),
    [listMappingsQuery.data?.mappings]
  );

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  // hide dropdown if no appointment types loaded and no search text
  const shouldHideDropdown = !searchText && appointmentTypeInfiniteListQuery.data?.pages[0].rows.length === 0;
  return {
    hasAppointmentTypeCheckListDropdownError: hasError,
    getAppointmentTypeSelectedUnselectedValues: useCallback(() => {
      const selectedValues = selectedItems.map((item) => item.value);
      const initialValues = initialSelectedItems.map((item) => item.value);

      const newSelectedAppointmentTypeIds = selectedValues.filter((value) => !initialValues.includes(value));
      const unSelectedAppointmentTypeIds = initialValues.filter((value) => !selectedValues.includes(value));
      const mappingIdsForDelete = (listMappingsQuery.data?.mappings ?? [])
        .filter((mapping) => unSelectedAppointmentTypeIds.includes(mapping.appointmentTypeMetaData?.id ?? ''))
        .map((mapping) => mapping.id);
      return {
        newSelectedAppointmentTypeIds,
        mappingIdsForDelete,
      };
    }, [selectedItems]),
    AppointmentTypeCheckListDropdownElement: !shouldHideDropdown && (
      <FormRow>
        <CustomMultiSelectInfiniteDropdown
          label={t('Appointment Types')}
          placeholder={t('Select appointment types')}
          searchFieldProps={searchFieldProps}
          infiniteQueryResult={appointmentTypeInfiniteListQuery}
          getOptionInfo={(appointment) => ({
            label: appointment?.displayName || appointment?.externalName || '',
            value: appointment?.id || '',
          })}
          setHasError={setHasError}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          isLoading={listMappingsQuery.isFetching}
        />
      </FormRow>
    ),
  };
};
