import { useState, useEffect } from 'react';
import {
  useDigitalFormsWritebackCapabilitiesContext,
  useDigitalFormsPreferredSourceTenantsContext,
  useDigitalFormsSourceTenantsContext,
  useDigitalFormsLocationsContext,
} from '../../providers';

interface UseMedicalHistoryAccessResults {
  hasMedicalHistoryAccess: boolean;
}

export const useMedicalHistoryAccessV2 = (): UseMedicalHistoryAccessResults => {
  const [hasMedicalHistoryAccess, setHasMedicalHistoryAccess] = useState(false);
  const { validFormsLocations, isValidatingLocations } = useDigitalFormsLocationsContext();
  const { isLoadingWritebackCapabilities, getSourceCapabilities } = useDigitalFormsWritebackCapabilitiesContext();
  const { arePreferredSourceTenantIdsReady } = useDigitalFormsPreferredSourceTenantsContext();
  const { isLoadingSourceTenants, getSupportedSourceTenantsForLocation } = useDigitalFormsSourceTenantsContext();

  useEffect(() => {
    if (
      isValidatingLocations ||
      isLoadingSourceTenants ||
      isLoadingWritebackCapabilities ||
      !arePreferredSourceTenantIdsReady
    ) {
      return;
    }

    for (const locationId of validFormsLocations) {
      const supportedSourceTenants = getSupportedSourceTenantsForLocation(locationId);

      // If there is at least one supported source tenant, then the user has access to medical history fields.
      for (const source of supportedSourceTenants) {
        const { showMedicalConditionPrimaryFields } = getSourceCapabilities(locationId, source.id);
        if (showMedicalConditionPrimaryFields) {
          setHasMedicalHistoryAccess(showMedicalConditionPrimaryFields);
          return;
        }
      }
    }

    setHasMedicalHistoryAccess(false);
  }, [
    isLoadingSourceTenants,
    isLoadingWritebackCapabilities,
    arePreferredSourceTenantIdsReady,
    getSupportedSourceTenantsForLocation,
    getSourceCapabilities,
    isValidatingLocations,
  ]);

  return {
    hasMedicalHistoryAccess,
  };
};
