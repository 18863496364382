import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DigitalFormsQueries as queryEndpointKeys } from '@frontend/api-digital-forms';
import { FormsMessaging } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useSendFormsStore, useSendFormsContext } from '../providers';
import { convertToHTML } from './utils/emailHelper';

type SendDocumentFn = () => Promise<void>;

export interface useSendDocumentResult {
  sendDocument: SendDocumentFn;
}

export const useSendDocument = (): useSendDocumentResult => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const { firstName, clearStore, getEmailPayload } = useSendFormsStore(['firstName', 'clearStore', 'getEmailPayload']);

  const queryClient = useQueryClient();

  const { mutateAsync: sendEmailAsync } = useMutation(FormsMessaging.API.sendEmail, {
    onSuccess: () => {
      alert.success(t('Forms sent to {{firstName}} successfully', { firstName }));
      sendFormsModalControls.closeModal();
      queryClient.invalidateQueries(queryEndpointKeys.endpointKeys.submissionDetailsForPerson);
      clearStore(origin);
    },
    onError: (error) => {
      console.error('Failed to send email', error);
      alert.error(t('Failed to send email'));
    },
  });

  const sendDocument = useCallback<SendDocumentFn>(async () => {
    const { email, emailBody, emailSubject } = getEmailPayload();
    if (email.trim() === '') {
      return;
    }

    sendEmailAsync({
      recipient: email,
      subject: emailSubject,
      html: convertToHTML(emailBody),
    });
  }, [sendEmailAsync, getEmailPayload]);

  return { sendDocument };
};
