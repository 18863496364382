import { http } from '@frontend/fetch';
import { SyncAppAuthCheckData, SyncAppAuthCheckRequest, SyncAppAuthCheckResponse } from './types';

const SyncAppEndpoints = {
  authCheck: 'desktop/v1/syncapp/tool/check/',
};
export const fetchSyncAppAuthCheck = async (request: SyncAppAuthCheckRequest): Promise<SyncAppAuthCheckData> => {
  const { data } = await http.get<SyncAppAuthCheckResponse>(SyncAppEndpoints.authCheck, {
    headers: {
      'Location-ID': request.locationId,
    },
  });

  return data;
};
