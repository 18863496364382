import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { QuickFilterOptions } from './DateFilters/quick-filters';

export type StatusTabsProps = {
  selectedStatus: QuickFilterOptions;
  onChange: (selectedStatus: QuickFilterOptions) => void;
};

const styles = {
  statusTabs: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  tab: css`
    font-size: ${theme.font.size.medium};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `,
};

const baseTrackingId = 'pay-portal-invoices--status-tabs--';

export const StatusTabs = ({ selectedStatus, onChange }: StatusTabsProps) => {
  const { t } = useTranslation('payments');
  const handleOnChange = (id: string) => {
    onChange(id as QuickFilterOptions);
  };
  return (
    <Tabs controlledTab={selectedStatus} onChange={handleOnChange}>
      <Tabs.Bar fullWidth={false} css={styles.statusTabs}>
        <Tabs.Tab trackingId={`${baseTrackingId}--allInvoices`} css={styles.tab} id='allInvoices'>
          {t('All Requests')}
        </Tabs.Tab>
        <Tabs.Tab trackingId={`${baseTrackingId}--paid`} css={styles.tab} id='paid'>
          {t('Paid')}
        </Tabs.Tab>
        <Tabs.Tab trackingId={`${baseTrackingId}--unpaid`} css={styles.tab} id='unpaid'>
          {t('Unpaid')}
        </Tabs.Tab>
        <Tabs.Tab trackingId={`${baseTrackingId}--refunded`} css={styles.tab} id='refunded'>
          {t('Refunds')}
        </Tabs.Tab>
        <Tabs.Tab trackingId={`${baseTrackingId}--failed`} css={styles.tab} id='failed'>
          {t('Failed')}
        </Tabs.Tab>
      </Tabs.Bar>
    </Tabs>
  );
};
