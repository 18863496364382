import { EntityType } from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { BulkMessagesQueries, MessagesFeatureFlags } from '@frontend/api-messaging';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { isBulkTextingSupportedInCountry } from '../utils';
import { useCheckTollFreePhoneNumber } from '.';

export const useHasBulkTexting = (): boolean => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isBulkTextingFeatureFlagEnabled = getFeatureFlagValue(MessagesFeatureFlags.bulkMessages);

  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const { data: tcrBrandData } = BulkMessagesQueries.useLocationTCRBrand(locationId);
  const isSupportedCountry = isBulkTextingSupportedInCountry(tcrBrandData?.country);
  const isProduction = localStorage.getItem('backendEnv') === 'prod';

  // sole proprietor is not supported for bulk texting
  const isSoleProprietor = tcrBrandData?.entityType === EntityType.ENTITY_TYPE_SOLE_PROPRIETOR;
  const entityTypeIsValid = !isSoleProprietor || (isSoleProprietor && !isProduction);

  // customer's with toll free phone numbers as their default texting number are not able to register these
  // but they can use these toll free phone numbers with bulk messaging
  const isTollFreePhoneNumber = useCheckTollFreePhoneNumber();

  const hasBulkTexting =
    isBulkTextingFeatureFlagEnabled && isSupportedCountry && (entityTypeIsValid || isTollFreePhoneNumber);

  return hasBulkTexting;
};
