import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, useAlert } from '@frontend/design-system';

type Props = {
  isDemoAccount?: boolean;
  personId: string;
  personName?: string;
  phoneNumber: string;
  trackingIdBase?: string;
};

const RealTableActions = memo(({ personId, personName, phoneNumber, trackingIdBase = 'actions' }: Props) => {
  const { t } = useTranslation('analytics');

  const { Modal: PhoneCallModal, triggerProps: callTriggerProps } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId,
      phoneNumber,
    },
  });

  return (
    <>
      <div css={styles.actions}>
        <Button
          {...callTriggerProps}
          iconName='phone'
          label={t('Call {{name}}', {
            name: personName || phoneNumber,
          })}
          trackingId={`${trackingIdBase}-call`}
          variant='secondary'
        />
        <ActionsUI.ContactActions.Message
          context={{
            personId,
            personPhone: phoneNumber,
          }}
        />
      </div>
      {PhoneCallModal}
    </>
  );
});

RealTableActions.displayName = 'RealTableActions';

const DemoTableActions = memo(({ personName, phoneNumber, trackingIdBase = 'actions' }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const handleClick = useCallback(() => {
    alert.warning(t('This action is not available in a demo account.'));
  }, []);

  return (
    <div css={styles.actions}>
      <Button
        iconName='phone'
        label={t('Call {{name}}', {
          name: personName || phoneNumber,
        })}
        onClick={handleClick}
        trackingId={`${trackingIdBase}-call`}
        variant='secondary'
      />

      <Button
        iconName='messaging'
        label={t('Message {{name}}', {
          name: personName || phoneNumber,
        })}
        onClick={handleClick}
        trackingId={`${trackingIdBase}-message`}
        variant='secondary'
      />
    </div>
  );
});

DemoTableActions.displayName = 'DemoTableActions';

export const TableActions = ({ isDemoAccount, ...rest }: Props) => {
  const Component = isDemoAccount ? DemoTableActions : RealTableActions;

  return <Component {...rest} />;
};

const styles = {
  actions: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    justify-content: flex-start;

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      justify-content: flex-end;
    }

    > button {
      background-color: transparent;
      border-radius: ${theme.borderRadius.small};
      border: none;
      height: ${theme.spacing(5)};
      min-width: ${theme.spacing(5)};
      width: ${theme.spacing(5)};

      &:hover {
        background-color: ${theme.colors.neutral10} !important;
      }

      svg {
        height: ${theme.spacing(3)};
        width: ${theme.spacing(3)};
      }
    }
  `,
};
