import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { GenericFieldProps } from '../../../types';
import { dropdownSectionTypeStyling, InputDropdownContent, labelStyling, Setting } from '.';

type PaddingSettingItemProps = GenericFieldProps & {
  heading: string;
  options: string[];
};

const PaddingSettingItem = ({ heading, ...fieldProps }: PaddingSettingItemProps) => {
  return (
    <div
      css={[
        dropdownSectionTypeStyling,
        css`
          &:last-of-type {
            margin-bottom: 0;
          }
          margin-bottom: ${theme.spacing(2)};
        `,
      ]}
    >
      <Text css={labelStyling}>{heading}</Text>
      <InputDropdownContent {...fieldProps} />
    </div>
  );
};

const defaultPaddingOptions = [theme.spacing(2), theme.spacing(3), theme.spacing(4), theme.spacing(10)];
const defaultSpacingOptions = [theme.spacing(1), theme.spacing(2), theme.spacing(3), theme.spacing(4)];

type PaddingSettingProps = {
  imageSpacingFieldProps?: GenericFieldProps;
  isLast?: boolean;
  paddingBottomFieldProps?: GenericFieldProps;
  paddingLeftFieldProps?: GenericFieldProps;
  paddingOptions?: string[];
  paddingRightFieldProps?: GenericFieldProps;
  paddingTopFieldProps?: GenericFieldProps;
  spacingOptions?: string[];
};

export const PaddingSetting = ({
  imageSpacingFieldProps,
  isLast,
  paddingBottomFieldProps,
  paddingLeftFieldProps,
  paddingOptions = defaultPaddingOptions,
  paddingRightFieldProps,
  paddingTopFieldProps,
  spacingOptions = defaultSpacingOptions,
}: PaddingSettingProps) => {
  const { t } = useTranslation('content-composer-settings');

  return (
    <Setting title={t('Padding')} isLast={isLast}>
      {paddingTopFieldProps && (
        <PaddingSettingItem {...paddingTopFieldProps} heading={t('Top')} options={paddingOptions} />
      )}
      {paddingBottomFieldProps && (
        <PaddingSettingItem {...paddingBottomFieldProps} heading={t('Bottom')} options={paddingOptions} />
      )}
      {paddingLeftFieldProps && (
        <PaddingSettingItem {...paddingLeftFieldProps} heading={t('Left')} options={paddingOptions} />
      )}
      {paddingRightFieldProps && (
        <PaddingSettingItem {...paddingRightFieldProps} heading={t('Right')} options={paddingOptions} />
      )}
      {imageSpacingFieldProps && (
        <PaddingSettingItem {...imageSpacingFieldProps} heading={t('Image Spacing')} options={spacingOptions} />
      )}
    </Setting>
  );
};
