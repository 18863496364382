import isObject from 'lodash-es/isObject';
import { useMutation } from 'react-query';
import { createStripeAccount, getStripeOnboardingLink } from '@frontend/api-payments';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils, usePaymentsBillingManagerLocations } from '@frontend/payments-hooks';
import { ModalControlModalProps, useAlert, useModalControl } from '@frontend/design-system';
import { CreationCheckModal } from './creation-check-modal';
import { TermsAndConditionsModal } from './legal-modal';
import { RoleRequiredModal } from './role-required-modal';

const stripeRedirectUrl = `${window.location.origin}/payments/invoices#settings/payments/account-info?stripe_redirected=1`;
type CreateAndTermsConditionModalProps = {
  businessType: string;
  creationCheckModalProps: ModalControlModalProps;
  refetchQueries?: boolean;
};
export const CreateAndTermsConditionModal = ({
  businessType,
  creationCheckModalProps,
  refetchQueries,
}: CreateAndTermsConditionModalProps) => {
  const { t } = useTranslation('payments');
  const alert = useAlert();

  const { activeProcessor, paymentsUrl, refetchAll } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const { isPaymentsBillingManager } = usePaymentsBillingManagerLocations({ locationId });

  const {
    modalProps: legalTermsModalProps,
    triggerProps: legalTermsTriggerProps,
    closeModal: legalTermsCloseModal,
  } = useModalControl({
    disableReturnFocus: true,
  });
  const { onClick: openLegalModal } = legalTermsTriggerProps;

  const { modalProps: roleRequiredModalProps, triggerProps: roleRequiredTriggerProps } = useModalControl({
    disableReturnFocus: true,
  });

  const redirectToStripe = ({
    onboardingUrl,
    setLoading,
  }: {
    onboardingUrl: string;
    setLoading: (loading: boolean) => void;
  }) => {
    window.open(onboardingUrl, '_blank');
    setLoading(false);
    legalTermsCloseModal();
  };

  const { mutate: createStripeAccountMutation } = useMutation(
    ({ paymentsUrl }: { paymentsUrl: string }) =>
      createStripeAccount(paymentsUrl, businessType, false, locationId)
        .then((res) =>
          getStripeOnboardingLink(paymentsUrl, res.stripeId, stripeRedirectUrl, stripeRedirectUrl, locationId)
        )
        .then((response) => response),
    { retry: false }
  );
  const { mutate: continueOnboardingMutation } = useMutation(
    ({ paymentsUrl, stripeId }: { paymentsUrl: string; stripeId: string | undefined }) =>
      getStripeOnboardingLink(paymentsUrl, stripeId, stripeRedirectUrl, stripeRedirectUrl, locationId).then(
        (response) => response
      ),
    { retry: false }
  );

  const handleCreationCheckModal = () => {
    creationCheckModalProps.onClose();
    openLegalModal();
  };

  const handleCreateAccount = ({ setLoading }: { setLoading: (loading: boolean) => void }) => {
    if (isPaymentsBillingManager) {
      setLoading(true);
      const onSuccess = ({ next: onboardingUrl }: { next: string }) => {
        redirectToStripe({ onboardingUrl, setLoading });
        if (refetchQueries) refetchAll();
      };
      const onError = (e: unknown) => {
        let error = t('Failed to redirect to Stripe. Please try again.');
        if (isObject(e) && 'data' in e) {
          const data = (e as { data: { error?: string; message?: string } }).data;
          error = data.error || data.message?.replaceAll('_', ' ') || error;
        }
        alert.error(error);
        legalTermsCloseModal();
      };

      if (!paymentsUrl) {
        alert.error(t('Payments URL is missing.'));
        legalTermsCloseModal();
        return;
      }
      if (!activeProcessor?.stripeId) {
        createStripeAccountMutation({ paymentsUrl }, { onSuccess, onError });
      } else {
        continueOnboardingMutation({ paymentsUrl, stripeId: activeProcessor?.stripeId }, { onSuccess, onError });
      }
    } else {
      roleRequiredTriggerProps.onClick();
    }
  };

  return (
    <>
      <CreationCheckModal onAccept={handleCreationCheckModal} modalProps={creationCheckModalProps} />
      <TermsAndConditionsModal onAccept={handleCreateAccount} modalProps={legalTermsModalProps} />
      <RoleRequiredModal roleRequiredModalProps={roleRequiredModalProps} />
    </>
  );
};
