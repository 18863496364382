import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyDigitalFormsChecklistSvg = lazy(() => import('./svg-components/digital-forms-checklist-svg'));

const DigitalFormsChecklistSvg = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyDigitalFormsChecklistSvg {...rest} />
  </Suspense>
);

export default DigitalFormsChecklistSvg;
