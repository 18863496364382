import { theme } from '@frontend/theme';
import { ContextPreviewProps } from '../common';
import { EmailArea, Header } from './components';
import { useEmailStates } from './hooks/useEmailStates';

type EmailViewProps = {
  states: ReturnType<typeof useEmailStates>;
  locationId: string;
  contextPreviewProps?: ContextPreviewProps;
};
export const EmailView = ({ states, locationId, contextPreviewProps }: EmailViewProps) => {
  const { getFieldProps, person, setAttachments, toField, setToField } = states;

  return (
    <div
      css={{
        height: 'min-content',
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      {person && <Header person={person} contextPreviewProps={contextPreviewProps} />}
      <EmailArea
        person={person}
        locationId={locationId}
        getFieldProps={getFieldProps}
        setAttachments={setAttachments}
        toField={toField}
        setToField={setToField}
      />
    </div>
  );
};
