import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { CommonHTMLAttributes } from '@frontend/types';

type Props = CommonHTMLAttributes & {
  children: ReactNode;
};
export const PageBody = ({ children, ...props }: Props) => {
  return (
    <div
      {...props}
      css={css`
        flex: 1;
      `}
    >
      {children}
    </div>
  );
};
