import { getLocationData } from '@frontend/api-location';
import { PortalUser, setLoginData } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';

export const locationRedirect = async (user?: PortalUser) => {
  const targetRedirectLocation = localStorage.getItem(LOCATION_REDIRECT_STORAGE_KEY);
  const locationInfo = await (targetRedirectLocation
    ? getLocationData({ locationId: targetRedirectLocation }).catch((e) => {
        console.error(e);
      })
    : undefined);

  if (targetRedirectLocation && locationInfo) {
    const userID = user?.userID ?? '*';
    http.setLocationIdHeader(targetRedirectLocation);

    console.info(`Setting location id to ${targetRedirectLocation} from url for user: ${userID}`);
    setLoginData(userID, {
      lastLocationId: targetRedirectLocation,
      lastLocationIds: [targetRedirectLocation],
      recentOrganizationId: locationInfo.orgId,
    });
    if (userID !== '*') {
      localStorage.removeItem(LOCATION_REDIRECT_STORAGE_KEY);
    }
  }
};

export const LOCATION_REDIRECT_STORAGE_KEY = 'targetRedirectLocation';
