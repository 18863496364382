import { useRef, useState } from 'react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { EmailView, useEmailStates } from '@frontend/integrated-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { Text, formatDate, useAlert } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { ShareInEmailStepBodyProps } from './share-in-email.types';

export const ShareInEmailBody = ({ onGoBack, onClickPrimary, onCompleteStep }: ShareInEmailStepBodyProps) => {
  const { invoice } = useCollectPaymentMultiContext();

  const alert = useAlert();
  const { t } = useTranslation('payments-share-in-email');
  const [isSending, setIsSending] = useState(false);
  const { getLocationName } = useAppScopeStore();
  const goBackOnSubmit = useRef(false);

  const templateType = TemplateType_Slug.MANUAL_PAYMENT_REQUEST;

  const emailStates = useEmailStates({
    locationId: invoice?.locationId ?? '',
    templateType,
    linkData: {
      link: invoice?.links.payment ?? '',
      relatedId: invoice?.id ?? '',
    },
    personId: invoice?.person.id ?? '',
    onSendSuccess: () => {
      if (goBackOnSubmit.current) {
        onGoBack();
      } else {
        onCompleteStep();
      }

      alert.success('Email sent successfully');
      setIsSending(false);
    },
    onSendError: () => {
      setIsSending(false);
    },
    initialValues: {
      subject: t('Balance due for {{name}}', { name: invoice ? getLocationName(invoice.locationId) : '' }),
    },
  });

  const { primaryTrackingId, secondaryTrackingId, backTrackingId } = useScopedTrackingIds({
    primaryTrackingId: `${PAYMENT_MULTI_STEPS.shareInEmail}--send-now-btn`,
    secondaryTrackingId: `${PAYMENT_MULTI_STEPS.shareInEmail}--send-and-select-another-btn`,
    backTrackingId: `${PAYMENT_MULTI_STEPS.shareInEmail}--back-btn`,
  });

  if (!invoice) return null;

  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.shareInEmail}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.shareInEmail]}
      footer={{
        onClickPrimary: onClickPrimary,
        onClickBack: onGoBack,
        primary: {
          children: t('Send Now'),
          onClick: (e) => {
            if (!e) return;
            setIsSending(true);
            emailStates.formProps.onSubmit(e);
          },
          disabled: isSending,
          trackingId: primaryTrackingId,
        },
        secondary: {
          children: t('Send & Select Another Delivery Method'),
          onClick: (e) => {
            if (!e) return;
            setIsSending(true);
            goBackOnSubmit.current = true;
            emailStates.formProps.onSubmit(e);
          },
          disabled: isSending,
          trackingId: secondaryTrackingId,
        },
        back: {
          children: t('Back'),
          trackingId: backTrackingId,
        },
      }}
    >
      <form {...emailStates.formProps}>
        <EmailView
          locationId={invoice.locationId}
          states={emailStates}
          contextPreviewProps={{
            iconName: 'dollar-sign',
            title: <Text weight='bold'>{formatCentsToCurrency(invoice?.billedAmount)}</Text>,
            subtitle: (
              <Text>
                {t('Due Date')}:{' '}
                <Text as='span' color='subdued'>
                  {formatDate(invoice?.billedAt, 'MMM D, YYYY')}
                </Text>
              </Text>
            ),
          }}
        />
      </form>
    </StyledStep>
  );
};
