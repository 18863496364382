import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { InfinitePaginatedList, PaginatedListRefType } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useFormField, useDebouncedValue, SearchField } from '@frontend/design-system';
import { useQuickFillCustomEventListener } from '../../../hooks/use-quick-fill-custom-events';
import { QUICK_FILL_TRACKING_IDS } from '../../../tracking-ids';
import { AddToListButton } from '../components/add-to-list-button';
import { EmptyListIllustration } from '../components/empty-list-illustration';
import { RecipientTally } from '../components/recipient-tally';
import { SortByEnum } from '../components/sort-dropdown';
import { SpinnerLoader } from '../components/spinning-loader';
import { useQuickFillRecipients } from '../hooks';
import { useQuickFillRecipientShallowStore } from '../store';
import { QuickFillListItem } from './list-item';

type Props = {
  locationId: string;
  hasAccordionWrapper?: boolean;
  height?: number | string;
};

const HEADER_FOOTER_HEIGHT = 64 + 72; // 64px for top bar, 72px for add to list button

const trackingIds = QUICK_FILL_TRACKING_IDS.manageListTab;

export const QuickFillList = ({ height, locationId, hasAccordionWrapper = false }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const paginatedListRef = useRef<PaginatedListRefType>(null);
  const searchFieldProps = useFormField({ type: 'text', value: '' });
  const searchTerm = useDebouncedValue(searchFieldProps.value);
  const newPersonIdRef = useRef<string>();

  useQuickFillCustomEventListener('quick-fill:person-added', ({ personId }) => {
    newPersonIdRef.current = personId;
  });

  const infiniteQuery = useQuickFillRecipients({
    locationId,
    searchTerm,
    sortByValue: SortByEnum.DateDesc,
  });

  const { recipientCounts } = useQuickFillRecipientShallowStore('recipientCounts');

  const { filteredCount, totalCount } = recipientCounts[locationId] || {};

  const isEmptyList = infiniteQuery.data?.pages[0].rows?.length === 0;
  const firstPersonId = infiniteQuery.data?.pages[0].rows?.[0]?.personId;

  useEffect(() => {
    // scroll to top of list when new person is added to list
    if (newPersonIdRef.current === firstPersonId) {
      paginatedListRef.current?.scrollToTop();
    }
  }, [firstPersonId]);

  // Only show loader for component for initial list load, not for searched or subsequent loads
  if (infiniteQuery.isLoading && isEmptyList && !searchTerm) {
    return <SpinnerLoader height={hasAccordionWrapper ? 260 : '100%'} />;
  }

  return (
    <>
      {isEmptyList && !searchTerm ? (
        <EmptyListIllustration imageSize={336} />
      ) : (
        <>
          <div css={topBarContainerStyle}>
            {/* for accordion, this tally will be shown in the accordion header */}
            {!hasAccordionWrapper && <RecipientTally filteredCount={filteredCount} totalCount={totalCount} />}
            <SearchField
              name='recipient-search'
              placeholder={t('Search')}
              clearable={false}
              containerCss={css({ flex: 1 })}
              data-trackingid={trackingIds.searchField}
              {...searchFieldProps}
            />
          </div>
          <InfinitePaginatedList
            height={typeof height === 'number' ? height - HEADER_FOOTER_HEIGHT : height}
            paginatedListRef={paginatedListRef}
            emptyStateConfig={{
              header: t('No Quick Fill recipients found'),
              disableGraphic: true,
              textProps: { color: 'subdued' },
            }}
            infiniteQueryProps={infiniteQuery}
            renderListItem={({ listItem }) => (
              <QuickFillListItem
                key={listItem.personId}
                recipient={listItem}
                locationId={locationId}
                highlightItem={listItem.personId === newPersonIdRef.current}
                onHighlightItemComplete={() => (newPersonIdRef.current = undefined)}
              />
            )}
          />
        </>
      )}
      <AddToListButton showSeparator locationId={locationId} position={isEmptyList ? 'center' : 'left'} />
    </>
  );
};

const topBarContainerStyle = css({
  padding: theme.spacing(2, 3, 2, 2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
});
