import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { ShowMoreButton } from '../../components';

interface Props extends PropsWithChildren {
  onShowMoreClick?: () => void;
  showMoreLabel?: string;
  trackingIdBase?: string;
}

export const ARChartsBase = ({ children, onShowMoreClick, showMoreLabel, trackingIdBase }: Props) => {
  return (
    <>
      <div css={styles.chartWrapper}>{children}</div>
      {!!onShowMoreClick && (
        <ShowMoreButton label={showMoreLabel} onClick={onShowMoreClick} trackingIdBase={trackingIdBase} />
      )}
    </>
  );
};

const styles = {
  chartWrapper: css`
    display: flex;
    flex-direction: column;
    min-width: 242px;
    position: relative;
    flex-grow: 1;
  `,

  showMoreWrapper: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > button > span {
      flex-direction: row-reverse;
    }
  `,
};
