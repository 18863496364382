import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { messagingAnalyticsDemoData } from '../demo-data';
import { useIsMessagingAnalyticsDemoAccount } from './use-is-messaging-analytics-demo-account';

export const useMessagingAnalyticsDemoData = () => {
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const isDemoAccount = useIsMessagingAnalyticsDemoAccount();

  const demoData = useMemo(() => {
    if (typeof isDemoAccount === 'undefined' || !isDemoAccount) {
      return null;
    }

    const locationsData = accessibleLocationData;

    return {
      chartsData: {
        incomingVsOutgoingMessages: messagingAnalyticsDemoData.charts.generateData(selectedLocationIds, [
          'Inbound',
          'Outbound',
        ]),
        messageCategories: messagingAnalyticsDemoData.charts.generateData(selectedLocationIds, [
          'appointments-reminders',
          'manual-messages',
          'missed-call',
          'product-ready',
          'recall-text',
          'review-requests',
          'save-the-date',
        ]),
        patientsVsUnknownMessages: messagingAnalyticsDemoData.charts.generateData(selectedLocationIds, [
          'current_patient',
          'unknown',
        ]),
        smsVsMms: messagingAnalyticsDemoData.charts.generateData(selectedLocationIds, ['SMS', 'MMS']),
      },

      tableData: messagingAnalyticsDemoData.table.data(selectedLocationIds, locationsData),
    };
  }, [selectedLocationIds.sort().join('-'), isDemoAccount]);

  return demoData;
};
