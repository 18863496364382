import {
  PortingData,
  PortingStatusInfo,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { NumberType, PortStatus } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { addDays, format, nextTuesday, startOfMonth, subDays } from 'date-fns';
import { getBankHolidays, getChristmas, getGoodFriday, getNewYearsEve, getThanksgiving } from 'date-fns-holiday-us';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import { PortingApi, PortingTypes } from '@frontend/api-porting';
import { formatDate } from '@frontend/date';
import { http } from '@frontend/fetch';
import { CountryCodes } from '@frontend/geography';
import { ChipVariants, DatePickerFieldProps } from '@frontend/design-system';
import { NumberInfo, PhoneBillInfo, PortingStatusTrackerInfo, StorePortOrderType, UpcomingPortRequest } from '../types';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

// format date to UTC string for API request (e.g., from "09/01/2012" to 2021-09-01T00:00:00.000Z)
export const getUTCDateStringForRequest = (date: string, format = 'MM/DD/YYYY'): string =>
  dayjs.utc(date, format).toISOString();

const alphaNumericRegex = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/;
const numberRegex = /^[0-9]*$/;

const fullNameRegex = /^[A-Za-z]{3,16}([\s][A-Za-z]{3,16}){1,2}$/; //accepts first name optional middle name and last name
export const splitAndTrimString = (phoneNumbers = '', deliminator = ''): string[] =>
  phoneNumbers
    .split(deliminator)
    .filter((num) => num?.trim())
    .map((num) => num?.trim());

const phoneNumberRegex = /^[1-9]\d{9}$/;

export const getFormattedNumbers = (numbers: string): string =>
  numbers.replace(/\+1/g, '').replace(/[-()]/g, '').replace(/\s+/g, '');

export const validatePhoneNumbersRegex = (phoneNumbers: string): boolean => {
  for (const number of splitAndTrimString(phoneNumbers, ',')) {
    if (!phoneNumberRegex.test(number)) {
      return false;
    }
  }
  return true;
};

export const isAlphaNumericValue = (value: string): boolean => alphaNumericRegex.test(value);

export const isNumericValue = (value: string): boolean => numberRegex.test(value);

export const validateFullNameRegex = (name: string): boolean => fullNameRegex.test(name);

// Helper function to format phone numbers as (XXX) XXX-XXXX
const formatPhoneNumber = (phoneNumber: string): string => {
  // Remove any non-digit characters from the phone number
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  // Format the phone number as (XXX) XXX-XXXX
  const formattedNumber = digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return formattedNumber;
};

export const formattedVoiceNumberValues = (numbers: string[]) =>
  numbers?.map((phoneNumber) => formatPhoneNumber(phoneNumber));

export const formatNumbers = (numbers: string) => {
  // Format the phone number as XXXXXXXXXX to check for validation
  return numbers.replace(/[-() ]/g, '');
};

export const convertedToNumbers = (numbers: string[]) => numbers?.map((phoneNumber) => formatNumbers(phoneNumber));

export const formatVoiceNumber = (otherNumbers: string[] | undefined) => {
  if (!otherNumbers) {
    return []; // Return an empty array if otherNumbers is undefined
  }
  const formattedNumbers = otherNumbers.flatMap((number) => {
    const splitNumbers = number.split(',');
    return splitNumbers.map((splitNumber) => formatNumbers(splitNumber));
  });
  return formattedNumbers;
};

export const getPortRequestStatusChipColor = (status: PortingTypes.PortRequestStatus): ChipVariants => {
  switch (status) {
    case PortingTypes.PortRequestStatus.Accepted:
      return 'success';
    case PortingTypes.PortRequestStatus.DraftIncomplete:
    case PortingTypes.PortRequestStatus.Draft:
      return 'warn';
    case PortingTypes.PortRequestStatus.Processing:
      return 'primary';
    default:
      return 'neutral';
  }
};

const isEmptyDate = (date?: string) => !formatDate(date);
export const getSoonerUpcomingPortingRequest = (list: PortingStatusInfo[] = []): UpcomingPortRequest | undefined => {
  const listWithDiff = list.map((item) => ({
    ...item,
    diff: -dayjs().diff(item.acceptedFocDate, 'days'),
  }));

  const sortedList = listWithDiff.sort((a, b) => {
    if (a.diff >= 0 && b.diff >= 0) {
      return a.diff - b.diff; // Sort positive values in ascending order
    } else if (a.diff >= 0) {
      return -1; // a is positive, so it should come before b
    } else if (b.diff >= 0) {
      return 1; // b is positive, so it should come before a
    } else {
      return 0; // no need to sort when both values are negative
    }
  });

  const upcomingRequest = sortedList.find((item) => {
    if (item.diff >= 0) {
      // exclude draft status (ref: PortingApi.getValueForPortingStatus)
      return !(
        [
          PortStatus.PORT_STATUS_DRAFT,
          PortStatus.PORT_STATUS_DRAFT_INCOMPLETE,
          PortStatus.PORT_STATUS_VALIDATION_FAILED,
          PortStatus.PORT_STATUS_UNSPECIFIED,
        ].includes(item.portingStatus!) && isEmptyDate(item.requestedFocDate)
      );
    }
    return false;
  });

  if (!upcomingRequest) return undefined;

  return {
    portRequestId: upcomingRequest.portingDataId!,
    portRequestStatus:
      upcomingRequest.portingStatus === PortStatus.PORT_STATUS_FOC
        ? PortingTypes.PortRequestStatus.Accepted
        : PortingTypes.PortRequestStatus.Processing,
    acceptedFOCDate: formatDate(upcomingRequest.acceptedFocDate, 'MM/DD/YYYY', true) || '-',
    requestedFOCDate: formatDate(upcomingRequest.requestedFocDate, 'MM/DD/YYYY', true) || '-',
  };
};

export const preparePortingStatusTrackerInfo = (
  portingInfoList: PortingStatusInfo[] = []
): PortingStatusTrackerInfo => {
  const trackerInfo: PortingStatusTrackerInfo = { acceptedCount: 0, pendingCount: 0 };
  portingInfoList.forEach((portingInfo) => {
    const portRequestStatus = PortingApi.getValueForPortingStatus(
      portingInfo.portingStatus,
      portingInfo.requestedFocDate
    );

    if (
      ![PortingTypes.PortRequestStatus.Accepted, PortingTypes.PortRequestStatus.Processing].includes(portRequestStatus)
    ) {
      return;
    }

    const acceptedDate = formatDate(portingInfo.acceptedFocDate, undefined, true);
    const requestedDate = formatDate(portingInfo.requestedFocDate, undefined, true);
    const isEmptyOrPastAcceptedDate = !acceptedDate || dayjs().isAfter(acceptedDate, 'day');
    const isEmptyOrPastRequestedDate = !requestedDate || dayjs().isAfter(requestedDate, 'day');

    if (!isEmptyOrPastAcceptedDate) {
      trackerInfo.acceptedCount += 1;
    } else if (!isEmptyOrPastRequestedDate) {
      trackerInfo.pendingCount += 1;
    }
  });

  return trackerInfo;
};

export const getAllHolidays = (): string[] => {
  const year = new Date().getFullYear();
  const dateFormat = 'MM/dd/yyyy';
  const bankHolidays = getBankHolidays(year);
  let weaveObservedHolidaysList: string[] = [];

  weaveObservedHolidaysList = Object.values(bankHolidays).map((date) => format(date.date, dateFormat));

  const goodFriday = format(getGoodFriday(year), dateFormat);
  const christmasDay = getChristmas(year);
  const christmasEve = format(subDays(christmasDay, 1), dateFormat);
  const thanksgiving = getThanksgiving(year);
  const blackFriday = format(addDays(thanksgiving, 1), dateFormat);
  const newYearsEve = format(getNewYearsEve(year), dateFormat);

  // Election tuesday is always the first Tuesday following the first monday of November
  // It can never be Nov 1st so flow gets the first day of november, and gets the next tuesday
  const electionTuesdayDate = startOfMonth(new Date(year, 10));
  const electionTuesday = format(nextTuesday(new Date(electionTuesdayDate)), dateFormat);
  weaveObservedHolidaysList.push(goodFriday, electionTuesday, blackFriday, christmasEve, newYearsEve);

  return weaveObservedHolidaysList;
};

/**
 * Returns the minimum date for porting by adding 4 business days to the current date (
 * monday to friday are business days and no holidays).
 *
 * @param startDate The start date to add business days to
 * @param numberOfDays The number of business days to add to the start date
 * @param holidaysToConsider The list of holidays to consider when calculating business days. If not provided, it will use the default list of holidays.
 * @returns dayjs.Dayjs The date after adding the specified number of business days to the start date.
 */
const addBusinessDays = (startDate: string | dayjs.Dayjs, numberOfDays: number, holidaysToConsider?: string[]) => {
  const holidays = holidaysToConsider ?? getAllHolidays();
  let nextDate = dayjs(startDate);
  let count = 0;

  while (count < numberOfDays) {
    // sunday is 0, saturday is 6
    const isSunday = nextDate.day() === 0;
    const isSaturday = nextDate.day() === 6;
    const isHoliday = holidays.includes(nextDate.format(`MM/DD/YYYY`));

    if (isSunday || isSaturday || isHoliday) {
      nextDate = nextDate.add(1, 'days');
    } else {
      nextDate = nextDate.add(1, 'days');
      count += 1;
    }
  }

  return nextDate;
};

const checkIsDateWithinBusinessDaysRange =
  (days: number) =>
  (date: string): boolean => {
    if (!date) return true;

    const holidays = getAllHolidays();
    const minDate = addBusinessDays(dayjs().startOf('day'), days, holidays);

    return dayjs(date).isSameOrAfter(minDate);
  };

// allow cancellation only if the port request accepted date is not falling in the upcoming 3 days
export const checkPortOrderCancellable = checkIsDateWithinBusinessDaysRange(3);

// allow date change only if the port request accepted date is not falling in the upcoming week (5 business days)
export const checkPortOrderDateChangeable = checkIsDateWithinBusinessDaysRange(5);

export const getPortDateFieldProps = (): Partial<DatePickerFieldProps> => {
  const holidays = getAllHolidays();
  const minDate = addBusinessDays(dayjs(), 4, holidays);
  const maxDate = dayjs().add(3, 'weeks');
  const blackoutDates = [];
  const dateFormat = 'MM/DD/YYYY';
  let nextDate = minDate;

  // get all weekend dates between min and max date
  while (nextDate.isBefore(maxDate)) {
    // sunday is 0, saturday is 6
    if (nextDate.day() === 0) {
      blackoutDates.push(nextDate.format(dateFormat));
      nextDate = nextDate.add(6, 'days');
    } else if (nextDate.day() === 6) {
      blackoutDates.push(nextDate.format(dateFormat));
      nextDate = nextDate.add(1, 'days');
    } else {
      nextDate = nextDate.add(1, 'days');
    }
  }

  return {
    minDate: minDate.format('MM/DD/YY'),
    maxDate: maxDate.format('MM/DD/YY'),
    blackoutDates: [...new Set([...blackoutDates, ...holidays])],
  };
};

type PreparePortingRequestsDataParams = {
  storePortOrder: StorePortOrderType;
  portingData?: PortingData;
  isDraft?: boolean;
  isPutCall?: boolean;
};

export const preparePortingRequestsData = ({
  storePortOrder,
  portingData,
  isDraft,
  isPutCall,
}: PreparePortingRequestsDataParams) => {
  // Reconciles the port order phone numbers from the store (form state) with the porting
  // data porting requests from the backend.

  // for put call, just update existing porting requests with rest of the fields except phone-number
  if (portingData?.portingRequests?.length && isPutCall) {
    return portingData?.portingRequests?.map((portingRequest) => {
      const storeNumberInfo = storePortOrder.numbers?.find(
        (numberInfo) => portingRequest.phoneNumber === numberInfo.number
      );

      return {
        ...portingRequest,
        numberType: storeNumberInfo?.numberType ?? portingRequest.numberType,
        requestedFirmOrderCommitmentDate: storePortOrder.portDate
          ? getUTCDateStringForRequest(storePortOrder.portDate)
          : '',
        smsPortEligible: storePortOrder.isSMSHosting,
        portingStatus: isDraft ? PortStatus.PORT_STATUS_DRAFT_INCOMPLETE : PortStatus.PORT_STATUS_DRAFT,
      };
    });
  }

  // post request is handling change in phone-number field only
  const reconciledPortRequests = storePortOrder.numbers.map((phoneNumberInfo) => {
    const foundPortingRequest = portingData?.portingRequests?.find(
      (portRequest) => portRequest.phoneNumber === phoneNumberInfo.number
    );

    return {
      ...foundPortingRequest,
      phoneNumber: phoneNumberInfo.number,
      numberType: phoneNumberInfo.numberType,
      requestedFirmOrderCommitmentDate: storePortOrder.portDate
        ? getUTCDateStringForRequest(storePortOrder.portDate)
        : '',
      smsPortEligible: storePortOrder.isSMSHosting,
      portingStatus: isDraft ? PortStatus.PORT_STATUS_DRAFT_INCOMPLETE : PortStatus.PORT_STATUS_DRAFT,
      portingDataId: portingData?.id,
    };
  });

  return reconciledPortRequests;
};

export const preparePortingDataRequest = (
  storePortOrder: StorePortOrderType,
  portingData?: PortingData,
  isDraft = true
): PortingData => {
  const authorizedUser: string = storePortOrder.phoneProviderInfo?.authorizedUser ?? '';
  const splitNameArr = authorizedUser.split(/ (.*)/s);
  const authorizedUserFirstName = splitNameArr[0];
  const authorizedUserLastName = splitNameArr.length > 1 ? splitNameArr[1] : '';

  return {
    ...portingData,
    locationId: storePortOrder.locationId,
    requestClient: 'admin-portal',
    portingRequests: preparePortingRequestsData({ storePortOrder, portingData, isDraft, isPutCall: true }),

    // We need to clear this field when updating the porting data see ACT-2660 for more info.
    // https://linear.app/getweave/issue/ACT-2660/update-portal-to-handle-house-number
    serviceHouseNumber: undefined,
    serviceStreet1: storePortOrder.providerServiceAddress?.address1,
    serviceStreet2: storePortOrder.providerServiceAddress?.address2,
    serviceCity: storePortOrder.providerServiceAddress?.city,
    serviceState: storePortOrder.providerServiceAddress?.state,
    serviceZip: storePortOrder.providerServiceAddress?.postal,
    serviceCountry: storePortOrder.providerServiceAddress?.country,

    currentPhoneServiceProvider: storePortOrder.phoneProviderInfo?.name,
    phoneServiceAccountNumber: storePortOrder.phoneProviderInfo?.accountNumber,
    accountPin: storePortOrder.phoneProviderInfo?.accountPin,
    authorizedUserFirstName,
    authorizedUserLastName,
    officeEmail: storePortOrder.phoneProviderInfo?.officeEmail,
    companyName: storePortOrder.phoneProviderInfo?.businessName,
  };
};

export const getStorePortOrderFromPortingData = (portingData: PortingData): StorePortOrderType => {
  const firstPortingRequest = portingData.portingRequests?.[0];
  let portType: PortingTypes.PortTypeEnum | undefined = undefined;
  if (firstPortingRequest) {
    portType =
      firstPortingRequest.numberType === NumberType.NUMBER_TYPE_SMS
        ? PortingTypes.PortTypeEnum.SMS_HOSTING
        : PortingTypes.PortTypeEnum.FULL_PORT;
  }

  return {
    locationId: portingData.locationId ?? '',
    numbers:
      portingData.portingRequests?.reduce<NumberInfo[]>((acc, portRequest) => {
        if (portRequest.phoneNumber && portRequest.numberType) {
          acc.push({
            number: portRequest.phoneNumber,
            numberType: portRequest.numberType,
          });
        }
        return acc;
      }, []) ?? [],
    portDate: formatDate(firstPortingRequest?.requestedFirmOrderCommitmentDate, 'MM/DD/YYYY', true),
    portType,
    isSMSHosting: portType === PortingTypes.PortTypeEnum.SMS_HOSTING,
    phoneProviderInfo: {
      name: portingData.currentPhoneServiceProvider ?? '',
      accountPin: portingData.accountPin ?? '',
      accountNumber: portingData.phoneServiceAccountNumber ?? '',
      authorizedUser: `${portingData.authorizedUserFirstName ?? ''} ${portingData.authorizedUserLastName ?? ''}`.trim(),
      officeEmail: portingData.officeEmail ?? '',
      businessName: portingData.companyName ?? '',
      infoCorrect: true,
    },
    providerServiceAddress: {
      // We need to concatenate the serviceHouseNumber and serviceStreet1 together. See ACT-2660 for more info.
      // https://linear.app/getweave/issue/ACT-2660/update-portal-to-handle-house-number
      address1:
        (portingData.serviceHouseNumber ? `${portingData.serviceHouseNumber} ` : '') +
        (portingData.serviceStreet1 ?? ''),
      address2: portingData.serviceStreet2 ?? '',
      city: portingData.serviceCity ?? '',
      state: portingData.serviceState ?? '',
      postal: portingData.serviceZip ?? '',
      country: (portingData.serviceCountry ?? CountryCodes.USA) as CountryCodes,
    },
    phoneBills:
      portingData.customerPhoneBillMedia?.map<PhoneBillInfo>((media) => ({
        fileName: media.fileName ?? '',
        mediaDataId: media.id ?? '',
      })) ?? [],
  };
};

const isValuesEqual = (newField?: unknown, oldField?: unknown) =>
  !newField && !oldField ? true : newField === oldField;

export const checkIsPortOrderModified = (
  newPO: Partial<StorePortOrderType>,
  oldPO: StorePortOrderType
): { isPortOrderModified: boolean; isNumbersModified: boolean } => {
  const isNeededInfoFilled =
    Boolean(newPO.locationId) &&
    Boolean(newPO.portType) &&
    Boolean(newPO.numbers?.length) &&
    (newPO.isSMSHosting || Boolean(newPO.portDate));

  const isFirstStepModified =
    !isValuesEqual(newPO.locationId, oldPO.locationId) || !isValuesEqual(newPO.portType, oldPO.portType);

  const isNumbersModified =
    !isValuesEqual(newPO.numbers?.length, oldPO.numbers.length) ||
    (newPO.numbers || []).some((info, index) => !isValuesEqual(info.number, oldPO.numbers[index].number));

  const isSecondStepModified =
    !isValuesEqual(newPO.portDate, oldPO.portDate) ||
    !isValuesEqual(newPO.numbers?.length, oldPO.numbers.length) ||
    (newPO.numbers || []).some(
      (info, index) =>
        !isValuesEqual(info.number, oldPO.numbers[index].number) ||
        !isValuesEqual(info.numberType, oldPO.numbers[index].numberType)
    );

  let isProviderInfoModified = false;
  if (!!newPO.phoneProviderInfo && !!oldPO.phoneProviderInfo) {
    let key: keyof typeof newPO.phoneProviderInfo;
    for (key in newPO.phoneProviderInfo) {
      // we don't want to check this field(infoCorrect) as it is carried over from common component(PhoneProviderInformationForm) type
      if (key !== 'infoCorrect' && !isValuesEqual(newPO.phoneProviderInfo[key], oldPO.phoneProviderInfo[key])) {
        isProviderInfoModified = true;
        break;
      }
    }
  }
  let isProviderAddressModified = false;
  if (!!newPO.providerServiceAddress && !!oldPO.providerServiceAddress) {
    let key: keyof typeof newPO.providerServiceAddress;
    for (key in newPO.providerServiceAddress) {
      // we don't want to check this field(verified) as it is carried over from common component(PhoneProviderInformationForm) type
      if (key !== 'verified' && !isValuesEqual(newPO.providerServiceAddress[key], oldPO.providerServiceAddress[key])) {
        isProviderAddressModified = true;
        break;
      }
    }
  }

  const isThirdStepModified = isProviderInfoModified || isProviderAddressModified;

  return {
    isPortOrderModified: isNeededInfoFilled && (isFirstStepModified || isSecondStepModified || isThirdStepModified),
    isNumbersModified,
  };
};

export const getAPIErrorMessage = (err: unknown): string | undefined => {
  if (!http.isHttpError(err)) return undefined;

  const message = (err.data as { message: string })?.message;
  if (message && typeof message === 'string') {
    try {
      const res = JSON.parse(message) as { messages?: string[] } | undefined;
      return res?.messages?.[0];
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};
