import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.light};
  width: ${theme.spacing(44)};
  cursor: pointer;
  padding-bottom: ${theme.spacing(2)};
`;

export const sectionStyle = css`
  padding: ${theme.spacing(0, 2)};
`;

export const nameContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const copyIconStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;

export const descriptionStyle = css`
  margin: 0;
  margin-top: ${theme.spacing(0.5)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 22px;
  color: ${theme.colors.text.subdued};
  font-size: ${theme.fontSize(14.4)};
`;

export const modalContainerStyle = css`
  min-height: 600px;
  max-height: 820px;
`;

export const bodyStyle = css`
  display: flex;
  flex-direction: column;
`;

export const previewStyleOverride = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const gridViewContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(3)};
  padding: ${theme.spacing(3)};
  overflow: auto;
`;
