import { createShallowStore, createStore } from '@frontend/store';
import { QUICK_FILL_SEND_MESSAGE_LIMIT } from '../../constants';

interface RecipientCount {
  filteredCount: number;
  totalCount: number;
}

interface State {
  validLocationIds: string[]; // Location IDs with customization flag ON
  recipientCounts: Record<string, RecipientCount>;
  selectedIds: string[]; // Recipient IDs
  isAllSelected: boolean; // Selection state for all recipients (unfiltered)
}

export interface Store extends State {
  setValidLocationIds: (validLocationIds: string[]) => void;
  setFilteredCount: (locationId: string, filteredCount: number) => void;
  setTotalCounts: (totalCounts: { locationId?: string; count?: number }[]) => void;
  toggleSelection: (locationId: string, recipientId: string) => void;
  toggleSelectAll: (recipientIds: string[]) => void;
  clearFilteredCount: (locationId: string) => void;
  clearSelection: () => void;
}

export const useQuickFillRecipientStore = createStore<Store>(
  (set) => ({
    validLocationIds: [],
    recipientCounts: {},
    selectedIds: [],
    isAllSelected: false,
    setValidLocationIds: (validLocationIds) => set({ validLocationIds }),
    setTotalCounts: (totalCounts) =>
      set((state) => {
        const recipientCounts = totalCounts.reduce<Record<string, RecipientCount>>((acc, { locationId, count }) => {
          if (locationId) {
            acc[locationId] = { ...state.recipientCounts[locationId], totalCount: count || 0 };
          }
          return acc;
        }, {});

        return { ...state, recipientCounts };
      }),
    setFilteredCount: (locationId, filteredCount) =>
      set((state) => ({
        ...state,
        recipientCounts: {
          ...state.recipientCounts,
          [locationId]: {
            ...state.recipientCounts[locationId],
            filteredCount,
          },
        },
      })),
    toggleSelection: (locationId, recipientId) =>
      set((state) => {
        const isSelected = state.selectedIds.includes(recipientId);
        const updatedSelectedIds = isSelected
          ? state.selectedIds.filter((id) => id !== recipientId)
          : state.selectedIds.length < QUICK_FILL_SEND_MESSAGE_LIMIT
          ? [...state.selectedIds, recipientId]
          : state.selectedIds;

        const totalCount = state.recipientCounts[locationId]?.totalCount || 0;

        return {
          ...state,
          selectedIds: updatedSelectedIds,
          isAllSelected:
            updatedSelectedIds.length > 0 &&
            (totalCount < QUICK_FILL_SEND_MESSAGE_LIMIT
              ? updatedSelectedIds.length === totalCount
              : updatedSelectedIds.length === QUICK_FILL_SEND_MESSAGE_LIMIT),
        };
      }),
    toggleSelectAll: (recipientIds) =>
      set((state) => {
        let updatedSelectedIds: string[] = [];

        if (!state.isAllSelected) {
          const allRecipients = new Set([...state.selectedIds, ...recipientIds]);
          updatedSelectedIds = Array.from(allRecipients).slice(0, QUICK_FILL_SEND_MESSAGE_LIMIT);
        } else {
          const removedRecipients = new Set(recipientIds);
          updatedSelectedIds = state.selectedIds.filter((id) => !removedRecipients.has(id));
        }

        return {
          ...state,
          selectedIds: updatedSelectedIds,
          isAllSelected: !state.isAllSelected,
        };
      }),
    clearFilteredCount: (locationId) =>
      set((state) => ({
        ...state,
        recipientCounts: {
          ...state.recipientCounts,
          [locationId]: {
            ...state.recipientCounts[locationId],
            filteredCount: undefined,
          },
        },
      })),
    clearSelection: () => {
      set((state) => ({
        ...state,
        selectedIds: [],
        isAllSelected: false,
      }));
    },
  }),
  { name: 'quick-fill-recipient-store' }
);

export const useQuickFillRecipientShallowStore = createShallowStore<Store>(useQuickFillRecipientStore);
