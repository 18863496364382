import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { WeaveLocationGroup } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BackIcon, Heading, Stepper, Text } from '@frontend/design-system';
import { trackingId } from '../../tracking';
import { StepperCardHeader } from './create-call-route-modal';
import { AddedExtension, ExtensionsAdder } from './extensions-adder';

type AssignExtensionsStepProps = {
  initialExtensions: string[];
  tenantLocation: WeaveLocationGroup;
  showContinueButton: boolean;
  onCancelClick: () => void;
  onStepChange: (extensions: string[], stepChangeType: 'back' | 'continue' | 'done') => void;
};

export const AssignExtensionsStep = ({
  initialExtensions,
  tenantLocation,
  showContinueButton,
  onCancelClick,
  onStepChange,
}: AssignExtensionsStepProps) => {
  const { t } = useTranslation('phone');

  const [extensions, setExtensions] = useState<AddedExtension[]>([]);

  const handleStepChange = (stepChangeType: 'back' | 'continue' | 'done') => {
    const validExtensions = extensions.filter((extension) => extension.isValid).map((extension) => extension.value);
    const uniqueExtensions = [...new Set(validExtensions)];
    onStepChange(uniqueExtensions, stepChangeType);
  };

  return (
    <>
      <div>
        <StepperCardHeader onCancelClick={onCancelClick} />
        <Stepper.Content>
          <div
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <Heading level={3}>{t('Assign Extensions')}</Heading>
            <Text size='large'>
              {t(
                'Assign extensions to this Call Route so that your staff can call or transfer callers to this Call Route.'
              )}
            </Text>
          </div>
          <ExtensionsAdder
            extensions={extensions}
            setExtensions={setExtensions}
            tenantLocation={tenantLocation}
            initialExtensions={initialExtensions}
            trackingIdBase={trackingId({ context: 'setting', feature: 'call-routes', details: 'create::extensions' })}
          />
        </Stepper.Content>
      </div>

      <Stepper.ButtonBarAlternate>
        {showContinueButton ? (
          <Stepper.ButtonAlternate
            type='next'
            position='primary'
            onClick={() => handleStepChange('continue')}
            trackingId={trackingId({
              context: 'setting',
              feature: 'call-routes',
              details: 'create::extensions-next-btn',
            })}
          >
            {t('Continue')}
          </Stepper.ButtonAlternate>
        ) : (
          <Stepper.ButtonAlternate
            type='none'
            position='primary'
            onClick={() => handleStepChange('done')}
            trackingId={trackingId({
              context: 'setting',
              feature: 'call-routes',
              details: 'create::extensions-done-btn',
            })}
          >
            {t('Done')}
          </Stepper.ButtonAlternate>
        )}
        <Stepper.ButtonAlternate
          type='none'
          position='secondary'
          onClick={onCancelClick}
          trackingId={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'create::extensions-cancel-btn',
          })}
        >
          {t('Cancel')}
        </Stepper.ButtonAlternate>
        <Stepper.ButtonAlternate
          type='previous'
          position='tertiary'
          onClick={() => handleStepChange('back')}
          trackingId={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'create::extensions-back-btn',
          })}
        >
          <BackIcon />
          {t('Back')}
        </Stepper.ButtonAlternate>
      </Stepper.ButtonBarAlternate>
    </>
  );
};
