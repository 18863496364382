import { useCallback, useRef } from 'react';

type AnyVoidFn = (...args: any[]) => void;

export const useThrottledFn = (callback: AnyVoidFn, wait = 300) => {
  const isThrottled = useRef(false);
  return useCallback(
    (...args: Parameters<typeof callback>) => {
      if (!isThrottled.current) {
        callback(...args);
        isThrottled.current = true;
        setTimeout(() => {
          isThrottled.current = false;
        }, wait);
      }
    },
    [callback, wait, isThrottled]
  );
};
