import { forwardRef } from 'react';
import { motion, MotionProps } from 'motion/react';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { Text, Avatar, Dot, Button } from '@frontend/design-system';
import { MiniChatContext } from '../../stores';
import { AVATAR_SIZING } from '../mini-chat-header';

type MobileChatSelectionItemProps = {
  onClick: () => void;
  onRemove: () => void;
} & Pick<MiniChatContext, 'unreadCount' | 'personPhone' | 'groupId'> &
  MotionProps;

export const MobileChatSelectionItem = forwardRef<HTMLDivElement, MobileChatSelectionItemProps>(
  ({ onClick, onRemove, unreadCount, personPhone, groupId, ...rest }, ref) => {
    const { t } = useTranslation('mini-chat');
    const { primaryContactId, associatedContacts } = PersonsV3.PersonHooks.useAssociatedContacts({
      phoneNumber: personPhone,
      locationId: groupId,
    });
    const primaryContact = associatedContacts.find((contact) => contact.personId === primaryContactId);
    const heading = primaryContact
      ? PersonsV3.PersonHelpers.getFullName(primaryContact)
      : formatPhoneNumber(personPhone);

    return (
      <motion.div
        ref={ref}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: unreadCount ? theme.colors.primary50 : theme.colors.white,
          borderRadius: theme.borderRadius.medium,
          boxShadow: theme.shadows.floating,
          padding: theme.spacing(1),
          cursor: 'pointer',
          ':hover, :active': {
            backgroundColor: unreadCount ? theme.colors.primary60 : theme.colors.neutral5,
          },
        }}
        role='button'
        onClick={onClick}
        // layout
        {...rest}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          {primaryContact ? (
            <Photos.ContactProfilePhoto
              personId={primaryContactId}
              disableClick
              firstName={primaryContact.preferredName || primaryContact.firstName}
              lastName={primaryContact.lastName}
              locationId={groupId}
              title={t('Open Conversation with {{nameOrNumber}}', {
                nameOrNumber: heading,
              })}
              disableHoverLabel
              css={AVATAR_SIZING}
            />
          ) : (
            <Avatar name={heading} title={heading} disableClick disableHoverLabel css={AVATAR_SIZING} />
          )}
          <Text weight={unreadCount ? 'bold' : undefined} color={unreadCount ? 'white' : 'default'}>
            {heading}
          </Text>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          {!!unreadCount && (
            <Dot
              color='critical'
              css={{
                position: 'static',
                border: 'none',
                color: theme.font.colors.white,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: theme.fontSize(12),
                fontWeight: theme.font.weight.bold,
              }}
            >
              {unreadCount}
            </Dot>
          )}
          <Button
            iconName='x'
            variant='secondary'
            css={
              !!unreadCount
                ? {
                    svg: {
                      color: theme.colors.white,
                    },
                    ':hover': {
                      backgroundColor: theme.colors.primary70,
                    },
                  }
                : undefined
            }
            onClick={(e) => {
              onRemove();
              e.stopPropagation();
            }}
          />
        </div>
      </motion.div>
    );
  }
);
MobileChatSelectionItem.displayName = 'MobileChatSelectionItem';
