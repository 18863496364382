import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { EmojiClickData } from 'emoji-picker-react';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { theme } from '@frontend/theme';
import { EmojiButton } from '@frontend/design-system';
import { useActiveConversationSelector } from '../../../providers/active-conversation.provider';
import { ReactionButton } from './reaction-button';

interface Props {
  message: TeamChatTypes.Message;
}

export const Reactions = ({ message }: Props) => {
  const addReaction = useActiveConversationSelector((ctx) => ctx.addReaction);
  const removeReaction = useActiveConversationSelector((ctx) => ctx.removeReaction);
  const [isModifyingReaction, setIsModifyingReaction] = useState(false);
  const handleClickEmoji = useCallback(
    (emoji: EmojiClickData, hasOwnReaction?: boolean) => {
      setIsModifyingReaction(true);
      if (hasOwnReaction) {
        removeReaction({
          channelId: message.channelId,
          messageId: message.id,
          reaction: emoji.unified,
          // we need parentId here but not on addReaction, because addReaction returns a message payload, and we can just get the parentId from that
          // removeReaction does not return a message payload, but we still need to know which message in the cache to update
          parentMessageId: message.parentId,
        }).finally(() => setIsModifyingReaction(false));
      } else {
        addReaction({
          channelId: message.channelId,
          messageId: message.id,
          reaction: emoji.unified,
          // if we wanted to keep track of the emoji name in stream, we could do it here
          // name: emoji.names[0]
        }).finally(() => setIsModifyingReaction(false));
      }
    },
    [message.reactions?.length, message.channelId, message.id]
  );

  return (
    <div css={styles.wrapper}>
      {message.reactions?.map((reaction) => (
        <ReactionButton
          key={reaction.name}
          onClickReaction={(emoji: EmojiClickData) => handleClickEmoji(emoji, reaction.hasOwnReaction)}
          reaction={reaction}
          wrapperStyle={[styles.reactionButton, reaction.hasOwnReaction && styles.ownReaction]}
        />
      ))}
      <EmojiButton
        isLoading={isModifyingReaction}
        css={[styles.reactionButton, styles.defaultEmoji]}
        onSelect={handleClickEmoji}
        trackingId='team-chat-2.0-emoji-icon-add'
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    margin-top: ${theme.spacing(1)};
  `,

  reactionButton: css({
    backgroundColor: theme.colors.neutral5,
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.colors.neutral10}`,
    height: theme.spacing(3),
    minWidth: theme.spacing(2),
    padding: theme.spacing(0, 0.5),
    width: 'fit-content',

    ':hover': {
      backgroundColor: theme.colors.white,
    },
  }),

  ownReaction: css({
    backgroundColor: theme.colors.primary10,
    borderColor: theme.colors.primary20,
  }),

  defaultEmoji: css`
    svg {
      fill: ${theme.colors.neutral50};
      height: ${theme.spacing(2)};
      width: ${theme.spacing(2)};
    }
  `,
};
