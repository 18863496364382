import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { BundleFeatureEnum, CommonUltimateFeaturesList } from './constants';

export const getBundleWiseFeatureList = (bundle: SalesforceAccountProductBundle): BundleFeatureEnum[] => {
  switch (bundle) {
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ULTIMATE:
      return [...CommonUltimateFeaturesList, BundleFeatureEnum.CALL_INTEL, BundleFeatureEnum.PRACTICE_ANALYTICS];
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ULTIMATE:
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ULTIMATE:
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ULTIMATE:
      return [...CommonUltimateFeaturesList];
    default:
      return [];
  }
};

export const getBundleWiseHighlightedFeatureList = (bundle: SalesforceAccountProductBundle): BundleFeatureEnum[] => {
  switch (bundle) {
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ULTIMATE:
      return [BundleFeatureEnum.ONLINE_SCHEDULING, BundleFeatureEnum.FORMS, BundleFeatureEnum.CALL_INTEL];
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ULTIMATE:
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ULTIMATE:
    case SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ULTIMATE:
      return [BundleFeatureEnum.ONLINE_SCHEDULING, BundleFeatureEnum.FORMS];
    default:
      return [];
  }
};
