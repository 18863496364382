import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import {
  PopoverDialog,
  UsePopoverDialogResponse,
  Button,
  Text,
  SearchableListbox,
  useListboxState,
} from '@frontend/design-system';
import { containerStyle, popoverDialogStyle, listItemStyle } from './pick-a-location.styles';

interface PickALocationProps {
  popoverDialogControls: UsePopoverDialogResponse;
  /**
   * List of available location IDs to pick from
   */
  availableLocationIds: string[];
  /**
   * The selected location ID state value
   */
  selectedLocationId?: string;
  /**
   * Callback function to handle the selection of a location
   */
  onSelectLocation: (locationId: string) => void;
  pickerButtonTrackingId?: string;
  listItemTrackingId?: string;
}

const PickALocation: FC<PickALocationProps> = ({
  availableLocationIds,
  selectedLocationId,
  popoverDialogControls,
  onSelectLocation,
  pickerButtonTrackingId,
  listItemTrackingId,
  ...otherProps
}) => {
  const { t } = useTranslation('forms');
  const { getLocationName } = useAppScopeStore();
  const listboxProps = useListboxState(selectedLocationId ? selectedLocationId : '');

  function selectLocationHandler(value: string | string[]) {
    if (typeof value === 'string') {
      onSelectLocation(value);
    }
    listboxProps.onSelect(value);
    popoverDialogControls.close();
  }

  return (
    <div css={containerStyle} {...otherProps}>
      <span {...popoverDialogControls.getTriggerProps()}>
        <Button variant='secondary' iconName='location' trackingId={pickerButtonTrackingId}>
          <Text as='span' size='small'>
            {selectedLocationId ? getLocationName(selectedLocationId) : t('Select Location')}
          </Text>
          <Icon name={popoverDialogControls.isOpen ? 'caret-up-small' : 'caret-down-small'} />
        </Button>
      </span>

      <PopoverDialog {...popoverDialogControls.getDialogProps()} css={popoverDialogStyle}>
        <SearchableListbox {...listboxProps} css={listItemStyle} onSelect={selectLocationHandler}>
          {availableLocationIds.map((locationId) => (
            <SearchableListbox.Option key={locationId} value={locationId} trackingId={listItemTrackingId}>
              {getLocationName(locationId)}
            </SearchableListbox.Option>
          ))}
        </SearchableListbox>
      </PopoverDialog>
    </div>
  );
};

export default PickALocation;
