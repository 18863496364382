import { css } from '@emotion/react';
import { PersonHelpers } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Avatar, Text, Button } from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider';

interface StatusTooltipContentProps {
  userId: string;
  close: () => void;
  shouldShowSendDirectMessage: boolean;
}

export const StatusTooltipContent = ({ userId, close, shouldShowSendDirectMessage }: StatusTooltipContentProps) => {
  const { t } = useTranslation('team-chat');

  const currentUser = useTeamChatSelector((ctx) => ctx.user);
  const user = useTeamChatSelector((ctx) => ctx.helpers.getUser(userId));
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const createConversation = useTeamChatSelector((ctx) => ctx.createConversation);
  const isUpdatingUser = useTeamChatSelector((ctx) => ctx.isUpdatingUser);
  const openStaticConversation = useTeamChatSelector((ctx) => ctx.openStaticConversation);
  const openUserStatus = useTeamChatSelector((ctx) => ctx.openUserStatus);
  const updateCurrentUser = useTeamChatSelector((ctx) => ctx.updateCurrentUser);

  // userId is person using team chat
  // currentUser.userId is the person whose status is being viewed
  const currentUserStatus = currentUser?.status;
  const name = user
    ? PersonHelpers.getFullName({
        FirstName: user?.firstName,
        LastName: user?.lastName,
      })
    : undefined;

  const isSelf = userId === currentUser?.userID;
  const canEdit = isSelf;
  const userStatus = isSelf ? currentUserStatus : user?.status;
  const hasStatus = !!userStatus?.status?.text || !!userStatus?.status.emoji;

  /** Only allowed to clear the current user */
  const clearStatus = () => {
    // update the user object
    if (!currentUser) {
      return;
    }
    updateCurrentUser({
      ...currentUser,
      status: {
        status: {
          text: undefined,
          duration: undefined,
          expiry: undefined,
          emoji: undefined,
        },
        presence: currentUser?.status.presence,
      },
    });
    // no close() is called here.
    // we intentionally leave the tooltip open here for now.
  };

  const openStatusModal = () => {
    openUserStatus();
    close();
  };

  const sendDirectMessage = () => {
    if (!currentUser || !user) {
      return;
    }
    const foundConversation = conversations?.dm.find(
      (conversation) =>
        conversation?.memberIds.length === 1 && conversation.memberIds.some((memberId) => user.userID === memberId)
    );
    if (foundConversation) {
      // set this is as active conversation
      openStaticConversation(foundConversation.channelId);
    } else {
      // conversation will be opened in the onSuccess of the mutation
      createConversation({ memberIds: [currentUser.userID], type: 'dm', name: undefined });
    }
    // close tooltip
    close();
  };

  return (
    <>
      <div css={tooltipAvatarContainerStyle}>
        <Avatar size='large' name={name} isUser disableHoverLabel />
        <Text size='large' weight='bold'>
          {name}
        </Text>
      </div>
      {(hasStatus || shouldShowSendDirectMessage || canEdit) && (
        <div css={tooltipStatusContainerStyle}>
          {hasStatus && (
            <span css={statusTextContainerStyle}>
              {userStatus?.status.emoji && (
                <Text size='large' css={emojiStyle}>
                  {userStatus.status.emoji}
                </Text>
              )}
              {userStatus?.status.text && (
                <Text size='large' weight='regular'>
                  {userStatus.status.text}
                </Text>
              )}
            </span>
          )}
          {canEdit && (
            <div css={{ display: 'flex', gap: theme.spacing(1), width: '100%', justifyContent: 'space-evenly' }}>
              {hasStatus && (
                <Button
                  variant='secondary'
                  loading={isUpdatingUser}
                  onClick={clearStatus}
                  trackingId={'team-chat-2.0-clear-status-button-from-tooltip'}
                >
                  <Text>{t('Clear Status')}</Text>
                </Button>
              )}
              <Button
                variant='secondary'
                loading={isUpdatingUser}
                onClick={openStatusModal}
                trackingId={'team-chat-2.0-update-status-button-from-tooltip'}
              >
                <Text>{t('Update Status')}</Text>
              </Button>
            </div>
          )}
          {shouldShowSendDirectMessage && !isSelf && (
            <Button onClick={sendDirectMessage} variant='secondary'>
              {t('Send Direct Message')}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

const tooltipAvatarContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
});

const tooltipStatusContainerStyle = css({
  borderTop: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
});

const statusTextContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});

const emojiStyle = css({ minWidth: '24px', fontSize: theme.fontSize(20) });

export const toolTipContainerStyle = css`
  background-color: ${theme.colors.white};
  padding: 0;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.floating};
  max-width: 320px;

  svg {
    display: none;
  }
`;
