import { useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import {
  BarChartData,
  Chart,
  CustomLegendsData,
  FormatValue,
  PieChartData,
  YAxisLabelValueTick,
} from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { DemoChip, LocationChip } from '..';
import { useAnalyticsOrgLocations } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import { PracticeAnalyticsURLs } from './constants';
import { getPracticeAnalyticsQueryVariables } from './helpers';
import { usePaNavigate, usePracticeAnalyticsShallowStore } from './hooks';
import { PracticeAnalyticsInfoTips, useMonthlyTrendChartModal } from '.';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      missedAppointments {
        totals(start: $start, end: $end, step: $step) {
          cancelled
          cancelledUnscheduled
          recapturedProduction
          rescheduled
          totalPersonsCount
          totalProduction
          totalProductionRisk
          unscheduledOpportunity
        }
      }
    }
  }
`;

type CancelledPatientsState = {
  cancelled: number;
  cancelledUnscheduled: number;
  rescheduled: number;
  totalPatients: number;
};

type ChartData = {
  barChartData?: BarChartData;
  cancelledPatients: CancelledPatientsState;
  cancelledPatientsChart?: PieChartData;
  rescheduledPatientsChart?: PieChartData;
  cancelledUnscheduledPatientsChart?: PieChartData;
};

type RenderChart = {
  commonTooltipLabel?: string;
  customLegends: CustomLegendsData;
  data?: PieChartData;
  formatter: FormatValue;
  tooltipTitle?: string;
};

const colors = {
  cancelled: theme.colors.warning50,
  cancelledUnscheduled: theme.colors.critical50,
  rescheduled: theme.colors.success20,
  skipTipRemaining: theme.colors.neutral20,
};

const customLegendsIds = Object.keys(colors);

const defaultChartData: PieChartData = {
  groups: [],
};

export const CancellationsMetric = ({
  clickNoop,
  isDrillDownPage,
  onFetchStateChange,
}: PracticeAnalyticsTypes.MetricProps) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = usePaNavigate();
  const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount',
    'showDemoChipAndBanner'
  );
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });

  const monthlyTrendModal = useMonthlyTrendChartModal({
    defaultKey: 'percentage',
    metric: 'cancellations',
    title: t('Trend: Cancellations'),
    trackingId: trackingIds.practiceAnalytics.cancellationsTrend,
  });

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
  const showLocationComparison = isDrillDownPage && multipleLocationsSelected;

  const labels = {
    cancelled: t('Cancelled Patients'),
    cancelledUnscheduled: t('Remaining Unscheduled'),
    rescheduled: t('Rescheduled'),
    totalPatients: t('Total Patients'),
    totalProduction: t('Total Production'),
  };

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`cancellationsSummary-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.CancellationsResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.cancellations : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const chartData: ChartData | undefined = useMemo(() => {
    const { aggregatedData, rawData } = PracticeAnalyticsAggregations.cancellationsSummary(data);

    if (!aggregatedData || !rawData) {
      return;
    }

    const { missedAppointments } = aggregatedData;
    let barChartData: BarChartData = {
      groups: [],
    };

    const cancelledPatients = {
      cancelled: missedAppointments?.totals?.cancelled || 0,
      cancelledUnscheduled: missedAppointments?.totals?.cancelledUnscheduled || 0,
      rescheduled: missedAppointments?.totals?.rescheduled || 0,
      totalPatients: missedAppointments?.totals?.totalPersonsCount || 0,
    };

    const cancelledPatientsChart: PieChartData = {
      centerMetric: formatters.currency.format(missedAppointments?.totals?.totalProductionRisk),
      groups: [
        {
          name: 'cancelled',
          value: cancelledPatients.cancelled,
        },
        {
          name: 'skipTipRemaining',
          value: cancelledPatients.totalPatients - cancelledPatients.cancelled,
        },
      ],
    };

    const rescheduledPatientsChart: PieChartData = {
      centerMetric: formatters.currency.format(missedAppointments?.totals?.recapturedProduction),
      groups: [
        {
          name: 'rescheduled',
          value: cancelledPatients.rescheduled,
        },
        {
          name: 'skipTipRemaining',
          value: cancelledPatients.cancelled - cancelledPatients.rescheduled,
        },
      ],
    };

    const cancelledUnscheduledPatientsChart: PieChartData = {
      centerMetric: formatters.currency.format(missedAppointments?.totals?.unscheduledOpportunity),
      groups: [
        {
          name: 'cancelledUnscheduled',
          value: cancelledPatients.cancelledUnscheduled,
        },
        {
          name: 'skipTipRemaining',
          value: cancelledPatients.cancelled - cancelledPatients.cancelledUnscheduled,
        },
      ],
    };

    if (showLocationComparison) {
      barChartData = Object.entries(rawData).reduce(
        (acc, [locationId, locationDetails]) => {
          const { totals = {} } = locationDetails?.location.missedAppointments || {};

          acc.groups.push({
            name: locationNames[locationId] || locationId,
            values: (
              ['cancelled', 'rescheduled', 'cancelledUnscheduled'] as Array<
                keyof PracticeAnalyticsTypes.MissedAppointmentsTotals
              >
            ).reduce((acc, key) => ({ ...acc, [key]: totals[key] || 0 }), {}),
          });
          return acc;
        },
        { groups: [] } as BarChartData
      );
    }

    return {
      barChartData,
      cancelledPatients,
      cancelledPatientsChart,
      rescheduledPatientsChart,
      cancelledUnscheduledPatientsChart,
    };
  }, [data?.data, locationNames]);

  const barChartTotals = useMemo(() => {
    return chartData?.barChartData?.groups?.reduce<Record<string, number>>((acc, { values }) => {
      Object.entries(values).forEach(([key, value]) => {
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key] += value;
      });
      return acc;
    }, {});
  }, [showLocationComparison, chartData?.barChartData]);

  const renderPieChart = useCallback(
    ({ commonTooltipLabel, customLegends, data, formatter, tooltipTitle }: RenderChart) => {
      return (
        <div css={styles.chartWrapper}>
          <Chart.PieChart
            appearance={{
              collectiveTooltip: true,
              customTooltipTitle: () => tooltipTitle,
              minWidth: 240,
            }}
            commonTooltipLabel={commonTooltipLabel}
            customLegendsIds={customLegendsIds}
            data={data ?? defaultChartData}
            formatValue={formatter}
          />
          <Chart.Legends customData={customLegends} layout='vertical' size='medium' />
        </div>
      );
    },
    []
  );

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <>
      <Chart
        colors={colors}
        isLoading={isLoading}
        labels={labels}
        onClick={
          clickNoop
            ? undefined
            : () => {
                navigate({
                  to: `${PracticeAnalyticsURLs.BASE}/cancelled-appointments`,
                });
              }
        }
        trackingId={trackingIds.practiceAnalytics.cancellationsChart}
      >
        {isDrillDownPage ? (
          showDemoChipAndBanner && <DemoChip />
        ) : (
          <Chart.Header
            actions={[monthlyTrendModal.triggerProps]}
            bottomElement={multipleLocationsSelected ? <CompareLocationsButton /> : null}
            infoTip={<PracticeAnalyticsInfoTips tip='cancellations' />}
            leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
            title={t('Cancellations')}
          />
        )}
        {showLocationComparison ? (
          <>
            <Chart.BarChart
              appearance={{
                showXAxis: true,
                customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
                customYAxisTick: ({ labels, groupName, ...rest }) => {
                  return <YAxisLabelValueTick {...rest} clipLength={22} label={labels?.[groupName] || groupName} />;
                },
                groupsGap: 24,
                layout: 'vertical',
                margin: { right: 20, left: 120 },
                mode: 'stacked',
                showGridLines: true,
                showYAxis: true,
              }}
              data={chartData?.barChartData}
            />
            <Chart.Legends formatValue={formatters.value.format} values={barChartTotals} />
          </>
        ) : (
          <>
            <Chart.Legends
              customData={{
                totalPatients: {
                  value: formatters.value.format(chartData?.cancelledPatients?.totalPatients),
                },
              }}
              size='medium'
            />
            <Chart.HorizontalContainer style={{ marginBottom: 0 }}>
              {renderPieChart({
                commonTooltipLabel: t('Patients'),
                customLegends: {
                  cancelled: {
                    value: formatters.value.format(chartData?.cancelledPatients?.cancelled),
                  },
                },
                data: chartData?.cancelledPatientsChart,
                formatter: formatters.value.format,
                tooltipTitle: t('Cancelled'),
              })}

              {renderPieChart({
                commonTooltipLabel: t('Patients'),
                customLegends: {
                  rescheduled: {
                    value: formatters.value.format(chartData?.cancelledPatients?.rescheduled),
                  },
                },
                data: chartData?.rescheduledPatientsChart,
                formatter: formatters.value.format,
                tooltipTitle: t('Rescheduled'),
              })}

              {renderPieChart({
                commonTooltipLabel: t('Patients'),
                customLegends: {
                  cancelledUnscheduled: {
                    value: formatters.value.format(chartData?.cancelledPatients?.cancelledUnscheduled),
                  },
                },
                data: chartData?.cancelledUnscheduledPatientsChart,
                formatter: formatters.value.format,
                tooltipTitle: t('Remaining Unscheduled'),
              })}
            </Chart.HorizontalContainer>
          </>
        )}
      </Chart>
      {monthlyTrendModal.modalRenderer()}
    </>
  );
};

const styles = {
  chartWrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;

    .sneak-peak-item.vertical {
      align-items: flex-start;
      gap: ${theme.spacing(1)};
    }
  `,
};
