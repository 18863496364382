import { useTranslation } from '@frontend/i18n';
import CallIntelMedia from '../../../assets/call-intel-promotion.png';
import { FeaturePromotionPageType } from '../../../types';
import { FeaturePromotionBasePage } from '../FeaturePromotionBasePage';

export const CallIntelPromotion: FeaturePromotionPageType = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'call-intel-promotion' });
  return (
    <FeaturePromotionBasePage
      title={t('Call Intelligence')}
      description={t('An AI-Powered solution for your office')}
      featureName={t('Call Intelligence')}
      media={{ src: CallIntelMedia, alt: t('Call Intelligence Example') }}
      {...props}
    />
  );
};
