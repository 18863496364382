import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonsV3 } from '@frontend/api-person';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { ThreadDrawer, useDepartmentSelector, usePersonPhoneSelector } from '@frontend/thread-header';
import { MiniChatHeader, MenuOption, ThreadOptionsDrawerState } from '../components';
import { useMiniChatShallowStore } from '../stores';

type UseThreadOptionsDrawerArgs = Pick<ComponentProps<typeof ThreadDrawer>, 'open'> &
  Pick<ComponentProps<typeof MiniChatHeader>, 'groupId' | 'personId' | 'personPhone' | 'departmentId'> & {
    associatedContacts: Person[];
    chatId: string;
  };

export const useThreadOptionsDrawer = ({
  chatId,
  groupId,
  personPhone,
  departmentId,
  personId,
  associatedContacts,
  open,
}: UseThreadOptionsDrawerArgs): ThreadOptionsDrawerState => {
  const [selectedMenu, setSelectedMenu] = useState<MenuOption>();
  const { chats, setChatPossiblePersonPhones } = useMiniChatShallowStore('chats', 'setChatPossiblePersonPhones');
  const currentPossiblePhones = chats.find((chat) => chat.chatId === chatId)?.possiblePersonPhones ?? [];

  useEffect(() => {
    if (!open) {
      setSelectedMenu(undefined);
    }
  }, [open]);

  const personPhoneSelectorState = usePersonPhoneSelector({
    personPhone,
    personId,
    groupId,
  });
  const departmentSelectorState = useDepartmentSelector({
    groupId,
    departmentId,
    alwaysShow: false,
    personPhone,
  });

  useEffect(() => {
    const newPossiblePhones = personPhoneSelectorState.allPhones.map(({ number }) => formatPhoneNumberE164(number));
    if (
      newPossiblePhones.length !== currentPossiblePhones.length ||
      !newPossiblePhones.some((newPhone) => !currentPossiblePhones.includes(newPhone)) ||
      !currentPossiblePhones.some((currentPhone) => !newPossiblePhones.includes(currentPhone))
    ) {
      setChatPossiblePersonPhones(chatId, newPossiblePhones);
    }
  }, [JSON.stringify(personPhoneSelectorState.allPhones)]);

  const hideOptions = useMemo<MenuOption[]>(() => {
    const result: MenuOption[] = [];
    if (!personPhoneSelectorState.showButton && !departmentSelectorState.showButton) result.push('change-thread');
    if (!associatedContacts.length) result.push('associated-contacts');
    return result;
  }, [personPhoneSelectorState.showButton, departmentSelectorState.showButton, !!associatedContacts.length]);

  const { mutateAsync } = PersonsV3.PersonMutations.useUpsertPrimaryContactLegacyMutation();

  const setPrimaryContact = useCallback<ThreadOptionsDrawerState['setPrimaryContact']>(
    async (contactId: string) => {
      await mutateAsync({
        personId: contactId,
        phoneNumber: personPhone,
        locationId: groupId,
      });
    },
    [mutateAsync, personPhone, groupId]
  );

  return {
    selectedMenu,
    setSelectedMenu,
    setPrimaryContact,
    hideOptions,
    personPhoneSelectorState,
    departmentSelectorState,
    associatedContacts,
  };
};
