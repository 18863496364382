import { css } from '@emotion/react';
import { ScopedLocationFilterMenu } from '@frontend/location-filter-menu';
import { theme } from '@frontend/theme';
import { TimePeriodPopoverSelector } from '../filter-selectors/time-period-select-popover';
import { DEFAULT_NO_TIME_PERIOD, PeriodFilter } from '../filter-selectors/time-period-selector';

type ToolbarChipFilter = {
  locationIds: string[];
  startDate: string;
  endDate: string;
};
interface FilterProps {
  defaultTimePeriod?: PeriodFilter;
  filters: ToolbarChipFilter;
  onChange: (args: ToolbarChipFilter) => void;
  trackingBaseId?: string;
}

export const ToolbarChipFilter = ({
  defaultTimePeriod,
  filters,
  onChange,
  trackingBaseId = 'all-calls-toolbar',
}: FilterProps) => {
  const handleChange = (filters: ToolbarChipFilter) => {
    onChange(filters);
  };

  return (
    <div css={styles.mainWrapper} style={{ flexGrow: 1, margin: 0 }}>
      <ScopedLocationFilterMenu
        selectedOptions={filters.locationIds}
        trackingIdBase={trackingBaseId}
        onChange={(locations) => {
          handleChange({ ...filters, locationIds: locations });
        }}
        showClearAll={false}
      />

      <TimePeriodPopoverSelector
        defaultPeriod={defaultTimePeriod ?? DEFAULT_NO_TIME_PERIOD}
        onChange={({ endDate, startDate }) => {
          onChange({ ...filters, startDate, endDate });
        }}
        startDate={filters.startDate}
        endDate={filters.endDate}
        trackingId={`${trackingBaseId}-time-selector-chip-filter`}
      />
    </div>
  );
};

const styles = {
  mainWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(3)};
    position: relative;
  `,
};
