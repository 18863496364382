import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const thumbnailSectionStyle = css`
  width: ${theme.spacing(7)};
  padding: ${theme.spacing(1)};
  margin: ${theme.spacing(2)};
  border-radius: ${theme.spacing(2, 2, 0, 2)};
`;

export const iconStyle = css`
  height: ${theme.spacing(3)};
  margin: ${theme.spacing(1)};
`;
