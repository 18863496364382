import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, PopoverMenu, usePopoverMenu, NakedButton } from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';

export type CallIntelSortOption =
  | 'callCountHL'
  | 'callCountLH'
  | 'contactNameAZ'
  | 'contactNameZA'
  | 'lastCallLatest'
  | 'lastCallOldest'
  | 'taskCountHL'
  | 'taskCountLH';

type SortOption = {
  label: string;
  value: CallIntelSortOption;
  trackingId: string;
};

type CallIntelSortFilterProps = {
  defaultSortOption?: CallIntelSortOption;
  disabled?: boolean;
  onSortChange: (sortBy: CallIntelTypes.FollowupSortFieldEnum, sortType: CallIntelTypes.SortTypeEnum) => void;
  selectedSortOption?: CallIntelSortOption;
  trackingId?: string;
};

const sortOptions: SortOption[] = [
  {
    label: i18next.t('Last Call: Latest', { ns: 'analytics' }),
    value: 'lastCallLatest',
    trackingId: trackingIds.callIntel.sortFilterLastCallLatest,
  },
  {
    label: i18next.t('Last Call: Oldest', { ns: 'analytics' }),
    value: 'lastCallOldest',
    trackingId: trackingIds.callIntel.sortFilterLastCallOldest,
  },
  {
    label: i18next.t('Call Count: High to Low', { ns: 'analytics' }),
    value: 'callCountHL',
    trackingId: trackingIds.callIntel.sortFilterCallCountHL,
  },
  {
    label: i18next.t('Call Count: Low to High', { ns: 'analytics' }),
    value: 'callCountLH',
    trackingId: trackingIds.callIntel.sortFilterCallCountLH,
  },
];

export const CallIntelSortFilter = ({
  defaultSortOption = 'lastCallLatest',
  disabled = false,
  selectedSortOption,
  onSortChange,
  trackingId,
}: CallIntelSortFilterProps) => {
  const { t } = useTranslation('analytics');
  const { close, getMenuProps, getTriggerProps, isOpen, open } = usePopoverMenu({ placement: 'bottom' });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  const [currentSortOption, setCurrentSortOption] = useState<CallIntelSortOption>(defaultSortOption);

  const handleSortChange = (option: CallIntelSortOption) => {
    setCurrentSortOption(option);

    let sortBy: CallIntelTypes.FollowupSortFieldEnum;
    let sortType: CallIntelTypes.SortTypeEnum;

    switch (option) {
      case 'callCountHL':
        sortBy = CallIntelTypes.FollowupSortFieldEnum.SORT_CALLS_COUNT;
        sortType = CallIntelTypes.SortTypeEnum.SORT_DESC;
        break;
      case 'callCountLH':
        sortBy = CallIntelTypes.FollowupSortFieldEnum.SORT_CALLS_COUNT;
        sortType = CallIntelTypes.SortTypeEnum.SORT_ASC;
        break;
      case 'lastCallLatest':
        sortBy = CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL;
        sortType = CallIntelTypes.SortTypeEnum.SORT_DESC;
        break;
      case 'lastCallOldest':
        sortBy = CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL;
        sortType = CallIntelTypes.SortTypeEnum.SORT_ASC;
        break;
      default:
        sortBy = CallIntelTypes.FollowupSortFieldEnum.SORT_LAST_CALL;
        sortType = CallIntelTypes.SortTypeEnum.SORT_DESC;
        break;
    }

    onSortChange(sortBy, sortType);
    close();
  };

  useEffect(() => {
    if (selectedSortOption && selectedSortOption !== currentSortOption) {
      setCurrentSortOption(selectedSortOption);
    }
  }, [selectedSortOption]);

  return (
    <div ref={filterTriggerRef}>
      <Chip.DropdownFilter
        {...triggerProps}
        css={styles.dropdownFilter(currentSortOption === defaultSortOption)}
        filterIsActive={currentSortOption !== defaultSortOption}
        onClick={isOpen ? close : open}
        leftElement={<Icon name='sort-descending-small' color='default' />}
        trackingId={trackingId}
      >
        {t('Sort')}
      </Chip.DropdownFilter>
      <PopoverMenu {...getMenuProps()}>
        {sortOptions.map((option) => (
          <NakedButton
            className={option.value === selectedSortOption ? 'active' : ''}
            css={styles.listItem}
            disabled={disabled}
            key={option.value}
            onClick={() => handleSortChange(option.value)}
            trackingId={option.trackingId}
          >
            {option.value === currentSortOption && (
              <span style={{ color: theme.colors.text.interactive }}>
                <Icon name='check' />
              </span>
            )}
            {option.label}
          </NakedButton>
        ))}
      </PopoverMenu>
    </div>
  );
};

const styles = {
  dropdownFilter: (isDefault: boolean) => css`
    gap: ${theme.spacing(2)};
    max-width: 150px;
    width: 100%;
    background-color: ${isDefault ? 'white' : theme.colors.neutral10};
    color: ${theme.colors.text.default};
  `,

  listItem: css`
    cursor: pointer;
    height: ${theme.spacing(5)};
    padding: ${theme.spacing(0, 3, 0, 6)};
    text-align: left;
    transition: background-color 0.3s ease;

    > span {
      left: ${theme.spacing(2)};
      position: absolute;
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,
};
