import { type HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, ContentLoader, ButtonBar, Button } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { MemberSelectionDropdown } from './member-selection-dropdown';

type Props = {
  name: string;
  memberIds: string[];
  onChange: (members: string[]) => void;
  onCancel: () => void;
  onSubmit: (members: string[]) => void;
};

export const MemberSelection = ({ name, memberIds, onCancel, onChange, onSubmit }: Props) => {
  const { t } = useTranslation('team-chat');
  const users = useTeamChatSelector((ctx) => ctx.users);
  const isCreatingConversation = useTeamChatSelector((ctx) => ctx.isCreatingConversation);

  const onFormSubmit: HTMLAttributes<HTMLFormElement>['onSubmit'] = (e) => {
    e.preventDefault();
    if (!memberIds.length) {
      return;
    }
    onSubmit(memberIds);
  };

  return (
    <form onSubmit={onFormSubmit}>
      <div css={channelInfoStyle}>
        <Text css={channelNameStyle} size='medium'>
          # {name}
        </Text>
        {memberIds.length > 0 && (
          <Text size='small' css={membersTextStyle}>
            {memberIds.length}
          </Text>
        )}
      </div>
      <div css={contentContainerStyle}>
        <MemberSelectionDropdown allUsers={users ?? []} selectedMemberIds={memberIds} onChange={onChange} />
      </div>
      <div css={bottomContainerStyle}>
        <Text css={stepTextStyle}>{t('Step 2 of 2')}</Text>

        <ButtonBar css={[buttonWidth, buttonBarStyle, marginRight]}>
          <Button
            variant='secondary'
            css={buttonWidth}
            onClick={() => onCancel()}
            type='button'
            trackingId='team-chat-2.0-new-channel-edit-name'
          >
            <Text>{t('Back')}</Text>
          </Button>
          <Button
            css={buttonWidth}
            disabled={!memberIds.length}
            type='submit'
            trackingId='team-chat-2.0-create-new-channel'
          >
            <Text color='white'>{t('Create')}</Text>
          </Button>
        </ButtonBar>
      </div>
      <ContentLoader show={isCreatingConversation} message={t('Creating a new conversation')} />
    </form>
  );
};

const channelInfoStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
});
const channelNameStyle = css({ color: theme.colors.black });
const membersTextStyle = css({
  backgroundColor: theme.colors.primary10,
  color: theme.colors.primary70,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0, 0.5),
});
const contentContainerStyle = css({ padding: theme.spacing(0, 3) });
const bottomContainerStyle = css({
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  borderTop: `1px solid ${theme.colors.neutral10}`,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});
const stepTextStyle = css({ paddingLeft: theme.spacing(3) });
const buttonBarStyle = css({ padding: 0 });
const buttonWidth = css({ width: 'fit-content' });
const marginRight = css({ marginRight: theme.spacing(3) });
