import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import {
  Text,
  useFormField,
  TextareaField,
  TextLink,
  useModalControl,
  TextField,
  Modal,
} from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import {
  editingAreaContainerStyle,
  modalTextAreaStyle,
  commentTextStyle,
  modalActionsStyle,
  containerStyle,
} from './info-with-editor.styles';

interface InfoEditorProps {
  title: string;
  placeholder?: string;
  value?: string;
  canEdit?: boolean;
  onClickSave?: (text: string) => Promise<void>;
  className?: string;
  comment?: string;
  isTextArea?: boolean;
  startAdornment?: ReactNode;
  canDisableSave?: boolean;
  conversationNames?: string[];
  saveButtonTrackingId?: string;
  cancelButtonTrackingId?: string;
  editButtonTrackingId?: string;
  maxLength?: number;
  isUpdatingConversation?: boolean;
}

export const InfoWithEditor: FC<InfoEditorProps> = ({
  title,
  placeholder,
  value,
  canEdit = false,
  onClickSave,
  className,
  comment,
  isTextArea = true,
  startAdornment,
  canDisableSave,
  conversationNames,
  saveButtonTrackingId,
  cancelButtonTrackingId,
  editButtonTrackingId,
  maxLength,
  isUpdatingConversation,
}) => {
  const editorProps = useFormField({ type: 'text', value });
  const { t } = useTranslation('team-chat');
  const { modalProps, openModal, closeModal } = useModalControl();
  const InputComponent = isTextArea ? TextareaField : TextField;
  // const {setLoading} = useModalLoadingState();
  const onSave = async () => {
    //   We don't want to make unnecessary API calls if the value hasn't changed
    if (onClickSave && value?.trim() !== editorProps.value.trim()) {
      await onClickSave(editorProps.value);
      closeModal();
    }
  };

  // TODO: fix the logic using useDebouncedValue, useEffect and useState
  const disableSave = useMemo(() => {
    if (!canDisableSave) {
      return false;
    }
    if (!editorProps.value) {
      return true;
    } else if (Array.isArray(conversationNames)) {
      return conversationNames.includes(editorProps.value.toLowerCase());
    } else {
      return !editorProps.value || value?.toLowerCase() === editorProps.value.toLowerCase();
    }
  }, [conversationNames, editorProps.value]);

  return (
    <div className={className}>
      <div css={containerStyle}>
        <Text size='large' weight='bold'>
          {title}
        </Text>
        {canEdit && (
          <TextLink onClick={openModal} trackingId={editButtonTrackingId}>
            <Text color='primary' weight='bold'>
              {t('Edit')}
            </Text>
          </TextLink>
        )}
      </div>
      <Text css={editingAreaContainerStyle}>{value || placeholder}</Text>
      <Modal {...modalProps} maxWidth={MIN_MODAL_WIDTH}>
        <Modal.Header onClose={closeModal}>
          <Text>{title}</Text>
        </Modal.Header>
        <Modal.Body>
          <InputComponent
            label={''}
            placeholder={placeholder}
            name={value ?? ''}
            {...editorProps}
            css={modalTextAreaStyle}
            style={{ width: '100%' }}
            startAdornment={startAdornment}
            maxLength={maxLength}
          />
          {comment && <Text css={commentTextStyle}>{comment}</Text>}
        </Modal.Body>
        <Modal.Actions
          css={modalActionsStyle}
          primaryLabel={t('Save')}
          secondaryLabel={t('cancel')}
          disablePrimary={!!disableSave}
          onPrimaryClick={onSave}
          onSecondaryClick={closeModal}
          primaryTrackingId={saveButtonTrackingId}
          secondaryTrackingId={cancelButtonTrackingId}
          primaryLoading={isUpdatingConversation}
        />
      </Modal>
    </div>
  );
};
