import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';
import DM from '../../assets/svg/dm.svg';
import Group from '../../assets/svg/group.svg';

type Props = {
  memberCount: number;
  type: TeamChatTypes.Conversation['type'];
};

export const EmptyConversation = ({ type, memberCount }: Props) => {
  const { t } = useTranslation('team-chat');
  const label =
    type === 'Group'
      ? t('This channel is all set and ready for discussion! Kick things off by starting a conversation!')
      : type === 'DM'
      ? memberCount > 1
        ? t('This group is ready for discussion. Begin by sharing your thoughts!')
        : t(`Looks like it’s just you two! Say hello and get the conversation rolling!`)
      : t("Let's start a conversation");

  return (
    <div css={[styles.container, dsStyles.flexCenter]}>
      {<img src={type === 'Group' ? Group : DM} />}
      <Text size='large' textAlign='center' color='subdued'>
        {label}
      </Text>
    </div>
  );
};

const styles = {
  container: css`
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    padding: ${theme.spacing(0, 4)};

    img {
      width: 100%;
    }
  `,
};
