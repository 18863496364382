import { useEffect } from 'react';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { RegisterTerminalNavigationSwitch } from './register-terminal-navigation-switch';
import { RegisterTerminalStepBodyProps } from './register-terminal.types';
import { RegisterTerminalStepper } from './stepper/register-terminal-stepper';

export const RegisterTerminalStripe = () => {
  return <RegisterTerminalNavigationSwitch body={RegisterTerminalStripeBody} />;
};

const RegisterTerminalStripeBody = ({
  onGoBack,
  onCancel,
  onComplete,
  onRegisterTerminal,
}: RegisterTerminalStepBodyProps) => {
  const { setMinWidth, resetMinWidth } = useCollectPaymentMultiContext();

  useEffect(() => {
    setMinWidth(776);

    return () => {
      resetMinWidth();
    };
  }, []);
  return (
    <RegisterTerminalStepper
      onComplete={onComplete}
      onCancel={onCancel}
      onGoBackFromStart={onGoBack}
      onRegisterTerminal={onRegisterTerminal}
    />
  );
};
