import { useScopedAppFlagStore } from '@frontend/scope';

interface UseAchFieldAccessResults {
  hasAchFieldAccess: boolean;
}

export const useAchFieldAccess = (): UseAchFieldAccessResults => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();

  return {
    hasAchFieldAccess: getFeatureFlagValue('ach-in-nwx-form-builder'),
  };
};
