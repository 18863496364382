import { demoDataUtils } from '../../../utils';
import { Overview, OverviewResponse } from '../hooks';

export const messagingOverviewDemoData = (locationIds: string[]): OverviewResponse => {
  const leadsContacted: Overview['leads_contacted'] = {
    existing_patients_counts: demoDataUtils.generateRandomInt(1, 500),
    new_patients_counts: demoDataUtils.generateRandomInt(1, 500),
    unscheduled_patients_counts: demoDataUtils.generateRandomInt(1, 500),
  };
  const totalPatients =
    (leadsContacted.existing_patients_counts || 0) +
    (leadsContacted.new_patients_counts || 0) +
    (leadsContacted.unscheduled_patients_counts || 0);

  const patientScheduled: Overview['patients_scheduled'] = {
    existing_patients_counts: demoDataUtils.generateRandomInt(1, leadsContacted.existing_patients_counts),
    new_patients_counts: demoDataUtils.generateRandomInt(1, leadsContacted.new_patients_counts),
  };

  const appointmentsCompleted: Overview['appointments_completed'] = {
    existing_patients_appts_counts: demoDataUtils.generateRandomInt(1, patientScheduled.existing_patients_counts),
    new_patients_appts_counts: demoDataUtils.generateRandomInt(1, patientScheduled.new_patients_counts),
  };

  return locationIds.map((locationId) => ({
    location_id: locationId,
    leads_contacted: {
      ...leadsContacted,
      sms_count: totalPatients + demoDataUtils.generateRandomInt(1, 200),
    },
    patients_scheduled: {
      ...patientScheduled,
      appointments_count:
        (patientScheduled.existing_patients_counts || 0) +
        (patientScheduled.new_patients_counts || 0) +
        demoDataUtils.generateRandomInt(1, 20),
    },
    appointments_completed: {
      ...appointmentsCompleted,
      appointments_count:
        (appointmentsCompleted.existing_patients_appts_counts || 0) +
        (appointmentsCompleted.new_patients_appts_counts || 0),
    },
  }));
};
