import { useEffect } from 'react';
import { SortBy_Enum, Order_Enum } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SortByEnum } from '../components/sort-dropdown';
import { useQuickFillRecipientShallowStore } from '../store';

interface RequestInfo {
  locationId: string;
  searchTerm: string;
  sortByValue: SortByEnum;
}

const getSortAndOrderValue = (sortBy: SortByEnum) => {
  switch (sortBy) {
    case SortByEnum.DateAsc:
      return { sortBy: SortBy_Enum.CREATED_AT, sortOrder: Order_Enum.ASC };
    case SortByEnum.DateDesc:
      return { sortBy: SortBy_Enum.CREATED_AT, sortOrder: Order_Enum.DESC };
    case SortByEnum.FirstNameAsc:
      return { sortBy: SortBy_Enum.FIRST_NAME, sortOrder: Order_Enum.ASC };
    case SortByEnum.FirstNameDesc:
      return { sortBy: SortBy_Enum.FIRST_NAME, sortOrder: Order_Enum.DESC };
    case SortByEnum.LastNameAsc:
      return { sortBy: SortBy_Enum.LAST_NAME, sortOrder: Order_Enum.ASC };
    case SortByEnum.LastNameDesc:
      return { sortBy: SortBy_Enum.LAST_NAME, sortOrder: Order_Enum.DESC };
    default:
      return { sortBy: SortBy_Enum.CREATED_AT, sortOrder: Order_Enum.DESC };
  }
};

export const useQuickFillRecipients = ({ locationId, searchTerm, sortByValue }: RequestInfo) => {
  const { setFilteredCount, clearFilteredCount } = useQuickFillRecipientShallowStore(
    'setFilteredCount',
    'clearFilteredCount'
  );

  const { sortBy, sortOrder } = getSortAndOrderValue(sortByValue);
  // Fetch Quick Fill Recipients - Paginated
  const infiniteQuery = ScheduleQueries.useQuickFillRecipientsInfiniteQuery({
    locationId,
    search: searchTerm,
    sortBy: sortBy,
    order: sortOrder,
  });
  const queryCount = infiniteQuery.data?.pages?.[0].totalCount || 0;

  useEffect(() => {
    // Do not update the filtered count if the query is still loading or fetching
    if (infiniteQuery.isFetching) {
      return;
    }

    if (!searchTerm) {
      clearFilteredCount(locationId);
      return;
    }

    if (queryCount !== undefined) {
      setFilteredCount(locationId, queryCount);
    }
  }, [infiniteQuery?.isLoading, infiniteQuery?.isFetching, queryCount]);

  return infiniteQuery;
};
