import { CallIntelTypes } from '@frontend/api-call-intel';
import { categories, contactTypes, getRandomElementNoMatch, randomBooleanNoMatch, sentiments } from './call-recordings';

const randomEditedAt = () => new Date().toISOString();

export function getCallEditHistory(
  call: CallIntelTypes.Call,
  restoredFields: CallIntelTypes.AnalysisTypeEnum[] = []
): CallIntelTypes.GetCallEditHistoryResponse {
  const editedFlags = call.isFieldEdited || {
    isAppointmentTypeEdited: false,
    isCategoryEdited: false,
    isContactTypeEdited: false,
    isSchedulingOpportunityEdited: false,
    isSchedulingOutcomeEdited: false,
    isSentimentEdited: false,
    isServiceQualityFlagEdited: false,
  };

  const editedByUser = call.officeUser;

  const original: CallIntelTypes.CallEditableFields = {};
  const current: CallIntelTypes.CallEditedField[] = [];

  const pushCurrentEdit = (
    analysisType: CallIntelTypes.AnalysisTypeEnum,
    fields: Partial<CallIntelTypes.CallEditableFields>
  ) => {
    current.push({
      fields: { ...fields },
      analysisType,
      editedBy: editedByUser as CallIntelTypes.OfficeUser,
      editedAt: randomEditedAt(),
    });
  };

  const shouldRestore = (analysisType: CallIntelTypes.AnalysisTypeEnum) => restoredFields.includes(analysisType);

  if (editedFlags.isContactTypeEdited) {
    const analysisType = CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CONTACT_TYPE;

    const currentValue = call.contactType ?? CallIntelTypes.ContactTypeEnum.CONTACT_UNKNOWN;

    const originalValue = shouldRestore(analysisType)
      ? currentValue
      : getRandomElementNoMatch(contactTypes, currentValue);

    original.contactType = originalValue;

    pushCurrentEdit(CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CONTACT_TYPE, {
      contactType: currentValue,
    });
  }

  if (editedFlags.isSentimentEdited) {
    const analysisType = CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SENTIMENT;

    const currentValue = call.sentiment ?? CallIntelTypes.SentimentEnum.SENTIMENT_UNKNOWN;

    const originalValue = shouldRestore(analysisType)
      ? currentValue
      : getRandomElementNoMatch(sentiments, currentValue);

    original.sentiment = originalValue;

    pushCurrentEdit(CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SENTIMENT, {
      sentiment: currentValue,
    });
  }

  if (editedFlags.isSchedulingOpportunityEdited) {
    const analysisType = CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY;

    const currentValue = call.schedulingOpportunity ?? undefined;

    const originalValue = shouldRestore(analysisType) ? currentValue : randomBooleanNoMatch(currentValue);

    original.schedulingOpportunity = originalValue;

    pushCurrentEdit(CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY, {
      schedulingOpportunity: currentValue,
    });
  }

  if (editedFlags.isSchedulingOutcomeEdited) {
    const analysisType = CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME;

    const currentValue = call.schedulingOutcome ?? undefined;

    const originalValue = shouldRestore(analysisType) ? currentValue : randomBooleanNoMatch(currentValue);

    original.schedulingOutcome = originalValue;

    pushCurrentEdit(CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME, {
      schedulingOutcome: currentValue,
    });
  }

  if (editedFlags.isCategoryEdited) {
    const analysisType = CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CATEGORY;

    const currentValue = call.categories ?? [];

    const candidate = shouldRestore(analysisType)
      ? currentValue[0]
      : getRandomElementNoMatch(categories, currentValue[0]);

    original.categories = [candidate];

    pushCurrentEdit(CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CATEGORY, {
      categories: currentValue,
    });
  }

  if (editedFlags.isAppointmentTypeEdited) {
    const analysisType = CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_APPOINTMENT_TYPE;
    const currentValue = call.appointmentTypes ?? [];

    const candidate = shouldRestore(analysisType)
      ? currentValue[0]
      : getRandomElementNoMatch(Object.values(CallIntelTypes.AppointmentTypeEnum), currentValue[0]);

    original.appointmentTypes = {
      appointmentType: [candidate],
    };
    pushCurrentEdit(CallIntelTypes.AnalysisTypeEnum.ANALYSIS_TYPE_APPOINTMENT_TYPE, {
      appointmentTypes: currentValue.length ? { appointmentType: currentValue } : null,
    });
  }

  return { original, current };
}
