import type { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import type { EventHandler, EventTypes, StreamChat } from 'stream-chat';
import { sentry } from '@frontend/tracking';
import type { GetWebsocketEventHandler } from '@frontend/websocket';
import type { ConversationEssentials, Message, User } from '../types';
import { CHANNEL_TYPE_TEAM_DESIGNATION } from './stream-constants';
import {
  constructConversationEssentialsFromStreamChannel,
  constructMessageFromStreamSearchResult,
  constructUserFromStreamUser,
  convertWeaveWebsocketPayload,
} from './stream-utils';

/**
 *
 * Since we're going to great lengths to abstract the StreamChat SDK, we are abstracting the subscription handler payloads as well into our own types
 */

type GenericHandler = (
  event: EventTypes,
  payload: {
    currentUser: User;
    user?: User;
    conversation?: ConversationEssentials;
    message?: Message;
  }
) => void;

export const subscribeToClient = (client: StreamChat, handler: GenericHandler) => {
  return client.on((e) => genericHandler(e, client, handler)).unsubscribe;
};

export const subscribeToChannel = (client: StreamChat, channelId: string, handler: GenericHandler) => {
  const channel = client.getChannelById(CHANNEL_TYPE_TEAM_DESIGNATION, channelId, {});
  if (!channel) {
    console.warn("Attempting to subscribe to a channel that doesn't exist");
    return () => {};
  }

  /**
   * We can either watch here or in the initial query. It feels more appropriate to watch here,
   * but it's more expensive because it has to make an api call for each channel, whereas, if
   * we watch in the initial query, we can automatically watch all channels in initial single query request.
   */
  const shouldWatch = false;
  if (shouldWatch) {
    // channel.watch();
  }
  const unsubscribe = channel.on((e) => genericHandler(e, client, handler)).unsubscribe;
  return () => {
    unsubscribe();
    if (shouldWatch) {
      // channel.stopWatching(); //I don't think we need to stop watching here because the watch will persist across connections.. and it's expensive to keep watching/unwatching
    }
  };
};

/**
 *
 * Takes a stream event and converts it into a generic payload for the generic handler
 */
const genericHandler = (event: Parameters<EventHandler>[0], client: StreamChat, handler: GenericHandler) => {
  const currentUser = client.user ? constructUserFromStreamUser(client.user) : undefined;
  if (!currentUser) {
    return undefined;
  }

  //TODO: we could break the handlers out for each different event type to clean this up, but they're similar enough that this works
  const userId =
    event.user?.id ?? event.user_id ?? event.reaction?.user_id ?? event.message?.user_id ?? event.member?.user_id;

  const totalUnreadCount = event.total_unread_count || event.unread_count || 0;
  const channel = event.channel ?? event.message?.channel;
  const streamUser = event.user ?? event.message?.user ?? (userId ? client.state.users[userId] : undefined);
  const user = streamUser ? constructUserFromStreamUser(streamUser) : undefined;
  // TODO: verify if channel_custom is actually doing anything.
  // The types from stream here are pretty different from the true payloads, so we basically override what we use here to fit reality
  const channelData = (event.channel_custom ?? event.channel) as unknown as
    | {
        is_dm: string | undefined;
        topic: string | undefined;
        name: string | undefined;
        description: string | undefined;
      }
    | undefined;
  const minimalChannelInfo = {
    channelId: event.channel_id || '',
    unreadCount: totalUnreadCount,
    topic: channelData?.topic || '',
    type: channelData?.is_dm ? 'DM' : 'Group',
    description: channelData?.description || '',
    name: channelData?.name ?? '',
  } satisfies ConversationEssentials;
  const conversation = channel ? constructConversationEssentialsFromStreamChannel(channel) : minimalChannelInfo;
  const message =
    event.message && currentUser
      ? constructMessageFromStreamSearchResult(event.message, currentUser.userID)
      : undefined;

  return handler(event.type, {
    currentUser,
    user,
    conversation,
    message,
  });
};

type AbstractHandler = (payload: ReturnType<typeof convertWeaveWebsocketPayload>) => void;
type WebsocketPayload = Parameters<GetWebsocketEventHandler<NotificationType.TEAM_CHAT>>[0];
export const getTeamChatWeaveWebsocketHandler = (handler: AbstractHandler) => {
  return (payload: WebsocketPayload) => {
    try {
      const converted = convertWeaveWebsocketPayload(payload);
      const isValidChannel = !converted.channelType || converted.channelType === CHANNEL_TYPE_TEAM_DESIGNATION;
      if (!isValidChannel) {
        return;
      }
      handler(converted);
    } catch (err: unknown) {
      console.error('Team Chat Websocket Subscription Error: ' + err);
      sentry.error({
        error: err,
        topic: 'team-chat',
      });
    }
  };
};
