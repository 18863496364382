import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { stepValueStyle } from './styles';

type Props = {
  locationName: string;
};

export const LocationStepValue = ({ locationName }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');

  return (
    <div css={stepValueStyle}>
      <Text weight='bold'>{locationName || t('Select Location')}</Text>
    </div>
  );
};
