import { useEffect, useState } from 'react';
import { Campaign, CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { DaysOfWeek } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from 'react-query';
import {
  BulkMessagingMutations,
  BulkMessagingQueries,
  BulkMessagingTypes,
  BulkMessagingUtils,
} from '@frontend/api-bulk-messaging';
import { ClientSettingsApi, ClientSettingsQueries } from '@frontend/api-client-settings';
import { MediaQueries } from '@frontend/api-media';
import { formatDate } from '@frontend/date';
import { FeatureAccessFlags, FeatureAccessPermissions, useFeatureAccess } from '@frontend/feature-access';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SettingsSection } from '@frontend/settings-ui';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Button,
  ButtonBar,
  CheckboxField,
  FormRow,
  Heading,
  StepStatus,
  Stepper,
  Text,
  useAlert,
} from '@frontend/design-system';
import { useBulkEmailEditorShallowStore, useBulkMessagingNavigator, useMonthUsageBreakdown } from '../../hooks';
import { getUniqueItems, sortItems } from '../../utils';
import { BulkMessageSegmentedBar } from '../bulk-message-segmented-bar';
import { BACKEND_DATE_TIME_FORMAT, DATE_TIME_FORMAT, DATE_TIME_SECOND_FORMAT } from '../utils';
import {
  useAttachmentStep,
  useAudienceStep,
  useEmailContentStep,
  useEmailForm,
  useLocationStep,
  useTextContentStep,
  useTitleStep,
  useWhenToSendStep,
} from './hooks';
import { ReplyTo } from './reply-to';
import { DayToSend, SendType, StepId } from './types';
import { isReadOnly } from './utils';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const convertToDaysOfWeek = (daysToSend: DayToSend[]): DaysOfWeek => {
  const daysOfWeek = {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  };

  daysToSend.forEach((day) => {
    daysOfWeek[day] = true;
  });

  return daysOfWeek;
};

type Props = {
  campaign?: Campaign;
  campaignType: CampaignType;
  isUnscheduled: boolean;
  onSaveDraft?: (state: Campaign) => Promise<string>;
  onSaveHiddenDraft?: (state: Campaign) => Promise<string>;
  onSchedule: (state: Campaign) => Promise<string>;
};

// TODO: Break this massive component up into smaller hooks, components, and chunks
export const CampaignEditor = ({
  campaign,
  campaignType,
  isUnscheduled,
  onSaveHiddenDraft,
  onSaveDraft,
  onSchedule,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const alert = useAlert();

  const store = useBulkEmailEditorShallowStore(
    'activeStep',
    'attachments',
    'audienceCount',
    'campaignId',
    'enableValidation',
    'getAsCampaign',
    'locationIds',
    'setActiveStep',
    'setAttachments',
    'setAudienceCount',
    'setCampaignId',
    'setConsent',
    'setEnableValidation',
    'setLocationIds',
    'setSendDaysOfWeek',
    'setSendPerDay',
    'setStartSendAt',
    'setTitle',
    'subject',
    'templateHtml',
    'templateJson'
  );

  const { selectedOrgId: orgId } = useAppScopeStore();
  const { locationsWithEnabledFlag, locationsWithACLAccess } = useFeatureAccess(
    FeatureAccessFlags.emailMarketing,
    FeatureAccessPermissions.emailMarketing
  );

  // invalidate drafts cache
  const queryClient = useQueryClient();
  const draftsQueryKey = BulkMessagingQueries.keys.draftCampaigns();
  const invalidateDrafts = (draft: Campaign) => {
    const previous = queryClient.getQueryData<Campaign[]>(draftsQueryKey) ?? [];
    const newDrafts = previous.filter((item) => item.campaignId !== draft.campaignId);
    newDrafts.push(draft);
    const sortedNewDrafts = BulkMessagingUtils.sortCampaigns(newDrafts);
    queryClient.setQueryData(draftsQueryKey, sortedNewDrafts);
    queryClient.invalidateQueries(draftsQueryKey);
  };

  const navigateTo = useBulkMessagingNavigator(campaignType, true);

  const readOnly = isReadOnly(campaign, isUnscheduled);
  const { formProps, seedValues, validateForm, values } = useEmailForm(readOnly);

  const {
    campaignPlanned,
    displayedMonth,
    monthAllotment,
    otherCampaignsPending,
    otherCampaignsUsed,
    remainingCampaigns,
  } = useMonthUsageBreakdown(values.date ?? '', formProps.locationIds.value, campaign, store.audienceCount);

  useEffect(() => {
    const uniqueLocationIdsToAdd = getUniqueItems(formProps.locationIds.value, store.locationIds);
    if (uniqueLocationIdsToAdd?.length) {
      seedValues({ locationIds: uniqueLocationIdsToAdd });
    }
  }, [sortItems([...store.locationIds]).join(',')]);

  // For various reasons, this validation is better handled outside of the useForm hook
  const sendIntervalError =
    values.sendOptions === SendType.SendSpecific &&
    (formProps.sendInterval.error || (values.sendInterval === '' && formProps.sendInterval.touched))
      ? formProps.sendInterval.error || t('This field is required')
      : '';

  const displayedLocations = [...new Set([...locationsWithACLAccess, ...(campaign?.locationIds ?? [])])];
  const showLocationsSelectorStep = locationsWithEnabledFlag.length > 1;
  const hideAttachmentStep = formProps.locationIds.value.length > 1;

  // media manager attachments by location
  const singularLocationId = formProps.locationIds.value[0]; // we only care when there is only one location selected
  const { data: allAttachments = [] } = MediaQueries.useGetAttachmentMedia(
    singularLocationId ? [singularLocationId] : undefined
  );

  const getSelectorSteps = () => {
    const steps: StepStatus = {
      1: 'active',
      2: 'inactive',
      3: 'inactive',
      4: 'inactive',
    };

    if (showLocationsSelectorStep) {
      steps[5] = 'inactive';
    }
    if (!hideAttachmentStep) {
      steps[6] = 'inactive';
    }
    return steps;
  };

  const [stepStatus, setStepStatus] = useState<StepStatus>(getSelectorSteps());

  const {
    querySetKeys: { sets, keys },
  } = ClientSettingsApi;
  const { useGetSetting } = ClientSettingsQueries;
  const { data: replyTo, isLoading: isReplyToLoading } = useGetSetting(sets.email, keys.email, {
    enabled: locationsWithEnabledFlag.length > 0,
    onError: () => {
      alert.error(t('Failed to get reply-to email address.'));
    },
  });

  const saveToStore = () => {
    const {
      attachments,
      consent = false,
      title = '',
      locationIds = [],
      date,
      time,
      sendOptions,
      sendInterval,
      daysToSend,
    } = values;
    const startSendAt =
      date && time ? formatDate(dayjs(`${date} ${time}`, DATE_TIME_FORMAT), BACKEND_DATE_TIME_FORMAT) : '';
    const sendPerDay = sendOptions === SendType.SendAll ? 0 : sendInterval ? parseInt(sendInterval) : 0;
    const sendDaysOfWeek =
      sendPerDay === 0
        ? null
        : daysToSend
        ? convertToDaysOfWeek(daysToSend as DayToSend[])
        : ({} as ReturnType<typeof convertToDaysOfWeek>);

    store.setTitle(title);
    store.setLocationIds(locationIds);
    store.setStartSendAt(startSendAt);
    store.setSendPerDay(sendPerDay);
    store.setSendDaysOfWeek(sendDaysOfWeek);
    store.setAttachments(attachments);
    store.setConsent(consent);
  };

  const createHiddenDraft = async () => {
    if (!store.campaignId) {
      try {
        if (!onSaveHiddenDraft) return;
        saveToStore();
        const state = store.getAsCampaign(campaignType);
        const campaignId = await onSaveHiddenDraft(state);
        store.setCampaignId(campaignId);
      } catch (error) {
        alert.error(t('An unexpected error occurred. Please try again.'));
        console.error(error);
      }
    }
  };

  const saveDraft = async () => {
    try {
      if (!onSaveDraft) return;
      saveToStore();
      const state = store.getAsCampaign(campaignType);
      const newCampaignId = await onSaveDraft(state);
      const createdAt = dayjs.utc().format(BACKEND_DATE_TIME_FORMAT);
      const campaignId = store.campaignId || newCampaignId;
      invalidateDrafts({ ...state, campaignId, createdAt });
      navigateTo.drafts();
    } catch (error) {
      alert.error(
        t(
          'An unexpected error occurred while saving your campaign. Please try again. If the problem persists, contact support.'
        )
      );
      console.error(error);
    }
  };

  const isEditorComplete = () => {
    const isEmailFieldsComplete = () => {
      return !!store.templateJson && !!store.subject;
    };

    const isTextFieldsComplete = () => {
      return !!store.templateHtml;
    };

    const dateTimeValue = dayjs(`${values.date} ${values.time}`, DATE_TIME_SECOND_FORMAT);
    const isDateTimeComplete =
      !formProps.date.error && !formProps.time.error && dateTimeValue.isValid() && dateTimeValue.isAfter(dayjs());

    const isSendIntervalComplete =
      values.sendOptions === SendType.SendAll || (!formProps.sendInterval.error && values.sendInterval !== '');

    const isDaysToSendComplete =
      values.sendOptions === SendType.SendAll || (!formProps.daysToSend.error && values.daysToSend?.length !== 0);

    const isCommonFieldsComplete =
      (readOnly && !formProps.title.error) ||
      (!readOnly &&
        !formProps.title.error &&
        !isLocationIdsError &&
        isDateTimeComplete &&
        isSendIntervalComplete &&
        isDaysToSendComplete &&
        !!store.audienceCount &&
        !formProps.consent.error &&
        !!replyTo);

    const isRemainingFieldsComplete =
      campaignType === CampaignType.EMAIL ? isEmailFieldsComplete() : isTextFieldsComplete();

    return isCommonFieldsComplete && isRemainingFieldsComplete;
  };

  const scheduleCampaign = async () => {
    try {
      saveToStore();
      validateEditor();

      if (!isEditorComplete()) return;

      if (!store.campaignId) {
        alert.error(t('An unexpected error occurred. Please try again or contact support.'));
        console.error(
          'Cannot schedule campaign. A campaign ID has somehow not been set and yet there is an audience count. How on earth has this happened?'
        );
        return;
      }

      const state = store.getAsCampaign(campaignType);
      await onSchedule(state);
      alert.success(isUnscheduled ? t('Email campaign scheduled!') : t('Email campaign updated!'));
      navigateTo.campaigns();
    } catch (error) {
      alert.error(
        isUnscheduled
          ? t(
              'An unexpected error occurred while scheduling your campaign. Please try again. If the problem persists, contact support.'
            )
          : t(
              'An unexpected error occurred while updating your campaign. Please try again. If the problem persists, contact support.'
            )
      );
      console.error(error);
    }
  };

  const openComposer = () => {
    saveToStore();
    if (!store.templateJson) {
      navigateTo.new();
      return;
    }
    navigateTo.composer();
  };

  const { mutateAsync: createAudienceSegment } = BulkMessagingMutations.useCreateSegment(
    {
      onError: () => {
        alert.error(t('An error occurred while saving your segment. Please try again or contact support.'));
      },
    },
    () => {
      alert.success(t('Segment added to campaign.'));
    }
  );

  const { refetch: refetchAudience } = BulkMessagingQueries.useGetAudience({ campaignId: store.campaignId, orgId });
  const [isCreatingSegment, setIsCreatingSegment] = useState(false);
  const handleCreateSegment = async (filters?: BulkMessagingTypes.AudienceFilters, listId?: string) => {
    if (formProps.locationIds.value.length === 0) return;
    if (!store.campaignId) {
      alert.error(t('An unexpected error occurred. Please contact support.'));
      console.error('Cannot save audience filters. A campaign ID has not been set.');
      return;
    }

    setIsCreatingSegment(true);
    await createAudienceSegment({
      campaignId: store.campaignId,
      orgId,
      segment: {
        filterOptions: filters,
        segmentId: '', // TODO: What should a new segmentId be?
        listId,
      },
    });

    const { data: audience } = await refetchAudience();
    store.setAudienceCount(audience?.total ?? 0);
    setIsCreatingSegment(false);
  };

  const validateEditor = () => {
    store.setEnableValidation(true);
    validateForm();
  };

  const getContentError = () => {
    if (!store.enableValidation) return '';
    if (campaignType === CampaignType.EMAIL) {
      if (!store.templateJson) {
        return t('Email content is required');
      }

      if (!store.subject) {
        return t('A subject is required');
      }
    }

    if (campaignType === CampaignType.SMS) {
      if (!store.templateHtml) {
        return t('Text content is required');
      }
    }

    return '';
  };

  const contentError = getContentError();
  const audienceError = store.enableValidation ? (store.audienceCount ? '' : t('An audience is required')) : '';

  const isTitleError = formProps.title.touched && !!formProps.title.error;
  const isDateError = formProps.date.touched && !!formProps.date.error;
  const isTimeError = formProps.time.touched && !!formProps.time.error;
  const isDaysToSendError =
    values.sendOptions === SendType.SendSpecific &&
    (!!formProps.daysToSend.error || (values.daysToSend?.length === 0 && formProps.daysToSend.touched));
  const isLocationIdsError = showLocationsSelectorStep ? !!formProps.locationIds.error : false;
  // checks the list of available media and make sure that it's available for the selected location
  const missingAttachments = (store.attachments || [])?.filter(
    (id) => !allAttachments?.find((media) => media.ID === id)
  );
  const isAttachmentError = store.attachments ? !!missingAttachments.length : false;
  const isConsentError = formProps.consent.touched && !!formProps.consent.error;

  const [touched, setTouched] = useState<Record<StepId, boolean>>({
    title: false,
    locations: false,
    'when-to-send': false,
    content: false,
    audience: false,
    attachments: false,
  });
  const attachmentsIndex = showLocationsSelectorStep ? 5 : 4;

  const updateStepperStatus = () => {
    if (readOnly) {
      if (showLocationsSelectorStep && !hideAttachmentStep) {
        setStepStatus({ 1: 'active', 2: 'completed', 3: 'completed', 4: 'completed', 5: 'completed', 6: 'completed' });
      } else if (showLocationsSelectorStep) {
        setStepStatus({ 1: 'active', 2: 'completed', 3: 'completed', 4: 'completed', 5: 'completed' });
      } else {
        setStepStatus({ 1: 'active', 2: 'completed', 3: 'completed', 4: 'completed' });
      }
      return;
    }

    const errors: boolean[] = [
      isTitleError,
      isDateError || isTimeError || isDaysToSendError || !!sendIntervalError,
      !!contentError,
      !!audienceError,
    ];
    if (showLocationsSelectorStep) {
      errors.splice(1, 0, !!formProps.locationIds.error);
    }
    if (!hideAttachmentStep) {
      errors.push(isAttachmentError);
    }

    const isSendOptionsPopulated =
      values.sendOptions === SendType.SendSpecific ? !!values.sendInterval && !!values.daysToSend : true;
    const populated: boolean[] = [
      !!values.title,
      !!values.date && !!values.time && isSendOptionsPopulated,
      !!store.templateHtml,
      !!store.audienceCount,
    ];
    if (showLocationsSelectorStep) {
      populated.splice(1, 0, !!values.locationIds);
    }
    if (!hideAttachmentStep) {
      populated.push(!!store.attachments);
    }

    const newStepStatus: StepStatus = {};
    errors.forEach((error, index) => {
      const key = index + 1;
      if (key === store.activeStep) {
        if (error) {
          newStepStatus[key] = 'errorActive';
        } else {
          newStepStatus[key] = 'active';
        }
      } else {
        if (error) {
          newStepStatus[key] = 'error';
        } else if (populated[index] && index === attachmentsIndex) {
          newStepStatus[key] = touched.attachments ? 'completed' : 'inactive';
        } else if (populated[index]) {
          newStepStatus[key] = 'completed';
        } else {
          newStepStatus[key] = 'inactive';
        }
      }
    });

    setStepStatus(newStepStatus);
  };

  useEffect(() => {
    updateStepperStatus();
  }, [
    store.activeStep,
    store.enableValidation,
    isTitleError,
    isDateError,
    isTimeError,
    isDaysToSendError,
    sendIntervalError,
    contentError,
    audienceError,
    isLocationIdsError,
  ]);

  useEffect(() => {
    if (store.enableValidation) {
      validateForm();
    }
  }, [store.enableValidation]);

  const getMaxStep = () => {
    let maxStep = 4;
    if (showLocationsSelectorStep) maxStep += 1;
    if (!hideAttachmentStep) maxStep += 1;
    return maxStep;
  };
  const maxStep = getMaxStep();

  const next = (stepId: StepId) => {
    const nextStep = Math.min(store.activeStep + 1, maxStep);
    store.setActiveStep(nextStep);
    const nextStepIdIndex = steps.findIndex((step) => step.id === stepId) + 1;
    const nextStepId = steps[nextStepIdIndex]?.id;
    if (nextStepId) setTouched({ ...touched, [nextStepId]: true });
  };

  const previous = (stepId: StepId) => {
    const previousStep = Math.max(store.activeStep - 1, 1);
    store.setActiveStep(previousStep);
    const previousStepIdIndex = steps.findIndex((step) => step.id === stepId) - 1;
    const previousStepId = steps[previousStepIdIndex]?.id;
    if (previousStepId) setTouched({ ...touched, [previousStepId]: true });
  };

  const getValidStepNumber = (step: number): number => {
    if (step > maxStep) return maxStep;
    if (step < 1) return 1;
    return step;
  };

  const skipTo = (step: number, stepId: StepId) => {
    if (step === store.activeStep) return;
    const validatedStep = getValidStepNumber(step);
    store.setActiveStep(validatedStep);
    setTouched({ ...touched, [stepId]: true });
  };

  const stepperControlProps = {
    next,
    previous,
  };

  const titleStep = useTitleStep({ ...stepperControlProps, fieldProps: formProps.title, readOnly });

  const locationStep = useLocationStep({
    ...stepperControlProps,
    displayedLocations,
    hide: !showLocationsSelectorStep,
    locationIdsFieldProps: formProps.locationIds,
    locationsWithACLAccess,
  });

  const whenToSendStep = useWhenToSendStep({
    ...stepperControlProps,
    fieldProps: {
      date: formProps.date,
      daysToSend: formProps.daysToSend,
      sendInterval: formProps.sendInterval,
      sendOptions: formProps.sendOptions,
      time: formProps.time,
    },
    readOnly,
    sendIntervalError,
  });

  const emailContentStep = useEmailContentStep({
    contentError,
    openComposer,
    ...stepperControlProps,
  });

  const textContentStep = useTextContentStep({
    contentError,
    ...stepperControlProps,
  });

  const contentStep = campaignType === CampaignType.EMAIL ? emailContentStep : textContentStep;

  const audienceStep = useAudienceStep({
    ...stepperControlProps,
    createHiddenDraft,
    handleCreateSegment,
    isCreatingSegment,
    locationIds: formProps.locationIds.value,
    next: !hideAttachmentStep ? next : undefined,
  });

  const attachmentStep = useAttachmentStep({
    ...stepperControlProps,
    allAttachments,
    fieldProps: formProps.attachments,
    hide: hideAttachmentStep,
    missingAttachments,
    readOnly,
    selectedLocationId: singularLocationId,
  });

  const steps = [titleStep, locationStep, whenToSendStep, contentStep, audienceStep, attachmentStep].filter(
    (item) => !item.hide
  );

  return (
    <>
      <BulkMessageSegmentedBar
        additional={campaignPlanned}
        month={displayedMonth}
        pending={otherCampaignsPending}
        quota={monthAllotment}
        sent={otherCampaignsUsed}
        showAllotments={showLocationsSelectorStep}
      />
      {readOnly ? (
        <div
          css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2), marginBottom: theme.spacing(6) }}
        >
          {steps.map((step, index) => (
            <SettingsSection key={index}>{step.readValue ?? step.collapsedValue}</SettingsSection>
          ))}
        </div>
      ) : (
        <Stepper
          stepStatus={stepStatus}
          shouldScrollToCard={false}
          css={{ maxWidth: 'initial', marginRight: 0, marginBottom: theme.spacing(6) }}
        >
          {steps.map((step, index) => (
            <Stepper.Card
              key={index}
              css={StepperCardStyles(readOnly)}
              onClick={readOnly ? undefined : (stepNumber) => skipTo(stepNumber, step.id)}
              preventDefaultOnClick
              stepValue={step.collapsedValue}
            >
              {step.Component}
            </Stepper.Card>
          ))}
        </Stepper>
      )}
      <section css={{ display: 'flex', marginBottom: theme.spacing(3) }}>
        <div
          css={{
            border: `solid 1px ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
            marginRight: theme.spacing(2),
            padding: theme.spacing(2),
          }}
        >
          <Heading level={2}>{campaignPlanned.toLocaleString()}</Heading>
          <Text>{t('Emails Will Be Used')}</Text>
        </div>
        <div
          css={{
            border: `solid 1px ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
            padding: theme.spacing(2),
            color: remainingCampaigns < 0 ? theme.font.colors.error : theme.colors.neutral90,
          }}
        >
          <Heading level={2} css={{ color: 'inherit' }}>
            {remainingCampaigns.toLocaleString()}
          </Heading>
          <Text css={{ color: 'inherit' }}>{t('Emails Remaining After Send')}</Text>
        </div>
      </section>
      <ReplyTo
        isMultiLocation={showLocationsSelectorStep}
        isReplyToLoading={isReplyToLoading}
        replyTo={replyTo}
        css={{ marginBottom: theme.spacing(3) }}
      />
      <FormRow>
        <CheckboxField
          css={{
            div: {
              alignSelf: 'center',
            },
            label: {
              fontSize: theme.font.size.small,
              color: isConsentError ? theme.font.colors.error : theme.font.colors.default,
            },
          }}
          {...formProps.consent}
          label={t(
            "Several laws in the US and Canada require that emails sent by businesses to consumers are appropriate and are sent only after receiving the individual's consent. By checking this box, you confirm that the recipients of this email have opted-in to receive messages."
          )}
          trackingId={`${BulkEmailPrefixes.Editor}-consent-checkbox`}
        />
      </FormRow>
      {isConsentError && (
        <Text color='error' size='small' css={{ margin: theme.spacing(-1, 0, 3, 0) }}>
          {formProps.consent.error}
        </Text>
      )}
      <ButtonBar css={{ justifyContent: 'start', padding: 0 }}>
        <Button onClick={scheduleCampaign} trackingId={`${BulkEmailPrefixes.Editor}-schedule-campaign-btn`}>
          {isUnscheduled ? t('Schedule Campaign') : t('Update Campaign')}
        </Button>
        {!!onSaveDraft && (
          <Button variant='secondary' onClick={saveDraft} trackingId={`${BulkEmailPrefixes.Editor}-save-draft-btn`}>
            {t('Save as Draft')}
          </Button>
        )}
      </ButtonBar>
    </>
  );
};

const StepperCardStyles = (readOnly: boolean) => ({
  '&[aria-current="false"]': {
    cursor: readOnly ? 'not-allowed' : 'pointer',
    height: 'fit-content !important', // This is needed since the height is a style prop
    '.step-content-title': {
      overflow: 'initial',
    },
  },
  '.step-content-title': {
    height: 'fit-content',
  },
});
