import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import {
  NakedButton,
  PopoverDialog,
  usePopoverDialog,
  useModalControl,
  Heading,
  IconButton,
  SpinningLoader,
  Button,
} from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { UserStatusSettings } from '../user-status-settings/user-status-settings';
import {
  containerStyles,
  textStyle,
  textContainerStyle,
  teamChatPopoverStyles,
  settingsButtonStyle,
} from './chat-title-bar.styles';

export const ChatTitleBar = () => {
  const t = useTranslation('team-chat').t;
  const { getTriggerProps, getDialogProps, close } = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: { x: -24, y: 0 },
  });
  const { modalProps } = useModalControl();
  const { navigate: settingsNavigate } = useSettingsNavigate();

  const openDynamicConversation = useTeamChatSelector((ctx) => ctx.openDynamicConversation);
  const isCurrentUserStatusOpen = useTeamChatSelector((ctx) => ctx.isCurrentUserStatusOpen);
  const openUserStatus = useTeamChatSelector((ctx) => ctx.openUserStatus);
  const closeUserStatus = useTeamChatSelector((ctx) => ctx.closeUserStatus);
  const restart = useTeamChatSelector((ctx) => ctx.restart);
  const isDataLoading = useTeamChatSelector((ctx) => ctx.isDataLoading);

  return (
    <section css={containerStyles}>
      <NakedButton css={textContainerStyle} {...getTriggerProps()}>
        <Heading css={textStyle} level={2}>
          {t('Team Chat')}
        </Heading>
        <Icon name='caret-down-tiny' size={10} />
      </NakedButton>
      <PopoverDialog {...getDialogProps()} css={teamChatPopoverStyles}>
        <Button
          onClick={() => {
            close();
            openUserStatus();
          }}
          variant='secondary'
          css={settingsButtonStyle}
          trackingId='team-chat-2.0-open-my-status-modal'
        >
          {t('My Status')}
        </Button>
        <Button
          onClick={() => {
            close();
            settingsNavigate({ to: `/personal/notifications/:type`, params: { type: 'chat-message-new' } });
          }}
          variant='secondary'
          css={settingsButtonStyle}
          trackingId='team-chat-2.0-open-team-chat-settings'
        >
          {t('Team Chat Settings')}
        </Button>
        <Button
          variant='secondary'
          css={[
            settingsButtonStyle,
            css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `,
          ]}
          onClick={() => {
            close();
            restart();
          }}
          trackingId='team-chat-2.0-reload-team-chat'
        >
          <>
            <span>{t('Reload Team Chat')}</span>
            {isDataLoading && <SpinningLoader size={'xs'} />}
          </>
        </Button>
      </PopoverDialog>
      <IconButton
        label={t('New Message')}
        showLabelOnHover
        onClick={() => openDynamicConversation(undefined)}
        trackingId='team-chat-2.0-start-new-conversation'
      >
        <Icon size={24} name='draft' />
      </IconButton>
      <UserStatusSettings
        modalProps={{ ...modalProps, show: isCurrentUserStatusOpen }}
        closeModal={() => closeUserStatus()}
      />
    </section>
  );
};
