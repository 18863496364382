import { PanelPortal, PanelHeader, PanelContent } from '@frontend/panel-engine';
import { TaskList, CreateTask } from './components';
import { TaskCenterProvider } from './provider';

export const TaskCenterPanel = ({ onClose }: { onClose: () => void }) => {
  return (
    <PanelPortal id='task-center' width='medium' onClose={onClose}>
      <TaskCenterProvider>
        {/* This PanelHeader.Close does not accepts css, hence passing style to align it in the end */}
        <PanelHeader.Close iconName='x' size='large' style={{ alignSelf: 'flex-end' }} />
        <PanelContent padding={false} css={{ display: 'flex' }}>
          <TaskList />
          <CreateTask />
        </PanelContent>
      </TaskCenterProvider>
    </PanelPortal>
  );
};
