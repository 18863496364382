import React from 'react';
import { useEditor } from '@craftjs/core';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, Text } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../constants';

export type SettingsPanelProps = {
  title: string;
  children: React.ReactNode;
};

export const SettingsPanel = ({ title, children, ...rest }: SettingsPanelProps) => {
  const { t } = useTranslation('content-composer-settings');
  const { actions } = useEditor();

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(1)};
          padding: ${theme.spacing(2, 2, 0, 2)};
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            display: none;
          }
        `}
      >
        <Heading level={3}>{title}</Heading>
        <NakedButton
          onClick={actions.clearEvents}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            name='check'
            color='primary'
            size={20}
            css={css`
              margin-right: ${theme.spacing(0.5)};
            `}
          />
          <Text as='span' color='primary'>
            {t('Done')}
          </Text>
        </NakedButton>
      </div>
      <div
        css={css`
          padding: ${theme.spacing(2, 2, 10)};
          @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
            margin-bottom: ${theme.spacing(7)};
            padding: ${theme.spacing(0, 2)};
          }
        `}
        {...rest}
      >
        {children}
      </div>
    </>
  );
};
