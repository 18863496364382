import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/phone_hours.pb';
import { useTranslation } from '@frontend/i18n';
import { TextField, useFormField } from '@frontend/design-system';
import { AddStepPanelProps, ButtonBar, HeaderBar } from './add-step-panel';

export const PhoneHoursStep = ({ initialState, onClose, onProceedClick, onBackClick }: AddStepPanelProps) => {
  const { t } = useTranslation('phone');

  const fieldProps = useFormField({
    required: true,
    type: 'text',
    value: initialState?.callObject?.primitiveName,
  });

  const handleProceedClick = () => {
    onProceedClick({
      callObject: {
        primitiveId: initialState?.callObject.primitiveId ?? '',
        primitiveName: fieldProps.value,
        instructionId: initialState?.callObject.instructionId ?? '',
        instructionSetId: initialState?.callObject.instructionSetId ?? '',
        // @ts-expect-error this is just temporary mock data
        phoneHoursExpansion: initialState?.callObject.phoneHoursExpansion ?? {
          phoneHours: mockPhoneHours,
        },
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Phone Hours')} onClose={onClose} />

      <TextField {...fieldProps} name='phoneHours' label={t('Phone hours Name')} />

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!fieldProps.value}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />
    </>
  );
};

const mockPhoneHours = [
  {
    scheduleId: '',
    type: ScheduleType.OPEN,
    rules: [
      {
        dayOfWeek: 1,
        startTime: '08:00:00',
        endTime: '17:00:00',
      },
      {
        dayOfWeek: 2,
        startTime: '08:00:00',
        endTime: '17:00:00',
      },
      {
        dayOfWeek: 3,
        startTime: '08:00:00',
        endTime: '17:00:00',
      },
      {
        dayOfWeek: 4,
        startTime: '08:00:00',
        endTime: '19:00:00',
      },
      {
        dayOfWeek: 5,
        startTime: '08:00:00',
        endTime: '17:00:00',
      },
      {
        dayOfWeek: 6,
        startTime: '09:00:00',
        endTime: '17:00:00',
      },
    ],
    name: 'Open',
  },
  {
    scheduleId: '',
    type: ScheduleType.BREAK,
    rules: [
      {
        dayOfWeek: 1,
        startTime: '012:00:00',
        endTime: '17:00:00',
      },
      {
        dayOfWeek: 2,
        startTime: '012:00:00',
        endTime: '13:00:00',
      },
      {
        dayOfWeek: 3,
        startTime: '012:00:00',
        endTime: '13:00:00',
      },
      {
        dayOfWeek: 4,
        startTime: '012:00:00',
        endTime: '13:00:00',
      },
      {
        dayOfWeek: 5,
        startTime: '012:00:00',
        endTime: '13:00:00',
      },
    ],
    name: 'Lunch',
  },
  {
    scheduleId: '',
    type: ScheduleType.BREAK,
    rules: [
      {
        dayOfWeek: 5,
        startTime: '012:00:00',
        endTime: '14:00:00',
      },
    ],
    name: 'Lunch w/ Games',
  },
  {
    scheduleId: '',
    type: ScheduleType.BREAK,
    rules: [
      {
        dayOfWeek: 1,
        startTime: '08:00:00',
        endTime: '12:00:00',
      },
    ],
    name: 'Breakfast w/ Games',
  },
];
