import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const personSelectorStyles = {
  header: css`
    padding: ${theme.spacing(1, 2)};
  `,
  content: css`
    padding: ${theme.spacing(1, 2)};
  `,
  footer: css`
    padding: ${theme.spacing(1, 2, 2, 2)};
    display: flex;
    & > button {
      flex: 1;
    }
    & > button > svg {
      margin-right: ${theme.spacing(1)};
    }
  `,
};
