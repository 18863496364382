import { FC } from 'react';
import { css } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, Heading, Text } from '@frontend/design-system';
import { getBundleTrialDays, getUpgradeBundleDisplayName } from '../../self-upgrade.util';

interface BundleUpgradeHeaderProps {
  showTrialBadge?: boolean;
  bundle: SalesforceAccountProductBundle;
}

export const BundleUpgradeHeader: FC<BundleUpgradeHeaderProps> = ({ showTrialBadge = true, bundle }) => {
  const { t } = useTranslation('bundleUpgrade');
  return (
    <div css={containerStyles}>
      {showTrialBadge && (
        <Chip leftElement={<Icon name='award' size={16} />} variant='seaweed' css={{ width: 'max-content' }}>
          {t('Free Trial')}
        </Chip>
      )}
      <Heading level={2}>{t('{{bundleName}} Bundle', { bundleName: getUpgradeBundleDisplayName(bundle) })}</Heading>
      <Text size='medium'>
        {t('Get the {{bundleName}} Bundle free for {{count}} days.', {
          bundleName: getUpgradeBundleDisplayName(bundle),
          count: getBundleTrialDays(bundle),
        })}
      </Text>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
});
