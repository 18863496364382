import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Global, css } from '@emotion/react';

interface Props {
  documentTitle?: string;
  show: boolean;
  onClose?: () => void;
  children: React.ReactElement;
  landscape?: boolean;
  showBackgroundGraphics?: boolean;
}

/**
 * This component will render given children(print only) element on container element '#print' (with help of react portal)
 * which present as sibling of application's root container element '#root'.
 *
 * NOTE: on print, this will hide whole application(#root) and show only passed children component
 */
export const PrintDialog = ({
  children,
  show,
  onClose,
  documentTitle,
  landscape = false,
  showBackgroundGraphics = false,
}: Props) => {
  const handleAfterPrint = () => {
    onClose && onClose();
  };

  useEffect(() => {
    window.addEventListener('afterprint', handleAfterPrint);
    return () => window.removeEventListener('afterprint', handleAfterPrint);
  }, []);

  useEffect(() => {
    if (!show) return;

    const title = document.title;
    if (documentTitle) document.title = documentTitle;
    window.print();
    if (documentTitle) document.title = title;
  }, [show]);

  const styles = css({
    '#print': {
      display: 'none',
    },
    '@media print': {
      '#root': {
        display: 'none',
      },
      '#root ~ *:not(#print)': {
        visibility: 'hidden',
      },
      '#print': {
        display: 'block',
        WebkitPrintColorAdjust: showBackgroundGraphics ? 'exact' : 'economy',
        colorAdjust: showBackgroundGraphics ? 'exact' : 'economy',
      },
      '@page': {
        size: 'portrait',
      },
    },
  });
  const landScapeStyles = css({
    '#print': {
      display: 'none',
    },
    '@media print': {
      '#root': {
        display: 'none',
      },
      '#root ~ *:not(#print)': {
        visibility: 'hidden',
      },
      '#print': {
        display: 'block',
        WebkitPrintColorAdjust: showBackgroundGraphics ? 'exact' : 'economy',
        colorAdjust: showBackgroundGraphics ? 'exact' : 'economy',
      },
      '@page': {
        size: 'landscape',
      },
    },
  });
  return (
    <>
      {createPortal(children, document.getElementById('print') as HTMLDivElement)}
      <Global styles={landscape ? landScapeStyles : styles} />
    </>
  );
};
