import { i18next } from '@frontend/i18n';

export const WEAVE_HELP_URLS = {
  INSTALL_TEXT_CONNECT: 'https://www.weavehelp.com/weavehelp/article/install-and-use-text-connect',
  ADD_ONLINE_SCHEDULING_LINK:
    'https://www.weavehelp.com/hc/en-us/articles/4414683486875-Add-the-Online-Scheduling-Link-to-Your-Website',
};

export const scheduleDirectAvailabilityBannerText = {
  officeHoursDirectAvailabilityTitle: i18next.t('Now managing availability through your PMS', {
    ns: 'schedule',
  }),
  officeHoursDirectAvailabilityMessage: i18next.t(
    'Availability for Weave Online Scheduling is now based on your practice management system settings.',
    { ns: 'schedule' }
  ),
  providerScheduleDirectAvailabilityTitle: i18next.t('Now managing availability through your PMS', {
    ns: 'schedule',
  }),
  providerScheduleDirectAvailabilityMessage: i18next.t(
    'Provider availability for Weave Online Scheduling is now based on your practice management system settings.',
    { ns: 'schedule' }
  ),
};
