import { FC } from 'react';
import { css } from '@emotion/react';
import { DashboardWidget, DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useGetLocationSettingsDetails } from '@frontend/insurance-verification';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedButton, Text } from '@frontend/design-system';

/**
 * @dashboard-widget
 *
 * id: wiv-setup
 * title: WIV Setup
 * description: Redirect user to WIV setup page if not done already.
 * icon: insurance-incomplete-small
 */
export const WIVSetupWidget: DashboardWidgetFC<{ onSkip?: () => void }> = ({ onSkip }) => {
  const { t } = useTranslation('insuranceDetails');
  const { selectedLocationIds } = useAppScopeStore();

  const { navigate } = useSettingsNavigate();

  const { data: locationSettingsData, isLoading: isLoadingLocationSettings } = useGetLocationSettingsDetails(
    selectedLocationIds[0]
  );

  const redirectToSetup = () => {
    navigate({ to: '/insurance' });
  };

  const handleSkip = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSkip?.();
  };

  const isSetupComplete =
    !isLoadingLocationSettings &&
    (!locationSettingsData?.supportAutoVerification || locationSettingsData?.isAutoVerification);

  return (
    <DashboardWidget
      onClick={redirectToSetup}
      data-trackingid='wiv-setup-widget-skip'
      css={[isSetupComplete && setupCompleteContainerStyles]}
    >
      <DashboardWidget.Content css={[contentContainerStyles]}>
        <ContentLoader show={isLoadingLocationSettings} />
        {isSetupComplete ? (
          <SetupCompleteIcon />
        ) : (
          <Icon name='in-progress-small' size={16} css={{ color: theme.colors.neutral20 }} />
        )}
        <Text size='large' css={{ flex: 1 }} color={isSetupComplete ? 'subdued' : 'default'}>
          {t('Activate Insurance Verification')}
        </Text>
        {!isSetupComplete && !!onSkip && (
          <NakedButton trackingId='wiv-setup-widget-skip' onClick={handleSkip}>
            <Text size='large' color='subdued'>
              {t('Skip')}
            </Text>
          </NakedButton>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

const SetupCompleteIcon: FC = () => {
  return (
    <div css={roundIconContainerStyles}>
      <Icon name='check-small' css={{ color: theme.colors.success50 }} />
    </div>
  );
};

WIVSetupWidget.config = {
  size: 'micro-narrow',
  feature: 'insurance',
};

const contentContainerStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  paddingTop: '6px',
  margin: 'auto 0',
});

const setupCompleteContainerStyles = css({
  backgroundColor: theme.colors.neutral5,
});

const roundIconContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.colors.white,
  borderRadius: theme.borderRadius.full,
  padding: theme.spacing(0.5),
  width: 24,
  height: 24,
});
