import dayjs from 'dayjs';
import { ScheduleRateResponse } from '../hooks';

const rates = [0.97, 0.99, 0.98, 0, 0.85, 0.94];
const weaveAverages = [0.5, 0.6, 0.55, 0.26, 0.65, 0.75];

const last6Months = Array.from({ length: 6 }, (_, i) => i)
  .map((i) => {
    return dayjs()
      .subtract(i + 1, 'month')
      .format('MMM');
  })
  .reverse();

export const appointmentsScheduleRate = (): ScheduleRateResponse => {
  return {
    dates: last6Months.map((month, index) => {
      return {
        key: month,
        rate: rates[index],
        weave_average: weaveAverages[index],
      };
    }),
    rate_difference: -0.0141,
    time_period: 'month',
    weave_average: 0.85,
  };
};
