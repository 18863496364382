import { MouseEventHandler } from 'react';
import { AdaptoActions } from '@frontend/adapto-actions';
import { CommunicationPreferencesAction } from '@frontend/contact-actions-communication-preferences';
import { DeleteContactAction } from '@frontend/contact-actions-delete-contact';
import {
  ContactPaymentAction,
  PaymentAction,
  PaymentHighlightedAction,
  usePaymentAction,
} from '@frontend/contact-actions-payment';
import { CallAction, usePhoneCallAction } from '@frontend/contact-actions-place-call';
import { QuickFillAction, useQuickFillAction } from '@frontend/contact-actions-quickfill';
import { ReviewActionComponents, ReviewActionHooks } from '@frontend/contact-actions-review-invitation';
import { SendFormAction } from '@frontend/contact-actions-send-form';
import { MessageAction, useMessageAction } from '@frontend/contact-actions-send-message';
import { GenerateServiceReport } from '@frontend/contact-actions-service-report';
import { useContactPanelShallowStore } from '@frontend/shared';

export const dialUrl = `/desktop/v1/phones/dial`;
export const endUrl = `/desktop/v1/phones/end`;
export const holdUrl = `/desktop/v1/phones/hold`;
export const resumeUrl = `/desktop/v1/phones/resume`;

type Action = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const useViewAction = (personId?: string, locationId?: string): Action => {
  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  return {
    onClick: () => {
      if (personId) {
        setPersonId(personId, true, locationId);
      }
    },
  };
};

const ViewButton = ({ personId, locationId }: { personId?: string; locationId?: string }) => {
  const trackingId = 'global-action-button-contact-profile';
  const { onClick } = useViewAction(personId, locationId);

  return (
    <AdaptoActions.Action
      label='Contact Profile'
      disabled={!personId}
      onClick={onClick}
      trackingId={trackingId}
      icon='user'
    />
  );
};

export const actions = {
  usePhoneCallAction,
  useQuickFillAction,
  useMessageAction,
  usePaymentAction,
  useViewAction,
  useReviewAction: ReviewActionHooks.useReviewAction,
};

export const Actions = {
  /**
   * To use this button properly, you can provide this contexts-
   * - If this action is associated with a contact or person, provide it in the `person` prop, or at least the `personId`.
   * - If this action is associated with a specific phone number, or to provide a default phone number selection, provide it in the `phoneNumber` prop.
   * - if you want the call to happen from a specific office number, provide the outbound number in the `outboundNumber` prop.
   */
  Call: CallAction,
  QuickFill: QuickFillAction,
  /**
   * To use this button properly, provide as much context as possible:
   * - If this action is associated with a contact or person, provide it in the `person` prop, or at least the `personId`.
   * - If this action is associated with a specific phone number, or to provide a default phone number selection, provide it in the `phoneNumber` prop.
   * - If this action is associated with a specific locationId, only departments/outbound numbers from that location will be available to select.
   * - If this action is associated with a specific department or outbound sms number, provide the associated departmentId in the `departmentId` prop.
   */
  Message: MessageAction,
  View: ViewButton,
  Payments: PaymentAction,
  PaymentsHighlighted: PaymentHighlightedAction,
  ContactPayments: ContactPaymentAction,
  SendForm: SendFormAction,
  Review: ReviewActionComponents.ReviewAction,
  DeleteContact: DeleteContactAction,
  ServiceReport: GenerateServiceReport,
  CommunicationPreferences: CommunicationPreferencesAction,
};
