import { FC } from 'react';
import { css } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { Actions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';

interface ContactActionsProps {
  onCall?: () => void;
  person: PersonTypes.Person;
  locationId?: string;
}

export const ContactActions: FC<React.PropsWithChildren<ContactActionsProps>> = ({ person, locationId }) => {
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { t } = useTranslation('contacts');
  const singleLocationId = selectedLocationIds[0];
  const groupId = locationId || singleLocationId;

  const hasACL = hasSchemaACL(groupId, Permission.REVIEW_READ);

  const { getLocationsByDatasource } = DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(person.SourceID, person.ClientLocationID);

  const { firstActiveLocationId, isLoading } = useMerchant();
  const canShowRequestPaymentAction = firstActiveLocationId && !isLoading;

  return (
    <AdaptoActions
      maxActions={5}
      variant='secondary-buttons'
      css={css`
        .action-item--secondary {
          padding-left: ${theme.spacing(1)};
          padding-right: ${theme.spacing(1)};
        }
      `}
    >
      {canShowRequestPaymentAction && (
        <Actions.ContactPayments
          context={{ person, personId: person.PersonID, locationId: singleLocationId }}
          trackingIdSuffix='profile-contact-actions'
        />
      )}

      <Actions.Message
        label={t('Message')}
        context={{
          personId: person.PersonID,
          threadGroupId: locationId,
          groupIds: locationId ? [locationId] : undefined,
        }}
        trackingIdSuffix='contact-panel'
      />

      <Actions.Call label={t('Call')} context={{ person }} trackingIdSuffix='profile-contact-actions' />

      <Actions.SendForm
        label={t('Send Form')}
        context={{
          locationId: singleLocationId,
          person,
        }}
        trackingIdSuffix='profile-contact-actions'
      />

      <Actions.QuickFill
        label={t('Add to Quick Fill')}
        personId={person.PersonID}
        locations={locations}
        trackingIdSuffix='profile-contact-actions'
      />

      {hasACL && (
        <Actions.Review
          label={t('Review')}
          groupId={groupId}
          personId={person.PersonID}
          trackingIdSuffix='-profile-contact-actions'
        />
      )}
      <Actions.CommunicationPreferences
        onClick={() => {
          const url = `https://book2.getweave.com/${singleLocationId}/preferences-portal/${person.MobilePhone.replace(
            '+1',
            ''
          )}`;
          window.open(url, '_blank', 'noopener,noreferrer');
        }}
        label={t('Communication Preferences')}
        trackingId='communication-preferences'
      />
    </AdaptoActions>
  );
};
