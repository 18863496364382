import { FC, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { Virtuoso } from 'react-virtuoso';
import { PersonHelpers } from '@frontend/api-person';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { useCreateAccountTooltip } from '../../hooks/useCreateAccountTooltip';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds, PaymentActionTrackingIds } from '../../tracking-ids';
import { PaymentActionEnum } from '../../types';

interface AppointmentsPanelProps {
  appointments: Appointment[];
  styles?: Interpolation<Theme>;
}

export const AppointmentsPanel: FC<AppointmentsPanelProps> = ({ appointments = [], styles }) => {
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });
  if (appointments.length) {
    return (
      <div css={[containerStyles, styles]}>
        <Text size='medium' color='subdued'>
          {t('Recent Appointments')}
        </Text>
        <Virtuoso<Appointment>
          data={appointments}
          itemContent={(index, appointment) => (
            <AppointmentCard key={`${appointment.id ?? ''}${index}`} appointment={appointment} />
          )}
        />
      </div>
    );
  }

  return null;
};

const AppointmentCard: FC<{ appointment: Appointment }> = ({ appointment }) => {
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });
  const { hasPaymentsAccount, handleAction, source } = usePaymentsPromotion();

  const { triggerProps, tooltipRender } = useCreateAccountTooltip();

  const { firstName, lastName, fromTime, toTime } = useMemo(() => {
    const person = appointment.person ?? {};
    const personName = PersonHelpers.getFullName({
      FirstName: person.firstName,
      LastName: person.lastName,
      PreferredName: person.preferredName,
    });
    const fromTime = formatDate(appointment.start, 'h:mm A');
    const toTime = formatDate(dayjs(appointment.start).add(appointment.duration ?? 0, 'm'), 'h:mm A');
    const [firstName, lastName] = personName.split(/\s(.*)/s);
    return {
      fromTime,
      toTime,
      firstName,
      lastName,
    };
  }, [appointment]);

  return (
    <div css={cardStyles}>
      <div>
        <Text size='medium'>
          <strong>{firstName}</strong> {lastName}
        </Text>
        <Text size='small' color='light'>
          {fromTime} - {toTime}
        </Text>
      </div>
      <Button
        variant='tertiary'
        trackingId={getPaymentHVATrackingIds({
          source,
          trackingId: PaymentActionTrackingIds[PaymentActionEnum.CREATE_INVOICE],
        })}
        onClick={() => handleAction(PaymentActionEnum.CREATE_INVOICE)}
        disabled={!hasPaymentsAccount}
        {...(hasPaymentsAccount ? null : triggerProps)}
      >
        {t('Request Payment')}
      </Button>
      {tooltipRender}
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: 252,
  gap: theme.spacing(1),
  padding: theme.spacing(0, 1),
});

const cardStyles = css({
  borderLeft: `4px solid ${theme.colors.warning50}`,
  background: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.75, 2),
  margin: theme.spacing(1, 1, 0, 0),
  boxShadow: theme.shadows.light,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
