import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { CaretDownIconSmall, PopoverDialog, SecondaryButton, usePopoverDialog } from '@frontend/design-system';
import { CustomDatePicker, CustomDatePickerProps } from './custom-date-picker';

type CustomDatePickerPopoverProps = Omit<CustomDatePickerProps, 'closePicker'> & { className?: string };

const styles = {
  popover: css`
    padding: ${theme.spacing(2, 2, 1)};
    max-width: ${theme.spacing(50)};

    @media (max-width: ${breakpoints.xsmall.max}px) {
      max-width: ${theme.spacing(42)};
    }
  `,
  selectButton: css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `,
};

export const CustomDatePickerPopover = ({ dateRangeProps, onSelect, className }: CustomDatePickerPopoverProps) => {
  const { t } = useTranslation();
  const { getDialogProps, getTriggerProps, close } = usePopoverDialog({ placement: 'bottom-end' });

  return (
    <div>
      <SecondaryButton {...getTriggerProps()} className={className} css={styles.selectButton}>
        {t('Select Date')}
        <CaretDownIconSmall />
      </SecondaryButton>
      <PopoverDialog {...getDialogProps()} css={styles.popover}>
        <CustomDatePicker dateRangeProps={dateRangeProps} closePicker={() => close()} onSelect={onSelect} />
      </PopoverDialog>
    </div>
  );
};
