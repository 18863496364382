import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.spacing(2)};
  padding-top: ${theme.spacing(2)};
`;

export const buttonStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(0, 2)};

  &:hover {
    box-shadow: ${theme.shadows.floating};
  }
`;

export const titleStyle = css`
  padding: ${theme.spacing(2, 0)};
  align-self: baseline;
`;
