import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const stepperCardStyle = css`
  background: ${theme.colors.white};
`;

export const stepValueStyle = css`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  margin-left: ${theme.spacing(1)};
`;
