import { useCallback, useMemo, useRef, useState } from 'react';
import { useDevToolsStore } from '@frontend/devtools';
import { useTranslation } from '@frontend/i18n';
import {
  PhoneProviderInformationForm,
  PhoneProviderInformationFormTypes,
} from '@frontend/phone-provider-information-form';
import { useAppScopeStore } from '@frontend/scope';
import { debounce } from '@frontend/timer';
import { theme } from '@frontend/theme';
import { BannerNotification, Stepper } from '@frontend/design-system';
import { useCreateOrUpdatePortOrder } from '../../hooks';
import { usePortOrderCreationStore } from '../../providers';
import { smallBannerStyle } from '../styles';
import { StepLoader } from './step-loader';
import { StepperFooter } from './stepper-footer';

export const AccountInformationStep = () => {
  const { t } = useTranslation('porting');
  const { selectedLocationIds, getScopeName } = useAppScopeStore();
  const { isSaving, storePortOrder, updateStorePortOrder } = usePortOrderCreationStore([
    'isSaving',
    'storePortOrder',
    'updateStorePortOrder',
  ]);
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);
  const [isInfoCorrect, setIsInfoCorrect] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formValueRef = useRef<PhoneProviderInformationFormTypes.FormRefType>({});
  const initialFormValues = useMemo(
    () => ({
      addressInfo: storePortOrder.providerServiceAddress,
      providerInfo: storePortOrder.phoneProviderInfo
        ? {
            ...storePortOrder.phoneProviderInfo,
            businessName: storePortOrder.phoneProviderInfo?.businessName || getScopeName(selectedLocationIds[0]),
          }
        : undefined,
    }),
    []
  );

  const { createOrUpdatePortOrder } = useCreateOrUpdatePortOrder();

  const handleAccountInfoDataChange = useCallback(
    debounce(({ addressInfo, providerInfo }: PhoneProviderInformationFormTypes.PhoneProviderAndAddressInfo) =>
      updateStorePortOrder({ phoneProviderInfo: providerInfo, providerServiceAddress: addressInfo })
    ),
    []
  );

  const handleNextButtonClick = async () => {
    const data = formValueRef.current?.handleSubmit?.();
    if (data || isDebugModeOn) {
      await createOrUpdatePortOrder();
    } else {
      // Need to throw error to disallow user to go to next step
      throw Error('invalid form');
    }
  };

  return (
    <>
      <Stepper.Title>{t('Account Information')}</Stepper.Title>
      <Stepper.Content>
        <StepLoader show={isSaving} />
        {!storePortOrder.isSMSHosting && (
          <BannerNotification
            status='warn'
            css={[smallBannerStyle, { marginBottom: theme.spacing(3) }]}
            message={t(
              'Input your information below exactly as listed on your account. Incorrect information may result in a rejected port request and delay your numbers transferring to Weave.'
            )}
          />
        )}
        <PhoneProviderInformationForm
          initialAddressInfo={initialFormValues.addressInfo}
          initialPhoneProviderInfo={initialFormValues.providerInfo}
          ref={formValueRef}
          setIsFormValid={setIsFormValid}
          onFormChange={handleAccountInfoDataChange}
        />
      </Stepper.Content>
      <StepperFooter
        isFormValid={!isSaving && isFormValid}
        isInfoCorrect={isInfoCorrect}
        setIsInfoCorrect={setIsInfoCorrect}
        onNextClick={handleNextButtonClick}
      />
    </>
  );
};
