import { css } from '@emotion/react';
import { motion, Variants } from 'motion/react';
import { theme } from '@frontend/theme';
import { StickyColor, stickyColors, stickyDragGradients } from '../colors';
import { stickyNoteTextareaStyle } from '../common-styles';
import { useStickyNoteContext, useStickyNoteStore } from '../providers';

const EASE_BEZIER = [0.4, 0.05, 0.85, 0.95];
const ANIMATION_DURATION = 0.75;
const PEEL_DURATION = 1;

const styles = {
  mainNote: (color: StickyColor) => css`
    width: 100%;
    height: 100%;
    background: ${stickyColors[color]};
    position: absolute;
    left: 0;
    top: 0;
    z-index: ${theme.zIndex.low};
    padding: ${theme.spacing(1.5)};
  `,
  peel: (color: StickyColor) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: ${stickyDragGradients[color]};
    transform-origin: 100% 100%;
  `,
  shadow: css`
    background: linear-gradient(138deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07749037114845936) 100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 200% 200%;
    z-index: 9;
    pointer-events: none;
  `,
  noPointerEvents: css`
    pointer-events: none;
  `,
  highZIndex: css`
    z-index: ${theme.zIndex.high};
  `,
};

const animations = {
  peel: {
    initial: {
      right: '-100%',
      bottom: '-100%',
      backgroundPosition: '200% 200%',
      pointerEvents: 'none',
    },
    animate: {
      right: ['-100%', '100%', '130%'],
      bottom: ['-100%', '100%', '130%'],
      backgroundPosition: '100% 100%',
      transition: { duration: PEEL_DURATION, ease: EASE_BEZIER, times: [0, 0.75, 1] },
    },
  },
  mainNote: {
    initial: {
      clipPath: 'polygon(100% 100%, -20% 100%, -20% -40%, 100% -40%, 100% 100%)',
    },
    animate: {
      clipPath: 'polygon(calc(100% - 200%) 100%, -20% 100%, -20% -40%, 100% -40%, 100% calc(100% - 200%))',
      transition: { duration: ANIMATION_DURATION, ease: EASE_BEZIER },
    },
  },
  shadow: {
    initial: {
      backgroundPosition: '50% 50%',
      opacity: 0.7,
    },
    animate: {
      backgroundPosition: '100% 100%',
      opacity: 0,
      transition: { duration: ANIMATION_DURATION, ease: EASE_BEZIER },
    },
  },
} satisfies Record<string, Variants>;

export const PeelingAnimationWrapper = () => {
  const { archivingNote } = useStickyNoteStore(['archivingNote']);
  return archivingNote ? <PeelingAnimation /> : null;
};

const PeelingAnimation = () => {
  const { archivingNote, setArchivingNote } = useStickyNoteStore(['noteText', 'archivingNote', 'setArchivingNote']);
  const { color } = useStickyNoteContext();
  const isAnimating = !!archivingNote;

  return (
    <>
      <motion.div
        css={[styles.mainNote(color), styles.highZIndex, styles.noPointerEvents]}
        variants={animations.mainNote}
        initial='initial'
        animate={isAnimating ? 'animate' : 'initial'}
      >
        <motion.div
          css={[styles.peel(color), styles.noPointerEvents]}
          variants={animations.peel}
          initial='initial'
          animate={isAnimating ? 'animate' : 'initial'}
          onAnimationComplete={() => setArchivingNote('')}
        />
        <textarea readOnly value={archivingNote} css={stickyNoteTextareaStyle} />
      </motion.div>
      <motion.div
        css={styles.shadow}
        variants={animations.shadow}
        initial='initial'
        animate={isAnimating ? 'animate' : 'initial'}
      />
    </>
  );
};
