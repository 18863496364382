import { createStoreWithSubscribe } from '@frontend/store';

type CallQueueMetricsType = {
  callCount?: number;
  averageWaitTimeInSeconds?: number;
  waitTimesInSeconds?: number[];
  name?: string;
  locationIds?: string[] | undefined;
};

export type CallQueueMetricsMap = {
  [queueId: string]: CallQueueMetricsType;
};

interface CallQueueMetricsStore {
  setMetrics: (metrics: CallQueueMetricsMap) => void;
  metrics: CallQueueMetricsMap;
}

export const useCallQueueMetricsStore = createStoreWithSubscribe<CallQueueMetricsStore>(
  (set) => ({
    metrics: {},
    setMetrics: (metrics) => {
      set(
        (state) => {
          state.metrics = { ...state.metrics, ...metrics };
        },
        false,
        'SET_CALL_QUEUE_METRICS'
      );
    },
  }),
  { name: 'CallQueueMetricsStore', trace: true }
);
