import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import dayjs from 'dayjs';
import type {
  Attachment as StreamAttachment,
  Channel,
  ChannelResponse,
  DefaultGenerics,
  SearchAPIResponse,
  UserResponse,
  Message as StreamMessage,
} from 'stream-chat';
import { DeepPartial } from '@frontend/types';
import { WebsocketEventPayload } from '@frontend/websocket';
import type {
  Message,
  User,
  UserPresence,
  StreamUserMetadata,
  StreamChannelMetadata,
  Conversation,
  ConversationEssentials,
  Attachment,
  ReactionGroup,
  UserStatus,
} from '../types';
import { CHANNEL_TYPE_TEAM_DESIGNATION, PRIVATE_CHANNEL_PREFIX_DESIGNATION } from './stream-constants';

export const getStreamUserId = ({ orgId, weaveUserId }: { orgId: string; weaveUserId: string }) =>
  `ORG_ID_${orgId}_${weaveUserId}`;
export const getTeamId = (orgId: string) => `ORG_ID_${orgId}`;

/** To this point, the message search result object satisfies everything we need,
 * so until we need more from a full message, we can just use the search result constructor
 * */
export const constructMessageFromStreamMessage = (message: Message) => constructMessageFromStreamSearchResult(message);
const getReplyCountTotal = (replyCount: number, deletedReplyCount: number) => replyCount - deletedReplyCount;
/**
 *
 * Converts a stream api search result message to our message type
 */
export const constructMessageFromStreamSearchResult = (
  message: SearchAPIResponse['results'][number]['message'],
  userId?: string
): Message => {
  return {
    channelId: message.channel?.id ?? message.cid?.replace(`${CHANNEL_TYPE_TEAM_DESIGNATION}:`, '') ?? '',
    createdAt: message.created_at ?? '',
    id: message.id,
    isEdited: !!message.message_text_updated_at,
    isOwnMessage: message.user?.id === userId,
    isUnread: message.status === 'unread',
    lastUpdated: message.updated_at ?? '',
    userId: message.user?.id ?? '',
    type: message.type,
    text: message.text ?? '',
    parentId: message.parent_id || '',
    // stream will not allow us to update a reply count to zero.
    // instead, they keep track of reply count and deleted reply count separately, so we have to do math to figure out the right number
    replyCount: getReplyCountTotal(message?.reply_count || 0, message?.deleted_reply_count || 0) || 0,
    threadParticipantIds: message.thread_participants?.map((participant) => participant.id) ?? [],
    reactions: constructionReactionsFromStreamMessageSearchResult(message),
    attachments: message.attachments
      ?.map(constructAttachmentFromStreamAttachment)
      .filter((attachment) => ['giphy', 'image'].includes(attachment.type) && attachment.url),
    mentionedUserIds: message.mentioned_users?.map((user) => user.id) ?? [],
  };
};

type TeamChatWebsocketPayload = Extract<WebsocketEventPayload, { method: NotificationType.TEAM_CHAT }>;
export const convertWeaveWebsocketPayload = (event: TeamChatWebsocketPayload) => {
  const payload = event.params;
  const [firstName, lastName] = payload.message?.user?.name?.split(' ') || ['Unknown', 'User'];
  if (!payload.message?.user?.id || !payload.message?.id || !payload.channelId || !payload.type) {
    throw new Error('Missing required fields in websocket event payload', {
      cause: {
        payload,
      },
    });
  }
  return {
    channelId: payload.channelId,
    channelType: 'channelType' in payload ? payload.channelType : null,
    user: {
      userID: payload.message?.user?.id || '',
      firstName,
      lastName,
      status: {
        presence: {
          online: payload.message?.user?.online || false,
        },
      },
    } satisfies DeepPartial<User>,
    message: {
      id: payload.message?.id || '',
      text: payload.message?.text || '',
      attachments: payload.message.attachments?.map(constructAttachmentFromStreamAttachment) || [],
      mentionedUserIds: payload.message?.mentionedUsers || [],
    } satisfies Partial<Message>,
    reaction: 'reaction' in payload ? payload.reaction : undefined,
  };
};

export const constructStreamMessageFromMessage = (message: Message): StreamMessage => {
  return {
    user_id: message.userId,
    id: message.id,
    type: message.type,
    text: message.text,
    created_at: message.createdAt,
    updated_at: message.lastUpdated,
    reply_count: message.replyCount,
    // thread_participants: message.threadParticipantIds?.map(constructStreamUserFromUser),
    attachments: message.attachments?.map(constructStreamAttachmentFromAttachment),
    reaction_counts: message.reactions?.reduce((acc, reaction) => {
      acc[reaction.name] = reaction.count;
      return acc;
    }, {} as Record<string, number>),
    own_reactions: message.reactions?.filter((reaction) => reaction.hasOwnReaction).map((reaction) => reaction.name),
    mentioned_users: message.mentionedUserIds,
  };
};

export const isValidMessage = (message: Message) => {
  return !!((message.text || message.attachments?.length) && !!message.userId && message.channelId);
};
/**
 *
 * converts the reactions stream api search result message to our reaction type
 */
export const constructionReactionsFromStreamMessageSearchResult = (
  message: SearchAPIResponse['results'][number]['message']
): ReactionGroup[] => {
  type ReactionGroups = Record<
    string,
    {
      count: number;
      first_reaction_at: string;
      last_reaction_at: string;
      sum_scores: number;
    }
  >;
  const reactionGroups = message.reaction_groups as ReactionGroups | undefined;
  if (!reactionGroups) {
    return [];
  }

  const ownReactions =
    message.own_reactions?.reduce((acc, reaction) => {
      acc[reaction.type] = true;
      return acc;
    }, {} as Record<string, boolean>) ?? {};

  // I could see the user names available only on the latest_reactions and not on the reaction_groups
  // Hence using the latest_reactions to get the user names for the reactions
  // TODO - @gisheri -  Check if the latest reactions are always available or there is a better way to get the user names
  const users =
    message.latest_reactions?.reduce((acc, { type, user }) => {
      if (user?.name) {
        if (acc[type]) {
          acc[type].push(user.id);
        } else {
          acc[type] = [user.id];
        }
      }
      return acc;
    }, {} as Record<string, string[]>) ?? {};

  const reactions = Object.entries(reactionGroups)
    .map(([reactionName, reaction]) => ({
      name: reactionName,
      count: reaction.count,
      hasOwnReaction: !!ownReactions[reactionName],
      firstReaction: dayjs(reaction.first_reaction_at).valueOf(),
      usersIds: users[reactionName] ?? [],
    }))
    .toSorted((a, b) => a.firstReaction - b.firstReaction);
  return reactions;
};

type StreamUserResponse = UserResponse<DefaultGenerics> & StreamUserMetadata;
/**
 * Constructs a user object from a stream user object
 */
export const constructUserFromStreamUser = (user: StreamUserResponse): User => {
  const { firstName, lastName } = getFirstAndLastName(user.name ?? 'Unknown User');

  return {
    firstName,
    lastName,
    userID: user.id,
    status: constructUserStatusFromStream(user.userStatus, user.weavePresence),
    isDeactivated: !!user.deactivated_at,
  };
};

/**
 *
 * Constructs a stream user object from a user object
 */

export const constructStreamUserFromUser = (user: User): StreamUserResponse => {
  return {
    id: user.userID,
    name: `${user.firstName} ${user.lastName}`,
    deactivated_at: user.isDeactivated ? new Date().toISOString() : undefined,
    ...constructStreamUserMetadataFromUser(user.status.status, user.status.presence),
  };
};

export const constructUserStatusFromStream = (
  streamStatus: StreamUserResponse['userStatus'],
  streamPresence: StreamUserResponse['weavePresence']
): UserStatus => {
  return {
    presence: {
      online: streamPresence ? isUserOnline(streamPresence) : false,
      updatedAt: streamPresence?.updatedAt || '',
      expiresAt: streamPresence?.expiresAt || '',
    },
    status: {
      duration: streamStatus?.statusDuration,
      expiry: streamStatus?.statusExpiration,
      text: streamStatus?.statusText,
      emoji: streamStatus?.emoji,
    },
  };
};

export const constructStreamUserMetadataFromUser = (
  status: User['status']['status'],
  presence: User['status']['presence']
): Pick<StreamUserResponse, 'userStatus' | 'weavePresence'> => {
  return {
    userStatus: {
      statusText: status.text,
      statusDuration: status.duration,
      statusExpiration: status.expiry,
      emoji: status.emoji,
    },
    weavePresence: {
      online: presence.online,
      updatedAt: presence.updatedAt,
      expiresAt: presence.expiresAt,
    },
  };
};

export const getFirstAndLastName = (name: string) => {
  // Just in case to handle bad data
  if (!name || !name.includes(' ')) {
    return { firstName: name ?? '', lastName: '' };
  }

  const [firstName, lastName] = name.split(' ');
  return { firstName, lastName };
};

const isUserOnline = (presence: UserPresence): boolean => {
  if (!presence?.online || !presence?.expiresAt) {
    return false;
  }
  return dayjs(new Date()).isAfter(dayjs(presence?.expiresAt)) ? false : presence?.online;
};

/**
 * Constructs a conversation object from a stream channel object
 */
export const constructConversationFromStreamChannel = (channel: Channel, currentUserId: string): Conversation => {
  if (!channel.id) {
    throw new Error('Channel id is missing');
  }
  const metadata = channel.data as Channel['data'] & StreamChannelMetadata;
  const isPrivate = channel.id?.startsWith(PRIVATE_CHANNEL_PREFIX_DESIGNATION);
  const members = Object.values(channel.state.members)
    .filter((user) => !user.banned && user.user_id !== currentUserId)
    .map((member) => member.user)
    .filter((user) => !!user);

  return {
    channelId: channel.id,
    name: !isPrivate && channel.data?.name ? channel.data?.name : members.map((user) => user?.name).join(', '),
    type: isPrivate ? 'DM' : 'Group',
    unreadCount: channel.state.read[currentUserId].unread_messages,
    memberIds: members.filter((member) => !!member.id).map((member) => member.id),
    createdBy: metadata.created_by.name,
    topic: metadata.topic,
    description: metadata.description,
    createdAt: dayjs(metadata.created_at).format('MMMM DD, YYYY'),
    isArchived: !!channel.data?.frozen,
    isHidden: !!channel.data?.hidden,
    usersTyping: Object.values(channel.state.typing)
      .filter((e) => !!e.user_id)
      .map((e) => e.user_id as string),
    lastReadMessageId: channel.state.read[currentUserId].last_read_message_id ?? '',
    lastMessageSentAt: channel.lastMessage()?.created_at?.toISOString(),
  };
};

/**
 * Constructs a conversation essentials object from a stream channel object
 */

export const constructConversationEssentialsFromStreamChannel = (
  channel: ChannelResponse & { topic?: string; description?: string },
  unreadCount?: number
): ConversationEssentials => {
  const isPrivate = channel.id?.startsWith(PRIVATE_CHANNEL_PREFIX_DESIGNATION);
  const members = channel.members?.filter((user) => !!user.user_id) ?? [];

  return {
    channelId: channel.id,
    type: channel.id?.startsWith(PRIVATE_CHANNEL_PREFIX_DESIGNATION) ? 'DM' : 'Group',
    topic: channel?.topic ?? '',
    description: channel?.description ?? '',
    unreadCount: unreadCount,
    name:
      !isPrivate && channel.name ? channel.name : members.map((user) => user?.user?.name ?? 'Unnamed User').join(', '),
  };
};

export const getLastMessageEpoch = (messages: Message[]) => {
  return messages.length ? dayjs(messages[messages.length - 1].lastUpdated).valueOf() : 0;
};

/**
 * Constructs an attachment object from a stream attachment object
 */

export const constructAttachmentFromStreamAttachment = (attachment: StreamAttachment): Attachment => {
  const type = ['image', 'video', 'audio', 'giphy'].includes(attachment.type ?? '')
    ? (attachment.type as Attachment['type'])
    : 'file';
  return {
    type,
    title: attachment.title ?? 'Untitled',
    url: attachment.asset_url ?? attachment.image_url ?? attachment.thumb_url ?? '',
  };
};

/**
 * Constructs a stream attachment object from an attachment object
 */

export const constructStreamAttachmentFromAttachment = (attachment: Attachment): StreamAttachment => {
  if (attachment.type === 'giphy') {
    // When the type of attachment is giphy i.e. a GIF then we should return the attachment as is.
    // Because Mobile accepts the attachment as parsed and uploaded in the meta field of use-image-upload hook
    return attachment;
  }
  return {
    type: attachment.type,
    title: attachment.title,
    asset_url: attachment.url,
  };
};

// This method was taken from the old desktop client for conformity
export const generateUniqueName = (friendlyName: string) => {
  return `${friendlyName
    .toLocaleLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-')
    .slice(0, 50)}-${Date.now().toString()}`;
};
