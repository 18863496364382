import { useMemo } from 'react';
import dayjs from 'dayjs';
import { SMSAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData } from '@frontend/charts';
import { useAppScopeStore } from '@frontend/scope';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { ExportUtils, formatDateByTimezone, getHourlyInsightsLabel } from '../../../utils';
import { useMessagingAnalyticsShallowStore } from './use-messaging-analytics-store';

interface Props {
  data?: SMSAnalyticsTypes.AggregatedSMSReport;

  //dataKeysSequence is used to organize the values keys in the chart data as per the given order
  dataKeysSequence?: string[];
}

type ProcessedData = {
  chartData: BarChartData;
  totals: Record<string, number>;
};

const defaultBarChartAppearance = {
  collectiveTooltip: true,
  showXAxis: true,
  showYAxis: true,
  showGridLines: true,
};

const getOrderedValues = (values: Record<string, number>, dataKeysSequence: string[]) => {
  return (dataKeysSequence.length ? dataKeysSequence : Object.keys(values)).reduce<Record<string, number>>(
    (acc, key) => {
      if (values[key] !== undefined) {
        acc[key] = values[key];
      }
      return acc;
    },
    {}
  );
};

export const useFormatBarChartData = ({ data = {}, dataKeysSequence = [] }: Props) => {
  const { filters, isDemoAccount } = useMessagingAnalyticsShallowStore('filters', 'isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'SMS',
  });
  const { accessibleLocationData, selectedLocationIds } = useAppScopeStore();
  const locationData = accessibleLocationData[selectedLocationIds[0]];

  const processedData: ProcessedData = useMemo(() => {
    // Hourly insight is applicable only:
    // - when the data has 1 location id AND
    // - the location id has only one date object
    const locationsInData = Object.keys(data);
    const valuesInFirstLocation = locationsInData.length === 1 ? Object.values(Object.values(data)[0]) : [];
    const isHourlyInsight = valuesInFirstLocation.length === 1;
    let groups: BarChartData['groups'] = [];
    const totals: Record<string, number> = {};

    if (isHourlyInsight) {
      // We can safely pick index 0 item from the valuesInFirstLocation array
      const dayData = valuesInFirstLocation[0] || {};
      const sortedKeys = dataKeysSequence.length ? dataKeysSequence : Object.keys(dayData);

      groups = sortedKeys.reduce<BarChartData['groups']>((acc, name) => {
        const { hourly = [] } = dayData[name] || {};
        hourly.forEach((value, i) => {
          if (!acc[i]) {
            acc[i] = {
              name: getHourlyInsightsLabel(i),
              values: {},
            };
          }
          acc[i].values[name] = value;
          totals[name] = (totals[name] || 0) + value;
        });
        return acc;
      }, []);
    } else {
      // When multiple locations are available in the data
      // We need to show location comparison chart otherwise show the date comparison chart
      if (locationsInData.length > 1) {
        // Sum up the daily values for each location to form chart data
        groups = Object.entries(data).map(([locationId, datewiseValues]) => {
          const values = Object.values(datewiseValues).reduce<Record<string, number>>((acc, dataPoints) => {
            Object.entries(dataPoints).forEach(([dataPoint, { daily }]) => {
              acc[dataPoint] = (acc[dataPoint] || 0) + daily;
              totals[dataPoint] = (totals[dataPoint] || 0) + daily;
            });
            return acc;
          }, {});

          return {
            name: locationNames[locationId] || locationId,
            values: getOrderedValues(values, dataKeysSequence),
          };
        });
      } else {
        groups = Object.values(data).flatMap((values) =>
          Object.entries(values).map(([date, dataPoints]) => {
            const orderedValues = getOrderedValues(
              Object.keys(dataPoints).reduce<Record<string, number>>((acc, key) => {
                const count = dataPoints[key].daily || 0;
                acc[key] = count;
                totals[key] = (totals[key] || 0) + count;
                return acc;
              }, {}),
              dataKeysSequence
            );

            return {
              name: dayjs(date).isValid()
                ? formatDateByTimezone(date, filters.start_date, locationData?.timezone)
                : date,
              values: orderedValues,
            };
          })
        );
      }
    }

    return {
      chartData: {
        groups,
      },
      totals,
    };
  }, [data, dataKeysSequence, locationNames]);

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.location_id || [], locationNames),
    [filters.location_id, locationNames]
  );

  return {
    defaultBarChartAppearance,
    exportPdfProps,
    processedData,
  };
};
