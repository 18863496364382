import { AnalyticsCommonApi, AnalyticsCommonTypes } from '@frontend/api-analytics';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useIsMulti } from './use-is-multi';

type Props = {
  isDemoAccount?: boolean;
  module: AnalyticsCommonTypes.GetAnalyticsLocationsRequest['Module'];
};

type Response = {
  hasDataSynced?: boolean;
  isLoadingLocations?: boolean;
  isMultiLocation?: boolean;
  locationIds: string[];
  locationNames: Record<string, string>;
};

export const useAnalyticsOrgLocations = ({ isDemoAccount = false, module }: Props): Response => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const user = getUser();
  const { isMulti, parentId: orgId } = useIsMulti();
  const { accessibleLocationData, selectedLocationIds = [] } = useAppScopeStore();

  const { data, isLoading } = useScopedQuery({
    queryKey: `analytics-org-locations-${module}-${isDemoAccount}-${selectedLocationIds.sort().join('-')}`,
    queryFn: () =>
      AnalyticsCommonApi.getAnalyticsLocations(
        {
          BGList: selectedLocationIds,
          IsMulti: isMulti,
          Module: module,
          OrgID: orgId,
          UserId: user?.userID || '',
        },
        isDemoAccount
      ),
    onError: () => {
      alert.error(t("Couldn't fetch the locations details. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const locationIds = isDemoAccount ? selectedLocationIds : data?.ActiveLocations || [];
      const locationsData = accessibleLocationData || {};

      return {
        hasDataSynced: data?.SyncStatus,
        isMultiLocation: locationIds.length > 1,
        locationIds,
        locationNames: locationIds.reduce(
          (acc, locationId) => ({
            ...acc,
            [locationId]: locationsData[locationId]?.name || data?.ActiveLocationNames?.[locationId] || '-',
          }),
          {} as Record<string, string>
        ),
      };
    },
    staleTime: 1000 * 60 * 5,
  });

  return {
    hasDataSynced: data?.hasDataSynced,
    isLoadingLocations: isLoading,
    isMultiLocation: data?.isMultiLocation,
    locationIds: data?.locationIds || [],
    locationNames: data?.locationNames || {},
  };
};
