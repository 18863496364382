import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  convertInvoiceV3toInvoiceModel,
  getInvoiceQueryKey,
  InvoiceModelV3,
  updateInvoiceQueryCache,
} from '@frontend/api-invoices';
import { useGetInvoiceNonQuery } from '@frontend/payments-invoice-controller';
import { getPersonActiveInvoiceKey } from '@frontend/person-invoice-list';
import { ButtonProps } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { CreateStepNavigationProps } from '../create.types';

export const CreateInsertNavigation = ({ body: Body }: CreateStepNavigationProps) => {
  const {
    setActiveInvoiceId,
    onInvoiceCreated,
    onInvoiceSelected,
    person,
    multiStep: { goPrevStep },
  } = useCollectPaymentMultiContext();

  const createGoBack = useCallback(() => {
    return goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select });
  }, []);

  const [isWaitingRefresh, setIsWaitingRefresh] = useState(false);

  const getInvoice = useGetInvoiceNonQuery();

  const getInvoiceSafe = async (invoiceId: string) => {
    try {
      return await getInvoice(invoiceId);
    } catch {
      // expected 404 on first 1-2 attempts
      return null;
    }
  };

  const getInvoiceSafeRetry = async (invoiceV3: InvoiceModelV3, attempt = 0) => {
    if (attempt > 5) {
      console.error('Failed to query created invoice after 5 attempts');
      return convertInvoiceV3toInvoiceModel(invoiceV3);
    }
    const invoice = await getInvoiceSafe(invoiceV3.id);

    if (invoice) {
      return invoice;
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
    return getInvoiceSafeRetry(invoiceV3, attempt + 1);
  };

  const queryClient = useQueryClient();

  const onCreateRequest = useCallback(
    async (incompleteInvoice: InvoiceModelV3) => {
      setActiveInvoiceId(incompleteInvoice?.id);

      if (incompleteInvoice) {
        onInvoiceCreated?.(incompleteInvoice.id);
      }

      setIsWaitingRefresh(true);

      const completeInvoice = await getInvoiceSafeRetry(incompleteInvoice);
      if (completeInvoice.links?.payment) {
        /**
         * Update single-invoice query w/ complete invoice
         */
        updateInvoiceQueryCache(
          queryClient,
          completeInvoice,
          getInvoiceQueryKey(completeInvoice.locationId, completeInvoice.id)
        );
      } else {
        /**
         * We didn't get the completed data, so invalidate invoice query
         */
        queryClient.invalidateQueries(getInvoiceQueryKey(completeInvoice.locationId, completeInvoice.id));
      }

      /**
       * Find / invalidate the person invoices query (used on select step)
       */
      const toCheck = getPersonActiveInvoiceKey(person?.personId);
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return Array.isArray(queryKey) && toCheck.every((key) => queryKey.includes(key));
        },
      });

      onInvoiceSelected?.(completeInvoice);

      setIsWaitingRefresh(false);

      goPrevStep({ orStep: PAYMENT_MULTI_STEPS.select });
    },
    [onInvoiceCreated, onInvoiceSelected]
  );

  const onClickSecondary = useCallback(() => {
    createGoBack();
  }, [createGoBack]);

  const primary = useMemo(() => {
    return {
      disabled: isWaitingRefresh ? true : undefined,
      children: isWaitingRefresh ? 'Creating...' : 'Create & Select',
      iconName: 'pay',
    } satisfies ButtonProps;
  }, [isWaitingRefresh]);

  return (
    <Body
      onCreateRequest={onCreateRequest}
      onGoBack={createGoBack}
      onClickSecondary={onClickSecondary}
      primary={primary}
    />
  );
};
