import { useEffect } from 'react';
import { css } from '@emotion/react';
import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { isEqual } from 'lodash-es';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScheduleAvailabilityHours } from '@frontend/schedule-availability-hours';
import { theme } from '@frontend/theme';
import { Button, ButtonBar, SpinningLoader, Stepper, Text, styles, useAlert } from '@frontend/design-system';
import { useAddPractitionerV3Context } from '../../../context/AddPractitionerV3Context';
import { useSchedulingLocationInfo } from '../../../hooks';
import { convertScheduleHoursObjectToRRuleObject } from '../../../rrule-helper';
import { OfficeTimezone } from '../../../views/ManagePractitionersV3/PractitionerDetails/OfficeTimezone';

type AddPractitionerStepperSecondStepProps = {
  isCreatePractitionerError: boolean;
};

export const AddPractitionerStepperSecondStep = ({
  isCreatePractitionerError,
}: AddPractitionerStepperSecondStepProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');

  const { onClose, locationId, practitionerDetails } = useAddPractitionerV3Context();

  const { getLocationTimeZone } = useSchedulingLocationInfo();
  const locationTimeZone = getLocationTimeZone(locationId);

  const { updatedScheduleAvailability, ScheduleAvailabilityHours, isError } = useScheduleAvailabilityHours({
    initialScheduleAvailability: {},
    closedOfficeText: t('Out of Office'),
    timeRangeJoiningText: t('to'),
  });

  const createScheduleMutation = SchedulerV3.Mutations.useCreateScheduleMutation({
    options: {
      onSuccess: () => {
        alert.success(t('Provider schedule created successfully'));
        onClose();
      },
      onError: () => {
        alert.error(t('Failed to create Provider schedule'));
      },
    },
  });

  const handleSaveClick = () => {
    const scheduleEntries = convertScheduleHoursObjectToRRuleObject(updatedScheduleAvailability, locationTimeZone);
    createScheduleMutation.mutate({
      id: practitionerDetails?.id,
      locationId,
      type: ScheduleType.PROVIDER,
      recurrenceRules: {
        workingHours: scheduleEntries.workingHours,
        breaks: scheduleEntries.breaks,
      },
    });
  };

  useEffect(() => {
    if (isCreatePractitionerError) {
      onClose();
    }
  }, [isCreatePractitionerError]);

  if (!practitionerDetails?.id || createScheduleMutation.isLoading) {
    return (
      <div css={[styles.flexCenter, { height: '80vh' }]}>
        <SpinningLoader />
      </div>
    );
  }

  const shouldDisableSave = isError || isEqual(updatedScheduleAvailability, {});
  return (
    <>
      <Text color='light'>
        {t("Set {{name}}'s regular weekly office hours. You can change this schedule at any time in settings.", {
          name: practitionerDetails?.firstName || '',
        })}
      </Text>
      <Stepper.Content css={{ marginTop: theme.spacing(3) }}>
        <OfficeTimezone timezone={locationTimeZone} />
        <div css={{ marginTop: theme.spacing(2) }}>{ScheduleAvailabilityHours}</div>
      </Stepper.Content>

      <Stepper.ButtonBar css={stepperButtonBarStyle}>
        <Stepper.PreviousButton>
          <Icon name='back' />
          <Text
            css={css`
              margin-left: ${theme.spacing(1)};
            `}
            color='primary'
            weight='bold'
          >
            {t('Back')}
          </Text>
        </Stepper.PreviousButton>
        <ButtonBar>
          <Button variant='secondary' onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleSaveClick} disabled={shouldDisableSave}>
            {t('Save')}
          </Button>
        </ButtonBar>
      </Stepper.ButtonBar>
    </>
  );
};

const stepperButtonBarStyle = css`
  display: flex;
  margin-top: ${theme.spacing(3)};
  justify-content: space-between;
  align-items: center;

  button {
    height: ${theme.spacing(5)};
  }

  & > button:first-of-type {
    margin-left: 0;
    width: fit-content;
    border: 0;
    color: ${theme.colors.primary50};
  }
`;
