import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ListDevice, ModelInfo } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { capitalize } from 'lodash-es';
import { scopedFilterAndSort } from '@frontend/filters';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  Modal,
  ModalControlModalProps,
  Heading,
  Text,
  Table,
  CaretRightIconSmall,
  Chip,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { PhoneSettingsScopedLocationFilterMenu } from '../../../common/index-table';
import { ShowMaxIconWithName } from '../modals/duplicate-layout.modal';

type TableProps = ModalControlModalProps & {
  device: ListDevice | undefined;
  devices: ListDevice[];
  models: Record<string, ModelInfo> | undefined;
  setPreviewDevice: Dispatch<SetStateAction<ListDevice | undefined>>;
  filteredLocations: string[];
  setFilteredLocations: Dispatch<SetStateAction<string[]>>;
};

export const ExistingLayoutDevicesTable = ({
  filteredLocations,
  setFilteredLocations,
  device,
  devices,
  models,
  setPreviewDevice,
  ...rest
}: TableProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showWarningText, setShowWarningText] = useState(false);
  const { name: deviceName } = device ?? {};
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const { settingsTenantLocation, globalAvailableLocationIds } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'globalAvailableLocationIds'
  );

  const filteredData = useMemo(() => {
    const filtered = scopedFilterAndSort({
      data: devices ?? [],
      filteredScopeIds: filteredLocations,
      scopeIds: globalAvailableLocationIds,
      scopeIdAccessor: (device) => (device.location?.locationId ? [device.location?.locationId] : []),
    });
    return searchTerm
      ? filtered.filter(
          (data) =>
            data.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            data.sipProfiles?.some(({ extension }) => extension?.extensionNumber === +searchTerm)
        )
      : filtered;
  }, [devices, searchTerm]);

  return (
    <>
      <Modal.Header textAlign='left' onClose={rest.onClose}>
        <Heading level={2} as='span'>
          {t('Use an Existing Layout')}
        </Heading>
        <Text weight='regular' color='light'>
          <Trans t={t} deviceName={deviceName}>
            Choose a device's Line Key layout to apply to{' '}
            <Text as='span' weight='bold'>
              {deviceName}
            </Text>
            .
          </Trans>
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={filteredData}
          isPaginated
          hasGlobalSearch
          fullHeight
          fullHeightConfig={{
            minHeight: 300,
            maxHeight: 550,
          }}
          customToolbarRender={
            settingsTenantLocation && settingsTenantLocation?.locationType !== 'single'
              ? () => (
                  <div css={{ marginRight: 'auto' }}>
                    <PhoneSettingsScopedLocationFilterMenu
                      selectedOptions={filteredLocations}
                      onChange={setFilteredLocations}
                    />
                  </div>
                )
              : undefined
          }
          globalSearchConfig={{
            searchHandler: (term) => {
              setSearchTerm(term);
            },
          }}
          rowActions={{
            actions: [
              {
                Icon: CaretRightIconSmall,
                label: '',
                onClick: (device) => {
                  setPreviewDevice(device);
                },
              },
            ],
          }}
          colConfig={[
            {
              id: 'name',
              Header: t('Name'),
              accessor: (data) => data,
              cellRenderer: ({ name, lineKeyCount }) => {
                if (!device?.model) return <Text>{name}</Text>;
                const maxKeys = models?.[device.model]?.sideKeys?.maxKeys ?? Infinity;
                const showIcon = lineKeyCount > maxKeys;
                if (showIcon) {
                  setShowWarningText(true);
                  return <ShowMaxIconWithName name={name ?? ''} />;
                }
                return <Text>{name}</Text>;
              },
            },
            {
              id: 'extension-number',
              Header: t('Extension'),
              accessor: ({ sipProfiles }) => sipProfiles?.[0]?.extension?.extensionNumber,
              minWidth: 115,
              maxWidth: 150,
            },
            {
              Header: t('Location'),
              id: 'location',
              accessor: (device) => device,
              cellRenderer: (device) => {
                if (!device.location) return '-';
                return <Chip.SingleChip>{device.location?.name}</Chip.SingleChip>;
              },
              disableSortBy: true,
              omit: settingsTenantLocation?.locationType === 'single',
              width: 150,
            },
            {
              id: 'model-name',
              Header: t('Model'),
              accessor: (data) => data,
              cellRenderer: ({ model, make }) => {
                if (!make || !model) return '-';
                return <Text>{capitalize(make?.toLocaleLowerCase()) + ' ' + model?.toLocaleUpperCase()}</Text>;
              },
            },
          ]}
          wrapperStyle={css`
            margin: ${theme.spacing(2, 0, 2)};
            .table-toolbar {
              justify-content: space-between;
            }
          `}
        />
        {showWarningText && (
          <BannerNotification
            fullWidth
            status='warn'
            message={t(
              '{{deviceName}} does not have enough Line Key slots for these layouts. The additional Line Keys of these layouts will not appear on the {{deviceName}} device when the layout is applied.',
              {
                deviceName,
              }
            )}
          />
        )}
      </Modal.Body>
    </>
  );
};
