import { FC, PropsWithChildren, ReactNode } from 'react';
import { useHVAPayments } from '../../hooks/useHVAPayments';
import { usePaymentAction } from '../../hooks/usePaymentAction';
import { PaymentsPromotionContext } from '../../provider/PaymentsPromotionProvider';

export const PaymentsProviderWrapper: FC<
  PropsWithChildren<{ locationId: string; source: string; fallback?: ReactNode }>
> = ({ locationId, children, fallback = null, source }) => {
  const { hasAccountCreationPermission, hasPaymentsAccount, showPromotion } = useHVAPayments({ locationId });
  const { handleAction, InvoiceModal } = usePaymentAction({ locationId });

  if (!showPromotion) {
    return fallback;
  }

  return (
    <PaymentsPromotionContext.Provider
      value={{ hasAccountCreationPermission, hasPaymentsAccount, source, handleAction }}
    >
      {children}
      <InvoiceModal />
    </PaymentsPromotionContext.Provider>
  );
};
