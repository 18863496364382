import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import {
  InstallBattery,
  PlugInTerminal,
  RegisterTerminalModalSteps,
  TurnTerminalOn,
  GeneratePairingCode,
  ConnectToNetwork,
  OpenWifiSettings,
  PairYourTerminal,
} from '@frontend/payments-register-terminal';
import { theme } from '@frontend/theme';
import { MultiStep, useMultiStepControl } from '@frontend/design-system';

export type RegisterTerminalStepperProps = {
  onComplete: () => void;
  onCancel: () => void;
  onRegisterTerminal: () => void;
  onGoBackFromStart: () => void;
};

const styles = {
  overrides: css`
    padding: ${theme.spacing(2)};
    padding-right: ${theme.spacing(3)};

    .stepper__label-text {
      overflow: visible;
      text-overflow: unset;
      white-space: normal;
    }

    .wv-modal-flex-content {
      padding-left: 0;
      padding-right: 0;
    }

    .multi-step__header-wrapper {
      padding-top: ${theme.spacing(1)};
    }
    .multi-step__footer-wrapper {
      display: none;
    }
    .register-terminal__footer {
      padding-left: 0;
      padding-right: 0;
      margin-top: auto;
    }
    .step__body {
      display: flex;
      flex-direction: column;
    }
    .multi-step__body {
      padding-left: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(1)};
    }
  `,
  stepper: css`
    /* .stepper__item {
      margin-top: ${theme.spacing(2)};
    }
    .stepper__progress--bg {
      height: 90%;
    } */
  `,
};
export const RegisterTerminalStepper = ({
  onComplete,
  onCancel,
  onGoBackFromStart,
  onRegisterTerminal,
}: RegisterTerminalStepperProps) => {
  const { t } = useTranslation('payments');

  const multiStepControl = useMultiStepControl({
    onComplete: () => {
      onComplete();
    },
    onCancel: () => {
      onCancel();
    },
  });

  const goBack = useCallback(() => {
    multiStepControl.goPrevStep();
  }, [multiStepControl.goPrevStep]);

  const goToStep = useCallback(
    (step: RegisterTerminalModalSteps) => {
      multiStepControl.goToStep({ orStep: step });
    },
    [multiStepControl.goToStep]
  );

  const steps = useMemo(() => {
    return [
      {
        id: RegisterTerminalModalSteps.InstallBattery,
        title: t('Power Up Terminal'),
        component: <InstallBattery goBack={onGoBackFromStart} goToStep={goToStep} />,
      },
      {
        id: RegisterTerminalModalSteps.PlugInTerminal,
        parentId: RegisterTerminalModalSteps.InstallBattery,
        title: t('Power Up Terminal'),
        component: <PlugInTerminal goBack={goBack} goToStep={goToStep} />,
      },
      {
        id: RegisterTerminalModalSteps.TurnTerminalOn,
        parentId: RegisterTerminalModalSteps.InstallBattery,
        title: t('Power Up Terminal'),
        component: <TurnTerminalOn goBack={goBack} goToStep={goToStep} />,
      },
      {
        id: RegisterTerminalModalSteps.OpenWifiSettings,
        title: t('Connect Terminal to WiFi'),
        component: <OpenWifiSettings goBack={goBack} goToStep={goToStep} />,
      },
      {
        id: RegisterTerminalModalSteps.ConnectToNetwork,
        parentId: RegisterTerminalModalSteps.OpenWifiSettings,
        title: t('Connect Terminal to WiFi'),
        component: <ConnectToNetwork goBack={goBack} goToStep={goToStep} />,
      },
      {
        id: RegisterTerminalModalSteps.GeneratePairingCode,
        title: t('Register Your Terminal'),
        component: <GeneratePairingCode goBack={goBack} goToStep={goToStep} />,
      },
      {
        id: RegisterTerminalModalSteps.PairYourTerminal,
        parentId: RegisterTerminalModalSteps.GeneratePairingCode,
        title: t('Register Your Terminal'),
        component: (
          <PairYourTerminal
            goBack={goBack}
            goToStep={goToStep}
            onSuccess={onComplete}
            onTerminalPaired={onRegisterTerminal}
          />
        ),
      },
    ];
  }, [onRegisterTerminal, goToStep]);

  return (
    <MultiStep.Root {...multiStepControl} css={styles.overrides}>
      {steps.map((step) => (
        <MultiStep.Step
          components={{ Footer: () => null }}
          label={step.title}
          id={step.id}
          key={step.id}
          parentId={step.parentId}
        >
          {step.component}
        </MultiStep.Step>
      ))}
    </MultiStep.Root>
  );
};
