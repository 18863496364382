import { type ReactNode, createContext, useContext, useMemo, useState } from 'react';
import type { StoredReader } from '@frontend/payments-terminal-controller';

interface TerminalStepContextStripeProps {
  children?: ReactNode;
}

type TerminalStepContextStripeState = {
  selectedReader: StoredReader | undefined;
  setSelectedReader: (reader: StoredReader | undefined) => void;
};

const TerminalStepContext = createContext<TerminalStepContextStripeState | undefined>(undefined);

export const TerminalStepContextStripe = ({ children }: TerminalStepContextStripeProps) => {
  const [selectedReader, setSelectedReader] = useState<StoredReader | undefined>();
  const value = useMemo(() => ({ selectedReader, setSelectedReader }), [selectedReader]);

  return (
    <>
      <TerminalStepContext.Provider value={value}>{children}</TerminalStepContext.Provider>
    </>
  );
};

export const useTerminalStepContextStripe = () => {
  const terminalStepContextStripe = useContext(TerminalStepContext);

  if (!terminalStepContextStripe) {
    throw new Error('useTerminalStepContextStripe must be used within a TerminalStepContextStripe');
  }

  return terminalStepContextStripe;
};
