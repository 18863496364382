import { useDynamicFields } from '@frontend/content-composer';

export const useFormatDynamicFieldAndLabel = () => {
  const { dynamicFields } = useDynamicFields('marketing');
  const convertLabelToDynamicField = (text?: string) => {
    if (!text) return text;
    const splitText = text.split(/(\[[^\]]*])/);
    return dynamicFields
      .reduce((acc, { label, symbol }) => {
        const key = '[' + label + ']';
        return acc.map((item) => item.replace(key, symbol));
      }, splitText)
      .join('');
  };

  const convertDynamicFieldToLabel = (text?: string) => {
    if (!text) return text;
    const splitText = text.split(/(\{\{[^}]*}})/);
    return dynamicFields
      .reduce((acc, { label, symbol }) => {
        return acc.map((item) => item.replace(symbol, '[' + label + ']'));
      }, splitText)
      .join('');
  };

  return { convertLabelToDynamicField, convertDynamicFieldToLabel };
};
