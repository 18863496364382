import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const styles = {
  card: css`
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `,
  marginBottom: css`
    margin-bottom: ${theme.spacing(2)};
  `,
};

export const paymentInfoStyles = {
  tableHeader: css`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  `,
  paymentDetails: css`
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing(1)};
  `,
  paymentDetailsRow: css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
    @media (min-width: ${breakpoints.small.min}px) {
      flex-flow: row;
    }
  `,
};

export const attachmentStyles = {
  attachmentSection: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  attachmentHeading: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
  warningIndicator: css`
    position: relative;
  `,
  attachmentNameRow: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `,
  uploadAttachmentLink: css`
    margin-bottom: ${theme.spacing(2, 0)};
    cursor: pointer;
  `,
};

export const activityStyles = {
  activityRow: css`
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing(2)};
    align-items: center;
  `,
};
