import { StepNavigationSwitch } from '../../atoms/step-navigation-switch';
import { ShareInMessageGenericNavigation } from './navigation/share-in-message-generic-navigation';
import { ShareInMessageBody } from './share-in-message-body';
import { ShareInMessageBodyProps, ShareInMessageProps } from './share-in-message.types';

export const ShareInMessageNavigationSwitch = (parentProps: ShareInMessageProps) => {
  return (
    <StepNavigationSwitch
      body={(props: ShareInMessageBodyProps) => <ShareInMessageBody {...parentProps} {...props} />}
      generic={ShareInMessageGenericNavigation}
    />
  );
};
