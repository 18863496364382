import { State } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { FeatureFlagStrategy, useAppFlagStore } from '@frontend/shared';
import { useAppScopeStore } from './store';

export const useScopedAppFlagStore = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const {
    getFeatureFlagValue,
    getCustomizationFlagValue,
    getCustomizationFlag,
    getFeatureFlag,
    customizationFlagMap,
    getCustomizationFlagLocationMap,
    getFeatureFlagLocationMap,
  } = useAppFlagStore();

  return {
    getFeatureFlagValue: (flagName: string, strategy: FeatureFlagStrategy = 'ANY') =>
      getFeatureFlagValue({ locationIds: selectedLocationIds, flagName, strategy }),
    getCustomizationFlagValue: (
      flagName: string,
      state: keyof typeof State = 'ACTIVE',
      strategy: FeatureFlagStrategy = 'ANY'
    ) => getCustomizationFlagValue({ locationIds: selectedLocationIds, flagName, strategy, state }),
    getCustomizationFlag: (flagName: string) => getCustomizationFlag({ locationIds: selectedLocationIds, flagName }),
    getFeatureFlag: (flagName: string) => getFeatureFlag({ locationIds: selectedLocationIds, flagName }),
    /**
     * Returns a map of currently selectedLocationIds to their customization flag values for a given customization flag
     */
    getCustomizationFlagLocationMap: (flagName: string) =>
      getCustomizationFlagLocationMap({ locationIds: selectedLocationIds, flagName }),
    /**
     * Returns a map of currently selectedLocationIds to their feature flag values for a given feature flag
     */
    getFeatureFlagLocationMap: (flagName: string) =>
      getFeatureFlagLocationMap({ locationIds: selectedLocationIds, flagName }),
    getCustomizationFlagValueById: (
      flagId: string,
      state: keyof typeof State = 'ACTIVE',
      strategy: FeatureFlagStrategy = 'ANY'
    ) =>
      customizationFlagMap[flagId]
        ? getCustomizationFlagValue({
            locationIds: selectedLocationIds,
            flagName: customizationFlagMap[flagId],
            strategy,
            state,
          })
        : false,
    getCustomizationFlagById: (flagId: string) =>
      getCustomizationFlag({ locationIds: selectedLocationIds, flagName: customizationFlagMap[flagId] }),
  };
};
