import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonsV3 } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import { contextFactory } from '@frontend/design-system';
import {
  ArchiveToggleAction,
  BlockToggleAction,
  CallAction,
  CommsPreferencesAction,
  ContactViewOrCreateAction,
  MarkUnreadAction,
  PopOutAction,
  RequestPaymentAction,
} from './actions';

type ThreadActionsContext = {
  groupId: string;
  threadId: string;
  personPhone: string;
  outboundPhone?: {
    departmentId: string;
    locationPhone?: string;
  };
  personId?: string;
  person?: Person;
};
export const [ThreadActionsContext, useThreadActionsContext] = contextFactory<ThreadActionsContext>(
  'useThreadActionsContext muse be used within ThreadActionsProvider (or ThreadActions component)'
);

type ThreadActionsProviderProps = ThreadActionsContext & {
  children: ReactNode;
};

export const ThreadActionsProvider = ({
  children,
  personId,
  person: providedPerson,
  groupId,
  ...context
}: ThreadActionsProviderProps) => {
  // Fetch the person if not provided, but personId is specified
  const { data: person } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: personId ?? '',
      locationIds: [groupId],
    },
    {
      enabled: !providedPerson && !!personId && !!groupId,
      placeholderData: providedPerson,
    }
  );

  return (
    <ThreadActionsContext.Provider value={{ ...context, personId, person, groupId }}>
      {children}
    </ThreadActionsContext.Provider>
  );
};

type ThreadActionsProps = ThreadActionsProviderProps & ComponentProps<typeof AdaptoActions>;

export const ThreadActions = ({
  children,
  groupId,
  threadId,
  personPhone,
  outboundPhone,
  personId,
  person,
  ...rest
}: ThreadActionsProps) => {
  return (
    <ThreadActionsProvider
      groupId={groupId}
      threadId={threadId}
      personPhone={personPhone}
      outboundPhone={outboundPhone}
      personId={personId}
      person={person}
    >
      <AdaptoActions
        variant={rest.maxActions === 1 ? 'icon-buttons' : 'secondary-buttons'}
        css={css`
          .action-item--secondary {
            padding-left: ${theme.spacing(1)};
            padding-right: ${theme.spacing(1)};
          }
        `}
        iconColor='default'
        spacing={0.5}
        {...rest}
      >
        {children}
      </AdaptoActions>
    </ThreadActionsProvider>
  );
};

ThreadActions.RequestPayment = RequestPaymentAction;
ThreadActions.ArchiveToggle = ArchiveToggleAction;
ThreadActions.BlockToggle = BlockToggleAction;
ThreadActions.Call = CallAction;
ThreadActions.CommsPreferences = CommsPreferencesAction;
ThreadActions.ContactViewOrCreate = ContactViewOrCreateAction;
ThreadActions.MarkUnread = MarkUnreadAction;
ThreadActions.PopOut = PopOutAction;
