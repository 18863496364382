import { CardOnFile } from './card-on-file';
import { CollectTerminal } from './collect-terminal';
import { Create } from './create';
import { ManualCardEntry } from './manual-card-entry';
import { PaymentMethods } from './payment-methods';
import { Preview } from './preview';
import { RegisterTerminal } from './register-terminal';
import { Select } from './select';
import { SelectPaymentsTemplate } from './select-payments-template';
import { SelectTerminal } from './select-terminal';
import { ShareInEmail } from './share-in-email';
import { ShareInMessage } from './share-in-message';
import { Sunbit } from './sunbit';

const CollectPaymentMultiStepsNamespace = {
  Select,
  SelectPaymentsTemplate,
  Preview,
  Create,
  PaymentMethods,
  CardOnFile,
  ShareInMessage,
  ShareInEmail,
  ManualCardEntry,
  Sunbit,
  SelectTerminal,
  RegisterTerminal,
  CollectTerminal,
};

export { CollectPaymentMultiStepsNamespace as CollectPaymentMultiSteps };
