import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const highlightValueStyles = css`
  align-items: center;
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  min-height: 135px;
  overflow: hidden;
  padding: ${theme.spacing(3)};
  position: relative;

  .graphic {
    height: 135px;
    position: absolute;
    right: 0;
    top: 0;

    @media screen and (min-width: ${breakpoints.large.min}px) {
      right: ${theme.spacing(10)};
    }
  }

  * {
    font-size: ${theme.fontSize(24)} !important;
  }
`;

export const summaryTabsStyles = {
  mainWrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    padding: ${theme.spacing(3)};
  `,

  statsWrapper: css`
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
  `,
};
