import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, SpinningLoader, Text } from '@frontend/design-system';
import { CustomProviderType, useFetchAndStoreProviderAvailabilities } from '../../../../hooks/booking-site';
import { useBookingSiteStore } from '../../../../stores';

const WEEKDAYS = [
  i18next.t('Monday', { ns: 'schedule' }),
  i18next.t('Tuesday', { ns: 'schedule' }),
  i18next.t('Wednesday', { ns: 'schedule' }),
  i18next.t('Thursday', { ns: 'schedule' }),
  i18next.t('Friday', { ns: 'schedule' }),
  i18next.t('Saturday', { ns: 'schedule' }),
  i18next.t('Sunday', { ns: 'schedule' }),
];

interface Props {
  provider: CustomProviderType;
}

export const getSlotsGroupByDay = (slots: string[]) => {
  return slots.reduce<Record<string, string[]>>((acc, slot) => {
    const day = formatDate(slot, 'YYYY-MM-DD');
    if (!acc[day]) {
      acc[day] = [];
    }
    if (acc[day].indexOf(slot) === -1) {
      acc[day].push(slot ?? '');
    }
    return acc;
  }, {});
};

const getWeekWiseSlotCount = (slotsGroupByDay: Record<string, string[]>) =>
  Object.entries(slotsGroupByDay).reduce<Record<string, number>>(
    (acc, [date, slots]) => ({ ...acc, [dayjs(date).format('dddd')]: slots.length }),
    {}
  );

export const ProviderApptCount = ({ provider }: Props) => {
  const { setSelectedProvider, allProviderAvailabilities } = useBookingSiteStore([
    'setSelectedProvider',
    'allProviderAvailabilities',
  ]);

  const { isLoading } = useFetchAndStoreProviderAvailabilities({
    providerId: provider.id ?? '',
    providerCalendarId: provider.calendarId ?? '',
  });

  const weekWiseSlotCount = useMemo(() => {
    const selectedProviderAvailabilities = (provider.id ? allProviderAvailabilities[provider.id] : []) ?? [];

    return getWeekWiseSlotCount(getSlotsGroupByDay(selectedProviderAvailabilities));
  }, [allProviderAvailabilities, provider.id]);

  return (
    <div css={containerStyle}>
      {isLoading ? (
        <div css={{ width: '100%', alignContent: 'center' }}>
          <SpinningLoader size='small' />
        </div>
      ) : (
        <WeekWiseSlots slotsMap={weekWiseSlotCount} onSelect={(day) => setSelectedProvider(provider, day)} />
      )}
    </div>
  );
};

export const AllProvidersApptCount = () => {
  const { setSelectedProvider, allProviderAvailabilities } = useBookingSiteStore([
    'setSelectedProvider',
    'allProviderAvailabilities',
  ]);

  const weekWiseSlotsMap = useMemo(() => {
    const allSlots = Object.values(allProviderAvailabilities).flat();
    return getWeekWiseSlotCount(getSlotsGroupByDay(allSlots));
  }, [allProviderAvailabilities]);

  return (
    <div css={containerStyle}>
      <WeekWiseSlots slotsMap={weekWiseSlotsMap} onSelect={(day) => setSelectedProvider('any', day)} />
    </div>
  );
};

const WeekWiseSlots = ({
  slotsMap,
  onSelect,
}: {
  slotsMap: Record<string, number>;
  onSelect: (day: string) => void;
}) => {
  const { t } = useTranslation('bookingSite');

  return WEEKDAYS.map((day) => {
    const count = slotsMap[day] ?? 0;
    return (
      <div key={day} css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
        <Text css={{ margin: 0 }}>{day.substring(0, 3)}</Text>
        <NakedButton
          css={buttonStyle(!!count)}
          disabled={!count}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(day);
          }}
        >
          {count ? t('{{count}} appts', { count }) : t('No appts')}
        </NakedButton>
      </div>
    );
  });
};

const containerStyle = css({
  display: 'flex',
  position: 'relative',
  gap: theme.spacing(1),
  minHeight: 100,
  width: '100%',
  margin: theme.spacing(1.5, 1),
});

const buttonStyle = (hasCount: boolean) =>
  css({
    height: '100%',
    padding: theme.spacing(1),
    fontSize: theme.fontSize(16),
    borderRadius: theme.borderRadius.small,
    textAlign: 'left',
    border: `1px solid ${hasCount ? theme.colors.neutral30 : theme.colors.neutral20} !important`, // need to override disable button style
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: theme.colors.neutral10,
    },
  });
