import React, { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useDragControls, useAnimate } from 'motion/react';
import { getUser } from '@frontend/auth-helpers';
import { DashboardWidget, type DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { StickyColor, stickyColors, stickyDragGradients } from '../colors';
import { stickyNoteTextareaStyle, stickyShadow } from '../common-styles';
import {
  StickyNoteProvider,
  StickyNoteContext,
  useStickyNoteStore,
  useStickyNonReactiveStore,
  useStickyNoteContext,
} from '../providers';
import { getStickyNoteFromStorage } from '../storage';
import { ArchiveArea } from './archive-area';
import { DraggableArea } from './draggable-area';
import { DraggableStickyNote } from './draggable-sticky-note';
import { PeelingAnimationWrapper } from './peeling-animation';
import { StickyNoteMenu } from './sticky-note-menu';

const PENDO_TRACKING_ID = 'sticky-note-textarea-focused';

function MainStickyNote({
  handleDragEnd,
  handlePointerDownOnHandle,
}: {
  handleDragEnd: () => void;
  handlePointerDownOnHandle: (e: React.PointerEvent<HTMLDivElement>) => void;
}) {
  const user = getUser();
  const { t } = useTranslation('sticky-note-widget');
  const { noteText, isHovering, handleTyping } = useStickyNoteStore(['noteText', 'isHovering', 'handleTyping']);
  const { color } = useStickyNoteContext();
  const handlePendoTracking = useCallback(() => {
    pendo.track(PENDO_TRACKING_ID, {
      user: user?.userID ?? '',
      color: color,
    });
  }, []);

  return (
    <div
      css={[
        css`
          width: 100%;
          height: 100%;
        `,
        stickyShadow(isHovering),
      ]}
    >
      <PeelingAnimationWrapper />
      <div css={mainStickyNoteStyle(isHovering, color)}>
        <div css={peelInsideStyle(isHovering, color)} />

        <textarea
          value={noteText}
          onChange={(e) => {
            handleTyping(e.target.value);
          }}
          onFocus={handlePendoTracking}
          placeholder={t('Write your note here...')}
          css={stickyNoteTextareaStyle}
        />
      </div>
      <DraggableArea handleDragEnd={handleDragEnd} handlePointerDownOnHandle={handlePointerDownOnHandle} />
    </div>
  );
}

export const StickyNoteWidgetWrapper = () => {
  const { setIsDragging, setNoteText, setDraggedText, setShowArchive, setIsHoveredOverArchive, applyDraggedText } =
    useStickyNoteStore([
      'setIsDragging',
      'setNoteText',
      'setDraggedText',
      'setShowArchive',
      'setIsHoveredOverArchive',
      'applyDraggedText',
    ]);

  const stickyStore = useStickyNonReactiveStore();
  const [scope, animate] = useAnimate();

  const dragControls = useDragControls();

  const handlePointerDownOnHandle = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      e.preventDefault();
      applyDraggedText();
      dragControls.start(e);
      animate(
        scope.current,
        {
          opacity: [0, 1],
          scale: [1, 0.8],
          x: [0, '30%'],
          y: [0, '30%'],
          transformOrigin: ['0 0', '15% 15%'],
          boxShadow: ['none', theme.shadows.floating],
        },
        { duration: 0.3, type: 'spring', stiffness: 300, damping: 20 }
      );
    },
    [applyDraggedText, dragControls, animate]
  );

  const handleDragEnd = useCallback(async () => {
    const { isHoveredOverArchive, draggedText, archiveCurrentNote } = stickyStore.getState();
    if (isHoveredOverArchive) {
      archiveCurrentNote();
      animate(scope.current, {
        opacity: 0,
        scale: 0,
        transitionEnd: {
          x: 0,
          y: 0,
        },
      });
    } else {
      await animate(scope.current, {
        scale: 1,
        x: 0,
        y: 0,
        boxShadow: 'none',
      });
      setNoteText(draggedText);
      animate(scope.current, { opacity: 0 });
    }

    setIsDragging(false);
    setShowArchive(false);
    setIsHoveredOverArchive(false);
    setDraggedText('');
  }, [
    stickyStore,
    animate,
    scope,
    setNoteText,
    setIsDragging,
    setShowArchive,
    setIsHoveredOverArchive,
    setDraggedText,
  ]);

  return (
    <DashboardWidget
      css={css`
        padding: 0;
      `}
    >
      <StickyNoteWrapper>
        <DashboardWidget.Header>
          <StickyNoteMenu />
        </DashboardWidget.Header>
        <DashboardWidget.Content
          css={css`
            position: relative;
            width: 100%;
            height: 100%;
            padding: ${theme.spacing(1.25, 2, 2.5, 2.25)};
          `}
        >
          <div
            css={css`
              position: relative;
              width: 100%;
              height: 100%;
            `}
          >
            <MainStickyNote handlePointerDownOnHandle={handlePointerDownOnHandle} handleDragEnd={handleDragEnd} />
            <DraggableStickyNote ref={scope} dragControls={dragControls} handleDragEnd={handleDragEnd} />
          </div>
          <ArchiveArea />
        </DashboardWidget.Content>
      </StickyNoteWrapper>
    </DashboardWidget>
  );
};

type StickyNoteWrapperProps = {
  children: React.ReactNode;
};

const StickyNoteWrapper = ({ children }: StickyNoteWrapperProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: ${theme.borderRadius.medium};
        overflow: hidden;
        padding: ${theme.spacing(1.25, 2, 2, 2)};
      `}
    >
      {children}
    </div>
  );
};

type StickyNoteWidgetProps = {
  color: StickyColor;
};

export const StickyNoteWidget: DashboardWidgetFC<StickyNoteWidgetProps> = ({ color = 'orange' }) => {
  const storedState = useMemo(() => getStickyNoteFromStorage(color), [color]);

  return (
    <StickyNoteProvider
      initialState={{
        color: color ?? 'yellow',
        noteText: storedState?.currentNote,
        archivedNotes: storedState?.archivedNotes,
      }}
    >
      <StickyNoteContext.Provider value={{ color }}>
        <StickyNoteWidgetWrapper />
      </StickyNoteContext.Provider>
    </StickyNoteProvider>
  );
};

StickyNoteWidget.config = {
  size: 'large-narrow',
  feature: 'general',
  availableSizes: ['medium-narrow', 'large-narrow'],
};

const peelInsideStyle = (isHovering: boolean, color: StickyColor) => css`
  position: absolute;
  width: ${isHovering ? '50px' : 0};
  height: ${isHovering ? '50px' : 0};
  right: 0;
  bottom: 0;
  background-image: ${stickyDragGradients[color]};
  background-position: ${isHovering ? '100% 100%' : '200% 200%'};
  transition: 0.5s ease;
  box-shadow: ${theme.shadows.heavy};
  transform-origin: 100% 100%;
`;

const mainStickyNoteStyle = (isHovering: boolean, color: StickyColor) => css`
  width: 100%;
  height: 100%;
  background: ${stickyColors[color]};
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${theme.zIndex.low};
  transition: 0.5s ease;
  padding: ${theme.spacing(1.5)};

  clip-path: polygon(
    ${isHovering ? 'calc(100% - 50px)' : '100%'} 100%,
    0% 100%,
    0% 0%,
    100% 0%,
    100% ${isHovering ? 'calc(100% - 50px)' : '100%'}
  );
`;
