import { useMemo } from 'react';
import { QueryKey, UseQueryOptions } from 'react-query';
import { LimitedSchemaQueryOptions, SchemaQueryKey } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import { ApiPayloadProps, GetCallsOverviewIO, ServiceStandardQueries } from '../types';
import { generateChartsPayload } from '../utils';
import { DemoData, useAdaptiveQuery } from './use-adaptive-query';

export interface UseGetOverviewDetailsArgs<E = unknown, T = GetCallsOverviewIO['output']> {
  demoData: DemoData<T>;
  demoQueryOptions?: Omit<UseQueryOptions<T, E, T>, 'queryKey' | 'queryFn'>;
  payload: ApiPayloadProps;
  isDemoAccount: boolean;
  realQueryOptions?: LimitedSchemaQueryOptions<
    SchemaIO<ServiceStandardQueries['GetCallsOverview']>,
    E,
    T,
    SchemaQueryKey<ServiceStandardQueries, 'GetCallsOverview'>
  >;
}

export function useGetOverviewDetails<E = unknown, T = GetCallsOverviewIO['output']>({
  demoData,
  demoQueryOptions,
  payload,
  isDemoAccount,
  realQueryOptions,
}: UseGetOverviewDetailsArgs<E, T>) {
  const { drillDownOptions, filters } = payload;
  const isValidFilters = !!(filters?.startDate && filters?.endDate && filters?.locations?.length);

  const request = useMemo<GetCallsOverviewIO['input']>(() => {
    if (!isValidFilters) return {} as GetCallsOverviewIO['input'];

    //This is type-casted till we move all the utils to schema-gen compatible
    return generateChartsPayload({ drillDownOptions, filters }) as GetCallsOverviewIO['input'];
  }, [payload]);

  return useAdaptiveQuery<'GetCallsOverview', E, T>({
    buildDemoQueryKey: ({ endpointName, request }) => ['demo', endpointName, request] as QueryKey,
    demoData,
    demoQueryEnabled: isValidFilters,
    demoQueryOptions,
    isDemoAccount,
    realQueryArgs: {
      endpointName: 'GetCallsOverview',
      request,
      options: realQueryOptions,
    },
    realQueryEnabled: isValidFilters,
  });
}
