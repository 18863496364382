import { useDeferredValue, useMemo } from 'react';
import Fuse from 'fuse.js';
import {
  DashboardWidgetData,
  WidgetLibraryItem,
  WidgetLibraryTray,
  isEnabled,
  useWidgetLibrary,
} from '@frontend/grid-dashboard';
import { useGridDashboard } from './store';
import { useAccessibleWidgetConfig } from './use-accesible-widgets-config';
import { useWidgets } from './use-dashboard-widgets';
import { customProps, widgetLibraryImages } from './widget-library-images-map';

export const DashboardWidgetLibrary = () => {
  const { widgetList } = useWidgets();
  const { librarySearchTerm, libraryFilterBy } = useWidgetLibrary(['librarySearchTerm', 'libraryFilterBy']);
  const deferredLibrarySearchTerm = useDeferredValue(librarySearchTerm);
  const { isLibraryOpen, closeWidgetLibrary } = useGridDashboard(['isLibraryOpen', 'closeWidgetLibrary']);

  const fuse = useMemo(
    () =>
      new Fuse(widgetList, {
        keys: ['title', 'description'],
        threshold: 0.3,
      }),
    [widgetList]
  );

  const filteredWidgets = useMemo(() => {
    const filterWidgetList =
      libraryFilterBy !== 'all'
        ? widgetList.filter((widget) => {
            return widget.component.config.feature === libraryFilterBy;
          })
        : widgetList;

    if (deferredLibrarySearchTerm.trim() === '') {
      return filterWidgetList;
    } else {
      const filteredList = fuse.search(deferredLibrarySearchTerm).map((result) => result.item);
      if (libraryFilterBy !== 'all') {
        filteredList.filter((widget) => {
          return widget.component.config.feature === libraryFilterBy;
        });
      }
      return filteredList;
    }
  }, [fuse, deferredLibrarySearchTerm, widgetList, libraryFilterBy]);

  return (
    <WidgetLibraryTray show={isLibraryOpen} onClose={closeWidgetLibrary} closeModal={closeWidgetLibrary}>
      {(filteredWidgets as DashboardWidgetData[]).map((widget) => (
        <WidgetLibraryItemWrapper key={widget.id} widget={widget} />
      ))}
    </WidgetLibraryTray>
  );
};

type WidgetLibraryContent = {
  widget: DashboardWidgetData;
};

const WidgetLibraryItemWrapper = ({ widget }: WidgetLibraryContent) => {
  const { currentBreakpoint } = useGridDashboard(['currentBreakpoint']);

  const { accessibleWidgets: widgetConfigs, addWidget } = useAccessibleWidgetConfig();
  const { title, description, icon, component: Component, hasAccess = true, plgConfig, id } = widget;
  const size = Component.config.size;
  const sizeOptions = typeof size === 'string' ? [size] : Object.values(size);

  const currentWidgetConfig = useMemo(() => widgetConfigs?.find((widget) => widget.id === id), [widgetConfigs, id]);

  const currentSize = useMemo(() => {
    if (!currentWidgetConfig) return;

    return typeof currentWidgetConfig?.size === 'string'
      ? currentWidgetConfig.size
      : currentWidgetConfig?.size[currentBreakpoint];
  }, [currentWidgetConfig, currentBreakpoint]);

  const allowedSizes = useMemo(() => {
    const sizes = [...new Set(sizeOptions)];

    if (currentBreakpoint !== 'large' && currentBreakpoint !== 'medium') {
      return sizes.filter((value) => !value.includes('wide'));
    }
    return sizes;
  }, [currentBreakpoint, sizeOptions]);

  if (!hasAccess && isEnabled(plgConfig?.hasFeature)) return null;

  return (
    <WidgetLibraryItem
      key={id}
      title={title}
      icon={icon}
      currentSize={currentSize}
      description={description}
      sizeOptions={allowedSizes}
      id={id}
      libraryImages={widgetLibraryImages[id]}
      customImageProps={customProps[id]}
      isEnabled={currentWidgetConfig?.enabled}
      plgConfig={plgConfig}
      onAddWidget={(id, selectedSize) => {
        addWidget({
          id,
          size: typeof size === 'string' ? selectedSize : { ...size, [currentBreakpoint]: selectedSize },
        });
      }}
    />
  );
};
