import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

interface Props {
  message?: string | JSX.Element;
  miniGap?: boolean;
  value?: number | string;
}

export const MultiOverview = ({ message, miniGap, value }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <section css={styles.overviewContainer}>
      <Heading level={3}>{t('Overview')}</Heading>
      <div css={styles.overview(miniGap)}>
        <Text weight='bold' style={{ fontSize: theme.fontSize(44) }}>
          {value}
        </Text>
        <Text color='subdued' size='large'>
          {message}
        </Text>
      </div>
    </section>
  );
};

const styles = {
  overviewContainer: css`
    padding: ${theme.spacing(3)};
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
  `,

  overview: (miniGap?: boolean) => css`
    align-items: center;
    column-gap: ${theme.spacing(miniGap ? 2 : 5)};
    display: flex;
    flex-wrap: wrap;
  `,
};
