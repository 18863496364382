import { BatchGetThreadStatusIO } from '../types';
import { standardizeBatchGetThreadStatusRequest } from '../utils';
import { useSMSDataV3Queries } from './use-sms-data-v3-queries';
import { UseSMSDataV3QueryEndpointArgs, useSMSDataV3Query } from './use-sms-data-v3-query';

/**
 * A hook that returns a query for the `BatchGetThreadStatus` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useBatchGetThreadStatusQuery = <E = unknown, D = BatchGetThreadStatusIO['output']>(
  args: UseSMSDataV3QueryEndpointArgs<'BatchGetThreadStatus', E, D>
) =>
  useSMSDataV3Query({
    endpointName: 'BatchGetThreadStatus',
    ...args,
    request: (context) =>
      // Sort the listed requests to avoid cache duplication due to different ordering of the same requests
      standardizeBatchGetThreadStatusRequest(typeof args.request === 'function' ? args.request(context) : args.request),
  });

/**
 * A hook that returns an array of queries for the `BatchGetThreadStatus` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useBatchGetThreadStatusQueries = <E = unknown, D = BatchGetThreadStatusIO['output']>(
  argsArr: UseSMSDataV3QueryEndpointArgs<'BatchGetThreadStatus', E, D>[]
) =>
  useSMSDataV3Queries<'BatchGetThreadStatus', E, D>(
    argsArr.map((args) => ({
      endpointName: 'BatchGetThreadStatus',
      ...args,
      request: (context) =>
        // Sort the listed requests to avoid cache duplication due to different ordering of the same requests
        standardizeBatchGetThreadStatusRequest(
          typeof args.request === 'function' ? args.request(context) : args.request
        ),
    }))
  );
