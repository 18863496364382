import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { CreateAndTermsConditionModal } from '@frontend/payments-setup';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Modal, ModalProps, MultiStep, MultiStepControl, useDebouncedValue } from '@frontend/design-system';
import { CollectPaymentMultiBody } from './body/collect-payment-multi-body';
import { LifecycleHooks } from './body/lifecycle/lifecycle-hooks';
import { CollectPaymentMultiProvider } from './collect-payment-multi.context';
import {
  CollectPaymentMultiSteps,
  UseCollectPaymentMultiStepProps,
  UseCollectPaymentMultiProps,
  CollectPaymentMultiState,
  CollectPaymentMultiFlow,
} from './collect-payment-multi.types';
import { useSyncCollectPaymentMultiToModal } from './hooks';
import { useCollectPaymentMulti } from './use-collect-payment-multi';
import { isPaymentCollectStep } from './utils/steps';

export type CollectPaymentMultiProps<F extends CollectPaymentMultiFlow = 'create'> = CollectPaymentMultiState<F> &
  PropsWithChildren<{
    multiStep: MultiStepControl;
    className?: string;
  }>;

export const CollectPaymentMulti = <F extends CollectPaymentMultiFlow = 'create'>({
  multiStep,
  className,
  children,
  ...props
}: CollectPaymentMultiProps<F>) => {
  return (
    <CollectPaymentMultiProvider {...props} multiStep={multiStep}>
      <LifecycleHooks />
      <MultiStep.Root
        {...multiStep}
        trackingId={props.trackingId || multiStep.trackingId}
        className={className}
        css={{ padding: 0 }}
      >
        <CollectPaymentMultiBody />
        {children}
      </MultiStep.Root>
    </CollectPaymentMultiProvider>
  );
};

export type CollectPaymentMultiModalProps = React.PropsWithChildren<ModalProps>;

export const CollectPaymentMultiModal = ({
  children,
  className = 'collect-payment-multi__modal-wrapper',
  ...modalPropsFull
}: CollectPaymentMultiModalProps) => {
  const { minWidth, ...modalProps } = modalPropsFull;

  return (
    <Modal
      {...modalProps}
      className={className}
      minWidth={minWidth}
      css={{
        padding: 0,
        background: 'transparent',
        '.step': {
          maxHeight: `min(calc(100vh - ${theme.spacing(6)}), 750px)`,
        },
      }}
    >
      {children}
    </Modal>
  );
};

export type CollectPaymentMultiInstanceProps<F extends CollectPaymentMultiFlow = 'create'> = CommonHTMLAttributes &
  PropsWithChildren<{
    multiStepProps: UseCollectPaymentMultiStepProps;
    collectProps: UseCollectPaymentMultiProps<F>;
    onChangeStep?: (step: CollectPaymentMultiSteps) => void;
  }>;

export const CollectPaymentMultiInstance = <F extends CollectPaymentMultiFlow = 'create'>({
  multiStepProps,
  collectProps,
  onChangeStep,
  ...rest
}: CollectPaymentMultiInstanceProps<F>) => {
  const state = useCollectPaymentMulti(collectProps, {
    ...multiStepProps,
    onChangeStep: (step, moveInfo) => {
      multiStepProps.onChangeStep?.(step, moveInfo);
      onChangeStep?.(step as CollectPaymentMultiSteps);
    },
  });

  useEffect(() => {
    onChangeStep?.(state.multiStep.activeStep as CollectPaymentMultiSteps);
  }, []);

  useSyncCollectPaymentMultiToModal({
    activeStep: state.multiStep.activeStep,
    setShowCancelAction: state.multiStep.setShowCancelAction,
  });

  return (
    <>
      <CollectPaymentMulti {...state} {...rest} />
      <CollectPaymentMultiPortal multiControl={state} />
    </>
  );
};

export type CollectPaymentMultiModalInstanceProps<F extends CollectPaymentMultiFlow = 'create'> = CommonHTMLAttributes &
  PropsWithChildren<{
    modalProps: Omit<ModalProps, 'children'>;
    multiStepProps?: UseCollectPaymentMultiStepProps;
    collectProps: UseCollectPaymentMultiProps<F>;
  }>;

/**
 * Only renders/initiates hooks when modalsProps.show is true.
 * Hook & children will re-mount every time modalsProps.show becomes true.
 */
export const CollectPaymentMultiModalInstance = <F extends CollectPaymentMultiFlow = 'create'>(
  props: CollectPaymentMultiModalInstanceProps<F>
) => {
  const debouncedShow = useDebouncedValue(props.modalProps.show, 500);

  /**
   * This {show & <component />} breaks modal closing animation.
   * So leave it mounted for a bit to allow closing animation.
   */
  const shouldShow = props.modalProps.show || debouncedShow;

  return <>{shouldShow && <RenderModalInstance {...props} />}</>;
};

const RenderModalInstance = <F extends CollectPaymentMultiFlow = 'create'>({
  modalProps,
  multiStepProps = {},
  collectProps: collectPropsRaw,
  ...rest
}: CollectPaymentMultiModalInstanceProps<F>) => {
  const collectProps = collectPropsRaw || { flow: 'create' };

  const [step, setStep] = useState<CollectPaymentMultiSteps>();

  const onChangeStep = useCallback((step: CollectPaymentMultiSteps) => {
    setStep(step);
  }, []);

  const isCollectStep = isPaymentCollectStep(step || '');

  const { onCancel, onComplete, ...multiStep } = multiStepProps;

  const state = useCollectPaymentMulti(collectProps, {
    ...multiStep,
    onCancel: () => {
      onCancel?.();
      modalProps.onClose();
    },
    onComplete: () => {
      onComplete?.();
      modalProps.onClose();
    },
    onChangeStep: (step, moveInfo) => {
      multiStepProps.onChangeStep?.(step, moveInfo);
      onChangeStep?.(step as CollectPaymentMultiSteps);
    },
  });

  useEffect(() => {
    onChangeStep?.(state.multiStep.activeStep as CollectPaymentMultiSteps);
  }, []);

  useSyncCollectPaymentMultiToModal({
    activeStep: state.multiStep.activeStep,
    setShowCancelAction: state.multiStep.setShowCancelAction,
  });

  return (
    <>
      <CollectPaymentMultiModal
        {...modalProps}
        minWidth={state.minWidth || modalProps.minWidth}
        disableCloseOnEscape={isCollectStep}
        disableCloseOnOverlayClick={isCollectStep}
      >
        <CollectPaymentMulti {...state} {...rest} />
      </CollectPaymentMultiModal>
      <CollectPaymentMultiPortal multiControl={state} />
    </>
  );
};

export const CollectPaymentMultiPortal = <F extends CollectPaymentMultiFlow = 'create'>({
  multiControl,
}: {
  multiControl: CollectPaymentMultiState<F>;
}) => {
  return (
    <>
      <CreateAndTermsConditionModal
        businessType=''
        creationCheckModalProps={multiControl.paymentSetupModalControl.modalProps}
      />
    </>
  );
};
