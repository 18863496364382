import { css } from '@emotion/react';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';

type Props = {
  count: number;
  users: TeamChatTypes.User[];
  onClick: () => void;
};
export const RepliesButton = ({ users, onClick, count }: Props) => {
  const { t } = useTranslation('team-chat');
  return (
    <NakedButton onClick={onClick} css={replyButtonStyle}>
      <span css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
        <ChatAvatar
          users={users}
          size='xxs'
          showGroupAvatars
          shouldShowOnlineStatus={false}
          showStatusAsTooltip={false}
        />
        {count === 1 ? (
          <>
            <Text size='small' color='subdued' className='replies-count'>
              {t('{{count}} Reply', {
                count,
              })}
            </Text>
            <Text size='small' className='view-replies' color='subdued' css={{ display: 'none' }}>
              {t('View Reply')}
            </Text>
          </>
        ) : (
          <>
            <Text size='small' color='subdued' className='replies-count'>
              {t('{{count}} Replies', {
                count,
              })}
            </Text>
            <Text size='small' className='view-replies' color='subdued' css={{ display: 'none' }}>
              {t('View Replies')}
            </Text>
          </>
        )}
      </span>
      <Icon name='caret-right-small' size={10} color='subdued' css={{ display: 'none' }} />
    </NakedButton>
  );
};

const replyButtonStyle = css`
  align-items: center;
  border: 1px solid transparent;
  color: ${theme.colors.primary50};
  display: flex;
  gap: ${theme.spacing(1)};
  justify-content: space-between;
  padding: ${theme.spacing(0.5)};
  width: 100%;
  :hover {
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.small};
    transition: all 0.2s;
    svg {
      display: block;
    }
    .replies-count {
      display: none;
    }
    .view-replies {
      display: block;
    }
  }
`;
