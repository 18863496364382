import { useMemo } from 'react';
import dayjs from 'dayjs';
import { BASE_URL_ANALYTICS, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { validateUUIDV4 } from '@frontend/string';
import { useAlert } from '@frontend/design-system';
import { useGetChartPayloadAndConfig } from './use-get-chart-payload-and-config';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

export type DeviceExtensionsCallType = 'answered' | 'placed';

type DeviceExtensions = {
  list: string[];
  renamedUUIDs: Record<string, string>;
};

type ExtensionAggregates = {
  Aggregates?: {
    Date: string;
    Total?: number;
    Values?: number[];
  }[];
  Extention?: string;
};

type LocationData = {
  CallAnswered?: ExtensionAggregates[];
  CallPlaced?: ExtensionAggregates[];
  Id: string;
};

export type DeviceExtensionsResponse = {
  LocationIds: LocationData[];
};

type ExtensionsData = {
  [date: string]: {
    [extensionName: string]: {
      answered: number[];
      placed: number[];
    };
  };
};

interface FormattedExtensionsData {
  existingData: ExtensionsData;
  newData?: ExtensionAggregates[];
  type: DeviceExtensionsCallType;
}

type Data = {
  details?: DeviceExtensions;
  records?: ExtensionsData;
};

type UseFetchDeviceExtensionsReport = {
  data?: Data;
  isLoading?: boolean;
  isHourlyData?: boolean;
};

const zeroArray = (length: number) => Array.from({ length }, () => 0);

const formatExtensionsData = ({ existingData, newData = [], type }: FormattedExtensionsData) => {
  const clonedData = { ...existingData };

  newData.forEach(({ Aggregates, Extention }) => {
    Aggregates?.forEach(({ Date, Values = [] }) => {
      if (!clonedData[Date]) {
        clonedData[Date] = {};
      }

      const extensionName = Extention?.trim() || 'unknown';

      // Ensure an extension object exists for each date
      if (!clonedData[Date][extensionName]) {
        const prefill = zeroArray(24);
        clonedData[Date][extensionName] = {
          answered: prefill,
          placed: prefill,
        };
      }

      // Sum the values for the extension
      // We found the cases of same extension names across multiple locations
      // If we don't sum the values, the later values will overwrite the previous ones
      clonedData[Date][extensionName][type] = clonedData[Date][extensionName][type].map(
        (val, idx) => val + (Values[idx] || 0)
      );
    });
  });

  return clonedData;
};

const api = async (filters: Partial<PhoneAnalyticsTypes.Filters>) => {
  const { data } = await http.post<{ data: DeviceExtensionsResponse }>(
    `${BASE_URL_ANALYTICS}/${'phonereports/v2/getphoneextentionaggregates'}`,
    PhoneAnalyticsApi.getDatesIntercepted(filters)
  );
  return data;
};

export const useFetchDeviceExtensionsReport = (): UseFetchDeviceExtensionsReport => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const { applicableFilters, config } = useGetChartPayloadAndConfig(filters, 'device-extensions');

  const isHourlyData =
    !!applicableFilters.start_date &&
    !!applicableFilters.end_date &&
    dayjs(applicableFilters.start_date).isSame(applicableFilters.end_date, 'day');

  const { data: deviceExtensions, isLoading } = useScopedQuery({
    ...config,
    queryFn: () => api(applicableFilters),
    onError: () => {
      alert.error(t("Couldn't load the aggregated device extensions report. Please try again."));
    },
  });

  const data: Data = useMemo(() => {
    const dataToUse = isDemoAccount ? demoData?.chartsData.deviceExtensions : deviceExtensions;

    // Process all locations data to extract extensions details
    const extensionsRecords =
      dataToUse?.LocationIds.reduce<ExtensionsData>((acc, { CallAnswered, CallPlaced }) => {
        const formattedAnswered = formatExtensionsData({
          existingData: acc,
          newData: CallAnswered,
          type: 'answered',
        });

        acc = formatExtensionsData({
          existingData: formattedAnswered,
          newData: CallPlaced,
          type: 'placed',
        });

        return acc;
      }, {}) || {};

    // All extensions record sets will have the same list of extension names
    // Hence picking up the list from the first record set
    const list = Object.keys(Object.values(extensionsRecords || {})[0] || {}).sort();

    // As per the product requirement, we need to rename the UUIDs to a more readable format
    const renamedUUIDs =
      list.reduce<Record<string, string>>((acc, extension) => {
        if (validateUUIDV4(extension)) {
          acc[extension] = t('Mobile App {{count}}', { count: Object.keys(acc).length + 1 });
          return acc;
        }

        return acc;
      }, {}) || {};

    return {
      details: {
        list,
        renamedUUIDs,
      },
      records: extensionsRecords,
    };
  }, [demoData, deviceExtensions, isDemoAccount]);

  return {
    data,
    isHourlyData,
    isLoading,
  };
};
