import { SchemaPhoneOverrideService, SchemaPhoneOverrideServiceV2 } from '@frontend/schema';
import {
  CreateOverrideTypes,
  DeleteOverrideTypes,
  ListOverrideTypes,
  ReadOverrideTypes,
  UpdateOverrideTypes,
  ListOverrideTypes2,
} from './types';

export const listOverride = (
  req: ListOverrideTypes['input'],
  opts?: { locationId?: string }
): Promise<ListOverrideTypes['output']> => {
  return SchemaPhoneOverrideService.List(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const listOverride2 = (
  req: ListOverrideTypes2['input'],
  opts?: { tenantId?: string }
): Promise<ListOverrideTypes2['output']> => {
  return SchemaPhoneOverrideServiceV2.List(
    req,
    opts?.tenantId ? { headers: { 'Location-Id': opts.tenantId } } : undefined
  );
};

export const readOverride = (
  req: ReadOverrideTypes['input'],
  opts?: { locationId?: string }
): Promise<ReadOverrideTypes['output']> => {
  return SchemaPhoneOverrideService.Read(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const createOverride = (
  req: CreateOverrideTypes['input'],
  opts?: { locationId?: string }
): Promise<CreateOverrideTypes['output']> => {
  return SchemaPhoneOverrideService.Create(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const UpdateOverride = (
  req: UpdateOverrideTypes['input'],
  opts?: { locationId?: string }
): Promise<UpdateOverrideTypes['output']> => {
  return SchemaPhoneOverrideService.Update(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};

export const DeleteOverride = (
  req: DeleteOverrideTypes['input'],
  opts?: { locationId?: string }
): Promise<DeleteOverrideTypes['output']> => {
  return SchemaPhoneOverrideService.Delete(
    req,
    opts?.locationId ? { headers: { 'Location-Id': opts.locationId } } : undefined
  );
};
