import { FC, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { FreeTrialSubscriptionQueries } from '@frontend/api-free-trial-subscription';
import { ErrorBoundary, MainBoundary } from '@frontend/error-boundary';
import { addWidgetToLayout, DashboardWidgetData } from '@frontend/grid-dashboard';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { BundleTrialHeader } from './BundleTrialHeader';
import { BundleTrialWidgetsLayout } from './BundleTrialWidgetsLayout';

interface BundleTrialDashboardProps {
  styles?: Interpolation<Theme>;
  dashboardWidgetsMap: Record<string, DashboardWidgetData>;
  addWidgetToDashboard: (widgetData: Parameters<typeof addWidgetToLayout>[1]) => void;
}

export const BundleTrialDashboard: FC<BundleTrialDashboardProps> = ({
  addWidgetToDashboard,
  styles,
  dashboardWidgetsMap,
}) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { data } = FreeTrialSubscriptionQueries.useGetBundleTrialStatus({
    locationIds: selectedLocationIds,
  });

  const { bundle, trialEndDate } = useMemo(() => {
    return {
      bundle:
        data?.bundleTrialStatus?.[0]?.recommendedBundle ??
        SalesforceAccountProductBundle.UNKNOWN_SALESFORCE_ACCOUNT_PRODUCT_BUNDLE,
      trialEndDate: data?.maxTrialEndDate,
    };
  }, [data]);

  if (!bundle || !trialEndDate) {
    return null;
  }

  return (
    <div css={[containerStyles, styles]}>
      <BundleTrialHeader>
        <BundleTrialHeader.Title bundle={bundle} />
        <BundleTrialHeader.Actions bundle={bundle} trialEndDate={trialEndDate} locationIds={selectedLocationIds} />
      </BundleTrialHeader>
      <ErrorBoundary fallback={MainBoundary}>
        <BundleTrialWidgetsLayout
          bundle={bundle}
          dashboardWidgetsMap={dashboardWidgetsMap}
          addWidgetToDashboard={addWidgetToDashboard}
        />
      </ErrorBoundary>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.large,
  backgroundColor: theme.colors.neutral10,
});
