import { FC } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { TaskCenterTypes, useTaskCenterMutations } from '@frontend/api-task-center';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Text,
  Chip,
  PopoverMenu,
  usePopoverMenu,
  PopoverMenuItem,
  Button,
  SpinningLoader,
} from '@frontend/design-system';
import { useTaskCenterStore } from '../../../provider';
import { StatusUpdater } from '../status-updater/status-updater';

export const TaskItem: FC<TaskCenterTypes.Task> = ({ dueDate, title, id, status, locationId }) => {
  const { locationsData } = useTaskCenterStore(['locationsData']);
  const { t } = useTranslation('task-center');
  const user = getUser();

  const date = dueDate ? dayjs(dueDate).format('MMMM D, YYYY') : t('No Due Date');
  const location: string = locationsData.find((loc) => loc.id === locationId)?.name || 'Location Name';

  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu();
  const { taskDeleteMutation, taskUpdateMutation } = useTaskCenterMutations();
  const { mutate: updateTask, isLoading: isUpdating } = taskUpdateMutation;
  const { mutate: deleteTask, isLoading: isDeleting } = taskDeleteMutation;

  const isLoading = isDeleting || isUpdating;

  return (
    <div
      css={css`
        padding: ${theme.spacing(3)};
        gap: ${theme.spacing(1.5)};
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid ${theme.colors.neutral20};
        position: relative;

        .row {
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        }
        :hover {
          cursor: pointer;
          background-color: ${theme.colors.neutral5};
        }
      `}
    >
      <div className='row'>
        <StatusUpdater
          status={status}
          onAction={() => {
            if (id && user?.userID)
              // @ts-ignore The schema is not updated on production yet. It will be updated after the schema releases
              updateTask({ id, updatedBy: user.userID, status: TaskCenterTypes.TaskStatus.STATUS_COMPLETED });
          }}
        />
        <Text>{title}</Text>
        <Button iconName='more' size='small' variant='secondary' {...getTriggerProps()} />
        <PopoverMenu {...getMenuProps()}>
          <PopoverMenuItem
            {...getItemProps()}
            iconName='edit'
            size='small'
            variant='secondary'
            onClick={() => {
              if (id && user?.userID)
                // @ts-ignore The schema is not updated on production yet. It will be updated after the schema releases
                updateTask({ id, updatedBy: user.userID, status: TaskCenterTypes.TaskStatus.STATUS_COMPLETED });
              close();
            }}
          >
            <Icon name='check' size={16} /> <Text>{t('Mark as Done')}</Text>
          </PopoverMenuItem>
          <PopoverMenuItem {...getItemProps()} iconName='edit' size='small' variant='secondary' disabled>
            <Icon name='edit' size={16} /> <Text>{t('Edit')}</Text>
          </PopoverMenuItem>
          <PopoverMenuItem
            {...getItemProps()}
            iconName='edit'
            size='small'
            variant='secondary'
            onClick={() => {
              if (id && user?.userID)
                updateTask({
                  id,
                  status: TaskCenterTypes.TaskStatus.STATUS_IN_PROGRESS,
                  // @ts-ignore The schema is not updated on production yet. It will be updated after the schema releases
                  updatedBy: user.userID,
                });
              close();
            }}
          >
            <Icon name='in-progress' size={16} /> <Text>{t('Move to In-Progress')}</Text>
          </PopoverMenuItem>
          <PopoverMenuItem
            {...getItemProps()}
            iconName='edit'
            size='small'
            variant='secondary'
            onClick={() => {
              if (id) deleteTask({ id });
              close();
            }}
          >
            <Icon name='check' size={16} color='error' /> <Text color='error'>{t('Delete Task')}</Text>
          </PopoverMenuItem>
        </PopoverMenu>
      </div>
      <div className='row'>
        <Icon size={16} name='priority-high' />
        <Chip leftElement={<Icon name='location' size={16} />} variant='neutral' maxWidth='100%'>
          {location}
        </Chip>
        <Chip
          leftElement={<Icon name='calendar-schedule-back' size={16} color='default' />}
          variant='neutral'
          maxWidth='100%'
          css={css`
            color: ${theme.font.colors.default};
            font-weight: ${theme.font.weight.bold};
          `}
        >
          {date}
        </Chip>
      </div>
      {isLoading && (
        <div
          css={css`
            inset: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${theme.colors.black};
            opacity: 0.3;
          `}
        >
          <SpinningLoader size='large' />
        </div>
      )}
    </div>
  );
};
