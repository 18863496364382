import { FieldMask } from '@weave/schema-gen-ts/dist/google/protobuf/field_mask.pb';
import { isEqual } from 'lodash-es';
import { FIELD_MASK_CREATION_FILTER_PRESETS } from './constants';
import { convertObjectToFieldMaskPaths } from './convert-object-to-field-mask';
import { FieldMaskCreationConfig, FieldMaskUpdateConfig, FieldMaskUpdateEntry, FieldMaskUpdateFilterFn } from './types';
import { getObjectValueFromPath } from './update-object-from-field-mask';

/**
 * A function that takes two objects and returns a list of strings that represents the paths of all values that are
 * different between the objects according to the provided `filter`.
 *
 * @param originalObj - The object from which to derive the paths
 * @param updatedObj - The object to compare against
 * @param config.filter (optional) - A callback function (or preset) that determines which entries in the object might
 * be ignored. It takes in an object for each entry in the object with the following values:
 * - `key`: The key associated with the value in the object, the provided `keyTransformation` does not affect this key
 * - `transformedKey`?: The key associated with the value in the object, after transformation defined by the provided
 *   `keyTransformation`. Will be `undefined` if `keyTransformation` is not provided.
 * - `updatedValue`: The updated value in the object.
 * - `originalValue`: The value in the original object.
 *
 * Some preset filters are also available that are based on the updated value.
 * To use them, pass one of the following strings instead:
 * - 'remove-nullish'
 * - 'remove-falsy'
 * - 'preserve-all' (default)
 * @param config.keyTransformation (optional) - A callback function to transform each key in the path. Useful to
 * convert between snake_case and camelCase when working with backend and frontend casing differences.
 * @param config.isEqual (optional) a function that determines if two values are equal. It takes in an argument that is
 * an object with the following values:
 * - `key`: The key associated with the value in the object, the provided `keyTransformation` does not affect this key
 * - `transformedKey`?: The key associated with the value in the object, after transformation defined by the provided
 *   `keyTransformation`. Will be `undefined` if `keyTransformation` is not provided.
 * - `updatedValue`: The updated value in the object.
 * - `originalValue`: The value in the original object.
 *
 * The function is expected to return `true` if the values are equal, `false` if they are not equal, and `undefined` to
 * default back to the default equality check (`isEqual` from `lodash-es`.
 */
export const convertObjectUpdateToFieldMaskPaths = <T extends object>(
  originalObj: T,
  updatedObj: T,
  { filter = 'preserve-all', keyTransformation, isEqual: providedIsEqual }: FieldMaskUpdateConfig<T> = {}
): NonNullable<FieldMask['paths']> => {
  const filterFn: FieldMaskUpdateFilterFn<T> =
    typeof filter === 'string'
      ? ({ updatedValue, ...rest }) => FIELD_MASK_CREATION_FILTER_PRESETS[filter]({ value: updatedValue, ...rest })
      : filter;
  const originalObjPaths = convertObjectToFieldMaskPaths(originalObj);
  const updatedObjPaths = convertObjectToFieldMaskPaths(updatedObj);
  const allPaths = Array.from(new Set([...originalObjPaths, ...updatedObjPaths]));

  const updatedPaths = allPaths.filter((path) => {
    const originalObjValue = getObjectValueFromPath<T>(originalObj, path);
    const updatedObjValue = getObjectValueFromPath<T>(updatedObj, path);

    const key = path.split('.').pop();
    if (!key) return false;

    const entryData: FieldMaskUpdateEntry<T> = {
      key: key as keyof T,
      transformedKey: keyTransformation?.(key),
      updatedValue: updatedObjValue as T[keyof T],
      originalValue: originalObjValue as T[keyof T],
    };

    const providedIsEqualResult = providedIsEqual?.(entryData);

    if (providedIsEqualResult ?? isEqual(originalObjValue, updatedObjValue)) return false;

    const shouldKeep = filterFn({
      key: key as keyof T,
      transformedKey: keyTransformation?.(key),
      updatedValue: updatedObjValue as T[keyof T],
      originalValue: originalObjValue as T[keyof T],
    });

    return shouldKeep;
  });

  return updatedPaths.map((path) => {
    const keys = path.split('.');
    const transformedKeys = keys.map((key) => keyTransformation?.(key) ?? key);
    return transformedKeys.join('.');
  });
};

/**
 * A function that takes two objects and returns a FieldMask that represents the paths of all values that are
 * different between the objects according to the provided `filter`.
 *
 * @param originalObj - The object from which to derive the paths
 * @param updatedObj - The object to compare against
 * @param config.filter (optional) - A callback function (or preset) that determines which entries in the object might
 * be ignored. It takes in an object for each entry in the object with the following values:
 * - `key`: The key associated with the value in the object, the provided `keyTransformation` does not affect this key
 * - `transformedKey`?: The key associated with the value in the object, after transformation defined by the provided
 *   `keyTransformation`. Will be `undefined` if `keyTransformation` is not provided.
 * - `value`: The updated value in the object.
 * - `originalValue`: The value in the original object.
 *
 * Some preset filters are also available that are based on the updated value.
 * To use them, pass one of the following strings instead:
 * - 'remove-nullish'
 * - 'remove-falsy'
 * - 'preserve-all' (default)
 * @param config.keyTransformation (optional) - A callback function to transform each key in the path. Useful to
 * convert between snake_case and camelCase when working with backend and frontend casing differences.
 */
export const convertObjectUpdateToFieldMask = <T extends object>(
  originalObj: T,
  updatedObj: T,
  { filter = 'preserve-all', keyTransformation }: FieldMaskCreationConfig<T> = {}
): FieldMask => {
  const paths = convertObjectUpdateToFieldMaskPaths(originalObj, updatedObj, {
    filter:
      typeof filter === 'string' ? filter : ({ updatedValue, ...rest }) => filter({ value: updatedValue, ...rest }),
    keyTransformation,
  });

  return new FieldMask({
    paths,
  });
};
