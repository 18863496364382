const ANALYTICS_BASE = '/analytics';
const ROI_BASE = `${ANALYTICS_BASE}/roi`;

// URLS
export const URLs = {
  ANALYTICS_DASHBOARD: `${ANALYTICS_BASE}/dashboard`,
  AUTO_RECALL_ANALYTICS: `${ANALYTICS_BASE}/auto-recall`,
  MESSAGING_ANALYTICS: `${ANALYTICS_BASE}/messaging`,
  PHONE_ANALYTICS: `${ANALYTICS_BASE}/phone`,
  ROI_APPOINTMENT_REMINDERS: `${ROI_BASE}/appointment-reminders`,
  ROI_BULK_TEXT: `${ROI_BASE}/bulk-text`,
  ROI_MISSED_CALL_TEXT_MULTI: `${ROI_BASE}/missed-call-text-multi-conversion`,
  ROI_MISSED_CALL_TEXT: `${ROI_BASE}/missed-call-text-conversion`,

  CALL_INTEL_BASE: '/analytics/call-intelligence',
  CALL_INTEL_MAIN_PAGE: '/analytics/call-intelligence/main',
  CALL_INTEL_SUBSCRIBE: '/portal/subscriptions/call-intelligence',
  CALL_INTEL_TALK_TO_SALES: 'https://www.getweave.com/campaign/call-intelligence/',

  MESSAGING_ANALYTICS_FEEDBACK:
    'https://feedback.getweave.com/forums/952282-analytics/suggestions/46461094-message-analytics-in-weave-portal-website',
  MESSAGING_ANALYTICS_HELP: 'https://www.weavehelp.com/weavehelp/article/using-messaging-analytics',

  PHONE_ANALYTICS_FEEDBACK:
    'https://feedback.getweave.com/forums/938685-weave-portal/suggestions/45636907-call-analytics',
  PHONE_ANALYTICS_HELP: 'https://www.weavehelp.com/weavehelp/article/using-phone-analytics',
  PHONE_ANALYTICS_VIDEO_ID: 'fq3b1i62az',

  PRACTICE_ANALYTICS_FEEDBACK:
    'https://feedback.getweave.com/forums/952282-analytics/suggestions/47770865-practice-analytics',
  PRACTICE_ANALYTICS_HELP: 'https://www.weavehelp.com/weavehelp/article/using-practice-analytics',
  PRACTICE_ANALYTICS_SUBSCRIBE: '/portal/subscriptions/practice-analytics',

  // Analytics Action URLs
  BULK_MESSAGES: '/portal/messages/bulk',
  QUICK_FILL: 'https://www.weavehelp.com/hc/en-us/articles/360061238691',
};

// Fixed values
export const DEFAULT_HOURS = 'all-hours';
export const DASHBOARD_CHART_SIZE = 200;
