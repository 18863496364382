import { useEffect } from 'react';
import {
  RegisterClickRequest,
  RegisterQueryRequest,
} from '@weave/schema-gen-ts/dist/schemas/search/history/v1/search.pb';
import { UiSource } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/ui_source.pb';
import { omit } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { SearchHistoryApi } from '@frontend/api-search-history';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { formatUUID, genUUIDV4 } from '@frontend/string';
import { useEventListener } from '@frontend/design-system';
import { REGISTER_CLICK_EVENT_NAME, TRIGGER_SEARCH_EVENT_NAME, UPDATE_SEARCH_EVENT_NAME } from './constants';
import { useSearchHistoryShallowStore } from './search-history-store';
import { RegisterClickPayload, TrigggerSearchPayload, UpdateSearchPayload } from './types';
import { calculateElapsed } from './utils/calculate-elapsed';
import { checkExceedsMaxTime } from './utils/check-exceeds-max-time';
import { convertSessionSearchesToResults } from './utils/convert-session-searches-to-results';
import { validateHasAllSearchResults } from './utils/validate-has-all-search-results';

const searchHistoryQueryKeys = {
  base: 'searchHistory',
  registerQueryBase: () => [searchHistoryQueryKeys.base, 'registerQuery'],
  registerQuery: (request: RegisterQueryRequest) => [
    ...searchHistoryQueryKeys.registerQueryBase(),
    omit(request, 'elapsed'),
  ],
  registerClick: (request: RegisterClickRequest) => [searchHistoryQueryKeys.base, 'registerClick', request],
};

export const useSearchHistory = (id: UiSource) => {
  const queryClient = useQueryClient();
  const { selectedOrgId, selectedLocationIds: locationIds } = useAppScopeStore();
  const orgId = selectedOrgId;
  const user = getUser();
  const userId = user?.userID;

  const {
    debouncedValue,
    resetSessionSearches,
    searchId,
    searchTerm,
    sessionSearches,
    setDebouncedValue,
    setSearchId,
    setSearchTerm,
    setSessionSearches,
    setStartTime,
    setUiSource,
    startTime,
    uiSource,
  } = useSearchHistoryShallowStore(
    'debouncedValue',
    'resetSessionSearches',
    'searchId',
    'searchTerm',
    'sessionSearches',
    'setDebouncedValue',
    'setSearchId',
    'setSearchTerm',
    'setSessionSearches',
    'setStartTime',
    'setUiSource',
    'startTime',
    'uiSource'
  );

  const startSearch = (payload: CustomEvent<TrigggerSearchPayload>) => {
    if (!payload.detail.searchTerm) return;
    resetSessionSearches(payload.detail.entities);
    const newSearchId = genUUIDV4();
    setSearchTerm(payload.detail.searchTerm);
    setSearchId(newSearchId);
    setStartTime(new Date().getTime());
    setUiSource(payload.detail.uiSource);
  };

  const checkShouldRegisterQuery = () =>
    debouncedValue &&
    searchId &&
    searchTerm === debouncedValue &&
    (validateHasAllSearchResults(sessionSearches) || // all the search results has returned within the given time
      checkExceedsMaxTime(startTime)); // it has exceeded the max time so just pass the search results it has

  const formRegisterQueryRequest = (): RegisterQueryRequest => ({
    elapsed: calculateElapsed(startTime, new Date().getTime()),
    id: searchId,
    locationIds,
    orgId,
    query: debouncedValue,
    results: convertSessionSearchesToResults(sessionSearches),
    uiSource: uiSource.toString(),
    userAgent: navigator.userAgent,
    userId,
  });

  const registerQuery = () => {
    if (id !== uiSource) return;
    if (!checkShouldRegisterQuery()) return;

    const formattedRequest = formRegisterQueryRequest();

    // checks to see if the searchId has already been registered
    const found = queryClient.getQueriesData({
      queryKey: searchHistoryQueryKeys.registerQueryBase(),
      predicate: (query) => {
        return (query.queryKey?.[2] as RegisterQueryRequest)?.id === searchId;
      },
    });
    if (found.length > 0) return;

    return queryClient.fetchQuery({
      queryKey: searchHistoryQueryKeys.registerQuery(formattedRequest),
      queryFn: () => SearchHistoryApi.registerQuery(formattedRequest),
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
    });
  };

  const registerClick = async (payload: CustomEvent<RegisterClickPayload>) => {
    if (id !== uiSource) return;
    const { entity, entityId, entityPosition, itemPosition } = payload.detail;
    const formattedUUID = formatUUID(entityId);
    if (formattedUUID === undefined) return;

    // checks the query cache to see if the query has already been registered
    const registerQueryKey = searchHistoryQueryKeys.registerQuery(formRegisterQueryRequest());
    const queryData = queryClient.getQueryData(registerQueryKey);

    const request = {
      entity,
      entityId: formattedUUID, // @deprecated - will use just `itemId` in the future
      entityPosition,
      id: genUUIDV4(),
      itemId: formattedUUID,
      itemPosition,
      locationIds,
      orgId,
      searchId,
      uiSource,
      userAgent: navigator.userAgent,
      userId,
    };

    queryClient.fetchQuery({
      queryKey: searchHistoryQueryKeys.registerClick(request),
      queryFn: () => SearchHistoryApi.registerClick(request),
    });

    // if the user has clicked an item before the RegisterQuery has returned, we need to register the query
    if (!queryData) {
      await registerQuery();
    } else {
      setSearchId('');
    }
  };

  const updateSearches = (payload: CustomEvent<UpdateSearchPayload>) => {
    const { entity, results } = payload.detail;
    setSessionSearches({
      [entity]: {
        elapsed: new Date().getTime(),
        results,
      },
    });
  };

  useEventListener(REGISTER_CLICK_EVENT_NAME as any, registerClick, true);
  useEventListener(TRIGGER_SEARCH_EVENT_NAME as any, startSearch, true);
  useEventListener(UPDATE_SEARCH_EVENT_NAME as any, updateSearches, true);

  useEffect(() => {
    registerQuery();
  }, [debouncedValue, searchId, searchTerm, sessionSearches, startTime]);

  useEffect(() => {
    return () => {
      setSearchId('');
      setDebouncedValue('');
      setSearchTerm('');
      setSessionSearches({});
      setStartTime(0);
    };
  }, []);
};
