import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Button, PopoverMenu, PopoverMenuItem, usePopoverMenu, Text } from '@frontend/design-system';
import { useStickyNoteStore } from '../providers';
import { StickyNoteTrackingIds } from '../tracking-ids';
import { ArchivedNotesCarousel } from './archived-notes-carousel';

export const StickyNoteMenu = () => {
  const { t } = useTranslation('sticky-note-widget');
  const [open, setOpen] = useState(false);
  const { getMenuProps, getItemProps, getTriggerProps } = usePopoverMenu({ placement: 'bottom-end' });
  const { archiveCurrentNote, archivedNotes, noteText, setArchivingNote } = useStickyNoteStore([
    'archiveCurrentNote',
    'archivedNotes',
    'noteText',
    'setArchivingNote',
  ]);

  const handleArchiveClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleArchiveCurrentNote = useCallback(() => {
    setArchivingNote(noteText);
    archiveCurrentNote();
  }, [archiveCurrentNote, noteText, setArchivingNote]);

  return (
    <>
      <Button variant='secondary' iconName='more' {...getTriggerProps()} />
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem
          css={css`
            cursor: pointer;
          `}
          Icon={() => <Icon name='note-small' />}
          {...getItemProps({
            index: 0,
            onClick: handleArchiveCurrentNote,
          })}
          trackingId={StickyNoteTrackingIds.newSticky}
          disabled={!noteText.length}
        >
          <Text as='span'>{t('New sticky')}</Text>
        </PopoverMenuItem>
        <PopoverMenuItem
          css={css`
            cursor: pointer;
          `}
          Icon={() => <Icon name='archive-small' />}
          {...getItemProps({
            index: 1,
            onClick: () => setOpen(true),
          })}
          trackingId={StickyNoteTrackingIds.viewStickyArchive}
          disabled={!archivedNotes.length}
        >
          <Text as='span'>{t('View Sticky Archive')}</Text>
        </PopoverMenuItem>
      </PopoverMenu>
      <ArchivedNotesCarousel isCarouselOpen={open} onCarouselClose={handleArchiveClose} />
    </>
  );
};
