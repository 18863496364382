import {
  DynamicFieldKey_Enum,
  DynamicFieldProperty_Enum,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useAppScopeStore } from '@frontend/scope';
import { ComposerMode } from '../types';

export type DynamicFieldTag = {
  key: DynamicFieldKey_Enum;
  label: string;
  symbol: string;
};

export const useDynamicFields = (composerMode: ComposerMode) => {
  const { selectedLocationIds: locationIds } = useAppScopeStore();

  const getTemplateTypeSlugs = () => {
    switch (composerMode) {
      case 'marketing':
        return [TemplateType_Slug.BULK_MESSAGE];
      case 'feedback':
        return [TemplateType_Slug.MANUAL_REVIEW_REQUEST];
      default:
        return undefined;
    }
  };

  const { data = [] } = TemplatorV2Queries.useTemplateTypesList(
    {
      businessGroupIds: locationIds,
      templateTypeSlugs: getTemplateTypeSlugs(),
    },
    {
      select: (response) => response?.templateTypes?.[0]?.dynamicFields,
    }
  );

  const dynamicFields = data.map((field) => ({
    key: field.key ?? DynamicFieldKey_Enum.UNKNOWN,
    label: field.label ?? '',
    symbol: field.key ? '{{' + field.key + '}}' : '',
  }));

  const bindingsList = data.map((tag) => ({
    property: tag.property ?? DynamicFieldProperty_Enum.UNKNOWN,
    values: tag.exampleValues ?? [],
  }));

  return { bindingsList, dynamicFields };
};
