import { useContext } from 'react';
import { _StreamAPIContext } from './stream-api-provider';

export const useTeamChatApi = (api: 'stream' | 'mock') => {
  const SelectedContext = (
    {
      stream: _StreamAPIContext,
      mock: _StreamAPIContext, //TODO: replace with mock provider
    } as const
  )[api];
  const context = useContext(SelectedContext);
  if (!context) {
    throw new Error('useTeamChatApi must be used with a valid api type');
  }
  return context;
};
