import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds, PaymentActionTrackingIds } from '../../tracking-ids';
import { PaymentActionEnum } from '../../types';

export const SetupPaymentAccountCard: FC = () => {
  const { source, handleAction } = usePaymentsPromotion();
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });

  return (
    <div css={cardStyles}>
      <Text size='medium' color='subdued'>
        {t('Set up Payments to see patient balance here')}
      </Text>
      <Button
        variant='secondary'
        onClick={() => handleAction(PaymentActionEnum.CREATE_ACCOUNT)}
        trackingId={getPaymentHVATrackingIds({
          source,
          trackingId: PaymentActionTrackingIds[PaymentActionEnum.CREATE_ACCOUNT],
        })}
      >
        {t('Setup Payment Account')}
      </Button>
    </div>
  );
};

const cardStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.colors.neutral5,
  border: `1px dashed ${theme.colors.neutral20}`,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  borderRadius: theme.borderRadius.large,
});
