import { useModalControl } from '@frontend/design-system';
import PmsChangeConfirmationModal from './pms-change-confirmation-modal.component';

interface UsePmsChangeConfirmationModalResults {
  pmsChangeConfirmationModalControls: ReturnType<typeof useModalControl>;
  PmsChangeConfirmationModal: typeof PmsChangeConfirmationModal;
}

export const usePmsChangeConfirmationModal = (): UsePmsChangeConfirmationModalResults => {
  const modalControls = useModalControl();

  return {
    pmsChangeConfirmationModalControls: modalControls,
    PmsChangeConfirmationModal,
  };
};
