import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import type { NotificationActions } from '@frontend/types';
import {
  BaseNotificationComponentProps,
  BaseNotificationInner,
  BaseNotificationOuter,
} from '../notification-components/base';
import { PreferenceNotification } from '../notification-queue';

export const PausePreferenceNotificationComponent = ({
  notification,
  emit,
  ...rest
}: BaseNotificationComponentProps<PreferenceNotification>) => {
  const { t } = useTranslation();

  const actions: NotificationActions = useMemo(
    () => [
      {
        label: t('Pause'),
        action: () =>
          emit(
            {
              action: 'preference',
              payload: {
                actionType: 'pause',
              },
            },
            notification
          ),
      },
      {
        label: t('No Thanks'),
        variant: 'secondary',
        action: () =>
          emit(
            {
              action: 'preference',
              payload: {
                actionType: 'dismiss',
              },
            },
            notification
          ),
      },
    ],
    [emit, notification, t]
  );

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={false} {...rest}>
      <BaseNotificationInner
        actions={actions}
        title={notification.payload.title || t('Notification Preference')}
        body={notification.payload.body || t('Would you like to adjust your notification settings?')}
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
        notificationType={notification.payload.notificationType}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
