import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Text,
  TextButton,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
  Breadcrumbs,
  NakedButton,
} from '@frontend/design-system';
import { useGlobalSearchFeatureLabelMap, globalSearchFeatures, type GlobalSearchFeatures } from '../config';
import { GlobalSearchTrackingIds } from '../constants';
import { useGlobalSearch } from '../provider';

type GlobalSearchFiltersProps = {
  children: React.ReactNode;
};

const FAX_FEATURE = 'faxes' as const;

export const GlobalSearchFilters = ({ children }: GlobalSearchFiltersProps) => {
  const { isExpandedView, viewAllFeature, debouncedSearchTerm, setViewAllFeature } = useGlobalSearch([
    'isExpandedView',
    'viewAllFeature',
    'debouncedSearchTerm',
    'setViewAllFeature',
  ]);
  const labelMap = useGlobalSearchFeatureLabelMap();

  return (
    <GlobalSearchFiltersContainer>
      {isExpandedView && viewAllFeature ? (
        <Breadcrumbs>
          <Breadcrumbs.Path as={NakedButton} onClick={() => setViewAllFeature('')}>
            All Results
          </Breadcrumbs.Path>
          <Breadcrumbs.Path>
            All {labelMap[viewAllFeature]} for '{debouncedSearchTerm}'
          </Breadcrumbs.Path>
        </Breadcrumbs>
      ) : (
        <GlobalSearchFeatureFilter />
      )}
      {children}
    </GlobalSearchFiltersContainer>
  );
};

export const GlobalSearchFiltersContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <section
      css={css`
        padding: ${theme.spacing(1.5)};
        padding-left: calc(${theme.spacing(2)} - 3px);
        padding-right: calc(${theme.spacing(2)} - 3px);
        border-bottom: 1px solid ${theme.colors.neutral20};
        margin-left: -5px;
        margin-right: -5px;
      `}
    >
      {children}
    </section>
  );
};

export const GlobalSearchFeatureFilter = () => {
  const { t } = useTranslation('global-search');
  const { searchFor, setSearchFor } = useGlobalSearch(['searchFor', 'setSearchFor']);
  const labelMap = useGlobalSearchFeatureLabelMap();
  const { getItemProps, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom',
  });

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const hasFaxSearch = getFeatureFlagValue('fax-global-search');

  const enabledGlobalSearchFeatures = hasFaxSearch
    ? globalSearchFeatures
    : globalSearchFeatures.filter((feature) => feature !== FAX_FEATURE);

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(0.5)};
        `}
      >
        <Text color='subdued'>{t('Searching:')}</Text>
        <TextButton
          trackingId={GlobalSearchTrackingIds.searchTypeFilter}
          {...getTriggerProps()}
          css={css`
            color: ${theme.font.colors.primary};
            font-size: ${theme.font.size.large};
            display: flex;
            align-items: center;
            border-radius: ${theme.borderRadius.small};
            gap: ${theme.spacing(1)};
          `}
        >
          <span>{labelMap[searchFor]}</span>
          <Icon name='alt-caret-down-tiny' />
        </TextButton>
      </div>
      <PopoverMenu
        css={css`
          width: 228px;
        `}
        {...getMenuProps()}
      >
        {enabledGlobalSearchFeatures.map((feature, index) => (
          <PopoverMenuItem
            trackingId={GlobalSearchTrackingIds.searchType(feature)}
            {...getItemProps({ index, onClick: () => setSearchFor(feature) })}
            active={searchFor === feature}
            css={{ cursor: 'pointer' }}
            key={feature}
          >
            {labelMap[feature]}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};

type GlobalSearchSectionProps = {
  id: GlobalSearchFeatures;
  children: React.ReactNode;
};

export const GlobalSearchFiltersSection = ({ children, id }: GlobalSearchSectionProps) => {
  const { searchFor, viewAllFeature } = useGlobalSearch(['searchFor', 'viewAllFeature']);

  if (id !== searchFor && id !== viewAllFeature) return null;

  return <div>{children}</div>;
};

GlobalSearchFilters.Section = GlobalSearchFiltersSection;
