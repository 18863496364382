import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Chip, Truncated } from '@frontend/design-system';
import { NodeType, SharedNodeProps } from '../data';
import { draggableStyles } from './styles';

export const HoursTypeNode = memo(({ data, type }: SharedNodeProps) => {
  if (type !== NodeType.OpenPhoneHours && type !== NodeType.ClosedPhoneHours && type !== NodeType.BreakPhoneHours) {
    throw new Error('Invalid node type for HoursTypeNode');
  }

  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };
  return (
    <div>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />

      {/* Open Hours */}
      {type === NodeType.OpenPhoneHours && (
        <Chip css={draggableStyles} variant='success'>
          Open
        </Chip>
      )}

      {/* Closed Hours */}
      {type === NodeType.ClosedPhoneHours && (
        <Chip css={draggableStyles} variant='critical'>
          Closed
        </Chip>
      )}

      {/* Break Hours */}
      {type === NodeType.BreakPhoneHours && (
        <Truncated as={Chip} maxWidth={'140px'} variant='indigo'>
          {data.label}
        </Truncated>
      )}

      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
});
HoursTypeNode.displayName = 'HoursTypeNode';
