import { ReactNode } from 'react';
import { useTranslation } from '@frontend/i18n';
import { CallPopWidget, useCallPopStateSync, CallPopInterface } from '@frontend/pop';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { CallPopTypes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Heading, XIcon, createKeydownHandler, Text, Chip, CaretRightIcon, IconButton } from '@frontend/design-system';

interface UseGroupedContactsResult {
  householdGroups: {
    name: string;
    householdId: string;
    contacts: CallPopTypes.ContactContext[];
  }[];
  individualContacts: CallPopTypes.ContactContext[];
}

function useGroupedContacts(contacts: CallPopTypes.ContactContext[] | undefined): UseGroupedContactsResult {
  const { t } = useTranslation('pop');

  // Group contacts by householdId
  const groupedContacts =
    contacts?.reduce((acc, contact) => {
      const { householdId } = contact;
      if (!acc[householdId]) {
        acc[householdId] = [];
      }
      acc[householdId].push(contact);
      return acc;
    }, {} as Record<string, CallPopTypes.ContactContext[]>) ?? {};

  // Separate households with only one member
  const individualContacts: CallPopTypes.ContactContext[] = [];
  const householdGroups: {
    name: string;
    householdId: string;
    contacts: CallPopTypes.ContactContext[];
  }[] = [];

  Object.keys(groupedContacts).forEach((householdId) => {
    const contactGroup = groupedContacts[householdId];
    if (contactGroup.length === 1) {
      individualContacts.push(contactGroup[0]);
    } else {
      householdGroups.push({
        name: t('Household'),
        householdId: contactGroup[0].householdId,
        contacts: contactGroup,
      });
    }
  });

  return { householdGroups, individualContacts };
}

export const MultiContactsPanel = () => {
  const { t } = useTranslation('pop');
  const { setOutlet } = useCallPopStateSync();
  const { setShow, context } = useSlidePanelShallowStore('setShow', 'context');
  const data = context as { contacts: CallPopTypes.ContactContext[] } | undefined;

  const { householdGroups, individualContacts } = useGroupedContacts(data?.contacts);

  return (
    <div
      style={{
        background: theme.colors.neutral5,
        height: 'auto',
      }}
    >
      <CallPopSection />
      <div style={{ padding: theme.spacing(4, 3, 2, 3), background: theme.colors.white }}>
        <header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Heading level={2} css={{ margin: 0 }}>
            {t('{{count}} Contact Matches', { count: data?.contacts.length })}
          </Heading>
          <IconButton
            label={t('Close panel')}
            onClick={() => {
              setOutlet('queue');
              setShow(false);
            }}
          >
            <XIcon />
          </IconButton>
        </header>
        <Text>{t('The phone number is associated with the following contacts:')}</Text>
      </div>
      <div
        style={{
          padding: theme.spacing(2, 3),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        {householdGroups.map((group) => (
          <ContactGroup key={group.householdId} name={group.name}>
            {group.contacts.map((contact) => (
              <ContactRow
                key={contact.id + 'householdGroups'}
                name={contact.name}
                id={contact.id}
                age={contact.age}
                gender={contact.gender}
                source={contact.source}
                isHeadOfHousehold={contact.householdId !== undefined && contact.householdId === contact.patientId}
                locationId={contact.matchedLocationId}
              />
            ))}
          </ContactGroup>
        ))}

        {individualContacts.length > 0 && (
          <ContactGroup name={t('Individual')}>
            {individualContacts.map((contact) => (
              <ContactRow
                key={contact.id + 'individualContacts'}
                name={contact.name}
                id={contact.id}
                age={contact.age}
                gender={contact.gender}
                source={contact.source}
                isHeadOfHousehold={false}
                locationId={contact.matchedLocationId}
              />
            ))}
          </ContactGroup>
        )}
      </div>
    </div>
  );
};

MultiContactsPanel.cleanup = () => {
  CallPopInterface.dismissTray();
};

const ContactGroup = ({ name, children }: { name: string; children: ReactNode }) => {
  return (
    <div data-contact-group>
      <Text as='span' color='light'>
        {name}
      </Text>
      {children}
    </div>
  );
};

const ContactRow = ({
  name,
  id,
  age,
  gender,
  source,
  isHeadOfHousehold = false,
  locationId,
}: {
  name: string;
  id: string;
  age: number;
  gender: string;
  source: string;
  isHeadOfHousehold: boolean;
  locationId: string;
}) => {
  const { setShow, context } = useSlidePanelShallowStore('setShow', 'context');
  const { setPersonId } = useContactPanelShallowStore('setPersonId');
  const { t } = useTranslation('pop');
  const data = context as { contacts: CallPopTypes.ContactContext[] } | undefined;

  return (
    <button
      css={{
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        background: theme.colors.white,
        border: 'none',
        ':not(:last-child)': {
          borderBottom: `1px solid ${theme.colors.neutral10}}`,
        },
      }}
      data-contact-child
      onKeyDown={createKeydownHandler({
        parentSelector: '[data-contact-group]',
        siblingSelector: '[data-contact-child]',
        shouldActivateOnFocus: false,
        orientation: 'vertical',
      })}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Text as='span' weight='bold'>
          {name}
        </Text>
        <Text as='span' size='small' color='light' css={{ marginBottom: theme.spacing(0.5) }}>
          {`${gender} • ${age}`}
          {isHeadOfHousehold && (
            <>
              {` • `}
              <Chip.Household variant='warningNeutral' maxWidth='160px'>
                {t('Head of Household')}
              </Chip.Household>
            </>
          )}
        </Text>
        <Chip.DataSource variant='neutral'>{source}</Chip.DataSource>
      </div>
      <IconButton
        label={t('View contact')}
        onClick={() => {
          setPersonId(id, false, locationId);
          setShow(true, 'contact', {
            backlink: () => setShow(true, 'multipleContacts', { contacts: data?.contacts }),
          });
        }}
      >
        <CaretRightIcon />
      </IconButton>
    </button>
  );
};

const CallPopSection = () => {
  const { outlet, notifications } = useCallPopStateSync();

  if (outlet !== 'profile' || notifications.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CallPopWidget notifications={notifications} />
    </div>
  );
};
