import { useState } from 'react';
import { ButtonSection, Link } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMediaQuery } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, Text, TextField, useFormField, useModalControl } from '@frontend/design-system';
import { useComposerSection } from '../../../hooks';
import { useGetFormList, useGetPacketList } from '../../../queries';
import { ButtonLinkType } from '../../../types';
import {
  DocumentSelectorModal,
  ExpansionButton,
  FloatingButton,
  FloatingToolbarCenter,
  PopoverButtonGroup,
  ToolbarButtonGroup,
} from '.';

type Props = {
  link?: Link;
};

type Option = { linkType: ButtonLinkType; label: string; url?: string };

const CALL_TO_ACTION_LINK = '{{CALL_TO_ACTION_LINK}}';
const PRACTICE_PHONE = '{{BUSINESS_GROUP_PHONE}}';
const PRACTICE_ADDRESS = '{{BUSINESS_GROUP_ADDRESS}}';
const SCHEDULE_LINK = '{{SCHEDULE_LINK}}';

export const ButtonsToolbar = ({ link }: Props) => {
  const { t } = useTranslation('content-composer');
  const [showOptions, setShowOptions] = useState(!!link?.url || !!link?.linkType);
  const [showCustom, setShowCustom] = useState(false);
  const { setProps } = useComposerSection<ButtonSection>();
  const updateLink = (newLink: Link) => {
    setProps((props) => (props.link = newLink));
  };

  const EXTRA_LARGE_BREAKPOINT = 1500;
  const LARGE_BREAKPOINT = 1320;

  const options: Option[] = [
    { linkType: 'phone', label: t('Practice Phone'), url: PRACTICE_PHONE },
    { linkType: 'address', label: t('Practice Address'), url: PRACTICE_ADDRESS },
    { linkType: 'schedule', label: t('Schedule'), url: SCHEDULE_LINK },
    { linkType: 'form', label: t('Form') },
    { linkType: 'confirmation', label: t('Confirmation'), url: CALL_TO_ACTION_LINK },
    { linkType: 'custom', label: t('Custom') },
  ];

  const getIcon = () => {
    switch (link?.linkType) {
      case 'form':
        return 'forms';
      default:
        return 'link';
    }
  };

  const getSelectedLabel = () => {
    switch (link?.linkType) {
      case 'form':
        return documents.find((document) => document.id === link.url)?.name;
      case 'schedule':
        return t('Schedule');
      case 'confirmation':
        return t('Confirmation');
      case 'phone':
        return t('Practice Phone');
      case 'address':
        return t('Practice Address');
      case 'custom':
      default:
        return link?.url;
    }
  };

  // FORM
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];
  const { data: forms } = useGetFormList({ companyId: locationId });
  const { data: packets } = useGetPacketList({ companyId: locationId });
  const documents = (forms || []).concat(packets || []);
  const { modalProps, triggerProps } = useModalControl();
  const openDocumentSelectorModal = () => {
    triggerProps.onClick();
  };
  const updateDocumentId = (documentId: string) => {
    updateLink({ ...link, url: documentId, linkType: 'form' });
    modalProps.onClose();
  };

  // CUSTOM
  const fieldProps = useFormField({ type: 'text', value: link?.url }, [link?.url]);

  const initialState = (
    <FloatingButton
      onClick={() => setShowOptions(true)}
      css={{ ...toolbarStyling, fontWeight: theme.font.weight.bold }}
    >
      <Icon name='link' css={iconStyling} />
      <Text as='span'>{t('Link Button')}</Text>
    </FloatingButton>
  );

  const hasLinkState = (
    <FloatingButton css={toolbarStyling}>
      <Icon name={getIcon()} css={iconStyling} />
      <Text
        as='span'
        color='primary'
        css={{
          textDecoration: 'underline',
          textUnderlineOffset: theme.spacing(0.5),
        }}
        onClick={() => {
          if (link?.linkType === 'form') {
            openDocumentSelectorModal();
          } else if (link?.linkType === 'custom') {
            setShowCustom(true);
          }
        }}
      >
        {getSelectedLabel()}
      </Text>
      <Icon
        name='x-small'
        onClick={() => {
          updateLink({ ...link, url: '', linkType: '' });
          setShowCustom(false);
        }}
      />
    </FloatingButton>
  );

  const handleCustomLinkInput = () => {
    if (fieldProps.value) {
      updateLink({ ...link, linkType: 'custom', url: fieldProps.value });
      setShowCustom(false);
    }
  };

  const customLinkTypeState = (
    <FloatingButton css={toolbarStyling}>
      <Icon name='back' css={iconStyling} onClick={() => setShowCustom(false)} />
      <TextField
        {...fieldProps}
        name=''
        label=''
        autoFocus
        placeholder={t('Link URL')}
        css={{
          width: 450,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleCustomLinkInput();
        }}
      />
      <Icon name='check' color={fieldProps.value ? 'primary' : 'disabled'} onClick={handleCustomLinkInput} />
    </FloatingButton>
  );

  const arr1 = options.slice(0, 1);
  const arr2 = options.filter((option) => !arr1.find((o) => o.linkType === option.linkType)).slice(0, 2);
  const arr3 = options.filter(
    (option) => !arr1.find((o) => o.linkType === option.linkType) && !arr2.find((o) => o.linkType === option.linkType)
  );
  const isLarge = useMediaQuery(`(min-width: ${LARGE_BREAKPOINT}px) and (max-width: ${EXTRA_LARGE_BREAKPOINT}px)`);

  const createLinkButton = (option: Option) => (
    <Button
      variant='secondary'
      key={option.linkType}
      css={{
        padding: theme.spacing(1),
        justifyContent: 'flex-start',
        width: 'auto',
        fontWeight: theme.font.weight.regular,
      }}
      onClick={() => {
        const newLink: Link = { ...link, linkType: '', url: '' };
        if (option.linkType === 'form') {
          openDocumentSelectorModal();
        } else if (
          option.linkType === 'address' ||
          option.linkType === 'schedule' ||
          option.linkType === 'phone' ||
          option.linkType === 'confirmation'
        ) {
          newLink.url = option.url ?? '';
        } else if (option.linkType === 'custom') {
          setShowCustom(true);
        }
        newLink.linkType = option.linkType;
        updateLink(newLink);
      }}
    >
      <Icon name='link' css={iconStyling} />
      <Text as='span'>{option.label}</Text>
    </Button>
  );

  const linkOptionsState = (
    <div css={toolbarStyling}>
      <Icon name='x' onClick={() => setShowOptions(false)} css={iconStyling} />
      {arr1.map(createLinkButton)}
      <ToolbarButtonGroup breakpoint={LARGE_BREAKPOINT}>{arr2.map(createLinkButton)}</ToolbarButtonGroup>
      <ToolbarButtonGroup breakpoint={EXTRA_LARGE_BREAKPOINT}>{arr3.map(createLinkButton)}</ToolbarButtonGroup>
      {((arr2.length > 0 && !isLarge) || arr3.length > 0) && (
        <ExpansionButton breakpoint={EXTRA_LARGE_BREAKPOINT} hideBorder>
          {arr2.length > 0 ? (
            <PopoverButtonGroup breakpoint={LARGE_BREAKPOINT}>{arr2.map(createLinkButton)}</PopoverButtonGroup>
          ) : null}
          {arr3.length > 0 ? arr3.map(createLinkButton) : null}
        </ExpansionButton>
      )}
    </div>
  );

  const getContent = () => {
    if (!showOptions) return initialState;
    else if (showCustom) return customLinkTypeState;
    else if (link?.url && !showCustom) return hasLinkState;
    else return linkOptionsState;
  };

  return (
    <>
      <FloatingToolbarCenter orientation='bottom' css={{ left: '50%' }} honorX>
        {getContent()}
      </FloatingToolbarCenter>
      <DocumentSelectorModal
        modalProps={modalProps}
        documents={documents}
        documentId={link?.url}
        updateDocumentId={updateDocumentId}
      />
    </>
  );
};

const toolbarStyling = {
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  padding: theme.spacing(1),
  background: theme.colors.white,
  boxShadow: theme.shadows.light,
  border: `solid 1px transparent`,
  borderRadius: theme.borderRadius.small,
  transition: 'all 250ms ease',

  '&:hover, &:focus': {
    outline: 'none',
    boxShadow: theme.shadows.floating,
    background: theme.colors.neutral5,
  },
};

const iconStyling = {
  height: theme.spacing(2),
  width: theme.spacing(2),
  minHeight: theme.spacing(2),
  minWidth: theme.spacing(2),
};
