import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../../feature-flags';
import { useFetchRoiData } from '../../hooks';
import { detailedConversionTimeline } from '../../missed-call-text/demo-data';
import { MCTRoiConversionType } from '../../missed-call-text/types';
import { MessagingRoiFetchParams } from '../../types';
import { useHasMCTRoiFeature } from './use-has-mct-roi-feature';

type ConversionData = {
  name: string;
  count?: number;
};

type ConversionDetail = {
  conversion_type: MCTRoiConversionType;
  data?: ConversionData[];
};

export type TimelineResponse = {
  conversion_details: ConversionDetail[];
  location_id?: string;
};

type UseFetchMissedCallTextAttributionTimelineProps = {
  timeline?: Partial<Record<MCTRoiConversionType, ConversionData[]>>;
  isLoading?: boolean;
};

type ApiParams = MessagingRoiFetchParams & {
  viewType: 'day' | 'week' | 'month' | '7_day';
};

type Props = {
  apiParams: ApiParams;
  isEnabled?: boolean;
};

const api = async ({ messagingCategory, payload, viewType }: ApiParams) => {
  const response = await http.post<{ data: TimelineResponse }>(
    `${BASE_URL_ANALYTICS}/roi/v2/${messagingCategory}/timeline/forward/${viewType}`,
    payload
  );
  return response.data;
};

export const useFetchMissedCallTextAttributionTimeline = ({
  isEnabled,
  ...rest
}: Props): UseFetchMissedCallTextAttributionTimelineProps => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);

  const { data: timelineData, isLoading } = useFetchRoiData<ApiParams, TimelineResponse>({
    ...rest,
    api,
    errorMessage: t("Couldn't fetch the missed call text attribution timeline. Please try again."),
    isEnabled: isEnabled && isMCTRoiEligibleLocation && !isDemoAccount,
    uniqueId: 'mct-attribution-timeline',
  });

  const timeline: UseFetchMissedCallTextAttributionTimelineProps['timeline'] = useMemo(() => {
    const timelineMap: Partial<Record<MCTRoiConversionType, ConversionData[]>> = {};
    const data = isDemoAccount ? detailedConversionTimeline : timelineData;

    data?.conversion_details.forEach((item) => {
      timelineMap[item.conversion_type] = item.data;
    });

    return timelineMap;
  }, [isDemoAccount, timelineData]);

  return {
    isLoading,
    timeline,
  };
};
