import { ComponentProps } from 'react';
import { useActionsContext } from '@frontend/adapto-actions';
import { Actions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { useThreadActionsContext } from '../thread-actions';

type RequestPaymentActionProps = Pick<ComponentProps<typeof Actions.Call>, 'trackingIdSuffix'> & {
  associatedPersonIds?: string[];
};

export const RequestPaymentAction = ({ trackingIdSuffix, associatedPersonIds }: RequestPaymentActionProps) => {
  const { t } = useTranslation('thread-actions');

  const { personId, groupId } = useThreadActionsContext();
  const actionsContext = useActionsContext();

  if (!personId) return null;

  if (actionsContext?.isInsidePopover) {
    return (
      <Actions.Payments
        label={t('Payment Request')}
        context={{
          personId,
          locationId: groupId,
        }}
        trackingIdSuffix={trackingIdSuffix}
      />
    );
  }

  return (
    <Actions.ContactPayments
      context={{
        personId,
        locationId: groupId,
        associatedPersonIds,
      }}
      trackingIdSuffix={trackingIdSuffix}
    />
  );
};
