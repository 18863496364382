import { ReviewedStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';

export const useApproveScheduleRequestQuery = () => {
  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  const updateScheduleRequestMutation = ScheduleQueries.useUpdateScheduleRequest();

  const updateScheduleRequestMutationV3 = SchedulerV3.Mutations.useUpdateBookingSubmissionMutation();

  const approveScheduleRequest = (request: ScheduleTypes.UpdateScheduleRequestType['input']) => {
    if (isScheduleV3FlagEnabled) {
      return updateScheduleRequestMutationV3.mutateAsync({
        submissionId: request.id ?? '',
        reviewedBy: request.reviewedBy ?? '',
        reviewedStatus: ReviewedStatus.ACCEPTED,
      });
    }
    return updateScheduleRequestMutation.mutateAsync(request);
  };

  return {
    approveScheduleRequest,
    isLoading: updateScheduleRequestMutation.isLoading || updateScheduleRequestMutationV3.isLoading,
    isError: updateScheduleRequestMutation.isError || updateScheduleRequestMutationV3.isError,
  };
};
