import { Placement } from '@floating-ui/react-dom-interactions';
import { Text, TextProps, useTooltip } from '@frontend/design-system';

interface Props extends TextProps {
  tooltipText: string;
  tooltipPlacement?: Placement;
}

export const TextWithTooltip = ({ tooltipText, tooltipPlacement = 'bottom', ...rest }: Props) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: tooltipPlacement });

  return (
    <>
      <Text {...triggerProps} {...rest} css={{ maxWidth: 'max-content' }} />
      <Tooltip {...tooltipProps}>
        <Text size='medium' color='white'>
          {tooltipText}
        </Text>
      </Tooltip>
    </>
  );
};
