import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { URLs } from '../../../constants';
import { featureFlags } from '../../../feature-flags';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { API_URLs } from '../constants';
import { useFetchMessagingROIOOverview, useFetchMissedCallTextConversionSummary } from '../hooks';
import { useHasMCTRoiFeature } from '../missed-call-text/hooks';
import { WidgetCardProps } from './types';
import { WidgetCard } from './widget-card';

export const MCTWidgetCard = ({ showTrendLine, timePeriod }: WidgetCardProps) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();

  const { aggregated, isLoading } = useFetchMessagingROIOOverview({
    apiParams: {
      messagingCategory: 'missedcalltext',
      payload: {
        start_date: timePeriod.value.startDate,
        end_date: timePeriod.value.endDate,
        location_id: selectedLocationIds,
      },
    },
    isDemoAccount,
    isEnabled: isMCTRoiEligibleLocation,
  });

  const { data, isLoading: isLoadingTrendLine } = useFetchMissedCallTextConversionSummary({
    apiParams: {
      payload: {
        start_date: timePeriod.value.startDate,
        end_date: timePeriod.value.endDate,
        location_id: selectedLocationIds,
      },
      url: API_URLs.missedCallText.appointmentScheduledSummary,
    },
    totalCountKey: 'sms_count',
  });

  const handleNavigate = useCallback(() => {
    navigate({
      to: URLs.ROI_MISSED_CALL_TEXT,
    });
  }, []);

  return (
    <WidgetCard
      iconName='message-small'
      isLoading={isLoading || isLoadingTrendLine}
      onClick={handleNavigate}
      subTitle={t('Appts Scheduled')}
      title={t('Missed Call Text')}
      trackingId={trackingIds.roi.missedCallTextRoiWidget}
      trend={showTrendLine && (data?.map(({ count = 0 }) => count) ?? [])}
      value={formatters.value.format(aggregated?.patients_scheduled?.appointments_count)}
    />
  );
};
