import { useMemo } from 'react';
import { Location } from '@frontend/api-location';
import { useMultiQueryUtils } from './use-multi-query-utils';

type LocationOpsType = NonNullable<Location['opsType']>;
export type LocationIdsByType = Partial<{ [K in LocationOpsType]: string[] }>;

export const useLocationIdsByType = (locationIds: string[]) => {
  const { getLocationData } = useMultiQueryUtils();
  const { locationIdsByType, groupedLocationIds } = useMemo(() => {
    const locationIdsByType: LocationIdsByType = {};
    locationIds.forEach((locationId) => {
      const locationData = getLocationData(locationId);
      if (locationData) {
        const { opsType } = locationData;
        if (opsType) {
          if (!locationIdsByType[opsType]) {
            locationIdsByType[opsType] = [];
          }
          locationIdsByType[opsType]?.push(locationId);
        }
      }
    });
    return { locationIdsByType, groupedLocationIds: Object.values(locationIdsByType) };
  }, [locationIds]);

  return { locationIdsByType, groupedLocationIds };
};
