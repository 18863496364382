import { useMemo } from 'react';
import { css } from '@emotion/react';
import { kebabCase } from 'lodash-es';
import { AnimatePresence, motion } from 'motion/react';
import { onlyText } from 'react-children-utilities';
import { useQuery } from 'react-query';
import { Dashboard, DashboardTrackingIds } from '@frontend/dashboard';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaQuickActionAPIService } from '@frontend/schema';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useModalControl } from '@frontend/design-system';
import { QuickActions, useQuickActions } from './actions';
import { QuickActionChip } from './atoms';
import { regulatedEnabledData } from './helpers';
import { QuickActionsTray } from './quick-actions-tray';
import type { QuickActionEnabledMap } from './types';

const quickActionsTrackingIds = DashboardTrackingIds.featureModule('quick-actions');
const emptyUUID = '00000000-0000-0000-0000-000000000000';

export const QuickActionsModule = () => {
  const { t } = useTranslation('quick-actions');
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const { getCustomizationFlagValueById } = useScopedAppFlagStore();

  const { data } = useQuery({
    queryKey: 'quickActions',
    queryFn: () => SchemaQuickActionAPIService.GetQuickActionsOfUser({}),
  });

  const quickActionEnabledMap = useMemo(() => {
    return (
      data?.all?.reduce((acc, val) => {
        const key = val.label;
        const isEmptyCFId = val.cFlagId === emptyUUID;
        if (!key) return acc;
        acc[key] = { hasCustomizationFlag: getCustomizationFlagValueById(val.cFlagId ?? '') || isEmptyCFId };
        return acc;
      }, {} as QuickActionEnabledMap) ?? {}
    );
  }, [getCustomizationFlagValueById, data]);

  const prefilteredActions = useQuickActions();
  const actions = useMemo(() => {
    const filteredActions: QuickActions = {};
    for (const action in prefilteredActions) {
      if (quickActionEnabledMap?.[action]?.hasCustomizationFlag) {
        filteredActions[action] = prefilteredActions[action];
      }
    }
    return filteredActions;
  }, [prefilteredActions, quickActionEnabledMap]);

  const enabledData = useMemo(() => regulatedEnabledData(data), [data]);

  return (
    <>
      <Dashboard.Module.Header
        title='My Launchpad'
        action={{
          label: t('Add Quick Action'),
          onClick: () => triggerProps.onClick(),
          ref: triggerProps.ref,
        }}
      />
      <Dashboard.Module.Content
        as={motion.div}
        layoutRoot
        css={css`
          display: flex;
          width: 100%;
          padding-top: ${theme.spacing(2)};
          gap: ${theme.spacing(2)};
          flex-wrap: wrap;
        `}
      >
        <AnimatePresence initial={false}>
          {!enabledData?.length && (
            <QuickActionChip
              trackingId={quickActionsTrackingIds('add-quick-action')}
              onClick={() => triggerProps.onClick()}
              variant='empty'
              leftElement={<Icon name='plus-small' color='primary' />}
            >
              {t('Add Quick Action')}
            </QuickActionChip>
          )}

          {enabledData?.map((action) => {
            const quickAction = actions?.[action];
            if (!quickAction) return null;

            return (
              <QuickActionChip
                trackingId={quickActionsTrackingIds(kebabCase(onlyText(action)))}
                key={action}
                leftElement={quickAction.icon}
                onClick={quickAction.onClick}
              >
                {quickAction.label}
              </QuickActionChip>
            );
          })}
        </AnimatePresence>
      </Dashboard.Module.Content>
      <QuickActionsTray modalProps={modalProps} data={data} closeModal={closeModal} actions={actions} />
    </>
  );
};

/**
 * @dashboard-widget
 *
 * id: quick-actions
 * title: Quick Actions
 * description: Program all your most-used actions into buttons for easy access.
 * icon: action-bar
 */
export const QuickActionsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('quick-actions');
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const { widgetTrackingId } = useDashboardWidget();
  const { getCustomizationFlagValueById } = useScopedAppFlagStore();
  const { data } = useQuery({
    queryKey: 'quickActions',
    queryFn: () => SchemaQuickActionAPIService.GetQuickActionsOfUser({}),
  });

  const enabledData = useMemo(() => regulatedEnabledData(data), [data]);

  const quickActionEnabledMap = useMemo(() => {
    return (
      data?.all?.reduce((acc, val) => {
        const key = val.label;
        const isEmptyCFId = val.cFlagId === emptyUUID;
        if (!key) return acc;
        acc[key] = { hasCustomizationFlag: getCustomizationFlagValueById(val.cFlagId ?? '') || isEmptyCFId };
        return acc;
      }, {} as QuickActionEnabledMap) ?? {}
    );
  }, [getCustomizationFlagValueById, data]);

  const prefilteredActions = useQuickActions();
  const actions = useMemo(() => {
    const filteredActions: QuickActions = {};
    for (const action in prefilteredActions) {
      if (quickActionEnabledMap?.[action]?.hasCustomizationFlag) {
        filteredActions[action] = prefilteredActions[action];
      }
    }
    return filteredActions;
  }, [prefilteredActions, quickActionEnabledMap]);

  return (
    <DashboardWidget>
      <DashboardWidget.Header
        action={{
          label: t('Add Quick Action'),
          onClick: () => triggerProps.onClick(),
          ref: triggerProps.ref,
          trackingId: widgetTrackingId('btn-add-quick-action'),
        }}
      ></DashboardWidget.Header>
      <DashboardWidget.Content
        as={motion.div}
        layoutRoot
        css={[
          css`
            display: grid;
            width: 100%;
            padding-top: ${theme.spacing(2)};
            gap: ${theme.spacing(1)};
            grid-template-columns: repeat(2, 1fr);
          `,
          (enabledData?.length ?? 0) > 10 &&
            css`
              overflow-y: auto;
            `,
        ]}
      >
        <AnimatePresence initial={false}>
          {!enabledData?.length && (
            <QuickActionChip
              trackingId={quickActionsTrackingIds('add-quick-action')}
              onClick={() => triggerProps.onClick()}
              variant='empty'
              leftElement={<Icon name='plus-small' color='primary' />}
            >
              {t('Add Quick Action')}
            </QuickActionChip>
          )}

          {enabledData?.map((action) => {
            const quickAction = actions?.[action];
            if (!quickAction) return null;

            return (
              <QuickActionChip
                trackingId={widgetTrackingId(`btn-chip-${kebabCase(onlyText(action))}`)}
                key={action}
                leftElement={quickAction.icon}
                onClick={quickAction.onClick}
              >
                {quickAction.label}
              </QuickActionChip>
            );
          })}
        </AnimatePresence>
      </DashboardWidget.Content>
      <QuickActionsTray actions={actions} modalProps={modalProps} data={data} closeModal={closeModal} />
    </DashboardWidget>
  );
};

QuickActionsWidget.config = {
  size: 'medium-narrow',
  feature: 'general',
};
