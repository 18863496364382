import { useEffect, useState } from 'react';
import { PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  DropdownField,
  FieldWrapper,
  FloatingLabel,
  MoneyField,
  Text,
  TextareaField,
  TextLink,
  UserIcon,
} from '@frontend/design-system';
import { AttachmentProps, CreateFormProps } from '../create-invoice-form';
import { UserSelector } from '../user-selector';
import { ClearBtnAriaLabelSuffix, Row } from './form-row';
import { LocationSelector } from './location-selector';

type CreatePaymentRequestProps = CreateFormProps & AttachmentProps & { onPaymentSettingsClick: () => void };
export const CreatePaymentRequest = ({
  chargesEnabledLocations,
  disableAttachment,
  form,
  handleOnClick,
  hasUploadedFiles,
  inputProps,
  getLocationName,
  providers,
  resetFiles,
  setSelectedPerson,
  selectedPerson,
  uploadError,
  onPaymentSettingsClick,
}: CreatePaymentRequestProps) => {
  const { t } = useTranslation('payments');
  const [showMemoField, setShowMemoField] = useState(false);
  const [showProviderField, setShowProviderField] = useState(false);
  const { formProps, getFieldProps, values, seedValues } = form;
  const allowUploads = !hasUploadedFiles && !disableAttachment;
  const memoLabel = t('Internal Notes');
  const { onBlur: memoOnBlur, ...memoFields } = getFieldProps('memo');

  const fullName = (patient: PersonTypes.Person) => `${patient?.FirstName ?? ''} ${patient?.LastName ?? ''}`;

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    if (values.provider === 'none') {
      timerId = setTimeout(() => {
        seedValues({ provider: '' });
        setShowProviderField(false);
      }, 500);
    }
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [form.values.provider]);

  if (!values.locationId) {
    return (
      <LocationSelector
        chargesEnabledLocations={chargesEnabledLocations}
        getLocationName={getLocationName}
        setLocation={(locationId) => {
          seedValues({ locationId });
        }}
      />
    );
  }

  return (
    <form
      {...formProps}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: '100%',
      }}
    >
      <div css={{ marginTop: theme.spacing(2) }}>
        <UserSelector
          label={t('Request from')}
          placeHolder={t('Select Customer')}
          setSelected={setSelectedPerson}
          locationId={values.locationId}
          value={selectedPerson ? fullName(selectedPerson) : ''}
        />
      </div>
      <Row
        label={t('Select Provider')}
        optional
        onClick={() => {
          setShowProviderField(true);
        }}
        active={!!(values.provider || showProviderField)}
      >
        <DropdownField
          {...getFieldProps('provider')}
          label={t('Select Provider')}
          startAdornment={<UserIcon size={16} color='light' />}
          autoFocus
          onBlur={() => {
            if (!values.provider) {
              setShowProviderField(false);
            }
          }}
          css={{ gap: theme.spacing(1) }}
        >
          <DropdownField.Option value={'none'} key={'no-provider'}>
            {t('None')}
          </DropdownField.Option>
          {providers?.map((provider) => {
            const providerName = [provider.firstName, provider.lastName].filter((n) => !!n).join(' ');
            return (
              <DropdownField.Option value={providerName} key={provider.id}>
                {providerName}
              </DropdownField.Option>
            );
          })}
        </DropdownField>
      </Row>
      <Row
        label={memoLabel}
        optional
        onClick={() => {
          setShowMemoField(true);
        }}
        active={!!(values.memo || showMemoField)}
        onClear={() => {
          seedValues({ memo: '' });
          setShowMemoField(false);
          setShowProviderField(false);
        }}
      >
        <TextareaField
          {...memoFields}
          label={t('Internal Notes (Optional)')}
          autoGrow={[1, 5]}
          onBlur={(e) => {
            memoOnBlur(e);
            if (e?.relatedTarget?.ariaLabel === `${memoLabel}-${ClearBtnAriaLabelSuffix}`) return;
            if (!values.memo) {
              setShowMemoField(false);
            }
          }}
          autoFocus
        />
      </Row>
      <input type='file' id='file-drop' hidden {...inputProps} />
      <Row
        label={t('Attachment')}
        optional
        onClick={handleOnClick}
        active={!disableAttachment && !allowUploads}
        onClear={resetFiles}
        disable={disableAttachment}
      >
        <>
          <FieldWrapper
            css={{
              padding: theme.spacing(1),
              gap: theme.spacing(2),
            }}
          >
            <FloatingLabel id={'payment-attachment-link'} active>
              {t('Attachment (Optional)')}
            </FloatingLabel>
            <Icon name='attachment-small' />
            <TextLink>{values.attachmentName}</TextLink>
            <Icon name='update-small' color='primary' />
          </FieldWrapper>
          <Text color='error' size='medium'>
            {uploadError}
          </Text>
        </>
      </Row>
      {disableAttachment && (
        <BannerNotification status='warn'>
          {t(
            'Customer birthday is missing, which is currently required for authentication of attachments. Invoice customer authentication requirements can be managed here:'
          )}{' '}
          <TextLink onClick={onPaymentSettingsClick}>{t('Payment Settings')}</TextLink>
        </BannerNotification>
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: theme.spacing(2),
        }}
      >
        <MoneyField
          {...getFieldProps('amount')}
          label={t('Request Amount')}
          css={{
            width: '160px',
            height: '56px',
            marginTop: theme.spacing(2),
            '& input': {
              fontWeight: 'bold',
              fontSize: theme.fontSize(24),
            },
            '& label': {
              top:
                values.amount === '' && !document.activeElement?.matches('input[name="amount"]')
                  ? theme.spacing(1)
                  : '0px',
            },
          }}
        />
      </div>
    </form>
  );
};
