import { VoicemailUpdateEvent } from '@weave/schema-gen-ts/dist/shared/phone/v1/voicemail/message.pb';
import { createStoreWithSubscribe } from '@frontend/store';

type VoicemailUpdateStore = {
  voicemailEvent?: VoicemailUpdateEvent;
  setVoicemailEvent: (voicemailEvent: VoicemailUpdateEvent) => void;
};

export const useVoicemailUpdateStore = createStoreWithSubscribe<VoicemailUpdateStore>(
  (set) => ({
    voicemailEvent: undefined,
    setVoicemailEvent: (voicemailEvent) => {
      set(
        (state) => {
          state.voicemailEvent = voicemailEvent;
        },
        false,
        'SET_VOICEMAIL_EVENT'
      );
    },
  }),
  { name: 'VoicemailUpdateStore', trace: true }
);
