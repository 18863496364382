import { ComponentType, Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { Page } from '@frontend/page';
import { SelectedLocationProvider, useMerchant, useMerchantsInfo } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { BreadcrumbPathProps, ContentLoader } from '@frontend/design-system';
import { AppVariantProvider, PaymentsPageVariant } from '../../providers/';
import { MerchantAlertBanner } from '../merchant-status';
import { MerchantError } from '../MerchantError';
import { NoInvoices } from '../NoInvoices';
import { PaymentError } from '../PaymentError';

interface CommonProps {
  title?: React.ReactNode;
  subtitle?: ReactNode;
  action?: ReactNode;
  maxWidth?: number;
  css?: SerializedStyles | SerializedStyles[];
  error?: Error | null;
  useFirstActiveLocation?: boolean;
  showLocationChip?: boolean;
  showLocationCount?: boolean;
  variant?: PaymentsPageVariant;
  breadcrumbs?: BreadcrumbPathProps[];
  isMultiSettingsPage?: boolean;
}
interface PaymentsPagePropsWithoutPrint extends CommonProps {
  printDialogue?: undefined;
  showPrint?: undefined;
  setShowPrint?: undefined;
}
interface PaymentsPagePropsWithPrint extends CommonProps {
  printDialogue: ComponentType<React.PropsWithChildren<{ onClose: () => void }>>;
  showPrint: boolean;
  setShowPrint: Dispatch<SetStateAction<boolean>>;
}
type PaymentsPageProps = PaymentsPagePropsWithoutPrint | PaymentsPagePropsWithPrint;

const styles = {
  pageTitleSection: css`
    & .title {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(2, 2)};
    }
  `,
};

export const PaymentsPage: FC<React.PropsWithChildren<PaymentsPageProps>> = ({
  title,
  subtitle,
  action,
  children,
  printDialogue,
  showPrint,
  setShowPrint,
  maxWidth,
  error,
  showLocationChip = false,
  useFirstActiveLocation = false,
  showLocationCount = false,
  variant = 'portal',
  breadcrumbs,
  isMultiSettingsPage = false,
  ...rest
}) => {
  const PrintDialogue = printDialogue;
  const { firstActiveLocationId, stripeCreatedNotOnboarded, paymentsUrl } = useMerchant();
  const { hasMerchantsError, allMerchantsLoaded, anyLocationHasPayments } = useMerchantsInfo();

  if (!allMerchantsLoaded) {
    return <ContentLoader show={true} />;
  }
  return error || !paymentsUrl ? (
    <PaymentError />
  ) : !hasMerchantsError && !anyLocationHasPayments && !isMultiSettingsPage ? (
    <NoInvoices stripeCreatedNotOnboarded={stripeCreatedNotOnboarded} />
  ) : (
    <SelectedLocationProvider locationId={firstActiveLocationId!} bypassContext={!useFirstActiveLocation}>
      <AppVariantProvider variant={variant}>
        <Page css={styles.pageTitleSection} maxWidth={maxWidth ?? 1420} {...rest}>
          <Page.Header>
            <Page.Header.Breadcrumbs breadcrumbs={breadcrumbs} />

            <Page.Header.Heading>
              <Page.Header.Title title={title}>
                <Page.Header.LocationChip showLocationChip={showLocationChip} showLocationCount={showLocationCount} />
              </Page.Header.Title>

              {!hasMerchantsError && anyLocationHasPayments && <Page.Header.Subtitle subtitle={subtitle} />}

              {action && <Page.Header.Action>{action}</Page.Header.Action>}
            </Page.Header.Heading>
          </Page.Header>

          <Page.Body>
            {hasMerchantsError ? (
              <MerchantError />
            ) : (
              <>
                <MerchantAlertBanner />
                {children}
                {showPrint && PrintDialogue && <PrintDialogue onClose={() => setShowPrint?.(false)} />}
              </>
            )}
          </Page.Body>
        </Page>
      </AppVariantProvider>
    </SelectedLocationProvider>
  );
};
