import { useQuery } from 'react-query';
import { DepartmentsApi } from '@frontend/api-departments';
import { i18next } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { DepartmentPayload, InstructionFieldsProps } from '../types';
import { InstructionFields } from './base';

export const DepartmentInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<DepartmentPayload>['onChange'];
  option: DepartmentPayload;
}) => {
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const { data = [], isLoading } = useQuery({
    queryKey: [queryKeys.listDepartments()],
    queryFn: () => {
      return DepartmentsApi.listDept({ locationId });
    },
    select: (data) => {
      return (
        data.departments
          ?.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
          .map((department) => ({
            value: department.id ?? '',
            label: department.name ?? '',
          })) ?? []
      );
    },
    staleTime: 0,
  });

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<DepartmentPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          departmentId: data ?? [],
        },
        value: option,
      }}
    />
  );
};

const schema = {
  type: 'department',
  fields: {
    departmentId: {
      label: i18next.t('Route', { ns: 'phone' }),
      name: 'departmentId',
      type: 'dropdown',
    },
  },
} as const;
