import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({ display: 'flex', gap: theme.spacing(1), alignItems: 'center' });

export const iconButtonStyle = css({
  border: 'unset',
  padding: theme.spacing(0, 1.5, 0, 0.75),
  span: {
    gap: theme.spacing(1),
  },
  svg: {
    padding: theme.spacing(0.5),
    borderRadius: theme.borderRadius.small,
    backgroundColor: theme.colors.neutral10,
    overflow: 'visible',
    hover: {
      backgroundColor: theme.colors.neutral10,
    },
  },
});
