import { useState } from 'react';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { List, ListListsResponse } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { UseInfiniteQueryResult } from 'react-query';
import { Virtuoso } from 'react-virtuoso';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, SearchField, SpinningLoader, Text, useFormField, useModalControl } from '@frontend/design-system';
import { DeleteListModal } from '../saved-list/delete-list-modal';
import { Mode } from '../types';
import { NewSavedListItem } from './new-saved-list-item';
import { RenameListItem } from './rename-list-item';
import { SavedListItem } from './saved-list-item';

type Props = {
  contactLists: List[];
  contactListsQueryResult: UseInfiniteQueryResult<ListListsResponse>;
  createContactList: (list: List) => Promise<void>;
  deleteContactList: (listId: string) => Promise<void>;
  deselectContactList: (listId: string) => void;
  recipientsToUpload: Recipient[];
  renameContactList: (list: List) => Promise<void>;
  selectContactList: (listId: string) => void;
  selectedContactLists: string[];
  setMode: (mode: Mode) => void;
  setRecipientsToUpload: (recipients: Recipient[]) => void;
};

export const SavedListModal = ({
  contactLists,
  contactListsQueryResult,
  createContactList,
  deleteContactList,
  deselectContactList,
  recipientsToUpload,
  renameContactList,
  selectContactList,
  selectedContactLists,
  setMode,
  setRecipientsToUpload,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const { isFetchingNextPage, isFetched, hasNextPage } = contactListsQueryResult;
  const searchListsFieldProps = useFormField({ type: 'text' });
  const displayedLists = searchListsFieldProps.value
    ? contactLists.filter((list) => list.name.toLowerCase().includes(searchListsFieldProps.value.toLowerCase()))
    : contactLists;
  const hasMore = isFetched && !!hasNextPage;

  // Deleting
  const [listIdToDelete, setListIdToDelete] = useState<string | undefined>(undefined);
  const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } = useModalControl();

  // Renaming
  const [listIdToRename, setListIdToRename] = useState<string | undefined>(undefined);

  return (
    <>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
          columnGap: theme.spacing(1),
          margin: theme.spacing(2, 0),
        }}
      >
        <SearchField
          {...searchListsFieldProps}
          name='search-lists'
          placeholder={t('Search Lists')}
          maxLength={70}
          data-trackingid={`${BulkEmailPrefixes.Audience}-contacts-list-search-input`}
        />
        <Button
          variant='tertiary'
          hoverLabel={t('Upload CSV List')}
          onClick={() => setMode('csv')}
          trackingId={`${BulkEmailPrefixes.Audience}-contacts-upload-csv-btn`}
          css={{ padding: theme.spacing(1) }}
        >
          <Icon name='upload' color='default' />
        </Button>
      </div>
      {recipientsToUpload.length > 0 && (
        <NewSavedListItem
          createContactList={createContactList}
          recipientsToUpload={recipientsToUpload}
          setRecipientsToUpload={setRecipientsToUpload}
        />
      )}
      {displayedLists.length > 0 ? (
        <Virtuoso
          style={{ height: `calc(100% - ${theme.spacing(9)})` }}
          data={displayedLists}
          totalCount={displayedLists.length}
          itemContent={(_index, list) =>
            listIdToRename && listIdToRename === list.listId ? (
              <RenameListItem
                key={list.listId}
                list={list}
                setListIdToRename={setListIdToRename}
                renameContactList={renameContactList}
              />
            ) : (
              <SavedListItem
                key={list.listId}
                list={list}
                disabled={false}
                checked={!!selectedContactLists.find((listId) => listId === list.listId)}
                onCheck={() => {
                  if (!list.listId) return;
                  selectedContactLists.includes(list.listId)
                    ? deselectContactList(list.listId)
                    : selectContactList(list.listId);
                }}
                onDelete={() => {
                  deleteTriggerProps.onClick();
                  setListIdToDelete(list.listId);
                }}
                setListIdToRename={setListIdToRename}
              />
            )
          }
          endReached={() => contactListsQueryResult.fetchNextPage({ cancelRefetch: false })}
          followOutput='smooth'
          components={{
            Footer: () => {
              if (isFetchingNextPage) {
                return <SpinningLoader />;
              } else if (!hasMore) {
                return (
                  <Text css={{ margin: 0, padding: theme.spacing(3, 0), textAlign: 'center' }}>
                    🎉 {t('You have reached the end')}
                  </Text>
                );
              }
              return null;
            },
          }}
        />
      ) : recipientsToUpload.length === 0 ? (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: `calc(100% - ${theme.spacing(9)})`,
            padding: theme.spacing(0, 4),
            rowGap: theme.spacing(2),
          }}
        >
          <Text color='subdued' weight='bold'>
            {t('No Saved Lists')}
          </Text>
          <Text color='subdued' css={{ textAlign: 'center' }}>
            {t('Target a group of contacts from an uploaded csv.')}
          </Text>
          <Button
            css={{ display: 'flex', columnGap: theme.spacing(0.5), marginTop: theme.spacing(1) }}
            iconName='plus'
            onClick={() => setMode('csv')}
            trackingId={`${BulkEmailPrefixes.Audience}-contacts-empty-screen-upload-csv-btn`}
            variant='tertiary'
          >
            <Text color='primary'>{t('Upload List')}</Text>
          </Button>
        </div>
      ) : null}
      <DeleteListModal
        deleteContactList={deleteContactList}
        listIdToDelete={listIdToDelete}
        modalProps={deleteModalProps}
        setListIdToDelete={setListIdToDelete}
      />
    </>
  );
};
