import { getFilterDisplayDates } from '@frontend/api-invoices';
import { http } from '@frontend/fetch';
import { SearchFailedPaymentsParams, SearchFailedPaymentsResponse, SearchFailedPaymentsResponseRaw } from './types';

export type GetFailedPaymentsProps = {
  paymentsUrl: string | null;
  params: SearchFailedPaymentsParams;
  locationIds: string[];
};

export const getFailedPayments = ({
  paymentsUrl,
  locationIds,
  params,
}: GetFailedPaymentsProps): Promise<SearchFailedPaymentsResponse> => {
  return http
    .get<SearchFailedPaymentsResponseRaw>(`${paymentsUrl}/invoices/payments`, {
      params: formatFailedPaymentsParams(locationIds, params),
    })
    .then(({ payments }) => {
      return {
        payments: payments.payments.edges.map((edge) => edge.node),
        meta: payments.pageInfo,
        totalCount: payments.totalCount,
        lastEdgeCursor: payments.payments.edges.length
          ? payments.payments.edges[payments.payments.edges.length - 1].cursor
          : null,
      };
    });
};

export const formatFailedPaymentsParams = (
  locationIds: string[],
  { submittedAt, search, ...params }: SearchFailedPaymentsParams
) => {
  const [greaterThanDate, lessThanDate] = getFilterDisplayDates({
    start: submittedAt?.greaterThanEqual,
    end: submittedAt?.lessThanEqual,
  });

  const formattedParams = {
    ...params,
    'search.locationIds': locationIds,
    ...toDotNotation(search, 'search'),
    ...toDotNotation(
      {
        greaterThanEqual: greaterThanDate?.toISOString(),
        lessThanEqual: lessThanDate?.toISOString(),
      },
      'submittedAt'
    ),
  };

  return formattedParams;
};

export const toDotNotation = (obj: Record<string, unknown>, prefix = '') => {
  const keys = Object.keys(obj);
  let toSet: Record<string, unknown> = {};

  keys.forEach((key) => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'object' && !Array.isArray(value)) {
      toSet = { ...toSet, ...toDotNotation(value as Record<string, unknown>, newKey) };
    } else if (value !== undefined) {
      toSet = { ...toSet, [newKey]: value };
    }
  });

  return toSet;
};
