import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import type { GetWeavePopNotificationByType, NotificationActions } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'chat-message-new'>>;
export const ChatNotificationComponent = ({ isHistorical, emit, notification, ...rest }: Props) => {
  const { t } = useTranslation();

  const actions: NotificationActions = [
    {
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];
  const payload = notification.payload;

  //Don't make fun of me for this
  const getAttachmentMessage = () => {
    if (!payload.attachments?.length) {
      return null;
    }
    if (payload.attachments?.length === 1) {
      if (payload.attachments[0].type === 'image') {
        return t('Sent you an image');
      }
      if (payload.attachments[0].type === 'file') {
        return t('Sent you a file');
      }
      if (payload.attachments[0].type === 'video') {
        return t('Sent you a video');
      }
      if (payload.attachments[0].type === 'giphy') {
        return t('Sent you a GIF');
      }
    } else if (payload.attachments.length > 1) {
      const allAreTheSame = payload.attachments.every(
        (attachment) => attachment.type === payload.attachments?.[0]?.type
      );
      if (allAreTheSame) {
        if (payload.attachments[0].type === 'image') {
          return t('Sent you {{count}} images', { count: payload.attachments.length });
        }
        if (payload.attachments[0].type === 'file') {
          return t('Sent you {{count}} files', { count: payload.attachments.length });
        }
        if (payload.attachments[0].type === 'video') {
          return t('Sent you {{count}} videos', { count: payload.attachments.length });
        }
        if (payload.attachments[0].type === 'giphy') {
          return t('Sent you {{count}} GIFs', { count: payload.attachments.length });
        }
      } else {
        const attachmentTypes = new Map(payload.attachments.map((attachment) => [attachment.type, attachment.type]));
        if (attachmentTypes.has('image') && attachmentTypes.has('giphy')) {
          return t('Sent you images and GIFs');
        }
        if (attachmentTypes.has('image') && attachmentTypes.has('video')) {
          return t('Sent you images and videos');
        }
        if (attachmentTypes.has('image') && attachmentTypes.has('file')) {
          return t('Sent you images and files');
        }
        if (attachmentTypes.has('giphy') && attachmentTypes.has('video')) {
          return t('Sent you GIFs and videos');
        }
        if (attachmentTypes.has('giphy') && attachmentTypes.has('file')) {
          return t('Sent you GIFs and files');
        }
        if (attachmentTypes.has('video') && attachmentTypes.has('file')) {
          return t('Sent you videos and files');
        }
        return t('Sent you multiple attachments');
      }
    }
    return null;
  };

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        title={t('New Team Chat')}
        body={
          <>
            {payload.isMention && (
              <Trans size='medium' weight='bold'>
                <Text css={mentionHeaderStyle}>
                  You've been <span css={mentionStyle}>@mentioned</span>
                </Text>
              </Trans>
            )}
            {payload.authorName && (
              <Text size='medium' weight='bold'>
                {payload.authorName}
              </Text>
            )}
            {!!payload.message?.length ? (
              <Text size='medium' css={twoLineTruncateStyle}>
                {payload.message}
              </Text>
            ) : payload.attachments?.length ? (
              getAttachmentMessage()
            ) : payload.reaction ? (
              parseEmoji(payload.reaction) + ' ' + t('Reacted to your message')
            ) : (
              t('Sent you a message')
            )}
          </>
        }
        notificationType={notification.type}
        location={notification.location}
        timestamp={notification.timestamp}
        id={notification.id}
      />
    </BaseNotificationOuter>
  );
};

const twoLineTruncateStyle = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
`;

const mentionStyle = css`
  background: ${theme.colors.warning5};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.primary60};
  padding: 0px 2px;
`;

const mentionHeaderStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const parseEmoji = (emoji: string): string => {
  if (typeof emoji !== 'string') return '';

  return emoji
    .split('-')
    .map((hex) => String.fromCodePoint(parseInt(hex, 16)))
    .join('');
};
