import { DataSourcesHooks } from '@frontend/api-data-sources';
import { QuickFillHooks } from '@frontend/api-quick-fill';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton, useTooltip } from '@frontend/design-system';
import { DisabledTooltipContent, disabledTooltipStyle } from './disabled-tooltip-content';
import { useQuickFillAction } from './use-quickfill-action';

type Props = {
  label?: string;
  personId: string;
  locations: DataSourcesHooks.LocationReference[];
  trackingIdSuffix?: string;
};
export const QuickFillButton = ({ label, personId, locations, trackingIdSuffix }: Props) => {
  const { triggerProps, Modal } = useQuickFillAction(personId, locations);

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Quickfill}:${trackingIdSuffix}`}
        label={label}
      >
        <Icon name='quickfill' size={16} />
      </ActionButton>
      {Modal}
    </div>
  );
};

type QuickFillActionProps = {
  label: string;
  personId: string;
  locations: DataSourcesHooks.LocationReference[];
  trackingIdSuffix?: string;
};

export const QuickFillAction = ({ label, personId, locations, trackingIdSuffix }: QuickFillActionProps) => {
  const { t } = useTranslation('schedule-quick-fill');
  const { selectedLocationIds } = useAppScopeStore();
  const { triggerProps, Modal } = useQuickFillAction(personId, locations);
  const { Tooltip, tooltipProps, triggerProps: optionTriggerProps } = useTooltip();
  const { hasQuickFillFeature, locationIdWiseQuickFillFeature } = QuickFillHooks.useQuickFillFeatureDetails();

  // Check if any of the the patient locations has Quick Fill feature access
  const hasQuickFillFeatureForPatientLocations = locations?.some(
    ({ locationId }) => locationIdWiseQuickFillFeature[locationId] === true
  );

  // Explicity enable for single location as we don't get `locations` value for single location and `hasQuickFillFeatureForPatientLocations` will be false.
  // Also, we already have a check for single location to not show the option at all if the location does not have Quick Fill feature.
  const isDisabled = selectedLocationIds.length !== 1 && !hasQuickFillFeatureForPatientLocations;

  if (!hasQuickFillFeature) {
    return null;
  }

  return (
    <>
      <div {...optionTriggerProps}>
        <AdaptoActions.Action
          {...triggerProps}
          trackingId={`${ContactActionPrefixes.Quickfill}:${trackingIdSuffix}`}
          label={label}
          icon='quickfill'
          disabled={isDisabled}
        />
        {isDisabled && (
          <Tooltip {...tooltipProps} css={disabledTooltipStyle}>
            <DisabledTooltipContent text={t('This contact does not belong to any location that uses Quick Fill.')} />
          </Tooltip>
        )}
      </div>
      {Modal}
    </>
  );
};
