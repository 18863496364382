import { useCallback, useMemo, useRef, useState } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence, motion } from 'motion/react';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useTranslation } from '@frontend/i18n';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { MiniChatContext, useMiniChatShallowStore } from '../stores';
import { MiniChat } from './mini-chat';
import { MobileMiniChatSelectionMenuButton } from './mobile-selection-menu';

export const MobileMiniChatManager = () => {
  const alert = useAlert();
  const { t } = useTranslation('mini-chat');
  const { selectedLocationIds } = useAppScopeStore();
  const {
    chats,
    openChat,
    minimizeChat,
    removeChat,
    activeChatId,
    addChat: changeThread,
    errorBoundaryActive,
  } = useMiniChatShallowStore(
    'chats',
    'rolledUp',
    'openChat',
    'minimizeChat',
    'removeChat',
    'activeChatId',
    'addChat',
    'errorBoundaryActive'
  );
  const { navigateToThread } = useInboxNavigate();
  const [chatSelectionMenuIsOpen, setChatSelectionMenuIsOpen] = useState(false);
  const currentOpenChat = useMemo<MiniChatContext | undefined>(
    () => chats.find(({ chatId }) => chatId === activeChatId),
    [activeChatId, JSON.stringify(chats)]
  );
  const { possiblePersonPhones, ...chatProps } = currentOpenChat ?? {};
  const departmentsQueries = DepartmentsQueries.useListDefaultSMSQueries(
    selectedLocationIds.map((locationId) => ({
      req: {
        locationId,
      },
      options: {
        select: (data) => ({
          locationId,
          departments:
            data.smsNumbers?.reduce<{ locationPhone: string; departmentId: string }[]>(
              (acc, curr) =>
                curr?.id && curr.smsNumber?.number
                  ? [...acc, { locationPhone: formatPhoneNumberE164(curr.smsNumber.number), departmentId: curr.id }]
                  : acc,
              []
            ) ?? [],
        }),
      },
    }))
  );
  const departmentIdsPerGroupId = departmentsQueries.reduce<Record<string, string[]>>((acc, curr) => {
    if (!curr.data.locationId) return acc;
    acc[curr.data.locationId] = curr.data.departments.map(({ departmentId }) => departmentId);
    return acc;
  }, {});

  const threadStatusQuery = SMSDataV3.Queries.useBatchGetThreadStatusQuery({
    request: {
      requests: SMSDataV3.Utils.calculatePossibleThreadStatusRequests({
        personPhones: possiblePersonPhones ?? [],
        departmentIdsPerGroupId,
        groupIds: selectedLocationIds,
      }),
    },
    options: {
      enabled: !!possiblePersonPhones?.length,
    },
  });

  const openChatSelectionMenu = useCallback(() => {
    minimizeChat();
    setChatSelectionMenuIsOpen(true);
  }, [minimizeChat, setChatSelectionMenuIsOpen]);

  const selectChat = useCallback(
    (chatId: string) => {
      openChat(chatId);
      setChatSelectionMenuIsOpen(false);
    },
    [openChat, setChatSelectionMenuIsOpen]
  );

  const closeChatSelectionMenu = useCallback(() => {
    setChatSelectionMenuIsOpen(false);
  }, [setChatSelectionMenuIsOpen]);

  const ref = useRef<HTMLSpanElement>(null);

  return (
    <FloatingPortal>
      <motion.span
        css={{
          position: 'absolute',
          right: 0,
          left: 0,
          padding: theme.spacing(0, 8, 0, 1),
          display: 'flex',
          justifyContent: 'flex-end',
          bottom: 0,
          pointerEvents: 'none',
          zIndex: 10000,
          '*': {
            pointerEvents: 'auto',
          },
          overflowX: 'clip',
        }}
        ref={ref}
      >
        <AnimatePresence>
          {!chatSelectionMenuIsOpen && !!currentOpenChat && !errorBoundaryActive && (
            <MiniChat
              {...(chatProps as Omit<MiniChatContext, 'possiblePersonPhones'>)}
              sizeVariant='full'
              isMobile
              onMaximize={() => {
                navigateToThread({
                  threadId: currentOpenChat.threadId,
                  groupId: currentOpenChat.groupId,
                  personId: currentOpenChat.personId,
                  departmentId: currentOpenChat.departmentId,
                  personPhone: currentOpenChat.personPhone,
                  smsId: currentOpenChat.targetSmsData?.id,
                  smsCreatedAt: currentOpenChat.targetSmsData?.createdAt,
                });
                removeChat(currentOpenChat.chatId);
              }}
              onClose={() => removeChat(currentOpenChat.chatId)}
              onSelectPersonPhone={(personPhone) => {
                const newThread = threadStatusQuery.data?.responses.find(
                  (response) =>
                    response.personPhone === formatPhoneNumberE164(personPhone) &&
                    response.locationId === currentOpenChat.groupId &&
                    (currentOpenChat.departmentId ? response.departmentId === currentOpenChat.departmentId : true)
                );
                if (!newThread) {
                  alert.error(t('Error switching to conversation.'));
                  sentry.error({
                    error: 'Error finding selected thread from thread status query response',
                    topic: 'messages',
                    addContext: {
                      name: 'Thread selection data',
                      context: {
                        threadStatusQuery,
                        currentOpenChat,
                        newPersonPhone: personPhone,
                      },
                    },
                  });
                  return;
                }

                changeThread({
                  personPhone,
                  groupId: currentOpenChat.groupId,
                  departmentId: currentOpenChat.departmentId,
                  personId: currentOpenChat.personId,
                  locationPhone: currentOpenChat.locationPhone,
                  unreadCount: 0,
                  threadId: newThread.threadId,
                });
              }}
              onSelectDepartment={({ departmentId, locationPhone }) => {
                const newThread = threadStatusQuery.data?.responses.find(
                  (response) =>
                    response.personPhone === formatPhoneNumberE164(currentOpenChat.personPhone) &&
                    response.departmentId === departmentId &&
                    response.locationId === currentOpenChat.groupId
                );

                if (!newThread) {
                  alert.error(t('Error switching to conversation.'));
                  sentry.error({
                    error: 'Error finding selected thread from thread status query response',
                    topic: 'messages',
                    addContext: {
                      name: 'Thread selection data',
                      context: {
                        threadStatusQuery,
                        currentOpenChat,
                        newDepartmentId: departmentId,
                        locationPhone,
                      },
                    },
                  });
                  return;
                }
                changeThread({
                  personPhone: currentOpenChat.personPhone,
                  groupId: currentOpenChat.groupId,
                  departmentId,
                  personId: currentOpenChat.personId,
                  locationPhone,
                  unreadCount: 0,
                  threadId: newThread.threadId,
                });
              }}
              onMinimize={() => minimizeChat()}
            />
          )}
        </AnimatePresence>
        <MobileMiniChatSelectionMenuButton
          chatSelectionMenuIsOpen={chatSelectionMenuIsOpen}
          onClick={() => {
            if (chatSelectionMenuIsOpen) {
              closeChatSelectionMenu();
            } else {
              openChatSelectionMenu();
            }
          }}
          selectChat={selectChat}
        />
      </motion.span>
    </FloatingPortal>
  );
};
