import { useMemo } from 'react';
import { css } from '@emotion/react';
import { DeviceInclude_Enum, DeviceType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesQueries } from '@frontend/api-devices';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Button,
  DropdownField,
  Heading,
  Modal,
  SkeletonLoaders,
  useAlert,
  useForm,
  useModalControl,
  Text,
  TextField,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { ButtonBar, AddStepPanelProps, HeaderBar } from './add-step-panel';

export const DeviceStep = ({ initialState, onClose, onProceedClick, onBackClick }: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const { modalProps, openModal, closeModal } = useModalControl();

  const locations =
    settingsTenantLocation?.children && settingsTenantLocation.children.length > 0
      ? settingsTenantLocation?.children
      : [settingsTenantLocation].filter(Boolean);

  const locationIds = locations.map((loc: { id: string }) => loc.id);

  const { data = [], isLoading } = DevicesQueries.useGetDevicesList(
    {
      include: [DeviceInclude_Enum.CALL_GROUPS, DeviceInclude_Enum.E911_ADDRESS, DeviceInclude_Enum.REGISTRATION],
      locationIds,
      tenantId: settingsTenantLocation?.phoneTenantId,
    },
    {
      select: (data) => (data?.devices ?? []).sort((a, b) => a.name.localeCompare(b.name)),
      enabled: !!settingsTenantLocation?.phoneTenantId,
      retry: false,
      staleTime: 30 * 1000,
    }
  );

  const filteredDevices = useMemo(() => {
    const filtered = data.filter(
      (device) => device.type === DeviceType_Enum.DESK_PHONE || device.type === DeviceType_Enum.SOFTPHONE
    );

    return filtered ?? [];
  }, [data, settingsTenantLocation?.locationType]);

  const { getFieldProps, isComplete, values } = useForm({
    fields: {
      deviceId: {
        required: true,
        type: 'dropdown',
        value: initialState?.callObject?.primitiveId,
      },
      ringDuration: {
        type: 'dropdown',
        required: true,
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: String(initialState?.callObject?.deviceExpansion?.timeout ?? '18000'),
      },
      callerLabel: {
        required: false,
        type: 'text',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: initialState?.callObject?.deviceExpansion?.callerLabel,
      },
    },
  });

  const handleProceedClick = () => {
    const device = filteredDevices.find((device) => device.deviceId === values.deviceId);

    if (!device) {
      alerts.error('Unknown device data');
      return;
    }

    onProceedClick({
      callObject: {
        primitiveId: device.deviceId,
        primitiveName: device.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        deviceExpansion: {
          timeout: Number(values.ringDuration),
          callerLabel: values.callerLabel,
        },
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Device')} onClose={onClose} />

      <SkeletonLoaders.Loader isLoading={isLoading} shape='rectangle' width={320} height={50}>
        <DropdownField
          label={t('Select device')}
          {...getFieldProps('deviceId')}
          placeholder={t('Select device')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        >
          {filteredDevices.map((device) => (
            <DropdownField.Option key={device.deviceId} value={device.deviceId}>
              {device.name}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </SkeletonLoaders.Loader>

      <SkeletonLoaders.Loader isLoading={isLoading} shape='rectangle' width={320} height={50}>
        <TextField
          label={t('Caller Label (Optional)')}
          {...getFieldProps('callerLabel')}
          helperText={t('Label displays on Weave phone screen while call is ringing')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        />
      </SkeletonLoaders.Loader>

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Heading level={3}>{t('Ring Duration')}</Heading>
        <Button iconName='info-small' variant='secondary' onClick={openModal} />
      </div>
      <SkeletonLoaders.Loader isLoading={isLoading} shape='rectangle' width={320} height={50}>
        <DropdownField
          label={t('Ring duration')}
          {...getFieldProps('ringDuration')}
          placeholder={t('Ring duration')}
          helperText={t('6 seconds usually equals 1 ring')}
          css={css`
            max-width: 318px;
            margin-top: ${theme.spacing(1)};
          `}
        >
          {[6_000, 12_000, 18_000, 24_000, 30_000, 36_000, 42_000, 48_000, 54_000, 60_000].map((duration) => (
            <DropdownField.Option key={duration} value={String(duration)}>
              {`${t('{{duration}} seconds', { duration: duration / 1000 })}`}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </SkeletonLoaders.Loader>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!isComplete}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />

      <Modal {...modalProps} minWidth={400}>
        <Modal.Header onClose={closeModal}>{t('Ring Duration')}</Modal.Header>
        <Modal.Body>
          <Text>
            {t(
              'The length of time a call rings before the system takes the caller to the next step in the Call Route.'
            )}
          </Text>
        </Modal.Body>
      </Modal>
    </>
  );
};
