import { forwardRef, useState } from 'react';
import { css } from '@emotion/react';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { styles, Text } from '@frontend/design-system';
import { useLineKeyState } from '../store/line-key-provider';

const DeviceLayoutTouchScreenWithoutPreviewEnabled = forwardRef<
  HTMLDivElement,
  {
    lineKeys: LineKeysTypes.GetLineKeysType['output']['lineKeys'] | undefined;
    model: LineKeysTypes.ModelInfoType | undefined;
    device: LineKeysTypes.ListDeviceType | undefined;
  }
>(({ lineKeys, model, device }, ref) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const [showHideColumns, setShowHideColumns] = useState(false);

  const { scrollToCard } = useLineKeyState(['scrollToCard']);

  const numOfKeys = Object.keys(model?.touchKeys?.keyPositions ?? {});
  const columns = model?.touchKeys?.columns ?? 0;
  const rows = model?.touchKeys?.rows ?? 0;
  const extensions = device?.sipProfiles?.map((ext) => ext.name);
  const columnsToHide: number[] = new Array(model?.touchKeys?.columns ?? 0)
    .fill(undefined)
    .map((_, i) => i + 1)
    .filter((_, i, arr) => i + 1 !== 1 && i + 1 !== arr.length);

  if (!model?.touchKeys) return null;

  return (
    <>
      <div ref={ref} className='touch-screen-container' css={[gridStyles({ columns, rows }), touchScreenStyles]}>
        {numOfKeys.map((b, i) => {
          // index starts at 1 not 0
          const position = model.touchKeys?.keyPositions[i + 1];
          const isExtension = (extensions?.length ?? 1) >= i + 1;
          const start = i - (extensions?.length ?? 1);
          const keyExists = !!lineKeys?.[start];
          const nextAvailable = (lineKeys?.length ?? 0) + (extensions?.length ?? 1) === i;
          const index = i + 1 - (extensions?.length ?? 1);
          const canBeHidden = position && columnsToHide.includes(position?.column);

          return (
            <div
              key={b}
              className={
                `touch-screen-tile` +
                ` ${isExtension ? 'static' : ''}` +
                ` ${keyExists || nextAvailable ? 'active' : 'inactive'}` +
                ` ${canBeHidden && showHideColumns ? 'hidden' : ''}`
              }
              css={gridItemStyles({ position })}
              onClick={() => {
                if (keyExists || nextAvailable) {
                  scrollToCard(index);
                }
              }}
            >
              {!isExtension && (
                <Text weight='bold' size='medium' className='touch-screen-tile-key' as='span'>
                  {index}
                </Text>
              )}
              {isExtension && (
                <Text weight='bold' className='touch-screen-tile-name' as='span'>
                  {extensions?.[i]}
                </Text>
              )}
              {(!!keyExists || !!nextAvailable) && (
                <>
                  <Text weight='bold' size='medium' className='touch-screen-tile-name' css={styles.truncate} as='span'>
                    {lineKeys?.[start]?.name}
                  </Text>
                </>
              )}
            </div>
          );
        })}

        <div
          className={`touch-screen-tile active`}
          css={gridItemStyles({ position: model?.touchKeys?.paginationKeyPosition })}
          onClick={() => {
            setShowHideColumns(!showHideColumns);
          }}
        >
          <Text
            onClick={() => setShowHideColumns(!showHideColumns)}
            weight='bold'
            size='medium'
            className='touch-screen-tile-key'
            as='span'
          >
            {showHideColumns ? t('More') : t('Hide')}
          </Text>
        </div>
      </div>
    </>
  );
});
DeviceLayoutTouchScreenWithoutPreviewEnabled.displayName = 'DeviceLayoutTouchScreenWithoutPreviewEnabled';

export const DeviceLayoutTouchScreenWithPreviewEnabled = forwardRef<
  HTMLDivElement,
  {
    lineKeys: LineKeysTypes.GetLineKeysType['output']['lineKeys'] | undefined;
    model: LineKeysTypes.ModelInfoType | undefined;
    device: LineKeysTypes.ListDeviceType | undefined;
  }
>(({ lineKeys, model, device }, ref) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const [showHideColumns, setShowHideColumns] = useState(false);

  const scrollToCard = () => {};

  const numOfKeys = Object.keys(model?.touchKeys?.keyPositions ?? {});
  const columns = model?.touchKeys?.columns ?? 0;
  const rows = model?.touchKeys?.rows ?? 0;
  const extensions = device?.sipProfiles?.map((ext) => ext.name);
  const columnsToHide: number[] = new Array(model?.touchKeys?.columns ?? 0)
    .fill(undefined)
    .map((_, i) => i + 1)
    .filter((_, i, arr) => i + 1 !== 1 && i + 1 !== arr.length);

  if (!model?.touchKeys) return null;

  return (
    <>
      <div ref={ref} className='touch-screen-container' css={[gridStyles({ columns, rows }), touchScreenStyles]}>
        {numOfKeys.map((b, i) => {
          // index starts at 1 not 0
          const position = model.touchKeys?.keyPositions[i + 1];
          const isExtension = (extensions?.length ?? 1) >= i + 1;
          const start = i - (extensions?.length ?? 1);
          const keyExists = !!lineKeys?.[start];
          const nextAvailable = (lineKeys?.length ?? 0) + (extensions?.length ?? 1) === i;
          const index = i + 1 - (extensions?.length ?? 1);
          const canBeHidden = position && columnsToHide.includes(position?.column);

          return (
            <div
              key={b}
              className={
                `touch-screen-tile` +
                ` ${isExtension ? 'static' : ''}` +
                ` ${keyExists || nextAvailable ? 'active' : 'inactive'}` +
                ` preview` +
                ` ${canBeHidden && showHideColumns ? 'hidden' : ''}`
              }
              css={gridItemStyles({ position })}
              onClick={() => {
                if (keyExists) {
                  scrollToCard();
                }
              }}
            >
              {!isExtension && (
                <Text weight='bold' size='medium' className='touch-screen-tile-key' as='span'>
                  {index}
                </Text>
              )}
              {isExtension && (
                <Text weight='bold' className='touch-screen-tile-name' as='span'>
                  {extensions?.[i]}
                </Text>
              )}
              {(!!keyExists || !!nextAvailable) && (
                <>
                  <Text weight='bold' size='medium' className='touch-screen-tile-name' css={styles.truncate} as='span'>
                    {lineKeys?.[start]?.name}
                  </Text>
                </>
              )}
            </div>
          );
        })}

        <div
          className={`touch-screen-tile active preview`}
          css={gridItemStyles({ position: model?.touchKeys?.paginationKeyPosition })}
        >
          <Text
            onClick={() => setShowHideColumns(!showHideColumns)}
            weight='bold'
            size='medium'
            className='touch-screen-tile-key'
            as='span'
          >
            {showHideColumns ? t('More') : t('Hide')}
          </Text>
        </div>
      </div>
    </>
  );
});
DeviceLayoutTouchScreenWithPreviewEnabled.displayName = 'DeviceLayoutTouchScreenWithPreviewEnabled';

export const DeviceLayoutTouchScreen = forwardRef<
  HTMLDivElement,
  {
    isPreviewEnabled?: boolean;
    lineKeys: LineKeysTypes.GetLineKeysType['output']['lineKeys'] | undefined;
    model: LineKeysTypes.ModelInfoType | undefined;
    device: LineKeysTypes.ListDeviceType | undefined;
  }
>(({ isPreviewEnabled, ...rest }, ref) => {
  if (isPreviewEnabled) {
    return <DeviceLayoutTouchScreenWithPreviewEnabled ref={ref} {...rest} />;
  }

  return <DeviceLayoutTouchScreenWithoutPreviewEnabled ref={ref} {...rest} />;
});
DeviceLayoutTouchScreen.displayName = 'DeviceLayoutTouchScreen';

const gridStyles = ({ columns, rows }: { columns: number; rows: number }) => css`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  grid-template-rows: repeat(${rows}, 1fr);
  gap: ${theme.spacing(1)};
`;

const gridItemStyles = ({ position }: { position?: { column: number; row: number } }) => {
  return css`
    grid-column: ${position?.column};
    grid-row: ${position?.row};
  `;
};

const touchScreenStyles = css`
  width: calc(100% - ${theme.spacing(7)});
  background: ${theme.colors.neutral10};
  margin: auto;
  padding: ${theme.spacing(5, 2)};
  border-radius: ${theme.borderRadius.large};
  border: 2px solid ${theme.colors.neutral90};
  overflow-x: auto;

  .touch-screen-tile {
    border: 2px solid ${theme.colors.neutral30};
    border-radius: ${theme.borderRadius.medium};
    background: ${theme.colors.white};
    padding: ${theme.spacing(1)};
    width: ${theme.spacing(14)};
    height: ${theme.spacing(7.5)};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1.5)};
  }

  .touch-screen-tile-key {
    color: ${theme.colors.neutral50};
  }

  .touch-screen-tile-name {
    color: ${theme.colors.neutral90};
  }

  .touch-screen-tile.static {
    border: 2px solid ${theme.colors.neutral20};
  }

  .touch-screen-tile.active:not(.static, .preview):hover {
    background: ${theme.colors.neutral5};
    color: ${theme.colors.neutral50};
    cursor: pointer;
  }

  .touch-screen-tile.active:not(.static, .preview):active {
    background: ${theme.colors.neutral10};
    color: ${theme.colors.neutral50};
  }

  .touch-screen-tile.static .touch-screen-tile-name {
    color: ${theme.colors.neutral30};
  }

  .touch-screen-tile.inactive {
    border-color: transparent;
  }

  .touch-screen-tile.inactive .touch-screen-tile-key {
    color: ${theme.colors.neutral20};
  }

  .touch-screen-tile.hidden {
    display: none;
  }
`;
