import {
  useCallback,
  useRef,
  useState,
  useMemo,
  type HTMLAttributes,
  useEffect,
  Fragment,
  forwardRef,
  type ComponentProps,
} from 'react';
import { css } from '@emotion/react';
import DOMPurify from 'dompurify';
import { $getRoot } from 'lexical';
import type { BeautifulMentionsMenuItemProps, BeautifulMentionsMenuProps } from 'lexical-beautiful-mentions';
import type { TeamChatTypes } from '@frontend/api-team-chat';
import { GifPicker, StreamGiphyType } from '@frontend/gif-picker';
import { useTranslation } from '@frontend/i18n';
import { MediaUploadPreview } from '@frontend/media-upload-preview';
import { useScopedAppFlagStore } from '@frontend/scope';
import { useThrottledFn } from '@frontend/timer';
import { theme } from '@frontend/theme';
import { RTEditor, Button, useAlert, useModalControl, DynamicFieldPlugin } from '@frontend/design-system';
import { useImagesUpload, UpdatedThreadSendingMediaItem } from '../../hooks/use-images-upload';
import { useActiveConversationSelector } from '../../providers/active-conversation.provider/active-conversation.provider';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { getMentionsFromText, getUserFullName, type Target } from '../../utils';
import { ChatAvatar } from '../common/chat-avatar/chat-avatar';
import { ImageUploadModal } from './image-upload-modal';
import { SubmitButton } from './submit-button';
import { TypingIndicator } from './typing-indicator';

interface MessageComposerEditProps {
  type: 'edit';
  onCancel: () => void;
  message: TeamChatTypes.Message;
}

interface MessageComposerNewProps {
  type: 'new';
  selectedTarget?: Target;
}

type MessageComposerProps = { className?: string } & (MessageComposerEditProps | MessageComposerNewProps);

export const MessageComposer = (props: MessageComposerProps) => {
  const { t } = useTranslation('team-chat');
  const alert = useAlert();

  const activeConversationId = useTeamChatSelector((ctx) => ctx.activeConversationId);
  const conversations = useTeamChatSelector((ctx) => ctx.conversations);
  const createConversation = useTeamChatSelector((ctx) => ctx.createConversation);
  const createConversationError = useTeamChatSelector((ctx) => ctx.createConversationError);
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const isThread = useTeamChatSelector((ctx) => ctx.isThread);
  const openStaticConversation = useTeamChatSelector((ctx) => ctx.openStaticConversation);
  const selectedThreadParentId = useTeamChatSelector((ctx) => ctx.selectedThreadParentId);
  const users = useTeamChatSelector((ctx) => ctx.users);

  const editMessage = useActiveConversationSelector((ctx) => ctx.editMessage);
  const editMessageError = useActiveConversationSelector((ctx) => ctx.editMessageError);
  const sendConversationEvent = useActiveConversationSelector((ctx) => ctx.sendConversationEvent);
  const sendMessage = useActiveConversationSelector((ctx) => ctx.sendMessage);
  const sendMessageError = useActiveConversationSelector((ctx) => ctx.sendMessageError);
  const uploadAttachment = useActiveConversationSelector((ctx) => ctx.uploadAttachment);
  const uploadAttachmentError = useActiveConversationSelector((ctx) => ctx.uploadAttachmentError);

  const mentionsMenuOpenRef = useRef(false);
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isGifFeatureEnabled = getFeatureFlagValue('team-chat-gif');

  const onCancel = props.type === 'edit' ? props.onCancel : undefined;
  const existingMessage = props.type === 'edit' ? props.message : undefined;
  const selectedTarget = props.type === 'new' ? props.selectedTarget : undefined;
  const className = props.className;

  const imageUploadModalControl = useModalControl();
  const { hideUploadingImagesEffect, images, removeImage, setImages, showUploadingImagesEffect, addGif } =
    useImagesUpload();
  const [value, setValue] = useState<string>(existingMessage?.text || '');

  // i don't think we want to show a loading state for every sending message. i havent seen other chat apps do this
  // const _isLoading = isCreatingConversation || isUploadingAttachment || isSendingMessage || isEditingMessage;
  const error = createConversationError || uploadAttachmentError || sendMessageError || editMessageError;

  //TODO: the RTE editor doesn't expose a hook that allows us to control the html from the outside (that I can find)
  //So we'll use a key to clear the editor state when we want to
  const [key, setKey] = useState('key-' + Math.random());

  const isEditing = !!existingMessage;
  const conversation = useMemo(
    () => (activeConversationId ? helpers.getConversation(activeConversationId) : undefined),
    [activeConversationId, helpers]
  );

  //these might be useful?
  // const userNames = useMemo(() => users?.map((user) => user.firstName + ' ' + user.lastName), [users]);
  // const channelNames = useMemo(() => conversations?.groups.map((c) => c.name), [conversations?.groups]);

  const afterImageUploadEffects = () => {
    hideUploadingImagesEffect();
  };

  const reset = () => {
    onCancel?.();
    setValue('');
    setKey('key-' + Math.random());
  };

  const handleSubmit = useCallback(async () => {
    const hasImages = !!images.length;
    const submitMessage = async (conversation: TeamChatTypes.Conversation) => {
      let attachments: TeamChatTypes.Attachment[] = [];
      if (hasImages) {
        showUploadingImagesEffect();

        // Using reduce to minimize loops otherwise we will need to use filter and map together
        const { nonEmptyFiles, gifs } = images.reduce<{
          nonEmptyFiles: UpdatedThreadSendingMediaItem[];
          gifs: UpdatedThreadSendingMediaItem[];
        }>(
          (acc, asset) => {
            if (!asset.uploadable && asset.meta) {
              acc.gifs.push(asset);
            } else if (asset && asset.file) {
              acc.nonEmptyFiles.push(asset);
            }
            return acc;
          },
          { nonEmptyFiles: [], gifs: [] }
        );

        attachments = await uploadAttachment({
          channelId: conversation.channelId,
          files: nonEmptyFiles.map((image) => image.file).filter((file) => !!file),
        });

        if (gifs.length) {
          // FIXME: @MehulWeave update the types so that it accepts the correct type
          // Adding this back in because this is the type that giphy accepts on mobile side.
          // @ts-ignore
          attachments.push(...gifs.map((asset) => ({ type: 'giphy', ...(asset.meta as StreamGiphyType) })));
        }
      }
      const mentionedUserIds = getMentionsFromText(
        value,
        users?.map((user) => getUserFullName(user.firstName, user.lastName)) ?? []
      )
        .map((userName) => users?.find((user) => getUserFullName(user.firstName, user.lastName) === userName)?.userID)
        .filter((userId) => userId !== undefined);

      const message =
        props.type === 'edit' && existingMessage
          ? await editMessage({
              ...existingMessage,
              attachments: [...(existingMessage.attachments ?? []), ...attachments],
              text: DOMPurify.sanitize(value),
              mentionedUserIds: mentionedUserIds,
            })
          : await sendMessage({
              conversationId: conversation.channelId,
              message: {
                attachments,
                text: DOMPurify.sanitize(value),
                mentionedUserIds: mentionedUserIds,
                // it may be kinda sneaky to just pass this in here. the other option is to break this function out into pieces so that adding another branch of logic for `sendReply` would go here
                // TODO: jonathan butler will refactor to make this more clear
                parentMessageId: selectedThreadParentId,
              },
            });

      if (hasImages) {
        afterImageUploadEffects();
        setImages([]);
      }

      return message;
    };

    if (activeConversationId && conversation) {
      return submitMessage(conversation)
        .then(() => {
          reset();
        })
        .catch(() => {
          alert.error(t('Failed to send message'));
        });
    }

    if (selectedTarget?.type === 'dm' && !activeConversationId) {
      const existingConversation = helpers.getConversationByMemberIds(selectedTarget.users.map((user) => user.userID));
      if (existingConversation) {
        openStaticConversation(existingConversation.channelId);
        reset();
        return;
      } else {
        return createConversation({
          type: 'dm',
          name: undefined,
          memberIds: selectedTarget.users.map((user) => user.userID),
        })
          .then((conversation) => {
            openStaticConversation(conversation.channelId);
            return conversation;
          })
          .then((conversation) => {
            return submitMessage(conversation);
          })
          .then((message) => {
            reset();
            return message;
          })
          .catch((error) => {
            alert.error(t('Failed to send message' + error.message));
          });
      }
    }
  }, [helpers, value, images, isEditing]);

  const typingStopRef = useRef<ReturnType<typeof setTimeout>>();
  const throttledSendTypingEvent = useThrottledFn(() => {
    if (activeConversationId) {
      if (typingStopRef.current) {
        clearTimeout(typingStopRef.current);
      }
      sendConversationEvent({
        conversationId: activeConversationId,
        event: 'typing_start',
      });
      typingStopRef.current = setTimeout(() => {
        sendConversationEvent({
          conversationId: activeConversationId,
          event: 'typing_stop',
        });
      }, 2000);
    }
  }, 500);

  const onChange = async (newValue: string) => {
    setValue(newValue);
    if (activeConversationId && newValue?.length > 1) {
      throttledSendTypingEvent();
    }
  };

  let placeholder = t('Use @ to mention others...');
  if (conversation?.name && conversation.type === 'DM') {
    placeholder = t('Message {{name}}...', { name: conversation.name });
  } else if (conversation?.name && conversation.type === 'Group') {
    placeholder = t('Message #{{name}}...', { name: conversation.name });
  }

  useEffect(() => {
    // this is here to focus the message composer input when the channel changes
    // if we remove this, the input will not focus
    setKey('key-' + Math.random());
  }, [conversation?.channelId]);

  const handleKeyEvents: HTMLAttributes<HTMLFormElement>['onKeyDown'] = (e) => {
    if (e.key === 'Escape') {
      onCancel?.();
    }
    if (e.key === 'Enter' && !e.shiftKey && !mentionsMenuOpenRef.current && !!value.trim()) {
      e.preventDefault();
      e.stopPropagation(); //to stop the RTE editor from inserting a line break
      handleSubmit();
    }
  };

  /**
   * TODO: An error in any of these cases should be handled in the UI, rather than triggering a toast.
   * There should be some UI in the composer that shows the error message gives the user some options on what to try.
   * Maybe a 'retry', 'reset', etc...
   */
  useEffect(() => {
    error && alert.error('Error: ' + ((error as Error)?.message ?? error?.toString()));
  }, [error]);

  const onEditorChange: ComponentProps<typeof RTEditor>['onChange'] = (editor) => {
    editor.read(() => {
      const textContent = $getRoot().getTextContent();
      //We shouldn't need to communicate every keystroke to the parent... we just need it to know the final value when it is submitted
      onChange(textContent);
    });
  };

  const { users: RTEUsers } = useMemo(
    () => getRTEMentionItems(users ?? [], conversations?.groups ?? []),
    [users, conversations]
  );

  // eslint-disable-next-line react/display-name
  const MenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
    ({ selected, item, itemValue: __, noTransform: __ignore, children, ...props }, ref) => {
      // lexical adds a trailing space
      const name = item.data?.label?.toString().trimEnd();
      if (!name) {
        return null;
      }

      const user = users?.find((u) => `@${getUserFullName(u?.firstName, u.lastName)}` === name);
      if (!user) {
        return null;
      }

      return (
        <li
          key={(item.data?.id as string) || undefined}
          className='mention-menu-item-style'
          css={mentionMenuItemStyles(selected)}
          {...props}
          ref={ref}
        >
          <ChatAvatar users={[user]} size='xs' />
          <span>
            {user.firstName} {user.lastName}
          </span>
        </li>
      );
    }
  );

  const Menu = ({ loading, children, ...props }: BeautifulMentionsMenuProps) => {
    return (
      <ul className='mention-menu-style' css={mentionMenuStyle} {...props}>
        {children}
      </ul>
    );
  };

  return (
    <form
      className={isEditing ? 'edit-message' : 'new-message'}
      css={[styles.wrapper(isEditing, isThread), className]}
      id='message-composer'
      onKeyDownCapture={handleKeyEvents}
      onSubmit={(e) => e.preventDefault()}
    >
      {conversation && !isEditing && !selectedThreadParentId && (
        <TypingIndicator typingUserIds={conversation?.usersTyping ?? []} />
      )}
      {!!images.length && (
        <MediaUploadPreview
          media={images}
          removeImageTrackingId='team-chat-v2-remove-image-upload'
          removeMediaItem={removeImage}
          inPopout
        />
      )}
      {/* putting this key here to allow resetting the RTE editor easily. Putting it directly on the editor component throws an error*/}
      <Fragment key={key}>
        <div css={editorStyles}>
          <RTEditor
            initialHTML={value}
            onChange={onEditorChange}
            hasDraggableBlocks={false}
            hideFloatingToolbar={true}
            autoFocus={!!conversation?.channelId}
          >
            <RTEditor.Editor trigger={'@'} placeholder={placeholder} />
            <div
              css={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                padding: theme.spacing(0.5),
              }}
            >
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <RTEditor.Toolbar.EmojiPicker showBoxShadow={false} showCaret={false} css={{ height: 40 }} />
                {isGifFeatureEnabled && <GifPicker trackingId='team-chat-2.0-gif-button-click' onSelect={addGif} />}
                <Button
                  {...imageUploadModalControl.triggerProps}
                  css={css`
                    height: 40px !important;
                    width: 40px;
                    svg {
                      height: 24px;
                      width: 24px;
                    }
                  `}
                  variant='secondary'
                  iconName='image'
                  trackingId='team-chat-2.0-image-button-click'
                />
              </div>
              <div css={styles.actionsBar}>
                {isEditing ? (
                  <div style={{ gap: theme.spacing(1), display: 'flex' }}>
                    <Button variant='secondary' onClick={onCancel}>
                      {t('Cancel')}
                    </Button>
                    <Button variant='primary' type='submit' onClick={() => handleSubmit()}>
                      {t('Save')}
                    </Button>
                  </div>
                ) : (
                  <SubmitButton disabled={!value && !images.length} onClick={() => handleSubmit()} />
                )}
              </div>
            </div>

            <DynamicFieldPlugin
              trigger='@'
              defaultMentionItems={RTEUsers}
              CustomMenu={Menu}
              CustomMenuItem={MenuItem}
              onMenuOpen={() => {
                mentionsMenuOpenRef.current = true;
              }}
              onMenuClose={() => {
                mentionsMenuOpenRef.current = false;
              }}
            />

            {/** TODO: eventually we will want to allow for selecting conversations from a dropdown
              <DynamicFieldPlugin
              trigger='#'
              defaultMentionItems={RTEConversations}
              CustomMenu={Menu}
              CustomMenuItem={MenuItem}
            /> */}
          </RTEditor>
        </div>
      </Fragment>

      <ImageUploadModal modalProps={imageUploadModalControl.modalProps} onImageUpload={setImages} />
    </form>
  );
};

MessageComposer.displayName = 'MessageComposer';

const getRTEMentionItems = (users: TeamChatTypes.User[], conversations: TeamChatTypes.Conversation[]) => {
  return {
    users: users?.length
      ? {
          ['@']: users.map((user) => ({
            id: user.userID,
            label: `@${user.firstName} ${user.lastName}`,
            value: `${user.firstName} ${user.lastName}`,
            noTransform: false,
          })),
        }
      : { '@': [] },
    conversations: conversations?.length
      ? {
          ['#']: conversations.map((c) => ({
            id: c.channelId,
            label: `#${c.name}`,
            value: `#${c.name}}`,
            noTransform: false,
          })),
        }
      : { '#': [] },
  };
};

const editorStyles = css`
  #message-composer {
    height: max-content !important;
  }
  .emoji-picker-action,
  .action-pressed-chip {
    height: 40px;
  }
  .editor-paragraph {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    top: ${theme.spacing(0)} !important;
    left: ${theme.spacing(0)} !important;
  }
  .editor-scroller {
    resize: none !important;
  }
  .editor-scroller,
  .editor-input {
    min-height: 72px !important; //TODO: sorry about the importants but the styling in the editor is too strong. We need to loosen that up.
  }
  .editor-placeholder {
    /* TODO: the figma file actually shows these measurements, but they feel really weird, so I'm silently adjusting them here     
    top: ${theme.spacing(2.5)};
    left: ${theme.spacing(1)};
    */
    // using important to override styles on the RTE :(
    top: 9px !important;
    left: 18px !important;
    color: ${theme.colors.neutral30} !important;
  }
  .editor-container {
    margin: 0 !important;
  }
  .editor-input {
    /* padding: ${theme.spacing(2, 1)}; TODO: Another tiny adjustment */
    padding: ${theme.spacing(1, 2)} !important;
  }
  .dynamic-field {
    background: ${theme.colors.primary5};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.primary60};
    padding: 2px 2px;
  }
`;

const styles = {
  wrapper: (isEditing: boolean, isThread: boolean) => [
    isThread &&
      css`
        border-bottom: 1px solid ${theme.colors.neutral10};
      `,
    css`
      position: relative;
      border-top: 1px solid ${theme.colors.neutral10};
      // this ul is for the image preview
      > ul {
        height: 100px;
        padding: ${theme.spacing(1, 2)};
      }

      li {
        background-color: ${theme.colors.neutral20};
      }

      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
      }

      .editor-inner,
      .editor-container {
        border-radius: ${theme.borderRadius.medium};
      }
      .editor-input {
        border-radius: ${theme.borderRadius.medium};
      }
      &.edit-message {
        form {
          border: 1px solid ${theme.colors.warning50};
          border-radius: ${theme.borderRadius.medium};
          background-color: ${theme.colors.white};

          height: max-content;

          > div:first-of-type {
            padding: ${theme.spacing(1, 1, 0)};
          }

          > div:last-of-type {
            background-color: ${theme.colors.white};
            padding: ${theme.spacing(0.75, 1)};
          }
        }
      }
    `,
    isEditing &&
      css`
        .editor-container {
          border: 1px solid ${theme.colors.warning50};
        }
      `,
  ],
  actionsBar: css`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: ${theme.spacing(0.5)};
    margin: ${theme.spacing(0.5)};
    gap: ${theme.spacing(0.5)};
  `,
};

const mentionMenuItemStyles = (selected: boolean) => [
  css`
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    margin-top: 2px;
    outline: none;
    padding: ${theme.spacing(0.5, 2)};
    min-height: 30px;
    gap: ${theme.spacing(1)};
    position: relative;
    text-decoration: none;
    border-radius: ${theme.borderRadius.medium};
    align-items: center;
    > :first-letter {
      text-transform: uppercase;
    }

    :hover {
      background-color: ${theme.colors.neutral5};
    }
    :focus {
      background-color: ${theme.colors.neutral5};
      outline: none;
    }
  `,
  selected &&
    css`
      background-color: ${theme.colors.neutral5};
      outline: none;
    `,
];

const mentionMenuStyle = css`
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.large};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  min-width: 100px;
  width: 400px;
  max-height: 300px;
  overflow: auto;
  padding: ${theme.spacing(2)};
  z-index: ${theme.zIndex.popover};
  border: 1px solid ${theme.colors.neutral10};
  position: absolute;
  bottom: calc(
    100% + 32px
  ); //TODO: there seems to be no good way to pass in positional props to the menu, so using CSS here
  :focus {
    outline: none;
  }
`;
