import { CalendarException } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/appointment_type.pb';
import { DateSlots } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar.pb';
import { Operatory } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/operatory.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { Rule, Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { DayOfWeek as DayOfWeekSchema } from '@weave/schema-gen-ts/dist/shared/schedule/schedule.pb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import { QueryKey } from 'react-query';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { ExceptionTypes } from '@frontend/api-schedule-exceptions';
import { ServiceProvidersTypes } from '@frontend/api-service-providers';
import { localStorageHelper } from '@frontend/auth-helpers';
import { formatDate } from '@frontend/date';
import env from '@frontend/env';
import { Options as HttpOptions } from '@frontend/fetch';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';
import { genUUIDV4 } from '@frontend/string';
import { sentry } from '@frontend/tracking';
import { formatDate as dsFormatDate } from '@frontend/design-system';
import { getDateRangeFromRRule } from './rrule-helper';
import { PersonSelectorTypes } from './views/Calendar/components/PersonSelector';

dayjs.extend(durationPlugin);

dayjs.extend(customParseFormat);

export const BOOKING_SITE_HTTP_OPTIONS: HttpOptions = {
  skipValidation: true,
};

export const CalendarEventsV3DateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const calendarViewV3ApiQueryLimit = 500;

type GetDayOfWeekReturnType = DayOfWeekSchema | '' | undefined;

export const MAX_LOCATIONS_ALLOWED_IN_CALENDAR_VIEW = 4;

export const getDayOfWeek = (date: string): GetDayOfWeekReturnType => {
  if (!date) return;

  // number of the day of the week from 0 (Sunday) to 6 (Saturday)
  const day = dayjs(date).isoWeekday();
  switch (day) {
    case 0:
      return DayOfWeekSchema.SUNDAY;
    case 1:
      return DayOfWeekSchema.MONDAY;
    case 2:
      return DayOfWeekSchema.TUESDAY;
    case 3:
      return DayOfWeekSchema.WEDNESDAY;
    case 4:
      return DayOfWeekSchema.THURSDAY;
    case 5:
      return DayOfWeekSchema.FRIDAY;
    case 6:
      return DayOfWeekSchema.SATURDAY;
    default:
      return '';
  }
};

const bookingLinkBaseURL = ['https://book2.weavedev.net', 'https://book2.getweave.com'];

const validateIsWeaveBookingLink = (url: string): boolean => {
  try {
    const parsedURL = new URL(url);
    return bookingLinkBaseURL.includes(parsedURL.origin);
  } catch {
    sentry.warn({
      error: 'Invalid url provided for booking link',
      topic: 'schedule',
      addContext: {
        name: 'url details',
        context: {
          url,
        },
      },
    });
    return false;
  }
};

export const isValidBookingLink = (url: string, locationId: string): boolean => {
  return validateIsWeaveBookingLink(url) && url?.includes(locationId);
};

export const hasSameIds = (masterIds: string[], filterIds: string[]): boolean => {
  return masterIds.length === filterIds.length && masterIds.every((id) => filterIds.includes(id));
};

export const getIsIntegratedOffice = (syncApps: DataSourcesTypes.DataSource[]): boolean => {
  return syncApps?.some((syncApp) => syncApp.SourceType === 'Integration');
};

export const getBookingSiteUrl = (locationId: string, source = ScheduleRequestSource.WEBSITE) => {
  return `${env.APP_BOOK_BASE_URL_NEW}/${locationId}/request-appointment?${new URLSearchParams({ source }).toString()}`;
};

/**
 * @desc: Function is defined to get the system timezone
 * @returns timezone: string
 */
export const getSystemTzid = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const calculateDuration = (startTime: string, endTime: string): number => {
  // Helper function to convert time string (HH:MM) to minutes
  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);

  // Calculate the duration in minutes
  const duration = endMinutes - startMinutes;
  return duration;
};

/**
 * @desc: This method is used to get the sanitized schedule hours object from the schedule availability
 * @returns : ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule
 */
export const getSanitizedScheduleHoursData = (
  scheduleHoursData: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule
): ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule => {
  const scheduleHours = { ...scheduleHoursData };

  for (const day in scheduleHours) {
    if (scheduleHours[day].length === 0) {
      delete scheduleHours[day];
    }
  }

  return scheduleHours;
};

export const getFormattedExceptionDateRange = (startDate?: string, endDate?: string): string => {
  if (!startDate || !endDate) return '';

  let formattedDate = '';

  const startDateTime = dayjs.utc(startDate);
  const endDateTime = dayjs.utc(endDate);

  const hasSameStartEndDate = startDateTime.isSame(endDateTime, 'day');

  if (hasSameStartEndDate) {
    formattedDate = startDateTime.format('MMMM D, YYYY');
  } else {
    const isSameYear = startDateTime.isSame(endDateTime, 'year');
    const isSameMonth = startDateTime.isSame(endDateTime, 'month');

    if (!isSameYear) {
      formattedDate = `${startDateTime.format('MMMM D, YYYY')} - ${endDateTime.format('MMMM D, YYYY')}`;
    } else if (!isSameMonth) {
      formattedDate = `${startDateTime.format('MMMM D')} - ${endDateTime.format('MMMM D, YYYY')}`;
    } else {
      formattedDate = `${startDateTime.format('MMMM D')} - ${endDateTime.format('D, YYYY')}`;
    }
  }

  return formattedDate;
};

export const getFormattedExceptionTimeRange = (startDate?: string, duration?: number): string => {
  if (!startDate || !duration) return '';

  let formattedTime = '';
  const startDateTime = dayjs.utc(startDate);
  const endDateTime = startDateTime.add(duration, 'minute');

  const isEntireDay =
    startDateTime.hour() === 0 &&
    startDateTime.minute() === 0 &&
    endDateTime.hour() === 23 &&
    endDateTime.minute() === 59;

  if (isEntireDay) {
    formattedTime = 'All Day';
  } else {
    const startTime = startDateTime;
    const endTime = startTime.add(duration, 'minute');
    formattedTime = `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  }

  return formattedTime;
};

export const isUpcomingException = (date: Date) => {
  const today = dayjs();
  const endDate = dayjs(date);
  return today.isBefore(endDate) || today.isSame(endDate);
};

export const isPastException = (date: Date) => {
  const today = dayjs();
  const startDate = dayjs(date);
  return today.isAfter(startDate);
};

export const isCompletedException = (date?: Date) => {
  if (!date) return false;

  const today = dayjs();
  const endDate = dayjs(date);
  return endDate.isBefore(today);
};

// Generate 5-character ID for exception
export const generateExceptionId = () => {
  return genUUIDV4().slice(-5);
};

export const findOverrideIndexById = (overrides: Rule[], id: string) => {
  return overrides.findIndex((override) => override.id && override.id === id);
};

type ConvertToSystemTimezoneParamsType = {
  dateString: string;
  timeString: string;
  sourceTimezone: string;
};

export const convertToSystemTimezone = ({
  dateString,
  timeString,
  sourceTimezone,
}: ConvertToSystemTimezoneParamsType): dayjs.Dayjs => {
  if (!dateString || !timeString) return dayjs();

  const datetimeString = `${dateString} ${timeString}`;

  const dateInSourceTimezone = dayjs.tz(datetimeString, sourceTimezone);

  // Convert the date to the system's local timezone
  const dateInSystemTimezone = dateInSourceTimezone.local();

  return dateInSystemTimezone;
};

export const getFullName = <
  T extends Partial<Record<'firstName' | 'lastName' | 'FirstName' | 'LastName', string>> | undefined | null
>(
  entity: T
) => {
  if (!entity) return '';
  return `${entity.firstName || entity.FirstName || ''} ${entity.lastName || entity.LastName || ''}`.trim();
};

// Transform V3 responses to match V2 fields for the review request form. This should be removed once component refactored to have separate V3 container
type TransformV3ResponsesForReviewRequest = {
  appointmentTypesDataV3?: AppointmentType[];
  practitionerDataV3?: Practitioner[];
  operatoriesDataV3?: Operatory[];
};

export const transformV3ResponsesForReviewRequest = ({
  appointmentTypesDataV3,
  practitionerDataV3,
  operatoriesDataV3,
}: TransformV3ResponsesForReviewRequest) => {
  const transformedAppointmentTypes =
    appointmentTypesDataV3?.map((appointmentType: AppointmentType) => {
      return {
        id: appointmentType.id,
        durationMinutes: appointmentType.details?.dur ?? 0,
        name: appointmentType.displayName || appointmentType.externalName || '',
        sourceId: appointmentType.sourceTenantId,
        externalId: appointmentType.externalId,
        amount: appointmentType.details?.amt,
        locationId: appointmentType.locationId,
      };
    }) || [];

  const transformedPractitioners = practitionerDataV3?.map((practitioner: Practitioner) => {
    return {
      id: practitioner.id,
      publicDisplayName: practitioner.displayName,
      firstName: practitioner.firstName,
      lastName: practitioner.lastName,
      sourceId: practitioner.sourceTenantId,
      calendarId: practitioner.calendarId,
      externalId: practitioner.externalId,
      locationId: practitioner.locationId,
      parentProviderId: practitioner.calendarId, // using the parentProviderId as calendarId field for transformation. //TODO: Need to update v2 Provider to have calendarId in the schema
    };
  });

  const transformedOperatories = operatoriesDataV3?.map((operatory: Operatory) => {
    return {
      id: operatory.id,
      workstationName: operatory.displayName || operatory.externalName || '',
      sourceId: operatory.sourceTenantId,
      locationId: operatory.locationId,
      externalId: operatory.externalId,
    };
  });

  return {
    transformedAppointmentTypes,
    transformedPractitioners,
    transformedOperatories,
  };
};

export const transformSchedulerV3EventInfoToCalendarEvent = (
  eventInfo: Schedule,
  eventId: string,
  providerId: string
): CalendarException => {
  const unavailableEvent = eventInfo.recurrenceRules?.override?.unAvailabilities?.find((event) => event.id === eventId);

  const { rrule } = unavailableEvent || {};

  if (rrule) {
    const { startDateTime, endDateTime } = getDateRangeFromRRule(rrule);

    return {
      id: eventInfo.id,
      name: unavailableEvent?.name || '',
      isAvailable: false,
      locationId: eventInfo.locationId,
      startDateTime: startDateTime,
      endDateTime: dayjs
        .utc(endDateTime)
        .add(unavailableEvent?.duration || 0, 'minute')
        .toISOString(),
      providerId,
    };
  }

  return {
    id: eventId,
    name: '',
    isAvailable: false,
    locationId: eventInfo.locationId,
    startDateTime: '',
    endDateTime: '',
    providerId,
  };
};

export const transformDateSlotsToOpeningBlocks = (dateSlots: DateSlots[]): ScheduleTypes.OpeningBlock[] => {
  return dateSlots.flatMap((dateSlot) =>
    (dateSlot.slots || [])
      .filter((slot) => slot.startTime && slot.endTime) // Ensure both startTime and endTime exist
      .map((slot) => {
        const targetDateFormat = 'YYYY-MM-DDTHH:mm:ss';

        const startDateTime = formatDate(`${dateSlot.date} ${slot.startTime}`, targetDateFormat);
        const endDateTime = formatDate(`${dateSlot.date} ${slot.endTime}`, targetDateFormat);

        return {
          startTime: startDateTime,
          endTime: endDateTime,
        };
      })
  );
};

export const invalidateFilterForPersonSearch = (
  queryKey: QueryKey,
  formValues: PersonSelectorTypes.PersonSelectorFormValues,
  locationId?: string
) => {
  return (
    queryKey[2] === locationId &&
    ((queryKey.includes('person-search-by-phone') && queryKey[1] === formValues.phoneNumber) ||
      queryKey.includes('person-search-by-name'))
  );
};

export const shouldInvalidateExceptions = (queryKey: QueryKey, locationId: string, providerId?: string) => {
  const isSchedule = queryKey[0] === 'schedule';
  const isProviderException = providerId && queryKey[1] === 'providerExceptions';
  const isOfficeException = !providerId && queryKey[1] === 'officeExceptions';

  // Do not invalidate if query key is not of schedule office or provider exceptions
  if (!isSchedule || (!isProviderException && !isOfficeException)) {
    return false;
  }

  const queryObject = queryKey[2] as ExceptionTypes.GetOfficeHoursExceptionsForMultipleLocationsType['input'] &
    ServiceProvidersTypes.ProvidersExceptionsForMultipleProvidersOfMultiLocations['input'];

  if (!queryObject) {
    return false;
  }

  const isProviderValid = providerId && queryObject.providerIds?.includes(providerId);
  const isLocationValid = queryObject.locationIds?.includes(locationId);

  // Invalidate if the query key includes providerId and locationId for provider exceptions or locationId for office exceptions
  return (isProviderException && isProviderValid && isLocationValid) || (isOfficeException && isLocationValid) || false;
};

export const schedulePulseSelectedTabStorage = {
  key: 'weave.schedule-pulse.selected-tab',
  set(tab: 'appointment-requests' | 'appointment-list') {
    localStorageHelper.create(this.key, tab);
  },
  get() {
    return localStorageHelper.get(this.key) as 'appointment-requests' | 'appointment-list';
  },
  reset() {
    localStorageHelper.delete(this.key);
  },
};
type FormatOutEventDateTimeParams = {
  startDate: string;
  endDate?: string;
  calendarDate?: string;
  duration: string;
};

export const formatOutEventCardDateTime = ({
  startDate,
  endDate,
  calendarDate,
  duration,
}: FormatOutEventDateTimeParams) => {
  // Non all day events
  if (duration !== 'All day') return `${dsFormatDate(calendarDate, 'MMM D')} • ${duration}`;

  // All day events
  if (!dayjs(endDate).isValid()) {
    return dsFormatDate(startDate, 'MMM D');
  }

  const startDateTime = dayjs(startDate);
  const endDateTime = dayjs(endDate);
  const isSameDay = startDateTime.isSame(endDateTime, 'day');
  const isSameYear = startDateTime.isSame(endDateTime, 'year');
  const isSameMonth = startDateTime.isSame(endDateTime, 'month');

  if (isSameDay) return dsFormatDate(startDate, 'MMM D');
  if (!isSameYear) return `${startDateTime.format('MMM D, YYYY')} - ${endDateTime.format('MMM D, YYYY')}`;
  if (!isSameMonth) return `${startDateTime.format('MMM D')} - ${endDateTime.format('MMM D')}`;

  return `${startDateTime.format('MMM D')} - ${endDateTime.format('D')}`;
};
