import { PropsWithChildren } from 'react';
import { ComponentProps } from '@frontend/types';
import { useCollectPaymentMultiContext } from '../../collect-payment-multi.context';
import { MultiStepDynamicRenderMethod } from '../../collect-payment-multi.types';
import { StyledStep } from './styled-step';

type StyledStepProps = ComponentProps<typeof StyledStep>;

export type DynamicStepProps = StyledStepProps &
  PropsWithChildren<{
    renderMethod?: MultiStepDynamicRenderMethod;
  }>;

export const DynamicStep = ({ renderMethod, children, components = {}, ...props }: DynamicStepProps) => {
  const context = useCollectPaymentMultiContext();

  return (
    <StyledStep {...props} components={{ ...components, Footer: () => null }}>
      {renderMethod ? renderMethod(context) : children}
    </StyledStep>
  );
};
