import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { Text, useTooltip } from '@frontend/design-system';
import { DisabledTooltipContent, disabledTooltipStyle } from './disabled-tooltip-content';

type Props = {
  locationId: string;
  hasQuickFillAccess: boolean;
};

export const DropdownOptionContent = ({ locationId, hasQuickFillAccess }: Props) => {
  const { t } = useTranslation('schedule-quick-fill');
  const { getLocationName } = useAppScopeStore();
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  if (hasQuickFillAccess) {
    return <Text>{getLocationName(locationId)}</Text>;
  }

  return (
    <>
      <Text {...triggerProps} color='subdued'>
        {getLocationName(locationId)}
      </Text>
      <Tooltip {...tooltipProps} css={disabledTooltipStyle}>
        <DisabledTooltipContent text={t("This location doesn't have Quick Fill.")} />
      </Tooltip>
    </>
  );
};
