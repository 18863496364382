import { useQueryClient } from 'react-query';
import type { StreamChat } from 'stream-chat';
import {
  useInfiniteQueryWithSetter,
  useQueryWithSetter,
  type ContextlessQueryObserverOptions,
} from '@frontend/react-query-helpers';
import {
  getUnreadMentionsCount,
  getMentions,
  initializeStreamClient,
  getUsers,
  getConversations,
  getConversationMessages,
  getReplies,
} from './stream-api';
import { USERS_QUERY_LIMIT } from './stream-constants';

type ClientWithUser = StreamChat & { user: NonNullable<StreamChat['user']> };
const verifyClientWithUser = (client: StreamChat | undefined) => {
  if (!client) {
    throw new Error('Stream client is not initialized');
  }
  if (!client.user?.id) {
    throw new Error('Stream client user is not connected');
  }
  return Promise.resolve(client as ClientWithUser);
};

const BaseKey = 'team-chat';

export const queryKeys = {
  token: (orgId: string) => [BaseKey, orgId],
  client: (orgId: string, userId: string) => [BaseKey, 'client', orgId, userId],
  unreadMentionsCount: (userId: string) => [BaseKey, userId, 'unread-mentions-count'],
  mentions: (userId: string, limit: number, offset: number) => [BaseKey, userId, 'mentions', limit, offset],
  users: (orgId: string) => [BaseKey, orgId, 'users'],
  conversations: (userId: string) => [BaseKey, userId, 'conversations'],
  messages: (conversationId: string) => [BaseKey, conversationId, 'messages'],
  threads: (threadId: string) => [BaseKey, 'threads', threadId],
};

export const useInvalidateAll = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([BaseKey]);
  };
};

export const useQueryCache = () => {
  const queryClient = useQueryClient();
  const setQueryData = queryClient.setQueryData;
  return { setQueryData };
};

export const useQueryStreamClient = (
  {
    orgId,
    weaveUserId,
  }: {
    orgId: string;
    weaveUserId: string;
  },
  opts: ContextlessQueryObserverOptions = {}
) => {
  return useQueryWithSetter({
    queryKey: queryKeys.client(orgId, weaveUserId),
    queryFn: () => initializeStreamClient({ orgId, weaveUserId }),
    enabled: opts?.enabled !== false && !!orgId && !!weaveUserId,
    retry: false,
    ...opts,
  });
};

export const useQueryUnreadMentionsCount = (
  streamClient: StreamChat | undefined,
  currentUserId: string,
  opts: ContextlessQueryObserverOptions
) => {
  return useQueryWithSetter({
    queryKey: queryKeys.unreadMentionsCount(currentUserId),
    queryFn: () => verifyClientWithUser(streamClient).then((client) => getUnreadMentionsCount(client, currentUserId)),
    enabled: opts?.enabled !== false && !!streamClient?.user?.id,
    ...opts,
  });
};

export const useQueryMentions = (
  streamClient: StreamChat | undefined,
  currentUserId: string,
  limit: number,
  offset: number,
  opts: ContextlessQueryObserverOptions
) => {
  return useQueryWithSetter({
    queryKey: queryKeys.mentions(currentUserId, limit, offset),
    queryFn: () =>
      verifyClientWithUser(streamClient).then((client) => getMentions(client, currentUserId, limit, offset)),
    enabled: opts?.enabled !== false && !!currentUserId && !!streamClient?.user?.id,
    ...opts,
  });
};

export const useQueryUsers = (
  streamClient: StreamChat | undefined,
  orgId: string,
  opts: ContextlessQueryObserverOptions = {}
) => {
  return useQueryWithSetter({
    queryKey: queryKeys.users(orgId),
    queryFn: () => verifyClientWithUser(streamClient).then((client) => getUsers(client, orgId)),
    enabled: opts?.enabled !== false && !!streamClient?.user?.id,
    ...opts,
  });
};

export const useQueryConversations = (
  streamClient: StreamChat | undefined,
  currentUserId: string | undefined,
  orgId: string,
  opts: ContextlessQueryObserverOptions = {}
) => {
  return useQueryWithSetter({
    queryKey: queryKeys.conversations(currentUserId ?? 'unknown-user'),
    queryFn: () => verifyClientWithUser(streamClient).then((client) => getConversations(client, orgId)),
    enabled: opts?.enabled !== false && !!currentUserId && !!streamClient?.user?.id,
    ...opts,
  });
};

export const useQueryConversationMessages = (
  streamClient: StreamChat | undefined,
  currentUserId: string | undefined,
  conversationId: string,
  opts: ContextlessQueryObserverOptions = {}
) => {
  return useInfiniteQueryWithSetter({
    queryKey: queryKeys.messages(conversationId),
    queryFn: ({ pageParam: cursor }) =>
      verifyClientWithUser(streamClient).then((client) =>
        getConversationMessages(client, conversationId, client.user.id, cursor, USERS_QUERY_LIMIT)
      ),
    getNextPageParam: (_lastPage, pages) => {
      //TODO: why?? The pages are being organized in reverse order, but I'm not doing it. So the lastPage is always the first-queried page, but the last page in the array..."
      //So Instead of looking at the lastPage, I'm going to look at the first page in the array or pages since that last-queried page is the most recent
      const hasNextPage = pages[0]?.length === USERS_QUERY_LIMIT;
      return hasNextPage ? pages[0]?.[0].id : undefined;
    },
    enabled: opts?.enabled !== false && !!currentUserId && !!streamClient?.user?.id,
    ...opts,
  });
};

export const useQueryReplies = (
  streamClient: StreamChat | undefined,
  activeConversationId: string,
  userId: string,
  parentId: string,
  opts: ContextlessQueryObserverOptions = {}
) => {
  return useInfiniteQueryWithSetter({
    queryKey: queryKeys.threads(parentId),
    queryFn: ({ pageParam: cursor }) =>
      verifyClientWithUser(streamClient).then((client) =>
        getReplies(client, activeConversationId, userId, parentId, USERS_QUERY_LIMIT, cursor)
      ),
    getNextPageParam: (_lastPage, pages) => {
      const hasNextPage = pages[0]?.length === USERS_QUERY_LIMIT;
      return hasNextPage ? pages[0]?.[0].id : undefined;
    },
    ...opts,
  });
};
