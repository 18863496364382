import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const getRelativeTime = (dateStr?: string): string => {
  if (!dateStr) return '';

  return dayjs(dateStr).fromNow();
};

export const formatHistoryTableDate = (dateStr: string): string => {
  const date = dayjs(dateStr);
  const today = dayjs();

  if (date.isSame(today, 'day')) {
    return `Today ${date.format('h:mm A')}`;
  } else if (date.isSame(today.subtract(1, 'day'), 'day')) {
    return `Yesterday ${date.format('h:mm A')}`;
  } else {
    return date.format('MMM D, h:mm A');
  }
};

export const getFullName = <
  T extends Partial<Record<'firstName' | 'lastName' | 'FirstName' | 'LastName', string>> | undefined | null
>(
  entity: T
) => {
  if (!entity) return '';
  return `${entity.firstName || entity.FirstName || ''} ${entity.lastName || entity.LastName || ''}`.trim();
};
