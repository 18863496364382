import { FC, PropsWithChildren, ReactElement, useMemo, useState } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PaymentMethod, PaymentPlanQueries } from '@frontend/api-payment-plans';
import { formatCentsToCurrency } from '@frontend/currency';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { ConfirmationModal, useAlert, useModalControl } from '@frontend/design-system';
import { STRIPE_RESPONSE_DELAY } from '../constants';
import { CardACHSelectionContext } from '../context';
import { useCardOnFile } from '../hooks';
import { AddACHForm } from './add-ach-form';
import { AddCardForm } from './add-card-form';
import { CardACHSelectionAction } from './card-ach-selection-action';
import { CardACHSelectionContent } from './card-ach-selection-content';

interface CardACHSelectionProps {
  patientId?: string;
  patientEmail: string;
  selectedPM: PaymentMethod | undefined;
  paymentAmount?: number;
  minACHAmount: number;
  achDisabledMessage?: string;
  achDisabledByCadence?: boolean;
  contentStyles?: Interpolation<Theme>;
  addCardContent?: ReactElement;
  addACHContent?: ReactElement;
  onChangePM: (pm: PaymentMethod | undefined) => void;
  onChangeView?: (view: CardACHSelectionView) => void;
}

interface CardACHSelectionComponentProps extends FC<PropsWithChildren<CardACHSelectionProps>> {
  Content: typeof CardACHSelectionContent;
  Action: typeof CardACHSelectionAction;
  AddACHForm: typeof AddACHForm;
  AddCardForm: typeof AddCardForm;
}

const PAYMENT_MENTHOD_IN_USE_ERROR = 'payment method is already in use';

export type CardACHSelectionView = 'payment-methods' | 'add-card' | 'add-ach';

export const CardACHSelection: CardACHSelectionComponentProps = ({
  children,
  patientId = '',
  patientEmail = '',
  selectedPM,
  paymentAmount = 0,
  minACHAmount,
  achDisabledByCadence,
  achDisabledMessage,
  addCardContent,
  addACHContent,
  onChangePM,
  onChangeView,
}) => {
  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const alerts = useAlert();
  const { t } = useTranslation('payments');

  const deleteModalControls = useModalControl();
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod | null>(null);

  const [view, setView] = useState<CardACHSelectionView>('payment-methods');

  const [isResendVerificationACHMode, setIsResendVerificationACHMode] = useState(false);
  const [isDeleteMutating, setIsDeleteMutating] = useState(false); // Custom state as timeout is there on success

  const { invalidatePaymentMethodsByPerson } = PaymentPlanQueries.usePaymentPlansInvalidation();

  const { mutate: deletePaymentMethod } = PaymentPlanQueries.useDeletePaymentMethod({
    onSuccess: () => {
      setTimeout(() => {
        alerts.success(t('Deleted successfully'));
        invalidatePaymentMethodsByPerson(patientId, locationId);
        setIsDeleteMutating(false);
      }, STRIPE_RESPONSE_DELAY);
    },
    onError: (error) => {
      if (error && error instanceof HttpError) {
        if (error.status === 400 && error.message.includes(PAYMENT_MENTHOD_IN_USE_ERROR)) {
          alerts.error(
            t('This payment method cannot be deleted, it is still being used in at least one payment plan.')
          );
        } else {
          alerts.error(error.message || t('An error occurred in delete.'));
        }
      }
      setIsDeleteMutating(false);
    },
  });

  const {
    isLoading: isLoadingPaymentMethods,
    disableACHPaymentMethods,
    paymentMethods,
    authorizationEmail,
  } = useCardOnFile({
    alertOnError: true,
    achDisabledByCadence,
    minACHAmount,
    patientId,
    paymentAmount,
    setSelectedPM: onChangePM,
    selectDefaultPaymentMethod: true,
  });

  const { data: paymentMethodSetupLink, isLoading: isLoadingSetupLink } =
    PaymentPlanQueries.useGetPaymentMethodSetupLink({
      paymentsUrl,
      locationId,
    });

  const { cardList, achList } = useMemo(
    () => ({
      cardList: (paymentMethods || []).filter(({ type }) => type === 'card'),
      achList: (paymentMethods || []).filter(({ type }) => type === 'us_bank_account'),
    }),
    [paymentMethods]
  );

  const { aggregateValue: achOnFileEnabled } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [locationId],
  });

  const { aggregateValue: addACHEnabled } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.EnableAddACH,
    locationIds: [locationId],
  });

  const handleChangeView = (newView: CardACHSelectionView) => {
    onChangeView?.(newView);
    setView(newView);
  };

  const onDeleteAction = (pm: PaymentMethod) => {
    setPaymentMethodToDelete(pm);
    deleteModalControls.openModal();
  };

  const onConfirmDelete = () => {
    setIsDeleteMutating(true);
    deletePaymentMethod({
      paymentsUrl: paymentsUrl || '',
      paymentMethodId: paymentMethodToDelete?.id ?? '',
      locationId,
    });
  };

  const disableACHPaymentMessage =
    achDisabledMessage ??
    t('ACH only available for payments over {{minimumAmount}}.', {
      minimumAmount: formatCentsToCurrency(minACHAmount),
    });

  const isLoading = isLoadingPaymentMethods || isLoadingSetupLink || isDeleteMutating;

  return (
    <CardACHSelectionContext.Provider
      value={{
        achOnFileEnabled,
        addACHEnabled,
        addPaymentMethodEnabled: !!paymentMethodSetupLink,
        achList,
        cardList,
        disableACHPaymentMethods,
        disableACHPaymentMessage,
        isLoading,
        isResendVerificationACHMode,
        patientId,
        authorizationEmail: isResendVerificationACHMode ? authorizationEmail : patientEmail,
        locationId,
        selectedPM,
        setSelectedPM: onChangePM,
        onDeleteAction,
        openAddCard: () => handleChangeView('add-card'),
        closeAddCard: () => handleChangeView('payment-methods'),
        openAddACH: () => handleChangeView('add-ach'),
        closeAddACH: () => {
          handleChangeView('payment-methods');
          setIsResendVerificationACHMode(false);
        },
        openResendACHVerification: () => {
          setIsResendVerificationACHMode(true);
          handleChangeView('add-ach');
        },
      }}
    >
      {view === 'payment-methods' && children}
      {view === 'add-card' && addCardContent}
      {view === 'add-ach' && addACHContent}
      <ConfirmationModal
        {...deleteModalControls.modalProps}
        title={paymentMethodToDelete?.type === 'us_bank_account' ? t('Delete ACH') : t('Delete Card')}
        message={
          paymentMethodToDelete?.type === 'us_bank_account'
            ? t('Are you sure you want to delete this ACH?')
            : t('Are you sure you want to delete this Card?')
        }
        confirmLabel={t('Delete')}
        onConfirm={onConfirmDelete}
        onCancel={() => setPaymentMethodToDelete(null)}
        destructive
      />
    </CardACHSelectionContext.Provider>
  );
};

CardACHSelection.Content = CardACHSelectionContent;
CardACHSelection.Action = CardACHSelectionAction;
CardACHSelection.AddACHForm = AddACHForm;
CardACHSelection.AddCardForm = AddCardForm;
