import { ReactElement, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { Chips } from '@frontend/chips';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Chip,
  Heading,
  SpinningLoader,
  Text,
  usePopoverMenu,
  NakedButton,
  PopoverMenu,
  PopoverMenuItem,
  useAlert,
  Truncated,
} from '@frontend/design-system';
import { useAppointmentsInfoShallowStore, useGetDataSourcesForLocation } from '../../../hooks';
import { useAppointmentEventCardShallowStore } from '../../../stores/use-appointment-event-card-store';
import { EventCardAppointmentStatusChip } from '../../v3/EventCardAppointmentStatusChip';
import {
  ScheduleEventAppointmentDetails,
  getLatestUpdatedAppointmentStatus,
  formatStringForComparison,
} from '../helpers';
import { AppointmentTypeChip as AppointmentType } from './appointment-type-chip';
import { useAppointmentStatusShallowStore } from './appointmentStatusStore';
import { AppointmentStatusType, APPOINTMENT_STATUS_MAPPING, AppointmentStatusEnum } from './types';

type AppointmentStatusProps = {
  locationId: string;
  appointmentId: string;
  status: string;
};

const AppointmentStatus = ({ locationId, appointmentId, status }: AppointmentStatusProps) => {
  const { isIntegratedOffice } = useAppointmentEventCardShallowStore('isIntegratedOffice');

  const { appointments: calendarAppointments } = useAppointmentsInfoShallowStore('appointments');

  const { data: dataSourcesData, isLoading: isLoadingDataSources } = useGetDataSourcesForLocation({
    locationId,
    isEnabled: isIntegratedOffice,
  });

  const isWritebackEnabled = useMemo(
    () =>
      dataSourcesData?.dataSources?.some((dataSource) =>
        dataSource.integration?.capabilities?.find(
          (capability) => capability.dataType === 'APPOINTMENT_STATUS' && capability.operation === 'LIST'
        )
      ),
    [dataSourcesData]
  );

  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarEvents');
  const { appointmentStatus, setAppointmentStatus, resetAppointmentStatus } = useAppointmentStatusShallowStore(
    'appointmentStatus',
    'setAppointmentStatus',
    'resetAppointmentStatus'
  );

  const { data: appointmentStatusData, isLoading: isAppointmentStatusLoading } =
    ScheduleQueries.useGetAppointmentStatuses(locationId);

  const {
    data: appointmentData,
    refetch: refetchAppointment,
    isLoading: isAppointmentDataLoading,
    isRefetching: isAppointmentDataRefetching,
  } = ScheduleQueries.useGetAppointment({ appointmentId, locationId, isEnabled: !!appointmentId && !!locationId });

  const appointment =
    appointmentData?.appointment ??
    calendarAppointments?.find((appointment) => appointment.id === appointmentId) ??
    null;

  const {
    getTriggerProps: getPopoverTriggerProps,
    getMenuProps: getPopoverProps,
    close: closePopover,
  } = usePopoverMenu({ placement: 'bottom-start' });

  const sourceAppointmentStatusMapping = useMemo(() => {
    const source = appointmentStatusData?.mappings?.find(
      (mapping) => mapping.sourceId === appointment?.createdBySourceId
    );

    let mappings = source?.mappings?.filter((status) => status.value.length);

    if (mappings?.length === 0 && source?.externalValues) {
      mappings = source?.externalValues.map((status) => {
        return {
          key: status.label,
          value: [{ label: status.label, value: status.value }],
        };
      });
    }
    return mappings;
  }, [appointmentStatusData, appointment]);

  const appointmentStatuses = useMemo(() => {
    return sourceAppointmentStatusMapping?.reduce((acc: AppointmentStatusType[], curr) => {
      const statusMap = APPOINTMENT_STATUS_MAPPING.get(
        curr.key?.toLowerCase().replace(' ', '') as AppointmentStatusEnum
      );
      curr.value.forEach((assignedStatus) => {
        const statusObject: AppointmentStatusType = {
          key: curr.key,
          value: assignedStatus.value,
          text: assignedStatus.label,
          iconName: statusMap ? statusMap.iconName : 'pending-small',
          variant: statusMap && statusMap.variant,
        };
        acc.push(statusObject);
      });
      return acc;
    }, []);
  }, [sourceAppointmentStatusMapping]);

  const resetAppointmentStatusStore = () => {
    refetchAppointment();
    resetAppointmentStatus();
  };

  const currentStatus = useMemo(() => {
    const statusToMatch =
      appointment && appointment.statusOfficeView
        ? formatStringForComparison(appointment?.statusOfficeView)
        : formatStringForComparison(status) ?? '';

    const statusChipValue = (statusToMatch: string) => {
      const status = appointmentStatuses?.find((statusItem) => {
        return (
          statusToMatch === formatStringForComparison(statusItem.key ?? '') ||
          statusToMatch === formatStringForComparison(statusItem.text ?? '')
        );
      });
      return status;
    };

    return (
      statusChipValue(statusToMatch) ||
      APPOINTMENT_STATUS_MAPPING.get(statusToMatch.replace(' ', '') as AppointmentStatusEnum)
    );
  }, [appointmentStatuses, appointment?.statusOfficeView, status, appointment]);

  const statusChip = useMemo(() => {
    let chip = currentStatus;
    if (appointmentStatus.length) {
      const existsInStore = appointmentStatus.findLast((status) => status.id === appointmentId);

      if (existsInStore) {
        const canUpdate = getLatestUpdatedAppointmentStatus(existsInStore);
        if (canUpdate) {
          resetAppointmentStatusStore();
          chip = currentStatus;
        } else {
          chip = existsInStore;
        }
      }
    }
    return chip as AppointmentStatusType;
  }, [appointmentId, appointmentStatus, currentStatus]);
  useEffect(() => {
    if (appointmentStatus.length) {
      resetAppointmentStatusStore();
    }
    refetchAppointment();
  }, []);

  const { mutateAsync: updateAppointmentStatus } = ScheduleQueries.useMutateUpdateAppointmentStatus(
    appointment?.externalId ?? ''
  );

  const updateStatus = async (requestData: AppointmentStatusType) => {
    if (!appointment) return;
    const { value, text, key, variant } = requestData;

    const data = {
      status_id: value,
      status: text,
      source_id: appointment.createdBySourceId ?? '',
      source_type: 'employee',
      location_id: locationId,
    } as ScheduleTypes.AppointmentStatusRequest;

    try {
      await updateAppointmentStatus(data);
      refetchAppointment();
      alert.success(t('Appointment status updated. It might take a few minutes to reflect'));
      setAppointmentStatus({
        key,
        text,
        value,
        iconName: requestData.iconName,
        variant,
        id: appointmentId,
        updatedAt: formatDate(dayjs()),
      });
    } catch (error) {
      console.error('Error updating appointment status', error);
      alert.error(t('Error updating appointment status'));
    } finally {
      closePopover();
    }
  };

  const getExternalStatusCounts = (appointmentStatuses: AppointmentStatusType[]) => {
    return appointmentStatuses.reduce((acc, item) => {
      const externalStatus = formatStringForComparison(item.text) ?? '';
      acc[externalStatus] = (acc[externalStatus] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  };

  if (!appointmentId) return null;

  if (!isWritebackEnabled) {
    const statusInfo = APPOINTMENT_STATUS_MAPPING.get(status?.toLowerCase().replace(' ', '') as AppointmentStatusEnum);

    if (!statusInfo) return null;
    return (
      <Chip
        variant={statusInfo.variant}
        css={{ minWidth: 'fit-content' }}
        leftElement={statusInfo.iconName && <Icon name={statusInfo.iconName} css={{ color: 'currentcolor' }} />}
      >
        {statusInfo.text}
      </Chip>
    );
  }

  return (
    <>
      {!!appointmentStatuses && !!statusChip && (
        <PopoverMenu {...getPopoverProps()}>
          {isAppointmentStatusLoading && <SpinningLoader size='xs' />}
          {appointmentStatuses.map((statusItem) => {
            // Key is the Weave status, text is the external status name, and value is the external status id
            const { key, text, value, iconName } = statusItem;

            // Format the key, text, and value from the appointment statuses mapping object to lowercase and trim to ensure the comparison is accurate
            const externalStatusText = formatStringForComparison(text);
            const externalStatusValue = formatStringForComparison(value ?? '');
            const statusKey = formatStringForComparison(key ?? '');

            // Format the Weave and PMS statuses from the appointment to lowercase and trim to ensure the comparison is accurate
            const appointmentStatus = appointment
              ? formatStringForComparison(appointment.statusOfficeView ?? status ?? '')
              : AppointmentStatusEnum.Unknown;

            const appointmentExternalStatus = formatStringForComparison(appointment?.externalStatus ?? '');

            // if the external/PMS status (text) already exist in the mapping object, display `${key} / ${text} - ${value}`. This is needed if the external/PMS status already exists, but the value (external/PMS status id) is different, e.g. "Confirmed / Confirmed - 1".
            const statusCounts = getExternalStatusCounts(appointmentStatuses);

            const getStatusLabel = () => {
              if (statusKey && statusCounts[externalStatusText] > 1) {
                return `${key} / ${text} - ${value}`;
              }
              return `${key} / ${text}`;
            };

            const weaveStatusMatches = appointmentStatus === statusKey || statusKey === externalStatusText;

            // The `externalStatus` in the appointment can be an id OR the text of the PMS status.
            const externalStatusMatches = !!appointmentExternalStatus
              ? externalStatusValue === appointmentExternalStatus || appointmentExternalStatus === externalStatusText
              : true;

            const selectedStatus = weaveStatusMatches && externalStatusMatches;

            return (
              <PopoverMenuItem
                key={`${key}-${value}`}
                value={value}
                css={{
                  borderLeft: selectedStatus ? `4px solid ${theme.colors.primary50}` : `4px solid transparent`,
                }}
                onClick={() => updateStatus(statusItem)}
              >
                {iconName && <Icon name={iconName} />}
                {getStatusLabel()}
              </PopoverMenuItem>
            );
          })}
        </PopoverMenu>
      )}
      {isAppointmentDataLoading || isAppointmentDataRefetching || isAppointmentStatusLoading || isLoadingDataSources ? (
        <SpinningLoader size='xs' />
      ) : (
        <>
          {!!statusChip && (
            <NakedButton {...getPopoverTriggerProps()}>
              <Chip
                variant={statusChip?.variant}
                css={{ width: 'auto', maxWidth: '200px' }}
                leftElement={
                  statusChip?.iconName && <Icon name={statusChip?.iconName} css={{ color: 'currentcolor' }} />
                }
              >
                {statusChip.key ? statusChip.key : statusChip.text}
              </Chip>
            </NakedButton>
          )}
        </>
      )}
    </>
  );
};

const AppointmentDetailItem = ({
  label,
  value,
  showTruncatedText = false,
}: {
  label: string;
  value: string | ReactElement;
  showTruncatedText?: boolean;
}) => {
  const isStringValue = typeof value === 'string';
  return (
    <div css={{ ...(showTruncatedText && { maxWidth: 150 }) }}>
      <Text as='dt' color='light' size='small'>
        {label}
      </Text>
      {!isStringValue && value}
      {isStringValue && showTruncatedText && (
        <Truncated as='dd' css={{ fontWeight: theme.font.weight.bold }}>
          {value || '-'}
        </Truncated>
      )}
      {isStringValue && !showTruncatedText && (
        <Text as='dd' weight='bold'>
          {value || '-'}
        </Text>
      )}
    </div>
  );
};

interface AppointmentDetailsProps {
  appointmentDetails: ScheduleEventAppointmentDetails;
  locationName: string;
  locationId: string;
  appointmentId: string;
}

export const AppointmentDetails = ({
  appointmentDetails: { type, status, date, practitioner, duration },
  locationName,
  locationId,
  appointmentId,
}: AppointmentDetailsProps) => {
  const { t } = useTranslation('scheduleCalendarEvents');
  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();

  return (
    <section css={{ marginBottom: theme.spacing(5) }}>
      <header css={headerStyle}>
        <Heading level={2}>{t('Appointment')}</Heading>
        {status &&
          (isScheduleV3FlagEnabled ? (
            <EventCardAppointmentStatusChip locationId={locationId} appointmentId={appointmentId} />
          ) : (
            <AppointmentStatus locationId={locationId} appointmentId={appointmentId} status={status} />
          ))}
      </header>
      <dl css={dlStyle}>
        <AppointmentDetailItem label={t('Date')} value={date} />
        <AppointmentDetailItem label={t('Time')} value={duration} />
        <AppointmentDetailItem label={t('Appointment Type')} value={<AppointmentType type={type} />} />
        <AppointmentDetailItem label={t('Practitioner')} value={practitioner} showTruncatedText />
        <AppointmentDetailItem
          label={t('Location')}
          value={
            <dd>
              <Chips.LocationChip>{locationName}</Chips.LocationChip>
            </dd>
          }
        />
      </dl>
    </section>
  );
};

const headerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  marginBottom: theme.spacing(3),
});

const dlStyle = css({
  display: 'grid',
  gridTemplateColumns: '0fr 0fr 1fr',
  gridColumnGap: theme.spacing(4),
  gridRowGap: theme.spacing(3),
  whiteSpace: 'nowrap',
});
