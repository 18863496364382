import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { FormRow, Text, Chip, StatusSwitcherVariants, NakedButton } from '@frontend/design-system';

interface TaskFormRowProps {
  children: ReactNode;
  label: string;
  icon: IconName;
  placeholder: string;
  buttonIconName: IconName;
  onClickChip?: () => void;
  value: string;
  getTriggerProps?: () => any;
  trackingId?: string;
  onRemoveClick?: () => void;
  disabled?: boolean;
  variant?: StatusSwitcherVariants;
  isRemovable?: boolean;
  trackingIds?: {
    removeButton?: string;
    chipButton?: string;
  };
}

export const TaskFormRow = ({
  children,
  label,
  icon,
  placeholder,
  buttonIconName,
  value,
  onRemoveClick,
  disabled = false,
  variant = 'neutral',
  trackingIds,
  isRemovable = true,
  ...rest
}: TaskFormRowProps) => {
  const triggerProps = rest?.getTriggerProps && rest?.getTriggerProps();

  const rightElement = value ? (
    isRemovable && onRemoveClick ? (
      <NakedButton
        onClick={(e) => {
          e.stopPropagation();
          onRemoveClick();
        }}
        trackingId={trackingIds?.removeButton}
      >
        <Icon name='x-small' size={12} />
      </NakedButton>
    ) : undefined
  ) : (
    <Icon name='caret-down-small' size={16} />
  );

  return (
    <FormRow css={rowStyle} cols={[30]}>
      <div css={labelContainerStyle}>
        <Icon name={icon} size={16} />
        <Text size='medium' weight='bold'>
          {label}
        </Text>
      </div>
      <Chip.StatusSwitcher
        color='gray'
        disabled={disabled}
        variant={variant}
        isRemovable
        leftElement={<Icon name={buttonIconName} size={16} />}
        rightElement={rightElement}
        trackingId={trackingIds?.chipButton}
        css={chipStyle}
        {...rest}
        {...triggerProps}
      >
        {value || placeholder}
      </Chip.StatusSwitcher>
      {children}
    </FormRow>
  );
};

const rowStyle = css`
  margin-right: 0;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
`;

const labelContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(0.75, 0, 0.75, 0)};
`;

const chipStyle = css`
  max-width: 100%;
`;
