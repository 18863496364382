import { SearchResults } from '@weave/schema-gen-ts/dist/schemas/search/history/v1/search.pb';
import { Entity } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/entity.pb';
import { SessionSearches } from '../types';

export const convertSessionSearchesToResults = (sessionSearches: SessionSearches): SearchResults[] => {
  return Object.entries(sessionSearches).map<SearchResults>(([entity, value]) => ({
    entity: entity as Entity,
    primaryKeys: value.results ?? [],
  }));
};
