import { Frame, ImageSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { useComposerSection, useSettingsForm } from '../../hooks';
import { convertValueToPixels } from '../../utils';
import { CountSetting, FrameSetting, ImageSetting, PaddingSetting, SettingsPanel } from './atoms';

export const ImageSettings = () => {
  const { t } = useTranslation('content-composer');

  const { props } = useComposerSection<ImageSection>();

  const { getFieldProps } = useSettingsForm<ImageSection>({
    corners: { type: 'radio' },
    frame: {
      alignment: { type: 'radio' },
      backgroundColor: { type: 'text' },
      paddingTop: { type: 'text' },
      paddingBottom: { type: 'text' },
      paddingLeft: { type: 'text' },
      paddingRight: { type: 'text' },
    },
    height: { type: 'text' },
    imageCount: { type: 'radio' },
    imageSpacing: { type: 'text' },
  });

  return (
    <SettingsPanel title={t('Image Styling')}>
      <CountSetting {...{ ...getFieldProps<number>('imageCount'), value: (props.imageCount || 1).toString() }} />
      <ImageSetting
        cornersFieldProps={getFieldProps<string>('corners')}
        heightFieldProps={getFieldProps<string>('height')}
      />
      <FrameSetting {...getFieldProps<Frame>('frame', 'backgroundColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps<Frame>('frame', 'paddingTop')}
        paddingBottomFieldProps={getFieldProps<Frame>('frame', 'paddingBottom')}
        paddingLeftFieldProps={getFieldProps<Frame>('frame', 'paddingLeft')}
        paddingRightFieldProps={getFieldProps<Frame>('frame', 'paddingRight')}
        imageSpacingFieldProps={{
          ...getFieldProps<number>('imageSpacing'),
          disabled: Number(props.imageCount) < 2,
          value: convertValueToPixels(props.imageSpacing || 0),
        }}
        isLast
      />
    </SettingsPanel>
  );
};
