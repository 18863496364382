import { useAppScopeStore } from '@frontend/scope';

export const useIsMulti = () => {
  const { accessibleLocationData, selectedLocationIds = [], isSingleTypeScope } = useAppScopeStore();

  /**
   * WARNING
   *
   * I made major changes here... probably need to check.
   */

  return {
    isMulti: !isSingleTypeScope,
    parentId: accessibleLocationData[selectedLocationIds[0]]?.groupId,
  };
};
