import { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { CreateResponse } from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup_service.pb';
import { CallGroupApi, CallGroupTypes } from '@frontend/api-call-group';
import { ForwardingNumberTypes } from '@frontend/api-forwarding-number';
import { AssignDeviceTypes } from '@frontend/assign-device-api';
import { scopedFilterAndSort } from '@frontend/filters';
import { i18next, useTranslation } from '@frontend/i18n';
import { useInvalidateQueries } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import {
  Chip,
  ContentLoader,
  InfoRoundIconSmall,
  ModalControlModalProps,
  PrimaryButton,
  SearchField,
  SecondaryButton,
  Table,
  Tabs,
  Text,
  useAlert,
  useFormField,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { trackingId } from '../../tracking';
import { PhoneSettingsScopedLocationFilterMenu } from './index-table';

interface Props {
  callForwardingNumbers?: ForwardingNumberTypes.FwdNumberModel[];
  callGroup: CreateResponse;
  setCallGroup?: Dispatch<SetStateAction<CreateResponse>>;
  devices?: CallGroupTypes.SipProfileType[];
  locationsNames: Record<string, string>;
  ringtone: string;
  modalProps?: ModalControlModalProps;
  setSelectedDevices?: (value: string[]) => void;
  setSelectedMobiles?: (value: string[]) => void;
  setSelectedForwardingNumbers?: (value: string[]) => void;
  subtitle: string;
  tabsToHide?: AssignDeviceTypes.AssignDevicesTab[];
  title?: string;
  isMultiLocation?: boolean;
}

const defaultTabs: AssignDeviceTypes.AssignDevicesTab[] = ['devices', 'mobile', 'forwarding'];

const getTabs = (tabsToHide: AssignDeviceTypes.AssignDevicesTab[]) => {
  if (tabsToHide.length) {
    return defaultTabs.filter((tab) => !tabsToHide.includes(tab));
  } else {
    return defaultTabs;
  }
};

const categorizeDevices = (devices: CallGroupTypes.SipProfileType[], locationsNames: Record<string, string>) => {
  const deviceCategories = devices.reduce(
    (acc, { device, sipProfileId, name }) => {
      if (sipProfileId && device?.deviceType) {
        const deviceTypeKey = device.deviceType.toLowerCase() as 'desk_phone' | 'mobile_app' | 'softphone';

        return {
          ...acc,
          [deviceTypeKey]: [
            ...(acc[deviceTypeKey] || []),
            {
              data: device,
              id: sipProfileId,
              identifier:
                device.deviceType === 'DESK_PHONE'
                  ? device.macAddress
                  : device.deviceType !== 'SOFTPHONE'
                  ? device.uniqueKey?.substring(0, 20)
                  : '—',
              location: {
                id: device.location?.name || 'NA',
                name: device.location?.name ? locationsNames[device.location.name] || i18next.t('NA') : i18next.t('NA'),
              },
              name:
                device.deviceType === 'MOBILE_APP'
                  ? device.deviceName || '-'
                  : device.deviceType === 'DESK_PHONE' || device.deviceType === 'SOFTPHONE'
                  ? name || '-'
                  : name,
              type: 'device',
            },
          ],
        };
      }
      return acc;
    },
    {
      desk_phone: [],
      mobile_app: [],
      softphone: [],
    } as {
      desk_phone: AssignDeviceTypes.TabPanelRow[];
      mobile_app: AssignDeviceTypes.TabPanelRow[];
      softphone: AssignDeviceTypes.TabPanelRow[];
    }
  );

  return {
    ...deviceCategories,
    device: [...deviceCategories.desk_phone, ...deviceCategories.softphone],
  };
};

export const AssignDeviceContent = ({
  callForwardingNumbers = [],
  callGroup,
  devices = [],
  locationsNames,
  ringtone,
  modalProps,
  setSelectedMobiles,
  setSelectedDevices,
  setSelectedForwardingNumbers,
  subtitle,
  tabsToHide = [],
  title,
  isMultiLocation = false,
}: Props) => {
  const alert = useAlert();

  const { t } = useTranslation('phone', { keyPrefix: 'call-groups' });
  const invalidateQueries = useInvalidateQueries();
  const [tabs, setTabs] = useState<AssignDeviceTypes.AssignDevicesTab[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>();
  const { settingsTenantLocation, globalAvailableLocationIds } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'globalAvailableLocationIds'
  );
  const [selectedForwardingNumberIds, setSelectedForwardingNumberIds] = useState<string[]>([]);
  const [selectedRowsMobile, setSelectedRowsMobile] = useState<string[]>([]);
  const [selectedRowsDevice, setSelectedRowsDevice] = useState<string[]>([]);
  const selectionLimit = 30;
  const deviceSearchField = useFormField({ type: 'text' });
  const mobileSearchField = useFormField({ type: 'text' });
  const forwardingNumbersearchField = useFormField({ type: 'text' });

  const [filteredLocationsForDevices, setFilteredLocationsForDevices] = useState<string[]>([]);
  const [filteredLocationsForMobiles, setFilteredLocationsForMobiles] = useState<string[]>([]);
  const [filteredLocationsForForwarding, setFilteredLocationsForForwarding] = useState<string[]>([]);

  const filteredAvailableDevices = useMemo(() => {
    const devicesFilteredByLocations = scopedFilterAndSort({
      data: devices,
      scopeIds: globalAvailableLocationIds,
      filteredScopeIds: filteredLocationsForDevices,
      scopeIdAccessor: (device) => (device.device?.location?.locationId ? [device.device?.location?.locationId] : []),
    });

    return devicesFilteredByLocations.filter(({ name }) =>
      name?.toLowerCase().includes(deviceSearchField.value.toLowerCase())
    );
  }, [deviceSearchField.value, devices, filteredLocationsForDevices, globalAvailableLocationIds]);

  const filteredAvailableMobiles = useMemo(() => {
    const devicesFilteredByLocations = scopedFilterAndSort({
      data: devices,
      scopeIds: globalAvailableLocationIds,
      filteredScopeIds: filteredLocationsForMobiles,
      scopeIdAccessor: (device) => (device.device?.location?.locationId ? [device.device?.location?.locationId] : []),
    });

    return devicesFilteredByLocations.filter(({ device }) =>
      device?.deviceName?.toLowerCase().includes(mobileSearchField.value.toLowerCase())
    );
  }, [mobileSearchField.value, devices, filteredLocationsForMobiles, globalAvailableLocationIds]);

  const filteredAvailableForwardingNumbers = useMemo(() => {
    const forwardingNumbersFilteredByLocations = scopedFilterAndSort({
      data: callForwardingNumbers,
      scopeIds: globalAvailableLocationIds,
      filteredScopeIds: filteredLocationsForForwarding,
      scopeIdAccessor: (forwardingNumber) => forwardingNumber.Labels?.map(({ Value }) => Value) ?? [],
    });

    return forwardingNumbersFilteredByLocations.filter((forwardingNumber) =>
      forwardingNumber.name.toLowerCase().includes(forwardingNumbersearchField.value.toLowerCase())
    );
  }, [
    forwardingNumbersearchField.value,
    callForwardingNumbers,
    filteredLocationsForForwarding,
    globalAvailableLocationIds,
  ]);

  const getTabTitle = (tab: AssignDeviceTypes.AssignDevicesTab) => {
    if (tab === 'devices') return t('Devices');
    if (tab === 'forwarding') return t('Forwarding Numbers');
    if (tab === 'mobile') return t('Mobile App');

    return '';
  };

  const preSelection = callGroup.devices.reduce<{
    forwardingNumbers: string[];
    sipProfiles: string[];
    mobilePhones: string[];
  }>(
    (acc, device) => {
      if ('forwardingNumber' in device) {
        acc.forwardingNumbers.push(device.forwardingNumber.forwardingNumberId);
      }
      if ('deskPhone' in device) {
        acc.sipProfiles.push(device.deskPhone.sipProfileId);
      }
      if ('mobilePhone' in device) {
        acc.mobilePhones.push(device.mobilePhone.sipProfileId);
      }
      if ('softPhone' in device) {
        acc.sipProfiles.push(device.softPhone.sipProfileId);
      }
      return acc;
    },
    { forwardingNumbers: [], sipProfiles: [], mobilePhones: [] }
  );

  const categorizedDevices = useMemo(
    () => categorizeDevices(filteredAvailableDevices, locationsNames),
    [filteredAvailableDevices, locationsNames, t]
  );
  const categorizedMobiles = useMemo(
    () => categorizeDevices(filteredAvailableMobiles, locationsNames),
    [filteredAvailableMobiles, locationsNames, t]
  );

  const formatForwardingNumbers: AssignDeviceTypes.TabPanelRow[] = useMemo(
    () =>
      filteredAvailableForwardingNumbers.reduce(
        (data, forwardingNumber) => [
          ...data,
          {
            data: forwardingNumber,
            id: forwardingNumber.id,
            identifier: formatPhoneNumber(forwardingNumber.number),
            location: {
              id: (forwardingNumber.Labels || [])[0]?.Value,
              name: locationsNames[(forwardingNumber.Labels || [])[0]?.Value] || t('NA'),
            },
            name: forwardingNumber.name,
            type: 'forwarding_number',
          },
        ],
        [] as AssignDeviceTypes.TabPanelRow[]
      ),
    [filteredAvailableForwardingNumbers]
  );

  useEffect(() => {
    const devicesSipProfiles = preSelection.sipProfiles.filter((device) => device);
    setSelectedRowsDevice(devicesSipProfiles);
    const mobileSipProfiles = preSelection.mobilePhones.filter((device) => device);
    setSelectedRowsMobile(mobileSipProfiles);
    const forwardingNumberIds = preSelection.forwardingNumbers.filter((device) => device);
    setSelectedForwardingNumberIds(forwardingNumberIds);
  }, [callGroup]);

  const handleSelectionMobile = (selectedSipProfileIds: string[]) => {
    setSelectedMobiles && setSelectedMobiles(selectedSipProfileIds);
  };

  const handleSelectionDevice = (selectedSipProfileIds: string[]) => {
    setSelectedDevices && setSelectedDevices(selectedSipProfileIds);
  };

  const handleSelectionForwarding = (selectedForwardingNumbers: string[]) => {
    setSelectedForwardingNumbers && setSelectedForwardingNumbers(selectedForwardingNumbers);
  };
  const combinedSelectedIds = [...selectedRowsMobile, ...selectedRowsDevice, ...selectedForwardingNumberIds];
  const combinedSelection = [
    ...preSelection.forwardingNumbers,
    ...preSelection.sipProfiles,
    ...preSelection.mobilePhones,
  ];
  const selectionLimitExceeded = combinedSelectedIds.length > selectionLimit;

  const hasChanges = (): boolean => {
    return (
      combinedSelectedIds.length !== combinedSelection.length ||
      !combinedSelectedIds.every((id) => combinedSelection.includes(id))
    );
  };

  const handleSave = async () => {
    setIsSaving(true);

    const idsToRemove = combinedSelection.filter((id) => !combinedSelectedIds.includes(id));
    const idsToAssign = combinedSelectedIds.filter((id) => !combinedSelection.includes(id));

    const payload = {
      callGroupId: callGroup.callGroupId,
      delayStart: 0,
      ringtone: ringtone,
      timeout: 18000,
    };

    const removePromises = idsToRemove.map(async (id: string) => {
      const deviceToRemove = callGroup.devices.find((device) => {
        if ('forwardingNumber' in device) {
          return device.forwardingNumber?.forwardingNumberId === id;
        }
        if ('deskPhone' in device) {
          return device.deskPhone?.sipProfileId === id;
        }
        if ('mobilePhone' in device) {
          return device.mobilePhone?.sipProfileId === id;
        }
        if ('softPhone' in device) {
          return device.softPhone?.sipProfileId === id;
        }

        return false;
      });
      if (deviceToRemove) {
        return CallGroupApi.removeDevicesFromCallGroup({ callLegId: deviceToRemove.callLegId });
      } else {
        return null;
      }
    });

    const assignPromises = idsToAssign.map(async (id: string) => {
      return CallGroupApi.assignDeviceToCallGroup({
        ...payload,
        sipProfileId: id,
        forwardingNumberId: selectedForwardingNumberIds.includes(id) ? id : undefined,
      });
    });

    const results = await Promise.all(
      [...removePromises, ...assignPromises].map((promise) =>
        promise.then(
          (value) => ({ value, resolved: true }),
          (error) => ({ error, resolved: false })
        )
      )
    );

    const errors = results.filter((result) => !result.resolved);
    if (errors.length) {
      alert.error(`${errors.length} devices failed to be assigned or removed from the call group.`);
    } else {
      alert.success('Call group updated successfully');
    }
    modalProps?.onClose();
    setIsSaving(false);
    invalidateQueries(queryKeys.callGroup(callGroup.callGroupId));
    invalidateQueries(queryKeys.listCallGroups(settingsTenantLocation?.phoneTenantId ?? ''));
    resetState(false);
  };

  const resetState = (reset: boolean) => {
    if (reset) {
      setSelectedRowsMobile([]);
      setSelectedRowsDevice([]);
      setSelectedForwardingNumberIds([]);
    }
  };

  useEffect(() => {
    setTabs(getTabs(tabsToHide));
  }, [tabsToHide]);

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <header>
        <h1>{title}</h1>
        <Text weight='regular' color='light' textAlign='left'>
          {subtitle}
        </Text>
      </header>
      <div>
        {tabs.length && (
          <>
            <Tabs initialTab={tabs[0]}>
              <Tabs.Bar>
                {tabs.map((tab) => (
                  <Tabs.Tab controls={`${tab}-panel`} id={tab} key={`${tab}-tab`}>
                    {getTabTitle(tab)}
                  </Tabs.Tab>
                ))}
              </Tabs.Bar>

              <Tabs.Panel controller='devices' id='devices-panel'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(3),
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: theme.spacing(4) }}>
                    {isMultiLocation && settingsTenantLocation ? (
                      <PhoneSettingsScopedLocationFilterMenu
                        selectedOptions={filteredLocationsForDevices}
                        onChange={setFilteredLocationsForDevices}
                      />
                    ) : null}
                    <SearchField {...deviceSearchField} name='device-search' />
                  </div>
                  <Table
                    isPaginated
                    rowActions={{
                      onRowSelect: (row) => {
                        const newDevices = [...selectedRowsDevice];
                        if (!row.isSelected) {
                          newDevices.push(row.original.id ?? '');
                        } else {
                          const removalIndex = newDevices.findIndex((device) => device === row.original.id);
                          if (removalIndex > -1) {
                            newDevices.splice(removalIndex, 1);
                          }
                        }
                        setSelectedRowsDevice(newDevices);
                        handleSelectionDevice(newDevices);
                      },
                    }}
                    rowSelectionConfig={{
                      hideBulkSelection: true,
                      initialState: Object.fromEntries(
                        callGroup.devices
                          .flatMap((device) => [
                            ['deskPhone' in device && device.deskPhone?.sipProfileId, true],
                            ['softPhone' in device && device.softPhone?.sipProfileId, true],
                          ])
                          .filter(([id]) => id)
                      ),
                    }}
                    isSelectable
                    fullHeight
                    fullHeightConfig={{
                      maxHeight: 300,
                      minHeight: 200,
                    }}
                    data={categorizedDevices.device}
                    colConfig={[
                      {
                        accessor: ({ name }) => name,
                        id: 'deviceName',
                        Header: t('Device Name'),
                      },
                      {
                        accessor: ({ identifier }) => identifier,
                        id: 'macAddress',
                        Header: t('MAC Address'),
                        width: 150,
                      },
                      {
                        id: 'location',
                        Header: t('Location'),
                        accessor: ({ location }) =>
                          location.id === 'NA' ? '--' : <Chip.SingleChip>{location.id}</Chip.SingleChip>,
                        omit: !isMultiLocation,
                        width: 150,
                      },
                    ]}
                    uniqueRowId={(row) => row.id ?? ''}
                  />
                </div>
              </Tabs.Panel>

              <Tabs.Panel controller='mobile' id='mobile-panel'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(3),
                  }}
                >
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto minmax(0, 300px)', gap: theme.spacing(4) }}>
                    {isMultiLocation && settingsTenantLocation ? (
                      <PhoneSettingsScopedLocationFilterMenu
                        selectedOptions={filteredLocationsForMobiles}
                        onChange={setFilteredLocationsForMobiles}
                      />
                    ) : null}
                    <SearchField css={{ justifySelf: 'end' }} {...mobileSearchField} name='mobile-search' />
                  </div>
                  <Table
                    isPaginated
                    rowActions={{
                      onRowSelect: (row) => {
                        const newMobiles = [...selectedRowsMobile];
                        if (!row.isSelected) {
                          newMobiles.push(row.original.id ?? '');
                        } else {
                          const removalIndex = newMobiles.findIndex((device) => device === row.original.id);
                          if (removalIndex > -1) {
                            newMobiles.splice(removalIndex, 1);
                          }
                        }
                        setSelectedRowsMobile(newMobiles);
                        handleSelectionMobile(newMobiles);
                      },
                    }}
                    rowSelectionConfig={{
                      hideBulkSelection: true,
                      initialState: Object.fromEntries(
                        callGroup.devices.map((device) => [
                          'mobilePhone' in device && device.mobilePhone?.sipProfileId,
                          true,
                        ])
                      ),
                    }}
                    isSelectable
                    fullHeight
                    fullHeightConfig={{
                      maxHeight: 300,
                      minHeight: 200,
                    }}
                    data={categorizedMobiles.mobile_app}
                    colConfig={[
                      {
                        accessor: ({ name }) => name,
                        id: 'deviceName',
                        Header: t('Device Name'),
                      },
                      {
                        accessor: ({ identifier }) => identifier,
                        id: 'sipName',
                        Header: t('SIP Name'),
                        width: 150,
                      },
                      {
                        id: 'location',
                        Header: t('Location'),
                        accessor: ({ location }) =>
                          location.id === 'NA' ? '--' : <Chip.SingleChip>{location.id}</Chip.SingleChip>,
                        omit: !isMultiLocation,
                        width: 150,
                      },
                    ]}
                    uniqueRowId={(row) => row.id ?? ''}
                  />
                </div>
              </Tabs.Panel>
              <Tabs.Panel controller='forwarding' id='forwarding-panel'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                    marginTop: theme.spacing(3),
                  }}
                >
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto minmax(0, 300px)', gap: theme.spacing(4) }}>
                    {isMultiLocation && settingsTenantLocation ? (
                      <PhoneSettingsScopedLocationFilterMenu
                        selectedOptions={filteredLocationsForForwarding}
                        onChange={setFilteredLocationsForForwarding}
                      />
                    ) : null}
                    <SearchField
                      css={{ justifySelf: 'end' }}
                      {...forwardingNumbersearchField}
                      name='forwarding-search'
                    />
                  </div>
                  <Table
                    isPaginated
                    rowActions={{
                      onRowSelect: (row) => {
                        const newForwardingNumbers = [...selectedForwardingNumberIds];
                        if (!row.isSelected) {
                          newForwardingNumbers.push(row.original.id ?? '');
                        } else {
                          const removalIndex = newForwardingNumbers.findIndex(
                            (forwardingNumber) => forwardingNumber === row.original.id
                          );
                          if (removalIndex > -1) {
                            newForwardingNumbers.splice(removalIndex, 1);
                          }
                        }
                        setSelectedForwardingNumberIds(newForwardingNumbers);
                        handleSelectionForwarding(newForwardingNumbers);
                      },
                    }}
                    rowSelectionConfig={{
                      hideBulkSelection: true,
                      initialState: Object.fromEntries(
                        callGroup.devices.map((device) => [
                          'forwardingNumber' in device && device.forwardingNumber?.forwardingNumberId,
                          true,
                        ])
                      ),
                    }}
                    isSelectable
                    fullHeight
                    fullHeightConfig={{
                      maxHeight: 300,
                      minHeight: 200,
                    }}
                    data={formatForwardingNumbers}
                    colConfig={[
                      {
                        accessor: ({ name }) => name,
                        id: 'deviceName',
                        Header: t('Device Name'),
                      },
                      {
                        accessor: ({ identifier }) => identifier,
                        id: 'phoneNumber',
                        Header: t('Phone Number'),
                        width: 150,
                      },
                      {
                        id: 'location',
                        Header: t('Location'),
                        accessor: ({ location }) =>
                          location.name === 'NA' ? '--' : <Chip.SingleChip>{location.name}</Chip.SingleChip>,
                        omit: !isMultiLocation,
                        width: 150,
                      },
                    ]}
                    uniqueRowId={(row) => row.id ?? ''}
                  />
                </div>
              </Tabs.Panel>
            </Tabs>
          </>
        )}

        {selectionLimitExceeded && (
          <Text
            css={css`
              display: flex;
              gap: ${theme.spacing(1)};
            `}
            color='light'
          >
            <InfoRoundIconSmall size={18} />
            {t('You can only assign a maximum of {{selectionLimit}} devices to a call group.', { selectionLimit })}
          </Text>
        )}

        <ContentLoader show={isSaving} />
      </div>
      {!!modalProps?.show && (
        <div
          css={{
            marginTop: theme.spacing(3),
            display: 'flex',
            width: '300px',
            gap: theme.spacing(1),
            marginLeft: 'auto',
          }}
        >
          <SecondaryButton
            onClick={() => {
              resetState(true);
              modalProps.onClose();
            }}
            trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'cancel-assign-devices' })}
          >
            {t('Cancel')}
          </SecondaryButton>
          <PrimaryButton
            disabled={isSaving || selectionLimitExceeded || !hasChanges()}
            onClick={handleSave}
            trackingId={trackingId({ context: 'setting', feature: 'call-groups', details: 'save-assign-devices' })}
          >
            {t('Assign Devices')}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
