import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { IosMobileQrCode } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { getElectronShellDownloadUrl } from '@frontend/opt-in-to-2-0';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { shell } from '@frontend/shell-utils';
import { theme } from '@frontend/theme';
import { ButtonBar, Modal, ModalControlModalProps, Tabs, Text, TextLink } from '@frontend/design-system';
import { downloadSoftwareContainer } from '../style/download-module-style';

type DownloadSoftwareType = {
  modalProps: ModalControlModalProps;
};

enum MobileTabs {
  Apple = 'iOs',
  Android = 'android',
}

const appleAppDownloadUrl = 'https://apps.apple.com/us/app/weave-phone-text-and-chat/id1030476630';
const androidAppDownloadUrl = 'https://play.google.com/store/apps/details?id=com.weaveconnect&hl=en_US';

export const DownloadSoftwareModal = ({ modalProps }: DownloadSoftwareType) => {
  const { t } = useTranslation('software-download-modal');
  const [contentKey, setContentKey] = useState('');
  const [controlledTab, setControlledTab] = useState(MobileTabs.Apple);
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const showMobileDownload = shell.isShell;
  const showDesktopAppDownload = !showMobileDownload;

  useEffect(() => {
    if (isMobile) {
      setContentKey(controlledTab === MobileTabs.Apple ? t('Apple Mobile App') : t('Android Mobile App'));
    } else {
      setContentKey(
        controlledTab === MobileTabs.Apple
          ? t('Apple Mobile App or scan this code')
          : t('Android Mobile App or scan this code')
      );
    }

    return () => {
      setContentKey('');
    };
  }, [isMobile, controlledTab]);

  return (
    <Modal css={downloadSoftwareContainer} {...modalProps} maxWidth={600}>
      <Modal.Header>{showMobileDownload ? t('Download The Mobile App') : t('Download Weave App')}</Modal.Header>
      <Modal.Body>
        {!isMobile && showDesktopAppDownload && (
          <div className='download-software-is-mobile-section margin-divider flex-column'>
            <Text>
              {t(
                'Download the software onto each workstation in your business. If you need additional help, view our Weave Help article '
              )}
              <TextLink
                rel='noreferrer'
                target='_blank'
                href={'https://www.weavehelp.com/weavehelp/article/download-the-weave-app'}
                trackingId='download-software-weave-help-link'
              >
                {t('here')}
              </TextLink>
              .
            </Text>
            <ul css={{ marginTop: theme.spacing(2) }}>
              <li>
                <span>{t('Customize the app')}</span> {t('according to your preferences')}
              </li>
              <li>
                {t('Never miss an update with')} <span>{t('enhanced notifications')}</span>
              </li>
              <li>
                {t('Automatically receive the')} <span>{t('latest features')}</span> {t('and improvements')}
              </li>
            </ul>
            <ButtonBar css={{ justifyContent: 'flex-start', gap: theme.spacing(3) }}>
              <TextLink
                rel='noreferrer'
                target='_blank'
                href={getElectronShellDownloadUrl()}
                trackingId='download-desktop-app-link'
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: ${theme.colors.primary50};
                  border-radius: ${theme.borderRadius.small};
                  color: ${theme.colors.white};
                  padding: ${theme.spacing(1, 2)};
                  font-weight: ${theme.font.weight.bold};
                  height: 32px;
                  font-size: ${theme.fontSize(14)};
                  &:hover {
                    background: ${theme.colors.primary60};
                    text-decoration: none;
                    box-shadow: ${theme.shadows.floating};
                  }
                `}
              >
                {t('Download Desktop App')}
              </TextLink>
            </ButtonBar>
          </div>
        )}
        {showMobileDownload && (
          <div className='download-software-mobile-app-section flex-column'>
            <Text className='light-text'>
              {t(
                "Access Weave's essential tools like phones, messaging, scheduling, and payments from anywhere with the Weave Mobile App."
              )}
            </Text>
            <Tabs controlledTab={controlledTab} onChange={(id) => setControlledTab(id as MobileTabs)}>
              <Tabs.Bar>
                <Tabs.Tab
                  id={MobileTabs.Apple}
                  controls={`${MobileTabs.Apple}-panel`}
                  data-trackingid={`${MobileTabs.Apple}-download-software-tab`}
                >
                  {t('iOS')}
                </Tabs.Tab>
                <Tabs.Tab
                  id={MobileTabs.Android}
                  controls={`${MobileTabs.Android}-panel`}
                  data-trackingid={`${MobileTabs.Android}-download-software-tab`}
                >
                  {t('Android')}
                </Tabs.Tab>
              </Tabs.Bar>
            </Tabs>
            <Text className={isMobile ? 'medium-margin-tb mobile flex-column' : 'medium-margin-tb'}>
              <TextLink
                rel='noreferrer'
                target='_blank'
                href={controlledTab === MobileTabs.Apple ? appleAppDownloadUrl : androidAppDownloadUrl}
                trackingId={`${controlledTab}-os-download-mobile-app-link`}
              >
                {t('Click here')}
              </TextLink>{' '}
              {t('to download the')} {contentKey}
            </Text>
            <div className={isMobile ? 'mobile' : ''}>
              <img src={IosMobileQrCode} alt='QR Code' />
            </div>
          </div>
        )}
        {showMobileDownload && (
          <div
            className={
              isMobile
                ? 'mobile download-software-footer-section margin-divider'
                : 'download-software-footer-section margin-divider'
            }
          >
            <Icon name='info-badge' color='primary' css={{ minWidth: 24 }} />
            <div>
              {t('Mobile app features may differ in availability from the Desktop App and Weave Portal. See the')}{' '}
              <TextLink
                rel='noreferrer'
                target='_blank'
                href='https://www.weavehelp.com/hc/en-us/articles/15312685850267-'
                trackingId='mobile-app-features-link'
              >
                {t('Getting to Know the Weave Mobile App')}
              </TextLink>{' '}
              {t('article for more information.')}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
