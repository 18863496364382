import { MouseEvent } from 'react';
import { createCustomEventSystem } from '@frontend/event';

export type SelectableIcons =
  | 'location'
  | 'notifications'
  | 'profile'
  | 'chat'
  | 'taskTray'
  | 'multiLocations'
  | 'quickfill'
  | 'schedulePulse'
  | 'chatV2'
  | 'actions'
  | 'taskCenter';

interface TopBarCustomEvents {
  'set-selected-top-bar-tray': {
    icon: SelectableIcons;
    mouseEvent?: MouseEvent;
  };
}

const { triggerCustomEvent, useCustomEventListener: useTopBarCustomEventListener } =
  createCustomEventSystem<TopBarCustomEvents>();

/**
 * Dispatches a custom event to select top bar tray icon (which ultimately open panel as well).
 */
const dispatchTopBarTraySelectionEvent = (icon: SelectableIcons, mouseEvent?: MouseEvent) => {
  triggerCustomEvent('set-selected-top-bar-tray', { icon, mouseEvent });
};

export { dispatchTopBarTraySelectionEvent, useTopBarCustomEventListener };
