import { CountryCodes } from '@frontend/geography';
import { IntakeForm, IntakeFormLocation, UserAnswer } from './types';

export const mockIntakeForm = (props: Partial<IntakeForm> = {}): IntakeForm => ({
  isMultiForm: false,
  isSoftwareOnlyForm: false,
  hideScreeningQuestions: false,
  formStartedAt: null,
  formCompletedAt: null,
  isOfficeUnderConstruction: null,
  hasInternetAndComputers: null,
  hasItContact: null,
  businessName: '',
  businessPhone: '',
  shippingSameAsOtherAddress: null,
  shippingAddress: {
    postal: '12345',
    address1: '123 South St',
    address2: '',
    city: 'Lehi',
    state: 'UT',
    country: CountryCodes.USA,
    verified: true,
  },
  billingAddress: {
    postal: '12345',
    address1: '123 South St',
    address2: '',
    city: 'Lehi',
    state: 'UT',
    country: CountryCodes.USA,
    verified: true,
  },
  e911SameAsOtherAddress: null,
  e911Address: null,
  shippingMethod: '',
  isAtBusinessLocation: null,
  allWorkstationsEthernetConnected: UserAnswer.Unanswered,
  numWorkstationsMissingEthernet: null,
  hasPhonesAwayFromWorkstations: UserAnswer.Unanswered,
  numEthernetPortsNeedInstalled: null,
  routerName: '',
  routerModel: '',
  networkTestTimedOut: null,
  networkTestPing: '',
  networkTestJitter: '',
  networkTestDownload: '',
  networkTestUpload: '',
  networkTestFailedDisclaimer: null,
  phoneProviderServiceAddress: null,
  phoneAndInternetBundled: UserAnswer.Unanswered,
  otherServicesUsingPhoneLines: UserAnswer.Unanswered,
  softwareLatestDownloadAt: null,
  stepProgress: [],
  acceptedPhonePortLoa: false,
  estimatedPortDate: '',
  integrationName: '',
  officeConfiguration: [],
  salesforceOpportunityId: '',
  salesforceSchedulingContactId: '',
  businessOwnerContactEmail: '',
  primaryContactEmail: '',
  itContactEmail: '',
  routerContactEmail: '',
  firstLoggedInUser: '',
  phoneProviderBillImageData: [],
  portingBatchId: '',
  sectionProgress: [],
  portOrders: null,
  networkAuditId: '',
  skipPortingSection: null,
  isFormManuallyCompleted: false,
  debug: {
    skipSalesforceAction: true,
  },
  locationId: '00000000-0000-0000-0000-d00000000000',
  weaveFeatureInterests: [],
  ...props,
});

export const getMockLocations = (isMultiLocation: boolean): IntakeFormLocation[] => {
  if (isMultiLocation) {
    const locations = [
      {
        locationId: '00000000-0000-0000-0000-d00000000000',
        businessName: 'Demo Location 1',
        onboardingContactEmail: '',
        formStartedAt: '',
        formCompletedAt: '',
        isSoftwareOnlyForm: false,
        isMultiForm: true,
      },
      {
        locationId: '00000000-0000-0000-0000-d00000000001',
        businessName: 'Demo Location 2',
        onboardingContactEmail: '',
        formStartedAt: '',
        formCompletedAt: '',
        isSoftwareOnlyForm: false,
        isMultiForm: true,
      },
    ];

    return locations;
  } else {
    const locations = [
      {
        locationId: '00000000-0000-0000-0000-d00000000000',
        businessName: 'Demo Location',
        onboardingContactEmail: '',
        formStartedAt: '',
        formCompletedAt: '',
        isSoftwareOnlyForm: false,
        isMultiForm: false,
      },
    ];

    return locations;
  }
};
