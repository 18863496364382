import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { PersonsV3 } from '@frontend/api-person';
import { convertToCents } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import {
  CreateInvoiceForm,
  DropZoneOverlay,
  shouldShowModal,
  ThresholdModals,
  useCreateInvoiceForm,
  useCreatePaymentRequest,
} from '@frontend/person-invoice-create';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { getTodaysDate, useModalControl } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { useScopedTrackingIds } from '../../../hooks/use-scoped-tracking-ids';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { CreateStepBodyProps } from './create.types';

const styles = {
  body: css`
    margin-bottom: ${theme.spacing(2)};
  `,
};

export const CreateBody = ({
  primary,
  secondary,
  onGoBack,
  onCreateRequest,
  onClickSecondary,
}: CreateStepBodyProps) => {
  const { t } = useTranslation('payments');

  const queryClient = useQueryClient();

  const { person } = useCollectPaymentMultiContext();

  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const {
    createFormProps,
    dropZoneProps,
    resetFields,
    attachment,
    selectedPerson: V2SelectedPerson,
    skipAttachmentAuth,
  } = useCreateInvoiceForm();

  const {
    form: { values, isComplete },
  } = createFormProps;
  const { createPaymentRequest } = useCreatePaymentRequest({ locationId: values.locationId });
  const { modalProps: thresholdModalProps, triggerProps: thresholdTriggerProps } = useModalControl();

  const { primaryTrackingId, secondaryTrackingId, backTrackingId } = useScopedTrackingIds({
    primaryTrackingId: 'primary-btn',
    secondaryTrackingId: 'secondary-btn',
    backTrackingId: 'back-btn',
  });

  const goBack = () => {
    onGoBack();
  };

  const handleCreateRequest = async () => {
    await createPaymentRequest({
      personId: values.personId!,
      amount: convertToCents(Number(values.amount)),
      attachment,
      mobileNumber: V2SelectedPerson?.MobilePhone,
      memo: values.memo,
      providerName: values.provider,
      skipAttachmentAuth,
    }).then((invoice) => {
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(values.amount),
      });
      queryClient.invalidateQueries(invoicesQueryKey);
      onCreateRequest(invoice);
    });
  };

  useEffect(() => {
    if (person && !V2SelectedPerson) {
      createFormProps.setSelectedPerson(PersonsV3.PersonHelpers.convertPersonV3ToPerson(person));
    }
  }, [person, V2SelectedPerson]);

  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.create}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.create]}
      isValid={isComplete}
      footer={{
        primary: primary || {
          children: t('Create Request'),
          trackingId: primaryTrackingId,
        },
        secondary: secondary || {
          trackingId: secondaryTrackingId,
          children: t('Cancel'),
        },
        back: {
          children: t('Back'),
          trackingId: backTrackingId,
        },
        onClickSecondary: () => {
          resetFields();
          onClickSecondary();
        },
        onClickPrimary: () =>
          shouldShowModal(createFormProps.form.values.amount) ? thresholdTriggerProps.onClick() : handleCreateRequest(),
        onClickBack: goBack,
      }}
    >
      <div className='invoice-body' css={styles.body}>
        <DropZoneOverlay {...dropZoneProps}>
          <CreateInvoiceForm {...createFormProps} />
        </DropZoneOverlay>
      </div>
      <ThresholdModals
        {...thresholdModalProps}
        amount={createFormProps.form.values.amount}
        onConfirm={handleCreateRequest}
      />
    </StyledStep>
  );
};
