import { FC, useEffect } from 'react';
import { Icon } from '@frontend/icons';
import { useMatchMedia } from '@frontend/responsiveness';
import { IconButton, WIDTH_DIMS } from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { trayTopBarStyles, rotateCollapseButton } from './tray-top-bar.styles';

interface TrayTopBarProps {
  onClose: () => void;
}

export const TrayTopBar: FC<TrayTopBarProps> = ({ onClose }) => {
  const isSmallSpace = useMatchMedia({ maxWidth: WIDTH_DIMS.xlarge + 40 });

  const mainPanelView = useTeamChatSelector((ctx) => ctx.mainPanelView);
  const isNavExpanded = useTeamChatSelector((ctx) => ctx.isNavExpanded);
  const collapseNav = useTeamChatSelector((ctx) => ctx.collapseNav);
  const expandNav = useTeamChatSelector((ctx) => ctx.expandNav);

  const onClickExpand = () => {
    isNavExpanded ? collapseNav() : expandNav();
  };

  const onClosePanel = () => {
    onClose();
  };

  useEffect(() => {
    if (isSmallSpace) {
      collapseNav();
    }
  }, [isSmallSpace]);

  const mainPanelIsOpen = mainPanelView !== 'none';
  const isDisabled = !mainPanelIsOpen || isSmallSpace;

  return (
    <header css={[trayTopBarStyles, !mainPanelIsOpen && { justifyContent: 'space-between' }]}>
      <IconButton
        onClick={onClickExpand}
        css={!isNavExpanded && rotateCollapseButton}
        label=''
        disabled={isDisabled}
        trackingId='team-chat-2.0-expand-collapse-chat-list-button'
      >
        <Icon name='collapse-right' size={20} />
      </IconButton>
      <IconButton label='close' onClick={onClosePanel} trackingId='team-chat-2.0-close-team-chat-button'>
        <Icon name='x' size={20} />
      </IconButton>
    </header>
  );
};
