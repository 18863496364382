import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { stepValueStyle } from './styles';
import { getTimeIn12HrFormat } from './utils';

type DateTimeStepValueProps = {
  date?: string;
  timeRange?: string[];
};

export const DateTimeStepValue = ({ date = '', timeRange = ['', ''] }: DateTimeStepValueProps) => {
  const { t } = useTranslation('schedule-quick-fill');
  const hasValue = date || timeRange?.filter((time) => time);

  return (
    <div css={stepValueStyle}>
      {!hasValue ? (
        <Text css={{ fontSize: theme.fontSize(20) }} as='span'>
          {t('Select Date & Time')}
        </Text>
      ) : (
        <>
          <Text weight='bold'>{dayjs(date).format('MMMM D, YYYY')}</Text>
          &bull;
          <Text weight='regular' color='subdued'>
            {getTimeIn12HrFormat(date, timeRange[0])} - {getTimeIn12HrFormat(date, timeRange[1])}
          </Text>
        </>
      )}
    </div>
  );
};
