import { FC, useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useBuilderEngineStore } from '@frontend/forms-builder-engine';
import { useTranslation, Trans } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Text, TextLink } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../shared/constants';
import { usePmsSelector } from '../../../../../../../shared/hooks';
import { BuilderFieldDivisionCategories } from '../../../../../constants';
import { useMedicalHistoryFieldsInfo } from '../../../../../hooks';
import { useFormBuilderStore } from '../../../../../provider';
import CategoriesLayout from '../../categories-layout/categories-layout.component';
import { useFilteredCategories } from '../../hooks';
import NoCommonSourceMessage from './no-common-source-message/no-common-source-message.component';

const SyncableMedicalHistoryFields: FC = () => {
  const { t } = useTranslation('forms');
  const { navigate } = useSettingsNavigate();
  const [availableSources, setAvailableSources] = useState<FormsSourceTenants.Types.ModifiedSourceTenant[]>([]);
  const {
    primaryFieldElements,
    usedPrimaryFieldElements,
    setSourceIdForMedicalHistoryFields,
    sourceIdForMedicalHistoryFields,
    formsEnabledLocationIds,
  } = useFormBuilderStore([
    'primaryFieldElements',
    'usedPrimaryFieldElements',
    'setSourceIdForMedicalHistoryFields',
    'sourceIdForMedicalHistoryFields',
    'formsEnabledLocationIds',
  ]);
  const { sharedLocationIds } = useBuilderEngineStore(['sharedLocationIds']);
  const { formHasPrimaryMedicalHistoryFields } = useMedicalHistoryFieldsInfo();
  const { filteredCategories } = useFilteredCategories({
    categories: BuilderFieldDivisionCategories.MEDICAL_HISTORY_WITH_WRITEBACK,
  });
  const { PmsSelectorPopoverDialog, pmsSelectorPopoverDialogControls } = usePmsSelector({
    popoverDialogConfig: {
      placement: 'bottom-start',
    },
  });
  const {
    isLoadingMedicalConditionSources,
    getSourcesWithMedicalConditionsForSelectedLocations,
    getCommonSourcesWithMedicalConditionsForSelectedLocations,
  } = DigitalFormScopeHooks.useMedicalConditionSources();
  const shouldShowMedicalHistoryFields = availableSources.length > 0 && !!sourceIdForMedicalHistoryFields;

  const selectPMSHandler = useCallback(
    (newSource: FormsSourceTenants.Types.ModifiedSourceTenant) => {
      setSourceIdForMedicalHistoryFields(newSource.id);
    },
    [setSourceIdForMedicalHistoryFields]
  );

  // Get the list of sources with medical conditions for the selected locations.
  useEffect(() => {
    if (isLoadingMedicalConditionSources) {
      return;
    }

    const data =
      sharedLocationIds.length > 0
        ? getCommonSourcesWithMedicalConditionsForSelectedLocations(sharedLocationIds).commonSources
        : getSourcesWithMedicalConditionsForSelectedLocations(formsEnabledLocationIds);
    setAvailableSources(data);
  }, [
    formsEnabledLocationIds,
    isLoadingMedicalConditionSources,
    getSourcesWithMedicalConditionsForSelectedLocations,
    sharedLocationIds,
    getCommonSourcesWithMedicalConditionsForSelectedLocations,
  ]);

  // Pre-select the source if there is only one source available.
  useEffect(() => {
    if (availableSources.length === 1 && !sourceIdForMedicalHistoryFields) {
      selectPMSHandler(availableSources[0]);
    }
  }, [availableSources, sourceIdForMedicalHistoryFields]);

  if (!primaryFieldElements) {
    return null;
  }

  return (
    <div>
      <Trans t={t}>
        <Text color='subdued' size='medium'>
          Select the respective PMS below to populate allergies, medications, and medical conditions from your{' '}
          <TextLink
            size='medium'
            css={css`
              cursor: pointer;
            `}
            onClick={() => navigate({ to: '/digital-forms/medical-history' })}
            trackingId={pendoTags.formBuilder.categories.primaryFields.medicalHistory.goToSettings}
          >
            medical history setting
          </TextLink>
        </Text>
      </Trans>
      <br />

      {availableSources.length > 0 ? (
        <PmsSelectorPopoverDialog
          popoverDialogControls={pmsSelectorPopoverDialogControls}
          availableSources={availableSources}
          selectedSourceId={sourceIdForMedicalHistoryFields}
          onSelectSource={selectPMSHandler}
          isDisabled={formHasPrimaryMedicalHistoryFields}
        />
      ) : (
        <NoCommonSourceMessage sharedLocationIds={sharedLocationIds} />
      )}

      {shouldShowMedicalHistoryFields && (
        <CategoriesLayout
          categories={filteredCategories}
          fields={primaryFieldElements}
          type='primary'
          usedFields={usedPrimaryFieldElements}
        />
      )}
    </div>
  );
};

export default SyncableMedicalHistoryFields;
