import { useMemo } from 'react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { SubscriptionStatusForSelfUpgrade } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useQueryClient } from 'react-query';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { checkAggregateACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';
import { FeatureSubscriptionContextProps } from '../providers/FeatureSubscriptionProvider';

interface FeatureSubscriptionConfig extends FeatureSubscriptionContextProps {
  isFeatureActiveInAllLocations: boolean;
  isFeatureHiddenInAllLocations: boolean;
  isLoading: boolean;
}

export const useFeatureSubscriptionConfig = ({
  feature,
  locationIds,
  checkBundleUpgrade = true,
}: {
  feature: Feature;
  locationIds?: string[];
  checkBundleUpgrade?: boolean;
}): FeatureSubscriptionConfig => {
  const { selectedLocationIds: appScopeLocationIds } = useAppScopeStore();
  const { setCustomizationFlags } = useAppFlagStore();
  const queryClient = useQueryClient();

  const selectedLocationIds = locationIds || appScopeLocationIds;

  const { aclValuesByLocationId: locationWiseAdminAccess, aggregateValue: hasAdminAccessInAtLeastOne } = useMemo(() => {
    return checkAggregateACL(selectedLocationIds, Permission.MANAGE_ADD_ON_SUBSCRIPTIONS, 'any');
  }, [selectedLocationIds]);

  CustomizationFlagQueries.useMultiCustomizationFlags([appScopeLocationIds[0]], {
    enabled: !!(selectedLocationIds && appScopeLocationIds[0] && selectedLocationIds.includes(appScopeLocationIds[0])),
    onSuccess: (data) => {
      // Set customization flags in store for the selected location
      const selectedLocationFeatures = data.locationsFeatures?.[appScopeLocationIds[0]]?.features;
      if (!!selectedLocationFeatures) {
        setCustomizationFlags({ locationId: appScopeLocationIds[0], flags: selectedLocationFeatures });
      }
    },
  });

  const { data: selfUpgradeFreeTrialEligibilityData, isFetching: isLoadingSelfUpgradeData } =
    FreeTrialSubscriptionQueries.useSelfUpgradeOrFreeTrialEligibility({
      featureEnum: feature,
      locationIds: selectedLocationIds,
    });

  const {
    selfUpgradeData,
    canSelfSubscribe,
    isUpgradeEligible,
    isFreeTrialEligible,
    isUpgradeOrTrialEligible,
    isFeatureHiddenInAllLocations,
    isFeatureActiveInAllLocations,
  } = useMemo(() => {
    const selfUpgradeData = selfUpgradeFreeTrialEligibilityData?.eligibility || [];

    const isUpgradeEligible = selfUpgradeData.some(({ subscriptionStatus }) => {
      return subscriptionStatus !== SubscriptionStatusForSelfUpgrade.SUBSCRIPTION_STATUS_UNSPECIFIED;
    });

    const canSelfSubscribe = selfUpgradeData.some(({ subscriptionStatus }) => {
      return subscriptionStatus === SubscriptionStatusForSelfUpgrade.SUBSCRIBE;
    });

    const isFreeTrialEligible = selfUpgradeData.some(({ trialStatus }) => {
      return trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ELIGIBLE;
    });

    const isFeatureActiveInAllLocations = selfUpgradeData.every(({ isStateActive, trialStatus }) => {
      return isStateActive || trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ACTIVE;
    });
    const isFeatureHiddenInAllLocations = selfUpgradeData.every(({ isStateActive, trialStatus }) => {
      return !isStateActive && trialStatus !== FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ACTIVE;
    });

    return {
      selfUpgradeData,
      isUpgradeEligible,
      isFeatureHiddenInAllLocations,
      isFeatureActiveInAllLocations,
      isFreeTrialEligible,
      isUpgradeOrTrialEligible: isUpgradeEligible || isFreeTrialEligible,
      canSelfSubscribe,
    };
  }, [selfUpgradeFreeTrialEligibilityData?.eligibility]);

  const { data: bundleUpgradeEligibility, isFetching: isLoadingBundleUpgradeEligibility } =
    FreeTrialSubscriptionQueries.useGetBundleUpgradeEligibility(
      {
        locationIds: selectedLocationIds.slice(0, 1),
      },
      {
        // Remove single location check when bundle upgrade is supported for multiple locations
        enabled: checkBundleUpgrade && selectedLocationIds.length === 1 && (isUpgradeEligible || isFreeTrialEligible),
      }
    );

  const invalidateSubscriptionConfig = () => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => {
        return (
          queryKey.includes('customization-flags') ||
          ((selectedLocationIds ?? []).some((locationId) => queryKey.includes(locationId)) &&
            FreeTrialSubscriptionQueries.freeTrialSubscriptionQueryKeys.subscriptionBaseQuery.every((part) =>
              queryKey.includes(part)
            ))
        );
      },
    });
  };

  return {
    selectedLocationIds,
    feature,
    isUpgradeOrTrialEligible,
    isFreeTrialEligible,
    isUpgradeEligible,
    canSelfSubscribe,
    isFeatureHiddenInAllLocations,
    isFeatureActiveInAllLocations,
    isLoading: isLoadingSelfUpgradeData || isLoadingBundleUpgradeEligibility,
    invalidateSubscriptionConfig,
    selfUpgradeData,
    locationWiseAdminAccess,
    hasAdminAccessInAtLeastOne,
    isBundleUpgradeEligible: !!bundleUpgradeEligibility?.isBundleTrialEligible,
    recommendedBundle:
      bundleUpgradeEligibility?.recommendedBundle ||
      SalesforceAccountProductBundle.UNKNOWN_SALESFORCE_ACCOUNT_PRODUCT_BUNDLE,
  };
};
