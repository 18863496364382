import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils, paymentsSentry } from '@frontend/payments-hooks';
import { PaymentsTerminalController } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import { ContentLoader, Text, TextField, ValidatorFieldState, useForm, useAlert } from '@frontend/design-system';
import { RegisterTerminalModalSteps } from './hooks';
import {
  RegisterTerminalModalStepProps,
  RegisterTerminalStepModalWrapper,
  RegisterTerminalStep,
} from './register-terminal-modal-step';

const styles = {
  list: css`
    list-style-type: number;
    padding-left: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
    & > li:not(:first-of-type) {
      margin-top: ${theme.spacing(2)};
    }
    & > li:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  registrationForm: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin: ${theme.spacing(2, 0)};
  `,
};

export const GeneratePairingCode = (props: RegisterTerminalModalStepProps) => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalStepModalWrapper
      title={t('Generate a pairing code on your terminal')}
      nextStep={RegisterTerminalModalSteps.PairYourTerminal}
      Action={RegisterTerminalStep.Next}
      {...props}
    >
      <ol css={styles.list}>
        <li>{t('Follow the terminal instructions to generate a registration code and select Register')}</li>
        <li>{t('Open the admin options menu by swiping from the left side of the display screen to the right')}</li>
        <li>{t('Type in the Admin Code: 07139')}</li>
        <li>{t('Tap Settings')}</li>
        <li>
          {t('Select Generate Pairing Code. This should display a randomized 3-keyword code, separated by hyphens')}
        </li>
      </ol>
    </RegisterTerminalStepModalWrapper>
  );
};

export const usePairYourTerminnal = ({
  onTerminalPaired,
  onSuccess,
}: {
  onTerminalPaired: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation('payments');

  const alerts = useAlert();
  const { paymentsUrl, stripeLocationId } = useMerchant();
  const { locationId } = useMultiQueryUtils();

  const { values, getFieldProps, isComplete } = useForm({
    fields: {
      pairingCode: {
        type: 'text',
        required: true,
        validator: (field: ValidatorFieldState<'text'>) => {
          const words = field.value.split('-');

          // remove trailing hyphens
          const filtered = words.filter((word) => word !== '');

          // must have 3 words
          if (!/[a-zA-Z0-9-]+$/g.test(field.value)) {
            return t('Only letters, numbers, or "-" are allowed.');
          } else if (filtered.length < 3) {
            return t('Not enough words, code should be 3 words separated by hyphens');
          } else if (filtered.length > 3) {
            return t('Too many words, code should be 3 words separated by hyphens');
          } else {
            // clear errors, all good
            return '';
          }
        },
      },
      terminalName: {
        type: 'text',
        required: true,
        validator: (field: ValidatorFieldState<'text'>) => {
          if (!/[a-zA-Z0-9- _.]+$/g.test(field.value)) {
            return 'Only letters, numbers, spaces, "-", "_" are allowed.';
          } else {
            return '';
          }
        },
      },
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      if (!paymentsUrl || !values.pairingCode || !values.terminalName || !locationId || !stripeLocationId) {
        const missingFields = [];
        if (!paymentsUrl) missingFields.push('paymentsUrl');
        if (!values.pairingCode) missingFields.push('pairingCode');
        if (!values.terminalName) missingFields.push('terminalName');
        if (!locationId) missingFields.push('locationId');
        if (!stripeLocationId) missingFields.push('stripeLocationId');

        throw new Error(`Missing required fields for terminal registration: ${missingFields.join(', ')}`);
      }
      return PaymentsTerminalController.registerTerminal({
        paymentsUrl,
        locationId,
        code: values.pairingCode,
        name: values.terminalName,
        stripeLocationId,
      });
    },
    onError: (error) => {
      alerts.error(t("Couldn't register terminal"));
      console.error('Trouble registering terminal', error);
      if (error instanceof HttpError)
        paymentsSentry.error(error.message ?? '', 'terminal-request', 'Trouble registering terminal');
    },
    onSuccess: () => {
      alerts.success(t('Terminal registered successfully'));
      onTerminalPaired();
      onSuccess();
    },
  });

  const handleRegisterTerminal = () => {
    if (values && values.pairingCode && values.terminalName && paymentsUrl && stripeLocationId) mutate();
  };

  return { getFieldProps, isComplete, isLoading, handleRegisterTerminal };
};
export const PairYourTerminal = ({
  onTerminalPaired,
  onSuccess,
  ...props
}: RegisterTerminalModalStepProps<{ onTerminalPaired: () => void; onSuccess: () => void }>) => {
  const { t } = useTranslation('payments');

  const { handleRegisterTerminal, isComplete, isLoading, getFieldProps } = usePairYourTerminnal({
    onTerminalPaired,
    onSuccess,
  });

  return (
    <RegisterTerminalStepModalWrapper
      title={t('Pair your terminal to Weave')}
      onClickAdvance={handleRegisterTerminal}
      isAdvanceDisabled={!isComplete || isLoading}
      Action={RegisterTerminalStep.Register}
      {...props}
    >
      <ContentLoader show={isLoading} message={t('Registering terminal')} />
      <div css={styles.registrationForm}>
        <Text>{t('Fill in the following information to complete the terminal registration')}</Text>
        <TextField label={t('Pairing Code')} {...getFieldProps('pairingCode')} />
        <TextField label={t('Custom terminal name')} {...getFieldProps('terminalName')} />
      </div>
    </RegisterTerminalStepModalWrapper>
  );
};
