import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const locationChipStyle = css`
  height: 26px;
  background: white;
  border: 1px solid ${theme.colors.neutral40};
  border-radius: ${theme.borderRadius.large};
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${theme.spacing(0.5, 0.75, 0.5, 1)};
`;

export const locationRemoveButtonStyle = css`
  color: ${theme.colors.neutral50};
  line-height: 0;
  margin-left: ${theme.spacing(1)};
  position: relative;
  transition: color 250ms ease-out;

  ::before {
    content: '';
    width: 24px;
    height: 24px;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
    display: block;
    transition: border-color 250ms ease-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :focus {
    color: ${theme.colors.primary50};
    outline: none;

    ::before {
      border-color: ${theme.colors.primary50};
    }
  }
`;
