import { useScopedAppFlagStore } from '@frontend/scope';

interface UseFormsDigitizationFeatureFlagResults {
  canUseFormsDigitization: boolean;
}

export const useFormsDigitizationFeatureFlag = (): UseFormsDigitizationFeatureFlagResults => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();

  return {
    canUseFormsDigitization: getFeatureFlagValue('forms:enable-form-digitization'),
  };
};
