import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin: ${theme.spacing(1, 0)};
`;

export const popoverDialogStyle = css`
  border-radius: ${theme.borderRadius.small};
  padding: 0;
  max-height: 500px;
  overflow: auto;
  width: 100vw;
  max-width: 350px;
`;

export const listItemStyle = css`
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};

  ul {
    max-height: 200px;
  }
`;
