import { useEffect } from 'react';
import { TeamChatApi } from '@frontend/api-team-chat';
import { useTeamChatSelector } from '../team-chat.provider';

const USER_PRESENCE_UPDATE_INTERVAL = 1000 * 60 * 3; // 3 minutes
const EXPIRATION_BUFFER = 1000 * 30; // 30 seconds - need buffer for network delay in posting the update
const USER_PRESENCE_CHECK_INTERVAL = USER_PRESENCE_UPDATE_INTERVAL / 2;

/**
 *
 * Starts a timer that continuously updates the user's presence to true
 */
const useSelfPresenceSync = () => {
  // online and offline on mount and unmount
  /** TODO: - @gisheri this seems problematic. We should probably be passing in all selected locations to the presence updater */
  const updateUserPresence = (status: 'online' | 'offline') => {
    const expiry = new Date(Date.now() + USER_PRESENCE_UPDATE_INTERVAL + EXPIRATION_BUFFER);
    return TeamChatApi.updateUserPresence(status, expiry);
  };

  useEffect(() => {
    updateUserPresence('online');
    const interval = setInterval(() => {
      updateUserPresence('online');
    }, USER_PRESENCE_UPDATE_INTERVAL);
    return () => {
      updateUserPresence('offline');
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const beforeUnloadListener = () => {
      updateUserPresence('offline');
    };
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);
};

/**
 * Runs a timer that updates users' presence to false after their presence expires
 * note: the users' presence expiry timesatmps are updated by the client's subscriptions handlers, but nothing updates them to false except here
 */
const useTeamPresenceSync = () => {
  const setUsers = useTeamChatSelector((ctx) => ctx.cache.setUsers);

  useEffect(() => {
    const interval = setInterval(() => {
      setUsers((prev) => {
        const next = [] as NonNullable<typeof prev>;
        let isChanged = false;
        prev?.forEach((user) => {
          const now = new Date();
          if (
            user.status.presence.online &&
            user.status.presence.expiresAt &&
            now > new Date(user.status.presence.expiresAt)
          ) {
            isChanged = true;
            next.push({
              ...user,
              status: {
                ...user.status,
                presence: {
                  ...user.status.presence,
                  online: false,
                },
              },
            });
          } else {
            next.push(user);
          }
        });
        return isChanged ? next : prev ?? [];
      });
    }, USER_PRESENCE_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [setUsers]);
};

export const _useTeamPresenceSync = useTeamPresenceSync;
export const _useSelfPresenceSync = useSelfPresenceSync;
