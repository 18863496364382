import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  CheckboxField,
  SearchField,
  Stepper,
  Text,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { QUICK_FILL_SEND_MESSAGE_LIMIT } from '../../../../constants';
import { QUICK_FILL_TRACKING_IDS } from '../../../../tracking-ids';
import { AddToListButton } from '../../components/add-to-list-button';
import { EmptyListIllustration } from '../../components/empty-list-illustration';
import { SortByDropdown, SortByEnum } from '../../components/sort-dropdown';
import { useQuickFillRecipients } from '../../hooks';
import { useQuickFillRecipientShallowStore } from '../../store';
import { ListItem } from './list-item';

type SelectRecipientsProps = {
  locationId: string;
};

const trackingIds = QUICK_FILL_TRACKING_IDS.sendMessagesTab.selectRecipientStep;

export const SelectRecipients = ({ locationId }: SelectRecipientsProps) => {
  const { t } = useTranslation('schedule-quick-fill');
  const [showMaxReachedBanner, setShowMaxReachedBanner] = useState(false);
  const selectAllFieldProps = useFormField({ type: 'checkbox', value: false });
  const searchFieldProps = useFormField({ type: 'text', value: '' }, [locationId]);
  const [sortBy, setSortBy] = useState(SortByEnum.DateAsc);
  const searchTerm = useDebouncedValue(searchFieldProps.value);

  const { recipientCounts, selectedIds, toggleSelectAll } = useQuickFillRecipientShallowStore(
    'recipientCounts',
    'selectedIds',
    'toggleSelectAll'
  );
  const { filteredCount, totalCount } = recipientCounts[locationId] || {};

  const infiniteQuery = useQuickFillRecipients({
    locationId,
    searchTerm,
    sortByValue: sortBy,
  });
  const isEmptyList = infiniteQuery.data?.pages[0].rows?.length === 0;

  // Recipient list filtered on search, used to calculate select all checkbox state
  const filteredRecipientList = infiniteQuery?.data?.pages
    .flatMap((page) => page.rows?.filter((row) => row.person?.receiveSms).map((i) => i.personId) || [])
    .filter((i) => i !== undefined);
  const selectedFilteredRecipientIds = filteredRecipientList?.filter((recipientId) =>
    selectedIds.includes(recipientId)
  );
  const selectedFilteredRecipientsLength = selectedFilteredRecipientIds?.length || 0;

  // Max limit is total count or 50, whichever is lower
  const maxLimit = totalCount < QUICK_FILL_SEND_MESSAGE_LIMIT ? totalCount : QUICK_FILL_SEND_MESSAGE_LIMIT;
  const isMaxSelected = selectedIds?.length >= maxLimit;

  // Remove max reached banner
  useEffect(() => {
    if (!isMaxSelected && showMaxReachedBanner) {
      setShowMaxReachedBanner(false);
    }
  }, [isMaxSelected, showMaxReachedBanner]);

  if (isEmptyList && !searchTerm) {
    return (
      <Stepper.Content>
        <EmptyListIllustration imageSize={268} />
        <AddToListButton locationId={locationId} />
      </Stepper.Content>
    );
  }

  return (
    <>
      <Stepper.Content>
        <div css={styles.searchContainer}>
          {Boolean(totalCount) && (
            <Text size='medium' color='subdued'>
              {t('{{count}} Patients', { count: totalCount })}
            </Text>
          )}
          <SearchField
            name='recipient-search'
            placeholder={'Search'}
            clearable={false}
            containerCss={css({ flex: 1, marginBottom: theme.spacing(2) })}
            data-trackingid={trackingIds.searchField}
            {...searchFieldProps}
          />
          <SortByDropdown value={sortBy} onChange={setSortBy} />
        </div>
        <div css={styles.listHeader}>
          {!(searchTerm && !infiniteQuery.isLoading) && (
            <div css={styles.listHeaderCheckboxContainer}>
              <CheckboxField
                {...selectAllFieldProps}
                label=''
                name='selectAll'
                trackingId={trackingIds.selectAllCheckbox}
                disabled={infiniteQuery.isLoading || filteredRecipientList?.length === 0} // Search result is empty
                value={selectedFilteredRecipientsLength > 0}
                // Show indeterminate state if some but not all recipients are selected
                indeterminate={
                  selectedFilteredRecipientsLength !== filteredRecipientList?.length &&
                  selectedFilteredRecipientsLength < maxLimit
                }
                onChange={() => toggleSelectAll(filteredRecipientList || [])}
              />
            </div>
          )}
          <div css={styles.listHeaderTextContainer}>
            <Text size='medium' color='subdued'>
              {searchTerm && !infiniteQuery.isLoading
                ? filteredCount
                  ? t('{{count}} Matches', { count: filteredCount })
                  : t('No Matches')
                : t('Select Patients')}
            </Text>
          </div>
        </div>
        <div css={styles.listContainer}>
          <InfinitePaginatedList
            height={282} // Height of 5 items in the list
            endOfListText=''
            emptyStateConfig={{
              header: t('No Recipients found'),
              disableGraphic: true,
              textProps: { color: 'subdued' },
            }}
            infiniteQueryProps={infiniteQuery}
            renderListItem={({ listItem }) => {
              return (
                <ListItem
                  key={listItem.personId}
                  recipient={listItem}
                  isMaxSelected={isMaxSelected}
                  locationId={locationId}
                  setShowMaxReachedBanner={setShowMaxReachedBanner}
                />
              );
            }}
          />
        </div>
        {showMaxReachedBanner && (
          <BannerNotification
            css={{ marginTop: theme.spacing(2) }}
            status='warn'
            data-trackingid={trackingIds.maxSelectionBanner}
            message={t(`You've reached the maximum number of recipients for this message`)}
          />
        )}
      </Stepper.Content>
      <Stepper.ButtonBar>
        <Text size='small' color='subdued' weight={isMaxSelected ? 'bold' : 'regular'}>
          {selectedIds.length === 0
            ? t('Select at least one recipient')
            : t('{{selected}} of {{total}} Selected', { selected: selectedIds?.length, total: maxLimit })}
        </Text>
        <Stepper.NextButton trackingId={trackingIds.nextButton} isValid={!!selectedIds?.length}>
          {t('Next')}
        </Stepper.NextButton>
      </Stepper.ButtonBar>
    </>
  );
};

const styles = {
  searchContainer: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(2)};
  `,
  listHeader: css`
    display: flex;
    border-radius: ${theme.borderRadius.small} ${theme.borderRadius.small} 0 0;
    border: 1px solid ${theme.colors.neutral20};
    border-bottom: none;
  `,
  listHeaderCheckboxContainer: css`
    padding: ${theme.spacing(2)};
    border-right: 1px solid ${theme.colors.neutral20};
  `,
  listHeaderTextContainer: css`
    padding: ${theme.spacing(2)};
    flex: 1;
  `,
  listContainer: css`
    border: 1px solid ${theme.colors.neutral20};
    border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  `,
};
