import { HTMLAttributes, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, NakedButton, Text, styles as dsStyles } from '@frontend/design-system';

interface SummaryItemProps {
  className?: string;
  label: string;
  value: number | string;
}

type BackgroundConfig = {
  gradientDegree?: number;
  gradientEnd?: string;
  gradientStart?: string;
};

interface RoiOverviewCardProps {
  backgroundConfig?: BackgroundConfig;
  filteredDate: string;
  iconName: IconName;
  isLoading?: boolean;
  onClick?: () => void;
  summaryValues: SummaryItemProps[];
  title: string;
  trackingId: string;
}

const defaultBackgroundConfig: BackgroundConfig = {
  gradientDegree: 135,
  gradientEnd: '#94BFFF', // Color not available in the theme
  gradientStart: theme.colors.primary40,
};

const SummaryItem = ({ label, value, ...rest }: SummaryItemProps & HTMLAttributes<HTMLDivElement>) => (
  <div {...rest}>
    <Text style={{ fontSize: theme.fontSize(48), lineHeight: 1 }} textAlign='left' weight='bold'>
      {value}
    </Text>
    <Text size='medium' textAlign='left'>
      {label}
    </Text>
  </div>
);

export const RoiOverviewCard = ({
  backgroundConfig,
  children,
  filteredDate,
  iconName,
  isLoading,
  onClick,
  summaryValues = [],
  title,
  trackingId,
}: PropsWithChildren<RoiOverviewCardProps>) => {
  const background = { ...defaultBackgroundConfig, ...backgroundConfig };

  return (
    <NakedButton
      className='roi-overview-card-button'
      css={styles.wrapper}
      onClick={onClick}
      style={{
        background: `linear-gradient(${background?.gradientDegree}deg, ${background?.gradientStart}, ${background?.gradientEnd})`,
      }}
      trackingId={trackingId}
    >
      <header>
        <div css={styles.flexGroup} style={{ maxWidth: 'calc(100% - 100px)' }}>
          <Icon name={iconName} size={16} />
          <Heading css={dsStyles.truncate} level={3} style={{ fontSize: theme.fontSize(14) }}>
            {title}
          </Heading>
        </div>
        <div css={styles.flexGroup}>
          <Icon name='calendar' size={16} />
          <Text style={{ fontSize: theme.fontSize(14), whiteSpace: 'nowrap' }}>{filteredDate}</Text>
        </div>
      </header>

      <div className='roi-overview-card-data-items' css={styles.dataItems}>
        {summaryValues.map((summaryItem) => (
          <SummaryItem key={summaryItem.label} {...summaryItem} />
        ))}

        {children}
      </div>
      <ContentLoader show={isLoading} size='small' />
    </NakedButton>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${theme.spacing(2)};
    position: relative;

    header {
      display: flex;
      justify-content: space-between;
    }

    * {
      color: ${theme.colors.white} !important;
    }
  `,

  flexGroup: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};

    svg {
      min-width: ${theme.spacing(2)};
    }
  `,

  dataItems: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    justify-content: space-around;
    margin-top: ${theme.spacing(1)};
  `,
};
