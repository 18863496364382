import { getFilterDisplayDates } from '@frontend/api-invoices';
import { PageParamsInStore } from '@frontend/api-payments';
import { RefundFilterType, RefundSearchParams } from '@frontend/api-refunds';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { getDateRefunded } from '../utils';

type RefundState = {
  displayDates: [Date, Date] | [];
  filter: RefundFilterType;
  numRows: number;
  order: RefundSearchParams['order'];
  pageParams: PageParamsInStore;
};

type RefundActions = {
  setFilter: (filter: RefundState['filter']) => void;
  setNumRows: (numRows: RefundState['numRows']) => void;
  setOrder: (order: RefundState['order']) => void;
  setPageParams: (pageParams: RefundState['pageParams']) => void;
  resetPageParams: () => void;
};

type RefundStore = RefundState & RefundActions;

const pageParams = { first: 10, currentPage: 1 };
const DEFAULT_ORDER = '-billedAt';

export const useRefundStore = createStoreWithSubscribe<RefundStore>(
  (set) => {
    return {
      filter: {},
      displayDates: getFilterDisplayDates({}),
      numRows: 10,
      order: DEFAULT_ORDER,
      pageParams,

      setFilter: (filter) => {
        set((prevState) => ({
          ...prevState,
          filter: { ...filter, dateRefunded: getDateRefunded(filter) },
          pageParams,
          displayDates: getFilterDisplayDates({
            start: filter.dateRefunded?.gte,
            end: filter.dateRefunded?.lte,
          }),
        }));
      },

      setOrder: (order) => set((prevState) => ({ ...prevState, order, pageParams })),
      setNumRows: (numRows) =>
        set((prevState) => ({ ...prevState, numRows, pageParams: { ...pageParams, first: numRows } })),
      setPageParams: (pageParams) => set((prevState) => ({ ...prevState, pageParams })),
      resetPageParams: () => set((prevState) => ({ ...prevState, pageParams, order: DEFAULT_ORDER })),
    };
  },
  { name: 'RefundStore' }
);

export const useRefundShallowStore = createShallowStore(useRefundStore);
