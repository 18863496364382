import { useCallback } from 'react';
import { useQuery, useQueries } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { DepartmentsSchemaServiceV2 } from '@frontend/schema';
import { ListDefaultSMSTypes } from './types';

export const getListDefaultSMSQueryKey = (req: ListDefaultSMSTypes['input']) =>
  ['DepartmentsV2', 'ListDefaultSMS', req] as const;
type QueryKey = ReturnType<typeof getListDefaultSMSQueryKey>;

/**
 * A hook that returns a query for the `ListDefaultSMS` endpoint.
 * Will gracefully handle errors and return an empty array if the data is not available.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useListDefaultSMSQuery = <E = unknown, D = ListDefaultSMSTypes['output']>(
  req: ListDefaultSMSTypes['input'],
  options?: LimitedSchemaQueryOptions<ListDefaultSMSTypes, E, D, QueryKey>,
  httpOptions?: Options
) => {
  const gracefulResponse: ListDefaultSMSTypes['output'] = { smsNumbers: [] };
  const defaultData: D = options?.select ? options?.select(gracefulResponse) : (gracefulResponse as D);

  const query = useQuery<ListDefaultSMSTypes['output'], E, D, QueryKey>({
    queryKey: getListDefaultSMSQueryKey(req),
    queryFn: () => DepartmentsSchemaServiceV2.ListDefaultSMS(req, httpOptions),
    useErrorBoundary: false, // Disable error boundary because we handle the error response with the `gracefulResponse`
    ...options,
    enabled: !!req?.locationId && (options?.enabled ?? true),
    retry: options?.retry ?? false,
    placeholderData: options?.placeholderData ?? gracefulResponse,
  });

  return {
    ...query,
    data: query.isError || !query.data ? defaultData : query.data,
  };
};

/**
 * A hook that returns an array of queries for the `ListDefaultSMS` endpoint.
 * It takes an array of objects with the following properties:
 * Will gracefully handle errors and return an empty array if the data is not available.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useListDefaultSMSQueries = <E = unknown, D = ListDefaultSMSTypes['output']>(
  argsArr: {
    req: ListDefaultSMSTypes['input'];
    options?: LimitedSchemaQueryOptions<ListDefaultSMSTypes, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) => {
  const selectFnWithLocationId = useCallback(
    ({
      data,
      locationId,
      selectFn,
    }: {
      data: ListDefaultSMSTypes['output'];
      locationId: string;
      selectFn?: (data: ListDefaultSMSTypes['output']) => D;
    }): D & { locationId: string } => {
      const transformedData: D = selectFn ? selectFn(data) : (data as D);
      return {
        ...transformedData,
        locationId,
      };
    },
    []
  );
  const gracefulResponse: ListDefaultSMSTypes['output'] = {
    smsNumbers: [],
  };

  const queries = useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getListDefaultSMSQueryKey(req),
      queryFn: () => DepartmentsSchemaServiceV2.ListDefaultSMS(req, httpOptions),
      useErrorBoundary: false, // Disable error boundary because we handle the error response with the `gracefulResponse`
      ...options,
      select: (data: ListDefaultSMSTypes['output']): D & { locationId: string } =>
        selectFnWithLocationId({ data, locationId: req.locationId ?? '', selectFn: options?.select }),
      retry: options?.retry ?? false,
      placeholderData: options?.placeholderData ?? gracefulResponse,
    }))
  ) as ReturnType<typeof useListDefaultSMSQuery<E, D & { locationId: string }>>[];

  return queries.map((query, i) => {
    const selectFn = (data: ListDefaultSMSTypes['output']) =>
      selectFnWithLocationId({
        selectFn: argsArr[i].options?.select,
        data,
        locationId: argsArr[i].req.locationId ?? '',
      });

    const defaultData = selectFn(gracefulResponse);

    return {
      ...query,
      data: query.isError || !query.data ? defaultData : query.data,
    };
  });
};
