import { useMemo } from 'react';
import { css } from '@emotion/react';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { BulkMessagingQueries } from '@frontend/api-bulk-messaging';
import { AllLocationsAllotment, AllLocationAllotmentTypes } from '@frontend/all-locations-allotment';
import { FeatureAccessFlags, FeatureAccessPermissions, useFeatureAccess } from '@frontend/feature-access';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { SegmentedBarTypes, SegmentedBar } from '@frontend/segmented-bar';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, Text, useModalControl } from '@frontend/design-system';
import { barStyles } from '../styles';
import { ContainerSize } from '../types';
import { getMonthFromNumber } from './utils';

type Props = {
  month?: number;
  quota?: number;
  sent?: number;
  pending?: number;
  failed?: number;
  additional?: number;
  showAllotments?: boolean;
  size?: ContainerSize;
  year?: number;
};

export const BulkMessageSegmentedBar = ({
  month,
  quota = 0,
  sent,
  pending,
  failed,
  additional,
  size = 'large',
  year = new Date().getFullYear(),
  showAllotments = false,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const isLarge = size === 'large';

  const { hasAccess, locationsWithEnabledFlag } = useFeatureAccess(
    FeatureAccessFlags.emailMarketing,
    FeatureAccessPermissions.emailMarketing
  );

  const used = (sent ?? 0) + (pending ?? 0) + (additional ?? 0);

  const data = useMemo(() => {
    const data: SegmentedBarTypes.Data[] = [];

    if (sent !== undefined) {
      data.push({
        label: t('Sent Messages'),
        data: sent + (failed ?? 0),
        color: theme.colors.primary70,
        hoverLabel: t('{{messageCount}} Sent', { messageCount: sent + (failed ?? 0) }),
      });
    }

    if (pending !== undefined) {
      data.push({
        label: t('Scheduled Messages'),
        data: pending,
        color: theme.colors.secondary.seaweed20,
        hoverLabel: t('{{messageCount}} Scheduled', { messageCount: pending }),
      });
    }

    if (additional !== undefined) {
      const availableAdditional = used > quota ? quota - (sent ?? 0) - (failed ?? 0) - (pending ?? 0) : additional;
      data.push({
        label: t('Messages Used by This Send'),
        data: availableAdditional,
        color: theme.colors.secondary.eggplant40,
        hoverLabel: t('{{messageCount}} Used by This Send', { messageCount: availableAdditional }),
      });
    }

    data.push({
      label: t('Remaining Messages'),
      data: quota - used,
      color: theme.colors.neutral10,
      hoverLabel: t('{{messageCount}} Available', { messageCount: quota - used }),
      isRemaining: true,
    });

    return data;
  }, [additional, pending, quota, sent, used, t]);

  const { modalProps, triggerProps } = useModalControl();
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { data: locationsUsage = {} } = BulkMessagingQueries.useGetUsage(
    {
      orgId,
      locationIds: locationsWithEnabledFlag,
      type: CampaignType.EMAIL,
      year,
    },
    month,
    { enabled: hasAccess && modalProps.show }
  );

  const formattedData: AllLocationAllotmentTypes.UsageData[] = Object.entries(locationsUsage).map(
    ([locationId, { usage: locationUsage }]) => ({
      locationId,
      quota: locationUsage[0]?.allotment,
      sent: locationUsage[0]?.sent,
      pending: locationUsage[0]?.scheduled,
      failed: locationUsage[0]?.error,
    })
  );

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        <Heading level={2}>{getMonthFromNumber(month)}</Heading>
        <div
          css={css`
            display: flex;
            align-items: center;
            column-gap: ${theme.spacing(1)};
          `}
        >
          <Text weight='semibold'>
            {t('{{usedMessageCount}} / {{maxMessageCount}} Messages Used', {
              usedMessageCount: used > quota ? quota : used,
              maxMessageCount: quota,
            })}
          </Text>
          {showAllotments && (
            <NakedButton {...triggerProps}>
              <Icon name='locations-small' />
            </NakedButton>
          )}
        </div>
      </div>
      <SegmentedBar
        items={data}
        css={
          isLarge &&
          css`
            margin-bottom: ${theme.spacing(5)};
          `
        }
      >
        <SegmentedBar.Bar barStyles={barStyles(isLarge)} max={quota} />
        {isLarge && <SegmentedBar.Legend />}
      </SegmentedBar>
      {modalProps.show && <AllLocationsAllotment data={formattedData} modalProps={modalProps} month={month} />}
    </>
  );
};
