import { prettify } from '@frontend/string';
import { Chip, ChipVariants, ChipProps } from '@frontend/design-system';
import { FailedPaymentStatuses } from '../../../hooks/payment-failed/types';

type StatusChipProps = ChipProps & {
  status: FailedPaymentStatuses;
};

const mapStatus = (status: FailedPaymentStatuses): ChipVariants => {
  switch (status) {
    case 'SUCCEEDED':
      return 'success';
    case 'STATUS_UNKNOWN':
      return 'disabled';
    case 'FAILED':
      return 'critical';
    case 'PENDING':
      return 'primary';
    default:
      return 'disabled';
  }
};

export const StatusChip = ({ status, ...rest }: StatusChipProps) => (
  <>
    {status && (
      <Chip variant={mapStatus(status)} {...rest}>
        {prettify(status, { split: '_' })}
      </Chip>
    )}
  </>
);
