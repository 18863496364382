import { useMemo, useState } from 'react';
import { FieldMask } from '@weave/schema-gen-ts/dist/google/protobuf/field_mask.pb';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { List } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { BulkMessagingMutations, BulkMessagingQueries } from '@frontend/api-bulk-messaging';

export const useContactLists = (accessibleLocationIds: string[], types: CampaignType[]) => {
  const request = {
    locationId: accessibleLocationIds[0],
    pageSize: 25,
    types,
  };

  const queryKey = BulkMessagingQueries.keys.listLists(request);

  const queryResult = BulkMessagingQueries.useListLists(request);
  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);

  const contactLists = useMemo(() => queryResult.data?.pages.flatMap((page) => page.lists) ?? [], [queryResult.data]);

  const { mutateAsync: mutateCreate } = BulkMessagingMutations.useCreateList(queryKey);
  const { mutateAsync: mutateDelete } = BulkMessagingMutations.useDeleteList(queryKey);
  const { mutateAsync: mutateUpdate } = BulkMessagingMutations.useUpdateList(queryKey);

  const createContactList = async (list: List) => {
    const response = await mutateCreate({ list });
    if (response.listId) selectContactList(response.listId);
  };

  const deleteContactList = async (listId: string) => {
    await mutateDelete({ listId });
    deselectContactList(listId);
  };

  const deselectContactList = (listId: string) => {
    setSelectedListIds(selectedListIds.filter((selectedListId) => selectedListId !== listId));
  };

  const renameContactList = async (list: List) => {
    await mutateUpdate({ list, updateMask: new FieldMask({ paths: ['list.name'] }) });
  };

  const selectContactList = (listId: string) => {
    setSelectedListIds([...selectedListIds, listId]);
  };

  const setSelectedContactLists = (listIds: string[]) => {
    setSelectedListIds(listIds);
  };

  return {
    contactLists,
    contactListsQueryResult: queryResult,
    createContactList,
    deleteContactList,
    deselectContactList,
    renameContactList,
    selectContactList,
    selectedContactLists: selectedListIds,
    setSelectedContactLists,
  };
};
