import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { SkeletonLoaders, useAlert, useFormField } from '@frontend/design-system';
import { AudioPicker } from '../../../audio-picker/audio-picker';
import { AudioItem } from '../../../audio-picker/types';
import { ButtonBar, AddStepPanelProps, HeaderBar } from './add-step-panel';

export const PlayMessageStep = ({
  tenantId,
  initialState,
  onClose,
  onProceedClick,
  onBackClick,
}: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const [selectedAudioFile, setSelectedAudioFile] = useState<AudioItem | undefined>(undefined);

  const audioFileProps = useFormField({
    required: true,
    type: 'dropdown',
    // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
    // and the generated typescript does not know how to handle that correctly.
    value: initialState?.callObject?.playMessageExpansion?.mediaItemId,
  });

  const handleProceedClick = () => {
    if (!selectedAudioFile) {
      alerts.error('No audio file selected');
      return;
    }

    onProceedClick({
      callObject: {
        primitiveId: selectedAudioFile.id,
        primitiveName: selectedAudioFile.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        playMessageExpansion: {
          // @ts-expect-error the schema type uses a oneOf for the expansion properties
          // and the generated typescript does not know how to handle that correctly.
          mediaFileName: initialState?.callObject?.playMessageExpansion?.mediaFileName ?? '',
          mediaItemId: selectedAudioFile.id,
        },
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Play Message')} onClose={onClose} />

      <SkeletonLoaders.Loader isLoading={false} shape='rectangle' width={320} height={50}>
        <AudioPicker
          widths={{ field: '300px', scrubber: '240px' }}
          allowedOptions={{
            add: true,
            custom: true,
            standard: false,
            mailbox: false,
            none: false,
            default: false,
          }}
          field={audioFileProps}
          name='mediaId'
          labels={{
            field: t('Select audio file'),
          }}
          onSelect={(item) => {
            // NOTE: this will not be called if the user selects the "No Greeting option"
            setSelectedAudioFile(item);
          }}
          tenantId={tenantId}
        />
      </SkeletonLoaders.Loader>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!audioFileProps.value}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />
    </>
  );
};
