import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { shell } from '@frontend/shell-utils';

/**
 * a naive wrapper of the window history stack of pages within the weave app
 * Allows to record the history of pages visited within the app and navigate between them
 */

const getHashParams = (url: string) => {
  const hash = url.split('#')?.[1];
  if (!hash) {
    return undefined;
  }

  const paramsIndex = hash.indexOf('?');
  if (paramsIndex === -1) {
    return undefined;
  }

  const queryString = hash.substring(paramsIndex + 1);
  return Object.fromEntries(new URLSearchParams(queryString).entries());
};

export const useHistoryControl = () => {
  let loc: ReturnType<typeof useLocation> | undefined;
  try {
    // This is not actually conditionally called
    // eslint-disable-next-line react-hooks/rules-of-hooks
    loc = useLocation();
  } catch {
    console.error('useLocation is not available in this context');
    loc = undefined;
  }

  let navigate: ReturnType<typeof useNavigate> | undefined;
  try {
    // This is not actually conditionally called
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigate = useNavigate();
  } catch {
    console.error('useNavigate is not available in this context');
    navigate = undefined;
  }

  let settingsNavigate: ReturnType<typeof useSettingsNavigate> | undefined;
  try {
    // This is not actually conditionally called
    // eslint-disable-next-line react-hooks/rules-of-hooks
    settingsNavigate = useSettingsNavigate();
  } catch {
    console.error('useSettingsNavigate is not available in this context');
    settingsNavigate = undefined;
  }

  const navigateTo = (url: string) => {
    if (!url) {
      return;
    }

    const isSettingsURL = url.includes('#settings/');
    const settingsURL = url.split('#settings/')?.[1];
    if (isSettingsURL && settingsURL) {
      if (settingsURL) {
        settingsNavigate?.navigate({ to: settingsURL as any, params: {} as any, search: getHashParams(settingsURL) });
      }
    } else {
      navigate?.({ to: url.replace(location.origin, '') });
      settingsNavigate?.closeSettings();
    }
  };

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  const back = () => {
    if (shell.isShell && (navigate || settingsNavigate)) {
      shell.invoke?.('window:history:back', undefined).then((url) => {
        navigateTo(url);
      });
    } else {
      history.back();
    }
  };
  const forward = () => {
    if (shell.isShell && (navigate || settingsNavigate)) {
      shell.invoke?.('window:history:forward', undefined).then((url) => {
        navigateTo(url);
      });
    } else {
      history.forward();
    }
  };
  const go = (n: number) => {
    if (shell.isShell && (navigate || settingsNavigate)) {
      shell.invoke?.('window:history:go', { step: n }).then((url) => {
        navigateTo(url);
      });
    } else {
      history.go(n);
    }
  };

  useEffect(() => {
    if (!shell) return;
    //race condition here. this history stack is updated after a 'push' event from another syncShellHistory hook,
    //so we need to give it a bit of time to process that before we check this.. :/
    setTimeout(async () => {
      shell
        .invoke?.('window:history:can-go-back', undefined)
        .then((res) => {
          setCanGoBack(res);
        })
        .catch(() => setCanGoBack(false));
      shell
        .invoke?.('window:history:can-go-forward', undefined)
        .then((res) => {
          setCanGoForward(res);
        })
        .catch(() => setCanGoForward(false));
    }, 500);
  }, [loc?.current.href, shell.isShell]);

  return {
    back,
    forward,
    go,
    canGoBack,
    canGoForward,
  };
};

export const useRecentHistory = () => {
  const [recent, setRecent] = useState<{ href: string; title: string }[]>([]);

  useEffect(() => {
    if (shell.isShell) {
      shell.invoke?.('window:history:get', undefined).then((stack) => {
        setRecent(stack);
      });
    }
  }, [shell.isShell]);

  return recent;
};

/**
 * Syncs the location history to the shell. The shell actually keeps track of the history, and communicates it to the window when needed
 */
export const useSyncShellHistory = () => {
  const location = useLocation();
  useEffect(() => {
    if (shell.isShell) {
      shell.emit?.('window:history:clear', undefined);
    }
  }, []);

  useEffect(() => {
    if (shell.isShell) {
      /* there is a bit of a race condition here to let the title load. There might be a way to wait for the title
      but there is no reason to rush to get this item into history, so I think a timeout is okay for now */
      setTimeout(() => {
        shell.emit?.('window:history:push', {
          href: window.location.href, //we just want everything after the origin
          title: document.title,
        });
      }, 500);
    }
  }, [shell.isShell, location.current.href]);
};
