import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PaymentOrigin } from '@frontend/api-invoices';
import { PaymentMethod, PaymentPlanDetailsResponse, PaymentPlanPaymentType } from '@frontend/api-payment-plans';
import {
  CreatePaymentIntentBody,
  PaymentIntent,
  PaymentMethodTypeEnum,
  SetupFutureUsage,
  getPaymentClientSecret,
  makePayment as makePaymentApi,
  makePaymentSync as makePaymentSyncApi,
} from '@frontend/api-weave-pay';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { genUUIDV4 } from '@frontend/string';
import { useAlert } from '@frontend/design-system';
import { isPaidInvoice } from '../../utils';
import { CollectPaymentModalSteps, useCollectPaymentModal } from './use-collect-payment-modal';

export enum PaymentIntentErrors {
  RequestAlreadyCompleted = 'request is already completed',
}

const getErrorCause = (err: unknown) => {
  let cause = '';
  if (err instanceof HttpError && err.data && typeof err.data !== 'string' && 'message' in err.data) {
    const errorMessage = JSON.parse(err.data.message);
    cause = errorMessage.cause ?? '';
  }
  return cause;
};
export interface UseCreatePaymentIntentOptions {
  amount: number;
  origin: PaymentOrigin;
  locationId: string | undefined;
  personId: string | undefined;
  email: string | undefined;
  invoiceId?: string;
  paymentMethod?: PaymentMethod;
  setupFutureUsage?: SetupFutureUsage | null;
  planDetails?: PaymentPlanDetailsResponse;
  receiptEmail?: string;
  cardholderName?: string;
}

/**
 * @deprecated collect-payment/* will be removed when /collect-payment-multi is fully live.
 */
export const useCreatePaymentIntent = ({
  amount,
  email,
  personId,
  locationId,
  origin,
  paymentMethod,
  setupFutureUsage,
  planDetails,
  invoiceId,
  receiptEmail,
  cardholderName,
}: UseCreatePaymentIntentOptions) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const { merchantsData } = useMerchant();

  const { paymentsUrl } = merchantsData[locationId ?? ''] ?? {};
  const { goBack } = useCollectPaymentModal();
  const { refetch: refetchInvoice } = useSelectedInvoice(invoiceId);
  const { refetch } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery(
    {
      flagName: PaymentsFeatureFlags.canUseSyncCreateIntentEndpoint,
      groupIds: locationId ? [locationId] : [],
    },
    { enabled: false }
  );
  const isACHPayment = paymentMethod?.type === 'us_bank_account';
  const paymentMethodId = paymentMethod?.id || '';
  const confirmPaymentType = paymentMethod?.type ?? 'card';

  const errorAlert = (message: string) =>
    alerts.error({
      autoDismissAfter: 12000,
      message,
    });

  const getPaymentPayload = () => {
    const payment: CreatePaymentIntentBody = {
      amount,
      paymentId: genUUIDV4(),
      origin,
      ...(paymentMethodId ? { paymentMethodId } : {}),
      ...(setupFutureUsage ? { setupFutureUsage } : {}),
      ...(receiptEmail ? { receiptEmail } : {}),
      ...(cardholderName ? { cardholderName } : {}),
      paymentMethodType: isACHPayment ? PaymentMethodTypeEnum.ACH : PaymentMethodTypeEnum.CARD,
      ...(personId || email
        ? {
            person: {
              ...(personId ? { id: personId } : {}),
              ...(email ? { email } : {}),
            },
          }
        : {}),
      ...(planDetails
        ? {
            paymentPlanPayment: {
              planId: planDetails.id,
              paymentType: PaymentPlanPaymentType.Balance,
            },
          }
        : {}),
    };

    return payment;
  };

  const createPaymentIntent = async () => {
    const payment = getPaymentPayload();
    let paymentIntent: PaymentIntent | undefined;
    let paymentId: string | undefined;
    let clientSecret: string | undefined;

    if (!paymentsUrl || !locationId) {
      console.error('PaymentsUrl or the locationId is empty', paymentsUrl, locationId);
    } else {
      try {
        const { data: invoice } = await refetchInvoice();
        if (isPaidInvoice(invoice)) {
          throw new Error(PaymentIntentErrors.RequestAlreadyCompleted);
        }
        const { data: canUseSyncCreateIntentEndpointFlagValues } = await refetch();
        const canUseSyncCreateIntentEndpoint =
          !!canUseSyncCreateIntentEndpointFlagValues && canUseSyncCreateIntentEndpointFlagValues[locationId];
        if (canUseSyncCreateIntentEndpoint) {
          const response = await makePaymentSyncApi(paymentsUrl, payment, locationId, invoiceId);
          paymentId = response.paymentId;
          paymentIntent = response.paymentIntent;
          clientSecret = response.paymentIntent.clientSecret;
        } else {
          const response = await makePaymentApi(paymentsUrl, payment, locationId, invoiceId);
          const {
            paymentId: paymentIdFromResponse,
            links: { self: getClientSecretURL },
          } = response;
          const PaymentIntentResponse = await getPaymentClientSecret(locationId, getClientSecretURL);
          paymentId = paymentIdFromResponse;
          paymentIntent = PaymentIntentResponse.paymentIntent;
          clientSecret = paymentIntent?.clientSecret;
        }
      } catch (err) {
        try {
          if (
            (err instanceof Error && err.message === PaymentIntentErrors.RequestAlreadyCompleted) ||
            getErrorCause(err) === PaymentIntentErrors.RequestAlreadyCompleted
          ) {
            errorAlert(t('Invoice is already paid.'));
            refetchInvoice();
            goBack(CollectPaymentModalSteps.PaymentFlowList);
          } else {
            // throwing to show the generic error message in next catch block
            throw err;
          }
        } catch (err) {
          errorAlert(t('Error retrieving payment details.'));
        }
      }
    }
    return { paymentIntent, paymentId, clientSecret };
  };

  return { createPaymentIntent, paymentMethodId, confirmPaymentType };
};
