import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { useQuery } from 'react-query';
import { getLocationOrgIds, getLocationsForOrgIds, getOrgsData } from '@frontend/api-location';
import { TenantApi } from '@frontend/api-tenants';
import { authnClient } from '@frontend/auth';
import {
  clearLastVisitedPage,
  getDecodedWeaveToken,
  getLoginData,
  getUser,
  getWeaveToken,
  isSessionTokenActive,
  isWeaveTokenActive,
  isWeaveTokenBufferActive,
  isWeaveUser,
  localStorageHelper,
  LOGIN_DATA_KEY,
  onWeaveTokenChange,
  PortalUser,
} from '@frontend/auth-helpers';
import { useRemoveAppLoader } from '@frontend/document';
import { useFetch } from '@frontend/fetch';
import { useSyncShellHistory } from '@frontend/history';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useOnboardingRedirect } from '@frontend/onboarding';
import { useMerchant } from '@frontend/payments-hooks';
import { useInitializePhones } from '@frontend/phone';
import {
  locationDataAugmentationPipeline,
  processOrgData,
  rebase,
  useAppScopeStore,
  useScopedAppFlagStore,
  WeaveLocation,
  WeaveLocationGroup,
} from '@frontend/scope';
import { Tracer, tracerHeader } from '@frontend/tracer';
import { sentry } from '@frontend/tracking';
import { useAlert } from '@frontend/design-system';
import { signOut } from '../helpers/sign-out';
import { useConfigureLocation, useHandleScopeChange } from '../utils/configure-location';
import { setCookie } from '../utils/set-cookie';
import { getShellThemeFromLocalStorage } from './shell-theme-switcher/helper';

export type AppStateType =
  | {
      state: 'ONBOARDING';
      meta: {
        onboardingRedirectUrl: string;
      };
    }
  | {
      state: 'LOADING';
    }
  | {
      state: 'LOCATION_SELECTION';
    }
  | {
      state: 'INITIALIZED';
    }
  | {
      state: 'REDIRECTING';
    };

export const useProcessScopeDataFromOrgIds = ({
  orgIds,
  allowedLocationIds,
}: {
  orgIds: string[];
  allowedLocationIds?: string[];
}) => {
  const { data: phoneTenants, isSuccess: tenantSuccess } = useQuery({
    queryKey: ['new-tenants', { orgIds }],
    queryFn: () => TenantApi.getTenantsWithOrgIds(orgIds),
    select: (data) => data.flatMap((t) => t.tenants),
    enabled: orgIds.length > 0,
  });

  const { data: locations, isSuccess: locationSuccess } = useQuery({
    queryKey: ['new-locations', { orgIds }],
    queryFn: async () => {
      return await getLocationsForOrgIds(orgIds);
    },
    select: (data) =>
      data
        .flatMap((l) => l.locations)
        // This is a dumb filter to satisfy TypeScript
        .filter(Boolean)
        .filter((l) => l.active)
        .map(rebase)
        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0)),
    enabled: orgIds.length > 0,
  });

  const { data: orgData = [], isSuccess: orgSuccess } = useQuery({
    queryKey: ['new-orgs-data', { orgIds }],
    queryFn: async () => {
      if (!orgIds || !orgIds.length) return [];

      const orgsData = await getOrgsData(orgIds);
      return orgsData;
    },
    enabled: !!orgIds && !!orgIds.length,
  });

  const { locationList, locationMap, groupMap, locationHierarchy } = useMemo(() => {
    if (locations && phoneTenants) {
      const {
        locationList: list,
        locationMap: map,
        groupMap: gMap,
        hierarchy,
      } = locationDataAugmentationPipeline({
        locations: locations as WeaveLocation[],
        tenants: phoneTenants,
        allowedLocationIds,
      });

      return {
        locationList: list,
        locationMap: map,
        groupMap: gMap,
        locationHierarchy: hierarchy,
      };
    }

    return {
      locationList: [],
      locationMap: {},
      groupMap: {},
      locationHierarchy: {},
    };
  }, [locations, phoneTenants, allowedLocationIds]);

  const { orgMap } = useMemo(
    () =>
      processOrgData({
        orgData,
        locationHierarchy,
        groupMap,
      }),
    [orgData, locationHierarchy, groupMap]
  );

  return useMemo(
    () => ({
      isSuccess: (tenantSuccess && orgSuccess && locationSuccess) || (isWeaveUser() && orgIds.length === 0),
      orgMap,
      locationList,
      locationMap,
      groupMap,
      locationHierarchy,
    }),
    [tenantSuccess, orgSuccess, locationSuccess, orgMap, locationList, locationMap, groupMap, locationHierarchy]
  );
};

/**
 * For weave users, we can use the recentOrganizationId to determine the initial orgIds.
 * For non-weave users, we need to make a request to get the orgIds.
 */
const useInitialOrgIds = ({ user }: { user?: PortalUser }) => {
  const { data: orgIds = [] } = useQuery({
    queryKey: ['orgIds', user?.userID],
    queryFn: async () => getLocationOrgIds(),
    select: (data) => data.orgId,
    enabled: user?.type !== 'weave',
  });

  const loginData = user ? getLoginData(user.userID) : undefined;
  const weaveUserOrgId = loginData?.recentOrganizationId;
  const resolvedOrgIds = useMemo(
    () => (user?.type === 'weave' && weaveUserOrgId ? [weaveUserOrgId] : orgIds),
    [orgIds, weaveUserOrgId, user?.type]
  );

  return resolvedOrgIds;
};

export const determineInitialLocations = ({
  locationMap,
  groupMap,
}: {
  locationMap: Record<string, WeaveLocation>;
  groupMap: Record<string, WeaveLocationGroup>;
}) => {
  const user = getUser();
  const decodedWeaveToken = getDecodedWeaveToken();
  const isWeaveUser = user?.type === 'weave';
  const keys = Object.keys(decodedWeaveToken?.ACLS ?? {});
  const loginData = getLoginData(user?.userID ?? '');

  let lastLocationIds: string[] = [];
  if (isWeaveUser) {
    lastLocationIds = loginData?.lastLocationIds ?? [];
  } else {
    /**
     * We cannot rely on `keys` to determine if the user has access to only one location. A user who has access to a parent and it's child will have 2 keys.
     * We need to filter the keys based on the locationMap to determine if the user has access to only one location.
     *
     * If we've determined that the user has access to only one location, we can set that as the lastLocationId.
     * If the user has access to multiple locations, we can use the lastLocationIds from the loginData.
     */
    const accessibleLocationIds = Object.values(locationMap)
      .filter((location) => keys.includes(location.id) && location.accessible)
      .map((location) => location.id);

    const accessibleGroupIds = Object.values(groupMap)
      .filter(
        // If a group has ZERO children the user can access, we will consider that group as a valid location for now
        (group) => keys.includes(group.id) && group.accessible && !group.children.some((child) => child.accessible)
      )
      .map((group) => group.id);

    const combined = accessibleGroupIds.concat(accessibleLocationIds);

    if (combined.length === 1) {
      /**
       * Give priority to the locations first. If there's only one location, use that.
       * If there are no locations, we can use the groups.
       */
      lastLocationIds = [combined[0]];
    } else {
      lastLocationIds =
        loginData?.lastLocationIds?.filter(
          // allow parent locations to be selected as well
          (id) => accessibleLocationIds.includes(id) || accessibleGroupIds.includes(id)
        ) ?? [];
    }
  }

  /**
   * Filter this list so that we only have locations that exist in the locationMap.
   * These ids should be valid locations that the user has access to, because we're getting them from either loginData or keys
   */
  // allow parent locations to be selected as well
  lastLocationIds = lastLocationIds.filter((id) => locationMap[id] || groupMap[id]);

  const needsOrgId = lastLocationIds.length > 0;

  // If there is no valid location, we don't want the org id to be set, so that the picker doesn't have an org selected with invalid locations
  const lastOrgId = needsOrgId
    ? // allow parent locations to be selected as well
      locationMap[lastLocationIds[0]]?.orgId || groupMap[lastLocationIds[0]]?.orgId
    : undefined;

  return {
    lastLocationIds,
    lastOrgId,
  };
};

export const useInitializeAppScopeStore = () => {
  const { setLastUsedVertical } = useLastUsedVerticalShallowStore('setLastUsedVertical');
  const {
    setOrgData,
    setLocationData,
    setGroupData,
    setSelectedGroupId,
    setSelectedLocationIds,
    setSelectedOrgId,
    setScopeHierarchy,
  } = useAppScopeStore();
  const user = getUser();
  const decodedWeaveToken = getDecodedWeaveToken();
  const isWeaveUser = decodedWeaveToken?.type === 'weave';
  const keys = useMemo(() => Object.keys(decodedWeaveToken?.ACLS ?? {}), [decodedWeaveToken]);

  const { configureLocationData } = useConfigureLocation();
  const [isComplete, setIsComplete] = useState(false);
  const orgIdsForLocationsQuery = useInitialOrgIds({ user });

  const { isSuccess, orgMap, locationMap, groupMap, locationHierarchy } = useProcessScopeDataFromOrgIds({
    orgIds: orgIdsForLocationsQuery,
    allowedLocationIds: isWeaveUser ? undefined : keys,
  });

  const { lastLocationIds, lastOrgId } = useMemo(
    () =>
      determineInitialLocations({
        locationMap,
        groupMap,
      }),
    [locationMap]
  );

  function setInitialValues() {
    const scopeHierarchy = Object.entries(locationHierarchy).reduce((acc, [groupId, locations]) => {
      const group = groupMap[groupId];

      if (!group.orgId) return acc;

      if (acc[group.orgId]) {
        acc[group.orgId][group.id] = locations;
      } else {
        acc[group.orgId] = {
          [group.id]: locations,
        };
      }

      return acc;
    }, {} as Record<string, Record<string, string[]>>);

    setGroupData(groupMap);
    setLocationData(locationMap);
    setOrgData(orgMap);
    setScopeHierarchy(scopeHierarchy);

    if (lastOrgId) {
      setSelectedOrgId(lastOrgId);
    }

    if (
      lastLocationIds &&
      lastLocationIds.length > 0 &&
      (locationMap[lastLocationIds[0]] || groupMap[lastLocationIds[0]])
    ) {
      if ((locationMap[lastLocationIds[0]] || groupMap[lastLocationIds[0]]).vertical) {
        setLastUsedVertical((locationMap[lastLocationIds[0]] || groupMap[lastLocationIds[0]]).vertical as Vertical);
      }

      setSelectedLocationIds(lastLocationIds, { skipValidation: true });
      const groupId = locationMap[lastLocationIds[0]]?.groupId ?? groupMap[lastLocationIds[0]]?.id ?? '';
      setSelectedGroupId(groupId, { skipValidation: true });

      // DEPRECATE THIS
      const newLocationId = lastLocationIds[0];
      configureLocationData({
        newLocationId,

        onError: () => {
          // If the location is not found, clear the login data and refresh the page
          const loginData: string | undefined = localStorageHelper.get(LOGIN_DATA_KEY);
          if (loginData) {
            // we don't want a user to be stuck in a loop of refreshing the page
            localStorageHelper.delete(LOGIN_DATA_KEY);
            location.reload();
          }
        },
      });
    }
  }

  useEffect(() => {
    if (isSuccess && !isComplete) {
      setInitialValues();
      setIsComplete(true);
    }
  }, [isSuccess]);

  return {
    storeInitialized: isComplete,
  };
};

const tracer = new Tracer();
export const useHttpSetup = ({ token, isSessionTokenEnabled }: { token?: string; isSessionTokenEnabled: boolean }) => {
  const isSignOutTriggered = useRef(false);
  const { t } = useTranslation();
  const alerts = useAlert();
  const orgIdRef = useRef('');
  const isUserAddedToOrg = useRef(true);
  const isSessionTokenEnabledRef = useRef(false);
  const [chaosBlockedURLS, setChaosBlockedURLS] = useState<string[]>([]);
  const [chaosAllowedURLS, setChaosAllowedURLS] = useState<string[]>([]);

  const {
    setAuthorizationHeader,
    addMiddleware,
    clearMiddleware,
    addErrorMiddleware,
    clearErrorMiddleware,
    configureRetryer,
  } = useFetch();

  const retryWithTrace: Parameters<typeof configureRetryer>[0] = (request, response, attempts) => {
    if (response.ok) {
      return null;
    }
    //404s and 401s specifically should not be retried
    if (response.status === 404 || response.status === 401) {
      return null;
    }
    if (attempts > 2) {
      return null;
    }
    const id = tracer.generateId();
    const link = tracer.generateLink();
    console.log(
      `%c${link}`,

      `font-size:12px;
       padding:20px;
       border: 1px solid #8e959e;
       border-radius: 0px 5px 5px 5px;
       margin-bottom: 10px;`
    );
    request.headers.set(tracerHeader, id);
    return request;
  };

  const triggerInactiveSignOut = () => {
    if (!isSignOutTriggered.current) {
      alerts.error(t('You have been signed out due to inactivity. Please sign in again.'));
      isSignOutTriggered.current = true;
      setTimeout(() => {
        clearLastVisitedPage();
        signOut();
        isSignOutTriggered.current = false;
      }, 2000);
    }
  };

  const refreshTokenMiddleware = async (req: Request) => {
    // If under test, skip this middleware
    if (import.meta.env.MODE === 'test') {
      return req;
    }

    // If this request does not need a token, just continue with the request
    if (!req.headers.has('Authorization')) {
      return req;
    }

    /**
     *
     * OKTA
     * If okta and weave are active, then follow through with request
     *
     * If okta active and weave inactive, then renew weave token
     *
     * If okta inactive and weave active - sign out
     *
     * If okta inactive and weave inactive - sign out
     *
     *
     * LEGACY
     *
     * if weave token active - follow through
     * if weave token inactive - renew
     */

    if (!authnClient.isUserAuthenticated() && !isWeaveTokenBufferActive()) {
      triggerInactiveSignOut();
    }

    // If the token has expired, refresh it and update the request header and http instance
    if (!isWeaveTokenActive() && isWeaveTokenBufferActive()) {
      try {
        const newToken = await authnClient.refreshWeaveToken();
        req.headers.set('Authorization', `Bearer ${newToken}`);
        setAuthorizationHeader(newToken);
      } catch (error) {
        alerts.error(t("You've been signed out"));
        clearLastVisitedPage();
        return signOut().then(() => req);
      }
    }

    const currAuthHeader = req.headers.get('Authorization');
    const localStorageWeaveToken = getWeaveToken();
    if (currAuthHeader !== `Bearer ${localStorageWeaveToken}`) {
      req.headers.set('Authorization', `Bearer ${localStorageWeaveToken}`);
    }

    if (!isSessionTokenActive() && orgIdRef.current !== '' && isUserAddedToOrg.current) {
      try {
        // check for this feature flag
        if (isSessionTokenEnabledRef.current) {
          authnClient
            .assureSessionToken(orgIdRef.current)
            .then((res) => {
              if (res.includes('403')) {
                //it means the user is not part of this organization so do not call session api for this organization
                isUserAddedToOrg.current = false;
              }
            })
            .catch((error) => {
              sentry.error({
                error: 'Failed to create a new session token',
                topic: 'session',
              });
              console.error('Failed to get session token', error);
            });
        }
      } catch (error) {
        sentry.error({
          error: 'Failed to create a new session token',
          topic: 'session',
        });
        console.error('Failed to get session token', error);
      }
    }
    return req;
  };

  async function handle401Error(response: Response): Promise<Response> {
    if (response.status === 401) {
      triggerInactiveSignOut();
      return response;
    }

    return Promise.resolve(response);
  }

  async function chaosMonkeyMiddleware(req: Request): Promise<Request> {
    // Give a 50% chance of blocking a network request
    if (Math.random() > 0.5) {
      console.error('Chaos Monkey Throwing Error', req.url);
      throw new Error('Chaos Monkey');
    }
    return req;
  }

  async function chaosMonkeyWithMemoryMiddleware(req: Request): Promise<Request> {
    // Give a 50% chance of blocking a url for the duration of the session
    // it maintains the blocked and allowed urls in state so we don't grind the network requests to a halt
    if (chaosBlockedURLS.includes(req.url) || (!chaosAllowedURLS.includes(req.url) && Math.random() > 0.5)) {
      if (!chaosBlockedURLS.includes(req.url)) {
        setChaosBlockedURLS((prev: string[]) => [...prev, req.url]);
      }
      console.error('Chaos Monkey Throwing Error', req.url);
      throw new Error('Chaos Monkey');
    } else {
      setChaosAllowedURLS((prev: string[]) => [...prev, req.url]);
    }
    return req;
  }

  // turns on chaos monkey if the feature flag is on or if the app is in dev
  function shouldUseChaosMonkey(): boolean {
    // This commented out version is how we can turn on chaos monkey for dev or local for all developers.
    // I kept it commented out because it is a bit too aggressive for most developers.

    // const isDevOrLocal =
    //     import.meta.env.VITE_WEAVE_APP_URL.startsWith('https://app.weavedev.net') ||
    //     import.meta.env.VITE_WEAVE_APP_URL.startsWith('http://localhost:3001');
    // return localStorage.getItem('chaosMonkey') === 'true' ||
    //   (isDevOrLocal && localStorage.getItem('chaosMonkey') !== 'false');

    return localStorage.getItem('chaosMonkey') === 'true';
  }

  // useEffect(() => {
  //   if (locationId && locationId !== '' && previousLocationIdRef.current !== locationId) {
  //     previousLocationIdRef.current = locationId;
  //     LocationsApi.getLocation(locationId)
  //       .then((locationInfo) => {
  //         const newOrgId = determineOrgID(locationInfo);
  //         if (orgIdRef.current !== newOrgId) {
  //           orgIdRef.current = newOrgId;
  //           isUserAddedToOrg.current = true;
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // }, [locationId]);

  useEffect(() => {
    addMiddleware(refreshTokenMiddleware);
    if (shouldUseChaosMonkey()) {
      addMiddleware(
        localStorage.getItem('chaosMemory') === 'true' ? chaosMonkeyWithMemoryMiddleware : chaosMonkeyMiddleware
      );
    }
    configureRetryer(retryWithTrace);
    setAuthorizationHeader(token ?? '');
    addErrorMiddleware(handle401Error);
    const clearTokenChangeEvent = onWeaveTokenChange((newToken) => {
      setAuthorizationHeader(newToken);
    });

    return () => {
      clearMiddleware();
      clearErrorMiddleware();
      clearTokenChangeEvent();
    };
  }, []);

  useEffect(() => {
    isSessionTokenEnabledRef.current = isSessionTokenEnabled;
  }, [isSessionTokenEnabled]);
};

export const useInitializeApp = (): AppStateType => {
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const { selectedLocationIds, selectedGroupId } = useAppScopeStore();
  const isSessionTokenEnabled = getFeatureFlagValue('use-session-token');
  const weaveToken = getWeaveToken();

  useHttpSetup({ token: weaveToken, isSessionTokenEnabled });
  const locationId = selectedLocationIds[0] ?? selectedGroupId;
  const { storeInitialized } = useInitializeAppScopeStore();

  const { flagsInitialized } = useHandleScopeChange();

  useSyncShellHistory();
  useMerchant();
  useInitializePhones({ ready: true });
  setCookie({ name: 'isWeaveCustomer', value: 'true' });
  const { shouldShowOnboardingLoader, onboardingRedirectUrl } = useOnboardingRedirect({ isLocationReady: true });

  useLayoutEffect(() => {
    getShellThemeFromLocalStorage();
  }, []);

  useRemoveAppLoader();

  if (shouldShowOnboardingLoader || onboardingRedirectUrl) {
    return {
      state: shouldShowOnboardingLoader ? 'LOADING' : 'ONBOARDING',
      meta: {
        onboardingRedirectUrl,
      },
    };
  } else if (!storeInitialized || !flagsInitialized) {
    return {
      state: 'LOADING',
    };
  } else if (!locationId) {
    return {
      state: 'LOCATION_SELECTION',
    };
  } else if (locationId && storeInitialized && flagsInitialized) {
    return {
      state: 'INITIALIZED',
    };
  }
  return {
    state: 'LOADING',
  };
};
