import React from 'react';
import { Chip, ChipVariants, CategoryChipVariants } from '@frontend/design-system';
import { CallIntelChipVariant } from './hooks';

type ChipType = 'chip' | 'category';

type CallIntelChipProps<T extends ChipType = 'chip', P extends ChipVariants | CategoryChipVariants = ChipVariants> = {
  as?: T;
  chipVariants: CallIntelChipVariant<P>;
  dataKey: string;
  label: string;
} & (T extends 'category'
  ? Omit<React.ComponentProps<typeof Chip.Category>, 'label'>
  : Omit<React.ComponentProps<typeof Chip>, 'children'>);

const CallIntelChip = <T extends ChipType = 'chip', P extends ChipVariants | CategoryChipVariants = ChipVariants>({
  as = 'chip' as T,
  chipVariants,
  dataKey,
  label,
  ...props
}: CallIntelChipProps<T, P>) => {
  const { variant, variantColor } = chipVariants[dataKey] || {};

  if (as === 'category') {
    return (
      <Chip.Category indicatorColor={variantColor} variant={variant as CategoryChipVariants}>
        {label}
      </Chip.Category>
    );
  }

  return (
    <Chip variant={variant as ChipVariants} {...(props as React.ComponentProps<typeof Chip>)}>
      {label}
    </Chip>
  );
};

export default CallIntelChip;
