import dayjs from 'dayjs';
import { Layout } from 'react-grid-layout';
import { WidgetSizes, DashboardBreakpoints } from '@frontend/grid-dashboard';
import { i18next } from '@frontend/i18n';

export type WidgetConfig = {
  id: string;
  enabled: boolean;
  size: Record<DashboardBreakpoints, WidgetSizes> | WidgetSizes;
  positions?: Record<DashboardBreakpoints, Layout>;
};

export function getWelcomeGreeting(name: string) {
  const now = dayjs();
  const hour = now.hour();
  const day = now.day();

  let greeting;
  let punctuation;

  if (hour >= 20) {
    greeting = i18next.t('Late night', { ns: 'dashboard' });
    punctuation = '?';
  } else if (hour < 7) {
    greeting = i18next.t('The early bird gets the worm', { ns: 'dashboard' });
    punctuation = '!';
  } else {
    switch (day) {
      case 1:
        greeting = i18next.t('Happy Monday', { ns: 'dashboard' });
        punctuation = '';
        break;
      case 5:
        greeting = i18next.t('Happy Friday! You made it', { ns: 'dashboard' });
        punctuation = '!';
        break;
      case 2:
      case 3:
      case 4:
        greeting = i18next.t('Welcome back', { ns: 'dashboard' });
        punctuation = '.';
        break;
      case 0:
      case 6:
        greeting = i18next.t('Welcome', { ns: 'dashboard' });
        punctuation = '.';
        break;
      default:
        greeting = i18next.t('Hello', { ns: 'dashboard' });
        punctuation = '.';
    }
  }

  return { greeting, nameWithPunctuation: name ? `, ${name}${punctuation}` : punctuation };
}
