import { useEffect, useState } from 'react';
import {
  Campaign,
  CreateCampaignRequest,
  Status_Enum as CampaignStatus,
  UpdateCampaignRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { BulkMessagingMutations } from '@frontend/api-bulk-messaging';
import { convertObjectUpdateToFieldMaskPaths } from '@frontend/schema-field-mask';
import { useAppScopeStore } from '@frontend/scope';
import { useFormatDynamicFieldAndLabel } from './use-format-dynamic-field-and-label';

const CAN_SAVE_AS_DRAFT_STATUSES = [
  CampaignStatus.UNSPECIFIED,
  CampaignStatus.DRAFT,
  CampaignStatus.HIDDEN_DRAFT,
  CampaignStatus.SCHEDULED,
];

export const useBulkEmailEditorActions = (originalCampaign?: Campaign) => {
  const isCreate = originalCampaign === undefined;
  const { selectedOrgId } = useAppScopeStore();
  const { mutateAsync: createCampaign } = BulkMessagingMutations.useCreateCampaign();
  const { mutateAsync: updateCampaign } = BulkMessagingMutations.useUpdateCampaign();
  const { convertLabelToDynamicField } = useFormatDynamicFieldAndLabel();
  const [lastSavedCampaign, setLastSavedCampaign] = useState(originalCampaign);

  useEffect(() => {
    setLastSavedCampaign(originalCampaign);
  }, [originalCampaign]);

  const saveAs = async (state: Campaign, saveAsStatus?: CampaignStatus) => {
    const currentStatus =
      saveAsStatus ??
      (state.currentStatus !== CampaignStatus.UNSPECIFIED ? state.currentStatus : CampaignStatus.SCHEDULED);

    const updatedCampaign = {
      ...state,
      currentStatus,
    };

    // Attachments currently only support one file
    const attachmentMediaIds =
      state.locationIds?.length === 1 && state.attachmentMediaIds?.length
        ? state.attachmentMediaIds.slice(0, 1)
        : undefined;

    // The subject field contains readable labels and needs to be converted to dynamic fields so the backend can interpolate the values
    const subject = convertLabelToDynamicField(state.subject);

    const request: CreateCampaignRequest | UpdateCampaignRequest = {
      orgId: selectedOrgId,
      campaign: {
        ...updatedCampaign,
        attachmentMediaIds,
        subject,
      },
    };

    const updateMask = createUpdateMask(lastSavedCampaign, updatedCampaign);

    const response =
      isCreate && !request.campaign?.campaignId
        ? await createCampaign(request)
        : await updateCampaign({
            ...request,
            updateMask,
          });

    if (!response.campaignId) {
      throw new Error('A campaign id was not returned from the server');
    }

    setLastSavedCampaign(updatedCampaign);
    return response.campaignId;
  };

  const canSaveAsDraft = !!(
    isCreate || CAN_SAVE_AS_DRAFT_STATUSES.find((status) => status === lastSavedCampaign?.currentStatus)
  );

  return {
    onSaveDraft: canSaveAsDraft ? (state: Campaign) => saveAs(state, CampaignStatus.DRAFT) : undefined,
    onSaveHiddenDraft: isCreate ? (state: Campaign) => saveAs(state, CampaignStatus.HIDDEN_DRAFT) : undefined,
    onSchedule: (state: Campaign) => saveAs(state, CampaignStatus.SCHEDULED),
  };
};

const createUpdateMask = (lastSavedCampaign: Campaign | undefined, updatedCampaign: Campaign) => {
  const { campaignId, type, ...sanitizedUpdatedCampaign } = updatedCampaign;
  const paths = convertObjectUpdateToFieldMaskPaths(
    { campaign: lastSavedCampaign },
    { campaign: sanitizedUpdatedCampaign },
    {
      filter: 'remove-nullish',
    }
  );

  return {
    paths: paths,
    toJSON: () => paths.join(','),
  };
};

/*
  These are the different cases for the campaign statuses and the actions that can be taken for each status:
  Status => Actions => Create or Update or N/A

  CampaignStatus_Enum.DELETED => can't access this in the UI => N/A
  
  CampaignStatus_Enum.CANCELED => schedule (update title) => Update
  CampaignStatus_Enum.COMPLETED => schedule (update title) => Update
  CampaignStatus_Enum.FAILED => schedule (update title) => Update
  CampaignStatus_Enum.PROCESSING => schedule (update title) => Update
  CampaignStatus_Enum.SCHEDULED => draft, schedule => Update
  
  CampaignStatus_Enum.DRAFT => draft, schedule => Create
  CampaignStatus_Enum.HIDDEN_DRAFT => draft, schedule => Create
  CampaignStatus_Enum.CAMPAIGN_STATUS_UNSPECIFIED => draft, hidden, schedule => Create (but honestly it should never hit this case)
*/
