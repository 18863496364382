import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { contextFactory } from '@frontend/design-system';

export interface FeatureSubscriptionContextProps {
  selectedLocationIds?: string[];
  isUpgradeOrTrialEligible: boolean;
  isFreeTrialEligible: boolean;
  isUpgradeEligible: boolean;
  canSelfSubscribe: boolean;
  feature: Feature;
  isLoading: boolean;
  invalidateSubscriptionConfig: () => void;
  selfUpgradeData: FreeTrialSubscriptionTypes.IsMultiEligibleForSelfUpgradeOrTrialDetail[];
  locationWiseAdminAccess: Record<string, boolean>;
  hasAdminAccessInAtLeastOne: boolean;
  isBundleUpgradeEligible: boolean;
  recommendedBundle: SalesforceAccountProductBundle;
}

export const [FeatureSubscriptionContext, useFeatureSubscription] = contextFactory<FeatureSubscriptionContextProps>();
