import { css, SerializedStyles } from '@emotion/react';
import dayjs from 'dayjs';
import { PersonTypes } from '@frontend/api-person';
import { Photos } from '@frontend/photos';
import { titleCase } from '@frontend/string';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { styles as dsStyles } from '@frontend/design-system';

const styles = {
  person: {
    info: css`
      display: flex;
      flex-flow: row;
      gap: ${theme.spacing(1)};
      align-items: center;
    `,
    name: css`
      width: 100%;
    `,
    details: css``,
    detailsContainer: css``,
  },
};

export const personFullName = (patient: PersonTypes.Person) => `${patient?.FirstName ?? ''} ${patient?.LastName ?? ''}`;

export const PaymentsPersonSearchContent = ({
  person,
  css: cssStyle,
}: {
  person: PersonTypes.Person;
  css?: SerializedStyles;
}) => {
  return (
    <div css={[styles.person.info, cssStyle]}>
      <Photos.ContactProfilePhoto personId={person.PersonID} name={personFullName(person)} size='medium' disableClick />
      <div css={styles.person.detailsContainer}>
        <Text css={styles.person.name} textAlign='left'>
          {personFullName(person)}
        </Text>
        <PersonSecondaryInfo person={person} />
      </div>
    </div>
  );
};
const PersonSecondaryInfo = ({ person }: { person: PersonTypes.Person }) => (
  <Text
    color='light'
    size='small'
    textAlign='left'
    css={[
      css`
        max-width: 100%;
      `,
      dsStyles.truncate,
    ]}
  >
    {getPersonV3SecondaryInfoString(person)}
  </Text>
);

const formatStatus = (status?: PersonTypes.Person['Status']): string => {
  if (!status) return '';
  const withSpaces = status.replace(/_/g, ' ');
  return titleCase(withSpaces);
};

const getPersonV3SecondaryInfoString = (person: PersonTypes.Person) => {
  const personStatus = formatStatus(person.Status);
  const stringElements: (string | number)[] = [];
  if (person.Gender) stringElements.push(person.Gender);
  if (person.Birthdate) stringElements.push(dayjs(person.Birthdate).diff(dayjs(), 'years') * -1);
  if (personStatus) stringElements.push(personStatus);
  return stringElements.join(' • ');
};
