import React, { useRef } from 'react';
import { CSSInterpolation } from '@emotion/css';
import { css } from '@emotion/react';
import { useAnimate, AnimationScope } from 'motion/react';
import type { WeavePopNotification, WeavePopNotificationActions, WeaveNotificationActions } from '@frontend/types';
import { theme } from '@frontend/theme';
import { contextFactory } from '@frontend/design-system';
import { NotificationContent } from './notification-content';
import { NotificationProgressBar } from './progress-bar';
import { Slider } from './slider';
import { useNotificationTimeout } from './use-notification-timeout';

type NotificationContextType = {
  notification: WeavePopNotification;
  onEmit: (
    action: WeavePopNotificationActions | WeaveNotificationActions,
    notification: WeavePopNotification,
    extra?: any
  ) => void;
  scope: AnimationScope<any>;
  animate: (target: HTMLElement, keyframes: any, options?: any) => any;
};

const [NotificationContext, useNotificationContext] = contextFactory<NotificationContextType>();

type NotificationProps = {
  notification: WeavePopNotification;
  onEmit: (
    action: WeavePopNotificationActions | WeaveNotificationActions,
    notification: WeavePopNotification,
    extra?: any
  ) => void;
  children: React.ReactNode;
  ContainerComponent?: React.ElementType;
  containerProps?: Record<string, any>;
  containerStyles?: CSSInterpolation;
};

export const Notification = ({
  notification,
  onEmit,
  children,
  ContainerComponent = 'div',
  containerProps,
  containerStyles,
}: NotificationProps) => {
  const [scope, animate] = useAnimate();
  const containerRef = useRef<HTMLDivElement>(null);

  const { handleMouseEnter, handleMouseLeave } = useNotificationTimeout({
    notification,
    onEmit,
    scope,
    animate,
  });

  const defaultStyles = css`
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadows.heavy};
    position: relative;
    height: 100%;
    width: fit-content;
  `;

  const contextValue: NotificationContextType = {
    notification,
    onEmit,
    scope,
    animate,
  };

  const Container = ContainerComponent;

  return (
    <NotificationContext.Provider value={contextValue}>
      <Container
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        css={[defaultStyles, containerStyles]}
        {...containerProps}
      >
        {children}
      </Container>
    </NotificationContext.Provider>
  );
};

const ProgressBar = () => {
  const { scope } = useNotificationContext();
  return <NotificationProgressBar scope={scope} />;
};
Notification.ProgressBar = ProgressBar;

Notification.Slider = Slider;

const Content = ({ stacked = false }) => {
  const { notification, onEmit } = useNotificationContext();
  return <NotificationContent notification={notification} stacked={stacked} onEmit={onEmit} />;
};
Notification.Content = Content;

export default Notification;
