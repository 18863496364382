import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { PersonHelpers } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions } from '@frontend/contact-actions';
import { formatDate } from '@frontend/date';
import { FirstWordBoldText, WidgetizedDashboardTrackingIds } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, usePopoverMenu } from '@frontend/design-system';
import { useGetAppointmentDetails } from '../../hooks';

interface Props {
  appointmentInfo: Appointment;
}

const appointmentsWidgetTrackingId =
  WidgetizedDashboardTrackingIds.widgetSpecificTrackingId('appointments-widget-action');

export const AppointmentCard = ({ appointmentInfo }: Props) => {
  const { t } = useTranslation('schedule');
  const popoverMenuProps = usePopoverMenu();

  const person = appointmentInfo.person ?? {};
  const personName = PersonHelpers.getFullName({
    FirstName: person.firstName,
    LastName: person.lastName,
    PreferredName: person.preferredName,
  });
  const fromTime = formatDate(appointmentInfo.start, 'h:mm A');
  const toTime = formatDate(dayjs(appointmentInfo.start).add(appointmentInfo.duration ?? 0, 'm'), 'h:mm A');
  const personPhoneNumber = person.mobilePhone || person.homePhone || person.workPhone || '';

  const { triggerProps: viewAppointmentTriggerProps, Modal: AppointmentDetailsModal } = useGetAppointmentDetails({
    appointmentId: appointmentInfo.id || '',
    locationId: appointmentInfo.locationId || '',
    appointments: [appointmentInfo],
    enabled: true,
  });

  const handleViewAppointmentClick = () => {
    popoverMenuProps.close();
    viewAppointmentTriggerProps.onClick();
  };

  return (
    <li css={[containerStyle, { borderColor: theme.colors.warning50 }]}>
      <div>
        <FirstWordBoldText size='medium'>{personName}</FirstWordBoldText>
        <Text size='small' color='light'>
          {fromTime} - {toTime}
        </Text>
      </div>
      <AdaptoActions variant='icon-buttons' iconColor='light' spacing={0.5} size='small'>
        <Actions.Call
          label={t('Call {{name}}', {
            name: personName || personPhoneNumber,
          })}
          context={{
            personId: person.personId,
            phoneNumber: personPhoneNumber,
            locationId: appointmentInfo.locationId,
          }}
          trackingIdSuffix='appointments-widget'
        />

        <Actions.Message
          label={t('Message {{name}}', {
            name: personName || personPhoneNumber,
          })}
          context={{
            personId: person.personId,
            personPhone: personPhoneNumber,
            groupIds: appointmentInfo.locationIds,
          }}
          trackingIdSuffix='appointments-widget'
        />
        <AdaptoActions.Action
          label={t('View Appointment')}
          icon='calendar-small'
          onClick={handleViewAppointmentClick}
          trackingId={appointmentsWidgetTrackingId('view-appointment')}
        />
      </AdaptoActions>
      {AppointmentDetailsModal}
    </li>
  );
};

const containerStyle = css({
  borderLeft: '4px solid',
  background: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(1),
  boxShadow: theme.shadows.light,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
