import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { SchemaFaxMediaService, SchemaIO } from '@frontend/schema';

const keys = {
  getFaxMediaAttributes: (mediaId: string) => ['fax-media-attributes', mediaId],
  generateMediaAttributes: () => ['generate-media-attributes'],
  listCoversheetTemplate: (locationId: string, locationIds: string[]) => [
    'list-coversheet-template',
    locationId,
    ...locationIds,
  ],
  getCoversheetTemplate: (id: string, locationId: string) => ['coversheet-template', locationId, id],
};

type SchemaGetMediaAttributes = SchemaIO<(typeof SchemaFaxMediaService)['GetMediaAttributes']>;

export const useGetMediaAttributes = (
  mediaId: string,
  locationId: string,
  opts: UseQueryOptions<SchemaGetMediaAttributes['output']> = {}
) =>
  useQuery({
    queryKey: keys.getFaxMediaAttributes(mediaId),
    queryFn: () => SchemaFaxMediaService.GetMediaAttributes({ mediaId, locationId }),
    ...opts,
  });

type SchemaGenerateMediaAttributes = SchemaIO<(typeof SchemaFaxMediaService)['GenerateMediaAttributes']>;

export const useGenerateMediaAttributes = (
  opts: UseMutationOptions<
    SchemaGenerateMediaAttributes['output'],
    unknown,
    SchemaGenerateMediaAttributes['input'],
    unknown
  > = {}
) =>
  useMutation({
    mutationKey: keys.generateMediaAttributes(),
    mutationFn: SchemaFaxMediaService.GenerateMediaAttributes,
    ...opts,
  });

type SchemaListCoversheetTemplate = SchemaIO<(typeof SchemaFaxMediaService)['ListCoversheetTemplate']>;

export const useListCoversheetTemplate = (
  locationId: string,
  locationIds: string[],
  opts: UseQueryOptions<SchemaListCoversheetTemplate['output']> = {}
) =>
  useQuery({
    queryKey: keys.listCoversheetTemplate(locationId, locationIds),
    queryFn: () => SchemaFaxMediaService.ListCoversheetTemplate({ locationId, locationIds }),
    ...opts,
  });

type SchemaGetCoversheetTemplate = SchemaIO<(typeof SchemaFaxMediaService)['GetCoversheetTemplate']>;

export const useGetCoversheetTemplate = (
  id: string,
  locationId: string,
  opts: UseQueryOptions<SchemaGetCoversheetTemplate['output']> = {}
) =>
  useQuery({
    queryKey: keys.getCoversheetTemplate(id, locationId),
    queryFn: () => SchemaFaxMediaService.GetCoversheetTemplate({ id, locationId }),
    ...opts,
  });

type UpsertCoversheetTemplateRes = SchemaIO<(typeof SchemaFaxMediaService)['UpsertCoversheetTemplate']>['output'];
type UpsertCoversheetTemplateReq = SchemaIO<(typeof SchemaFaxMediaService)['UpsertCoversheetTemplate']>['input'];
export const useUpsertCoversheetTemplate = (
  opts: UseMutationOptions<UpsertCoversheetTemplateRes, unknown, UpsertCoversheetTemplateReq> = {}
) => {
  return useMutation({
    mutationKey: 'UpsertCoversheetTemplate',
    mutationFn: (template) => {
      return SchemaFaxMediaService.UpsertCoversheetTemplate(template);
    },
    ...opts,
  });
};

type DeleteCoversheetTemplateRes = SchemaIO<(typeof SchemaFaxMediaService)['DeleteCoversheetTemplate']>['output'];
type DeleteCoversheetTemplateReq = SchemaIO<(typeof SchemaFaxMediaService)['DeleteCoversheetTemplate']>['input'];
export const useDeleteCoversheetTemplate = (
  opts: UseMutationOptions<DeleteCoversheetTemplateRes, unknown, DeleteCoversheetTemplateReq> = {}
) =>
  useMutation({
    mutationKey: 'DeleteCoversheetTemplate',
    mutationFn: SchemaFaxMediaService.DeleteCoversheetTemplate,
    ...opts,
  });
