import { FC, useRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { TaskCenterTypes } from '@frontend/api-task-center';
import { useTranslation } from '@frontend/i18n';
import { IconName, Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, useTooltip, statusSwitcherVariantsColor } from '@frontend/design-system';

interface StatusUpdaterProps {
  onAction: () => void;
  status?: TaskCenterTypes.TaskStatus;
}

const getIconName = (status?: TaskCenterTypes.TaskStatus): { name: IconName; color?: string } => {
  if (status === TaskCenterTypes.TaskStatus.STATUS_COMPLETED) {
    return { name: 'check-small', color: theme.colors.neutral70 };
  } else if (status === TaskCenterTypes.TaskStatus.STATUS_IN_PROGRESS) {
    return { name: 'in-progress-small', color: theme.colors.neutral90 };
  } else if (status === TaskCenterTypes.TaskStatus.STATUS_TODO) {
    return { name: 'pending-small', color: theme.colors.neutral60 };
  } else {
    return { name: 'pending-small', color: statusSwitcherVariantsColor['default'].color };
  }
};

export const StatusUpdater: FC<StatusUpdaterProps> = ({ status, onAction }) => {
  const { t } = useTranslation('task-center');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'bottom' });
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { name, color } = getIconName(status);

  useEffect(() => {
    const onEnter = () => setIsHovering(true);
    const onFocused = () => setIsFocused(true);

    const onLeave = () => setIsHovering(false);
    const onBlurred = () => setIsFocused(false);

    buttonRef.current?.addEventListener('mouseenter', onEnter);
    buttonRef.current?.addEventListener('mouseleave', onLeave);

    buttonRef.current?.addEventListener('focus', onFocused);
    buttonRef.current?.addEventListener('blur', onBlurred);
    return () => {
      buttonRef.current?.removeEventListener('mouseenter', onEnter);
      buttonRef.current?.removeEventListener('mouseleave', onLeave);

      buttonRef.current?.removeEventListener('focus', onFocused);
      buttonRef.current?.removeEventListener('blur', onBlurred);
    };
  }, []);

  return (
    <span ref={buttonRef}>
      <Button {...triggerProps} onClick={onAction} variant='tertiary' css={buttonStyle}>
        <Icon
          name={isFocused || isHovering ? 'mark-done' : name}
          css={css({ color: isFocused || isHovering ? theme.colors.neutral70 : color })}
          size={16}
        />
      </Button>
      <Tooltip {...tooltipProps}>{t('Mark as Done')}</Tooltip>
    </span>
  );
};

const buttonStyle = css`
  :hover {
    background-color: ${theme.colors.neutral10};
  }
`;
