import { FooterSection, Frame } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { useTranslation } from '@frontend/i18n';
import { useSettingsForm } from '../../hooks';
import { AlignmentSetting, FrameSetting, PaddingSetting, SettingsPanel } from './atoms';

export const FooterSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<FooterSection>({
    frame: {
      alignment: { type: 'radio' },
      backgroundColor: { type: 'text' },
      paddingTop: { type: 'text' },
      paddingBottom: { type: 'text' },
      paddingLeft: { type: 'text' },
      paddingRight: { type: 'text' },
    },
  });

  return (
    <SettingsPanel title={t('Footer Styling')}>
      <AlignmentSetting {...getFieldProps<Frame>('frame', 'alignment')} />
      <FrameSetting {...getFieldProps<Frame>('frame', 'backgroundColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps<Frame>('frame', 'paddingTop')}
        paddingBottomFieldProps={getFieldProps<Frame>('frame', 'paddingBottom')}
        paddingLeftFieldProps={getFieldProps<Frame>('frame', 'paddingLeft')}
        paddingRightFieldProps={getFieldProps<Frame>('frame', 'paddingRight')}
        isLast
      />
    </SettingsPanel>
  );
};
