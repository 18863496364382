import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { SelfUpgradeActionType } from './self-upgrade';

export const SelfUpgradeLandingPageTrackingIds = {
  multiLocationBannerUpgradeAction: 'landing-page-multi-location-banner-upgrade-action-button',
  viewDemoAction: 'landing-page-view-demo-action-button',
};

export const FeatureUpgradeModalTrackingIds = {
  upgradeAction: 'feature-upgrade-modal-upgrade-action-button',
  scheduleCallAction: 'feature-upgrade-modal-schedule-call-action-button',
  requestUpgradeAction: 'feature-upgrade-modal-request-upgrade-action-button',
  freeTrialAction: 'feature-upgrade-modal-free-trial-action-button',
  dismissAction: 'feature-upgrade-modal-dismiss-action-button',
};

export const SelfUpgradeModalTrackingIds = {
  nextAction: 'self-upgrade-modal-location-selection-next-action-button',
  upgradeAction: 'self-upgrade-modal-upgrade-action-button',
  scheduleCallAction: 'self-upgrade-modal-schedule-call-action-button',
  dismissAction: 'self-upgrade-modal-dismiss-action-button',
  termsCheckbox: 'self-upgrade-modal-terms-checkbox',
  viewTermsAction: 'self-upgrade-modal-view-terms-action-button',
  onboardingCallAction: 'self-upgrade-modal-onboarding-call-action-button',
  successModalCloseAction: 'self-upgrade-success-modal-close-action-button',
  successModalOkayAction: 'self-upgrade-success-modal-okay-action-button',
  failModalCloseAction: 'self-upgrade-fail-modal-close-action-button',
  overdueModalCloseAction: 'self-upgrade-overdue-modal-close-action-button',
  billingLink: 'self-upgrade-modal-billing-link',
  weaveHelpLink: 'self-upgrade-modal-weave-help-link',
};

export const FreeTrialModalTrackingIds = {
  freeTrialAction: 'free-trial-modal-free-trial-action-button',
  dismissAction: 'free-trial-modal-dismiss-action-button',
};

export const RequestUpgradeModalTrackingIds = {
  requestUpgradeAction: 'request-upgrade-modal-request-upgrade-action-button',
  dismissAction: 'request-upgrade-modal-dismiss-action-button',
};

export const FormsUpgradeModalTrackingIds = {
  additionalDigitizationCheckbox: 'forms-upgrade-modal-additional-digitization-checkbox',
};

export const FormsDigitizationModalTrackingIds = {
  upgradeAction: 'forms-digitization-modal-continue-upgrade-button',
  dismissAction: 'forms-digitization-modal-dismiss-action-button',
};

export const UpgradePromotionIconTrackingIdsMap: Partial<Record<Feature, string>> = {
  [Feature.FORMS]: 'upgrade-promotion-icon-forms',
};

export const UpgradeActionButtonTrackingIdMap: Record<SelfUpgradeActionType | 'DISMISS', string> = {
  DISMISS: 'dismiss-action-button',
  [SelfUpgradeActionType.REQUEST_UPGRADE]: 'request-upgrade-action-button',
  [SelfUpgradeActionType.SCHEDULE_CALL]: 'schedule-call-action-button',
  [SelfUpgradeActionType.FREE_TRIAL]: 'free-trial-action-button',
  [SelfUpgradeActionType.UPGRADE_NOW]: 'upgrade-action-button',
  [SelfUpgradeActionType.NONE]: 'none-action-button',
};

export const DismissActionButtonTrackingIdMap: Record<
  FeatureNotificationTypes.FeatureNotificationAction | 'DISMISS',
  string
> = {
  DISMISS: 'dismiss-action-button',
  [FeatureNotificationTypes.FeatureNotificationAction.ACTION_UNKNOWN]: 'unknown-action-button',
  [FeatureNotificationTypes.FeatureNotificationAction.ACTION_NEVER_SHOW_AGAIN]: 'never-show-again-action-button',
  [FeatureNotificationTypes.FeatureNotificationAction.ACTION_REMIND_AFTER_3_DAYS]: 'remind-3-days-action-button',
};

export const getFeatureWiseTrackingId = (feature: Feature, trackingId: string) => {
  return `${feature}-${trackingId}`;
};

type UpgradeType = 'feature' | 'bundle';

const buildTrackingId = ({
  feature,
  source,
  upgradeType,
  trackingId,
}: {
  feature: Feature;
  source?: string;
  upgradeType: UpgradeType;
  trackingId: string;
}) => [feature, source, upgradeType, trackingId].filter((str) => !!str).join('-');

type FeaturePromoSourceType = 'landing-page' | 'demo-modal' | 'promotion-card' | '';

export const getFeaturePromoActionTrackingId = ({
  feature,
  source = '',
  action,
  upgradeType = 'feature',
}: {
  feature: Feature;
  source?: FeaturePromoSourceType;
  action: keyof typeof UpgradeActionButtonTrackingIdMap;
  upgradeType: UpgradeType;
}) => buildTrackingId({ feature, source, upgradeType, trackingId: UpgradeActionButtonTrackingIdMap[action] });

export const getNotificationTeaserTrackingId = ({
  featureEnum,
  source,
  upgradeType,
  upgradeAction,
  dismissAction,
}: {
  featureEnum: Feature;
  source: FeatureNotificationTypes.FeatureNotificationSource;
  upgradeType: UpgradeType;
  upgradeAction?: keyof typeof UpgradeActionButtonTrackingIdMap;
  dismissAction?: keyof typeof DismissActionButtonTrackingIdMap;
}) =>
  buildTrackingId({
    feature: featureEnum,
    source,
    upgradeType,
    trackingId: upgradeAction
      ? UpgradeActionButtonTrackingIdMap[upgradeAction]
      : dismissAction
      ? DismissActionButtonTrackingIdMap[dismissAction]
      : '',
  });

export const getFormsTeaserTrackingId = ({ source, upgradeType }: { source: string; upgradeType: UpgradeType }) =>
  buildTrackingId({
    feature: Feature.FORMS,
    source,
    upgradeType,
    trackingId: 'action-button',
  });

export const BundleUpgradeTrackingIds = {
  dismissAction: 'bundle-upgrade-modal-dismiss-action-button',
  termsCheckbox: 'bundle-upgrade-modal-terms-checkbox',
  viewTermsAction: 'bundle-upgrade-modal-view-terms-action-button',
  backButton: 'bundle-upgrade-modal-back-button',
  upgradeAction: 'bundle-upgrade-modal-upgrade-action-button',
  requestUpgradeAction: 'bundle-upgrade-modal-request-upgrade-action-button',
  trialAction: 'bundle-upgrade-modal-trial-action-button',
};

export const BundleTrialActiveTrackingIds = {
  dismissAction: 'bundle-trial-active-modal-dismiss-action-button',
  doneAction: 'bundle-trial-active-modal-done-action-button',
  trainingWidgetLink: 'bundle-trial-active-modal-training-widget-link',
  introVideoLink: 'bundle-trial-active-modal-intro-video-link',
  joinWebinarLink: 'bundle-trial-active-modal-join-webinar-link',
};

export const BundleRequestUpgradeTrackingIds = {
  dismissAction: 'bundle-request-upgrade-modal-dismiss-action-button',
  requestAction: 'bundle-request-upgrade-modal-request-action-button',
};

export const BundleTrialDashboardTrackingIds = {
  upgradeAction: 'bundle-trial-dashboard-upgrade-action-button',
  bundleTraining: 'bundle-trial-dashboard-bundle-training-button',
};
