import { useCallback } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { useLocalStorage } from '@frontend/web-storage';

const SKIP_WIDGET_STORAGE_KEY = 'bundleDashboardSkipWidget';

export const useSkipBundleDashboardWidgets = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const [data, setData] = useLocalStorage<{ [locationId: string]: { [widgetId: string]: boolean } }>({
    key: SKIP_WIDGET_STORAGE_KEY,
    defaultValue: {},
  });

  const skipWidget = useCallback(
    (widgetId: string) => {
      setData({ ...skipWidget, [locationId]: { ...data?.[locationId], [widgetId]: true } });
    },
    [locationId, data]
  );

  const isWidgetSkipped = useCallback(
    (widgetId: string) => {
      return !!data?.[locationId]?.[widgetId];
    },
    [locationId, data]
  );

  return {
    skipWidget,
    isWidgetSkipped,
  };
};
