import { css } from '@emotion/react';
import { InvoiceFilterType } from '@frontend/api-invoices';
import { theme } from '@frontend/theme';
import { InvoicesQuickFillBar } from '../../Invoices';

const styles = {
  dateFilters: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(3)};
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
  `,
};

export type DateFiltersProps = {
  dateRange: InvoiceFilterType['dateRange'];
  onChangeDateRange?: (dateRange: InvoiceFilterType['dateRange']) => void;
};
export const DateFilters = ({ dateRange, onChangeDateRange }: DateFiltersProps) => {
  return (
    <section css={styles.dateFilters}>
      <InvoicesQuickFillBar
        dateRange={dateRange}
        onChange={onChangeDateRange}
        showAllTimeButton={true}
        enableDropDownFilter={false}
      />
    </section>
  );
};
