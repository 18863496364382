import { useSchemaMutation } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaEmailSendV2Service, serviceName } from '../service';
import { MutationEndpointName, ServiceMutations } from '../types';

export type UseEmailSendV2MutationArgs<
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
> = Omit<
  Parameters<typeof useSchemaMutation<ServiceMutations, EndpointName, E, C, OtherOptions, RequestOverride>>[0],
  'service' | 'serviceName'
>;

/**
 * A wrapper around useSchemaMutation that uses the EmailSendV2 service and serviceName.
 * This is intended to only be used internally in the `mutations` directory of the library and not exported.
 */
export const useEmailSendV2Mutation = <
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
>(
  args: UseEmailSendV2MutationArgs<EndpointName, E, C, OtherOptions, RequestOverride>
) =>
  useSchemaMutation<ServiceMutations, EndpointName, E, C, OtherOptions, RequestOverride>({
    service: SchemaEmailSendV2Service,
    serviceName,
    ...args,
  });

export type UseEmailSendV2MutationEndpointArgs<
  EndpointName extends MutationEndpointName,
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations[EndpointName]>['input'] = SchemaIO<
    ServiceMutations[EndpointName]
  >['input']
> = Omit<UseEmailSendV2MutationArgs<EndpointName, E, C, OtherOptions, RequestOverride>, 'endpointName'> & {
  optimisticUpdate?: boolean;
};
