import { format } from 'date-fns';
import { shell } from '@frontend/shell-utils';
import { DownloadPayload, createDownloadLink } from './';

export function openPDFInNewWindow(blob: Blob) {
  const fileURL = URL.createObjectURL(blob);
  try {
    if (shell.isShell) {
      shell.emit?.('window:open', { url: fileURL });
      return;
    }

    const pdfWindow = window.open(fileURL, '_blank');
    if (pdfWindow) {
      const interval = setInterval(() => {
        if (pdfWindow.closed) {
          window.URL.revokeObjectURL(fileURL);
          clearInterval(interval);
        }
      }, 500);
    }
  } catch {
    window.URL.revokeObjectURL(fileURL);
  }
}

export function generateFileName(pdfName: string) {
  const regExp = /[ #<${+%>!`&.*'|{?"=}/:\\@-]+/gi;
  const formattedPDFName = pdfName.trim().replace(regExp, '_');
  const date = format(Date.now(), 'yyyyMMdd');
  return `${formattedPDFName}_${date}`;
}

export function savePDF(data: DownloadPayload) {
  let { base64Data } = data;
  base64Data = `data:application/pdf;base64,${base64Data}`;
  return createDownloadLink({ ...data, base64Data });
}
