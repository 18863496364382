import { FieldConfigUnion, FormConfig, useForm } from '@frontend/design-system';
import { useComposerSection } from './use-composer-section';

type RootField<RK> = FieldConfigUnion & { value?: RK };
type NestedField<RK> = { [N in keyof RK]: FieldConfigUnion & { value?: RK[N] } };
type ExcludedField<T> = Exclude<keyof T, 'sectionType'>;

const isRootField = <T>(field: RootField<T> | NestedField<T>): field is RootField<T> => {
  return 'type' in field; // if it has a type field, it's a root field - type is the only guaranteed property on a incoming form field
};

// TODO: make the type more strict so it only accepts the fields that are in the schema
export const useSettingsForm = <T>(fields?: {
  [K in ExcludedField<T>]?: RootField<T[K]> | NestedField<T[K]>;
}) => {
  const { setProps, props } = useComposerSection<T>();
  const formattedFields: FormConfig = {};

  for (const key in fields) {
    const rootField = fields[key as ExcludedField<T>] as
      | RootField<T[ExcludedField<T>]>
      | NestedField<T[ExcludedField<T>]>
      | undefined;
    if (!rootField) continue;
    if (isRootField(rootField)) {
      const rootValue = (props as any)[key];
      formattedFields[key] = {
        ...rootField,
        value: rootValue ? rootValue : rootField.value,
      };
    } else {
      for (const nestedKey in rootField) {
        const nestedField = rootField[nestedKey];
        const nestedValue = (props as any)[key]?.[nestedKey];
        formattedFields[nestedKey] = {
          ...nestedField,
          value: nestedValue ? nestedValue : nestedField.value,
        };
      }
    }
  }

  // form needs to conform to the DS useForm type
  const form = useForm({
    fields: formattedFields,
  });

  const getFieldProps = <RootKey>(rootKey: keyof T, nestedKey?: keyof RootKey) => {
    const keyToUse = nestedKey ? nestedKey : rootKey; // determine where to get the value from
    const fieldProps = form.getFieldProps(keyToUse as string);

    return {
      ...fieldProps,
      onChange: (e: any) => {
        fieldProps.onChange(e);

        setProps((props) => {
          if (nestedKey) {
            // @ts-ignore - ds types are incorrect
            if (props[rootKey]) props[rootKey][nestedKey] = e.target?.value ?? e.value;
            return;
          }
          // @ts-ignore - ds types are incorrect
          props[rootKey] = e.target?.value ?? e.value;
        });
      },
    } as any;
  };

  return {
    getFieldProps,
  };
};
