import { SuperTextarea, SuperTextareaProps } from '@frontend/super-textarea';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { useFileUpload } from '@frontend/design-system';
import { DropzoneMessage } from '../../../common';
import { ThreadSendingMediaItem } from '../../types';
import { MediaSendingPreviewArea } from './media-sending-preview-area';

type ThreadSendingAreaProps = CommonHTMLAttributes & {
  bodyValue: string;
  setBodyValue: (value: string) => void;
  threadMedia: ThreadSendingMediaItem[];
  disabled?: boolean;
  removeMediaItem: (id: string) => void;
  fileUploadProps: ReturnType<typeof useFileUpload>;
  allowedFileTypes?: string[];
  onSubmit: () => void;
  hideSubmitButton?: boolean;
  extraActions?: SuperTextareaProps['extraActions'];
};

export const ThreadSendingArea = ({
  bodyValue,
  setBodyValue,
  threadMedia,
  removeMediaItem,
  disabled,
  fileUploadProps,
  allowedFileTypes,
  onSubmit,
  hideSubmitButton,
  extraActions,
  ...rest
}: ThreadSendingAreaProps) => {
  return (
    <>
      <MediaSendingPreviewArea media={threadMedia} onRemoveMediaItem={(id) => removeMediaItem(id)} />
      <div {...rest}>
        <input type='file' {...fileUploadProps.inputProps} css={{ display: 'none' }} multiple />
        <SuperTextarea
          value={bodyValue}
          onChange={setBodyValue}
          disabled={disabled}
          hasSubmit={!hideSubmitButton}
          onSubmit={onSubmit}
          onImageClick={() => fileUploadProps.handleOnClick()}
          extraActions={extraActions}
        >
          {fileUploadProps.isDragging && (
            <DropzoneMessage
              allowedFileTypes={allowedFileTypes}
              css={{
                position: 'absolute',
                margin: theme.spacing(1),
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: theme.colors.white,
              }}
            />
          )}
        </SuperTextarea>
      </div>
    </>
  );
};
