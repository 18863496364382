import { useMemo, useState } from 'react';
import { CreateResponse } from '@weave/schema-gen-ts/dist/schemas/phone/callqueue-config/v1/callqueue_config_service.pb';
import { useQueryClient } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { WeaveLocationGroup } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  BackIcon,
  FormFieldActionTypes,
  Heading,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  Stepper,
  TextField,
  useAlert,
  useForm,
  useModalControl,
} from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { trackingId } from '../../../tracking';
import { AudioItem } from '../../audio-picker/types';
import { DEFAULT_STANDARD_MEDIA } from '../../media-picker';
import { AssignDeviceStepCard } from './assign-device';
import { CallerExperienceCard, LocationsCard, RoutingCard } from './call-queue-page';
import { SchemaFormValues, createSubmitPayload, populateSchema } from './schema';
import { CallQueueState, CallQueueRingType } from './types';
import { useCallQueueMutations } from './use-call-queue-mutations';

const getDefaultState = (tenantId: string): CallQueueState => ({
  tenantId: tenantId,
  allowExit: false,
  callQueueId: '',
  deviceRingType: CallQueueRingType.RING_TYPE_RING_ALL,
  devices: [],
  greetingEnabled: false,
  greetingMediaId: '',
  holdMusicId: DEFAULT_STANDARD_MEDIA,
  locationIds: [],
  // Default to 10m when creating
  maxWaitTimeMs: 600000,
  name: '',
  positionAnnouncement: {
    firstAnnouncementDelayMs: 0,
    repeatIntervalMs: 0,
  },
  positionAnnouncementEnabled: false,
});

export const CreateCallQueueButton = ({ tenantLocation }: { tenantLocation: WeaveLocationGroup }) => {
  const { t } = useTranslation('phone');

  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <PrimaryButton
        size='large'
        {...triggerProps}
        css={{ width: 'fit-content' }}
        trackingId={trackingId({ context: 'setting', feature: 'call-queues', details: 'create' })}
      >
        {t('Create Call Queue')}
      </PrimaryButton>
      <CreateModal tenantLocation={tenantLocation} modalProps={modalProps} />
    </>
  );
};

export const CreateModal = ({
  tenantLocation,
  modalProps,
  onSuccess,
}: {
  tenantLocation: WeaveLocationGroup;
  modalProps: ModalControlModalProps;
  onSuccess?: (data: CreateResponse) => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const [callQueue, setCallQueue] = useState<CallQueueState>(() => getDefaultState(tenantLocation.phoneTenantId ?? ''));
  const queryClient = useQueryClient();
  const { create } = useCallQueueMutations(tenantLocation.phoneTenantId ?? '');
  const { navigate } = useSettingsNavigate();
  const populatedSchema = useMemo(() => {
    return populateSchema(callQueue);
  }, [callQueue]);

  const form = useForm({
    fields: populatedSchema,
    /**
     * This reducer sets the holdMusicSystemMedia value based on the holdMusicId value
     * The holdMusicSystemMedia field is hidden to the user
     * */
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'holdMusicId') {
        const holdMusicData = queryClient.getQueryData<{ standardMedia: AudioItem[] }>(
          [
            tenantLocation.phoneTenantId,
            ...queryKeys.audioPicker({
              add: true,
              custom: true,
              standard: true,
              tenantId: tenantLocation.phoneTenantId,
            }),
          ],
          {}
        )?.standardMedia;

        const flattened = holdMusicData ? Object.values(holdMusicData).flat() : [];
        const holdMusicSystemMedia = flattened.some((media) => media.id === action.payload.value);

        return {
          ...state,
          holdMusicSystemMedia: { ...state.holdMusicSystemMedia, value: holdMusicSystemMedia },
        };
      }
      return state;
    },
  });
  const textFieldProps = form.getFieldProps('name');
  const locationOptions = useMemo(() => {
    return (
      tenantLocation.children?.map((location) => ({
        label: location.name ?? '',
        value: location.locationId ?? '',
      })) ?? []
    );
  }, [tenantLocation]);

  const createCallQueue = (state: CallQueueState, formValues: SchemaFormValues) => {
    const finalState = createSubmitPayload({ state, formValues });
    create.mutate(finalState, {
      onSuccess: (data) => {
        alerts.success({
          message: t('Call Queue created successfully.'),
          action: {
            label: t('Go to Call Queue'),
            onClick: () => navigate({ to: `/phone/call-queues/:id`, params: { id: data.callQueueId } }),
          },
        });
        form.reset();
        onSuccess?.(data);
      },
      onError: () => {
        alerts.error('System failed to create Call Queue. Please try again.');
      },
    });
    modalProps.onClose();
  };

  const cancel = () => {
    form.reset();
    modalProps.onClose();
  };

  const isUnify = tenantLocation?.locationType === 'unify';
  const stepTitles: Record<string, string> = {
    1: t('Call Queue Name'),
    2: t('Assign Devices'),
    3: t('Routing'),
    4: t('Caller Experience'),
  };
  if (isUnify) {
    stepTitles[5] = t('Locations');
  }

  return (
    <Modal {...modalProps} minWidth={900}>
      <Modal.Header onClose={cancel}></Modal.Header>
      <Modal.Body>
        <Stepper stepTitleText={stepTitles} isVerticalSingleView maxWidth={900}>
          {/* Call Queue Name */}
          <Stepper.Card>
            <Stepper.Title level={3}>{t('Create Call Queue')}</Stepper.Title>
            <Stepper.Content css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
              <Heading level={3}>{t('Call Queue Name')}</Heading>
              <TextField {...textFieldProps} label={t('Call Queue Name')} name='name'></TextField>
            </Stepper.Content>
            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate type='next' position='primary' disabled={!textFieldProps.value}>
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Assign Devices */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Call Queue')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <AssignDeviceStepCard
                  setState={setCallQueue}
                  selectedDeviceCount={callQueue.devices.length}
                  assignedDevices={callQueue.devices}
                  tenantLocation={tenantLocation}
                />
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate
              helperText={
                callQueue.devices.length > 0
                  ? t('{{count}} devices selected.', { count: callQueue.devices.length })
                  : undefined
              }
            >
              <Stepper.ButtonAlternate type='next' position='primary' disabled={callQueue.devices.length > 20}>
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='previous' position='tertiary'>
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Routing */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Call Queue')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <RoutingCard form={form} condensed />
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate type='next' position='primary'>
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='previous' position='tertiary'>
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Caller Experience */}
          <Stepper.Card>
            <Stepper.Title>{t('Create Call Queue')}</Stepper.Title>

            <Stepper.Content>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(2),
                  '> div': {
                    boxShadow: 'none',
                    padding: 0,
                  },
                }}
              >
                <CallerExperienceCard form={form} tenantId={tenantLocation.phoneTenantId ?? ''} condensed />
              </div>
            </Stepper.Content>

            <Stepper.ButtonBarAlternate>
              {isUnify ? (
                <Stepper.ButtonAlternate type='next' position='primary'>
                  {t('Continue')}
                </Stepper.ButtonAlternate>
              ) : (
                <Stepper.ButtonAlternate
                  type='none'
                  position='primary'
                  onClick={() => createCallQueue(callQueue, form.values)}
                >
                  {t('Save')}
                </Stepper.ButtonAlternate>
              )}
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='previous' position='tertiary'>
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Locations */}
          {isUnify && (
            <Stepper.Card>
              <Stepper.Title>{t('Create Call Queue')}</Stepper.Title>

              <Stepper.Content>
                <div
                  css={{
                    '> div': {
                      boxShadow: 'none',
                      padding: 0,
                    },
                  }}
                >
                  <LocationsCard form={form} options={locationOptions} />
                </div>
              </Stepper.Content>

              <Stepper.ButtonBarAlternate>
                <Stepper.ButtonAlternate
                  type='none'
                  position='primary'
                  onClick={() => createCallQueue(callQueue, form.values)}
                >
                  {t('Save')}
                </Stepper.ButtonAlternate>
                <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                  {t('Cancel')}
                </Stepper.ButtonAlternate>
                <Stepper.ButtonAlternate type='previous' position='tertiary'>
                  <BackIcon />
                  {t('Back')}
                </Stepper.ButtonAlternate>
              </Stepper.ButtonBarAlternate>
            </Stepper.Card>
          )}
        </Stepper>
      </Modal.Body>
    </Modal>
  );
};
