import { useCancelInvoiceMutation } from '@frontend/api-invoices';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';

export const useCancelInvoice = () => {
  const { paymentsUrl } = useMerchant();
  const alerts = useAlert();
  const { locationId } = useMultiQueryUtils();

  return useCancelInvoiceMutation({
    paymentsUrl,
    locationId,
    onSuccess: () => {
      alerts.success('Invoice Canceled');
    },
    onError: (error, invoice) => {
      alerts.error('Failed to cancel payment request');
      console.error(`Tried to cancel payment request: ${invoice.id}`, error);
    },
  });
};
