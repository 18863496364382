import { memo, PropsWithChildren, useCallback, useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import env from '@frontend/env';
import { createShallowStore, createStore } from '@frontend/store';

export const CaptchaV3Provider = memo(({ children }: PropsWithChildren) => {
  return (
    <GoogleReCaptchaProvider
      useEnterprise
      reCaptchaKey={env.CAPTCHAV3_SITE_KEY}
      container={{
        element: 'recaptcha',
        parameters: {
          badge: 'bottomleft',
        },
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
});

CaptchaV3Provider.displayName = 'CaptchaV3Provider';

interface CaptchaV3Store {
  captchaV3Token: string;
  setCaptchaV3Token: (captchaV3Token: string) => void;
}

export const useCaptchaV3Store = createStore<CaptchaV3Store>(
  (set) => ({
    captchaV3Token: '',
    setCaptchaV3Token: (captchaV3Token) => {
      set({ captchaV3Token }, false, 'SET_CAPTCHA_V3_TOKEN');
    },
  }),
  { name: 'CaptchaV3Store', trace: true }
);

export const useCaptchaV3ShallowStore = createShallowStore(useCaptchaV3Store);

export const useRefreshCaptchaV3Token = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setCaptchaV3Token } = useCaptchaV3ShallowStore('setCaptchaV3Token');

  // Function to generate and refresh the token
  const refreshCaptchaV3Token = useCallback(async () => {
    if (!executeRecaptcha) return;

    try {
      const token = await executeRecaptcha('postrequest');
      setCaptchaV3Token(token);
    } catch (e) {
      console.error('error', e);
      // NOTE: This is a temporary fix to handle the case when we do book again action for v3 captcha
      // We should handle this properly in the future
      setTimeout(refreshCaptchaV3Token, 1000);
    }
  }, [executeRecaptcha]);

  return { refreshCaptchaV3Token };
};

export const useAutoFetchAndSetCaptchaV3Token = ({ isEnabled }: { isEnabled: boolean }) => {
  const { refreshCaptchaV3Token } = useRefreshCaptchaV3Token();

  // Refresh token every 90 seconds
  useEffect(() => {
    if (!isEnabled) return;

    refreshCaptchaV3Token(); // Initial token fetch

    const intervalId = setInterval(() => {
      refreshCaptchaV3Token();
    }, 90 * 1000); // Refresh every 1.5 minutes

    return () => clearInterval(intervalId);
  }, [isEnabled, refreshCaptchaV3Token]);
};
