import { css } from '@emotion/react';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ConfirmationModal, ModalControlModalProps, Text } from '@frontend/design-system';
import { MessageMinimal } from '../message-minimal';

interface Props {
  message: TeamChatTypes.Message;
  modalProps: ModalControlModalProps;
  onConfirm: () => void;
}

export const DeleteMessageConfirmation = ({ message, modalProps, onConfirm }: Props) => {
  const { t } = useTranslation('team-chat');

  return (
    <ConfirmationModal
      {...modalProps}
      confirmLabel='Delete'
      destructive
      maxWidth={800}
      message={
        <>
          <Text>{t('Are you sure you want to delete this message? This cannot be undone.')}</Text>
          <MessageMinimal css={styles.message} message={message} />
        </>
      }
      onConfirm={onConfirm}
      title={t('Delete Message')}
      cancelTrackingId='team-chat-2.0-delete-message-modal-cancel-button'
      confirmTrackingId='team-chat-2.0-delete-message-modal-confirmation-button'
    />
  );
};

const styles = {
  message: css`
    border: 2px solid ${theme.colors.neutral10};
    border-radius: ${theme.borderRadius.small};
    margin-top: ${theme.spacing(2)};
    pointer-events: none;
  `,
};
