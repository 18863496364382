import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useInvoicePerson } from '@frontend/payments-collection-flow';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Chip, Info, Text } from '@frontend/design-system';

interface DetailsProps {
  invoice: InvoiceModel;
}

const styles = {
  detailsSection: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  detailsContainer: css`
    display: grid;
    overflow-y: auto;
    grid-template-columns: repeat(1, max-content);
    grid-gap: ${theme.spacing(1, 3)};
    margin-bottom: ${theme.spacing(3)};
    @media (min-width: ${breakpoints.small.min}px) {
      grid-template-columns: repeat(2, max-content);
    }
  `,
  detailsInfo: css`
    margin-bottom: ${theme.spacing(1)};

    @media (min-width: ${breakpoints.small.min}px) {
      margin-bottom: ${theme.spacing(0)};
    }
  `,
  noMargin: css`
    margin: 0;
  `,
  locationChip: css`
    max-width: none;
  `,
  detailsTitle: css`
    display: flex;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
  infoText: css`
    color: ${theme.colors.text.subdued};
  `,
};

export const Details = ({ invoice }: DetailsProps) => {
  const { t } = useTranslation('payments');
  const { getLocationName, selectedLocationIds } = useMultiQueryUtils();
  const { personEmail } = useInvoicePerson(invoice);

  return (
    <section css={styles.detailsContainer}>
      {selectedLocationIds.length > 1 && (
        <DetailRow
          title={t('Location')}
          detail={<Chip.SingleChip css={styles.locationChip}>{getLocationName(invoice.locationId)}</Chip.SingleChip>}
        />
      )}
      <DetailRow title={t('Provider')} detail={invoice.providerName} />
      <DetailRow title={t('Date Created')} detail={formatDate(invoice.billedAt, 'MMMM D, YYYY')} />
      <DetailRow
        title={t('Customer Email')}
        detail={personEmail}
        info={t('This email is used for the Online Bill Pay payments')}
      />
    </section>
  );
};

const DetailRow = ({
  title,
  detail,
  info,
}: {
  title: string;
  detail: string | undefined | ReactNode;
  info?: string;
}) => {
  return (
    <>
      <div css={styles.detailsTitle}>
        <Text color='subdued' size='medium' css={styles.noMargin}>
          {title}
        </Text>
        {info && <Info css={styles.infoText}>{info}</Info>}
      </div>
      {typeof detail === 'string' ? (
        <Text size='medium' css={[styles.noMargin, styles.detailsInfo]}>
          {detail || '-'}
        </Text>
      ) : (
        <div css={[styles.noMargin, styles.detailsInfo]}>{detail || '-'}</div>
      )}
    </>
  );
};
