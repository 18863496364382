import { FC, useState, useMemo } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { theme } from '@frontend/theme';
import { ModalControlModalProps, Text } from '@frontend/design-system';
import { Pagination } from '../../../../../../shared/components';
import { pendoTags } from '../../../../../../shared/constants';
import { useIntegrationCheck } from '../../../../../../shared/hooks';
import { useSubmissionPDF } from '../../../../hooks';
import CustomModal from '../custom-modal.component';
import ModalActionBar from '../modal-action-bar/modal-action-bar.component';
import { ModalNavigator } from '../modal.types';
import {
  bodyContainerStyle,
  fieldContainerStyle,
  innerContainerStyle,
  sectionContainerStyle,
  sectionTitleStyle,
} from './form-details-modal.styles';
import FormField from './form-field/form-field.component';

interface FormDetailsModalProps {
  title: string;
  firstName: string;
  lastName: string;
  modalProps: ModalControlModalProps;
  submissionId: string;
  reviewRequired: boolean;
  activeFormIndex: number;
  formData: FormsSubmission.Types.NormalizedFormDetails[];
  isSubmitted: boolean;
  onGetAttachments: (formId: string) => FormsSubmission.Types.AttachmentData[];
  onViewAttachmentsInTheForm?: (formId: string) => void;
  onFormNavigate: (formIndex: number, navigateForm: boolean) => void;
  onRejectSubmission: () => void;
  onApproveSubmission: () => void;
  locationId: string;
}

const { useFormsACL } = DigitalFormScopeHooks;
const PAGE_SIZE = 1;

const FormDetailsModal: FC<React.PropsWithChildren<FormDetailsModalProps>> = ({
  modalProps,
  submissionId,
  reviewRequired,
  formData,
  activeFormIndex,
  isSubmitted,
  onFormNavigate,
  onViewAttachmentsInTheForm,
  onGetAttachments,
  onRejectSubmission,
  onApproveSubmission,
  locationId,
  ...rest
}) => {
  const { fields, sections, form } = formData[activeFormIndex].submissionData;
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoadingPDF, downloadPDF, printPDF } = useSubmissionPDF({
    submissionId,
    formId: form.id,
    pdfName: form.name,
  });

  const { hasPMSIntegration } = useIntegrationCheck();
  const _hasPMSIntegration = hasPMSIntegration(locationId);

  const { hasPermissionToReviewSubmissions, neitherHasReviewSubmissionNorManageProviderPermissions } = useFormsACL({
    locationId,
  });

  const hasAttachments = useMemo(() => {
    const attachments = onGetAttachments(formData[activeFormIndex].formId);
    return attachments.length !== 0;
  }, [activeFormIndex]);

  const currentSectionId = form.sections[currentPage - 1];
  const currentSection = sections[currentSectionId];
  const hasFormFields = currentSection.fields?.length > 0;

  function viewAttachmentsHandler() {
    if (!form.id || !onViewAttachmentsInTheForm) {
      return;
    }

    onViewAttachmentsInTheForm(form.id);
  }

  function resetCurrentPage() {
    setTimeout(() => {
      setCurrentPage(1);
    }, 300);
  }

  const modalNavigator: ModalNavigator = {
    previous: {
      show: activeFormIndex !== 0,
      disabled: false,
      onClick: () => {
        setCurrentPage(1);
        onFormNavigate(activeFormIndex - 1, true);
      },
    },
    next: {
      show: true,
      disabled: activeFormIndex === formData.length - 1,
      onClick: () => {
        setCurrentPage(1);
        onFormNavigate(activeFormIndex + 1, true);
      },
    },
    home: {
      show: true,
      disabled: activeFormIndex === 0,
      onClick: () => {
        onFormNavigate(0, true);
      },
    },
  };

  if (!isSubmitted) {
    return null;
  }

  if (formData.length - 1 < activeFormIndex) return null;

  return (
    <CustomModal
      modalProps={modalProps}
      modalDataOptions={
        <ModalActionBar
          isDownloading={isLoadingPDF}
          onDownload={downloadPDF}
          onPrint={printPDF}
          modalType='form'
          onOpenAnotherModal={hasAttachments ? viewAttachmentsHandler : undefined}
        />
      }
      onCloseModal={resetCurrentPage}
      {...(reviewRequired &&
        !neitherHasReviewSubmissionNorManageProviderPermissions && {
          primaryButtonText: hasPermissionToReviewSubmissions || !_hasPMSIntegration ? 'Approve' : 'Approve & Sync',
          secondaryButtonText: 'Reject',
          onPrimaryButtonClick: () => {
            onApproveSubmission();
          },
          onSecondaryButtonClick: () => {
            onRejectSubmission();
          },
          primaryButtonTrackingId: pendoTags.providerReview.modal.approve,
          secondaryButtonTrackingId: pendoTags.providerReview.modal.reject,
        })}
      {...((!reviewRequired || neitherHasReviewSubmissionNorManageProviderPermissions) && {
        secondaryButtonText: 'Cancel',
        onSecondaryButtonClick: () => {
          modalProps.onClose();
          resetCurrentPage();
        },
      })}
      {...(formData.length > 1 && { modalNavigator })}
      {...rest}
      maxWidth='978px'
      minWidth='400px'
    >
      <div css={[bodyContainerStyle, { overflow: 'auto', padding: theme.spacing(2, 3) }]}>
        <div css={innerContainerStyle}>
          <div css={sectionContainerStyle}>
            <Text weight='semibold' css={sectionTitleStyle}>
              {currentSection.title}
            </Text>

            <div css={fieldContainerStyle}>
              {hasFormFields &&
                currentSection.fields.map((fieldId) => <FormField key={fieldId} field={fields[fieldId]} />)}
            </div>

            {hasAttachments && <Text>View attachment data using the link above.</Text>}
          </div>
        </div>

        <Pagination
          onPageChange={(page) => setCurrentPage(page)}
          currentPage={currentPage}
          totalCount={form.sections.length}
          pageSize={PAGE_SIZE}
        />
      </div>
    </CustomModal>
  );
};

export default FormDetailsModal;
