import { PersonsV3 } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Actions } from '@frontend/contact-actions';
import { PaymentsAppointmentPromoWrapper, useHVAPaymentsInAppointment } from '@frontend/high-value-adoption';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader } from '@frontend/design-system';
import { useScheduleActionsContainerMethod } from '../../../context/ScheduleActionsContainerContext';
import { EditAppointmentActionButton } from './edit-appointment-action-button';

type ActionMenuButtonsProps = {
  personId: string;
  locationId: string;
  closeModal?: () => void;
};

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const AppointmentEventActionButtons = ({ personId, locationId, closeModal }: ActionMenuButtonsProps) => {
  const { t } = useTranslation('schedule');
  const { closeModal: closeScheduleActionsModal } = useScheduleActionsContainerMethod();

  const { showPromotion: showPaymentsHVAPromo, showHighlightedPaymentBtn } = useHVAPaymentsInAppointment({
    locationId,
  });

  const { data: personData, isLoading } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId,
      locationIds: [locationId],
    },
    {
      enabled: !!personId,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      select: (data) => PersonsV3.PersonHelpers.convertPersonV3ToPerson(data),
    }
  );

  if (isLoading) return <SpinningLoader size='small' />;
  if (!personData) return null;

  return (
    <AdaptoActions
      css={{ marginRight: theme.spacing(1), gap: 2 }}
      iconColor='subdued'
      maxActions={showHighlightedPaymentBtn ? 5 : 3}
    >
      <Actions.SendForm
        label={t('Send Form')}
        context={{ person: personData, locationId: locationId }}
        trackingIdSuffix='appointment-contact-actions'
      />
      <Actions.Message
        label={t('Message')}
        context={{ personId: personData.PersonID, locationId: locationId, onSuccess: () => closeModal?.() }}
        trackingIdSuffix='appointment-contact-actions'
      />
      <EditAppointmentActionButton />
      {/* 
        This is an experiment to see if putting highlighted payments outside of dropdown brings more click
        PaymentsAppointmentPromoWrapper will change payments button action to open promotional modal as per internal checks
        TODO: Milind remove this after experiment is done
      */}
      {showHighlightedPaymentBtn && (
        <PaymentsAppointmentPromoWrapper locationId={locationId} onSettingsOpen={closeScheduleActionsModal}>
          <Actions.PaymentsHighlighted
            label={t('Request Payment')}
            context={{ person: personData, personId: personData.PersonID, locationId: locationId }}
            trackingIdSuffix='promo-appointment-contact-actions'
          />
        </PaymentsAppointmentPromoWrapper>
      )}
      {/* Hide the payments action if highlighted payment button is visible and HVA promotion for payment is active with payment account not setup */}
      {!(showPaymentsHVAPromo && showHighlightedPaymentBtn) && (
        <Actions.Payments
          label={t('Request Payment')}
          context={{ person: personData, personId: personData.PersonID, locationId: locationId }}
          trackingIdSuffix='appointment-contact-actions'
        />
      )}
      <Actions.Review
        label={t('Review')}
        groupId={locationId}
        personId={personData.PersonID}
        trackingIdSuffix='appointment-contact-actions'
      />
    </AdaptoActions>
  );
};
