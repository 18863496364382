import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { useMutation } from 'react-query';
import { DepartmentsTypes } from '@frontend/api-departments';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { OverrideApi } from '@frontend/api-overrides';
import { Trans, useTranslation } from '@frontend/i18n';
import { useInvalidateQueries } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useModalControl, ConfirmationModal, Text, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { PhoneOverrideType } from './types';
import { useOverrideMutations } from './use-overrides-mutations';

interface IOverrideSettingRemoveModal {
  override: PhoneOverride | PhoneOverrideType;
  department?: DepartmentsTypes.DepartmentModel;
  modalProps: ReturnType<typeof useModalControl>['modalProps'];
  phoneTenantId?: string;
  modalControls?: ReturnType<typeof useModalControl>;
}

export const NewOverrideSettingRemoveModal = ({
  override,
  department,
  modalProps,
  phoneTenantId,
  modalControls,
}: IOverrideSettingRemoveModal) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const invalidateQueries = useInvalidateQueries();
  const alerts = useAlert();
  const { selectedLocationIds } = useAppScopeStore();
  const { aggregateValue: hasCallRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: selectedLocationIds ?? [],
  });

  const { mutate: deleteOverride } = useMutation(
    () => {
      return OverrideApi.DeleteOverride({ phoneOverrideId: override?.id }, { locationId: override?.locationId });
    },
    {
      onSuccess: () => {
        invalidateQueries([...queryKeys.overrideList()]);
        invalidateQueries([...queryKeys.listDepartmentsWithSchedules()]);
        alerts.success(t('Override removed successfully'));
      },
      onError: () => {
        alerts.error(t('Override removal failed'));
      },
    }
  );

  const { remove } = useOverrideMutations(phoneTenantId ?? '');

  const deleteOverrideV2 = () =>
    remove.mutate(
      { phoneOverrideId: override?.id ?? '' },
      {
        onSuccess: () => {
          alerts.success(t('Override removed successfully'));
          modalControls?.closeModal();
        },
        onError: () => {
          alerts.error(t('Override removal failed'));
        },
      }
    );

  return (
    <ConfirmationModal
      {...modalProps}
      onConfirm={() => (hasCallRoutesFlag ? deleteOverrideV2() : deleteOverride())}
      title={t('Remove Active Override?')}
      message={
        hasCallRoutesFlag ? (
          <Trans t={t} callRouteName={(override as PhoneOverrideType).callRouteName}>
            Removing the override will return the Call Route{' '}
            <Text as='span' weight='bold'>
              {(override as PhoneOverrideType).callRouteName}
            </Text>{' '}
            to its standard configuration.
          </Trans>
        ) : !!department ? (
          <Trans t={t} departmentName={department.name}>
            Removing the override will return the{' '}
            <Text as='span' size='medium' weight='bold'>
              {department.name}
            </Text>{' '}
            department to its standard call configuration.
          </Trans>
        ) : (
          t('Removing the override will return this location to its standard call configuration.')
        )
      }
      cancelLabel={t('No, Cancel')}
      confirmLabel={t('Yes, Remove')}
      destructive
      cancelTrackingId='phone-portal-button-cancelRemoveOverride'
      confirmTrackingId='phone-portal-button-removeOverride'
    />
  );
};
