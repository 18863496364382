import { FaxQueries } from '@frontend/api-fax';
import { GlobalSearch, useGlobalSearch } from '@frontend/global-search';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { phone } from '@frontend/design-system';
import { FaxSearchResult } from './fax-search-result';

const DEFAULT_MAX_RESULTS = 4;
const DEFAULT_SEARCH_LIMIT = 10;
const FAX_SEARCH_LIMIT = 100;

export const FaxesGlobalSearchModule = () => {
  const { t } = useTranslation('fax');
  const { selectedLocationIds } = useAppScopeStore();

  const { debouncedSearchTerm, searchFor, viewAllFeature } = useGlobalSearch([
    'debouncedSearchTerm',
    'searchFor',
    'viewAllFeature',
  ]);

  const containsNoAlphabet = (str: string) => !/[a-zA-Z]/.test(str);

  const conditionalSanitizePhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber) return '';

    if (containsNoAlphabet(phoneNumber)) {
      return phone(phoneNumber, { format: 'numbers' });
    }

    return phoneNumber;
  };

  const { data: faxes } = FaxQueries.useSearchFaxesQuery({
    locationIds: selectedLocationIds,
    query: conditionalSanitizePhoneNumber(debouncedSearchTerm),
    limit: searchFor === 'faxes' ? FAX_SEARCH_LIMIT : DEFAULT_SEARCH_LIMIT,
  });

  const maxResults = viewAllFeature === 'faxes' ? faxes : faxes?.slice(0, DEFAULT_MAX_RESULTS);

  return (
    <GlobalSearch.Section.Content
      showViewAll={faxes && faxes?.length > DEFAULT_MAX_RESULTS}
      hasResults={!!faxes?.length}
      count={!!faxes?.length && faxes.length > DEFAULT_SEARCH_LIMIT - 1 ? `${DEFAULT_SEARCH_LIMIT}+` : faxes?.length}
    >
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.spacing(1),
        }}
      >
        {maxResults?.length ? (
          maxResults?.map((faxItem) => <FaxSearchResult key={faxItem.id} faxItem={faxItem} />)
        ) : (
          <GlobalSearch.EmptyState customMessage={t('No fax matches this search.')} />
        )}
      </div>
    </GlobalSearch.Section.Content>
  );
};
