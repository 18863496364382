import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { AvatarGroup, Avatar, Text } from '@frontend/design-system';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { getUserFullName } from '../../utils';

interface Props {
  typingUserIds: string[];
}

export const TypingIndicator = ({ typingUserIds }: Props) => {
  const t = useTranslation('chat').t;
  const users = useTeamChatSelector((ctx) => ctx.users);

  const typingUsers = typingUserIds
    .map((userId) => users?.find((user) => user.userID === userId))
    .filter((user) => !!user);
  const text =
    typingUserIds.length > 2
      ? t('Several people are typing...')
      : t('{{name}} is typing...', {
          name: typingUserIds
            .map((userId) => users?.find((user) => user.userID === userId))
            .filter((user) => !!user)
            .map((user) => getUserFullName(user.firstName, user.lastName))
            .join(', '),
          count: typingUserIds.length,
        });

  return (
    <AnimatePresence>
      <motion.div
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        css={[containerStyle, typingUsers.length && { padding: theme.spacing(0.75, 2), gap: theme.spacing(1) }]}
      >
        {typingUsers.length > 0 && (
          <>
            <AvatarGroup fallbackName={''} size='xxs' hideOverflow>
              {typingUsers.map((user) => (
                <Avatar name={`${user.firstName} ${user.lastName}`} isUser key={`${user.firstName} ${user.lastName}`} />
              ))}
            </AvatarGroup>
            <Text size='small' css={textStyle}>
              {text}
            </Text>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const containerStyle = css({
  display: 'flex',
  width: '100%',
});

const textStyle = css({ color: theme.colors.neutral30 });
