import { css } from '@emotion/react';
import { ValidatedImageType } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import EmptyStateBird from './bird.svg?react';
import EmptyStateCat from './cat.svg?react';
import EmptyStateHamster from './hamster.svg?react';
import EmptyStateHorse from './horse.svg?react';
import EmptyStateLizard from './lizard.svg?react';
import EmptyStateVet from './vet-v1.svg?react';

type Props = {
  imageType: ValidatedImageType;
  size?: number; // Definition for image size
};

export const PetEmptyState = ({ imageType, size = 132 }: Props) => {
  const { t } = useTranslation();

  const getEmptyState = (imageType: ValidatedImageType) => {
    switch (imageType) {
      case ValidatedImageType.DOG:
        return <EmptyStateVet />;
      case ValidatedImageType.CAT:
        return <EmptyStateCat />;
      case ValidatedImageType.BIRD:
        return <EmptyStateBird />;
      case ValidatedImageType.LARGEMAMMAL:
      case ValidatedImageType.HORSE:
        return <EmptyStateHorse />;
      case ValidatedImageType.SMALLMAMMAL:
      case ValidatedImageType.MOUSE:
        return <EmptyStateHamster />;
      case ValidatedImageType.REPTILE:
      case ValidatedImageType.CHAMELEON:
        return <EmptyStateLizard />;
      default:
        return <EmptyStateVet />;
    }
  };
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        svg {
          height: ${size}px;
          width: ${size}px;
        }
        padding: ${theme.spacing(2)};
      `}
    >
      {getEmptyState(imageType)}
      <Text
        css={css`
          color: ${theme.colors.neutral50};
        `}
        size='small'
        weight='bold'
      >
        {t('Looks like this pet is all caught up for now.')}
      </Text>
    </div>
  );
};
