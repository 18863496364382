import { Icon } from '@frontend/icons';
import { Chip } from '@frontend/design-system';
import { useAppointmentStatusShallowStore } from '../../../components/schedule-calendar-components/appointment-event-card/appointmentStatusStore';
import {
  APPOINTMENT_STATUS_MAPPING,
  AppointmentStatusEnum,
} from '../../../components/schedule-calendar-components/appointment-event-card/types';
import {
  AppointmentStatusV3Enum,
  APPOINTMENT_STATUS_MAPPING_V3,
} from '../../../components/v3/EventCardAppointmentStatusChip/types';

type AppointmentBadgeStatusProps = {
  status:
    | AppointmentStatusEnum.Confirmed
    | AppointmentStatusEnum.Completed
    | AppointmentStatusEnum.InOffice
    | AppointmentStatusEnum.Attempted
    | AppointmentStatusEnum.Canceled
    | AppointmentStatusEnum.Unconfirmed
    | AppointmentStatusEnum.NoShow
    | AppointmentStatusEnum.Unknown
    | string
    | undefined
    | AppointmentStatusV3Enum.UNKNOWN_EVENT_STATUS;
  appointmentId: string;
};

export const AppointmentBadgeStatus = ({ status, appointmentId }: AppointmentBadgeStatusProps) => {
  const formatStatus = status?.toLowerCase().replace(/[\s_]/g, '') ?? '';

  const { appointmentStatus: appointmentsInStore } = useAppointmentStatusShallowStore('appointmentStatus');

  const updatedAppointment = appointmentsInStore.find((appointment) => appointment.id === appointmentId);

  const statusKey = updatedAppointment?.key?.toLowerCase().replace(/[\s_]/g, '') ?? '';

  let appointmentStatus = APPOINTMENT_STATUS_MAPPING.get((statusKey || formatStatus) as AppointmentStatusEnum);

  if (AppointmentStatusV3Enum.UNKNOWN_EVENT_STATUS === status) {
    appointmentStatus = APPOINTMENT_STATUS_MAPPING_V3.get(AppointmentStatusV3Enum.UNKNOWN_EVENT_STATUS);
  }

  if (!appointmentStatus) {
    return null;
  }

  return (
    <Chip
      variant={appointmentStatus.variant}
      css={{ minWidth: 'fit-content' }}
      leftElement={
        appointmentStatus.iconName && <Icon name={appointmentStatus.iconName} css={{ color: 'currentcolor' }} />
      }
    >
      {appointmentStatus.text}
    </Chip>
  );
};
