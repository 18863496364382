import { css } from '@emotion/react';

export const digitizationBodyStyle = css`
  display: flex;
  flex-direction: column;
`;

export const innerContainerStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const containerStyle = css`
  container-type: size;
`;
