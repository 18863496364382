import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ModalControlResponse, Text, Tray, useModalControl } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { EmptyOverrideSetting, SettingContent } from '../../views/settings/overrides';
import { NewOverrideSettingRemoveModal } from '../override/override-settings-remove-modal.new';
import { ExtendedCallRoute } from './types';

type Props = {
  callRoute: ExtendedCallRoute;
  locationId: string;
  editOverrideTray: ModalControlResponse;
};

export const OverridePanel = ({ callRoute, locationId, editOverrideTray }: Props) => {
  const { t } = useTranslation('phone');
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const phoneTenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const removeOverride = useModalControl();
  const override = callRoute.callRoutePhoneOverride;

  return (
    <>
      <Tray.Body>
        <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
          <Text size='medium'>
            {t(
              'Overrides enable you to quickly and easily reroute your calls to secure phone coverage during breaks and holidays.'
            )}{' '}
          </Text>
          {override && override.id ? (
            <SettingContent
              override={override}
              isSideModal={true}
              editOverride={editOverrideTray}
              removeOverride={removeOverride}
            />
          ) : (
            <EmptyOverrideSetting
              locationId={locationId}
              override={override}
              isSideModal={true}
              editOverride={editOverrideTray}
            />
          )}
        </div>
      </Tray.Body>
      <NewOverrideSettingRemoveModal
        override={override ?? {}}
        modalProps={removeOverride.modalProps}
        phoneTenantId={phoneTenantId}
      />
    </>
  );
};
