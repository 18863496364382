import { CallIntelTypes } from '@frontend/api-call-intel';
import { generateRandomValue, overviewScheduledOpportunities, totalCallsAnalyzed } from './overview';

export const getOverviewStats = (): CallIntelTypes.OverviewStatsResponse => {
  return {
    serviceQualitySummary: {
      callsToReview: generateRandomValue(10, 20),
      callsResolved: generateRandomValue(10, 20),
      isPartial: false,
    },
    summary: {
      isPartial: false,
      scheduledOpportunities: generateRandomValue(1, overviewScheduledOpportunities),
      totalOpportunities: overviewScheduledOpportunities,
    },
    totalCallsAnalyzed,
  };
};
