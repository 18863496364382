import dayjs, { ConfigType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { formatDate } from '@frontend/date';

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const TIME_DISPLAY_FORMAT = 'hh:mm A';
const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';

export const getTimeString = (date: ConfigType, isToUTC?: boolean): string =>
  formatDate(date, TIME_DISPLAY_FORMAT, isToUTC);

export const getEndTimeString = (startDate: ConfigType, durationInMinutes?: number): string => {
  if (!durationInMinutes) return '';

  const startDateString = formatDate(startDate);
  if (!startDateString) return '';

  return getTimeString(dayjs(startDateString).add(durationInMinutes, 'm'));
};

export const getTimeDiff = (
  startTime: ConfigType,
  endTime: ConfigType,
  fromFormat: string,
  unit: dayjs.QUnitType = 'm'
): number => {
  const start = dayjs(startTime, fromFormat);
  const end = dayjs(endTime, fromFormat);

  return end.diff(start, unit);
};

export const getDateByTimezone = (dateTime: dayjs.ConfigType, fromFormat: string, fromTimezone?: string): dayjs.Dayjs =>
  dayjs.tz(dateTime, fromFormat, fromTimezone);

export const getDateString = (date: ConfigType): string => formatDate(date, DATE_DISPLAY_FORMAT);

export const getTodaysDate = (): string => dayjs().format(DATE_DISPLAY_FORMAT);

export const isSelectedDateIsToday = (selectedDate: ConfigType): boolean => {
  return dayjs(selectedDate).isSame(dayjs(), 'day');
};

export const addDaysToDate = (date: ConfigType, noOfDays: number): string => {
  return dayjs(date).add(noOfDays, 'day').format(DATE_DISPLAY_FORMAT);
};

// Define an interface for the input options
export interface FormatDateTimeOptions {
  fromTimezone?: string; // The source timezone (e.g., 'America/New_York')
  toTimezone?: string; // The target timezone (e.g., 'Asia/Tokyo')
  inputFormat?: string; // The format of the input date-time (optional)
  outputFormat?: string; // The desired output format (optional)
}

export function formatDateTime(inputDateTime: ConfigType, options?: FormatDateTimeOptions): string {
  const { fromTimezone, toTimezone, inputFormat, outputFormat = 'L LT' } = options || {};

  if (!inputDateTime) {
    return '';
  }

  // remove Z from the input date-time string to avoid parsing issues (as for that case, it's already in UTC)
  if (typeof inputDateTime === 'string' && inputDateTime.endsWith('Z')) {
    inputDateTime = inputDateTime.slice(0, -1);
  }

  const date = inputFormat ? dayjs(inputDateTime, inputFormat) : dayjs(inputDateTime);

  // Check if the input date is invalid or too early (before the year 1900)
  if (!date.isValid() || date.year() < 1900) {
    return '';
  }

  // Assuming we no need to convert to timezone if either of from or to timezone is not provided
  if (!fromTimezone || !toTimezone) {
    return date.format(outputFormat);
  }

  const convertedDate = date.tz(fromTimezone, true).tz(toTimezone);

  return convertedDate.format(outputFormat);
}
