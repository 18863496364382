import { Placement } from '@floating-ui/react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { PersonSelector, PersonSelectorTypes, usePersonSelector } from '../components/PersonSelector';
import { CustomContactFormFields } from '../components/PersonSelector/types';
import { triggerQuickFillCustomEvent } from './use-quick-fill-custom-events';

const defaultFormFields: CustomContactFormFields = {
  firstName: { value: '', required: true },
  lastName: { value: '', required: true },
  phoneNumber: { value: '', required: true },
  notes: { value: '' },
};

type UseAddToQuickFillListDialog = {
  customContactFormFields: CustomContactFormFields;
  placement?: Placement;
  initialOffset?: { x: number; y: number };
  defaultSearchValue?: string;
  selectedLocationId: string;
  width?: number;
};

export const useAddToQuickFillListDialog = ({
  customContactFormFields,
  placement = 'bottom-start',
  initialOffset,
  defaultSearchValue = '',
  selectedLocationId,
  width = 388,
}: UseAddToQuickFillListDialog) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule-quick-fill');

  const { personSelectorDialogProps, personSelectorProps, selectedPerson } = usePersonSelector({
    formFields: customContactFormFields ?? defaultFormFields,
    placement,
    initialOffset,
  });

  const { mutateAsync: addCustomContact, isLoading: isAddingCustomContact } =
    ScheduleQueries.useMutateAddCustomContact(selectedLocationId);

  const { mutateAsync: addQuickFillRecipient, isLoading: isAddingQuickFillRecipient } =
    ScheduleQueries.useMutateAddQuickFillRecipient();

  const handleAddToList = ({ personId, notes, isNew }: PersonSelectorTypes.AddToListHandlerParamsType) => {
    addQuickFillRecipient({
      personId: personId || '',
      note: notes,
      locationId: selectedLocationId,
    })
      .then(() => {
        if (isNew) {
          // set personId to highlight it in listing
          triggerQuickFillCustomEvent('quick-fill:person-added', { personId: personId || '' });
        }
        personSelectorDialogProps.close();
        alert.success(isNew ? t('Quick Fill Recipient Added') : t('Quick Fill Recipient Updated'));
      })
      .catch(() =>
        alert.error(isNew ? t('Error Adding Quick Fill Recipient') : t('Error Updating Quick Fill Recipient'))
      );
  };

  const handleAddCustomerContact = async (formValues: PersonSelectorTypes.PersonSelectorFormValues) => {
    try {
      const customContactData = await addCustomContact({
        first_name: formValues?.firstName ?? '',
        last_name: formValues?.lastName ?? '',
        phone_mobile: formValues?.phoneNumber ?? '',
      });
      if (customContactData?.data?.person_id) {
        handleAddToList({
          personId: customContactData?.data?.person_id ?? '',
          notes: formValues?.notes ?? '',
          isNew: true,
        });
      }
    } catch (err) {
      alert.error(t('Error Adding Custom Contact'));
    }
  };

  return {
    selectedPerson,
    customContactDialogProps: personSelectorDialogProps,
    AddToListQuickFillListDialog: (
      <PersonSelector
        defaultSearchValue={defaultSearchValue}
        selectedPerson={selectedPerson}
        isLoading={isAddingQuickFillRecipient || isAddingCustomContact}
        onAddToListHandler={handleAddToList}
        handleAddCustomerContact={handleAddCustomerContact}
        locationId={selectedLocationId}
        personSelectorDialogProps={personSelectorDialogProps}
        shouldRenderNotes={true}
        width={width}
        {...personSelectorProps}
      />
    ),
  };
};
