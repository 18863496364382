import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { TeamChatTypes } from '@frontend/api-team-chat';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { getUserFullName } from '../../../utils';
import { ChatAvatar } from '../../common/chat-avatar/chat-avatar';
import { Author } from './author';
import { NonActionableMessageBase } from './base';
import { MessageBody } from './message-body';

interface MessageItemProps {
  message: TeamChatTypes.Message;
  isHighlighted?: boolean;
  onClick?: () => void;
  className?: string;
}

export const MessageMention = memo(({ className, message, isHighlighted, onClick }: MessageItemProps) => {
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const messageSenderUser = useMemo(() => helpers.getUser(message.userId), [helpers.getUser, message.userId]);
  const messageSenderUsers = useMemo(() => (messageSenderUser ? [messageSenderUser] : []), [messageSenderUser]);
  const authorName = getUserFullName(messageSenderUsers?.[0]?.firstName, messageSenderUsers?.[0]?.lastName);
  const conversation = useMemo(
    () => helpers.getConversation(message.channelId),
    [helpers.getConversation, message.channelId]
  );
  const type = conversation?.type;
  const note =
    type === 'DM'
      ? conversation?.memberIds.length === 1
        ? `${authorName} @Mentioned you in Direct Message`
        : `@Mention in ${conversation?.name}`
      : `@Mention in #${conversation?.name}`;
  return (
    <li css={style}>
      <Text className='note' color='subdued'>
        {message.isUnread && <UnreadDot />}
        {note}
      </Text>
      <NonActionableMessageBase
        as='div'
        className={className}
        css={css`
          padding: ${theme.spacing(1, 0)};
        `}
        isHighlighted={isHighlighted}
        onClick={onClick}
      >
        {messageSenderUsers && (
          <ChatAvatar
            users={messageSenderUsers}
            size='small'
            showStatusAsTooltip
            css={css({ gridColumn: '1/2', gridRow: '1/2' })}
          />
        )}
        <div>
          <Author date={message.createdAt} name={authorName || 'Unknown Member'} userID={message.userId} />
          <MessageBody message={message} />
        </div>
      </NonActionableMessageBase>
    </li>
  );
});

const UnreadDot = () => <span className='dot'></span>;

const style = css`
  .note {
    padding-bottom: ${theme.spacing(1)};
  }
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background: ${theme.colors.primary50};
    display: inline-block;
    margin-right: ${theme.spacing(1)};
    transform: translateY(-2px);
  }
  border-bottom: 1px solid #e5e5e5;
  padding: ${theme.spacing(1.5, 2)};
  list-style: none;
  margin: 0;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.neutral5};
  }
`;

MessageMention.displayName = 'MessageMention';
