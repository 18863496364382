import { useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../feature-flags';

export const useIsAppointmentDemoAccount = (): boolean => {
  // Maintaining a reusable hook to check if the account is a demo account
  // This hook will be updated further if we have more conditions to check
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.appointmentAnalyticsDemoData);

  return isDemoAccount;
};
