import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const Container = ({ children }: { children: ReactNode }) => {
  return <div css={style}>{children}</div>;
};

const style = css({
  padding: theme.spacing(1),
  display: 'flex',
  flex: 1,
  gap: theme.spacing(2),
  overflowY: 'auto',
  flexDirection: 'column',
});
