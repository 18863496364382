import { useTranslation } from '@frontend/i18n';
import type { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface ScheduleRequestDetailsPropertyProps extends CommonHTMLAttributes {
  label: string;
  value: string | React.ReactNode;
}
export const ScheduleRequestDetailsProperty = ({ label, value, ...rest }: ScheduleRequestDetailsPropertyProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');

  return (
    <div {...rest} style={{ padding: theme.spacing(1) }}>
      <Text size='small' color='light'>
        {label}
      </Text>
      {typeof value === 'string' ? <Text size='medium'>{value}</Text> : value}
      {typeof value === 'boolean' && <Text size='medium'>{value ? t('Yes') : t('No')}</Text>}
    </div>
  );
};
