import { FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { BundleFeatureEnum, BundleFeatureInfoMap } from './constants';

interface BundleHighlightedFeaturesProps {
  bundleFeatureEnumList: BundleFeatureEnum[];
  selectedBundleFeatureEnum?: BundleFeatureEnum;
  onChange: (bundleFeatureEnum: BundleFeatureEnum) => void;
}
export const BundleHighlightedFeatures: FC<BundleHighlightedFeaturesProps> = ({
  bundleFeatureEnumList = [],
  selectedBundleFeatureEnum,
  onChange,
}) => {
  const [hoveredFeature, setHoveredFeature] = useState<BundleFeatureEnum | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (hoveredFeature) {
      onChange(hoveredFeature);
    } else if (selectedBundleFeatureEnum) {
      timer = setTimeout(() => {
        const selectedIndex = bundleFeatureEnumList.indexOf(selectedBundleFeatureEnum);
        const nextIndex = (selectedIndex + 1) % bundleFeatureEnumList.length;
        onChange(bundleFeatureEnumList[nextIndex]);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [bundleFeatureEnumList, hoveredFeature, selectedBundleFeatureEnum]);

  useEffect(() => {
    if (!selectedBundleFeatureEnum && bundleFeatureEnumList.length) {
      onChange(bundleFeatureEnumList[0]);
    }
  }, [selectedBundleFeatureEnum, bundleFeatureEnumList]);

  return (
    <div css={containerStyles}>
      {bundleFeatureEnumList.map((bundleFeatureEnum) => (
        <FeatureCard
          key={bundleFeatureEnum}
          bundleFeatureEnum={bundleFeatureEnum}
          onHoverStateChange={(hoverActive) => setHoveredFeature(hoverActive ? bundleFeatureEnum : null)}
          selected={selectedBundleFeatureEnum === bundleFeatureEnum}
        />
      ))}
    </div>
  );
};

const FeatureCard: FC<{
  bundleFeatureEnum: BundleFeatureEnum;
  onHoverStateChange: (hoverActive: boolean) => void;
  selected: boolean;
}> = ({ bundleFeatureEnum, selected, onHoverStateChange }) => {
  const featureInfo = useMemo(() => BundleFeatureInfoMap[bundleFeatureEnum], [bundleFeatureEnum]);

  return (
    <div
      css={[featureCardStyles, selected && selectedCardStyles]}
      onMouseEnter={() => onHoverStateChange(true)}
      onMouseLeave={() => onHoverStateChange(false)}
      onClick={() => onHoverStateChange(true)}
    >
      <Icon
        name={featureInfo.icon}
        size={28}
        css={{
          flexShrink: 0,
          color: selected ? theme.colors.indigo60 : theme.colors.neutral90,
          padding: theme.spacing(0.5),
        }}
      />
      <div css={featureContentStyles}>
        <Text size='large' weight='bold' css={{ color: selected ? theme.colors.indigo50 : theme.colors.neutral90 }}>
          {featureInfo.title}
        </Text>
        <Text size='medium' color='light' css={{ color: selected ? theme.colors.indigo50 : theme.colors.neutral50 }}>
          {featureInfo.description}
        </Text>
      </div>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.borderRadius.large,
  overflow: 'auto',
});

const featureCardStyles = css({
  display: 'flex',
  padding: theme.spacing(1),
  gap: theme.spacing(1.5),
  borderRadius: theme.borderRadius.large,
});

const selectedCardStyles = css({
  backgroundColor: theme.colors.indigo5,
  color: theme.colors.indigo50,
});

const featureContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
});
