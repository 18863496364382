import { PaymentMethodsStepNavigationProps } from '../payment-methods.types';
import { usePayentMethodsGenericNavigation } from './payment-methods-generic-navigation';

export const PaymentMethodsSelectNavigation = ({ body: Body }: PaymentMethodsStepNavigationProps) => {
  const { onClickBack, onGoBack, onClickPrimary } = usePayentMethodsGenericNavigation();

  return (
    <Body
      onClickBack={onClickBack}
      onGoBack={onGoBack}
      onClickPrimary={onClickPrimary}
      /**
       * Allow default back button to be rendered.
       */
      back={undefined}
    />
  );
};
