import { useCallback } from 'react';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useMessageAction } from '@frontend/contact-actions-send-message';
import { useTranslation } from '@frontend/i18n';
import { useThreadActionsContext } from '../thread-actions';
import { ThreadActionProps } from '../types';

type PopOutActionProps = {
  targetSmsData?: { id: string; createdAt: string };
  onPopout: () => void;
} & Omit<ThreadActionProps, 'label' | 'icon' | 'onClick' | 'disabled'>;

export const PopOutAction = ({ onPopout, targetSmsData, ...props }: PopOutActionProps) => {
  const { t } = useTranslation('thread-actions');
  const { threadId, personId, personPhone, groupId, outboundPhone } = useThreadActionsContext();

  const { onClick } = useMessageAction();

  const handleClick = useCallback(async () => {
    await onClick({
      personId,
      personPhone,
      threadGroupId: groupId,
      departmentId: outboundPhone?.departmentId,
      locationPhone: outboundPhone?.locationPhone,
      targetSmsData,
      threadId,
    });
    onPopout();
  }, [onClick, onPopout]);

  return <AdaptoActions.Action label={t('Pop Out Conversation')} icon='pop-out' onClick={handleClick} {...props} />;
};
