import { useMutation } from 'react-query';
import { PaymentOrigin } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useInvoicePerson } from '../../../hooks';
import { useMakePayment } from '../../hooks';

interface UseCardOnFilePaymentOptions {
  paymentMethod?: PaymentMethod;
}

export const useCardOnFilePayment = ({ paymentMethod }: UseCardOnFilePaymentOptions) => {
  const { invoice } = useSelectedInvoice();
  const locationId = invoice?.locationId;
  const personId = invoice?.person.id;
  const { personEmail } = useInvoicePerson(invoice);
  const email = personEmail;
  const amount = invoice?.billedAmount ?? 0;

  const { makePayment } = useMakePayment({
    amount,
    email,
    personId,
    locationId,
    origin: PaymentOrigin.ManualCardEntry,
    setupFutureUsage: SetupFutureUsage.onSession,
    paymentMethod,
    invoiceId: invoice?.id,
  });

  const { mutateAsync: makeCardOnFilePayment, isLoading: makingCardOnFilePayment } = useMutation({
    mutationFn: () => makePayment(),
  });

  return { makeCardOnFilePayment, makingCardOnFilePayment };
};
