import { useCallback, useMemo, useState } from 'react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, SortingValue, Table, TableColumnConfig, Tabs } from '@frontend/design-system';
import { LocationChip } from '../../../location-chip';
import { TableActions } from '../../../table-actions';
import { UserCard } from '../../../user-card';
import {
  LeadsContactedAdditionalPayload,
  LeadsConvertedAdditionalPayload,
  RoiPatient,
  useFetchRoiPatients,
  UseFetchRoiPatientsProps,
} from '../../hooks';
import { MessagingRoi, RoiBaseFilters } from '../../types';

export interface MessagingRoiTableProps {
  filters?: RoiBaseFilters;
  isDemoAccount?: boolean;
  messagingCategory: UseFetchRoiPatientsProps['category'];
  messagingSubCategory: UseFetchRoiPatientsProps['subCategory'];
  messagingTableUniqueId: string;
  middleColumnConfig?: TableColumnConfig<RoiPatient>[];
  trackingIdBase?: string;
}

type Tab = 'opportunities' | 'all' | 'appts_completed';

interface TablePanelProps extends MessagingRoiTableProps {
  tableInstanceId: string;
  tabType: Tab;
}

type Sorting = {
  order_by_field: string;
  order_by: SortingValue<string>['value'];
};

const TablePanel = ({
  filters,
  isDemoAccount,
  messagingCategory = 'missedcalltext',
  messagingSubCategory = 'getrawmctsms',
  messagingTableUniqueId,
  middleColumnConfig = [],
  tableInstanceId,
  tabType,
  trackingIdBase = '',
}: TablePanelProps) => {
  const { t } = useTranslation('analytics');
  const { accessibleLocationData } = useAppScopeStore();
  const locationsData = accessibleLocationData;
  const search = useSearch<{ Search: { view: MessagingRoi } }>();

  const [sorting, setSorting] = useState<Sorting>({
    order_by: 'desc',
    order_by_field: 'entry_date',
  });

  const additionalPayload = useMemo((): LeadsContactedAdditionalPayload | LeadsConvertedAdditionalPayload => {
    const commonPayload = {
      // appointment_filter_flag: true means filtered opportunities, else all leads (patients)
      appointment_filter_flag: tabType === 'opportunities' || tabType === 'appts_completed',
    };

    if (messagingSubCategory === 'getrawmctsms' || messagingSubCategory === 'getrawsms') {
      return {
        ...commonPayload,
        appointment_scheduled_flag: false,
      };
    }

    if (messagingSubCategory === 'getconvertedpatients') {
      return {
        ...commonPayload,
        appointment_status: {
          appointment_status: 'Completed',
          appointment_status_equality: search.view === 'revenueGenerated',
        },
      };
    }

    return {};
  }, [messagingSubCategory, search.view, tabType]);

  const { hasNextPage, hasPreviousPage, isLoading, pageConfig, patients, setPageConfig } = useFetchRoiPatients({
    category: messagingCategory,
    isDemoAccount,
    payload: {
      ...sorting,
      appointment_filters: additionalPayload,
      end_date: filters?.endDate,
      location_id: filters?.locationIds,
      roi_type: 'MissedCallText',
      start_date: filters?.startDate,
    },
    queryKeyHelpers: [messagingTableUniqueId, tabType],
    subCategory: messagingSubCategory,
  });

  const handleSorting = useCallback((sortValues: SortingValue<string>[]) => {
    const { id, value } = sortValues[0] || {};
    if (id && value) {
      setSorting({
        order_by_field: id,
        order_by: value,
      });
    }
  }, []);

  const columnConfig: TableColumnConfig<RoiPatient>[] = useMemo(() => {
    return [
      {
        accessor: (patient) => patient,
        cellRenderer: ({ location_id, patient_id, patient_name = '' }: RoiPatient) => {
          const [firstName, ...rest] = patient_name.split(' ');
          return (
            <UserCard
              firstName={firstName}
              key={patient_id}
              lastName={rest.join(' ')}
              locationId={location_id}
              openProfileOnClick={!isDemoAccount}
              showOnlyName
              userId={isDemoAccount ? '' : patient_id}
            />
          );
        },
        disableColumnFilter: true,
        disableSortBy: true,
        Header: t('Patient Name'),
        id: 'patient_name',
        sticky: 'left',
        width: 200,
      },
      {
        accessor: ({ location_id }) => location_id,
        cellRenderer: (value: string) => {
          return value ? <LocationChip locationName={locationsData?.[value]?.name || value} maxWidth={180} /> : '-';
        },
        disableSortBy: true,
        Header: t('Location'),
        id: 'location_id',
        omit: filters?.locationIds?.length === 1,
        width: 220,
      },
      {
        accessor: ({ contact_type }) => contact_type,
        cellRenderer: (value) => {
          switch (value) {
            case 'new_patient':
              return <Chip variant='warn'>{t('New Patient')}</Chip>;

            case 'existing_patient':
              return <Chip variant='primary'>{t('Existing Patient')}</Chip>;

            case 'unscheduled':
              return <Chip variant='indigo'>{t('Unscheduled')}</Chip>;

            default:
              return '-';
          }
        },
        disableSortBy: true,
        Header: t('Contact Type'),
        id: 'contact_type',
        width: 160,
      },
      ...middleColumnConfig,
      {
        accessor: ({ patient_id, patient_name, phone_number }) => ({
          patient_id,
          patient_name,
          phone_number,
        }),
        cellRenderer: ({ patient_id, patient_name, phone_number }) => {
          return (
            <TableActions
              isDemoAccount={isDemoAccount}
              personId={patient_id}
              personName={patient_name}
              phoneNumber={phone_number}
              trackingIdBase={`${trackingIdBase}-${tabType}`}
            />
          );
        },
        cellAlign: 'center',
        disableSortBy: true,
        Header: '',
        id: 'actions',
        sticky: 'right',
        width: 120,
      },
    ];
  }, [locationsData, middleColumnConfig]);

  return (
    <Table
      colConfig={columnConfig}
      data={patients}
      disableMultiSort
      hasResizeColumns
      hasResponsiveColWidths
      isLoading={isLoading}
      isPaginated
      manualPaginationConfig={{
        handleChange: (action: 'next' | 'prev') => {
          if (action === 'next') {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
          } else {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
          }
        },
        hasNext: hasNextPage,
        hasPrevious: hasPreviousPage,
        page: pageConfig.pageNumber,
        defaultRowsPerPage: pageConfig.pageSize,
        onNumRowsChange: (num) => {
          setPageConfig({ pageNumber: 1, pageSize: num });
        },
        rowsPerPageOptions: [10, 25, 50, 100],
      }}
      manualSortBy
      onSortChange={handleSorting}
      tableInstanceId={tableInstanceId}
    />
  );
};

export const MessagingRoiTable = (props: MessagingRoiTableProps) => {
  const { t } = useTranslation('analytics');
  const search = useSearch<{ Search: { view: MessagingRoi } }>();
  const [activeTab, setActiveTab] = useState<Tab>(
    search.view === 'revenueGenerated' ? 'appts_completed' : 'opportunities'
  );

  return (
    <div>
      <Tabs initialTab={activeTab} onChange={(id: string) => setActiveTab(id as Tab)}>
        <Tabs.Bar css={{ marginBottom: theme.spacing(2) }}>
          {search.view === 'revenueGenerated'
            ? [
                <Tabs.Tab
                  id='appts_completed'
                  key='appts_completed'
                  trackingId={`${props.trackingIdBase}-appts_completed`}
                >
                  {t('Appointments Completed')}
                </Tabs.Tab>,
              ]
            : [
                <Tabs.Tab id='opportunities' key='opportunities' trackingId={`${props.trackingIdBase}-opportunities`}>
                  {t('Opportunities')}
                </Tabs.Tab>,
                <Tabs.Tab id='all' key='all' trackingId={`${props.trackingIdBase}-all`}>
                  {t('All')}
                </Tabs.Tab>,
              ]}
        </Tabs.Bar>

        {search.view === 'revenueGenerated' ? (
          <Tabs.Panel controller='appts_completed'>
            <TablePanel {...props} tableInstanceId='messaging-roi-appts-compeleted' tabType='appts_completed' />
          </Tabs.Panel>
        ) : (
          <>
            <Tabs.Panel controller='opportunities'>
              <TablePanel {...props} tableInstanceId='messaging-roi-opportunities-patients' tabType='opportunities' />
            </Tabs.Panel>
            <Tabs.Panel controller='all'>
              <TablePanel {...props} tableInstanceId='messaging-roi-all-patients' tabType='all' />
            </Tabs.Panel>
          </>
        )}
      </Tabs>
    </div>
  );
};
