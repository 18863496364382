import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip, Avatar, NakedButton, Text } from '@frontend/design-system';
import { useBookingSiteStore } from '../../../../stores';
import { AllProvidersApptCount, ProviderApptCount } from './provider-appt-count';

export const ProviderList = () => {
  const { t } = useTranslation('bookingSite');
  const { isSchedularV3Enabled, availableProviders, setSelectedProvider } = useBookingSiteStore([
    'isSchedularV3Enabled',
    'availableProviders',
    'setSelectedProvider',
  ]);

  return (
    <section css={containerStyle}>
      {!isSchedularV3Enabled && availableProviders.length > 1 && (
        <NakedButton onClick={() => setSelectedProvider('any')}>
          <div className='provider-info'>
            <Avatar src={''} size='xl' />
            <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(0.5) }}>
              <Text as='span' weight='bold' css={{ textAlign: 'start' }}>
                {t('No Preference')}
              </Text>
              <Chip css={{ maxWidth: 'max-content' }}>{t('Most Availability')}</Chip>
            </div>
          </div>
          <AllProvidersApptCount />
        </NakedButton>
      )}
      {availableProviders.map((provider) => {
        const { firstName, lastName, publicDisplayName, publicDisplayImage, id } = provider;
        const name = publicDisplayName || `${firstName} ${lastName}`;
        return (
          <NakedButton key={id} onClick={() => setSelectedProvider(provider)}>
            <div className='provider-info'>
              <Avatar name={name} src={publicDisplayImage} size='xl' />
              <Text
                as='span'
                weight='bold'
                textAlign='left'
                css={{ overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}
              >
                {name}
              </Text>
            </div>
            <ProviderApptCount provider={provider} />
          </NakedButton>
        );
      })}
    </section>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  button: {
    minWidth: '100%',
    borderBottom: `1px solid ${theme.colors.neutral20}`,
    display: 'flex',
    justifyContent: 'space-between',
    '.provider-info': {
      alignSelf: 'center',
      minWidth: 250,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 0),
      gap: theme.spacing(2),
    },
    '@media only screen and (max-width: 650px)': {
      flexDirection: 'column',
      '.provider-info': {
        position: 'sticky',
        left: 0,
        right: 0,
      },
    },
  },
});
