import { ListRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { GetAppointmentTypeListType, ListAppointmentTypeTimesType } from './types';

type AppointmentOpeningTimeParamsType = {
  apptTypeId: string;
  weekInView: string;
  locationId: string;
  providerId?: string;
};
export const scheduleBookingSiteQueryKeys = {
  base: ['booking-site'],
  locations: (locationId: string) => [...scheduleBookingSiteQueryKeys.base, 'locations', locationId],
  providers: (locationId: string) => [...scheduleBookingSiteQueryKeys.base, 'providers', locationId],
  appointmentTypes: (req: GetAppointmentTypeListType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'appointmentTypes',
    req,
  ],
  appointmentTypeTimes: (req: ListAppointmentTypeTimesType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'appointmentTypeTimes',
    req,
  ],
  customFields: (locationId: string) => [...scheduleBookingSiteQueryKeys.base, 'customFields', locationId],
  getAppointmentOpeningsKeys: ({
    apptTypeId,
    weekInView,
    locationId,
    providerId = 'any',
  }: AppointmentOpeningTimeParamsType) => ['appointmentOpeningsTime', providerId, apptTypeId, weekInView, locationId],
};

export const scheduleQuickFillQueryKeys = {
  base: ['quick-fill'],
  infiniteQuickFillRecipients: (request: Omit<ListRequest, 'skip' | 'limit'>) => [
    ...scheduleQuickFillQueryKeys.base,
    'infinite-recipients',
    request,
  ],
  quickFillRecipientCounts: (locationIds: string[]) => [
    ...scheduleQuickFillQueryKeys.base,
    'recipient-counts',
    locationIds,
  ],
  quickFillRecipients: (locationId: string) => [...scheduleQuickFillQueryKeys.base, 'recipients', locationId],
  quickFillRecipient: (locationId: string, personId: string) => [
    ...scheduleQuickFillQueryKeys.base,
    'recipient',
    locationId,
    personId,
  ],
};
