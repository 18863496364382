import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, CheckboxField, useControlledField } from '@frontend/design-system';
import { StreamUser } from '../../../types';
import { getFullName } from '../../../utils';
import { ChatAvatar } from '../../common/';

interface UserRowProps {
  user: StreamUser;
  selectedMembers: StreamUser[];
  onRemoveUser: (user: StreamUser) => void;
  onAddUser: (user: StreamUser) => void;
}

export const UserRow = ({ user, selectedMembers, onAddUser, onRemoveUser }: UserRowProps) => {
  const { t } = useTranslation('team-chat');
  const isMemberAdded = selectedMembers.find(({ userID }) => userID === user.userID);
  const checkboxFieldProps = useControlledField({
    value: !!isMemberAdded,
    onChange: () => (isMemberAdded ? onRemoveUser(user) : onAddUser(user)),
    type: 'checkbox',
  });

  const checkboxName = isMemberAdded ? t('Remove Member') : t('Add Member');

  return (
    <div css={userContainerStyle} key={user.userID}>
      <ChatAvatar members={[user]} />
      <Text>{getFullName(user)}</Text>
      <CheckboxField
        {...checkboxFieldProps}
        name={checkboxName}
        label={undefined}
        trackingId={
          isMemberAdded
            ? 'team-chat-2.0-remove-member-from-existing-group-button'
            : 'team-chat-2.0-add-member-to-existing-group-button'
        }
        css={marginLeftAuto}
      />
    </div>
  );
};

const userContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

const marginLeftAuto = css({ marginLeft: 'auto' });
