import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { LocationAccordionInfo, LocationAccordionList } from '../../../components';
import { RecipientTally } from '../components/recipient-tally';
import { useQuickFillRecipientShallowStore } from '../store';
import { QuickFillList } from './list';

export const ManageListTabContent = () => {
  const { getScopeName } = useAppScopeStore();
  const { validLocationIds, recipientCounts } = useQuickFillRecipientShallowStore(
    'validLocationIds',
    'recipientCounts'
  );

  const validLocationInfoList = useMemo<LocationAccordionInfo[]>(() => {
    return validLocationIds.map((locationId: string) => {
      const { filteredCount, totalCount } = recipientCounts[locationId] || {};
      return {
        locationId,
        name: getScopeName(locationId),
        count: <RecipientTally filteredCount={filteredCount} totalCount={totalCount} textColor='inherit' />,
      };
    });
  }, [validLocationIds]);

  return <LocationAccordionList locationInfoList={validLocationInfoList} ContentComponent={QuickFillList} />;
};
