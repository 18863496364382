import { FC, memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { UserDetails } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { PersonTypes } from '@frontend/api-person';
import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PopoverDialog, usePopoverDialog, SearchableListbox, Avatar, Text } from '@frontend/design-system';
import { PersonType } from '../../../types';
import { getFullName } from '../../../utils';
import { TaskFormRow } from '../../common';

interface CommonProps {
  label: string;
  icon: IconName;
  buttonIconName: IconName;
  placeholder: string;
  onChange: (value: string) => void;
  value: string;
  type: PersonType;
}

interface PatientPickerProps extends CommonProps {
  type: 'patient';
  data: PersonTypes.Person[];
}

interface AssigneePickerProps extends CommonProps {
  data: UserDetails[];
  type: 'assignee';
}

type PersonPickerProps = PatientPickerProps | AssigneePickerProps;

export const PersonPicker: FC<PersonPickerProps> = memo((props) => {
  const { getDialogProps, getTriggerProps, close } = usePopoverDialog({ placement: 'bottom' });
  const { label, icon, buttonIconName, placeholder, onChange, data, value } = props;

  const getSearchableListboxOption = useCallback((id: string, name: string): JSX.Element => {
    return (
      <SearchableListbox.Option
        value={id}
        key={`searchable-${id}`}
        css={css`
          border-radius: ${theme.borderRadius.small};
          padding: ${theme.spacing(1)};

          span {
            display: flex;
            gap: ${theme.spacing(1)};
          }
        `}
      >
        <Avatar size='xs' name={name} /> <Text size='large'>{name}</Text>
      </SearchableListbox.Option>
    );
  }, []);

  if (!data) {
    return null;
  }

  function getPersonFullname() {
    if (props.type === 'patient') {
      const personValue = props.data.find((item) => item.PersonID === value);
      if (!personValue) return '';
      return getFullName({ user: personValue, type: 'patient' });
    }

    const personValue = props.data.find((item) => item.userId === value);
    if (!personValue) return '';
    return getFullName({ user: personValue, type: 'assignee' });
  }

  function getSearchableListboxOptions() {
    if (props.type === 'patient') {
      return props.data.map((item) => {
        const name = getFullName({ user: item, type: 'patient' });
        return getSearchableListboxOption(item.PersonID, name);
      });
    }

    return props.data.map((item) => {
      const name = getFullName({ user: item, type: 'assignee' });
      return getSearchableListboxOption(item.userId ?? '', name);
    });
  }

  return (
    <TaskFormRow
      label={label}
      icon={icon}
      placeholder={placeholder}
      buttonIconName={buttonIconName}
      value={getPersonFullname()}
      getTriggerProps={getTriggerProps}
      onRemoveClick={() => onChange('')}
    >
      <PopoverDialog {...getDialogProps()} css={popoverDialogStyle}>
        <SearchableListbox
          value={value}
          onSelect={(value) => {
            onChange(value as string);
            close();
          }}
          labelId={''}
          css={css`
            height: 240px;
          `}
        >
          {getSearchableListboxOptions()}
        </SearchableListbox>
      </PopoverDialog>
    </TaskFormRow>
  );
});

PersonPicker.displayName = 'PersonPicker';

const popoverDialogStyle = css`
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.floating};
  border: 1px solid ${theme.colors.neutral10};
  width: 300px;
`;
