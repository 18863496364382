import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { SkeletonLoader, Text } from '@frontend/design-system';

type ContactMenuItemProps = {
  index: number;
  personId: string;
  locationId: string;
  name: string;
  invoiceCount: number;
};

// This component assumes that the contact with index=0 is the primary contact
export const ContactMenuItem = ({ index, personId, locationId, name, invoiceCount }: ContactMenuItemProps) => {
  const { t } = useTranslation('contacts');

  const { data: person, isLoading } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      locationIds: [locationId],
      personId,
    },
    {
      enabled: !name && !!personId,
    }
  );
  const personName = name || (person ? PersonsV3.PersonHelpers.getFullName(person) : t('Unknown Contact'));

  return isLoading && !name ? (
    <SkeletonLoader css={{ width: '100%', height: '50%', minWidth: '165px' }} />
  ) : (
    <>
      <div
        css={{
          display: 'flex',
          gap: theme.spacing(0.5),
          ...(!!index && { paddingLeft: theme.spacing(1) }),
        }}
      >
        {/* All contacts, except the first menu item, are assumed to be associated contacts and will have a nested icon before them. */}
        {!!index && (
          <div css={{ width: '15px' }}>
            <Icon name='nested-small' size={28} color='disabled' />
          </div>
        )}
        <Photos.ContactProfilePhoto
          personId={personId}
          locationId={locationId}
          name={personName}
          size='small'
          disableClick
        />
      </div>
      <div
        css={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Text
            css={{
              maxWidth: theme.spacing(14),
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {personName}
          </Text>
          {!index && (
            <Text size='small' color='subdued' css={{ alignSelf: 'flex-start' }}>
              {t('Phone Owner')}
            </Text>
          )}
        </div>
        <Text size='small' css={{ color: !invoiceCount ? theme.colors.neutral20 : theme.colors.neutral70 }}>
          {invoiceCount === 1
            ? t('{{count}} invoice', { count: invoiceCount })
            : t('{{count}} invoices', { count: invoiceCount })}
        </Text>
      </div>
    </>
  );
};
