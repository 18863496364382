import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { ContactType_Enum, PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { titleCase } from '@frontend/string';
import { getFullName } from '../../utils';
import { TransformedLegacyPerson } from './components/PersonSelector/types';
import { getPersonContactInfoByType } from './utils';

const FILTERED_PROVIDERS_IN_CALENDAR_VIEW_LOCAL_STORAGE_KEY = 'FilteredProvidersInCalendarView';

export const getFilteredProvidersInCalendarViewLocationStorageKey = (locationId: string, userId: string) => {
  return `${FILTERED_PROVIDERS_IN_CALENDAR_VIEW_LOCAL_STORAGE_KEY}_${locationId}_${userId}`;
};

export const transformToLegacyPerson = (person: Person) => {
  return {
    PreferredName: person.preferredName ?? '',
    FirstName: person.firstName ?? '',
    LastName: person.lastName ?? '',
    PersonID: person.personId,
    ContactInfo: person.contactInfo,
    Notes: person.notes ?? '',
    SourceID: person.sourceId ?? '',
    SourceTenantID: person.sourceTenantId ?? '',
    MobilePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_MOBILE,
    }),
    Email: getPersonContactInfoByType({ contactInfo: person?.contactInfo, contactType: ContactType_Enum.EMAIL }),
    HomePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_HOME,
    }),
    PMID: person.personPmid ?? '',
    PMDisplayId: person.personDisplayPmid ?? '',
    Gender: person.gender ?? '',
    Status: titleCase(PersonStatus_Enum[person.status || PersonStatus_Enum.UNSPECIFIED]),
    Birthdate: person.birthdate ?? '',
  } as unknown as TransformedLegacyPerson;
};

type GetFilteredAppointmentsProps = {
  appointmentsToFilter: Appointment[];
  providerList: Provider[];
  selectedAppointmentTypes?: string[];
  appointmentStatus: string;
};

export const getFilteredAppointments = ({
  appointmentsToFilter,
  providerList,
  selectedAppointmentTypes,
  appointmentStatus,
}: GetFilteredAppointmentsProps): Appointment[] => {
  if (!appointmentsToFilter.length) return [];
  if (!providerList.length && !selectedAppointmentTypes?.length && !appointmentStatus) return appointmentsToFilter;

  return appointmentsToFilter
    ?.filter((appointment) => {
      return !!selectedAppointmentTypes?.length ? selectedAppointmentTypes.includes(appointment.type ?? '') : true;
    })
    .filter((appointment) => {
      return !!appointmentStatus
        ? appointment.statusOfficeView?.toUpperCase() === appointmentStatus.toUpperCase()
        : true;
    })
    .filter((appointment) => {
      return providerList.some((provider) => {
        const appointmentPractitionerName = appointment.practitionerName?.trim();
        const providerPublicDisplayName = provider.publicDisplayName?.trim();
        const providerFullName = getFullName(provider);
        const providerResourceName = provider.resourceName?.trim();

        const hasMatchingProvider =
          (appointmentPractitionerName === providerPublicDisplayName ||
            appointmentPractitionerName === providerFullName ||
            appointmentPractitionerName === providerResourceName) &&
          appointment.locationId === provider.locationId;

        const hasUnassignedProvider =
          !appointmentPractitionerName &&
          !!appointment.providerIds?.length &&
          appointment?.providerIds[0] === '' &&
          provider.id === `unassigned-${appointment.locationId}` &&
          providerPublicDisplayName === 'unassigned';

        return hasMatchingProvider || hasUnassignedProvider;
      });
    });
};

export const getLocalStorageProviderListDetails = (
  locationId: string,
  userId: string,
  defaultProvidersList?: Provider[]
) => {
  try {
    const localStorageProviderIdsList: Array<string> = JSON.parse(
      localStorage.getItem(getFilteredProvidersInCalendarViewLocationStorageKey(locationId, userId)) || '[]'
    );

    const localStorageProviderList =
      defaultProvidersList?.filter((provider) => provider.id && localStorageProviderIdsList.includes(provider.id)) ||
      [];

    return {
      localStorageProviderIdsList,
      localStorageProviderList,
    };
  } catch (err) {
    console.error(err);
    return {
      localStorageProviderIdsList: [],
      localStorageProviderList: [],
    };
  }
};
