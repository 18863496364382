import { useState } from 'react';
import dayjs from 'dayjs';
import Lottie from 'react-lottie';
import { getUser, localStorageHelper } from '@frontend/auth-helpers';
import { theme } from '@frontend/theme';
import heart from './heart.json';

export const HeartAnimation = () => {
  const user = getUser();
  const VALENTINE_ANIMATION_KEY = `valentine-animation-shown-${user?.userID}`;

  const [shouldShow, setShouldShow] = useState(() => {
    const today = dayjs();
    const currentYear = today.year();
    const isValentinesDay = today.month() === 1 && today.date() === 14;

    const lastShownYear = localStorageHelper.get(VALENTINE_ANIMATION_KEY);

    return isValentinesDay && `${lastShownYear}` !== currentYear.toString();
  });

  const handleAnimationComplete = () => {
    const currentYear = dayjs().year();
    localStorageHelper.create(VALENTINE_ANIMATION_KEY, currentYear.toString());
    setShouldShow(false);
  };

  return shouldShow ? (
    <Lottie
      isClickToPauseDisabled
      style={{
        position: 'fixed',
        zIndex: theme.zIndex.alerts,
        top: 0,
        bottom: 0,
        right: 0,
        left: '0',
        width: '100%',
        pointerEvents: 'none',
        maxWidth: '700px',
      }}
      options={{
        loop: false,
        animationData: heart,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      eventListeners={[
        {
          eventName: 'complete',
          callback: handleAnimationComplete,
        },
      ]}
    />
  ) : null;
};
