import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { getFormsTeaserTrackingId } from '../constants/tracking-ids';
import { getUpgradeBundleDisplayName } from '../self-upgrade.util';
import { useFeatureSubscriptionConfig } from './useFeatureSubscriptionConfig';

interface UseFormsTeaserConfigProps {
  locationIds: string[];
  checkBundleUpgrade?: boolean;
  source: string;
}

interface UseFormsTeaserConfigResults {
  actionLabel: string;
  isFormsActive: boolean;
  isLoading: boolean;
  isBundleUpgradeEligible: boolean;
  showFormsTeaser: boolean;
  trackingId: string;
}

export const useFormsTeaserConfig = ({
  locationIds = [],
  checkBundleUpgrade = true,
  source,
}: UseFormsTeaserConfigProps): UseFormsTeaserConfigResults => {
  const { t } = useTranslation('featurePromotion>>digital-forms-promotion');
  const {
    isBundleUpgradeEligible,
    isFeatureHiddenInAllLocations,
    isLoading,
    isUpgradeOrTrialEligible,
    recommendedBundle,
  } = useFeatureSubscriptionConfig({
    feature: Feature.FORMS,
    locationIds,
    checkBundleUpgrade,
  });

  const actionLabel = useMemo(() => {
    if (isBundleUpgradeEligible) {
      return t('Try this feature for free with the {{bundleName}} Bundle trial', {
        bundleName: getUpgradeBundleDisplayName(recommendedBundle),
      });
    } else if (isUpgradeOrTrialEligible) {
      return t('Digital Forms is an Elite feature. Click to upgrade.');
    }
    return '';
  }, [isBundleUpgradeEligible, isUpgradeOrTrialEligible, recommendedBundle]);

  const trackingId = useMemo(() => {
    return getFormsTeaserTrackingId({
      source,
      upgradeType: isBundleUpgradeEligible ? 'bundle' : 'feature',
    });
  }, [isBundleUpgradeEligible, source]);

  return {
    actionLabel,
    isFormsActive: !isFeatureHiddenInAllLocations,
    isLoading,
    isBundleUpgradeEligible,
    showFormsTeaser: isUpgradeOrTrialEligible,
    trackingId,
  };
};
