export interface Person {
  Accounts: any;
  Address: Address;
  Appointments: Appointment[];
  Birthdate: string;
  ClientLocationID: string;
  ClientLocationName: string;
  ContactInfo: ContactInfo;
  Email: string;
  FirstName: string;
  Gender: string;
  HomePhone: string;
  LastName: string;
  MobilePhone: string;
  Notes: string;
  OtherContactInfo: string;
  PersonID: string;
  PMID: string;
  PMDisplayID: string;
  Preferences: Preferences;
  PreferredName: string;
  Recall: any[];
  ReceiveSMS?: boolean;
  SourceType?: PersonSourceType;
  SourceID: string;
  SourceName: string;
  Status: 'Active' | 'Inactive' | 'Prospective';
  WeaveHouseholdID: string;
  WorkPhone: string;
  Department?: string; // department ID as string
}

export type DeletePersonImageResponse = { response_time: number };

export type UploadPersonImageResponse = {
  response_time: number;
  insert_id: string;
  records_inserted: number;
};

export type TaskType = 'birthday' | 'collection' | 'recall' | 'reminder' | 'unknown';

export interface HouseholdTask {
  task_id: string;
  id: string;
  description: string;
  status?: string;
  stars: null;
  next_appt_date: string;
  apt_datetime: string;
  apt_pms_status: string;
  date_created: string;
  created_by: {
    first_name: string;
    last_name: string;
    employee_id: string;
    user_id: string;
    email: string;
    username: string;
    photo: string;
  };
  date_completed: null;
  completed_by: string;
  uncompleted_by: null;
  modified_by: null;
  appointment?: {
    patient: {
      patient_id: string;
      household_id: string;
      photo: null;
      first_name: string;
      last_name: string;
      preferred_name: string;
      email: string;
      gender: string;
      sex: string;
      birthdate: null;
      is_guardian: true;
      next_apt: null;
      last_visit: null;
      due_for_cc: null;
      due_date: null;
      date_due: null;
      notes: null;
      collection: {
        aging_0to30: null;
        aging_31to60: null;
        aging_61to90: null;
        aging_91plus: null;
        aging_total: number | null;
        total: number | null;
      };
      phones: {
        home: string;
        mobile: string;
        work: string;
        other: null;
        phone_home: string;
        phone_mobile: string;
        phone_work: string;
        phone_other: null;
        phone_home_textable: null;
        phone_mobile_textable: null;
        phone_work_textable: null;
        phone_other_textable: null;
      };
      address: {
        street: null;
        city: null;
        state: null;
        postcode: null;
      };
      aging_0to30: null;
      aging_31to60: null;
      aging_61to90: null;
      aging_91plus: null;
      aging_total: number | null;
      total: number | null;
      home: string;
      mobile: string;
      work: string;
      other: null;
      phone_home: string;
      phone_mobile: string;
      phone_work: string;
      phone_other: null;
      phone_home_textable: null;
      phone_mobile_textable: null;
      phone_work_textable: null;
      phone_other_textable: null;
      street: null;
      city: null;
      state: null;
      postcode: null;
      status: null;
    };
    appointment_id: string;
    id: string;
    appointment_datetime: string;
    apt_status: string;
    pms_status: string;
    writeback_appointment_status: null;
    writeback_status: null;
    apt_practitioner: string;
    practitioner: null;
    apt_type: string;
    type: null;
    next_appointment: null;
    next_appt_date: null;
    calculated_status: null;
    calculated_status_details: null;
    serviceable_id: string;
    datetime: null;
    patient_id: string;
    household_id: string;
  };
  patient: {
    patient_id: string;
    household_id: string;
    photo: null;
    first_name: string;
    last_name: string;
    preferred_name: string;
    email: string;
    gender: string;
    sex: string;
    birthdate: null;
    is_guardian: true;
    next_apt: null;
    last_visit: null;
    due_for_cc: null;
    due_date: null;
    date_due: null;
    notes: null;
    collection: {
      aging_0to30: null;
      aging_31to60: null;
      aging_61to90: null;
      aging_91plus: null;
      aging_total: number | null;
      total: number | null;
    };
    phones: {
      home: string;
      mobile: string;
      work: string;
      other: null;
      phone_home: string;
      phone_mobile: string;
      phone_work: string;
      phone_other: null;
      phone_home_textable: null;
      phone_mobile_textable: null;
      phone_work_textable: null;
      phone_other_textable: null;
    };
    address: {
      street: null;
      city: null;
      state: null;
      postcode: null;
    };
    aging_0to30: null;
    aging_31to60: null;
    aging_61to90: null;
    aging_91plus: null;
    aging_total: number | null;
    total: number | null;
    home: string;
    mobile: string;
    work: string;
    other: null;
    phone_home: string;
    phone_mobile: string;
    phone_work: string;
    phone_other: null;
    phone_home_textable: null;
    phone_mobile_textable: null;
    phone_work_textable: null;
    phone_other_textable: null;
    street: null;
    city: null;
    state: null;
    postcode: null;
    appointment_id: string;
    id: string;
    appointment_datetime: string;
    apt_status: string;
    pms_status: string;
    writeback_appointment_status: null;
    writeback_status: null;
    apt_practitioner: string;
    practitioner: null;
    apt_type: string;
    type: null;
    next_appointment: null;
    next_appt_date: null;
    calculated_status: null;
    calculated_status_details: null;
    serviceable_id: string;
    datetime: null;
    apt_datetime: string;
  };
  patient_id: string;
  household_id: string;
  photo: null;
  first_name: string;
  last_name: string;
  preferred_name: string;
  email: string;
  gender: string;
  sex: string;
  birthdate: string;
  is_guardian: true;
  next_apt: null;
  last_visit: null;
  due_for_cc: null;
  due_date: null;
  date_due: null;
  notes: null;
  collection: {
    aging_0to30: null;
    aging_31to60: null;
    aging_61to90: null;
    aging_91plus: null;
    aging_total: number | null;
    total: number | null;
  };
  phones: {
    home: string;
    mobile: string;
    work: string;
    other: null;
    phone_home: string;
    phone_mobile: string;
    phone_work: string;
    phone_other: null;
    phone_home_textable: null;
    phone_mobile_textable: null;
    phone_work_textable: null;
    phone_other_textable: null;
  };
  address: {
    street: null;
    city: null;
    state: null;
    postcode: null;
  };
  aging_0to30: null;
  aging_31to60: null;
  aging_61to90: null;
  aging_91plus: null;
  aging_total: number | null;
  total: number | null;
  home: string;
  mobile: string;
  work: string;
  other: null;
  phone_home: string;
  phone_mobile: string;
  phone_work: string;
  phone_other: null;
  phone_home_textable: null;
  phone_mobile_textable: null;
  phone_work_textable: null;
  phone_other_textable: null;
  street: null;
  city: null;
  state: null;
  postcode: null;
  appointment_id: string;
  appointment_datetime: string;
  apt_status: string;
  pms_status: string;
  writeback_appointment_status: null;
  writeback_status: null;
  apt_practitioner: string;
  practitioner: null;
  apt_type: string;
  type: string | null;
  next_appointment: null;
  calculated_status: null;
  calculated_status_details: null;
  serviceable_id: string;
  datetime: null;
}

export type HouseholdTasks = {
  birthdays: HouseholdTask[];
  contact: HouseholdTask[];
  overdue: HouseholdTask[];
  reminders: HouseholdTask[];
  collections: HouseholdTask[];
  information: unknown;
  // i'm not sure if this typo is the actual type, or a misspelling
  eyewear: unknown;
};

export interface AgingData {
  aging_0to30: string;
  aging_31to60: string;
  aging_61to90: string;
  aging_91plus: string;
  aging_total: string;
}

export interface HouseholdMember {
  phones: {
    home_textable: string | null;
    mobile_textable: string | null;
    work_textable: string | null;
    other_textable: string | null;
  };
  phone_home: string;
  phone_home_textable: any;
  phone_mobile: string;
  phone_mobile_textable: any;
  phone_work: string;
  phone_work_textable: any;
  phone_other: string;
  phone_other_textable: any;
  sms_number: string;
  address: {
    street: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  street: string;
  city: string;
  state: string;
  postcode: string;
  photo: string;
  id: string;
  patient_id: string;
  person_id: string;
  household_id: string;
  weaveHouseholdId: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  gender: string;
  sex: string;
  birthdate: string;
  is_guardian: boolean;
  status: Person['Status'];
  source_id: string;
  client_location_id: string;
  client_location_name: string;
  next: string | null;
  last: string | null;
}

export enum PersonSourceType {
  Integration = 0,
  Csv = 1,
  CustomContact = 2,
}

export interface Account {
  AccountID: string;
  AccountPMID: string;
  AccountRelationID: string;
  AccountRelationPMID: string;
  PersonID: string;
}

export interface Address {
  City: string;
  State: string;
  Street: string;
  Zip: string;
}

export interface Appointment {
  AppointmentDate: Date;
  AppointmentID: string;
  AppointmentPMID: string;
  ConfirmationStatus: string;
  Length: number;
  Operatory: string;
  PersonID: string;
  Provider: string;
  Status: string;
  StatusPM: string;
  Type: string;
}

export interface ContactInfo {
  EmailAddresses?: string[];
  HomePhoneNumbers?: string[];
  MobilePhoneNumbers?: string[];
  WorkPhoneNumbers?: string[];
  OtherPhoneNumbers?: string[];
}

export interface Preferences {
  CreatedAt?: any;
  Language?: any;
  ModifiedAt?: any;
}
export type PersonNumberType = {
  label: string;
  number: string;
};

export type Note = {
  id: string;
  created_at: string;
  created_by_id: string;
  created_by: {
    employee_id: string;
    PersonID: string;
    first_name: string;
    last_name: string;
    username: string;
    user_name: string;
    photo: string;
    is_active: boolean | null;
  };
  household_id: string;
  householdid: string;
  text: string;
};

export type NewNote = {
  author: string;
  date: string;
  household_id: string;
  text: string;
};
