import { useEffect, useMemo, useRef } from 'react';
import { Img } from '@react-email/components';
import { LogoSection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/composer.pb';
import { PercentCrop } from 'react-image-crop';
import { useComposerSection } from '../../hooks';
import { ComposerSectionOutputProps } from '../../types';
import { getFrameStyle, getImageCropStyle } from '../../utils';

export const LogoOutput = ({ onRenderReady }: ComposerSectionOutputProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { props, sectionHTML } = useComposerSection<LogoSection>();

  const logo = props.image;
  const crop = props.image?.crop;

  // to get the image element inside the Image section.
  const imageElement = useMemo(() => sectionHTML?.querySelector('img[data-type="src-image"]'), [sectionHTML]);

  const logoStyles = getImageCropStyle(imageElement?.clientWidth, imageElement?.clientHeight, crop as PercentCrop);

  useEffect(() => {
    // if (logo?.url) {
    //   imgRef.current?.setAttribute('src', logo?.url);
    // // }
    onRenderReady?.();
  }, [logo]);

  return logo ? (
    <div style={{ ...getFrameStyle(props.frame), display: 'flex' }}>
      <img
        ref={imgRef}
        src={logo?.url}
        alt={logo?.name}
        style={{
          height: props.height,
          display: 'none',
          visibility: 'hidden',
          position: 'absolute',
        }}
      />
      <Img
        style={{
          width: imageElement?.clientWidth,
          height: imageElement?.clientHeight,
          maxWidth: '100%',
          ...logoStyles,
        }}
        src={logo?.url}
      />
    </div>
  ) : null;
};
