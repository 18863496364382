import { useMemo } from 'react';
import { LocationFeatureResponse } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { SubscriptionsConstant, SubscriptionsQueries, SubscriptionsTypes } from '@frontend/api-subscription';
import { GbbSalesforceBundleInfo, UseIsGbbSalesforceBundleResponseType } from '../types';

type UseIsGbbSalesforceBundleParamsType = {
  enabled: boolean;
  customizationFlags: Record<string, LocationFeatureResponse>;
};

// GbbSalesforceBundle is the `Good better best` bundle names in the salesforce
// e.g: Weave Core Elite, Weave Plus Pro
export const useIsGbbSalesforceBundle = ({
  enabled,
  customizationFlags,
}: UseIsGbbSalesforceBundleParamsType): UseIsGbbSalesforceBundleResponseType => {
  const {
    data: salesforceBundleInfo,
    isLoading,
    isError,
  } = SubscriptionsQueries.useGetSalesforceBundleDetailsByLocation({
    enabled,
  });

  const officeIsIntegrated =
    customizationFlags?.integrated?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  const isGbbBundle = checkIsGbbBundle(salesforceBundleInfo?.sfProductBundleEnum);
  const bundleDisplayName = getSfBundleDisplayName({ salesforceBundleInfo, officeIsIntegrated });

  return {
    isGbbBundle,
    isLoading,
    salesforceProductBundleEnum:
      salesforceBundleInfo?.sfProductBundleEnum ??
      SalesforceAccountProductBundle.UNKNOWN_SALESFORCE_ACCOUNT_PRODUCT_BUNDLE,
    bundleDisplayName,
    isIntegrated: (salesforceBundleInfo?.isIntegrated || officeIsIntegrated) ?? false,
    isSoftwareOnlyBundle: salesforceBundleInfo?.isSoftOnlyBundle ?? false,
    isError,
  };
};

export const useMultiIsGbbSalesforceBundle = ({ locationIds }: { locationIds: string[] }) => {
  const {
    data: locationWiseCustomizationFlags,
    isLoading: isLoadingCustomizationFlags,
    isError: isCustomizationFlagError,
  } = CustomizationFlagQueries.useMultiCustomizationFlags(locationIds);

  const {
    data: salesforceBundleList,
    isLoading: isLoadingSalesforceBundles,
    isError: isSalesforceBundleError,
  } = SubscriptionsQueries.useGetSalesforceBundleDetailsMultiLocation({
    locationIds,
  });

  const data = useMemo<GbbSalesforceBundleInfo[]>(() => {
    return (salesforceBundleList?.response || []).map(({ bundleDetails, locationId }) => {
      const features = (locationId && locationWiseCustomizationFlags?.locationsFeatures?.[locationId]?.features) || [];

      const officeIsIntegrated = !!(
        features?.find(({ featureEnum }) => featureEnum === SubscriptionsTypes.Feature.INTEGRATED)?.state ===
        CustomizationFlagTypes.CustomizationFlagState.ACTIVE
      );

      const isGbbBundle = checkIsGbbBundle(bundleDetails?.sfProductBundleEnum);
      const bundleDisplayName = getSfBundleDisplayName({ salesforceBundleInfo: bundleDetails, officeIsIntegrated });

      return {
        isGbbBundle,
        isIntegrated: !!(bundleDetails?.isIntegrated || officeIsIntegrated),
        isSoftwareOnlyBundle: !!bundleDetails?.isSoftOnlyBundle,
        bundleDisplayName,
        salesforceProductBundleEnum:
          bundleDetails?.sfProductBundleEnum ??
          SalesforceAccountProductBundle.UNKNOWN_SALESFORCE_ACCOUNT_PRODUCT_BUNDLE,
        locationId,
      };
    });
  }, [locationWiseCustomizationFlags, salesforceBundleList]);

  return {
    data,
    isLoading: isLoadingCustomizationFlags || isLoadingSalesforceBundles,
    isError: isCustomizationFlagError || isSalesforceBundleError,
  };
};

const checkIsGbbBundle = (sfProductBundleEnum?: SalesforceAccountProductBundle) => {
  return !!(
    sfProductBundleEnum && SubscriptionsConstant.SalesforceGoodBetterBestProductBundles.includes(sfProductBundleEnum)
  );
};

const getSfBundleDisplayName = ({
  salesforceBundleInfo,
  officeIsIntegrated = false,
}: {
  salesforceBundleInfo?: SubscriptionsTypes.GetSalesforceBundleNameResponse;
  officeIsIntegrated?: boolean;
}) => {
  const isGbbBundle = checkIsGbbBundle(salesforceBundleInfo?.sfProductBundleEnum);

  if (isGbbBundle) {
    return salesforceBundleInfo?.bundleDisplayName ?? '';
  } else if (salesforceBundleInfo?.sfProductBundleEnum === SalesforceAccountProductBundle.PRODUCT_BUNDLE_PATTERSON) {
    return SubscriptionsTypes.SalesforceBundleNameEnums.PRODUCT_BUNDLE_PATTERSON;
  } else if (salesforceBundleInfo?.isIntegrated || officeIsIntegrated) {
    return SubscriptionsTypes.SalesforceBundleNameEnums.WEAVE_PLUS;
  } else {
    return SubscriptionsTypes.SalesforceBundleNameEnums.WEAVE_CORE;
  }
};
