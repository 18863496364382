import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { GetUsersInLocationsResponse, StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes, CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { LiteApi } from '@frontend/api-lite';
import { UsersQueries } from '@frontend/api-users';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useScopedAppFlagStore, useScopedInfiniteQuery, useScopedQuery } from '@frontend/scope';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  DocumentErrorIcon,
  DocumentFilledIcon,
  IconButton,
  ListsIcon,
  SortingValue,
  PhoneOutgoingIcon,
  SpinningLoader,
  Table,
  TableColumnConfig,
  TableLoadingSkeleton,
  Text,
  DocumentWarningIcon,
  DocumentUndeterminedIcon,
  useAlert,
  TextLink,
  emptyStateGraphics,
  useModalControl,
  Tray,
} from '@frontend/design-system';
import { CallIntelFilters, CallIntelInfoTips, CallTakeawayPanel, OfficeUserName } from '..';
import { Accept, ColumnHeaderInfoTip, LocationChip, Thumb, UserCard } from '../..';
import { featureFlags } from '../../../feature-flags';
import { queryKeys } from '../../../query-keys';
import { TrackingOptions } from '../../../tracking';
import { trackingIds } from '../../../tracking-ids';
import { callIntelligenceUtils } from '../../../utils';
import CallIntelChip from '../call-intel-chip';
import { CallIntelSearch } from '../call-intel-search-field';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelLocations, useCallIntelShallowStore, useCallTakeawayPanelShallowStore } from '../hooks';
import { ServiceQualityBadge } from '../service-quality-badge';

// Constants
const MIN_SEARCH_LENGTH = 3;

// Helper functions
const commonCellRenderer = (status?: CallIntelTypes.CallStatusEnum) => {
  if (callIntelligenceUtils.isCallInProcessing(status)) {
    return <TableLoadingSkeleton />;
  }

  if (callIntelligenceUtils.isFailedCall(status) || callIntelligenceUtils.isSkippedCall(status)) {
    return ' '; // Need to render nothing, but need white space just for a check
  }

  return null;
};

const cellStyler = (status?: CallIntelTypes.CallStatusEnum) => {
  if (callIntelligenceUtils.isFailedCall(status)) {
    return css`
      background: ${theme.colors.critical5};
    `;
  }

  if (callIntelligenceUtils.isSkippedCall(status)) {
    return css`
      background: ${theme.colors.neutral10};
    `;
  }

  return;
};

const getTrayIcon = (call?: CallIntelTypes.Call, isDemoAccount?: boolean, isEdited?: boolean) => {
  if (!call?.status) {
    return;
  }

  const { status } = call;

  if (isEdited) {
    return <Icon name='document-sent' color='success' size={24} />;
  }

  if (callIntelligenceUtils.isCallInProcessing(status)) {
    return <SpinningLoader size='small' style={{ marginLeft: theme.spacing(-0.5) }} />;
  }

  if (callIntelligenceUtils.isFailedCall(status)) {
    return <DocumentErrorIcon color='error' />;
  }

  if (callIntelligenceUtils.isSkippedCall(status)) {
    return <DocumentUndeterminedIcon color='subdued' />;
  }

  if (!isDemoAccount && callIntelligenceUtils.isPoorAudio(call)) {
    return <DocumentWarningIcon color='warn' />;
  }

  return (
    <span>
      <DocumentFilledIcon color='primary' />
    </span>
  );
};

const prioritizeColumns = (
  columns: TableColumnConfig<CallIntelTypes.Call>[],
  priorityColumns: string[] = []
): TableColumnConfig<CallIntelTypes.Call>[] => {
  // Sort the columns based on the index in the priorityColumns array
  const sortedColumns = columns.sort((a, b) => {
    const aIndex = priorityColumns.indexOf(a.id);
    const bIndex = priorityColumns.indexOf(b.id);

    // If both columns are in the priorityColumns array, sort by their index in the array
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // If only one column is in the priorityColumns array, that one should come first
    if (aIndex !== -1) return -1;
    if (bIndex !== -1) return 1;

    // If neither column is in the priorityColumns array, maintain their original order
    return 0;
  });

  return sortedColumns;
};

// Function to generate empty state configurations
const getEmptyStateConfig = ({ drillDownFilters, searchQuery }: GetEmptyStateConfigParams) => {
  if (searchQuery.value.length) {
    return {
      type: 'summary' as EmptyStateIconType,
      header: '',
      description: () => (
        <div css={styles.emptyStateWrapper}>
          <Text css={styles.emptyStateText}>
            {i18next.t('No results that match your search.', { ns: 'analytics' })}
          </Text>
          <Text css={styles.emptyStateText}>
            {i18next.t('Try adjusting your filters or searching for a different name or number.', { ns: 'analytics' })}
          </Text>
        </div>
      ),
    };
  }

  if (drillDownFilters?.key === 'service_quality_flags') {
    return {
      type: 'no_service_quality' as EmptyStateIconType,
      header: '',
      description: () => (
        <div css={styles.emptyStateWrapper}>
          <Text css={styles.emptyStateText}>{i18next.t('Nothing to review.', { ns: 'analytics' })}</Text>
          <Text css={styles.emptyStateText}>
            {i18next.t('Choose a different time period or adjust your filters.', { ns: 'analytics' })}
          </Text>
        </div>
      ),
    };
  }

  // Default empty state when no specific condition is met
  return {
    type: 'sync_your_phone' as EmptyStateIconType,
  };
};

type GetEmptyStateConfigParams = {
  drillDownFilters?: CallIntelTypes.DrillDownOptions;
  searchQuery: CallIntelTypes.SearchState;
};

type EmptyStateIconType = keyof typeof emptyStateGraphics;

type CallRecordsTableProps = {
  defaultAccordion?: CallIntelTypes.CallTakeawayAccordion;
  drillDownFilters?: CallIntelTypes.DrillDownOptions;
  hideFilters?: (keyof CallIntelTypes.Filters)[];
  isDemoAccount?: boolean;
  marginTop?: number;
  priorityColumns?: string[];
  showOnlyUnscheduled?: boolean;
  trackingOptions?: TrackingOptions;
  trayNotificationMessage?: React.ReactNode;
};

export const CallRecordsTable = ({
  defaultAccordion = 'call-summary',
  drillDownFilters,
  hideFilters,
  isDemoAccount,
  marginTop = 40,
  priorityColumns = [],
  showOnlyUnscheduled,
  trackingOptions,
  trayNotificationMessage,
}: CallRecordsTableProps) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isServiceQualityEnabled = getFeatureFlagValue(featureFlags.enableCallIntelServiceQuality);

  const { chipVariants, dataLabels, filters, setFilters } = useCallIntelShallowStore(
    'chipVariants',
    'dataLabels',
    'filters',
    'setFilters'
  );

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<CallIntelTypes.SearchState>({ query: '', value: '' });

  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
  });
  const {
    allCalls,
    editedCallIds,
    selectedCall,
    setAllCalls,
    setDefaultAccordion,
    setIsDemoAccount,
    setSelectedCall,
    setSelectedCallMetaData,
  } = useCallTakeawayPanelShallowStore(
    'allCalls',
    'editedCallIds',
    'selectedCall',
    'setAllCalls',
    'setDefaultAccordion',
    'setIsDemoAccount',
    'setSelectedCall',
    'setSelectedCallMetaData'
  );
  const { personId, setPersonId } = useContactPanelStore();
  const { panelType } = useSlidePanelShallowStore('panelType');
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const {
    modalProps: demoCallTakeawayProps,
    openModal: openDemoCallTakeaway,
    closeModal: closeDemoCallTakeaway,
  } = useModalControl();

  const personIdRef = useRef<string>(personId);
  const selectedCallRef = useRef<CallIntelTypes.Call | null>(selectedCall);

  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [sortType, setSortType] = useState<CallIntelTypes.SortTypeEnum>(CallIntelTypes.SortTypeEnum.SORT_DESC);

  const demoCallRecords = useMemo(() => {
    const { key, value } = drillDownFilters || {};

    if (key === 'appointment_types') {
      return CallIntelMockData.callRecordings({
        count: 10,
        appointmentType: value?.[0] as CallIntelTypes.AppointmentTypeEnum,
        showOnlyUnscheduled,
        vertical: lastUsedVertical,
      });
    } else if (key === 'categories') {
      return CallIntelMockData.callRecordings({
        count: 10,
        category: value?.[0] as CallIntelTypes.CategoryEnum,
        showOnlyUnscheduled,
        vertical: lastUsedVertical,
      });
    } else if (key === 'sentiments') {
      return CallIntelMockData.callRecordings({
        count: 10,
        sentiment: value?.[0] as CallIntelTypes.SentimentEnum,
        showOnlyUnscheduled,
        vertical: lastUsedVertical,
      });
    } else if (key === 'service_quality_flags') {
      return CallIntelMockData.callRecordings({
        count: 10,
        serviceQualityFlags: value as CallIntelTypes.ServiceQualityFlagEnum[],
        vertical: lastUsedVertical,
      });
    } else {
      return CallIntelMockData.callRecordings({
        count: 10,
        showOnlyUnscheduled,
        vertical: lastUsedVertical,
      });
    }
  }, [isDemoAccount, lastUsedVertical]);

  const emptyStateConfig = useMemo(
    () => getEmptyStateConfig({ drillDownFilters, searchQuery }),
    [searchQuery, drillDownFilters]
  );

  const filteredDemoCallRecords = useMemo(() => {
    return CallIntelMockData.getFilteredCallRecordings(demoCallRecords, filters);
  }, [demoCallRecords, filters]);

  const { data: officeNumbers } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `office-numbers-${isDemoAccount}-${isMounted}-${JSON.stringify(filters.locations)}`
    ),
    queryFn: () =>
      isDemoAccount || !isMounted || !filters.locations?.length
        ? null
        : LiteApi.getPhoneNumbers(filters.locations.join('&location_ids=')),
    onError: (error) => {
      console.error(`Failed to get the phone numbers, ${error}`);
    },
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => {
      if (isDemoAccount) {
        return CallIntelMockData.phoneNumbers;
      }

      // Only return phone numbers for voice and sms and non e911 usage type

      return (
        data?.phoneNumbers
          .filter(
            ({ number }) =>
              (number.voiceProvider || number.smsProvider) && number.phoneNumber.usageType !== UsageType.USAGE_TYPE_E911
          )
          .map(({ number }) => number.phoneNumber.phoneNumber.nationalNumber) || []
      );
    },
    staleTime: 1000 * 60 * 5,
  });

  const { data: officeUsers } = UsersQueries.useGetUsersInLocations(
    !filters.locations || filters.locations?.[0]?.includes('demo') ? [] : filters.locations
  );

  const activeOfficeUsers = useMemo(() => {
    const users = isDemoAccount ? CallIntelMockData.officeUsers : (officeUsers as GetUsersInLocationsResponse)?.users;

    return (
      users
        ?.filter(({ status }) => !status || status === StatusType.ACTIVE)
        .reduce((acc, { firstName = '', lastName = '', userId = '' }) => {
          if (userId) {
            acc[userId] = `${firstName} ${lastName}`.trim();
          }
          return acc;
        }, {} as AnalyticsCommonTypes.StringRecord) || {}
    );
  }, [officeUsers, isDemoAccount]);

  const queryString = useMemo(
    () =>
      `call-records-${JSON.stringify(filters)}-${searchQuery.query}-${JSON.stringify(
        drillDownFilters
      )}-${showOnlyUnscheduled}-${pageConfig.pageSize}-${sortType}-isDemoAccount-${isDemoAccount}`,
    [filters, drillDownFilters, pageConfig.pageSize, searchQuery.query, sortType, isDemoAccount]
  );

  const { data, fetchNextPage, hasPreviousPage, isFetching } = useScopedInfiniteQuery({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: ({ pageParam = {} }) =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(null)
        : CallIntelligenceApi.getCalls({
            drillDownOptions: drillDownFilters,
            filters,
            pageConfig: { ...pageConfig, ...pageParam },
            showOnlyUnscheduled,
            searchQuery: searchQuery.query,
            sortType,
          }),
    getNextPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 0) + 1, // Defaults to page 1
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    getPreviousPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 2) - 1, // Defaults to page 1
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    onError: () => {
      if (filters.hideNonAnalyzedCalls) {
        alert.error(t('Failed to hide calls with no analysis. Please try again.'));
        setFilters({ hideNonAnalyzedCalls: false });
      } else {
        alert.error(t('Failed to fetch call records'));
      }
    },
    enabled: isMounted,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? filteredDemoCallRecords : data),
  });

  const { data: callsStats } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(
      `calls-stats-${JSON.stringify(
        Object.keys(filters)
          .filter((key) => key !== 'hideNonAnalyzedCalls')
          .map((key) => filters[key as keyof CallIntelTypes.Filters])
      )}-${searchQuery.query}-${isMounted}`
    ),
    queryFn: () => (isDemoAccount || !isMounted ? null : CallIntelligenceApi.getCallsStats(filters, searchQuery.query)),
    onError: (error) => {
      console.error('Failed to fetch calls stats', error);
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const handleSorting = (sortValues: SortingValue<string>[]) => {
    const { value } = sortValues[0] || {};
    setSortType(value === 'asc' ? CallIntelTypes.SortTypeEnum.SORT_ASC : CallIntelTypes.SortTypeEnum.SORT_DESC);
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  };

  const handlePaginationAfterApplyingFilters = useCallback(() => {
    setPageConfig((pageConfig) => ({ ...pageConfig, pageNumber: 1 }));
  }, []);

  const handleSearchOnChange = useCallback(
    (value: string, debounced?: boolean) => {
      if (!debounced) {
        if (value == '') {
          setSearchQuery({ query: '', value: '' });
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      } else {
        if (value.length >= MIN_SEARCH_LENGTH) {
          setSearchQuery({ value, query: value });
          handlePaginationAfterApplyingFilters();
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      }
    },
    [handlePaginationAfterApplyingFilters]
  );

  const handleSearchReset = useCallback(() => {
    setSearchQuery({ query: '', value: '' });
  }, []);

  const callConfig: TableColumnConfig<CallIntelTypes.Call>[] = [
    {
      accessor: (call) => call,
      cellRenderer: (value: CallIntelTypes.Call) => {
        // If the contact panel already open via call takeaway tray, do nothing on click
        const isContactPanelOpen = selectedCall?.id === value.id && personId === value.person?.id;

        return (
          <UserCard
            firstName={value?.person?.firstName}
            lastName={value?.person?.lastName}
            key={value?.person?.id}
            openProfileOnClick={!isDemoAccount && !isContactPanelOpen}
            phoneNumber={callIntelligenceUtils.getPhoneNumber(value.phoneNumber)}
            rightElement={
              value.direction === CallIntelTypes.CallDirectionEnum.DIRECTION_OUTBOUND ? (
                <span style={{ color: theme.colors.neutral70 }}>
                  <PhoneOutgoingIcon size={16} />
                </span>
              ) : null
            }
            showOnlyName
            userId={value?.person?.id || ''}
            locationId={value?.locationId}
          />
        );
      },
      disableColumnFilter: true,
      disableSortBy: true,
      Header: t('Contact Name'),
      id: 'person',
      sticky: 'left',
      width: 280,
    },
    {
      accessor: ({ locationId }) => locationId,
      cellRenderer: (value: string) => {
        return value ? <LocationChip locationName={locations[value] || value} maxWidth={180} /> : '-';
      },
      disableSortBy: true,
      Header: t('Location'),
      id: 'locationName',
      omit: !isMultiLocation,
      width: 220,
    },
    {
      accessor: ({ startTime }) => dayjs(startTime).format('MMM D, YYYY, h:mm A'),
      Header: t('Time'),
      id: 'time',
      startingSortBy: 'desc',
      width: 220,
    },
    {
      accessor: ({ officeUser, startTime }) => ({ officeUser, startTime }),
      cellRenderer: ({ officeUser, startTime }) => (
        <OfficeUserName
          {...officeUser}
          isDataUnavailable={callIntelligenceUtils.isOfficeUserDataUnavailable(startTime)}
          isInactive={callIntelligenceUtils.isUserInactive(officeUser)}
        />
      ),
      disableSortBy: true,
      Header: t('Office User'),
      headerLabel: t('Office User'),
      id: 'officeUserId',
      width: 200,
    },
    {
      accessor: ({ contactType, isFieldEdited, status }) => ({ contactType, isFieldEdited, status }),
      cellRenderer: ({ contactType, isFieldEdited, status }) => {
        const renderer = commonCellRenderer(status);

        return (
          renderer || (
            <div css={styles.chips}>
              <CallIntelChip
                chipVariants={chipVariants}
                dataKey={contactType}
                label={dataLabels.contactType?.[contactType] ?? ''}
              />
              {isFieldEdited?.isContactTypeEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          )
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip columnTitle={t('Contact Type')} infoTip={<CallIntelInfoTips noCta tip='contactType' />} />
      ),
      headerLabel: t('Contact Type'),
      id: 'contactType',
      width: 240,
    },
    {
      accessor: (rowData) => rowData,
      cellRenderer: (rowData) => {
        const { status, taskCount } = rowData;

        const renderer = commonCellRenderer(status);

        return (
          renderer || (
            <>
              {taskCount > 0 ? (
                <TextLink
                  onClick={() => {
                    if (rowData.id !== selectedCall?.id || panelType !== 'callTakeaway') {
                      setPersonId('', false);
                      setSelectedCall(rowData);
                      setSelectedCallMetaData(null);
                      setDefaultAccordion('call-tasks');
                    }
                    setDefaultAccordion('call-tasks');
                  }}
                  trackingId={trackingIds.callIntel.taskLinkAllCallRecordings}
                >
                  {t(`{{ taskCount }} ${taskCount == 1 ? 'Task' : 'Tasks'}`, { taskCount })}
                </TextLink>
              ) : (
                <Text>-</Text>
              )}
            </>
          )
        );
      },
      disableSortBy: true,
      Header: <ColumnHeaderInfoTip columnTitle={t('Tasks')} infoTip={<CallIntelInfoTips noCta tip='tasks' />} />,
      headerLabel: t('Tasks'),
      id: 'tasks',
      width: 158,
    },
    {
      accessor: ({ taskTypes, status }) => ({ taskTypes, status }),
      cellRenderer: ({ taskTypes, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (taskTypes?.length ? (
            <div css={styles.chips}>
              {taskTypes?.map((taskType: string) =>
                taskType.trim() ? (
                  <CallIntelChip
                    as='category'
                    chipVariants={chipVariants}
                    dataKey={taskType}
                    label={dataLabels.taskTypes?.[taskType] || taskType}
                    key={taskType}
                  />
                ) : null
              )}
            </div>
          ) : (
            <Text>-</Text>
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Follow-up Reason')}
          infoTip={<CallIntelInfoTips noCta tip='followUpReason' />}
        />
      ),
      headerLabel: t('Follow-up Reason'),
      id: 'followUpReason',
      width: 281,
    },
    {
      accessor: ({ serviceQualityFlags, status, isFieldEdited }) => ({ serviceQualityFlags, status, isFieldEdited }),
      cellRenderer: ({ serviceQualityFlags, status, isFieldEdited }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (serviceQualityFlags?.length ? (
            <div css={styles.chips}>
              {serviceQualityFlags?.map((serviceQualityFlag: CallIntelTypes.ServiceQualityFlagEnum) => (
                <ServiceQualityBadge
                  key={serviceQualityFlag}
                  label={dataLabels.serviceQualityFlag?.[serviceQualityFlag] || serviceQualityFlag}
                  type={serviceQualityFlag}
                />
              ))}
              {!!isFieldEdited?.isServiceQualityFlagEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          ) : (
            <Text>-</Text>
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Service Quality')}
          infoTip={<CallIntelInfoTips noCta tip='serviceQuality' />}
        />
      ),
      headerLabel: t('Service Quality'),
      id: 'serviceQualityFlags',
      omit: isServiceQualityEnabled ? false : true,
      width: 281,
    },
    {
      accessor: ({ isFieldEdited, schedulingOpportunity, status }) => ({
        isFieldEdited,
        schedulingOpportunity,
        status,
      }),
      cellRenderer: ({ isFieldEdited, schedulingOpportunity, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer || (
            <div css={styles.fieldWrapper}>
              <Thumb up={schedulingOpportunity} />
              {isFieldEdited?.isSchedulingOpportunityEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          )
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Scheduling Opportunity')}
          infoTip={<CallIntelInfoTips noCta tip='schedulingOpportunities' />}
        />
      ),
      headerLabel: t('Scheduling Opportunity'),
      omit: showOnlyUnscheduled,
      id: 'schedulingOpportunity',
      width: 220,
    },
    {
      accessor: ({ isFieldEdited, schedulingOpportunity, schedulingOutcome, status }) => ({
        isFieldEdited,
        schedulingOpportunity,
        schedulingOutcome,
        status,
      }),
      cellRenderer: ({ isFieldEdited, schedulingOpportunity, schedulingOutcome, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer || (
            <div css={styles.fieldWrapper}>
              {schedulingOpportunity ? <Accept accepted={schedulingOutcome} /> : t('N/A')}
              {isFieldEdited?.isSchedulingOutcomeEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          )
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Scheduling Outcome')}
          infoTip={<CallIntelInfoTips noCta tip='schedulingOutcome' />}
        />
      ),
      headerLabel: t('Scheduling Outcome'),
      id: 'schedulingOutcome',
      width: 200,
    },
    {
      accessor: ({ isFieldEdited, sentiment, status }) => ({ isFieldEdited, sentiment, status }),
      cellRenderer: ({ isFieldEdited, sentiment, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (callIntelligenceUtils.isFailedCall(status) ||
          callIntelligenceUtils.isSkippedCall(status) ? null : sentiment ? (
            <div css={styles.fieldWrapper}>
              <Text>{dataLabels.sentimentsWithEmoji?.[sentiment] || sentiment}</Text>
              {isFieldEdited?.isSentimentEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          ) : (
            <Text>-</Text>
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Customer Sentiment')}
          infoTip={<CallIntelInfoTips noCta tip='sentiments' />}
        />
      ),
      headerLabel: t('Customer Sentiment'),
      id: 'sentiment',
      width: 225,
    },
    {
      accessor: ({ categories, isFieldEdited, status }) => ({ categories, isFieldEdited, status }),
      cellRenderer: ({ categories, isFieldEdited, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (categories?.length ? (
            <div css={styles.chips}>
              {categories?.map((category: string) =>
                category.trim() ? (
                  <CallIntelChip
                    as='category'
                    chipVariants={chipVariants}
                    dataKey={category}
                    label={dataLabels.categories?.[category] || category}
                    key={category}
                  />
                ) : null
              )}
              {isFieldEdited?.isCategoryEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          ) : (
            <div css={styles.fieldWrapper}>
              <Text>-</Text>
              {isFieldEdited?.isCategoryEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Category')}
          infoTip={<CallIntelInfoTips customTipTitle={t('Category')} noCta tip='callsByCategory' />}
        />
      ),
      headerLabel: t('Category'),
      id: 'category',
      width: 270,
    },
    {
      accessor: ({ appointmentTypes, isFieldEdited, status }) => ({ appointmentTypes, isFieldEdited, status }),
      cellRenderer: ({ appointmentTypes, isFieldEdited, status }) => {
        const renderer = commonCellRenderer(status);
        return (
          renderer ||
          (appointmentTypes?.length ? (
            <div css={styles.chips}>
              {appointmentTypes?.map((appointmentType: string) =>
                appointmentType.trim() ? (
                  <CallIntelChip
                    as='category'
                    chipVariants={chipVariants}
                    dataKey={appointmentType}
                    label={dataLabels.appointmentTypes?.[appointmentType] || appointmentType}
                    key={appointmentType}
                  />
                ) : null
              )}
              {isFieldEdited?.isAppointmentTypeEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          ) : (
            <div css={styles.fieldWrapper}>
              <Text>-</Text>
              {isFieldEdited?.isAppointmentTypeEdited && (
                <Text size='small' color='subdued' css={styles.editedText}>
                  {t('Edited')}
                </Text>
              )}
            </div>
          ))
        );
      },
      disableSortBy: true,
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Appointment Type')}
          infoTip={<CallIntelInfoTips customTipTitle={t('Appointment Type')} noCta tip='callsByAppointmentType' />}
        />
      ),
      headerLabel: t('Appointment Type'),
      id: 'appointmentType',
      width: 270,
    },
    {
      accessor: ({ officeNumber, startTime }) =>
        callIntelligenceUtils.isOfficeUserDataUnavailable(startTime)
          ? t('Unavailable Data')
          : officeNumber?.nationalNumber
          ? formatPhoneNumber(officeNumber.nationalNumber)
          : '-',
      disableSortBy: true,
      Header: t('Office Number'),
      headerLabel: t('Office Number'),
      id: 'officeNumber',
      omit: (officeNumbers?.length || 0) === 1,
      width: 180,
    },
  ];

  const prioritizedColumns = useMemo(
    () => prioritizeColumns(callConfig, priorityColumns),
    [callConfig, priorityColumns]
  );

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageConfig.pageNumber]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
    setAllCalls(isDemoAccount ? data?.pages[0]?.calls || [] : data?.pages[pageConfig.pageNumber - 1]?.calls || []);
  }, [isDemoAccount, data, pageConfig.pageNumber]);

  useEffect(() => {
    // Keeping a ref to the current values as they are always containing the previous render's values
    // Which is not allowing to manage the panels as requested by design
    personIdRef.current = personId;
    selectedCallRef.current = selectedCall;
  }, [personId, selectedCall]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <>
      <Table
        colConfig={prioritizedColumns}
        customToolbarRender={() => (
          <CallIntelFilters
            activeTab='records'
            hideFilters={hideFilters}
            isDemoAccount={isDemoAccount}
            isLoadingData={isFetching}
            onApplyingFilters={handlePaginationAfterApplyingFilters}
            onlyChipFilters
          />
        )}
        data={allCalls}
        disableMultiSort
        emptyStateConfig={emptyStateConfig}
        fullHeight={!drillDownFilters}
        globalTrackingId='call-intel-records'
        manualFiltersRender={(modalProps, setShowNotificationBadge) => (
          <div css={styles.filtersWrapper}>
            <CallIntelSearch
              minSearchLength={MIN_SEARCH_LENGTH}
              onChange={handleSearchOnChange}
              value={searchQuery.value}
              trackingId={trackingIds.callIntel.allCallRecordingsSearch}
            />
            <CallIntelFilters
              activeTab='records'
              hideFilters={hideFilters}
              isDemoAccount={isDemoAccount}
              isLoadingData={isFetching}
              modalProps={modalProps}
              nonAnalyzedCallsCount={callsStats?.count}
              officeNumbers={(officeNumbers?.length || 0) > 1 ? officeNumbers : undefined}
              officeUsers={Object.keys(activeOfficeUsers || {}).length > 1 ? activeOfficeUsers : undefined}
              onApplyingFilters={handlePaginationAfterApplyingFilters}
              onSearchReset={handleSearchReset}
              onlyTrayFilters
              setShowNotificationBadge={setShowNotificationBadge}
              trackingOptions={trackingOptions}
              trayNotificationMessage={trayNotificationMessage}
              wrapperStyles={{ flexGrow: 1, margin: 0 }}
            />
          </div>
        )}
        manualFilters
        hasFilterColumns
        hasGlobalSearch={false}
        hasResizeColumns
        hasResponsiveColWidths
        isLoading={isFetching}
        isPaginated
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
            } else {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
            }
          },
          hasNext: isDemoAccount ? false : data?.pages[pageConfig.pageNumber - 1]?.hasNextPage,
          hasPrevious: hasPreviousPage,
          page: pageConfig.pageNumber,
          defaultRowsPerPage: pageConfig.pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
          },
          rowsPerPageOptions: [10, 25, 50, 100],
        }}
        manualSortBy
        onSortChange={handleSorting}
        rowActions={{
          actions: [
            {
              // Label and Icon both are required properties (as of now) even if renderCustom is provided
              // Hence, passing both of them to avoid typescript errors
              Icon: ListsIcon,
              label: t('Call Takeaways'),
              renderCustom: (_getItemProps, _index, _close, _isSingleAction, rowData) => {
                const isFailedCall = callIntelligenceUtils.isFailedCall(rowData.status);
                const isSkippedCall = callIntelligenceUtils.isSkippedCall(rowData.status);
                const isCallInProcessing = callIntelligenceUtils.isCallInProcessing(rowData.status);
                const isPoorAudio = isDemoAccount ? false : callIntelligenceUtils.isPoorAudio(rowData);
                const isEdited = editedCallIds.includes(rowData.id);

                return (
                  <IconButton
                    key={rowData.id}
                    label={
                      isEdited
                        ? t('Edits to this Call Takeaway have been saved.')
                        : isCallInProcessing
                        ? t('We are analyzing this call.')
                        : isFailedCall
                        ? t('We are unable to transcribe and analyze this call.')
                        : isSkippedCall
                        ? t(
                            "We didn't analyze this call because the short audio length resulted in inaccurate outputs. Please review the transcript."
                          )
                        : isPoorAudio
                        ? t(
                            'The transcript and details of this call may be inaccurate due to poor audio quality. Please review the recording.'
                          )
                        : t('Call Takeaways')
                    }
                    onClick={() => {
                      if (rowData.id !== selectedCall?.id || panelType !== 'callTakeaway') {
                        setPersonId('', false);
                        setSelectedCall(rowData);
                        setSelectedCallMetaData(null);
                        !!isDemoAccount && openDemoCallTakeaway();
                      }
                      setDefaultAccordion(defaultAccordion);
                    }}
                    showLabelOnHover
                    trackingId={trackingIds.callIntel.openCallTakeAwayTray}
                  >
                    {getTrayIcon(rowData, isDemoAccount, isEdited)}
                  </IconButton>
                );
              },
            },
          ],
        }}
        styleConfig={{
          columns: [
            {
              id: 'tasks',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'followUpReason',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'schedulingOpportunity',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'schedulingOutcome',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'sentiment',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'appointmentType',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'category',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
            {
              id: 'officeNumber',
              cellStyler: (_cellVal, _column, rowObject) => {
                const isDataUnavailable = callIntelligenceUtils.isOfficeUserDataUnavailable(
                  rowObject.original.startTime
                );

                return css`
                  color: ${isDataUnavailable ? theme.colors.neutral50 : 'inherit'};
                `;
              },
            },
            {
              id: 'serviceQualityFlags',
              cellStyler: (_cellVal, _column, rowObject) => cellStyler(rowObject.original.status),
            },
          ],
          rows: (rowData) => {
            return rowData.id === selectedCall?.id ? { background: theme.colors.neutral5 } : {};
          },
        }}
        tableInstanceId='uploaded-call-records'
        wrapperStyle={css`
          margin-top: ${marginTop}px;

          .table-toolbar {
            gap: ${theme.spacing(2)};
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;

            > div:last-of-type {
              padding-right: 0;
              padding-left: 0;
              margin-left: auto;
              align-items: flex-start;

              ${!drillDownFilters
                ? `
                @media screen and (max-width: 1500px) {
                margin-left: 0;
                flex: 1 1 100%; 
              }`
                : `
              @media screen and (max-width: 680px) {
              margin-left: 0;
              flex: 1 1 100%; 
            }`};
            }
          }
        `}
      />

      {!!isDemoAccount && (
        <Tray
          mountTarget='[data-settings-modal-content]'
          width='medium'
          css={{ padding: 0 }}
          {...demoCallTakeawayProps}
        >
          <CallTakeawayPanel onClose={closeDemoCallTakeaway} />
        </Tray>
      )}
    </>
  );
};

const styles = {
  chips: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,

  emptyStateWrapper: css`
    width: 400px;
    text-align: center;
  `,

  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,

  filtersWrapper: css`
    order: -1;
    margin-right: ${theme.spacing(2)};

    @media screen and (max-width: 1500px) {
      flex: 1;
    }
  `,

  rowLoader: css`
    width: ${theme.spacing(7.5)};
  `,
  editedText: css`
    font-style: italic;
  `,

  fieldWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
};
