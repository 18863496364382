import { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { FreeTrialSubscriptionQueries } from '@frontend/api-free-trial-subscription';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Chip, ContentLoader, formatDate, Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { BundleTrialActiveTrackingIds } from '../../constants/tracking-ids';
import { getUpgradeBundleDisplayName } from '../../self-upgrade.util';
import { BundleTrainingWidgets } from './constants';

const DATE_FORMAT = 'MMM DD, YYYY';
interface BundleTrialActiveModalProps extends ModalControlModalProps {
  showTrialDate?: boolean;
  title?: string;
  locationIds?: string[];
}
export const BundleTrialActiveModal: FC<BundleTrialActiveModalProps> = ({
  showTrialDate = true,
  title,
  locationIds = [],
  ...modalProps
}) => {
  const { t } = useTranslation('bundleUpgrade', { keyPrefix: 'bundle-trial-active-modal' });

  const { data, isLoading } = FreeTrialSubscriptionQueries.useGetBundleTrialStatus(
    {
      locationIds,
    },
    {
      enabled: locationIds.length > 0 && showTrialDate && modalProps.show,
    }
  );

  const titleText = useMemo(() => {
    return (
      title ||
      t('Your {{bundleName}} Trial Has Begun!', {
        bundleName: getUpgradeBundleDisplayName(
          data?.bundleTrialStatus?.[0]?.recommendedBundle ??
            SalesforceAccountProductBundle.UNKNOWN_SALESFORCE_ACCOUNT_PRODUCT_BUNDLE
        ),
      })
    );
  }, [title, data?.bundleTrialStatus]);

  const trialDurationText = useMemo(() => {
    if (!data?.maxTrialEndDate) return '';
    const trialStartDateString = data?.bundleTrialStatus
      ?.filter(({ isBundleTrialActive }) => isBundleTrialActive)
      .flatMap(({ featureDetails }) => featureDetails?.map(({ startDate }) => startDate))
      .find((date) => !!date);

    return `${formatDate(
      trialStartDateString ? new Date(trialStartDateString) : new Date(),
      DATE_FORMAT
    )} - ${formatDate(data.maxTrialEndDate, DATE_FORMAT)}`;
  }, [data]);

  const openWidgetLink = (link: string) => {
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <Modal {...modalProps} css={modalStyles}>
      <ContentLoader show={isLoading} />
      <Modal.Header onClose={modalProps.onClose} closeBtnTrackingId={BundleTrialActiveTrackingIds.dismissAction}>
        {titleText}
        {showTrialDate && !!trialDurationText && (
          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <Text size='medium' color='light' weight='regular'>
              {t('Your trial is active from')}
            </Text>
            <Chip variant='seaweed' css={{ maxWidth: 'max-content' }}>
              {trialDurationText}
            </Chip>
          </div>
        )}
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        <Text size='large' color='light'>
          {t('To manage your subscription go to Organization Settings > Subscriptions')}
        </Text>
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <Icon name='graduation-cap' />
          <Text size='large'>{t('Get the most out of your trial with these training resources')}</Text>
        </div>
        <div css={trainingWidgetsContainerStyles}>
          {BundleTrainingWidgets.map((widget) => (
            <div key={widget.title} css={trainingCardStyles}>
              <Text size='medium'>{widget.title}</Text>
              <Text color='light' size='small'>
                {widget.duration}
              </Text>
              <Button
                variant='tertiary'
                iconName={widget.actionIcon}
                onClick={() => openWidgetLink(widget.actionLink)}
                css={actionButtonStyles}
              >
                {widget.actionText}
              </Button>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Done'),
          onClick: modalProps.onClose,
          trackingId: BundleTrialActiveTrackingIds.doneAction,
        }}
      />
    </Modal>
  );
};

const modalStyles = css({
  minWidth: 'min(900px, 90vw)',
});

const modalBodyStyles = css({ padding: theme.spacing(4, 3) });

const actionButtonStyles = css({
  maxWidth: 'max-content',
  marginTop: 'auto',
  marginLeft: theme.spacing(-1),
});

const trainingWidgetsContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
});

const trainingCardStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.neutral30}`,
  width: 300,
  minHeight: 140,
});
