import { useEffect, useRef } from 'react';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { Avatar } from '@frontend/design-system';
import { ProfilePhoto } from './profile-photo';

type ContactProfilePhotoTypes = { personId: string; locationId?: string } & Omit<
  React.ComponentProps<typeof Avatar>,
  'src'
>;

export const ContactProfilePhoto = ({
  personId,
  children,
  firstName,
  lastName,
  onClick,
  disableClick,
  locationId,
  ...rest
}: ContactProfilePhotoTypes) => {
  const { show, setShow } = useSlidePanelShallowStore('setShow', 'show');

  const { lastFocused, setPersonId, setLastFocused } = useContactPanelShallowStore(
    'setPersonId',
    'lastFocused',
    'setLastFocused'
  );
  const medium = useMatchMedia({ maxWidth: breakpoints.medium.max });
  const photoRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const shouldFocus = personId && lastFocused === personId;
    if (!show && shouldFocus && !disableClick && medium) {
      photoRef?.current?.focus();
      setLastFocused('');
    }
  }, [show]);

  return (
    <ProfilePhoto
      personId={personId}
      firstName={firstName}
      lastName={lastName}
      ref={photoRef}
      disableClick={disableClick}
      {...rest}
      onClick={(e) => {
        e?.stopPropagation();
        if (personId) {
          setShow(true);
          setPersonId(personId, undefined, locationId);
        }
        onClick?.(e);
      }}
    >
      {children}
    </ProfilePhoto>
  );
};
