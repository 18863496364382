import { FilterType, FilterType_index } from '@weave/schema-gen-ts/dist/schemas/task-center/shared/v1/enums.pb';
import * as TaskCenter from '@weave/schema-gen-ts/dist/schemas/task-center/v1/task_service.pb';
import { useQuery } from 'react-query';
import { PersonAPI } from '@frontend/api-person';
import { bindHTTP } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';

const TaskCenterService = bindHTTP(TaskCenter.TaskService);

export const useGetTasks = () => {
  const { selectedLocationIds } = useAppScopeStore();

  return useQuery<TaskCenter.ListTasksResponse>(
    'tasks',
    () =>
      TaskCenterService.ListTasks({
        filter: { [FilterType_index[FilterType.FILTER_BY_LOCATION]]: { locationId: selectedLocationIds } },
      }),
    {
      initialData: { tasks: [] } as TaskCenter.ListTasksResponse,
      enabled: selectedLocationIds.length > 0,
      refetchOnMount: true,
      retry: true,
    }
  );
};

export const useGetPersons = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  return useQuery([selectedLocationIds[0], 'task-center'], () => PersonAPI.getPersons(100, 0, '', '', locationId), {
    enabled: !!locationId,
    refetchOnMount: true,
  });
};
