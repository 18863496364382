import { createProvider } from '@frontend/store';
import { contextFactory } from '@frontend/design-system';
import { PROVIDER_COLUMN_MIN_WIDTH } from './constants';
import { CalendarViewOffices, EventType, EventData } from './types';
import { getProviderColumnLaneCount } from './utils';

type CalendarViewStore = {
  backgroundView: boolean;
  setBackgroundView: (backgroundView: boolean) => void;
  hoveredTimeProgressPosition: number;
  setHoveredTimeProgressPosition: (hoveredTimeProgressPosition: number) => void;
  containerState: {
    isLeft: boolean;
    isRight: boolean;
    isScrollable: boolean;
    clientWidth: number;
  };
  eventCarouselData: {
    isOpen: boolean;
    locationName: string;
    events: EventData[];
  };
  setEventCarouselData: (eventCarouselData: { isOpen: boolean; events: EventData[]; locationName: string }) => void;
  floatingTooltipData: undefined | { name: string; value: string };
  setFloatingTooltipData: (floatingTooltipData: undefined | { name: string; value: string }) => void;
  setContainerState: (containerState: {
    isLeft: boolean;
    isRight: boolean;
    isScrollable: boolean;
    clientWidth: number;
  }) => void;

  visibleLaneCount: number;
  providerColumnWidth: number;
  setVisibleLaneCountBasedOnTotalWidth: (providerColumnWidth: number) => void;
};

export const { Provider: CalendarViewProvider, useStore: useCalendarView } = createProvider<CalendarViewStore>()(
  (set) => ({
    backgroundView: false,
    hoveredTimeProgressPosition: 0,
    eventCarouselData: {
      isOpen: false,
      events: [],
      locationName: '',
    },
    containerState: {
      isLeft: false,
      isRight: false,
      isScrollable: false,
      clientWidth: 0,
    },
    floatingTooltipData: undefined,
    setFloatingTooltipData: (floatingTooltipData) => {
      set(() => ({ floatingTooltipData }));
    },

    setEventCarouselData: async (eventCarouselData) => {
      set(() => ({ eventCarouselData }));
    },
    setContainerState: (containerState) => {
      set(() => ({ containerState }));
    },
    setHoveredTimeProgressPosition: (hoveredTimeProgressPosition) => {
      set(() => ({ hoveredTimeProgressPosition }));
    },
    setBackgroundView: (backgroundView) => {
      set(() => ({ backgroundView }));
    },
    providerColumnWidth: PROVIDER_COLUMN_MIN_WIDTH,
    visibleLaneCount: 2,
    setVisibleLaneCountBasedOnTotalWidth: (providerColumnWidth) => {
      const visibleLaneCount = getProviderColumnLaneCount(providerColumnWidth);
      set(() => ({ visibleLaneCount, providerColumnWidth }));
    },
  })
);

export type CalendarViewProps = {
  /**
   * An optional number that represents the starting hour of the calendar view.
   * If not provided, the default value is 0 (Which represents 12AM).
   */
  startHour?: number;
  /**
   * An optional number that represents the ending hour of the calendar view.
   * If not provided, the default value is 24 (Which represents 12AM next day).
   */
  endHour?: number;
  /**
   *  An optional function that is called when a new event is created.
   *  It takes in three parameters: ***startHour*** which is a string representing the
   *  starting hour of the event, ***endHour*** which is a string representing the
   *  ending hour of the event, and ***providerName*** which is a string representing
   *  the name of the provider associated with the event.
   */
  onEventCreate?: (
    startHour: string,
    endHour: string,
    providerName: string | undefined,
    providerId: string | undefined,
    isValid: boolean,
    locationId: string,
    calendarDateValue: string
  ) => void;
  /**
   * A required property that represents the data for the calendar view.
   */
  data: CalendarViewOffices;

  /**
   * An optional string that represents the id for that specific calendar view
   */
  id?: string;

  /**
   * A required property that represents Calendar event Card Component
   */
  EventCardComponent: React.FC<EventCardComponentProps>;

  NoProviderComponent?: React.FC;

  /**
   * A boolean that represents if the event creation should be allowed or not.
   */
  shouldAllowEventCreation?: boolean;
};

export const [CalendarViewProps, useCalendarViewProps] = contextFactory<CalendarViewProps>();

export type EventCardComponentProps = {
  id: string;
  title: string;
  eventType: EventType;
  locationName?: string;
  duration?: string;
  providerId?: string;
  providerName?: string;
  locationId?: string;
  isExpanded: boolean;
  startDate: string;
};
