import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';

export interface ReplyActionProps {
  onClick: () => void;
}

export const ReplyAction = ({ onClick }: ReplyActionProps) => {
  return (
    <Button variant='secondary' onClick={onClick} css={styles}>
      <Icon name='chat' />
    </Button>
  );
};

const styles = css`
  min-width: ${theme.spacing(4)};
  width: ${theme.spacing(4)};
  border: unset;
  padding: 0;
`;
