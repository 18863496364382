import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useScopedAppFlagStore } from '@frontend/scope';
import { featureFlags } from '../../../../feature-flags';
import { formatters } from '../../../../utils';
import { RoiSummary, TabCardProps } from '../../components';
import { REVENUE_MULTIPLIER } from '../../constants';
import { roiDefinitions } from '../../helpers';
import { MessagingOverview } from '../../types';
import { useBTRoiShallowStore } from '../hooks';

interface BTSummaryProps {
  data: MessagingOverview;
}

export const BTSummary = ({ data }: BTSummaryProps) => {
  const { t } = useTranslation('analytics');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);
  const { definitionsHelperDateRange } = useBTRoiShallowStore('definitionsHelperDateRange');

  const summaryCardsData: TabCardProps[] = useMemo(() => {
    return [
      {
        title: t('Messages Sent'),
        data: [
          {
            label: t('{{count}} Patients', { count: data?.leadsContacted?.totalPatients }),
            value: formatters.value.format(data?.leadsContacted?.messagesSent),
            infoHoverText: t('A total of {{messages}} messages were sent from {{patients}} patients', {
              messages: data?.leadsContacted?.messagesSent,
              patients: data?.leadsContacted?.totalPatients,
            }),
          },
        ],
      },
      {
        title: t('Appointments Scheduled'),
        data: [
          {
            label: t('{{count}} Patients', { count: data?.patientsScheduled?.totalPatients }),
            value: formatters.value.format(data?.patientsScheduled?.appointmentsScheduledCount),
            infoHoverText: t(
              'A total of {{appointments}} appointments were scheduled from {{patients}} unique patients',
              {
                appointments: data?.patientsScheduled?.appointmentsScheduledCount,
                patients: data?.patientsScheduled?.totalPatients,
              }
            ),
          },
        ],
      },
      {
        title: t('Production Value'),
        data: [
          {
            label: t('Appts Completed'),
            value: formatters.value.format(data?.revenueGenerated?.totalCompletedAppts),
            infoHoverText: t('Total completed appointments {{dateRangeHelperText}}', {
              dateRangeHelperText: definitionsHelperDateRange,
            }),
          },
          {
            label: t('Approx Value'),
            value: formatters.currency.shortenNumber(data?.revenueGenerated?.totalCompletedAppts * REVENUE_MULTIPLIER),
            infoHoverText: roiDefinitions.productionValueDefinition(definitionsHelperDateRange),
          },
        ],
      },
    ];
  }, [data]);

  return <RoiSummary isDemoAccount={isDemoAccount} summaryCardsData={summaryCardsData} />;
};
