import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { styles } from '@frontend/design-system';

export const PlusIcon = () => {
  return (
    <div css={[plusIconStyles, styles.flexCenter]}>
      <Icon color='white' name='plus-small' />
    </div>
  );
};

const plusIconStyles = css`
  width: 24px;
  height: 24px;
  background-color: ${theme.colors.primary50};
  border-radius: ${theme.borderRadius.full};
  margin-right: ${theme.spacing(1)};
  & > svg {
    margin-right: 0;
  }
`;
