import { FC, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { useAppScopeStore } from '@frontend/scope';
import { SpinningLoader, Text } from '@frontend/design-system';
import { CallIntelNoAccessIllustration } from '../assets/images';
import { useIsCallIntelEligibleAccount } from '../components/call-intelligence/hooks';

export const CallIntelACLCheck: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('analytics');
  const { isCallIntelEligibleAccount, isLoading } = useIsCallIntelEligibleAccount();
  const { selectedLocationIds } = useAppScopeStore();

  const canUserRead = selectedLocationIds.every((locationId) =>
    hasSchemaACL(locationId, Permission.CALL_INTELLIGENCE_READ)
  );

  if (isLoading) {
    return (
      <div css={loaderStyle}>
        <SpinningLoader />
      </div>
    );
  }

  if (isCallIntelEligibleAccount && canUserRead) {
    return children;
  }

  return (
    <Page>
      <Page.Header>
        <Page.Header.Heading>
          <Page.Header.Title title={t('Call Intelligence')} />
        </Page.Header.Heading>
      </Page.Header>

      <Page.Body>
        <div css={aclMessageSectionStyle}>
          <CallIntelNoAccessIllustration />
          <Text color='subdued' weight='regular' textAlign='center'>
            {t('You do not have permission to view Call Intelligence.')}
          </Text>
          <Text color='subdued' weight='regular' textAlign='center'>
            {t('Please contact your admin to upgrade your access.')}
          </Text>
        </div>
      </Page.Body>
    </Page>
  );
};

const loaderStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const aclMessageSectionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
