import { useEffect, useState } from 'react';
import { EmailTemplate } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { EmailTemplateQueries } from '@frontend/api-email-template';
import { EmailTemplates } from '@frontend/email-templates';
import { useAppScopeStore } from '@frontend/scope';
import { useBulkEmailEditorShallowStore } from '../../hooks';
import { CONTAINER_HEIGHT } from './constants';
import { CustomTemplateCard } from './custom-template-card';
import { EmptyCustomTemplateState } from './empty-custom-template-state';

export const CustomListTemplates = () => {
  const { selectedOrgId: orgId, selectedLocationIds: appSelectedLocationIds } = useAppScopeStore();
  const { locationIds } = useBulkEmailEditorShallowStore('locationIds');
  const selectedLocationIds = locationIds.length ? locationIds : appSelectedLocationIds;

  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const listRequest = {
    orgId,
    locationIds: selectedLocationIds,
    pageToken,
  };

  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } =
    EmailTemplateQueries.useListEmailTemplates(listRequest);

  useEffect(() => {
    const newData = data?.pages?.[0]?.templates ?? [];
    const newTemplates = [...newData, ...templates];
    const filteredTemplates = newTemplates.filter(
      (template, index, arr) => arr.findIndex((t) => t.id === template.id) === index
    );
    setTemplates(filteredTemplates);
    return () => {
      setTemplates([]);
    };
  }, [pageToken, isFetched]);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setPageToken(data?.pages?.[0]?.nextPageToken);
      fetchNextPage();
    }
  };

  return (
    <EmailTemplates<EmailTemplate>
      DisplayedCard={(_index, template) => (
        <CustomTemplateCard
          key={template.id}
          {...template}
          selectedLocationIds={selectedLocationIds}
          setTemplates={setTemplates}
          templates={templates}
        />
      )}
      EmptyCardState={<EmptyCustomTemplateState />}
      getNextPage={getNextPage}
      hasNextPage={hasNextPage}
      height={CONTAINER_HEIGHT}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      templates={templates}
    />
  );
};
