import { FC } from 'react';
import { TaskCenterTypes } from '@frontend/api-task-center';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import {
  PopoverMenu,
  PopoverMenuItem,
  StatusSwitcherVariants,
  statusSwitcherVariantsColor,
  usePopoverMenu,
} from '@frontend/design-system';
import { TaskFormRow } from '../task-form-row/task-form-row';

type TaskOption = {
  Icon: React.ComponentType;
  label: string;
  trackingId: string;
  variant: StatusSwitcherVariants;
};

// @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
type PriorityTaskOption = TaskOption & { value: TaskCenterTypes.Task_Priority };
type StatusTaskOption = TaskOption & { value: TaskCenterTypes.TaskStatus };

type SwitcherProps = {
  label: string;
  icon: IconName;
  buttonIconName: IconName;
  placeholder: string;
  trackingId?: string;
};

type StatusSwitcherProps = SwitcherProps & {
  onChange: (value: TaskCenterTypes.TaskStatus | '') => void;
  value: TaskCenterTypes.TaskStatus;
};

type PrioritySwitcherProps = SwitcherProps & {
  // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
  onChange: (value: TaskCenterTypes.Task_Priority | '') => void;
  // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
  value: TaskCenterTypes.Task_Priority;
};

export const TaskStatusSwitcher: FC<StatusSwitcherProps> = ({
  label,
  icon,
  buttonIconName,
  placeholder,
  onChange,
  value,
  trackingId,
}) => {
  const { t } = useTranslation('task-center');

  // Options for task status
  const options: StatusTaskOption[] = [
    {
      Icon: (props: any) => <Icon name='pending-small' {...props} />,
      label: t('To-Do'),
      trackingId: '',
      value: TaskCenterTypes.TaskStatus.STATUS_TODO,
      variant: 'critical',
    },
    {
      Icon: (props) => <Icon name='in-progress-small' {...props} />,
      label: t('In Progress'),
      trackingId: '',
      value: TaskCenterTypes.TaskStatus.STATUS_IN_PROGRESS,
      variant: 'warning',
    },
    {
      Icon: (props) => <Icon name='check-small' {...props} />,
      label: t('Completed'),
      trackingId: '',
      value: TaskCenterTypes.TaskStatus.STATUS_COMPLETED,
      variant: 'success',
    },
  ];
  const selectedOption = options.find((option) => option.value === value);

  const { close, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom',
  });

  const handleOptionClick = (option: StatusTaskOption) => {
    onChange(option.value);
    close();
  };

  return (
    <TaskFormRow
      label={label}
      icon={icon}
      placeholder={placeholder}
      buttonIconName={buttonIconName}
      value={selectedOption?.label ?? ''}
      getTriggerProps={getTriggerProps}
      trackingId={trackingId}
      onRemoveClick={() => onChange('')}
      variant={selectedOption?.variant}
    >
      <PopoverMenu {...getMenuProps()}>
        {options.map((option) => (
          <PopoverMenuItem key={option.value} onClick={() => handleOptionClick(option)} trackingId={option.trackingId}>
            <option.Icon css={{ color: statusSwitcherVariantsColor[option.variant].color }} />
            {option.label}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </TaskFormRow>
  );
};

export const TaskPrioritySwitcher: FC<PrioritySwitcherProps> = ({
  label,
  buttonIconName,
  icon,
  placeholder,
  value,
  trackingId,
  onChange,
}) => {
  const { t } = useTranslation('task-center');

  // Options for task status
  const options: PriorityTaskOption[] = [
    {
      Icon: (props) => <Icon name='priority-high' size={16} {...props} />,
      label: t('High'),
      trackingId: '',
      // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
      value: TaskCenterTypes.Task_Priority.HIGH,
      variant: 'neutral',
    },
    {
      Icon: (props) => <Icon name='priority-medium' size={16} {...props} />,
      label: t('Medium'),
      trackingId: '',
      // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
      value: TaskCenterTypes.Task_Priority.MEDIUM,
      variant: 'neutral',
    },
    {
      Icon: (props) => <Icon name='priority-low' size={16} {...props} />,
      label: t('Low'),
      trackingId: '',
      // @ts-expect-error The schema is not updated on production yet. It will be updated after the schema releases
      value: TaskCenterTypes.Task_Priority.LOW,
      variant: 'neutral',
    },
  ];
  const selectedOption = value ? options.find((option) => option.value === value)?.label ?? '' : '';

  const { close, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom',
  });

  const handleOptionClick = (option: PriorityTaskOption) => {
    onChange(option.value);
    close();
  };

  return (
    <TaskFormRow
      label={label}
      icon={icon}
      placeholder={placeholder}
      buttonIconName={buttonIconName}
      value={selectedOption}
      getTriggerProps={getTriggerProps}
      trackingId={trackingId}
      onRemoveClick={() => onChange('')}
    >
      <PopoverMenu {...getMenuProps()}>
        {options.map((option) => (
          <PopoverMenuItem key={option.value} onClick={() => handleOptionClick(option)} trackingId={option.trackingId}>
            <option.Icon css={{ color: statusSwitcherVariantsColor[option.variant].color }} />
            {option.label}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </TaskFormRow>
  );
};
