import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { CreateNavigationSwitch } from './create/create-navigation-switch';

export const Create = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.create}>
      <CreateNavigationSwitch />
    </StepPerformanceWrapper>
  );
};
