import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useTooltip, Text } from '@frontend/design-system';

type ErrorStatusProps = {
  errorText: string;
};

export const ErrorStatus = ({ errorText }: ErrorStatusProps) => {
  const { t } = useTranslation('thread-body');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  return (
    <>
      <Tooltip {...tooltipProps}>{errorText}</Tooltip>
      <span css={{ display: 'flex', gap: theme.spacing(1) }} {...triggerProps}>
        <Text color='error' size='small'>
          {t('Not delivered')}
        </Text>
        <Icon name='warning-badge' size={16} color='error' />
      </span>
    </>
  );
};
