import { useRef, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { useInfiniteQuery } from 'react-query';
import apiConfig from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, TextField, useControlledField, SpinningLoader, ImageComponent } from '@frontend/design-system';
import GiphyAttribution from './assets/giphy-attribution.png';
import { GifPreview } from './gif-preview';
import { generateImageColumns } from './utils';

interface GiphyPickerProps {
  onSelect: (item: IGif) => void;
  width: number;
  height: number;
  columns?: number;
}

const gf = new GiphyFetch(apiConfig.GIPHY_API_KEY);

export const GiphyPicker = ({ width = 350, height = 450, columns = 3, onSelect }: GiphyPickerProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { t } = useTranslation();
  const gifTextField = useControlledField({
    type: 'text',
    placeholder: t('Search Gif'),
    value: searchTerm,
    onChange: (value) => {
      setSearchTerm(value);
      refetch();
    },
  });
  const containerRef = useRef<HTMLDivElement>(null);
  // removed the padding from the width for precise calculation
  const gifContainerWidth = width - 24;

  const {
    data: gifData,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch, // Add refetch for search resets
  } = useInfiniteQuery(
    ['Giphy-gifs', searchTerm],
    async ({ pageParam = 0 }) =>
      searchTerm.length > 0 ? gf.search(searchTerm, { offset: pageParam }) : gf.trending({ offset: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < 25) return undefined;
        return lastPage.pagination.offset + lastPage.pagination.count;
      },
      // Keep previous data when fetching more
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const gifColumns = useMemo(() => {
    const flattenedData = gifData?.pages ? gifData.pages.flatMap((page) => page.data) : [];
    return generateImageColumns(flattenedData, columns, gifContainerWidth);
  }, [gifData?.pages]);

  useEffect(() => {
    const handleScroll = async () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 10; // Adjust the 10 for tolerance.

        if (atBottom && hasNextPage) {
          fetchNextPage();
        }
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading, hasNextPage, fetchNextPage]);

  return (
    <div css={[styles.parent, css({ width, height })]}>
      <TextField label={t('Search Gif')} name={''} {...gifTextField} clearable />
      {isLoading && (
        <div css={styles.loaderContainer}>
          <SpinningLoader size='large' />
        </div>
      )}
      {!!gifData?.pages?.length && !isLoading && (
        <div
          ref={containerRef}
          css={[
            styles.container,
            css({
              width: `calc(${width}px - 16px)`,
              gridTemplateColumns: `repeat(${columns},1fr)`,
            }),
          ]}
        >
          {gifColumns.map((gifs, cId) => {
            return (
              <div
                key={`gif-picker-column-${cId}`}
                css={[styles.columns, css({ width: `calc(${gifContainerWidth / columns}px - ${theme.spacing(1)})` })]}
              >
                {gifs.map((gif, index) => (
                  <GifPreview key={`gif-${gif.url}-index-${index}`} index={index} gif={gif} onSelect={onSelect} />
                ))}
              </div>
            );
          })}
          {(isFetchingNextPage || true) && (
            <div css={[styles.nextPageLoader, css({ gridColumn: `1 / ${columns + 1}` })]}>
              <SpinningLoader size='xs' css={styles.loader} />
            </div>
          )}
        </div>
      )}
      {!!error && <Text>{t('Failed to load gifs')}</Text>}
      <ImageComponent src={GiphyAttribution} css={styles.attributionMark} />
    </div>
  );
};

const styles = {
  parent: { overflow: 'hidden', padding: theme.spacing(1) },
  container: css({
    display: 'grid',
    gap: theme.spacing(1),
    overflow: 'scroll',
    marginTop: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(9)})`, // it helps the div to scroll
  }),
  columns: css({
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    justifyItems: 'center',
  }),
  loader: css({
    alignSelf: 'center',
  }),
  giphyLogo: css({
    position: 'absolute',
    bottom: 0,
    paddingBottom: theme.spacing(1),
  }),
  loaderContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  }),
  nextPageLoader: css({
    width: '100%',
    textAlign: 'center',
  }),
  attributionMark: css({ width: '40%', marginTop: theme.spacing(1) }),
};
