import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

export type ROIFilters = {
  endDate?: string;
  locationIds?: string[];
  startDate?: string;
};

interface ROIStore {
  defaultFilters?: ROIFilters;
  definitionsHelperDateRange?: string;
  filterHintText?: string;
  filters?: ROIFilters;
  selectedRoiLocationIds?: string[];

  setDefaultFilters: (defaultFilters: Partial<ROIFilters>) => void;
  setDefinitionsHelperDateRange: (definitionsHelperDateRange?: string) => void;
  setFilterHintText: (filterHintText?: string) => void;
  setFilters: (filters: Partial<ROIFilters>) => void;
  setSelectedRoiLocationIds: (selectedLocationIds: string[]) => void;

  resetStore: () => void;
}

const filtersPlaceholder = {
  endDate: '',
  locationIds: [],
  startDate: '',
};

const initialValues = {
  defaultFilters: filtersPlaceholder,
  definitionsHelperDateRange: '',
  filterHintText: '',
  filters: filtersPlaceholder,
  selectedRoiLocationIds: [],
};

const useBTRoiStore = createStoreWithSubscribe<ROIStore>(
  (set, get) => ({
    ...initialValues,

    setDefaultFilters: (defaultFilters) => {
      set({ defaultFilters });
    },

    setDefinitionsHelperDateRange: (definitionsHelperDateRange) => {
      set({ definitionsHelperDateRange });
    },

    setFilterHintText: (filterHintText) => {
      set({ filterHintText });
    },

    setFilters: (filters) => {
      const filtersCopy = { ...get().filters, ...filters };
      set({ filters: filtersCopy });
    },

    setSelectedRoiLocationIds: (selectedRoiLocationIds) => {
      set({ selectedRoiLocationIds });
    },

    resetStore: () => {
      set({ ...initialValues });
    },
  }),
  {
    name: 'BTROIStore',
    trace: true,
  }
);

export const useBTRoiShallowStore = createShallowStore<ROIStore>(useBTRoiStore);
