import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  SkeletonLoaders,
  TextField,
  useForm,
  Text,
  Heading,
  ChecklistField,
  CheckboxField,
  Button,
  Modal,
  useModalControl,
  DropdownField,
  useAlert,
} from '@frontend/design-system';
import { AudioPicker } from '../../../audio-picker/audio-picker';
import { AudioItem } from '../../../audio-picker/types';
import { ButtonBar, AddStepPanelProps, HeaderBar } from './add-step-panel';

export const PhoneTreeStep = ({ tenantId, initialState, onClose, onProceedClick, onBackClick }: AddStepPanelProps) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const [selectedAudioFile, setSelectedAudioFile] = useState<AudioItem | undefined>(undefined);
  const { modalProps, openModal, closeModal } = useModalControl();

  const { getFieldProps, isComplete, values } = useForm({
    fields: {
      name: {
        required: true,
        type: 'text',
        value: initialState?.callObject?.primitiveName,
      },
      audioFile: {
        required: false,
        type: 'dropdown',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: initialState?.callObject?.phoneTreeExpansion?.promptMediaItemId,
      },
      dialOptions: {
        required: false,
        type: 'checklist',
        value: initialState?.dialOptions,
      },
      extensionDialing: {
        required: false,
        type: 'checkbox',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: initialState?.callObject?.phoneTreeExpansion?.extensionDialing ?? false,
      },
      timeoutAfter: {
        required: true,
        type: 'dropdown',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: String(initialState?.callObject?.phoneTreeExpansion?.timeout ?? '10'),
      },
      maxTimeouts: {
        required: true,
        type: 'dropdown',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: String(initialState?.callObject?.phoneTreeExpansion?.maxTimeouts ?? '3'),
      },
      maxInvalidSelections: {
        required: true,
        type: 'dropdown',
        // @ts-expect-error This is complaining because the schema type uses a oneOf for the expansion properties
        // and the generated typescript does not know how to handle that correctly.
        value: String(initialState?.callObject?.phoneTreeExpansion?.maxFailures ?? '3'),
      },
    },
  });

  const handleProceedClick = () => {
    if (!isComplete || !values.name || !values.timeoutAfter || !values.maxTimeouts || !values.maxInvalidSelections) {
      alerts.error('missing required fields');
      return;
    }

    onProceedClick({
      dialOptions: values.dialOptions,
      callObject: {
        primitiveId: initialState?.callObject?.primitiveId ?? '',
        primitiveName: values.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        phoneTreeExpansion: {
          extensionDialing: values.extensionDialing ?? false,
          maxFailures: parseInt(values.maxInvalidSelections),
          maxTimeouts: parseInt(values.maxTimeouts),
          timeout: parseInt(values.timeoutAfter),
          promptMediaFileName: selectedAudioFile?.name ?? '',
          promptMediaItemId: selectedAudioFile?.id ?? '',
        },
      },
    });
  };

  return (
    <>
      <HeaderBar title={t('Phone Tree')} onClose={onClose} />

      <TextField
        {...getFieldProps('name')}
        label={t('Phone tree name')}
        css={css`
          width: 318px;
          margin-bottom: ${theme.spacing(1)};
        `}
      />
      <SkeletonLoaders.Loader isLoading={false} shape='rectangle' width={320} height={50}>
        <div>
          <AudioPicker
            widths={{ field: '318px', scrubber: '240px' }}
            css={css`
              margin-bottom: ${theme.spacing(1)};
            `}
            allowedOptions={{
              add: true,
              custom: true,
              standard: false,
              mailbox: false,
              none: false,
              default: false,
            }}
            field={getFieldProps('audioFile')}
            name='audioFile'
            labels={{
              field: t('Select prompt'),
            }}
            onSelect={(item) => {
              // NOTE: this will not be called if the user selects the "No Greeting option"
              setSelectedAudioFile(item);
            }}
            tenantId={tenantId}
          />
          <Text size='small' color='subdued'>
            {t('Plays when caller reaches Phone Tree.')}
          </Text>
        </div>
      </SkeletonLoaders.Loader>

      <div>
        <Heading
          level={3}
          css={css`
            margin-bottom: ${theme.spacing(0.5)};
          `}
        >
          {t('Dial Options')}
        </Heading>
        <ChecklistField {...getFieldProps('dialOptions')} name='dialOptions' label='Select the dial options.'>
          {/* Show numbered options from 0 to 9 */}
          {[...Array(10).keys()].map((i) => (
            <ChecklistField.Option key={i} name={i.toString()}>
              {i.toString()}
            </ChecklistField.Option>
          ))}
        </ChecklistField>

        <Heading
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
          level={3}
        >
          {t('Extension Dialing')}
        </Heading>
        <CheckboxField
          {...getFieldProps('extensionDialing')}
          name='extensionDialing'
          label={t('Callers can dial device extensions in your phone system')}
        />
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Heading level={3}>{t('Timeouts & Invalid Selections')}</Heading>
        <Button iconName='info-small' variant='secondary' onClick={openModal} />
      </div>
      <div
        css={css`
          width: 318px;
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(3)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <DropdownField
          {...getFieldProps('timeoutAfter')}
          name='timeoutAfter'
          label={t('Timeout after')}
          helperText={t('Phone Tree prompt will replay after timeout.')}
        >
          {[1, 2, 3, 4, 5, 10, 15, 30, 60].map((i) => (
            <DropdownField.Option key={i} value={(i * 1000).toString()}>
              {`${i} seconds`}
            </DropdownField.Option>
          ))}
        </DropdownField>

        <DropdownField
          {...getFieldProps('maxTimeouts')}
          name='maxTimeouts'
          label={t('Max timeouts')}
          helperText={t('Exceeding this number will send caller to fallback option.')}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <DropdownField.Option key={i} value={i.toString()}>
              {i.toString()}
            </DropdownField.Option>
          ))}
        </DropdownField>

        <DropdownField
          {...getFieldProps('maxInvalidSelections')}
          name='maxInvalidSelections'
          label={t('Max invalid selections')}
          helperText={t('Exceeding this number will send caller to fallback option.')}
        >
          {[1, 2, 3, 4, 5].map((i) => (
            <DropdownField.Option key={i} value={i.toString()}>
              {i.toString()}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </div>

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!isComplete}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />

      <Modal {...modalProps} minWidth={600}>
        <Modal.Header onClose={closeModal}>{t('Timeouts & Invalid Selections')}</Modal.Header>
        <Modal.Body>
          <div>
            <Text
              weight='bold'
              css={css`
                margin-bottom: ${theme.spacing(0.5)};
              `}
            >
              {t('Timeout')}
            </Text>
            <Text>
              {t(
                'A timeout occurs when a call is not answered for a set period prompting the system to go to the next step in the call route.'
              )}
            </Text>
          </div>

          <div>
            <Text
              weight='bold'
              css={css`
                margin-bottom: ${theme.spacing(0.5)};
              `}
            >
              {t('Invalid Selection')}
            </Text>
            <Text>
              {t(
                'An invalid selection is a caller input that does not correspond to any of the Phone Tree’s menu options.'
              )}
            </Text>
          </div>

          <div>
            <Text
              weight='bold'
              css={css`
                margin-bottom: ${theme.spacing(0.5)};
              `}
            >
              {t('Fallback')}
            </Text>
            <Text>
              {t(
                'A fallback is an alternative action or destination used by the phone system when the caller exceeds the maximum number of timeouts or invalid options, or when the primary number is busy or unavailable.'
              )}
            </Text>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
