import {
  OffsetDirection_Enum,
  OffsetType_Enum,
  ProcessingStrategy_Enum,
  Rule,
} from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/models.pb';
import { Message } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/models.pb';
import { MessageGroupBy_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/service.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { AutoRulesQueries } from '@frontend/api-auto-rules';
import { AutoWorkerQueries } from '@frontend/api-auto-worker';
import { AutoMessageSuppressionCard } from '@frontend/auto-message-suppression-card';
import { useTranslation } from '@frontend/i18n';
import { useFormatStatuses } from '@frontend/message-queue-hooks';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { AutoMessagePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Accordion, NakedButton, SpinningLoader, Text, useAlert } from '@frontend/design-system';
import { ScheduleEventPersonInfo } from './auto-message-detail-appointment-event';

const CARD_CONTAINER_HEIGHT = 202;

type Props = {
  appointmentId: string;
  appointmentLocationId: string;
  closeAppointmentDetailsModal?: () => void;
  person: ScheduleEventPersonInfo;
};

export const AutoMessageDetailAppointmentAccordionBody = ({
  appointmentId,
  appointmentLocationId,
  closeAppointmentDetailsModal,
  person,
}: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('messages');
  const { setPersonId, setScrollTo } = useContactPanelShallowStore('setPersonId', 'setScrollTo');

  const { selectedLocationIds: locationIds, selectedOrgId: orgId } = useAppScopeStore();
  const personId = person.id;
  const { getGroupedStatus } = useFormatStatuses({
    [OutboundMessageStatus.UNSPECIFIED]: OutboundMessageStatus.DELIVERED,
  });

  const {
    data: next7 = [],
    isLoading: next7IsLoading,
    queryKey: forecastMessagesQueryKey,
  } = AutoWorkerQueries.useForecastMessages<Message[]>(
    {
      locationIds,
      orgId,
      personId,
      groupBy: MessageGroupBy_Enum.LOCATION,
      recordId: appointmentId,
    },
    {
      enabled: !!(orgId && personId),
      select: (response) =>
        Object.values(response)
          .flatMap((group) =>
            [...group.sentSending, ...group.notSentSending].map((message) => ({
              ...message,
              currentStatus: getGroupedStatus(message.currentStatus!),
            }))
          )
          .sort((a, b) => {
            const first = a.sendAt ?? '';
            const second = b.sendAt ?? '';
            if (first === second) return 0;
            return first > second ? 1 : -1; // ascending order
          }),
    }
  );

  const ruleIds = next7.map((message) => message.ruleId);

  const { data: rules = [] } = AutoRulesQueries.useListRules<Rule[]>(
    {
      orgId,
      skipFilterValidation: true,
      summaryOnly: true,
      businessGroups: locationIds,
    },
    {
      select: (response) => response.rules || [],
      onError: () => {
        // TODO: Ethan's providing a new endpoint for this to help the max limit issue
        alert.error(t('There was an error fetching the rules.'));
      },
    }
  );

  const filteredRules = rules.filter((rule) => ruleIds.includes(rule.ruleId));

  const formMessageDetails = (ruleId?: string) => {
    if (!ruleId) return;
    const rule = filteredRules.find((rule) => rule.ruleId === ruleId);
    if (!rule) return;

    const { autoMessageType, offsetDirection, offsetDuration, offsetType } = rule;
    const processingStrategy = autoMessageType?.processingStrategy;

    const isNotRelativeOrInvalid =
      processingStrategy !== ProcessingStrategy_Enum.RELATIVE && processingStrategy !== ProcessingStrategy_Enum.INVALID;
    if (isNotRelativeOrInvalid) return t('Starting');

    switch (offsetType) {
      case OffsetType_Enum.HOURS_OFFSET:
        return offsetDirection === OffsetDirection_Enum.PRE_TRIGGER
          ? t(`Sending {{count}} hour before this appointment`, { count: offsetDuration ?? 1, context: 'hour' })
          : t(`Sending {{count}} hour after this appointment`, { count: offsetDuration ?? 1, context: 'hour' });
      case OffsetType_Enum.DAYS_OFFSET:
        return offsetDirection === OffsetDirection_Enum.PRE_TRIGGER
          ? t(`Sending {{count}} day before this appointment`, { count: offsetDuration ?? 1, context: 'day' })
          : t(`Sending {{count}} day after this appointment`, { count: offsetDuration ?? 1, context: 'day' });
      case OffsetType_Enum.SELECTED_DAYS_OFFSET:
        return offsetDirection === OffsetDirection_Enum.PRE_TRIGGER
          ? t(`Sending {{count}} selected day before this appointment`, { count: offsetDuration ?? 1, context: 'day' })
          : t(`Sending {{count}} selected day after this appointment`, { count: offsetDuration ?? 1, context: 'day' });
      case OffsetType_Enum.WEEKS_OFFSET:
        return offsetDirection === OffsetDirection_Enum.PRE_TRIGGER
          ? t(`Sending {{count}} week before this appointment`, { count: offsetDuration ?? 1, context: 'week' })
          : t(`Sending {{count}} week after this appointment`, { count: offsetDuration ?? 1, context: 'week' });
      case OffsetType_Enum.MONTHS_OFFSET:
        return offsetDirection === OffsetDirection_Enum.PRE_TRIGGER
          ? t(`Sending {{count}} month before this appointment`, { count: offsetDuration ?? 1, context: 'month' })
          : t(`Sending {{count}} month after this appointment`, { count: offsetDuration ?? 1, context: 'month' });
      case OffsetType_Enum.NO_OFFSET:
      default:
        return '';
    }
  };

  const handlePersonClick = () => {
    setPersonId(personId, true, appointmentLocationId);
    closeAppointmentDetailsModal?.();
    setScrollTo('auto-messages');
  };

  return (
    <Accordion.Body css={{ padding: 0 }}>
      {next7IsLoading ? (
        <div css={containerStyle}>
          <SpinningLoader size='small' />
        </div>
      ) : next7.length ? (
        <div css={{ maxHeight: CARD_CONTAINER_HEIGHT, overflow: 'auto' }}>
          {next7.map((message) => (
            <AutoMessageSuppressionCard
              key={message.messageId}
              message={message}
              queryKeyToInvalidate={forecastMessagesQueryKey}
              isNext
              canSuppress={message.currentStatus === OutboundMessageStatus.DELIVERED}
              cardType='rule'
              messageDetails={formMessageDetails(message.ruleId)}
              person={{ PersonID: personId }}
              showTimestamp
              simplifiedTimestamp
              trackingPrefix={AutoMessagePrefixes.AppointmentEventDetail}
              css={{ '&:last-of-type': { borderBottom: `1px solid ${theme.colors.neutral20}` } }}
            />
          ))}
        </div>
      ) : (
        <div css={containerStyle}>
          <Text color='light' css={{ maxWidth: 220, textAlign: 'center' }}>
            {t('No related auto-messages scheduled in the next 7 days.')}
          </Text>
        </div>
      )}
      <div css={{ display: 'flex', justifyContent: 'flex-end', padding: theme.spacing(1, 2) }}>
        <NakedButton
          css={{ display: 'flex', alignItems: 'center', columnGap: theme.spacing(0.5) }}
          onClick={handlePersonClick}
          trackingId={AutoMessagePrefixes.Suppression + '-appointment-event-accordion-next-7-days-view-all'}
        >
          <Text color='primary' size='medium'>
            {t('View All')}
          </Text>
        </NakedButton>
      </div>
    </Accordion.Body>
  );
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 100,
};
