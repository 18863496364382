import { useCallback, useEffect, useMemo, useState } from 'react';
import { DestinationType_Enum, MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import {
  DynamicFieldProperty_Enum,
  Template,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { isEqual } from 'lodash-es';
import { EmailSendV2 } from '@frontend/api-email-send';
import { PersonsV3 } from '@frontend/api-person';
import { TemplatorV2Queries } from '@frontend/api-templator-v2';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useAlert, useForm } from '@frontend/design-system';
import {
  LinkData,
  ManualTemplateTypesWithStaticTemplate,
  PropertyBindingsData,
  convertBindingsDataToPropertyBindingsList,
  convertLinkDataToProperty,
  useGetDefaultTemplate,
} from '../../messaging';
import { SendingMediaItem, ToFieldType } from '../types';

type useEmailStatesProps = {
  locationId: string;
  templateType: ManualTemplateTypesWithStaticTemplate;
  linkData: LinkData;
  personId?: string;
  onSendSuccess?: () => void;
  onSendError?: () => void;
  initialValues?: {
    subject?: string;
  };
};
export const useEmailStates = ({
  locationId,
  templateType,
  linkData,
  personId,
  onSendSuccess,
  onSendError,
  initialValues,
}: useEmailStatesProps) => {
  const { t } = useTranslation('integrated-messaging');
  const alert = useAlert();
  const { accessibleLocationData, getLocationName, selectedOrgId } = useAppScopeStore();
  const locationData = accessibleLocationData[locationId];
  const [initTemplate, setInitTemplate] = useState<Template>();
  const { mutate: sendEmail } = EmailSendV2.Mutations.useSendMutation();
  const [attachments, setAttachments] = useState<SendingMediaItem[]>([]);
  const [toField, setToField] = useState<ToFieldType>({ value: [], error: false });

  const { getFieldProps, seedValues, formProps } = useForm({
    fields: {
      from: { type: 'text', required: true, value: 'noreply@mail.sg.getweave.com' },
      subject: { type: 'text', required: true, placeholder: t('Subject Line'), value: initialValues?.subject },
      body: { type: 'text', required: true },
    },
    onSubmit: async (values) => {
      if (!toField.value.length) {
        setToField({ value: toField.value, error: true });
        alert.error(t('Please select at least one recipient.'));
        return;
      }
      const to =
        toField.value.map((person) => {
          return {
            email: person.contactInfo?.find((contact) => contact.type === ContactType_Enum.EMAIL)?.destination ?? '',
            name: PersonsV3.PersonHelpers.getFullName(person),
          };
        }) ?? [];

      const formattedAttachments = attachments
        .filter((attachment) => !attachment.hasError)
        .map((attachment) => ({
          fileName: attachment.file?.name ?? '',
          mimeType: attachment.file?.type ?? '',
          mediaId: attachment.mediaObj?.mediaId ?? '',
        }));

      sendEmail(
        {
          locationId,
          email: {
            emailType: MessageType_Enum.PAYMENT_REMINDER,
            to,
            subject: values.subject ?? '',
            body: {
              rawHtml: values.body?.replaceAll('\n', '<br/>'),
            },
            attachments: formattedAttachments,
          },
        },
        {
          onSuccess: () => onSendSuccess?.(),
          onError: () => {
            alert.error(t('Error sending email. Please try again.'));
            onSendError?.();
          },
        }
      );
    },
  });

  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      locationIds: [locationId],
      personId: personId ?? '',
    },
    {
      enabled: !!personId,
    }
  );

  const getDefaultTemplate = useGetDefaultTemplate({
    groupIds: [locationId],
  });

  const [renderData, setRenderData] = useState<{
    payload: Parameters<(typeof TemplatorV2Queries)['useRender']>[0];
    templateId: string;
    linkData?: LinkData;
  }>();
  const renderedTemplateQuery = TemplatorV2Queries.useRender(
    renderData?.payload
      ? {
          ...renderData.payload,
          keepEmptyDynamicFields: true,
        }
      : {
          templateTypeSlug: TemplateType_Slug.UNSPECIFIED_TEMPLATE_TYPE,
          templateString: '',
          bindingsList: [],
          timezone: locationData?.timezone ?? '',
          locale: 'en_US',
          destinationType: DestinationType_Enum.EMAIL,
        },
    {
      enabled: !!renderData,
      onSuccess: ({ message: { message } }) => {
        seedValues({
          body: message,
        });
      },
    }
  );

  const defaultBindingsData = useMemo<PropertyBindingsData>(
    () => ({
      [DynamicFieldProperty_Enum.ORG_NAME]: getLocationName(selectedOrgId),
      [DynamicFieldProperty_Enum.BUSINESS_GROUP_NAME]: getLocationName(locationId),
      [DynamicFieldProperty_Enum.FIRST_NAME]: personQuery.data?.firstName,
      [DynamicFieldProperty_Enum.LAST_NAME]: personQuery.data?.lastName,
      [DynamicFieldProperty_Enum.PREFERRED_NAME]: personQuery.data?.preferredName || personQuery.data?.firstName,
    }),
    [
      locationId,
      selectedOrgId,
      personQuery.data?.firstName,
      personQuery.data?.lastName,
      personQuery.data?.preferredName,
      getLocationName,
    ]
  );

  const setPayloadToRenderedTemplate = useCallback(
    (template: Template) => {
      const linkDataPropertyBindings = convertLinkDataToProperty({ linkData, templateType });
      const bindingsList = convertBindingsDataToPropertyBindingsList({
        ...defaultBindingsData,
        ...linkDataPropertyBindings,
      });
      setRenderData((prev) => {
        const newPayload = {
          templateTypeSlug: template.templateTypeSlug,
          templateString: template.templateString,
          bindingsList,
          timezone: locationData?.timezone ?? '',
          locale: 'en_US',
          destinationType: DestinationType_Enum.SMS,
        };

        if (renderedTemplateQuery.data && isEqual(newPayload, prev?.payload)) {
          seedValues({
            body: renderedTemplateQuery.data.message.message,
          });
        }

        return {
          payload: newPayload,
          templateId: template.templateId,
        };
      });
    },
    [
      selectedOrgId,
      defaultBindingsData,
      locationData?.timezone,
      setRenderData,
      linkData,
      templateType,
      convertLinkDataToProperty,
      renderedTemplateQuery?.data,
      seedValues,
    ]
  );

  useEffect(() => {
    if (renderedTemplateQuery.data) {
      seedValues({
        body: renderedTemplateQuery.data.message.message,
      });
    }
  }, [renderedTemplateQuery.data]);

  useEffect(() => {
    if (templateType) {
      const template = getDefaultTemplate({ type: templateType });
      if (template) setInitTemplate(template);
    }
  }, [templateType]);

  useEffect(() => {
    if (initTemplate) {
      setPayloadToRenderedTemplate(initTemplate);
    }
  }, [initTemplate]);

  useEffect(() => {
    if (personQuery.data) {
      const personEmail =
        personQuery.data.contactInfo?.find((contact) => contact.type === ContactType_Enum.EMAIL)?.destination ?? '';
      if (personEmail) {
        const personData: Person = personQuery.data;
        setToField({ value: [personData], error: false });
      }
    }
  }, [personQuery.data]);

  return {
    person: personQuery.data,
    formProps,
    getFieldProps,
    setAttachments,
    setToField,
    toField,
  };
};
