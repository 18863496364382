import { css } from '@emotion/react';
import { Link } from '@tanstack/react-location';
import { ContactsHooks } from '@frontend/contacts';
import { Dashboard } from '@frontend/dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon, type IconName } from '@frontend/icons';
import { useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface AllAppsModule {
  showHeader?: boolean;
}

export const AllAppsModule = ({ showHeader = true }: AllAppsModule) => {
  const { t } = useTranslation('dashboard');
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const faxFeatureFlag = getFeatureFlagValue('fax-2-0');
  const hasListHomeIconAccess = ContactsHooks.useHasListHomeIconAccess();

  return (
    <>
      {showHeader && <Dashboard.Module.Header title={t('All Apps')} />}
      <Dashboard.Module.Content>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: ${theme.spacing(2.5)};
            padding: ${theme.spacing(1)};
            margin-top: ${theme.spacing(3)};
          `}
        >
          <AppFeatureButton icon='phone' to='/calls/recent-calls' label={t('Phone')} />
          <AppFeatureButton icon='messaging' to='/messages/inbox' label={t('Messages')} />
          <AppFeatureButton icon='contacts' to='/contacts/all-contacts' label={t('Contacts')} />
          <AppFeatureButton icon='lists' to='/contacts/lists' label={t('Lists')} show={hasListHomeIconAccess} />
          <AppFeatureButton icon='calendar' to='/schedule/calendar' label={t('Schedule')} />
          <AppFeatureButton icon='forms' to='/forms/submissions/inbox/all' label={t('Forms')} />
          <AppFeatureButton icon='analytics' to='/analytics/dashboard' label={t('Analytics')} />
          <AppFeatureButton icon='reviews-dash' to='/reviews/all-reviews' label={t('Reviews')} />
          <AppFeatureButton icon='payments' to='/payments/invoices' label={t('Payments')} />
          <AppFeatureButton icon='fax' to='/fax/inbox' label={t('Fax')} show={faxFeatureFlag} />
        </div>
      </Dashboard.Module.Content>
    </>
  );
};

type AppFeatureButtonProps = {
  icon: IconName;
  label: string;
  to: string;
  show?: boolean;
  size?: 'large' | 'small';
};

export const AppFeatureButton = ({ icon, label, to, show, size = 'large' }: AppFeatureButtonProps) => {
  if (show === false) return null;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        align-items: center;
      `}
    >
      <Link
        to={to}
        css={css`
          width: ${size === 'large' ? '72px' : '60px'};
          height: ${size === 'large' ? '72px' : '60px'};
          background-color: ${theme.colors.primary50};
          color: ${theme.colors.white};
          border-radius: ${theme.borderRadius.full};
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s ease;

          :hover,
          :focus {
            box-shadow: ${theme.shadows.floating};
          }
          &:hover,
          &:active {
            transform: scale(1.05);
            box-shadow: ${theme.shadows.heavy};
          }

          &:active {
            transform: scale(0.95);
          }
        `}
      >
        <Icon name={icon} size={32} />
      </Link>
      <Text size='small'>{label}</Text>
    </div>
  );
};
