import { useCallback, useEffect, useRef } from 'react';
import type { AnimationPlaybackControls, AnimationScope } from 'motion/react';
import { WeavePopNotification, WeavePopNotificationActions } from '@frontend/types';

export type NotificationState = {
  timeout?: number;
  paused?: boolean;
};

export type Notification = {
  id: string;
  type: string;
  state: NotificationState;
};

export type EmitAction = 'blur' | 'timed-out' | 'focus';

type UseNotificationTimeoutProps = {
  notification: WeavePopNotification;
  onEmit: (action: WeavePopNotificationActions, notification: WeavePopNotification) => void;
  scope: AnimationScope<any>;
  animate: (target: HTMLElement, keyframes: any, options?: any) => AnimationPlaybackControls;
};

export const useNotificationTimeout = ({ notification, onEmit, scope, animate }: UseNotificationTimeoutProps) => {
  const animation = useRef<AnimationPlaybackControls>();
  const hasTimeout = !!notification.state.timeout;
  const insideNotification = useRef(true);

  const startTimeout = useCallback(() => {
    if (!hasTimeout || notification.state.paused) return;
    animation.current?.cancel();
    animate(scope.current!, { height: '100%' });

    animation.current = animate(
      scope.current!,
      { width: ['100%', 0] },
      {
        duration: (notification.state.timeout ?? 0) / 1000,
        ease: 'linear',
        onComplete: () => {
          if (!notification.state.paused) {
            onEmit('timed-out', notification as any);
          }
        },
      }
    );
  }, [hasTimeout, notification.id, notification.state.timeout, notification.state.paused, animate, scope]);

  useEffect(() => {
    if (!insideNotification.current) return;

    if (!notification.state.paused) {
      startTimeout();
    }

    return () => {
      animation.current?.cancel();
      animation.current = undefined;
    };
  }, [notification.id, notification.state.paused, notification.state.timeout]);

  const handleMouseEnter = useCallback(() => {
    insideNotification.current = false;
    onEmit('focus', notification);
    animation.current?.pause();
    animate(scope.current!, { height: 0 });
  }, [onEmit, notification, animate, scope]);

  const handleMouseLeave = useCallback(() => {
    insideNotification.current = true;
    if (notification.state.paused) return;
    onEmit('blur', notification);
    startTimeout();
  }, [notification.state.paused, onEmit, notification, startTimeout]);

  return { handleMouseEnter, handleMouseLeave };
};
