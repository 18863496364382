import { useTranslation } from '@frontend/i18n';
import type { GetWeavePopNotificationByType, NotificationActions } from '@frontend/types';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'message-followup'>>;

export const MessageFollowupNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const { t } = useTranslation();
  const actions: NotificationActions = [
    // add this back when we know where it should redirect to
    // {
    //   Icon: undefined,
    //   label: t('View Message'),
    //   action: () => {
    //     emit({ action: 'view', payload: undefined }, notification);
    //   },
    // },
    ...(!isHistorical
      ? [
          {
            label: t('Ignore'),
            action: () => {
              emit({ action: 'ignore', payload: undefined }, notification);
            },
          },
        ]
      : []),
  ];
  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        actions={actions}
        id={notification.id}
        title={t('New Follow Up')}
        body={notification.payload.patient.first_name + ' ' + notification.payload.patient.last_name}
        timestamp={notification.timestamp}
        notificationType={notification.type}
        {...rest}
      />
    </BaseNotificationOuter>
  );
};
