import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import { SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { TagSelectionHooks } from '@frontend/tag-selection';

export const CONVERSATION_IMG_ID_PREFIX = 'media-id-';
export const EXPORT_IMG_ID_PREFIX = 'export-media-id-';
export const TEXT_WRITEBACKS_RELATED_ID_TYPE = SchemaSMSSharedEnums.RelatedType.RELATED_TYPE_SMS_WRITEBACK_NOTE;
export const TEMP_WRITEBACKS_RELATED_ID: SchemaSMSSharedModels.RelatedID = {
  type: TEXT_WRITEBACKS_RELATED_ID_TYPE,
  id: 'temp-writeback-id',
};
export const HIDE_DELETE_MODAL = 'hide-sms-delete-modal';
export const PERSON_SEARCH_PAGE_SIZE = 20;

export const SMART_TAG_FEEDBACK_THRESHOLDS: Parameters<
  typeof TagSelectionHooks.useTagFeedbackFlow
>[0]['feedbackClickThreshold'] = {
  [FeedbackType.FEEDBACK_TYPE_BAD]: 3,
  [FeedbackType.FEEDBACK_TYPE_GOOD]: -1,
};

export const INBOX_PANEL_ID = 'inbox-panel';
export const THREAD_PANEL_ID = 'thread-panel';
