import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useValidateACHTransactionBalance } from '@frontend/api-weave-pay';
import { PaymentsFeatureFlags } from '@frontend/payments-hooks';

/**
 * @deprecated collect-flow/* will be removed once collect-flow-multi/ is fully live.
 */
export interface UseValidateACHBalanceOptions {
  amount: number;
  locationId: string | undefined;
  paymentMethod?: PaymentMethod;
}
/**
 * @deprecated collect-flow/* will be removed once collect-flow-multi/ is fully live.
 */
export const useValidateACHBalance = ({ amount, locationId, paymentMethod }: UseValidateACHBalanceOptions) => {
  const { mutateAsync } = useValidateACHTransactionBalance();
  const { aggregateValue: enabledACHBalanceCheck } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.EnableACHBalanceCheck,
    locationIds: [locationId ?? ''],
  });
  const paymentMethodId = paymentMethod?.id || '';

  if (!enabledACHBalanceCheck || !paymentMethodId || paymentMethod?.type !== 'us_bank_account') {
    return { isACHBalanceValidated: (): boolean => true };
  }

  const isACHBalanceValidated = async (): Promise<boolean> => {
    const data = await mutateAsync({ locationId: locationId ?? '', amount, paymentMethodId });

    return !!data?.balanceAvailable;
  };

  return { isACHBalanceValidated };
};
