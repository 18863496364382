import { useSchemaQuery } from '@frontend/react-query-helpers';
import type { SchemaIO } from '@frontend/schema';
import { defaultOptions } from '../config';
import { SchemaCallIntelV2Service, serviceName } from '../service';
import { ServiceQueries, ServiceStandardQueries, StandardQueryEndpointName } from '../types';

export type UseCallIntelV2QueryArgs<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
> = Omit<Parameters<typeof useSchemaQuery<ServiceStandardQueries, EndpointName, E, D>>[0], 'service' | 'serviceName'>;

export function useCallIntelV2Query<
  EndpointName extends StandardQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceQueries[EndpointName]>['output']
>({ options, ...args }: UseCallIntelV2QueryArgs<EndpointName, E, D>) {
  return useSchemaQuery<ServiceStandardQueries, EndpointName, E, D>({
    service: SchemaCallIntelV2Service,
    serviceName,
    ...args,
    options: {
      ...defaultOptions,
      ...options,
    },
  });
}
