import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { HttpError } from '@frontend/fetch';
import { i18next } from '@frontend/i18n';
import { PaymentsTerminalError } from '../payment-terminal-error';
import type {
  TerminalCollectAndProcessProps,
  TerminalStrategyContextCreator,
  TerminalStrategyMethods,
  TerminalStrategyProps,
} from '../terminal-strategy';
import { RetryLimitError, doActionWithRetry } from '../utils';

const MINUTES = 6e4; // ms
const TERMINAL_STATUS_POLLING_RETRY_WAIT_MS = 3000; // ms
const TRANSACTION_TIMEOUT_MS = 5 * MINUTES; // ms - backend should time out at 5 min, this is a fail safe
const MAX_TRANSACTION_ATTEMPTS = Math.ceil(TRANSACTION_TIMEOUT_MS / TERMINAL_STATUS_POLLING_RETRY_WAIT_MS); // unitless number

const OVERRIDDEN_ERROR_MESSAGE =
  'Another station terminated the transaction to use the terminal. Please try again to process the payment.';

const createServerDrivenTerminalSession: TerminalStrategyContextCreator = async ({
  reader,
  locationId,
  paymentsUrl,
  onPaymentStatusChange: onPaymentStatusChange,
}: TerminalStrategyProps): Promise<TerminalStrategyMethods> => {
  onPaymentStatusChange?.('initializing');

  let _canceled = false;
  let _paymentIntentId: string;

  const cancelCurrentAction = async () => {
    if (!_paymentIntentId) {
      console.log(_paymentIntentId);
      _canceled = true;
      return Promise.resolve();
    }

    try {
      await PaymentsTerminalApi.cancelTerminalAction({
        readerId: reader.readerId,
        locationId,
        paymentIntentId: _paymentIntentId,
        paymentsUrl,
      });
      _canceled = true;
    } catch (error) {
      throw new PaymentsTerminalError('cancel', 'Error cancelling terminal action');
    }
  };

  const _pollPaymentStatus = (
    request: () => ReturnType<typeof PaymentsTerminalApi.getTerminalPaymentStatus>
  ): Promise<void> => {
    return doActionWithRetry(
      async () => {
        if (_canceled) {
          return true;
        }

        try {
          // Server is implementing long polling, the connection limit will be 10 minutes
          const { status, error } = await request();

          switch (status) {
            case 'TERMINAL_PAYMENT_STATUS_SUCCEEDED':
              onPaymentStatusChange?.('success');
              /**
               * If true is not returned, polling and on status change callbacks will keep
               * getting called.
               */
              return true;
            case 'TERMINAL_PAYMENT_STATUS_CANCELED_DUE_TO_TIMEOUT':
            case 'TERMINAL_PAYMENT_STATUS_CANCELED':
            case 'TERMINAL_PAYMENT_STATUS_MISMATCH':
            case 'TERMINAL_PAYMENT_STATUS_FAILED':
              onPaymentStatusChange?.('error');

              // on the other two cancel cases except the overriding case, the error message is already set
              // the other two cases are timeout and customer cancelling directly from the readers
              if (status === 'TERMINAL_PAYMENT_STATUS_CANCELED' && !error.message) {
                error.message = OVERRIDDEN_ERROR_MESSAGE;
              }
              throw new PaymentsTerminalError('collect_and_process', error);
            // This part shouldn't happen, but just in case the server responds with unexpected
            // states immediately, should poll again with caution
            case 'TERMINAL_PAYMENT_STATUS_UNKNOWN':
              onPaymentStatusChange?.('initializing');
              break;
            case 'TERMINAL_PAYMENT_STATUS_IN_PROGRESS':
              onPaymentStatusChange?.('waiting-input');
              break;
            default: {
              const _exhaustiveCheck: never = status;
              throw new PaymentsTerminalError(
                'collect_and_process',
                'Unexpected status from server: ' + _exhaustiveCheck
              );
            }
          }
        } catch (error) {
          if (error instanceof HttpError) {
            // Because of the long polling, we might get a 502 or 504 error, we should retry
            if (error.status === 502 || error.status === 504) {
              return;
            }
            throw new PaymentsTerminalError('collect_and_process', error);
          } else if (error instanceof PaymentsTerminalError) {
            throw error;
          } else if (error instanceof RetryLimitError) {
            throw new PaymentsTerminalError(
              'collect_and_process',
              `Did not process payment before transaction timed out. (${error.type})`
            );
          }
          onPaymentStatusChange?.('error');
          throw new PaymentsTerminalError(
            'collect_and_process',
            'Unknown error occurred, check records and try again.'
          );
        }

        return false;
      },
      {
        timeBetweenRetries: TERMINAL_STATUS_POLLING_RETRY_WAIT_MS,
        exponentialBackoff: false,
        maxRetryDuration: TRANSACTION_TIMEOUT_MS,
        maxAttempts: MAX_TRANSACTION_ATTEMPTS,
        maxDurationMessage: i18next.t('Transaction timed out', { namespace: 'payments' }),
      }
    );
  };

  /**
   * This method is responsible for collecting and processing the payment using the server driven apis.
   *
   * See [Terminal Payments Flowchart]{@link https://drive.google.com/file/d/1ZHd1HElTDGwqnVPENfrwkXSXqouKHArO/view?usp=sharing}
   */
  const collectAndProcessPayment = async ({ paymentIntent, paymentId }: TerminalCollectAndProcessProps) => {
    _canceled = false;

    if (!paymentIntent.id) {
      throw new PaymentsTerminalError('collect_and_process', 'Payment intent is missing. Got: ' + paymentIntent);
    }
    _paymentIntentId = paymentIntent.id;

    try {
      const { links } = await PaymentsTerminalApi.processTerminalPayment({
        locationId,
        paymentsUrl: paymentsUrl,
        paymentIntentId: paymentIntent.id,
        readerId: reader.readerId,
        paymentId,
      });

      if (typeof links.status === 'string' && links.status.startsWith('http')) {
        return _pollPaymentStatus(() => PaymentsTerminalApi.getTerminalPaymentStatus(links.status));
      }

      return _pollPaymentStatus(() =>
        PaymentsTerminalApi.getTerminalPaymentStatus({
          paymentIntentId: paymentIntent.id,
          locationId,
          paymentsUrl,
        })
      );
    } catch (error) {
      console.error('Error processing payment', error);
      throw new PaymentsTerminalError('collect_and_process', 'Error processing payment');
    }
  };

  return {
    cancelCurrentAction,
    collectAndProcessPayment,
    usingServerDrivenFlow: true,
  };
};

export const ServerDrivenStrategy = { createServerDrivenTerminalSession };
