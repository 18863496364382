import { useRef, useState } from 'react';
import { PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { Accordion, Heading } from '@frontend/design-system';
import { AutoMessageDetailContactAccordionBody } from './auto-message-detail-contact-accordion';

export const AutoMessageDetailContactPanel = ({ person }: { person?: PersonTypes.Person }) => {
  const [accordionValue, setAccordionValue] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('messages');

  return (
    <div ref={ref}>
      <Accordion
        controlledValue={accordionValue}
        css={{ button: { borderBottom: 'none' } }}
        onChange={(val) => setAccordionValue(val)}
      >
        <Accordion.Item value='auto-messages'>
          <Accordion.Header>
            <Heading level={3}>{t('Auto-Messages')}</Heading>
          </Accordion.Header>
          {accordionValue && <AutoMessageDetailContactAccordionBody person={person} />}
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
