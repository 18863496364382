import { useMemo } from 'react';
import dayjs from 'dayjs';
import { InsuranceQueries } from '@frontend/api-insurance';
import { useOrgData } from '@frontend/get-org-data';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { InsuranceDetailsConstants } from '@frontend/insurance-details';
import { useGetWeaveInsuranceVerificationCustomizationFlagDetails } from '@frontend/insurance-verification';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { dispatchTopBarTraySelectionEvent } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, SpinningLoader, useTooltip, Text } from '@frontend/design-system';
import { useGetIntegrationDetails } from '../../../../hooks';
import { useAppointmentsInfoShallowStore } from '../../../../hooks/use-appointments-info-store';
import { useCalendarHeaderFilterShallowStore } from '../../../../stores';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../stores/use-schedule-actions-appointment-list-store';
import { HeaderBarTrackingIds } from './trackingIds';

const { INSURANCE_STATUS_MAPPING } = InsuranceDetailsConstants;

export const SummaryBoxRow = () => {
  const { t } = useTranslation('schedule');
  const isMedium = useMatchMedia({ maxWidth: breakpoints.medium.max });

  const { selectedLocationIds, selectedGroupId } = useAppScopeStore();
  const { orgId } = useOrgData();

  const { isIntegratedOffice } = useGetIntegrationDetails({
    selectedLocationId: selectedLocationIds[0],
    parentLocationId: selectedGroupId,
    isEnabled: !!(selectedLocationIds[0] || selectedGroupId),
  });

  const { isFeatureHiddenInAllLocation } = useGetWeaveInsuranceVerificationCustomizationFlagDetails();

  //NOTE - Check if the office is integrated and has Weave Verify enabled, otherwise don't show the insurance alerts count.
  const canUseInsuranceAlerts = isIntegratedOffice && !isFeatureHiddenInAllLocation;

  const { appointments: unfilteredAppointments } = useAppointmentsInfoShallowStore('appointments');

  const { filteredAppointmentList, isFiltersApplied } = useCalendarHeaderFilterShallowStore(
    'filteredAppointmentList',
    'isFiltersApplied'
  );

  const appointments = isFiltersApplied ? filteredAppointmentList : unfilteredAppointments;

  const {
    tooltipProps,
    triggerProps: toolTipTriggerProps,
    Tooltip,
  } = useTooltip({ placement: 'top', hoverDelay: 300 });

  const { selectedDate: calendarViewDate } = useAppointmentsInfoShallowStore('selectedDate');

  const [startDate, endDate] = useMemo(() => {
    if (calendarViewDate) {
      const startDateInUTC = dayjs(calendarViewDate).utc().toISOString();
      const endDateInUTC = dayjs(calendarViewDate).add(1, 'day').utc().toISOString();
      return [startDateInUTC, endDateInUTC];
    }
    return ['', ''];
  }, [calendarViewDate]);

  const { setStatusFilter, setInsuranceStatusFilters, setHasActiveFilters } =
    useScheduleActionsAppointmentListInfoShallowStore(
      'setStatusFilter',
      'setInsuranceStatusFilters',
      'setHasActiveFilters'
    );

  const getUnconfirmedAppointmentsCount = () => {
    return (
      (appointments &&
        appointments.filter((appointment) => {
          return appointment.statusOfficeView?.toLowerCase() === 'unconfirmed';
        }).length) ||
      0
    );
  };

  const { data: insuranceStatuses, isLoading: isLoadingInsuranceStatuses } =
    InsuranceQueries.useGetInsuranceAlertsByOrgId(
      {
        orgId,
        locationIds: selectedLocationIds ?? [],
        startDate,
        endDate,
      },
      {
        enabled: canUseInsuranceAlerts && !!orgId && !!selectedLocationIds?.length && !!startDate && !!endDate,
      }
    );

  const insuranceAlertsCount = useMemo(() => {
    const statusCounts = insuranceStatuses?.statusCounts ?? {};
    //NOTE - Remove any keys that have '_' or '-' in them to better match the status keys
    const excludedStatuses = ['selfpay', 'verified', 'active'];
    return Object.entries(statusCounts).reduce((acc, item) => {
      const status = item[0].replace(/[-_]/g, '').toLowerCase();
      return excludedStatuses.includes(status) ? acc : acc + item[1];
    }, 0);
  }, [insuranceStatuses?.statusCounts, isLoadingInsuranceStatuses]);

  const openAppointmentList = (filter?: string) => {
    dispatchTopBarTraySelectionEvent('schedulePulse');

    setStatusFilter?.(filter || '');
    setInsuranceStatusFilters([]);
    !filter && setHasActiveFilters(false);
  };

  const getInsuranceAlertStatuses = () => {
    const excludedStatuses = ['self pay', 'verified', 'active'];
    const statusAlerts = [...INSURANCE_STATUS_MAPPING.values()].reduce((acc, item) => {
      const status = item.text.toLowerCase().replace(/[-_]/g, ' ');
      excludedStatuses.includes(status.toLowerCase()) ? acc : acc.push(status);
      return acc;
    }, [] as string[]);
    return statusAlerts;
  };

  const openAppointmentListWithInsuranceAlerts = () => {
    const statusAlerts = getInsuranceAlertStatuses();
    setInsuranceStatusFilters(statusAlerts);
    setStatusFilter?.('');
    dispatchTopBarTraySelectionEvent('schedulePulse');
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: 'fit-content',
        height: theme.spacing(8),
      }}
    >
      <SummaryBox
        title={isMedium ? t('Appts') : t('Appointments')}
        iconName='calendar'
        count={appointments?.length || 0}
        onClickBox={openAppointmentList}
        trackingId={HeaderBarTrackingIds.allAppointmentsCountButton}
      />

      <SummaryBox
        title={t('Unconfirmed')}
        iconName='pending'
        count={getUnconfirmedAppointmentsCount()}
        onClickBox={() => openAppointmentList('unconfirmed')}
        trackingId={HeaderBarTrackingIds.unconfirmedAppointmentsCount}
      />

      {canUseInsuranceAlerts && (
        <>
          {isMedium && <Tooltip {...tooltipProps}>{t('Check Insurance')}</Tooltip>}
          <div {...toolTipTriggerProps}>
            <SummaryBox
              title={isMedium ? t('Insurance') : t('Check Insurance')}
              iconName='insurance-error'
              count={insuranceAlertsCount}
              onClickBox={openAppointmentListWithInsuranceAlerts}
              trackingId={HeaderBarTrackingIds.insuranceAlertsCount}
              isLoading={isLoadingInsuranceStatuses}
            />
          </div>
        </>
      )}
    </div>
  );
};

type SummaryBoxProps = {
  title: string;
  iconName: IconName;
  count: number;
  onClickBox?: () => void;
  trackingId?: string;
  isLoading?: boolean;
};

export const SummaryBox = ({ title, iconName, count, onClickBox, trackingId, isLoading }: SummaryBoxProps) => {
  const isMedium = useMatchMedia({ maxWidth: breakpoints.medium.max });

  return (
    <>
      {onClickBox ? (
        <NakedButton
          onClick={() => onClickBox()}
          trackingId={trackingId ?? ''}
          css={{
            width: 'fit-content',
            height: '100%',
            backgroundColor: theme.colors.white,
            padding: theme.spacing(1, 2),
            marginRight: isMedium ? theme.spacing(1) : theme.spacing(3),
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.small,
            boxShadow: theme.shadows.light,
            position: 'relative',
            transition: 'border 0.3s ease-in-out',
            textWrap: 'nowrap',
            '::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: theme.borderRadius.small,
              boxShadow: theme.shadows.floating,
              opacity: 0,
              transition: 'opacity 0.3s ease-in-out',
            },
            ':hover::after': {
              opacity: 1,
            },
            ':active': {
              border: `1px solid ${theme.colors.primary50}`,
            },
          }}
        >
          <Text
            size='small'
            color='subdued'
            textAlign='left'
            css={{ lineHeight: '1', marginBottom: theme.spacing(0.5) }}
          >
            {title}
          </Text>
          <div css={{ display: 'flex', alignItems: 'center', height: theme.spacing(3) }}>
            <Icon
              name={iconName}
              color='subdued'
              size={isMedium ? 16 : 24}
              css={{ marginRight: theme.spacing(1), minWidth: theme.spacing(2) }}
            />
            {isLoading ? <SpinningLoader size='xs' /> : <Heading level={2}>{count}</Heading>}
          </div>
        </NakedButton>
      ) : (
        <div
          css={{
            width: 'fit-content',
            height: '100%',
            backgroundColor: theme.colors.white,
            padding: theme.spacing(1, 2),
            marginRight: isMedium ? theme.spacing(1) : theme.spacing(3),
            border: `1px solid ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.small,
            boxShadow: theme.shadows.light,
            position: 'relative',
            transition: 'border 0.3s ease-in-out',
            textWrap: 'nowrap',
          }}
        >
          <Text size='small' color='subdued'>
            {title}
          </Text>
          <div css={{ display: 'flex', alignItems: 'center', width: '100%', height: '24px' }}>
            <Icon name={iconName} color='subdued' size={isMedium ? 16 : 24} css={{ marginRight: theme.spacing(1) }} />
            {isLoading ? <SpinningLoader size='xs' /> : <Heading level={2}>{count}</Heading>}
          </div>
        </div>
      )}
    </>
  );
};
