import { type FC } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { CommonHTMLImageAttributes } from '@frontend/types';
import { default as UpgradeIcon } from '../../assets/upgrade-icon.svg';

interface FeatureUpgradeIcon extends Omit<CommonHTMLImageAttributes, 'src' | 'alt'> {
  css?: Interpolation<Theme>;
}

export const FeatureUpgradeIcon: FC<FeatureUpgradeIcon> = ({ css, ...imageProps }) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-upgrade-action' });

  return <img alt={t('Upgrade')} src={UpgradeIcon} css={[upgradeIconBaseStyles, css]} {...imageProps} />;
};

const upgradeIconBaseStyles = css({
  aspectRatio: 1,
  width: 14,
});
