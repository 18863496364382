import { FormValues, UseFormReturnType } from '@frontend/design-system';
import { VoicemailBoxCreateRequest, VoicemailBoxReadResponse, VoicemailBoxUpdateRequest } from './types';

export const schema = {
  name: {
    type: 'text',
    required: true,
    value: '' as string,
  },
  extension: {
    type: 'dropdown',
    required: false,
    value: '' as string,
  },
  pin: {
    type: 'text',
    required: false,
    value: '' as string,
    validator: ({ value }: { value: string }) => {
      const hasLength = value.length === 4;
      const isNumeric = /^\d+$/.test(value);
      const isInRange = +value >= 1000 && +value <= 9999;

      if (!(hasLength && isNumeric && isInRange)) {
        return 'PIN must be a number from 1000 to 9999.';
      }
      return '';
    },
  },
  isShared: {
    type: 'checkbox',
    required: false,
    value: false as boolean,
  },
  playMessageDateEnabled: {
    type: 'checkbox',
    required: false,
    value: true as boolean,
  },
  notificationsEnabled: {
    type: 'checkbox',
    required: false,
    value: false as boolean,
  },
  notificationEmail: {
    type: 'list',
    required: false,
    value: [] as string[],
  },
  notificationSMS: {
    type: 'list',
    required: false,
    value: [] as string[],
  },
  locationIds: {
    type: 'checklist',
    required: false,
    value: [] as string[],
  },
} as const;

export const populateSchema = (state: VoicemailBoxReadResponse) => {
  const res = { ...schema };

  let key: keyof typeof schema;
  for (key in schema) {
    if (key === 'extension') {
      res[key] = { ...res[key], value: state.extension?.number?.toString() ?? 'none' };
    } else if (key === 'isShared') {
      res[key] = { ...res[key], value: state.mailbox.sharedAccess ?? false };
    } else if (key === 'name') {
      res[key] = { ...res[key], value: state.mailbox.name ?? '' };
    } else if (key === 'notificationEmail') {
      const list = !state.mailbox.email || state.mailbox.email === '' ? [] : state.mailbox.email?.split(',');
      res[key] = { ...res[key], value: list };
    } else if (key === 'notificationSMS') {
      const list = !state.mailbox.sms || state.mailbox.sms === '' ? [] : state.mailbox.sms?.split(',');
      res[key] = { ...res[key], value: list };
    } else if (key === 'notificationsEnabled') {
      res[key] = { ...res[key], value: state.mailbox.sendNotification ?? false };
    } else if (key === 'playMessageDateEnabled') {
      res[key] = { ...res[key], value: state.mailbox.playMessageDate ?? true };
    } else if (key === 'pin') {
      res[key] = { ...res[key], value: state.mailbox.pin?.toString() ?? '' };
    } else if (key === 'locationIds') {
      res[key] = { ...res[key], value: state.locationIds ?? [] };
    }
  }

  return res;
};

export const populateCreateSchema = (state: VoicemailBoxCreateRequest) => {
  const res = { ...schema };

  let key: keyof typeof schema;
  for (key in schema) {
    if (key === 'extension') {
      res[key] = { ...res[key], value: state.extensionNumber?.toString() ?? '' };
    } else if (key === 'isShared') {
      res[key] = { ...res[key], value: state.sharedAccess ?? false };
    } else if (key === 'name') {
      res[key] = { ...res[key], value: state.name ?? '' };
    } else if (key === 'notificationEmail') {
      res[key] = { ...res[key], value: state.emailAddresses ?? [] };
    } else if (key === 'notificationSMS') {
      res[key] = { ...res[key], value: state.smsNumbers ?? [] };
    } else if (key === 'notificationsEnabled') {
      res[key] = { ...res[key], value: state.sendNotification ?? false };
    } else if (key === 'playMessageDateEnabled') {
      res[key] = { ...res[key], value: state.playMessageDate ?? true };
    } else if (key === 'pin') {
      res[key] = { ...res[key], value: state.pin?.toString() ?? '' };
    } else if (key === 'locationIds') {
      res[key] = { ...res[key], value: state.locationIds ?? [] };
    }
  }

  return res;
};

export const createUpdatePayload = ({
  formValues,
  state,
}: {
  formValues: FormValues<typeof schema>;
  state: VoicemailBoxReadResponse;
}): VoicemailBoxUpdateRequest => {
  return {
    voicemailBoxId: state.mailbox.id,
    locationIds: formValues.locationIds ?? [],
    name: formValues.name ?? '',
    number: state.mailbox.number,
    sendNotification: formValues.notificationsEnabled ?? false,
    emailAddresses: formValues.notificationEmail ?? [],
    smsNumbers: formValues.notificationSMS ?? [],
    pin: formValues.pin ? parseInt(formValues.pin) : undefined,
    sharedAccess: formValues.isShared ?? false,
    playMessageDate: formValues.playMessageDateEnabled ?? false,
    extensionNumber:
      formValues.extension && formValues.extension !== 'none' ? parseInt(formValues.extension) : undefined,
  };
};

export const createSubmitPayload = ({
  tenantId,
  formValues,
}: {
  tenantId: string;
  formValues: FormValues<typeof schema>;
}): VoicemailBoxCreateRequest => {
  return {
    tenantId,
    locationIds: formValues.locationIds ?? [],
    name: formValues.name ?? '',
    sendNotification: formValues.notificationsEnabled ?? false,
    emailAddresses: formValues.notificationEmail ?? [],
    smsNumbers: formValues.notificationSMS ?? [],
    pin: formValues.pin ? parseInt(formValues.pin) : undefined,
    sharedAccess: formValues.isShared ?? false,
    playMessageDate: formValues.playMessageDateEnabled ?? false,
    extensionNumber:
      formValues.extension && formValues.extension !== 'none' ? parseInt(formValues.extension) : undefined,
  };
};

export type SchemaForm = UseFormReturnType<typeof schema, keyof typeof schema>;
export type SchemaFormValues = FormValues<typeof schema>;
