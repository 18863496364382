import { useCallback } from 'react';
import { useSoftphoneCallState } from '..';
import { toggleLocalSenderTracks } from '../../../utils/stream-utils';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';

export const useToggleMute = () => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const { emit } = useSoftphoneEventsEmitter();
  const incomingCalls = useSoftphoneCallState((ctx) => ctx.incomingCalls);
  const outgoingCalls = useSoftphoneCallState((ctx) => ctx.outgoingCalls);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);

  return useCallback(
    (muted: boolean) => {
      if (!client) {
        throw new Error('No Client');
      }

      incomingCalls.forEach((call) => {
        toggleLocalSenderTracks(client, call.invitation, !muted);
      });
      outgoingCalls.forEach((call) => {
        toggleLocalSenderTracks(client, call.inviter, !muted);
      });
      establishedCalls.forEach((call) => {
        toggleLocalSenderTracks(client, call.session, !muted);
      });

      setIsMuted(muted);
      if (muted) {
        emit('settings.mute', null);
      } else {
        emit('settings.unmute', null);
      }
      return muted;
    },
    [client, incomingCalls, outgoingCalls, establishedCalls, setIsMuted]
  );
};
