import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Modal, useModalControl } from '@frontend/design-system';
import { MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatSelector } from '../../../providers/team-chat.provider';
import { MemberSelection } from './member-selection';
import { NameSelection } from './name-selection';

const CREATE_CHANNEL_MODAL_WIDTH = 500;

type Props = {
  modalProps: ReturnType<typeof useModalControl>['modalProps'];
};
export const NewConversationModal = ({ modalProps }: Props) => {
  const { t } = useTranslation('team-chat');
  const [currentStep, setCurrentStep] = useState<'name-selection' | 'members-selection'>('name-selection');
  const createConversation = useTeamChatSelector((ctx) => ctx.createConversation);

  const [name, setName] = useState('');
  const [memberIds, setMemberIds] = useState<string[]>([]);

  const updateName = (name: string) => {
    setName(name);
    setCurrentStep('members-selection');
  };

  const updateMembers = (members: string[]) => {
    setMemberIds(members);
  };

  const submitConversation = async () => {
    createConversation({
      name,
      type: 'group',
      memberIds: memberIds,
    });
    modalProps.onClose();
  };

  return (
    <Modal
      {...modalProps}
      minWidth={currentStep === 'name-selection' ? CREATE_CHANNEL_MODAL_WIDTH : MIN_MODAL_WIDTH}
      disableCloseOnOverlayClick
      disableCloseOnEscape
    >
      <Modal.Header
        onClose={() => {
          setCurrentStep('name-selection');
          modalProps.onClose();
        }}
        closeBtnTrackingId='team-chat-2.0-new-channel-close-modal-button'
      >
        {t('Create a channel')}
      </Modal.Header>
      <Modal.Body css={css({ padding: '0 !important' })}>
        {currentStep === 'name-selection' && <NameSelection name={name} onSubmit={updateName} />}
        {currentStep === 'members-selection' && (
          <MemberSelection
            name={name}
            memberIds={memberIds}
            onCancel={() => setCurrentStep('name-selection')}
            onChange={updateMembers}
            onSubmit={submitConversation}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
