import { useMemo } from 'react';
import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { Provider, Workstation } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { useAppScopeStore } from '@frontend/scope';
import { TagType } from '@frontend/design-system';
import { getRequestTags } from '../utils';
import {
  ReducedAppointmentType,
  ReducedOperatory,
  ReducedPractitioner,
  ReviewRequestReturnType,
  useReviewRequest,
} from './useReviewRequest';
import { useSyncAppBySource } from './useSyncAppBySource';

type UseManageScheduleRequestModalDetailsReturnType = {
  tags: TagType[];
  syncAppDataSources: DataSourcesTypes.DataSource[];
  access: DataSourcesTypes.AccessDataResponse;
  isSyncAppDetailsLoading: boolean;
  appointmentTypes: AppointmentTypesTypes.AppointmentType[] | ReducedAppointmentType[];
  providers: Provider[] | ReducedPractitioner[];
  workstations: Workstation[] | ReducedOperatory[];
  isLoading: boolean;
  selectedAppointmentDetails: ReviewRequestReturnType['selected'];
};

export const useManageScheduleRequestModalDetails = (
  selectedScheduleRequest: ScheduleRequest
): UseManageScheduleRequestModalDetailsReturnType => {
  const locationId = selectedScheduleRequest?.locationId ?? '';

  const { getLocationName, accessibleLocationData } = useAppScopeStore();

  const parentId = accessibleLocationData?.[locationId]?.groupId ?? '';

  const { lists, isLoading, selected: selectedAppointmentDetails } = useReviewRequest(selectedScheduleRequest);
  const { access, syncAppDataSources, isLoading: isSyncAppDetailsLoading } = useSyncAppBySource(locationId, parentId);

  const { list: tags } = getRequestTags(
    selectedScheduleRequest?.dateTime ?? '',
    selectedScheduleRequest?.schedulee,
    getLocationName(locationId)
  );

  const filterDataSources = useMemo(
    () => syncAppDataSources?.filter((syncApp) => syncApp?.SourceType !== 'CSV') || [],
    [syncAppDataSources]
  );

  return {
    syncAppDataSources: filterDataSources,
    tags,
    access,
    isSyncAppDetailsLoading,
    isLoading,
    selectedAppointmentDetails,
    appointmentTypes: lists.appointmentTypes,
    providers: lists.providers,
    workstations: lists.workstations,
  };
};
