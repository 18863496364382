import { useEffect } from 'react';
import { MultiStepModalControl } from '@frontend/design-system';
import { isPaymentCollectStep } from '../utils/steps';

/**
 * Utility hook.
 * Returns props to disable a modal closeOnEscape/CloseOnOverlayClick
 * when on a payment collection step.
 *
 * Also, hides the multi-step's cancel action for collection steps.
 */
export const useSyncCollectPaymentMultiToModal = ({
  activeStep,
  setShowCancelAction,
}: {
  activeStep: MultiStepModalControl['activeStep'];
  setShowCancelAction: MultiStepModalControl['setShowCancelAction'];
}) => {
  const isCollectionStep = isPaymentCollectStep(activeStep);

  useEffect(() => {
    setShowCancelAction(!isCollectionStep);
  }, [isCollectionStep]);

  return {
    disableCloseOnEscape: isCollectionStep,
    disableCloseOnOverlayClick: isCollectionStep,
    isCollectionStep,
  };
};
