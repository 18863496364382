import dayjs from 'dayjs';
import { demoDataUtils } from '../../../../utils';
import { NoShowRateLocationWiseResponse, NoShowRateTimelineResponse, OverviewResponse } from '../hooks';

const subscribedTrend = [0.97, 0.99, 0.98, 0, 0.85, 0.94];
const unsubscribedTrend = [0.5, 0.6, 0.55, 0.26, 0.65, 0.75];

const last6Months = Array.from({ length: 6 }, (_, i) => i)
  .map((i) => {
    return dayjs()
      .subtract(i + 1, 'month')
      .format('MMM');
  })
  .reverse();

export const generateOverviewDemoData = (locationIds: string[]): OverviewResponse => {
  return locationIds.map((locationId) => ({
    location_id: locationId,
    appointment_reminders_sent_patients: demoDataUtils.generateRandomInt(100, 1200),
    appointment_reminders_sent: demoDataUtils.generateRandomInt(1200, 1500),
    appointments_completed_patients: demoDataUtils.generateRandomInt(800, 1000),
    appointments_completed: demoDataUtils.generateRandomInt(1000, 1200),
    appointments_confirmed_patients: demoDataUtils.generateRandomInt(1000, 1200),
    appointments_confirmed: demoDataUtils.generateRandomInt(1000, 1200),
    total_appointments_completed: demoDataUtils.generateRandomInt(1200, 1600),
  }));
};

export const generateNoShowRateTimelineDemoData = (): NoShowRateTimelineResponse => {
  return {
    dates: last6Months.map((month, index) => {
      return {
        key: month,
        subscribed: subscribedTrend[index],
        unsubscribed: unsubscribedTrend[index],
      };
    }),
    rate_difference: -0.2,
  };
};

export const generateNoShowRateLocationWiseDemoData = (locationIds: string[]): NoShowRateLocationWiseResponse => {
  return {
    locations: locationIds.map((locationId) => ({
      location_id: locationId,
      no_show_rate: demoDataUtils.generateRandomFloat(),
    })),
    unsubscribed: 0.7,
  };
};
