import { useEffect } from 'react';
import { useMerchant, useMerchantsInfo } from '@frontend/payments-hooks';
import { CreateAndTermsConditionModal } from '@frontend/payments-setup';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useModalControl } from '@frontend/design-system';
import { MultiStepModal, MultiStepModalProps } from './multi-step-modal';

export const PaymentsMultiStepModal = ({ children, modalProps, closeModal, ...rest }: MultiStepModalProps) => {
  const { show } = modalProps;

  const { navigate } = useSettingsNavigate();
  const { stripeCreatedNotOnboarded } = useMerchant();
  const { allMerchantsLoaded, anyLocationHasPayments } = useMerchantsInfo();
  const { modalProps: paymentSetupModalProps, openModal: openPaymentSetupModal } = useModalControl();

  useEffect(() => {
    if (!show || !allMerchantsLoaded) return;

    if (!anyLocationHasPayments) {
      if (!stripeCreatedNotOnboarded) {
        navigate({ to: '/payments/account-info' });
      } else {
        openPaymentSetupModal();
      }
      closeModal();
    }
  }, [show, allMerchantsLoaded, anyLocationHasPayments, stripeCreatedNotOnboarded]);

  return (
    <>
      {allMerchantsLoaded && anyLocationHasPayments ? (
        <MultiStepModal modalProps={modalProps} closeModal={closeModal} {...rest}>
          {children}
        </MultiStepModal>
      ) : (
        <CreateAndTermsConditionModal businessType='' creationCheckModalProps={paymentSetupModalProps} />
      )}
    </>
  );
};
