import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'motion/react';
import { Slider, Unit } from '@frontend/components';
import { Trans, useTranslation } from '@frontend/i18n';
import { useAppScopeStore, WeaveLocationGroup } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  Heading,
  IconButton,
  InfoRoundIconSmall,
  Modal,
  ModalControlModalProps,
  RadioCardField,
  Table,
  Text,
  useModalControl,
  PrimaryButton,
  useAlert,
  EditSimpleIcon,
  XIcon,
  Chip,
  ChecklistMenuField,
  CheckboxField,
} from '@frontend/design-system';
import { trackingId } from '../../../tracking';
import { AudioPicker } from '../../audio-picker/audio-picker';
import { SettingsCard } from '../../common/settings-card';
import { WeaveHelpLink } from '../../weave-support-link';
import { AssignDeviceModal, useAssignDeviceAPI } from './assign-device';
import {
  FIRST_ANNOUNCEMENT_DELAY_OPTIONS,
  REPEAT_INTERVAL_OPTIONS,
  SchemaForm,
  createSubmitPayload,
  populateSchema,
} from './schema';
import { CallQueueState, CallQueueDevice, CallQueueRingType } from './types';
import { useCallQueueMutations } from './use-call-queue-mutations';

export const CallQueuePage = ({
  data,
  tenantLocation,
  form,
}: {
  data: CallQueueState;
  tenantLocation?: WeaveLocationGroup;
  form: SchemaForm;
}) => {
  const { t } = useTranslation('phone');

  if (!tenantLocation) {
    return (
      <SettingsCard title=''>
        <div>{t('No Phone Tenant Selected')}</div>
      </SettingsCard>
    );
  }

  return <CallQueueContent key={data.callQueueId} data={data} tenantLocation={tenantLocation} form={form} />;
};

const CallQueueContent = ({
  data,
  tenantLocation,
  form,
}: {
  data: CallQueueState;
  tenantLocation: WeaveLocationGroup;
  form: SchemaForm;
}) => {
  const { t } = useTranslation('phone');
  const [callQueue, setCallQueue] = useState<CallQueueState>(() => data);

  const alerts = useAlert();
  const tenantId = tenantLocation.phoneTenantId;

  const isUnify = tenantLocation.locationType === 'unify';

  const { update } = useCallQueueMutations(tenantLocation.phoneTenantId ?? '');

  const locationOptions = useMemo(() => {
    return (
      tenantLocation.children?.map((location) => ({
        label: location.name ?? '',
        value: location.locationId ?? '',
      })) ?? []
    );
  }, [tenantLocation]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <AssignDeviceCard
        callQueueId={callQueue.callQueueId}
        assignedDevices={callQueue.devices}
        setState={setCallQueue}
        tenantLocation={tenantLocation}
      />
      <RoutingCard form={form} />
      <CallerExperienceCard form={form} tenantId={tenantId ?? ''} />
      {isUnify && locationOptions.length > 0 ? <LocationsCard form={form} options={locationOptions} /> : null}
      <PrimaryButton
        size='large'
        css={{ width: 'fit-content' }}
        onClick={() => {
          if (!tenantLocation.phoneTenantId) {
            return;
          }

          const finalState = createSubmitPayload({
            state: callQueue,
            formValues: form.values,
          });
          update.mutate(finalState, {
            onSuccess: () => {
              alerts.success(t('Call Queue updated successfully.'));
            },
            onError: () => {
              alerts.error(t('System failed to update Call Queue. Please try again.'));
            },
          });

          const newFields = populateSchema(finalState);

          form.reset(newFields);
        }}
        disabled={!form.changedValues}
        trackingId={trackingId({ context: 'setting', feature: 'call-queues', details: 'save' })}
      >
        {t('Save Changes')}
      </PrimaryButton>
    </div>
  );
};

export const AssignDeviceCard = ({
  callQueueId,
  assignedDevices,
  tenantLocation,
  setState,
}: {
  callQueueId: string;
  assignedDevices: CallQueueDevice[];
  tenantLocation: WeaveLocationGroup;
  setState: Dispatch<SetStateAction<CallQueueState>>;
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps, openModal } = useModalControl();
  const { navigate } = useSettingsNavigate();
  const { mutation } = useAssignDeviceAPI(tenantLocation);
  const { getScopeName } = useAppScopeStore();
  const isUnify = tenantLocation && tenantLocation.locationType === 'unify';

  const updateDevices = (devices: CallQueueDevice[]) => {
    setState((state) => ({ ...state, devices: devices }));
  };

  return (
    <SettingsCard
      title={t('Assigned Devices')}
      action={{
        label: t('Assign Device'),
        onClick: () => openModal(),
        ref: triggerProps.ref,
        trackingId: trackingId({ context: 'setting', feature: 'call-queues', details: 'assign-devices' }),
      }}
    >
      <Table
        isPaginated
        fullHeight
        fullHeightConfig={{
          minHeight: 200,
        }}
        data={assignedDevices}
        colConfig={[
          { id: 'name', accessor: 'name', Header: t('Assigned Device'), sortType: 'string' },
          { id: 'macAddress', accessor: 'macAddress', Header: t('MAC Address') },
          {
            id: 'labels',
            Header: t('Locations'),
            accessor: (data) => {
              const locationIds = data.locationIds;
              if (!locationIds) {
                return null;
              }
              if (locationIds.length === 1) {
                return { label: getScopeName(locationIds[0]), type: 'single' };
              } else if (locationIds.length > 1) {
                return { label: t('{{count}} locations', { count: locationIds.length }), type: 'multi' };
              } else {
                return { label: '--', type: 'none' };
              }
            },
            cellRenderer: (data: { label: string; type: 'multi' | 'single' | 'none' }) => {
              if (data.type === 'none') {
                return data.label;
              }
              return data.type === 'multi' ? (
                <Chip.MultiChip>{data.label}</Chip.MultiChip>
              ) : (
                <Chip.SingleChip>{data.label}</Chip.SingleChip>
              );
            },
            width: 150,
            omit: !isUnify,
            sortType: (a, b) => {
              return a.values.labels.label.localeCompare(b.values.labels.label);
            },
          },
        ]}
        rowActions={{
          actions: [
            {
              label: t('Edit Device'),
              Icon: EditSimpleIcon,
              onClick: (data) => {
                if (data.deviceId) {
                  navigate({ to: '/phone/devices/:id', params: { id: data.deviceId } });
                }
              },
            },
            {
              label: t('Remove Device'),
              Icon: XIcon,
              onClick: (data) => {
                mutation.mutate(
                  {
                    callQueueId,
                    devices: assignedDevices.filter((device) => device.sipProfileId !== data.sipProfileId),
                  },
                  {
                    onSuccess: (_data, payload) => {
                      updateDevices(payload.devices);
                    },
                  }
                );
              },
            },
          ],
        }}
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No Assigned Devices'),
          description: () => (
            <Trans t={t}>
              <Text textAlign='center'>You don't have any devices assigned yet. </Text>
              <Text textAlign='center'>
                Click{' '}
                <Text as='span' weight='bold'>
                  Assign Device
                </Text>{' '}
                to get started.
              </Text>
            </Trans>
          ),
        }}
      />
      <AssignDeviceModal
        callQueueId={callQueueId}
        modalProps={modalProps}
        assignedDevices={assignedDevices}
        updateDevices={updateDevices}
        tenantLocation={tenantLocation}
      />
    </SettingsCard>
  );
};

export const RoutingCard = ({ form, condensed }: { form: SchemaForm; condensed?: boolean }) => {
  const { t } = useTranslation('phone');

  return (
    <SettingsCard title={t('Routing')} condensed={condensed}>
      <SettingsSection css={{ width: 'auto' }}>
        <SectionHeading condensed={condensed}>{t('Routing Options')}</SectionHeading>
        <RadioCardField {...form.getFieldProps('deviceRingType')} name='deviceRingType'>
          <RadioCardField.Option
            css={{ maxWidth: 240 }}
            value={CallQueueRingType.RING_TYPE_RING_ALL}
            title={t('Simultaneous')}
            description={t('All available devices will ring at the same time.')}
          />
          <RadioCardField.Option
            css={{ maxWidth: 240 }}
            value={CallQueueRingType.RING_TYPE_LONGEST_IDLE_AGENT}
            title={t('Longest Idle')}
            description={t("Ring the device that's been idle for the longest time.")}
          />
        </RadioCardField>
      </SettingsSection>
    </SettingsCard>
  );
};

const SECTION_WIDTH = 320;
const SCRUBBER_WIDTH = 250;

const checkboxDiv = css`
  display: grid;
  grid-template-areas: 'checkbox-row checkbox-row checkbox-row' '. description description';
  grid-template-columns: 0.2fr 1fr 1fr;
`;
const checkboxRow = css`
  grid-area: checkbox-row;
`;
const description = css`
  grid-area: description;
`;

export const CallerExperienceCard = ({
  form,
  tenantId,
  condensed,
}: {
  form: SchemaForm;
  tenantId: string;
  condensed?: boolean;
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps } = useModalControl();

  const greetingField = form.getFieldProps('greetingEnabled');
  const greetingMedia = form.getFieldProps('greetingMediaId');
  const escapeField = form.getFieldProps('allowExit');
  const holdMusicMedia = form.getFieldProps('holdMusicId');
  const holdMusicSystemMedia = form.getFieldProps('holdMusicSystemMedia');
  const positionAnnouncementField = form.getFieldProps('positionAnnouncementEnabled');
  const initialAnnouncementField = form.getFieldProps('firstAnnouncementDelayMs');
  const announcementIntervalField = form.getFieldProps('repeatIntervalMs');
  const maxHoldTime = form.getFieldProps('maxWaitTimeMs');
  const sliderValue = useMemo(() => [parseInt(maxHoldTime.value)], [maxHoldTime.value]);

  return (
    <SettingsCard title={t('Caller Experience')} condensed={condensed}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <SettingsSection>
          <div css={checkboxDiv}>
            <CheckboxField
              {...greetingField}
              css={checkboxRow}
              name='greetingEnabled'
              label={
                <SectionHeading condensed={condensed}>
                  <div style={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
                    {t('Add Greeting')}
                    <IconButton size='xsmall' label={t('Greeting Help')} {...triggerProps}>
                      <InfoRoundIconSmall />
                    </IconButton>
                    <GreetingHelpModal modalProps={modalProps} />
                  </div>
                </SectionHeading>
              }
              labelPlacement='right'
            />
            <Text size='medium' color='light' css={description}>
              {t('Add an optional greeting to play before the caller enters the queue.')}
            </Text>
          </div>
          <AnimatePresence>
            {greetingField.value && (
              <motion.div variants={variants} animate='open' initial='closed' exit='closed'>
                <AudioPicker
                  widths={{ field: SECTION_WIDTH, scrubber: SCRUBBER_WIDTH }}
                  allowedOptions={{
                    add: true,
                    custom: true,
                    standard: false,
                  }}
                  field={greetingMedia}
                  name='greetingMediaId'
                  labels={{
                    placeholder: t('Select Greeting'),
                  }}
                  tenantId={tenantId}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </SettingsSection>

        <SettingsSection>
          <div css={checkboxDiv}>
            <CheckboxField
              {...escapeField}
              css={checkboxRow}
              name='allowExit'
              label={<SectionHeading condensed={condensed}>{t('Add Escape Option')}</SectionHeading>}
              labelPlacement='right'
            />
            <Text size='medium' color='light' css={description}>
              {t('Allow callers to press 0 to escape the queue and go to your fallback option.')}
            </Text>
          </div>
        </SettingsSection>

        <SettingsSection css={{ width: 'auto' }}>
          <div style={{ width: SECTION_WIDTH }}>
            <SectionHeading condensed={condensed}>{t('Hold Music')}</SectionHeading>
            <Text size='medium' color='light'>
              {t('Play an audio file while callers wait for their call to be answered.')}
            </Text>
          </div>
          <AudioPicker
            widths={{ field: SECTION_WIDTH, scrubber: SCRUBBER_WIDTH }}
            allowedOptions={{ add: true, custom: true, standard: true }}
            field={holdMusicMedia}
            name='holdMusicId'
            labels={{ placeholder: t('Select Hold Music') }}
            tenantId={tenantId}
          />
          <input hidden type='checkbox' readOnly checked={holdMusicSystemMedia.value} />
        </SettingsSection>

        <SettingsSection>
          <div css={checkboxDiv}>
            <CheckboxField
              {...positionAnnouncementField}
              css={checkboxRow}
              name='positionAnnouncementEnabled'
              label={<SectionHeading condensed={condensed}>{t('Add Position Announcement')}</SectionHeading>}
              labelPlacement='right'
            />
            <Text size='medium' color='light' css={description}>
              {t('Play a message that informs the caller of their position in the call queue.')}
            </Text>
          </div>
          <AnimatePresence>
            {positionAnnouncementField.value && (
              <motion.div
                variants={variants}
                animate='open'
                initial='closed'
                exit='closed'
                style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}
              >
                <DropdownField
                  {...initialAnnouncementField}
                  name='firstAnnouncementDelayMs'
                  label={t('Initial Announcement')}
                >
                  {FIRST_ANNOUNCEMENT_DELAY_OPTIONS.map((option) => (
                    <DropdownField.Option key={option.value} value={option.value}>
                      {option.label}
                    </DropdownField.Option>
                  ))}
                </DropdownField>
                <DropdownField {...announcementIntervalField} name='repeatIntervalMs' label={t('Repeating every')}>
                  {REPEAT_INTERVAL_OPTIONS.map((option) => (
                    <DropdownField.Option key={option.value} value={option.value}>
                      {option.label}
                    </DropdownField.Option>
                  ))}
                </DropdownField>
              </motion.div>
            )}
          </AnimatePresence>
        </SettingsSection>

        <SettingsSection>
          <div>
            <SectionHeading condensed={condensed}>{t('Maximum Caller Hold Time')}</SectionHeading>
            <Text size='medium' color='light'>
              {t('The maximum time the caller will be in the call queue.')}
            </Text>
          </div>
          <div style={{ padding: theme.spacing(2) }}>
            <Slider
              max={60}
              min={1}
              onChange={(values) => {
                maxHoldTime.onChange({ name: 'maxWaitTimeMs', value: values[0].toString() });
              }}
              step={1}
              values={sliderValue}
              units={Unit.Minutes}
            />
          </div>
        </SettingsSection>
      </div>
    </SettingsCard>
  );
};

const SettingsSection = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <section
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: 320,
      }}
      className={className}
    >
      {children}
    </section>
  );
};

const SectionHeading = ({ condensed, children }: { children: ReactNode; condensed?: boolean }) => {
  return condensed ? <Text weight='bold'>{children}</Text> : <Heading level={3}>{children}</Heading>;
};

const variants = {
  open: {
    height: 'auto',
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      opacity: { duration: 0.3 },
      height: { duration: 0.1 },
    },
  },
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      ease: 'easeInOut',
      opacity: { duration: 0.15 },
      height: { duration: 0.3 },
    },
  },
};

const GreetingHelpModal = ({ modalProps }: { modalProps: ModalControlModalProps }) => {
  const { t } = useTranslation('phone');

  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>{t('Greeting Help')}</Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Trans t={t}>
          <Text>This optional audio file will be the first thing the caller hears before entering the queue.</Text>
          <Text>
            The greeting is often used to welcome your callers, show them appreciation for calling, and inform them of
            any escape options they may choose while in the queue.
          </Text>
          <Text>At this time, the only escape option will be 0.</Text>
          <div>
            <Text weight='bold'>{t('Example')}</Text>

            <Text>
              "Thank you for calling our dental office. We appreciate your call and will be with you shortly. To escape
              the queue, please press 0."
            </Text>
          </div>
          <Text>
            For more help on uploading a call queue greeting, visit <WeaveHelpLink />.
          </Text>
        </Trans>
      </Modal.Body>
      <Modal.Actions primaryLabel={t('Done')} onPrimaryClick={modalProps.onClose} />
    </Modal>
  );
};

export const LocationsCard = ({ form, options }: { form: SchemaForm; options: { label: string; value: string }[] }) => {
  const { t } = useTranslation('phone');

  return (
    <SettingsCard
      title={t('Locations (Optional)')}
      description={t("Select the locations whose users can access this queue's real-time data.")}
      condensed
    >
      <SettingsSection>
        <div data-tracking-id={trackingId({ context: 'setting', feature: 'call-queues', details: 'location-select' })}>
          <ChecklistMenuField
            {...form.getFieldProps('locationIds')}
            label={t('Locations')}
            name='locationIds'
            helperText={t('Selecting none will auto-label all locations to this Call Queue.')}
          >
            {options.map((option) => (
              <ChecklistMenuField.Option key={option.value} value={option.value}>
                {option.label}
              </ChecklistMenuField.Option>
            ))}
          </ChecklistMenuField>
        </div>
      </SettingsSection>
    </SettingsCard>
  );
};
