import { useQuery } from 'react-query';
import { getOfficeHoursExceptionsForMultipleLocations } from './api';
import { queryKeys } from './query-keys';
import { GetOfficeHoursExceptionsForMultipleLocationsType } from './types';

export const useGetOfficeExceptions = (req: GetOfficeHoursExceptionsForMultipleLocationsType['input']) => {
  return useQuery({
    queryKey: queryKeys.officeExceptions(req),
    queryFn: () =>
      getOfficeHoursExceptionsForMultipleLocations(req).catch((err) => {
        if (err.message.includes('Maximum number of location IDs allowed')) {
          console.error('Error fetching office exceptions', err);
          return { data: [] };
        } else {
          throw err;
        }
      }),
    enabled: !!req.locationIds?.length,
  });
};
