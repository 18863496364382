import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { WeaveLocation } from '@frontend/scope';
import { useAppFlagStore } from '@frontend/shared';

export const useHasPhoneSystemAccess = ({ locationIds }: { locationIds: string[] }) => {
  const { getCustomizationFlagValue } = useAppFlagStore();

  return getCustomizationFlagValue({ flagName: 'phonehomeicon', locationIds });
};

export const useHasPhoneSettingsAccess = ({ selectedLocations }: { selectedLocations: WeaveLocation[] }) => {
  const { getCustomizationFlagValue } = useAppFlagStore();
  const hasPhonesCustomizationFlag = getCustomizationFlagValue({
    flagName: 'phonehomeicon',
    locationIds: selectedLocations.map((loc) => loc.id),
  });

  const candidateLocation =
    selectedLocations.length > 0 ? selectedLocations[0] : { locationType: '', id: '', groupId: '' };
  const locationIds = selectedLocations.map((location) => location.id);

  const hasPhoneFullAccessAcl =
    candidateLocation.locationType === 'unify'
      ? hasSchemaACL(candidateLocation.groupId, Permission.PHONE_FULL_ACCESS)
      : hasSchemaACL(candidateLocation.id, Permission.PHONE_FULL_ACCESS);
  const hasFullPhoneAccess = hasPhoneFullAccessAcl && hasPhonesCustomizationFlag;

  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: locationIds ?? [],
  });

  const { aggregateValue: callRoutesFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'call-routes-settings',
    locationIds: locationIds ?? [],
  });

  if (!selectedLocations.length) {
    return {
      hasPhoneFullAccessAcl: false,
      hasPhonesCustomizationFlag: false,
      hasFullPhoneAccess: false,
      hasMainLineAccess: false,
      hasDepartmentsAccess: false,
      hasOverridesAccess: false,
      hasOfficeHoursAccess: false,
      hasCallGroupsAccess: false,
      hasCallQueuesAccess: false,
      hasCallRoutesAccess: false,
      hasCallRouteDetailsAccess: false,
      hasCallRoutesAccessNoFeatureFlag: false,
      hasPhoneTreesAccess: false,
      hasVoicemailBoxesAccess: false,
      hasAudioLibraryAccess: false,
      hasCallBlockingAccess: false,
      hasCallRecordingAccess: false,
      hasHoldMusicAccess: false,
      hasDeviceSettingsAccess: false,
      hasE911AddressesAccess: false,
      hasContactDirectoriesAccess: false,
      hasLineKeysAccess: false,
    };
  }

  const phoneAccess = {
    hasPhoneFullAccessAcl,
    hasPhonesCustomizationFlag,
    hasFullPhoneAccess,
    // Below are the route specific access checks for phone settings routes
    hasMainLineAccess: departmentsFlag && hasPhoneFullAccessAcl,
    hasDepartmentsAccess: departmentsFlag && hasPhoneFullAccessAcl,
    hasOverridesAccess: hasPhonesCustomizationFlag,
    hasOfficeHoursAccess: true,
    hasCallGroupsAccess: hasFullPhoneAccess,
    hasCallQueuesAccess: hasFullPhoneAccess,
    hasCallRoutesAccess: callRoutesFlag && hasFullPhoneAccess,
    hasCallRouteDetailsAccess: callRoutesFlag && hasFullPhoneAccess,
    // hasCallRoutesAccessNoFeatureFlag is for a temporary workaround to allow visiting the page through URL without the feature flag
    hasCallRoutesAccessNoFeatureFlag: hasFullPhoneAccess,
    hasPhoneTreesAccess: hasFullPhoneAccess,
    hasVoicemailBoxesAccess: hasPhonesCustomizationFlag,
    hasAudioLibraryAccess: hasPhonesCustomizationFlag,
    hasCallBlockingAccess: hasFullPhoneAccess,
    hasCallRecordingAccess: hasFullPhoneAccess,
    hasHoldMusicAccess: hasFullPhoneAccess,
    hasDeviceSettingsAccess: hasFullPhoneAccess,
    hasE911AddressesAccess: hasFullPhoneAccess,
    hasContactDirectoriesAccess: hasFullPhoneAccess,
    hasLineKeysAccess: hasFullPhoneAccess,
  };

  return phoneAccess;
};
