import { useEffect, useMemo, useRef, useState } from 'react';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { BulkMessagesQueries, MessagesTypes } from '@frontend/api-messaging';
import { MAX_BATCH_RECIPIENTS } from '../../../utils';

const getUnselectedRecipients = (allRecipients: Recipient[], selectedRecipients: Recipient[]) =>
  allRecipients.filter((recipient) => !selectedRecipients.find((selected) => selected.personId === recipient.personId));

export const useSelectAllRecipients = (
  updatedSelectedRecipients: Recipient[],
  setUpdatedSelectedRecipients: (recipients: Recipient[]) => void,
  quota: number,
  filters: MessagesTypes.Filters,
  searchToken?: string
) => {
  const listRecipientsResponse = BulkMessagesQueries.useListRecipients(filters, MAX_BATCH_RECIPIENTS, searchToken);
  const { data, hasNextPage, fetchNextPage, isFetching } = listRecipientsResponse;
  const [selectAllRecipients, setSelectAllRecipients] = useState(false);
  const abortControllerRef = useRef<AbortController>();

  const startSelectAllRecipients = async () => {
    if (selectAllRecipients || abortControllerRef.current) return;
    setSelectAllRecipients(true);
    abortControllerRef.current = new AbortController();
  };

  const endSelectAllRecipients = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = undefined;
    }
    setSelectAllRecipients(false);
  };

  useEffect(() => {
    if (!selectAllRecipients) return;

    if (hasNextPage && !isFetching) {
      fetchNextPage();
      return;
    }

    if (!hasNextPage && !isFetching) {
      const allRecipients = data?.pages.map((page) => page.rows ?? []).flat() ?? [];
      const unselectedRecipients = getUnselectedRecipients(allRecipients, updatedSelectedRecipients);
      setUpdatedSelectedRecipients(
        [...updatedSelectedRecipients, ...unselectedRecipients].slice(0, Math.min(quota, MAX_BATCH_RECIPIENTS))
      );
      endSelectAllRecipients();
    }
  }, [
    selectAllRecipients,
    hasNextPage,
    isFetching,
    data,
    updatedSelectedRecipients,
    quota,
    fetchNextPage,
    endSelectAllRecipients,
  ]);

  const infiniteQueryProps = useMemo(() => {
    const pages = data?.pages ?? [];
    const recipients = pages.map((page) => page.rows ?? []).flat();
    const displayedRecipients = getUnselectedRecipients(recipients, updatedSelectedRecipients);

    return {
      ...listRecipientsResponse,
      data: { ...data, pages: [{ rows: displayedRecipients }] },
    } as typeof listRecipientsResponse;
  }, [listRecipientsResponse, updatedSelectedRecipients, data]);

  return {
    selectAllRecipients: startSelectAllRecipients,
    abortSelectAllRecipients: endSelectAllRecipients,
    isSelectAllLoading: selectAllRecipients,
    infiniteQueryProps,
  };
};
