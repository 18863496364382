import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL, isWeaveUser } from '@frontend/auth-helpers';
import { useMultiQueryUtils } from './use-multi-query-utils';

export const usePaymentsBillingManagerLocations = ({ locationId }: { locationId: string }) => {
  const { locationIds } = useMultiQueryUtils();
  const paymentsBillingManagerLocations = locationIds.filter((location) =>
    hasSchemaACL(location, [
      Permission.BILLING_INFORMATION_READ,
      Permission.BILLING_INFORMATION_WRITE,
      Permission.PAYMENTS_REFUNDS,
      Permission.PAYMENTS_EXPORTS,
      Permission.PAYMENTS_EXPRESS_DASHBOARD,
    ])
  );

  const isPaymentsBillingManager = paymentsBillingManagerLocations.includes(locationId) || isWeaveUser();

  return { paymentsBillingManagerLocations, isPaymentsBillingManager };
};
