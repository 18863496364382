import { useEffect, useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { useAppScopeStore } from '@frontend/scope';

type Params = {
  demoLocations?: AnalyticsCommonTypes.StringRecord;
  onLoadLocations?: (locations: AnalyticsCommonTypes.StringRecord) => void;
};

type Response = {
  isLoading?: boolean;
  isMultiLocation?: boolean;
  locations: AnalyticsCommonTypes.StringRecord;
};

const adjustDemoLocations = (locations: AnalyticsCommonTypes.StringRecord, isWAMMultiLocation?: boolean) => {
  if (!isWAMMultiLocation) {
    const [firstKey, firstValue] = Object.entries(locations)?.[0] || {};
    return { [firstKey]: firstValue };
  }

  return locations || {};
};

export const useCallIntelLocations = (options?: Params): Response => {
  const { demoLocations, onLoadLocations } = options || {};
  const { getScopeName, selectedLocationIds, isSingleTypeScope } = useAppScopeStore();
  const isMultiLocation = !isSingleTypeScope;

  // Fetch location-wise customization flags
  const { data: locationWiseCustomizationFlags, isLoading } =
    CustomizationFlagQueries.useMultiCustomizationFlags(selectedLocationIds);

  // Get active call-intel locations
  const activeLocations: AnalyticsCommonTypes.StringRecord = useMemo(() => {
    if (!locationWiseCustomizationFlags?.locationsFeatures) {
      return {};
    }

    return Object.keys(locationWiseCustomizationFlags.locationsFeatures ?? {})
      .filter((locationId) =>
        locationWiseCustomizationFlags.locationsFeatures?.[locationId]?.features?.some(
          (feature) =>
            feature.featureEnum === Feature.CALL_INTELLIGENCE &&
            feature.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE
        )
      )
      .reduce((acc: AnalyticsCommonTypes.StringRecord, locationId) => {
        acc[locationId] = getScopeName(locationId);
        return acc;
      }, {});
  }, [locationWiseCustomizationFlags]);

  const locations: AnalyticsCommonTypes.StringRecord = useMemo(() => {
    const receivedLocations: AnalyticsCommonTypes.StringRecord = demoLocations
      ? adjustDemoLocations(demoLocations, isMultiLocation)
      : activeLocations || {};

    const sortedLocationsByName = Object.entries(receivedLocations).sort(([, a], [, b]) => a.localeCompare(b));

    return Object.fromEntries(sortedLocationsByName);
  }, [activeLocations, demoLocations]);

  useEffect(() => {
    if (locations && onLoadLocations) {
      onLoadLocations(locations);
    }
  }, [locations]);

  return {
    isLoading,
    isMultiLocation,
    locations,
  };
};
