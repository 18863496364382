import { css } from '@emotion/react';
import { motion, type AnimationScope } from 'motion/react';
import { theme } from '@frontend/theme';

export type ProgressBarProps = {
  scope: AnimationScope<any>;
};

export const NotificationProgressBar = ({ scope }: ProgressBarProps) => (
  <div
    css={css`
      bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      border-bottom-left-radius: ${theme.borderRadius.medium};
      border-bottom-right-radius: ${theme.borderRadius.medium};
      overflow: hidden;
      height: 4px;
      z-index: 50;
    `}
  >
    <motion.div
      ref={scope}
      css={css`
        position: absolute;
        left: 0;
        bottom: 0;
        background: ${theme.colors.primary50};
        height: 100%;
        width: 100%;
        transform-origin: left;
      `}
      initial={{ scaleX: 1 }}
    />
  </div>
);
