import { WeaveFeatureInterests } from '@weave/schema-gen-ts/dist/shared/insys/activation/intake_form.pb';
import { CountryCodes } from '@frontend/geography';

export type { GetLocationHierarchyResponseRow as IntakeFormLocation } from '@weave/schema-gen-ts/dist/schemas/insys/intake-form/v1/insys_onboarding_intake_form.pb';

export { WeaveFeatureInterests };

export enum INTAKE_FORM_DEMO_MODE_KEYS {
  IS_SOFTWARE_ONLY = 'IS_SOFTWARE_ONLY',
  IS_MULTI_FORM = 'IS_MULTI_FORM',
}

export enum Sections {
  BusinessSetup = 'businessSetup',
  PortingInformation = 'phoneNumberPortingInformation',
  SoftwareSetup = 'softwareSetup',
}

export enum Tasks {
  BusinessInfo = 'businessInfo',
  BusinessInformationTenDLC = 'businessInformationTenDLC',
  SubmitPort = 'submitPort',
  AccountInfo = 'accountInfo',
  PortingTerms = 'portingTerms',
  DownloadSoftware = 'downloadSoftware',
}

export enum Steps {
  // Business Setup Section
  Addresses = 'addresses',
  AddressesSoftwareOnly = 'addressesSO',
  BusinessInformationTenDLC = 'businessInformationTenDLC',
  // Porting Information Section
  PortRequests = 'portRequests',
  PhonesTiedToInternet = 'phonesTiedToInternet',
  PortingTerms = 'portingTerms',
  // SoftwareSetup
  DownloadSoftware = 'downloadSoftware',
}

/*------------------------------- Intake Form Data Types -------------------------------*/
export type IntakeFormStepProgress = {
  displayId: `${Sections}/${Tasks}/${Steps}`;
  name: string;
  startedAt: string | null;
  completedAt: string | null;
  section: Sections;
  task: Tasks;
  lastModifiedAt: string | null;
  lastModifiedBy: string;
};

export type IntakeFormSectionProgress = {
  name: Sections;
  totalSteps: number | null;
  stepsCompleted: number | null;
  startedAt: string | null;
  completedAt: string | null;
  lastModifiedAt: string | null;
};

export type SectionInfo = {
  id: Sections;
  name: string;
  isCompleted: boolean;
};

export type IntakeFormAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: CountryCodes;
  postal: string;
  verified: boolean | null;
};

export type PhoneBillObject = {
  fileName: string;
  mediaId: string;
};

export type PhoneNumbers = {
  phoneNumbers: string[];
};

export type PhoneNumbersResponse = {
  response: PhoneNumberAndValidResponse[];
};

export type PhoneNumberAndValidResponse = {
  phoneNumber: string;
  valid: boolean;
};
export type IntakeFormPortOrder = {
  phoneProviderName: string;
  phoneProviderAccountNumber: string;
  phoneProviderServiceAddress: IntakeFormAddress | null;
  phoneProviderInfoCorrect: boolean | null;
  faxNumbers: string[] | null;
  faxNumbersCorrect: boolean | null;
  officeEmail: string;
  phoneNumbers: string[] | null;
  phoneNumbersCorrect: boolean | null;
  phoneProviderAuthorizedUser: string;
  phoneProviderBillImageData: PhoneBillObject[] | null;
};

export enum UserAnswer {
  Yes = 'yes',
  No = 'no',
  NotSure = 'not-sure',
  Unanswered = '',
}

export enum ShippingMethod {
  standard = 'standard',
  twoDay = 'two-day',
  overnight = 'overnight',
}

export type IntakeForm = {
  locationId: string;
  isMultiForm: boolean;
  isSoftwareOnlyForm: boolean;
  // What to show
  hideScreeningQuestions: boolean;
  officeConfiguration: string[] | null;
  salesforceOpportunityId: string;
  salesforceSchedulingContactId: string;
  // Screening
  isOfficeUnderConstruction: boolean | null;
  hasInternetAndComputers: boolean | null;
  // Progress
  formStartedAt: string | null;
  formCompletedAt: string | null;
  stepProgress: IntakeFormStepProgress[] | null;
  sectionProgress: IntakeFormSectionProgress[] | null;
  // Form Questions
  firstLoggedInUser: string | null;
  businessOwnerContactEmail: string;
  primaryContactEmail: string;
  hasItContact: boolean | null;
  itContactEmail: string;
  businessName: string;
  businessPhone: string;
  shippingSameAsOtherAddress: boolean | null;
  shippingAddress: IntakeFormAddress | null;
  billingAddress: IntakeFormAddress | null;
  e911SameAsOtherAddress: boolean | null;
  e911Address: IntakeFormAddress | null;
  shippingMethod: ShippingMethod | '';
  isAtBusinessLocation: boolean | null;
  allWorkstationsEthernetConnected: UserAnswer;
  numWorkstationsMissingEthernet: number | null;
  hasPhonesAwayFromWorkstations: UserAnswer;
  numEthernetPortsNeedInstalled: number | null;
  routerName: string;
  routerModel: string;
  routerContactEmail: string;
  networkTestTimedOut: boolean | null;
  networkTestPing: string;
  networkTestJitter: string;
  networkTestDownload: string;
  networkTestUpload: string;
  networkTestFailedDisclaimer: boolean | null;
  phoneProviderBillImageData: PhoneBillObject[] | null;
  phoneProviderServiceAddress: IntakeFormAddress | null;
  phoneAndInternetBundled: UserAnswer;
  otherServicesUsingPhoneLines: UserAnswer;
  estimatedPortDate: string;
  acceptedPhonePortLoa: boolean;
  portingBatchId: string;
  softwareLatestDownloadAt: string | null;
  integrationName: string;
  portOrders: IntakeFormPortOrder[] | null;
  networkAuditId: string;
  skipPortingSection: boolean | null;
  debug: {
    skipSalesforceAction: boolean | null;
  } | null;
  isFormManuallyCompleted: boolean | null;
  weaveFeatureInterests: WeaveFeatureInterests[];
};

export type AddressInformationType = {
  components: {
    premise: string | undefined;
    thoroughfare: string | undefined;
    locality: string | undefined;
    administrative_area: string | undefined;
    sub_building_type: string | undefined;
    sub_building_number: string | undefined;
    postal_code_short: string | undefined;
    postal_code_extra: string | undefined;
  };
  analysis: {
    address_precision: string | undefined;
  };
};

export type VerifiyAddressType = {
  address1: string;
  address2: string;
  components: {
    administrative_area: string;
    sub_administrative_area: string;
    country_iso_3: string;
    locality: string;
    postal_code: string;
    postal_code_short: string;
    postal_code_extra: string;
    premise: string;
    premise_number: string;
    thoroughfare: string;
    thoroughfare_name: string;
    thoroughfare_predirection: string;
    thoroughfare_type: string;
    thoroughfare_trailing_type: string;
  };
  metadata: null;
  analysis: {
    verification_status: string;
    address_precision: string;
    changes: {
      components: null;
    };
  };
};
