import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { containerStyle as commonContainerStyle } from '../common.styles';

export const containerStyle = [
  commonContainerStyle,
  css`
    flex-direction: column;
    align-items: initial;
  `,
];

export const innerContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

export const previewContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`;
