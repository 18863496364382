import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ChipVariants } from '@frontend/design-system';
import CallIntelChip from './call-intel-chip';

const chipVariants: Record<string, { variant: ChipVariants }> = {
  beta: { variant: 'seaweed' },
};

const BetaBadge = () => {
  const { t } = useTranslation('analytics');

  return (
    <CallIntelChip
      as='chip'
      chipVariants={chipVariants}
      css={styles.badge}
      dataKey='beta'
      maxWidth='46px'
      label={t('Beta')}
    />
  );
};

const styles = {
  badge: css`
    color: ${theme.colors.secondary.seaweed70};
    padding-top: ${theme.spacing(0.125)};
    padding-bottom: ${theme.spacing(0.125)};
  `,
};

export default BetaBadge;
