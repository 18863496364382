import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallObject } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import {
  ReactFlow,
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Background,
  BackgroundVariant,
  Edge,
  Node,
  EdgeProps,
  NodeProps,
  useNodesInitialized,
  ProOptions,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { theme } from '@frontend/theme';
import { ActionButtons } from './action-buttons';
import {
  CustomNodeProps,
  DataPayload,
  EdgeType,
  NodeType,
  NodeTypes,
  SharedNodeProps,
  CustomEdgeData,
  extendableNodes,
} from './data';
import { AnimatedSVGEdge, AnimatedSVGEdgeReverse, BasicEdge, PhoneTreeEdge } from './edges/custom';
import { BasicNode } from './nodes/basic-node';
import { BooleanNode } from './nodes/boolean-node';
import { HoursTypeNode } from './nodes/hours-type-node';
import {
  VoicemailNode,
  CallGroupNode,
  PlayNode,
  ForwardingDeviceNode,
  ForwardingNumberNode,
  PhoneTreeNode,
  OfficeHoursNode,
  CallQueueNode,
  TreeOptionNode,
  RepeatNode,
  FallbackNode,
  CallRouteNode,
} from './nodes/instruction-nodes';
import { PlaceholderNode } from './nodes/placeholder-node';
import { PlaygroundNode } from './nodes/playground-node';
import { StartNode } from './nodes/start-node';
import { TerminateNode } from './nodes/terminate-node';
import { InteractionContextType, InteractionProvider } from './provider';
import { useCalculateLayout } from './util';

// It’s important that the nodeTypes are memoized or defined outside of the component.
// Otherwise React creates a new object on every render which leads to performance issues and bugs.
const nodeTypes: (
  commonProps: CustomNodeProps
) => Record<
  (typeof NodeType)[keyof typeof NodeType] | 'playground',
  (props: NodeProps<Node<DataPayload, NodeTypes>>) => ReactNode
> = (commonProps) => ({
  playground: PlaygroundNode,
  [NodeType.Start]: StartNode,
  [NodeType.Terminate]: TerminateNode,
  [NodeType.VoicemailPrompt]: ({ ...props }) => <VoicemailNode {...props} {...commonProps} />,
  [NodeType.CallGroup]: ({ ...props }) => <CallGroupNode {...props} {...commonProps} />,
  [NodeType.CallRoute]: ({ ...props }) => <CallRouteNode {...props} {...commonProps} />,
  [NodeType.OfficeHours]: ({ ...props }) => <OfficeHoursNode {...props} {...commonProps} />,
  [NodeType.OpenPhoneHours]: ({ ...props }) => <HoursTypeNode {...props} {...commonProps} />,
  [NodeType.ClosedPhoneHours]: ({ ...props }) => <HoursTypeNode {...props} {...commonProps} />,
  [NodeType.BreakPhoneHours]: ({ ...props }) => <HoursTypeNode {...props} {...commonProps} />,
  [NodeType.PlayMessage]: ({ ...props }) => <PlayNode {...props} {...commonProps} />,
  [NodeType.Boolean]: BooleanNode,
  [NodeType.PhoneTree]: ({ ...props }) => <PhoneTreeNode {...props} {...commonProps} />,
  [NodeType.TreeOption]: ({ ...props }) => <TreeOptionNode {...props} {...commonProps} />,
  [NodeType.ForwardDevice]: ({ ...props }) => <ForwardingDeviceNode {...props} {...commonProps} />,
  [NodeType.ForwardNumber]: ({ ...props }) => <ForwardingNumberNode {...props} {...commonProps} />,
  [NodeType.VoicemailBox]: ({ ...props }) => <VoicemailNode {...props} {...commonProps} />,
  [NodeType.CallQueue]: ({ ...props }) => <CallQueueNode {...props} {...commonProps} />,
  [NodeType.Repeat]: ({ ...props }) => <RepeatNode {...props} {...commonProps} />,
  [NodeType.Fallback]: ({ ...props }) => <FallbackNode {...props} {...commonProps} />,
  [NodeType.Basic]: ({ ...props }) => <BasicNode {...props} {...commonProps} />,
  [NodeType.PlaceHolder]: ({ ...props }) => <PlaceholderNode {...props} {...commonProps} />,
});

const edgeTypes: Record<(typeof EdgeType)[keyof typeof EdgeType], (props: EdgeProps) => ReactNode> = {
  [EdgeType.ToTarget]: AnimatedSVGEdge,
  [EdgeType.FromSource]: AnimatedSVGEdgeReverse,
  [EdgeType.TreeOption]: PhoneTreeEdge,
  [EdgeType.Basic]: BasicEdge,
  [EdgeType.PlaceHolder]: BasicEdge,
};

export const FlowContent = ({
  nodes: initialNodes,
  edges: initialEdges,
  showDebugButtons = false,
  canEdit = false,
  initialNodeId,
  onClickLink,
  onPlusIconClick,
  onEditIconClick,
  onDeleteIconClick,
  AudioPlayerComponent,
  DayHoursListComponent,
}: {
  nodes: Node<DataPayload>[];
  edges: Edge<CustomEdgeData>[];
  initialNodeId?: string;
  canEdit?: boolean;
  showDebugButtons?: boolean;
  onClickLink?: SharedNodeProps['onClickLink'];
  onPlusIconClick?: InteractionContextType['onPlusIconClick'];
  onEditIconClick?: InteractionContextType['onEditIconClick'];
  onDeleteIconClick?: InteractionContextType['onDeleteIconClick'];
  AudioPlayerComponent?: SharedNodeProps['AudioPlayerComponent'];
  DayHoursListComponent?: SharedNodeProps['DayHoursListComponent'];
}) => {
  const { setCenter, zoomIn, zoomOut, fitView, getZoom, zoomTo } = useReactFlow();
  const nodesInitialized = useNodesInitialized({
    includeHiddenNodes: false,
  });
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [mode, setMode] = useState<'edit' | 'view'>(canEdit ? 'edit' : 'view');
  const [initialEditModeCenteringDone, setInitialEditModeCenteringDone] = useState(false);
  const calculate = useCalculateLayout({ shortVersion: !canEdit });

  const commonProps = useMemo(
    () => ({
      onClickLink,
      AudioPlayerComponent,
      DayHoursListComponent,
    }),
    [onClickLink]
  );
  const memoizedNodeTypes = useMemo(() => nodeTypes(commonProps), [commonProps]);

  const onLayout = useCallback(
    (direction: 'TB' | 'LR') => {
      const { nodes: calculatedNodes } = calculate({ direction });

      if (!initialEditModeCenteringDone) {
        window.requestAnimationFrame(() => {
          // Find the start node and center the view on it
          const startNode = initialNodeId
            ? calculatedNodes.find((node) => node.id === initialNodeId) ?? calculatedNodes[0]
            : calculatedNodes[0];

          if (direction === 'TB') {
            // Center the view when viewing the flow vertically
            setCenter(
              startNode.position.x + (startNode.measured?.width ?? 0) / 2,
              startNode.position.y + (startNode.measured?.height ?? 0) / 2 + 200, // Center the view a little below the start node
              { zoom: 1, duration: 300 }
            );
          } else {
            // Center the view when viewing the flow horizontally
            setCenter(
              startNode.position.x + 200, // Center the view a little to the right of the start node
              startNode.position.y,
              { zoom: 1, duration: 300 }
            );
          }

          // If there are more than 2 nodes, then we can assume that the initial centering is done.
          // This is because the start node plus a placeholder node will be added to the graph when in edit mode.
          // We only want to center the view once when in edit mode. This to prevent re-centering the view when
          // adding new nodes.
          if (mode === 'edit' && nodes.length > 2) {
            setInitialEditModeCenteringDone(true);
          }
        });
      }
    },
    [initialEditModeCenteringDone, nodes, mode]
  );

  useEffect(() => {
    if (nodesInitialized && nodes.length > 0) {
      onLayout('TB');
    }
  }, [nodesInitialized]);

  useEffect(() => {
    setMode(canEdit ? 'edit' : 'view');
    if (mode === 'edit') {
      const placeholders = getPlaceHolders(initialNodes, initialEdges);
      setNodes([...initialNodes, ...placeholders.nodes]);
      setEdges([...initialEdges, ...placeholders.edges]);
    } else if (mode === 'view') {
      // When switching back to view mode, remove all the placeholder nodes and edges
      setNodes([...initialNodes]);
      setEdges([...initialEdges]);
    }
  }, [initialNodes, initialEdges, mode]);

  const handleZoom = useCallback((direction: 'in' | 'out') => {
    const currentZoom = getZoom();

    if (direction === 'in') {
      zoomTo(currentZoom + 0.3, { duration: 300 });
    } else {
      zoomTo(currentZoom - 0.3, { duration: 300 });
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '=') {
        handleZoom('in');
      } else if (event.key === '-') {
        handleZoom('out');
      } else if (event.key === '/') {
        fitView({ duration: 300 });
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [zoomIn, zoomOut]);

  /**
   * Helper function to get all the placeholder nodes and edges that can be added on to a
   * given graph. This is used to add the placeholder nodes (plus icons) and edges when in edit mode.
   *
   * @param graphNodes The nodes in the graph
   * @param graphEdges The edges in the graph
   * @returns
   */
  const getPlaceHolders = (graphNodes: Node<DataPayload>[], graphEdges: Edge<CustomEdgeData>[]) => {
    const placeHolderNodes: Node<DataPayload>[] = [];
    const placeHolderEdges: Edge<CustomEdgeData>[] = [];
    // Get all the leaf nodes (have no children) that can be added on to
    const extendableLeafNodes = graphNodes.filter((node) => {
      return graphEdges.every((edge) => edge.source !== node.id) && extendableNodes.includes(node.type as NodeTypes);
    });

    // For each extendable leaf node, add a placeholder edge and placeholder node to it
    extendableLeafNodes.forEach((node) => {
      const placeHolderNodeId = `${node.id}-placeholder`;
      const placeholderNode = {
        id: placeHolderNodeId,
        type: NodeType.PlaceHolder,
        position: { x: node.position.x, y: node.position.y },
        data: { id: placeHolderNodeId, label: '', parentId: node.id },
      };

      const placeholderEdge = {
        id: `${node.id}-placeholder-edge`,
        source: node.id,
        target: placeholderNode.id,
        type: EdgeType.PlaceHolder,
        selectable: false,
        data: {},
      };

      placeHolderNodes.push(placeholderNode);
      placeHolderEdges.push(placeholderEdge);
    });

    return { nodes: placeHolderNodes, edges: placeHolderEdges };
  };

  const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

  return (
    <InteractionProvider
      mode={mode}
      onPlusIconClick={onPlusIconClick}
      onEditIconClick={onEditIconClick}
      onDeleteIconClick={onDeleteIconClick}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        nodeTypes={memoizedNodeTypes}
        nodesDraggable={false}
        panOnScrollSpeed={1}
        minZoom={0.1} // Allows zooming out really far
        deleteKeyCode={null}
        proOptions={proOptions}
        panOnScroll
        css={css`
          .react-flow__edges {
            /**
              Need to add width: 100%; to this class to make the edges show. This is the parent of the edges
              SVGs edges and without it, the parent div has a calculated width of 0px which causes the edges
              to not show.
             */
            width: 100%;
          }
        `}
      >
        {/* Control Panel that has the zoom-in, zoom-out and fit view buttons */}
        <Panel
          position='top-left'
          css={{
            display: 'flex',
            gap: theme.spacing(1),
          }}
        >
          <ActionButtons
            mode={mode}
            showDebugButtons={showDebugButtons}
            onLayoutChange={onLayout}
            setMode={setMode}
            onZoomClick={handleZoom}
            onFitViewClick={() => {
              fitView({ duration: 300 });
            }}
          />
        </Panel>

        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </InteractionProvider>
  );
};

export function Flow() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlowProvider>
        <FlowContent
          nodes={[
            {
              id: 'playground',
              type: 'playground',
              position: { x: -100, y: -100 },
              data: { id: 'playground', label: 'Playground' },
            },
          ]}
          edges={[]}
        />
      </ReactFlowProvider>
    </div>
  );
}

export function CallRouteFlow({
  nodes,
  edges,
  canEdit = false,
  showDebugButtons = false,
  onClickLink,
  onEditIconClick,
  onPlusIconClick,
  onDeleteIconClick,
  AudioPlayerComponent,
  DayHoursListComponent,
  initialCallObjectId,
}: {
  nodes: {
    id: string;
    type?: string;
    isPhoneTreeDescendent?: boolean;
    callObject: CallObject;
  }[];
  edges: { sourceId: string; targetId: string; label?: string; type?: string }[];
  canEdit?: boolean;
  showDebugButtons?: boolean;
  initialCallObjectId?: string;
  onClickLink?: SharedNodeProps['onClickLink'];
  onPlusIconClick?: InteractionContextType['onPlusIconClick'];
  onEditIconClick?: InteractionContextType['onEditIconClick'];
  onDeleteIconClick?: InteractionContextType['onDeleteIconClick'];
  AudioPlayerComponent: SharedNodeProps['AudioPlayerComponent'];
  DayHoursListComponent: SharedNodeProps['DayHoursListComponent'];
}) {
  const memoizedNodes = useMemo(
    () =>
      nodes.map((node) => {
        return {
          id: node.id,
          type: node.type ?? 'basic',
          position: { x: -100, y: -100 }, // Set an arbitrary position (it will be overwritten later)
          data: {
            ...node,
            id: node.id,
            label: node.callObject.primitiveName,
            callObject: node.callObject,
          },
        };
      }),
    [nodes]
  );

  const memoizedEdges = useMemo(
    () =>
      edges.map((edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.sourceId);

        return {
          id: edge.sourceId + ':' + edge.targetId,
          source: edge.sourceId,
          target: edge.targetId,
          type: edge.type ?? 'basic',
          selectable: false, // make the edges unselectable so that you can't drag them
          data: {
            isExtendable: sourceNode?.type ? extendableNodes.includes(sourceNode.type as NodeTypes) : false,
            label: edge.label,
          },
        };
      }),
    [edges]
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlowProvider>
        <FlowContent
          nodes={
            !!nodes.length
              ? memoizedNodes
              : [
                  // Default to showing only a start node if no nodes are provided
                  {
                    id: 'start',
                    type: NodeType.Start,
                    position: { x: -100, y: -100 },
                    data: { id: 'start', label: 'Incoming Call' },
                  },
                ]
          }
          edges={memoizedEdges}
          canEdit={canEdit}
          onPlusIconClick={onPlusIconClick}
          onEditIconClick={onEditIconClick}
          onDeleteIconClick={onDeleteIconClick}
          showDebugButtons={showDebugButtons}
          initialNodeId={initialCallObjectId}
          onClickLink={onClickLink}
          AudioPlayerComponent={AudioPlayerComponent}
          DayHoursListComponent={DayHoursListComponent}
        />
      </ReactFlowProvider>
    </div>
  );
}
