import { useMemo } from 'react';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { InvoiceModel } from '@frontend/api-invoices';
import { PersonsV3 } from '@frontend/api-person';
import { useAppScopeStore } from '@frontend/scope';
import { useInvoicePerson } from '../../hooks';

export const useValidEmailFromInvoice = (invoice: InvoiceModel | undefined) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { person } = useInvoicePerson(invoice);

  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      locationIds: invoice?.locationId ? [invoice.locationId] : selectedLocationIds,
      personId: person?.personId ?? '',
    },
    {
      enabled: !!person?.personId,
    }
  );

  const validEmail = useMemo(() => {
    if (personQuery.data) {
      return (
        personQuery.data.contactInfo?.find((contact) => contact.type === ContactType_Enum.EMAIL)?.destination ?? ''
      );
    }
    return null;
  }, [personQuery.data]);

  return { firstValidEmail: validEmail };
};
