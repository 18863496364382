import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BlockIcon, Modal, ModalControlModalProps, Text } from '@frontend/design-system';

type RoleRequiredModalProps = {
  roleRequiredModalProps: ModalControlModalProps;
};
export const RoleRequiredModal = ({ roleRequiredModalProps }: RoleRequiredModalProps) => {
  const { t } = useTranslation('payments');
  return (
    <Modal {...roleRequiredModalProps}>
      <Modal.Header>
        <span css={styles.roleRequiredModalHeader}>
          <BlockIcon css={styles.blockIcon} color='error' />
          {t('Missing authorized roles')}
        </span>
      </Modal.Header>
      <Modal.Body>
        <Text>{t('Super Admin or Payments/Billing Manager role is required to create a Stripe account.')}</Text>
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  blockIcon: css`
    display: inline;
  `,
  roleRequiredModalHeader: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};
