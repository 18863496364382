import { LayoutType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { LineKeysTypes } from '@frontend/api-line-keys';

export const DeviceLayoutFooter = ({ model }: { model: LineKeysTypes.ModelInfoType }) => {
  return (
    <div
      className={`device-model-layout-footer ${model?.layoutType === LayoutType_Enum.TOUCH_SCREEN && 'touch-screen'}`}
    ></div>
  );
};
