import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Entity } from '@weave/schema-gen-ts/dist/shared/wsearch/v1/entity.pb';
import {
  GlobalSearch,
  GlobalSearchModuleOrder,
  GlobalSearchTrackingIds,
  useGlobalSearch,
} from '@frontend/global-search';
import { SchemaPersonV3Service } from '@frontend/schema';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { formatResultsPrimaryKeys, handleRegisterClick, handleUpdateSearch } from '@frontend/search-history';
import { theme } from '@frontend/theme';
import { NakedUl, SkeletonLoader } from '@frontend/design-system';
import { useInboxSearch } from '../../hooks';
import { ContactSearchResult } from '../inbox-panel/inbox-search/list-items';

const defaultMaxResults = 4;
const entity = Entity.ENTITY_PERSON;

export const ContactsGlobalSearchModule = () => {
  const { debouncedSearchTerm, viewAllFeature, close, addSearch } = useGlobalSearch([
    'debouncedSearchTerm',
    'viewAllFeature',
    'close',
    'addSearch',
  ]);
  const { selectedLocationIds } = useAppScopeStore();

  const { personsQuery, logSearch } = useInboxSearch({ debouncedSearchValue: debouncedSearchTerm });
  const pmidQuery = useScopedQuery({
    queryKey: ['searchByPmid', debouncedSearchTerm.trim()],
    queryFn: () =>
      SchemaPersonV3Service.SearchPersonsByPMIDLegacy({
        locationIds: selectedLocationIds,
        search: debouncedSearchTerm.trim(),
      }),
    enabled: !debouncedSearchTerm.trim().includes(' '),
  });

  useEffect(() => {
    if (personsQuery.isLoading.firstPage) return;
    handleUpdateSearch({
      entity,
      results: formatResultsPrimaryKeys<Person>('personId', personsQuery.results),
    });
  }, [personsQuery.isLoading.firstPage, pmidQuery.data]);

  const fullPersonsCount = personsQuery.results.length;
  const maxResults = viewAllFeature === 'contacts' ? fullPersonsCount : defaultMaxResults;
  // merge the results from the pmid query with the results from the persons query
  const combinedResults = [...(pmidQuery?.data?.persons.length ? pmidQuery.data.persons : []), ...personsQuery.results];
  // if we have results from pmidQuery, there is a chance that the same person is returned from both queries, so we need to filter out duplicates
  // if there are no results from pmidQuery, we can just use the results from personsQuery
  const uniqueResults = pmidQuery?.data?.persons.length
    ? [...new Map(combinedResults.map((person) => [person['personPmid'], person])).values()]
    : personsQuery.results;

  return (
    <GlobalSearch.Section.Content
      showViewAll={personsQuery.results.length > defaultMaxResults}
      hasResults={!!personsQuery.results.length}
      count={`${fullPersonsCount}${personsQuery.hasNextPage ? '+' : ''}`}
    >
      <NakedUl css={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1), alignItems: 'stretch' }}>
        {personsQuery.isLoading.firstPage || pmidQuery.isLoading ? (
          <>
            {Array.from({ length: defaultMaxResults }).map((_, index) => (
              <ContactItemSkeletonLoader key={index} />
            ))}
          </>
        ) : uniqueResults.length ? (
          uniqueResults.slice(0, maxResults).map((person, index) => (
            <ContactSearchResult
              trackingId={GlobalSearchTrackingIds.searchResultItem('contacts')}
              isGlobalSearch
              key={person.personId}
              person={person}
              searchValue={debouncedSearchTerm}
              onSelect={() => {
                logSearch(debouncedSearchTerm);
                addSearch(debouncedSearchTerm, 'contacts');
                handleRegisterClick({
                  entity,
                  entityId: person.personId,
                  entityPosition: GlobalSearchModuleOrder.contacts,
                  itemPosition: index,
                });
                close();
              }}
            />
          ))
        ) : (
          <GlobalSearch.EmptyState />
        )}
      </NakedUl>
    </GlobalSearch.Section.Content>
  );
};

const ContactItemSkeletonLoader = () => {
  return (
    <li
      css={css`
        display: flex;
        gap: ${theme.spacing(2)};
        border-radius: ${theme.borderRadius.small};
        padding: ${theme.spacing(2)};
        border: 1px solid ${theme.colors.neutral10};
        align-items: left;
        min-width: 234px;
        max-height: 76px;
        flex: 1;
      `}
    >
      <SkeletonLoader
        css={css`
          flex-shrink: 0;
        `}
        height={40}
        width={40}
        shape='circle'
      />
      <div
        css={css`
          width: 100%;
        `}
      >
        <SkeletonLoader
          height={20}
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
          width='60%'
        />
        <SkeletonLoader height={18} width='95%' />
      </div>
    </li>
  );
};
