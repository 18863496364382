import { useEffect, useRef } from 'react';
import { IPCRendererCallback, IpcRendererEvent, useIpcShellProvider, shell } from '@frontend/shell-utils';
import {
  GetWeavePopNotificationByType,
  GetWeavePopNotificationActionsByType,
  WeavePopNotification,
  GetWeavePopNotificationWithActionsByType,
} from '@frontend/types';
import { useNotificationContext } from '../../notification-provider';

export function useNotificationActionSubscription<
  T extends WeavePopNotification['type'],
  N extends GetWeavePopNotificationByType<T>,
  A extends GetWeavePopNotificationActionsByType<T>['action'],
  Payload extends Extract<GetWeavePopNotificationWithActionsByType<N['type']>['actions'], { action: A }>['payload'],
  //TODO: @gisheri - the type below is specifically made for this purpose, but using it here doesn't work
  // Payload extends GetWeavePopNotificationActionByType<N['type'], A>['payload'],
  H extends (data: { notification: N; payload: Payload }) => void
>(
  notificationType: T,
  action: A,
  handler: H,
  /* in an attempt to reduce repeatedly re-listening to the same handler,
      we'll not re-effect on handler change, but instead let the consuemr pass in arbitrary deps
      */
  deps?: any[]
) {
  const { status } = useIpcShellProvider();
  const { emitter } = useNotificationContext();

  type CallbackData = Parameters<IPCRendererCallback<'notification:action'>>[1];
  type ShellCallback = (e: IpcRendererEvent, data: CallbackData) => void; //IPCRendererCallback<'notification:action'>;
  type EmitterCallback = Parameters<typeof emitter.on<T>>[1];
  const shellCallback = useRef<ShellCallback>();
  const shellCallbackId = useRef<string>();
  const emitterCallback = useRef<EmitterCallback>();

  useEffect(() => {
    if (shell.isShell && status === 'connected') {
      const callback: ShellCallback = (_e, data) => {
        if (data.notification.type === notificationType && data.action.action === action) {
          //TODO: @gisheri: try to avoid the typecast here. Probably by providing more type information to the ShellCallback type
          handler({
            notification: data.notification as N,
            payload: data.action.payload as Payload,
          });
        }
      };
      if (shellCallback.current && shellCallbackId.current) {
        shell.off?.('notification:action', shellCallback.current, shellCallbackId.current);
      }
      shellCallbackId.current = notificationType + action + Math.random();
      shellCallback.current = callback;
      shell.on?.('notification:action', callback, shellCallbackId.current);
      return () => {
        if (shellCallback.current && shellCallbackId.current) {
          shell.off?.('notification:action', shellCallback.current, shellCallbackId.current);
          shellCallback.current = undefined;
          shellCallbackId.current = undefined;
        }
      };
    } else {
      const callback: EmitterCallback = (e) => {
        if (e.notification.type === notificationType && e.action === action) {
          //TODO: @gisheri: try to avoid the typecast here.. Probably by providing more type information to the ShellCallback type
          handler({
            notification: e.notification as N,
            payload: e.payload as Payload,
          });
        }
      };
      if (emitterCallback.current) {
        emitter.off(notificationType, emitterCallback.current);
      }
      emitter.on(notificationType, callback);
      emitterCallback.current = callback;
      return () => {
        emitter.off(notificationType, emitterCallback.current);
        emitterCallback.current = undefined;
      };
    }
  }, [status, notificationType, action, ...(deps ?? [])]);
}
