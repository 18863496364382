import { ComponentProps, useEffect } from 'react';
import { Variants, motion } from 'motion/react';
import { Icon } from '@frontend/icons';
import type { CommonHTMLButtonAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Dot, NakedButton, Text } from '@frontend/design-system';
import { POPUP_BAR_MAX_HEIGHT, MOBILE_POPUP_SELECTOR_CLOSE_BUTTON_HEIGHT } from '../constants';
import { MessagePopup, MessagePopupThreadStatus } from '../messages';
import { usePopupBarManager } from './provider';

type Props = Omit<CommonHTMLButtonAttributes, 'onClick'>;

export const PopupBarRollupDesktopButton = (props: Props) => {
  const { isCollapsed, setIsCollapsed } = usePopupBarManager();

  const onClick = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <NakedButton
      css={[
        {
          borderTopLeftRadius: theme.borderRadius.medium,
          borderTop: `1px solid ${theme.colors.neutral20}`,
          borderLeft: `1px solid ${theme.colors.neutral20}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: POPUP_BAR_MAX_HEIGHT,
          zIndex: theme.zIndex.high, // so the popouts animate under this button on rollup toggle
          ':focus': {
            outline: `1px solid ${theme.colors.primary50}`,
          },
        },
        isCollapsed
          ? {
              backgroundColor: theme.colors.white,
              ':active, :focus, :hover': {
                backgroundColor: theme.colors.neutral10,
              },
            }
          : {
              backgroundColor: theme.colors.neutral10,
              ':active, :focus, :hover': {
                backgroundColor: theme.colors.neutral20,
              },
            },
      ]}
      onClick={onClick}
      {...props}
    >
      <Icon
        name='caret-right-small'
        css={{
          transition: 'transform 0.3s ease-in-out',
          transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
    </NakedButton>
  );
};

const MOBILE_BUTTON_VARIANTS = {
  open: {
    borderTopLeftRadius: 0,
    left: 0,
    height: MOBILE_POPUP_SELECTOR_CLOSE_BUTTON_HEIGHT,
    right: 0,
  },
  closed: {
    borderTopLeftRadius: theme.borderRadius.medium,
    left: 'auto',
    height: POPUP_BAR_MAX_HEIGHT,
    right: 0,
  },
  hidden: {
    borderTopLeftRadius: theme.borderRadius.medium,
    left: 'auto',
    height: POPUP_BAR_MAX_HEIGHT,
    right: '-100%',
  },
} satisfies Variants;

const MotionNakedButton = motion(NakedButton);

export const PopupBarRollupMobileButton = ({
  count,
  ...props
}: ComponentProps<typeof MotionNakedButton> & { count: number }) => {
  const { activePopup, popupList, setActivePopup, mobileSelectorIsOpen, setMobileSelectorIsOpen, setIsCollapsed } =
    usePopupBarManager();
  const unreadCount = popupList.filter((popup) =>
    popup.type === 'message'
      ? (popup as MessagePopup).meta.status === MessagePopupThreadStatus.NEW
      : !!(popup as any).status?.status
  ).length;

  const openSelector = () => {
    setMobileSelectorIsOpen(true);
    setActivePopup([]);
    setIsCollapsed(false);
  };

  const closeSelector = () => {
    setMobileSelectorIsOpen(false);
    if (!activePopup.length) {
      setIsCollapsed(true);
    }
  };

  const onClick = () => {
    if (mobileSelectorIsOpen) {
      closeSelector();
      return;
    }

    openSelector();
  };

  useEffect(() => {
    if (activePopup.length > 1) {
      const lastActivePopup = popupList.find((popup) => popup.id === activePopup[activePopup.length - 1]);
      setActivePopup(lastActivePopup ? [lastActivePopup.id] : []);
    }
    // Close the mobile selector when the component unmounts (e.g. when the desktop button is used instead)
    return () => {
      setMobileSelectorIsOpen(false);
    };
  }, []);

  return (
    <MotionNakedButton
      css={{
        borderTop: `1px solid ${theme.colors.neutral20}`,
        borderLeft: `1px solid ${theme.colors.neutral20}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: theme.zIndex.high, // so the popouts animate under this button on rollup toggle
        backgroundColor: theme.colors.white,
        ':active, :focus, :hover': {
          backgroundColor: theme.colors.neutral10,
        },
        position: 'absolute',
        bottom: 0,
        gap: theme.spacing(0.25),
        ':focus': {
          outline: `1px solid ${theme.colors.primary50}`,
        },
        padding: theme.spacing(0, 1),
        minWidth: 54,
      }}
      variants={MOBILE_BUTTON_VARIANTS}
      initial='closed'
      exit='hidden'
      animate={mobileSelectorIsOpen ? 'open' : 'closed'}
      onClick={onClick}
      {...props}
    >
      <Icon name={mobileSelectorIsOpen ? 'x' : 'message-thread'} />
      {!!count && !mobileSelectorIsOpen && (
        <Text size='small' color='light'>
          {count}
        </Text>
      )}
      {!!unreadCount && (
        <Dot
          color='critical'
          css={{
            width: 18,
            height: 18,
            borderColor: theme.colors.critical50,
            top: theme.spacing(-0.75),
            left: theme.spacing(-0.75),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'opacity 0.3s ease-in-out',
            opacity: mobileSelectorIsOpen ? 0 : 1,
          }}
        >
          <Text color='white' weight='bold' size='small'>
            {unreadCount}
          </Text>
        </Dot>
      )}
    </MotionNakedButton>
  );
};
