import { useFetchMessagingROIOOverview } from '../hooks/use-fetch-messaging-roi-overview';
import { MessagingOverview } from '../types';

export const messagingOverviewFormatter = (
  aggregated: ReturnType<typeof useFetchMessagingROIOOverview>['aggregated']
): MessagingOverview => {
  // Leads
  const leadsNewPatients = aggregated?.leads_contacted?.new_patients_counts || 0;
  const leadsExistingPatients = aggregated?.leads_contacted?.existing_patients_counts || 0;
  const leadsUnscheduledPatients = aggregated?.leads_contacted?.unscheduled_patients_counts || 0;

  // Patients Scheduled
  const scheduledNewPatients = aggregated?.patients_scheduled?.new_patients_counts || 0;
  const scheduledExistingPatients = aggregated?.patients_scheduled?.existing_patients_counts || 0;

  // Patients Completed
  const newPatientsCompletedAppts = aggregated?.appointments_completed?.new_patients_appts_counts || 0;
  const existingPatientsCompletedAppts = aggregated?.appointments_completed?.existing_patients_appts_counts || 0;

  return {
    leadsContacted: {
      newPatients: leadsNewPatients,
      existingPatients: leadsExistingPatients,
      unsheduledPatients: leadsUnscheduledPatients,
      totalPatients: leadsNewPatients + leadsExistingPatients + leadsUnscheduledPatients,
      messagesSent: aggregated?.leads_contacted?.sms_count || 0,
    },
    patientsScheduled: {
      newPatients: scheduledNewPatients,
      existingPatients: scheduledExistingPatients,
      totalPatients: scheduledNewPatients + scheduledExistingPatients,
      appointmentsScheduledCount: aggregated?.patients_scheduled?.appointments_count || 0,
    },
    revenueGenerated: {
      newPatientsCompletedAppts,
      existingPatientsCompletedAppts,
      totalCompletedAppts: newPatientsCompletedAppts + existingPatientsCompletedAppts,
    },
  };
};
