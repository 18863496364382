import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { i18next } from '@frontend/i18n';

export const getTimeIn12HrFormat = (date: string, time: string) => {
  return dayjs(`${date} ${time}`).format('h:mmA');
};

export const isEndTimeBeforeStartTime = (timeRange?: string[]) => {
  if (!isEmpty(timeRange)) {
    const [start, end] = timeRange || [];
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    return endTime.isBefore(startTime);
  }
  return false;
};

export const defaultQuickFillTemplate = i18next.t(
  'Hello, {{FIRST_NAME}}, this is {{PRACTICE_NAME}}. We have a last minute opening for {{DATE_TIME}}. Does this time work for you? Reply yes or no.',
  { ns: 'schedule-quick-fill' }
);
