import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { CommonHTMLAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Button, Chip, LocationIcon } from '@frontend/design-system';
import { Divider } from '../divider';

export type Mode = 'widget' | 'modal';

export type PageHeaderWrapProps = {
  children?: ReactNode;
  hasUnderline?: boolean;
  inlineWithIcons?: boolean;
  hasBackButton?: boolean;
  backBtnTrackingId?: string;
  closeBtnTrackingId?: string;
  showMultiLocationChip?: boolean;
  onBack?: () => void;
  onExit?: () => void;
};

type Props = CommonHTMLAttributes &
  PageHeaderWrapProps & {
    mode: Mode;
    locationName: string;
    goToDefaultRoute?: () => void;
  };

export const PageHeader = ({
  hasUnderline,
  hasBackButton,
  showMultiLocationChip,
  inlineWithIcons = true,
  children,
  backBtnTrackingId,
  closeBtnTrackingId,
  onBack,
  onExit,
  mode,
  locationName,
  goToDefaultRoute,
  ...props
}: Props) => {
  const { t } = useTranslation('phone');
  const hasClose = mode === 'modal';
  const hasIcons = hasBackButton || hasClose;
  const { isMultiLocation } = useLocationDataShallowStore('isMultiLocation');

  const onClose = () => {
    onExit?.();
  };

  const backBtn = hasBackButton && (
    <Button
      variant='secondary'
      aria-label={t('Back')}
      onClick={() => {
        goToDefaultRoute?.();
        onBack?.();
      }}
      css={css`
        &:hover:not(:disabled) {
          background-color: ${theme.colors.neutral80};
        }
        svg {
          color: ${theme.colors.white};
        }
      `}
      trackingId={backBtnTrackingId}
      iconName='back'
    />
  );
  const closeBtn = hasClose && (
    <Button
      variant='secondary'
      aria-label={t('Close')}
      trackingId={closeBtnTrackingId ? closeBtnTrackingId : 'softphone-modal-exit'}
      onClick={() => onClose()}
      css={css`
        margin-left: auto;
        &:hover:not(:disabled) {
          background-color: ${theme.colors.neutral80};
        }
        svg {
          color: ${theme.colors.white};
        }
      `}
      iconName='x'
    />
  );

  return (
    <>
      {hasIcons && inlineWithIcons ? (
        <header
          css={css`
            display: flex;
            justify-content: space-between;
            grid-gap: ${theme.spacing(1)};
          `}
          {...props}
        >
          {backBtn}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: ${theme.spacing(1)};
            `}
          >
            {children}
            {isMultiLocation && showMultiLocationChip && (
              <Chip variant='neutral' leftElement={<LocationIcon size={16} />}>
                {locationName}
              </Chip>
            )}
          </div>
          {closeBtn}
        </header>
      ) : hasIcons && !inlineWithIcons ? (
        <header {...props}>
          <div
            css={css`
              display: flex;
              justify-content: ${backBtn ? 'space-between' : 'end'};
              align-items: center;
            `}
          >
            {backBtn}
            {closeBtn}
          </div>
          {children}
          {isMultiLocation && showMultiLocationChip && (
            <Chip variant='neutral' leftElement={<LocationIcon size={16} />}>
              {locationName}
            </Chip>
          )}
        </header>
      ) : (
        <header {...props}>{children}</header>
      )}
      {hasUnderline && <Divider />}
    </>
  );
};
