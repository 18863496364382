import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { IntakeFormQueries, useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { PortingQueries } from '@frontend/api-porting';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  CopyToClipboardButton,
  Heading,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  styles,
  useAlert,
} from '@frontend/design-system';
import { trackingIds } from '../../constants';
import { useDebugMode } from '../../hooks';
import { buttonStyle, containerStyle } from './styles';

interface Props {
  onBackClick: () => void;
  onSaveSuccess: () => Promise<void>;
}

export const TemporaryNumberInfoStep = ({ onBackClick, onSaveSuccess }: Props) => {
  const { t } = useTranslation('onboarding');
  const alert = useAlert();
  const { isDebugMode } = useDebugMode();
  const client = useQueryClient();
  const { selectedIntakeFormLocationId } = useIntakeFormShallowStore('selectedIntakeFormLocationId');
  const temporaryPhoneNumberQuery = PortingQueries.useGetTemporaryPhoneNumber(selectedIntakeFormLocationId ?? '');
  const intakeFormCompleteMutation = IntakeFormQueries.useMutateIntakeFormComplete({
    isDebugMode,
    onSuccess: async () => {
      // invalidating location-hierarchy query and intake form store data to avoid redirection back to form with old data
      await client.invalidateQueries({
        queryKey: IntakeFormQueries.queryKeys.onboardingLocations(selectedIntakeFormLocationId ?? ''),
        exact: true,
        refetchInactive: true,
      });
      await onSaveSuccess();
    },
    onError: () => alert.error(t('Error submitting form data')),
  });

  const handleContinueClick = () => {
    intakeFormCompleteMutation.mutate();
  };

  const phoneNumber = temporaryPhoneNumberQuery.data ?? '';
  return (
    <div css={[styles.flexCenter, { flexDirection: 'column', gap: theme.spacing(3) }]}>
      <Heading level={2}>{t("You're All Set!")}</Heading>
      <div css={[containerStyle, { gap: theme.spacing(2) }]}>
        <ContentLoader show={intakeFormCompleteMutation.isLoading} />
        {temporaryPhoneNumberQuery.isLoading ? (
          <div css={styles.flexCenter}>
            <SpinningLoader />
          </div>
        ) : !temporaryPhoneNumberQuery.data ? (
          <Text css={{ textAlign: 'center' }}>
            {t('Weave will be providing you a phone number to use with our software.')}
          </Text>
        ) : (
          <>
            <Heading level={3} css={{ textAlign: 'center' }}>
              {t("Here's your new number in Weave")}
            </Heading>
            <div css={numberTextContainerStyle}>
              <Heading level={1} css={{ textWrap: 'nowrap' }}>
                {formatPhoneNumber(phoneNumber)}
              </Heading>
              <CopyToClipboardButton
                variant='tertiary'
                textToCopy={phoneNumber}
                hasSuccessTooltip={true}
                tooltipText={t('Copy number')}
                tooltipSuccessText={t('Number copied!')}
                hasIcon={false}
                css={{ padding: theme.spacing(1) }}
                trackingId={trackingIds.onboardingV2.temporaryNumberStep.copyToClipboardButtonClick}
              >
                <Icon name='copy' color='light' />
              </CopyToClipboardButton>
            </div>
            <Text size='medium'>{t('This number can be used to text your customers through Weave.')}</Text>
          </>
        )}
      </div>
      <div css={bannerStyle}>
        <Icon name='graduation-cap' css={{ color: theme.colors.secondary.seaweed50 }} size={24} />
        <Text size='small' css={{ color: theme.colors.secondary.seaweed50 }}>
          <Trans t={t}>
            Already have a number? You can port an existing SMS/Text number in <strong>Organization Settings</strong>.
          </Trans>
        </Text>
      </div>
      <PrimaryButton
        css={buttonStyle}
        onClick={handleContinueClick}
        disabled={temporaryPhoneNumberQuery.isLoading || intakeFormCompleteMutation.isLoading}
        trackingId={trackingIds.onboardingV2.temporaryNumberStep.continueToWeaveButtonClick}
      >
        {t('Continue to Weave')}
      </PrimaryButton>
      {isDebugMode && (
        <SecondaryButton css={{ width: 'max-content' }} onClick={onBackClick}>
          {t('Back')}
        </SecondaryButton>
      )}
    </div>
  );
};

const bannerStyle = css({
  maxWidth: 400,
  margin: theme.spacing(0, 1),
  padding: theme.spacing(1, 2),
  borderRadius: theme.borderRadius.small,
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.secondary.seaweed50}`,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
});

const numberTextContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(1, 1.5),
  background: theme.colors.neutral10,
  mixBlendMode: 'multiply',
  transform: 'translate3d(0,0,0)', // to fix the background blend mode issue in Safari
});
