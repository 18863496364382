import { PropsWithChildren } from 'react';
import { MerchantsLoadedHook } from './merchants-loaded/merchants-loaded-hook';

export type LifecycleSwitchProps = PropsWithChildren<object>;

export const LifecycleHooks = () => {
  return (
    <>
      <MerchantsLoadedHook />
    </>
  );
};
