import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useNotificationPreferences } from './store';

export const useSmsNotificationPausePreference = () => {
  const { t } = useTranslation('notifications');
  const { addSuppressRule, addPreferenceRule, isNotificationPaused } = useNotificationPreferences([
    'addSuppressRule',
    'addPreferenceRule',
    'isNotificationPaused',
  ]);

  useEffect(() => {
    const unsubscribeSuppressRule = addSuppressRule({
      id: 'sms-inbox-suppress',
      for: 'sms-message-new',
      condition: (_notification, context) => {
        return context.pathname.startsWith('/messages/inbox') && isNotificationPaused('sms-message-new');
      },
    });

    const unsubscribePreferenceRule = addPreferenceRule({
      id: 'sms-inbox-pause',
      for: 'sms-message-new',
      type: 'pause-notifications',
      title: t('Pause SMS Notifications?'),
      body: t("Want to pause SMS pop-ups while you're in the Inbox page?"),
      condition: (_notification, context) => {
        return context.pathname.startsWith('/messages/inbox');
      },
    });

    return () => {
      unsubscribeSuppressRule();
      unsubscribePreferenceRule();
    };
  }, [addSuppressRule, addPreferenceRule, isNotificationPaused, t]);
};
