import { PropsWithChildren } from 'react';
import { css, keyframes } from '@emotion/react';
import { classNames } from '@frontend/string';
import { theme } from '@frontend/theme';

type ActionableProps = PropsWithChildren<{
  isHighlighted?: boolean;
  onClick?: () => void;
  className?: string;
  isEditing?: boolean;
  actionMenuIsOpen: boolean;
  onOpenMenuAction?: () => void;
  onCloseMenuAction?: () => void;
}>;
export const ActionableMessageBase = ({
  onClick,
  isHighlighted,
  isEditing,
  actionMenuIsOpen,
  onOpenMenuAction,
  onCloseMenuAction,
  className,
  children,
}: ActionableProps) => {
  return (
    <li
      className={classNames(className, {
        'action-menu-open': actionMenuIsOpen,
        'is-editing': isEditing,
        'is-highlighted': isHighlighted,
        'is-clickable': !!onClick,
      })}
      css={style}
      onMouseEnter={isEditing ? undefined : () => onOpenMenuAction?.()}
      onMouseLeave={isEditing ? undefined : () => onCloseMenuAction?.()}
      onClick={onClick ? () => onClick() : undefined}
    >
      {children}
    </li>
  );
};

type NonActionableProps = PropsWithChildren<{
  as: 'div' | 'li';
  isHighlighted?: boolean;
  onClick?: () => void;
  className?: string;
}>;
export const NonActionableMessageBase = ({
  isHighlighted,
  onClick,
  as = 'li',
  className,
  children,
}: NonActionableProps) => {
  const Component = as;
  return (
    <Component
      className={classNames(className, {
        'is-highlighted': isHighlighted,
      })}
      css={style}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};

const highlight = keyframes`
0% {
  background: ${theme.colors.warning10}
}
100% {
  background: transparent
}
`;

const style = css`
  display: flex;
  gap: ${theme.spacing(0.5, 1)};
  padding: ${theme.spacing(1.5, 2)};
  position: relative;
  &.action-menu-open {
    background-color: ${theme.colors.neutral5};
  }
  &.is-editing {
    background-color: ${theme.colors.warning5};
  }
  &.is-highlighted {
    animation: ${highlight} 3s;
  }
`;
