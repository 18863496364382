import { useRef, useMemo, useEffect } from 'react';

/**
 * A hook for tracking a single value in its previous (most recent) and current state.
 * This keeps a ref of the previous value and returns the current and previous.
 * eg: const [previousValue, currentValue] = usePreviousValue(computeNumber());
 * @param {any} value A value to track
 * @return [previousValue, currentValue]
 */
export function usePreviousValue<T = unknown>(value: T): [T, T] {
  const prev = useRef<T>(value);
  const current = useMemo(() => value, [value]);

  useEffect(() => {
    prev.current = current;
  }, [current]);

  return [prev.current, current];
}
