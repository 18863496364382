import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { useGraphQLQuery } from '@frontend/api-payments';
import { RefundEdge, RefundListQueryResponse, RefundModel, RefundSummary } from '@frontend/api-refunds';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { downloadCSV } from '@frontend/media-helpers';
import { paymentsQueryKeys, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';
import { transformDataForCsvExport } from '../components/Refunds/RefundList/generate-columns';
import {
  allRefundsQueryString,
  getRefundsQueryFilter,
  refundListQueryString,
  usePaymentsGraphqlClient,
} from '../utils';
import { useRefundShallowStore } from './';

export const useQueryAllRefunds = () => {
  const { client } = usePaymentsGraphqlClient();
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey, locationIds } = useMultiQueryUtils();
  const { filter } = useRefundShallowStore('filter');

  const { data, isLoading, refetch } = useLocalizedQuery<RefundListQueryResponse, Error, RefundModel[]>({
    enabled: !!paymentsUrl,
    queryKey: getMultiQueryKey([paymentsQueryKeys.allRefunds, filter]),
    queryFn: () =>
      client.request(
        gql`
          ${allRefundsQueryString}
        `,
        { filter: { ...getRefundsQueryFilter(filter), locationId: locationIds } }
      ),
    select: (data) => (data?.refunds?.edges?.map((e) => e.node) ?? []) as RefundModel[],
  });

  return {
    allRefunds: data,
    allRefundsLoading: isLoading,
    refetch,
  };
};

const useRefundSearchQuery = () => {
  const { client } = usePaymentsGraphqlClient();
  const { filter, numRows, pageParams, setPageParams, resetPageParams } = useRefundShallowStore(
    'filter',
    'numRows',
    'pageParams',
    'setPageParams',
    'resetPageParams'
  );
  const { getMultiQueryKey, locationIds } = useMultiQueryUtils({ onLocationChange: () => resetPageParams() });
  const { merchant, paymentsUrl } = useMerchant();

  const { data: refunds, ...rest } = useGraphQLQuery<RefundListQueryResponse, RefundModel, RefundSummary, RefundEdge>({
    queryKey: getMultiQueryKey(['refunds', filter, numRows]),
    queryFn: (pageParam) =>
      client.request(
        gql`
          ${refundListQueryString}
        `,
        { filter: { ...getRefundsQueryFilter(filter), locationId: locationIds }, pageInfo: pageParam }
      ),
    numRows,
    pageParams,
    setPageParams,
    getPageInfo: (response?: RefundListQueryResponse) => response?.refunds?.pageInfo,
    getEdges: (response?: RefundListQueryResponse) => response?.refunds?.edges,
    getSummary: (response?: RefundListQueryResponse) => response?.refunds?.summary,
    enabled: !!paymentsUrl && !!merchant,
  });

  return {
    refunds,
    ...rest,
  };
};
export const useRefundSearch = useRefundSearchQuery;

export const useExportRefundsQuery = () => {
  const { client } = usePaymentsGraphqlClient();
  const { getMultiQueryKey, locationIds, getLocationName } = useMultiQueryUtils();
  const { filter } = useRefundShallowStore('filter');
  const alerts = useAlert();
  const { t } = useTranslation('payments');
  const { isMultiLocationFeature } = useMultiQueryUtils();

  const {
    data: refundsForExport,
    mutate: exportRefunds,
    isLoading: isExporting,
  } = useMutation<RefundListQueryResponse>({
    mutationKey: getMultiQueryKey([paymentsQueryKeys.allRefunds, filter]),
    mutationFn: () =>
      client.request(
        gql`
          ${allRefundsQueryString}
        `,
        { filter: { ...getRefundsQueryFilter(filter), locationId: locationIds } }
      ),
    onSuccess: ({ refunds }) => {
      const { edges } = refunds ?? {};
      const transformedData = transformDataForCsvExport(edges, getLocationName, isMultiLocationFeature);
      downloadCSV(transformedData, 'refunds_list');
    },
    onError: (err) => {
      alerts.error(t('Failed to export refund history'));
      console.error(err);
    },
  });

  return { refundsForExport, isExporting, exportRefunds };
};
