import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { NoPaymentsHook } from './no-payments/no-payments-hook';

export const MerchantsLoadedHook = () => {
  const {
    allMerchantsLoaded,
    lifecycleHooks: {
      allMerchantsLoaded: { noPayments },
    },
  } = useCollectPaymentMultiContext();

  return <>{allMerchantsLoaded && <>{noPayments && <NoPaymentsHook />}</>}</>;
};
