import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { FormsDigitization } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { maxFileCount, maxFileSizeBytes } from '../../../../../constants';
import { useFormDigitizationStore } from '../../../../../provider';
import { formatBytes } from '../../../../../utils';
import { useSubmitForDigitization } from './use-submit-for-digitization';

export interface RejectedFile {
  file: File;
  errorMsg: string;
}

interface SortedFiles {
  accepted: File[];
  rejected: RejectedFile[];
}

type GetFilesToUploadFn = (selectedFiles: File[]) => SortedFiles & { shouldUpload: boolean };
type UploadFormsFn = (files: File[]) => Promise<void>;

interface UseUploadFormsResults {
  uploadForms: UploadFormsFn;
}

const allowedMaxSize = formatBytes(maxFileSizeBytes);

export const useUploadForms = (): UseUploadFormsResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { t } = useTranslation('forms');
  const { submitForDigitization } = useSubmitForDigitization();
  const { isFormInDigitizationProcess, canWeStillUploadForms, getDigitizationStoreState, setLoader } =
    useFormDigitizationStore([
      'isFormInDigitizationProcess',
      'canWeStillUploadForms',
      'getDigitizationStoreState',
      'setLoader',
    ]);

  const { mutateAsync } = useMutation(FormsDigitization.API.uploadForm, {
    onSuccess: async () => {
      // if current digitization process is completed and user are uploading because current proccess is not assigned
      // Update the BE about the latest file uploaded
      if (!isFormInDigitizationProcess && canWeStillUploadForms) {
        await submitForDigitization();
      }
      return queryClient.invalidateQueries(DigitalFormsQueries.endpointKeys.getUploadedFormsDetails);
    },
  });

  const getFilesToUpload = useCallback<GetFilesToUploadFn>((selectedFiles) => {
    const { canWeStillUploadForms, latestProcessUploadedForms } = getDigitizationStoreState();
    if (!canWeStillUploadForms) {
      alert.error(t("Cannot upload form. You've already submitted all your forms"));
      return {
        accepted: [],
        rejected: [],
        shouldUpload: false,
      };
    }

    if (selectedFiles.length + latestProcessUploadedForms.length > maxFileCount) {
      alert.error(t("You're exceeding the limit of {{maxFileCount}} forms.", { maxFileCount }));
      return {
        accepted: [],
        rejected: [],
        shouldUpload: false,
      };
    }

    const sortedFiles = selectedFiles.reduce<SortedFiles>(
      (acc, file) => {
        if (file.size <= maxFileSizeBytes) {
          acc.accepted.push(file);
        } else {
          acc.rejected.push({
            file,
            errorMsg: t(`Size of the file {{fileName}} exceeds {{allowedMaxSize}}`, {
              fileName: file.name,
              allowedMaxSize,
            }),
          });
        }
        return acc;
      },
      { accepted: [], rejected: [] }
    );

    return {
      ...sortedFiles,
      shouldUpload: true,
    };
  }, []);

  const uploadForms = useCallback<UploadFormsFn>(async (files) => {
    const sortResult = getFilesToUpload(files);
    if (!sortResult.shouldUpload) {
      return;
    }

    const { locationId } = getDigitizationStoreState();
    const { accepted: acceptedFiles, rejected: rejectedFiles } = sortResult;
    try {
      if (acceptedFiles.length > 0) {
        const formData = new FormData();
        acceptedFiles.forEach((file) => formData.append('files', file));
        setLoader({ show: true, message: t('Uploading the file(s)...') });
        await mutateAsync({
          form_data: formData,
          location_id: locationId,
        });
        acceptedFiles.forEach((file) =>
          alert.success(t('Successfully uploaded the file: {{fileName}}:', { fileName: file.name }))
        );
      }
      rejectedFiles.forEach(({ errorMsg }) =>
        alert.error(t('Error while uploading the file: {{errorMsg}}', { errorMsg }))
      );
    } finally {
      setLoader({ show: false, message: '' });
    }
  }, []);

  return {
    uploadForms,
  };
};
