import { useEffect, useRef, useState } from 'react';
import { FlatIndexLocationWithAlign, ListRange, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { MediaQueries } from '@frontend/api-media';
import { PersonHelpers } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';
import { createThreadDateList } from '../utils';
import { ThreadDateTimeDivider, BackgroundHighlight, SMSThreadItem } from './thread-items';

const START_INDEX = 100000;

type ScrollState = 'atBottom' | 'atTop' | 'inMiddle';
type VirtuosoContext = {
  hasOlderMessages: boolean;
  hasNewerMessages: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isNewConversation: boolean;
  optOutBannerHeight?: number;
  mediaQueries: ReturnType<typeof MediaQueries.useMmsMedia>;
  targetSmsId?: string;
  firstUnreadMessageId?: string;
};

type ThreadListProps = {
  groupId: string;
  threadId: string;
  targetSmsData?: {
    id: string;
    createdAt: string;
  };
  personPhone: string;
  onNewMessage?: () => void;
  unreadCount?: number;
};

export const ThreadList = ({
  groupId,
  threadId,
  targetSmsData,
  personPhone,
  onNewMessage,
  unreadCount,
  ...rest
}: ThreadListProps) => {
  const { t } = useTranslation('thread-body');
  const user = getUser();
  const getThreadQuery = SMSDataV3.Queries.useGetThreadQuery({
    request: {
      threadId,
      locationId: groupId,
      taggedSmsId: targetSmsData?.id,
      taggedCreatedAt: targetSmsData?.createdAt,
      messageLimit: 50,
    },
    options: {
      retry: 1,
    },
  });
  const markAsReadMutation = SMSDataV3.Mutations.useSetThreadsStatusReadMutation();
  const isUnread = getThreadQuery.data?.pages.some((page) => page.thread.status === 'new');
  const markAsRead = () => {
    if (!markAsReadMutation.isLoading && isUnread && !!user?.userID) {
      markAsReadMutation.mutate({
        threadIds: [threadId],
        locationId: groupId,
        groupIds: [groupId],
        userId: user.userID,
      });
    }
  };

  const messagesList =
    getThreadQuery.data?.pages
      .flatMap((page) => page.thread.messages)
      .sort((a, b) => (b.createdAt < a.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0)) ?? [];
  const messagesDateList = createThreadDateList(messagesList);
  const firstItemId = messagesList.at(0)?.id ?? '';
  const contact = getThreadQuery.data?.pages?.[0]?.thread.person;
  const firstUnreadMessageId = unreadCount ? messagesList[messagesList.length - unreadCount]?.id : undefined;
  const [debouncedUnreadMessageId, setDebouncedUnreadMessageId] = useState(firstUnreadMessageId);

  useEffect(() => {
    if (debouncedUnreadMessageId && !firstUnreadMessageId) {
      setTimeout(() => {
        setDebouncedUnreadMessageId(firstUnreadMessageId);
      }, 3000);
      return;
    }

    setDebouncedUnreadMessageId(firstUnreadMessageId);
  }, [firstUnreadMessageId]);

  const mediaIds = messagesList.flatMap((message) => message.media.map(({ mediaId }) => mediaId));
  const mediaQueries = MediaQueries.useMmsMedia({ mediaIds, locationId: groupId });
  const [scrollState, setScrollState] = useState<ScrollState>(targetSmsData ? 'inMiddle' : 'atBottom');

  const [virtuosoListData, setVirtuosoListData] = useState<{
    firstItemId: string;
    firstItemIndex: number;
    listLength: number;
  }>({
    firstItemId,
    firstItemIndex: START_INDEX,
    listLength: messagesList.length,
  });
  const [hasInitiallyScrolled, setHasInitiallyScrolled] = useState(false);
  const [directionToFetch, setDirectionToFetch] = useState<'previous' | 'next'>();
  const someMediaIsLoading = Object.values(mediaQueries).some((mediaQuery) => mediaQuery.isLoading);
  const ref = useRef<VirtuosoHandle>(null);
  const targetSmsIndex = messagesDateList.findIndex(
    (message) => typeof message !== 'string' && message.id === targetSmsData?.id
  );
  const initialTopMostItemIndex: FlatIndexLocationWithAlign = {
    index: targetSmsData?.id ? targetSmsIndex : 'LAST',
    align: targetSmsData?.id ? 'center' : 'end',
    behavior: 'auto',
  };

  const handlePageFetch = async () => {
    if (directionToFetch === 'previous') {
      await getThreadQuery.fetchPreviousPage();
    } else if (directionToFetch === 'next') {
      await getThreadQuery.fetchNextPage();
    }
  };

  const handleRangeChange = (range: ListRange) => {
    if (hasInitiallyScrolled) return;
    if (!targetSmsData) {
      // Wait for first page to finish rendering before setting initially scrolled to true
      setTimeout(() => {
        setHasInitiallyScrolled(true);
      }, 500);
    } else if (
      range.startIndex <= virtuosoListData.firstItemIndex + targetSmsIndex &&
      range.endIndex >= virtuosoListData.firstItemIndex + targetSmsIndex
    ) {
      // Wait for first page to finish rendering before setting initially scrolled to true
      setTimeout(() => {
        setHasInitiallyScrolled(true);
      }, 500);
    }
  };

  useEffect(() => {
    const firstItemHasChanged = virtuosoListData.firstItemId && firstItemId !== virtuosoListData.firstItemId;
    if (!firstItemHasChanged) {
      setVirtuosoListData((prev) => ({
        ...prev,
        firstItemId,
        listLength: messagesDateList.length,
      }));
    } else {
      const newItemsCount = messagesDateList.length - virtuosoListData.listLength;
      setVirtuosoListData((prev) => ({
        ...prev,
        listLength: messagesDateList.length,
        firstItemIndex: prev.firstItemIndex - newItemsCount,
      }));
    }
    // Cooldown so that multiple pages don't get fetched accidentally at the same time
    setTimeout(() => {
      setDirectionToFetch(undefined);
    }, 500);
  }, [messagesDateList.length]);

  useEffect(() => {
    setVirtuosoListData({
      firstItemId,
      firstItemIndex: START_INDEX,
      listLength: messagesDateList.length,
    });
    setDirectionToFetch(undefined);
  }, [threadId, targetSmsData?.id]);

  useEffect(() => {
    if (!hasInitiallyScrolled && !!getThreadQuery.data && !someMediaIsLoading) {
      // use timeout here to allow the messages to finish rendering before initial scroll
      setTimeout(() => {
        ref.current?.scrollToIndex(initialTopMostItemIndex);
      }, 300);
    }
  }, [someMediaIsLoading, !!getThreadQuery.data]);

  useEffect(() => {
    if (!someMediaIsLoading && !!getThreadQuery.data) handlePageFetch();
  }, [!!getThreadQuery.data, directionToFetch]);

  useEffect(() => {
    if (firstItemId) {
      onNewMessage?.();
    }
  }, [firstItemId]);

  return (
    <Virtuoso<(typeof messagesDateList)[number], VirtuosoContext>
      key={`${threadId}-${targetSmsData?.id ?? ''}`}
      ref={ref}
      style={{
        height: 'auto',
        flexGrow: 1,
      }}
      data-trackingid={`${InboxPrefixes.Popout}-thread-list`}
      alignToBottom
      followOutput={(isAtBottom) => {
        if (isAtBottom && !getThreadQuery.hasPreviousPage) return 'smooth';
        return false;
      }}
      firstItemIndex={virtuosoListData.firstItemIndex}
      data={messagesDateList}
      startReached={() => {
        if (hasInitiallyScrolled && getThreadQuery.hasNextPage) setDirectionToFetch('next');
      }}
      atTopStateChange={(isAtTop) => {
        setScrollState(isAtTop ? 'atTop' : 'inMiddle');
      }}
      atBottomStateChange={(isAtBottom) => {
        setScrollState(isAtBottom ? 'atBottom' : 'inMiddle');
        if (isAtBottom) {
          setDirectionToFetch('previous');
          markAsRead();
        }
      }}
      onFocus={() => {
        if (scrollState === 'atBottom') {
          markAsRead();
        }
      }}
      context={{
        hasOlderMessages: !!getThreadQuery.hasNextPage,
        hasNewerMessages: !!getThreadQuery.hasPreviousPage,
        isFetching: getThreadQuery.isFetching,
        isNewConversation: getThreadQuery.isFetched && !messagesList.length,
        isLoading: (!getThreadQuery.data && !getThreadQuery.isError) || getThreadQuery.isLoading,
        mediaQueries,
        targetSmsId: targetSmsData?.id,
        firstUnreadMessageId: debouncedUnreadMessageId,
      }}
      rangeChanged={handleRangeChange}
      components={{
        Header: ({ context }) => {
          if (context?.isLoading && !context?.isNewConversation) {
            return (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(1, 0),
                  width: '100%',
                }}
              >
                <SpinningLoader size='small' />
              </div>
            );
          }

          if (context?.hasOlderMessages && context?.isFetching && !context?.isLoading) {
            return (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(1, 0),
                  width: '100%',
                }}
              >
                <SpinningLoader size='small' />
              </div>
            );
          }
          if (!context?.isNewConversation && !context?.hasOlderMessages) {
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                🎉 {t('You have reached the end')}
              </Text>
            );
          }
          return null;
        },
        Footer: ({ context }) => {
          if (context?.isNewConversation)
            return (
              <Text css={{ margin: 0, padding: theme.spacing(2, 0), textAlign: 'center' }}>
                {t('No messages yet.')}
              </Text>
            );

          if (context?.hasNewerMessages && context?.isFetching && !context?.isLoading)
            return (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(1, 0),
                  width: '100%',
                }}
              >
                <SpinningLoader size='small' />
              </div>
            );

          return null;
        },
      }}
      itemContent={(index, sms, context) => {
        if (typeof sms === 'string') {
          return <ThreadDateTimeDivider key={sms} timestamp={new Date(sms)} />;
        }

        const mediaIds = sms.media.map(({ mediaId }) => mediaId);
        const media = context.mediaQueries.reduce<NonNullable<(typeof context.mediaQueries)[number]['data']>[]>(
          (acc, { data }) => {
            if (!data || !mediaIds.includes(data.mediaId)) return acc;
            acc.push(data);
            return acc;
          },
          []
        );

        return (
          <>
            {context?.firstUnreadMessageId && sms.id === context.firstUnreadMessageId && (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                  width: '100%',
                  padding: theme.spacing(0, 1, 2),
                }}
              >
                <figure css={{ height: 1, backgroundColor: theme.colors.primary20, flexGrow: 1 }} />
                <Text size='small' color='primary' textAlign='center'>
                  {t('New')}
                </Text>
                <figure css={{ height: 1, backgroundColor: theme.colors.primary20, flexGrow: 1 }} />
              </div>
            )}
            <div
              css={{
                position: 'relative',
              }}
            >
              {context?.targetSmsId === sms.id && (
                <BackgroundHighlight
                  additionalCSS={{
                    default: {
                      top: theme.spacing(-1),
                      bottom: theme.spacing(1),
                    },
                  }}
                />
              )}
              <SMSThreadItem
                key={sms.id + index}
                sms={sms}
                avatarProps={{
                  size: 'small',
                }}
                media={media}
                css={{
                  padding: theme.spacing(0, 2),
                }}
                contactName={{
                  name: PersonHelpers.getFullName({
                    FirstName: contact?.firstName,
                    LastName: contact?.lastName,
                  }),
                  firstName: contact?.firstName,
                  lastName: contact?.lastName,
                }}
              />
            </div>
          </>
        );
      }}
      initialTopMostItemIndex={initialTopMostItemIndex}
      {...rest}
    />
  );
};
