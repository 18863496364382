import { ComponentProps } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { FeatureUpgradeIcon, FeatureUpgradeModalWrapper, useFeatureUpgradeDetailsModal } from '@frontend/self-upgrade';
import { useTooltip } from '@frontend/design-system';
import { TemplateFlowPopoverItem } from '../types';

interface FormsPromotionFlowResults {
  popoverItem?: TemplateFlowPopoverItem;
  modalProps: ComponentProps<typeof FeatureUpgradeModalWrapper>;
}

interface UseFormsPromotionFlowProps {
  tooltipText: string;
  trackingId: string;
  onUpgradeSuccess?: () => void;
}
export const useFormsPromotionFlow = ({
  tooltipText,
  trackingId,
  onUpgradeSuccess,
}: UseFormsPromotionFlowProps): FormsPromotionFlowResults => {
  const { t } = useTranslation('inbox-templates');

  const { featureUpgradeDetailsModalControls } = useFeatureUpgradeDetailsModal({ feature: Feature.FORMS });

  return {
    popoverItem: {
      iconName: 'forms',
      trackingId,
      label: <FeatureUpgradeLabelWithTooltip label={t('Digital Forms')} tooltipText={tooltipText} />,
      onClick: featureUpgradeDetailsModalControls.openModal,
    },
    modalProps: {
      modalControls: featureUpgradeDetailsModalControls,
      feature: Feature.FORMS,
      onUpgradeSuccess,
    },
  };
};

const FeatureUpgradeLabelWithTooltip = ({ label, tooltipText }: { label: string; tooltipText: string }) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'right' });
  return (
    <div css={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
      <div css={{ display: 'flex', flex: 1, justifyContent: 'space-between' }} {...triggerProps}>
        <p>{label}</p>
        <FeatureUpgradeIcon css={{ width: '12px' }} />
      </div>
      <Tooltip {...tooltipProps}> {tooltipText} </Tooltip>
    </div>
  );
};
