import { FC, PropsWithChildren } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { BgNoiseImg } from '@frontend/assets';
import { theme } from '@frontend/theme';
import { WeaveLogoIcon } from '@frontend/design-system';

interface GradientPageProps {
  pageStyles?: Interpolation<Theme>;
  showWeaveIconAtBottom?: boolean;
  bgColor?: string;
  gradientColor1?: string;
  gradientColor2?: string;
}
export const GradientPage: FC<PropsWithChildren<GradientPageProps>> = ({
  children,
  pageStyles,
  showWeaveIconAtBottom,
  bgColor = theme.colors.white,
  gradientColor1 = theme.colors.secondary.seaweed10,
  gradientColor2 = theme.colors.indigo10,
}) => {
  return (
    <div css={[rootStyles, pageStyles]}>
      <div css={gradientNoiseBGStyles(bgColor)}>
        <div css={gradientShape1Styles(gradientColor1, bgColor)} />
        <div css={gradientShape2Styles(gradientColor2, bgColor)} />
      </div>
      {showWeaveIconAtBottom && <WeaveLogoIcon size={32} css={weaveIconAtBottomStyles} />}
      {children}
    </div>
  );
};

const rootStyles = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
});

const gradientNoiseBGStyles = (bgColor: string) =>
  css({
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: `url("${BgNoiseImg}")`,
    backgroundBlendMode: 'overlay',
    overflow: 'hidden',
    backgroundColor: `${bgColor}cc`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: -1,
  });

const gradientShape1Styles = (gradientColor: string, baseColor: string) =>
  css({
    position: 'absolute',
    height: 'max(100vh, 100vw)',
    aspectRatio: '1/1',
    borderRadius: theme.borderRadius.full,
    top: 0,
    right: 0,
    transform: 'rotate(0deg) translate(10%, -65%)',
    background: `radial-gradient(ellipse at 50% 50%, ${gradientColor} 50%, ${baseColor}00 70%)`,
    animation: 'moveAround1 16s ease infinite',
    mixBlendMode: 'multiply',
    ['@keyframes moveAround1']: {
      '0%': {
        transform: 'rotate(0deg) translate(10%, -65%)',
        scale: '1',
      },
      '25%': {
        transform: 'rotate(10deg) translate(15%, -60%)',
        scale: '1.1',
      },
      '50%': {
        transform: 'rotate(5deg) translate(5%, -70%)',
        scale: '1.2',
      },
      '75%': {
        transform: 'rotate(-5deg) translate(20%, -55%)',
        scale: '1.1',
      },
      '100%': {
        transform: 'rotate(0deg) translate(10%, -65%)',
        scale: '1',
      },
    },
  });

const gradientShape2Styles = (gradientColor: string, baseColor: string) =>
  css({
    position: 'absolute',
    height: 'max(100vh, 100vw)',
    aspectRatio: '1.1/1',
    borderRadius: theme.borderRadius.full,
    bottom: 0,
    right: 0,
    transform: 'rotate(-20deg) translate(30%, 60%)',
    background: `radial-gradient(ellipse at 50% 50%, ${gradientColor} 50%, ${baseColor}00 70%)`,
    animation: 'moveAround2 16s 3s ease infinite',
    mixBlendMode: 'multiply',
    ['@keyframes moveAround2']: {
      '0%': {
        transform: 'rotate(-20deg) translate(30%, 60%)',
        scale: '1',
      },
      '20%': {
        transform: 'rotate(-10deg) translate(25%, 65%)',
        scale: '1.1',
      },
      '40%': {
        transform: 'rotate(0deg) translate(20%, 70%)',
        scale: '1.2',
      },
      '60%': {
        transform: 'rotate(10deg) translate(15%, 75%)',
        scale: '1.1',
      },
      '80%': {
        transform: 'rotate(5deg) translate(20%, 70%)',
        scale: '1.2',
      },
      '100%': {
        transform: 'rotate(-20deg) translate(30%, 60%)',
        scale: '1',
      },
    },
  });

const weaveIconAtBottomStyles = css({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  color: theme.colors.neutral30,
  zIndex: -1,
});
