import dayjs from 'dayjs';
import { formatTime } from '@frontend/date';
import { useTranslation, Trans } from '@frontend/i18n';
import { titleCase } from '@frontend/string';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  DatePickerField,
  FieldProps,
  FormRow,
  NumberField,
  RadioField,
  Stepper,
  Text,
  TimeField,
  formatDate,
} from '@frontend/design-system';
import { DaysToSend } from '../days-to-send';
import { overrideTextStyles, stepValueContainerStyles } from '../styles';
import { SendType, Step, StepId } from '../types';

type Props = {
  fieldProps: {
    date: FieldProps<
      {
        date: {
          type: 'date';
        };
      },
      'date'
    >;
    time: FieldProps<
      {
        time: {
          type: 'time';
        };
      },
      'time'
    >;
    sendOptions: FieldProps<
      {
        sendOptions: {
          type: 'radio';
        };
      },
      'sendOptions'
    >;
    sendInterval: FieldProps<
      {
        sendInterval: {
          type: 'number';
        };
      },
      'sendInterval'
    >;
    daysToSend: FieldProps<
      {
        daysToSend: {
          type: 'checklist';
        };
      },
      'daysToSend'
    >;
  };
  next: (stepId: StepId) => void;
  previous: (stepId: StepId) => void;
  readOnly: boolean;
  sendIntervalError: string;
};

export const useWhenToSendStep = ({ fieldProps, next, previous, readOnly, sendIntervalError }: Props): Step => {
  const { t } = useTranslation('bulk-messaging');
  const {
    date: dateFieldProps,
    time: timeFieldProps,
    sendInterval: sendIntervalFieldProps,
    sendOptions: sendOptionsFieldProps,
    daysToSend: daysToSendFieldProps,
  } = fieldProps;

  const convertToReadableDays = (days?: string[]) => {
    if (!days) return '';
    const lastIndex = days.length - 1;
    if (days.length === 7) return t('per day');

    return days.reduce((acc, day, index) => {
      const formattedDay = titleCase(day);
      if (days.length === 1 || index === 0) return `${acc} ${formattedDay}`;
      if (index === lastIndex) return `${acc} and ${formattedDay}`;
      return `${acc}, ${formattedDay}`;
    }, 'on');
  };

  const id: StepId = 'when-to-send';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Select When To Send Your Campaign')}</Stepper.Title>

        <Stepper.Content>
          <FormRow cols={[60, 40]} css={{ maxWidth: 400 }}>
            <DatePickerField
              {...dateFieldProps}
              label={t('Send Date')}
              disabled={readOnly}
              data-trackingid={`${BulkEmailPrefixes.Editor}-send-date-input`}
            />
            <div css={{ display: timeFieldProps.hidden ? 'none' : 'flex' }}>
              <TimeField
                {...timeFieldProps}
                label={t('Time')}
                interval={30}
                disabled={readOnly}
                hidden={false}
                data-trackingid={`${BulkEmailPrefixes.Editor}-send-time-input`}
              />
            </div>
          </FormRow>
          <FormRow>
            <RadioField
              {...fieldProps.sendOptions}
              css={{ display: fieldProps.sendOptions.hidden ? 'none' : 'block' }}
              disabled={readOnly}
            >
              <RadioField.Radio value={SendType.SendAll} trackingId={`${BulkEmailPrefixes.Editor}-send-all-radio`}>
                {t('Send entire campaign all at once')}
              </RadioField.Radio>
              <RadioField.Radio
                value={SendType.SendSpecific}
                trackingId={`${BulkEmailPrefixes.Editor}-send-daily-radio`}
              >
                <Trans t={t}>
                  Send{' '}
                  <div
                    css={{
                      display: 'inline-block',
                      width: 68,
                      margin: theme.spacing(0, 1),
                    }}
                  >
                    <NumberField
                      {...sendIntervalFieldProps}
                      aria-invalid={sendIntervalFieldProps['aria-invalid'] || sendIntervalError !== ''}
                      required={
                        sendIntervalFieldProps.required || sendOptionsFieldProps.value === SendType.SendSpecific
                      }
                      disabled={readOnly || sendOptionsFieldProps.value !== SendType.SendSpecific}
                      error=''
                      label=''
                      css={[
                        {
                          margin: 0,
                          padding: theme.spacing(0, 1),
                        },
                        sendIntervalError !== '' && {
                          borderColor: theme.colors.text.critical,
                          '&:has(:is(input, textarea):focus)': {
                            boxShadow: `0 0 0 1px ${theme.colors.text.critical}`,
                          },
                        },
                      ]}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      data-trackingid={`${BulkEmailPrefixes.Editor}-send-interval-input`}
                    />
                  </div>{' '}
                  emails per day
                </Trans>
                {sendIntervalError !== '' && (
                  <Text color='error' size='small' css={{ marginTop: theme.spacing(1) }}>
                    {sendIntervalError}
                  </Text>
                )}
              </RadioField.Radio>
            </RadioField>
          </FormRow>
          <FormRow>
            <DaysToSend
              {...daysToSendFieldProps}
              label={t('Days to Send')}
              disabled={readOnly}
              trackingId={`${BulkEmailPrefixes.Editor}-days-to-send`}
            />
          </FormRow>
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => previous(id)} position='secondary' type='none'>
            {t('Previous')}
          </Stepper.ButtonAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none'>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Select When to Send Your Campaign')}</Text>
        {dateFieldProps.value && timeFieldProps.value && sendOptionsFieldProps.value && (
          <>
            <Text css={{ ...overrideTextStyles, textTransform: 'initial' }}>
              {t('{{day}} at {{time}}', {
                day: formatDate(dayjs(dateFieldProps.value), 'M/D/YY'),
                time: formatTime(timeFieldProps.value),
              })}
            </Text>
            <Text css={{ ...overrideTextStyles, textTransform: 'initial' }}>
              {sendOptionsFieldProps.value === SendType.SendSpecific
                ? t('Send {{interval}} emails {{days}}', {
                    interval: sendIntervalFieldProps.value,
                    days: convertToReadableDays(daysToSendFieldProps.value),
                  })
                : t('Send entire email campaign all at once')}
            </Text>
          </>
        )}
      </div>
    ),
  };
};
