import { memo, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, useModalControl, NakedButton, IconButton, useTooltip, Text } from '@frontend/design-system';
import { ChannelSettingsController } from '../../constants';
import { useTeamChatSelector } from '../../providers/team-chat.provider';
import { getUserFullName } from '../../utils';
import { ConversationSettings } from '../conversation/conversation-settings/conversation-settings';

export const ThreadTitle = memo(() => {
  const t = useTranslation('team-chat').t;
  const { modalProps, openModal } = useModalControl();
  const initialTab = useRef<ChannelSettingsController>(ChannelSettingsController.about);
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  const activeConversationId = useTeamChatSelector((ctx) => ctx.activeConversationId);
  const closeThread = useTeamChatSelector((ctx) => ctx.closeThread);
  const helpers = useTeamChatSelector((ctx) => ctx.helpers);
  const users = useTeamChatSelector((ctx) => ctx.users);

  const conversation = useMemo(
    () => (activeConversationId ? helpers.getConversation(activeConversationId) : undefined),
    [activeConversationId, helpers]
  );

  const memberIds = conversation?.memberIds || [];
  const name = conversation?.name || 'New Message';
  const type = conversation?.type || 'Group';

  const members = useMemo(
    () => memberIds.map((memberId) => users?.find((user) => user.userID === memberId)).filter((user) => !!user),
    [memberIds, users]
  );

  const conversationTitle = useMemo(() => {
    if (type === 'Group') {
      return name;
    } else if (type === 'DM') {
      if (members.length <= 3) {
        return members.map((member) => getUserFullName(member.firstName, member.lastName)).join(', ');
      } else {
        const membersLength = members.length - 2;
        return (
          members
            .slice(0, 2)
            .map((member) => getUserFullName(member.firstName, member.lastName))
            .join(', ') + t(', {{membersLength}} others', { membersLength })
        );
      }
    } else {
      return t('New Message');
    }
  }, [type, name, members]);

  const openDefaultModal = () => {
    initialTab.current = ChannelSettingsController.about;
    openModal();
  };

  const onClickBack = () => {
    closeThread(conversation?.channelId || '');
  };

  return (
    <header css={componentStyles.header}>
      <IconButton
        label='Back'
        onClick={onClickBack}
        css={componentStyles.backButtonStyle}
        trackingId='team-chat-2.0-close-conversation-button'
      >
        <Icon name='caret-left-small' size={18} />
      </IconButton>
      <NakedButton
        onClick={openDefaultModal}
        css={componentStyles.settingsButton}
        trackingId='team-chat-2.0-open-channel-setting-with-about'
        {...triggerProps}
      >
        <Heading css={componentStyles.heading} level={2}>
          {t('Thread')}
        </Heading>
        {conversation && (
          <>
            <Text weight='bold'>#{conversationTitle}</Text>
            <Icon name='caret-down-tiny' size={10} css={modalProps.show && componentStyles.rotateSettingIcon} />
            <ConversationSettings
              conversationId={conversation.channelId}
              modalProps={modalProps}
              initialTab={initialTab.current}
            />
          </>
        )}
      </NakedButton>
      {conversation?.description && <Tooltip {...tooltipProps}>{conversation.description}</Tooltip>}
    </header>
  );
});

ThreadTitle.displayName = 'ConversationTitle';

const componentStyles = {
  header: css`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral20};
    display: flex;
    padding: ${theme.spacing(1.75, 2, 1.75, 1)};
    gap: ${theme.spacing(1)};
  `,
  heading: css({
    overflow: ' hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '230px',
  }),
  settingsButton: css({
    marginRight: 'auto',
    display: 'inherit',
    alignItems: 'inherit',
    gap: theme.spacing(1),
  }),
  rotateSettingIcon: css({
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s',
  }),
  backButtonStyle: css({ minWidth: '36px', padding: 0, width: '36px', height: '36px' }),
};
