import { FC } from 'react';
import { isFunction } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, ResolvedFieldProps, Text, TextareaField } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../shared/constants';

interface DigitizationNotesModalProps {
  modalProps: ModalControlModalProps;
  textAreaProps: ResolvedFieldProps<'text'>;
  onConfirm: () => void;
  onBack?: () => void;
}

const DigitizationNotesModal: FC<DigitizationNotesModalProps> = ({ modalProps, textAreaProps, onConfirm, onBack }) => {
  const { t } = useTranslation('forms');

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header onClose={modalProps.onClose}>{t('Add Notes')}</Modal.Header>
      <Modal.Body>
        <div style={styles.body}>
          <Text style={styles.content}>
            {t(
              'Do you have any additional instructions or details related to your form digitization that you’d like to share with our team?'
            )}
          </Text>
          <TextareaField
            {...textAreaProps}
            name='digitization-notes'
            label={t('Add your comments here.')}
            aria-label={t('Add your Digitization notes comments here.')}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Continue'),
          onClick: onConfirm,
          trackingId: pendoTags.formDigitization.modal.notes.continue,
        }}
        secondary={{
          label: t('Cancel'),
          onClick: modalProps.onClose,
          trackingId: pendoTags.formDigitization.modal.notes.cancel,
        }}
        tertiary={
          isFunction(onBack)
            ? {
                label: t('Back'),
                iconName: 'back',
                onClick: onBack,
                trackingId: pendoTags.formDigitization.modal.notes.back,
              }
            : undefined
        }
      />
    </Modal>
  );
};

const styles = {
  body: {
    width: '540px',
  },
  content: {
    marginBottom: theme.spacing(5),
  },
};

export default DigitizationNotesModal;
