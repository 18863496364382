import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { TagsUtils } from '@frontend/api-tag';
import { VoiceMailboxTypes } from '@frontend/api-voicemail-boxes';
import { VoicemailTypes } from '@frontend/api-voicemails';
import { AdaptoActions } from '@frontend/adapto-actions';
import { Chips } from '@frontend/chips';
import { Actions } from '@frontend/contact-actions';
import { useDevToolsStore } from '@frontend/devtools';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { useAppScopeStore } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  InfoIcon,
  TextLink,
  Text,
  DownloadIcon,
  SkeletonLoader,
  MarkReadIcon,
  MarkUnreadIcon,
  PopoverMenu,
  TrashIcon,
  UserIcon,
  usePopoverMenu,
  PopoverMenuItem,
  Chip,
  useAlert,
  VoicemailOverrideIcon,
  Avatar,
  Button,
} from '@frontend/design-system';
import { useVoicemailTagMutation } from '../../hooks/use-voicemail-tag-mutations';
import { transcriptionTrackingId } from '../../pendo-tracking';
import { CachedAudioScrubber } from '../all-calls/cached-audio-scrubber';
import { VoicemailTableDataRow } from '../all-calls/types';
import { VoicemailTagSelector } from '../voicemails/voicemail-tag-selector';
import { downloadVoicemail } from '../voicemails/voicemail-utils';
import { TranscriptionContext } from './types';

export const TagsSectionBody = ({
  tags,
  voicemailId,
  voicemailsQueryKey,
}: {
  tags: Tag[];
  voicemailId: string;
  voicemailsQueryKey: string[];
}) => {
  const { t } = useTranslation('calls');
  const [isEditing, setIsEditing] = useState(false);
  const alerts = useAlert();
  const { removeTag } = useVoicemailTagMutation({
    queryKey: voicemailsQueryKey,
    voicemailId,
    onSuccess: ({ op }) => {
      if (op === 'remove') alerts.success(t('Successfully removed tag.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Failed to remove tag.'));
    },
  });

  return (
    <div style={{ display: 'flex', gap: theme.spacing(2), flexDirection: 'column' }}>
      {tags.length === 0 && isEditing && (
        <div style={{ padding: theme.spacing(1) }}>
          <VoicemailTagSelector
            onClose={() => {
              setIsEditing(false);
            }}
            onSelect={() => {
              setIsEditing(false);
            }}
            voicemailId={voicemailId}
            queryKey={voicemailsQueryKey}
            existingTagIds={tags.map((t) => t.id)}
          />
        </div>
      )}
      {tags.length === 0 && !isEditing && <Text color='subdued'>{t('No tags added')}</Text>}
      {tags.length > 0 && (
        <div style={{ display: 'flex', gap: theme.spacing(1), padding: theme.spacing(1), flexWrap: 'wrap' }}>
          {tags.length > 0 &&
            tags.map((tag) => (
              <Chip.Tag
                isRemovable
                onRemoveClick={() => {
                  removeTag(tag);
                }}
                key={tag.id}
                color={TagsUtils.convertStringToTagColor(tag.color)}
              >
                {tag.name}
              </Chip.Tag>
            ))}
          {isEditing && (
            <VoicemailTagSelector
              css={{ padding: 0 }}
              onClose={() => {
                setIsEditing(false);
              }}
              onSelect={() => {
                setIsEditing(false);
              }}
              voicemailId={voicemailId}
              queryKey={voicemailsQueryKey}
              existingTagIds={tags.map((t) => t.id)}
            />
          )}
        </div>
      )}
      <TextLink
        disabled={isEditing}
        weight='bold'
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
        onClick={() => {
          setIsEditing(true);
        }}
        trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'add-tag' })}
      >
        <Icon name='label' /> {t('Add Tag')}
      </TextLink>
    </div>
  );
};

const ListRow = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return (
    <li
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <Text css={{ color: theme.colors.neutral70 }} size='large'>
        {label}
      </Text>
      {children}
    </li>
  );
};

export const CallDetailsSectionBody = ({
  mailboxName,
  date,
  locationId,
}: {
  mailboxName: string;
  date: string;
  locationId: string;
}) => {
  const { t } = useTranslation('calls');
  const { getLocationName } = useAppScopeStore();

  return (
    <ul
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        padding: 0;
      `}
    >
      <ListRow label={t('Time')}>
        <Text size='large'>{date}</Text>
      </ListRow>
      <ListRow label={t('Voicemail Box')}>
        <Text size='large'>{mailboxName}</Text>
      </ListRow>
      <ListRow label={t('Location')}>
        {!!locationId && <Chips.LocationChip>{getLocationName(locationId)}</Chips.LocationChip>}
        {!locationId && <span>{'--'}</span>}
      </ListRow>
    </ul>
  );
};

export const VoicemailMedia = ({
  message,
  mediaFilePath,
  updateReadStatus,
}: {
  message: VoicemailTableDataRow;
  mediaFilePath: string;
  updateReadStatus: () => void;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), padding: theme.spacing(1) }}>
      <CachedAudioScrubber onPlay={updateReadStatus} filePath={mediaFilePath} mediaId={message.mediaId ?? ''} />
    </div>
  );
};

export const TranscriptSectionBody = ({
  message,
  transcription,
  updateReadStatus,
  isOpen,
}: {
  message: VoicemailTableDataRow;
  transcription?: VoiceMailboxTypes.GetVoicemailTranscriptionResponse;
  updateReadStatus: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation('calls');
  const { options } = useDevToolsStore();
  const isDebugMode = options.showVoicemailDetails;

  useEffect(() => {
    if (!message?.readAt && isOpen) {
      setTimeout(() => {
        updateReadStatus();
      }, 4000);
    }
  }, [message?.channelId, isOpen]);

  if (!message) {
    // This is not a defined state in the designs, but all fields are optional in the schema, so we're adding this to satisfy types until they get fixed
    return (
      <div>
        <Text>{t('No message')}</Text>
      </div>
    );
  }

  if (!transcription) {
    return <SkeletonLoader animation='shimmer' width={'100%'} height={20} count={2} distance={10} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <TranscriptionTextContent transcription={transcription} />
      {isDebugMode && (
        <Text
          css={{
            padding: theme.spacing(1),
            background: theme.colors.secondary.seaweed10,
            width: 'max-content',
            borderRadius: theme.borderRadius.medium,
          }}
          size='small'
        >
          state: {transcription?.state}
        </Text>
      )}
    </div>
  );
};

type TextSize = 'large' | 'medium' | 'small';
const containerStyle = {
  display: 'flex',
  gap: theme.spacing(1),
};

const NoContent = ({ textSize = 'large' }: { textSize?: TextSize }) => {
  const { t } = useTranslation('calls');
  return (
    <div style={containerStyle}>
      <InfoIcon color='subdued' />
      <Text size={textSize}>{t('Nothing to transcribe.')}</Text>
    </div>
  );
};
const FailedContent = ({ textSize = 'large' }: { textSize?: TextSize }) => {
  const { t } = useTranslation('calls');
  return (
    <div style={containerStyle}>
      <InfoIcon color='subdued' />
      <Text size={textSize}>{t('Transcription failed.')}</Text>
    </div>
  );
};

const PendingContent = ({ textSize = 'large' }: { textSize?: TextSize }) => {
  const { t } = useTranslation('calls');
  return (
    <div style={containerStyle}>
      <InfoIcon color='subdued' />
      <Text size={textSize}>{t('Transcription pending. This may take a few minutes.')}</Text>
    </div>
  );
};

const UnavailableContent = ({ textSize = 'large' }: { textSize?: TextSize }) => {
  const { t } = useTranslation('calls');
  return (
    <div style={containerStyle}>
      <InfoIcon color='subdued' />
      <Text size={textSize}>{t('Transcription unavailable for calls before September 2023.')}</Text>
    </div>
  );
};

export const TranscriptionTextContent = ({
  transcription,
  textSize = 'large',
  className,
}: {
  textSize?: TextSize;
  transcription: VoiceMailboxTypes.GetVoicemailTranscriptionResponse;
  className?: string;
}) => {
  switch (transcription.state) {
    case VoiceMailboxTypes.TranscriptionState.STATE_HAS_CONTENT:
    case VoiceMailboxTypes.TranscriptionState.STATE_LOW_CONFIDENCE: {
      /** Special case for when there is no text (same as no content state) */
      if (!transcription.text) {
        return <NoContent textSize={textSize} />;
      }

      return <AvailableContent textSize={textSize} transcription={transcription} className={className} />;
    }
    case VoiceMailboxTypes.TranscriptionState.STATE_NO_CONTENT:
    case VoiceMailboxTypes.TranscriptionState.STATE_UNSPECIFIED:
      return <NoContent textSize={textSize} />;
    case VoiceMailboxTypes.TranscriptionState.STATE_UNSUBMITTED:
      return <PendingContent textSize={textSize} />;

    case VoiceMailboxTypes.TranscriptionState.STATE_FAILED:
      return <FailedContent textSize={textSize} />;

    case VoiceMailboxTypes.TranscriptionState.STATE_IN_PROGRESS:
      return <PendingContent textSize={textSize} />;

    case VoiceMailboxTypes.TranscriptionState.STATE_UNAVAILABLE:
      return <UnavailableContent textSize={textSize} />;

    default:
      return null;
  }
};

/**
 * Soft cap for the length of characters to allow in the collapsed text box.
 * Text will be truncated down to this length if it is too long.
 */
const MAX_COLLAPSED_TEXT_LENGTH = 500;
/**
 * A buffer to allow an excess of this many characters to still be rendered in the collapsed text box.
 * This allows some flexibility in the length of the text before it is truncated, avoiding the need to have users expand the text box
 * when the text is only slightly longer than the maximum length.
 */
const OVERFLOW_ALLOWANCE = 20;

const AvailableContent = ({
  transcription,
  textSize = 'large',
  className,
}: {
  textSize?: 'small' | 'medium' | 'large';
  transcription: VoiceMailboxTypes.GetVoicemailTranscriptionResponse;
  className?: string;
}) => {
  const { t } = useTranslation('calls');

  const [isExpanded, setIsExpanded] = useState(false);

  /**
   * The "No transcript available" state is not defined in designs.
   * This is a possible state even within the "successful" transcription states - the transcription is marked a success but there is no text attached.
   */
  const text = transcription?.text || t('No transcript available.');
  /**
   * Display text up to MAX_COLLAPSED_TEXT_LENGTH + OVERFLOW_ALLOWANCE characters,
   * but truncate down to MAX_COLLAPSED_TEXT_LENGTH if the text length exceeds MAX_COLLAPSED_TEXT_LENGTH + OVERFLOW_ALLOWANCE
   */
  const isTextOverflowing = text.length > MAX_COLLAPSED_TEXT_LENGTH + OVERFLOW_ALLOWANCE;
  let textToRender = text;
  if (isTextOverflowing && !isExpanded) {
    textToRender = `${text.substring(0, MAX_COLLAPSED_TEXT_LENGTH)}...`;
  }

  return (
    <div className={className} css={[{ ...containerStyle, flexDirection: 'column' }]}>
      <Text size={textSize} color={transcription.text ? 'default' : 'subdued'}>
        {textToRender}
      </Text>
      {isTextOverflowing && (
        <TextLink
          css={{ cursor: 'pointer', alignSelf: 'start' }}
          size='medium'
          weight='bold'
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {isExpanded ? t('Show Less') : t('Show More')}
        </TextLink>
      )}
    </div>
  );
};

export const ContactSection = ({
  contact,
  locationId,
  isMarked = false,
  children,
}: {
  contact: {
    firstName: string;
    lastName: string;
    profileName: string;
    id: string;
    number: string;
    defaultName: string;
  };
  locationId: string;
  isMarked?: boolean;
  children: React.ReactNode;
}) => {
  const { firstName, lastName, profileName, id: personId, number, defaultName } = contact;
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore('setShow');

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateAreas: '"img name" "img number"',
          gridTemplateColumns: 'auto 1fr',
          columnGap: theme.spacing(1),
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Photos.ContactProfilePhoto
          css={{ gridArea: 'img' }}
          locationId={locationId}
          name={profileName}
          personId={personId}
          disableClick={!personId}
        >
          {!!isMarked && <Avatar.Dot variant='primary' />}
        </Photos.ContactProfilePhoto>
        {(firstName || lastName) && (
          <div
            css={{ gridArea: 'name' }}
            onDoubleClick={() => {
              if (!!personId) {
                setPersonId(personId, undefined, locationId);
                setShow(true, 'contact');
              }
            }}
          >
            {!!firstName && (
              <Text as='span' size='medium' weight='bold'>
                {firstName}
              </Text>
            )}
            {!!lastName && (
              <Text as='span' size='medium'>
                {' '}
                {lastName}
              </Text>
            )}
          </div>
        )}
        {!firstName && !lastName && (
          <Text
            css={{ gridArea: 'name' }}
            weight='bold'
            size='medium'
            onClick={() => {
              if (!!personId) {
                setPersonId(personId, undefined, locationId);
                setShow(true, 'contact');
              }
            }}
          >
            {profileName || defaultName}
          </Text>
        )}
        <Text css={{ gridArea: 'number' }} size='medium' color='light'>
          {number}
        </Text>
      </div>
      {children}
    </div>
  );
};

const useActions = ({
  message,
  contact,
  updateReadStatus,
  deleteVoicemail,
  downloadVoicemail,
  openForwardVoicemailModal,
}: {
  message: VoicemailTableDataRow;
  contact: { name: string; id: string; number: string };
  updateReadStatus: (markAsRead: boolean) => Promise<VoicemailTypes.SetVoicemailViewStatusResponse>;
  deleteVoicemail: () => void;
  downloadVoicemail: () => void;
  openForwardVoicemailModal: () => void;
}) => {
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore<TranscriptionContext>('setShow');
  const { t } = useTranslation('calls');

  return [
    {
      id: 'view-profile',
      label: t('View Profile'),
      Icon: UserIcon,
      trackingId: transcriptionTrackingId({ subComponent: 'panel', context: 'view-profile' }),
      disabled: !contact.id,
      onClick: () => {
        if (!!contact.id) {
          setPersonId(contact.id, true, message?.locationIds[0] ?? '');
          setShow(true, 'contact');
        }
      },
    },
    {
      id: 'download-voicemail',
      label: t('Download Voicemail'),
      Icon: DownloadIcon,
      trackingId: transcriptionTrackingId({ subComponent: 'panel', context: 'download-voicemail' }),
      onClick: () => {
        downloadVoicemail();
      },
    },
    {
      id: 'mark-unread',
      label: t('Mark Unread'),
      Icon: MarkUnreadIcon,
      hide: !message?.readAt,
      trackingId: transcriptionTrackingId({ subComponent: 'panel', context: 'mark-unread' }),
      onClick: () => updateReadStatus(false),
    },
    {
      id: 'mark-read',
      label: t('Mark Read'),
      Icon: MarkReadIcon,
      hide: !!message?.readAt,
      trackingId: transcriptionTrackingId({ subComponent: 'panel', context: 'mark-read' }),
      onClick: () => updateReadStatus(true),
    },
    {
      id: 'forward-voicemail',
      label: t('Forward Voicemail'),
      Icon: VoicemailOverrideIcon,
      trackingId: transcriptionTrackingId({ subComponent: 'panel', context: 'forward-voicemail' }),
      destructive: true,
      onClick: () => {
        openForwardVoicemailModal();
      },
    },
    {
      id: 'delete-voicemail',
      label: t('Delete Voicemail'),
      Icon: TrashIcon,
      trackingId: transcriptionTrackingId({ subComponent: 'panel', context: 'delete-voicemail' }),
      destructive: true,
      onClick: () => {
        deleteVoicemail();
      },
    },
  ];
};

export const ContactActions = ({
  contact,
  locationId,
  mediaFilePath,
  date,
  message,
  updateReadStatus,
  deleteVoicemail,
  openForwardVoicemailModal,
}: {
  contact: { name: string; id: string; number: string };
  locationId: string;
  mediaFilePath: string;
  date: string;
  message: VoicemailTableDataRow;
  updateReadStatus: (markAsRead: boolean) => Promise<VoicemailTypes.SetVoicemailViewStatusResponse>;
  deleteVoicemail: () => void;
  openForwardVoicemailModal: () => void;
}) => {
  const { t } = useTranslation('calls');
  const actions = useActions({
    message,
    contact,
    updateReadStatus,
    deleteVoicemail,
    downloadVoicemail: () => downloadVoicemail(mediaFilePath, contact.name, date),
    openForwardVoicemailModal: openForwardVoicemailModal,
  });

  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu({
    interactionOptions: {
      listNavigation: {
        openOnArrowKeyDown: false,
      },
    },
    placement: 'left',
  });

  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        gap: ${theme.spacing(1)};
      `}
    >
      <AdaptoActions enableCloseEvent iconColor='inherit' variant='icon-buttons' css={{ display: 'flex' }}>
        <Actions.Call
          label={t(`Call ${contact.name}`)}
          context={{ personId: contact.id, phoneNumber: contact.number, locationId }}
          trackingIdSuffix={transcriptionTrackingId({ subComponent: 'panel', context: 'call-contact' })}
        />
        <Actions.Message
          label={t(`Text ${contact.name}`)}
          context={{ personId: contact.id, phoneNumber: contact.number, locationId }}
          trackingIdSuffix={transcriptionTrackingId({ subComponent: 'panel', context: 'message-contact' })}
        />
      </AdaptoActions>
      <Button
        variant='secondary'
        css={{
          padding: theme.spacing(1),
          minWidth: '40px',
          minHeight: '40px',
          border: 'none',
          ':hover': {
            backgroundColor: theme.colors.neutral5,
          },
        }}
        {...getTriggerProps()}
      >
        <Icon size={24} name='more' />
        <PopoverMenu {...getMenuProps()} title={''} css={{ maxWidth: 320 }}>
          {actions
            .filter((action) => !action.hide)
            .map((action, index) => (
              <PopoverMenuItem
                {...getItemProps({
                  onClick: () => {
                    action.onClick?.();
                    close();
                  },
                  index,
                })}
                css={action.disabled && { pointerEvents: 'auto' }}
                key={action.id}
                Icon={action.Icon}
                label={action.label}
                disabled={action.disabled}
                trackingId={action.trackingId}
              >
                {action.label ?? ''}
              </PopoverMenuItem>
            ))}
        </PopoverMenu>
      </Button>
    </section>
  );
};
