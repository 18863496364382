import React from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { BANNER_ICONS, BANNER_ICON_COLORS } from '../constants';
import { BannerType, BannerAction } from '../types';
import { BannerAction as BannerActionComponent } from './banner-action';
import { BannerContent } from './banner-content';
import { DismissButton } from './dismiss-button';
import { getBaseStyles, getContentStyles, getActionContainerStyles } from './styles';

export interface GlobalBannerBaseProps {
  type: BannerType;
  icon?: React.ReactNode;
  title: string;
  message: React.ReactNode;
  action?: BannerAction;
  trackingId?: string;
  isDismissible?: boolean;
  onDismiss?: () => void;
  onModalClose?: () => void;
  onModalOpen?: () => void;
}

export const GlobalBannerBase: React.FC<GlobalBannerBaseProps> = ({
  type,
  icon,
  title,
  message,
  action,
  trackingId,
  isDismissible,
  onDismiss,
  onModalClose,
  onModalOpen,
}) => (
  <motion.div css={getBaseStyles}>
    {icon}
    <Icon
      name={BANNER_ICONS[type]}
      css={[
        css`
          color: ${BANNER_ICON_COLORS[type]};
          @container (max-width: ${breakpoints.small.min}px) {
            align-self: flex-start;
          }
        `,
      ]}
    />
    <motion.div css={getContentStyles}>
      <BannerContent
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        title={title}
        message={message}
        action={action}
      />
      <div css={getActionContainerStyles}>
        <BannerActionComponent action={action} trackingId={trackingId} />
        <DismissButton show={!!isDismissible} onDismiss={onDismiss} />
      </div>
    </motion.div>
  </motion.div>
);
