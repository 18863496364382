import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import type { TerminalPaymentStatus, ITerminalStrategyError } from '@frontend/payments-terminal-controller';
import { useInvoicePerson } from '../../../../../hooks';
import { ReaderStatusDisplay } from './reader-status-display';

type ReaderStatusAdapterProps = {
  status: TerminalPaymentStatus;
  error?: ITerminalStrategyError;
  isTerminalInUse: boolean;
  invoice: InvoiceModel | undefined;
  handleEndActivePayment: () => void;
};

export const ReaderStatusAdapter = ({
  status,
  error,
  isTerminalInUse,
  invoice,
  handleEndActivePayment,
}: ReaderStatusAdapterProps) => {
  const { t } = useTranslation('payments');

  const { personFullName } = useInvoicePerson(invoice);

  const getVariantProps = (): Parameters<typeof ReaderStatusDisplay>[number] => {
    const defaultProps = {
      title: personFullName,
      amount: invoice?.billedAmount,
    };

    // show error regardless of status?
    if (isTerminalInUse) {
      return {
        ...defaultProps,
        message: t('This terminal is in use.'),
        variant: 'actionable-error',
        actionLabel: t('Override Terminal Payment'),
        onActionClick: handleEndActivePayment,
      };
    } else if (error) {
      return {
        ...defaultProps,
        message: error.message,
        variant: 'error',
      };
    }

    switch (status) {
      case 'not_ready':
        return {
          ...defaultProps,
          message: t('Initializing payment...'),
          variant: 'initializing',
        };
      case 'initializing':
        return {
          ...defaultProps,
          message: t('Initializing payment...'),
          variant: 'initializing',
        };
      case 'connecting':
        return {
          ...defaultProps,
          message: t('Connecting to terminal...'),
          variant: 'initializing',
        };
      case 'waiting-input':
        return {
          ...defaultProps,
          message: t('Waiting for customer'),
          variant: 'waiting-input',
        };
      case 'processing':
        return {
          ...defaultProps,
          message: t('Processing'),
          variant: 'processing',
        };
      case 'error':
        return {
          ...defaultProps,
          message: 'Card Declined',
          variant: 'error',
        };
      case 'success':
        return {
          ...defaultProps,
          message: 'Payment Accepted',
          variant: 'success',
        };
      default: {
        const _exhaustiveCheck: never = status;
        return _exhaustiveCheck;
      }
    }
  };

  const { title, amount, message, variant, actionLabel, onActionClick } = getVariantProps();

  return (
    <ReaderStatusDisplay
      title={title}
      amount={amount}
      message={message}
      variant={variant}
      actionLabel={actionLabel}
      onActionClick={onActionClick}
    />
  );
};
