import { FC, useState } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { GlobalBannerDynamic } from '@frontend/global-info-banner';
import { useTranslation } from '@frontend/i18n';
import { SelfUpgradeFeatureNameMap } from '../constants/self-upgrade';
import { getFeatureWiseTrackingId, SelfUpgradeLandingPageTrackingIds } from '../constants/tracking-ids';

interface FeatureMultiLocationUpgradeAlertProps {
  feature: Feature;
  onUpgradeActionClick: () => void;
}

export const FeatureMultiLocationUpgradeAlert: FC<FeatureMultiLocationUpgradeAlertProps> = ({
  feature,
  onUpgradeActionClick,
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'multi-location-upgrade-alert' });
  const [bannerDismissed, setBannerDismissed] = useState(false);

  if (bannerDismissed) {
    return null;
  }

  return (
    <GlobalBannerDynamic
      type='info'
      id={`${feature}-multi-location-upgrade-alert-banner`}
      message={t("Some locations you're viewing need to be upgraded to access {{feature}}.", {
        feature: SelfUpgradeFeatureNameMap[feature],
      })}
      isDismissible
      onDismiss={() => setBannerDismissed(true)}
      title={t('{{feature}} is only available for some locations', {
        feature: SelfUpgradeFeatureNameMap[feature],
      })}
      trackingId={getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.multiLocationBannerUpgradeAction)}
      action={{
        label: t('Upgrade Locations'),
        onClick: onUpgradeActionClick,
      }}
    />
  );
};
