import { useState } from 'react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { MediaTypes } from '@frontend/api-media';
import { MMS_MAX_SIZE, MessagesHooks, SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { SMSSendV3 } from '@frontend/api-sms-send';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaSMSService } from '@frontend/schema';
import { useAppFlagStore } from '@frontend/shared';
import { ThreadSendingAreaUtils } from '@frontend/thread-sending-area';
import { sentry } from '@frontend/tracking';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ContextMenuActions as DSContextMenuActions, ModalControlResponse, useAlert } from '@frontend/design-system';
import { HIDE_DELETE_MODAL } from '../constants';
import { LocalSendingStatus, SentMessage } from '../types';
import { MediaInteractionUtils } from '../utils';

type ContextMenuActions = (Omit<DSContextMenuActions[number], 'label'> & {
  label: string;
})[];

type Props = {
  message: SentMessage;
  modalControl: ModalControlResponse;
  groupId: string;
  isDeleted: boolean;
};

type BubbleActions = {
  globalActions: DSContextMenuActions;
  mediaActions: (mediaItem: Partial<SchemaSMSSharedModels.Media>) => DSContextMenuActions;
  messageActions: DSContextMenuActions;
  handleSmsDelete: () => void;
  retrySend: (mediaItem?: Partial<SchemaSMSSharedModels.Media>) => void;
};

export const useBubbleActions = ({ message, modalControl, groupId, isDeleted }: Props): BubbleActions => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const user = getUser();
  const { featureFlags } = useAppFlagStore();
  const { clearFiles } = MessagesHooks.useMediaUploader({
    maxFileSize: MMS_MAX_SIZE,
    onExceedMaxFiles: () => {
      alert.error({ message: t('You can only attach up to 10 images at a time.') });
    },
  });
  const smsId = message.id;

  // TODO: remove this FF check after rolling this out to all users.
  const canCheckForDeleteSMSACL = !!featureFlags[groupId]?.get('inbox-sms-delete-acl-check')?.value;
  const canDeleteSMS = canCheckForDeleteSMSACL ? hasSchemaACL(groupId, Permission.SMS_INBOX_MESSAGE_DELETE) : true;

  const [files, setFiles] = useState<MediaTypes.MediaUploadFile[]>([]);

  const { mutate: deleteSms } = SMSDataV3.Mutations.useDeleteSMSMutation();

  const handleSmsDelete = async () => {
    deleteSms(
      {
        smsId,
        locationId: groupId,
        userId: user?.userID ?? '',
      },
      {
        onSuccess: () => {
          alert.success(t('Message deleted'));
        },
        onError: () => {
          alert.error(t('Error deleting message'));
        },
      }
    );
  };

  const copySms = () => {
    navigator.clipboard.writeText(message?.body ?? '');
    alert.success(t('Message Copied'));
  };

  const { isLoading, mutate: sendMessage } = SMSSendV3.Mutations.useSendMutation({
    options: {
      onSuccess: () => {
        clearFiles();
      },
      onError: () => {
        alert.error(t('Error resending message'));
      },
    },
  });
  const hasRetry =
    !isLoading &&
    (message.status === SchemaSMSSharedEnums.Status.STATUS_ERROR ||
      message.status === SchemaSMSSharedEnums.Status.STATUS_NOT_SENT);

  const retrySend = async (mediaItem?: Partial<SchemaSMSSharedModels.Media>) => {
    if (!groupId) {
      alert.error(t('Error resending message.'));
      return;
    }
    const mediaId = mediaItem?.mediaId ?? '';
    if (mediaItem) {
      const blob = await MediaInteractionUtils.generateBlob(mediaId);
      if (!blob) return;
      const imageName = MediaInteractionUtils.getImageName({ id: mediaId, filename: mediaItem?.filename ?? '' });
      const file = new File([blob], imageName, { type: blob.type });
      setFiles((prev) => [
        ...prev,
        {
          file,
          uploaded: true,
          mediaId: mediaId,
          previewUrl: window.URL.createObjectURL(blob),
        },
      ]);
    }
    const deleteRequest = {
      smsId,
      locationId: groupId,
      userId: user?.userID ?? '',
    };
    await SchemaSMSService.DeleteSMS(deleteRequest);
    sendMessage({
      body: message.body ?? '',
      media: files.flatMap(({ mediaId }) => (mediaId ? [{ mediaId }] : [])),
      createdBy: user?.userID ?? '',
      departmentId: message.departmentId,
      locationId: groupId,
      personPhone: message.personPhone ?? '',
      locationPhone: message.locationPhone,
      programSlugId: message.programSlugId ?? '',
      personId: message.personId ?? '',
      relatedIds: message.relatedIds,
      messageType: ThreadSendingAreaUtils.getMessageTypeFromRelatedIds(message.relatedIds ?? []),
      _otherOptions: message?.threadId
        ? {
            threadId: message.threadId,
          }
        : undefined,
    });
  };

  const COPY_MSG_ACTION = {
    label: t('Copy Message'),
    Icon: () => <Icon name='copy' size={18} />,
    onClick: () => copySms(),
    trackingId: `${InboxPrefixes.Thread}-message-action-copy`,
  };
  const COPY_IMG_ACTION = (mediaItem: Partial<SchemaSMSSharedModels.Media>) => ({
    label: t('Copy Image'),
    Icon: () => <Icon name='copy' size={18} />,
    onClick: () => {
      try {
        MediaInteractionUtils.copyImage(mediaItem.mediaId!, groupId);
        alert.success(t('Image Copied'));
      } catch (err) {
        alert.error(t('Error copying image'));
        sentry.error({
          error: err,
          topic: 'messages',
          severityLevel: 'error',
          addContext: {
            name: 'Message object & media item',
            context: {
              message,
              mediaItem,
              errMessage: 'Error copying image from message',
            },
          },
        });
      }
    },
    disabled: !mediaItem.mediaId,
    trackingId: `${InboxPrefixes.Thread}-message-action-copy-image`,
  });
  const DOWNLOAD_IMG_ACTION = (mediaItem: Partial<SchemaSMSSharedModels.Media>) => ({
    label: t('Download Image'),
    Icon: () => <Icon name='download' size={18} />,
    onClick: () => {
      try {
        MediaInteractionUtils.downloadImage({
          mediaId: mediaItem.mediaId!,
          filename: mediaItem.filename ?? `${message.personPhone}-${t('image-download')}`,
          locationId: groupId,
        });
        alert.success(t('Image downloaded'));
      } catch (err) {
        alert.error(t('Error downloading image'));
        sentry.error({
          error: err,
          topic: 'messages',
          severityLevel: 'error',
          addContext: {
            name: 'Message object & media item',
            context: {
              message,
              mediaItem,
              errMessage: 'Error downloading image from message',
            },
          },
        });
      }
    },
    disabled: !mediaItem.mediaId,
    trackingId: `${InboxPrefixes.Thread}-message-action-download-image`,
  });
  const RETRY_MSG_ACTION = {
    label: t('Retry Message Send'),
    Icon: () => <Icon name='update' size={18} />,
    onClick: () => retrySend(),
    trackingId: `${InboxPrefixes.Thread}-message-action-retry`,
  };
  const RETRY_IMG_ACTION = (mediaItem: Partial<SchemaSMSSharedModels.Media>) => ({
    label: t('Retry Image Send'),
    onClick: () => retrySend(mediaItem),
    trackingId: `${InboxPrefixes.Thread}-message-action-retry`,
  });
  const DELETE_ACTION = {
    label: t('Delete from Thread'),
    Icon: () => <Icon name='trash' size={18} />,
    disabled: !canDeleteSMS,
    hoverLabel: !canDeleteSMS ? t('You do not have permission to delete messages.') : undefined,
    onClick: () => {
      const showDeleteModal = localStorage.getItem(HIDE_DELETE_MODAL) !== 'true';
      if (showDeleteModal) {
        modalControl.openModal();
      } else {
        handleSmsDelete();
      }
    },
    trackingId: `${InboxPrefixes.Thread}-message-action-delete`,
  };

  const getActions = (mediaItem?: Partial<SchemaSMSSharedModels.Media>) =>
    [
      !isDeleted && !mediaItem && COPY_MSG_ACTION,
      mediaItem && COPY_IMG_ACTION(mediaItem),
      mediaItem && DOWNLOAD_IMG_ACTION(mediaItem),
      hasRetry && !mediaItem && RETRY_MSG_ACTION,
      hasRetry && mediaItem && RETRY_IMG_ACTION(mediaItem),
      message.localSendingStatus !== LocalSendingStatus.SENDING && !isDeleted && DELETE_ACTION,
    ].filter(Boolean);

  const globalActions: ContextMenuActions = [
    !isDeleted && COPY_MSG_ACTION,
    message.localSendingStatus !== LocalSendingStatus.SENDING && !isDeleted && DELETE_ACTION,
  ].filter(Boolean);

  return {
    globalActions,
    mediaActions: getActions,
    messageActions: getActions(),
    handleSmsDelete,
    retrySend,
  };
};
