import { useState, useEffect, useMemo } from 'react';
import { useGetTasks, useGetPersons } from '@frontend/api-task-center';
import { UsersQueries } from '@frontend/api-users';
import { useAppScopeStore } from '@frontend/scope';
import { TaskCenterContext } from '../types';

export const DEFAULT_CONTEXT_VALUE: TaskCenterContext = {
  showCreateTask: false,
  setShowCreateTask: () => {},
  tasks: [],
  locationId: '',
  patients: [],
  assignees: [],
  locationsData: [],
  shouldShowLocationDropdown: false,
};

export const useGetContextValues = (): TaskCenterContext => {
  // Static values
  const [showCreateTask, setShowCreateTask] = useState(false);
  const { accessibleGroupData, selectedOrgId, selectedLocationIds } = useAppScopeStore();

  const accessibleLocations = accessibleGroupData[selectedOrgId].children; //orgIdMap[selectedOrgId].locations;

  const locationsData = useMemo(
    () =>
      accessibleLocations.map((location) => {
        return { id: location.id, name: location.name };
      }),
    [accessibleLocations]
  );

  const shouldShowLocationDropdown = accessibleLocations.length > 1 && selectedLocationIds.length > 1;

  // Dynamic values
  const { data: tasksData, refetch } = useGetTasks();
  // The useQuery is not working as expected, hence using a useEffect to refetch the data
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, []);

  const { data: patients } = useGetPersons();
  const { data: assignees } = UsersQueries.useGetUsersInLocations(selectedLocationIds);

  return {
    showCreateTask,
    setShowCreateTask,
    tasks: tasksData?.tasks,
    locationId: selectedLocationIds[0],
    patients: patients?.rows ?? [],
    assignees: assignees?.users ?? [],
    locationsData,
    shouldShowLocationDropdown,
  };
};
