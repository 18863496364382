import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Button,
  ButtonBar,
  FormRow,
  Text,
  PhoneField,
  TextField,
  TextareaField,
  useForm,
  SpinningLoader,
  styles,
  DateField,
  DropdownField,
  EmailField,
  useAlert,
  Heading,
} from '@frontend/design-system';
import { getIsIntegratedOffice } from '../../../../utils';
import { getShouldRenderSourceDropDown } from '../../EventsPanel/ScheduleActions/ScheduleRequests/utils';
import { useManagePersonListContext } from './PersonSelectorContext';
import { personSelectorStyles } from './styles';

export const CustomContact = () => {
  const alert = useAlert();
  const { t } = useTranslation('personSelector');
  const {
    isLoading,
    closePopoverDialog,
    shouldRenderNotes,
    formFields,
    dataSources,
    clientLocationIds,
    addContactScreenTitle,
    handleAddCustomerContact,
    addToListButtonTrackingId,
    cancelButtonTrackingId,
    patientWritebackSourceTenants,
    shouldAddAndLink,
  } = useManagePersonListContext();

  const { isScheduleV3FlagEnabled } = SchedulerV3.Helpers.useGetSchedulerV3FeatureFlagDetails();
  const isIntegratedOffice = getIsIntegratedOffice(dataSources ?? []);
  const shouldRenderDataSourceDropdown = getShouldRenderSourceDropDown(dataSources ?? []);
  const isV3Integrated =
    isScheduleV3FlagEnabled && isIntegratedOffice && Number(patientWritebackSourceTenants?.length) > 1;

  const { formProps, getFieldProps, isComplete, values, seedValues } = useForm({
    fields: {
      firstName: {
        type: 'text',
        value: formFields?.firstName?.value ?? '',
        required: !!formFields?.firstName?.required,
      },
      lastName: {
        type: 'text',
        value: formFields?.lastName?.value ?? '',
        required: !!formFields?.lastName?.required,
      },
      phoneNumber: {
        type: 'phone',
        value: formFields?.phoneNumber?.value ?? '',
        required: !!formFields?.phoneNumber?.required,
      },
      notes: {
        type: 'text',
        value: formFields?.notes?.value ?? '',
        required: !!formFields?.notes?.required,
      },
      dateOfBirth: {
        type: 'date',
        value: formFields?.dateOfBirth?.value ?? '',
        required: !!formFields?.dateOfBirth?.required,
      },
      gender: {
        type: 'dropdown',
        value: formFields?.gender?.value ?? '',
        required: !!formFields?.gender?.required,
      },
      email: {
        type: 'email',
        value: formFields?.email?.value ?? '',
        required: !!formFields?.email?.required,
      },
      source: {
        type: 'dropdown',
        value: formFields?.source?.value ?? '',
        required: !!formFields?.source?.required && shouldRenderDataSourceDropdown,
      },
      clientLocationId: {
        type: 'dropdown',
        value: formFields?.clientLocationId?.value ?? '',
      },
    },
  });

  const filteredClientLocations = useMemo(() => {
    if (!values.source) return [];
    return clientLocationIds?.filter((clientLocation) => clientLocation.SourceID === values.source);
  }, [values.source]);

  const handleAddPatient = async () => {
    try {
      if (isComplete && handleAddCustomerContact) {
        handleAddCustomerContact({
          firstName: values.firstName ?? '',
          lastName: values.lastName ?? '',
          phoneNumber: values.phoneNumber ?? '',
          dateOfBirth: values.dateOfBirth ?? '',
          email: values.email ?? '',
          gender: values.gender ?? '',
          source: values.source ?? '',
          notes: values.notes ?? '',
          clientLocationId: values.clientLocationId ?? '',
        });
      }
    } catch (err) {
      alert.error(t('Error Adding Custom Contact'));
    }
  };

  useEffect(() => {
    if (dataSources?.length === 1) {
      seedValues({
        ...values,
        source: dataSources[0].SourceID,
      });
    }
    if (filteredClientLocations?.length === 1) {
      seedValues({
        ...values,
        clientLocationId: filteredClientLocations[0].ClientLocationID,
      });
    }
  }, [dataSources, filteredClientLocations]);

  useEffect(() => {
    if (patientWritebackSourceTenants?.length === 1) {
      seedValues({
        ...values,
        source: patientWritebackSourceTenants[0].sourceTenantId,
      });
    }
  }, [patientWritebackSourceTenants]);

  const shouldRenderClientLocationDropdown =
    isIntegratedOffice &&
    !!formFields.clientLocationId &&
    !!filteredClientLocations &&
    filteredClientLocations.length > 1;

  if (isLoading)
    return (
      <div css={css([{ height: 300 }, styles.flexCenter])}>
        <SpinningLoader />
      </div>
    );

  return (
    <div css={{ paddingTop: theme.spacing(1) }}>
      <div css={personSelectorStyles.header}>
        <Heading level={3} css={{ lineHeight: '24px' }}>
          {addContactScreenTitle}
        </Heading>
      </div>
      <form {...formProps} css={personSelectorStyles.content}>
        <FormRow cols={[50, 50]}>
          <TextField {...getFieldProps('firstName')} label={t('*First Name')} placeholder={t('*First Name')} />
          <TextField {...getFieldProps('lastName')} label={t('*Last Name')} placeholder={t('*Last Name')} />
        </FormRow>
        <FormRow cols={!!formFields.dateOfBirth && !!formFields.gender ? [50, 50] : [100]}>
          {!!formFields.dateOfBirth && (
            <DateField {...getFieldProps('dateOfBirth')} label={t('Date of Birth')} placeholder={t('*Date of Birth')} />
          )}
          {!!formFields.gender && (
            <DropdownField {...getFieldProps('gender')} label={t('Gender')}>
              <DropdownField.Option value='Male'>Male</DropdownField.Option>
              <DropdownField.Option value='Female'>Female</DropdownField.Option>
              <DropdownField.Option value='Other'>Other</DropdownField.Option>
            </DropdownField>
          )}
        </FormRow>
        <FormRow cols={!!formFields.phoneNumber && !!formFields.email ? [50, 50] : [100]}>
          {!!formFields.phoneNumber && (
            <PhoneField {...getFieldProps('phoneNumber')} label={t('*Phone Number')} placeholder={t('*Phone Number')} />
          )}
          {!!formFields.email && (
            <EmailField {...getFieldProps('email')} label={t('*Email')} placeholder={t('*Email')} />
          )}
        </FormRow>
        {!!formFields.source && !isV3Integrated && shouldRenderDataSourceDropdown && (
          <FormRow>
            <DropdownField {...getFieldProps('source')} label={t('Source')}>
              {dataSources?.map(({ SourceID, SourceName, PracticeManagementSystem }) => (
                <DropdownField.Option value={SourceID} key={SourceID}>
                  <Text as='span' css={{ marginRight: theme.spacing(1) }}>
                    {SourceName}
                  </Text>
                  <Text as='span' color='light' size='small'>
                    {PracticeManagementSystem}
                  </Text>
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
        )}
        {!!formFields.source && isV3Integrated && (
          <FormRow>
            <>
              {!!patientWritebackSourceTenants?.length && (
                <DropdownField label={t('Location')} {...getFieldProps('source')}>
                  {patientWritebackSourceTenants?.map(({ sourceTenantId = '', name, pmsName }) => (
                    <DropdownField.Option value={sourceTenantId} key={name}>
                      <Text as='span' css={{ marginRight: theme.spacing(1) }}>
                        {name}
                      </Text>
                      <Text as='span' color='light' size='small'>
                        {pmsName}
                      </Text>
                    </DropdownField.Option>
                  ))}
                </DropdownField>
              )}
            </>
          </FormRow>
        )}
        {!isV3Integrated && shouldRenderClientLocationDropdown && (
          <FormRow>
            <DropdownField {...getFieldProps('clientLocationId')} label={t('Location')}>
              {filteredClientLocations?.map(({ ClientLocationID, Name }) => (
                <DropdownField.Option value={ClientLocationID} key={ClientLocationID}>
                  <Text as='span' css={{ marginRight: theme.spacing(1) }}>
                    {Name}
                  </Text>
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
        )}
        {shouldRenderNotes && (
          <FormRow>
            <TextareaField
              {...getFieldProps('notes')}
              label={t('Patient notes (optional)')}
              placeholder={t('Patient notes (optional)')}
            />
          </FormRow>
        )}
      </form>
      <ButtonBar css={personSelectorStyles.footer}>
        <Button variant='secondary' trackingId={cancelButtonTrackingId} onClick={closePopoverDialog}>
          {t('Cancel')}
        </Button>
        {shouldAddAndLink ? (
          <Button trackingId={addToListButtonTrackingId} disabled={!isComplete} onClick={handleAddPatient}>
            {t('Add and Link')}
          </Button>
        ) : (
          <Button trackingId={addToListButtonTrackingId} disabled={!isComplete} onClick={handleAddPatient}>
            <Icon name='plus-small' color='white' />
            {t('Add to List')}
          </Button>
        )}
      </ButtonBar>
    </div>
  );
};
