import { useCallback } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { RefundFilterType, RefundModel, RefundSearchParams } from '@frontend/api-refunds';
import { PrintDialog } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, PrintIcon, Table, UpdateIcon, useAlert } from '@frontend/design-system';
import {
  useRefundShallowStore,
  useRefundSearch,
  useGenerateColumns,
  useCanDoAction,
  useQueryAllRefunds,
} from '../../../hooks';
import { useAppVariantContext } from '../../../providers';
import { PaymentsTableInstances, isPaymentsAppVariant } from '../../../utils';
import { PrintHeader } from '../../PrintHeader';
import { useCsvExport } from '../CSVExport';
import { RefundFilter } from '../RefundFilter';
import { generateColumns } from './generate-columns';

const FIELD_TO_ORDER: { [key: string]: RefundSearchParams['order'] } = {
  billedAmount: 'billedamount',
  billedAt: 'billedAt',
  paidAmount: 'paidamount',
  paidAt: 'paidat',
};

export type RefundListProps = { handlePrint: () => void; onChangeFilter?: (filter: RefundFilterType) => void };

export const RefundList = ({ handlePrint, onChangeFilter }: RefundListProps) => {
  const { t } = useTranslation('payments');
  const navigate = useNavigate();
  const alert = useAlert();
  const { canExport } = useCanDoAction();
  const { variant } = useAppVariantContext();
  const columns = useGenerateColumns<RefundModel>(generateColumns);

  // Refund Provider
  const { numRows, setNumRows, setOrder, filter, setFilter, resetPageParams } = useRefundShallowStore(
    'numRows',
    'setNumRows',
    'setOrder',
    'filter',
    'setFilter',
    'resetPageParams'
  );

  const { refunds, refetch, loading, hasMore, hasPrevious, fetchNextPage, fetchPreviousPage, currentPage } =
    useRefundSearch();

  const { ExportAction, InvoiceExportModal } = useCsvExport({
    dateRange: { start: filter.dateRefunded?.gte, end: filter.dateRefunded?.lte },
    hasSearchParams: !!filter.personName,
    hasFiltersOtherThanDates: Object.keys(filter).filter((value) => value !== 'dateRefunded').length > 0,
  });

  const handlePrintClick = () => {
    if (!refunds.length) {
      alert.error('No data to print. Apply a filter and try again.');
    } else {
      handlePrint();
    }
  };

  const handleChangeFilter = useCallback(
    (toSet: RefundFilterType) => {
      setFilter(toSet);
      onChangeFilter?.(toSet);
    },
    [onChangeFilter]
  );

  return (
    <>
      <Table
        tableStyle={css`
          min-height: 500px;
          height: 100%;
        `}
        globalTrackingId='pay-portal-refunds'
        colConfig={columns}
        data={refunds}
        tableInstanceId={PaymentsTableInstances.Refunds}
        isLoading={loading}
        loadingRowsCount={numRows}
        manualSortBy
        hasResizeColumns
        hasResponsiveColWidths
        manualFilters
        manualFiltersRender={(modalProps, setShowNotification) => (
          <RefundFilter
            data-trackingid='pay-portal-refunds-btn-filter'
            initialFilter={filter}
            onApply={handleChangeFilter}
            modalProps={modalProps}
            setShowNotification={setShowNotification}
          />
        )}
        emptyStateConfig={{
          type: 'payments',
          header: t('No data to display'),
        }}
        tableActions={[
          {
            label: t('Print'),
            Icon: PrintIcon,
            onClick: handlePrintClick,
          },
          ...(canExport ? [ExportAction] : []),
          {
            label: t('Refresh'),
            Icon: UpdateIcon,
            onClick: () => refetch(),
          },
        ]}
        styleConfig={{
          columns: [
            {
              id: '*',
              cellStyler: css`
                padding: ${theme.spacing(1, 1.5)};
              `,
              headerStyler: css`
                padding: ${theme.spacing(2, 1.5)};
              `,
            },
          ],
        }}
        onSortChange={(res) => {
          resetPageParams();
          if (!res.length) {
            setOrder('-paidat');
            return;
          }
          const { id, value } = res[0];
          if (id) {
            const order = FIELD_TO_ORDER[id];
            // fallback to -billedAt if field is unsupported
            setOrder(
              order
                ? (`${value === 'asc' ? '' : '-'}${FIELD_TO_ORDER[id]}` as RefundSearchParams['order'])
                : '-billedAt'
            );
          } else {
            setOrder('-billedAt');
          }
        }}
        rowActions={{
          onRowClick: (refund: RefundModel) => {
            if (refund.invoiceId)
              navigate({
                to: isPaymentsAppVariant(variant)
                  ? `/payments/refunds/${refund.invoiceId}`
                  : `/portal/payments/invoices/${refund.invoiceId}/refunds`,
              });
          },
        }}
        isPaginated
        manualPaginationConfig={{
          page: currentPage,
          hasNext: hasMore && !loading,
          hasPrevious: hasPrevious && currentPage !== 1 && !loading,
          onNumRowsChange: (num) => {
            setNumRows(num);
            resetPageParams();
          },
          defaultRowsPerPage: numRows,
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          handleChange: (prevOrNext: 'prev' | 'next') => {
            if (prevOrNext === 'prev') fetchPreviousPage();
            if (prevOrNext === 'next') fetchNextPage();
          },
        }}
        hasGlobalSearch={true}
        globalSearchConfig={{
          initialValue: filter.personName || '',
          position: 'right',
          searchHandler: (searchText: string) => {
            const toSet = {
              ...filter,
              personName: searchText.trim().length > 0 ? searchText : undefined,
            };
            setFilter(toSet);
          },
          placeholder: t('Search'),
          debounceDelay: 1000,
        }}
      />
      {InvoiceExportModal}
    </>
  );
};

export type PrintRefundListProps = {
  onClose: () => void;
  headerContent?: React.ReactNode;
};

export const PrintRefundList = ({ onClose, headerContent }: PrintRefundListProps) => {
  const { allRefunds, allRefundsLoading } = useQueryAllRefunds();
  const columns = useGenerateColumns<RefundModel>(generateColumns, true, PaymentsTableInstances.Refunds);

  return allRefundsLoading ? (
    <ContentLoader show={allRefundsLoading} />
  ) : !allRefunds?.length ? null : (
    <PrintDialog show={true} onClose={onClose}>
      <div
        css={css`
          margin: ${theme.spacing(2)};
        `}
      >
        <PrintHeader />
        {headerContent}
        <Table colConfig={columns} data={allRefunds} tableInstanceId={PaymentsTableInstances.Refunds} />
      </div>
    </PrintDialog>
  );
};
