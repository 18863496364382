import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import UpgradeIcon from '../../../assets/upgrade-icon.svg';
import { usePaymentsPromotion } from '../../provider/PaymentsPromotionProvider';
import { getPaymentHVATrackingIds, PaymentActionTrackingIds } from '../../tracking-ids';
import { PaymentActionEnum } from '../../types';

interface AppointmentPopoverContentProps {
  onSettingsOpen: () => void;
  onClose: () => void;
}
export const AppointmentPopoverContent: FC<AppointmentPopoverContentProps> = ({ onClose, onSettingsOpen }) => {
  const { hasAccountCreationPermission, source, handleAction } = usePaymentsPromotion();
  const { t } = useTranslation('highValueAdoption', { keyPrefix: 'payments' });

  const action = hasAccountCreationPermission
    ? PaymentActionEnum.CREATE_ACCOUNT
    : PaymentActionEnum.CREATE_ACCOUNT_LEARN_MORE;

  const handlePrimaryActionClick = () => {
    switch (action) {
      case PaymentActionEnum.CREATE_ACCOUNT:
        onSettingsOpen();
        break;
      default:
        onClose();
        break;
    }

    handleAction(action);
  };

  return (
    <div css={containerStyles}>
      <img src={UpgradeIcon} height={48} width={48} />
      <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
        <Text size='large' color='subdued'>
          {t('Customers pay on time more often with Text-to-pay and Online Bill Pay')}
        </Text>
        <div css={actionBarStyles}>
          <Button
            variant='tertiary'
            onClick={handlePrimaryActionClick}
            trackingId={getPaymentHVATrackingIds({
              source,
              trackingId: PaymentActionTrackingIds[action],
            })}
          >
            {hasAccountCreationPermission ? t('Set Up Payments Account') : t('Learn More')}
          </Button>
          <Button
            variant='tertiary'
            onClick={onClose}
            css={{ color: theme.colors.neutral50 }}
            trackingId={getPaymentHVATrackingIds({
              source,
              trackingId: PaymentActionTrackingIds[PaymentActionEnum.DISMISS],
            })}
          >
            {t('Dismiss')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  maxWidth: 360,
});

const actionBarStyles = css({
  display: 'flex',
  alignItems: 'center',
});
