import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { humanizeDateDifference } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Text } from '@frontend/design-system';
import { URLs } from '../../constants';
import { featureFlags } from '../../feature-flags';
import { defaultTimePeriods, findExistingTimePeriodKey } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { demoDataUtils, formatters } from '../../utils';
import Badge from '../call-intelligence/badge';
import { DemoChip } from '../demo-chip';
import { useFetchMessagingROIOOverview, useFetchMissedCallTextConversionSummary } from '../roi';
import { API_URLs } from '../roi/constants';
import { useHasMCTRoiFeature } from '../roi/missed-call-text/hooks';

type Props = {
  onLoading?: (isLoading?: boolean) => void;
};

const SummaryItem = ({ title, value }: { title: string; value: number }) => (
  <div>
    <Text style={{ fontSize: theme.font.size.h1, lineHeight: 1 }} weight='bold'>
      {formatters.value.format(value)}
    </Text>
    <Text>{title}</Text>
  </div>
);

export const MissedCallTextROIOnDashboard = ({ onLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIds } = useAppScopeStore();
  const navigate = useNavigate();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.missedCallTextRoiDemoData);
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();

  const payload = {
    start_date: defaultTimePeriods.lastMonth.value.startDate,
    end_date: defaultTimePeriods.lastMonth.value.endDate,
    location_id: selectedLocationIds,
  };

  const { aggregated, isLoading: isLoadingRoiOverview } = useFetchMessagingROIOOverview({
    apiParams: {
      messagingCategory: 'missedcalltext',
      payload,
    },
    isEnabled: isMCTRoiEligibleLocation,
    isDemoAccount,
  });

  const { data: chartData, isLoading: isLoadingChartData } = useFetchMissedCallTextConversionSummary({
    apiParams: {
      payload,
      url: API_URLs.missedCallText.appointmentScheduledSummary,
    },
    isEnabled: !isDemoAccount,
    totalCountKey: 'sms_count',
  });

  const isLoading = isLoadingChartData || isLoadingRoiOverview;

  const filterText = useMemo(() => {
    if (!payload.start_date || !payload.end_date) {
      return '';
    }

    const timePeriodKey = findExistingTimePeriodKey(payload.start_date, payload.end_date);

    if (timePeriodKey) {
      return defaultTimePeriods[timePeriodKey].label;
    } else {
      return humanizeDateDifference(payload.start_date, payload.end_date);
    }
  }, [payload.start_date, payload.end_date]);

  const handleNavigate = () => {
    navigate({
      to: URLs.ROI_MISSED_CALL_TEXT,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleNavigate();
    }
  };

  useEffect(() => {
    onLoading?.(isLoading);
  }, [isLoading]);

  if (!aggregated.leads_contacted?.sms_count || !aggregated.patients_scheduled?.appointments_count) {
    return null;
  }

  return (
    <section
      css={styles.wrapper}
      data-trackingid={trackingIds.roi.missedCallTextOverviewWidget}
      onClick={handleNavigate}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
    >
      <div className='stats-container'>
        <header css={styles.flexContainer} style={{ flexWrap: 'wrap' }}>
          <div css={styles.flexContainer} style={{ flexWrap: 'wrap' }}>
            {isDemoAccount ? <DemoChip /> : <Badge label={t('New')} />}
            <Heading level={3}>{t('Missed Call Text Conversion')}</Heading>
          </div>
          <div css={styles.flexContainer}>
            <Icon name='calendar-small' />
            <Text color='subdued' size='medium'>
              {filterText}
            </Text>
          </div>
        </header>
        <div css={[styles.flexContainer, styles.summaryItems]}>
          <SummaryItem title={t('Messages Sent')} value={aggregated.leads_contacted?.sms_count ?? 0} />
          <SummaryItem
            title={t('Appointments Scheduled')}
            value={aggregated.patients_scheduled?.appointments_count ?? 0}
          />
          <SummaryItem
            title={t('Completed Appointments')}
            value={aggregated.appointments_completed?.appointments_count ?? 0}
          />
        </div>
      </div>
      <aside className='chart-container'>
        <Chart.TinyAreaChart
          data={isDemoAccount ? demoDataUtils.generateRandomIntArray() : chartData?.map(({ count = 0 }) => count) ?? []}
          color={theme.colors.white}
        />
      </aside>
      <ContentLoader show={isLoading} size='small' />
    </section>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    cursor: pointer;
    display: flex;
    margin-bottom: ${theme.spacing(3)};
    overflow: hidden;
    position: relative;
    transition: background-color 0.2s;
    width: 100%;

    &:hover {
      background-color: ${theme.colors.neutral5};
    }

    .stats-container {
      flex: 1;
      gap: ${theme.spacing(3)};
      padding: ${theme.spacing(3)};
    }

    .chart-container {
      // The second gradient color is not available in the theme
      background: linear-gradient(135deg, ${theme.colors.primary40}, #94bfff);
      flex: 1;
      padding: ${theme.spacing(2)};

      @container (max-width: ${breakpoints.mobile.max}px) {
        display: none;
      }

      @container (min-width: ${breakpoints.mobile.max}px) {
        max-width: 400px;
      }

      @container (min-width: ${breakpoints.large.max}px) {
        max-width: 500px;
      }

      .recharts-surface {
        cursor: pointer;
      }
    }
  `,

  flexContainer: css`
    align-items: center;
    column-gap: ${theme.spacing(1)};
    display: flex;
    justify-content: space-between;
  `,

  summaryItems: css`
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(3)};
  `,
};
