import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { ActionsUI } from '@frontend/contact-actions';
import { formatDate } from '@frontend/date';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { ActionButton, ListRow } from '@frontend/design-system';
import { useGetAppointmentDetails } from '../../../hooks';
import { isUUID } from '../utils';
import { AppointmentBadgeStatus } from './AppointmentBadgeStatus';

type PatientListRowProps = {
  appt?: Appointment;
  optOutText?: boolean;
  locationId: string;
  shouldShowAppointmentModal?: boolean;
  appointments?: Appointment[];
};

export const PatientListRow = ({
  optOutText,
  appt,
  locationId,
  shouldShowAppointmentModal = false,
  appointments,
}: PatientListRowProps) => {
  const start = formatDate(appt?.start, 'LT');
  const end = appt?.duration ? formatDate(dayjs(appt?.start).add(appt.duration, 'm'), 'LT') : undefined;

  const addDot = !!appt?.type && !!appt.practitionerName ? '•' : '';

  const { triggerProps: viewAppointmentTriggerProps, Modal: AppointmentDetailsModal } = useGetAppointmentDetails({
    appointmentId: appt?.id || '',
    locationId: locationId || appt?.locationId || '',
    appointments,
    enabled: shouldShowAppointmentModal,
  });

  const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId: appt?.person?.personId ?? '',
      phoneNumber: appt?.person?.mobilePhone ?? '',
      locationId: appt?.locationId,
    },
  });

  const isAppointmentTypeStringPresent = !isUUID(appt?.type ?? '');

  const apptTypeAndPractitionerName = `${appt?.type} ${addDot} ${appt?.practitionerName ?? ''}`;

  const handleOpenAppointmentModal = () => {
    if (shouldShowAppointmentModal) viewAppointmentTriggerProps?.onClick();
  };

  const personName = `${appt?.person?.firstName ? appt?.person?.firstName : 'Unknown'} ${
    (appt?.person?.lastName || '') ?? ''
  }`;

  return (
    <>
      {AppointmentDetailsModal}
      <ListRow css={{ border: 0 }} onClick={handleOpenAppointmentModal}>
        <ListRow.Lead
          css={css`
            align-items: start;
          `}
        >
          <Photos.ProfilePhoto
            personId={(appt?.person && appt.person?.personId) ?? ''}
            firstName={appt?.person?.firstName}
            lastName={appt?.person?.lastName}
          />
        </ListRow.Lead>
        <ListRow.Content>
          <ListRow.Content.Title>{personName}</ListRow.Content.Title>
          <ListRow.Content.Subtitle>{`${start}${end ? ` - ${end}` : ''}`}</ListRow.Content.Subtitle>
          <ListRow.Content.TertiaryTitle>
            {isAppointmentTypeStringPresent ? apptTypeAndPractitionerName : appt?.practitionerName}
          </ListRow.Content.TertiaryTitle>
          {optOutText && (
            <ListRow.Content.TertiaryTitle>
              <AppointmentBadgeStatus status={appt?.statusOfficeView ?? ''} appointmentId={appt?.id ?? ''} />
            </ListRow.Content.TertiaryTitle>
          )}
        </ListRow.Content>
        <ListRow.Trail>
          {optOutText ? (
            <ActionButton>
              <Icon name='phone' size={16} onClick={() => callTriggerProps?.onClick()} />
            </ActionButton>
          ) : (
            <AppointmentBadgeStatus status={appt?.statusOfficeView ?? ''} appointmentId={appt?.id ?? ''} />
          )}
        </ListRow.Trail>
      </ListRow>
      {PhoneCallModal}
    </>
  );
};
