import { useState } from 'react';
import { css } from '@emotion/react';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { WeaveLocationGroup } from '@frontend/scope';
import { Modal, Text, Button, useAlert } from '@frontend/design-system';
import { trackingId } from '../../tracking';
import { PhoneNumber } from '../../views/settings/types';
import { PhoneNumbersTable } from './phone-numbers-table';
import { ExtendedCallRoute } from './types';

export const EditPhoneNumbersModal = ({
  callRoute,
  tenantLocation,
  unallocatedPhoneNumbers,
  onClose,
}: {
  callRoute: ExtendedCallRoute;
  unallocatedPhoneNumbers: PhoneNumber[];
  tenantLocation: WeaveLocationGroup;
  onClose: () => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const [selectedPhoneNumberIds, setSelectedPhoneNumberIds] = useState<string[]>(callRoute.phoneNumberIds);

  const { mutate: updateCallRoutePhoneNumbers, isLoading: updateCallRoutePhoneNumbersIsLoading } =
    CallRouteV1.Mutations.useUpdatePhoneNumbersMutation({
      options: {
        onSuccess: () => {
          alerts.success(t('Phone Numbers updated successfully.'));
          onClose();
        },
        onError: () => {
          alerts.error(t('Failed to update phone numbers.'));
        },
      },
    });

  const handleSave = () => {
    updateCallRoutePhoneNumbers({
      callRouteId: callRoute.callRouteId,
      phoneNumberIds: selectedPhoneNumberIds,
    });
  };

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Edit Phone Numbers')}</Modal.Header>
      <Modal.Body>
        <Text color='light'>{callRoute.name}</Text>
        <Text>{t('Assign phone numbers to this Call Route so it can be called.')}</Text>

        <PhoneNumbersTable
          tenantLocation={tenantLocation}
          phoneNumbers={[...callRoute.phoneNumbers, ...unallocatedPhoneNumbers]}
          selectedPhoneNumberIds={selectedPhoneNumberIds}
          setSelectedPhoneNumberIds={setSelectedPhoneNumberIds}
          trackingIdBase={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'edit-phone-numbers',
          })}
        />
      </Modal.Body>
      <Modal.Footer
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        {selectedPhoneNumberIds.length > 0 && (
          <Text color='light' textAlign='right'>
            {selectedPhoneNumberIds.length > 1
              ? t('{{count}} phone numbers selected', { count: selectedPhoneNumberIds.length })
              : t('{{count}} phone number selected', { count: selectedPhoneNumberIds.length })}
          </Text>
        )}
        <Button
          variant='secondary'
          size='large'
          onClick={onClose}
          trackingId={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'edit-phone-numbers-cancel-btn',
          })}
          css={css`
            width: fit-content;
          `}
        >
          {t('Cancel')}
        </Button>
        <Button
          size='large'
          disabled={updateCallRoutePhoneNumbersIsLoading}
          onClick={handleSave}
          trackingId={trackingId({
            context: 'setting',
            feature: 'call-routes',
            details: 'edit-phone-numbers-save-btn',
          })}
          css={css`
            width: fit-content;
          `}
        >
          {t('Save')}
        </Button>
      </Modal.Footer>
    </>
  );
};
