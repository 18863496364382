import { FC } from 'react';
import dayjs from 'dayjs';
import { usePopoverDialog, useCalendar, PopoverDialog, DialogCalendarLayout } from '@frontend/design-system';
import { TaskFormRow } from '../../common';

interface DatePickerDialogProps {
  label: string;
  placeholder: string;
  onChange: (value: string) => void;
  value: string;
}

export const DatePickerDialog: FC<DatePickerDialogProps> = ({ label, placeholder, onChange, ...rest }) => {
  const {
    getDialogProps: calenderDialogProps,
    getTriggerProps: calenderTriggerProps,
    close,
  } = usePopoverDialog({ placement: 'bottom' });

  const calendarProps = useCalendar({
    onSelect(value) {
      onChange(dayjs(value as string).format('YYYY-MM-DD'));
      close();
    },
  });

  return (
    <TaskFormRow
      icon='calendar-schedule-back'
      label={label}
      placeholder={placeholder}
      buttonIconName='calendar'
      getTriggerProps={calenderTriggerProps}
      value={rest.value ? dayjs(rest.value).format('MMMM D, YYYY') : ''}
      onRemoveClick={() => onChange('')}
    >
      <PopoverDialog {...calenderDialogProps()}>
        <DialogCalendarLayout {...calendarProps} />
      </PopoverDialog>
    </TaskFormRow>
  );
};
