import { FC, useEffect } from 'react';
import { BuilderFieldDivisionCategories } from '../../../constants';
import { useAchFieldAccess } from '../../../hooks';
import { useFormBuilderStore } from '../../../provider';
import { PaymentsFieldsDivisionCategory } from '../../../types/field-divisions';
import CategoriesLayout from './categories-layout/categories-layout.component';
import { useFilteredCategories } from './hooks';

export const PaymentsFields: FC = () => {
  const { hasAchFieldAccess } = useAchFieldAccess();
  const { filteredCategories, setCompleteCategories } = useFilteredCategories<PaymentsFieldsDivisionCategory>({
    categories: BuilderFieldDivisionCategories.PAYMENTS_FIELD_CATEGORIES,
  });
  const { paymentsFieldElements } = useFormBuilderStore(['paymentsFieldElements']);

  useEffect(() => {
    if (!hasAchFieldAccess) {
      return;
    }

    setCompleteCategories(BuilderFieldDivisionCategories.PAYMENTS_FIELD_CATEGORIES_WITH_ACH);
  }, [hasAchFieldAccess, setCompleteCategories]);

  if (!paymentsFieldElements) {
    return null;
  }

  return <CategoriesLayout categories={filteredCategories} fields={paymentsFieldElements} type='payments' />;
};
