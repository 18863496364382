import { PetsV1 } from '@frontend/api-pets';
import { petIsActive } from '../utils';

export const useGetActivePetsQuery = ({ personId, locationId }: { personId: string; locationId: string }) =>
  PetsV1.Queries.useFetchPetsByPersonIdQuery(
    { personId, locationId },
    {
      select: (data) => data?.pets?.filter((pet) => petIsActive(pet.activeStatus)),
      enabled: !!personId && !!locationId,
    }
  );

export const useGetActivePetQuery = ({ petId, locationId }: { petId: string; locationId: string }) =>
  PetsV1.Queries.useFetchPetQuery(
    { petId, locationId, includeExtendedPetDetails: true },
    { select: (pet) => (petIsActive(pet.activeStatus) ? pet : undefined) }
  );
