import { format } from 'date-fns';
import type { CommonHTMLTimeAttributes } from '@frontend/types';
import { theme } from '@frontend/theme';

type ThreadDateTimeDividerProps = {
  timestamp: Date;
} & CommonHTMLTimeAttributes;

export const ThreadDateTimeDivider = ({ timestamp, ...rest }: ThreadDateTimeDividerProps) => {
  return (
    <time
      css={{
        width: '100%',
        display: 'block',
        textAlign: 'center',
        color: theme.colors.neutral50,
        paddingBottom: theme.spacing(2),
      }}
      {...rest}
    >
      {format(timestamp, 'PP')}
    </time>
  );
};
