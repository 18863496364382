import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

export type GetCalendarEventsRequest = Pick<
  SchedulerV3.Types.ListCalendarEventsIO['input'],
  'startDateTime' | 'endDateTime' | 'page' | 'limit'
>;

type UseGetCalendarEventsV3DataParamsType = {
  selectedLocationId: string;
  selectedLocationIds: string[];
  request: GetCalendarEventsRequest;
  enabled?: boolean;
};

export const useGetCalendarEventsV3Data = ({
  request,
  selectedLocationIds,
  selectedLocationId,
  enabled = true,
}: UseGetCalendarEventsV3DataParamsType) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();

  const listCalendarEventsQuery = SchedulerV3.Queries.useListCalendarEventsQuery({
    request: {
      locationId: selectedLocationId,
      startDateTime: request.startDateTime,
      endDateTime: request.endDateTime,
      includeAttendeeDetails: true,
      ...(request.limit && { limit: request.limit }),
      ...(request.page && { page: request.page }),
    },
    options: {
      retry: 1,
      cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
      staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
      enabled: !isMultiLocation && !!selectedLocationId && !!request.startDateTime && !!request.endDateTime && enabled,
    },
  });

  const listCalendarEventsMultiQuery = SchedulerV3.Queries.useListCalendarEventsMultiQuery({
    request: {
      locationsIds: selectedLocationIds,
      startDateTime: request.startDateTime,
      endDateTime: request.endDateTime,
      parentLocationId,
      includeAttendeeDetails: true,
      ...(request.limit && { limit: request.limit }),
      ...(request.page && { page: request.page }),
    },
    options: {
      retry: 1,
      cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
      staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
      enabled:
        isMultiLocation && !!selectedLocationIds.length && !!request.startDateTime && !!request.endDateTime && enabled,
    },
  });

  return isMultiLocation ? listCalendarEventsMultiQuery : listCalendarEventsQuery;
};
