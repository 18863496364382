import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { ManualCardEntrySwitch } from './manual-card-entry/manual-card-entry-switch';

export const ManualCardEntry = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.manualCardEntry}>
      <ManualCardEntrySwitch />
    </StepPerformanceWrapper>
  );
};
