import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { CallIntelTypes } from '@frontend/api-call-intel';
import { CallIntelSubView, CallIntelSubViewId } from '../components/call-intelligence/hooks';
import { timePeriodLabels } from '../hooks';
import { defaultDateRangeMap } from './days-and-dates';
import { toHHmmss } from './time';

dayjs.extend(isBetween);

export const callIntelligenceUtils = {
  /**
   * To get the enum key from the value
   */
  getEnumKeyByValue: <T extends Record<string, number | string | undefined>>(
    enumObject: T,
    value?: number | string
  ): string => {
    if (value) {
      for (const key in enumObject) {
        if (enumObject[key] === value || enumObject[key] === parseInt(value as string)) {
          return key;
        }
      }
    }
    return '';
  },

  /**
   * To get the drill down filter keys for overview page
   */
  getDrillDownFilterKeys: ({ id, type }: CallIntelSubView): CallIntelTypes.DrillDownOptions | undefined => {
    if (!id || !type) return undefined;

    switch (type) {
      case 'appointment-type':
        return {
          index: CallIntelTypes.FilterTypeEnum.FILTER_BY_APPOINTMENT_TYPE,
          key: 'appointment_types',
          value: [id],
        };

      case 'category':
        return {
          index: CallIntelTypes.FilterTypeEnum.FILTER_BY_CATEGORY,
          key: 'categories',
          value: [id],
        };

      case 'sentiment':
        return {
          index: CallIntelTypes.FilterTypeEnum.FILTER_BY_SENTIMENT,
          key: 'sentiments',
          value: [id],
        };

      case 'service-quality':
        return {
          index: CallIntelTypes.FilterTypeEnum.FILTER_BY_SERVICE_QUALITY_FLAG,
          key: 'service_quality_flags',
          value: [id],
        };

      default:
        return undefined;
    }
  },

  // To get the total number of calls
  getCallsCount: (overview?: CallIntelTypes.Overview, sentimentCode?: CallIntelSubViewId): number => {
    if (sentimentCode) {
      return overview?.sentiments?.[sentimentCode as CallIntelTypes.SentimentEnum] || 0;
    }

    // Each call will have only 1 sentiment, hence total calls = total sentiments
    return Object.values(overview?.sentiments || {}).reduce((acc, count) => acc + count, 0);
  },

  // Defines if the call is still in processing state
  isCallInProcessing: (status?: CallIntelTypes.CallStatusEnum): boolean =>
    status === CallIntelTypes.CallStatusEnum.CALL_STATUS_INFERENCING ||
    status === CallIntelTypes.CallStatusEnum.CALL_STATUS_PENDING ||
    status === CallIntelTypes.CallStatusEnum.CALL_STATUS_TRANSCRIBING,

  // Identify if the call quality is bad
  // mosScore : 1 - 3.5 Poor Quality
  // mosScore : > 3.5 to 5 Good Quality
  isPoorAudio: (call?: CallIntelTypes.Call | null): boolean => {
    if (!call?.mosScore) {
      // Older records might not have mosScore hence do not treat them as of bad quality
      return false;
    }

    return call.mosScore >= 1 && call.mosScore <= 3.5;
  },

  isFailedCall: (status?: CallIntelTypes.CallStatusEnum): boolean => {
    return (
      status === CallIntelTypes.CallStatusEnum.CALL_STATUS_FAILED ||
      status === CallIntelTypes.CallStatusEnum.CALL_STATUS_ERROR
    );
  },

  isSkippedCall: (status?: CallIntelTypes.CallStatusEnum): boolean => {
    return status === CallIntelTypes.CallStatusEnum.CALL_STATUS_SKIPPED;
  },

  getPhoneNumber: (phoneNumber?: CallIntelTypes.PhoneNumber): string => {
    if (!phoneNumber) return '';

    const { countryCode, nationalNumber } = phoneNumber;
    return `${countryCode}${nationalNumber}`;
  },

  isUserInactive: (_officeUser?: CallIntelTypes.OfficeUser | null): boolean => {
    // TODO :: add inactive user logic when we have one, for now return false for all users
    return false;
  },

  isOfficeUserDataUnavailable: (recordDate?: string): boolean => {
    // As confirmed by the BE, office user data is not available prior to 1 April 2024 (including this date)
    // Hence, hard coding the date to 2nd April 2024
    const unavailableOfficeUserDataDate = new Date(2024, 3, 2);
    return !!recordDate && dayjs(recordDate).isBefore(dayjs(unavailableOfficeUserDataDate));
  },
};

export const findCitationTime = (transcript: string, citationText: string): string => {
  if (!citationText) {
    return '';
  }

  const parsedTranscript = parseTranscript(transcript);

  for (const entry of parsedTranscript) {
    if (entry.text.includes(citationText)) {
      return entry.time.start;
    }
  }

  return '';
};

export const parseTranscript = (transcript: string, tokens?: Record<string, string | undefined>) => {
  const regex = /\[(.*?)\] <(.*?) --> (.*?)> (.+?)\n/g;
  const result = [];
  let match;

  while ((match = regex.exec(transcript)) !== null) {
    const time = {
      start: toHHmmss(parseFloat(match[2]), true),
      end: toHHmmss(parseFloat(match[3]), true),
    };
    const speaker = match[1];
    const text = match[4];

    result.push({ time, speaker, text: !tokens ? text : parseTranscriptWithTokens(text, tokens) });
  }

  return result;
};

export const parseTranscriptWithTokens = (transcript: string, tokens: Record<string, string | undefined>): string => {
  if (!transcript) {
    return '';
  }
  return transcript.replace(/\[([^\]]+)\]/g, (match, p1) => tokens[p1] || match);
};

export const getFilteredEnumValues = <T extends Record<string, string>>(enumObj: T, excludeValue: T[keyof T]) =>
  Object.values(enumObj).filter((value) => value !== excludeValue);

export const fillTaskTypes = (
  partialTaskTypes: Partial<Record<CallIntelTypes.TaskTypeEnum, number>>,
  order: Exclude<CallIntelTypes.TaskTypeEnum, CallIntelTypes.TaskTypeEnum.TYPE_UNKNOWN>[]
): Record<Exclude<CallIntelTypes.TaskTypeEnum, CallIntelTypes.TaskTypeEnum.TYPE_UNKNOWN>, number> => {
  const filledTaskTypes = order.map((key) => [key, partialTaskTypes[key] ?? 0]);

  return Object.fromEntries(filledTaskTypes);
};

export const generateContactId = (call: CallIntelTypes.Call) => {
  if (call.person?.id) {
    return call.person.id;
  } else if (call.phoneNumber?.nationalNumber) {
    return callIntelligenceUtils.getPhoneNumber(call.phoneNumber);
  }
  return `unknown-contact-${call.id}`;
};

export const getChangeType = (rateChange: number): CallIntelTypes.ChangeType => {
  if (isNaN(rateChange)) return 'noData';
  if (rateChange > 0) return 'increased';
  if (rateChange < 0) return 'decreased';
  return 'unchanged';
};

export const getPreviousInterval = ({
  startDate,
  endDate,
  periodType,
}: CallIntelTypes.DateInterval & {
  periodType: string;
}): CallIntelTypes.DateInterval => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  let previousStartDate;
  let previousEndDate;

  const matchingPeriodType = Object.keys(timePeriodLabels).find(
    (key) => timePeriodLabels[key] === periodType
  ) as keyof typeof defaultDateRangeMap;

  switch (matchingPeriodType) {
    case 'this-week': {
      previousStartDate = start.subtract(1, 'week').startOf('week');
      previousEndDate = start.subtract(1, 'week').endOf('week');
      break;
    }

    case 'this-month': {
      previousStartDate = start.subtract(1, 'month').startOf('month'); // Always 1st of previous month
      previousEndDate = start.subtract(1, 'month').endOf('month'); // Last day of previous month
      break;
    }

    case 'this-quarter': {
      previousStartDate = start.subtract(1, 'quarter').startOf('quarter');
      previousEndDate = start.subtract(1, 'quarter').endOf('quarter');
      break;
    }

    default: {
      const daysDiff = end.diff(start, 'day');
      previousStartDate = start.subtract(daysDiff + 1, 'day');
      previousEndDate = end.subtract(daysDiff + 1, 'day');
      break;
    }
  }

  return {
    startDate: previousStartDate.format('YYYY-MM-DDTHH:mm:ss'),
    endDate: previousEndDate.format('YYYY-MM-DDTHH:mm:ss'),
  };
};

export const isOngoingLabel = (label: string): boolean => {
  const today = dayjs();

  switch (label) {
    case timePeriodLabels.today:
    case timePeriodLabels['this-week']:
    case timePeriodLabels['this-month']:
    case timePeriodLabels['this-quarter']:
      return true;

    // If it's a custom range, check if it includes today.
    default:
      if (label.includes('-')) {
        const [startLabel, endLabel] = label.split(' - ').map((date) => dayjs(date.trim()));
        return today.isBetween(startLabel, endLabel, 'day', '[]');
      }

      // For all other cases (e.g., "Last Week", "Last Month"), return false
      return false;
  }
};
