import {
  DataType as CapabilityDataType,
  Operation as CapabilityOperation,
} from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/capability.pb';
import { useGetDataSourcesForLocation } from './use-get-data-sources-for-location';

type WritebackCapabilityArgs = {
  type: CapabilityDataType;
  operation: CapabilityOperation;
};

export const useCheckIntegrationCapability = ({ type, operation }: WritebackCapabilityArgs, locationId: string) => {
  const { data, isLoading } = useGetDataSourcesForLocation({ locationId, isEnabled: !!locationId });

  const hasIntegrationCapability = data?.dataSources?.some((dataSource) =>
    dataSource?.integration?.capabilities?.find(
      (capability) => capability.dataType === type && capability.operation === operation
    )
  );

  return { hasIntegrationCapability, isLoading };
};
