import { useCallback } from 'react';
import { StoredReader } from '@frontend/payments-terminal-controller';
import { useCollectPaymentMultiContext } from '../../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEPS } from '../../../../utils/steps';
import { SelectTerminalStepNavigationProps } from '../select-terminal.types';

export const SelectTerminalGenericNavigation = ({ body: Body }: SelectTerminalStepNavigationProps) => {
  const {
    multiStep: { goNextStep, goPrevStep },
  } = useCollectPaymentMultiContext();
  const onReaderSelected = useCallback(
    (_reader: StoredReader) => {
      goNextStep({ orStep: PAYMENT_MULTI_STEPS.collectTerminal });
    },
    [goNextStep]
  );

  const onGoBack = useCallback(() => goPrevStep({ orStep: PAYMENT_MULTI_STEPS.paymentMethods }), []);

  const onClickRegisterTerminal = useCallback(() => {
    goNextStep({ orStep: PAYMENT_MULTI_STEPS.registerTerminal });
  }, [goNextStep]);

  return (
    <Body onReaderSelected={onReaderSelected} onGoBack={onGoBack} onClickRegisterTerminal={onClickRegisterTerminal} />
  );
};
