import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { InvoiceStatus } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useLatencyBustPoll } from '@frontend/payments-hooks';
import { useGetInvoiceNonQuery, useUpdateInvoiceQueryCache } from '@frontend/payments-invoice-controller';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { theme } from '@frontend/theme';
import { ContentLoader, EmailField, Text, useControlledField } from '@frontend/design-system';
import { useInvoicePerson } from '../../../../hooks';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { ManualCardEntryNavigationSwitch } from './manual-card-entry-navigation-switch';
import { useManualCardEntryPayment } from './manual-card-entry-stripe/use-manual-card-entry-payment';
import { ManualCardEntryStepBodyProps } from './manual-card-entry.types';

const DATA_TRACKING_PREFIX = `pay-client-virtualterminal`;

const styles = {
  emailField: css`
    margin-bottom: ${theme.spacing(1.5)};
  `,
  billingAddressTitle: css`
    margin: ${theme.spacing(2, 0)};
  `,
};

export const ManualCardEntryStripe = () => {
  return (
    <StripeElementsWrapper type={PaymentsFlowType.ManualCardEntry}>
      <ManualCardEntryNavigationSwitch body={ManualCardEntryStripeBody} />
    </StripeElementsWrapper>
  );
};

const ManualCardEntryStripeBody = ({ onGoBack, onCompleteStep, onPaymentSuccess }: ManualCardEntryStepBodyProps) => {
  const { t } = useTranslation('payments');

  const {
    invoice,
    invoiceQuery: { refetch: refetchInvoice },
  } = useCollectPaymentMultiContext();

  const { person, personEmail, personFullName, personAddress } = useInvoicePerson(invoice);

  const [emailReceipt, setEmailReceipt] = useState<string | undefined>(personEmail);
  const [cardReadyToSubmit, setCardReadyToSubmit] = useState<boolean>(false);
  const [addressComplete, setAddressComplete] = useState<boolean>(false);
  const [cardName, setCardName] = useState<string | undefined>(undefined);
  const { value: receiptEmail, ...receiptEmailProps } = useControlledField({
    required: false,
    value: emailReceipt,
    type: 'email',
    onChange: (value) => {
      setEmailReceipt(value);
    },
  });

  useEffect(() => {
    if (person) setEmailReceipt(personEmail);
  }, [person]);

  const handleCardChange = (event: StripePaymentElementChangeEvent) => setCardReadyToSubmit(!!event.complete);

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    setAddressComplete(!!event.complete);
    if (event.complete) setCardName(event.value.name);
  };

  const validate = () => !!cardName && !receiptEmailProps.error && cardReadyToSubmit && addressComplete;

  const onRefetchInvoice = useCallback(() => {
    return refetchInvoice().then((res) => res.data);
  }, [refetchInvoice]);

  const { makeManualCardEntryPayment, makingManualCardEntryPayment } = useManualCardEntryPayment({
    cardName,
    receiptEmail,
    invoice,
    onGoBack,
    onRefetchInvoice,
  });

  const updateInvoiceCache = useUpdateInvoiceQueryCache(invoice?.id);

  const getInvoice = useGetInvoiceNonQuery(invoice?.id);

  const { getPollPromise } = useLatencyBustPoll({
    queryMethod: async () => getInvoice(),
    onPollEnd: (data) => {
      updateInvoiceCache(data);
      onPaymentSuccess();
    },
    accessor: (data) => {
      return data?.status;
    },
  });

  const onSubmit = async () => {
    if (await makeManualCardEntryPayment()) {
      if (invoice) {
        getPollPromise(invoice);

        /**
         * Optimistically update the status to processing.
         */
        updateInvoiceCache({ ...invoice, status: 'Processing' as InvoiceStatus });

        onCompleteStep();
      }
      return true;
    }

    return false;
  };

  return (
    <StyledStep
      id={PAYMENT_MULTI_STEPS.manualCardEntry}
      label={PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.manualCardEntry]}
      isValid={validate() && !makingManualCardEntryPayment}
      footer={{
        primary: {
          children: t('Complete Payment'),
          disabled: !validate(),
        },
        secondary: {
          children: t('Back'),
          disabled: makingManualCardEntryPayment,
        },
        showBackButton: false,
        onClickPrimary: async () => !!(await onSubmit()),
        onClickSecondary: onGoBack,
      }}
    >
      <ContentLoader show={makingManualCardEntryPayment} message={t('Making Manual Card Entry Payment...')} />

      <form>
        <EmailField
          name='email'
          label={t('Email Receipt (optional)')}
          {...receiptEmailProps}
          actionText={'Clear'}
          data-trackingid={`${DATA_TRACKING_PREFIX}-txt-emailreceipt`}
          onActionClick={emailReceipt ? () => setEmailReceipt('') : undefined}
          hasNonFloatingLabel={true}
          css={styles.emailField}
          value={receiptEmail}
        />
        <PaymentElement onChange={handleCardChange} />
        <Text size='large' weight='bold' as='h3' css={styles.billingAddressTitle}>
          {t('Billing Address')}
        </Text>
        <AddressElement
          onChange={handleAddressChange}
          options={{
            mode: 'billing',
            defaultValues: {
              name: personFullName,
              address: personAddress,
            },
          }}
        />
      </form>
    </StyledStep>
  );
};
