import { PAYMENT_MULTI_STEPS } from '../../utils/steps';
import { StepPerformanceWrapper } from '../atoms/step-performance-wrapper';
import { ShareInMessageNavigationSwitch } from './share-in-message/share-in-message-navigation-switch';

export const Sunbit = () => {
  return (
    <StepPerformanceWrapper step={PAYMENT_MULTI_STEPS.sunbit}>
      <ShareInMessageNavigationSwitch isBnplRequest step={PAYMENT_MULTI_STEPS.sunbit} />
    </StepPerformanceWrapper>
  );
};
