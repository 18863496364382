import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { GenericFieldProps } from '../../../types';
import { dropdownSectionTypeStyling, InputDropdownContent, Setting } from '.';

type Props = GenericFieldProps & {
  independent?: boolean;
  isLast?: boolean;
  options?: string[];
  title?: string;
};

const defaultOptions = ['auto', theme.spacing(30), theme.spacing(40), theme.spacing(45), theme.spacing(50)];

export const HeightSetting = ({ independent, isLast, options = defaultOptions, title, ...fieldProps }: Props) => {
  const { t } = useTranslation('content-composer-settings');

  return independent ? (
    <Setting title={title ?? t('Logo')} isLast={isLast}>
      <div css={dropdownSectionTypeStyling}>
        <Text>{t('Height')}</Text>
        <InputDropdownContent {...fieldProps} options={options} />
      </div>
    </Setting>
  ) : (
    <div
      css={[
        dropdownSectionTypeStyling,
        !isLast &&
          css`
            margin-bottom: ${theme.spacing(2)};
          `,
      ]}
    >
      <Text>{t('Height')}</Text>
      <InputDropdownContent {...fieldProps} options={options} />
    </div>
  );
};
