import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { theme } from '@frontend/theme';
import { Chip, NakedUl } from '@frontend/design-system';
import { PetIcon } from './pet-icon';

export const PetList = ({ pets }: { pets: PetDetails[] }) => (
  <NakedUl css={{ display: 'flex', overflow: 'auto' }}>
    {pets?.map((pet) => (
      <li key={pet.petId}>
        <Chip.Pet
          name={pet.name}
          css={{ marginRight: theme.spacing(1) }}
          leftElement={<PetIcon imageType={pet.validatedImageType} size={16} />}
        />
      </li>
    ))}
  </NakedUl>
);
