import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  IconButton,
  Tray,
  ModalControlModalProps,
  SearchField,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
  useControlledField,
  BannerNotification,
} from '@frontend/design-system';
import { featureList, widgetLibraryFeatures } from '../../constants';
import { useWidgetLibrary } from './provider';

interface WidgetLibraryTrayProps extends ModalControlModalProps {
  closeModal: () => void;
}

export const WidgetLibraryTray = ({
  children,
  closeModal,
  ...rest
}: React.PropsWithChildren<WidgetLibraryTrayProps>) => {
  const { t } = useTranslation('dashboard');
  const hasChildren = !!React.Children.count(children);

  return (
    <>
      <Tray
        autoFocusTimeout={3600000 * 2}
        mountTarget='#app-container'
        width='medium'
        {...rest}
        css={css`
          padding: 0;
          gap: 0;
          border-top: 1px solid ${theme.colors.neutral20};
        `}
      >
        <Tray.Header
          css={css`
            padding: ${theme.spacing(4, 2)};
            border-bottom: 1px solid ${theme.colors.neutral10};
          `}
          Buttons={
            <IconButton label='close' onClick={() => closeModal()}>
              <Icon name='x' />
            </IconButton>
          }
        >
          {t('Widget Library')}
        </Tray.Header>
        <Tray.Body>
          <WidgetLibrarySearch />
          <div
            css={css`
              width: 100%;
              padding: ${theme.spacing(2)};
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-auto-flow: dense;
              gap: ${theme.spacing(2)};
            `}
          >
            {hasChildren ? (
              children
            ) : (
              <BannerNotification
                css={css`
                  grid-column: span 2;
                `}
                fullWidth
                status='info'
                message={t('No matching widgets found')}
              />
            )}
          </div>
        </Tray.Body>
      </Tray>
    </>
  );
};

const WidgetLibrarySearch = () => {
  const { librarySearchTerm, setLibrarySearchTerm } = useWidgetLibrary(['librarySearchTerm', 'setLibrarySearchTerm']);
  const widgetSearch = useControlledField({ type: 'text', value: librarySearchTerm, onChange: setLibrarySearchTerm });

  return (
    <div
      css={css`
        padding: ${theme.spacing(2)};
        position: sticky;
        top: 0;
        z-index: ${theme.zIndex.middle};
        background: ${theme.colors.white};
        display: flex;
        justify-content: flex-start;
        gap: ${theme.spacing(1)};
        border-bottom: 1px solid ${theme.colors.neutral10};
      `}
    >
      <SearchField
        name='Library Search'
        containerCss={css`
          flex: 1;
        `}
        css={[
          css`
            background-color: ${theme.colors.white};
            border-radius: ${theme.borderRadius.medium};
            border: 1px solid ${theme.colors.neutral20};
            :focus {
              border: 1px solid ${theme.colors.primary20};
            }

            transition: 0.3s ease;
            svg {
              color: ${theme.colors.neutral50};
            }
          `,
        ]}
        {...widgetSearch}
      />
      <GlobalSearchFeatureFilter />
    </div>
  );
};

export const GlobalSearchFeatureFilter = () => {
  const { t } = useTranslation('dashboard');
  const { getItemProps, getMenuProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom-end',
  });
  const { libraryFilterBy, setLibraryFilterBy } = useWidgetLibrary(['libraryFilterBy', 'setLibraryFilterBy']);

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(0.5)};
        `}
      >
        <IconButton {...getTriggerProps()} label={t('Filter Widgets')}>
          <Icon name='filter-alt' />
        </IconButton>
      </div>
      <PopoverMenu
        css={css`
          width: 200px;
        `}
        {...getMenuProps()}
      >
        {featureList.map((feature, index) => {
          const label = widgetLibraryFeatures[feature].label;
          return (
            <PopoverMenuItem
              {...getItemProps({ index, onClick: () => setLibraryFilterBy(feature) })}
              css={{ cursor: 'pointer' }}
              key={feature}
              active={feature === libraryFilterBy}
            >
              {label}
            </PopoverMenuItem>
          );
        })}
      </PopoverMenu>
    </>
  );
};
