import { ElementType, forwardRef } from 'react';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef } from '@frontend/design-system';
import { useThreadHeaderContext } from '../thread-header';

type HeaderProps<E extends ElementType = 'div'> = PolymorphicComponentPropWithoutRef<E, Record<string, unknown>>;

export const Header = forwardRef(
  <E extends ElementType = 'div'>({ as, children, ...rest }: HeaderProps<E>, ref: React.Ref<any>) => {
    const { variant } = useThreadHeaderContext();
    const Component = as || 'div';

    return (
      <Component
        css={{
          display: 'flex',
          alignItems: variant === 'horizontal' ? 'center' : 'start',
          padding: theme.spacing(2),
          position: 'relative',
          width: '100%',
          justifyContent: 'space-between',
        }}
        {...rest}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
