import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { InvoiceStatus } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { CardACHSelection, CardACHSelectionView, MIN_ACH_AMOUNT } from '@frontend/payments-card-on-file';
import { useLatencyBustPoll } from '@frontend/payments-hooks';
import { useGetInvoiceNonQuery, useUpdateInvoiceQueryCache } from '@frontend/payments-invoice-controller';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { useCollectPaymentMultiContext } from '../../../collect-payment-multi.context';
import { PAYMENT_MULTI_STEP_LABELS, PAYMENT_MULTI_STEPS } from '../../../utils/steps';
import { StyledStep } from '../../atoms/styled-step';
import { CardOnFileNavigationSwitch } from './card-on-file-navigation-switch';
import { useCardOnFilePayment } from './card-on-file-stripe/use-card-on-file';
import { CardOnFileStepBodyProps } from './card-on-file-types';

export const CardOnFileStripe = () => {
  return (
    <StripeElementsWrapper type={PaymentsFlowType.CardOnFilePayment}>
      <CardOnFileNavigationSwitch body={CardOnFileContent} />
    </StripeElementsWrapper>
  );
};

const styles = {
  body: css`
    .add-ach-form,
    .add-card-form {
      flex-grow: 1;
      height: 100%;
      padding: 0;

      &__footer {
        margin-top: auto;
      }
    }
  `,
};

const CardOnFileContent = ({ onPaymentSuccess, onCompleteStep, onGoBack }: CardOnFileStepBodyProps) => {
  const { t } = useTranslation('payments');

  const [selectedPM, setSelectedPM] = useState<PaymentMethod>();

  const [activeView, setActiveView] = useState<CardACHSelectionView>('payment-methods');

  const {
    invoice,
    invoiceQuery: { refetch: refetchInvoice },
    stripeData,
  } = useCollectPaymentMultiContext();

  const onRefetchInvoice = useCallback(() => {
    return refetchInvoice().then((res) => res.data);
  }, [refetchInvoice]);

  const { makeCardOnFilePayment, makingCardOnFilePayment } = useCardOnFilePayment({
    paymentMethod: selectedPM,
    invoice,
    onGoBack,
    onRefetchInvoice,
  });

  const updateInvoiceCache = useUpdateInvoiceQueryCache(invoice?.id);

  const getInvoice = useGetInvoiceNonQuery(invoice?.id);

  const { getPollPromise } = useLatencyBustPoll({
    queryMethod: getInvoice,
    onPollEnd: (data) => {
      updateInvoiceCache(data);
      onPaymentSuccess();
    },
    accessor: (data) => {
      return data?.status;
    },
  });

  const goBack = useCallback(() => {
    onGoBack();
  }, []);

  const onPrimaryClick = async () => {
    const workflowId = await makeCardOnFilePayment();

    /**
     * If card is declided, or failure happens, workflowId will be undefined.
     */
    if (workflowId) {
      if (invoice) {
        getPollPromise(invoice);

        /**
         * Optimistically update the status to processing.
         */
        updateInvoiceCache({ ...invoice, status: 'Processing' as InvoiceStatus });
      }

      onCompleteStep();
    }
  };

  const label = useMemo(() => {
    return stripeData.enableACHOnFile
      ? PAYMENT_MULTI_STEP_LABELS['card-and-ach-on-file']
      : PAYMENT_MULTI_STEP_LABELS[PAYMENT_MULTI_STEPS.cardOnFile];
  }, [stripeData.enableACHOnFile]);

  return (
    <StyledStep
      css={styles.body}
      id={PAYMENT_MULTI_STEPS.cardOnFile}
      label={label}
      isValid={!!selectedPM}
      header={{ title: label }}
      hasInternalFooter={activeView !== 'payment-methods'}
      footer={{
        onClickBack: goBack,
        onClickSecondary: goBack,
        onClickPrimary: onPrimaryClick,
        secondary: t('Cancel'),
        primary: t('Complete Payment'),
      }}
      components={{
        Footer: activeView === 'payment-methods' ? undefined : () => null,
      }}
    >
      <CardACHSelection
        patientId={invoice?.person.id}
        patientEmail={invoice?.person.emailAddress || ''}
        selectedPM={selectedPM}
        onChangePM={setSelectedPM}
        paymentAmount={invoice?.billedAmount}
        minACHAmount={MIN_ACH_AMOUNT}
        addCardContent={<CardACHSelection.AddCardForm />}
        addACHContent={<CardACHSelection.AddACHForm />}
        onChangeView={setActiveView}
      >
        <ContentLoader
          show={makingCardOnFilePayment}
          message={
            selectedPM?.type === 'us_bank_account'
              ? t('Making ACH on File Payment...')
              : t('Making Card on File Payment...')
          }
        />

        <CardACHSelection.Content css={{ padding: `0 !important`, marginBottom: theme.spacing(2) }} />
      </CardACHSelection>
    </StyledStep>
  );
};
