import { ComponentProps, useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { SMSSendV3 } from '@frontend/api-sms-send';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ButtonVariants, usePopoverDialog } from '@frontend/design-system';
import { ScheduleMessagePopover } from '../schedule-message-popover';

export const SEND_BUTTON_CLASS = 'thread-sending-area-send-button';

type ButtonStyles = {
  mainButton: SerializedStyles;
  moreButton: SerializedStyles;
  containerStyles: SerializedStyles;
};

type SendResponse = Promise<SMSSendV3.Types.SendIO['output']> | undefined;
type ScheduleArgs = Parameters<ComponentProps<typeof ScheduleMessagePopover>['onSchedule']>[0];
type ScheduleResponse = Promise<ManualSmsScheduledV1.Types.ScheduleIO['output']> | undefined;
type DeleteScheduledResponse = Promise<ManualSmsScheduledV1.Types.DeleteIO['output']> | undefined;

export type SendButtonActions = {
  onSend: () => SendResponse | void;
  onSendScheduled?: (scheduledSms: ScheduledSms) => SendResponse | void;
  onSchedule: ({ sendAt, pausable }: ScheduleArgs) => ScheduleResponse | void;
  onReschedule?: ({
    sendAt,
    pausable,
    scheduledSms,
  }: ScheduleArgs & { scheduledSms: ScheduledSms }) => ScheduleResponse | void;
  onDeleteScheduledMessage?: (scheduledSms: ScheduledSms) => DeleteScheduledResponse | void;
};

type SendButtonProps = {
  disabled?: boolean;
  canSchedule: boolean;
  scheduledMessageForEdit?: ScheduledSms;
  schedulePopoverTrackingIdSuffix?: string;
  variant?: ButtonVariants;
  labelText?: string;
  primaryButtonTrackingPrefix?: string;
  hidePausable?: boolean;
  buttonType?: 'submit' | 'button';
} & SendButtonActions;

export const SendButton = ({
  canSchedule,
  disabled,
  onSend,
  onSendScheduled,
  onSchedule,
  onReschedule,
  onDeleteScheduledMessage,
  scheduledMessageForEdit,
  schedulePopoverTrackingIdSuffix,
  variant = 'tertiary',
  labelText,
  primaryButtonTrackingPrefix,
  hidePausable = false,
  buttonType = 'submit',
}: SendButtonProps) => {
  const { t } = useTranslation('thread-sending-area');

  const buttonStyles = useMemo(() => {
    const containerStyles: ButtonStyles['containerStyles'] = css([
      {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.borderRadius.small,
      },
      scheduledMessageForEdit && {
        [`.${SEND_BUTTON_CLASS}`]: {
          backgroundColor: disabled ? theme.colors.success20 : theme.colors.success50,
          ':hover, :focus': {
            backgroundColor: disabled ? undefined : theme.colors.success60,
          },
        },
      },
    ]);
    const baseStyles = css({
      padding: variant === 'tertiary' ? theme.spacing(0.5) : theme.spacing(1),
      height: 'auto',
    });

    const dividerColors: {
      default: string;
      disabled: string;
    } =
      variant === 'primary'
        ? {
            default: theme.colors.primary70,
            disabled: theme.colors.neutral30,
          }
        : variant === 'secondary'
        ? {
            default: theme.colors.neutral50,
            disabled: theme.colors.neutral20,
          }
        : {
            default: theme.colors.neutral20,
            disabled: theme.colors.neutral20,
          };
    return {
      containerStyles,
      mainButton: css([
        baseStyles,
        {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRightWidth: variant === 'secondary' ? 0 : 1,
          borderRightStyle: 'solid',
          borderRightColor: disabled ? dividerColors.disabled : dividerColors.default,
          '&[aria-disabled="true"]': {
            borderRightColor: dividerColors.disabled,
          },
        },
        variant === 'tertiary' && {
          paddingRight: theme.spacing(1),
          svg: {
            color: disabled ? theme.colors.neutral20 : theme.colors.neutral70,
          },
          ':hover': {
            backgroundColor: disabled ? 'transparent' : theme.colors.neutral5,
          },
        },
      ]),
      moreButton: css([
        baseStyles,
        {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
        variant === 'tertiary' && {
          paddingLeft: theme.spacing(1),
          svg: {
            color: disabled ? theme.colors.neutral20 : theme.colors.neutral70,
          },
          ':hover': {
            backgroundColor: disabled ? 'transparent' : theme.colors.neutral5,
          },
        },
        !!labelText && {
          height: theme.spacing(4.38),
        },
      ]),
    };
  }, [disabled, variant, canSchedule]);

  const { getDialogProps, getTriggerProps, close } = usePopoverDialog({
    placement: 'top-end',
  });

  const primaryTrackingId = useMemo(() => {
    const trackingId = scheduledMessageForEdit ? 'thread-resume-send-button' : 'thread-send-button';
    return primaryButtonTrackingPrefix ? `${primaryButtonTrackingPrefix}-${trackingId}` : trackingId;
  }, [!!scheduledMessageForEdit, primaryButtonTrackingPrefix]);

  return (
    <span css={buttonStyles.containerStyles}>
      <Button
        iconName={labelText ? undefined : scheduledMessageForEdit ? 'history' : 'send'}
        variant={variant}
        css={buttonStyles.mainButton}
        type={buttonType}
        onClick={buttonType === 'submit' ? undefined : onSend}
        disabled={disabled}
        size={variant === 'tertiary' ? 'large' : 'small'}
        hoverLabel={scheduledMessageForEdit ? t('Resume Send') : t('Send')}
        trackingId={primaryTrackingId}
        className={SEND_BUTTON_CLASS}
      >
        {labelText}
      </Button>
      <Button
        iconName='caret-down-small'
        variant={variant}
        css={buttonStyles.moreButton}
        disabled={disabled || (!scheduledMessageForEdit && !canSchedule)}
        size={variant === 'tertiary' ? 'large' : 'small'}
        showLabelWhenDisabled={!canSchedule}
        hoverLabel={
          !canSchedule
            ? t('Start a conversation in order to schedule a message')
            : scheduledMessageForEdit
            ? t('More Options')
            : t('Schedule Send')
        }
        className={SEND_BUTTON_CLASS}
        {...getTriggerProps()}
      />
      {canSchedule && (
        <ScheduleMessagePopover
          {...getDialogProps()}
          onSchedule={(args) => {
            if (scheduledMessageForEdit) {
              onReschedule?.({ ...args, scheduledSms: scheduledMessageForEdit });
            } else {
              onSchedule(args);
            }
            close();
          }}
          onSendNow={(...args) => {
            if (scheduledMessageForEdit) {
              onSendScheduled?.(scheduledMessageForEdit);
            } else {
              onSend(...args);
            }
            close();
          }}
          deleteScheduledMessage={() => {
            if (scheduledMessageForEdit) onDeleteScheduledMessage?.(scheduledMessageForEdit);
            close();
          }}
          scheduledMessageForEdit={scheduledMessageForEdit}
          disableForm={disabled}
          trackingIdSuffix={schedulePopoverTrackingIdSuffix}
          hidePausable={hidePausable}
        />
      )}
    </span>
  );
};
