import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Device, DeviceType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useQueryClient } from 'react-query';
import { DevicesApi, DevicesTypes } from '@frontend/api-devices';
import SipProfileAPI, { SipProfilesApiTypes } from '@frontend/api-sip-profile';
import { VoicemailConfigAPI } from '@frontend/api-voicemail-boxes';
import { useTranslation } from '@frontend/i18n';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { DropdownField, Heading, useFormField, Text, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';

type VMProps = {
  sipProfile: SipProfilesApiTypes.SipProfileType;
  device?: Device;
  tenantId?: string;
};

export const VoicemailBoxPreferences = ({ sipProfile, device, tenantId }: VMProps) => {
  const { t } = useTranslation('phone', { keyPrefix: 'devices' });
  const alerts = useAlert();
  const queryClient = useQueryClient();

  const { personalVoicemailBoxId, name } = sipProfile;

  const { data } = useQuery({
    queryKey: [tenantId, ...queryKeys.settings.listVoicemailBox()],
    queryFn: () => VoicemailConfigAPI.List({ tenantId: tenantId ?? '' }),
    select: ({ voicemailBoxes }) => voicemailBoxes.sort((a, b) => a.mailbox.name.localeCompare(b.mailbox.name)),
    enabled: !!tenantId,
  });

  const messageWaitingIndicatorProps = useFormField({
    type: 'dropdown',
    value: device?.mwiVoicemailBoxId,
  });

  const personalVoicemailProps = useFormField({
    type: 'dropdown',
    value: personalVoicemailBoxId ?? '',
  });

  const { mutate: mutateDevice } = useMutation({
    mutationFn: ({ device }: DevicesTypes.UpdateDeviceType['input']) =>
      DevicesApi.UpdateDevice({
        device,
      }),
    onSuccess: () => {
      alerts.success(t('Saved Voicemail Box Preferences'));
    },
    onError: (_e, { device }) => {
      messageWaitingIndicatorProps.onChange({
        type: 'dropdown',
        value: device?.mwiVoicemailBoxId,
        name: 'vm-box-dropdown',
      });
      alerts.error(t('Unable to save Voicemail Box Preferences. Please try again.'));
    },
  });

  const { mutate: mutateDeviceExtension } = useMutation({
    mutationFn: ({ sipProfile }: { sipProfile: SipProfilesApiTypes.SipProfileType }) =>
      SipProfileAPI.Update({ sipProfile }),
    onSuccess: () => {
      alerts.success(t('Saved Voicemail Box Preferences'));
      queryClient.invalidateQueries([tenantId, ...queryKeys.settings.listDeviceSipProfiles(device?.deviceId ?? '')]);
    },
    onError: (_e, { sipProfile }) => {
      personalVoicemailProps.onChange({
        type: 'dropdown',
        value: sipProfile?.personalVoicemailBoxId ?? '',
        name: 'personal-vm-box-dropdown',
      });
      alerts.error(t('Unable to save Voicemail Box Preferences. Please try again.'));
    },
  });

  useEffect(() => {
    if (!!messageWaitingIndicatorProps.touched && device?.deviceId) {
      mutateDevice({
        device: {
          ...device,
          mwiVoicemailBoxId: messageWaitingIndicatorProps.value,
        },
      });
    }
  }, [messageWaitingIndicatorProps.value]);

  useEffect(() => {
    if (!!personalVoicemailProps.touched) {
      mutateDeviceExtension({
        sipProfile: {
          ...sipProfile,
          personalVoicemailBoxId: personalVoicemailProps.value,
        },
      });
    }
  }, [personalVoicemailProps.value]);

  return (
    <>
      {device?.type !== DeviceType_Enum.SOFTPHONE && (
        <>
          <Heading level={3}>{t('Message Key & Notifications')}</Heading>
          <Text color='light'>
            {t(
              'Choose the voicemail box that you will access when you press the message key on this device. New messages in this selected voicemail box will be notified by an indicator light on the device and a notification on the device’s screen. '
            )}
          </Text>
          <DropdownField
            helperText={t('{{name}} will receive notifications for new messages in this voicemail box.', {
              name,
            })}
            {...messageWaitingIndicatorProps}
            name='vm-box-dropdown'
            label={t('Select Voicemail Box')}
            css={css`
              margin-top: ${theme.spacing(2)};
              max-width: ${theme.spacing(40)};
            `}
          >
            <DropdownField.Option value=''>Select Voicemail Box</DropdownField.Option>
            {(data || []).map(({ mailbox }) => (
              <DropdownField.Option key={mailbox.id} value={mailbox.id} searchValue={mailbox.name}>
                {mailbox.name}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </>
      )}

      <Heading
        css={css`
          ${device?.type !== DeviceType_Enum.SOFTPHONE && `margin-top: ${theme.spacing(2)}`};
        `}
        level={3}
      >
        {t('9001 Voicemail Box')}
      </Heading>
      <Text color='light'>
        {t(
          'Choose the voicemail box that you will access when you dial 9001 on this device. This will not change where missed calls are routed to.'
        )}
      </Text>
      <DropdownField
        helperText={t('You will listen to this voicemail box when you dial 9001 on this device.')}
        {...personalVoicemailProps}
        name='personal-vm-box-dropdown'
        label={t('Select Voicemail Box')}
        css={css`
          margin-top: ${theme.spacing(2)};
          max-width: ${theme.spacing(40)};
        `}
      >
        <DropdownField.Option value=''>Select Voicemail Box</DropdownField.Option>
        {(data || []).map(({ mailbox }) => (
          <DropdownField.Option key={mailbox.id} value={mailbox.id} searchValue={mailbox.name}>
            {mailbox.name}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </>
  );
};
