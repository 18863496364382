import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallRoute } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import PhoneOverrideApi from '@frontend/api-overrides-v2';
import { PhoneNumbersV1 } from '@frontend/api-phone-numbers';
import { scopedFilterAndSort } from '@frontend/filters';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import {
  FormRow,
  Heading,
  Modal,
  SwitchField,
  useFormField,
  useModalControl,
  Table,
  Chip,
} from '@frontend/design-system';
import { queryKeys } from '../../../../query-keys';
import { usePhoneLocationFilterShallowStore, usePhoneSettingsShallowStore } from '../../../../store/settings';
import { useTenantLocationIds } from '../../../../utils/use-tenant-location-ids';
import { CallRoutesListTable } from '../../call-routes-list-table';
import { NumbersPanelTabs } from '../../numbers-panel';
import { ExtendedCallRoute } from '../../types';
import { AddStepPanelProps, ButtonBar, HeaderBar } from './add-step-panel';

export const CallRouteStep = ({ initialState, onClose, onProceedClick, onBackClick }: AddStepPanelProps) => {
  const { t } = useTranslation('phone');

  const { locationFilters, setLocationFilters } = usePhoneLocationFilterShallowStore(
    'locationFilters',
    'setLocationFilters'
  );
  const filteredLocations = locationFilters.callRoutesIndex;

  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantLocationIds = useTenantLocationIds();
  const { getScopeName } = useAppScopeStore();
  const {
    modalProps: numbersModalProps,
    closeModal: closeNumbersModal,
    openModal: openNumbersModal,
  } = useModalControl();
  const {
    modalProps: extensionsModalProps,
    closeModal: closeExtensionsModal,
    openModal: openExtensionsModal,
  } = useModalControl();
  const [selectedCallRoute, setSelectedCallRoute] = useState<ExtendedCallRoute | undefined>(undefined);
  // The call route that is currently being viewed in one of the modals.
  const [viewingCallRoute, setViewingCallRoute] = useState<ExtendedCallRoute | undefined>(undefined);

  const switchField = useFormField({
    type: 'switch',
  });

  useEffect(() => {
    if (!numbersModalProps.show && !extensionsModalProps.show) {
      setViewingCallRoute(undefined);
    }
  }, [numbersModalProps.show, extensionsModalProps.show]);

  const { data: callRoutes = [], isLoading: callRoutesIsLoading } = CallRouteV1.Queries.useListQuery({
    request: { tenantId: settingsTenantLocation?.phoneTenantId ?? '' },
    options: {
      enabled: !!settingsTenantLocation?.phoneTenantId,
      select: ({ callRoutes }) =>
        // Sort the extension numbers so that they are displayed in ascending order.
        callRoutes.reduce<CallRoute[]>((acc, callroute) => {
          return [
            ...acc,
            {
              ...callroute,
              extensionNumbers: callroute.extensionNumbers.sort(),
            },
          ];
        }, []),
      cacheTime: 30 * 1000,
      staleTime: 30 * 1000,
    },
  });

  const callRouteLocationIds = callRoutes.map((callRoute) => callRoute.locationId).filter(Boolean);

  const filteredCallRoutes = useMemo(
    () =>
      scopedFilterAndSort({
        data: callRoutes,
        scopeIdAccessor: (callRoute) => (callRoute.locationId ? [callRoute.locationId] : []),
        scopeIds: callRouteLocationIds,
        filteredScopeIds: filteredLocations,
      }),
    [callRoutes, filteredLocations]
  );

  const { data: phoneOverrides = [], isLoading: phoneOverridesIsLoading } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.listOverrides()],
    queryFn: async () => PhoneOverrideApi.List({ tenantId: settingsTenantLocation?.phoneTenantId ?? '' }),
    select: (data) => data?.phoneOverrides ?? [],
    enabled: !!settingsTenantLocation?.phoneTenantId,
    retry: false,
  });
  const validPhoneOverrides = phoneOverrides.filter((override) => override.id);

  const { data: allPhoneNumbers, isLoading: phoneNumbersIsLoading } =
    PhoneNumbersV1.Queries.useListPhoneNumbersForLocationsQuery({
      request: { locationIds: tenantLocationIds },
      options: { enabled: !!tenantLocationIds.length, select: (data) => data?.phoneNumbers ?? [], retry: false },
    });

  const isLoading = callRoutesIsLoading || phoneOverridesIsLoading || phoneNumbersIsLoading;

  // tableData is the callRoutes data with the phoneNumbers mapped to the callRoute (since
  // the callRoute only has an array of phoneNumberIds) and also the phoneOverrides. The
  // phoneOverrides data has the callRouteId that maps to a callRoute.
  const tableData = useMemo(() => {
    const extendedData =
      filteredCallRoutes?.map((callRoute) => {
        const phoneNumberIds = callRoute.phoneNumberIds ?? [];
        const phoneNumbersForCallRoute =
          allPhoneNumbers?.filter((phoneNumber) => phoneNumberIds.includes(phoneNumber.id)) ?? [];
        const callRoutePhoneOverride = validPhoneOverrides?.find(
          (phoneOverride) => phoneOverride.instructionSetId === callRoute.rootInstructionSetId
        );

        return {
          ...callRoute,
          phoneNumbers: phoneNumbersForCallRoute,
          callRoutePhoneOverride,
        };
      }) ?? [];

    const showSelected = switchField.value === true;
    if (showSelected && selectedCallRoute) {
      return extendedData.filter((callRoute) => callRoute.callRouteId === selectedCallRoute.callRouteId);
    } else {
      return extendedData;
    }
  }, [filteredCallRoutes, allPhoneNumbers, phoneOverrides, switchField.value, selectedCallRoute]);

  useEffect(() => {
    if (initialState?.callObject?.primitiveId) {
      setSelectedCallRoute(
        tableData.find((callRoute) => callRoute.callRouteId === initialState?.callObject?.primitiveId)
      );
    }
  }, [initialState, tableData]);

  const handleProceedClick = () => {
    if (!selectedCallRoute) {
      console.error('No call route selected');
      return;
    }

    onProceedClick({
      callObject: {
        primitiveId: selectedCallRoute.callRouteId,
        primitiveName: selectedCallRoute.name,
        instructionId: initialState?.callObject?.instructionId ?? '',
        instructionSetId: initialState?.callObject?.instructionSetId ?? '',
        callRouteExpansion: {
          extensions: selectedCallRoute.extensionNumbers.map((ext) => String(ext)),
          phoneNumbers: selectedCallRoute.phoneNumberIds,
          locationId: selectedCallRoute.locationId,
        },
      },
    });
  };

  const moreTextClickHandler = (type: NumbersPanelTabs, callRoute?: ExtendedCallRoute) => {
    setViewingCallRoute(callRoute);
    if (type === NumbersPanelTabs.PHONE_NUMBERS) {
      openNumbersModal();
    } else if (type === NumbersPanelTabs.EXTENSIONS) {
      openExtensionsModal();
    }
  };

  const hasChildLocations = Boolean(settingsTenantLocation?.children?.length);

  return (
    <>
      <HeaderBar
        css={css`
          margin-bottom: 0;
        `}
        title={t('Call Route')}
        onClose={onClose}
      />

      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Heading
          css={css`
            display: inline-block;
          `}
          level={3}
        >
          {t('Select Call Route')}
        </Heading>
        <FormRow
          css={css`
            display: inline-block;
            margin-bottom: 0;
          `}
        >
          <SwitchField
            {...switchField}
            name='showSelected'
            label={t('Show selected Call Route')}
            labelPlacement='left'
            disabled={!selectedCallRoute}
          />
        </FormRow>
      </div>

      {settingsTenantLocation && (
        <CallRoutesListTable
          tableData={tableData}
          filteredLocations={filteredLocations}
          setLocationFilters={setLocationFilters}
          isLoading={isLoading}
          settingsTenantLocation={settingsTenantLocation}
          clickableCallRoutes={false}
          onMoreTextClick={moreTextClickHandler}
          onRowSelect={setSelectedCallRoute}
          initialSelectedCallRouteId={initialState?.callObject?.primitiveId}
        />
      )}

      <ButtonBar
        primaryButtonLabel={t('Done')}
        primaryButtonDisabled={!selectedCallRoute}
        backButtonLabel={initialState?.callObject.primitiveId ? t('Change Step') : t('Back')}
        onPrimaryButtonClick={handleProceedClick}
        onCancelClick={onClose}
        onBackClick={onBackClick}
      />

      <Modal {...numbersModalProps} minWidth={hasChildLocations ? 600 : 400}>
        <Modal.Header onClose={closeNumbersModal}>{t('Phone Numbers')}</Modal.Header>
        <Modal.Label>{viewingCallRoute?.name}</Modal.Label>
        <Modal.Body>
          <Table
            data={viewingCallRoute?.phoneNumbers ?? []}
            colConfig={[
              {
                Header: t('Phone Number'),
                accessor: (phoneNumber) => phoneNumber.phoneNumber.nationalNumber,
                id: 'phone-number',
                cellRenderer: (phoneNumber) => formatPhoneNumber(phoneNumber),
                width: 180,
              },
              {
                Header: t('Location'),
                accessor: (phoneNumber) => phoneNumber.locationId,
                cellRenderer: (locationId) =>
                  locationId ? <Chip.SingleChip>{getScopeName(locationId)}</Chip.SingleChip> : '',
                id: 'location-name',
                sortType: 'caseInsensitive',
                width: 190,
                omit: !hasChildLocations,
              },
            ]}
          />
        </Modal.Body>
        <Modal.Footer
          secondary={{
            label: t('Close'),
            onClick: closeNumbersModal,
          }}
        />
      </Modal>

      <Modal {...extensionsModalProps} minWidth={400}>
        <Modal.Header onClose={closeExtensionsModal}>{t('Extensions')}</Modal.Header>
        <Modal.Label>{viewingCallRoute?.name}</Modal.Label>
        <Modal.Body>
          <Table
            data={viewingCallRoute?.extensionNumbers.map((extension) => ({ extension })) ?? []}
            colConfig={[
              {
                Header: t('Extension'),
                accessor: (row) => row.extension,
                id: 'extension',
              },
            ]}
          />
        </Modal.Body>
        <Modal.Footer
          secondary={{
            label: t('Close'),
            onClick: closeExtensionsModal,
          }}
        />
      </Modal>
    </>
  );
};
