import { PetDetails } from '@weave/schema-gen-ts/dist/schemas/pets-api/v1/pets_api.pb';
import { http } from '@frontend/fetch';
import {
  GenerateLinkPayload,
  GenerateMagicLinkReturnType,
  PersonType,
  VerifyUserPayload,
  VerifyUserReturnType,
} from './types';

export const portalServiceUrls = {
  verifyUser: '/patient-portal/api/v1/user/verify',
  getPersonPets: (personId: string, orgId: string) => `/patient-portal/api/v1/persons/${personId}/pets?org_id=${orgId}`,
  getPetById: (petId: string, orgId: string, personId: string) =>
    `/patient-portal/api/v1/pets/${petId}?org_id=${orgId}&person_id=${personId}`,
  getPersonById: (personId: string, orgId: string) => `/patient-portal/api/v1/persons/${personId}?org_id=${orgId}`,
};

const authClientUrls = {
  magicLink: '/patient-portal/auth-client/magic-link',
};

export const getPersonPets = (personId: string, orgId: string) => {
  return http.get<{ pets: PetDetails[] }>(portalServiceUrls.getPersonPets(personId, orgId));
};

export const getPetById = (petId: string, orgId: string, personId: string) => {
  return http.get<{ pet: PetDetails }>(portalServiceUrls.getPetById(petId, orgId, personId));
};

export const getPersonById = ({ personId, orgId }: { personId: string; orgId: string }) => {
  return http.get<{ person: PersonType }>(portalServiceUrls.getPersonById(personId, orgId));
};

export const verifyUser = ({ otp, personId, orgId, authToken }: VerifyUserPayload) => {
  return http.post<VerifyUserReturnType>(
    portalServiceUrls.verifyUser,
    {
      otp,
      person_id: personId,
      org_id: orgId,
    },
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
};

export const generatePortalMagicLink = ({ personId, locationId }: GenerateLinkPayload) => {
  return http.post<GenerateMagicLinkReturnType>(authClientUrls.magicLink, {
    personId: personId,
    org_id: locationId,
  });
};
