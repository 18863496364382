import {
  Audience,
  Campaign,
  Status_Enum as CampaignStatus,
  Counts,
  GetAudienceRequest,
  GetCampaignRequest,
  GetCampaignsSummaryRequest,
  ListCampaignsRequest,
  ListListsRequest,
  ListListsResponse,
  ListSegmentsRequest,
  Segment,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { Query, QueryClient, QueryKey, UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { HTTPBoundSchemaMethod, SchemaIO } from '@frontend/schema';
import { keys } from './queries';
import { SchemaBulkMessagingService } from './service';
import { sortCampaigns } from './utils';

// TODO: Replace SchemaMutationOptions with helper type from the query helper library. Or just remove it completely.
type SchemaMutationOptions<T extends HTTPBoundSchemaMethod<any>> = UseMutationOptions<
  SchemaIO<T>['output'],
  unknown,
  SchemaIO<T>['input'],
  unknown
>;

const isListEmailDraftsRequestType = (request: ListCampaignsRequest): request is ListCampaignsRequest =>
  !!(request.sort && request.locationIds && request.orgId && request.year);

const getListCampaignDraftsQueryKey = (queryClient: QueryClient) => {
  const cache = queryClient.getQueryCache();
  const queryKey =
    cache
      ?.findAll(keys.draftCampaigns())
      .map((query) => query.queryKey)
      .find((query) => {
        const typedQuery = query[2] as ListCampaignsRequest;
        if (typedQuery && isListEmailDraftsRequestType(typedQuery)) {
          const { sort, locationIds, orgId, year } = typedQuery;
          return sort && locationIds && orgId && year;
        }
        return false;
      }) ?? keys.draftCampaigns();
  return queryKey;
};

const getDraftStatuses = (campaign: Campaign) => {
  const isDraft = campaign.currentStatus === CampaignStatus.DRAFT;
  const isHiddenDraft = campaign.currentStatus === CampaignStatus.HIDDEN_DRAFT;
  return { isDraft, isHiddenDraft };
};

// TODO: This doesn't seem like the right way to find query keys to mutate since it just finds the first request that is of a certain type.
// What if the query key we want is the second or third request of this type? Then we will muck with the wrong thing.
// Possibly make the match function just return a boolean. That way the match can look for a key that matches the request object exactly
// or it could just return this first request of a given type or do anything really.
// Or even better, just use the built in query find function in React Query to get the right thing.
type TypeGuard<T> = (value: any) => value is T;
const useFindQueryKey = <T>(match: TypeGuard<T>, baseQueryKey: readonly string[]) => {
  const queryClient = useQueryClient();
  const cache = queryClient.getQueryCache();
  const queryKey =
    cache
      .findAll(baseQueryKey)
      .map((query) => query.queryKey)
      .find((query) => match(query[2])) ?? baseQueryKey; // 3rd element in the query key is the request object
  return queryKey;
};

const isGetCampaignsSummaryRequest = (value?: any): value is GetCampaignsSummaryRequest =>
  !!(value && value.locationIds && value.orgId && value.types && value.year);

// By convention, the 3rd element in the query key is the request object
const getQueryKeyRequest = <T>(query: Query) => query.queryKey[2] as T;

export const useCreateCampaign = (
  options: SchemaMutationOptions<(typeof SchemaBulkMessagingService)['CreateCampaign']> = {}
) => {
  const queryClient = useQueryClient();
  const summaryQueryKey = useFindQueryKey(isGetCampaignsSummaryRequest, keys.campaignsSummary());
  const draftsQueryKey = getListCampaignDraftsQueryKey(queryClient);

  return useMutation({
    mutationKey: 'create-campaign',
    mutationFn: SchemaBulkMessagingService.CreateCampaign,

    onMutate: (request) => {
      const { isDraft, isHiddenDraft } = getDraftStatuses(request.campaign);
      const queryKey = isDraft ? draftsQueryKey : summaryQueryKey;
      const previousCampaigns = queryClient.getQueryData<Campaign[]>(queryKey);

      queryClient.cancelQueries(queryKey);
      if (isHiddenDraft) return { previous: previousCampaigns };

      const newList = sortCampaigns([...(previousCampaigns ?? []), request.campaign]);
      queryClient.setQueryData(queryKey, newList);
      return { previousCampaigns };
    },

    onError: (_error, request, context: unknown) => {
      const { isDraft } = getDraftStatuses(request.campaign);
      const queryKey = isDraft ? draftsQueryKey : summaryQueryKey;
      const { previousCampaigns } = context as { previousCampaigns: Campaign[] };
      queryClient.setQueryData(queryKey, previousCampaigns);
    },

    onSettled: (response, _error, request) => {
      const { isDraft, isHiddenDraft } = getDraftStatuses(request.campaign);
      if (isHiddenDraft) return;

      const queryKey = isDraft ? keys.draftCampaigns() : keys.campaignsSummary();
      queryClient.invalidateQueries(queryKey);

      if (!response) return;
      queryClient.invalidateQueries(keys.getAudience({ campaignId: response.campaignId, orgId: request.orgId }));
      queryClient.setQueryData(keys.getCampaign({ campaignId: response.campaignId, orgId: request.orgId }), response);
    },

    ...options,
  });
};

export const useCreateSegment = (
  options: SchemaMutationOptions<(typeof SchemaBulkMessagingService)['CreateSegment']> = {},
  additionalOnSuccess?: () => void // TODO: Figure out a better, more elegant way of handling additional onSuccess
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'create-segment',
    mutationFn: SchemaBulkMessagingService.CreateSegment,

    onMutate: (request) => {
      queryClient.cancelQueries(keys.listSegments({ campaignId: request.campaignId, orgId: request.orgId }));
    },

    onSuccess: (response, request) => {
      additionalOnSuccess?.();

      const segmentsQueryKey = keys.listSegments({ campaignId: request.campaignId, orgId: request.orgId });
      queryClient.invalidateQueries(segmentsQueryKey);
      const previousSegments = queryClient.getQueryData<Segment[]>(segmentsQueryKey);
      const updatedSegments: Segment[] = [...(previousSegments ?? []), response];
      queryClient.setQueryData(segmentsQueryKey, updatedSegments);

      // Invalidate the campaign and update the recipients count
      const campaignQueryKey = keys.getCampaign({ campaignId: request.campaignId, orgId: request.orgId });
      const previousCampaign = queryClient.getQueryData<Campaign>(campaignQueryKey);
      if (!previousCampaign) return;
      queryClient.invalidateQueries(campaignQueryKey);
      const updatedTotalRecipients = updatedSegments.reduce((acc, segment) => acc + (segment?.recipientCount ?? 0), 0);
      const updatedMessageCounts: Counts = {
        total: updatedTotalRecipients,
        delivered: previousCampaign.messageCounts?.delivered ?? 0,
        failed: previousCampaign.messageCounts?.failed ?? 0,
        scheduled: previousCampaign.messageCounts?.scheduled ?? 0,
      };
      const updatedCampaign: Campaign = { ...previousCampaign, messageCounts: updatedMessageCounts };
      queryClient.setQueryData(campaignQueryKey, updatedCampaign);

      // Invalidate the audience
      queryClient.invalidateQueries(keys.getAudience({ campaignId: request.campaignId, orgId: request.orgId }));
    },

    onSettled: (_response, _error, _request) => {
      queryClient.invalidateQueries(keys.campaignsSummary());
    },

    ...options,
  });
};

export const useDeleteCampaign = (
  isDraft = false,
  options: SchemaMutationOptions<(typeof SchemaBulkMessagingService)['DeleteCampaign']> = {}
) => {
  const queryClient = useQueryClient();
  const queryKey = isDraft ? keys.draftCampaigns() : keys.campaignsSummary();

  return useMutation({
    mutationKey: 'delete-campaign',
    mutationFn: SchemaBulkMessagingService.DeleteCampaign,

    onMutate: (request) => {
      queryClient.cancelQueries(queryKey);

      const previousCampaigns = queryClient.getQueriesData<Campaign[]>(queryKey);

      queryClient.setQueriesData<Campaign[] | undefined>({ queryKey }, (campaigns) =>
        campaigns?.filter((campaign) => campaign.campaignId !== request.campaignId)
      );

      return { previousCampaigns };
    },

    onError: (_error, _request, context: unknown) => {
      const { previousCampaigns } = context as { previousCampaigns: [QueryKey, Campaign[]][] };
      queryClient.setQueriesData(queryKey, previousCampaigns);
    },

    onSuccess: (_response, request) => {
      queryClient.removeQueries(keys.campaign(), {
        predicate: (query) => getQueryKeyRequest<GetCampaignRequest>(query).campaignId === request.campaignId,
      });

      queryClient.removeQueries(keys.audience(), {
        predicate: (query) => getQueryKeyRequest<GetAudienceRequest>(query).campaignId === request.campaignId,
      });

      queryClient.removeQueries(keys.segments(), {
        predicate: (query) => getQueryKeyRequest<ListSegmentsRequest>(query).campaignId === request.campaignId,
      });

      queryClient.invalidateQueries(queryKey);
    },

    ...options,
  });
};

export const useDeleteSegment = (
  options: SchemaMutationOptions<(typeof SchemaBulkMessagingService)['DeleteSegment']> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'delete-segment',
    mutationFn: SchemaBulkMessagingService.DeleteSegment,

    onMutate: (request) => {
      const queryKey = keys.listSegments({ campaignId: request.campaignId, orgId: request.orgId });
      queryClient.cancelQueries(queryKey);
      const previousSegments = queryClient.getQueryData<Segment[]>(queryKey);
      const newList = previousSegments?.filter((segment) => segment.segmentId !== request.segmentId);
      queryClient.setQueryData(queryKey, newList);
      return { previousSegments };
    },

    onError: (_error, request, context: unknown) => {
      const { previousSegments } = context as { previousSegments?: Segment[] };
      queryClient.setQueryData(
        keys.listSegments({ campaignId: request.campaignId, orgId: request.orgId }),
        previousSegments
      );
    },

    onSettled: (_response, _error, request) => {
      queryClient.invalidateQueries(keys.listSegments({ campaignId: request.campaignId, orgId: request.orgId }));
      queryClient.invalidateQueries(keys.getAudience({ campaignId: request.campaignId, orgId: request.orgId }));
    },

    ...options,
  });
};

type UpdateCampaignMutationContext = {
  previousCampaign?: Campaign;
  previousSummaryCampaigns: Campaign[];
  previousDraftCampaigns: Campaign[];
};

export const useUpdateCampaign = (
  options: SchemaMutationOptions<(typeof SchemaBulkMessagingService)['UpdateCampaign']> = {}
) => {
  const queryClient = useQueryClient();
  const summaryQueryKey = useFindQueryKey(isGetCampaignsSummaryRequest, keys.campaignsSummary());
  const draftsQueryKey = getListCampaignDraftsQueryKey(queryClient);

  return useMutation({
    mutationKey: 'update-campaign',
    mutationFn: SchemaBulkMessagingService.UpdateCampaign,

    onMutate: (request) => {
      queryClient.cancelQueries(summaryQueryKey);
      queryClient.cancelQueries(draftsQueryKey);

      const campaignQueryKey = keys.getCampaign({ campaignId: request.campaign.campaignId, orgId: request.orgId });

      const previousCampaign = queryClient.getQueryData<Campaign>(campaignQueryKey);
      const summaryCampaigns = queryClient.getQueryData<Campaign[]>(summaryQueryKey) ?? [];
      const draftCampaigns =
        (queryClient.getQueryData<{ draftCampaigns: Campaign[] }>(draftsQueryKey) ?? {}).draftCampaigns ?? [];

      let updatedSummaryCampaigns: Campaign[];
      let updatedDraftCampaigns: Campaign[];

      const { isDraft: isRequestDraft } = getDraftStatuses(request.campaign);

      // drafts flow
      if (isRequestDraft) {
        const hasExistingDraft = draftCampaigns.find((campaign) => campaign.campaignId === request.campaign.campaignId);
        if (hasExistingDraft) {
          // Updates the existing campaign in the drafts list
          updatedDraftCampaigns = draftCampaigns.map((draftCampaign) =>
            draftCampaign.campaignId === request.campaign.campaignId ? request.campaign : draftCampaign
          );
        } else {
          // Adds the campaign to the drafts list if it's not found
          updatedDraftCampaigns = [...draftCampaigns, request.campaign];
        }

        // Removes the draft campaign from the summary list
        updatedSummaryCampaigns = summaryCampaigns.filter(
          (campaign) => campaign.campaignId !== request.campaign.campaignId
        );
      }
      // real campaigns flow
      else {
        const hasExistingCampaign = summaryCampaigns.find(
          (campaign) => campaign.campaignId === request.campaign.campaignId
        );
        if (hasExistingCampaign) {
          // Update the existing campaign in the summary list
          updatedSummaryCampaigns = summaryCampaigns.map((campaign) =>
            campaign.campaignId === request.campaign.campaignId ? request.campaign : campaign
          );
        } else {
          // Add the campaign to the summary list
          updatedSummaryCampaigns = [...summaryCampaigns, request.campaign];
        }

        // Remove the campaign from the drafts list
        updatedDraftCampaigns = draftCampaigns.filter(
          (draftCampaign) => draftCampaign.campaignId !== request.campaign.campaignId
        );
      }

      queryClient.setQueryData(campaignQueryKey, request.campaign);
      queryClient.setQueryData(summaryQueryKey, sortCampaigns(updatedSummaryCampaigns));
      queryClient.setQueryData(draftsQueryKey, sortCampaigns(updatedDraftCampaigns));

      return {
        previousCampaign,
        previousSummaryCampaigns: summaryCampaigns,
        previousDraftCampaigns: draftCampaigns,
      } as UpdateCampaignMutationContext;
    },

    onError: (_error, request, context: unknown) => {
      const { previousCampaign, previousSummaryCampaigns, previousDraftCampaigns } =
        context as UpdateCampaignMutationContext;
      const campaignQueryKey = keys.getCampaign({ campaignId: request.campaign.campaignId, orgId: request.orgId });
      queryClient.setQueryData(campaignQueryKey, previousCampaign);
      queryClient.setQueryData(summaryQueryKey, previousSummaryCampaigns);
      queryClient.setQueryData(draftsQueryKey, previousDraftCampaigns);
    },

    onSettled: (_response, _error, request) => {
      const campaignQueryKey = keys.getCampaign({ campaignId: request.campaign.campaignId, orgId: request.orgId });
      queryClient.invalidateQueries(campaignQueryKey);
    },

    ...options,
  });
};

type ListInfiniteQuery = { pages: ListListsResponse[]; pageParams: string[] };
type ListMutationContext = { previous: ListInfiniteQuery };
export const useCreateList = (queryKey: (string | ListListsRequest)[]) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'createList',
    mutationFn: SchemaBulkMessagingService.CreateList,
    onSuccess: (response, request) => {
      const previous = queryClient.getQueryData<ListInfiniteQuery>(queryKey);
      queryClient.setQueryData(queryKey, () => {
        const pages = [{ lists: [{ ...response, recipients: request.list.recipients }] }, ...(previous?.pages ?? [])];
        return { pages, pageParams: previous?.pageParams ?? [] };
      });
    },
  });
};

export const useDeleteList = (queryKey: (string | ListListsRequest)[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteList',
    mutationFn: SchemaBulkMessagingService.DeleteList,
    onMutate: (request) => {
      queryClient.cancelQueries(queryKey);
      const previous = queryClient.getQueryData<ListInfiniteQuery>(queryKey);
      queryClient.setQueryData(queryKey, () => {
        const pages =
          previous?.pages?.map((page) => ({ lists: page.lists.filter((list) => list.listId !== request.listId) })) ??
          [];
        return { pages, pageParams: previous?.pageParams ?? [] };
      });
      return { previous };
    },
    onError: (_error, _request, context: unknown) => {
      const { previous } = context as ListMutationContext;
      queryClient.setQueryData(queryKey, previous);
    },
  });
};

export const useUpdateList = (queryKey: (string | ListListsRequest)[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'updateList',
    mutationFn: SchemaBulkMessagingService.UpdateList,
    onMutate: (request) => {
      queryClient.cancelQueries(queryKey);
      const previous = queryClient.getQueryData<ListInfiniteQuery>(queryKey);
      queryClient.setQueryData(queryKey, () => {
        const pages =
          previous?.pages?.map((page) => ({
            lists: page.lists.map((list) => (list.listId === request.list.listId ? request.list : list)),
          })) ?? [];
        return { pages, pageParams: previous?.pageParams ?? [] };
      });
      return { previous };
    },
    onError: (_error, _request, context: unknown) => {
      const { previous } = context as ListMutationContext;
      queryClient.setQueryData(queryKey, previous);
    },
  });
};

type LimitAudienceMutationContext = {
  previousAudience?: Audience;
  previousCampaign?: Campaign;
  recipients?: number;
};

export const useLimitAudience = (
  options: SchemaMutationOptions<(typeof SchemaBulkMessagingService)['LimitAudience']> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'limit-audience',
    mutationFn: SchemaBulkMessagingService.LimitAudience,
    onMutate: (req) => {
      const audienceQueryKey = keys.getAudience({ campaignId: req.campaignId, orgId: req.orgId ?? '' });
      const campaignQueryKey = keys.getCampaign({ campaignId: req.campaignId, orgId: req.orgId ?? '' });

      queryClient.cancelQueries(audienceQueryKey);
      queryClient.cancelQueries(campaignQueryKey);

      const previousAudience = queryClient.getQueryData<Audience>(audienceQueryKey);
      const previousCampaign = queryClient.getQueryData<Campaign>(campaignQueryKey);

      const recipients = req.limits?.reduce((acc, limit) => acc + (limit.count || 0), 0);
      const newCampaign = { ...previousCampaign, recipients };

      queryClient.setQueryData(campaignQueryKey, newCampaign);
      queryClient.setQueryData(audienceQueryKey, { ...previousAudience, counts: req.limits });

      return { previousAudience, previousCampaign, recipients };
    },
    onError: (_error, request, context: unknown) => {
      const { previousAudience, previousCampaign } = context as LimitAudienceMutationContext;
      queryClient.setQueryData(
        keys.getAudience({ campaignId: request.campaignId, orgId: request.orgId ?? '' }),
        previousAudience
      );
      queryClient.setQueryData(
        keys.getCampaign({ campaignId: request.campaignId, orgId: request.orgId ?? '' }),
        previousCampaign
      );
    },
    onSettled: (_response, _error, request, context: unknown) => {
      const typedContext = context as LimitAudienceMutationContext;
      const isDraft =
        typedContext.previousCampaign?.currentStatus === CampaignStatus.DRAFT ||
        typedContext.previousCampaign?.currentStatus === CampaignStatus.HIDDEN_DRAFT;
      const listQueryKey = isDraft ? keys.draftCampaigns() : keys.campaignsSummary();

      const previousList = queryClient.getQueryData<Campaign[]>(listQueryKey);
      const newList = previousList?.map((campaign) => {
        if (campaign.campaignId === request.campaignId) {
          return { ...campaign, recipients: typedContext?.recipients };
        }
        return campaign;
      });

      queryClient.setQueryData(listQueryKey, newList);
      queryClient.invalidateQueries(keys.getCampaign({ campaignId: request.campaignId, orgId: request.orgId ?? '' }));
    },
    ...options,
  });
};
