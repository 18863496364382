import { useMemo } from 'react';
import { GetPayoutDescriptorResponse } from '@weave/schema-gen-ts/dist/schemas/payments-platform/stripe-integration';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { WithPayoutDescriptor } from '@frontend/api-payments';
import { SchemaStripeIntegrationService } from '@frontend/schema';

const queryKeys = {
  base: ['payments', 'payoutDescriptor'] as const,
  payoutDesriptor: (locationIds: string[]) => [...queryKeys.base, locationIds],
};

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return {
    invalidatePayoutQueryDescriptor: (locationIds: string[]) => {
      queryClient.invalidateQueries(queryKeys.payoutDesriptor(locationIds));
    },
  };
};

type UseGetPayoutDescriptorsOptions = {
  locationIds: string[];
};

export const useGetPayoutDescriptors = (
  { locationIds }: UseGetPayoutDescriptorsOptions,
  options: Omit<UseQueryOptions<GetPayoutDescriptorResponse>, 'queryKey' | 'queryFn'> = {}
) => {
  return useQuery<GetPayoutDescriptorResponse>({
    queryKey: queryKeys.payoutDesriptor(locationIds),
    queryFn: () => SchemaStripeIntegrationService.GetPayoutDescriptor({ locationIds }),
    ...options,
  });
};

export type UsePayoutDescriptorOptions = {
  locationIds: string[];
  enabled?: boolean;
};

export const usePayoutDescriptor = ({ locationIds, enabled = true }: UsePayoutDescriptorOptions) => {
  const { data } = useGetPayoutDescriptors({ locationIds }, { enabled: enabled && locationIds.length > 0 });
  const { payoutDescriptors, payoutDescriptorLength } = useMemo(() => {
    const payoutDescriptors = data?.payoutDescriptors ?? {};
    const payoutDescriptorLength = Object.values(payoutDescriptors).filter((v) => v).length;
    return { payoutDescriptors, payoutDescriptorLength };
  }, [data]);

  return { payoutDescriptors, payoutDescriptorLength };
};

type UseDataWithPayoutDescriptorOptions<T> = UsePayoutDescriptorOptions & {
  data: T[];
};

/**
 *
 * @param data memoized array of records to add the payoutDescriptor to.
 * @param locationIds locationIds to get the payoutDescriptor for.
 * @returns dataWithDescriptors memoized array of records with the payoutDescriptor added.
 */
export const useDataWithPayoutDescriptor = <T extends { locationId?: string | string[] }>({
  data,
  locationIds,
  enabled = true,
}: UseDataWithPayoutDescriptorOptions<T>) => {
  const { payoutDescriptors } = usePayoutDescriptor({ locationIds, enabled });

  const dataWithDescriptors: WithPayoutDescriptor<T>[] = useMemo(() => {
    return enabled
      ? data.map((dataItem) => {
          const locationId = Array.isArray(dataItem.locationId) ? dataItem.locationId?.[0] : dataItem.locationId;
          const payoutDescriptor = locationId ? payoutDescriptors[locationId] : '';
          return { ...dataItem, payoutDescriptor };
        })
      : data;
  }, [data, payoutDescriptors, enabled]);

  return { dataWithDescriptors };
};
