import { FC } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { GetSubmissionDetailsForPersonResponse_SubmissionDetails } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useDownloadPDF } from '@frontend/forms-digital-hooks';
import { useTranslation } from '@frontend/i18n';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { Text, Chip, styles, Button } from '@frontend/design-system';
import { pendoTags } from '../pendo-tags';
import { itemContainerStyle, displayFixStyle, centerAlignmentStyle } from './form-item.styles';

type FormItemProps = {
  form: GetSubmissionDetailsForPersonResponse_SubmissionDetails;
  formType: 'submission' | 'sent';
  locationId: string;
};

export const FormItem: FC<FormItemProps> = ({ form, formType, locationId }) => {
  const { t } = useTranslation('forms');
  const navigate = useNavigate();
  const formatTimestamp = useTimestampFormatter();
  const { documentName, createdAt, submittedAt, expiryDate, submissionId } = form;
  const { downloadPDF } = useDownloadPDF({
    submissionId: submissionId || '', // default to an empty string if undefined
    pdfName: documentName || '',
    companyId: locationId || '',
  });

  const expiryDateObj = expiryDate && new Date(expiryDate);
  const isExpired = expiryDateObj && expiryDateObj < new Date();

  const redirectToSubmissionHandler = (submissionId: string, locationId: string) => {
    const url = `/forms/submissions/inbox/all/${submissionId}?locationId=${locationId}`;
    navigate({ to: url });
  };

  return (
    <div css={itemContainerStyle}>
      <div css={displayFixStyle}>
        <div>
          <Text weight='bold' css={[styles.truncate, { maxWidth: 175 }]} style={{ marginRight: theme.spacing(0.5) }}>
            {documentName}
          </Text>
          {formType === 'submission' && (
            <Text size='medium'>{submittedAt && `${t('Submitted')} ${formatTimestamp(submittedAt)}`}</Text>
          )}
          {formType === 'sent' && (
            <Text size='medium'>{createdAt && `${t('Sent')} ${formatTimestamp(createdAt)}`}</Text>
          )}
        </div>
        {formType === 'submission' && (
          <div css={centerAlignmentStyle}>
            <Button
              iconName='preview'
              variant='secondary'
              trackingId={pendoTags.contactPanel.preview}
              hoverLabel={t('Preview')}
              onClick={() => redirectToSubmissionHandler(submissionId || '', locationId)}
            />
            <Button
              iconName='download'
              variant='secondary'
              trackingId={pendoTags.contactPanel.downloadPdf}
              hoverLabel={t('Download PDF')}
              onClick={() => downloadPDF()}
            />
          </div>
        )}
        {formType === 'sent' && (
          <div css={centerAlignmentStyle}>
            {isExpired ? (
              <Chip variant='warn'>{t('Expired')}</Chip>
            ) : (
              <Chip variant='neutral' css={[styles.truncate, { maxWidth: 110 }]}>
                {t('Not Submitted')}
              </Chip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
