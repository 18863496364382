import { FC } from 'react';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu, Text } from '@frontend/design-system';
import { TaskFormRow } from '../../common';

interface LocationPickerProps {
  data: Array<{ id: string; name?: string }>;
  label: string;
  placeholder: string;
  value: string;
  shouldRenderChildren: boolean;
  onSelect: (value: string) => void;
}

export const LocationPicker: FC<LocationPickerProps> = ({
  data,
  label,
  placeholder,
  value,
  shouldRenderChildren = false,
  onSelect,
}) => {
  const { getItemProps, getMenuProps, getTriggerProps, close } = usePopoverMenu();
  let children;

  const dataValue = data.find((item) => item.id === value)?.name ?? '';

  const onClickItem = (id: string) => {
    onSelect(id);
    close();
  };

  if (data.length >= 1 && shouldRenderChildren) {
    children = (
      <PopoverMenu {...getMenuProps()}>
        {data.map(({ name, id }) => (
          <PopoverMenuItem key={`location-picker-${id}`} {...getItemProps()} onClick={() => onClickItem(id)}>
            <Text>{name}</Text>
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    );
  }

  return (
    <TaskFormRow
      label={label}
      icon='locations'
      placeholder={placeholder}
      buttonIconName='location'
      value={dataValue}
      getTriggerProps={getTriggerProps}
      disabled={data.length === 1 || !shouldRenderChildren}
      isRemovable={false}
    >
      {children}
    </TaskFormRow>
  );
};
