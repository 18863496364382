import { useRef } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { MediaUploadPreview } from '@frontend/media-upload-preview';
import { AutoGrowTextarea, TextareaRef } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import { EmojiButton, IconButton, useFileUpload } from '@frontend/design-system';
import { DropzoneMessage } from '../../common';
import { SendingMediaItem } from '../types';

type BodyFieldProps = {
  fileUploadProps: ReturnType<typeof useFileUpload>;
  value: string;
  onChange: (value: string) => void;
  attachments: SendingMediaItem[];
  removeAttachment: (id: string) => void;
};
export const BodyField = ({ fileUploadProps, value, onChange, attachments, removeAttachment }: BodyFieldProps) => {
  const { t } = useTranslation('integrated-messaging');
  const inputRef = useRef<TextareaRef>(null);

  const addEmoji = ({ emoji }: { emoji: string }) => {
    const { selectionStart, selectionEnd } = inputRef.current?.getSelection() || {};
    const newValue = `${value.slice(0, selectionStart)}${emoji}${value.slice(selectionEnd)}`;
    onChange(newValue);
    inputRef.current?.focus();
  };

  return (
    <div
      css={{
        padding: theme.spacing(2),
      }}
      {...fileUploadProps.dropContainerProps}
    >
      <input type='file' {...fileUploadProps.inputProps} css={{ display: 'none' }} multiple />
      <AutoGrowTextarea
        maxRows={5}
        minRows={5}
        name='body'
        onChange={(val) => onChange(val)}
        value={value}
        ref={inputRef}
      />
      <MediaUploadPreview media={attachments} removeMediaItem={removeAttachment} inPopout />
      <div
        css={{
          display: 'flex',
        }}
      >
        <IconButton label={t('Attach files')} showLabelOnHover onClick={() => fileUploadProps.handleOnClick()}>
          <Icon name='attachment' />
        </IconButton>
        <EmojiButton onSelect={addEmoji} trackingId={'payments-email-emoji-button-click'} pickerWidth={350} />
      </div>
      {fileUploadProps.isDragging && (
        <DropzoneMessage
          css={{
            position: 'absolute',
            margin: theme.spacing(1),
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.colors.white,
          }}
        />
      )}
    </div>
  );
};
