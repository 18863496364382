import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedAppFlagStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { URLs } from '../../../constants';
import { featureFlags } from '../../../feature-flags';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { useFetchMessagingROIOOverview } from '../hooks';
import { WidgetCardProps } from './types';
import { WidgetCard } from './widget-card';

export const BTWidgetCard = ({ timePeriod }: WidgetCardProps) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { selectedLocationIds } = useAppScopeStore();
  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isDemoAccount = getFeatureFlagValue(featureFlags.bulkTextRoiDemoData);

  const { aggregated, isLoading } = useFetchMessagingROIOOverview({
    apiParams: {
      messagingCategory: 'bulkmessaging',
      payload: {
        start_date: timePeriod.value.startDate,
        end_date: timePeriod.value.endDate,
        location_id: selectedLocationIds,
      },
    },
    isDemoAccount,
  });

  const handleNavigate = useCallback(() => {
    navigate({
      to: URLs.ROI_BULK_TEXT,
    });
  }, []);

  return (
    <WidgetCard
      backgroundConfig={{
        gradientStart: theme.colors.secondary.seaweed40,
        // Color isn't available in the theme
        gradientEnd: '#66DFD8',
      }}
      iconName='bulk-messaging-small'
      isLoading={isLoading}
      onClick={handleNavigate}
      subTitle={t('Appts Scheduled')}
      title={t('Bulk Texting')}
      trackingId={trackingIds.roi.bulkTextRoiWidget}
      value={formatters.value.format(aggregated?.patients_scheduled?.appointments_count)}
    />
  );
};
