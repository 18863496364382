import { FC, PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { motion } from 'motion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';

type ContentType = { title: string; image: string; action?: ReactNode };

interface ContentSliderProps {
  contentList: ContentType[];
  styles?: Interpolation<Theme>;
}

const SLIDE_DURATION = 5; // in seconds

export const ContentSlider: FC<PropsWithChildren<ContentSliderProps>> = ({ contentList = [], styles }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentContent = useMemo(() => contentList[currentIndex], [contentList, currentIndex]);
  const nextContent = useMemo(() => {
    const nextIndex = (currentIndex + 1) % contentList.length;
    return contentList[nextIndex];
  }, [contentList, currentIndex]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [contentList]);

  return (
    <div css={[slideContainerStyles, styles]}>
      <motion.div
        key={`current-${currentIndex}`}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{
          duration: 0.1,
          delay: SLIDE_DURATION,
        }}
        css={containerStyles}
      >
        <SlideContent {...currentContent} />
      </motion.div>
      <motion.div
        key={`next-${currentIndex + 1}`}
        initial={{ y: 0 }}
        animate={{ y: '-100%' }}
        transition={{
          duration: 0.2,
          delay: SLIDE_DURATION,
        }}
        onAnimationComplete={() => setCurrentIndex((prevIndex) => (prevIndex + 1) % contentList.length)}
        css={containerStyles}
      >
        <SlideContent {...nextContent} />
      </motion.div>
    </div>
  );
};

const SlideContent: FC<ContentType> = ({ title, image, action }) => {
  return (
    <>
      <img src={image} css={imageStyles} />
      <div css={contentStyles}>
        <Heading level={2} css={headingStyles}>
          {title}
        </Heading>
        {action}
      </div>
    </>
  );
};

const slideContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  overflow: 'hidden',
  height: 176,
});

const containerStyles = css({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100%',
  gap: theme.spacing(2),
});

const headingStyles = css({
  [`@media (max-width: ${breakpoints.medium.max}px)`]: {
    fontSize: theme.font.size.h3,
  },
});

const imageStyles = css({
  aspectRatio: 1,
  width: 128,
  [`@media (max-width: ${breakpoints.small.max}px)`]: {
    width: 80,
  },
});

const contentStyles = css({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
});
