import React, {
  ComponentProps,
  ComponentType,
  FormEventHandler,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { css, Interpolation, PropsOf, SerializedStyles, Theme } from '@emotion/react';
import { IGif } from '@giphy/js-types';
import { EmojiClickData } from 'emoji-picker-react';
import { isFunction, noop } from 'lodash-es';
import { Range } from 'slate';
import { GifPicker } from '@frontend/gif-picker';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  IconButton,
  TemplateIcon,
  ImageIcon,
  KeyNames,
  SecondaryButton,
  PrimaryButton,
  MultiActionButton,
  MultiActionButtonProps,
  Text,
  MessageTemplate,
  useCreateEditor,
  TagType,
  focusEditor,
  insertTextAtSelection,
  EmojiButton,
  SkeletonLoader,
} from '@frontend/design-system';
import { AutoGrowTextarea, textAreaStyle } from './auto-grow-textarea';
import {
  formContainerStyle,
  bottomRowContainer,
  textAreaContainer,
  topRowContainer,
  actionButtonsContainer,
  cancelButtonStyles,
  tagListStyle,
  invalidMessageStyle,
  messageTemplateStyle,
  skeletonLoaderContainer,
  skeletonLoaderStyles,
} from './super-textarea.styles';
import { useHandlerWithoutTags } from './use-handler-without-tags';

type IconButtonClickHandler = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> & React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => void;

export type ExtraAction = Omit<PropsOf<typeof IconButton>, 'children'> & {
  icon: ReactNode;
};

export interface SuperTextareaProps {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  disableEmojis?: boolean;
  disableSend?: boolean;
  enableGifPicker?: boolean;
  id?: string;
  onChange: (value: string) => void;
  onImageClick?: IconButtonClickHandler;
  onSubmit: (trimmedValue: string) => void;
  onTemplateClick?: IconButtonClickHandler;
  onFormsTemplateClick?: IconButtonClickHandler;
  onCancel?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  hasSubmit?: boolean;
  cancelLabel?: ReactNode;
  sendOnEmpty?: boolean;
  trackingIds?: {
    emoji?: string;
    image?: string;
    send?: string;
    template?: string;
    digitalForms?: string;
    gif?: string;
  };
  SubmitComponent?: ComponentType<React.PropsWithChildren<{ disabled: boolean; onClick: FormEventHandler }>>;
  value: string;
  subtext?: string | ReactNode;
  trackingId?: string;
  styleProp?: SerializedStyles;
  submitButtonLabel?: string | ReactNode;
  extraActions?: ExtraAction[];
  isEdit?: boolean;
  multiActionButtonActions?: MultiActionButtonProps['actions'];
  minRows?: number;
  emojiPickerWidth?: number;
  enableTagEditor?: boolean;
  templateTags?: TagType[];
  showTagList?: boolean;
  invalidMessage?: string;
  invalid?: boolean;
  isNewTag?: boolean;
  isValueReRendered?: boolean;
  setIsValueReRendered?: (val: boolean) => void;
  customPanel?: ReactNode;
  isBodyValueLoading?: boolean;
  multiActionButtonProps?: Partial<MultiActionButtonProps>;
  addOnsStyles?: Interpolation<Theme>;
  emojiButtonProps?: Omit<ComponentProps<typeof EmojiButton>, 'onSelect' | 'pickerWidth'>;
  gifButtonProps?: Omit<ComponentProps<typeof EmojiButton>, 'onSelect' | 'pickerWidth'>;
  onSelectGif?: (gif: IGif) => void;
}

export type SuperTextAreaRef = {
  focus: () => void;
  insertText: (text: string, onChange?: (value: string) => void) => void;
};

export const SuperTextarea = forwardRef<SuperTextAreaRef, SuperTextareaProps>(
  (
    {
      className = '',
      children,
      disabled,
      disableEmojis,
      disableSend,
      enableGifPicker,
      id,
      onChange,
      onImageClick,
      onSubmit,
      onCancel,
      onTemplateClick,
      onFormsTemplateClick,
      onFocus,
      placeholder = 'Type something...',
      hasSubmit,
      cancelLabel = 'Cancel',
      sendOnEmpty,
      trackingId,
      trackingIds = {},
      value,
      subtext,
      styleProp,
      isEdit,
      submitButtonLabel = '',
      extraActions,
      multiActionButtonActions,
      minRows = 1,
      emojiPickerWidth = 350,
      SubmitComponent,
      enableTagEditor = false,
      templateTags = [],
      showTagList = !!templateTags.length,
      invalidMessage = '',
      invalid = !!invalidMessage,
      isNewTag,
      isValueReRendered,
      setIsValueReRendered,
      customPanel,
      multiActionButtonProps,
      isBodyValueLoading,
      emojiButtonProps,
      gifButtonProps,
      addOnsStyles = {},
      onSelectGif,
      ...rest
    }: SuperTextareaProps,
    externalRef
  ) => {
    const showImage = isFunction(onImageClick);
    const showTemplate = isFunction(onTemplateClick);
    const showFormsTemplate = isFunction(onFormsTemplateClick);
    const showAddons =
      !disableEmojis || showImage || showTemplate || showFormsTemplate || !!extraActions?.length || !!customPanel;
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const editor = useCreateEditor(templateTags, false, true);
    const [editorSelection, setEditorSelection] = useState<Range | null>(null);
    const [initialTemplate, setInitialTemplate] = useState(value);
    enableTagEditor = !!templateTags.length || enableTagEditor;

    useEffect(() => {
      if (!!isValueReRendered) {
        setInitialTemplate(value);
        setIsValueReRendered?.(false);
      }
    }, [isValueReRendered]);

    const {
      focusInput,
      insertText: insertInTextArea,
      inputId,
      inputRef,
    } = useHandlerWithoutTags({
      value,
      id,
      setShowEmojiPicker,
      onChange,
    });

    const focus = () => {
      if (enableTagEditor) focusEditor(editor);
      else focusInput();
    };

    const insertText = (text: string, onChange?: (value: string) => void) => {
      if (enableTagEditor) setEditorSelection(insertTextAtSelection(editor, text, editorSelection, onChange));
      else insertInTextArea(text, onChange);
    };

    useImperativeHandle(externalRef, () => ({
      focus,
      insertText,
    }));

    const handleEmojiSelect = (emojiObject: EmojiClickData) => {
      setShowEmojiPicker(false);
      if (emojiObject.unified !== '') {
        insertText(emojiObject.emoji, onChange);
      }
    };

    const handleEmojiClick = () => {
      setShowEmojiPicker(!showEmojiPicker);
      if (showEmojiPicker && !enableTagEditor) {
        focus();
      }
    };

    const canSend = !disableSend && (sendOnEmpty || !!value.trim());

    const handleSend: FormEventHandler = (e) => {
      e.preventDefault();
      if (canSend) {
        onSubmit(value.trim());
        onChange('');
      }
    };

    const handleKeyDown = (e: any) => {
      if (!e.shiftKey && e.key === KeyNames.Enter) {
        handleSend(e);
      } else if (e.key === KeyNames.Escape && showEmojiPicker) {
        handleEmojiClick();
      }
    };

    const handleChange = (text: string) => {
      if (showEmojiPicker) {
        setShowEmojiPicker(false);
      }
      onChange(text);
    };

    const SubmitElement = SubmitComponent ? (
      <SubmitComponent disabled={!canSend} onClick={handleSend} />
    ) : (
      <PrimaryButton
        disabled={!canSend}
        trackingId={trackingId ?? trackingIds.send ?? 'sms-send-button-click'}
        type='submit'
      >
        {submitButtonLabel || 'submit'}
      </PrimaryButton>
    );

    const SubtextElement =
      typeof subtext === 'string' ? (
        // This padding lines up the text with the text inside the text area above it
        <Text css={{ padding: theme.spacing(0.25) }} color='light'>
          {subtext}
        </Text>
      ) : (
        subtext
      );

    return (
      <form
        css={formContainerStyle(isEdit)}
        className={className}
        onKeyDown={handleKeyDown}
        onSubmit={handleSend}
        tabIndex={-1}
        {...rest}
      >
        {!!isBodyValueLoading && (
          <div css={skeletonLoaderContainer(!!SubtextElement)}>
            <SkeletonLoader count={2} distance={theme.spacing(1)} css={skeletonLoaderStyles()} />
          </div>
        )}
        {!isBodyValueLoading &&
          (enableTagEditor ? (
            <section className='superTextarea-textContainer' css={topRowContainer(isEdit)}>
              <div css={textAreaContainer(isEdit)}>
                {!isValueReRendered && (
                  <MessageTemplate
                    editor={editor}
                    initialTemplate={initialTemplate}
                    onChange={handleChange}
                    tags={templateTags}
                    isNewTag={isNewTag}
                    draggableTags={true}
                  >
                    <MessageTemplate.Editor
                      css={[
                        textAreaStyle,
                        messageTemplateStyle,
                        styleProp,
                        disabled && { backgroundColor: 'transparent' },
                      ]}
                      placeholder={placeholder}
                      onBlur={() => setEditorSelection(editor.selection)}
                      {...rest}
                    />
                    {!!templateTags.length && showTagList && <MessageTemplate.TagList css={tagListStyle} />}
                    {invalid && invalidMessage && <div css={invalidMessageStyle}>{invalidMessage}</div>}
                  </MessageTemplate>
                )}
              </div>
            </section>
          ) : (
            <div css={topRowContainer(isEdit)}>
              <p css={textAreaContainer(isEdit)}>
                <AutoGrowTextarea
                  id={inputId}
                  maxRows={4}
                  minRows={minRows}
                  name='supertextarea'
                  onChange={handleChange}
                  placeholder={placeholder}
                  value={value}
                  ref={inputRef}
                  css={[styleProp, disabled && { backgroundColor: 'transparent' }]}
                  disabled={disabled}
                  onFocus={onFocus}
                />
                {SubtextElement}
              </p>
            </div>
          ))}

        <div css={bottomRowContainer(isEdit)}>
          {showAddons && (
            <p
              css={[
                css`
                  margin: 0;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `,
                addOnsStyles,
              ]}
            >
              {!disableEmojis && (
                <EmojiButton
                  onSelect={handleEmojiSelect}
                  disabled={disabled}
                  trackingId={trackingIds.emoji ?? 'sms-emoji-button-click'}
                  pickerWidth={emojiPickerWidth}
                  css={{
                    color: disabled ? theme.font.colors.disabled : theme.colors.neutral70,
                  }}
                  {...emojiButtonProps}
                />
              )}
              {enableGifPicker && (
                <GifPicker
                  onSelect={onSelectGif}
                  disabled={disabled}
                  trackingId={trackingIds.gif ?? 'sms-gif-button-click'}
                  pickerWidth={emojiPickerWidth}
                  css={{
                    color: disabled ? theme.font.colors.disabled : theme.colors.neutral70,
                  }}
                  {...gifButtonProps}
                />
              )}
              {showImage && (
                <IconButton
                  type='button'
                  label='Add image'
                  aria-label='add image'
                  data-trackingid={trackingIds.image}
                  showLabelOnHover
                  onClick={onImageClick || noop}
                  disabled={disabled}
                  trackingId={trackingIds.image ?? 'sms-image-button-click'}
                >
                  <ImageIcon
                    css={{
                      color: disabled ? theme.font.colors.disabled : theme.colors.neutral70,
                    }}
                  />
                </IconButton>
              )}
              {showTemplate && (
                <IconButton
                  type='button'
                  label='Templates'
                  aria-label='use message template'
                  data-trackingid={trackingIds.template}
                  showLabelOnHover
                  onClick={onTemplateClick || noop}
                  disabled={disabled}
                  trackingId={trackingIds.template ?? 'sms-template-button-click'}
                >
                  <TemplateIcon
                    css={{
                      color: disabled ? theme.font.colors.disabled : theme.colors.neutral70,
                    }}
                  />
                </IconButton>
              )}
              {showFormsTemplate && (
                <IconButton
                  type='button'
                  label='Digital Forms Template'
                  aria-label='use digital forms template'
                  data-trackingid={trackingIds.digitalForms}
                  showLabelOnHover
                  onClick={onFormsTemplateClick || noop}
                  disabled={disabled}
                >
                  <Icon
                    name='forms'
                    css={{
                      color: disabled ? theme.font.colors.disabled : theme.colors.neutral70,
                    }}
                  />
                </IconButton>
              )}
              {extraActions?.map((action, index) => {
                const { icon, disabled: actionDisabled, ...rest } = action;
                return (
                  <IconButton
                    key={`${action.label}-${index}`}
                    type='button'
                    showLabelOnHover={!!rest.label}
                    {...rest}
                    disabled={actionDisabled ?? disabled}
                  >
                    {icon}
                  </IconButton>
                );
              })}
              {customPanel}
            </p>
          )}
          <div css={actionButtonsContainer(isEdit)}>
            {(multiActionButtonProps?.Dialog || multiActionButtonActions) && !hasSubmit && !isEdit && (
              <MultiActionButton
                onClick={() => {}}
                disabled={!canSend || disabled}
                label={submitButtonLabel || 'Send'}
                type='submit'
                actions={multiActionButtonActions}
                size='small'
                trackingId={trackingId ?? trackingIds.send ?? 'sms-multi-send-button-click'}
                {...multiActionButtonProps}
              />
            )}
            {hasSubmit && !onCancel && !isEdit && SubmitElement}
            {onCancel && (
              <SecondaryButton type='button' css={cancelButtonStyles} onClick={onCancel}>
                {cancelLabel}
              </SecondaryButton>
            )}
            {isEdit && (
              <PrimaryButton disabled={!canSend} type='submit'>
                Save
              </PrimaryButton>
            )}
          </div>
        </div>
        {children}
      </form>
    );
  }
);
SuperTextarea.displayName = 'SuperTextarea';
